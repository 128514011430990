import React, { Component } from "react";

import Utils from "../../../utils/utils";

import { Box, Icon } from "@material-ui/core";

import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";

class RSuiteDateRangePickerCostume extends Component {
  constructor(props) {
    super(props);

    let value = props.value ? props.value : [];
    let icon = props.icon ? props.icon : <Icon component={"i"}>event_24</Icon>;
    let placeholder = props.placeholder ? props.placeholder : "";
    let appearance = props.appearance ? props.appearance : "subtle"; // default
    let format = props.placeholder
      ? props.format
      : 'dd-MM-yyyy';
    let block =
      props.block === false || props.block === true ? props.block : true;
    let cleanable =
      props.cleanable === false || props.cleanable === true
        ? props.cleanable
        : true;
    let isDefault =
      props.isDefault === false || props.isDefault === true
        ? props.isDefault
        : false;
    let ranges =
      props.ranges && props.ranges.length > 0
        ? props.ranges
        : Utils.getDateRanges();
    let rangesLabel =
      props.rangesLabel && props.rangesLabel.length > 0
        ? props.rangesLabel
        : Utils.getDateRangeLabel;

    this.state = {
      value: value,
      icon: icon,
      placeholder: placeholder,
      appearance: appearance,
      format: format,
      block: block,
      cleanable: cleanable,
      isDefault: isDefault,
      ranges: ranges,
      rangesLabel: rangesLabel,
      selectedDate: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.value) {
      let value = nextProps.value ? nextProps.value : [];
      this.setState({
        value: value,
      });
    }
  }

  getOptionsEls = () => {
    let options = null;

    try {
      let daterange = document.body.getElementsByClassName(
        "rs-picker-daterange-menu"
      )[0];
      let ranges = daterange.getElementsByClassName(
        "rs-picker-toolbar-ranges"
      )[0];
      options = ranges.getElementsByClassName("rs-btn");
    } catch (err) {}

    return options;
  };
  getCustomEl = () => {
    let el = null;

    try {
      let options = this.getOptionsEls();

      if (options && options.length > 0) {
        el = options[options.length - 1];
      }
    } catch (err) {}

    return el;
  };
  addClickListener = (el) => {
    try {
      if (el) {
        el.addEventListener("click", this.onCustomClickListener);
      }
    } catch (err) {}
  };
  addClickListeners = (els) => {
    if (els && els.length > 0) {
      for(let i = 0; i < (els.length - 1); i++){
        try {
          if (els[i]) {
            els[i].addEventListener("click", this.onOtherClickListener);
          }
        } catch (err) {}
      }
    }
  };
  removeClickListener = (el) => {
    try {
      if (el) {
        el.removeEventListener("click", this.onCustomClickListener);
      }
    } catch (err) {}
  };
  removeClickListeners = (els) => {
    if (els && els.length > 0) {
      for(let i = 0; i < (els.length - 1); i++){
        try {
          if (els[i]) {
            els[i].removeEventListener("click", this.onOtherClickListener);
          }
        } catch (err) {}
      }
    }
  };
  onCustomClickListener = (e) => {
    this.selectLabelOnEntering();
    let options = this.getOptionsEls();
    if (options && options.length > 0) {
      for (let i = 0; i < options.length; i++) {
        options[i].disabled = false;
        options[i].classList.remove("rs-btn-disabled");
        options[i].classList.remove("selected");
      }
    }

    e.target.classList.add("selected");
  };
  onOtherClickListener = (e) => {
    let els = document.getElementsByClassName('rs-picker-toolbar-right');
    if(els && els.length > 0){
      let btns = els[0].getElementsByClassName('rs-btn-sm')
      if(btns && btns.length > 0){
        btns[0].click();
      }
    }
  };

  selectLabelOnEntering = () => {
    try {
      let label = this.state.rangesLabel(
        this.state.value[0],
        this.state.value[1]
      );
      let options = this.getOptionsEls();

      if (options && options.length > 0) {
        for (let i = 0; i < options.length; i++) {
          options[i].disabled = false;
          options[i].classList.remove("rs-btn-disabled");
          options[i].classList.remove("selected");

          if (options[i].innerHTML === label) {
            options[i].classList.add("selected");
          }
        }
      }
    } catch (err) {}
  };
  selectLabelOnSelect = () => {
    try {
      let d1 = this.state.selectedDate && this.state.selectedDate.length > 0 ? this.state.selectedDate[0] : null;
      let d2 = this.state.selectedDate && this.state.selectedDate.length > 1 ? this.state.selectedDate[1] : null;

      let label = this.state.rangesLabel(d1, d2);
      let options = this.getOptionsEls();

      if (options && options.length > 0) {
        for (let i = 0; i < options.length; i++) {
          options[i].classList.remove("selected");

          if (options[i].innerHTML === label) {
            options[i].classList.add("selected");
          }
        }
      }
    } catch (err) {}
  };

  render() {
    return (
      <Box
        className={"custom-rsuite-date-range-picker " + this.props.className + (this.state.isDefault ? ' default-look ' : '')}
      >
        <DateRangePicker
          isoWeek
          placeholder={this.state.placeholder}
          value={this.state.value}
          block={this.state.block}
          cleanable={this.state.cleanable}
          format={this.state.format}
          appearance={this.state.appearance}
          renderValue={this.props.renderValue}
          disabled={this.props.disabled}
          ranges={this.state.ranges}
          placement={(this.props.placement && this.props.placement != '') ? this.props.placement : 'bottomStart'}
          character={' to '}
          locale={{
            ok: 'APPLY'
          }}
          onEntering={() => {
            let el = this.getCustomEl();
            this.addClickListener(el);

            let els = this.getOptionsEls();
            this.addClickListeners(els);

            this.selectLabelOnEntering();
          }}
          onClose={() => {
            let el = this.getCustomEl();
            this.removeClickListener(el);

            let els = this.getOptionsEls();
            this.removeClickListeners(els);
          }}
          onSelect={(date) => {
            let selectedDate = this.state.selectedDate;

            if (selectedDate && selectedDate.length < 2) {
              selectedDate.push(date);
            } else {
              selectedDate = [];
              selectedDate.push(date);
            }

            this.setState(
              {
                selectedDate: selectedDate,
              },
              () => {
                this.selectLabelOnSelect();
              }
            );
          }}
          onChange={(value) => {
            this.setState(
              {
                value: value,
              },
              () => {
                if (this.props.onChange) {
                  this.props.onChange(value);
                }
              }
            );
          }}
        />
        {this.state.icon}
      </Box>
    );
  }
}

export default RSuiteDateRangePickerCostume;
