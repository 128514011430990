import React, {useState, useEffect } from 'react'
import moment from 'moment';
// import {useIntl} from 'react-intl'
import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { dispatchApiCallGet, dispatchApiCallPost } from '../../../setup/redux/dispatch/actions'
// import { commonProfileApiCallGet, commonProfileApiCallPost, commonProfileApiCallPut, commonProfileApiCallDelete } from '../../../setup/redux/commonProfile/actions'

import ViewLayout from "./layout/ViewLayout.jsx";
import ViewTopSection from "./partial/ViewTopSection.jsx";
import Utils from '../../utils/utils'

import { 
    Card,
    Button,
    Row,
    Col
  } from "react-bootstrap-v5";

const Dashboard = (props) => {
    const [loading, setLoading] = useState(false)
    // const auth = useSelector(state => state.auth);
    // const intl = useIntl();
    const history = useHistory();

    const [countUnassigned, setCountUnassigned] = useState(0);
    const [idUnassigned, setIdUnassigned] = useState(0);
    const [countAssigned, setcountAssigned] = useState(0);
    const [idAssigned, setIdAssigned] = useState(0);
    const [countDispatched, setCountDispatched] = useState(0);
    const [idDispatched, setIdDispatched] = useState(0);
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());

    const dispatch = useDispatch();
    const dispatchCrud = useSelector(state => state.dispatchCrud);

    function findStatus (data, text) {
        let status = null;

        if(data && data.length > 0) {
            let statusItem = data.filter(x => x.jobStatusName.toLowerCase() === text.toLowerCase());

            if(statusItem && statusItem.length > 0){
                status = statusItem[0];
            }
        }

        return status;
    }

    function setData (result) {
        if(result) {
            if(result.data) {
                let statusDraft = findStatus(result.data, "Draft");
                let statusUnassigned = findStatus(result.data, "Unassigned");
                let statusAssigned = findStatus(result.data, "Assigned");
                let statusDispatched = findStatus(result.data, "Dispatched");
            
                let statusDraftCount = (statusDraft && statusDraft.count) ? parseInt(statusDraft.count) : 0;
                let statusUnassignedCount = (statusUnassigned && statusUnassigned.count) ? parseInt(statusUnassigned.count) : 0;
                let unassignedCount = statusDraftCount + statusUnassignedCount;

                let statusDraftId = (statusDraft && statusDraft.jobStatusId) ? statusDraft.jobStatusId : 0;
                let statusUnassignedId = (statusUnassigned && statusUnassigned.jobStatusId) ? statusUnassigned.jobStatusId : 0;
                let unassignedIds = [statusDraftId, statusUnassignedId].join(',');

                setCountUnassigned(unassignedCount);
                setIdUnassigned(unassignedIds);

                setcountAssigned((statusAssigned && statusAssigned.count) ? statusAssigned.count : 0);
                setIdAssigned((statusAssigned && statusAssigned.jobStatusId) ? statusAssigned.jobStatusId : 0);

                setCountDispatched((statusDispatched && statusDispatched.count) ? statusDispatched.count : 0);
                setIdDispatched((statusDispatched && statusDispatched.jobStatusId) ? statusDispatched.jobStatusId : 0);
            }
        }
    }     

    function callStatApi (callback = null) {
        setLoading(true);

        let fromDate = moment(startDate).format(Utils.getAPIDateFormat());
        let toDate = moment(endDate).format(Utils.getAPIDateFormat());

        let data = {
            searchQuery: '',
            fromDate: fromDate,
            toDate: toDate,
            jobStatusFilter: ''
        };

        dispatch(dispatchApiCallGet(data, 'dashboard-stats', 'job/status/stat', null, callback, null));
    }
    
    function SendNotificationToDriver () {
        setLoading(true);

        let fromDate = moment(startDate).format(Utils.getAPIDateFormat());
        let toDate = moment(endDate).format(Utils.getAPIDateFormat());

        let data = {
            searchQuery: '',
            fromDate: fromDate,
            toDate: toDate,
            jobStatusFilter: idDispatched
        };
        
        Utils.toast("Reminder notification sent successfully!", 'success');
        dispatch(dispatchApiCallPost(data, null, 'job/send-notification-to-drivers', null, setLoading(false), setLoading(false)));
    }

    useEffect(() => {
        callStatApi(setData);
    }, []);

    useEffect(() => {
        Utils.reduxProps(dispatchCrud,
            'dashboard-stats', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                setLoading(false);
                
                if(callback){
                    callback(data);
                }
            }
        );
    }, [dispatchCrud]);

    return <div className={'dashboard-page'}>
        <ViewLayout 
            isBlocking={loading}
            topSection={<ViewTopSection />}
            contentSection={
                <div>                    
                    <Row className='justify-content-center mt-5 mb-5'>
                        <Col xs={12} className='fs-4'>
                            Today's Summary
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <Col xs={12} sm={6} xl={3}>
                            <Card className='mb-3'>
                                <Card.Body className='text-center pb-20 pt-18'>
                                    <Card.Text className='d-flex align-items-center justify-content-center'>
                                        <span className='fs-3tx fw-bold me-4'>
                                            {countUnassigned}
                                        </span>
                                        <span className='fs-4'>
                                            Job Not Assigned
                                        </span>
                                    </Card.Text>
                                    <Button variant="primary" className='mt-10'
                                        onClick={e => history.push('/jobs?status_filter=' + idUnassigned)}
                                    >Assign Now</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={6} xl={3}>
                            <Card className='mb-3'>
                                <Card.Body className='text-center pb-20 pt-18'>
                                    <Card.Text className='d-flex align-items-center justify-content-center'>
                                        <span className='fs-3tx fw-bold me-4'>
                                            {countAssigned}
                                        </span>
                                        <span className='fs-4'>
                                            Job Not Dispatched
                                        </span>
                                    </Card.Text>
                                    <Button variant="primary" className='mt-10'
                                        onClick={e => history.push('/jobs?status_filter=' + idAssigned)}
                                    >Dispatch Now</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} sm={12} xl={3}>
                            <Card className='mb-3'>
                                <Card.Body className='text-center pb-20 pt-18'>
                                    <Card.Text className='d-flex align-items-center justify-content-center'>
                                        <span className='fs-3tx fw-bold me-4'>
                                            {countDispatched}
                                        </span>
                                        <span className='fs-4'>
                                            Job Not Ackowledged
                                        </span>
                                    </Card.Text>
                                    <Button variant="danger" className='mt-10' onClick={e => SendNotificationToDriver()}>Send Reminders</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            }
        />
    </div>
}


export default Dashboard;