import axios from 'axios'

const { 
  REACT_APP_DISPATCH_API_URL
} = process.env;


export function readApi(data = null, path = '', cancelationToken = null, token = null) {
  if(cancelationToken){
    const axiosInstance = axios.create({
      cancelToken: cancelationToken.token,
      headers: {
        'Accept': 'application/json',
        'content-type': 'application/json',
        'ContentType': 'application/json',
        'Authorization': token,
      }
    });

    return axiosInstance.get(REACT_APP_DISPATCH_API_URL + path, { params: data })
  } else {
    return axios.get(REACT_APP_DISPATCH_API_URL + path, { params: data })
  }
}

export function createApi(data: any = null, path = '', cancelationToken = null, token = null) {
  if(cancelationToken){
    const axiosInstance = axios.create({
      cancelToken: cancelationToken.token,
      headers: {
        'Accept': 'application/json',
        'content-type': 'application/json',
        'ContentType': 'application/json',
        'Authorization': token,
      }
    });

    return axiosInstance.post(REACT_APP_DISPATCH_API_URL + path, data)
  } else {
    return axios.post(REACT_APP_DISPATCH_API_URL + path, data)
  }
}

export function updateApi(data: any = null, path = '', cancelationToken = null, token = null) {
  if(cancelationToken){
    const axiosInstance = axios.create({
      cancelToken: cancelationToken.token,
      headers: {
        'Accept': 'application/json',
        'content-type': 'application/json',
        'ContentType': 'application/json',
        'Authorization': token,
      }
    });

    return axiosInstance.put(REACT_APP_DISPATCH_API_URL + path, data)
  } else {
    return axios.put(REACT_APP_DISPATCH_API_URL + path, data)
  }
}
  
export function deleteApi(data = null, path = '', cancelationToken = null, token = null) {
  if(cancelationToken){
    const axiosInstance = axios.create({
      cancelToken: cancelationToken.token,
      headers: {
        'Accept': 'application/json',
        'content-type': 'application/json',
        'ContentType': 'application/json',
        'Authorization': token,
      }
    });

    return axiosInstance.delete(REACT_APP_DISPATCH_API_URL + path, { data: data })
  } else {
    return axios.delete(REACT_APP_DISPATCH_API_URL + path, { data: data })
  }
}