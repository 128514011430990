import React from "react";

import { 
    Row,
    Col,
} from "react-bootstrap-v5";

import AddPhotoIcon from '../../../../../_metronic/assets/img/icons/material-photo.png';

import InnerFilesDropzoneWithCropper from "../../../../components/OLD/Dropzone/InnerDropzone/InnerFilesDropzoneWithCropper.jsx";

import Utils from "../../../../utils/utils";

import { connect } from 'react-redux'
import { clear } from "../../../../../setup/redux/actions";
import { dispatchApiCallGet } from '../../../../../setup/redux/dispatch/actions'

class PhotosSection extends React.Component {
  
    constructor(props) {
        super(props);
        
        this.name = 'job_form_photos_section';

        let dataParams = (props.dataParams) ? props.dataParams : null;
        let id = (dataParams && dataParams.id) ? dataParams.id : null;
        let row = (dataParams && dataParams.row) ? dataParams.row : null;
        

        this.state = {
            id: id,
            row: row,

            isExpanded: true,
        };
    }
    

    body = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;
        
        return <Row className={'pb-10'}>
            <Col xs={12} className={'mt-10 mb-10'}><hr /></Col>

            <Col xs={12}>
                <h3 className={'mb-7'}>Attachments</h3>
            </Col>

            <Col xs={12}>
                <InnerFilesDropzoneWithCropper
                    className={"job-form-files-dropzone-images-inline"}
                    maxEmptyItems={4}
                    smSize={2}
                    addImage={AddPhotoIcon}
                    showInput={true}
                    inputPlaceholder={""}
                    path={"jobs"}
                    accept={"image/jpeg,image/jpg,image/png"}
                    acceptText={".jpeg, .jpg, .png"}
                    disabled={!values.isActive || Utils.isCancelledOrFailed(values.currentStatusName)}
                    value={values.photoAttachmentsUrl.map(x => x.photoUrl)}
                    onDrop={async (files) => {
                        await setFieldValue('customFormParams.photoAttachmentsIsLoading', true);
                    }}
                    onUpload={async (files) => {
                        let photoAttachmentsUrl = values.photoAttachmentsUrl;
                        if(files && files.length > 0){
                            files.forEach((item, i) => {
                                photoAttachmentsUrl.push({
                                    photoUrl: item,
                                });
                            });
                        }
                        await setFieldValue('photoAttachmentsUrl', photoAttachmentsUrl);
                        await setFieldValue('customFormParams.photoAttachmentsIsLoading', false);
                    }}
                    onRemove={async (file, i, files) => {
                        let attachmentsUrl = values.photoAttachmentsUrl;
                        Utils.removeFromArrayWithField(attachmentsUrl, file, 'photoUrl');

                        await setFieldValue('photoAttachmentsUrl', attachmentsUrl);
                        await setFieldValue('customFormParams.photoAttachmentsIsLoading', false);
                    }}
                    // descField={<ManageInput
                    //   value={'asd'}
                    //   placeholder={"desc"}
                    //   onBlur={(value) => {
                    //     // this.setState({
                    //     //   billingServiceTag: value
                    //     // });
                    //   }}
                    // />}
                    />
            </Col>
        </Row>
    }
    

    render() {
        return <>
            {this.body()}
        </>
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PhotosSection);
