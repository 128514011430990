import React from "react";
import _ from 'lodash';

import { 
    Row,
    Col,
    Form,
    Spinner,
    Button,
    Collapse,
    InputGroup,
} from "react-bootstrap-v5";

import { FieldArray } from 'formik';

import { DrawerContentLayout } from '../../../components/drawer/DrawerLayout'
import Utils from "../../../utils/utils";
import { PayrollTemplateVariableType, DayOfWeek } from "../../../utils/enums";

import RSuiteSelectPicker from '../../../components/OLD/Select/RSuiteSelectPicker';
import RSuiteTimePicker from '../../../components/OLD/TimePicker/RSuiteTimePicker';
import IconButton from "../../../components/Buttons/IconButton";

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet } from '../../../../setup/redux/dispatch/actions'


const Accordion = (props) => {
    const [isOpen, setIsOpen] = React.useState(((props.isOpen === false) || (props.isOpen === true)) ? props.isOpen : false);

    React.useEffect(() => {
        setIsOpen(((props.isOpen === false) || (props.isOpen === true)) ? props.isOpen : false);
    }, [props.isOpen]);

    return <>
        <Row className={'align-items-center mt-5'}>
            <Col xs={true}
                className={'cursor-pointer'}
                onClick={() => {
                    if(props.onChange){
                        props.onChange(!isOpen);
                    }
                }}
            >
                <h6 className={'mb-0'}>{props.title}</h6>
            </Col>
            <Col xs={'auto'}
                className={'cursor-pointer'}
                onClick={() => {
                    if(props.onChange){
                        props.onChange(!isOpen);
                    }
                }}
            >
                <i className={'material-icons'}>{isOpen ? 'expand_more' : 'expand_less'}</i>
            </Col>
            <Col xs={12}><hr className={'custom-manage-hr mt-0 mb-1'} /></Col>
        </Row>
        <Collapse in={isOpen}>
            <Row>
                <Col xs={12}>
                    <h6 className={'mb-3'}><small>{props.desc}</small></h6>
                </Col>
                <Col xs={12} className={'mt-5'}>{props.children}</Col>
            </Row>
        </Collapse>
    </>
}


class PayrollForm extends React.Component {
  
    constructor(props) {
        super(props);
    
        this.bodyRef = React.createRef();
        this.accountWorkingHourRef = React.createRef();

        let dataParams = (props.dataParams) ? props.dataParams : null;
        let id = (dataParams && dataParams.id) ? dataParams.id : null;
        let row = (dataParams && dataParams.row) ? dataParams.row : null;
    
        this.state = {
            id: id,
            row: row,

            isExpandedWorkTime: true,
            isExpandedDistance: true,

            accountWorkingHourDays: [
                {
                  label: Utils.getDayOfWeekLabel(DayOfWeek.Sunday),
                  day: DayOfWeek.Sunday,
                  isEnabled: false,
                  timeFrom: null,
                  timeTo: null,
                },
                {
                  label: Utils.getDayOfWeekLabel(DayOfWeek.Monday),
                  day: DayOfWeek.Monday,
                  isEnabled: false,
                  timeFrom: null,
                  timeTo: null,
                },
                {
                  label: Utils.getDayOfWeekLabel(DayOfWeek.Tuesday),
                  day: DayOfWeek.Tuesday,
                  isEnabled: false,
                  timeFrom: null,
                  timeTo: null,
                },
                {
                  label: Utils.getDayOfWeekLabel(DayOfWeek.Wednesday),
                  day: DayOfWeek.Wednesday,
                  isEnabled: false,
                  timeFrom: null,
                  timeTo: null,
                },
                {
                  label: Utils.getDayOfWeekLabel(DayOfWeek.Thursday),
                  day: DayOfWeek.Thursday,
                  isEnabled: false,
                  timeFrom: null,
                  timeTo: null,
                },
                {
                  label: Utils.getDayOfWeekLabel(DayOfWeek.Friday),
                  day: DayOfWeek.Friday,
                  isEnabled: false,
                  timeFrom: null,
                  timeTo: null,
                },
                {
                  label: Utils.getDayOfWeekLabel(DayOfWeek.Saturday),
                  day: DayOfWeek.Saturday,
                  isEnabled: false,
                  timeFrom: null,
                  timeTo: null,
                },
            ],
        };
    }


    componentDidMount(){
        if(this.state.id <= 0){
            let {
                setFieldValue,
            } = this.props.formOptions;
    
            this.callJobTemplateApi(async (arr, jobTemplateItems) => {
                await setFieldValue('customFormParams.jobTemplateItems', jobTemplateItems);
                await setFieldValue('payrollTemplateVariableJobTemplates', arr);
            })
        } else {
            let {
                values,
                setFieldValue,
            } = this.props.formOptions;
    
            setFieldValue('customFormParams.jobTemplateItems', values.customFormParams.jobTemplateItems);
        }
    }
    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
		Utils.reduxProps(nextProps,
            'manage_payroll_form_drawer-account_working_hour', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.accountWorkingHourId;
                        let title = item.workingHourName;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );
		
        Utils.reduxProps(nextProps,
            'manage_payroll_form_drawer-job_template', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = [];
                let jobTemplateItems = [];

                let payrollTemplateVariableJobTemplateRanges = [];
                if(variables && variables.items && variables.items.length > 0){
                    variables.items.forEach((item, i) => {
                        payrollTemplateVariableJobTemplateRanges.push({
                            variableDistanceName: item.variableDistanceName,
                            standardPay: '',
                            overtimePay: '',
                        });
                    });
                }

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.jobTemplateId;
                        let title = item.jobTemplateName;

                        let arrItem = {
                            jobTemplateId: value,
                            jobTemplateName: title,
                            value: value,
                            title: title,
                            item: item,
                            isExpanded: false,
                            payrollTemplateVariableJobTemplateRanges: payrollTemplateVariableJobTemplateRanges,
                            standardPay: '',
                            overtimePay: '',
                        };

                        let jobTemplateItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                        jobTemplateItems = Utils.addToArray(jobTemplateItems, value, jobTemplateItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr, jobTemplateItems);
                    }
                });
            }
        );
    }
	
    callAccountWorkingHourApi = (items, callback = null) => {
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            isActive: true,
        };
     
        this.props.dispatchApiCallGet(data, 'manage_payroll_form_drawer-account_working_hour', 'AccountWorkingHour', { items: items }, callback, () => {});
    }

    callJobTemplateApi = (callback = null) => {
        let {
            values,
        } = this.props.formOptions;
    
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            sortColumn: 'common',
            sortDir: 'desc',
        };
     
        this.props.dispatchApiCallGet(data, 'manage_payroll_form_drawer-job_template', 'jobtemplate', { items: values.payrollTemplateVariableRanges }, callback, () => {});
    }
    /* END API */


    /* FUNCTIONS */
    workTimeSection = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;

        return <Accordion
            title={'WORK TIME'}
            desc={'Set up the standard work hours for drivers. Over time hours are outside of standard hours.'}
            isOpen={this.state.isExpandedWorkTime}
            onChange={(isOpen) => {
                this.setState({
                    isExpandedWorkTime: isOpen
                });
            }}
        >
            <Row>
                <Col xs={12}>
                    <Form.Group as={Row} className={'mb-5'}>
                        <Form.Label column sm={3}>{this.props.fields.accountWorkingHourId.label}</Form.Label>
                        <Col sm={true}>
                            <Form.Control
                                ref={this.accountWorkingHourRef}

                                as = {RSuiteSelectPicker}
                                isInvalid={!!errors.accountWorkingHourId}

                                id={this.props.fields.accountWorkingHourId.id}
                                placeholder={(values.accountWorkingHourIsNew && (values.accountWorkingHourId === null)) ? 'NEW' : this.props.fields.accountWorkingHourId.placeholder}
                                items={values.customFormParams.accountWorkingHourItems}
                                value={values.accountWorkingHourId}
                                selectedName={values.accountWorkingHourName}
                                isLoading={values.customFormParams.accountWorkingHourIsLoading}
                                searchable={true}
                                cleanable={true}
                                isDefault={true}
                                renderExtraFooter={() => {
                                    return <Row className={'align-items-center'}>
                                        <Col xs={12}>
                                            <hr />
                                        </Col>
                                        <Col xs={12}>
                                            <div className={'ps-2 pe-2 pb-3'}>
                                                <Button
                                                    variant={"primary"}
                                                    disabled={values.accountWorkingHourIsNew}
                                                    onClick={async (e) => {
                                                        await setFieldValue('accountWorkingHourIsNew', true);
                                                        await setFieldValue('accountWorkingHourId', null);
                                                        await setFieldValue('accountWorkingHourName', '');
                                                        
                                                        await setFieldValue('accountWorkingHourDays', this.state.accountWorkingHourDays);
                                                        await setFieldValue('workTimeName', '');
                                                        await setFieldValue('workTimeDescription', '');

                                                        if(this.accountWorkingHourRef && this.accountWorkingHourRef.current){
                                                            this.accountWorkingHourRef.current.handleClose()
                                                        }

                                                        // setTimeout(() => validateForm(), 100);
                                                    }}
                                                >
                                                    <div className={'ps-2 pe-2 pt-1 pb-1 d-flex align-items-center'}>
                                                        <i className={'material-icons'}>add</i>
                                                        <span xs={'auto'}>Add new</span>
                                                    </div>
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                }}
                                onClick={async () => {
                                    await setFieldValue('customFormParams.accountWorkingHourIsLoading', true);
                                    this.callAccountWorkingHourApi(values.customFormParams.accountWorkingHourItems, async (items) => {
                                        await setFieldValue('customFormParams.accountWorkingHourItems', items);
                                        await setFieldValue('customFormParams.accountWorkingHourIsLoading', false);
                                    });
                                }}
                                onSelect={async (value, item) => {
                                    await setFieldValue('accountWorkingHourIsNew', false);
                                    await setFieldValue('accountWorkingHourId', value);
                                    await setFieldValue('accountWorkingHourName', item.title);

                                    let horkHourItem = (item && item.item) ? item.item : null;
                                    let workingHourName = (horkHourItem && horkHourItem.workingHourName && horkHourItem.workingHourName !== '') ? horkHourItem.workingHourName : '';
                                    let workingHourDescription = (horkHourItem && horkHourItem.workingHourDescription && horkHourItem.workingHourDescription !== '') ? horkHourItem.workingHourDescription : '';
                                    let accountWorkingHourDays = (horkHourItem && horkHourItem.accountWorkingHourDays && horkHourItem.accountWorkingHourDays.length > 0) ? horkHourItem.accountWorkingHourDays : [];
                                    if(accountWorkingHourDays && accountWorkingHourDays.length > 0){
                                        let accountWorkingHourDaysItems = accountWorkingHourDays.map((workHourItem, j) => {
                                            return {
                                                label: Utils.getDayOfWeekLabel(workHourItem.day),
                                                day: workHourItem.day,
                                                isEnabled: workHourItem.isEnabled,
                                                timeFrom: workHourItem.timeFrom,
                                                timeTo: workHourItem.timeTo,
                                            }
                                        });
                                        await setFieldValue('accountWorkingHourDays', accountWorkingHourDaysItems);
                                        await setFieldValue('workTimeName', workingHourName);
                                        await setFieldValue('workTimeDescription', workingHourDescription);
                                    }
                                    
                                }}
                                onClear={async () => {
                                    await setFieldValue('accountWorkingHourIsNew', false);
                                    await setFieldValue('accountWorkingHourId', null);
                                    await setFieldValue('accountWorkingHourName', '');

                                    await setFieldValue('accountWorkingHourDays', this.state.accountWorkingHourDays);
                                    await setFieldValue('workTimeName', '');
                                    await setFieldValue('workTimeDescription', '');
                                }}
                            />
                            <Form.Control.Feedback type="invalid">{errors.accountWorkingHourId}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                </Col>

                {(values.accountWorkingHourIsNew || (values.accountWorkingHourId)) && <>
                    {(!values.accountWorkingHourId) && <Col xs={12}>
                        <Form.Group as={Row} className={'mb-5'}>
                            <Form.Label column sm={3}>{this.props.fields.workTimeName.label}</Form.Label>
                            <Col sm={9}>
                                <Form.Control
                                    type="text"
                                    id={this.props.fields.workTimeName.id}
                                    placeholder={this.props.fields.workTimeName.placeholder}
                                    value={values.workTimeName}
                                    onChange={handleChange}
                                    isInvalid={!!errors.workTimeName}
                                />
                                <Form.Control.Feedback type="invalid">{errors.workTimeName}</Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                    </Col>}
                    <Col xs={12}>
                        <Form.Group as={Row} className={'mb-5'}>
                            <Form.Label column sm={3}>{this.props.fields.workTimeDescription.label}</Form.Label>
                            <Col sm={9}>
                                <Form.Control
                                    type="text"
                                    id={this.props.fields.workTimeDescription.id}
                                    placeholder={this.props.fields.workTimeDescription.placeholder}
                                    value={values.workTimeDescription}
                                    onChange={handleChange}
                                    isInvalid={!!errors.workTimeDescription}
                                />
                                <Form.Control.Feedback type="invalid">{errors.workTimeDescription}</Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col xs={12}>

                        <FieldArray 
                            name={this.props.fields.accountWorkingHourDays.id}
                            validateOnChange={false}
                        >
                            {({ remove, push }) => (
                                <>
                                    {values.accountWorkingHourDays.map((item, i) => {
                                        return <Row key={i} className={'align-items-center mb-5 mt-5'}>
                                            <Form.Label column sm={3}>{item.label}</Form.Label>
                                            <Col sm={2} className={'pb-3 pb-sm-0'}>
                                                <Form.Group>
                                                    <Form.Check
                                                        type="switch"
                                                        id={this.props.fields.accountWorkingHourDaysDay.id + '_' + i}
                                                        label={item.isEnabled ? 'Work' : 'Rest'}
                                                        checked={item.isEnabled}
                                                        onChange={async (e) => {
                                                            await setFieldValue('accountWorkingHourDays.' + i + '.isEnabled', e.target.checked);

                                                            if(e.target.checked){
                                                                if(item.timeFrom === null && item.timeTo === null){
                                                                    let timeFrom = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 9);
                                                                    let timeTo = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 18);
                                                                    
                                                                    let dateTimeFrom = Utils.convertDateTime(timeFrom);
                                                                    let dateTimeTo = Utils.convertDateTime(timeTo);
                                                                    
                                                                    await setFieldValue('accountWorkingHourDays.' + i + '.timeFrom', dateTimeFrom);
                                                                    await setFieldValue('accountWorkingHourDays.' + i + '.timeTo', dateTimeTo);
                                                                }
                                                            } else {
                                                                await setFieldValue('accountWorkingHourDays.' + i + '.timeFrom', null);
                                                                await setFieldValue('accountWorkingHourDays.' + i + '.timeTo', null);
                                                            }
                                                        }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col sm={7}>
                                                {item.isEnabled && <InputGroup>
                                                    <Form.Control
                                                        as = {RSuiteTimePicker}
                                                        isInvalid={!!(errors && errors.accountWorkingHourDays && errors.accountWorkingHourDays[i] && errors.accountWorkingHourDays[i].timeFrom)}
                                                        
                                                        id={this.props.fields.accountWorkingHourDaysTimeFrom.id + '_' + i}
                                                        value={item.timeFrom}
                                                        hideMinutes={minute => minute % 30 !== 0}
                                                        onChange={async (value) => {
                                                            let dateTime = Utils.convertDateTime(value);
                                                            await setFieldValue('accountWorkingHourDays.' + i + '.timeFrom', dateTime);
                                                        }}
                                                    />
                                                    <InputGroup.Text className={'bg-white border-top-0 border-bottom-0'}>TO</InputGroup.Text>
                                                    <Form.Control
                                                        as = {RSuiteTimePicker}
                                                        isInvalid={!!(errors && errors.accountWorkingHourDays && errors.accountWorkingHourDays[i] && errors.accountWorkingHourDays[i].timeTo)}
                                                        
                                                        id={this.props.fields.accountWorkingHourDaysTimeTo.id + '_' + i}
                                                        value={item.timeTo}
                                                        hideMinutes={minute => minute % 30 !== 0}
                                                        onChange={async (value) => {
                                                            let dateTime = Utils.convertDateTime(value);
                                                            await setFieldValue('accountWorkingHourDays.' + i + '.timeTo', dateTime);
                                                        }}
                                                    />
                                                </InputGroup>}
                                            </Col>
                                        </Row>
                                    })}
                                </>
                            )}
                        </FieldArray>

                    </Col>
                </>}
                
                {(values.payrollTemplateVariableTypeId === PayrollTemplateVariableType.WorkTime) && <Col xs={12} className={'mt-7'}>
                    <Row className={'align-items-center '}>
                        <Col sm={12} className={'mb-5'}>
                            <Row className={'align-items-center'}>
                                <Col sm={5}>
                                    <Form.Group>
                                        <Form.Label>Standard hours pay</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <Form.Control
                                            type="number"
                                            id={this.props.fields.standardPay.id}
                                            placeholder={this.props.fields.standardPay.placeholder}
                                            value={values.standardPay}
                                            onChange={handleChange}
                                            isInvalid={!!errors.standardPay}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.standardPay}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col sm={'auto'}>
                                    <Form.Group>
                                        <Form.Label>Per trip</Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={12} className={'mb-5'}>
                            <Row className={'align-items-center'}>
                                <Col sm={5}>
                                    <Form.Group>
                                        <Form.Label>Overtime hours pay</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Control
                                        type="number"
                                        id={this.props.fields.overtimePay.id}
                                        placeholder={this.props.fields.overtimePay.placeholder}
                                        value={values.overtimePay}
                                        onChange={handleChange}
                                        isInvalid={!!errors.overtimePay}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.overtimePay}</Form.Control.Feedback>
                                </Col>
                                <Col sm={'auto'}>
                                    <Form.Group>
                                        <Form.Label>Per trip</Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>}

            </Row>
        </Accordion>
    }

    distanceSection = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;

        return <Accordion
            title={'DISTANCE'}
            desc={'Set up driver\'s each trip pay according to distance blocks.'}
            isOpen={this.state.isExpandedDistance}
            onChange={(isOpen) => {
                this.setState({
                    isExpandedDistance: isOpen
                });
            }}
        >
            <Row>
                <Col xs={12} className={'pb-7'}>

                    <FieldArray 
                        name={this.props.fields.payrollTemplateVariableRanges.id}
                        validateOnChange={false}
                    >
                        {({ remove, push }) => (
                            <>
                                {values.payrollTemplateVariableRanges.map((item, i) => {
                                    return <Row key={i} className={'align-items-center mb-5 mt-5'}>
                                        <Col sm={4} className={'pb-3 pb-sm-0'}>
                                            <Form.Group>
                                                <Form.Control
                                                    type="text"
                                                    id={this.props.fields.variableDistanceName.id}
                                                    placeholder={this.props.fields.variableDistanceName.placeholder}
                                                    value={item.variableDistanceName}
                                                    onChange={async (e) => {
                                                        await setFieldValue('payrollTemplateVariableRanges.' + i + '.variableDistanceName', e.target.value);
                                                    }}
                                                    isInvalid={!!(errors && errors.payrollTemplateVariableRanges && errors.payrollTemplateVariableRanges[i] && errors.payrollTemplateVariableRanges[i].variableDistanceName)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={true}>
                                            <InputGroup>
                                                <Form.Control
                                                    type="number"
                                                    id={this.props.fields.payrollTemplateVariableRangeDistanceFrom.id}
                                                    placeholder={this.props.fields.payrollTemplateVariableRangeDistanceFrom.placeholder}
                                                    value={item.distanceFrom}
                                                    onChange={async (e) => {
                                                        await setFieldValue('payrollTemplateVariableRanges.' + i + '.distanceFrom', e.target.value);
                                                    }}
                                                    isInvalid={!!(errors && errors.payrollTemplateVariableRanges && errors.payrollTemplateVariableRanges[i] && errors.payrollTemplateVariableRanges[i].distanceFrom)}
                                                />
                                                <InputGroup.Text>KM</InputGroup.Text>
                                                <InputGroup.Text className={'bg-white border-top-0 border-bottom-0'}>TO</InputGroup.Text>
                                                <Form.Control
                                                    type="number"
                                                    id={this.props.fields.payrollTemplateVariableRangeDistanceTo.id}
                                                    placeholder={this.props.fields.payrollTemplateVariableRangeDistanceTo.placeholder}
                                                    value={item.distanceTo}
                                                    onChange={async (e) => {
                                                        await setFieldValue('payrollTemplateVariableRanges.' + i + '.distanceTo', e.target.value);
                                                    }}
                                                    isInvalid={!!(errors && errors.payrollTemplateVariableRanges && errors.payrollTemplateVariableRanges[i] && errors.payrollTemplateVariableRanges[i].distanceTo)}
                                                />
                                                <InputGroup.Text>KM</InputGroup.Text>
                                            </InputGroup>
                                        </Col>
                                        <Col sm={'auto'} className={'text-center'}>
                                            <IconButton
                                                className={'ms-2'}
                                                onClick={() => {
                                                    remove(i);
                                                }}
                                            >
                                                <i className={'material-icons text-danger'}>delete_forever</i>
                                            </IconButton>
                                        </Col>
                                    </Row>
                                })}

                                <Row>
                                    <Col xs={12} className={'pt-5 pt-sm-0'}>
                                        <Button
                                            type={'button'}
                                            variant={'light'}
                                            className={'ps-3 pe-3'}
                                            style={{ border: '1px solid #ccc' }}
                                            onClick={() => {
                                                validateForm().then((err) => {
                                                    if(!!err.payrollTemplateVariableRanges){
                                                        Utils.toast('You must fill in the required fields', 'error');
                                                    } else {
                                                        push(this.props.initialValues.payrollTemplateVariableRanges[0]);
                                                    }
                                                })
                                            }}
                                        >
                                            <i className={'material-icons pe-2'}>add</i>
                                            Add distance
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </FieldArray>

                </Col>
                <Col xs={12}>

                    <FieldArray 
                        name={this.props.fields.payrollTemplateVariableRanges.id}
                        validateOnChange={false}
                    >
                        {({ remove, push }) => (
                            <>
                                {values.payrollTemplateVariableRanges.map((item, i) => {
                                    return <Row key={i} className={'align-items-center mb-5 mt-5'}>
                                        <Col sm={12} className={'mb-5'}>
                                            <Row className={'align-items-center'}>
                                                <Col sm={5}>
                                                    <Form.Group>
                                                        <Form.Label>Distance</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={4}>
                                                    <Form.Group>
                                                        <Form.Label>{item.variableDistanceName}</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col sm={12} className={'mb-5'}>
                                            <Row className={'align-items-center'}>
                                                <Col sm={5}>
                                                    <Form.Group>
                                                        <Form.Label>Standard hours pay</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={4}>
                                                    <Form.Group>
                                                        <Form.Control
                                                            type="number"
                                                            id={this.props.fields.payrollTemplateVariableRangeStandardPay.id}
                                                            placeholder={this.props.fields.payrollTemplateVariableRangeStandardPay.placeholder}
                                                            value={item.standardPay}
                                                            onChange={async (e) => {
                                                                await setFieldValue('payrollTemplateVariableRanges.' + i + '.standardPay', e.target.value);
                                                            }}
                                                            isInvalid={!!(errors && errors.payrollTemplateVariableRanges && errors.payrollTemplateVariableRanges[i] && errors.payrollTemplateVariableRanges[i].standardPay)}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col sm={12} className={'mb-5'}>
                                            <Row className={'align-items-center'}>
                                                <Col sm={5}>
                                                    <Form.Group>
                                                        <Form.Label>Overtime hours pay</Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={4}>
                                                    <Form.Control
                                                        type="number"
                                                        id={this.props.fields.payrollTemplateVariableRangeOvertimePay.id}
                                                        placeholder={this.props.fields.payrollTemplateVariableRangeOvertimePay.placeholder}
                                                        value={item.overtimePay}
                                                        onChange={async (e) => {
                                                            await setFieldValue('payrollTemplateVariableRanges.' + i + '.overtimePay', e.target.value);
                                                        }}
                                                        isInvalid={!!(errors && errors.payrollTemplateVariableRanges && errors.payrollTemplateVariableRanges[i] && errors.payrollTemplateVariableRanges[i].overtimePay)}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                })}
                            </>
                        )}
                    </FieldArray>

                </Col>
            </Row>
        </Accordion>
    }

    jobTypeSection = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;

        return <FieldArray 
            name={this.props.fields.payrollTemplateVariableJobTemplates.id}
            validateOnChange={false}
        >
            {({ remove, push }) => (
                <>
                    {values.payrollTemplateVariableJobTemplates.map((item, i) => {
                        return <Accordion
                            key={i}
                            title={item.jobTemplateName}
                            desc={'Set up driver\'s each trip pay according to job type.'}
                            isOpen={item.isExpanded}
                            onChange={async (isOpen) => {
                                await setFieldValue('payrollTemplateVariableJobTemplates.' + i + '.isExpanded', !item.isExpanded);
                            }}
                        >
                            <Row className={'align-items-center mb-5 mt-5'}>
                                <Col sm={12} className={'mb-5'}>
                                    <Row className={'align-items-center'}>
                                        <Col sm={5}>
                                            <Form.Group>
                                                <Form.Label>Standard hours pay</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={4}>
                                            <Form.Group>
                                                <Form.Control
                                                    type="number"
                                                    id={this.props.fields.payrollTemplateVariableJobTemplatesStandardPay.id}
                                                    placeholder={this.props.fields.payrollTemplateVariableJobTemplatesStandardPay.placeholder}
                                                    value={item.standardPay}
                                                    onChange={async (e) => {
                                                        await setFieldValue('payrollTemplateVariableJobTemplates.' + i + '.standardPay', e.target.value);
                                                    }}
                                                    isInvalid={!!(errors && errors.payrollTemplateVariableJobTemplates && errors.payrollTemplateVariableJobTemplates[i] && errors.payrollTemplateVariableJobTemplates[i].standardPay)}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={12} className={'mb-5'}>
                                    <Row className={'align-items-center'}>
                                        <Col sm={5}>
                                            <Form.Group>
                                                <Form.Label>Overtime hours pay</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={4}>
                                            <Form.Control
                                                type="number"
                                                id={this.props.fields.payrollTemplateVariableJobTemplatesOvertimePay.id}
                                                placeholder={this.props.fields.payrollTemplateVariableJobTemplatesOvertimePay.placeholder}
                                                value={item.overtimePay}
                                                onChange={async (e) => {
                                                    await setFieldValue('payrollTemplateVariableJobTemplates.' + i + '.overtimePay', e.target.value);
                                                }}
                                                isInvalid={!!(errors && errors.payrollTemplateVariableJobTemplates && errors.payrollTemplateVariableJobTemplates[i] && errors.payrollTemplateVariableJobTemplates[i].overtimePay)}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Accordion>
                    })}
                </>
            )}
        </FieldArray>
    }

    distanceJobTypeSection = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;
    
        return <Row>
            <Col xs={12} className={'pb-7'}>

                <Accordion
                    title={'DISTANCE'}
                    desc={'Set up driver\'s each trip pay according to distance blocks.'}
                    isOpen={this.state.isExpandedDistance}
                    onChange={(isOpen) => {
                        this.setState({
                            isExpandedDistance: isOpen
                        });
                    }}
                >
                    <FieldArray 
                        name={this.props.fields.payrollTemplateVariableRanges.id}
                        validateOnChange={false}
                    >
                        {({ remove, push }) => (
                            <>
                                {values.payrollTemplateVariableRanges.map((item, i) => {
                                    return <Row key={i} className={'align-items-center mb-5 mt-5'}>
                                        <Col sm={4} className={'pb-3 pb-sm-0'}>
                                            <Form.Group>
                                                <Form.Control
                                                    type="text"
                                                    id={this.props.fields.variableDistanceName.id}
                                                    placeholder={this.props.fields.variableDistanceName.placeholder}
                                                    value={item.variableDistanceName}
                                                    onChange={async (e) => {
                                                        await setFieldValue('payrollTemplateVariableRanges.' + i + '.variableDistanceName', e.target.value);

                                                        if(values.payrollTemplateVariableJobTemplates && values.payrollTemplateVariableJobTemplates.length > 0){
                                                            values.payrollTemplateVariableJobTemplates.forEach((jobTemplateItem, j) => {
                                                                setFieldValue('payrollTemplateVariableJobTemplates.' + j + '.payrollTemplateVariableJobTemplateRanges.' + i + '.variableDistanceName', e.target.value);
                                                            });
                                                        }
                                                    }}
                                                    isInvalid={!!(errors && errors.payrollTemplateVariableRanges && errors.payrollTemplateVariableRanges[i] && errors.payrollTemplateVariableRanges[i].variableDistanceName)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={true}>
                                            <InputGroup>
                                                <Form.Control
                                                    type="number"
                                                    id={this.props.fields.payrollTemplateVariableRangeDistanceFrom.id}
                                                    placeholder={this.props.fields.payrollTemplateVariableRangeDistanceFrom.placeholder}
                                                    value={item.distanceFrom}
                                                    onChange={async (e) => {
                                                        await setFieldValue('payrollTemplateVariableRanges.' + i + '.distanceFrom', e.target.value);
                                                    }}
                                                    isInvalid={!!(errors && errors.payrollTemplateVariableRanges && errors.payrollTemplateVariableRanges[i] && errors.payrollTemplateVariableRanges[i].distanceFrom)}
                                                />
                                                <InputGroup.Text>KM</InputGroup.Text>
                                                <InputGroup.Text className={'bg-white border-top-0 border-bottom-0'}>TO</InputGroup.Text>
                                                <Form.Control
                                                    type="number"
                                                    id={this.props.fields.payrollTemplateVariableRangeDistanceTo.id}
                                                    placeholder={this.props.fields.payrollTemplateVariableRangeDistanceTo.placeholder}
                                                    value={item.distanceTo}
                                                    onChange={async (e) => {
                                                        await setFieldValue('payrollTemplateVariableRanges.' + i + '.distanceTo', e.target.value);
                                                    }}
                                                    isInvalid={!!(errors && errors.payrollTemplateVariableRanges && errors.payrollTemplateVariableRanges[i] && errors.payrollTemplateVariableRanges[i].distanceTo)}
                                                />
                                                <InputGroup.Text>KM</InputGroup.Text>
                                            </InputGroup>
                                        </Col>
                                        <Col sm={'auto'} className={'text-center'}>
                                            <IconButton
                                                className={'ms-2'}
                                                onClick={() => {
                                                    remove(i);

                                                    if(values.payrollTemplateVariableJobTemplates && values.payrollTemplateVariableJobTemplates.length > 0){
                                                        values.payrollTemplateVariableJobTemplates.forEach((jobTemplateItem, j) => {
                                                            let items = values.payrollTemplateVariableJobTemplates[j].payrollTemplateVariableJobTemplateRanges;
                                                            items.splice(i, 1);
                                                            setFieldValue('payrollTemplateVariableJobTemplates.' + j + '.payrollTemplateVariableJobTemplateRanges', items);
                                                        });
                                                    }
                                                }}
                                            >
                                                <i className={'material-icons text-danger'}>delete_forever</i>
                                            </IconButton>
                                        </Col>
                                    </Row>
                                })}

                                <Row>
                                    <Col xs={12} className={'pt-5 pt-sm-0'}>
                                        <Button
                                            type={'button'}
                                            variant={'light'}
                                            className={'ps-3 pe-3'}
                                            style={{ border: '1px solid #ccc' }}
                                            onClick={() => {
                                                validateForm().then((err) => {
                                                    if(!!err.payrollTemplateVariableRanges){
                                                        Utils.toast('You must fill in the required fields', 'error');
                                                    } else {
                                                        push(this.props.initialValues.payrollTemplateVariableRanges[0]);
    
                                                        if(values.payrollTemplateVariableJobTemplates && values.payrollTemplateVariableJobTemplates.length > 0){
                                                            values.payrollTemplateVariableJobTemplates.forEach((jobTemplateItem, j) => {
                                                                let items = values.payrollTemplateVariableJobTemplates[j].payrollTemplateVariableJobTemplateRanges;
                                                                items.push({
                                                                    variableDistanceName: '',
                                                                    standardPay: '',
                                                                    overtimePay: '',
                                                                });
                                                                setFieldValue('payrollTemplateVariableJobTemplates.' + j + '.payrollTemplateVariableJobTemplateRanges', items);
                                                            });
                                                        }
                                                    }
                                                })
                                            }}
                                        >
                                            <i className={'material-icons pe-2'}>add</i>
                                            Add distance
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </FieldArray>
                </Accordion>
                
            </Col>
            <Col xs={12}>

                {<FieldArray 
                    name={this.props.fields.payrollTemplateVariableJobTemplates.id}
                    validateOnChange={false}
                >
                    {({ remove, push }) => (
                        <>
                            {values.payrollTemplateVariableJobTemplates.map((jobTemplateItem, j) => {
                                return <Accordion
                                    key={j}
                                    title={jobTemplateItem.jobTemplateName}
                                    desc={'Set up driver\'s each trip pay according to job type.'}
                                    isOpen={jobTemplateItem.isExpanded}
                                    onChange={async (isOpen) => {
                                        await setFieldValue('payrollTemplateVariableJobTemplates.' + j + '.isExpanded', !jobTemplateItem.isExpanded);
                                    }}
                                >
                                    <FieldArray 
                                        name={this.props.fields.payrollTemplateVariableJobTemplateRanges.id}
                                        validateOnChange={false}
                                    >
                                        {({ remove, push }) => (
                                            <>
                                                {jobTemplateItem.payrollTemplateVariableJobTemplateRanges.map((item, i) => {
                                                    return <Row key={i} className={'align-items-center mb-5 mt-5'}>
                                                        <Col sm={12} className={'mb-5'}>
                                                            <Row className={'align-items-center'}>
                                                                <Col sm={5}>
                                                                    <Form.Group>
                                                                        <Form.Label>Distance</Form.Label>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col sm={4}>
                                                                    <Form.Group>
                                                                        <Form.Label>{item.variableDistanceName}</Form.Label>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col sm={12} className={'mb-5'}>
                                                            <Row className={'align-items-center'}>
                                                                <Col sm={5}>
                                                                    <Form.Group>
                                                                        <Form.Label>Standard hours pay</Form.Label>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col sm={4}>
                                                                    <Form.Group>
                                                                        <Form.Control
                                                                            type="number"
                                                                            id={this.props.fields.payrollTemplateVariableJobTemplateRangesStandardPay.id}
                                                                            placeholder={this.props.fields.payrollTemplateVariableJobTemplateRangesStandardPay.placeholder}
                                                                            value={item.standardPay}
                                                                            onChange={async (e) => {
                                                                                await setFieldValue('payrollTemplateVariableJobTemplates.' + j + '.payrollTemplateVariableJobTemplateRanges.' + i + '.standardPay', e.target.value);
                                                                            }}
                                                                            isInvalid={!!(errors && errors.payrollTemplateVariableJobTemplates && errors.payrollTemplateVariableJobTemplates[j] && errors.payrollTemplateVariableJobTemplates[j].payrollTemplateVariableJobTemplateRanges && errors.payrollTemplateVariableJobTemplates[j].payrollTemplateVariableJobTemplateRanges[i] && errors.payrollTemplateVariableJobTemplates[j].payrollTemplateVariableJobTemplateRanges[i].standardPay)}
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col sm={12} className={'mb-5'}>
                                                            <Row className={'align-items-center'}>
                                                                <Col sm={5}>
                                                                    <Form.Group>
                                                                        <Form.Label>Overtime hours pay</Form.Label>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col sm={4}>
                                                                    <Form.Control
                                                                        type="number"
                                                                        id={this.props.fields.payrollTemplateVariableJobTemplateRangesOvertimePay.id}
                                                                        placeholder={this.props.fields.payrollTemplateVariableJobTemplateRangesOvertimePay.placeholder}
                                                                        value={item.overtimePay}
                                                                        onChange={async (e) => {
                                                                            await setFieldValue('payrollTemplateVariableJobTemplates.' + j + '.payrollTemplateVariableJobTemplateRanges.' + i + '.overtimePay', e.target.value);
                                                                        }}
                                                                        isInvalid={!!(errors && errors.payrollTemplateVariableJobTemplates && errors.payrollTemplateVariableJobTemplates[j] && errors.payrollTemplateVariableJobTemplates[j].payrollTemplateVariableJobTemplateRanges && errors.payrollTemplateVariableJobTemplates[j].payrollTemplateVariableJobTemplateRanges[i] && errors.payrollTemplateVariableJobTemplates[j].payrollTemplateVariableJobTemplateRanges[i].overtimePay)}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                })}
                                            </>
                                        )}
                                    </FieldArray>
                                </Accordion>
                            })}
                        </>
                    )}
                </FieldArray>}

            </Col>
        </Row>
    }
    /* END FUNCTIONS */


    body = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;
        
        return <Row>
            
            <Col xs={12}>
                <Form.Group as={Row} className={'mb-5'}>
                    <Form.Label column sm={3}>{this.props.fields.templateName.label}</Form.Label>
                    <Col sm={9}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.templateName.id}
                            placeholder={this.props.fields.templateName.placeholder}
                            value={values.templateName}
                            onChange={handleChange}
                            isInvalid={!!errors.templateName}
                        />
                        <Form.Control.Feedback type="invalid">{errors.templateName}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
            </Col>
            <Col xs={12}>
                <Form.Group as={Row} className={'mb-5'}>
                    <Form.Label column sm={3}>{this.props.fields.templateDescription.label}</Form.Label>
                    <Col sm={9}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.templateDescription.id}
                            placeholder={this.props.fields.templateDescription.placeholder}
                            value={values.templateDescription}
                            onChange={handleChange}
                            isInvalid={!!errors.templateDescription}
                        />
                        <Form.Control.Feedback type="invalid">{errors.templateDescription}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
            </Col>
            <Col xs={12}>
                <Form.Group as={Row} className={'mb-5'}>
                    <Form.Label column sm={3}>{this.props.fields.basicPay.label}</Form.Label>
                    <Col sm={9}>
                        <Form.Control
                            type="number"
                            id={this.props.fields.basicPay.id}
                            placeholder={this.props.fields.basicPay.placeholder}
                            value={values.basicPay}
                            onChange={handleChange}
                            isInvalid={!!errors.basicPay}
                        />
                        <Form.Control.Feedback type="invalid">{errors.basicPay}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
            </Col>
            <Col xs={12}>
                <Form.Group as={Row} className={'mb-5'}>
                    <Form.Label column sm={3}>{this.props.fields.payrollTemplateVariableTypeId.label}</Form.Label>
                    <Col sm={9}>
                        <Form.Control
                            as = {RSuiteSelectPicker}
                            isInvalid={!!errors.payrollTemplateVariableTypeId}

                            id={this.props.fields.payrollTemplateVariableTypeId.id}
                            placeholder={this.props.fields.payrollTemplateVariableTypeId.placeholder}
                            items={[
                                {
                                    value: PayrollTemplateVariableType.None,
                                    title: Utils.getPayrollTemplateVariableTypeNameByID(PayrollTemplateVariableType.None),
                                },
                                {
                                    value: PayrollTemplateVariableType.WorkTime,
                                    title: Utils.getPayrollTemplateVariableTypeNameByID(PayrollTemplateVariableType.WorkTime),
                                },
                                {
                                    value: PayrollTemplateVariableType.WorkTimeDistance,
                                    title: Utils.getPayrollTemplateVariableTypeNameByID(PayrollTemplateVariableType.WorkTimeDistance),
                                },
                                {
                                    value: PayrollTemplateVariableType.WorkTimeJobType,
                                    title: Utils.getPayrollTemplateVariableTypeNameByID(PayrollTemplateVariableType.WorkTimeJobType),
                                },
                                {
                                    value: PayrollTemplateVariableType.WorkTimeDistanceJobType,
                                    title: Utils.getPayrollTemplateVariableTypeNameByID(PayrollTemplateVariableType.WorkTimeDistanceJobType),
                                }
                            ]}
                            value={values.payrollTemplateVariableTypeId}
                            selectedName={values.payrollTemplateVariableTypeName}
                            isLoading={false}
                            searchable={false}
                            cleanable={true}
                            isDefault={true}
                            onSelect={async (value, item) => {
                                await setFieldValue('payrollTemplateVariableTypeId', value);
                                await setFieldValue('payrollTemplateVariableTypeName', item.title);

                                if(values.accountWorkingHourDays === null || values.accountWorkingHourDays.length <= 0){
                                    await setFieldValue('accountWorkingHourDays', this.state.accountWorkingHourDays);
                                }

                                if(values.payrollTemplateVariableJobTemplates === null || values.payrollTemplateVariableJobTemplates.length <= 0){
                                    this.callJobTemplateApi(async (arr, jobTemplateItems) => {
                                        await setFieldValue('customFormParams.jobTemplateItems', jobTemplateItems);
                                        await setFieldValue('payrollTemplateVariableJobTemplates', arr);
                                    })
                                }


                                let payrollTemplateVariableRanges = [{
                                    variableDistanceName: '',
                                    distanceFrom: '',
                                    distanceTo: '',
                                    standardPay: '',
                                    overtimePay: '',
                                }];
                                await setFieldValue('payrollTemplateVariableRanges', payrollTemplateVariableRanges);

                                let payrollTemplateVariableJobTemplateRanges = [];
                                if(payrollTemplateVariableRanges && payrollTemplateVariableRanges.length > 0){
                                    payrollTemplateVariableRanges.forEach((item, i) => {
                                        payrollTemplateVariableJobTemplateRanges.push({
                                            variableDistanceName: item.variableDistanceName,
                                            standardPay: '',
                                            overtimePay: '',
                                        });
                                    });
                                }

                                let payrollTemplateVariableJobTemplates = [];
                                let jobTemplateItems = values.customFormParams.jobTemplateItems;
                                if(jobTemplateItems && jobTemplateItems.length > 0){
                                    jobTemplateItems.forEach((item, i) => {
                                        payrollTemplateVariableJobTemplates.push({
                                            jobTemplateId: item.value,
                                            jobTemplateName: item.title,
                                            value: item.value,
                                            title: item.title,
                                            item: item.item,
                                            isExpanded: false,
                                            payrollTemplateVariableJobTemplateRanges: payrollTemplateVariableJobTemplateRanges,
                                            standardPay: '',
                                            overtimePay: '',
                                        });
                                    });
                                }
                                await setFieldValue('payrollTemplateVariableJobTemplates', payrollTemplateVariableJobTemplates);
                            }}
                            onClear={async () => {
                                await setFieldValue('payrollTemplateVariableTypeId', PayrollTemplateVariableType.None);
                                await setFieldValue('payrollTemplateVariableTypeName', 'None');


                                if(values.accountWorkingHourDays === null || values.accountWorkingHourDays.length <= 0){
                                    await setFieldValue('accountWorkingHourDays', this.state.accountWorkingHourDays);
                                }

                                if(values.payrollTemplateVariableJobTemplates === null || values.payrollTemplateVariableJobTemplates.length <= 0){
                                    this.callJobTemplateApi(async (arr, jobTemplateItems) => {
                                        await setFieldValue('customFormParams.jobTemplateItems', jobTemplateItems);
                                        await setFieldValue('payrollTemplateVariableJobTemplates', arr);
                                    })
                                }


                                let payrollTemplateVariableRanges = [{
                                    variableDistanceName: '',
                                    distanceFrom: '',
                                    distanceTo: '',
                                    standardPay: '',
                                    overtimePay: '',
                                }];
                                await setFieldValue('payrollTemplateVariableRanges', payrollTemplateVariableRanges);

                                
                                let payrollTemplateVariableJobTemplateRanges = [];
                                if(payrollTemplateVariableRanges && payrollTemplateVariableRanges.length > 0){
                                    payrollTemplateVariableRanges.forEach((item, i) => {
                                        payrollTemplateVariableJobTemplateRanges.push({
                                            variableDistanceName: item.variableDistanceName,
                                            standardPay: '',
                                            overtimePay: '',
                                        });
                                    });
                                }

                                let payrollTemplateVariableJobTemplates = [];
                                let jobTemplateItems = values.customFormParams.jobTemplateItems;
                                if(jobTemplateItems && jobTemplateItems.length > 0){
                                    jobTemplateItems.forEach((item, i) => {
                                        payrollTemplateVariableJobTemplates.push({
                                            jobTemplateId: item.value,
                                            jobTemplateName: item.title,
                                            value: item.value,
                                            title: item.title,
                                            item: item.item,
                                            isExpanded: false,
                                            payrollTemplateVariableJobTemplateRanges: payrollTemplateVariableJobTemplateRanges,
                                            standardPay: '',
                                            overtimePay: '',
                                        });
                                    });
                                }
                                await setFieldValue('payrollTemplateVariableJobTemplates', payrollTemplateVariableJobTemplates);
                            }}
                        />
                        <Form.Control.Feedback type="invalid">{errors.payrollTemplateVariableTypeId}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
            </Col>
            
            {(values.payrollTemplateVariableTypeId !== PayrollTemplateVariableType.None) && <>
                <Col xs={12}>
                    {this.workTimeSection()}
                </Col>
                
                {(values.payrollTemplateVariableTypeId === PayrollTemplateVariableType.WorkTimeDistance) && <Col xs={12}>
                    {this.distanceSection()}
                </Col>}

                {(values.payrollTemplateVariableTypeId === PayrollTemplateVariableType.WorkTimeJobType) && <Col xs={12}>
                    {this.jobTypeSection()}
                </Col>}

                {(values.payrollTemplateVariableTypeId === PayrollTemplateVariableType.WorkTimeDistanceJobType) && <Col xs={12}>
                    {this.distanceJobTypeSection()}
                </Col>}
            </>}
            
        </Row>
    }
    
    footer = () => {
        let {
            validateForm,
            values,
        } = this.props.formOptions;

        return <Row>
            <Col xs={'auto'}>
                <Button 
                    type={'submit'}
                    disabled={this.props.isLoading}
                >
                    SAVE
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
            <Col xs={'auto'}>
                <Button 
                    type={'button'}
                    variant={'secondary'}
                    disabled={this.props.isLoading}
                    onClick={() => {
                        Utils.showDrawer(this.props.drawer, false);
                    }}
                >
                    CANCEL
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
            
            
            {values.isEdit && <>
                <Col xs={12} className={'d-flex d-sm-none pb-3'}></Col>
                <Col xs={'auto'} sm={true} className={'text-end'}>
                    <Button 
                        type={'button'}
                        variant={'danger'}
                        disabled={this.props.isLoading}
                        onClick={() => {
                            if(this.props.onDelete){
                                this.props.onDelete(values);
                            }
                        }}
                    >
                        REMOVE
                        {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                    </Button>
                </Col>
                <Col xs={'auto'} md={'auto'}>
                    <Button 
                        type={'button'}
                        variant={'outline-secondary'}
                        disabled={this.props.isLoading}
                        onClick={() => {
                            validateForm().then((err) => {
                                if(_.isEmpty(err)){
                                    if(this.props.onCopy){
                                        this.props.onCopy(values);
                                    }
                                } else {
                                    Utils.toast('You must fill in the required fields', 'error');
                                }
                            })
                        }}
                    >
                        <i className={'material-icons'}>content_copy</i>
                        COPY
                        {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                    </Button>
                </Col>
            </>}

        </Row>
    }


    render() {
        return <DrawerContentLayout
            ref={this.bodyRef}
            isBlocking={this.props.isBlocking}
            body={this.body()}
            footer={this.footer()}
        />
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PayrollForm);
