import React from "react";
import _ from 'lodash';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import h2p from 'html2plaintext';
import CurrencyInput from 'react-currency-input-field';

import { 
    Row,
    Col,
    Form,
    Button,
    Spinner,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import { Formik } from 'formik';
import * as yup from 'yup';

import Utils from "../../../utils/utils";
import { RoundedFormCheck } from "../../../utils/styles";

import InputLayout from "../../../components/input/InputLayout";
import RSuiteCheckPicker from "../../../components/OLD/Checkbox/RSuiteCheckPicker.jsx";
import Tooltip from '../../../components/Tooltip/Tooltip';
import ContactPersonDialog from '../dialog/ContactPersonDialog';

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut, dispatchApiCallDelete } from '../../../../setup/redux/dispatch/actions'


const CustomRadio = styled(Form.Check)`
    input {
        margin-top: 10px;
    }
`;
const StyledOwerlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;

  background-color: rgba(0, 0, 0, 0.2);
  cursor: no-drop;

  justify-content: center;
  align-items: center;
  display: flex;
`;



const formFields = {
    customerSites: {
      id: 'customerSites',
      label: 'Mailing list',
      placeholder: ' ',
    },
    subject: {
      id: 'subject',
      label: 'Subject',
      placeholder: ' ',
    },
    fromName: {
        id: 'fromName',
        label: 'From',
        placeholder: ' ',
    },
    toName: {
        id: 'toName',
        label: 'To',
        placeholder: ' ',
    },
    recipientCc: {
        id: 'recipientCc',
        label: 'CC copy',
        placeholder: ' ',
        smallPlaceholder: ' ',
    },
    message: {
        id: 'message',
        label: 'Message',
        placeholder: Utils.placeholderRows(5),
    },
}
const formSchema = yup.object().shape({
    isEdit: yup.bool().oneOf([true, false]),

    creditLimitEmailTemplate: yup.object().shape({
        subject: yup.string().required().label(formFields.subject.label),
        fromName: yup.string().label(formFields.fromName.label),
        toName: yup.array().of(yup.string().min(1).label(formFields.toName.label)),
        recipientCc: yup.string().label(formFields.recipientCc.label),
        message: yup.string().required().label(formFields.message.label),
    }),
});

  
class MailTemplateCustomerSiteForm extends React.Component {
  
    constructor(props) {
        super(props);
    
        this.pageName = 'customer_site_mail_template';

        this.refForm = React.createRef();
        this.refLastSelectedInput = React.createRef();

        let id = (props.id) ? props.id : null;
        

        this.tagItems = [
            {
                value: '[[CustomerName]]',
                title: 'Customer Name',
            },
            {
                value: '[[CreditLimitAmount]]',
                title: 'Credit Limit',
            },
            {
                value: '[[CreditAmount]]',
                title: 'Credit Amount',
            },
            {
                value: '[[CreditPercent]]',
                title: 'Credit Percent',
            },
        ];

        this.state = this.prepareForm(id);
    }


    componentDidMount(){
        let id = (this.props.id) ? this.props.id : null;
        let idsArr = (id && id != '') ? id.split(',') : [];
        // let firstId = (idsArr && idsArr.length == 1) ? idsArr[0] : null;
        let firstId = (idsArr && idsArr.length > 0) ? idsArr[0] : null;
        if(firstId){
           this.callReadCustomerSiteApi(firstId, (details) => {
            if(details){
                let initialValues = this.state.initialValues;
                // initialValues.isAutoSendDoDoc = details.isAutoSendDoDoc;
                
                let contactEmails = (details.contactEmails && details.contactEmails.length > 0) ? details.contactEmails.map(x => {
                    if(x.customerContactPersonId){
                        return x.customerContactPerson.emailAddress
                    } else {
                        return x.emailAddress
                    }
                }) : [];

                if(contactEmails.length > 0){
                    let toName = (this.state.toNameItems && this.state.toNameItems.length > 0) ? this.state.toNameItems.filter(item => contactEmails.includes(item.title)).map(x => x.value) : [];
                    initialValues.creditLimitEmailTemplate.toName = toName;
                }

                this.setState(initialValues)
            }
           }); 
        }
    }

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            this.pageName + '-customersite_details', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoadingSingleCustomerSite: false,
                    
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(data);
                    }
                });
            }
        );
    }

    callReadCustomerSiteApi = (id = null, callback = null) => {
        this.setState({
            isLoadingSingleCustomerSite: true
        });

        this.props.dispatchApiCallGet(null, this.pageName + '-customersite_details', 'customersite/' + id, null, callback, () => {});
    }
    /* END API */


    /* FUNCTIONS */
    setLoading = (state = false) => {
        if(this.props.onLoading){
            this.props.onLoading(state);
        }
    }
    
    prepareForm = (id = null) => {
        let toNameItems = [];
        let customerSiteItems = [];
        let selectedCustomerSitesDisabled = [];
        let selectedCustomerSites = [];
        let customerSitesIsSelectAll = false;

        let message = '';
        let plainText = h2p(message);
        let editorState = Utils.createEditorState(message);

        let includePdfAttachmentDeliveryOrder = false;
        let includePdfAttachmentPhoto = false;
        let includePdfAttachmentWeightChit = false;
        let creditLimitEmailTemplate = {
            subject: '',
            fromName: '',
            toName: [],
            recipientCc: '',
            message: plainText,
        };

        if(this.props.item){
            var newForm = _.cloneDeep(this.props.item);

            let customerSites = (newForm && newForm.customerSites) ? newForm.customerSites : [];
            if(customerSites && customerSites.length > 0){
                customerSites.forEach((item, i) => {
                    customerSiteItems.push({
                        value: item.customerSiteId,
                        title: item.siteNameDisplay,
                        isAutoSendDoDoc: item.isAutoSendDoDoc,
                        item: item,
                    });
                    
                    selectedCustomerSitesDisabled.push(item.customerSiteId);

                    if(item.isAutoSendDoDoc){
                        selectedCustomerSites.push(item.customerSiteId);
                    }
                })
            }
            customerSitesIsSelectAll = (customerSiteItems.length === selectedCustomerSites.length);

            if(newForm.emailAddresses && newForm.emailAddresses != ''){
                toNameItems.push({
                    value: newForm.emailAddresses,
                    title: newForm.emailAddresses,
                    item: newForm.emailAddresses,
                });
            }

            let contactPersons = (newForm && newForm.contactPersons) ? newForm.contactPersons : [];
            if(contactPersons && contactPersons.length > 0){
                contactPersons.forEach((item, i) => {
                    if(item.emailAddress != '')
                        toNameItems.push({
                            value: item.customerContactPersonId,
                            title: item.emailAddress,
                            item: item,
                        });
                })
            }
            // let toName = (contactPersons && contactPersons.length > 0) ? contactPersons.map(x => x.customerContactPersonId) : [];
            let toName = (toNameItems && toNameItems.length > 0) ? toNameItems.filter(x => x.value == newForm.emailAddresses).map(x => x.value) : [];

            let emailTemplate = (newForm && newForm.emailTemplate) ? newForm.emailTemplate : null;
    
            if(emailTemplate){
                includePdfAttachmentDeliveryOrder = ((emailTemplate.includePdfAttachmentDeliveryOrder === false) || (emailTemplate.includePdfAttachmentDeliveryOrder === true)) ? emailTemplate.includePdfAttachmentDeliveryOrder : false;
                includePdfAttachmentPhoto = ((emailTemplate.includePdfAttachmentPhoto === false) || (emailTemplate.includePdfAttachmentPhoto === true)) ? emailTemplate.includePdfAttachmentPhoto : false;
                includePdfAttachmentWeightChit = ((emailTemplate.includePdfAttachmentWeightChit === false) || (emailTemplate.includePdfAttachmentWeightChit === true)) ? emailTemplate.includePdfAttachmentWeightChit : false;
            }

            message = (emailTemplate && emailTemplate.message && emailTemplate.message != '') ? emailTemplate.message : '';
            plainText = h2p(message);
            editorState = Utils.createEditorState(message);
            
            creditLimitEmailTemplate = {
                subject: (emailTemplate && emailTemplate.subject && emailTemplate.subject != '') ? emailTemplate.subject : '',
                fromName: (emailTemplate && emailTemplate.fromName && emailTemplate.fromName != '') ? emailTemplate.fromName : '',
                toName: toName,
                recipientCc: (emailTemplate && emailTemplate.recipientCc && emailTemplate.recipientCc != '') ? emailTemplate.recipientCc : '',
                message: plainText,
            };
        }

        return {
            id: id,
            
            toNameItems: toNameItems,
            customerSiteItems: customerSiteItems,
            selectedCustomerSitesDisabled: selectedCustomerSitesDisabled,

            initialValues: {
                isEdit: false,
                
                isLoadingSingleCustomerSite: false,
        
                includePdfAttachmentDeliveryOrder: includePdfAttachmentDeliveryOrder,
                includePdfAttachmentPhoto: includePdfAttachmentPhoto,
                includePdfAttachmentWeightChit: includePdfAttachmentWeightChit,

                isAutoSendDoDoc: customerSitesIsSelectAll,
                autoSendDoDocType: 0,
                autoSendDoDocHoursLater: null,
                
                creditLimitEmailTemplate: creditLimitEmailTemplate,
                customFormParams: {
                    messageHTML: message,
                    messagePlainText: plainText,
                    message: editorState,
                },
                
                selectedCustomerSites: selectedCustomerSites,
                customerSitesIsSelectAll: customerSitesIsSelectAll,
            },

            isCreateNewDialog: false,
            newDialogItem: null,
        }
    }
    prepareData = (form) => {
        var newForm = _.cloneDeep(form);

        // let includePdfAttachmentDeliveryOrder = ((newForm.includePdfAttachmentDeliveryOrder === false) || (newForm.includePdfAttachmentDeliveryOrder === true)) ? newForm.includePdfAttachmentDeliveryOrder : false;
        // let includePdfAttachmentPhoto = ((newForm.includePdfAttachmentPhoto === false) || (newForm.includePdfAttachmentPhoto === true)) ? newForm.includePdfAttachmentPhoto : false;
        // let includePdfAttachmentWeightChit = ((newForm.includePdfAttachmentWeightChit === false) || (newForm.includePdfAttachmentWeightChit === true)) ? newForm.includePdfAttachmentWeightChit : false;
        
        let isAutoSendDoDoc = ((newForm.isAutoSendDoDoc === false) || (newForm.isAutoSendDoDoc === true)) ? newForm.isAutoSendDoDoc : false;
        let autoSendDoDocType = (newForm && newForm.autoSendDoDocType && newForm.autoSendDoDocType != '') ? newForm.autoSendDoDocType : 0;
        let autoSendDoDocHoursLater = (newForm && newForm.autoSendDoDocHoursLater && newForm.autoSendDoDocHoursLater != '') ? newForm.autoSendDoDocHoursLater : null;

        let creditLimitEmailTemplate = (newForm && newForm.creditLimitEmailTemplate) ? newForm.creditLimitEmailTemplate : null;
        let toName = (creditLimitEmailTemplate && creditLimitEmailTemplate.toName && creditLimitEmailTemplate.toName.length > 0) ? creditLimitEmailTemplate.toName : [];
        let contactEmails = (toName && toName.length > 0) ? toName.map(x => {
            if(typeof x == 'string'){
                let itm = null;
                let filteredItem = this.state.toNameItems.filter(item => item.value == x);
                if(filteredItem && filteredItem.length > 0){
                    itm = filteredItem[0];
                }

                if(itm && itm.item){
                    //Email from customer site
                    return { 
                        emailAddress: x,
                    }
                } else {
                    //New email address
                    return { 
                        customerContactPerson: {
                            emailAddress: x
                        }
                    }
                }
            } else {
                //Email from contact persons
                let emailAddress = '';
                let filteredItem = this.state.toNameItems.filter(item => item.value == x);
                if(filteredItem && filteredItem.length > 0){
                    let itm = filteredItem[0];
                    emailAddress = itm.title;
                }
                return { 
                    customerContactPerson: {
                        emailAddress: emailAddress
                    }
                }
                // return { customerContactPersonId: x }
            }
        }) : [];
        
        let data = {
            ids: this.state.id,
            contactEmails: contactEmails,
            // contactEmails: [
            //     {
            //         "customerSiteContactEmailId": 0,
            //         "customerSiteId": 0,
            //         "customerContactPersonId": 0,
            //         "customerContactPerson": {
            //             "customerContactPersonId": 0,
            //             "customerId": 0,
            //             "firstName": "string",
            //             "emailAddress": "string",
            //             "workPhone": "string",
            //             "mobilePhone": "string",
            //             "department": "string",
            //             "isActive": true
            //         }
            //     }
            // ],
            isAutoSendDoDoc: isAutoSendDoDoc,
            autoSendDoDocType: autoSendDoDocType,
            autoSendDoDocHoursLater: autoSendDoDocHoursLater,
        };
        
        return data;
    }
    setSubmit = (form) => {
        if(this.props.onSubmit){
            this.props.onSubmit(form);
        }
    }
    /* END FUNCTIONS */


    /* FORM */
    form = (formOptions) => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = formOptions;
        
        return <>

            <InputLayout
                label={formFields.customerSites.label}
            >
                <div
                    style={{
                        padding: '0.75rem 1rem',
                        borderRadius: '0.475rem',
                        border: '1px solid #E4E6EF',
                        maxHeight: '130px',
                        overflow: 'auto',
                    }}
                >{(this.state.customerSiteItems && this.state.customerSiteItems.length > 0) && this.state.customerSiteItems.map((itm, i) => {
                    return <div key={'mailing_list_' + i} style={{ padding: '2px 0 2px 0' }}>
                        <i style={{ width: '23px' }} className={(itm.isAutoSendDoDoc ? 'fa fa-check' : 'far fa-square')}></i>
                        <span>{itm.title}</span>
                    </div>
                })}</div>
            </InputLayout>

            <InputLayout
                label={' '}
                inputProps={{
                    className: 'd-flex align-items-center'
                }}
            >
                <Form.Check
                    type={'checkbox'}
                    id={'do_not_send_email_1'}
                    label={'Select all'}
                    checked={values.isAutoSendDoDoc}
                    onChange={async (e) => {
                        await setFieldValue('isAutoSendDoDoc', e.target.checked);

                        let customerSiteItems = this.state.customerSiteItems;
                        if(customerSiteItems && customerSiteItems.length > 0){
                            customerSiteItems.forEach((itm, i) => {
                                itm.isAutoSendDoDoc = e.target.checked;
                            });
                        }
                        
                        this.setState({
                            customerSiteItems: customerSiteItems,
                        });
                    }}
                />
            </InputLayout>

            <InputLayout
                label={formFields.subject.label}
                required
            >
                <Form.Control
                    type="text"
                    id={formFields.subject.id}
                    placeholder={formFields.subject.placeholder}
                    value={values.creditLimitEmailTemplate.subject}
                    disabled={true}
                    onChange={async (e) => {
                      await setFieldValue('creditLimitEmailTemplate.subject', e.target.value);
                    }}
                    onFocus={(e) => {
                        this.refLastSelectedInput = 'subject';
                    }}
                    isInvalid={!!(errors && errors.creditLimitEmailTemplate && errors.creditLimitEmailTemplate.subject)}
                />
                <Form.Control.Feedback type="invalid">{(errors && errors.creditLimitEmailTemplate && errors.creditLimitEmailTemplate.subject) && errors.creditLimitEmailTemplate.subject}</Form.Control.Feedback>
            </InputLayout>

            <InputLayout
                label={formFields.fromName.label}
            >
                <Form.Control
                    type="text"
                    id={formFields.fromName.id}
                    placeholder={formFields.fromName.placeholder}
                    value={values.creditLimitEmailTemplate.fromName}
                    disabled={true}
                    onChange={async (e) => {
                      await setFieldValue('creditLimitEmailTemplate.fromName', e.target.value);
                    }}
                    onFocus={(e) => {
                        this.refLastSelectedInput = 'fromName';
                    }}
                    isInvalid={!!(errors && errors.creditLimitEmailTemplate && errors.creditLimitEmailTemplate.fromName)}
                />
                <Form.Control.Feedback type="invalid">{(errors && errors.creditLimitEmailTemplate && errors.creditLimitEmailTemplate.fromName) && errors.creditLimitEmailTemplate.fromName}</Form.Control.Feedback>
            </InputLayout>

            <InputLayout
                label={formFields.toName.label}
            >
                <Form.Control
                    as = {RSuiteCheckPicker}
                    isInvalid={!!(errors && errors.creditLimitEmailTemplate && errors.creditLimitEmailTemplate.toName)}

                    id={formFields.toName.id}
                    placeholder={formFields.toName.placeholder}
                    items={this.state.toNameItems}
                    value={values.creditLimitEmailTemplate.toName}
                    disabled={false}
                    isLoading={false}
                    searchable={false}
                    countable={false}
                    cleanable={true}
                    isDefault={true}
                    showSelectAll={false}
                    onChange={async (values, items) => {
                        await setFieldValue('creditLimitEmailTemplate.toName', values);
                    }}
                    onClear={async () => {
                        await setFieldValue('creditLimitEmailTemplate.toName', []);
                    }}
                    renderExtraFooter={() => {
                        return <Row className={'align-items-center'}>
                            <Col xs={12}>
                                <hr />
                            </Col>
                            <Col xs={12}>
                                <div className={'ps-2 pe-2 pb-3'}>
                                    <Button
                                    variant={"primary"}
                                    // disabled={(this.state.toNameItems.length > 4)}
                                    onClick={(e) => {
                                        this.setState({
                                            isCreateNewDialog: true,
                                            newDialogItem: null,
                                        });
                                    }}
                                    >
                                    <div className={'ps-2 pe-2 pt-1 pb-1 d-flex align-items-center'}>
                                        <i className={'material-icons'}>add_circle</i>
                                        <span xs={'auto'}>Create New</span>
                                    </div>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    }}
                />
{/* 
                <Form.Control
                    as = {RSuiteTagInput}
                    isInvalid={!!(errors && errors.creditLimitEmailTemplate && errors.creditLimitEmailTemplate.toName)}

                    id={formFields.toName.id}
                    placeholder={formFields.toName.placeholder}
                    value={values.creditLimitEmailTemplate.toName}
                    isDefault={true}
                    onChange={async (value) => {
                        let arr = [];
                        if(value && value.length > 0){
                            value.forEach(email => {
                                let pattern = Utils.emailValidation();
    
                                if(email.match(pattern) != null){
                                    arr.push(email);
                                }
                            })
                        }

                        await setFieldValue('creditLimitEmailTemplate.toName', arr);
                    }}
                    onClean={async () => {
                        await setFieldValue('creditLimitEmailTemplate.toName', []);
                    }}
                /> */}
                <Form.Control.Feedback type="invalid" style={{ display: ((errors && errors.creditLimitEmailTemplate && errors.creditLimitEmailTemplate.toName && errors.creditLimitEmailTemplate.toName.length > 0) ? 'block' : 'none') }}>{(errors && errors.creditLimitEmailTemplate && errors.creditLimitEmailTemplate.toName) && errors.creditLimitEmailTemplate.toName}</Form.Control.Feedback>
            </InputLayout>

            <InputLayout
                label={formFields.recipientCc.label}
            >
                <Form.Control
                    type="text"
                    id={formFields.recipientCc.id}
                    placeholder={formFields.recipientCc.placeholder}
                    value={values.creditLimitEmailTemplate.recipientCc}
                    disabled={true}
                    onChange={async (e) => {
                      await setFieldValue('creditLimitEmailTemplate.recipientCc', e.target.value);
                    }}
                    isInvalid={!!(errors && errors.creditLimitEmailTemplate && errors.creditLimitEmailTemplate.recipientCc)}
                />
                <Form.Control.Feedback type="invalid">{(errors && errors.creditLimitEmailTemplate && errors.creditLimitEmailTemplate.recipientCc) && errors.creditLimitEmailTemplate.recipientCc}</Form.Control.Feedback>
            </InputLayout>

            <InputLayout
                label={formFields.message.label}
            >
                <Form.Control
                    as="textarea"
                    rows={5}
                    id={formFields.message.id}
                    placeholder={formFields.message.placeholder}
                    disabled={true}
                    value={values.creditLimitEmailTemplate.message}
                    onChange={async (e) => {
                        Utils.limitRows(e.target.value, 5, async (value) => {
                            await setFieldValue('creditLimitEmailTemplate.message', value);
                        });
                    }}
                    isInvalid={!!(errors && errors.creditLimitEmailTemplate && errors.creditLimitEmailTemplate.message)}
                />

                {/* <Form.Control
                    as = {RTextEditor}
                    isInvalid={!!(errors && errors.creditLimitEmailTemplate && errors.creditLimitEmailTemplate.message)}
                    id={formFields.message.id}

                    editorState={values.customFormParams.message}
                    placeholder={formFields.message.placeholder}
                    disabled={true}
                    onChange={async (plainText, html, editorState) => {
                        await setFieldValue('creditLimitEmailTemplate.message', html);

                        await setFieldValue('customFormParams.messagePlainText', plainText);
                        await setFieldValue('customFormParams.messageHTML', html);
                        await setFieldValue('customFormParams.message', editorState);
                    }}
                    onFocus={(e) => {
                        this.refLastSelectedInput = 'message';
                    }}
                /> */}
                <Form.Control.Feedback type="invalid">{(errors && errors.creditLimitEmailTemplate && errors.creditLimitEmailTemplate.message) && errors.creditLimitEmailTemplate.message}</Form.Control.Feedback>
            </InputLayout>

        </>
    }

    formCustomField = (formOptions) => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = formOptions;

        return <Row className={'align-items-center mb-5 mt-10 pt-10'}>
            <Col sm={12} className={'mb-5'}>Custom Fields</Col>
            {this.tagItems.map((tag, i) => {
                return <CopyToClipboard 
                    key={i} 
                    text={tag.value}
                    onCopy={async () => {
                        if(this.refLastSelectedInput == 'subject'){
                            let prevValue = values.creditLimitEmailTemplate.subject;
                            let plainText = prevValue + ' ' + tag.value;
    
                            await setFieldValue('creditLimitEmailTemplate.subject', plainText);

                        } else if(this.refLastSelectedInput == 'fromName'){
                            let prevValue = values.creditLimitEmailTemplate.fromName;
                            let plainText = prevValue + ' ' + tag.value;
    
                            await setFieldValue('creditLimitEmailTemplate.fromName', plainText);

                        } else {
                            let messagePlainText = values.customFormParams.messagePlainText;
                            let plainText = messagePlainText + ' ' + tag.value;
                            let editorState = Utils.createEditorState(plainText);
    
                            await setFieldValue('creditLimitEmailTemplate.message', plainText);
                            await setFieldValue('customFormParams.messagePlainText', plainText);
                            await setFieldValue('customFormParams.messageHTML', plainText);
                            await setFieldValue('customFormParams.message', editorState);
                        }
                    }}
                >
                    <Col sm={12} className={'cursor-pointer mb-5'}>
                        <Tooltip title="Copy Tag">
                            <div className={'d-flex align-items-center'} color={'#7F7F7F'}>
                                <i className={'material-icons'} style={{ color: '#7F7F7F', marginRight: '10px', fontSize: '18px' }}>content_copy</i>
                                {tag.title}
                            </div>
                        </Tooltip>
                    </Col>
                </CopyToClipboard>
            })}
        </Row>
    }
    
    formAttachments = (formOptions) => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = formOptions;

        return <Row className={'align-items-center mb-5 mt-10 pt-10'}>
            <Col sm={12} className={'mb-5'}>
                <InputLayout
                    label={'Attachments'}
                >
                    <RoundedFormCheck
                        className={'pb-3'}
                        type={'checkbox'}
                        id={'Delivery_Order_1'}
                        disabled={true}
                        label={<>
                            <span>Delivery Order </span>
                            {/* <a href={'/'}
                                className={'link-default-underline'}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                }}
                            >
                                Setup <span className={'material-icons'} style={{ fontSize: '13px', position: 'relative', top: '3px' }}>open_in_new</span>
                            </a> */}
                        </>}
                        checked={values.includePdfAttachmentDeliveryOrder}
                        onChange={async (e) => {
                            await setFieldValue('includePdfAttachmentDeliveryOrder', e.target.checked);
                        }}
                    />
                    <RoundedFormCheck
                        className={'pb-3'}
                        type={'checkbox'}
                        id={'Photos_2'}
                        disabled={true}
                        label={<>
                            <span>Photos </span>
                        </>}
                        checked={values.includePdfAttachmentPhoto}
                        onChange={async (e) => {
                            await setFieldValue('includePdfAttachmentPhoto', e.target.checked);
                        }}
                    />
                    <RoundedFormCheck
                        className={'pb-3'}
                        type={'checkbox'}
                        id={'Weigh_Chit_3'}
                        disabled={true}
                        label={<>
                            <span>Weigh Chit </span>
                            {/* <a href={'/'}
                                className={'link-default-underline'}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                }}
                            >
                                Setup <span className={'material-icons'} style={{ fontSize: '13px', position: 'relative', top: '3px' }}>open_in_new</span>
                            </a> */}
                        </>}
                        checked={values.includePdfAttachmentWeightChit}
                        onChange={async (e) => {
                            await setFieldValue('includePdfAttachmentWeightChit', e.target.checked);
                        }}
                    />
                </InputLayout>
            </Col>
        </Row>
    }

    formOptions = (formOptions) => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = formOptions;

        return  <Row className={'align-items-center mb-5 mt-10 pt-10'}>
            <Col sm={12}>
                <Form.Group>
                    <Form.Label className={'mb-5'}>Options</Form.Label>
                    <Form.Check
                        className={'mb-5'}
                        type={'radio'}
                        id={'sendEmailOnceJobIsCompleted_1'}
                        label={'Send email once job is completed'}
                        checked={values.autoSendDoDocType == '0'}
                        onChange={async (e) => {
                            await setFieldValue('autoSendDoDocType', '0');
                            await setFieldValue('autoSendDoDocHoursLater', null);
                        }}
                    />
                    <CustomRadio
                        className={'mb-5'}
                        type={'radio'}
                        id={'sendEmailOnceJobIsCompleted_2'}
                        label={<>
                            <span>Delay send for </span>
                            <div style={{ position: 'inherit', display: 'inherit' }}>
                                <Form.Control
                                    style={{ width: '70px' }}
                                    className={'mt-0'}
                                    as = {CurrencyInput}
                                    isInvalid={!!(errors && errors.autoSendDoDocHoursLater)}
                                    
                                    allowDecimals={true}
                                    allowNegativeValue={false}
                                    disableGroupSeparators={true}
                                    disabled={values.autoSendDoDocType == '0'}

                                    value={values.autoSendDoDocHoursLater}
                                    onValueChange={async (value) => {
                                        await setFieldValue('autoSendDoDocHoursLater', value);
                                    }}
                                    onBlur={async (e) => {
                                        if(values.autoSendDoDocHoursLater < 1){
                                            await setFieldValue('autoSendDoDocHoursLater', null);
                                        }
                                    }}
                                />
                            </div>
                            <span> hour(s)</span>
                        </>}
                        checked={values.autoSendDoDocType == '1'}
                        onChange={async (e) => {
                            await setFieldValue('autoSendDoDocType', '1');
                        }}
                    />
                </Form.Group>
            </Col>
        </Row>
    }
    /* END FORM */


    setNewDialog = (formOptions) => {
        return <ContactPersonDialog 
          show={this.state.isCreateNewDialog}
          isLoading={false}
          item={this.state.newDialogItem}
          onHide={() => {
            this.setState({
              isCreateNewDialog: false,
              newDialogItem: null,
            });
          }}
          onSave={(value, item) => {
            let {
                setFieldValue,
                values,
            } = formOptions;

            let toNameItems = this.state.toNameItems;
            
            let hasDuplicated = Utils.hasDuplicated(toNameItems, ['title'], [value]);
            if(!hasDuplicated){
                if(toNameItems.length < 5){
                    toNameItems.push({
                        value: value,
                        title: value,
                        item: null,
                    });
        
                    this.setState({
                        toNameItems: toNameItems,
                        isCreateNewDialog: false,
                        newDialogItem: null,
                    }, async () => {
                        let emails = values.creditLimitEmailTemplate.toName;
                        emails.push(value);
                        await setFieldValue('creditLimitEmailTemplate.toName', emails);
                    });
                } else {
                    Utils.toast('You can enter a maximum of 5 email addresses', 'error');
                }
            } else {
                Utils.toast('The entered email address already exists', 'error');
            }
          }}
        />
    }


    render() {
        return <Formik
            innerRef={this.refForm}
            validationSchema={formSchema}
            initialValues={this.state.initialValues}
            enableReinitialize={true}
            validateOnMount={false}
            validateOnChange={true}
            onSubmit={(form, e) => {
                this.setLoading(true);
                let data = this.prepareData(form);
                this.setSubmit(data);
            }}
        >
            {(formOptions) => {
                return <Form className='w-100 p-5' noValidate autoComplete="off" onSubmit={formOptions.handleSubmit} onKeyDown={(keyEvent) => {
                    // if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                    //     keyEvent.preventDefault();
                    // }
                }}>
                    <Row>
                        <Col xs={12} md={10} lg={8} className={'ps-2 pe-2 ps-md-7 pe-md-7'}>{this.form(formOptions)}</Col>
                        <Col xs={12} md={2} lg={2}></Col>
                        {/* <Col xs={12} md={2} lg={2}>{this.formCustomField(formOptions)}</Col> */}
                        <Col xs={12}>{this.formAttachments(formOptions)}</Col>
                        <Col xs={12}><hr /></Col>
                        <Col xs={12}>{this.formOptions(formOptions)}</Col>
                    </Row>
                    
                    {this.setNewDialog(formOptions)}
                    
                    {this.state.isLoadingSingleCustomerSite && <StyledOwerlay><Spinner animation="border" /></StyledOwerlay>}
                </Form>
            }}
        </Formik>
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  dispatchApiCallPost,
  dispatchApiCallPut,
  dispatchApiCallDelete,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(MailTemplateCustomerSiteForm);
