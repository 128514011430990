import React from 'react';

import { 
    Modal,
    Row,
    Col,
    Button,
    Form,
} from "react-bootstrap-v5";

// import Utils from '../../../../utils/utils';

import { StyledCheck, StyledChecked, StyledUnChecked, StyledFormText } from '../style/styles';

import { useDispatch, useSelector } from "react-redux";
import {RootState} from '../../../../../setup'
import payrollBetaCopyGeofence from '../redux/payrollBetaCopyGeofence';



const CopyGeofence = (props: any) => {
    const dispatch = useDispatch();
    const { show, geofenceIndex, accountMapGeofenceGroupId, geofence, checkedGeofence } = useSelector((state: RootState) => state.payrollBetaCopyGeofence);
    

    const hide = () => {
        dispatch(payrollBetaCopyGeofence.hide());
    }
    const onSave = () => {
        dispatch(payrollBetaCopyGeofence.hide());

        if(props.onSave){
            props.onSave(accountMapGeofenceGroupId, checkedGeofence, geofenceIndex);
        }
    }


    return <Modal
        show={show}
        onHide={hide}
        backdrop={"static"}
        // fullscreen={true}
        scrollable={true}
        keyboard={false}
        enforceFocus={false}
    >
        <Modal.Header closeButton={true}>Where do you wish to copy to?</Modal.Header>

        <Modal.Body style={{ maxHeight: '217px' }}>
            {
                (geofence && geofence.length > 0)
                ?
                <Row>
                    {geofence.filter(x => x.accountMapGeofenceGroupId !== accountMapGeofenceGroupId).map((item: any, i: number) => {
                        return <Col xs={12} className={'mb-2'} key={'jobTypes_' + i + '_' + item.accountMapGeofenceGroupId + '_key'}>
                            <StyledCheck id={'jobTypes_' + i + '_' + item.accountMapGeofenceGroupId + '_cb'}>
                                <Form.Check.Input
                                    type={'checkbox'}
                                    className={'d-none'}
                                    defaultChecked={checkedGeofence.find((x: any) => x == item.accountMapGeofenceGroupId) ? true : false}
                                    onClick={(e: any) => {
                                        dispatch(payrollBetaCopyGeofence.setCheckedGeofence(item.accountMapGeofenceGroupId));
                                    }}
                                />
                                <Form.Check.Label>
                                    <Row className={'align-items-center g-2'}>
                                        <Col xs={'auto'}>{checkedGeofence.find((x: any) => x == item.accountMapGeofenceGroupId) ? <StyledChecked className={'fas fa-check'} /> : <StyledUnChecked className={'fas fa-check'} />}</Col>
                                        <Col xs={'auto'}><StyledFormText>{item.groupName}</StyledFormText></Col>
                                    </Row>
                                </Form.Check.Label>
                            </StyledCheck>
                        </Col>
                    })}
                </Row>
                :
                <>No geofence</>
            }
        </Modal.Body>

        <Modal.Footer style={{ justifyContent: 'flex-start' }}>
            <Row className={'m-0 align-items-center'}>
                <Col xs={'auto'}>
                    <Button 
                        type={'button'}
                        variant={'primary'}
                        size={'sm'}
                        onClick={onSave}
                    >
                        Save
                    </Button>
                </Col>
                <Col xs={'auto'}>
                    <Button 
                        type={'button'}
                        variant={'secondary'}
                        size={'sm'}
                        onClick={hide}
                    >
                        Cancel
                    </Button>
                </Col>
            </Row>
        </Modal.Footer>
    </Modal>
}


export default CopyGeofence;
