import React from 'react';

import { Row, Col, Collapse, Spinner } from 'react-bootstrap-v5';

import { useDispatch, useSelector } from "react-redux";
import {RootState} from '../../../../../setup'

import { StyledElementBlockerLoading, StyledElementOverlay } from "../../../../utils/styles";

import { StyledBasicCard, StyledBasicShadow, StyledTitle, StyledExpandIcon, StyledHistoryLink } from '../style/driversDetailsStyles';


const Accordion = props => {
    const { months } = useSelector((state: RootState) => state.driversDetails);

    const [isOpen, setIsOpen] = React.useState(((props.isOpen === false) || (props.isOpen === true)) ? props.isOpen : false);
    const [isLoading, setIsLoading] = React.useState(((props.isLoading === false) || (props.isLoading === true)) ? props.isLoading : false);

    React.useEffect(() => {
        setIsOpen(((props.isOpen === false) || (props.isOpen === true)) ? props.isOpen : false);
    }, [props.isOpen]);

    React.useEffect(() => {
        setIsLoading(((props.isLoading === false) || (props.isLoading === true)) ? props.isLoading : false);
    }, [props.isLoading]);

    return <StyledBasicShadow className={'d-flex align-items-center'}>
        <StyledBasicCard xs={12} active={isOpen ? 'true' : ''}>
            <Row className={'d-flex align-items-center'}>
                <Col xs={true}
                    className={'cursor-pointer d-flex align-items-center'}
                    onClick={() => {
                        if(props.onChange){
                            props.onChange(!isOpen);
                        }
                    }}
                >
                    <StyledTitle>{props.title}</StyledTitle>
                    {(isOpen && ((months[props.index].details))) && <StyledHistoryLink
                        href={'/'}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            if(props && props.onClickHistory){
                                props.onClickHistory();
                            }
                        }}
                    >
                        <i className={'material-icons'}>history</i>
                    </StyledHistoryLink>}
                </Col>
                <Col xs={'auto'}
                    className={'cursor-pointer d-flex align-items-center'}
                    onClick={() => {
                        if(props.onChange){
                            props.onChange(!isOpen);
                        }
                    }}
                >
                    <StyledExpandIcon className={'material-icons expand-icon'}>{isOpen ? 'expand_less' : 'expand_more'}</StyledExpandIcon>
                </Col>
                {/* {isOpen && <Col xs={12}><hr style={{ opacity: '1', borderColor: '#EFEFEF' }} /></Col>} */}
            </Row>
            <Collapse in={isOpen}>
                <Row>
                    <Col xs={12} className={'mt-0'}>
                        {props.children}
                    </Col>
                </Row>
            </Collapse>
            

            {(isLoading) && <StyledElementBlockerLoading>
                <StyledElementOverlay />
                <Spinner animation="border" variant="primary" />
            </StyledElementBlockerLoading>}
        </StyledBasicCard>
    </StyledBasicShadow>
}


export default Accordion;