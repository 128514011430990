import React from 'react';
import CurrencyInput from 'react-currency-input-field';
import InputMask from 'react-input-mask';
import moment from 'moment';
import _ from 'lodash';

import { 
    Row,
    Col,
    Form,
    Tab,
} from "react-bootstrap-v5";

import Utils from '../../../../utils/utils';

import RSuiteDatePicker from '../../../../components/OLD/Datepicker/RSuiteDatePicker';
import Tooltip from '../../../../components/Tooltip/Tooltip';

import { useDispatch, useSelector } from "react-redux";
import {RootState} from '../../../../../setup'
import payrollBetaForm, { fields, hasSpecialDate, SelectedJobTypeStruct, SelectedGeofenceJobTypeStruct, hasSelectedDateTime, copyJobType, copyGeofenceJobType, getDayOfWeekLabel } from '../redux/payrollBetaForm';
import payrollBetaCopyJobTypes from '../redux/payrollBetaCopyJobTypes';
import payrollBetaCopyGeofence from '../redux/payrollBetaCopyGeofence';

import { useFormikContext } from '../PayrollBetaForm';
import CopyJobType from './CopyJobType';
import CopyGeofence from './CopyGeofence';

import { StyledColLeft, StyledColRight, StyledPay, StyledMenuRow, StyleMenuWrapperCol, StyledDatePicker, StyledStepHr, StyledFormLabel, StyledFormText, StyledSecondaryRadio, StyledTabs } from '../style/styles';


const GeofenceJobType = (props: any) => {
    const { values, errors, setFieldValue, validateForm } = useFormikContext();

    const dispatch = useDispatch();
    // const { step3bRadio } = useSelector((state: RootState) => state.payrollBetaLayout);
    const { jobTypes } = useSelector((state: RootState) => state.payrollBetaForm);

    
    const getSelected = (i: number) => {
        if(values.selectedGeofenceJobType && values.selectedGeofenceJobType.length > 0 && values.selectedGeofenceJobType[i]){
            let index = values.selectedGeofenceJobType[i].jobTemplates.findIndex((x: SelectedJobTypeStruct) => x.isSelected === true);
            if(index > -1){
                return {
                    i: i,
                    index: index,
                    item: values.selectedGeofenceJobType[i].jobTemplates[index],
                };
            }
        }

        return {
            i: i,
            index: -1,
            item: null,
        };
    }

    
    const workTimeForm = (i: number) => {
        let selected = getSelected(i);
        if(selected && selected.item){
            return <Row>
                <Col xs={12} className={'mb-3'}>
                    <Row>
                        <Col xs={2} md={2} lg={2} xl={1}>
                            <div className={'position-absolute'}><StyledFormLabel>WORK DAYS</StyledFormLabel></div>
                        </Col>
                        <Col xs={12} md={10} lg={10} xl={11}>
                            <Row className={'align-items-center mb-2'}>
                                <Col xs={12} md={8}>&nbsp;</Col>
                                <Col xs={12} md={4} className={'d-none d-md-block'}><StyledPay>Pay</StyledPay></Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12}>
                    {(selected.item.workingDays && selected.item.workingDays.length > 0) && <>
                        {selected.item.workingDays.map((item: any, j: number) => {
                            if(item.isEnabled){
                                return <Row key={'workTimes_' + i + '_' + j} className={'mb-3'}>
                                    <Col xs={2} md={2} lg={2} xl={1} className={'mt-0 mt-md-3 mb-2 mb-md-0'}><StyledFormText>{getDayOfWeekLabel(item.day)}</StyledFormText></Col>
                                    <Col xs={12} md={10} lg={10} xl={11}>
                                        {item.times.map((subItem: any, k: number) => {
                                            return <Row key={'work_times_' + '_' + selected.index + '_' + i + '_'  + j + '_day_' + k} className={'align-items-center mb-2'}>
                                                <Col xs={12} md={8} className={'mb-3 mb-md-0'}>
                                                    <Row className={'align-items-center'}>
                                                        <Col xs={12} sm={true} className={'custom-rsuite-time-picker-component'}>
                                                            <Form.Control
                                                                as = {InputMask}
                                                                mask={'99:99'}
                                                                size={'sm'}
                                                                readOnly={true}
                                                                tabIndex={-1}

                                                                id={fields.workTimes.id + '_' + selected.index + '_' + i + '_' + j + '_' + fields.workTimesTimeFrom.id + '_' + k + '_timeFrom'}
                                                                
                                                                placeholder={fields.workTimesTimeFrom.placeholder}
                                                                value={subItem.timeFrom}
                                                                onChange={(e: any) => {
                                                                    setFieldValue('selectedGeofenceJobType.' + i + '.jobTemplates.' + selected.index + '.workingDays.' + j + '.times.' + k + '.timeFrom', e.target.value);
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col xs={'auto'} className={'d-none d-lg-block px-1'}><StyledFormText>TO</StyledFormText></Col>
                                                        <Col xs={12} sm={true} className={'custom-rsuite-time-picker-component'}>
                                                            <Form.Control
                                                                as = {InputMask}
                                                                mask={'99:99'}
                                                                size={'sm'}
                                                                readOnly={true}
                                                                tabIndex={-1}

                                                                id={fields.workTimes.id + '_' + selected.index + '_' + i + '_' + j + '_' + fields.workTimesTimeFrom.id + '_' + k + '_timeTo'}
                                
                                                                placeholder={fields.workTimesTimeTo.placeholder}
                                                                value={subItem.timeTo}
                                                                onChange={(e: any) => {
                                                                    setFieldValue('selectedGeofenceJobType.' + i + '.jobTemplates.' + selected.index + '.workingDays.' + j + '.times.' + k + '.timeTo', e.target.value);
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={12} md={4}>
                                                    <StyledFormLabel className={'d-block d-md-none'}><StyledPay>{fields.workTimesPay.label}</StyledPay></StyledFormLabel>
                                                    <Form.Control
                                                        as = {CurrencyInput}
                                                        allowDecimals={true}
                                                        allowNegativeValue={false}
                                                        disableGroupSeparators={true}
                                                        prefix={Utils.getCurrency()}
                                                        size={'sm'}
                                                        tabIndex={i*j*k}
                                                        style={{ borderColor: (errors.selectedGeofenceJobType && errors.selectedGeofenceJobType[i] && errors.selectedGeofenceJobType[i].jobTemplates && errors.selectedGeofenceJobType[i].jobTemplates.length > 0 && errors.selectedGeofenceJobType[i].jobTemplates[selected.index] && errors.selectedGeofenceJobType[i].jobTemplates[selected.index].workingDays && errors.selectedGeofenceJobType[i].jobTemplates[selected.index].workingDays[j] && errors.selectedGeofenceJobType[i].jobTemplates[selected.index].workingDays[j].times && errors.selectedGeofenceJobType[i].jobTemplates[selected.index].workingDays[j].times[k] && errors.selectedGeofenceJobType[i].jobTemplates[selected.index].workingDays[j].times[k].standardPay) ? '#FF0090' : '#0066FF' }}

                                                        id={fields.workTimes.id + '_' + i + '_' + j + '_' + fields.workTimesTimeFrom.id + '_' + k + '_pay'}
                                                        isInvalid={!!(errors.selectedGeofenceJobType && errors.selectedGeofenceJobType[i] && errors.selectedGeofenceJobType[i].jobTemplates && errors.selectedGeofenceJobType[i].jobTemplates.length > 0 && errors.selectedGeofenceJobType[i].jobTemplates[selected.index] && errors.selectedGeofenceJobType[i].jobTemplates[selected.index].workingDays && errors.selectedGeofenceJobType[i].jobTemplates[selected.index].workingDays[j] && errors.selectedGeofenceJobType[i].jobTemplates[selected.index].workingDays[j].times && errors.selectedGeofenceJobType[i].jobTemplates[selected.index].workingDays[j].times[k] && errors.selectedGeofenceJobType[i].jobTemplates[selected.index].workingDays[j].times[k].standardPay)}

                                                        placeholder={fields.workTimesPay.placeholder}
                                                        value={Utils.isNumber(subItem.standardPay) ? subItem.standardPay : ''}
                                                        onValueChange={(value: any) => {
                                                            setFieldValue('selectedGeofenceJobType.' + i + '.jobTemplates.' + selected.index + '.workingDays.' + j + '.times.' + k + '.standardPay', value);
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        })}
                                    </Col>
                                </Row>
                            }
                        })}
                    </>}
                </Col>
            </Row>
        }
    }

    const specialDatesForm = (i: number) => {
        let selected = getSelected(i);
        if(selected && selected.item){
            return <Row>
                <Col xs={12} className={'mb-3'}>
                    <Row>
                        <Col xs={12} md={4} lg={3} xl={3}>
                            <div className={'position-absolute'}><StyledFormLabel>SPECIAL DATES</StyledFormLabel></div>
                        </Col>
                        <Col xs={12} md={8} lg={9} xl={9}>
                            <Row className={'align-items-center mb-2'}>
                                <Col xs={12} md={8}>&nbsp;</Col>
                                <Col xs={12} md={4} className={'d-none d-md-block'}><StyledPay>Pay</StyledPay></Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12}>
                    {(selected.item.specialDays && selected.item.specialDays.length > 0) && <>
                        {selected.item.specialDays.map((item: any, j: number) => {
                            return <Row key={'specialDays_' + i + '_' + j} className={'mb-3'}>
                                <Col xs={12} md={4} lg={3} xl={3}>
                                    <StyledDatePicker
                                        as = {RSuiteDatePicker}
                                        isInvalid={!!(errors.specialDays && errors.specialDays.length > 0 && errors.specialDays[i] && errors.specialDays[i].date)}
                                        className={'form-control'}
                                        tabIndex={1000+i}
                                        
                                        placeholder={fields.specialDatesDate.placeholder}
                                        id={fields.specialDates.id + '_' + i + '_date'}
                                        value={item.date}
                                        icon={<></>}
                                        placement={'bottomStart'}
                                        cleanable={true}
                                        isDefault={true}
                                        readOnly={true}
                                        oneTap={true}
                                        // disabledDate={(date: any) => {
                                        //     return (moment(date).isAfter(moment().subtract(1, 'days'))) ? false : true;
                                        // }}
                                        onChange={(value: any) => {
                                            setFieldValue('selectedGeofenceJobType.' + i + '.jobTemplates.' + selected.index + '.specialDays.' + i + '.date', value);
                                        }}
                                    />
                                </Col>
                                <Col xs={12} md={8} lg={9} xl={9}>
                                    {item.times.map((subItem: any, k: number) => {
                                        return <Row key={'work_times_' + '_' + selected.index + '_' + i + '_'  + j + '_day_' + k} className={'align-items-center mb-2'}>
                                            <Col xs={12} md={8} className={'mb-3 mb-md-0'}>
                                                <Row className={'align-items-center'}>
                                                    <Col xs={12} sm={true} className={'custom-rsuite-time-picker-component'}>
                                                        <Form.Control
                                                            as = {InputMask}
                                                            mask={'99:99'}
                                                            size={'sm'}
                                                            readOnly={true}
                                                            tabIndex={-1}

                                                            id={fields.workTimes.id + '_' + selected.index + '_' + i + '_' + j + '_' + fields.workTimesTimeFrom.id + '_' + k + '_timeFrom'}
                                                            
                                                            placeholder={fields.workTimesTimeFrom.placeholder}
                                                            value={subItem.timeFrom}
                                                            onChange={(e: any) => {
                                                                setFieldValue('selectedGeofenceJobType.' + i + '.jobTemplates.' + selected.index + '.specialDays.' + j + '.times.' + k + '.timeFrom', e.target.value);
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col xs={'auto'} className={'d-none d-lg-block px-1'}><StyledFormText>TO</StyledFormText></Col>
                                                    <Col xs={12} sm={true} className={'custom-rsuite-time-picker-component'}>
                                                        <Form.Control
                                                            as = {InputMask}
                                                            mask={'99:99'}
                                                            size={'sm'}
                                                            readOnly={true}
                                                            tabIndex={-1}

                                                            id={fields.workTimes.id + '_' + selected.index + '_' + i + '_' + j + '_' + fields.workTimesTimeFrom.id + '_' + k + '_timeTo'}
                            
                                                            placeholder={fields.workTimesTimeTo.placeholder}
                                                            value={subItem.timeTo}
                                                            onChange={(e: any) => {
                                                                setFieldValue('selectedGeofenceJobType.' + i + '.jobTemplates.' + selected.index + '.specialDays.' + j + '.times.' + k + '.timeTo', e.target.value);
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <StyledFormLabel className={'d-block d-md-none'}><StyledPay>{fields.workTimesPay.label}</StyledPay></StyledFormLabel>
                                                <Form.Control
                                                    as = {CurrencyInput}
                                                    allowDecimals={true}
                                                    allowNegativeValue={false}
                                                    disableGroupSeparators={true}
                                                    prefix={Utils.getCurrency()}
                                                    size={'sm'}
                                                    tabIndex={i*j*k}
                                                    style={{ borderColor: (errors.selectedGeofenceJobType && errors.selectedGeofenceJobType[i] && errors.selectedGeofenceJobType[i].jobTemplates && errors.selectedGeofenceJobType[i].jobTemplates.length > 0 && errors.selectedGeofenceJobType[i].jobTemplates[selected.index] && errors.selectedGeofenceJobType[i].jobTemplates[selected.index].specialDays && errors.selectedGeofenceJobType[i].jobTemplates[selected.index].specialDays[j] && errors.selectedGeofenceJobType[i].jobTemplates[selected.index].specialDays[j].times && errors.selectedGeofenceJobType[i].jobTemplates[selected.index].specialDays[j].times[k] && errors.selectedGeofenceJobType[i].jobTemplates[selected.index].specialDays[j].times[k].standardPay) ? '#FF0090' : '#0066FF' }}

                                                    id={fields.workTimes.id + '_' + i + '_' + j + '_' + fields.workTimesTimeFrom.id + '_' + k + '_pay'}
                                                    isInvalid={!!(errors.selectedGeofenceJobType && errors.selectedGeofenceJobType[i] && errors.selectedGeofenceJobType[i].jobTemplates && errors.selectedGeofenceJobType[i].jobTemplates.length > 0 && errors.selectedGeofenceJobType[i].jobTemplates[selected.index] && errors.selectedGeofenceJobType[i].jobTemplates[selected.index].specialDays && errors.selectedGeofenceJobType[i].jobTemplates[selected.index].specialDays[j] && errors.selectedGeofenceJobType[i].jobTemplates[selected.index].specialDays[j].times && errors.selectedGeofenceJobType[i].jobTemplates[selected.index].specialDays[j].times[k] && errors.selectedGeofenceJobType[i].jobTemplates[selected.index].specialDays[j].times[k].standardPay)}

                                                    placeholder={fields.workTimesPay.placeholder}
                                                    value={Utils.isNumber(subItem.standardPay) ? subItem.standardPay : ''}
                                                    onValueChange={(value: any) => {
                                                        setFieldValue('selectedGeofenceJobType.' + i + '.jobTemplates.' + selected.index + '.specialDays.' + j + '.times.' + k + '.standardPay', value);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    })}
                                </Col>
                            </Row>
                        })}
                    </>}
                </Col>
            </Row>
        }
    }

    const menuSection = (value: number|null, i: number) => {
        let selected = getSelected(i);

        return <Row style={{ padding: '0px 15px' }}>
            <Col xs={12} className={'mb-2 mb-lg-7 pt-5'}>
                <h3>Job types</h3>
            </Col>
            <StyleMenuWrapperCol xs={12}>
                {jobTypes.map((item: any, j: number) => {
                    let errItm: any = null;
                    if(errors && errors.selectedGeofenceJobType && Array.isArray(errors.selectedGeofenceJobType) && errors.selectedGeofenceJobType.length > 0 && errors.selectedGeofenceJobType[i] && Array.isArray(errors.selectedGeofenceJobType[i].jobTemplates) && errors.selectedGeofenceJobType[i].jobTemplates.length > 0){
                        errItm = errors.selectedGeofenceJobType[i].jobTemplates.filter((x: any) => x.jobTemplateId).find((x: any) => x.jobTemplateId === item.jobTemplateId);
                    }
                    
                    return <StyledMenuRow
                        key={'menu2-' + value + '_' + item.jobTemplateId}
                        active={(selected && selected.item && selected.item.jobTemplateId && selected.item.jobTemplateId > 0) ? (selected.item.jobTemplateId === item.jobTemplateId) ? "true" : "" : ""}
                        filled={hasSelectedDateTime(((values && values.selectedGeofenceJobType.length > 0 && values.selectedGeofenceJobType[i] && values.selectedGeofenceJobType[i].jobTemplates && values.selectedGeofenceJobType[i].jobTemplates.length > 0) ? values.selectedGeofenceJobType[i].jobTemplates : []), item.jobTemplateId) ? 'true' : ''}
                        error={errItm ? 'true' : ''}
                        onClick={(e: any) => {
                            e.stopPropagation();
                            e.preventDefault();

                            let arr: Array<SelectedGeofenceJobTypeStruct> = _.cloneDeep(values.selectedGeofenceJobType);
                            if(arr && arr.length > 0 && arr[i] && arr[i].jobTemplates && arr[i].jobTemplates.length > 0){
                                let jobTemplates = arr[i].jobTemplates.map((item: SelectedJobTypeStruct, j: number) => {
                                    item.isSelected = false;
                                    return item;
                                });
                                let selectedIndex = jobTemplates.findIndex(x => x.jobTemplateId === item.jobTemplateId);
                                arr[i].jobTemplates[selectedIndex].isSelected = true
                                arr[i].jobTemplates = jobTemplates;
                            }
                            setFieldValue('selectedGeofenceJobType', arr);
                        }}
                    >
                        <Col xs={true}>{item.jobTemplateName}</Col>
                        <Col xs={'auto'}>
                            <Tooltip id={'row2-' + item.jobTemplateId} title={'Copy'}>
                                <a href={'/'} tabIndex={-1}>
                                    <i className={'fa fa-clone'}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            
                                            dispatch(payrollBetaCopyJobTypes.show({ jobTemplateId: item.jobTemplateId, jobTypes: jobTypes, geofenceIndex: i }));
                                        }}
                                    ></i>
                                </a>
                            </Tooltip>
                        </Col>
                    </StyledMenuRow>
                })}
            </StyleMenuWrapperCol>
        </Row>
    }

    const geofenceSection = () => {
        const indexesArray = (errors && errors.selectedGeofenceJobType && errors.selectedGeofenceJobType.length > 0) ? errors.selectedGeofenceJobType.map((obj: any, index: number) => obj ? index : undefined).filter((index: number) => index !== undefined) : [];
        
        return <StyledTabs err={indexesArray}>
            {values.selectedGeofenceJobType.map((item: any, i: number) => {
                return <Tab
                    key={'geofenceGroup_' + item.accountMapGeofenceGroupId} 
                    eventKey={item.accountMapGeofenceGroupId}
                    title={<Row>
                        <Col xs={'auto'}>{item.groupName}</Col>
                        <Col xs={'auto'}>
                            <Tooltip id={'row2-' + item.jobTemplateId} title={'Copy'}>
                                <a href={'/'} tabIndex={-1}>
                                    <i className={'fa fa-clone'}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            
                                            dispatch(payrollBetaCopyGeofence.show({ accountMapGeofenceGroupId: item.accountMapGeofenceGroupId, geofence: values.selectedGeofenceJobType, geofenceIndex: i }));
                                        }}
                                    ></i>
                                </a>
                            </Tooltip>
                        </Col>
                    </Row>}
                >
                    <Row className={'mt-10'}>
                        <Col xs={12} lg={2} xl={2}>
                            {menuSection(item.accountMapGeofenceGroupId, i)}
                            <Form.Control.Feedback type="invalid" className={'d-block'}>{(errors.selectedGeofenceJobType && errors.selectedGeofenceJobType[i] && errors.selectedGeofenceJobType[i].jobTemplates && typeof errors.selectedGeofenceJobType[i].jobTemplates === "string") && errors.selectedGeofenceJobType[i].jobTemplates}</Form.Control.Feedback>
                        </Col>
                        <Col xs={12} lg={{ span: 10 }}>
                            <Row className={'justify-content-center'} style={{ border: '1px solid #ececec', borderBottom: '0px' }}>
                                <Col xs={12} lg={6} xl={6} className={'py-5 ps-5'}><h3 className={'m-0'}>Work Time</h3></Col>
                                <Col xs={12} lg={6} xl={6}></Col>
                            </Row>
                            <Row className={'justify-content-center'} style={{ border: '1px solid #ececec' }}>
                                <StyledColLeft xs={12} lg={6} xl={6} hasspecialdate={values.hasSpecialDates}>{workTimeForm(i)}</StyledColLeft>
                                {values.hasSpecialDates && <StyledColRight xs={12} lg={6} xl={6}>{specialDatesForm(i)}</StyledColRight>}
                            </Row>
                        </Col>
                    </Row>
                </Tab>
            })}
            <Col xs={12} className={'m-0 p-0'}><StyledStepHr /></Col>
        </StyledTabs>
    }


    return <Row>
        <Col xs={12}>
            {geofenceSection()}
            <CopyJobType
                onSave={(jobTemplateId: number|null, checkedJobTypes: Array<number>, geofenceIndex: number) => {
                    if(values.selectedGeofenceJobType && values.selectedGeofenceJobType.length > 0 && values.selectedGeofenceJobType[geofenceIndex]){
                        let item = values.selectedGeofenceJobType[geofenceIndex].jobTemplates.find((x: SelectedJobTypeStruct) => x.jobTemplateId === jobTemplateId);
                        if(item){
                            let arr = copyJobType(item, values.selectedGeofenceJobType[geofenceIndex].jobTemplates, checkedJobTypes);
                            setFieldValue('selectedGeofenceJobType.' + geofenceIndex + '.jobTemplates', arr);
                        }
                    }
                }}
            />
            <CopyGeofence
                onSave={(accountMapGeofenceGroupId: number|null, checkedGeofence: Array<number>, geofenceIndex: number) => {
                    if(values.selectedGeofenceJobType && values.selectedGeofenceJobType.length > 0 && values.selectedGeofenceJobType[geofenceIndex]){
                        let item = values.selectedGeofenceJobType.find((x: any) => x.accountMapGeofenceGroupId == accountMapGeofenceGroupId);
                        if(item){
                            let arr = copyGeofenceJobType(item, values.selectedGeofenceJobType, checkedGeofence);
                            setFieldValue('selectedGeofenceJobType', arr);
                        }
                    }
                }}
            />
        </Col>
    </Row>
}


export default GeofenceJobType;
