import React from 'react';

import { Button } from 'react-bootstrap-v5';

import Tooltip from '../Tooltip/Tooltip';

const IconButtonTooltip = props => {
    const {title, placement, ...rest} = props;

    return <Tooltip title={title} placement={placement}>
        <Button {...rest} className={'p-0'} size='sm' variant='link'>
            {props.children}
        </Button>
    </Tooltip>
}


export default IconButtonTooltip;