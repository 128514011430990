import React from 'react';
import _ from 'lodash';

import { 
  Container,
  Row,
  Col,
  Button,
  Spinner,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import LoadingButton from '../components/Buttons/LoadingButton';
import WrappedButton from '../components/Buttons/WrappedButton';

import { useDispatch } from "react-redux";
import cancelToken from "../../setup/redux/slices/cancelToken";


const StyledHeader = styled.div`
  display: ${props => ((props.options.showHeader === true) ? 'block' : 'none')};
  position: ${props => ((props.options.isHeaderFixed === true) ? 'fixed' : 'initial')};
  left: 0px;
  width: 100%;
  padding: 20px 0px;
  z-index: 6;
  background-color: white;
  border-bottom: 1px solid #f4f4f4;
`;

const StyledBody = styled.div`
  padding-top: ${props => ((props.options.showHeader === true) ? ((props.options.isHeaderFixed === true) ? '80px' : '10px') : '10px')};
  padding-bottom: ${props => ((props.options.showFooter === true) ? ((props.options.isFooterFixed === true) ? '90px' : '10px') : '10px')};
`;

const StyledFooter = styled.div`
display: ${props => ((props.options.showFooter === true) ? 'block' : 'none')};
  position: ${props => ((props.options.isFooterFixed === true) ? 'fixed' : 'initial')};
  left: 0px;
  bottom: 0;
  width: 100%;
  padding: 20px 0px;
  z-index: 6;
  background-color: white;
  border-top: 1px solid #f4f4f4;
`;

const StyledOwerlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;

  background-color: rgba(0, 0, 0, 0.2);
  cursor: no-drop;

  justify-content: center;
  align-items: center;
  display: ${props => ((props.options.showLoadingOverlay === true) ? 'flex' : 'none')};
`;


const PageLayout = ({ children, ...rest }) => {
  const dispatch = useDispatch();

  const [form, setForm] = React.useState(null);
  const [isLoading, setLoading] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [options, setOptions] = React.useState({});

  
  React.useEffect(() => {
    
    return () => {
      dispatch(cancelToken.cancel());
    }
  }, []);


  rest.layoutRef.current = {
    setLayoutForm: (form = null, options = {}) => {
      let initOptions = {
        showHeader: true,
        isHeaderFixed: true,
        headerCloseShow: true,
        showFooter: true,
        isFooterFixed: true,
        footerSaveText: 'Save',
        footerSaveShow: true,
        footerCancelText: 'Cancel',
        footerCancelShow: true,
        footerContent: null,
        showLoadingOverlay: true,
      };
      
      setForm(form);
      setOptions(_.assign(initOptions, options));
    },
    setLayoutLoading: (state = false) => {
      setLoading(state);
    },
    setlayoutTitle: (title = '') => {
      setTitle(title);
    },
    setLayoutSubmit: (formData) => {
      if(form && form.props && form.props.history){
        form.props.history.push(form.backLink);
      }
    },
  };

  return <>
    <StyledHeader options={options}>
      <Container className={'px-5'}>
        <Row className={'align-items-center'} style={{ flexBasis: '100%', margin: '0px' }}>
          <Col xs={true}>
            {(typeof title === 'function') ? title() : <h3>{title}</h3>}
          </Col>

          {options.headerCloseShow && <Col xs={'auto'}>
            <WrappedButton
              className={'px-3 pe-1'}
              variant={"light"}
              size={'sm'}
              style={{ backgroundColor: 'transparent' }}
              onClick={() => {
                if(form && form.props && form.props.history){
                  form.props.history.push(form.backLink);
                }
              }}
            >
              <i className={'material-icons'}>close</i>
            </WrappedButton>
          </Col>}
        </Row>
      </Container>
    </StyledHeader>

    <StyledBody options={options}>
      <Container className={'px-5'}>
        {children}
      </Container>
    </StyledBody>

    <StyledFooter options={options}>
      <Container className={'px-5'}>
        <Row className={'align-items-center'} style={{ flexBasis: '100%', margin: '0px' }}>
          {options.footerSaveShow && <Col xs={'auto'}>
            <LoadingButton
              isLoading={isLoading}
              onClick={() => {
                if(form && form.refForm && form.refForm.current){
                  form.refForm.current.handleSubmit();
                }
              }}
            >{options.footerSaveText}</LoadingButton>
          </Col>}
          
          {(typeof options.footerContent === 'function') ? options.footerContent(isLoading) : options.footerContent}

          {options.footerCancelShow && <Col xs={'auto'}>
            <Button
              variant={'light'}
              disabled={isLoading}
              onClick={() => {
                if(form && form.props && form.props.history){
                  form.props.history.push(form.backLink);
                }
              }}
            >{options.footerCancelText}</Button>
          </Col>}
        </Row>
      </Container>
    </StyledFooter>

    {isLoading && <StyledOwerlay options={options}><Spinner animation="border" /></StyledOwerlay>}
  </>
}

export default PageLayout;
