import { createSlice } from "@reduxjs/toolkit";

import Utils from "../../../utils/utils";
import { UserCookieSettingType, PageCookieSettingType } from "../../../utils/enums";


function NewReducer() {
  const name = 'billingDefault';


  const initialState = {
    isDefaultGroupByDate: true,
  };


  const reducers = {
    getDefault: (state, action) => {
      const auth = (action.payload.auth) ? action.payload.auth : null;
      const settingsType = UserCookieSettingType.BillingByJobDates;
      const pageName = name;

      let settingsItem = null;
      if(settingsType){
        const { userCookieSettings } = auth;
        settingsItem = Utils.getUserCookieSettings(userCookieSettings, settingsType, pageName);
      }
      
      let isDefaultGroupByDate = initialState.isDefaultGroupByDate;
      if(settingsItem){
        isDefaultGroupByDate = ((settingsItem[PageCookieSettingType.isDefaultGroupByDate] == false) || (settingsItem[PageCookieSettingType.isDefaultGroupByDate] == true)) ? settingsItem[PageCookieSettingType.isDefaultGroupByDate] : state.isDefaultGroupByDate;
      }
      
      state.isDefaultGroupByDate = isDefaultGroupByDate;
    },
    setDefault: (state, action) => {
      const auth = (action.payload.auth) ? action.payload.auth : null;
      const settingsType = UserCookieSettingType.BillingByJobDates;
      const pageName = name;

      let isDefaultGroupByDate = ((action.payload.isDefaultGroupByDate == false) || (action.payload.isDefaultGroupByDate == true)) ? action.payload.isDefaultGroupByDate : state.isDefaultGroupByDate;
      state.isDefaultGroupByDate = isDefaultGroupByDate;

      if(settingsType){
        let { token, userCookieSettings } = auth;
  
        let data = {
          name: pageName,
          type: settingsType,
        };
  
        let value = {
          [PageCookieSettingType.isDefaultGroupByDate]: state.isDefaultGroupByDate,
        };
        data['value'] = JSON.stringify(value);
  
        Utils.saveUserCookieSettings(data, token, (settings) => {
          if(settings){
            let settingsArr = (userCookieSettings && userCookieSettings.length > 0) ? userCookieSettings : [];
            let settingsIndex = Utils.getUserCookieSettingsIndex(settingsArr, settingsType);
            if(settingsIndex !== -1 && settingsArr[settingsIndex]){
              settingsArr[settingsIndex] = settings;
            } else {
              settingsArr.push(settings);
            }
          }
        });
      }
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
  };
}


export default NewReducer();