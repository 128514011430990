import React, { useState } from 'react';
import { compose, withProps, lifecycle } from 'recompose';
import { withScriptjs } from "react-google-maps"
import { StandaloneSearchBox } from "react-google-maps/lib/components/places/StandaloneSearchBox";

import Utils from "../../utils/utils";

import { 
  Form,
  InputGroup,
  Button,
} from "react-bootstrap-v5";


const { REACT_APP_GOOGLE_MAP_API_KEY } = process.env;
const URL = "https://maps.googleapis.com/maps/api/js?key=" + REACT_APP_GOOGLE_MAP_API_KEY + "&v=3.exp&libraries=geometry,drawing,places";


const JobGoogleSearch = compose(
    withProps({
        googleMapURL: URL,
        loadingElement: <div style={{ height: '100%' }} />,
        containerElement: <div style={{ height:'100%' }} />
    }),
    lifecycle({
        componentWillMount() {
          const refs = {}
    
          this.setState({
            places: [],
            onSearchBoxMounted: ref => {
              refs.searchBox = ref;
            },
            onPlacesChanged: (value) => {
              let places = refs.searchBox.getPlaces();
          
              this.setState({
                places,
              });

              let lat = (places && places.length > 0 && places[0].geometry && places[0].geometry.location) ? places[0].geometry.location.lat() : '';
              let lng = (places && places.length > 0 && places[0].geometry && places[0].geometry.location) ? places[0].geometry.location.lng() : '';

              if(Utils.validateCoordinates(value)){
                // let args = value.split(',');

                // let center = {
                //   lat: (args && args.length > 0) ? parseFloat(args[0]) : '',
                //   lng: (args && args.length > 1) ? parseFloat(args[1]) : '',
                // };

                // if (this.props.onEnterCoordinate) {
                //   this.props.onEnterCoordinate(places && places.length > 0 ? places[0] : null, center ? center : null);
                // }
              } else {
                let center = places && places[0]
                  ? {
                      lat: lat,
                      lng: lng,
                    }
                  : null;

                if (this.props.onPlacesChanged) {
                  this.props.onPlacesChanged(places && places.length > 0 ? places[0] : null, center ? center : null);
                }
              }
            },
          })
        },
      }),
    withScriptjs
  )(props => {
    const [className] = useState(props.className ? props.className : '');
    const [placeholder] = useState(props.placeholder ? props.placeholder : '');
    const [id] = useState(props.id ? props.id : 'JobAddressSearchInput');
    const [label] = useState(props.label ? props.label : '');
    const [error, setError] = useState(props.error ? props.error : '');
    const [value, setValue] = useState(props.value ? props.value : '');


    React.useEffect(() => {
      setValue(props.value ? props.value : '');
    }, [props.value]);

    React.useEffect(() => {
      setError(props.error ? props.error : '');
    }, [props.error]);


    const searchBox = () => {
      return <StandaloneSearchBox
        ref={props.onSearchBoxMounted}
        bounds={props.bounds}
        onPlacesChanged={() => props.onPlacesChanged(value)}
      >
        <Form.Group className={'mb-5'}>
          {label && <Form.Label>{label}</Form.Label>}
          <InputGroup className={'rounded-1 overflow-hidden'}>
            <Form.Control
              type="text"
              id={id}
              isInvalid={!!error}
              className={className}
              placeholder={placeholder}
              value={value}
              disabled={props.readOnly}
              style={{ 
                backgroundColor: (props.readOnly ? 'white' : 'transparent'),
                color: (props.readOnly ? '#A1A5B7' : '#484848'),
              }}
              onChange={(e) => {
                if(props.onChange){
                  props.onChange(e.target.value);
                }
              }}
              onKeyDown={(e) => {
                if ((e.charCode || e.keyCode) === 13) {
                  e.preventDefault();
                  e.stopPropagation();

                  if(props.onPressEnter){
                    props.onPressEnter(value);
                  }
                }
              }}
              onBlur={(e) => {
                if(props.onBlur){
                  props.onBlur(e);
                }
              }}
            />
            <Button
                className={'overflow-hidden'}
                variant={'primary'}
                disabled={props.readOnly}
                onClick={() => {
                  if(props.onClick){
                    props.onClick();
                  }
                }}
            >
                <i className={'material-icons'}>map</i>
            </Button>
            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </StandaloneSearchBox>
    }
    
    return searchBox();
});


export default JobGoogleSearch;