import React from "react";

import moment from 'moment';
import CountUp from 'react-countup';
import parse from 'html-react-parser';
import _ from 'lodash';
import h2p from 'html2plaintext';
import InfiniteScroll from 'react-infinite-scroller';
import pluralize from 'pluralize';
import { EditorState } from 'draft-js';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
  } from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { 
    Row,
    Col,
    Form,
    Button,
    Dropdown,
    Badge,
    Spinner,
} from "react-bootstrap-v5";
import styled from 'styled-components';
import { Icon } from '@iconify/react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import Utils from "../../../utils/utils";
import { AuditTrailObjectType, SortColumns, PagesPermissions } from "../../../utils/enums";

import LoadingOverlay from "../../../components/LoadingOverlay/LoadingOverlay";
import WrappedButton from '../../../components/Buttons/WrappedButton';
import DropdownToggle from '../../../components/dropdown/DropdownToggle';
import SearchDialog from '../../../components/dialog/SearchDialog';
import AuditTrailListCustomer from '../../../components/OLD/Pages/AuditTrail/AuditTrailListCustomer';
import AuditTrailFunctions from '../../../components/OLD/Pages/AuditTrail/AuditTrailFunctions.jsx';
import Popup from '../../../components/popup/Popup';
import LoadingButton from '../../../components/Buttons/LoadingButton';
import RemoveDialog from '../../../components/dialog/RemoveDialog';

import CustomerForm from "./CustomerForm";
import CreditLimitForm from "./CreditLimitForm";

import AuditTrailApi from '../../../api/OLD/Dispatch/AuditTrail.jsx';
import { readApi } from '../../../api/CRUD/DispatchCRUD'

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut, dispatchApiCallDelete } from '../../../../setup/redux/dispatch/actions'


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
);


const StyledDropdownToggleText = styled.span`
    font-size: 14px;
`;
const StyledBadge = styled(Badge)`
    background-color: transparent;
    border: 1px solid #DFDFDF;
    font-weight: normal;
`;
const StyledTabButton = styled.button`
    background-color: transparent;
    color: #185CFF;
    border-bottom: 2px solid transparent;
    padding-bottom: 7px;

    &:hover {
        border-bottom: 2px solid #185CFF;
    }

    ${props => {
        if(props.active){
            return 'color: #484848; border-bottom: 2px solid #185CFF;';
        }
    }}
`;

const StyledFormCheck = styled(Form.Check)`
    position: relative;
    border-bottom: 1px solid #F5F5F5;
    min-height: 60px;
    display: flex;
    align-items: center;

    .form-check-input[type=checkbox] {
        margin-left: 10px;
        border-radius: 0px;
        border: 0px;
        cursor: pointer;
        background-size: contain;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15'%3e%3cg id='Icon_ionic-ios-checkbox-outline' data-name='Icon ionic-ios-checkbox-outline' transform='translate(-2.5 -2.5)'%3e%3cpath id='Path_24397' data-name='Path 24397' d='M16.25,2.5H3.75A1.249,1.249,0,0,0,2.5,3.75v12.5A1.249,1.249,0,0,0,3.75,17.5h12.5a1.249,1.249,0,0,0,1.25-1.25V3.75A1.249,1.249,0,0,0,16.25,2.5Zm.156,13.594a.313.313,0,0,1-.312.313H3.906a.313.313,0,0,1-.312-.312V3.906a.313.313,0,0,1,.313-.312H16.094a.313.313,0,0,1,.313.313Z' fill='%23dfdfdf'/%3e%3cpath id='Path_24398' data-name='Path 24398' d='M14.2,7.535l-.687-.707a.148.148,0,0,0-.109-.047h0a.142.142,0,0,0-.109.047l-4.766,4.8L6.8,9.895a.151.151,0,0,0-.219,0l-.7.7a.156.156,0,0,0,0,.223L8.07,13a.692.692,0,0,0,.457.223.725.725,0,0,0,.453-.215h0l5.223-5.25A.167.167,0,0,0,14.2,7.535Z' fill='%23dfdfdf'/%3e%3c/g%3e%3c/svg%3e");
        
        &:checked{
            background-color: transparent;
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15'%3e%3cg id='Icon_ionic-ios-checkbox-outline' data-name='Icon ionic-ios-checkbox-outline' transform='translate(-2.5 -2.5)'%3e%3cpath id='Path_24397' data-name='Path 24397' d='M16.25,2.5H3.75A1.249,1.249,0,0,0,2.5,3.75v12.5A1.249,1.249,0,0,0,3.75,17.5h12.5a1.249,1.249,0,0,0,1.25-1.25V3.75A1.249,1.249,0,0,0,16.25,2.5Zm.156,13.594a.313.313,0,0,1-.312.313H3.906a.313.313,0,0,1-.312-.312V3.906a.313.313,0,0,1,.313-.312H16.094a.313.313,0,0,1,.313.313Z' fill='%23185cff'/%3e%3cpath id='Path_24398' data-name='Path 24398' d='M14.2,7.535l-.687-.707a.148.148,0,0,0-.109-.047h0a.142.142,0,0,0-.109.047l-4.766,4.8L6.8,9.895a.151.151,0,0,0-.219,0l-.7.7a.156.156,0,0,0,0,.223L8.07,13a.692.692,0,0,0,.457.223.725.725,0,0,0,.453-.215h0l5.223-5.25A.167.167,0,0,0,14.2,7.535Z' fill='%23185cff'/%3e%3c/g%3e%3c/svg%3e");
        }
    }

    label {
        position: relative;
        left: 10px;
        width: 100%;
        height: 100%;
        padding: 0px 10px 0px 0px;
        cursor: pointer;
        
        display: flex;
        align-items: center;
        
        width: 100%;
    
        ${(props) => {
            return props.isActive ? 'color: #185CFF;' : 'color: #FF0090;';
        }}

        &.text-secondary {
            color: #7B7B7B !important;
            font-size: 12px;
        }
    }

    .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
        opacity: 1;
    }

    ${(props) => {
        return props.active ? 'background-color: #ECF5DF;' : '';
    }}
`;
const StyledSortItem = styled(Dropdown.Item)`
    position: relative;
    display: flex;
    align-items: center;

    .custom-title {
        display: inline-block;
        flex-basis: 100%;
        width: 130px;
        white-space: pre-wrap;
        color: #484848;

        ${(props) => {
            return (props.active) ? 'font-weight: bold;' : '';
        }}
    }

    .custom-icons {
        display: inline-block;
        position: relative;
        width: 20px;
        height: 20px;
        overflow: hidden;
        background-color: transparent !important;

        .custom-icon-up {
            overflow: hidden;
            position: relative;
            height: 12px;

            > div {
                position: absolute;
                top: -6px;

                i {
                    ${(props) => {
                        return (props.active && props.direction == 'asc') ? 'color: #484848;' : 'color: #B8B8B8;';
                    }}

                    &:hover {
                        color: #484848;
                    }
                }
            }

            ${(props) => {
                return (props.active) ? 'display: block;' : 'display: none;';
            }}
        }

        .custom-icon-down {
            overflow: hidden;
            position: relative;
            height: 12px;

            > div {
                position: absolute;
                top: -9px;

                i {
                    ${(props) => {
                        return (props.active && props.direction == 'desc') ? 'color: #484848;' : 'color: #B8B8B8;';
                    }}

                    &:hover {
                        color: #484848;
                    }
                }
            }

            ${(props) => {
                return (props.active) ? 'display: block;' : 'display: none;';
            }}
        }
    }
    
    &.active, &:active, &:focus {
        ${(props) => {
            return props.active ? 'background-color: #ECECEC !important;' : 'background-color: transparent !important;';
        }}
    }

    &:hover {
        .custom-icons {
            .custom-icon-up {
                display: block;
            }
            .custom-icon-down {
                display: block;
            }
        }
    }
`;


class CustomerOverview extends React.Component {
  
    constructor(props) {
        super(props);

        this.pageName = 'customer_overview';
        this.backLink = '/Customers';

        this.refForm = React.createRef();
        this.refCustomerForm = React.createRef();
        this.refCreditLimitForm = React.createRef();

        let id = Utils.getIntProps(props);
        
        let user = (props && props.auth && props.auth.user) ? props.auth.user : null;
        this.permissions = (user && user.permissions && user.permissions.length > 0) ? user.permissions : [];
        
        if(this.permissions && this.permissions.length > 0){
            if(this.permissions.findIndex(x => x.permissionId === PagesPermissions.CustomersOverview.read) === -1){
                props.history.push('/Customer-overview-local-services?id=' + id);
            }
        }

        this.state = {
            id: id,
            details: null,
            isLoading: false,
            
            popupShowCustomerForm: false,
            popupLoadingCustomerForm: false,
            popupIdCustomerForm: 0,
            popupIsCopyCustomerForm: false,

            popupShowCreditLimForm: false,
            popupLoadingCreditLimForm: false,
            popupDataCreditLimForm: null,
            popupEmailItemsCreditLimForm: null,
            popupMainEmailItemsCreditLimForm: null,

            leftItems: [],
            leftSearch: '',
            leftSortColumn: 'updated',
            leftSortDir: 'desc',
            leftCurrentPage: 1,
            leftPageSize: 30,
            leftTotal: 0,
            leftIsIncludeInactive: null,
            leftIsInactive: false,
            leftIsSearchDialog: false,
            sortColumnsLoading: false,
            sortColumns: SortColumns.customer,

            isLoadingAuditTrail: true,
            currentPageAuditTrail: 1,
            pageSizeAuditTrail: 6,
            totalRowsAuditTrail: 0,
            itemsAuditTrail: [],

            statuses: [],

            isRemoveDialog: false,
            
            isLoadingChart: false,
            isCompleted: true,
            lastMonths: 6,
            optionsChart: {
                responsive: true,
                scales: {
                    x: {
                        grid: {
                            display: false
                        }
                    }, 
                    y: {
                        ticks: {
                            stepSize: 1
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    title: {
                        display: true,
                        align: 'start',
                        text: '',
                        padding: {
                            bottom: 30
                        },
                        font: {
                            weight: 'normal'
                        },
                        color: '#7A7A7A'
                    },
                    tooltip: {
                        displayColors: false,
                        callbacks: {
                            label: (context) => {
                                let val = 0;
                                try {
                                    val = parseInt(context.formattedValue);
                                    return context.formattedValue + " " + pluralize('job', val);
                                }catch(e){
                                    return context.formattedValue + "jobs";
                                }
                            }
                        }
                    }
                },
            },
            dataChart: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        backgroundColor: '#8BADFF'
                    }
                ]
            },

            isLoadingCreditLimit: false,
            initialValues: {
                isEdit: false,
                isActive: true,
                customerName: '',
                billingName: '',
                billingAddress: '',
                contactNumber: '',
                note: '',
                customerCategoryId: null,
                customerCategoryName: '',
                businessRegNo: '',
                accountBillingProfileId: null,
                accountBillingProfileName: '',
                paymentTermsId: null,
                paymentTermsName: '',
                xeroPaymentTerm: '',
                xeroPaymentTermType: '0',
                isRequirePaymentCollection: false,
                contactPersons: [this.contactPersonsInit],
                emailAddresses: [],

                creditLimit: '',
                creditLimitAlertMessage: '',

                customerCreditLimitTriggers: [this.customerCreditLimitTriggersInit],
                creditLimitEmailTemplate: this.creditLimitEmailTemplateInit,
                customFormParams: {
                    accountBillingProfileItems: [],
                    isConnectQuickbook: false,
                    isConnectXero: false,
                    quickBookAppId: 0,
                    paymentTermsItems: [],
                    paymentTermsIsLoading: false,
                    customerCategoryItems: [],
                    
                    messagePlainText: '',
                    messageHTML: '',
                    message: EditorState.createEmpty(),
                }
            },
        };
    }


    componentDidMount(){
        this.setLayoutForm({
            leftHeaderContent: this.leftHeader,
            leftBodyContent: this.leftBody,
            rightHeaderContent: this.rightHeader,
            rightHeaderButtons: this.rightHeaderButtons,
            rightHeaderTabs: this.rightTabs,
        });

        this.callReadJobStatusApi(() => {
            this.callInitApis();
        });
    }

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            this.pageName + '-read', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = [];
                if(data && data.data && data.data.length > 0){
                    arr = data.data.map((item, i) => {
                        return {
                            value: item.customerId,
                            title: item.customerName,
                            checked: false,
                            disabled: false,
                            active: (item.customerId === this.state.id) ? true : false,
                            isActive: item.isActive,
                            item: item,
                        }
                    });

                    arr = arr.sort((x, y) => {
                        return (x.active === y.active) ? 0 : x.active ? -1 : 1;
                    });
                }

                let total = (data && data.total) ? data.total : 0;

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr, total);
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            this.pageName + '-read_more', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if (data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.customerId;
                        let title = item.customerName;
                        let arrItem = {
                            value: value,
                            title: title,
                            checked: false,
                            disabled: false,
                            active: (item.customerId === this.state.id) ? true : false,
                            isActive: item.isActive,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }

                    arr = arr.sort((x, y) => {
                        return (x.active === y.active) ? 0 : x.active ? -1 : 1;
                    });
                }

                let total = (data && data.total) ? data.total : 0;

                this.setState({
                    leftItems: arr,
                    leftTotal: total,
                    
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback();
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-read_job_status', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = [];
                if(data && data.data && data.data.length > 0){
                    arr = data.data.map((item, i) => {
                        return {
                            value: item.jobStatusId,
                            title: item.jobStatusName,
                            item: item,
                        }
                    });
                }

                this.setState({
                    statuses: arr,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );
    
        Utils.reduxProps(nextProps,
            this.pageName + '-details', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoadingCreditLimit: false,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(data);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-deactivate', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(data);
                    }
                });
            }
        );
    
        Utils.reduxProps(nextProps,
            this.pageName + '-update', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(data, isError);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-delete', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(!isError && (data != '')){
                        Utils.toast(data, 'success');
                    }

                    if(callback){
                        callback(data, isError);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-read_chart', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let labels = [];
                let datasets = [];
                
                if(data && data.data && data.data.length > 0){
                    labels = data.data.map(x => x.month);
                    datasets = data.data.map(x => x.value);
                }

                let dataChart = {
                    labels: labels,
                    datasets: [
                        {
                            data: datasets,
                            backgroundColor: '#8BADFF'
                        }
                    ]
                }
    
                this.setState({
                    isLoadingChart: false,
                    dataChart: dataChart,
                }, () => {
                    this.refreshLayout();
                });

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(dataChart);
                    }
                });
            }
        );
    }
	
    callInitApis = () => {
        Utils.callAllApi([
            {
                api: readApi,
                path: 'customer',
                params: () => {
                    let data = {
                        currentPage: this.state.leftCurrentPage,
                        pageSize: this.state.leftPageSize,
                        searchQuery: this.state.leftSearch,
                        sortColumn: this.state.leftSortColumn,
                        sortDir: this.state.leftSortDir,
                    };
            
                    let leftIsIncludeInactive = ((this.state.leftIsIncludeInactive === true) || (this.state.leftIsIncludeInactive === false)) ? this.state.leftIsIncludeInactive : null;
                    if(leftIsIncludeInactive){
                        data['isIncludeInactive'] = leftIsIncludeInactive;
                    }

                    let leftIsInactive = ((this.state.leftIsInactive === true) || (this.state.leftIsInactive === false)) ? this.state.leftIsInactive : null;
                    if(leftIsInactive){
                        data['isInactive'] = leftIsInactive;
                    }

                    return data;
                },
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        let arr = [];
                        if(data && data.data && data.data.length > 0){
                            arr = data.data.map((item, i) => {
                                return {
                                    value: item.customerId,
                                    title: item.customerName,
                                    checked: false,
                                    disabled: false,
                                    active: (item.customerId === this.state.id) ? true : false,
                                    isActive: item.isActive,
                                    item: item,
                                }
                            });

                            arr = arr.sort((x, y) => {
                                return (x.active === y.active) ? 0 : x.active ? -1 : 1;
                            });
                        }

                        let total = (data && data.total) ? data.total : 0;

                        this.setState({
                            leftItems: arr,
                            leftTotal: total,
                        }, () => {
                            this.refreshLayout();
                        });
                    });
                }
            },
            {
                api: readApi,
                path: 'customer/' + this.state.id,
                params: null,
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        this.setState({
                            details: data,
                        }, () => {
                            this.refreshLayout();
                        });
                    });
                }
            },
            {
                api: readApi,
                path: 'audittrail',
                params: () => {
                    this.setState({
                        isLoadingAuditTrail: true,
                    });

                    let data = {
                        currentPage: this.state.currentPageAuditTrail,
                        pageSize: this.state.pageSizeAuditTrail,
                        searchQuery: '',
                        sortColumn: 'created',
                        sortDir: 'desc',
                        objectId: this.state.id,
                        objectType: AuditTrailObjectType.Customer,
                    };
                
                    return data;
                },
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        let total = 0;
                        let items = [];
                        if(data && data.data && data.data.length > 0){
                            total = data.total;

                            let dataItems = data.data.sort((a, b) => new Date(b.updated) - new Date(a.updated));
                            items = dataItems.map((item, i) => {
                                return {
                                    id: item.auditTrailId,
                                    item: item,
                                    time: moment(item.updated).format('MMM. DD, YYYY hh:mm A'),
                                    color: 'primary',
                                    contentTitle: null,
                                    contentDesc: <div>{parse(item.description)}</div>,
                                    isLoadingDetails: false,
                                    showDetails: false,
                                    details: null,
                                }
                            });
                        }

                        this.setState({
                            isLoadingAuditTrail: false,
                            itemsAuditTrail: items,
                            totalRowsAuditTrail: total,
                        }, () => {
                            this.refreshLayout();
                        });
                    }, (error) => {
                        this.setState({
                            isLoadingAuditTrail: false,
                            itemsAuditTrail: null,
                            totalRowsAuditTrail: 0,
                        }, () => {
                            this.refreshLayout();
                        });
                    });
                }
            },
            {
                api: readApi,
                path: 'customer/job/stat',
                params: () => {
                    let statusCompleted = Utils.findStatus(this.state.statuses, "title", "Completed");
                    let statusCompletedId = statusCompleted && statusCompleted.value ? statusCompleted.value : null;

                    let data = {
                        customerId: this.state.id,
                        fromDate: moment().subtract(1, 'year').startOf('month').format(Utils.getAPIDateFormat()),
                        toDate: moment().endOf('month').format(Utils.getAPIDateFormat()),
                        jobStatusFilter: statusCompletedId,
                    };
            
                    return data;
                },
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        let labels = [];
                        let datasets = [];
                        
                        if(data && data.data && data.data.length > 0){
                            labels = data.data.map(x => x.month);
                            datasets = data.data.map(x => x.value);
                        }

                        let dataChart = {
                            labels: labels,
                            datasets: [
                                {
                                    data: datasets,
                                    backgroundColor: '#8BADFF'
                                }
                            ]
                        }
            
                        this.setState({
                            dataChart: dataChart,
                        }, () => {
                            this.refreshLayout();
                        });
                    });
                }
            }
        ], (result) => {
            this.setLoading(true);
        }, (result) => {
            this.setLoading(false);
            this.refreshLayout();
        });
    }

    callDetailsApis = (additionalParams = null) => {
        Utils.callAllApi([
            {
                api: readApi,
                path: 'customer/' + this.state.id,
                params: null,
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        this.setState({
                            details: data,
                        }, () => {
                            this.refreshLayout();
                        });
                    });
                }
            },
            {
                api: readApi,
                path: 'audittrail',
                params: () => {
                    this.setState({
                        isLoadingAuditTrail: true,
                    });

                    let data = {
                        currentPage: this.state.currentPageAuditTrail,
                        pageSize: this.state.pageSizeAuditTrail,
                        searchQuery: '',
                        sortColumn: 'created',
                        sortDir: 'desc',
                        objectId: this.state.id,
                        objectType: AuditTrailObjectType.Customer,
                    };
                
                    return data;
                },
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        let total = 0;
                        let items = [];
                        if(data && data.data && data.data.length > 0){
                            total = data.total;

                            let dataItems = data.data.sort((a, b) => new Date(b.updated) - new Date(a.updated));
                            items = dataItems.map((item, i) => {
                                return {
                                    id: item.auditTrailId,
                                    item: item,
                                    time: moment(item.updated).format('MMM. DD, YYYY hh:mm A'),
                                    color: 'primary',
                                    contentTitle: null,
                                    contentDesc: <div>{parse(item.description)}</div>,
                                    isLoadingDetails: false,
                                    showDetails: false,
                                    details: null,
                                }
                            });
                        }

                        this.setState({
                            isLoadingAuditTrail: false,
                            itemsAuditTrail: items,
                            totalRowsAuditTrail: total,
                        }, () => {
                            this.refreshLayout();
                        });
                    }, (error) => {
                        this.setState({
                            isLoadingAuditTrail: false,
                            itemsAuditTrail: null,
                            totalRowsAuditTrail: 0,
                        }, () => {
                            this.refreshLayout();
                        });
                    });
                }
            },
            {
                api: readApi,
                path: 'customer/job/stat',
                params: () => {
                    let statusCompleted = Utils.findStatus(this.state.statuses, "title", "Completed");
                    let statusCompletedId = statusCompleted && statusCompleted.value ? statusCompleted.value : null;

                    let data = {
                        customerId: this.state.id,
                        fromDate: moment().subtract(1, 'year').startOf('month').format(Utils.getAPIDateFormat()),
                        toDate: moment().endOf('month').format(Utils.getAPIDateFormat()),
                        jobStatusFilter: statusCompletedId,
                    };
            
                    return data;
                },
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        let labels = [];
                        let datasets = [];
                        
                        if(data && data.data && data.data.length > 0){
                            labels = data.data.map(x => x.month);
                            datasets = data.data.map(x => x.value);
                        }

                        let dataChart = {
                            labels: labels,
                            datasets: [
                                {
                                    data: datasets,
                                    backgroundColor: '#8BADFF'
                                }
                            ]
                        }
            
                        this.setState({
                            dataChart: dataChart,
                        }, () => {
                            this.refreshLayout();
                        });
                    });
                }
            }
        ], (result) => {
            this.setLoading(true);
        }, (result) => {
            if(additionalParams){
                this.setState(additionalParams);
            }

            this.setLoading(false);
            this.refreshLayout();
        });
    }

    callReadApi = (callback = null) => {
        this.setLoading(true);
        
        let data = {
            currentPage: this.state.leftCurrentPage,
            pageSize: this.state.leftPageSize,
            searchQuery: this.state.leftSearch,
            sortColumn: this.state.leftSortColumn,
            sortDir: this.state.leftSortDir,
        };
   
        let leftIsIncludeInactive = ((this.state.leftIsIncludeInactive === true) || (this.state.leftIsIncludeInactive === false)) ? this.state.leftIsIncludeInactive : null;
        if(leftIsIncludeInactive){
            data['isIncludeInactive'] = leftIsIncludeInactive;
        }

        let leftIsInactive = ((this.state.leftIsInactive === true) || (this.state.leftIsInactive === false)) ? this.state.leftIsInactive : null;
        if(leftIsInactive){
            data['isInactive'] = leftIsInactive;
        }

        this.props.dispatchApiCallGet(data, this.pageName + '-read', 'customer', null, callback, () => {});
    }

    callReadMoreApi = (callback = null) => {
        this.setLoading(true);
        
        let data = {
            currentPage: this.state.leftCurrentPage,
            pageSize: this.state.leftPageSize,
            searchQuery: this.state.leftSearch,
            sortColumn: this.state.leftSortColumn,
            sortDir: this.state.leftSortDir,
        };
   
        let leftIsIncludeInactive = ((this.state.leftIsIncludeInactive === true) || (this.state.leftIsIncludeInactive === false)) ? this.state.leftIsIncludeInactive : null;
        if(leftIsIncludeInactive){
            data['isIncludeInactive'] = leftIsIncludeInactive;
        }

        let leftIsInactive = ((this.state.leftIsInactive === true) || (this.state.leftIsInactive === false)) ? this.state.leftIsInactive : null;
        if(leftIsInactive){
            data['isInactive'] = leftIsInactive;
        }

        this.props.dispatchApiCallGet(data, this.pageName + '-read_more', 'customer', { items: this.state.leftItems }, callback, () => {});
    }

    callReadJobStatusApi = (callback = null) => {
        this.setLoading(true);
        
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
        };
   
        this.props.dispatchApiCallGet(data, this.pageName + '-read_job_status', 'jobStatus', null, callback, () => {});
    }

    callAuditTrailApi = (id = null) => {
        let token = '';

        this.setState({
            isLoadingAuditTrail: true,
        });

        let data = {
          currentPage: this.state.currentPageAuditTrail,
          pageSize: this.state.pageSizeAuditTrail,
          searchQuery: '',
          sortColumn: 'created',
          sortDir: 'desc',
          objectId: id,
          objectType: AuditTrailObjectType.Customer,
        };
    
        AuditTrailApi.getAuditTrail(
          data, 
          token
        ).then(result => {
            Utils.parseResult(result, (data) => {
                let total = 0;
                let items = [];
                if(data && data.data && data.data.length > 0){
                    total = data.total;

                    let dataItems = data.data.sort((a, b) => new Date(b.updated) - new Date(a.updated));
                    items = dataItems.map((item, i) => {
                        return {
                            id: item.auditTrailId,
                            item: item,
                            time: moment(item.updated).format('MMM. DD, YYYY hh:mm A'),
                            color: 'primary',
                            contentTitle: null,
                            contentDesc: <div>{parse(item.description)}</div>,
                            isLoadingDetails: false,
                            showDetails: false,
                            details: null,
                        }
                    });
                }

                this.setState({
                    isLoadingAuditTrail: false,
                    itemsAuditTrail: items,
                    totalRowsAuditTrail: total,
                });
            }, (error, type) => {
                this.setState({
                    isLoadingAuditTrail: false,
                    itemsAuditTrail: null,
                    totalRowsAuditTrail: 0,
                });
            });
        });
    }

    callActivateApi = (active = false, callback = null) => {
        this.setLoading(true);

        let data = {
            isActive: active,
            ids: this.state.id.toString(),
        };
    
		this.props.dispatchApiCallPut(data, this.pageName + '-deactivate', 'customer/bulk', null, callback, null);
    }
    
    callUpdateApi = (data, callback = null) => {
        this.setLoading(true);
        this.props.dispatchApiCallPut(data, this.pageName + '-update', 'customer', data, callback, null);
    }

    callDeleteApi = (data, callback = null) => {
        this.setLoading(true);
        this.props.dispatchApiCallDelete(data, this.pageName + '-delete', 'customer', data, callback, null);
    }

    callReadChartApi = (callback = null) => {
        this.setState({
            isLoadingChart: true,
        });
        
        let jobStatusFilter = [];
        
        let statusAssigned = Utils.findStatus(this.state.statuses, "title", "Assigned");
        let statusAssignedId = statusAssigned && statusAssigned.value ? statusAssigned.value : null;

        let statusUnassigned = Utils.findStatus(this.state.statuses, "title", "Unassigned");
        let statusUnassignedId = statusUnassigned && statusUnassigned.value ? statusUnassigned.value : null;

        let statusCompleted = Utils.findStatus(this.state.statuses, "title", "Completed");
        let statusCompletedId = statusCompleted && statusCompleted.value ? statusCompleted.value : null;

        let statusDraft = Utils.findStatus(this.state.statuses, "title", "Draft");
        let statusDraftId = statusDraft && statusDraft.value ? statusDraft.value : null;

        let fromDate = null;
        let toDate = null;
        if(this.state.isCompleted){
            jobStatusFilter = [statusCompletedId];

            fromDate = moment().subtract(1, 'year').startOf('month').format(Utils.getAPIDateFormat());
            toDate = moment().endOf('month').format(Utils.getAPIDateFormat());
            
            if(this.state.lastMonths == 6){
                fromDate = moment().subtract(5, 'month').startOf('month').format(Utils.getAPIDateFormat());
                toDate = moment().endOf('month').format(Utils.getAPIDateFormat());
            }
        } else {
            jobStatusFilter = [statusDraftId, statusUnassignedId, statusAssignedId];

            fromDate = moment().startOf('month').format(Utils.getAPIDateFormat());
            toDate = moment().add(1, 'year').endOf('month').format(Utils.getAPIDateFormat());
            
            if(this.state.lastMonths == 6){
                fromDate = moment().startOf('month').format(Utils.getAPIDateFormat());
                toDate = moment().add(5, 'month').endOf('month').format(Utils.getAPIDateFormat());
            }
        }

        let data = {
            customerId: this.state.id,
            fromDate: fromDate,
            toDate: toDate,
            jobStatusFilter: jobStatusFilter.join(','),
        };

        this.props.dispatchApiCallGet(data, this.pageName + '-read_chart', 'customer/job/stat', null, callback, () => {});
    }

    callCustomerDetailsApi = (id = null, callback = null) => {
        if(id){
            this.setState({
                isLoadingCreditLimit: true,
            });
            this.props.dispatchApiCallGet(null, this.pageName + '-details', 'customer/' + id, null, callback, () => {});
        } else {
            if(callback){
                callback(null)
            }
        }
    }
    /* END API */


    /* LAYOUT FUNCTIONS */
    setLayoutForm = (options) => {
        if(this.props.layoutRef && this.props.layoutRef.current){
            this.props.layoutRef.current.setLayoutForm(this, options);
        }
    }
    setLayoutLoading = (state = false) => {
        if(this.props.layoutRef && this.props.layoutRef.current){
            this.props.layoutRef.current.setLayoutLoading(state);
        }
    }
    refreshLayout = () => {
        if(this.props.layoutRef && this.props.layoutRef.current){
            this.props.layoutRef.current.refreshLayout();
        }
    }
    /* END LAYOUT FUNCTIONS */


    /* FUNCTIONS */
    setLoading = (state = false) => {
        this.setLayoutLoading(state);

        this.setState({
            isLoading: state,
        });

        if(this.props.onLoading){
            this.props.onLoading(state);
        }
    }

    getPaymentTerms = () => {
        let billingProfile = (this.state.details && this.state.details.billingProfile) ? this.state.details.billingProfile : null;
        let isConnectQuickbook = (billingProfile && billingProfile.isConnectQuickbook) ? billingProfile.isConnectQuickbook : false;
        let isConnectXero = (billingProfile && billingProfile.isConnectXero) ? billingProfile.isConnectXero : false;

        if(isConnectQuickbook){
            return Utils.getDataValue(this.state.details, 'paymentTerms');
        }

        if(isConnectXero){
            return Utils.getDataValue(this.state.details, 'paymentTerms');
        }

        return '-';
    }
    getCollectPayment = () => {
        let isRequirePaymentCollection = (this.state.details && this.state.details.isRequirePaymentCollection) ? this.state.details.isRequirePaymentCollection : false;
        
        return (isRequirePaymentCollection) ? 'Yes' : 'No';
    }
    getPrice = (field = null) => {
        if(field){
            let price = (this.state.details && this.state.details[field]) ? this.state.details[field] : null;
            
            return (price) 
            ? 
                <CountUp
                    delay={0}
                    start={0}
                    end={price}
                    duration={2}
                    decimals={2}
                    separator=","
                    decimal="."
                    prefix={Utils.getCurrency()}
                    suffix=""
                >
                    {({ countUpRef }) => (
                        <span ref={countUpRef} />
                    )}
                </CountUp>
            :
                '-'
        } else {
            return '-';
        }
    }

    getCreditLimit = () => {
        return (this.state.details && this.state.details.creditLimit) ? this.state.details.creditLimit : null;
    }
    getUnbilledAmount = () => {
        return (this.state.details && this.state.details.unbilledAmount) ? this.state.details.unbilledAmount : null;
    }
    getAvailableCredit = () => {
        let creditLimit = this.getCreditLimit();
        let unbilledAmount = this.getUnbilledAmount();

        creditLimit = (creditLimit) ? creditLimit : 0;
        unbilledAmount = (unbilledAmount) ? unbilledAmount : 0;

        let availableCredit = creditLimit - unbilledAmount;
        return availableCredit;
    }

    getFirstId = () => {
        if(this.state.leftItems && this.state.leftItems.length > 0){
            let item = this.state.leftItems[0]
            
            this.setState({
                id: item.value,
                details: null,
                leftItems: [],
            }, () => {
                this.props.history.push('/Customer-overview?id=' + this.state.id);

                this.state.leftItems.forEach(x => x.active = false);
                item.active = true;
                this.refreshLayout();
                
                this.callInitApis();
            });
        } else {
            this.props.history.push('/Customers');
        }
    }

    prepareForm = (data) => {
        if(data){
            var newData = _.cloneDeep(data);

            let customFormParams = this.state.initialValues.customFormParams;

            let customerCreditLimitTriggers = [];
            if(newData && newData.customerCreditLimitTriggers && newData.customerCreditLimitTriggers.length > 0){
                for(let i = 0; i < newData.customerCreditLimitTriggers.length; i++){
                    let actions = (newData.customerCreditLimitTriggers[i] && newData.customerCreditLimitTriggers[i].actions && newData.customerCreditLimitTriggers[i].actions.length > 0) ? newData.customerCreditLimitTriggers[i].actions : [];
                    let contacts = (newData.customerCreditLimitTriggers[i] && newData.customerCreditLimitTriggers[i].contacts && newData.customerCreditLimitTriggers[i].contacts.length > 0) ? newData.customerCreditLimitTriggers[i].contacts : [];
                    
                    newData.customerCreditLimitTriggers[i]['action'] = (actions && actions.length > 0) ? actions.map(x => x.action) : [];

                    if(contacts && contacts.length > 0) {
                        newData.customerCreditLimitTriggers[i]['emailAddress'] = contacts.map(x => {
                            if(x.emailAddress && x.emailAddress != ''){
                                return x.emailAddress
                            } else {
                                return x.customerContactPerson.emailAddress
                            }
                        })
                    }

                    // let emailAddress = (contacts && contacts.length > 0) ? (contacts.emailAddress && contacts.emailAddress != '') ? contacts.emailAddress.split(',') : [] : [];
                    // let contactsEmailAddress = (contacts && contacts.length > 0) ? contacts.map(x => x.customerContactPerson.emailAddress) : [];

                    // newData.customerCreditLimitTriggers[i]['emailAddress'] = [...emailAddress, ...contactsEmailAddress]
                    
                    customerCreditLimitTriggers.push(newData.customerCreditLimitTriggers[i]);
                }
            }
            if(customerCreditLimitTriggers.length == 0){
                customerCreditLimitTriggers = [this.customerCreditLimitTriggersInit];
            }


            let creditLimitEmailTemplate = null;
            if((newData && newData.creditLimitEmailTemplate)){
                let recipientCc = (newData.creditLimitEmailTemplate.recipientCc) ? newData.creditLimitEmailTemplate.recipientCc : '';
                let recipientCcItems = (recipientCc != '') ? recipientCc.split(',') : [];
                newData.creditLimitEmailTemplate.recipientCc = recipientCcItems;

                let message = (newData.creditLimitEmailTemplate.message) ? newData.creditLimitEmailTemplate.message : '';
                let messagePlainText = h2p(message);

                creditLimitEmailTemplate = newData.creditLimitEmailTemplate;
                creditLimitEmailTemplate.subject = (newData.creditLimitEmailTemplate.subject) ? newData.creditLimitEmailTemplate.subject : '';
                creditLimitEmailTemplate.fromName = (newData.creditLimitEmailTemplate.fromName) ? newData.creditLimitEmailTemplate.fromName : '';
                creditLimitEmailTemplate.message = (newData.creditLimitEmailTemplate.message) ? newData.creditLimitEmailTemplate.message : '';
                // creditLimitEmailTemplate.recipientCc = recipientCc;
                creditLimitEmailTemplate.recipientCc = recipientCcItems;
                
                customFormParams.messagePlainText = messagePlainText;
                customFormParams.messageHTML = message;
                customFormParams.message = Utils.createEditorState(message);
            } else {
                creditLimitEmailTemplate = this.creditLimitEmailTemplateInit;

                customFormParams.messagePlainText = '';
                customFormParams.messageHTML = '';
                customFormParams.message = Utils.createEditorState();
            }


            let billingProfileItem = (newData && newData.billingProfile) ? newData.billingProfile : null;
            let accountBillingProfileId = (billingProfileItem && billingProfileItem.accountBillingProfileId) ? billingProfileItem.accountBillingProfileId : null;
            let accountBillingProfileName = (billingProfileItem && billingProfileItem.billingProfileName) ? billingProfileItem.billingProfileName : '';
            let accountBillingProfileItems = (billingProfileItem) ? [{
                value: accountBillingProfileId,
                title: accountBillingProfileName,
                item: billingProfileItem,
            }] : [];
        
        
            let isConnectQuickbook = (billingProfileItem && billingProfileItem.isConnectQuickbook) ? billingProfileItem.isConnectQuickbook : false;
            let isConnectXero = (billingProfileItem && billingProfileItem.isConnectXero) ? billingProfileItem.isConnectXero : false;
            
            let paymentTermsId = (newData && newData.quickBookDefaultTermId) ? newData.quickBookDefaultTermId : null;
            let paymentTermsName = (newData && newData.paymentTerms) ? newData.paymentTerms : '';
            let paymentTermsItems = (isConnectQuickbook && paymentTermsId) ? [{
                value: paymentTermsId,
                title: paymentTermsName,
                item: newData,
            }] : [];
        
            let quickBookApp = (billingProfileItem && billingProfileItem.quickBookApp) ? billingProfileItem.quickBookApp : null;
            let quickBookAppId = (quickBookApp && quickBookApp.quickBookAppId) ? quickBookApp.quickBookAppId : 0;
            let xeroPaymentTerm = (newData && Utils.isNumber(newData.xeroPaymentTerm)) ? newData.xeroPaymentTerm.toString() : '';
            let xeroPaymentTermType = (newData && newData.xeroPaymentTermType) ? newData.xeroPaymentTermType.toString() : '0';
            
            let customerCategoryItem = (newData && newData.category) ? newData.category : null;
            let customerCategoryId = (customerCategoryItem && customerCategoryItem.customerCategoryId) ? customerCategoryItem.customerCategoryId : null;
            let customerCategoryName = (customerCategoryItem && customerCategoryItem.customerCategoryName) ? customerCategoryItem.customerCategoryName : '';
            let customerCategoryItems = (customerCategoryItem) ? [{
                value: customerCategoryId,
                title: customerCategoryName,
                item: customerCategoryItem,
            }] : [];

        
            let contactPersons = (newData && newData.contactPersons && newData.contactPersons.length > 0) ? newData.contactPersons : [this.contactPersonsInit];
        
        
            customFormParams.accountBillingProfileItems = accountBillingProfileItems;
            customFormParams.isConnectQuickbook = isConnectQuickbook;
            customFormParams.isConnectXero = isConnectXero;
            customFormParams.quickBookAppId = quickBookAppId;
            customFormParams.paymentTermsItems = paymentTermsItems;
            customFormParams.customerCategoryItems = customerCategoryItems;
        
            
            let rowValues = {
                isEdit: (this.state.isCopy) ? false : true,
                isActive: ((newData.isActive === false) || (newData.isActive === true)) ? newData.isActive : false,
                customerName: (newData && newData.customerName && newData.customerName !== '') ? newData.customerName : '',
                billingName: (newData && newData.billingName && newData.billingName !== '') ? newData.billingName : '',
                billingAddress: (newData && newData.billingAddress && newData.billingAddress !== '') ? newData.billingAddress : '',
                contactNumber: (newData && newData.contactNumber && newData.contactNumber !== '') ? newData.contactNumber : '',
                note: (newData && newData.note && newData.note !== '') ? newData.note : '',
                customerCategoryId: customerCategoryId,
                customerCategoryName: customerCategoryName,
                businessRegNo: (newData && newData.businessRegNo && newData.businessRegNo !== '') ? newData.businessRegNo : '',
                accountBillingProfileId: accountBillingProfileId,
                accountBillingProfileName: accountBillingProfileName,
                paymentTermsId: paymentTermsId,
                paymentTermsName: paymentTermsName,
                xeroPaymentTerm: xeroPaymentTerm,
                xeroPaymentTermType: xeroPaymentTermType,
                isRequirePaymentCollection: ((newData.isRequirePaymentCollection === false) || (newData.isRequirePaymentCollection === true)) ? newData.isRequirePaymentCollection : false,
                contactPersons: contactPersons,

                emailAddresses: (newData && newData.emailAddresses && newData.emailAddresses !== '') ? newData.emailAddresses.split(',') : [],
                
                creditLimit: (newData && newData.creditLimit) ? newData.creditLimit : '',
                creditLimitAlertMessage: (newData && newData.creditLimitAlertMessage) ? newData.creditLimitAlertMessage : '',

                customerCreditLimitTriggers: customerCreditLimitTriggers,
                creditLimitEmailTemplate: creditLimitEmailTemplate,

                customFormParams: customFormParams,
            };
        
            return rowValues;
        }
    }
    prepareData = (form) => {
        let emailItems = [];
        if(this.refForm && this.refForm.current){
            let values = this.refForm.current.values;
            let contactPersons = (values && values.contactPersons && values.contactPersons.length > 0) ? values.contactPersons : [];
            if(contactPersons && contactPersons.length > 0){
                emailItems = contactPersons.map((item, i) => {
                    return {
                        value: item.emailAddress,
                        title: item.emailAddress,
                        item: item
                    }
                });
            }
        }

        var newForm = _.cloneDeep(form);

        // newForm.creditLimitEmailTemplate.recipientCc = form.creditLimitEmailTemplate.recipientCc;
        newForm.creditLimitEmailTemplate.recipientCc = form.creditLimitEmailTemplate.recipientCc.join(',');

        let customerCreditLimitTriggers2 = [];
        let customerCreditLimitTriggers1 = _.cloneDeep(newForm.customerCreditLimitTriggers);
        customerCreditLimitTriggers1.forEach((item, i) => {
            let actions = [];
            if(item.action && item.action.length > 0){
                actions = item.action.map(x => ({ action: x }));
            }

            let contacts = [];
            if(item.emailAddress && item.emailAddress.length > 0){
                contacts = item.emailAddress.map(x => {
                    let child = {};
                    if(form.emailAddresses.includes(x)){
                        child = { 
                            emailAddress: x
                        }
                    } else {
                        child = { 
                            customerContactPerson: {
                                emailAddress: x
                            }
                        }
                    }

                    let mAdrItems = emailItems.filter(y => y.item.emailAddress == x);
                    if(mAdrItems && mAdrItems.length > 0){
                        child['customerContactPersonId'] = mAdrItems[0].item.customerContactPersonId;
                    }

                    return child
                });
            }

            let params = {
                percentOfCreditLimit: item.percentOfCreditLimit,
                actions: actions,
                contacts: contacts,
            }

            if(item && item.customerCreditLimitTriggerId){
                params['customerCreditLimitTriggerId'] = item.customerCreditLimitTriggerId;
            }

            customerCreditLimitTriggers2.push(params);
        });
      

        let category = null;
        let customerCategoryId = newForm.customerCategoryId;
        if(customerCategoryId){
            category = {
                // customerCategoryId: customerCategoryId,
                customerCategoryName: newForm.customerCategoryName,
            }
        }
        
        // let billingProfile = null;
        let accountBillingProfileId = newForm.accountBillingProfileId;
        // if(accountBillingProfileId){
        //   billingProfile = {
        //     accountBillingProfileId: accountBillingProfileId,
        //     billingProfileName: form.accountBillingProfileName,
        //   }
        // }
        
        let emailAddresses = (newForm && newForm.emailAddresses && newForm.emailAddresses.length > 0) ?  newForm.emailAddresses.join(",") : '';
        
        let customerCreditLimitTriggers = [];
        if(customerCreditLimitTriggers2 && customerCreditLimitTriggers2.length > 0){
            var arrCopy = customerCreditLimitTriggers2.slice();
            customerCreditLimitTriggers = Utils.removeEmptyRowsWithArrays(arrCopy, (row) => {
                if(row['actions'] && row['contacts']){
                    return (row['percentOfCreditLimit'] !== null || row['actions'].length > 0 || row['contacts'].length > 0);
                }
            });
        }
        
        let contactPersons = [];
        if(newForm.contactPersons && newForm.contactPersons.length > 0){
            contactPersons = Utils.removeEmptyRows(newForm.contactPersons, [ 'firstName', 'emailAddress', 'workPhone', 'mobilePhone', 'department']);
        }

        let data = {
            isActive: newForm.isActive,
            customerName: newForm.customerName,
            billingName: newForm.billingName,
            billingAddress: newForm.billingAddress,
            contactNumber: newForm.contactNumber,
            note: newForm.note,
            customerCategoryId: customerCategoryId,
            category: category,
            businessRegNo: newForm.businessRegNo,
            accountBillingProfileId: accountBillingProfileId,
            // billingProfile: billingProfile,
            isRequirePaymentCollection: newForm.isRequirePaymentCollection,
            quickBookDefaultTermId: newForm.paymentTermsId,
            xeroPaymentTerm: newForm.xeroPaymentTerm,
            xeroPaymentTermType: newForm.xeroPaymentTermType,
            contactPersons: contactPersons,
            emailAddresses: emailAddresses,
            
            creditLimit: newForm.creditLimit,
            creditLimitAlertMessage: newForm.creditLimitAlertMessage,
            customerCreditLimitTriggers: customerCreditLimitTriggers,
            creditLimitEmailTemplate: newForm.creditLimitEmailTemplate,
        };

        if ((!this.state.isCopy) && this.state.id > 0) {
            data['customerId'] = this.state.id;
        }

        return data;
    }
    /* END FUNCTIONS */


    /* FORM */
    leftHeader = () => {
        return <Row className={'align-items-center gx-1'}>
            <Col xs={true}>
                <Dropdown alignRight>
                    <Dropdown.Toggle as={DropdownToggle} className={'px-0'}>
                        <StyledDropdownToggleText>{Utils.getAllActiveInactiveText(this.state.leftIsIncludeInactive, this.state.leftIsInactive)} Customers</StyledDropdownToggleText>
                        <ArrowDropDownIcon />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            active={(this.state.leftIsIncludeInactive && this.state.leftIsInactive == null)}
                            onClick={() => {
                                this.setState({
                                    leftCurrentPage: 1,
                                    leftSearch: '',
                                    leftIsIncludeInactive: true,
                                    leftIsInactive: null,
                                }, () => {
                                    this.callReadApi((items, total) => {
                                        this.setState({
                                            leftItems: items,
                                            leftTotal: total,
                                        }, () => {
                                            this.setLoading(false);
                                            this.refreshLayout();
                                        });
                                    });
                                });
                            }}
                        >All</Dropdown.Item>
                        <Dropdown.Item
                            active={(this.state.leftIsIncludeInactive == null && !this.state.leftIsInactive)}
                            onClick={() => {
                                this.setState({
                                    leftCurrentPage: 1,
                                    leftSearch: '',
                                    leftIsIncludeInactive: null,
                                    leftIsInactive: false,
                                }, () => {
                                    this.callReadApi((items, total) => {
                                        this.setState({
                                            leftItems: items,
                                            leftTotal: total,
                                        }, () => {
                                            this.setLoading(false);
                                            this.refreshLayout();
                                        });
                                    });
                                });
                            }} 
                        >Active</Dropdown.Item>
                        <Dropdown.Item
                            active={(this.state.leftIsIncludeInactive == null && this.state.leftIsInactive)}
                            onClick={() => {
                                this.setState({
                                    leftCurrentPage: 1,
                                    leftSearch: '',
                                    leftIsIncludeInactive: null,
                                    leftIsInactive: true,
                                }, () => {
                                    this.callReadApi((items, total) => {
                                        this.setState({
                                            leftItems: items,
                                            leftTotal: total,
                                        }, () => {
                                            this.setLoading(false);
                                            this.refreshLayout();
                                        });
                                    });
                                });
                            }} 
                        >Inactive</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
            <Col xs={'auto'}>
                <WrappedButton
                    className={'px-3 pe-1'}
                    variant={"primary"}
                    size={'sm'}
                    onClick={() => {
                        this.setState({
                          popupShowCustomerForm: true,
                          popupLoadingCustomerForm: false,
                          popupIdCustomerForm: 0,
                          popupIsCopyCustomerForm: false,
                        });
                    }}
                >
                    <i className={'material-icons'}>add</i>
                </WrappedButton>
            </Col>
            <Col xs={'auto'}>
                <Dropdown alignRight>
                    <Dropdown.Toggle
                        as={DropdownToggle}
                        isButton
                        className={'px-3 pe-1'}
                        variant={"light"}
                        size={'sm'}
                        style={{ backgroundColor: 'transparent' }}
                    >
                        <i className={'material-icons'}>format_list_bulleted</i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Header>SORT BY</Dropdown.Header>

                        {this.state.sortColumns.map((item, i) => {
                            return <StyledSortItem
                                key={i}
                                active={(this.state.leftSortColumn == item.value) ? true : false}
                                direction={this.state.leftSortDir}
                                disabled={false}
                                onClick={() => {
                                    this.setState({
                                        sortColumnsLoading: true,
                                        leftCurrentPage: 1,
                                        leftSortColumn: item.value,
                                        leftSortDir: 'asc',
                                    }, () => {
                                        this.callReadApi((items, total) => {
                                            this.setState({
                                                sortColumnsLoading: false,
                                                leftItems: items,
                                                leftTotal: total,
                                            }, () => {
                                                this.setLoading(false);
                                                this.refreshLayout();
                                            });
                                        });
                                    });
                                }}
                            >
                                <div className={'custom-title'}>{item.title}</div>
                                <div className={'custom-icons'}>
                                    <div className={'custom-icon-up'}>
                                        <div>
                                            <i className={'material-icons ' + ((this.state.leftSortDir == 'asc') ? true : false)}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();

                                                    this.setState({
                                                        sortColumnsLoading: true,
                                                        leftCurrentPage: 1,
                                                        leftSortColumn: item.value,
                                                        leftSortDir: 'asc',
                                                    }, () => {
                                                        Utils.clickOutside();
                                                        this.callReadApi((items, total) => {
                                                            this.setState({
                                                                sortColumnsLoading: false,
                                                                leftItems: items,
                                                                leftTotal: total,
                                                            }, () => {
                                                                this.setLoading(false);
                                                                this.refreshLayout();
                                                            });
                                                        });
                                                    });
                                                }}
                                            >arrow_drop_up</i>
                                        </div>
                                    </div>
                                    <div className={'custom-icon-down'}>
                                        <div>
                                            <i className={'material-icons ' + ((this.state.leftSortDir == 'desc') ? true : false)}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();

                                                    this.setState({
                                                        sortColumnsLoading: true,
                                                        leftCurrentPage: 1,
                                                        leftSortColumn: item.value,
                                                        leftSortDir: 'desc',
                                                    }, () => {
                                                        Utils.clickOutside();
                                                        this.callReadApi((items, total) => {
                                                            this.setState({
                                                                sortColumnsLoading: false,
                                                                leftItems: items,
                                                                leftTotal: total,
                                                            }, () => {
                                                                this.setLoading(false);
                                                                this.refreshLayout();
                                                            });
                                                        });
                                                    });
                                                }}
                                            >arrow_drop_down</i>
                                        </div>
                                    </div>
                                </div>
                            </StyledSortItem>
                        })}
                        
                        <Dropdown.Divider />

                        <Dropdown.Item
                            active={false}
                            disabled={false}
                            onClick={() => {
                                this.setState({
                                    sortColumnsLoading: true,
                                    leftCurrentPage: 1,
                                    leftSearch: '',
                                    leftSortColumn: 'updated',
                                    leftSortDir: 'desc',
                                }, () => {
                                    this.callReadApi((items, total) => {
                                        this.setState({
                                            sortColumnsLoading: false,
                                            leftItems: items,
                                            leftTotal: total,
                                        }, () => {
                                            this.setLoading(false);
                                            this.refreshLayout();
                                        });
                                    });
                                });
                            }} 
                        >Refresh List</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
            <Col xs={'auto'}>
                <WrappedButton
                    className={'px-3 pe-1'}
                    variant={"light"}
                    size={'sm'}
                    style={{ backgroundColor: 'transparent' }}
                    onClick={() => {
                        this.setState({
                            leftIsSearchDialog: true,
                        });
                    }}
                >
                    <i className={'material-icons'}>search</i>
                </WrappedButton>
            </Col>
            {this.leftClearSearch()}
        </Row>
    }

    leftBody = () => {
        return <div style={{ width: '100%' }}>
            {
            (this.state.leftItems && this.state.leftItems.length > 0)
            ?
            <>
                <InfiniteScroll
                    loader={<div key={0} className={'text-center pt-2'}><Spinner animation="border" size={'sm'} /></div>}
                    useWindow={false}
                    initialLoad={false}
                    getScrollParent={() => document.getElementById((window.innerWidth < 992) ? 'left_panel_1_ID' : 'left_panel_2_ID')}
                    threshold={1}

                    pageStart={1}
                    hasMore={((this.state.leftTotal > this.state.leftPageSize) && (this.state.leftItems.length < this.state.leftTotal))}

                    loadMore={(page) => {
                        if((this.state.leftTotal > this.state.leftPageSize) && (this.state.leftItems.length < this.state.leftTotal)){
                            this.setState({
                                leftCurrentPage: (this.state.leftCurrentPage+1)
                            }, () => {
                                this.callReadMoreApi(() => {
                                    this.setLoading(false);
                                    this.refreshLayout();
                                });
                            });
                        }
                    }}
                >
                    {this.state.leftItems.map((item, i) => {
                        return <div key={i}
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                                if(!item.active){
                                    this.setState({
                                        id: item.value,
                                        details: null,
                                    }, () => {
                                        this.props.history.push('/Customer-overview?id=' + this.state.id);

                                        this.state.leftItems.forEach(x => x.active = false);
                                        item.active = true;
                                        this.refreshLayout();
                                        
                                        this.callDetailsApis();
                                    });
                                }
                            }}
                        >
                            <StyledFormCheck
                                type={'checkbox'}
                                active={item.active}
                                isActive={item.isActive}
                            >
                                <Row className={'align-items-center g-0'}>
                                    {/* <Col xs={'auto'}>
                                        <Form.Check.Input
                                            type={'checkbox'}
                                            checked={item.checked}
                                            disabled={item.disabled}
                                            onChange={(e) => {
                                                item.checked = e.target.checked;
                                                this.refreshLayout();
                                            }}
                                        />
                                    </Col> */}
                                    <Col xs={true}>
                                        <Row className={'align-items-center g-0'}>
                                            <Col xs={12}>
                                                <Form.Check.Label
                                                    // onClick={(e) => {
                                                    //     if(!item.active){
                                                    //         this.setState({
                                                    //             id: item.value,
                                                    //             details: null,
                                                    //         }, () => {
                                                    //             this.props.history.push('/Customer-overview?id=' + this.state.id);
                            
                                                    //             this.state.leftItems.forEach(x => x.active = false);
                                                    //             item.active = true;
                                                    //             this.refreshLayout();
                                                                
                                                    //             this.callDetailsApis();
                                                    //         });
                                                    //     }
                                                    // }}
                                                >{item.title}</Form.Check.Label>
                                            </Col>
                                            {!this.state.sortColumnsLoading && <Col xs={12}>
                                                <Form.Check.Label
                                                    className={'text-secondary'}
                                                    onClick={(e) => {
                                                        if(!item.active){
                                                            this.setState({
                                                                id: item.value,
                                                                details: null,
                                                            }, () => {
                                                                this.props.history.push('/Customer-overview?id=' + this.state.id);
                            
                                                                this.state.leftItems.forEach(x => x.active = false);
                                                                item.active = true;
                                                                this.refreshLayout();
                                                                
                                                                this.callDetailsApis();
                                                            });
                                                        }
                                                    }}
                                                >{Utils.findSortItemTemplate(this.state.sortColumns, this.state.leftSortColumn, ((item && item.item) ? item.item : null))}</Form.Check.Label>
                                            </Col>}
                                        </Row>
                                    </Col>
                                </Row>
                            </StyledFormCheck>
                        </div>
                    })}
                </InfiniteScroll>

                {/* {((this.state.leftTotal > this.state.leftPageSize) && (this.state.leftItems.length < this.state.leftTotal)) && <div className={'pt-3 pb-3'}>
                    <a href={'/'}
                        className={'link-primary'}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();

                            this.setState({
                                leftCurrentPage: (this.state.leftCurrentPage + 1)
                            }, () => {
                                this.callReadMoreApi(() => {
                                    this.setLoading(false);
                                    this.refreshLayout();
                                });
                            });
                        }}
                    >
                        Show More
                    </a>
                </div>} */}

            </>
            :
            <h6 className={'text-center pt-5 m-0'}>No Results!</h6>
            }
        </div>
    }

    leftClearSearch = () => {
        if(this.state.leftSearch !== ''){
            return <Col xs={12} className={'text-end'}>
                <a href={'/'}
                    className={'link-primary'}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        this.setState({
                            leftCurrentPage: 1,
                            leftSearch: '',
                            leftIsSearchDialog: false,
                        }, () => {
                            this.callReadApi((items, total) => {
                                this.setState({
                                    leftItems: items,
                                    leftTotal: total,
                                }, () => {
                                    this.setLoading(false);
                                    this.refreshLayout();
                                });
                            });
                        });
                    }}
                >
                    Clear Search
                </a>
            </Col>
        }
    }

    rightHeader = () => {
        if((this.state.id > 0) && this.state.details){
            return <Row className={'align-items-center justify-content-center justify-content-md-start py-5'}>
                <Col xs={'auto'}>
                    <h2 className={'m-0'}>{(this.state.details && this.state.details.customerName && this.state.details.customerName != '') ? this.state.details.customerName : <>&nbsp;</>}</h2>
                </Col>
                <Col xs={'auto'}>
                    {this.state.details && <StyledBadge 
                        pill 
                        className={'px-5 py-2'} 
                        bg={this.state.details.isActive ? 'default' : 'danger'}
                        text={this.state.details.isActive ? 'dark' : 'light'}
                    >
                        {this.state.details.isActive ? 'Active' : 'Inactive'}
                    </StyledBadge>}
                </Col>
            </Row>
        } else {
            return <Row className={'align-items-center justify-content-center justify-content-md-start py-5'}>
                <Col xs={'auto'}>
                    <h2 className={'m-0'}>{<>&nbsp;</>}</h2>
                </Col>
            </Row>
        }
    }

    rightHeaderButtons = () => {
        if((this.state.id > 0) && this.state.details){
            return <Row className={'align-items-center'}>
                <Col xs={true} sm={'auto'} className={'text-end text-sm-center'}>
                    <WrappedButton
                        className={'px-3'}
                        variant={"light"}
                        size={'sm'}
                        style={{ border: '1px solid #ECECEC' }}
                        onClick={() => {
                            this.setState({
                                popupShowCustomerForm: true,
                                popupLoadingCustomerForm: false,
                                popupIdCustomerForm: this.state.id,
                                popupIsCopyCustomerForm: false,
                            });
                        }}
                    >
                        <Icon icon="feather:edit-2" width="17" />
                    </WrappedButton>
                </Col>
                <Col xs={true} sm={'auto'} className={'text-start text-sm-center'}>
                    <Dropdown alignRight>
                        <Dropdown.Toggle
                            as={DropdownToggle}
                            isButton
                            className={'px-3 pe-1'}
                            variant={"light"}
                            size={'sm'}
                            style={{ border: '1px solid #ECECEC' }}
                        >
                            More
                            <i className={'material-icons'}>arrow_drop_down</i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {/* <Dropdown.Item
                                active={false}
                                disabled={false}
                                onClick={() => {
                                    this.setState({
                                        popupShowCustomerForm: true,
                                        popupLoadingCustomerForm: false,
                                        popupIdCustomerForm: this.state.id,
                                        popupIsCopyCustomerForm: true,
                                    });
                                }}
                            >Copy</Dropdown.Item> */}
                            <Dropdown.Item
                                active={false}
                                disabled={false}
                                onClick={() => {
                                    let isActive = (this.state.details && this.state.details.isActive) ? this.state.details.isActive : false;
                                    this.callActivateApi(!isActive, () => {
                                        Utils.toast('Customer is ' + (!isActive ? 'activated' : 'deactivated')+ '!', 'success');
                                        
                                        this.callDetailsApis();
                                    });
                                }} 
                            >Mark as {(this.state.details && this.state.details.isActive) ? 'Inactive' : 'Active'}</Dropdown.Item>
                            <Dropdown.Item
                                active={false}
                                disabled={false}
                                onClick={() => {
                                    this.callCustomerDetailsApi(this.state.id, (data) => {
                                        let values = this.prepareForm(data);
                                    
                                        let popupEmailItemsCreditLimForm = [];
                                        let contactPersons = (values && values.contactPersons && values.contactPersons.length > 0) ? values.contactPersons : [];
                                        
                                        let emailAddresses = (values && values.emailAddresses && values.emailAddresses.length > 0) ? values.emailAddresses : [];
                                        if(contactPersons && contactPersons.length > 0){
                                            contactPersons.forEach((item, i) => {
                                                if(item && item.emailAddress != ''){
                                                    popupEmailItemsCreditLimForm.push({
                                                        value: item.emailAddress,
                                                        title: item.emailAddress,
                                                        item: item
                                                    })
                                                }
                                            });
                                        }
    
                                        if(emailAddresses && emailAddresses.length > 0){
                                            emailAddresses.forEach((item, i) => {
                                                if(item && item.emailAddress != ''){
                                                    let obj = {
                                                        value: item,
                                                        title: item,
                                                        item: item
                                                    }
                                                    popupEmailItemsCreditLimForm = Utils.addToArrayIfNotExist(popupEmailItemsCreditLimForm, item, obj);
                                                }
                                            });
                                        }
    
                                        this.setState({
                                            popupShowCreditLimForm: true,
                                            popupLoadingCreditLimForm: false,
                                            popupDataCreditLimForm: values,
                                            popupEmailItemsCreditLimForm: popupEmailItemsCreditLimForm,
                                            popupMainEmailItemsCreditLimForm: emailAddresses,
                                        });
                                    });
                                }} 
                            >Manage Credit Limit</Dropdown.Item>
                            {(this.state.details && !this.state.details.haveJobs) && <Dropdown.Item
                                active={false}
                                onClick={() => {
                                    this.setState({
                                        isRemoveDialog: true,
                                    });
                                }} 
                            >Delete</Dropdown.Item>}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>
        }
    }

    rightTabs = () => {
        if((this.state.id > 0) && this.state.details){
            return <Row className={'gx-md-0 gx-lg-5 align-items-center justify-content-center justify-content-md-start'}>
                {(Utils.hasPermission(this.permissions, PagesPermissions.CustomersOverview.read)) && <Col xs={'auto'}>
                    <StyledTabButton
                        active={true}
                        onClick={() => {

                        }}
                    >Overview</StyledTabButton>
                </Col>}
                {(Utils.hasPermission(this.permissions, PagesPermissions.CustomerServices.read)) && <Col xs={'auto'}>
                    <StyledTabButton
                        active={false}
                        onClick={() => {
                            this.props.history.push('/Customer-overview-local-services?id=' + this.state.id);
                        }}
                    >Local Service</StyledTabButton>
                </Col>}
                {(Utils.hasPermission(this.permissions, PagesPermissions.CustomerSites.read)) && <Col xs={'auto'}>
                    <StyledTabButton
                        active={false}
                        onClick={() => {
                            this.props.history.push('/Customer-overview-sites?id=' + this.state.id);
                        }}
                    >Sites</StyledTabButton>
                </Col>}
                {(Utils.hasPermission(this.permissions, PagesPermissions.CustomersEmail.read)) && <Col xs={'auto'}>
                    <StyledTabButton
                        active={false}
                        onClick={() => {
                            this.props.history.push('/Customer-overview-email?id=' + this.state.id);
                        }}
                    >Email Logs</StyledTabButton>
                </Col>}
            </Row>
        }
    }

    rightBody = () => {
        if(!this.state.isLoading){
            if(this.state.id > 0){
                if(this.state.details){
                    return <Row className={'gx-0'}>
                        <Col xs={12} md={5} lg={4} xl={4}>
                            <div className={'px-6 py-6'} style={{ backgroundColor: '#F7F7F7', height: '100%' }}>
                                <Row className={'mb-3'}>
                                    <Col xs={12} lg={4}>Customer</Col>
                                    <Col xs={12} lg={8}>{Utils.getDataValue(this.state.details, 'customerName')}</Col>
                                </Row>
                                <Row className={'mb-3'}>
                                    <Col xs={12} lg={4}>Billing name</Col>
                                    <Col xs={12} lg={8}>{Utils.getDataValue(this.state.details, 'billingName')}</Col>
                                </Row>
                                <Row className={'mb-3'}>
                                    <Col xs={12} lg={4}>Address</Col>
                                    <Col xs={12} lg={8}>{Utils.getDataValue(this.state.details, 'billingAddress')}</Col>
                                </Row>
                                <Row className={'mb-3'}>
                                    <Col xs={12} lg={4}>Phone number.</Col>
                                    <Col xs={12} lg={8}>{Utils.getDataValue(this.state.details, 'contactNumber')}</Col>
                                </Row>
                                <Row className={'mb-3'}>
                                    <Col xs={12} lg={4}>Business Reg.</Col>
                                    <Col xs={12} lg={8}>{Utils.getDataValue(this.state.details, 'businessRegNo')}</Col>
                                </Row>
                                <Row className={'mb-3'}>
                                    <Col xs={12} lg={4}>Company email</Col>
                                    <Col xs={12} lg={8}>{Utils.getDataValue(this.state.details, 'emailAddresses')}</Col>
                                </Row>
                                <Row className={'mb-3'}>
                                    <Col xs={12} lg={4}>Billing by</Col>
                                    <Col xs={12} lg={8}>{Utils.getDataValue(this.state.details, 'billingProfileName')}</Col>
                                </Row>
                                <Row className={'mb-3'}>
                                    <Col xs={12} lg={4}>Category</Col>
                                    <Col xs={12} lg={8}>{Utils.getDataValue(this.state.details, 'customerCategoryName')}</Col>
                                </Row>
                                {/* <Row className={'mb-3'}>
                                    <Col xs={12} lg={4}>Alert Delay</Col>
                                    <Col xs={12} lg={8}>{Utils.getDataValue(this.state.details, 'creditLimitAlertMessage')}</Col>
                                </Row> */}
                                {/* <Row className={'mb-3'}>
                                    <Col xs={12} lg={4}>Salesman</Col>
                                    <Col xs={12} lg={8}>Michael Jordan</Col>
                                </Row> */}
                                <div className={'mb-3 mt-10'}>
                                    <div style={{ color: '#7A7A7A' }}>Customer note (For internal use)</div>
                                    <hr className={'mt-0 mb-2'} />
                                    <div>{Utils.getDataValue(this.state.details, 'note')}</div>
                                </div>
                                <div className={'mb-3 mt-10'}>
                                    <div style={{ color: '#7A7A7A' }}>Contact Persons </div>
                                    <hr className={'mt-0 mb-2'} />
                                    {
                                    (this.state.details && this.state.details.contactPersons && this.state.details.contactPersons.length > 0)
                                    ?
                                    this.state.details.contactPersons.map((item, i) => {
                                        return <div key={i} className={'mb-7'}>
                                            <Row className={'mb-2'}>
                                                <Col xs={12} lg={4}>First name {(i+1)}</Col>
                                                <Col xs={12} lg={8}>{Utils.getDataValue(item, 'firstName')}</Col>
                                            </Row>
                                            <Row className={'mb-2'}>
                                                <Col xs={12} lg={4}>Email address {(i+1)}</Col>
                                                <Col xs={12} lg={8}>{Utils.getDataValue(item, 'emailAddress')}</Col>
                                            </Row>
                                            <Row className={'mb-2'}>
                                                <Col xs={12} lg={4}>Work phone {(i+1)}</Col>
                                                <Col xs={12} lg={8}>{Utils.getDataValue(item, 'workPhone')}</Col>
                                            </Row>
                                            <Row className={'mb-2'}>
                                                <Col xs={12} lg={4}>Mobile phone {(i+1)}</Col>
                                                <Col xs={12} lg={8}>{Utils.getDataValue(item, 'mobilePhone')}</Col>
                                            </Row>
                                            <Row className={'mb-2'}>
                                                <Col xs={12} lg={4}>Department {(i+1)}</Col>
                                                <Col xs={12} lg={8}>{Utils.getDataValue(item, 'department')}</Col>
                                            </Row>
                                        </div>
                                    })
                                    :
                                    <>-</>
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={7} lg={8} xl={8}>
                            <div className={'px-7 py-4'}>
                                <div className={'mb-10'}>
                                    <Row className={'d-none d-md-flex'}>
                                        <Col xs={12} md={4} className={'py-1'} style={{ color: '#7A7A7A' }}>Payment Terms</Col>
                                        <Col xs={12} md={4} className={'py-1'} style={{ color: '#7A7A7A' }}>Collect Payment</Col>
                                        {/* <Col xs={12} md={4} className={'py-1'} style={{ color: '#7A7A7A' }}>Credit Limit</Col> */}
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={4} className={'py-1'}><span className={'d-block d-md-none pr-2'}>Payment Terms</span>{this.getPaymentTerms()}</Col>
                                        <Col xs={12} md={4} className={'py-1'}><span className={'d-block d-md-none pr-2'}>Collect Payment</span>{this.getCollectPayment()}</Col>
                                        {/* <Col xs={12} md={4} className={'py-1'}><span className={'d-block d-md-none pr-2'}>Credit Limit</span>{this.getPrice('creditLimit')}</Col> */}
                                    </Row>
                                </div>
            
                                <div className={'mb-10 px-2'}>
                                    <Row className={'d-none d-md-flex'}>
                                        <Col xs={12} md={4} className={'py-2'} style={{ backgroundColor: '#F7F7F7', color: '#7A7A7A', textTransform: 'uppercase' }}>CREDIT LIMIT</Col>
                                        <Col xs={12} md={4} className={'py-2'} style={{ backgroundColor: '#F7F7F7', color: '#7A7A7A', textTransform: 'uppercase' }}>{'CREDIT AMOUNT'}</Col>
                                        <Col xs={12} md={4} className={'py-2'} style={{ backgroundColor: '#F7F7F7', color: '#7A7A7A', textTransform: 'uppercase' }}>{'AVAILABLE CREDIT'}</Col>
                                        {/* <Col xs={12} md={4} className={'py-2'} style={{ backgroundColor: '#F7F7F7', color: '#7A7A7A', textTransform: 'uppercase' }}>{this.getUnbilledAmount() && 'CREDIT AMOUNT'}</Col>
                                        <Col xs={12} md={4} className={'py-2'} style={{ backgroundColor: '#F7F7F7', color: '#7A7A7A', textTransform: 'uppercase' }}>{this.getUnbilledAmount() && 'AVAILABLE CREDIT'}</Col> */}
                                    </Row>
                                    <Row style={{ border: '1px solid #F7F7F7' }}>
                                        <Col xs={12} md={4} className={'py-2'}>
                                            <span className={'d-block d-md-none pr-2'}>CREDIT LIMIT</span>
                                            {this.getCreditLimit()
                                            ?
                                            <CountUp
                                                delay={0}
                                                start={0}
                                                end={this.getCreditLimit()}
                                                duration={2}
                                                decimals={2}
                                                separator=","
                                                decimal="."
                                                prefix={Utils.getCurrency()}
                                                suffix=""
                                            >
                                                {({ countUpRef }) => (
                                                    <span ref={countUpRef} />
                                                )}
                                            </CountUp>
                                            :
                                            <>Unlimited</>
                                            }
                                        </Col>
                                        
                                        {/* {this.getUnbilledAmount() && <> */}
                                            <Col xs={12} md={4} className={'py-2'}>
                                                <span className={'d-block d-md-none pr-2'}>CREDIT AMOUNT</span>
                                                <CountUp
                                                    delay={0}
                                                    start={0}
                                                    end={this.getUnbilledAmount()}
                                                    duration={2}
                                                    decimals={2}
                                                    separator=","
                                                    decimal="."
                                                    prefix={Utils.getCurrency()}
                                                    suffix=""
                                                >
                                                    {({ countUpRef }) => (
                                                        <span ref={countUpRef} />
                                                    )}
                                                </CountUp>
                                            </Col>
                                            <Col xs={12} md={4} className={'py-2'}>
                                                <span className={'d-block d-md-none pr-2'}>AVAILABLE CREDIT</span>
                                                {this.getCreditLimit()
                                                ?
                                                <CountUp
                                                    delay={0}
                                                    start={0}
                                                    end={this.getAvailableCredit()}
                                                    duration={2}
                                                    decimals={2}
                                                    separator=","
                                                    decimal="."
                                                    prefix={Utils.getCurrency()}
                                                    suffix=""
                                                >
                                                    {({ countUpRef }) => (
                                                        <span ref={countUpRef} />
                                                    )}
                                                </CountUp>
                                                :
                                                <>-</>
                                                }
                                            </Col>
                                        {/* </>} */}
            
                                    </Row>
                                </div>
            
                                <div className={'mb-10'} style={{ position: 'relative' }}>
                                    <Row className={'gx-2'}>
                                        <Col xs={'auto'}>Jobs</Col>
                                        <Col xs={'auto'}>
                                            {
                                            !this.state.isCompleted
                                            ?
                                            <a href={'/'}
                                                className={'link-primary-underline'}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
            
                                                    this.setState({
                                                        isCompleted: true,
                                                        lastMonths: 6,
                                                    }, () => {
                                                        this.callReadChartApi();
                                                    });
                                                }}
                                            >Completed</a>
                                            :
                                            <>Completed</>
                                            }
                                        </Col>
                                        <Col xs={'auto'}>|</Col>
                                        <Col xs={'auto'}>
                                            {
                                            this.state.isCompleted
                                            ?
                                            <a href={'/'}
                                                className={'link-primary-underline'}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
            
                                                    this.setState({
                                                        isCompleted: false,
                                                        lastMonths: 6,
                                                    }, () => {
                                                        this.callReadChartApi();
                                                    });
                                                }}
                                            >Planned</a>
                                            :
                                            <>Planned</>
                                            }
                                        </Col>
                                        <Col xs={true}></Col>
                                        <Col xs={'auto'}>
                                            <Dropdown alignRight>
                                                <Dropdown.Toggle as={DropdownToggle} className={'py-0'}>
                                                    <StyledDropdownToggleText>{(this.state.isCompleted) ? 'Last' : 'Next'} {this.state.lastMonths} Months</StyledDropdownToggleText>
                                                    <ArrowDropDownIcon />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        active={this.state.lastMonths == 6}
                                                        onClick={() => {
                                                            this.setState({
                                                                lastMonths: 6,
                                                            }, () => {
                                                                this.callReadChartApi();
                                                            });
                                                        }}
                                                    >{(this.state.isCompleted) ? 'Last' : 'Next'} 6 Months</Dropdown.Item>
                                                    <Dropdown.Item
                                                        active={this.state.lastMonths == 12}
                                                        onClick={() => {
                                                            this.setState({
                                                                lastMonths: 12,
                                                            }, () => {
                                                                this.callReadChartApi();
                                                            });
                                                        }} 
                                                    >{(this.state.isCompleted) ? 'Last' : 'Next'} 12 Months</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Col>
                                    </Row>
            
                                    {this.state.isLoadingChart && <div
                                        style={{ 
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            position: 'absolute',
                                            width: '100%',
                                            top: '55%',
                                        }}
                                    >
                                        <Spinner animation="border" />
                                    </div>}
            
                                    <Bar
                                        style={{ visibility: (this.state.isLoadingChart ? 'hidden': 'visible') }}
                                        height={100}
                                        options={this.state.optionsChart}
                                        data={this.state.dataChart}
                                    />
                                </div>
                            </div>
                            
                            <hr className={'mt-0 mb-2'} />
                            
                            <div className={'mb-10'}>
                                <AuditTrailListCustomer
                                    items={this.state.itemsAuditTrail}
                                    objectId={this.state.id}
                                    auditTrailObjectType={AuditTrailObjectType.Customer}
                                    totalRows={this.state.totalRowsAuditTrail}
                                    pageSize={this.state.pageSizeAuditTrail}
                                    currentPage={this.state.currentPageAuditTrail}
                                    isLoading={this.state.isLoadingAuditTrail}
                                    details={(obj, index, currentVersion, previousVersion) => {
                                    return AuditTrailFunctions.getDetails(AuditTrailObjectType.Customer, obj, index, currentVersion, previousVersion);
                                    }}
                                    manageData={(currentVersion, previousVersion, callback) => {
                                    return AuditTrailFunctions.getManageData(AuditTrailObjectType.Customer, currentVersion, previousVersion, callback);
                                    }}
                                    onChangePage={(id, page) => {
                                        if(this.state.currentPageAuditTrail !== page){
                                            this.setState({
                                                currentPageAuditTrail: page,
                                            }, () => {
                                                this.callAuditTrailApi(id);
                                            });
                                        }
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                } else {
                    return <h5 className={'text-center mt-10 pt-10'}>The customer was not found</h5>
                }
            } else {
                return <h5 className={'text-center mt-10 pt-10'}>The customer doesn't exist</h5>
            }
        }
    }
    /* END FORM */


    /* DIALOG */
    setSearchDialog = () => {
        return <SearchDialog 
            show={this.state.leftIsSearchDialog}
            onCancel={() => {
                this.setState({
                    leftIsSearchDialog: false,
                });
            }}
            onSearch={(value) => {
                this.setState({
                    leftCurrentPage: 1,
                    leftSearch: value,
                    leftIsSearchDialog: false,
                }, () => {
                    this.callReadApi((items, total) => {
                        this.setState({
                            leftItems: items,
                            leftTotal: total,
                        }, () => {
                            this.setLoading(false);
                            this.refreshLayout();
                        });
                    });
                });
            }}
        />
    }

    setPopupCustomerForm = () => {
        return <Popup 
            className={'no-bg'}
            auth={this.props.auth}
            settingsType={this.props.settingsType}

            show={this.state.popupShowCustomerForm}
            isLoading={this.state.popupLoadingCustomerForm}
            id={this.state.popupIdCustomerForm}
            isCopy={this.state.popupIsCopyCustomerForm}

            showHeader={true}
            header={this.state.popupIdCustomerForm ? this.state.popupIsCopyCustomerForm ? 'Copy Customer' : 'Edit Customer' : 'New Customer'}
            showFooter={true}
            centerFooter={'start'}
            footer={({ isLoading, id, onHide, onSave, onSaveCreateNewSite }) => {
                return <Row>
                    <Col xs={'auto'}>
                        <LoadingButton
                            isLoading={isLoading}
                            onClick={onSave}
                        >Save</LoadingButton>
                    </Col>

                    {(!id) && <Col xs={'auto'}>
                        <Button 
                            type={'button'}
                            variant={'primary'}
                            disabled={isLoading}
                            onClick={onSaveCreateNewSite}
                        >
                            Save & Create New Site
                            {isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                        </Button>
                    </Col>}

                    <Col xs={'auto'}>
                        <Button
                            variant={'light'}
                            disabled={isLoading}
                            onClick={onHide}
                        >Cancel</Button>
                    </Col>
                </Row>
            }}

            scrollable={true}
            centered={true}
            closeButton={true}
            fullscreen={true}
            noBodyPadding={true}

            onHide={() => {
                this.setState({
                    popupShowCustomerForm: false,
                    popupLoadingCustomerForm: false,
                    popupIdCustomerForm: 0,
                    popupIsCopyCustomerForm: false,
                });
            }}
            onSave={() => {
                if(this.refCustomerForm && this.refCustomerForm.current){
                    if(this.refCustomerForm.current.refForm && this.refCustomerForm.current.refForm.current){
                        this.refCustomerForm.current.refForm.current.handleSubmit();
                    }
                }
            }}
            onSaveCreateNewSite={() => {
                if(this.refCustomerForm && this.refCustomerForm.current){
                    if(this.refCustomerForm.current.onSaveCreateNewSite){
                        this.refCustomerForm.current.onSaveCreateNewSite((res) => {
                            this.props.history.push({
                                pathname: '/Customer-overview-sites',
                                search: '?id=' + res.customerId,
                                state: { showNewPopup: true },
                            });
                        });
                    }
                }
            }}
            
            onLoading={(state) => {
                this.setState({
                    popupLoadingCustomerForm: state,
                });
            }}
            onSubmit={(form) => {
                this.setState({
                    popupShowCustomerForm: false,
                    popupLoadingCustomerForm: false,
                    popupIdCustomerForm: 0,
                    popupIsCopyCustomerForm: false,
                    details: null,
                }, () => {
                    this.callReadApi((items, total) => {
                        this.setState({
                            leftItems: items,
                            leftTotal: total,
                        }, () => {
                            this.refreshLayout();
                        });
                    });

                    this.callDetailsApis();
                });
            }}
        >
            {(props) => {
                return <CustomerForm
                    ref={this.refCustomerForm}
                    {...props}
                />
            }}
        </Popup>
    }

    setPopupCreditLimitForm = () => {
        return <Popup 
            className={'no-bg'}
            auth={this.props.auth}
            settingsType={this.props.settingsType}

            show={this.state.popupShowCreditLimForm}
            isLoading={this.state.popupLoadingCreditLimForm}
            dataParam={this.state.popupDataCreditLimForm}
            emailItems={this.state.popupEmailItemsCreditLimForm}
            mainEmailItems={this.state.popupMainEmailItemsCreditLimForm}

            showHeader={true}
            header={({ isLoading, id, onHide, onSave }) => {
                return <>
                    <h3>
                        Manage Your Customer's Credit Limit<br />
                        <span style={{ fontSize: '13px', fontWeight: 'normal', color: '#7A7A7A' }}>Notify your customers via email and alert yourself or team members when credit limit is exceeded.</span>
                    </h3>
                    
                </>
            }}
            showFooter={true}
            centerFooter={'start'}
            footer={({ isLoading, id, onHide, onSave }) => {
                return <Row>
                    <Col xs={'auto'}>
                        <LoadingButton
                            isLoading={isLoading}
                            onClick={onSave}
                        >Ok</LoadingButton>
                    </Col>
                    <Col xs={'auto'}>
                        <Button
                            variant={'light'}
                            disabled={isLoading}
                            onClick={onHide}
                        >Cancel</Button>
                    </Col>
                </Row>
            }}

            scrollable={true}
            centered={true}
            closeButton={true}
            fullscreen={true}
            noBodyPadding={true}

            onHide={() => {
                this.setState({
                    popupShowCreditLimForm: false,
                    popupLoadingCreditLimForm: false,
                    popupDataCreditLimForm: null,
                    popupEmailItemsCreditLimForm: null,
                    popupMainEmailItemsCreditLimForm: null,
                });
            }}
            onSave={() => {
                if(this.refCreditLimitForm && this.refCreditLimitForm.current){
                    if(this.refCreditLimitForm.current.refForm && this.refCreditLimitForm.current.refForm.current){
                        this.refCreditLimitForm.current.refForm.current.handleSubmit();
                    }
                }
            }}
            
            onLoading={(state) => {
                this.setState({
                    popupLoadingCreditLimForm: state,
                });
            }}
            onSubmit={(form) => {
                this.setLoading(true);
                let data = this.prepareData(form);

                this.callUpdateApi(data, (res, isError) => {
                    if(!isError){
                        this.callDetailsApis({
                            popupShowCreditLimForm: false,
                            popupLoadingCreditLimForm: false,
                            popupDataCreditLimForm: null,
                            popupEmailItemsCreditLimForm: null,
                            popupMainEmailItemsCreditLimForm: null,
                        });
                    } else {
                        this.setLoading(false);
                    }
                });
            }}
        >
            {(props) => {
                return <CreditLimitForm
                    ref={this.refCreditLimitForm}
                    {...props}
                />
            }}
        </Popup>
    }

    setRemoveDialog = () => {
        return <RemoveDialog 
          title={'Remove Customer'}
          show={this.state.isRemoveDialog}
          isLoading={false}
          onCancel={() => {
            this.setState({
                isRemoveDialog: false,
            });
          }}
          onRemove={() => {
            this.setState({
                isRemoveDialog: false,
            }, () => {
                let data = {
                    customerId: this.state.id,
                    // forceDelete: true,
                };
                this.callDeleteApi(data, (res, isError) => {
                    this.setLoading(false);
                    if(!isError){
                        // this.props.history.push('/Customers');
                        this.getFirstId();
                    }
                });
            });
          }}
        />
    }
    /* END DIALOG */


    render() {
        return <>
            <Row style={{ display: 'contents', width: '100%' }}>
                <Col xs={12} className={'p-0'}>
                    {this.rightBody()}
                </Col>
            </Row>
            <LoadingOverlay active={this.state.isLoadingCreditLimit} />
            {this.setSearchDialog()}
            {this.setPopupCustomerForm()}
            {this.setPopupCreditLimitForm()}
            {this.setRemoveDialog()}
        </>
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  dispatchApiCallPost,
  dispatchApiCallPut,
  dispatchApiCallDelete,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CustomerOverview);
