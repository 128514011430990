/* eslint-disable eqeqeq */
import React from 'react';

import {
    Box,
    Grid,
    FormGroup,
    FormControl,
    Select,
    MenuItem,
} from '@material-ui/core';

import CustomCheckBox from '../Checkbox/CustomCheckBox.jsx';


const DataTableActionMenu = React.forwardRef((props, ref) => {
    const { columns, columnVisibility, selected, selectItems, onSelectChange, onChangeIncludeInactive, onChangeColumnVisibility, onSelectChangeAjax, canInactive, pageName, isCheckedIncludeInactive } = props;

    const [canSelectOption, setCanSelectOption] = React.useState(((props.canSelectOption === false) || (props.canSelectOption === true)) ? props.canSelectOption : true);


    const [selectedValue, setSelectedValue] = React.useState(selected);
    const [columnsValue, setColumnsValue] = React.useState((columns && columns.length > 0) ? columns : []);

    let colVis = (columnVisibility && columnVisibility.length > 0) ? columnVisibility.map((item, i) => {
        return {
            visible: item,
            index: i
        }
    }) : [];
    const [columnVisibilityValue, setColumnVisibilityValue] = React.useState((colVis && colVis.length > 0) ? colVis : []);

    let cItem = (columnVisibilityValue && columnVisibilityValue.length > 0) ? columnVisibilityValue : new Array(columnsValue.length).map((item, i) => {
        return {
            visible: true,
            index: i
        }
    });
    const [checkedItem, setCheckedItem] = React.useState(cItem);
    ;
    const [checkedIncludeInactive, setCheckedIncludeInactive] = React.useState(((isCheckedIncludeInactive === true || isCheckedIncludeInactive === false) ? isCheckedIncludeInactive : false));
    const [canIncludeInactive] = React.useState(canInactive);
    const [isShowedMore, setIsShowedMore] = React.useState(false);

    const [checkedTrue] = React.useState(checkedItem.filter(x => x.visible === true));
    const [checkedFalse] = React.useState(checkedItem.filter(x => x.visible === false));

    React.useEffect(() => {
        setCanSelectOption(((props.canSelectOption === false) || (props.canSelectOption === true)) ? props.canSelectOption : true);

        setSelectedValue(props.selected);
        setColumnsValue((props.columns && props.columns.length > 0) ? props.columns : []);

        let colVis = (props.columnVisibility && props.columnVisibility.length > 0) ? props.columnVisibility.map((item, i) => {
            return {
                visible: item,
                index: i
            }
        }) : [];
        setColumnVisibilityValue((colVis && colVis.length > 0) ? colVis : []);

        let cItem = (colVis && colVis.length > 0) ? colVis : new Array((props.columns && props.columns.length > 0) ? props.columns.length : 0).map((item, i) => {
            return {
                visible: true,
                index: i
            }
        });
        setCheckedItem(cItem);
        
        setCheckedIncludeInactive(((props.isCheckedIncludeInactive === true || props.isCheckedIncludeInactive === false) ? props.isCheckedIncludeInactive : false));
    }, [props, props.selected, props.columns, props.columnVisibility, props.isCheckedIncludeInactive, pageName]);


    return (<li ref={ref}>
            <Box pt={1} p={3} className="datatable-action-menu-component" style={props.style}>
                <Grid container>
                    <Box clone pb={2} textAlign={'right'}>
                        <Grid item xs={12}>
                            <a 
                                className={'link-danger'}
                                href={'/'}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    
                                    if(props.onResetColumns){
                                        props.onResetColumns();
                                    }
                                }}
                            >Reset Columns</a>
                        </Grid>
                    </Box>
                    {/* </Box>} */}
                    <Box clone pb={1}>
                        <Grid item xs={12}>
                            Columns
                        </Grid>
                    </Box>
                    <Box clone pb={2} style={{ maxHeight: '50vh', overflow: 'auto' }}>
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <FormGroup>
                                    {(checkedTrue && checkedTrue.length > 0) && checkedTrue.map((item) => {
                                        return (
                                            columnsValue[item.index]
                                            ?
                                                columnsValue[item.index].visibleInDropdown === false
                                                ?
                                                null
                                                :
                                                columnsValue[item.index].visible === false
                                                ?
                                                null
                                                :
                                                <CustomCheckBox 
                                                    key={item.index}
                                                    index={item.index}
                                                    checked={checkedItem[item.index].visible}
                                                    label={columnsValue[item.index].menuLabel ? columnsValue[item.index].menuLabel : columnsValue[item.index].label}
                                                    color={'default'}
                                                    onChange={(index, val) => {
                                                        let newItems = checkedItem;
                                                        newItems[index] = {
                                                            visible: val,
                                                            index: val
                                                        };
                                                        
                                                        setCheckedItem(newItems);
        
                                                        if(onChangeColumnVisibility){
                                                            onChangeColumnVisibility(index, val);
                                                        }
                                                    }}
                                                />
                                            :
                                            null
                                        )
                                    })}

                                    {(isShowedMore && checkedFalse && checkedFalse.length > 0) && checkedFalse.map((item) => {
                                        return (
                                            columnsValue[item.index]
                                            ?
                                                columnsValue[item.index].visibleInDropdown === false
                                                ?
                                                null
                                                :
                                                columnsValue[item.index].visible === false
                                                ?
                                                null
                                                :
                                                <CustomCheckBox 
                                                    key={item.index}
                                                    index={item.index}
                                                    checked={checkedItem[item.index].visible}
                                                    label={columnsValue[item.index].label}
                                                    color={'default'}
                                                    onChange={(index, val) => {
                                                        let newItems = checkedItem;
                                                        newItems[index] = {
                                                            visible: val,
                                                            index: val
                                                        };
                                                        
                                                        setCheckedItem(newItems);

                                                        if(onChangeColumnVisibility){
                                                            onChangeColumnVisibility(index, val);
                                                        }
                                                    }}
                                                />
                                            :
                                            null
                                        )
                                    })}
                                </FormGroup>
                            </FormControl>
                        </Grid>
                    </Box>
                    {<Box clone pb={1} textAlign={'right'}>
                        <Grid item xs={12}>
                            <a 
                                className={'link-primary'}
                                href={'/'}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    
                                    setIsShowedMore(!isShowedMore);

                                    if(props.onShowMore){
                                        props.onShowMore(!isShowedMore);
                                    }
                                }}
                            >{isShowedMore ? 'Show Less' : 'Show More'}</a>
                        </Grid>
                    </Box>}
                    {canIncludeInactive && <>
                        <Box clone pb={1}>
                            <Grid item xs={12}>
                                Others
                            </Grid>
                        </Box>
                        <Box clone pb={2}>
                            <Grid item xs={12}>
                            <FormControl component="fieldset">
                                    <FormGroup>
                                        <CustomCheckBox 
                                                index={0}
                                                checked={checkedIncludeInactive}
                                                label={(props.includeInactiveText && props.includeInactiveText != '') ? props.includeInactiveText : "Include Inactive"}
                                                color={'default'}
                                                onChange={(index, val) => {
                                                    setCheckedIncludeInactive(val);

                                                    if(onChangeIncludeInactive){
                                                        onChangeIncludeInactive(val);
                                                    }
                                                }}
                                            />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                        </Box>
                    </>}
                    {canSelectOption && <>
                        <Box clone pb={1}>
                            <Grid item xs={12}>
                                Rows
                            </Grid>
                        </Box>
                        <Box clone>
                            <Grid item xs={12}>
                                <FormControl variant="outlined">
                                    <Select
                                        className={"custom-action-select-style"}
                                        value={selectedValue}
                                        onChange={(event) => {
                                            setSelectedValue(event.target.value);

                                            if(onSelectChange){
                                                onSelectChange(event.target.value);
                                            }

                                            if(onSelectChangeAjax){
                                                onSelectChangeAjax(event.target.value);
                                            }
                                        }}
                                    >
                                        {(selectItems && selectItems.length > 0) && selectItems.map((item, i) => {
                                            let val = item.value ? item.value : item;
                                            let lbl = item.label ? item.label : item;
                                            return <MenuItem key={i} value={val}>{lbl}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Box>
                    </>}
                </Grid>
            </Box>
        </li>
    );
  });

  export default DataTableActionMenu;