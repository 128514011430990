import React from 'react';

import { Button } from 'react-bootstrap-v5';

import Tooltip from '../Tooltip/Tooltip';

const IconButtonTable = props => {
    const {title, ...rest} = props;

    return <Tooltip title={title}>
        <Button {...rest} size='sm' variant='link'>
            {props.children}
        </Button>
    </Tooltip>
}


export default IconButtonTable;