import ReactDOM from 'react-dom';
import moment from 'moment';

import { Action } from '@neuronet.io/vido/vido.esm';

import Utils from '../../../../utils/utils';



export const CustomWeekendHiglight = (options = null) => {
  const weekdays = (options && options.weekdays) || [6, 0];
  let className;
  let api;

  class WeekendHighlightAction extends Action {
    constructor(element, data) {
      super();
      this.highlight(element, data.time.leftGlobal);
    }

    update(element, data) {
      this.highlight(element, data.time.leftGlobal);
    }

    highlight(element, time) {
      const isWeekend = weekdays.includes(api.time.date(time).day());
      const hasClass = element.classList.contains(className);
      if (!hasClass && isWeekend) {
        element.classList.add(className);
      } else if (hasClass && !isWeekend) {
        element.classList.remove(className);
      }
    }
  }

  return (vido) => {
    api = vido.api;
    className = api.getClass('chart-timeline-grid-row-block') + '--weekend';
    vido.state.update('config.actions.chart-timeline-grid-row-block', actions => {
      actions.push(WeekendHighlightAction);
      return actions;
    });
  };
}





export const CustomRow = (options = null) => {
  const rows = (options && options.rows) || [];
  const items = (options && options.items) || [];
  const rowHeight = (options && options.rowHeight) || 60;
  const CustomRowView = (options && options.CustomRowView) || null;

  class CustomRowAction extends Action {
    constructor(element, data) {
      super();
      this.init(element, data);
    }

    update(element, data) {
      this.init(element, data);
    }
    

    init(element, data) {
      let node = this.getNode(element);
      let el = this.getElement(node, '.gantt-schedule-timeline-calendar__list-column-row-content');

      let rowItems = items.filter(x => x.rowId === data.rowId);
      let rowData = (rows && rows.length > 0) ? rows.filter(x => x.id === data.rowId) : [];
      let row = (rowData && rowData.length > 0) ? rowData[0] : null;

      ReactDOM.render(this.getCustomRow(row, rowItems, rowHeight), el);
    }

    getNode(element) {
      var node = ReactDOM.findDOMNode(element);
      node.style.lineHeight = 'unset';
      return node;
    }
  
    getElement(node, className) {
      const el = node.querySelector(className);
      el.style.lineHeight = 'unset';
      return el;
    }
  
    getCustomRow(row, rowItems, rowHeight) {
      return CustomRowView(row, rowItems, rowHeight);
    }
  }

  return (vido) => {
    vido.state.update('config.actions.list-column-row', actions => {
      actions.push(CustomRowAction);
      return actions;
    });
  };
}





export const CustomSearch = (options = null) => {
  const searchPlaceholder = (options && options.searchPlaceholder) || '';
  const onSearch = (options && options.onSearch) || null;
  const CustomSearchView = (options && options.CustomSearchView) || null;

  class CustomSearchAction extends Action {
    constructor(element, data) {
      super();
      this.init(element, data);
    }

    update(element, data) {
      this.init(element, data);
    }
    

    init(element, data) {
      let node = this.getNode(element);
      node.style.lineHeight = 'unset';
      
      let headerContentEl = this.getElement(node, '.gantt-schedule-timeline-calendar__list-column-header-content');
      headerContentEl.style.lineHeight = 'unset';

      let headerContentResizerEl = this.getElement(headerContentEl, '.gantt-schedule-timeline-calendar__list-column-header-resizer');
      headerContentResizerEl.style.lineHeight = 'unset';
      headerContentResizerEl.style.width = '100%';
      headerContentResizerEl.style.display = 'flex';
      
      let el = this.getElement(node, '.gantt-schedule-timeline-calendar__list-column-header-resizer-container');
      el.style.lineHeight = 'unset';
      el.style.width = '90%';
      el.style.display = 'flex';

      ReactDOM.render(this.getView(searchPlaceholder, onSearch), el);
    }

    getNode(element) {
      var node = ReactDOM.findDOMNode(element);
      node.style.lineHeight = 'unset';
      return node;
    }
  
    getElement(node, className) {
      const el = node.querySelector(className);
      el.style.lineHeight = 'unset';
      return el;
    }
  
    getView(searchPlaceholder, onSearch) {
      return CustomSearchView(searchPlaceholder, onSearch);
    }
  }

  return (vido) => {
    vido.state.update('config.actions.list-column-header', actions => {
      actions.push(CustomSearchAction);
      return actions;
    });
  };
}





export const CustomVehiclesDrivers = (options = null) => {
  const customVehiclesDriversView = (options && options.customVehiclesDriversView) || null;

  class CustomVehiclesDriversAction extends Action {
    constructor(element, data) {
      super();
      this.init(element, data);
    }

    update(element, data) {
      this.init(element, data);
    }
    

    init(element, data) {
      let node = this.getNode(element);
      node.style.lineHeight = 'unset';
      
      let headerContentEl = this.getElement(node, '.gantt-schedule-timeline-calendar__list-column-header-content');
      headerContentEl.style.lineHeight = 'unset';

      let headerContentResizerEl = this.getElement(headerContentEl, '.gantt-schedule-timeline-calendar__list-column-header-resizer');
      headerContentResizerEl.style.lineHeight = 'unset';
      headerContentResizerEl.style.width = '100%';
      headerContentResizerEl.style.display = 'flex';
      
      let el = this.getElement(node, '.gantt-schedule-timeline-calendar__list-column-header-resizer-container');
      el.style.lineHeight = 'unset';
      el.style.width = '90%';
      el.style.display = 'flex';

      ReactDOM.render(this.getView(), el);
    }

    getNode(element) {
      var node = ReactDOM.findDOMNode(element);
      node.style.lineHeight = 'unset';
      return node;
    }
  
    getElement(node, className) {
      const el = node.querySelector(className);
      el.style.lineHeight = 'unset';
      return el;
    }
  
    getView() {
      return customVehiclesDriversView();
    }
  }

  return (vido) => {
    vido.state.update('config.actions.list-column-header', actions => {
      actions.push(CustomVehiclesDriversAction);
      return actions;
    });
  };
}





export const CustomItemResize = (callback = null) => {
  
  class CustomItemResizeAction extends Action {
    constructor(element, data) {
      super();
      this.onResize(element, data);
    }

    update(element, data) {
      this.onResize(element, data);
    }
    

    onResize(element, data) {
      const hasClass = element.classList.contains('resizing');
      if (data.item.isResizing && !hasClass) {
        element.classList.add('resizing');
      } else if (!data.item.isResizing && hasClass) {
        element.classList.remove('resizing');
        
        if(callback){
          callback(data);
        }
      }

      if (data.item.isResizing) {
        element.classList.add('visible-resizing');

        let node = this.getNode(element);
        let itemInfoRightTextEl = this.getElement(node, '.custom-item-info-right-text');

        itemInfoRightTextEl.innerHTML = moment(data.item.time.end).format(Utils.getDefaultTimeFormat());
      } else {
        element.classList.remove('visible-resizing');
      }

      if (data.item.isMovingFinish) {
        element.classList.add('visible-moving');

        let node = this.getNode(element);
        // let itemCustomTimeEl = this.getElement(node, '.custom-time');
        let itemInfoLeftTextEl = this.getElement(node, '.custom-item-info-left-text');
        let itemInfoRightTextEl = this.getElement(node, '.custom-item-info-right-text');
        
        // itemCustomTimeEl.innerHTML = moment(data.item.time.start).format(Utils.getDefaultTimeFormat());
        itemInfoLeftTextEl.innerHTML = moment(data.item.time.start).format(Utils.getDefaultTimeFormat());
        itemInfoRightTextEl.innerHTML = moment(data.item.time.end).format(Utils.getDefaultTimeFormat());
      } else {
        element.classList.remove('visible-moving');
      }
    }

    getNode(element) {
      return ReactDOM.findDOMNode(element);
    }
  
    getElement(node, className) {
      return node.querySelector(className);
    }
  }

  return (vido) => {
    vido.state.subscribe('config.plugin.ItemMovement', itemMovement => {
      if (!itemMovement || !itemMovement.item) return;

      vido.state.update(`config.chart.items.${itemMovement.item.id}.isResizing`, itemMovement.item.resizing);
      vido.state.update(`config.chart.items.${itemMovement.item.id}.isMovingFinish`, itemMovement.item.moving);
      
      if(!itemMovement.item.moving){
        setTimeout(() => {
          vido.state.update(`config.chart.items.${itemMovement.item.id}.isMoving`, itemMovement.item.moving);
        }, 1000);
      } else {
        vido.state.update(`config.chart.items.${itemMovement.item.id}.isMoving`, itemMovement.item.moving);
      }
    });

    vido.state.update('config.actions.chart-timeline-items-row-item', actions => {
      actions.push(CustomItemResizeAction);
      return actions;
    });
  };
}





export const DragDropAction = (element, data, callback = null) => {
  const onDragOver = (e) => {
    e.preventDefault();
    e.target.classList.add('custom-drag-over');
  }
  const onDragLeave = (e) => {
    e.preventDefault();
    e.target.classList.remove('custom-drag-over');
  }
  const onDrop = (e) => {
    e.preventDefault();
    e.target.classList.remove('custom-drag-over');

    var id = e.dataTransfer.getData("id");
    var info = e.dataTransfer.getData("info");

    if(callback){
      callback(data, id, info);
    }
  }

  element.addEventListener("dragover", onDragOver);
  element.addEventListener("dragleave", onDragLeave);
  element.addEventListener("drop", onDrop);

  return {
    update(element, newData) {
      data = newData;
    },

    destroy(element, data) {
      element.removeEventListener("dragover", onDragOver);
      element.removeEventListener("dragleave", onDragLeave);
      element.removeEventListener("drop", onDrop);
    }
  };
}





export const DropAction = (element, data, callback = null) => {
  const onDrop = (event) => {
    if(callback && event.button === 0){
      if (data.item.isMoving) {
        callback(event, data);
      }
    }
  }

  element.addEventListener('mouseup', onDrop);

  return {
    update(element, newData) {
      data = newData;
    },

    destroy(element, data) {
      element.removeEventListener('mouseup', onDrop);
    }
  };
}


export const ClickAction = (element, data, callback = null) => {
  const onClick = (event) => {
    if(callback && event.button === 0 && (event.target.className !== 'custom-resizer')){
      callback(event, data);
    }
  }

  element.addEventListener('click', onClick);

  return {
    update(element, newData) {
      data = newData;
    },

    destroy(element, data) {
      element.removeEventListener('click', onClick);
    }
  };
}


export const HoverAction = (element, data) => {
  const onMouseOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    element.closest('.gantt-schedule-timeline-calendar__chart-timeline-items-row').classList.add('custom-hover-row');
    element.classList.add('custom-hover');
  }

  const onMouseOut = (event) => {
    event.preventDefault();
    event.stopPropagation();
    element.closest('.gantt-schedule-timeline-calendar__chart-timeline-items-row').classList.remove('custom-hover-row');
    element.classList.remove('custom-hover');
  }

  element.addEventListener('mouseover', onMouseOver);
  element.addEventListener('mouseout', onMouseOut);

  return {
    update(element, newData) {
      data = newData;
    },

    destroy(element, data) {
      element.removeEventListener('mouseover', onMouseOver);
      element.removeEventListener('mouseout', onMouseOut);
    }
  };
}


export const RightClickAction = (element, data, callbackContextMenu = null, callbackMenuItem = null) => {
  const onContextMenu = (event) => {
    event.preventDefault();
    event.stopPropagation();
    
    let el = element.querySelectorAll('.custom-item-context-menu-item');
    removeEventL(el);
    addEventL(el);

    if(callbackContextMenu){
      callbackContextMenu(event, data);
    }
  }

  const onContextMenuItem = (event) => {
    event.preventDefault();
    event.stopPropagation();
    
    let isDisabled = false;
    if(event.target.dataset.disabled && event.target.dataset.disabled === 'true'){
      isDisabled = true;
    }

    if(callbackMenuItem && !isDisabled){
      callbackMenuItem(event.target.dataset.type, data);
    }
  }


  const addEventL = (el) => {
    if(el && el.length > 0){
      for(let i = 0; i < el.length; i++){
        el[i].addEventListener('click', onContextMenuItem);
      }
    }
  }

  const removeEventL = (el) => {
    if(el && el.length > 0){
      for(let i = 0; i < el.length; i++){
        el[i].removeEventListener('click', onContextMenuItem);
      }
    }
  }


  element.addEventListener('contextmenu', onContextMenu);

  let el = element.querySelectorAll('.custom-item-context-menu-item');
  addEventL(el);


  return {
    update(element, newData) {
      data = newData;
    },

    destroy(element, data) {
      element.removeEventListener('contextmenu', onContextMenu);
      
      let el = element.querySelectorAll('.custom-item-context-menu-item');
      removeEventL(el);
    }
  };
}