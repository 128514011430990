import React from 'react';

import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";


export const ReportTable = React.forwardRef((props, ref) => {
    return props.children && <table ref={ref} {...props}>
        {props.children}
    </table>
});

export const ReportCaption = props => {
    return props.children && <caption {...props}>
        {props.children}
    </caption>
};

export const ReportThead = props => {
    return <thead {...props}>
        {props.children}
    </thead>
};

export const ReportTbody = props => {
    return <tbody {...props}>
        {props.children}
    </tbody>
};

export const ReportTfoot = props => {
    return <tfoot {...props}>
        {props.children}
    </tfoot>
};

export const ReportTr = props => {
    return <tr {...props}>
        {props.children}
    </tr>
};

export const ReportTh = props => {
    return <th {...props}>
        {props.children}
    </th>
};

export const ReportTd = (props, Width) => {
    return <td style={{ width: `${Width}` }} {...props}>
        {props.children}
    </td>
};


export const SortableList = SortableContainer((props) => {
    return <tbody>
        {(props.items && props.items.length > 0) && props.items.map((row, index) => {
            return <SortableTr 
                key={index}
                index={index}
            >
                {
                    row.isEdit 
                    ? 
                    props.printRowEdit(row, index)
                    :
                    props.printRowView(row, index)
                }
            </SortableTr>
        })}
    </tbody>
});

export const SortableTr = SortableElement((props) => {
    return <tr {...props}>
        {props.children}
    </tr>
});

export const SortableDrag = SortableHandle(({ className, children }) => {
    return <div className={'cursor-sortable-grab ' + className} >
        {children}
    </div>
});