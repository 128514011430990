import React from "react";
import moment from "moment";
import Inputmask from "inputmask";

import { 
    Row,
    Col,
    Form,
    Button,
    InputGroup,
    Spinner,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import RSuiteSelectPicker from '../../../../components/OLD/Select/RSuiteSelectPicker';
import RSuiteInputPicker from '../../../../components/OLD/input/RSuiteInputPicker';
import RSuiteDatePicker from '../../../../components/OLD/Datepicker/RSuiteDatePicker';
import CustomAutosuggest from '../../../../components/OLD/Autocomplete/CustomAutosuggest';
import InputLayout from "../../../../components/input/InputLayout";

import Utils from "../../../../utils/utils";
import { CustomerSiteAvailability } from "../../../../utils/enums";

import { ReactComponent as CalendarIcon } from "../../../../../_metronic/assets/img/icons/feather_calendar.svg"; 
import { ReactComponent as ClockIcon } from "../../../../../_metronic/assets/img/icons/feather_clock.svg";  
import { ReactComponent as LightningIcon } from "../../../../../_metronic/assets/img/icons/lightning.svg";  

import { connect } from 'react-redux'
import { clear } from "../../../../../setup/redux/actions";
import { dispatchApiCallPost, dispatchApiCallGet } from '../../../../../setup/redux/dispatch/actions'

const CustomStatusStyle = styled(Form.Control)`
    display: inline-flex;
    min-width: 110px;

    .rs-picker-toggle-caret {
        display: none;
    }
`;
const RSuiteInputPickerTimeStyle = styled(RSuiteInputPicker)`
    .rs-picker-tag-wrapper {
        min-height: 41px;
    }
`;

class DispatchSection extends React.Component {
  
    constructor(props) {
        super(props);
        
        this.name = 'job_form_dispatch_section';

        let dataParams = (props.dataParams) ? props.dataParams : null;
        let id = (dataParams && dataParams.id) ? dataParams.id : null;
        let row = (dataParams && dataParams.row) ? dataParams.row : null;
        

        this.state = {
            id: id,
            row: row,

            isLoadingAutoSuggestDriver: false,
            
            driverNoteItems: [],
            isLoadingDriverNote: false,
        };
    }
    

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            this.name + '-AccountJobWorkingTime', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.accountJobWorkingTimeId;
                        let title = item.workingTimeName;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();
                
                    if(callback){
                        callback(arr);
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            this.name + '-driver', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.driverId;
                        let title = item.driverName;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.name + '-vehicle', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.vehicleId;
                        let title = item.vehicleName;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.name + '-auto_suggest_driver', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                
                this.setState({
                    isLoadingAutoSuggestDriver: false,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(data);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.name + '-driver_note_list', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
              let arr = [];
    
              if(data && data.data && data.data.length > 0){
                  for(var i = 0; i < data.data.length; i++){
                      let item = data.data[i];
                      let id = item.driverNoteId;
                      let name = item.note;
    
                      if(arr.findIndex(x => x.value === id) === -1){
                          arr.push({
                              value: id,
                              title: name,
                              item: item
                          });
                      }
                  }
              }
              
              this.setState({
                isLoadingDriverNote: false,
      
                isLoading: isLoading,
                isError: isError,
                err: err,
              }, () => {
                this.props.clear();

                if(this.state.isError){
                  this.setState({
                    isLoadingDriverNote: false,
                  });
                }
                
                if(callback){
                  callback(arr);
                }
              });
            }
        );
    }
	
    callTimeReadApi = (jobDate = null, customerSiteId = null, items, callback = null) => {
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            sortColumn: 'order',
            sortDir: 'asc',
            jobDate: jobDate,
        };
     
        if(customerSiteId && customerSiteId > 0){
            data['customerSiteId'] = customerSiteId;
        }

        this.props.dispatchApiCallGet(data, this.name + '-AccountJobWorkingTime', 'AccountJobWorkingTime', { items: items }, callback, () => {});
    }
    
    callReadDriversApi = (items, callback = null) => {
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            isAssignJobToPhone: true,
        };
     
        this.props.dispatchApiCallGet(data, this.name + '-driver', 'driver', { items: items }, callback, () => {});
    }

    callReadVehiclesApi = (customerSiteId = null, items, callback = null) => {
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
        };
     
        if(customerSiteId && customerSiteId > 0){
            data['customerSiteId'] = customerSiteId;
        }

        this.props.dispatchApiCallGet(data, this.name + '-vehicle', 'vehicle', { items: items }, callback, () => {});
    }

    callAutoSuggestDriverApi = (callback = null) => {
        let {
            values,
        } = this.props.formOptions;

        this.setState({
            isLoadingAutoSuggestDriver: true,
        });

        let data = {
            jobTimeSpecific: values.customFormParams.jobTimeSpecific ? values.customFormParams.jobTimeSpecific : values.jobDate,
            customerSiteId: values.customerSiteId,
        };
     
        this.props.dispatchApiCallPost(data, this.name + '-auto_suggest_driver', 'job/auto-suggest-driver', null, callback, () => {});
    }

    callDriverNoteReadApi = (page = 1, searchQuery = "", callback = null) => {
        this.setState({
            isLoadingDriverNote: true,
            driverNoteItems: []
        });
    
        let data = {
            currentPage: page,
            pageSize: 30,
            searchQuery: searchQuery
        };
  
        this.props.dispatchApiCallGet(data, this.name + '-driver_note_list', 'drivernote', null, callback, () => {});
    }
    /* END API */


    body = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;
        
        let timeAvailability = CustomerSiteAvailability.All;
        let isWeekend = Utils.isWeekend(Utils.weekDayNumb(this.props.formOptions));
        if(isWeekend){
            timeAvailability = values.customFormParams.weekendAvailability;
        } else {
            timeAvailability = values.customFormParams.weekdaysAvailability;
        }

        return <Row className={'pb-10'}>
            <Col xs={12} className={'mt-10 mb-10'}><hr /></Col>

            <Col xs={12} md={values.isRecurringForm ? 12 : 6}>
                <h3 className={'mb-7'}>
                    Dispatch Order
                    <a href={'/'}
                        className={'link-default-underline ps-2'}
                        style={{ fontSize: '12px', fontWeight: 'normal' }}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();

                            if(this.props.onOptions){
                                this.props.onOptions()
                            }
                        }}
                    >
                        Driver Options
                    </a>
                </h3>
            </Col>
            {!values.isRecurringForm && <Col xs={12} md={6}>
                <InputLayout
                    className={'justify-content-start justify-content-md-end'}
                    label={<small>{this.props.fields.statusId.label}</small>}
                    labelProps={{
                        sm: 'auto',
                    }}
                    inputProps={{
                        sm: 'auto',
                        className: 'd-flex align-items-center',
                    }}
                >
                    <h3 className={'m-0'}>{values.statusName}</h3>
                    {/* <CustomStatusStyle
                        as = {RSuiteSelectPicker}
                        isInvalid={!!errors.statusId}

                        id={this.props.fields.statusId.id}
                        placeholder={this.props.fields.statusId.placeholder}
                        items={values.customFormParams.statusItems}
                        disabledItemValues={values.customFormParams.disabledStatusItems}
                        value={values.statusId}
                        selectedName={values.statusName}
                        isLoading={values.customFormParams.statusIsLoading}
                        placement={'topStart'}
                        readOnly={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || Utils.isCancelledOrFailed(values.currentStatusName)}
                        searchable={false}
                        cleanable={false}
                        isDefault={true}
                        renderValue={(value, itemObj, selectedElement) => {
                            return <h3 className={'m-0'}>{itemObj.title}</h3>
                        }}
                        onSelect={async (value, item) => {
                            await setFieldValue('statusId', value);
                            await setFieldValue('statusName', item.title);

                            let selectedStatus = Utils.findStatus(values.customFormParams.statusItems, 'title','unassigned');
                            if (value === selectedStatus.value) {
                                await setFieldValue('driverId', null);
                                await setFieldValue('driverName', '');
                                
                                await setFieldValue('vehicleId', null);
                                await setFieldValue('vehicleName', '');
                                
                                let disabledStatusItems = Utils.setStatusDisable(values.customFormParams.statusItems, values.currentStatusId, null, values.isEdit, values.isCopy, values.customFormParams.jobTemplateType);
                                await setFieldValue('customFormParams.disabledStatusItems', disabledStatusItems);
                            }

                            
                            let statusCancelled = Utils.findStatus(values.customFormParams.statusItems, "title", "Cancelled");
                            if (value === statusCancelled.value) {
                                if(this.props.onSelectCancelled){
                                    this.props.onSelectCancelled();
                                }
                            }

                            let statusFailed = Utils.findStatus(values.customFormParams.statusItems, "title", "Failed");
                            if (value === statusFailed.value) {
                                if(this.props.onSelectFailed){
                                    this.props.onSelectFailed();
                                }
                            }
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.statusId}</Form.Control.Feedback> */}
                </InputLayout>
            </Col>}
            
            {!values.isRecurringForm && <>
                <Col xs={12} md={6}>
                    <InputLayout
                        label={this.props.fields.jobDate.label}
                    >
                        <Form.Control
                            as = {RSuiteDatePicker}
                            isInvalid={!!errors.jobDate}
                            
                            placeholder={this.props.fields.jobDate.placeholder}
                            id={this.props.fields.jobDate.id}
                            value={values.jobDate}
                            icon={<></>}
                            placement={'bottom'}
                            readOnly={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                            cleanable={true}
                            isDefault={true}
                            disabledDate={(date) => {
                                return (Utils.isDraft(values.currentStatusName) || (!values.isEdit || values.isCopy)) ? false : (moment(date).isAfter(moment().subtract(1, 'days'))) ? false : true;
                            }}
                            onChange={async (value) => {
                                let jobDate = moment(value).format(Utils.getAPIDateFormat());
                                let jobTime = (values.customFormParams.jobTimeSpecific) ? moment(values.customFormParams.jobTimeSpecific).format(Utils.getDefaultTimeFormat()) : moment().format(Utils.getDefaultTimeFormat());
                                let jobTimeSpecific = Utils.getLocalIsoDateTime(moment(jobDate + ' ' + jobTime, Utils.getAPIDateTimeFormat()))
                                
                                await setFieldValue('jobDate', value);
                                // await setFieldValue('customFormParams.jobTimeSpecific', jobTimeSpecific);

                                try {
                                    let newDate = Utils.addDate(values.followUpDays, value);
                                    await setFieldValue('followUpDate', newDate.toDate());
                                }catch(e){}
                            }}
                            onClear={async () => {
                                await setFieldValue('jobDate', null);
                                // await setFieldValue('customFormParams.jobTimeSpecific', null);
                            }}
                        />
                        <Form.Control.Feedback type="invalid">{errors.jobDate}</Form.Control.Feedback>
                    </InputLayout>
                </Col>
                <Col xs={12} md={6}>
                    <InputLayout
                        label={this.props.fields.accountJobWorkingTimeId.label}
                    >
                        <Form.Control
                            as = {RSuiteInputPickerTimeStyle}
                            isInvalid={!!errors.accountJobWorkingTimeId}

                            id={this.props.fields.accountJobWorkingTimeId.id}
                            placeholder={this.props.fields.accountJobWorkingTimeId.placeholder}
                            items={values.customFormParams.accountJobWorkingTimeItems}
                            value={values.accountJobWorkingTimeId}
                            selectedName={values.accountJobWorkingTimeName}
                            isLoading={values.customFormParams.accountJobWorkingTimeIsLoading}
                            readOnly={(timeAvailability == CustomerSiteAvailability.NotAvailable) || Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                            searchable={true}
                            cleanable={true}
                            virtualized={false}
                            isDefault={true}
                            searchInput={(input) => {
                                Inputmask({ regex: Utils.timeRegex(), jitMasking: true }).mask(input);
                            }}
                            onClick={async () => {
                                await setFieldValue('customFormParams.accountJobWorkingTimeIsLoading', true);
                                this.callTimeReadApi(values.jobDate, values.customerSiteId, values.customFormParams.accountJobWorkingTimeItems, async (items) => {
                                    await setFieldValue('customFormParams.accountJobWorkingTimeItems', items);
                                    await setFieldValue('customFormParams.accountJobWorkingTimeIsLoading', false);
                                });
                            }}
                            onSelect={async (value, item) => {
                                let jobDate = moment(values.jobDate).format(Utils.getAPIDateFormat());

                                if(item.create){
                                    let jobTimeSpecific = Utils.getLocalIsoDateTime(moment(jobDate + ' ' + value, Utils.getAPIDateTimeFormat()))
                                    
                                    let items = values.customFormParams.accountJobWorkingTimeItems;
                                    let nextValue = Utils.getNextMinValue(items);
                                    let title = value;
                                    let arrItem = {
                                        value: nextValue,
                                        title: title,
                                        item: {
                                            accountJobWorkingTimeId: nextValue,
                                            workingTimeName: title,
                                            startTime: jobTimeSpecific,
                                            endTime: jobTimeSpecific,
                                        },
                                    };
                                    items = Utils.addToArray(items, nextValue, arrItem);
                                    
                                    await setFieldValue('customFormParams.accountJobWorkingTimeItems', items);
                                    await setFieldValue('accountJobWorkingTimeId', nextValue);
                                    await setFieldValue('accountJobWorkingTimeName', item.title);
                                    await setFieldValue('customFormParams.jobTimeSpecific', jobTimeSpecific);

                                } else {
                                    let jobTime = (item && item.item && item.item.startTime) ? moment(item.item.startTime).format(Utils.getDefaultTimeFormat()) : moment().format(Utils.getDefaultTimeFormat());
                                    let jobTimeSpecific = Utils.getLocalIsoDateTime(moment(jobDate + ' ' + jobTime, Utils.getAPIDateTimeFormat()))
                                    
                                    await setFieldValue('accountJobWorkingTimeId', value);
                                    await setFieldValue('accountJobWorkingTimeName', item.title);
                                    await setFieldValue('customFormParams.jobTimeSpecific', jobTimeSpecific);
                                }
                            }}
                            onClear={async () => {
                                await setFieldValue('accountJobWorkingTimeId', 0);
                                await setFieldValue('accountJobWorkingTimeName', '');
                                await setFieldValue('customFormParams.jobTimeSpecific', null);
                            }}
                        />
                        <Form.Control.Feedback type="invalid">{errors.accountJobWorkingTimeId}</Form.Control.Feedback>
                    </InputLayout>
                </Col>
            </>}

            <Col xs={12} md={6}>
                <InputLayout
                    label={this.props.fields.driverId.label}
                >
                    <Form.Control
                        as = {RSuiteSelectPicker}
                        isInvalid={!!errors.driverId}

                        id={this.props.fields.driverId.id}
                        placeholder={this.props.fields.driverId.placeholder}
                        items={values.customFormParams.driverItems}
                        value={values.driverId}
                        selectedName={values.driverName}
                        isLoading={values.customFormParams.driverIsLoading}
                        readOnly={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || Utils.isStartedOrInProgressOrCompletedOrCancelledOrFailed(values.currentStatusName)}
                        searchable={true}
                        cleanable={true}
                        isDefault={true}
                        onClick={async () => {
                            await setFieldValue('customFormParams.driverIsLoading', true);
                            this.callReadDriversApi(values.customFormParams.driverItems, async (items) => {
                                await setFieldValue('customFormParams.driverItems', items);
                                await setFieldValue('customFormParams.driverIsLoading', false);
                            });
                        }}
                        onSelect={async (value, item) => {
                            await setFieldValue('driverId', value);
                            await setFieldValue('driverName', item.title);

                            if(values.vehicleId === null || values.vehicleId <= 0){
                                if(item && item.item && item.item.defaultVehicle){
                                    let vehicleItem = item.item.defaultVehicle;
                                    let vehicleId = (vehicleItem.vehicleId && vehicleItem.vehicleId > 0) ? vehicleItem.vehicleId : null;
                                    let vehicleName = (vehicleItem.vehicleName && vehicleItem.vehicleName !== '') ? vehicleItem.vehicleName : '';
                                    // let unladednBinWeight = (vehicleItem.unladednBinWeight) ? vehicleItem.unladednBinWeight : 0;

                                    let items = values.customFormParams.vehicleItems;
                                    let arrItem = {
                                        value: vehicleId,
                                        title: vehicleName,
                                        item: vehicleItem,
                                    };
                                    items = Utils.addToArray(items, vehicleId, arrItem);
                                    await setFieldValue('customFormParams.vehicleItems', items);


                                    await setFieldValue('vehicleId', vehicleId);
                                    await setFieldValue('vehicleName', vehicleName);
                                    // await setFieldValue('weighChitTareWeight', unladednBinWeight);

                                    await Utils.weighChitCalc(this.props.formOptions);
                                }
                            }

                            
                            let selectedStatus = Utils.findStatus(values.customFormParams.statusItems, 'title','unassigned');
                            if (values.statusId === null || values.statusId === 0 || values.statusId === selectedStatus.value) {
                                let newStatus = Utils.findStatus(values.customFormParams.statusItems, 'title','assigned');
                                await setFieldValue('statusId', newStatus.value);
                                await setFieldValue('statusName', newStatus.title);

                                let disabledStatusItems = Utils.setStatusDisable(values.customFormParams.statusItems, values.currentStatusId, value, values.isEdit, values.isCopy, values.customFormParams.jobTemplateType);
                                await setFieldValue('customFormParams.disabledStatusItems', disabledStatusItems);
                            }
                        }}
                        onClear={async () => {
                            await setFieldValue('driverId', null);
                            await setFieldValue('driverName', '');
                            
                            try {
                                let selectedStatus = Utils.findStatus(values.customFormParams.statusItems, 'title','assigned');
                                let dispatchedStatus = Utils.findStatus(values.customFormParams.statusItems, 'title','dispatched');
                                if (values.statusId === selectedStatus.value) {
                                    let newStatus = Utils.findStatus(values.customFormParams.statusItems, 'title','unassigned');
                                    await setFieldValue('statusId', newStatus.value);
                                    await setFieldValue('statusName', newStatus.title);

                                    let disabledStatusItems = Utils.setStatusDisable(values.customFormParams.statusItems, values.currentStatusId, null, values.isEdit, values.isCopy, values.customFormParams.jobTemplateType);
                                    await setFieldValue('customFormParams.disabledStatusItems', disabledStatusItems);

                                } else if (values.statusId === dispatchedStatus.value) {
                                    let newStatus = Utils.findStatus(values.customFormParams.statusItems, 'title','unassigned');
                                    await setFieldValue('statusId', newStatus.value);
                                    await setFieldValue('statusName', newStatus.title);

                                    let disabledStatusItems = Utils.setStatusDisable(values.customFormParams.statusItems, values.currentStatusId, null, values.isEdit, values.isCopy, values.customFormParams.jobTemplateType);
                                    await setFieldValue('customFormParams.disabledStatusItems', disabledStatusItems);
                                }
                            }catch(e){}
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.driverId}</Form.Control.Feedback>
                </InputLayout>
            </Col>
            <Col xs={12} md={6}>
                <InputLayout
                    label={this.props.fields.vehicleId.label}
                >
                    <Form.Control
                        as = {RSuiteSelectPicker}
                        isInvalid={!!errors.vehicleId}

                        id={this.props.fields.vehicleId.id}
                        placeholder={this.props.fields.vehicleId.placeholder}
                        items={values.customFormParams.vehicleItems}
                        value={values.vehicleId}
                        selectedName={values.vehicleName}
                        isLoading={values.customFormParams.vehicleIsLoading}
                        readOnly={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || Utils.isCancelledOrFailed(values.currentStatusName)}
                        searchable={true}
                        cleanable={true}
                        isDefault={true}
                        onClick={async () => {
                            await setFieldValue('customFormParams.vehicleIsLoading', true);
                            this.callReadVehiclesApi(values.customerSiteId, values.customFormParams.vehicleItems, async (items) => {
                                await setFieldValue('customFormParams.vehicleItems', items);
                                await setFieldValue('customFormParams.vehicleIsLoading', false);
                            });
                        }}
                        onSelect={async (value, item) => {
                            await setFieldValue('vehicleId', value);
                            await setFieldValue('vehicleName', item.title);
                            
                            // let unladednBinWeight = (item && item.item && item.item.unladednBinWeight) ? item.item.unladednBinWeight : 0;
                            // await setFieldValue('weighChitTareWeight', unladednBinWeight);

                            if(values.driverId === null || values.driverId <= 0){
                                if(item && item.item && item.item.defaultDriver){
                                    let driverItem = item.item.defaultDriver;
                                    let driverId = (driverItem.driverId && driverItem.driverId > 0) ? driverItem.driverId : null;
                                    let driverName = (driverItem.driverName && driverItem.driverName !== '') ? driverItem.driverName : '';
                                    
                                    let items = values.customFormParams.driverItems;
                                    let arrItem = {
                                        value: driverId,
                                        title: driverName,
                                        item: driverItem,
                                    };
                                    items = Utils.addToArray(items, driverId, arrItem);
                                    await setFieldValue('customFormParams.driverItems', items);

                                    await setFieldValue('driverId', driverId);
                                    await setFieldValue('driverName', driverName);

                                    
                                    let selectedStatus = Utils.findStatus(values.customFormParams.statusItems, 'title','unassigned');
                                    if (values.statusId === null || values.statusId === 0 || values.statusId === selectedStatus.value) {
                                        let newStatus = Utils.findStatus(values.customFormParams.statusItems, 'title','assigned');
                                        await setFieldValue('statusId', newStatus.value);
                                        await setFieldValue('statusName', newStatus.title);
                                    }
                                }
                            }

                            await Utils.weighChitCalc(this.props.formOptions);
                        }}
                        onClear={async () => {
                            await setFieldValue('vehicleId', null);
                            await setFieldValue('vehicleName', '');
                            await setFieldValue('weighChitTareWeight', '0');
                            
                            await Utils.weighChitCalc(this.props.formOptions);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.vehicleId}</Form.Control.Feedback>
                </InputLayout>
            </Col>
            
            {!values.isRecurringForm && <>

                {!values.isActive || (values.customerSiteId && Utils.isVisibleAutoSuggestDriver(values.currentStatusName)) && <Col xs={12} className={'pb-5'}>
                    <Button 
                        variant={'success'}
                        disabled={!(values.jobDate) || this.state.isLoadingAutoSuggestDriver}
                        onClick={() => {
                            this.callAutoSuggestDriverApi( async (data) => {
                                if(data){
                                    let driverId = (data && data.driverId && data.driverId > 0) ? data.driverId : null;
                                    let driverName = (data && data.driverName && data.driverName !== '') ? data.driverName : '';

                                    let driverItems = values.customFormParams.driverItems;
                                    let arrItemDriver = {
                                        value: driverId,
                                        title: driverName,
                                        item: data,
                                    };
                                    driverItems = Utils.addToArray(driverItems, driverId, arrItemDriver);
                                    await setFieldValue('customFormParams.driverItems', driverItems);

                                    await setFieldValue('driverId', driverId);
                                    await setFieldValue('driverName', driverName);
                                    

                                    if(data && data.defaultVehicle){
                                        let vehicleItem = data.defaultVehicle;
                                        let vehicleId = (vehicleItem.vehicleId && vehicleItem.vehicleId > 0) ? vehicleItem.vehicleId : null;
                                        let vehicleName = (vehicleItem.vehicleName && vehicleItem.vehicleName !== '') ? vehicleItem.vehicleName : '';

                                        let items = values.customFormParams.vehicleItems;
                                        let arrItem = {
                                            value: vehicleId,
                                            title: vehicleName,
                                            item: vehicleItem,
                                        };
                                        items = Utils.addToArray(items, vehicleId, arrItem);
                                        await setFieldValue('customFormParams.vehicleItems', items);

                                        await setFieldValue('vehicleId', vehicleId);
                                        await setFieldValue('vehicleName', vehicleName);
                                        
                                        await Utils.weighChitCalc(this.props.formOptions);
                                    }


                                    let selectedStatus = Utils.findStatus(values.customFormParams.statusItems, 'title','unassigned');
                                    if(values.statusId === null || values.statusId === 0 || values.statusId === selectedStatus.value) {
                                        let newStatus = Utils.findStatus(values.customFormParams.statusItems, 'title','assigned');
                                        await setFieldValue('statusId', newStatus.value);
                                        await setFieldValue('statusName', newStatus.title);

                                        let disabledStatusItems = Utils.setStatusDisable(values.customFormParams.statusItems, values.currentStatusId, driverId, values.isEdit, values.isCopy, values.customFormParams.jobTemplateType);
                                        await setFieldValue('customFormParams.disabledStatusItems', disabledStatusItems);
                                    }
                                }
                            });
                        }}
                    >
                        <LightningIcon />
                        <span className={'ps-2'}>AUTO-SUGGEST</span>
                        {this.state.isLoadingAutoSuggestDriver ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                    </Button>
                </Col>}

                {/* <Col xs={12} md={6}>
                    <InputLayout
                        label={this.props.fields.instructionToDrivers.label}
                    >
                        <Form.Control
                            type="text"
                            id={this.props.fields.instructionToDrivers.id}
                            placeholder={this.props.fields.instructionToDrivers.placeholder}
                            readOnly={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                            value={values.instructionToDrivers}
                            onChange={handleChange}
                            isInvalid={!!errors.instructionToDrivers}
                        />
                        <Form.Control.Feedback type="invalid">{errors.instructionToDrivers}</Form.Control.Feedback>
                    </InputLayout>
                </Col>
                <Col xs={12} md={6}>
                    <InputLayout
                        label={<>
                            {!values.isActive || values.isEdit && <a href={'/'}
                                className={'link-default-underline'}
                                style={{ fontSize: '12px', fontWeight: 'normal' }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    
                                    if(this.props.onChatMore){
                                        this.props.onChatMore(values.customFormParams.messages);
                                    }
                                }}
                            >
                                <u>Chat more</u>
                            </a>}
                        </>}
                    />
                </Col> */}
                
                
                {(values.driverRemarks && values.driverRemarks != '') && <Col xs={12} md={6}>
                    <InputLayout
                        label={this.props.fields.driverRemarks.label}
                    >
                        <Form.Control
                            // type="text"
                            as="textarea"
                            rows={5}
                            id={this.props.fields.driverRemarks.id}
                            placeholder={this.props.fields.driverRemarks.placeholder}
                            value={values.driverRemarks}
                            readOnly={true}
                            // onChange={async (e) => {
                            //     Utils.limitRows(e.target.value, 5, async (value) => {
                            //         await setFieldValue('driverRemarks', value);
                            //     });
                            // }}
                            // isInvalid={!!errors.driverRemarks}
                        />
                        {/* <Form.Control.Feedback type="invalid">{errors.driverRemarks}</Form.Control.Feedback> */}
                    </InputLayout>
                </Col>}


                {Utils.isCancelledOrFailed(values.statusName) && <Col xs={12} md={6}>

                    {Utils.isCancelled(values.statusName) && <InputLayout
                        label={this.props.fields.cancellationRemarks.label}
                    >
                        <Form.Control
                            as="textarea"
                            rows={5}
                            id={this.props.fields.cancellationRemarks.id}
                            placeholder={this.props.fields.cancellationRemarks.placeholder}
                            value={values.cancellationRemarks}
                            onChange={async (e) => {
                                Utils.limitRows(e.target.value, 5, async (value) => {
                                    await setFieldValue('cancellationRemarks', value);
                                });
                            }}
                            isInvalid={!!errors.cancellationRemarks}
                        />
                        <Form.Control.Feedback type="invalid">{errors.cancellationRemarks}</Form.Control.Feedback>
                    </InputLayout>}

                    {Utils.isFailed(values.statusName) && <InputLayout
                        label={this.props.fields.cancellationRemarks.labelFailed}
                    >
                        <Form.Control
                            as = {CustomAutosuggest}
                            isError={!!errors.cancellationRemarks}
                            
                            id={this.props.fields.cancellationRemarks.idFailed}
                            placeholder={this.props.fields.cancellationRemarks.placeholderFailed}
                            textName={'title'}
                            isCustomRenderSuggestionsContainer={true}
                            value={values.cancellationRemarks}
                            items={this.state.driverNoteItems}
                            isLoading={this.state.isLoadingDriverNote}
                            noIcon={true}
                            isDefault={true}
                            onChange={async (value) => {
                                if(value){
                                    await setFieldValue('cancellationRemarks', value.title);
                                } else {
                                    await setFieldValue('cancellationRemarks', '');
                                }
                            }}
                            onSearch={async (value, e) => {
                                await setFieldValue('cancellationRemarks', value);

                                this.callDriverNoteReadApi(1, value, (arr) => {
                                    this.setState({
                                        driverNoteItems: arr
                                    }, () => {
                                        e.showAllSuggestions();
                                    });
                                });
                            }}
                            onClick={(e) => {
                                this.callDriverNoteReadApi(1, '', (arr) => {
                                    this.setState({
                                        driverNoteItems: arr
                                    }, () => {
                                        e.showAllSuggestions();
                                    });
                                });
                            }}
                            // onInit={() => {
                            //     this.callDriverNoteReadApi(1, '');
                            // }}
                            onClear={async () => {
                                await setFieldValue('cancellationRemarks', '');
                            }}
                        />
                        <Form.Control.Feedback type="invalid">{errors.cancellationRemarks}</Form.Control.Feedback>
                    </InputLayout>}

                </Col>}
                
            </>}

        </Row>
    }
    

    render() {
        return this.body()
    }
}

const mapDispatchToProps = {
    dispatchApiCallPost,
    dispatchApiCallGet,
    clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DispatchSection);
