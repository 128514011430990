/* eslint-disable no-loop-func */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import * as dispatchCrudApi from '../../api/CRUD/DispatchCRUD'
import Utils from "../../utils/utils";
import { AccountSettingType } from '../../utils/enums'

import { ParseResult } from "../../utils/interfaces";


interface InitState {
  visible: Array<number>,
  coordinates: any,
  staticCoordinates: any,
  isExist: boolean,

  isCreate: boolean,
  isEdit: boolean,
  isRemove: boolean,

  isLoading: boolean,
  center: any,
  zoom: number,
}


function NewReducer() {
  const name = 'geofenceMapViewSlice';


  const initialState: InitState = {
    visible: [],
    coordinates: [],
    staticCoordinates: [],
    isExist: false,

    isCreate: false,
    isEdit: false,
    isRemove: false,

    isLoading: false,
    center: { lat: 1.353915, lng: 103.822901 },
    zoom: 15,
  };


  const reducers = {
    setStaticCoordinates: (state: InitState, action: PayloadAction<any>) => {
      let staticCoordinates = (action.payload && action.payload.length > 0) ? action.payload : [];
      state.staticCoordinates = staticCoordinates;
    },
    setCoordinates: (state: InitState, action: PayloadAction<any>) => {
      let coordinates = (action.payload && action.payload.length > 0) ? action.payload : [];
      let isExist = (coordinates && coordinates.length > 0) ? true : false;
      state.coordinates = coordinates;
      state.isExist = isExist;
    },
    setCenter: (state: InitState, action: PayloadAction<any>) => {
      let center = (action.payload && action.payload) ? action.payload : { lat: 1.353915, lng: 103.822901 };
      state.center = center;
    },
    setZoom: (state: InitState, action: PayloadAction<any>) => {
      let zoom = (action.payload && action.payload) ? action.payload : 13;
      state.zoom = zoom;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setVisible: (state: InitState, action: PayloadAction<number>) => {
      let arr = state.visible;
      
      let index = arr.findIndex((x) => x === action.payload);
      if (index === -1) {
        arr.push(action.payload);
      } else {
        arr.splice(index, 1);
      }

      state.visible = arr;
    },
    setAllVisible: (state: InitState, action: PayloadAction<Array<number>>) => {
      state.visible = action.payload;
    },

    isExist: (state: InitState, action: PayloadAction<boolean>) => {
      state.isExist = action.payload;
    },
    isCreate: (state: InitState, action: PayloadAction<boolean>) => {
      state.isCreate = action.payload;
    },
    isEdit: (state: InitState, action: PayloadAction<boolean>) => {
      state.isEdit = action.payload;
    },
    isRemove: (state: InitState, action: PayloadAction<boolean>) => {
      state.isRemove = action.payload;
    },

    start: (state: InitState) => {
      state.isLoading = true;
    },
    finish: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      let isFromPopup = (action.payload && action.payload.isFromPopup) ? action.payload.isFromPopup : false;

      let latObj = (data && data.length > 0) ? data.find((x: any) => x.type === AccountSettingType.DefaultMapPositionLatitude) : null;
      let lngObj = (data && data.length > 0) ? data.find((x: any) => x.type === AccountSettingType.DefaultMapPositionLongitude) : null;
      let zoomObj = (data && data.length > 0) ? data.find((x: any) => x.type === AccountSettingType.DefaultMapPositionZoom) : null;

      let lat = (latObj && latObj.value && latObj.value != '') ? latObj.value : 1.353915;
      let lng = (lngObj && lngObj.value && lngObj.value != '') ? lngObj.value : 103.822901;
      let zoom = (zoomObj && zoomObj.value && zoomObj.value != '') ? zoomObj.value : 13;

      zoom = isFromPopup ? (zoom-1) : zoom;

      state.center = { lat: lat, lng: lng };
      state.zoom = zoom;
      state.isLoading = false;
    },
  };


  const apis = {
    callLatLngZoomApi: (isFromPopup: boolean = false) => async (dispatch: any) => {
      dispatch(actions.start());

      let arr = [
        AccountSettingType.DefaultMapPositionLatitude,
        AccountSettingType.DefaultMapPositionLongitude,
        AccountSettingType.DefaultMapPositionZoom,
      ];

      let params: any = {
        types: arr.join(',')
      }

      await dispatchCrudApi.readApi(params, 'AccountSetting').then(result => {
        let data = result.data;

        dispatch(actions.finish({ isFromPopup: isFromPopup, data: data }));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        dispatch(actions.finish({ isFromPopup: false, data: null }));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();