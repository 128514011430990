import React from "react";

import { 
    Row,
    Col,
    Form,
    Button,
    Spinner,
} from "react-bootstrap-v5";

import Carousel from 'react-elastic-carousel';

import Utils from "../../../../utils/utils";
import { JobStepType, AccountSettingType } from "../../../../utils/enums";

import { connect } from 'react-redux'
import { clear } from "../../../../../setup/redux/actions";
import { dispatchApiCallGet } from '../../../../../setup/redux/dispatch/actions'


class JobTypesSection extends React.Component {
  
    constructor(props) {
        super(props);
        
        this.name = 'job_form_job_type_section';

        this.carouselRef = React.createRef();

        let dataParams = (props.dataParams) ? props.dataParams : null;
        let id = (dataParams && dataParams.id) ? dataParams.id : null;
        let row = (dataParams && dataParams.row) ? dataParams.row : null;
        

        this.breakPoints = [
            { width: 1, itemsToShow: 1 },
            { width: 300, itemsToShow: 2 },
            { width: 550, itemsToShow: 3 },
            { width: 850, itemsToShow: 4 },
            { width: 1150, itemsToShow: 5 },
            { width: 1450, itemsToShow: 6 },
            { width: 1750, itemsToShow: 7 },
        ];


        let settings = Utils.getAccountSettings();
        let latObj = (settings && settings.length > 0) ? settings.find(x => x.type === AccountSettingType.DefaultMapPositionLatitude) : null;
        let lngObj = (settings && settings.length > 0) ? settings.find(x => x.type === AccountSettingType.DefaultMapPositionLongitude) : null;
        let addressObj = (settings && settings.length > 0) ? settings.find(x => x.type === AccountSettingType.DefaultMapPositionCountryName) : null;
        
        this.lat = (latObj && latObj.value && latObj.value != '') ? parseFloat(latObj.value) : null;
        this.lng = (lngObj && lngObj.value && lngObj.value != '') ? parseFloat(lngObj.value) : null;
        this.address = (addressObj && addressObj.value && addressObj.value != '') ? addressObj.value : '';

        
        this.state = {
            id: id,
            row: row,

            jobTemplateItems: [],
            jobTemplateIsLoading: false,
        };
    }


    componentDidMount() {
        this.callReadJobtemplateApi();
    }

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            this.name + '-jobtemplate', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = [];

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.jobTemplateId;
                        let title = item.jobTemplateName;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                if(variables){
                    let index = arr.findIndex(x => x.value == variables);
                    if(index !== -1){
                        arr.unshift(arr.splice(index, 1)[0]);
                    }
                }

                this.setState({
                    jobTemplateItems: arr,
                    jobTemplateIsLoading: false,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            this.name + '-details_jobtemplate', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(data);
                    }
                });
            }
        );
    }
	
    
    callReadJobtemplateApi = (callback = null) => {
        let {
            values,
        } = this.props.formOptions;

        this.setState({
            jobTemplateIsLoading: true,
        });

        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            sortColumn: 'common',
            sortDir: 'desc',
        };
     
        this.props.dispatchApiCallGet(data, this.name + '-jobtemplate', 'jobtemplate', values.jobTemplateId, callback, () => {});
    }

    callDetailsJobtemplateApi = (jobTemplateId = null, callback = null) => {
        if(jobTemplateId){
            this.props.dispatchApiCallGet(null, this.name + '-details_jobtemplate', 'jobtemplate/' + jobTemplateId, null, callback, () => {});
        } else {
            if(callback){
                callback(null);
            }
        }
    }
    /* END API */


    /* FUNCTIONS */
    getSelectedJobTemplate = () => {
        let {
            values,
        } = this.props.formOptions;

        let index = this.state.jobTemplateItems.findIndex(x => x.jobTemplateId === values.jobTemplateId);

        return (index > -1) ? index : 0;
    }
    /* END FUNCTIONS */


    body = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            setValues,
            values,
            errors,
        } = this.props.formOptions;
        
        return <Row className={'pb-10 mb-5 gx-custom'}>
            <Col xs={12} className={'mt-10 mb-10'}><hr /></Col>

            {
                this.state.jobTemplateIsLoading
                ?
                <Col xs={12} className={'text-center'}>
                    {/* <Spinner animation="border" /> */}
                </Col>
                :
                <Col xs={12}>
                    <Form.Group as={Row} className={'align-items-center'}>
                        <Col xs={12} className={'pb-2 pb-md-0'}>
                            <h3 className={'mb-7'}>{this.props.fields.jobTemplateId.label}</h3>
                        </Col>
                        <Col xs={12} md={true} className={'pb-2 pb-md-0'}>
                            <Form.Control
                                ref={this.carouselRef}
                                className={'custom-curousel'}

                                as = {Carousel}
                                isInvalid={!!errors.jobTemplateId}

                                id={this.props.fields.jobTemplateId.id}

                                itemsToShow={5}
                                outerSpacing={0}
                                itemPadding={[0, 20]}
                                initialActiveIndex={this.getSelectedJobTemplate()}
                                breakPoints={this.breakPoints}
                                showEmptySlots={true}
                                showArrows={false}
                                pagination={false}
                                enableSwipe={false}
                            >
                                {this.state.jobTemplateItems.map((item, i) => {
                                    return <Button key={i}
                                        className={'w-100 ' + (Utils.isCompletedOrCancelledOrFailed(values.currentStatusName) ? 'disabled' : '')}
                                        variant={(values.jobTemplateId === item.value) ? 'primary' : 'outline-secondary'}
                                        disabled={values.isParams || (values.isRecurringForm && values.isEdit) || Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.customFormParams.jobTemplateIsLoading ? true : ((values.customerId > 0) ? false : true) || values.isCollectJob}
                                        onClick={async (e) => {
                                            e.preventDefault();
                                            e.stopPropagation();

                                            if(!Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)){
                                                await setFieldValue('jobTemplateId', item.value);
                                                await setFieldValue('jobTemplateName', item.title);

                                                await setFieldValue('customFormParams.jobTemplateIsLoading', true);
                                                this.callDetailsJobtemplateApi(item.value, async (details) => {
                                                    let binActivity1 = null;
                                                    let binActivity2 = null;
                                                    let binActivity3 = null;

                                                    let jobTemplateType = null;
                                                    let templateStep = (details && details.templateSteps && details.templateSteps.length > 0) ? details.templateSteps[0] : null;
                                                    if(templateStep){
                                                        jobTemplateType = templateStep.stepType
                                                    }

                                                    let jobTemplateStep1Id = null;
                                                    let isDefaultLocation1 = false;
                                                    let templateStepSites1Items = [];
                                                    let templateStepSites1Id = null;
                                                    let templateStepSites1Name = this.address;
                                                    let templateStepSites1Address = this.address;
                                                    let templateStepSites1Latitude = this.lat;
                                                    let templateStepSites1Longitude = this.lng;

                                                    let jobTemplateStep2Id = null;
                                                    let isDefaultLocation2 = false;
                                                    let templateStepSites2Items = [];
                                                    let templateStepSites2Id = null;
                                                    let templateStepSites2Name = this.address;
                                                    let templateStepSites2Address = this.address;
                                                    let templateStepSites2Latitude = this.lat;
                                                    let templateStepSites2Longitude = this.lng;

                                                    let isRequireBinNumberToEnd1 = false;
                                                    let isRequireBinNumberToEnd2 = false;
                                                    let mustTakePhoto1 = '0';
                                                    let numberofPhotosRequired1 = '0';
                                                    let mustTakeSignature = false;
                                                    // let isRequirePaymentCollection = false;
                                                    let isRequireBinWeight = false;
                                                    let isRequireReviewWasteType = false;
                                                    let mustTakePhoto2 = '0';
                                                    let numberofPhotosRequired2 = '0';

                                                    let jobDurationHours = details && details.jobDurationHours && details.jobDurationHours.toString() !== "" ? details.jobDurationHours.toString() : "0";
                                                    let jobDurationMinutes = details && details.jobDurationMinutes && details.jobDurationMinutes.toString() !== "" ? details.jobDurationMinutes.toString() : "0";
                                                    let duration = Utils.getDuration(jobDurationHours, jobDurationMinutes);
                                              
                                              
                                                    if(details && details.templateSteps && details.templateSteps.length > 0){
                                                        let steps = details.templateSteps.sort((a, b) => a.stepOrder - b.stepOrder);

                                                        let step1 = (steps && steps.length > 0) ? steps[0] : null;
                                                        let step2 = (steps && steps.length >= 2) ? steps[1] : null;
                                                        let step3 = (steps && steps.length >=3) ? steps[2] : null;

                                                        if (jobTemplateType === JobStepType.Pull) {
                                                            if (step1) {
                                                                isRequireBinNumberToEnd2 = step1.isRequireBinNumberToEnd;
                                            
                                                                numberofPhotosRequired1 = step1.numberofPhotosRequired;
                                                                mustTakePhoto1 = step1.mustTakePhoto;
                                                                mustTakeSignature = step1.mustTakeSignature;
                                                            }
                                            
                                                            if (step2) {
                                                                numberofPhotosRequired2 = step2.numberofPhotosRequired;
                                                                mustTakePhoto2 = step2.mustTakePhoto;
                                            
                                                                isRequireBinWeight = step2.isRequireBinWeight;
                                                                isRequireReviewWasteType = step2.isRequireReviewWasteType;
                                                            }
                                                          } else if (jobTemplateType === JobStepType.Put) {
                                                            if (step1) {
                                                                isRequireBinNumberToEnd1 = step1.isRequireBinNumberToEnd;
                                                            }
                                            
                                                            if (step2) {
                                                                numberofPhotosRequired2 = step2.numberofPhotosRequired;
                                                                mustTakePhoto2 = step2.mustTakePhoto;
                                            
                                                                mustTakeSignature = step2.mustTakeSignature;
                                                            }

                                                          } else if (jobTemplateType === JobStepType.Task) {
                                                            if (step2) {
                                                                numberofPhotosRequired2 = step2.numberofPhotosRequired;
                                                                mustTakePhoto2 = step2.mustTakePhoto;
                                            
                                                                mustTakeSignature = step2.mustTakeSignature;
                                                            }
                                                            
                                                          } else if (jobTemplateType === JobStepType.Out) {
                                                            if (step1) {
                                                                isRequireBinNumberToEnd1 = step1.isRequireBinNumberToEnd;
                                                                isRequireBinWeight = step1.isRequireBinWeight;
                                                            }
                                            
                                                            if (step2) {
                                                                numberofPhotosRequired2 = step2.numberofPhotosRequired;
                                                                mustTakePhoto2 = step2.mustTakePhoto;
                                            
                                                                // mustTakeSignature = step2.mustTakeSignature;
                                                            }

                                                          } else if (jobTemplateType === JobStepType.Shift) {
                                                            if (step1) {
                                                                isRequireBinNumberToEnd2 = step1.isRequireBinNumberToEnd;
                                            
                                                                numberofPhotosRequired1 = step1.numberofPhotosRequired;
                                                                mustTakePhoto1 = step1.mustTakePhoto;
                                                                mustTakeSignature = step1.mustTakeSignature;
                                                            }
                                            
                                                            if (step2) {
                                                                numberofPhotosRequired2 = step2.numberofPhotosRequired;
                                                                mustTakePhoto2 = step2.mustTakePhoto;
                                                            }
                                                          } else {
                                                            if (jobTemplateType !== JobStepType.Pull) {
                                                              if (step1) {
                                                                isRequireBinNumberToEnd1 = step1.isRequireBinNumberToEnd;
                                                              }
                                                            }
                                            
                                                            if ((jobTemplateType !== JobStepType.Put) && (jobTemplateType !== JobStepType.Task) && (jobTemplateType !== JobStepType.Out)) {
                                                              if (
                                                                jobTemplateType === JobStepType.Pull ||
                                                                jobTemplateType === JobStepType.Exchange
                                                              ) {
                                                                if (step2) {
                                                                    isRequireBinNumberToEnd2 = step2.isRequireBinNumberToEnd;
                                                                }
                                                              }
                                            
                                                              if (step2) {
                                                                numberofPhotosRequired1 = step2.numberofPhotosRequired;
                                                                mustTakePhoto1 = step2.mustTakePhoto;
                                                                mustTakeSignature = step2.mustTakeSignature;
                                                              }
                                                            }
                                            
                                                            if (step3) {
                                                                numberofPhotosRequired2 = step3.numberofPhotosRequired;
                                                                mustTakePhoto2 = step3.mustTakePhoto;
                                            
                                                                isRequireBinWeight = step3.isRequireBinWeight;
                                                                isRequireReviewWasteType = step3.isRequireReviewWasteType;
                                                            }
                                                        }


                                                        for(let i = 0; i < steps.length; i++){
                                                            let item = steps[i];

                                                            if(i === 0){
                                                                binActivity1 = item.binActivity;
                                                            } else if(i === 1){
                                                                binActivity2 = item.binActivity;
                                                            } else if(i === 2){
                                                                binActivity3 = item.binActivity;
                                                            }
                                                        }
                                                    }

                                                    if(details && details.templateSteps && details.templateSteps.length > 0){
                                                        let templateSteps = details.templateSteps.filter(x => x.hasCustomerSite === false).sort((a, b) => a.stepOrder - b.stepOrder);
                                                        
                                                        if(templateSteps && templateSteps.length > 0){
                                                            templateSteps.forEach((item, i) => {
                                                                if(i === 0){
                                                                    isDefaultLocation1 = item.hasDefaultSite;
                                                                    jobTemplateStep1Id = item.jobTemplateStepId;

                                                                    if(item.hasDefaultSite){
                                                                        let defaultItems = item.templateStepSites.filter(x => x.isDefault === true);
                                                                        if(defaultItems && defaultItems.length > 0){
                                                                            let defaultItem = defaultItems[0];

                                                                            templateStepSites1Items = (defaultItem) ? [{
                                                                                value: defaultItem.jobTemplateStepSiteId,
                                                                                title: defaultItem.stepSiteName,
                                                                                item: defaultItem,
                                                                            }] : [];
                                                                            
                                                                            templateStepSites1Id = defaultItem.jobTemplateStepSiteId;
                                                                            templateStepSites1Name = defaultItem.stepSiteName;
                                                                            templateStepSites1Address = defaultItem.stepSiteAddress;
                                                                            templateStepSites1Latitude = defaultItem.stepSiteLatitude;
                                                                            templateStepSites1Longitude = defaultItem.stepSiteLongitude;
                                                                        }
                                                                    }
                                                                } else {
                                                                    isDefaultLocation2 = item.hasDefaultSite;
                                                                    jobTemplateStep2Id = item.jobTemplateStepId;
                                                                    
                                                                    if(item.hasDefaultSite){
                                                                        let defaultItems = item.templateStepSites.filter(x => x.isDefault === true);
                                                                        if(defaultItems && defaultItems.length > 0){
                                                                            let defaultItem = defaultItems[0];
                                                                            
                                                                            templateStepSites2Items = (defaultItem) ? [{
                                                                                value: defaultItem.jobTemplateStepSiteId,
                                                                                title: defaultItem.stepSiteName,
                                                                                item: defaultItem,
                                                                            }] : [];
                                                                            
                                                                            templateStepSites2Id = defaultItem.jobTemplateStepSiteId;
                                                                            templateStepSites2Name = defaultItem.stepSiteName;
                                                                            templateStepSites2Address = defaultItem.stepSiteAddress;
                                                                            templateStepSites2Latitude = defaultItem.stepSiteLatitude;
                                                                            templateStepSites2Longitude = defaultItem.stepSiteLongitude;
                                                                        }
                                                                    }
                                                                }
                                                            });
                                                        }
                                                    }


                                                    let statusItems = [];
                                                    if (details && details.templateStatuses && details.templateStatuses.length) {
                                                        let stat = details.templateStatuses.map((item, i) => {
                                                            return item.templateStatus;
                                                        });

                                                        let status = Utils.sortStatus(stat);

                                                        statusItems = status.map((item, i) => {
                                                            return {
                                                                value: item.jobStatusId,
                                                                title: item.jobStatusName,
                                                                disabled: false,
                                                                item: item,
                                                            };
                                                        });
                                                    }
                                                    
                                                    let disabledStatusItems = Utils.setStatusDisable(statusItems, values.currentStatusId, values.driverId, values.isEdit, values.isCopy, jobTemplateType);
                                                    
                                                    let paymentMethodItems = [];
                                                    if (details && details.jobPaymentTypeList && details.jobPaymentTypeList.length) {
                                                        paymentMethodItems = details.jobPaymentTypeList.map((item, i) => {
                                                            return {
                                                                value: i,
                                                                title: item,
                                                            };
                                                        });
                                                    }


                                                    let markers = Utils.getJobFormLocationMarkers({
                                                        customerSiteId: values.customerSiteId,
                                                        customerSiteItems: values.customFormParams.customerSiteItems,
                                                        customerSiteShiftId: values.customerSiteShiftId,
                                                        customerSiteShiftItems: values.customFormParams.customerSiteShiftItems,
                                                        templateStepSites1Latitude: templateStepSites1Latitude,
                                                        templateStepSites1Longitude: templateStepSites1Longitude,
                                                        templateStepSites2Latitude: templateStepSites2Latitude,
                                                        templateStepSites2Longitude: templateStepSites2Longitude,
                                                        jobTemplateType: jobTemplateType
                                                    }, values)

                                                    let newValues = {
                                                        ...values,

                                                        jobTemplateId: item.value,
                                                        jobTemplateName: item.title,

                                                        jobTemplateType: jobTemplateType,
                                                        isBillable: details ? details.isBillable : false,

                                                        templateStepSites1Id: templateStepSites1Id,
                                                        templateStepSites1Name: templateStepSites1Name,
                                                        templateStepSites1Address: templateStepSites1Address,
                                                        templateStepSites1Latitude: templateStepSites1Latitude,
                                                        templateStepSites1Longitude: templateStepSites1Longitude,

                                                        templateStepSites2Id: templateStepSites2Id,
                                                        templateStepSites2Name: templateStepSites2Name,
                                                        templateStepSites2Address: templateStepSites2Address,
                                                        templateStepSites2Latitude: templateStepSites2Latitude,
                                                        templateStepSites2Longitude: templateStepSites2Longitude,

                                                        isEditableBinTypeOut: details ? details.isEditableBinTypeOut : false,
                                                        isEditableWasteTypeOut: details ? details.isEditableWasteTypeOut : false,
                                                        isEditableBinNumberOut: details ? details.isEditableBinNumberOut : false,
                                                        isEditableBinNumberIn: details ? details.isEditableBinNumberIn : false,
                                                        isEditableWasteTypeIn: details ? details.isEditableWasteTypeIn : false,
                                                        duration: duration,

                                                        isRequireBinNumberToEnd1: isRequireBinNumberToEnd1,
                                                        isRequireBinNumberToEnd2: isRequireBinNumberToEnd2,
                                                        mustTakePhoto1: mustTakePhoto1,
                                                        numberofPhotosRequired1: numberofPhotosRequired1,
                                                        mustTakeSignature: mustTakeSignature,
                                                        // isRequirePaymentCollection: isRequirePaymentCollection,
                                                        isRequireBinWeight: isRequireBinWeight,
                                                        isRequireReviewWasteType: isRequireReviewWasteType,
                                                        mustTakePhoto2: mustTakePhoto2,
                                                        numberofPhotosRequired2: numberofPhotosRequired2,

                                                        weighChitTicketNumber: '',
                                                        stepBinWeight: '',
                                                        binOutWeight: '',
                                                        weighChitTareWeight: '0',
                                                        weighChitNettWeight: '0',
                                                        nonBillableBinWeight: '0',
                                                        weighChitBillableWeight: '0',
                                                        weighChitRemarks: '',
                                                        weighChitWeighBy: '',
                                                        weighChitDate: null,
                                                        weighChitTime: null,

                                                        customFormParams: {
                                                            ...values.customFormParams,

                                                            jobTemplateDetails: details,
                                                            jobTemplateIsLoading: false,
                                                            jobTemplateType: jobTemplateType,

                                                            binActivity1: binActivity1,
                                                            binActivity2: binActivity2,
                                                            binActivity3: binActivity3,

                                                            jobTemplateStep1Id: jobTemplateStep1Id,
                                                            isDefaultLocation1: isDefaultLocation1,
                                                            templateStepSites1Items: templateStepSites1Items,

                                                            jobTemplateStep2Id: jobTemplateStep2Id,
                                                            isDefaultLocation2: isDefaultLocation2,
                                                            templateStepSites2Items: templateStepSites2Items,

                                                            statusItems: statusItems,
                                                            disabledStatusItems: disabledStatusItems,
                                                            paymentMethodItems: paymentMethodItems,

                                                            totalDistance: 0,
                                                            markers: markers,

                                                            selectedBinWaste: [],
                                                        },
                                                    };

                                                    if(!values.isEdit && values.statusId === null){
                                                        let statusUnassigned = Utils.findStatus(statusItems, "title", "unassigned");
                                                        if(statusUnassigned && statusUnassigned.value) {
                                                            let statusId = (statusUnassigned.value) ? statusUnassigned.value : null;
                                                            let statusName = (statusUnassigned.title) ? statusUnassigned.title : '';
                                                            
                                                            newValues['statusId'] = statusId;
                                                            newValues['statusName'] = statusName;
                                                        }
                                                    }

                                                    await setValues(newValues);


                                                    Utils.onClearSteps(this.props.formOptions);
                                                    await Utils.onChangeBinTypeJobForm(this.props.formOptions, null, []);

                                                    if(this.props.onInitCalcDistance){
                                                        this.props.onInitCalcDistance();
                                                    }
                                                });
                                            }
                                        }}
                                    >{item.title}</Button>
                                })}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">{errors.jobTemplateId}</Form.Control.Feedback>
                        </Col>
                        <Col xs={6} md={'auto'}>
                            <a href={'/'}
                                className={'link-secondary d-block'}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    if(this.carouselRef && this.carouselRef.current){
                                        this.carouselRef.current.slidePrev()
                                    }
                                }}
                            >
                                <span className={'fa fa-arrow-circle-left fs-1'}></span>
                            </a>
                        </Col>
                        <Col xs={6} md={'auto'} className={'text-end'}>
                            <a href={'/'}
                                className={'link-secondary d-block'}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    if(this.carouselRef && this.carouselRef.current){
                                        this.carouselRef.current.slideNext()
                                    }
                                }}
                            >
                                <span className={'fa fa-arrow-circle-right fs-1'}></span>
                            </a>
                        </Col>
                    </Form.Group>
                </Col>
            }

        </Row>
    }
    

    render() {
        return this.body()
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(JobTypesSection);
