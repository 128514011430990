import React, { Component } from 'react';

import { ReactComponent as CalendarIcon } from "../../../../_metronic/assets/img/icons/calendar.svg";

import { DatePicker } from 'rsuite';
import "rsuite/dist/rsuite.min.css";


class RSuiteDatePicker extends Component {
  
	constructor(props){
		super(props);

        let value = props.value ? props.value : null;
        let icon = props.icon ? props.icon : <CalendarIcon />;
        let placeholder = props.placeholder ? props.placeholder : '';
        let appearance = props.appearance ? props.appearance : 'subtle'; // default
        let format = props.format ? props.format : 'dd/MM/yyyy';
        let block = ((props.block === false) || (props.block === true)) ? props.block : true;
        let cleanable = ((props.cleanable === false) || (props.cleanable === true)) ? props.cleanable : false;
        let showMeridian = ((props.showMeridian === false) || (props.showMeridian === true)) ? props.showMeridian : false;
        let oneTap = ((props.oneTap === false) || (props.oneTap === true)) ? props.oneTap : true;
        let isDefault = ((props.isDefault === true || props.isDefault === false) ? props.isDefault : false);
        let hideMinutes = (props.hideMinutes) ? props.hideMinutes : null;
        let placement = (props.placement) ? props.placement : 'auto';
        let ranges = (props.ranges && props.ranges.length > 0) ? props.ranges : [];

		this.state = {
            value: value,
            icon: icon,
            placeholder: placeholder,
            appearance: appearance,
            format: format,
            block: block,
            cleanable: cleanable,
            showMeridian: showMeridian,
            hideMinutes: hideMinutes,
            oneTap: oneTap,
            placement: placement,
            ranges: ranges,
            isDefault: isDefault
		}
	}
    

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.state.value) {
            let value = nextProps.value ? nextProps.value : null;
			this.setState({
				value: value
			});
        }
    }


	render() {
		return <div name={this.props.name} className={'custom-rsuite-date-picker ' +  (this.state.isDefault ? 'default-look ' : '') + (this.props.readOnly ? ' read-only ' : '') + this.props.className}>
            {this.state.icon}
            <DatePicker
                placeholder={this.state.placeholder}
                value={this.state.value}
                block={this.state.block}
                cleanable={this.state.cleanable}
                format={this.state.format}
                appearance={this.state.appearance}
                ranges={this.state.ranges}
                showMeridian={this.state.showMeridian}
                hideMinutes={this.state.hideMinutes}
                placement={this.state.placement}
                oneTap={this.state.oneTap}
                readOnly={this.props.readOnly}
                disabledDate={this.props.disabledDate}
                onChange={value => {
                    this.setState({
                        value: value
                    }, () => {
                        if(this.props.onChange){
                            this.props.onChange(value);
                        }
                    });
                }}
                onChangeCalendarDate={value => {
                    if(this.props.onChangeCalendarDate){
                        this.props.onChangeCalendarDate(value);
                    }
                }}
                onBlur={this.props.onBlur}
                onFocus={this.props.onFocus}
                isoWeek
            />
		</div>;
	}
}

export default RSuiteDatePicker;