import React from 'react';

import { isMobile, isTablet } from "react-device-detect";

import Utils from '../../utils/utils';
import WrappedButton from '../../components/Buttons/WrappedButton';

import { 
    Form,
    InputGroup,
} from "react-bootstrap-v5";
import styled from 'styled-components';


const StyledInput = styled(Form.Control)`
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-radius: 0px;
    max-height: 34px;
`;


const IconButtonSearch = ({onClick, additionalStyles, smallExpand, ...rest}) => {

    let txtRef = React.useRef();

    let [value, setValue] = React.useState((rest.value && rest.value !== '') ? rest.value : '');
    let [isOpen, setOpen] = React.useState(((rest.isOpen == false) || (rest.isOpen == true)) ? rest.isOpen : false);


    React.useEffect(() => {
        setValue((rest.value && rest.value !== '') ? rest.value : '');
    }, [rest.value]);

    React.useEffect(() => {
        setOpen(((rest.isOpen == false) || (rest.isOpen == true)) ? rest.isOpen : false);
    }, [rest.isOpen]);


    return <>
        <InputGroup>
            {!(isMobile || isTablet) && <WrappedButton 
                className={'px-3 pe-1'}
                active={isOpen}
                variant={"light"}
                size={'sm'}
                style={{ backgroundColor: 'transparent', maxHeight: '34px' }}
                onClick={(e) => {
                    setOpen(!isOpen);

                    setTimeout(() => {
                        if(txtRef && txtRef.current){
                            txtRef.current.focus();
                        }
                    }, 300);
                }}
            >
                <i className={'material-icons'}>search</i>
            </WrappedButton>}

            {((isMobile || isTablet) || isOpen) && <>
                <StyledInput 
                    ref={txtRef}
                    name='search' 
                    placeholder='Search ...' 
                    value={value}
                    disabled={rest.disabled}
                    enterkeyhint="search"
                    onChange={(e) => {
                        Utils.escapeChars(e);

                        setValue(e.target.value);
                    }}
                    onKeyPress={(e) => {
                        Utils.escapeChars(e);

                        if (e.key === 'Enter') {
                            if(rest.onPressEnter){
                                rest.onPressEnter(e.target.value);
                            }
                        }
                    }}
                    onKeyDown={(e) => {
                        if(rest.canEnter){
                            Utils.escapeChars(e);
    
                            if (e.key === 'Enter') {
                                if(rest.onPressEnter){
                                    rest.onPressEnter(e.target.value);
                                }
                            }
                        }
                    }}
                    onSearch={(e) => {
                        if(rest.onPressEnter){
                            rest.onPressEnter(e.target.value);
                        }
                    }}
                    onPaste={(e) => {
                        const pastedText = e.clipboardData.getData('text');
                        const cleanedText = Utils.escapeCharsInStringOnPaste(pastedText);
                        setValue(cleanedText);
                        e.preventDefault();
                    }}
                />

                {(!rest.disabled && isOpen && value !== '') && <WrappedButton 
                    className={'px-3 pe-1'}
                    variant={"light"}
                    size={'sm'}
                    style={{ backgroundColor: 'transparent', maxHeight: '34px' }}
                    onClick={(e) => {
                        if(rest.onClear){
                            setValue('');
                            setOpen(false);
                            rest.onClear();
                        }
                    }}
                >
                    <i className={'material-icons'}>close</i>
                </WrappedButton>}
            </>}
        </InputGroup>
        
        
    </>
};
export default IconButtonSearch;