import React, { Component } from 'react';
import moment from "moment";

import { 
    Box,
    Grid,
    CardMedia,
} from '@material-ui/core';

import CompanyProfileIcon from "../../../../_metronic/assets/img/no_img_box_shape.png";

import PODSignature from '../Partial/PODSignature';
import Card from '../../../components/OLD/Card/Card.jsx';
import InnerFilesDropzoneWithCropper from "../../../components/OLD/Dropzone/InnerDropzone/InnerFilesDropzoneWithCropper.jsx";

import Utils from '../../../utils/utils'
import { AccountDocSettingVariables, AccountDocSettingType, ServiceType, JobStepType, ReportUOM, WeighChitLayout } from '../../../utils/enums'

import OrderableListByChildren from '../../../components/orderableList/OrderableListByChildren';
import OrderableMultiListByChildren from '../../../components/orderableList/OrderableMultiListByChildren';

class ProofOfDeliveryPDF extends Component {
  
    constructor(props){
        super(props);

        this.state = {
            
        }
    }


    getAccountDocSettingVariables = () => {
        let {
            values,
        } = this.props.formOptions;
        
        let detailsDeliveryOrder = null;
        if (values && values.detailsPOD && values.detailsPOD.accountDocSettings && values.detailsPOD.accountDocSettings.length > 0) {
            let items = values.detailsPOD.accountDocSettings.filter((x) => x.type === AccountDocSettingType.DeliveryOrder);
            if (items && items.length > 0) {
                detailsDeliveryOrder = items[0];
            }
        }

        return detailsDeliveryOrder &&
          detailsDeliveryOrder.accountDocSettingVariables &&
          detailsDeliveryOrder.accountDocSettingVariables.length > 0
          ? detailsDeliveryOrder.accountDocSettingVariables
          : [];
    };
    getAccountDocSettingItem = (
        variableName = "",
        type = AccountDocSettingVariables.String,
        defaultValue = ""
      ) => {
        let accountDocSettingVariables = this.getAccountDocSettingVariables();
        let accountDocSettingItem = Utils.findSettings(
          accountDocSettingVariables,
          variableName
        );
    
        if (type === AccountDocSettingVariables.Show) {
          let defValue = Utils.strToBool(defaultValue);
          let value = Utils.findSettingsItem(accountDocSettingItem, type);
          return value === false || value === true ? value : defValue;
        } else {
          let value = Utils.findSettingsItem(accountDocSettingItem, type);
          return value && value !== "" ? value : defaultValue;
        }
    };

    getAccountDocSettingVariablesWeightChit = () => {
      let {
          values,
      } = this.props.formOptions;
      
      let detailsDeliveryOrder = null;
      if (values && values.detailsPOD && values.detailsPOD.accountDocSettings && values.detailsPOD.accountDocSettings.length > 0) {
          let items = values.detailsPOD.accountDocSettings.filter((x) => x.type === AccountDocSettingType.WeightChit);
          if (items && items.length > 0) {
              detailsDeliveryOrder = items[0];
          }
      }

      return detailsDeliveryOrder &&
        detailsDeliveryOrder.accountDocSettingVariables &&
        detailsDeliveryOrder.accountDocSettingVariables.length > 0
        ? detailsDeliveryOrder.accountDocSettingVariables
        : [];
    };
    getAccountDocSettingItemWeightChit = (
      variableName = "",
      type = AccountDocSettingVariables.String,
      defaultValue = ""
    ) => {
      let accountDocSettingVariables = this.getAccountDocSettingVariablesWeightChit();
      let accountDocSettingItem = Utils.findSettings(
        accountDocSettingVariables,
        variableName
      );
  
      if (type === AccountDocSettingVariables.Show) {
        let defValue = Utils.strToBool(defaultValue);
        let value = Utils.findSettingsItem(accountDocSettingItem, type);
        return value === false || value === true ? value : defValue;
      } else {
        let value = Utils.findSettingsItem(accountDocSettingItem, type);
        return value && value !== "" ? value : defaultValue;
      }
    };

    getSiteContact = () => {
        let {
            values,
        } = this.props.formOptions;
        
        let arr = [];

        let arr1 = [];
        let contactPerson1 = values.contactPersonOne && values.contactPersonOne !== "" ? values.contactPersonOne : "";
        let contactNumber1 = values.contactNumberOne && values.contactNumberOne !== "" ? values.contactNumberOne : "";

        if (contactPerson1 !== "") arr1.push(contactPerson1);
        if (contactNumber1 !== "") arr1.push("(" + contactNumber1 + ")");

        let CPN1 = arr1.join(" : ");
        if (CPN1 !== "") arr.push(CPN1);


        let arr2 = [];
        let contactPerson2 = values.contactPersonTwo && values.contactPersonTwo !== "" ? values.contactPersonTwo : "";
        let contactNumber2 = values.contactNumberTwo && values.contactNumberTwo !== "" ? values.contactNumberTwo : "";

        if (contactPerson2 !== "") arr2.push(contactPerson2);
        if (contactNumber2 !== "") arr2.push("(" + contactNumber2 + ")");

        let CPN2 = arr2.join(" : ");
        if (CPN2 !== "") arr.push(CPN2);

        return arr.join("\n\r");
    }

    getCollections = () => {
        let {
            values,
        } = this.props.formOptions;

        let item = Utils.getCurrency() + "0.00";

        if (
            !values.isCopy &&
            values.customFormParams.jobDetails &&
            values.customFormParams.jobDetails.attempts &&
            values.customFormParams.jobDetails.attempts.length > 0
        ) {
            let attempt1 = values.customFormParams.jobDetails.attempts[0];

            if (
                attempt1 &&
                attempt1.amountCollected &&
                attempt1.amountCollected !== ""
            ) {
                item = Utils.getCurrency() + Utils.formatNumber(attempt1.amountCollected);
            }
        }

        return item + (values.paymentMethodName && values.paymentMethodName ? " (" + values.paymentMethodName + ")" : "");
    }

    getBinOUT = () => {
        let {
            values,
        } = this.props.formOptions;

        if(values.customFormParams.jobTemplateType === JobStepType.Exchange){
            return (values.stepExchangeOutBinNumber) ? values.stepExchangeOutBinNumber : '';
            
        } else if(values.customFormParams.jobTemplateType === JobStepType.OnTheSpot){
            return (values.stepOnTheSpotBinNumber) ? values.stepOnTheSpotBinNumber : '';

        } else if(values.customFormParams.jobTemplateType === JobStepType.Shift){
            return (values.stepShiftBinNumber) ? values.stepShiftBinNumber : '';

        } else if(values.customFormParams.jobTemplateType === JobStepType.Put){
            return (values.stepPutBinNumber) ? values.stepOutBinNumber : '';
        }

        else if(values.customFormParams.jobTemplateType === JobStepType.Out){
          return (values.stepOutBinNumber) ? values.stepPutBinNumber : '';
        }

        return '';
    }
    
    getBinIN = () => {
        let {
            values,
        } = this.props.formOptions;

        if(values.customFormParams.jobTemplateType === JobStepType.Exchange){
            return (values.stepExchangeInBinNumber) ? values.stepExchangeInBinNumber : '';
            
        } else if(values.customFormParams.jobTemplateType === JobStepType.OnTheSpot){
            return (values.stepOnTheSpotBinNumber) ? values.stepOnTheSpotBinNumber : '';

        } else if(values.customFormParams.jobTemplateType === JobStepType.Pull){
            return (values.stepPullBinNumber) ? values.stepPullBinNumber : '';
        }

        return '';
    }
    
    getBinType = () => {
        let {
            values,
        } = this.props.formOptions;

        if(values.customFormParams.jobTemplateType === JobStepType.Exchange){
            return (values.stepExchangeInBinTypeName) ? values.stepExchangeInBinTypeName : '';

        } else if(values.customFormParams.jobTemplateType === JobStepType.OnTheSpot){
            return (values.stepOnTheSpotBinTypeName) ? values.stepOnTheSpotBinTypeName : '';

        } else if(values.customFormParams.jobTemplateType === JobStepType.Shift){
            return (values.stepShiftBinTypeName) ? values.stepShiftBinTypeName : '';
        
        } else if(values.customFormParams.jobTemplateType === JobStepType.Put){
            return (values.stepPutBinTypeName) ? values.stepPutBinTypeName : '';
        
        } else if(values.customFormParams.jobTemplateType === JobStepType.Out){
            return (values.stepOutBinTypeName) ? values.stepOutBinTypeName : '';
        
        } else if(values.customFormParams.jobTemplateType === JobStepType.Pull){
            return (values.stepPullBinTypeName) ? values.stepPullBinTypeName : '';
        }

        return '';
    }
    
    getWasteINType = () => {
        let {
            values,
        } = this.props.formOptions;

        if(values.customFormParams.jobTemplateType === JobStepType.Exchange){
            return (values.stepExchangeInWasteTypeName) ? values.stepExchangeInWasteTypeName : '';

        } else if(values.customFormParams.jobTemplateType === JobStepType.OnTheSpot){
            return (values.stepOnTheSpotWasteTypeName) ? values.stepOnTheSpotWasteTypeName : '';

        } else if(values.customFormParams.jobTemplateType === JobStepType.Shift){
            return (values.stepShiftWasteTypeName) ? values.stepShiftWasteTypeName : '';
        
        } else if(values.customFormParams.jobTemplateType === JobStepType.Put){
            return (values.stepPutWasteTypeName) ? values.stepPutWasteTypeName : '';
        
          } else if(values.customFormParams.jobTemplateType === JobStepType.Out){
            return (values.stepOutWasteTypeName) ? values.stepOutWasteTypeName : '';
        
        } else if(values.customFormParams.jobTemplateType === JobStepType.Pull){
            return (values.stepPullWasteTypeName) ? values.stepPullWasteTypeName : '';
        }

        return '';
    }
    
    getWasteType = (wasteTypeId) => {
        let {
            values,
        } = this.props.formOptions;

        if(values.customFormParams.jobTemplateType === JobStepType.Exchange){
            let stepExchangeInWasteTypeItems = (values.customFormParams && values.customFormParams.stepExchangeInWasteTypeItems && values.customFormParams.stepExchangeInWasteTypeItems.length > 0) ? values.customFormParams.stepExchangeInWasteTypeItems : [];
            
            let item = stepExchangeInWasteTypeItems.filter(x => x.value == wasteTypeId);
            if(item && item.length > 0){
              return item[0].title;
            }
            
        } else if(values.customFormParams.jobTemplateType === JobStepType.OnTheSpot){
            let stepOnTheSpotWasteTypeItems = (values.customFormParams && values.customFormParams.stepOnTheSpotWasteTypeItems && values.customFormParams.stepOnTheSpotWasteTypeItems.length > 0) ? values.customFormParams.stepOnTheSpotWasteTypeItems : [];
            
            let item = stepOnTheSpotWasteTypeItems.filter(x => x.value == wasteTypeId);
            if(item && item.length > 0){
              return item[0].title;
            }
            
        } else if(values.customFormParams.jobTemplateType === JobStepType.Shift){
            let stepShiftWasteTypeItems = (values.customFormParams && values.customFormParams.stepShiftWasteTypeItems && values.customFormParams.stepShiftWasteTypeItems.length > 0) ? values.customFormParams.stepShiftWasteTypeItems : [];
            
            let item = stepShiftWasteTypeItems.filter(x => x.value == wasteTypeId);
            if(item && item.length > 0){
              return item[0].title;
            }
            
        } else if(values.customFormParams.jobTemplateType === JobStepType.Put){
            return (values.stepPutWasteTypeName) ? values.stepPutWasteTypeName : '';
        
        } else if(values.customFormParams.jobTemplateType === JobStepType.Out){
          return (values.stepOutWasteTypeName) ? values.stepOutWasteTypeName : '';
        
        } else if(values.customFormParams.jobTemplateType === JobStepType.Pull){
          let stepPullWasteTypeItems = (values.customFormParams && values.customFormParams.stepPullWasteTypeItems && values.customFormParams.stepPullWasteTypeItems.length > 0) ? values.customFormParams.stepPullWasteTypeItems : [];
          
          let item = stepPullWasteTypeItems.filter(x => x.value == wasteTypeId);
          if(item && item.length > 0){
            return item[0].title;
          }
        }

        return '';
    }

    getBinTypeNonBillable = () => {
      let {
          values,
      } = this.props.formOptions;

      if(values.customFormParams.jobTemplateType === JobStepType.Exchange){
          return (values.stepExchangeInBinTypeName) ? values.stepExchangeInBinTypeName : '';

      } else if(values.customFormParams.jobTemplateType === JobStepType.OnTheSpot){
          return (values.stepOnTheSpotBinTypeName) ? values.stepOnTheSpotBinTypeName : '';

      } else if(values.customFormParams.jobTemplateType === JobStepType.Shift){
          return (values.stepShiftBinTypeName) ? values.stepShiftBinTypeName : '';
      
      } else if(values.customFormParams.jobTemplateType === JobStepType.Put){
          return (values.stepPutBinTypeName) ? values.stepPutBinTypeName : '';
      
      } else if(values.customFormParams.jobTemplateType === JobStepType.Out){
        return (values.stepOutBinTypeName) ? values.stepOutBinTypeName : '';
      
      } else if(values.customFormParams.jobTemplateType === JobStepType.Pull){
          return (values.stepPullBinTypeName) ? values.stepPullBinTypeName : '';
      }

      return '';
    }

    getWasteTypeNonBillable = () => {
      let {
          values,
      } = this.props.formOptions;

      if(values.customFormParams.jobTemplateType === JobStepType.Exchange){
          return (values.stepExchangeInWasteTypeName) ? values.stepExchangeInWasteTypeName : '';

      } else if(values.customFormParams.jobTemplateType === JobStepType.OnTheSpot){
          return (values.stepOnTheSpotWasteTypeName) ? values.stepOnTheSpotWasteTypeName : '';

      } else if(values.customFormParams.jobTemplateType === JobStepType.Shift){
          return (values.stepShiftWasteTypeName) ? values.stepShiftWasteTypeName : '';
      
      } else if(values.customFormParams.jobTemplateType === JobStepType.Put){
          return (values.stepPutWasteTypeName) ? values.stepPutWasteTypeName : '';
      
      } else if(values.customFormParams.jobTemplateType === JobStepType.Out){
        return (values.stepOutWasteTypeName) ? values.stepOutWasteTypeName : '';
      
      } else if(values.customFormParams.jobTemplateType === JobStepType.Pull){
          return (values.stepPullWasteTypeName) ? values.stepPullWasteTypeName : '';
      }

      return '';
    }

    getBinWeightNonBillable = () => {
      let {
          values,
      } = this.props.formOptions;

      return (values.stepBinWeight && values.stepBinWeight !== '') ? values.stepBinWeight : '0';
    }

    getProofOfDeliveryHeader = () => {
        return (
          <Box pb={1}>
            <Grid container alignItems={"center"}>
              <Box clone>
                <Grid item xs={12} sm={"auto"}>
                  <CardMedia
                    className={"mobile-m-0-auto mobile-mw-200-250"}
                    component={"img"}
                    src={this.getAccountDocSettingItem(
                      "StringCompanyLogo",
                      AccountDocSettingVariables.String,
                      CompanyProfileIcon
                    )}
                    title="Company Logo"
                  />
                </Grid>
              </Box>
              <Box clone p={{ xs: 0, sm: 5 }}>
                <Grid item xs={12} sm={true}>
                  <Box className={'rich-text-parse-text'}>
                    {Utils.stringToHtmlTrim(
                      this.getAccountDocSettingItem(
                        "StringHeaderText",
                        AccountDocSettingVariables.String,
                        ""
                      )
                    )}
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Box>
        );
    };

    getProofOfDeliveryHeaderSecondLayout = () => {
        let {
          values,
        } = this.props.formOptions;
      
        return (
          <Box pb={1}>
            <Grid container>
              <Box clone p={2}>
                <Grid item xs={12} sm={true}>
                  {/* {(values.accountBillingProfileName && values.accountBillingProfileName != '') && <Box className={'mb-5 text-center'}>
                    {values.accountBillingProfileName}
                  </Box>} */}
                  <Box className={'rich-text-parse-text'}>
                    {Utils.stringToHtmlTrim(
                      this.getAccountDocSettingItemWeightChit(
                        "StringHeaderText",
                        AccountDocSettingVariables.String,
                        ""
                      )
                    )}
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Box>
        );
    };

    getProofOfDeliveryPage1 = () => {
        let {
            values,
        } = this.props.formOptions;

        return (
          <Box>
            <Grid container>
              <Box clone pb={2}>
                <Grid item xs={12}>
                  <Grid container>
                    <Box clone>
                      <Grid item xs={true}>
                        <b>{this.getAccountDocSettingItem(
                          "LabelDELIVERY_ORDER",
                          AccountDocSettingVariables.Label,
                          ""
                        )}</b>
                      </Grid>
                    </Box>
                    <Box clone textAlign={"right"}>
                      <Grid item xs={true}>
                        <b>{values.customFormParams.jobNumber}</b>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>


              <Box
                clone
                pb={1}
                pt={2}
                pl={2}
                pr={2}
                style={{ border: "1px solid #E5E5E5" }}
              >
                <Grid item xs={12}>

                  <OrderableListByChildren>
                    <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowCustomerName'), null, 'sortOrder')}>
                      {this.getAccountDocSettingItem(
                        "ShowCustomerName",
                        AccountDocSettingVariables.Show,
                        false
                      ) && (
                        <Box pb={1}>
                          <Grid container>
                            <Box clone>
                              <Grid item xs={12} md={3}>
                                {this.getAccountDocSettingItem(
                                  "LabelCustomer_Name",
                                  AccountDocSettingVariables.Label,
                                  "Customer Name"
                                )}
                              </Grid>
                            </Box>
                            <Box clone>
                              <Grid item xs={12} md={9}>
                                {(values.customerItem && values.customerItem.customerName) ? values.customerItem.customerName : ''}
                              </Grid>
                            </Box>
                          </Grid>
                        </Box>
                      )}
                    </div>
      
                    <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowCustomerAddress'), null, 'sortOrder')}>
                      {this.getAccountDocSettingItem(
                        "ShowCustomerAddress",
                        AccountDocSettingVariables.Show,
                        false
                      ) && (
                        <Box pb={1}>
                          <Grid container>
                            <Box clone>
                              <Grid item xs={12} md={3}>
                                {this.getAccountDocSettingItem(
                                  "LabelCustomer_Address",
                                  AccountDocSettingVariables.Label,
                                  "Customer Address"
                                )}
                              </Grid>
                            </Box>
                            <Box clone>
                              <Grid item xs={12} md={9}>
                                {(values.customerItem && values.customerItem.billingAddress) ? values.customerItem.billingAddress : ''}
                              </Grid>
                            </Box>
                          </Grid>
                        </Box>
                      )}
                    </div>
      
                    <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowProjectSite'), null, 'sortOrder')}>
                      {this.getAccountDocSettingItem(
                        "ShowProjectSite",
                        AccountDocSettingVariables.Show,
                        false
                      ) && (
                        <Box pb={1}>
                          <Grid container>
                            <Box clone>
                              <Grid item xs={12} md={3}>
                                {this.getAccountDocSettingItem(
                                  "LabelProject_Site",
                                  AccountDocSettingVariables.Label,
                                  "Project Site"
                                )}
                              </Grid>
                            </Box>
                            <Box clone>
                              <Grid item xs={12} md={9}>
                                {(values.customerSiteAddress) ? values.customerSiteAddress : ''}
                              </Grid>
                            </Box>
                          </Grid>
                        </Box>
                      )}
                    </div>
      
                    <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowJobType'), null, 'sortOrder')}>
                      {this.getAccountDocSettingItem(
                        "ShowJobType",
                        AccountDocSettingVariables.Show,
                        false
                      ) && (
                        <Box pb={1}>
                          <Grid container>
                            <Box clone>
                              <Grid item xs={12} md={3}>
                                {this.getAccountDocSettingItem(
                                  "LabelJob_Type",
                                  AccountDocSettingVariables.Label,
                                  "Job Type"
                                )}
                              </Grid>
                            </Box>
                            <Box clone>
                              <Grid item xs={12} md={9}>
                                {(values.jobTemplateName) ? values.jobTemplateName : ''}
                              </Grid>
                            </Box>
                          </Grid>
                        </Box>
                      )}
                    </div>
      
                    <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowJobCompletion'), null, 'sortOrder')}>
                      {this.getAccountDocSettingItem(
                        "ShowJobCompletion",
                        AccountDocSettingVariables.Show,
                        false
                      ) && (
                        <Box pb={1}>
                          <Grid container>
                            <Box clone>
                              <Grid item xs={12} md={3}>
                                {this.getAccountDocSettingItem(
                                  "LabelJob_Completion",
                                  AccountDocSettingVariables.Label,
                                  "Job Completion"
                                )}
                              </Grid>
                            </Box>
                            <Box clone>
                              <Grid item xs={12} md={9}>
                                {(values.customFormParams && values.customFormParams.jobDetails && values.customFormParams.jobDetails.doCompletedDate) ? moment.utc(values.customFormParams.jobDetails.doCompletedDate).format("DD-MMM-YYYY hh:mm A") : ''}
                              </Grid>
                            </Box>
                          </Grid>
                        </Box>
                      )}
                    </div>
      
                    <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowDriver'), null, 'sortOrder')}>
                      {this.getAccountDocSettingItem(
                        "ShowDriver",
                        AccountDocSettingVariables.Show,
                        false
                      ) && (
                        <Box pb={1}>
                          <Grid container>
                            <Box clone>
                              <Grid item xs={12} md={3}>
                                {this.getAccountDocSettingItem(
                                  "LabelDriver",
                                  AccountDocSettingVariables.Label,
                                  "Driver"
                                )}
                              </Grid>
                            </Box>
                            <Box clone>
                              <Grid item xs={12} md={9}>
                                {(values.driverName) ? values.driverName : ''}
                              </Grid>
                            </Box>
                          </Grid>
                        </Box>
                      )}
                    </div>
      
                    <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowVehicle'), null, 'sortOrder')}>
                      {this.getAccountDocSettingItem(
                        "ShowVehicle",
                        AccountDocSettingVariables.Show,
                        false
                      ) && (
                        <Box pb={1}>
                          <Grid container>
                            <Box clone>
                              <Grid item xs={12} md={3}>
                                {this.getAccountDocSettingItem(
                                  "LabelVehicle",
                                  AccountDocSettingVariables.Label,
                                  "Vehicle"
                                )}
                              </Grid>
                            </Box>
                            <Box clone>
                              <Grid item xs={12} md={9}>
                                {(values.vehicleName) ? values.vehicleName : ''}
                              </Grid>
                            </Box>
                          </Grid>
                        </Box>
                      )}
                    </div>
      
                    <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowBinNumberOUT'), null, 'sortOrder')}>
                      {this.getBinOUT() !== "" &&
                        this.getAccountDocSettingItem(
                          "ShowBinNumberOUT",
                          AccountDocSettingVariables.Show,
                          false
                        ) && (
                          <Box pb={1}>
                            <Grid container>
                              <Box clone>
                                <Grid item xs={12} md={3}>
                                  {this.getAccountDocSettingItem(
                                    "LabelBin_Number_OUT",
                                    AccountDocSettingVariables.Label,
                                    "BIN OUT"
                                  )}
                                </Grid>
                              </Box>
                              <Box clone>
                                <Grid item xs={12} md={9}>
                                  {this.getBinOUT()}
                                </Grid>
                              </Box>
                            </Grid>
                          </Box>
                        )}
                      </div>
      
                      <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowBinNumberIN'), null, 'sortOrder')}>
                        {this.getBinIN() !== "" &&
                        this.getAccountDocSettingItem(
                          "ShowBinNumberIN",
                          AccountDocSettingVariables.Show,
                          false
                        ) && (
                          <Box pb={1}>
                            <Grid container>
                              <Box clone>
                                <Grid item xs={12} md={3}>
                                  {this.getAccountDocSettingItem(
                                    "LabelBin_Number_IN",
                                    AccountDocSettingVariables.Label,
                                    "Bin IN"
                                  )}
                                </Grid>
                              </Box>
                              <Box clone>
                                <Grid item xs={12} md={9} mt={3}>
                                  {this.getBinIN()}
                                </Grid>
                              </Box>
                            </Grid>
                          </Box>
                        )}
                      </div>
      
                      <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowSiteContact'), null, 'sortOrder')}>
                        {this.getAccountDocSettingItem(
                          "ShowSiteContact",
                          AccountDocSettingVariables.Show,
                          false
                        ) && (
                          <Box pb={1}>
                            <Grid container>
                              <Box clone>
                                <Grid item xs={12} md={3}>
                                  {this.getAccountDocSettingItem(
                                    "LabelSite_Contact",
                                    AccountDocSettingVariables.Label,
                                    "Site Contacts"
                                  )}
                                </Grid>
                              </Box>
                              <Box clone style={{ whiteSpace: "break-spaces" }}>
                                <Grid item xs={12} md={9}>
                                  {this.getSiteContact()}
                                </Grid>
                              </Box>
                            </Grid>
                          </Box>
                        )}
                      </div>
                      
                      <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowPaymentTerms'), null, 'sortOrder')}>
                        {this.getAccountDocSettingItem(
                          "ShowPaymentTerms",
                          AccountDocSettingVariables.Show,
                          false
                        ) && (
                          <Box pb={1}>
                            <Grid container>
                              <Box clone>
                                <Grid item xs={12} md={3}>
                                  {this.getAccountDocSettingItem(
                                    "LabelPayment_Terms",
                                    AccountDocSettingVariables.Label,
                                    "Payment Terms"
                                  )}
                                </Grid>
                              </Box>
                              <Box clone>
                                <Grid item xs={12} md={9}>
                                  {(values.paymentTermsName) ? values.paymentTermsName : ''}
                                </Grid>
                              </Box>
                            </Grid>
                          </Box>
                        )}
                      </div>

                      <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowAmountCollected'), null, 'sortOrder')}>
                        {values.isRequirePaymentCollection === true
                          ? this.getAccountDocSettingItem(
                            "ShowAmountCollected",
                            AccountDocSettingVariables.Show,
                            false
                          ) && (
                            <Box pb={1}>
                              <Grid container>
                                <Box clone>
                                  <Grid item xs={12} md={3}>
                                    {this.getAccountDocSettingItem(
                                      "LabelCollections",
                                      AccountDocSettingVariables.Label,
                                      "Collected"
                                    )}
                                  </Grid>
                                </Box>
                                <Box clone>
                                  <Grid item xs={12} md={9}>
                                    {this.getCollections()}
                                  </Grid>
                                </Box>
                              </Grid>
                            </Box>
                          )
                        : ""}
                      </div>
                  </OrderableListByChildren>

                </Grid>
              </Box>
    
                {
                  (values.pricings && values.pricings.length > 0)
                  ?
                  <>
                    {values.pricings && (values.pricings.filter(x => Utils.isStandardService(x)).length > 0) &&
                
                    <Box clone pt={10}>
                      <Grid item xs={12}>

                        <Box pb={1}>
                          <Box pb={1}>
                            <Grid container>
                              <Box clone>
                                  <Grid item xs={12} md={2}>
                                  No.
                                  </Grid>
                              </Box>

                              <OrderableListByChildren>

                                {this.getAccountDocSettingItem(
                                    "ShowStandardServicesBinType",
                                    AccountDocSettingVariables.Show,
                                    false
                                ) && (
                                  <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowStandardServicesBinType'), null, 'sortOrder')}>
                                    <Grid item xs={12} md={true} >
                                    Bin Type
                                    </Grid>
                                  </div>
                                )}

                                {this.getAccountDocSettingItem(
                                    "ShowStandardServicesWasteType",
                                    AccountDocSettingVariables.Show,
                                    false
                                ) && (
                                  <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowStandardServicesWasteType'), null, 'sortOrder')}>
                                    <Grid item xs={12} md={true}>
                                    Waste Type
                                    </Grid>
                                  </div>
                                )}
                                {this.getAccountDocSettingItem(
                                    "ShowStandardServicesQty",
                                    AccountDocSettingVariables.Show,
                                    false
                                ) && (
                                  <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowStandardServicesQty'), null, 'sortOrder')}>
                                    <Grid item xs={12} md={true}>
                                    Qty
                                    </Grid>
                                  </div>
                                )}
                                {this.getAccountDocSettingItem(
                                    "ShowStandardServicesRate",
                                    AccountDocSettingVariables.Show,
                                    false
                                ) && (
                                  <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowStandardServicesRate'), null, 'sortOrder')}>
                                    <Grid item xs={12} md={true}>
                                        Rate
                                    </Grid>
                                  </div>
                                )}
                                {this.getAccountDocSettingItem(
                                    "ShowStandardServicesPrice",
                                    AccountDocSettingVariables.Show,
                                    false
                                ) && (
                                  <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowStandardServicesPrice'), null, 'sortOrder')}>
                                    <Grid item xs={12} md={true}>
                                        Price
                                    </Grid>
                                  </div>
                                )}

                              </OrderableListByChildren>

                            </Grid>
                          </Box>
                          <Box
                            pb={1}
                            style={{ borderTop: "1px solid #E5E5E5" }}
                          ></Box>
      
                          {values.pricings.filter(x => Utils.isStandardService(x)).map((item, i) => {
                            return (
                              <Box key={i} pb={1}>
                                <Grid container>
                                  <Box clone>
                                    <Grid item xs={12} md={2}>
                                      {i + 1}
                                    </Grid>
                                  </Box>

                                  <OrderableListByChildren>

                                    {this.getAccountDocSettingItem(
                                      "ShowStandardServicesBinType",
                                      AccountDocSettingVariables.Show,
                                      false
                                    ) && (
                                      <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowStandardServicesBinType'), null, 'sortOrder')}>
                                        <Grid item xs={12} md={true}>
                                          {this.getBinType()}
                                        </Grid>
                                      </div>
                                    )}
                                    {this.getAccountDocSettingItem(
                                      "ShowStandardServicesWasteType",
                                      AccountDocSettingVariables.Show,
                                      false
                                    ) && (
                                      <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowStandardServicesWasteType'), null, 'sortOrder')}>
                                        <Grid item xs={12} md={true}>
                                        {this.getWasteType(item.wasteTypeId)}
                                        </Grid>
                                      </div>
                                    )}
                                    {this.getAccountDocSettingItem(
                                      "ShowStandardServicesQty",
                                      AccountDocSettingVariables.Show,
                                      false
                                    ) && (
                                      <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowStandardServicesQty'), null, 'sortOrder')}>
                                        <Grid item xs={12} md={true}>
                                        {item.quantity}
                                        </Grid>
                                      </div>
                                    )}
                                    {this.getAccountDocSettingItem(
                                      "ShowStandardServicesRate",
                                      AccountDocSettingVariables.Show,
                                      false
                                    ) && (
                                      <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowStandardServicesRate'), null, 'sortOrder')}>
                                        <Grid item xs={12} md={true}>
                                          {Utils.parseFloat(item.price).toFixed(2)}
                                        </Grid>
                                      </div>
                                    )}
                                    {this.getAccountDocSettingItem(
                                      "ShowStandardServicesPrice",
                                      AccountDocSettingVariables.Show,
                                      false
                                    ) && (
                                      <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowStandardServicesPrice'), null, 'sortOrder')}>
                                        <Grid item xs={12} md={true}>
                                          {(
                                            Utils.parseFloat(item.quantity) *
                                            Utils.parseFloat(item.price)
                                          ).toFixed(2)}
                                        </Grid>
                                      </div>
                                    )}
                                  
                                  </OrderableListByChildren>

                                </Grid>
                              </Box>
                            );
                          })}
                        </Box>
                      </Grid>
                    </Box>
                  }
                  </>
                  :
                  <>
                    {!values.isBillable &&
                
                      <Box clone pt={10}>
                        <Grid item xs={12}>

                          <Box pb={1}>
                            <Box pb={1}>
                              <Grid container>
                                <Box clone>
                                    <Grid item xs={12} md={2}>
                                    No.
                                    </Grid>
                                </Box>


                                <OrderableListByChildren>

                                  {this.getAccountDocSettingItem(
                                      "ShowStandardServicesBinType",
                                      AccountDocSettingVariables.Show,
                                      false
                                  ) && (
                                      <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowStandardServicesBinType'), null, 'sortOrder')}>
                                      <Grid item xs={12} md={true}>
                                      Bin Type
                                      </Grid>
                                      </div>
                                  )}
                                  {this.getAccountDocSettingItem(
                                      "ShowStandardServicesWasteType",
                                      AccountDocSettingVariables.Show,
                                      false
                                  ) && (
                                      <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowStandardServicesWasteType'), null, 'sortOrder')}>
                                      <Grid item xs={12} md={true}>
                                      Waste Type
                                      </Grid>
                                      </div>
                                  )}
                                  {this.getAccountDocSettingItem(
                                      "ShowStandardServicesQty",
                                      AccountDocSettingVariables.Show,
                                      false
                                  ) && (
                                      <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowStandardServicesQty'), null, 'sortOrder')}>
                                      <Grid item xs={12} md={true}>
                                      Qty
                                      </Grid>
                                      </div>
                                  )}
                                  {this.getAccountDocSettingItem(
                                      "ShowStandardServicesRate",
                                      AccountDocSettingVariables.Show,
                                      false
                                  ) && (
                                      <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowStandardServicesRate'), null, 'sortOrder')}>
                                      <Grid item xs={12} md={true}>
                                          Rate
                                      </Grid>
                                      </div>
                                  )}
                                  {this.getAccountDocSettingItem(
                                      "ShowStandardServicesPrice",
                                      AccountDocSettingVariables.Show,
                                      false
                                  ) && (
                                      <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowStandardServicesPrice'), null, 'sortOrder')}>
                                      <Grid item xs={12} md={true}>
                                          Price
                                      </Grid>
                                      </div>
                                  )}
                                
                                </OrderableListByChildren>

                              </Grid>
                            </Box>
                            <Box
                              pb={1}
                              style={{ borderTop: "1px solid #E5E5E5" }}
                            ></Box>
        
                            <Box pb={1}>
                              <Grid container>
                                <Box clone>
                                  <Grid item xs={12} md={2}>
                                    {1}
                                  </Grid>
                                </Box>

                                <OrderableListByChildren>

                                  {this.getAccountDocSettingItem(
                                      "ShowStandardServicesBinType",
                                      AccountDocSettingVariables.Show,
                                      false
                                  ) && (
                                      <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowStandardServicesBinType'), null, 'sortOrder')}>
                                      <Grid item xs={12} md={true}>
                                      {this.getBinTypeNonBillable()}
                                      </Grid>
                                      </div>
                                  )}
                                  {this.getAccountDocSettingItem(
                                      "ShowStandardServicesWasteType",
                                      AccountDocSettingVariables.Show,
                                      false
                                  ) && (
                                      <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowStandardServicesWasteType'), null, 'sortOrder')}>
                                      <Grid item xs={12} md={true}>
                                      {this.getWasteTypeNonBillable()}
                                      </Grid>
                                      </div>
                                  )}
                                  {this.getAccountDocSettingItem(
                                      "ShowStandardServicesQty",
                                      AccountDocSettingVariables.Show,
                                      false
                                  ) && (
                                      <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowStandardServicesQty'), null, 'sortOrder')}>
                                      <Grid item xs={12} md={true}>
                                      {this.getBinWeightNonBillable()}
                                      </Grid>
                                      </div>
                                  )}
                                  {this.getAccountDocSettingItem(
                                      "ShowStandardServicesRate",
                                      AccountDocSettingVariables.Show,
                                      false
                                  ) && (
                                      <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowStandardServicesRate'), null, 'sortOrder')}>
                                      <Grid item xs={12} md={true}>
                                      N.A.
                                      </Grid>
                                      </div>
                                  )}
                                  {this.getAccountDocSettingItem(
                                      "ShowStandardServicesPrice",
                                      AccountDocSettingVariables.Show,
                                      false
                                  ) && (
                                      <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowStandardServicesPrice'), null, 'sortOrder')}>
                                      <Grid item xs={12} md={true}>
                                      N.A.
                                      </Grid>
                                      </div>
                                  )}

                                </OrderableListByChildren>

                              </Grid>
                            </Box>
                          </Box>
                        </Grid>
                      </Box>
                    }
                  </>
                }
                
              {values.pricings && (values.pricings.filter(x => x.serviceType === ServiceType.AdditionalService).length > 0) &&
                this.getAccountDocSettingItem(
                  "ShowAdditionalServiceTable",
                  AccountDocSettingVariables.Show,
                  false
                ) && (
                  <Box clone pt={10}>
                    <Grid item xs={12}>

                      <Box pb={1}>
                        <Box pb={1}>
                          <Grid container>
                            <Box clone>
                              <Grid item xs={12} md={2}>
                                No.
                              </Grid>
                            </Box>
                            
                            <OrderableListByChildren>

                              {this.getAccountDocSettingItem(
                                "ShowAdditionalServicesDescription",
                                AccountDocSettingVariables.Show,
                                false
                              ) && (
                                <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowAdditionalServicesDescription'), null, 'sortOrder')}>
                                  <Grid item xs={12} md={true}>
                                  Additional Service
                                  </Grid>
                                </div>
                              )}
                              {this.getAccountDocSettingItem(
                                "ShowAdditionalServicesQty",
                                AccountDocSettingVariables.Show,
                                false
                              ) && (
                                <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowAdditionalServicesQty'), null, 'sortOrder')}>
                                  <Grid item xs={12} md={true}>
                                  Qty
                                  </Grid>
                                </div>
                              )}
                              {this.getAccountDocSettingItem(
                                "ShowAdditionalServicesRate",
                                AccountDocSettingVariables.Show,
                                false
                              ) && (
                                <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowAdditionalServicesRate'), null, 'sortOrder')}>
                                  <Grid item xs={12} md={true}>
                                    Rate
                                  </Grid>
                                </div>
                              )}
                              {this.getAccountDocSettingItem(
                                "ShowAdditionalServicesPrice",
                                AccountDocSettingVariables.Show,
                                false
                              ) && (
                                <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowAdditionalServicesPrice'), null, 'sortOrder')}>
                                  <Grid item xs={12} md={true}>
                                    Price
                                  </Grid>
                                </div>
                              )}

                            </OrderableListByChildren>

                          </Grid>
                        </Box>
                        <Box
                          pb={1}
                          style={{ borderTop: "1px solid #E5E5E5" }}
                        ></Box>
    
                        {values.pricings.filter(x => x.serviceType === ServiceType.AdditionalService).map((item, i) => {
                          return (
                            <Box key={i} pb={1}>
                              <Grid container>
                                <Box clone>
                                  <Grid item xs={12} md={2}>
                                    {i + 1}
                                  </Grid>
                                </Box>
                                
                                <OrderableListByChildren>

                                  {this.getAccountDocSettingItem(
                                    "ShowAdditionalServicesDescription",
                                    AccountDocSettingVariables.Show,
                                    false
                                  ) && (
                                    <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowAdditionalServicesDescription'), null, 'sortOrder')}>
                                      <Grid item xs={12} md={true}>
                                      {item.serviceTag}
                                      </Grid>
                                    </div>
                                  )}
                                  {this.getAccountDocSettingItem(
                                    "ShowAdditionalServicesQty",
                                    AccountDocSettingVariables.Show,
                                    false
                                  ) && (
                                    <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowAdditionalServicesQty'), null, 'sortOrder')}>
                                      <Grid item xs={12} md={true}>
                                      {item.quantity}
                                      </Grid>
                                    </div>
                                  )}
                                  {this.getAccountDocSettingItem(
                                    "ShowAdditionalServicesRate",
                                    AccountDocSettingVariables.Show,
                                    false
                                  ) && (
                                    <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowAdditionalServicesRate'), null, 'sortOrder')}>
                                      <Grid item xs={12} md={true}>
                                        {Utils.parseFloat(item.price).toFixed(2)}
                                      </Grid>
                                    </div>
                                  )}
                                  {this.getAccountDocSettingItem(
                                    "ShowAdditionalServicesPrice",
                                    AccountDocSettingVariables.Show,
                                    false
                                  ) && (
                                    <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariables(), 'ShowAdditionalServicesPrice'), null, 'sortOrder')}>
                                      <Grid item xs={12} md={true}>
                                        {(
                                          Utils.parseFloat(item.quantity) *
                                          Utils.parseFloat(item.price)
                                        ).toFixed(2)}
                                      </Grid>
                                    </div>
                                  )}

                                </OrderableListByChildren>

                              </Grid>
                            </Box>
                          );
                        })}
                      </Box>
                    </Grid>
                  </Box>
                )}
    
    
            <Box clone pt={5}>
                <Grid item xs={12}>
                {this.getAccountDocSettingItem(
                    "ShowMessageToCustomer",
                    AccountDocSettingVariables.Show,
                    false
                ) && (
                  <Box className={'rich-text-parse-text'}>
                    <div>{Utils.stringToHtmlTrim(this.getAccountDocSettingItem(
                        "StringMessageToCustomer",
                        AccountDocSettingVariables.String,
                        ""
                    ))}</div>
                  </Box>
                )}
                </Grid>
            </Box>

              <Box clone pt={5}>
                <Grid item xs={12}>
                  {this.getAccountDocSettingItem(
                    "ShowSignature",
                    AccountDocSettingVariables.Show,
                    false
                  ) && (
                    <Box
                      style={{ float: "right" }}
                      width={{ xs: 200, sm: 250, md: 300 }}
                    >
                      <PODSignature
                        isCopy={values.isCopy}
                        disabled={true}
                        statusText={values.statusName}
                        jobSignatures={values.jobSignatures}
                        // onChange={async (items) => {
                        //     await setFieldValue('jobSignatures', items);
                        // }}
                      />
                    </Box>
                  )}
                </Grid>
              </Box>
            </Grid>
          </Box>
        );
    };

    getProofOfDeliveryPage2 = () => {
        let {
            values,
        } = this.props.formOptions;

        return (
          <Box>
            <Grid container>
              <Box clone pb={2}>
                <Grid item xs={12}>
                  <Box clone pb={2} style={{ borderBottom: "1px solid #E5E5E5" }}>
                    <Grid container>
                      <Box clone>
                        <Grid item xs={true}>
                          <b>{this.getAccountDocSettingItem(
                            "LabelPROOF_OF_DELIVERY",
                            AccountDocSettingVariables.Label,
                            ""
                          )}</b>
                        </Grid>
                      </Box>
                      {/* <Box clone textAlign={"right"}>
                        <Grid item xs={true}>
                          <b>{values.customFormParams.jobNumber}</b>
                        </Grid>
                      </Box> */}
                    </Grid>
                  </Box>
    
                  <Box clone pt={2}>
                    <Grid container>
                      <Box clone>
                        <Grid item xs={12}>
                          <InnerFilesDropzoneWithCropper
                            className={"job-form-files-dropzone-images"}
                            maxEmptyItems={4}
                            showInput={true}
                            inputPlaceholder={"Comments on this image"}
                            path={"jobs"}
                            accept={"image/jpeg,image/jpg,image/png"}
                            acceptText={".jpeg, .jpg, .png"}
                            disabled={true}
                            value={values.photoAttachmentsUrl.map(x => x.photoUrl)}
                          />
                        </Grid>
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Box>
        );
    };

    getProofOfDeliveryPage3 = () => {
      let {
        values,
      } = this.props.formOptions;
      
      let price = 0;
      if(values.pricings && values.pricings.length > 0){
        let index = values.pricings.findIndex(x => x.useBinWeightQuantity == true);
        if(index > -1){
          price = values.pricings[index].price;
        }
      }

      let weightChitDateTime = '';
      if(values.weighChitDate){
        let wcDate = moment(values.weighChitDate).format(Utils.getAPIDateFormat());
        let wcTime = moment(values.weighChitDate).format(Utils.getDefaultTimeFormat());
        weightChitDateTime = moment(moment(wcDate + ' ' + wcTime)).format("DD-MMM-YYYY hh:mm A")
      }

      let weightChitOutDateTime = '';
      if(values.weightChitOutDateTime){
        let wcDate = moment(values.weightChitOutDateTime).format(Utils.getAPIDateFormat());
        let wcTime = moment(values.weightChitOutDateTime).format(Utils.getDefaultTimeFormat());
        weightChitOutDateTime = moment(moment(wcDate + ' ' + wcTime)).format("DD-MMM-YYYY hh:mm A")
      }

      let weighChitBillableWeight = parseFloat(values.weighChitBillableWeight);
      let rate = parseFloat(price);
      let amount = weighChitBillableWeight * rate;
      

      return (
        <Box>
          <Grid container>
            <Box clone pb={2}>
              <Grid item xs={12}>
                <Grid container>
                  <Box clone>
                    <Grid item xs={true}>
                      <b>{this.getAccountDocSettingItemWeightChit(
                        "LabelWEIGH_TICKET",
                        AccountDocSettingVariables.Label,
                        ""
                      )}</b>
                    </Grid>
                  </Box>
                  <Box clone textAlign={"right"}>
                    <Grid item xs={true}>
                      <b>TICKET NO: {values.weighChitTicketNumber}</b>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
  
            <Box
              clone
              pb={1}
              pt={2}
              pl={2}
              pr={2}
              style={{ border: "1px solid #E5E5E5" }}
            >
              <Grid item xs={12}>

                <OrderableListByChildren>

                <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowCustomerName'), null, 'sortOrder')}>
                  {this.getAccountDocSettingItemWeightChit(
                    "ShowCustomerName",
                    AccountDocSettingVariables.Show,
                    false
                  ) && (
                    <Box pb={1}>
                      <Grid container>
                        <Box clone>
                          <Grid item xs={12} md={3}>
                            {this.getAccountDocSettingItemWeightChit(
                              "LabelCustomer_Name",
                              AccountDocSettingVariables.Label,
                              "Customer Name"
                            )}
                          </Grid>
                        </Box>
                        <Box clone>
                          <Grid item xs={12} md={9}>
                            {(values.customerItem && values.customerItem.customerName) ? values.customerItem.customerName : ''}
                          </Grid>
                        </Box>
                      </Grid>
                    </Box>
                  )}
                </div>
  
                <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowCustomerAddress'), null, 'sortOrder')}>
                  {this.getAccountDocSettingItemWeightChit(
                    "ShowCustomerAddress",
                    AccountDocSettingVariables.Show,
                    false
                  ) && (
                    <Box pb={1}>
                      <Grid container>
                        <Box clone>
                          <Grid item xs={12} md={3}>
                            {this.getAccountDocSettingItemWeightChit(
                              "LabelCustomer_Address",
                              AccountDocSettingVariables.Label,
                              "Customer Address"
                            )}
                          </Grid>
                        </Box>
                        <Box clone>
                          <Grid item xs={12} md={9}>
                            {(values.customerItem && values.customerItem.billingAddress) ? values.customerItem.billingAddress : ''}
                          </Grid>
                        </Box>
                      </Grid>
                    </Box>
                  )}
                </div>
  
                <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowSiteAddress'), null, 'sortOrder')}>
                  {this.getAccountDocSettingItemWeightChit(
                    "ShowSiteAddress",
                    AccountDocSettingVariables.Show,
                    false
                  ) && (
                    <Box pb={1}>
                      <Grid container>
                        <Box clone>
                          <Grid item xs={12} md={3}>
                            {this.getAccountDocSettingItemWeightChit(
                              "LabelSite_Address",
                              AccountDocSettingVariables.Label,
                              "Site Address"
                            )}
                          </Grid>
                        </Box>
                        <Box clone>
                          <Grid item xs={12} md={9}>
                            {(values.customerSiteAddress) ? values.customerSiteAddress : ''}
                          </Grid>
                        </Box>
                      </Grid>
                    </Box>
                  )}
                </div>
  
                <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowDONumber'), null, 'sortOrder')}>
                  {this.getAccountDocSettingItemWeightChit(
                    "ShowDONumber",
                    AccountDocSettingVariables.Show,
                    false
                  ) && (
                    <Box pb={1}>
                      <Grid container>
                        <Box clone>
                          <Grid item xs={12} md={3}>
                            {this.getAccountDocSettingItemWeightChit(
                              "LabelDO_Number",
                              AccountDocSettingVariables.Label,
                              "DO Number"
                            )}
                          </Grid>
                        </Box>
                        <Box clone>
                          <Grid item xs={12} md={9}>
                            {(values.customFormParams.jobNumber) ? values.customFormParams.jobNumber : ''}
                          </Grid>
                        </Box>
                      </Grid>
                    </Box>
                  )}
                </div>
  
                <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowJobCompletion'), null, 'sortOrder')}>
                  {this.getAccountDocSettingItemWeightChit(
                    "ShowJobCompletion",
                    AccountDocSettingVariables.Show,
                    false
                  ) && (
                    <Box pb={1}>
                      <Grid container>
                        <Box clone>
                          <Grid item xs={12} md={3}>
                            {this.getAccountDocSettingItemWeightChit(
                              "LabelJob_Completion",
                              AccountDocSettingVariables.Label,
                              "Job Completion"
                            )}
                          </Grid>
                        </Box>
                        <Box clone>
                          <Grid item xs={12} md={9}>
                            {(values.customFormParams && values.customFormParams.jobDetails && values.customFormParams.jobDetails.weightChitCompletedDate) ? moment.utc(values.customFormParams.jobDetails.weightChitCompletedDate).format("DD-MMM-YYYY hh:mm A") : ''}
                          </Grid>
                        </Box>
                      </Grid>
                    </Box>
                  )}
                </div>
  
                <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowJobType'), null, 'sortOrder')}>
                  {this.getAccountDocSettingItemWeightChit(
                    "ShowJobType",
                    AccountDocSettingVariables.Show,
                    false
                  ) && (
                    <Box pb={1}>
                      <Grid container>
                        <Box clone>
                          <Grid item xs={12} md={3}>
                            {this.getAccountDocSettingItemWeightChit(
                              "LabelJob_Type",
                              AccountDocSettingVariables.Label,
                              "Job Type"
                            )}
                          </Grid>
                        </Box>
                        <Box clone>
                          <Grid item xs={12} md={9}>
                            {(values.jobTemplateName) ? values.jobTemplateName : ''}
                          </Grid>
                        </Box>
                      </Grid>
                    </Box>
                  )}
                </div>
  
                <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowBinINType'), null, 'sortOrder')}>
                  {this.getBinType() !== "" &&
                  this.getAccountDocSettingItemWeightChit(
                    "ShowBinINType",
                    AccountDocSettingVariables.Show,
                    false
                  ) && (
                    <Box pb={1}>
                      <Grid container>
                        <Box clone>
                          <Grid item xs={12} md={3}>
                            {this.getAccountDocSettingItemWeightChit(
                              "LabelBin_IN_Type",
                              AccountDocSettingVariables.Label,
                              "Bin IN Type"
                            )}
                          </Grid>
                        </Box>
                        <Box clone>
                          <Grid item xs={12} md={9}>
                            {this.getBinType()}
                          </Grid>
                        </Box>
                      </Grid>
                    </Box>
                  )}
                </div>
  
                <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowWasteINType'), null, 'sortOrder')}>
                  {this.getWasteINType() !== "" &&
                  this.getAccountDocSettingItemWeightChit(
                    "ShowWasteINType",
                    AccountDocSettingVariables.Show,
                    false
                  ) && (
                    <Box pb={1}>
                      <Grid container>
                        <Box clone>
                          <Grid item xs={12} md={3}>
                            {this.getAccountDocSettingItemWeightChit(
                              "LabelWaste_IN_Type",
                              AccountDocSettingVariables.Label,
                              "Waste IN Type"
                            )}
                          </Grid>
                        </Box>
                        <Box clone>
                          <Grid item xs={12} md={9} mt={3}>
                            {this.getWasteINType()}
                          </Grid>
                        </Box>
                      </Grid>
                    </Box>
                  )}
                </div>
  
                <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowWeighTimestamp'), null, 'sortOrder')}>
                  {this.getAccountDocSettingItemWeightChit(
                    "ShowWeighTimestamp",
                    AccountDocSettingVariables.Show,
                    false
                  ) && (
                    <Box pb={1}>
                      <Grid container>
                        <Box clone>
                          <Grid item xs={12} md={3}>
                            {this.getAccountDocSettingItemWeightChit(
                              "LabelWeigh_Timestamp",
                              AccountDocSettingVariables.Label,
                              "Weigh Timestamp"
                            )}
                          </Grid>
                        </Box>
                        <Box clone style={{ whiteSpace: "break-spaces" }}>
                          <Grid item xs={12} md={9}>
                            {weightChitDateTime}
                          </Grid>
                        </Box>
                      </Grid>
                    </Box>
                  )}
                </div>

                <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowWeighOutTimestamp'), null, 'sortOrder')}>
                  {this.getAccountDocSettingItemWeightChit(
                    "ShowWeighOutTimestamp",
                    AccountDocSettingVariables.Show,
                    false
                  ) && (
                    <Box pb={1}>
                      <Grid container>
                        <Box clone>
                          <Grid item xs={12} md={3}>
                            {this.getAccountDocSettingItemWeightChit(
                              "LabelWeigh_Out_Timestamp",
                              AccountDocSettingVariables.Label,
                              "Weigh Out Timestamp"
                            )}
                          </Grid>
                        </Box>
                        <Box clone style={{ whiteSpace: "break-spaces" }}>
                          <Grid item xs={12} md={9}>
                            {weightChitOutDateTime}
                          </Grid>
                        </Box>
                      </Grid>
                    </Box>
                  )}
                </div>
  
                <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowDriver'), null, 'sortOrder')}>
                  {this.getAccountDocSettingItemWeightChit(
                    "ShowDriver",
                    AccountDocSettingVariables.Show,
                    false
                  ) && (
                    <Box pb={1}>
                      <Grid container>
                        <Box clone>
                          <Grid item xs={12} md={3}>
                            {this.getAccountDocSettingItemWeightChit(
                              "LabelDriver",
                              AccountDocSettingVariables.Label,
                              "Driver"
                            )}
                          </Grid>
                        </Box>
                        <Box clone>
                          <Grid item xs={12} md={9}>
                            {(values.driverName) ? values.driverName : ''}
                          </Grid>
                        </Box>
                      </Grid>
                    </Box>
                  )}
                </div>
  
                <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowVehicle'), null, 'sortOrder')}>
                  {this.getAccountDocSettingItemWeightChit(
                    "ShowVehicle",
                    AccountDocSettingVariables.Show,
                    false
                  ) && (
                    <Box pb={1}>
                      <Grid container>
                        <Box clone>
                          <Grid item xs={12} md={3}>
                            {this.getAccountDocSettingItemWeightChit(
                              "LabelVehicle",
                              AccountDocSettingVariables.Label,
                              "Vehicle"
                            )}
                          </Grid>
                        </Box>
                        <Box clone>
                          <Grid item xs={12} md={9}>
                            {(values.vehicleName) ? values.vehicleName : ''}
                          </Grid>
                        </Box>
                      </Grid>
                    </Box>
                  )}
                </div>
  
                <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowWeighBy'), null, 'sortOrder')}>
                  {this.getAccountDocSettingItemWeightChit(
                    "ShowWeighBy",
                    AccountDocSettingVariables.Show,
                    false
                  ) && (
                    <Box pb={1}>
                      <Grid container>
                        <Box clone>
                          <Grid item xs={12} md={3}>
                            {this.getAccountDocSettingItemWeightChit(
                              "LabelWeigh_By",
                              AccountDocSettingVariables.Label,
                              "Weigh By"
                            )}
                          </Grid>
                        </Box>
                        <Box clone>
                          <Grid item xs={12} md={9}>
                            {(values.weighChitWeighBy) ? values.weighChitWeighBy : ''}
                          </Grid>
                        </Box>
                      </Grid>
                    </Box>
                  )}
                </div>
  
                <div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowRemarks'), null, 'sortOrder')}>
                  {this.getAccountDocSettingItemWeightChit(
                    "ShowRemarks",
                    AccountDocSettingVariables.Show,
                    false
                  ) && (
                    <Box pb={1}>
                      <Grid container>
                        <Box clone>
                          <Grid item xs={12} md={3}>
                            {this.getAccountDocSettingItemWeightChit(
                              "LabelRemarks",
                              AccountDocSettingVariables.Label,
                              "Remarks"
                            )}
                          </Grid>
                        </Box>
                        <Box clone>
                          <Grid item xs={12} md={9}>
                            {(values.weighChitRemarks) ? values.weighChitRemarks : ''}
                          </Grid>
                        </Box>
                      </Grid>
                    </Box>
                  )}
                </div>

                {(values.jobStepWeighChitCustomFields && values.jobStepWeighChitCustomFields.length > 0) && <>
                  {values.jobStepWeighChitCustomFields.map((customItem, i) => {
                    let val = (customItem.value) ? customItem.value : '';
                    if(customItem.accountCustomField.label != ''){
                      return <Box pb={1} order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowCustomField_' + customItem.accountCustomFieldId), null, 'sortOrder')}>
                        <Grid container style={{ marginBottom: '10px' }}>
                          <Box clone>
                            <Grid item xs={12} md={3}>
                              {customItem.accountCustomField.label}
                            </Grid>
                          </Box>
                          <Box clone>
                            <Grid item xs={12} md={9}>
                              {val}
                            </Grid>
                          </Box>
                        </Grid>
                      </Box>
                    }
                  })}
                </>}

                </OrderableListByChildren>

              </Grid>
            </Box>
  
            <Box clone pt={10}>
              <Grid item xs={12}>

                <Box pb={1}>
                  <Box pb={1}>
                    <Grid container>

                      <OrderableListByChildren>

                        {this.getAccountDocSettingItemWeightChit(
                            "ShowGrossWeight",
                            AccountDocSettingVariables.Show,
                            false
                        ) && (<div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowGrossWeight'), null, 'sortOrder')}>
                          <Grid item xs={12} md={true}>
                            Gross
                          </Grid>
                        </div>)}

                        {this.getAccountDocSettingItemWeightChit(
                            "ShowTareWeight",
                            AccountDocSettingVariables.Show,
                            false
                        ) && (<div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowTareWeight'), null, 'sortOrder')}>
                          <Grid item xs={12} md={true}>
                          Tare
                          </Grid>
                        </div>)}
                        
                        {this.getAccountDocSettingItemWeightChit(
                            "ShowNettWeight",
                            AccountDocSettingVariables.Show,
                            false
                        ) && (<div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowNettWeight'), null, 'sortOrder')}>
                          <Grid item xs={12} md={true}>
                          Nett
                          </Grid>
                        </div>)}

                        {this.getAccountDocSettingItemWeightChit(
                            "ShowNonBillableWeight",
                            AccountDocSettingVariables.Show,
                            false
                        ) && (<div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowNonBillableWeight'), null, 'sortOrder')}>
                          <Grid item xs={12} md={true}>
                          Non-Billable
                          </Grid>
                        </div>)}

                        {this.getAccountDocSettingItemWeightChit(
                            "ShowBillableWeight",
                            AccountDocSettingVariables.Show,
                            false
                        ) && (<div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowBillableWeight'), null, 'sortOrder')}>
                          <Grid item xs={12} md={true}>
                          Billable
                          </Grid>
                        </div>)}

                        {this.getAccountDocSettingItemWeightChit(
                            "ShowRate",
                            AccountDocSettingVariables.Show,
                            false
                        ) && (<div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowRate'), null, 'sortOrder')}>
                          <Grid item xs={12} md={true}>
                            Rate ({Utils.getCurrency()})
                          </Grid>
                        </div>)}
                        
                        {this.getAccountDocSettingItemWeightChit(
                            "ShowAmount",
                            AccountDocSettingVariables.Show,
                            false
                        ) && (<div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowAmount'), null, 'sortOrder')}>
                          <Grid item xs={12} md={true}>
                            Amount ({Utils.getCurrency()})
                          </Grid>
                        </div>)}

                      </OrderableListByChildren>

                    </Grid>
                  </Box>
                  <Box
                    pb={1}
                    style={{ borderTop: "1px solid #E5E5E5" }}
                  ></Box>

                  <Box pb={1}>
                    <Grid container>
                      
                      <OrderableListByChildren>

                        {this.getAccountDocSettingItemWeightChit(
                            "ShowGrossWeight",
                            AccountDocSettingVariables.Show,
                            false
                        ) && (<div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowGrossWeight'), null, 'sortOrder')}>
                          <Grid item xs={12} md={true}>
                            {(values.stepBinWeight) ? Utils.parseFloatToFIxed(values.stepBinWeight).toFixed((Utils.getUOM() === ReportUOM.kg) ? 0 : 3) : (Utils.getUOM() === ReportUOM.kg) ? '0' : '0.000'}
                          </Grid>
                        </div>)}

                        
                        {this.getAccountDocSettingItemWeightChit(
                            "ShowTareWeight",
                            AccountDocSettingVariables.Show,
                            false
                        ) && (<div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowTareWeight'), null, 'sortOrder')}>
                          <Grid item xs={12} md={true}>
                            {Utils.parseFloatToFIxed(values.weighChitTareWeight).toFixed((Utils.getUOM() === ReportUOM.kg) ? 0 : 3)}
                          </Grid>
                        </div>)}
                        
                        {this.getAccountDocSettingItemWeightChit(
                            "ShowNettWeight",
                            AccountDocSettingVariables.Show,
                            false
                        ) && (<div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowNettWeight'), null, 'sortOrder')}>
                          <Grid item xs={12} md={true}>
                            {Utils.parseFloatToFIxed(values.weighChitNettWeight).toFixed((Utils.getUOM() === ReportUOM.kg) ? 0 : 3)}
                          </Grid>
                        </div>)}
                        
                        {this.getAccountDocSettingItemWeightChit(
                            "ShowNonBillableWeight",
                            AccountDocSettingVariables.Show,
                            false
                        ) && (<div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowNonBillableWeight'), null, 'sortOrder')}>
                          <Grid item xs={12} md={true}>
                            {Utils.parseFloatToFIxed(values.nonBillableBinWeight).toFixed((Utils.getUOM() === ReportUOM.kg) ? 0 : 3)}
                          </Grid>
                        </div>)}

                        {this.getAccountDocSettingItemWeightChit(
                            "ShowBillableWeight",
                            AccountDocSettingVariables.Show,
                            false
                        ) && (<div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowBillableWeight'), null, 'sortOrder')}>
                          <Grid item xs={12} md={true}>
                            {Utils.parseFloatToFIxed(values.weighChitBillableWeight).toFixed((Utils.getUOM() === ReportUOM.kg) ? 0 : 3)}
                          </Grid>
                        </div>)}

                        {this.getAccountDocSettingItemWeightChit(
                            "ShowRate",
                            AccountDocSettingVariables.Show,
                            false
                        ) && (<div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowRate'), null, 'sortOrder')}>
                          <Grid item xs={12} md={true}>
                            {Utils.parseFloatToFIxed(price).toFixed(3)}
                          </Grid>
                        </div>)}

                        {this.getAccountDocSettingItemWeightChit(
                            "ShowAmount",
                            AccountDocSettingVariables.Show,
                            false
                        ) && (<div order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowAmount'), null, 'sortOrder')}>
                          <Grid item xs={12} md={true}>
                            {Utils.parseFloatToFIxed(amount).toFixed(3)}
                          </Grid>
                        </div>)}

                      </OrderableListByChildren>

                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Box>
             
            <Box clone pt={5}>
              <Grid item xs={12}>
              {/* {this.getAccountDocSettingItemWeightChit(
                  "ShowMessageToCustomer",
                  AccountDocSettingVariables.Show,
                  false
              ) && ( */}
                <Box className={'rich-text-parse-text'}>
                  <div>{Utils.stringToHtmlTrim(this.getAccountDocSettingItemWeightChit(
                      "StringMessageToCustomer",
                      AccountDocSettingVariables.String,
                      ""
                  ))}</div>
                </Box>
              {/* )} */}
              </Grid>
            </Box>

          </Grid>
        </Box>
      );
    };

    getProofOfDeliveryPage3SecondLayout = () => {
      let {
        values,
      } = this.props.formOptions;
      
      let price = 0;
      if(values.pricings && values.pricings.length > 0){
        let index = values.pricings.findIndex(x => x.useBinWeightQuantity == true);
        if(index > -1){
          price = values.pricings[index].price;
        }
      }

      let weightChitDateTime = '';
      if(values.weighChitDate){
        let wcDate = moment(values.weighChitDate).format(Utils.getAPIDateFormat());
        let wcTime = moment(values.weighChitDate).format(Utils.getDefaultTimeFormat());
        weightChitDateTime = moment(moment(wcDate + ' ' + wcTime)).format("DD-MMM-YYYY hh:mm A")
      }

      let weightChitOutDateTime = '';
      if(values.weightChitOutDateTime){
        let wcDate = moment(values.weightChitOutDateTime).format(Utils.getAPIDateFormat());
        let wcTime = moment(values.weightChitOutDateTime).format(Utils.getDefaultTimeFormat());
        weightChitOutDateTime = moment(moment(wcDate + ' ' + wcTime)).format("DD-MMM-YYYY hh:mm A")
      }

      let weighChitBillableWeight = parseFloat(values.weighChitBillableWeight);
      let rate = parseFloat(price);
      let amount = weighChitBillableWeight * rate;
      

      return (
        <Box>
          <Grid container>
            <Box
              clone
              pb={1}
              pt={2}
              pl={2}
              pr={2}
              // style={{ border: "1px solid #E5E5E5" }}
            >
              <Grid item xs={12}>

                <Box pb={1}>
                  <Grid container>
                    <Box clone>
                      <Grid item xs={12} md={4}>
                        {this.getAccountDocSettingItemWeightChit(
                          "LabelWEIGH_TICKET",
                          AccountDocSettingVariables.Label,
                          ""
                        )}
                      </Grid>
                    </Box>
                    <Box clone>
                      <Grid item xs={12} md={8}>
                      {values.weighChitTicketNumber}
                      </Grid>
                    </Box>
                  </Grid>
                </Box>


                <OrderableMultiListByChildren>

                  <div group={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowVehicle'), null, 'group')} order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowVehicle'), null, 'sortOrder')}>
                    {this.getAccountDocSettingItemWeightChit(
                      "ShowVehicle",
                      AccountDocSettingVariables.Show,
                      false
                    ) && (
                      <Box pb={1}>
                        <Grid container>
                          <Box clone>
                            <Grid item xs={12} md={4}>
                              {this.getAccountDocSettingItemWeightChit(
                                "LabelVehicle",
                                AccountDocSettingVariables.Label,
                                "Vehicle"
                              )}
                            </Grid>
                          </Box>
                          <Box clone>
                            <Grid item xs={12} md={8}>
                              {(values.vehicleName) ? values.vehicleName : ''}
                            </Grid>
                          </Box>
                        </Grid>
                      </Box>
                    )}
                  </div>
    
                  <div group={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowDriver'), null, 'group')} order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowDriver'), null, 'sortOrder')}>
                    {this.getAccountDocSettingItemWeightChit(
                      "ShowDriver",
                      AccountDocSettingVariables.Show,
                      false
                    ) && (
                      <Box pb={1}>
                        <Grid container>
                          <Box clone>
                            <Grid item xs={12} md={4}>
                              {this.getAccountDocSettingItemWeightChit(
                                "LabelDriver",
                                AccountDocSettingVariables.Label,
                                "Driver"
                              )}
                            </Grid>
                          </Box>
                          <Box clone>
                            <Grid item xs={12} md={8}>
                              {(values.driverName) ? values.driverName : ''}
                            </Grid>
                          </Box>
                        </Grid>
                      </Box>
                    )}
                  </div>

                  <div group={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowCustomerName'), null, 'group')} order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowCustomerName'), null, 'sortOrder')}>
                    {this.getAccountDocSettingItemWeightChit(
                      "ShowCustomerName",
                      AccountDocSettingVariables.Show,
                      false
                    ) && (
                      <Box pb={1}>
                        <Grid container>
                          <Box clone>
                            <Grid item xs={12} md={4}>
                              {this.getAccountDocSettingItemWeightChit(
                                "LabelCustomer_Name",
                                AccountDocSettingVariables.Label,
                                "Customer Name"
                              )}
                            </Grid>
                          </Box>
                          <Box clone>
                            <Grid item xs={12} md={8}>
                              {(values.customerItem && values.customerItem.customerName) ? values.customerItem.customerName : ''}
                            </Grid>
                          </Box>
                        </Grid>
                      </Box>
                    )}
                  </div>
    
                  <div group={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowCustomerAddress'), null, 'group')} order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowCustomerAddress'), null, 'sortOrder')}>
                    {this.getAccountDocSettingItemWeightChit(
                    "ShowCustomerAddress",
                      AccountDocSettingVariables.Show,
                      false
                    ) && (
                      <Box pb={1}>
                        <Grid container>
                          <Box clone>
                            <Grid item xs={12} md={4}>
                              {this.getAccountDocSettingItemWeightChit(
                                "LabelCustomer_Address",
                                AccountDocSettingVariables.Label,
                                "Customer Address"
                              )}
                            </Grid>
                          </Box>
                          <Box clone>
                            <Grid item xs={12} md={8}>
                              {(values.customerItem && values.customerItem.billingAddress) ? values.customerItem.billingAddress : ''}
                            </Grid>
                          </Box>
                        </Grid>
                      </Box>
                    )}
                  </div>
    
                  <div group={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowSiteAddress'), null, 'group')} order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowSiteAddress'), null, 'sortOrder')}>
                    {this.getAccountDocSettingItemWeightChit(
                      "ShowSiteAddress",
                      AccountDocSettingVariables.Show,
                      false
                    ) && (
                      <Box pb={1}>
                        <Grid container>
                          <Box clone>
                            <Grid item xs={12} md={4}>
                              {this.getAccountDocSettingItemWeightChit(
                                "LabelSite_Address",
                                AccountDocSettingVariables.Label,
                                "Site Address"
                              )}
                            </Grid>
                          </Box>
                          <Box clone>
                            <Grid item xs={12} md={8}>
                              {(values.customerSiteAddress) ? values.customerSiteAddress : ''}
                            </Grid>
                          </Box>
                        </Grid>
                      </Box>
                    )}
                  </div>
    
                  <div group={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowBinINType'), null, 'group')} order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowBinINType'), null, 'sortOrder')}>
                    {this.getBinType() !== "" &&
                    this.getAccountDocSettingItemWeightChit(
                      "ShowBinINType",
                      AccountDocSettingVariables.Show,
                      false
                    ) && (
                      <Box pb={1}>
                        <Grid container>
                          <Box clone>
                            <Grid item xs={12} md={4}>
                              {this.getAccountDocSettingItemWeightChit(
                                "LabelBin_IN_Type",
                                AccountDocSettingVariables.Label,
                                "Bin IN Type"
                              )}
                            </Grid>
                          </Box>
                          <Box clone>
                            <Grid item xs={12} md={8}>
                              {this.getBinType()}
                            </Grid>
                          </Box>
                        </Grid>
                      </Box>
                    )}
                  </div>
    
                  <div group={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowWasteINType'), null, 'group')} order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowWasteINType'), null, 'sortOrder')}>
                    {this.getWasteINType() !== "" &&
                    this.getAccountDocSettingItemWeightChit(
                      "ShowWasteINType",
                      AccountDocSettingVariables.Show,
                      false
                    ) && (
                      <Box pb={1}>
                        <Grid container>
                          <Box clone>
                            <Grid item xs={12} md={4}>
                              {this.getAccountDocSettingItemWeightChit(
                                "LabelWaste_IN_Type",
                                AccountDocSettingVariables.Label,
                                "Waste IN Type"
                              )}
                            </Grid>
                          </Box>
                          <Box clone>
                            <Grid item xs={12} md={8} mt={3}>
                              {this.getWasteINType()}
                            </Grid>
                          </Box>
                        </Grid>
                      </Box>
                    )}
                  </div>
    
                  <div group={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowJobType'), null, 'group')} order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowJobType'), null, 'sortOrder')}>
                    {this.getAccountDocSettingItemWeightChit(
                    "ShowJobType",
                      AccountDocSettingVariables.Show,
                      false
                    ) && (
                      <Box pb={1}>
                        <Grid container>
                          <Box clone>
                            <Grid item xs={12} md={4}>
                              {this.getAccountDocSettingItemWeightChit(
                                "LabelJob_Type",
                                AccountDocSettingVariables.Label,
                                "Job Type"
                              )}
                            </Grid>
                          </Box>
                          <Box clone>
                            <Grid item xs={12} md={8}>
                              {(values.jobTemplateName) ? values.jobTemplateName : ''}
                            </Grid>
                          </Box>
                        </Grid>
                      </Box>
                    )}
                  </div>
    
                  <div group={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowWeighTimestamp'), null, 'group')} order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowWeighTimestamp'), null, 'sortOrder')}>
                    {this.getAccountDocSettingItemWeightChit(
                    "ShowWeighTimestamp",
                      AccountDocSettingVariables.Show,
                      false
                    ) && (
                      <Box pb={1}>
                        <Grid container>
                          <Box clone>
                            <Grid item xs={12} md={4}>
                              {this.getAccountDocSettingItemWeightChit(
                                "LabelWeigh_Timestamp",
                                AccountDocSettingVariables.Label,
                                "Weigh Timestamp"
                              )}
                            </Grid>
                          </Box>
                          <Box clone style={{ whiteSpace: "break-spaces" }}>
                            <Grid item xs={12} md={8}>
                              {weightChitDateTime}
                            </Grid>
                          </Box>
                        </Grid>
                      </Box>
                    )}
                  </div>

                  <div group={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowGrossWeight'), null, 'group')} order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowGrossWeight'), null, 'sortOrder')}>
                    {this.getAccountDocSettingItemWeightChit(
                    "ShowGrossWeight",
                      AccountDocSettingVariables.Show,
                      false
                    ) && (
                      <Box pb={1}>
                        <Grid container>
                          <Box clone>
                            <Grid item xs={12} md={4}>
                              {this.getAccountDocSettingItemWeightChit(
                                "LabelGross_Weight",
                                AccountDocSettingVariables.Label,
                                "Gross Weight"
                              )}
                            </Grid>
                          </Box>
                          <Box clone>
                            <Grid item xs={12} md={8}>
                            {(values.stepBinWeight) ? Utils.parseFloatToFIxed(values.stepBinWeight).toFixed((Utils.getUOM() === ReportUOM.kg) ? 0 : 3) : (Utils.getUOM() === ReportUOM.kg) ? '0' : '0.000'}
                            </Grid>
                          </Box>
                        </Grid>
                      </Box>
                    )}
                  </div>

                  <div group={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowWeighOutTimestamp'), null, 'group')} order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowWeighOutTimestamp'), null, 'sortOrder')}>
                    {this.getAccountDocSettingItemWeightChit(
                    "ShowWeighOutTimestamp",
                      AccountDocSettingVariables.Show,
                      false
                    ) && (
                      <Box pb={1}>
                        <Grid container>
                          <Box clone>
                            <Grid item xs={12} md={4}>
                              {this.getAccountDocSettingItemWeightChit(
                                "LabelWeigh_Out_Timestamp",
                                AccountDocSettingVariables.Label,
                                "Weigh Out Timestamp"
                              )}
                            </Grid>
                          </Box>
                          <Box clone style={{ whiteSpace: "break-spaces" }}>
                            <Grid item xs={12} md={8}>
                              {weightChitOutDateTime}
                            </Grid>
                          </Box>
                        </Grid>
                      </Box>
                    )}
                  </div>
                  
                  <div group={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowTareWeight'), null, 'group')} order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowTareWeight'), null, 'sortOrder')}>
                    {this.getAccountDocSettingItemWeightChit(
                      "ShowTareWeight",
                      AccountDocSettingVariables.Show,
                      false
                    ) && (
                      <Box pb={1}>
                        <Grid container>
                          <Box clone>
                            <Grid item xs={12} md={4}>
                              {this.getAccountDocSettingItemWeightChit(
                                "LabelTare_Weight",
                                AccountDocSettingVariables.Label,
                                "Tare Weight"
                              )}
                            </Grid>
                          </Box>
                          <Box clone>
                            <Grid item xs={12} md={8}>
                            {Utils.parseFloatToFIxed(values.weighChitTareWeight).toFixed((Utils.getUOM() === ReportUOM.kg) ? 0 : 3)}
                            </Grid>
                          </Box>
                        </Grid>
                      </Box>
                    )}
                  </div>

                  <div group={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowNettWeight'), null, 'group')} order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowNettWeight'), null, 'sortOrder')}>
                    {this.getAccountDocSettingItemWeightChit(
                      "ShowNettWeight",
                      AccountDocSettingVariables.Show,
                      false
                    ) && (
                      <Box pb={1}>
                        <Grid container>
                          <Box clone>
                            <Grid item xs={12} md={4}>
                              {this.getAccountDocSettingItemWeightChit(
                                "LabelNett_Weight",
                                AccountDocSettingVariables.Label,
                                "Nett Weight"
                              )}
                            </Grid>
                          </Box>
                          <Box clone>
                            <Grid item xs={12} md={8}>
                            {Utils.parseFloatToFIxed(values.weighChitNettWeight).toFixed((Utils.getUOM() === ReportUOM.kg) ? 0 : 3)}
                            </Grid>
                          </Box>
                        </Grid>
                      </Box>
                    )}
                  </div>

                  <div group={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowDONumber'), null, 'group')} order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowDONumber'), null, 'sortOrder')}>
                    {this.getAccountDocSettingItemWeightChit(
                    "ShowDONumber",
                      AccountDocSettingVariables.Show,
                      false
                    ) && (
                      <Box pb={1}>
                        <Grid container>
                          <Box clone>
                            <Grid item xs={12} md={4}>
                              {this.getAccountDocSettingItemWeightChit(
                                "LabelDO_Number",
                                AccountDocSettingVariables.Label,
                                "DO Number"
                              )}
                            </Grid>
                          </Box>
                          <Box clone>
                            <Grid item xs={12} md={8}>
                              {(values.customFormParams.jobNumber) ? values.customFormParams.jobNumber : ''}
                            </Grid>
                          </Box>
                        </Grid>
                      </Box>
                    )}
                  </div>
    
                  <div group={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowJobCompletion'), null, 'group')} order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowJobCompletion'), null, 'sortOrder')}>
                    {this.getAccountDocSettingItemWeightChit(
                    "ShowJobCompletion",
                      AccountDocSettingVariables.Show,
                      false
                    ) && (
                      <Box pb={1}>
                        <Grid container>
                          <Box clone>
                            <Grid item xs={12} md={4}>
                              {this.getAccountDocSettingItemWeightChit(
                                "LabelJob_Completion",
                                AccountDocSettingVariables.Label,
                                "Job Completion"
                              )}
                            </Grid>
                          </Box>
                          <Box clone>
                            <Grid item xs={12} md={8}>
                              {(values.customFormParams && values.customFormParams.jobDetails && values.customFormParams.jobDetails.weightChitCompletedDate) ? moment.utc(values.customFormParams.jobDetails.weightChitCompletedDate).format("DD-MMM-YYYY hh:mm A") : ''}
                            </Grid>
                          </Box>
                        </Grid>
                      </Box>
                    )}
                  </div>
    
                  <div group={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowRemarks'), null, 'group')} order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowRemarks'), null, 'sortOrder')}>
                    {this.getAccountDocSettingItemWeightChit(
                    "ShowRemarks",
                      AccountDocSettingVariables.Show,
                      false
                    ) && (
                      <Box pb={1}>
                        <Grid container>
                          <Box clone>
                            <Grid item xs={12} md={4}>
                              {this.getAccountDocSettingItemWeightChit(
                                "LabelRemarks",
                                AccountDocSettingVariables.Label,
                                "Remarks"
                              )}
                            </Grid>
                          </Box>
                          <Box clone>
                            <Grid item xs={12} md={8}>
                              {(values.weighChitRemarks) ? values.weighChitRemarks : ''}
                            </Grid>
                          </Box>
                        </Grid>
                      </Box>
                    )}
                  </div>
    
                  <div group={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowWeighBy'), null, 'group')} order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowWeighBy'), null, 'sortOrder')}>
                    {this.getAccountDocSettingItemWeightChit(
                      "ShowWeighBy",
                      AccountDocSettingVariables.Show,
                      false
                    ) && (
                      <Box pb={1}>
                        <Grid container>
                          <Box clone>
                            <Grid item xs={12} md={4}>
                              {this.getAccountDocSettingItemWeightChit(
                                "LabelWeigh_By",
                                AccountDocSettingVariables.Label,
                                "Weigh By"
                              )}
                            </Grid>
                          </Box>
                          <Box clone>
                            <Grid item xs={12} md={8}>
                              {(values.weighChitWeighBy) ? values.weighChitWeighBy : ''}
                            </Grid>
                          </Box>
                        </Grid>
                      </Box>
                    )}
                  </div>
                  
                  {(values.jobStepWeighChitCustomFields && values.jobStepWeighChitCustomFields.length > 0) && <>
                    {values.jobStepWeighChitCustomFields.map((customItem, i) => {
                      let val = (customItem.value) ? customItem.value : '';
                      if(customItem.accountCustomField.label != ''){
                        return <Box key={i} pb={1} group={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowCustomField_' + customItem.accountCustomFieldId), null, 'group')} order={Utils.findSettingsItem(Utils.findSettings(this.getAccountDocSettingVariablesWeightChit(), 'ShowCustomField_' + customItem.accountCustomFieldId), null, 'sortOrder')}>
                          <Grid container style={{ marginBottom: '5px' }}>
                            <Box clone>
                              <Grid item xs={12} md={4}>
                                {customItem.accountCustomField.label}
                              </Grid>
                            </Box>
                            <Box clone>
                              <Grid item xs={12} md={8}>
                                {val}
                              </Grid>
                            </Box>
                          </Grid>
                        </Box>
                      }
                    })}
                  </>}


                  {/* {this.getAccountDocSettingItemWeightChit(
                    "ShowNonBillableWeight",
                    AccountDocSettingVariables.Show,
                    false
                  ) && (
                    <Box pb={1}>
                      <Grid container>
                        <Box clone>
                          <Grid item xs={12} md={4}>
                            {this.getAccountDocSettingItemWeightChit(
                              "LabelNonBillable_Weight",
                              AccountDocSettingVariables.Label,
                              "Non-Billable"
                            )}
                          </Grid>
                        </Box>
                        <Box clone>
                          <Grid item xs={12} md={8}>
                          {parseFloat(values.nonBillableBinWeight).toFixed((Utils.getUOM() === ReportUOM.kg) ? 0 : 3)}
                          </Grid>
                        </Box>
                      </Grid>
                    </Box>
                  )}

                  {this.getAccountDocSettingItemWeightChit(
                    "ShowBillableWeight",
                    AccountDocSettingVariables.Show,
                    false
                  ) && (
                    <Box pb={1}>
                      <Grid container>
                        <Box clone>
                          <Grid item xs={12} md={4}>
                            {this.getAccountDocSettingItemWeightChit(
                              "LabelBillable_Weight",
                              AccountDocSettingVariables.Label,
                              "Billable"
                            )}
                          </Grid>
                        </Box>
                        <Box clone>
                          <Grid item xs={12} md={8}>
                          {parseFloat(values.weighChitBillableWeight).toFixed((Utils.getUOM() === ReportUOM.kg) ? 0 : 3)}
                          </Grid>
                        </Box>
                      </Grid>
                    </Box>
                  )}

                  {this.getAccountDocSettingItemWeightChit(
                    "ShowRate",
                    AccountDocSettingVariables.Show,
                    false
                  ) && (
                    <Box pb={1}>
                      <Grid container>
                        <Box clone>
                          <Grid item xs={12} md={4}>
                            {this.getAccountDocSettingItemWeightChit(
                              "LabelBillable_Weight",
                              AccountDocSettingVariables.Label,
                              "Rate"
                            ) + ' (' + Utils.getCurrency() + ')'}
                          </Grid>
                        </Box>
                        <Box clone>
                          <Grid item xs={12} md={8}>
                          {parseFloat(price).toFixed(3)}
                          </Grid>
                        </Box>
                      </Grid>
                    </Box>
                  )}

                  {this.getAccountDocSettingItemWeightChit(
                    "ShowAmount",
                    AccountDocSettingVariables.Show,
                    false
                  ) && (
                    <Box pb={1}>
                      <Grid container>
                        <Box clone>
                          <Grid item xs={12} md={4}>
                            {this.getAccountDocSettingItemWeightChit(
                              "LabelBillable_Weight",
                              AccountDocSettingVariables.Label,
                              "Amount"
                            ) + ' (' + Utils.getCurrency() + ')'}
                          </Grid>
                        </Box>
                        <Box clone>
                          <Grid item xs={12} md={8}>
                          {parseFloat(amount).toFixed(3)}
                          </Grid>
                        </Box>
                      </Grid>
                    </Box>
                  )} */}

                </OrderableMultiListByChildren>

              </Grid>
            </Box>
  
            <Box clone pt={5}>
              <Grid item xs={12}>
              {/* {this.getAccountDocSettingItemWeightChit(
                  "ShowMessageToCustomer",
                  AccountDocSettingVariables.Show,
                  false
              ) && ( */}
                <Box className={'rich-text-parse-text'}>
                  <div>{Utils.stringToHtmlTrim(this.getAccountDocSettingItemWeightChit(
                      "StringMessageToCustomer",
                      AccountDocSettingVariables.String,
                      ""
                  ))}</div>
                </Box>
              {/* )} */}
              </Grid>
            </Box>

          </Grid>
        </Box>
      );
    };
    
    getProofOfDeliveryFooter = () => {
        return this.getAccountDocSettingItem(
          "ShowDisclaimer",
          AccountDocSettingVariables.Show,
          false
        ) ? (
          <Box pt={5} textAlign={"center"} className={'rich-text-parse-text'}>
            {Utils.stringToHtmlTrim(this.getAccountDocSettingItem(
              "StringDisclaimerText",
              AccountDocSettingVariables.String,
              ""
            ))}
          </Box>
        ) : (
          ""
        );
    };
    
    getProofOfDeliveryWeightChitFooter = () => {
        // return this.getAccountDocSettingItemWeightChit(
        //   "ShowDisclaimer",
        //   AccountDocSettingVariables.Show,
        //   false
        // ) ? (
          return <Box pt={5} textAlign={"left"} className={'rich-text-parse-text'}>
            {Utils.stringToHtmlTrim(this.getAccountDocSettingItemWeightChit(
              "StringDisclaimerText",
              AccountDocSettingVariables.String,
              ""
            ))}
          </Box>
        // ) : (
        //   ""
        // );
    };


    render() {
        let {
            values,
        } = this.props.formOptions;

          return <div className={'custom-pod-pdf-preview'}>
            <Box className={'custom-card-pdf'} style={{ height: (values.includeDeliveryOrderPOD || values.includeWeighChitPOD || values.includeWeighChitPOD) ? 'auto' : '100%'}}>
                {values.detailsPOD &&
                <Grid container justifyContent={'center'}>
                    <Grid item xs={12} lg={12}>

                        <Box pt={{ xs: 1, sm: 2,md: 3 }} pb={{ xs: 1, sm: 2,md: 3 }} pl={{ xs: 1, sm: 2,md: 3 }} pr={{ xs: 1, sm: 2,md: 3 }}>
                            <Grid container>

                                {/* PAGE 1 */}
                                {(values.includeDeliveryOrderPOD) && <Box clone pb={5}>
                                    <Grid item xs={12}>
                                    
                                        <Card className={'no-radius'}>
                                            <Box pt={2} pb={2} pl={5} pr={5}>
                                                <Grid container>
                                                    <Box clone>
                                                        <Grid item xs={12}>
                                                            {this.getProofOfDeliveryHeader()}
                                                        </Grid>
                                                    </Box>
                                                    <Box clone pt={5} pb={5}>
                                                        <Grid item xs={12}>
                                                            {this.getProofOfDeliveryPage1()}
                                                        </Grid>
                                                    </Box>
                                                    <Box clone pt={5} pb={5}>
                                                        <Grid item xs={12}>
                                                            {this.getProofOfDeliveryFooter()}
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </Box>
                                        </Card>
                                        
                                    </Grid>
                                </Box>}
                                {/* END PAGE 1 */}

                                {/* PAGE 2 */}
                                {(values.includeDeliveryOrderPOD && values.includePhotosPOD) && <Box clone pb={5}>
                                    <Grid item xs={12}>
                                    
                                        <Card className={'no-radius'}>
                                            <Box pt={2} pb={2} pl={5} pr={5}>
                                                <Grid container>
                                                    {/* <Box clone>
                                                        <Grid item xs={12}>
                                                            {this.getProofOfDeliveryHeader()}
                                                        </Grid>
                                                    </Box> */}
                                                    <Box clone>
                                                        <Grid item xs={12}>
                                                            {this.getProofOfDeliveryPage2()}
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={12}>
                                                            {this.getProofOfDeliveryFooter()}
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </Box>
                                        </Card>
                                        
                                    </Grid>
                                </Box>}
                                {/* END PAGE 2 */}


                                {/* PAGE 3 */}
                                {(values.includeWeighChitPOD) && <Box clone pb={5}>
                                    <Grid item xs={12}>
                                    
                                        <Card className={'no-radius'} style={(this.props.layout == WeighChitLayout.Receipt) ? { width: '500px', margin: '0 auto' } : {}}>
                                            <Box pt={2} pb={2} pl={5} pr={5}>
                                                <Grid container>
                                                    <Box clone>
                                                        <Grid item xs={12}>
                                                            {(this.props.layout == WeighChitLayout.Receipt) ? this.getProofOfDeliveryHeaderSecondLayout() : this.getProofOfDeliveryHeader()}
                                                        </Grid>
                                                    </Box>
                                                    <Box clone pb={5}>
                                                        <Grid item xs={12}>
                                                            {(this.props.layout == WeighChitLayout.Receipt) ? this.getProofOfDeliveryPage3SecondLayout() : this.getProofOfDeliveryPage3()}
                                                        </Grid>
                                                    </Box>
                                                    <Box clone pt={5} pb={3}>
                                                        <Grid item xs={12}>
                                                            {this.getProofOfDeliveryWeightChitFooter()}
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </Box>
                                        </Card>
                                        
                                    </Grid>
                                </Box>}
                                {/* END PAGE 3 */}

                            </Grid>
                        </Box>
                        
                    </Grid>
                </Grid>}
            </Box>
        </div>
    }
}


export default ProofOfDeliveryPDF;
