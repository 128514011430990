import React from 'react';

import { Button, Spinner } from 'react-bootstrap-v5';

const LoadingButton = (props) => {
    return <Button
        variant={props.variant}
        disabled={props.isLoading || props.disabled}
        onClick={props.onClick}
    >
        {props.isLoading ? ((props.loadingText && props.loadingText != '') ? props.loadingText : 'Please Wait ...') : props.children}
        {props.isLoading ? <span className={(props && props.children && props.children != '') ? 'ps-4' : ''}><Spinner animation="border" size={'sm'} /></span> : null}
    </Button>
}


export default LoadingButton;