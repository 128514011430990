import React from 'react';

import { Overlay, Popover } from 'react-bootstrap-v5';


const CustomPopover = (props) => {
    const [show, setShow] = React.useState(false);
    const [target, setTarget] = React.useState(null);

    var myTimeout = null;

    const ref = React.useRef(null);

    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setShow(!show);
        setTarget(e.target);
    };

    const handleMouseEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setShow(true);
        setTarget(e.target);
    };

    const handleMouseLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setShow(false);
        setTarget(e.target);
    };

    return <div ref={ref}>
        <div
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={(e) => {
                myTimeout = setTimeout(() => {
                    handleMouseLeave(e);
                }, 500);
            }}
        >{props.children}</div>

        <Overlay
            show={show}
            target={target}
            placement="top"
            container={ref}
            rootClose={true}
            onHide={() => {
                setShow(false);
                setTarget(null);
            }}
        >
            <Popover
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                onMouseEnter={() => {
                    clearTimeout(myTimeout);
                }}
                onMouseLeave={handleMouseLeave}
            >{props.title}</Popover>
        </Overlay>
    </div>
}


export default CustomPopover;