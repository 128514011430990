import React from 'react'
import styled from 'styled-components'
import { useTable, useGlobalFilter, useBlockLayout } from 'react-table'
import { VariableSizeList as List } from 'react-window'
// import { useDispatch, useSelector } from "react-redux";
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';

import { 
  Row,
  Col,
  Form,
  InputGroup,
  ProgressBar,
  Spinner,
} from "react-bootstrap-v5";

import { PER_CALL } from '../../redux/importWeighChit';

import Utils from '../../../../utils/utils';
import ProgressOverlay from "../../../../components/LoadingOverlay/ProgressOverlay";


const Styles = styled.div`
  padding: 1rem;

  .table {
    display: inline-block;
    border-spacing: 0;
    border: 0px;

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    > div {
      .tr:first-child {
        .th {
          .form-control {
            border-radius: 0px;
            border: 0px;
            border-bottom: 1px solid #F0F3FA;
            margin-bottom: 10px;

            &.selected-value {
              border-bottom: 1px solid #95989A;
            }
          }
        }
      }

      .tr:last-child {
        border-bottom: 2px solid #F0F3FA;

        .th {
          height: 50px;
          display: flex !important;
          align-content: center;
          flex-wrap: wrap;
          font-weight: bold;
        }
      }
    }

    .th, .td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #F0F3FA;
    }

    .td {
      .form-control, .rs-picker-date {
        border: 0;
        border-radius: 0;
      }

      .rs-picker-date {
        :hover {
          background-color: #F0F3FA;
          input {
            background-color: #F0F3FA;
          }
        }
      }

      .form-control:not([readonly]){
        :hover {
          background-color: #F0F3FA;
          input {
            background-color: #F0F3FA;
          }
        }

        :focus {
          background-color: #F0F3FA;
        }
      }
    }

    .are-you-sure {
      width: 100%;
      display: flex;
      align-items: center;

      > span {
        font-size: 14px;
        font-weight: bold;
        padding-right: 20px;
      }
    }
  }
`

const StyledInputGroupSeacrh = styled(InputGroup)`
    position: relative;
    margin-bottom: 10px;

    i {
        position: absolute;
        top: 10px;
        z-index: 10;
        color: #95989A;
    }

    .form-control {
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid #F0F3FA;
        padding-left: 32px;
        
        &:focus {
            border-bottom: 1px solid #95989A;
        }
    }
`;

const scrollbarWidth = () => {
    const scrollDiv = document.createElement('div')
    scrollDiv.setAttribute('style', 'width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;')
    document.body.appendChild(scrollDiv)
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
    document.body.removeChild(scrollDiv)
    return scrollbarWidth
}


const Table = React.forwardRef(({ form, columns, data, onRowDelete, onCancelRowDelete, isSearch, loading }, ref) => {
  // const state = useSelector((state) => state);
  const inputRef = React.useRef(null);

  const [searchValue, setSearchValue] = React.useState('');
  

  const defaultColumn = React.useMemo(() => ({ width: 200 }), [])
  const scrollBarSize = React.useMemo(() => scrollbarWidth(), [])
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    totalColumnsWidth,
    prepareRow,
    setGlobalFilter,
  } = useTable({ columns, data, defaultColumn }, useGlobalFilter, useBlockLayout);


  React.useEffect(() => {
    setSearchValue(searchValue);
    setGlobalFilter(searchValue);

    if (ref.current) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data, columns]);


  // const scrollToRow = (rowIndex) => {
  //   if (ref.current) {
  //     ref.current.scrollToItem(rowIndex, 'start');
  //   }
  // };
  
  
  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index]

      if(((row.values.id !== undefined) && (row.values.id !== null)) && ((row.values.id === 0) || (row.values.id > 0))){
        prepareRow(row)

        return <div
          {...row.getRowProps({
            style,
          })}
          className="tr"
          id={'row_' + index + '_ID'}
        >
          {
            (!row.values.isDelete)
            ?
            row.cells.map(cell => {
              if(cell.column.show){
                return <div {...cell.getCellProps()} className="td">
                  {cell.render('Cell')}
                </div>
              }
            })
            :
            <div className="td are-you-sure">
              <span>{row.values.isLoading ? 'Please Wait...' : 'Are you sure you want to remove this row?'}</span>

              {!row.values.isLoading && <a
                className={"active-link-i"}
                href={"/"}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
    
                  if(!row.values.isLoading){
                    form.setFieldValue(row.values.id + '.isLoading', true);
  
                    setTimeout(() => {
                      if(onRowDelete){
                        onRowDelete(row, () => {
                          form.setFieldValue(row.values.id + '.isLoading', false);
                          form.setFieldValue(row.values.id + '.isDelete', false);
                        });
                      } else {
                        form.setFieldValue(row.values.id + '.isLoading', false);
                        form.setFieldValue(row.values.id + '.isDelete', false);
                      }
                    }, 50);
                  }
                }}
              >
                <i className={'material-icons'}>check</i>
              </a>}

              {!row.values.isLoading && <a
                className={"danger-link-i"}
                href={"/"}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  
                  if(!row.values.isLoading){
                    setTimeout(() => {
                      if(onCancelRowDelete){
                        onCancelRowDelete(() => {
                          form.setFieldValue(row.values.id + '.isLoading', false);
                          form.setFieldValue(row.values.id + '.isDelete', false);
                        });
                      } else {
                        form.setFieldValue(row.values.id + '.isLoading', false);
                        form.setFieldValue(row.values.id + '.isDelete', false);
                      }
                    }, 50);
                  }
                }}
              >
                <i className={'material-icons'}>close</i>
              </a>}
            </div>
          }
        </div>
      } else {
        return null;
      }
    },
    [prepareRow, rows]
  )
  const renderEmptyRow = () => {
    return <div className="tr">
      <div className="td" style={{
        paddingLeft: '60px',
        paddingTop: '15px',
      }}>
        <h4>No data to display</h4>
      </div>
    </div>
  }
  // const renderLoadingRow = () => {
  //   return <div className="tr">
  //     <div className="td" style={{
  //       paddingLeft: '60px',
  //       paddingTop: '15px',
  //     }}>
  //       <h4>Loading...</h4>
  //     </div>
  //   </div>
  // }

  const getItemSize = (index) => {
    // return validationSchemaRow(state, data[index]) ? 55 : 80;

    let maxHeight = 80;
    let isValid = true;
    try {
      let tr = document.getElementById('row_' + index + '_ID');
      let feedbacks = (tr) ? tr.getElementsByClassName('invalid-feedback') : [];
      isValid = !(Array.from(feedbacks).some((e) => e.textContent.trim() !== ''));

      if(!isValid && feedbacks && feedbacks.length > 0){
        for(let i = 0; i < feedbacks.length; i++){
          if(feedbacks[i].textContent.trim() !== ''){
            let boundingBox = feedbacks[i].getBoundingClientRect();
            let height = boundingBox.height;
  
            if(height > maxHeight){
              maxHeight = height
            }
          }
        }
      }
    } catch(e){}
    
    return isValid ? 55 : maxHeight + 25;
  }
  const onItemsRendered = (props) => {
    if (ref.current) {
      ref.current.resetAfterIndex(props.visibleStartIndex, true);
    }
  };

  return <>
    {isSearch && <Row>
      <Col xs={'auto'}><div style={{ width: '38px' }}></div></Col>
      <Col xs={8} md={4} xl={3}>
        <StyledInputGroupSeacrh>
          <i className={'material-icons'}>search</i>
          <Form.Control 
            ref={inputRef}
            value={searchValue || ''}
            placeholder={'Search in...'}
            autoFocus={false}
            
            onChange={(e: any) => {
              Utils.escapeChars(e);
              setSearchValue(e.target.value);
              setGlobalFilter(e.target.value);
            }}
            onKeyPress={(e: any) => {
              Utils.escapeChars(e);
            }}
            onPaste={(e: any) => {
              const pastedText = e.clipboardData.getData('text');
              const cleanedText = Utils.escapeCharsInStringOnPaste(pastedText);
              
              if(inputRef && inputRef.current){
                inputRef.current.value = cleanedText;
              }
              
              e.preventDefault();
              
              setSearchValue(cleanedText);
              setGlobalFilter(cleanedText);
            }}
          />
        </StyledInputGroupSeacrh>
      </Col>
    </Row>}
      
    <div {...getTableProps()} className="table">
      <div>
        {headerGroups.map(headerGroup => <div {...headerGroup.getHeaderGroupProps()} className="tr">
          {headerGroup.headers.map(column => column.hideHeader === false ? null : !column.show ? null : <div {...column.getHeaderProps()} className="th">
            {column.render('Header')}
          </div>)}
        </div>)}
      </div>

      <div {...getTableBodyProps()} style={{ display: 'flex', flexGrow: 1, height: "50vh" }}>
        {
        // loading
        // ?
        // renderLoadingRow()
        // :
        (rows.filter((x: any) => (x.values.id !== undefined) && (x.values.id !== null)).length === 0)
        ?
        renderEmptyRow()
        :
        <AutoSizer>
          {({ height }: Size) => (
            <List
              ref={ref}
              height={height}
              itemCount={rows.length}
              itemSize={getItemSize}
              onItemsRendered={onItemsRendered}
              width={totalColumnsWidth+scrollBarSize}
            >
              {RenderRow}
            </List>
          )}
        </AutoSizer>
        }
      </div>
    </div>
  </>
});


type MyTableComponentProps = {
  ref: MutableRefObject<any>;
  form: FormikProps<any>;
  columns: any;
  onRowDelete?: (row: any, callback: () => void) => void;
  onCancelRowDelete?: (callback: () => void) => void;
  isSearch: boolean;
  loading?: boolean;
  percent?: number|null;
};

const MyTable: React.FC<MyTableComponentProps> = React.forwardRef((props, ref) => {
  return <Styles>
    <Table ref={ref} form={props.form} columns={props.columns} data={props.form.values.filter((x: any) => x !== undefined)} onRowDelete={props.onRowDelete} onCancelRowDelete={props.onCancelRowDelete} isSearch={props.isSearch} loading={props.loading} />
    <ProgressOverlay active={props.loading}>
      <div style={{ width: '70vw' }}>
        {
        ((props.percent === null) || (props.form.values.filter((x: any) => x !== undefined).length <= PER_CALL))
        ?
        <>
          <Spinner animation="border" variant="light" />
          <div>Loading ...</div>
        </>
        :
        <>
          <h3 style={{ color: 'white' }}>Please Wait... ({props.percent}%)</h3>
          <ProgressBar min={0} max={100} now={props.percent} />
        </>
        }
      </div>
    </ProgressOverlay>
  </Styles>
});

export default React.memo(MyTable)
