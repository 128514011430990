import React, { Component } from 'react';

import { 
    Box,
    Grid,
} from '@material-ui/core';

import ManageLabel from '../../../../components/OLD/input/ManageLabel.jsx';

import Utils from '../../../../utils/utils.js';


class MenuSection extends Component {

    constructor(props){
        super(props);

        let id = (props.id && props.id > 0) ? props.id : 0;
        let isCopy = ((props.isCopy === true || props.isCopy === false) ? props.isCopy : false);
        let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
        let disabled = ((props.disabled === true || props.disabled === false) ? props.disabled : false);
        let jobType = (props.jobType && props.jobType) ? props.jobType : 0;

        let items = [
            {
                value: 1,
                title: 'Addresses',
                elementId: '#section_1_ID',
            },
            {
                value: 2,
                title: 'Job Steps',
                elementId: '#section_2_ID',
            },
            {
                value: 3,
                title: 'App Permission',
                elementId: '#section_3_ID',
            },
            {
                value: 4,
                title: 'Others',
                elementId: '#section_4_ID',
            },
        ];

        let item = 0;

        this.state = {
            id: id,
            isCopy: isCopy,
            isLoading: isLoading,
            disabled: disabled,
            jobType: jobType,
            
            items: items,
            item: item,
        }
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.isCopy !== this.state.isCopy) {
            let isCopy = ((nextProps.isCopy === true || nextProps.isCopy === false) ? nextProps.isCopy : false);
            this.setState({
                isCopy: isCopy
            });
        }
        if (nextProps.isLoading !== this.state.isLoading) {
            let isLoading = ((nextProps.isLoading === true || nextProps.isLoading === false) ? nextProps.isLoading : false);
            this.setState({
                isLoading: isLoading
            });
        }
        if (nextProps.disabled !== this.state.disabled) {
            let disabled = ((nextProps.disabled === true || nextProps.disabled === false) ? nextProps.disabled : false);
            this.setState({
                disabled: disabled
            });
        }
        if (nextProps.jobType !== this.state.jobType) {
            let jobType = (nextProps.jobType && nextProps.jobType) ? nextProps.jobType : 0;
            this.setState({
                jobType: jobType
            });
        }
    }


    /* FUNCTION */
    scrollTo(selector) {
        Utils.scrollToEl(selector);
    }
    /* END FUNCTION */


    /* GETTER & SETTER */
    getItem = () => {
        return this.state.item
    }
    setItem = (item = null) => {
        this.setState({
            item: item
        });
    }
    /* END GETTER & SETTER */


    setItemsForm = () => {
        return <Grid container alignItems={'center'}>
            {this.state.items.map((item, i) => {
                return <Box key={i} clone className={'job-type-form-menu-item ' + ((item.value === this.state.item) ? 'active' : '')}
                    onClick={() => {
                        this.setState({
                            item: item.value
                        }, () => {
                            this.scrollTo(item.elementId);

                            if(this.props.onClick){
                                this.props.onClick(this.state.value);
                            }
                        });
                    }}
                >
                    <Grid item xs={12}>
                        <h6>{item.title}</h6>
                    </Grid>
                </Box>
            })}
        </Grid>
    }


    render() {
        return <Box className={'job-type-form-menu'}>
            {this.setItemsForm()}
        </Box>
    }
}

export default MenuSection;
