import React, { Component } from 'react';

import apiUtil from '../../../../api/apiUtil.jsx';
// import Api from "../../../../api/CommonProfile/Account.jsx";

import logo from '../../../../../_metronic/assets/img/logo.png';

import ManageLabel from '../../../../components/OLD/input/ManageLabel.jsx';


class CustomerInfo extends Component {

	constructor(props){
		super(props);

        let item = props.item ? props.item : null;

		this.state = {
            item: item,

            accountName: '',
            address: '',
            phone: '',
            faxNumber: '',
            uenNumber: '',
            gstNumber: '',
            logoImageUrl: logo,
		}
	}
	
	
    componentDidMount() {
        this.setState({
            item: this.getUserInfo()
        });
    }

	componentWillReceiveProps(nextProps) {
		if (nextProps.item !== this.state.item) {
            let item = nextProps.item ? nextProps.item : null;
			this.setState({
				item: item
			});
        }
	}


    getUserInfo = () => {
        const { dispatchUser } = this.context;
        let item = apiUtil.getUserInfo(dispatchUser);
        
        if(item){
            this.setState({
                accountName: item.accountName,
                address: item.address,
                phone: item.phone,
                faxNumber: item.faxNumber,
                uenNumber: item.uenNumber,
                gstNumber: item.gstNumber,
                logoImageUrl: item.logoImageUrl,
			});
        } else {
            this.setState({
                accountName: '',
                address: '',
                phone: '',
                faxNumber: '',
                uenNumber: '',
                gstNumber: '',
                logoImageUrl: logo,
			});
        }
    }


	render() {
		return <div className={'row height-150'}>
            {/* <div className={'col-xs-12 pdf-col-sm-3 col-lg-3'}>
                <img className={'reports-avatart'} src={this.state.logoImageUrl} alt={'logo'} />
            </div>
            <div className={'col-xs-12 pdf-col-sm-9 col-lg-9'}>
                <div className={'row'}>
                    <div className={'col-xs-12'}>
                        <ManageLabel
                            className={'no-margin'}
                            label={this.state.accountName}
                            size={'xs'}
                        />
                    </div>
                    <div className={'col-xs-12'}>{this.state.address}</div>
                </div>
                <div className={'row'}>
                    <div className={'col-xs-6 pdf-col-sm-6 col-lg-6'}>Tel: {this.state.phone}</div>
                    <div className={'col-xs-6 pdf-col-sm-6 col-lg-6'}>Fax: {this.state.faxNumber}</div>
                </div>
                <div className={'row'}>
                    <div className={'col-xs-6 pdf-col-sm-6 col-lg-6'}>UEN: {this.state.uenNumber}</div>
                    <div className={'col-xs-6 pdf-col-sm-6 col-lg-6'}>GST: {this.state.gstNumber}</div>
                </div>
            </div> */}
        </div>
	}
}

export default CustomerInfo;
