import React from 'react';

import Select from 'react-dropdown-select';
import ManageLabel from '../input/ManageLabel.jsx';

const CustomDropdownMultiSelect = React.forwardRef((props, ref) => {
    const [className] = React.useState(props.className ? props.className : '');
    const [options, setOptions] = React.useState(props.options ? props.options : []);
    const [values, setValues] = React.useState(props.values ? props.values : []);
    const [loading, setLoading] = React.useState(props.loading ? props.loading : false);
    const [placeholder] = React.useState(props.placeholder ? props.placeholder : '');
    const [label] = React.useState(props.label ? props.label : '');
    const [labelComponent] = React.useState(props.labelComponent ? props.labelComponent : null);
    const [clearable] = React.useState(props.clearable ? props.clearable : false);
    const [disabled, setDisabled] = React.useState(props.disabled ? props.disabled : false);
    const [required] = React.useState(props.required ? props.required : false);
    const [error, setError] = React.useState(props.error ? props.error : false);
    const [searchable] = React.useState(props.searchable ? props.searchable : true);
    const [trnasparantBg] = React.useState(props.trnasparantBg ? props.trnasparantBg : false);

    const [labelField] = React.useState(props.labelField ? props.labelField : 'label');
    const [valueField] = React.useState(props.valueField ? props.valueField : 'value');


    React.useEffect(() => {
        setOptions(props.options ? props.options : []);
        setValues(props.values ? props.values : []);
        setLoading(props.loading ? props.loading : false);
        setDisabled(props.disabled ? props.disabled : false);
        setError(props.error ? props.error : false);
    }, [props.options, props.values, props.loading, props.disabled, props.error]);

    
    return (
        <div className={ (trnasparantBg ===true ? "custom-dropdown-select-component-transparant ":" custom-dropdown-select-component ") + className + (disabled ? ' disabled' : '')}>
            {label && <ManageLabel 
                className={"custom-label"}
                label={label}
                labelComponent={labelComponent}
                disabled={disabled}
                error={error}
                required={required}
            />}
            <Select
                ref={ref}
                placeholder={placeholder}
                addPlaceholder={placeholder}
                options={options}
                values={values}
                multi={true}
                dropdownHandle={false}
                required={required}
                disabled={disabled}
                searchable={searchable}
                clearable={clearable}
                labelField={labelField}
                valueField={valueField}
                loading={loading}
                dropdownPosition="auto"
                // portal={typeof document !== `undefined` && document.body}
                optionRenderer={(obj) => {
                    if(props.optionRenderer){
                        return props.optionRenderer(obj);
                    } else {
                        return null;
                    }
                }}
                noDataRenderer={(obj) => {
                    if(props.noDataRenderer){
                        return props.noDataRenderer(obj);
                    } else {
                        return null;
                    }
                }}
                onChange={(values) => {
                    if(props.onChange){
                        return props.onChange(values);
                    } else {
                        return null;
                    }
                }}
                searchFn={(values) => {
                    if(props.onSearch){
                        return props.onSearch(values.state.search);
                    } else {
                        return null;
                    }
                }}
                onDropdownOpen={() => {
                    if(props.onOpen){
                        return props.onOpen();
                    } else {
                        return null;
                    }
                }}
                onDropdownClose={() => {
                    if(props.onClose){
                        return props.onClose();
                    } else {
                        return null;
                    }
                }}
            />
        </div>
    );
});

export default CustomDropdownMultiSelect;