import { createSlice } from "@reduxjs/toolkit";
import moment from 'moment';

import Utils from "../../../app/utils/utils";
import { PageCookieSettingType } from "../../../app/utils/enums";


function NewReducer() {
  const name = 'dateSection';

  
  const initialState = {
    fromDate: moment().startOf('week').format(Utils.getAPIDateFormat()),
    toDate: moment().endOf('week').format(Utils.getAPIDateFormat()),
    dateRange: Utils.getDateRangeValue("This Week"),
    format: Utils.getDateRangeFormat("This Week"),
  };


  const reducers = {
    dateGetFilters: (state, action) => {
      // const auth = (action.payload.auth) ? action.payload.auth : null;
      const settingsType = (action.payload.settingsType) ? action.payload.settingsType : null;
      let pageName = (action.payload.pageName) ? action.payload.pageName : null;
      pageName = pageName + "_" + name;
      
      let valueLocalStorageStr = window.localStorage.getItem(pageName + '_' + settingsType);
      let valueLocalStorage = JSON.parse(valueLocalStorageStr);
      let fromDateValue = (valueLocalStorage && valueLocalStorage[PageCookieSettingType.fromDate] && valueLocalStorage[PageCookieSettingType.fromDate] !== '') ? valueLocalStorage[PageCookieSettingType.fromDate] : initialState.fromDate;
      let toDateValue = (valueLocalStorage && valueLocalStorage[PageCookieSettingType.toDate] && valueLocalStorage[PageCookieSettingType.toDate] !== '') ? valueLocalStorage[PageCookieSettingType.toDate] : initialState.toDate;
      let formatValue = (valueLocalStorage && valueLocalStorage[PageCookieSettingType.formatRangeDate] && valueLocalStorage[PageCookieSettingType.formatRangeDate] !== '') ? valueLocalStorage[PageCookieSettingType.formatRangeDate] : initialState.format;
      
      let dateRangeValue = [
        moment(fromDateValue).toDate(),
        moment(toDateValue).toDate(),
      ];

      let format = initialState.format;
      try {
        format = JSON.parse(formatValue);
      } catch(e){}

      state.fromDate = fromDateValue;
      state.toDate = toDateValue;
      state.dateRange = dateRangeValue;
      state.format = format;
    },
    dateSetFilters: (state, action) => {
      // const auth = (action.payload.auth) ? action.payload.auth : null;
      const settingsType = (action.payload.settingsType) ? action.payload.settingsType : null;
      let pageName = (action.payload.pageName) ? action.payload.pageName : null;
      pageName = pageName + "_" + name;
      
      if(settingsType){
        let valueLocalStorageStr = window.localStorage.getItem(pageName + '_' + settingsType);
        let valueLocalStorage = (valueLocalStorageStr && valueLocalStorageStr != '') ? JSON.parse(valueLocalStorageStr) : {};

        valueLocalStorage[PageCookieSettingType.fromDate] = state.fromDate;
        valueLocalStorage[PageCookieSettingType.toDate] = state.toDate;
        valueLocalStorage[PageCookieSettingType.formatRangeDate] = JSON.stringify(state.format);
        
        window.localStorage.setItem(pageName + '_' + settingsType, JSON.stringify(valueLocalStorage));
      }
    },

    datePrev: (state, action) => {
      let fromDate = moment(state.fromDate, Utils.getAPIDateFormat()).subtract(state.format.amount, state.format.unit).startOf(state.format.unit).format(Utils.getAPIDateFormat());
      let toDate = moment(state.toDate, Utils.getAPIDateFormat()).subtract(state.format.amount, state.format.unit).endOf(state.format.unit).format(Utils.getAPIDateFormat());
      let dateRange = [new Date(fromDate), new Date(toDate)];
      
      state.fromDate = fromDate;
      state.toDate = toDate;
      state.dateRange = dateRange;
    },
    dateNext: (state, action) => {
      let fromDate = moment(state.fromDate, Utils.getAPIDateFormat()).add(state.format.amount, state.format.unit).startOf(state.format.unit).format(Utils.getAPIDateFormat());
      let toDate = moment(state.toDate, Utils.getAPIDateFormat()).add(state.format.amount, state.format.unit).endOf(state.format.unit).format(Utils.getAPIDateFormat());
      let dateRange = [new Date(fromDate), new Date(toDate)];

      state.fromDate = fromDate;
      state.toDate = toDate;
      state.dateRange = dateRange;
    },
    dateToday: (state, action) => {
      let fromDate = moment().format(Utils.getAPIDateFormat());
      let toDate = moment().format(Utils.getAPIDateFormat());
      let dateRange = [new Date(fromDate), new Date(toDate)];
      let format = {
        amount: 1,
        unit: 'days'
      };

      state.fromDate = fromDate;
      state.toDate = toDate;
      state.dateRange = dateRange;
      state.format = format;
    },
    dateChange: (state, action) => {
      const arr = (action.payload && action.payload.length > 0) ? action.payload : [];

      const d1 = (arr && arr.length > 0) ? arr[0] : null;
      const d2 = (arr && arr.length > 1) ? arr[1] : null;

      let dateFormat = Utils.getAPIDateFormat();
      let fromDate = d1 ? moment(d1).format(dateFormat) : null;
      let toDate = d2 ? moment(d2).format(dateFormat) : null;

      let label = Utils.getDateRangeLabel(fromDate, toDate);
      let format = Utils.getDateRangeFormat(label);
      
      let dateRange = [];
      if(fromDate){
        dateRange.push(new Date(fromDate));
      }
      if(toDate){
        dateRange.push(new Date(toDate));
      }

      state.fromDate = fromDate;
      state.toDate = toDate;
      state.dateRange = dateRange;
      state.format = format;
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
  };
}


export default NewReducer();