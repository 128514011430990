import React, { Component } from 'react';

import { 
  Box,
  Grid,
  Button
} from '@material-ui/core';


class RemoveDialog extends Component {

  handleOkClick = (e) => {
    if(this.props.onOk)
        this.props.onOk()
  }

  handleCloseClick = (e) => {
    if(this.props.onClose)
        this.props.onClose()
  }

  
  setHead = () => {
    return <Grid container>
        <Box clone textAlign={"center"}>
            <Grid item xs={12} className="head">REMOVE</Grid>
        </Box>
    </Grid>
  }

  setBody = () => {
    return <Grid container>
        <Box clone textAlign={"center"} pt={4} pb={7}>
            <Grid item xs={12} className="body">Are you sure you want to remove?</Grid>
        </Box>
    </Grid>
  }

  setFoot = () => {
    return <Grid container>
        <Box clone textAlign={"center"}>
            <Grid item xs={12} className="foot">
                <Button 
                    className={'cancel'}
                    color={'default'} 
                    variant={'contained'}
                    style={{ marginRight: '15px' }}
                    onClick={this.handleCloseClick}
                >
                    CANCEL
                </Button>
                <Button 
                    className={'yes'}
                    color={'secondary'} 
                    variant={'contained'}
                    onClick={this.handleOkClick}
                >
                    Yes, Please remove
                </Button>
            </Grid>
        </Box>
    </Grid>
  }


  render() {
    return <Box className="datatable-remove-dialog" p={2}>
        {this.setHead()}
        {this.setBody()}
        {this.setFoot()}
    </Box>;
  }
}

export default RemoveDialog;
