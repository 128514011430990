import React, { Component } from 'react';
import queryString from 'query-string';

import { QuickbookOrXero } from '../../../utils/enums';
import Api from '../../../api/OLD/Xero/Xero';

import { 
  Box,
  Grid,
} from '@material-ui/core';

import LinkIcon from '../../../../_metronic/assets/img/manage/integrations/link_icon.png';


class XeroIntegrationsForm extends Component {
  
  constructor(props){
    super(props);

    let q = queryString.parse(window.location.search);
    this.code = (q.code) ? q.code : '';
    this.state  = (q.state ) ? q.state : '';

    this.id = '';
    this.clientId = '';
    try {
        let json = JSON.parse(this.state);
        this.id = json.id;
        this.clientId = json.id;
    } catch(e){}

    this.state = {
        data: []
    }
  }

  
  componentDidMount() {
    this.callXeroCreateApi();
  }


  /* API */
  callXeroCreateApi = () => {
    let data = {
        accountBillingProfileId: this.id,
        xeroAccessCode: this.code
    };

    let isErr = "0";
    let err = "";
    
    Api.create(data).then(result => {
        if(result && result.errorMessage && result.errorMessage.error !== ''){
          isErr = "1";
          err = result.errorMessage.error;

          this.setState({
              data: []
          });
        } else {
          isErr = "0";
          err = "";

          this.setState({
              data: []
          });
        }

        setTimeout(() => {
            window.location.href = '/manage/profile/company?itegration=' + QuickbookOrXero.Xero + '&id=' + this.id + '&isErr=' + isErr + '&err=' + err;
        }, 1000);
    });
  }
  /* END API */


  render() {
    return <Box className="manage-integrations-page" overflow={"hidden"}>
      <Grid container>
        <Grid item xs={12}>
            <Box pt={15} pb={15}>
                <Box textAlign={"center"}><img src={LinkIcon} alt={"link"} /></Box>
                <Box textAlign={"center"}>Waiting for connections...</Box>
            </Box>
        </Grid>
      </Grid>
    </Box>;
  }
}

export default XeroIntegrationsForm;
