import React from 'react';

import { 
    Button,
} from "react-bootstrap-v5";
import styled from 'styled-components';


const StyledDiv = styled.div`
    position: relative;
    width: fit-content;
    font-size: 20px;
    padding: 5px 10px;
    border-radius: 5px;
    user-select: none;
    cursor: pointer;

    &:hover {
        background-color: #f7f7f7;
    }

    &::after {
        display: none;
    }
`;

const StyledButton = styled(Button)`
    &::after {
        display: none;
    }
`;


const DropdownToggle = React.forwardRef(({ children, onClick, isButton, ...rest }, ref) => {
    if(isButton == true){
        return <StyledButton
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            {...rest}
        >{children}</StyledButton>
    } else {
        return <StyledDiv
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            {...rest}
        >{children}</StyledDiv>
    }
});


export default DropdownToggle;
