import React from 'react';

import { compose, lifecycle, withProps, withHandlers } from 'recompose';
import { GoogleMap, Marker, Polyline, withScriptjs, withGoogleMap, DirectionsRenderer } from "react-google-maps"

import MarkerIcon from '../../../../../../_metronic/assets/img/icons/marker-icon.png';

// import Utils from '../../../../../utils/utils';

import { connect } from "react-redux";
import { dispatchApiCallGet } from '../../../../../../setup/redux/dispatch/actions'
import { clear } from "../../../../../../setup/redux/actions";
import Utils from '../../../../../utils/utils'
import { AccountSettingType } from '../../../../../utils/enums'

import Spiderfy from "../../../../googleMap/Spiderfy";

import markerPin from "../../../../../../_metronic/assets/img/icons/marker-pin.png";


const { REACT_APP_GOOGLE_MAP_API_KEY } = process.env;
const URL = "https://maps.googleapis.com/maps/api/js?key=" + REACT_APP_GOOGLE_MAP_API_KEY + "&v=3.exp&libraries=geometry,drawing,places";


const DEF_CENTER = { lat: 1.353915, lng: 103.822901 };
const DEF_ZOOM = 16


const JobRouteMap = compose(
    withProps({
        googleMapURL: URL,
        loadingElement: <div style={{ width: '100%', height: '100%' }} />,
        containerElement: <div style={{ width: '100%', height:'100%' }} />,
        mapElement: <div style={{ width: '100%', height: `100%` }} />
    }),
    withScriptjs,
    withGoogleMap,
    withHandlers((props) => {
      return {
        callReadAccountSettingsLatTypeApi: () => (callback) => {
          props.dispatchApiCallGet(null, 'map_route_dialog-account_setting_type_lat', 'AccountSetting/type/' + AccountSettingType.DefaultMapPositionLatitude, null, callback, () => {});
        },
        callReadAccountSettingsLngTypeApi: () => (callback) => {
          props.dispatchApiCallGet(null, 'map_route_dialog-account_setting_type_lng', 'AccountSetting/type/' + AccountSettingType.DefaultMapPositionLongitude, null, callback, () => {});
        },
        callReadAccountSettingsZoomTypeApi: () => (callback) => {
          props.dispatchApiCallGet(null, 'map_route_dialog-account_setting_type_zoom', 'AccountSetting/type/' + AccountSettingType.DefaultMapPositionZoom, null, callback, () => {});
        }
      }
    }),
    lifecycle({
        componentWillMount() {
            const DirectionsService = new window.google.maps.DirectionsService();

            let waypoints = [];
            if(this.props.markers && this.props.markers.length > 1){
                waypoints = this.props.markers.map(p => {
                    if(p){
                        return {
                            location: { lat: p.latitude, lng: p.longitude },
                            stopover: true,
                        }
                    } else {
                        return null;
                    }
                });

                let origin = (waypoints && waypoints.length > 0) ? waypoints[0] ? waypoints[0].location : null : null;
                let destination = (waypoints && waypoints.length > 0) ? waypoints[waypoints.length-1] ? waypoints[waypoints.length-1].location : null : null;
                
                if(origin){
                    DirectionsService.route({
                        origin: origin,
                        destination: destination,
                        travelMode: window.google.maps.TravelMode.DRIVING,
                        waypoints: waypoints,
                    }, (result, status) => {
                        if (status === window.google.maps.DirectionsStatus.OK) {
                            let markers = [];

                            if(result && result.request && result.request.waypoints && result.request.waypoints.length > 0){
                                result.request.waypoints.forEach((item, i) => {
                                    let position = {
                                        lat: item.location.location.lat(),
                                        lng: item.location.location.lng(),
                                    };
                    
                                    // if(!markers.some(x => x.lat === position.lat && x.lng === position.lng)){
                                        markers.push(position);
                                    // }
                                });
                            }

                            let zoom = 12;
                            if(markers && markers.length === 1){
                                zoom = 15;
                            }

                            this.setState({
                                directions: result,
                                markers: markers,
                                zoom: zoom,
                            });
                        }
                    });
                } else {
                    this.setState({
                        directions: null,
                        markers: [],
                        zoom: 12,
                    });
                }
            } else {
                this.setState({
                    directions: null,
                    markers: [],
                    zoom: 12,
                });
            }
        }
      })
  )(props => {
    const [defaultCenter, setDefaultCenter] = React.useState(props.defaultCenter ? props.defaultCenter : DEF_CENTER);
    const [defaultZoom, setDefaultZoom] = React.useState(props.defaultZoom ? props.defaultZoom : DEF_ZOOM);
    

    React.useEffect(() => {
        props.callReadAccountSettingsLatTypeApi((latitude) => {
          props.callReadAccountSettingsLngTypeApi((longitude) => {
            props.callReadAccountSettingsZoomTypeApi((zoomItem) => {
              let lat = (latitude && latitude.value && latitude.value != '') ? latitude.value : DEF_CENTER.lat;
              let lng = (longitude && longitude.value && longitude.value != '') ? longitude.value : DEF_CENTER.lng;
              let zoom = (zoomItem && zoomItem.value && zoomItem.value != '') ? zoomItem.value : '';
              
              let defCenter = (lat != '' && lng != '') ? { lat: parseFloat(lat), lng: parseFloat(lng) } : DEF_CENTER;
              let defZoom = (zoom != '' && zoom != '') ? parseInt(zoom) : DEF_ZOOM;
              
              setDefaultZoom(defZoom);
              setDefaultCenter(defCenter);
            });
          });
        });
    }, []);

    React.useEffect(() => {
        Utils.reduxProps(props,
          'map_route_dialog-account_setting_type_lat', 
          (data, isLoading, isError, err, statusCode, variables, callback) => {
            if(callback){
              callback(data);
            }
    
            props.clear();
          }
        );
    
        Utils.reduxProps(props,
          'map_route_dialog-account_setting_type_lng', 
          (data, isLoading, isError, err, statusCode, variables, callback) => {
            if(callback){
              callback(data);
            }
    
            props.clear();
          }
        );
    
        Utils.reduxProps(props,
          'map_route_dialog-account_setting_type_zoom', 
          (data, isLoading, isError, err, statusCode, variables, callback) => {
            if(callback){
              callback(data);
            }
    
            props.clear();
          }
        );
    }, [props]);


    const getIconByStatus = (position, i) => {
      if(position){
          let start = 10;
          let step = 5;

          let data = {
            url: markerPin,
          }

          if(window && window.google && window.google.maps){
            // data['size'] = new window.google.maps.Size(26, 37)
            data['origin'] = new window.google.maps.Point(0, 0);
            data['labelOrigin'] = new window.google.maps.Point(14, 14);
  
            // if(i > 0){
              data['anchor'] = new window.google.maps.Point((start - (step * (i - 1))), 37);
            // }
          }

          return data;
      } else {
          return null;
      }
    }

    const showAllMarkers = () => {
        if(props.markers && props.markers.length > 0){
            return props.markers.map((pos, i) => {
              let position = null;
              if(pos && pos.latitude){
                position = { lat: pos.latitude , lng: pos.longitude };
              } else if(pos && pos.lat){
                position = pos;
              }

                if(position){
                    return <Marker 
                        key={i}
                        position={position}
                        label={{
                            text: "" + (i+1),
                            color: 'white',
                        }}
                        draggable={false}
                        options={{
                            icon: getIconByStatus(position, i),
                            data: position
                        }}
                    />
                } else {
                    return null;
                }
            });
        }
    }

    const getBearing = (from, to) => {
      try {
        const lat1 = from.lat * (Math.PI / 180);
        const lng1 = from.lng * (Math.PI / 180);
        const lat2 = to.lat * (Math.PI / 180);
        const lng2 = to.lng * (Math.PI / 180);
        
        const y = Math.sin(lng2 - lng1) * Math.cos(lat2);
        const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1);
        
        const bearing = Math.atan2(y, x);
        return (bearing * (180 / Math.PI) + 360) % 360;
      } catch(e){
        return 0;
      }
    };

    const getDriverLocationsPath = () => {
      return <>
        <Polyline
          path={props.driverLocation.map((item, i) => ({ lat: item.latitude, lng: item.longitude }))}
          options={{
            strokeColor: "#1253f9",
            strokeOpacity: 1.0,
            strokeWeight: 11,
          }}
        />
        {props.driverLocation.map((item, i) => {
          let position = { lat: item.latitude, lng: item.longitude };
          let toPosition = props.driverLocation[i + 1] ? { lat: props.driverLocation[i + 1]?.latitude, lng: props.driverLocation[i + 1]?.longitude } : null;
          
          return <Marker
            key={'driver_location_marker_' + i}
            position={position}
            title={'Updated: ' + item.updatedDisplay}
            icon={{
              // path: window.google.maps.SymbolPath.CIRCLE,
              path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              scale: 2.5,
              fillColor: "#FFFFFF",
              fillOpacity: 1,
              strokeWeight: 1,
              strokeColor: "#000000",
              rotation: i < props.driverLocation.length - 1 ? getBearing(position, toPosition) : 0,
            }}
          />
        })}
      </>
    }

    const googleMap = () => {
      let centerPos = defaultCenter;
      if(props.markers && props.markers.length > 0){
        if(props.markers[0] && props.markers[0].latitude){
          centerPos = { lat: props.markers[0].latitude , lng: props.markers[0].longitude };
        } else if(props.markers[0] && props.markers[0].lat){
          centerPos = props.markers[0];
        } else {
          centerPos = defaultCenter;
        }
      } else {
        centerPos = defaultCenter;
      }

      return <div>
        {defaultCenter && <GoogleMap
            center={centerPos}
            defaultCenter={defaultCenter}
            defaultZoom={defaultZoom}
            defaultOptions={{
                disableDefaultUI: true,
                disableDoubleClickZoom: true,
                rotateControl: false,
            }}
        >
            {(props.directions && (props.markers && props.markers.length > 1)) && props.directions && <DirectionsRenderer 
                directions={props.directions}
                options={{
                    suppressInfoWindows: true,
                    suppressMarkers: true,
                    // markerOptions: {
                    //     icon: MarkerIcon,
                    // },
                    // polylineOptions: {
                    //     strokeOpacity: 0.5,
                    //     strokeColor: '#FF0000',
                    // },
                }}
            />}

            <Spiderfy circleFootSeparation={25}>
              {showAllMarkers()}
            </Spiderfy>

            {getDriverLocationsPath()}
        </GoogleMap>}
      </div>
    }
    
    return googleMap();
});


const mapDispatchToProps = {
    dispatchApiCallGet,
    clear,
  }
  export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(JobRouteMap);
  