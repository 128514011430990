import React, { Component } from 'react';

import { 
  Row,
  Col,
  Form,
} from "react-bootstrap-v5";

import Utils from "../../../utils/utils";
import { JobStepType } from "../../../utils/enums";

import InputLayout from "../../../components/input/InputLayout";
import RSuiteSelectPicker from '../../../components/OLD/Select/RSuiteSelectPicker';
import JobGoogleSearch from '../../../components/googleMap/JobGoogleSearch';
import CustomDialog from '../../../components/dialog/CustomDialog';

import JobFormLocationDialog from "../Dialogs/JobFormLocationDialog";

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet } from '../../../../setup/redux/dispatch/actions'


class JobLocationDialog extends Component {
  
  constructor(props){
    super(props);

    this.name = 'job_form_default_locations_dialog';

    this.refJobFormLocationDialog = React.createRef();

    let {
      values,
    } = this.props.formOptions;
  
    let templateStepSites1Id = (values && values.templateStepSites1Id) ? values.templateStepSites1Id : null;
    let templateStepSites1Name = (values && values.templateStepSites1Name) ? values.templateStepSites1Name : '';
    let templateStepSites1Address = (values && values.templateStepSites1Address) ? values.templateStepSites1Address : '';
    let templateStepSites1Latitude = (values && values.templateStepSites1Latitude) ? values.templateStepSites1Latitude : null;
    let templateStepSites1Longitude = (values && values.templateStepSites1Longitude) ? values.templateStepSites1Longitude : null;

    let templateStepSites2Id = (values && values.templateStepSites2Id) ? values.templateStepSites2Id : null;
    let templateStepSites2Name = (values && values.templateStepSites2Name) ? values.templateStepSites2Name : '';
    let templateStepSites2Address = (values && values.templateStepSites2Address) ? values.templateStepSites2Address : '';
    let templateStepSites2Latitude = (values && values.templateStepSites2Latitude) ? values.templateStepSites2Latitude : null;
    let templateStepSites2Longitude = (values && values.templateStepSites2Longitude) ? values.templateStepSites2Longitude : null;

    this.state = {
      isLocationsDialog: false,
      rowLocationsDialog: null,
      rowLocationsTypeDialog: null,
      
      templateStepSites1Id: templateStepSites1Id,
      templateStepSites1Name: templateStepSites1Name,
      templateStepSites1Address: templateStepSites1Address,
      templateStepSites1Latitude: templateStepSites1Latitude,
      templateStepSites1Longitude: templateStepSites1Longitude,
      
      templateStepSites2Id: templateStepSites2Id,
      templateStepSites2Name: templateStepSites2Name,
      templateStepSites2Address: templateStepSites2Address,
      templateStepSites2Latitude: templateStepSites2Latitude,
      templateStepSites2Longitude: templateStepSites2Longitude,
    }
  }


  
  componentWillReceiveProps(nextProps) {
    this.reduxProps(nextProps);
  }

	
    /* API */
    reduxProps = nextProps => {
      Utils.reduxProps(nextProps,
          this.name + '-jobtemplate_sites', 
          (data, isLoading, isError, err, statusCode, variables, callback) => {
              let arr = variables.items;

              if(data && data.data && data.data.length > 0) {
                  for (var i = 0; i < data.data.length; i++) {
                      let item = data.data[i];
                      let value = Utils.makeIDfromString(item.stepSiteName);
                      let title = item.stepSiteName;
                      let arrItem = {
                          value: value,
                          title: title,
                          item: item,
                      };
                      
                      arr = Utils.addToArrayByTitle(arr, title, arrItem);
                  }
              }

              this.setState({
                  isLoading: isLoading,
                  isError: isError,
                  err: err,
              }, () => {
                  this.props.clear();

                  if(callback){
                      callback(arr);
                  }
              });
          }
      );
  }

callReadJobTemplateSitesApi = (jobTemplateStepId = null, items, callback = null) => {
    let data = {
        currentPage: 1,
        pageSize: Utils.getMaxPageSize(),
        searchQuery: '',
        jobTemplateStepId: jobTemplateStepId,
    };
 
    this.props.dispatchApiCallGet(data, this.name + '-jobtemplate_sites', 'jobtemplate/get-job-template-site-address', { items: items }, callback, () => {});
}
/* END API */


  setForm = () => {
    let {
        handleChange,
        setFieldValue,
        validateForm,
        values,
        errors,
    } = this.props.formOptions;
    
    return <Row>
      <Col xs={12}>
        {
          (values.customFormParams.isDefaultLocation1)
          ?
          <InputLayout
            label={(values.customFormParams.jobTemplateType !== JobStepType.Pull) ? this.props.fields.templateStepSites1Id.label : this.props.fields.templateStepSites2Id.label}
          >
            <Form.Control
                  as = {RSuiteSelectPicker}
                  isInvalid={!!errors.templateStepSites1Id}

                  id={this.props.fields.templateStepSites1Id.id}
                  placeholder={this.props.fields.templateStepSites1Id.placeholder}
                  items={values.customFormParams.templateStepSites1Items}
                  value={values.templateStepSites1Id}
                  selectedName={values.templateStepSites1Name}
                  isLoading={values.customFormParams.templateStepSites1IsLoading}
                  readOnly={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                  searchable={true}
                  cleanable={true}
                  isDefault={true}
                  onClick={async () => {
                      await setFieldValue('customFormParams.templateStepSites1IsLoading', true);
                      this.callReadJobTemplateSitesApi(values.customFormParams.jobTemplateStep1Id, values.customFormParams.templateStepSites1Items, async (items) => {
                          await setFieldValue('customFormParams.templateStepSites1Items', items);
                          await setFieldValue('customFormParams.templateStepSites1IsLoading', false);
                      });
                  }}
                  onSelect={async (value, item) => {
                      await setFieldValue('templateStepSites1Id', value);
                      await setFieldValue('templateStepSites1Name', item.title);
                      await setFieldValue('templateStepSites1Address', item.item.stepSiteAddress);
                      await setFieldValue('templateStepSites1Latitude', item.item.stepSiteLatitude);
                      await setFieldValue('templateStepSites1Longitude', item.item.stepSiteLongitude);
                      
                      let markers = Utils.getJobFormLocationMarkers({
                          customerSiteId: values.customerSiteId,
                          customerSiteItems: values.customFormParams.customerSiteItems,
                          customerSiteShiftId: values.customerSiteShiftId,
                          customerSiteShiftItems: values.customFormParams.customerSiteShiftItems,
                          templateStepSites1Latitude: item.item.stepSiteLatitude,
                          templateStepSites1Longitude: item.item.stepSiteLongitude,
                          stepSiteAddress1: item.title,
                          templateStepSites2Latitude: values.templateStepSites2Latitude,
                          templateStepSites2Longitude: values.templateStepSites2Longitude,
                          jobTemplateType: values.customFormParams.jobTemplateType
                      }, values)
                      await setFieldValue('customFormParams.markers', markers);
                  }}
                  onClear={async () => {
                      await setFieldValue('templateStepSites1Id', null);
                      await setFieldValue('templateStepSites1Name', '');
                      await setFieldValue('templateStepSites1Address', '');
                      await setFieldValue('templateStepSites1Latitude', null);
                      await setFieldValue('templateStepSites1Longitude', null);
                      
                      let markers = Utils.getJobFormLocationMarkers({
                          customerSiteId: values.customerSiteId,
                          customerSiteItems: values.customFormParams.customerSiteItems,
                          customerSiteShiftId: values.customerSiteShiftId,
                          customerSiteShiftItems: values.customFormParams.customerSiteShiftItems,
                          templateStepSites1Latitude: null,
                          templateStepSites1Longitude: null,
                          stepSiteAddress1: '',
                          templateStepSites2Latitude: values.templateStepSites2Latitude,
                          templateStepSites2Longitude: values.templateStepSites2Longitude,
                          jobTemplateType: values.customFormParams.jobTemplateType
                      }, values)
                      await setFieldValue('customFormParams.markers', markers);
                  }}
              />
              <Form.Control.Feedback type="invalid">{errors.templateStepSites1Id}</Form.Control.Feedback>
          </InputLayout>
          :
          <InputLayout
            label={this.props.fields.templateStepSites1Id.label}
          >
            <JobGoogleSearch
              id={this.props.fields.templateStepSites1Id.id}
              label={null}
              placeholder={this.props.fields.templateStepSites1Id.placeholder}
              readOnly={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
              error={errors.templateStepSites1Name}
              defaultValue={values.templateStepSites1Name}
              value={values.templateStepSites1Name}

              onChange={async (value) => {
                  setFieldValue('templateStepSites1Id', null);
                  setFieldValue('templateStepSites1Name', value);
                  setFieldValue('templateStepSites1Address', value);
                  setFieldValue('templateStepSites1Latitude', null);
                  setFieldValue('templateStepSites1Longitude', null);

                  // let markers = Utils.getJobFormLocationMarkers({
                  //     customerSiteId: values.customerSiteId,
                  //     customerSiteItems: values.customFormParams.customerSiteItems,
                  //     customerSiteShiftId: values.customerSiteShiftId,
                  //     customerSiteShiftItems: values.customFormParams.customerSiteShiftItems,
                  //     templateStepSites1Latitude: values.templateStepSites1Latitude,
                  //     templateStepSites1Longitude: values.templateStepSites1Longitude,
                  //     templateStepSites2Latitude: values.templateStepSites2Latitude,
                  //     templateStepSites2Longitude: values.templateStepSites2Longitude,
                  //     jobTemplateType: values.customFormParams.jobTemplateType
                  // }, values)
                  // await setFieldValue('customFormParams.markers', markers);
              }}
              onBlur={async (e) => {
                let value = e.target.value;
                let isCoord = false;

                if(Utils.validateCoordinates(value)){
                    let args = value.split(',');
    
                    let center = {
                      lat: (args && args.length > 0) ? Utils.isNumeric(args[0].trim()) ? parseFloat(args[0].trim()) : '' : '',
                      lng: (args && args.length > 1) ? Utils.isNumeric(args[1].trim()) ? parseFloat(args[1].trim()) : '' : '',
                    };
                    
                    isCoord = true;

                    await setFieldValue('templateStepSites1Id', null);
                    await setFieldValue('templateStepSites1Name', value);
                    await setFieldValue('templateStepSites1Address', value);
                    await setFieldValue('templateStepSites1Latitude', center.lat);
                    await setFieldValue('templateStepSites1Longitude', center.lng);
                }

                if(!isCoord){
                    if(values.templateStepSites1Latitude !== null && values.templateStepSites1Longitude !== null){
                        
                    } else {
                        // Utils.toast('Invalid coordinate', 'error');
                        
                        await setFieldValue('templateStepSites1Id', null);
                        await setFieldValue('templateStepSites1Name', '');
                        await setFieldValue('templateStepSites1Address', '');
                        await setFieldValue('templateStepSites1Latitude', null);
                        await setFieldValue('templateStepSites1Longitude', null);
                    }
                }
              }}
              
              onPlacesChanged={async (place) => {
                  if (place) {
                      if (place.formatted_address) {
                          await setFieldValue('templateStepSites1Id', null);
                          await setFieldValue('templateStepSites1Name', place.formatted_address);
                          await setFieldValue('templateStepSites1Address', place.formatted_address);
                      } else if (place.name) {
                          await setFieldValue('templateStepSites1Id', null);
                          await setFieldValue('templateStepSites1Name', place.name);
                          await setFieldValue('templateStepSites1Address', place.name);
                      }
          
                      if (place.geometry && place.geometry.location) {
                          let latitude = place.geometry.location.lat();
                          let longitude = place.geometry.location.lng();
                          
                          await setFieldValue('templateStepSites1Latitude', latitude);
                          await setFieldValue('templateStepSites1Longitude', longitude);
                      
                          let markers = Utils.getJobFormLocationMarkers({
                              customerSiteId: values.customerSiteId,
                              customerSiteItems: values.customFormParams.customerSiteItems,
                              customerSiteShiftId: values.customerSiteShiftId,
                              customerSiteShiftItems: values.customFormParams.customerSiteShiftItems,
                              templateStepSites1Latitude: latitude,
                              templateStepSites1Longitude: longitude,
                              templateStepSites2Latitude: values.templateStepSites2Latitude,
                              templateStepSites2Longitude: values.templateStepSites2Longitude,
                              jobTemplateType: values.customFormParams.jobTemplateType
                          }, values)
                          await setFieldValue('customFormParams.markers', markers);
                      }
                  }
              }}
              onEnterCoordinate={async (place, center) => {
                  if (place) {
                      if (place.formatted_address) {
                          await setFieldValue('templateStepSites1Id', null);
                          await setFieldValue('templateStepSites1Name', place.formatted_address);
                          await setFieldValue('templateStepSites1Address', place.formatted_address);
                      } else if (place.name) {
                          await setFieldValue('templateStepSites1Id', null);
                          await setFieldValue('templateStepSites1Name', place.name);
                          await setFieldValue('templateStepSites1Address', place.name);
                      }
                  
                      if (center) {
                          let latitude = center.lat;
                          let longitude = center.lng;
                          
                          await setFieldValue('templateStepSites1Latitude', latitude);
                          await setFieldValue('templateStepSites1Longitude', longitude);
                      
                          let markers = Utils.getJobFormLocationMarkers({
                              customerSiteId: values.customerSiteId,
                              customerSiteItems: values.customFormParams.customerSiteItems,
                              customerSiteShiftId: values.customerSiteShiftId,
                              customerSiteShiftItems: values.customFormParams.customerSiteShiftItems,
                              templateStepSites1Latitude: latitude,
                              templateStepSites1Longitude: longitude,
                              templateStepSites2Latitude: values.templateStepSites2Latitude,
                              templateStepSites2Longitude: values.templateStepSites2Longitude,
                              jobTemplateType: values.customFormParams.jobTemplateType
                          }, values)
                          await setFieldValue('customFormParams.markers', markers);
                      }
                  }
              }}

              onClick={() => {
                  let address = (values && values.templateStepSites1Address) ? values.templateStepSites1Address : '';
                  let latitude = (values && values.templateStepSites1Latitude) ? values.templateStepSites1Latitude : null;
                  let longitude = (values && values.templateStepSites1Longitude) ? values.templateStepSites1Longitude : null;

                  this.setState({
                    isLocationsDialog: true,
                    rowLocationsTypeDialog: 1,
                    rowLocationsDialog: {
                      address: address,
                      latitude: latitude,
                      longitude: longitude,
                      isInvalid: false,
                    }
                  });
              }}
            />
          </InputLayout>
        }
      </Col>
      <Col xs={12}>
        {((values.customFormParams.jobTemplateType !== JobStepType.Put) && (values.customFormParams.jobTemplateType !== JobStepType.Task) && (values.customFormParams.jobTemplateType !== JobStepType.Out) && (values.customFormParams.jobTemplateType !== JobStepType.Pull)) &&  <>
          {
            (values.customFormParams.isDefaultLocation2)
            ?
            <InputLayout
              label={this.props.fields.templateStepSites2Id.label}
            >
              <Form.Control
                    as = {RSuiteSelectPicker}
                    isInvalid={!!errors.templateStepSites2Id}

                    id={this.props.fields.templateStepSites2Id.id}
                    placeholder={this.props.fields.templateStepSites2Id.placeholder}
                    items={values.customFormParams.templateStepSites2Items}
                    value={values.templateStepSites2Id}
                    selectedName={values.templateStepSites2Name}
                    isLoading={values.customFormParams.templateStepSites2IsLoading}
                    readOnly={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                    searchable={true}
                    cleanable={true}
                    isDefault={true}
                    onClick={async () => {
                        await setFieldValue('customFormParams.templateStepSites2IsLoading', true);
                        this.callReadJobTemplateSitesApi(values.customFormParams.jobTemplateStep2Id, values.customFormParams.templateStepSites2Items, async (items) => {
                            await setFieldValue('customFormParams.templateStepSites2Items', items);
                            await setFieldValue('customFormParams.templateStepSites2IsLoading', false);
                        });
                    }}
                    onSelect={async (value, item) => {
                        await setFieldValue('templateStepSites2Id', value);
                        await setFieldValue('templateStepSites2Name', item.title);
                        await setFieldValue('templateStepSites2Address', item.item.stepSiteAddress);
                        await setFieldValue('templateStepSites2Latitude', item.item.stepSiteLatitude);
                        await setFieldValue('templateStepSites2Longitude', item.item.stepSiteLongitude);
                    
                        let markers = Utils.getJobFormLocationMarkers({
                            customerSiteId: values.customerSiteId,
                            customerSiteItems: values.customFormParams.customerSiteItems,
                            customerSiteShiftId: values.customerSiteShiftId,
                            customerSiteShiftItems: values.customFormParams.customerSiteShiftItems,
                            templateStepSites1Latitude: values.templateStepSites1Latitude,
                            templateStepSites1Longitude: values.templateStepSites1Longitude,
                            templateStepSites2Latitude: item.item.stepSiteLatitude,
                            templateStepSites2Longitude: item.item.stepSiteLongitude,
                            stepSiteAddress2: item.title,
                            jobTemplateType: values.customFormParams.jobTemplateType
                        }, values)
                        await setFieldValue('customFormParams.markers', markers);
                    }}
                    onClear={async () => {
                        await setFieldValue('templateStepSites2Id', null);
                        await setFieldValue('templateStepSites2Name', '');
                        await setFieldValue('templateStepSites2Address', '');
                        await setFieldValue('templateStepSites2Latitude', null);
                        await setFieldValue('templateStepSites2Longitude', null);
                    
                        let markers = Utils.getJobFormLocationMarkers({
                            customerSiteId: values.customerSiteId,
                            customerSiteItems: values.customFormParams.customerSiteItems,
                            customerSiteShiftId: values.customerSiteShiftId,
                            customerSiteShiftItems: values.customFormParams.customerSiteShiftItems,
                            templateStepSites1Latitude: values.templateStepSites1Latitude,
                            templateStepSites1Longitude: values.templateStepSites1Longitude,
                            templateStepSites2Latitude: null,
                            templateStepSites2Longitude: null,
                            stepSiteAddress2: '',
                            jobTemplateType: values.customFormParams.jobTemplateType
                        }, values)
                        await setFieldValue('customFormParams.markers', markers);
                    }}
                />
                <Form.Control.Feedback type="invalid">{errors.templateStepSites2Id}</Form.Control.Feedback>
            </InputLayout>
            :
            <InputLayout
              label={this.props.fields.templateStepSites2Id.label}
            >
              <JobGoogleSearch
                id={this.props.fields.templateStepSites2Id.id}
                label={null}
                placeholder={this.props.fields.templateStepSites2Id.placeholder}
                readOnly={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                error={errors.templateStepSites2Name}
                defaultValue={values.templateStepSites2Name}
                value={values.templateStepSites2Name}

                onChange={async (value) => {
                    setFieldValue('templateStepSites2Id', null);
                    setFieldValue('templateStepSites2Name', value);
                    setFieldValue('templateStepSites2Address', value);
                    setFieldValue('templateStepSites2Latitude', null);
                    setFieldValue('templateStepSites2Longitude', null);

                    // let markers = Utils.getJobFormLocationMarkers({
                    //     customerSiteId: values.customerSiteId,
                    //     customerSiteItems: values.customFormParams.customerSiteItems,
                    //     customerSiteShiftId: values.customerSiteShiftId,
                    //     customerSiteShiftItems: values.customFormParams.customerSiteShiftItems,
                    //     templateStepSites1Latitude: values.templateStepSites1Latitude,
                    //     templateStepSites1Longitude: values.templateStepSites1Longitude,
                    //     templateStepSites2Latitude: values.templateStepSites2Latitude,
                    //     templateStepSites2Longitude: values.templateStepSites2Longitude,
                    //     jobTemplateType: values.customFormParams.jobTemplateType
                    // }, values)
                    // await setFieldValue('customFormParams.markers', markers);
                }}
                onBlur={async (e) => {
                  let value = e.target.value;
                  let isCoord = false;
  
                  if(Utils.validateCoordinates(value)){
                      let args = value.split(',');
      
                      let center = {
                        lat: (args && args.length > 0) ? Utils.isNumeric(args[0].trim()) ? parseFloat(args[0].trim()) : '' : '',
                        lng: (args && args.length > 1) ? Utils.isNumeric(args[1].trim()) ? parseFloat(args[1].trim()) : '' : '',
                      };
                      
                      isCoord = true;

                      await setFieldValue('templateStepSites2Id', null);
                      await setFieldValue('templateStepSites2Name', value);
                      await setFieldValue('templateStepSites2Address', value);
                      await setFieldValue('templateStepSites2Latitude', center.lat);
                      await setFieldValue('templateStepSites2Longitude', center.lng);
                  }
  
                  if(!isCoord){
                      if(values.templateStepSites2Latitude !== null && values.templateStepSites2Longitude !== null){
                          
                      } else {
                          // Utils.toast('Invalid coordinate', 'error');
                          
                          await setFieldValue('templateStepSites2Id', null);
                          await setFieldValue('templateStepSites2Name', '');
                          await setFieldValue('templateStepSites2Address', '');
                          await setFieldValue('templateStepSites2Latitude', null);
                          await setFieldValue('templateStepSites2Longitude', null);
                      }
                  }
                }}

                onPlacesChanged={async (place) => {
                    if (place) {
                        if (place.formatted_address) {
                            await setFieldValue('templateStepSites2Id', null);
                            await setFieldValue('templateStepSites2Name', place.formatted_address);
                            await setFieldValue('templateStepSites2Address', place.formatted_address);
                        } else if (place.name) {
                            await setFieldValue('templateStepSites2Id', null);
                            await setFieldValue('templateStepSites2Name', place.name);
                            await setFieldValue('templateStepSites2Address', place.name);
                        }
            
                        if (place.geometry && place.geometry.location) {
                            let latitude = place.geometry.location.lat();
                            let longitude = place.geometry.location.lng();
                            
                            await setFieldValue('templateStepSites2Latitude', latitude);
                            await setFieldValue('templateStepSites2Longitude', longitude);
                    
                            let markers = Utils.getJobFormLocationMarkers({
                                customerSiteId: values.customerSiteId,
                                customerSiteItems: values.customFormParams.customerSiteItems,
                                customerSiteShiftId: values.customerSiteShiftId,
                                customerSiteShiftItems: values.customFormParams.customerSiteShiftItems,
                                templateStepSites1Latitude: values.templateStepSites1Latitude,
                                templateStepSites1Longitude: values.templateStepSites1Longitude,
                                templateStepSites2Latitude: latitude,
                                templateStepSites2Longitude: longitude,
                                jobTemplateType: values.customFormParams.jobTemplateType
                            }, values)
                            await setFieldValue('customFormParams.markers', markers);
                        }
                    }
                }}
                onEnterCoordinate={async (place, center) => {
                    if (place) {
                        if (place.formatted_address) {
                            await setFieldValue('templateStepSites2Id', null);
                            await setFieldValue('templateStepSites2Name', place.formatted_address);
                            await setFieldValue('templateStepSites2Address', place.formatted_address);
                        } else if (place.name) {
                            await setFieldValue('templateStepSites2Id', null);
                            await setFieldValue('templateStepSites2Name', place.name);
                            await setFieldValue('templateStepSites2Address', place.name);
                        }
                    
                        if (center) {
                            let latitude = center.lat;
                            let longitude = center.lng;
                            
                            await setFieldValue('templateStepSites2Latitude', latitude);
                            await setFieldValue('templateStepSites2Longitude', longitude);
                    
                            let markers = Utils.getJobFormLocationMarkers({
                                customerSiteId: values.customerSiteId,
                                customerSiteItems: values.customFormParams.customerSiteItems,
                                customerSiteShiftId: values.customerSiteShiftId,
                                customerSiteShiftItems: values.customFormParams.customerSiteShiftItems,
                                templateStepSites1Latitude: values.templateStepSites1Latitude,
                                templateStepSites1Longitude: values.templateStepSites1Longitude,
                                templateStepSites2Latitude: latitude,
                                templateStepSites2Longitude: longitude,
                                jobTemplateType: values.customFormParams.jobTemplateType
                            }, values)
                            await setFieldValue('customFormParams.markers', markers);
                        }
                    }
                }}

                onClick={() => {
                    let address = (values && values.templateStepSites2Address) ? values.templateStepSites2Address : '';
                    let latitude = (values && values.templateStepSites2Latitude) ? values.templateStepSites2Latitude : null;
                    let longitude = (values && values.templateStepSites2Longitude) ? values.templateStepSites2Longitude : null;

                    this.setState({
                      isLocationsDialog: true,
                      rowLocationsTypeDialog: 2,
                      rowLocationsDialog: {
                        address: address,
                        latitude: latitude,
                        longitude: longitude,
                        isInvalid: false,
                      }
                    });
                }}
              />
            </InputLayout>
          }
        </>}
      </Col>
    </Row>
  }
  
  
  setLocationDialog = () => {
    return <CustomDialog 
        title={'Default Locations'}
        cancelText={'Cancel'}
        okText={'Save'}
        size={'md'}
        body={<JobFormLocationDialog
          ref={this.refJobFormLocationDialog}
          item={this.state.rowLocationsDialog}
          onSubmit={async (form) => {
            let {
              setFieldValue,
              values,
            } = this.props.formOptions;
          
            await setFieldValue('templateStepSites' + this.state.rowLocationsTypeDialog + 'Id', null);
            await setFieldValue('templateStepSites' + this.state.rowLocationsTypeDialog + 'Name', form.address);
            await setFieldValue('templateStepSites' + this.state.rowLocationsTypeDialog + 'Address', form.address);
            await setFieldValue('templateStepSites' + this.state.rowLocationsTypeDialog + 'Latitude', form.latitude);
            await setFieldValue('templateStepSites' + this.state.rowLocationsTypeDialog + 'Longitude', form.longitude);

            this.setState({
              isLocationsDialog: false,
              rowLocationsDialog: null,
              rowLocationsTypeDialog: null,
            }, async () => {
              let markers = Utils.getJobFormLocationMarkers({
                customerSiteId: values.customerSiteId,
                customerSiteItems: values.customFormParams.customerSiteItems,
                customerSiteShiftId: values.customerSiteShiftId,
                customerSiteShiftItems: values.customFormParams.customerSiteShiftItems,
                templateStepSites1Latitude: values.templateStepSites1Latitude,
                templateStepSites1Longitude: values.templateStepSites1Longitude,
                templateStepSites2Latitude: values.templateStepSites2Latitude,
                templateStepSites2Longitude: values.templateStepSites2Longitude,
                jobTemplateType: values.customFormParams.jobTemplateType
              }, values)
              await setFieldValue('customFormParams.markers', markers);
            });
          }}
        />}
        show={this.state.isLocationsDialog}
        onCancel={() => {
          this.setState({
            isLocationsDialog: false,
            rowLocationsDialog: null,
            rowLocationsTypeDialog: null,
          });
        }}
        onOk={() => {
          if(this.refJobFormLocationDialog && this.refJobFormLocationDialog.current && this.refJobFormLocationDialog.current.refForm && this.refJobFormLocationDialog.current.refForm.current){
            this.refJobFormLocationDialog.current.refForm.current.submitForm();
          }
        }}
    />
}

  render() {
    return <>
      {this.setForm()}
      {this.setLocationDialog()}
    </>
  }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(JobLocationDialog);
