import React from "react";
import _ from 'lodash';

import { 
    Row,
    Col,
    Form,
    Spinner,
    Button,
    InputGroup,
} from "react-bootstrap-v5";

import { FieldArray } from 'formik';

import { DrawerContentLayout } from '../../../components/drawer/DrawerLayout'
import Utils from "../../../utils/utils";
import { PayrollTemplateVariableType, DayOfWeek } from "../../../utils/enums";

import RSuiteSelectPicker from '../../../components/OLD/Select/RSuiteSelectPicker';
import RSuiteTimePicker from '../../../components/OLD/TimePicker/RSuiteTimePicker';
import IconButton from "../../../components/Buttons/IconButton";

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet } from '../../../../setup/redux/dispatch/actions'


class WorkHoursForm extends React.Component {
  
    constructor(props) {
        super(props);
    
        this.bodyRef = React.createRef();

        let dataParams = (props.dataParams) ? props.dataParams : null;
        let id = (dataParams && dataParams.id) ? dataParams.id : null;
        let row = (dataParams && dataParams.row) ? dataParams.row : null;
    
        this.state = {
            id: id,
            row: row,
        };
    }


    body = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;
        
        return <Row>
            
            <Col xs={12}>
                <Form.Group as={Row} className={'mb-5'}>
                    <Form.Label column sm={3}>{this.props.fields.workingHourName.label}</Form.Label>
                    <Col sm={9}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.workingHourName.id}
                            placeholder={this.props.fields.workingHourName.placeholder}
                            value={values.workingHourName}
                            onChange={handleChange}
                            isInvalid={!!errors.workingHourName}
                        />
                        <Form.Control.Feedback type="invalid">{errors.workingHourName}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
            </Col>
            <Col xs={12}>
                <Form.Group as={Row} className={'mb-5'}>
                    <Form.Label column sm={3}>{this.props.fields.workingHourDescription.label}</Form.Label>
                    <Col sm={9}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.workingHourDescription.id}
                            placeholder={this.props.fields.workingHourDescription.placeholder}
                            value={values.workingHourDescription}
                            onChange={handleChange}
                            isInvalid={!!errors.workingHourDescription}
                        />
                        <Form.Control.Feedback type="invalid">{errors.workingHourDescription}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
            </Col>
            
            <Col xs={12}>
                <FieldArray 
                    name={this.props.fields.accountWorkingHourDays.id}
                    validateOnChange={false}
                >
                    {({ remove, push }) => (
                        <>
                            {values.accountWorkingHourDays.map((item, i) => {
                                return <Row key={i} className={'align-items-center mb-5 mt-5'}>
                                    <Form.Label column sm={3}>{item.label}</Form.Label>
                                    <Col sm={2} className={'pb-3 pb-sm-0'}>
                                        <Form.Group>
                                            <Form.Check
                                                type="switch"
                                                id={this.props.fields.accountWorkingHourDaysDay.id + '_' + i}
                                                label={item.isEnabled ? 'Work' : 'Rest'}
                                                checked={item.isEnabled}
                                                onChange={async (e) => {
                                                    await setFieldValue('accountWorkingHourDays.' + i + '.isEnabled', e.target.checked);
                                                    
                                                    if(e.target.checked){
                                                        if(item.timeFrom === null && item.timeTo === null){
                                                            let timeFrom = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 9);
                                                            let timeTo = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 18);
                                                            
                                                            let dateTimeFrom = Utils.convertDateTime(timeFrom);
                                                            let dateTimeTo = Utils.convertDateTime(timeTo);
                                                            
                                                            await setFieldValue('accountWorkingHourDays.' + i + '.timeFrom', dateTimeFrom);
                                                            await setFieldValue('accountWorkingHourDays.' + i + '.timeTo', dateTimeTo);
                                                        }
                                                    } else {
                                                        await setFieldValue('accountWorkingHourDays.' + i + '.timeFrom', null);
                                                        await setFieldValue('accountWorkingHourDays.' + i + '.timeTo', null);
                                                    }
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={7}>
                                        {item.isEnabled && <InputGroup>
                                            <Form.Control
                                                as = {RSuiteTimePicker}
                                                isInvalid={!!(errors && errors.accountWorkingHourDays && errors.accountWorkingHourDays[i] && errors.accountWorkingHourDays[i].timeFrom)}
                                                
                                                id={this.props.fields.accountWorkingHourDaysTimeFrom.id + '_' + i}
                                                value={item.timeFrom}
                                                hideMinutes={minute => minute % 30 !== 0}
                                                onChange={async (value) => {
                                                    let dateTime = Utils.convertDateTime(value);
                                                    await setFieldValue('accountWorkingHourDays.' + i + '.timeFrom', dateTime);
                                                }}
                                            />
                                            <InputGroup.Text className={'bg-white border-top-0 border-bottom-0'}>TO</InputGroup.Text>
                                            <Form.Control
                                                as = {RSuiteTimePicker}
                                                isInvalid={!!(errors && errors.accountWorkingHourDays && errors.accountWorkingHourDays[i] && errors.accountWorkingHourDays[i].timeTo)}
                                                
                                                id={this.props.fields.accountWorkingHourDaysTimeTo.id + '_' + i}
                                                value={item.timeTo}
                                                hideMinutes={minute => minute % 30 !== 0}
                                                onChange={async (value) => {
                                                    let dateTime = Utils.convertDateTime(value);
                                                    await setFieldValue('accountWorkingHourDays.' + i + '.timeTo', dateTime);
                                                }}
                                            />
                                        </InputGroup>}
                                    </Col>
                                </Row>
                            })}
                        </>
                    )}
                </FieldArray>
            </Col>
            
        </Row>
    }
    
    footer = () => {
        let {
            validateForm,
            values,
        } = this.props.formOptions;

        return <Row>
            <Col xs={'auto'}>
                <Button 
                    type={'submit'}
                    disabled={this.props.isLoading}
                >
                    SAVE
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
            <Col xs={'auto'}>
                <Button 
                    type={'button'}
                    variant={'secondary'}
                    disabled={this.props.isLoading}
                    onClick={() => {
                        Utils.showDrawer(this.props.drawer, false);
                    }}
                >
                    CANCEL
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
            
            
            {values.isEdit && <>
                <Col xs={12} className={'d-flex d-sm-none pb-3'}></Col>
                <Col xs={'auto'} sm={true} className={'text-end'}>
                    <Button 
                        type={'button'}
                        variant={'danger'}
                        disabled={this.props.isLoading}
                        onClick={() => {
                            if(this.props.onDelete){
                                this.props.onDelete(values);
                            }
                        }}
                    >
                        REMOVE
                        {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                    </Button>
                </Col>
                <Col xs={'auto'} md={'auto'}>
                    <Button 
                        type={'button'}
                        variant={'outline-secondary'}
                        disabled={this.props.isLoading}
                        onClick={() => {
                            validateForm().then((err) => {
                                if(_.isEmpty(err)){
                                    if(this.props.onCopy){
                                        this.props.onCopy(values);
                                    }
                                } else {
                                    Utils.toast('You must fill in the required fields', 'error');
                                }
                            })
                        }}
                    >
                        <i className={'material-icons'}>content_copy</i>
                        COPY
                        {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                    </Button>
                </Col>
            </>}

        </Row>
    }


    render() {
        return <DrawerContentLayout
            ref={this.bodyRef}
            isBlocking={this.props.isBlocking}
            body={this.body()}
            footer={this.footer()}
        />
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(WorkHoursForm);
