import React from 'react';
import _ from 'lodash';

import Utils from '../../../utils/utils';

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut, dispatchApiCallDelete } from '../../../../setup/redux/dispatch/actions'


const makeCRUD = (Target) => {
  class Component extends React.Component {
      
    constructor(props) {
      super(props);

      this.mainName = 'makeCRUD_';
      this.targetRef = React.createRef();

      this.componentTitle = (props.componentTitle && props.componentTitle != '') ? props.componentTitle : '';
      this.componentApi = (props.componentApi && props.componentApi != '') ? props.componentApi : '';
      this.componentId = (props.componentId && props.componentId != '') ? props.componentId : '';
      this.componentName = (props.componentName && props.componentName != '') ? props.componentName : '';
      this.componentInactive = (props.componentInactive) ? props.componentInactive : null;
      this.componentTotal = (props.componentTotal && props.componentTotal != '') ? props.componentTotal : 'total';
      this.componentValue = (props.keyValue && props.keyValue != '') ? props.keyValue : 'value';
      this.componentLabel = (props.keyLabel && props.keyLabel != '') ? props.keyLabel : 'title';
      this.componentItem = (props.keyItem && props.keyItem != '') ? props.keyItem : 'item';
      this.componentIndex = (props.componentIndex && props.componentIndex != '') ? props.componentIndex : '';
      this.customName = (props.customName && props.customName != '') ? props.customName : '';

      let isInfiniteScroll = ((props.isInfiniteScroll === false) || (props.isInfiniteScroll === true)) ? props.isInfiniteScroll : true;

      let pageSize = (props.pageSize && props.pageSize > 0) ? props.pageSize : (isInfiniteScroll ? 10 : Utils.getMaxPageSize());
      let sortColumn = (props.sortColumn == undefined) ? null : (props.sortColumn != '') ? props.sortColumn : 'updated';
      let sortDir = (props.sortDir == undefined) ? null : (props.sortDir != '') ? props.sortDir : 'desc';
      let showDefault = ((props.showDefault === false) || (props.showDefault === true)) ? props.showDefault : false;

      this.state = {
        isLoading: false,
        options: [],

        hasMore: false,
        currentPage: 1,

        pageSize: pageSize,
        sortColumn: sortColumn,
        sortDir: sortDir,
        showDefault: showDefault,
      };
    }


    componentWillReceiveProps(nextProps) {
      this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
      Utils.reduxProps(nextProps,
        this.mainName + this.componentTitle + '_read' + ((this.customName !== '') ? '_' + this.customName : ''),
        (data, isLoading, isError, err, statusCode, variables, callback) => {
          let options = (variables && variables.length > 0) ? variables : [];
          let hasMore = false;

          if(this.props.renderReadAPI){
            let backData = this.props.renderReadAPI(data, variables);
            options = backData.options;
            hasMore = backData.hasMore;
          } else {
            if(data && data.data && data.data.length > 0) {
              for (var i = 0; i < data.data.length; i++) {
                let item = data.data[i];
                let value = item[this.componentId];
                let title = item[this.componentName];
                let arrItem = {
                  [this.componentValue]: value,
                  [this.componentLabel]: title,
                  [this.componentItem]: item,
                };
  
                if(this.state.showDefault && this.props.keyDefault && this.props.keyDefault != ''){
                  arrItem[this.props.keyDefault] = item[this.props.keyDefault];
                }
  
                options = Utils.addToArrayIfNotExist(options, value, arrItem);
              }
                
              hasMore = (options.length < data[this.componentTotal]) ? (options.length < this.state.pageSize) ? false : true : false;
            }
          }
          
          this.setState({
            isError: isError,
            err: err,
          }, () => {
            this.props.clear();

            if(callback){
              callback(options, hasMore);
            }
          });
        }
      );

      Utils.reduxProps(nextProps,
        this.mainName + this.componentTitle + '_create' + ((this.customName !== '') ? '_' + this.customName : ''),
        (data, isLoading, isError, err, statusCode, variables, callback) => {
          let index = null;
          let item = null;
          let options = this.state.options;
          if(data){
            item = {
              [this.componentValue]: data[this.componentId],
              [this.componentLabel]: data[this.componentName],
              [this.componentItem]: data,
            };
            
            if(this.state.showDefault && this.props.keyDefault && this.props.keyDefault != ''){
              item[this.props.keyDefault] = data[this.props.keyDefault];
            }

            options.unshift(item);
            index = 0;
          }
  
          this.setState({
            options: options,

            isLoading: isLoading,
            isError: isError,
            err: err,
          }, () => {
            this.props.clear();

            if(!this.state.isError){
              Utils.toast("Successfully created!", 'success'); 
            }
        
            if(callback){
              callback(item, index);
            }
          });
        }
      );

      Utils.reduxProps(nextProps,
        this.mainName + this.componentTitle + '_update' + ((this.customName !== '') ? '_' + this.customName : ''),
        (data, isLoading, isError, err, statusCode, variables, callback) => {
          let item = null;
          let index = null;
          let options = this.state.options;
          if(data){
            item = {
              [this.componentValue]: data[this.componentId],
              [this.componentLabel]: data[this.componentName],
              [this.componentItem]: data,
            };
            
            if(this.state.showDefault && this.props.keyDefault && this.props.keyDefault != ''){
              item[this.props.keyDefault] = data[this.props.keyDefault];
            }

            
            options = Utils.unshiftToArray(options, item.value, item);
            index = 0;
          }
  
          this.setState({
            options: options,

            isLoading: isLoading,
            isError: isError,
            err: err,
          }, () => {
            this.props.clear();

            if(!this.state.isError){
              Utils.toast("Successfully updated!", 'success'); 
            }
        
            if(callback){
              callback(item, index);
            }
          });
        }
      );

      Utils.reduxProps(nextProps,
        this.mainName + this.componentTitle + '_delete' + ((this.customName !== '') ? '_' + this.customName : ''), 
        (data, isLoading, isError, err, statusCode, variables, callback) => {
          let options = this.state.options;
          options = Utils.removeFromArrayWithField(options, variables[this.componentId], [this.componentValue], variables);

          this.setState({
            options: options,

            isLoading: isLoading,
            isError: isError,
            err: err,
          }, () => {
            this.props.clear();

            if(!this.state.isError){
              Utils.toast("Successfully deleted!", 'success'); 
            }
        
            if(callback){
              callback(variables);
            }
          });
        }
      );
    }

    callReadApi = (searchQuery = '', callback = null) => {
      let data = null;

      let params = {
        searchQuery: searchQuery,
        currentPage: this.state.currentPage,
        pageSize: this.state.pageSize,
        sortColumn: this.state.sortColumn,
        sortDir: this.state.sortDir,
      };
      
      if(this.componentInactive){
        params[this.componentInactive] = this.state.showDefault;
      }

      if(this.props.componentData){
        data = _.assign(params, this.props.componentData);
      } else {
        data = params;
      }

      this.props.dispatchApiCallGet(data, this.mainName + this.componentTitle + '_read' + ((this.customName !== '') ? '_' + this.customName : ''), this.componentApi, this.state.options, callback, () => {});
    }
    
    callCreateApi = (data, callback = null) => {
      let params = null;

      if(this.props.componentCreateData){
        params = _.assign(data, this.props.componentCreateData);
      } else {
        params = data;
      }

      this.props.dispatchApiCallPost(params, this.mainName + this.componentTitle + '_create' + ((this.customName !== '') ? '_' + this.customName : ''), this.componentApi, data, callback, () => {});
    }
    
    callUpdateApi = (data, callback = null) => {
      this.props.dispatchApiCallPut(data, this.mainName + this.componentTitle + '_update' + ((this.customName !== '') ? '_' + this.customName : ''), this.componentApi, data, callback, () => {});
    }

    callDeleteApi = (data, callback = null) => {
      this.props.dispatchApiCallDelete(data, this.mainName + this.componentTitle + '_delete' + ((this.customName !== '') ? '_' + this.customName : ''), this.componentApi, data, callback, () => {});
    }
    /* END API */


    /* FUNCTIONS */
    onEnter = () => {
      this.setState({
        isLoading: true,
        // options: [],
        hasMore: false,
        currentPage: 1,
      });
    }
    onEntered = () => {
      this.setState({
        isLoading: true,
        // options: [],
        hasMore: false,
        currentPage: 1,
      }, () => {
        this.callReadApi('', (items, hasMore) => {
          this.setState({
            isLoading: false,
            options: items,
            hasMore: hasMore,
          });
        });
      });
    }
    onExited = () => {
      this.setState({
        isLoading: false,
        // options: [],
        hasMore: false,
        currentPage: 1,
      });
    }
    
    onLoadMore = (page) => {
      this.setState({
        currentPage: page,
      }, () => {
        this.callReadApi('', (items, hasMore) => {
          this.setState({
            options: items,
            hasMore: hasMore,
          });
        });
      });
    }
    
    onSearchPressEnter = async (value) => {
      this.setState({
        isLoading: true,
        options: [],
        hasMore: false,
        currentPage: 1,
      }, () => {
        this.callReadApi(value, (items, hasMore) => {
          this.setState({
            isLoading: false,
            options: items,
            hasMore: hasMore,
          });
        });
      });
    }
    onClearSearch = async () => {
      this.setState({
        isLoading: true,
        options: [],
        hasMore: false,
        currentPage: 1,
      }, () => {
        this.callReadApi('', (items, hasMore) => {
          this.setState({
            isLoading: false,
            options: items,
            hasMore: hasMore,
          });
        });
      });
    }


    onCreate = (value) => {
      let data = {
        [this.componentName]: value
      };

      this.setState({
        isLoading: true,
      }, () => {
        this.callCreateApi(data, (newItem, index) => {
          this.setState({
            isLoading: false,
          }, () => {
            if(newItem && this.props.onChange){
              this.props.onChange(newItem.value, newItem, index)
            }

            if(this.props.onCreateData){
              this.props.onCreateData()
            }
          });
        });
      });
    }
    onUpdate = (value, item) => {
      let data = {
        [this.componentId]: item.value,
        [this.componentName]: value
      };

      this.setState({
        isLoading: true,
      }, () => {
        this.callUpdateApi(data, (newItem, index) => {
          this.setState({
            isLoading: false,
          }, () => {
            if(newItem && this.props.onChange){
              this.props.onChange(newItem.value, newItem, index)
            }

            if(this.props.onUpdateData){
              this.props.onUpdateData()
            }
          });
        });
      });
    }
    onDelete = (item) => {
      let data = {
        [this.componentId]: item.value,
      };

      this.setState({
        isLoading: true,
      }, () => {
        this.callDeleteApi(data, (oldItem) => {
          this.setState({
            isLoading: false,
          }, () => {
            if(this.props.onClear && (item.value === this.props.value)){
              this.props.onClear()
            }

            if(this.props.onDeleteData){
              this.props.onDeleteData()
            }
          });
        });
      });
    }
    onDefault = (item) => {
      let data = {
        [this.componentId]: item.value,
        [this.props.keyDefault]: !item[this.props.keyDefault],
      };

      this.setState({
        isLoading: true,
      }, () => {
        this.callUpdateApi(data, (newItem, index) => {
          this.setState({
            isLoading: false,
          }, () => {
            if(newItem && this.props.onChange){
              this.props.onChange(newItem.value, newItem, index)
            }
          });
        });
      });
    }
    onAddFreeText = (value) => {
      let data = {
        [this.componentName]: value
      };

      this.setState({
        isLoading: true,
      }, () => {
        this.callCreateApi(data, (newItem, index) => {
          this.setState({
            isLoading: false,
          }, () => {
            if(newItem && this.props.onChange){
              this.props.onChange(newItem.value, newItem, index)
            }
          });
        });
      });
    }


    onTypeFreeText = (value) => {
      let index = 0;
      let item = null;

      let newItem = {
        [this.componentValue]: value,
        [this.componentLabel]: value,
        [this.componentItem]: item,
      }

      let options = this.state.options;
      options.unshift(newItem);
      
      this.setState({
        options: options,
      }, () => {
        this.props.onChange(newItem.value, newItem, index)
      })
    }
    /* END FUNCTIONS */


    render() {
      let { 
        isResponse,
        isError,
        statusCode,
        callbackError500,
        callback,
        isLoading,

        dispatchApiCallGet,
        dispatchApiCallPost,
        dispatchApiCallPut,
        dispatchApiCallDelete,
        clear,
        
        customName,
        componentTitle,
        componentApi,
        componentId,
        componentName,
        componentInactive,
        componentData,
        componentCreateData,
        componentTotal,
        componentValue,
        componentLabel,
        componentItem,

        keyValue,
        keyLabel,

        pageStart,
        hasMore,
        
        pageSize,
        sortColumn,
        sortDir,
        
        renderReadAPI,

        ...rest
      } = this.props;

      return <Target 
        ref={this.targetRef}
        crudRef={this}

        keyValue={this.componentValue}
        keyLabel={this.componentLabel}

        options={this.state.options}
        isLoading={this.state.isLoading}

        pageStart={this.state.currentPage}
        hasMore={this.state.hasMore}
        
        onEnter={this.onEnter}
        onEntered={this.onEntered}
        onExited={this.onExited}

        onLoadMore={this.onLoadMore}

        onSearchPressEnter={this.onSearchPressEnter}
        onClearSearch={this.onClearSearch}

        onCreate={this.onCreate}
        onUpdate={this.onUpdate}
        onDelete={this.onDelete}
        onDefault={this.onDefault}
        onAddFreeText={this.onAddFreeText}
        onTypeFreeText={this.onTypeFreeText}
        
        {...rest}
      />
    }
  }

  const mapDispatchToProps = {
    dispatchApiCallGet,
    dispatchApiCallPost,
    dispatchApiCallPut,
    dispatchApiCallDelete,
    clear,
  }
  
  return connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Component)
};

export default makeCRUD;