import React, { Component } from 'react';

import {
  Box
} from '@material-ui/core';

import Icon from '@material-ui/core/Icon';


class CustomOptionsToggle extends Component {
  
  constructor(props){
    super(props);

    let value = (props.value) ? parseInt(props.value) : 0;
    let options = (props.options && props.options.length > 0) ? props.options : [];
    let disabled = ((props.disabled === true || props.disabled === false) ? props.disabled : false);
    let className = (props.className) ? props.className : '';

    this.state = {
        value: value,
        options: options,
        disabled: disabled,
        className: className,
    }
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.value) {
        let value = (nextProps.value) ? parseInt(nextProps.value) : 0;
        this.setState({
            value: value
        });
    }
    if (nextProps.options !== this.state.options) {
        let options = (nextProps.options && nextProps.options.length > 0) ? nextProps.options : [];
        this.setState({
            options: options
        });
    }
    if (nextProps.disabled !== this.state.disabled) {
        let disabled = ((nextProps.disabled === true || nextProps.disabled === false) ? nextProps.disabled : false);
        this.setState({
            disabled: disabled
        });
    }
  }


  setValue = (direction) => {
    if(!this.state.disabled && this.props.onChange){
        if(this.state.value !== '' && this.state.options && this.state.options.length > 0){
            let value = this.state.value;

            if(direction === 'up'){
                if(value < (this.state.options.length - 1)){
                    value = value + 1;
                } else {
                    value = 0;
                }
            } else {
                if(value > 0){
                    value = value - 1;
                } else {
                    value = (this.state.options.length - 1);
                }
            }

            this.setState({
                value: value
            }, () => {
                this.props.onChange(this.state.value);
            });
        }
    }
  }

  getText = () => {
    if(this.state.value !== '' && this.state.options && this.state.options.length > 0){
        return this.state.options[this.state.value].label;
    } else {
        return 0;
    }
  }


  render() {
    return <Box className={"custom-toggle-component custom-options " + this.state.className + (this.state.disabled ? 'disabled' : ((this.state.value === 0) ? '' : ((this.state.value === 1) ? '' : '')))}>
      <Box className={'custom-wrapper'}
        onClick={() => {
            this.setValue('up')
        }}
      >
          <Box className={'custom-text'}>{this.getText()}</Box>
          <Box className={'custom-btns'}>
            <Box className={'custom-up'}
                onClick={() => {
                    this.setValue('up')
                }}
            >
                <Icon component={'i'} >expand_less</Icon>
            </Box>
            <Box className={'custom-down'}
                onClick={() => {
                    this.setValue('down')
                }}
            >
                <Icon component={'i'} >expand_more</Icon>
            </Box>
          </Box>
      </Box>
    </Box>;
  }
}

export default CustomOptionsToggle;
