import React from 'react';
import InputMask from 'react-input-mask';
import moment from 'moment';
import _ from 'lodash';

import { 
    Row,
    Col,
    Form,
} from "react-bootstrap-v5";

import Utils from '../../../../utils/utils';
import { StyledRemoveButton } from '../../../../utils/styles';

import RSuiteDatePicker from '../../../../components/OLD/Datepicker/RSuiteDatePicker';

import { useDispatch, useSelector } from "react-redux";
import {RootState} from '../../../../../setup'
import payrollBetaForm, { fields, specialDate, workTime, getDayOfWeekLabel } from '../redux/payrollBetaForm';

import { useFormikContext } from '../PayrollBetaForm';

import { StyledColLeft, StyledColRight, StyledColInput, StyledColRemove, StyledDatePicker, StyledLinkButton, StyledFormText, StyledFormLabel, StyledFormCheckWwitch } from '../style/styles';


const PayrollBetaStep1 = (props: any) => {
    const { values, errors, setFieldValue, validateForm } = useFormikContext();

    // const dispatch = useDispatch();
    // const { values } = useSelector((state: RootState) => state.payrollBetaForm);

    
    const readOnlyTimeFrom = (times: Array<any>, index: number) => {
        let isOnlyOneRow = (times.length === 1) ? true : false;
        let isFirstRow = (index === 0) ? true : false;
        // let isLastRow = (index === (times.length - 1)) ? true : false;

        if(isOnlyOneRow){
            //if only 1 row, start and end time are readonly
            return true
        } else {
            if(isFirstRow){
                // first row start time is readonly and always 00:00
                return true
            } else {
                return false
            }
        }
    }
    const readOnlyTimeTo = (times: Array<any>, index: number) => {
        let isOnlyOneRow = (times.length === 1) ? true : false;
        // let isFirstRow = (index === 0) ? true : false;
        let isLastRow = (index === (times.length - 1)) ? true : false;

        if(isOnlyOneRow){
            //if only 1 row, start and end time are readonly
            return true
        } else {
            if(isLastRow){
                // last row end time is readonly and always 23:59
                return true
            } else {
                return false
            }
        }
    }

    const addTimeRow = (i: number) => {
        let times = _.cloneDeep(values.workTimes[i].times);

        let lastRow = times[times.length - 1];
        lastRow.timeTo = '';

        if(lastRow.timeFrom != '23:58'){
            let obj = {...workTime};
            obj.timeFrom = '';
    
            times.push(obj);
            setFieldValue('workTimes.' + i + '.times', times);
        } else {
            Utils.toast('Time from cannot start with 23:58', 'error');
        }
    }
    const removeTimeRow = (i: number, j: number) => {
        let times = _.cloneDeep(values.workTimes[i].times);

        let isLastRow = (j === (times.length-1)) ? true : false;
        if(isLastRow){
            let lastRow = times[j-1];
            lastRow.timeTo = moment().endOf('day').format('HH:mm');
        } else {
            let currentRow = times[j];
            let nextRow = times[j+1];
            nextRow.timeFrom = currentRow.timeFrom
        }
        
        times.splice(j, 1);
        setFieldValue('workTimes.' + i + '.times', times);
    }
    const updateTimeFrom = (i: number, j: number, value: string) => {
        const parsedTime = moment.utc(value, 'HH:mm');

        setFieldValue('workTimes.' + i + '.times.' + j + '.timeFrom', value);
        setFieldValue('workTimes.' + i + '.times.' + (j-1) + '.timeTo', parsedTime.subtract(1, 'minutes').format('HH:mm'));

        setTimeout(() => {
            validateForm()
        });
    }
    const updateTimeTo = (i: number, j: number, value: string) => {
        const parsedTime = moment.utc(value, 'HH:mm');

        setFieldValue('workTimes.' + i + '.times.' + (j+1) + '.timeFrom', parsedTime.add(1, 'minutes').format('HH:mm'));
        setFieldValue('workTimes.' + i + '.times.' + j + '.timeTo', value);

        setTimeout(() => {
            validateForm()
        });
    }
    
    const addDateTimeRow = (i: number) => {
        let times = _.cloneDeep(values.specialDates[i].times);

        let lastRow = times[times.length - 1];
        lastRow.timeTo = '';

        if(lastRow.timeFrom != '23:58'){
            let obj = {...workTime};
            obj.timeFrom = '';
    
            times.push(obj);
            setFieldValue('specialDates.' + i + '.times', times);
        } else {
            Utils.toast('Time from cannot start with 23:58', 'error');
        }
    }
    const removeDateTimeRow = (i: number, j: number) => {
        let times = _.cloneDeep(values.specialDates[i].times);

        if(times.length > 1){
            let isLastRow = (j === (times.length-1)) ? true : false;
            if(isLastRow){
                let lastRow = times[j-1];
                lastRow.timeTo = moment().endOf('day').format('HH:mm');
            } else {
                let currentRow = times[j];
                let nextRow = times[j+1];
                nextRow.timeFrom = currentRow.timeFrom
            }
            
            times.splice(j, 1);
            setFieldValue('specialDates.' + i + '.times', times);
        } else {
            let arr = _.cloneDeep(values.specialDates);
            arr.splice(i, 1);
            setFieldValue('specialDates', arr);
        }
    }
    const updateDateTimeFrom = (i: number, j: number, value: string) => {
        const parsedTime = moment.utc(value, 'HH:mm');

        setFieldValue('specialDates.' + i + '.times.' + j + '.timeFrom', value);
        setFieldValue('specialDates.' + i + '.times.' + (j-1) + '.timeTo', parsedTime.subtract(1, 'minutes').format('HH:mm'));

        setTimeout(() => {
            validateForm()
        });
    }
    const updateDateTimeTo = (i: number, j: number, value: string) => {
        const parsedTime = moment.utc(value, 'HH:mm');

        setFieldValue('specialDates.' + i + '.times.' + (j+1) + '.timeFrom', parsedTime.add(1, 'minutes').format('HH:mm'));
        setFieldValue('specialDates.' + i + '.times.' + j + '.timeTo', value);

        setTimeout(() => {
            validateForm()
        });
    }

    const workTimeForm = () => {
        if(values.workTimes && values.workTimes.length > 0){
            return values.workTimes.map((item: any, i: number) => {
                return <Row key={'workTimes_' + i} className={'mb-3'}>
                    <Col xs={2} md={2} lg={2} xl={1} className={'mt-0 mt-md-9'}><StyledFormText>{getDayOfWeekLabel(item.day)}</StyledFormText></Col>
                    <Col xs={2} md={2} lg={2} xl={1} className={'mt-0 mt-md-9'}>
                        <StyledFormCheckWwitch
                            type="switch"
                            tabIndex={-1}
                            id={fields.workTimes.id + '_' + i + '_' + fields.workTimesIsEnabled.id + '_isEnabled'}
                            label={null}
                            checked={item.isEnabled}
                            onChange={(e: any) => {
                                setFieldValue('workTimes.' + i + '.isEnabled', e.target.checked);
                            }}
                        />
                    </Col>
                    {
                    item.isEnabled
                    ?
                    <Col xs={12} md={8} lg={8} xl={9}>
                        <div className={'text-end'} style={{ paddingRight: '30px' }}>
                            <StyledLinkButton
                                type={'button'}
                                tabIndex={-1}
                                onClick={(e) => {
                                    validateForm().then((err: any) => {
                                        let state = false;
                                        if(!!(err && err.workTimes && err.workTimes[i])){
                                            state = false;
                                        } else {
                                            state = true;
                                        }

                                        if(state){
                                            addTimeRow(i);
                                        } else {
                                            Utils.toast(fields.times.err, 'error');
                                        }
                                    })
                                }}
                            >{fields.times.add}</StyledLinkButton>
                        </div>

                        {item.times.map((subItem: any, j: number) => {
                            return <Row key={'work_times_' + i + '_day_' + j} className={'align-items-center mb-2'}>
                                <Col xs={true}>
                                    <Row className={'align-items-center'}>
                                        <Col xs={12} sm={true} className={'custom-rsuite-time-picker-component'}>
                                            <Form.Control
                                                as = {InputMask}
                                                mask={'99:99'}
                                                size={'sm'}

                                                tabIndex={(i*j)}
                                                readOnly={readOnlyTimeFrom(item.times, j)}

                                                id={fields.workTimes.id + '_' + i + '_' + fields.workTimesTimeFrom.id + '_' + j + '_timeFrom'}
                                                isInvalid={readOnlyTimeFrom(item.times, j) ? false : !!(errors.workTimes && errors.workTimes[i] && errors.workTimes[i].times && errors.workTimes[i].times[j] && errors.workTimes[i].times[j].timeFrom)}
                                                placeholder={fields.workTimesTimeFrom.placeholder}
                                                value={subItem.timeFrom}
                                                onChange={(e: any) => {
                                                    const hasUnderscore = e.target.value.includes('_');
                                                    if(!hasUnderscore && e.target.value != ''){
                                                        updateTimeFrom(i, j, e.target.value);
                                                    } else {
                                                        setFieldValue('workTimes.' + i + '.times.' + j + '.timeFrom', e.target.value);
                                                    }
                                                }}
                                            />
                                        </Col>
                                        <Col xs={'auto'} className={'d-none d-lg-block px-1'}><StyledFormText>TO</StyledFormText></Col>
                                        <Col xs={12} sm={true} className={'custom-rsuite-time-picker-component'}>
                                            <Form.Control
                                                as = {InputMask}
                                                mask={'99:99'}
                                                size={'sm'}
                                                tabIndex={(i*j)}

                                                readOnly={readOnlyTimeTo(item.times, j)}
                                                
                                                id={fields.workTimes.id + '_' + i + '_' + fields.workTimesTimeTo.id + '_' + j + '_timeTo'}
                                                isInvalid={readOnlyTimeTo(item.times, j) ? false : !!(errors.workTimes && errors.workTimes[i] && errors.workTimes[i].times && errors.workTimes[i].times[j] && errors.workTimes[i].times[j].timeTo)}
                                                placeholder={fields.workTimesTimeTo.placeholder}
                                                value={subItem.timeTo}
                                                onChange={(e: any) => {
                                                    const hasUnderscore = e.target.value.includes('_');
                                                    if(!hasUnderscore && e.target.value != ''){
                                                        updateTimeTo(i, j, e.target.value);
                                                    } else {
                                                        setFieldValue('workTimes.' + i + '.times.' + j + '.timeTo', e.target.value);
                                                    }
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <StyledColRemove xs={'auto'}>
                                    {(j > 0) && <StyledRemoveButton
                                        type={'button'}
                                        tabIndex={-1}
                                        onClick={(e) => {
                                            removeTimeRow(i, j);
                                        }}
                                    >
                                        <i className={'fas fa-minus'}></i>
                                    </StyledRemoveButton>}
                                </StyledColRemove>
                            </Row>
                        })}
                    </Col>
                    :
                    <Col xs={2} className={'mt-0 mt-md-9'}><StyledFormText>{fields.workTimesIsEnabled.placeholder}</StyledFormText></Col>
                    }
                </Row>
            })
        }
    }

    const specialDatesForm = () => {
        return <Row className={'align-items-center'}>
            <Col xs={12} className={'mb-7 mt-5'}>
                <Row className={'align-items-center'}>
                    <Col xs={'auto'}>
                        <StyledFormLabel>{fields.hasSpecialDates.label}</StyledFormLabel>
                    </Col>
                    <Col xs={true}>
                        <StyledFormCheckWwitch
                            type="switch"
                            tabIndex={-1}
                            id={fields.hasSpecialDates.id + '_hasSpecialDates'}
                            label={null}
                            checked={values.hasSpecialDates}
                            onChange={(e: any) => {
                                setFieldValue('hasSpecialDates', e.target.checked);
                            }}
                        />
                    </Col>
                </Row>
            </Col>
            {values.hasSpecialDates && <Col xs={12}>
                {(values.specialDates && values.specialDates.length > 0) && values.specialDates.map((item: any, i: number) => {
                    return <Row key={'specialDates_' + i} className={'mb-7'}>
                        <Col xs={12} md={4} lg={4} xl={4}>
                            <div className={'text-start'} style={{ paddingRight: '30px' }}>
                                {
                                (i == 0)
                                ?
                                <StyledLinkButton
                                    type={'button'}
                                    tabIndex={-1}
                                    onClick={(e) => {
                                        validateForm().then((err: any) => {
                                            let state = false;
                                            if(typeof err.specialDates === "string"){
                                                state = (err.specialDates.includes('unique')) ? false : true;
                                            } else if(!!err.specialDates){
                                                state = false;
                                            } else {
                                                state = true;
                                            }

                                            if(state){
                                                if(!values.specialDates.some((x: any) => (x.date == null || x.date == ''))){
                                                    let arr = [...values.specialDates];
                                                    arr.push(specialDate);
                                                    setFieldValue('specialDates', arr);
                                                } else {
                                                    Utils.toast(fields.specialDates.errEmpty, 'error');
                                                }
                                            } else {
                                                Utils.toast(fields.specialDates.err, 'error');
                                            }
                                        })
                                    }}
                                >{fields.specialDates.add}</StyledLinkButton>
                                :
                                <span>&nbsp;</span>
                                }
                            </div>

                            <StyledDatePicker
                                as = {RSuiteDatePicker}
                                isInvalid={!!(errors.specialDates && errors.specialDates.length > 0 && errors.specialDates[i] && errors.specialDates[i].date)}
                                className={'form-control'}
                                tabIndex={1000+i}
                                
                                placeholder={fields.specialDatesDate.placeholder}
                                id={fields.specialDates.id + '_' + i + '_date'}
                                value={item.date}
                                icon={<></>}
                                placement={'bottomStart'}
                                cleanable={true}
                                isDefault={true}
                                readOnly={false}
                                oneTap={true}
                                // disabledDate={(date: any) => {
                                //     return (moment(date).isAfter(moment().subtract(1, 'days'))) ? false : true;
                                // }}
                                // onChange={(value: any) => {
                                //     setFieldValue('specialDates.' + i + '.date', value);
                                // }}
                                onChangeCalendarDate={(value: any) => {
                                    setFieldValue('specialDates.' + i + '.date', value);
                                }}
                            />
                        </Col>
                        <Col xs={12} md={8} lg={8} xl={8}>
                            <div className={'text-end'} style={{ paddingRight: '30px' }}>
                                <StyledLinkButton
                                    type={'button'}
                                    tabIndex={-1}
                                    onClick={(e) => {
                                        validateForm().then((err: any) => {
                                            let state = false;
                                            if(!!(err && err.specialDates && err.specialDates[i])){
                                                state = false;
                                            } else {
                                                state = true;
                                            }

                                            if(state){
                                                addDateTimeRow(i);
                                            } else {
                                                Utils.toast(fields.times.err, 'error');
                                            }
                                        })
                                    }}
                                >{fields.times.add}</StyledLinkButton>
                            </div>

                            {item.times.map((subItem: any, j: number) => {
                                return <Row key={'special_dates_times_' + i + '_day_' + j} className={'align-items-center mb-2'}>
                                    <Col xs={true}>
                                        <Row className={'align-items-center'}>
                                            <Col xs={12} sm={true} className={'custom-rsuite-time-picker-component'}>
                                                <Form.Control
                                                    as = {InputMask}
                                                    mask={'99:99'}
                                                    size={'sm'}

                                                    tabIndex={(i*j)}
                                                    readOnly={readOnlyTimeFrom(item.times, j)}

                                                    id={fields.workTimes.id + '_' + i + '_' + fields.workTimesTimeFrom.id + '_' + j + '_timeFrom'}
                                                    isInvalid={readOnlyTimeFrom(item.times, j) ? false : !!(errors.specialDates && errors.specialDates[i] && errors.specialDates[i].times && errors.specialDates[i].times[j] && errors.specialDates[i].times[j].timeFrom)}
                                                    placeholder={fields.workTimesTimeFrom.placeholder}
                                                    value={subItem.timeFrom}
                                                    onChange={(e: any) => {
                                                        const hasUnderscore = e.target.value.includes('_');
                                                        if(!hasUnderscore && e.target.value != ''){
                                                            updateDateTimeFrom(i, j, e.target.value);
                                                        } else {
                                                            setFieldValue('specialDates.' + i + '.times.' + j + '.timeFrom', e.target.value);
                                                        }
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={'auto'} className={'d-none d-lg-block px-1'}><StyledFormText>TO</StyledFormText></Col>
                                            <Col xs={12} sm={true} className={'custom-rsuite-time-picker-component'}>
                                                <Form.Control
                                                    as = {InputMask}
                                                    mask={'99:99'}
                                                    size={'sm'}
                                                    tabIndex={(i*j)}

                                                    readOnly={readOnlyTimeTo(item.times, j)}
                                                    
                                                    id={fields.workTimes.id + '_' + i + '_' + fields.workTimesTimeTo.id + '_' + j + '_timeTo'}
                                                    isInvalid={readOnlyTimeTo(item.times, j) ? false : !!(errors.specialDates && errors.specialDates[i] && errors.specialDates[i].times && errors.specialDates[i].times[j] && errors.specialDates[i].times[j].timeTo)}
                                                    placeholder={fields.workTimesTimeTo.placeholder}
                                                    value={subItem.timeTo}
                                                    onChange={(e: any) => {
                                                        const hasUnderscore = e.target.value.includes('_');
                                                        if(!hasUnderscore && e.target.value != ''){
                                                            updateDateTimeTo(i, j, e.target.value);
                                                        } else {
                                                            setFieldValue('specialDates.' + i + '.times.' + j + '.timeTo', e.target.value);
                                                        }
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <StyledColRemove xs={'auto'}>
                                        {!(i == 0 && j == 0) && <StyledRemoveButton
                                            type={'button'}
                                            tabIndex={-1}
                                            onClick={(e) => {
                                                removeDateTimeRow(i, j);
                                            }}
                                        >
                                            <i className={'fas fa-minus'}></i>
                                        </StyledRemoveButton>}
                                    </StyledColRemove>
                                </Row>
                            })}
                        </Col>
                    </Row>
                })}
                <Form.Control.Feedback type="invalid" className={'d-block'}>{(errors.specialDates && typeof errors.specialDates === "string") && errors.specialDates}</Form.Control.Feedback>
            </Col>}
        </Row>
    }


    return <Row>
        <StyledColLeft xs={12} md={6} hasspecialdate={"true"}>
            {workTimeForm()}
            <Form.Control.Feedback type="invalid" className={'d-block'}>{(errors.workTimes && typeof errors.workTimes === "string") && errors.workTimes}</Form.Control.Feedback>
        </StyledColLeft>
        <StyledColRight xs={12} md={6}>{specialDatesForm()}</StyledColRight>
    </Row>
}


export default PayrollBetaStep1;
