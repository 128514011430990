import {
    CLEAR,
    SUCCESS,
    ERROR,
    INITIAL_STATE,
} from '../actions';

import {
    DISPATCH_POST,
    DISPATCH_GET,
    DISPATCH_PUT,
    DISPATCH_DELETE,
} from './actions';

export const reducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case DISPATCH_POST:
        case DISPATCH_GET:
        case DISPATCH_PUT:
        case DISPATCH_DELETE:
        case SUCCESS:
        case ERROR:
        case CLEAR:
            return payload;

        default:
            return state;
    }
}