import React from 'react';
import _ from 'lodash';

import { 
    Row,
    Col,
    Form,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import { useSelector } from "react-redux";
import { BillingTypes } from '../../../../utils/enums';


const StyledUl = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
        float: unset;
        padding-right: 15px;
        min-width: 154px;

        @media (min-width: 992px) {
            float: left;
        }

        a {
            i {
                font-size: 16px;
                color: #185cff;
                position: relative;
                top: 4px;
            }

            &:hover {
                opacity: 0.5
            }
        }
    }
`;


const TabInfo = props => {
    const { billingType, successItems } = useSelector((state) => state.invoiceNumber);
    const { formLabels } = useSelector((state) => state.invoiceNumberForm);


    const customerForm = (customer, i) => {
        return <>
            <Col xs={12} className={'mb-3 px-5 px-md-10'}>
                <Row className={'mt-2 mb-3'}>
                    <Col xs={12} md={3}>
                        <Form.Group>
                            <Form.Control className={'mb-0'} plaintext readOnly defaultValue={formLabels.customerName.label} />
                            <Form.Label className={'mb-0'}>{(customer && customer.customer && customer.customer.customerName) ? customer.customer.customerName : ''}</Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group>
                            <Form.Control className={'mb-0'} plaintext readOnly defaultValue={formLabels.billingName.label} />
                            <Form.Label className={'mb-0'}>{(customer && customer.customer && customer.customer.billingName) ? customer.customer.billingName : ''}</Form.Label>
                        </Form.Group>
                    </Col>
                </Row>
            </Col>
        </>
    }

    const invoiceNumberForm = (customer, i) => {
        let sites = customer.siteJobs.filter(site => {
            return site.jobs.some(job => job.response != null);
        });

        return <Col xs={12} className={'mb-3 px-5 px-md-10'}>
            <Row>
                <Col xs={12} lg={4} xl={3}>
                    <Form.Control className={'mb-0'} plaintext readOnly defaultValue={'Site name'} />
                </Col>
                <Col xs={12} lg={4} xl={2}>
                    <Form.Control className={'mb-0'} plaintext readOnly defaultValue={(billingType == BillingTypes.Purchase) ? 'Purchase number' : (billingType == BillingTypes.Expense) ? 'Expense number' : 'Invoice number'} />
                </Col>
                <Col xs={12} lg={4} xl={7}>
                    <Form.Control className={'mb-0'} plaintext readOnly defaultValue={'Delivery orders'} />
                </Col>
            </Row>

            {(sites && sites.length > 0) && <Row>
                {sites.map((site, j) => {
                    let filteredJobs = site.jobs;
                    if(billingType == BillingTypes.Purchase){
                        filteredJobs = _.uniqBy(site.jobs, obj => _.get(obj, 'response.job.purchaseOrderNumber'));
                    } else if(billingType == BillingTypes.Expense){
                            filteredJobs = _.uniqBy(site.jobs, obj => _.get(obj, 'response.job.expenseNumber'));
                    } else {
                        filteredJobs = _.uniqBy(site.jobs, obj => _.get(obj, 'response.job.invoiceNumber'));
                    }

                    return <React.Fragment key={'success_invoicenumber_site_' + j}>
                        <Col xs={12} lg={4} xl={3} className={'py-1'}>
                            <Form.Label className={'mb-0'}>{site.siteName}</Form.Label>
                        </Col>
                        <Col xs={12} lg={4} xl={2} className={'py-1'}>
                            {(filteredJobs && filteredJobs.length > 0) && <StyledUl>
                                {filteredJobs.filter(x => x.isBillable).map((job, k) => {
                                    if(job && job.response)
                                    return <li key={'success_invoicenumber_job_' + k}>
                                        <a href={
                                            (billingType == BillingTypes.Purchase)
                                            ?
                                                (job.response.job && job.response.job.xeroPurchaseOrderUrl && job.response.job.xeroPurchaseOrderUrl != '')
                                                ?
                                                job.response.job.xeroPurchaseOrderUrl
                                                :
                                                    (job.response.job && job.response.job.quickbookPurchaseOrderUrl && job.response.job.quickbookPurchaseOrderUrl != '')
                                                    ?
                                                    job.response.job.quickbookPurchaseOrderUrl
                                                    :
                                                    ''
                                            :
                                            (billingType == BillingTypes.Expense)
                                            ?
                                                (job.response.job && job.response.job.xeroExpenseUrl && job.response.job.xeroExpenseUrl != '')
                                                ?
                                                job.response.job.xeroExpenseUrl
                                                :
                                                    (job.response.job && job.response.job.quickbookExpenseUrl && job.response.job.quickbookExpenseUrl != '')
                                                    ?
                                                    job.response.job.quickbookExpenseUrl
                                                    :
                                                    ''
                                            :
                                            (job.response.job && job.response.job.xeroInvoiceUrl && job.response.job.xeroInvoiceUrl != '')
                                                ? 
                                                job.response.job.xeroInvoiceUrl
                                                :
                                                    (job.response.job && job.response.job.quickbookInvoiceUrl && job.response.job.quickbookInvoiceUrl != '')
                                                    ?
                                                    job.response.job.quickbookInvoiceUrl
                                                    :
                                                    ''
                                        }
                                            target={'_blank'}
                                            rel="noreferrer"
                                            className={'link-primary-underline'}
                                        >
                                            {
                                                (billingType == BillingTypes.Purchase)
                                                ?
                                                job.response.job && job.response.job.purchaseOrderNumber ? job.response.job.purchaseOrderNumber : ''
                                                :
                                                (billingType == BillingTypes.Expense)
                                                ?
                                                job.response.job && job.response.job.expenseNumber ? job.response.job.expenseNumber : ''
                                                :
                                                job.response.job && job.response.job.invoiceNumber ? job.response.job.invoiceNumber : ''
                                            }
                                            <i className={'material-icons ps-1'}>open_in_new</i>
                                        </a>
                                    </li>
                                })}
                            </StyledUl>}
                        </Col>
                        <Col xs={12} lg={4} xl={7} className={'py-1'}>
                            {(site && site.jobs && site.jobs.length > 0) && <StyledUl>
                                {site.jobs.filter(x => x.isBillable).map((job, k) => {
                                    return <li key={'success_deliveryorders_job_' + k}>
                                        <a href={'/billing/billing-form?id=' + job.jobId + '&billingType=' + billingType}
                                            target={'_blank'}
                                            rel="noreferrer"
                                            className={'link-primary-underline'}
                                        >
                                            {(job && job.response && job.response.job) ? job.response.job.jobNumber : job.jobNumber}
                                            <i className={'material-icons ps-1'}>open_in_new</i>
                                        </a>
                                    </li>
                                })}
                            </StyledUl>}
                        </Col>
                    </React.Fragment>
                })}
            </Row>}
        </Col>
    }


    return <>
        <div>
            {
                (successItems && successItems.length > 0)
                ?
                successItems.map((customer, i) => {
                    return <Row key={'success_customer_' + i} className={'mb-5'}>
                        {customerForm(customer, i)}
                        {invoiceNumberForm(customer, i)}
                        {(i < (successItems.length - 1)) && <Col><hr /></Col>}
                    </Row>
                })
                :
                <Row className={'mb-5'}>
                    <Col xs={12} className={'mb-3 px-5 px-md-10 mt-10 text-center'}>
                        <Form.Group>
                            <Form.Label className={'mb-0'}>{'No successful jobs'}</Form.Label>
                        </Form.Group>
                    </Col>
                </Row>
            }
        </div>
    </>
}


export default TabInfo;
