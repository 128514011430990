import React from 'react';
import {shallowEqual, useSelector} from 'react-redux'
import { Redirect } from 'react-router-dom';

import {RootState} from '../../setup'


const RedirectLayout = ({...rest }) => {
    const auth: any = useSelector<RootState>(({auth}) => auth, shallowEqual)
    const user = (auth && auth.user) ? auth.user : null;

    const isAuthorized = (user) ? true : false;
    const [permissions] = React.useState((user && user.permissions && user.permissions.length > 0) ? user.permissions : []);


    if(isAuthorized){
        return (
            <Redirect to={rest.checkPermission(permissions)} />
        )
    } else {
        return (
            <Redirect to="/sign-in" />
        )
    }
};

export default RedirectLayout;