/* eslint-disable eqeqeq */
import React from 'react';
import { useHistory } from "react-router-dom";
import _ from 'lodash';

import { 
    Row,
    Col,
    Button,
    Form,
    Modal,
    CloseButton,
} from "react-bootstrap-v5";

import Utils from '../../../../utils/utils';

import GeofenceView from "../../geofence/GeofenceView";

import { useDispatch, useSelector } from "react-redux";
import {RootState} from '../../../../../setup'
import payrollBetaLayout from '../redux/payrollBetaLayout';
import payrollBetaForm, { SelectedGeofenceStruct, SelectedJobTypeStruct, SelectedGeofenceJobTypeStruct } from '../redux/payrollBetaForm';

import { useFormikContext } from '../PayrollBetaForm';

import { StyledCheck, StyledChecked, StyledUnChecked, StyledFormLabel, StyledFormText, StyledSecondaryRadio } from '../style/styles';


const PayrollBetaStep2 = (props: any) => {
    const { values, errors, setFieldValue, validateForm } = useFormikContext();

    const history = useHistory();

    const dispatch = useDispatch();
    const { step2Radio } = useSelector((state: RootState) => state.payrollBetaLayout);
    const { isLoading, jobTypes, geofenceGroup} = useSelector((state: RootState) => state.payrollBetaForm);

    const [show, setShow] = React.useState(false);


    React.useEffect(() => {
        if(!show){
            dispatch(payrollBetaForm.callGeofenceGroupApi());
        }
    }, [show]);
    
    const getJobTemplates = () => {
        let jobTemplates: any = [];
        if(jobTypes && jobTypes.length > 0){
            let specialDays = [...values.specialDates];
            if(values.specialDates && values.specialDates.length === 1){
                if(values.specialDates[0].date === null){
                    specialDays = [];
                }
            }

            jobTemplates = jobTypes.map((item: any, i: number) => {
                return {
                    jobTemplateId: item.jobTemplateId,
                    jobTemplateName: item.jobTemplateName,
                    workingDays: [...values.workTimes],
                    specialDays: specialDays,
                    isSelected: (i === 0) ? true : false,
                }
            });
        }

        return jobTemplates;
    }

    const syncSpecialDays = (firstArray: any, secondArray: any) => {
        let newSecondArray = firstArray.map((item: any, index: any) => {
          let correspondingItem = (secondArray && secondArray.length > 0) ? secondArray[index] : [];
          
          if (!correspondingItem || correspondingItem.length === 0 || correspondingItem.date === null) {
            return { ...item };
          } else {
            let newItem = {
              ...correspondingItem,
              times: [...correspondingItem.times],
            };
      
            item.times.forEach((time: any, idx: any) => {
              if (!newItem.times[idx]) {
                newItem.times[idx] = { ...time };
              }
            });
      
            newItem.times = newItem.times.filter((_: any, idx: any) => item.times[idx]);
      
            newItem.times = newItem.times.map((time: any, idx: any) => {
              if (item.times[idx]) {
                return {
                  ...time,
                  timeFrom: item.times[idx].timeFrom,
                  timeTo: item.times[idx].timeTo,
                };
              }
              return time;
            });

            return newItem;
          }
        });
      
        return newSecondArray;
    }
    const syncWorkingDays = (firstArray: any, secondArray: any) => {
        let newSecondArray = firstArray.map((item: any, index: any) => {
          let correspondingItem = (secondArray && secondArray.length > 0) ? secondArray[index] : [];
          
          if (!correspondingItem || correspondingItem.length === 0) {
            return { ...item };
          } else {
            let newItem = {
              ...correspondingItem,
              times: [...correspondingItem.times],
            };
      
            item.times.forEach((time: any, idx: any) => {
              if (!newItem.times[idx]) {
                newItem.times[idx] = { ...time };
              }
            });
      
            newItem.times = newItem.times.filter((_: any, idx: any) => item.times[idx]);
      
            newItem.times = newItem.times.map((time: any, idx: any) => {
              if (item.times[idx]) {
                return {
                  ...time,
                  timeFrom: item.times[idx].timeFrom,
                  timeTo: item.times[idx].timeTo,
                };
              }
              return time;
            });
      
            newItem.isEnabled = item.isEnabled;

            return newItem;
          }
        });
      
        return newSecondArray;
    }
    const syncJobTypes = (firstArray: any, secondArray: any) => {
        let newArr = (secondArray && secondArray.length > 0) ? _.cloneDeep(secondArray) : [];
        
        if(firstArray && firstArray.length > 0){
            firstArray.forEach((item: any) => {
                let isExist = newArr.find((x: any) => x.jobTemplateId === item.jobTemplateId);
                if(!isExist){
                    newArr.push(item)
                }
            });
        }

        return newArr;
    }

    const syncWorkTime = () => {
        let newValue: SelectedJobTypeStruct = values.selectedWorkTime ? {...values.selectedWorkTime} : {
            workingDays: _.cloneDeep(values.workTimes),
            specialDays: _.cloneDeep(values.specialDates),
        }
        
        let workingDays = syncWorkingDays(values.workTimes, newValue.workingDays);
        newValue.workingDays = workingDays;
        
        let specialDays = syncSpecialDays(values.specialDates, newValue.specialDays);
        newValue.specialDays = specialDays;
        
        setFieldValue('selectedWorkTime', newValue);
    }
    const syncJobType = () => {
        let jobTemplates: any = getJobTemplates();
        let newValues: Array<SelectedJobTypeStruct> = syncJobTypes(jobTemplates, values.selectedJobType);
        
        if(newValues && newValues.length > 0){
            newValues.forEach((item: any) => {
                let workingDays = syncWorkingDays(values.workTimes, item.workingDays);
                item.workingDays = workingDays;
        
                let specialDays = syncSpecialDays(values.specialDates, item.specialDays);
                item.specialDays = specialDays;
            });
        }
        
        setFieldValue('selectedJobType', newValues);
    }
    const syncGeofence = () => {
        let newValues: Array<SelectedGeofenceStruct> = (values.selectedGeofence && values.selectedGeofence.length > 0) ? [...values.selectedGeofence] : []

        if(newValues && newValues.length > 0){
            newValues.forEach((item: any) => {
                let workingDays = syncWorkingDays(values.workTimes, item.workingDays);
                item.workingDays = workingDays;
        
                let specialDays = syncSpecialDays(values.specialDates, item.specialDays);
                item.specialDays = specialDays;
            });
        }
        
        setFieldValue('selectedGeofence', newValues);
    }
    const syncGeofenceJobType = () => {
        let newValues: Array<SelectedGeofenceJobTypeStruct> = (values.selectedGeofenceJobType && values.selectedGeofenceJobType.length > 0) ? [...values.selectedGeofenceJobType] : []
        if(newValues && newValues.length > 0){
            newValues.forEach((item: any) => {
                if(item && item.jobTemplates.length > 0){
                    item.jobTemplates.forEach((subItem: any) => {
                        let workingDays = syncWorkingDays(values.workTimes, subItem.workingDays);
                        subItem.workingDays = workingDays;
                
                        let specialDays = syncSpecialDays(values.specialDates, subItem.specialDays);
                        subItem.specialDays = specialDays;
                    });
                }
            });
        }
        
        setFieldValue('selectedGeofenceJobType', newValues);
    }

    React.useEffect(() => {
        dispatch(payrollBetaLayout.setMainLoading(true));
        syncWorkTime();
        syncJobType();
        syncGeofence();
        syncGeofenceJobType();
        dispatch(payrollBetaLayout.setMainLoading(false));
    }, [step2Radio]);


    const geofenceForm = () => {
        if(step2Radio){
            return <Row className={'align-items-center'}>
                <Col xs={'auto'} className={'mb-5'}>
                    <StyledFormLabel>Geofence groups found. Select below options or create</StyledFormLabel>
                </Col>
                <Col xs={'auto'} className={'mb-5'}>
                    <Button
                        variant={'secondary'}
                        size={'sm'}
                        onClick={() => {
                            setShow(true);
                        }}
                    >New</Button>
                </Col>

                <Col xs={12}>
                    <Row>
                        {geofenceGroup.map((item: any, i: number) => {
                            return <Col xs={'auto'} key={'geofenceGroup_' + item.accountMapGeofenceGroupId}>
                                <StyledCheck id={'geofenceGroup_' + item.accountMapGeofenceGroupId + '_toggle'}>
                                    <Form.Check.Input
                                        type={'checkbox'}
                                        className={'d-none'}
                                        defaultChecked={(values.selectedGeofence.filter((x: SelectedGeofenceStruct) => x.accountMapGeofenceGroupId == item.accountMapGeofenceGroupId).length > 0) ? true : false}
                                        value={item.accountMapGeofenceGroupId}
                                        onChange={(e: any) => {
                                            let value = e.target.value;

                                            let selectedGeofence = [...values.selectedGeofence];
                                            let selectedGeofenceIndex = selectedGeofence.findIndex((x) => x.accountMapGeofenceGroupId == value);
                                            if (selectedGeofenceIndex === -1) {
                                                let newVlaue: SelectedGeofenceStruct = {
                                                    accountMapGeofenceGroupId: value,
                                                    groupName: item.groupName,
                                                    workingDays: [...values.workTimes],
                                                    specialDays: [...values.specialDates],
                                                }
                                                selectedGeofence.push(newVlaue);
                                            } else {
                                                selectedGeofence.splice(selectedGeofenceIndex, 1);
                                            }
                                            setFieldValue('selectedGeofence', selectedGeofence);


                                            let selectedGeofenceJobType = [...values.selectedGeofenceJobType];
                                            let selectedGeofenceJobTypeIndex = selectedGeofenceJobType.findIndex((x) => x.accountMapGeofenceGroupId == value);
                                            if (selectedGeofenceJobTypeIndex === -1) {
                                                let jobTemplates: any = getJobTemplates();
                                                let newVlaue: SelectedGeofenceJobTypeStruct = {
                                                    accountMapGeofenceGroupId: value,
                                                    groupName: item.groupName,
                                                    jobTemplates: jobTemplates,
                                                }
                                                selectedGeofenceJobType.push(newVlaue);
                                            } else {
                                                selectedGeofenceJobType.splice(selectedGeofenceJobTypeIndex, 1);
                                            }
                                            setFieldValue('selectedGeofenceJobType', selectedGeofenceJobType);
                                        }}
                                    />
                                    <Form.Check.Label>
                                        <Row className={'align-items-center g-2'}>
                                            <Col xs={'auto'}>{values.selectedGeofence.find((x: SelectedGeofenceStruct) => x.accountMapGeofenceGroupId == item.accountMapGeofenceGroupId) ? <StyledChecked className={'fas fa-check'} /> : <StyledUnChecked className={'fas fa-check'} />}</Col>
                                            <Col xs={'auto'}><StyledFormText>{item.groupName}</StyledFormText></Col>
                                        </Row>
                                    </Form.Check.Label>
                                </StyledCheck>
                            </Col>
                        })}
                    </Row>
                </Col>
            </Row>
        }
    }
    
    const noDataForm = () => {
        if(step2Radio){
            return <Row className={'align-items-center'}>
                <Col xs={12} sm={'auto'} className={'mb-5 mb-sm-0'}>
                    <StyledFormLabel>No geofence found!</StyledFormLabel>
                </Col>
                <Col xs={12} sm={'auto'}>
                    <Button
                        variant={'success'}
                        size={'sm'}
                        onClick={() => {
                            setShow(true);
                        }}
                    >Create New</Button>
                </Col>
            </Row>
        }
    }

    const setPopup = () => {
        return <Modal
            show={show}
            fullscreen={true}
            centered={true}
            backdrop={'static'}
            keyboard={false}
            enforceFocus={false}
            onHide={() => {
                setShow(false);
            }}
        >
            <Modal.Header>
                <Row className={'align-items-center gx-0 w-100'}>
                    <Col xs={true} lg={'auto'} className={'order-1 order-lg-1'}>
                        <Modal.Title>Geofence</Modal.Title>
                    </Col>
                    <Col xs={'auto'} lg={true} className={'order-3 order-lg-3'}></Col>
                    <Col xs={'auto'} className={'order-2 order-lg-4'}>
                        <CloseButton
                            className={'rounded-2'}
                            disabled={isLoading}
                            onClick={async () => {
                                setShow(false)
                            }}
                        />
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body className={'p-0'}>
                <GeofenceView isModal={true} initTab={'tab2'} />
            </Modal.Body>
        </Modal>
    }


    return <Row>
        <Col xs={12} className={'mb-10'}>
            <StyledSecondaryRadio
                inline
                type={'radio'}
                name={'step_2'}
                id={'step_2_1'}
                label={'No'}
                checked={!step2Radio}
                onChange={(e: any) => {
                    dispatch(payrollBetaLayout.setStep2Radio(false));
                }}
            />
            <StyledSecondaryRadio
                inline
                type={'radio'}
                name={'step_2'}
                id={'step_2_2'}
                label={'Yes'}
                checked={step2Radio}
                onChange={(e: any) => {
                    dispatch(payrollBetaLayout.setStep2Radio(true));
                }}
            />
        </Col>
        <Col xs={12}>
            {isLoading ? null : (geofenceGroup && geofenceGroup.length > 0) ? geofenceForm() : noDataForm()}
            {setPopup()}
        </Col>
    </Row>
}


export default PayrollBetaStep2;
