import React from "react";
import moment from "moment";
import _ from "lodash";
import CurrencyInput from 'react-currency-input-field';

import { 
    Row,
    Col,
    Form,
    Spinner,
    Button,
    InputGroup,
    Dropdown,
} from "react-bootstrap-v5";
// import styled from 'styled-components';

import { FieldArray } from 'formik';

import { DrawerContentLayout } from '../../../../components/drawer/DrawerLayout'
import Utils from "../../../../utils/utils";
import { BillingTypes, ServiceType, BillingStatus, XeroPaymentTermTypeItems, JobStepType } from "../../../../utils/enums";
import { StyledDropdownButton } from "../../../../utils/styles";

import InputLayout from "../../../../components/input/InputLayout";
import RSuiteSelectPicker from '../../../../components/OLD/Select/RSuiteSelectPicker';
import RSuiteAutoComplete from '../../../../components/OLD/Autocomplete/RSuiteAutoComplete';
import RSuiteDatePicker from '../../../../components/OLD/Datepicker/RSuiteDatePicker';
import IconButton from "../../../../components/Buttons/IconButton";
import IconButtonTooltip from "../../../../components/Buttons/IconButtonTooltip";

import SmartInputDropdown from '../../../../components/dropdown/smartInputDropdown/SmartInputDropdown';
import makeCRUD from "../../../../components/dropdown/smartInputDropdown/makeCRUD";

import { connect } from 'react-redux'
import { clear } from "../../../../../setup/redux/actions";
import { dispatchApiCallGet, dispatchApiCallPost } from '../../../../../setup/redux/dispatch/actions'
import taxRate from "../../../../../setup/redux/slices/taxRate";


const SmartDropdown = makeCRUD(SmartInputDropdown);


// const StyledInputReadOnly = styled(Form.Control)`
//     border: 0px;

//     &[readonly]{
//         color: #181C32 !important;
//     }
// `;


class JobFormBillingDrawer extends React.Component {
  
    constructor(props) {
        super(props);
    
        this.name = 'job_form_billing_drawer';

        this.bodyRef = React.createRef();

        // let dataParams = (props.dataParams) ? props.dataParams : null;
        // let id = (dataParams && dataParams.id) ? dataParams.id : null;
    
        
        let billingType = (props && props.billingType) ? props.billingType : BillingTypes.Invoice;


        this.state = {
            billingType: billingType,

            isReadOnlyInvoiceNumber: false,
        };
    }
    
    
    // async componentDidMount(){}

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
		Utils.reduxProps(nextProps,
            this.name + '-billing_rofile', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.accountBillingProfileId;
                        let title = item.billingProfileName;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(this.state.isError){
                        Utils.toast(this.state.err, 'error'); 
                    }
                
                    if(callback){
                        callback(arr);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.name + '-quickBook_credit_terms', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if(data && data.length > 0) {
                    for (var i = 0; i < data.length; i++) {
                        let item = data[i];
                        let value = item.id;
                        let title = item.name;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(this.state.isError){
                        Utils.toast(this.state.err, 'error'); 
                    }
                
                    if(callback){
                        callback(arr);
                    }
                });
            }
        );

		Utils.reduxProps(nextProps,
            this.name + '-billing_charge_category', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.chargeCategoryId;
                        let title = item.chargeCategoryName;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(this.state.isError){
                        Utils.toast(this.state.err, 'error'); 
                    }
                
                    if(callback){
                        callback(arr);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.name + '-additional_charge_template', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.serviceItemId;
                        let title = item.serviceTag;
                        let arrItem = {
                            value: value,
                            title: title,
                            label: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(this.state.isError){
                        Utils.toast(this.state.err, 'error'); 
                    }
                
                    if(callback){
                        callback(arr);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.name + '-prompt_number', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = [];
                try {
                    arr = (data && data != '') ? data.split('-') : [data];
                } catch(e){}
        
                let prefix = (arr && arr.length > 0) ? arr[0] : '';
                let invoiceNumber = (arr && arr.length > 0) ? arr[arr.length-1] : '';
                
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();
        
                    if(callback){
                        callback(data, prefix, invoiceNumber);
                    }
                });
            }
        );
    }
	
    callReadBillingProfileApi = (items, callback = null) => {
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            isActive: true,
        };
     
        this.props.dispatchApiCallGet(data, this.name + '-billing_rofile', 'AccountBillingProfile', { items: items }, callback, () => {});
    }
    
    callQuickbookReadCreditTermsApi = (quickBookAppId = 0, items, callback = null) => {
        let data = {
            quickBookAppId: quickBookAppId,
        };
     
        this.props.dispatchApiCallGet(data, this.name + '-quickBook_credit_terms', 'quickbookapp/term', { items: items }, callback, () => {});
    }

    callReadChargeCategoryApi = (items, callback = null) => {
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            isActive: true,
        };
     
        this.props.dispatchApiCallGet(data, this.name + '-billing_charge_category', 'chargecategory', { items: items }, callback, () => {});
    }

    callReadAdditionalServicesApi = (items, search = '', callback = null) => {
        let customerId = null;
        if(this.props.dataParams && this.props.dataParams.row){
            customerId = this.props.dataParams.row.customerId;
        }

        let customerSiteId = null;
        if(this.props.dataParams && this.props.dataParams.row){
            customerSiteId = this.props.dataParams.row.customerSiteId;
        }
        
        let data = {
            currentPage: 1,
            pageSize: 30,
            searchQuery: search,
            sortColumn: 'serviceTag',
            sortDir: 'desc',
            customerId: customerId,
            customerSiteId: customerSiteId,
            type: ServiceType.AdditionalService,
            includeGlobalService: true,
            IncludeTaxRate: true,
        };
     
        this.props.dispatchApiCallGet(data, this.name + '-additional_charge_template', 'service-item', { items: items }, callback, () => {});
    }

    callPromptInvoiceNumberApi = (callback = null) => {
        let obj = this.getInvoiceNumberParams();

        if(this.state.billingType === BillingTypes.Purchase){
            let data = {
                accountBillingProfileId: obj.accountBillingProfileId,
                purchaseOrderDate: obj.invoiceDate ? obj.invoiceDate : moment().format("YYYY-MM-DDTHH:mm:ss.000"),
            };
        
            this.props.dispatchApiCallPost(data, this.name + '-prompt_number', 'job/billing/prompt-po-number', null, callback, () => {});
        } else if(this.state.billingType === BillingTypes.Expense){
            let data = {
                accountBillingProfileId: obj.accountBillingProfileId,
                expenseDate: obj.invoiceDate ? obj.invoiceDate : moment().format("YYYY-MM-DDTHH:mm:ss.000"),
            };
        
            this.props.dispatchApiCallPost(data, this.name + '-prompt_number', 'job/billing/prompt-expense-number', null, callback, () => {});
        } else {
            let data = {
                accountBillingProfileId: obj.accountBillingProfileId,
                invoiceDate: obj.invoiceDate ? obj.invoiceDate : moment().format("YYYY-MM-DDTHH:mm:ss.000"),
            };
        
            this.props.dispatchApiCallPost(data, this.name + '-prompt_number', 'job/billing/prompt-invoice-number', null, callback, () => {});
        }
    }
    /* END API */

    
    getAdditionalServiceParams = () => {
        let customerId = null;
        if(this.props.dataParams && this.props.dataParams.row){
            customerId = this.props.dataParams.row.customerId;
        }

        let customerSiteId = null;
        if(this.props.dataParams && this.props.dataParams.row){
            customerSiteId = this.props.dataParams.row.customerSiteId;
        }
        
        return {
            type: ServiceType.AdditionalService,
            customerId: customerId,
            customerSiteId: customerSiteId,
            includeGlobalService: true,
            IncludeTaxRate: true,
        };
    }

    getInvoiceNumberParams = () => {
        let {
            values,
        } = this.props.formOptions;
        
        let accountBillingProfileId = (values && values.accountBillingProfileId) ? values.accountBillingProfileId : null;
        let invoiceDate = (values && values.invoiceDate) ? values.invoiceDate : new Date();

        return {
            accountBillingProfileId: accountBillingProfileId,
            invoiceDate: invoiceDate,
        };
    }


    body = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;

        let row = (this.props.dataParams && this.props.dataParams.row) ? this.props.dataParams.row : null;
        let currentStatusName = (row && row.currentStatusName) ? row.currentStatusName : '';
        let isActive = (row && ((row.isActive == false) || (row.isActive == true))) ? row.isActive : true;

        let customFormParams = (row && row.customFormParams) ? row.customFormParams : null;
        let jobNumber = (customFormParams && customFormParams.jobNumber) ? customFormParams.jobNumber : null;
        let customerName = (row && row.customerName) ? row.customerName : null;
        
        return <Row className={'p-2 p-md-10 g-10'}>
            <Col xs={12} lg={6}>
                <Row>
                    {jobNumber &&<Col xs={12}>
                        <InputLayout
                            label={'DO #'}
                        >
                            <Form.Control
                                value={jobNumber}
                                readOnly
                            />
                        </InputLayout>
                    </Col>}
                    {customerName &&<Col xs={12}>
                        <InputLayout
                            label={'Customer name'}
                        >
                            <Form.Control
                                value={customerName}
                                readOnly
                            />
                        </InputLayout>
                    </Col>}

                    <Col xs={12}>
                        <InputLayout
                            label={this.props.fields.isBillable.label}
                        >
                            <Form.Control
                                as = {RSuiteSelectPicker}

                                id={'status'}
                                placeholder={''}
                                items={[
                                    {
                                        value: 0,
                                        title: 'Non Billable',
                                    },
                                    {
                                        value: 1,
                                        title: 'Billable',
                                    },
                                ]}
                                value={(values.isBillable) ? 1 : 0}
                                isLoading={false}
                                searchable={false}
                                cleanable={false}
                                readOnly={(this.props.jobTemplateType == JobStepType.Out) ? true : Utils.isRejectedOrCancelledOrFailedOrIsNotActive(currentStatusName, isActive)}
                                isDefault={true}
                                renderValue={(value, item, selectedElement) => {
                                    return <span className={(value === 0) ? 'text-danger' : 'text-primary'}>{item.title}</span>
                                }}
                                onSelect={async (value, item) => {
                                    await setFieldValue('isBillable', (value === 1) ? true : false);

                                    if(this.props.onChangeStatus){
                                        this.props.onChangeStatus((value === 1) ? true : false);
                                    }
                                }}
                            />
                        </InputLayout>
                    </Col>

                    {values.isBillable && <>
                        <Col xs={12}>
                            <InputLayout
                                label={this.props.fields.accountBillingProfileId.label}
                            >
                                <Form.Control
                                    as = {RSuiteSelectPicker}
                                    isInvalid={!!errors.accountBillingProfileId}

                                    id={this.props.fields.accountBillingProfileId.id}
                                    placeholder={this.props.fields.accountBillingProfileId.placeholder}
                                    items={values.customFormParams.accountBillingProfileItems}
                                    value={values.accountBillingProfileId}
                                    selectedName={values.accountBillingProfileName}
                                    isLoading={values.customFormParams.accountBillingProfileIsLoading}
                                    // readOnly={Utils.isRejectedOrCancelledOrFailedOrIsNotActive(currentStatusName, isActive)}
                                    readOnly={true}
                                    searchable={true}
                                    cleanable={true}
                                    isDefault={true}
                                    onClick={async () => {
                                        await setFieldValue('customFormParams.accountBillingProfileIsLoading', true);
                                        this.callReadBillingProfileApi(values.customFormParams.accountBillingProfileItems, async (items) => {
                                            await setFieldValue('customFormParams.accountBillingProfileItems', items);
                                            await setFieldValue('customFormParams.accountBillingProfileIsLoading', false);
                                        });
                                    }}
                                    onSelect={async (value, item) => {
                                        let quickBookApp = (item && item.item && item.item.quickBookApp) ? item.item.quickBookApp : null;
                                        let isConnectQuickbook = (item && item.item && item.item.isConnectQuickbook) ? item.item.isConnectQuickbook : false;
                                        let isConnectXero = (item && item.item && item.item.isConnectXero) ? item.item.isConnectXero : false;
                                        let quickBookAppId = (quickBookApp && quickBookApp.quickBookAppId) ? quickBookApp.quickBookAppId : 0;

                                        await setFieldValue('accountBillingProfileId', value);
                                        await setFieldValue('accountBillingProfileName', item.title);
                                        await setFieldValue('customFormParams.quickBookAppId', quickBookAppId);
                                        await setFieldValue('customFormParams.isConnectQuickbook', isConnectQuickbook);
                                        await setFieldValue('customFormParams.isConnectXero', isConnectXero);
                                        
                                        if(isConnectXero){
                                            let xeroApp = (item && item.item && item.item.xeroApp) ? item.item.xeroApp : null;
                                            let xeroPaymentTermType = (xeroApp && xeroApp.xeroPaymentTermType) ? xeroApp.xeroPaymentTermType.toString() : '0';
                                            let xeroPaymentTerm = (xeroApp && xeroApp.xeroPaymentTerm) ? xeroApp.xeroPaymentTerm.toString() : '0';
                                            await setFieldValue('xeroPaymentTermType', xeroPaymentTermType);
                                            await setFieldValue('xeroPaymentTerm', xeroPaymentTerm);
                                        }
                                    }}
                                    onClear={async () => {
                                        await setFieldValue('accountBillingProfileId', null);
                                        await setFieldValue('accountBillingProfileName', '');
                                        await setFieldValue('xeroPaymentTermType', '0');
                                        await setFieldValue('xeroPaymentTerm', '0');
                                        await setFieldValue('customFormParams.quickBookAppId', 0);
                                        await setFieldValue('customFormParams.isConnectQuickbook', false);
                                        await setFieldValue('customFormParams.isConnectXero', false);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">{errors.accountBillingProfileId}</Form.Control.Feedback>
                            </InputLayout>
                        </Col>
                        {values.customFormParams.isConnectQuickbook && <Col xs={12}>
                            <InputLayout
                                label={this.props.fields.paymentTermsId.label}
                            >
                                <Form.Control
                                    as = {RSuiteSelectPicker}
                                    isInvalid={!!errors.paymentTermsId}

                                    id={this.props.fields.paymentTermsId.id}
                                    placeholder={this.props.fields.paymentTermsId.placeholder}
                                    items={values.customFormParams.paymentTermsItems}
                                    value={values.paymentTermsId}
                                    selectedName={values.paymentTermsName}
                                    isLoading={values.customFormParams.paymentTermsIsLoading}
                                    // readOnly={Utils.isRejectedOrCancelledOrFailedOrIsNotActive(currentStatusName, isActive)}
                                    readOnly={true}
                                    searchable={true}
                                    cleanable={true}
                                    isDefault={true}
                                    onClick={async () => {
                                        await setFieldValue('customFormParams.paymentTermsIsLoading', true);
                                        this.callQuickbookReadCreditTermsApi(values.customFormParams.quickBookAppId, values.customFormParams.paymentTermsItems, async (items) => {
                                            await setFieldValue('customFormParams.paymentTermsItems', items);
                                            await setFieldValue('customFormParams.paymentTermsIsLoading', false);
                                        });
                                    }}
                                    onSelect={async (value, item) => {
                                        await setFieldValue('paymentTermsId', value);
                                        await setFieldValue('paymentTermsName', item.title);
                                        await setFieldValue('xeroPaymentTerm', '');
                                    }}
                                    onClear={async () => {
                                        await setFieldValue('paymentTermsId', null);
                                        await setFieldValue('paymentTermsName', '');
                                        await setFieldValue('xeroPaymentTerm', '');
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">{errors.paymentTermsId}</Form.Control.Feedback>
                            </InputLayout>
                        </Col>}
                        {values.customFormParams.isConnectXero && <Col xs={12}>
                            <InputLayout
                                label={this.props.fields.xeroPaymentTerm.label}
                            >
                                <InputGroup>
                                    <Form.Control
                                        type="number"
                                        id={this.props.fields.xeroPaymentTerm.id}
                                        placeholder={this.props.fields.xeroPaymentTerm.placeholder}
                                        // readOnly={Utils.isRejectedOrCancelledOrFailedOrIsNotActive(currentStatusName, isActive)}
                                    readOnly={true}
                                        value={values.xeroPaymentTerm}
                                        onChange={async (e) => {
                                            await setFieldValue('xeroPaymentTerm', e.target.value);
                                            await setFieldValue('paymentTermsId', null);
                                            await setFieldValue('paymentTermsName', '');
                                        }}
                                        isInvalid={!!errors.xeroPaymentTerm}
                                    />
                                    <StyledDropdownButton
                                        title={Utils.getXeroPaymentTermTypeName(values.xeroPaymentTermType)}
                                        variant={'secondary'}
                                        menuAlign={'right'}
                                        disabled={true}
                                    >
                                        {XeroPaymentTermTypeItems.map((item, i) => {
                                            return <Dropdown.Item
                                                className={'py-2'}
                                                key={item.value + '_' + item.title + '_' + i}
                                                active={(item.value === values.xeroPaymentTermType)}
                                                onClick={async () => {
                                                    await setFieldValue('xeroPaymentTermType', item.value);
                                                }}
                                            >{item.title}</Dropdown.Item>
                                        })}
                                    </StyledDropdownButton>
                                    <Form.Control.Feedback type="invalid">{errors.xeroPaymentTerm}</Form.Control.Feedback>
                                </InputGroup>
                            </InputLayout>
                        </Col>}
                    
                        <Col xs={12}>
                            <InputLayout
                                label={this.props.fields.billedDate.label}
                            >
                                <Form.Control
                                    as = {RSuiteDatePicker}
                                    isInvalid={!!errors.billedDate}

                                    placeholder={this.props.fields.billedDate.placeholder}
                                    id={this.props.fields.billedDate.id}
                                    value={values.billedDate}
                                    icon={<></>}
                                    placement={'bottom'}
                                    cleanable={true}
                                    // readOnly={Utils.isRejectedOrCancelledOrFailedOrIsNotActive(currentStatusName, isActive)}
                                    readOnly={true}
                                    isDefault={true}
                                    onChange={async (value) => {
                                        await setFieldValue('billedDate', value);
                                    }}
                                    onClear={async () => {
                                        await setFieldValue('invoiceDate', null);
                                    }}
                                />
                            </InputLayout>
                        </Col>
                        
                        <Col xs={12}>
                            <InputLayout
                                label={this.props.fields.billingAddress.label}
                            >
                                <Form.Control
                                    as="textarea"
                                    rows={4}
                                    id={this.props.fields.billingAddress.id}
                                    placeholder={this.props.fields.billingAddress.placeholder}
                                    // readOnly={Utils.isRejectedOrCancelledOrFailedOrIsNotActive(currentStatusName, isActive)}
                                    readOnly={true}
                                    value={values.billingAddress}
                                    onChange={async (e) => {
                                        Utils.limitRowsBillingAddress(e.target.value, 4, async (value) => {
                                            await setFieldValue('billingAddress', value);
                                        });
                                    }}
                                    isInvalid={!!errors.billingAddress}
                                />
                                <Form.Control.Feedback type="invalid">{errors.billingAddress}</Form.Control.Feedback>
                            </InputLayout>
                        </Col>

                        <Col xs={12}>
                            <InputLayout
                                label={this.props.fields.billingNote.label}
                            >
                                <Form.Control
                                    as="textarea"
                                    rows={5}
                                    id={this.props.fields.billingNote.id}
                                    placeholder={this.props.fields.billingNote.placeholder}
                                    readOnly={Utils.isRejectedOrCancelledOrFailedOrIsNotActive(currentStatusName, isActive)}
                                    value={values.billingNote}
                                    onChange={async (e) => {
                                        Utils.limitRows(e.target.value, 5, async (value) => {
                                            await setFieldValue('billingNote', value);
                                        });
                                    }}
                                    isInvalid={!!errors.billingNote}
                                />
                                <Form.Control.Feedback type="invalid">{errors.billingNote}</Form.Control.Feedback>
                            </InputLayout>
                        </Col>
                    </>}

                </Row>
            </Col>

            {values.isBillable && <>
                <Col xs={12} lg={6}>
                    <Row>
                        <Col xs={12}>
                            <InputLayout
                                label={this.props.fields.invoiceDate.label}
                            >
                                <Form.Control
                                    as = {RSuiteDatePicker}
                                    isInvalid={!!errors.invoiceDate}
                                    
                                    placeholder={this.props.fields.invoiceDate.placeholder}
                                    id={this.props.fields.invoiceDate.id}
                                    value={values.invoiceDate}
                                    icon={<></>}
                                    placement={'bottom'}
                                    cleanable={true}
                                    readOnly={Utils.isRejectedOrCancelledOrFailedOrIsNotActive(currentStatusName, isActive)}
                                    isDefault={true}
                                    onChange={async (value) => {
                                        await setFieldValue('invoiceDate', value);
                                    }}
                                    onClear={async () => {
                                        await setFieldValue('invoiceDate', null);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">{errors.invoiceDate}</Form.Control.Feedback>
                            </InputLayout>
                        </Col>
                        <Col xs={12}>
                            <InputLayout
                                label={this.props.fields.invoiceNumber.label}
                                // label={<>
                                //     {'Invoice number'}
                                //     {values.isBillable && <IconButtonTooltip
                                //         title={'Update Invoice number'}
                                //         placement={'top'}
                                //         onClick={(e) => {
                                //             e.preventDefault();
                                //             e.stopPropagation();
    
                                //             this.callPromptInvoiceNumberApi(async (data, prefix, invoiceNumber) => {
                                //                 await setFieldValue('invoiceNumber', data);
    
                                //                 this.setState({
                                //                     isReadOnlyInvoiceNumber: false,
                                //                 });
                                //             });
                                //         }}
                                //     >
                                //         <i className={'material-icons'}>refresh</i>
                                //     </IconButtonTooltip>}
                                // </>}
                            >
                                <Form.Control
                                    type="text"
                                    id={this.props.fields.invoiceNumber.id}
                                    placeholder={this.props.fields.invoiceNumber.placeholder}
                                    // readOnly={Utils.isRejectedOrCancelledOrFailedOrIsNotActive(currentStatusName, isActive)}
                                    readOnly={true}
                                    value={values.invoiceNumber}
                                    onChange={handleChange}
                                    isInvalid={!!errors.invoiceNumber}
                                />
                                <Form.Control.Feedback type="invalid">{errors.invoiceNumber}</Form.Control.Feedback>
                            </InputLayout>
                        </Col>
                    </Row>
                </Col>

                <Col xs={12} className={'pt-10'}>
                    <Row className={'mb-5 d-none d-lg-block'}>
                        <Col xs={12} lg={11}>
                            <Row>
                                <Col xs={12} lg={3}><h5>{this.props.fields.serviceTag.label}</h5></Col>
                                <Col xs={6} lg={2}><h5>{this.props.fields.billingType.label}</h5></Col>
                                <Col xs={6} lg={4}><h5>{this.props.fields.chargeCategoryId.label}</h5></Col>
                                <Col xs={4} lg={1}><h5>{this.props.fields.quantity.label}</h5></Col>
                                <Col xs={4} lg={1}><h5>{this.props.fields.price.label}</h5></Col>
                                <Col xs={4} lg={1}><h5>TOTAL</h5></Col>
                            </Row>
                        </Col>
                        <Col xs={12} lg={1}></Col>
                        <Col xs={12}><hr /></Col>
                    </Row>

                    <FieldArray 
                        name="pricings"
                        validateOnChange={false}
                    >
                        {({ remove, push }) => (
                            <>
                                {
                                    (values.pricings && values.pricings.length > 0)
                                    ?
                                    values.pricings.map((item, i) => {
                                        return <Row key={i} className={'mb-5'}>
                                            <Col xs={12} lg={11}>
                                                <Row className={''}>
                                                    <Col xs={12} lg={3}>
                                                        <Form.Group className={'mb-5'}>
                                                            <Form.Label className={'d-block d-lg-none'}>{this.props.fields.serviceTag.label}</Form.Label>
                                                            {
                                                                (item.serviceType === ServiceType.AdditionalService)
                                                                ?
                                                                <SmartDropdown
                                                                    isInvalid={!!(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].serviceTag)}
                                                                    errorText={(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].serviceTag) && errors.pricings[i].serviceTag}

                                                                    componentTitle={'serviceItem'}
                                                                    componentApi={'service-item'}
                                                                    componentId={'serviceItemId'}
                                                                    componentName={'serviceTag'}
                                                                    componentData={this.getAdditionalServiceParams()}
                                                                    customName={'' + (i+1)}
                                                                    sortColumn={'serviceTag'}
                                                                    sortDir={'desc'}

                                                                    placeholder={this.props.fields.serviceTag.placeholder}
                                                                    value={item.serviceTag}
                                                                    label={item.serviceTag}

                                                                    disabled={false}
                                                                    showDefault={false}
                                                                    showSearch={true}
                                                                    showFooter={false}
                                                                    showAdd={false}
                                                                    showEdit={false}
                                                                    isInfiniteScroll={false}
                                                                    isFreeText={false}

                                                                    onChange={async (value, item, j) => {
                                                                        await setFieldValue('pricings.' + i + '.serviceItemId', value);
                                                                        await setFieldValue('pricings.' + i + '.serviceTag', item.title);

                                                                        let chargeCategory = (item && item.item && item.item.chargeCategory) ? item.item.chargeCategory : null;
                                                                        let chargeCategoryId = (chargeCategory && chargeCategory.chargeCategoryId) ? chargeCategory.chargeCategoryId : null;
                                                                        let chargeCategoryName = (chargeCategory && chargeCategory.chargeCategoryName) ? chargeCategory.chargeCategoryName : '';

                                                                        let chargeCategoryItems = _.cloneDeep(values.customFormParams.chargeCategoryItems);
                                                                        if (chargeCategory) {
                                                                            let chargeCategoryItem = {
                                                                                value: chargeCategoryId,
                                                                                title: chargeCategoryName,
                                                                                item: chargeCategory,
                                                                            };

                                                                            chargeCategoryItems = Utils.addToArray(chargeCategoryItems, chargeCategoryId, chargeCategoryItem);

                                                                            await setFieldValue('customFormParams.chargeCategoryItems', chargeCategoryItems);
                                                                            await setFieldValue('pricings.' + i + '.chargeCategoryId', chargeCategoryId);
                                                                            await setFieldValue('pricings.' + i + '.chargeCategoryName', chargeCategoryName);
                                                                        }


                                                                        let billingType = (item && item.item) ? item.item.billingType : null;
                                                                        if(billingType){
                                                                            await setFieldValue('pricings.' + i + '.billingType', billingType);
                                                                        }
                                                                        
                                                                        let price = (item && item.item) ? item.item.price : '0';
                                                                        await setFieldValue('pricings.' + i + '.price', price);
                                                                        await setFieldValue('pricings.' + i + '.quantity', 1);
                                                                        await setFieldValue('pricings.' + i + '.bundleServiceTag', '');
                                                                    }}
                                                                    onClear={async () => {
                                                                        await setFieldValue('pricings.' + i + '.serviceItemId', '');
                                                                        await setFieldValue('pricings.' + i + '.serviceTag', '');
                                                                        await setFieldValue('pricings.' + i + '.bundleServiceTag', '');
                                                                        await setFieldValue('pricings.' + i + '.billingType', null);
                                                                        await setFieldValue('pricings.' + i + '.price', '');
                                                                        await setFieldValue('pricings.' + i + '.quantity', '');
                                                                        await setFieldValue('pricings.' + i + '.chargeCategoryId', null);
                                                                        await setFieldValue('pricings.' + i + '.chargeCategoryName', '');
                                                                    }}
                                                                />

                                                                // <Form.Control
                                                                //     as = {RSuiteAutoComplete}
                                                                //     isInvalid={!!(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].serviceTag)}

                                                                //     id={this.props.fields.serviceTag.id + '_' + i}
                                                                //     placeholder={this.props.fields.serviceTag.placeholder}
                                                                //     items={values.customFormParams.serviceTagItems}
                                                                //     value={item.serviceTag}
                                                                //     isLoading={values.customFormParams.serviceTagIsLoading}
                                                                //     isDefault={true}
                                                                //     placement={'topStart'}
                                                                //     onClick={async () => {
                                                                //         await setFieldValue('customFormParams.serviceTagIsLoading', true);
                                                                //         this.callReadAdditionalServicesApi(values.customFormParams.serviceTagItems, '', async (items) => {
                                                                //             await setFieldValue('customFormParams.serviceTagItems', items);
                                                                //             await setFieldValue('customFormParams.serviceTagIsLoading', false);
                                                                //         });
                                                                //     }}
                                                                //     onSearch={async (value) => {
                                                                //         await setFieldValue('customFormParams.serviceTagIsLoading', true);
                                                                //         this.callReadAdditionalServicesApi(values.customFormParams.serviceTagItems, value, async (items) => {
                                                                //             await setFieldValue('customFormParams.serviceTagItems', items);
                                                                //             await setFieldValue('customFormParams.serviceTagIsLoading', false);
                                                                //         });
                                                                //     }}
                                                                //     onChange={async (value) => {
                                                                //         await setFieldValue('pricings.' + i + '.serviceTag', value);
                                                                //     }}
                                                                //     onSelect={async (value, item) => {
                                                                //         let chargeCategory = (item && item.item && item.item.chargeCategory) ? item.item.chargeCategory : null;
                                                                //         let chargeCategoryId = (chargeCategory && chargeCategory.chargeCategoryId) ? chargeCategory.chargeCategoryId : null;
                                                                //         let chargeCategoryName = (chargeCategory && chargeCategory.chargeCategoryName) ? chargeCategory.chargeCategoryName : '';
                                                                        
                                                                //         let chargeCategoryItems = values.customFormParams.chargeCategoryItems;
                                                                //         if(chargeCategory) {
                                                                //             let chargeCategoryItem = {
                                                                //                 value: chargeCategoryId,
                                                                //                 title: chargeCategoryName,
                                                                //                 item: chargeCategory,
                                                                //             };
                                                                            
                                                                //             chargeCategoryItems = Utils.addToArray(chargeCategoryItems, chargeCategoryId, chargeCategoryItem);
                                                                //         }

                                                                        
                                                                //         let billingType = (item && item.item) ? item.item.billingType : null;
                                                                //         let price = (item && item.item) ? item.item.price : '0';

                                                                        
                                                                //         await setFieldValue('pricings.' + i + '.serviceTag', value);
                                                                //         await setFieldValue('pricings.' + i + '.bundleServiceTag', '');
                                                                        
                                                                //         await setFieldValue('pricings.' + i + '.customFormParams.chargeCategoryItems', chargeCategoryItems);
                                                                //         await setFieldValue('pricings.' + i + '.chargeCategoryId', chargeCategoryId);
                                                                //         await setFieldValue('pricings.' + i + '.chargeCategoryName', chargeCategoryName);
                                                                        
                                                                //         await setFieldValue('pricings.' + i + '.billingType', billingType);
                                                                //         await setFieldValue('pricings.' + i + '.price', price);
                                                                //         await setFieldValue('pricings.' + i + '.quantity', 1);
                                                                //     }}
                                                                //     readOnly={Utils.isRejectedOrCancelledOrFailedOrIsNotActive(currentStatusName, isActive)}
                                                                // />
                                                                :
                                                                <Form.Control
                                                                    type="text"
                                                                    id={this.props.fields.serviceTag.id + '_' + i}
                                                                    placeholder={this.props.fields.serviceTag.placeholder}
                                                                    value={item.serviceTag}
                                                                    onChange={async (e) => {
                                                                        await setFieldValue('pricings.' + i + '.serviceTag', e.target.value);
                                                                        await setFieldValue('pricings.' + i + '.bundleServiceTag', e.target.value);
                                                                    }}
                                                                    isInvalid={!!(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].serviceTag)}
                                                                    readOnly={Utils.isRejectedOrCancelledOrFailedOrIsNotActive(currentStatusName, isActive)}
                                                                />
                                                            }
                                                            <Form.Control.Feedback type="invalid">{(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].serviceTag) && errors.pricings[i].serviceTag}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} sm={6} lg={2}>
                                                        <Form.Group className={'mb-5'}>
                                                            <Form.Label className={'d-block d-lg-none'}>{this.props.fields.billingType.label}</Form.Label>
                                                            <Form.Control
                                                                as = {RSuiteSelectPicker}
                                                                isInvalid={!!(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].billingType)}
                
                                                                id={this.props.fields.billingType.id + '_' + i}
                                                                placeholder={this.props.fields.billingType.placeholder}
                                                                items={[
                                                                    {
                                                                        value: BillingTypes.Invoice,
                                                                        title: 'Invoice',
                                                                    },
                                                                    {
                                                                        value: BillingTypes.Purchase,
                                                                        title: 'Purchase',
                                                                    },
                                                                    {
                                                                        value: BillingTypes.Expense,
                                                                        title: 'Expense',
                                                                    },
                                                                ]}
                                                                value={item.billingType}
                                                                isLoading={false}
                                                                searchable={true}
                                                                cleanable={true}
                                                                // readOnly={Utils.isRejectedOrCancelledOrFailedOrIsNotActive(currentStatusName, isActive)}
                                                                readOnly={true}
                                                                isDefault={true}
                                                                onClick={() => {
                                                                    
                                                                }}
                                                                onSelect={async (value, item) => {
                                                                    await setFieldValue('pricings.' + i + '.billingType', value);
                                                                }}
                                                                onClear={async () => {
                                                                    await setFieldValue('pricings.' + i + '.billingType', null);
                                                                }}
                                                            />
                                                            <Form.Control.Feedback type="invalid">{(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].billingType) && errors.pricings[i].billingType}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} sm={6} lg={4}>
                                                        <Form.Group className={'mb-5'}>
                                                            <Form.Label className={'d-block d-lg-none'}>{this.props.fields.chargeCategoryId.label}</Form.Label>
                                                            <Form.Control
                                                                as = {RSuiteSelectPicker}
                                                                isInvalid={!!(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].chargeCategoryId)}
                
                                                                id={this.props.fields.chargeCategoryId.id + '_' + i}
                                                                placeholder={this.props.fields.chargeCategoryId.placeholder}
                                                                items={values.customFormParams.chargeCategoryItems}
                                                                value={item.chargeCategoryId}
                                                                selectedName={item.chargeCategoryName}
                                                                isLoading={values.customFormParams.chargeCategoryIsLoading}
                                                                searchable={true}
                                                                cleanable={true}
                                                                isDefault={true}
                                                                onClick={async () => {
                                                                    await setFieldValue('customFormParams.chargeCategoryIsLoading', true);
                                                                    this.callReadChargeCategoryApi(values.customFormParams.chargeCategoryItems, async (items) => {
                                                                        await setFieldValue('customFormParams.chargeCategoryItems', items);
                                                                        await setFieldValue('customFormParams.chargeCategoryIsLoading', false);
                                                                    });
                                                                }}
                                                                onSelect={async (value, itemC) => {
                                                                    await setFieldValue('pricings.' + i + '.chargeCategoryId', value);
                                                                    await setFieldValue('pricings.' + i + '.chargeCategoryName', itemC.title);
                                                                    
                                                                    let invoiceTaxRate = (itemC && itemC.item) ? itemC.item.invoiceTaxRate : null;
                                                                    let purchaseTaxRate = (itemC && itemC.item) ? itemC.item.purchaseTaxRate : null;
                                                                    this.props.action.taxRate.setTaxRate({ index: value, invoiceTaxRate: invoiceTaxRate, purchaseTaxRate: purchaseTaxRate });
                                                                }}
                                                                onClear={async () => {
                                                                    await setFieldValue('pricings.' + i + '.chargeCategoryId', null);
                                                                    await setFieldValue('pricings.' + i + '.chargeCategoryName', '');

                                                                    this.props.action.taxRate.setTaxRate({ index: null, invoiceTaxRate: null, purchaseTaxRate: null });
                                                                }}
                                                                // readOnly={Utils.isRejectedOrCancelledOrFailedOrIsNotActive(currentStatusName, isActive)}
                                                                readOnly={true}
                                                            />
                                                            <Form.Control.Feedback type="invalid">{(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].chargeCategoryId) && errors.pricings[i].chargeCategoryId}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} sm={4} lg={1}>
                                                        <Form.Group className={'mb-5'}>
                                                            <Form.Label className={'d-block d-lg-none'}>{this.props.fields.quantity.label}</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                id={this.props.fields.quantity.id + '_' + i}
                                                                placeholder={this.props.fields.quantity.placeholder}
                                                                value={item.quantity}
                                                                onChange={async (e) => {
                                                                    await setFieldValue('pricings.' + i + '.quantity', e.target.value);
                                                                    if(this.props.onChangeQty){
                                                                        this.props.onChangeQty(e.target.value);
                                                                    }
                                                                }}
                                                                isInvalid={!!(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].quantity)}
                                                                readOnly={Utils.isRejectedOrCancelledOrFailedOrIsNotActive(currentStatusName, isActive) || Utils.isMainPricings(values.pricings, item)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">{(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].quantity) && errors.pricings[i].quantity}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} sm={4} lg={1}>
                                                        <Form.Group className={'mb-5'}>
                                                            <Form.Label className={'d-block d-lg-none'}>{this.props.fields.price.label}</Form.Label>
                                                            <Form.Control
                                                                as = {CurrencyInput}
                                                                isInvalid={!!(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].price)}
                                                                
                                                                allowDecimals={true}
                                                                allowNegativeValue={false}
                                                                disableGroupSeparators={true}
                                                                prefix={Utils.getCurrency()}
                                                                readOnly={Utils.isRejectedOrCancelledOrFailedOrIsNotActive(currentStatusName, isActive)}

                                                                id={this.props.fields.price.id + '_' + i}
                                                                placeholder={this.props.fields.price.placeholder}
                                                                value={item.price}
                                                                onValueChange={async (value) => {
                                                                    await setFieldValue('pricings.' + i + '.price', value);
                                                                }}
                                                            />
                                                            <Form.Control.Feedback type="invalid">{(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].price) && errors.pricings[i].price}</Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid" className={(item.isTaxInclusive && Utils.isNumber(item.price)) ? 'd-block' : ''}>{taxRate.getTaxRate(item.isTaxInclusive, item, this.props.taxRate)}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} sm={4} lg={1}>
                                                        <Form.Group className={'mb-2'}>
                                                            <Form.Label className={'d-block d-lg-none'}>TOTAL</Form.Label>
                                                            <Form.Label className={'mt-0 mt-sm-4'}>{Utils.getCurrency()}{Utils.formatNumber(item.quantity * item.price)}</Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={12} lg={1} className={'text-center text-lg-start'}>
                                                {((item.serviceType === ServiceType.AdditionalService) && !Utils.isRejectedOrCancelledOrFailedOrIsNotActive(currentStatusName, isActive)) && <IconButton
                                                    className={'ms-2'}
                                                    onClick={() => {
                                                        remove(i);
                                                    }}
                                                >
                                                    <i className={'material-icons text-danger'}>delete_forever</i>
                                                </IconButton>}
                                            </Col>
                                            {(i < (values.pricings.length-1)) && <Col xs={12} className={'d-block d-lg-none'}><hr /></Col>}
                                        </Row>
                                    })
                                    :
                                    <h3 className={'mb-5'}>No items!</h3>
                                }
           
                                {!Utils.isRejectedOrCancelledOrFailedOrIsNotActive(currentStatusName, isActive) && <a href={'/'}
                                    className={'link-default fw-bold'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        validateForm().then((err) => {
                                            if(!!err.pricings){
                                                Utils.toast('You must fill in the required fields', 'error');
                                            } else {
                                                push(this.props.initialValues.pricings[0]);
                                                // Utils.scrollToBottom(this.bodyRef);
                                            }
                                        })
                                    }}
                                >
                                    + Add Service Items
                                </a>}
                            </>
                        )}
                    </FieldArray>
                </Col>

                <Col xs={12}>
                    <Col xs={12}><hr /></Col>
                    <Row className={'mb-5'}>
                        <Col xs={12} lg={11}>
                            <Row>
                                <Col xs={true} lg={11} className={'text-end'}><h3>TOTAL</h3></Col>
                                <Col xs={true} lg={1} className={'position-lg-relative'}>
                                    <h3 className={'position-lg-absolute'}>
                                    {Utils.getCurrency()}
                                    {
                                        (values && values.pricings && values.pricings.length > 0)
                                        ?
                                        Utils.formatNumber(values.pricings.map(x => parseFloat(x.quantity ? x.quantity : 0) * parseFloat(x.price ? x.price : 0)).reduce((sum, x) => sum + x))
                                        :
                                        parseFloat(0).toFixed(2)
                                    }
                                    </h3>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} lg={1}></Col>
                    </Row>
                </Col>
            </>}
        </Row>
    }
    
    footer = () => {
        let {
            values,
            errors,
        } = this.props.formOptions;

        return <Row>
            <Col xs={'auto'}>
                <Button 
                    type={'submit'}
                    disabled={this.props.isLoading}
                >
                    OK
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
            <Col xs={'auto'}>
                <Button 
                    type={'button'}
                    variant={'secondary'}
                    disabled={this.props.isLoading}
                    onClick={() => {
                        Utils.showDrawer(this.props.drawer, false);
                    }}
                >
                    CANCEL
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
        </Row>
    }


    render() {
        return <DrawerContentLayout
            ref={this.bodyRef}
            isBlocking={this.props.isBlocking}
            body={this.body()}
            footer={this.footer()}
        />
    }
}

const mapStateToProps = state => {
  return {
    ...state,
    ...Utils.mapStateToProps(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  dispatchApiCallGet: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallGet(p1, p2, p3, p4, p5, p6)),
  dispatchApiCallPost: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallPost(p1, p2, p3, p4, p5, p6)),
  clear: () => dispatch(clear()),
  action: {
    taxRate: {
      setMainTaxRate: (payload) => dispatch(taxRate.setMainTaxRate(payload)),
      setTaxRate: (payload) => dispatch(taxRate.setTaxRate(payload)),
    },
  }
});


export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(JobFormBillingDrawer);
