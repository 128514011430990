import React from 'react';

import {
    Box,
} from '@material-ui/core';

import Select from 'react-dropdown-select';
import ManageLabel from '../input/ManageLabel.jsx';

const CustomDropdownSelect = React.forwardRef((props, ref) => {
    const [className] = React.useState(props.className ? props.className : '');
    const [options, setOptions] = React.useState(props.options ? props.options : []);
    const [values, setValues] = React.useState(props.values ? props.values : []);
    const [loading, setLoading] = React.useState(props.loading ? props.loading : false);
    const [placeholder] = React.useState(props.placeholder ? props.placeholder : '');
    const [label] = React.useState(props.label ? props.label : '');
    const [labelComponent] = React.useState(props.labelComponent ? props.labelComponent : null);
    const [clearable] = React.useState(props.clearable ? props.clearable : false);
    const [disabled, setDisabled] = React.useState(props.disabled ? props.disabled : false);
    const [required] = React.useState(props.required ? props.required : false);
    const [error, setError] = React.useState(props.error ? props.error : false);
    const [searchable] = React.useState(props.searchable ? props.searchable : false);

    const [labelField] = React.useState(props.labelField ? props.labelField : 'label');
    const [valueField] = React.useState(props.valueField ? props.valueField : 'value');


    React.useEffect(() => {
        setOptions(props.options ? props.options : []);
        setValues(props.values ? props.values : []);
        setLoading(props.loading ? props.loading : false);
        setDisabled(props.disabled ? props.disabled : false);
        setError(props.error ? props.error : false);
    }, [props.options, props.values, props.loading, props.disabled, props.error]);


    return (
        <Box className={"custom-dropdown-select-component " + className + (disabled ? ' disabled' : '')}>
            {label && <ManageLabel 
                className={"custom-label"}
                label={label}
                labelComponent={labelComponent}
                disabled={disabled}
                error={error}
                required={required}
            />}
            <Select
                ref={ref}
                placeholder={placeholder}
                addPlaceholder={placeholder}
                options={options}
                values={values}
                required={required}
                disabled={disabled}
                searchable={searchable}
                clearable={clearable}
                labelField={labelField}
                valueField={valueField}
                loading={loading}
                // portal={typeof document !== `undefined` && document.body}
                contentRenderer={(obj) => {
                    if(props.contentRenderer){
                        return props.contentRenderer(obj);
                    } else {
                        return null;
                    }
                }}
                dropdownRenderer={props.dropdownRenderer}
                noDataRenderer={(obj) => {
                    if(props.noDataRenderer){
                        return props.noDataRenderer(obj);
                    } else {
                        return null;
                    }
                }}
                dropdownHandleRenderer={(obj) => {
                    if(props.dropdownHandleRenderer){
                        return props.dropdownHandleRenderer(obj);
                    } else {
                        return null;
                    }
                }}
                clearRenderer={(obj) => {
                    if(props.clearRenderer){
                        return props.clearRenderer(obj);
                    } else {
                        return null;
                    }
                }}
                onChange={(values) => {
                    if(props.onChange){
                        props.onChange(values);
                    } else {
                        return null;
                    }
                }}
                onDropdownOpen={() => {
                    if(props.onOpen){
                        props.onOpen();
                    } else {
                        return null;
                    }
                }}
                onDropdownClose={() => {
                    if(props.onClose){
                        return props.onClose();
                    } else {
                        return null;
                    }
                }}
            />
        </Box>
    );
  });

  export default CustomDropdownSelect;