import React, { Component } from 'react';

import { 
  Box,
  Grid,
} from '@material-ui/core';

import { toast } from 'react-toastify';

import ManageLabel from '../../../../components/OLD/input/ManageLabel.jsx';
import StripeElement from '../../../../components/OLD/Stripe/StripeElement.jsx';


class StripePayDialog extends Component {
  
  constructor(props){
    super(props);

    let item = (props.item) ? props.item : null;

    this.state = {
      item: item,
    }
  }


  render() {
    return <Box className="create-driver-category-form">
        <Grid container>
            <Box clone pb={2}>
                <Grid item xs={12}>
                    <ManageLabel label={'Payment Card'} />
                </Grid>
            </Box>
            <Box clone pb={2}>
                <Grid item xs={12}>
                    <StripeElement
                        item={this.state.item}
                        onPay={(result, err) => {
                            if(err){
                                toast.error(<span>{err}</span>, {
                                    autoClose: 4000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                });
                            }

                            if(this.props.onPay){
                                this.props.onPay(result);
                            }
                        }}
                        onClose={() => {
                            if(this.props.onClose){
                                this.props.onClose();
                            }
                        }}
                    />
                </Grid>
            </Box>
        </Grid>
    </Box>;
  }
}

export default StripePayDialog;
