import React from 'react';

import { 
  Container,
} from "react-bootstrap-v5";

import { useDispatch } from "react-redux";
import cancelToken from "../../../../setup/redux/slices/cancelToken";


const ViewLayout = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  

  React.useEffect(() => {
    
    return () => {
      dispatch(cancelToken.cancel());
    }
  }, []);


  return <div className={'view-layout'}>
    <div className={'sections'}>
        {props.topSection && <div className={'custom-top-section '}
        style={{ 
          top: 65 + 'px',
          zIndex: '2', 
        }}
      >
        <Container className={'px-4 py-8'}>{props.topSection}</Container>
      </div>}

      <Container className={'px-5'}>
          {props.tableSection && <div className={'custom-table-section'}>
            <Container className={'p-0'}>{props.tableSection}</Container>
          </div>}
          
          {props.paginationSection && <div className={'custom-pagination-section bg-color'}>
            <Container className={'p-0'}>{props.paginationSection}</Container>
          </div>}
      </Container>
    </div>
  </div>
});

export default ViewLayout;