import React, { Component } from 'react';

import CustomCropperDialog from './CustomCropperDialog.jsx';


class CustomCropperDialogWrapper extends Component {
    constructor(props) {
        super(props);
        
		let dialogItems = (props.dialogItems) ? props.dialogItems : null;
	  
		this.state = {
            dialogItems: dialogItems,
            isOpenDialog: false,
            count: 0,
            newImgArray: [],
		}
    }


	componentWillReceiveProps(nextProps) {
        if (nextProps.dialogItems !== this.state.dialogItems) {
            let dialogItems = (nextProps.dialogItems) ? nextProps.dialogItems : null;
			this.setState({
				dialogItems: dialogItems,
                isOpenDialog: (dialogItems && dialogItems.length > 0) ? true : false,
                count: 0,
                newImgArray: [],
			});
		}
	}
    

	setCropperDialog = () => {
        if(this.state.isOpenDialog){
            return <CustomCropperDialog
                isOpenDialog={this.state.isOpenDialog}
                dialogItem={((this.state.dialogItems && this.state.dialogItems.length > 0) ? this.state.dialogItems[this.state.count] : null)}
                onCropFinished={(file) => {
                    let newImgArray = this.state.newImgArray;
                    if(file){
                        newImgArray.push(file);
                    }

                    this.setState({
                        isOpenDialog: false,
                        newImgArray: newImgArray,
                    }, () => {
                        if(this.state.count < (this.state.dialogItems.length-1)){
                            setTimeout(() => {
                                this.setState({
                                    isOpenDialog: true,
                                    count: (this.state.count+1),
                                });
                            }, 500);
                        } else {
                            this.setState({
                                isOpenDialog: false,
                                dialogItems: null,
                                count: 0,
                            }, () => {
                                if(this.props.onCropFinished){
                                    this.props.onCropFinished(this.state.newImgArray);
                                }
                            });
                        }
                    });
                }}
            />
        } else {
            return null;
        }
	}
	
	
    render() {
        return this.setCropperDialog()
    }
}

export default CustomCropperDialogWrapper;
