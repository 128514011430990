import React, { Component } from 'react';

import { 
    Row,
    Col,
    Modal,
    Button,
    CloseButton,
    Spinner,
} from "react-bootstrap-v5";

import HistoryDetails from "../Partial/HistoryDetails.jsx";

import Utils from '../../../utils/utils'

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallPost } from '../../../../setup/redux/dispatch/actions'


class AuditTrailDetailsPopup extends Component {
  
    constructor(props){
        super(props);

        this.pageName = 'job_form_audit_trail_details_popup';

        let item = (props.item) ? props.item : null;
        
        this.state = {
            isLoading: true,
            item: item,
            currentVersion: null,
            previousVersion: null,
        }
    }


    componentDidMount() {
        this.callJobCompareHistoryApi((currentVersion, previousVersion) => {
            this.setState({
                currentVersion: currentVersion,
                previousVersion: previousVersion,
            });
        });
    }

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            this.pageName + '-job_compare_history', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let currentVersion = data && data.currentVersion ? data.currentVersion : null;
                let previousVersion = data && data.previousVersion ? data.previousVersion : null;

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();
                    
                    if(this.state.isError){
                        Utils.toast(this.state.err, 'error');
                    }
                    
                    if(callback){
                        callback(currentVersion, previousVersion);
                    }
                });
            }
        );
    }

    callJobCompareHistoryApi = (callback = null) => {
        if(this.state.item){
            this.setState({
                isLoading: true,
                currentVersion: null,
                previousVersion: null,
            });
        
            let data = {
                jobId: this.state.item.jobId,
                jobHistoryId: this.state.item.jobHistoryId,
            };
    
            this.props.dispatchApiCallPost(data, this.pageName + '-job_compare_history', 'job/compare-history', null, callback, () => {});
        } else {
            if(callback){
                callback(null, null)
            }
        }
    }
    /* END API */


    header = () => {
        return <Modal.Header>
            <Row className={'align-items-center gx-0 w-100'}>
                <Col xs={true}>
                    <Modal.Title>Audit trail</Modal.Title>
                </Col>
                <Col xs={'auto'}>
                    <CloseButton
                        className={'rounded-2'}
                        onClick={() => {
                            if(this.props.onHide){
                                this.props.onHide();
                            }
                        }}
                    />
                </Col>
            </Row>
        </Modal.Header>
    }
    
    body = () => {
        return <Modal.Body>
            {this.details()}
        </Modal.Body>
    }

    footer = () => {
        return <Modal.Footer>
            <Row className={'w-100'}>
                <Col xs={'auto'}>
                    <Button 
                        type={'button'}
                        variant={'secondary'}
                        disabled={this.props.isLoading}
                        onClick={() => {
                            if(this.props.onHide){
                                this.props.onHide();
                            }
                        }}
                    >
                        CANCEL
                    </Button>
                </Col>
            </Row>
        </Modal.Footer>
    }


    details = () => {
        return <Row>
            <Col xs={12}>
                {
                    !this.state.isLoading
                    ?
                        (this.state.currentVersion)
                        ?
                        <HistoryDetails
                            index={0}
                            last={0}
                            previousVersion={this.state.previousVersion}
                            currentVersion={this.state.currentVersion}
                        />
                        :
                        <div style={{ fontWeight: "bold" }}>Job audit trails is not recorded for old jobs</div>
                    :
                    <div className={'text-center'}>
                        <Spinner animation="border" />
                    </div>
                }
            </Col>
        </Row>
    }


    render() {
        return <>
            {this.header()}
            {this.body()}
            {this.footer()}
        </>
    }
}

const mapDispatchToProps = {
  dispatchApiCallPost,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AuditTrailDetailsPopup);
