import React from 'react';

import {
    TableCell,
    TableRow,
    Box
} from '@material-ui/core';


const DataTableEmptyRow = (props) => {
    const { isBorder, isCheckbox, expandDetails, isDragable, colSpan } = props;
    
    return <TableRow
        className={"empty_row"}
        tabIndex={-1}
    >
        {isBorder && <TableCell className="table-cell-border"></TableCell>}

        {isCheckbox && 
        <TableCell 
            className="datatable-checkbox-style"
            width={'50px'} 
            padding="normal"
        />}

        {expandDetails && <TableCell width={'30px'} />}

        {isDragable && <TableCell className="responsive-hide" width={'30px'} />}

        <TableCell  
            id={"empty_row_id"} 
            scope="row" 
            align={'center'}
            padding={'normal'}
            colSpan={colSpan-1}
        >
            <Box fontWeight={"bold"}>No Result!</Box>
        </TableCell>

        {isBorder && <TableCell className="table-cell-border"></TableCell>}
    </TableRow>;
  }

  export default DataTableEmptyRow;