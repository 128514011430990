import React, { Component } from 'react';

import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'

import Utils from "../../../utils/utils";

import ManageLabel from '../input/ManageLabel.jsx';


class FreeTextTagsInput extends Component {
  
  constructor(props){
    super(props);

    let label = props.label ? props.label : "";
    let labelComponent = props.labelComponent ? props.labelComponent : null;
    let name = props.name ? props.name : "";
    let value = props.value ? props.value : [];
    let placeholder = props.placeholder ? props.placeholder : "";
    let className = props.className ? props.className : "";
    let helperText = props.helperText ? props.helperText : "";
    let errorText = props.errorText ? props.errorText : "";
    let validationRegex = props.validationRegex ? props.validationRegex : /.*/;
    let disabled = ((props.disabled === true || props.disabled === false) ? props.disabled : false);
    let readOnly = ((props.readOnly === true || props.readOnly === false) ? props.readOnly : false);
    let error = ((props.error === true || props.error === false) ? props.error : false);
    let required = ((props.required === true || props.required === false) ? props.required : false);

    this.tagInputRef = React.createRef();

    this.state = {
        label: label,
        labelComponent: labelComponent,
        className: className,

        name: name,
        value: value,
        placeholder: placeholder,

        disabled: disabled,
        readOnly: readOnly,
        required: required,

        error: error,
        errorText: errorText,
        helperText: helperText,
        validationRegex: validationRegex
    };
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.value) {
        this.setState({
            value: nextProps.value ? nextProps.value : []
        });
    }
    if (nextProps.placeholder !== this.state.placeholder) {
        this.setState({
            placeholder: nextProps.placeholder
        });
    }
    if (nextProps.disabled !== this.state.disabled) {
        this.setState({
            disabled: nextProps.disabled
        });
    }
    if (nextProps.readOnly !== this.state.readOnly) {
        this.setState({
            readOnly: nextProps.readOnly
        });
    }
    if (nextProps.required !== this.state.required) {
        this.setState({
            required: nextProps.required
        });
    }
    if (nextProps.error !== this.state.error) {
        this.setState({
            error: nextProps.error
        });
    }
    if (nextProps.helperText !== this.state.helperText) {
        this.setState({
            helperText: nextProps.helperText
        });
    }
    if (nextProps.errorText !== this.state.errorText) {
        this.setState({
            errorText: nextProps.errorText
        });
    }
  }
  

  handleChange = (value) => {
    this.setState({
        value: value
    }, () => {
        if(this.props.onChange){
            this.props.onChange(value);
        }
    })
  }

  handleOnPressEnter = (event) => {
    Utils.escapeChars(event);
  }


  render() {
    return <div className={"free-text-tags-imput-component " + this.state.className + " " + (this.state.error ? 'error' : '') + " " + ((this.state.value && this.state.value.length > 0) ? (this.props.smallPlaceholder && this.props.smallPlaceholder != '') ? 'smallPlaceholder' : 'bigPlaceholder' : 'bigPlaceholder')} >
        {this.state.label && <ManageLabel 
            className={"custom-label"}
            label={this.state.label}
            labelComponent={this.state.labelComponent}
            disabled={this.state.disabled}
            error={this.state.error}
            required={this.state.required}
        />}
        <TagsInput 
            ref={this.tagInputRef}
            value={this.state.value.filter(x => x != '')} 
            onChange={this.handleChange} 
            addKeys ={[9, 13, 188]} 
            onlyUnique
            addOnBlur
            disabled={this.state.disabled}
            validationRegex={this.state.validationRegex}
            onValidationReject={(arr) => {
                if(this.props.onValidationReject){
                    this.props.onValidationReject(arr);
                }
            }}
            inputProps={{
                id: (this.state.name !== '') ? this.state.name + '-id' : new Date().getTime() + '-id',
                name: this.state.name,
                disabled: this.state.disabled,
                readOnly: this.state.readOnly,
                placeholder: (this.state.value && this.state.value.length > 0) ? (this.props.smallPlaceholder && this.props.smallPlaceholder != '') ? this.props.smallPlaceholder : this.state.placeholder : this.state.placeholder,
                required: (this.state.required) ? (this.state.value && this.state.value.length === 0) ? true : false : false,
                onKeyPress: this.handleOnPressEnter,
                onKeyDown: (e) => { 
                    if (e.keyCode === 9 || e.keyCode === 13 || e.keyCode === 188) {
                        e.preventDefault();
                        e.stopPropagation();

                        if(this.tagInputRef && this.tagInputRef.current){
                            let val = (this.tagInputRef.current.input && this.tagInputRef.current.input.value && this.tagInputRef.current.input.value != '') ? this.tagInputRef.current.input.value : '';
                            this.tagInputRef.current.addTag(val);
                        }
                    }
                },
            }}
        />
        {(this.state.error && this.state.errorText) && <ManageLabel 
            className={"custom-label text-danger"}
            size={'sm'}
            label={this.state.errorText}
            disabled={this.state.disabled}
            error={this.state.error}
            required={this.state.required}
        />}
        {this.state.helperText && <ManageLabel 
            className={"custom-label " + (this.state.error ? 'text-danger' : '')}
            size={'sm'}
            label={this.state.helperText}
            disabled={this.state.disabled}
            error={this.state.error}
            required={this.state.required}
        />}
    </div>
  }
}

export default FreeTextTagsInput;
