import React, { Component } from "react";
import moment from 'moment';
import _ from 'lodash';

import { 
    Row,
    Col,
    // Form,
    // InputGroup,
    Collapse,
} from "react-bootstrap-v5";

import { 
  Box,
  TableRow,
  TableCell,
  // Checkbox,
} from '@material-ui/core';

import Icon from '@material-ui/core/Icon';
// import { ReactComponent as CheckboxIcon } from "../../../../_metronic/assets/img/icons/ionic-ios-checkbox-outline.svg";
// import { ReactComponent as CheckboxCheckedIcon } from "../../../../_metronic/assets/img/icons/ionic-ios-checkbox-outline-checked.svg";


import Utils from '../../../utils/utils'
import { BillingTypes } from '../../../utils/enums'

import DataTable from "../../../components/OLD/DataTable/DataTable";
import DataTableScrollSync from '../../../components/OLD/DataTable/DataTableScrollSync.jsx';
// import RSuiteSelectPicker from '../../../components/OLD/Select/RSuiteSelectPicker';
// import RSuiteDatePicker from '../../../components/OLD/Datepicker/RSuiteDatePicker';
import IconButton from "../../../components/Buttons/IconButton";
import CustomPopper from '../../../components/OLD/Popper/CustomPopper.jsx';

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet, dispatchApiCallPost } from '../../../../setup/redux/dispatch/actions'
import checkboxCount from "../../../../setup/redux/slices/checkboxCount";
import billingQuickbookXero from "../redux/billingQuickbookXero";

import { initColumnDetailsInvoice, initColumnVisibilityDetailsInvoice, initColumnDetailsPurchase, initColumnVisibilityDetailsPurchase, initColumnDetailsExpense, initColumnVisibilityDetailsExpense, childColumnNames }from '../functions/BillingByDateColumns';
// import { fieldsDetails as fields }from '../functions/Fields';

import taxRate from "../../../../setup/redux/slices/taxRate";


class GroupByCustomerDetails extends Component {

  constructor(props) {
    super(props);
    
    this.pageRef = React.createRef();
    this.tableRef = React.createRef();
    this.refDataTableScrollSync = React.createRef();
    this.tableRef.current = [];

    let rowData = (props && props.rowData) ? props.rowData : null;
    
    let statusValue = (rowData && rowData.statusValue) ? rowData.statusValue : null;
    let customer = (rowData && rowData.customer) ? rowData.customer : null;
    let billingProfileItem = (customer && customer.billingProfile) ? customer.billingProfile : null;

    let customerId = (rowData) ? rowData.customerId : 0;
    let billingAddress = (customer) ? customer.billingAddress : '';
    let accountBillingProfileId = (customer) ? customer.accountBillingProfileId : null;
    let accountBillingProfileName = (customer) ? customer.billingProfileName : '';
    let accountBillingProfileItem = {
      value: accountBillingProfileId,
      title: accountBillingProfileName,
      item: {
        accountBillingProfileId: accountBillingProfileId,
        billingProfileName: accountBillingProfileName,
      },
    }
    let accountBillingProfileItems = accountBillingProfileId ? [accountBillingProfileItem] : [];

    
    let isConnectQuickbook = (billingProfileItem && billingProfileItem.isConnectQuickbook) ? billingProfileItem.isConnectQuickbook : false;
    let isConnectXero = (billingProfileItem && billingProfileItem.isConnectXero) ? billingProfileItem.isConnectXero : false;
    
    let invoiceNumberPrefix = '';
    if(this.props.type === BillingTypes.Purchase){
      invoiceNumberPrefix = (billingProfileItem && billingProfileItem.purchaseOrderNumberPrefix) ? billingProfileItem.purchaseOrderNumberPrefix : '';
    } else if(this.props.type === BillingTypes.Expense){
      invoiceNumberPrefix = (billingProfileItem && billingProfileItem.expenseNumberPrefix) ? billingProfileItem.expenseNumberPrefix : '';
    } else {
      invoiceNumberPrefix = (billingProfileItem && billingProfileItem.invoiceNumberPrefix) ? billingProfileItem.invoiceNumberPrefix : '';
    }
    
    let paymentTermsId = (customer && customer.quickBookDefaultTermId) ? customer.quickBookDefaultTermId : null;
    let paymentTermsName = (customer && customer.paymentTerms) ? customer.paymentTerms : '';
    let paymentTermsItems = (isConnectQuickbook) ? [{
      value: paymentTermsId,
      title: paymentTermsName,
      item: customer,
    }] : [];

    let quickBookApp = (billingProfileItem && billingProfileItem.quickBookApp) ? billingProfileItem.quickBookApp : null;
    let quickBookAppId = (quickBookApp && quickBookApp.quickBookAppId) ? quickBookApp.quickBookAppId : 0;
    let xeroPaymentTerm = (customer && customer.xeroPaymentTerm) ? customer.xeroPaymentTerm : '';
    let xeroPaymentTermType = (customer && customer.xeroPaymentTermType) ? customer.xeroPaymentTermType.toString() : '0';



    let details = (rowData && rowData.details && rowData.details.length > 0) ? rowData.details.map((x, i) => (
      { 
        ...x, 
        isExpanded: true,
        // isExpanded: (statusValue) ? true : ((i === 0) ? true : false),
        isLoading: false,
        rows: [],
      }
    )) : [];

    let fromDate = (rowData && rowData.fromDate) ? rowData.fromDate : moment().startOf('week').format(Utils.getAPIDateFormat());
    let toDate = (rowData && rowData.toDate) ? rowData.toDate : moment().endOf('week').format(Utils.getAPIDateFormat());

    
    this.initColumns = Array.from((this.props.type === BillingTypes.Purchase) ? initColumnDetailsPurchase : (this.props.type === BillingTypes.Expense) ? initColumnDetailsExpense : initColumnDetailsInvoice);
    this.columns = Array.from(this.initColumns);

    this.initColumnVisibility = Array.from((this.props.type === BillingTypes.Purchase) ? initColumnVisibilityDetailsPurchase : (this.props.type === BillingTypes.Expense) ? initColumnVisibilityDetailsExpense : initColumnVisibilityDetailsInvoice);
    this.columnVisibility = Array.from(this.initColumnVisibility);


    this.state = {
      rowData: rowData,
      details: details,
      
      fromDate: fromDate,
      toDate: toDate,
      
      search: '',
      sortColumn: 'jobDate',
      sortDir: 'desc',

      disabledCheckboxes: [],
      selected: [],
      columnVisibility: this.columnVisibility,
      columns: this.columns,
      currentPage: 1,
      totalRows: 0,
      rowsPerPageSelected: Utils.getMaxPageSize(),
      rowsPerPageOptions: [-1],

      customerId: customerId,
      customerDetails: null,

      billingAddress: billingAddress,
      billingAddressValidation: false,

      accountBillingProfileId: accountBillingProfileId,
      accountBillingProfileName: accountBillingProfileName,
      accountBillingProfileValidation: false,
      accountBillingProfileItems: accountBillingProfileItems,
      accountBillingProfileIsLoading: false,

      quickBookAppId: quickBookAppId,
      isConnectQuickbook: isConnectQuickbook,
      isConnectXero: isConnectXero,

      invoiceDate: new Date(),
      invoiceDateValidation: false,

      invoiceNumberPrefix: invoiceNumberPrefix,
      invoiceNumber: '',
      invoiceNumberValidation: false,

      paymentTermsId: paymentTermsId,
      paymentTermsName: paymentTermsName,
      paymentTermsValidation: false,
      paymentTermsItems: paymentTermsItems,
      paymentTermsIsLoading: false,
      
      xeroPaymentTerm: xeroPaymentTerm,
      xeroPaymentTermType: xeroPaymentTermType,
    }
  }


  componentDidMount() {
    // this.callCustomerDetailsApi(this.state.customerId);
    // this.callPromptInvoiceNumberApi(this.state.accountBillingProfileId, this.state.invoiceDate);
  }
    
  componentWillReceiveProps(nextProps) {
    this.reduxProps(nextProps);
  }

	/* FUNCTIONS */
  reduxProps = nextProps => {
    Utils.reduxProps(nextProps,
      'group-by-customer_job-list', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        let rows = (data && data.data && data.data.length > 0) ? data.data : [];
        // let totalRows = (data && data.total) ? data.total : 0;
        
        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(callback){
            callback(rows);
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      'group-by-customer_row-billing-rofile', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        let arr = variables.items;

        if(data && data.data && data.data.length > 0) {
            for (var i = 0; i < data.data.length; i++) {
              let item = data.data[i];
              let value = item.accountBillingProfileId;
              let title = item.billingProfileName;
              let arrItem = {
                value: value,
                title: title,
                item: item,
              };

              arr = Utils.addToArray(arr, value, arrItem);
            }
        }

        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          this.props.clear();

          if(callback){
            callback(arr);
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      'group-by-customer_row-quickBook-credit-terms', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        let arr = this.state.paymentTermsItems;

        if(data && data.length > 0) {
          for (var i = 0; i < data.length; i++) {
            let item = data[i];
            let value = item.id;
            let title = item.name;
            let arrItem = {
              value: value,
              title: title,
              item: item,
            };

            arr = Utils.addToArray(arr, value, arrItem);
          }
        }

        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          this.props.clear();

          if(callback){
            callback(arr);
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      'group-by-customer_row-prompt-invoice-number', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        let arr = [];
        try {
          arr = (data && data != '') ? data.split('-') : [data];
        } catch(e){}
        
        // let prefix = (arr && arr.length > 0) ? arr[0] : '';
        let invoiceNumber = (arr && arr.length > 0) ? arr[arr.length-1] : '';
        
        this.setState({
          // invoiceNumberPrefix: prefix,
          invoiceNumber: invoiceNumber,

          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          this.props.clear();

          if(callback){
            callback(data);
          }
        });
      }
    );
    
    Utils.reduxProps(nextProps,
      'group-by-customer_row-prompt-po-number', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        let arr = [];
        try {
          arr = (data && data != '') ? data.split('-') : [data];
        } catch(e){}

        // let prefix = (arr && arr.length > 0) ? arr[0] : '';
        let invoiceNumber = (arr && arr.length > 0) ? arr[arr.length-1] : '';
        
        this.setState({
          // invoiceNumberPrefix: prefix,
          invoiceNumber: invoiceNumber,

          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          this.props.clear();

          if(callback){
            callback(data);
          }
        });
      }
    );
    
    Utils.reduxProps(nextProps,
      'group-by-customer_row-prompt-expense-number', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        let arr = [];
        try {
          arr = (data && data != '') ? data.split('-') : [data];
        } catch(e){}

        // let prefix = (arr && arr.length > 0) ? arr[0] : '';
        let invoiceNumber = (arr && arr.length > 0) ? arr[arr.length-1] : '';
        
        this.setState({
          // invoiceNumberPrefix: prefix,
          invoiceNumber: invoiceNumber,

          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          this.props.clear();

          if(callback){
            callback(data);
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      'group-by-customer_row-customer-details', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        let billingProfileItem = (data && data.billingProfile) ? data.billingProfile : null;

        let quickBookApp = (billingProfileItem && billingProfileItem.quickBookApp) ? billingProfileItem.quickBookApp : null;
        let quickBookAppId = (quickBookApp && quickBookApp.quickBookAppId) ? quickBookApp.quickBookAppId : 0;

        let isConnectQuickbook = (billingProfileItem && billingProfileItem.isConnectQuickbook) ? billingProfileItem.isConnectQuickbook : false;
        let isConnectXero = (billingProfileItem && billingProfileItem.isConnectXero) ? billingProfileItem.isConnectXero : false;
      
        let invoiceNumberPrefix = '';
        if(this.props.type === BillingTypes.Purchase){
          invoiceNumberPrefix = (billingProfileItem && billingProfileItem.purchaseOrderNumberPrefix) ? billingProfileItem.purchaseOrderNumberPrefix : '';
        } else if(this.props.type === BillingTypes.Expense){
          invoiceNumberPrefix = (billingProfileItem && billingProfileItem.expenseNumberPrefix) ? billingProfileItem.expenseNumberPrefix : '';
        } else {
          invoiceNumberPrefix = (billingProfileItem && billingProfileItem.invoiceNumberPrefix) ? billingProfileItem.invoiceNumberPrefix : '';
        }

        let paymentTermsId = (data && data.quickBookDefaultTermId) ? data.quickBookDefaultTermId : null;
        let paymentTermsName = (data && data.paymentTerms) ? data.paymentTerms : '';
        let paymentTermsItems = (isConnectQuickbook) ? [{
          value: paymentTermsId,
          title: paymentTermsName,
          item: data,
        }] : [];

        let xeroPaymentTerm = (data && data.xeroPaymentTerm) ? data.xeroPaymentTerm : '';
        let xeroPaymentTermType = (data && data.xeroPaymentTermType) ? data.xeroPaymentTermType.toString() : '0';


        this.setState({
          customerDetails: data,
          quickBookAppId: quickBookAppId,
          isConnectQuickbook: isConnectQuickbook,
          isConnectXero: isConnectXero,
          invoiceNumberPrefix: invoiceNumberPrefix,

          paymentTermsId: paymentTermsId,
          paymentTermsName: paymentTermsName,
          paymentTermsItems: paymentTermsItems,
          xeroPaymentTerm: xeroPaymentTerm,
          xeroPaymentTermType: xeroPaymentTermType,
          
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          this.props.clear();

          if(callback){
            callback(data);
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      'group-by-customer_row-job-details', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        

        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          this.props.clear();

          if(callback){
            callback(data);
          }
        });
      }
    );
  }

  getQBList = (row) => {
    return <div>
        <div className={'text-center'}>
        <CustomPopper
            className={'billed-history-btn'}
            classNamePopper={'billed-history'}
            icon={<i className={'material-icons'}>history</i>}
            items={this.props.billingQuickbookXero.quickbook.items
            }
            isLoading={this.props.billingQuickbookXero.quickbook.isLoading}
            onOpen={() => {
            if(this.pageRef && this.pageRef.current){
                this.pageRef.current.click();
            }

            this.callQuickbookInvoiceHistoryApi(row);
            }}
        />
        </div>
        <div>
        <a target={'_blank'} rel="noopener noreferrer"
            href={row.quickbookInvoiceUrl} 
            className={'link-primary d-flex align-items-center'}
            onClick={(e) => {
            e.stopPropagation();
            }}
        >
            {row.invoiceNumber}
            <span className={'material-icons ps-1'} style={{ fontSize: '18px' }}>open_in_new</span>
        </a>
        </div>
    </div>
  }
  getXeroList = (row) => {
    return <div>
        <div className={'text-center'}>
        <CustomPopper
            className={'billed-history-btn'} 
            classNamePopper={'billed-history'}
            icon={<i className={'material-icons'}>history</i>}
            items={this.props.billingQuickbookXero.xero.items}
            isLoading={this.props.billingQuickbookXero.xero.isLoading}
            onOpen={() => {
            if(this.pageRef && this.pageRef.current){
                this.pageRef.current.click();
            }

            this.callXeroInvoiceHistoryApi(row);
            }}
        />
        </div>
        <div>
        <a target={'_blank'} rel="noopener noreferrer"
            href={row.xeroInvoiceUrl} 
            className={'link-primary d-flex align-items-center'}
            onClick={(e) => {
            e.stopPropagation();
            }}
        >
            {row.invoiceNumber}
            <span className={'material-icons ps-1'} style={{ fontSize: '18px' }}>open_in_new</span>
        </a>
        </div>
    </div>
  }
  getQBPurchaseList = (row) => {
    return <div>
      <div className={'text-center'}>
        <CustomPopper
          className={'billed-history-btn'}
          classNamePopper={'billed-history'}
          icon={<i className={'material-icons'}>history</i>}
          items={this.props.billingQuickbookXero.QBPurchase.items}
          isLoading={this.props.billingQuickbookXero.QBPurchase.isLoading}
          onOpen={() => {
            if(this.pageRef && this.pageRef.current){
              this.pageRef.current.click();
            }

            this.callQuickbookPurchaseOrderHistoryApi(row);
          }}
        />
      </div>
      <div>
        <a target={'_blank'} rel="noopener noreferrer"
          href={row.quickbookPurchaseOrderUrl} 
          className={'link-primary d-flex align-items-center'}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {row.purchaseOrderNumber}
          <span className={'material-icons ps-1'} style={{ fontSize: '18px' }}>open_in_new</span>
        </a>
      </div>
    </div>
  }
  getXeroPurchaseList = (row) => {
    return <div>
        <div className={'text-center'}>
        <CustomPopper
            className={'billed-history-btn'} 
            classNamePopper={'billed-history'}
            icon={<i className={'material-icons'}>history</i>}
            items={this.props.billingQuickbookXero.xeroPurchase.items}
            isLoading={this.props.billingQuickbookXero.xeroPurchase.isLoading}
            onOpen={() => {
            if(this.pageRef && this.pageRef.current){
                this.pageRef.current.click();
            }

            this.callXeroPurchaseHistoryApi(row);
            }}
        />
        </div>
        <div>
        <a target={'_blank'} rel="noopener noreferrer"
            href={row.xeroPurchaseOrderUrl} 
            className={'link-primary d-flex align-items-center'}
            onClick={(e) => {
            e.stopPropagation();
            }}
        >
            {row.purchaseOrderNumber}
            <span className={'material-icons ps-1'} style={{ fontSize: '18px' }}>open_in_new</span>
        </a>
        </div>
    </div>
  }
  getQBExpenseList = (row) => {
    return <div>
      <div className={'text-center'}>
        <CustomPopper
          className={'billed-history-btn'}
          classNamePopper={'billed-history'}
          icon={<i className={'material-icons'}>history</i>}
          items={this.props.billingQuickbookXero.QBExpense.items}
          isLoading={this.props.billingQuickbookXero.QBExpense.isLoading}
          onOpen={() => {
            if(this.pageRef && this.pageRef.current){
              this.pageRef.current.click();
            }

            this.callQuickbookExpenseHistoryApi(row);
          }}
        />
      </div>
      <div>
        <a target={'_blank'} rel="noopener noreferrer"
          href={row.quickbookExpenseUrl} 
          className={'link-primary d-flex align-items-center'}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {row.expenseNumber}
          <span className={'material-icons ps-1'} style={{ fontSize: '18px' }}>open_in_new</span>
        </a>
      </div>
    </div>
  }
  getXeroExpenseList = (row) => {
    return <div>
        <div className={'text-center'}>
        <CustomPopper
            className={'billed-history-btn'} 
            classNamePopper={'billed-history'}
            icon={<i className={'material-icons'}>history</i>}
            items={this.props.billingQuickbookXero.xeroExpense.items}
            isLoading={this.props.billingQuickbookXero.xeroExpense.isLoading}
            onOpen={() => {
            if(this.pageRef && this.pageRef.current){
                this.pageRef.current.click();
            }

            this.callXeroExpenseHistoryApi(row);
            }}
        />
        </div>
        <div>
        <a target={'_blank'} rel="noopener noreferrer"
            href={row.xeroExpenseUrl} 
            className={'link-primary d-flex align-items-center'}
            onClick={(e) => {
            e.stopPropagation();
            }}
        >
            {row.expenseNumber}
            <span className={'material-icons ps-1'} style={{ fontSize: '18px' }}>open_in_new</span>
        </a>
        </div>
    </div>
  }

  onCustomerSiteRowClick = (item, i, isExpanded = false) => {
    let details = this.state.details;

    // if(this.tableRef && this.tableRef.current && this.tableRef.current.length > 0){
    //   for(let c = 0; c < this.tableRef.current.length; c++){
    //     this.tableRef.current[c].ref.setSelected();
    //   }
    // }

    // Close all opened before open clicked one
    // if(isExpanded){
    //   details = details.map(x => {
    //     x.isExpanded = false;
    //     return x;
    //   });
    // }

    item.rows = item.jobs;

    item.isExpanded = isExpanded;
    // item.isLoading = isExpanded ? true : false;
    details[i] = item;

    this.setState({
      details: details,
      // selected: [],
    }, () => {
      if(item.isExpanded){
        // this.callReadApi(item.customerSiteId, (arr) => {
        //   let details = this.state.details;
        //     item.isLoading = false;
        //     item.rows = arr;
        //     details[i] = item;
  
        //     let disabledCheckboxes = [];
        //     if(arr && arr.length > 0){
        //       arr.forEach(x => {
        //         if(!x.isBillable){
        //           disabledCheckboxes.push(x.jobId);
        //         } 
        //       });
        //     }

        //     this.setState({
        //       details: details,
        //       disabledCheckboxes: disabledCheckboxes,
        //     });
        // });
      }
    });
  }
  
  addToRefs = (el, i, item) => {
    if (el){
      if(this.props.onRefAdd){
        this.props.onRefAdd(item.customerSiteId, { id: item.customerSiteId, ref: el });
      }

      if (!this.tableRef.current.some(x => x.id === i)) {
        this.tableRef.current.push({ id: i, ref: el });
      } else {
        let index = this.tableRef.current.findIndex(x => x.id === i);
        if(index > -1){
          this.tableRef.current[index].ref = el;
        }
      }
    }
  }

  getSelectedRows = () => {
    let selected = [];

    if(this.tableRef && this.tableRef.current && this.tableRef.current.length > 0){
      for(let i = 0; i < this.tableRef.current.length; i++){
        if(this.tableRef.current[i] && this.tableRef.current[i].ref){
          let sel = this.tableRef.current[i].ref.getSelected();
          let rem = this.tableRef.current[i].ref.getDisabledCheckboxes()

          selected = _.union(selected, sel);
          selected = _.remove(selected, x => (rem.findIndex(y => y === x) === -1));
        }
      }
    }

    return selected;
  }

  setSelectedRows = (selected = []) => {
    if(this.tableRef && this.tableRef.current && this.tableRef.current.length > 0){
      for(let i = 0; i < this.tableRef.current.length; i++){
        if(this.tableRef.current[i] && this.tableRef.current[i].ref){
          this.tableRef.current[i].ref.setSelected(selected)
        }
      }
    }
  }
  
  onRealod = () => {
    if(this.tableRef && this.tableRef.current && this.tableRef.current.length > 0){
      for(let i = 0; i < this.tableRef.current.length; i++){
        if(this.tableRef.current[i] && this.tableRef.current[i].ref){
          this.tableRef.current[i].ref.onRealod();
        }
      }
    }
  }
  
  callPromptInvoiceNumber = () => {
    this.callPromptInvoiceNumberApi(this.state.accountBillingProfileId, this.state.invoiceDate);
  }

  getData = () => {
    let data = {
      invoiceDate: this.state.invoiceDate,
      accountBillingProfileId: this.state.accountBillingProfileId,
    };

    let arr = this.state.invoiceNumber ? this.state.invoiceNumber.split('-') : [];
    // let prefix = (arr && arr.length > 0) ? arr[0] : '';
    let invoiceNumber = (arr && arr.length > 0) ? arr[arr.length-1] : '';
    data['invoiceNumber'] = invoiceNumber ? invoiceNumber : null;

    if(this.state.isConnectQuickbook || this.state.isConnectXero){
      if(this.state.isConnectQuickbook){
        data['paymentTermsId'] = this.state.paymentTermsId;
      }

      if(this.state.isConnectXero){
        data['xeroPaymentTerm'] = this.state.xeroPaymentTerm;
        data['xeroPaymentTermType'] = this.state.xeroPaymentTermType;
      }
    }

    return data;
  }
	/* END FUNCTIONS */

	
	/* API */
  callReadApi = (customerSiteId = null, callback = null) => {
    if(customerSiteId){
      let fromDate = moment(this.state.fromDate).format(Utils.getAPIDateFormat());
      let toDate = moment(this.state.toDate).format(Utils.getAPIDateFormat());
  
      let data = {
        currentPage: this.state.currentPage,
        pageSize: this.state.rowsPerPageSelected,
        searchQuery: this.state.search,
        fromDate: fromDate,
        toDate: toDate,
        sortColumn: this.state.sortColumn,
        sortDir: this.state.sortDir,
        jobStatusFilter: this.props.jobStatusFilter,
        isBillable: this.props.isBillable,
        isInvoiced: this.props.isInvoiced,
        isPaid: this.props.isPaid,
        isPurchaseOrdered: this.props.isPurchaseOrdered,
        customerSiteId: customerSiteId,
      };
      
      if(this.props.type === BillingTypes.Purchase){
        data['billingType'] = 1;
        // data['showAll'] = true;
      } else if(this.props.type === BillingTypes.Expense){
        data['billingType'] = 2;
        // data['showAll'] = true;
      } else if (this.props.type === BillingTypes.Invoice){
        data['billingType'] = 0;
      }

      this.props.dispatchApiCallGet(data, 'group-by-customer_job-list', 'job/billing', null, callback, null);
    }
  }

  callReadBillingProfileApi = (items, callback = null) => {
    let data = {
      currentPage: 1,
      pageSize: Utils.getMaxPageSize(),
      searchQuery: '',
      isActive: true,
    };
 
    this.props.dispatchApiCallGet(data, 'group-by-customer_row-billing-rofile', 'AccountBillingProfile', { items: items }, callback, () => {});
  }

  callQuickbookReadCreditTermsApi = (quickBookAppId = 0, callback = null) => {
    let data = {
      quickBookAppId: quickBookAppId,
    };

    this.props.dispatchApiCallGet(data, 'group-by-customer_row-quickBook-credit-terms', 'quickbookapp/term', null, callback, () => {});
  }
  
  callPromptInvoiceNumberApi = (accountBillingProfileId = 0, invoiceDate, callback = null) => {
    if(this.props.type === BillingTypes.Purchase){
      let data = {
        accountBillingProfileId: accountBillingProfileId,
        purchaseOrderDate: invoiceDate ? invoiceDate : moment().format("YYYY-MM-DDTHH:mm:ss.000"),
      };
  
      this.props.dispatchApiCallPost(data, 'group-by-customer_row-prompt-po-number', 'job/billing/prompt-po-number', null, callback, () => {});
    } else if(this.props.type === BillingTypes.Expense){
      let data = {
        accountBillingProfileId: accountBillingProfileId,
        expenseDate: invoiceDate ? invoiceDate : moment().format("YYYY-MM-DDTHH:mm:ss.000"),
      };
  
      this.props.dispatchApiCallPost(data, 'group-by-customer_row-prompt-expense-number', 'job/billing/prompt-expense-number', null, callback, () => {});
    } else {
      let data = {
        accountBillingProfileId: accountBillingProfileId,
        invoiceDate: invoiceDate ? invoiceDate : moment().format("YYYY-MM-DDTHH:mm:ss.000"),
      };
  
      this.props.dispatchApiCallPost(data, 'group-by-customer_row-prompt-invoice-number', 'job/billing/prompt-invoice-number', null, callback, () => {});
    }
  }
  
  callCustomerDetailsApi = (customerId = 0, callback = null) => {
    this.props.dispatchApiCallGet(null, 'group-by-customer_row-customer-details', 'customer/' + customerId, null, callback, () => {});
  }

  callJobDetailsApi = (id = 0, callback= null) => {
    this.props.dispatchApiCallGet(null, 'group-by-customer_row-job-details', 'job/' + id, null, callback, () => {});
  }
  
  callQuickbookInvoiceHistoryApi = async (item) => {
    let data = {
      currentPage: 1,
      pageSize: Utils.getMaxPageSize(),
      searchQuery: '',
      sortDir: 'desc',
      cortColumn: 'invoiceDate',
      jobId: item.jobId,
    };
    
    await this.props.action.billingQuickbookXero.callQuickbookApi(data);
  }
  callXeroInvoiceHistoryApi = async (item) => {
    let data = {
      currentPage: 1,
      pageSize: Utils.getMaxPageSize(),
      searchQuery: '',
      sortDir: 'desc',
      cortColumn: 'invoiceDate',
      jobId: item.jobId,
    };
    
    await this.props.action.billingQuickbookXero.callXeroApi(data);
  }
  callQuickbookPurchaseOrderHistoryApi = async (item) => {
    let data = {
      currentPage: 1,
      pageSize: Utils.getMaxPageSize(),
      searchQuery: '',
      sortDir: 'desc',
      cortColumn: 'purchaseOrderDate',
      jobId: item.jobId,
    };
    
    await this.props.action.billingQuickbookXero.callQBPurchaseApi(data);
  }
  callXeroPurchaseHistoryApi = async (item) => {
    let data = {
      currentPage: 1,
      pageSize: Utils.getMaxPageSize(),
      searchQuery: '',
      sortDir: 'desc',
      cortColumn: 'purchaseOrderDate',
      jobId: item.jobId,
    };
    
    await this.props.action.billingQuickbookXero.callXeroPurchaseApi(data);
  }
  callQuickbookExpenseHistoryApi = async (item) => {
    let data = {
      currentPage: 1,
      pageSize: Utils.getMaxPageSize(),
      searchQuery: '',
      sortDir: 'desc',
      cortColumn: 'expenseDate',
      jobId: item.jobId,
    };
    
    await this.props.action.billingQuickbookXero.callQBExpenseApi(data);
  }
  callXeroExpenseHistoryApi = async (item) => {
    let data = {
      currentPage: 1,
      pageSize: Utils.getMaxPageSize(),
      searchQuery: '',
      sortDir: 'desc',
      cortColumn: 'expenseDate',
      jobId: item.jobId,
    };
    
    await this.props.action.billingQuickbookXero.callXeroExpenseApi(data);
  }
	/* END API */
	
	
	/* SECTIONS */
  setDataTable = (item, i) => {
    return <DataTable 
      ref={(r) => this.addToRefs(r, i, item)}

      className={"table-style-v5"}
      classNameWrapper={"table-v5"}

      caption={'Jobs Billing'}
      rowId={'jobId'}

      noMarginBottom={true}
      verticalAlign={'middle'}
      
      isHeader={true}
      stickyHeader={false}
      resizable={false}
      isDragableHeaderColumns={false}
      isDragable={false}
      isCheckbox={true}
      isBorder={false}
      isPagination={false}

      // disabledResizeColumns={[0]}
      columnVisibility={this.state.columnVisibility}
      // disabledCheckboxes={this.state.disabledCheckboxes}

      isLoading={item.isLoading}
      rowsPerPageSelected={this.state.rowsPerPageSelected}
      rowsPerPageOptions={this.state.rowsPerPageOptions}

      search={this.state.search}
      rows={item.rows}
      columns={this.state.columns}

      getBillingType={this.props.type}
      getQBList={this.getQBList}
      getXeroList={this.getXeroList}
      getQBPurchaseList={this.getQBPurchaseList}
      getXeroPurchaseList={this.getXeroPurchaseList}
      getQBExpenseList={this.getQBExpenseList}
      getXeroExpenseList={this.getXeroExpenseList}

      details={this.setCustomDetails}
      expandDetails={(id, row, props, isOpen, toggleDetails) => this.setExpandDetails(id, row, props, isOpen, toggleDetails, item, i)}
      
      onRowClick={(id, row, obj) => {
        if(this.props.onRowClick){
          let ref = ((this.tableRef && this.tableRef.current && this.tableRef.current.length > 0) ? this.tableRef.current.find(x => x.id === i) : null);
          this.props.onRowClick(id, row, ((ref && ref.ref) ? ref.ref: null));
        }
      }}

      onClickCheckAll={(obj, checked, isDisabledBatchAction, selectedCount) => {
        // if(this.props.onDisabledBatchAction){
        //   let selected = this.getSelectedRows();
        //   let ref = ((this.tableRef && this.tableRef.current && this.tableRef.current.length > 0) ? this.tableRef.current.find(x => x.id === i) : null);
        //   this.props.onDisabledBatchAction(isDisabledBatchAction, ((ref && ref.ref) ? ref.ref: null), null);

        //   this.setState({
        //     selected: checked ? selected : [],
        //   });
        // }
      }}
      onCheckAll={async (obj, selectedCount) => {
        await this.props.action.checkboxCount.onCheckAll({ allIds: obj.getAllIds(), checkedIds: obj.getSelected() });

        // if(this.props.onSelectedCount){
        //   let selected = this.getSelectedRows();
        //   this.props.onSelectedCount(selected.length);
        // }
      }}
      onCheckRow={(obj, id, isDisabledBatchAction, selectedCount) => {
        this.props.action.checkboxCount.onCheckRow(id);

        // if(this.props.onDisabledBatchAction){
        //   let ref = ((this.tableRef && this.tableRef.current && this.tableRef.current.length > 0) ? this.tableRef.current.find(x => x.id === i) : null);
        //   this.props.onDisabledBatchAction(isDisabledBatchAction, ((ref && ref.ref) ? ref.ref: null), selectedCount);

        //   let selected = this.getSelectedRows();
        //   this.setState({
        //     selected: selected,
        //   });
        // }
      }}

      onOrder={(name, sort) => {
        this.setState({
          sortColumn: name,
          sortDir: sort,
        }, () => {
          this.onCustomerSiteRowClick(item, i, item.isExpanded);
        });
      }}

      onInit={() => {
        if(item.isExpanded){
          item.isLoading = true;
          const myPromise = new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve(item.jobs);
            }, 200);
          });

          myPromise
            .then((arr) => {
              let details = this.state.details;
              item.isLoading = false;
              item.rows = _.cloneDeep(item.jobs);
              details[i] = item;

              let disabledCheckboxes = [];
                  if(arr && arr.length > 0){
                    arr.forEach(x => {
                      if(!x.isBillable){
                        disabledCheckboxes.push(x.jobId);
                      } 
                    });
                  }

              this.setState({
                details: details,
                disabledCheckboxes: disabledCheckboxes,
              });
            })
            .catch((error) => {
              item.isLoading = false;
            });


          // item.isLoading = true;
          // this.callReadApi(item.customerSiteId, (arr) => {
          //   let details = this.state.details;
          //     item.isLoading = false;
          //     item.rows = arr;
          //     details[i] = item;

          //     let disabledCheckboxes = [];
          //     if(arr && arr.length > 0){
          //       arr.forEach(x => {
          //         if(!x.isBillable){
          //           disabledCheckboxes.push(x.jobId);
          //         } 
          //       });
          //     }

          //     this.setState({
          //       details: details,
          //       disabledCheckboxes: disabledCheckboxes,
          //     });
          // });
        }
      }}

      onRealod={(callback = null) => {
        if(item.isExpanded){
          let details = this.state.details;
          item.rows = item.jobs;
          details[i] = item;
          this.setState({
            details: details,
          }, () => {
            if(callback){
              callback();
            }
          });
        //   item.isLoading = true;
        //   this.callReadApi(item.customerSiteId, (arr) => {
        //     let details = this.state.details;
        //       item.isLoading = false;
        //       item.rows = arr;
        //       details[i] = item;
    
        //       this.setState({
        //         details: details
        //       }, () => {
        //         if(callback){
        //           callback();
        //         }
        //       });
        //   });
        }
      }}
    />
  }
  setExpandDetails = (id, row, props, isOpen, toggleDetails, item, i) => {
    let ref = ((this.tableRef && this.tableRef.current && this.tableRef.current.length > 0) ? this.tableRef.current.find(x => x.id === i) : null);
    let tableRef = (ref && ref.ref) ? ref.ref : null;
    
    if(row.haveBilling)
    return <Box>
      <IconButton
        icon={tableRef.isRowExpanded(row.jobId) ? 'expand_less' : 'expand_more'}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          try {
            let isExpanded = tableRef.isRowExpanded(row.jobId);
            // tableRef.expandSingleRow(row.jobId);
            tableRef.expandRow(row.jobId);
  
            let rowIndex = -1;
            let rows = _.cloneDeep(item.rows);
            if(rows && rows.length > 0){
              rowIndex = rows.findIndex(x => x.jobId === row.jobId);
            }
  
            if(!isExpanded){
              // Utils.scrollTo('row_' + row.jobId, -165, 500);
  
              if(rowIndex > -1){
                rows[rowIndex].isLoading = true;
              }
  
              this.callJobDetailsApi(row.jobId, (data) => {
                if(rowIndex > -1){
                  rows[rowIndex].isLoading = false;
                  rows[rowIndex].details = data;
                  rows[rowIndex].fromDate = this.state.fromDate;
                  rows[rowIndex].toDate = this.state.toDate;
                  tableRef.refrsh();
                }
              });
            } else {
              if(rowIndex > -1){
                rows[rowIndex].isLoading = false;
                rows[rowIndex].details = null;
                rows[rowIndex].fromDate = this.state.fromDate;
                rows[rowIndex].toDate = this.state.toDate;
                tableRef.refrsh();
              }
            }

            item.rows = rows;
          }catch(e){}
        }}
      >
        <Icon className={'custom-expand-icon custom-expand-icon-' + id} component={'i'}>{tableRef.isRowExpanded(row.jobId) ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
      </IconButton>
    </Box>;
  }
  setCustomDetails = (row, props, isItemSelected, obj) => {
    if(isItemSelected){
      let isExpanded = isItemSelected.isRowExpanded(row.jobId);
      
      if(isExpanded){
        let pricings = (row && row.details && row.details.pricings && row.details.pricings.length > 0) ? row.details.pricings : [];
        
        let bt = (this.props.type === BillingTypes.Purchase) ? '1' : (this.props.type === BillingTypes.Expense) ? '2' : '0';
        
        return pricings.filter(x => {
          if(x.chargeCategory){
            return x.chargeCategory.billingType.toString() == bt
          } else {
            return x.billingType.toString() == bt
          }
        }).map((item, i) => {
          return <TableRow key={i} className={'details-row custom-details-row-' + row.jobId + (isExpanded ? ' exp-details-row ' : '') + (row.isLoading ? ' is-loading' : '')}>
            <TableCell>&nbsp;</TableCell>

            {childColumnNames.map((col, c) => {
              if(col === 'serviceTag'){
                return <TableCell key={col + '_' + i + '_' + c} colSpan={3}>{item.serviceTag}</TableCell>

              } else if(col === 'chargeCategory.chargeCategoryName'){
                return <TableCell key={col + '_' + i + '_' + c} colSpan={2}>{(item.chargeCategory) ? item.chargeCategory.chargeCategoryName : ''}</TableCell>

              } else if(col === 'chargeCategory.billingTypeDisplay'){
                return <TableCell key={col + '_' + i + '_' + c} colSpan={1}>{(item.chargeCategory) ? item.chargeCategory.billingTypeDisplay : ''}</TableCell>

              } else if(col === 'quantity'){
                return <TableCell key={col + '_' + i + '_' + c} colSpan={1}>{item.quantity}</TableCell>

              } else if(col === 'price'){
                return <TableCell key={col + '_' + i + '_' + c} colSpan={1}>{taxRate.getTaxRateColumn(item)}</TableCell>

              } else if(col === 'amount'){
                return <TableCell key={col + '_' + i + '_' + c} colSpan={1}>{Utils.getCurrency() + Utils.formatNumber(item.quantity * item.price)}</TableCell>
              }
            })}
          </TableRow>
        });
      }
    }
  }
	/* END SECTIONS */

	
  render() {
    return <div ref={this.pageRef}>
      <Row>
        <Col xs={12}>
          <div className={'p-0'}>
            <Row>
              <Col xs={12}>

                {
                  (this.state.details && this.state.details.length > 0)
                  ?
                  this.state.details.map((item, i) => {
                    // let ref = ((this.tableRef && this.tableRef.current && this.tableRef.current.length > 0) ? this.tableRef.current.find(x => x.id === i) : null);
                    // let tableRef = (ref && ref.ref) ? ref.ref : null;
                    
                    // let numSelected = (tableRef && tableRef.state && tableRef.state.selected && tableRef.state.selected.length > 0) ? tableRef.state.selected.length : 0;
                    // let rowCount = (tableRef && tableRef.state && tableRef.state.rows && tableRef.state.rows.length > 0) ? tableRef.state.rows.length : 0;
                    
                    return <Row key={i} className={'align-items-center' + ((i < (this.state.details.length - 1)) ? ' pb-7 ' : '')}>
                      {/* <Col xs={'auto'}>
                        <Checkbox
                          className={"ms-3"}
                          indeterminate={numSelected > 0 && numSelected < rowCount}
                          checked={rowCount > 0 && numSelected === rowCount}
                          disabled={false}
                          color={'default'}
                          onChange={(event, isChecked) => {
                            if(tableRef) {
                              tableRef.handleSelectAllClick(event);
                            }
                          }}
                          icon={<CheckboxIcon />}
                          checkedIcon={<CheckboxCheckedIcon />}
                          indeterminateIcon={<CheckboxCheckedIcon />}
                        />
                      </Col> */}
                      <Col xs={'auto'}>
                        <a href={'/'}
                          className={'text-secondary fs-5 fw-bolder'}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            this.onCustomerSiteRowClick(item, i, !item.isExpanded);
                          }}
                        >{item.siteName}</a>
                      </Col>
                      <Col xs={'auto'}>
                        <IconButton
                          icon={item.isExpanded ? 'expand_less' : 'expand_more'}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            
                            this.onCustomerSiteRowClick(item, i, !item.isExpanded);
                          }}
                        />
                      </Col>
                      <Col xs={12}>
                        <Collapse in={item.isExpanded}>
                          <div>
                            <DataTableScrollSync ref={this.refDataTableScrollSync} className={"table-sync-v4"}>{this.setDataTable(item, i)}</DataTableScrollSync>
                          </div>
                        </Collapse>
                      </Col>
                    </Row>
                  })
                  :
                  <h5>No jobs!</h5>
                }
                  
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  }
}


const mapStateToProps = state => {
  return {
    ...state,
    ...Utils.mapStateToProps(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchApiCallGet: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallGet(p1, p2, p3, p4, p5, p6)),
  dispatchApiCallPost: (p1, p2, p3, p4, p5, p6, p7) => dispatch(dispatchApiCallPost(p1, p2, p3, p4, p5, p6, p7)),
  clear: (payload) => dispatch(clear(payload)),
  action: {
    checkboxCount: {
      onCheckAll: (payload) => dispatch(checkboxCount.onCheckAll(payload)),
      onCheckRow: (payload) => dispatch(checkboxCount.onCheckRow(payload)),
    },
    billingQuickbookXero: {
      callQuickbookApi: (payload) => dispatch(billingQuickbookXero.callQuickbookApi(payload)),
      callXeroApi: (payload) => dispatch(billingQuickbookXero.callXeroApi(payload)),
      callQBPurchaseApi: (payload) => dispatch(billingQuickbookXero.callQBPurchaseApi(payload)),
      callXeroPurchaseApi: (payload) => dispatch(billingQuickbookXero.callXeroPurchaseApi(payload)),
      callQBExpenseApi: (payload) => dispatch(billingQuickbookXero.callQBExpenseApi(payload)),
      callXeroExpenseApi: (payload) => dispatch(billingQuickbookXero.callXeroExpenseApi(payload)),
    },
  }
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(GroupByCustomerDetails);
