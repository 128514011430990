import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';

import { 
    Modal,
    Row,
    Col,
    Button,
    Dropdown,
    Form,
    Spinner,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import Utils from '../../../../utils/utils';
import { ReportUOM } from '../../../../utils/enums';

import FileInputButton from '../../../../components/Buttons/fileInputButton/FileInputButton';

import { useDispatch, useSelector } from "react-redux";
import {RootState} from '../../../../../setup'
import importWeighChit, { HeaderColumnItem, Steps } from '../../redux/importWeighChit';
import fileInputButtonSlice from '../../../../components/Buttons/fileInputButton/FileInputButtonSlice';
import realTimeUpdate from '../../../../../setup/redux/slices/realTimeUpdate';

import { validationSchema, validation, getColumns, getColumnsFailed, getColumnsSuccess, getData } from './Functions';
import MyTable from './MyTable';


const StyledModalBody = styled(Modal.Body)`
    padding: 0px;
`;

const StyledButtonUOM = styled(Dropdown)`
    button {
        border-radius: 2px !important;
    }
`;

const StyledButtonFailed = styled(Button)`
    background-color: #F4F5F5 !important;
    color: #444444 !important;

    &.btn.btn-light.active {
        background-color: #fff0f8 !important;
        color: #FF0090 !important;
    }
`;

const StyledButtonSuccess = styled(Button)`
    background-color: #F4F5F5 !important;
    color: #444444 !important;

    &.btn.btn-light.active {
        background-color: #f0f8ff !important;
        color: #185CFF !important;
    }
`;


const ImportWeighChitPopup = (props: any) => {
    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state);
    const { dispatchUser } = useSelector((state: RootState) => state.auth);
    const { isLoading, show, step, percent, uom, excludeFirstRow, firstRow, data, failedJobs, successJobs } = useSelector((state: RootState) => state.importWeighChit);
    const weighChitCustomFields = useSelector((state: RootState) => state.weighChitCustomFields);
    
    const { isFile, file } = useSelector((state: RootState) => state.fileInputButtonSlice);

    const formikRef = React.useRef<FormikProps<any>>(null);
    const formikRefFailed = React.useRef<FormikProps<any>>(null);
    const formikRefSuccess = React.useRef<FormikProps<any>>(null);

    const tableRef = React.useRef<any>(null);

    
    const setHeaderDropdownCols = (uom: number|null) => {
        let myHeaderDropdownColumns: Array<HeaderColumnItem> = [
            {
                value: 1,
                name: 'jobNumber',
                label: 'DO number',
                title: <span>DO number <span className={'text-danger'}>*</span></span>,
                type: 'string',
                successType: 'text',
                successRender: (response: any) => {
                    if(response){
                        return <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '15px', height: '38px' }}>
                            <a
                                className={"link-primary d-flex align-items-center"}
                                href={'/jobs-form?id=' + response.jobId}
                                target={'_blank'}
                                rel="noreferrer"
                            >
                                {response.jobNumber}
                                <span className={'material-icons ps-2'}>open_in_new</span>
                            </a>
                        </div>
                    }
                },
                validation: Yup.string().typeError('This field is required').required().label('DO number'),
                required: true,
                item: null,
                itemField: null,
            },
            {
                value: 2,
                name: 'weightChitDateTime',
                label: 'Weigh bridge check in',
                title: 'Weigh bridge check in',
                type: 'date',
                successType: 'text',
                successRender: (response: any) => {
                    return <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '15px', height: '38px' }}>{moment(response.weightChitDateTime).format(Utils.getImportDateTimeFormat())}</div>;
                },
                validation: Yup.string().typeError('Invalid date').test('valid-date', 'Invalid date', (value: any) => {
                    let parsedValue: any = value;
                    try {
                        let testDate = moment(value, [moment.ISO_8601, Utils.getImportDateFormat(), Utils.getImportDateTimeFormat(), Utils.getImportDateTimeFormat('a'), 'DD/MM/YYYY HH:mm', 'DD/MM/YYYY HH:mm:ss', 'ddd MMM DD YYYY HH:mm:ss [GMT]Z']);
                        let isValidDate = testDate.isValid();
                        parsedValue = isValidDate ? testDate.toDate() : value;
                      } catch (error) {}
    
                    if (!parsedValue || !moment(parsedValue, [moment.ISO_8601, Utils.getImportDateFormat(), Utils.getImportDateTimeFormat(), Utils.getImportDateTimeFormat('a')]).isValid()) {
                      return false;
                    }
                    return true;
                }).nullable().required().label('Date/Time IN'),
                required: false,
                item: null,
                itemField: null,
            },
            {
                value: 3,
                name: 'weightChitOutDateTime',
                label: 'Weigh bridge check out',
                title: 'Weigh bridge check out',
                type: 'date',
                successType: 'text',
                successRender: (response: any) => {
                    return <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '15px', height: '38px' }}>{moment(response.weightChitOutDateTime).format(Utils.getImportDateTimeFormat())}</div>;
                },
                validation: Yup.string().typeError('Invalid date').test('valid-date', 'Invalid date', (value: any) => {
                    let parsedValue: any = value;
                    try {
                        let testDate = moment(value, [moment.ISO_8601, Utils.getImportDateFormat(), Utils.getImportDateTimeFormat(), Utils.getImportDateTimeFormat('a'), 'DD/MM/YYYY HH:mm', 'DD/MM/YYYY HH:mm:ss', 'ddd MMM DD YYYY HH:mm:ss [GMT]Z']);
                        let isValidDate = testDate.isValid();
                        parsedValue = isValidDate ? testDate.toDate() : value;
                      } catch (error) {}
    
                    if (!parsedValue || !moment(parsedValue, [moment.ISO_8601, Utils.getImportDateFormat(), Utils.getImportDateTimeFormat(), Utils.getImportDateTimeFormat('a')]).isValid()) {
                      return false;
                    }
                    return true;
                }).nullable().required().label('Date/Time OUT'),
                required: false,
                item: null,
                itemField: null,
            },
            {
                value: 4,
                name: 'weightChitTicketNumber',
                label: 'Ticket number',
                title: 'Ticket number',
                type: 'string',
                successType: 'text',
                successRender: null,
                validation: Yup.string().typeError('This field is required').nullable().required().label('Ticket number'),
                required: false,
                item: null,
                itemField: null,
            },
            {
                value: 5,
                name: 'binWeight',
                label: 'Gross weight',
                title: <span>Gross weight ({(uom === ReportUOM.tons) ? 'tons' : 'kg'}) <span className={'text-danger'}>*</span></span>,
                type: 'number',
                successType: 'text',
                successRender: null,
                validation: (uom: number| null, grossWeightField: any) => {
                    return Yup.number().when([`${grossWeightField}`], (grossWeight, schema) => {
                        if (uom === ReportUOM.kg ) {
                            return Yup.number()
                                .integer()
                                .typeError('Invalid gross weight')
                                .min(0)
                                .max(99999)
                                .test('noDecimal', 'Decimal values are not allowed', val => {
                                    if (val !== undefined && val !== null) {
                                        const strVal = val.toString();
                                        return strVal.indexOf('.') === -1;
                                    }
                                    return true;
                                })
                                .required()
                                .label('Gross weight');
    
                        } else {
                            return Yup.number()
                                .typeError('Invalid gross weight')
                                .transform((value, originalValue) => {
                                    if (originalValue === undefined || originalValue === null || originalValue === '') {
                                    return undefined;
                                    }
                                    return parseFloat(originalValue);
                                })
                                .test('maxDecimal', 'You can enter a maximum of 5 numbers with max 3 decimal places', val => {
                                    if (val === null || val === undefined) return true;
                                        const strVal = val.toString();
                                        const parts = strVal.split('.');
                                    if (parts.length === 1 || (parts.length === 2 && parts[1].length <= 3)) {
                                        return true;
                                    }
                                    return false;
                                })
                                .min(0)
                                .max(99.999)
                                .required()
                                .label('Gross weight');
                        }
                    })
                },
                required: true,
                item: null,
                itemField: null,
            },
            {
                value: 6,
                name: 'tareBinWeight',
                label: 'Tare weight',
                title: 'Tare weight (' + ((uom === ReportUOM.tons) ? 'tons' : 'kg') + ')',
                type: 'number',
                successType: 'text',
                successRender: null,
                validation: (uom: number| null, grossWeightField: any) => {
                    return Yup.number().when([`${grossWeightField}`], (grossWeight, schema) => {
                        if (uom === ReportUOM.kg ) {
                            return Yup.number()
                                .integer()
                                .typeError('Invalid tare weight')
                                .min(0)
                                .max((grossWeight && Utils.parseFloat(grossWeight) > 0) ? grossWeight : 99999)
                                .test('noDecimal', 'Decimal values are not allowed', val => {
                                    if (val !== undefined && val !== null) {
                                        const strVal = val.toString();
                                        return strVal.indexOf('.') === -1;
                                    }
                                    return true;
                                })
                                .required()
                                .label('Tare weight');
                                
                        } else {
                            return Yup.number()
                                .typeError('Invalid tare weight')
                                .transform((value, originalValue) => {
                                    if (originalValue === undefined || originalValue === null || originalValue === '') {
                                      return undefined;
                                    }
                                    return parseFloat(originalValue);
                                })
                                .test('maxDecimal', 'You can enter a maximum of 5 numbers with max 3 decimal places', val => {
                                    if (val === null || val === undefined) return true;
                                        const strVal = val.toString();
                                        const parts = strVal.split('.');
                                    if (parts.length === 1 || (parts.length === 2 && parts[1].length <= 3)) {
                                        return true;
                                    }
                                    return false;
                                })
                                .min(0)
                                .max((grossWeight && Utils.parseFloat(grossWeight) > 0) ? grossWeight : 99.999)
                                .required()
                                .label('Tare weight');
                        }
                    })
                },
                required: false,
                item: null,
                itemField: null,
            },
            // {
            //     value: 7,
            //     name: 'nonBillableBinWeight',
            //     label: 'Non-billable weight',
            //     title: 'Non-billable weight (' + ((uom === ReportUOM.tons) ? 'tons' : 'kg') + ')',
            //     type: 'number',
            //     successType: 'text',
            //     successRender: null,
            //     validation: (uom: number| null, grossWeight: number| null, tareBinWeight: number| null) => {
            //         let gw = (grossWeight && Utils.parseFloat(grossWeight) > 0) ? grossWeight : null;
            //         let tw = (tareBinWeight && Utils.parseFloat(tareBinWeight) > 0) ? tareBinWeight : null;
    
            //         let max: number| null = null;
            //         if(gw != null && tw != null){
            //             max = gw - tw;
            //         }
    
            //         return Yup.number().when([], (value, schema) => {
            //             if (uom === ReportUOM.kg ) {
            //                 return Yup.number()
            //                     .integer()
            //                     .max((max && Utils.parseFloat(max) > 0) ? max : 99999)
            //                     .test('noDecimal', 'Decimal values are not allowed', val => {
            //                         if (val !== undefined && val !== null) {
            //                             const strVal = val.toString();
            //                             return strVal.indexOf('.') === -1;
            //                         }
            //                         return true;
            //                     })
            //                     .required()
            //                     .label('Non-billable weight');
    
            //             } else {
            //                 return Yup.number()
            //                     .typeError('Invalid non-billable weight')
            //                     .transform((value, originalValue) => {
            //                         if (originalValue === undefined || originalValue === null || originalValue === '') {
            //                         return undefined;
            //                         }
            //                         return parseFloat(originalValue);
            //                     })
            //                     .test('maxDecimal', 'You can enter a maximum of 5 numbers with max 3 decimal places', val => {
            //                         if (val === null || val === undefined) return true;
            //                             const strVal = val.toString();
            //                             const parts = strVal.split('.');
            //                         if (parts.length === 1 || (parts.length === 2 && parts[1].length <= 3)) {
            //                             return true;
            //                         }
            //                         return false;
            //                     })
            //                     .max((max && Utils.parseFloat(max) > 0) ? max : 99.999)
            //                     .required()
            //                     .label('Non-billable weight');
            //             }
            //         })
            //     },
            //     required: false,
            //     item: null,
            //     itemField: null,
            // },
            {
                value: 8,
                name: 'binWeighBy',
                label: 'Weigh by',
                title: 'Weigh by',
                type: 'string',
                successType: 'text',
                successRender: null,
                validation: Yup.string().typeError('This field is required').nullable().required().label('Weigh by'),
                required: false,
                item: null,
                itemField: null,
            },
            {
                value: 9,
                name: 'weightChitRemarks',
                label: 'Weight remarks',
                title: 'Weight remarks',
                type: 'string',
                successType: 'text',
                successRender: null,
                validation: Yup.string().typeError('This field is required').nullable().required().label('Weight remarks'),
                required: false,
                item: null,
                itemField: null,
            },
        ]

        if(weighChitCustomFields && weighChitCustomFields.items && weighChitCustomFields.items.length > 0){
            for(let i = 0; i < weighChitCustomFields.items.length; i++){
                if(weighChitCustomFields.items[i].accountCustomFieldId && weighChitCustomFields.items[i].label && weighChitCustomFields.items[i].label.trim() != ''){
                    myHeaderDropdownColumns.push({
                        value: (11 + i),
                        name: weighChitCustomFields.items[i].accountCustomFieldId,
                        label: weighChitCustomFields.items[i].label.trim(),
                        title: weighChitCustomFields.items[i].label.trim(),
                        type: 'string',
                        successType: 'text',
                        successRender: null,
                        validation: Yup.string().nullable().label(weighChitCustomFields.items[i].label),
                        required: false,
                        item: weighChitCustomFields.items[i],
                        itemField: {
                            mainKey: 'jobStepWeighChitCustomFields',
                            dataKey: 'accountCustomFieldId',
                            labelKey: 'value',
                            dataValue: 'accountCustomFieldId',
                            labelValue: 'label',
                        },
                    });
                }
            }
        }

        return myHeaderDropdownColumns;
    }
    

    React.useEffect(() => {
        dispatch(importWeighChit.setHeaderDropdownColumns(setHeaderDropdownCols(uom)));
    }, [uom]);

    React.useEffect(() => {
        if((step === Steps.FailedTab) || (step === Steps.SuccessTab)){
            dispatch(importWeighChit.setUom(dispatchUser.defaultUoM));
            dispatch(importWeighChit.setHeaderDropdownColumns(setHeaderDropdownCols(dispatchUser.defaultUoM)));
        }
    }, [step]);


    const onRowDelete = (row: any, callback: () => void) => {
        let updatedData = formikRef.current?.values.filter((item: any) => item.id != row.values.id);
        let arr = updatedData.map((item: any, i: number) => ({...item, isLoading: false, isDelete: false }));
        arr = arr.filter((item: any) => (item.id !== undefined) && (item.id !== null));

        formikRef.current?.setValues(arr);
        dispatch(importWeighChit.setData(arr));
        if(!excludeFirstRow){
            let fRow = (arr && arr.length > 0) ? arr[0] : null;
            dispatch(importWeighChit.setFirstRow(fRow));
            dispatch(importWeighChit.setHeaderColumns(fRow));
        }
        callback();

        let state = arr.some((x: any) => x.id);
        if(!state){
            hide();
        }
    }
    const onCancelRowDelete = (callback: () => void) => {
        let arr = formikRef.current?.values.map((item: any, i: number) => ({...item, isLoading: false, isDelete: false }));
        formikRef.current?.setValues(arr);
        dispatch(importWeighChit.setData(arr));
        callback();
    }
    const onRowDeleteFailed = (row: any, callback: () => void) => {
        let updatedData = formikRefFailed.current?.values.filter((item: any) => item.id != row.values.id);
        let arr = updatedData.map((item: any, i: number) => ({...item, isLoading: false, isDelete: false }));
        arr = arr.filter((item: any) => (item.id !== undefined) && (item.id !== null));
        formikRefFailed.current?.setValues(arr);
        dispatch(importWeighChit.setFailedJobs(arr));
        callback();

        let state = arr.some((x: any) => (x.id !== undefined) && (x.id !== null));
        if(!state){
            let successState = successJobs.some((x: any) => (x.id !== undefined) && (x.id !== null));
            if(!successState){
                hide();
            } else {
                dispatch(importWeighChit.nextStep(Steps.SuccessTab));
            }
        }
    }
    const onCancelRowDeleteFailed = (callback: () => void) => {
        let arr = formikRefFailed.current?.values.map((item: any, i: number) => ({...item, isLoading: false, isDelete: false }));
        formikRefFailed.current?.setValues(arr);
        dispatch(importWeighChit.setFailedJobs(arr));
        callback();
    }
    const formValidation = (form: any, formik: any, dontShowToast: boolean, callback: (form: any) => void) => {
        validationSchema(state, form)
        .then((result: any) => {
            if (validation(state)) {
                dispatch(importWeighChit.setLoading(false));

                setTimeout(() => {
                    if(callback){
                        callback(form);
                    }
                }, 0);
            } else {
                dispatch(importWeighChit.setLoading(false));
                if(dontShowToast){
                    Utils.toast("You must select mandatory columns in the header table", "error");
                }
            }
        })
        .catch((validationErrors: any) => {
            let errorPath: any = null;
            const errors: { [key: string]: string } = {};
            validationErrors.inner.forEach((error: any, i: number) => {
                if(i === 0){
                    errorPath = error.path;
                }
                errors[error.path] = error.message;
            });
            formik.current?.setErrors(errors);

            try {
                if(errorPath && errorPath != ''){
                    const regex = /\[(\d+)\]/;
                    const match = errorPath.match(regex);
                    const numberInsideBrackets = match ? parseInt(match[1]) : null;

                    if (tableRef.current) {
                        tableRef.current.scrollToItem(numberInsideBrackets, 'smart');
                    }
                }
            } catch(e){}

            try {
                if(errorPath && errorPath != ''){
                    let fields = document.getElementsByName(errorPath);
                    if(fields && fields.length > 0){
                        if (fields[0].tagName === 'DIV') {
                            const inputField = fields[0].querySelector('input') as HTMLInputElement | null;
                            if (inputField) {
                                inputField.focus();
                            }
                        } else {
                            fields[0].focus();
                        }
                    }
                }
            } catch(e){}

            dispatch(importWeighChit.setLoading(false));
        });
    }

    const hide = () => {
        dispatch(fileInputButtonSlice.setDisabled(false));
        dispatch(fileInputButtonSlice.onChange(null));
        dispatch(realTimeUpdate.play());
        dispatch(importWeighChit.hide());
    }
    const onShow = () => {
        dispatch(realTimeUpdate.pause());
    }
    const onNext = async () => {
        dispatch(fileInputButtonSlice.setDisabled(true));
        await dispatch(importWeighChit.callReadExcelApi({ file: file, path: 'util/read-excel' }));

        await dispatch(importWeighChit.setUom(dispatchUser.defaultUoM));
        await dispatch(importWeighChit.setHeaderDropdownColumns(setHeaderDropdownCols(dispatchUser.defaultUoM)));

        formValidation(data, formikRef, false, (form) => {});
    }
    const onClickFailedTab = async () => {
        dispatch(importWeighChit.nextStep(Steps.FailedTab));
    }
    const onClickSuccessTab = async () => {
        dispatch(importWeighChit.nextStep(Steps.SuccessTab));
    }
    const onImport = () => {
        if (formikRef.current) {
            dispatch(importWeighChit.setLoading(true));
            setTimeout(() => {
                if (formikRef.current) {
                    formikRef.current.handleSubmit();
                }
            }, 0);
        }
    }
    const onSubmit = (form: any) => {
        formValidation(form, formikRef, true, async (form) => {
            let dataForm = getData(state, form);
            await dispatch(importWeighChit.callBatchWeighChitApi({ 
                form: dataForm,
                path: 'job/weight-chit/batch-weight-chit',
                callback: (failedJobs: any, successJobs: any, successJobIds: any) => {
                    formikRefFailed.current?.setValues(failedJobs);
                    formikRefSuccess.current?.setValues(successJobs);

                    // if(successJobIds && successJobIds.length > 0){
                    //     dispatch(importWeighChit.callUpdateSignalRApi(successJobIds));
                    // }
                }
            }));
        });
    }
    const onRetry = () => {
        if (formikRefFailed.current) {
            dispatch(importWeighChit.setLoading(true));
            setTimeout(() => {
                if (formikRefFailed.current) {
                    formikRefFailed.current.handleSubmit();
                }
            }, 0);
        }
    }
    const onSubmitFailed = (form: any) => {
        formValidation(form, formikRefFailed, true, async (form) => {
            let dataForm = getData(state, form);
            await dispatch(importWeighChit.callBatchWeighChitRetryApi({ 
                form: dataForm,
                path: 'job/weight-chit/batch-weight-chit',
                callback: (failedJobs: any, successJobs: any, successJobIds: any) => {
                    formikRefFailed.current?.setValues(failedJobs);
                    formikRefSuccess.current?.setValues(successJobs);

                    // if(successJobIds && successJobIds.length > 0){
                    //     dispatch(importWeighChit.callUpdateSignalRApi(successJobIds));
                    // }
                }
            }));
        });
    }


    return <Modal
        show={show}
        onHide={hide}
        onShow={onShow}
        backdrop={"static"}
        fullscreen={true}
        keyboard={false}
        enforceFocus={false}
    >
        <Modal.Header closeButton={true}>
            <h4>Import weigh chit</h4>
        </Modal.Header>

        {(step === Steps.SelectFile || step === Steps.ImportFile) && <Modal.Header>
            <Row className={'w-100 align-items-center'}>
                <Col xs={true}>
                    <FileInputButton />
                </Col>
                {(step === Steps.ImportFile) && <Col xs={12} sm={'auto'} className={'text-end'}>
                    <StyledButtonUOM>
                        <Dropdown.Toggle variant="primary">
                            UOM: <span className={'ps2 pe-2'}>{(uom === ReportUOM.kg) ? 'kg' : 'tons'}</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                active={(uom === ReportUOM.kg)}
                                onClick={async () => {
                                    await dispatch(importWeighChit.setUom(ReportUOM.kg));
                                }}
                            >kg</Dropdown.Item>
                            <Dropdown.Item
                                active={(uom !== ReportUOM.kg)}
                                onClick={async () => {
                                    await dispatch(importWeighChit.setUom(ReportUOM.tons));
                                }}
                            >tons</Dropdown.Item>
                        </Dropdown.Menu>
                    </StyledButtonUOM>
                    {(dispatchUser.defaultUoM != uom) && <div className='text-danger'>*Weight will be converted to {(dispatchUser.defaultUoM === ReportUOM.tons) ? 'tons' : 'kg'}</div>}
                </Col>}
            </Row>
        </Modal.Header>}

        {((step === Steps.FailedTab) || (step === Steps.SuccessTab)) && <Modal.Header>
            <Row className={'w-100 align-items-center'}>
                <Col xs={'auto'} className={'d-none d-md-block'}><div style={{ width: '30px' }}></div></Col>
                
                {failedJobs.some((x: any) => (x.id !== undefined) && (x.id !== null)) && <Col xs={'auto'}>
                    <StyledButtonFailed 
                        type={'button'}
                        variant={'light'}
                        active={(step === Steps.FailedTab)}
                        disabled={isLoading}
                        onClick={onClickFailedTab}
                    >
                        Failed items: {failedJobs.filter((x: any) => (x.id !== undefined) && (x.id !== null)).length}
                    </StyledButtonFailed>
                </Col>}

                {successJobs.some((x: any) => (x.id !== undefined) && (x.id !== null)) && <Col xs={'auto'}>
                    <StyledButtonSuccess
                        type={'button'}
                        variant={'light'}
                        active={(step === Steps.SuccessTab)}
                        disabled={isLoading}
                        onClick={onClickSuccessTab}
                    >
                        Successful items: {successJobs.filter((x: any) => (x.id !== undefined) && (x.id !== null)).length}
                    </StyledButtonSuccess>
                </Col>}
            </Row>
        </Modal.Header>}



        {(step === Steps.ImportFile) && <StyledModalBody>
            <Formik
                innerRef={formikRef}
                enableReinitialize={false}
                validateOnMount={false}
                validateOnChange={false}
                initialValues={data}
                onSubmit={onSubmit}
            >
                {(formOptions) => <MyTable ref={tableRef} form={formOptions} columns={getColumns(formOptions, false, state, dispatch, null)} onRowDelete={onRowDelete} onCancelRowDelete={onCancelRowDelete} isSearch={false} loading={isLoading} percent={percent} />}
            </Formik>
        </StyledModalBody>}

        {(step === Steps.FailedTab) && <StyledModalBody>
            <Formik
                innerRef={formikRefFailed}
                enableReinitialize={true}
                validateOnMount={true}
                validateOnChange={false}
                initialValues={failedJobs}
                onSubmit={onSubmitFailed}
            >
                {(formOptions) => <MyTable ref={tableRef} form={formOptions} columns={getColumnsFailed(formOptions, true, state, dispatch, null)} onRowDelete={onRowDeleteFailed} onCancelRowDelete={onCancelRowDeleteFailed} isSearch={true} loading={isLoading} percent={percent} />}
            </Formik>
        </StyledModalBody>}

        {(step === Steps.SuccessTab) && <StyledModalBody>
            <Formik
                innerRef={formikRefSuccess}
                enableReinitialize={false}
                validateOnMount={false}
                validateOnChange={false}
                initialValues={successJobs}
                onSubmit={() => {}}
            >
                {(formOptions) => <MyTable ref={tableRef} form={formOptions} columns={getColumnsSuccess(formOptions, true, state, dispatch, null)} isSearch={false} />}
            </Formik>
        </StyledModalBody>}



        <Modal.Footer style={{ justifyContent: 'flex-start' }}>
            <Row className={'m-0 align-items-center'}>
                {(step === Steps.SelectFile) && <Col xs={'auto'} className={'ps-0'}>
                    <Button 
                        type={'button'}
                        disabled={!isFile || isLoading || weighChitCustomFields.isLoading}
                        onClick={onNext}
                    >
                        Next
                        {(isLoading || weighChitCustomFields.isLoading) ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                    </Button>
                </Col>}

                {(step === Steps.ImportFile) && <Col xs={'auto'} className={'ps-0'}>
                    <Button 
                        type={'button'}
                        disabled={!isFile || isLoading}
                        onClick={onImport}
                    >
                        Import
                        {(isLoading) ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                    </Button>
                </Col>}

                {(step === Steps.FailedTab) && <Col xs={'auto'} className={'ps-0'}>
                    <Button 
                        type={'button'}
                        disabled={!isFile || isLoading}
                        variant={'danger'}
                        onClick={onRetry}
                    >
                        Retry
                        {(isLoading) ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                    </Button>
                </Col>}

                <Col xs={'auto'}>
                    <Button 
                        type={'button'}
                        variant={'secondary'}
                        disabled={isLoading}
                        onClick={hide}
                    >
                        {(step === Steps.SuccessTab) ? 'Close' : 'Cancel'}
                        {(isLoading) ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                    </Button>
                </Col>

                {(step === Steps.ImportFile) && <>
                    <Col xs={12} className={'d-block d-sm-none pb-5'} />
                    <Col xs={'auto'}>
                        Total: {data.length}
                    </Col>
                    <Col xs={'auto'}>
                        <Form.Group>
                            <Form.Check
                                id={'excludeFirstRowID'}
                                type={'checkbox'}
                                label={'Exclude 1st row'}

                                disabled={isLoading}
                                checked={excludeFirstRow}
                                onChange={(e: any) => {
                                    let arr = _.cloneDeep(formikRef.current?.values);
                                    dispatch(importWeighChit.setExcludeFirstRow(e.target.checked));

                                    if(e.target.checked){
                                        let fRow = (arr && arr.length > 0) ? arr[0] : null;
                                        dispatch(importWeighChit.setFirstRow(fRow));
                                        dispatch(importWeighChit.setHeaderColumns(fRow));
                                        arr.splice(0, 1);
                                    } else {
                                        dispatch(importWeighChit.setFirstRow(firstRow));
                                        dispatch(importWeighChit.setHeaderColumns(firstRow));
                                        arr.unshift(firstRow);
                                    }
                                    
                                    formikRef.current?.setValues(arr);
                                    dispatch(importWeighChit.setData(arr));
                                }}
                            />
                        </Form.Group>
                    </Col>
                </>}

            </Row>
        </Modal.Footer>
    </Modal>
}


export default ImportWeighChitPopup;
