import React, { useState, useEffect } from 'react';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';
import pluralize from 'pluralize';

import { 
  Row,
  Col,
  Form,
  Collapse,
  Dropdown,
  Spinner,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import Offcanvas from "react-bootstrap/Offcanvas";
import 'bootstrap/dist/css/bootstrap.min.css';

import DropdownToggle from '../../../components/dropdown/DropdownToggle';
import IconButtonSearch from '../../../components/input/IconButtonSearch';

import { useDispatch, useSelector } from "react-redux";
import binCenterDetailsPanel from '../redux/binCenterDetailsPanel';
import binCenter from '../redux/binCenter';
import binCenterMergePopup from '../redux/binCenterMergePopup';
import binCenterBinNumberPopup from '../redux/binCenterBinNumberPopup';

import reduxDeletePopupSlice from '../../../components/popup/reduxDeletePopupSlice';

import Utils from '../../../utils/utils';

import { ReactComponent as ArrowRightIcon } from "../../../../_metronic/assets/img/bin_center/arrow_right.svg";
import { ReactComponent as CalendarIcon } from "../../../../_metronic/assets/img/bin_center/calendar.svg";
import { ReactComponent as CloseIcon } from "../../../../_metronic/assets/img/bin_center/close.svg";
import { ReactComponent as CompanyIcon } from "../../../../_metronic/assets/img/bin_center/company.svg";
import { ReactComponent as DotsIcon } from "../../../../_metronic/assets/img/bin_center/dots.svg";
import { ReactComponent as JobNumberIcon } from "../../../../_metronic/assets/img/bin_center/job_number.svg";
import { ReactComponent as LocationIcon } from "../../../../_metronic/assets/img/bin_center/location.svg";
import { ReactComponent as OpenIcon } from "../../../../_metronic/assets/img/bin_center/open.svg";
import { ReactComponent as SiteIcon } from "../../../../_metronic/assets/img/bin_center/site.svg";


export const StyledRow = styled(Row)`
  font-family: 'Inter';
`;
const StyledTitle = styled.span`
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  color: #111111;
`;
const StyledDashedTitle = styled.span`
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  color: #111111;
  border-bottom: 1px dashed #111111;
`;
const StyledSubTitle = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  color: #444444;
`;
const StyledLabel = styled.span`
  font-size: 10px;
  font-weight: 400;
  line-height: 8px;
  color: #7C7C7C;
`;
const StyledValue = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 8px;
  color: #444444;
`;
const StyledText = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 8px;
  color: #444444;
`;
const StyledA = styled.a`
  font-size: 12px;
  font-weight: 400;
  line-height: 8px;
`;
const StyledDays = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: #444444;
`;
const StyledHrShadow = styled.hr`
  color: #D9E1E5;
  opacity: 1;
  -webkit-box-shadow: 0px 0px 4px 0px #D9E1E5;
  -moz-box-shadow: 0px 0px 4px 0px #D9E1E5;
  box-shadow: 0px 0px 4px 0px #D9E1E5;
`;
const StyledHr = styled.hr`
  color: #EFEFEF;
  opacity: 1;
`;
const StyledDropdownItem = styled(Dropdown.Item)`
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  color: #7C7C7C;
  padding-top: 5px;
  padding-bottom: 5px;
`;
const StyledDropdownItemDanger = styled(Dropdown.Item)`
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  color: #DC3545;
  padding-top: 5px;
  padding-bottom: 5px;
`;


const DetailsPanel = (props) => {
  const dispatch = useDispatch();
  const { isLoading, show, open, id, binTypeId, details, isLoadingHistory, detailsHistory, detailsHistoryHasMore } = useSelector((state) => state.binCenterDetailsPanel);
  
  const [ search, setSearch] = React.useState('');

  useEffect(() => {
    dispatch(binCenterDetailsPanel.clear());

    if(show){
      dispatch(binCenterDetailsPanel.open(false))
      dispatch(binCenterDetailsPanel.callDetailsApi(id, (state: boolean, data: any) => {}));
      
      dispatch(binCenterDetailsPanel.callDetailsHistoryFirstTimeApi({
        binNumberId: id,
        currentPage: 1,
        pageSize: 10,
        searchQuery: search,
        sortColumn: 'binOutDate',
        sortDir: 'desc',
      }, (state: boolean, data: any) => {}));
    }
  }, [show]);


  return <Offcanvas 
    placement={'end'}
    show={show}
    onHide={() => {
      dispatch(binCenterDetailsPanel.show({ show: false, id: null }));
    }}
    enforceFocus={false}
  >
    <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          {(id > 0) && <DropdownToggle
            className={'p-1 mt-1 d-flex align-items-center'}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              if(props.onAuditTrail){
                props.onAuditTrail(id)
              }
            }}
          >
            <i className={'material-icons'}>history</i>
          </DropdownToggle>}
        </Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body id={'scroll_panel_ID'} className={'p-0'} style={{ overflowX: 'hidden'}}>
        {
          id > 0
          ?
          <>
            <StyledRow className={'align-items-center g-0 py-2'}  style={{ position: 'sticky', top: '0px', backgroundColor: 'white' }}>
              <Col xs={12} className='px-5'>
                <Row className={'align-items-center'}>
                  <Col xs={'auto'}><StyledTitle>Bin ID:</StyledTitle></Col>
                  <Col xs={'auto'}>{isLoading ? <Spinner animation="border" size={'sm'} /> : <StyledDashedTitle>{details?.binNumberName}</StyledDashedTitle>}</Col>
                  <Col xs={'auto'} sm={2}>
                    <Dropdown alignRight>
                      <Dropdown.Toggle as={DropdownToggle} className={'py-3 d-flex align-items-center'}><DotsIcon /></Dropdown.Toggle>
                      <Dropdown.Menu>
                        {(details && details.isValidated === false) && <StyledDropdownItem
                          active={false}
                          onClick={() => {
                            dispatch(binCenter.callSaveApi({ binNumberId: id }, (state: boolean, data: any) => {
                              if(state){
                                if(props.onReload){
                                  props.onReload()
                                }
                              }
                            }));
                          }} 
                        >Save Bin</StyledDropdownItem>}

                        <StyledDropdownItem
                          active={false}
                          onClick={() => {
                            dispatch(binCenterBinNumberPopup.setShow({ id: id }));
                          }} 
                        >Edit Bin</StyledDropdownItem>

                        {(details && details.inSite === true && (detailsHistory && detailsHistory.length > 0)) && <StyledDropdownItem
                          active={false}
                          onClick={() => {
                            let customerSiteBinHistoryId = (detailsHistory && detailsHistory.length > 0 && detailsHistory[0] && detailsHistory[0].customerSiteBinHistoryId) ? detailsHistory[0].customerSiteBinHistoryId : null;
                            dispatch(binCenter.callMarkAsCollectedApi({ customerSiteBinHistoryId: customerSiteBinHistoryId }, (state: boolean, data: any) => {
                              if(state){
                                dispatch(binCenterDetailsPanel.callDetailsApi(id, (state: boolean, data: any) => {}));
                                dispatch(binCenterDetailsPanel.callDetailsHistoryFirstTimeApi({
                                  binNumberId: id,
                                  currentPage: 1,
                                  pageSize: 10,
                                  searchQuery: search,
                                  sortColumn: 'binOutDate',
                                  sortDir: 'desc',
                                }, (state: boolean, data: any) => {}));
                              }
                            }));
                          }} 
                        >Mark as collected</StyledDropdownItem>}
                        
                        <StyledDropdownItem
                          active={false}
                          onClick={() => {
                            dispatch(binCenterMergePopup.setShow({ item: { binTypeId: binTypeId, binNumberId: id} }));
                          }} 
                        >Merge Bin</StyledDropdownItem>

                        <StyledDropdownItem
                          active={false}
                          onClick={() => {
                            dispatch(binCenter.callRetrieApi({ binNumberId: id }, (state: boolean, data: any) => {
                              if(state){
                                if(props.onReload){
                                  props.onReload()
                                }
                              }
                            }));
                          }} 
                        >Retire Bin</StyledDropdownItem>

                        <StyledDropdownItemDanger
                          active={false}
                          onClick={() => {
                            dispatch(reduxDeletePopupSlice.setShow({ item: id }));
                          }} 
                      >Delete Bin</StyledDropdownItemDanger>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                  <Col xs={'auto'}>
                    <a href={'/'}
                      className='cursor-pointer'
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        dispatch(binCenterDetailsPanel.open(!open))
                      }}
                    >
                      {open ? <OpenIcon /> : <CloseIcon />}
                    </a>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} className='px-5 mt-3'>
                <Collapse in={open}>
                  <div>
                    <Row className='mt-2 mb-3'>
                      <Col xs={4} sm={3}>
                        <div><StyledLabel>Bin type</StyledLabel></div>
                        <div><StyledValue>{details?.binType?.binTypeName}</StyledValue></div>
                      </Col>
                      <Col xs={4} sm={3}>
                        <div><StyledLabel>Status</StyledLabel></div>
                        <div><StyledValue>{details?.inSite ? 'Active' : 'Inactive'}</StyledValue></div>
                      </Col>
                    </Row>
                    <Row className={'mb-2'}>
                      <Col xs={4} sm={3}>
                        <div><StyledLabel>Created by</StyledLabel></div>
                        <div><StyledValue>{details?.createdByName}</StyledValue></div>
                      </Col>
                      <Col xs={4} sm={3}>
                        <div><StyledLabel>Source</StyledLabel></div>
                        <div><StyledValue>{details?.source}</StyledValue></div>
                      </Col>
                      <Col xs={4} sm={3}>
                        <div><StyledLabel>Creation date</StyledLabel></div>
                        <div><StyledValue>{details ? moment(details.created).format('DD-MM-YY') : ''}</StyledValue></div>
                      </Col>
                    </Row>
                  </div>
                </Collapse>
              </Col>
              <Col xs={true} className='px-5 mt-4' style={{ minWidth: '110px' }}><StyledSubTitle>Bin Histories</StyledSubTitle></Col>
              <Col xs={'auto'} className='px-5 mt-4' style={{ maxWidth: '250px' }}>
                <IconButtonSearch 
                  value={search}
                  isOpen={(search !== '') ? true : false}
                  onPressEnter={async (value) => {
                    setSearch(value);
                    dispatch(binCenterDetailsPanel.callDetailsHistoryFirstTimeApi({
                      binNumberId: id,
                      currentPage: 1,
                      pageSize: 10,
                      searchQuery: value,
                      sortColumn: 'binOutDate',
                      sortDir: 'desc',
                    }, (state: boolean, data: any) => {}));
                  }}
                  onClear={async () => {
                    setSearch('');
                    dispatch(binCenterDetailsPanel.callDetailsHistoryFirstTimeApi({
                      binNumberId: id,
                      currentPage: 1,
                      pageSize: 10,
                      searchQuery: '',
                      sortColumn: 'binOutDate',
                      sortDir: 'desc',
                    }, (state: boolean, data: any) => {}));
                  }}
                />
              </Col>
              <Col xs={12}><StyledHrShadow className='mt-4 mb-0' /></Col>
            </StyledRow>
            <StyledRow className={'align-items-center px-5 py-2'}>
              
              <InfiniteScroll
                element={'div'}
                className={'col-12'}
                pageStart={0}
                loadMore={(page) => {
                  dispatch(binCenterDetailsPanel.callDetailsHistoryApi({
                    binNumberId: id,
                    currentPage: page,
                    pageSize: 10,
                    searchQuery: search,
                    sortColumn: 'binOutDate',
                    sortDir: 'desc',
                  }, (state: boolean, data: any) => {}));
                }}
                hasMore={detailsHistoryHasMore}
                initialLoad={false}
                useWindow={false}
                getScrollParent={() => document.getElementById('scroll_panel_ID')}
                loader={<div key={'loadingKey'} className={'col-12 text-center'}>Loading...</div>}
              >
                {
                (detailsHistory && detailsHistory.length > 0)
                ?
                detailsHistory.map((item, index) => {
                  let diffDays = (item.binInDate && item.binOutDate) ? moment(item.binInDate).diff(item.binOutDate, 'days') : 0;
                  let diffDaysText = item.binInDate ? diffDays + ' ' + pluralize('Day', diffDays) : item.daysOut + ' ' + pluralize('Day', item.daysOut);

                  return <React.Fragment key={index}>
                    <Row className='align-items-center mt-2 mb-3 gy-5'>
                      <Col xs={'auto'}><StyledValue>{item.binOutDate ? moment(item.binOutDate).format('DD-MM-YY') : '-'}</StyledValue></Col>
                      <Col xs={'auto'}><ArrowRightIcon /></Col>
                      <Col xs={'auto'}><StyledValue>{item.binInDate ? moment(item.binInDate).format('DD-MM-YY') : '-'}</StyledValue></Col>
                      <Col xs={true} className={'text-end'}><CalendarIcon /><StyledDays className={'ms-3'}>{diffDaysText}</StyledDays></Col>
                      <Col xs={12}><CompanyIcon /><StyledText className={'ms-4'}>{item.customerName}</StyledText></Col>
                      <Col xs={12}><SiteIcon /><StyledText className={'ms-4'}>{item.siteName}</StyledText></Col>
                      <Col xs={12}>
                        <JobNumberIcon />
                        <StyledA href={'#'}
                          href={'/jobs-form?id=' + item.jobId}
                          className={'link-primary-underline ms-4'}
                          target={'_blank'}
                          rel="noreferrer"
                        >
                          {item.jobNumber}
                        </StyledA>
                      </Col>
                      {/* <Col xs={12}><StyledHr className='mt-4 mb-0' /></Col>
                      <Col xs={'auto'}><LocationIcon /><StyledText className={'ms-4'}>58 Tuas Ave 6</StyledText></Col>
                      <Col xs={true} className={'text-end'}><CalendarIcon /><StyledDays className={'ms-3'}>12 Days</StyledDays></Col> */}
                      <Col xs={12}><StyledHr className='mt-0 mb-0' /></Col>
                    </Row>
                  </React.Fragment>
                })
                :
                <Row className='align-items-center mt-2 mb-3 gy-5 text-center'>
                  {!isLoadingHistory && <Col xs={12}>No bin history</Col>}
                </Row>
                }
              </InfiniteScroll>

            </StyledRow>
          </>
          :
          <StyledRow className={'align-items-center g-0 py-2'}  style={{ position: 'sticky', top: '0px', backgroundColor: 'white' }}>
            <Col xs={12} className='px-5 text-center'>There is no bin ID</Col>
          </StyledRow>
        }
    </Offcanvas.Body>
  </Offcanvas>
}

export default DetailsPanel;