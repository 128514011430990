import React from "react";

import {
    Row,
    Col,
    Button,
    Dropdown,
} from "react-bootstrap-v5";
// import styled from 'styled-components';

import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { ReactComponent as LightbulbIcon } from '../../../_metronic/assets/img/icons/material-lightbulb-outline.svg';

import Utils from "../../utils/utils";
import { PageCookieSettingType } from "../../utils/enums";

import WrappedButton from '../../components/Buttons/WrappedButton';
import DropdownToggle from '../../components/dropdown/DropdownToggle';
import SearchDialog from '../../components/dialog/SearchDialog';
import Popup from '../../components/popup/Popup';
import LoadingButton from '../../components/Buttons/LoadingButton';
import makeDropdownList from "../../components/dropdown/makeDropdownList";
import DataTableActionMenu from '../../components/OLD/DataTable/DataTableActionMenu';
import DataTable from "../../components/OLD/DataTable/DataTable";
import DataTableAjaxPagination from '../../components/OLD/DataTable/Pagination/DataTableAjaxPaginationV5';
import DataTableScrollSync from '../../components/OLD/DataTable/DataTableScrollSync.jsx';
import IconButtonSearch from '../../components/input/IconButtonSearch';
import RemoveDialog from '../../components/dialog/RemoveDialog';

import ViewLayout from "./layout/ViewLayout.jsx";
import ViewTopSection from "./partial/ViewTopSection";

import BillingCategoryForm from "./partial/BillingCategoryForm";
import BulkUpdateBillingCategoryForm from "./partial/BulkUpdateBillingCategoryForm";

import { readApi } from '../../api/CRUD/DispatchCRUD'

import { connect } from 'react-redux'
import { clear } from "../../../setup/redux/actions";
import { actions } from "../../../setup/redux/auth/saga";
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut, dispatchApiCallDelete } from '../../../setup/redux/dispatch/actions'


const { saveUserCookieSettings } = actions;
const DropdownMenu = makeDropdownList(Button);


class BillingCategoryList extends React.Component {

    constructor(props) {
        super(props);

        props.layoutRef.current = this;

        this.pageName = 'billing_category';

        this.pageIdField = "chargeCategoryId";
        this.pageAPI = "ChargeCategory";
        this.pageCaption = 'Billing Category'

        this.refForm = React.createRef();
        this.refBillingCategoryForm = React.createRef();
        this.refBulkUpdate = React.createRef();
        this.tableRef = React.createRef();
        this.refDataTableScrollSync = React.createRef();

        let id = Utils.getIntProps(props);

        this.isUseBinWeightQuantityTemplate = (id, row, col, obj) => {
            return row.useBinWeightQuantity === true ? "Yes" : "No";
        };

        let columns = [
            {
                id: this.pageIdField,
                filterKey: this.pageIdField,
                name: this.pageIdField,
                label: 'ID',
                visible: false
            },
            {
                id: "chargeCategoryName",
                name: "chargeCategoryName",
                label: "NAME",
                responsiveLabel: "NAME",
                orderable: true,
            },
            {
                id: "billingTypeDisplay",
                name: "billingType",
                label: "BILLING ACTION",
                responsiveLabel: "BILLING ACTION",
                orderable: true,
            },
            {
                id: "useBinWeightQuantity",
                name: "useBinWeightQuantity",
                label: "LINK BILLABLE WEIGHT TO SERVICE ITEM QTY",
                responsiveLabel: "LINK BILLABLE WEIGHT TO SERVICE ITEM QTY",
                orderable: true,
                template: this.isUseBinWeightQuantityTemplate
            }
        ];
        let initColumns = Array.from(columns);
        columns = Array.from(initColumns);


        let columnVisibility = [
            false,
            true,
            true,
            true,
        ];
        let initColumnVisibility = Array.from(columnVisibility);
        columnVisibility = Array.from(initColumnVisibility);


        this.state = {
            id: id,
            details: null,
            mainLoading: false,

            popupShowBillingCategoryForm: false,
            popupLoadingBillingCategoryForm: false,
            popupIdBillingCategoryForm: 0,
            popupIsCopyBillingCategoryForm: false,

            leftItems: [],
            leftSearch: '',
            leftSortColumn: 'updated',
            leftSortDir: 'asc',
            leftIsIncludeInactive: null,
            leftIsInactive: false,
            leftIsSearchDialog: false,

            search: '',
            sortColumn: 'chargeCategoryName',
            sortDir: 'asc',
            isIncludeInactive: null,
            isInactive: false,

            selected: [],
            columnVisibility: columnVisibility,
            initColumnVisibility: initColumnVisibility,
            columns: columns,
            initColumns: initColumns,
            rows: [],
            currentPage: 1,
            totalRows: 0,
            rowsPerPageSelected: 50,
            rowsPerPageOptions: [50, 100, 300],

            isDisabledBatchAction: true,
            isCookies: false,

            isBulkRemoveDialog: false,

            size: 0,
        };
    }


    componentDidMount() {
        this.setLayoutForm({
            leftHeaderContent: this.leftHeader,
            leftBodyContent: this.leftBody,
            rightHeaderContent: this.rightHeader,
            rightHeaderTabs: this.rightTabs,
            onWindowResize: () => {
                this.getFilterInLocalStorage();
            },
            onChangeSize: (size) => {
                this.setState({
                    size: size
                });
            },
        });

        this.getFilterInLocalStorage(() => {
            this.callInitApis();
        });
    }

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            this.pageName + '-read_services',
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = [];
                let total = 0;
                if (data && data.data && data.data.length > 0) {
                    arr = data.data;
                    total = data.total;
                }

                this.setState({
                    rows: arr,
                    totalRows: total,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    this.setLoading(false);

                    if (callback) {
                        callback();
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-list_download_excel',
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    if (callback) {
                        callback(data);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-deactivate',
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    if (callback) {
                        callback(data);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-update_bulk',
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    if (callback) {
                        callback(data);
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            this.pageName + '-bulk_delete', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(data, isError);
                    }
                });
            }
        );
    }

    callInitApis = () => {
        Utils.callAllApi([
            {
                api: readApi,
                path: this.pageAPI,
                params: () => {
                    let data = {
                        currentPage: this.state.currentPage,
                        pageSize: this.state.rowsPerPageSelected,
                        searchQuery: this.state.search,
                        sortColumn: this.state.sortColumn,
                        sortDir: this.state.sortDir,
                    };

                    let isIncludeInactive = ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : null;
                    if (isIncludeInactive) {
                        data['isIncludeInactive'] = isIncludeInactive;
                    }

                    let isInactive = ((this.state.isInactive === true) || (this.state.isInactive === false)) ? this.state.isInactive : null;
                    if (isInactive) {
                        data['isInactive'] = isInactive;
                    }

                    return data;
                },
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        let arr = [];
                        let total = 0;
                        if (data && data.data && data.data.length > 0) {
                            arr = data.data;
                            total = data.total;
                        }

                        this.setState({
                            rows: arr,
                            totalRows: total,
                        }, () => {
                            this.refreshLayout();
                        });
                    });
                }
            }
        ], (result) => {
            this.setState({
                mainLoading: true,
            });

            this.setLoading(true);
        }, (results) => {
            this.setLoading(false);
            this.refreshLayout();

            this.setState({
                mainLoading: false,
            });
        });
    }

    callDetailsApis = (additionalParams = null) => {
        Utils.callAllApi([
            {
                api: readApi,
                path: this.pageAPI,
                params: () => {
                    let data = {
                        currentPage: this.state.currentPage,
                        pageSize: this.state.rowsPerPageSelected,
                        searchQuery: this.state.search,
                        sortColumn: this.state.sortColumn,
                        sortDir: this.state.sortDir,
                    };

                    let isIncludeInactive = ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : null;
                    if (isIncludeInactive) {
                        data['isIncludeInactive'] = isIncludeInactive;
                    }

                    let isInactive = ((this.state.isInactive === true) || (this.state.isInactive === false)) ? this.state.isInactive : null;
                    if (isInactive) {
                        data['isInactive'] = isInactive;
                    }

                    return data;
                },
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        let arr = [];
                        let total = 0;
                        if (data && data.data && data.data.length > 0) {
                            arr = data.data;
                            total = data.total;
                        }

                        this.setState({
                            rows: arr,
                            totalRows: total,
                        }, () => {
                            this.refreshLayout();
                        });
                    });
                }
            }
        ], (result) => {
            this.setState({
                mainLoading: true,
            });

            this.setLoading(true);
        }, (result) => {
            if (additionalParams) {
                this.setState(additionalParams);
            }

            this.setLoading(false);
            this.refreshLayout();

            this.setState({
                mainLoading: false,
            });
        });
    }

    callReadApi = (callback = null) => {
        this.setLoading(true);

        let data = {
            currentPage: this.state.currentPage,
            pageSize: this.state.rowsPerPageSelected,
            searchQuery: this.state.search,
            sortColumn: this.state.sortColumn,
            sortDir: this.state.sortDir,
        };

        let isIncludeInactive = ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : null;
        if (isIncludeInactive) {
            data['isIncludeInactive'] = isIncludeInactive;
        }

        let isInactive = ((this.state.isInactive === true) || (this.state.isInactive === false)) ? this.state.isInactive : null;
        if (isInactive) {
            data['isInactive'] = isInactive;
        }

        this.props.dispatchApiCallGet(data, this.pageName + '-read_services', this.pageAPI, null, callback, () => { });
    }

    callDownloadExcelAllApi = (callback = null) => {
        Utils.toast(<div style={{ fontWeight: 'bold' }}>Please Wait ...</div>, 'info');

        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: this.state.search,
            sortColumn: this.state.sortColumn,
            sortDir: this.state.sortDir,
        };


        let isIncludeInactive = ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : null;
        if (isIncludeInactive) {
            data['isIncludeInactive'] = isIncludeInactive;
        }

        let isInactive = ((this.state.isInactive === true) || (this.state.isInactive === false)) ? this.state.isInactive : null;
        if (isInactive) {
            data['isInactive'] = isInactive;
        }

        this.props.dispatchApiCallGet(data, this.pageName + '-list_download_excel', this.pageAPI + '/download-excel', null, callback, null);
    }

    callActivateApi = (active = false, callback = null) => {
        this.setState({
            isLoading: true,
        });

        let ids = (this.tableRef && this.tableRef.current) ? this.tableRef.current.getSelected().join(',') : '0';

        let data = {
            isActive: active,
            ids: ids,
        };

        this.props.dispatchApiCallPut(data, this.pageName + '-deactivate', this.pageAPI + '/deactivate', null, callback, null);
    }

    callUpdateApi = (data, callback = null) => {
        this.setState({
            isLoading: true,
        });

        this.props.dispatchApiCallPut(data, this.pageName + '-update_bulk', this.pageAPI + '/bulk', data, callback, null);
    }
    
    callBulkDeleteApi = (callback = null) => {
        this.setLoading(true);
        
        let selected = Utils.getSelected(this.tableRef);

        let data = { 
            ids: selected,
            forceDelete: true
        };

        this.props.dispatchApiCallDelete(data, this.pageName + '-bulk_delete', this.pageAPI + '/bulk-delete', data, callback, null);
    }
    /* END API */


    /* LAYOUT FUNCTIONS */
    setLayoutForm = (options) => {
        // if (this.props.layoutRef && this.props.layoutRef.current) {
        //     this.props.layoutRef.current.setLayoutForm(this, options);
        // }
    }
    setLayoutLoading = (state = false) => {
        // if (this.props.layoutRef && this.props.layoutRef.current) {
        //     this.props.layoutRef.current.setLayoutLoading(state);
        // }
    }
    refreshLayout = () => {
        // if (this.props.layoutRef && this.props.layoutRef.current) {
        //     this.props.layoutRef.current.refreshLayout();
        // }
    }
    getLayoutObj = (options) => {
        // if (this.props.layoutRef && this.props.layoutRef.current && this.props.layoutRef.current.obj && this.props.layoutRef.current.obj.current) {
        //     return this.props.layoutRef.current.obj.current;
        // }
    }
    /* END LAYOUT FUNCTIONS */


    /* FUNCTIONS */
    onWindowResize = () => {
        this.getFilterInLocalStorage();
    }
    
    setLoading = (state = false) => {
        this.setLayoutLoading(state);

        if (this.props.onLoading) {
            this.props.onLoading(state);
        }
    }

    setFilterInLocalStorage = (isCookies = true) => {
        if (this.props.settingsType >= 0) {
            let { token, userCookieSettings } = this.props.auth;

            this.setState({
                isCookies: isCookies,
            });

            let data = {
                name: this.pageName,
                type: this.props.settingsType,
            };

            let valueLocalStorage = {
                [PageCookieSettingType.currentPage]: this.state.currentPage,
            };
            window.localStorage.setItem(this.pageName + '_' + this.props.settingsType, JSON.stringify(valueLocalStorage));

            let value = {
                [PageCookieSettingType.isCookies]: isCookies,
                [PageCookieSettingType.search]: this.state.search,
                [PageCookieSettingType.sortColumn]: this.state.sortColumn,
                [PageCookieSettingType.sortDir]: this.state.sortDir,
                [PageCookieSettingType.rowsPerPageSelected]: this.state.rowsPerPageSelected,
                [PageCookieSettingType.columnVisibility]: this.state.columnVisibility,
                [PageCookieSettingType.columns]: this.state.columns,
                [PageCookieSettingType.isIncludeInactive]: this.state.isIncludeInactive,
                [PageCookieSettingType.isInactive]: this.state.isInactive,
            };
            data['value'] = JSON.stringify(value);

            Utils.saveUserCookieSettings(data, token, (settings) => {
                if (settings) {
                    let settingsArr = (userCookieSettings && userCookieSettings.length > 0) ? userCookieSettings : [];
                    let settingsIndex = Utils.getUserCookieSettingsIndex(settingsArr, this.props.settingsType);
                    if (settingsIndex !== -1 && settingsArr[settingsIndex]) {
                        settingsArr[settingsIndex] = settings;
                    } else {
                        settingsArr.push(settings);
                    }

                    this.props.saveUserCookieSettings(settingsArr)
                }
            });
        }
    };
    getFilterInLocalStorage = (callback = null) => {
        let settingsItem = null;

        if (this.props.settingsType >= 0) {
            const { userCookieSettings } = this.props.auth;

            settingsItem = Utils.getUserCookieSettings(userCookieSettings, this.props.settingsType, this.pageName);
        }

        let valueLocalStorageStr = window.localStorage.getItem(this.pageName + '_' + this.props.settingsType);
        let valueLocalStorage = JSON.parse(valueLocalStorageStr);
        let currentPageValue = valueLocalStorage && valueLocalStorage[PageCookieSettingType.currentPage] && valueLocalStorage[PageCookieSettingType.currentPage] !== "" ? valueLocalStorage[PageCookieSettingType.currentPage] : this.state.currentPage;

        let isCookiesValue = settingsItem && settingsItem[PageCookieSettingType.isCookies] && settingsItem[PageCookieSettingType.isCookies] !== "" ? settingsItem[PageCookieSettingType.isCookies] : this.state.isCookies;
        let searchValue = (settingsItem && settingsItem[PageCookieSettingType.search] && settingsItem[PageCookieSettingType.search] !== '') ? settingsItem[PageCookieSettingType.search] : this.state.search;
        let sortColumnValue = settingsItem && settingsItem[PageCookieSettingType.sortColumn] && settingsItem[PageCookieSettingType.sortColumn] !== "" ? settingsItem[PageCookieSettingType.sortColumn] : this.state.sortColumn;
        let sortDirValue = settingsItem && settingsItem[PageCookieSettingType.sortDir] && settingsItem[PageCookieSettingType.sortDir] !== "" ? settingsItem[PageCookieSettingType.sortDir] : this.state.sortDir;
        let rowsPerPageSelectedValue = settingsItem && settingsItem[PageCookieSettingType.rowsPerPageSelected] && settingsItem[PageCookieSettingType.rowsPerPageSelected] !== "" ? settingsItem[PageCookieSettingType.rowsPerPageSelected] : this.state.rowsPerPageSelected;
        let columnVisibilityValue = (settingsItem && settingsItem[PageCookieSettingType.columnVisibility] && settingsItem[PageCookieSettingType.columnVisibility] !== '') ? settingsItem[PageCookieSettingType.columnVisibility] : this.state.columnVisibility;
        let columnsValue = (settingsItem && settingsItem[PageCookieSettingType.columns] && settingsItem[PageCookieSettingType.columns] !== '') ? settingsItem[PageCookieSettingType.columns] : this.state.columns;
        let isIncludeInactiveValue = (settingsItem && settingsItem[PageCookieSettingType.isIncludeInactive] && settingsItem[PageCookieSettingType.isIncludeInactive] !== '') ? settingsItem[PageCookieSettingType.isIncludeInactive] : this.state.isIncludeInactive;
        let isInactiveValue = (settingsItem && settingsItem[PageCookieSettingType.isInactive] && settingsItem[PageCookieSettingType.isInactive] !== '') ? settingsItem[PageCookieSettingType.isInactive] : this.state.isInactive;

        let columns = Utils.setTemplateFunctionsToColumns(this.state.columns, columnsValue);

        this.setState({
            isCookies: isCookiesValue,
            search: searchValue,
            sortColumn: sortColumnValue,
            sortDir: sortDirValue,
            currentPage: currentPageValue,
            rowsPerPageSelected: rowsPerPageSelectedValue,
            columnVisibility: columnVisibilityValue,
            isIncludeInactive: isIncludeInactiveValue,
            isInactive: isInactiveValue,
            columns: columns,
        }, () => {
            if (callback) {
                callback();
            }
        });
    };
    /* END FUNCTIONS */


    /* FORM */
    topSection = () => {
        return <ViewTopSection
            hasChecked={(!this.state.isDisabledBatchAction)}
            unchecked={this.topSectionUnchecked()}
            checked={this.topSectionChecked()}
        />
    }
    topSectionUnchecked = () => {
        return <Row className={"align-items-center"}>
          <Col xs={12} md={'auto'}>
            <div style={{ fontSize: '20px' }}>Billing Category</div>
            {/* <Dropdown alignRight>
              <Dropdown.Toggle as={DropdownToggle}>
                {Utils.getAllActiveInactiveText(this.state.isIncludeInactive, this.state.isInactive)} Billing Category
                <ArrowDropDownIcon />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  active={(this.state.isIncludeInactive && this.state.isInactive == null)}
                  onClick={() => {
                    this.setState({
                      isIncludeInactive: true,
                      isInactive: null,
                      currentPage: 1,
                    }, () => {
                      this.callReadApi();
                    });
                  }} 
                >All</Dropdown.Item>
                <Dropdown.Item
                  active={(this.state.isIncludeInactive == null && !this.state.isInactive)}
                  onClick={() => {
                    this.setState({
                      isIncludeInactive: null,
                      isInactive: false,
                      currentPage: 1,
                    }, () => {
                      this.setFilterInLocalStorage();
                      this.callReadApi();
                    });
                  }} 
                >Active</Dropdown.Item>
                <Dropdown.Item
                  active={(this.state.isIncludeInactive == null && this.state.isInactive)}
                  onClick={() => {
                    this.setState({
                      isIncludeInactive: null,
                      isInactive: true,
                      currentPage: 1,
                    }, () => {
                      this.setFilterInLocalStorage();
                      this.callReadApi();
                    });
                  }} 
              >Inactive</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
          </Col>
          <Col xs={12} md={4} lg={3} className={'pb-2 pb-md-0'}>
            <IconButtonSearch 
              value={this.state.search}
              isOpen={(this.state.search !== '') ? true : false}
              onPressEnter={(value) => {
                this.setState({
                  search: value,
                  currentPage: 1,
                }, () => {
                  this.setFilterInLocalStorage();
                  this.callReadApi();
                });
              }}
              onClear={() => {
                this.setState({
                  search: '',
                  currentPage: 1,
                }, () => {
                  this.setFilterInLocalStorage();
                  this.callReadApi();
                });
              }}
            />
          </Col>
          <Col xs={true}></Col>
          <Col xs={'auto'} className={'text-center'}>
            <Button
              size={"sm"}
              onClick={() => {
                this.setState({
                    popupShowBillingCategoryForm: true,
                    popupLoadingBillingCategoryForm: false,
                    popupIdBillingCategoryForm: 0,
                    popupIsCopyBillingCategoryForm: false,
                });
              }}
            >+ New</Button>
          </Col>
          <Col xs={'auto'} className={'text-center'}>
            <Dropdown>
              <Dropdown.Toggle as={DropdownToggle} isButton className={'px-3'} variant={"light"} size={"sm"}>
                <SettingsOutlinedIcon />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  active={false}
                  onClick={() => {
                    this.callDownloadExcelAllApi((data) => {
                      let type = 'application/vnd.ms-excel';
                      Utils.saveByteArray(this.pageCaption, data, type);
                    });
                  }} 
                >Download all in Excel format</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col xs={'auto'} className={'text-center'}>
            <DropdownMenu
              className={'px-3'}
              variant={"light"}
              size={"sm"}
              text={<ViewHeadlineIcon />}
            >
              <DataTableActionMenu 
                pageName={this.pageName}
                columns={this.state.columns}
                selected={this.state.rowsPerPageSelected}
                selectItems={this.state.rowsPerPageOptions}
                isCheckedIncludeInactive={this.state.isIncludeInactive}
                columnVisibility={this.state.columnVisibility}
                canSelectOption={false}
                onChangeColumnVisibility={(index, value) => {
                  let colVis = this.state.columnVisibility;
                  colVis[index] = value;
    
                  this.setState({
                    columnVisibility: colVis
                  }, () => {
                    this.setFilterInLocalStorage();
                  });
                }}
                canInactive={false}
                onSelectChangeAjax={(val) => {
                  this.setState({
                    currentPage: 1,
                    rowsPerPageSelected: val
                  }, () => {
                    this.setFilterInLocalStorage();
                    this.callReadApi();
                  });
                }}
                onChangeIncludeInactive={(val) => {
                  this.setState({
                    isIncludeInactive: val,
                    currentPage: 1,
                  }, () => {
                    this.setFilterInLocalStorage();
                    this.callReadApi();
                  });
                }}
                isCookies={this.state.isCookies}
                onResetColumns={() => {
                  this.setState({
                    columns: Array.from(this.state.initColumns),
                    columnVisibility: Array.from(this.state.initColumnVisibility),
                    sortColumn: this.state.sortColumnInit,
                    sortDir: this.state.sortDirInit,
                  }, () => {
                    this.setFilterInLocalStorage(false);
                    this.callReadApi();
                    if(this.refDropdownActionMenu && this.refDropdownActionMenu.current){
                      this.refDropdownActionMenu.current.hide();
                    }
                  });
                }}
                onShowMore={(isShowedMore) => {
                  this.setState({
                    pageName: this.pageName,
                  });
                }}
              />
            </DropdownMenu>
          </Col>
        </Row>
      }
      topSectionChecked = () => {
        return <Row className={"align-items-center"}>
          <Col xs={12} md={'auto'}>
            <Dropdown alignRight>
              <Dropdown.Toggle>
                Batch Action
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                    disabled={this.state.isDisabledBatchAction}
                    active={false}
                    onClick={() => {
                        let selected = '';
                        if (this.tableRef && this.tableRef.current) {
                            let selectedArr = this.tableRef.current.getSelected();
                            if (selectedArr && selectedArr.length > 0) {
                                selected = selectedArr.join(',');
                            }
                        }

                        this.setState({
                            popupShowBulkUpdate: true,
                            popupLoadingBulkUpdate: false,
                            popupIdBulkUpdate: selected,
                        });
                    }}
                >Bulk Update</Dropdown.Item>
                <Dropdown.Item
                    disabled={this.state.isDisabledBatchAction}
                    active={false}
                    onClick={() => {
                        this.setState({
                            isBulkRemoveDialog: true,
                        });
                    }} 
                >Delete</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col xs={12} md={true}>
            <span>{((this.tableRef && this.tableRef.current) ? this.tableRef.current.getSelected().length : '0')} item(s) selected</span>
          </Col>
          <Col xs={true}></Col>
          <Col xs={'auto'} className={'text-center'}>
            <WrappedButton
              className={'px-3 pe-1'}
              variant={"light"}
              size={'sm'}
              style={{ backgroundColor: 'transparent' }}
              onClick={() => {
                if(this.tableRef && this.tableRef.current){
                  this.tableRef.current.setSelected([], () => {
                    this.setState({
                      isDisabledBatchAction: true,
                    });
                  });
                }
              }}
            >
              <i className={'material-icons'}>close</i>
            </WrappedButton>
          </Col>
        </Row>
      }

    tableSection = () => {
        return <DataTableScrollSync ref={this.refDataTableScrollSync} className={"table-sync-v4"}>{this.setDataTable()}</DataTableScrollSync>
    }
    setDataTable = () => {
        return <DataTable
            ref={this.tableRef}

            className={"table-style-v4"}
            classNameWrapper={"table-v4"}

            pageName={this.pageName}
            rowId={this.pageIdField}
            caption={this.pageCaption}

            noMarginBottom={false}
            verticalAlign={'middle'}

            stickyHeader={false}
            isHeader={true}
            resizable={true}
            isDragableHeaderColumns={true}
            isDragable={false}
            isCheckbox={true}
            isBorder={false}
            isPagination={false}
            isVirtualize={false}

            columnVisibility={this.state.columnVisibility}
            disabledResizeColumns={[0]}

            isLoading={this.state.isLoading}
            rowsPerPageSelected={this.state.rowsPerPageSelected}
            rowsPerPageOptions={this.state.rowsPerPageOptions}

            search={this.state.search}
            rows={this.state.rows}
            columns={this.state.columns}

            onRowClick={(id, row) => {
                this.setState({
                    popupShowBillingCategoryForm: true,
                    popupLoadingBillingCategoryForm: false,
                    popupIdBillingCategoryForm: id,
                    popupIsCopyBillingCategoryForm: false,
                });
            }}

            onRemove={(row) => {

            }}

            onClickCheckAll={(obj, checked, isDisabledBatchAction) => {
                this.setState({
                    isDisabledBatchAction: isDisabledBatchAction
                });
            }}
            onCheckRow={(obj, id, isDisabledBatchAction) => {
                this.setState({
                    isDisabledBatchAction: isDisabledBatchAction
                });
            }}

            onSortEnd={(columns, columnVisibility) => {
                this.setState({
                    columns: columns,
                    columnVisibility: columnVisibility,
                }, () => {
                    this.setFilterInLocalStorage();
                });
            }}
            onOrder={(name, sort) => {
                if(this.tableRef && this.tableRef.current){
                    this.tableRef.current.setSelected([], () => {
                        this.setState({
                            isDisabledBatchAction: true,
                        });
                    });
                }
                
                this.setState({
                    sortColumn: name,
                    sortDir: sort,
                }, () => {
                    this.setFilterInLocalStorage();
                    this.callReadApi((rows, total) => {
                        this.refreshLayout();
                    });
                });
            }}
            onResize={(columns) => {
                if(columns){
                  this.setState({
                    columns: columns
                  });
                }
                this.setFilterInLocalStorage();
            }}
        />
    }

    paginationSection = () => {
        return <DataTableAjaxPagination
            style={{
                padding: '10px 0px',
                border: '0px',
                left: this.state.size + 'px',
                width: 'calc(100% - ' + this.state.size + 'px)'
            }}
            page={this.state.currentPage}
            selected={this.state.rowsPerPageSelected}
            count={this.state.totalRows}
            fixed={true}
            onPageChange={(page) => {
                this.setState({
                    currentPage: page,
                }, () => {
                    this.setFilterInLocalStorage();
                    this.callReadApi((rows, total) => {
                        this.refreshLayout();
                    });
                });
            }}
            onOptionsChange={(option) => {
              this.setState({
                currentPage: 1,
                rowsPerPageSelected: option,
              }, () => {
                this.setFilterInLocalStorage();
                this.callReadApi((rows, total) => {
                    this.refreshLayout();
                });
              });
            }}
        />
    }
    /* END FORM */


    /* DIALOG */
    setPopupBulkUpdate = () => {
        return <Popup
            className={'no-bg'}
            auth={this.props.auth}
            settingsType={this.props.settingsType}

            show={this.state.popupShowBulkUpdate}
            isLoading={this.state.popupLoadingBulkUpdate}
            id={this.state.popupIdBulkUpdate}

            showHeader={true}
            header={'Billing Category'}
            showFooter={true}
            centerFooter={'center'}
            footer={({ isLoading, id, onHide, onSave }) => {
                return <Row>
                    <Col xs={'auto'}>
                        <LoadingButton
                            isLoading={isLoading}
                            onClick={onSave}
                        >Save</LoadingButton>
                    </Col>
                    <Col xs={'auto'}>
                        <Button
                            variant={'light'}
                            disabled={isLoading}
                            onClick={onHide}
                        >Cancel</Button>
                    </Col>
                </Row>
            }}

            scrollable={true}
            centered={true}
            closeButton={true}
            fullscreen={false}
            noBodyPadding={false}
            size={'lg'}

            onHide={() => {
                this.setState({
                    popupShowBulkUpdate: false,
                    popupLoadingBulkUpdate: false,
                    popupIdBulkUpdate: null,
                });
            }}
            onSave={() => {
                if (this.refBulkUpdate && this.refBulkUpdate.current) {
                    if (this.refBulkUpdate.current.refForm && this.refBulkUpdate.current.refForm.current) {
                        this.refBulkUpdate.current.refForm.current.handleSubmit();
                    }
                }
            }}

            onLoading={(state) => {
                this.setState({
                    popupLoadingBulkUpdate: state,
                });
            }}
            onSubmit={(form) => {
                this.setState({
                    popupLoadingBulkUpdate: true,
                }, () => {
                    this.callUpdateApi(form, (res) => {
                        this.setState({
                            popupShowBulkUpdate: false,
                            popupLoadingBulkUpdate: false,
                            popupIdBulkUpdate: null,
                        }, () => {
                            if (this.tableRef && this.tableRef.current) {
                                this.tableRef.current.setSelected([], () => {
                                    this.setState({
                                        isDisabledBatchAction: true,
                                    });
                                });
                            }

                            this.callReadApi();
                        });
                    });
                });
            }}
        >
            {(props) => {
                return <BulkUpdateBillingCategoryForm
                    ref={this.refBulkUpdate}
                    {...props}
                />
            }}
        </Popup>
    }

    setSearchDialog = () => {
        return <SearchDialog
            show={this.state.leftIsSearchDialog}
            onCancel={() => {
                this.setState({
                    leftIsSearchDialog: false,
                });
            }}
            onSearch={(value) => {
                this.setState({
                    leftSearch: value,
                    leftIsSearchDialog: false,
                }, () => {
                    this.callReadApi((items) => {
                        this.setState({
                            leftItems: items,
                        }, () => {
                            this.setLoading(false);
                            this.refreshLayout();
                        });
                    });
                });
            }}
        />
    }

    setPopupBillingCategoryForm = () => {
        return <Popup
            className={'no-bg'}
            auth={this.props.auth}
            settingsType={this.props.settingsType}

            show={this.state.popupShowBillingCategoryForm}
            isLoading={this.state.popupLoadingBillingCategoryForm}
            id={this.state.popupIdBillingCategoryForm}
            isCopy={this.state.popupIsCopyBillingCategoryForm}

            showHeader={true}
            header={this.state.popupIdBillingCategoryForm ? this.state.popupIsCopyBillingCategoryForm ? 'Copy Billing Category' : 'Edit Billing Category' : 'Add Billing Category'}
            showFooter={true}
            centerFooter={'start'}
            footer={({ isLoading, id, onHide, onSave }) => {
                return <Row style={{ width: '100%' }}>
                    <Col xs={'auto'}>
                        <LoadingButton
                            isLoading={isLoading}
                            onClick={onSave}
                        >Save</LoadingButton>
                    </Col>
                    <Col xs={'auto'}>
                        <Button
                            variant={'light'}
                            disabled={isLoading}
                            onClick={onHide}
                        >Cancel</Button>
                    </Col>
                </Row>
            }}

            scrollable={true}
            centered={true}
            closeButton={true}
            fullscreen={true}
            noBodyPadding={true}

            onHide={() => {
                this.setState({
                    popupShowBillingCategoryForm: false,
                    popupLoadingBillingCategoryForm: false,
                    popupIdBillingCategoryForm: 0,
                    popupIsCopyBillingCategoryForm: false,
                });
            }}
            onSave={() => {
                if (this.refBillingCategoryForm && this.refBillingCategoryForm.current) {
                    if (this.refBillingCategoryForm.current.refForm && this.refBillingCategoryForm.current.refForm.current) {
                        this.refBillingCategoryForm.current.refForm.current.handleSubmit();
                    }
                }
            }}

            onLoading={(state) => {
                this.setState({
                    popupLoadingBillingCategoryForm: state,
                });
            }}
            onSubmit={(form) => {
                this.setState({
                    popupShowBillingCategoryForm: false,
                    popupLoadingBillingCategoryForm: false,
                    popupIdBillingCategoryForm: 0,
                    popupIsCopyBillingCategoryForm: false,
                    details: null,
                }, () => {
                    this.callReadApi((items) => {
                        this.setState({
                            leftItems: items,
                        }, () => {
                            this.setLoading(false);
                            this.refreshLayout();
                        });
                    });
                });
            }}
        >
            {(props) => {
                return <BillingCategoryForm
                    ref={this.refBillingCategoryForm}
                    {...props}
                />
            }}
        </Popup>
    }

    setBulkRemoveDialog = () => {
        return <RemoveDialog 
          title={'Remove charge categories'}
          show={this.state.isBulkRemoveDialog}
          isLoading={false}
          onCancel={() => {
            this.setState({
                isBulkRemoveDialog: false,
            });
          }}
          onRemove={() => {
            this.setState({
                isBulkRemoveDialog: false,
            }, () => {
                this.callBulkDeleteApi((res, isError) => {
                    this.callReadApi((rows, total) => {
                        this.setState({
                            isDisabledBatchAction: !this.state.isDisabledBatchAction,
                        }, () => {
                            Utils.setSelected(this.tableRef);
                            this.setLoading(false);
                            this.refreshLayout();
                        });
                    });
                });
            });
          }}
        />
    }
    /* END DIALOG */


    render() {
        return <div className={'global-service-page'}>
            <ViewLayout
                topSection={this.topSection()}
                tableSection={this.tableSection()}
                paginationSection={this.paginationSection()}
            />
            <div className={'px-5 container'}>
                <Row className={'justify-content-start pb-10'}>
                    <Col xs={12} sm={8} lg={6} className={'pb-10'}>
                        <Row className={'pb-10'}>
                            <Col xs={'auto'} className={'pt-10'}>
                                <LightbulbIcon />
                            </Col>
                            <Col xs={true} className={'pb-10'}>
                                <Row className={'pb-10'}>
                                    <Col xs={12} className={'mb-2 fw-bold'}>What Is A Billing Category</Col>
                                    <Col xs={12}>Like a Chart of Account in an accounting software, Billing Category allows you to record invoice. purchase transactions and associating billable weight to transactional quantity.</Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            {this.setPopupBulkUpdate()}
            {this.setPopupBillingCategoryForm()}
            {this.setBulkRemoveDialog()}
        </div>
    }
}

const mapDispatchToProps = {
    dispatchApiCallGet,
    dispatchApiCallPost,
    dispatchApiCallPut,
    dispatchApiCallDelete,
    saveUserCookieSettings,
    clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(BillingCategoryList);
