import { createSlice } from "@reduxjs/toolkit";

import * as dispatchCrudApi from '../../../api/CRUD/DispatchCRUD'
import Utils from "../../../utils/utils";


function NewReducer() {
  const name = 'billingBatchAction';


  const initialState = {
    updateBillingStatus: {
      isLoading: false,
      failedJobs: [],
      failedJobIds: [],
      successJobs: [],
      successJobIds: [],
    },
    refreshBillingStatus: {
      isLoading: false,
      failedJobs: [],
      failedJobIds: [],
      successJobs: [],
      successJobIds: [],
    },
    createInvoice: {
      isLoading: false,
      showInvoiceNumber: false,
      items: [],
      isError: false,
    },
    createPurchase: {
      isLoading: false,
      showInvoiceNumber: false,
      items: [],
      isError: false,
    },
  };


  const reducers = {
    startUpdateBillingStatus: (state) => {
      state.updateBillingStatus.isLoading = true;
      state.updateBillingStatus.failedJobs = [];
      state.updateBillingStatus.failedJobIds = [];
      state.updateBillingStatus.successJobs = [];
      state.updateBillingStatus.successJobIds = [];
    },
    finishUpdateBillingStatus: (state, action) => {
      let updateBillingStatus = state.updateBillingStatus;

      let data = action.payload.data;
      let failedJobs = [];
      let failedJobIds = [];
      if(data && data.failedJobs && data.failedJobs.length){
        for(let i = 0; i < data.failedJobs.length; i++){
          let item = data.failedJobs[i];

          failedJobIds.push(item.jobId);

          if(item && item.job)
          failedJobs.push(item.job);
        }
      }

      let successJobs = [];
      let successJobIds = [];
      if(data && data.successJobs && data.successJobs.length > 0){
        for(let i = 0; i < data.successJobs.length; i++){
          let item = data.successJobs[i];

          successJobIds.push(item.jobId);
          
          if(item && item.job)
          successJobs.push(item.job);
        }
      }
      
      state.updateBillingStatus.failedJobs = failedJobs;
      state.updateBillingStatus.failedJobIds = failedJobIds;
      state.updateBillingStatus.successJobs = successJobs;
      state.updateBillingStatus.successJobIds = successJobIds;

      updateBillingStatus.isLoading = false;
      state.updateBillingStatus = updateBillingStatus;
    },


    startRefreshBillingStatus: (state) => {
      state.refreshBillingStatus.isLoading = true;
      state.refreshBillingStatus.failedJobs = [];
      state.refreshBillingStatus.failedJobIds = [];
      state.refreshBillingStatus.successJobs = [];
      state.refreshBillingStatus.successJobIds = [];
    },
    finishRefreshBillingStatus: (state, action) => {
      let refreshBillingStatus = state.refreshBillingStatus;

      let data = action.payload.data;
      let failedJobs = [];
      let failedJobIds = [];
      if(data && data.failedJobs && data.failedJobs.length){
        for(let i = 0; i < data.failedJobs.length; i++){
          let item = data.failedJobs[i];

          failedJobIds.push(item.jobId);

          if(item && item.job)
          failedJobs.push(item.job);
        }
      }

      let successJobs = [];
      let successJobIds = [];
      if(data && data.successJobs && data.successJobs.length > 0){
        for(let i = 0; i < data.successJobs.length; i++){
          let item = data.successJobs[i];

          successJobIds.push(item.jobId);

          if(item && item.job)
          successJobs.push(item.job);
        }
      }

      state.refreshBillingStatus.failedJobs = failedJobs;
      state.refreshBillingStatus.failedJobIds = failedJobIds;
      state.refreshBillingStatus.successJobs = successJobs;
      state.refreshBillingStatus.successJobIds = successJobIds;

      refreshBillingStatus.isLoading = false;
      state.refreshBillingStatus = refreshBillingStatus;
    },


    startCreateInvoice: (state) => {
      state.createInvoice.isLoading = true;
      state.createInvoice.showInvoiceNumber = false;
      state.createInvoice.items = [];
      state.createInvoice.isError = false;
    },
    finishCreateInvoice: (state, action) => {
      let createInvoice = state.createInvoice;

      let data = action.payload.data;
      state.createInvoice.isError = action.payload.isError;

      let listPromptInvoiceNumber = [];
      if(data && data.failedJobs && data.failedJobs.length){
        for(let i = 0; i < data.failedJobs.length; i++){
          let item = data.failedJobs[i];
          if(item.promptInvoiceNumber === true){
            listPromptInvoiceNumber.push(item);
          }
        }
      }

      if(data && data.failedJobs && data.failedJobs.length){
        for(let i = 0; i < data.failedJobs.length; i++){
          let item = data.failedJobs[i];
          let jobNumber = item.jobNumber;
          let title = item.message;
          let msg = item.quickbookError;
          let invoiceNumber = (item.job && item.job.invoiceNumber && item.job.invoiceNumber != '') ? item.job.invoiceNumber : null;
          let purchaseOrderNumber  = (item.job && item.job.purchaseOrderNumber  && item.job.purchaseOrderNumber  != '') ? item.job.purchaseOrderNumber  : null;
          let number = invoiceNumber ? invoiceNumber : purchaseOrderNumber ? purchaseOrderNumber : jobNumber;
          

          Utils.toast(<div>
            <div fontWeight={'bold'} pb={2}>{number + ' - ' + title}</div>
            <div>{msg}</div>
          </div>, 'error');
          break;
        }
      }

      if(data && data.successJobs && data.successJobs.length > 0){
        for(let i = 0; i < data.successJobs.length; i++){
          let item = data.successJobs[i];
          let jobNumber = item.jobNumber;
          let title = item.message;
          let msg = item.quickbookError;
          let invoiceNumber = (item.job && item.job.invoiceNumber && item.job.invoiceNumber != '') ? item.job.invoiceNumber : null;
          let purchaseOrderNumber  = (item.job && item.job.purchaseOrderNumber  && item.job.purchaseOrderNumber  != '') ? item.job.purchaseOrderNumber  : null;
          let number = invoiceNumber ? invoiceNumber : purchaseOrderNumber ? purchaseOrderNumber : jobNumber;
          

          Utils.toast(<div>
            <div fontWeight={'bold'} pb={2}>{number + ' - ' + title}</div>
            <div>{msg}</div>
          </div>, 'success');
          break;
        }
      }

      if(listPromptInvoiceNumber && listPromptInvoiceNumber.length > 0){
        createInvoice.showInvoiceNumber = true;
        createInvoice.items = listPromptInvoiceNumber;
      } else {
        createInvoice.showInvoiceNumber = false;
        createInvoice.items = [];
      }

      createInvoice.isLoading = false;
      state.createInvoice = createInvoice;
    },


    startCreatePurchase: (state) => {
      state.createPurchase.isLoading = true;
      state.createPurchase.showInvoiceNumber = false;
      state.createPurchase.items = [];
      state.createPurchase.isError = false;
    },
    finishCreatePurchase: (state, action) => {
      let createPurchase = state.createPurchase;

      let data = action.payload.data;
      state.createPurchase.isError = action.payload.isError;

      let listPromptInvoiceNumber = [];
      if(data && data.failedJobs && data.failedJobs.length){
        for(let i = 0; i < data.failedJobs.length; i++){
          let item = data.failedJobs[i];
          if(item.promptInvoiceNumber === true){
            listPromptInvoiceNumber.push(item);
          }
        }
      }

      if(data && data.failedJobs && data.failedJobs.length){
        for(let i = 0; i < data.failedJobs.length; i++){
          let item = data.failedJobs[i];
          let jobNumber = item.jobNumber;
          let title = item.message;
          let msg = item.quickbookError;
          let invoiceNumber = (item.job && item.job.invoiceNumber && item.job.invoiceNumber != '') ? item.job.invoiceNumber : null;
          let purchaseOrderNumber  = (item.job && item.job.purchaseOrderNumber  && item.job.purchaseOrderNumber  != '') ? item.job.purchaseOrderNumber  : null;
          let number = invoiceNumber ? invoiceNumber : purchaseOrderNumber ? purchaseOrderNumber : jobNumber;
          

          Utils.toast(<div>
            <div fontWeight={'bold'} pb={2}>{number + ' - ' + title}</div>
            <div>{msg}</div>
          </div>, 'error');
          break;
        }
      }

      if(data && data.successJobs && data.successJobs.length > 0){
        for(let i = 0; i < data.successJobs.length; i++){
          let item = data.successJobs[i];
          let jobNumber = item.jobNumber;
          let title = item.message;
          let msg = item.quickbookError;
          let invoiceNumber = (item.job && item.job.invoiceNumber && item.job.invoiceNumber != '') ? item.job.invoiceNumber : null;
          let purchaseOrderNumber  = (item.job && item.job.purchaseOrderNumber  && item.job.purchaseOrderNumber  != '') ? item.job.purchaseOrderNumber  : null;
          let number = invoiceNumber ? invoiceNumber : purchaseOrderNumber ? purchaseOrderNumber : jobNumber;
          

          Utils.toast(<div>
            <div fontWeight={'bold'} pb={2}>{number + ' - ' + title}</div>
            <div>{msg}</div>
          </div>, 'success');
          break;
        }
      }

      if(listPromptInvoiceNumber && listPromptInvoiceNumber.length > 0){
        createPurchase.showInvoiceNumber = true;
        createPurchase.items = listPromptInvoiceNumber;
      } else {
        createPurchase.showInvoiceNumber = false;
        createPurchase.items = [];
      }

      createPurchase.isLoading = false;
      state.createPurchase = createPurchase;
    },
  };


  const apis = {
    batchUpdateBillingStatus: (params = null) => async (dispatch) => {
      dispatch(actions.startUpdateBillingStatus());
  
      await dispatchCrudApi.updateApi(params, 'job/billing/batch-update-billing-status').then(result => {
        let data = result.data;
        
        dispatch(actions.finishUpdateBillingStatus({ data: data, errorMessage: '', isError: false }));
      }).catch(error => {
        let isError = true;
        let errorMessage = "";

        Utils.parseError(error, (result) => {
          isError = result.isError;
          errorMessage = result.errorMessage;
        });
        
        let err = (errorMessage && errorMessage.error && errorMessage.error != '') ? errorMessage.error : null;
        if(err){
          Utils.toast(<div style={{ fontWeight: 'bold' }}>{errorMessage.error}</div>, 'error');
        }
        
        dispatch(actions.finishUpdateBillingStatus({ data: null, errorMessage: errorMessage, isError: isError }));
      });
    },

    batchRefreshBillingStatus: (params = null) => async (dispatch) => {
      dispatch(actions.startRefreshBillingStatus());
  
      await dispatchCrudApi.createApi(params, 'job/billing/check-payment').then(result => {
        let data = result.data;
        
        if (typeof data == 'string') {
          Utils.toast(<div style={{ fontWeight: 'bold' }}>{data}</div>, 'info');
        }

        dispatch(actions.finishRefreshBillingStatus({ data: data, errorMessage: '', isError: false }));
      }).catch(error => {
        let isError = true;
        let errorMessage = "";

        Utils.parseError(error, (result) => {
          isError = result.isError;
          errorMessage = result.errorMessage;
        });
        
        let err = (errorMessage && errorMessage.error && errorMessage.error != '') ? errorMessage.error : null;
        if(err){
          Utils.toast(<div style={{ fontWeight: 'bold' }}>{errorMessage.error}</div>, 'error');
        }
        
        dispatch(actions.finishRefreshBillingStatus({ data: null, errorMessage: errorMessage, isError: isError }));
      });
    },

    callPushJobInvoiceApi: (params = null) => async (dispatch) => {
      dispatch(actions.startCreateInvoice());
  
      await dispatchCrudApi.createApi(params, 'job/billing/invoice').then(result => {
        let data = result.data;
        
        dispatch(actions.finishCreateInvoice({ data: data, errorMessage: '', isError: false }));
      }).catch(error => {
        let isError = true;
        let errorMessage = "";

        Utils.parseError(error, (result) => {
          isError = result.isError;
          errorMessage = result.errorMessage;
        });
        
        let err = (errorMessage && errorMessage.error && errorMessage.error != '') ? errorMessage.error : null;
        if(err){
          Utils.toast(<div style={{ fontWeight: 'bold' }}>{errorMessage.error}</div>, 'error');
        }
        
        dispatch(actions.finishCreateInvoice({ data: null, errorMessage: errorMessage, isError: isError }));
      });
    },

    callPushJobPurchaseApi: (params = null) => async (dispatch) => {
      dispatch(actions.startCreatePurchase());
  
      await dispatchCrudApi.createApi(params, 'job/billing/purchase-order').then(result => {
        let data = result.data;
        
        dispatch(actions.finishCreatePurchase({ data: data, errorMessage: '', isError: false }));
      }).catch(error => {
        let isError = true;
        let errorMessage = "";

        Utils.parseError(error, (result) => {
          isError = result.isError;
          errorMessage = result.errorMessage;
        });
        
        let err = (errorMessage && errorMessage.error && errorMessage.error != '') ? errorMessage.error : null;
        if(err){
          Utils.toast(<div style={{ fontWeight: 'bold' }}>{errorMessage.error}</div>, 'error');
        }
        
        dispatch(actions.finishCreatePurchase({ data: null, errorMessage: errorMessage, isError: isError }));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();