import React, { useState } from 'react';

import { compose, lifecycle, withProps } from 'recompose';
import { GoogleMap, Marker, withScriptjs, withGoogleMap, DirectionsRenderer } from "react-google-maps"

import MarkerIcon from '../../../../../_metronic/assets/img/icons/marker-icon.png';


const { REACT_APP_GOOGLE_MAP_API_KEY } = process.env;
const URL = "https://maps.googleapis.com/maps/api/js?key=" + REACT_APP_GOOGLE_MAP_API_KEY + "&v=3.exp&libraries=geometry,drawing,places";

const DEF_CENTER = { lat: 1.352083, lng: 103.819836 };


const GanttchartRouteMap = compose(
    withProps({
        googleMapURL: URL,
        loadingElement: <div style={{ width: '100%', height: '100%' }} />,
        containerElement: <div style={{ width: '100%', height:'100%' }} />,
        mapElement: <div style={{ width: '100%', height: `100%` }} />
    }),
    withScriptjs,
    withGoogleMap,
    lifecycle({
        componentDidMount() {
            const DirectionsService = new window.google.maps.DirectionsService();

            let places = [];
            if(this.props.markers && this.props.markers.length > 0){
                this.props.markers.map((item, i) => {
                    let position = null;
                    
                    if(item.steps && item.steps.length > 0){
                        for(let i = 0; i< item.steps.length; i++){
                            let data = item.steps[i];
        
                            if(data.customerSiteId){
                                position = { latitude: data.latitude, longitude: data.longitude };
                                break;
                            }
                        }
                    }
        
                    places.push(position);
                });
            }
            
            let waypoints = [];
            if(this.props.markers && this.props.markers.length > 0){
                waypoints = places.map(p =>({
                    location: { lat: p.latitude, lng: p.longitude },
                    stopover: true,
                }));
            }

            let origin = (waypoints && waypoints.length > 0) ? waypoints[0].location : null;
            let destination = (waypoints && waypoints.length > 0) ? waypoints[waypoints.length-1].location : null;
            
            DirectionsService.route({
                origin: origin,
                destination: destination,
                travelMode: window.google.maps.TravelMode.DRIVING,
                waypoints: waypoints,
            }, (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    let markers = [];

                    if(result && result.request && result.request.waypoints && result.request.waypoints.length > 0){
                        result.request.waypoints.forEach((item, i) => {
                            let position = {
                                lat: item.location.location.lat(),
                                lng: item.location.location.lng(),
                            };
            
                            if(!markers.some(x => x.lat === position.lat && x.lng === position.lng)){
                                markers.push(position);
                            }
                        });
                    }

                    let center = DEF_CENTER;
                    if(markers && markers.length > 0){
                        center = markers[0];
                    }

                    let zoom = 12;
                    if(markers && markers.length === 1){
                        zoom = 15;
                    }

                    this.setState({
                        directions: result,
                        markers: markers,
                        center: center,
                        zoom: zoom,
                    });
                }
            });
        }
      })
  )(props => {
    const [defaultCenter, setDefaultCenter] = useState(props.defaultCenter ? props.defaultCenter : DEF_CENTER);
    const [defaultZoom, setDefaultZoom] = useState(props.defaultZoom ? props.defaultZoom : 12);


    React.useEffect(() => {
        setDefaultCenter(props.defaultCenter ? props.defaultCenter : DEF_CENTER);
        setDefaultZoom(props.defaultZoom ? props.defaultZoom : 12);
    }, []);

    
    const showAllMarkers = () => {
        if(props.markers && props.markers.length > 0){
            return props.markers.map((position, i) => {
                return <Marker 
                    key={i}
                    position={position}
                    label={{
                        text: "" + (i+1),
                        color: 'white',
                    }}
                    draggable={false}
                    options={{
                        markerLabel: {
                            color: '#ffffff',
                        }
                    }}
                />
            });
        }
    }

    
    const googleMap = () => {
      return <div>
        <GoogleMap
            ref={props.onMapMounted}
            onCenterChanged={props.onCenterChanged}
            center={props.center}
            zoom={props.zoom}
            defaultZoom={defaultZoom}
            defaultCenter={defaultCenter}
            defaultOptions={{
                disableDefaultUI: true,
                disableDoubleClickZoom: true,
                rotateControl: false,
            }}
        >
            {(props.directions && (props.markers && props.markers.length > 1)) && props.directions && <DirectionsRenderer 
                directions={props.directions}
                options={{
                    suppressInfoWindows: true,
                    suppressMarkers: true,
                    markerOptions: {
                        icon: MarkerIcon,
                    },
                    // polylineOptions: {
                    //     strokeOpacity: 0.5,
                    //     strokeColor: '#FF0000',
                    // },
                }}
            />}
            {showAllMarkers()}
        </GoogleMap>
      </div>
    }
    
    return googleMap();
});


export default GanttchartRouteMap;