import React from "react";

import { 
    OverlayTrigger,
    Popover,
} from "react-bootstrap-v5";
import styled from 'styled-components';


const StyledPopover = styled(Popover)`
    .popover-arrow {
        display: none;
    }

    li {
        &::marker {
            content: '';
        }
    }
`;


const makeDropdownList = (TargetButton) => {
    
    return class extends React.Component {
        
        render() {
            const { children, text, ...rest } = this.props;

            return <OverlayTrigger
                trigger={"click"}
                placement={'bottom'}
                rootClose
                overlay={
                    <StyledPopover>
                        <div className={'p-2'}>{children}</div>
                    </StyledPopover>
                }
            >
                <TargetButton {...rest}>{text}</TargetButton>
            </OverlayTrigger>
        }
    }
}


export default makeDropdownList;
