import React, { Component } from 'react';

import { 
    Box,
    Grid,
} from '@material-ui/core';

import { JobStepType } from '../../../../utils/enums';

import Card from '../../../../components/OLD/Card/Card.jsx';
import CustomToggle from '../../../../components/OLD/Checkbox/CustomToggle.jsx';
import CustomUpDown from '../../../../components/OLD/input/CustomUpDown.jsx';


class OthersSection extends Component {
    
    constructor(props){
        super(props);

        let id = (props.id && props.id > 0) ? props.id : 0;
        let isCopy = ((props.isCopy === true || props.isCopy === false) ? props.isCopy : false);
        let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
        let disabled = ((props.disabled === true || props.disabled === false) ? props.disabled : false);
        let jobType = (props.jobType && props.jobType) ? props.jobType : 0;

        let isBillable = (jobType !== JobStepType.Out) ? false : true;
        let duration = '2';
        
        
        this.state = {
            id: id,
            isCopy: isCopy,
            isLoading: isLoading,
            disabled: disabled,
            jobType: jobType,

            isBillable: isBillable,
            duration: duration,
        }
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.isCopy !== this.state.isCopy) {
            let isCopy = ((nextProps.isCopy === true || nextProps.isCopy === false) ? nextProps.isCopy : false);
            this.setState({
                isCopy: isCopy
            });
        }
        if (nextProps.isLoading !== this.state.isLoading) {
            let isLoading = ((nextProps.isLoading === true || nextProps.isLoading === false) ? nextProps.isLoading : false);
            this.setState({
                isLoading: isLoading
            });
        }
        if (nextProps.disabled !== this.state.disabled) {
            let disabled = ((nextProps.disabled === true || nextProps.disabled === false) ? nextProps.disabled : false);
            this.setState({
                disabled: disabled
            });
        }
        if (nextProps.jobType !== this.state.jobType) {
            let jobType = (nextProps.jobType && nextProps.jobType) ? nextProps.jobType : 0;
            this.setState({
                jobType: jobType
            });
        }
    }


    /* GETTER & SETTER */
    getIsBillable = () => {
        return this.state.isBillable
    }
    setIsBillable = (isBillable = false) => {
        this.setState({
            isBillable: isBillable,
        });
    }
    
    setDuration = (duration = '') => {
        this.setState({
            duration: duration,
        });
    }
    getDuration = () => {
        return this.state.duration
    }
    /* END GETTER & SETTER */


    setOthersForm = () => {
        return <Card>
            <Box pl={{ xs: 0, md: 5 }}>
                <Box clone pb={2} pt={2}>
                    <Grid container alignItems={'center'}>
                        <Box clone pb={{ xs: 1, md: 0 }}>
                            <Grid item xs={12} md={true}>
                                {(this.state.jobType !== JobStepType.Out) ? "I bill my customer for this job type" : "Customer bill me for this job type"}
                            </Grid>
                        </Box>
                        <Box clone>
                            <Grid item xs={true} md={'auto'}>
                                <CustomToggle
                                    checked={this.state.isBillable}
                                    disabled={false}
                                    onChange={(checked) => {
                                        this.setState({
                                            isBillable: checked
                                        });

                                        if(this.props.onChangeIsBillable){
                                            this.props.onChangeIsBillable(checked);
                                        }
                                    }}
                                />
                            </Grid>
                        </Box>
                        <Box clone textAlign={'center'}>
                            <Grid item xs={'auto'} md={1}>&nbsp;</Grid>
                        </Box>
                    </Grid>
                </Box>

                <Box clone pb={2}>
                    <Grid container alignItems={'center'}>
                        <Box clone pb={{ xs: 1, md: 0 }}>
                            <Grid item xs={12} md={true}>
                                Default job duration for this job type
                            </Grid>
                        </Box>
                        <Box clone>
                            <Grid item xs={true} md={'auto'}>
                                <CustomUpDown
                                    value={this.state.duration}
                                    disabled={this.state.disabled}
                                    onUpChange={(value) => {
                                        this.setState({
                                            duration: value
                                        });

                                        if(this.props.onChangeDuration){
                                            this.props.onChangeDuration(value);
                                        }
                                    }}
                                    onDownChange={(value) => {
                                        this.setState({
                                            duration: value
                                        });

                                        if(this.props.onChangeDuration){
                                            this.props.onChangeDuration(value);
                                        }
                                    }}
                                />
                            </Grid>
                        </Box>
                        <Box clone textAlign={'center'}>
                            <Grid item xs={'auto'} md={1}>
                                <b>hours</b>
                            </Grid>
                        </Box>
                    </Grid>
                </Box>
            </Box>
        </Card>
    }


    render() {
        return <Box>
            {this.setOthersForm()}
        </Box>
    }
}

export default OthersSection;
