import React from 'react';
import moment from 'moment';

import Utils from '../../../utils/utils';

import { 
  Row,
  Col,
  Form,
} from "react-bootstrap-v5";

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet } from '../../../../setup/redux/dispatch/actions'


const makeBinTypePullHistory = (Target) => {
      class Component extends React.Component {
        
        constructor(props) {
            super(props);

            this.componentName = 'binTypePullHistory_';

            this.targetRef = React.createRef();


            this.state = {
              
            };
        }


        componentWillReceiveProps(nextProps) {
          this.reduxProps(nextProps);
        }


        /* API */
        reduxProps = nextProps => {
          Utils.reduxProps(nextProps,
            this.componentName + '-service_customer_site_details_bin_waste_type', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = [];

                if(data && data.charges && data.charges.length > 0) {
                    for (var i = 0; i < data.charges.length; i++) {
                        let item = data.charges[i];
                        let value = Utils.makeIDfromString(((item.serviceItemId) ? item.serviceItemId : '') + item.binTypeId + "_" + item.binTypeName + "_" + item.wasteTypeId + "_" + item.wasteTypeName + "_" + item.serviceTag);
                        let binTypeId = item.binTypeId;
                        let binTypeName = item.binTypeName;
                        let wasteTypeId = item.wasteTypeId;
                        let wasteTypeName = item.wasteTypeName;
                        let serviceTag = (item.serviceTag) ? item.serviceTag : '';
                        let binOutDate = (item.binOutDate) ? item.binOutDate : '';
                        let binNumber = (item.binNumber) ? item.binNumber : '';
                        let driverName = (item.driverName) ? item.driverName : '';
                        let vehicleName = (item.vehicleName) ? item.vehicleName : '';
                        let arrItem = {
                            value: value,
                            item: item,
                            binTypeId: binTypeId,
                            binTypeName: binTypeName,
                            wasteTypeId: wasteTypeId,
                            wasteTypeName: wasteTypeName,
                            serviceTag: serviceTag,
                            binOutDate: binOutDate,
                            binNumber: binNumber,
                            driverName: driverName,
                            vehicleName: vehicleName,
                            additionalCharges: (item && item.additionalCharges && item.additionalCharges.length > 0) ? item.additionalCharges : [],
                        };

                        // arr = Utils.addToArrayWithTwoValues(arr, 'binTypeId', binTypeId, 'wasteTypeId', wasteTypeId, arrItem);
                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
          );
          
          Utils.reduxProps(nextProps,
            this.componentName + '-report_bin_type_detail', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = [];

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        
                        let index = (i+1);
                        let serviceTag = Utils.getServiceTagName(item.wasteTypeName, item.binTypeName);
                        let value = Utils.makeIDfromString(index + '_' + item.binTypeId + "_" + item.binTypeName + "_" + item.wasteTypeId + "_" + item.wasteTypeName + "_" + serviceTag);
                        let binTypeId = item.binTypeId;
                        let binTypeName = item.binTypeName;
                        let wasteTypeId = item.wasteTypeId;
                        let wasteTypeName = item.wasteTypeName;
                        let binOutDate = (item.binOutDate) ? item.binOutDate : '';
                        let binNumber = (item.binNumber) ? item.binNumber : '';
                        let driverName = (item.driverName) ? item.driverName : '';
                        let vehicleName = (item.vehicleName) ? item.vehicleName : '';
                        let arrItem = {
                            rid: index,
                            value: value,
                            item: {...item, serviceTag},
                            binTypeId: binTypeId,
                            binTypeName: binTypeName,
                            wasteTypeId: wasteTypeId,
                            wasteTypeName: wasteTypeName,
                            serviceTag: serviceTag,
                            binOutDate: binOutDate,
                            binNumber: binNumber,
                            driverName: driverName,
                            vehicleName: vehicleName,
                            additionalCharges: (item && data.additionalCharges && item.additionalCharges.length > 0) ? item.additionalCharges : [],
                        };

                        // arr = Utils.addToArrayWithTwoValues(arr, 'binTypeId', binTypeId, 'wasteTypeId', wasteTypeId, arrItem);
                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
          );
        }

        callServiceDetailsBinWasteTypeReadApi = (customerSiteId = null, items, callback = null) => {
          this.props.dispatchApiCallGet(null, this.componentName + '-service_customer_site_details_bin_waste_type', 'service/customer/site/' + customerSiteId, { items: items }, callback, () => {});
        }

        callHistoryReadApi = (customerSiteId = null, items, callback = null) => {
          let data = {
              currentPage: 1,
              pageSize: Utils.getMaxPageSize(),
              sortColumn: 'created',
              sortDir: 'asc',
              customerSiteId: customerSiteId,
          };
  
          this.props.dispatchApiCallGet(data, this.componentName + '-report_bin_type_detail', 'report/bin-out-detail', { items: items }, callback, () => {});
        }
        /* END API */


        render() {
          let {
            setFieldValue,
            values,
          } = this.props.formOptions;
          
          return <>
              <Target 
                ref={this.targetRef}
                {...this.props}

                onClick={async () => {
                  if(!values.customFormParams.isServiceOrHistory){
                    await setFieldValue('customFormParams.stepPullBinWasteTypeIsLoading', true);
                    this.callServiceDetailsBinWasteTypeReadApi(values.customerSiteId, values.customFormParams.stepPullBinWasteTypeItems, async (items) => {
                      await setFieldValue('customFormParams.stepPullBinWasteTypeItems', items);
                      await setFieldValue('customFormParams.stepPullBinWasteTypeIsLoading', false);
                    });
                  } else {
                    await setFieldValue('customFormParams.stepPullBinWasteTypeIsLoading', true);
                    this.callHistoryReadApi(values.customerSiteId, values.customFormParams.stepPullBinWasteTypeItems, async (items) => {
                      await setFieldValue('customFormParams.stepPullBinWasteTypeItems', items);
                      await setFieldValue('customFormParams.stepPullBinWasteTypeIsLoading', false);
                    });
                  }
                }}
                renderMenuHeader={(menu) => {
                  return <div className={'custom-mw-800'}>
                    <div className={'ps-3 pe-3 pt-2 pb-2 border-top border-bottom'}>
                      {
                        (!values.customFormParams.isServiceOrHistory)
                        ?
                        <Row className={'gx-5'}>
                          <Col xs={4} className={'border-end'}>Bin type</Col>
                          <Col xs={4} className={'border-end'}>Waste type</Col>
                          <Col xs={4}>Service name</Col>
                        </Row>
                        :
                        <Row className={'gx-5'}>
                          <Col xs={2} className={'border-end'}>Complete date</Col>
                          <Col xs={3} className={'border-end'}>Bin type</Col>
                          <Col xs={3} className={'border-end'}>For Waste type</Col>
                          <Col xs={2} className={'border-end'}>Bin ID</Col>
                          <Col xs={2}>Driver/Vehicle</Col>
                        </Row>
                      }
                    </div>
                    <div>{menu}</div>
                  </div>;
                }}
                renderMenuItem={(label, item) => {
                  if(!values.customFormParams.isServiceOrHistory){
                    return <Row>
                      <Col xs={4}>{item.binTypeName}</Col>
                      <Col xs={4}>{item.wasteTypeName}</Col>
                      <Col xs={4}>{item.serviceTag}</Col>
                    </Row>
                  } else {
                    return <Row>
                      <Col xs={2}>{(item.binOutDate) ? moment(item.binOutDate).format(Utils.getDefaultDateFormat()) : ''}</Col>
                      <Col xs={3}>{item.binTypeName}</Col>
                      <Col xs={3}>{item.wasteTypeName}</Col>
                      <Col xs={2}>{item.binNumber}</Col>
                      <Col xs={2}>
                        <div>{item.driverName}</div>
                        <div>{item.vehicleName}</div>
                      </Col>
                    </Row>
                  }
                }}
                renderExtraFooter={() => {
                  if(!values.customFormParams.stepPullBinWasteTypeIsLoading){
                    return <Row className={'ps-3 pe-3 pt-2 pb-2 border-top align-items-center'}>
                      <Col xs={4}>Select from</Col>
                      <Col xs={true}>
                        <Form.Check
                          inline
                          type={'radio'}
                          id={'services'}
                          name={'jobFormBinTypeRadio1'}
                          label={'Services'}
                          checked={!values.customFormParams.isServiceOrHistory}
                          onChange={async (e) => {
                            await setFieldValue('customFormParams.isServiceOrHistory', false);

                            await setFieldValue('customFormParams.stepPullBinWasteTypeIsLoading', true);
                            this.callServiceDetailsBinWasteTypeReadApi(values.customerSiteId, values.customFormParams.stepPullBinWasteTypeItems, async (items) => {
                              await setFieldValue('customFormParams.stepPullBinWasteTypeItems', items);
                              await setFieldValue('customFormParams.stepPullBinWasteTypeIsLoading', false);
                            });
                          }}
                        />
                        <Form.Check
                          inline
                          type={'radio'}
                          id={'history'}
                          label={'History'}
                          name={'jobFormBinTypeRadio1'}
                          checked={values.customFormParams.isServiceOrHistory}
                          onChange={async (e) => {
                            await setFieldValue('customFormParams.isServiceOrHistory', true);

                            await setFieldValue('customFormParams.stepPullBinWasteTypeIsLoading', true);
                            this.callHistoryReadApi(values.customerSiteId, values.customFormParams.stepPullBinWasteTypeItems, async (items) => {
                              await setFieldValue('customFormParams.stepPullBinWasteTypeItems', items);
                              await setFieldValue('customFormParams.stepPullBinWasteTypeIsLoading', false);
                            });
                          }}
                        />
                      </Col>
                      <Col xs={'auto'}>
                        <a href={'/'}
                          className={'link-secondary d-flex'}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();

                            if(this.props.onClickIcon){
                              this.props.onClickIcon(values.customFormParams.isServiceOrHistory);
                            }
                          }}
                        >
                          <span className={'material-icons'}>open_in_new</span>
                        </a>
                      </Col>
                    </Row>
                  }
                }}
              />
          </>
        }
    }

    const mapDispatchToProps = {
      dispatchApiCallGet,
      clear,
    }
    
    return connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Component)
};

export default makeBinTypePullHistory;