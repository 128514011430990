import React, { useMemo, useState } from "react";

import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from "@stripe/react-stripe-js";

import { 
  Box,
  Grid,
  Button,
  CircularProgress,
} from '@material-ui/core';

import StripeApi from '../../../api/OLD/Stripe/Stripe.jsx';


const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          color: "#484848",
          fontSize: '15px',
          "::placeholder": {
            color: "#CCCCCC"
          }
        },
        invalid: {
          color: "#CF3E3E"
        }
      }
    }),
    []
  );

  return options;
};

const CardForm = (props) => {
  let { item } = props;

  const [isLoading, setIsLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    let amount = (item && item.amount) ? item.amount : 0;
    let description = (item && item.description) ? item.description : '';
    
    stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name: description,
      },
    }).then((result) => {
      if (result.error) {
        let msg = (result && result.error && result.error.message) ? result.error.message : 'Stripe Payment Error!';
        if(props.onPay){
          props.onPay(null, msg);
        }

        setIsLoading(false);
      } else {
        StripeApi.pay(result.paymentMethod.id, parseFloat(amount*100), 'SGD').then((result) => {
          if(props.onPay){
            props.onPay(result, null);
          }

          setIsLoading(false);
        });
      }
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container className={"stripe-pay-fields"}>
        <Box clone pb={2}>
          <Grid item xs={12}>
            <label className="w-100">
              Card number
              <Box p={1} style={{ backgroundColor: '#F7F9FD' }}>
                <CardNumberElement 
                  options={options}
                />
              </Box>
            </label>
          </Grid>
        </Box>
        <Box clone pb={2}>
          <Grid item xs={12}>
            <label>
              Expiration date
              <Box p={1} style={{ backgroundColor: '#F7F9FD' }}>
                <CardExpiryElement 
                  options={options}
                />
              </Box>
            </label>
          </Grid>
        </Box>
        <Box clone>
          <Grid item xs={12}>
            <label style={{ minWidth: '70px' }}>
              CVC
              <Box p={1} style={{ backgroundColor: '#F7F9FD' }}>
                <CardCvcElement 
                  options={options}
                />
              </Box>
            </label>
          </Grid>
        </Box>
        <Box clone pt={3}>
          <Grid item xs={12}>
            <Grid container>
              <Box clone>
                <Grid item xs={6} md={'auto'}>
                  <Button 
                    color={'default'} 
                    variant={'contained'}
                    style={{ marginRight: '10px' }}
                    onClick={() => {
                      if(props.onClose)
                        props.onClose()
                      }
                    }
                  >
                    Cancel
                  </Button>
                </Grid>
              </Box>
              <Box clone pr={{ xs: 0, md: 2 }} textAlign={'right'}>
                <Grid item xs={6} md={true}>
                  <Button 
                    className={'btn-primary'}
                    color={'primary'} 
                    variant={'contained'}
                    type={'submit'}
                    disabled={isLoading}
                  >
                    Pay
                    {isLoading && <span style={{ paddingLeft: '10px' }}><CircularProgress size={15} style={{ color: 'white' }} /></span>}
                  </Button>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </form>
  );
};

export default CardForm;
