import React, { Component } from "react";
import ReactDOM from "react-dom";
import { isMobile } from "react-device-detect";
import _ from 'lodash';

import Utils from "../../../utils/utils";

import jsPDF from "jspdf";
import "jspdf-autotable";

import { CSVLink } from "react-csv";

import { Table, TableContainer } from "@material-ui/core";

// import arrayMove from "array-move";
// import {arrayMoveImmutable} from "array-move";
import moment from "moment";

import {
  TableBody,
} from '@material-ui/core';

import DataTableSkeletonRow from './DataTableSkeletonRow';

import CustomDialog from "../../../components/OLD/Dialog/CustomDialog";

import DataTableBody from "./DataTableBody.jsx";
import DataTableMainHeader from './DataTableMainHeader';
import DataTableRemoveDialog from "./DataTableRemoveDialog";

import ColumnResizer from "column-resizer";

class DataTable extends Component {
  constructor(props) {
    super(props);

    this.resizer = null;

    this.counter = 1;

    this.unit = "pt";
    this.size = "A4";
    this.orientation = "portrait";
    this.margin = 40;

    this.tableContainerRef = React.createRef();
    this.tableRef = React.createRef();
    this.bodyRef = React.createRef();
    this.csvRef = React.createRef();

    let getScrollParent = props.getScrollParent ? props.getScrollParent : null;
    let useWindowScroll = props.useWindowScroll === true || props.useWindowScroll === false ? props.useWindowScroll : true;
    let disableRowClick = props.disableRowClick === true || props.disableRowClick === false ? props.disableRowClick : false;
    let stickyHeader = props.stickyHeader === true || props.stickyHeader === false ? props.stickyHeader : false;
    let stickyFooter = props.stickyFooter === true || props.stickyFooter === false ? props.stickyFooter : true;
    let isDragableHeaderColumns = props.isDragableHeaderColumns === true || props.isDragableHeaderColumns === false ? props.isDragableHeaderColumns : true;
    let isCheckbox = props.isCheckbox === true || props.isCheckbox === false ? props.isCheckbox : false;
    let isDragable = props.isDragable === true || props.isDragable === false ? props.isDragable : false;
    let isPagination = props.isPagination === true || props.isPagination === false ? props.isPagination : false;
    let isHeader = props.isHeader === true || props.isHeader === false ? props.isHeader : true;
    let isBorder = props.isBorder === true || props.isBorder === false ? props.isBorder : false;
    let isLoading = props.isLoading === true || props.isLoading === false ? props.isLoading : false;
    let noMarginBottom = props.noMarginBottom === true || props.noMarginBottom === false ? props.noMarginBottom : false;
    let isVirtualize = props.isVirtualize === true || props.isVirtualize === false ? props.isVirtualize : true;
    let customToolbar = props.customToolbar ? props.customToolbar : null;
    let customHeader = props.customHeader ? props.customHeader : null;
    let customRow = props.customRow ? props.customRow : null;
    let customPagination = props.customPagination ? props.customPagination : null;
    let details = props.details ? props.details : null;
    let customDetails = props.customDetails ? props.customDetails : null;
    let expandDetails = props.expandDetails ? props.expandDetails : null;
    let rowId = props.rowId ? props.rowId : "rowId";
    let columns = props.columns ? props.columns : [];
    let rows = props.rows ? props.rows : [];
    let expandedRows = props.expandedRows ? props.expandedRows : [];
    let disabledResizeColumns = props.disabledResizeColumns ? props.disabledResizeColumns : [];
    let columnVisibility = props.columnVisibility ? props.columnVisibility : new Array(columns.length).fill(true);
    let filters = props.filters ? props.filters : null;
    let groupBy = props.groupBy ? props.groupBy : null;
    let groupByTotal = props.groupByTotal ? props.groupByTotal : null;
    let total = props.total ? props.total : 0;
    let caption = props.caption ? props.caption : "";
    let pageName = props.pageName ? props.pageName : "";
    let orderBy = props.orderBy ? props.orderBy : "";
    let nestedOrderBy = props.nestedOrderBy ? props.nestedOrderBy : "";
    let search = props.search ? props.search : "";
    let verticalAlign = props.verticalAlign ? props.verticalAlign : null;
    let order = props.order ? props.order : "asc";
    let rowsPerPageOptions = props.rowsPerPageOptions ? props.rowsPerPageOptions : [50, 100, 300]; //, { value: -1, label: 'All' }];
    let rowsPerPage = props.rowsPerPage ? props.rowsPerPage : 50;
    let rowsPerPageSelected = props.rowsPerPageSelected ? props.rowsPerPageSelected : 50;
    let minWidthResize = props.minWidthResize ? props.minWidthResize : 100;
    let width = props.width ? props.width : "100%";
    let className = props.className ? props.className : "";
    let removeMessage = props.removeMessage ? props.removeMessage : null;
    let noResult = props.noResult ? props.noResult : null;
    let resizeMode = props.resizeMode ? props.resizeMode : "overflow";
    let tableContainerStyle = props.tableContainerStyle ? props.tableContainerStyle : null;
    let disabledCheckboxes = props.disabledCheckBox ? props.disabledCheckboxes : [];

    this.state = {
      getScrollParent: getScrollParent,
      getScrollParentPom: getScrollParent,
      useWindowScroll: useWindowScroll,
      useWindowScrollPom: useWindowScroll,
      disableRowClick: disableRowClick,
      stickyHeader: stickyHeader,
      stickyFooter: stickyFooter,
      isCheckbox: isCheckbox,
      isDragable: isDragable,
      isDragableHeaderColumns: isDragableHeaderColumns,
      isPagination: isPagination,
      isBorder: isBorder,
      isHeader: isHeader,
      customToolbar: customToolbar,
      customHeader: customHeader,
      customRow: customRow,
      customPagination: customPagination,
      details: details,
      customDetails: customDetails,
      expandDetails: expandDetails,
      rowId: rowId,
      page: 0,
      rowsPerPage: rowsPerPage,
      rowsPerPageOptions: rowsPerPageOptions,
      rowsPerPageSelected: rowsPerPageSelected,
      order: order,
      filters: filters,
      groupBy: groupBy,
      groupByTotal: groupByTotal,
      total: total,
      countSelected: 0,
      disabledCheckboxes: disabledCheckboxes,
      selected: [],
      selectedGroups: [],
      orderBy: orderBy,
      nestedOrderBy: nestedOrderBy,
      search: search,
      verticalAlign: verticalAlign,
      // columns: _.cloneDeep(columns),
      // rows: _.cloneDeep(rows),
      columns: columns,
      rows: rows,
      expandedRows: expandedRows,
      headerHeight: 0,
      width: width,
      className: className,
      removeMessage: removeMessage,
      noResult: noResult,
      isLoading: isLoading,
      noMarginBottom: noMarginBottom,
      caption: caption,
      pageName: pageName,
      minWidthResize: minWidthResize,
      columnVisibility: columnVisibility,
      disabledResizeColumns: disabledResizeColumns,
      tableContainerStyle: tableContainerStyle,
      isVirtualize: isVirtualize,
      resizeMode: isLoading ? "overflow" : resizeMode,
      includeInactive: true,
      selectedAllGroups: false,
      isRemoveDialog: false,
      removeRow: null,
      dateFormat: "DD-MM-YYYY",
      timeFormat: Utils.getDefaultTimeFormat(),
      dateTimeFormat: "DD-MM-YYYY hh:mm a",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.search !== this.state.search) {
      this.setState({
        search: nextProps.search,
      });
    }
    
    if (nextProps.disabledCheckboxes !== this.state.disabledCheckboxes) {
      this.setState({
        disabledCheckboxes: nextProps.disabledCheckboxes,
      });
    }

    if (nextProps.rows !== this.state.rows) {
      let rows = nextProps.rows && nextProps.rows.length > 0 ? nextProps.rows : [];
      this.setState(
        {
          // rows: _.cloneDeep(rows),
          rows: rows,
        },
        () => {
          if (this.props.onInitRows) {
            this.props.onInitRows(this);
          }
        }
      );
    }

    if (nextProps.filters !== this.state.filters) {
      this.setState({
        filters: nextProps.filters,
      });
    }

    if (nextProps.groupBy !== this.state.groupBy) {
      this.setState({
        groupBy: nextProps.groupBy,
      });
    }

    if (nextProps.groupByTotal !== this.state.groupByTotal) {
      this.setState({
        groupByTotal: nextProps.groupByTotal,
      });
    }
    if (nextProps.total !== this.state.total) {
      this.setState({
        total: nextProps.total,
      });
    }

    if (nextProps.rowsPerPageSelected !== this.state.rowsPerPageSelected) {
      this.setState({
        rowsPerPageSelected: nextProps.rowsPerPageSelected,
      });
    }

    if (nextProps.isLoading !== this.state.isLoading) {
      this.setState(
        {
          isLoading: nextProps.isLoading,
          resizeMode: nextProps.isLoading ? "overflow" : "overflow",
          expandedRows: [],
        },
        () => {
          if (this.props.onLoadFinish && !this.state.isLoading) {
            this.props.onLoadFinish(this);
          }
        }
      );
    }

    if (nextProps.columns !== this.state.columns) {
      this.setState({
        // columns: _.cloneDeep(nextProps.columns),
        columns: nextProps.columns,
      });
    }
    if (nextProps.columnVisibility !== this.state.columnVisibility) {
      this.setState({
        columnVisibility: nextProps.columnVisibility,
      });
    }
    let disableRowClick = nextProps.disableRowClick === true || nextProps.disableRowClick === false ? nextProps.disableRowClick : false;
    if (disableRowClick !== this.state.disableRowClick) {
      this.setState({
        disableRowClick: disableRowClick,
      });
    }
    
    let isCheckbox = nextProps.isCheckbox === true || nextProps.isCheckbox === false ? nextProps.isCheckbox : false;
    if (isCheckbox !== this.state.isCheckbox) {
      this.setState({
        isCheckbox: isCheckbox,
      });
    }
  }

  componentDidMount() {
    this.initResizeWindow();
    window.addEventListener("resize", this.initResizeWindow);

    try {
      let header = document.getElementsByClassName("header");

      this.setState({
        headerHeight: header[0].offsetHeight,
      });
    } catch(e){}

    if (this.props.onInit) {
      this.props.onInit(this);
    }

    if (this.props.resizable) {
      this.enableResize();
      this.onResize(false);
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.initResizeWindow);

    if (this.props.resizable) {
      this.disableResize();
    }
  }
  componentDidUpdate(nextProps) {
    if (this.props.resizable) {
      this.enableResize();
    }
  }
  componentWillUpdate(nextProps) {
    if (this.props.resizable) {
      this.disableResize();
    }
  }

  initResizeWindow = () => {
    if (Utils.isSmallScreen()) {
      this.setState({
        useWindowScroll: true,
        getScrollParent: null,
      });
    } else {
      this.setState({
        useWindowScroll: this.state.useWindowScrollPom,
        getScrollParent: this.state.getScrollParentPom,
      });
    }
  };

  enableResize() {
    const options = {
      gripInnerHtml: "<div class='custom-grip'></div>",
      liveDrag: true,
      headerOnly: true,
      serialize: false,
      resizeMode: this.state.resizeMode,
      minWidth: this.state.minWidthResize,
      disabledColumns: this.state.disabledResizeColumns,
      onResize: (e) => {
        e.stopPropagation();
        this.onResize(true);

        if (this.props.onComponentUpdate) {
          this.props.onComponentUpdate();
        }

        if (this.props.onResize) {
          this.props.onResize(null);
        }
      },
    };

    if (!this.resizer) {
      this.resizer = new ColumnResizer(this.tableRef, options);
    } else {
      this.resizer.reset(options);
    }
  }

  disableResize() {
    if (this.resizer) {
      this.resizer.reset({ disable: true });
    }
  }

  onResize = (state = false) => {
    if (this.props.resizable) {
      if (this.state.pageName && this.state.pageName !== "") {
        var ths = this.tableRef.getElementsByTagName("th");
        if (ths && ths.length > 0) {
          // let columns = this.state.columns;
          let columns = _.cloneDeep(this.state.columns);
          for (let i = 0; i < ths.length; i++) {
            let item = ths[i];
            
            let index =
              item && item.dataset && item.dataset.index
                ? item.dataset.index
                : 0;
            let width =
              item.offsetWidth < this.state.minWidthResize
                ? this.state.minWidthResize
                : item.style.width;

            try {
              columns[index].width = width;
            } catch(e){}
          }

          this.setState({
              columns: columns,
          }, () => {
            if (state && this.props.onResize) {
              this.props.onResize(this.state.columns);
            }
          });
        }
      }
    }
  };

  refrsh = () => {
    this.forceUpdate();
  };

  onRealod = (callback = null) => {
    if(this.props.onRealod){
      this.props.onRealod(callback);
    }
  };

  setDisableRowClick = (disableRowClick = false) => {
    this.setState({
      disableRowClick: disableRowClick,
    });
  };

  setSelected = (selected = [], callback = null) => {
    this.setState({
      selected: selected,
    }, () => {
      if(callback){
        callback();
      }
    });
  };
  getDisabledCheckboxes = () => {
    return this.state.disabledCheckboxes;
  };
  getSelected = () => {
    return this.state.selected;
  };
  getSelectedRows = () => {
    let selected = this.state.selected;

    let checkedRows = [];
    if (selected && selected.length > 0) {
      selected.forEach((rowId, i) => {
        let item = this.state.rows.filter((x) => x[this.state.rowId] === rowId);
        if(item && item.length > 0){
          checkedRows.push(item[0])
        }
      });
    }

    return checkedRows;
  };

  getAllIds = () => {
    let rows = this.state.rows;
    if (rows && rows.length > 0) {
      return rows.map(x => x[this.state.rowId]);

    } else {
      return [];
    }
  };

  changeRows = (newRows = []) => {
    if(newRows && newRows.length > 0){
      let rows = this.state.rows;

      newRows.forEach(row => {
        let index = rows.findIndex(x => x[this.state.rowId] === row[this.state.rowId]);
        if(index > -1){
          rows[index] = row;
        }
      });

      this.setState({
        rows: rows,
      }, () => {
        this.refrsh();
      });
    }
  };

  /* EXPORT */
  print = (dataRows = null) => {
    if (this.props.onComponentUpdate) {
      this.props.onComponentUpdate();
    }

    const doc = new jsPDF(this.orientation, this.unit, this.size);
    doc.setProperties({ title: this.state.caption });

    const exportData = this.getExportData(
      dataRows ? dataRows : this.state.rows,
      false
    );

    const header = exportData.header;
    const body = exportData.body;
    
    doc.text(this.state.caption, this.margin, this.margin);
    this.sliceData(20, header, body, doc)

    doc.output("dataurlnewwindow");
  };


  sliceData=(numberSelice ,header, body  ,doc)=> {
    let maxContentRow = numberSelice 
    let bodySlice = body
    let count = 0
    let bodyTemplete =[] 
    let bodyReady = []
    let margin  = 10 

    bodySlice.map((data , i )=>{
      if (count <= maxContentRow  ) {
        bodyTemplete.push(bodySlice[i]);
        if (count === maxContentRow) {
          bodyTemplete.push(bodySlice[i+1]);
          bodyReady.push(bodyTemplete);
          count = count +1 ;
        } else if (i === bodySlice.length - 1) {
          bodyReady.push(bodyTemplete);
          count = count +1 ;
        } else {
          count = count +1 ;
        }
      } else {
        bodyTemplete = [];
 
        count = 0;
        count = count +1 ;
      }


    })
    
    return (

      bodyReady.map((resultBody , i )=>{
        if (i !== 0){
          margin = margin + 900
        } else {
          margin= 10
        }

        return this.ManagePrintLayout( header,resultBody ,doc ,margin)
      })
    )


  }

  ManagePrintLayout = (header, body, doc , thisMargin) => {
    let sliceValue = 6;

    let count = 1;

    let dataHolding = [];
    let RealData = [];

    let mainData = [];
    let mainDataHolding = [];

    let valueY = 0;
    let valueX = 6;

    header.map((rowId, i) => {
      if (count < sliceValue  ) {
          


        dataHolding.push(header[i]);
        count = count +1 ;
        if (count === sliceValue) {
          dataHolding.push(header[i+1]);
          RealData.push(dataHolding);
          // count = count +1 ;
        } else if (i === header.length - 1) {
          RealData.push(dataHolding);
  
        }
      } else {
        dataHolding = [];
 
        count = 0;
        count = count +1 ;
      }

 
    });

    for (let index = 0; index < RealData.length; index++) {
      body.map((jobsData, i) => {
        if(jobsData){
          let bodySlcie = jobsData.slice((valueY ), valueX);
          mainDataHolding.push(bodySlcie);
          if (i === (body.length - 1)) {
            mainData.push(mainDataHolding);
            mainDataHolding = [];
            valueY = valueY + (sliceValue );
            valueX = valueX + (sliceValue  +1);
          }
        }
      });
    }

    return RealData.map((rowId, i) => {
      return (    doc.autoPrint(),

      doc.autoTable(RealData[i], mainData[i], {
        startY: i === 0 ? this.margin + thisMargin : this.margin + 900,
        styles: {
          fontSize: 8,
          minCellWidth: 60,
          minCellHeight: 10,
          margin: 40,
        },
      })

      )
    });
  };

  exportCSV = (dataRows = null) => {
    if (this.props.onComponentUpdate) {
      this.props.onComponentUpdate();
    }

    const exportData = this.getExportData(
      dataRows ? dataRows : this.state.rows,
      true
    );
    const header = exportData.headerCSV;
    const body = exportData.body;

    let data = [].concat([header], body);

    if (data && data.length > 0) {
      let url = this.csvRef.current.buildURI(
        data,
        this.csvRef.current.props.uFEFF,
        null,
        this.csvRef.current.props.separator,
        `"`
      );
      this.csvRef.current.link.href = url;
      this.csvRef.current.link.click();
    }
  };

  exportPDF = (dataRows = null) => {
		Utils.toast(<div style={{ fontWeight: 'bold' }}>Please Wait ...</div>, 'info');
    
    if (this.props.onComponentUpdate) {
      this.props.onComponentUpdate();
    }

    const doc = new jsPDF(this.orientation, this.unit, this.size);
    doc.setProperties({ title: this.state.caption });

    const exportData = this.getExportData(
      dataRows ? dataRows : this.state.rows,
      false
    );
    const header = exportData.header;
    const body = exportData.body;

    doc.text(this.state.caption, this.margin, this.margin);
    this.sliceData(20, header, body, doc)

    doc.save(this.state.caption + ".pdf");
  };

  putExportData = (array, visibleColArr, isCSV = false) => {
    let body = [];

    if (array && array.length > 0) {
      for (let r = 0; r < array.length; r++) {
        let row = array[r];

        let rowArr = [];
        if (this.state.columns && this.state.columns.length > 0) {
          for (let c = 0; c < this.state.columns.length; c++) {
            let coll = this.state.columns[c];

            if (visibleColArr.indexOf(c) !== -1) {
              if (isCSV) {
                if (coll.exportCSVTemplate) {
                  rowArr.push(coll.exportCSVTemplate(row, this, r, coll));
                } else {
                  rowArr.push(row[coll.id]);
                }
              } else {
                if (coll.exportTemplate) {
                  rowArr.push(coll.exportTemplate(row, this, r, coll));
                } else {
                  rowArr.push(row[coll.id]);
                }
              }
            }
          }
        }

        if (rowArr.length > 0) {
          body.push(rowArr);
        }
      }
    }

    return body;
  };

  getExportData = (rows = [], isCSV = false) => {
    const visibleColArr = [];
    const header = [];
    const headerCSV = [];
    let body = [];
    let groups = [];

    if (this.state.columns && this.state.columns.length > 0) {
      for (let c = 0; c < this.state.columns.length; c++) {
        let coll = this.state.columns[c];

        if (
          this.state.columnVisibility[c] === false ||
          coll.exportVisible === false
        ) {
          //nop
        } else {
          visibleColArr.push(c);
          header.push(
            coll.exportLabel
              ? coll.exportLabel
              : coll.label
              ? coll.label
              : coll.id
              ? coll.id
              : ""
          );
          headerCSV.push(coll.label ? coll.label : coll.id ? coll.id : "");
        }
      }
    }

    body = this.putExportData(rows, visibleColArr, isCSV);

    return {
      visibleColumns: visibleColArr,
      header: header,
      headerCSV: headerCSV,
      body: body,
      groups: groups,
    };
  };
  /* END EXPORT */

  /* DATE FORMAT */
  dateFormat = (date, format = "") => {
    return moment(date).format(format !== "" ? format : this.state.dateFormat);
  };

  timeFormat = (time, format = "") => {
    return moment(time).format(format !== "" ? format : this.state.timeFormat);
  };

  dateTimeFormat = (dateTime, format = "") => {
    return moment(dateTime).format(
      format !== "" ? format : this.state.dateTimeFormat
    );
  };
  /* END DATE FORMAT */

  handleRequestSort = (event, property, nested, callback = null) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";

    this.setState(
      {
        order: isAsc ? "desc" : "asc",
        orderBy: property,
        nestedOrderBy: nested,
      },
      () => {
        if (callback) {
          callback(
            this.state.order,
            this.state.orderBy,
            this.state.nestedOrderBy
          );
        }
      }
    );
  };

  handleCustomSelectAllClick = (event, callback = null) => {
    if (event.target.checked) {
      const newSelecteds = this.state.rows.map((n) => {
        return callback(n);
      });

      this.setState({
        selected: newSelecteds,
        countSelected: newSelecteds.length,
      });

      if (this.state.groupBy !== null) {
        this.setState({
          selectedAllGroups: true,
        });
      }
      return;
    }
    this.setState({
      selected: [],
      selectedGroups: [],
      selectedAllGroups: false,
    });
  };

  handleSelectAllClick = (event) => {
    if (this.props.onSelectAll) {
      this.props.onSelectAll(this);
    }

    if (event.target.checked) {
      const newSelecteds = this.state.rows.map((n) => n[this.state.rowId]);

      this.setState({
        selected: this.state.selected.length > 0 ? [] : newSelecteds,
      }, () => {
        if (this.props.onClickCheckAll) {
          let indeterminate = JSON.parse(event.target.dataset.indeterminate);
          let checked = event.target.checked;
          
          this.props.onClickCheckAll(this, event.target.checked, (!indeterminate ? !checked : true), (this.state.selected && this.state.selected.length > 0) ? this.state.selected.length : 0);
        }
      });

      if (this.state.groupBy !== null) {
        this.setState(
          {
            selectedAllGroups: true,
          },
          () => {
            if (this.props.onCheckAll) {
              this.props.onCheckAll(this, (this.state.selected && this.state.selected.length > 0) ? this.state.selected.length : 0);
            }
          }
        );
      }
      return;
    } else {
      if (this.props.onClickCheckAll) {
        let indeterminate = JSON.parse(event.target.dataset.indeterminate);
        let checked = event.target.checked;
        
        this.props.onClickCheckAll(this, event.target.checked, (!indeterminate ? !checked : true), (this.state.selected && this.state.selected.length > 0) ? this.state.selected.length : 0);
      }
    }

    this.setState(
      {
        selected: [],
        selectedGroups: [],
        selectedAllGroups: false,
      },
      () => {
        if (this.props.onCheckAll) {
          this.props.onCheckAll(this, (this.state.selected && this.state.selected.length > 0) ? this.state.selected.length : 0);
        }
      }
    );
  };

  customRowClick = (id, row) => {
    if (this.props.onCustomRowClick) {
      this.props.onCustomRowClick(id, row, this);
    }
  };

  handleRowClickProgramaticly = (id, row, value) => {
    this.props.onRowClick(id, row, this, value);
  };
  handleClick = (event, id, row) => {
    if (!this.state.disableRowClick && this.props.onRowClick) {
      this.props.onRowClick(id, row, this);
    }
  };
  handleColClick = (event, id, row) => {
    if (!this.state.disableRowClick && this.props.onRowClick && this.props.onColClick) {
      this.props.onColClick(id, row, this);
    }
  };

  handleColumnClick = (id, row, col, obj, index, field, value) => {
    this.props.onColumnClick(id, row, col, obj, index, field, value);
  };

  handleCBClick = (event, id, row, groupIndex) => {
    event.stopPropagation();

    if (this.props.onComponentUpdate) {
      this.props.onComponentUpdate();
    }

    if (this.state.isCheckbox) {
      if (
        this.state.groupBy === null ||
        typeof this.state.groupBy === "undefined"
      ) {
        const selectedIndex = this.state.selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
          newSelected = newSelected.concat(this.state.selected, id);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(this.state.selected.slice(1));
        } else if (selectedIndex === this.state.selected.length - 1) {
          newSelected = newSelected.concat(this.state.selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            this.state.selected.slice(0, selectedIndex),
            this.state.selected.slice(selectedIndex + 1)
          );
        }

        this.setState(
          {
            selected: newSelected,
            countSelected: newSelected.length,
          },
          () => {
            if (this.props.onCheckRow) {
              this.props.onCheckRow(this, id, !(this.state.selected && this.state.selected.length > 0 ? true : false), (this.state.selected && this.state.selected.length > 0) ? this.state.selected.length : 0);
            }
          }
        );
      } else {
        let selectedGroupArr = this.state.selectedGroups;

        if (!selectedGroupArr[groupIndex]) {
          selectedGroupArr[groupIndex] = [];
        }

        if (event.target.checked) {
          if (selectedGroupArr[groupIndex].indexOf(id) === -1) {
            selectedGroupArr[groupIndex].push(id);
          }
        } else {
          if (selectedGroupArr[groupIndex].indexOf(id) !== -1) {
            let index = selectedGroupArr[groupIndex].indexOf(id);
            selectedGroupArr[groupIndex].splice(index, 1);
          }
        }

        let selectedArr = [];
        if (selectedGroupArr.length > 0) {
          for (let i = 0; i < selectedGroupArr.length; i++) {
            if (selectedGroupArr[i] && selectedGroupArr[i].length > 0) {
              for (let j = 0; j < selectedGroupArr[i].length; j++) {
                selectedArr.push(selectedGroupArr[i][j]);
              }
            }
          }
        }

        this.setState({
          selectedGroups: selectedGroupArr,
          selected: selectedArr,
        });
      }
    }
  };

  handleCBChange = (event, isChecked, id, row) => {
    if (this.props.onRowCheckBoxChange) {
      this.props.onRowCheckBoxChange(event, isChecked, id, row, this);
    }
  };

  isSelectedAll = () => {
    return this.state.countSelected > 0 &&
      this.state.countSelected === this.state.rows.length - 1
      ? true
      : false;
  };

  hasChecked = () => {
    return (this.state.selected && this.state.selected.length > 0) ? true : false;
  };

  handleChangePage = (event, newPage) => {
    this.setState(
      {
        page: newPage,
      },
      () => {
        if (this.props.onChangePage) {
          this.props.onChangePage(this.state.page);
        }
      }
    );
  };

  handleChangeRowsPerPage = (event) => {
    this.setState(
      {
        rowsPerPageSelected: event.target.value,
        rowsPerPage: +event.target.value,
        page: 0,
      },
      () => {
        if (this.props.onChangeRowsPerPage) {
          this.props.onChangeRowsPerPage(this.state.rowsPerPageSelected);
        }
      }
    );
  };

  handleChangePageCustom = (page) => {
    this.setState({
      page: page,
    });
  };

  handleChangeRowsPerPageCustom = (value) => {
    this.setState({
      rowsPerPageSelected: value,
      rowsPerPage: +value,
      page: 0,
    });
  };

  isSelected = (id, row) => {
    if (this.state.isCheckbox) {
      return this.state.selected.indexOf(id) !== -1;
    } else {
      return false;
    }
  };

  isDisabledCheckbox= (id, row) => {
    if (this.state.isCheckbox) {
      return (this.state.disabledCheckboxes && this.state.disabledCheckboxes.length > 0) ? this.state.disabledCheckboxes.indexOf(id) !== -1 : false;
    } else {
      return false;
    }
  };

  setCol = (col, row, i) => {
    try {
      if (col.template) {
        return <div className={'custom-cell-line-3'}>{col.template(row[this.state.rowId], row, col, this, i)}</div>;
      } else {
        return row[col.id] !== "" ? (
          <div className={'custom-cell-line-3'} title={row[col.id]}>{row[col.id]}</div>
        ) : col.placeholder ? (
          <span className="col-muted">{col.placeholder}</span>
        ) : (
          ""
        );
      }
    } catch (err) {
      return null;
    }
  };

  setResponsiveColHead = (col, row) => {
    if (typeof col.responsiveLabel === "undefined") {
      return (
        <div className="responsive-title">
          <b>{col.label}</b>
        </div>
      );
    } else if (col.responsiveLabel === "") {
      return null;
    } else {
      return (
        <div className="responsive-title">
          <b>
            {col.responsiveLabel && col.responsiveLabel !== "" ? (
              col.responsiveLabel
            ) : (
              <>&nbsp;</>
            )}
          </b>
        </div>
      );
    }
  };

  setCustomToolbar = () => {
    if (this.state.customToolbar) {
      return (
        <div className={"table-toolbar"}>
          {this.state.customToolbar(this, this.state, this.props)}
        </div>
      );
    } else {
      return null;
    }
  };
  setDetails = (row, props, isItemSelected) => {
    if (this.state.details) {
      return this.state.details(
        row,
        props,
        this,
        this.state,
        this.props,
        isItemSelected
      );
    } else {
      return null;
    }
  };

  expandAllRowsDiffMinus1 = (expandAll = false, field = null, value = null) => {
    let expandedRows = this.state.expandedRows;

    if (!expandAll) {
      expandedRows = [-1];
    } else {
      if (this.state.rows && this.state.rows.length > 0) {
        this.state.rows.forEach((x, i) => {
          if (field && value) {
            if (x[field] === value) {
              if (!Utils.isInArray(expandedRows, x[this.state.rowId])) {
                expandedRows.push(x[this.state.rowId]);
              }
            }
          } else {
            if (!Utils.isInArray(expandedRows, x[this.state.rowId])) {
              expandedRows.push(x[this.state.rowId]);
            }
          }
        });
      }
    }

    this.setState(
      {
        expandedRows: expandedRows,
      },
      () => {
        this.refrsh();
      }
    );
  };
  expandAllRows = (expandAll = false) => {
    let expandedRows = this.state.expandedRows;

    if (!expandAll) {
      expandedRows = [];
    } else {
      if (this.state.rows && this.state.rows.length > 0) {
        this.state.rows.forEach((x, i) => {
          if (!Utils.isInArray(expandedRows, x[this.state.rowId])) {
            expandedRows.push(x[this.state.rowId]);
          }
        });
      }
    }

    this.setState(
      {
        expandedRows: expandedRows,
      },
      () => {
        this.refrsh();
      }
    );
  };
  collapseAllRows = (callback = null) => {
    this.setState({
      expandedRows: [],
    }, () => {
      if(callback){
        callback();
      }
    });
  };
  expandSingleRow = (id) => {
    let expandedRows = this.state.expandedRows;

    if (!Utils.isInArray(expandedRows, id)) {
      expandedRows = [];
      expandedRows.push(id);
    } else {
      expandedRows = [];
    }

    this.setState({
      expandedRows: expandedRows,
    });
  };
  expandRow = (id) => {
    let expandedRows = this.state.expandedRows;

    if (!Utils.isInArray(expandedRows, id)) {
      expandedRows.push(id);
    } else {
      Utils.removeFromArray(expandedRows, id);
    }

    this.setState({
      expandedRows: expandedRows,
    });
  };
  isRowExpanded = (id) => {
    return Utils.isInArray(this.state.expandedRows, id);
  };
  hasExpandedRows = () => {
    return this.state.expandedRows && this.state.expandedRows.length > 0
      ? true
      : false;
  };
  hasAllExpandedRows = () => {
    return this.state.expandedRows && this.state.expandedRows.length > 0
      ? this.state.rows && this.state.rows.length > 0
        ? this.state.expandedRows.length === this.state.rows.length
          ? true
          : false
        : false
      : false;
  };

  expandRowByClass = (cls = "", id = "", clsName = "", callback = null) => {
    var el = document.querySelectorAll("." + cls + id);
    if (el && el.length > 0) {
      const hasCls = el[0].classList.contains(clsName);
      if (hasCls) {
        for (let i = 0; i < el.length; i++) {
          el[i].classList.remove(clsName);
        }

        if (callback) {
          callback(false);
        }
      } else {
        for (let i = 0; i < el.length; i++) {
          el[i].classList.add(clsName);
        }

        if (callback) {
          callback(true);
        }
      }
    }
  };
  addClassToExpandedRow = (cls = "", id = "", clsName = "", state = false) => {
    var el = document.querySelectorAll("." + cls + id);
    if (el && el.length > 0) {
      if (state) {
        for (let i = 0; i < el.length; i++) {
          el[i].classList.add(clsName);
        }
      } else {
        for (let i = 0; i < el.length; i++) {
          el[i].classList.remove(clsName);
        }
      }
    }
  };
  opacityNotExpandedRows = () => {
    var el = document.querySelectorAll(".visible-details-row");
    if (el && el.length > 0) {
      let rows = document.querySelectorAll(".row");
      if (rows && rows.length > 0) {
        for (let i = 0; i < rows.length; i++) {
          rows[i].classList.add("row-opacity");
        }
      }
    } else {
      let rows = document.querySelectorAll(".row");
      if (rows && rows.length > 0) {
        for (let i = 0; i < rows.length; i++) {
          rows[i].classList.remove("row-opacity");
        }
      }
    }
  };

  flash = (row, anim = "highlight") => {
    setTimeout(() => {
      try {
        let el = ReactDOM.findDOMNode(
          this.tableContainerRef
        ).getElementsByClassName("row_" + row[this.state.rowId]);
        el[0].classList.add(anim);
        setTimeout(() => {
          try {
            el[0].classList.remove(anim);
          } catch (err) {}
        }, 1500);
      } catch (err) {}
    }, 500);
  };

  getClonedRow = (row) => {
    return JSON.parse(JSON.stringify(row));
  };
  getMax = (array, col) => {
    return Math.max.apply(
      Math,
      array.map(function (o) {
        return o[col];
      })
    );
  };
  getNewId = () => {
    return this.getMax(this.state.rows, this.state.rowId) + 1;
  };
  getStartIndex = (array, row) => {
    return array.indexOf(row) + 1;
  };
  setSplice = (array, row, clonedRow) => {
    return array.splice(this.getStartIndex(array, row), 0, clonedRow);
  };
  copy = (row, callback = null) => {
    let clonedRow = this.getClonedRow(row);
    clonedRow[this.state.rowId] = this.getNewId();
    this.setSplice(this.state.rows, row, clonedRow);

    if (callback !== null) {
      callback(clonedRow, this.state.rows);
    }

    this.flash(clonedRow);
  };
  create = (row, callback = null) => {
    this.state.rows.splice(0, 0, row);

    if (callback !== null) {
      callback(row, this.state.rows);
    }

    this.flash(row);
  };
  update = (row, callback = null) => {
    let rows = [...this.state.rows];
    let index = rows.findIndex(
      (x) => x[this.state.rowId] === row[this.state.rowId]
    );

    rows[index] = row;

    if (callback !== null) {
      callback(row, rows, index);
    }

    this.flash(row);
  };
  customUpdate = (row, mainId, nestedArray, rowIdCoulumn, callback = null) => {
    let rows = [...this.state.rows];

    let mainIndex = -1;
    if (rows && rows.length > 0) {
      mainIndex = rows.findIndex((x) => x[this.state.rowId] === mainId);

      let subIndex = -1;
      if (
        rows &&
        rows[mainIndex] &&
        rows[mainIndex][nestedArray] &&
        rows[mainIndex][nestedArray].length > 0
      ) {
        subIndex = rows[mainIndex][nestedArray].findIndex(
          (x) => x[rowIdCoulumn] === row[rowIdCoulumn]
        );

        rows[mainIndex][nestedArray][subIndex] = row;
      }
    }

    if (callback !== null) {
      callback(row, rows);
    }

    this.flash(row);
  };

  remove = (row) => {
    this.setState({
      isRemoveDialog: true,
      removeRow: row,
    });
  };

  toggleAllDetails = () => {
    this.bodyRef.current.toggleAllDetails();
  };
  hideAllDetails = () => {
    this.bodyRef.current.hideAllDetails();
  };
  showAllDetails = () => {
    this.bodyRef.current.showAllDetails();
  };

  getRowIdName = () => {
    return this.state.rowId;
  };

  setHeader = () => {
    if (this.state.isHeader) {
      if (this.state.customHeader) {
        return this.state.customHeader(this, this.props, this.state);
      } else {
        return <DataTableMainHeader
          obj={this}
          state={this.state}
          onSortEnd={(columns, columnVisibility) => {
            if(this.props.onSortEnd){
              this.props.onSortEnd(columns, columnVisibility);
            }
          }}
          onOrder={(name, sort) => {
            if(this.props.onOrder){
              this.props.onOrder(name, sort);
            }
          }}
        />;
      }
    } else {
      return null;
    }
  };

  setBody = () => {
    return (
      <DataTableBody
        ref={this.bodyRef}
        rows={this.state.rows}
        order={this.state.order}
        orderBy={this.state.orderBy}
        nestedOrderBy={this.state.nestedOrderBy}
        page={this.state.page}
        rowsPerPage={this.state.rowsPerPage}
        rowsPerPageSelected={this.state.rowsPerPageSelected}
        search={this.state.search}
        rowId={this.state.rowId}
        isCheckbox={this.state.isCheckbox}
        isDragable={this.state.isDragable}
        isPagination={this.state.isPagination}
        isBorder={this.state.isBorder}
        columns={this.state.columns}
        columnVisibility={this.state.columnVisibility}
        includeInactive={this.state.includeInactive}
        noResult={this.state.noResult}
        filters={this.state.filters}
        groupBy={this.state.groupBy}
        groupByTotal={this.state.groupByTotal}
        verticalAlign={this.state.verticalAlign}
        selected={this.state.selected}
        selectedGroups={this.state.selectedGroups}
        selectedAllGroups={this.state.selectedAllGroups}
        isLoading={this.state.isLoading}
        customRow={this.state.customRow}
        details={this.setDetails}
        customDetails={this.state.customDetails}
        expandDetails={this.state.expandDetails}
        expandedRows={this.state.expandedRows}
        getScrollParent={this.state.getScrollParent}
        useWindowScroll={this.state.useWindowScroll}
        isVirtualize={this.state.isVirtualize}
        isSelected={this.isSelected}
        isDisabledCheckbox={this.isDisabledCheckbox}
        handleClick={this.handleClick}
        onColClick={this.handleColClick}
        handleCBClick={this.handleCBClick}
        handleCBChange={this.handleCBChange}
        customCheckBoxRow={this.props.customCheckBoxRow}
        customCheckBoxHead={this.props.customCheckBoxHead}
        setCol={this.setCol}
        setResponsiveColHead={this.setResponsiveColHead}
        onRowSortEnd={(oldIndex, newIndex) => {
          if(this.props.onRowSortEnd){
            this.props.onRowSortEnd(oldIndex, newIndex);
          }
        }}
      />
    );
  };

  setPagination = () => {
    if (this.state.isPagination) {
      if (this.state.customPagination) {
        return (
          <div
            className={
              "custom-pagination " + (this.state.stickyFooter ? "fixed" : "")
            }
          >
            {this.state.customPagination(this, this.props, this.state)}
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  setRemoveDialog = () => {
    return (
      <CustomDialog
        open={this.state.isRemoveDialog}
        maxWidth={"lg"}
        onClose={() => {
          this.setState({
            isRemoveDialog: false,
            removeRow: null,
          });
        }}
      >
        <DataTableRemoveDialog
          removeMessage={this.state.removeMessage}
          onClose={() => {
            this.setState({
              isRemoveDialog: false,
              removeRow: null,
            });
          }}
          onOk={() => {
            if (this.props.onRemove) {
              this.props.onRemove(this.state.removeRow);
            }

            this.setState({
              isRemoveDialog: false,
              removeRow: null,
            });
          }}
        />
      </CustomDialog>
    );
  };

  render() {
    return (
      <div className={"table-container-parent " + this.props.classNameWrapper}>
        {this.setCustomToolbar()}
        <TableContainer
          className={
            "table-container " + (this.state.noMarginBottom ? "no-mb" : "")
          }
          ref={(r) => {
            this.tableContainerRef = r;
          }}
          style={this.state.tableContainerStyle}
        >
          <Table
            ref={(r) => {
              this.tableRef = r;
            }}
            className={
              "datatable-component " +
              this.state.className +
              (this.props.onRowClick ? " row-currsor-hand" : "")
            }
            stickyHeader={this.state.stickyHeader}
            size={"medium"}
            style={{
              width: isMobile ? "100%" : this.state.width,
            }}
          >
            {this.setHeader()}
            {
              (this.state.isLoading)
              ?
              <TableBody>
                <DataTableSkeletonRow 
                  isCheckbox={this.state.isCheckbox}
                  isDragable={this.state.isDragable}
                  isBorder={this.state.isBorder}
                  expandDetails={this.state.expandDetails}
                  columns={this.state.columns}
                  columnVisibility={this.state.columnVisibility}
                  height={42}
                />
              </TableBody>
              :
              this.setBody()
            }
          </Table>
        </TableContainer>

        <CSVLink
          ref={this.csvRef}
          data={[]}
          filename={this.state.caption + ".csv"}
          target="_blank"
          style={{
            visibility: "hidden",
            opacity: "0",
          }}
        ></CSVLink>
        {this.setPagination()}
        {this.setRemoveDialog()}
      </div>
    );
  }
}

export default DataTable;
