import React, { Component } from 'react';


import { 
    Row,
    Col
  } from "react-bootstrap-v5";

import Utils from '../../../utils/utils';

import { CheckPicker, Checkbox } from 'rsuite';
import "rsuite/dist/rsuite.min.css";


class RSuiteCheckPicker extends Component {
  
	constructor(props){
		super(props);
        
        this.selectRef = React.createRef();

        let menuClassName = props.menuClassName ? props.menuClassName : '';
        let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
        let disabled = ((props.disabled === true || props.disabled === false) ? props.disabled : false);
        let shouldClose = ((props.shouldClose === true || props.shouldClose === false) ? props.shouldClose : true);
        let searchable = ((props.searchable === true || props.searchable === false) ? props.searchable : true);
        let countable = ((props.countable === true || props.countable === false) ? props.countable : false);
        let cleanable = ((props.cleanable === true || props.cleanable === false) ? props.cleanable : true);
        let readOnly = ((props.readOnly === true || props.readOnly === false) ? props.readOnly : false);
        let showSelectAll = ((props.showSelectAll === true || props.showSelectAll === false) ? props.showSelectAll : false);
        let isSelectedAll = ((props.isSelectedAll === true || props.isSelectedAll === false) ? props.isSelectedAll : false);
        let isDefault = ((props.isDefault === true || props.isDefault === false) ? props.isDefault : true);
        let items = props.items ? props.items : [];
        let value = (props.value && props.value.length > 0) ? props.value : [];
        let id = props.id;
        let placeholder = props.placeholder ? props.placeholder : '';
        let placement = props.placement ? props.placement : 'bottomStart';
        let labelKey = props.labelKey ? props.labelKey : 'title';
        let valueKey = props.valueKey ? props.valueKey : 'value';

		this.state = {
            menuClassName: menuClassName,
            items: items,
            value: value,
            id: id,
            placeholder: placeholder,
            labelKey: labelKey,
            valueKey: valueKey,
            isLoading: isLoading,
            disabled: disabled,
            shouldClose: shouldClose,
            searchable: searchable,
            countable: countable,
            cleanable: cleanable,
            placement: placement,
            readOnly: readOnly,
            showSelectAll: showSelectAll,
            isSelectedAll: isSelectedAll,
            isDefault: isDefault,
            isOpen: false,
            isMenuOpen: false,
		}
	}
    

    componentDidMount(){
        document.body.addEventListener('click', this.onOutsiteClickListener);

        if(this.props.onInit){
            this.props.onInit();
        }
    }
    
    componentWillUnmount(){
        document.body.removeEventListener('click', this.onOutsiteClickListener);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.items !== this.state.items) {
            let items = nextProps.items ? nextProps.items : [];
			this.setState({
				items: items
			});
        }
        
        if (nextProps.value !== this.state.value) {
            let value = (nextProps.value && nextProps.value.length > 0) ? nextProps.value : [];
			this.setState({
				value: value
			});
        }
        
        if (nextProps.shouldClose !== this.state.shouldClose) {
            let shouldClose = ((nextProps.shouldClose === true || nextProps.shouldClose === false) ? nextProps.shouldClose : true);
			this.setState({
				shouldClose: shouldClose
			});
		}

        if (nextProps.isLoading !== this.state.isLoading) {
            let isLoading = ((nextProps.isLoading === true || nextProps.isLoading === false) ? nextProps.isLoading : false);
			this.setState({
				isLoading: isLoading
			});
		}

        if (nextProps.readOnly !== this.state.readOnly) {
            let readOnly = ((nextProps.readOnly === true || nextProps.readOnly === false) ? nextProps.readOnly : false);
			this.setState({
				readOnly: readOnly
			});
		}

        if (nextProps.isSelectedAll !== this.state.isSelectedAll) {
            let isSelectedAll = ((nextProps.isSelectedAll === true || nextProps.isSelectedAll === false) ? nextProps.isSelectedAll : false);
			this.setState({
				isSelectedAll: isSelectedAll
			});
		}

        if (nextProps.disabled !== this.state.disabled) {
            let disabled = ((nextProps.disabled === true || nextProps.disabled === false) ? nextProps.disabled : false);
			this.setState({
				disabled: disabled
			});
		}
    }


    onOutsiteClickListener = (e) => {
        try {
            if(this.state.isOpen && this.state.shouldClose && this.state.isMenuOpen){
                var path = e.path || (e.composedPath && e.composedPath());
  
                if(path && path.length > 0){
                    let state = false;

                    for(let i = 0; i < path.length; i++){
                        let cls = (path[i] && path[i].className && path[i].className !== '') ? path[i].className : '';
                        if(this.state.id){
                            if((cls.includes(this.state.id)) || cls.includes("rs-picker-check-menu")){
                                state = true;
                                break;
                            }
                        } else {
                            if(cls.includes("rs-picker-check-menu") || cls.includes("rs-btn-default")){
                                state = true;
                                break;
                            }
                        }
                    }

                    if(!state){
                        this.handleClose();
                    } else {
                        return;
                    }
                } else {
                    return;
                }
            } else {
                return;
            }
        } catch(err) {
            return;
        }
    }


    handleClick = () => {
        if(this.state.disabled || this.state.readOnly){

        } else {
            this.setState({
                isOpen: true,
            });

            if(this.props.onClick){
                this.props.onClick();
            }
        }
    }
    handleOpen = () => {
        this.setState({
            isMenuOpen: true,
        }, () => {
            if(this.state.searchable){
                try {
                    let inputElement = document.getElementsByClassName('rs-picker-search-bar-input')[0];
                    inputElement.focus();
                } catch(e){}
            }
    
            if(this.props.onOpen){
                this.props.onOpen();
            }
        });
    }

    handleClose = () => {
        if(this.state.shouldClose){
            this.setState({
                isMenuOpen: false,
                isOpen: false,
            });
        }
    }

    handleSearch = (value) => {
        if(this.props.onSearch){
            this.props.onSearch(value);
        }
    }
    
    handleSelect = (item, value) => {
        // this.setState({
        //     value,
        // }, () => {
        //     // this.handleClose();

        //     if(this.props.onSelect){
        //         this.props.onSelect(value, item);
        //     }
        // });
    }
    
    handleChange = (values, e) => {
        let ariaChecked = e.target.getAttribute('aria-checked');
        let checked = !Utils.strToBoolLowerCase(ariaChecked);

        this.setState({
            values,
        }, () => {
            // this.handleClose();

            if(this.props.onChange){
                this.props.onChange(values, this.state.items, e.target.value, checked, this.state.items.filter(x => values.includes(x[this.state.valueKey])));
            }
        });
    }
    
    handleClean = (e) => {
        this.setState({
            value: [],
        }, () => {
            this.handleClose();

            if(this.props.onClear){
                this.props.onClear();
            }
        });
    }
    
    handleSearchBy = (keyword, label, item) => {
        if(keyword == ''){
            return true;
        } else {
            return label.toLowerCase().includes(keyword.toLowerCase())
        }
    }
    

	render() {
		return <div className={'custom-rsuite-checktree-picker ' + (this.state.isDefault ? 'default-look ' : '') + this.props.className + (this.state.readOnly ? ' read-only' : '') + (this.state.isMenuOpen ? ' menu-is-open' : '') + (this.state.id ? ' ' + this.state.id : '')}>
            <CheckPicker
                ref={this.selectRef}
                menuClassName={'custom-menu-checkpicker ' + this.state.menuClassName}
                data={this.state.isLoading ? [] : this.state.items}
                placeholder={this.state.placeholder}
                value={this.state.value}
                disabled={this.state.disabled}
                labelKey={this.state.labelKey}
                valueKey={this.state.valueKey}
                open={this.state.isOpen}
                searchable={this.state.searchable}
                countable={this.state.countable}
                cleanable={this.state.cleanable}
                placement={this.state.placement}
                readOnly={this.state.readOnly}
                block={true}
                virtualized={false}
                preventOverflow={true}
                onClick={this.handleClick}
                onOpen={this.handleOpen}
                onClose={this.handleClose}
                onSearch={this.handleSearch}
                onSelect={this.handleSelect}
                onChange={this.handleChange}
                onClean={this.handleClean}
                searchBy={this.handleSearchBy}
                renderValue={this.props.renderValue}
                disabledItemValues={this.props.disabledItemValues}
                
                renderMenuItem={(label, item) => {
                    if(this.props.renderMenuItem){
                        return this.props.renderMenuItem(label, item);
                    } else {
                        return <>{label}</>;
                    }
                }}

                renderMenu={menu => {
                    return <>
                        {
                            (this.state.isLoading)
                            ?
                            <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>Loading...</p>
                            :
                                (this.state.items.length === 0)
                                ?
                                <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>No Result!</p>
                                :
                                    this.props.renderMenuHeader
                                    ?
                                    this.props.renderMenuHeader(menu)
                                    :
                                    menu
                        }
                    </>
                }}
                renderExtraFooter={() => {
                    if(this.state.showSelectAll){
                        return <div
                            style={{
                                paddingLeft: '2px',
                                paddingRight: '2px',
                            }}
                        >
                            <hr className={'mt-1 mb-1'} />
                            <Row className={'align-items-center'}>
                                <Col xs={true}>
                                    <Checkbox 
                                        className={'custom-select-all-cb w-100'}
                                        disabled={this.state.isLoading}
                                        checked={this.state.isSelectedAll}
                                        indeterminate={(this.state.items.length > 0) && (this.state.value.length > 0) && (this.state.value.length < this.state.items.length)}
                                        onChange={(value, checked) => {
                                            if(this.props.onSelectAll){
                                                this.props.onSelectAll(checked, ((this.state.items.length > 0) && (this.state.value.length > 0) && (this.state.value.length < this.state.items.length)));
                                            }
                                        }}
                                    >
                                        <div className="position-relative d-flex align-items-center">
                                            <div style={{ flex: '0 1 88%' }}>Select all</div>
                                            <div style={{ flex: '0 1 12%' }}>
                                                {(this.props.allCount && !this.state.isLoading) && <span 
                                                    className={"ms-3 badge rounded-pill bg-primary"}
                                                    style={{
                                                        padding: '3px 8px',
                                                        fontSize: '11px',
                                                    }}
                                                >{this.props.allCount()}</span>}
                                            </div>
                                        </div>
                                    </Checkbox>
                                </Col>
                                {this.props.customElement && <Col xs={'auto'}>
                                    {this.props.customElement}
                                </Col>}
                            </Row>
                        </div>
                    } else {
                        if(this.props.renderExtraFooter){
                            return this.props.renderExtraFooter();
                        } else {
                            return null;
                        }
                    }
                }}
            />
		</div>;
	}
}

export default RSuiteCheckPicker;