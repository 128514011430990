import React from 'react';

import {
    Box,
    IconButton,
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
} from '@material-ui/core';

import { 
  Spinner,
} from "react-bootstrap-v5";


const CustomPopper = props => {
    const { icon, items, className, classNamePopper } = props;

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);


    const handleToggle = (e) => {
      e.preventDefault();
      e.stopPropagation();
      
      setOpen(prevOpen => !prevOpen);

      if(!open){
        if(props.onOpen){
          props.onOpen();
        }
      }
    };
  
    const handleClose = event => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
  
      setOpen(false);
    };


    const prevOpen = React.useRef(open);
    React.useEffect(() => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
      }

      prevOpen.current = open;
    }, [open]);
    

    const setItems = () => {
      if(!props.isLoading){
        if(items && items.length > 0){
          return items.map((item, i) => {
            return <Box key={i}>
              {item}
            </Box>
          });
        } else {
          return <Box>
            No results
          </Box>
        }
      } else {
        return <Spinner animation="border" size={'sm'} />
      }
    };


    return (
        <Box className={className}>
            <IconButton
              ref={anchorRef}
              onClick={handleToggle}
            >
              {icon}
            </IconButton>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition className={'custom-poper-' + classNamePopper} style={{ zIndex: '9999' }}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper style={{ padding: '15px' }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <Box>
                                    {setItems()}
                                </Box>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    );
  }

  export default CustomPopper;