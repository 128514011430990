import React, { Component } from 'react';

import apiUtil from '../../../../api/apiUtil.jsx';
import { StepType, DefaultLoaction, JobStepType } from '../../../../utils/enums';

import { 
    Box,
    Grid,
    Divider,
} from '@material-ui/core';

import Card from '../../../../components/OLD/Card/Card.jsx';
import ManageSelect from '../../../../components/OLD/input/ManageSelect.jsx';
import GridTable from '../../../../components/OLD/GridTable/GridTable.jsx';

import LoactionHeaderRow from '../Partial/LoactionHeaderRow.jsx';
import LocationItemRow from '../Partial/LocationItemRow.jsx';
import LoactionEmptyRow from '../Partial/LoactionEmptyRow.jsx';


class AddressesSection extends Component {
  
    constructor(props){
        super(props);

        this.gridTable1 = React.createRef();
        this.gridTable2 = React.createRef();

        let id = (props.id && props.id > 0) ? props.id : 0;
        let isCopy = ((props.isCopy === true || props.isCopy === false) ? props.isCopy : false);
        let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
        let disabled = ((props.disabled === true || props.disabled === false) ? props.disabled : false);
        let jobType = (props.jobType && props.jobType) ? props.jobType : 0;

        let addresses1 = [];
        let pomAddresses1 = [];
        let addresses2 = [];
        let pomAddresses2 = [];

        let locations = [
            {
                value: DefaultLoaction.DefaultLocation,
                label: 'Default locations.',
            },
            {
                value: DefaultLoaction.NoDefaultLocation,
                label: 'No Default location. I will enter address when creating job',
            }
        ];


        this.state = {
            id: id,
            isCopy: isCopy,
            isLoading: isLoading,
            disabled: disabled,
            jobType: jobType,

            addresses1: addresses1,
            pomAddresses1: pomAddresses1,
            isClickedShowMore1: false,
            addresses2: addresses2,
            pomAddresses2: pomAddresses2,
            isClickedShowMore2: false,

            locations: locations,
            location1: DefaultLoaction.None,
            location2: DefaultLoaction.None,
        }
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.isCopy !== this.state.isCopy) {
            let isCopy = ((nextProps.isCopy === true || nextProps.isCopy === false) ? nextProps.isCopy : false);
            this.setState({
                isCopy: isCopy
            });
        }
        if (nextProps.isLoading !== this.state.isLoading) {
            let isLoading = ((nextProps.isLoading === true || nextProps.isLoading === false) ? nextProps.isLoading : false);
            this.setState({
                isLoading: isLoading
            });
        }
        if (nextProps.disabled !== this.state.disabled) {
            let disabled = ((nextProps.disabled === true || nextProps.disabled === false) ? nextProps.disabled : false);
            this.setState({
                disabled: disabled
            });
        }
        if (nextProps.jobType !== this.state.jobType) {
            let jobType = (nextProps.jobType && nextProps.jobType) ? nextProps.jobType : 0;
            this.setState({
                jobType: jobType
            });
        }
    }

        
    /* GETTER & SETTER */
    getSelectedLocation = (hasDefaultSite) => {
        let location = null;

        if(this.state.locations && this.state.locations.length > 0){
            let item = this.state.locations.filter(x => {
                if(hasDefaultSite === true){
                    return x.value === DefaultLoaction.DefaultLocation;
                } else {
                    return x.value === DefaultLoaction.NoDefaultLocation;
                }
                
            });
            
            if(item && item.length > 0){
                location = item[0].value;
            }
        }
        return location
    }

    getAddress = (x) => {
        return this.state['addresses' + x]
    }
    setAddress = (addresses = [], x, callback = null) => {
        return this.setState({
            ['addresses' + x]: addresses,
            ['isClickedShowMore' + x]: (addresses && addresses.length > 0) ? false : true,
        }, () => {
            if(callback) {
                callback();
            }
        });
    }
    getLocation = (x) => {
        return this.state['location' + x]
    }
    setLocation = (location = DefaultLoaction.DefaultLocation, x) => {
        return this.setState({
            ['location' + x]: location
        });
    }
    /* END GETTER & SETTER */


    setStepsForm = () => {
        if(this.state.jobType === JobStepType.Shift){
            let stepsStruct = apiUtil.getStepStruct(this.state.jobType);
            
            if(stepsStruct && stepsStruct.length > 0){
                return stepsStruct.map((item, i) => {
                    if(item.stepTypeDraw === StepType.BIN_OUT){
                        return <Box key={i} mb={1}>
                            <Card>
                                <Box p={3}>
                                    {this.setRow(item, i, 0)}
                                </Box>
                            </Card>
                        </Box>
                    }
                });
            }

        } else if(this.state.jobType === JobStepType.Task){
            let stepsStruct = apiUtil.getStepStruct(this.state.jobType);
            
            if(stepsStruct && stepsStruct.length > 0){
                let count = 0;
                return stepsStruct.map((item, i) => {
                    if((item.stepTypeDraw !== StepType.BIN_EXCHANGE) && (item.stepTypeDraw !== StepType.WASTE_COLLECTION)){
                        count++;
                        return <Box key={i} mb={1}>
                            <Card>
                                <Box p={3}>
                                    {this.setRow(item, i, count)}
                                </Box>
                            </Card>
                        </Box>
                    }
                });
            }

        } else {
            let stepsStruct = apiUtil.getStepStruct(this.state.jobType);
            
            if(stepsStruct && stepsStruct.length > 0){
                let count = 0;
                return stepsStruct.map((item, i) => {
                    if((item.stepTypeDraw !== StepType.BIN_EXCHANGE) && (item.stepTypeDraw !== StepType.WASTE_COLLECTION)){
                        count++;
                        return <Box key={i} mb={1}>
                            <Card>
                                <Box p={3}>
                                    {this.setRow(item, i, count)}
                                </Box>
                            </Card>
                        </Box>
                    }
                });
            }
        }
    }
    
    setRow = (item, i, count) => {
        return <Grid container>
            <Box clone>
                <Grid item xs={12}>
                    {this.setDesc(item, i, count)}
                </Grid>
            </Box>

            {(this.state.jobType !== JobStepType.Shift) && <Box clone pl={{ xs: 0, md: 2 }}>
                <Grid item xs={12}>
                    <Grid container>
                        <Box clone pb={5}>
                            <Grid item xs={'auto'}>
                                {this.setLocationForm(item, i, count)}
                            </Grid>
                        </Box>
                        {(this.state['location' + count] !== 2) && <Box clone>
                            <Grid item xs={12}>
                                {this.setDataGridForm(item, i, count)}
                            </Grid>
                        </Box>}
                    </Grid>
                </Grid>
            </Box>}
        </Grid>
    }

    setDesc = (item, i, count) => {
        if(this.state.jobType === JobStepType.Shift){
            return <>
                <Box pl={2} pr={2} pb={5}>
                    <Box><b>{(i + 1) + '.'} BIN OUT</b></Box>
                    <Box pb={1}>Collect bin from this location</Box>
                    <Divider />
                    <Box pt={1} pb={1} fontWeight={'bold'}>Select from customer site </Box>
                </Box>
                <Box pl={2} pr={2} pb={3}>
                    <Box><b>{(i + 2) + '.'} BIN IN</b></Box>
                    <Box pb={1}>Deliver bin from this location</Box>
                    <Divider />
                    <Box pt={1} pb={1} fontWeight={'bold'}>Select from customer site </Box>
                </Box>
            </>;

        } else if(this.state.jobType === JobStepType.Task){
            return <>
                <Box pl={2} pr={2} pb={5}>
                    <Box><b>{(i + 1) + '.'} TASK</b></Box>
                    <Box pb={1}>Do task from this address</Box>
                    <Divider />
                </Box>
            </>;

        } else {
            if(item.stepTypeDraw === StepType.BIN_IN){
                return <Box pl={2} pr={2} pb={3}>
                    <Box><b>{(i + 1) + '.'} BIN IN</b></Box>
                    <Box pb={1}>Return bin to this location</Box>
                    <Divider />
                </Box>;
            } else if(item.stepTypeDraw === StepType.BIN_OUT){
                return <Box pl={2} pr={2} pb={3}>
                    <Box><b>{(i + 1) + '.'} BIN OUT</b></Box>
                    <Box pb={1}>Collect bin from this address</Box>
                    <Divider />
                </Box>;
            }
        }
    }


    setLocationForm = (item, i, count) => {
        return <ManageSelect
            className={'bg-white-job-type-select'}
            value={this.state['location' + count]}
            options={this.state.locations}
            onChange={(value) => {
                if(value.target.value === 2){
                    let addresses = this.state['addresses' + count];

                    this.setState({
                        ['pomAddresses' + count]: addresses,
                        ['addresses' + count]: []
                    });
                } else {
                    let pomAddresses = this.state['pomAddresses' + count];
                    
                    this.setState({
                        ['addresses' + count]: pomAddresses
                    }, () => {
                        if(this.state['addresses' + count] && this.state['addresses' + count].length === 0){
                            if(this['gridTable' + count] && this['gridTable' + count].current){
                                let items = this['gridTable' + count].current.addEmptyRow({
                                    stepSiteName: '', 
                                    stepSiteAddress: '', 
                                    stepSiteLatitude: '', 
                                    stepSiteLongitude: '',
                                    isDefault: true,
                                });

                                this.setState({
                                    ['addresses' + count]: items
                                });
                            }
                        }
                    });
                }
                
                this.setState({
                    ['location' + count]: value.target.value,
                }, () => {
                    if(this.props.onChangeLocation){
                        this.props.onChangeLocation(this.state['location' + count]);
                    }
                });
            }}
        />
    }

    setDataGridForm = (item, i, count) => {
        return <GridTable
            ref={this['gridTable' + count]}
            className={'job-type-list'}
            items={this.state['addresses' + count]}
            struct={{ 
                stepSiteName: '', 
                stepSiteAddress: '', 
                stepSiteLatitude: '', 
                stepSiteLongitude: '',
                isDefault: false,
            }}
            required={[ 
                {
                    field: 'stepSiteName',
                    text: 'site name',
                },
                {
                    field: 'stepSiteAddress',
                    text: 'site address',
                }
            ]}
            duplicated={[ 
                'stepSiteName',
            ]}

            hideIconsAtEmptyRow={true}

            headerRow={<LoactionHeaderRow />}
            itemsRow={(item, i, obj) => {
                return <LocationItemRow
                    item={item}
                    addresses={(count === 1) ? this.state.addresses2 : this.state.addresses1}
                    onChange={(field, value, isValidate, isRefresh) => {
                        item[field] = value;

                        if(isRefresh){
                            obj.refresh();
                        }
                    }}
                    onRemoveDefault={(callback) => {
                        let items = this.removeAllIsDefault(this.state['addresses' + count]);

                        this.setState({
                            ['addresses' + count]: items,
                        }, () => {
                            callback();
                        });
                    }}
                />
            }}
            emptyRow={(item, obj) => {
                return <LoactionEmptyRow
                    onClick={(checked) => {
                        let items = obj.addEmptyRow({ 
                            stepSiteName: '', 
                            stepSiteAddress: '', 
                            stepSiteLatitude: '', 
                            stepSiteLongitude: '',
                            isDefault: (this.isDefaultExist(this.state['addresses' + count])) ? false : true,
                        });
                        
                        this.setState({
                            ['addresses' + count]: items,
                        });
                    }}
                />
            }}

            onAddRow={(items, item, obj) => {
                this.setState({
                    ['addresses' + count]: items,
                    ['isClickedShowMore' + count]: (items && items.length > 0) ? false : true,
                }, () => {
                    obj.hideIconsAtEmptyRow(true);
                });
            }}
            onRemoveRow={(items, item, i, obj) => {
                this.setState({
                    ['addresses' + count]: items,
                });
            }}
        />
    }


    isDefaultExist = (items = []) => {
        let isDefault = false;

        if(items && items.length > 0){
            isDefault = items.some(x => x.isDefault === true);
        }

        return isDefault;
    }
    removeAllIsDefault = (items = []) => {
        if(items && items.length > 0){
            return items.map(x => {
                x.isDefault = false;
                return x;
            });
        }

        return items;
    }
    addEmptyRow = (count) => {
        if(this.state['addresses' + count] && this.state['addresses' + count].length === 0){
            if(this['gridTable' + count] && this['gridTable' + count].current){
                let items = this['gridTable' + count].current.addEmptyRow({
                    stepSiteName: '', 
                    stepSiteAddress: '', 
                    stepSiteLatitude: '', 
                    stepSiteLongitude: '',
                    isDefault: (this.isDefaultExist(this.state['addresses' + count])) ? false : true,
                });

                this.setState({
                    ['addresses' + count]: items
                });
            }
        }
    }


    isDefaultExistInAddresses = (x = 1) => {
        let location = this.getLocation(x);

        if(location === DefaultLoaction.DefaultLocation){
            let items = (this.state['addresses' + x] && this.state['addresses' + x].length > 0) ? this.state['addresses' + x] : [];
            if(items && items.length > 0){
                return items.some(x => x.isDefault === true);
            } else {
                return true;
            }
        } else {
            return true;
        }
    }


    render() {
        return <Box>
            {this.setStepsForm()}
        </Box>
    }
}

export default AddressesSection;
