import React, { useEffect, useRef, forwardRef, useImperativeHandle, useState } from 'react';
import _ from 'lodash';
import uuid from 'react-uuid'
import styled from 'styled-components';

import Utils from '../../utils/utils';

import { 
    Spinner,
} from "react-bootstrap-v5";

import { union } from '@turf/turf';
import { 
    MapContainer,
    TileLayer,
    Polygon,
    FeatureGroup,
    Tooltip,
} from 'react-leaflet';
import L from 'leaflet';
import { EditControl } from 'react-leaflet-draw';
import 'leaflet/dist/leaflet.css';
import "leaflet-draw/dist/leaflet.draw.css";

import { useDispatch, useSelector } from "react-redux";
import geofenceMapViewSlice from './geofenceMapViewSlice';

import { GestureHandler } from './GestureHandler';


const StyledContainer = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;

    .leaflet-draw.leaflet-control,
    .leaflet-control-attribution {
        display: none;
    }

    svg {
        path {
            &:focus {
                outline: none !important;
            }
        }
    }
`;


L.drawLocal = {
	draw: {
		toolbar: {
			actions: {
				title: 'Cancel drawing',
				text: 'Cancel'
			},
			finish: {
				title: 'Finish drawing',
				text: 'Finish'
			},
			undo: {
				title: 'Delete last point drawn',
				text: 'Delete last point'
			},
			buttons: {
				polyline: 'Draw a polyline',
				polygon: 'Draw a polygon',
				rectangle: 'Draw a rectangle',
				circle: 'Draw a circle',
				marker: 'Draw a marker',
				circlemarker: 'Draw a circlemarker'
			}
		},
		handlers: {
			circle: {
				tooltip: {
					start: 'Click and drag to draw circle.'
				},
				radius: 'Radius'
			},
			circlemarker: {
				tooltip: {
					start: 'Click map to place circle marker.'
				}
			},
			marker: {
				tooltip: {
					start: 'Click map to place marker.'
				}
			},
			polygon: {
				tooltip: {
					start: 'Click to start drawing shape.',
					cont: 'Click to continue drawing shape.',
					end: 'Click first point to close this shape.'
				}
			},
			polyline: {
				error: '<strong>Error:</strong> shape edges cannot cross!',
				tooltip: {
					start: 'Click to start drawing line.',
					cont: 'Click to continue drawing line.',
					end: 'Click last point to finish line.'
				}
			},
			rectangle: {
				tooltip: {
					start: 'Click and drag to draw rectangle.'
				}
			},
			simpleshape: {
				tooltip: {
					end: 'Release mouse to finish drawing.'
				}
			}
		}
	},
	edit: {
		toolbar: {
			actions: {
				save: {
					title: 'Save changes',
					text: 'Save'
				},
				cancel: {
					title: 'Cancel editing, discards all changes',
					text: 'Cancel'
				},
				clearAll: {
					title: 'Clear all layers',
					text: 'Clear All'
				}
			},
			buttons: {
				edit: 'Edit layers',
				editDisabled: 'No layers to edit',
				remove: 'Delete layers',
				removeDisabled: 'No layers to delete'
			}
		},
		handlers: {
			edit: {
				tooltip: {
					text: '',
					subtext: ''
				}
			},
			remove: {
				tooltip: {
					text: ''
				}
			}
		}
	}
};


const GeofenceMapView = forwardRef(({ height = '100%', isFromPopup = false, enabledCreate = false, selectedTab, selectedId, selectedGroupId, rows, polygons, onLoad, onClick, onDoubleClick, onHover, onEdit, onEdited, onCreate, onCancelCreate, onDeleted, onEditVertex, ...restProps }, ref) => {
    const dispatch = useDispatch();
    const { isLoading, isExist, center, zoom, coordinates, staticCoordinates, visible } = useSelector((state) => state.geofenceMapViewSlice);
    
    const [hover, setHover] = useState([]);

    const featureGroupRef = useRef();
    const editControlRef = useRef();


    useEffect(() => {
        dispatch(geofenceMapViewSlice.callLatLngZoomApi(isFromPopup));
    }, []);

    useEffect(() => {
        draw();
    }, [selectedTab]);

    useEffect(() => {
        draw();
    }, [rows]);
    
    useEffect(() => {
        draw();
    }, [polygons]);

    useEffect(() => {
        setScrollZoom((coordinates.length > 0));
        draw();
    }, [coordinates]);

    useEffect(() => {
        setCenter(center);
    }, [center]);

    useEffect(() => {
        setZoom(zoom);
    }, [zoom]);
    
    useEffect(() => {
        if(enabledCreate){
            onCreatePolygon();
        }
    }, [enabledCreate]);


    useImperativeHandle(
        ref,
        () => ({
            checkIfExist() {
                return checkIfExist();
            },
            checkOverlap(polygon) {
                return checkOverlap(polygon);
            },
            click(id) {
                return click(id);
            },
            getHover() {
                return hover;
            },
            addHover(id) {
                setHover(id);
            },
            removeHover() {
                setHover([]);
            },

            clearLayer() {
                clearLayer();
            },
            getCenter(latlngs) {
                return getCenter(latlngs);
            },
            getPoygonsCenter(polygons) {
                return getPoygonsCenter(polygons);
            },
            setCenter(latlng) {
                setCenter(latlng);
            },
            setCenterPolygon(latlngs) {
                setCenterPolygon(latlngs);
            },

            create() {
                onCreatePolygon();
            },
            cancelCreate() {
                onCancelCreatePolygon();
            },

            edit() {
                onEditPolygon();
            },
            saveEdit() {
                onSaveEditPolygon();
            },
            cancelEdit() {
                onCancelEditPolygon();
            },

            remove() {
                onRemovePolygon();
            },
            saveRemove() {
                onSaveRemovePolygon();
            },
            cancelRemove() {
                onCancelRemovePolygon();
            },
         }),
    );


    const draw = () => {
        let coord = [];

        let newItems = (selectedTab === 'tab2') ? polygons : rows;
        if(newItems && newItems.length > 0){
            if(selectedTab === 'tab2'){
                let selected = (coordinates && coordinates.length > 0) ? coordinates[0] : null;
                if(selected){
                    coord = newItems.map((polygon, i) => {
                        if(polygon.accountMapGeofenceId !== selected.id){
                            let accountMapGeofencePoints = (polygon && polygon.accountMapGeofencePoints && polygon.accountMapGeofencePoints.length > 0) ? polygon.accountMapGeofencePoints.sort((a, b) => a.pointOrder - b.pointOrder) : [];
                            let latlngs = (accountMapGeofencePoints && accountMapGeofencePoints.length > 0) ? accountMapGeofencePoints.map(point => [point.latitude, point.longitude]) : [];
                            
                            return {
                                id: polygon.accountMapGeofenceId,
                                name: polygon.name,
                                color: polygon.zoneColor,
                                group: polygon.group,
                                latlngs: latlngs,
                            }
                        }
                    });

                    dispatch(geofenceMapViewSlice.setStaticCoordinates(coord));
                    onEditPolygon();

                } else {
                    coord = newItems.map((polygon, i) => {
                        let accountMapGeofencePoints = (polygon && polygon.accountMapGeofencePoints && polygon.accountMapGeofencePoints.length > 0) ? polygon.accountMapGeofencePoints.sort((a, b) => a.pointOrder - b.pointOrder) : [];
                        let latlngs = (accountMapGeofencePoints && accountMapGeofencePoints.length > 0) ? accountMapGeofencePoints.map(point => [point.latitude, point.longitude]) : [];
                        
                        return {
                            id: polygon.accountMapGeofenceId,
                            name: polygon.name,
                            color: polygon.zoneColor,
                            group: polygon.group,
                            latlngs: latlngs,
                        }
                    });
                    dispatch(geofenceMapViewSlice.setStaticCoordinates(coord));
                }

            } else {
                let selected = (coordinates && coordinates.length > 0) ? coordinates[0] : null;
                if(selected){
                    coord = newItems.map((polygon, i) => {
                        if(polygon.accountMapGeofenceId !== selected.id){
                            let accountMapGeofencePoints = (polygon && polygon.accountMapGeofencePoints && polygon.accountMapGeofencePoints.length > 0) ? polygon.accountMapGeofencePoints.sort((a, b) => a.pointOrder - b.pointOrder) : [];
                            let latlngs = (accountMapGeofencePoints && accountMapGeofencePoints.length > 0) ? accountMapGeofencePoints.map(point => [point.latitude, point.longitude]) : [];
                            
                            return {
                                id: polygon.accountMapGeofenceId,
                                name: polygon.name,
                                color: polygon.zoneColor,
                                group: polygon.group,
                                latlngs: latlngs,
                            }
                        }
                    });
                    
                    dispatch(geofenceMapViewSlice.setStaticCoordinates(coord));
                    onEditPolygon();

                } else {
                    coord = newItems.map((polygon, i) => {
                        let accountMapGeofencePoints = (polygon && polygon.accountMapGeofencePoints && polygon.accountMapGeofencePoints.length > 0) ? polygon.accountMapGeofencePoints.sort((a, b) => a.pointOrder - b.pointOrder) : [];
                        let latlngs = (accountMapGeofencePoints && accountMapGeofencePoints.length > 0) ? accountMapGeofencePoints.map(point => [point.latitude, point.longitude]) : [];
                        
                        return {
                            id: polygon.accountMapGeofenceId,
                            name: polygon.name,
                            color: polygon.zoneColor,
                            group: polygon.group,
                            latlngs: latlngs,
                        }
                    });
                    dispatch(geofenceMapViewSlice.setStaticCoordinates(coord));
                }
            }
        } else {
            if(selectedTab === 'tab2'){
                coord = newItems.map((polygon, i) => {
                    let accountMapGeofencePoints = (polygon && polygon.accountMapGeofencePoints && polygon.accountMapGeofencePoints.length > 0) ? polygon.accountMapGeofencePoints.sort((a, b) => a.pointOrder - b.pointOrder) : [];
                    let latlngs = (accountMapGeofencePoints && accountMapGeofencePoints.length > 0) ? accountMapGeofencePoints.map(point => [point.latitude, point.longitude]) : [];
                    
                    return {
                        id: polygon.accountMapGeofenceId,
                        name: polygon.name,
                        color: polygon.zoneColor,
                        group: polygon.group,
                        latlngs: latlngs,
                    }
                });
                dispatch(geofenceMapViewSlice.setStaticCoordinates(coord));
            } else {
                dispatch(geofenceMapViewSlice.setStaticCoordinates([]));
            }
        }
    };

    const checkIfExist = () => {
        try {
            if (editControlRef && editControlRef.current) {
                const map = editControlRef.current._map;
                if (map) {
                    const layers = map._layers;
                    const polygonsExist = Object.values(layers).some(layer => layer instanceof L.Polygon);
                    return polygonsExist;
                }
            }
            return false;
        } catch(e){
            return false;
        }
    };

    const clearLayer = () => {
        try {
            if (featureGroupRef && featureGroupRef.current) {
                featureGroupRef.current.clearLayers();
            }
        } catch (error) {}
    };

    const click = (id) => {
        let poly = null;

        let newItems = (selectedTab === 'tab2') ? polygons : rows;
        if(newItems && newItems.length > 0){
            newItems.forEach((polygon, i) => {
                if(polygon.accountMapGeofenceId == id){
                    let accountMapGeofencePoints = (polygon && polygon.accountMapGeofencePoints && polygon.accountMapGeofencePoints.length > 0) ? polygon.accountMapGeofencePoints.sort((a, b) => a.pointOrder - b.pointOrder) : [];
                    let latlngs = (accountMapGeofencePoints && accountMapGeofencePoints.length > 0) ? accountMapGeofencePoints.map(point => [point.latitude, point.longitude]) : [];
                    
                    poly = {
                        id: polygon.accountMapGeofenceId,
                        name: polygon.name,
                        color: polygon.zoneColor,
                        group: polygon.group,
                        latlngs: latlngs,
                    }
                }
            });
        }

        return poly;
    }

    const getPoygonsCenter = (polygons) => {
        try {
            if (polygons && polygons.length > 0) {
                const polygonsCenters = polygons.map((polygonCoords) => getCenter(polygonCoords));
                
                const totalCenters = polygonsCenters.length;
                const sumLat = polygonsCenters.reduce((acc, center) => acc + center[0], 0);
                const sumLng = polygonsCenters.reduce((acc, center) => acc + center[1], 0);
              
                const avgLat = sumLat / totalCenters;
                const avgLng = sumLng / totalCenters;
              
                return [avgLat, avgLng];
            }
        } catch (error) {}
        return null;
    };
    const getCenter = (coordinates) => {
        try {
            if (coordinates && coordinates.length > 0) {
                const polygon = L.polygon(coordinates);
                const bounds = polygon.getBounds();
                const center = bounds.getCenter();
                return [center.lat, center.lng];
            }
        } catch (error) {}
        return null;
    };
    const setCenter = (center) => {
        try {
            if (editControlRef && editControlRef.current) {
                const map = editControlRef.current._map;
                if (map) {
                    map.setView(center);
                }
            }
        } catch (error) {}
    };
    const setCenterPolygon = (latlngs) => {
        try {
            if (editControlRef && editControlRef.current) {
                const map = editControlRef.current._map;
                if (map && latlngs) {
                    const polygon = L.polygon(latlngs);
                    const bounds = polygon.getBounds();
                    map.fitBounds(bounds);
                }
            }
        } catch (error) {}
    };
    const setScrollZoom = (state = false) => {
        try {
            if (editControlRef && editControlRef.current) {
                const map = editControlRef.current._map;
                if (map) {
                    if(state){
                        map.scrollWheelZoom.enable()
                    } else {
                        map.scrollWheelZoom.disable()
                    }
                }
            }
        } catch (error) {}
    };
    const setZoom = (zoom = 13) => {
        try {
            if (editControlRef && editControlRef.current) {
                const map = editControlRef.current._map;
                if (map) {
                    map.setZoom(zoom)
                }
            }
        } catch (error) {}
    };

    const checkOverlap = (currentPolygon) => {
        try {
            if (editControlRef && editControlRef.current) {
                const map = editControlRef.current._map;
                if (map) {
                    const targetPolygon = L.polygon(currentPolygon.latlngs);
                    const targetPolygonCoord = targetPolygon.getLatLngs()[0];

                    const allLayers = map._layers;
                    const allPolygons = Object.keys(allLayers)
                        .filter(key => allLayers[key] instanceof L.Polygon)
                        .map(key => allLayers[key])
                        .filter(polygon => {
                            const polygonLatLngs = polygon.getLatLngs()[0];
                            const areEqual = polygonLatLngs.every((point, index) => {
                                return point.lat === targetPolygonCoord[index].lat && point.lng === targetPolygonCoord[index].lng;
                            });
                            return !areEqual;
                        });

                    for (let i = 0; i < allPolygons.length; i++) {
                        const unionPolygon = union(targetPolygon.toGeoJSON(), allPolygons[i].toGeoJSON());
                        if (unionPolygon.geometry.type === 'Polygon') {
                            return true;
                        }
                    }
                }
            }

            return false;
        } catch (error) {
            return false;
        }
    }


    const onCreateEvent = (e) => {
        let layer = e.layer;
        const latlngs = layer._latlngs[0].map((latlng) => {
            return [latlng.lat, latlng.lng];
        });
        let obj = { id: layer.options.id, name: layer.options.name, color: layer.options.color, group: layer.options.group, latlngs: latlngs };
        
        dispatch(geofenceMapViewSlice.isCreate(false));

        if(checkOverlap(obj)){
            Utils.toast('Polygons overlapped', 'error');
            clearLayer();
            onCancelCreatePolygon();
            
            if(onCancelCreate){
                onCancelCreate();
            }
        } else {
            setTimeout(() => {
                clearLayer();
                onCancelCreatePolygon();
    
                if(onCreate){
                    onCreate(obj);
                }
            });
        }
    }

    const onEditedEvent = (e) => {
        try {
            let layers = e.layers
            const obj = Object.values(layers._layers).map((layer) => {
                const latlngs = layer.editing.latlngs[0][0].map((latlng) => {
                    return [latlng.lat, latlng.lng];
                });
                return { id: layer.options.id, name: layer.options.name, color: layer.options.color, group: layer.options.group, latlngs };
            });
            
            if(onEdited){
                onEdited((obj && obj.length > 0) ? obj[0] : null);
            }
            
            dispatch(geofenceMapViewSlice.isEdit(false));
        } catch(e){}
    };

    const onDeletedEvent = (e) => {
        const arr = _.toArray(e.layers._layers);

        if(onDeleted){
            onDeleted(arr);
        }

        dispatch(geofenceMapViewSlice.isRemove(false));
    }

    const onEditVertexEvent = (e) => {
        let layers = e.poly
        const latlngs = layers._latlngs[0].map((latlng) => {
            return [latlng.lat, latlng.lng];
        });
        let obj = { id: layers.options.id, name: layers.options.name, color: layers.options.color, group: layers.options.group, latlngs: latlngs };

        if(checkOverlap(obj)){
            Utils.toast('Polygons overlapped', 'error');
            dispatch(geofenceMapViewSlice.setCoordinates([]));
        } else {
            if(onEditVertex){
                onEditVertex(obj);
            }
        }
    }


    const onCreatePolygon = () => {
        try {
            if (editControlRef && editControlRef.current) {
                editControlRef.current._toolbars.draw._modes.polygon.handler.enable();
            }
            dispatch(geofenceMapViewSlice.isCreate(true));
        }catch(e){}
    };
    const onCancelCreatePolygon = () => {
        try {
            if (editControlRef && editControlRef.current) {
                editControlRef.current._toolbars.draw._modes.polygon.handler.disable();
            }
            dispatch(geofenceMapViewSlice.isCreate(false));
            
            if(onCancelCreate){
                onCancelCreate();
            }
        }catch(e){}
    };

    const onEditPolygon = (e) => {
        try {
            if (editControlRef && editControlRef.current) {
                editControlRef.current._toolbars.edit._modes.edit.handler.enable();
            }
            dispatch(geofenceMapViewSlice.isEdit(true));
        }catch(e){}
    };
    const onSaveEditPolygon = (e) => {
        try {
            if (editControlRef && editControlRef.current) {
                editControlRef.current._toolbars.edit._modes.edit.handler.save();
                editControlRef.current._toolbars.edit._modes.edit.handler.disable();
            }
            dispatch(geofenceMapViewSlice.isEdit(false));
        }catch(e){}
    };
    const onCancelEditPolygon = (e) => {
        try {
            if (editControlRef && editControlRef.current) {
                editControlRef.current._toolbars.edit._modes.edit.handler.revertLayers();
                editControlRef.current._toolbars.edit._modes.edit.handler.save();
                editControlRef.current._toolbars.edit._modes.edit.handler.disable();
            }
            dispatch(geofenceMapViewSlice.isEdit(false));
        }catch(e){}
    };
    
    const onRemovePolygon = (e) => {
        try {
            if (editControlRef && editControlRef.current) {
                editControlRef.current._toolbars.edit._modes.remove.handler.enable();
            }
            dispatch(geofenceMapViewSlice.isRemove(true));
        }catch(e){}
    };
    const onSaveRemovePolygon = (e) => {
        try {
            if (editControlRef && editControlRef.current) {
                editControlRef.current._toolbars.edit._modes.remove.handler.save();
                editControlRef.current._toolbars.edit._modes.remove.handler.disable();
            }
            dispatch(geofenceMapViewSlice.isRemove(false));
        }catch(e){}
    };
    const onCancelRemovePolygon = (e) => {
        try {
            if (editControlRef && editControlRef.current) {
                editControlRef.current._toolbars.edit._modes.remove.handler.revertLayers();
                editControlRef.current._toolbars.edit._modes.remove.handler.disable();
            }
            dispatch(geofenceMapViewSlice.isRemove(false));
        }catch(e){}
    };
    

    return <StyledContainer style={{ height: height }}>
        {isLoading && <div style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            zIndex: '9999',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'white',
            opacity: '0.4',
        }}>
            <Spinner animation="border" style={{ width: '60px', height: '60px' }} />
        </div>}
        
        <MapContainer 
            style={{ height: '100%', width: '100%', zIndex: '1' }}
            center={center}
            zoom={zoom}
            scrollWheelZoom={false}
            doubleClickZoom={false}
            whenCreated={(mapInstance) => {
                mapInstance.on('click', (e) => {
                    const clickedComponent = e.originalEvent.target;
                    if ((clickedComponent.tagName === 'DIV') || (clickedComponent.tagName === 'div')) {
                        dispatch(geofenceMapViewSlice.setCoordinates([]));

                        if(onClick){
                            onClick(null);
                        }
                    }
                });
            }}
        >
            {/* https://leaflet-extras.github.io/leaflet-providers/preview/ */}
            {/* <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='' /> */}
            <TileLayer url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png" />
            <GestureHandler />

            <FeatureGroup ref={featureGroupRef}>
                <EditControl
                    position="topright"
                    onCreated={onCreateEvent}
                    onEdited={onEditedEvent}
                    onDeleted={onDeletedEvent}
                    onEditVertex={onEditVertexEvent}
                    onMounted={(e) => {
                        editControlRef.current = e;

                        if(onLoad){
                            onLoad(e);
                        }
                    }}
                    // edit={{
                    //     edit: true,
                    //     remove: true,
                    // }}
                    draw={{
                        // marker: false,
                        // circlemarker: false,
                        // polyline: false,
                        // rectangle: false,
                        // circle: false,
                        polygon: {
                            allowIntersection: false,
                            repeatMode: false
                        }
                    }}
                />

                {coordinates?.map((polygon) => {
                    if(polygon && !Utils.isInArray(visible, polygon.id)){
                        let color = (polygon && polygon.group && polygon.group.groupColor && polygon.group.groupColor != '') ? polygon.group.groupColor : '#484848';
                        return (
                            <Polygon
                                key={'polygon-' + polygon.id + '-' + polygon.color}
                                id={polygon.id}
                                positions={polygon.latlngs}
                                color={color}
                                name={polygon.name}
                                group={polygon.group}
                                eventHandlers={{
                                    dblclick: (e) => {
                                        if(onDoubleClick){
                                            onDoubleClick(polygon);
                                        }
                                    },
                                }}
                            >
                                <Tooltip sticky>
                                    <h6>
                                        <div>{polygon.name}</div>
                                        {polygon.group && <small>{polygon.group.groupName}</small>}
                                    </h6>
                                </Tooltip>
                            </Polygon>
                        );
                    }
                })}
            </FeatureGroup>

            {staticCoordinates?.map((polygon) => {
                let color = (polygon && polygon.group && polygon.group.groupColor && polygon.group.groupColor != '') ? polygon.group.groupColor : '#484848';
                if(polygon && !Utils.isInArray(visible, polygon.id)){
                    let opacity = 0.2;
                    if(selectedTab === 'tab2'){
                        let accountMapGeofenceGroupId = (polygon.group && polygon.group.accountMapGeofenceGroupId) ? polygon.group.accountMapGeofenceGroupId : null;
                        opacity = (selectedGroupId == accountMapGeofenceGroupId) ? 0.5 : (hover.includes(polygon.id)) ? 0.4 : 0.2;
                    } else {
                        opacity = (selectedId == polygon.id) ? 0.5 : (hover.includes(polygon.id)) ? 0.4 : 0.2;
                    }

                    return (
                        <Polygon
                            key={'polygon-' + polygon.id + '-' + polygon.color}
                            id={polygon.id}
                            positions={polygon.latlngs}
                            color={color}
                            name={polygon.name}
                            group={polygon.group}
                            pathOptions={{ 
                                fillOpacity: opacity,
                                color: color,
                                weight: 2,
                            }}
                            eventHandlers={{
                                click: (e) => {
                                    if(onClick){
                                        onClick(polygon);
                                    }
                                },
                                mouseover: () => {
                                    setTimeout(() => {
                                        setHover([polygon.id]);

                                        if(onHover){
                                            onHover([polygon.id]);
                                        }
                                    });
                                },
                                mouseout: () => {
                                    setTimeout(() => {
                                        setHover([]);

                                        if(onHover){
                                            onHover([]);
                                        }
                                    });
                                }
                            }}
                        >
                            <Tooltip sticky>
                                <h6>
                                    <div>{polygon.name}</div>
                                        {polygon.group && <small>{polygon.group.groupName}</small>}
                                </h6>
                            </Tooltip>
                        </Polygon>
                    );
                }
            })}

            {(isExist) && <>
                {/* {((selectedTab === 'tab1') && (coordinates && coordinates.length > 0 && coordinates[0] && coordinates[0].id)) && <button 
                    style={{
                        position: 'absolute',
                        top: '15px',
                        right: '100px',
                        zIndex: '9999',
                        backgroundColor: 'white',
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        if(onEdit){
                            let obj = (coordinates && coordinates.length > 0) ? coordinates[0] : null;
                            onEdit(obj);
                        }
                    }}
                >
                    <i className={'fa fa-pen p-2'}></i>
                </button>} */}
                {/* {((coordinates && coordinates.length > 0 && coordinates[0] && coordinates[0].id)) && <button 
                    style={{
                        position: 'absolute',
                        top: '15px',
                        right: '55px',
                        zIndex: '9999',
                        backgroundColor: 'white',
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        if(onDeleted){
                            let obj = (coordinates && coordinates.length > 0) ? coordinates[0] : null;
                            dispatch(geofenceMapViewSlice.setCoordinates([]));
                            onDeleted(obj);
                        }
                    }}
                >
                    <i className={'fa fa-trash p-2'}></i>
                </button>} */}
                <button 
                    style={{
                        position: 'absolute',
                        top: '15px',
                        right: '15px',
                        zIndex: '9999',
                        backgroundColor: 'white',
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        dispatch(geofenceMapViewSlice.setCoordinates([]));

                        if(onClick){
                            onClick(null);
                        }
                    }}
                >
                    <i className={'fa fa-times p-2'}></i>
                </button>
            </>}
        </MapContainer>
    </StyledContainer>
});

export default GeofenceMapView;