import React from 'react';

import { 
    Modal,
    Button,
    Spinner,
} from 'react-bootstrap-v5';

import { useDispatch, useSelector } from "react-redux";
import {RootState} from '../../../setup'
import reduxPopupSlice from './reduxPopupSlice';


const ReduxPopup = (props: any) => {
    const dispatch = useDispatch();
    const { show, isLoading } = useSelector((state: RootState) => state.reduxPopupSlice);

    return <Modal 
        show={show} 
        size={'sm'}
        backdrop={'static'} 
        onHide={() => {
            dispatch(reduxPopupSlice.setHide());
            
            if(props.onCancel){
                props.onCancel();
            }
        }} 
        centered={props.centered ? true : false}
        dialogClassName={(props.dialogClassName && props.dialogClassName != '') ? props.dialogClassName : ''}
        enforceFocus={false}
    >
        <Modal.Header className={'px-4 py-3'} closeButton={false}>
            <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>

        {
            (props && props.isHtml == true)
            ?
            <Modal.Body className={'px-5 py-7'} dangerouslySetInnerHTML={{ __html: props.body }} />
            :
            <Modal.Body className={'px-5 py-7'}>{props.body}</Modal.Body>
        }

        <Modal.Footer className={'p-3'} style={{ justifyContent: 'start' }}>
            <Button
                type={'button'}
                variant={(props && props.saveVariant) ? props.saveVariant : 'primary'}
                size={'sm'}
                disabled={isLoading}
                onClick={() => {
                    dispatch(reduxPopupSlice.setHide());

                    if(props.onSave){
                        props.onSave();
                    }
                }}
            >
                {props.save}
                {isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
            </Button>

            <Button
                type={'button'}
                variant={'light'}
                size={'sm'}
                disabled={isLoading}
                onClick={() => {
                    dispatch(reduxPopupSlice.setHide());
            
                    if(props.onCancel){
                        props.onCancel();
                    }
                }}
            >
                {props.cancel}
                {isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
            </Button>
        </Modal.Footer>
    </Modal>
}


export default ReduxPopup;