import React, { Component } from 'react';

import {
  Box
} from '@material-ui/core';

import Icon from '@material-ui/core/Icon';


class CustomUpDown extends Component {
  
  constructor(props){
    super(props);

    let value = (props.value && props.value !== '') ? props.value : '0.5';
    let disabled = ((props.disabled === true || props.disabled === false) ? props.disabled : false);
    let className = (props.className) ? props.className : '';

    this.state = {
        value: value,
        disabled: disabled,
        className: className,
    }
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.value) {
        let value = (nextProps.value && nextProps.value !== '') ? nextProps.value : '';
        this.setState({
            value: value
        });
    }
    if (nextProps.disabled !== this.state.disabled) {
        let disabled = ((nextProps.disabled === true || nextProps.disabled === false) ? nextProps.disabled : false);
        this.setState({
            disabled: disabled
        });
    }
  }


  parseValue = (x) => {
    if(x === 0 || x === "0" || x === "0.00"){
        return 0;
    } else {
        return isNaN(parseFloat(x)) ? parseFloat(0) : parseFloat(x);
    }
  }

  upClick = () => {
    let value = this.parseValue(this.state.value);

    if(value < 24){
        value += 0.5;
    }

    if(!this.state.disabled && this.props.onUpChange){
        this.props.onUpChange(value);
    }
  }

  downClick = () => {
    let value = this.parseValue(this.state.value);

    if(value > 0.5){
        value -= 0.5;
    }

    if(!this.state.disabled && this.props.onDownChange){
        this.props.onDownChange(value);
    }
  }


  render() {
    return <Box className={"custom-up-down-component " + this.state.className + (this.state.disabled ? 'disabled' : '')}>
      <Box 
        className={'custom-wrapper'}
    >
          <Box className={'custom-text'}>{this.state.value}</Box>
          <Box className={'custom-btns'}>
            <Box className={'custom-up'}>
                <Icon 
                    component={'i'}
                    onClick={() => {
                        this.upClick();
                    }}
                >expand_less</Icon>
            </Box>
            <Box className={'custom-down'}>
                <Icon 
                    component={'i'}
                    onClick={() => {
                        this.downClick();
                    }}
                >expand_more</Icon>
            </Box>
          </Box>
      </Box>
    </Box>;
  }
}

export default CustomUpDown;
