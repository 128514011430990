import {FC} from 'react'

const Error404: FC = () => {
  return (
    <>
      <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>Page not found</h1>

      <div className='fw-bold fs-3 text-gray-400 mb-15'>
      We are sorry<br />The page you are looking for might have been removed<br />had its name changed or is temporarily unavailable.
      </div>
    </>
  )
}

export {Error404}
