import React, { Component } from 'react';

import { 
    Box,
    Grid,
} from '@material-ui/core';


class LoactionHeaderRow extends Component {
  
    render() {
        return <Box className={'header-row-custom-item'}>
            <Grid container spacing={2} className={'custom-row'}>
                <Box clone>
                    <Grid item xs={12} md={1} className={'custom-cell'}>Default</Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12} md={3} className={'custom-cell'}>Location Name</Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12} md={true} className={'custom-cell'}>Address</Grid>
                </Box>
            </Grid>
        </Box>
    }
}

export default LoactionHeaderRow;
