/* eslint-disable no-loop-func */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import * as dispatchCrudApi from '../../../api/CRUD/DispatchCRUD'
import Utils from "../../../utils/utils";

import { ParseResult } from "../../../utils/interfaces";


interface InitState {
  isLoading: boolean,
  vehicleUnladenWeight: number|null,
  binUnladenWeight: number|null,
  nonBillableWeight: number|null,
}


function NewReducer() {
  const name = 'refreshWeighChit';


  const initialState: InitState = {
    isLoading: false,
    vehicleUnladenWeight: null,
    binUnladenWeight: null,
    nonBillableWeight: null,
  };


  const reducers = {
    clear: (state: InitState) => {
      state.vehicleUnladenWeight = null;
      state.binUnladenWeight = null;
      state.nonBillableWeight = null;
    },

    startVehicle: (state: InitState) => {
      state.isLoading = true;
    },
    finishVehicle: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.unladednBinWeight) ? action.payload.unladednBinWeight : null;

      state.vehicleUnladenWeight = data;
      state.isLoading = false;
    },
    cancelVehicle: (state: InitState) => {
      state.isLoading = false;
    },
    
    startBin: (state: InitState) => {
      state.isLoading = true;
    },
    finishBin: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.unladednBinWeight) ? action.payload.unladednBinWeight : null;

      state.binUnladenWeight = data;
      state.isLoading = false;
    },
    cancelBin: (state: InitState) => {
      state.isLoading = false;
    },
    
    startNonBillable: (state: InitState) => {
      state.isLoading = true;
    },
    finishNonBillable: (state: InitState, action: PayloadAction<any>) => {
      let bundleServices = (action.payload && action.payload.bundleServices) ? action.payload.bundleServices : [];
      let nonBillableWeight = null;
      if (bundleServices && bundleServices.length > 0) {
        let useBinWeightQuantityIndex = bundleServices.findIndex((x: any) => x.useBinWeightQuantity);
        if (useBinWeightQuantityIndex > -1) {
          nonBillableWeight = bundleServices[useBinWeightQuantityIndex].nonBillableWeight;
        }
      }

      state.nonBillableWeight = nonBillableWeight;
      state.isLoading = false;
    },
    cancelNonBillable: (state: InitState) => {
      state.isLoading = false;
    },
  };


  const apis = {
    callReadVehicleWeightApi: (id: number|null) => async (dispatch: any) => {
      dispatch(actions.startVehicle());

      if(id){
        await dispatchCrudApi.readApi(null, 'vehicle/' + id).then(result => {
          let data = result.data;
  
          dispatch(actions.finishVehicle(data));
        }).catch(error => {
          let res: ParseResult = {
            isError: false,
            errorMessage: null,
            status: null,
          };
  
          Utils.parseErrorTS(error, (result: ParseResult): void => {
            res = result
          });
          
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
            Utils.toast(err, 'error');
          }
          
          dispatch(actions.finishVehicle(null));
        });
      } else {
        dispatch(actions.cancelVehicle());
      }
    },

    callReadBinWeightApi: (id: number|null) => async (dispatch: any) => {
      dispatch(actions.startBin());

      if(id){
        await dispatchCrudApi.readApi(null, 'bintype/' + id).then(result => {
          let data = result.data;
  
          dispatch(actions.finishBin(data));
        }).catch(error => {
          let res: ParseResult = {
            isError: false,
            errorMessage: null,
            status: null,
          };
  
          Utils.parseErrorTS(error, (result: ParseResult): void => {
            res = result
          });
          
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
            Utils.toast(err, 'error');
          }
          
          dispatch(actions.finishBin(null));
        });
      } else {
        dispatch(actions.cancelBin());
      }
    },

    callReadNonBillableWeightApi: (id: number|null) => async (dispatch: any) => {
      dispatch(actions.startNonBillable());

      if(id){
        await dispatchCrudApi.readApi(null, 'service-item/' + id).then(result => {
          let data = result.data;
  
          dispatch(actions.finishNonBillable(data));
        }).catch(error => {
          let res: ParseResult = {
            isError: false,
            errorMessage: null,
            status: null,
          };
  
          Utils.parseErrorTS(error, (result: ParseResult): void => {
            res = result
          });
          
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
            Utils.toast(err, 'error');
          }
          
          dispatch(actions.finishNonBillable(null));
        });
      } else {
        dispatch(actions.cancelNonBillable());
      }
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();