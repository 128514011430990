/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Yup from 'yup';
import _ from 'lodash';

// import * as dispatchCrudApi from '../../../api/CRUD/DispatchCRUD'
// import Utils from "../../../utils/utils";
// import { ParseResult } from "../../../utils/interfaces";


export const fields = {
  binNumberId: {
    id: 'binNumberId',
    label: 'Bin number',
    placeholder: 'Select',
  },
};

export const formSchema = () => {
  return Yup.object().shape({
    binNumberId: Yup.number().nullable().required().label(fields.binNumberId.label),
  })
}

export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    const formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};

export interface initialValuesStruct {
  binNumberId: number|null,
  binNumberName: string,
};
export const initialValues: initialValuesStruct = {
  binNumberId: null,
  binNumberName: '',
};

export const prepareData = (values: any = null, mergeToBinNumber: any) => {
  let data: any = {};

  if(values){
    data['mergeToBinNumberId'] = values.binNumberId;
    data['binNumberId'] = mergeToBinNumber;
  }

  return data;
};


interface InitState {
  isLoading: boolean,
  show: boolean,
  item: any,
  items: Array<any>,
}


function NewReducer() {
  const name = 'binCenterMergePopup';


  const initialState: InitState = {
    isLoading: false,
    show: false,
    item: null,
    items: [],
  };


  const reducers = {
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<{ item: any }>) => {
      state.item = action.payload.item;
      state.show = true;
      state.isLoading = false;
    },
    setHide: (state: InitState) => {
      state.item = null;
      state.show = false;
      state.isLoading = false;
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
  };
}


export default NewReducer();