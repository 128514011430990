import React, { Component } from 'react';

import { 
    Box,
    Grid,
} from '@material-ui/core';


class LoactionEmptyRow extends Component {

    setLink = () => {
        return <Box className={'empty-row-custom-item'}>
            <Grid container spacing={2} alignItems={'center'} className={'custom-row'}>
                <Box clone>
                    <Grid item xs={12} md={1} className={'custom-cell'}>&nbsp;</Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12} md={3} className={'custom-cell'}>
                        <a href={"/"} 
                            className={"link-primary"}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                if(this.props.onClick){
                                    this.props.onClick();
                                }
                            }}
                        ><b>Add more location</b></a>
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12} md={true} className={'custom-cell'}>&nbsp;</Grid>
                </Box>
            </Grid>
        </Box>
    }


    render() {
        return <Box className={'empty-row-custom-item'}>
            {this.setLink()}
        </Box>
    }
}

export default LoactionEmptyRow;
