import React, { ReactNode } from 'react';
import { useFormik } from 'formik';
import _ from 'lodash';

import {
  Row,
  Col,
  Form,
//   InputGroup,
//   Dropdown,
  Button,
  Modal,
} from 'react-bootstrap-v5';

import Offcanvas from "react-bootstrap/Offcanvas";
import 'bootstrap/dist/css/bootstrap.min.css';

// import styled from 'styled-components';

import { Loader } from 'rsuite';

import { ReactComponent as LightbulbIcon } from '../../../../_metronic/assets/img/icons/material-lightbulb-outline.svg';

import SmartInputDropdown from '../../../components/dropdown/smartInputDropdown/SmartInputDropdown';
import RSuiteCheckPicker from '../../../components/OLD/Checkbox/RSuiteCheckPicker';
import makeCRUD from "../../../components/dropdown/smartInputDropdown/makeCRUD";

import CustomGoogleMap from '../../../components/OLD/GoogleMap/CustomGoogleMap';
import AddressSearchInput from '../../../components/googleMap/AddressSearchInput';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../../../setup'
import slice, { FormikContext, fields, initialValues, formSchema, prepareForm, prepareData } from './slice';

import Utils from '../../../utils/utils';
import { CustomerSiteAvailability } from '../../../utils/enums';
// import {  } from "../../../utils/styles";

// ----------------------------------------------------------------------

const SmartDropdown = makeCRUD(SmartInputDropdown);


const FormComponent = (props: any) => {
    const dispatch = useDispatch();
    const { isLoading, show, id, customerId, fieldType, details, isLoadingDriver, driverItems, weekdayIsLoading, weekdayIsSelectAll, weekdayItems, weekendIsLoading, weekendIsSelectAll, weekendItems } = useSelector((state: RootState) => state.customerSiteMiniSlice);
    
    const [showInfo, setShowInfo] = React.useState(false);
    const [more, setMore] = React.useState(false);


    React.useEffect(() => {
        if(show){
            setMore(false);
            setShowInfo(false);
            let form = prepareForm(null, initialValues);
            setValues(form);
        }
    }, [show]);

    React.useEffect(() => {
        if(id && id > 0){
            dispatch(slice.callDetailsApi(id, (state: boolean, data: any) => {}));
        }
    }, [id]);
        
    React.useEffect(() => {
        dispatch(slice.setLoading(true));
        let form = prepareForm(details, initialValues);
        setValues(form);
        dispatch(slice.setLoading(false));
    }, [details]);


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: formSchema(null),
        onSubmit: values => {
            onSubmit(values, (data: any) => {
                if(props && props.onSave){
                    props.onSave(data, fieldType);
                }
                
                onCancel();
            });
        },
    });
    const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = formik;


    const onSubmit = (values: any, callback: (data: any) => void) => {
        let data = prepareData(values, customerId);
        if(id > 0){
            dispatch(slice.callUpdateApi(data, (state: boolean, data: any) => {
                if(state){
                    callback(data);
                }
            }));
        } else {
            dispatch(slice.callCreateApi(data, (state: boolean, data: any) => {
                if(state){
                    callback(data);
                }
            }));
        }
    }
    const onCancel = () => {
        let form = prepareForm(null, initialValues);
        setValues(form);
        dispatch(slice.setValues(form));
        dispatch(slice.setShow({ show: false, id: null, customerId: null, fieldType: null }));
        dispatch(slice.resetSlice());
    }

    
    const setHeader = () => {
        return <Offcanvas.Header closeButton className='border-bottom'>
            <Offcanvas.Title className='mb-0'>{(id && id > 0) ? 'Edit' : 'Create'} Customer Site</Offcanvas.Title>
        </Offcanvas.Header>
    }
    const setFooter = () => {
        return <Modal.Footer className='border-top'>
            <Row className='p-2'>
                <Col xs={true} className='text-end'>
                    <Button
                        className='me-5'
                        variant={'light'}
                        size={'sm'}
                        disabled={isLoading}
                        onClick={(e) => {
                            onCancel();
                        }}
                    >Cancel</Button>
                    <Button
                        variant={'primary'}
                        size={'sm'}
                        disabled={isLoading}
                        onClick={() => {
                            handleSubmit()
                            // dispatch(slice.setLoading(true));
                        }}
                    >Save</Button>
                </Col>
            </Row>
        </Modal.Footer>
    }
    const setForm = () => {
        return <Row className={'g-2'}>
            <Col xs={12}>
                <Form.Control 
                    as={AddressSearchInput}

                    isInvalid={values.isInvalid}
                    error={values.isInvalid}

                    placeholder={fields.siteName.placeholder}
                    autoFocus

                    value={values.siteName}
                    onChange={(value) => {
                        setFieldValue('siteName', value);
                        setFieldValue('latitude', null);
                        setFieldValue('longitude', null);
                    }}
                    onBlur={(e: any) => {
                        let isCoord = false;

                        let value = e.target.value;
                        if(Utils.validateCoordinates(value)){
                            let args = value.split(',');
            
                            let center = {
                                lat: (args && args.length > 0) ? Utils.isNumeric(args[0].trim()) ? parseFloat(args[0].trim()) : '' : '',
                                lng: (args && args.length > 1) ? Utils.isNumeric(args[1].trim()) ? parseFloat(args[1].trim()) : '' : '',
                            };
                            
                            isCoord = true;

                            setFieldValue('siteName', value);
                            setFieldValue('latitude', center.lat);
                            setFieldValue('longitude', center.lng);
                            setFieldValue('coordinates', Utils.joinCoordinates(center.lat, center.lng));
                            setFieldValue('isInvalid', false);
                        }

                        if(!isCoord){
                            if(values.latitude !== null && values.longitude !== null){
                                
                            } else {
                                setFieldValue('isInvalid', true);
                            }
                        }
                    }}
                    onPlacesChanged={(place: any) => {
                        if (place) {
                            let address_components = place["address_components"] || [];
                
                            let route = Utils.getFromAddress(address_components, "route");
                            let postal_code = Utils.getFromAddress(address_components, "postal_code");
                            let premise = Utils.getFromAddress(address_components, "premise");
                
                            let street = route ? route : '';
                            let postalCode = postal_code ? postal_code : '';
                            let blockNo = premise ? premise : '';
                            let customSiteName = place.name ? place.name : '';
                            let siteName = (place.formatted_address) ? place.formatted_address : place.name;
                            
                            
                            setFieldValue('street', street);
                            setFieldValue('postalCode', postalCode);
                            setFieldValue('blockNo', blockNo);
                            setFieldValue('customSiteName', customSiteName);
                            setFieldValue('siteName', siteName);

                            if (place.geometry && place.geometry.location) {
                                setFieldValue('latitude', place.geometry.location.lat());
                                setFieldValue('longitude', place.geometry.location.lng());
                                setFieldValue('coordinates', Utils.joinCoordinates(place.geometry.location.lat(), place.geometry.location.lng()));
                                setFieldValue('isInvalid', false);
                            }
                        }
                    }}
                    onEnterCoordinate={async (place: any, center: any) => {
                        if (place) {
                            let address_components = place["address_components"] || [];
                
                            let route = Utils.getFromAddress(address_components, "route");
                            let postal_code = Utils.getFromAddress(address_components, "postal_code");
                            let premise = Utils.getFromAddress(address_components, "premise");
                
                            let street = route ? route : '';
                            let postalCode = postal_code ? postal_code : '';
                            let blockNo = premise ? premise : '';
                            let customSiteName = place.name ? place.name : '';
                            let siteName = (place.formatted_address) ? place.formatted_address : place.name;
                            
                            
                            setFieldValue('street', street);
                            setFieldValue('postalCode', postalCode);
                            setFieldValue('blockNo', blockNo);
                            setFieldValue('customSiteName', customSiteName);
                            setFieldValue('siteName', siteName);
                
                            if (place.geometry && place.geometry.location) {
                                setFieldValue('latitude', place.geometry.location.lat());
                                setFieldValue('longitude', place.geometry.location.lng());
                                setFieldValue('coordinates', Utils.joinCoordinates(place.geometry.location.lat(), place.geometry.location.lng()));
                                setFieldValue('isInvalid', false);
                            }
                        }
                    }}
                />
            </Col>

            {(values.latitude != null && values.latitude != '') && <Col xs={12} style={{ minHeight: '280px' }}>
                <CustomGoogleMap
                    center={(values.latitude !== "" && values.longitude !== "") ? { lat: values.latitude, lng: values.longitude } : null}
                    isSearchable={false}
                    streetViewControl={true}
                    defaultZoom={props.zoom}
                    markerCenter={(values.latitude !== "" && values.longitude !== "") ? { lat: values.latitude, lng: values.longitude } : null}
                    onDragEnd={async (position: any) => {
                        setFieldValue('latitude', position.lat);
                        setFieldValue('longitude', position.lng);
                        setFieldValue('coordinates', Utils.joinCoordinates(position.lat, position.lng));
                    }}
                    onLoad={(refs: any) => {
                        if(values.latitude && values.longitude){
                            Utils.dinamicZoom(refs.map, values.latitude, values.longitude);
                        }
                    }}
                />
            </Col>}

            {(values.latitude != null && values.latitude != '') && <Col xs={12}>
                <Row>
                    <Col xs={'auto'} className={'pt-2 cursor-hand'} style={{ userSelect: 'none' }}
                        onClick={() => {
                            setShowInfo(!showInfo);
                        }}
                    >
                        <LightbulbIcon />
                    </Col>
                    <Col xs={true}>
                        <Row className={'pt-2'}>
                            <Col xs={12} className={'mb-2 fw-bold cursor-hand'} style={{ userSelect: 'none' }}
                                onClick={() => {
                                    setShowInfo(!showInfo);
                                }}
                            >Drag Location Marker To Adjust</Col>
                            {showInfo && <Col xs={12}>You can adjust the position of the marker by dragging it. The coordinates will change but the rest of the fields will stay the same.
                                <p className='ms-0'>If you wish to search a new location, go to the address bar to search again.</p>
                            </Col>}
                        </Row>
                    </Col>
                </Row>
            </Col>}
            
            {(values.latitude != null && values.latitude != '') && <>
                <Col xs={12} className='mt-5'>
                    <Form.Group>
                        <Form.Label>{fields.siteName.label}</Form.Label>
                        <Form.Control
                            type={'text'}
                            autoComplete='off'
                            placeholder={fields.siteName.placeholder}
                            disabled={false}
                            value={values.siteName}
                            onChange={(e) => {
                                setFieldValue('siteName', e.target.value);
                            }}
                            isInvalid={!!(errors && errors.siteName)}
                        />
                        <Form.Control.Feedback type="invalid">{errors && errors.siteName as ReactNode}</Form.Control.Feedback>
                    </Form.Group>
                </Col>

                <Col xs={12}>
                    <Form.Group>
                        <Form.Label>{fields.street.label}</Form.Label>
                        <Form.Control
                            type={'text'}
                            autoComplete='off'
                            placeholder={fields.street.placeholder}
                            disabled={false}
                            value={values.street}
                            onChange={(e) => {
                                setFieldValue('street', e.target.value);
                            }}
                            isInvalid={!!(errors && errors.street)}
                        />
                        <Form.Control.Feedback type="invalid">{errors && errors.street as ReactNode}</Form.Control.Feedback>
                    </Form.Group>
                </Col>

                <Col xs={12}>
                    <Form.Group>
                        <Form.Label>{fields.blockNo.label}</Form.Label>
                        <Form.Control
                            type={'text'}
                            autoComplete='off'
                            placeholder={fields.blockNo.placeholder}
                            disabled={false}
                            value={values.blockNo}
                            onChange={(e) => {
                                setFieldValue('blockNo', e.target.value);
                            }}
                            isInvalid={!!(errors && errors.blockNo)}
                        />
                        <Form.Control.Feedback type="invalid">{errors && errors.blockNo as ReactNode}</Form.Control.Feedback>
                    </Form.Group>
                </Col>

                <Col xs={12}>
                    <Form.Group>
                        <Form.Label>{fields.unitNo.label}</Form.Label>
                        <Form.Control
                            type={'text'}
                            autoComplete='off'
                            placeholder={fields.unitNo.placeholder}
                            disabled={false}
                            value={values.unitNo}
                            onChange={(e) => {
                                setFieldValue('unitNo', e.target.value);
                            }}
                            isInvalid={!!(errors && errors.unitNo)}
                        />
                        <Form.Control.Feedback type="invalid">{errors && errors.unitNo as ReactNode}</Form.Control.Feedback>
                    </Form.Group>
                </Col>

                <Col xs={12}>
                    <Form.Group>
                        <Form.Label>{fields.postalCode.label}</Form.Label>
                        <Form.Control
                            type={'text'}
                            autoComplete='off'
                            placeholder={fields.postalCode.placeholder}
                            disabled={false}
                            value={values.postalCode}
                            onChange={(e) => {
                                setFieldValue('postalCode', e.target.value);
                            }}
                            isInvalid={!!(errors && errors.postalCode)}
                        />
                        <Form.Control.Feedback type="invalid">{errors && errors.postalCode as ReactNode}</Form.Control.Feedback>
                    </Form.Group>
                </Col>

                <Col xs={12}>
                    <Form.Group>
                        <Form.Label>{fields.coordinates.label}</Form.Label>
                        <Form.Control
                            type={'text'}
                            autoComplete='off'
                            placeholder={fields.coordinates.placeholder}
                            disabled={false}
                            readOnly={true}
                            value={Utils.getDataValue(values, 'coordinates')}
                            onChange={(e) => {
                                setFieldValue('coordinates', e.target.value);
                            }}
                            isInvalid={!!(errors && errors.coordinates)}
                        />
                        <Form.Control.Feedback type="invalid">{errors && errors.coordinates as ReactNode}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                
                <Col xs={12}>
                    <Form.Group>
                        <Form.Label>{fields.contactPersonName.label}</Form.Label>
                        <Form.Control
                            type={'text'}
                            autoComplete='off'
                            placeholder={fields.contactPersonName.placeholder}
                            disabled={false}
                            value={values.contactPersonName}
                            onChange={(e) => {
                                setFieldValue('contactPersonName', e.target.value);
                            }}
                            isInvalid={!!(errors && errors.contactPersonName)}
                        />
                        <Form.Control.Feedback type="invalid">{errors && errors.contactPersonName as ReactNode}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                
                <Col xs={12}>
                    <Form.Group>
                        <Form.Label>{fields.contactPersonPhoneNumber.label}</Form.Label>
                        <Form.Control
                            type={'text'}
                            autoComplete='off'
                            placeholder={fields.contactPersonPhoneNumber.placeholder}
                            disabled={false}
                            value={values.contactPersonPhoneNumber}
                            onChange={(e) => {
                                setFieldValue('contactPersonPhoneNumber', e.target.value);
                            }}
                            isInvalid={!!(errors && errors.contactPersonPhoneNumber)}
                        />
                        <Form.Control.Feedback type="invalid">{errors && errors.contactPersonPhoneNumber as ReactNode}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                
                <Col xs={12}>
                    <Form.Group>
                        <Form.Label>{fields.contactPersonNameTwo.label}</Form.Label>
                        <Form.Control
                            type={'text'}
                            autoComplete='off'
                            placeholder={fields.contactPersonNameTwo.placeholder}
                            disabled={false}
                            value={values.contactPersonNameTwo}
                            onChange={(e) => {
                                setFieldValue('contactPersonNameTwo', e.target.value);
                            }}
                            isInvalid={!!(errors && errors.contactPersonNameTwo)}
                        />
                        <Form.Control.Feedback type="invalid">{errors && errors.contactPersonNameTwo as ReactNode}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                
                <Col xs={12}>
                    <Form.Group>
                        <Form.Label>{fields.contactPersonPhoneNumberTwo.label}</Form.Label>
                        <Form.Control
                            type={'text'}
                            autoComplete='off'
                            placeholder={fields.contactPersonPhoneNumberTwo.placeholder}
                            disabled={false}
                            value={values.contactPersonPhoneNumberTwo}
                            onChange={(e) => {
                                setFieldValue('contactPersonPhoneNumberTwo', e.target.value);
                            }}
                            isInvalid={!!(errors && errors.contactPersonPhoneNumberTwo)}
                        />
                        <Form.Control.Feedback type="invalid">{errors && errors.contactPersonPhoneNumberTwo as ReactNode}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </>}

            {(values.latitude != null && values.latitude != '') && <Col xs={12} className='text-end mt-5'>
                <a href={'/'}
                    className={'link-primary'}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        
                        setMore(!more);
                    }}
                >{more ? 'Show less' : 'Show more'}</a>
            </Col>}
        </Row>
    }
    const setOtherForm = () => {
        return <Row className={'g-2'}>
            <Col xs={12}>
                <Row className={'g-2 pt-5 pb-5'}>

                    <Col xs={12}>
                        <Form.Group>
                            <Form.Label>{fields.zoneId.label}</Form.Label>
                            <SmartDropdown
                                isInvalid={!!errors.zoneId}
                                errorText={errors.zoneId}

                                componentTitle={'Zone'}
                                componentApi={'zone'}
                                componentId={'zoneId'}
                                componentName={'zoneName'}

                                placeholder={fields.zoneId.placeholder}
                                value={values.zoneId}
                                label={values.zoneName}

                                disabled={false}
                                showDefault={false}
                                isInfiniteScroll={false}

                                onChange={async (value: any, item: any, i: any) => {
                                    await setFieldValue('zoneId', value);
                                    await setFieldValue('zoneName', item.title);
                                }}
                                onClear={async () => {
                                    await setFieldValue('zoneId', null);
                                    await setFieldValue('zoneName', '');
                                }}
                            />
                            <Form.Control.Feedback type="invalid">{errors && errors.zoneId as ReactNode}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col xs={12}>
                        <Form.Group>
                            <Form.Label>{fields.remarks.label} <small>{fields.remarks.labelDetails}</small></Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                id={fields.remarks.id}
                                placeholder={fields.remarks.placeholder}
                                value={values.remarks}
                                onChange={async (e) => {
                                    Utils.limitRows(e.target.value, 5, async (value: any) => {
                                        await setFieldValue('remarks', value);
                                    });
                                }}
                                isInvalid={!!errors.remarks}
                            />
                            <Form.Control.Feedback type="invalid">{errors && errors.remarks as ReactNode}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col xs={12} className='mb-2'>
                        <Form.Group>
                            <Form.Check
                                id={fields.copySiteRemarksToJob.id}
                                label={fields.copySiteRemarksToJob.label}
                                checked={values.copySiteRemarksToJob}
                                onChange={async (e) => {
                                    await setFieldValue('copySiteRemarksToJob', e.target.checked);
                                }}
                                isInvalid={!!errors.copySiteRemarksToJob}
                                feedback={errors.copySiteRemarksToJob}
                            />
                            <Form.Control.Feedback type="invalid">{errors && errors.copySiteRemarksToJob as ReactNode}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col xs={12}>
                        <Form.Group>
                            <Form.Label>{fields.driverId.label}</Form.Label>
                            <Form.Control
                                as = {RSuiteCheckPicker}
                                isInvalid={!!errors.driverId}
                                
                                id={fields.driverId.id}
                                placeholder={fields.driverId.placeholder}
                                items={driverItems}
                                value={values.driverId}
                                isSelectedAll={false}
                                isLoading={isLoadingDriver}
                                searchable={true}
                                countable={true}
                                cleanable={true}
                                showSelectAll={false}
                                isDefault={true}
                                onClick={async () => {
                                    let params = {
                                        currentPage: 1,
                                        pageSize: Utils.getMaxPageSize(),
                                        searchQuery: '',
                                        sortColumn: 'updated',
                                        sortDir: 'asc',
                                    };
                                    dispatch(slice.callReadDriverApi(params, () => {}));
                                }}
                                onChange={async (values: any) => {
                                    await setFieldValue('driverId', values);
                                }}
                                onClear={async () => {
                                    await setFieldValue('driverId', []);
                                }}
                            />
                            <Form.Control.Feedback type="invalid">{errors.driverId}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col xs={12}>
                        <Form.Group>
                            <Form.Label>{fields.instructions.label} <small>{fields.instructions.labelDetails}</small></Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                id={fields.instructions.id}
                                placeholder={fields.instructions.placeholder}
                                value={values.instructions}
                                onChange={async (e) => {
                                    Utils.limitRows(e.target.value, 5, async (value: any) => {
                                        await setFieldValue('instructions', value);
                                    });
                                }}
                                isInvalid={!!errors.instructions}
                            />
                            <Form.Control.Feedback type="invalid">{errors && errors.instructions as ReactNode}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    
                    <Col xs={12} className='mt-5'>
                        <Form.Group>
                            <Form.Check
                                inline
                                type={'radio'}
                                id={fields.weekdayId.id + '_1'}
                                label={'All'}
                                checked={values.weekdaysAvailability === CustomerSiteAvailability.All}
                                onChange={async (e) => {
                                    await setFieldValue('weekdaysAvailability', CustomerSiteAvailability.All);
                                }}
                            />
                            <Form.Check
                                inline
                                type={'radio'}
                                id={fields.weekdayId.id + '_2'}
                                label={'Selected hours'}
                                checked={values.weekdaysAvailability === CustomerSiteAvailability.SelectedHours}
                                onChange={async (e) => {
                                    await setFieldValue('weekdaysAvailability', CustomerSiteAvailability.SelectedHours);
                                }}
                            />
                            <Form.Check
                                inline
                                type={'radio'}
                                id={fields.weekdayId.id + '_3'}
                                label={'Not Available'}
                                checked={values.weekdaysAvailability === CustomerSiteAvailability.NotAvailable}
                                onChange={async (e) => {
                                    await setFieldValue('weekdaysAvailability', CustomerSiteAvailability.NotAvailable);
                                }}
                            />
                        </Form.Group>
                    </Col>
                    {(values.weekdaysAvailability === CustomerSiteAvailability.SelectedHours) && <Col xs={12}>
                        <Form.Group>
                            <Form.Control
                                as = {RSuiteCheckPicker}
                                isInvalid={!!errors.weekdayId}
                                
                                id={fields.weekdayId.id}
                                placeholder={fields.weekdayId.placeholder}
                                items={weekdayItems}
                                value={values.weekdayId}
                                isSelectedAll={weekdayIsSelectAll}
                                isLoading={weekdayIsLoading}
                                searchable={true}
                                countable={true}
                                cleanable={true}
                                showSelectAll={true}
                                isDefault={true}
                                placement={'topStart'}
                                onClick={async () => {
                                    let params = {    
                                        currentPage: 1,
                                        pageSize: Utils.getMaxPageSize(),
                                        searchQuery: '',
                                        sortColumn: 'order',
                                        sortDir: 'asc',
                                    };
                                    dispatch(slice.callReadWeekdayTimesApi(params, () => {}));
                                }}
                                onSelectAll={async (checked: any, isIndeterminate: any) => {
                                    if(checked){
                                        await setFieldValue('weekdayId', weekdayItems.map((x: any) => x.value));
                                        dispatch(slice.setWeekdayIsSelectAll(true));
                                    } else {
                                        await setFieldValue('weekdayId', []);
                                        dispatch(slice.setWeekdayIsSelectAll(false));
                                    }
                                }}
                                onChange={async (values: any) => {
                                    await setFieldValue('weekdayId', values);
                                    
                                    if(weekdayItems.length > 0){
                                        dispatch(slice.setWeekdayIsSelectAll((values.length === weekdayItems.length)));
                                    } else {
                                        dispatch(slice.setWeekdayIsSelectAll(false));
                                    }
                                }}
                                onClear={async () => {
                                    await setFieldValue('weekdayId', []);
                                    dispatch(slice.setWeekdayIsSelectAll(false));
                                }}
                            />
                            <Form.Control.Feedback type="invalid">{errors.weekdayId}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>}
                    
                    <Col xs={12} className='mt-5'>
                        <Form.Group>
                            <Form.Check
                                inline
                                type={'radio'}
                                id={fields.weekendId.id + '_1'}
                                label={'All'}
                                checked={values.weekendAvailability === CustomerSiteAvailability.All}
                                onChange={async (e) => {
                                    await setFieldValue('weekendAvailability', CustomerSiteAvailability.All);
                                }}
                            />
                            <Form.Check
                                inline
                                type={'radio'}
                                id={fields.weekendId.id + '_2'}
                                label={'Selected hours'}
                                checked={values.weekendAvailability === CustomerSiteAvailability.SelectedHours}
                                onChange={async (e) => {
                                    await setFieldValue('weekendAvailability', CustomerSiteAvailability.SelectedHours);
                                }}
                            />
                            <Form.Check
                                inline
                                type={'radio'}
                                id={fields.weekendId.id + '_3'}
                                label={'Not Available'}
                                checked={values.weekendAvailability === CustomerSiteAvailability.NotAvailable}
                                onChange={async (e) => {
                                    await setFieldValue('weekendAvailability', CustomerSiteAvailability.NotAvailable);
                                }}
                            />
                        </Form.Group>
                    </Col>
                    {(values.weekendAvailability === CustomerSiteAvailability.SelectedHours) && <Col xs={12}>
                        <Form.Group>
                            <Form.Control
                                as = {RSuiteCheckPicker}
                                isInvalid={!!errors.weekendId}
                                
                                id={fields.weekendId.id}
                                placeholder={fields.weekendId.placeholder}
                                items={weekendItems}
                                value={values.weekendId}
                                isSelectedAll={weekendIsSelectAll}
                                isLoading={weekendIsLoading}
                                searchable={true}
                                countable={true}
                                cleanable={true}
                                showSelectAll={true}
                                isDefault={true}
                                placement={'topStart'}
                                onClick={async () => {
                                    let params = {    
                                        currentPage: 1,
                                        pageSize: Utils.getMaxPageSize(),
                                        searchQuery: '',
                                        sortColumn: 'order',
                                        sortDir: 'asc',
                                    };
                                    dispatch(slice.callReadWeekendTimesApi(params, () => {}));
                                }}
                                onSelectAll={async (checked: any, isIndeterminate: any) => {
                                    if(checked){
                                        await setFieldValue('weekendId', weekendItems.map(x => x.value));
                                        dispatch(slice.setWeekendIsSelectAll(true));
                                    } else {
                                        await setFieldValue('weekendId', []);
                                        dispatch(slice.setWeekendIsSelectAll(false));
                                    }
                                }}
                                onChange={async (values: any) => {
                                    await setFieldValue('weekendId', values);
                                    
                                    if(weekendItems.length > 0){
                                        dispatch(slice.setWeekendIsSelectAll((values.length === weekendItems.length)));
                                    } else {
                                        dispatch(slice.setWeekendIsSelectAll(false));
                                    }
                                }}
                                onClear={async () => {
                                    await setFieldValue('weekendId', []);
                                    dispatch(slice.setWeekendIsSelectAll(false));
                                }}
                            />
                            <Form.Control.Feedback type="invalid">{errors.weekendId}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>}
                </Row>
            </Col>
        </Row>
    }


    return <Offcanvas
        backdrop={'static'}
        enforceFocus={false}
        scroll={false}
        placement={'end'}
        show={show}
        onHide={() => {
            onCancel();
        }}
        style={{ width: '600px' }}
    >
        <FormikContext.Provider value={formik}>
            {setHeader()}
            <Offcanvas.Body>
                <Row className={'g-2'}>
                    <Col xs={12}>{setForm()}</Col>
                    {(more && (values.latitude != null && values.latitude != '')) && <Col xs={12}>{setOtherForm()}</Col>}
                </Row>
                {isLoading && <Loader center backdrop content={'loading'} style={{ zIndex: '5' }} />}
            </Offcanvas.Body>
            {setFooter()}
        </FormikContext.Provider>
    </Offcanvas>
}

export default FormComponent;
