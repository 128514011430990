import React, { Component } from 'react';

import { 
  Box,
  Grid,
  Typography,
  CircularProgress,
} from '@material-ui/core';


class OneStatusItem extends Component {
  
	constructor(props){
		super(props);

		let active = ((props.active === true || props.active === false) ? props.active : false);
		let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
		let disabled = ((props.disabled === true || props.disabled === false) ? props.disabled : true);
		let title = props.title ? props.title : null;
		let numb = props.numb ? props.numb : null;
		let text = props.text ? props.text : null;
		let pb = props.pb ? props.pb : {xs: 0, lg: 1, xl: 2};

		this.state = {
			active: active,
			isLoading: isLoading,
			disabled: disabled,
			title: title,
			numb: numb,
			text: text,
			pb: pb,
		}
	}
	
	
	componentWillReceiveProps(nextProps) {
		if (nextProps.active !== this.state.active) {
			this.setState({
				active: nextProps.active
			});
		}
		if (nextProps.isLoading !== this.state.isLoading) {
			this.setState({
				isLoading: nextProps.isLoading
			});
		}
		if (nextProps.title !== this.state.title) {
			this.setState({
				title: nextProps.title
			});
		}
		if (nextProps.numb !== this.state.numb) {
			this.setState({
				numb: nextProps.numb
			});
		}
		if (nextProps.text !== this.state.text) {
			this.setState({
				text: nextProps.text
			});
		}
		if (nextProps.pb !== this.state.pb) {
			this.setState({
				pb: nextProps.pb
			});
		}
		if (nextProps.disabled !== this.state.disabled) {
			this.setState({
				disabled: nextProps.disabled
			});
		}
	}
	

	resetIsActive = () => {
		this.setState({
			active: false
		});
	}
	setIsActive = () => {
		this.setState({
			active: true
		});
	}
	getIsActive = () => {
		return this.state.active;
	}
	setNumb = (numb) => {
		this.setState({
			numb: numb
		});
	}
	getNumb = () => {
		return this.state.numb;
	}

	
	render() {
		return <Box className={"one-status-item-component " + this.props.className}>
			<Grid container
				id={this.state.text}
				className={'item cursor-hand ' + ((this.state.active) ? 'active' : '') + (this.state.disabled ? ' disabled' : '')}
				onClick={() => {
					if(this.props.onClick){
						this.props.onClick(this);
					}
				}}
			>
                {this.state.title && <Box clone pb={this.state.pb} textAlign={"center"}>
                    <Grid item xs={12}>
                        <Typography noWrap component={'div'} id={this.props.className}  className={"title"}>{this.state.title}</Typography>
                    </Grid>
                </Box>}
				{
				this.state.isLoading
				?
				<Box clone pb={this.state.pb} textAlign={"center"}>
                    <Grid item xs={12}>
						<CircularProgress />
                    </Grid>
                </Box>
				:
				<Box clone pb={this.state.pb} textAlign={"center"}>
                    <Grid item xs={12}>
                        <Typography component={'div'} className={"numb"}>{this.state.numb}</Typography>
                    </Grid>
                </Box>
				}
                {this.state.text && <Box clone textAlign={"center"}>
                    <Grid  item xs={12}>
                        <Typography id={this.state.text}  component={'div'} className={"text"}>{this.state.text}</Typography>
                    </Grid>
                </Box>}
            </Grid>
		</Box>;
	}
}

export default OneStatusItem;
