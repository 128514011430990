import React, { Component } from 'react';

import { 
    Box,
    TextField,
    MenuItem
} from '@material-ui/core';

import ManageLabel from './ManageLabel.jsx';


class ManageSelect extends Component {
  
	constructor(props){
		super(props);

        let label = props.label ? props.label : "";
        let labelComponent = props.labelComponent ? props.labelComponent : null;
        let name = props.name ? props.name : "";
        let value = props.value ? props.value : "";
        let type = props.type ? props.type : "text";
        let autoComplete = props.autoComplete ? props.autoComplete : "";
        let placeholder = props.placeholder ? props.placeholder : "";
        let className = props.className ? props.className : "";
        let autoFocus = ((this.props.autoFocus === true || this.props.autoFocus === false) ? this.props.autoFocus : false);
        let disabled = ((this.props.disabled === true || this.props.disabled === false) ? this.props.disabled : false);
        let readOnly = ((this.props.readOnly === true || this.props.readOnly === false) ? this.props.readOnly : false);
        let error = ((this.props.error === true || this.props.error === false) ? this.props.error : false);
        let fullWidth = ((this.props.fullWidth === true || this.props.fullWidth === false) ? this.props.fullWidth : true);
        let multiline = ((this.props.multiline === true || this.props.multiline === false) ? this.props.multiline : false);
        let required = ((this.props.required === true || this.props.required === false) ? this.props.required : false);
        let options = props.options ? props.options : [];

		this.state = {
            label: label,
            labelComponent: labelComponent,

            options: options,

            name: name,
            value: value,
            placeholder: placeholder,
            type: type,
            className: className,

            disabled: disabled,
            readOnly: readOnly,
            required: required,

            autoComplete: autoComplete,
            autoFocus: autoFocus,
            multiline: multiline,
            fullWidth: fullWidth,

            error: error
		}
    }
    

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.state.value) {
			this.setState({
				value: nextProps.value
			});
		}
        if (nextProps.options !== this.state.options) {
			this.setState({
				options: nextProps.options
			});
		}
        if (nextProps.type !== this.state.type) {
			this.setState({
				type: nextProps.type
			});
		}
        if (nextProps.disabled !== this.state.disabled) {
			this.setState({
				disabled: nextProps.disabled
			});
        }
        if (nextProps.readOnly !== this.state.readOnly) {
			this.setState({
				readOnly: nextProps.readOnly
			});
        }
        if (nextProps.error !== this.state.error) {
			this.setState({
				error: nextProps.error
			});
		}
    }
    

    setPlaceholder = () => {
        if(this.state.placeholder && this.state.placeholder !== ''){
            return <MenuItem value="" disabled>{this.state.placeholder}</MenuItem>
        }
    };


	render() {
		return <Box className={"manage-input-component " + this.state.className}>
            {this.state.label && <ManageLabel 
                className={"custom-label"}
                label={this.state.label}
                labelComponent={this.state.labelComponent}
                disabled={this.state.disabled}
                error={this.state.error}
                required={this.state.required}
            />}
            <TextField 
                className={"custom-input"}
                select={true}
                value={this.state.value}
                name={this.state.name}
                type={this.state.type}
                autoComplete={this.state.autoComplete}
                autoFocus={this.state.autoFocus}
                disabled={this.state.disabled}
                readOnly={this.state.readOnly}
                error={this.state.error}
                fullWidth={this.state.fullWidth}
                multiline={this.state.multiline}
                placeholder={this.state.placeholder}
                required={this.state.required}
                onChange={(event) => {
                    if(this.props.onChange)
                        this.props.onChange(event);
                }}
            >
                {this.setPlaceholder()}
                {this.state.options.map(option => (
                    <MenuItem key={option.value} value={option.value} data-item={option.item ? JSON.stringify(option.item) : ""}>{option.label}</MenuItem >
                ))}
            </TextField>
		</Box>;
	}
}

export default ManageSelect;
