import React from 'react';

import { 
    Modal,
    Button,
    Spinner,
} from 'react-bootstrap-v5';

import { useDispatch, useSelector } from "react-redux";
import {RootState} from '../../../setup'
import reduxRemovePopupSlice from './reduxRemovePopupSlice';


const ReduxRemovePopup = (props: any) => {
    const dispatch = useDispatch();
    const { show, isLoading } = useSelector((state: RootState) => state.reduxRemovePopupSlice);

    React.useEffect(() => {
        dispatch(reduxRemovePopupSlice.setLoading(props.loading));
    }, [props.loading]);

    return <Modal 
        show={show} 
        size={'sm'} 
        backdrop={'static'} 
        onHide={() => {
            dispatch(reduxRemovePopupSlice.setHide());
            
            if(props.onCancel){
                props.onCancel();
            }
        }} 
        enforceFocus={false}
    >
        <Modal.Header className={'px-4 py-3'} closeButton={false}>
            <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body className={'px-5 py-7'}>{props.body}</Modal.Body>

        <Modal.Footer className={'p-3'} style={{ justifyContent: 'start' }}>
            <Button
                type={'button'}
                variant={'danger'}
                size={'sm'}
                disabled={isLoading}
                onClick={() => {
                    dispatch(reduxRemovePopupSlice.setHide());

                    if(props.onSave){
                        props.onSave();
                    }
                }}
            >
                {props.save}
                {isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
            </Button>

            <Button
                type={'button'}
                variant={'light'}
                size={'sm'}
                disabled={isLoading}
                onClick={() => {
                    dispatch(reduxRemovePopupSlice.setHide());
            
                    if(props.onCancel){
                        props.onCancel();
                    }
                }}
            >
                {props.cancel}
                {isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
            </Button>
        </Modal.Footer>
    </Modal>
}


export default ReduxRemovePopup;