import React from 'react';

import {
  Button,
  Popper,
  Grow,
  ClickAwayListener
} from '@material-ui/core';


const CustomDropdown = props => {
    const { text, color, leftIcon, rightIcon, variant, children, className, classNamePopper } = props;

    const [open, setOpen] = React.useState((props.open === true || props.open === false) ? props.open : false);
    const [isClickAway, setIsClickAway] = React.useState((props.isClickAway === true || props.isClickAway === false) ? (props.isClickAway === true ? 'onClick' : false) : 'onClick');

    const anchorRef = React.useRef(null);


    const handleToggle = (e) => {
      e.preventDefault();
      e.stopPropagation();

      setOpen(prevOpen => !prevOpen);
    };
  
    const handleClose = event => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
  
      setOpen(false);
    };


    const prevOpen = React.useRef(open);
    React.useEffect(() => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
      }
      
      prevOpen.current = open;

      setIsClickAway((props.isClickAway === true || props.isClickAway === false) ? (props.isClickAway === true ? 'onClick' : false) : 'onClick');
    }, [open, props.isClickAway]);


    if(children) {
        return (
            <>
                <Button
                  ref={anchorRef}
                  className={className + ((open) ? ' is-open' : '')}
                  style={{ 
                    height: '50px',
                    textTransform: 'unset',
                  }}
                  aria-haspopup="true"
                  color={(color ? color : 'primary')}
                  variant={(variant ? variant : 'contained')}
                  onClick={handleToggle}
                >
                  {leftIcon}{text}{rightIcon}
                </Button>
                <Popper open={open} 
                    anchorEl={anchorRef.current} 
                    role={undefined} 
                    transition
                    className={classNamePopper}
                    style={{ 
                        zIndex: 9999 
                    }}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ 
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' 
                            }}
                        >
                            <ClickAwayListener onClickAway={handleClose} mouseEvent={isClickAway}>
                                {children}
                            </ClickAwayListener>
                        </Grow>
                    )}
                </Popper>
            </>
        );
    } else {
        return null;
    }
  }

  export default CustomDropdown;