import styled from 'styled-components';
import { 
    Row,
    Col,
    Form,
    Tabs,
    Button,
} from "react-bootstrap-v5";


export const StyledTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  color: #444444;
`;
export const StyledFormLabel = styled(Form.Label)`
  font-size: 0.925rem !important;
`;
export const StyledFormText = styled.span`
  font-size: 0.925rem !important;
  user-select: none !important;
`;
export const StyledTabs = styled(Tabs)`
    .nav-item {
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        border-bottom: 5px solid transparent;
        border-radius: 0px;
        font-size: 0.925rem !important;
        color: #444444;

        &:hover {
            border-bottom: 5px solid #EFEFEF;
        }

        &.active {
            border-bottom: 5px solid #0066FF;
        }
    }
    
    ${props => {
        if(props.err && props.err.length > 0){
            return props.err.map(x => {
                return `
                    .nav-item:nth-child(${(x+1)}) {
                        border-bottom: 5px solid #FF0090;
                        color: #FF0090;
                    }
                `
            });
        }
    }}
`;
export const StyledButton = styled(Button)`
    border-radius: 0;
`;


// Accordion
export const StyledExpandIcon = styled.i`
  color: #D9E1E5;

  &:hover {
    color: #444444;
  }
`;
export const StyledHistoryLink = styled.a`
    display: inline-flex;
    margin-left: 15px;

    &:link, &:visited, &:active {
        color: #444444;
    }

    i {
        font-size: 21px;
        font-weight: 100;
        color: #444444;
    }

    &:hover {
        opacity: 0.7;
    }
`;


// Basic Info
export const StyledBasicShadow = styled(Row)`
    overflow: hidden;
    position: relative;
    box-shadow: 0px 0px 4px 0px #EFEFEF;
`;
export const StyledBasicCard = styled(Col)`
    border: 1px solid #EFEFEF;
    padding: 20px 25px;

    ${props => {
        if(props.hidebottomborder){
            return `
                border-bottom: 0px;
            `
        }
    }}

    &:hover {
        .expand-icon {
            color: #444444;
        }
    }

    ${props => {
        if(props.active){
            return `
                .expand-icon {
                    color: #444444;
                }
            `
        }
    }}
`;


// Top Section
export const StyledTopSection = styled(Col)`
    min-height: 50px;
    background-color: #EFEFEF;
    position: sticky;
    top: 0px;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: center;
`;
export const StyledPrevNextLink = styled.a`
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    color: #444444;

    &:link, &:visited, &:active {
        color: #444444;
    }

    &:hover {
        color: #C1C1C1;
    }
`;


// Salary
export const StyledCheckLink = styled.a`
    display: flex;

    &:link, &:visited, &:active {
        color: #444444;
    }

    i {
        font-size: 21px;
        font-weight: 100;
        color: #0047FF;
    }

    &:hover {
        opacity: 0.7;
    }
`;
export const StyledUncheckLink = styled.a`
    display: flex;

    &:link, &:visited, &:active {
        color: #444444;
    }

    i {
        font-size: 21px;
        font-weight: 100;
        color: #FF0000;
    }

    &:hover {
        opacity: 0.7;
    }
`;
export const StyledMonthlyAdHocButton = styled(Button)`
    border-radius: 0;
    background-color: white !important;
    color: #9F9F9F !important;
    border: 1px solid #9F9F9F !important;
    font-weight: 400 !important;

    ${props => {
        if(props.active){
            return `
                background-color: #D9D9D9 !important;
                color: #444444 !important;
                border: 1px solid #444444 !important;
            `
        }
    }}
`;
export const StyledExportButton = styled(Button)`
    border-radius: 0;
    background-color: white !important;
    color: #444444 !important;
    border: 1px solid black !important;
    font-weight: 400 !important;
`;
export const StyledMonthlyTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  color: #444444;
`;
export const StyledMonthlyPrice = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  color: #444444;
`;
export const StyledTableWrapper = styled.div`
    overflow-y: hidden;
    overflow-x: auto;
`;
export const StyledTable = styled.table`
  width: 100%;

  thead {
    tr {
        th {
            background-color: #EFEFEF !important;
            color: #444444 !important;
            font-weight: 400 !important;
            border: 1px solid #ECECEC !important;
            padding: 10px 9px;

            &:last-child {
                background-color: transparent !important;
                border: 1px solid transparent !important;
            }
        }
    }
  }

  tbody {
    tr {
        &:hover {
            > td {
                background-color: transparent !important;
            }
        }

        td {
            position: relative;
            color: #444444 !important;
            font-weight: 400 !important;
            border: 0px solid transparent !important;
            padding: 0px;

            input {
                border-radius: 0px;
            }

            &:last-child {
                background-color: transparent !important;
                border: 1px solid transparent !important;
                text-align: center;
            }
        }
    }
  }

  tfoot {
    tr {
        &:hover {
            > td {
                background-color: transparent !important;
            }
        }

        td {
            padding: 15px 0 0 0;
        }
    }
  }
`;