import React, { Component } from 'react';

import { 
  Box,
  Grid,
  Button,
  CircularProgress
} from '@material-ui/core';


class DataTableRemoveDialog extends Component {
  
  constructor(props){
		super(props);

    let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
    let removeMessage = props.removeMessage ? props.removeMessage : null;
    let yesBtn = props.yesBtn ? props.yesBtn : 'Yes, Please remove';
    let noBtn = props.noBtn ? props.noBtn : 'BACK';
    let title = props.title ? props.title : 'REMOVE';

		this.state = {
			isLoading: isLoading,
			removeMessage: removeMessage,
			yesBtn: yesBtn,
			noBtn: noBtn,
			title: title,
		}
  }
  

  componentWillReceiveProps(nextProps) {
		if (nextProps.isLoading !== this.state.isLoading) {
			this.setState({
				isLoading: nextProps.isLoading
			});
		}
  }
  

  handleOkClick = (e) => {
    if(this.props.onOk)
        this.props.onOk()
  }

  handleCloseClick = (e) => {
    if(this.props.onClose)
        this.props.onClose()
  }

  
  setHead = () => {
    return <Grid container className="no-propagation">
        <Box clone textAlign={"center"} className="no-propagation">
          <Grid item xs={12} className="head no-propagation">{this.state.title}</Grid>
        </Box>
    </Grid>
  }

  setBody = () => {
    return <Grid container className="no-propagation">
        <Box clone textAlign={"center"} className="no-propagation" pt={4} pb={7}>
            <Grid item xs={12} className="body no-propagation">
                {this.state.removeMessage
                ?
                this.state.removeMessage
                :
                <>Are you sure you want to remove this?</>
                }
            </Grid>
        </Box>
    </Grid>
  }

  setFoot = () => {
    return <Grid container className="no-propagation">
        <Box clone textAlign={"center"} className="no-propagation">
            <Grid item xs={12} className="foot no-propagation">
                <Button 
                    className={'cancel no-propagation'}
                    color={'default'} 
                    variant={'contained'}
                    style={{ marginRight: '15px' }}
                    onClick={this.handleCloseClick}
                >
                    {this.state.noBtn}
                </Button>
                <Button 
                    className={'yes no-propagation'}
                    color={'secondary'} 
                    variant={'contained'}
                    disabled={this.state.isLoading}
                    onClick={this.handleOkClick}
                >
                    {this.state.yesBtn}
                    {this.state.isLoading && <span style={{ paddingLeft: '10px' }}><CircularProgress size={15} style={{ color: 'white' }} /></span>}
                </Button>
            </Grid>
        </Box>
    </Grid>
  }


  render() {
    return <Box className="datatable-remove-dialog" p={2}>
        {this.setHead()}
        {this.setBody()}
        {this.setFoot()}
    </Box>;
  }
}

export default DataTableRemoveDialog;
