import React from 'react';

import Utils from '../../../../utils/utils';

import { 
  Box,
  Grid,
  IconButton,
  LinearProgress
} from '@material-ui/core';

import Icon from '@material-ui/core/Icon';


const PreviewImage = (props) => {
  const { index, file, noImage, handleRemove, url, contain } = props;

  const [indexProgress, setIndexProgress] = React.useState(props.indexProgress >= 0 ? props.indexProgress : null);
  const [progress, setProgress] = React.useState(props.progress ? props.progress : null);


  React.useEffect(() => {
    setIndexProgress(props.indexProgress >= 0 ? props.indexProgress : null);
    setProgress(props.progress ? props.progress : null);
  }, [props.indexProgress, props.progress]);


  const isImage = (type) => {
    if(type.indexOf("image") !== -1){
      return true;
    } else {
      return false;
    }
  }

  const setImage = (file) => {
    try {
      if(file !== null){
        if(isImage(file.type)){
          if(file.preview){
            return file.preview;
          } else {
            if(file.path){
              return file.path;
            } else {
              return '';
            }
          }
        } else {
          return '';
        }
      } else {
        return url;
      }
    } catch(e){
      return '';
    }
  }

  const setProgressBar = () => {
    if(index === indexProgress){
      if(progress){
        return <div className="dropzone-progress-bar">
          <LinearProgress variant="determinate" value={progress} />
          <Grid container alignContent={"center"} alignItems={"center"} style={{ height: '100%', textAlign: 'center' }}>
            <Grid item xs={12} className={"text-white"}>Uploading ({progress}%)</Grid>
          </Grid>
        </div>
      }
    } else if(indexProgress !== null && indexProgress < index){
      return <div className="dropzone-progress-bar">
        <Grid container alignContent={"center"} alignItems={"center"} style={{ height: '100%', textAlign: 'center' }}>
          <Grid item xs={12} className={"text-white"}>Please Wait...</Grid>
        </Grid>
      </div>
    }
  }

  const setRemoveBtn = () => {
    if(indexProgress !== null && indexProgress <= index){
      return null;
    } else {
      if(!props.disabled){
        return <div className="dropzone-remove-file">
            <IconButton
                variant="danger"
                icon="delete_forever"
                onClick={() => {handleRemove(index, url)}}
            >
              <Icon component={"i"}>delete</Icon>
            </IconButton>
        </div>
      } else {
        return null;
      }
    }
  }


  return (
    <Box 
      className={"dropzone-files-item " + ((indexProgress !== null && indexProgress <= index) ? ' blur' : '')}
      style={{
        cursor: (props.disabled) ? 'unset' : ((indexProgress !== null && indexProgress <= index) ? 'unset' : 'pointer')
      }}  
    >
      {setRemoveBtn()}
      {setProgressBar()}
      {
      (!props.disabled)
      ?
      <a 
        href={(setImage(file) !== '') ? setImage(file) : '#'} 
        target={"_blank"} 
        rel="noopener noreferrer"
        onClick={(e) => {
          if(indexProgress !== null && indexProgress <= index){
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      >
        <div 
            className={"dropzone-image div-img-" + (contain ? 'contain' : 'cover') + " " + ((setImage(file) !== '') ? '' : 'bg-size-unset') + ((indexProgress !== null && indexProgress === index) ? ' blur-' + progress : '')} 
            style={{ backgroundImage: "url('" + ((setImage(file) !== '') ? setImage(file) : noImage) + "')"}} 
        ></div>
        <div className="dropzone-details">{(file !== null) ? file.name : url.split('/').pop()}{(file !== null) ? ' - ' + Utils.bytesToSize(file.size) : ''}</div>
      </a>
      :
      <div 
        className={"dropzone-image div-img-" + (contain ? 'contain' : 'cover') + " " + ((setImage(file) !== '') ? '' : 'bg-size-unset') + ((indexProgress !== null && indexProgress === index) ? ' blur-' + progress : '')} 
        style={{ backgroundImage: "url('" + ((setImage(file) !== '') ? setImage(file) : noImage) + "')"}} 
      ></div>
      }
    </Box>
  );
}


export default PreviewImage;
