/* eslint-disable no-loop-func */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Yup from 'yup';

import * as dispatchCrudApi from '../../../api/CRUD/DispatchCRUD'
import Utils from "../../../utils/utils";
import { ParseResult } from "../../../utils/interfaces";
import { BillingTypes } from "../../../utils/enums";


export interface InitState {
  show: boolean,
  isLoading: boolean,
  jobIds: any,
  formValues: FormValues,
  formLabels: any | null,
  validationSchema: any | null,
}

interface ApiCallArgs {
  jobId: number | null,
  isBillable: boolean,
  isInvoiced: boolean,
  billedDate: Date,
  invoiceDate: Date | null,
  invoiceNumber: string,
  billingNote: string,
  customer: any,
  pricings: Array<any>,
}

interface FormValues {
  billingType: number,
  invoicePrefix: string,
  invoiceNumber: number,
  accountBillingProfileId: number | null,
  invoiceDate: Date | null,
  args: ApiCallArgs,
}


function NewReducer() {
  const name = 'invoiceThisJob';


  const initArgs: ApiCallArgs = {
    jobId: null,
    isBillable: false,
    isInvoiced: false,
    billedDate: new Date(),
    invoiceDate: null,
    invoiceNumber: '',
    billingNote: '',
    customer: null,
    pricings: [],
  }

  const formValues: FormValues = {
    billingType: BillingTypes.Invoice,
    invoicePrefix: '',
    invoiceNumber: 0,
    accountBillingProfileId: null,
    invoiceDate: null,
    args: initArgs,
  }

  const formLabels = {
    invoiceNumber: {
      field: {
        type: 'number',
        id: 'invoiceNumber',
        name: 'invoiceNumber',
        placeholder: 'Invoice number',
      },
      label: 'Invoice number',
      labelPurchase: 'Purchase number',
      labelExpense: 'Expense number',
      required: true,
    },
  }

  const validationSchema = Yup.object().shape({
    billingType: Yup.number().nullable(),
    invoicePrefix: Yup.string().nullable(),
    invoiceNumber: Yup.number().when(['billingType'], (billingType) => {
      if(Utils.parseFloat(billingType) == BillingTypes.Purchase){
        return Yup.number().nullable().min(0).required().label(formLabels.invoiceNumber.labelPurchase);
      } else if(Utils.parseFloat(billingType) == BillingTypes.Expense){
        return Yup.number().nullable().min(0).required().label(formLabels.invoiceNumber.labelExpense);
      } else {
        return Yup.number().nullable().min(0).required().label(formLabels.invoiceNumber.label);
      }
    }),
  });


  const initialState: InitState = {
    show: false,
    isLoading: false,
    jobIds: null,
    formValues: formValues,
    formLabels: formLabels,
    validationSchema: validationSchema,
  };


  const reducers = {
    hide: (state: InitState) => {
      state.isLoading = false;
      state.show = false;
    },


    startPush: (state: InitState) => {
      state.isLoading = true;
    },
    finishPush: (state: InitState, action: PayloadAction<{ args: ApiCallArgs, data: any, errorMessage: string | null, isError: boolean, accountBillingProfileId: number | null, billingType: number, jobIds: any }>) => {
      let data = (action.payload && action.payload.data) ? action.payload.data : null;
      let failedJobs = (data && data.failedJobs) ? data.failedJobs : [];
      let successJobs = (data && data.successJobs) ? data.successJobs : [];

      if(successJobs && successJobs.length > 0){
        let successJob = successJobs[0];
        let message = (successJob && successJob.message && successJob.message != '') ? successJob.message : null;
        if(message){
          Utils.toast(message, 'success');
        }

        state.show = false;

      } else if(failedJobs && failedJobs.length > 0){
        let failedJob = failedJobs[0];
        
        if(failedJob && failedJob.promptInvoiceNumber){
          state.formValues = {
            billingType: action.payload.billingType,
            accountBillingProfileId: action.payload.accountBillingProfileId,
            args: action.payload.args,

            invoicePrefix: failedJob.invoiceNumberPrefix.replace('-', ''),
            invoiceNumber: failedJob.lastInvoiceNumber,
            invoiceDate: failedJob.invoiceDate,
          };

          state.jobIds = action.payload.jobIds;
          state.show = true;
        } else {
          let message = (failedJob && failedJob.message && failedJob.message != '') ? failedJob.message : null;
          if(message){
            Utils.toast(message, 'error');
          }
        }
      }

      state.isLoading = false;
    },
  };


  const apis = {
    callPushInvoiceApi: (jobId: number | null, args: ApiCallArgs, billingType: number) => async (dispatch: any) => {
      dispatch(actions.startPush());

      let params: any = args;
      if(params){
        try {
          delete params.isInvoiced;
        } catch(e){}
  
        try {
          if(params && params.pricings && params.pricings.length > 0){
            for(let i = 0; i < params.pricings.length; i++){
              if(params.pricings[i] && params.pricings[i].useBinWeightQuantity == false){
                delete params.pricings[i].jobStep;
              }
            }
          }
        } catch(e){}
      }

      let accountBillingProfileId = (params && params.customer && params.customer.accountBillingProfileId && params.customer.accountBillingProfileId != '') ? params.customer.accountBillingProfileId : null;
      // let path = (billingType == BillingTypes.Purchase) ? 'job/billing/purchase-order' : 'job/billing/invoice';
      let path = 'job/billing/' + jobId + '/invoice';

      await dispatchCrudApi.createApi(params, path).then(result => {
        let data = result.data;
        
        dispatch(actions.finishPush({ args: args, data: data, errorMessage: null, isError: false, accountBillingProfileId: accountBillingProfileId, billingType: billingType, jobIds: jobId }));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        dispatch(actions.finishPush({ args: initArgs, data: null, errorMessage: err, isError: true, accountBillingProfileId: null, billingType: BillingTypes.Invoice, jobIds: null }));
      });

    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();