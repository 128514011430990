import React, { Component } from 'react';

import { TagInput } from 'rsuite';
import "rsuite/dist/rsuite.min.css";


class RSuiteTagInput extends Component {
  
	constructor(props){
		super(props);

        this.tagInputRef = React.createRef();

        let disabled = ((props.disabled === true || props.disabled === false) ? props.disabled : false);
        let isDefault = ((props.isDefault === true || props.isDefault === false) ? props.isDefault : true);
        let placeholder = props.placeholder ? props.placeholder : '';
        let trigger = props.trigger ? props.trigger : ['Enter', 'Comma'];
        let value = props.value;

		this.state = {
            value: value,
            placeholder: placeholder,
            trigger: trigger,
            disabled: disabled,
            isDefault: isDefault,
		}
	}
    
    
    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.state.value) {
			this.setState({
				value: nextProps.value
			});
        }

        if (nextProps.disabled !== this.state.disabled) {
            let disabled = ((nextProps.disabled === true || nextProps.disabled === false) ? nextProps.disabled : false);
			this.setState({
				disabled: disabled
			});
		}
    }

    
    handleChange = (value) => {
        if(this.props.onChange){
            this.props.onChange(value);
        }
    }

    handleClean = (e) => {
        if(this.props.onClean){
            this.props.onClean(e);
        }
    }

    handleBlur = (e) => {
        try {
            const input = this.tagInputRef.current.target.querySelector('input');
            const enterKeyEvent = new KeyboardEvent('keydown', {
                key: 'Enter',
                bubbles: true,
                cancelable: true,
                shiftKey: false,
            });
            input.dispatchEvent(enterKeyEvent);
        }catch(e){}
    }


	render() {
		return <div className={'custom-rsuite-tag-input ' + (this.state.isDefault ? 'default-look ' : '') + this.props.className}>
            <TagInput
                ref={this.tagInputRef}
                
                value={this.state.value}
                placeholder={this.state.placeholder}
                disabled={this.state.disabled}
                trigger={this.state.trigger}

                onChange={this.handleChange}
                onClean={this.handleClean}
                onBlur={this.handleBlur}
            />
		</div>;
	}
}

export default RSuiteTagInput;