/* eslint-disable no-loop-func */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// import { ParseResult } from "../../../../utils/interfaces";


interface InitState {
  isMainLoading: boolean,
  step: string,
  step2Radio: boolean,
  step3aRadio: boolean,
  step3bRadio: boolean,
}


function NewReducer() {
  const name = 'payrollBetaLayout';


  const initialState: InitState = {
    isMainLoading: false,
    step: '1',
    step2Radio: false,
    step3aRadio: false,
    step3bRadio: false,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },

    setMainLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isMainLoading = action.payload;
    },

    setStep: (state: InitState, action: PayloadAction<string>) => {
      state.step = action.payload;
    },
    setStep2Radio: (state: InitState, action: PayloadAction<boolean>) => {
      state.step2Radio = action.payload;
    },
    setStep3aRadio: (state: InitState, action: PayloadAction<boolean>) => {
      state.step3aRadio = action.payload;
    },
    setStep3bRadio: (state: InitState, action: PayloadAction<boolean>) => {
      state.step3bRadio = action.payload;
    },
    back: (state: InitState) => {
      if(state.step === '4'){
        if(state.step2Radio){
          state.step = '3b';
        } else {
          state.step = '3a';
        }

      } else if(state.step === '3a' || state.step === '3b'){
        state.step = '2';

      } else if(state.step === '2'){
        state.step = '1';
      }
    },
    next: (state: InitState) => {
      if(state.step === '1'){
        state.step = '2';

      } else if(state.step === '2'){
        if(state.step2Radio){
          state.step = '3b';
        } else {
          state.step = '3a';
        }

      } else if(state.step === '3a' || state.step === '3b'){
        state.step = '4';
      }
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
  };
}


export default NewReducer();