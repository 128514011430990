import React from 'react';

import Utils from '../../../utils/utils';

import { 
  Row,
  Col,
  Button,
} from "react-bootstrap-v5";

import IconButton from "../../Buttons/IconButton";
import CategoryDialog from './CategoryDialog';
import RemoveDialog from '../../dialog/RemoveDialog';

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut, dispatchApiCallDelete } from '../../../../setup/redux/dispatch/actions'


const makeWasteTypeCategory = (Target) => {
      class Component extends React.Component {
        
        constructor(props) {
            super(props);

            this.componentName = 'wasteTypeCategoryDialog_';
            this.categoryAPI = 'WasteTypeCategory';
            this.categoryId = 'wasteTypeCategoryId';
            this.categoryName = 'wasteTypeCategoryName';

            this.targetRef = React.createRef();

            let category = [];

            let defaultValue = props.defaultValue ? props.defaultValue : null;
            if(defaultValue && defaultValue[this.categoryId] && defaultValue[this.categoryId] > 0){
                if(category.findIndex(x => x.value === defaultValue[this.categoryId]) === -1){
                    category.push({
                        value: defaultValue[this.categoryId],
                        title: defaultValue[this.categoryName],
                        item: defaultValue,
                    });
                }
            }

            let canAdd = ((props.canAdd === false) || (props.canAdd === true)) ? props.canAdd : true;
            let canEdit = ((props.canEdit === false) || (props.canEdit === true)) ? props.canEdit : true;
            let canRemove = ((props.canRemove === false) || (props.canRemove === true)) ? props.canRemove : true;

            this.state = {
                shouldClose: true, 

                category: category,

                isCreateNewDialog: false,

                newDialogItemLoading: false,
                newDialogItem: null,
                
                isRemoveDialog: false,
                removeRow: null,

                canAdd: canAdd,
                canEdit: canEdit,
                canRemove: canRemove,
            };
        }


        componentWillReceiveProps(nextProps) {
          if (nextProps.defaultValue !== this.state.defaultValue) {
            let category = this.state.category;

            let defaultValue = nextProps.defaultValue ? nextProps.defaultValue : null;
            if(defaultValue && defaultValue[this.categoryId] && defaultValue[this.categoryId] > 0){
              if(category.findIndex(x => x.value === defaultValue[this.categoryId]) === -1){
                category.push({
                  value: defaultValue[this.categoryId],
                  title: defaultValue[this.categoryName],
                  item: defaultValue,
                });
              }
            }
            this.setState({
              category: category
            });
          }
            
          this.reduxProps(nextProps);
        }


        /* API */
        reduxProps = nextProps => {
          Utils.reduxProps(nextProps,
            this.componentName + 'read' + ((this.props.customName && this.props.customName !== '') ? '_' + this.props.customName : ''),
            (data, isLoading, isError, err, statusCode, variables, callback) => {
              let arr = this.state.category;

              if(data && data.data && data.data.length > 0) {
                for (var i = 0; i < data.data.length; i++) {
                  let item = data.data[i];
                  let value = item[this.categoryId];
                  let title = item[this.categoryName];
                  let arrItem = {
                    value: value,
                    title: title,
                    item: item,
                  };

                  arr = Utils.addToArray(arr, value, arrItem);
                }
              }

              this.setState({
                category: arr,

                isLoading: isLoading,
                isError: isError,
                err: err,
              }, () => {
                this.props.clear();

                if(callback){
                  callback(arr);
                }
              });
            }
          );

          Utils.reduxProps(nextProps,
            this.componentName + 'create' + ((this.props.customName && this.props.customName !== '') ? '_' + this.props.customName : ''),
            (data, isLoading, isError, err, statusCode, variables, callback) => {
              let cat = null;
              let categoryArr = this.state.category;
              if(data){
                  cat = {
                      value: data[this.categoryId],
                      item: data,
                      title: data[this.categoryName]
                  };
                  categoryArr.unshift(cat);
              }
      
              this.setState({
                isCreateNewDialog: false,
                newDialogItemLoading: false,
                category: categoryArr,

                isLoading: isLoading,
                isError: isError,
                err: err,
              }, () => {
                this.props.clear();

                if(!this.state.isError){
                  Utils.toast("Successfully created!", 'success'); 
                }
            
                if(callback){
                  callback(cat);
                }
              });
            }
          );

          Utils.reduxProps(nextProps,
            this.componentName + 'update' + ((this.props.customName && this.props.customName !== '') ? '_' + this.props.customName : ''),
            (data, isLoading, isError, err, statusCode, variables, callback) => {
              let cat = null;
              let categoryArr = this.state.category;
              if(data){
                  cat = {
                      value: data[this.categoryId],
                      item: data,
                      title: data[this.categoryName]
                  };
                  categoryArr.unshift(cat);
              }
      
              this.setState({
                isCreateNewDialog: false,
                newDialogItemLoading: false,
                category: categoryArr,

                isLoading: isLoading,
                isError: isError,
                err: err,
              }, () => {
                this.props.clear();

                if(!this.state.isError){
                  Utils.toast("Successfully updated!", 'success'); 
                }
            
                if(callback){
                  callback(cat);
                }
              });
            }
          );

          Utils.reduxProps(nextProps,
            this.componentName + 'delete' + ((this.props.customName && this.props.customName !== '') ? '_' + this.props.customName : ''), 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
              this.setState({
                isLoading: isLoading,
                isError: isError,
                err: err,
              }, () => {
                this.props.clear();

                if(!this.state.isError){
                  Utils.toast("Successfully deleted!", 'success'); 
                }
            
                if(callback){
                  callback();
                }
              });
            }
          );
        }

        callReadApi = (callback = null) => {
          let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            sortColumn: 'updated',
            sortDir: 'desc',
          };
     
          this.props.dispatchApiCallGet(data, this.componentName + 'read' + ((this.props.customName && this.props.customName !== '') ? '_' + this.props.customName : ''), this.categoryAPI, null, callback, () => {});
        }
        
        callCreateApi = (data, callback = null) => {
          this.setState({
            newDialogItemLoading: true,
          });

          this.props.dispatchApiCallPost(data, this.componentName + 'create' + ((this.props.customName && this.props.customName !== '') ? '_' + this.props.customName : ''), this.categoryAPI, null, callback, () => {});
        }
        
        callUpdateApi = (data, callback = null) => {
          this.setState({
            newDialogItemLoading: true,
          });
          
          this.props.dispatchApiCallPut(data, this.componentName + 'update' + ((this.props.customName && this.props.customName !== '') ? '_' + this.props.customName : ''), this.categoryAPI, null, callback, () => {});
        }
    
        callDeleteApi = (data, callback = null) => {
          this.setState({
            newDialogItemLoading: true,
          });
          
          this.props.dispatchApiCallDelete(data, this.componentName + 'delete' + ((this.props.customName && this.props.customName !== '') ? '_' + this.props.customName : ''), this.categoryAPI, null, callback, () => {});
        }
        /* END API */


        /* DIALOGS */
        setRemoveDialog = () => {
          return <RemoveDialog 
            show={this.state.isRemoveDialog}
            isLoading={this.state.newDialogItemLoading}
            onCancel={() => {
              this.setState({
                isRemoveDialog: false,
                removeRow: null,
                shouldClose: true,
              });
            }}
            onRemove={() => {
              let item = this.state.removeRow;
                          
              let rowData = {
                [this.categoryId]: item.value,
              };
    
              this.callDeleteApi(rowData, () => {
                this.callReadApi(() => {
                  this.setState({
                    category: [],
                    isRemoveDialog: false,
                    newDialogItemLoading: false,
                    newDialogItem: null,
                    removeRow: null,
                    shouldClose: true,
                  }, () => {
                    if(this.props.onRemove){
                      this.props.onRemove(item);
                    }
                  });
                });
              });
            }}
          />
        }

        setCategoryDialog = () => {
          return <CategoryDialog 
            show={this.state.isCreateNewDialog}
            isLoading={this.state.newDialogItemLoading}
            item={this.state.newDialogItem}
            onHide={() => {
              this.setState({
                isCreateNewDialog: false,
                newDialogItem: null,
                shouldClose: true,
              });
            }}
            onSave={(value, item) => {
              if(item){
                let data = {
                  [this.categoryId]: item.value,
                  [this.categoryName]: value
                };
     
                this.callUpdateApi(data, (newRow) => {
                  this.callReadApi(() => {
                    this.setState({
                      isCreateNewDialog: false,
                      newDialogItemLoading: false,
                      newDialogItem: null,
                      shouldClose: true,
                    }, () => {
                      if(this.props.onUpdate){
                        this.props.onUpdate(newRow);
                      }
                    });
                  });
                });
              } else {
                let data = {
                  [this.categoryName]: value
                };

                this.callCreateApi(data, (newRow) => {
                  this.callReadApi(() => {
                    this.setState({
                      isCreateNewDialog: false,
                      newDialogItemLoading: false,
                      newDialogItem: null,
                      shouldClose: true,
                    }, () => {
                      if(this.props.onCreate && newRow){
                        this.props.onCreate(newRow);
                      }
                    });
                  });
                });
              }
            }}
          />
        }
        /* END DIALOGS */


        render() {
            return <>
                <Target 
                    ref={this.targetRef}
                    {...this.props}
                    renderMenuItem={(label, item) => {
                      return <Row className={'align-items-center'}>
                          <Col xs={true} className={'text-wrap'}>
                            {label}
                          </Col>
                          <Col xs={'auto'}>
                            {this.state.canEdit && <IconButton
                              className={'delete-btn ms-2'}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
              
                                this.setState({
                                  isCreateNewDialog: true,
                                  newDialogItem: item,
                                  shouldClose: true,
                                });
                              }}
                            >
                              <i className={'material-icons text-primary'}>edit</i>
                            </IconButton>}
                            {this.state.canRemove && <IconButton
                              className={'delete-btn ms-2'}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
              
                                this.setState({
                                  isRemoveDialog: true,
                                  removeRow: item,
                                  shouldClose: true,
                                });
                              }}
                            >
                              <i className={'material-icons text-danger'}>delete_forever</i>
                            </IconButton>}
                          </Col>
                      </Row>
                    }}
                    renderExtraFooter={() => {
                      if(this.state.canAdd){
                        return <Row className={'align-items-center'}>
                          <Col xs={12}>
                            <hr />
                          </Col>
                          <Col xs={12}>
                              <div className={'ps-2 pe-2 pb-3'}>
                                <Button
                                  variant={"primary"}
                                  disabled={this.state.newDialogItemLoading}
                                  onClick={(e) => {
                                    this.setState({
                                      isCreateNewDialog: true,
                                      newDialogItem: null,
                                      shouldClose: true,
                                    });
                                  }}
                                >
                                  <div className={'ps-2 pe-2 pt-1 pb-1 d-flex align-items-center'}>
                                    <i className={'material-icons'}>add_circle</i>
                                    <span xs={'auto'}>Create New</span>
                                  </div>
                                </Button>
                              </div>
                          </Col>
                      </Row>
                      } else {
                        return null;
                      }
                    }}
                />

                {this.setRemoveDialog()}
                {this.setCategoryDialog()}
            </>
        }
    }

    const mapDispatchToProps = {
      dispatchApiCallGet,
      dispatchApiCallPost,
      dispatchApiCallPut,
      dispatchApiCallDelete,
      clear,
    }
    
    return connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Component)
};

export default makeWasteTypeCategory;