import React, { Component } from 'react';
import h2p from 'html2plaintext';

import { 
    Row,
    Col,
    Form,
    Modal,
    Button,
    CloseButton,
} from "react-bootstrap-v5";

import RSuiteTagInput from '../../../components/OLD/TagsInput/RSuiteTagInput';
import RTextEditor from '../../../components/OLD/RichTextEditor/RTextEditor.jsx';

import Utils from '../../../utils/utils'
import { AccountDocSettingType, WeighChitLayout, JobStepType } from '../../../utils/enums'
import { readApi } from '../../../api/CRUD/DispatchCRUD'

import ProofOfDeliveryPDF from '../Partial/ProofOfDeliveryPDF'

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut } from '../../../../setup/redux/dispatch/actions'


class PODDialog extends Component {
  
    constructor(props){
        super(props);

        this.pageName = 'job_form_POD_dialog';

        this.state = {
            isLoading: true,

            accountBillingProfileDetails: null,
            customerSiteDetails: null,

            layout: WeighChitLayout.Default,
        }
    }


    componentDidMount() {
        this.callInitApis();
        // this.callAccountBillingProfileDetailsApi(async (details) => {
        //     let {
        //         values,
        //         setFieldValue,
        //     } = this.props.formOptions;
            
        //     if(details && details.accountEmailTemplates && details.accountEmailTemplates.length > 0){
        //         let accountEmailTemplate = details.accountEmailTemplates[0];
        //         if(accountEmailTemplate){
        //             let bodyPODPlainText = h2p(accountEmailTemplate.message);
        //             let bodyPODHTML = accountEmailTemplate.message;
        //             let bodyPOD = Utils.createEditorState(bodyPODHTML);

        //             let toPOD = [];
        //             if(values && values.customerItem && values.customerItem.emailAddresses){
        //                 let emailAddresses = values.customerItem.emailAddresses.split(',');
        //                 if(emailAddresses && emailAddresses.length > 0){
        //                     for(let i = 0; i < emailAddresses.length; i++){
        //                         toPOD.push(emailAddresses[i]);
        //                     }
        //                 }
        //             }

        //             await setFieldValue('toPOD', toPOD);
        //             await setFieldValue('fromPOD', accountEmailTemplate.fromName);
        //             await setFieldValue('subjectPOD', accountEmailTemplate.subject);
        //             await setFieldValue('bodyPODPlainText', bodyPODPlainText);
        //             await setFieldValue('bodyPODHTML', bodyPODHTML);
        //             await setFieldValue('bodyPOD', bodyPOD);
        //             await setFieldValue('includeDeliveryOrderPOD', accountEmailTemplate.includePdfAttachmentDeliveryOrder);
        //             await setFieldValue('includePhotosPOD', accountEmailTemplate.includePdfAttachmentPhoto);
        //             await setFieldValue('includeWeighChitPOD', accountEmailTemplate.includePdfAttachmentWeightChit);
        //             await setFieldValue('includeAttachmentPOD', true);
        //         }
        //     }
            
        //     await setFieldValue('detailsPOD', details);
        // });
    }

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
        // Utils.reduxProps(nextProps,
        //     this.pageName + '-account_billing_profile_details', 
        //     (data, isLoading, isError, err, statusCode, variables, callback) => {
        //         this.setState({
        //             isLoading: isLoading,
        //             isError: isError,
        //             err: err,
        //         }, () => {
        //             this.props.clear();
                    
        //             if(this.state.isError){
        //                 Utils.toast(this.state.err, 'error');
        //             }
                    
        //             if(callback){
        //                 callback(data);
        //             }
        //         });
        //     }
        // );
        
        Utils.reduxProps(nextProps,
            this.pageName + '-send_complete_job_email', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();
                    
                    if(this.state.isError){
                        Utils.toast(this.state.err, 'error');
                    }
                    
                    if(callback){
                        callback(data);
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            this.pageName + '-get_job_doc', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();
                    
                    if(this.state.isError){
                        Utils.toast(this.state.err, 'error');
                    }
                    
                    if(callback){
                        callback(data);
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            this.pageName + '-get_job_doc_no_photo', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();
                    
                    if(this.state.isError){
                        Utils.toast(this.state.err, 'error');
                    }
                    
                    if(callback){
                        callback(data);
                    }
                });
            }
        );
    }

    callInitApis = () => {
        let {
            values,
        } = this.props.formOptions;

        Utils.callAllApi([
            {
                api: readApi,
                path: 'AccountBillingProfile/' + values.accountBillingProfileId,
                params: null,
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        this.setState({
                            accountBillingProfileDetails: data,
                        });
                    });
                }
            },
            {
                api: readApi,
                path: 'customersite/' + values.customerSiteId,
                params: null,
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        this.setState({
                            customerSiteDetails: data,
                        });
                    });
                }
            }
        ], (result) => {
            this.setState({
                isLoading: true,
            });
        }, (result) => {
            this.setState({
                isLoading: false,
            }, () => {
                this.initDetailsData();
            });
        });
    }
    initDetailsData = async () => {
        let {
            values,
            setFieldValue,
        } = this.props.formOptions;
        
        let contactEmails = [];
        let isAutoSendDoDoc = false;
        if(this.state.customerSiteDetails && this.state.customerSiteDetails.contactEmails && this.state.customerSiteDetails.contactEmails.length > 0){
            contactEmails = this.state.customerSiteDetails.contactEmails;
            isAutoSendDoDoc = this.state.customerSiteDetails.isAutoSendDoDoc;
        }

        if(this.state.accountBillingProfileDetails && this.state.accountBillingProfileDetails.accountEmailTemplates && this.state.accountBillingProfileDetails.accountEmailTemplates.length > 0){
            let accountEmailTemplate = this.state.accountBillingProfileDetails.accountEmailTemplates[0];
            if(accountEmailTemplate){
                let bodyPODPlainText = h2p(accountEmailTemplate.message);
                let bodyPODHTML = accountEmailTemplate.message;
                let bodyPOD = Utils.createEditorState(bodyPODHTML);


                let toPOD = [];
                if(isAutoSendDoDoc){
                    if(contactEmails.length > 0){
                        for(let i = 0; i < contactEmails.length; i++){
                            if(contactEmails[i].customerContactPersonId){
                                if(contactEmails[i].customerContactPerson.emailAddress){
                                    toPOD.push(contactEmails[i].customerContactPerson.emailAddress);
                                }
                            } else {
                                if(contactEmails[i].emailAddress){
                                    toPOD.push(contactEmails[i].emailAddress);
                                }
                            }
                        }
                    } else {
                        if(values && values.customerItem && values.customerItem.emailAddresses){
                            let emailAddresses = values.customerItem.emailAddresses.split(',');
                            if(emailAddresses && emailAddresses.length > 0){
                                for(let i = 0; i < emailAddresses.length; i++){
                                    if(emailAddresses[i]){
                                        toPOD.push(emailAddresses[i]);
                                    }
                                }
                            }
                        }
                    }
                } else {
                    if(values && values.customerItem && values.customerItem.emailAddresses){
                        let emailAddresses = values.customerItem.emailAddresses.split(',');
                        if(emailAddresses && emailAddresses.length > 0){
                            for(let i = 0; i < emailAddresses.length; i++){
                                if(emailAddresses[i]){
                                    toPOD.push(emailAddresses[i]);
                                }
                            }
                        }
                    }
                }

                await setFieldValue('toPOD', toPOD);
                await setFieldValue('fromPOD', accountEmailTemplate.fromName);
                await setFieldValue('subjectPOD', accountEmailTemplate.subject);
                await setFieldValue('bodyPODPlainText', bodyPODPlainText);
                await setFieldValue('bodyPODHTML', bodyPODHTML);
                await setFieldValue('bodyPOD', bodyPOD);
                await setFieldValue('includeDeliveryOrderPOD', accountEmailTemplate.includePdfAttachmentDeliveryOrder);
                await setFieldValue('includePhotosPOD', accountEmailTemplate.includePdfAttachmentPhoto);
                await setFieldValue('includeWeighChitPOD', accountEmailTemplate.includePdfAttachmentWeightChit);
                await setFieldValue('includeAttachmentPOD', true);
            }
        }

        if(this.state.accountBillingProfileDetails && this.state.accountBillingProfileDetails.accountDocSettings && this.state.accountBillingProfileDetails.accountDocSettings.length > 0){
            let accountDocSettingsItems = this.state.accountBillingProfileDetails.accountDocSettings.filter(x => x.type == AccountDocSettingType.WeightChit);
            if(accountDocSettingsItems && accountDocSettingsItems.length > 0){
                let accountDocSettings = accountDocSettingsItems[0];
                let layout = accountDocSettings.layout;

                this.setState({
                    layout: layout,
                });
            }
        }
        
        await setFieldValue('detailsPOD', this.state.accountBillingProfileDetails);
    }

    // callAccountBillingProfileDetailsApi = async (callback = null) => {
    //     let {
    //         values,
    //         setFieldValue,
    //     } = this.props.formOptions;

    //     await setFieldValue('detailsPOD', null);

    //     if(values.accountBillingProfileId){
    //         this.setState({
    //             isLoading: true,
    //         });
        
    //         this.props.dispatchApiCallGet(null, this.pageName + '-account_billing_profile_details', 'AccountBillingProfile/' + values.accountBillingProfileId, null, callback, () => {});
    //     } else {
    //         if(callback){
    //             callback(null)
    //         }
    //     }
    // }

    callSendCompleteJobEmailApi = (callback = null) => {
        let {
            values,
        } = this.props.formOptions;
        
        Utils.toast('You will receive an mail soon', 'info');

        let toPOD = values.toPOD && values.toPOD.length > 0 ? values.toPOD.join(',') : '';

        this.setState({
            isLoading: true,
        });

        let data = {
            jobId: this.props.jobId,
            emailAddresses: toPOD,
            fromName: values.fromPOD,
            subject: values.subjectPOD,
            message: values.bodyPODHTML,
            includeAttachment: values.includeAttachmentPOD,
            includePdfAttachmentDeliveryOrder: values.includeDeliveryOrderPOD,
            includePdfAttachmentPhoto: values.includePhotosPOD,
            includePdfAttachmentWeightChit: values.includeWeighChitPOD,
        };

        this.props.dispatchApiCallPost(data, this.pageName + '-send_complete_job_email', 'job/send-complete-job-email', null, callback, () => {});
    }
    
    callDownloadPDFFileApi = (callback = null) => {
		Utils.toast(<div style={{ fontWeight: 'bold' }}>Please Wait ...</div>, 'info');
    
        this.setState({
            isLoading: true,
        });

        let {
            values,
        } = this.props.formOptions;
        
        let toPOD = values.toPOD && values.toPOD.length > 0 ? values.toPOD.join(',') : '';

        let data = {
            jobId: this.props.jobId,
            emailAddresses: toPOD,
            fromName: values.fromPOD,
            subject: values.subjectPOD,
            message: values.bodyPODHTML,
            includeAttachment: values.includeAttachmentPOD,
            includePdfAttachmentDeliveryOrder: values.includeDeliveryOrderPOD,
            includePdfAttachmentPhoto: values.includePhotosPOD,
            includePdfAttachmentWeightChit: values.includeWeighChitPOD,
        };


        this.props.dispatchApiCallPost(data, this.pageName + '-get_job_doc', 'job/get-job-doc', null, callback, () => {});
    }
    
    callDownloadPDFFileNoPhotoApi = (callback = null) => {
		Utils.toast(<div style={{ fontWeight: 'bold' }}>Please Wait ...</div>, 'info');
    
        this.setState({
            isLoading: true,
        });

        this.props.dispatchApiCallGet(null, this.pageName + '-get_job_doc_no_photo', 'job/get-job-doc-no-photo/' + this.props.jobId, null, callback, () => {});
    }
    /* END API */


    header = () => {
        return <Modal.Header>
            <Row className={'align-items-center gx-0 w-100'}>
                <Col xs={true}>
                    <Modal.Title>Preview or Send</Modal.Title>
                </Col>
                <Col xs={'auto'}>
                    <CloseButton
                        className={'rounded-2'}
                        onClick={() => {
                            if(this.props.onHide){
                                this.props.onHide();
                            }
                        }}
                    />
                </Col>
            </Row>
        </Modal.Header>
    }
    
    body = () => {
        let {
            values,
        } = this.props.formOptions;
        
        let currentStatusName = (values.currentStatusName && values.currentStatusName !== '') ? values.currentStatusName : '';
        let isCompletedStatus = (currentStatusName.toString().toLowerCase() == 'completed') ? true : false;
        
        return <Modal.Body>
            {
                this.state.isLoading
                ?
                <Row>
                    <Col xs={12} className={'text-center'}>Loading ...</Col>
                </Row>
                :
                // (values.includeDeliveryOrderPOD || values.includePhotosPOD || values.includeWeighChitPOD)
                // ?
                <Row>
                    {isCompletedStatus && <Col xs={12} xl={6} className={'pb-2'}>
                        {this.fields()}
                    </Col>}
                    <Col xs={12} xl={isCompletedStatus ? 6 : 12}>
                        {this.pdf()}
                    </Col>
                </Row>
                // :
                // <Row>
                //     <Col xs={12} xl={12}><h5 className='text-center'>The display is not enabled in the billing profile page</h5></Col>
                // </Row>
            }
            
        </Modal.Body>
    }

    footer = () => {
        let {
            values,
        } = this.props.formOptions;
        
        let currentStatusName = (values.currentStatusName && values.currentStatusName !== '') ? values.currentStatusName : '';
        let isCompletedStatus = (currentStatusName.toString().toLowerCase() == 'completed') ? true : false;

        let isDisabled = (!values.includeDeliveryOrderPOD && !values.includePhotosPOD && !values.includeWeighChitPOD) ? true : false;

        return <Modal.Footer>
            <Row className={'w-100'}>
                <Col xs={'auto'}>
                    {isCompletedStatus && <Button 
                        type={'button'}
                        variant={'primary'}
                        disabled={this.state.isLoading || isDisabled}
                        onClick={() => {
                            // if(values.includePhotosPOD){
                                this.callDownloadPDFFileApi((response) => {
                                    let {
                                        values,
                                    } = this.props.formOptions;
                                    
                                    let byteArray = Utils.getUint8Array(response);
    
                                    const url = window.URL.createObjectURL(
                                        new Blob([byteArray], { type: "application/pdf" })
                                    );
                                    const link = document.createElement("a");
                                    link.href = url;
                                    link.setAttribute(
                                        "download",
                                        values.customFormParams.jobNumber +
                                        values.customerItem.billingName +
                                        ".pdf"
                                    );
                                    document.body.appendChild(link);
                                    link.click();
                                    link.remove();
                                });

                            // } else {
                            //     this.callDownloadPDFFileNoPhotoApi((response) => {
                            //         let {
                            //             values,
                            //         } = this.props.formOptions;
                                    
                            //         let byteArray = Utils.getUint8Array(response);
    
                            //         const url = window.URL.createObjectURL(
                            //             new Blob([byteArray], { type: "application/pdf" })
                            //         );
                            //         const link = document.createElement("a");
                            //         link.href = url;
                            //         link.setAttribute(
                            //             "download",
                            //             values.customFormParams.jobNumber +
                            //             values.customerItem.billingName +
                            //             ".pdf"
                            //         );
                            //         document.body.appendChild(link);
                            //         link.click();
                            //         link.remove();
                            //     });
                            // }
                        }}
                    >
                        DOWNLOAD
                    </Button>}
                </Col>
                <Col xs={true}>
                    {isCompletedStatus && <Button 
                        type={'button'}
                        variant={'secondary'}
                        disabled={this.state.isLoading || isDisabled}
                        onClick={() => {
                            let {
                                values,
                            } = this.props.formOptions;
                            
                            let toPOD = values.toPOD && values.toPOD.length > 0 ? values.toPOD.join(',') : '';

                            let data = {
                                jobId: this.props.jobId,
                                emailAddresses: toPOD,
                                fromName: values.fromPOD,
                                subject: values.subjectPOD,
                                message: values.bodyPODHTML,
                                includeAttachment: values.includeAttachmentPOD,
                                includePdfAttachmentDeliveryOrder: values.includeDeliveryOrderPOD,
                                includePdfAttachmentPhoto: values.includePhotosPOD,
                                includePdfAttachmentWeightChit: values.includeWeighChitPOD,
                            };
                            localStorage.setItem("jobIframeData", JSON.stringify(data));

                            const link = document.createElement("a");
                            link.href = "/Job-Iframe";
                            link.setAttribute("target", "_blank");
                            document.body.appendChild(link);
                            link.click();
                            link.remove();
                        }}
                    >
                        PRINT
                    </Button>}
                </Col>
                <Col xs={'auto'}>
                    {isCompletedStatus && <Button 
                        type={'button'}
                        variant={'success'}
                        disabled={this.state.isLoading}
                        onClick={() => {
                            this.callSendCompleteJobEmailApi();
                        }}
                    >
                        SEND EMAIL
                    </Button>}
                </Col>
            </Row>
        </Modal.Footer>
    }


    fields = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;
        
        return <Row>
            <Col xs={12}>
                <Form.Group className={'mb-5'}>
                    <Form.Label>{this.props.fields.fromPOD.label}</Form.Label>
                    <Form.Control
                        type="text"
                        id={this.props.fields.fromPOD.id}
                        placeholder={this.props.fields.fromPOD.placeholder}
                        disabled={this.state.isLoading}
                        value={values.fromPOD}
                        onChange={handleChange}
                        isInvalid={!!errors.fromPOD}
                    />
                    <Form.Control.Feedback type="invalid">{errors.fromPOD}</Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col xs={12}>
                <Form.Group className={'mb-5'}>
                    <Form.Label>{this.props.fields.toPOD.label}</Form.Label>
                    <Form.Control
                        as = {RSuiteTagInput}
                        isInvalid={!!errors.toPOD}

                        id={this.props.fields.toPOD.id}
                        placeholder={this.props.fields.toPOD.placeholder}
                        disabled={this.state.isLoading}
                        value={values.toPOD}
                        isDefault={true}
                        onChange={async (value) => {
                            let arr = [];
                            if(value && value.length > 0){
                                value.forEach(email => {
                                    let pattern = Utils.emailValidation();
        
                                    if(email.match(pattern) != null){
                                        arr.push(email);
                                    } else {
                                        Utils.toast('You must enter a valid email address', 'error');
                                    }
                                })
                            }

                            await setFieldValue('toPOD', arr);
                        }}
                        onClean={async () => {
                            await setFieldValue('toPOD', []);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.toPOD}</Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col xs={12}>
                <Form.Group className={'mb-5'}>
                    <Form.Label>{this.props.fields.subjectPOD.label}</Form.Label>
                    <Form.Control
                        type="text"
                        id={this.props.fields.subjectPOD.id}
                        placeholder={this.props.fields.subjectPOD.placeholder}
                        disabled={this.state.isLoading}
                        value={values.subjectPOD}
                        onChange={handleChange}
                        isInvalid={!!errors.subjectPOD}
                    />
                    <Form.Control.Feedback type="invalid">{errors.subjectPOD}</Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col xs={12}>
                <Form.Group className={'mb-5'}>
                    <Form.Label>{this.props.fields.bodyPODPlainText.label}</Form.Label>
                    <Form.Control
                        as = {RTextEditor}
                        isInvalid={!!errors.bodyPODPlainText}
                        id={this.props.fields.bodyPODPlainText.id}

                        editorState={values.bodyPOD}
                        placeholder={this.props.fields.bodyPODPlainText.placeholder}
                        disabled={this.state.isLoading}
                        onChange={async (plainText, html, editorState) => {
                            await setFieldValue('bodyPODPlainText', plainText);
                            await setFieldValue('bodyPODHTML', html);
                            await setFieldValue('bodyPOD', editorState);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.bodyPOD}</Form.Control.Feedback>
                </Form.Group>
            </Col>

            <Col xs={12}>
                <Row>
                    <Col xs={12} sm={true} className={'mb-5'}>
                        <Form.Group>
                            <Form.Check
                                type="switch"
                                id={this.props.fields.includeAttachmentPOD.id}
                                label={this.props.fields.includeAttachmentPOD.label}
                                disabled={this.state.isLoading}
                                checked={values.includeAttachmentPOD}
                                onChange={async (e) => {
                                    await setFieldValue('includeAttachmentPOD', e.target.checked);
                                    if(e.target.checked){
                                        if(!values.includeDeliveryOrderPOD && !values.includeWeighChitPOD){
                                            await setFieldValue('includeDeliveryOrderPOD', true);
                                        }
                                    }
                                }}
                                isInvalid={!!errors.includeAttachmentPOD}
                                feedback={errors.includeAttachmentPOD}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Form.Label>Include</Form.Label>
                    </Col>
                    <Col xs={12} sm={true}>
                        <Form.Check
                            id={this.props.fields.includeDeliveryOrderPOD.id}
                            label={this.props.fields.includeDeliveryOrderPOD.label}
                            disabled={this.state.isLoading}
                            checked={values.includeDeliveryOrderPOD}
                            onChange={async (e) => {
                                if(values.includeAttachmentPOD){
                                    await setFieldValue('includeDeliveryOrderPOD', e.target.checked);
                                    if(!e.target.checked){
                                        await setFieldValue('includePhotosPOD', false);
                                    }
                                } else {
                                    await setFieldValue('includeDeliveryOrderPOD', e.target.checked);
                                    if(!e.target.checked){
                                        await setFieldValue('includePhotosPOD', false);
                                    }
                                }
                            }}
                            isInvalid={!!errors.includeDeliveryOrderPOD}
                            feedback={errors.includeDeliveryOrderPOD}
                        />
                    </Col>
                    <Col xs={12} sm={true}>
                        <Form.Check
                            id={this.props.fields.includePhotosPOD.id}
                            label={this.props.fields.includePhotosPOD.label}
                            disabled={this.state.isLoading || !values.includeDeliveryOrderPOD}
                            checked={values.includePhotosPOD}
                            onChange={async (e) => {
                                await setFieldValue('includePhotosPOD', e.target.checked);
                            }}
                            isInvalid={!!errors.includePhotosPOD}
                            feedback={errors.includePhotosPOD}
                        />
                    </Col>

                    {((values.jobTemplateType != JobStepType.Put) && (values.jobTemplateType != JobStepType.Task) && (values.jobTemplateType != JobStepType.Shift)) && <Col xs={12} sm={true}>
                        <Form.Check
                            id={this.props.fields.includeWeighChitPOD.id}
                            label={this.props.fields.includeWeighChitPOD.label}
                            disabled={this.state.isLoading}
                            checked={values.includeWeighChitPOD}
                            onChange={async (e) => {
                                if(values.includeAttachmentPOD){
                                    await setFieldValue('includeWeighChitPOD', e.target.checked);
                                } else {
                                    await setFieldValue('includeWeighChitPOD', e.target.checked);
                                }
                            }}
                            isInvalid={!!errors.includeWeighChitPOD}
                            feedback={errors.includeWeighChitPOD}
                        />
                    </Col>}

                </Row>
            </Col>
        </Row>
    }

    pdf = () => {
        return <ProofOfDeliveryPDF 
            formOptions={this.props.formOptions}
            layout={this.state.layout}
        />
    }


    render() {
        return <>
            {this.header()}
            {this.body()}
            {this.footer()}
        </>
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  dispatchApiCallPost,
  dispatchApiCallPut,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PODDialog);
