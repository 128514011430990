import React from 'react';
import _ from 'lodash';

import { 
    Row,
    Col,
    Form,
    Dropdown,
} from "react-bootstrap-v5";

import CurrencyInput from 'react-currency-input-field';

import Utils from '../../../../utils/utils';
import { PayrollTemplateIncentiveType } from '../../../../utils/enums';
import { StyledRemoveButton } from '../../../../utils/styles';

import { useDispatch, useSelector } from "react-redux";
import {RootState} from '../../../../../setup'
import payrollBetaLayout from '../redux/payrollBetaLayout';
import payrollBetaForm, { fields, graduatedPay } from '../redux/payrollBetaForm';

import { useFormikContext } from '../PayrollBetaForm';

import { StyledDropdown, StyledDropdownToggle, StyledHeaderRow, StyledBodyRow, StyledLinkButton, StyledFormLabel, StyledFormText, StyledColPer, StyledColPay, StyledgraduatedPayText } from '../style/styles';


const PayrollBetaStep4 = (props: any) => {
    const { values, errors, setFieldValue, validateForm } = useFormikContext();

    // const dispatch = useDispatch();
    // const { step } = useSelector((state: RootState) => state.payrollBetaLayout);
    const { graduatedWageItems } = useSelector((state: RootState) => state.payrollBetaForm);

    
    const getTotal = () => {
        let totalJobs: any = 0;
        let totalPrice = 0;

        values.graduatedPay.forEach((item: any) => {
            let firstJob = Utils.isNumber(item.firstJob) ? parseInt(item.firstJob) : 0;
            let lastJob = Utils.isNumber(item.lastJob) ? parseInt(item.lastJob) : 0;
            let jobPay = Utils.isNumber(item.jobPay) ? parseFloat(item.jobPay) : 0;
            let flatPay = Utils.isNumber(item.flatPay) ? parseFloat(item.flatPay) : 0;

            let lastJobVal = (!Utils.isNumber(lastJob) || lastJob >= Utils.getMaxPageSize()) ? 1 : lastJob;
            let jobs: any = (lastJobVal - firstJob) + 1;
            jobs = (jobs <= 0) ? 1 : jobs;
            let total = ((jobs * jobPay) + flatPay);

            totalJobs = totalJobs + jobs;
            totalPrice = totalPrice + total;
        })

        return { totalJobs, totalPrice }
    }

    const readOnlyFirstJob = (index: number) => {
        let isFirstRow = (index === 0) ? true : false;

        if(isFirstRow){
            return true
        } else {
            return false
        }
    }
    const readOnlyLastJob = (index: number) => {
        let isLastRow = (index === (values.graduatedPay.length - 1)) ? true : false;

        if(isLastRow){
            return true
        } else {
            return false
        }
    }

    const addRow = () => {
        let items = _.cloneDeep(values.graduatedPay);

        let lastRow = items[items.length - 1];
        lastRow.lastJob = '';

        // if(Utils.isNumber(lastRow.lastJob) && lastRow.lastJob < (Utils.getMaxPageSize() - 1)){
            let obj: any = _.cloneDeep(graduatedPay);
            obj.firstJob = '';
    
            items.push(obj);
            setFieldValue('graduatedPay', items);
        // } else {
        //     Utils.toast('Cannot add a new line if the last job is infinity', 'error');
        // }
    }
    const removeRow = (i: number) => {
        let items = _.cloneDeep(values.graduatedPay);

        let isLastRow = (i === (items.length-1)) ? true : false;
        if(isLastRow){
            let lastRow = items[i-1];
            lastRow.lastJob = Utils.getMaxPageSize();
        } else {
            let currentRow = items[i];
            let nextRow = items[i+1];
            nextRow.firstJob = currentRow.firstJob
        }
        
        items.splice(i, 1);
        setFieldValue('graduatedPay', items);
    }

    // const addRow = () => {
    //     let items = _.cloneDeep(values.graduatedPay);

    //     let lastRow = items[items.length - 1];
    //     if(Utils.isNumber(lastRow.lastJob) && lastRow.lastJob < (Utils.getMaxPageSize() - 1)){
    //         let lastJob = (lastRow.lastJob && lastRow.lastJob != '') ? parseFloat(lastRow.lastJob) : 0;
    //         let x: number = (lastJob+1);

    //         let obj = {...graduatedPay};
    //         obj.firstJob = x;

    //         items.push(obj);
    //         setFieldValue('graduatedPay', items);
    //     } else {
    //         Utils.toast('Cannot add a new line if the last job is infinity', 'error');
    //     }
    // }
    // const removeRow = (i: number) => {
    //     let items = _.cloneDeep(values.graduatedPay);

    //     let isLastRow = (i === (items.length-1)) ? true : false;
    //     if(!isLastRow){
    //         let currentRow = items[i];
    //         let nextRow = items[i+1];
    //         nextRow.firstJob = currentRow.firstJob
    //         nextRow.lastJob = (nextRow.lastJob && nextRow.lastJob != '') ? nextRow.lastJob : ''
    //     }
        
    //     items.splice(i, 1);
    //     setFieldValue('graduatedPay', items);
    // }
    const updateFirstJob = (i: number, value: string) => {
        let firstJob = Utils.isNumber(value) ? parseFloat(value) : null;
        let x: number = firstJob ? (firstJob-1) : 0;

        setFieldValue('graduatedPay.' + i + '.firstJob', value);

        let items = _.cloneDeep(values.graduatedPay);
        let hasPrevRow = (items && items.length > 0 && items[(i-1)]) ? true : false;
        if(hasPrevRow){
            setFieldValue('graduatedPay.' + (i-1) + '.lastJob', x);
        }

        setTimeout(() => {
            validateForm()
        });
    }
    const updateLastJob = (i: number, value: string) => {
        let lastJob = Utils.isNumber(value) ? parseFloat(value) : null;
        let x: number = lastJob ? (lastJob+1) : 0;

        setFieldValue('graduatedPay.' + i + '.lastJob', value);

        let items = _.cloneDeep(values.graduatedPay);
        let hasNextRow = (items && items.length > 0 && items[(i+1)]) ? true : false;
        if(hasNextRow){
            setFieldValue('graduatedPay.' + (i+1) + '.firstJob', x);
        }
        setTimeout(() => {
            validateForm()
        });
    }


    const tableForm = () => {
        return <Row>
            <Col xs={12} md={10} className={'mb-3'}>
                <Row>
                    <Col xs={12} md={5}>
                        <StyledFormLabel>{(values.payrollTemplateIncentiveType == PayrollTemplateIncentiveType.VolumeWage) ? fields.incentivesVolumePay.label : fields.graduatedPay.label}</StyledFormLabel>
                    </Col>
                    <Col xs={12} md={6} className={'text-start text-md-end'}>
                        <StyledLinkButton
                            type={'button'}
                            onClick={(e) => {
                                validateForm().then((err: any) => {
                                    let state = false;
                                    if(!!(err && err.graduatedPay)){
                                        state = false;
                                    } else {
                                        state = true;
                                    }

                                    if(state){
                                        addRow();
                                    } else {
                                        Utils.toast(fields.graduatedPay.err, 'error');
                                    }
                                })
                            }}
                        >{fields.graduatedPay.add}</StyledLinkButton>
                    </Col>
                    <Col xs={12} md={1}></Col>
                </Row>

            </Col>
            <Col xs={12} md={10}>
                <StyledHeaderRow className={'d-none d-md-flex g-0'}>
                    <Col xs={12} md={3}></Col>
                    <Col xs={12} md={2}><StyledFormText>{fields.graduatedPayFirstJob.label}</StyledFormText></Col>
                    <Col xs={12} md={2}><StyledFormText>{fields.graduatedPayLastJob.label}</StyledFormText></Col>
                    <Col xs={12} md={2}><StyledFormText>{fields.graduatedPayJobPay.label}</StyledFormText></Col>
                    <Col xs={12} md={2}><StyledFormText>{fields.graduatedPayFlatPay.label}</StyledFormText></Col>
                    <Col xs={12} md={1}></Col>
                </StyledHeaderRow>
                
                {values.graduatedPay.map((item: any, i: number) => {
                    return <StyledBodyRow key={fields.graduatedPay.label + '_' + i} className={'g-0'}>
                        <Col xs={12} md={3}>
                            <Row className={'w-100 g-0'}>
                                <Col xs={12} className={'d-block d-md-none'}><StyledFormLabel>{fields.graduatedPayText.label}</StyledFormLabel></Col>
                                <Col xs={12} className={'p-md-0'}>
                                    <StyledgraduatedPayText
                                        size={'sm'}
                                        id={fields.graduatedPayText.id + '_' + i + '_text'}
                                        isInvalid={!!(errors.graduatedPay && errors.graduatedPay[i] && errors.graduatedPay[i].text)}
                                        placeholder={(values.payrollTemplateIncentiveType == PayrollTemplateIncentiveType.VolumeWage) ? fields.graduatedPayText.placeholder : (i === 0) ? fields.graduatedPayText.firstText : fields.graduatedPayText.nextText}
                                        value={item.text}
                                        readOnly={true}
                                        onChange={(e: any) => {
                                            setFieldValue('graduatedPay.' + i + '.text', e.target.value);
                                        }}
                                    />
                                    {/* <Form.Control.Feedback type="invalid">{errors && errors.graduatedPay && errors.graduatedPay[i] && errors.graduatedPay[i].text}</Form.Control.Feedback> */}
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={2}>
                            <Row className={'w-100 g-0'}>
                                <Col xs={12} className={'d-block d-md-none'}><StyledFormLabel>{fields.graduatedPayFirstJob.label}</StyledFormLabel></Col>
                                <Col xs={12} className={'p-md-0'}>
                                    <Form.Control
                                        as = {CurrencyInput}
                                        
                                        allowDecimals={false}
                                        allowNegativeValue={false}
                                        disableGroupSeparators={true}
                                        prefix={''}
                                        size={'sm'}
                                
                                        readOnly={readOnlyFirstJob(i)}

                                        id={fields.graduatedPayFirstJob.id + '_' + i + '_firstJob'}
                                        isInvalid={!!(errors.graduatedPay && errors.graduatedPay[i] && errors.graduatedPay[i].firstJob)}
                                        placeholder={fields.graduatedPayFirstJob.placeholder}
                                        value={item.firstJob}
                                        onValueChange={(value: any) => {
                                            if(value != ''){
                                                updateFirstJob(i, value);
                                            } else {
                                                setFieldValue('graduatedPay.' + i + '.firstJob', value);
                                            }
                                        }}
                                    />
                                    {/* <Form.Control.Feedback type="invalid">{errors && errors.graduatedPay && errors.graduatedPay[i] && errors.graduatedPay[i].firstJob}</Form.Control.Feedback> */}
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={2}>
                            <Row className={'w-100 g-0'}>
                                <Col xs={12} className={'d-block d-md-none'}><StyledFormLabel>{fields.graduatedPayLastJob.label}</StyledFormLabel></Col>
                                <Col xs={12} className={'p-md-0'}>
                                    <Form.Control
                                        as = {CurrencyInput}
                                        
                                        allowDecimals={false}
                                        allowNegativeValue={false}
                                        disableGroupSeparators={true}
                                        prefix={''}
                                        size={'sm'}
                                
                                        readOnly={readOnlyLastJob(i)}

                                        id={fields.graduatedPayLastJob.id + '_' + i + '_lastJob'}
                                        isInvalid={!!(errors.graduatedPay && errors.graduatedPay[i] && errors.graduatedPay[i].lastJob)}
                                        placeholder={(i === (values.graduatedPay.length-1)) ? (item.lastJob < Utils.getMaxPageSize()) ? '' : '∞' : ''}
                                        value={(item.lastJob >= Utils.getMaxPageSize()) ? '' : item.lastJob}
                                        onValueChange={(value: any) => {
                                            if(value != ''){
                                                updateLastJob(i, value);
                                            } else {
                                                setFieldValue('graduatedPay.' + i + '.lastJob', value);
                                            }
                                        }}
                                    />
                                    {/* <Form.Control.Feedback type="invalid">{errors && errors.graduatedPay && errors.graduatedPay[i] && errors.graduatedPay[i].lastJob}</Form.Control.Feedback> */}
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={2}>
                            <Row className={'w-100 g-0'}>
                                <Col xs={12} className={'d-block d-md-none'}><StyledFormLabel>{fields.graduatedPayJobPay.label}</StyledFormLabel></Col>
                                <Col xs={12} className={'p-md-0'}>
                                    <Form.Control
                                        as = {CurrencyInput}
                                        
                                        allowDecimals={true}
                                        allowNegativeValue={false}
                                        disableGroupSeparators={true}
                                        prefix={Utils.getCurrency()}
                                        size={'sm'}
                                
                                        id={fields.graduatedPayJobPay.id + '_' + i + '_jobPay'}
                                        isInvalid={!!(errors.graduatedPay && errors.graduatedPay[i] && errors.graduatedPay[i].jobPay)}
                                        placeholder={fields.graduatedPayJobPay.placeholder}
                                        value={item.jobPay}
                                        onValueChange={(value: any) => {
                                            setFieldValue('graduatedPay.' + i + '.jobPay', value);
                                        }}
                                    />
                                    {/* <Form.Control.Feedback type="invalid">{errors && errors.graduatedPay && errors.graduatedPay[i] && errors.graduatedPay[i].jobPay}</Form.Control.Feedback> */}
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={2}>
                            <Row className={'w-100 g-0'}>
                                <Col xs={12} className={'d-block d-md-none'}><StyledFormLabel>{fields.graduatedPayFlatPay.label}</StyledFormLabel></Col>
                                <Col xs={12} className={'p-md-0'}>
                                    <Form.Control
                                        as = {CurrencyInput}
                                        
                                        allowDecimals={true}
                                        allowNegativeValue={false}
                                        disableGroupSeparators={true}
                                        prefix={Utils.getCurrency()}
                                        size={'sm'}
                                
                                        id={fields.graduatedPayFlatPay.id + '_' + i + '_flatPay'}
                                        isInvalid={!!(errors.graduatedPay && errors.graduatedPay[i] && errors.graduatedPay[i].flatPay)}
                                        placeholder={fields.graduatedPayFlatPay.placeholder}
                                        value={item.flatPay}
                                        onValueChange={(value: any) => {
                                            setFieldValue('graduatedPay.' + i + '.flatPay', value);
                                        }}
                                    />
                                    {/* <Form.Control.Feedback type="invalid">{errors && errors.graduatedPay && errors.graduatedPay[i] && errors.graduatedPay[i].flatPay}</Form.Control.Feedback> */}
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={1}>
                            {(i > 0) && <StyledRemoveButton
                                type={'button'}
                                onClick={(e) => {
                                    removeRow(i);
                                }}
                            >
                                <i className={'fas fa-minus'}></i>
                            </StyledRemoveButton>}
                        </Col>
                    </StyledBodyRow>
                })}
                
            </Col>
        </Row>
    }

    const previewGraduatedForm = () => {
        return  <Row>
            <Col xs={12} className={'mb-3'}>
                <StyledFormLabel>{fields.graduatedPay.preview}</StyledFormLabel>
            </Col>
            <Col xs={12}>
                {values.graduatedPay.map((item: any, i: number) => {
                    let firstJob = Utils.isNumber(item.firstJob) ? parseInt(item.firstJob) : 0;
                    let lastJob = Utils.isNumber(item.lastJob) ? parseInt(item.lastJob) : 0;
                    let jobPay = Utils.isNumber(item.jobPay) ? parseFloat(item.jobPay) : 0;
                    let flatPay = Utils.isNumber(item.flatPay) ? parseFloat(item.flatPay) : 0;

                    let lastJobVal = (!Utils.isNumber(lastJob) || lastJob >= Utils.getMaxPageSize()) ? 1 : lastJob;
                    let jobs: any = (lastJobVal - firstJob) + 1;
                    jobs = (jobs <= 0) ? 1 : jobs;
                    let total = ((jobs * jobPay) + flatPay);
                    
                    return <Row key={fields.graduatedPay.label + '_preview_' + i} className={'g-0'}>
                        <Col xs={'auto'} style={{ minWidth: '30px' }}>{jobs}</Col>
                        <Col xs={'auto'} style={{ textAlign: 'center', minWidth: '20px' }}>*</Col>
                        <Col xs={'auto'} style={{ textAlign: 'center', minWidth: '80px' }}>{Utils.getCurrency() + Utils.formatNumber(jobPay)}</Col>
                        <Col xs={'auto'} style={{ textAlign: 'center', minWidth: '20px' }}>+</Col>
                        <Col xs={'auto'} style={{ textAlign: 'center', minWidth: '80px' }}>{Utils.getCurrency() + Utils.formatNumber(flatPay)}</Col>
                        <Col xs={'auto'} style={{ textAlign: 'center', minWidth: '20px' }}>=</Col>
                        <Col xs={'auto'}>{Utils.getCurrency() + Utils.formatNumber(total)}</Col>
                    </Row>
                })}
                <div>{getTotal().totalJobs} jobs will pay {Utils.getCurrency() + Utils.formatNumber(getTotal().totalPrice)}</div>
            </Col>
        </Row>
    }
    const previewVolumeForm = () => {
        if(values.graduatedPay && values.graduatedPay.length > 0){
            let item = values.graduatedPay[values.graduatedPay.length-1];
            
            let firstJob = Utils.isNumber(item.firstJob) ? parseInt(item.firstJob) : 0;
            let lastJob = Utils.isNumber(item.lastJob) ? parseInt(item.lastJob) : 0;
            let jobPay = Utils.isNumber(item.jobPay) ? parseFloat(item.jobPay) : 0;
            let flatPay = Utils.isNumber(item.flatPay) ? parseFloat(item.flatPay) : 0;

            let jobs = (!Utils.isNumberAvoidZero(lastJob) || lastJob >= Utils.getMaxPageSize()) ? firstJob : lastJob;
            let total = ((jobs * jobPay) + flatPay);
            
            return  <Row>
                <Col xs={12} className={'mb-3'}>
                    <StyledFormLabel>{fields.graduatedPay.preview}</StyledFormLabel>
                </Col>
                <Col xs={12}>
                    <Row key={fields.incentivesVolumePay.label + '_preview_volume_' + 0} className={'g-0'}>
                        <Col xs={'auto'} style={{ minWidth: '30px' }}>{jobs}</Col>
                        <Col xs={'auto'} style={{ textAlign: 'center', minWidth: '20px' }}>*</Col>
                        <Col xs={'auto'} style={{ textAlign: 'center', minWidth: '80px' }}>{Utils.getCurrency() + Utils.formatNumber(jobPay)}</Col>
                        <Col xs={'auto'} style={{ textAlign: 'center', minWidth: '20px' }}>+</Col>
                        <Col xs={'auto'} style={{ textAlign: 'center', minWidth: '80px' }}>{Utils.getCurrency() + Utils.formatNumber(flatPay)}</Col>
                        <Col xs={'auto'} style={{ textAlign: 'center', minWidth: '20px' }}>=</Col>
                        <Col xs={'auto'}>{Utils.getCurrency() + Utils.formatNumber(total)}</Col>
                    </Row>
                    <div>{jobs} jobs will pay {Utils.getCurrency() + Utils.formatNumber(total)}</div>
                </Col>
            </Row>
        }
    }
    

    const standardForm = () => {
        return  <Row>
            <Col xs={12} md={4} lg={3} xl={2} className={'mb-3'}>
                <StyledFormLabel>{fields.basicPay.label}</StyledFormLabel>
                <Form.Control
                    as = {CurrencyInput}
                    allowDecimals={true}
                    allowNegativeValue={false}
                    disableGroupSeparators={true}
                    prefix={Utils.getCurrency()}
                    size={'sm'}
                    
                    id={fields.basicPay.id + '_pay'}
                    isInvalid={!!(errors.basicPay)}
                    placeholder={fields.basicPay.placeholder}
                    value={values.basicPay}
                    onValueChange={(value: any) => {
                        setFieldValue('basicPay', value);
                    }}
                />
                {/* <Form.Control.Feedback type="invalid" className={'d-block'}>{errors.basicPay}</Form.Control.Feedback> */}
            </Col>
        </Row>
    }


    const packageForm = () => {
        return  <Row className={'align-items-center'}>
            <Col xs={12}>
                <StyledFormLabel>{fields.incentivesPackagePay.label}</StyledFormLabel>
            </Col>
            <StyledColPay className={'mb-3'}>
                <Form.Control
                    as = {CurrencyInput}
                    allowDecimals={true}
                    allowNegativeValue={false}
                    disableGroupSeparators={true}
                    prefix={Utils.getCurrency()}
                    size={'sm'}
                    
                    id={fields.incentivesPackagePay.id + '_pay'}
                    isInvalid={!!(errors.incentivesPackagePay)}
                    placeholder={fields.incentivesPackagePay.placeholder}
                    value={values.incentivesPackagePay}
                    onValueChange={(value: any) => {
                        setFieldValue('incentivesPackagePay', value);
                    }}
                />
                {/* <Form.Control.Feedback type="invalid" className={'d-block'}>{errors.incentivesPackagePay}</Form.Control.Feedback> */}
            </StyledColPay>
            <Col xs={12} sm={'auto'} className={'mb-3'}><StyledFormText>{fields.incentivesPackagePer.label}</StyledFormText></Col>
            <StyledColPer className={'mb-3'}>
                <Form.Control
                    as = {CurrencyInput}
                    allowDecimals={false}
                    allowNegativeValue={false}
                    disableGroupSeparators={true}
                    size={'sm'}
                    style={{ textAlign: 'center' }}
                    
                    id={fields.incentivesPackagePer.id + '_pay'}
                    isInvalid={!!(errors.incentivesPackagePer)}
                    placeholder={fields.incentivesPackagePer.placeholder}
                    value={values.incentivesPackagePer}
                    onValueChange={(value: any) => {
                        setFieldValue('incentivesPackagePer', value);
                    }}
                />
                {/* <Form.Control.Feedback type="invalid" className={'d-block'}>{errors.incentivesPackagePer}</Form.Control.Feedback> */}
            </StyledColPer>
            <Col xs={12} sm={'auto'} className={'mb-3'}><StyledFormText>{fields.incentivesPackagePer.jobs}</StyledFormText></Col>
        </Row>
    }


    return <Row >
        <Col xs={12} md={4} lg={3} xl={2} className={'mb-10'}>
            <StyledDropdown>
                <StyledDropdownToggle size={'sm'}>
                    {values.graduatedWageName}
                    <i className={'fas fa-sort-down'}></i>
                </StyledDropdownToggle>
                <Dropdown.Menu className={'custom-graduatedWage-menu'}>
                    {graduatedWageItems.map((option: any, i: number) => {
                        return <Dropdown.Item key={fields.graduatedWageId.id + '_' + i + '_option'} 
                            onClick={() => {
                                setFieldValue('payrollTemplateIncentiveType', option.value);
                                setFieldValue('graduatedWageName', option.title);
                            }}
                        >{option.title}</Dropdown.Item>
                    })}
                </Dropdown.Menu>
            </StyledDropdown>
        </Col>
        <Col xs={12}>
            {(values.payrollTemplateIncentiveType == PayrollTemplateIncentiveType.VolumeWage) && <Row className={'g-10'}>
                <Col xs={12} md={8}>
                    {tableForm()}
                </Col>
                <Col xs={12} md={4}>
                    {previewVolumeForm()}
                </Col>
            </Row>}

            {(values.payrollTemplateIncentiveType == PayrollTemplateIncentiveType.GraduatedWage) && <Row className={'g-10'}>
                <Col xs={12} md={8}>
                    {tableForm()}
                </Col>
                <Col xs={12} md={4}>
                    {previewGraduatedForm()}
                </Col>
            </Row>}

            {(values.payrollTemplateIncentiveType == PayrollTemplateIncentiveType.PackageWage) && <Row className={'g-10'}>
                <Col xs={12}>
                    {packageForm()}
                </Col>
            </Row>}

            {(values.payrollTemplateIncentiveType == PayrollTemplateIncentiveType.StandardWage) && <Row className={'g-10'}>
                <Col xs={12}>
                    {standardForm()}
                </Col>
            </Row>}
        </Col>
    </Row>
}


export default PayrollBetaStep4;
