import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';

import moment from 'moment';

import Utils from '../../utils/utils';
import { JobStepType } from '../../utils/enums';

import { connect } from 'react-redux'
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut } from '../../../setup/redux/dispatch/actions'

import { toast } from 'react-toastify';

import { 
  Box,
  Grid,
  Button,
  Tooltip,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Menu,
  MenuItem,
  IconButton,
} from '@material-ui/core';


import { ReactComponent as CirclePlay } from '../../../_metronic/assets/img/icons/circle-play.svg';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Icon from '@material-ui/core/Icon';
// import { Filter } from 'react-feather';

import GranttChart from "../../components/OLD/GranttChart/GranttChart.jsx";
import { Period, Zoom, ItemType } from "../../components/OLD/GranttChart/Util/Enums.jsx";

import DropdownCheckbox from '../../components/OLD/Dropdown/DropdownCheckbox.jsx';
import DataTableSearch from '../../components/OLD/DataTable/DataTableSearch.jsx';
import NewSingleDatePicker from '../../components/OLD/Datepicker/NewSingleDatePicker.jsx';
import DropdownFilter from '../../components/OLD/Dropdown/DropdownFilter.jsx';
import CustomDropdown from '../../components/OLD/Dropdown/CustomDropdown.jsx';
import CustomSlider from '../../components/OLD/Slider/CustomSlider.jsx';
import CustomCheckBox from '../../components/OLD/Checkbox/CustomCheckBox.jsx';
import LegendMenu from '../../components/OLD/Pages/Jobs/LegendMenu.jsx';
import CustomSwitch from '../../components/OLD/Checkbox/CustomSwitch.jsx';

import JobsDialog from '../../components/OLD/Pages/Jobs/JobsDialog.jsx';
// import CreateJobsForm from './CreateJobsForm.jsx';
// import JobsForm from './JobsForm.jsx';

// TODO JobForm

import CustomDialog from '../../components/OLD/Dialog/CustomDialog.jsx';
import CancelDialog from './Dialogs/CancelDialog.jsx';
import DriverRouteDialog from './Dialogs/DriverRouteDialog.jsx';

import {  
  CustomWeekendHiglight,
  CustomItemResize,
  CustomRow,
  // CustomSearch,
  CustomVehiclesDrivers,
  DragDropAction,
  DropAction,
  ClickAction,
  HoverAction,
  RightClickAction,
} from "../../components/OLD/GranttChart/Util/Components";

import ItemMovement from "gantt-schedule-timeline-calendar/dist/ItemMovement.plugin.js"
import CalendarScroll from "gantt-schedule-timeline-calendar/dist/CalendarScroll.plugin.js"
// import Selection from "gantt-schedule-timeline-calendar/dist/Selection.plugin.js"
import Selection from "../../components/OLD/GranttChart/Util/CustomSelection.jsx"

import SignalR from '../../components/signalR/SignalR.jsx';
import { ReceiveMessage } from '../../components/signalR/Enums.jsx';

import {
  ControlledMenu,
  SubMenu,
  MenuItem as CustomMenuItem
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';


const { 
  REACT_APP_REAL_TIME_URL
} = process.env;


class GranttChartView extends Component {
  
  constructor(props){
    super(props);

    this.pageRef = React.createRef();
    this.refSignalR = React.createRef();
    this.createJobFormRef = React.createRef();
  
    this.granttChartID = 'grantt_chart_ID';
    this.GSTC = null;
    this.ROW_HEIGHT = 80;

    this.selectedBlocks = [];

    let userInfo = props.auth.user;
    let groupAccountEmail = (userInfo && userInfo.userAccount && userInfo.userAccount.accountEmail) ? userInfo.userAccount.accountEmail : '';
    let userEmail = (userInfo && userInfo.email && userInfo.email !== '') ? userInfo.email : '';
    
    this.state = {
      isLoading: true,
      isLoadingUnassigned: true,

      unassignedData: [],
      unassignedTotal: 0,
 
      cancelDialogItemLoading: false,
      isCancelDialog: false,
      cancelRow: null,
      cancelItem: '',

      searchQuery: '',
      
      allDrivers: [],

      data: [],
      rows: [],
      items: [],
      columns: {},
      period: Period.Hour,
      zoom: Zoom.Init,

      fromDate: '1970-01-01',
      toDate: '9999-12-31',

      dateFormat: Utils.getAPIDateFormat(),

      filterDate: moment(),
      filterDateText: '',

      expanded: false,

      newDialogItemLoading: false,
      isCreateNewDialog: false,
      newDialogItem: null,
      newDialogParams: null,
      newDialogItemDefDate: null,

      groupAccountEmail: groupAccountEmail,
      userEmail: userEmail,

      mouseX: null,
      mouseY: null,
      contextMenuItem: null,

      mouseGanttChartX: null,
      mouseGanttChartY: null,
      contextMenuGanttChartItem: null,

      status: [],
      legendCanceledFailed: true,
      legendCompleted: true,
      jobStatusFilter: null,

      showPlanedJobTime: true,
      showActualJobTime: false,

      isMapDialog: false,
      mapDialogItem: null,
    }
  }


  componentDidMount() {
    this.callReadDriversApi();
    this.setDateFilters(this.state.filterDate);

    this.callStatApi(() => {
      this.callReadUnassignedApi();
    });

    this.loadGanttChartData();
  }

  componentWillReceiveProps(nextProps) {
    this.reduxProps(nextProps);
  }


  reduxProps = (nextProps) => {
    Utils.reduxProps(nextProps,
      'gantt-chart_job-group-by-driver', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        let allDrivers = data.data.map((item, i) => {
            return {
                id: item.driverId,
                text: item.driverName,
                checked: true,
            };
        });

        this.setState({
            allDrivers: allDrivers,

            isLoading: isLoading,
            isError: isError,
            err: err,
        }, () => {
            if(callback){
                callback(data);
            }
        });
      }
    );

    Utils.reduxProps(nextProps,
        'gantt-chart_job-group-by-driver-load', 
        (data, isLoading, isError, err, statusCode, variables, callback) => {
          this.setState({
              isLoading: isLoading,
              isError: isError,
              err: err,
          }, () => {
                if(this.state.isError){
                    if(variables.showLoading){
                        this.setState({
                            isLoading: false
                        });
                    }
            
                    this.setState({
                        data: [],
                    }, () => {
                        if(callback){
                            callback([])
                        }
                    });
                } else {
                    if(variables.showLoading){
                        this.setState({
                        isLoading: false
                        });
                    }
            
            
                    let newData = data.data;
                    if(this.state.allDrivers && this.state.allDrivers.length > 0){
                        let drivers = this.state.allDrivers.filter(x => x.checked === true).map((item, i) => {
                        return item.id;
                        });
                        newData = data.data.filter(x => drivers.includes(x.driverId));
                    }
            
                    
                    let newNewData = [];
                    // if(this.state.showPlanedJobTime || this.state.showActualJobTime){
                    if(newData && newData.length > 0){
                        newNewData = newData.map((driver, i) => {
                            let newJobs = [];
            
                            if(driver.jobs && driver.jobs.length > 0){
                                let statuses = [];
                
                                if(!this.state.legendCompleted){
                                    statuses.push('Completed');
                                }
                                
                                if(!this.state.legendCanceledFailed){
                                    statuses.push('Cancelled');
                                    statuses.push('Failed');
                                }
                    
                                let filteredJobs = [];
                                if(statuses && statuses.length > 0){
                                    filteredJobs = driver.jobs.filter(x => !statuses.includes(x.statusName));
                                } else {
                                    filteredJobs = driver.jobs;
                                }
                                
                    
                                for(let j = 0; j < filteredJobs.length; j++){
                                    let job = filteredJobs[j];
                
                
                                    if(this.state.showPlanedJobTime){
                                        let jobPlaned = Object.assign({}, job);
                    
                                        jobPlaned.itemType = ItemType.Planed;
                    
                                        newJobs.push(jobPlaned);
                                    }
                    
                    
                                    if(this.state.showActualJobTime){
                                        if(job.statusName === 'Completed'){
                                            let jobActual = Object.assign({}, job);
                    
                                            jobActual.itemType = ItemType.Actual;
                    
                                            jobActual.jobTimeFrom = jobActual.actualJobTimeSpecific;
                                            jobActual.jobTimeSpecific = jobActual.actualJobTimeSpecific;
                                            jobActual.jobDate = jobActual.actualJobTimeSpecific;
                    
                                            let diff = Utils.getDiff(jobActual.jobAttemptCompletedDate, jobActual.actualJobTimeSpecific);
                                            
                                            jobActual.jobDurationHours = diff.h;
                                            jobActual.jobDurationMinutes = diff.m;
                    
                                            // jobActual.jobId = "actual_" + jobActual.jobId;
                                            // let start = moment(jobActual.jobTimeSpecific).add(3, 'hours');
                                            
                                            // jobActual.jobTimeFrom = start.format();
                                            // jobActual.jobTimeSpecific = start.format();
                                            // jobActual.jobDate = start.format();
                    
                                            // jobActual.jobDurationHours = 4;
                                            // jobActual.jobDurationMinutes = 0;
                    
                                            
                                            newJobs.push(jobActual);
                                        }
                                    }
                                }
                            }
            
                            driver.jobs = newJobs;
                            return driver;
                        });
                    }
                    // }
            
                    this.setState({
                        data: newNewData,
                    }, () => {
                        if(callback){
                            callback(data)
                        }
                    });
                }
          });
        }
    );

    Utils.reduxProps(nextProps,
        'gantt-chart_job-read', 
        (data, isLoading, isError, err, statusCode, variables, callback) => {
          this.setState({
            unassignedData: data.data,
            unassignedTotal: (data && data.data && data.data.length > 0) ? data.data.length : 0,
            isLoadingUnassigned: false,
  
            isLoading: isLoading,
            isError: isError,
            err: err,
          }, () => {
            if(callback){
                callback(data);
            }
          });
        }
    );

    Utils.reduxProps(nextProps,
        'gantt-chart_job-update-time', 
        (data, isLoading, isError, err, statusCode, variables, callback) => {
          this.setState({
            isLoading: isLoading,
            isError: isError,
            err: err,
          }, () => {
            if(callback){
                callback(data);
            }
          });
        }
    );
    
    Utils.reduxProps(nextProps,
        'gantt-chart_job-dispatch', 
        (data, isLoading, isError, err, statusCode, variables, callback) => {
          this.setState({
            isLoading: isLoading,
            isError: isError,
            err: err,
          }, () => {
            if(this.state.isError){
                if(callback){
                    callback(false);
                }
            } else {
                if(callback){
                    callback(true);
                }
            }
          });
        }
    );

    Utils.reduxProps(nextProps,
        'gantt-chart_job-batch-dispatch', 
        (data, isLoading, isError, err, statusCode, variables, callback) => {
          this.setState({
            isLoading: isLoading,
            isError: isError,
            err: err,
          }, () => {
            if(this.state.isError){
                if(callback){
                    callback(false);
                }
            } else {
                if(callback){
                    callback(true);
                }
            }
          });
        }
    );

    Utils.reduxProps(nextProps,
        'gantt-chart_job-status-stat', 
        (data, isLoading, isError, err, statusCode, variables, callback) => {
            let statuses = [];
            if(data && data.data && data.data.length > 0){
                statuses = Utils.sortStatus(data.data);
            }

          this.setState({
            status: statuses,

            isLoading: isLoading,
            isError: isError,
            err: err,
          }, () => {
            if(callback){
                callback(statuses);
            }
          });
        }
    );

    Utils.reduxProps(nextProps,
        'gantt-chart_job-update', 
        (data, isLoading, isError, err, statusCode, variables, callback) => {
          this.setState({
            isCreateNewDialog: false,
            newDialogItem: null,
            newDialogItemDefDate: null,
            newDialogParams: null,
            newDialogItemLoading: false,

            isLoading: isLoading,
            isError: isError,
            err: err,
          }, () => {
            if(callback){
                callback(data);
            }
          });
        }
    );

    Utils.reduxProps(nextProps,
        'gantt-chart_job-create', 
        (data, isLoading, isError, err, statusCode, variables, callback) => {
          this.setState({
            isCreateNewDialog: false,
            newDialogItem: null,
            newDialogItemDefDate: null,
            newDialogParams: null,
            newDialogItemLoading: false,

            isLoading: isLoading,
            isError: isError,
            err: err,
          }, () => {
            if(callback){
                callback(data);
            }
          });
        }
    );
  }


  /* API */
  callReadDriversApi = (callback = null) => {
    let fromDate = moment(this.state.filterDate).format(this.state.dateFormat);
    let toDate = moment(this.state.filterDate).format(this.state.dateFormat);

    let data = {
      currentPage: 1,
      pageSize: Utils.getMaxPageSize(),
      searchQuery: this.state.searchQuery,
      fromDate: fromDate,
      toDate: toDate,
      isAssigned: true,
      sortColumn: 'updated',
      sortDir: 'desc',
    };

    this.props.dispatchApiCallGet(data, 'gantt-chart_job-group-by-driver', 'job/group-by-driver', null, callback, null);
  }

  callReadGroupByDriverApi = (showLoading = true, jobStatusFilter = null, callback = null) => {
    if(showLoading){
      this.setState({
        isLoading: true
      });
    }
    
    let fromDate = moment(this.state.filterDate).format(this.state.dateFormat);
    let toDate = moment(this.state.filterDate).format(this.state.dateFormat);

    let data = {
      currentPage: 1,
      pageSize: Utils.getMaxPageSize(),
      searchQuery: this.state.searchQuery,
      fromDate: fromDate,
      toDate: toDate,
      isAssigned: true,
      sortColumn: 'updated',
      sortDir: 'desc',
    };

    if(jobStatusFilter){
      data['jobStatusFilter'] = jobStatusFilter;
    }

    this.props.dispatchApiCallGet(data, 'gantt-chart_job-group-by-driver-load', 'job/group-by-driver', { showLoading: showLoading }, callback, null);
  }

  callReadUnassignedApi = (callback = null) => {
    this.setState({
        isLoadingUnassigned: true
    }, () => {
      let fromDate = (this.state.filterDate !== '') ? moment(this.state.filterDate).format(this.state.dateFormat) : moment(this.state.filterDate).format(this.state.dateFormat);
      let toDate = (this.state.filterDate !== '') ? moment(this.state.filterDate).format(this.state.dateFormat) : moment(this.state.filterDate).format(this.state.dateFormat);

      let statusUnassigned = this.findStatus('unassigned');

      let data = {
        currentPage: 1,
        pageSize: Utils.getMaxPageSize(),
        searchQuery: this.state.searchQuery,
        fromDate: fromDate,
        toDate: toDate,
        sortColumn: 'updated',
        sortDir: 'desc',
        jobStatusFilter: statusUnassigned.jobStatusId,
      };
      
      this.props.dispatchApiCallGet(data, 'gantt-chart_job-read', 'job', null, callback, null);
    })
  }

  callUpdateApi = (jobId, data, callback = null) => {
    this.props.dispatchApiCallPut(data, 'gantt-chart_job-update-time', 'job/' + jobId + '/time', null, callback, null);
  }

  callDispatchApi = (data, callback = null) => {
    this.props.dispatchApiCallPut(data, 'gantt-chart_job-dispatch', 'job/' + data.jobId + '/dispatch', null, callback, null);
  }

  callDispatchAllApi = (data, callback = null) => {
    this.props.dispatchApiCallPut(data, 'gantt-chart_job-batch-dispatch', 'job/' + data.jobId + '/batch-dispatch', null, callback, null);
  }

  callStatApi = (callback = null) => {
    let fromDate = (this.state.fromDate !== '') ? moment(this.state.fromDate).format(this.state.dateFormat) : moment(this.state.fromDate).format(this.state.dateFormat);
    let toDate = (this.state.toDate !== '') ? moment(this.state.toDate).format(this.state.dateFormat) : moment(this.state.toDate).format(this.state.dateFormat);

    this.setState({
      isLoadingStatus: true,
    });

    let data = {
      searchQuery: '',
      fromDate: fromDate,
      toDate: toDate,
    };

    this.props.dispatchApiCallGet(data, 'gantt-chart_job-status-stat', 'job/status/stat', null, callback, null);
  }

  callUpdateJobApi = (data, callback = null) => {
    this.props.dispatchApiCallPut(data, 'gantt-chart_job-update', 'job', null, callback, null);
  }

  callCreateJobApi = (data, callback = null) => {
    this.props.dispatchApiCallPost(data, 'gantt-chart_job-create', 'job', null, callback, null);
  }
  /* END API */


  /* GSTC */

  typeList() {
    return (
      <Grid className="containerTypeList">
        <Button
          className="listView "
          onClick={() => {
            this.props.history.push("/jobs");
          }}
        >
          <i className="fas fa-list "></i>
          <p>List View</p>
        </Button>
        <Button
          className="listView active"
          onClick={() => {
            this.props.history.push("/Grantt-chart");
          }}
        >
          <i
            className="mif-chart-bars active"
            style={{
              transform: "rotate(90deg)",
              top: "0px",
              fontSize: "18px",
            }}
          ></i>
          <p>Gantt View</p>
        </Button>
      </Grid>
    );
  }

  getGranttChart = () => {
    return <GranttChart 
      className={'period-hours'}
      config={this.getConfig()}
      onInit={(gstc) => {
        this.GSTC = gstc;
        this.GSTCSet('config.chart.time.zoom', this.state.zoom);
        this.GSTCRecenter(moment().subtract(4, 'hours').valueOf());
      }}
    />
  }
  getConfig = () => {
    return {
      height: ((this.state.rows && this.state.rows.length > 0) ? ((this.state.rows.length + 2) * this.ROW_HEIGHT) : this.ROW_HEIGHT),
      utcMode: false,
      plugins: [
          ItemMovement({
              moveable: true,
              resizeable: true,
              collisionDetection: false,
              ghostNode: false,
              wait: 200,
              resizerContent: '<div class="custom-resizer"></div>',
          }),
          Selection({
            rectStyle: { opacity: '0.0' },
            deselecting: (data, type) => {
              if(this.selectedBlocks.findIndex(x => x.id === data.id) === -1){
                this.selectedBlocks.push({
                  date: data.id,
                  row: data.row.data,
                });
              }
            },
            onFinish: () => {
              if(this.selectedBlocks && this.selectedBlocks.length > 0){
                let item = this.selectedBlocks[0];
                let duration = this.selectedBlocks.length;
                this.selectedBlocks = [];

                let date = item.date;
                let row = item.row;

                let block = this.parseBlock(date);

                if(row && block){
                  let startDate = Utils.getLocalIsoDateTime(moment(block.date + ' ' + block.time).format());
                  
                  let jobData = {
                    isAddNew: true,
                    startDate: startDate,
                    duration: duration,
                    driverId: row.driverId,
                    driverName: row.driverName,
                    vehicleId: row.vehicleId,
                    vehicleName: row.vehicleName,
                  };
                  
                  this.setState({
                    isCreateNewDialog: true,
                    newDialogItem: null,
                    newDialogItemDefDate: this.getSelectedFilterDate(),
                    newDialogParams: jobData
                  });
                }
              }
            },
          }),
          CalendarScroll({ 
              speed: 0,
              hideScroll: false,
          }),
          CustomWeekendHiglight(),
          CustomVehiclesDrivers({
            customVehiclesDriversView: this.customVehiclesDriversView,
          }),
          // CustomSearch({
          //     CustomSearchView: this.customSearchView,
          //     searchPlaceholder: 'Search Vehicle',
          //     onSearch: (val) => {
          //       let filteredData = (this.state.data && this.state.data.length > 0) ? this.state.data.filter(x => (x.vehicleName) ? x.vehicleName.toLowerCase().includes(val.toLowerCase()) : '') : [];
                
          //       this.addData(filteredData, () => {
          //         ReactDOM.unmountComponentAtNode(document.getElementById(this.granttChartID));
          //         setTimeout(() => {
          //           ReactDOM.render(this.getGranttChart(), document.getElementById(this.granttChartID));
          //         }, 300);
          //       });
          //     }
          // }),
          CustomRow({
              rows: (this.state.rows && this.state.rows.length > 0) ? this.state.rows : [
                {
                  id: "0",
                  label: "No Drivers!"
                }
              ], 
              items: this.state.items,
              rowHeight: this.ROW_HEIGHT,
              CustomRowView: this.customRowView
          }),
          CustomItemResize((item) => {
            var dif = moment.duration(moment(item.item.time.end).diff(moment(item.item.time.start)));
            let jobDurationHours = dif.hours();
            let jobDurationMinutes = dif.minutes();

            let jobId = item.item.data.jobId;
            
            let startDayjs = this.getStartDate(item.item.data.jobTimeFrom, item.item.data.jobTimeSpecific, item.item.data.jobDate);
            let endDayjs = this.getEndDate(startDayjs, this.getMinutes(jobDurationHours, jobDurationMinutes));
            
            let items = this.state.items;
            let jobIndex = items.findIndex(x => x.data.jobId === jobId);
            items[jobIndex].data.jobDate = item.item.data.jobDate;
            items[jobIndex].data.jobTimeFrom = item.item.data.jobTimeFrom;
            items[jobIndex].data.jobTimeSpecific = item.item.data.jobTimeSpecific;
            items[jobIndex].data.jobDurationHours = jobDurationHours;
            items[jobIndex].data.jobDurationMinutes = jobDurationMinutes;
            items[jobIndex].data.vehicleId = item.item.data.vehicleId;
            items[jobIndex].data.driverId = item.item.data.driverId;
            items[jobIndex].time.start = startDayjs.valueOf();
            items[jobIndex].time.end = endDayjs.valueOf();


            /* COLOR */
            let statusName = (items[jobIndex].data.jobStatusName) ? items[jobIndex].data.jobStatusName : (items[jobIndex].data.statusName) ? items[jobIndex].data.statusName : '';
            let color = this.getColor(statusName);
            /* END COLOR */
            

            items[jobIndex] = this.addItem(
              jobIndex, 
              items[jobIndex].rowId, 
              ReactDOMServer.renderToStaticMarkup(this.customItemView(items[jobIndex].data, startDayjs.valueOf(), endDayjs.valueOf())), 
              startDayjs.valueOf(), 
              endDayjs.valueOf(),
              color,
              items[jobIndex].data
            );

            this.setState({
              items: items
            }, () => {
              this.GSTCSet('config.chart.items', this.state.items);
              this.GSTCSet('config.chart.items.' + item.item.id + '.style.borderLeft', '5px solid ' + color);

              let data = {
                jobId: jobId,
                jobDate: item.item.data.jobDate,
                jobTimeFrom: item.item.data.jobTimeFrom,
                jobTimeSpecific: item.item.data.jobTimeSpecific,
                jobDurationHours: jobDurationHours,
                jobDurationMinutes: jobDurationMinutes,
                vehicleId: item.item.data.vehicleId,
                driverId: item.item.data.driverId,
              };
  
              this.callUpdateApi(jobId, data);
            });
          }),
      ],
      list: {
          rows: (this.state.rows && this.state.rows.length > 0) ? this.state.rows : [
            {
              id: "0",
              label: "No Drivers!"
            }
          ],
          rowHeight: this.ROW_HEIGHT,
          columns: {
              data: this.state.columns,
              resizer: {
                inRealTime: true
              }
          },
          toggle: {
              display: false
          }
      },
      chart: {
        items: (this.state.items && this.state.items.length > 0) ? this.state.items : [
          {
            id: "-1",
            rowId: "-1",
            label: "",
            time: {
              start: moment(this.state.filterDate).valueOf(),
              end: moment(this.state.filterDate).valueOf()
            }
          }
        ],
        time: {
            // from: moment().subtract(1, 'year').startOf('year').valueOf(),
            // to: moment().add(1, 'year').endOf('year').valueOf(),
            from: moment(this.state.filterDate).valueOf(),
            to: moment(this.state.filterDate).valueOf(),
            period: this.state.period,
            additionalSpaces: {
                minute: { before: 60, after: 60, period: 'minute' },
                hour: { before: this.getCountedHours().before, after: this.getCountedHours().after, period: 'hour' },
                day: { before: 1, after: 1, period: 'month' },
                week: { before: 1, after: 1, period: 'year' },
                month: { before: 6, after: 6, period: 'year' },
                year: { before: 12, after: 12, period: 'year' }
            },
        },
        calendar: {
          expand: true,
          levels: [
            // {
            //   formats: [
            //     {
            //       zoomTo: 14,
            //       period: 'day',
            //       className: 'gstc-date-medium gstc-date-left',
            //       format({ timeStart }) {
            //         return timeStart.format('DD MMMM YYYY (dddd)');
            //       }
            //     },
            //     {
            //       zoomTo: 16,
            //       period: 'day',
            //       format({ timeStart }) {
            //         return timeStart.format('DD MMMM YYYY (dddd)');
            //       }
            //     },
            //     {
            //       zoomTo: 16,
            //       period: 'month',
            //       format({ timeStart, className, vido }) {
            //         return timeStart.format("MMMM 'YY");
            //       }
            //     },
            //     {
            //       zoomTo: 25,
            //       period: 'month',
            //       format({ timeStart }) {
            //         return timeStart.format('MMM YYYY');
            //       }
            //     },
            //     {
            //       zoomTo: 27,
            //       period: 'year',
            //       format({ timeStart }) {
            //         return timeStart.format('YYYY');
            //       }
            //     },
            //     {
            //       zoomTo: 30,
            //       period: 'year',
            //       format({ timeStart }) {
            //         return null;
            //       }
            //     },
            //     {
            //       zoomTo: 100,
            //       period: 'year',
            //       format({ timeStart }) {
            //         return null;
            //       }
            //     },
            //   ]
            // },
            {
              main: true,
              formats: [
                {
                  zoomTo: 9,
                  period: 'minute',
                  format({ timeStart, timeEnd, className, vido }) {
                    return timeStart.format(Utils.getDefaultTimeFormat());
                  }
                },
                {
                  zoomTo: 11,
                  period: 'minute',
                  format({ timeStart, timeEnd, className, vido }) {
                    return vido.html`<div class="${className}-content gstc-date-top"><div class="${className}-content gstc-date-small gstc-date-thin">${timeStart.format(
                      'hh'
                    )}</div><div class="${className}-content gstc-date-small gstc-date-thin">${timeEnd.format(
                      'mm'
                    )}</div><div class="${className}-content gstc-date-small gstc-date-thin">${timeStart.format(
                      'A'
                    )}</div>`;
                  }
                },
                {
                  zoomTo: 14,
                  period: 'hour',
                  format({ timeStart }) {
                    return timeStart.format(Utils.getDefaultTimeFormat());
                  }
                },
                {
                  zoomTo: 16,
                  period: 'hour',
                  default: true,
                  format({ timeStart, timeEnd, className, vido }) {
                    return timeStart.format('HH') + 'h';
                  }
                },
                {
                  zoomTo: 18,
                  period: 'day',
                  default: true,
                  format({ timeStart, vido, className }) {
                    return vido.html`<div class="${className}-content gstc-date-top">${timeStart.format(
                      'DD'
                    )}</div><div class="${className}-content gstc-date-small">${timeStart.format('dddd')}</div>`;
                  }
                },
                {
                  zoomTo: 22,
                  period: 'week',
                  default: true,
                  format({ timeStart, timeEnd, className, vido }) {
                    return vido.html`<div class="${className}-content gstc-date-top">${timeStart.format(
                      'DD'
                    )} - ${timeEnd.format(
                      'DD'
                    )}</div><div class="${className}-content gstc-date-small gstc-date-thin">${timeStart.format(
                      'ddd'
                    )} - ${timeEnd.format('dd')}</div>`;
                  }
                },
                {
                  zoomTo: 25,
                  period: 'week',
                  className: 'gstc-date-vertical',
                  format({ timeStart, timeEnd, className, vido }) {
                    return vido.html`<div class="${className}-content gstc-date-top gstc-date-small gstc-date-normal">${timeStart.format(
                      'DD'
                    )}</div><div class="gstc-dash gstc-date-small">-</div><div class="${className}-content gstc-date-small gstc-date-normal">${timeEnd.format(
                      'DD'
                    )}</div>`;
                  }
                },
                {
                  zoomTo: 26,
                  period: 'month',
                  default: true,
                  className: 'gstc-date-month-level-1',
                  format({ timeStart, vido, className }) {
                    return vido.html`<div class="${className}-content gstc-date-top">${timeStart.format(
                      'MMM'
                    )}</div><div class="${className}-content gstc-date-small gstc-date-bottom">${timeStart.format(
                      'MM'
                    )}</div>`;
                  }
                },
                {
                  zoomTo: 27,
                  period: 'month',
                  className: 'gstc-date-vertical',
                  format({ timeStart, className, vido }) {
                    return vido.html`<div class="${className}-content gstc-date-top">${timeStart.format(
                      'MM'
                    )}</div><div class="${className}-content gstc-date-extra-small">${timeStart.format('MMM')}</div>`;
                  }
                },
                {
                  zoomTo: 28,
                  period: 'year',
                  default: true,
                  className: 'gstc-date-big',
                  format({ timeStart }) {
                    return timeStart.format('YYYY');
                  }
                },
                {
                  zoomTo: 30,
                  period: 'year',
                  className: 'gstc-date-medium',
                  format({ timeStart }) {
                    return timeStart.format('YY');
                  }
                },
                {
                  zoomTo: 31,
                  period: 'year',
                  default: true,
                  format({ timeStart }) {
                    return timeStart.format('YY');
                  }
                },
                {
                  zoomTo: 100,
                  period: 'year',
                  default: true,
                  format({ timeStart }) {
                    return null;
                  }
                }
              ]
            }
          ]
        }
      },
      wrappers: {
        'ChartTimelineItemsRowItem': (item) => {
          if(item && item.values && item.values.length > 0){
            let data = (item.values[2] && item.values[2].props && item.values[2].props.item && item.values[2].props.item.data) ? item.values[2].props.item.data : null;

            let itemType = ItemType.Planed;
            if(data && data.itemType){
              itemType = data.itemType;
            }
            
            item.values[1] = "gantt-schedule-timeline-calendar__chart-timeline-items-row-item " + ((itemType === ItemType.Actual) ? 'actual-wrapper-item' : '');
          }
          
          return item;
        }
      },
      actions: {
          'main': [
              (element, data) => {
                  ClickAction(element, data, (event, item) => {
                    var els = document.querySelectorAll('.visible-context-menu');
                    if(els && els.length > 0){
                      for (var i = 0; i < els.length; i++) {
                        els[i].classList.remove('visible-context-menu')
                      }
                    }

                    if(this.state.items && this.state.items.length > 0){
                      for (var i = 0; i < this.state.items.length; i++) {
                        this.GSTCSet('config.chart.items.' + i + '.style.transform', '');
                        this.GSTCSet('config.chart.items.' + i + '.style.zIndex', '');
                      }
                    }
                  });
              },
          ],
          'chart-timeline-grid-row-block': [
            (element, data) => {
              DragDropAction(element, data, (item, id, info) => {
                let timeBlock = item.id.split(':')[1];
                let rowId = item.row.id;

                
                let jobInfo = null;
                let statusAssigned = null;
                let color = this.colors().none;
                try {
                  jobInfo = JSON.parse(info);
                } catch(e) {
                  //nop
                } finally {
                  statusAssigned = this.findStatus('assigned');
                }


                let jobDurationHours = (jobInfo.jobDurationHours && jobInfo.jobDurationHours > 0) ? jobInfo.jobDurationHours : 0; 
                let jobDurationMinutes = (jobInfo.jobDurationMinutes && jobInfo.jobDurationMinutes > 0) ? jobInfo.jobDurationMinutes : 0; 

                let startDayjs = null;
                let endDayjs = null;
                if(jobInfo.jobTimeFrom && jobInfo.jobTimeFrom !== ''){
                  jobInfo.jobTimeFrom = Utils.getLocalIsoDateTime(moment(jobInfo.jobTimeFrom).format());
                  jobInfo.jobDate = Utils.getLocalIsoDateTime(moment(jobInfo.jobTimeFrom).format());
                  startDayjs = moment(jobInfo.jobTimeFrom);
                  endDayjs = this.getEndDate(startDayjs, this.getMinutes(jobDurationHours, jobDurationMinutes));
                  jobInfo.jobTimeTo = Utils.getLocalIsoDateTime(moment(endDayjs).format());

                  if(jobInfo.jobTimeSpecific && jobInfo.jobTimeSpecific !== ''){
                    jobInfo.jobTimeSpecific = Utils.getLocalIsoDateTime(moment(jobInfo.jobTimeFrom).format());
                  }
                } else if(jobInfo.jobTimeSpecific && jobInfo.jobTimeSpecific !== ''){
                  jobInfo.jobTimeSpecific = Utils.getLocalIsoDateTime(moment(jobInfo.jobTimeSpecific).format());
                  jobInfo.jobDate = Utils.getLocalIsoDateTime(moment(jobInfo.jobTimeSpecific).format());
                  startDayjs = moment(jobInfo.jobTimeSpecific);
                  endDayjs = this.getEndDate(startDayjs, this.getMinutes(jobDurationHours, jobDurationMinutes));
                } else if(jobInfo.jobDate && jobInfo.jobDate !== ''){
                  jobInfo.jobDate = Utils.getLocalIsoDateTime(moment(jobInfo.jobDate).format());
                  startDayjs = moment(jobInfo.jobDate);
                  endDayjs = this.getEndDate(startDayjs, this.getMinutes(jobDurationHours, jobDurationMinutes));
                } else {
                  jobInfo.jobDate = Utils.getLocalIsoDateTime(moment(timeBlock).format());
                  startDayjs = moment(timeBlock);
                  endDayjs = this.getEndDate(startDayjs, this.getMinutes(jobDurationHours, jobDurationMinutes));
                }
    

                jobInfo.jobStatusId = statusAssigned.jobStatusId;
                jobInfo.statusName = statusAssigned.jobStatusName;
                jobInfo.vehicleId = item.row.data.vehicleId;
                jobInfo.vehicleName = item.row.data.vehicleName;
                jobInfo.driverId = item.row.data.driverId;
                jobInfo.driverName = item.row.data.driverName;

                let items = this.state.items;
                items.push(this.addItem(
                  items.length, 
                  rowId, 
                  ReactDOMServer.renderToStaticMarkup(this.customItemView(jobInfo, startDayjs.valueOf(), endDayjs.valueOf())), 
                  startDayjs.valueOf(), 
                  endDayjs.valueOf(),
                  color,
                  jobInfo
                ));
                
                let unassignedData = [];
                let unassignedTotal = 0;
                if(this.state.unassignedData && this.state.unassignedData.length > 0){
                  unassignedData = this.state.unassignedData.filter(x => x.jobId !== jobInfo.jobId);
                  unassignedTotal = (this.state.unassignedTotal && this.state.unassignedTotal > 0) ? (this.state.unassignedTotal - 1) : 0;
                }


                this.setState({
                  items: items,
                  unassignedData: unassignedData,
                  unassignedTotal: unassignedTotal,
                }, () => {
                  this.GSTCSet('config.chart.items', this.state.items);
                  // this.GSTCTimeGoTo(startDayjs, false);

                  let data = {
                    jobId: id,
                    jobDate: jobInfo.jobDate,
                    jobTimeFrom: jobInfo.jobTimeFrom,
                    jobTimeSpecific: jobInfo.jobTimeSpecific,
                    jobDurationHours: jobInfo.jobDurationHours,
                    jobDurationMinutes: jobInfo.jobDurationMinutes,
                    vehicleId: jobInfo.vehicleId,
                    driverId: jobInfo.driverId,
                    jobStatusId: statusAssigned.jobStatusId,
                  };
                  this.callUpdateApi(id, data);
                });
              });
            },
          ],
          'chart-timeline-items-row-item': [
              (element, data) => {
                ClickAction(element, data, (event, item) => {
                  event.stopPropagation();

                  let ids = [];
                  if(this.state.items && this.state.items.length > 0){
                    for (var i = 0; i < this.state.items.length; i++) {
                      if(i.toString() !== item.item.id){
                        this.GSTCSet('config.chart.items.' + i + '.style.transform', '');
                        this.GSTCSet('config.chart.items.' + i + '.style.zIndex', '');
                      }

                      if(this.state.items[i].data.jobId === item.item.data.jobId){
                        ids.push(i);
                      }
                    }
                  }

                  if(ids && ids.length > 0){
                    let style = this.GSTCGet('config.chart.items.' + item.item.id + '.style.transform');

                    for(let j = 0; j < ids.length; j++){
                      this.GSTCSet('config.chart.items.' + ids[j] + '.style.transform', (style && style !== '') ? '' : 'scale(1.2)');
                      this.GSTCSet('config.chart.items.' + ids[j] + '.style.zIndex', (style && style !== '') ? '' : '10');
                    }
                  }
                });

                if(data.item.data.itemType === ItemType.Planed){
                  DropAction(element, data, (event, item) => {
                    

                    let jobDate = item.item.data.jobDate;
                    let jobTimeFrom = item.item.data.jobTimeFrom;
                    let jobTimeTo = item.item.data.jobTimeTo;
                    let jobTimeSpecific = item.item.data.jobTimeSpecific;


                    let startDayjs = null;
                    let endDayjs = null;
                    if(jobTimeFrom && jobTimeFrom !== ''){
                      jobTimeFrom = Utils.getLocalIsoDateTime(moment(item.item.time.start).format());
                      jobDate = Utils.getLocalIsoDateTime(moment(item.item.time.start).format());
                      startDayjs = moment(item.item.time.start);
                      endDayjs = this.getEndDate(startDayjs, this.getMinutes(item.item.data.jobDurationHours, item.item.data.jobDurationMinutes));
                      jobTimeTo = Utils.getLocalIsoDateTime(moment(endDayjs).format());

                      if(jobTimeSpecific && jobTimeSpecific !== ''){
                        jobTimeSpecific = Utils.getLocalIsoDateTime(moment(item.item.time.start).format());
                      }
                    } else if(jobTimeSpecific && jobTimeSpecific !== ''){
                      jobTimeSpecific = Utils.getLocalIsoDateTime(moment(item.item.time.start).format());
                      jobDate = Utils.getLocalIsoDateTime(moment(item.item.time.start).format());
                      startDayjs = moment(item.item.time.start);
                      endDayjs = this.getEndDate(startDayjs, this.getMinutes(item.item.data.jobDurationHours, item.item.data.jobDurationMinutes));
                    } else {
                      jobDate = Utils.getLocalIsoDateTime(moment(item.item.time.start).format());
                      startDayjs = moment(item.item.time.start);
                      endDayjs = this.getEndDate(startDayjs, this.getMinutes(item.item.data.jobDurationHours, item.item.data.jobDurationMinutes));
                    }
                    
                    let jobId = item.item.data.jobId;
                    let vehicleId = item.row.data.vehicleId;
                    let vehicleName = item.row.data.vehicleName;
                    let driverId = item.row.data.driverId;
                    let driverName = item.row.data.driverName;

                    let items = this.state.items;
                    let jobIndex = items.findIndex(x => x.data.jobId === jobId);
                    items[jobIndex].data.jobDate = jobDate;
                    items[jobIndex].data.jobTimeFrom = jobTimeFrom;
                    items[jobIndex].data.jobTimeTo = jobTimeTo;
                    items[jobIndex].data.jobTimeSpecific = jobTimeSpecific;
                    items[jobIndex].data.jobDurationHours = item.item.data.jobDurationHours;
                    items[jobIndex].data.jobDurationMinutes = item.item.data.jobDurationMinutes;
                    items[jobIndex].data.vehicleId = vehicleId;
                    items[jobIndex].data.vehicleName = vehicleName;
                    items[jobIndex].data.driverId = driverId;
                    items[jobIndex].data.driverName = driverName;
                    items[jobIndex].data.jobStatusId = item.item.data.jobStatusId;
                    items[jobIndex].time.start = startDayjs.valueOf();
                    items[jobIndex].time.end = endDayjs.valueOf();
                    items[jobIndex].rowId = item.row.id.toString();


                    /* COLOR */
                    let statusName = (items[jobIndex].data.jobStatusName) ? items[jobIndex].data.jobStatusName : (items[jobIndex].data.statusName) ? items[jobIndex].data.statusName : '';
                    let color = this.getColor(statusName);
                    /* END COLOR */
                    

                    items[jobIndex] = this.addItem(
                      jobIndex, 
                      items[jobIndex].rowId, 
                      ReactDOMServer.renderToStaticMarkup(this.customItemView(items[jobIndex].data, startDayjs.valueOf(), endDayjs.valueOf())), 
                      startDayjs.valueOf(), 
                      endDayjs.valueOf(),
                      color,
                      items[jobIndex].data
                    );

                    this.setState({
                      items: items,
                    }, () => {
                      this.GSTCSet('config.chart.items', this.state.items);
                      this.GSTCSet('config.chart.items.' + item.item.id + '.style.borderLeft', '5px solid ' + color);

                      let data = {
                        jobId: jobId,
                        jobDate: jobDate,
                        jobTimeFrom: jobTimeFrom,
                        jobTimeSpecific: jobTimeSpecific,
                        jobDurationHours: item.item.data.jobDurationHours,
                        jobDurationMinutes: item.item.data.jobDurationMinutes,
                        vehicleId: vehicleId,
                        vehicleName: vehicleName,
                        driverId: item.item.data.driverId,
                        jobStatusId: item.item.data.jobStatusId,
                      };
                      this.callUpdateApi(jobId, data);
                    });
                  });
                }
              },
              (element, data) => {
                HoverAction(element, data);
              },
              (element, data) => {
                RightClickAction(element, data, 
                  (event, item) => {
                    if(this.state.mouseY !== null){
                      this.setState({
                        mouseGanttChartX: null,
                        mouseGanttChartY: null,
                        contextMenuGanttChartItem: null,
                      });
                    } else {
                      this.setState({
                        mouseGanttChartX: event.clientX - 2,
                        mouseGanttChartY: event.clientY - 4,
                        contextMenuGanttChartItem: item,
                      });
                    }
                  }
                );
              },
          ]
      }
    }
  }
  GSTCRecenter = (time = null) => {
    if(this.GSTC){
      this.GSTC.api.scrollToTime((time) ? time : moment().subtract(12, 'hour').valueOf());
    }
  }
  GSTCSet = (config, value) => {
    if(this.GSTC){
      this.GSTC.state.update(config, value);
    }
  }
  GSTCGet = (config) => {
    if(this.GSTC){
      return this.GSTC.state.get(config);
    }
  }
  GSTCTimePrevious = () => {
    let time = this.GSTCGet('config.chart.time');
    
    time.from = moment(time.from).subtract(1, time.period).valueOf();
    time.finalFrom = moment(time.finalFrom).subtract(1, time.period).valueOf();
    time.leftGlobal = moment(time.leftGlobal).subtract(1, time.period).valueOf();

    time.to = moment(time.to).subtract(1, time.period).valueOf();
    time.finalTo = moment(time.finalTo).subtract(1, time.period).valueOf();
    time.rightGlobal = moment(time.rightGlobal).subtract(1, time.period).valueOf();
    
    this.GSTCSet('config.chart.time', time);
  }
  GSTCTimeNext = () => {
    let time = this.GSTCGet('config.chart.time');
    
    time.from = moment(time.from).add(1, time.period).valueOf();
    time.finalFrom = moment(time.finalFrom).add(1, time.period).valueOf();
    time.leftGlobal = moment(time.leftGlobal).add(1, time.period).valueOf();

    time.to = moment(time.to).add(1, time.period).valueOf();
    time.finalTo = moment(time.finalTo).add(1, time.period).valueOf();
    time.rightGlobal = moment(time.rightGlobal).add(1, time.period).valueOf();
    
    this.GSTCSet('config.chart.time', time);
  }
  GSTCTimeGoTo = (date, clearTime = true) => {
    let m = moment(date);

    if(clearTime){
      m.set({h: 0, m: 0, s: 0});
    }

    if(this.GSTC){
      this.GSTC.api.scrollToTime(m.valueOf());
    }
  }
  /* END GSTC */

  
  /* TEMPLATES */
  customRowView = (row, rowItems, rowHeight) => {
    let completedItems = null;
    let lateItems = null;
    if(rowItems && rowItems.length > 0){
      completedItems = rowItems.filter(x => x.data.statusName === 'Completed');
      let dispatchedItems = rowItems.filter(x => x.data.statusName === 'Dispatched');
      lateItems = (dispatchedItems && dispatchedItems.length > 0) ? dispatchedItems.filter(x => this.isAfterDate(moment(), moment(x.time.start))) : [];
    }

    return <Box className={'custom-list-column-row'}
      style={{
        height: rowHeight + 'px'
      }}
    >
      <div className={'MuiGrid-container'}>
        <Box clone>
          <div className={'MuiGrid-item MuiGrid-grid-xs-9'}
            style={{
              paddingLeft: '15px'
            }}
          >
            <div className={'MuiGrid-container'}>
              <Box clone>
                <div className={'MuiGrid-item MuiGrid-grid-xs-12 custom-label'}>
                  <span>{(row && row.label) ? row.label : ''}</span>
                  {(rowItems.length > 0) && <IconButton
                    style={{ position: 'absolute',  right: '15px', padding: '0px' }} 
                    onClick={(e) => {
                      this.setState({
                        isMapDialog: true,
                        mapDialogItem: row,
                      });
                    }}
                  >
                    <CirclePlay />
                  </IconButton>}
                </div>
              </Box>
              <Box clone>
                <div className={'MuiGrid-item MuiGrid-grid-xs-12 custom-desc'}>
                  <span>{(row && row.data && row.data.vehicleName && row.data.vehicleName !== '') ? row.data.vehicleName : ''}</span>
                </div>
              </Box>
              <Box clone>
                <div className={'MuiGrid-item MuiGrid-grid-xs-4'}>
                  <Tooltip title={'Numb of jobs'}>
                    <span className="custom-count">{(rowItems) ? rowItems.length : '0'}</span>
                  </Tooltip>
                </div>
              </Box>
              <Box clone>
                <div className={'MuiGrid-item MuiGrid-grid-xs-4'}>
                  {(completedItems && completedItems.length > 0) && <Tooltip title={'Completed jobs'}>
                    <Box className={'custom-done'}>
                      <i className="material-icons">done</i>
                      <span>{completedItems.length}</span>
                    </Box>
                  </Tooltip>}
                </div>
              </Box>
              <Box clone>
                <div className={'MuiGrid-item MuiGrid-grid-xs-4'}>
                  {(lateItems && lateItems.length > 0) && <Tooltip title={'Late for job'}>
                    <Box className={'custom-watch'}>
                      <i className="material-icons">watch_later</i>
                      <span>{lateItems.length}</span>
                    </Box>
                  </Tooltip>}
                </div>
              </Box>
            </div>
          </div>
        </Box>
      </div>
    </Box>;
  }
  
  customItemView = (item, startDate, endDate, minutes) => {
    /* BIN / WASTE TYPE / ADDRESS */
    let binType = '';
    let wasteType = '';
    let address = '';
    if(item && item.steps && item.steps.length > 0){
      let item1 = (item.steps[0]) ? item.steps[0] : null;
      let item2 = (item.steps[1]) ? item.steps[1] : null;
      let item3 = (item.steps[2]) ? item.steps[2] : null;

      if(JobStepType.Exchange === item1.stepType){
        binType = item2.binTypeName;
        wasteType = item2.wasteTypeName;
      } else {
        binType = item1.binTypeName;
        wasteType = item1.wasteTypeName;
      }

      for(let i = 0; i < item.steps.length; i++){
        if(item.steps[i].customerSiteId && item.steps[i].customerSiteId > 0){
          address = item.steps[i].address;
        }
      }

      if(address === ''){
        address = (item3) ? item3.address : item2.address;
      }
    }
    /* END BIN / WASTE TYPE / ADDRESS */

    let isAfterDate = false;
    if(item.statusName.toLowerCase() === 'dispatched'){
      isAfterDate = this.isAfterDate(moment(), moment(startDate));
    }

    /* COLOR */
    let statusName = (item.jobStatusName) ? item.jobStatusName : (item.statusName) ? item.statusName : '';
    let color = this.getColor(statusName);
    let paleColor = this.getPaleColor(statusName);
    /* END COLOR */

    return <Box className={'custom-item ' + ((item.itemType === ItemType.Actual) ? 'actual-item' : '')}
      style={{
        borderColor: color,
        backgroundColor: ((item.itemType === ItemType.Actual) ? paleColor : ''),
      }}
    >
      <div className={'MuiGrid-container'}>
        <Box clone pb={0.5}>
          <div className={'MuiGrid-item MuiGrid-grid-xs-12'}>
            <b>{item.jobTemplateName}</b>
            {(isAfterDate) && <i className="material-icons custom-watch">watch_later</i>}
            {/* <span className={'custom-time'}>{moment(startDate).format(Utils.getDefaultTimeFormat())}</span>
            <div className={'custom-circle'}></div>
            <b>{binType}</b>
            <div className={'custom-circle'}></div>
            <b>{wasteType}</b> */}
          </div>
        </Box>
        <Box clone>
          <div className={'MuiGrid-item MuiGrid-grid-xs-12'}>{item.customerName} - {address}</div>
        </Box>
      </div>
      
      <Paper className={'custom-item-info-center'}>
        <Box className={'custom-item-info-tooltip'} p={1}>
          <Box className={'custom-tooltip-title'}>{item.customerName}</Box>
          <Box className={'custom-tooltip-address'}>{address}</Box>
          <Box className={'custom-tooltip-info'}>{item.jobTemplateName} | {binType} | {wasteType}</Box>
          <Box className={'custom-tooltip-time'}>
            <span className={'custom-tooltip-time-left'}>{moment(startDate).format(Utils.getDefaultTimeFormat())}</span>
            {
              (minutes > 1)
              ?
              <span className={'custom-tooltip-time-right'}>{moment(endDate).format(Utils.getDefaultTimeFormat())}</span>
              :
              <span className={'custom-tooltip-time-right'}>{moment(endDate).subtract(minutes, 'm').format(Utils.getDefaultTimeFormat())}</span>
            }
          </Box>
        </Box>
      </Paper>

      <Paper className={'custom-item-info-left'}>
        <Box className={'custom-item-info-left-text'} p={1}>{moment(startDate).format(Utils.getDefaultTimeFormat())}</Box>
      </Paper>
      <Paper className={'custom-item-info-right'}>
        <Box className={'custom-item-info-right-text'} p={1}></Box>
      </Paper>
    </Box>;
  }
  
  customUnassignedItemView = (item) => {
    /* DATE & TIME */
    let startTime = this.getStartDateFormat(item.jobTimeFrom, item.jobTimeSpecific, item.jobDate);
    /* END DATE & TIME */


    /* BIN / WASTE TYPE / ADDRESS */
    let binType = '';
    let wasteType = '';
    let address = '';
    if(item && item.steps && item.steps.length > 0){
      let item1 = (item.steps[0]) ? item.steps[0] : null;
      let item2 = (item.steps[1]) ? item.steps[1] : null;
      let item3 = (item.steps[2]) ? item.steps[2] : null;

      if(JobStepType.Exchange === item1.stepType){
        binType = item2.binTypeName;
        wasteType = item2.wasteTypeName;
      } else {
        binType = item1.binTypeName;
        wasteType = item1.wasteTypeName;
      }

      for(let i = 0; i < item.steps.length; i++){
        if(item.steps[i].customerSiteId && item.steps[i].customerSiteId > 0){
          address = item.steps[i].address;
        }
      }

      if(address === ''){
        address = (item3) ? item3.address : item2.address;
      }
    }
    /* END BIN / WASTE TYPE / ADDRESS */


    let isAfterDate = false;
    if(item.statusName.toLowerCase() === 'dispatched'){
      isAfterDate = this.isAfterDate(moment(), moment(startTime));
    }

    return <Box
      className={'custom-item'}
      style={{ cursor: 'context-menu' }}
      draggable={true} 
      onDragStart={(e) => {
        e.dataTransfer.setData("id", item.jobId);
        e.dataTransfer.setData("info", JSON.stringify(item));
      }}
      onContextMenu={(event) => {
        event.preventDefault();

        let jobId = (event && event.currentTarget && event.currentTarget.dataset && event.currentTarget.dataset.id) ? event.currentTarget.dataset.id : null;
        
        if(this.state.mouseY !== null){
          this.setState({
            mouseX: null,
            mouseY: null,
            contextMenuItem: null,
          });
        } else {
          this.setState({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
            contextMenuItem: jobId,
          });
        }
      }}
      data-id={item.jobId}
    >
      <Tooltip title={item.customerName + ' - ' + address}>
        <Grid container>
          <Box clone pb={0.5}>
            <Grid item xs={12}>
              <b>{item.jobTemplateName}</b>
              {(isAfterDate) && <i className="material-icons custom-watch">watch_later</i>}
              {/* <span className={'custom-time'}>{startTime}</span>
              <div className={'custom-circle'}></div>
              <b>{binType}</b>
              <div className={'custom-circle'}></div>
              <b>{wasteType}</b> */}
            </Grid>
          </Box>
          <Box clone>
            <Grid item xs={12}>{item.customerName} - {address}</Grid>
          </Box>
        </Grid>
      </Tooltip>

      <Menu
        classes={{
          paper: 'custom-border',
        }}
        elevation={0}
        keepMounted
        open={this.state.mouseY !== null}
        onClose={this.hideContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          this.state.mouseY !== null && this.state.mouseX !== null
            ? { top: this.state.mouseY, left: this.state.mouseX }
            : undefined
        }
      >
        {/* <MenuItem 
          onClick={() => {
            this.setState({
              isCreateNewDialog: true,
              newDialogItemDefDate: null,
              newDialogItem: {
                jobId: this.state.contextMenuItem
              },
              newDialogParams: null,
            }, () => {
              this.hideContextMenu()
            });
          }}
        >Edit Job</MenuItem> */}
      </Menu>
    </Box>;
  }

  // customSearchView = (searchPlaceholder, onSearch) => {
  //   return <Box className={'custom-search-row'}>
  //     <div className={'MuiGrid-container'}>
  //       <Box clone>
  //         <div className={'MuiGrid-item MuiGrid-grid-xs-7 MuiGrid-grid-md-9'}>
  //           <DataTableSearch 
  //             className={"custom-search-field"}
  //             placeholder={searchPlaceholder}
  //             onPressEnter={(val) => {
  //               if(onSearch !== null){
  //                 onSearch(val);
  //               }
  //             }}
  //             onButtonClick={(val) => {
  //               if(onSearch !== null){
  //                 onSearch(val);
  //               }
  //             }}
  //           />
  //         </div>
  //       </Box>
  //       <Box clone display={'flex'} alignItems={'center'}>
  //         <div className={'MuiGrid-item MuiGrid-grid-xs-5 MuiGrid-grid-md-3'}>
  //           <CustomIconButton
  //             icon={'keyboard_arrow_left'}
  //             onClick={() => {
  //               this.GSTCTimePrevious();
  //             }}
  //           />
  //           <CustomIconButton
  //             icon={'keyboard_arrow_right'}
  //             onClick={() => {
  //               this.GSTCTimeNext();
  //             }}
  //           />
  //         </div>
  //       </Box>
  //     </div>
  //   </Box>;
  // }

  customVehiclesDriversView = () => {
    return <Box className={'custom-vehicles-drivers-row'}>
      <div className={'MuiGrid-container'}>
        <Box clone>
          <div className={'MuiGrid-item MuiGrid-grid-xs-12'}>
            <DropdownCheckbox 
              className={'ganttchart-drivers-btn'}
              text={'Drivers'}
              rightIcon={<Icon component={'i'} style={{ top: '1px' }}>expand_more</Icon>}
              menuItems={this.state.allDrivers}
              onChange={(items) => {
                this.setState({
                  allDrivers: items
                });
              }}
              onApply={(items) => {
                this.loadGanttChartData();
              }}
            />
          </div>
        </Box>
      </div>
    </Box>;
  }
  /* END TEMPLATES */


  /* FUNCTIONS */
  colors = () => {
    return {
      none: '#F6F6F6',
      gray: '#7F7F7F',
      blue: '#185cff',
      purple: '#8C03F4',
      green: '#06D6A0',
      red: '#EF476F',
    }
  }
  paleColors = () => {
    return {
      none: '#f9fafb',
      gray: '#f2f3f4',
      blue: '#ecf6fa',
      purple: '#f3edfa',
      green: '#ecf8f6',
      red: '#f8f1f4',
    }
  }

  addColumn = (id = 'label', width = 250) => {
    return {
      id: id,
      width: width,
      data: null,
      header: {
        content: '',
        isHtml: true,
      }
    }
  }

  addRow = (id = 0, label = '', data = null, parentId = undefined, expanded = false) => {
    return {
      id: id.toString(),
      label: label, 
      isHtml: true,
      parentId: parentId ? parentId.toString() : undefined,
      expanded: expanded,
      data: data
    }
  }

  addItem = (id = 0, rowId = 0, template = '', start = null, end = null, color, data = null) => {
    let resizable = true;
    let moveable = true;
    if(data && data.itemType === ItemType.Actual){
      resizable = false;
      moveable = false;
    } else {
      if(data){
        if((data.statusName === 'Started') || (data.statusName === 'In Progress') || (data.statusName === 'Completed') || (data.statusName === 'Cancelled') || (data.statusName === 'Failed') || (data.statusName === 'Rejected')){
          resizable = false;
          moveable = false;
        }
      }
    }

    return {
      id: id.toString(),
      rowId: rowId.toString(),
      label: template,
      isHtml: true,
      time: {
        start: start,
        end: end
      },
      resizable: resizable,
      moveable: moveable,
      style: { 
        borderLeft: '5px solid ' + (color ? color : this.colors().none),
      },
      data: data
    }
  }

  addData = (data = [], callback = null) => {
    let column = this.addColumn();
    let columns = { [column.id]: column };
    let rows = [];
    let items = [];

    let dataItems = [];
    if(data && data.length > 0){
      // dataItems = data.filter(x => (x.driverId !== null && x.driverName !== null && x.vehicleId !== null && x.vehicleName !== null));
      dataItems = data.filter(x => (x.driverId !== null && x.driverName !== null));
    }

    if(dataItems && dataItems.length > 0){
      let countItems = 0;
      for (let r = 0; r < dataItems.length; r++) {
        let obj = dataItems[r];

        rows.push(this.addRow(r, obj.driverName, obj));
        
        if(obj && obj.jobs && obj.jobs.length > 0){
          for (let i = 0; i < obj.jobs.length; i++) {
            let job = obj.jobs[i];

            /* COLOR */
            let color = this.getColor(job.statusName);
            /* END COLOR */


            /* DATE & TIME */
            let jobDurationHours = (job.jobDurationHours && job.jobDurationHours > 0) ? job.jobDurationHours : 0; 
            let jobDurationMinutes = (job.jobDurationMinutes && job.jobDurationMinutes > 0) ? job.jobDurationMinutes : 0; 

            let startDayjs = this.getStartDate(job.jobTimeFrom, job.jobTimeSpecific, job.jobDate);
            let minutes = this.getMinutes(jobDurationHours, jobDurationMinutes);
            let endDayjs = this.getEndDate(startDayjs, minutes);
            /* END DATE & TIME */

            items.push(this.addItem(
              countItems, 
              r, 
              ReactDOMServer.renderToStaticMarkup(this.customItemView(job, startDayjs.valueOf(), endDayjs.valueOf(), minutes)), 
              startDayjs.valueOf(), 
              endDayjs.valueOf(),
              color,
              job
            ));

            countItems++;
          }
        }
      }
    }

    this.setState({
      columns: columns,
      rows: rows,
      items: items,
    }, () => {
      if(callback){
        callback();
      }
    });
  }

  findStatus = (text) => {
		let status = null;

		if(this.state.status && this.state.status.length > 0){
			let statusItem = this.state.status.filter(x => x.jobStatusName.toLowerCase() === text.toLowerCase());

			if(statusItem && statusItem.length > 0){
				status = statusItem[0];
			}
		}

		return status;
  }

  prevDateFilters = () => {
    return moment(this.state.filterDate).subtract(1,'days');
  }
  nextDateFilters = () => {
		return moment(this.state.filterDate).add(1,'days');
  }

  parseBlock = (item = '') => {
    let itemSplitSpace = '';
    let itemTime = '';
    let itemRowId = '';
    let itemDate = '';

    try {
      itemSplitSpace = item.split(' ')[0];
      itemTime = item.split(' ')[1];
      itemRowId = itemSplitSpace.split(':')[0];
      itemDate = itemSplitSpace.split(':')[1];
      
      return {
        rowId: itemRowId,
        date: itemDate,
        time: itemTime,
      };
    } catch (e){
      return null;
    }
  }

  getCountedHours = () => {
    let h = moment(this.state.filterDate).hour();
    let after = 24 - h;
		return {
      before: h+1, 
      after: after
    }
  }

  getStartDateFormat = (jobTimeFrom, jobTimeSpecific, jobDate) => {
    if(jobTimeFrom && jobTimeFrom !== ''){
      return moment(jobTimeFrom).format(Utils.getDefaultTimeFormat());
    } else if(jobTimeSpecific && jobTimeSpecific !== ''){
      return moment(jobTimeSpecific).format(Utils.getDefaultTimeFormat());
    } else {
      return moment(jobDate).format(Utils.getDefaultTimeFormat());
    }
  }
  getStartDate = (jobTimeFrom, jobTimeSpecific, jobDate) => {
		if(jobTimeFrom && jobTimeFrom !== ''){
      return moment(jobTimeFrom);
    } else if(jobTimeSpecific && jobTimeSpecific !== ''){
      return moment(jobTimeSpecific);
    } else {
      return moment(jobDate);
    }
  }
  getEndDate = (startDayjs, minutes) => {
    return startDayjs.clone().add(minutes, 'minutes');
  }
  getMinutes = (jobDurationHours, jobDurationMinutes) => {
		let minutes = (jobDurationHours * 60) + jobDurationMinutes;
    return (minutes && minutes > 0) ? minutes : 1;
  }
  isAfterDate = (date1, date2) => {
		return moment(date1).isAfter(date2);
  }
  getColor = (statusName) => {
    if(statusName === 'Assigned'){
      return this.colors().none;
    } else if(statusName === 'Dispatched' || statusName === 'Acknowledged'){
      return this.colors().gray;
    } else if(statusName === 'Started'){
      return this.colors().blue;
    } else if(statusName === 'In Progress'){
      return this.colors().purple;
    } else if(statusName === 'Completed'){
      return this.colors().green;
    } else if(statusName === 'Cancelled' || statusName === 'Failed'){
      return this.colors().red;
    } else {
      return this.colors().none;
    }
  }
  getPaleColor = (statusName) => {
    if(statusName === 'Assigned'){
      return this.paleColors().none;
    } else if(statusName === 'Dispatched' || statusName === 'Acknowledged'){
      return this.paleColors().gray;
    } else if(statusName === 'Started' || statusName === 'In Progress'){
      return this.paleColors().purple;
    } else if(statusName === 'Completed'){
      return this.paleColors().green;
    } else if(statusName === 'Cancelled' || statusName === 'Failed'){
      return this.paleColors().red;
    } else {
      return this.paleColors().none;
    }
  }
  
  setDateFilters = (filterDate, callback = null) => {
    let date = moment(filterDate);
    let dateText = date.format('dddd DD, MMM');

    if(moment(date.format(this.state.dateFormat)).isSame(moment().subtract(1, 'days').format(this.state.dateFormat))){
      dateText = "(YESTERDAY) " + date.format('dddd DD, MMM');
    } else if (moment(date.format(this.state.dateFormat)).isSame(moment().format(this.state.dateFormat))) {
      dateText = "(TODAY) " + date.format('dddd DD, MMM');
    } else if (moment(date.format(this.state.dateFormat)).isSame(moment().add(1, 'days').format(this.state.dateFormat))) {
      dateText = "(TOMORROW) " + date.format('dddd DD, MMM');
    }

    this.setState({
      filterDate: filterDate,
      filterDateText: dateText,
    }, () => {
      if(callback !== null){
        callback(dateText);
      }
    });
  }

  loadGanttChartData = (jobStatusFilter = null, callback = null) => {
    this.callReadGroupByDriverApi(true, jobStatusFilter, (data) => {
      this.addData(this.state.data, () => {
        ReactDOM.render(this.getGranttChart(), document.getElementById(this.granttChartID));

        if(callback !== null){
          callback();
        }
      });
    });
  }


  realTimeUpdate = (jobInfo) => {
    let items = this.state.items;
    let jobIndex = items.findIndex(x => x.data.jobId === jobInfo.jobId);

    if(jobInfo && jobInfo.jobId && (jobInfo.jobId > 0) && (jobIndex !== -1)){
      let startDayjs = this.getStartDate(jobInfo.jobTimeFrom, jobInfo.jobTimeSpecific, jobInfo.jobDate);
      let endDayjs = this.getEndDate(startDayjs, this.getMinutes(jobInfo.jobDurationHours, jobInfo.jobDurationMinutes));

      let color = this.getColor((jobInfo.status && jobInfo.status.jobStatusName) ? jobInfo.status.jobStatusName : '');
      if(color === this.getColor('')){
        color = this.getColor((jobInfo.statusName && jobInfo.statusName) ? jobInfo.statusName : '');
      }


      items[jobIndex] = this.addItem(
        items[jobIndex].id, 
        items[jobIndex].rowId, 
        ReactDOMServer.renderToStaticMarkup(this.customItemView(jobInfo, startDayjs.valueOf(), endDayjs.valueOf())), 
        startDayjs.valueOf(), 
        endDayjs.valueOf(),
        color,
        jobInfo
      );


      let rows = this.state.rows;
      if(rows && rows.length > 0 && jobInfo.driverId > 0){
        let rowItem = rows.filter(r => r.data.driverId === jobInfo.driverId);
        if(rowItem && rowItem.length > 0){
          items[jobIndex].rowId = rowItem[0].id.toString();
        }
      }


      let unassignedData = [];
      let unassignedTotal = 0;
      if(this.state.unassignedData && this.state.unassignedData.length > 0){
        unassignedData = this.state.unassignedData.filter(x => x.jobId !== jobInfo.jobId);
        unassignedTotal = (this.state.unassignedTotal && this.state.unassignedTotal > 0) ? (this.state.unassignedTotal - 1) : 0;
      }

      this.setState({
        items: items,
        unassignedData: unassignedData,
        unassignedTotal: unassignedTotal,
      }, () => {
        this.callReadUnassignedApi();
        this.GSTCSet('config.chart.items', this.state.items);
      });
    } else {
      let rowId = '';
      let rows = this.state.rows;
      if(rows && rows.length > 0 && jobInfo.driverId > 0){
        let rowItem = rows.filter(r => r.data.driverId === jobInfo.driverId);
        if(rowItem && rowItem.length > 0){
          rowId = rowItem[0].id.toString();
        }
      }

      let color = this.colors().none;

      let jobDurationHours = (jobInfo.jobDurationHours && jobInfo.jobDurationHours > 0) ? jobInfo.jobDurationHours : 0; 
      let jobDurationMinutes = (jobInfo.jobDurationMinutes && jobInfo.jobDurationMinutes > 0) ? jobInfo.jobDurationMinutes : 0; 

      let startDayjs = null;
      let endDayjs = null;
      if(jobInfo.jobTimeFrom && jobInfo.jobTimeFrom !== ''){
        jobInfo.jobTimeFrom = Utils.getLocalIsoDateTime(moment(jobInfo.jobTimeFrom).format());
        jobInfo.jobDate = Utils.getLocalIsoDateTime(moment(jobInfo.jobTimeFrom).format());
        startDayjs = moment(jobInfo.jobTimeFrom);
        endDayjs = this.getEndDate(startDayjs, this.getMinutes(jobDurationHours, jobDurationMinutes));
        jobInfo.jobTimeTo = Utils.getLocalIsoDateTime(moment(endDayjs).format());

        if(jobInfo.jobTimeSpecific && jobInfo.jobTimeSpecific !== ''){
          jobInfo.jobTimeSpecific = Utils.getLocalIsoDateTime(moment(jobInfo.jobTimeFrom).format());
        }
      } else if(jobInfo.jobTimeSpecific && jobInfo.jobTimeSpecific !== ''){
        jobInfo.jobTimeSpecific = Utils.getLocalIsoDateTime(moment(jobInfo.jobTimeSpecific).format());
        jobInfo.jobDate = Utils.getLocalIsoDateTime(moment(jobInfo.jobTimeSpecific).format());
        startDayjs = moment(jobInfo.jobTimeSpecific);
        endDayjs = this.getEndDate(startDayjs, this.getMinutes(jobDurationHours, jobDurationMinutes));
      } else {
        jobInfo.jobDate = Utils.getLocalIsoDateTime(moment(jobInfo.jobDate).format());
        startDayjs = moment(jobInfo.jobDate);
        endDayjs = this.getEndDate(startDayjs, this.getMinutes(jobDurationHours, jobDurationMinutes));
      }

      let items = this.state.items;
      items.push(this.addItem(
        items.length, 
        rowId, 
        ReactDOMServer.renderToStaticMarkup(this.customItemView(jobInfo, startDayjs.valueOf(), endDayjs.valueOf())), 
        startDayjs.valueOf(), 
        endDayjs.valueOf(),
        color,
        jobInfo
      ));

      
      let unassignedData = [];
      let unassignedTotal = 0;
      if(this.state.unassignedData && this.state.unassignedData.length > 0){
        unassignedData = this.state.unassignedData.filter(x => x.jobId !== jobInfo.jobId);
        unassignedTotal = (this.state.unassignedTotal && this.state.unassignedTotal > 0) ? (this.state.unassignedTotal - 1) : 0;
      }


      this.setState({
        items: items,
        unassignedData: unassignedData,
        unassignedTotal: unassignedTotal,
      }, () => {
        this.callReadUnassignedApi();
        this.GSTCSet('config.chart.items', this.state.items);
      });
    }
  }

  hideContextMenu = () => {
    this.setState({
      mouseX: null,
      mouseY: null,
      contextMenuItem: null,
    });
  }
  hideContextMenuGanttChart = () => {
    this.setState({
      mouseGanttChartX: null,
      mouseGanttChartY: null,
      contextMenuGanttChartItem: null,
    });
  }

  getStatusGanttChart = () => {
    let item = this.state.contextMenuGanttChartItem;
    let job = (item && item.item && item.item.data) ? item.item.data : null;
    return (job && job.statusName) ? job.statusName : '';
  }
  getItemGanttChart = () => {
    let item = this.state.contextMenuGanttChartItem;
    return (item && item.item && item.item.data) ? item.item.data : null;
  }

  isDisabledItemGanttChart = (statusName = '') => {
    let unassigned = false;
    let started = false;
    let completed = false;
    let cancelled = false;
    let failed = false;

    if(statusName.toLowerCase() === 'unassigned'){
      started = true;
      completed = true;
      cancelled = true;
      failed = true;
    } else if(statusName.toLowerCase() === 'assigned'){
      unassigned = false;
      started = false;
      completed = false;
      cancelled = false;
      failed = false;
    } else if(statusName.toLowerCase() === 'dispatched'){
      unassigned = false;
      started = false;
      completed = false;
      cancelled = false;
      failed = false;
    } else if(statusName.toLowerCase() === 'acknowledged'){
      unassigned = false;
      started = false;
      completed = false;
      cancelled = false;
      failed = false;
    } else if(statusName.toLowerCase() === 'started'){
      unassigned = true;
      started = true;
      completed = false;
      cancelled = false;
      failed = false;
    } else if(statusName.toLowerCase() === 'in progress'){
      unassigned = true;
      started = true;
      completed = false;
      cancelled = false;
      failed = false;
    } else if(statusName.toLowerCase() === 'completed'){
      unassigned = true;
      started = true;
      completed = true;
      cancelled = true;
      failed = true;
    } else if(statusName.toLowerCase() === 'cancelled'){
      unassigned = true;
      started = true;
      completed = true;
      cancelled = true;
      failed = true;
    } else if(statusName.toLowerCase() === 'failed'){
      unassigned = true;
      started = true;
      completed = true;
      cancelled = true;
      failed = true;
    } else if(statusName.toLowerCase() === 'rejected'){
      unassigned = true;
      started = true;
      completed = true;
      cancelled = true;
      failed = true;
    }

    return {
      unassigned: unassigned,
      started: started,
      completed: completed,
      cancelled: cancelled,
      failed: failed,
    }
  }

  selectMenuGanttChartItem = (item, selectedStatus, form = null) => {
    let newStatus = this.findStatus(selectedStatus);
                      
    let startDayjs = this.getStartDate(item.item.data.jobTimeFrom, item.item.data.jobTimeSpecific, item.item.data.jobDate);
    let endDayjs = this.getEndDate(startDayjs, this.getMinutes(item.item.data.jobDurationHours, item.item.data.jobDurationMinutes));
    
    let items = this.state.items;
    let jobIndex = items.findIndex(x => x.data.jobId === item.item.data.jobId);
    let oldItem = items[jobIndex];
    let color = this.getColor(newStatus.jobStatusName);

    let data = {
      jobId: item.item.data.jobId,
      vehicleId: (selectedStatus === 'unassigned') ? null : item.item.data.vehicleId,
      driverId: (selectedStatus === 'unassigned') ? null : item.item.data.driverId,
      jobStatusId: newStatus.jobStatusId,
      jobAttemptCompletedDate: (item.item.data.jobAttemptCompletedDate) ? item.item.data.jobAttemptCompletedDate : null,
    };

    if(form){
      data['remarks'] = (form && form.driverNoteText && form.driverNoteText !== '') ? form.driverNoteText : '';
    }

    this.callDispatchApi(data, (state) => {
      if(!state){
        items[jobIndex] = oldItem;
        color = this.getColor(oldItem.jobStatusName);
      } else {
        items[jobIndex].data.jobStatusId = newStatus.jobStatusId;
        items[jobIndex].data.statusName = newStatus.jobStatusName;
        items[jobIndex].time.start = startDayjs.valueOf();
        items[jobIndex].time.end = endDayjs.valueOf();

        items[jobIndex] = this.addItem(
          jobIndex, 
          items[jobIndex].rowId, 
          ReactDOMServer.renderToStaticMarkup(this.customItemView(items[jobIndex].data, startDayjs.valueOf(), endDayjs.valueOf())), 
          startDayjs.valueOf(), 
          endDayjs.valueOf(),
          color,
          items[jobIndex].data
        );
      }

      this.setState({
        items: items
      }, () => {
        this.GSTCSet('config.chart.items', this.state.items);
        this.GSTCSet('config.chart.items.' + item.item.id + '.style.borderLeft', '5px solid ' + color);
      });

      if(selectedStatus === 'unassigned'){
        this.callReadUnassignedApi();
        this.loadGanttChartData();
      }
    });
  }

  getSelectedFilterDate = () => {
    return this.state.filterDate;
  }
  /* END FUNCTIONS */


  setHeader = () => {
    return <Box className={'grantt-chart-page-header'} p={2}>
      <Box>
        <Grid container>
          <Grid item xs={12}>
            <Accordion 
              className={'custom-accordian'} 
              square
              onChange={(event, expanded) => {
                this.setState({
                  expanded: expanded
                });
              }}
            >
              <AccordionSummary>
                <Box width={'100%'}>
                  <Grid container alignItems="center">
                    <Box clone>
                      <Grid item xs={12} lg={'auto'}>
                        <span
                          style={{
                            top: '0px',
                            position: 'relative',
                          }}
                        >{this.state.expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>
                        <span>Unassigned</span>
                        {
                          this.state.isLoadingUnassigned
                          ?
                          <CircularProgress 
                            style={{
                              marginLeft: '10px',
                              position: 'relative',
                              top: '5px'
                            }} 
                            size={15} 
                          />
                          :
                          <span className={'custom-unassigned-count shadow'}><b>{this.state.unassignedTotal}</b></span>
                        }
                      </Grid>
                    </Box>

                  
                    <Box clone pt={{ xs: 2, lg: 0 }} pb={{ xs: 2, lg: 0 }} pl={{ xs: 0, lg: 2 }} pr={{ xs: 0, lg: 2 }}>
                      <Grid item xs={12} lg={true}>
                        <DataTableSearch 
                          className={"custom-search-field"}
                          placeholder={"Search Jobs"}
                          onPressEnter={(val) => {
                            this.setState({
                              searchQuery: val
                            }, () => {
                              this.callReadUnassignedApi();
                              this.loadGanttChartData();
                            });
                          }}
                          onButtonClick={(val) => {
                            this.setState({
                              searchQuery: val
                            }, () => {
                              this.callReadUnassignedApi();
                              this.loadGanttChartData();
                            });
                          }}
                        />
                      </Grid>
                    </Box>
                    <Box clone pt={{ xs: 2, lg: 0 }} pb={{ xs: 2, lg: 0 }} pl={{ xs: 0, lg: 2 }} pr={{ xs: 0, lg: 2 }}>
                      <Grid item xs={12} sm={true} lg={true}>
                       {this.typeList()}
                      </Grid>
                    </Box>
                    <Box clone pr={1}>
                      <Grid item xs={'auto'}>
                        <Button className={"ganttchart-btn-blue"} variant={'contained'} color={'primary'}
                          onClick={(e) => {
                            e.stopPropagation();
                            // this.setState({
                            //   isCreateNewDialog: true,
                            //   newDialogItem: null,
                            //   newDialogItemDefDate: this.getSelectedFilterDate(),
                            //   newDialogParams: null,
                            // });
                            var win = window.open('/jobs-form', '_blank');
                            win.focus();
                          }}
                        >New Job</Button>
                      </Grid>
                    </Box>
                    <Box clone>
                      <Grid item xs={'auto'}>
                        <Button className={"ganttchart-btn-green"} variant={'contained'} color={'primary'}
                          onClick={(e) => {
                            e.stopPropagation();
                            let data = this.state.data;
                            
                            let assignedStatus = Utils.findStatus(this.state.status, 'jobStatusName', 'Assigned');
                            if(assignedStatus){
                              let jobIds = [];
                              if(data && data.length > 0){
                                for(let d = 0; d < data.length; d++){
                                  let driver = data[d];
  
                                  if(driver.jobs && driver.jobs.length > 0){
                                    for(let j = 0; j < driver.jobs.length; j++){
                                      let job = driver.jobs[j];
                                      
                                      if(job.jobStatusId === assignedStatus.jobStatusId){
                                        jobIds.push(job.jobId);
                                      }
                                    }
                                  }
                                }
                              }
                              
                              let dispatchedStatus = Utils.findStatus(this.state.status, 'jobStatusName', 'Dispatched');
                              if(dispatchedStatus && jobIds && jobIds.length > 0){
                                let row = {
                                  jobIds: jobIds.join(','),
                                  jobStatusId: dispatchedStatus.jobStatusId,
                                };
                                this.callDispatchAllApi(row);
                              } else {
                                toast.warning(<Box>There are no assigned jobs!</Box>, {
                                  autoClose: 4000,
                                  hideProgressBar: true,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                });
                              }
                            } else {
                              toast.warning(<Box>There are no assigned jobs!</Box>, {
                                autoClose: 4000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                              });
                            }
                          }}
                        >Dispatch All</Button>
                      </Grid>
                    </Box>
                  </Grid>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} className={'custom-wrapper-items'}>

                  {
                    (this.state.unassignedData && this.state.unassignedData.length > 0)
                    ?
                    this.state.unassignedData.map((item, i) => {
                      return <Grid key={i} item xs={12} md={6} lg={3} xl={2}>
                        {this.customUnassignedItemView(item)}
                      </Grid>
                    })
                    :
                    <Box pl={3}>No Unassigned Jobs</Box>
                  }

                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Box>
    </Box>
  }

  setGanttChartHead = () => {
    return <Box>
      <Box>
        <Grid container alignItems="center">
          <Box clone>
            <Grid item xs={12} md={2}></Grid>
          </Box>
          <Box clone textAlign={'center'}>
            <Grid item xs={12} md={true}>
              <Box>
                <Grid container alignItems="center" justifyContent="center">
                  <Box clone>
                    <Grid item xs={'auto'}>
                      <IconButton
                        className={"ganttchart-icon-btn"}
                        onClick={() => {
                          this.setDateFilters(this.prevDateFilters(), () => {
                            this.callReadUnassignedApi();
                            this.loadGanttChartData();
                          });
                        }}
                      >
                        <Icon component={'i'}>keyboard_arrow_left</Icon>
                      </IconButton>
                    </Grid>
                  </Box>
                  <Box clone>
                    <Grid item xs={'auto'}>{this.state.filterDateText}</Grid>
                  </Box>
                  <Box clone>
                    <Grid item xs={'auto'}>
                      <IconButton 
                        className={"ganttchart-icon-btn"}
                        onClick={() => {
                          this.setDateFilters(this.nextDateFilters(), () => {
                            this.callReadUnassignedApi();
                            this.loadGanttChartData();
                          });
                        }}
                      >
                        <Icon component={'i'}>keyboard_arrow_right</Icon>
                      </IconButton>
                    </Grid>
                  </Box>

                  <Box clone>
                    <Grid item xs={'auto'}>
                      <DropdownFilter 
                        text={''} 
                        isClickAway={true} 
                        rightIcon={<Box pl={2} pr={2} className={'v-center'} >
                          <Icon component={'i'}>event</Icon>
                          <Icon component={'i'}>arrow_drop_down</Icon>
                        </Box>} 
                        color={"default"} 
                        variant={"text"}
                      >
                        <NewSingleDatePicker
                          date={this.state.filterDate}
                          onChange={(date) => {
                            this.setState({
                              filterDate: date,
                              jobStatusFilter: '',
                            }, () => {
                              this.pageRef.current.click();
                              this.setDateFilters(date, () => {
                                this.callReadUnassignedApi();
                                this.loadGanttChartData();
                              });
                            });
                          }}
                        />
                      </DropdownFilter>
                    </Grid>
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Box>

          <Box clone pr={2}>
            <Grid item xs={12} lg={'auto'}>
              <CustomSlider
                className={'ganttchart-slider'}
                value={this.state.zoom}
                min={Zoom.Min}
                max={Zoom.Max}
                startIcon={<IconButton
                    onClick={() => {
                      this.setState({
                        zoom: ((this.state.zoom > Zoom.Min) ? (this.state.zoom - 1) : Zoom.Min)
                      }, () => {
                        this.GSTCSet('config.chart.time.zoom', this.state.zoom);
                      });
                    }}
                >
                  <Icon component={"i"}>zoom_in</Icon>
                </IconButton>}
                endIcon={<IconButton
                    onClick={() => {
                      this.setState({
                        zoom: ((this.state.zoom < Zoom.Max) ? (this.state.zoom + 1) : Zoom.Max)
                      }, () => {
                        this.GSTCSet('config.chart.time.zoom', this.state.zoom);
                      });
                    }}
                >
                  <Icon component={"i"}>zoom_out</Icon>
                </IconButton>}
                onChange={(value) => {
                  this.setState({
                    zoom: value
                  }, () => {
                    this.GSTCSet('config.chart.time.zoom', this.state.zoom);
                  });
                }}
              />
            </Grid>
          </Box>
        </Grid>
      </Box>
    </Box>
  }

  setGranttChart = () => {
    return <Box style={{ marginBottom: '90px' }}>
        <Grid container>
            <Box clone>
              {
                this.state.isLoading
                ?
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    height: '65vh',
                    textAlign: 'center',
                    justifyContent: 'center',
                    justifyItems: 'center',
                    alignItems: 'center',
                  }}
                >
                  <CircularProgress size={30} />
                </div>
                :
                <Grid item xs={12} id={this.granttChartID}></Grid>
              }
            </Box>
        </Grid>
    </Box>
  }

  setFooter = () => {
    return <Box pt={1} className={'custom-ganttchart-footer'}>
      <Box clone>
        <Grid container alignItems={'center'}>
        
          <Box clone>
            <Grid item xs={12} lg={8}>
              
              <Box clone>
                <Grid container alignItems={'center'}>
                  <Box clone>
                    <Grid item xs={'auto'}>
                      <CustomCheckBox 
                        index={0}
                        checked={this.state.showPlanedJobTime}
                        label={"Show planed job time"}
                        color={'default'}
                        onChange={(index, val) => {
                            this.setState({
                              showPlanedJobTime: val
                            }, () => {
                                this.loadGanttChartData();
                            });
                        }}
                      />
                    </Grid>
                  </Box>
                  <Box clone>
                    <Grid item xs={'auto'}>
                      <CustomCheckBox 
                        index={0}
                        checked={this.state.showActualJobTime}
                        label={"Show actual job time"}
                        color={'default'}
                        onChange={(index, val) => {
                          this.setState({
                            showActualJobTime: val
                          }, () => {
                            this.loadGanttChartData();
                          });
                        }}
                      />
                    </Grid>
                  </Box>
                </Grid>
              </Box>

            </Grid>
          </Box>
          <Box clone textAlign={'right'}>
            <Grid item xs={12} lg={4}>
              <Box pr={5}>
                <CustomDropdown
                  text={'Legend'} 
                  rightIcon={<Box pl={1} pr={1} className={'v-center'} >
                    <Icon component={'i'}>view_stream</Icon>
                  </Box>} 
                  color={"default"} 
                  variant={"text"}
                >
                  <LegendMenu
                    items={[
                      {
                        id: 1,
                        text: 'Assigned',
                        className: 'status-white-background status-unassigned-border',
                        action: null,
                      },
                      {
                        id: 2,
                        text: 'Dispatched',
                        className: 'status-dispatched-background status-dispatched-border',
                        action: null,
                      },
                      {
                        id: 3,
                        text: 'Started',
                        className: 'status-started-background status-started-border',
                        action: null,
                      },
                      {
                        id: 4,
                        text: 'In Progress',
                        className: 'status-inprogress-background status-inprogress-border',
                        action: null,
                      },
                      {
                        id: 5,
                        text: 'Completed',
                        className: 'status-completed-background status-completed-border',
                        button: null,
                        action: <Box>
                          <CustomSwitch
                            className={'align-center'}
                            checked={this.state.legendCompleted}
                            onColor={"#7F7F7F"}
                            width={60}
                            height={30}
                            checkedIcon={<></>}
                            uncheckedIcon={<></>}

                            isExtraText={true}
                            checkedExtraText={'Hide'}
                            uncheckedExtraText={'Show'}
                            onChange={(checked) => {
                              this.setState({
                                legendCompleted: checked
                              }, () => {
                                this.loadGanttChartData();
                              });
                            }}
                          />
                        </Box>,
                      },
                      {
                        id: 6,
                        text: 'Cancelled / Failed',
                        className: 'status-unsuccessful-background status-unsuccessful-border',
                        button: null,
                        action: <Box>
                          <CustomSwitch
                            className={'align-center'}
                            checked={this.state.legendCanceledFailed}
                            onColor={"#7F7F7F"}
                            width={60}
                            height={30}
                            checkedIcon={<></>}
                            uncheckedIcon={<></>}

                            isExtraText={true}
                            checkedExtraText={'Hide'}
                            uncheckedExtraText={'Show'}
                            onChange={(checked) => {
                              this.setState({
                                legendCanceledFailed: checked
                              }, () => {
                                this.loadGanttChartData();
                              });
                            }}
                          />
                        </Box>,
                      }
                    ]}
                    onClick={(item) => {
                      let status = null;
                      if(item.id === 1){
                        status = Utils.findStatus(this.state.status, 'jobStatusName', 'Assigned');
                      } else if(item.id === 2){
                        status = Utils.findStatus(this.state.status, 'jobStatusName', 'Dispatched');
                      } else if(item.id === 3){
                        status = Utils.findStatus(this.state.status, 'jobStatusName', 'Started');
                      } else if(item.id === 4){
                        status = Utils.findStatus(this.state.status, 'jobStatusName', 'In Progress');
                      }

                      if(status){
                        this.setState({
                          jobStatusFilter: status.jobStatusId
                        }, () => {
                          this.loadGanttChartData(this.state.jobStatusFilter);
                        });
                      }
                    }}
                  />
                </CustomDropdown>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </Box>
  }


  setCreateDialog = () => {
    return <JobsDialog
      className={"default"}
      open={this.state.isCreateNewDialog}
      isCloseBtn={false}
      fullWidth={true}
      onClose={() => {
        this.setState({
          isCreateNewDialog: false,
          newDialogItem: null,
          newDialogParams: null,
          newDialogItemDefDate: null,
        });
      }}
    >
      {/* <JobsForm
        ref={this.createJobFormRef}
        auth={this.props.auth}
        isDialog={true}
        item={this.state.newDialogItem}
        id={(this.state.newDialogItem && this.state.newDialogItem.jobId) ? this.state.newDialogItem.jobId : 0}
        params={this.state.newDialogParams}
        isLoading={this.state.newDialogItemLoading}
        defDate={this.state.newDialogItemDefDate}
        onClose={() => {
          this.setState({
            isCreateNewDialog: false,
            newDialogItem: null,
            newDialogParams: null,
            newDialogItemDefDate: null,
          });
        }}
        onOk={(isEdit, form) => {
          if(isEdit){
            this.callUpdateJobApi(form, (item) => {
              this.callReadUnassignedApi();
              this.loadGanttChartData();
            });
          } else {
            this.callCreateJobApi(form, (item) => {
              this.callReadUnassignedApi();
              this.loadGanttChartData();
            });
          }
        }}
      /> */}
    </JobsDialog>
  }

  setCancelDialog = () => {
    return  <CustomDialog
      open={this.state.isCancelDialog}
      maxWidth={"lg"}
      onClose={() => {
        this.setState({
          isCancelDialog: false,
          cancelRow: null,
          cancelItem: '',
        });
      }}
    >
      <CancelDialog
          isLoading={this.state.cancelDialogItemLoading}
          onClose={() => {
            this.setState({
              isCancelDialog: false,
              cancelRow: null,
              cancelItem: '',
            });
          }}
          onOk={(isEdit, form) => {
            let item = this.state.cancelItem;
            
            this.setState({
              isCancelDialog: false,
              cancelRow: null,
              cancelItem: '',
            }, () => {
              this.selectMenuGanttChartItem(item, 'cancelled', form);
            });
          }}
      />
    </CustomDialog>
  }

  setMapDialog = () => {
    return  <CustomDialog
      open={this.state.isMapDialog}
      maxWidth={"lg"}
      fullWidth={true}
      onClose={() => {
        this.setState({
          isMapDialog: false,
          mapDialogItem: null,
        });
      }}
    >
      <DriverRouteDialog
          item={this.state.mapDialogItem}
          onClose={() => {
            this.setState({
              isMapDialog: false,
              mapDialogItem: null,
            });
          }}
      />
    </CustomDialog>
  }

  setSignalR = () => {
    return <SignalR 
      ref={this.refSignalR}
      url={REACT_APP_REAL_TIME_URL}
      group={this.state.groupAccountEmail}
      onConnect={() => {
        
      }}
      onFailed={(err) => {
        
      }}
      onReceiveMessage={(type, msg) => {
        if(type === ReceiveMessage.UpdateJob){
          if(this.state.userEmail !== msg.updatedBy){
            this.realTimeUpdate(msg);
          }
        } else if(type === ReceiveMessage.UpdateBatchJob){
          if(msg && msg.successJobs && msg.successJobs.length > 0){
            for(let i = 0; i < msg.successJobs.length; i++){
              let jobInfo = msg.successJobs[i];
              this.realTimeUpdate(jobInfo.job);
            }
          }
        } else if(type === ReceiveMessage.UpdateMessage){
          if(this.state.isCreateNewDialog && this.createJobFormRef && this.createJobFormRef.current && this.createJobFormRef.current.refJobDetailsTab){
            if(this.state.userEmail !== msg.createdBy){
              this.createJobFormRef.current.refJobDetailsTab.addRealTimeMsg(msg);
            }
          }
        }
      }}
    />
  }

  setGanttChartItemMenu = () => {
      return <ControlledMenu 
        className={'custom-border'}
        isOpen={this.state.mouseGanttChartY !== null}
        onClose={this.hideContextMenuGanttChart}
        anchorPoint={
          this.state.mouseGanttChartY !== null && this.state.mouseGanttChartX !== null
            ? { y: this.state.mouseGanttChartY, x: this.state.mouseGanttChartX }
            : undefined
        }
      >
        {/* <CustomMenuItem 
          onClick={(e) => {
            let item = this.state.contextMenuGanttChartItem;
            let job = (item && item.item && item.item.data) ? item.item.data : null;
            
            this.setState({
              isCreateNewDialog: true,
              newDialogItem: job,
              newDialogItemDefDate: null,
              newDialogParams: null,
            });
          }}
        >
          Edit Job
        </CustomMenuItem> */}
        <SubMenu label="Change status">
          <CustomMenuItem
            disabled={this.isDisabledItemGanttChart(this.getStatusGanttChart()).unassigned}
            onClick={(e) => {
              this.selectMenuGanttChartItem(this.state.contextMenuGanttChartItem, 'unassigned');
            }}
          >Unassigned</CustomMenuItem>
          <CustomMenuItem
            disabled={this.isDisabledItemGanttChart(this.getStatusGanttChart()).dispatched}
            onClick={(e) => {
              this.selectMenuGanttChartItem(this.state.contextMenuGanttChartItem, 'dispatched');
            }}
          >Dispatched</CustomMenuItem>
          <CustomMenuItem
            disabled={this.isDisabledItemGanttChart(this.getStatusGanttChart()).started}
            onClick={(e) => {
              this.selectMenuGanttChartItem(this.state.contextMenuGanttChartItem, 'started');
            }}
          >Started</CustomMenuItem>
          <CustomMenuItem
            disabled={this.isDisabledItemGanttChart(this.getStatusGanttChart()).completed}
            onClick={(e) => {
              this.selectMenuGanttChartItem(this.state.contextMenuGanttChartItem, 'completed');
            }}
          >Completed</CustomMenuItem>
          <CustomMenuItem
            disabled={this.isDisabledItemGanttChart(this.getStatusGanttChart()).cancelled}
            onClick={(e) => {
              let item = this.state.contextMenuGanttChartItem;
              let job = (item && item.item && item.item.data) ? item.item.data : null;
            
              if(job.jobId){
                this.setState({
                  isCancelDialog: true,
                  cancelRow: job,
                  cancelItem: this.state.contextMenuGanttChartItem,
                });
              }
            }}
          >Cancel</CustomMenuItem>
          <CustomMenuItem
            disabled={this.isDisabledItemGanttChart(this.getStatusGanttChart()).failed}
            onClick={(e) => {
              this.selectMenuGanttChartItem(this.state.contextMenuGanttChartItem, 'failed');
            }}
          >Failed</CustomMenuItem>
        </SubMenu>
      </ControlledMenu>
  }

  render() {
    return <Box className="grantt-chart-page" ref={this.pageRef}>
      <Grid container className={'gantt-chart-header'}>
        <Grid item xs={12}>
          {this.setHeader()}
        </Grid>
      </Grid>
      <Box p={1}>
        <Grid container>
          <Grid item xs={12}>
            {this.setGanttChartHead()}
            {this.setGranttChart()}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            {this.setFooter()}
          </Grid>
        </Grid>
      </Box>
      {this.setCreateDialog()}
      {this.setCancelDialog()}
      {this.setMapDialog()}
      {this.setSignalR()}
      {this.setGanttChartItemMenu()}
    </Box>;
  }
}


const mapDispatchToProps = {
  dispatchApiCallGet,
  dispatchApiCallPost,
  dispatchApiCallPut,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(GranttChartView);
