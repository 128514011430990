import React from 'react';
import ReactDOM from 'react-dom'

import {
  Box,
  // IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

import WrappedButton from '../../Buttons/WrappedButton';


const CustomMenu = withStyles(() => ({
  paper: {
    overflow: 'visible',
  }
}))(Menu);


const DropdownIconButtonMenu = React.forwardRef((props, ref) => {
    const { icon, items, selected, onClick, disabled, className, overflowVisible, maxHeight = '34px' } = props;

    let MenuComponent = Menu;
    if(overflowVisible){
      MenuComponent = CustomMenu;
    }

    
    React.useImperativeHandle(ref, () => ({
      hide() {
        setAnchorEl(null);
      }
    }));

    const containerRef = React.useRef();
    const menuRef = React.useRef();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setTimeout(() => { 
          if(menuRef && menuRef.current){
            let cls = ReactDOM.findDOMNode(menuRef.current).getElementsByClassName('MuiMenu-paper');
            if(cls && cls.length > 0){
              cls[0].removeAttribute("tabIndex");
            }
          }
        }, 100);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    const setMenuItems = () => {
      if(items && items.length > 0){
        return items.map((item, i) => {
          return <MenuItem 
            key={i}
            selected={item.value === selected}
            disabled={(item && item.disabled) ? item.disabled : false}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              if(onClick){
                onClick(item);
                handleClose();
              }
            }}
          >
            {item.text}
          </MenuItem>
        });
      }
    };


    return (
        <Box ref={containerRef} className={'custom-icon-button-dropdown-componenet ' + className}>
            {/* <IconButton
              className={'custom-button ' + className}
              aria-describedby={id} 
              aria-haspopup="true"
              color={'primary'}
              variant={'contained'}
              onClick={handleClick}
              disabled={disabled}
            >
              {icon}
            </IconButton> */}
            <WrappedButton 
                className={'custom-button px-3 ' + className}
                active={open}
                variant={"light"}
                size={'sm'}
                style={{ backgroundColor: 'transparent', maxHeight: maxHeight }}
                onClick={handleClick}
            >
              {icon}
            </WrappedButton>
            <MenuComponent
                ref={menuRef}
                className={'custom-menu ' + className}
                id={id}
                open={open}
                variant={'menu'}
                getContentAnchorEl={null}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                container={containerRef.current}
            >
              {
                props.children
                ?
                props.children
                :
                setMenuItems()
              }
            </MenuComponent>
        </Box>
    );
  });

  export default DropdownIconButtonMenu;