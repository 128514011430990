import React, { Component } from 'react';

import apiUtil from '../../../../api/apiUtil.jsx';
import { JobStepType, CustomPhotoSignature } from '../../../../utils/enums';

import { 
    Box,
    Grid,
    Divider,
} from '@material-ui/core';

import Card from '../../../../components/OLD/Card/Card.jsx';
import ManageLabel from '../../../../components/OLD/input/ManageLabel.jsx';
import ManageInput from '../../../../components/OLD/input/ManageInput.jsx';
import CustomToggle from '../../../../components/OLD/Checkbox/CustomToggle.jsx';
import CustomOptionsToggle from '../../../../components/OLD/Checkbox/CustomOptionsToggle.jsx';


class JobStepsSection extends Component {
    
    constructor(props){
        super(props);

        let id = (props.id && props.id > 0) ? props.id : 0;
        let isCopy = ((props.isCopy === true || props.isCopy === false) ? props.isCopy : false);
        let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
        let disabled = ((props.disabled === true || props.disabled === false) ? props.disabled : false);
        let jobType = (props.jobType && props.jobType) ? props.jobType : 0;
        let isEditableWasteTypeIn = ((props.isEditableWasteTypeIn === true || props.isEditableWasteTypeIn === false) ? props.isEditableWasteTypeIn : false);

        
        let isRequireBinNumberToEnd1 = false;
        
        let isRequireBinNumberToEnd2 = false;
        let mustTakePhoto1 = 0;
        let numberofPhotosRequired1 = '0';
        let mustTakeSignature = 0;
        // let isRequirePaymentCollection = false;

        let isRequireBinWeight1 = false;
        let isRequireBinWeight2 = false;
        let isRequireReviewWasteType = false;
        let mustTakePhoto2 = 0;
        let numberofPhotosRequired2 = '0';
      

        
        this.state = {
            id: id,
            isCopy: isCopy,
            isLoading: isLoading,
            disabled: disabled,
            jobType: jobType,

            isRequireBinNumberToEnd1: isRequireBinNumberToEnd1,

            isRequireBinNumberToEnd2: isRequireBinNumberToEnd2,
            mustTakePhoto1: mustTakePhoto1,
            numberofPhotosRequired1: numberofPhotosRequired1,
            mustTakeSignature: mustTakeSignature,
            // isRequirePaymentCollection: isRequirePaymentCollection,

            isRequireBinWeight1: isRequireBinWeight1,
            isRequireBinWeight2: isRequireBinWeight2,
            isRequireReviewWasteType: isRequireReviewWasteType,
            mustTakePhoto2: mustTakePhoto2,
            numberofPhotosRequired2: numberofPhotosRequired2,

            isEditableWasteTypeIn: isEditableWasteTypeIn,
          

        }
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.isCopy !== this.state.isCopy) {
            let isCopy = ((nextProps.isCopy === true || nextProps.isCopy === false) ? nextProps.isCopy : false);
            this.setState({
                isCopy: isCopy
            });
        }
        if (nextProps.isLoading !== this.state.isLoading) {
            let isLoading = ((nextProps.isLoading === true || nextProps.isLoading === false) ? nextProps.isLoading : false);
            this.setState({
                isLoading: isLoading
            });
        }
        if (nextProps.disabled !== this.state.disabled) {
            let disabled = ((nextProps.disabled === true || nextProps.disabled === false) ? nextProps.disabled : false);
            this.setState({
                disabled: disabled
            });
        }
        if (nextProps.jobType !== this.state.jobType) {
            let jobType = (nextProps.jobType && nextProps.jobType) ? nextProps.jobType : 0;
            this.setState({
                jobType: jobType
            });
        }
        if (nextProps.isEditableWasteTypeIn !== this.state.isEditableWasteTypeIn) {
            let isEditableWasteTypeIn = ((nextProps.isEditableWasteTypeIn === true || nextProps.isEditableWasteTypeIn === false) ? nextProps.isEditableWasteTypeIn : false);
            this.setState({
                isEditableWasteTypeIn: isEditableWasteTypeIn,
                isRequireReviewWasteType: (!isEditableWasteTypeIn) ? false : this.state.isRequireReviewWasteType,
            });
        }
    }


    /* GETTER & SETTER */
    setIsRequireBinNumberToEnd1 = (isRequireBinNumberToEnd1 = false) => {
        this.setState({
            isRequireBinNumberToEnd1: isRequireBinNumberToEnd1,
        });
    }
    getIsRequireBinNumberToEnd1 = () => {
        return this.state.isRequireBinNumberToEnd1
    }

    setIsRequireBinNumberToEnd2 = (isRequireBinNumberToEnd2 = false) => {
        this.setState({
            isRequireBinNumberToEnd2: isRequireBinNumberToEnd2,
        });
    }
    getIsRequireBinNumberToEnd2 = () => {
        return this.state.isRequireBinNumberToEnd2
    }

    setMustTakePhoto1 = (mustTakePhoto1 = 0) => {
        this.setState({
            mustTakePhoto1: mustTakePhoto1,
        });
    }
    getMustTakePhoto1 = () => {
        return this.state.mustTakePhoto1
    }

    setNumberofPhotosRequired1 = (numberofPhotosRequired1 = '0') => {
        this.setState({
            numberofPhotosRequired1: numberofPhotosRequired1,
        });
    }
    getNumberofPhotosRequired1 = () => {
        return this.state.numberofPhotosRequired1
    }

    setMustTakeSignature = (mustTakeSignature = 0) => {
        this.setState({
            mustTakeSignature: mustTakeSignature,
        });
    }
    getMustTakeSignature = () => {
        return this.state.mustTakeSignature
    }

    // setIsRequirePaymentCollection = (isRequirePaymentCollection = false) => {
    //     this.setState({
    //         isRequirePaymentCollection: isRequirePaymentCollection,
    //     });
    // }
    // getIsRequirePaymentCollection = () => {
    //     return this.state.isRequirePaymentCollection
    // }

    setIsRequireBinWeight1 = (isRequireBinWeight1 = false) => {
        let data = {
            isRequireBinWeight1: isRequireBinWeight1,
        };

        this.setState(data);
    }
    getIsRequireBinWeight1 = () => {
        return this.state.isRequireBinWeight1
    }

    setIsRequireBinWeight2 = (isRequireBinWeight2 = false) => {
        let data = {
            isRequireBinWeight2: isRequireBinWeight2,
        };

        if(!isRequireBinWeight2){
            data['isRequireReviewWasteType'] = false;

            if(this.state.jobType !== JobStepType.Shift){
                data['mustTakePhoto2'] = 0;
                data['numberofPhotosRequired2'] = '0';
            }
        }

        this.setState(data);
    }
    getIsRequireBinWeight2 = () => {
        return this.state.isRequireBinWeight2
    }

    setIsRequireReviewWasteType = (isRequireReviewWasteType = false) => {
        this.setState({
            isRequireReviewWasteType: isRequireReviewWasteType,
        });
    }
    getIsRequireReviewWasteType = () => {
        return this.state.isRequireReviewWasteType
    }
    
    setMustTakePhoto2 = (mustTakePhoto2 = 0) => {
        this.setState({
            mustTakePhoto2: mustTakePhoto2,
        });
    }
    getMustTakePhoto2 = () => {
        return this.state.mustTakePhoto2
    }

    setNumberofPhotosRequired2 = (numberofPhotosRequired2 = '0') => {
        this.setState({
            numberofPhotosRequired2: numberofPhotosRequired2,
        });
    }
    getNumberofPhotosRequired2 = () => {
        return this.state.numberofPhotosRequired2
    }

    setIsEditableWasteTypeIn = (isEditableWasteTypeIn = false) => {
        this.setState({
            isEditableWasteTypeIn: isEditableWasteTypeIn,
        });
    }
    // new get set




    getNumberOfPhotos = (mustTakePhoto = 0, numberofPhotosRequired = '0') => {
        if(mustTakePhoto > 0){
            let numb = apiUtil.customParseInt((numberofPhotosRequired && numberofPhotosRequired !== '') ? numberofPhotosRequired : 0);
            numberofPhotosRequired = ((mustTakePhoto === 1) || (mustTakePhoto === 2)) ? ((numb < 1 || numb > 2) ? 1 : numb) : ((numb < 1 || numb > 2) ? 0 : numb);
        }

        return (numberofPhotosRequired && numberofPhotosRequired !== '') ? numberofPhotosRequired : '0';
    }

    isDisabled = () => {
        if((this.state.jobType === JobStepType.Put) || (this.state.jobType === JobStepType.Task) || (this.state.jobType === JobStepType.Out) || (this.state.jobType === JobStepType.Shift)) {
            return false
        } else {
            return !this.state.isRequireBinWeight2
        }
    }
    /* END GETTER & SETTER */


    setAppPermissionForm = () => {
        return <Card>
            <Box pl={{ xs: 0, md: 5 }}>

                <Grid container>
                    <Box clone pt={3}>
                        <Grid item xs={12}>
                            
                            <Box clone>
                                <Grid container>
                                    <Box clone pb={2}>
                                        <Grid item xs={12}>
                                            <ManageLabel
                                                className={'custom-options-subtitle'}
                                                label={"1. START JOB"}
                                            />
                                            <Divider />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12}>
                                            
                                            {((this.state.jobType !== JobStepType.Pull) && (this.state.jobType !== JobStepType.Shift)) && <Box clone pb={2}>
                                                <Grid container alignItems={'center'}>
                                                    <Box clone pb={{ xs: 1, md: 0 }}>
                                                        <Grid item xs={12} md={true}>
                                                            The driver is required to input bin ID
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={true} md={'auto'}>
                                                            <CustomToggle
                                                                checked={this.state.isRequireBinNumberToEnd1}
                                                                disabled={false}
                                                                onChange={(checked) => {
                                                                    this.setState({
                                                                        isRequireBinNumberToEnd1: checked
                                                                    });
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box clone textAlign={'center'}>
                                                        <Grid item xs={'auto'} md={1}>&nbsp;</Grid>
                                                    </Box>
                                                </Grid>
                                            </Box>}

                                            {(this.state.jobType === JobStepType.Out) && <Box clone pb={2}>
                                                <Grid container alignItems={'center'}>
                                                    <Box clone pb={{ xs: 1, md: 0 }}>
                                                        <Grid item xs={12} md={true}>
                                                            The driver is required to input bin weight
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={true} md={'auto'}>
                                                            <CustomToggle
                                                                checked={this.state.isRequireBinWeight1}
                                                                disabled={false}
                                                                onChange={(checked) => {
                                                                    let data = {
                                                                        isRequireBinWeight1: checked
                                                                    };
    
                                                                    this.setState(data);
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box clone textAlign={'center'}>
                                                        <Grid item xs={'auto'} md={1}>&nbsp;</Grid>
                                                    </Box>
                                                </Grid>
                                            </Box>}

                                        </Grid>
                                    </Box>
                                </Grid>
                            </Box>

                            {((this.state.jobType !== JobStepType.Put) && (this.state.jobType !== JobStepType.Out)) && <Box clone>
                                <Grid container>
                                    <Box clone pt={5} pb={2}>
                                        <Grid item xs={12}>
                                            <ManageLabel
                                                className={'custom-options-subtitle'}
                                                label={"2. IN PROGRESS"}
                                            />
                                            <Divider />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12}>
                                            
                                            {((this.state.jobType === JobStepType.Pull) || (this.state.jobType === JobStepType.Exchange) || (this.state.jobType === JobStepType.Shift)) && <Box clone pb={2}>
                                                <Grid container alignItems={'center'}>
                                                    <Box clone pb={{ xs: 1, md: 0 }}>
                                                        <Grid item xs={12} md={true}>
                                                            The driver is required to input bin ID
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={true} md={'auto'}>
                                                            <CustomToggle
                                                                checked={this.state.isRequireBinNumberToEnd2}
                                                                disabled={false}
                                                                onChange={(checked) => {
                                                                    this.setState({
                                                                        isRequireBinNumberToEnd2: checked
                                                                    });
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box clone textAlign={'center'}>
                                                        <Grid item xs={'auto'} md={1}>&nbsp;</Grid>
                                                    </Box>
                                                </Grid>
                                            </Box>}

                                            <Box clone pb={2}>
                                                <Grid container alignItems={'center'}>
                                                    <Box clone pb={{ xs: 1, md: 0 }}>
                                                        <Grid item xs={12} md={true}>

                                                            <Box clone>
                                                                <Grid container alignItems={'center'}>
                                                                    <Box clone>
                                                                        <Grid item xs={'auto'}>
                                                                            The driver is required to take
                                                                        </Grid>
                                                                    </Box>
                                                                    <Box clone pl={2} pr={2}>
                                                                        <Grid item xs={'auto'}>
                                                                            <ManageInput 
                                                                                className={"custom-max-width-50"}
                                                                                value={this.getNumberOfPhotos(this.state.mustTakePhoto1, this.state.numberofPhotosRequired1)}
                                                                                placeholder={""}
                                                                                type={'number'}
                                                                                readOnly={(this.state.mustTakePhoto1 === 0) ? true : false}
                                                                                inputProps={{
                                                                                    min: ((this.state.mustTakePhoto1 === 1) || (this.state.mustTakePhoto1 === 2)) ? 1 : 0,
                                                                                    max: 2,
                                                                                    pattern: ((this.state.mustTakePhoto1 === 1) || (this.state.mustTakePhoto1 === 2)) ? apiUtil.maxInputNumber12() : apiUtil.maxInputNumber012(),
                                                                                }}
                                                                                disabled={false}
                                                                                onChange={(e) => {
                                                                                    this.setState({
                                                                                        numberofPhotosRequired1: e.target.value
                                                                                    });
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    </Box>
                                                                    <Box clone>
                                                                        <Grid item md={'auto'}>
                                                                            photo
                                                                        </Grid>
                                                                    </Box>
                                                                </Grid>
                                                            </Box>
                                                            
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={true} md={'auto'}>
                                                            <CustomOptionsToggle
                                                                options={[
                                                                    {
                                                                        value: CustomPhotoSignature.No,
                                                                        label: 'NO',
                                                                    },
                                                                    {
                                                                        value: CustomPhotoSignature.Yes,
                                                                        label: 'YES',
                                                                    },
                                                                    {
                                                                        value: CustomPhotoSignature.Optional,
                                                                        label: 'OPTIONAL',
                                                                    }
                                                                ]}
                                                                value={this.state.mustTakePhoto1}
                                                                disabled={false}
                                                                onChange={(value) => {
                                                                    this.setState({
                                                                        mustTakePhoto1: value,
                                                                        numberofPhotosRequired1: (value === 1) ? 1 : 0,
                                                                    });
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box clone textAlign={'center'}>
                                                        <Grid item xs={'auto'} md={1}>&nbsp;</Grid>
                                                    </Box>
                                                </Grid>
                                            </Box>

                                            <Box clone pb={2}>
                                                <Grid container alignItems={'center'}>
                                                    <Box clone pb={{ xs: 1, md: 0 }}>
                                                        <Grid item xs={12} md={true}>
                                                            The driver is required to take signature
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={true} md={'auto'}>
                                                            <CustomOptionsToggle
                                                                options={[
                                                                    {
                                                                        value: CustomPhotoSignature.No,
                                                                        label: 'NO',
                                                                    },
                                                                    {
                                                                        value: CustomPhotoSignature.Yes,
                                                                        label: 'YES',
                                                                    },
                                                                    {
                                                                        value: CustomPhotoSignature.Optional,
                                                                        label: 'OPTIONAL',
                                                                    }
                                                                ]}
                                                                value={this.state.mustTakeSignature}
                                                                disabled={false}
                                                                onChange={(value) => {
                                                                    this.setState({
                                                                        mustTakeSignature: value,
                                                                    });
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box clone textAlign={'center'}>
                                                        <Grid item xs={'auto'} md={1}>&nbsp;</Grid>
                                                    </Box>
                                                </Grid>
                                            </Box>

                                            {/* <Box clone pb={2}>
                                                <Grid container alignItems={'center'}>
                                                    <Box clone pb={{ xs: 1, md: 0 }}>
                                                        <Grid item xs={12} md={true}>
                                                            The driver is required to collect payment
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={true} md={'auto'}>
                                                            <CustomToggle
                                                                checked={this.state.isRequirePaymentCollection}
                                                                disabled={false}
                                                                onChange={(checked) => {
                                                                    this.setState({
                                                                        isRequirePaymentCollection: checked
                                                                    });
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box clone textAlign={'center'}>
                                                        <Grid item xs={'auto'} md={1}>&nbsp;</Grid>
                                                    </Box>
                                                </Grid>
                                            </Box> */}

                                        </Grid>
                                    </Box>
                                </Grid>
                            </Box>}

                            <Box clone>
                                <Grid container>
                                    <Box clone pt={5} pb={2}>
                                        <Grid item xs={12}>
                                            <ManageLabel
                                                className={'custom-options-subtitle'}
                                                label={"3. COMPLETE JOB"}
                                            />
                                            <Divider />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12}>
                                            
                                            {((this.state.jobType !== JobStepType.Put) && (this.state.jobType !== JobStepType.Out) && (this.state.jobType !== JobStepType.Shift)) && <Box clone pb={2}>
                                                <Grid container alignItems={'center'}>
                                                    <Box clone pb={{ xs: 1, md: 0 }}>
                                                        <Grid item xs={12} md={true}>
                                                            The driver is required to input bin weight
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={true} md={'auto'}>
                                                            <CustomToggle
                                                                checked={this.state.isRequireBinWeight2}
                                                                disabled={false}
                                                                onChange={(checked) => {
                                                                    let data = {
                                                                        isRequireBinWeight2: checked
                                                                    };
    
                                                                    if(!checked){
                                                                        data['isRequireReviewWasteType'] = false;
                                                                        data['mustTakePhoto2'] = 0;
                                                                        data['numberofPhotosRequired2'] = '0';
                                                                    }
    
                                                                    this.setState(data);
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box clone textAlign={'center'}>
                                                        <Grid item xs={'auto'} md={1}>&nbsp;</Grid>
                                                    </Box>
                                                </Grid>
                                            </Box>}

                                            {((this.state.jobType !== JobStepType.Put) && (this.state.jobType !== JobStepType.Out) && (this.state.jobType !== JobStepType.Shift)) && <Box clone pb={2}>
                                                <Grid container alignItems={'center'}>
                                                    <Box clone pb={{ xs: 1, md: 0 }}>
                                                        <Grid item xs={12} md={true}>
                                                            The driver is required to review waste type
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={true} md={'auto'}>
                                                            <CustomToggle
                                                                checked={this.state.isRequireReviewWasteType}
                                                                disabled={!this.state.isEditableWasteTypeIn}
                                                                onChange={(checked) => {
                                                                    this.setState({
                                                                        isRequireReviewWasteType: checked,
                                                                    });
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box clone textAlign={'center'}>
                                                        <Grid item xs={'auto'} md={1}>&nbsp;</Grid>
                                                    </Box>
                                                </Grid>
                                            </Box>}

                                            <Box clone pb={1}>
                                                <Grid container alignItems={'center'}>
                                                    <Box clone pb={{ xs: 1, md: 0 }}>
                                                        <Grid item xs={12} md={true}>
                                                            
                                                            <Box clone>
                                                                <Grid container alignItems={'center'}>
                                                                    <Box clone>
                                                                        <Grid item xs={'auto'}>
                                                                            The driver is required to take
                                                                        </Grid>
                                                                    </Box>
                                                                    <Box clone pl={2} pr={2}>
                                                                        <Grid item xs={'auto'}>
                                                                            <ManageInput 
                                                                                className={"custom-max-width-50"}
                                                                                value={this.getNumberOfPhotos(this.state.mustTakePhoto2, this.state.numberofPhotosRequired2)}
                                                                                placeholder={""}
                                                                                readOnly={(this.state.mustTakePhoto2 === 0) ? true : false}
                                                                                type={'number'}
                                                                                inputProps={{
                                                                                    min: ((this.state.mustTakePhoto2 === 1) || (this.state.mustTakePhoto2 === 2)) ? 1 : 0,
                                                                                    max: 2,
                                                                                    pattern: ((this.state.mustTakePhoto2 === 1) || (this.state.mustTakePhoto2 === 2)) ? apiUtil.maxInputNumber12() : apiUtil.maxInputNumber012(),
                                                                                }}
                                                                                disabled={this.isDisabled()}
                                                                                onChange={(e) => {
                                                                                    this.setState({
                                                                                        numberofPhotosRequired2: e.target.value
                                                                                    });
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    </Box>
                                                                    <Box clone>
                                                                        <Grid item md={'auto'}>
                                                                            photo
                                                                        </Grid>
                                                                    </Box>
                                                                </Grid>
                                                            </Box>

                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={true} md={'auto'}>
                                                            <CustomOptionsToggle
                                                                options={[
                                                                    {
                                                                        value: CustomPhotoSignature.No,
                                                                        label: 'NO',
                                                                    },
                                                                    {
                                                                        value: CustomPhotoSignature.Yes,
                                                                        label: 'YES',
                                                                    },
                                                                    {
                                                                        value: CustomPhotoSignature.Optional,
                                                                        label: 'OPTIONAL',
                                                                    }
                                                                ]}
                                                                value={this.state.mustTakePhoto2}
                                                                disabled={this.isDisabled()}
                                                                onChange={(value) => {
                                                                    this.setState({
                                                                        mustTakePhoto2: value,
                                                                        numberofPhotosRequired2: (value === 1) ? 1 : 0,
                                                                    });
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box clone textAlign={'center'}>
                                                        <Grid item xs={'auto'} md={1}>&nbsp;</Grid>
                                                    </Box>
                                                </Grid>
                                            </Box>

                                            {(this.state.jobType === JobStepType.Put) && <Box clone pb={2}>
                                                <Grid container alignItems={'center'}>
                                                    <Box clone pb={{ xs: 1, md: 0 }}>
                                                        <Grid item xs={12} md={true}>
                                                            The driver is required to take signature
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={true} md={'auto'}>
                                                            <CustomOptionsToggle
                                                                options={[
                                                                    {
                                                                        value: CustomPhotoSignature.No,
                                                                        label: 'NO',
                                                                    },
                                                                    {
                                                                        value: CustomPhotoSignature.Yes,
                                                                        label: 'YES',
                                                                    },
                                                                    {
                                                                        value: CustomPhotoSignature.Optional,
                                                                        label: 'OPTIONAL',
                                                                    }
                                                                ]}
                                                                value={this.state.mustTakeSignature}
                                                                disabled={false}
                                                                onChange={(value) => {
                                                                    this.setState({
                                                                        mustTakeSignature: value,
                                                                    });
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box clone textAlign={'center'}>
                                                        <Grid item xs={'auto'} md={1}>&nbsp;</Grid>
                                                    </Box>
                                                </Grid>
                                            </Box>}

                                            {/* {((this.state.jobType === JobStepType.Put) || (this.state.jobType === JobStepType.Out)) && <Box clone pb={2}>
                                                <Grid container alignItems={'center'}>
                                                    <Box clone pb={{ xs: 1, md: 0 }}>
                                                        <Grid item xs={12} md={true}>
                                                            The driver is required to collect payment
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={true} md={'auto'}>
                                                            <CustomToggle
                                                                checked={this.state.isRequirePaymentCollection}
                                                                disabled={false}
                                                                onChange={(checked) => {
                                                                    this.setState({
                                                                        isRequirePaymentCollection: checked
                                                                    });
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box clone textAlign={'center'}>
                                                        <Grid item xs={'auto'} md={1}>&nbsp;</Grid>
                                                    </Box>
                                                </Grid>
                                            </Box>} */}

                                        </Grid>
                                    </Box>
                                </Grid>
                            </Box>

                        </Grid>
                    </Box>
                </Grid>
        
            </Box>
        </Card>
    }

    setTaskAppPermissionForm = () => {
        return <Card>
            <Box pl={{ xs: 0, md: 5 }}>

                <Grid container>
                    <Box clone pt={3}>
                        <Grid item xs={12}>
                            
                            <Box clone>
                                <Grid container>
                                    <Box clone pb={2}>
                                        <Grid item xs={12}>
                                            <ManageLabel
                                                className={'custom-options-subtitle'}
                                                label={"1. COMPLETE JOB"}
                                            />
                                            <Divider />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12}>
                                            

                                            <Box clone pb={1}>
                                                <Grid container alignItems={'center'}>
                                                    <Box clone pb={{ xs: 1, md: 0 }}>
                                                        <Grid item xs={12} md={true}>
                                                            
                                                            <Box clone>
                                                                <Grid container alignItems={'center'}>
                                                                    <Box clone>
                                                                        <Grid item xs={'auto'}>
                                                                            The driver is required to take
                                                                        </Grid>
                                                                    </Box>
                                                                    <Box clone pl={2} pr={2}>
                                                                        <Grid item xs={'auto'}>
                                                                            <ManageInput 
                                                                                className={"custom-max-width-50"}
                                                                                value={this.getNumberOfPhotos(this.state.mustTakePhoto2, this.state.numberofPhotosRequired2)}
                                                                                placeholder={""}
                                                                                readOnly={(this.state.mustTakePhoto2 === 0) ? true : false}
                                                                                type={'number'}
                                                                                inputProps={{
                                                                                    min: ((this.state.mustTakePhoto2 === 1) || (this.state.mustTakePhoto2 === 2)) ? 1 : 0,
                                                                                    max: 2,
                                                                                    pattern: ((this.state.mustTakePhoto2 === 1) || (this.state.mustTakePhoto2 === 2)) ? apiUtil.maxInputNumber12() : apiUtil.maxInputNumber012(),
                                                                                }}
                                                                                disabled={this.isDisabled()}
                                                                                onChange={(e) => {
                                                                                    this.setState({
                                                                                        numberofPhotosRequired2: e.target.value
                                                                                    });
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    </Box>
                                                                    <Box clone>
                                                                        <Grid item md={'auto'}>
                                                                            photo
                                                                        </Grid>
                                                                    </Box>
                                                                </Grid>
                                                            </Box>

                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={true} md={'auto'}>
                                                            <CustomOptionsToggle
                                                                options={[
                                                                    {
                                                                        value: CustomPhotoSignature.No,
                                                                        label: 'NO',
                                                                    },
                                                                    {
                                                                        value: CustomPhotoSignature.Yes,
                                                                        label: 'YES',
                                                                    },
                                                                    {
                                                                        value: CustomPhotoSignature.Optional,
                                                                        label: 'OPTIONAL',
                                                                    }
                                                                ]}
                                                                value={this.state.mustTakePhoto2}
                                                                disabled={this.isDisabled()}
                                                                onChange={(value) => {
                                                                    this.setState({
                                                                        mustTakePhoto2: value,
                                                                        numberofPhotosRequired2: (value === 1) ? 1 : 0,
                                                                    });
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box clone textAlign={'center'}>
                                                        <Grid item xs={'auto'} md={1}>&nbsp;</Grid>
                                                    </Box>
                                                </Grid>
                                            </Box>

                                            {(this.state.jobType === JobStepType.Task) && <Box clone pb={2}>
                                                <Grid container alignItems={'center'}>
                                                    <Box clone pb={{ xs: 1, md: 0 }}>
                                                        <Grid item xs={12} md={true}>
                                                            The driver is required to take signature
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={true} md={'auto'}>
                                                            <CustomOptionsToggle
                                                                options={[
                                                                    {
                                                                        value: CustomPhotoSignature.No,
                                                                        label: 'NO',
                                                                    },
                                                                    {
                                                                        value: CustomPhotoSignature.Yes,
                                                                        label: 'YES',
                                                                    },
                                                                    {
                                                                        value: CustomPhotoSignature.Optional,
                                                                        label: 'OPTIONAL',
                                                                    }
                                                                ]}
                                                                value={this.state.mustTakeSignature}
                                                                disabled={false}
                                                                onChange={(value) => {
                                                                    this.setState({
                                                                        mustTakeSignature: value,
                                                                    });
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box clone textAlign={'center'}>
                                                        <Grid item xs={'auto'} md={1}>&nbsp;</Grid>
                                                    </Box>
                                                </Grid>
                                            </Box>}

                                        </Grid>
                                    </Box>
                                </Grid>
                            </Box>

                        </Grid>
                    </Box>
                </Grid>
        
            </Box>
        </Card>
    }


    render() {
        return <Box>
            {(this.state.jobType === JobStepType.Task) ? this.setTaskAppPermissionForm() : this.setAppPermissionForm()}
        </Box>
    }
}

export default JobStepsSection;
