import React from 'react';

import { ReactComponent as CheckboxIcon } from "../../../../_metronic/assets/img/icons/ionic-ios-checkbox-outline.svg";
import { ReactComponent as UnCheckboxIcon } from "../../../../_metronic/assets/img/icons/ionic-ios-uncheckbox-outline.svg";
import { ReactComponent as CheckboxCheckedIcon } from "../../../../_metronic/assets/img/icons/ionic-ios-checkbox-outline-checked.svg";

import {
    TableCell,
    TableHead,
    TableRow,
    Checkbox,
    Icon,
} from '@material-ui/core';


const DataTableCustomHead = (props) => {
    const { onSelectAllClick, numDisabledCheckBoxes, numSelected, rowCount, isCheckbox, isDragable, isBorder, stickyHeader, children, customCheckBoxHead, expandDetails } = props;
    
    const ref = React.useRef();
    
    const setCustomCheckBox = () => {
      if(customCheckBoxHead){
          return customCheckBoxHead(props, ref);
      } else {
          return <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            color="default"
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            icon={<UnCheckboxIcon />}
            checkedIcon={<CheckboxCheckedIcon />}
            indeterminateIcon={<CheckboxCheckedIcon />}
            onClick={event => {
              var els = document.querySelectorAll('.details-row');
              if(els && els.length > 0){
                for (var i = 0; i < els.length; i++) {
                  els[i].classList.remove('visible-details-row');
                }
              }
      
              var expandEls = document.querySelectorAll('.custom-expand-icon');
              if(expandEls && expandEls.length > 0){
                for (var j = 0; j < expandEls.length; j++) {
                  expandEls[j].innerHTML = 'keyboard_arrow_down';
                }
              }
            }}
            inputProps={{ 'aria-label': 'select all' }}
          />
      }
    }


    const resizeListener = () => {
      let header = document.body.getElementsByTagName('header')[0];
      let headerPosition = header.getBoundingClientRect();
      let headerHeight = headerPosition.height;
      
      let tableContainer = document.body.getElementsByClassName('table-container')[0];
      let stickyHeader = tableContainer.getElementsByClassName('MuiTableCell-stickyHeader');

      try {
        header = document.body.getElementsByTagName('header')[0];
        headerPosition = header.getBoundingClientRect();
        headerHeight = headerPosition.height;
      
        tableContainer = document.body.getElementsByClassName('table-container')[0];
        stickyHeader = tableContainer.getElementsByClassName('MuiTableCell-stickyHeader');
      } catch(err){}
      
      if(stickyHeader && stickyHeader.length > 0){
        for(var i = 0; i < stickyHeader.length; i++) {
          stickyHeader[i].style.top = headerHeight + 'px';
        }
      }
    }

    const scrollListener = () => {
      let header = document.body.getElementsByTagName('header')[0];
      let headerPosition = header.getBoundingClientRect();
      let headerHeight = headerPosition.height;
      
      let tableHead = ref.current;
      let tableHeadPosition = tableHead.getBoundingClientRect();
      
      let tableContainer = document.body.getElementsByClassName('table-container')[0];
      let stickyHeader = tableContainer.getElementsByClassName('MuiTableCell-stickyHeader');

      try {
        header = document.body.getElementsByTagName('header')[0];
        headerPosition = header.getBoundingClientRect();
        headerHeight = headerPosition.height;
      
        tableHead = ref.current;
        tableHeadPosition = tableHead.getBoundingClientRect();
      
        tableContainer = document.body.getElementsByClassName('table-container')[0];
        stickyHeader = tableContainer.getElementsByClassName('MuiTableCell-stickyHeader');
      } catch(err){}
      
      if (window.scrollY > (tableHeadPosition.top - headerHeight)) {
        if(stickyHeader && stickyHeader.length > 0){
          for(var i = 0; i < stickyHeader.length; i++) {
            stickyHeader[i].style.top = headerHeight + 'px';
          }
        }
      }
    }
    

    React.useEffect(() => {
      try{
        if(stickyHeader){
          resizeListener();
          scrollListener();
        }
      } catch(e){}
      
      if(stickyHeader){
        window.addEventListener('resize', resizeListener);
        window.addEventListener('scroll', scrollListener);
      }
  
      return () => {
        if(stickyHeader){
          window.removeEventListener('resize', resizeListener);
          window.removeEventListener('scroll', scrollListener);
        }
      }
    });


    return (
      <TableHead ref={ref}>
        <TableRow>
            {isBorder && 
            <TableCell 
                className="table-cell-border"
            ></TableCell>}

            {isCheckbox && 
            <TableCell 
              width={'50px'} 
              align={'center'}
            >
              {setCustomCheckBox()}
            </TableCell>}
            
            {expandDetails && 
            <TableCell 
              className={'custom-expand-details-th'} 
              width={'30px'} 
            ></TableCell>}

            {isDragable && 
            <TableCell 
              width={'30px'} 
              align={'center'}
              padding="normal"
            >
              <Icon component={'i'} className="my-drag-head" style={{ paddingTop: '7px' }}>menu</Icon>
            </TableCell>}

            {children}

            {isBorder && 
            <TableCell 
                className="table-cell-border"
            ></TableCell>}
        </TableRow>
      </TableHead>
    );
  }

  export default DataTableCustomHead;