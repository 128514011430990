import React from 'react';

import { 
    Row,
    Col,
} from "react-bootstrap-v5";

import { useDispatch, useSelector } from "react-redux";
import {RootState} from '../../../../../setup'
import payrollBetaLayout from '../redux/payrollBetaLayout';

import WorkTime from './WorkTime';
import WorkTimeJobType from './WorkTimeJobType';

import { StyledSecondaryRadio } from '../style/styles';


const PayrollBetaStep3a = (props: any) => {
    const dispatch = useDispatch();
    const { step3aRadio } = useSelector((state: RootState) => state.payrollBetaLayout);
    const { isLoading} = useSelector((state: RootState) => state.payrollBetaForm);

    return <>
        {
            isLoading
            ?
            null
            :
            <Row>
                <Col xs={12} className={'mb-10'}>
                    <StyledSecondaryRadio
                        inline
                        type={'radio'}
                        name={'step_3a'}
                        id={'step_3a_1'}
                        label={'No'}
                        checked={!step3aRadio}
                        onChange={(e: any) => {
                            dispatch(payrollBetaLayout.setStep3aRadio(false));
                        }}
                    />
                    <StyledSecondaryRadio
                        inline
                        type={'radio'}
                        name={'step_3a'}
                        id={'step_3a_2'}
                        label={'Yes'}
                        checked={step3aRadio}
                        onChange={(e: any) => {
                            dispatch(payrollBetaLayout.setStep3aRadio(true));
                        }}
                    />
                </Col>
                <Col xs={12}>
                    {step3aRadio ? <WorkTimeJobType /> : <WorkTime />}
                </Col>
            </Row>
        }
    </>
}


export default PayrollBetaStep3a;
