import React from "react";

import { 
    Row,
    Col,
    Form,
    InputGroup,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import Utils from "../../../../utils/utils";
import { InOrOut, BinOrWaste, JobStepType, PagesPermissions } from "../../../../utils/enums";

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import CustomTooltip from '../../../../components/Tooltip/Tooltip';
import RSuiteAutoComplete from '../../../../components/OLD/Autocomplete/RSuiteAutoComplete';
import RSuiteSelectPicker from '../../../../components/OLD/Select/RSuiteSelectPicker';
import InputLayout from "../../../../components/input/InputLayout";

import { connect } from 'react-redux'
import { clear } from "../../../../../setup/redux/actions";
import { dispatchApiCallGet } from '../../../../../setup/redux/dispatch/actions'

import { ReactComponent as ScanTextIcon } from "../../../../../_metronic/assets/img/bin_center/scan_text.svg";


const StyledInputGroup = styled(InputGroup)`
    input {
        &:not(.disabled) {
            &:not([value=""]) {
                border-right: 0px;
            }
        }
    }

    .input-group-text {
        background-color: white;
        cursor: pointer;

        i {
            font-size: 14px;
            color: #8e8e93;
        }

        &:hover {
            i {
                color: #f44336;
            }
        }
    }
`;
const StyledInputReadOnly = styled(Form.Control)`
    border: 0px;
    padding-left: 5px;
    cursor: default;

    &[readonly]{
        color: #181C32 !important;
    }
`;


class StepShiftSection extends React.Component {
  
    constructor(props) {
        super(props);
        
        this.name = 'job_form_step_shift_section';

        let dataParams = (props.dataParams) ? props.dataParams : null;
        let id = (dataParams && dataParams.id) ? dataParams.id : null;
        let row = (dataParams && dataParams.row) ? dataParams.row : null;
        
        this.pageRef = React.createRef();

        this.state = {
            id: id,
            row: row,

            currentPageCustomerSite: 1,
            totalPageCustomerSite: 0,
            queryStringCustomerSite: '',

        };
    }
    

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            this.name + '-binnumber', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = [];

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.binNumberId;
                        let title = item.binNumberName;
                        let arrItem = {
                            index: i,
                            value: value,
                            title: title,
                            label: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.name + '-customersite', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;
                let total = 0;

                let values = (this.props && this.props.formOptions && this.props.formOptions.values) ? this.props.formOptions.values : null;
                
                if(data && data.data && data.data.length > 0) {
                    total = data.total;

                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.customerSiteId;
                        // let title = item.siteNameDisplay;
                        let title = Utils.formatCustomerSiteAddress(item);
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        // if(values && values.customerSiteId && values.customerSiteId !== value){
                            arr = Utils.addToArray(arr, value, arrItem);
                        // }
                    }
                }

                this.setState({
                    totalPageCustomerSite: total,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );
    }

    callBinNumberReadApi = (binTypeId = null, items, searchQuery = '', callback = null) => {
        let {
            values,
        } = this.props.formOptions;

        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: searchQuery,
            binTypeId: binTypeId,
            customerSiteId: values.customerSiteId,
            customerId: values.customerId,
            showRecentJob: true,
        };

        this.props.dispatchApiCallGet(data, this.name + '-binnumber', 'binnumber', { items: items }, callback, () => {});
    }

    callReadCustomerSiteApi = (customerId = null, items, currentPage = null, callback = null) => {
        if(currentPage){
            this.setState({
                currentPageCustomerSite: currentPage,
            }, () => {
                let data = {
                    currentPage: this.state.currentPageCustomerSite,
                    pageSize: Utils.getDefaultPageSize(),
                    searchQuery: this.state.queryStringCustomerSite,
                    customerId: customerId,
                    showRecentJob: true,
                };
             
                this.props.dispatchApiCallGet(data, this.name + '-customersite', 'customersite', { items: items }, callback, () => {});
            });
        } else {
            let data = {
                currentPage: this.state.currentPageCustomerSite,
                pageSize: Utils.getDefaultPageSize(),
                searchQuery: this.state.queryStringCustomerSite,
                customerId: customerId,
                showRecentJob: true,
            };
         
            this.props.dispatchApiCallGet(data, this.name + '-customersite', 'customersite', { items: items }, callback, () => {});
        }
    }
    /* END API */


    body = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;
        
        let hasEditJobAfterCompleted = (Utils.hasPermission(Utils.getPermissions(), PagesPermissions.EditJobAfterCompleted.read))

        let isDisabledCustomerSite1 = Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName);
        let isDisabledCustomerSite2 = Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || Utils.isCancelledOrFailed(values.currentStatusName);
        let isDisabledCustomerSite = hasEditJobAfterCompleted ? isDisabledCustomerSite2 : isDisabledCustomerSite1;
        
        let isDisabled1 = values.isParams || Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || !(values.customerSiteId) || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName);
        let isDisabled2 = values.isParams || Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || !(values.customerSiteId) || Utils.isCancelledOrFailed(values.currentStatusName);
        let isDisabled = hasEditJobAfterCompleted ? isDisabled2 : isDisabled1;
        
        let isDisabledBN1 = values.isParams || Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || !(values.customerSiteId) || !(values.stepShiftBinTypeId) || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName);
        let isDisabledBN2 = values.isParams || Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || !(values.customerSiteId) || !(values.stepShiftBinTypeId) || Utils.isCancelledOrFailed(values.currentStatusName);
        let isDisabledBN = hasEditJobAfterCompleted ? isDisabledBN2 : isDisabledBN1;

        return <Row className={''}>
            <Col xs={12} md={6}>
            <Row className={''}>
                    <Col xs={12}>
                        <h3 className={'mb-7'} style={{ color: '#DFDFDF' }}>New Location</h3>
                    </Col>
                    <Col xs={12}>
                        <Row>
                            <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.customerSiteShiftId.label + ((values.customFormParams.jobTemplateType === JobStepType.Shift) ? ' ' + this.props.fields.customerSiteShiftId.info : '')}
                                    required
                                >
                                    <Form.Control
                                        as = {RSuiteSelectPicker}
                                        isInvalid={!!errors.customerSiteShiftId || !!errors.hasCoordCustomerSiteShift}

                                        id={this.props.fields.customerSiteShiftId.id}
                                        placeholder={this.props.fields.customerSiteShiftId.placeholder}
                                        items={values.customFormParams.customerSiteShiftItems}
                                        value={values.customerSiteShiftId}
                                        selectedName={values.customerSiteShiftName}
                                        isLoading={values.customFormParams.customerSiteShiftIsLoading}
                                        readOnly={isDisabledCustomerSite}
                                        searchable={true}
                                        cleanable={true}
                                        virtualized={false}
                                        isDefault={true}
                                        renderExtraFooter={() => {
                                            let totalCount = Utils.getPaginationCount(this.state.totalPageCustomerSite, Utils.getDefaultPageSize());

                                            return <Row className={'align-items-center'}>
                                                
                                                {(((this.state.totalPageCustomerSite > 0) && (this.state.currentPageCustomerSite < totalCount))) && <Col xs={12}>
                                                    <div className={'px-5 pt-3'}>
                                                        <a href={'/'}
                                                            className={"link-primary"}
                                                            onClick={async (e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();

                                                                await setFieldValue('customFormParams.customerSiteShiftIsLoading', true);
                                                                this.callReadCustomerSiteApi(values.customerId, values.customFormParams.customerSiteShiftItems, (this.state.currentPageCustomerSite + 1), async (items) => {
                                                                    await setFieldValue('customFormParams.customerSiteShiftItems', items);
                                                                    await setFieldValue('customFormParams.customerSiteShiftIsLoading', false);
                                                                    Utils.scrollToBottomByClassName('ReactVirtualized__Grid');
                                                                });
                                                            }}
                                                        >View more</a>
                                                    </div>
                                                </Col>}

                                                <Col xs={12}>
                                                    <hr />
                                                </Col>
                                                <Col xs={12}>
                                                    <div className={'px-5 pb-3'}>
                                                        <a href={'/'}
                                                            className={"link-primary"}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();

                                                                if(this.props.onCreateCustomerSite){
                                                                    this.props.onCreateCustomerSite(2);
                                                                }
                                                            }}
                                                        >Create new</a>
                                                    </div>
                                                </Col>
                                            </Row>
                                        }}
                                        onSearch={(val) => {
                                            this.setState({
                                                queryStringCustomerSite: val,
                                            }, async () => {
                                                await setFieldValue('customFormParams.customerSiteShiftIsLoading', true);
                                                this.callReadCustomerSiteApi(values.customerId, [], 1, async (items) => {
                                                    let item = values.customerSiteShiftItem;
                                                    if(item){
                                                        let value = item.customerSiteId;
                                                        // let title = item.siteNameDisplay;
                                                        let title = Utils.formatCustomerSiteAddress(item);
                                                        let arrItem = {
                                                            value: value,
                                                            title: title,
                                                            item: item,
                                                        };
                                                        items = Utils.addToArrayIfNotExist(items, value, arrItem);
                                                    }

                                                    await setFieldValue('customFormParams.customerSiteShiftItems', items);
                                                    await setFieldValue('customFormParams.customerSiteShiftIsLoading', false);
                                                });
                                            });
                                        }}
                                        onClick={async () => {
                                            this.setState({
                                                queryStringCustomerSite: '',
                                            }, async () => {
                                                await setFieldValue('customFormParams.customerSiteShiftIsLoading', true);
                                                this.callReadCustomerSiteApi(values.customerId, [], 1, async (items) => {
                                                    let item = values.customerSiteShiftItem;
                                                    if(item){
                                                        let value = item.customerSiteId;
                                                        // let title = item.siteNameDisplay;
                                                        let title = Utils.formatCustomerSiteAddress(item);
                                                        let arrItem = {
                                                            value: value,
                                                            title: title,
                                                            item: item,
                                                        };
                                                        items = Utils.addToArrayIfNotExist(items, value, arrItem);
                                                    }

                                                    await setFieldValue('customFormParams.customerSiteShiftItems', items);
                                                    await setFieldValue('customFormParams.customerSiteShiftIsLoading', false);
                                                });
                                            });
                                        }}
                                        onSelect={async (value, item) => {
                                            await setFieldValue('customerSiteShiftId', value);
                                            await setFieldValue('customerSiteShiftName', item.title);
                                            await setFieldValue('customerSiteShiftAddress', Utils.formatCustomerSiteAddress(item.item));
                                            await setFieldValue('customerSiteShiftItem', item.item);

                                            let latitude = (item && item.item && item.item.latitude && item.item.latitude != '') ? item.item.latitude : '';
                                            let longitude = (item && item.item && item.item.longitude && item.item.longitude != '') ? item.item.longitude : '';
                                            let hasCoord = (latitude != '' && longitude != '') ? true : false;
                                            if(!hasCoord){
                                                Utils.toast('Customer site doesn\'t have coordinates, please add the missing coordinates.', 'warning', null, 4000);
                                            }

                                            await setFieldValue('hasCoordCustomerSiteShift', hasCoord);
                                                
                                            let markers = Utils.getJobFormLocationMarkers({
                                                customerSiteId: values.customerSiteId,
                                                customerSiteItems: values.customFormParams.customerSiteItems,
                                                customerSiteShiftId: value,
                                                customerSiteShiftItems: values.customFormParams.customerSiteShiftItems,
                                                templateStepSites1Latitude: values.templateStepSites1Latitude,
                                                templateStepSites1Longitude: values.templateStepSites1Longitude,
                                                templateStepSites2Latitude: values.templateStepSites2Latitude,
                                                templateStepSites2Longitude: values.templateStepSites2Longitude,
                                                jobTemplateType: values.customFormParams.jobTemplateType
                                            }, values)
                                            await setFieldValue('customFormParams.markers', markers);
                                        }}
                                        onClear={async () => {
                                            await setFieldValue('customerSiteShiftId', null);
                                            await setFieldValue('customerSiteShiftName', '');
                                            await setFieldValue('customerSiteShiftAddress', '');
                                            await setFieldValue('customerSiteShiftItem', null);
                                            await setFieldValue('hasCoordCustomerSiteShift', false);
                                                
                                            let markers = Utils.getJobFormLocationMarkers({
                                                customerSiteId: values.customerSiteId,
                                                customerSiteItems: values.customFormParams.customerSiteItems,
                                                customerSiteShiftId: null,
                                                customerSiteShiftItems: values.customFormParams.customerSiteShiftItems,
                                                templateStepSites1Latitude: values.templateStepSites1Latitude,
                                                templateStepSites1Longitude: values.templateStepSites1Longitude,
                                                templateStepSites2Latitude: values.templateStepSites2Latitude,
                                                templateStepSites2Longitude: values.templateStepSites2Longitude,
                                                jobTemplateType: values.customFormParams.jobTemplateType
                                            }, values)
                                            await setFieldValue('customFormParams.markers', markers);
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.customerSiteShiftId}</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">{errors.hasCoordCustomerSiteShift}</Form.Control.Feedback>
                                </InputLayout>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col xs={12} md={6}>
                <Row className={''}>
                    <Col xs={12}>
                        <h3 className={'mb-7'} style={{ color: '#DFDFDF' }}>BIN INFO</h3>
                    </Col>
                    <Col xs={12}>
                        <Row>
                            <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.stepShiftBinTypeId.label}
                                    required
                                >
                                    <StyledInputGroup>
                                        <Form.Control
                                            className={'custom-readonly-bin-waste-field ' + (isDisabled ? 'disabled': '')}
                                            id={this.props.fields.stepShiftBinTypeId.id}
                                            placeholder={this.props.fields.stepShiftBinTypeId.placeholder}
                                            readOnly={true}
                                            disabled={isDisabled}
                                            value={values.stepShiftBinTypeName}
                                            isInvalid={!!errors.stepShiftBinTypeId}
                                            onClick={(e) => {
                                                if(!isDisabled){
                                                    if(this.props.onBinWasteSidePanel){
                                                        this.props.onBinWasteSidePanel(InOrOut.IN, BinOrWaste.BIN);
                                                    }
                                                }
                                            }}
                                        />
                                        {(!isDisabled && values.stepShiftBinTypeName != null && values.stepShiftBinTypeName != '') && <InputGroup.Text
                                            onClick={(e) => {
                                                if(this.props.onBinWasteClear){
                                                    this.props.onBinWasteClear(InOrOut.IN, BinOrWaste.BIN);
                                                }
                                            }}
                                        >
                                            <i className={'material-icons'}>close</i>
                                        </InputGroup.Text>}
                                        <Form.Control.Feedback type="invalid">{errors.stepShiftBinTypeId}</Form.Control.Feedback>
                                    </StyledInputGroup>
                                </InputLayout>
                            </Col>
                            <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.stepShiftWasteTypeIds.label}
                                    required
                                >
                                    <StyledInputGroup>
                                        <Form.Control
                                            className={'custom-readonly-bin-waste-field ' + ((isDisabled) ? 'disabled': '')}
                                            id={this.props.fields.stepShiftWasteTypeIds.id}
                                            placeholder={this.props.fields.stepShiftWasteTypeIds.placeholder}
                                            readOnly={true}
                                            disabled={isDisabled}
                                            value={values.stepShiftWasteTypeIds ? values.customFormParams.stepShiftWasteTypeItems.filter(item => values.stepShiftWasteTypeIds.includes(item.value)).map(x => x.title).join(', ') : ''}
                                            isInvalid={!!errors.stepShiftWasteTypeIds}
                                            onClick={(e) => {
                                                if(!isDisabled){
                                                    if(this.props.onBinWasteSidePanel){
                                                        this.props.onBinWasteSidePanel(InOrOut.IN, BinOrWaste.BIN);
                                                    }
                                                }
                                            }}
                                        />
                                        {(!isDisabled && (values.stepShiftWasteTypeIds ? values.customFormParams.stepShiftWasteTypeItems.filter(item => values.stepShiftWasteTypeIds.includes(item.value)).map(x => x.title).join(', ') : '') != '') && <InputGroup.Text
                                            onClick={(e) => {
                                                if(this.props.onBinWasteClear){
                                                    this.props.onBinWasteClear(InOrOut.IN, BinOrWaste.BIN);
                                                }
                                            }}
                                        >
                                            <i className={'material-icons'}>close</i>
                                        </InputGroup.Text>}
                                        <Form.Control.Feedback type="invalid">{errors.stepShiftWasteTypeIds}</Form.Control.Feedback>
                                    </StyledInputGroup>
                                </InputLayout>
                            </Col>
                            <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.stepShiftBinNumber.label}
                                >
                                    <InputGroup>
                                    <Form.Control
                                        as = {RSuiteAutoComplete}
                                        isInvalid={!!errors.stepShiftBinNumber}

                                        id={this.props.fields.stepShiftBinNumber.id}
                                        placeholder={this.props.fields.stepShiftBinNumber.placeholder}
                                        items={values.customFormParams.stepShiftBinNumberItems}
                                        value={values.stepShiftBinNumber}
                                        isLoading={values.customFormParams.stepShiftBinNumberIsLoading}
                                        disabled={isDisabledBN}
                                        labelKey={'title'}
                                        isDefault={true}
                                        pattern={Utils.max7chars()}
                                        renderMenuItem={(label, item) => {
                                            let minDate = Utils.getMinDate();
                                            let lastJobDate = (item && item.item && item.item.lastJobDate) ? item.item.lastJobDate : minDate;

                                            return <div className={'d-flex align-items-center'}>
                                                {((lastJobDate !== minDate) && (item.index < 5)) ? <i className={'material-icons'}>access_time</i> : null}
                                                <span className={'ps-2'}>{item.title}</span>
                                            </div>
                                        }}
                                        onClick={async () => {
                                            await setFieldValue('customFormParams.stepShiftBinNumberIsLoading', true);
                                            this.callBinNumberReadApi(values.stepShiftBinTypeId, values.customFormParams.stepShiftBinNumberItems, '', async (items) => {
                                                await setFieldValue('customFormParams.stepShiftBinNumberItems', items);
                                                await setFieldValue('customFormParams.stepShiftBinNumberIsLoading', false);
                                            });
                                        }}
                                        onSearch={async (value) => {
                                            await setFieldValue('stepShiftisUsingQr', false);
                                            await setFieldValue('stepShiftisUsingTextDetection', false);
                                            await setFieldValue('customFormParams.isSameAsBinOut', false);
                                            await setFieldValue('customFormParams.stepShiftBinNumberIsLoading', true);
                                            this.callBinNumberReadApi(values.stepShiftBinTypeId, values.customFormParams.stepShiftBinNumberItems, value, async (items) => {
                                                await setFieldValue('customFormParams.stepShiftBinNumberItems', items);
                                                await setFieldValue('customFormParams.stepShiftBinNumberIsLoading', false);
                                            });
                                        }}
                                        onChange={async (value) => {
                                            await setFieldValue('stepShiftisUsingQr', false);
                                            await setFieldValue('stepShiftisUsingTextDetection', false);
                                            await setFieldValue('stepShiftBinNumber', value);
                                            await setFieldValue('customFormParams.isSameAsBinOut', false);
                                        }}
                                        onSelect={async (value, item) => {
                                            await setFieldValue('stepShiftisUsingQr', false);
                                            await setFieldValue('stepShiftisUsingTextDetection', false);
                                            await setFieldValue('stepShiftBinNumber', value);
                                            await setFieldValue('customFormParams.isSameAsBinOut', false);
                                        }}
                                    />
                                    {values.stepShiftisUsingQr && <CustomTooltip title={'QR code is scanned'}>
                                        <i style={{ position: 'absolute', right: '15px', top: '15px' }} class="fa fa-qrcode"></i>
                                    </CustomTooltip>}
                                    {values.stepShiftisUsingTextDetection && <CustomTooltip title={'QR code is scanned with text recognition'}>
                                        <ScanTextIcon style={{ position: 'absolute', right: '14px', top: '15px', width: '15px', height: '15px' }} />
                                    </CustomTooltip>}
                                    <Form.Control.Feedback type="invalid">{errors.stepShiftBinNumber}</Form.Control.Feedback>
                                    </InputGroup>
                                </InputLayout>
                            </Col>
                            
                            <Col xs={12}>
                                <InputLayout
                                    label={'Service name'}
                                    inputProps={{
                                        className: 'd-flex align-items-center'
                                    }}
                                >
                                    <div>
                                        <span className={'pe-2'}>{values.stepShiftServiceItemName}</span>
                                        {(values.stepShiftServiceItemIsGlobal && (values.stepShiftServiceItemName != '')) && <CustomTooltip placement={'top'} title={'Global service'}>
                                            <i className={'material-icons'} style={{ position: 'relative', top: '6px', fontSize: '22px', }}>public</i>
                                        </CustomTooltip>}
                                    </div>
                                </InputLayout>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    }
    

    render() {
        return <div ref={this.pageRef}>
            {this.body()}
        </div>
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(StepShiftSection);
