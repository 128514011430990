import React from "react";

import { 
    Row,
    Col,
    Form,
    Collapse,
} from "react-bootstrap-v5";

import InnerFilesDropzone from '../../../../components/OLD/Dropzone/InnerDropzone/InnerFilesDropzone.jsx';
import InputLayout from "../../../../components/input/InputLayout";

import Utils from "../../../../utils/utils";

import FileSignatureIcon from '../../../../../_metronic/assets/img/manage/file_signature.png';

import { connect } from 'react-redux'
import { clear } from "../../../../../setup/redux/actions";
import { dispatchApiCallGet } from '../../../../../setup/redux/dispatch/actions'


const Accordion = (props) => {
    const [isOpen, setIsOpen] = React.useState(((props.isOpen === false) || (props.isOpen === true)) ? props.isOpen : false);

    React.useEffect(() => {
        setIsOpen(((props.isOpen === false) || (props.isOpen === true)) ? props.isOpen : false);
    }, [props.isOpen]);

    return <>
        <Row className={'align-items-center'}>
            <Col xs={true}
                className={'cursor-pointer'}
                onClick={() => {
                    if(props.onChange){
                        props.onChange(!isOpen);
                    }
                }}
            >
                <h3 className={'mb-0'}>{props.title}</h3>
            </Col>
            <Col xs={'auto'}
                className={'cursor-pointer'}
                onClick={() => {
                    if(props.onChange){
                        props.onChange(!isOpen);
                    }
                }}
            >
                <i className={'material-icons'}>{isOpen ? 'expand_more' : 'expand_less'}</i>
            </Col>
            <Col xs={12}><hr className={'custom-manage-hr mt-0 mb-1'} /></Col>
        </Row>
        <Collapse in={isOpen}>
            <Row>
                <Col xs={12} className={'mt-5'}>{props.children}</Col>
            </Row>
        </Collapse>
    </>
}


class SignatureSection extends React.Component {
  
    constructor(props) {
        super(props);
        
        this.name = 'job_form_signature_section';

        let dataParams = (props.dataParams) ? props.dataParams : null;
        let id = (dataParams && dataParams.id) ? dataParams.id : null;
        let row = (dataParams && dataParams.row) ? dataParams.row : null;
        

        this.state = {
            id: id,
            row: row,

            isExpanded: true,
        };
    }
    

    body = () => {
        let {
            setFieldValue,
            values,
            errors,
            handleChange,
        } = this.props.formOptions;
        
        return <Row className={'pb-10'}>
            <Col xs={12} className={'mt-10 mb-10'}><hr /></Col>

            <Col xs={12}>
                <h3 className={'mb-7'}>Signature</h3>
            </Col>

            <Col xs={12} md={6} lg={4} xl={3}>
                <InnerFilesDropzone 
                    className={"job-form-files-dropzone-images"}
                    token={this.props.token}
                    dispatchAccountId={this.props.dispatchAccountId}
                    addImage={FileSignatureIcon}
                    multiple={false}
                    contain={true}
                    disabled={!values.isActive || Utils.isCancelledOrFailed(values.currentStatusName)}
                    path={'jobs/signature'}
                    accept={"image/jpeg,image/jpg,image/png"}
                    acceptText={".jpeg, .jpg, .png"}
                    value={(values.jobSignatureUrl && values.jobSignatureUrl !== '') ? [values.jobSignatureUrl]: []}
                    onDrop={async (files) => {
                        await setFieldValue('customFormParams.signatureAttachmentsIsLoading', true);
                    }}
                    onUpload={async (files) => {
                        let jobSignatureUrl = (files && files.length > 0) ? files[0] : '';
                        await setFieldValue('jobSignatureUrl', jobSignatureUrl);
                        await setFieldValue('customFormParams.signatureAttachmentsIsLoading', false);
                    }}
                    onRemove={async (file, i, files) => {
                        await setFieldValue('jobSignatureUrl', '');
                        await setFieldValue('customFormParams.signatureAttachmentsIsLoading', false);
                    }}
                />
            </Col>
            <Col xs={12} md={6} className={'pt-4 pt-md-0'}>
                <InputLayout
                    label={this.props.fields.jobSignedUserName.label}
                >
                    <Form.Control
                        type="text"
                        id={this.props.fields.jobSignedUserName.id}
                        placeholder={this.props.fields.jobSignedUserName.placeholder}
                        value={values.jobSignedUserName}
                        onChange={handleChange}
                        isInvalid={!!errors.jobSignedUserName}
                    />
                    <Form.Control.Feedback type="invalid">{errors.jobSignedUserName}</Form.Control.Feedback>
                </InputLayout>
                
                <InputLayout
                    label={this.props.fields.jobSignedUserContact.label}
                >
                    <Form.Control
                        type="text"
                        id={this.props.fields.jobSignedUserContact.id}
                        placeholder={this.props.fields.jobSignedUserContact.placeholder}
                        value={values.jobSignedUserContact}
                        onChange={handleChange}
                        isInvalid={!!errors.jobSignedUserContact}
                    />
                    <Form.Control.Feedback type="invalid">{errors.jobSignedUserContact}</Form.Control.Feedback>
                </InputLayout>
            </Col>
        </Row>
    }
    

    render() {
        return <>
            {this.body()}
        </>
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SignatureSection);
