import React from 'react';

import { 
    Modal,
    Button,
    Spinner,
    CloseButton,
    Row,
    Col,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import { useDispatch, useSelector } from "react-redux";
import advancedMappingQuickBook from '../redux/advancedMappingQuickBook';

import IconButtonSearch from '../../../../components/input/IconButtonSearch';
import DataTable from "../../../../components/OLD/DataTable/DataTable";
import DataTableAjaxPagination from '../../../../components/OLD/DataTable/Pagination/DataTableAjaxPaginationV5';


const StyledLoadingWrapper = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #00000030;
    display: flex;
    z-index: 9999;

    > div {
        display: flex;
        align-items: center;
        align-content: center;
        text-align: center;
        width: 100%;
    }
`;


const AdvancedQuickBookPopup = props => {
    const dispatch = useDispatch();
    const { isLoading, show, items, columns, columnVisibility, rowsPerPageSelected, pageSize, searchQuery, currentPage, total, sortColumn, sortDir } = useSelector((state) => state.advancedMappingQuickBook);
    

    const tableRef = React.useRef(null);


    const setDataTable = () => {
        return <DataTable
            ref={tableRef}

            className={"table-style-v4"}
            classNameWrapper={"table-v4"}

            pageName={'advanced-quickBook'}
            rowId={'chargeCategoryId'}
            caption={'Advanced Quickbook'}

            noMarginBottom={false}
            verticalAlign={'middle'}

            stickyHeader={false}
            isHeader={true}
            resizable={false}
            isDragableHeaderColumns={false}
            isDragable={false}
            isCheckbox={false}
            isBorder={false}
            isPagination={false}
            isVirtualize={false}

            columnVisibility={columnVisibility}
            disabledResizeColumns={[]}

            isLoading={isLoading}
            rowsPerPageSelected={rowsPerPageSelected}
            rowsPerPage={pageSize}

            search={searchQuery}
            rows={items}
            columns={columns}

            onRowClick={(id, row) => {
                
            }}

            onRemove={(row) => {

            }}

            onClickCheckAll={(obj, checked, isDisabledBatchAction) => {
                
            }}
            onCheckRow={(obj, id, isDisabledBatchAction) => {
                
            }}

            onSortEnd={(columns, columnVisibility) => {
                
            }}
            onOrder={async (name, sort) => {
                await dispatch(advancedMappingQuickBook.callReadApi({
                    currentPage: currentPage,
                    pageSize: pageSize,
                    searchQuery: searchQuery,
                    sortColumn: name,
                    sortDir: sort,
                }));
            }}
            onResize={(columns) => {
                
            }}
        />
    }

    const paginationSection = () => {
        return <DataTableAjaxPagination
            style={{
                padding: '10px 5px',
                border: '0px',
                bottom: '70px',
                zIndex: '9999',
            }}
            page={currentPage}
            selected={pageSize}
            count={total}
            fixed={true}
            onPageChange={async (page) => {
                await dispatch(advancedMappingQuickBook.callReadApi({
                    currentPage: page,
                    pageSize: pageSize,
                    searchQuery: searchQuery,
                    sortColumn: sortColumn,
                    sortDir: sortDir,
                }));
            }}
            onOptionsChange={async (option) => {
                await dispatch(advancedMappingQuickBook.callReadApi({
                    currentPage: currentPage,
                    pageSize: parseInt(option),
                    searchQuery: searchQuery,
                    sortColumn: sortColumn,
                    sortDir: sortDir,
                }));
            }}
        />
    }


    const header = () => {
        return <Modal.Header>
            <Row className={'align-items-center gx-0 w-100'}>
                <Col xs={true} lg={'auto'} className={'order-1 order-lg-1'}>
                    <Modal.Title>Advanced Quickbook Settings</Modal.Title>
                </Col>
                <Col xs={12} lg={3} className={'ps-0 ps-lg-4 order-4 order-lg-2'}>
                    <IconButtonSearch
                        canEnter={true}
                        value={searchQuery}
                        isOpen={(searchQuery !== '') ? true : false}
                        onPressEnter={async (value) => {
                            await dispatch(advancedMappingQuickBook.callReadApi({
                                currentPage: 1,
                                pageSize: pageSize,
                                searchQuery: value,
                                sortColumn: sortColumn,
                                sortDir: sortDir,
                            }));
                        }}
                        onClear={async () => {
                            await dispatch(advancedMappingQuickBook.callReadApi({
                                currentPage: 1,
                                pageSize: pageSize,
                                searchQuery: '',
                                sortColumn: sortColumn,
                                sortDir: sortDir,
                            }));
                        }}
                    />
                </Col>
                <Col xs={'auto'} lg={true} className={'order-3 order-lg-3'}></Col>
                <Col xs={'auto'} className={'order-2 order-lg-4'}>
                    <CloseButton
                        className={'rounded-2'}
                        disabled={isLoading}
                        onClick={async () => {
                            await dispatch(advancedMappingQuickBook.hide());
                        }}
                    />
                </Col>
            </Row>
        </Modal.Header>
    }
    const body = () => {
        return <Modal.Body>
            <Row>
                <Col xs={12}>
                    {setDataTable()}
                    {paginationSection()}
                </Col>
            </Row>
        </Modal.Body>
    }
    const footer = () => {
        return <Modal.Footer>
            <Row className={'w-100'}>
                <Col xs={'auto'}>
                    <Button 
                        type={'button'}
                        variant={'secondary'}
                        disabled={isLoading}
                        size={'sm'}
                        onClick={async () => {
                            await dispatch(advancedMappingQuickBook.hide());
                        }}
                    >
                        Close
                        {(isLoading) ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                    </Button>
                </Col>
            </Row>
        </Modal.Footer>
    }
    const loading = () => {
        return <StyledLoadingWrapper>
            <Row>
                <Col xs={12} className={'px-7 py-5 text-center'}>
                    <Spinner animation="border" variant='primary' />
                </Col>
            </Row>
        </StyledLoadingWrapper>
    }


    return <Modal
        show={show}
        onHide={async () => {
            await dispatch(advancedMappingQuickBook.hide());
        }}
        backdrop={"static"}
        keyboard={false}
        enforceFocus={false}
        fullscreen={true}
    >
        {header()}
        {body()}
        {footer()}
        {isLoading && loading()}
    </Modal>
}


export default AdvancedQuickBookPopup;
