import { createSlice } from "@reduxjs/toolkit";

import * as dispatchCrudApi from '../../../app/api/CRUD/DispatchCRUD'
import Utils from "../../../app/utils/utils";


function NewReducer() {
  const name = 'jobStatuses';


  const initialState = {
    isLoading: false,

    statuses: [],
    total: 0,

    selectedId: null,
    selectedIds: [],
  };


  const reducers = {
    getIdByName: (state, action) => {
      const payload = (action.payload && action.payload != '') ? action.payload : '';

      let selectedId = null;

      let items = state.statuses.filter(x => x.jobStatusName.toLowerCase().trim() == payload.toLowerCase().trim());
      if(items && items.length > 0){
        selectedId = items[0].jobStatusId;
      }

      state.selectedId = selectedId;
    },
    getIdsByNames: (state, action) => {
      const payload = (action.payload && action.payload.length > 0) ? action.payload : [];

      let selectedIds = [];

      let items = state.statuses.filter(x => payload.includes(x.jobStatusName.toLowerCase().trim()));
      if(items && items.length > 0){
        items.forEach((x) => {
          selectedIds.push(x.jobStatusId);
        });
      }

      state.selectedIds = selectedIds;
    },
    

    startRead: (state, action) => {
      state.isLoading = true;
      state.statuses = [];
      state.total = 0;
    },
    finishRead: (state, action) => {
      let data = action.payload;
      
      let arr = [];
      let total = 0;
      if (data && data.data && data.data.data && data.data.data.length > 0) {
        arr = data.data.data;
        total = data.data.total;
      }

      state.statuses = arr;
      state.total = total;
      state.isLoading = false;
    },
  };


  const apis = {
    callReadJobStatusesApi: (payload = null) => async (dispatch) => {
      dispatch(actions.startRead());
      
      let params = {
        currentPage: 1,
        pageSize: Utils.getMaxPageSize(),
      };

      await dispatchCrudApi.readApi(params, 'jobStatus').then(result => {
        let data = result.data;
        
        dispatch(actions.finishRead({ data: data, errorMessage: '', isError: false }));
      }).catch(error => {
        let isError = true;
        let errorMessage = "";

        Utils.parseError(error, (result) => {
          isError = result.isError;
          errorMessage = result.errorMessage;
        });
        
        dispatch(actions.finishRead({ data: null, errorMessage: errorMessage, isError: isError }));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();