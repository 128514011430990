import React from 'react';

import Utils from '../../../utils/utils';

import { 
    Row,
    Col,
    Form,
    OverlayTrigger,
    Popover,
    Spinner,
    Button,
    Badge,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import InfiniteScroll from 'react-infinite-scroller';
// import { Transition } from 'react-transition-group';


const StyledContainer = styled.div`
    position: relative;
`;

const StyledClose = styled.i`
    position: absolute;
    right: 8px;
    top: 13px;
    user-select: none;
    cursor: pointer;
    font-size: 18px;

    &:hover {
        opacity: 0.5;
    }
`;

const StyledArrow = styled.i`
    position: absolute;
    right: 8px;
    top: 13px;
    user-select: none;
    cursor: pointer;
    font-size: 18px;

    &:hover {
        opacity: 0.5;
    }
`;

const StyledSpinner = styled.div`
    position: absolute;
    right: 10px;
    top: 7px;
    user-select: none;
    cursor: default;
    font-size: 18px;
`;

const StyledInput = styled(Form.Control)`
    &[readonly] {
        color: ${props => {
            if(props.selecteditem && props.selecteditem[props.keyvaluecolor] && props.selecteditem[props.keyvaluecolor] != ''){
                return props.selecteditem[props.keyvaluecolor];
            } else {
                if(props.valuecolor && props.valuecolor != ''){
                    return props.valuecolor;
                } else {
                    return '#484848';
                }
            }
        }} !important;
    }

    &[disabled] {
        background-color: #EFF2F5;
        cursor: not-allowed !important;
    }
`;

const StyledMenu = styled.div`
    width: 100%;

    ${props => {
        if(!props.showsearch && !props.showfooter){
            return (props.isloading) ? 'padding-top: 10px;' : 'padding-top: 10px; padding-bottom: 10px;';
        } else {
            if(!props.showsearch){
                return 'padding-top: 10px;'
            } else if(!props.showfooter){
                return (!props.isloading) ? 'padding-bottom: 10px;' : '';
            }
        }
    }}
`;

const StyledMenuSearch = styled.div`
    padding: 10px;
    margin-bottom: 5px;
`;

const StyledMenuFooter = styled.div`
    padding: 10px 10px 8px 10px;
    margin-top: 10px;
    border-top: 1px solid #F7F7F7;
`;

const StyledMenuMoreLess = styled.div`
    padding: 0px 10px 0px 10px;
    margin-top: 10px;
    border-top: 0px;
`;

const StyledMenuAddField = styled.div`
    padding: 10px 10px 5px 10px;
`;

const StyledMenuButton = styled(Button)`
    border-radius: 20px;
    font-size: 12px;
    line-height: 5px;
    border: 1px;

    &:hover {
        opacity: 0.8 !important;
    }
`;

const StyledPopover = styled(Popover)`
    .popover-arrow {
        display: none;
    }

    li {
        &::marker {
            content: '';
        }
    }
`;

const StyledMenuList = styled.ul`
    padding: 0px 10px;
    margin: 0px;
    overflow: auto;
    
    max-height: ${props => {
        return (props.ismore && !props.isInfiniteScroll && props.showmoreless) ? (props.isshowedmore) ? '340px' : '170px' : '170px'
    }} !important;
`;

const StyledMenuListItem = styled.li`
    list-style-type: none;
    padding: 5px;
    user-select: none;
    cursor: pointer;
    font-weight: normal;
    min-height: 34px;
    display: grid;

    &:hover {
        background-color: #3699FF10;
        color: black;
    }

    &.active {
        background-color: #3699FF;
        color: white;
    }

    &.caption {
        background-color: transparent;
        color: black;
        cursor: default;
    }

    &.no-results {
        background-color: transparent;
        color: black;
        cursor: default;
    }

    &.loading {
        background-color: transparent;
        color: black;
        cursor: default;
        text-align: center;
        padding-bottom: 15px;
    }

    &.disabled {
        cursor: not-allowed;
        color: black;

        &.active {
            background-color: #3699FF !important;
            color: white !important;
        }

        &:hover {
            background-color: transparent;
        }
    }
`;

const StyledA = styled.a`
    font-weight: normal;

    &:hover {
        opacity: 0.8;
    }
`;

const StyledMoreLessA = styled.a`
    font-size: 13px !important;
    font-weight: normal !important;
    font-style: italic !important;
    text-decoration: underline;
    color: #7A7A7A !important;

    &:hover {
        opacity: 0.8;
    }
`;

const StyledMenuDeleteText = styled.span`
    font-size: 12px;
`;

const StyledSettings = styled.i`
    user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #DFDFDF;
    &:hover {
        color: #484848;
    }

    &.active {
        color: #185cff;
    }
`;

const StyledEdit = styled.i`
    user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #DFDFDF;
    visibility: vissible;

    &.hidden {
        visibility: hidden;
    }

    &:hover {
        color: #484848;
    }

    &.active-${props => props.index} {
        color: #185cff;
    }

    &.disabled-${props => props.index} {
        color: #DFDFDF;
        cursor: not-allowed;
        display: none;
    }
`;

const StyledDelete = styled.i`
    user-select: none;
    cursor: pointer;
    display: flex};
    align-items: center;
    font-size: 18px;
    color: #DFDFDF;
    visibility: vissible;

    &.hidden {
        visibility: hidden;
    }

    &:hover {
        color: #484848;
    }

    &.active-${props => props.index} {
        color: #FF0090;
    }

    &.disabled-${props => props.index} {
        color: #DFDFDF;
        cursor: not-allowed;
        display: none;
    }
`;

const StyledMenuDefaultButton = styled(Button)`
    background-color: white !important;
    border-radius: 20px;
    font-size: 10px;
    line-height: 0px;
    border: 1px;
    visibility: vissible;

    &.hidden {
        visibility: hidden;
    }

    &:hover {
        background-color: #484848 !important;
    }

    &.active-${props => props.index} {
        background-color: ${props => (props.defaultcolor && props.defaultcolor != '') ? props.defaultcolor : '#185cff'} !important;
        color: white !important;
        border-color: ${props => (props.defaultcolor && props.defaultcolor != '') ? props.defaultcolor : '#185cff'} !important;

        &:hover {
            opacity: 0.6;
        }
    }

    &.disabled-${props => props.index} {
        color: white !important;
        background-color: #484848 !important;
        border-color: #DFDFDF !important;
        cursor: not-allowed !important;
        display: none;
    }

    &.selected-${props => props.index} {
        background-color: ${props => (props.defaultcolor && props.defaultcolor != '') ? props.defaultcolor : '#185cff'} !important;
        color: white !important;
        border-color: ${props => (props.defaultcolor && props.defaultcolor != '') ? props.defaultcolor : '#185cff'} !important;

        &:hover {
            opacity: 0.6;
        }
    }
`;

const StyledMenuDefaultBadge = styled(Badge)`
    border-radius: 20px;
    font-size: 10px;
    padding-left: calc(1.5rem + 1px);
    padding-right: calc(1.5rem + 1px);

    background-color: ${props => (props.defaultcolor && props.defaultcolor != '') ? props.defaultcolor : '#185cff'} !important;
    border-color: ${props => (props.defaultcolor && props.defaultcolor != '') ? props.defaultcolor : '#185cff'} !important;
`;


const DEFAULT_SHOW = false;
const DEFAULT_DISABLED = false;
const DEFAULT_IS_LOADING = false;
const DEFAULT_IS_MAIN_VALUE = true;
const DEFAULT_IS_FREE_TEXT = false;
const DEFAULT_IS_CREATE_UPDATE_POPUP = false;
const DEFAULT_CAN_CREATE_ON_BLUR = false;
const DEFAULT_VALUE = '';
const DEFAULT_LABEL = '';
const DEFAULT_ITEM = null;
const DEFAULT_NO_RESULTS = 'No Results!';
const DEFAULT_CAPTION = '';
const DEFAULT_LOADING_TEXT = 'Loading...';
const DEFAULT_ERROR_TEXT = 'You must select item';
const DEFAULT_FOOTER_BUTTON_TEXT = '+ Add new item';
const DEFAULT_SHOW_MORE_TEXT = 'Show more...';
const DEFAULT_SHOW_LESS_TEXT = 'Show less...';
const DEFAULT_FOOTER_ICON = 'settings';
const DEFAULT_CLOSE_ICON = 'close';
const DEFAULT_ARROW_ICON = 'expand_more';
const DEFAULT_EDIT_ICON = 'edit';
const DEFAULT_DELETE_ICON = 'cancel';
const DEFAULT_SEARCH_PLACEHOLDER_TEXT = 'Search...';
const DEFAULT_ADD_PLACEHOLDER_TEXT = 'Add...';
const DEFAULT_EDIT_PLACEHOLDER_TEXT = 'Edit...';
const DEFAULT_IS_INFINITE_SCROLL = true;
const DEFAULT_PAGE_START = 0;
const DEFAULT_HAS_MORE = false;
const DEFAULT_KEY_VALUE = 'value';
const DEFAULT_KEY_LABEL = 'title';
const DEFAULT_SHOW_ADD = true;
const DEFAULT_SHOW_EDIT = true;
const DEFAULT_SHOW_DEFAULT = false;
const DEFAULT_SHOW_DELETE = true;
const DEFAULT_SHOW_SETTINGS = true;
const DEFAULT_SHOW_CLEAR = true;
const DEFAULT_SHOW_SEARCH = true;
const DEFAULT_SHOW_FOOTER = true;
const DEFAULT_SHOW_MORE_LESS = true;
const DEFAULT_SHOW_ARROW = true;
const DEFAULT_KEY_DEFAULT = 'isDefault';
const DEFAULT_KEY_DEFAULT_LABEL = 'Default';
const DEFAULT_KEY_DEFAULT_SELECTED_LABEL = 'Default';
const DEFAULT_KEY_DEFAULT_DISPLAY_LABEL = 'Default';
const DEFAULT_KEY_DEFAULT_COLOR = '';
const DEFAULT_KEY_VALUE_COLOR = 'color';
const DEFAULT_VALUE_COLOR = '';
const DEFAULT_ADD_OK_TEXT = 'Save';
const DEFAULT_ADD_CANCEL_TEXT = 'Cancel';
const DEFAULT_EDIT_OK_TEXT = 'Save';
const DEFAULT_EDIT_CANCEL_TEXT = 'Cancel';
const DEFAULT_DEFAULT_OK_TEXT = 'Okay';
const DEFAULT_DEFAULT_CANCEL_TEXT = 'Cancel';
const DEFAULT_DELETE_OK_TEXT = 'Yes';
const DEFAULT_DELETE_CANCEL_TEXT = 'No';
const DEFAULT_DELETE_TEXT = 'Are you sure?';


const SmartInputDropdown = React.forwardRef(({crudRef = null, label, item, footerIcon, closeIcon, arrowIcon, editIcon, deleteIcon, footerButtonText, showMoreText, showLessText, searchPlaceholder, errorText, noResults, caption = DEFAULT_CAPTION, loadingText, isLoading, pageStart, hasMore, keyValue, keyLabel, keyDefault, keyDefaultLabel, keyDefaultSelectedLabel, keyDefaultDisplayLabel, defaultColor, keyValueColor, valueColor, addOkText, addCancelText, editOkText, editCancelText, defaultOkText, defaultCancelText, deleteOkText, deleteCancelText, deleteText, options, onChange, onAddFreeText, onTypeFreeText, onClear, onSearch, onSearchPressEnter, onClearSearch, onLoadMore, onEnter, onEntered, onExited, addPlaceholder, editPlaceholder, onCreate, onUpdate, onDefault, onDelete, onClickAdd, onClickEdit, isInfiniteScroll = DEFAULT_IS_INFINITE_SCROLL, showAdd = DEFAULT_SHOW_ADD, showEdit = DEFAULT_SHOW_EDIT, showDefault = DEFAULT_SHOW_DEFAULT, showDelete = DEFAULT_SHOW_DELETE, showSettings = DEFAULT_SHOW_SETTINGS, showClear = DEFAULT_SHOW_CLEAR, showArrow = DEFAULT_SHOW_ARROW, showSearch = DEFAULT_SHOW_SEARCH, showFooter = DEFAULT_SHOW_FOOTER, showMoreLess = DEFAULT_SHOW_MORE_LESS, isMainValue = DEFAULT_IS_MAIN_VALUE, isFreeText = DEFAULT_IS_FREE_TEXT, canCreateOnBlur = DEFAULT_CAN_CREATE_ON_BLUR, isCreateUpdatePopup = DEFAULT_IS_CREATE_UPDATE_POPUP, ...props}, ref) => {
    const [width, setWidth] = React.useState('auto');
    
    const [show, setShow] = React.useState(props.show || DEFAULT_SHOW);
    const [_isLoading, setLoading] = React.useState(isLoading || DEFAULT_IS_LOADING);
    const [disabled, setDisabled] = React.useState(props.disabled || DEFAULT_DISABLED);
    const [value, setValue] = React.useState(props.value || DEFAULT_VALUE);
    const [_label, setLabel] = React.useState(label || DEFAULT_LABEL);
    const [_item, setItem] = React.useState(item || DEFAULT_ITEM);
    const [_noResults, setNoResults] = React.useState(noResults || DEFAULT_NO_RESULTS);
    const [_loadingText, setLoadingText] = React.useState(loadingText || DEFAULT_LOADING_TEXT);
    const [_errorText, setErrorText] = React.useState(errorText || DEFAULT_ERROR_TEXT);
    const [_searchPlaceholder, setSearchPlaceholder] = React.useState(searchPlaceholder || DEFAULT_SEARCH_PLACEHOLDER_TEXT);
    const [_footerButtonText, setFooterButtonText] = React.useState(footerButtonText || DEFAULT_FOOTER_BUTTON_TEXT);
    const [_showMoreText, setShowMoreText] = React.useState(showMoreText || DEFAULT_SHOW_MORE_TEXT);
    const [_showLessText, setShowLessText] = React.useState(showLessText || DEFAULT_SHOW_LESS_TEXT);
    const [_footerIcon, setFooterIcon] = React.useState(footerIcon || DEFAULT_FOOTER_ICON);
    const [_closeIcon, setCloseIcon] = React.useState(closeIcon || DEFAULT_CLOSE_ICON);
    const [_arrowIcon, setArrowIcon] = React.useState(arrowIcon || DEFAULT_ARROW_ICON);
    const [_editIcon, setEditIcon] = React.useState(editIcon || DEFAULT_EDIT_ICON);
    const [_deleteIcon, setDeleteIcon] = React.useState(deleteIcon || DEFAULT_DELETE_ICON);
    const [_addPlaceholder, setAddPlaceholder] = React.useState(addPlaceholder || DEFAULT_ADD_PLACEHOLDER_TEXT);
    const [_editPlaceholder, setEditPlaceholder] = React.useState(addPlaceholder || DEFAULT_EDIT_PLACEHOLDER_TEXT);
    const [_pageStart, setPageStart] = React.useState(pageStart || DEFAULT_PAGE_START);
    const [_hasMore, setHasMore] = React.useState(hasMore || DEFAULT_HAS_MORE);
    const [_keyValue, setKeyValue] = React.useState(keyValue || DEFAULT_KEY_VALUE);
    const [_keyLabel, setKeyLabel] = React.useState(keyLabel || DEFAULT_KEY_LABEL);
    const [_keyDefault, setKeyDefault] = React.useState(keyDefault || DEFAULT_KEY_DEFAULT);
    const [_keyDefaultLabel, setKeyDefaultLabel] = React.useState(keyDefaultLabel || DEFAULT_KEY_DEFAULT_LABEL);
    const [_keyDefaultSelectedLabel, setKeyDefaultSelectedLabel] = React.useState(keyDefaultSelectedLabel || DEFAULT_KEY_DEFAULT_SELECTED_LABEL);
    const [_keyDefaultDisplayLabel, setKeyDefaultDisplayLabel] = React.useState(keyDefaultDisplayLabel || DEFAULT_KEY_DEFAULT_DISPLAY_LABEL);
    const [_defaultColor, setDefaultColor] = React.useState(defaultColor || DEFAULT_KEY_DEFAULT_COLOR);
    const [_keyValueColor, setKeyValueColor] = React.useState(keyValueColor || DEFAULT_KEY_VALUE_COLOR);
    const [_valueColor, setValueColor] = React.useState(valueColor || DEFAULT_VALUE_COLOR);
    const [_addOkText, setAddOkText] = React.useState(addOkText || DEFAULT_ADD_OK_TEXT);
    const [_addCancelText, setAddCancelText] = React.useState(addCancelText || DEFAULT_ADD_CANCEL_TEXT);
    const [_editOkText, setEditOkText] = React.useState(editOkText || DEFAULT_EDIT_OK_TEXT);
    const [_editCancelText, setEditCancelText] = React.useState(editCancelText || DEFAULT_EDIT_CANCEL_TEXT);
    const [_defaultOkText, setDefaultOkText] = React.useState(defaultOkText || DEFAULT_DEFAULT_OK_TEXT);
    const [_defaultCancelText, setDefaultCancelText] = React.useState(defaultCancelText || DEFAULT_DEFAULT_CANCEL_TEXT);
    const [_deleteOkText, setDeleteOkText] = React.useState(deleteOkText || DEFAULT_DELETE_OK_TEXT);
    const [_deleteCancelText, setDeleteCancelText] = React.useState(deleteCancelText || DEFAULT_DELETE_CANCEL_TEXT);
    const [_deleteText, setDeleteText] = React.useState(deleteText || DEFAULT_DELETE_TEXT);

    const [freeTextValue, setFreeTextValue] = React.useState('');
    const [selectedValue, setSelectedValue] = React.useState(null);
    const [isCreate, setIsCreate] = React.useState(false);
    const [isUpdate, setIsUpdate] = React.useState(false);
    const [isDelete, setIsDelete] = React.useState(false);
    const [isDefault, setIsDefault] = React.useState(false);
    const [isSettings, setIsSettings] = React.useState(false);

    const [addValue, setAddValue] = React.useState(null);
    const [editValue, setEditValue] = React.useState(null);

    const [isShowedMore, setIsShowedMore] = React.useState(false);

    const searchRef = React.useRef(null);
    const inputRef = React.useRef(null);

    
    React.useImperativeHandle(
        ref,
        () => ({
            getActive(item) {
                return getActive(item);
            },
            getValue() {
                return getValue();
            },
            getSelectedItem() {
                return getSelectedItem();
            },
            selectItem(value = null, label = '', isDefault = false) {
                return selectItem(value, label, isDefault);
            },
            getInputWidth() {
                return getInputWidth();
            },
            searchFocus() {
                searchFocus();
            },
            getSearchValue() {
                getSearchValue();
            },
            toggleDropdown() {
                toggleDropdown();
            },
         }),
    );

    React.useEffect(() => {
        if(!isLoading && show){
            searchFocus();
        }
    });


    React.useEffect(() => {
        selectItem(props.value, _label, ((_item && _item[_keyDefault]) ? _item[_keyDefault] : false));
    }, [options]);

    React.useEffect(() => {
        setLoading(isLoading || DEFAULT_IS_LOADING)
    }, [isLoading]);

    React.useEffect(() => {
        setDisabled(props.disabled || DEFAULT_DISABLED)
        if(show){
            setShow(false);
        }
    }, [props.disabled]);

    React.useEffect(() => {
        setValue(props.value || DEFAULT_VALUE)
    }, [props.value]);

    React.useEffect(() => {
        setLabel(label || DEFAULT_LABEL)
    }, [label]);

    React.useEffect(() => {
        setItem(item || DEFAULT_ITEM)
    }, [item]);

    React.useEffect(() => {
        setNoResults(props.noResults || DEFAULT_NO_RESULTS)
    }, [props.noResults]);

    React.useEffect(() => {
        setLoadingText(props.loadingText || DEFAULT_LOADING_TEXT)
    }, [props.loadingText]);

    React.useEffect(() => {
        setErrorText(errorText || DEFAULT_ERROR_TEXT)
    }, [errorText]);

    React.useEffect(() => {
        setSearchPlaceholder(searchPlaceholder || DEFAULT_SEARCH_PLACEHOLDER_TEXT)
    }, [searchPlaceholder]);

    React.useEffect(() => {
        setFooterButtonText(footerButtonText || DEFAULT_FOOTER_BUTTON_TEXT)
    }, [footerButtonText]);

    React.useEffect(() => {
        setShowMoreText(showMoreText || DEFAULT_SHOW_MORE_TEXT)
    }, [showMoreText]);

    React.useEffect(() => {
        setShowLessText(showLessText || DEFAULT_SHOW_LESS_TEXT)
    }, [showLessText]);

    React.useEffect(() => {
        setFooterIcon(footerIcon || DEFAULT_FOOTER_ICON)
    }, [footerIcon]);
    
    React.useEffect(() => {
        setCloseIcon(closeIcon || DEFAULT_CLOSE_ICON)
    }, [closeIcon]);

    React.useEffect(() => {
        setArrowIcon(arrowIcon || DEFAULT_ARROW_ICON)
    }, [arrowIcon]);
    
    React.useEffect(() => {
        setEditIcon(editIcon || DEFAULT_EDIT_ICON)
    }, [editIcon]);
    
    React.useEffect(() => {
        setDeleteIcon(deleteIcon || DEFAULT_DELETE_ICON)
    }, [deleteIcon]);

    React.useEffect(() => {
        setPageStart(pageStart || DEFAULT_PAGE_START)
    }, [pageStart]);

    React.useEffect(() => {
        setHasMore(hasMore || DEFAULT_HAS_MORE)
    }, [hasMore]);

    React.useEffect(() => {
        setKeyValue(keyValue || DEFAULT_KEY_VALUE)
    }, [keyValue]);

    React.useEffect(() => {
        setKeyLabel(keyLabel || DEFAULT_KEY_LABEL)
    }, [keyLabel]);

    React.useEffect(() => {
        setKeyDefault(keyDefault || DEFAULT_KEY_DEFAULT)
    }, [keyDefault]);

    React.useEffect(() => {
        setKeyDefaultLabel(keyDefaultLabel || DEFAULT_KEY_DEFAULT_LABEL)
    }, [keyDefaultLabel]);

    React.useEffect(() => {
        setKeyDefaultSelectedLabel(keyDefaultSelectedLabel || DEFAULT_KEY_DEFAULT_SELECTED_LABEL)
    }, [keyDefaultSelectedLabel]);

    React.useEffect(() => {
        setKeyDefaultDisplayLabel(keyDefaultDisplayLabel || DEFAULT_KEY_DEFAULT_DISPLAY_LABEL)
    }, [keyDefaultDisplayLabel]);

    React.useEffect(() => {
        setKeyValueColor(keyValueColor || DEFAULT_KEY_VALUE_COLOR)
    }, [keyValueColor]);

    React.useEffect(() => {
        setDefaultColor(defaultColor || DEFAULT_KEY_DEFAULT_COLOR)
    }, [defaultColor]);

    React.useEffect(() => {
        setValueColor(valueColor || DEFAULT_VALUE_COLOR)
    }, [valueColor]);

    React.useEffect(() => {
        setAddPlaceholder(addPlaceholder || DEFAULT_ADD_PLACEHOLDER_TEXT)
    }, [addPlaceholder]);

    React.useEffect(() => {
        setEditPlaceholder(editPlaceholder || DEFAULT_EDIT_PLACEHOLDER_TEXT)
    }, [editPlaceholder]);

    React.useEffect(() => {
        setAddOkText(addOkText || DEFAULT_ADD_OK_TEXT)
    }, [addOkText]);

    React.useEffect(() => {
        setAddCancelText(addCancelText || DEFAULT_ADD_CANCEL_TEXT)
    }, [addCancelText]);

    React.useEffect(() => {
        setEditOkText(editOkText || DEFAULT_EDIT_OK_TEXT)
    }, [editOkText]);

    React.useEffect(() => {
        setEditCancelText(editCancelText || DEFAULT_EDIT_CANCEL_TEXT)
    }, [editCancelText]);

    React.useEffect(() => {
        setDefaultOkText(defaultOkText || DEFAULT_DEFAULT_OK_TEXT)
    }, [defaultOkText]);

    React.useEffect(() => {
        setDefaultCancelText(defaultCancelText || DEFAULT_DEFAULT_CANCEL_TEXT)
    }, [defaultCancelText]);

    React.useEffect(() => {
        setDeleteOkText(deleteOkText || DEFAULT_DELETE_OK_TEXT)
    }, [deleteOkText]);

    React.useEffect(() => {
        setDeleteCancelText(deleteCancelText || DEFAULT_DELETE_CANCEL_TEXT)
    }, [deleteCancelText]);

    React.useEffect(() => {
        setDeleteText(deleteText || DEFAULT_DELETE_TEXT)
    }, [deleteText]);


    const getActive = (item) => {
        let active = '';
        
        if(isMainValue){
            active = (value != null && value != '' && value === item[_keyValue]) ? 'active' : '';
        } else {
            active = (_label != null && _label != '' && _label === item[_keyLabel]) ? 'active' : '';
        }
        
        return active;
    }

    const getValue = () => {
        let lbl = '';
        if(freeTextValue != ''){
            lbl = freeTextValue
        } else {
            if(isMainValue){
                lbl = (value && value != '') ? _label : '';
            } else {
                lbl = _label;
            }
            
            if(options && options.length > 0){
                let items = null;

                if(isMainValue){
                    items = options.filter(x => x[_keyValue] == value);
                } else {
                    items = options.filter(x => x[_keyLabel] == _label);
                }

                if(items && items.length > 0){
                    lbl = items[0][_keyLabel];
                }
            }
        }

        return lbl;
    }
    
    const getSelectedItem = () => {
        let item = null;

        if(options && options.length > 0){
            let items = null;

            if(isMainValue){
                items = options.filter(x => x[_keyValue] == value);
            } else {
                items = options.filter(x => x[_keyLabel] == _label);
            }
            
            if(items && items.length > 0){
                item = items[0];
            }
        }

        return item;
    }

    const selectItem = (value = null, label = '', isDefault = false) => {
        if(value){
            let item = {
                [_keyValue]: value,
                [_keyLabel]: label,
            };

            if(showDefault && _keyDefault && _keyDefault != ''){
                item[_keyDefault] = isDefault;
            }

            if(options && options.length > 0){
                Utils.addToArrayIfNotExist(options, value, item);
            } else {
                options.unshift(item);
            }
        }
    }

    const getInputWidth = () => {
        if(inputRef && inputRef.current){
            return inputRef.current.clientWidth + 'px';
        } else {
            return 'auto';
        }
    }
    
    const searchFocus = () => {
        if(isFreeText && (addValue == null || addValue == '') && (editValue == null || editValue == '')){
            if(inputRef && inputRef.current){
                inputRef.current.focus();
            }
        } else {
            if(searchRef && searchRef.current){
                searchRef.current.focus();
            }
        }
    }

    const getSearchValue = () => {
        if(searchRef && searchRef.current){
            return searchRef.current.value;
        }
    }

    const toggleDropdown = () => {
        if(inputRef && inputRef.current){
            inputRef.current.click();
        }
    }


    const setMenu = (canShowMore = false) => {
        if(!_isLoading){
            if(options && options.length > 0){
                let backData = [];

                backData = options.map((item, i) => {
                    if(canShowMore && showMoreLess && !isShowedMore){
                        if(i > 4){
                            return null;
                        }
                    }

                    return <StyledMenuListItem
                        key={i}
                        className={getActive(item) + ' ' + ((isCreate || isUpdate || isDefault || isDelete) ? 'disabled' : '')}
                        onClick={() => {
                            if(!(isCreate || isUpdate || isDefault || isDelete)){
                                setValue(item[_keyValue]);
                                setFreeTextValue('');
                                toggleDropdown();

                                if(onChange){
                                    onChange(item[_keyValue], item, i);
                                }
                            }
                        }}
                    >
                        <Row className={'align-items-center gx-2'}>
                            {
                                (isUpdate && (selectedValue && selectedValue[_keyValue] == item[_keyValue]))
                                ?
                                <Col xs={true}>
                                    <Form.Control
                                        defaultValue={item[_keyLabel]}
                                        placeholder={_editPlaceholder}
                                        isInvalid={editValue == ''}
                                        autoFocus
                                        onChange={(e) => {
                                            setEditValue(e.target.value);
                                        }}
                                        onKeyDown={(e) => {
                                            if ((e.charCode || e.keyCode) === 13) {
                                                e.preventDefault();
                                                e.stopPropagation();
                            
                                                if(editValue){
                                                    if(onUpdate){
                                                        onUpdate(editValue, selectedValue);
                                                        setEditValue(null);
                                                        setSelectedValue(null);
                                                        setIsUpdate(false);
                                                        setIsSettings(false);
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                </Col>
                                :
                                <>
                                    <Col xs={12} sm={'auto'} className={'d-flex'}>{(props && props.itemRender) ? props.itemRender(item[_keyLabel], item, _keyLabel) : item[_keyLabel]}</Col>
                                    {(showDefault && !isSettings && item[_keyDefault]) && <Col xs={'auto'}>
                                        <StyledMenuDefaultBadge pill bg={'primary'} defaultcolor={_defaultColor}>{_keyDefaultDisplayLabel}</StyledMenuDefaultBadge>
                                    </Col>}
                                </>
                            }

                            {isSettings && <>
                                {
                                    !(isUpdate && (selectedValue && selectedValue[_keyValue] == item[_keyValue]))
                                    &&
                                    <>
                                        {showEdit && <Col xs={true} md={'auto'}>
                                            <StyledEdit
                                                index={item[_keyValue]}
                                                className={'material-icons ' + (((!isUpdate && !isDefault && !isDelete) || isUpdate) ? '' : 'hidden')  + ' ' + (isUpdate ? (selectedValue && selectedValue[_keyValue] == item[_keyValue]) ? 'active-' + item[_keyValue] : 'disabled-' + item[_keyValue] : '')}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();

                                                    if(isCreateUpdatePopup){
                                                        if((!isDefault && !isDelete)){
                                                            if(onClickEdit){
                                                                onClickEdit(item, crudRef);
                                                            }
                                                        }
                                                    } else {
                                                        if((!isDefault && !isDelete)){
                                                            setSelectedValue(item);
                                                            setIsUpdate(!isUpdate);
                                                        }
                                                    }
                                                }}
                                            >{_editIcon}</StyledEdit>
                                        </Col>}

                                        <Col xs={true} md={true} className={'d-none d-md-flex'}></Col>

                                        {showDefault && <Col xs={'auto'} md={'auto'}>
                                            <StyledMenuDefaultButton
                                                index={item[_keyValue]}
                                                className={(((!isUpdate && !isDefault && !isDelete) || isDefault) ? '' : 'hidden') + ' ' + (isDefault ? (selectedValue && selectedValue[_keyValue] == item[_keyValue]) ? 'active-' + item[_keyValue] : 'disabled-' + item[_keyValue] : '') + ' ' + (item[_keyDefault] ? 'selected-' + item[_keyValue] : '')}
                                                variant={'outline-secondary'}
                                                defaultcolor={_defaultColor}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();

                                                    if((!isUpdate && !isDefault && !isDelete) || isDefault){
                                                        setSelectedValue(item);
                                                        setIsDefault(!isDefault);
                                                    }
                                                }}
                                            >{item[_keyDefault] ? _keyDefaultSelectedLabel : _keyDefaultLabel}</StyledMenuDefaultButton>
                                        </Col>}

                                        {showDelete && <Col xs={'auto'} md={'auto'}>
                                            <StyledDelete
                                                index={item[_keyValue]}
                                                className={'material-icons ' + (((!isUpdate && !isDefault && !isDelete) || isDelete) ? '' : 'hidden') + ' ' + (isDelete ? (selectedValue && selectedValue[_keyValue] == item[_keyValue]) ? 'active-' + item[_keyValue] : 'disabled-' + item[_keyValue] : '')}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();

                                                    if((!isUpdate && !isDefault && !isDelete) || isDelete){
                                                        setSelectedValue(item);
                                                        setIsDelete(!isDelete);
                                                    }
                                                }}
                                            >{_deleteIcon}</StyledDelete>
                                        </Col>}
                                    </>
                                }
                            </>}
                        </Row>
                    </StyledMenuListItem>
                });
                

                if(caption != ''){
                    backData.unshift(<StyledMenuListItem className={'caption align-items-center caption'} key={'-1'}><b>{caption}</b></StyledMenuListItem>)
                }

                return backData;
            } else {
                return <StyledMenuListItem className={'no-results'}>{_noResults}</StyledMenuListItem>
            }
        } else {
            return <StyledMenuListItem className={'loading'}>
                <Row className={'align-items-center justify-content-center'}>
                    <Col xs={'auto'}>{_loadingText}</Col>
                    <Col xs={'auto'}><Spinner animation="border" size={'sm'} /></Col>
                </Row>
            </StyledMenuListItem>
        }
    }

    const setSearch = () => {
        return <Form.Control 
            ref={searchRef}
            type={'search'}
            autoFocus
            // disabled={_isLoading || ((options.length == 0) && (getSearchValue() == ''))}
            disabled={((options.length == 0) && (getSearchValue() == ''))}
            placeholder={_searchPlaceholder}
            onChange={(e) => {
                if(e.target.value != ''){
                    if(onSearch){
                        onSearch(e.target.value);
                    }
                } else {
                    if(onClearSearch){
                        onClearSearch();
                    }
                }
            }}
            // onKeyDown={(e) => {
            //     if ((e.charCode || e.keyCode) === 13) {
            //         e.preventDefault();
            //         e.stopPropagation();

            //         if(onSearchPressEnter){
            //             onSearchPressEnter(e.target.value);
            //         }
            //     }
            // }}
            onKeyUp={(e) => {
                e.preventDefault();
                e.stopPropagation();

                if(onSearchPressEnter){
                    if(e.target.value.length > 1){
                        onSearchPressEnter(e.target.value);
                    }
                }
            }}
        />
    }

    const setFooter = () => {
        if(isCreate){
            return setFooterAdd();
        } else if(isUpdate){
            return setFooterUpdate();
        } else if(isDefault){
            return setFooterDefault();
        } else if(isDelete){
            return setFooterDelete();
        } else {
            return <Row className={'align-items-center'}>
                <Col xs={true}>
                    {showAdd && !isSettings && <StyledA href={'/'}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            
                            let searchVal = getSearchValue();
                            let search = (searchVal && searchVal != '') ? searchVal : null;

                            if(isCreateUpdatePopup){
                                if(onClickAdd){
                                    onClickAdd(crudRef, search);
                                }
                            } else {
                                setIsCreate(true);
                                setAddValue(search);
                            }
                        }}
                    >{_footerButtonText}</StyledA>}
                </Col>
                {(showSettings && options && options.length > 0) && <Col xs={'auto'}>
                    <StyledSettings
                        className={'material-icons ' + (isSettings ? 'active' : '')}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            setIsSettings(!isSettings);
                        }}
                    >{_footerIcon}</StyledSettings>
                </Col>}
            </Row>
        }
    }

    const setFooterAdd = () => {
        return <Row className={'align-items-center'}>
            <Col xs={'auto'}>
                <StyledMenuButton 
                    variant={'primary'}
                    disabled={addValue ? false : true}
                    onClick={() => {
                        if(addValue != ''){
                            if(onCreate){
                                onCreate(addValue);
                                setAddValue(null);
                                setSelectedValue(null);
                                setIsCreate(false);
                            }
                        }
                    }}
                >{_addOkText}</StyledMenuButton>
            </Col>
            <Col xs={'auto'}>
                <StyledMenuButton
                    variant={'outline-secondary'}
                    onClick={() => {
                        setAddValue(null);
                        setSelectedValue(null);
                        setIsCreate(false);
                    }}
                >{_addCancelText}</StyledMenuButton>
            </Col>
        </Row>
    }

    const setFooterUpdate = () => {
        return <Row className={'align-items-center'}>
            <Col xs={'auto'}>
                <StyledMenuButton 
                    variant={'primary'}
                    disabled={editValue ? false : true}
                    onClick={() => {
                        if(editValue != ''){
                            if(onUpdate){
                                onUpdate(editValue, selectedValue);
                                setEditValue(null);
                                setSelectedValue(null);
                                setIsUpdate(false);
                                setIsSettings(false);
                            }
                        }
                    }}
                >{_editOkText}</StyledMenuButton>
            </Col>
            <Col xs={'auto'}>
                <StyledMenuButton
                    variant={'outline-secondary'}
                    onClick={() => {
                        setEditValue(null);
                        setSelectedValue(null);
                        setIsUpdate(false);
                    }}
                >{_editCancelText}</StyledMenuButton>
            </Col>
        </Row>
    }

    const setFooterDefault = () => {
        return <Row className={'align-items-center'}>
            <Col xs={'auto'}>
                <StyledMenuButton 
                    variant={'primary'}
                    onClick={() => {
                        if(selectedValue){
                            if(onDefault){
                                onDefault(selectedValue);
                                setSelectedValue(null);
                                setIsDefault(false);
                                setIsSettings(false);
                            }
                        }
                    }}
                >{_defaultOkText}</StyledMenuButton>
            </Col>
            <Col xs={'auto'}>
                <StyledMenuButton
                    variant={'outline-secondary'}
                    onClick={() => {
                        setSelectedValue(null);
                        setIsDefault(false);
                    }}
                >{_defaultCancelText}</StyledMenuButton>
            </Col>
        </Row>
    }

    const setFooterDelete = () => {
        return <Row className={'align-items-center'}>
            <Col xs={'auto'}>
                <StyledMenuButton 
                    variant={'danger'}
                    onClick={() => {
                        if(selectedValue){
                            if(onDelete){
                                onDelete(selectedValue);
                                setSelectedValue(null);
                                setIsDelete(false);
                                setIsSettings(false);
                            }
                        }
                    }}
                >{_deleteOkText}</StyledMenuButton>
            </Col>
            <Col xs={'auto'}>
                <StyledMenuButton
                    variant={'outline-secondary'}
                    onClick={() => {
                        setSelectedValue(null);
                        setIsDelete(false);
                    }}
                >{_deleteCancelText}</StyledMenuButton>
            </Col>
            <Col xs={'auto'}>
                <StyledMenuDeleteText>{_deleteText}</StyledMenuDeleteText>
            </Col>
        </Row>
    }


    return <StyledContainer>
        <OverlayTrigger
            rootClose 
            trigger={'click'}
            placement={'auto'}
            show={show}
            onToggle={(isShow) => {
                setShow(isShow);
            }}
            onEnter={() => { 
                if(onEnter){
                    onEnter();
                }

                if(!disabled){
                    setWidth(getInputWidth());
                }
            }}
            onEntered={() => { 
                if(onEntered){
                    onEntered();
                }
            }}
            onExited={() => { 
                setSelectedValue(null);
                setAddValue(null);
                setEditValue(null);
                setIsCreate(false);
                setIsUpdate(false);
                setIsDelete(false);
                setIsDefault(false);
                setIsSettings(false);

                if(onExited){
                    onExited();
                }
            }}
            overlay={
                <StyledPopover style={{ maxWidth: '100%', width: width }}>
                    <StyledMenu
                        isloading={_isLoading}
                        showsearch={showSearch}
                        showfooter={showFooter}
                    >
                        {showSearch && (!isCreate && !isUpdate && !isDefault && !isDelete) && <StyledMenuSearch>{setSearch()}</StyledMenuSearch>}
                        
                        <StyledMenuList
                            isinfinitescroll={isInfiniteScroll}
                            showmoreless={showMoreLess}
                            isshowedmore={isShowedMore}
                            ismore={(options && options.length > 5)}
                        >
                            {
                                (!_isLoading && isInfiniteScroll)
                                ?
                                <InfiniteScroll
                                    loader={<div key={0} className={'text-center pt-2'}><Spinner animation="border" size={'sm'} /></div>}
                                    useWindow={false}
                                    initialLoad={false}
                                    threshold={1}

                                    pageStart={_pageStart}
                                    hasMore={(options && options.length > 0) && _hasMore}

                                    loadMore={(page) => {
                                        if(onLoadMore){
                                            onLoadMore(page);
                                        }
                                    }}
                                >
                                    {setMenu(false)}
                                </InfiniteScroll>
                                :
                                setMenu(true)
                            }
                        </StyledMenuList>

                        {isCreate && <StyledMenuAddField>
                            <Form.Control
                                value={addValue ? addValue : ''}
                                placeholder={_addPlaceholder}
                                isInvalid={addValue == ''}
                                autoFocus
                                onChange={(e) => {
                                    setAddValue(e.target.value);
                                }}
                                onKeyDown={(e) => {
                                    if ((e.charCode || e.keyCode) === 13) {
                                        e.preventDefault();
                                        e.stopPropagation();
                    
                                        if(addValue){
                                            if(onCreate){
                                                onCreate(addValue);
                                                setAddValue(null);
                                                setSelectedValue(null);
                                                setIsCreate(false);
                                            }
                                        }
                                    }
                                }}
                            />
                        </StyledMenuAddField>}

                        {(!_isLoading && !isInfiniteScroll && showMoreLess && (options && options.length > 5)) && <StyledMenuMoreLess>
                            <StyledMoreLessA href={'/'}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    
                                    setIsShowedMore(!isShowedMore);
                                }}
                            >{isShowedMore ? _showLessText : _showMoreText}</StyledMoreLessA>
                        </StyledMenuMoreLess>}

                        {(!_isLoading && showFooter) && <StyledMenuFooter>{setFooter()}</StyledMenuFooter>}
                    </StyledMenu>
                </StyledPopover>
            }
            popperConfig={{
                modifiers: [
                    {
                        name: 'flip',
                        options: {
                            allowedAutoPlacements: ['top', 'bottom'],
                        },
                    },
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 0],
                        },
                    },
                ],
            }}
        >
            <StyledInput
                ref={inputRef}
                {...props}
                readOnly={!isFreeText}
                value={getValue()}
                selecteditem={getSelectedItem()}
                keyvaluecolor={_keyValueColor}
                valuecolor={_valueColor}
                onChange={(e) => {
                    if(isFreeText){
                        setFreeTextValue(e.target.value);

                        if(e.target.value == ''){
                            if(onClear){
                                onClear();
                                setShow(false);
                            }
                        }
                    }
                }}
                onBlur={() => {
                    if(isFreeText && canCreateOnBlur){
                        if(freeTextValue != ''){
                            if(onAddFreeText){
                                onAddFreeText(freeTextValue);
                                setFreeTextValue('');
                            }
                        }
                    } else if(isFreeText && !canCreateOnBlur){
                        if(freeTextValue != ''){
                            if(onTypeFreeText){
                                onTypeFreeText(freeTextValue);
                                setFreeTextValue('');
                            }
                        }
                    }
                }}
            />
        </OverlayTrigger>
        
        {(showClear && !_isLoading && !disabled && (value != null && value != '' || freeTextValue != '')) && <StyledClose
            className={'material-icons'}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                
                setValue('');
                setFreeTextValue('');
                
                if(onClear){
                    onClear();
                    setShow(false);
                }
            }}
        >
            {_closeIcon}
        </StyledClose>}
        
        {(showArrow && !_isLoading && !disabled && (value == null || value == '') && (isFreeText && freeTextValue == '') || !showClear) && <StyledArrow
            className={'material-icons'}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                
                setShow(!show);
            }}
        >
            {_arrowIcon}
        </StyledArrow>}

        {(_isLoading && !disabled) && <StyledSpinner>
            <Spinner animation="border" size={'sm'} />    
        </StyledSpinner>}

        <Form.Control.Feedback type="invalid">{_errorText}</Form.Control.Feedback>
    </StyledContainer>
});


export default SmartInputDropdown;
