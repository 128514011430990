/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */

import React, {FC} from 'react'
import {Redirect, Switch, Route} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'
import AuthPage from '../views/account/layout/AuthPage'
import Logout from '../views/account/Logout'
import {ErrorsPage} from '../views/errors/layout/ErrorsPage'
import {LoginT} from '../views/account/LoginT'
import {RootState} from '../../setup'
import {MasterInit} from '../../_metronic/layout/MasterInit'
import {Maintenance} from "../views/errors/layout/Maintenance";
import {dispatchMaintenance} from '../../app/api/auth/api'
import PublicIframe from "../views/jobs/PublicIframe";


const Routes: FC = () => {
  const user = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const isAuthorized = (user) ? true : false;

  const [isLoadingMaintenance, setLoadingMaintenance] = React.useState(true);
  const [isMaintenance, setMaintenance] = React.useState(false);
  
  React.useEffect(() => {
    const isMaintenance = async () => {
      setLoadingMaintenance(true);
      const { data: isMaintenance }  = await dispatchMaintenance()
      setMaintenance((isMaintenance === "True"));
      setLoadingMaintenance(false);
    }

    isMaintenance()
  }, [])

  return (
    <>
      
        {
          isLoadingMaintenance
          ?
          null
          : 
          isMaintenance
          ?
          <Switch>
            <Route path="*" component={Maintenance} />
          </Switch>
          :
          <Switch>
            <Route path="/Sign-in-t" component={LoginT} />

            {!isAuthorized && <Route>
              <AuthPage />
            </Route>}

            <Route path='/error' component={ErrorsPage} />
            <Route path='/logout' component={Logout} />
            <Route path="/pub/job/preview" component={PublicIframe} />

            {!isAuthorized ? (
              <Redirect to='/login' />
            ) : (
              <MasterLayout>
                <PrivateRoutes />
              </MasterLayout>
            )}
          </Switch>
        }
      
      <MasterInit />
    </>
  )
}

export {Routes}
