import React from 'react';

import { 
  Container,
  Spinner,
  Row,
  Col,
} from "react-bootstrap-v5";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

const ViewLayout = React.forwardRef((props, ref) => {
  
  return <BlockUi 
    className={!props.renderChildren ? 'custom-block-ui-manage' : ''} 
    tag={'div'} 
    keepInView 
    blocking={props.isBlocking}
    renderChildren={props.renderChildren} 
    loader={<Spinner animation="border" />}
  >
    <div className={'view-layout'}>
      <div className={'sections'}>
        <div className={'custom-top-section shadow-sm'}
          style={{ 
            top: 65 + 'px',
            zIndex: '2'
          }}
        >
          <Container className={'py-8'}>
            <Row className="view-top-section-layout align-items-center">
              {props.title && <Col xs={'auto'}>
                <h1 className={'title mx-0'}>{props.title ? props.title : ''}</h1>
              </Col>}
              {props.col}
            </Row>
          </Container>
        </div>

        <div className={'bg-white ps-5 pe-5'}>
          <div className={'bg-color ps-5 pe-5'}>
              {props.actionSection && <div className={'custom-action-section'}>
                <Container className={'pt-3 pb-3 px-0'}>{props.actionSection}</Container>
              </div>}

              <div className={'bg-white ' + ((props.cardClassName && props.cardClassName !== '') ? props.cardClassName : 'ps-5 pe-5')}>
                  {props.toolbarSection && <div className={'custom-toolbar-section'}>
                    <Container className={'pt-5 pb-0 ' + ((props.cardClassName && props.cardClassName !== '') ? props.cardClassName : 'ps-5 pe-5')}>{props.toolbarSection}</Container>
                  </div>}

                  {props.tableSection && <div className={'custom-table-section'}>
                    <Container className={'pt-5 pb-5 ' + ((props.cardClassName && props.cardClassName !== '') ? props.cardClassName : 'ps-5 pe-5')}>{props.tableSection}</Container>
                  </div>}

                  {props.pageSection && <div className={'custom-page-section'}>
                    <Container className={'pt-5 pb-5 ' + ((props.cardClassName && props.cardClassName !== '') ? props.cardClassName : 'ps-5 pe-5')}>{props.pageSection}</Container>
                  </div>}

              </div>

              {props.paginationSection && <div className={'custom-pagination-section bg-color'}>
                <Container className={'pt-5 pb-5 ps-5 pe-5'}>{props.paginationSection}</Container>
              </div>}
          </div>

          {props.footerSection && <div className={'custom-footer-section bg-color'}>
            <Container className={'pt-5 pb-5 ps-5 pe-5'}>{props.footerSection}</Container>
          </div>}
        </div>
      </div>
    </div>
  </BlockUi>
});


export default ViewLayout;