import React from 'react';
import _ from 'lodash';
import CurrencyInput from 'react-currency-input-field';
import InputMask from 'react-input-mask';
import moment from 'moment';

import {
    Row,
    Col,
    Form,
    Spinner,
} from "react-bootstrap-v5";

import Utils from '../../../../utils/utils';
// import {  } from '../../../../utils/enums';

import SmartInputDropdown from '../../../../components/dropdown/smartInputDropdown/SmartInputDropdown';
import makeCRUD from "../../../../components/dropdown/smartInputDropdown/makeCRUD";
import signalRslice from "../../../../components/signalR/signalRslice";
import reduxPopupSlice from "../../../../components/popup/reduxPopupSlice";
import reduxDeletePopupSlice from "../../../../components/popup/reduxDeletePopupSlice";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../../../../setup'
import driversDetails, { useFormikContext, fields, isValidDates, MonthlyRowStruct, monthlyRow, AdHocRowStruct, adHocRow } from '../redux/driversDetails';

import { StyledTableWrapper, StyledTable, StyledCheckLink, StyledUncheckLink, StyledMonthlyAdHocButton, StyledButton, StyledExportButton, StyledMonthlyTitle, StyledMonthlyPrice } from '../style/driversDetailsStyles';
import { StyledLinkButton, StyledgraduatedPayText } from '../../Payroll/style/styles';


const SmartDropdown = makeCRUD(SmartInputDropdown);


const SalaryForm = (props: any) => {
    const { values, errors, setFieldValue, touched, setFieldTouched, validateForm } = useFormikContext();

    const dispatch = useDispatch();
    const { id, details, months } = useSelector((state: RootState) => state.driversDetails);

    const [payrollTemplateId, setPayrollTemplateId] = React.useState(null);
    const [payrollTemplateName, setPayrollTemplateName] = React.useState('');


    const getMonthySubTotal = (data: any, field: string) => {
        let total: any = 0;

        if(data && data.details && data.details.length > 0){
            data.details.forEach((item: any) => {
                let val = Utils.isNumber(item[field]) ? parseFloat(item[field]) : 0;
                total = total + val;
            })
        }

        return Utils.formatNumber(total);
    }
    const getMonthyTotal = () => {
        let total: any = 0;

        if(values && values.data && values.data.days && values.data.days.length > 0){
            values.data.days.forEach((day: any) => {
                if(day && day.details && day.details.length > 0){
                    day.details.forEach((day: any) => {
                        let tripPay = Utils.isNumber(day['tripPay']) ? parseFloat(day['tripPay']) : 0;
                        let incentivePay = Utils.isNumber(day['incentivePay']) ? parseFloat(day['incentivePay']) : 0;
                        let currentTotal = tripPay + incentivePay;

                        total = total + currentTotal;
                    })
                }
            })
        }

        return Utils.formatNumber(total);
    }
    
    const getAdHocSubTotal = (data: any, field: string) => {
        let total: any = 0;

        if(data && data.adhocDetails && data.adhocDetails.length > 0){
            data.adhocDetails.forEach((item: any) => {
                let val = Utils.isNumber(item[field]) ? parseFloat(item[field]) : 0;
                total = total + val;
            })
        }

        return Utils.formatNumber(total);
    }
    const getAdHocTotal = (field: string) => {
        let total: any = 0;

        if(values && values.data && values.data.adhocDetails && values.data.adhocDetails.length > 0){
            values.data.adhocDetails.forEach((day: any) => {
                let val = Utils.isNumber(day[field]) ? parseFloat(day[field]) : 0;
                total = total + val;
            })
        }

        return Utils.formatNumber(total);
    }


    const runForm = () => {
        return <Row>
            <Col xs={12}>
                <hr style={{ opacity: '1', borderColor: '#EFEFEF', marginTop: '20px', marginBottom: '20px' }} />
            </Col>
            <Col xs={true} md={4} lg={3} xl={2}>
                <Form.Group as={Row} className={'mb-3'}>
                    <Form.Label column sm={12}>{fields.payrollTemplateId.label}</Form.Label>
                    <Col sm={12}>
                        <SmartDropdown
                            // isInvalid={!!errors.payrollTemplateId}
                            // errorText={errors.payrollTemplateId}

                            componentTitle={'payrollTemplate'}
                            componentApi={'payrollTemplate'}
                            componentId={'payrollTemplateId'}
                            componentName={'templateName'}

                            placeholder={fields.payrollTemplateId.placeholder}
                            value={payrollTemplateId}
                            label={payrollTemplateName}

                            disabled={false}
                            showDefault={false}
                            isInfiniteScroll={false}
                            showFooter={false}
                            showAdd={false}
                            showEdit={false}

                            onChange={(value: any, item: any, i: any) => {
                                setPayrollTemplateId(value);
                                setPayrollTemplateName(item.title);

                                let clonedMnths = _.cloneDeep(months);
                                clonedMnths[props.index].isReRun = false;
                                dispatch(driversDetails.setMonths(clonedMnths));
                            }}
                            onClear={() => {
                                setPayrollTemplateId(null);
                                setPayrollTemplateName('');

                                let clonedMnths = _.cloneDeep(months);
                                clonedMnths[props.index].isReRun = false;
                                dispatch(driversDetails.setMonths(clonedMnths));
                            }}
                        />
                    </Col>
                </Form.Group>
            </Col>
            <Col xs={'auto'} md={'auto'}>
                <Form.Group as={Row} className={'mb-3'}>
                    <Form.Label column sm={12}>&nbsp;</Form.Label>
                    <Col sm={12}>
                        <StyledButton
                            type={'button'}
                            variant={'primary'}
                            style={{ minWidth: '88px' }}
                            onClick={async (e: any) => {
                                await dispatch(signalRslice.connect({
                                    callback: (percent: any) => {
                                        dispatch(signalRslice.onPercentage(percent));
                                    },
                                    callbackInit: async (hubId: any) => {
                                        await dispatch(driversDetails.callGenerateApi(hubId, months[props.index].date, props.index, payrollTemplateId, async (state) => {
                                            if(state){
                                                setTimeout(async () => {
                                                    await dispatch(driversDetails.callMonthDetailsApi(months[props.index].date, props.index, false, true, (state: any, data: any) => {
                                                        if(data){
                                                            data.isAdHoc = false;
                                                        }
                                                        
                                                        setTimeout(async () => {
                                                            setFieldValue('data', data);
                                                        }, 100);
                                                    }));
                                                }, 100);
                                            }
                                        }));

                                        dispatch(signalRslice.disconnect());
                                    }
                                }));

                            }}
                            disabled={months[props.index].isLoading || !payrollTemplateId}
                        >
                            {!months[props.index].isLoading && <>Run</>}
                            {months[props.index].isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                        </StyledButton>
                    </Col>
                </Form.Group>
            </Col>
        </Row>
    }

    const monthlyAdhocForm = () => {
        return <Row className={'align-items-center mt-5 gx-2'}>
            <Col xs={{ span: 'auto', order: 1 }} md={{ span: 'auto', order: 1 }} className={'mb-5 mb-md-0'}>
                <StyledMonthlyAdHocButton
                    variant={'light'}
                    size={'sm'}
                    active={!values?.data?.isAdHoc ? 'true' : ''}
                    onClick={() => {
                        setFieldValue('data.isAdHoc', false);
                    }}
                >Monthly</StyledMonthlyAdHocButton>
            </Col>
            <Col xs={{ span: 'auto', order: 2 }} md={{ span: 'auto', order: 2 }} className={'mb-5 mb-md-0'}>
                <StyledMonthlyAdHocButton
                    variant={'light'}
                    active={values?.data?.isAdHoc ? 'true' : ''}
                    size={'sm'}
                    onClick={() => {
                        setFieldValue('data.isAdHoc', true);
                    }}
                >Ad-hoc</StyledMonthlyAdHocButton>
            </Col>
            <Col xs={{ span: 12, order: 4 }} md={{ span: true, order: 3 }}>
                <Row className={'align-items-center justify-content-md-center gx-10'}>
                    <Col xs={'auto'} md={'auto'}>
                        <StyledMonthlyTitle>Monthly</StyledMonthlyTitle>
                        <StyledMonthlyPrice>{Utils.getCurrency() + ' ' + getMonthyTotal()}</StyledMonthlyPrice>
                    </Col>
                    <Col xs={'auto'} md={'auto'}>
                        <StyledMonthlyTitle>Ad-hoc</StyledMonthlyTitle>
                        <StyledMonthlyPrice>{Utils.getCurrency() + ' ' + getAdHocTotal('otherPay')}</StyledMonthlyPrice>
                    </Col>
                </Row>
            </Col>
            <Col xs={{ span: true, order: 3 }} md={{ span: 'auto', order: 4 }} className={'mb-5 mb-md-0 text-end text-md-start'}>
                <StyledExportButton
                    variant={'light'}
                    size={'sm'}
                    onClick={async () => {
                        await dispatch(signalRslice.connect({
                            callback: (percent: any) => {
                                dispatch(signalRslice.onPercentage(percent));
                            },
                            callbackInit: async (hubId: any) => {
                                await dispatch(driversDetails.callExportApi(hubId, id, months[props.index].date, async (date, type, data) => {
                                    let driverName = (details && details.driverName && details.driverName != '') ? details.driverName + ' - ' : '';
                                    let name = driverName + moment(date).format('MMM yyyy');
                                    Utils.saveByteArray(name, data, type);
                                }));

                                dispatch(signalRslice.disconnect());
                            }
                        }));
                    }}
                >Export</StyledExportButton>
            </Col>
            <Col xs={{ span: 12, order: 5 }}>
                <hr style={{ opacity: '1', borderColor: '#EFEFEF', marginTop: '20px', marginBottom: '20px' }} />
            </Col>
        </Row>
    }

    const reRunForm = () => {
        return <Row className={'align-items-center'}>
            <Col xs={true} md={4} lg={3} xl={2}>
                <Form.Group as={Row} className={'mb-3'}>
                    <Form.Label column sm={12}>{fields.payrollTemplateId.label}</Form.Label>
                    <Col sm={12}>
                        <SmartDropdown
                            isInvalid={!!(errors.data && errors.data.payrollTemplateId)}
                            errorText={errors.data && errors.data.payrollTemplateId}

                            componentTitle={'payrollTemplate'}
                            componentApi={'payrollTemplate'}
                            componentId={'payrollTemplateId'}
                            componentName={'templateName'}

                            placeholder={fields.payrollTemplateId.placeholder}
                            value={values?.data?.payrollTemplateId}
                            label={values?.data?.payrollTemplate?.templateName}

                            disabled={false}
                            showDefault={false}
                            isInfiniteScroll={false}
                            showFooter={false}
                            showAdd={false}
                            showEdit={false}

                            onChange={(value: any, item: any, i: any) => {
                                setFieldValue('data.payrollTemplateId', value);
                                setFieldValue('data.payrollTemplate', item.item);

                                let clonedMnths = _.cloneDeep(months);
                                clonedMnths[props.index].isReRun = false;
                                dispatch(driversDetails.setMonths(clonedMnths));
                            }}
                            onClear={() => {
                                setFieldValue('data.payrollTemplateId', null);
                                setFieldValue('data.payrollTemplate', null);

                                let clonedMnths = _.cloneDeep(months);
                                clonedMnths[props.index].isReRun = false;
                                dispatch(driversDetails.setMonths(clonedMnths));
                            }}
                        />
                    </Col>
                </Form.Group>
            </Col>
            <Col xs={'auto'} md={'auto'}>
                {
                    months[props.index].isReRun
                        ?
                        <Row className={'align-items-center'}>
                            <Col xs={12}><Form.Label column sm={12}>&nbsp;</Form.Label></Col>
                            <Col xs={'auto'}>Day</Col>
                            <Col xs={'auto'}>
                                <Form.Control
                                    as={CurrencyInput}
                                    allowDecimals={false}
                                    allowNegativeValue={false}
                                    disableGroupSeparators={true}
                                    maxLength={2}
                                    style={{ maxWidth: '50px', textAlign: 'center', borderColor: isValidDates(months[props.index]) ? '#0066FF' : '#FF0090' }}
                                    value={months[props.index].startDate}
                                    onValueChange={(value: any) => {
                                        if (value && value != '') {
                                            const enteredValue = parseFloat(value);
                                            let max = months[props.index].date.daysInMonth();
                                            if (enteredValue >= 1 && enteredValue <= max) {
                                                let clonedMnths = _.cloneDeep(months);
                                                clonedMnths[props.index].startDate = value;
                                                dispatch(driversDetails.setMonths(clonedMnths));
                                            }
                                        } else {
                                            let clonedMnths = _.cloneDeep(months);
                                            clonedMnths[props.index].startDate = '';
                                            dispatch(driversDetails.setMonths(clonedMnths));
                                        }
                                    }}
                                />
                            </Col>
                            <Col xs={'auto'}>To Day</Col>
                            <Col xs={'auto'}>
                                <Form.Control
                                    as={CurrencyInput}
                                    allowDecimals={false}
                                    allowNegativeValue={false}
                                    disableGroupSeparators={true}
                                    maxLength={2}
                                    style={{ maxWidth: '50px', textAlign: 'center', borderColor: isValidDates(months[props.index]) ? '#0066FF' : '#FF0090' }}
                                    value={months[props.index].endDate}
                                    onValueChange={(value: any) => {
                                        if (value && value != '') {
                                            const enteredValue = parseFloat(value);
                                            let max = months[props.index].date.daysInMonth();
                                            if (enteredValue >= 1 && enteredValue <= max) {
                                                let clonedMnths = _.cloneDeep(months);
                                                clonedMnths[props.index].endDate = value;
                                                dispatch(driversDetails.setMonths(clonedMnths));
                                            }
                                        } else {
                                            let clonedMnths = _.cloneDeep(months);
                                            clonedMnths[props.index].endDate = '';
                                            dispatch(driversDetails.setMonths(clonedMnths));
                                        }
                                    }}
                                />
                            </Col>
                            <Col xs={'auto'}>
                                <StyledCheckLink
                                    href={'/'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        dispatch(reduxPopupSlice.setShow({ item: months[props.index], index: props.index }));
                                    }}
                                >
                                    <i className={'far fa-check-circle'}></i>
                                </StyledCheckLink>
                            </Col>
                            <Col xs={'auto'}>
                                <StyledUncheckLink
                                    href={'/'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        let clonedMnths = _.cloneDeep(months);
                                        clonedMnths[props.index].startDate = '';
                                        clonedMnths[props.index].endDate = '';
                                        clonedMnths[props.index].isReRun = false;
                                        dispatch(driversDetails.setMonths(clonedMnths));
                                    }}
                                >
                                    <i className={'far fa-times-circle'}></i>
                                </StyledUncheckLink>
                            </Col>
                        </Row>
                        :
                        <Form.Group as={Row} className={'mb-3'}>
                            <Form.Label column sm={12}>&nbsp;</Form.Label>
                            <Col sm={12}>
                                <StyledButton
                                    type={'button'}
                                    variant={'primary'}
                                    style={{ minWidth: '88px' }}
                                    onClick={async (e: any) => {
                                        let max = months[props.index].date.daysInMonth();

                                        let clonedMnths = _.cloneDeep(months);
                                        clonedMnths[props.index].startDate = '1';
                                        clonedMnths[props.index].endDate = max.toString();
                                        clonedMnths[props.index].isReRun = true;
                                        dispatch(driversDetails.setMonths(clonedMnths));
                                    }}
                                    disabled={!values?.data?.payrollTemplateId}
                                >
                                    {!months[props.index].isLoading && <>Re-Run</>}
                                    {months[props.index].isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                                </StyledButton>
                            </Col>
                        </Form.Group>
                }
            </Col>
            <Col xs={12} md={true} className={'text-start text-md-end'}>
                <Form.Group as={Row} className={'mb-3 align-items-center'}>
                    <Form.Label column xs={12} className={'d-none d-md-flex'}>&nbsp;</Form.Label>
                    <Form.Label column xs={'auto'} md={true}>{values?.data?.isLocked ? 'Locked' : 'Unlocked'}</Form.Label>
                    <Col sm={'auto'}>
                        <Form.Check
                            type="switch"
                            id={'locked_' + props.index}
                            checked={values?.data?.isLocked}
                            onChange={async (e) => {
                                let checked = e.target.checked;
                                setFieldValue('data.isLocked', checked);

                                await dispatch(signalRslice.connect({
                                    callback: (percent: any) => {
                                        dispatch(signalRslice.onPercentage(percent));
                                    },
                                    callbackInit: async (hubId: any) => {
                                        await dispatch(driversDetails.callLockApi(hubId, checked, months[props.index].details, props.index, async (state) => { }));

                                        dispatch(signalRslice.disconnect());
                                    }
                                }));
                            }}
                        />
                    </Col>
                </Form.Group>
            </Col>
        </Row>
    }

    const remarksForm = () => {
        return <Row>
            <Col xs={12}>
                <Form.Control
                    type="text"
                    placeholder={fields.remarks.placeholder}
                    value={values?.data?.remarks}
                    readOnly={values?.data?.isLocked}
                    onChange={(e) => {
                        setFieldValue('data.remarks', e.target.value);
                    }}
                    onBlur={async (e: any) => {
                        let value = e.target.value;
                        let prevValue = (months && months[props.index] && months[props.index].details && months[props.index].details.remarks) ? months[props.index].details.remarks : '';
                        
                        if (!(values?.data?.isLocked) && value != prevValue) {
                            await dispatch(signalRslice.connect({
                                callback: (percent: any) => {
                                    dispatch(signalRslice.onPercentage(percent));
                                },
                                callbackInit: async (hubId: any) => {
                                    await dispatch(driversDetails.callRemarksApi(hubId, values?.data?.remarks, months[props.index].details, props.index, async (state) => { }));

                                    dispatch(signalRslice.disconnect());
                                }
                            }));
                        }
                    }}
                // isInvalid={!!(errors && errors.data && errors.data.remarks)}
                />
            </Col>
        </Row>
    }

    const tablesForm = () => {
        return <Row>
            {(values.data && values.data.days && values.data.days.length > 0) && values.data.days.map(function (data: any, i: number) {
                return (
                    <Col key={'monthly_table_' + i} xs={12} className={'mb-10'}>
                        <Row className={'mb-2'}>
                            <Col xs={12} md={true}>
                                <StyledMonthlyPrice>{data.dateDisplay} ({data.dayDisplay}){(data.isSpecial ? ' - Special' : '')}</StyledMonthlyPrice>
                            </Col>
                            <Col xs={12} md={true} className={'text-start text-md-end me-3'}>
                                {(!values?.data?.isLocked) && <StyledLinkButton
                                    type={'button'}
                                    onClick={(e) => {
                                        validateForm().then((err: any) => {
                                            if(!_.isEmpty(err)){
                                                Utils.toast('You must fill in the required fields', 'error');
                                            } else {
                                                let details = (data && data.details && data.details.length > 0) ? _.cloneDeep(data.details) : [];
                                                let arr = [];
                                                if(touched && touched.data && touched.data.days && touched.data.days.length > 0 && touched.data.days[i] && touched.data.days[i].details && touched.data.days[i].details.length > 0){
                                                    arr = touched.data.days[i].details.filter((obj: any) => obj).map((obj: any) => Object.values(obj)).flat();
                                                }
                                                if(details.every((x: any) => x.driverPayrollDayReportDetailId) && arr.every((x: any) => x === false)){
                                                    let newRow: MonthlyRowStruct = monthlyRow;
                                                    details.push(newRow);
                                                    setFieldValue('data.days.' + i + '.details', details);
                                                } else {
                                                    Utils.toast('You must save the row', 'error');
                                                }
                                            }
                                        })
                                    }}
                                >Add row</StyledLinkButton>}
                            </Col>
                            <Col xs={12} md={'auto'} style={{ minWidth: '70px' }}></Col>
                        </Row>

                        <StyledTableWrapper className={'responsive-table-sm'}>
                            <StyledTable className={'no-responsive-table'}>
                                <thead>
                                    <tr>
                                        <th>Work date</th>
                                        <th>Work time</th>
                                        <th>Job type</th>
                                        <th>Customer</th>
                                        <th>Site</th>
                                        <th>DO Number</th>
                                        <th>Geofence name</th>
                                        <th>Geofence Group</th>
                                        <th>Trip pay</th>
                                        <th>Incentive pay</th>
                                        <th>Remarks</th>
                                        <th style={{ width: '65px' }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(data && data.details && data.details.length > 0) && data.details.map(function (item: any, j: number) {
                                        return (<tr key={'monthly_table_' + i + '_details_' + j}
                                            onMouseEnter={(e: any) => {
                                                setFieldTouched('data.days.' + i + '.detailsClick.' + j, true);
                                            }}
                                            onMouseLeave={(e: any) => {
                                                setFieldTouched('data.days.' + i + '.detailsClick.' + j, false);
                                            }}
                                        >
                                            <td>
                                                <Form.Control
                                                    as = {InputMask}
                                                    mask={'99-99-9999'}
                                                    maskChar={null}
                                                    size={'sm'}

                                                    readOnly={(values?.data?.isLocked)}

                                                    id={'days_' + i + '_' + fields.workDate.id + '_' + j + '-workDate'}
                                                    isInvalid={!!(errors && errors.data && errors.data.days && errors.data.days.length > 0 && errors.data.days[i] && errors.data.days[i].details && errors.data.days[i].details.length > 0 && errors.data.days[i].details[j] && errors.data.days[i].details[j].workDate)}
                                                    placeholder={fields.workDate.placeholder}
                                                    value={item.workDate}
                                                    // onFocus={(e: any) => {
                                                    //     setFieldTouched('data.days.' + i + '.detailsClick.' + j, true);
                                                    // }}
                                                    // onBlur={(e: any) => {
                                                    //     setFieldTouched('data.days.' + i + '.detailsClick.' + j, false);
                                                    // }}
                                                    onChange={(e: any) => {
                                                        setFieldValue('data.days.' + i + '.details.' + j + '.workDate', e.target.value);
                                                        setFieldTouched('data.days.' + i + '.details.' + j + '.workDate', true);
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <StyledgraduatedPayText
                                                // <Form.Control
                                                    // as = {InputMask}
                                                    // mask={'99:99'}
                                                    // maskChar={null}
                                                    size={'sm'}

                                                    readOnly={(values?.data?.isLocked)}

                                                    id={'days_' + i + '_' + fields.workTime.id + '_' + j + '-workTime'}
                                                    isInvalid={!!(errors && errors.data && errors.data.days && errors.data.days.length > 0 && errors.data.days[i] && errors.data.days[i].details && errors.data.days[i].details.length > 0 && errors.data.days[i].details[j] && errors.data.days[i].details[j].workTime)}
                                                    placeholder={fields.workTime.placeholder}
                                                    value={item.workTime}
                                                    // onFocus={(e: any) => {
                                                    //     setFieldTouched('data.days.' + i + '.detailsClick.' + j, true);
                                                    // }}
                                                    // onBlur={(e: any) => {
                                                    //     setFieldTouched('data.days.' + i + '.detailsClick.' + j, false);
                                                    // }}
                                                    onChange={(e: any) => {
                                                        setFieldValue('data.days.' + i + '.details.' + j + '.workTime', e.target.value);
                                                        setFieldTouched('data.days.' + i + '.details.' + j + '.workTime', true);
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <StyledgraduatedPayText
                                                    size={'sm'}
                                                    id={'days_' + i + '_' + fields.jobType.id + '_' + j + '-jobType'}
                                                    isInvalid={!!(errors && errors.data && errors.data.days && errors.data.days.length > 0 && errors.data.days[i] && errors.data.days[i].details && errors.data.days[i].details.length > 0 && errors.data.days[i].details[j] && errors.data.days[i].details[j].jobType)}
                                                    placeholder={fields.jobType.placeholder}
                                                    value={item.jobType}
                                                    readOnly={(values?.data?.isLocked)}
                                                    // onFocus={(e: any) => {
                                                    //     setFieldTouched('data.days.' + i + '.detailsClick.' + j, true);
                                                    // }}
                                                    // onBlur={(e: any) => {
                                                    //     setFieldTouched('data.days.' + i + '.detailsClick.' + j, false);
                                                    // }}
                                                    onChange={(e: any) => {
                                                        setFieldValue('data.days.' + i + '.details.' + j + '.jobType', e.target.value);
                                                        setFieldTouched('data.days.' + i + '.details.' + j + '.jobType', true);
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <StyledgraduatedPayText
                                                    size={'sm'}
                                                    id={'days_' + i + '_' + fields.customerName.id + '_' + j + '-customerName'}
                                                    isInvalid={!!(errors && errors.data && errors.data.days && errors.data.days.length > 0 && errors.data.days[i] && errors.data.days[i].details && errors.data.days[i].details.length > 0 && errors.data.days[i].details[j] && errors.data.days[i].details[j].customerName)}
                                                    placeholder={fields.customerName.placeholder}
                                                    value={item.customerName}
                                                    readOnly={(values?.data?.isLocked)}
                                                    // onFocus={(e: any) => {
                                                    //     setFieldTouched('data.days.' + i + '.detailsClick.' + j, true);
                                                    // }}
                                                    // onBlur={(e: any) => {
                                                    //     setFieldTouched('data.days.' + i + '.detailsClick.' + j, false);
                                                    // }}
                                                    onChange={(e: any) => {
                                                        setFieldValue('data.days.' + i + '.details.' + j + '.customerName', e.target.value);
                                                        setFieldTouched('data.days.' + i + '.details.' + j + '.customerName', true);
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <StyledgraduatedPayText
                                                    size={'sm'}
                                                    id={'days_' + i + '_' + fields.site.id + '_' + j + '-site'}
                                                    isInvalid={!!(errors && errors.data && errors.data.days && errors.data.days.length > 0 && errors.data.days[i] && errors.data.days[i].details && errors.data.days[i].details.length > 0 && errors.data.days[i].details[j] && errors.data.days[i].details[j].site)}
                                                    placeholder={fields.site.placeholder}
                                                    value={item.site}
                                                    readOnly={(values?.data?.isLocked)}
                                                    // onFocus={(e: any) => {
                                                    //     setFieldTouched('data.days.' + i + '.detailsClick.' + j, true);
                                                    // }}
                                                    // onBlur={(e: any) => {
                                                    //     setFieldTouched('data.days.' + i + '.detailsClick.' + j, false);
                                                    // }}
                                                    onChange={(e: any) => {
                                                        setFieldValue('data.days.' + i + '.details.' + j + '.site', e.target.value);
                                                        setFieldTouched('data.days.' + i + '.details.' + j + '.site', true);
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <StyledgraduatedPayText
                                                    size={'sm'}
                                                    id={'days_' + i + '_' + fields.jobNumber.id + '_' + j + '-jobNumber'}
                                                    isInvalid={!!(errors && errors.data && errors.data.days && errors.data.days.length > 0 && errors.data.days[i] && errors.data.days[i].details && errors.data.days[i].details.length > 0 && errors.data.days[i].details[j] && errors.data.days[i].details[j].jobNumber)}
                                                    placeholder={fields.jobNumber.placeholder}
                                                    value={item.jobNumber}
                                                    readOnly={(values?.data?.isLocked)}
                                                    // onFocus={(e: any) => {
                                                    //     setFieldTouched('data.days.' + i + '.detailsClick.' + j, true);
                                                    // }}
                                                    // onBlur={(e: any) => {
                                                    //     setFieldTouched('data.days.' + i + '.detailsClick.' + j, false);
                                                    // }}
                                                    onChange={(e: any) => {
                                                        setFieldValue('data.days.' + i + '.details.' + j + '.jobNumber', e.target.value);
                                                        setFieldTouched('data.days.' + i + '.details.' + j + '.jobNumber', true);
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <StyledgraduatedPayText
                                                    size={'sm'}
                                                    id={'days_' + i + '_' + fields.geofenceName.id + '_' + j + '-geofenceName'}
                                                    isInvalid={!!(errors && errors.data && errors.data.days && errors.data.days.length > 0 && errors.data.days[i] && errors.data.days[i].details && errors.data.days[i].details.length > 0 && errors.data.days[i].details[j] && errors.data.days[i].details[j].geofenceName)}
                                                    placeholder={fields.geofenceName.placeholder}
                                                    value={item.geofenceName}
                                                    readOnly={(values?.data?.isLocked)}
                                                    // onFocus={(e: any) => {
                                                    //     setFieldTouched('data.days.' + i + '.detailsClick.' + j, true);
                                                    // }}
                                                    // onBlur={(e: any) => {
                                                    //     setFieldTouched('data.days.' + i + '.detailsClick.' + j, false);
                                                    // }}
                                                    onChange={(e: any) => {
                                                        setFieldValue('data.days.' + i + '.details.' + j + '.geofenceName', e.target.value);
                                                        setFieldTouched('data.days.' + i + '.details.' + j + '.geofenceName', true);
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <StyledgraduatedPayText
                                                    size={'sm'}
                                                    id={'days_' + i + '_' + fields.geofenceGroup.id + '_' + j + '-geofenceGroup'}
                                                    isInvalid={!!(errors && errors.data && errors.data.days && errors.data.days.length > 0 && errors.data.days[i] && errors.data.days[i].details && errors.data.days[i].details.length > 0 && errors.data.days[i].details[j] && errors.data.days[i].details[j].geofenceGroup)}
                                                    placeholder={fields.geofenceGroup.placeholder}
                                                    value={item.geofenceGroup}
                                                    readOnly={(values?.data?.isLocked)}
                                                    // onFocus={(e: any) => {
                                                    //     setFieldTouched('data.days.' + i + '.detailsClick.' + j, true);
                                                    // }}
                                                    // onBlur={(e: any) => {
                                                    //     setFieldTouched('data.days.' + i + '.detailsClick.' + j, false);
                                                    // }}
                                                    onChange={(e: any) => {
                                                        setFieldValue('data.days.' + i + '.details.' + j + '.geofenceGroup', e.target.value);
                                                        setFieldTouched('data.days.' + i + '.details.' + j + '.geofenceGroup', true);
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <Form.Control
                                                    as={CurrencyInput}

                                                    allowDecimals={true}
                                                    allowNegativeValue={false}
                                                    disableGroupSeparators={true}
                                                    prefix={Utils.getCurrency()}
                                                    size={'sm'}

                                                    readOnly={(values?.data?.isLocked)}

                                                    id={'days_' + i + '_' + fields.tripPay.id + '_' + j + '-tripPay'}
                                                    isInvalid={!!(errors && errors.data && errors.data.days && errors.data.days.length > 0 && errors.data.days[i] && errors.data.days[i].details && errors.data.days[i].details.length > 0 && errors.data.days[i].details[j] && errors.data.days[i].details[j].tripPay)}
                                                    placeholder={fields.tripPay.placeholder}
                                                    value={item.tripPay}
                                                    // onFocus={(e: any) => {
                                                    //     setFieldTouched('data.days.' + i + '.detailsClick.' + j, true);
                                                    // }}
                                                    // onBlur={(e: any) => {
                                                    //     setFieldTouched('data.days.' + i + '.detailsClick.' + j, false);
                                                    // }}
                                                    onValueChange={(value: any) => {
                                                        if(value != item.otherPay){
                                                            setFieldValue('data.days.' + i + '.details.' + j + '.tripPay', value);
                                                            setFieldTouched('data.days.' + i + '.details.' + j + '.tripPay', true);
                                                        }
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <Form.Control
                                                    as={CurrencyInput}

                                                    allowDecimals={true}
                                                    allowNegativeValue={false}
                                                    disableGroupSeparators={true}
                                                    prefix={Utils.getCurrency()}
                                                    size={'sm'}

                                                    readOnly={(values?.data?.isLocked)}

                                                    id={'days_' + i + '_' + fields.incentivePay.id + '_' + j + '-incentivePay'}
                                                    isInvalid={!!(errors && errors.data && errors.data.days && errors.data.days.length > 0 && errors.data.days[i] && errors.data.days[i].details && errors.data.days[i].details.length > 0 && errors.data.days[i].details[j] && errors.data.days[i].details[j].incentivePay)}
                                                    placeholder={fields.incentivePay.placeholder}
                                                    value={item.incentivePay}
                                                    // onFocus={(e: any) => {
                                                    //     setFieldTouched('data.days.' + i + '.detailsClick.' + j, true);
                                                    // }}
                                                    // onBlur={(e: any) => {
                                                    //     setFieldTouched('data.days.' + i + '.detailsClick.' + j, false);
                                                    // }}
                                                    onValueChange={(value: any) => {
                                                        if(value != item.otherPay){
                                                            setFieldValue('data.days.' + i + '.details.' + j + '.incentivePay', value);
                                                            setFieldTouched('data.days.' + i + '.details.' + j + '.incentivePay', true);
                                                        }
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <StyledgraduatedPayText
                                                    size={'sm'}
                                                    id={'days_' + i + '_' + fields.remarks.id + '_' + j + '-remarks'}
                                                    isInvalid={!!(errors && errors.data && errors.data.days && errors.data.days.length > 0 && errors.data.days[i] && errors.data.days[i].details && errors.data.days[i].details.length > 0 && errors.data.days[i].details[j] && errors.data.days[i].details[j].remarks)}
                                                    placeholder={fields.remarks.placeholderInTable}
                                                    value={item.remarks}
                                                    readOnly={(values?.data?.isLocked)}
                                                    // onFocus={(e: any) => {
                                                    //     setFieldTouched('data.days.' + i + '.detailsClick.' + j, true);
                                                    // }}
                                                    // onBlur={(e: any) => {
                                                    //     setFieldTouched('data.days.' + i + '.detailsClick.' + j, false);
                                                    // }}
                                                    onChange={(e: any) => {
                                                        setFieldValue('data.days.' + i + '.details.' + j + '.remarks', e.target.value);
                                                        setFieldTouched('data.days.' + i + '.details.' + j + '.remarks', true);
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                {(
                                                    !(item.driverPayrollDayReportDetailId) || 
                                                    (touched && touched.data && touched.data.days && touched.data.days.length > 0 && touched.data.days[i] && touched.data.days[i].details && touched.data.days[i].details.length > 0 && touched.data.days[i].details[j]) || 
                                                    (touched && touched.data && touched.data.days && touched.data.days.length > 0 && touched.data.days[i] && touched.data.days[i].detailsClick && touched.data.days[i].detailsClick.length > 0 && touched.data.days[i].detailsClick[j]) || 
                                                    (errors && errors.data && errors.data.days && errors.data.days.length > 0 && errors.data.days[i] && errors.data.days[i].details && errors.data.days[i].details.length > 0 && errors.data.days[i].details[j])
                                                ) && <>
                                                    {(!values?.data?.isLocked) && <StyledCheckLink
                                                        href={'/'}
                                                        className={'d-inline-flex me-1'}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();

                                                            validateForm().then((err: any) => {
                                                                if(!_.isEmpty(err)){
                                                                    Utils.toast('You must fill in the required fields', 'error');
                                                                } else {
                                                                    if(item.driverPayrollDayReportDetailId){
                                                                        dispatch(driversDetails.callUpdateRowApi(item, (state: any, row: any) => {
                                                                            if(state){
                                                                                let details = (data && data.details && data.details.length > 0) ? _.cloneDeep(data.details) : [];
                                                                                details[j] = row;
                                                                                setFieldValue('data.days.' + i + '.details', details);
                                                                                setFieldTouched('data.days.' + i + '.details.' + j, null);
                                                                            }
                                                                        }));

                                                                    } else {
                                                                        let newItem = _.cloneDeep(item);
                                                                        newItem['driverPayrollDayReportId'] = data.driverPayrollDayReportId;
            
                                                                        dispatch(driversDetails.callCreateRowApi(newItem, (state: any, row: any) => {
                                                                            if(state){
                                                                                let details = (data && data.details && data.details.length > 0) ? _.cloneDeep(data.details) : [];
                                                                                details[j] = row;
                                                                                setFieldValue('data.days.' + i + '.details', details);
                                                                                setFieldTouched('data.days.' + i + '.details.' + j, null);
                                                                            }
                                                                        }));
                                                                    }
                                                                }
                                                            })
                                                        }}
                                                    >
                                                        <i className={'far fa-check-circle'}></i>
                                                    </StyledCheckLink>}
                                                    {(!values?.data?.isLocked) && <StyledUncheckLink
                                                        href={'/'}
                                                        className={'d-inline-flex'}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();

                                                            if(item.driverPayrollDayReportDetailId){
                                                                validateForm().then((err: any) => {
                                                                    if(!_.isEmpty(err)){
                                                                        Utils.toast('You must fill in the required fields', 'error');
                                                                    } else {
                                                                        dispatch(reduxDeletePopupSlice.setShow({ item: item, index: i, subIndex: j, type: 'monthly' }));
                                                                        setFieldTouched('data.days.' + i + '.details.' + j, null);
                                                                    }
                                                                })
                                                            } else {
                                                                let details = (data && data.details && data.details.length > 0) ? _.cloneDeep(data.details) : [];
                                                                details.splice(j, 1);
                                                                setFieldValue('data.days.' + i + '.details', details);
                                                                setFieldTouched('data.days.' + i + '.details.' + j, null);
                                                            }
                                                        }}
                                                    >
                                                        <i className={'far fa-times-circle'}></i>
                                                    </StyledUncheckLink>}
                                                </>}
                                            </td>
                                        </tr>
                                        )
                                    })}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td style={{ textAlign: 'right' }}><StyledMonthlyTitle className={'pe-2'}>Sub total:</StyledMonthlyTitle></td>
                                        <td><StyledMonthlyPrice className={'ps-2'}>{Utils.getCurrency() + ' ' + getMonthySubTotal(data, 'tripPay')}</StyledMonthlyPrice></td>
                                        <td><StyledMonthlyPrice className={'ps-2'}>{Utils.getCurrency() + ' ' + getMonthySubTotal(data, 'incentivePay')}</StyledMonthlyPrice></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </StyledTable>
                        </StyledTableWrapper>
                    </Col>
                )
            })}
        </Row>
    }

    const adHocTableForm = () => {
        return <Row>
            <Col xs={12} className={'mb-10'}>
                <Row className={'mb-2'}>
                    <Col xs={12} md={true} className={'text-start text-md-end me-2'}>
                        {(!values?.data?.isLocked) && <StyledLinkButton
                            type={'button'}
                            onClick={(e) => {
                                validateForm().then((err: any) => {
                                    if(!_.isEmpty(err)){
                                        Utils.toast('You must fill in the required fields', 'error');
                                    } else {
                                        let adhocDetails = (values.data && values.data.adhocDetails && values.data.adhocDetails.length > 0) ? _.cloneDeep(values.data.adhocDetails) : [];
                                        let arr = [];
                                        if(touched && touched.data && touched.data.adhocDetails && touched.data.adhocDetails.length > 0){
                                            arr = touched.data.adhocDetails.filter((obj: any) => obj).map((obj: any) => Object.values(obj)).flat();
                                        }
                                        if(adhocDetails.every((x: any) => x.driverPayrollAdhocReportDetailId) && arr.every((x: any) => x === false)){
                                            let newRow: AdHocRowStruct = adHocRow;
                                            adhocDetails.push(newRow);
                                            setFieldValue('data.adhocDetails', adhocDetails);
                                        } else {
                                            Utils.toast('You must save the row', 'error');
                                        }
                                    }
                                })
                            }}
                        >Add row</StyledLinkButton>}
                    </Col>
                    <Col xs={12} md={'auto'} style={{ minWidth: '70px' }}></Col>
                </Row>

                <StyledTableWrapper className={'responsive-table-sm'}>
                    <StyledTable className={'no-responsive-table'}>
                        <thead>
                            <tr>
                                <th style={{ width: '70%' }}>Pay description</th>
                                <th>Other pay</th>
                                <th style={{ width: '65px' }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {(values.data && values.data.adhocDetails && values.data.adhocDetails.length > 0) && values.data.adhocDetails.map(function (item: any, i: number) {
                                return (<tr key={'adhoc_table_' + i}
                                    onMouseEnter={(e: any) => {
                                        setFieldTouched('data.adhocDetailsClick.' + i, true);
                                    }}
                                    onMouseLeave={(e: any) => {
                                        setFieldTouched('data.adhocDetailsClick.' + i, false);
                                    }}
                                >
                                    <td>
                                        <StyledgraduatedPayText
                                            size={'sm'}
                                            id={'adhoc_' + i + '_' + fields.description.id + '-description'}
                                            isInvalid={!!(errors && errors.data && errors.data.adhocDetails && errors.data.adhocDetails.length > 0 && errors.data.adhocDetails[i] && errors.data.adhocDetails[i].description)}
                                            placeholder={fields.description.placeholder}
                                            value={item.description}
                                            readOnly={(values?.data?.isLocked)}
                                            // onFocus={(e: any) => {
                                            //     setFieldTouched('data.adhocDetailsClick.' + i, true);
                                            // }}
                                            // onBlur={(e: any) => {
                                            //     setFieldTouched('data.adhocDetailsClick.' + i, false);
                                            // }}
                                            onChange={(e: any) => {
                                                setFieldValue('data.adhocDetails.' + i + '.description', e.target.value);
                                                setFieldTouched('data.adhocDetails.' + i + '.description', true);
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            as={CurrencyInput}

                                            allowDecimals={true}
                                            allowNegativeValue={false}
                                            disableGroupSeparators={true}
                                            prefix={Utils.getCurrency()}
                                            size={'sm'}

                                            readOnly={(values?.data?.isLocked)}

                                            id={'adhoc_' + i + '_' + fields.otherPay.id + '-otherPay'}
                                            isInvalid={!!(errors && errors.data && errors.data.adhocDetails && errors.data.adhocDetails.length > 0 && errors.data.adhocDetails[i] && errors.data.adhocDetails[i].otherPay)}
                                            placeholder={fields.otherPay.placeholder}
                                            value={item.otherPay}
                                            // onFocus={(e: any) => {
                                            //     setFieldTouched('data.adhocDetailsClick.' + i, true);
                                            // }}
                                            // onBlur={(e: any) => {
                                            //     setFieldTouched('data.adhocDetailsClick.' + i, false);
                                            // }}
                                            onValueChange={(value: any) => {
                                                if(value != item.otherPay){
                                                    setFieldValue('data.adhocDetails.' + i + '.otherPay', value);
                                                    setFieldTouched('data.adhocDetails.' + i + '.otherPay', true);
                                                }
                                            }}
                                        />
                                    </td>
                                    <td>
                                        {(
                                            !(item.driverPayrollAdhocReportDetailId) || 
                                            (touched && touched.data && touched.data.adhocDetails && touched.data.adhocDetails.length > 0 && touched.data.adhocDetails[i]) || 
                                            (touched && touched.data && touched.data.adhocDetailsClick && touched.data.adhocDetailsClick.length > 0 && touched.data.adhocDetailsClick[i]) || 
                                            (errors && errors.data && errors.data.adhocDetails && errors.data.adhocDetails.length > 0 && errors.data.adhocDetails[i])
                                        ) && <>
                                            {(!values?.data?.isLocked) && <StyledCheckLink
                                                href={'/'}
                                                className={'d-inline-flex me-1'}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();

                                                    validateForm().then((err: any) => {
                                                        if(!_.isEmpty(err)){
                                                            Utils.toast('You must fill in the required fields', 'error');
                                                        } else {
                                                            if(item.driverPayrollAdhocReportDetailId){
                                                                dispatch(driversDetails.callUpdateAdHocRowApi(item, (state: any, row: any) => {
                                                                    if(state){
                                                                        let adhocDetails = (values.data && values.data.adhocDetails && values.data.adhocDetails.length > 0) ? _.cloneDeep(values.data.adhocDetails) : [];
                                                                        adhocDetails[i] = row;
                                                                        setFieldValue('data.adhocDetails', adhocDetails);
                                                                        setFieldTouched('data.adhocDetails.' + i, null);
                                                                    }
                                                                }));
                                                                
                                                            } else {
                                                                let newItem = _.cloneDeep(item);
                                                                newItem['driverPayrollMonthReportId'] = values.data.driverPayrollMonthReportId;

                                                                dispatch(driversDetails.callCreateAdHocRowApi(newItem, (state: any, row: any) => {
                                                                    if(state){
                                                                        let adhocDetails = (values.data && values.data.adhocDetails && values.data.adhocDetails.length > 0) ? _.cloneDeep(values.data.adhocDetails) : [];
                                                                        adhocDetails[i] = row;
                                                                        setFieldValue('data.adhocDetails', adhocDetails);
                                                                        setFieldTouched('data.adhocDetails.' + i, null);
                                                                    }
                                                                }));
                                                            }
                                                        }
                                                    })
                                                }}
                                            >
                                                <i className={'far fa-check-circle'}></i>
                                            </StyledCheckLink>}
                                            {(!values?.data?.isLocked) && <StyledUncheckLink
                                                href={'/'}
                                                className={'d-inline-flex'}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();

                                                    if(item.driverPayrollAdhocReportDetailId){
                                                        validateForm().then((err: any) => {
                                                            if(!_.isEmpty(err)){
                                                                Utils.toast('You must fill in the required fields', 'error');
                                                            } else {
                                                                dispatch(reduxDeletePopupSlice.setShow({ item: item, index: i, subIndex: null, type: 'adhoc' }));
                                                                setFieldTouched('data.adhocDetails.' + i, null);
                                                            }
                                                        })
                                                    } else {
                                                        let adhocDetails = (values.data && values.data.adhocDetails && values.data.adhocDetails.length > 0) ? _.cloneDeep(values.data.adhocDetails) : [];
                                                        adhocDetails.splice(i, 1);
                                                        setFieldValue('data.adhocDetails', adhocDetails);
                                                        setFieldTouched('data.adhocDetails.' + i, null);
                                                    }
                                                }}
                                            >
                                                <i className={'far fa-times-circle'}></i>
                                            </StyledUncheckLink>}
                                        </>}
                                    </td>
                                </tr>
                                )
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td style={{ textAlign: 'right' }}><StyledMonthlyTitle className={'pe-2'}>Sub total:</StyledMonthlyTitle></td>
                                <td><StyledMonthlyPrice className={'ps-2'}>{Utils.getCurrency() + ' ' + getAdHocSubTotal(values.data, 'otherPay')}</StyledMonthlyPrice></td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </StyledTable>
                </StyledTableWrapper>
            </Col>
        </Row>
    }


    return <>
        <Row>
            {
                (months[props.index].details)
                    ?
                    <Col xs={12}>
                        <Row>
                            <Col xs={12}>{monthlyAdhocForm()}</Col>
                            {!values?.data?.isAdHoc && <Col xs={12}>{reRunForm()}</Col>}
                            {!values?.data?.isAdHoc && <Col xs={12} className={'my-10'}>{remarksForm()}</Col>}
                            {!values?.data?.isAdHoc && <Col xs={12}>{tablesForm()}</Col>}
                            {values?.data?.isAdHoc && <Col xs={12} className={'mt-10'}>{adHocTableForm()}</Col>}
                        </Row>
                    </Col>
                    :
                    <Col xs={12}>{runForm()}</Col>
            }
        </Row>
    </>
}


export default SalaryForm;
