import React from 'react';

import { 
    Row,
    Col,
    Form,
} from "react-bootstrap-v5";


const MIN_WIDTH = 768;


const GroupLayout = (props) => {
    if((props.windowWidth < MIN_WIDTH) || props.isVertical){
        return <div className={"mb-5 " + props.className}>{props.children}</div>
    } else {
        return <Row className={"mb-5 " + props.className}>{props.children}</Row>
    }
};

const InputLayout = (props) => {
    let [windowWidth, setWindowWidth] = React.useState(0);

    const resizeListener = () => {
        setWindowWidth(window.innerWidth);
    }
    
    React.useEffect(() => {
        try{
            resizeListener();
        } catch(e){}
        
        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    });
    

    return <Form.Group as={GroupLayout} {...props} windowWidth={windowWidth} >
        {
            ((windowWidth < MIN_WIDTH) || props.isVertical)
            ?
            <>
                {props.label && <Form.Label>
                    {props.label}
                    {props.required && <span className={'text-danger ps-1'}>*</span>}
                    {props.details && <small className={'d-block w-100'}>{props.details}</small>}
                </Form.Label>}
                {props.children}
                {props.cols}
                {props.error}
            </>
            :
            <>
                {props.label && <Form.Label column xs={3} {...props.labelProps}>
                    {props.label}
                    {props.required && <span className={'text-danger ps-1'}>*</span>}
                    {props.details && <small className={'d-block w-100'}>{props.details}</small>}
                </Form.Label>}
                {props.children && <Col xs={9} {...props.inputProps}>{props.children}</Col>}
                {props.cols && <Col {...props.colProps}>{props.cols}</Col>}
                {props.error && <Col xs={12} {...props.errorProps}>{props.error}</Col>}
            </>
        }
    </Form.Group>
};


export default InputLayout;
