import React, { Component } from 'react';
import queryString from 'query-string';

import Utils from '../../utils/utils';

import { 
    Box,
    Grid,
    Icon
} from '@material-ui/core';

import { connect } from 'react-redux'
import { dispatchApiCallPost } from '../../../setup/redux/dispatch/actions'


class JobIframe extends Component {
	
	constructor(props){
		super(props);

        const jobIframeData = localStorage.getItem("jobIframeData");
        let data = null;
        try {
            data = JSON.parse(jobIframeData);
        }catch(e){}
        localStorage.removeItem("jobIframeData");

		this.state = {
            url: '',
            data: data,
            downloadIsLoading: true,
		}
	}


    componentDidMount() {
        try {
            document.getElementById('kt_header').remove();
        }catch(e){}

		this.callDownloadFileApi(this.state.data, (response) => {
            let url = '';
            if(response){
                let byteArray = Utils.getUint8Array(response);
                url = window.URL.createObjectURL(new Blob([byteArray], {type: 'application/pdf'}));
            }
            
            this.setState({
                url: url,
                downloadIsLoading: false,
            });
        });
    }
    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    reduxProps = (nextProps) => {
        Utils.reduxProps(nextProps,
          'job-iframe_get-job-doc', 
          (data, isLoading, isError, err, statusCode, variables, callback) => {
            this.setState({
              isLoading: isLoading,
              isError: isError,
              err: err,
            }, () => {
              if(callback){
                callback(data);
              }
            });
          }
        );
    }


    callDownloadFileApi = (data, callback = null) => {
		Utils.toast(<Box>
		  <Box fontWeight={'bold'} pb={2}>Please Wait ...</Box>
		</Box>, 'info');

        this.props.dispatchApiCallPost(data, 'job-iframe_get-job-doc', 'job/get-job-doc', null, callback, () => {});
    }
    

	render() {
        if(this.state.downloadIsLoading){
            return <Grid container justifyContent={'center'} alignItems={'center'}>
                <Grid item>
                    <Grid xs={12}>
                        <Box style={{ fontSize: '35px' }}>Please Wait ...</Box>
                        <Box textAlign={'center'}>
                            <Icon component={'i'} style={{ fontSize: '55px' }}>print</Icon>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        } else {
            return <iframe 
                id={'iframeID'} 
                title={'PDF'} 
                src={this.state.url} 
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'fixed',
                }}
                onLoad={(e) => {
                    setTimeout(function() {
                        document.getElementById('iframeID').contentWindow.print();
                    }, 2000);
                }}
            />;
        }
        
	}
}

const mapDispatchToProps = {
    dispatchApiCallPost,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(JobIframe);
