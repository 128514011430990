/* eslint-disable no-loop-func */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import * as dispatchCrudApi from '../../../api/CRUD/DispatchCRUD'
import Utils from "../../../utils/utils";
import { ParseResult } from "../../../utils/interfaces";
// import {  } from '../../../utils/enums'


interface InitState {
  isLoading: boolean,
  show: boolean,
  open: boolean,

  id: any,
  binTypeId: any,
  details: any,

  isLoadingHistory: boolean,
  detailsHistory: Array<any>,
  detailsHistoryTotal: number,
  detailsHistoryHasMore: boolean,
}


function NewReducer() {
  const name = 'binCenterDetailsPanel';


  const initialState: InitState = {
    isLoading: false,
    show: false,
    open: false,

    id: null,
    binTypeId: null,
    details: null,

    isLoadingHistory: false,
    detailsHistory: [],
    detailsHistoryTotal: 0,
    detailsHistoryHasMore: true
  };


  const reducers = {
    clear: (state: InitState, action: PayloadAction<{ show: boolean, id: any, binTypeId: any}>) => {
      state.detailsHistory = [];
      state.detailsHistoryTotal = 0;
      state.detailsHistoryHasMore = true;
    },
    show: (state: InitState, action: PayloadAction<{ show: boolean, id: any, binTypeId: any}>) => {
      state.detailsHistory = [];
      state.detailsHistoryTotal = 0;
      state.detailsHistoryHasMore = true;
      
      state.id = action.payload.id;
      state.binTypeId = action.payload.binTypeId;
      state.show = action.payload.show;
    },
    open: (state: InitState, action: PayloadAction<any>) => {
      state.open = action.payload;
    },
    
    startDetails: (state: InitState) => {
      state.isLoading = true;
    },
    finishDetails: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload
      state.isLoading = false;
    },

    startDetailsHistoryFirstTime: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoadingHistory = true;
      state.detailsHistoryHasMore = action.payload
    },
    finishDetailsHistoryFirstTime: (state: InitState, action: PayloadAction<{ data: any, total: number, hasMore: boolean}>) => {
      let arr: any = [];
      let newData = action.payload.data;
      let newArray = _.concat(arr, newData);

      state.detailsHistory = newArray;
      state.detailsHistoryTotal = action.payload.total
      state.detailsHistoryHasMore = false
      state.isLoadingHistory = false;
    },
    
    startDetailsHistory: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoadingHistory = true;
      state.detailsHistoryHasMore = action.payload
    },
    finishDetailsHistory: (state: InitState, action: PayloadAction<{ data: any, total: number, hasMore: boolean}>) => {
      let arr = state.detailsHistory;
      let newData = action.payload.data;
      let newArray = _.concat(arr, newData);

      state.detailsHistory = newArray;
      state.detailsHistoryTotal = action.payload.total
      state.detailsHistoryHasMore = false
      state.isLoadingHistory = false;
    },
  };


  const apis = {
    callDetailsApi: (id: number, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDetails());

      await dispatchCrudApi.readApi(null, 'binnumber/' + id).then(result => {
        let data = result.data;
        
        callback(true, data);
        dispatch(actions.finishDetails(data));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false, null);
        dispatch(actions.finishDetails(null));
      });
    },
    
    callDetailsHistoryFirstTimeApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any, getState: any) => {
      const { detailsHistory, detailsHistoryTotal } = getState().binCenterDetailsPanel;
      
      let hasMore = ((params.currentPage > 1) && (detailsHistory.length > 0 && detailsHistoryTotal > 0)) ? (detailsHistory.length < detailsHistoryTotal) ? true : false : true;

      dispatch(actions.startDetailsHistoryFirstTime(hasMore));

      await dispatchCrudApi.readApi(params, 'bin-center/bin-on-site-detail-list').then(result => {
        let data = result.data.data;
        let total = result.data.total;
        
        callback(true, data);
        dispatch(actions.finishDetailsHistoryFirstTime({ data, total, hasMore }));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false, null);
        dispatch(actions.finishDetailsHistoryFirstTime({ data: null, total: 0, hasMore: false }));
      });
    },
    callDetailsHistoryApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any, getState: any) => {
      const { detailsHistory, detailsHistoryTotal } = getState().binCenterDetailsPanel;
      
      let hasMore = ((params.currentPage > 1) && (detailsHistory.length > 0 && detailsHistoryTotal > 0)) ? (detailsHistory.length < detailsHistoryTotal) ? true : false : true;

      dispatch(actions.startDetailsHistory(hasMore));

      await dispatchCrudApi.readApi(params, 'bin-center/bin-on-site-detail-list').then(result => {
        let data = result.data.data;
        let total = result.data.total;
        
        callback(true, data);
        dispatch(actions.finishDetailsHistory({ data, total, hasMore }));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false, null);
        dispatch(actions.finishDetailsHistory({ data: null, total: 0, hasMore: false }));
      });
    },
  }


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();