/* eslint-disable no-loop-func */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import moment from 'moment';

import * as dispatchCrudApi from '../../../api/CRUD/DispatchCRUD'
import Utils from "../../../utils/utils";
// import { } from '../../../utils/enums';

import { ParseResult } from "../../../utils/interfaces";
import { getReverseData } from "../import/Functions";

export enum Steps {
  None = 0,
  SelectFile = 1,
  ImportFile = 2,
  FailedTab = 3,
  SuccessTab = 4,
}

export interface HeaderColumnItem {
  value: number,
  label: string,
  name: string,
  title: any,
  type: string,
  successType: string,
  successRender: any|null,
  validation: any,
  required: boolean,
  item: any|null,
  itemField: any|null,
}

interface ReadExcelApiCallArgs {
  file: any,
  path: string,
}

interface BatchApiCallArgs {
  form: any,
  path: string,
  callback: (failedJobs: any, successJobs: any, successJobIds: any) => void,
}

interface InitState {
  isLoading: boolean,
  show: boolean,
  step: number,
  percent: number|null,

  excludeFirstRow: boolean,
  firstRow: any,
  headerDropdownColumns: Array<HeaderColumnItem>,
  headerColumns: Array<any|null>,
  headerColumnsFailed: Array<any|null>,
  headerColumnsSuccess: Array<any|null>,
  data: Array<any>,
  failedJobs: Array<any>,
  successJobs: Array<any>,
}

export const PER_CALL = 10;


function NewReducer() {
  const name = 'binCenterImport';


  const initialState: InitState = {
    isLoading: false,
    show: false,
    step: Steps.SelectFile,
    percent: null,

    excludeFirstRow: false,
    firstRow: null,
    headerDropdownColumns: [],
    headerColumns: [],
    headerColumnsFailed: [],
    headerColumnsSuccess: [],
    data: [],
    failedJobs: [],
    successJobs: [],
  };


  const reducers = {
    show: (state: InitState) => {
      state.step = Steps.SelectFile;
      state.show = true;
    },
    hide: (state: InitState) => {
      state.isLoading = false;
      state.show = false;
      state.step = Steps.SelectFile;
      state.percent = null;

      state.excludeFirstRow = false;
      state.firstRow = null;
      state.headerDropdownColumns = [];
      state.headerColumns = [];
      state.headerColumnsFailed = [];
      state.headerColumnsSuccess = [];
      state.data = [];
      state.failedJobs = [];
      state.successJobs = [];
    },
    
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
      state.percent = null;
    },
    nextStep: (state: InitState, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setPercent: (state: InitState, action: PayloadAction<number|null>) => {
      state.percent = action.payload;
    },

    setExcludeFirstRow: (state: InitState, action: PayloadAction<boolean>) => {
      state.excludeFirstRow = action.payload;
    },
    setFirstRow: (state: InitState, action: PayloadAction<any|null>) => {
      state.firstRow = action.payload;
    },
    setHeaderDropdownColumns: (state: InitState, action: PayloadAction<Array<HeaderColumnItem>>) => {
      state.headerDropdownColumns = action.payload;
    },
    setHeaderColumnItem: (state: InitState, action: PayloadAction<{ index: number, item: HeaderColumnItem|null }>) => {
      let index = action.payload.index;
      let item = action.payload.item;

      let headerColumns = state.headerColumns;
      headerColumns[index].selected = item;
      state.headerColumns = headerColumns;
    },
    setHeaderColumns: (state: InitState, action: PayloadAction<any>) => {
      let firstRow: any = action.payload;

      let headerArr: Array<any> = [];
      if(firstRow){
        headerArr = Object.keys({id: null, isDelete: false, isLoading: false, ...firstRow});
      }

      let selectedFields = state.headerColumns.filter((column: any) => column.selected !== null);

      let headerColumns: Array<any> = [];
      if(headerArr && headerArr.length > 0){
        headerArr.forEach((key: any, i: number) => {
          let selected = null;
          let selItm = selectedFields.filter(x => x.field === key);
          if(selItm && selItm.length > 0){
            selected = selItm[0].selected;
          }

          headerColumns.push({ 
            title: (firstRow && firstRow[key]) ? firstRow[key] : '',
            field: key,
            isAction: false,
            selected: selected,
          });
        });
      }
      headerColumns.unshift({ 
        title: '',
        field: 'action',
        isAction: true,
        selected: null,
      });

      state.headerColumns = headerColumns;
    },
    setData: (state: InitState, action: PayloadAction<Array<any>>) => {
      state.data = action.payload;
    },
    setFailedJobs: (state: InitState, action: PayloadAction<Array<any>>) => {
      state.failedJobs = action.payload;
    },
    setSuccessJobs: (state: InitState, action: PayloadAction<Array<any>>) => {
      state.successJobs = action.payload;
    },

    startReadExcel: (state: InitState) => {
      state.isLoading = true;
    },
    cancelReadExcel: (state: InitState) => {
      state.data = [];
      state.isLoading = false;
    },
    finishReadExcel: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.length > 0) ? action.payload : [];

      let arr = [];
      let firstRow: any = null;
      let headerArr: Array<any> = [];
      if(data && data.length > 0){
        headerArr = Object.keys({id: null, isDelete: false, isLoading: false, ...data[0].row});
        arr = data.map((item: any, i: number) => ({...item.row, id: i, isDelete: false, isLoading: false }));

        firstRow = arr[0];
        if(state.excludeFirstRow){
          // firstRow = arr[0];
          arr.splice(0, 1);
        }
      }

      let headerColumns: Array<any> = [];
      if(headerArr && headerArr.length > 0){
        headerArr.forEach((key: any, i: number) => {
          headerColumns.push({ 
            title: firstRow[key],
            field: key,
            isAction: false,
            selected: null,
          });
        });
      }
      headerColumns.unshift({ 
        title: '',
        field: 'action',
        isAction: true,
        selected: null,
      });

      state.data = arr;
      state.firstRow = firstRow;
      state.headerColumns = headerColumns;
      state.step = Steps.ImportFile;
      state.isLoading = false;
    },
    
    startBatch: (state: InitState) => {
      state.percent = 0;
      state.isLoading = true;
    },
    cancelBatch: (state: InitState) => {
      state.percent = 0;
      state.isLoading = false;
    },
    changeBatch: (state: InitState, action: PayloadAction<any>) => {
      let result = (action.payload && action.payload.result) ? action.payload.result : null;
      let callback = (action.payload && action.payload.callback) ? action.payload.callback : null;
      
      if(result){
        let failedJobs = (result && result.failedJobs && result.failedJobs.length > 0) ? result.failedJobs : [];
        let successJobs = (result && result.successJobs && result.successJobs.length > 0) ? result.successJobs : [];

        let headerColumns = state.headerColumns;
        let failedJobsObj = getReverseData(headerColumns, failedJobs);
        let successJobsObj = getReverseData(headerColumns, successJobs);

        let headerColumnsFailed: Array<any> = [];
        if(failedJobsObj && failedJobsObj.realHeaderArr && failedJobsObj.realHeaderArr.length > 0){
          failedJobsObj.realHeaderArr.forEach((item: any, i: number) => {
            headerColumnsFailed.push({ 
              title: item.title,
              field: item.realField,
              selected: item.selected,
              isAction: false,
            });
          });
          headerColumnsFailed.unshift({ 
            title: '',
            field: 'action',
            isAction: true,
            selected: null,
          });

          let failedJobsData = [...state.failedJobs, ...failedJobsObj.data].map((item: any, i: number) => ({...item, id: i }));

          state.failedJobs = failedJobsData;
          state.headerColumnsFailed = headerColumnsFailed;
        }

        let headerColumnsSuccess: Array<any> = [];
        if(successJobsObj && successJobsObj.realHeaderArr && successJobsObj.realHeaderArr.length > 0){
          successJobsObj.realHeaderArr.forEach((item: any, i: number) => {
            headerColumnsSuccess.push({ 
              title: item.title,
              field: item.realField,
              selected: item.selected,
              isAction: false,
            });
          });

          let successJobsData = [...state.successJobs, ...successJobsObj.data].map((item: any, i: number) => ({...item, id: i }));

          state.successJobs = successJobsData;
          state.headerColumnsSuccess = headerColumnsSuccess;
        }
        
        if(callback){
          let sJobIds: any = successJobsObj.data.map((x: any) => x.response.jobId);
          callback(failedJobsObj.reversedData, successJobsObj.reversedData, sJobIds);
        }
      } else {
        if(callback){
          callback(state.failedJobs, state.successJobs, null);
        }
      }
    },
    finishBatch: (state: InitState) => {
      let step = state.step;
      if(state.failedJobs.length > 0){
        step = Steps.FailedTab;
      } else if(state.successJobs.length > 0){
        step = Steps.SuccessTab;
      }

      state.step = step;
      state.isLoading = false;
    },
    
    startBatchRetry: (state: InitState) => {
      state.percent = 0;
      state.failedJobs = [];
      state.isLoading = true;
    },
    cancelBatchRetry: (state: InitState) => {
      state.percent = 0;
      state.isLoading = false;
    },
    changeBatchRetry: (state: InitState, action: PayloadAction<any>) => {
      let result = (action.payload && action.payload.result) ? action.payload.result : null;
      let callback = (action.payload && action.payload.callback) ? action.payload.callback : null;

      if(result){
        let failedJobs = (result && result.failedJobs && result.failedJobs.length > 0) ? result.failedJobs : [];
        let successJobs = (result && result.successJobs && result.successJobs.length > 0) ? result.successJobs : [];

        let headerColumns = state.headerColumns;
        let failedJobsObj = getReverseData(headerColumns, failedJobs);
        let successJobsObj = getReverseData(headerColumns, successJobs);

        let headerColumnsFailed: Array<any> = [];
        if(failedJobsObj && failedJobsObj.realHeaderArr && failedJobsObj.realHeaderArr.length > 0){
          failedJobsObj.realHeaderArr.forEach((item: any, i: number) => {
            headerColumnsFailed.push({ 
              title: item.title,
              field: item.realField,
              selected: item.selected,
              isAction: false,
            });
          });
          headerColumnsFailed.unshift({ 
            title: '',
            field: 'action',
            isAction: true,
            selected: null,
          });
        
          let failedJobsData = [...state.failedJobs, ...failedJobsObj.data].map((item: any, i: number) => ({...item, id: i }));

          state.failedJobs = failedJobsData;
          state.headerColumnsFailed = headerColumnsFailed;
        }

        let successJobsData = state.successJobs;
        let headerColumnsSuccess: Array<any> = [];
        if(successJobsObj && successJobsObj.realHeaderArr && successJobsObj.realHeaderArr.length > 0){
          successJobsObj.realHeaderArr.forEach((item: any, i: number) => {
            headerColumnsSuccess.push({ 
              title: item.title,
              field: item.realField,
              selected: item.selected,
              isAction: false,
            });
          });

          let successJobsData = [...state.successJobs, ...successJobsObj.data].map((item: any, i: number) => ({...item, id: i }));

          state.successJobs = successJobsData;
          state.headerColumnsSuccess = headerColumnsSuccess;
        }

        if(callback){
          let sJobIds: any = successJobsObj.data.map((x: any) => x.response.jobId);
          callback(failedJobsObj.reversedData, successJobsData, sJobIds);
        }
      } else {
        if(callback){
          callback(state.failedJobs, state.successJobs, null);
        }
      }
    },
    finishBatchRetry: (state: InitState) => {
      let step = state.step;
      if(state.failedJobs.length > 0){
        step = Steps.FailedTab;
      } else if(state.successJobs.length > 0){
        step = Steps.SuccessTab;
      }

      state.step = step;
      state.isLoading = false;
    },
  };


  const apis = {
    callReadExcelApi: (params: ReadExcelApiCallArgs) => async (dispatch: any) => {
      dispatch(actions.startReadExcel());

      const formData = new FormData();
      formData.append('file', params.file);

      await dispatchCrudApi.createApi(formData, params.path).then(result => {
        let data = result.data;
        
        dispatch(actions.finishReadExcel(data));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        dispatch(actions.cancelReadExcel());
      });
    },

    callBatchApi: (params: BatchApiCallArgs) => async (dispatch: any, getState: any) => {
      const { headerColumns } = getState().binCenterImport;
      let selectedFields = headerColumns.filter((column: any) => (column.selected !== null) && (column.selected.type == 'date')).map((x: any) => x.selected.name);
      
      let apis: any = [];
      if(params.form && params.form.length > 0){
        for(let i = 0; i < params.form.length; i=i+PER_CALL){
          let rows: Array<any> = [];

          for(let j = 0; j < PER_CALL; j++){
            if(params.form[(i+j)]){
              rows.push(params.form[(i+j)]);
            }
          }

          if(selectedFields && selectedFields.length > 0){
            if(rows && rows.length > 0){
              for(let r = 0; r < rows.length; r++){
                selectedFields.forEach((field: any, i: number) => {
                  rows[r][field] = moment(rows[r][field]).format('MM/DD/YYYY HH:mm:ss');
                })
              }
            }
          }

          apis.push({
            api: dispatchCrudApi.updateApi,
            path: params.path,
            params: () => {
              return {
                data: rows,
              };
            },
            callback: (result: any) => {
              let data = (result && result.data && result.data.data) ? result.data.data : null;
              dispatch(actions.changeBatch({ result: data, callback: params.callback }));
            }
          });
        }
      }

      Utils.callProgressApis(apis, 
      (percent: any) => {
        dispatch(actions.setPercent(percent));
      }, () => {
        dispatch(actions.startBatch());
      }, () => {
        dispatch(actions.finishBatch());
      }, () => {
        dispatch(actions.cancelBatch());
      });
    },
    
    callBatchRetryApi: (params: BatchApiCallArgs) => async (dispatch: any, getState: any) => {
      const { headerColumns } = getState().binCenterImport;
      let selectedFields = headerColumns.filter((column: any) => (column.selected !== null) && (column.selected.type == 'date')).map((x: any) => x.selected.name);
      
      let apis: any = [];
      if(params.form && params.form.length > 0){
        for(let i = 0; i < params.form.length; i=i+PER_CALL){
          let rows: Array<any> = [];

          for(let j = 0; j < PER_CALL; j++){
            if(params.form[(i+j)]){
              rows.push(params.form[(i+j)]);
            }
          }

          if(selectedFields && selectedFields.length > 0){
            if(rows && rows.length > 0){
              for(let r = 0; r < rows.length; r++){
                selectedFields.forEach((field: any, i: number) => {
                  rows[r][field] = moment(rows[r][field]).format('DD/MM/YYYY HH:mm:ss');
                })
              }
            }
          }

          apis.push({
            api: dispatchCrudApi.updateApi,
            path: params.path,
            params: () => {
              return {
                data: rows,
              };
            },
            callback: (result: any) => {
              let data = (result && result.data && result.data.data) ? result.data.data : null;
              dispatch(actions.changeBatchRetry({ result: data, callback: params.callback }));
            }
          });
        }
      }

      Utils.callProgressApis(apis, 
      (percent: any) => {
        dispatch(actions.setPercent(percent));
      }, () => {
        dispatch(actions.startBatchRetry());
      }, () => {
        dispatch(actions.finishBatchRetry());
      }, () => {
        dispatch(actions.cancelBatchRetry());
      });
    },

    callUpdateSignalRApi: (params: any) => async () => {
      await dispatchCrudApi.updateApi(params, 'job/signal-r');
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();