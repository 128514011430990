import React from "react";
import moment from 'moment';
import parse from 'html-react-parser';

import { 
    Row,
    Col,
    Button,
} from "react-bootstrap-v5";

import Utils from "../../utils/utils";

import Popup from '../../components/popup/Popup';
import DataTable from "../../components/OLD/DataTable/DataTable";
import DataTableAjaxPagination from '../../components/OLD/DataTable/Pagination/DataTableAjaxPaginationV5';

import { connect } from 'react-redux'
import { clear } from "../../../setup/redux/actions";
import { dispatchApiCallGet } from '../../../setup/redux/dispatch/actions'


class AuditTrailList extends React.Component {
  
    constructor(props) {
        super(props);

        this.pageName = 'audit_trail_history';
        this.pageIdField = "auditTrailId";

        this.id = Utils.getIntProps(props);
        this.objectType = Utils.getIntProps(props, 'objectType');
        this.pageCaption = Utils.getStringProps(props, 'pageCaption');

        this.refHistoryForm = React.createRef();
        this.tableRef = React.createRef();
        
        let user = (props && props.auth && props.auth.user) ? props.auth.user : null;
        this.permissions = (user && user.permissions && user.permissions.length > 0) ? user.permissions : [];
        
        
        this.updatedTemplate = (id, row, col, obj) => {
            return moment(row.created).format('DD-MM-YYYY hh:mm A')
        };
        this.updatedExportTemplate = (row) => {
            return moment(row.updated).format("LLL");
        };

        this.detailsTemplate = (id, row, col, obj) => {
            // return (row.created != row.updated) ? 
            return <a href="/" className="link-primary" 
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();

                            this.setState({
                                popupShowHistoryForm: true,
                                popupLoadingHistoryForm: false,
                                popupIdHistoryForm: id,
                                popupIsCopyHistoryForm: false,
                            });
                        }
                    }>View Changes</a> 
                    // : <span>New</span>
        };
        this.detailsExportTemplate = (row) => {
            // return (row.created != row.updated) ? 'View Changes' : 'New';
            return 'View Changes';
        };

        this.descriptionTemplate = (id, row, col, obj) => {
            return <div>{parse(row.description)}</div>
        };
        this.descriptionExportTemplate = (row) => {
            return parse(row.description);
        };

        let columns = [
            { 
                id: this.pageIdField, 
                filterKey: this.pageIdField, 
                name: this.pageIdField, 
                label: 'ID',
                visible: false
            },
            {
                id: "updated",
                name: "updated",
                label: "DATE & TIME",
                responsiveLabel: "DATE & TIME",
                orderable: true,
                template: this.updatedTemplate,
                exportTemplate: this.updatedExportTemplate,
            },
            {
                id: "details",
                name: "details",
                label: "DETAILS",
                responsiveLabel: "DETAILS",
                orderable: false,
                template: this.detailsTemplate,
                exportTemplate: this.detailsExportTemplate,
            },
            {
                id: "description",
                name: "description",
                label: "DESCRIPTION",
                responsiveLabel: "DESCRIPTION",
                orderable: true,
                template: this.descriptionTemplate,
                exportTemplate: this.descriptionExportTemplate,
            },
            {
                id: "updatedBy",
                name: "updatedBy",
                label: "UPDATED BY",
                responsiveLabel: "UPDATED BY",
                orderable: true,
            },
        ];
        let initColumns = Array.from(columns);
        columns = Array.from(initColumns);
        
        
        let columnVisibility = [
            false,
            true,
            true,
            true,
            true
        ];
        let initColumnVisibility = Array.from(columnVisibility);
        columnVisibility = Array.from(initColumnVisibility);
    
        
        this.state = {
            details: null,
            
            popupShowHistoryForm: false,
            popupLoadingHistoryForm: false,
            popupIdHistoryForm: 0,
            popupIsCopyHistoryForm: false,

            search: '',
            sortColumn: 'created',
            sortDir: 'desc',
            isIncludeInactive: null,
            isInactive: false,
            
            selected: [],
            columnVisibility: columnVisibility,
            initColumnVisibility: initColumnVisibility,
            columns: columns,
            initColumns: initColumns,
            rows: [],
            currentPage: 1,
            totalRows: 0,
            rowsPerPageSelected: 10,
            rowsPerPageOptions: [10, 20, 30],
        };
    }


    componentDidMount(){
        this.callReadHistoryApi();
    }

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            this.pageName + '-read_history', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = [];
                let total = 0;
                if(data && data.data && data.data.length > 0){
                    arr = data.data;
                    total = data.total;
                }

                this.setState({
                    rows: arr,
                    totalRows: total,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    this.setLoading(false);

                    if(callback){
                        callback(arr, total);
                    }
                });
            }
        );
    }
	
    callReadHistoryApi = (callback = null) => {
        this.setLoading(true);
        
        let data = {
            currentPage: this.state.currentPage,
            pageSize: this.state.rowsPerPageSelected,
            searchQuery: this.state.search,
            sortColumn: this.state.sortColumn,
            sortDir: this.state.sortDir,
            objectId: this.id,
            objectType: this.objectType,
        };
   
        let isIncludeInactive = ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : null;
        if(isIncludeInactive){
            data['isIncludeInactive'] = isIncludeInactive;
        }

        let isInactive = ((this.state.isInactive === true) || (this.state.isInactive === false)) ? this.state.isInactive : null;
        if(isInactive){
            data['isInactive'] = isInactive;
        }

        this.props.dispatchApiCallGet(data, this.pageName + '-read_history', 'audittrail', null, callback, () => {});
    }
    /* END API */


    /* FUNCTIONS */
    setLoading = (state = false) => {
        if(this.props.onLoading){
            this.props.onLoading(state);
        }
    }
    /* END FUNCTIONS */


    /* FORM */
    rightBody = () => {
        return <div>
            {this.setDataTable()}
            {this.paginationSection()}
        </div>
    }

    setDataTable = () => {
        return <DataTable 
            ref={this.tableRef}

            className={"table-style-v4"}
            classNameWrapper={"table-v4"}

            pageName={this.pageName}
            rowId={this.pageIdField}
            caption={this.pageCaption}
            
            noMarginBottom={true}
            verticalAlign={'middle'}
            
            stickyHeader={false}
            isHeader={true}
            resizable={false}
            isDragableHeaderColumns={false}
            isDragable={false}
            isCheckbox={false}
            isBorder={false}
            isPagination={false}
            isVirtualize={false}
            
            columnVisibility={this.state.columnVisibility}

            isLoading={this.state.isLoading}
            rowsPerPageSelected={this.state.rowsPerPageSelected}
            rowsPerPageOptions={this.state.rowsPerPageOptions}

            search={this.state.search}
            rows={this.state.rows}
            columns={this.state.columns}

            onRemove={(row) => {

            }}

            onClickCheckAll={(obj, checked, isDisabledBatchAction) => {
                
            }}
            onCheckRow={(obj, id, isDisabledBatchAction) => {
                
            }}
            
            onSortEnd={(columns, columnVisibility) => {
                this.setState({
                    columns: columns,
                    columnVisibility: columnVisibility,
                });
            }}
            onOrder={(name, sort) => {
                this.setState({
                    sortColumn: name,
                    sortDir: sort,
                }, () => {
                    this.callReadHistoryApi();
                });
            }}
            onResize={() => {
                
            }}
        />
    }

	paginationSection = () => {
		return <DataTableAjaxPagination
            style={{ marginTop: '20px' }}
            page={this.state.currentPage}
            selected={this.state.rowsPerPageSelected}
            options={this.state.rowsPerPageOptions}
            count={this.state.totalRows}
            fixed={false}
            onPageChange={(page) => {
                this.setState({
                    currentPage: page,
                }, () => {
                    this.callReadHistoryApi();
                });
            }}
            onOptionsChange={(option) => {
              this.setState({
                currentPage: 1,
                rowsPerPageSelected: option,
              }, () => {
                this.callReadHistoryApi();
              });
            }}
        />
	}
    /* END FORM */


    /* DIALOG */
    setPopupHistoryForm = () => {
        return <Popup 
            className={'no-bg'}
            auth={this.props.auth}
            settingsType={this.props.settingsType}

            customerId={this.state.customerId}
            objectId={this.id}
            objectType={this.objectType}
            
            show={this.state.popupShowHistoryForm}
            isLoading={this.state.popupLoadingHistoryForm}
            id={this.state.popupIdHistoryForm}
            isCopy={this.state.popupIsCopyHistoryForm}

            showHeader={true}
            centerHeader={'start'}
            header={'View Changes'}
            showFooter={true}
            centerFooter={'start'}
            footer={({ isLoading, id, onHide }) => {
                return <Row style={{ width: '100%' }}>
                    <Col xs={'auto'}>
                        <Button
                            variant={'light'}
                            disabled={isLoading}
                            onClick={onHide}
                        >Cancel</Button>
                    </Col>
                </Row>
            }}

            scrollable={true}
            centered={true}
            closeButton={true}
            fullscreen={true}
            noBodyPadding={false}
            size={'xl'}

            onHide={() => {
                this.setState({
                    popupShowHistoryForm: false,
                    popupLoadingHistoryForm: false,
                    popupIdHistoryForm: 0,
                    popupIsCopyHistoryForm: false,
                });
            }}
            
            onLoading={(state) => {
                this.setState({
                    popupLoadingHistoryForm: state,
                });
            }}
        >
            {(p) => {
                if(this.props && this.props.HistoryForm){
                    let HistoryForm = this.props.HistoryForm;
                    return <HistoryForm
                        ref={this.refHistoryForm}
                        {...p}
                    />
                }
            }}
        </Popup>
    }
    /* END DIALOG */


    render() {
        return <>
            <Row style={{ width: '100%' }}>
                <Col xs={12} className={'p-0'}>
                    {this.rightBody()}
                </Col>
            </Row>
            {this.setPopupHistoryForm()}
        </>
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AuditTrailList);
