import React from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { ReceiveMessage } from './Enums.jsx';

import { useDispatch, useSelector } from "react-redux";
import signalRslice from './signalRslice';

import ProgressBarPopup from './ProgressBarPopup';


const { REACT_APP_REAL_TIME_DOWNLOAD_URL } = process.env;


const SignalRDownload = React.forwardRef((props: any, ref: any) => {
    const dispatch = useDispatch();
    const { connection } = useSelector((state) => state.signalRslice);

    
    React.useImperativeHandle(
        ref,
        () => ({
            connect() {
                connect();
            },
            disconnect() {
                disconnect();
            },
            sendMessage(message) {
                sendMessage(message);
            },
         }),
    );


    const connect = () => {
        let conn = connection;

        try {
            conn = new HubConnectionBuilder()
                .withUrl(REACT_APP_REAL_TIME_DOWNLOAD_URL)
                .withAutomaticReconnect([0, 2000, 10000, 30000])
                .build();

            conn.serverTimeoutInMilliseconds = 60000;

            conn.start().then(() => {
                dispatch(signalRslice.setConnection(conn));

                conn.on(ReceiveMessage.Progress, message => {
                    let percentage = (message && (typeof message.percentage === 'number')) ? message.percentage : 0;
                    dispatch(signalRslice.onPercentage(percentage));
                });
            });
        } catch (err) {}
    }
    
    const disconnect = () => {
        try {
            connection.stop();
        } catch (err) {}
    }

    const sendMessage = async (message) => {
        const msg = {
            message: message
        };

        try {
            await connection.send('SendMessage', msg);
        } catch(e) {}
    }


    return <ProgressBarPopup />
});


export default SignalRDownload;
