import React from 'react';

import {
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener
} from '@material-ui/core';


const DropdownFilter = props => {
    const { text, color, leftIcon, rightIcon, variant, children, className, classNamePopper } = props;

    const [open, setOpen] = React.useState((props.open === true || props.open === false) ? props.open : false);
    const [isClickAway, setIsClickAway] = React.useState((props.isClickAway === true || props.isClickAway === false) ? (props.isClickAway === true ? 'onClick' : false) : 'onClick');

    const anchorRef = React.useRef(null);

    const CustomButton = props.button || Button;


    const handleToggle = (e) => {
      e.preventDefault();
      e.stopPropagation();
      
      setOpen(prevOpen => !prevOpen);
      
      if(!open && props.onOpen){
        props.onOpen()
      }
    };
  
    const handleClose = event => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
  
      setOpen(false);
    };


    const prevOpen = React.useRef(open);
    React.useEffect(() => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
      }
      
      prevOpen.current = open;

      setIsClickAway((props.isClickAway === true || props.isClickAway === false) ? (props.isClickAway === true ? 'onClick' : false) : 'onClick');
    }, [open, props.isClickAway]);


    if(children) {
        return (
            <>
                <CustomButton
                  ref={anchorRef}
                  className={className + ((open) ? ' is-open' : '')}
                  style={{ height: '50px' }}
                  aria-haspopup="true"
                  color={(color ? color : 'primary')}
                  variant={(variant ? variant : 'contained')}
                  onClick={handleToggle}
                >
                  {leftIcon}{text}{rightIcon}
                </CustomButton>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition
                  className={classNamePopper}
                  style={{ zIndex: 9999 }}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                            >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose} mouseEvent={isClickAway}>
                                    {children}
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </>
        );
    } else {
        return null;
    }
  }

  export default DropdownFilter;