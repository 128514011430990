import React, { Component } from 'react';

import { JobStepType } from '../../../../utils/enums';

import { 
    Box,
    Grid,
    Button,
    IconButton,
    Icon,
} from '@material-ui/core';

import CircularProgressBar from '../../../../components/OLD/ProgressBar/CircularProgressBar.jsx';
import CustomDropdownSelect from '../../../../components/OLD/Autocomplete/CustomDropdownSelect.jsx';
import ManageLabel from '../../../../components/OLD/input/ManageLabel.jsx';
import ManageInput from '../../../../components/OLD/input/ManageInput.jsx';


class TopSection extends Component {

    constructor(props){
        super(props);

        this.jobTypeRef = React.createRef();

        let id = (props.id && props.id > 0) ? props.id : 0;
        let isCopy = ((props.isCopy === true || props.isCopy === false) ? props.isCopy : false);
        let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
        let disabled = ((props.disabled === true || props.disabled === false) ? props.disabled : false);
        let jobType = (props.jobType && props.jobType) ? props.jobType : null;
        
        let jobTypes = [
            {
                value: JobStepType.Exchange.toString(),
                title: 'EXCHANGE',
            },
            {
                value: JobStepType.Pull.toString(),
                title: 'PULL',
            },
            {
                value: JobStepType.Put.toString(),
                title: 'PUT',
            },
            {
                value: JobStepType.OnTheSpot.toString(),
                title: 'ON THE SPOT',
            },
            {
                value: JobStepType.Out.toString(),
                title: 'OUT',
            },
        ];

        let name = '';
        let desc = '';

        this.state = {
            id: id,
            isCopy: isCopy,
            isLoading: isLoading,
            disabled: disabled,
            
            jobTypes: jobTypes,

            jobType: jobType,
            name: name,
            desc: desc,

            nameValidated: false,
        }
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.isCopy !== this.state.isCopy) {
            let isCopy = ((nextProps.isCopy === true || nextProps.isCopy === false) ? nextProps.isCopy : false);
            this.setState({
                isCopy: isCopy
            });
        }
        if (nextProps.isLoading !== this.state.isLoading) {
            let isLoading = ((nextProps.isLoading === true || nextProps.isLoading === false) ? nextProps.isLoading : false);
            this.setState({
                isLoading: isLoading
            });
        }
        if (nextProps.disabled !== this.state.disabled) {
            let disabled = ((nextProps.disabled === true || nextProps.disabled === false) ? nextProps.disabled : false);
            this.setState({
                disabled: disabled
            });
        }
        if (nextProps.jobType !== this.state.jobType) {
            let jobType = (nextProps.jobType && nextProps.jobType) ? nextProps.jobType : null;
            this.setState({
                jobType: jobType
            });
        }
    }


    /* GETTER & SETTER */
    getSelectedJobTypes = (jobTemplateType) => {
        let jobType = null;

        if(this.state.jobTypes && this.state.jobTypes.length > 0){
            let item = this.state.jobTypes.filter(x => x.value === jobTemplateType.toString());
            if(item && item.length > 0){
                jobType = item[0];
            }
        }
        return jobType
    }

    getJobType = () => {
        return this.state.jobType
    }
    setJobType = (jobType = null) => {
        if(this.jobTypeRef && this.jobTypeRef.current){
            this.jobTypeRef.current.selectAll([ jobType ]);
        }
    }

    getName = () => {
        return this.state.name
    }
    setName = (name = '') => {
        this.setState({
            name: name,
        });
    }

    getDesc = () => {
        return this.state.desc
    }
    setDesc = (desc = '') => {
        this.setState({
            desc: desc,
        });
    }
    /* END GETTER & SETTER */


    
    setClose = () => {
        return <IconButton
            className={"custom-close-btn"}
            onClick={() => {
                if(this.props.onClose){
                    this.props.onClose();
                }
            }}
        >
            <Icon component={'i'}>close</Icon>
        </IconButton>
    }

    setJobTypeForm = () => {
        return <>
            <ManageLabel
                className={'no-margin'}
                label={"Job Type Template"}
                weight={'normal'}
                size={'xs'}
            />
            <CustomDropdownSelect 
                ref={this.jobTypeRef}
                className={'job-type-form'}
                valueField={'value'}
                labelField={'title'}
                clearable={true}
                values={this.state.jobType}
                options={this.state.jobTypes}
                contentRenderer={(obj) => {
                    let values = (obj && obj.state && obj.state.values && obj.state.values.length > 0) ? obj.state.values : [];
                
                    if(values && values.length > 0){
                        return values.map((value, i) => {
                            let item = (value && value.title) ? value.title : '';

                            return <b key={i}>{item}</b>;
                        })
                    } else {
                        return <b>Select Job Type</b>
                    }
                }}
                dropdownHandleRenderer={(obj) => {
                    let values = (obj && obj.state && obj.state.values && obj.state.values.length > 0) ? obj.state.values : [];
                
                    return (values && values.length > 0) ? null : <Icon className={"custom-dropdown-icon"} component={'i'}>arrow_drop_down</Icon>;
                }}
                clearRenderer={(obj) => {
                    let values = (obj && obj.state && obj.state.values && obj.state.values.length > 0) ? obj.state.values : [];
                    
                    return (values && values.length > 0) ? <Icon className={"custom-close-icon"} component={'i'} onClick={(e) => { 
                        e.stopPropagation();
                        obj.methods.clearAll();

                        this.setState({
                            jobType: null,
                        }, () => {
                            if(this.props.onChangeJobType){
                                this.props.onChangeJobType(this.state.jobType);
                            }
                        });
                    }}>close</Icon> : null;
                }}
                onChange={(values) => {
                    if(values && values.length > 0){
                        this.setState({
                            jobType: values[0]
                        }, () => {
                            if(this.props.onChangeJobType){
                                this.props.onChangeJobType(this.state.jobType);
                            }
                        });
                    } else {
                        this.setState({
                            jobType: null,
                        }, () => {
                            if(this.props.onChangeJobType){
                                this.props.onChangeJobType(this.state.jobType);
                            }
                        });
                    }
                }}
            />
            <ManageLabel
                className={'no-margin'}
                label={<>&nbsp;</>}
                weight={'normal'}
                size={'xs'}
            />
        </>
    }
    setJobTypeNameForm = () => {
        return <>
            <ManageLabel
                className={'no-margin'}
                label={"Job Type Name"}
                weight={'normal'}
                size={'xs'}
            />
            <ManageInput
                value={this.state.name}
                name={"jobName"}
                placeholder={"Job type name"}
                required={true}
                error={this.state.nameValidated} 
                inputProps={{
                    maxLength: 30
                }}
                onChange={(value) => {
                    this.setState({
                        name: value.target.value,
                    });
                }}
                onBlur={() => {
                    if(this.props.onChangeName){
                        this.props.onChangeName(this.state.name);
                    }
                }}
            />
            <ManageLabel
                className={'no-margin'}
                label={'You can enter up to 30 characters'}
                weight={'normal'}
                size={'xs'}
            />
        </>
    }

    setJobTypeDescForm = () => {
        return <>
            <ManageLabel
                className={'no-margin'}
                label={"Job Type Desc"}
                weight={'normal'}
                size={'xs'}
            />
            <ManageInput
                value={this.state.desc}
                name={"jobDescription"}
                placeholder={"Job type description"}
                onChange={(value) => {
                    this.setState({
                        desc: value.target.value,
                    });
                }}
                onBlur={() => {
                    if(this.props.onChangeDesc){
                        this.props.onChangeDesc(this.state.desc);
                    }
                }}
            />
            <ManageLabel
                className={'no-margin'}
                label={<>&nbsp;</>}
                weight={'normal'}
                size={'xs'}
            />
        </>
    }

    setButtons = () => {
        return <Grid container>
            <Box clone pr={2}>
                <Grid item xs={true}>
                    <Button 
                        color={'default'} 
                        variant={'contained'}
                        style={{ textTransform: 'capitalize' }}
                        fullWidth
                        onClick={() => {
                            if(this.props.onClose){
                                this.props.onClose();
                            }
                        }}
                    >
                        Cancel
                    </Button>
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={true}>
                    <Button 
                        type={'submit'}
                        color={'primary'} 
                        variant={'contained'}
                        style={{ textTransform: 'capitalize' }}
                        fullWidth
                        disabled={this.state.isLoading}
                    >
                        
                        {(this.state.isCopy) ? 'Save' : (this.state.id > 0) ? 'Update' : 'Save'}
                        {this.state.isLoading && <CircularProgressBar size={15} />}
                    </Button>
                </Grid>
            </Box>
        </Grid>
    }


    render() {
        return <Box>

            {/* CLOSE */}
            {/* <Box textAlign={'right'}>
                {this.setClose()}
            </Box> */}
            {/* END CLOSE */}
            
            <Box pt={1}>
                <Grid container alignItems={'center'}>
                    <Box clone>
                        <Grid item xs={12} md={true}>
                            <Grid container alignItems={'center'}>
                                {/* <Box clone pr={{ xs: 0, md: 2 }}>
                                    <Grid item xs={12} md={4} lg={3} xl={3}>
                                        {this.setJobTypeForm()}
                                    </Grid>
                                </Box> */}
                                <Box clone pr={{ xs: 0, md: 2 }} pb={{ xs: 2, md: 0 }}>
                                    <Grid item xs={12} md={4} lg={3} xl={3}>
                                        {this.setJobTypeNameForm()}
                                    </Grid>
                                </Box>
                                <Box clone pr={{ xs: 0, md: 2 }}>
                                    <Grid item xs={12} md={4} lg={6} xl={6}>
                                        {this.setJobTypeDescForm()}
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box clone pt={{ xs: 2, md: 0 }}>
                        <Grid item xs={12} md={4} lg={3} xl={2}>
                            {this.setButtons()}
                        </Grid>
                    </Box>
                </Grid>
            </Box>

        </Box>
    }
}

export default TopSection;
