import React, { Component } from "react";
import moment from "moment";
import NumberFormat from 'react-number-format';

import apiUtil from "../../../api/apiUtil.jsx";
import Utils from "../../../utils/utils";
import ReportApi from "../../../api/OLD/Dispatch/Report.jsx";
import Driver from "../../../api/OLD/Dispatch/Driver.jsx";

import {
  Box,
  Grid,
  Button,
  IconButton,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";

import { ReactComponent as MailIcon } from "../../../../_metronic/assets/img/icons/report-mail.svg";
import { ReactComponent as PrintIcon } from "../../../../_metronic/assets/img/icons/report-print.svg";
import { ReactComponent as DownloadIcon } from "../../../../_metronic/assets/img/icons/report-download.svg";
import { ReactComponent as EditIcon } from "../../../../_metronic/assets/img/icons/report-edit.svg";
import CustomerInfo from "../../../components/OLD/Pages/Reports/CustomerInfo.jsx";

import {
  ReportTable,
  ReportCaption,
  ReportThead,
  ReportTbody,
  ReportTfoot,
  ReportTr,
  ReportTh,
  ReportTd,
} from "../../../components/OLD/Pages/Reports/CustomTable.jsx";

import ReportsLayout from "../Layout/ReportsLayout.jsx";
import SendEmailDialog from "../Dialogs/SendEmailDialog.jsx";


import Snackbar from "../../../components/OLD/Snackbar/Snackbar.jsx";
import IconsList from "../../../components/OLD/IconsList/IconsList.jsx";
import ManageLabel from "../../../components/OLD/input/ManageLabel.jsx";
import RSuiteDateRangePicker from "../../../components/OLD/Datepicker/RSuiteDateRangePickerCostume.jsx";
import RSuiteCheckPicker from "../../../components/OLD/Checkbox/RSuiteCheckPicker.jsx";
import CustomDialog from "../../../components/OLD/Dialog/CustomDialog.jsx";
import DropdownFilter from "../../../components/OLD/Dropdown/DropdownFilter.jsx";
import ManageInput from "../../../components/OLD/input/ManageInput.jsx";

import { connect } from 'react-redux'
import signalRslice from "../../../components/signalR/signalRslice";


class DriverTripPay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      report: null,

      pageName: "Driver trip pay",
      isIconClicked: false,

      onEditToggler: false,



      isSendEmailDialog: false,
      htmlSendEmailDialog: "",

      showError: false,
      errorText: "",
      errorSeverity: null,

      download: "1",

      dateRange: apiUtil.getDateRangeValue("This Month"),
      dateFormat: apiUtil.getAPIDateFormat(),

      driverNumberIsLoading: false,
      driverNumberText: "",
      driverNumberId: "",
      driverNumberItem: null,
      driverNumber: [],
      driverPayDayFrist: null,
      driverPayDayLast: null,
      totalRow: [],
    };
  }

  componentDidMount() { }

  /* BIN NUMBERS API */

  DriverReadApi = (searchQuery = "", callback = null) => {
    const { token } = this.context;

    this.setState({
      driverNumberIsLoading: true,
      driverNumberId: [],
    });

    Driver.read(
      {
        currentPage: 1,
        pageSize: apiUtil.getMaxPageSize(),
        // searchQuery: searchQuery,
        isIncludeInactive: false,
        sortColumn: "driverName",
        sortDir: "asc",
      },
      token
    ).then((result) => {
      apiUtil.parseResult(
        result,
        (data) => {
          let arr = [];

          if (data && data.data && data.data.length > 0) {
            for (var i = 0; i < data.data.length; i++) {
              let item = data.data[i];

              let id = item.driverId;
              let name = item.driverName;

              if (arr.findIndex((x) => x.value === id) === -1) {
                arr.push({
                  value: id,
                  title: name,
                  item: item,
                });
              }
            }
          }

          this.setState(
            {
              driverNumber: arr,
              driverNumberIsLoading: false,
            },
            () => {
              if (callback !== null) {
                callback(this.state.driverNumber);
              }
            }
          );
        },
        (error, type) => {
          this.setState({
            driverNumber: [],
            driverNumberIsLoading: false,
          });
        }
      );
    });
  };
  /* END BIN NUMBERS API */

  /* REPORT API */
  callReportReadApi = async (callback = null) => {
    const { token } = this.context;

    await this.props.action.signalRslice.connect({
      callback: (percent) => {
        this.props.action.signalRslice.onPercentage(percent);
      },
      callbackInit: (hubId) => {}
    });

    Utils.clickOutside();

    this.setState({
      isLoading: false,
      report: null,
    });

    let fromD =
      this.state.dateRange && this.state.dateRange.length > 0
        ? this.state.dateRange[0]
        : "";
    let toD =
      this.state.dateRange && this.state.dateRange.length === 2
        ? this.state.dateRange[1]
        : "";

    let fromDate =
      fromD !== "" ? moment(fromD).format(this.state.dateFormat) : "";
    let toDate = toD !== "" ? moment(toD).format(this.state.dateFormat) : "";

    let driverIds = this.state.driverNumberSearchColumns.join(",");

    let formData = {
      fromDate: fromDate,
      toDate: toDate,
      driverIds: driverIds,
      hubId: this.props.signalRslice.hubId,
    };

    ReportApi.DriverTripPay("/driver-trip-pay", formData, token).then(
      (result) => {
        apiUtil.parseResult(
          result,
          (data) => {
            this.setState(
              {
                report: data.data,
                isLoading: false,
              },
              () => {
                if (callback !== null) {
                  callback(this.state.report);
                }
              }
            );

            this.props.action.signalRslice.disconnect();
          },
          (error, type) => {
            this.setState({
              report: null,
              isLoading: false,
            });

            this.props.action.signalRslice.disconnect();
          }
        );
      }
    );
  };


  callRecalculateApi = async (callback = null) => {
    const { token } = this.context;

    await this.props.action.signalRslice.connect({
      callback: (percent) => {
        this.props.action.signalRslice.onPercentage(percent);
      },
      callbackInit: (hubId) => {}
    });
    
    Utils.clickOutside();

    this.setState({
      isLoading: false,
      report: null,
    });

    let fromD =
      this.state.dateRange && this.state.dateRange.length > 0
        ? this.state.dateRange[0]
        : "";
    let toD =
      this.state.dateRange && this.state.dateRange.length === 2
        ? this.state.dateRange[1]
        : "";

    let fromDate =
      fromD !== "" ? moment(fromD).format(this.state.dateFormat) : "";
    let toDate = toD !== "" ? moment(toD).format(this.state.dateFormat) : "";

    let driverIds = this.state.driverNumberSearchColumns.join(",");

    let formData = {
      fromDate: fromDate,
      toDate: toDate,
      driverIds: driverIds,
      hubId: this.props.signalRslice.hubId,
    };

    ReportApi.DriverTripPay("/recalculate-driver-trip-pay", formData, token).then(
      (result) => {
        apiUtil.parseResult(
          result,
          (data) => {
            this.setState(
              {
                report: data.data,
                isLoading: false,
              },
              () => {
                if (callback !== null) {
                  callback(this.state.report);
                }
              }
            );

            this.props.action.signalRslice.disconnect();
          },
          (error, type) => {
            this.setState({
              report: null,
              isLoading: false,
            });

            this.props.action.signalRslice.disconnect();
          }
        );
      }
    );
  };

  callGeneratePDFApi = async (fileName = "", html = "", callback = null) => {
    const { token } = this.context;

    await this.props.action.signalRslice.connect({
      callback: (percent) => {
        this.props.action.signalRslice.onPercentage(percent);
      },
      callbackInit: (hubId) => {}
    });
    
    Utils.clickOutside();

    ReportApi.generatePDF(
      {
        fileName: fileName,
        htmlContent: html,
        hubId: this.props.signalRslice.hubId,
      },
      token
    ).then((result) => {
      apiUtil.parseResult(
        result,
        (data) => {
          if (callback !== null) {
            callback(fileName, data);
          }

          this.props.action.signalRslice.disconnect();
        },
        (error, type) => {
          if (callback !== null) {
            callback(null, null);
          }

          this.props.action.signalRslice.disconnect();
        }
      );
    });
  };

  callGenerateEXCELApi = async (callback = null) => {
    const { token } = this.context;

    await this.props.action.signalRslice.connect({
      callback: (percent) => {
        this.props.action.signalRslice.onPercentage(percent);
      },
      callbackInit: (hubId) => {}
    });
    
    Utils.clickOutside();

    let fromD =
      this.state.dateRange && this.state.dateRange.length > 0
        ? this.state.dateRange[0]
        : "";
    let toD =
      this.state.dateRange && this.state.dateRange.length === 2
        ? this.state.dateRange[1]
        : "";

    let fromDate =
      fromD !== "" ? moment(fromD).format(this.state.dateFormat) : "";
    let toDate = toD !== "" ? moment(toD).format(this.state.dateFormat) : "";

    let driverIds = this.state.driverNumberSearchColumns.join(",");

    let formData = {
      fromDate: fromDate,
      toDate: toDate,
      driverIds: driverIds,
      remarks: (this.state.report && this.state.report.remarks) ? this.state.report.remarks : '-',
      hubId: this.props.signalRslice.hubId,
    };

    ReportApi.generatePostEXCEL("/driver-trip-pay-excel", formData, token).then(
      (result) => {
        apiUtil.parseResult(
          result,
          (data) => {
            if (callback !== null) {
              callback(data);
            }

            this.props.action.signalRslice.disconnect();
          },
          (error, type) => {
            if (callback !== null) {
              callback(null);
            }

            this.props.action.signalRslice.disconnect();
          }
        );
      }
    );
  };

  callSendPDFEmailApi = (
    emailAddresses = "",
    fileName = "",
    html = "",
    callback = null
  ) => {
    const { token } = this.context;

    ReportApi.sendPDFEmail(
      {
        emailAddresses: emailAddresses,
        emailSubject: fileName,
        fileName: fileName,
        htmlContent: html,
      },
      token
    ).then((result) => {
      apiUtil.parseResult(
        result,
        (data) => {
          this.setState(
            {
              showError: true,
              errorText: "Email sent successfully!",
              errorSeverity: "success",
            },
            () => {
              if (callback) {
                callback();
              }
            }
          );
        },
        (error, type) => {
          this.setState(
            {
              showError: true,
              errorText: error,
              errorSeverity: type,
            },
            () => {
              if (callback) {
                callback();
              }
            }
          );
        }
      );
    });
  };
  /* END REPORT API */

  /* FUNCTIONS */
  isRunReportBtnDisabled = () => {
    let driverNumberSearchColumns =
      this.state.driverNumberSearchColumns &&
        this.state.driverNumberSearchColumns.length > 0
        ? this.state.driverNumberSearchColumns.length
        : 0;

    return driverNumberSearchColumns > 0 ? false : true;
  };
  /* END FUNCTIONS */

  setSnackBar = () => {
    return (
      <Snackbar
        open={this.state.showError}
        text={this.state.errorText}
        severity={this.state.errorSeverity}
        onClose={() => {
          this.setState({
            showError: false,
            errorText: "",
            errorSeverity: null,
          });
        }}
      />
    );
  };

  /* SECTIONS */
  topSection = () => {
    return (
      <Box>
        <Grid container spacing={2} alignItems={"center"}>
          <Box clone>
            <Grid item xs={12} md={3} lg={4}>
              <ManageLabel
                className={"no-margin"}
                label={"Reporting periods"}
                weight={"normal"}
                size={"xs"}
              />
              <Box>
                <RSuiteDateRangePicker
                  className={"reports-date-picker"}
                  placeholder={"Select date"}
                  //format={"DD.MM.YYYY"}
                  ranges={Utils.getDateRanges(['Last 6 Months'])}
                  value={this.state.dateRange}
                  cleanable={false}
                  renderValue={(value, format) => {
                    let date =
                      moment(value[0]).format(Utils.getDefaultDateRangeFormat()) +
                      " - " +
                      moment(value[1]).format(Utils.getDefaultDateRangeFormat());
                    let label = apiUtil.getDateRangeLabel(value[0], value[1]);

                    return (
                      <span>
                        {label && label !== "Custom"
                          ? label + " (" + date + ")"
                          : date}
                      </span>
                    );
                  }}
                  onChange={(value) => {
                    this.setState({
                      dateRange: value,
                    });
                  }}
                />
              </Box>
            </Grid>
          </Box>

          <Box clone>
            <Grid item xs={12} md={3} lg={4}>
              <ManageLabel
                className={"no-margin"}
                label={"Select driver(s)"}
                weight={"normal"}
                size={"xs"}
              />
              <RSuiteCheckPicker
                items={this.state.driverNumber}
                value={this.state.driverNumberSearchColumns}
                placeholder={"Select driver(s)"}
                isLoading={this.state.driverNumberIsLoading}
                renderMenuItem={(label, item) => {
                  return <div>{label}</div>;
                }}
                onOpen={() => {
                  this.setState(
                    {
                      driverNumberIsLoading: true,
                    },
                    () => {
                      this.DriverReadApi((arr) => {
                        this.setState({
                          driverNumberIsLoading: false,
                          driverNumber: arr,
                        });
                      });
                    }
                  );
                }}
                onChange={(values) => {
                  this.setState({
                    driverNumberSearchColumns: values,
                  });
                }}
              />
            </Grid>
          </Box>

          <Box clone textAlign={"right"}>
            <Grid item xs={12} md={6} lg={4}>
              <ManageLabel
                className={"no-margin"}
                label={<>&nbsp;</>}
                weight={"normal"}
                size={"xs"}
              />
              <Button
                className={"reports-primary-btn"}
                disabled={this.isRunReportBtnDisabled()}
                onClick={() => {
                  this.setState(
                    {
                      isLoading: false,
                      report: null,
                    },
                    () => {
                      this.callReportReadApi();
                    }
                  );
                }}
              >
                Run report
              </Button>
            </Grid>
          </Box>
        </Grid>
      </Box>
    );
  };

  infoSection = () => {
    return <CustomerInfo item={this.state.report} />;
  };


  iconsSectionButton = (ref) => {
    return (
      <Button
      className={"reports-primary-btn"}
      disabled={this.isRunReportBtnDisabled() || this.state.isIconClicked}
      onClick={() => {
        this.setState(
          {
            isLoading: false,
            report: null,
          },
          () => {
            this.callRecalculateApi();
          }
        );
      }}
    >
      Recalculate Trip Pay
    </Button>
    )
  };


  iconsSection = (ref) => {
    return (
   
      <IconsList
        items={[
          {
            id: 1,
            text: "Edit",
            disabled: this.state.isIconClicked,
            [this.state.onEditToggler ? 'button' : 'icon']: this.state.onEditToggler ? (
              <Button
                onClick={() => {
                  this.setState({
                    onEditToggler: !this.state.onEditToggler,
                    isIconClicked: false,
                  });
                }}
                style={{
                  paddingRight: '5px'
                }}
                className={"reports-primary-btn"}
                size="small"
              >
                Save
              </Button>
            ) : (
              <EditIcon />
            ),
          },
          {
            id: 2,
            text: "Email",
            disabled: this.state.isIconClicked,
            icon: <MailIcon />,
          },
          {
            id: 3,
            text: "Print",
            disabled: this.state.isIconClicked,
            icon: <PrintIcon />,
          },
          {
            id: 4,
            text: "Download",
            button: (
              <DropdownFilter
                isClickAway={true}
                text={<DownloadIcon />}
                button={IconButton}
              >
                <Box p={3}>
                  <RadioGroup
                    aria-label="download"
                    name="download"
                    value={this.state.download}
                    onChange={(e) => {
                      this.setState({
                        download: e.target.value,
                      });
                    }}
                  >
                    <FormControlLabel
                      value={"1"}
                      control={<Radio disabled={this.state.isIconClicked} />}
                      label={"PDF"}
                    />
                    <FormControlLabel
                      value={"2"}
                      control={<Radio disabled={this.state.isIconClicked} />}
                      label={"EXCEL"}
                    />
                  </RadioGroup>
                  <Box pt={2}>
                    <Button
                      className={"reports-primary-btn"}
                      variant={"contained"}
                      color={"primary"}
                      disabled={this.state.isIconClicked}
                      onClick={() => {
                        if (this.state.download === "1") {
                          // PDF
                          this.setState(
                            {
                              isLoading: false,
                              isIconClicked: true,
                            },
                            () => {
                              apiUtil
                                .getTemplateFromFile(
                                  "/templates/HtmlTemplate.html"
                                )
                                .then((result) => {
                                  let html1 = result.data.replace(
                                    "[[title]]",
                                    this.state.pageName
                                  );
                                  let html = html1.replace(
                                    "[[content]]",
                                    ref.current.outerHTML
                                  );
                                  this.callGeneratePDFApi(
                                    this.state.pageName,
                                    html,
                                    (fileName, data) => {
                                      apiUtil.saveByteArray(fileName, data);

                                      this.setState({
                                        isLoading: false,
                                        isIconClicked: false,
                                      });
                                    }
                                  );
                                });
                            }
                          );
                        } else {
                          // EXCEL
                          this.setState(
                            {
                              isLoading: false,
                              isIconClicked: true,
                            },
                            () => {
                              this.callGenerateEXCELApi((data) => {
                                let fileName = this.state.pageName + ".xls";
                                let type = "application/vnd.ms-excel";

                                apiUtil.saveByteArray(fileName, data, type);

                                this.setState({
                                  isLoading: false,
                                  isIconClicked: false,
                                });
                              });
                            }
                          );
                        }
                      }}
                    >
                      Download
                    </Button>
                  </Box>
                </Box>
              </DropdownFilter>
            ),
          },
        ]}
        onClick={(item) => {
          if (item.id === 1) {
            // Edit
            this.setState(
              {
                isIconClicked: true,
              },
              () => {
                this.setState({
                  onEditToggler: !this.state.onEditToggler,
                });
              }
            );
          } else

          if (item.id === 2) {
            // Email
            this.setState({
              isIconClicked: true,
              isSendEmailDialog: true,
              htmlSendEmailDialog: ref.current.outerHTML,
            });
          } else if (item.id === 3) {
            // Print
            this.setState(
              {
                isLoading: true,
                isIconClicked: true,
              },
              () => {
                apiUtil
                  .getTemplateFromFile("/templates/HtmlTemplate.html")
                  .then((result) => {
                    let html1 = result.data.replace(
                      "[[title]]",
                      this.state.pageName
                    );
                    let html = html1.replace(
                      "[[content]]",
                      ref.current.outerHTML
                    );
                    apiUtil.printByteArray(this.state.pageName, html);

                    this.setState({
                      isLoading: false,
                      isIconClicked: false,
                    });
                  });
              }
            );
          }
        }}
      />
      

    );
  };

  pageSection = () => {
    if (this.state.report) {
      return (
        <div className="page-break">
          <div className={"row"}>
            <div className={"col-xs-12 pdf-col-sm-6 col-lg-6"}>
              <div className={"row"}>
                <div className={"col-xs-12 pdf-col-sm-4 col-md-4 col-xl-3"}>
                  Remarks
                </div>
                <div className={"col-xs-12 pdf-col-sm-8 col-md-8 col-xl-9"}>
                {this.state.onEditToggler ? (
                      <ManageInput
                        className={
                          "job-form-input bg-white plr-10 lh-25  no-padding"
                        }
                        placeholder={"Remarks"}
                        value={(this.state.report && this.state.report.remarks) ? this.state.report.remarks : ''}
                        multiline={true}
                        rows={1}
                        border={true}
                        onChange={(e) => {
                          let report = this.state.report;
                          report.remarks = e.target.value;
                          this.setState({
                            report: report,
                          });
                        }}
                      />
                    ) : (
                      <div>{(this.state.report && this.state.report.remarks) ? this.state.report.remarks : <>-</>}</div>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "30px" }} className={"row"}>
            <div className={"col-xs-12"}>
              {this.printTable(this.state.report)}
            </div>
          </div>
        </div>
      );
    } else {
      return this.printNoTable();
    }
  };

  printNoTable = () => {
    return (
      <Box className={"reports-table"}>
        <ReportTable className={"no-responsive-table"}>
          <>
            <ReportThead>
              <ReportTr>
                <ReportTh rowSpan={2} style={{ width: "10%" }}>
                  Date
                </ReportTh>
                <ReportTh rowSpan={2} style={{ width: "15%" }}>
                  Job Type
                </ReportTh>
                <ReportTh rowSpan={2} style={{ width: "15%" }}>
                  Customer
                </ReportTh>
                <ReportTh rowSpan={2} style={{ width: "15%" }}>
                  Site
                </ReportTh>
                <ReportTh rowSpan={2} style={{ width: "15%" }}>
                  DO Number
                </ReportTh>
                <ReportTh rowSpan={2} style={{ width: "10%" }}>
                Trip Name
                </ReportTh>
                <ReportTh colSpan={2} style={{ width: "15%" }}>
                  Trip Pay
                </ReportTh>
              </ReportTr>
              <ReportTr>
                <ReportTh>Per Trip</ReportTh>
                <ReportTh>Incentive</ReportTh>
              </ReportTr>
            </ReportThead>
            <ReportTbody>
              <ReportTr key={0}>
                <ReportTd colSpan={9}>
                  <Box textAlign={"center"}>No Result!</Box>
                </ReportTd>
              </ReportTr>
            </ReportTbody>
          </>
        </ReportTable>
      </Box>
    );
  };

  printTable = (items) => {
    if (items && items && items.length > 0) {
      return items.map((item, i) => {
        return (
          <Box className={"reports-table"} key={i}>
            <ReportTable className={"no-responsive-table"}>
              <>
                {this.printTableCaption(item)}
                {this.printTableHead(item)}
                {this.printTableBody(item)}
              </>
            </ReportTable>
          </Box>
        );
      });
    } else {
      return this.printNoTable();
    }
  };

  printTableCaption = (item) => {
    return <ReportCaption>{item.driverNameDisplay}</ReportCaption>;
  };

  printTableHead = (item) => {
    return (
      <ReportThead>
        <ReportTr>
          <ReportTh rowSpan={2} style={{ width: "10%" }}>
            Date
          </ReportTh>
          <ReportTh rowSpan={2} style={{ width: "15%" }}>
            Job Type
          </ReportTh>
          <ReportTh rowSpan={2} style={{ width: "15%" }}>
            Customer
          </ReportTh>
          <ReportTh rowSpan={2} style={{ width: "15%" }}>
            Site
          </ReportTh>
          <ReportTh rowSpan={2} style={{ width: "15%" }}>
            DO Number
          </ReportTh>
          <ReportTh rowSpan={2} style={{ width: "10%" }}>
          Trip Name
          </ReportTh>
          <ReportTh colSpan={2} style={{ width: "15%" }}>
            Trip Pay
          </ReportTh>
        </ReportTr>
        <ReportTr>
          <ReportTh>Per Trip</ReportTh>
          <ReportTh>Incentive</ReportTh>
        </ReportTr>
      </ReportThead>
    );
  };



  printTableBody = (item) => {
    let rows = [];

    if (item && item.data && item.data.length > 0) {
      let totalTripPay = 0;
      let totalIncentivePay = 0;

      rows.push(
        item.data.map((dataItem, i) => {
          let dataLength = dataItem.data.length;

          let totalPay = 0;
          let tripPay = 0;
          let perDayPay = 0;
          let totalIncentivePayDisplay = dataItem.totalIncentivePayDisplay;

          totalTripPay += dataItem.totalPay;
          totalIncentivePay += dataItem.totalIncentivePay;

          if(dataItem && dataItem.data && dataItem.data.length > 0){
            for(let j = 0; j < dataItem.data.length; j++){
              let subSubItem = dataItem.data[j];
              tripPay += subSubItem.tripPay;
              totalPay += subSubItem.totalPay;
              perDayPay += subSubItem.dayPay;
            }
          }
          
          return dataItem.data.map((dataItem2, i) => {
            if ((i === 0) && (i === (dataItem.data.length - 1))) {
              return <React.Fragment key={i}>
                  <ReportTr>
                  <ReportTd>
                    {dataItem2.jobDateDisplay && dataItem2.jobDateDisplay !== ""
                      ? dataItem.jobDateDisplay
                      : "-"}
                  </ReportTd>
                  <ReportTd>
                    {dataItem2.jobTemplateName && dataItem2.jobTemplateName !== ""
                      ? dataItem2.jobTemplateName
                      : "-"}
                  </ReportTd>
                  <ReportTd>
                    {dataItem2.customerName && dataItem2.customerName !== ""
                      ? dataItem2.customerName
                      : "-"}
                  </ReportTd>
                  <ReportTd>
                    {dataItem2.customerSiteName && dataItem2.customerSiteName !== ""
                      ? dataItem2.customerSiteName
                      : "-"}
                  </ReportTd>
                  <ReportTd>
                    {dataItem2.jobNumber && dataItem2.jobNumber !== ""
                      ? dataItem2.jobNumber
                      : "-"}
                  </ReportTd>
                  <ReportTd>
                    {dataItem2.tripPayName && dataItem2.tripPayName 
                      ?dataItem2.tripPayName
                      : "-"}
                  </ReportTd>
                  <ReportTd>
                    {dataItem2.tripPayDisplay && dataItem2.tripPayDisplay !== ""
                      ? dataItem2.tripPayDisplay
                      : "-"}
                  </ReportTd>
                  <ReportTd rowSpan={dataLength}>
                    {totalIncentivePayDisplay && totalIncentivePayDisplay !== ""
                      ? totalIncentivePayDisplay
                      : "-"}

                  </ReportTd>
                </ReportTr>

                <ReportTr>
                  <ReportTd style={{ border: 0 }} colSpan={5}>&nbsp;</ReportTd>
                  <ReportTd>Subtotal</ReportTd>
                  <ReportTd>
                    <NumberFormat value={tripPay} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                  </ReportTd>
                  <ReportTd>
                    <NumberFormat value={totalIncentivePayDisplay} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                  </ReportTd>
                </ReportTr>
                <ReportTr>
                  <ReportTd style={{ border: 0 }} colSpan={8}>&nbsp;</ReportTd>
                </ReportTr>
              </React.Fragment>


            } else if (i === 0){
              return <ReportTr key={i}>
                <ReportTd>
                  {dataItem2.jobDateDisplay && dataItem2.jobDateDisplay !== ""
                    ? dataItem.jobDateDisplay
                    : "-"}
                </ReportTd>
                <ReportTd>
                  {dataItem2.jobTemplateName && dataItem2.jobTemplateName !== ""
                    ? dataItem2.jobTemplateName
                    : "-"}
                </ReportTd>
                <ReportTd>
                  {dataItem2.customerName && dataItem2.customerName !== ""
                    ? dataItem2.customerName
                    : "-"}
                </ReportTd>
                <ReportTd>
                  {dataItem2.customerSiteName && dataItem2.customerSiteName !== ""
                    ? dataItem2.customerSiteName
                    : "-"}
                </ReportTd>
                <ReportTd>
                  {dataItem2.jobNumber && dataItem2.jobNumber !== ""
                    ? dataItem2.jobNumber
                    : "-"}
                </ReportTd>
                <ReportTd>
                  {dataItem2.tripPayName && dataItem2.tripPayName 
                    ?dataItem2.tripPayName
                    : "-"}
                </ReportTd>
                <ReportTd>
                  {dataItem2.tripPayDisplay && dataItem2.tripPayDisplay !== ""
                    ? dataItem2.tripPayDisplay
                    : "-"}
                </ReportTd>
                <ReportTd rowSpan={dataLength}>
                  {totalIncentivePayDisplay && totalIncentivePayDisplay !== ""
                    ? totalIncentivePayDisplay
                    : "-"}

                </ReportTd>
              </ReportTr>
              
            } else if (i == (dataItem.data.length - 1)) {
              return <React.Fragment key={i}>
                <ReportTr>
                  <ReportTd>
                    {dataItem2.jobDateDisplay && dataItem2.jobDateDisplay !== ""
                      ? dataItem2.jobDateDisplay
                      : "-"}
                  </ReportTd>
                  <ReportTd>
                    {dataItem2.jobTemplateName && dataItem2.jobTemplateName !== ""
                      ? dataItem2.jobTemplateName
                      : "-"}
                  </ReportTd>
                  <ReportTd>
                    {dataItem2.customerName && dataItem2.customerName !== ""
                      ? dataItem2.customerName
                      : "-"}
                  </ReportTd>
                  <ReportTd>
                    {dataItem2.customerSiteName && dataItem2.customerSiteName !== ""
                      ? dataItem2.customerSiteName
                      : "-"}
                  </ReportTd>
                  <ReportTd>
                    {dataItem2.jobNumber && dataItem2.jobNumber !== ""
                      ? dataItem2.jobNumber
                      : "-"}
                  </ReportTd>
                  <ReportTd>
                  {dataItem2.tripPayName && dataItem2.tripPayName 
                      ?dataItem2.tripPayName
                      : "-"}
                  </ReportTd>
                  <ReportTd>
                    {dataItem2.tripPayDisplay && dataItem2.tripPayDisplay !== ""
                      ? dataItem2.tripPayDisplay
                      : "-"}
                  </ReportTd>
                </ReportTr>

                <ReportTr>
                  <ReportTd style={{ border: 0 }} colSpan={5}>&nbsp;</ReportTd>
                  <ReportTd>Subtotal</ReportTd>
                  <ReportTd>
                    <NumberFormat value={tripPay} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                  </ReportTd>
                  <ReportTd>
                    <NumberFormat value={totalIncentivePayDisplay} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                  </ReportTd>
                </ReportTr>
                <ReportTr>
                  <ReportTd style={{ border: 0 }} colSpan={8}>&nbsp;</ReportTd>
                </ReportTr>
              </React.Fragment>

            } else {
              return <ReportTr key={i}>
                <ReportTd>
                  {dataItem2.jobDateDisplay && dataItem2.jobDateDisplay !== ""
                    ? dataItem2.jobDateDisplay
                    : "-"}
                </ReportTd>
                <ReportTd>
                  {dataItem2.jobTemplateName && dataItem2.jobTemplateName !== ""
                    ? dataItem2.jobTemplateName
                    : "-"}
                </ReportTd>
                <ReportTd>
                  {dataItem2.customerName && dataItem2.customerName !== ""
                    ? dataItem2.customerName
                    : "-"}
                </ReportTd>
                <ReportTd>
                  {dataItem2.customerSiteName && dataItem2.customerSiteName !== ""
                    ? dataItem2.customerSiteName
                    : "-"}
                </ReportTd>
                <ReportTd>
                  {dataItem2.jobNumber && dataItem2.jobNumber !== ""
                    ? dataItem2.jobNumber
                    : "-"}
                </ReportTd>
                <ReportTd>
                {dataItem2.tripPayName && dataItem2.tripPayName 
                    ?dataItem2.tripPayName
                    : "-"}
                </ReportTd>
                <ReportTd>
                  {dataItem2.tripPayDisplay && dataItem2.tripPayDisplay !== ""
                    ? dataItem2.tripPayDisplay
                    : "-"}
                </ReportTd>
              </ReportTr>
            }
          });
        })
      );

      rows.push(<>
        <ReportTr>
          <ReportTd style={{ border: 0 }} colSpan={5}>&nbsp;</ReportTd>
          <ReportTd>Total</ReportTd>
          <ReportTd>
            <NumberFormat value={totalTripPay} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
          </ReportTd>
          <ReportTd>
            <NumberFormat value={totalIncentivePay} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
          </ReportTd>
        </ReportTr>
        <ReportTr>
          <ReportTd style={{ border: 0 }} colSpan={8}>&nbsp;</ReportTd>
        </ReportTr>
      </>)

    } else {
      rows.push(
        <ReportTr key={0}>
          <ReportTd colSpan={8}>
            <Box textAlign={"center"}>No Result!</Box>
          </ReportTd>
        </ReportTr>
      );
    }

    return <ReportTbody>{rows}</ReportTbody>;
  };
  /* END SECTIONS */

  /* DIALOGS */
  setSendEmailDialog = () => {
    return (
      <CustomDialog
        open={this.state.isSendEmailDialog}
        maxWidth={"lg"}
        onClose={() => {
          this.setState({
            isSendEmailDialog: false,
            isIconClicked: false,
            htmlSendEmailDialog: "",
          });
        }}
      >
        <SendEmailDialog
          item={null}
          isLoading={false}
          onClose={() => {
            this.setState({
              isSendEmailDialog: false,
              isIconClicked: false,
              htmlSendEmailDialog: "",
            });
          }}
          onOk={(isEdit, form) => {
            let htmlSendEmailDialog = this.state.htmlSendEmailDialog;

            if (form && form.emailAddresses && form.emailAddresses !== "") {
              apiUtil
                .getTemplateFromFile("/templates/HtmlTemplate.html")
                .then((result) => {
                  let html1 = result.data.replace(
                    "[[title]]",
                    this.state.pageName
                  );
                  let html = html1.replace("[[content]]", htmlSendEmailDialog);

                  this.callSendPDFEmailApi(
                    form.emailAddresses,
                    this.state.pageName,
                    html,
                    () => {
                      this.setState({
                        isSendEmailDialog: false,
                        isIconClicked: false,
                        htmlSendEmailDialog: "",
                      });
                    }
                  );
                });
            } else {
              this.setState({
                isSendEmailDialog: false,
                isIconClicked: false,
                htmlSendEmailDialog: "",
              });
            }
          }}
        />
      </CustomDialog>
    );
  };

  render() {
    return (
      <Box>
        <ReportsLayout
          auth={this.props.auth}
          isLoading={this.state.isLoading}
          report={this.state.report}
          titleSection={this.state.pageName}
          topSection={this.topSection()}
          infoSection={this.infoSection()}
          iconsSection={this.iconsSection}
          iconsSectionButton={this.iconsSectionButton}
          pageSection={this.pageSection()}
        />
        {this.setSendEmailDialog()}
        {this.setSnackBar()}
      </Box>
    );
  }
}


const mapStateToProps = state => {
  return {
    ...state,
    ...Utils.mapStateToProps(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  action: {
    signalRslice: {
      connect: (payload) => dispatch(signalRslice.connect(payload)),
      disconnect: (payload) => dispatch(signalRslice.disconnect(payload)),
      onPercentage: (payload) => dispatch(signalRslice.onPercentage(payload)),
    },
  }
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DriverTripPay);
