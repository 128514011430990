/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

import { Spinner } from "react-bootstrap-v5";

import {KTSVG} from '../../../_metronic/helpers'

import Utils from '../../utils/utils';
import { StyledBlockerLoading, StyledOverlay } from "../../utils/styles";

import { Form } from "react-bootstrap-v5";
import { Formik } from 'formik';

export const DrawerEnum = {
    PREFIX: 'kt_drawer_',
    NAME: 'custom',
}


export const DrawerPageContentLayout = React.forwardRef((props, ref) => {
    return <>
        {props.body && <div ref={ref} className={'card-body overflow-auto ' + (props.padding ? props.padding + ' ' : ' p-0 ') +  + props.className}>
            {props.isBlocking ? null : props.body}
        </div>}
        {props.footer && <div className='card-footer ps-4 pe-4 pt-5 pb-2' style={{ borderTop: '1px solid #EFF2F5' }}>
            {props.footer}
        </div>}
    </>
})

export const DrawerContentLayout = React.forwardRef((props, ref) => {
    return <>
        {props.body && <div ref={ref} className={'card-body overflow-auto ' + (props.padding ? props.padding + ' ' : ' p-5 ') + props.className} style={{ height: 'calc(100vh - 138px)' }}>
            {props.isBlocking ? null : props.body}
        </div>}
        {props.footer && <div className='card-footer ps-4 pe-4 pt-5 pb-2' style={{ borderTop: '1px solid #EFF2F5' }}>
            {props.footer}
        </div>}
        {props.isLoading && <StyledBlockerLoading>
            <StyledOverlay />
            <Spinner animation="border" variant="primary" />
        </StyledBlockerLoading>}
    </>
})

export const DrawerListContentLayout = React.forwardRef((props, ref) => {
    return <>
        {props.body && <div ref={ref} className={'card-body overflow-auto ' + (props.padding ? props.padding + ' ' : ' p-0 ') +  + props.className} style={{ height: 'calc(100vh - 139px)' }}>
            {props.isBlocking ? null : props.body}
        </div>}
        {props.footer && <div className='card-footer ps-4 pe-4 pt-5 pb-2' style={{ borderTop: '1px solid #EFF2F5' }}>
            {props.footer}
        </div>}
    </>
})

export const DrawerLayout = React.forwardRef((props, ref) => {
    let prefix = (props && props.prefix && props.prefix !== '') ? props.prefix : DrawerEnum.PREFIX;
    let name = (props && props.name && props.name !== '') ? props.name : DrawerEnum.NAME;
    let size = (props && props.size && props.size !== '') ? props.size : '500px';
    let drawerWidth = (props && props.drawerWidth && props.drawerWidth !== '') ? props.drawerWidth : null;
    let zIndex = (props && props.zIndex && props.zIndex !== '') ? props.zIndex : null;

    const [isVisible, setIsVisible] = React.useState(((props.isVisible === false) || (props.isVisible === true)) ? props.isVisible : false);

    React.useEffect(() => {
        setIsVisible(((props.isVisible === false) || (props.isVisible === true)) ? props.isVisible : false);
        
        document.body.style.overflow = (props.isVisible ? 'hidden' : 'initial');

        if(props.onOpenClose){
            props.onOpenClose(props.isVisible, Utils.getDrawerData(props.drawer));
        }
    }, [props.isVisible]);


    return <div
        id={prefix + name}
        className='bg-white'
        data-kt-drawer='true'
        data-kt-drawer-name={name}
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width={drawerWidth ? drawerWidth : "{default:'100%', 'md': '" + size + "'}"}
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle={'#' + prefix + name + '_toggle'}
        data-kt-drawer-close={'#' + prefix + name + '_menu'}
        style={zIndex ? {
            zIndex: zIndex,
        } : {}}
    >
        <Formik
            innerRef={ref}
            validationSchema={props.validationSchema}
            initialValues={props.values ? props.values : props.initialValues}
            validateOnMount={((props.validateOnMount === false) || (props.validateOnMount === true)) ? props.validateOnMount : true}
            enableReinitialize={((props.enableReinitialize === false) || (props.enableReinitialize === true)) ? props.enableReinitialize : true}
            onSubmit={(form, e) => {
                if(typeof props.onSubmit === 'function'){
                    props.onSubmit(form, Utils.getDrawerData(props.drawer));
                }
            }}
        >
            {(formOptions) => {
                return <Form className='w-100' noValidate autoComplete="off" onSubmit={formOptions.handleSubmit} onKeyDown={(keyEvent) => {
                    // if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                    //     keyEvent.preventDefault();
                    // }
                }}>
                    <div className='card w-100 rounded-0'>
                        <div className='card-header ps-4 pe-4'>
                            <div className='card-title'>
                                <div className='d-flex justify-content-center flex-column me-3'>{(typeof props.title === 'function') ? props.title(Utils.getDrawerData(props.drawer)) : props.title}</div>
                            </div>

                            <div className='card-toolbar'>
                                {props.toolbar && <div className='me-2'>{props.toolbar}</div>}

                                <div className='btn btn-sm btn-icon btn-active-light-primary' id={prefix + name + '_menu'}>
                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
                                </div>
                            </div>
                        </div>

                        {isVisible && <BlockUi 
                            className={!props.renderChildren ? 'custom-block-ui' : ''} 
                            tag={'div'} 
                            keepInView 
                            blocking={props.isBlocking}
                            renderChildren={props.renderChildren} 
                            loader={<Spinner animation="border" />}
                        >
                            {(typeof props.children === 'function') ? props.children(formOptions, Utils.getDrawerData(props.drawer), props.isBlocking) : props.children}
                        </BlockUi>}
                    </div>
                </Form>
            }}
        </Formik>
    </div>
});
