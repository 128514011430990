import React from "react";
import moment from 'moment';
import parse from 'html-react-parser';
import InfiniteScroll from 'react-infinite-scroller';

import { 
    Row,
    Col,
    Form,
    Button,
    Dropdown,
    Badge,
    Spinner,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import { Icon } from '@iconify/react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';

import Utils from "../../../utils/utils";
import { PageCookieSettingType, SortColumns, PagesPermissions } from "../../../utils/enums";

import WrappedButton from '../../../components/Buttons/WrappedButton';
import DropdownToggle from '../../../components/dropdown/DropdownToggle';
import SearchDialog from '../../../components/dialog/SearchDialog';
import Popup from '../../../components/popup/Popup';
import LoadingButton from '../../../components/Buttons/LoadingButton';
import makeDropdownList from "../../../components/dropdown/makeDropdownList";
import DataTableActionMenu from '../../../components/OLD/DataTable/DataTableActionMenu';
import DataTable from "../../../components/OLD/DataTable/DataTable";
import DataTableAjaxPagination from '../../../components/OLD/DataTable/Pagination/DataTableAjaxPaginationV5';
import DataTableScrollSync from '../../../components/OLD/DataTable/DataTableScrollSync.jsx';
import IconButtonSearch from '../../../components/input/IconButtonSearch';

import ViewTopSection from "../partial/ViewTopSection";

import CustomerForm from "./CustomerForm";

import { readApi } from '../../../api/CRUD/DispatchCRUD'

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { actions } from "../../../../setup/redux/auth/saga";
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut, dispatchApiCallDelete } from '../../../../setup/redux/dispatch/actions'


const { saveUserCookieSettings } = actions;
const DropdownMenu = makeDropdownList(Button);


const StyledDropdownToggleText = styled.span`
    font-size: 14px;
`;
const StyledBadge = styled(Badge)`
    background-color: transparent;
    border: 1px solid #DFDFDF;
    font-weight: normal;
`;
const StyledTabButton = styled.button`
    background-color: transparent;
    color: #185CFF;
    border-bottom: 2px solid transparent;
    padding-bottom: 7px;

    &:hover {
        border-bottom: 2px solid #185CFF;
    }

    ${props => {
        if(props.active){
            return 'color: #484848; border-bottom: 2px solid #185CFF;';
        }
    }}
`;



const StyledFormCheck = styled(Form.Check)`
    position: relative;
    border-bottom: 1px solid #F5F5F5;
    min-height: 60px;
    display: flex;
    align-items: center;

    .form-check-input[type=checkbox] {
        margin-left: 10px;
        border-radius: 0px;
        border: 0px;
        cursor: pointer;
        background-size: contain;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15'%3e%3cg id='Icon_ionic-ios-checkbox-outline' data-name='Icon ionic-ios-checkbox-outline' transform='translate(-2.5 -2.5)'%3e%3cpath id='Path_24397' data-name='Path 24397' d='M16.25,2.5H3.75A1.249,1.249,0,0,0,2.5,3.75v12.5A1.249,1.249,0,0,0,3.75,17.5h12.5a1.249,1.249,0,0,0,1.25-1.25V3.75A1.249,1.249,0,0,0,16.25,2.5Zm.156,13.594a.313.313,0,0,1-.312.313H3.906a.313.313,0,0,1-.312-.312V3.906a.313.313,0,0,1,.313-.312H16.094a.313.313,0,0,1,.313.313Z' fill='%23dfdfdf'/%3e%3cpath id='Path_24398' data-name='Path 24398' d='M14.2,7.535l-.687-.707a.148.148,0,0,0-.109-.047h0a.142.142,0,0,0-.109.047l-4.766,4.8L6.8,9.895a.151.151,0,0,0-.219,0l-.7.7a.156.156,0,0,0,0,.223L8.07,13a.692.692,0,0,0,.457.223.725.725,0,0,0,.453-.215h0l5.223-5.25A.167.167,0,0,0,14.2,7.535Z' fill='%23dfdfdf'/%3e%3c/g%3e%3c/svg%3e");
        
        &:checked{
            background-color: transparent;
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15'%3e%3cg id='Icon_ionic-ios-checkbox-outline' data-name='Icon ionic-ios-checkbox-outline' transform='translate(-2.5 -2.5)'%3e%3cpath id='Path_24397' data-name='Path 24397' d='M16.25,2.5H3.75A1.249,1.249,0,0,0,2.5,3.75v12.5A1.249,1.249,0,0,0,3.75,17.5h12.5a1.249,1.249,0,0,0,1.25-1.25V3.75A1.249,1.249,0,0,0,16.25,2.5Zm.156,13.594a.313.313,0,0,1-.312.313H3.906a.313.313,0,0,1-.312-.312V3.906a.313.313,0,0,1,.313-.312H16.094a.313.313,0,0,1,.313.313Z' fill='%23185cff'/%3e%3cpath id='Path_24398' data-name='Path 24398' d='M14.2,7.535l-.687-.707a.148.148,0,0,0-.109-.047h0a.142.142,0,0,0-.109.047l-4.766,4.8L6.8,9.895a.151.151,0,0,0-.219,0l-.7.7a.156.156,0,0,0,0,.223L8.07,13a.692.692,0,0,0,.457.223.725.725,0,0,0,.453-.215h0l5.223-5.25A.167.167,0,0,0,14.2,7.535Z' fill='%23185cff'/%3e%3c/g%3e%3c/svg%3e");
        }
    }

    label {
        position: relative;
        left: 10px;
        width: 100%;
        height: 100%;
        padding: 0px 10px 0px 0px;
        cursor: pointer;
        
        display: flex;
        align-items: center;
        
        width: 100%;
    
        ${(props) => {
            return props.isActive ? 'color: #185CFF;' : 'color: #FF0090;';
        }}

        &.text-secondary {
            color: #7B7B7B !important;
            font-size: 12px;
        }
    }

    .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
        opacity: 1;
    }

    ${(props) => {
        return props.active ? 'background-color: #ECF5DF;' : '';
    }}
`;
const StyledSortItem = styled(Dropdown.Item)`
    position: relative;
    display: flex;
    align-items: center;

    .custom-title {
        display: inline-block;
        flex-basis: 100%;
        width: 130px;
        white-space: pre-wrap;
        color: #484848;

        ${(props) => {
            return (props.active) ? 'font-weight: bold;' : '';
        }}
    }

    .custom-icons {
        display: inline-block;
        position: relative;
        width: 20px;
        height: 20px;
        overflow: hidden;
        background-color: transparent !important;

        .custom-icon-up {
            overflow: hidden;
            position: relative;
            height: 12px;

            > div {
                position: absolute;
                top: -6px;

                i {
                    ${(props) => {
                        return (props.active && props.direction == 'asc') ? 'color: #484848;' : 'color: #B8B8B8;';
                    }}

                    &:hover {
                        color: #484848;
                    }
                }
            }

            ${(props) => {
                return (props.active) ? 'display: block;' : 'display: none;';
            }}
        }

        .custom-icon-down {
            overflow: hidden;
            position: relative;
            height: 12px;

            > div {
                position: absolute;
                top: -9px;

                i {
                    ${(props) => {
                        return (props.active && props.direction == 'desc') ? 'color: #484848;' : 'color: #B8B8B8;';
                    }}

                    &:hover {
                        color: #484848;
                    }
                }
            }

            ${(props) => {
                return (props.active) ? 'display: block;' : 'display: none;';
            }}
        }
    }
    
    &.active, &:active, &:focus {
        ${(props) => {
            return props.active ? 'background-color: #ECECEC !important;' : 'background-color: transparent !important;';
        }}
    }

    &:hover {
        .custom-icons {
            .custom-icon-up {
                display: block;
            }
            .custom-icon-down {
                display: block;
            }
        }
    }
`;


class CustomerEmail extends React.Component {
  
    constructor(props) {
        super(props);

        this.pageName = 'customer_email';
        this.backLink = '/Customers';

        this.pageIdField = "jobEmailLogId";
        this.pageCaption = 'Customer Email'

        this.refCustomerForm = React.createRef();
        this.tableRef = React.createRef();
        this.refDataTableScrollSync = React.createRef();
        
        let id = Utils.getIntProps(props);
        
        let user = (props && props.auth && props.auth.user) ? props.auth.user : null;
        this.permissions = (user && user.permissions && user.permissions.length > 0) ? user.permissions : [];
        
        
        // if(this.permissions && this.permissions.length > 0){
        //     if(this.permissions.findIndex(x => x.permissionId === PagesPermissions.CustomersEmail.read) === -1){
        //         props.history.push('/no-permission');
        //     }
        // }
        
        
        this.timestampTemplate = (id, row, col, obj) => {
            return moment(row.created).format('DD-MM-YYYY hh:mm A')
        };
        this.timestampExportTemplate = (row) => {
            return moment(row.created).format("LLL");
        };

        this.isReadTemplate = (id, row, col, obj) => {
            return row.isRead ? 'Yes' : 'No';
        };
        this.isReadExportTemplate = (row) => {
            return row.isRead ? 'Yes' : 'No';
        };

        this.descriptionTemplate = (id, row, col, obj) => {
            return <div>{parse(row.description)}</div>
        };
        this.descriptionExportTemplate = (row) => {
            return parse(row.description);
        };


        let columns = [
            { 
                id: this.pageIdField, 
                filterKey: this.pageIdField, 
                name: this.pageIdField, 
                label: 'ID',
                visible: false
            },
            {
                id: "created",
                name: "created",
                label: "Timestamp",
                responsiveLabel: "Timestamp",
                orderable: true,
                width: "250px",
                template: this.timestampTemplate,
                exportTemplate: this.timestampExportTemplate,
            },
            {
                id: "description",
                name: "description",
                label: "Description",
                responsiveLabel: "Description",
                orderable: true,
                template: this.descriptionTemplate,
                exportTemplate: this.descriptionExportTemplate,
            },
            {
                id: "isRead",
                name: "isRead",
                label: "Is read",
                responsiveLabel: "Is read",
                orderable: true,
                width: "200px",
                template: this.isReadTemplate,
                exportTemplate: this.isReadExportTemplate,
            }
            
        ];
        let initColumns = Array.from(columns);
        columns = Array.from(initColumns);
        
        
        let columnVisibility = [
            false,
            true,
            true,
            true,
        ];
        let initColumnVisibility = Array.from(columnVisibility);
        columnVisibility = Array.from(initColumnVisibility);
    
        
        this.state = {
            id: id,
            details: null,
            mainLoading: false,
            
            popupShowCustomerForm: false,
            popupLoadingCustomerForm: false,
            popupIdCustomerForm: 0,
            popupIsCopyCustomerForm: false,

            leftItems: [],
            leftSearch: '',
            leftSortColumn: 'updated',
            leftSortDir: 'desc',
            leftCurrentPage: 1,
            leftPageSize: 30,
            leftTotal: 0,
            leftIsIncludeInactive: null,
            leftIsInactive: false,
            leftIsSearchDialog: false,
            sortColumnsLoading: false,
            sortColumns: SortColumns.customer,

            search: '',
            sortColumn: 'created',
            sortColumnInit: 'created',
            sortDir: 'desc',
            sortDirInit: 'desc',
            isIncludeInactive: null,
            isInactive: false,
            
            selected: [],
            columnVisibility: columnVisibility,
            initColumnVisibility: initColumnVisibility,
            columns: columns,
            initColumns: initColumns,

            rows: [],
            currentPage: 1,
            totalRows: 0,
            rowsPerPageSelected: 50,
            rowsPerPageOptions: [50, 100, 300],
            
            isDisabledBatchAction: true,
            isCookies: false,

            size: 0,
        };
    }


    componentDidMount(){
        this.setLayoutForm({
            leftHeaderContent: this.leftHeader,
            leftBodyContent: this.leftBody,
            rightHeaderContent: this.rightHeader,
            rightHeaderTabs: this.rightTabs,
            // onWindowResize: () => {
            //     this.getFilterInLocalStorage();
            // },
            onChangeSize: (size) => {
                this.setState({
                    size: size
                });
            },
        });

        this.getFilterInLocalStorage(() => {
            this.callInitApis();
        });
    }

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            this.pageName + '-read', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = [];
                if(data && data.data && data.data.length > 0){
                    arr = data.data.map((item, i) => {
                        return {
                            value: item.customerId,
                            title: item.customerName,
                            checked: false,
                            disabled: false,
                            active: (item.customerId === this.state.id) ? true : false,
                            isActive: item.isActive,
                            item: item,
                        }
                    });

                    arr = arr.sort((x, y) => {
                        return (x.active === y.active) ? 0 : x.active ? -1 : 1;
                    });
                }

                let total = (data && data.total) ? data.total : 0;

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr, total);
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            this.pageName + '-read_more', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if (data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.customerId;
                        let title = item.customerName;
                        let arrItem = {
                            value: value,
                            title: title,
                            checked: false,
                            disabled: false,
                            active: (item.customerId === this.state.id) ? true : false,
                            isActive: item.isActive,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }

                    arr = arr.sort((x, y) => {
                        return (x.active === y.active) ? 0 : x.active ? -1 : 1;
                    });
                }

                let total = (data && data.total) ? data.total : 0;

                this.setState({
                    leftItems: arr,
                    leftTotal: total,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback();
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-read_customer_email', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = [];
                let total = 0;
                if(data && data.data && data.data.length > 0){
                    arr = data.data;
                    total = data.total;
                }

                this.setState({
                    rows: arr,
                    totalRows: total,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    this.setLoading(false);

                    if(callback){
                        callback(arr, total);
                    }
                });
            }
        );
    }
	
    callInitApis = () => {
        Utils.callAllApi([
            {
                api: readApi,
                path: 'customer',
                params: () => {
                    let data = {
                        currentPage: this.state.leftCurrentPage,
                        pageSize: this.state.leftPageSize,
                        searchQuery: this.state.leftSearch,
                        sortColumn: this.state.leftSortColumn,
                        sortDir: this.state.leftSortDir,
                    };
            
                    let leftIsIncludeInactive = ((this.state.leftIsIncludeInactive === true) || (this.state.leftIsIncludeInactive === false)) ? this.state.leftIsIncludeInactive : null;
                    if(leftIsIncludeInactive){
                        data['isIncludeInactive'] = leftIsIncludeInactive;
                    }

                    let leftIsInactive = ((this.state.leftIsInactive === true) || (this.state.leftIsInactive === false)) ? this.state.leftIsInactive : null;
                    if(leftIsInactive){
                        data['isInactive'] = leftIsInactive;
                    }

                    return data;
                },
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        let arr = [];
                        if(data && data.data && data.data.length > 0){
                            arr = data.data.map((item, i) => {
                                return {
                                    value: item.customerId,
                                    title: item.customerName,
                                    checked: false,
                                    disabled: false,
                                    active: (item.customerId === this.state.id) ? true : false,
                                    isActive: item.isActive,
                                    item: item,
                                }
                            });

                            arr = arr.sort((x, y) => {
                                return (x.active === y.active) ? 0 : x.active ? -1 : 1;
                            });
                        }

                        let total = (data && data.total) ? data.total : 0;

                        this.setState({
                            leftItems: arr,
                            leftTotal: total,
                        }, () => {
                            this.refreshLayout();
                        });
                    });
                }
            },
            {
                api: readApi,
                path: 'customer/' + this.state.id + '?includeEmailTemplate=true',
                params: null,
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        this.setState({
                            details: data,
                        }, () => {
                            this.refreshLayout();
                        });
                    });
                }
            },
            {
                api: readApi,
                path: 'job/email-log',
                params: () => {
                    let data = {
                        currentPage: this.state.currentPage,
                        pageSize: this.state.rowsPerPageSelected,
                        searchQuery: this.state.search,
                        sortColumn: this.state.sortColumn,
                        sortDir: this.state.sortDir,
                        customerId: this.state.id,
                    };
            
                    let isIncludeInactive = ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : null;
                    if(isIncludeInactive){
                        data['isIncludeInactive'] = isIncludeInactive;
                    }

                    let isInactive = ((this.state.isInactive === true) || (this.state.isInactive === false)) ? this.state.isInactive : null;
                    if(isInactive){
                        data['isInactive'] = isInactive;
                    }

                    return data;
                },
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        let arr = [];
                        let total = 0;
                        if(data && data.data && data.data.length > 0){
                            arr = data.data;
                            total = data.total;
                        }

                        this.setState({
                            rows: arr,
                            totalRows: total,
                        }, () => {
                            this.refreshLayout();
                        });
                    });
                }
            }
        ], (result) => {
            this.setState({
                mainLoading: true,
            });

            this.setLoading(true);
        }, (results) => {
            this.setLoading(false);
            this.refreshLayout();
            
            this.setState({
                mainLoading: false,
            });
        });
    }

    callDetailsApis = (additionalParams = null) => {
        Utils.callAllApi([
            {
                api: readApi,
                path: 'customer/' + this.state.id + '?includeEmailTemplate=true',
                params: null,
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        this.setState({
                            details: data,
                        }, () => {
                            this.refreshLayout();
                        });
                    });
                }
            },
            {
                api: readApi,
                path: 'job/email-log',
                params: () => {
                    let data = {
                        currentPage: this.state.currentPage,
                        pageSize: this.state.rowsPerPageSelected,
                        searchQuery: this.state.search,
                        sortColumn: this.state.sortColumn,
                        sortDir: this.state.sortDir,
                        customerId: this.state.id,
                    };
            
                    let isIncludeInactive = ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : null;
                    if(isIncludeInactive){
                        data['isIncludeInactive'] = isIncludeInactive;
                    }

                    let isInactive = ((this.state.isInactive === true) || (this.state.isInactive === false)) ? this.state.isInactive : null;
                    if(isInactive){
                        data['isInactive'] = isInactive;
                    }

                    return data;
                },
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        let arr = [];
                        let total = 0;
                        if(data && data.data && data.data.length > 0){
                            arr = data.data;
                            total = data.total;
                        }

                        this.setState({
                            rows: arr,
                            totalRows: total,
                        }, () => {
                            this.refreshLayout();
                        });
                    });
                }
            }
        ], (result) => {
            this.setState({
                mainLoading: true,
            });

            this.setLoading(true);
        }, (result) => {
            if(additionalParams){
                this.setState(additionalParams);
            }

            this.setLoading(false);
            this.refreshLayout();

            this.setState({
                mainLoading: false,
            });
        });
    }

    callReadApi = (callback = null) => {
        this.setLoading(true);
        
        let data = {
            currentPage: this.state.leftCurrentPage,
            pageSize: this.state.leftPageSize,
            searchQuery: this.state.leftSearch,
            sortColumn: this.state.leftSortColumn,
            sortDir: this.state.leftSortDir,
        };
   
        let leftIsIncludeInactive = ((this.state.leftIsIncludeInactive === true) || (this.state.leftIsIncludeInactive === false)) ? this.state.leftIsIncludeInactive : null;
        if(leftIsIncludeInactive){
            data['isIncludeInactive'] = leftIsIncludeInactive;
        }

        let leftIsInactive = ((this.state.leftIsInactive === true) || (this.state.leftIsInactive === false)) ? this.state.leftIsInactive : null;
        if(leftIsInactive){
            data['isInactive'] = leftIsInactive;
        }

        this.props.dispatchApiCallGet(data, this.pageName + '-read', 'customer', null, callback, () => {});
    }
    
    callReadMoreApi = (callback = null) => {
        this.setLoading(true);
        
        let data = {
            currentPage: this.state.leftCurrentPage,
            pageSize: this.state.leftPageSize,
            searchQuery: this.state.leftSearch,
            sortColumn: this.state.leftSortColumn,
            sortDir: this.state.leftSortDir,
        };
   
        let leftIsIncludeInactive = ((this.state.leftIsIncludeInactive === true) || (this.state.leftIsIncludeInactive === false)) ? this.state.leftIsIncludeInactive : null;
        if(leftIsIncludeInactive){
            data['isIncludeInactive'] = leftIsIncludeInactive;
        }

        let leftIsInactive = ((this.state.leftIsInactive === true) || (this.state.leftIsInactive === false)) ? this.state.leftIsInactive : null;
        if(leftIsInactive){
            data['isInactive'] = leftIsInactive;
        }

        this.props.dispatchApiCallGet(data, this.pageName + '-read_more', 'customer', { items: this.state.leftItems }, callback, () => {});
    }
    
    callReadCustomerEmailApi = (callback = null) => {
        this.setLoading(true);
        
        let data = {
            currentPage: this.state.currentPage,
            pageSize: this.state.rowsPerPageSelected,
            searchQuery: this.state.search,
            sortColumn: this.state.sortColumn,
            sortDir: this.state.sortDir,
            customerId: this.state.id,
        };
   
        let isIncludeInactive = ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : null;
        if(isIncludeInactive){
            data['isIncludeInactive'] = isIncludeInactive;
        }

        let isInactive = ((this.state.isInactive === true) || (this.state.isInactive === false)) ? this.state.isInactive : null;
        if(isInactive){
            data['isInactive'] = isInactive;
        }

        this.props.dispatchApiCallGet(data, this.pageName + '-read_customer_email', 'job/email-log', null, callback, () => {});
    }
    /* END API */


    /* LAYOUT FUNCTIONS */
    setLayoutForm = (options) => {
        if(this.props.layoutRef && this.props.layoutRef.current){
            this.props.layoutRef.current.setLayoutForm(this, options);
        }
    }
    setLayoutLoading = (state = false) => {
        if(this.props.layoutRef && this.props.layoutRef.current){
            this.props.layoutRef.current.setLayoutLoading(state);
        }
    }
    refreshLayout = () => {
        if(this.props.layoutRef && this.props.layoutRef.current){
            this.props.layoutRef.current.refreshLayout();
        }
    }
    getLayoutObj = (options) => {
        if(this.props.layoutRef && this.props.layoutRef.current && this.props.layoutRef.current.obj && this.props.layoutRef.current.obj.current){
            return this.props.layoutRef.current.obj.current;
        }
    }
    /* END LAYOUT FUNCTIONS */


    /* FUNCTIONS */
    setLoading = (state = false) => {
        this.setLayoutLoading(state);

        if(this.props.onLoading){
            this.props.onLoading(state);
        }
    }

    setFilterInLocalStorage = (isCookies = true) => {
        if(this.props.settingsType >= 0){
            let { token, userCookieSettings } = this.props.auth;
        
            this.setState({
                isCookies: isCookies,
            });
            
            let data = {
                name: this.pageName,
                type: this.props.settingsType,
            };
        
            let valueLocalStorage = {
                [PageCookieSettingType.currentPage]: this.state.currentPage,
            };
            window.localStorage.setItem(this.pageName + '_' + this.props.settingsType, JSON.stringify(valueLocalStorage));
        
            let value = {
                [PageCookieSettingType.isCookies]: isCookies,
                // [PageCookieSettingType.search]: this.state.search,
                [PageCookieSettingType.sortColumn]: this.state.sortColumn,
                [PageCookieSettingType.sortDir]: this.state.sortDir,
                [PageCookieSettingType.rowsPerPageSelected]: this.state.rowsPerPageSelected,
                [PageCookieSettingType.columnVisibility]: this.state.columnVisibility,
                [PageCookieSettingType.columns]: this.state.columns,
                [PageCookieSettingType.isIncludeInactive]: this.state.isIncludeInactive,
                [PageCookieSettingType.isInactive]: this.state.isInactive,
                [PageCookieSettingType.leftIsIncludeInactive]: this.state.leftIsIncludeInactive,
                [PageCookieSettingType.leftIsInactive]: this.state.leftIsInactive,
            };
            data['value'] = JSON.stringify(value);
        
            Utils.saveUserCookieSettings(data, token, (settings) => {
                if(settings){
                    let settingsArr = (userCookieSettings && userCookieSettings.length > 0) ? userCookieSettings : [];
                    let settingsIndex = Utils.getUserCookieSettingsIndex(settingsArr, this.props.settingsType);
                    if(settingsIndex !== -1 && settingsArr[settingsIndex]){
                        settingsArr[settingsIndex] = settings;
                    } else {
                        settingsArr.push(settings);
                    }
            
                    this.props.saveUserCookieSettings(settingsArr)
                }
            });
        }
    };
    getFilterInLocalStorage = (callback = null) => {
        let settingsItem = null;
    
        if(this.props.settingsType >= 0){
            const { userCookieSettings } = this.props.auth;
            settingsItem = Utils.getUserCookieSettings(userCookieSettings, this.props.settingsType, this.pageName);
        }
    
        let valueLocalStorageStr = window.localStorage.getItem(this.pageName + '_' + this.props.settingsType);
        let valueLocalStorage = JSON.parse(valueLocalStorageStr);
        let currentPageValue = valueLocalStorage && valueLocalStorage[PageCookieSettingType.currentPage] && valueLocalStorage[PageCookieSettingType.currentPage] !== "" ? valueLocalStorage[PageCookieSettingType.currentPage] : this.state.currentPage;
        
        let isCookiesValue = settingsItem && settingsItem[PageCookieSettingType.isCookies] && settingsItem[PageCookieSettingType.isCookies] !== "" ? settingsItem[PageCookieSettingType.isCookies] : this.state.isCookies;
        let searchValue = (settingsItem && settingsItem[PageCookieSettingType.search] && settingsItem[PageCookieSettingType.search] !== '') ? settingsItem[PageCookieSettingType.search] : this.state.search;
        let sortColumnValue = settingsItem && settingsItem[PageCookieSettingType.sortColumn] && settingsItem[PageCookieSettingType.sortColumn] !== "" ? settingsItem[PageCookieSettingType.sortColumn] : this.state.sortColumn;
        let sortDirValue = settingsItem && settingsItem[PageCookieSettingType.sortDir] && settingsItem[PageCookieSettingType.sortDir] !== "" ? settingsItem[PageCookieSettingType.sortDir] : this.state.sortDir;
        let rowsPerPageSelectedValue = settingsItem && settingsItem[PageCookieSettingType.rowsPerPageSelected] && settingsItem[PageCookieSettingType.rowsPerPageSelected] !== "" ? settingsItem[PageCookieSettingType.rowsPerPageSelected] : this.state.rowsPerPageSelected;
        let columnVisibilityValue = (settingsItem && settingsItem[PageCookieSettingType.columnVisibility] && settingsItem[PageCookieSettingType.columnVisibility] !== '') ? settingsItem[PageCookieSettingType.columnVisibility] : this.state.columnVisibility;
        let columnsValue = (settingsItem && settingsItem[PageCookieSettingType.columns] && settingsItem[PageCookieSettingType.columns] !== '') ? settingsItem[PageCookieSettingType.columns] : this.state.columns;
        let isIncludeInactiveValue = (settingsItem && settingsItem[PageCookieSettingType.isIncludeInactive] && settingsItem[PageCookieSettingType.isIncludeInactive] !== '') ? settingsItem[PageCookieSettingType.isIncludeInactive] : this.state.isIncludeInactive;
        let isInactiveValue = (settingsItem && settingsItem[PageCookieSettingType.isInactive] && settingsItem[PageCookieSettingType.isInactive] !== '') ? settingsItem[PageCookieSettingType.isInactive] : this.state.isInactive;
        let leftIsIncludeInactiveValue = (settingsItem && settingsItem[PageCookieSettingType.leftIsIncludeInactive] && settingsItem[PageCookieSettingType.leftIsIncludeInactive] !== '') ? settingsItem[PageCookieSettingType.leftIsIncludeInactive] : this.state.leftIsIncludeInactive;
        let leftIsInactiveValue = (settingsItem && settingsItem[PageCookieSettingType.leftIsInactive] && settingsItem[PageCookieSettingType.leftIsInactive] !== '') ? settingsItem[PageCookieSettingType.leftIsInactive] : this.state.leftIsInactive;
        
        let columns = Utils.setTemplateFunctionsToColumns(this.state.columns, columnsValue);
        
        this.setState({
            isCookies: isCookiesValue,
            search: searchValue,
            sortColumn: sortColumnValue,
            sortDir: sortDirValue,
            currentPage: currentPageValue,
            rowsPerPageSelected: rowsPerPageSelectedValue,
            columnVisibility: columnVisibilityValue,
            isIncludeInactive: isIncludeInactiveValue,
            isInactive: isInactiveValue,
            leftIsIncludeInactive: leftIsIncludeInactiveValue,
            leftIsInactive: leftIsInactiveValue,
            columns: columns,
        }, () => {
            if (callback) {
                callback();
            }
        });
    };
    /* END FUNCTIONS */


    /* FORM */
    leftHeader = () => {
        return <Row className={'align-items-center gx-1'}>
            <Col xs={true}>
                <Dropdown alignRight>
                    <Dropdown.Toggle as={DropdownToggle} className={'px-0'}>
                        <StyledDropdownToggleText>{Utils.getAllActiveInactiveText(this.state.leftIsIncludeInactive, this.state.leftIsInactive, 1)} Customers</StyledDropdownToggleText>
                        <ArrowDropDownIcon />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            active={(this.state.leftIsIncludeInactive && this.state.leftIsInactive == null)}
                            onClick={() => {
                                this.setState({
                                    leftCurrentPage: 1,
                                    leftSearch: '',
                                    leftIsIncludeInactive: true,
                                    leftIsInactive: null,
                                }, () => {
                                    this.setFilterInLocalStorage();
                                    this.callReadApi((items, total) => {
                                        this.setState({
                                            leftItems: items,
                                            leftTotal: total,
                                        }, () => {
                                            this.setLoading(false);
                                            this.refreshLayout();
                                        });
                                    });
                                });
                            }}
                        >All</Dropdown.Item>
                        <Dropdown.Item
                            active={(this.state.leftIsIncludeInactive == null && !this.state.leftIsInactive)}
                            onClick={() => {
                                this.setState({
                                    leftCurrentPage: 1,
                                    leftSearch: '',
                                    leftIsIncludeInactive: null,
                                    leftIsInactive: false,
                                }, () => {
                                    this.setFilterInLocalStorage();
                                    this.callReadApi((items, total) => {
                                        this.setState({
                                            leftItems: items,
                                            leftTotal: total,
                                        }, () => {
                                            this.setLoading(false);
                                            this.refreshLayout();
                                        });
                                    });
                                });
                            }} 
                        >Active</Dropdown.Item>
                        <Dropdown.Item
                            active={(this.state.leftIsIncludeInactive == null && this.state.leftIsInactive)}
                            onClick={() => {
                                this.setState({
                                    leftCurrentPage: 1,
                                    leftSearch: '',
                                    leftIsIncludeInactive: null,
                                    leftIsInactive: true,
                                }, () => {
                                    this.setFilterInLocalStorage();
                                    this.callReadApi((items, total) => {
                                        this.setState({
                                            leftItems: items,
                                            leftTotal: total,
                                        }, () => {
                                            this.setLoading(false);
                                            this.refreshLayout();
                                        });
                                    });
                                });
                            }} 
                        >Inactive</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
            <Col xs={'auto'}>
                <WrappedButton
                    className={'px-3 pe-1'}
                    variant={"primary"}
                    size={'sm'}
                    onClick={() => {
                        this.setState({
                          popupShowCustomerForm: true,
                          popupLoadingCustomerForm: false,
                          popupIdCustomerForm: 0,
                          popupIsCopyCustomerForm: false,
                        });
                    }}
                >
                    <i className={'material-icons'}>add</i>
                </WrappedButton>
            </Col>
            <Col xs={'auto'}>
                <Dropdown alignRight>
                    <Dropdown.Toggle
                        as={DropdownToggle}
                        isButton
                        className={'px-3 pe-1'}
                        variant={"light"}
                        size={'sm'}
                        style={{ backgroundColor: 'transparent' }}
                    >
                        <i className={'material-icons'}>format_list_bulleted</i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Header>SORT BY</Dropdown.Header>

                        {this.state.sortColumns.map((item, i) => {
                            return <StyledSortItem
                                key={i}
                                active={(this.state.leftSortColumn == item.value) ? true : false}
                                direction={this.state.leftSortDir}
                                disabled={false}
                                onClick={() => {
                                    this.setState({
                                        sortColumnsLoading: true,
                                        leftCurrentPage: 1,
                                        leftSortColumn: item.value,
                                        leftSortDir: 'asc',
                                    }, () => {
                                        this.callReadApi((items, total) => {
                                            this.setState({
                                                sortColumnsLoading: false,
                                                leftItems: items,
                                                leftTotal: total,
                                            }, () => {
                                                this.setLoading(false);
                                                this.refreshLayout();
                                            });
                                        });
                                    });
                                }}
                            >
                                <div className={'custom-title'}>{item.title}</div>
                                <div className={'custom-icons'}>
                                    <div className={'custom-icon-up'}>
                                        <div>
                                            <i className={'material-icons ' + ((this.state.leftSortDir == 'asc') ? true : false)}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();

                                                    this.setState({
                                                        sortColumnsLoading: true,
                                                        leftCurrentPage: 1,
                                                        leftSortColumn: item.value,
                                                        leftSortDir: 'asc',
                                                    }, () => {
                                                        Utils.clickOutside();
                                                        this.callReadApi((items, total) => {
                                                            this.setState({
                                                                sortColumnsLoading: false,
                                                                leftItems: items,
                                                                leftTotal: total,
                                                            }, () => {
                                                                this.setLoading(false);
                                                                this.refreshLayout();
                                                            });
                                                        });
                                                    });
                                                }}
                                            >arrow_drop_up</i>
                                        </div>
                                    </div>
                                    <div className={'custom-icon-down'}>
                                        <div>
                                            <i className={'material-icons ' + ((this.state.leftSortDir == 'desc') ? true : false)}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();

                                                    this.setState({
                                                        sortColumnsLoading: true,
                                                        leftCurrentPage: 1,
                                                        leftSortColumn: item.value,
                                                        leftSortDir: 'desc',
                                                    }, () => {
                                                        Utils.clickOutside();
                                                        this.callReadApi((items, total) => {
                                                            this.setState({
                                                                sortColumnsLoading: false,
                                                                leftItems: items,
                                                                leftTotal: total,
                                                            }, () => {
                                                                this.setLoading(false);
                                                                this.refreshLayout();
                                                            });
                                                        });
                                                    });
                                                }}
                                            >arrow_drop_down</i>
                                        </div>
                                    </div>
                                </div>
                            </StyledSortItem>
                        })}
                        
                        <Dropdown.Divider />

                        <Dropdown.Item
                            active={false}
                            disabled={false}
                            onClick={() => {
                                this.setState({
                                    sortColumnsLoading: true,
                                    leftCurrentPage: 1,
                                    leftSearch: '',
                                    leftSortColumn: 'updated',
                                    leftSortDir: 'desc',
                                }, () => {
                                    this.callReadApi((items, total) => {
                                        this.setState({
                                            sortColumnsLoading: false,
                                            leftItems: items,
                                            leftTotal: total,
                                        }, () => {
                                            this.setLoading(false);
                                            this.refreshLayout();
                                        });
                                    });
                                });
                            }} 
                        >Refresh List</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
            <Col xs={'auto'}>
                <WrappedButton
                    className={'px-3 pe-1'}
                    variant={"light"}
                    size={'sm'}
                    style={{ backgroundColor: 'transparent' }}
                    onClick={() => {
                        this.setState({
                            leftIsSearchDialog: true,
                        });
                    }}
                >
                    <i className={'material-icons'}>search</i>
                </WrappedButton>
            </Col>
            {this.leftClearSearch()}
        </Row>
    }

    leftBody = () => {
        return <div style={{ width: '100%' }}>
            {
            (this.state.leftItems && this.state.leftItems.length > 0)
            ?
            <>
                <InfiniteScroll
                    loader={<div key={0} className={'text-center pt-2'}><Spinner animation="border" size={'sm'} /></div>}
                    useWindow={false}
                    initialLoad={false}
                    getScrollParent={() => document.getElementById((window.innerWidth < 992) ? 'left_panel_1_ID' : 'left_panel_2_ID')}
                    threshold={1}

                    pageStart={1}
                    hasMore={((this.state.leftTotal > this.state.leftPageSize) && (this.state.leftItems.length < this.state.leftTotal))}

                    loadMore={(page) => {
                        if((this.state.leftTotal > this.state.leftPageSize) && (this.state.leftItems.length < this.state.leftTotal)){
                            this.setState({
                                leftCurrentPage: (this.state.leftCurrentPage+1)
                            }, () => {
                                this.callReadMoreApi(() => {
                                    this.setLoading(false);
                                    this.refreshLayout();
                                });
                            });
                        }
                    }}
                >
                    {this.state.leftItems.map((item, i) => {
                        return <div key={i}
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                                if(!item.active){
                                    this.setState({
                                        id: item.value,
                                        details: null,
                                    }, () => {
                                        this.props.history.push('/Customer-overview-email?id=' + this.state.id);

                                        this.state.leftItems.forEach(x => x.active = false);
                                        item.active = true;
                                        this.refreshLayout();

                                        this.callDetailsApis();
                                    });
                                }
                            }}
                        >
                            <StyledFormCheck
                                type={'checkbox'}
                                active={item.active}
                                isActive={item.isActive}
                            >
                                <Row className={'align-items-center g-0'}>
                                    {/* <Col xs={'auto'}>
                                        <Form.Check.Input
                                            type={'checkbox'}
                                            checked={item.checked}
                                            disabled={item.disabled}
                                            onChange={(e) => {
                                                item.checked = e.target.checked;
                                                this.refreshLayout();
                                            }}
                                        />
                                    </Col> */}
                                    <Col xs={true}>
                                        <Row className={'align-items-center g-0'}>
                                            <Col xs={12}>
                                                <Form.Check.Label
                                                    // onClick={(e) => {
                                                    //     if(!item.active){
                                                    //         this.setState({
                                                    //             id: item.value,
                                                    //             details: null,
                                                    //         }, () => {
                                                    //             this.props.history.push('/Customer-overview-email?id=' + this.state.id);
                            
                                                    //             this.state.leftItems.forEach(x => x.active = false);
                                                    //             item.active = true;
                                                    //             this.refreshLayout();
                            
                                                    //             this.callDetailsApis();
                                                    //         });
                                                    //     }
                                                    // }}
                                                >{item.title}</Form.Check.Label>
                                            </Col>
                                            {!this.state.sortColumnsLoading && <Col xs={12}>
                                                <Form.Check.Label
                                                    className={'text-secondary'}
                                                    onClick={(e) => {
                                                        if(!item.active){
                                                            this.setState({
                                                                id: item.value,
                                                                details: null,
                                                            }, () => {
                                                                this.props.history.push('/Customer-overview-email?id=' + this.state.id);
                            
                                                                this.state.leftItems.forEach(x => x.active = false);
                                                                item.active = true;
                                                                this.refreshLayout();
                            
                                                                this.callDetailsApis();
                                                            });
                                                        }
                                                    }}
                                                >{Utils.findSortItemTemplate(this.state.sortColumns, this.state.leftSortColumn, ((item && item.item) ? item.item : null))}</Form.Check.Label>
                                            </Col>}
                                        </Row>
                                    </Col>
                                </Row>
                            </StyledFormCheck>
                        </div>
                    })}
                </InfiniteScroll>

                
                
                {/* {((this.state.leftTotal > this.state.leftPageSize) && (this.state.leftItems.length < this.state.leftTotal)) && <div className={'pt-3 pb-3'}>
                    <a href={'/'}
                        className={'link-primary'}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            
                            this.setState({
                                leftCurrentPage: (this.state.leftCurrentPage + 1)
                            }, () => {
                                this.callReadMoreApi(() => {
                                    this.setLoading(false);
                                    this.refreshLayout();
                                });
                            });
                        }}
                    >
                        Show More
                    </a>
                </div>} */}

            </>
            :
            <h6 className={'text-center pt-5 m-0'}>No Results!</h6>
            }
        </div>
    }

    leftClearSearch = () => {
        if(this.state.leftSearch !== ''){
            return <Col xs={12} className={'text-end'}>
                <a href={'/'}
                    className={'link-primary'}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        this.setState({
                            leftCurrentPage: 1,
                            leftSearch: '',
                            leftIsSearchDialog: false,
                        }, () => {
                            this.callReadApi((items, total) => {
                                this.setState({
                                    leftItems: items,
                                    leftTotal: total,
                                }, () => {
                                    this.setLoading(false);
                                    this.refreshLayout();
                                });
                            });
                        });
                    }}
                >
                    Clear Search
                </a>
            </Col>
        }
    }

    rightHeader = () => {
        if((this.state.id > 0) && this.state.details){
            return <Row className={'align-items-center justify-content-center justify-content-md-start py-5'}>
                <Col xs={'auto'}>
                    <h2 className={'m-0'}>{(this.state.details && this.state.details.customerName && this.state.details.customerName != '') ? this.state.details.customerName : <>&nbsp;</>}</h2>
                </Col>
                <Col xs={'auto'}>
                    {this.state.details && <StyledBadge 
                        pill 
                        className={'px-5 py-2'} 
                        bg={this.state.details.isActive ? 'default' : 'danger'}
                        text={this.state.details.isActive ? 'dark' : 'light'}
                    >
                        {this.state.details.isActive ? 'Active' : 'Inactive'}
                    </StyledBadge>}
                </Col>
            </Row>
        }
    }

    rightTabs = () => {
        if((this.state.id > 0) && this.state.details){
            return <Row className={'gx-md-0 gx-lg-5 align-items-center justify-content-center justify-content-md-start'}>
                {(Utils.hasPermission(this.permissions, PagesPermissions.CustomersOverview.read)) && <Col xs={'auto'}>
                    <StyledTabButton
                        active={false}
                        onClick={() => {
                            this.props.history.push('/Customer-overview?id=' + this.state.id);
                        }}
                    >Overview</StyledTabButton>
                </Col>}
                {(Utils.hasPermission(this.permissions, PagesPermissions.CustomerServices.read)) && <Col xs={'auto'}>
                    <StyledTabButton
                        active={false}
                        onClick={() => {
                            this.props.history.push('/Customer-overview-local-services?id=' + this.state.id);
                        }}
                    >Local Service</StyledTabButton>
                </Col>}
                {(Utils.hasPermission(this.permissions, PagesPermissions.CustomerSites.read)) && <Col xs={'auto'}>
                    <StyledTabButton
                        active={false}
                        onClick={() => {
                            this.props.history.push('/Customer-overview-sites?id=' + this.state.id);
                        }}
                    >Sites</StyledTabButton>
                </Col>}
                {(Utils.hasPermission(this.permissions, PagesPermissions.CustomersEmail.read)) && <Col xs={'auto'}>
                    <StyledTabButton
                        active={true}
                        onClick={() => {
                            this.props.history.push('/Customer-overview-email?id=' + this.state.id);
                        }}
                    >Email Logs</StyledTabButton>
                </Col>}
            </Row>
        }
    }

    rightBody = () => {
        return <Row className={'gx-0'}>
            <Col xs={12}>
                {this.topSection()}
            </Col>
            <Col xs={12}>
                {this.tableSection()}
            </Col>
            <Col xs={12}>
                {this.paginationSection()}
            </Col>
        </Row>
    }

    topSection = (state = true) => {
		return <ViewTopSection
            hasChecked={(!this.state.isDisabledBatchAction)}
            unchecked={this.topSectionUnchecked(state)}
            checked={this.topSectionChecked(state)}
        />
	}
	topSectionUnchecked = (state = true) => {
        return <Row className={"align-items-center"}>
            <Col xs={'auto'} className={'pb-2 pb-md-0'}>
                <IconButtonSearch 
                    value={this.state.search}
                    isOpen={(this.state.search !== '') ? true : false}
                    onPressEnter={(value) => {
                        this.setState({
                            search: value,
                            currentPage: 1,
                        }, () => {
                            this.setFilterInLocalStorage();
                            this.callReadCustomerEmailApi((rows, total) => {
                                this.refreshLayout();
                            });
                        });
                    }}
                    onClear={() => {
                        this.setState({
                            search: '',
                            currentPage: 1,
                        }, () => {
                            this.setFilterInLocalStorage();
                            this.callReadCustomerEmailApi((rows, total) => {
                                this.refreshLayout();
                            });
                        });
                    }}
                />
            </Col>

            <Col xs={true}></Col>

            <Col xs={'auto'} className={'text-center'}>
                <DropdownMenu
                    className={'px-3'}
                    variant={"light"}
                    size={"sm"}
                    text={<ViewHeadlineIcon />}
                >
                    <DataTableActionMenu 
                        pageName={this.pageName}
                        columns={this.state.columns}
                        selected={this.state.rowsPerPageSelected}
                        selectItems={this.state.rowsPerPageOptions}
                        isCheckedIncludeInactive={this.state.isIncludeInactive}
                        columnVisibility={this.state.columnVisibility}
                        canSelectOption={false}
                        onChangeColumnVisibility={(index, value) => {
                            let colVis = this.state.columnVisibility;
                            colVis[index] = value;

                            this.setState({
                                columnVisibility: colVis
                            }, () => {
                                this.setFilterInLocalStorage();
                            });
                        }}
                        canInactive={false}
                        onSelectChangeAjax={(val) => {
                            this.setState({
                                currentPage: 1,
                                rowsPerPageSelected: val
                            }, () => {
                                this.setFilterInLocalStorage();
                                this.callReadCustomerEmailApi((rows, total) => {
                                    this.refreshLayout();
                                });
                            });
                        }}
                        onChangeIncludeInactive={(val) => {
                            this.setState({
                                isIncludeInactive: val,
                                currentPage: 1,
                            }, () => {
                                this.setFilterInLocalStorage();
                                this.callReadCustomerEmailApi((rows, total) => {
                                    this.refreshLayout();
                                });
                            });
                        }}
                        isCookies={this.state.isCookies}
                        onResetColumns={() => {
                            this.setState({
                                columns: Array.from(this.state.initColumns),
                                columnVisibility: Array.from(this.state.initColumnVisibility),
                                sortColumn: this.state.sortColumnInit,
                                sortDir: this.state.sortDirInit,
                            }, () => {
                                this.setFilterInLocalStorage(false);
                                this.callReadCustomerEmailApi((rows, total) => {
                                    this.refreshLayout();
                                });
                                if(this.refDropdownActionMenu && this.refDropdownActionMenu.current){
                                    this.refDropdownActionMenu.current.hide();
                                }
                            });
                        }}
                        onShowMore={(isShowedMore) => {
                            this.setState({
                                pageName: this.pageName,
                            });
                        }}
                    />
                </DropdownMenu>
            </Col>

        </Row>
    }
	topSectionChecked = (state = true) => {
        return <Row className={"align-items-center"}>
            <Col xs={12} md={true}>
                <span>{((this.tableRef && this.tableRef.current) ? this.tableRef.current.getSelected().length : '0')} item(s) selected</span>
            </Col>
        </Row>
    }
    
	tableSection = () => {
		return <DataTableScrollSync ref={this.refDataTableScrollSync} className={"table-sync-v4"}>{this.setDataTable()}</DataTableScrollSync>
	}
    setDataTable = () => {
        return <DataTable 
            ref={this.tableRef}

            className={"table-style-v4"}
            classNameWrapper={"table-v4"}

            pageName={this.pageName}
            rowId={this.pageIdField}
            caption={this.pageCaption}
            
            noMarginBottom={false}
            verticalAlign={'middle'}
            
            stickyHeader={false}
            isHeader={true}
            resizable={true}
            isDragableHeaderColumns={true}
            isDragable={false}
            isCheckbox={false}
            isBorder={false}
            isPagination={false}
            isVirtualize={false}
            
            columnVisibility={this.state.columnVisibility}
            disabledResizeColumns={[0]}

            isLoading={this.state.isLoading}
            rowsPerPageSelected={this.state.rowsPerPageSelected}
            rowsPerPageOptions={this.state.rowsPerPageOptions}

            search={this.state.search}
            rows={this.state.rows}
            columns={this.state.columns}

            onRowClick={(id, row) => {
                
            }}

            onRemove={(row) => {

            }}

            onClickCheckAll={(obj, checked, isDisabledBatchAction) => {
                this.setState({
                    isDisabledBatchAction: isDisabledBatchAction
                });
            }}
            onCheckRow={(obj, id, isDisabledBatchAction) => {
                this.setState({
                    isDisabledBatchAction: isDisabledBatchAction
                });
            }}
            
            onSortEnd={(columns, columnVisibility) => {
                this.setState({
                    columns: columns,
                    columnVisibility: columnVisibility,
                }, () => {
                    this.setFilterInLocalStorage();
                });
            }}
            onOrder={(name, sort) => {
                this.setState({
                    sortColumn: name,
                    sortDir: sort,
                }, () => {
                    this.setFilterInLocalStorage();
                    this.callReadCustomerEmailApi((rows, total) => {
                        this.refreshLayout();
                    });
                });
            }}
            onResize={(columns) => {
                if(columns){
                  this.setState({
                    columns: columns
                  });
                }
                this.setFilterInLocalStorage();
            }}
        />
    }

	paginationSection = () => {
		return <DataTableAjaxPagination
            style={{
                padding: '10px 0px',
                border: '0px',
                left: this.state.size + 'px',
                width: 'calc(100% - ' + this.state.size + 'px)'
            }}
            page={this.state.currentPage}
            selected={this.state.rowsPerPageSelected}
            count={this.state.totalRows}
            fixed={true}
            onPageChange={(page) => {
                this.setState({
                    currentPage: page,
                }, () => {
                    this.setFilterInLocalStorage();
                    this.callReadCustomerEmailApi((rows, total) => {
                        this.refreshLayout();
                    });
                });
            }}
            onOptionsChange={(option) => {
              this.setState({
                currentPage: 1,
                rowsPerPageSelected: option,
              }, () => {
                this.setFilterInLocalStorage();
                this.callReadCustomerEmailApi((rows, total) => {
                    this.refreshLayout();
                });
              });
            }}
        />
	}
    /* END FORM */


    /* DIALOG */
    setSearchDialog = () => {
        return <SearchDialog 
            show={this.state.leftIsSearchDialog}
            onCancel={() => {
                this.setState({
                    leftIsSearchDialog: false,
                });
            }}
            onSearch={(value) => {
                this.setState({
                    leftCurrentPage: 1,
                    leftSearch: value,
                    leftIsSearchDialog: false,
                }, () => {
                    this.callReadApi((items, total) => {
                        this.setState({
                            leftItems: items,
                            leftTotal: total,
                        }, () => {
                            this.setLoading(false);
                            this.refreshLayout();
                        });
                    });
                });
            }}
        />
    }

    setPopupCustomerForm = () => {
        return <Popup 
            className={'no-bg'}
            auth={this.props.auth}
            settingsType={this.props.settingsType}

            show={this.state.popupShowCustomerForm}
            isLoading={this.state.popupLoadingCustomerForm}
            id={this.state.popupIdCustomerForm}
            isCopy={this.state.popupIsCopyCustomerForm}

            showHeader={true}
            header={this.state.popupIdCustomerForm ? this.state.popupIsCopyCustomerForm ? 'Copy Customer' : 'Edit Customer' : 'New Customer'}
            showFooter={true}
            centerFooter={'start'}
            footer={({ isLoading, id, onHide, onSave, onSaveCreateNewSite }) => {
                return <Row>
                    <Col xs={'auto'}>
                        <LoadingButton
                            isLoading={isLoading}
                            onClick={onSave}
                        >Save</LoadingButton>
                    </Col>

                    {(!id) && <Col xs={'auto'}>
                        <Button 
                            type={'button'}
                            variant={'primary'}
                            disabled={isLoading}
                            onClick={onSaveCreateNewSite}
                        >
                            Save & Create New Site
                            {isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                        </Button>
                    </Col>}

                    <Col xs={'auto'}>
                        <Button
                            variant={'light'}
                            disabled={isLoading}
                            onClick={onHide}
                        >Cancel</Button>
                    </Col>
                </Row>
            }}

            scrollable={true}
            centered={true}
            closeButton={true}
            fullscreen={true}
            noBodyPadding={true}

            onHide={() => {
                this.setState({
                    popupShowCustomerForm: false,
                    popupLoadingCustomerForm: false,
                    popupIdCustomerForm: 0,
                    popupIsCopyCustomerForm: false,
                });
            }}
            onSave={() => {
                if(this.refCustomerForm && this.refCustomerForm.current){
                    if(this.refCustomerForm.current.refForm && this.refCustomerForm.current.refForm.current){
                        this.refCustomerForm.current.refForm.current.handleSubmit();
                    }
                }
            }}
            onSaveCreateNewSite={() => {
                if(this.refCustomerForm && this.refCustomerForm.current){
                    if(this.refCustomerForm.current.onSaveCreateNewSite){
                        this.refCustomerForm.current.onSaveCreateNewSite((res) => {
                            this.props.history.push({
                                pathname: '/Customer-overview-sites',
                                search: '?id=' + res.customerId,
                                state: { showNewPopup: true },
                            });
                        });
                    }
                }
            }}
            
            onLoading={(state) => {
                this.setState({
                    popupLoadingCustomerForm: state,
                });
            }}
            onSubmit={(form) => {
                this.setState({
                    popupShowCustomerForm: false,
                    popupLoadingCustomerForm: false,
                    popupIdCustomerForm: 0,
                    popupIsCopyCustomerForm: false,
                    details: null,
                }, () => {
                    this.callReadApi((items, total) => {
                        this.setState({
                            leftItems: items,
                            leftTotal: total,
                        }, () => {
                            this.setLoading(false);
                            this.refreshLayout();
                        });
                    });

                    this.callDetailsApis();
                });
            }}
        >
            {(props) => {
                return <CustomerForm
                    ref={this.refCustomerForm}
                    {...props}
                />
            }}
        </Popup>
    }
    /* END DIALOG */


    render() {
        return <>
            <Row style={{ display: 'contents', width: '100%' }}>
                <Col xs={12} className={'p-0'}>
                    {this.state.mainLoading ? null : this.rightBody()}
                </Col>
            </Row>
            {this.setSearchDialog()}
            {this.setPopupCustomerForm()}
        </>
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  dispatchApiCallPost,
  dispatchApiCallPut,
  dispatchApiCallDelete,
  saveUserCookieSettings,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CustomerEmail);
