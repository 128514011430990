const name = 'dispatch';

export const DISPATCH_POST = 'api_call_' + name + '_post';
export const DISPATCH_GET = 'api_call_' + name + '_get';
export const DISPATCH_PUT = 'api_call_' + name + '_put';
export const DISPATCH_DELETE = 'api_call_' + name + '_delete';

export const dispatchApiCallPost = (data, name = '', path = '', variables = null, callback = null, callbackError500 = null, cancelRequestToken = null) => ({ type: DISPATCH_POST, payload: { isResponse: false, name: name, path: path, isLoading: true, params: data, data: null, err: null, isError: false, statusCode: null, variables: variables, callback: callback, callbackError500: callbackError500, cancelRequestToken: cancelRequestToken } })
export const dispatchApiCallGet = (data, name = '', path = '', variables = null, callback = null, callbackError500 = null, cancelRequestToken = null) => ({ type: DISPATCH_GET, payload: { isResponse: false,  name: name, path: path, isLoading: true, params: data, data: null, err: null, isError: false, statusCode: null, variables: variables, callback: callback, callbackError500: callbackError500, cancelRequestToken: cancelRequestToken } })
export const dispatchApiCallPut = (data, name = '', path = '', variables = null, callback = null, callbackError500 = null, cancelRequestToken = null) => ({ type: DISPATCH_PUT, payload: { isResponse: false,  name: name, path: path, isLoading: true, params: data, data: null, err: null, isError: false, statusCode: null, variables: variables, callback: callback, callbackError500: callbackError500, cancelRequestToken: cancelRequestToken } })
export const dispatchApiCallDelete = (data, name = '', path = '', variables = null, callback = null, callbackError500 = null, cancelRequestToken = null) => ({ type: DISPATCH_DELETE, payload: { isResponse: false,  name: name, path: path, isLoading: true, params: data, data: null, err: null, isError: false, statusCode: null, variables: variables, callback: callback, callbackError500: callbackError500, cancelRequestToken: cancelRequestToken } })
