import React from 'react';

import {
    Box,
    RadioGroup,
    FormControlLabel,
    Radio
} from '@material-ui/core';


const CustomRadio = props => {
    const { items, value, name, vertical, className, disabled } = props;

    const [valueR, setValueR] = React.useState(value ? value : 0);
    const [nameR] = React.useState(name ? name : '');
    const [verticalR] = React.useState(vertical ? vertical : '');
    const [itemsR, setItemsR] = React.useState(items ? items : []);
    const [disabledR, setDisabledR] = React.useState(disabled);


    React.useEffect(() => {
        setValueR(value ? value : 0);
        setItemsR(items);
        setDisabledR(disabled);
    }, [items, value, disabled]);


    const handleChange = event => {
        setValueR(event.target.value);

        if(props.onChange && (value !== parseInt(event.target.value))){
            props.onChange(parseInt(event.target.value));
        }
    };

    
    return (
        <Box className={"custom-radio-component " + (props.className ? props.className : '')}>
            <RadioGroup className={className + " " + (verticalR ? "vertical" : "")} name={nameR} value={valueR} onChange={handleChange} >
                {itemsR.map((item, i) => {
                    return <Box key={i} className={className}>
                        <FormControlLabel
                            value={item.value}
                            disabled={disabledR}
                            control={<Radio 
                                color={item.color ? item.color : 'primary'} 
                                onClick={() => {
                                    setValueR(parseInt(item.value));

                                    if(props.onChange && (value !== parseInt(item.value))){
                                        props.onChange(parseInt(item.value));
                                    }
                                }}
                            />}
                            label={item.label}
                            labelPlacement="end"
                            onClick={() => {
                                if(!disabledR){
                                    setValueR(parseInt(item.value));

                                    if(props.onChange && (value !== parseInt(item.value))){
                                        props.onChange(parseInt(item.value));
                                    }
                                }
                            }}
                        />
                        {(item.desc && item.desc !== '') && <div 
                            className={"desc-muted"} 
                            onClick={() => {
                                if(!disabledR){
                                    setValueR(parseInt(item.value));

                                    if(props.onChange && (value !== parseInt(item.value))){
                                        props.onChange(parseInt(item.value));
                                    }
                                }
                            }}
                        >{item.desc}</div>}
                        {item.value === valueR ? item.children : null}
                    </Box>
                })}
            </RadioGroup>
        </Box>
    );
  }

  export default CustomRadio;