import React from 'react';

import {
    Snackbar
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';


const TooltipIcon = props => {
    const [open, setOpen] = React.useState(props.open ? props.open : false);

    const [text, setText] = React.useState(props.text ? props.text : '');
    const [autoHideDuration] = React.useState(props.autoHideDuration ? props.autoHideDuration : 4000);
    const [vertical] = React.useState(props.vertical ? props.vertical : 'top');
    const [horizontal] = React.useState(props.horizontal ? props.horizontal : 'right');
    const [severity, setSeverity] = React.useState(props.severity ? props.severity : 'error');


    React.useEffect(() => {
        setOpen(props.open);
        setText(props.text);
        setSeverity(props.severity ? props.severity : severity);
    }, [ props.open, props.text, props.severity, severity]);


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);

        if(props.onClose){
            props.onClose();
        }
    };
    
    const setAlertMsg = () => {
        if(text){
            if(typeof text === 'string'){
                return (text ? text : '');
            } else {
                if(text.length > 0){
                    return text.map((item, i) => {
                        return <div key={i} className={'text-white'}>{item}</div>;
                    });
                } else {
                    return '';
                }
            }
        } else {
            return '';
        }
    };


    return (
        <Snackbar 
            open={open} 
            autoHideDuration={autoHideDuration} 
            anchorOrigin={{ vertical, horizontal }}
            onClose={handleClose}
        >
            <Alert 
                className={"snackbar-component"}
                elevation={6} 
                variant="filled" 
                onClose={handleClose} 
                severity={severity}
            >
                <div className={'text-white'}>{setAlertMsg()}</div>
            </Alert>
        </Snackbar>
    );
  }

  export default TooltipIcon;