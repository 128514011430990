import React, { Component } from 'react';
import moment from 'moment';

import Utils from '../../../utils/utils'
import { PageCookieSettingType, JobStepType, ReportUOM } from '../../../utils/enums'

import { connect } from 'react-redux'
import { actions } from "../../../../setup/redux/auth/saga";
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet, dispatchApiCallPost } from '../../../../setup/redux/dispatch/actions'
import signalRslice from "../../../components/signalR/signalRslice";
import dateSection from "../../../../setup/redux/slices/dateSection";

import DataTable from "../../../components/OLD/DataTable/DataTable";
import DataTableAjaxPagination from '../../../components/OLD/DataTable/Pagination/DataTableAjaxPaginationV5';
import DataTableScrollSync from '../../../components/OLD/DataTable/DataTableScrollSync.jsx';
import DataTableActionMenu from '../../../components/OLD/DataTable/DataTableActionMenu';

import IconButtonDropdown from '../../../components/OLD/Dropdown/IconButtonDropdown';

import { 
    Form,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import RSuiteDatePicker from '../../../components/OLD/Datepicker/RSuiteDatePicker';
import RSuiteTimePicker from '../../../components/OLD/TimePicker/RSuiteTimePicker';


const { saveUserCookieSettings } = actions;

const iputStyle = {
    backgroundColor: '#F7F7F7',
    borderRadius: '0px',
    // border: '0px',
};
const StyledDatePicker = styled(RSuiteDatePicker)`
    border-radius: 0px;
    border: 0px;

    &.default-look {
        .rs-picker-date {
            background-color: #F7F7F7;
            border-radius: 0px;
            border: 0px;

            input {
                background-color: #F7F7F7;
                color: #484848;
            }

            .rs-picker-toggle-textbox {
                padding-left: 0px;
            }
        }

        .rs-picker-read-only {
            cursor: pointer;
        }
    }
`;
const StyledTimePicker = styled(RSuiteTimePicker)`
    input {
        background-color: #F7F7F7;
    }

    &.rs-picker-read-only {
        .rs-picker-toggle-value {
            color: #A1A5B7;
        }

        .rs-picker-toggle-placeholder {
            color: #A1A5B7 !important;
        }
    }
`;


class PopupWeighChit extends Component {
    constructor(props) {
        super(props);
        
        this.pageName = "job_weigh_chit_list";
        this.pageIdField = "jobId";
        this.pageAPI = "job";
        this.pageCaption = 'Weigh Chit'

        this.tableRef = React.createRef();
        this.refDataTableScrollSync = React.createRef();
        this.refTableWrapper = React.createRef();
        this.refDropdownActionMenu = React.createRef();
        
        this.scrollLeftPosition = 0;

        this.dispatchUser = (props.auth && props.auth.dispatchUser) ? props.auth.dispatchUser : null;

        this.customerNameTemplate = (id, row, col, obj, index) => {
            return (row.customerNameDisplay && row.customerNameDisplay !== '') ? row.customerNameDisplay : ''
        };
        this.customerNameExportTemplate = (row) => {
        return (row.customerNameDisplay && row.customerNameDisplay !== '') ? row.customerNameDisplay : ''
        };
        this.customerNameExportCSVTemplate = (row) => {
            return (row.customerNameDisplay && row.customerNameDisplay !== '') ? row.customerNameDisplay : ''
        };
    
        this.jobDateCompleteTemplate = (id, row, col, obj, index) => {
        return (row.jobAttemptCompletedDateDisplay) ? Utils.toUcase(row.jobAttemptCompletedDateDisplay) : '';
        };
        this.jobDateCompleteExportTemplate = (row) => {
        return (row.jobAttemptCompletedDateDisplay) ? Utils.toUcase(row.jobAttemptCompletedDateDisplay) : '';
        };
        this.jobDateCompleteExportCSVTemplate = (row) => {
        return (row.jobAttemptCompletedDateDisplay) ? Utils.toUcase(row.jobAttemptCompletedDateDisplay) : '';
        };

        this.WasteInItemTemplate = (id, row, col, obj, index) => {
            if(row && row.wasteTypesIn && row.wasteTypesIn.length > 0){
                return row.wasteTypesIn.map(x => x.wasteTypeName).join(", ");
            }
        };
        this.WasteInItemExportTemplate = (row) => {
            if(row && row.wasteTypesIn && row.wasteTypesIn.length > 0){
            return row.wasteTypesIn.map(x => x.wasteTypeName).join(", ");
            }
        }
        this.WasteInItemExportCSVTemplate = (row) => {
            if(row && row.wasteTypesIn && row.wasteTypesIn.length > 0){
            return row.wasteTypesIn.map(x => x.wasteTypeName).join(", ");
            }
        };

        this.weightChitTicketNumberTemplate = (id, row, col, obj, index) => {
            let stepType = (row && row.steps && row.steps.length > 0) ? (row.steps[0]) ? row.steps[0].stepType : null : null;
            
            if((stepType == JobStepType.Put) || (stepType == JobStepType.Task) || (stepType == JobStepType.Shift)){
                return <div className={'text-center'}>-</div>
            } else {
                return <Form.Control
                    id={this.pageName + '_input_' + id}
                    as={'input'}
                    style={iputStyle}

                    disabled={false}
                    readOnly={Utils.isRejectedOrCancelledOrFailedOrDeleted(row.statusName)}
                    placeholder={''}

                    defaultValue={(row && row[col.name]) ? row[col.name] : ''}
                    onBlur={(e) => {
                        row[col.name] = e.target.value;
                    }}
                    onChange={(e) => {
                        if(this.props.onChanged){
                            this.props.onChanged();
                        }
                    }}
                />
            }
        };
        this.weightChitTicketNumberExportTemplate = (row) => {
            let stepType = (row && row.steps && row.steps.length > 0) ? (row.steps[0]) ? row.steps[0].stepType : null : null;
            
            if((stepType == JobStepType.Put) || (stepType == JobStepType.Task) || (stepType == JobStepType.Shift)){
                return <div className={'text-center'}>-</div>
            } else {
                return (row && row.weightChitTicketNumber) ? row.weightChitTicketNumber : ''
            }
        };
        this.weightChitTicketNumberExportCSVTemplate = (row) => {
            let stepType = (row && row.steps && row.steps.length > 0) ? (row.steps[0]) ? row.steps[0].stepType : null : null;
            
            if((stepType == JobStepType.Put) || (stepType == JobStepType.Task) || (stepType == JobStepType.Shift)){
                return <div className={'text-center'}>-</div>
            } else {
                return (row && row.weightChitTicketNumber) ? row.weightChitTicketNumber : ''
            }
        };

        this.binWeightTemplate = (id, row, col, obj, index) => {
            let stepType = (row && row.steps && row.steps.length > 0) ? (row.steps[0]) ? row.steps[0].stepType : null : null;
            
            let kgRegex = RegExp(/^(?:\d{1,5})?$/);
            let tRegex = RegExp(/^(?:\d{1,2}(?:\.\d{1,3})?)?$/);

            let regex = (this.dispatchUser.defaultUoM == ReportUOM.kg) ? kgRegex : tRegex;
            let val = (row && row[col.name]) ? row[col.name].toString() : '';
            let isInvalid = false;
            try {
                isInvalid = (val.match(regex) == null);
            }catch(e){}
            
            if((stepType == JobStepType.Put) || (stepType == JobStepType.Task) || (stepType == JobStepType.Shift)){
                return <div className={'text-center'}>-</div>
            } else {
                return <Form.Control
                    id={this.pageName + '_input_' + id}
                    as={'input'}
                    type={'number'}
                    style={iputStyle}
    
                    isInvalid={isInvalid}
                    disabled={false}
                    readOnly={Utils.isRejectedOrCancelledOrFailedOrDeleted(row.statusName)}
                    placeholder={''}
    
                    value={(row && row[col.name]) ? row[col.name] : ''}
                    onChange={(e) => {
                        if(this.refDataTableScrollSync && this.refDataTableScrollSync.current){
                            this.scrollLeftPosition = this.refDataTableScrollSync.current.scrollLeft;
                        }
    
                        let regex = (this.dispatchUser.defaultUoM == ReportUOM.kg) ? kgRegex : tRegex;
                        if(e.target.value.match(regex) !== null){
                            let value = e.target.value;
                            
                            let grossWeight = Utils.parseFloat(value);
                            let tareWeight = Utils.parseFloat((row && row['tareBinWeight']) ? row['tareBinWeight'] : '0');
                            let nonBillableBinWeight = Utils.parseFloat((row && row['nonBillableBinWeight']) ? row['nonBillableBinWeight'] : '0');
                            let nettWeight = grossWeight - tareWeight;
                            let billableWeight = nettWeight - nonBillableBinWeight;
                            billableWeight = (billableWeight < 0) ? 0 : billableWeight;
        
                            let rows = this.state.rows;
                            if(rows && rows.length > 0 && rows[index]){
                                rows[index][col.name] = value;
                                rows[index]['netBinWeight'] = nettWeight.toFixed((this.dispatchUser.defaultUoM === ReportUOM.kg) ? 0 : 3);
                                rows[index]['billableBinWeight'] = billableWeight.toFixed((this.dispatchUser.defaultUoM === ReportUOM.kg) ? 0 : 3);
                            }
                            this.setState({
                                rows: rows
                            });
                            
                            if(this.props.onChanged){
                                this.props.onChanged();
                            }
                        }
                    }}
                />
            }
        };
        this.binWeightExportTemplate = (row) => {
            let stepType = (row && row.steps && row.steps.length > 0) ? (row.steps[0]) ? row.steps[0].stepType : null : null;
            
            if((stepType == JobStepType.Put) || (stepType == JobStepType.Task) || (stepType == JobStepType.Shift)){
                return <div className={'text-center'}>-</div>
            } else {
                return (row && row.binWeight) ? row.binWeight : ''
            }
        };
        this.binWeightExportCSVTemplate = (row) => {
            let stepType = (row && row.steps && row.steps.length > 0) ? (row.steps[0]) ? row.steps[0].stepType : null : null;
            
            if((stepType == JobStepType.Put) || (stepType == JobStepType.Task) || (stepType == JobStepType.Shift)){
                return <div className={'text-center'}>-</div>
            } else {
                return (row && row.binWeight) ? row.binWeight : ''
            }
        };

        this.tareBinWeightTemplate = (id, row, col, obj, index) => {
            let stepType = (row && row.steps && row.steps.length > 0) ? (row.steps[0]) ? row.steps[0].stepType : null : null;
            
            let kgRegex = RegExp(/^(?:\d{1,5})?$/);
            let tRegex = RegExp(/^(?:\d{1,2}(?:\.\d{1,3})?)?$/);

            let regex = (this.dispatchUser.defaultUoM == ReportUOM.kg) ? kgRegex : tRegex;
            let val = (row && row[col.name]) ? row[col.name].toString() : '';
            let isInvalid = false;
            try {
                isInvalid = (val.match(regex) == null);
            }catch(e){}

            if((stepType == JobStepType.Put) || (stepType == JobStepType.Task) || (stepType == JobStepType.Shift)){
                return <div className={'text-center'}>-</div>
            } else {
                return <Form.Control
                    id={this.pageName + '_input_' + id}
                    as={'input'}
                    type={'number'}
                    style={iputStyle}
    
                    isInvalid={isInvalid}
                    disabled={false}
                    readOnly={((row.binWeight !== '') ? false : true) || Utils.isRejectedOrCancelledOrFailedOrDeleted(row.statusName)}
                    placeholder={''}
    
                    value={(row && row[col.name]) ? row[col.name] : '0'}
                    onChange={(e) => {
                        if(this.refDataTableScrollSync && this.refDataTableScrollSync.current){
                            this.scrollLeftPosition = this.refDataTableScrollSync.current.scrollLeft;
                        }
                        
                        let regex = (this.dispatchUser.defaultUoM == ReportUOM.kg) ? kgRegex : tRegex;
                        if(e.target.value.match(regex) !== null){
                            let value = e.target.value;
                            
                            let grossWeight = Utils.parseFloat((row && row['binWeight']) ? row['binWeight'] : '0');
                            let tareWeight = Utils.parseFloat(value);
                            let nonBillableBinWeight = Utils.parseFloat((row && row['nonBillableBinWeight']) ? row['nonBillableBinWeight'] : '0');
                            let nettWeight = grossWeight - tareWeight;
                            let billableWeight = nettWeight - nonBillableBinWeight;
                            billableWeight = (billableWeight < 0) ? 0 : billableWeight;
        
                            let rows = this.state.rows;
                            if(rows && rows.length > 0 && rows[index]){
                                rows[index][col.name] = value;
                                rows[index]['netBinWeight'] = nettWeight.toFixed((this.dispatchUser.defaultUoM === ReportUOM.kg) ? 0 : 3);
                                rows[index]['billableBinWeight'] = billableWeight.toFixed((this.dispatchUser.defaultUoM === ReportUOM.kg) ? 0 : 3);
                            }
                            this.setState({
                                rows: rows
                            });
                            
                            if(this.props.onChanged){
                                this.props.onChanged();
                            }
                        }
                    }}
                />
            }
        };
        this.tareBinWeightExportTemplate = (row) => {
            let stepType = (row && row.steps && row.steps.length > 0) ? (row.steps[0]) ? row.steps[0].stepType : null : null;
            
            if((stepType == JobStepType.Put) || (stepType == JobStepType.Task) || (stepType == JobStepType.Shift)){
                return <div className={'text-center'}>-</div>
            } else {
                return (row && row.tareBinWeight) ? row.tareBinWeight : ''
            }
        };
        this.tareBinWeightExportCSVTemplate = (row) => {
            let stepType = (row && row.steps && row.steps.length > 0) ? (row.steps[0]) ? row.steps[0].stepType : null : null;
            
            if((stepType == JobStepType.Put) || (stepType == JobStepType.Task) || (stepType == JobStepType.Shift)){
                return <div className={'text-center'}>-</div>
            } else {
                return (row && row.tareBinWeight) ? row.tareBinWeight : ''
            }
        };

        this.netBinWeightTemplate = (id, row, col, obj, index) => {
            let stepType = (row && row.steps && row.steps.length > 0) ? (row.steps[0]) ? row.steps[0].stepType : null : null;
            
            if((stepType == JobStepType.Put) || (stepType == JobStepType.Task) || (stepType == JobStepType.Shift)){
                return <div className={'text-center'}>-</div>
            } else {
                return <Form.Control
                    id={this.pageName + '_input_' + id}
                    as={'input'}
                    type={'number'}
                    style={iputStyle}
    
                    disabled={false}
                    readOnly={true}
                    placeholder={''}
    
                    value={(row && row[col.name]) ? row[col.name] : '0'}
                    onBlur={(e) => {
                        row[col.name] = e.target.value;
                    }}
                    onChange={(e) => {
                        if(this.props.onChanged){
                            this.props.onChanged();
                        }
                    }}
                />
            }
        };
        this.netBinWeightExportTemplate = (row) => {
            let stepType = (row && row.steps && row.steps.length > 0) ? (row.steps[0]) ? row.steps[0].stepType : null : null;
            
            if((stepType == JobStepType.Put) || (stepType == JobStepType.Task) || (stepType == JobStepType.Shift)){
                return <div className={'text-center'}>-</div>
            } else {
                return (row && row.netBinWeight) ? row.netBinWeight : ''
            }
        };
        this.netBinWeightExportCSVTemplate = (row) => {
            let stepType = (row && row.steps && row.steps.length > 0) ? (row.steps[0]) ? row.steps[0].stepType : null : null;
            
            if((stepType == JobStepType.Put) || (stepType == JobStepType.Task) || (stepType == JobStepType.Shift)){
                return <div className={'text-center'}>-</div>
            } else {
                return (row && row.netBinWeight) ? row.netBinWeight : ''
            }
        };

        this.billableBinWeightTemplate = (id, row, col, obj, index) => {
            let stepType = (row && row.steps && row.steps.length > 0) ? (row.steps[0]) ? row.steps[0].stepType : null : null;
            
            let kgRegex = RegExp(/^(?:\d{1,5})?$/);
            let tRegex = RegExp(/^(?:\d{1,2}(?:\.\d{1,3})?)?$/);

            let regex = (this.dispatchUser.defaultUoM == ReportUOM.kg) ? kgRegex : tRegex;
            let val = (row && row[col.name]) ? row[col.name].toString() : '';
            let isInvalid = false;
            try {
                isInvalid = (val.match(regex) == null);
            }catch(e){}

            if((stepType == JobStepType.Put) || (stepType == JobStepType.Task) || (stepType == JobStepType.Shift)){
                return <div className={'text-center'}>-</div>
            } else {
                return <Form.Control
                    id={this.pageName + '_input_' + id}
                    as={'input'}
                    type={'number'}
                    style={iputStyle}
    
                    // isInvalid={isInvalid}
                    disabled={false}
                    readOnly={((row.binWeight !== '') ? false : true) || Utils.isRejectedOrCancelledOrFailedOrDeleted(row.statusName)}
                    placeholder={''}
    
                    value={(row && row[col.name]) ? row[col.name] : '0'}
                    onBlur={(e) => {
                        row[col.name] = e.target.value;
                    }}
                    onChange={(e) => {
                        if(this.refDataTableScrollSync && this.refDataTableScrollSync.current){
                            this.scrollLeftPosition = this.refDataTableScrollSync.current.scrollLeft;
                        }
    
                        // let regex = (this.dispatchUser.defaultUoM == ReportUOM.kg) ? kgRegex : tRegex;
                        // if(e.target.value.match(regex) !== null){
                            let value = e.target.value;
        
                            let rows = this.state.rows;
                            if(rows && rows.length > 0 && rows[index]){
                                rows[index][col.name] = value;
                            }
                            this.setState({
                                rows: rows
                            });

                            if(this.props.onChanged){
                                this.props.onChanged();
                            }
                        // }
                    }}
                />
            }
        };
        this.billableBinWeightExportTemplate = (row) => {
            let stepType = (row && row.steps && row.steps.length > 0) ? (row.steps[0]) ? row.steps[0].stepType : null : null;
            
            if((stepType == JobStepType.Put) || (stepType == JobStepType.Task) || (stepType == JobStepType.Shift)){
                return <div className={'text-center'}>-</div>
            } else {
                return (row && row.billableBinWeight) ? row.billableBinWeight : ''
            }
        };
        this.billableBinWeightExportCSVTemplate = (row) => {
            let stepType = (row && row.steps && row.steps.length > 0) ? (row.steps[0]) ? row.steps[0].stepType : null : null;
            
            if((stepType == JobStepType.Put) || (stepType == JobStepType.Task) || (stepType == JobStepType.Shift)){
                return <div className={'text-center'}>-</div>
            } else {
                return (row && row.billableBinWeight) ? row.billableBinWeight : ''
            }
        };
        
        this.nonBillableBinWeightTemplate = (id, row, col, obj, index) => {
            let stepType = (row && row.steps && row.steps.length > 0) ? (row.steps[0]) ? row.steps[0].stepType : null : null;
            
            let kgRegex = RegExp(/^(?:\d{1,5})?$/);
            let tRegex = RegExp(/^(?:\d{1,2}(?:\.\d{1,3})?)?$/);

            let regex = (this.dispatchUser.defaultUoM == ReportUOM.kg) ? kgRegex : tRegex;
            let val = (row && row[col.name]) ? row[col.name].toString() : '';
            let isInvalid = false;
            try {
                isInvalid = (val.match(regex) == null);
            }catch(e){}

            if((stepType == JobStepType.Put) || (stepType == JobStepType.Task) || (stepType == JobStepType.Shift)){
                return <div className={'text-center'}>-</div>
            } else {
                return <Form.Control
                    id={this.pageName + '_input_' + id}
                    as={'input'}
                    type={'number'}
                    style={iputStyle}
    
                    isInvalid={isInvalid}
                    disabled={false}
                    readOnly={((row.binWeight !== '') ? false : true) || Utils.isRejectedOrCancelledOrFailedOrDeleted(row.statusName)}
                    placeholder={''}
    
                    value={(row && row[col.name]) ? row[col.name] : '0'}
                    onBlur={(e) => {
                        row[col.name] = e.target.value;
                    }}
                    onChange={(e) => {
                        if(this.refDataTableScrollSync && this.refDataTableScrollSync.current){
                            this.scrollLeftPosition = this.refDataTableScrollSync.current.scrollLeft;
                        }
                        
                        let regex = (this.dispatchUser.defaultUoM == ReportUOM.kg) ? kgRegex : tRegex;
                        if(e.target.value.match(regex) !== null){
                            let value = e.target.value;
                            
                            let netBinWeight = Utils.parseFloat((row && row['netBinWeight']) ? row['netBinWeight'] : '0');
                            let nonBillableBinWeight = Utils.parseFloat(value);
                            let billableWeight = netBinWeight - nonBillableBinWeight;
        
                            let rows = this.state.rows;
                            if(rows && rows.length > 0 && rows[index]){
                                rows[index][col.name] = value;
                                rows[index]['billableBinWeight'] = billableWeight.toFixed((this.dispatchUser.defaultUoM === ReportUOM.kg) ? 0 : 3);
                            }
                            this.setState({
                                rows: rows
                            });

                            if(this.props.onChanged){
                                this.props.onChanged();
                            }
                        }
                    }}
                />
            }
        };
        this.nonBillableBinWeightExportTemplate = (row) => {
            let stepType = (row && row.steps && row.steps.length > 0) ? (row.steps[0]) ? row.steps[0].stepType : null : null;
            
            if((stepType == JobStepType.Put) || (stepType == JobStepType.Task) || (stepType == JobStepType.Shift)){
                return <div className={'text-center'}>-</div>
            } else {
                return (row && row.nonBillableBinWeight) ? row.nonBillableBinWeight : ''
            }
        };
        this.nonBillableBinWeightExportCSVTemplate = (row) => {
            let stepType = (row && row.steps && row.steps.length > 0) ? (row.steps[0]) ? row.steps[0].stepType : null : null;
            
            if((stepType == JobStepType.Put) || (stepType == JobStepType.Task) || (stepType == JobStepType.Shift)){
                return <div className={'text-center'}>-</div>
            } else {
                return (row && row.nonBillableBinWeight) ? row.nonBillableBinWeight : ''
            }
        };

        this.weightChitRemarksTemplate = (id, row, col, obj, index) => {
            let stepType = (row && row.steps && row.steps.length > 0) ? (row.steps[0]) ? row.steps[0].stepType : null : null;
            
            if((stepType == JobStepType.Put) || (stepType == JobStepType.Task) || (stepType == JobStepType.Shift)){
                return <div className={'text-center'}>-</div>
            } else {
                return <Form.Control
                    id={this.pageName + '_input_' + id}
                    as={'input'}
                    style={iputStyle}
    
                    disabled={false}
                    readOnly={Utils.isRejectedOrCancelledOrFailedOrDeleted(row.statusName)}
                    placeholder={''}
    
                    defaultValue={(row && row[col.name]) ? row[col.name] : ''}
                    onBlur={(e) => {
                        row[col.name] = e.target.value;
                    }}
                    onChange={(e) => {
                        if(this.props.onChanged){
                            this.props.onChanged();
                        }
                    }}
                />
            }
        };
        this.weightChitRemarksExportTemplate = (row) => {
            let stepType = (row && row.steps && row.steps.length > 0) ? (row.steps[0]) ? row.steps[0].stepType : null : null;
            
            if((stepType == JobStepType.Put) || (stepType == JobStepType.Task) || (stepType == JobStepType.Shift)){
                return <div className={'text-center'}>-</div>
            } else {
                return (row && row.weightChitRemarks) ? row.weightChitRemarks : ''
            }
        };
        this.weightChitRemarksExportCSVTemplate = (row) => {
            let stepType = (row && row.steps && row.steps.length > 0) ? (row.steps[0]) ? row.steps[0].stepType : null : null;
            
            if((stepType == JobStepType.Put) || (stepType == JobStepType.Task) || (stepType == JobStepType.Shift)){
                return <div className={'text-center'}>-</div>
            } else {
                return (row && row.weightChitRemarks) ? row.weightChitRemarks : ''
            }
        };

        this.binWeighByTemplate = (id, row, col, obj, index) => {
            let stepType = (row && row.steps && row.steps.length > 0) ? (row.steps[0]) ? row.steps[0].stepType : null : null;
            
            if((stepType == JobStepType.Put) || (stepType == JobStepType.Task) || (stepType == JobStepType.Shift)){
                return <div className={'text-center'}>-</div>
            } else {
                return <Form.Control
                    id={this.pageName + '_input_' + id}
                    as={'input'}
                    style={iputStyle}
    
                    disabled={false}
                    readOnly={Utils.isRejectedOrCancelledOrFailedOrDeleted(row.statusName)}
                    placeholder={''}
    
                    defaultValue={(row && row[col.name]) ? row[col.name] : ''}
                    onBlur={(e) => {
                        row[col.name] = e.target.value;
                    }}
                    onChange={(e) => {
                        if(this.props.onChanged){
                            this.props.onChanged();
                        }
                    }}
                />
            }
        };
        this.binWeighByExportTemplate = (row) => {
            let stepType = (row && row.steps && row.steps.length > 0) ? (row.steps[0]) ? row.steps[0].stepType : null : null;
            
            if((stepType == JobStepType.Put) || (stepType == JobStepType.Task) || (stepType == JobStepType.Shift)){
                return <div className={'text-center'}>-</div>
            } else {
                return (row && row.binWeighBy) ? row.binWeighBy : ''
            }
        };
        this.binWeighByExportCSVTemplate = (row) => {
            let stepType = (row && row.steps && row.steps.length > 0) ? (row.steps[0]) ? row.steps[0].stepType : null : null;
            
            if((stepType == JobStepType.Put) || (stepType == JobStepType.Task) || (stepType == JobStepType.Shift)){
                return <div className={'text-center'}>-</div>
            } else {
                return (row && row.binWeighBy) ? row.binWeighBy : ''
            }
        };
        
        this.weightChitDateTemplate = (id, row, col, obj, index) => {
            let stepType = (row && row.steps && row.steps.length > 0) ? (row.steps[0]) ? row.steps[0].stepType : null : null;
            
            if((stepType == JobStepType.Put) || (stepType == JobStepType.Task) || (stepType == JobStepType.Shift)){
                return <div className={'text-center'}>-</div>
            } else {
                return <Form.Control
                    as = {StyledDatePicker}
                    id={this.pageName + '_date_input_' + id}
                    style={iputStyle}
    
                    placeholder={''}
                    value={(row && row[col.name]) ? new Date(row[col.name]) : null}
                    icon={<></>}
                    cleanable={false}
                    readOnly={Utils.isRejectedOrCancelledOrFailedOrDeleted(row.statusName)}
                    isDefault={true}
                    onChange={(value) => {
                        row[col.name] = value;
                        
                        if(this.props.onChanged){
                            this.props.onChanged();
                        }
                    }}
                />
            }
        };
        this.weightChitDateExportTemplate = (row) => {
            let stepType = (row && row.steps && row.steps.length > 0) ? (row.steps[0]) ? row.steps[0].stepType : null : null;
            
            if((stepType == JobStepType.Put) || (stepType == JobStepType.Task) || (stepType == JobStepType.Shift)){
                return <div className={'text-center'}>-</div>
            } else {
                return ((row && row.weightChitDateTime) ? moment(row.weightChitDateTime).format(Utils.getDefaultDateTimeFormat()) : '')
            }
        };
        this.weightChitDateExportCSVTemplate = (row) => {
            let stepType = (row && row.steps && row.steps.length > 0) ? (row.steps[0]) ? row.steps[0].stepType : null : null;
            
            if((stepType == JobStepType.Put) || (stepType == JobStepType.Task) || (stepType == JobStepType.Shift)){
                return <div className={'text-center'}>-</div>
            } else {
                return (row && row.weightChitDateTime) ? moment(row.weightChitDateTime).format(Utils.getDefaultDateTimeFormat()) : ''
            }
        };

        this.weightChitTimeTemplate = (id, row, col, obj, index) => {
            let stepType = (row && row.steps && row.steps.length > 0) ? (row.steps[0]) ? row.steps[0].stepType : null : null;
            
            if((stepType == JobStepType.Put) || (stepType == JobStepType.Task) || (stepType == JobStepType.Shift)){
                return <div className={'text-center'}>-</div>
            } else {
                return <Form.Control
                    as = {StyledTimePicker}
                    id={this.pageName + '_time_input_' + id}
                    style={iputStyle}
    
                    placeholder={''}
                    value={(row && row[col.name]) ? new Date(row[col.name]) : null}
                    cleanable={false}
                    readOnly={Utils.isRejectedOrCancelledOrFailedOrDeleted(row.statusName)}
                    onChange={(value) => {
                        if(this.refDataTableScrollSync && this.refDataTableScrollSync.current){
                            this.scrollLeftPosition = this.refDataTableScrollSync.current.scrollLeft;
                        }
    
                        let dateTime = Utils.convertDateTimeByDate(((row && row['weightChitDateTime']) ? new Date(row['weightChitDateTime']) : new Date()), value);
                        
                        let rows = this.state.rows;
                        if(rows && rows.length > 0 && rows[index]){
                            rows[index][col.name] = dateTime;
                        }
                        this.setState({
                            rows: rows
                        });
                        
                        if(this.props.onChanged){
                            this.props.onChanged();
                        }
                    }}
                />
            }
        };
        this.weightChitTimeExportTemplate = (row) => {
            let stepType = (row && row.steps && row.steps.length > 0) ? (row.steps[0]) ? row.steps[0].stepType : null : null;
            
            if((stepType == JobStepType.Put) || (stepType == JobStepType.Task) || (stepType == JobStepType.Shift)){
                return <div className={'text-center'}>-</div>
            } else {
                return ((row && row.weightChitDateTime) ? moment(row.weightChitDateTime).format(Utils.getDefaultDateTimeFormat()) : '')
            }
        };
        this.weightChitTimeExportCSVTemplate = (row) => {
            let stepType = (row && row.steps && row.steps.length > 0) ? (row.steps[0]) ? row.steps[0].stepType : null : null;
            
            if((stepType == JobStepType.Put) || (stepType == JobStepType.Task) || (stepType == JobStepType.Shift)){
                return <div className={'text-center'}>-</div>
            } else {
                return (row && row.weightChitDateTime) ? moment(row.weightChitDateTime).format(Utils.getDefaultDateTimeFormat()) : ''
            }
        };
        
        this.weightChitOutDateTimeTemplate = (id, row, col, obj, index) => {
            let stepType = (row && row.steps && row.steps.length > 0) ? (row.steps[0]) ? row.steps[0].stepType : null : null;
            
            if((stepType == JobStepType.Put) || (stepType == JobStepType.Task) || (stepType == JobStepType.Shift)){
                return <div className={'text-center'}>-</div>
            } else {
                return <Form.Control
                    as = {StyledTimePicker}
                    id={this.pageName + '_time_input_' + id}
                    style={iputStyle}
    
                    placeholder={''}
                    value={(row && row[col.name]) ? new Date(row[col.name]) : null}
                    cleanable={false}
                    readOnly={Utils.isRejectedOrCancelledOrFailedOrDeleted(row.statusName)}
                    onChange={(value) => {
                        if(this.refDataTableScrollSync && this.refDataTableScrollSync.current){
                            this.scrollLeftPosition = this.refDataTableScrollSync.current.scrollLeft;
                        }
    
                        let dateTime = Utils.convertDateTimeByDate(((row && row['weightChitOutDateTime']) ? new Date(row['weightChitOutDateTime']) : new Date()), value);
                        
                        let rows = this.state.rows;
                        if(rows && rows.length > 0 && rows[index]){
                            rows[index][col.name] = dateTime;
                        }
                        this.setState({
                            rows: rows
                        });
                        
                        if(this.props.onChanged){
                            this.props.onChanged();
                        }
                    }}
                />
            }
        };
        this.weightChitOutDateTimeExportTemplate = (row) => {
            let stepType = (row && row.steps && row.steps.length > 0) ? (row.steps[0]) ? row.steps[0].stepType : null : null;
            
            if((stepType == JobStepType.Put) || (stepType == JobStepType.Task) || (stepType == JobStepType.Shift)){
                return <div className={'text-center'}>-</div>
            } else {
                return ((row && row.weightChitOutDateTime) ? moment(row.weightChitOutDateTime).format(Utils.getDefaultDateTimeFormat()) : '')
            }
        };
        this.weightChitOutDateTimeExportCSVTemplate = (row) => {
            let stepType = (row && row.steps && row.steps.length > 0) ? (row.steps[0]) ? row.steps[0].stepType : null : null;
            
            if((stepType == JobStepType.Put) || (stepType == JobStepType.Task) || (stepType == JobStepType.Shift)){
                return <div className={'text-center'}>-</div>
            } else {
                return (row && row.weightChitOutDateTime) ? moment(row.weightChitOutDateTime).format(Utils.getDefaultDateTimeFormat()) : ''
            }
        };


        let columns = [
            {
                id: this.pageIdField,
                name: this.pageIdField,
                label: "ID",
                responsiveLabel: "ID",
                visible: false,
            },
            { 
                id: 'customerName',
                filterKeyParam: 'customerName',
                name: 'customerName',
                nestedOrderBy: 'customer',  
                label: 'CUSTOMER',
                responsiveLabel: 'CUSTOMER',
                width: '200px',
                orderable: true,
                template: this.customerNameTemplate,
                exportTemplate: this.customerNameExportTemplate,
                exportCSVTemplate: this.customerNameExportCSVTemplate,
            },
            { 
                id: 'location', 
                filterKeyParam: 'location', 
                name: 'location', 
                label: 'SITE ADDRESS',
                responsiveLabel: 'SITE ADDRESS',
                width: '250px',
                orderable: false,
            },
            { 
                id: 'jobNumber', 
                filterKeyParam: 'jobNumber', 
                name: 'jobNumber', 
                label: 'DO NUMBER',
                responsiveLabel: 'DO NUMBER',
                width: '150px',
                orderable: true,
            },
            { 
                id: 'jobAttemptCompletedDate', 
                filterKeyParam: 'jobAttemptCompletedDate', 
                name: 'jobAttemptCompletedDate', 
                label: 'COMPLETED DATE/TIME',
                responsiveLabel: 'COMPLETED DATE/TIME',
                width: '150px',
                orderable: false,
                template: this.jobDateCompleteTemplate,
                exportTemplate: this.jobDateCompleteExportTemplate,
                exportCSVTemplate: this.jobDateCompleteExportCSVTemplate,
            },
            { 
                id: 'wasteTypeIn', 
                filterKeyParam: 'wasteTypeIn', 
                name: 'wasteTypeIn', 
                label: 'WASTE IN',
                responsiveLabel: 'WASTE IN',
                width: '150px',
                orderable: true,
                template: this.WasteInItemTemplate,
                exportTemplate: this.WasteInItemExportTemplate,
                exportCSVTemplate: this.WasteInItemExportCSVTemplate,
            },
            
            { 
                id: 'weightChitTicketNumber', 
                filterKeyParam: 'weightChitTicketNumber', 
                name: 'weightChitTicketNumber', 
                label: 'TICKET NUMBER',
                responsiveLabel: 'TICKET NUMBER',
                width: '150px',
                orderable: true,
    
                template: this.weightChitTicketNumberTemplate,
                exportTemplate: this.weightChitTicketNumberExportTemplate,
                exportCSVTemplate: this.weightChitTicketNumberExportCSVTemplate,
            },
            { 
                id: 'binWeight', 
                filterKeyParam: 'binWeight', 
                name: 'binWeight', 
                label: 'GROSS WEIGHT' + ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? ' (kg)' : ' (tons)'),
                responsiveLabel: 'GROSS WEIGHT' + ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? ' (kg)' : ' (tons)'),
                width: '150px',
                orderable: true,
    
                template: this.binWeightTemplate,
                exportTemplate: this.binWeightExportTemplate,
                exportCSVTemplate: this.binWeightExportCSVTemplate,
            },
            { 
                id: 'tareBinWeight', 
                filterKeyParam: 'tareBinWeight', 
                name: 'tareBinWeight', 
                label: 'TARE WEIGHT' + ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? ' (kg)' : ' (tons)'),
                responsiveLabel: 'TARE WEIGHT' + ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? ' (kg)' : ' (tons)'),
                width: '150px',
                orderable: true,
    
                template: this.tareBinWeightTemplate,
                exportTemplate: this.tareBinWeightExportTemplate,
                exportCSVTemplate: this.tareBinWeightExportCSVTemplate,
            },
            { 
                id: 'netBinWeight', 
                filterKeyParam: 'netBinWeight', 
                name: 'netBinWeight', 
                label: 'NETT WEIGHT' + ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? ' (kg)' : ' (tons)'),
                responsiveLabel: 'NETT WEIGHT' + ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? ' (kg)' : ' (tons)'),
                width: '150px',
                orderable: true,
    
                template: this.netBinWeightTemplate,
                exportTemplate: this.netBinWeightExportTemplate,
                exportCSVTemplate: this.netBinWeightExportCSVTemplate,
            },
            { 
                id: 'nonBillableBinWeight', 
                filterKeyParam: 'nonBillableBinWeight', 
                name: 'nonBillableBinWeight', 
                label: 'NON-BILLABLE WEIGHT' + ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? ' (kg)' : ' (tons)'),
                width: '150px',
                responsiveLabel: 'NON-BILLABLE WEIGHT' + ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? ' (kg)' : ' (tons)'),
                orderable: true,

                template: this.nonBillableBinWeightTemplate,
                exportTemplate: this.nonBillableBinWeightExportTemplate,
                exportCSVTemplate: this.nonBillableBinWeightExportCSVTemplate,
            },
            { 
                id: 'billableBinWeight', 
                filterKeyParam: 'billableBinWeight', 
                name: 'billableBinWeight', 
                label: 'BILLABLE WEIGHT' + ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? ' (kg)' : ' (tons)'),
                responsiveLabel: 'BILLABLE WEIGHT' + ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? ' (kg)' : ' (tons)'),
                width: '150px',
                orderable: true,
    
                template: this.billableBinWeightTemplate,
                exportTemplate: this.billableBinWeightExportTemplate,
                exportCSVTemplate: this.billableBinWeightExportCSVTemplate,
            },
            { 
                id: 'weightChitRemarks', 
                filterKeyParam: 'weightChitRemarks', 
                name: 'weightChitRemarks', 
                label: 'WEIGHT REMARKS',
                responsiveLabel: 'WEIGHT REMARKS',
                width: '150px',
                orderable: true,
    
                template: this.weightChitRemarksTemplate,
                exportTemplate: this.weightChitRemarksExportTemplate,
                exportCSVTemplate: this.weightChitRemarksExportCSVTemplate,
            },
            { 
                id: 'binWeighBy', 
                filterKeyParam: 'binWeighBy', 
                name: 'binWeighBy', 
                label: 'WEIGHT BY',
                responsiveLabel: 'WEIGHT BY',
                width: '150px',
                orderable: true,
    
                template: this.binWeighByTemplate,
                exportTemplate: this.binWeighByExportTemplate,
                exportCSVTemplate: this.binWeighByExportCSVTemplate,
            },
            { 
              id: 'weightChitDate', 
              filterKeyParam: 'weightChitDateTime', 
              name: 'weightChitDateTime', 
              label: 'DATE',
              responsiveLabel: 'DATE',
              width: '150px',
              orderable: true,
    
              template: this.weightChitDateTemplate,
              exportTemplate: this.weightChitDateExportTemplate,
              exportCSVTemplate: this.weightChitDateExportCSVTemplate,
            },
            { 
              id: 'weightChitTime', 
              filterKeyParam: 'weightChitDateTime', 
              name: 'weightChitDateTime', 
              label: 'TIME',
              responsiveLabel: 'TIME',
              width: '150px',
              orderable: true,
    
              template: this.weightChitTimeTemplate,
              exportTemplate: this.weightChitTimeExportTemplate,
              exportCSVTemplate: this.weightChitTimeExportCSVTemplate,
            },
            { 
              id: 'weightChitOutDateTime', 
              filterKeyParam: 'weightChitOutDateTime', 
              name: 'weightChitOutDateTime', 
              label: 'OUT TIME',
              responsiveLabel: 'OUT TIME',
              width: '150px',
              orderable: true,
    
              template: this.weightChitOutDateTimeTemplate,
              exportTemplate: this.weightChitOutDateTimeExportTemplate,
              exportCSVTemplate: this.weightChitOutDateTimeExportCSVTemplate,
            },
        ];

        let initColumns = Array.from(columns);
        columns = Array.from(initColumns);

        let columnVisibility = [
          false,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
        ];
        let initColumnVisibility = Array.from(columnVisibility);
        columnVisibility = Array.from(initColumnVisibility);


		this.state = {
            isLoading: true,

            // fromDate: moment().startOf('year').format(Utils.getAPIDateFormat()),
            // toDate: moment().endOf('year').format(Utils.getAPIDateFormat()),

            search: '',
            sortColumn: 'jobDate',
            sortDir: 'desc',
            isIncludeInactive: ((props.isIncludeInactive === true) || (props.isIncludeInactive === false)) ? props.isIncludeInactive : false,

            selected: [],
            columnVisibility: columnVisibility,
            initColumnVisibility: initColumnVisibility,
            columns: columns,
            initColumns: initColumns,
            rows: [],
            currentPage: 1,
            totalRows: 0,
            rowsPerPageSelected: 50,
            rowsPerPageOptions: [50, 100, 300],
		}
    }


    async componentDidMount() {
        await this.props.action.dateSection.dateGetFilters({
          auth: this.props.auth,
          settingsType: this.props.settingsType,
          pageName: 'jobs-page',
        });

        this.getFilterInLocalStorage(() => {
            this.callReadApi();
        });
    }

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }
	
	
    setFilterInLocalStorage = (isCookies = true) => {
        // if (this.props.settingsType) {
        //     let { token, userCookieSettings } = this.props.auth;

        //     this.setState({
        //         isCookies: isCookies,
        //     });

        //     let data = {
        //         name: this.pageName,
        //         type: this.props.settingsType,
        //     };

        //     let valueLocalStorage = {
        //         [PageCookieSettingType.currentPage]: this.state.currentPage,
        //         [PageCookieSettingType.fromDate]: this.state.fromDate,
        //         [PageCookieSettingType.toDate]: this.state.toDate,
        //     };
        //     window.localStorage.setItem(this.pageName + '_' + this.props.settingsType, JSON.stringify(valueLocalStorage));

        //     let value = {
        //         [PageCookieSettingType.isCookies]: isCookies,
        //         [PageCookieSettingType.sortColumn]: this.state.sortColumn,
        //         [PageCookieSettingType.sortDir]: this.state.sortDir,
        //         // [PageCookieSettingType.currentPage]: this.state.currentPage,
        //         [PageCookieSettingType.rowsPerPageSelected]: this.state.rowsPerPageSelected,
        //         // [PageCookieSettingType.fromDate]: this.state.fromDate,
        //         // [PageCookieSettingType.toDate]: this.state.toDate,
        //         // [PageCookieSettingType.columnVisibility]: this.state.columnVisibility,
        //         // [PageCookieSettingType.columns]: this.state.columns,
        //         [PageCookieSettingType.isIncludeInactive]: this.state.isIncludeInactive,
        //     };
        //     data['value'] = JSON.stringify(value);

        //     Utils.saveUserCookieSettings(data, token, (settings) => {
        //         if (settings) {
        //             let settingsArr = (userCookieSettings && userCookieSettings.length > 0) ? userCookieSettings : [];
        //             let settingsIndex = Utils.getUserCookieSettingsIndex(settingsArr, this.props.settingsType);
        //             if (settingsIndex !== -1 && settingsArr[settingsIndex]) {
        //                 settingsArr[settingsIndex] = settings;
        //             } else {
        //                 settingsArr.push(settings);
        //             }

        //             this.props.saveUserCookieSettings(settingsArr)
        //         }
        //     });

        //     // apiUtil.deleteUserCookieSettings({ name: this.pageName, type: this.props.settingsType }, token);
        //     // apiUtil.deleteAllUserCookieSettings(token);
        // }
    };

    getFilterInLocalStorage = (callback = null) => {
        // let settingsItem = null;

        // if (this.props.settingsType) {
        //     const { userCookieSettings } = this.props.auth;

        //     settingsItem = Utils.getUserCookieSettings(userCookieSettings, this.props.settingsType, this.pageName);
        // }

        // let valueLocalStorageStr = window.localStorage.getItem(this.pageName + '_' + this.props.settingsType);
        // let valueLocalStorage = JSON.parse(valueLocalStorageStr);
        // let currentPageValue = valueLocalStorage && valueLocalStorage[PageCookieSettingType.currentPage] && valueLocalStorage[PageCookieSettingType.currentPage] !== "" ? valueLocalStorage[PageCookieSettingType.currentPage] : this.state.currentPage;
        // let fromDateValue = (valueLocalStorage && valueLocalStorage[PageCookieSettingType.fromDate] && valueLocalStorage[PageCookieSettingType.fromDate] !== '') ? valueLocalStorage[PageCookieSettingType.fromDate] : this.state.fromDate;
        // let toDateValue = (valueLocalStorage && valueLocalStorage[PageCookieSettingType.toDate] && valueLocalStorage[PageCookieSettingType.toDate] !== '') ? valueLocalStorage[PageCookieSettingType.toDate] : this.state.toDate;

        // let isCookiesValue = settingsItem && settingsItem[PageCookieSettingType.isCookies] && settingsItem[PageCookieSettingType.isCookies] !== "" ? settingsItem[PageCookieSettingType.isCookies] : this.state.isCookies;
        // let sortColumnValue = settingsItem && settingsItem[PageCookieSettingType.sortColumn] && settingsItem[PageCookieSettingType.sortColumn] !== "" ? settingsItem[PageCookieSettingType.sortColumn] : this.state.sortColumn;
        // let sortDirValue = settingsItem && settingsItem[PageCookieSettingType.sortDir] && settingsItem[PageCookieSettingType.sortDir] !== "" ? settingsItem[PageCookieSettingType.sortDir] : this.state.sortDir;
        // let rowsPerPageSelectedValue = settingsItem && settingsItem[PageCookieSettingType.rowsPerPageSelected] && settingsItem[PageCookieSettingType.rowsPerPageSelected] !== "" ? settingsItem[PageCookieSettingType.rowsPerPageSelected] : this.state.rowsPerPageSelected;
        // // let columnVisibilityValue = (settingsItem && settingsItem[PageCookieSettingType.columnVisibility] && settingsItem[PageCookieSettingType.columnVisibility] !== '') ? settingsItem[PageCookieSettingType.columnVisibility] : this.state.columnVisibility;
        // // let columnsValue = (settingsItem && settingsItem[PageCookieSettingType.columns] && settingsItem[PageCookieSettingType.columns] !== '') ? settingsItem[PageCookieSettingType.columns] : this.state.columns;

        // // let columns = Utils.setTemplateFunctionsToColumns(this.state.columns, columnsValue);
        // let dateRange = [
        //     moment(fromDateValue).toDate(),
        //     moment(toDateValue).toDate(),
        // ];

        // this.setState({
        //     isCookies: isCookiesValue,
        //     sortColumn: sortColumnValue,
        //     sortDir: sortDirValue,
        //     currentPage: currentPageValue,
        //     rowsPerPageSelected: rowsPerPageSelectedValue,
        //     fromDate: fromDateValue,
        //     toDate: toDateValue,
        //     // columnVisibility: columnVisibilityValue,
        //     // columns: columns,
        //     dateRange: dateRange,
        // }, () => {
        //     if (callback) {
        //         callback();
        //     }
        // });

        if (callback) {
            callback();
        }
    };

    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            this.pageName + '_list',
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let rows = (data && data.data && data.data.length > 0) ? data.data : [];
                let totalRows = (data && data.total) ? data.total : 0;

                this.setState({
                    rows: rows,
                    totalRows: totalRows,
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if (callback) {
                        callback();
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '_job-list-download-excel', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();
                    
                    if(callback){
                        callback(data);
                    }
                    
                    this.props.action.signalRslice.disconnect();
                });
            }
        );

        

        Utils.reduxProps(nextProps,
            this.pageName + '_merge-weight-chit-doc', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(data);
                    }

                    this.props.action.signalRslice.disconnect();
                });
            }
      ) ;
    }


    /* API */
    callReadApi = (callback = null) => {
        let fromDate = moment(this.props.dateSection.fromDate).format(Utils.getAPIDateFormat());
        let toDate = moment(this.props.dateSection.toDate).format(Utils.getAPIDateFormat());

        this.setState({
            isLoading: true,
        });

        let jobIds = (this.props.items) ? this.props.items : '';

        let data = {
            ids: jobIds,
            currentPage: this.state.currentPage,
            pageSize: this.state.rowsPerPageSelected,
            searchQuery: this.state.search,
            fromDate: fromDate,
            toDate: toDate,
            sortColumn: this.state.sortColumn,
            sortDir: this.state.sortDir,
            isIncludeInactive: ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : false,
        };

        this.props.dispatchApiCallPost(data, this.pageName + '_list', this.pageAPI + '/get-list', null, null, callback);
    }

    callDownloadExcelApi = async (callback = null) => {
        await this.props.action.signalRslice.connect({
            callback: (percent) => {
            this.props.action.signalRslice.onPercentage(percent);
            },
            callbackInit: (hubId) => {}
        });

        let jobIds = null;
        if(this.tableRef && this.tableRef.current){
            let selected = this.tableRef.current.getSelected();
            if(selected && selected.length > 0){
                let ids = selected.join(',');
                jobIds = ids.replace(/,+(?=,|$)/g, '');
            }
        }

        if(jobIds){
            this.setState({
                isLoading: true,
            });
    
            let fromDate = moment(this.props.dateSection.fromDate).format(Utils.getAPIDateFormat());
            let toDate = moment(this.props.dateSection.toDate).format(Utils.getAPIDateFormat());
    
            let data = {
                ids: jobIds,
                currentPage: 1,
                pageSize: Utils.getMaxPageSize(),
                searchQuery: this.state.search,
                fromDate: fromDate,
                toDate: toDate,
                sortColumn: this.state.sortColumn,
                sortDir: this.state.sortDir,
                isIncludeInactive: ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : false,
                hubId: this.props.signalRslice.hubId,
            };
    
            this.props.dispatchApiCallGet(data, this.pageName + '_job-list-download-excel', 'job/download-weight-chit-excel', null, callback, null);
        } else {
            Utils.toast('Please select jobs', 'error');
        }
    }

    callMergeJobDocApi = async (callback = null) => {
        await this.props.action.signalRslice.connect({
            callback: (percent) => {
            this.props.action.signalRslice.onPercentage(percent);
            },
            callbackInit: (hubId) => {}
        });

        let jobIds = null;
        if(this.tableRef && this.tableRef.current){
            let selected = this.tableRef.current.getSelected();
            if(selected && selected.length > 0){
                let ids = selected.join(',');
                jobIds = ids.replace(/,+(?=,|$)/g, '');
            }
        }

        if(jobIds){
            this.setState({
                isLoading: true,
            });
    
            let data = {
              jobIds: jobIds,
              sortColumn: this.state.sortColumn,
              sortDir: this.state.sortDir,
              hubId: this.props.signalRslice.hubId,
            };
        
            this.props.dispatchApiCallPost(data, this.pageName + '_merge-weight-chit-doc', 'job/merge-weight-chit-doc', null, callback, () => {});
        } else {
            Utils.toast('Please select jobs', 'error');
        }
    }
    /* END API */

    
	/* FUNCTIONS */
	downloadExcel = () => {
        this.callDownloadExcelApi((data) => {
            let type = 'application/vnd.ms-excel';
            Utils.saveByteArray(this.pageCaption, data, type);

            this.setState({
                isLoading: false,
            });
        });
	}
	downloadPDF = () => {
        this.callMergeJobDocApi((data) => {
            if(data){
                Utils.saveByteArray(data.fileName, data.fileBytes);
      
                this.setState({
                    isLoading: false,
                });
            } else {
                this.setState({
                    isLoading: false,
                });
            }
        });
	}
	columnSelection = () => {
		return <IconButtonDropdown 
            ref={this.refDropdownActionMenu}
            title={'Column Selection'}
            placement={'bottom'}
            disabled={this.state.isLoading}
            icon={<i className={'material-icons'}>view_headline</i>}
        >
            <DataTableActionMenu 
                style={{
                    fontWeight: 'normal',
                    fontSize: '12px',
                }}
                pageName={this.pageName}
                columns={this.state.columns}
                selected={this.state.rowsPerPageSelected}
                selectItems={this.state.rowsPerPageOptions}
                columnVisibility={this.state.columnVisibility}
                onChangeColumnVisibility={(index, value) => {
                    let colVis = this.state.columnVisibility;
                    colVis[index] = value;

                    this.setState({
                        columnVisibility: colVis
                    });

                    this.setFilterInLocalStorage();
                }}
                includeInactiveText={'Include Deleted'}
                canInactive={true}
                isCheckedIncludeInactive={this.state.isIncludeInactive === true || this.state.isIncludeInactive === false ? this.state.isIncludeInactive : false}
                onSelectChangeAjax={(val) => {
                    this.setState({
                        currentPage: 1,
                        rowsPerPageSelected: val
                    }, () => {
                        if(this.props.onTypesReset){
                            this.props.onTypesReset();
                        }

                        this.setFilterInLocalStorage();
                        this.callReadApi();
                    });
                }}
                onChangeIncludeInactive={(val) => {
                    this.setState({
                        isIncludeInactive: val,
                        currentPage: 1,
                    }, () => {
                        if(this.props.onTypesReset){
                            this.props.onTypesReset();
                        }
                        
                        this.setFilterInLocalStorage();
                        this.callReadApi();
                    });
                }}
                isCookies={this.state.isCookies}
                onResetColumns={() => {
                    this.setState({
                        columns: Array.from(this.state.initColumns),
                        columnVisibility: Array.from(this.state.initColumnVisibility),
                        sortColumn: this.state.sortColumnInit,
                        sortDir: this.state.sortDirInit,
                    }, () => {
                    this.setFilterInLocalStorage(false);
                        this.callReadApi();
                        if(this.refDropdownActionMenu && this.refDropdownActionMenu.current){
                            this.refDropdownActionMenu.current.hide();
                        }
                    });
                }}
                onShowMore={(isShowedMore) => {
                    this.setState({
                        pageName: this.pageName,
                    });
                }}
            />
        </IconButtonDropdown>
	}
	/* FUNCTIONS */
	
	
    tableSection = () => {
        return <DataTableScrollSync 
            ref={this.refDataTableScrollSync}
            className={"table-sync-v4 no-border"}
            bottom={'71px'}
            onLoad={(r) => { 
                if(this.refDataTableScrollSync && this.refDataTableScrollSync.current){
                    this.refDataTableScrollSync.current.scrollLeft = this.scrollLeftPosition;
                }
            }}
        >
            {this.setDataTable()}
        </DataTableScrollSync>
    }

    setDataTable = () => {
        return <DataTable
            ref={this.tableRef}

            className={"table-style-v4"}
            classNameWrapper={"table-v4 no-hover no-row-currsor no-selection"}

            useWindowScroll={false}
            // getScrollParent={this.refTableWrapper.current}

            pageName={this.pageName}
            rowId={this.pageIdField}
            caption={this.pageCaption}

            noMarginBottom={false}
            verticalAlign={'middle'}

            stickyHeader={false}
            isHeader={true}
            resizable={true}
            isDragableHeaderColumns={true}
            isDragable={false}
            isBorder={false}
            isPagination={false}
            isCheckbox={true}
            disableRowClick={true}

            disabledResizeColumns={[0]}
            columnVisibility={this.state.columnVisibility}

            isLoading={this.props.isLoading}
            rowsPerPageSelected={this.state.rowsPerPageSelected}
            rowsPerPageOptions={this.state.rowsPerPageOptions}

            search={this.state.search}
            rows={this.state.rows}
            columns={this.state.columns}

            onCheckAll={(obj) => {
                this.scrollLeftPosition = 0;

                if(this.props.onChecked){
                    this.props.onChecked(obj.hasChecked())
                }
            }}
            onCheckRow={(obj) => {
                this.scrollLeftPosition = 0;
                
                if(this.props.onChecked){
                    this.props.onChecked(obj.hasChecked())
                }
            }}

            onSortEnd={(columns, columnVisibility) => {
                this.setState({
                    columns: columns,
                    columnVisibility: columnVisibility,
                }, () => {
                    this.setFilterInLocalStorage();
                });
            }}
            onOrder={(name, sort) => {
                this.setState({
                    sortColumn: name,
                    sortDir: sort,
                }, () => {
                    this.setFilterInLocalStorage();
                    this.callReadApi(() => {
                        this.setState({
                            totalRows: 0,
                            rows: [],
                            isLoading: false,
                        });
                    });
                });
            }}
            onResize={(columns) => {
                if(columns){
                  this.setState({
                    columns: columns
                  });
                }
                this.setFilterInLocalStorage();
            }}
            onLoadFinish={(obj) => {
                let selected = [];
                let rows = this.state.rows;
                if(rows && rows.length > 0){
                    selected = rows.map(x => x[this.pageIdField]);
                }

                if(selected && selected.length > 0){
                    obj.setSelected(selected, () => {
                        if(this.props.onChecked){
                            this.props.onChecked(obj.hasChecked())
                        }
                    });
                }
            }}
        />
    }

    paginationSection = () => {
        return <DataTableAjaxPagination
            page={this.state.currentPage}
            selected={this.state.rowsPerPageSelected}
            count={this.state.totalRows}
            options={this.state.rowsPerPageOptions}
            fixed={false}
            onPageChange={(page) => {
                this.setState({
                    currentPage: page,
                }, () => {
                    this.setFilterInLocalStorage();
                    this.callReadApi(() => {
                        this.setState({
                            totalRows: 0,
                            rows: [],
                            isLoading: false
                        });
                    });
                });
            }}
            onOptionsChange={(option) => {
                this.setState({
                    currentPage: 1,
                    rowsPerPageSelected: option,
                }, () => {
                    this.setFilterInLocalStorage();
                    this.callReadApi(() => {
                        this.setState({
                            totalRows: 0,
                            rows: [],
                            isLoading: false
                        });
                    });
                });
            }}
        />
    }


    render() {
        return <div style={{ overflow: 'auto' }}>
        <div ref={(ref) => this.refTableWrapper = ref}>{this.tableSection()}</div>
        <div className={'p-5 fixed-bottom-pagination-70 bg-white'}>{this.paginationSection()}</div>
      </div>
    }
}


const mapStateToProps = state => {
  return {
    ...state,
    ...Utils.mapStateToProps(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  dispatchApiCallGet: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallGet(p1, p2, p3, p4, p5, p6)),
  dispatchApiCallPost: (p1, p2, p3, p4, p5, p6, p7) => dispatch(dispatchApiCallPost(p1, p2, p3, p4, p5, p6, p7)),
  saveUserCookieSettings: (payload) => dispatch(saveUserCookieSettings(payload)),
  clear: (payload) => dispatch(clear(payload)),
  action: {
    signalRslice: {
      connect: (payload) => dispatch(signalRslice.connect(payload)),
      disconnect: (payload) => dispatch(signalRslice.disconnect(payload)),
      onPercentage: (payload) => dispatch(signalRslice.onPercentage(payload)),
    },
    dateSection: {
      dateGetFilters: (payload) => dispatch(dateSection.dateGetFilters(payload)),
      dateSetFilters: (payload) => dispatch(dateSection.dateSetFilters(payload)),
    },
  }
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PopupWeighChit);