import React, { Component } from 'react';
import queryString from 'query-string';

import apiUtil from "../../../../api/apiUtil.jsx";
import ReportApi from "../../../../api/OLD/Dispatch/Report.jsx";


class ReportIframe extends Component {
  
	constructor(props){
		super(props);

        let q = queryString.parse(window.location.search);
        let title = (q.title) ? q.title : '';

        let html = window.localStorage.getItem('print_' + title);
        localStorage.removeItem('print_' + title);

        this.state = {
          url: null,
          title: title,
          html: html,
        }
	}


    componentDidMount() {
      apiUtil.getTemplateFromFile('/templates/HtmlTemplate.html').then(result => {
        let html1 = result.data.replace("[[title]]", this.state.title);
        let html = html1.replace("[[content]]", this.state.html);
        
        this.callGeneratePDFApi(this.state.title, html, (data) => {
          let byteArray = apiUtil.getUint8Array(data);
          let blob = new Blob([byteArray], { type: "application/pdf" });
          let url = window.URL.createObjectURL(blob);

          this.setState({
            url: url,
          });
        });
      });
    }
    

    callGeneratePDFApi = (fileName = "", html = "", callback = null) => {
      const { token } = this.context;
  
      ReportApi.generatePDF(
        {
          fileName: fileName,
          htmlContent: html,
        },
        token
      ).then((result) => {
        apiUtil.parseResult(result,
          (data) => {
            if(callback !== null) {
              callback(data);
            }
          },
          (error, type) => {
            if(callback !== null) {
              callback(null);
            }
          }
        );
      });
    };


	render() {
    if(this.state.url){
      return <iframe 
        id={'iframeID'} 
        title={this.state.title} 
        src={this.state.url} 
        style={{
          width: '100%',
          height: '100%',
          position: 'fixed',
        }}
        onLoad={(e) => {
          setTimeout(function() {
            document.getElementById('iframeID').contentWindow.print();
          }, 2000);
        }}
      />
    } else {
      return <div>
        <h6 style={{ paddingTop: '50px', textAlign: 'center' }}>Please Wait...</h6>
        <div style={{ paddingTop: '30px', textAlign: 'center' }}>
          <i style={{ fontSize: '70px' }} className="fa fa-print"></i>
        </div>
      </div>
    }
	}
}

export default ReportIframe;
