import React, { ReactNode } from 'react';
import { useFormik } from 'formik';
import _ from 'lodash';

import Switch from "react-switch";

import {
  Row,
  Col,
  Form,
  InputGroup,
  Button,
  Dropdown,
  Modal,
} from 'react-bootstrap-v5';

import Offcanvas from "react-bootstrap/Offcanvas";
import 'bootstrap/dist/css/bootstrap.min.css';

import styled from 'styled-components';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { Loader } from 'rsuite';

import CustomTooltip from '../../../components/Tooltip/Tooltip';
import SmartInputDropdown from '../../../components/dropdown/smartInputDropdown/SmartInputDropdown';
import makeCRUD from "../../../components/dropdown/smartInputDropdown/makeCRUD";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../../../setup'
import slice, { FormikContext, fields, initialValues, formSchema, prepareForm, prepareData, initContactPerson } from './slice';

import Utils from '../../../utils/utils';
import { XeroPaymentTermTypeItems } from '../../../utils/enums';
import { StyledDropdownButton } from "../../../utils/styles";

// ----------------------------------------------------------------------

const SmartDropdown = makeCRUD(SmartInputDropdown);

const StyledCancel = styled.i`
    cursor: pointer;
    color: #DFDFDF;

    &:hover {
        color: #FF0090;
    }
`;


const FormComponent = (props: any) => {
    const dispatch = useDispatch();
    const { isLoading, show, id, details, defaultBillingProfile, defaultTermItem } = useSelector((state: RootState) => state.customerMiniSlice);
    
    const [more, setMore] = React.useState(false);

    React.useEffect(() => {
        if(show){
            setMore(false);

            if(!(id && id > 0)){
                let params = {
                    currentPage: 1,
                    pageSize: Utils.getMaxPageSize(),
                    searchQuery: '',
                    isActive: true,
                };
                dispatch(slice.callReadAccountBillingProfileApi(params, (state: boolean, data: any) => {}));
            }
        }
    }, [show]);

    React.useEffect(() => {
        if(id && id > 0){
            dispatch(slice.callDetailsApi(id, (state: boolean, data: any) => {}));
        }
    }, [id]);
        
    React.useEffect(() => {
        dispatch(slice.setLoading(true));
        let form = prepareForm(details, initialValues);
        setValues(form);
        dispatch(slice.setLoading(false));
    }, [details]);
    
    React.useEffect(() => {
        dispatch(slice.setLoading(true));
        if(defaultBillingProfile){
            let quickBookApp = (defaultBillingProfile && defaultBillingProfile.quickBookApp) ? defaultBillingProfile.quickBookApp : null;
            let isConnectQuickbook = (defaultBillingProfile && defaultBillingProfile.isConnectQuickbook) ? defaultBillingProfile.isConnectQuickbook : false;
            let isConnectXero = (defaultBillingProfile && defaultBillingProfile.isConnectXero) ? defaultBillingProfile.isConnectXero : false;
            let quickBookAppId = (quickBookApp && quickBookApp.quickBookAppId) ? quickBookApp.quickBookAppId : 0;
            let quickBookDefaultTermId = (quickBookApp && quickBookApp.quickBookDefaultTermId) ? quickBookApp.quickBookDefaultTermId.toString() : '';
            
            setFieldValue('accountBillingProfileId', defaultBillingProfile.accountBillingProfileId);
            setFieldValue('accountBillingProfileName', defaultBillingProfile.billingProfileName);
            setFieldValue('quickBookAppId', quickBookAppId);
            setFieldValue('isConnectQuickbook', isConnectQuickbook);
            setFieldValue('isConnectXero', isConnectXero);

            if(isConnectXero){
                let xeroApp = (defaultBillingProfile && defaultBillingProfile.xeroApp) ? defaultBillingProfile.xeroApp : null;
                let xeroPaymentTermType = (xeroApp && xeroApp.xeroPaymentTermType) ? xeroApp.xeroPaymentTermType.toString() : '0';
                let xeroPaymentTerm = (xeroApp && xeroApp.xeroPaymentTerm) ? xeroApp.xeroPaymentTerm.toString() : '0';
        
                setFieldValue('xeroPaymentTerm', xeroPaymentTerm);
                setFieldValue('xeroPaymentTermType', xeroPaymentTermType);
        
            } else if(isConnectQuickbook){
                
                let params = {
                    quickBookAppId: quickBookAppId,
                };
                dispatch(slice.callReadQuickbookCreditTermsApi(params, quickBookDefaultTermId, (state: boolean, data: any) => {}));
            }
        }
        dispatch(slice.setLoading(false));
    }, [defaultBillingProfile]);
    
    React.useEffect(() => {
        dispatch(slice.setLoading(true));
        if(defaultTermItem){
            let paymentTermsId = (defaultTermItem && defaultTermItem.id) ? defaultTermItem.id : null;
            let paymentTermsName = (defaultTermItem && defaultTermItem.name && defaultTermItem.name != '') ? defaultTermItem.name : '';
            
            setFieldValue('paymentTermsId', paymentTermsId);
            setFieldValue('paymentTermsName', paymentTermsName);
        }
        dispatch(slice.setLoading(false));
    }, [defaultTermItem]);


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: formSchema(null),
        onSubmit: values => {
            onSubmit(values, (data: any) => {
                if(props && props.onSave){
                    props.onSave(data);
                }
                
                onCancel();
            });
        },
    });
    const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = formik;


    const onSubmit = (values: any, callback: (data: any) => void) => {
        let data = prepareData(values);
        if(id > 0){
            dispatch(slice.callUpdateApi(data, (state: boolean, data: any) => {
                if(state){
                    callback(data);
                }
            }));
        } else {
            dispatch(slice.callCreateApi(data, (state: boolean, data: any) => {
                if(state){
                    callback(data);
                }
            }));
        }
    }
    const onCancel = () => {
        let form = prepareForm(null, initialValues);
        setValues(form);
        dispatch(slice.setValues(form));
        dispatch(slice.setShow({ show: false, id: null }));
        dispatch(slice.resetSlice());
    }

    
    const setHeader = () => {
        return <Offcanvas.Header closeButton className='border-bottom'>
            <Offcanvas.Title className='mb-0'>{(id && id > 0) ? 'Edit' : 'Create'} Customer</Offcanvas.Title>
        </Offcanvas.Header>
    }
    const setFooter = () => {
        return <Modal.Footer className='border-top'>
            <Row className='p-2'>
                <Col xs={true} className='text-end'>
                    <Button
                        className='me-5'
                        variant={'light'}
                        size={'sm'}
                        disabled={isLoading}
                        onClick={(e) => {
                            onCancel();
                        }}
                    >Cancel</Button>
                    <Button
                        variant={'primary'}
                        size={'sm'}
                        disabled={isLoading}
                        onClick={() => {
                            handleSubmit()
                        }}
                    >Save</Button>
                </Col>
            </Row>
        </Modal.Footer>
    }
    const setForm = () => {
        return <Row className={'g-2'}>
            <Col xs={12}>
                <Form.Group>
                    <Form.Label>{fields.customerName.label}</Form.Label>
                    <Form.Control
                        type={'text'}
                        autoComplete='off'
                        tabIndex={1}
                        placeholder={fields.customerName.placeholder}
                        disabled={false}
                        value={values.customerName}
                        onChange={(e) => {
                            setFieldValue('customerName', e.target.value);
                            setFieldValue('billingName', e.target.value);
                        }}
                        isInvalid={!!(errors && errors.customerName)}
                    />
                    <Form.Control.Feedback type="invalid">{errors && errors.customerName as ReactNode}</Form.Control.Feedback>
                </Form.Group>
            </Col>

            <Col xs={12}>
                <Form.Group>
                    <Form.Label>{fields.billingName.label}</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={fields.billingName.placeholder}
                        disabled={false}
                        value={values.billingName}
                        onChange={(e) => {
                            setFieldValue('billingName', e.target.value);
                        }}
                        isInvalid={!!(errors && errors.billingName)}
                    />
                    <Form.Control.Feedback type="invalid">{errors && errors.billingName as ReactNode}</Form.Control.Feedback>
                </Form.Group>
            </Col>

            <Col xs={12}>
                <Form.Group>
                    <Form.Label>{fields.accountBillingProfileId.label}</Form.Label>
                    <SmartDropdown
                        isInvalid={!!errors.accountBillingProfileId}
                        errorText={errors.accountBillingProfileId}

                        componentTitle={'AccountBillingProfile'}
                        componentApi={'AccountBillingProfile'}
                        componentId={'accountBillingProfileId'}
                        componentName={'billingProfileName'}

                        renderReadAPI={(data: any, variables: any) => {
                            let options = (variables && variables.length > 0) ? variables : [];

                            if(data && data.data && data.data.length > 0) {
                                for (var i = 0; i < data.data.length; i++) {
                                    let item = data.data[i];
                                    let value = item['accountBillingProfileId'];
                                    let title = item['billingProfileName'];
                                    let arrItem: any = {
                                        value: value,
                                        title: title,
                                        item: item,
                                    };
                        
                                    options = Utils.addToArray(options, value, arrItem);
                                }
                            }

                            return {
                                options: options,
                                hasMore: false,
                            }
                        }}

                        placeholder={fields.accountBillingProfileId.placeholder}
                        value={values.accountBillingProfileId}
                        label={values.accountBillingProfileName}

                        disabled={false}
                        showDefault={false}
                        showFooter={false}
                        isInfiniteScroll={false}

                        onChange={async (value: any, item: any, i: any) => {
                            await setFieldValue('accountBillingProfileId', value);
                            await setFieldValue('accountBillingProfileName', item.title);
                            await setFieldValue('paymentTermsId', null);
                            await setFieldValue('paymentTermsName', '');
                            await setFieldValue('xeroPaymentTermType', '0');
                            await setFieldValue('xeroPaymentTerm', '');
                            // await setFieldValue('accountBillingProfileItem', item);

                            let quickBookApp = (item && item.item && item.item.quickBookApp) ? item.item.quickBookApp : null;
                            let isConnectQuickbook = (item && item.item && item.item.isConnectQuickbook) ? item.item.isConnectQuickbook : false;
                            let isConnectXero = (item && item.item && item.item.isConnectXero) ? item.item.isConnectXero : false;
                            let quickBookAppId = (quickBookApp && quickBookApp.quickBookAppId) ? quickBookApp.quickBookAppId : 0;

                            await setFieldValue('quickBookAppId', quickBookAppId);
                            await setFieldValue('isConnectQuickbook', isConnectQuickbook);
                            await setFieldValue('isConnectXero', isConnectXero);

                            if(isConnectXero){
                                let xeroApp = (item && item.item && item.item.xeroApp) ? item.item.xeroApp : null;
                                let xeroPaymentTermType = (xeroApp && xeroApp.xeroPaymentTermType) ? xeroApp.xeroPaymentTermType.toString() : '0';
                                let xeroPaymentTerm = (xeroApp && xeroApp.xeroPaymentTerm) ? xeroApp.xeroPaymentTerm.toString() : '0';
                                await setFieldValue('xeroPaymentTermType', xeroPaymentTermType);
                                await setFieldValue('xeroPaymentTerm', xeroPaymentTerm);
                            }

                            // if(isConnectQuickbook){
                            //     this.setLoading(true);
                            //     this.callQuickbookReadCreditTermsApi(quickBookAppId, async (arr) => {
                            //         let paymentTermsName = '';
                            //         let quickBookDefaultTermId = (quickBookApp && quickBookApp.quickBookDefaultTermId) ? quickBookApp.quickBookDefaultTermId.toString() : '';

                            //         if(arr && arr.length > 0){
                            //             let qbDefTermItem = arr.find(x => x.value == quickBookDefaultTermId);
                            //             if(qbDefTermItem){
                            //                 paymentTermsName = qbDefTermItem.title;
                            //             }
                            //         }
                            //         await this.refForm.current.setFieldValue('paymentTermsId', quickBookDefaultTermId);
                            //         await this.refForm.current.setFieldValue('paymentTermsName', paymentTermsName);

                            //         this.setLoading(false);
                            //     });
                            // }
                        }}
                        onClear={async () => {
                            await setFieldValue('accountBillingProfileId', null);
                            await setFieldValue('accountBillingProfileName', '');
                            // await setFieldValue('accountBillingProfileItem', null);
                            
                            await setFieldValue('paymentTermsId', null);
                            await setFieldValue('paymentTermsName', '');
                            await setFieldValue('xeroPaymentTermType', '0');
                            await setFieldValue('xeroPaymentTerm', '');
                            await setFieldValue('quickBookAppId', 0);
                            await setFieldValue('isConnectQuickbook', false);
                            await setFieldValue('isConnectXero', false);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors && errors.accountBillingProfileId as ReactNode}</Form.Control.Feedback>
                </Form.Group>
            </Col>

            {values.isConnectQuickbook && <Col xs={12}>
                <Form.Group>
                    <Form.Label>{fields.paymentTermsId.label}</Form.Label>
                    <SmartDropdown
                        isInvalid={!!errors.paymentTermsId}
                        errorText={errors.paymentTermsId}

                        componentTitle={'paymentTerms'}
                        componentApi={'quickbookapp/term'}
                        componentId={'id'}
                        componentName={'name'}
                        componentData={{
                            quickBookAppId: values.quickBookAppId,
                        }}
                        
                        pageSize={Utils.getMaxPageSize()}

                        placeholder={fields.paymentTermsId.placeholder}
                        value={values.paymentTermsId}
                        label={values.paymentTermsName}

                        disabled={false}
                        showDefault={false}
                        showSearch={false}
                        showFooter={false}
                        isInfiniteScroll={false}

                        renderReadAPI={(data: any, variables: any) => {
                            let options = (variables && variables.length > 0) ? variables : [];

                            if(data && data.length > 0) {
                                for (var i = 0; i < data.length; i++) {
                                    let item = data[i];
                                    let value = item['id'];
                                    let title = item['name'];
                                    let arrItem: any = {
                                        value: value,
                                        title: title,
                                        item: item,
                                    };
                        
                                    options = Utils.addToArrayIfNotExist(options, value, arrItem);
                                }
                            }

                            return {
                                options: options,
                                hasMore: false,
                            }
                        }}

                        onChange={async (value: any, item: any, i: any) => {
                            await setFieldValue('paymentTermsId', value);
                            await setFieldValue('paymentTermsName', item.title);
                            await setFieldValue('xeroPaymentTerm', '');
                            // await setFieldValue('paymentTermsItem', item);
                        }}
                        onClear={async () => {
                            await setFieldValue('paymentTermsId', null);
                            await setFieldValue('paymentTermsName', '');
                            await setFieldValue('xeroPaymentTerm', '');
                            // await setFieldValue('paymentTermsItem', null);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors && errors.paymentTermsId as ReactNode}</Form.Control.Feedback>
                </Form.Group>
            </Col>}

            {values.isConnectXero && <Col xs={12}>
                <Form.Group>
                    <Form.Label>{fields.xeroPaymentTerm.label}</Form.Label>
                    <InputGroup>
                        <Form.Control
                            type="number"
                            id={fields.xeroPaymentTerm.id}
                            placeholder={fields.xeroPaymentTerm.placeholder}
                            value={values.xeroPaymentTerm}
                            onChange={async (e) => {
                                await setFieldValue('xeroPaymentTerm', e.target.value);
                                await setFieldValue('paymentTermsId', null);
                                await setFieldValue('paymentTermsName', '');
                            }}
                            isInvalid={!!errors.xeroPaymentTerm}
                        />
                        <StyledDropdownButton
                            title={Utils.getXeroPaymentTermTypeName(values.xeroPaymentTermType)}
                            variant={'secondary'}
                            menuAlign={'right'}
                        >
                            {XeroPaymentTermTypeItems.map((item, i) => {
                                return <Dropdown.Item
                                    className={'py-2'}
                                    key={item.value + '_' + item.title + '_' + i}
                                    active={(item.value === values.xeroPaymentTermType)}
                                    onClick={async () => {
                                        await setFieldValue('xeroPaymentTermType', item.value);
                                    }}
                                >{item.title}</Dropdown.Item>
                            })}
                        </StyledDropdownButton>
                        <Form.Control.Feedback type="invalid">{errors.xeroPaymentTerm}</Form.Control.Feedback>
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">{errors && errors.xeroPaymentTerm as ReactNode}</Form.Control.Feedback>
                </Form.Group>
            </Col>}

            <Col xs={12} className='text-end mt-5'>
                <a href={'/'}
                    className={'link-primary'}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        
                        setMore(!more);
                    }}
                >{more ? 'Show less' : 'Show more'}</a>
            </Col>
        </Row>
    }
    const setOtherForm = () => {
        return <Row className={'g-2'}>
            <Col xs={12}><hr className={'custom-manage-hr mt-0 mb-1'} /></Col>
            
            <Col xs={12}>
                <Form.Group>
                    <Form.Label>{fields.billingAddress.label}</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={4}
                        id={fields.billingAddress.id}
                        placeholder={fields.billingAddress.placeholder}
                        value={values.billingAddress}
                        onChange={async (e) => {
                            Utils.limitRowsBillingAddress(e.target.value, 4, async (value: any) => {
                                await setFieldValue('billingAddress', value);
                            });
                        }}
                        isInvalid={!!errors.billingAddress}
                    />
                    <Form.Control.Feedback type="invalid">{errors && errors.billingAddress as ReactNode}</Form.Control.Feedback>
                </Form.Group>
            </Col>

            <Col xs={12}>
                <Form.Group>
                    <Form.Label>{fields.contactNumber.label}</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={fields.contactNumber.placeholder}
                        disabled={false}
                        value={values.contactNumber}
                        onChange={(e) => {
                            setFieldValue('contactNumber', e.target.value);
                        }}
                        isInvalid={!!(errors && errors.contactNumber)}
                    />
                    <Form.Control.Feedback type="invalid">{errors && errors.contactNumber as ReactNode}</Form.Control.Feedback>
                </Form.Group>
            </Col>
            
            <Col xs={12}>
                <Form.Group>
                    <Form.Label>{fields.note.label} <small>{fields.note.labelDetails}</small></Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={5}
                        id={fields.note.id}
                        placeholder={fields.note.placeholder}
                        value={values.note}
                        onChange={async (e) => {
                            Utils.limitRows(e.target.value, 5, async (value: any) => {
                                await setFieldValue('note', value);
                            });
                        }}
                        isInvalid={!!errors.note}
                    />
                    <Form.Control.Feedback type="invalid">{errors && errors.note as ReactNode}</Form.Control.Feedback>
                </Form.Group>
            </Col>

            <Col xs={12}>
                <Form.Group>
                    <Form.Label>
                        {fields.emailAddresses.label} 
                        <CustomTooltip placement={'top'} title={fields.emailAddresses.info}>
                            <InfoOutlinedIcon style={{ color: '#DFDFDF', width: '20px', height: '20px', left: '10px', position: 'relative' }} />
                        </CustomTooltip>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        id={fields.emailAddresses.id}
                        placeholder={fields.emailAddresses.placeholder}
                        value={values.emailAddresses.join(',')}
                        onChange={async (e) => {
                            await setFieldValue('emailAddresses', [e.target.value]);
                        }}
                        isInvalid={!!errors.emailAddresses}
                    />
                    <Form.Control.Feedback type="invalid">{errors && errors.emailAddresses as ReactNode}</Form.Control.Feedback>
                </Form.Group>
            </Col>

            <Col xs={12}>
                <Form.Group>
                    <Form.Label>{fields.customerCategoryId.label}</Form.Label>
                    <SmartDropdown
                        isInvalid={!!errors.customerCategoryId}
                        errorText={errors.customerCategoryId}

                        componentTitle={'customerCategory'}
                        componentApi={'customerCategory'}
                        componentId={'customerCategoryId'}
                        componentName={'customerCategoryName'}

                        placeholder={fields.customerCategoryId.placeholder}
                        value={values.customerCategoryId}
                        label={values.customerCategoryName}

                        disabled={false}
                        showDefault={false}
                        isInfiniteScroll={false}

                        onChange={async (value: any, item: any, i: any) => {
                            await setFieldValue('customerCategoryId', value);
                            await setFieldValue('customerCategoryName', item.title);
                            // await setFieldValue('customerCategoryItem', item);
                        }}
                        onClear={async () => {
                            await setFieldValue('customerCategoryId', null);
                            await setFieldValue('customerCategoryName', '');
                            // await setFieldValue('customerCategoryItem', null);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors && errors.customerCategoryId as ReactNode}</Form.Control.Feedback>
                </Form.Group>
            </Col>

            <Col xs={12}>
                <Form.Group>
                    <Form.Label>{fields.businessRegNo.label}</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={fields.businessRegNo.placeholder}
                        disabled={false}
                        value={values.businessRegNo}
                        onChange={(e) => {
                            setFieldValue('businessRegNo', e.target.value);
                        }}
                        isInvalid={!!(errors && errors.businessRegNo)}
                    />
                    <Form.Control.Feedback type="invalid">{errors && errors.businessRegNo as ReactNode}</Form.Control.Feedback>
                </Form.Group>
            </Col>

            <Col xs={12}>
                <Form.Group className='d-flex align-items-center'>
                    <Form.Label className='mb-0'>{fields.isRequirePaymentCollection.label}</Form.Label>
                    <Switch
                        className='ps-5'
                        id={fields.isRequirePaymentCollection.id}

                        checked={values.isRequirePaymentCollection}
                        onChange={async (checked: any) => {
                            await setFieldValue('isRequirePaymentCollection', checked);
                        }}

                        onColor="#F5F5F5"
                        onHandleColor="#185CFF"
                        
                        offColor="#F5F5F5"
                        offHandleColor="#7B7B7B"

                        handleDiameter={28}
                        width={50}
                        height={13}

                        uncheckedIcon={false}
                        checkedIcon={false}
                    />
                </Form.Group>
            </Col>

            <Col xs={12}><hr className={'custom-manage-hr mt-4 mb-4'} /></Col>

            <Col xs={12}>
                {
                    (values.contactPersons && values.contactPersons.length > 0)
                    ?
                    values.contactPersons.map((item: any, i: number) => {
                        return <Row key={i} className={'g-2'}>
                            <Col xs={12}>
                                <Form.Group>
                                    <Form.Label>{fields.firstName.label}</Form.Label>
                                    <Form.Control
                                        type={'text'}
                                        placeholder={fields.firstName.placeholder}
                                        disabled={false}
                                        value={item.firstName}
                                        onChange={(e) => {
                                            setFieldValue('contactPersons.' + i + '.firstName', e.target.value);
                                        }}
                                        isInvalid={!!(errors && errors.contactPersons && errors.contactPersons[i] && errors.contactPersons[i].firstName)}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors && errors.contactPersons && errors.contactPersons[i] && errors.contactPersons[i].firstName as ReactNode}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col xs={12}>
                                <Form.Group>
                                    <Form.Label>{fields.emailAddress.label}</Form.Label>
                                    <Form.Control
                                        type={'text'}
                                        placeholder={fields.emailAddress.placeholder}
                                        disabled={false}
                                        value={item.emailAddress}
                                        onChange={(e) => {
                                            setFieldValue('contactPersons.' + i + '.emailAddress', e.target.value);
                                        }}
                                        isInvalid={!!(errors && errors.contactPersons && errors.contactPersons[i] && errors.contactPersons[i].emailAddress)}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors && errors.contactPersons && errors.contactPersons[i] && errors.contactPersons[i].emailAddress as ReactNode}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col xs={12}>
                                <Form.Group>
                                    <Form.Label>{fields.workPhone.label}</Form.Label>
                                    <Form.Control
                                        type={'text'}
                                        placeholder={fields.workPhone.placeholder}
                                        disabled={false}
                                        value={item.workPhone}
                                        onChange={(e) => {
                                            setFieldValue('contactPersons.' + i + '.workPhone', e.target.value);
                                        }}
                                        isInvalid={!!(errors && errors.contactPersons && errors.contactPersons[i] && errors.contactPersons[i].workPhone)}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors && errors.contactPersons && errors.contactPersons[i] && errors.contactPersons[i].workPhone as ReactNode}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col xs={12}>
                                <Form.Group>
                                    <Form.Label>{fields.mobilePhone.label}</Form.Label>
                                    <Form.Control
                                        type={'text'}
                                        placeholder={fields.mobilePhone.placeholder}
                                        disabled={false}
                                        value={item.mobilePhone}
                                        onChange={(e) => {
                                            setFieldValue('contactPersons.' + i + '.mobilePhone', e.target.value);
                                        }}
                                        isInvalid={!!(errors && errors.contactPersons && errors.contactPersons[i] && errors.contactPersons[i].mobilePhone)}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors && errors.contactPersons && errors.contactPersons[i] && errors.contactPersons[i].mobilePhone as ReactNode}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col xs={12}>
                                <Form.Group>
                                    <Form.Label>{fields.department.label}</Form.Label>
                                    <Form.Control
                                        type={'text'}
                                        placeholder={fields.department.placeholder}
                                        disabled={false}
                                        value={item.department}
                                        onChange={(e) => {
                                            setFieldValue('contactPersons.' + i + '.department', e.target.value);
                                        }}
                                        isInvalid={!!(errors && errors.contactPersons && errors.contactPersons[i] && errors.contactPersons[i].department)}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors && errors.contactPersons && errors.contactPersons[i] && errors.contactPersons[i].department as ReactNode}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            {(values.contactPersons.length > 1) && <Col xs={12} className='text-center'>
                                <StyledCancel
                                    className={'material-icons'}
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        let contactPersons = _.cloneDeep(values.contactPersons);
                                        contactPersons.splice(i, 1);
                                        setFieldValue('contactPersons', contactPersons);
                                    }}
                                >cancel</StyledCancel>
                            </Col>}
                            
                            {(i < (values.contactPersons.length-1)) && <Col xs={12}><hr className={'custom-manage-hr mt-0 mb-5'} /></Col>}
                        </Row>
                    })
                    :
                    <div>No Contacts!</div>
                }

                <div className={'mt-2'}>
                    <a href={'/'} 
                        className={'link-primary'}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            
                            if(values.contactPersons.length < 5){
                                let hasEmpty = Utils.hasEmptyRows(values.contactPersons, (item: any, i: any) => {
                                    return Utils.isEmptyRow(item, [ 'firstName', 'emailAddress', 'workPhone', 'mobilePhone', 'department' ]);
                                });
                                
                                if(!hasEmpty){
                                    let contactPersons = _.cloneDeep(values.contactPersons);
                                    contactPersons.push(initContactPerson);
                                    setFieldValue('contactPersons', contactPersons);
                                } else {
                                    Utils.toast('You must fill in the required fields', 'error');
                                }
                            } else {
                                Utils.toast('You can add a maximum of 5 contacts', 'warning');
                            }
                        }}
                    >+ Add Contact Person</a>
                </div>
            </Col>
        </Row>
    }


    return <Offcanvas
        backdrop={'static'}
        enforceFocus={false}
        scroll={false}
        placement={'end'}
        show={show}
        onHide={() => {
            onCancel();
        }}
        style={{ width: '600px' }}
    >
        <FormikContext.Provider value={formik}>
            {setHeader()}
            <Offcanvas.Body>
                <Row className={'g-2'}>
                    <Col xs={12}>{setForm()}</Col>
                    {(!more && values.customerName != '') && <Col xs={12}>
                        <Form.Control.Feedback type="invalid" className='d-block'>{errors && errors.billingName as ReactNode}</Form.Control.Feedback>
                    </Col>}
                    {more && <Col xs={12}>{setOtherForm()}</Col>}
                </Row>
                {isLoading && <Loader center backdrop content={'loading'} />}
            </Offcanvas.Body>
            {setFooter()}
        </FormikContext.Provider>
    </Offcanvas>
}

export default FormComponent;
