import React, { Component } from 'react';

import { 
    Box,
    InputLabel 
} from '@material-ui/core';


class ManageLabel extends Component {
  
	constructor(props){
		super(props);

        let label = props.label ? props.label : "";
        let size = props.size ? props.size : "";
        let weight = props.weight ? props.weight : "";
        let fontStyle = props.fontStyle ? props.fontStyle : "";
        let style = props.style ? props.style : null;
        let textAlign = props.textAlign ? props.textAlign : "";
        let className = props.className ? props.className : "";
        let labelComponent = props.labelComponent ? props.labelComponent : null;
        let disabled = ((this.props.disabled === true || this.props.disabled === false) ? this.props.disabled : false);
        let error = ((this.props.error === true || this.props.error === false) ? this.props.error : false);
        let required = ((this.props.required === true || this.props.required === false) ? this.props.required : false);
        let background = ((this.props.background === true || this.props.background === false) ? this.props.background : false);
        
		this.state = {
            label: label,
            size: size,
            weight: weight,
            fontStyle: fontStyle,
            style: style,
            textAlign: textAlign,
            labelComponent: labelComponent,
            disabled: disabled,
            required: required,
            error: error,
            background: background,
            className: className
		}
	}
    

    componentWillReceiveProps(nextProps) {
        if (nextProps.label !== this.state.label) {
			this.setState({
				label: nextProps.label
			});
        }
        if (nextProps.disabled !== this.state.disabled) {
			this.setState({
				disabled: nextProps.disabled
			});
        }
        if (nextProps.error !== this.state.error) {
			this.setState({
				error: nextProps.error
			});
		}
    }
    
    
	render() {
        return <Box 
            className={"manage-input-component " + this.state.className + " " + this.state.size + " " + this.state.weight + " " + this.state.fontStyle + " " + this.state.textAlign + " " + (this.state.background ? 'bg' : '')}
        >
            <InputLabel 
                disabled={this.state.disabled}
                error={this.state.error}
                required={this.state.required}
                shrink
                style={this.state.style}
            >
                {this.state.label}{this.state.labelComponent}
            </InputLabel>
		</Box>;
	}
}

export default ManageLabel;
