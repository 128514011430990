import React, { Component } from 'react';
import queryString from 'query-string';

import { 
  Box,
  Grid,
} from '@material-ui/core';

import Utils from "../../../utils/utils";

import { connect } from 'react-redux'
import notifications from './redux/notifications';

import LinkIcon from '../../../../_metronic/assets/img/manage/integrations/link_icon.png';


class TwilioIntegrationForm extends Component {
  
  constructor(props){
    super(props);

    let q = queryString.parse(window.location.search);
    this.accountSid = (q.AccountSid) ? q.AccountSid : '';
    this.id = (q.state) ? q.state : '';

    this.state = {
      
    }
  }

  
  async componentDidMount() {
    await this.props.action.notifications.connect(
      {
        accountBillingProfileId: this.id,
        accountSid: this.accountSid
      }, 
      (data) => {
        setTimeout(() => {
          window.location.href = '/manage/profile/company/profile?id=' + this.id + '&selectedTab=6';
        }, 1000);
      }
    );
  }

  
  render() {
    return <Box className="manage-integrations-page" overflow={"hidden"}>
      <Grid container>
        <Grid item xs={12}>
            <Box pt={15} pb={15}>
                <Box textAlign={"center"}><img src={LinkIcon} alt={"link"} /></Box>
                <Box textAlign={"center"}>Waiting for connections...</Box>
            </Box>
        </Grid>
      </Grid>
    </Box>;
  }
}


const mapStateToProps = state => {
  return {
    ...state,
    ...Utils.mapStateToProps(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  action: {
    notifications: {
      connect: (payload, callback) => dispatch(notifications.connect(payload, callback)),
    },
  }
});


export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(TwilioIntegrationForm);
