import React, { Component } from 'react';

import { 
    Box,
    Icon,
} from '@material-ui/core';

import CustomPaperDialog from '../../OLD/Dialog/CustomPaperDialog.jsx';
import CustomCropperForDialog from '../../OLD/Cropper/CustomCropperForDialog.jsx';


class CustomCropperDialog extends Component {
    constructor(props) {
        super(props);
        
        this.customCropperForDialogRef = React.createRef();

		let isLoading = ((props.isLoading === false) || (props.isLoading === true)) ? props.isLoading : false;
		let isOpenDialog = ((props.isOpenDialog === false) || (props.isOpenDialog === true)) ? props.isOpenDialog : false;
		let dialogItem = (props.dialogItem) ? props.dialogItem : null;
	  
		this.state = {
            isLoading: isLoading,
            isOpenDialog: isOpenDialog,
            dialogItem: dialogItem,

            resolutionWidth: 0,
            resolutionHeight: 0,
		}
    }


	componentWillReceiveProps(nextProps) {
        if (nextProps.isOpenDialog !== this.state.isOpenDialog) {
            let isOpenDialog = ((nextProps.isOpenDialog === false) || (nextProps.isOpenDialog === true)) ? nextProps.isOpenDialog : false;
			this.setState({
				isOpenDialog: isOpenDialog
			});
		}

        if (nextProps.dialogItem !== this.state.dialogItem) {
            let dialogItem = (nextProps.dialogItem) ? nextProps.dialogItem : null;
			this.setState({
				dialogItem: dialogItem
			});
		}
	}
    

	printCropper = () => {
		return <Box>
            <CustomCropperForDialog
                ref={this.customCropperForDialogRef}
                initialImg={(this.state.dialogItem) ? URL.createObjectURL(this.state.dialogItem) : null}
                name={(this.state.dialogItem && this.state.dialogItem.name) ? this.state.dialogItem.name : ''}
                isCircle={false}
                onLoad={() => {
                    this.setState({
                        isLoading: true
                    });
                }}
                onUpload={(blob, name) => {
                    this.setState({
                        dialogItem: null,
                    }, () => {
                        let file = new File([blob], name);

                        if(this.props.onCropFinished){
                            this.props.onCropFinished(file);
                        }
                    });
                }}
                onEnd={() => {
                    this.setState({
                        isLoading: false
                    });
                }}
                onClose={() => {
                    this.setState({
                        dialogItem: null,
                    });
                }}
                onCrop={(width, height) => {
                    this.setState({
                        resolutionWidth: width,
                        resolutionHeight: height,
                    });
                }}
            />
        </Box>
	}
	
    setResolution = () => {
        return <Box>Resolution: {this.state.resolutionWidth.toFixed(0) + 'x' + this.state.resolutionHeight.toFixed(0)}</Box>
    }

	
    render() {
        return <CustomPaperDialog
            className={'max-width-100 no-padding'}
            isLoading={this.state.isLoading}
            open={this.state.isOpenDialog}
            maxWidth={"md"}
            fullWidth={false}
            closeBtnPosition={"in"}
            closeIcon={<Icon component={'i'}>close</Icon>}
            onClose={() => {
                this.setState({
                    dialogItem: null,
                }, () => {
                    if(this.props.onCropFinished){
                        this.props.onCropFinished(null);
                    }
                });
            }}
            onOk={() => {
                this.setState({
                    dialogItem: null,
                }, () => {
                    if(this.customCropperForDialogRef && this.customCropperForDialogRef.current){
                        this.customCropperForDialogRef.current.crop(this.state.resolutionWidth, this.state.resolutionHeight);
                    }
                });
            }}
        >
            {this.printCropper()}
            {/* {this.setResolution()} */}
        </CustomPaperDialog>
    }
}

export default CustomCropperDialog;
