import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';

import { 
    Modal,
    Row,
    Col,
    Button,
    Dropdown,
    Form,
    Spinner,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import Utils from '../../../utils/utils';
// import {  } from '../../../utils/enums';

import FileInputButton from '../../../components/Buttons/fileInputButton/FileInputButton';

import { useDispatch, useSelector } from "react-redux";
import {RootState} from '../../../../setup'
import binCenterImport, { HeaderColumnItem, Steps } from '../redux/binCenterImport';
import fileInputButtonSlice from '../../../components/Buttons/fileInputButton/FileInputButtonSlice';
import realTimeUpdate from '../../../../setup/redux/slices/realTimeUpdate';

import { validationSchema, validation, getColumns, getColumnsFailed, getColumnsSuccess, getData } from './Functions';
import MyTable from '../../jobs/Dialogs/importWeighChit/MyTable';


const StyledModalBody = styled(Modal.Body)`
    padding: 0px;
`;

const StyledButtonFailed = styled(Button)`
    background-color: #F4F5F5 !important;
    color: #444444 !important;

    &.btn.btn-light.active {
        background-color: #fff0f8 !important;
        color: #FF0090 !important;
    }
`;

const StyledButtonSuccess = styled(Button)`
    background-color: #F4F5F5 !important;
    color: #444444 !important;

    &.btn.btn-light.active {
        background-color: #f0f8ff !important;
        color: #185CFF !important;
    }
`;


const ImportPopup = (props: any) => {
    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state);
    const { isLoading, show, step, percent, excludeFirstRow, firstRow, data, failedJobs, successJobs } = useSelector((state: RootState) => state.binCenterImport);
    
    const { isFile, file } = useSelector((state: RootState) => state.fileInputButtonSlice);

    const formikRef = React.useRef<FormikProps<any>>(null);
    const formikRefFailed = React.useRef<FormikProps<any>>(null);
    const formikRefSuccess = React.useRef<FormikProps<any>>(null);

    const tableRef = React.useRef<any>(null);

    
    const setHeaderDropdownCols = () => {
        let myHeaderDropdownColumns: Array<HeaderColumnItem> = [
            {
                value: 1,
                name: 'binTypeName',
                label: 'Bin Type',
                title: 'Bin Type',
                type: 'string',
                successType: 'text',
                successRender: null,
                validation: Yup.string().typeError('This field is required').nullable().required().label('Bin Type'),
                required: false,
                item: null,
                itemField: null,
            },
            {
                value: 2,
                name: 'binNumberName',
                label: 'Bin ID',
                title: 'Bin ID',
                type: 'string',
                successType: 'text',
                successRender: null,
                validation: Yup.string().typeError('This field is required').nullable().required().label('Bin ID'),
                required: false,
                item: null,
                itemField: null,
            },
        ]

        return myHeaderDropdownColumns;
    }

    React.useEffect(() => {
        if((step === Steps.FailedTab) || (step === Steps.SuccessTab)){
            dispatch(binCenterImport.setHeaderDropdownColumns(setHeaderDropdownCols()));
        }
    }, [step]);


    const onRowDelete = (row: any, callback: () => void) => {
        let updatedData = formikRef.current?.values.filter((item: any) => item.id != row.values.id);
        let arr = updatedData.map((item: any, i: number) => ({...item, isLoading: false, isDelete: false }));
        arr = arr.filter((item: any) => (item.id !== undefined) && (item.id !== null));

        formikRef.current?.setValues(arr);
        dispatch(binCenterImport.setData(arr));
        if(!excludeFirstRow){
            let fRow = (arr && arr.length > 0) ? arr[0] : null;
            dispatch(binCenterImport.setFirstRow(fRow));
            dispatch(binCenterImport.setHeaderColumns(fRow));
        }
        callback();

        let state = arr.some((x: any) => x.id);
        if(!state){
            hide();
        }
    }
    const onCancelRowDelete = (callback: () => void) => {
        let arr = formikRef.current?.values.map((item: any, i: number) => ({...item, isLoading: false, isDelete: false }));
        formikRef.current?.setValues(arr);
        dispatch(binCenterImport.setData(arr));
        callback();
    }
    const onRowDeleteFailed = (row: any, callback: () => void) => {
        let updatedData = formikRefFailed.current?.values.filter((item: any) => item.id != row.values.id);
        let arr = updatedData.map((item: any, i: number) => ({...item, isLoading: false, isDelete: false }));
        arr = arr.filter((item: any) => (item.id !== undefined) && (item.id !== null));
        formikRefFailed.current?.setValues(arr);
        dispatch(binCenterImport.setFailedJobs(arr));
        callback();

        let state = arr.some((x: any) => (x.id !== undefined) && (x.id !== null));
        if(!state){
            let successState = successJobs.some((x: any) => (x.id !== undefined) && (x.id !== null));
            if(!successState){
                hide();
            } else {
                dispatch(binCenterImport.nextStep(Steps.SuccessTab));
            }
        }
    }
    const onCancelRowDeleteFailed = (callback: () => void) => {
        let arr = formikRefFailed.current?.values.map((item: any, i: number) => ({...item, isLoading: false, isDelete: false }));
        formikRefFailed.current?.setValues(arr);
        dispatch(binCenterImport.setFailedJobs(arr));
        callback();
    }
    const formValidation = (form: any, formik: any, dontShowToast: boolean, callback: (form: any) => void) => {
        validationSchema(state, form)
        .then((result: any) => {
            if (validation(state)) {
                dispatch(binCenterImport.setLoading(false));

                setTimeout(() => {
                    if(callback){
                        callback(form);
                    }
                }, 0);
            } else {
                dispatch(binCenterImport.setLoading(false));
                if(dontShowToast){
                    Utils.toast("You must select mandatory columns in the header table", "error");
                }
            }
        })
        .catch((validationErrors: any) => {
            let errorPath: any = null;
            const errors: { [key: string]: string } = {};
            validationErrors.inner.forEach((error: any, i: number) => {
                if(i === 0){
                    errorPath = error.path;
                }
                errors[error.path] = error.message;
            });
            formik.current?.setErrors(errors);

            try {
                if(errorPath && errorPath != ''){
                    const regex = /\[(\d+)\]/;
                    const match = errorPath.match(regex);
                    const numberInsideBrackets = match ? parseInt(match[1]) : null;

                    if (tableRef.current) {
                        tableRef.current.scrollToItem(numberInsideBrackets, 'smart');
                    }
                }
            } catch(e){}

            try {
                if(errorPath && errorPath != ''){
                    let fields = document.getElementsByName(errorPath);
                    if(fields && fields.length > 0){
                        if (fields[0].tagName === 'DIV') {
                            const inputField = fields[0].querySelector('input') as HTMLInputElement | null;
                            if (inputField) {
                                inputField.focus();
                            }
                        } else {
                            fields[0].focus();
                        }
                    }
                }
            } catch(e){}

            dispatch(binCenterImport.setLoading(false));
        });
    }

    const hide = () => {
        dispatch(fileInputButtonSlice.setDisabled(false));
        dispatch(fileInputButtonSlice.onChange(null));
        dispatch(realTimeUpdate.play());
        dispatch(binCenterImport.hide());
    }
    const onShow = () => {
        dispatch(realTimeUpdate.pause());
    }
    const onNext = async () => {
        dispatch(fileInputButtonSlice.setDisabled(true));
        await dispatch(binCenterImport.callReadExcelApi({ file: file, path: 'util/read-excel' }));

        await dispatch(binCenterImport.setHeaderDropdownColumns(setHeaderDropdownCols()));

        formValidation(data, formikRef, false, (form) => {});
    }
    const onClickFailedTab = async () => {
        dispatch(binCenterImport.nextStep(Steps.FailedTab));
    }
    const onClickSuccessTab = async () => {
        dispatch(binCenterImport.nextStep(Steps.SuccessTab));
    }
    const onImport = () => {
        if (formikRef.current) {
            dispatch(binCenterImport.setLoading(true));
            setTimeout(() => {
                if (formikRef.current) {
                    formikRef.current.handleSubmit();
                }
            }, 0);
        }
    }
    const onSubmit = (form: any) => {
        formValidation(form, formikRef, true, async (form) => {
            let dataForm = getData(state, form);
            await dispatch(binCenterImport.callBatchApi({ 
                form: dataForm,
                path: 'binnumber/batch-import',
                callback: (failedJobs: any, successJobs: any, successJobIds: any) => {
                    formikRefFailed.current?.setValues(failedJobs);
                    formikRefSuccess.current?.setValues(successJobs);

                    // if(successJobIds && successJobIds.length > 0){
                    //     dispatch(binCenterImport.callUpdateSignalRApi(successJobIds));
                    // }
                }
            }));
        });
    }
    const onRetry = () => {
        if (formikRefFailed.current) {
            dispatch(binCenterImport.setLoading(true));
            setTimeout(() => {
                if (formikRefFailed.current) {
                    formikRefFailed.current.handleSubmit();
                }
            }, 0);
        }
    }
    const onSubmitFailed = (form: any) => {
        formValidation(form, formikRefFailed, true, async (form) => {
            let dataForm = getData(state, form);
            await dispatch(binCenterImport.callBatchRetryApi({ 
                form: dataForm,
                path: 'binnumber/batch-import',
                callback: (failedJobs: any, successJobs: any, successJobIds: any) => {
                    formikRefFailed.current?.setValues(failedJobs);
                    formikRefSuccess.current?.setValues(successJobs);

                    // if(successJobIds && successJobIds.length > 0){
                    //     dispatch(binCenterImport.callUpdateSignalRApi(successJobIds));
                    // }
                }
            }));
        });
    }


    return <Modal
        show={show}
        onHide={hide}
        onShow={onShow}
        backdrop={"static"}
        fullscreen={true}
        keyboard={false}
        enforceFocus={false}
    >
        <Modal.Header closeButton={true}>
            <h4>Import bins</h4>
        </Modal.Header>

        {(step === Steps.SelectFile || step === Steps.ImportFile) && <Modal.Header>
            <Row className={'w-100 align-items-center'}>
                <Col xs={true}>
                    <FileInputButton />
                </Col>
            </Row>
        </Modal.Header>}

        {((step === Steps.FailedTab) || (step === Steps.SuccessTab)) && <Modal.Header>
            <Row className={'w-100 align-items-center'}>
                <Col xs={'auto'} className={'d-none d-md-block'}><div style={{ width: '30px' }}></div></Col>
                
                {failedJobs.some((x: any) => (x.id !== undefined) && (x.id !== null)) && <Col xs={'auto'}>
                    <StyledButtonFailed 
                        type={'button'}
                        variant={'light'}
                        active={(step === Steps.FailedTab)}
                        disabled={isLoading}
                        onClick={onClickFailedTab}
                    >
                        Failed items: {failedJobs.filter((x: any) => (x.id !== undefined) && (x.id !== null)).length}
                    </StyledButtonFailed>
                </Col>}

                {successJobs.some((x: any) => (x.id !== undefined) && (x.id !== null)) && <Col xs={'auto'}>
                    <StyledButtonSuccess
                        type={'button'}
                        variant={'light'}
                        active={(step === Steps.SuccessTab)}
                        disabled={isLoading}
                        onClick={onClickSuccessTab}
                    >
                        Successful items: {successJobs.filter((x: any) => (x.id !== undefined) && (x.id !== null)).length}
                    </StyledButtonSuccess>
                </Col>}
            </Row>
        </Modal.Header>}



        {(step === Steps.ImportFile) && <StyledModalBody>
            <Formik
                innerRef={formikRef}
                enableReinitialize={false}
                validateOnMount={false}
                validateOnChange={false}
                initialValues={data}
                onSubmit={onSubmit}
            >
                {(formOptions) => <MyTable ref={tableRef} form={formOptions} columns={getColumns(formOptions, false, state, dispatch, null)} onRowDelete={onRowDelete} onCancelRowDelete={onCancelRowDelete} isSearch={false} loading={isLoading} percent={percent} />}
            </Formik>
        </StyledModalBody>}

        {(step === Steps.FailedTab) && <StyledModalBody>
            <Formik
                innerRef={formikRefFailed}
                enableReinitialize={true}
                validateOnMount={true}
                validateOnChange={false}
                initialValues={failedJobs}
                onSubmit={onSubmitFailed}
            >
                {(formOptions) => <MyTable ref={tableRef} form={formOptions} columns={getColumnsFailed(formOptions, true, state, dispatch, null)} onRowDelete={onRowDeleteFailed} onCancelRowDelete={onCancelRowDeleteFailed} isSearch={true} loading={isLoading} percent={percent} />}
            </Formik>
        </StyledModalBody>}

        {(step === Steps.SuccessTab) && <StyledModalBody>
            <Formik
                innerRef={formikRefSuccess}
                enableReinitialize={false}
                validateOnMount={false}
                validateOnChange={false}
                initialValues={successJobs}
                onSubmit={() => {}}
            >
                {(formOptions) => <MyTable ref={tableRef} form={formOptions} columns={getColumnsSuccess(formOptions, true, state, dispatch, null)} isSearch={false} />}
            </Formik>
        </StyledModalBody>}



        <Modal.Footer style={{ justifyContent: 'flex-start' }}>
            <Row className={'m-0 align-items-center'}>
                {(step === Steps.SelectFile) && <Col xs={'auto'} className={'ps-0'}>
                    <Button 
                        type={'button'}
                        disabled={!isFile || isLoading}
                        onClick={onNext}
                    >
                        Next
                        {(isLoading) ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                    </Button>
                </Col>}

                {(step === Steps.ImportFile) && <Col xs={'auto'} className={'ps-0'}>
                    <Button 
                        type={'button'}
                        disabled={!isFile || isLoading}
                        onClick={onImport}
                    >
                        Import
                        {(isLoading) ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                    </Button>
                </Col>}

                {(step === Steps.FailedTab) && <Col xs={'auto'} className={'ps-0'}>
                    <Button 
                        type={'button'}
                        disabled={!isFile || isLoading}
                        variant={'danger'}
                        onClick={onRetry}
                    >
                        Retry
                        {(isLoading) ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                    </Button>
                </Col>}

                <Col xs={'auto'}>
                    <Button 
                        type={'button'}
                        variant={'secondary'}
                        disabled={isLoading}
                        onClick={hide}
                    >
                        {(step === Steps.SuccessTab) ? 'Close' : 'Cancel'}
                        {(isLoading) ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                    </Button>
                </Col>

                {(step === Steps.ImportFile) && <>
                    <Col xs={12} className={'d-block d-sm-none pb-5'} />
                    <Col xs={'auto'}>
                        Total: {data.length}
                    </Col>
                    <Col xs={'auto'}>
                        <Form.Group>
                            <Form.Check
                                id={'excludeFirstRowID'}
                                type={'checkbox'}
                                label={'Exclude 1st row'}

                                disabled={isLoading}
                                checked={excludeFirstRow}
                                onChange={(e: any) => {
                                    let arr = _.cloneDeep(formikRef.current?.values);
                                    dispatch(binCenterImport.setExcludeFirstRow(e.target.checked));

                                    if(e.target.checked){
                                        let fRow = (arr && arr.length > 0) ? arr[0] : null;
                                        dispatch(binCenterImport.setFirstRow(fRow));
                                        dispatch(binCenterImport.setHeaderColumns(fRow));
                                        arr.splice(0, 1);
                                    } else {
                                        dispatch(binCenterImport.setFirstRow(firstRow));
                                        dispatch(binCenterImport.setHeaderColumns(firstRow));
                                        arr.unshift(firstRow);
                                    }
                                    
                                    formikRef.current?.setValues(arr);
                                    dispatch(binCenterImport.setData(arr));
                                }}
                            />
                        </Form.Group>
                    </Col>
                </>}

            </Row>
        </Modal.Footer>
    </Modal>
}


export default ImportPopup;
