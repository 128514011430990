import React from 'react';

const IconButton = props => {
    return <button type={'button'} className={'btn btn-sm btn-icon btn-active-light-primary ' + props.className}
        onClick={(e) => {
            if(props.onClick){
                props.onClick(e);
            }
        }}
    >
        {props.icon ? <span className={'material-icons'}>{props.icon}</span> : props.children}
    </button>
}


export default IconButton;