import React from 'react';

import { 
    Row,
    Col,
} from "react-bootstrap-v5";

import { useDispatch, useSelector } from "react-redux";
import {RootState} from '../../../../../setup'
import payrollBetaLayout from '../redux/payrollBetaLayout';

import Geofence from './Geofence';
import GeofenceJobType from './GeofenceJobType';

import { StyledSecondaryRadio } from '../style/styles';


const PayrollBetaStep3b = (props: any) => {
    const dispatch = useDispatch();
    const { step3bRadio } = useSelector((state: RootState) => state.payrollBetaLayout);
    const { isLoading} = useSelector((state: RootState) => state.payrollBetaForm);


    return <>
        {
            isLoading
            ?
            null
            :
            <Row>
                <Col xs={12} className={'mb-10'}>
                    <StyledSecondaryRadio
                        inline
                        type={'radio'}
                        name={'step_3b'}
                        id={'step_3b_1'}
                        label={'No'}
                        checked={!step3bRadio}
                        onChange={(e: any) => {
                            dispatch(payrollBetaLayout.setStep3bRadio(false));
                        }}
                    />
                    <StyledSecondaryRadio
                        inline
                        type={'radio'}
                        name={'step_3b'}
                        id={'step_3b_2'}
                        label={'Yes'}
                        checked={step3bRadio}
                        onChange={(e: any) => {
                            dispatch(payrollBetaLayout.setStep3bRadio(true));
                        }}
                    />
                </Col>
                <Col xs={12}>
                    {step3bRadio ? <GeofenceJobType /> : <Geofence />}
                </Col>
            </Row>
        }
    </>
}


export default PayrollBetaStep3b;
