import React, { Component } from 'react';

import {
    Box,
    Icon,
    CircularProgress,
} from '@material-ui/core';

import { CheckTree } from 'rsuite';
import "rsuite/dist/rsuite.min.css";


class RSuiteCheckTreePicker extends Component {
  
	constructor(props){
		super(props);

        let items = props.items ? props.items : [];
        let value = props.value ? props.value : [];
        let uncheckableItemValues = (props.uncheckableItemValues && props.uncheckableItemValues.length > 0) ? props.uncheckableItemValues : [];
        let cascade = ((props.cascade === true || props.cascade === false) ? props.cascade : true);
        let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
        let defaultExpandAll = ((props.defaultExpandAll === true || props.defaultExpandAll === false) ? props.defaultExpandAll : true);
        let labelKey = props.labelKey ? props.labelKey : 'label';
        let valueKey = props.valueKey ? props.valueKey : 'value';
        let childrenKey = props.childrenKey ? props.childrenKey : 'children';
        let elementsClassName = (props.elementsClassName && props.elementsClassName.length > 0) ? props.elementsClassName : [];
        let customHeight = props.customHeight ? props.customHeight : 0;
        
		this.state = {
            isLoading: isLoading,
            items: items,
            value: value,
            uncheckableItemValues: uncheckableItemValues,
            cascade: cascade,
            defaultExpandAll: defaultExpandAll,
            labelKey: labelKey,
            valueKey: valueKey,
            childrenKey: childrenKey,
            elementsClassName: elementsClassName,
            customHeight: customHeight,

            height: 360,
		}
	}
    

    componentDidMount() {
        this.initResize();
        window.addEventListener('resize', this.initResize);

        if(this.props.onInit){
            this.props.onInit();
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.initResize);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.items !== this.state.items) {
            let items = nextProps.items ? nextProps.items : [];
			this.setState({
				items: items
			});
        }
        
        if (nextProps.value !== this.state.value) {
            let value = nextProps.value ? nextProps.value : [];
			this.setState({
				value: value
			});
        }

        if (nextProps.cascade !== this.state.cascade) {
            let cascade = ((nextProps.cascade === true || nextProps.cascade === false) ? nextProps.cascade : true);
			this.setState({
				cascade: cascade
			});
		}

        if (nextProps.isLoading !== this.state.isLoading) {
            let isLoading = ((nextProps.isLoading === true || nextProps.isLoading === false) ? nextProps.isLoading : false);
			this.setState({
				isLoading: isLoading
			});
		}

        if (nextProps.uncheckableItemValues !== this.state.uncheckableItemValues) {
            let uncheckableItemValues = (nextProps.uncheckableItemValues && nextProps.uncheckableItemValues.length > 0) ? nextProps.uncheckableItemValues : [];
			this.setState({
				uncheckableItemValues: uncheckableItemValues
			});
		}
    }


    initResize = () => {
        let height = this.state.height;
        let windowHeight = window.innerHeight;
    
        try {
            let header = document.body.getElementsByTagName('header')[0];
            let headerPosition = header.getBoundingClientRect();
            let hHeight = headerPosition.height + 1;
            let elementsHeight = this.getElementsHeight();
            height = windowHeight - hHeight - elementsHeight - this.state.customHeight;
        } catch(err){
        } finally {
            this.setState({
                height: height,
            });
        }
    }
    getElementsHeight = () => {
        let height = 0;
        let elementsClassName = this.state.elementsClassName;
        
        if(elementsClassName && elementsClassName.length > 0){
            for(let i = 0; i < elementsClassName.length; i++){
                let cls = elementsClassName[i];
                
                try {
                    let el = document.getElementsByClassName(cls)[0];
                    let elPosition = el.getBoundingClientRect();
                    height += elPosition.height;
                } catch(err){}
            }
        }
    
        return height;
      }


    handleChange = (value) => {
        if(this.props.onChange){
            this.props.onChange(value);
        }
    }
    handleSelect = (activeNode, value, e) => {
        if(this.props.onSelect){
            this.props.onSelect(activeNode, value, e);
        }
    }
    

	render() {
		return <Box className={'custom-rsuite-checktree-picker ' + (this.props.isDefault ? 'default-look ' : '') + this.props.className}>
            {
                this.state.isLoading
                ?
                <div style={{ paddingTop: '10px', paddingLeft: '20px' }}><CircularProgress size={35} /></div>
                :
                <CheckTree
                    // style={{
                    //     maxHeight: this.state.height + 'px'
                    // }}
                    data={this.state.items}
                    value={this.state.value}
                    labelKey={this.state.labelKey}
                    valueKey={this.state.valueKey}
                    childrenKey={this.state.childrenKey}
                    cascade={this.state.cascade}
                    defaultExpandAll={this.state.defaultExpandAll}
                    uncheckableItemValues={this.state.uncheckableItemValues}
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                    renderTreeIcon={(x) => {
                        return <div className={'custom-expand-icon-wrapper'}>
                            <Icon component={'i'} className={'custom-expand-icon far ' + (x.expand ? 'fa-minus-square' : 'fa-plus-square')} />
                        </div>
                    }}
                    renderTreeNode={(x) => {
                        return <div>{x[this.state.labelKey]}</div>
                    }}
                />
            }
            
		</Box>;
	}
}

export default RSuiteCheckTreePicker;