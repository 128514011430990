import React, { Component } from "react";

import { 
  Row,
  Col,
  Button,
  Dropdown,
} from "react-bootstrap-v5";

import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import ViewLayout from "./layout/ViewLayout.jsx";
import ViewTopSection from "./partial/ViewTopSection.jsx";
import BulkUpdateCustomerForm from "./partial/BulkUpdateCustomerForm";

import DataTable from "../../components/OLD/DataTable/DataTable";
import DataTableActionMenu from '../../components/OLD/DataTable/DataTableActionMenu';
import DataTableAjaxPagination from '../../components/OLD/DataTable/Pagination/DataTableAjaxPaginationV5';
import DataTableScrollSync from '../../components/OLD/DataTable/DataTableScrollSync.jsx';
import IconButtonSearch from '../../components/input/IconButtonSearch';
import DropdownToggle from '../../components/dropdown/DropdownToggle';
import makeDropdownList from "../../components/dropdown/makeDropdownList";
import WrappedButton from '../../components/Buttons/WrappedButton';
import Popup from '../../components/popup/Popup';
import LoadingButton from '../../components/Buttons/LoadingButton';

import Utils from '../../utils/utils'
import { PageCookieSettingType } from '../../utils/enums'

import { connect } from 'react-redux'
import { actions } from "../../../setup/redux/auth/saga";
import { clear } from "../../../setup/redux/actions";
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut, dispatchApiCallDelete } from '../../../setup/redux/dispatch/actions'
import signalRslice from "../../components/signalR/signalRslice";
import cancelToken from "../../../setup/redux/slices/cancelToken";


const { saveUserCookieSettings } = actions;
const DropdownMenu = makeDropdownList(Button);


class CustomerList extends Component {
  constructor(props) {
    super(props);

    props.layoutRef.current = this;

    this.pageName = "customer-list-page";
    this.pageIdField = "customerId";
    this.pageAPI = "customer";
    this.pageCaption = 'Customers'

    this.tableRef = React.createRef();
    this.refDataTableScrollSync = React.createRef();
    this.refCustomerForm = React.createRef();
    this.refBulkUpdate = React.createRef();
    
    
    this.customerNameTemplate = (id, row, col, obj) => {
      return <a href={"/"} 
        className='menu-link'
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          
          this.props.history.push('/Customer-form?id=' + id);
        }}
      >
        {row.customerName}
      </a>
    };
    this.customerNameExportTemplate = (row) => {
      return (row.customerName && row.customerName !== '') ? row.customerName : '';
    };
    this.customerNameExportCSVTemplate = (row) => {
      return (row.customerName && row.customerName !== '') ? row.customerName : '';
    };

    this.customerPaymentTermNameTemplate = (id, row, col, obj) => {
      let paymentTerms = row.paymentTerms && row.paymentTerms !== '' ? row.paymentTerms : "";
      let customerPaymentTermName = row.paymentTerm && row.paymentTerm.customerPaymentTermName ? row.paymentTerm.customerPaymentTermName : "";
      return (paymentTerms && paymentTerms !== '') ? paymentTerms : customerPaymentTermName;
    };
    this.customerPaymentTermNameExportTemplate = (row) => {
      let paymentTerms = row.paymentTerms && row.paymentTerms !== '' ? row.paymentTerms : "";
      let customerPaymentTermName = row.paymentTerm && row.paymentTerm.customerPaymentTermName ? row.paymentTerm.customerPaymentTermName : "";
      return (paymentTerms && paymentTerms !== '') ? paymentTerms : customerPaymentTermName;
    };
    this.customerPaymentTermExportCSVTemplate = (row) => {
      let paymentTerms = row.paymentTerms && row.paymentTerms !== '' ? row.paymentTerms : "";
      let customerPaymentTermName = row.paymentTerm && row.paymentTerm.customerPaymentTermName ? row.paymentTerm.customerPaymentTermName : "";
      return (paymentTerms && paymentTerms !== '') ? paymentTerms : customerPaymentTermName;
    };

    this.creditLimitTemplate = (id, row, col, obj) => {
      return <div>{(row.creditLimit) ? Utils.getCurrency() + Utils.formatNumber(row.creditLimit) : 'Unlimited'}</div>
    };
    this.creditLimitExportTemplate = (row) => {
      return row.creditLimit ? Utils.getCurrency() + Utils.formatNumber(row.creditLimit) : 'Unlimited';
    };
    this.creditLimitExportCSVTemplate = (row) => {
      return row.creditLimit ? Utils.getCurrency() + Utils.formatNumber(row.creditLimit) : 'Unlimited';
    };

    this.isActiveTemplate = (id, row, col, obj) => {
      return <div className={(row.isActive) ? '' : 'text-danger'}>{(row.isActive) ? 'Active' : 'Inactive'}</div>
    };
    this.isActiveExportTemplate = (row) => {
      return row.isActive === true ? "Active" : "Inactive";
    };
    this.isActiveExportCSVTemplate = (row) => {
      return row.isActive === true ? "Active" : "Inactive";
    };

    this.isAutoSendDoDocTemplate = (id, row, col, obj) => {
      return row.isAutoSendDoDoc === true ? "Yes" : "No";
    };
    this.isAutoSendDoDocExportTemplate = (row) => {
      return row.isAutoSendDoDoc === true ? "Yes" : "No";
    };
    this.isAutoSendDoDocExportCSVTemplate = (row) => {
      return row.isAutoSendDoDoc === true ? "Yes" : "No";
    };

    this.isRequirePaymentCollectionTemplate = (id, row, col, obj) => {
      return row.isRequirePaymentCollection === true ? "Yes" : "No";
    };
    this.isRequirePaymentCollectionExportTemplate = (row) => {
      return row.isRequirePaymentCollection === true ? "Yes" : "No";
    };
    this.isRequirePaymentCollectionExportCSVTemplate = (row) => {
      return row.isRequirePaymentCollection === true ? "Yes" : "No";
    };


    this.firstNameTemplate = (id, row, col, obj) => {
      return this.getContactPersonsColumn(row, col.field, col.index);
    };
    this.firstNameExportTemplate = (row, obj, r, col) => {
      return this.getContactPersonsColumn(row, col.field, col.index);
    };
    this.firstNameExportCSVTemplate = (row, obj, r, col) => {
      return this.getContactPersonsColumn(row, col.field, col.index);
    };

    this.emailAddressTemplate = (id, row, col, obj) => {
      return this.getContactPersonsColumn(row, col.field, col.index);
    };
    this.emailAddressExportTemplate = (row, obj, r, col) => {
      return this.getContactPersonsColumn(row, col.field, col.index);
    };
    this.emailAddressExportCSVTemplate = (row, obj, r, col) => {
      return this.getContactPersonsColumn(row, col.field, col.index);
    };

    this.workPhoneTemplate = (id, row, col, obj) => {
      return this.getContactPersonsColumn(row, col.field, col.index);
    };
    this.workPhoneExportTemplate = (row, obj, r, col) => {
      return this.getContactPersonsColumn(row, col.field, col.index);
    };
    this.workPhoneExportCSVTemplate = (row, obj, r, col) => {
      return this.getContactPersonsColumn(row, col.field, col.index);
    };

    this.mobilePhoneTemplate = (id, row, col, obj) => {
      return this.getContactPersonsColumn(row, col.field, col.index);
    };
    this.mobilePhoneExportTemplate = (row, obj, r, col) => {
      return this.getContactPersonsColumn(row, col.field, col.index);
    };
    this.mobilePhoneExportCSVTemplate = (row, obj, r, col) => {
      return this.getContactPersonsColumn(row, col.field, col.index);
    };

    this.departmentTemplate = (id, row, col, obj) => {
      return this.getContactPersonsColumn(row, col.field, col.index);
    };
    this.departmentExportTemplate = (row, obj, r, col) => {
      return this.getContactPersonsColumn(row, col.field, col.index);
    };
    this.departmentExportCSVTemplate = (row, obj, r, col) => {
      return this.getContactPersonsColumn(row, col.field, col.index);
    };



    let columns = [
      { 
        id: this.pageIdField, 
        filterKey: this.pageIdField, 
        name: this.pageIdField, 
        label: 'ID',
        visible: false
      },
      {
        id: "accountCustomerId",
        name: "accountCustomerId",
        label: "ID",
        menuLabel: "ID",
        responsiveLabel: "ID",
        width: "50px",
        orderable: true,
      },
      {
        id: "customerName",
        name: "customerName",
        label: "Customer",
        responsiveLabel: "Customer",
        orderable: true,
        width: "150px",
        template: this.customerNameTemplate,
        exportTemplate: this.customerNameExportTemplate,
        exportCSVTemplate: this.customerNameExportCSVTemplate,
      },
      {
        id: "isActive",
        name: "isActive",
        label: "Status",
        responsiveLabel: "Status",
        width: "100px",
        orderable: true,
        template: this.isActiveTemplate,
        exportTemplate: this.isActiveExportTemplate,
        exportCSVTemplate: this.isActiveExportCSVTemplate,
      },
      {
        id: "billingAddress",
        name: "billingAddress",
        label: "Billing address",
        responsiveLabel: "Billing address",
        width: "220px",
        orderable: true,
      },
      {
        id: "customerCategoryName",
        name: "customerCategoryName",
        label: "Category",
        responsiveLabel: "Category",
        width: "150px",
        orderable: true,
      },
      {
        id: "billingProfileName",
        name: "billingProfileName",
        label: "Billing by",
        responsiveLabel: "Billing by",
        width: "150px",
        orderable: true,
      },
      {
        id: "billingName",
        name: "billingName",
        label: "Billing name",
        responsiveLabel: "Billing name",
        width: "150px",
        orderable: true,
      },
      {
        id: "businessRegNo",
        name: "businessRegNo",
        label: "Business Reg. No.",
        responsiveLabel: "Business Reg. No.",
        width: "150px",
        orderable: true,
      },
      {
        id: "paymentTerms",
        name: "paymentTerms",
        label: "Payment terms",
        responsiveLabel: "Payment terms",
        width: "150px",
        orderable: true,
        template: this.customerPaymentTermNameTemplate,
        exportTemplate: this.customerPaymentTermNameExportTemplate,
        exportCSVTemplate: this.customerPaymentTermExportCSVTemplate,
      },
      {
        id: "creditLimit",
        name: "creditLimit",
        label: "Credit Limit",
        responsiveLabel: "Credit Limit",
        width: "150px",
        orderable: true,
        template: this.creditLimitTemplate,
        exportTemplate: this.creditLimitExportTemplate,
        exportCSVTemplate: this.creditLimitExportCSVTemplate,
      },
      {
        id: "emailAddresses",
        name: "emailAddresses",
        label: "Company email",
        responsiveLabel: "Company email",
        width: "220px",
        orderable: true,
      },
      {
        id: "note",
        name: "note",
        label: "Customer note",
        responsiveLabel: "Customer note",
        width: "150px",
        orderable: true,
      },
      // {
      //   id: "isAutoSendDoDoc",
      //   name: "isAutoSendDoDoc",
      //   label: "Autosend DO",
      //   responsiveLabel: "Autosend DO",
      //   width: "100px",
      //   orderable: true,
      //   template: this.isAutoSendDoDocTemplate,
      //   exportTemplate: this.isAutoSendDoDocExportTemplate,
      //   exportCSVTemplate: this.isAutoSendDoDocExportCSVTemplate,
      // },
      {
        id: "contactNumber",
        name: "contactNumber",
        label: "Phone number",
        responsiveLabel: "Phone number",
        width: "150px",
        orderable: true,
      },
      {
        id: "isRequirePaymentCollection",
        name: "isRequirePaymentCollection",
        label: "Collect payment",
        responsiveLabel: "Collect payment",
        width: "100px",
        orderable: true,
        template: this.isRequirePaymentCollectionTemplate,
        exportTemplate: this.isRequirePaymentCollectionExportTemplate,
        exportCSVTemplate: this.isRequirePaymentCollectionExportCSVTemplate,
      },
      {
        id: "noOfCharges",
        name: "noOfCharges",
        label: "Local Services",
        responsiveLabel: "Local Services",
        width: "90px",
        orderable: true,
      },
      {
        id: "noOfSites",
        name: "noOfSites",
        label: "Sites",
        responsiveLabel: "Sites",
        width: "70px",
        orderable: true,
      },
      
      {
        id: "firstName1",
        name: "firstName1",
        label: "First name 1",
        responsiveLabel: "First name 1",
        field: "firstName",
        index: 1,
        orderable: false,
        width: "170px",
        template: this.firstNameTemplate,
        exportTemplate: this.firstNameExportTemplate,
        exportCSVTemplate: this.firstNameExportCSVTemplate,
      },
      {
        id: "emailAddress1",
        name: "emailAddress1",
        label: "Email address 1",
        responsiveLabel: "Email address 1",
        field: "emailAddress",
        index: 1,
        orderable: false,
        width: "250px",
        template: this.emailAddressTemplate,
        exportTemplate: this.emailAddressExportTemplate,
        exportCSVTemplate: this.emailAddressExportCSVTemplate,
      },
      {
        id: "workPhone1",
        name: "workPhone1",
        label: "Work phone 1",
        responsiveLabel: "Work phone 1",
        field: "workPhone",
        index: 1,
        orderable: false,
        width: "200px",
        template: this.workPhoneTemplate,
        exportTemplate: this.workPhoneExportTemplate,
        exportCSVTemplate: this.workPhoneExportCSVTemplate,
      },
      {
        id: "mobilePhone1",
        name: "mobilePhone1",
        label: "Mobile phone 1",
        responsiveLabel: "Mobile phone 1",
        field: "mobilePhone",
        index: 1,
        orderable: false,
        width: "200px",
        template: this.mobilePhoneTemplate,
        exportTemplate: this.mobilePhoneExportTemplate,
        exportCSVTemplate: this.mobilePhoneExportCSVTemplate,
      },
      {
        id: "department1",
        name: "department1",
        label: "Department 1",
        responsiveLabel: "Department 1",
        field: "department",
        index: 1,
        orderable: false,
        width: "200px",
        template: this.departmentTemplate,
        exportTemplate: this.departmentExportTemplate,
        exportCSVTemplate: this.departmentExportCSVTemplate,
      },

      {
        id: "firstName2",
        name: "firstName2",
        label: "First name 2",
        responsiveLabel: "First name 2",
        field: "firstName",
        index: 2,
        orderable: false,
        width: "170px",
        template: this.firstNameTemplate,
        exportTemplate: this.firstNameExportTemplate,
        exportCSVTemplate: this.firstNameExportCSVTemplate,
      },
      {
        id: "emailAddress2",
        name: "emailAddress2",
        label: "Email address 2",
        responsiveLabel: "Email address 2",
        field: "emailAddress",
        index: 2,
        orderable: false,
        width: "250px",
        template: this.emailAddressTemplate,
        exportTemplate: this.emailAddressExportTemplate,
        exportCSVTemplate: this.emailAddressExportCSVTemplate,
      },
      {
        id: "workPhone2",
        name: "workPhone2",
        label: "Work phone 2",
        responsiveLabel: "Work phone 2",
        field: "workPhone",
        index: 2,
        orderable: false,
        width: "200px",
        template: this.workPhoneTemplate,
        exportTemplate: this.workPhoneExportTemplate,
        exportCSVTemplate: this.workPhoneExportCSVTemplate,
      },
      {
        id: "mobilePhone2",
        name: "mobilePhone2",
        label: "Mobile phone 2",
        responsiveLabel: "Mobile phone 2",
        field: "mobilePhone",
        index: 2,
        orderable: false,
        width: "200px",
        template: this.mobilePhoneTemplate,
        exportTemplate: this.mobilePhoneExportTemplate,
        exportCSVTemplate: this.mobilePhoneExportCSVTemplate,
      },
      {
        id: "department2",
        name: "department2",
        label: "Department 2",
        responsiveLabel: "Department 2",
        field: "department",
        index: 2,
        orderable: false,
        width: "200px",
        template: this.departmentTemplate,
        exportTemplate: this.departmentExportTemplate,
        exportCSVTemplate: this.departmentExportCSVTemplate,
      },

      {
        id: "firstName3",
        name: "firstName3",
        label: "First name 3",
        responsiveLabel: "First name 3",
        field: "firstName",
        index: 3,
        orderable: false,
        width: "170px",
        template: this.firstNameTemplate,
        exportTemplate: this.firstNameExportTemplate,
        exportCSVTemplate: this.firstNameExportCSVTemplate,
      },
      {
        id: "emailAddress3",
        name: "emailAddress3",
        label: "Email address 3",
        responsiveLabel: "Email address 3",
        field: "emailAddress",
        index: 3,
        orderable: false,
        width: "250px",
        template: this.emailAddressTemplate,
        exportTemplate: this.emailAddressExportTemplate,
        exportCSVTemplate: this.emailAddressExportCSVTemplate,
      },
      {
        id: "workPhone3",
        name: "workPhone3",
        label: "Work phone 3",
        responsiveLabel: "Work phone 3",
        field: "workPhone",
        index: 3,
        orderable: false,
        width: "200px",
        template: this.workPhoneTemplate,
        exportTemplate: this.workPhoneExportTemplate,
        exportCSVTemplate: this.workPhoneExportCSVTemplate,
      },
      {
        id: "mobilePhone3",
        name: "mobilePhone3",
        label: "Mobile phone 3",
        responsiveLabel: "Mobile phone 3",
        field: "mobilePhone",
        index: 3,
        orderable: false,
        width: "200px",
        template: this.mobilePhoneTemplate,
        exportTemplate: this.mobilePhoneExportTemplate,
        exportCSVTemplate: this.mobilePhoneExportCSVTemplate,
      },
      {
        id: "department3",
        name: "department3",
        label: "Department 3",
        responsiveLabel: "Department 3",
        field: "department",
        index: 3,
        orderable: false,
        width: "200px",
        template: this.departmentTemplate,
        exportTemplate: this.departmentExportTemplate,
        exportCSVTemplate: this.departmentExportCSVTemplate,
      },

      {
        id: "firstName4",
        name: "firstName4",
        label: "First name 4",
        responsiveLabel: "First name 4",
        field: "firstName",
        index: 4,
        orderable: false,
        width: "170px",
        template: this.firstNameTemplate,
        exportTemplate: this.firstNameExportTemplate,
        exportCSVTemplate: this.firstNameExportCSVTemplate,
      },
      {
        id: "emailAddress4",
        name: "emailAddress4",
        label: "Email address 4",
        responsiveLabel: "Email address 4",
        field: "emailAddress",
        index: 4,
        orderable: false,
        width: "250px",
        template: this.emailAddressTemplate,
        exportTemplate: this.emailAddressExportTemplate,
        exportCSVTemplate: this.emailAddressExportCSVTemplate,
      },
      {
        id: "workPhone4",
        name: "workPhone4",
        label: "Work phone 4",
        responsiveLabel: "Work phone 4",
        field: "workPhone",
        index: 4,
        orderable: false,
        width: "200px",
        template: this.workPhoneTemplate,
        exportTemplate: this.workPhoneExportTemplate,
        exportCSVTemplate: this.workPhoneExportCSVTemplate,
      },
      {
        id: "mobilePhone4",
        name: "mobilePhone4",
        label: "Mobile phone 4",
        responsiveLabel: "Mobile phone 4",
        field: "mobilePhone",
        index: 4,
        orderable: false,
        width: "200px",
        template: this.mobilePhoneTemplate,
        exportTemplate: this.mobilePhoneExportTemplate,
        exportCSVTemplate: this.mobilePhoneExportCSVTemplate,
      },
      {
        id: "department4",
        name: "department4",
        label: "Department 4",
        responsiveLabel: "Department 4",
        field: "department",
        index: 4,
        orderable: false,
        width: "200px",
        template: this.departmentTemplate,
        exportTemplate: this.departmentExportTemplate,
        exportCSVTemplate: this.departmentExportCSVTemplate,
      },

      {
        id: "firstName5",
        name: "firstName5",
        label: "First name 5",
        responsiveLabel: "First name 5",
        field: "firstName",
        index: 5,
        orderable: false,
        width: "170px",
        template: this.firstNameTemplate,
        exportTemplate: this.firstNameExportTemplate,
        exportCSVTemplate: this.firstNameExportCSVTemplate,
      },
      {
        id: "emailAddress5",
        name: "emailAddress5",
        label: "Email address 5",
        responsiveLabel: "Email address 5",
        field: "emailAddress",
        index: 5,
        orderable: false,
        width: "250px",
        template: this.emailAddressTemplate,
        exportTemplate: this.emailAddressExportTemplate,
        exportCSVTemplate: this.emailAddressExportCSVTemplate,
      },
      {
        id: "workPhone5",
        name: "workPhone5",
        label: "Work phone 5",
        responsiveLabel: "Work phone 5",
        field: "workPhone",
        index: 5,
        orderable: false,
        width: "200px",
        template: this.workPhoneTemplate,
        exportTemplate: this.workPhoneExportTemplate,
        exportCSVTemplate: this.workPhoneExportCSVTemplate,
      },
      {
        id: "mobilePhone5",
        name: "mobilePhone5",
        label: "Mobile phone 5",
        responsiveLabel: "Mobile phone 5",
        field: "mobilePhone",
        index: 5,
        orderable: false,
        width: "200px",
        template: this.mobilePhoneTemplate,
        exportTemplate: this.mobilePhoneExportTemplate,
        exportCSVTemplate: this.mobilePhoneExportCSVTemplate,
      },
      {
        id: "department5",
        name: "department5",
        label: "Department 5",
        responsiveLabel: "Department 5",
        field: "department",
        index: 5,
        orderable: false,
        width: "200px",
        template: this.departmentTemplate,
        exportTemplate: this.departmentExportTemplate,
        exportCSVTemplate: this.departmentExportCSVTemplate,
      },
    ];
    let initColumns = Array.from(columns);
    columns = Array.from(initColumns);
    
    
    let columnVisibility = [
      false,
      true,
      true,
      true,
      true,
      true,
      true,
      false,
      false,
      false,
      true,
      true,
      true,
      // false,
      true,
      false,
      true,
      true,

      true,
      true,
      true,
      true,
      true,

      false,
      false,
      false,
      false,
      false,

      false,
      false,
      false,
      false,
      false,

      false,
      false,
      false,
      false,
      false,

      false,
      false,
      false,
      false,
      false,

      // true,
    ];
    let initColumnVisibility = Array.from(columnVisibility);
    columnVisibility = Array.from(initColumnVisibility);


    this.state = {
      data: null,
      err: null,
      isError: false,
      isLoading: false,

      search: '',
      sortColumn: 'updated',
      sortDir: 'desc',
      sortColumnInit: 'updated',
      sortDirInit: 'desc',

      filterParams: [],
      searchOperator: [],

      selected: [],
      columnVisibility: columnVisibility,
      initColumnVisibility: initColumnVisibility,
      columns: columns,
      initColumns: initColumns,
      rows: [],
      currentPage: 1,
      totalRows: 0,
      rowsPerPageSelected: 50,
      rowsPerPageOptions: [50, 100, 300],
      isIncludeInactive: null,
      isInactive: false,
      
      isDisabledBatchAction: true,
      isCookies: false,
      
      popupShowBulkUpdate: false,
      popupLoadingBulkUpdate: false,
      popupIdBulkUpdate: null,
      
      binTypeId: null,
      binTypeName: '',
      binTypeItem: null,
    }
  }


  componentDidMount() {
    this.getFilterInLocalStorage(() => {
      this.callReadApi();
    });
  }
  async componentWillUnmount() {
    await this.props.action.cancelToken.cancel();
  }
  
  componentWillReceiveProps(nextProps) {
    this.reduxProps(nextProps);
	}
  

	/* FUNCTIONS */
  onWindowResize = () => {
    this.getFilterInLocalStorage();
  }

  setFilterInLocalStorage = (isCookies = true) => {
    if(this.props.settingsType >= 0){
      let { token, userCookieSettings } = this.props.auth;

      this.setState({
        isCookies: isCookies,
      });
      
      let data = {
        name: this.pageName,
        type: this.props.settingsType,
      };

      let valueLocalStorage = {
        [PageCookieSettingType.currentPage]: this.state.currentPage,
      };
      window.localStorage.setItem(this.pageName + '_' + this.props.settingsType, JSON.stringify(valueLocalStorage));

      let value = {
        [PageCookieSettingType.isCookies]: isCookies,
        [PageCookieSettingType.search]: this.state.search,
        [PageCookieSettingType.sortColumn]: this.state.sortColumn,
        [PageCookieSettingType.sortDir]: this.state.sortDir,
        [PageCookieSettingType.rowsPerPageSelected]: this.state.rowsPerPageSelected,
        [PageCookieSettingType.columnVisibility]: this.state.columnVisibility,
        [PageCookieSettingType.columns]: this.state.columns,
        // [PageCookieSettingType.isIncludeInactive]: this.state.isIncludeInactive,
        // [PageCookieSettingType.isInactive]: this.state.isInactive,
      };
      data['value'] = JSON.stringify(value);

      Utils.saveUserCookieSettings(data, token, (settings) => {
        if(settings){
          let settingsArr = (userCookieSettings && userCookieSettings.length > 0) ? userCookieSettings : [];
          let settingsIndex = Utils.getUserCookieSettingsIndex(settingsArr, this.props.settingsType);
          if(settingsIndex !== -1 && settingsArr[settingsIndex]){
            settingsArr[settingsIndex] = settings;
          } else {
            settingsArr.push(settings);
          }

          this.props.saveUserCookieSettings(settingsArr)
        }
      });
    }
  };
  getFilterInLocalStorage = (callback = null) => {
    let settingsItem = null;

    if(this.props.settingsType >= 0){
      const { userCookieSettings } = this.props.auth;

      settingsItem = Utils.getUserCookieSettings(userCookieSettings, this.props.settingsType, this.pageName);
    }

    let valueLocalStorageStr = window.localStorage.getItem(this.pageName + '_' + this.props.settingsType);
    let valueLocalStorage = JSON.parse(valueLocalStorageStr);
    let currentPageValue = valueLocalStorage && valueLocalStorage[PageCookieSettingType.currentPage] && valueLocalStorage[PageCookieSettingType.currentPage] !== "" ? valueLocalStorage[PageCookieSettingType.currentPage] : this.state.currentPage;
    
    let isCookiesValue = settingsItem && settingsItem[PageCookieSettingType.isCookies] && settingsItem[PageCookieSettingType.isCookies] !== "" ? settingsItem[PageCookieSettingType.isCookies] : this.state.isCookies;
    let searchValue = (settingsItem && settingsItem[PageCookieSettingType.search] && settingsItem[PageCookieSettingType.search] !== '') ? settingsItem[PageCookieSettingType.search] : this.state.search;
    let sortColumnValue = settingsItem && settingsItem[PageCookieSettingType.sortColumn] && settingsItem[PageCookieSettingType.sortColumn] !== "" ? settingsItem[PageCookieSettingType.sortColumn] : this.state.sortColumn;
    let sortDirValue = settingsItem && settingsItem[PageCookieSettingType.sortDir] && settingsItem[PageCookieSettingType.sortDir] !== "" ? settingsItem[PageCookieSettingType.sortDir] : this.state.sortDir;
    let rowsPerPageSelectedValue = settingsItem && settingsItem[PageCookieSettingType.rowsPerPageSelected] && settingsItem[PageCookieSettingType.rowsPerPageSelected] !== "" ? settingsItem[PageCookieSettingType.rowsPerPageSelected] : this.state.rowsPerPageSelected;
    let columnVisibilityValue = (settingsItem && settingsItem[PageCookieSettingType.columnVisibility] && settingsItem[PageCookieSettingType.columnVisibility] !== '') ? settingsItem[PageCookieSettingType.columnVisibility] : this.state.columnVisibility;
    let columnsValue = (settingsItem && settingsItem[PageCookieSettingType.columns] && settingsItem[PageCookieSettingType.columns] !== '') ? settingsItem[PageCookieSettingType.columns] : this.state.columns;
    // let isIncludeInactiveValue = (settingsItem && settingsItem[PageCookieSettingType.isIncludeInactive] && settingsItem[PageCookieSettingType.isIncludeInactive] !== '') ? settingsItem[PageCookieSettingType.isIncludeInactive] : this.state.isIncludeInactive;
    // let isInactiveValue = (settingsItem && settingsItem[PageCookieSettingType.isInactive] && settingsItem[PageCookieSettingType.isInactive] !== '') ? settingsItem[PageCookieSettingType.isInactive] : this.state.isInactive;
    
    let columns = Utils.setTemplateFunctionsToColumns(this.state.columns, columnsValue);
    
    this.setState({
      isCookies: isCookiesValue,
      search: searchValue,
      sortColumn: sortColumnValue,
      sortDir: sortDirValue,
      currentPage: currentPageValue,
      rowsPerPageSelected: rowsPerPageSelectedValue,
      columnVisibility: columnVisibilityValue,
      // isIncludeInactive: isIncludeInactiveValue,
      // isInactive: isInactiveValue,
      columns: columns,
    }, () => {
      if (callback) {
        callback();
      }
    });
  };

  getContactPersonsColumn = (row = null, field = '', index = null) => {
    if(row && index && field && field != ''){
      if(row.contactPersons && row.contactPersons.length >= index){
        return (row.contactPersons[(index-1)] && row.contactPersons[(index-1)][field] && row.contactPersons[(index-1)][field] != '') ? row.contactPersons[(index-1)][field] : '';
      } else {
        return '';
      }
    } else {
      return '';
    }
  }


  reduxProps = nextProps => {
		Utils.reduxProps(nextProps,
      this.pageName + '-list', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        let rows = (data && data.data && data.data.length > 0) ? data.data : [];
        let totalRows = (data && data.total) ? data.total : 0;
        
        this.setState({
          rows: rows,
          totalRows: totalRows,

          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(callback){
            callback();
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      this.pageName + '-list_by_page', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        let rows = (data && data.data && data.data.length > 0) ? data.data : [];
        
        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(callback){
            callback(rows);
          }
        });
      }
    );
    
    Utils.reduxProps(nextProps,
      this.pageName + '-list_all', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        let rows = (data && data.data && data.data.length > 0) ? data.data : [];
        
        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          this.props.clear();

          if(callback){
            callback(rows, isError, err);
          }

          this.props.action.signalRslice.disconnect();
        });
      }
    );

    Utils.reduxProps(nextProps,
      this.pageName + '-list_download_excel', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          this.props.clear();

          if(callback){
            callback(data, isError, err);
          }

          this.props.action.signalRslice.disconnect();
        });
      }
    );

    Utils.reduxProps(nextProps,
      this.pageName + '-deactivate', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(callback){
            callback(data);
          }
        });
      }
    );
    
    Utils.reduxProps(nextProps,
      this.pageName + '-update_bulk', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
          this.setState({
              isLoading: isLoading,
              isError: isError,
              err: err,
          }, () => {
              if(callback){
                  callback(data);
              }
          });
      }
    );
  }
	/* END FUNCTIONS */

	
	/* API */
  callReadApi = (withStatusCall = true, callback = null) => {
    this.setState({
      isLoading: true,
    });

    let data = {
      currentPage: this.state.currentPage,
      pageSize: this.state.rowsPerPageSelected,
      searchQuery: this.state.search,
      sortColumn: this.state.sortColumn,
      sortDir: this.state.sortDir,
    };
 
    
    let isIncludeInactive = ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : null;
    if(isIncludeInactive){
        data['isIncludeInactive'] = isIncludeInactive;
    }

    let isInactive = ((this.state.isInactive === true) || (this.state.isInactive === false)) ? this.state.isInactive : null;
    if(isInactive){
        data['isInactive'] = isInactive;
    }


    if (this.state.filterParams && this.state.filterParams.length > 0) {
      data["filterParams"] = Utils.filterParams(this.state.filterParams);
    }
    if (this.state.searchOperator && this.state.searchOperator.length > 0) {
      data["searchOperator"] = Utils.getSelectedOperator(this.state.searchOperator);
    }

		this.props.dispatchApiCallPost(data, this.pageName + '-list', this.pageAPI + '/get-list', null, callback, () => {});

    if(withStatusCall){
      
    }
	}

  callReadByPageApi = (callback = null) => {
    let data = {
      currentPage: this.state.currentPage,
      pageSize: this.state.rowsPerPageSelected,
      searchQuery: this.state.search,
      sortColumn: this.state.sortColumn,
      sortDir: this.state.sortDir,
    };
 
    
    let isIncludeInactive = ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : null;
    if(isIncludeInactive){
        data['isIncludeInactive'] = isIncludeInactive;
    }

    let isInactive = ((this.state.isInactive === true) || (this.state.isInactive === false)) ? this.state.isInactive : null;
    if(isInactive){
        data['isInactive'] = isInactive;
    }


    if (this.state.filterParams && this.state.filterParams.length > 0) {
      data["filterParams"] = Utils.filterParams(this.state.filterParams);
    }
    if (this.state.searchOperator && this.state.searchOperator.length > 0) {
      data["searchOperator"] = Utils.getSelectedOperator(this.state.searchOperator);
    }

		this.props.dispatchApiCallPost(data, this.pageName + '-list_by_page', this.pageAPI + '/get-list', null, callback, null);
  }

  callReadAllApi = async (callback = null) => {
    await this.props.action.signalRslice.connect({
      callback: (percent) => {
        this.props.action.signalRslice.onPercentage(percent);
      },
      callbackInit: (hubId) => {}
    });

    let data = {
      currentPage: 1,
      pageSize: Utils.getMaxPageSize(),
      searchQuery: this.state.search,
      sortColumn: this.state.sortColumn,
      sortDir: this.state.sortDir,
      hubId: this.props.signalRslice.hubId,
    };

    let isIncludeInactive = ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : null;
    if(isIncludeInactive){
        data['isIncludeInactive'] = isIncludeInactive;
    }

    let isInactive = ((this.state.isInactive === true) || (this.state.isInactive === false)) ? this.state.isInactive : null;
    if(isInactive){
        data['isInactive'] = isInactive;
    }

		this.props.dispatchApiCallGet(data, this.pageName + '-list_all', this.pageAPI, null, callback, null);
  }

  callDownloadExcelAllApi = async (callback = null) => {
		await this.props.action.signalRslice.connect({
      callback: (percent) => {
        this.props.action.signalRslice.onPercentage(percent);
      },
      callbackInit: (hubId) => {}
    });
    
    let data = {
      currentPage: 1,
      pageSize: Utils.getMaxPageSize(),
      searchQuery: this.state.search,
      sortColumn: this.state.sortColumn,
      sortDir: this.state.sortDir,
      hubId: this.props.signalRslice.hubId,
    };

    
    let isIncludeInactive = ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : null;
    if(isIncludeInactive){
        data['isIncludeInactive'] = isIncludeInactive;
    }

    let isInactive = ((this.state.isInactive === true) || (this.state.isInactive === false)) ? this.state.isInactive : null;
    if(isInactive){
        data['isInactive'] = isInactive;
    }

		this.props.dispatchApiCallGet(data, this.pageName + '-list_download_excel', this.pageAPI + '/download-excel', null, callback, null);
  }

  callActivateApi = (active = false, callback = null) => {
		this.setState({
      isLoading: true,
    });

    let ids = (this.tableRef && this.tableRef.current) ? this.tableRef.current.getSelected().join(',') : '0';

    let data = {
      isActive: active,
      ids: ids,
    };
    
		this.props.dispatchApiCallPut(data, this.pageName + '-deactivate', this.pageAPI + '/deactivate', null, callback, null);
  }
  
  callUpdateApi = (data, callback = null) => {
    this.setState({
      isLoading: true,
    });

    this.props.dispatchApiCallPut(data, this.pageName + '-update_bulk', 'customer/bulk', data, callback, null);
  }
	/* END API */
	
	
	/* SECTIONS */
	topSection = () => {
		return <ViewTopSection
      hasChecked={(!this.state.isDisabledBatchAction)}
      unchecked={this.topSectionUnchecked()}
      checked={this.topSectionChecked()}
    />
	}
	topSectionUnchecked = () => {
    return <Row className={"align-items-center"}>
      <Col xs={12} md={'auto'}>
        <Dropdown alignRight>
          <Dropdown.Toggle as={DropdownToggle}>
            {Utils.getAllActiveInactiveText(this.state.isIncludeInactive, this.state.isInactive)} Customers
            <ArrowDropDownIcon />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              active={(this.state.isIncludeInactive && this.state.isInactive == null)}
              onClick={() => {
                this.setState({
                  isIncludeInactive: true,
                  isInactive: null,
                  currentPage: 1,
                }, () => {
                  this.callReadApi();
                });
              }} 
            >All</Dropdown.Item>
            <Dropdown.Item
              active={(this.state.isIncludeInactive == null && !this.state.isInactive)}
              onClick={() => {
                this.setState({
                  isIncludeInactive: null,
                  isInactive: false,
                  currentPage: 1,
                }, () => {
                  this.setFilterInLocalStorage();
                  this.callReadApi();
                });
              }} 
            >Active</Dropdown.Item>
            <Dropdown.Item
              active={(this.state.isIncludeInactive == null && this.state.isInactive)}
              onClick={() => {
                this.setState({
                  isIncludeInactive: null,
                  isInactive: true,
                  currentPage: 1,
                }, () => {
                  this.setFilterInLocalStorage();
                  this.callReadApi();
                });
              }} 
          >Inactive</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
      <Col xs={12} md={4} lg={3} className={'pb-2 pb-md-0'}>
        <IconButtonSearch 
          value={this.state.search}
          isOpen={(this.state.search !== '') ? true : false}
          onPressEnter={(value) => {
            this.setState({
              search: value,
              currentPage: 1,
            }, () => {
              this.setFilterInLocalStorage();
              this.callReadApi(true);
            });
          }}
          onClear={() => {
            this.setState({
              search: '',
              currentPage: 1,
            }, () => {
              this.setFilterInLocalStorage();
              this.callReadApi(true);
            });
          }}
        />
      </Col>
      <Col xs={true}></Col>
      <Col xs={'auto'} className={'text-center'}>
        <Button
          size={"sm"}
          onClick={() => {
            this.props.history.push('/Customer-form');
          }}
        >+ New</Button>
      </Col>
      <Col xs={'auto'} className={'text-center'}>
        <Dropdown>
          <Dropdown.Toggle as={DropdownToggle} isButton className={'px-3'} variant={"light"} size={"sm"}>
            <SettingsOutlinedIcon />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              active={false}
              onClick={() => {
                this.callDownloadExcelAllApi((data, isError, err) => {
                  if(!isError){
                    let type = 'application/vnd.ms-excel';
                    Utils.saveByteArray(this.pageCaption, data, type);
                  }
                });
              }} 
            >Download all in Excel format</Dropdown.Item>
            <Dropdown.Item
              active={false}
              onClick={() => {
                this.callReadAllApi((data, isError, err) => {
                   if(!isError){
                    this.tableRef.current.exportPDF(data);
                  }
                });
              }} 
            >Download all in PDF format</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
      <Col xs={'auto'} className={'text-center'}>
        <DropdownMenu
          className={'px-3'}
          variant={"light"}
          size={"sm"}
          text={<ViewHeadlineIcon />}
        >
          <DataTableActionMenu 
            pageName={this.pageName}
            columns={this.state.columns}
            selected={this.state.rowsPerPageSelected}
            selectItems={this.state.rowsPerPageOptions}
            isCheckedIncludeInactive={this.state.isIncludeInactive}
            columnVisibility={this.state.columnVisibility}
            canSelectOption={false}
            onChangeColumnVisibility={(index, value) => {
              let colVis = this.state.columnVisibility;
              colVis[index] = value;

              this.setState({
                columnVisibility: colVis
              }, () => {
                this.setFilterInLocalStorage();
              });
            }}
            canInactive={false}
            onSelectChangeAjax={(val) => {
              this.setState({
                currentPage: 1,
                rowsPerPageSelected: val
              }, () => {
                this.setFilterInLocalStorage();
                this.callReadApi();
              });
            }}
            onChangeIncludeInactive={(val) => {
              this.setState({
                isIncludeInactive: val,
                currentPage: 1,
              }, () => {
                this.setFilterInLocalStorage();
                this.callReadApi();
              });
            }}
            isCookies={this.state.isCookies}
            onResetColumns={() => {
              this.setState({
                columns: Array.from(this.state.initColumns),
                columnVisibility: Array.from(this.state.initColumnVisibility),
                sortColumn: this.state.sortColumnInit,
                sortDir: this.state.sortDirInit,
              }, () => {
                this.setFilterInLocalStorage(false);
                this.callReadApi();
                if(this.refDropdownActionMenu && this.refDropdownActionMenu.current){
                  this.refDropdownActionMenu.current.hide();
                }
              });
            }}
            onShowMore={(isShowedMore) => {
              this.setState({
                pageName: this.pageName,
              });
            }}
          />
        </DropdownMenu>
      </Col>
    </Row>
  }
	topSectionChecked = () => {
    return <Row className={"align-items-center"}>
      <Col xs={12} md={'auto'}>
        <Dropdown alignRight>
          <Dropdown.Toggle>
            Batch Action
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              active={false}
              onClick={() => {
                let selected = '';
                if(this.tableRef && this.tableRef.current){
                  let selectedArr = this.tableRef.current.getSelected();
                  if(selectedArr && selectedArr.length > 0){
                    selected = selectedArr.join(',');
                  }
                }

                this.setState({
                  popupShowBulkUpdate: true,
                  popupLoadingBulkUpdate: false,
                  popupIdBulkUpdate: selected,
                });
              }} 
            >Bulk Update</Dropdown.Item>
            <Dropdown.Item
              active={false}
              onClick={() => {
                this.callActivateApi(true, (data) => {
                  Utils.toast('Customer is activated!', 'success');

                  this.setState({
                    isDisabledBatchAction: !this.state.isDisabledBatchAction,
                  }, () => {
                    Utils.setSelected(this.tableRef);
                    this.callReadApi();
                  });
                });
              }} 
            >Mark As Active</Dropdown.Item>
            <Dropdown.Item
              active={false}
              onClick={() => {
                this.callActivateApi(false, (data) => {
                  Utils.toast('Customer is deactivated!', 'success');

                  this.setState({
                    isDisabledBatchAction: !this.state.isDisabledBatchAction,
                  }, () => {
                    Utils.setSelected(this.tableRef);
                    this.callReadApi();
                  });
                });
              }} 
            >Mark As Inactive</Dropdown.Item>
            {/* <Dropdown.Item
              disabled={true}
              active={false}
              onClick={() => {
                
              }} 
            >Delete</Dropdown.Item> */}
          </Dropdown.Menu>
        </Dropdown>
      </Col>
      <Col xs={12} md={true}>
        <span>{((this.tableRef && this.tableRef.current) ? this.tableRef.current.getSelected().length : '0')} item(s) selected</span>
      </Col>
      <Col xs={true}></Col>
      <Col xs={'auto'} className={'text-center'}>
        <WrappedButton
          className={'px-3 pe-1'}
          variant={"light"}
          size={'sm'}
          style={{ backgroundColor: 'transparent' }}
          onClick={() => {
            if(this.tableRef && this.tableRef.current){
              this.tableRef.current.setSelected([], () => {
                this.setState({
                  isDisabledBatchAction: true,
                });
              });
            }
          }}
        >
          <i className={'material-icons'}>close</i>
        </WrappedButton>
      </Col>
    </Row>
  }

	tableSection = () => {
		return <DataTableScrollSync ref={this.refDataTableScrollSync} className={"table-sync-v4"}>{this.setDataTable()}</DataTableScrollSync>
	}
  setDataTable = () => {
    return <DataTable 
      ref={this.tableRef}

      className={"table-style-v4"}
      classNameWrapper={"table-v4"}

      pageName={this.pageName}
      rowId={this.pageIdField}
      caption={this.pageCaption}
      
      noMarginBottom={false}
      verticalAlign={'middle'}
      
      stickyHeader={false}
      isHeader={true}
      resizable={true}
      isDragableHeaderColumns={true}
      isDragable={false}
      isCheckbox={true}
      isBorder={false}
      isPagination={false}
      
      columnVisibility={this.state.columnVisibility}
      disabledResizeColumns={[0]}

      isLoading={this.state.isLoading}
      rowsPerPageSelected={this.state.rowsPerPageSelected}
      rowsPerPageOptions={this.state.rowsPerPageOptions}

      search={this.state.search}
      rows={this.state.rows}
      columns={this.state.columns}

      onRowClick={(id, row) => {
        this.props.history.push('/Customer-overview?id=' + id);
      }}

      onRemove={(row) => {

      }}

      onClickCheckAll={(obj, checked, isDisabledBatchAction) => {
        this.setState({
          isDisabledBatchAction: isDisabledBatchAction
        });
      }}
      onCheckRow={(obj, id, isDisabledBatchAction) => {
        this.setState({
          isDisabledBatchAction: isDisabledBatchAction
        });
      }}
      
      onSortEnd={(columns, columnVisibility) => {
        this.setState({
          columns: columns,
          columnVisibility: columnVisibility,
        }, () => {
          this.setFilterInLocalStorage();
        });
      }}
      onOrder={(name, sort) => {
        if(this.tableRef && this.tableRef.current){
          this.tableRef.current.setSelected([], () => {
            this.setState({
              isDisabledBatchAction: true,
            });
          });
        }
        
        this.setState({
          sortColumn: name,
          sortDir: sort,
        }, () => {
          this.setFilterInLocalStorage();
          this.callReadApi(false);
        });
      }}
      onResize={(columns) => {
        if(columns){
          this.setState({
            columns: columns
          });
        }
        this.setFilterInLocalStorage();
      }}
    />
  }

	paginationSection = () => {
		return <DataTableAjaxPagination
      page={this.state.currentPage}
      selected={this.state.rowsPerPageSelected}
      count={this.state.totalRows}
      fixed={true}
      onPageChange={(page) => {
        this.setState({
          currentPage: page,
        }, () => {
          this.setFilterInLocalStorage();
          this.callReadApi(false);
        });
      }}
      onOptionsChange={(option) => {
        this.setState({
          currentPage: 1,
          rowsPerPageSelected: option,
        }, () => {
          this.setFilterInLocalStorage();
          this.callReadApi(false);
        });
      }}
    />
	}
	/* END SECTIONS */


	/* DIALOG */
  setPopupBulkUpdate = () => {
    return <Popup 
      className={'no-bg'}
      auth={this.props.auth}
      settingsType={this.props.settingsType}

      show={this.state.popupShowBulkUpdate}
      isLoading={this.state.popupLoadingBulkUpdate}
      id={this.state.popupIdBulkUpdate}

      showHeader={true}
      header={'Bulk Update - Customer'}
      showFooter={true}
      centerFooter={'center'}
      footer={({ isLoading, id, onHide, onSave }) => {
        return <Row>
          <Col xs={'auto'}>
            <LoadingButton
              isLoading={isLoading}
              onClick={onSave}
            >Save</LoadingButton>
          </Col>
          <Col xs={'auto'}>
            <Button
              variant={'light'}
              disabled={isLoading}
              onClick={onHide}
            >Cancel</Button>
          </Col>
        </Row>
      }}

      scrollable={true}
      centered={true}
      closeButton={true}
      fullscreen={false}
      noBodyPadding={false}
      size={'lg'}

      onHide={() => {
        this.setState({
          popupShowBulkUpdate: false,
          popupLoadingBulkUpdate: false,
          popupIdBulkUpdate: null,
        });
      }}
      onSave={() => {
        if(this.refBulkUpdate && this.refBulkUpdate.current){
          if(this.refBulkUpdate.current.refForm && this.refBulkUpdate.current.refForm.current){
            this.refBulkUpdate.current.refForm.current.handleSubmit();
          }
        }
      }}
      
      onLoading={(state) => {
        this.setState({
          popupLoadingBulkUpdate: state,
        });
      }}
      onSubmit={(form) => {
        this.setState({
          popupLoadingBulkUpdate: true,
        }, () => {
          this.callUpdateApi(form, (res) => {
            this.setState({
              popupShowBulkUpdate: false,
              popupLoadingBulkUpdate: false,
              popupIdBulkUpdate: null,
            }, () => {
              if(this.tableRef && this.tableRef.current){
                this.tableRef.current.setSelected([], () => {
                  this.setState({
                    isDisabledBatchAction: true,
                  });
                });
              }

              this.callReadApi();
            });
          });
        });
      }}
    >
      {(props) => {
        return <BulkUpdateCustomerForm
          ref={this.refBulkUpdate}
          {...props}
        />
      }}
    </Popup>
  }
	/* END DIALOG */

	
  render() {
    return <div className={'customers-page'}>
      <ViewLayout
        topSection={this.topSection()}
        tableSection={this.tableSection()}
        paginationSection={this.paginationSection()}
      />
      {this.setPopupBulkUpdate()}
    </div>
  }
}


const mapStateToProps = state => {
  return {
    ...state,
    ...Utils.mapStateToProps(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  dispatchApiCallGet: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallGet(p1, p2, p3, p4, p5, p6)),
  dispatchApiCallPost: (p1, p2, p3, p4, p5, p6, p7) => dispatch(dispatchApiCallPost(p1, p2, p3, p4, p5, p6, p7)),
  dispatchApiCallPut: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallPut(p1, p2, p3, p4, p5, p6)),
  dispatchApiCallDelete: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallDelete(p1, p2, p3, p4, p5, p6)),
  saveUserCookieSettings: (payload) => dispatch(saveUserCookieSettings(payload)),
  clear: (payload) => dispatch(clear(payload)),
  action: {
    signalRslice: {
      connect: (payload) => dispatch(signalRslice.connect(payload)),
      disconnect: (payload) => dispatch(signalRslice.disconnect(payload)),
      onPercentage: (payload) => dispatch(signalRslice.onPercentage(payload)),
    },
    cancelToken: {
      cancel: (payload) => dispatch(cancelToken.cancel(payload)),
    },
  }
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CustomerList);
