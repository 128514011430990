/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Yup from 'yup';
import _ from 'lodash';
import moment, { Moment } from 'moment';

import * as dispatchCrudApi from '../../../../api/CRUD/DispatchCRUD'
import Utils from "../../../../utils/utils";
// import {  } from "../../../../utils/enums";

import { ParseResult } from "../../../../utils/interfaces";


export const fields = {
  driverName: {
    id: 'driverName',
    label: 'Name',
    placeholder: ' ',
  },
  idNumber: {
    id: 'idNumber',
    label: 'Identification No.',
    placeholder: ' ',
  },
  phoneNumber: {
    id: 'phoneNumber',
    label: 'Mobile Phone',
    placeholder: ' ',
  },
  homeAddress: {
    id: 'homeAddress',
    label: 'Home Address',
    placeholder: Utils.placeholderRows(5),
  },
  isDeactivateOrDelete: {
    id: 'isDeactivateOrDelete',
    label: 'Status',
    placeholder: ' ',
  },
  driverCategoryId: {
    id: 'driverCategoryId',
    label: 'Category',
    placeholder: ' ',
  },
  vehicleId: {
    id: 'vehicleId',
    label: 'Default vehicle',
    placeholder: ' ',
  },
  driverEmploymentTypeId: {
    id: 'driverEmploymentTypeId',
    label: 'Employment type',
    placeholder: ' ',
  },
  dateResigned: {
    id: 'dateResigned',
    label: 'Date resigned',
    placeholder: ' ',
  },
  dateJoined: {
    id: 'dateJoined',
    label: 'Date joined',
    placeholder: ' ',
  },
  isAssignJobToPhone: {
    id: 'isAssignJobToPhone',
    label: 'Access to Driver mobile app',
    placeholder: ' ',
  },
  userName: {
    id: 'userName',
    label: 'Username',
    placeholder: ' ',
  },
  password: {
    id: 'password',
    label: 'Password',
    placeholder: ' ',
    info: 'The password must contain at least six letters or numbers'
  },
  
  payrollTemplateId: {
    id: 'payrollTemplateId',
    label: 'Select payroll type',
    placeholder: ' ',
  },
  remarks: {
    id: 'remarks',
    label: ' ',
    placeholder: 'Insert remarks',
    placeholderInTable: ' ',
  },
  isLocked: {
    id: 'isLocked',
    label: ' ',
    placeholder: ' ',
  },
  workDate: {
    id: 'workDate',
    label: ' ',
    placeholder: ' ',
  },
  workTime: {
    id: 'workTime',
    label: '',
    placeholder: ' ',
  },
  jobType: {
    id: 'jobType',
    label: '',
    placeholder: ' ',
  },
  customerName: {
    id: 'customerName',
    label: '',
    placeholder: ' ',
  },
  site: {
    id: 'site',
    label: '',
    placeholder: ' ',
  },
  jobNumber: {
    id: 'jobNumber',
    label: '',
    placeholder: ' ',
  },
  geofenceName: {
    id: 'geofenceName',
    label: '',
    placeholder: ' ',
  },
  geofenceGroup: {
    id: 'geofenceGroup',
    label: '',
    placeholder: ' ',
  },
  tripPay: {
    id: 'tripPay',
    label: '',
    placeholder: ' ',
  },
  incentivePay: {
    id: 'incentivePay',
    label: '',
    placeholder: ' ',
  },

  description: {
    id: 'description',
    label: '',
    placeholder: ' ',
  },
  otherPay: {
    id: 'otherPay',
    label: '',
    placeholder: ' ',
  },
};

export const formSchema = (id: number|null= null, isMobileDispatch: boolean = false, tab: string) => {
  return Yup.object().shape({
    driverName: Yup.string().when(['isAssignJobToPhone'], (isAssignJobToPhone) => {
      if(!isMobileDispatch){
        return Yup.string().nullable().required().label(fields.driverName.label);
      } else {
        return Yup.string().nullable().label(fields.driverName.label);
      }
    }),

    idNumber: Yup.string().nullable().label(fields.idNumber.label),
    phoneNumber: Yup.string().nullable().label(fields.phoneNumber.label),
    homeAddress: Yup.string().nullable().label(fields.phoneNumber.label),
    isDeactivateOrDelete: Yup.bool().oneOf([true, false]).label(fields.isDeactivateOrDelete.label),
    driverCategoryId: Yup.number().nullable().label(fields.driverCategoryId.label),
    vehicleId: Yup.number().nullable().label(fields.vehicleId.label),
    driverEmploymentTypeId: Yup.number().nullable().label(fields.driverEmploymentTypeId.label),
    dateResigned: Yup.date().nullable().label(fields.dateResigned.label),
    dateJoined: Yup.date().nullable().label(fields.dateJoined.label),
    payrollTemplateId: Yup.number().nullable().label(fields.payrollTemplateId.label),

    isAssignJobToPhone: Yup.bool().oneOf([true, false]),
    userName: Yup.string().when(['isAssignJobToPhone'], (isAssignJobToPhone) => {
      if(isAssignJobToPhone){
        return Yup.string().nullable().required().label(fields.userName.label);
      } else {
        return Yup.string().nullable().label(fields.userName.label);
      }
    }),
    password: Yup.string().when(['isAssignJobToPhone'], (isAssignJobToPhone) => {
      if(!id && isAssignJobToPhone){
        return Yup.string().nullable().required().min(6).matches(/^[a-zA-Z0-9]{6,}$/g, fields.password.info).label(fields.password.label);
      } else {
        return Yup.string().min(6).matches(/^[a-zA-Z0-9]{6,}$/g, fields.password.info).label(fields.password.label);
      }
    }),

    data: Yup.object().nullable().shape({
      isAdHoc: Yup.bool().oneOf([true, false]),
      payrollTemplateId: Yup.number().nullable().label(fields.payrollTemplateId.label),
      remarks: Yup.string().nullable().label(fields.remarks.label),
      isLocked: Yup.bool().oneOf([true, false]).label(fields.isLocked.label),

      days: Yup.array().of(Yup.object().shape({
        details: Yup.array().of(Yup.object().shape({
          workDate: Yup.string().nullable()
            .matches(/^\d{2}-\d{2}-\d{4}$/, 'Enter the correct date format (dd-mm-yyyy)')
            .test('isValidDate', 'Date is not valid', function (value) {
              if (!value) {
                return true;
              }

              const dateFormat = 'DD-MM-YYYY';
              const parsedDate = moment(value, dateFormat, true);

              return parsedDate.isValid();
            })
            .label(fields.workDate.label),
          workTime: Yup.string().nullable()
            // .matches(/^([01][0-9]|2[0-3]):[0-5][0-9]$/, 'Enter the correct time format (hh:mm)')
            .label(fields.workTime.label),
          jobType: Yup.string().nullable().label(fields.jobType.label),
          customerName: Yup.string().nullable().label(fields.customerName.label),
          site: Yup.string().nullable().label(fields.site.label),
          jobNumber: Yup.string().nullable().label(fields.jobNumber.label),
          geofenceName: Yup.string().nullable().label(fields.geofenceName.label),
          geofenceGroup: Yup.string().nullable().label(fields.geofenceGroup.label),
          tripPay: Yup.number().nullable().min(0).label(fields.tripPay.label),
          incentivePay: Yup.number().nullable().min(0).label(fields.incentivePay.label),
          remarks: Yup.string().nullable().label(fields.remarks.label),
        }))
      }))
      .test('at-least-one-field', 'At least one field is required', function (values) {
        if (!Array.isArray(values) || values.length <= 0)
          return true;

        const { isAdHoc } = this.parent;

        if(tab === 'tab2'){
          if(!isAdHoc){
            let errors: any = [];
            values.forEach((item: any, i: number) => {
              if(item && item.details && item.details.length > 0){
                item.details.forEach((subitem: any, j: number) => {
                  if(Utils.isEmptyRow(subitem, ['workDate', 'workTime', 'jobType', 'customerName', 'site', 'jobNumber', 'geofenceName', 'geofenceGroup', 'tripPay', 'incentivePay', 'remarks'])) {
                    let subErrors = {
                      workDate: 'Empty field',
                      workTime: 'Empty field',
                      jobType: 'Empty field',
                      customerName: 'Empty field',
                      site: 'Empty field',
                      jobNumber: 'Empty field',
                      geofenceName: 'Empty field',
                      geofenceGroup: 'Empty field',
                      tripPay: 'Empty field',
                      incentivePay: 'Empty field',
                      remarks: 'Empty field',
                    };
                    if (!errors[i]) { errors[i] = {} }
                    errors[i]['details'] = errors[i]['details'] || [];
                    errors[i]['details'][j] = errors[i]['details'][j] || {};
                    errors[i]['details'][j] = subErrors;
                  }
                });
              }
            });

            if(errors.length > 0){
              return this.createError({
                message: () => [errors]
              })
            }
          }
        }

        return true;
      }),

      adhocDetails: Yup.array().of(Yup.object().shape({
        description: Yup.string().nullable().label(fields.tripPay.label),
        otherPay: Yup.number().nullable().min(0).label(fields.incentivePay.label),
      }))
      .test('at-least-one-field-adhoc', 'At least one field is required', function (values) {
        if (!Array.isArray(values) || values.length <= 0)
          return true;

        const { isAdHoc } = this.parent;

        if(tab === 'tab2'){
          if(isAdHoc){
            let errors: any = [];
            values.forEach((item: any, i: number) => {
              if(Utils.isEmptyRow(item, ['description', 'otherPay'])) {
                errors[i] = {
                  description: 'Empty field',
                  otherPay: 'Empty field',
                };
              }
            });

            if(errors.length > 0){
              return this.createError({
                message: () => [errors]
              })
            }
          }
        }

        return true;
      }),
    }),
  })
}

export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    const formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const prepareForm = (values: any = null, defValues: any = null) => {
  let form = _.cloneDeep(values);
  let data = _.cloneDeep(defValues);
  
  if(data && form ){
    let driverName = (form.driverName && form.driverName !== '') ? form.driverName : '';

    let category = (form.category) ? form.category : null;
    let driverCategoryId = (category && category.driverCategoryId) ? category.driverCategoryId : null;
    let driverCategoryName = (category && category.driverCategoryName) ? category.driverCategoryName : '';
    
    let defaultVehicle = (form.defaultVehicle) ? form.defaultVehicle : null;
    let vehicleId = (defaultVehicle && defaultVehicle.vehicleId) ? defaultVehicle.vehicleId : null;
    let vehicleName = (defaultVehicle && defaultVehicle.vehicleName) ? defaultVehicle.vehicleName : '';

    let employmentType = (form.employmentType) ? form.employmentType : null;
    let driverEmploymentTypeId = (employmentType && employmentType.driverEmploymentTypeId) ? employmentType.driverEmploymentTypeId : null;
    let employmentTypeName = (employmentType && employmentType.employmentTypeName) ? employmentType.employmentTypeName : '';

    let isDeactivateOrDelete = ((form.isDeactivateOrDelete === false) || (form.isDeactivateOrDelete === true)) ? form.isDeactivateOrDelete : false;
    let isAssignJobToPhone = ((form.isAssignJobToPhone === false) || (form.isAssignJobToPhone === true)) ? form.isAssignJobToPhone : false;
    let userName = (form.userName && form.userName !== '') ? form.userName : '';
    let password = (form.password && form.password !== '') ? form.password : '';

    let idNumber = (form.idNumber && form.idNumber !== '') ? form.idNumber : '';
    let phoneNumber = (form.phoneNumber && form.phoneNumber !== '') ? form.phoneNumber : '';
    let homeAddress = (form.homeAddress && form.homeAddress !== '') ? form.homeAddress : '';

    let dateResigned = (form.dateResigned) ? moment(form.dateResigned).toDate() : null;
    let dateJoined = (form.dateJoined) ? moment(form.dateJoined).toDate() : null;
    
    let payrollTemplate = (form.payrollTemplate) ? form.payrollTemplate : null;
    let payrollTemplateId = (payrollTemplate && payrollTemplate.payrollTemplateId) ? payrollTemplate.payrollTemplateId : null;
    let templateName = (payrollTemplate && payrollTemplate.templateName) ? payrollTemplate.templateName : '';

    
    data['driverName'] = driverName;
    data['driverCategoryId'] = driverCategoryId;
    data['driverCategoryName'] = driverCategoryName;
    data['vehicleId'] = vehicleId;
    data['vehicleName'] = vehicleName;
    data['isAssignJobToPhone'] = isAssignJobToPhone;
    data['userName'] = userName;
    data['password'] = password;
    data['payrollTemplateId'] = payrollTemplateId;
    data['payrollTemplateName'] = templateName;
    data['isDeactivateOrDelete'] = isDeactivateOrDelete;
    data['idNumber'] = idNumber;
    data['phoneNumber'] = phoneNumber;
    data['homeAddress'] = homeAddress;
    data['dateResigned'] = dateResigned;
    data['dateJoined'] = dateJoined;
    data['driverEmploymentTypeId'] = driverEmploymentTypeId;
    data['employmentTypeName'] = employmentTypeName;
  }
  
  return data;
};
export const prepareData = (values: any = null) => {
  let data: any = {};

  if(values){
    data['driverName'] = values.driverName;
    data['isAssignJobToPhone'] = values.isAssignJobToPhone;
    data['isDeactivateOrDelete'] = values.isDeactivateOrDelete;
    data['idNumber'] = values.idNumber;
    data['phoneNumber'] = values.phoneNumber;
    data['homeAddress'] = values.homeAddress;
    data['dateResigned'] = values.dateResigned ? moment.utc(values.dateResigned).toDate() : null;
    data['dateJoined'] = values.dateJoined ? moment.utc(values.dateJoined).toDate() : null;
    data['driverCategoryId'] = values.driverCategoryId;
    data['driverEmploymentTypeId'] = values.driverEmploymentTypeId;
    data['payrollTemplateId'] = values.payrollTemplateId;

    if(values.isAssignJobToPhone){
      data['userName'] = values.userName;
      data['password'] = values.password;
    }

    if(values.vehicleId && values.vehicleId > 0){
      data['defaultVehicle'] = {
        vehicleId: values.vehicleId
      }
    }
  }

  return data;
};


export const getPrevDriver = (index: number, listDriver: Array<any>) => {
  let arr = (listDriver && listDriver.length > 0) ? listDriver : [];

  let indexDriver = index;
  if(index > 0){
    indexDriver = index-1;
  } else {
    indexDriver = arr.length-1;
  }

  return (arr && arr.length > 0 && arr[indexDriver] && arr[indexDriver].driverId) ? arr[indexDriver] : null;
}
export const getNextDriver = (index: number, listDriver: Array<any>) => {
  let arr = (listDriver && listDriver.length > 0) ? listDriver : [];
  
  let indexDriver = index;
  if(index < arr.length-1){
    indexDriver = index+1;
  } else {
    indexDriver = 0;
  }

  return (arr && arr.length > 0 && arr[indexDriver] && arr[indexDriver].driverId) ? arr[indexDriver] : null;
}

export const getYears = (dispatchUser: any) => {
  const startYear = (dispatchUser && dispatchUser.activatedAt && dispatchUser.activatedAt != '') ? moment(dispatchUser.activatedAt) : moment().year(2023).startOf('month');
  const currentYear = moment();
  const years = [];

  let start = (startYear.year() <= 2023) ? 2023 : startYear.year();
  let end = currentYear.year();
  let between = end - start;

  let count = 0;
  for (let i = end; i >= start; i--) {
    if(count === 0){
      years.push(currentYear);
    } else if(count === between){
      years.push(startYear.year(start));
    } else {
      const year = moment().year(i).endOf('year');
      years.push(year);
    }

    count++;
  }

  return years;
}
export const getYearInfo = (dispatchUser: any, year: any) => {
  if(year == ''){
    year = moment().format('L')
  }

  const startYear = (dispatchUser && dispatchUser.activatedAt && dispatchUser.activatedAt != '') ? moment(dispatchUser.activatedAt) : moment().year(2023).startOf('month');
  const currentYear = moment();

  if(moment(year).isSame(moment(currentYear.format('L')))){
    return 'end'
  } else if(moment(year).isSame(moment(startYear.format('L')))){
    return 'start'
  } else {
    return 'between'
  }
}
export const getMonthsByYear = (dispatchUser: any, year: any) => {
  if(year == ''){
    year = moment().format('L')
  }

  let mounthCount = 12;
  const parsedYear = moment(year).year();
  let yearInfo = getYearInfo(dispatchUser, year);

  if(yearInfo == 'end'){
    const months: MonthStruct[] = [];
    mounthCount = moment(year).month()+1;

    for (let i = 0; i < mounthCount; i++) {
      const month = moment().year(parsedYear).month(i).startOf('month');
      months.push({
        date: month,
        isOpen: false,
        details: null,
        isLoading: false,
        isReRun: false,
        startDate: '',
        endDate: '',
      });
    }

    return months;

  } else if(yearInfo == 'start'){
    const months: MonthStruct[] = [];
    mounthCount = moment(year).month();

    for (let i = mounthCount; i < 12; i++) {
      const month = moment().year(parsedYear).month(i).startOf('month');
      months.push({
        date: month,
        isOpen: false,
        details: null,
        isLoading: false,
        isReRun: false,
        startDate: '',
        endDate: '',
      });
    }

    return months;

  } else {
    const months: MonthStruct[] = [];
    for (let i = 0; i < mounthCount; i++) {
      const month = moment().year(parsedYear).month(i).startOf('month');
      months.push({
        date: month,
        isOpen: false,
        details: null,
        isLoading: false,
        isReRun: false,
        startDate: '',
        endDate: '',
      });
    }

    return months;
  }
}
export const isValidDates = (item: any) => {
  if(item){
    let startDate = Utils.isNumber(item.startDate) ? parseInt(item.startDate) : 0;
    let endDate = Utils.isNumber(item.endDate) ? parseInt(item.endDate) : 0;

    if(!Utils.isNumberAvoidZero(startDate)){
      return false;
    }

    if(!Utils.isNumberAvoidZero(endDate)){
      return false;
    }

    if(startDate > endDate){
      return false;
    }
  }

  return true;
}


export interface initialValuesStruct {
  driverName: string,
  idNumber: string,
  driverCategoryId: number|null,
  driverCategoryName: string,
  vehicleId: number|null,
  vehicleName: string,
  driverEmploymentTypeId: number|null,
  employmentTypeName: string,
  dateResigned: Date|null,
  dateJoined: Date|null,
  isAssignJobToPhone: boolean,
  userName: string,
  password: string,
  payrollTemplateId: number|null,
  payrollTemplateName: string,
  isDeactivateOrDelete: boolean,

  data: any,
};
export const initialValues: initialValuesStruct = {
  driverName: '',
  idNumber: '',
  driverCategoryId: null,
  driverCategoryName: '',
  vehicleId: null,
  vehicleName: '',
  driverEmploymentTypeId: null,
  employmentTypeName: '',
  dateResigned: null,
  dateJoined: null,
  isAssignJobToPhone: false,
  userName: '',
  password: '',
  payrollTemplateId: null,
  payrollTemplateName: '',
  isDeactivateOrDelete: false,
  
  data: null,
};


export interface MonthlyRowStruct {
  workDate: string,
  workTime: string,
  jobType: string,
  customerName: string,
  site: string,
  jobNumber: string,
  geofenceName: string,
  geofenceGroup: string,
  tripPay: number|null,
  incentivePay: number|null,
  remarks: string,
};
export const monthlyRow: MonthlyRowStruct = {
  workDate: '',
  workTime: '',
  jobType: '',
  customerName: '',
  site: '',
  jobNumber: '',
  geofenceName: '',
  geofenceGroup: '',
  tripPay: null,
  incentivePay: null,
  remarks: '',
};

export interface AdHocRowStruct {
  description: string|null,
  otherPay: number|null,
};
export const adHocRow: AdHocRowStruct = {
  description: '',
  otherPay: null,
};


export interface MonthStruct {
  date: Moment,
  isOpen: boolean,
  details: any,
  isLoading: boolean,
  isReRun: boolean,
  startDate: string,
  endDate: string,
};
interface InitState {
  isLoading: boolean,
  isMobileDispatch: boolean,
  show: boolean,
  id: any|null,
  details: any,

  isLoadingDriver: boolean,
  listDriver: Array<any>,
  indexDriver: number,

  years: Array<any>,
  year: any,
  months: Array<MonthStruct>,
}

function NewReducer() {
  const name = 'driversDetails';


  const initialState: InitState = {
    isLoading: false,
    isMobileDispatch: false,
    show: false,
    id: null,
    details: initialValues,

    isLoadingDriver: false,
    listDriver: [],
    indexDriver: 0,

    years: [],
    year: '',
    months: [],
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },

    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setId: (state: InitState, action: PayloadAction<any|null>) => {
      state.id = action.payload;
      state.show = false;
      state.isMobileDispatch = false;
      
      state.isLoadingDriver = false;
      state.listDriver = [];
      state.indexDriver = 0;

      state.years = [];
      state.year = '';
      state.months = [];
    },
    setShow: (state: InitState, action: PayloadAction<{ show: boolean, id: any|null, isMobileDispatch: boolean}>) => {
      state.id = action.payload.id;
      state.show = action.payload.show;
      state.isMobileDispatch = action.payload.isMobileDispatch;
      
      state.isLoadingDriver = false;
      state.listDriver = [];
      state.indexDriver = 0;

      state.years = [];
      state.year = '';
      state.months = [];
    },
    setValues: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
    },

    prevDriver: (state: InitState) => {
      let index = state.indexDriver;
      let arr = (state.listDriver && state.listDriver.length > 0) ? state.listDriver : [];

      let indexDriver = state.indexDriver;
      if(index > 0){
        indexDriver = index-1;
      } else {
        indexDriver = arr.length-1;
      }

      state.indexDriver = indexDriver;
      state.id = arr[indexDriver].driverId;
    },
    nextDriver: (state: InitState) => {
      let index = state.indexDriver;
      let arr = (state.listDriver && state.listDriver.length > 0) ? state.listDriver : [];
      
      let indexDriver = state.indexDriver;
      if(index < arr.length-1){
        indexDriver = index+1;
      } else {
        indexDriver = 0;
      }

      state.indexDriver = indexDriver;
      state.id = arr[indexDriver].driverId;
    },

    setYears: (state: InitState, action: PayloadAction<any>) => {
      let years = (action.payload && action.payload.length > 0) ? action.payload : [];
      let year = (years && years.length > 0) ? years[0].format('L') : '';

      state.years = years;
      state.year = year;
    },
    setYear: (state: InitState, action: PayloadAction<any>) => {
      state.year = action.payload;
    },
    setMonths: (state: InitState, action: PayloadAction<any>) => {
      let months = (action.payload && action.payload.length > 0) ? action.payload : [];
      state.months = months;
    },


    startRead: (state: InitState) => {
      state.isLoadingDriver = true;
    },
    finishRead: (state: InitState, action: PayloadAction<any>) => {
      let arr = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      let index = state.id ? arr.findIndex((x: any) => x.driverId === state.id) : 0;

      state.listDriver = arr;
      state.indexDriver = index
      state.isLoadingDriver = false;
    },

    startDetails: (state: InitState) => {
      state.isLoading = true;
    },
    finishDetails: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload
      state.isLoading = false;
    },
        
    startCreate: (state: InitState) => {
      state.isLoading = true;
    },
    finishCreate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },
        
    startUpdate: (state: InitState) => {
      state.isLoading = true;
    },
    finishUpdate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },

    startMonthDetails: (state: InitState, action: PayloadAction<{ i: number, shouldCollapse: boolean, shouldClearDetails: boolean }>) => {
      let index = action.payload.i;
      if(index > -1){
        let months = state.months;
        months[index].isOpen = action.payload.shouldCollapse;
        if(action.payload.shouldClearDetails){
          months[index].details = null;
        }
        months[index].isLoading = true;
      }
    },
    finishMonthDetails: (state: InitState, action: PayloadAction<{ data: any, i: number }>) => {
      let index = action.payload.i;
      if(index > -1){
        let months = state.months;
        months[index].isOpen = true;
        months[index].details = action.payload.data;
        months[index].isLoading = false;
      }
    },
        
    startGenerate: (state: InitState, action: PayloadAction<number>) => {
      let index = action.payload;
      if(index > -1){
        let months = state.months;
        months[index].details = null;
        months[index].isLoading = true;
      }
    },
    finishGenerate: (state: InitState, action: PayloadAction<{ data: any, i: number }>) => {
      let index = action.payload.i;
      if(index > -1){
        let months = state.months;
        months[index].details = action.payload.data;
        months[index].isLoading = false;
      }
    },

    startBatchGenerate: (state: InitState) => {
      
    },
    finishBatchGenerate: (state: InitState, action: PayloadAction<any>) => {
      
    },
        
    startReGenerate: (state: InitState, action: PayloadAction<number>) => {
      let index = action.payload;
      if(index > -1){
        let months = state.months;
        months[index].isLoading = true;
      }
    },
    finishReGenerate: (state: InitState, action: PayloadAction<{ data: any, i: number }>) => {
      let index = action.payload.i;
      if(index > -1){
        let months = state.months;
        months[index].details = action.payload.data;
        months[index].isLoading = false;
      }
    },
    finishErrorReGenerate: (state: InitState, action: PayloadAction<{ data: any, i: number }>) => {
      let index = action.payload.i;
      if(index > -1){
        let months = state.months;
        months[index].isLoading = false;
      }
    },
    
    startLock: (state: InitState, action: PayloadAction<number>) => {
      let index = action.payload;
      // if(index > -1){
      //   let months = state.months;
      //   months[index].isLoading = true;
      // }
    },
    finishLock: (state: InitState, action: PayloadAction<{ data: any, i: number }>) => {
      let index = action.payload.i;
      if(index > -1){
        let months = state.months;
        months[index].details = action.payload.data;
        // months[index].isLoading = false;
      }
    },
    
    startRemarks: (state: InitState, action: PayloadAction<number>) => {
      let index = action.payload;
      // if(index > -1){
      //   let months = state.months;
      //   months[index].isLoading = true;
      // }
    },
    finishRemarks: (state: InitState, action: PayloadAction<{ data: any, i: number }>) => {
      let index = action.payload.i;
      if(index > -1){
        let months = state.months;
        months[index].details = action.payload.data;
        // months[index].isLoading = false;
      }
    },

    startExport: (state: InitState) => {
      state.isLoading = true;
    },
    finishExport: (state: InitState) => {
      state.isLoading = false;
    },
        
    startCreateRow: (state: InitState) => {
      state.isLoading = true;
    },
    finishCreateRow: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },
        
    startUpdateRow: (state: InitState) => {
      state.isLoading = true;
    },
    finishUpdateRow: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },
    
    startDeleteRow: (state: InitState) => {
      state.isLoading = true;
    },
    finishDeleteRow: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },
        
    startCreateAdHocRow: (state: InitState) => {
      state.isLoading = true;
    },
    finishCreateAdHocRow: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },
        
    startUpdateAdHocRow: (state: InitState) => {
      state.isLoading = true;
    },
    finishUpdateAdHocRow: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },
    
    startDeleteAdHocRow: (state: InitState) => {
      state.isLoading = true;
    },
    finishDeleteAdHocRow: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },
        
    startBatchActive: (state: InitState) => {
      
    },
    finishBatchActive: (state: InitState, action: PayloadAction<any>) => {
      
    },
  };


  const apis = {
    callReadApi: (sortColumn: string|null, sortDir: string|null) => async (dispatch: any) => {
      dispatch(actions.startRead());

      let params: any = { sortColumn, sortDir };

      await dispatchCrudApi.readApi(params, 'driver').then(result => {
        let data = result.data;
        
        dispatch(actions.finishRead(data));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        dispatch(actions.finishRead(null));
      });
    },

    callDetailsApi: (id: number) => async (dispatch: any) => {
      dispatch(actions.startDetails());

      await dispatchCrudApi.readApi(null, 'driver/' + id).then(result => {
        let data = result.data;
        
        dispatch(actions.finishDetails(data));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        dispatch(actions.finishDetails(null));
      });
    },
    
    callCreateApi: (params: any, callback: (state: boolean) => void) => async (dispatch: any) => {
      dispatch(actions.startCreate());

      await dispatchCrudApi.createApi(params, 'driver').then(result => {
        let data = result.data.data;
        
        callback(true);
        dispatch(actions.finishCreate(data));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false);
        dispatch(actions.finishCreate(null));
      });
    },
    
    callUpdateApi: (params: any, callback: (state: boolean) => void) => async (dispatch: any) => {
      dispatch(actions.startUpdate());

      await dispatchCrudApi.updateApi(params, 'driver').then(result => {
        let data = result.data.data;
        
        callback(true);
        dispatch(actions.finishUpdate(data));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }

        callback(false);
        dispatch(actions.finishUpdate(null));
      });
    },

    callMonthDetailsApi: (date: any, i: number, shouldCollapse: boolean = false, shouldClearDetails = true, callback: (state: boolean, data: any) => void) => async (dispatch: any, getState: any) => {
      const { id } = getState().driversDetails;

      dispatch(actions.startMonthDetails({ i, shouldCollapse, shouldClearDetails}));

      await dispatchCrudApi.readApi(null, 'driver/' + id + '/payroll/' + moment(date).year() + '/' + (moment(date).month()+1)).then(result => {
        let data = (result && result.data && result.data != '') ? result.data : null;
        
        callback(true, data);
        dispatch(actions.finishMonthDetails({ data, i }));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false, null);
        dispatch(actions.finishMonthDetails({ data: null, i: -1 }));
      });
    },
    
    callGenerateApi: (hubId: any, date: any, i: number, payrollTemplateId: number|null, callback: (state: boolean) => void) => async (dispatch: any, getState: any) => {
      const { id } = getState().driversDetails;

      dispatch(actions.startGenerate(i));

      let params = {
        driverId: id,
        payrollTemplateId: payrollTemplateId,
        year: moment(date).year(),
        month: (moment(date).month()+1),
        hubId: hubId
      }

      await dispatchCrudApi.createApi(params, 'driver/' + id + '/payroll/generate').then(result => {
        let data = result.data;
        
        callback(true);
        dispatch(actions.finishGenerate({ data, i }));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false);
        dispatch(actions.finishGenerate({ data: null, i: -1 }));
      });
    },

    callReGenerateApi: (hubId: any, item: any, i: number, payrollTemplateId: number|null, callback: (state: boolean) => void) => async (dispatch: any, getState: any) => {
      const { id } = getState().driversDetails;

      dispatch(actions.startReGenerate(i));

      let params = {
        driverId: id,
        payrollTemplateId: payrollTemplateId,
        year: moment(item.date).year(),
        month: (moment(item.date).month()+1),
        startDate: Utils.isNumber(item.startDate) ? parseInt(item.startDate) : 0,
        endDate: Utils.isNumber(item.endDate) ? parseInt(item.endDate) : 0,
        hubId: hubId
      }

      await dispatchCrudApi.createApi(params, 'driver/' + id + '/payroll/generate').then(result => {
        let data = result.data;
        
        callback(true);
        dispatch(actions.finishReGenerate({ data, i }));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false);
        dispatch(actions.finishErrorReGenerate({ data: null, i: i }));
      });
    },

    callBatchGenerateApi: (hubId: any, ids: any, date: any, callback: (state: boolean) => void) => async (dispatch: any) => {
      dispatch(actions.startBatchGenerate());

      let params = {
        ids: ids,
        year: moment(date).year(),
        month: (moment(date).month()+1),
        hubId: hubId
      }

      await dispatchCrudApi.createApi(params, 'driver/payroll/batch-generate').then(result => {
        let data = result.data;
        
        callback(true);
        dispatch(actions.finishBatchGenerate(data));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false);
        dispatch(actions.finishBatchGenerate(null));
      });
    },

    callLockApi: (hubId: any, isLocked: any, details: any, i: number, callback: (state: boolean) => void) => async (dispatch: any, getState: any) => {
      const { id } = getState().driversDetails;

      dispatch(actions.startLock(i));

      let params = {
        driverPayrollMonthReportId: details ? details.driverPayrollMonthReportId : null,
        isLocked: isLocked,
        hubId: hubId
      }

      await dispatchCrudApi.updateApi(params, 'driver/' + id + '/payroll/lock').then(result => {
        let data = result.data;
        
        callback(true);
        dispatch(actions.finishLock({ data, i }));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false);
        dispatch(actions.finishLock({ data: null, i: -1 }));
      });
    },

    callRemarksApi: (hubId: any, remarks: any, details: any, i: number, callback: (state: boolean) => void) => async (dispatch: any, getState: any) => {
      const { id } = getState().driversDetails;

      dispatch(actions.startRemarks(i));

      let params = {
        driverPayrollMonthReportId: details ? details.driverPayrollMonthReportId : null,
        remarks: remarks,
        hubId: hubId
      }

      await dispatchCrudApi.updateApi(params, 'driver/' + id + '/payroll/remarks').then(result => {
        let data = result.data;
        
        callback(true);
        dispatch(actions.finishRemarks({ data, i }));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false);
        dispatch(actions.finishRemarks({ data: null, i: -1 }));
      });
    },

    callExportApi: (hubId: any, ids: any, date: any, callback: (date: any, type: any, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startExport());

      let yearData = moment(date).year()
      let monthData = (moment(date).month()+1)

      let params: any = {
        ids: ids,
        year: yearData,
        month: monthData,
        hubId: hubId
      }

      await dispatchCrudApi.readApi(params, 'driver/payroll/download-excel').then(result => {
        let type = 'application/vnd.ms-excel';
        let data = result.data;

        callback(date, type, data);
        dispatch(actions.finishExport());
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        } else {
          Utils.toast('An error has occurred', 'error');
        }
        
        callback(date, '', null);
        dispatch(actions.finishExport());
      });
    },
    
    callCreateRowApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any, getState: any) => {
      const { id } = getState().driversDetails;

      dispatch(actions.startCreateRow());

      await dispatchCrudApi.createApi(params, 'driver/' + id + '/payroll/row').then(result => {
        let data = result.data;
        
        callback(true, data);
        dispatch(actions.finishCreateRow(data));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false, null);
        dispatch(actions.finishCreateRow(null));
      });
    },
    
    callUpdateRowApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any, getState: any) => {
      const { id } = getState().driversDetails;

      dispatch(actions.startUpdateRow());

      await dispatchCrudApi.updateApi(params, 'driver/' + id + '/payroll/row').then(result => {
        let data = result.data;
        
        callback(true, data);
        dispatch(actions.finishUpdateRow(data));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false, null);
        dispatch(actions.finishUpdateRow(null));
      });
    },

    callDeleteRowApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any, getState: any) => {
      const { id } = getState().driversDetails;

      dispatch(actions.startDeleteRow());

      await dispatchCrudApi.deleteApi(params, 'driver/' + id + '/payroll/row').then(result => {
        let data = result.data;
        
        callback(true, data);
        dispatch(actions.finishDeleteRow(data));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false, null);
        dispatch(actions.finishDeleteRow(null));
      });
    },
    
    callCreateAdHocRowApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any, getState: any) => {
      const { id } = getState().driversDetails;

      dispatch(actions.startCreateAdHocRow());

      await dispatchCrudApi.createApi(params, 'driver/' + id + '/payroll/adhoc-row').then(result => {
        let data = result.data;
        
        callback(true, data);
        dispatch(actions.finishCreateAdHocRow(data));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false, null);
        dispatch(actions.finishCreateAdHocRow(null));
      });
    },
    
    callUpdateAdHocRowApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any, getState: any) => {
      const { id } = getState().driversDetails;

      dispatch(actions.startUpdateAdHocRow());

      await dispatchCrudApi.updateApi(params, 'driver/' + id + '/payroll/adhoc-row').then(result => {
        let data = result.data;
        
        callback(true, data);
        dispatch(actions.finishUpdateAdHocRow(data));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false, null);
        dispatch(actions.finishUpdateAdHocRow(null));
      });
    },

    callDeleteAdHocRowApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any, getState: any) => {
      const { id } = getState().driversDetails;

      dispatch(actions.startDeleteAdHocRow());

      await dispatchCrudApi.deleteApi(params, 'driver/' + id + '/payroll/adhoc-row').then(result => {
        let data = result.data;
        
        callback(true, data);
        dispatch(actions.finishDeleteAdHocRow(data));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false, null);
        dispatch(actions.finishDeleteAdHocRow(null));
      });
    },
    
    callBatchActiveApi: (hubId: any, ids: any, isActive: boolean, callback: (state: boolean) => void) => async (dispatch: any) => {
      dispatch(actions.startBatchActive());

      let params: any = {
        ids: ids,
        isActive: isActive,
        hubId: hubId
      }

      await dispatchCrudApi.updateApi(params, 'driver/batch-active').then(result => {
        let data = result.data;

        callback(true);
        dispatch(actions.finishBatchActive(data));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        } else {
          Utils.toast('An error has occurred', 'error');
        }
        
        callback(false);
        dispatch(actions.finishBatchActive(null));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();