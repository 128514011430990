import React from 'react';

import { Button } from 'react-bootstrap-v5';

const WrappedButton = ({ children, ...rest }) => {
    return <Button
        {...rest}
    >
        {children}
    </Button>
}


export default WrappedButton;