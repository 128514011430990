import React, { useState } from 'react';
import { compose, withProps, lifecycle } from 'recompose';
import { withScriptjs } from "react-google-maps"
import { StandaloneSearchBox } from "react-google-maps/lib/components/places/StandaloneSearchBox";

import Utils from "../../../utils/utils";
import ManageInput from '../input/ManageInput.jsx';

const { REACT_APP_GOOGLE_MAP_API_KEY } = process.env;
const URL = "https://maps.googleapis.com/maps/api/js?key=" + REACT_APP_GOOGLE_MAP_API_KEY + "&v=3.exp&libraries=geometry,drawing,places";


const AddressSearchBox = compose(
    withProps({
        googleMapURL: URL,
        loadingElement: <div style={{ height: '100%' }} />,
        containerElement: <div style={{ height:'100%' }} />
    }),
    lifecycle({
        componentWillMount() {
          const refs = {}
    
          this.setState({
            places: [],
            onSearchBoxMounted: ref => {
              refs.searchBox = ref;
            },
            onPlacesChanged: (value) => {
              let places = refs.searchBox.getPlaces();
          
              this.setState({
                places,
              });

              let lat = (places && places.length > 0 && places[0].geometry && places[0].geometry.location) ? places[0].geometry.location.lat() : '';
              let lng = (places && places.length > 0 && places[0].geometry && places[0].geometry.location) ? places[0].geometry.location.lng() : '';

              if(Utils.validateCoordinates(value)){
                let args = value.split(',');

                let center = {
                  lat: (args && args.length > 0) ? parseFloat(args[0]) : '',
                  lng: (args && args.length > 1) ? parseFloat(args[1]) : '',
                };

                // if (this.props.onEnterCoordinate) {
                //   this.props.onEnterCoordinate(places && places.length > 0 ? places[0] : null, center ? center : null);
                // }
              } else {
                let center = places && places[0]
                  ? {
                      lat: lat,
                      lng: lng,
                    }
                  : null;

                if (this.props.onPlacesChanged) {
                  this.props.onPlacesChanged(places && places.length > 0 ? places[0] : null, center ? center : null);
                }
              }
            },
          })
        },
      }),
    withScriptjs
  )(props => {
    const [className] = useState(props.className ? props.className : '');
    const [placeholder] = useState(props.placeholder ? props.placeholder : '');
    const [value, setValue] = useState(props.value ? props.value : '');
    const [startAdornment] = useState(props.startAdornment ? props.startAdornment : null);
    const [endAdornment] = useState(props.endAdornment ? props.endAdornment : null);
    const [required] = useState(((props.required === false) || (props.required === true)) ? props.required : false);
    const [disabled, setDisabled] = useState(((props.disabled === false) || (props.disabled === true)) ? props.disabled : false);
    const [error, setError] = useState(props.error ? props.error : false);


    React.useEffect(() => {
      setValue(props.value ? props.value : '');
      setDisabled(((props.disabled === false) || (props.disabled === true)) ? props.disabled : false);
      setError(props.error ? props.error : false);
    }, [props.value, props.disabled, props.error]);


    const searchBox = () => {
        return <div>
            <StandaloneSearchBox
                ref={props.onSearchBoxMounted}
                bounds={props.bounds}
                onPlacesChanged={() => props.onPlacesChanged(value)}
            >
                <ManageInput
                  className={"address-searchbox-component " + className}
                  value={value}
                  placeholder={placeholder}
                  startAdornment={startAdornment}
                  endAdornment={endAdornment}
                  disabled={disabled}
                  required={required}
                  error={error} 
                  onChange={(e) => {
                    setValue(e.target.value);

                    if(props.onChange){
                      props.onChange(e.target.value);
                    }
                  }}
                  onPressEnter={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    setValue(e.target.value);

                    if(props.onChange){
                      props.onChange(e.target.value);
                    }
                  }}
                  onBlur={(e) => {
                    if(props.onBlur){
                      props.onBlur(e);
                    }
                  }}
                />
            </StandaloneSearchBox>
            
        </div>
    }
    
    return searchBox();
});


export default AddressSearchBox;