import React, { Component } from "react";

import uuid from 'react-uuid'

import { DayOfWeek } from "../../../utils/enums";
import apiUtil from "../../../api/apiUtil.jsx";
import AccountPublicHolidayApi from "../../../api/OLD/Dispatch/AccountPublicHoliday.jsx";
import AccountWorkingDayApi from "../../../api/OLD/Dispatch/AccountWorkingDay.jsx";

import {
  Box,
  Grid,
  CircularProgress,
  Backdrop,
  Card,
  FormGroup,
  FormControlLabel,
  Checkbox,
  IconButton,
  Tooltip,
} from "@material-ui/core";

import { 
	Button,
	Row,
	Col,
} from "react-bootstrap-v5";

import ViewLayout from '../layout/ViewLayout';
import CurrentDateTime from '../../../components/currentDateTime/CurrentDateTime'


import { ReactComponent as DeleteIcon } from "../../../../_metronic/assets/img/icons/manage-delete.svg";

import Snackbar from "../../../components/OLD/Snackbar/Snackbar.jsx";
import ManageLabel from "../../../components/OLD/input/ManageLabel.jsx";
import RSuiteDatePicker from "../../../components/OLD/Datepicker/RSuiteDatePicker.jsx";

import Utils from '../../../utils/utils';
import {PagesPermissions} from '../../../utils/enums';



class HolidayView extends Component {

  constructor(props) {
    super(props);

		let user = (props.auth && props.auth.user) ? props.auth.user : null;
		this.permissions = (user && user.permissions && user.permissions.length > 0) ? user.permissions : [];

    this.state = {
      showError: false,
      errorText: "",
      errorSeverity: null,

      isLoading: false,

      weekdaysMonday: false,
      weekdaysTuesday: false,
      weekdaysWednesday: false,
      weekdaysThursday: false,
      weekdaysFriday: false,
      weekdaysSaturday: false,
      weekdaysSunday: false,

      weekendsMonday: false,
      weekendsTuesday: false,
      weekendsWednesday: false,
      weekendsThursday: false,
      weekendsFriday: false,
      weekendsSaturday: false,
      weekendsSunday: false,

      workingDays: [],
      publicHolidays: [],
    };
  }


  componentDidMount() {
    this.callWorkingDayReadApi();
    this.callPublicHolidayReadApi(() => {
      if(!this.state.publicHolidays || this.state.publicHolidays.length === 0){
        this.addItem();
      }
    });
  }


  /* API */
  callPublicHolidayReadApi = (callback = null) => {
    const { token } = this.context;

    this.setState({
      isLoading: true,
      publicHolidays: [],
    });
    
    AccountPublicHolidayApi.read(
      {
        currentPage: 1,
        pageSize: apiUtil.getMaxPageSize(),
        sortColumn: "created",
        sortDir: "asc",
      },
      token
    ).then((result) => {
      apiUtil.parseResult(result, (data) => {
          let arr = [];
          if (data && data.data && data.data.length > 0) {
            data.data.forEach((x) => {
              arr.push({
                uuid: uuid(),
                accountPublicHolidayId: x.accountPublicHolidayId,
                date: new Date(x.date),
              });
            });
          }

          this.setState({
            publicHolidays: arr,
            isLoading: false,
          }, () => {
            if (callback !== null) {
              callback(this.state.publicHolidays);
            }
          });
        },
        (error, type) => {
          this.setState({
            publicHolidays: [],
            isLoading: false,
          }, () => {
            if (callback !== null) {
              callback([]);
            }
          });
        }
      );
    });
  };

  callPublicHolidayCreateApi = (row, callback = null) => {
    const { token } = this.context;
    
    this.setState({
      isLoading: true,
    });

    AccountPublicHolidayApi.create(
        row, 
        token
    ).then(result => {
        apiUtil.parseResult(result, (data) => {
            this.setState({
                isLoading: false,
                showError: true,
                errorText: "Successfully created!",
                errorSeverity: 'success'
            }, () => {
              if (callback !== null) {
                callback(data);
              }
            });
        }, (error, type) => {
            this.setState({
                isLoading: false,
                showError: true,
                errorText: error,
                errorSeverity: type
            }, () => {
              if (callback !== null) {
                callback(null);
              }
            });
        });
    });
  }

  callPublicHolidayUpdateApi = (row, callback = null) => {
    const { token } = this.context;
    
    this.setState({
      isLoading: true,
    });

    AccountPublicHolidayApi.update(
        row, 
        token
    ).then(result => {
        apiUtil.parseResult(result, (data) => {
            this.setState({
                isLoading: false,
                showError: true,
                errorText: "Successfully updated!",
                errorSeverity: 'success'
            }, () => {
              if (callback !== null) {
                callback(data);
              }
            });
        }, (error, type) => {
            this.setState({
                isLoading: false,
                showError: true,
                errorText: error,
                errorSeverity: type
            }, () => {
              if (callback !== null) {
                callback(null);
              }
            });
        });
    });
  }

  callPublicHolidayDeleteApi = (row, callback = null) => {
    const { token, setModal } = this.context;

    this.setState({
      isLoading: true,
    });

    AccountPublicHolidayApi.delete(
      row,
      token
    ).then((result) => {
      apiUtil.parseResult(result, (data) => {
          this.setState({
            showError: true,
            errorText: "Successfully deleted!",
            errorSeverity: "success",
            isLoading: false,
          }, () => {
            if (callback !== null) {
              callback(data);
            }
          });
        },
        (error, type, errorsArray, needForceDelete) => {
          if(needForceDelete){
            setModal({
              open: needForceDelete,
              title: 'Force Delete!',
              desc: error,
              onInit: () => {
                
              },
              onClickClose: () => {
                setModal(null);
              },
              onClickOk: () => {
                row['forceDelete'] = true;
              
                this.callPublicHolidayDeleteApi(row, (data) => {
                  if(callback !== null){
                    callback(data);
                  }
                
                  setModal(null);
                });
              },
            });
          } else {
            this.setState({
              showError: true,
              errorText: error,
              errorSeverity: type,
              isLoading: false,
            });
          }
        }
      );
    });
  };


  callWorkingDayReadApi = (callback = null) => {
    const { token } = this.context;

    this.setState({
      isLoading: true,
      workingDays: [],
    });
    
    AccountWorkingDayApi.read(
      {
        currentPage: 1,
        pageSize: apiUtil.getMaxPageSize(),
        sortColumn: "created",
        sortDir: "asc",
      },
      token
    ).then((result) => {
      apiUtil.parseResult(result, (data) => {
          let weekdaysMonday = false;
          let weekdaysTuesday = false;
          let weekdaysWednesday = false;
          let weekdaysThursday = false;
          let weekdaysFriday = false;
          let weekdaysSaturday = false;
          let weekdaysSunday = false;

          let weekendsMonday = false;
          let weekendsTuesday = false;
          let weekendsWednesday = false;
          let weekendsThursday = false;
          let weekendsFriday = false;
          let weekendsSaturday = false;
          let weekendsSunday = false;

          if (data && data.data && data.data.length > 0) {
            let weekdays = data.data.filter(x => x.isWeekend === false);
            if(weekdays && weekdays.length > 0){
              weekdays.forEach(x => {
                if(x.day === DayOfWeek.Monday){
                  weekdaysMonday = true;
                } else if(x.day === DayOfWeek.Tuesday){
                  weekdaysTuesday = true;
                } else if(x.day === DayOfWeek.Wednesday){
                  weekdaysWednesday = true;
                } else if(x.day === DayOfWeek.Thursday){
                  weekdaysThursday = true;
                } else if(x.day === DayOfWeek.Friday){
                  weekdaysFriday = true;
                } else if(x.day === DayOfWeek.Saturday){
                  weekdaysSaturday = true;
                } else if(x.day === DayOfWeek.Sunday){
                  weekdaysSunday = true;
                }
              });
            }

            let weekends = data.data.filter(x => x.isWeekend === true);
            if(weekends && weekends.length > 0){
              weekends.forEach(x => {
                if(x.day === DayOfWeek.Monday){
                  weekendsMonday = true;
                } else if(x.day === DayOfWeek.Tuesday){
                  weekendsTuesday = true;
                } else if(x.day === DayOfWeek.Wednesday){
                  weekendsWednesday = true;
                } else if(x.day === DayOfWeek.Thursday){
                  weekendsThursday = true;
                } else if(x.day === DayOfWeek.Friday){
                  weekendsFriday = true;
                } else if(x.day === DayOfWeek.Saturday){
                  weekendsSaturday = true;
                } else if(x.day === DayOfWeek.Sunday){
                  weekendsSunday = true;
                }
              });
            }
          }

          this.setState({
            isLoading: false,
            workingDays: data.data,

            weekdaysMonday: weekdaysMonday,
            weekdaysTuesday: weekdaysTuesday,
            weekdaysWednesday: weekdaysWednesday,
            weekdaysThursday: weekdaysThursday,
            weekdaysFriday: weekdaysFriday,
            weekdaysSaturday: weekdaysSaturday,
            weekdaysSunday: weekdaysSunday,

            weekendsMonday: weekendsMonday,
            weekendsTuesday: weekendsTuesday,
            weekendsWednesday: weekendsWednesday,
            weekendsThursday: weekendsThursday,
            weekendsFriday: weekendsFriday,
            weekendsSaturday: weekendsSaturday,
            weekendsSunday: weekendsSunday,
          }, () => {
            if (callback !== null) {
              callback(this.state.workingDays);
            }
          });
        },
        (error, type) => {
          this.setState({
            isLoading: false,
            workingDays: [],

            weekdaysMonday: false,
            weekdaysTuesday: false,
            weekdaysWednesday: false,
            weekdaysThursday: false,
            weekdaysFriday: false,
            weekdaysSaturday: false,
            weekdaysSunday: false,

            weekendsMonday: false,
            weekendsTuesday: false,
            weekendsWednesday: false,
            weekendsThursday: false,
            weekendsFriday: false,
            weekendsSaturday: false,
            weekendsSunday: false,
          }, () => {
            if (callback !== null) {
              callback(null);
            }
          });
        }
      );
    });
  };

  callWorkingDayCreateApi = (row, callback = null) => {
    const { token } = this.context;
    
    this.setState({
      isLoading: true,
    });

    AccountWorkingDayApi.create(
        row, 
        token
    ).then(result => {
        apiUtil.parseResult(result, (data) => {
            this.setState({
                isLoading: false,
                showError: true,
                errorText: "Successfully created!",
                errorSeverity: 'success'
            }, () => {
              if (callback !== null) {
                callback(data);
              }
            });
        }, (error, type) => {
            this.setState({
                isLoading: false,
                showError: true,
                errorText: error,
                errorSeverity: type
            }, () => {
              if (callback !== null) {
                callback(null);
              }
            });
        });
    });
  }

  callWorkingDayDeleteApi = (row, callback = null) => {
    const { token, setModal } = this.context;

    this.setState({
      isLoading: true,
    });

    AccountWorkingDayApi.delete(
      row,
      token
    ).then((result) => {
      apiUtil.parseResult(result, (data) => {
          this.setState({
            showError: true,
            errorText: "Successfully deleted!",
            errorSeverity: "success",
            isLoading: false,
          }, () => {
            if (callback !== null) {
              callback(data);
            }
          });
        },
        (error, type, errorsArray, needForceDelete) => {
          if(needForceDelete){
            setModal({
              open: needForceDelete,
              title: 'Force Delete!',
              desc: error,
              onInit: () => {
                
              },
              onClickClose: () => {
                setModal(null);
              },
              onClickOk: () => {
                row['forceDelete'] = true;
              
                this.callWorkingDayDeleteApi(row, (data) => {
                  if(callback !== null){
                    callback(data);
                  }
                
                  setModal(null);
                });
              },
            });
          } else {
            this.setState({
              showError: true,
              errorText: error,
              errorSeverity: type,
              isLoading: false,
            });
          }
        }
      );
    });
  };
  /* END API */


  /* BACKDROP && SNACKBAR */
  setBackDrop = () => {
    return (
      <Backdrop
        open={this.state.isLoading}
        style={{
          zIndex: 999999,
          color: "#ffffff",
        }}
      >
        <CircularProgress
          color="inherit"
          style={{
            zIndex: 999999,
            color: "#ffffff",
          }}
        />
      </Backdrop>
    );
  };

  setSnackBar = () => {
    return (
      <Snackbar
        open={this.state.showError}
        text={this.state.errorText}
        severity={this.state.errorSeverity}
        onClose={() => {
          this.setState({
            showError: false,
            errorText: "",
            errorSeverity: null,
          });
        }}
      />
    );
  };
  /* END BACKDROP && SNACKBAR */

  /* SECTIONS */
  topSection = () => {
    return (
      <Box>
        <Grid container>
          <Box clone pt={1}>
            <Grid item xs={12} md={true}>
              <ManageLabel label={"Holiday set up"} size={"md"} />
            </Grid>
          </Box>
        </Grid>
      </Box>
    );
  };

  weekdaysSection = () => {
    return <Box p={3}>
      <Grid container spacing={2}>
        <Box clone>
          <Grid item xs={12}>
            <ManageLabel 
              label={"Weekdays"}
              size={"md"}
              style={{
                borderBottom: '2px dashed #484848',
                paddingBottom: '3px',
              }}
            />
          </Grid>
        </Box>
        <Box clone>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                className={'checkbox-primary'}
                label="Monday"
                disabled={this.state.weekendsMonday}
                control={<Checkbox
                  color="default"
                  checked={this.state.weekdaysMonday}
                  onChange={(e) => {
                    let checked = e.target.checked;

                    if(checked){
                      let data = {
                        day: DayOfWeek.Monday,
                        isWeekend: false,
                      };
  
                      this.callWorkingDayCreateApi(data, () => {
                        this.callWorkingDayReadApi();
                        this.setState({
                          weekdaysMonday: checked,
                        });
                      });
                    } else {
                      let data = this.state.workingDays.filter(x => x.isWeekend === false && x.day === DayOfWeek.Monday);
                      if(data && data.length > 0){
                        let rowData = {
                          accountWorkingDayId: data[0].accountWorkingDayId,
                        };

                        this.callWorkingDayDeleteApi(rowData, () => {
                          this.setState({
                            weekdaysMonday: checked,
                          });
                        });
                      }
                    }
                  }}
                />}
              />
              <FormControlLabel
                className={'checkbox-primary'}
                label="Tuesday"
                disabled={this.state.weekendsTuesday}
                control={<Checkbox
                  color="default"
                  checked={this.state.weekdaysTuesday}
                  onChange={(e) => {
                    let checked = e.target.checked;
                    
                    if(checked){
                      let data = {
                        day: DayOfWeek.Tuesday,
                        isWeekend: false,
                      };
  
                      this.callWorkingDayCreateApi(data, () => {
                        this.callWorkingDayReadApi();
                        this.setState({
                          weekdaysTuesday: checked,
                        });
                      });
                    } else {
                      let data = this.state.workingDays.filter(x => x.isWeekend === false && x.day === DayOfWeek.Tuesday);
                      if(data && data.length > 0){
                        let rowData = {
                          accountWorkingDayId: data[0].accountWorkingDayId,
                        };

                        this.callWorkingDayDeleteApi(rowData, () => {
                          this.setState({
                            weekdaysTuesday: checked,
                          });
                        });
                      }
                    }
                  }}
                />}
              />
              <FormControlLabel
                className={'checkbox-primary'}
                label="Wednesday"
                disabled={this.state.weekendsWednesday}
                control={<Checkbox
                  color="default"
                  checked={this.state.weekdaysWednesday}
                  onChange={(e) => {
                    let checked = e.target.checked;
                    
                    if(checked){
                      let data = {
                        day: DayOfWeek.Wednesday,
                        isWeekend: false,
                      };
  
                      this.callWorkingDayCreateApi(data, () => {
                        this.callWorkingDayReadApi();
                        this.setState({
                          weekdaysWednesday: checked,
                        });
                      });
                    } else {
                      let data = this.state.workingDays.filter(x => x.isWeekend === false && x.day === DayOfWeek.Wednesday);
                      if(data && data.length > 0){
                        let rowData = {
                          accountWorkingDayId: data[0].accountWorkingDayId,
                        };

                        this.callWorkingDayDeleteApi(rowData, () => {
                          this.setState({
                            weekdaysWednesday: checked,
                          });
                        });
                      }
                    }
                  }}
                />}
              />
              <FormControlLabel
                className={'checkbox-primary'}
                label="Thursday"
                disabled={this.state.weekendsThursday}
                control={<Checkbox
                  color="default"
                  checked={this.state.weekdaysThursday}
                  onChange={(e) => {
                    let checked = e.target.checked;
                    
                    if(checked){
                      let data = {
                        day: DayOfWeek.Thursday,
                        isWeekend: false,
                      };
  
                      this.callWorkingDayCreateApi(data, () => {
                        this.callWorkingDayReadApi();
                        this.setState({
                          weekdaysThursday: checked,
                        });
                      });
                    } else {
                      let data = this.state.workingDays.filter(x => x.isWeekend === false && x.day === DayOfWeek.Thursday);
                      if(data && data.length > 0){
                        let rowData = {
                          accountWorkingDayId: data[0].accountWorkingDayId,
                        };

                        this.callWorkingDayDeleteApi(rowData, () => {
                          this.setState({
                            weekdaysThursday: checked,
                          });
                        });
                      }
                    }
                  }}
                />}
              />
              <FormControlLabel
                className={'checkbox-primary'}
                label="Friday"
                disabled={this.state.weekendsFriday}
                control={<Checkbox
                  color="default"
                  checked={this.state.weekdaysFriday}
                  onChange={(e) => {
                    let checked = e.target.checked;
                    
                    if(checked){
                      let data = {
                        day: DayOfWeek.Friday,
                        isWeekend: false,
                      };
  
                      this.callWorkingDayCreateApi(data, () => {
                        this.callWorkingDayReadApi();
                        this.setState({
                          weekdaysFriday: checked,
                        });
                      });
                    } else {
                      let data = this.state.workingDays.filter(x => x.isWeekend === false && x.day === DayOfWeek.Friday);
                      if(data && data.length > 0){
                        let rowData = {
                          accountWorkingDayId: data[0].accountWorkingDayId,
                        };

                        this.callWorkingDayDeleteApi(rowData, () => {
                          this.setState({
                            weekdaysFriday: checked,
                          });
                        });
                      }
                    }
                  }}
                />}
              />
              <FormControlLabel
                className={'checkbox-primary'}
                label="Saturday"
                disabled={this.state.weekendsSaturday}
                control={<Checkbox
                  color="default"
                  checked={this.state.weekdaysSaturday}
                  onChange={(e) => {
                    let checked = e.target.checked;
                    
                    if(checked){
                      let data = {
                        day: DayOfWeek.Saturday,
                        isWeekend: false,
                      };
  
                      this.callWorkingDayCreateApi(data, () => {
                        this.callWorkingDayReadApi();
                        this.setState({
                          weekdaysSaturday: checked,
                        });
                      });
                    } else {
                      let data = this.state.workingDays.filter(x => x.isWeekend === false && x.day === DayOfWeek.Saturday);
                      if(data && data.length > 0){
                        let rowData = {
                          accountWorkingDayId: data[0].accountWorkingDayId,
                        };

                        this.callWorkingDayDeleteApi(rowData, () => {
                          this.setState({
                            weekdaysSaturday: checked,
                          });
                        });
                      }
                    }
                  }}
                />}
              />
              <FormControlLabel
                className={'checkbox-primary'}
                label="Sunday"
                disabled={this.state.weekendsSunday}
                control={<Checkbox
                  color="default"
                  checked={this.state.weekdaysSunday}
                  onChange={(e) => {
                    let checked = e.target.checked;
                    
                    if(checked){
                      let data = {
                        day: DayOfWeek.Sunday,
                        isWeekend: false,
                      };
  
                      this.callWorkingDayCreateApi(data, () => {
                        this.callWorkingDayReadApi();
                        this.setState({
                          weekdaysSunday: checked,
                        });
                      });
                    } else {
                      let data = this.state.workingDays.filter(x => x.isWeekend === false && x.day === DayOfWeek.Sunday);
                      if(data && data.length > 0){
                        let rowData = {
                          accountWorkingDayId: data[0].accountWorkingDayId,
                        };

                        this.callWorkingDayDeleteApi(rowData, () => {
                          this.setState({
                            weekdaysSunday: checked,
                          });
                        });
                      }
                    }
                  }}
                />}
              />
            </FormGroup>
          </Grid>
        </Box>
      </Grid>
    </Box>
  }

  weekendsSection = () => {
    return <Box p={3}>
      <Grid container spacing={2}>
        <Box clone>
          <Grid item xs={12}>
            <ManageLabel 
              label={"Weekends"}
              size={"md"}
              style={{
                borderBottom: '2px dashed #484848',
                paddingBottom: '3px',
              }}
            />
          </Grid>
        </Box>
        <Box clone>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                className={'checkbox-primary'}
                label="Monday"
                disabled={this.state.weekdaysMonday}
                control={<Checkbox
                  color="default"
                  checked={this.state.weekendsMonday}
                  onChange={(e) => {
                    let checked = e.target.checked;
                    
                    if(checked){
                      let data = {
                        day: DayOfWeek.Monday,
                        isWeekend: true,
                      };
  
                      this.callWorkingDayCreateApi(data, () => {
                        this.callWorkingDayReadApi();
                        this.setState({
                          weekendsMonday: checked,
                        });
                      });
                    } else {
                      let data = this.state.workingDays.filter(x => x.isWeekend === true && x.day === DayOfWeek.Monday);
                      if(data && data.length > 0){
                        let rowData = {
                          accountWorkingDayId: data[0].accountWorkingDayId,
                        };

                        this.callWorkingDayDeleteApi(rowData, () => {
                          this.setState({
                            weekendsMonday: checked,
                          });
                        });
                      }
                    }
                  }}
                />}
              />
              <FormControlLabel
                className={'checkbox-primary'}
                label="Tuesday"
                disabled={this.state.weekdaysTuesday}
                control={<Checkbox
                  color="default"
                  checked={this.state.weekendsTuesday}
                  onChange={(e) => {
                    let checked = e.target.checked;
                    
                    if(checked){
                      let data = {
                        day: DayOfWeek.Tuesday,
                        isWeekend: true,
                      };
  
                      this.callWorkingDayCreateApi(data, () => {
                        this.callWorkingDayReadApi();
                        this.setState({
                          weekendsTuesday: checked,
                        });
                      });
                    } else {
                      let data = this.state.workingDays.filter(x => x.isWeekend === true && x.day === DayOfWeek.Tuesday);
                      if(data && data.length > 0){
                        let rowData = {
                          accountWorkingDayId: data[0].accountWorkingDayId,
                        };

                        this.callWorkingDayDeleteApi(rowData, () => {
                          this.setState({
                            weekendsTuesday: checked,
                          });
                        });
                      }
                    }
                  }}
                />}
              />
              <FormControlLabel
                className={'checkbox-primary'}
                label="Wednesday"
                disabled={this.state.weekdaysWednesday}
                control={<Checkbox
                  color="default"
                  checked={this.state.weekendsWednesday}
                  onChange={(e) => {
                    let checked = e.target.checked;
                    
                    if(checked){
                      let data = {
                        day: DayOfWeek.Wednesday,
                        isWeekend: true,
                      };
  
                      this.callWorkingDayCreateApi(data, () => {
                        this.callWorkingDayReadApi();
                        this.setState({
                          weekendsWednesday: checked,
                        });
                      });
                    } else {
                      let data = this.state.workingDays.filter(x => x.isWeekend === true && x.day === DayOfWeek.Wednesday);
                      if(data && data.length > 0){
                        let rowData = {
                          accountWorkingDayId: data[0].accountWorkingDayId,
                        };

                        this.callWorkingDayDeleteApi(rowData, () => {
                          this.setState({
                            weekendsWednesday: checked,
                          });
                        });
                      }
                    }
                  }}
                />}
              />
              <FormControlLabel
                className={'checkbox-primary'}
                label="Thursday"
                disabled={this.state.weekdaysThursday}
                control={<Checkbox
                  color="default"
                  checked={this.state.weekendsThursday}
                  onChange={(e) => {
                    let checked = e.target.checked;
                    
                    if(checked){
                      let data = {
                        day: DayOfWeek.Thursday,
                        isWeekend: true,
                      };
  
                      this.callWorkingDayCreateApi(data, () => {
                        this.callWorkingDayReadApi();
                        this.setState({
                          weekendsThursday: checked,
                        });
                      });
                    } else {
                      let data = this.state.workingDays.filter(x => x.isWeekend === true && x.day === DayOfWeek.Thursday);
                      if(data && data.length > 0){
                        let rowData = {
                          accountWorkingDayId: data[0].accountWorkingDayId,
                        };

                        this.callWorkingDayDeleteApi(rowData, () => {
                          this.setState({
                            weekendsThursday: checked,
                          });
                        });
                      }
                    }
                  }}
                />}
              />
              <FormControlLabel
                className={'checkbox-primary'}
                label="Friday"
                disabled={this.state.weekdaysFriday}
                control={<Checkbox
                  color="default"
                  checked={this.state.weekendsFriday}
                  onChange={(e) => {
                    let checked = e.target.checked;
                    
                    if(checked){
                      let data = {
                        day: DayOfWeek.Friday,
                        isWeekend: true,
                      };
  
                      this.callWorkingDayCreateApi(data, () => {
                        this.callWorkingDayReadApi();
                        this.setState({
                          weekendsFriday: checked,
                        });
                      });
                    } else {
                      let data = this.state.workingDays.filter(x => x.isWeekend === true && x.day === DayOfWeek.Friday);
                      if(data && data.length > 0){
                        let rowData = {
                          accountWorkingDayId: data[0].accountWorkingDayId,
                        };

                        this.callWorkingDayDeleteApi(rowData, () => {
                          this.setState({
                            weekendsFriday: checked,
                          });
                        });
                      }
                    }
                  }}
                />}
              />
              <FormControlLabel
                className={'checkbox-primary'}
                label="Saturday"
                disabled={this.state.weekdaysSaturday}
                control={<Checkbox
                  color="default"
                  checked={this.state.weekendsSaturday}
                  onChange={(e) => {
                    let checked = e.target.checked;
                    
                    if(checked){
                      let data = {
                        day: DayOfWeek.Saturday,
                        isWeekend: true,
                      };
  
                      this.callWorkingDayCreateApi(data, () => {
                        this.callWorkingDayReadApi();
                        this.setState({
                          weekendsSaturday: checked,
                        });
                      });
                    } else {
                      let data = this.state.workingDays.filter(x => x.isWeekend === true && x.day === DayOfWeek.Saturday);
                      if(data && data.length > 0){
                        let rowData = {
                          accountWorkingDayId: data[0].accountWorkingDayId,
                        };

                        this.callWorkingDayDeleteApi(rowData, () => {
                          this.setState({
                            weekendsSaturday: checked,
                          });
                        });
                      }
                    }
                  }}
                />}
              />
              <FormControlLabel
                className={'checkbox-primary'}
                label="Sunday"
                disabled={this.state.weekdaysSunday}
                control={<Checkbox
                  color="default"
                  checked={this.state.weekendsSunday}
                  onChange={(e) => {
                    let checked = e.target.checked;
                    
                    if(checked){
                      let data = {
                        day: DayOfWeek.Sunday,
                        isWeekend: true,
                      };
  
                      this.callWorkingDayCreateApi(data, () => {
                        this.callWorkingDayReadApi();
                        this.setState({
                          weekendsSunday: checked,
                        });
                      });
                    } else {
                      let data = this.state.workingDays.filter(x => x.isWeekend === true && x.day === DayOfWeek.Sunday);
                      if(data && data.length > 0){
                        let rowData = {
                          accountWorkingDayId: data[0].accountWorkingDayId,
                        };

                        this.callWorkingDayDeleteApi(rowData, () => {
                          this.setState({
                            weekendsSunday: checked,
                          });
                        });
                      }
                    }
                  }}
                />}
              />
            </FormGroup>
          </Grid>
        </Box>
      </Grid>
    </Box>
  }

  publicHolidaysSection = () => {
    return <Box p={3}>
      <Grid container spacing={2}>
        <Box clone>
          <Grid item xs={12}>
            <ManageLabel 
              label={"Public holidays"}
              size={"md"}
              style={{
                borderBottom: '2px dashed #484848',
                paddingBottom: '3px',
              }}
            />
          </Grid>
        </Box>
        <Box clone>
          <Grid item xs={12}>

              <Grid container>
                {this.state.publicHolidays.map((item, i) => {
                  return this.publicHolidaysItem(item, i)
                })}

                <Box clone>
                  <Grid item xs={12}>
                    <a href={'/'} 
                      className={"link-primary"}
                      style={{
                        paddingLeft: '10px',
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        this.addItem();
                      }}
                    >
                      Add more
                    </a>
                  </Grid>
                </Box>
                
              </Grid>

          </Grid>
        </Box>
      </Grid>
    </Box>
  }
  /* END SECTIONS */

  
  /* ITEM */
  publicHolidaysItem = (item, i) => {
    return <Box clone pb={1} key={i}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Box clone>
            <Grid item xs={true}>
              <RSuiteDatePicker
                className={"public-holiday-date-picker " + (item.dateValidate ? " error" : "")}
                icon={<></>}
                value={item.date}
                onChange={(value) => {
                  let publicHolidays = this.state.publicHolidays;
                  publicHolidays[i].date = value;
                  publicHolidays[i].dateValidate = false;
                  this.setState({
                    publicHolidays: publicHolidays,
                  }, () => {
                    if(this.state.publicHolidays[i].accountPublicHolidayId){
                      this.callPublicHolidayUpdateApi(this.state.publicHolidays[i])
                    } else {
                      this.callPublicHolidayCreateApi(this.state.publicHolidays[i], (item) => {
                        let publicHolidays = this.state.publicHolidays;
                        publicHolidays[i]['accountPublicHolidayId'] = item.accountPublicHolidayId;
                        this.setState({
                          publicHolidays: publicHolidays,
                        });
                      })
                    }
                  });
                }}
              />
            </Grid>
          </Box>
          <Box clone>
            <Grid item xs={'auto'}>
              <Tooltip title={"Delete"}>
                <span>
                  <IconButton
                    className={"manage-icon-btn"}
                    disabled={(this.state.publicHolidays && this.state.publicHolidays.length > 1) ? false : true}
                    onClick={(e) => {
                      let rowData = {
                        accountPublicHolidayId: item.accountPublicHolidayId,
                      };

                      this.callPublicHolidayDeleteApi(rowData, () => {
                        this.removeItem(item);
                      });
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  }
  /* END ITEM */


  /* FUNCTIONS */
  addItem = () => {
    let publicHolidays = this.state.publicHolidays;
    
    let state = false;
    if(publicHolidays && publicHolidays.length > 0){
      for(let i = 0; i < publicHolidays.length; i++){
        if(publicHolidays[i].date === null){
          publicHolidays[i].dateValidate = true;
          state = true;
        }
      }
    }

    if(state){
      this.setState({
        showError: true,
        errorText: 'You must select a date!',
        errorSeverity: 'error',
      });

      return;
    }

    let item = {
      uuid: uuid(),
      date: null,
      dateValidate: false,
    }
    publicHolidays.push(item);
    this.setState({
      publicHolidays: publicHolidays,
    });
  }
  removeItem = (item = null) => {
    if(item){
      let publicHolidays = this.state.publicHolidays.filter(x => x.uuid !== item.uuid);
      this.setState({
        publicHolidays: publicHolidays,
      });
    }
  }
  /* END FUNCTIONS */


  render() {
    return <Box className={'manage-layout'}>
		<ViewLayout
		isBlocking={false}
		title={'SETTINGS'}
    col={<Col xs={true} className={'d-none d-lg-flex'} style={{ minHeight: '43px' }}></Col>}
		actionSection={<Row className={'align-items-center'}>
      
		  {(Utils.hasPermission(this.permissions, PagesPermissions.ManageGeneral.read)) && <Col xs={'auto'} className={'mb-2 mb-sm-0'}>
		  <Button
			variant={'light'}
			className={'pt-1 pb-1 rounded-1 bg-white'}
			onClick={(e) => {
			this.props.history.push('/manage/setting/general');
			}}
		  >
        GENERAL
		  </Button>
		  </Col>}
      
		  {(Utils.hasPermission(this.permissions, PagesPermissions.ManageSubscription.read)) && <Col xs={'auto'} className={'mb-2 mb-sm-0'}>
		  <Button
			variant={'light'}
			className={'pt-1 pb-1 rounded-1 bg-white'}
			onClick={(e) => {
			this.props.history.push('/manage/setting/subscription');
			}}
		  >
			SUBSCRIPTION
		  </Button>
		  </Col>}
      
		  {/* {(Utils.hasPermission(this.permissions, PagesPermissions.ManageHoliday.read)) && <Col xs={'auto'} className={'mb-2 mb-sm-0'}>
		  <Button
			variant={'success'}
			className={'pt-1 pb-1 rounded-1 '}
			onClick={(e) => {
			this.props.history.push('/manage/setting/holiday');
			}}
		  >
		  HOLIDAY
		  </Button>
		  </Col>} */}

		  <Col xs={12} md={true} className={'order-1 order-md-3 pb-10 pb-md-0'}>
		  <CurrentDateTime />
		  </Col>
		</Row>}
		// toolbarSection={this.toolbarSection()}
		tableSection={<>
		  <Grid container spacing={2} className={'border-right-list'}>
          <Box clone>
            <Grid item xs={true} sm={6} lg={4}>
              {this.weekdaysSection()}
            </Grid>
          </Box>
          <Box clone>
            <Grid item xs={true} sm={6} lg={4}>
              {this.weekendsSection()}
            </Grid>
          </Box>
          <Box clone>
            <Grid item xs={12} sm={12} lg={4}>
              {this.publicHolidaysSection()}
            </Grid>
          </Box>
        </Grid>
		</>}
		// paginationSection={this.paginationSection()}
		/>
      
      {this.setSnackBar()}
      {this.setBackDrop()}
    </Box>
  }
}

export default HolidayView;
