import React from 'react';
import { useFormik } from 'formik';

import { 
    Row,
    Col,
    Form,
    Modal,
    Button,
    Spinner,
} from 'react-bootstrap-v5';

import SmartInputDropdown from '../../../components/dropdown/smartInputDropdown/SmartInputDropdown';
import makeCRUD from "../../../components/dropdown/smartInputDropdown/makeCRUD";

import Utils from '../../../utils/utils';

import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../../../setup'
import binCenterBinNumberPopup, { FormikContext, formSchema, initialValues, fields, prepareData, prepareForm } from '../redux/binCenterBinNumberPopup';


const SmartDropdown = makeCRUD(SmartInputDropdown);


const BinNumberPopup = (props: any) => {
    const dispatch = useDispatch();
    const { show, id, details, isLoading } = useSelector((state: RootState) => state.binCenterBinNumberPopup);


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: formSchema(),
        enableReinitialize: true,
        validateOnMount: true,
        validateOnChange: true,
        onSubmit: values => {
            let data = prepareData(id, values);
            if(id && id > 0){
                dispatch(binCenterBinNumberPopup.callUpdateApi(data, (state: boolean, data: any) => {
                    if(state){
                        dispatch(binCenterBinNumberPopup.setHide());

                        if(props && props.onUpdate){
                            props.onUpdate(data);
                        }
                    } else {
                        if(data){
                            if(props && props.onUpdateError){
                                props.onUpdateError(data);
                            }
                        }
                    }
                }));
            } else {
                dispatch(binCenterBinNumberPopup.callCreateApi(data, (state: boolean, data: any) => {
                    if(state){
                        dispatch(binCenterBinNumberPopup.setHide());

                        if(props && props.onCreate){
                            props.onCreate(data);
                        }
                    }
                }));
            }
        },
    });
    const { values, errors, setValues, setFieldValue, setErrors, handleSubmit } = formik;


    React.useEffect(() => {
        if(show){
            setErrors({});
        } else {
            setValues(initialValues);
        }
    }, [show]);

    React.useEffect(() => {
        if(id && id > 0){
            dispatch(binCenterBinNumberPopup.callDetailsApi(id, (state: boolean, data: any) => {}));
        }
    }, [id]);
    
    React.useEffect(() => {
        dispatch(binCenterBinNumberPopup.setLoading(true));
        let form = prepareForm(details, initialValues);
        setValues(form);
        dispatch(binCenterBinNumberPopup.setLoading(false));
    }, [details]);


    return <Modal 
        className={'z-index-1050'}
        show={show} 
        size={'sm'} 
        backdrop={'static'} 
        onHide={() => {
            dispatch(binCenterBinNumberPopup.setHide());
            
            if(props.onCancel){
                props.onCancel();
            }
        }} 
        enforceFocus={false}
    >
        <Modal.Header className={'px-4 py-3'} closeButton={false}>
            <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body className={'px-5 py-7'}>
            <FormikContext.Provider value={formik}>
                <Row>
                    <Col xs={12}>
                        <Form.Group className={'mb-5'}>
                            <Form.Label>{fields.binNumberName.label}</Form.Label>
                            <Form.Control
                                type="text"
                                id={fields.binNumberName.id}
                                placeholder={fields.binNumberName.placeholder}
                                disabled={isLoading}
                                value={values.binNumberName}
                                onChange={async (e) => {
                                    let value = e.target.value;
                                    let pattern = Utils.max7chars();
            
                                    if(value.match(pattern) != null){
                                        await setFieldValue('binNumberName', value);
                                    }
                                }}
                                isInvalid={!!errors.binNumberName}
                            />
                            <Form.Control.Feedback type="invalid">{errors.binNumberName}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12}>
                        <Form.Group className={'mb-3'}>
                            <Form.Label>{fields.binTypeId.label}</Form.Label>
                            <SmartDropdown
                                isInvalid={!!errors.binTypeId}
                                errorText={errors.binTypeId}

                                componentTitle={'binType'}
                                componentApi={'binType'}
                                componentId={'binTypeId'}
                                componentName={'binTypeName'}

                                placeholder={fields.binTypeId.placeholder}
                                value={values.binTypeId}
                                label={values.binTypeName}

                                showFooter={false}
                                disabled={isLoading}
                                showDefault={false}
                                isInfiniteScroll={false}

                                onChange={async (value: any, item: any, i: any) => {
                                    await setFieldValue('binTypeId', value);
                                    await setFieldValue('binTypeName', item.title);
                                }}
                                onClear={async () => {
                                    await setFieldValue('binTypeId', null);
                                    await setFieldValue('binTypeName', '');
                                }}

                                onUpdateData={() => {
                                    if(props.onReload){
                                        props.onReload();
                                    }
                                }}
                                onDeleteData={() => {
                                    if(props.onReload){
                                        props.onReload();
                                    }
                                }}
                            />
                            <Form.Control.Feedback type="invalid">{errors.binTypeId}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
            </FormikContext.Provider>
        </Modal.Body>

        <Modal.Footer className={'p-3'} style={{ justifyContent: 'start' }}>
            <Button
                type={'button'}
                variant={'primary'}
                size={'sm'}
                disabled={isLoading}
                onClick={() => {
                    handleSubmit()
                }}
            >
                {props.save}
                {isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
            </Button>

            <Button
                type={'button'}
                variant={'light'}
                size={'sm'}
                disabled={isLoading}
                onClick={() => {
                    dispatch(binCenterBinNumberPopup.setHide());
            
                    if(props.onCancel){
                        props.onCancel();
                    }
                }}
            >
                {props.cancel}
                {isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
            </Button>
        </Modal.Footer>
    </Modal>
}


export default BinNumberPopup;