/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useDispatch} from 'react-redux'
import { useHistory } from "react-router-dom";
import queryString from "query-string";

import {toAbsoluteUrl} from '../../../_metronic/helpers'
import * as auth from '../../../setup/redux/auth/saga'
import { authenticateToken, dispatchAuthenticateToken, getAccountPermissions } from '../../../app/api/auth/api'

import logo from '../../../_metronic/assets/img/logo.png';


export function LoginT() {
  const dispatch = useDispatch()
  let history = useHistory();
  React.useEffect(() => {
    const q = queryString.parse(window.location.search);
    const t = (q.t) ? q.t : null;
    const returnUrl = (q.returnUrl && q.returnUrl != '') ? q.returnUrl : null;

    const requestAuth = async (token: any) => {
      if(token && token !== ''){
        dispatch(auth.actions.login(t as string))
  
        const {data: user} = await authenticateToken(token as string)
        dispatch(auth.actions.fulfillUser(user))
        
        const {data: dispatchUser} = await dispatchAuthenticateToken(token as string)
        dispatch(auth.actions.fulfillDispatchUser(dispatchUser))

        const allPermissions = await getAccountPermissions()
        dispatch(auth.actions.setAccountPermissions(allPermissions))
        
        setTimeout(() => {
          if(returnUrl){
            window.location.replace(returnUrl.toString());
          } else {
            history.push("/");
          }
        }, 500);
      }
    }

    requestAuth(t);
  }, []);


  return (
    <div className='d-flex flex-column flex-root'>
      <div
        className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        style={{backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/progress-hd.png')}')`}}
      >
        <div className='d-flex flex-column flex-column-fluid text-center p-10 py-lg-20'>
          <a href='/dashboard' className='mb-10 pt-lg-20'>
            <img
              alt='Logo'
              src={logo}
              className='h-50px mb-5'
            />
          </a>
          <div className='pt-lg-10 mb-10'>
            <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>Please wait</h1>

            <div className='fw-bold fs-3 text-gray-400 mb-15'>
              You will soon be redirected to the dashboard page
            </div>

            <div className='text-center'>
              <a href={'/'} className='btn btn-lg btn-primary fw-bolder'>
                Go to homepage
              </a>
            </div>
          </div>
          <div
            className='
              d-flex
              flex-row-auto
              bgi-no-repeat
              bgi-position-x-center
              bgi-size-contain
              bgi-position-y-bottom
              min-h-100px min-h-lg-350px
            '
            style={{
              backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/sketchy-1/17.png')}')`,
            }}
          ></div>
        </div>

      </div>
    </div>
  )
}
