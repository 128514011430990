/* eslint-disable no-loop-func */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface InitState {
    isFile: boolean,
    file: File|null,
    name: string|null,
    disabled: boolean,
}


function NewReducer() {
  const name = 'fileInputButtonSlice';


  const initialState: InitState = {
    isFile: false,
    file: null,
    name: null,
    disabled: false,
  };


  const reducers = {
    onChange: (state: InitState, action: PayloadAction<File|null>) => {
      let file = action.payload;
      state.isFile = file ? true : false;
      state.file = file ? file : null;
      state.name = file ? file.name : null;
    },
    setDisabled: (state: InitState, action: PayloadAction<boolean>) => {
      state.disabled = action.payload;
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
  };
}


export default NewReducer();