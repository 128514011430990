import { createSlice } from "@reduxjs/toolkit";

import * as dispatchCrudApi from '../../../api/CRUD/DispatchCRUD'
import Utils from "../../../utils/utils";


function NewReducer() {
  const name = 'billingInvoiceStatusSection';


  const initialState = {
    billingStatus: {
      isLoading: false,

      status1Progress1name: '',
      status1Progress1count: 0,
      status1Progress1total: 0,
      status1Progress1percent: 0,

      status1Progress2name: '',
      status1Progress2count: 0,
      status1Progress2total: 0,
      status1Progress2percent: 0,

      status1total: 0,
    },
    invoiceStatus: {
      isLoading: false,

      status2Progress1name: 'Unbilled',
      status2Progress1count: 0,
      status2Progress1total: 0,
      status2Progress1percent: 0,

      status2Progress2name: 'Billed',
      status2Progress2count: 0,
      status2Progress2total: 0,
      status2Progress2percent: 0,

      status2Progress3name: 'Paid',
      status2Progress3count: 0,
      status2Progress3total: 0,
      status2Progress3percent: 0,

      status2total: 0,
    },
    purchaseStatus: {
      isLoading: false,

      status1Progress1name: 'PO Issued',
      status1Progress1count: 0,
      status1Progress1total: 0,
      status1Progress1percent: 0,

      status1Progress2name: 'PO not Issued',
      status1Progress2count: 0,
      status1Progress2total: 0,
      status1Progress2percent: 0,

      status1total: 0,
    },
    expenseStatus: {
      isLoading: false,

      status1Progress1name: 'EXP Issued',
      status1Progress1count: 0,
      status1Progress1total: 0,
      status1Progress1percent: 0,

      status1Progress2name: 'EXP not Issued',
      status1Progress2count: 0,
      status1Progress2total: 0,
      status1Progress2percent: 0,

      status1total: 0,
    },
  };


  const reducers = {
    startBilling: (state) => {
      state.billingStatus.isLoading = true;
      
      state.billingStatus.status1Progress1name = '';
      state.billingStatus.status1Progress1count = 0;
      state.billingStatus.status1Progress1total = 0;

      state.billingStatus.status1Progress2name = '';
      state.billingStatus.status1Progress2count = 0;
      state.billingStatus.status1Progress2total = 0;
    },
    finishBilling: (state, action) => {
      let billingStatus = state.billingStatus;

      let data = action.payload;
      if(data && data.data && data.data.length > 0){
        for(let i = 0; i < data.data.length; i++){
          if(data.data[i].name === 'Billable'){
            billingStatus.status1Progress1name = data.data[i].name;
            billingStatus.status1Progress1count = data.data[i].value;
            billingStatus.status1Progress1total = data.data[i].totalPrice;
          } else {
            billingStatus.status1Progress2name = data.data[i].name;
            billingStatus.status1Progress2count = data.data[i].value;
            billingStatus.status1Progress2total = data.data[i].totalPrice;
          }
        }
      }
      billingStatus.status1total = billingStatus.status1Progress1count + billingStatus.status1Progress2count;
      billingStatus.status1Progress1percent = (billingStatus.status1Progress1count / billingStatus.status1total) * 100;
      billingStatus.status1Progress2percent = (billingStatus.status1Progress2count / billingStatus.status1total) * 100;
      
      if(billingStatus.status1Progress1percent < 10){
        billingStatus.status1Progress1percent = 10
      }
      if(billingStatus.status1Progress2percent < 10){
        billingStatus.status1Progress2percent = 10
      }

      billingStatus.isLoading = false;

      state.billingStatus = billingStatus;
    },

    startInvoice: (state) => {
      state.invoiceStatus.isLoading = true;

      state.invoiceStatus.status2Progress1count = 0;
      state.invoiceStatus.status2Progress1total = 0;

      state.invoiceStatus.status2Progress2count = 0;
      state.invoiceStatus.status2Progress2total = 0;

      state.invoiceStatus.status2Progress3count = 0;
      state.invoiceStatus.status2Progress3total = 0;
    },
    finishInvoice: (state, action) => {
      let invoiceStatus = state.invoiceStatus;

      let data = action.payload;
      if(data && data.data && data.data.length > 0){
        for(let i = 0; i < data.data.length; i++){
          if(data.data[i].isPaid){
            invoiceStatus.status2Progress3count = invoiceStatus.status2Progress3count + data.data[i].count;
            invoiceStatus.status2Progress3total = invoiceStatus.status2Progress3total + data.data[i].totalPrice;
          } else {
            if(data.data[i].isInvoiced){
              invoiceStatus.status2Progress2count = data.data[i].count;
              invoiceStatus.status2Progress2total = data.data[i].totalPrice;
            } else {
              invoiceStatus.status2Progress1count = data.data[i].count;
              invoiceStatus.status2Progress1total = data.data[i].totalPrice;
            }
          }
        }
      }
      invoiceStatus.status2total = invoiceStatus.status2Progress1count + invoiceStatus.status2Progress2count + invoiceStatus.status2Progress3count;
      invoiceStatus.status2Progress1percent = (invoiceStatus.status2Progress1count / invoiceStatus.status2total) * 100;
      invoiceStatus.status2Progress2percent = (invoiceStatus.status2Progress2count / invoiceStatus.status2total) * 100;
      invoiceStatus.status2Progress3percent = (invoiceStatus.status2Progress3count / invoiceStatus.status2total) * 100;

      if(invoiceStatus.status2Progress1percent < 10){
        invoiceStatus.status2Progress1percent = 10
      }
      if(invoiceStatus.status2Progress2percent < 10){
        invoiceStatus.status2Progress2percent = 10
      }
      if(invoiceStatus.status2Progress3percent < 10){
        invoiceStatus.status2Progress3percent = 10
      }

      invoiceStatus.isLoading = false;

      state.invoiceStatus = invoiceStatus;
    },

    startPurchase: (state) => {
      state.purchaseStatus.isLoading = true;
      
      state.purchaseStatus.status1Progress1count = 0;
      state.purchaseStatus.status1Progress1total = 0;

      state.purchaseStatus.status1Progress2count = 0;
      state.purchaseStatus.status1Progress2total = 0;
    },
    finishPurchase: (state, action) => {
      let purchaseStatus = state.purchaseStatus;

      let data = action.payload;
      if(data && data.data && data.data.length > 0){
        for(let i = 0; i < data.data.length; i++){
          if(data.data[i].isPurchaseOrdered){
            purchaseStatus.status1Progress1count = data.data[i].count;
            purchaseStatus.status1Progress1total = data.data[i].totalPrice;
          } else {
            purchaseStatus.status1Progress2count = data.data[i].count;
            purchaseStatus.status1Progress2total = data.data[i].totalPrice;
          }
        }
      }
      purchaseStatus.status1total = purchaseStatus.status1Progress1count + purchaseStatus.status1Progress2count;
      purchaseStatus.status1Progress1percent = (purchaseStatus.status1Progress1count / purchaseStatus.status1total) * 100;
      purchaseStatus.status1Progress2percent = (purchaseStatus.status1Progress2count / purchaseStatus.status1total) * 100;
      
      if(purchaseStatus.status1Progress1percent < 10){
        purchaseStatus.status1Progress1percent = 10
      }
      if(purchaseStatus.status1Progress2percent < 10){
        purchaseStatus.status1Progress2percent = 10
      }

      purchaseStatus.isLoading = false;

      state.purchaseStatus = purchaseStatus;
    },

    startExpense: (state) => {
      state.expenseStatus.isLoading = true;
      
      state.expenseStatus.status1Progress1count = 0;
      state.expenseStatus.status1Progress1total = 0;

      state.expenseStatus.status1Progress2count = 0;
      state.expenseStatus.status1Progress2total = 0;
    },
    finishExpense: (state, action) => {
      let expenseStatus = state.expenseStatus;

      let data = action.payload;
      if(data && data.data && data.data.length > 0){
        for(let i = 0; i < data.data.length; i++){
          if(data.data[i].isExpensed){
            expenseStatus.status1Progress1count = data.data[i].count;
            expenseStatus.status1Progress1total = data.data[i].totalPrice;
          } else {
            expenseStatus.status1Progress2count = data.data[i].count;
            expenseStatus.status1Progress2total = data.data[i].totalPrice;
          }
        }
      }
      expenseStatus.status1total = expenseStatus.status1Progress1count + expenseStatus.status1Progress2count;
      expenseStatus.status1Progress1percent = (expenseStatus.status1Progress1count / expenseStatus.status1total) * 100;
      expenseStatus.status1Progress2percent = (expenseStatus.status1Progress2count / expenseStatus.status1total) * 100;
      
      if(expenseStatus.status1Progress1percent < 10){
        expenseStatus.status1Progress1percent = 10
      }
      if(expenseStatus.status1Progress2percent < 10){
        expenseStatus.status1Progress2percent = 10
      }

      expenseStatus.isLoading = false;

      state.expenseStatus = expenseStatus;
    },
  };


  const apis = {
    callJobBillingStatApi: (params = null) => async (dispatch) => {
      dispatch(actions.startBilling());
  
      await dispatchCrudApi.readApi(params, 'job/billable/stat').then(result => {
        let data = result.data.data;
        
        dispatch(actions.finishBilling({ data: data, errorMessage: '', isError: false }));
      }).catch(error => {
        let isError = true;
        let errorMessage = "";

        Utils.parseError(error, (result) => {
          isError = result.isError;
          errorMessage = result.errorMessage;
        });
        
        dispatch(actions.finishBilling({ data: null, errorMessage: errorMessage, isError: isError }));
      });
    },
    
    callJobInvoiceStatApi: (params = null) => async (dispatch) => {
      dispatch(actions.startInvoice());
  
      await dispatchCrudApi.readApi(params, 'job/billing/invoice/stat').then(result => {
        let data = result.data.data;
        
        dispatch(actions.finishInvoice({ data: data, errorMessage: '', isError: false }));
      }).catch(error => {
        let isError = true;
        let errorMessage = "";
        
        Utils.parseError(error, (result) => {
          isError = result.isError;
          errorMessage = result.errorMessage;
        });
        
        dispatch(actions.finishInvoice({ data: null, errorMessage: errorMessage, isError: isError }));
      });
    },

    callPurchaseStatApi: (params = null) => async (dispatch) => {
      dispatch(actions.startPurchase());
  
      await dispatchCrudApi.readApi(params, 'job/billing/purchase-order/stat').then(result => {
        let data = result.data.data;
        
        dispatch(actions.finishPurchase({ data: data, errorMessage: '', isError: false }));
      }).catch(error => {
        let isError = true;
        let errorMessage = "";
        
        Utils.parseError(error, (result) => {
          isError = result.isError;
          errorMessage = result.errorMessage;
        });
        
        dispatch(actions.finishPurchase({ data: null, errorMessage: errorMessage, isError: isError }));
      });
    },
    
    callExpenseStatApi: (params = null) => async (dispatch) => {
      dispatch(actions.startExpense());
  
      await dispatchCrudApi.readApi(params, 'job/billing/expense/stat').then(result => {
        let data = result.data.data;
        
        dispatch(actions.finishExpense({ data: data, errorMessage: '', isError: false }));
      }).catch(error => {
        let isError = true;
        let errorMessage = "";
        
        Utils.parseError(error, (result) => {
          isError = result.isError;
          errorMessage = result.errorMessage;
        });
        
        dispatch(actions.finishExpense({ data: null, errorMessage: errorMessage, isError: isError }));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();