import React, { Component } from 'react';

import { 
    Row,
    Col,
    Modal,
    Button,
    CloseButton,
} from "react-bootstrap-v5";

import Utils from '../../../utils/utils'

import CustomAutosuggest from '../../../components/OLD/Autocomplete/CustomAutosuggest';

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut } from '../../../../setup/redux/dispatch/actions'


class FailedDialog extends Component {
  
    constructor(props){
        super(props);

        this.pageName = 'job-failed-dialog';

        this.state = {
          driverNoteItems: [],
          isLoadingDriverNote: false,
          driverNoteTextValidated: false,
          driverNoteText: '',
          driverNoteId: '',
          driverNoteItem: null,
        }
    }


    componentWillReceiveProps(nextProps) {
      this.reduxProps(nextProps);
    }


    reduxProps = (nextProps) => {
      Utils.reduxProps(nextProps,
        this.pageName + '_driver-note-list', 
        (data, isLoading, isError, err, statusCode, variables, callback) => {
          let arr = [];

          if(data && data.data && data.data.length > 0){
              for(var i = 0; i < data.data.length; i++){
                  let item = data.data[i];
                  let id = item.driverNoteId;
                  let name = item.note;

                  if(arr.findIndex(x => x.value === id) === -1){
                      arr.push({
                          value: id,
                          title: name,
                          item: item
                      });
                  }
              }
          }
          
          this.setState({
            isLoadingDriver: false,
  
            isLoading: isLoading,
            isError: isError,
            err: err,
          }, () => {
            if(this.state.isError){
              this.setState({
                  isLoadingDriver: false,
              });
            }
            
            if(callback){
              callback(arr);
            }
          });
        }
      );
    }


    /* API */
    callDriverNoteReadApi = (page = 1, searchQuery = "", callback = null) => {
      this.setState({
          isLoadingDriver: true,
          driverItems: []
      });
  
      let data = {
          currentPage: page,
          pageSize: 30,
          searchQuery: searchQuery
      };

      this.props.dispatchApiCallGet(data, this.pageName + '_driver-note-list', 'drivernote', null, callback, null);
    }
    /* END API */


    header = () => {
        return <Modal.Header>
            <Row className={'align-items-center gx-0 w-100'}>
                <Col xs={true}>
                    <Modal.Title>Faild job</Modal.Title>
                </Col>
                <Col xs={'auto'}>
                    <CloseButton
                        className={'rounded-2'}
                        onClick={() => {
                            if(this.props.onHide){
                                this.props.onHide();
                            }
                        }}
                    />
                </Col>
            </Row>
        </Modal.Header>
    }
    
    body = () => {
        return <Modal.Body>
            <Row>
                <Col xs={12}>
                    {this.fields()}
                </Col>
            </Row>
        </Modal.Body>
    }

    footer = () => {
        return <Modal.Footer>
            <Row className={'w-100'}>
                <Col xs={true}>
                    
                </Col>
                <Col xs={'auto'}>
                   <Button 
                        type={'button'}
                        variant={'light'}
                        disabled={this.state.isLoading}
                        onClick={() => {
                          if(this.props.onHide) {
                            this.props.onHide()
                          }
                        }}
                    >
                      No
                    </Button>
                </Col>
                <Col xs={'auto'}>
                   <Button 
                        type={'button'}
                        variant={'danger'}
                        disabled={this.state.isLoading}
                        onClick={() => {
                          if(this.props.onOk) {
                            this.props.onOk({
                              driverNoteText: this.state.driverNoteText,
                            })
                          }
                        }}
                    >
                      Yes
                    </Button>
                </Col>
            </Row>
        </Modal.Footer>
    }


    fields = () => {
      return <Row>
        <Col xs={12}>
          <CustomAutosuggest 
            label={'Remarks'}
            placeholder={"Enter remarks"}
            valueName={'value'}
            textName={'title'}
            required={true}
            isCustomRenderSuggestionsContainer={true}
            error={this.state.driverNoteTextValidated}
            value={this.state.driverNoteText}
            items={this.state.driverNoteItems}
            isLoading={this.state.isLoadingDriverNote}
            onChange={(value) => {
                if(value){
                    this.setState({
                        driverNoteText: value.title,
                        driverNoteId: value.value,
                        driverNoteItem: value.item,
                        driverNoteTextValidated: false,
                    });
                } else {
                    this.setState({
                        driverNoteText: '',
                        driverNoteId: '',
                        driverNoteItem: null,
                        driverNoteTextValidated: false,
                    });
                }
            }}
            onSearch={(value, e) => {
                this.setState({
                    driverNoteText: value,
                }, () => {
                    this.callDriverNoteReadApi(1, value, () => {
                        e.showAllSuggestions();
                    });
                });
            }}
            onClick={(e) => {
                this.callDriverNoteReadApi(1, '', (arr) => {
                    this.setState({
                        driverNoteItems: arr
                    }, () => {
                        e.showAllSuggestions();
                    });
                });
            }}
            onInit={() => {
                this.callDriverNoteReadApi(1, '');
            }}
            onClear={() => {
                this.setState({
                    driverNoteText: '',
                    driverNoteId: '',
                    driverNoteItem: null,
                    driverNoteTextValidated: false,
                });
            }}
        />
        </Col>
      </Row>
    }


    render() {
        return <>
            {this.header()}
            {this.body()}
            {this.footer()}
        </>
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  dispatchApiCallPost,
  dispatchApiCallPut,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(FailedDialog);
