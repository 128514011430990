import React from "react";
import _ from 'lodash';

import { 
    Row,
    Col,
    Form,
    Spinner,
    Button,
    InputGroup,
} from "react-bootstrap-v5";

import { DrawerContentLayout } from '../../../components/drawer/DrawerLayout'
import Utils from "../../../utils/utils";

import RSuiteSelectPicker from '../../../components/OLD/Select/RSuiteSelectPicker';

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { commonProfileApiCallGet } from '../../../../setup/redux/commonProfile/actions'


class RolesForm extends React.Component {
  
    constructor(props) {
        super(props);
    
        this.bodyRef = React.createRef();

        let dataParams = (props.dataParams) ? props.dataParams : null;
        let id = (dataParams && dataParams.id) ? dataParams.id : null;
        let row = (dataParams && dataParams.row) ? dataParams.row : null;
        
        this.state = {
            id: id,
            row: row,

            passwordType: true,
        };
    }


    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            'users_form_drawer-role', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.roleId;
                        let title = item.roleName;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );
    }
	
    callReadRoleApi = (items, callback = null) => {
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
        };
     
        this.props.commonProfileApiCallGet(data, 'users_form_drawer-role', 'role', { items: items }, callback, () => {});
    }
    /* END API */


    body = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;
        
        return <Row>
            
            <Col xs={12}>
                <Form.Group className={'mb-5'}>
                    <Form.Label>{this.props.fields.name.label}</Form.Label>
                    <Form.Control
                        type="text"
                        id={this.props.fields.name.id}
                        placeholder={this.props.fields.name.placeholder}
                        value={values.name}
                        onChange={handleChange}
                        isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col xs={12}>
                <Form.Group className={'mb-5'}>
                    <Form.Label>{this.props.fields.password.label}</Form.Label>
                    <InputGroup className={'rounded-1 overflow-hidden'}>
                        <Form.Control
                            type={this.state.passwordType ? 'password' : 'text'}
                            id={this.props.fields.password.id}
                            placeholder={this.props.fields.password.placeholder}
                            value={values.password}
                            onChange={handleChange}
                            isInvalid={!!errors.password}
                        />
                        <InputGroup.Text
                            className={'cursor-pointer'}
                            onClick={() => {
                                this.setState({
                                    passwordType: !this.state.passwordType,
                                });
                            }}
                        >
                            <i className={'material-icons'} style={{ fontSize: '18px' }}>{this.state.passwordType ? 'visibility_off' : 'visibility'}</i>
                        </InputGroup.Text>
                        
                        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
            </Col>
            <Col xs={12}>
                <Form.Group className={'mb-5'}>
                    <Form.Label>{this.props.fields.email.label}</Form.Label>
                    <Form.Control
                        type="text"
                        id={this.props.fields.email.id}
                        placeholder={this.props.fields.email.placeholder}
                        value={values.email}
                        onChange={handleChange}
                        isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col xs={12}>
                <Form.Group className={'mb-5'}>
                    <Form.Label>{this.props.fields.roleId.label}</Form.Label>
                    <Form.Control
                        as = {RSuiteSelectPicker}
                        isInvalid={!!errors.roleId}

                        id={this.props.fields.roleId.id}
                        placeholder={this.props.fields.roleId.placeholder}
                        items={values.customFormParams.roleItems}
                        value={values.roleId}
                        selectedName={values.roleName}
                        isLoading={values.customFormParams.roleIsLoading}
                        searchable={true}
                        cleanable={true}
                        isDefault={true}
                        onClick={async () => {
                            await setFieldValue('customFormParams.roleIsLoading', true);
                            this.callReadRoleApi(values.customFormParams.roleItems, async (items) => {
                                await setFieldValue('customFormParams.roleItems', items);
                                await setFieldValue('customFormParams.roleIsLoading', false);
                            });
                        }}
                        onSelect={async (value, item) => {
                            await setFieldValue('roleId', value);
                            await setFieldValue('roleName', item.title);
                        }}
                        onClear={async () => {
                            await setFieldValue('roleId', null);
                            await setFieldValue('roleName', '');
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.roleId}</Form.Control.Feedback>
                </Form.Group>
            </Col>
            
        </Row>
    }
    
    footer = () => {
        let {
            validateForm,
            values,
        } = this.props.formOptions;

        return <Row>
            <Col xs={'auto'}>
                <Button 
                    type={'submit'}
                    disabled={this.props.isLoading}
                >
                    SAVE
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
            <Col xs={'auto'}>
                <Button 
                    type={'button'}
                    variant={'secondary'}
                    disabled={this.props.isLoading}
                    onClick={() => {
                        Utils.showDrawer(this.props.drawer, false);
                    }}
                >
                    CANCEL
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
            
            
            {values.isEdit && <>
                <Col xs={12} className={'d-flex d-sm-none pb-3'}></Col>
                <Col xs={'auto'} sm={true} className={'text-end'}>
                    <Button 
                        type={'button'}
                        variant={'danger'}
                        disabled={this.props.isLoading}
                        onClick={() => {
                            if(this.props.onDelete){
                                this.props.onDelete(values);
                            }
                        }}
                    >
                        REMOVE
                        {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                    </Button>
                </Col>
                <Col xs={'auto'} md={'auto'}>
                    <Button 
                        type={'button'}
                        variant={'outline-secondary'}
                        disabled={this.props.isLoading}
                        onClick={() => {
                            validateForm().then((err) => {
                                if(_.isEmpty(err)){
                                    if(this.props.onCopy){
                                        this.props.onCopy(values);
                                    }
                                } else {
                                    Utils.toast('You must fill in the required fields', 'error');
                                }
                            })
                        }}
                    >
                        <i className={'material-icons'}>content_copy</i>
                        COPY
                        {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                    </Button>
                </Col>
            </>}

        </Row>
    }


    render() {
        return <DrawerContentLayout
            ref={this.bodyRef}
            isBlocking={this.props.isBlocking}
            body={this.body()}
            footer={this.footer()}
        />
    }
}

const mapDispatchToProps = {
    commonProfileApiCallGet,
    clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(RolesForm);
