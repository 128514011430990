import React, { Component } from 'react';

import { 
    Box,
    Grid,
    Typography,
    Slider,
} from '@material-ui/core';


class CustomSlider extends Component {
  
	constructor(props){
		super(props);

        let className = props.className ? props.className : "";
        let label = props.label ? props.label : "";
        let value = props.value ? props.value : "";
        let min = props.min ? props.min : 0;
        let step = props.step ? props.step : 1;
        let max = props.max ? props.max : 100;
        let startIcon = props.startIcon ? props.startIcon : null;
        let endIcon = props.endIcon ? props.endIcon : null;
        let disabled = ((this.props.disabled === true || this.props.disabled === false) ? this.props.disabled : false);
        
		this.state = {
            label: label,
            value: value,
            disabled: disabled,
            className: className,
            startIcon: startIcon,
            endIcon: endIcon,
            min: min,
            step: step,
            max: max,
		}
	}
    

    componentWillReceiveProps(nextProps) {
        if (nextProps.label !== this.state.label) {
			this.setState({
				label: nextProps.label
			});
        }
        if (nextProps.value !== this.state.value) {
			this.setState({
				value: nextProps.value
			});
		}
        if (nextProps.disabled !== this.state.disabled) {
			this.setState({
				disabled: nextProps.disabled
			});
        }
        if (nextProps.startIcon !== this.state.startIcon) {
			this.setState({
				startIcon: nextProps.startIcon
			});
        }
        if (nextProps.endIcon !== this.state.endIcon) {
			this.setState({
				endIcon: nextProps.endIcon
			});
        }
        if (nextProps.min !== this.state.min) {
			this.setState({
				min: nextProps.min
			});
        }
        if (nextProps.step !== this.state.step) {
			this.setState({
				step: nextProps.step
			});
        }
        if (nextProps.max !== this.state.max) {
			this.setState({
				max: nextProps.max
			});
        }
    }
    
    
	render() {
        return <Box 
            className={"custom-slider-component " + this.state.className}
        >
            {this.state.label && <Typography id="continuous-slider" gutterBottom>{this.state.label}</Typography>}
            <Grid container spacing={2} alignItems={'center'}>
                {this.state.startIcon && <Grid item>{this.state.startIcon}</Grid>}
                <Grid item xs>
                    <Slider 
                        value={this.state.value} 
                        min={this.state.min}
                        step={this.state.step}
                        max={this.state.max}
                        disabled={this.state.disabled} 
                        valueLabelDisplay={"off"}
                        onChange={(event, newValue) => {
                            if(this.props.onChange){
                                this.props.onChange(newValue);
                            }
                        }} 
                    />
                </Grid>
                    {this.state.endIcon && <Grid item>{this.state.endIcon}</Grid>}
            </Grid>
		</Box>;
	}
}

export default CustomSlider;
