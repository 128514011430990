import React, { useState, useRef } from "react";

import { compose, withProps } from "recompose";
import {
  GoogleMap,
  Marker,
  withScriptjs,
  withGoogleMap,
} from "react-google-maps";

const { REACT_APP_GOOGLE_MAP_API_KEY } = process.env;
const URL = "https://maps.googleapis.com/maps/api/js?key=" + REACT_APP_GOOGLE_MAP_API_KEY + "&v=3.exp&libraries=geometry,drawing,places";


const DEF_CENTER = { lat: 1.353915, lng: 103.822901 };
const DEF_ZOOM = 16

         
const StaticGoogleMap = compose(
  withProps({
    googleMapURL: URL,
    loadingElement: <div style={{ width: "100%", height: "100%" }} />,
    containerElement: <div style={{ width: "100%", height: "100%" }} />,
    mapElement: <div style={{ width: "100%", height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap,
)((props) => {
  const [center, setCenter] = useState(props.center ? props.center : DEF_CENTER);
  const [zoom, setZoom] = useState(props.zoom ? props.zoom : DEF_ZOOM);

  const mapRef = useRef(null);


  React.useEffect(() => {
    if(props.onLoad){
      props.onLoad(mapRef.current);
    }
  }, []);

  React.useEffect(() => {
    setCenter(props.center ? props.center : DEF_CENTER);
    
    if(props.onLoad){
      props.onLoad(mapRef.current);
    }
  }, [ props.center ]);

  React.useEffect(() => {
    setZoom(props.zoom ? props.zoom : DEF_ZOOM);
  }, [ props.zoom ]);


  return <GoogleMap
    ref={mapRef}
    center={center}
    zoom={zoom}
    defaultOptions={{
      disableDefaultUI: true,
      disableDoubleClickZoom: true,
      rotateControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      streetViewControlOptions: {
        position: 7,
      },
      mapTypeControl: false,
    }}
  >
    {center && (
      <Marker position={center} />
    )}
  </GoogleMap>
});


export default StaticGoogleMap;
