import React from 'react';

import { Spinner } from 'react-bootstrap-v5';

const LoadingOverlay = props => {
    return <div className={'custom-loading-overlay ' + (props.active ? 'active' : '')}>
        <div className='content'>
            <Spinner animation="border" variant="light" />
            {props.children && <div className={'text'}>{props.children}</div>}
        </div>
    </div>
}


export default LoadingOverlay;