import React from "react";
import Pluralize from "react-pluralize";

import {
    Row,
    Col,
    Form,
    Spinner,
    Button,
} from "react-bootstrap-v5";

import { DrawerContentLayout } from '../../../components/drawer/DrawerLayout'
import Utils from "../../../utils/utils";

import RSuiteSelectPicker from '../../../components/OLD/Select/RSuiteSelectPicker';
import RSuiteDatePicker from '../../../components/OLD/Datepicker/RSuiteDatePicker';
import RSuiteCheckPicker from '../../../components/OLD/Checkbox/RSuiteCheckPicker';

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet } from '../../../../setup/redux/dispatch/actions'
import { commonProfileApiCallGet } from '../../../../setup/redux/commonProfile/actions'

class ReminderForm extends React.Component {

    constructor(props) {
        super(props);

        this.bodyRef = React.createRef();

        let dataParams = (props.dataParams) ? props.dataParams : null;
        let id = (dataParams && dataParams.id) ? dataParams.id : null;
        let row = (dataParams && dataParams.row) ? dataParams.row : null;

        this.state = {
            id: id,
            row: row,
        };
    }

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }

    /* API */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            'reminder-form-drawer_row-customer-list',
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if (data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.customerId;
                        let title = item.customerName;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if (callback) {
                        callback(arr);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            'reminder-form-drawer_row-customer-site-list',
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if (data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.customerSiteId;
                        let title = item.siteName;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if (callback) {
                        callback(arr);
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            'reminder-form-drawer_row-user-list',
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if (data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.userId;
                        let title = item.name;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if (callback) {
                        callback(arr);
                    }
                });
            }
        );
    }

    callReadCustomerApi = (items, callback = null) => {
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            sortColumn: 'customerName',
            sortDir: 'asc',
            isIncludeInactive: false,
            includeServices: false
        };

        this.props.dispatchApiCallGet(data, 'reminder-form-drawer_row-customer-list', 'customer/list', { items: items }, callback, () => { });
    }

    callReadCustomerSiteApi = (customerId, items, callback = null) => {
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            sortColumn: 'siteName',
            sortDir: 'asc',
            isIncludeInactive: false,
            includeServices: false,
            customerId: customerId,
        };

        this.props.dispatchApiCallGet(data, 'reminder-form-drawer_row-customer-site-list', 'customersite/list', { items: items }, callback, () => { });
    }
    
    callReadUserApi = (items, callback = null) => {
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: "",
            isIncludeAll: true,
        };

        this.props.commonProfileApiCallGet(data, 'reminder-form-drawer_row-user-list', 'user', { items: items }, callback, () => { });
    }
    /* END API */

    body = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;

        return <Row>
            <Col xs={6}>
                <Form.Group className={'mb-5'}>
                    <Form.Label>{this.props.fields.customerId.label}</Form.Label>
                    <Form.Control
                        as={RSuiteSelectPicker}
                        isInvalid={!!errors.customerId}

                        id={this.props.fields.customerId.id}
                        placeholder={this.props.fields.customerId.placeholder}
                        items={values.customFormParams.customerItems}
                        value={values.customerId}
                        selectedName={values.customerName}
                        isLoading={values.customFormParams.customerIsLoading}
                        searchable={true}
                        cleanable={true}
                        isDefault={true}
                        onClick={async () => {
                            await setFieldValue('customFormParams.customerIsLoading', true);
                            this.callReadCustomerApi(values.customFormParams.customerItems, async (items) => {
                                await setFieldValue('customFormParams.customerItems', items);
                                await setFieldValue('customFormParams.customerIsLoading', false);
                            });
                        }}
                        onSelect={async (value, item) => {
                            await setFieldValue('customerId', value);
                            await setFieldValue('customerName', item.title);
                            await setFieldValue('customerSiteId', null);
                            await setFieldValue('customerSiteName', '');
                            await setFieldValue('customFormParams.customerSiteItems', []);
                        }}
                        onClear={async () => {
                            await setFieldValue('customerId', null);
                            await setFieldValue('customerName', '');
                            await setFieldValue('customerSiteId', null);
                            await setFieldValue('customerSiteName', '');
                            await setFieldValue('customFormParams.customerSiteItems', []);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.customerId}</Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col xs={6}>
                <Form.Group className={'mb-5'}>
                    <Form.Label>{this.props.fields.customerSiteId.label}</Form.Label>
                    <Form.Control
                        as={RSuiteSelectPicker}
                        isInvalid={!!errors.customerSiteId}

                        id={this.props.fields.customerSiteId.id}
                        placeholder={this.props.fields.customerSiteId.placeholder}
                        items={values.customFormParams.customerSiteItems}
                        value={values.customerSiteId}
                        selectedName={values.customerSiteName}
                        isLoading={values.customFormParams.customerSiteIsLoading}
                        searchable={true}
                        cleanable={true}
                        isDefault={true}
                        disabled={values.customerId == null}
                        onClick={async () => {
                            await setFieldValue('customFormParams.customerSiteIsLoading', true);
                            this.callReadCustomerSiteApi(values.customerId, values.customFormParams.customerSiteItems, async (items) => {
                                await setFieldValue('customFormParams.customerSiteItems', items);
                                await setFieldValue('customFormParams.customerSiteIsLoading', false);
                            });
                        }}
                        onSelect={async (value, item) => {
                            await setFieldValue('customerSiteId', value);
                            await setFieldValue('customerSiteName', item.title);
                        }}
                        onClear={async () => {
                            await setFieldValue('customerSiteId', null);
                            await setFieldValue('customerSiteName', '');
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.customerSiteId}</Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col xs={12}>
                <Form.Group className={'mb-5'}>
                    <Form.Label>{this.props.fields.reminderDescripton.label}</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={5}
                        id={this.props.fields.reminderDescripton.id}
                        placeholder={this.props.fields.reminderDescripton.placeholder}
                        value={values.reminderDescripton}
                        onChange={async (e) => {
                            Utils.limitRows(e.target.value, 5, async (value) => {
                                await setFieldValue('reminderDescripton', value);
                            });
                        }}
                        isInvalid={!!errors.reminderDescripton}
                    />
                    <Form.Control.Feedback type="invalid">{errors.reminderDescripton}</Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col xs={6}>
                <Form.Group className={'mb-5'}>
                    <Form.Label>{this.props.fields.reminderDate.label}</Form.Label>
                    <Form.Control
                        as={RSuiteDatePicker}
                        isInvalid={!!errors.reminderDate}

                        placeholder={this.props.fields.reminderDate.placeholder}
                        id={this.props.fields.reminderDate.id}
                        value={values.reminderDate}
                        icon={<></>}
                        placement={'bottom'}
                        cleanable={true}
                        isDefault={true}
                        onChange={async (value) => {
                            await setFieldValue('reminderDate', value);
                        }}
                        onClear={async () => {
                            await setFieldValue('reminderDate', null);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.reminderDate}</Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col xs={12}>
                <Form.Group className={'mb-5'}>
                    <Form.Label>{this.props.fields.userId.label}</Form.Label>
                    <Form.Control
                        as = {RSuiteCheckPicker}
                        isInvalid={!!errors.userId}
                        
                        id={this.props.fields.userId.id}
                        placeholder={this.props.fields.userId.placeholder}
                        items={values.customFormParams.userItems}
                        value={values.userId}
                        isSelectedAll={values.customFormParams.userIsSelectAll}
                        isLoading={values.customFormParams.userIsLoading}
                        searchable={true}
                        countable={true}
                        cleanable={true}
                        showSelectAll={true}
                        isDefault={true}
                        onClick={async () => {
                            await setFieldValue('customFormParams.userIsLoading', true);
                            this.callReadUserApi(values.customFormParams.userItems, async (items) => {
                                await setFieldValue('customFormParams.userItems', items);
                                await setFieldValue('customFormParams.userIsLoading', false);
                            });
                        }}
                        onSelectAll={async (checked, isIndeterminate) => {
                            if(checked){
                                await setFieldValue('userId', values.customFormParams.userItems.map(x => x.value));
                                await setFieldValue('customFormParams.userIsSelectAll', true);
                            } else {
                                await setFieldValue('userId', []);
                                await setFieldValue('customFormParams.userIsSelectAll', false);
                            }
                        }}
                        onChange={async (values, items) => {
                            await setFieldValue('userId', values);
                            
                            if(items.length > 0){
                                await setFieldValue('customFormParams.userIsSelectAll', (values.length === items.length));
                            } else {
                                await setFieldValue('customFormParams.userIsSelectAll', false);
                            }
                        }}
                        onClear={async () => {
                            await setFieldValue('userId', []);
                            await setFieldValue('customFormParams.userIsSelectAll', false);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.userId}</Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col xs={12}>
                <Form.Group className={'mb-5'}>
                    <Form.Label>{this.props.fields.status.label}</Form.Label>
                    <div className={'pt-3'}>
                        <Form.Check
                            inline
                            type={'radio'}
                            id={this.props.fields.status.id + '_1'}
                            label={'Open'}
                            checked={(values.status === false)}
                            onChange={async (e) => {
                                await setFieldValue('status', false);
                            }}
                        />
                        {values.isEdit && <Form.Check
                            inline
                            type={'radio'}
                            id={this.props.fields.status.id + '_2'}
                            label={'Closed'}
                            checked={(values.status === true)}
                            onChange={async (e) => {
                                await setFieldValue('status', true);
                            }}
                        />}
                    </div>
                </Form.Group>
            </Col>
        </Row>
    }

    footer = () => {
        let {
            values,
        } = this.props.formOptions;

        return <Row>
            <Col xs={'auto'}>
                <Button
                    type={'submit'}
                    disabled={this.props.isLoading}
                >
                    SAVE
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
            <Col xs={'auto'}>
                <Button
                    type={'button'}
                    variant={'secondary'}
                    disabled={this.props.isLoading}
                    onClick={() => {
                        Utils.showDrawer(this.props.drawer, false);
                    }}
                >
                    CANCEL
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
            {(values.isEdit) && <Col className="d-flex justify-content-end">
                <Button
                    type={'button'}
                    variant={'danger'}
                    disabled={this.props.isLoading}
                    onClick={() => {
                        if (this.props.onDelete) {
                            this.props.onDelete(this.props.dataParams);
                        }
                    }}
                >
                    DELETE
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>}
        </Row>
    }


    render() {
        return <DrawerContentLayout
            ref={this.bodyRef}
            isBlocking={this.props.isBlocking}
            body={this.body()}
            footer={this.footer()}
        />
    }
}

const mapDispatchToProps = {
    dispatchApiCallGet,
    commonProfileApiCallGet,
    clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps)(ReminderForm);
