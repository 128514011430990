import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Row, Col } from "react-bootstrap-v5";


const DraggableMultiListByChildren = ({ droppableId = 'droppable', children, init, onDragEnd, layout = 'inline', idField = 'id', group = 'group', sortOrder = 'sortOrder', draggable = 'draggable', itemData = 'itemData', ...rest }) => {
    const [items, setItems] = React.useState([]);


    React.useEffect(() => {
        if(init && init.props && init.props.children){
            setItems(getItems(init.props.children))
        }
    }, [init]);


    const getItems = (children = []) => {
        let groups = [];
        
        if(children && children.length > 0){
            children.forEach((section, index) => {
                let list = [];

                section.props.children.forEach((child, index) => {
                    let item =  {
                        id: droppableId + '-' + index,
                        content: child.props.children,
                        layout: layout,
                        [sortOrder]: child.props[sortOrder],
                        [itemData]: child.props[itemData],
                        [group]: child.props[group],
                        [draggable]: (child.props[draggable] == false) ? false : true,
                        render: (child) => {
                            return (layout == 'inline') ? <div className={'d-md-flex align-items-center'}>{child}</div> : <div>{child}</div>
                        }
                    }

                    list.push(item);
                })

                let disabledList = list.filter(x => x[draggable] === false);
                let enabledList = list.filter(x => x[draggable] === true);

                groups.push({
                    [group]: section.props[group],
                    disabled: disabledList.sort((a, b) => a[sortOrder] - b[sortOrder]),
                    list: enabledList.sort((a, b) => a[sortOrder] - b[sortOrder])
                });
            });
        }

        return groups.sort((a, b) => a[group] - b[group]);
    };

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? '#f2f2f2' : 'white',
        width: '100%'
    });

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: 'none',
        background: isDragging ? '#fffff0' : 'white',
        paddingTop: '5px',
        paddingBottom: '5px',
        ...draggableStyle
    });

    const dragHandle = (provided, item) => {
        return <i 
            style={{
                cursor: (item && item.draggable) ? 'grab' : 'default',
                opacity: (item && item.draggable) ? '1' : '0',
                paddingRight: '15px',
                paddingBottom: '2px',
            }}
            {...provided.dragHandleProps}
            className={'material-icons'}
        >menu</i>
    };

    const reorder = (result) => {
        const sourceGroup = result.source.droppableId.split(':')[1];
        const sourceIndex = result.source.index;
        const sourceList = items.find(x => x[group] == sourceGroup).list;

        const destinationGroup = result.destination.droppableId.split(':')[1];
        const destinationIndex = result.destination.index;
        const destinationList = items.find(x => x[group] == destinationGroup).list;
        
        const item = sourceList[sourceIndex];
        sourceList.splice(sourceIndex, 1);
        destinationList.splice(destinationIndex, 0, item);

        sourceList.forEach((item, index) => item.sortOrder = (index+1));
        destinationList.forEach((item, index) => item.sortOrder = (index+1));
        
        setItems([...items]);

        if(onDragEnd){
            onDragEnd({
                items: items,
                sourceGroup: sourceGroup,
                sourceIndex: sourceIndex,
                destinationGroup: destinationGroup,
                destinationIndex: destinationIndex,
            });
        }
    };

    
    return <>
        <DragDropContext
            onDragEnd={(result) => {
                if (!result.destination)
                    return;
                    
                reorder(result);
            }}
        >
            {items.map((itm, i) => {
                return <React.Fragment key={i}>
                    <Row>
                        <Col xs={12}>
                            <h5 className={'mb-0'}>SECTION {i+1}</h5>
                        </Col>
                        <Col xs={12}>
                            <hr />
                        </Col>
                        <Col xs={12} className={'mb-10 mt-10'}>
                            {(itm.disabled && itm.disabled.length > 0) && itm.disabled.map((item, index) => {
                                return <div key={'disabled_' + droppableId + '_' + i + '_' + index}>
                                    {
                                        (item.layout !== 'inline')
                                        ?
                                        item.render(<>
                                            <div 
                                                style={{
                                                    width: '35px',
                                                    display: 'inline-block',
                                                    height: '24px',
                                                    position: 'relative',
                                                    top: '7px'
                                                }}
                                            >
                                                <i 
                                                    style={{
                                                        cursor: 'default',
                                                        opacity: '0',
                                                        paddingRight: '15px',
                                                        paddingBottom: '2px',
                                                    }}
                                                    className={'material-icons'}
                                                >menu</i>
                                            </div>
                                            {item.content}
                                        </>)
                                        :
                                        item.render(<>
                                            <i 
                                                style={{
                                                    cursor: 'default',
                                                    opacity: '0',
                                                    paddingRight: '15px',
                                                    paddingBottom: '2px',
                                                }}
                                                className={'material-icons'}
                                            >menu</i>
                                            {item.content}
                                        </>)
                                    }
                                </div>
                            })}

                            <Droppable 
                                droppableId={droppableId + '_' + i + ':' + itm[group]}
                            >
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                    >
                                        {(itm.list && itm.list.length > 0) && itm.list.map((item, index) => {
                                            return <Draggable 
                                                key={droppableId + '_' + i + '_' + index} 
                                                draggableId={droppableId + '_' + i + '_' + index} 
                                                index={index}
                                                isDragDisabled={!item.draggable}
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                    >
                                                        {
                                                            (item.layout !== 'inline')
                                                            ?
                                                            item.render(<>
                                                                <div 
                                                                    style={{
                                                                        width: '35px',
                                                                        display: 'inline-block',
                                                                        height: '24px',
                                                                        position: 'relative',
                                                                        top: '7px'
                                                                    }}
                                                                >
                                                                    {dragHandle(provided, item)}
                                                                </div>
                                                                {item.content}
                                                            </>)
                                                            :
                                                            item.render(<>
                                                                {dragHandle(provided, item)}
                                                                {item.content}
                                                            </>)
                                                        }
                                                    </div>
                                                )}
                                            </Draggable>
                                        })}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </Col>  
                    </Row>  
                </React.Fragment>
            })}
        </DragDropContext>
    </>
}


export default DraggableMultiListByChildren;
