import React from 'react';

import {
    Box,
    Checkbox
} from '@material-ui/core';


const CustomCheckBox = props => {
    const { index, checked, indeterminate, label, desc, disabled, color, bold, className, onChange, onClick, children } = props;

    const [indexCB] = React.useState(index ? index : 0);
    const [checkedCB, setCheckedCB] = React.useState(checked);
    const [indeterminateCB, setIndeterminateCB] = React.useState(indeterminate);
    const [labelCB, setLabelCB] = React.useState(label);
    const [descCB] = React.useState(desc);
    const [disabledCB] = React.useState(disabled);
    const [colorCB] = React.useState(color ? color : "primary");
    const [boldCB] = React.useState(bold);


    React.useEffect(() => {
        setCheckedCB(checked);
        setIndeterminateCB(indeterminate);
        setLabelCB(label);
    }, [checked, label]);


    return (
        <Box className={"custom-checkbox-component " + className}>
            <Box>
                <Checkbox 
                    indeterminate={indeterminateCB}
                    checked={checkedCB}
                    disabled={disabledCB}
                    color={colorCB}
                    onClick={(e) => {
                        setCheckedCB(!checkedCB);

                        if(onClick){
                            onClick(e);
                        }

                        if(onChange){
                            onChange(indexCB, !checkedCB);
                        }
                    }}
                />
                {labelCB && <label
                    className={"checkbox-label-style" + (boldCB ? ' boldCB' : '')}
                    onClick={() => {
                        if(!disabledCB){
                            setCheckedCB(!checkedCB);

                            if(onChange){
                                onChange(indexCB, !checkedCB);
                            }
                        }
                    }}
                >
                    {labelCB}
                    {(descCB && descCB !== '') && <div className="desc-muted">{descCB}</div>}
                </label>}
            </Box>
            {(children && checkedCB) && <Box>{children}</Box>}
        </Box>
    );
  }

  export default CustomCheckBox;