/* eslint-disable no-loop-func */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import * as dispatchCrudApi from '../../../../api/CRUD/DispatchCRUD'
import Utils from "../../../../utils/utils";
import { AccountCustomFieldType } from "../../../../utils/enums";
import { ParseResult } from "../../../../utils/interfaces";


interface InitState {
  isLoading: boolean,
  items: Array<any>,
  item: any,
}

interface ApiCallArgs {
  label: string,
  accountCustomFieldId?: number,
}


function NewReducer() {
  const name = 'weighChitCustomFields';


  const initialState: InitState = {
    isLoading: false,
    items: [],
    item: null,
  };


  const reducers = {
    startRead: (state: InitState) => {
      state.item = null;
      state.items = [];
      state.isLoading = true;
    },
    finishRead: (state: InitState, action: PayloadAction<{ data: any, errorMessage: string|null, isError: boolean }>) => {
      let data = (action.payload && action.payload.data  && action.payload.data.length > 0) ? action.payload.data : [];
      if(data && data.length > 0){
        data = data.sort((a: any, b: any) => a.accountDocSettingId - b.accountDocSettingId).map((itm: any, i: number) => {
          itm['customFieldLabel'] = 'Custom ' + (i+1)
          return itm;
        })
      }
      
      state.item = null;
      state.items = data;
      state.isLoading = false;
    },
    
    startUpdate: (state: InitState) => {
      state.item = null;
      state.isLoading = true;
    },
    finishUpdate: (state: InitState, action: PayloadAction<{ data: any, errorMessage: string|null, isError: boolean }>) => {
      let data = (action.payload && action.payload.data) ? action.payload.data : null;

      state.item = data;
      state.isLoading = false;
    },
    
    startDelete: (state: InitState) => {
      state.item = null;
      state.isLoading = true;
    },
    finishDelete: (state: InitState, action: PayloadAction<{ data: any, errorMessage: string|null, isError: boolean }>) => {
      let data = (action.payload && action.payload.data) ? action.payload.data : null;

      state.item = data;
      state.isLoading = false;
    },
  };


  const apis = {
    callReadApi: () => async (dispatch: any) => {
      dispatch(actions.startRead());

      let params: any = {
        types: AccountCustomFieldType.WeighChit,
      };
      
      await dispatchCrudApi.readApi(params, 'account/custom-field').then((result: any) => {
        dispatch(actions.finishRead({ data: result.data, errorMessage: null, isError: false }));
      }).catch((error: any) => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        dispatch(actions.finishRead({ data: null, errorMessage: err, isError: true }));
      });
    },

    callUpdateApi: (params: ApiCallArgs) => async (dispatch: any) => {
      dispatch(actions.startUpdate());
      
      const args: any = { ...params, type: AccountCustomFieldType.WeighChit };

      await dispatchCrudApi.updateApi(args, 'account/custom-field').then((result: any) => {
        dispatch(actions.finishUpdate({ data: result.data, errorMessage: null, isError: false }));
      }).catch((error: any) => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        dispatch(actions.finishUpdate({ data: null, errorMessage: err, isError: true }));
      });
    },
    
    callDeleteApi: (params: ApiCallArgs) => async (dispatch: any) => {
      dispatch(actions.startDelete());
      
      const args: any = { ...params, type: AccountCustomFieldType.WeighChit };

      await dispatchCrudApi.deleteApi(args, 'account/custom-field').then((result: any) => {
        dispatch(actions.finishDelete({ data: result.data, errorMessage: null, isError: false }));
      }).catch((error: any) => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        dispatch(actions.finishDelete({ data: null, errorMessage: err, isError: true }));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();