import React from 'react';

import { 
    Row,
    Col,
    Modal,
    Form,
    InputGroup,
    Button,
    CloseButton,
} from "react-bootstrap-v5";

import Utils from '../../utils/utils';

  
const SearchDialog = props => {
    const [show, setShow] = React.useState(((props.show === false) || (props.show === true)) ? props.show : false);
    
    const inputRef = React.useRef(null);


    React.useEffect(() => {
        setShow(((props.show === false) || (props.show === true)) ? props.show : false);
    }, [props.show]);

    
    return <Modal
        className={props.className}
        show={show}
        onHide={() => {
            if(props.onCancel){
                props.onCancel();
            }
        }}
        size="lg"
        keyboard={false}
        animation={false}
    >
        <Modal.Header>
            <Row className={'align-items-center gx-0 w-100'}>
                <Col xs={true}>
                    <Modal.Title>{props.title ? props.title : 'Search'}</Modal.Title>
                </Col>
                <Col xs={'auto'}>
                    <CloseButton
                        className={'rounded-2'}
                        onClick={() => {
                            if(props.onCancel){
                                props.onCancel();
                            }
                        }}
                    />
                </Col>
            </Row>
        </Modal.Header>
        <Modal.Body>
            <form 
                noValidate 
                autoComplete="off"
                onSubmit={(e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    let value = '';
                    if(inputRef && inputRef.current){
                        value = inputRef.current.value;
                    }

                    if(props.onSearch){
                        props.onSearch(value);
                    }
                }}
            >
                <InputGroup>
                    <Form.Control 
                        ref={inputRef}
                        placeholder={props.placeholder ? props.placeholder : 'Search...'}
                        autoFocus
                        
                        onChange={(e) => {
                            Utils.escapeChars(e);
                        }}
                        onKeyPress={(e) => {
                            Utils.escapeChars(e);
                        }}
                        onPaste={(e) => {
                            const pastedText = e.clipboardData.getData('text');
                            const cleanedText = Utils.escapeCharsInStringOnPaste(pastedText);
                            
                            if(inputRef && inputRef.current){
                                inputRef.current.value = cleanedText;
                            }
                            
                            e.preventDefault();
                        }}
                    />
                    <Button type={'submit'}>
                        {props.icon ? props.icon : <i className={'material-icons'}>search</i>}
                        {props.text ? props.text : 'Search'}
                    </Button>
                </InputGroup>
            </form>
        </Modal.Body>
    </Modal>
}


export default SearchDialog;
