import clsx from 'clsx'
import React, {FC} from 'react'
import { useHistory } from "react-router-dom";
import moment from 'moment';
import {shallowEqual, useSelector, connect, useDispatch, ConnectedProps} from 'react-redux'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import HeaderUserMenu from '../../../partials/layout/header-menus/HeaderUserMenu'
import {MenuItem} from './MenuItem'

import {UserModel} from '../../../../app/views/account/models/UserModel'
import {RootState} from '../../../../setup'

import { ReactComponent as ReminderIcon } from "../../../assets/img/icons/report-mail.svg";
import { ReactComponent as AlertIcon } from "../../../assets/img/icons/report-mail.svg";

// import {DrawerEnum} from '../../../../app/components/drawer/DrawerLayout'

import DefaultImage from "../../../../_metronic/assets/img/default_image.jpg";

import SignalR from "../../../../app/components/signalR/SignalR.jsx";
import { ReceiveMessage } from "../../../../app/components/signalR/Enums.jsx";

import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut, dispatchApiCallDelete } from '../../../../setup/redux/dispatch/actions'
import Utils from '../../../../app/utils/utils';
import { PagesPermissions } from '../../../../app/utils/enums';

import UIfx from "uifx";
const beepMp3 = require("../../../assets/sound/beep.mp3");

const beep = new UIfx(beepMp3);

const { REACT_APP_REAL_TIME_URL } = process.env;

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const [permissions] = React.useState((user && user.permissions && user.permissions.length > 0) ? user.permissions : []);

  const dispatchCrud = useSelector<any>(state => state.dispatchCrud);
  const dispatch = useDispatch();
  let history = useHistory();

  const [count, setCount] = React.useState(0);

  let profileImageUrl = (user.profileImageUrl) ? user.profileImageUrl : DefaultImage;
  

  React.useEffect(() => {
    callAccountNotificationCountApi();
  }, []);

  React.useEffect(() => {
      Utils.reduxPropsTS(dispatchCrud,
        'toolbar-accountnotification-count', 
        (data: any, isLoading: any, isError: any, err: any, statusCode: any, variables: any, callback: (arg0: any) => void) => {
          let count = (data && data > 0) ? data : 0;
          Utils.setFavNumb(count);
          setCount(count);

          if(callback){
            callback(data);
          }
        }
    );
  }, [dispatchCrud]);

  function callAccountNotificationCountApi (callback = null) {
    let fromDate = moment().format(Utils.getAPIDateFormat());
    let toDate = moment().format(Utils.getAPIDateFormat());

    let data = {
      isRead: false,
      type: null,
      fromDate: fromDate,
      toDate: toDate,
    };

    dispatch(dispatchApiCallGet(data, 'toolbar-accountnotification-count', 'accountnotification/count', null, callback, null));
  }


  
  const setSignalR = () => {
    let groupAccountEmail = user && user.userAccount && user.userAccount.accountEmail ? user.userAccount.accountEmail : "";
    let accountEmail = user && user.userAccount && user.userAccount.accountEmail ? user.userAccount.accountEmail : "";

    return (
      <SignalR
        url={REACT_APP_REAL_TIME_URL}
        group={groupAccountEmail}
        onReceiveMessage={(type: string, msg: { updatedBy: any; createdBy: any; }) => {
          if (accountEmail !== msg.updatedBy) {
            callAccountNotificationCountApi();

            if(type === ReceiveMessage.CreateJob) {
              if (accountEmail !== msg.updatedBy) {
                beep.play();
              }
            } else if (type === ReceiveMessage.UpdateJob) {
              if (accountEmail !== msg.updatedBy) {
                beep.play();
              }
            } else if (type === ReceiveMessage.UpdateMessage) {
              if (accountEmail !== msg.createdBy) {
                beep.play();
              }
            }
          }
        }}
      />
    );
  };


  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='topbar d-flex align-items-stretch flex-shrink-0'>
        
        {/* Quick links */}
        <div 
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        >
          <a href="/reminders"
              className={clsx(
                'btn btn-icon btn-active-light-primary link-dark',
                toolbarButtonHeightClass
              )}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                history.push('/reminders');
              }}
          >
            <span className={'material-icons fs-22'}>access_alarm</span>
          </a>
        </div>
        <div 
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        >
          <a href="/alert"
              className={clsx(
                'position-relative btn btn-icon btn-active-light-primary link-dark',
                toolbarButtonHeightClass
              )}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                history.push('/alert');
              }}
          >
            <span className={'material-icons fs-22'}>notifications_none</span>
            {(count > 0) && <span 
              className="position-absolute translate-middle badge rounded-pill bg-danger"
              style={{
                width: '15px',
                height: '15px',
                lineHeight: '15px',
                fontSize: '8px',
                padding: '0px',
                left: '30px',
                top: '9px',
                zIndex: '10',
              }}
            >{count}</span>}
          </a>
        </div>
        
        {(Utils.hasPermission(permissions, PagesPermissions.AuditTrail.read)) && <div 
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        >
          <a href="/reminders"
              className={clsx(
                'btn btn-icon btn-active-light-primary link-dark',
                toolbarButtonHeightClass
              )}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                history.push('/audit-trail');
              }}
          >
            <span className={'material-icons fs-22'}>history</span>
          </a>
        </div>}

        {/* begin::User */}
        <div
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
          id='kt_header_user_menu_toggle'
        >
          {/* begin::Toggle */}
          <div
            className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <img
              className='h-30px w-30px rounded'
              src={profileImageUrl}
              alt='metronic'
            />
          </div>
          <HeaderUserMenu />
          {/* end::Toggle */}
        </div>
        {/* end::User */}
      </div>

      {setSignalR()}
    </div>
  )
}

export {Topbar}
