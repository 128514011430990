import React from 'react';

import { 
  Container,
  Spinner,
  // Button,
  // CloseButton,
  Modal,
  Row,
  Col,
} from "react-bootstrap-v5";


import { useDispatch, useSelector } from "react-redux";
import { dispatchApiCallGet } from '../../../../setup/redux/dispatch/actions'
import cancelToken from "../../../../setup/redux/slices/cancelToken";

import Utils from '../../../utils/utils';
import { StyledBlockerLoading, StyledOverlay } from "../../../utils/styles";


const ViewLayout = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const dispatchCrud = useSelector(state => state.dispatchCrud);

  const [isCheckDueDateInvocieDialog, setIsCheckDueDateInvocieDialog] = React.useState(false);


  React.useEffect(() => {
    callCheckDueInvoiceApi((isExist) => {
      setIsCheckDueDateInvocieDialog(isExist);
    });
    
    return () => {
      dispatch(cancelToken.cancel());
    }
  }, []);

  React.useEffect(() => {
    Utils.reduxProps(dispatchCrud,
      'billing_layout-check_due_invoice', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        if(callback){
          callback(data);
        }
      }
    );
  }, [dispatchCrud]);


  const callCheckDueInvoiceApi = (callback = null) => {
    dispatch(dispatchApiCallGet(null, 'billing_layout-check_due_invoice', 'AccountSubscriptionBilling/check-due-invoice', null, callback, () => {}));
  }
  
  const checkDueInvoiceDialog = () => {
    return <Modal
      show={isCheckDueDateInvocieDialog}
      onHide={() => {
        setIsCheckDueDateInvocieDialog(false);
      }}
      centered
      backdrop="static"
      size="md"
      keyboard={false}
    >
      <Modal.Header>
          <Row className={'align-items-center gx-0 w-100'}>
            <Col xs={true}>
              <Modal.Title>Subscription has expired</Modal.Title>
            </Col>
            {/* <Col xs={'auto'}>
              <CloseButton
                className={'rounded-2'}
                onClick={() => {
                  setIsCheckDueDateInvocieDialog(false);
                }}
              />
            </Col> */}
          </Row>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12} className={'pb-5'}>
            You have overdue payments for your WastePorter subscription.<br />
            If payment remains outstanding, your subscription will not be renewed and service shall be discontinued. 
          </Col>
          <Col xs={12} className={'pb-5'}>
            To make payment for your subscription go to tab:<br />
            <a href={"/manage/setting/subscription"} 
              className={"main-link"}
            >
              <b>Manage</b> > <b>My Subscription</b>.
            </a>
          </Col>
          <Col xs={12}>
            If you have any questions, contact <a className={'text-primary'} href={'mailto:hello@wasteporter.com'}>hello@wasteporter.com</a>
          </Col>
        </Row>  
      </Modal.Body>
      {/* <Modal.Footer>
        <Button 
          type={'button'}
          variant={'danger'}
          onClick={() => {
            setIsCheckDueDateInvocieDialog(false);
          }}
        >
          OK
        </Button>
      </Modal.Footer> */}
    </Modal>
  }

  return <>
      <div className={'view-layout'}>
        <div className={'sections'}>
            {props.topSection && <div className={'custom-top-section '}
            style={{ 
              top: 65 + 'px',
              zIndex: '2'
            }}
          >
            <Container className={'px-4 py-8'}>{props.topSection}</Container>
          </div>}

          <Container className={'px-5'}>
              {props.statusSection && <div className={'custom-status-section mb-5'}>
                <Container className={'p-0'}>{props.statusSection}</Container>
              </div>}

              {props.tableSection && <div className={'custom-table-section mt-10'}>
                <Container className={'p-0'}>{props.tableSection}</Container>
              </div>}
              
              {props.paginationSection && <div className={'custom-pagination-section bg-color'}>
                <Container className={'p-0'}>{props.paginationSection}</Container>
              </div>}
          </Container>
        </div>
      </div>

      {checkDueInvoiceDialog()}

      {props.isBlocking && <StyledBlockerLoading>
        <StyledOverlay />
        <Spinner animation="border" variant="primary" />
      </StyledBlockerLoading>}
    </>
});

export default ViewLayout;