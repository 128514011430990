import React from "react";

import { 
  Row,
  Col,
} from "react-bootstrap-v5";


const ViewTopSection = React.forwardRef((props, ref) => {
  return <div style={{ display: 'flex', minHeight: '69px' }}>
    <Row style={{ flexBasis: '100%', margin: '0px' }}>
      <Col xs={12} className={'px-1'}>
        <div className={'px-2 py-4'}>{props.hasChecked ? props.checked : props.unchecked}</div>
      </Col>
    </Row>
  </div>
});


export default ViewTopSection;