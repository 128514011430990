import React from "react";

import styled from 'styled-components';

import {
    ReportTable,
    ReportThead,
    ReportTbody,
    ReportTr,
    ReportTh,
    ReportTd,
} from "../../../components/OLD/Pages/Reports/CustomTable.jsx";

import Utils from "../../../utils/utils";
import { ReportUOM } from "../../../utils/enums";

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut, dispatchApiCallDelete } from '../../../../setup/redux/dispatch/actions'



const ReportTableStyle = styled(ReportTable)`
    width: 100%;

    thead {
        tr {
            border-bottom: 1px solid #DFDFDF;

            th {
                color: #7A7A7A;
                padding-bottom: 5px;
            }
        }
    }

    tbody {
        tr {
            td {
                color: #7A7A7A;
                padding-top: 3px;
                padding-bottom: 3px;
            }

            &:hover {
                td {
                    background-color: #EFF6E4;
                }
            }
        }
    }
`;


class GlobalServiceHistoryForm extends React.Component {
  
    constructor(props) {
        super(props);

        this.pageName = 'global_service_history_form';

        let id = Utils.getIntProps(props);
        let objectId = Utils.getIntProps(props, 'objectId');
        let objectType = Utils.getIntProps(props, 'objectType');
        
        this.state = {
            id: id,
            objectId: objectId,
            objectType: objectType,

            currentVersion: null,
            previousVersion: null,
        };
    }


    componentDidMount(){
        this.callDetailsApi((currentVersion, previousVersion) => {
            this.setState({
                currentVersion: currentVersion,
                previousVersion: previousVersion,
            }, () => {
                this.setLoading(false);
            });
        });
    }

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            this.pageName + '-details', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let currentVersion = (data && data.currentVersion) ? data.currentVersion : null;
                let previousVersion = (data && data.previousVersion) ? data.previousVersion : null;
                
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(currentVersion, previousVersion);
                    }
                });
            }
        );
    }
	
    callDetailsApi = (callback = null) => {
        if(this.state.id){
            this.setLoading(true);
            this.props.dispatchApiCallGet(null, this.pageName + '-details', 'audittrail/compare-audit-trail/' + this.state.objectType + '/' + this.state.objectId + '/' + this.state.id, null, callback, () => {});
        } else {
            if(callback){
                callback(null, null)
            }
        }
    }
    /* END API */


    /* FUNCTIONS */
    setLoading = (state = false) => {
        if(this.props.onLoading){
            this.props.onLoading(state);
        }
    }

    setRow = (name = null, prevVal = null, currVal = null, isDiff = false) => {
        return <ReportTr>
            <ReportTd>{name ? name : '-'}</ReportTd>
            <ReportTd>{prevVal ? prevVal : '-'}</ReportTd>
            <ReportTd style={{ color: (isDiff ? '#185CFF' : '') }}>{currVal ? currVal : '-'}</ReportTd>
        </ReportTr>
    }
    /* END FUNCTIONS */


    /* FORM */
    printTableHeader = () => {
        return <ReportThead>
            <ReportTr>
                <ReportTh style={{ width: '33%' }}>Fields</ReportTh>
                <ReportTh style={{ width: '33%' }}>Previous</ReportTh>
                <ReportTh style={{ width: '33%' }}>Current</ReportTh>
            </ReportTr>
        </ReportThead>
    }
    
    printMainTable = () => {
        return <ReportTableStyle className={"no-responsive-table"}>
            <>
                {this.printTableHeader()}
                {this.state.currentVersion && this.printMainTableBody()}
            </>
        </ReportTableStyle>
    }
    printMainTableBody = () => {
        return <ReportTbody>
            {Utils.getAuditTrailValue(this.state.previousVersion, this.state.currentVersion, this.setRow, 'Service Name', 'serviceTag')}
            {Utils.getAuditTrailValue(this.state.previousVersion, this.state.currentVersion, this.setRow, '', null, () => {
                let title = 'Waste Type';
                let group = 'wasteType';
                let field = 'wasteTypeName';

                let prevItem = Utils.getPreviousValue(this.state.previousVersion, group);
                let currItem = Utils.getCurrentValue(this.state.currentVersion, group);

                let prevValue = (prevItem && prevItem[field]) ? prevItem[field] : '-';
                let currValue = (currItem && currItem[field]) ? currItem[field] : '-';
                let isDifferentValue = Utils.isDifferentValue(prevValue, currValue);
                
                return this.setRow(title, prevValue, currValue, isDifferentValue);
            })}
            {Utils.getAuditTrailValue(this.state.previousVersion, this.state.currentVersion, this.setRow, '', null, () => {
                let title = 'Bin Type';
                let group = 'binType';
                let field = 'binTypeName';

                let prevItem = Utils.getPreviousValue(this.state.previousVersion, group);
                let currItem = Utils.getCurrentValue(this.state.currentVersion, group);

                let prevValue = (prevItem && prevItem[field]) ? prevItem[field] : '-';
                let currValue = (currItem && currItem[field]) ? currItem[field] : '-';
                let isDifferentValue = Utils.isDifferentValue(prevValue, currValue);
                
                return this.setRow(title, prevValue, currValue, isDifferentValue);
            })}
            {Utils.getAuditTrailValue(this.state.previousVersion, this.state.currentVersion, this.setRow, 'Description', 'description')}
            {Utils.getAuditTrailValue(this.state.previousVersion, this.state.currentVersion, this.setRow, 'Rate', 'price', () => {
                let title = 'Rate';
                let group = 'price';

                let prevItem = Utils.getPreviousValue(this.state.previousVersion, group);
                let currItem = Utils.getCurrentValue(this.state.currentVersion, group);

                let prevValue = (prevItem) ? Utils.formatNumber(prevItem) : '-';
                let currValue = (currItem) ? Utils.formatNumber(currItem) : '-';
                let isDifferentValue = Utils.isDifferentValue(prevValue, currValue);
                
                return this.setRow(title, prevValue, currValue, isDifferentValue);
            })}
            {Utils.getAuditTrailValue(this.state.previousVersion, this.state.currentVersion, this.setRow, 'Is tax inclusive', 'isTaxInclusive', () => {
                let title = 'Is tax inclusive';
                let group = 'isTaxInclusive';

                let prevItem = Utils.getCurrentPreviousBoolValue(this.state.previousVersion, group);
                let currItem = Utils.getCurrentPreviousBoolValue(this.state.currentVersion, group);

                let prevValue = (prevItem) ? prevItem : '-';
                let currValue = (currItem) ? currItem : '-';
                let isDifferentValue = Utils.isDifferentValue(prevValue, currValue);
                
                return this.setRow(title, prevValue, currValue, isDifferentValue);
            })}
            {Utils.getAuditTrailValue(this.state.previousVersion, this.state.currentVersion, this.setRow, 'Billing Action', 'billingTypeDisplay')}
            {Utils.getAuditTrailValue(this.state.previousVersion, this.state.currentVersion, this.setRow, '', null, () => {
                let title = 'Billing Category';
                let group = 'chargeCategory';
                let field = 'chargeCategoryName';

                let prevItem = Utils.getPreviousValue(this.state.previousVersion, group);
                let currItem = Utils.getCurrentValue(this.state.currentVersion, group);

                let prevValue = (prevItem && prevItem[field]) ? prevItem[field] : '-';
                let currValue = (currItem && currItem[field]) ? currItem[field] : '-';
                let isDifferentValue = Utils.isDifferentValue(prevValue, currValue);
                
                return this.setRow(title, prevValue, currValue, isDifferentValue);
            })}
            
            {Utils.getAuditTrailValue(this.state.previousVersion, this.state.currentVersion, this.setRow, 'Link To Bin Weight', 'useBinWeightQuantity', () => {
                let title = 'Link To Bin Weight';
                let group = 'useBinWeightQuantity';

                let prevItem = Utils.getCurrentPreviousBoolValue(this.state.previousVersion, group);
                let currItem = Utils.getCurrentPreviousBoolValue(this.state.currentVersion, group);

                let prevValue = (prevItem) ? prevItem : '-';
                let currValue = (currItem) ? currItem : '-';
                let isDifferentValue = Utils.isDifferentValue(prevValue, currValue);
                
                return this.setRow(title, prevValue, currValue, isDifferentValue);
            })}
            {Utils.getAuditTrailValue(this.state.previousVersion, this.state.currentVersion, this.setRow, 'Non-Billable Weight To', 'nonBillableWeight', () => {
                let title = 'Non-Billable Weight To';
                let group = 'nonBillableWeight';

                let prevItem = Utils.getPreviousValue(this.state.previousVersion, group);
                let currItem = Utils.getCurrentValue(this.state.currentVersion, group);

                let prevValue = (prevItem) ? Utils.formatNumber(prevItem) : '-';
                let currValue = (currItem) ? Utils.formatNumber(currItem) : '-';
                let isDifferentValue = Utils.isDifferentValue(prevValue, currValue);
                
                return this.setRow(title, prevValue, currValue, isDifferentValue);
            })}
        </ReportTbody>;
    }

    printBundleTable = () => {
        return (this.state.currentVersion && this.state.currentVersion.bundleServices && this.state.currentVersion.bundleServices.length > 0) && this.printBundleTableBody()
    }
    printBundleTableBody = () => {
        const id = 'serviceItemId';
        const fields = [
            {
                value: ['serviceTag'],
                title: 'Bundle Name',
            },
            {
                value: ['price'],
                title: 'Rate',
                format: (data) => {
                    return data.value ? Utils.getCurrency() + Utils.formatNumber(data.value) : data.value
                }
            },
            {
                value: ['isTaxInclusive'],
                title: 'Is tax inclusive',
                format: (data) => {
                    return data.value ? 'Yes' : 'No'
                }
            },
            {
                value: ['billingTypeDisplay'],
                title: 'Billing Action',
            },
            {
                value: ['chargeCategory', 'chargeCategoryName'],
                title: 'Billing Category',
            },
            {
                value: ['useBinWeightQuantity'],
                title: 'Link To Bin Weight',
                format: (data) => {
                    return data.value ? 'Yes' : 'No'
                }
            },
            {
                value: ['nonBillableWeight'],
                title: 'Non-Billable Weight To',
                format: (data) => {
                    return data.row.useBinWeightQuantity ? (Utils.getBillingUOM() == ReportUOM.kg) ? Utils.formatNumber(data.value, '0,0') : Utils.formatNumber(data.value, '0,0.000') : ''
                }
            }
        ];
        const arr1 = (this.state.currentVersion && this.state.currentVersion.bundleServices && this.state.currentVersion.bundleServices.length > 0) ? this.state.currentVersion.bundleServices : [];
        const arr2 = (this.state.previousVersion && this.state.previousVersion.bundleServices && this.state.previousVersion.bundleServices.length > 0) ? this.state.previousVersion.bundleServices : [];
        const differences = Utils.getDifferences(arr1, arr2, id);
        return Utils.printTable(differences, fields);
    }
    /* END FORM */


    render() {
        return <div style={{ width: '100%' }}>
            <div>{this.printMainTable()}</div>
            <div className={'pt-10'}>{this.printBundleTable()}</div>
        </div>
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  dispatchApiCallPost,
  dispatchApiCallPut,
  dispatchApiCallDelete,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(GlobalServiceHistoryForm);
