import React from "react";
import moment from "moment";

import {
  Button,
  Row,
  Col,
  Spinner
} from "react-bootstrap-v5";

import Utils from "../../../utils/utils";

import RSuiteDateRangePickerCostume from "../../../components/OLD/Datepicker/RSuiteDateRangePickerCostume";

class ViewTopSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      thisDate: moment().format(Utils.getAPIDateFormat())
    }
  }


  datePicker() {
    return <div className={'date-range-picker'}>
      <RSuiteDateRangePickerCostume
        className={''}
        placeholder={"Select date"}
        ranges={Utils.getDateRanges(['Last 6 Months'])}
        value={this.props.dateRange}
        cleanable={false}
        renderValue={(value, format) => {
          if (moment(value[0]).format(Utils.getDefaultDateRangeFormat()) === moment(value[1]).format(Utils.getDefaultDateRangeFormat())) {
            let date = moment(value[0]).format(Utils.getDefaultDateRangeFormat())

            return (
              <div pl={1}>
                <span className="dateTicker">{date}</span>
              </div>
            );
          } else {
            let date = moment(value[0]).format(Utils.getDefaultDateRangeFormat()) + " to " + moment(value[1]).format(Utils.getDefaultDateRangeFormat());

            return (
              <div pl={1}>
                <span className="dateTicker">{date}</span>
              </div>
            );
          }
        }}
        onChange={(value) => {
          let format = Utils.getAPIDateFormat();
          let fromDate = moment(value[0]).format(format);
          let toDate = moment(value[1]).format(format);

          if (this.props.onDateChange) {
            this.props.onDateChange(fromDate, toDate, value);
          }
        }}
      />
    </div>
  }

  setButtonArrow() {
    return (
      <div className="button-arrow">
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            let dateFrom = moment(this.props.fromDate, Utils.getAPIDateFormat()).subtract(1, "days").format();
            let toDate = moment(this.props.toDate, Utils.getAPIDateFormat()).subtract(1, "days").format();

            if (this.props.onDateChange) {
              this.props.onDateChange(dateFrom, toDate, [new Date(dateFrom), new Date(toDate)]);
            }
          }}
        >
          <i color="blue" className="fas fa-arrow-left"></i>
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            let dateFrom = moment(this.props.fromDate, Utils.getAPIDateFormat()).add(1, "days").format();
            let toDate = moment(this.props.toDate, Utils.getAPIDateFormat()).add(1, "days").format();

            if (this.props.onDateChange) {
              this.props.onDateChange(dateFrom, toDate, [new Date(dateFrom), new Date(toDate)]);
            }
          }}
        >
          <i className="fas fa-arrow-right"></i>
        </Button>
      </div>
    );
  }

  render() {
    return <Row className="view-top-section-layout align-items-center">
      <Col xs={12} md={12} lg={'auto'} className={'mb-2 mb-md-0'}>
        <h1 className={'title mx-0 mb-0'}>REMINDERS</h1>
      </Col>
      <Col xs={12} md={'auto'} className={'mb-2 mb-md-0'}>
        {this.setButtonArrow()}
      </Col>
      <Col xs={12} sm={true} md={5} lg={3} className={'mb-2 mb-md-0'}>
        {this.datePicker()}
      </Col>
      <Col xs={'12'} sm={'auto'} md={'auto'} className={'mb-2 mb-md-0'}>
        <Button
          className={"btn-today"}
          variant={"outlined"}
          color={"primary"}
          onClick={() => {
            if (this.props.onDateChange) {
              this.props.onDateChange(this.state.thisDate, this.state.thisDate, [new Date(this.state.thisDate), new Date(this.state.thisDate)]);
            }
          }}
        >
          TODAY
        </Button>
      </Col>
      <Col xs={12} md={true} className={'text-start text-md-end mb-2 mb-md-0'}>
        <Button
          variant="primary"
          onClick={(e) => {
            if (this.props.onCreate) {
              this.props.onCreate();
            }
          }}
        >
          {this.props.buttonTitle ? this.props.buttonTitle : 'NEW REMINDER'}
        </Button>
      </Col>
    </Row>
  }
}

export default ViewTopSection;
