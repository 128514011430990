import React from 'react';

import { 
  Spinner,
} from "react-bootstrap-v5";


const ViewLayout = (props) => {
  const topMarging = 65;

  let [contentSectionHeight, setContentSectionHeight] = React.useState(0);
  
  
  const resizeListener = () => {
    let topSectionEl = document.getElementsByClassName('custom-top-section')[0];
    let topPosition = topSectionEl.getBoundingClientRect();

    let contentSectionHeight = window.innerHeight - topPosition.height - topMarging;
    
    setContentSectionHeight(contentSectionHeight);
  }


  React.useEffect(() => {
    try{
      resizeListener();
    } catch(e){}
    
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  });


  return <div>
    <div className={'custom-top-section shadow-sm py-10 px-5'}
      style={{ 
        top: topMarging + 'px',
        zIndex: '2',
      }}
    >
      <div className={'py-2'}>{props.topSection}</div>
    </div>

    <div className={'p-0 map-content-mh-100'} style={{ height: contentSectionHeight + 'px' }}>
      {props.contentSection}
    </div>

    {props.isLoading && <div style={{
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      zIndex: '9999',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'white',
      opacity: '0.4',
    }}>
      <Spinner animation="border" style={{ width: '60px', height: '60px' }} />
    </div>}
  </div>
};

export default ViewLayout;