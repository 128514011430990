import React, { Component } from 'react';

import apiUtil from '../../../../api/apiUtil.jsx';
import AuditTrailApi from '../../../../api/OLD/Dispatch/AuditTrail.jsx';

import { 
    Box,
    LinearProgress,
} from '@material-ui/core';

import Pagination from '@material-ui/lab/Pagination';

import AuditTrailTimeline from "../../../../components/OLD/Timeline/AuditTrailTimeline.jsx";
import AuditTrailDetails from "./AuditTrailDetails.jsx";


class AuditTrailListCustomer extends Component {
  
    constructor(props){
        super(props);
        
        let isLoading = ((props.isLoading === false) || (props.isLoading === true)) ? props.isLoading : false;
        let items = (props.items && props.items.length > 0) ? props.items : [];
        let objectId = (props.objectId) ? props.objectId : null;
        let auditTrailObjectType = (props.auditTrailObjectType) ? props.auditTrailObjectType : null;
        let title = (props.title && props.title !== '') ? props.title : '';
        
        this.state = {
            isLoading: isLoading,
            items: items,
            objectId: objectId,
            auditTrailObjectType: auditTrailObjectType,
            title: title,
        }
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.isLoading !== this.state.isLoading) {
            let isLoading = ((nextProps.isLoading === false) || (nextProps.isLoading === true)) ? nextProps.isLoading : false;
			this.setState({
				isLoading: isLoading
			});
		}

		if (nextProps.items !== this.state.items) {
            let items = (nextProps.items && nextProps.items.length > 0) ? nextProps.items : [];
			this.setState({
				items: items
			});
        }

        if (nextProps.objectId !== this.state.objectId) {
            let objectId = (nextProps.objectId) ? nextProps.objectId : null;
			this.setState({
				objectId: objectId
			});
        }

        if (nextProps.auditTrailObjectType !== this.state.auditTrailObjectType) {
            let auditTrailObjectType = (nextProps.auditTrailObjectType) ? nextProps.auditTrailObjectType : null;
			this.setState({
				auditTrailObjectType: auditTrailObjectType
			});
        }

        if (nextProps.title !== this.state.title) {
            let title = (nextProps.title && nextProps.title !== '') ? nextProps.title : '';
			this.setState({
				title: title
			});
        }
	}


    /* API */
    callCompareAuditTrailApi = (auditTrailId, callback = null) => {
        let token = '';
        
        AuditTrailApi.compareAuditTrail(
            this.state.auditTrailObjectType, 
            this.state.objectId,
            auditTrailId,
            token
        ).then(result => {
            apiUtil.parseResult(result, (data) => {
                let currentVersion = (data && data.currentVersion) ? data.currentVersion : null;
                let previousVersion = (data && data.previousVersion) ? data.previousVersion : null;
                
                if(this.props.manageData){
                    this.props.manageData(currentVersion, previousVersion, (c, p) => {
                        currentVersion = (c) ? c : null;
                        previousVersion = (p) ? p : null;
                    });
                }

                if(callback){
                    callback(currentVersion, previousVersion);
                }
            }, (error, type) => {
                if(callback){
                    callback(null, null);
                }
            });
        });
    }
    /* END API */


    getDetails = (index, currentVersion, previousVersion) => {
        if(this.props.details){
            return <AuditTrailDetails
                index={index}
                currentVersion={currentVersion}
                previousVersion={previousVersion}
                details={this.props.details}
            />
        }
    }


    setTitle = () => {
        return <Box>
            <div style={{ color: '#7A7A7A' }} className={'px-8 mt-6'}>Activities</div>
        </Box>
    }

    setList = () => {
        return <Box pt={2}>
            <AuditTrailTimeline
                items={this.state.items}
                isLoading={this.state.isLoading}
                className={'min-width-120'}
                noResult={'No Audit Trail!'}
                isDetails={true}
                detailsBtnTextOpened={'Collapse Details'}
                detailsBtnTextClosed={'View Changes'}
                onChangeShowDetails={(index, showDetails) => {
                    let items = this.state.items;
                    if(items && items.length > 0 && index > -1){
                        items[index].showDetails = showDetails;
                        items[index].details = <LinearProgress />;
                        items[index].isLoadingDetails = showDetails;
            
                        this.setState({
                            items: items
                        }, () => {
                            if(showDetails){
                                this.callCompareAuditTrailApi(items[index].id, (currentVersion, previousVersion) => {
                                    let detailsMsg = <Box style={{ fontWeight: 'bold' }}>Audit trails is not recorded for old data!</Box>;

                                    let details = null;
                                    if(currentVersion){
                                        details = this.getDetails(index, currentVersion, previousVersion);
                                        if(details){
                                            items[index].details = details;
                                        } else {
                                            details = detailsMsg;
                                        }
                                    } else {
                                        details = detailsMsg;
                                    }
                    
                                    items[index].details = details;
                                    items[index].isLoadingDetails = false;
                    
                                    this.setState({
                                        items: items
                                    });
                                });
                            }
                        });
                    }
                }}
            />
        </Box>
    }

    setPagination = () => {
        if(this.state.items && this.state.items.length > 0){
            return <Box pb={2} textAlign={'center'}>
                <Pagination 
                    className={'v-center'}
                    count={apiUtil.getPaginationCount(this.props.totalRows, this.props.pageSize)} 
                    page={this.props.currentPage} 
                    size={'small'}
                    boundaryCount={2} 
                    showFirstButton
                    showLastButton
                    onChange={(event, value) => {
                        if(this.props.onChangePage){
                            this.props.onChangePage(this.state.objectId, value);
                        }
                    }}
                />
            </Box>
        }
    }


    render() {
        return <Box>
            {this.setTitle()}
            {this.setList()}
            {this.setPagination()}
        </Box>
    }
}

export default AuditTrailListCustomer;
