import React from "react";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CardForm from './CardForm.jsx';

const { 
    REACT_APP_STRIPE_PUBLISHED_API_KEY
} = process.env;


const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLISHED_API_KEY);

const StripeElement = (props) => {
    return <Elements stripe={stripePromise}>
        <CardForm {...props} />
    </Elements>
};

export default StripeElement;
