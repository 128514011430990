/* eslint-disable no-loop-func */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface InitState {
  isSelected: boolean,
  selected: Array<number>,
  count: number,
}


function NewReducer() {
  const name = 'checkboxCount';


  const initialState: InitState = {
    isSelected: false,
    selected: [],
    count: 0,
  };


  const reducers = {
    clear: (state: InitState) => {
      state.isSelected = false;
      state.selected = [];
      state.count = 0;
    },
    onCheckAll: (state: InitState, action: PayloadAction<{ allIds: Array<number>, checkedIds: Array<number> }>) => {
      let allIds = action.payload.allIds;
      let checkedIds = action.payload.checkedIds;
      let isChecked = (checkedIds.length > 0) ? true : false;
      let selected = state.selected;

      if(isChecked) {
        checkedIds.forEach(id => {
          let index = selected.findIndex((x) => x === id);
          if(index === -1) {
            selected.push(id);
          }
        });

      } else {
        selected = [];
      }

      state.selected = selected;
      state.count = state.selected.length;
      state.isSelected = (state.count > 0) ? true : false;
    },
    onCheckRow: (state: InitState, action: PayloadAction<number>) => {
      let id = action.payload;
      let selected = state.selected;

      let index = selected.findIndex((x) => x === id);
      if(index === -1) {
        selected.push(id);
      } else {
        selected.splice(index, 1);
      }

      state.selected = selected;
      state.count = state.selected.length;
      state.isSelected = (state.count > 0) ? true : false;
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
  };
}


export default NewReducer();