import React from 'react';

import { OverlayTrigger, Tooltip } from 'react-bootstrap-v5';


const CustomTooltip = props => {

    return <OverlayTrigger
        {...props}
        overlay={<Tooltip className={'dark-tooltip'} {...props}>{props.title}</Tooltip>}
    >
        {props.children}
    </OverlayTrigger>
}


export default CustomTooltip;