import React, { Component } from 'react';

import * as arrayDiff from "fast-array-diff";
import { diff } from "nested-object-diff";
import uuid from 'react-uuid'

import { 
    Box,
    Grid,
    Tooltip,
    Divider,
} from '@material-ui/core';


class AuditTrailDetails extends Component {
    
    constructor(props){
        super(props);
        
        let index = (props.index) ? props.index : 0;
        let currentVersion = (props.currentVersion) ? props.currentVersion : null;
        let previousVersion = (props.previousVersion) ? props.previousVersion : null;
        let objectType = (props.objectType != null) ? props.objectType : null;

        let item = { ...previousVersion, ...currentVersion };
        var differences = diff(previousVersion, currentVersion);

        this.state = {
            index: index,
            currentVersion: currentVersion,
            previousVersion: previousVersion,
            objectType: objectType,

            item: item,
            diff: differences,
        }
    }


    /* FUNCTIONS */
    isExists = (arr = [], item = null, field = null) => {
        let index = arr.findIndex(x => x[field] === item[field]);
        return (index === -1) ? false : true;
    }
    concatUniqueArrays = (arr1 = [], arr2 = [], field = null) => {
        let ids = new Set(arr1.map(d => d[field]));
        return [...arr1, ...arr2.filter(d => !ids.has(d[field]))].reverse();
    }
    getArrDiff = (arr1 = [], arr2 = [], callback = null) => {
        return arrayDiff.diff(arr1, arr2, callback);
    }

    getDiff = (obj1 = null, obj2 = null) => {
        return diff(obj1, obj2);
    }

    findDiff = (field = null, arr = null) => {
        let obj = null;
        let diff = (arr) ? arr : this.state.diff;

        if(field && diff && diff.length > 0){
            let item = diff.filter(x => x.path === field);
            obj = (item && item.length > 0) ? item[0] : null;
        }

        return obj;
    }
    
    classNameDiff = (item = null, prefix = '') => {
        let cls = '';

        if(item){
            if(item.type === 'A'){
                cls = 'highlight' + prefix + '-green';
            } else if(item.type === 'E'){
                cls = 'highlight' + prefix;
            } else if(item.type === 'D'){
                cls = 'highlight' + prefix + '-red';
            } else if(item.type === 'M'){
                cls = 'highlight' + prefix;
            }
        }

        return cls;
    }
    classNameDiffRow = (item = null) => {
        return this.classNameDiff(item, '-row');
    }
    
    getDiffValue = (field = null, obj = null, callback = null) => {
        let value = '';
        if(field && obj){
            try {
                let val = field.split('.').reduce((o, i) => o[i], obj);
                let fieldValue = ((typeof val == "boolean") ? ((val === true) ? 'YES' : 'NO') : ((val == '0') ? val : ((val && val !== '') ? val : null)));
                value = (callback) ? callback(fieldValue) : fieldValue;
            } catch(e){}
        }

        return value;
    }
    getPreviousValue = (item = null, callback = null) => {
        let value = '';
        let fieldValue = ((item) ? ((typeof item.lhs == "boolean") ? ((item.lhs === true) ? 'YES' : 'NO') : ((item.lhs == '0') ? item.lhs : ((item.lhs && item.lhs !== '') ? item.lhs : null))) : null);
        value = (callback) ? callback(fieldValue) : fieldValue;

        return (value && value !== '') ? 'Previous value: ' + value : 'No previous value!';
    }
    getPreviousValueImg = (item = null, callback = null) => {
        let value = '';
        let fieldValue = ((item) ? ((typeof item.lhs == "boolean") ? ((item.lhs === true) ? 'YES' : 'NO') : ((item.lhs == '0') ? item.lhs : ((item.lhs && item.lhs !== '') ? item.lhs : null))) : null);
        value = (callback) ? callback(fieldValue) : fieldValue;

        return (value && value !== '') ? value : null;
    }
    getHighlightedItem = (item = null, field = null, callback = null, obj = null) => {
        let items = (obj) ? obj : this.state.item;
        
        let cls = this.classNameDiff(item);
        let value = this.getDiffValue(field, items, callback);
        let previousValue = this.getPreviousValue(item, callback);

        if(item){
            return <Tooltip placement="top" title={previousValue}>
                <span className={cls}>{value}</span>
            </Tooltip>
        } else {
            return <>{value}</>
        }
    }
    getHighlightedItemHTML = (item = null, field = null, callback = null, obj = null) => {
        let items = (obj) ? obj : this.state.item;
        
        let cls = this.classNameDiff(item);
        let value = this.getDiffValue(field, items, callback);
        let previousValue = this.getPreviousValue(item, callback);

        if(item){
            return <Tooltip  placement="top" title={<div className={'highlight-white-all'} dangerouslySetInnerHTML={{ __html: previousValue }} />}>
                <div className={cls} style={{ lineHeight: '12px' }} dangerouslySetInnerHTML={{ __html: value }} />
            </Tooltip>
        } else {
            return <div dangerouslySetInnerHTML={{ __html: value }} />
        }
    }
    getHighlightedItemImg = (item = null, field = null, callback = null, obj = null) => {
        let items = (obj) ? obj : this.state.item;
        
        let cls = this.classNameDiff(item);
        let value = this.getDiffValue(field, items, callback);
        let previousValue = this.getPreviousValueImg(item, callback);

        if(item){
            return <Tooltip  placement="top" title={previousValue ? <img src={previousValue} alt={previousValue} height={150} /> : 'No previous value!'}>
                <span className={cls} style={{ display: 'inline-block' }}>
                    {(value && value !== '') && <img src={value} alt={value} height={150} />}
                </span>
            </Tooltip>
        } else {
            return (value && value !== '') ? <img src={value} alt={value} height={150} /> : ''
        }
    }

    getItem = (item = null, field = null, callback = null, obj = null) => {
        let items = (obj) ? obj : this.state.item;
        let value = this.getDiffValue(field, items, callback);

        return value
    }
    getValue = (field = null, callback = null, obj = null) => {
        let item = this.findDiff(field, obj);
        return this.getItem(item, field, callback, obj);
    }

    highlight = (field = null, callback = null, obj = null) => {
        let item = this.findDiff(field, obj);
        return this.getHighlightedItem(item, field, callback, obj);
    }
    highlightHTML = (field = null, callback = null, obj = null) => {
        let item = this.findDiff(field, obj);
        return this.getHighlightedItemHTML(item, field, callback, obj);
    }
    highlightImg = (field = null, callback = null, obj = null) => {
        let item = this.findDiff(field, obj);
        return this.getHighlightedItemImg(item, field, callback, obj);
    }
    highlightRow = (field = null, obj = null) => {
        let item = this.findDiff(field, obj);
        let cls = this.classNameDiffRow(item);
        return cls;
    }

    customTemplate = (template = null) => {
        return <Box key={uuid()} pb={1}>{template}</Box>;
    }
    rowTemplate = (field = null, value = null) => {
        return <Box key={uuid()} pb={1}>
            <Grid container>
                <Box clone>
                    <Grid item xs={12} md={3}>{field}</Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12} md={9}>{value}</Grid>
                </Box>
            </Grid>
        </Box>;
    }
    rowTemplateHalfAndHalf = (field = null, value = null) => {
        return <Box key={uuid()} pb={1}>
            <Grid container>
                <Box clone>
                    <Grid item xs={12} md={6}>{field}</Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12} md={6}>{value}</Grid>
                </Box>
            </Grid>
        </Box>;
    }
    rowTemplateLink = (field = null, value = null, url = '/') => {
        return <Box key={uuid()} pb={1}>
            <Grid container>
                <Box clone>
                    <Grid item xs={12} md={3}>{field}</Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12} md={9}>
                        <a href={url}
                            rel="noopener noreferrer"
                            target={'_blank'}
                        >{value}</a>
                    </Grid>
                </Box>
            </Grid>
        </Box>;
    }
    tableTemplate = (header = [], data = [], noResults = 'No results!', className = '') => {
        return <Box key={uuid()} pb={1}>
            <Grid item xs={12}>
                <Box pb={1}>
                    <table className={'custom-universal-audit-trail-table ' + className}>
                        {((header && header.length > 0) && (data && data.length > 0)) && <thead className={'custom-universal-audit-trail-table-thead'}>
                            <tr className={'custom-universal-audit-trail-table-thead-tr'}>
                                {header.map((th, h) => {
                                    return <th key={h} className={'custom-universal-audit-trail-table-thead-tr-th'}>{th}</th>
                                })}
                            </tr>
                        </thead>}

                        {
                            (data && data.length > 0)
                            ?
                                <tbody className={'custom-universal-audit-trail-table-tbody'}>
                                    {data.map((tr, r) => {
                                        return <tr key={r} className={'custom-universal-audit-trail-table-tbody-tr'}>
                                            {tr.map((td, c) => {
                                                return <td key={c} className={'custom-universal-audit-trail-table-tbody-tr-td'}>{td}</td>
                                            })}
                                        </tr>
                                    })}
                                </tbody>
                            :
                                <tbody className={'custom-universal-audit-trail-table-tbody'}>
                                    <tr className={'custom-universal-audit-trail-table-tbody-tr'}>
                                        <td className={'custom-universal-audit-trail-table-tbody-tr-td'} colSpan={header.length}>{noResults}</td>
                                    </tr>
                                </tbody>
                        }
                    </table>
                </Box>

            </Grid>
        </Box>;
    }
    titleTemplate = (title = null) => {
        return <Box key={uuid()} pb={1}>
            <Grid container>
                <Box clone fontWeight={'bold'}>
                    <Grid item xs={12}>{title}</Grid>
                </Box>
            </Grid>
        </Box>;
    }
    textTemplate = (text = null) => {
        return <Box key={uuid()}>
            <Grid container>
                <Box clone>
                    <Grid item xs={12}>{text}</Grid>
                </Box>
            </Grid>
        </Box>;
    }
    hrTemplate = () => {
        return <Box key={uuid()} pb={1}>
            <Grid container>
                <Box clone>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </Box>
            </Grid>
        </Box>;
    }
    lineTemplate = () => {
        return <Box key={uuid()} style={{ height: '1px', backgroundColor: 'white' }}></Box>;
    }
    emptyRowTemplate = () => {
        return <Box key={uuid()}>
            <Grid container>
                <Box clone>
                    <Grid item xs={12}>&nbsp;</Grid>
                </Box>
            </Grid>
        </Box>;
    }
    /* END FUNCTIONS */


    render() {
        return <Grid container>
            <Box clone>
                <Grid item xs={12}>
                    {this.props.details && this.props.details(this, this.state.index, this.state.currentVersion, this.state.previousVersion, this.state.objectType)}
                </Grid>
            </Box>
        </Grid>
    }
}

export default AuditTrailDetails;
