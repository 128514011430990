import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HubConnectionBuilder, IRetryPolicy } from '@microsoft/signalr';
import uuid from 'react-uuid'
import { ReceiveMessage } from './Enums.jsx';
import Utils from "../../utils/utils.js";


const { REACT_APP_REAL_TIME_DOWNLOAD_URL } = process.env;


interface InitState {
  connection: any,
  hubId: string|null,
  percentage: number|null,
}


function NewReducer() {
  const name = 'signalRslice';


  const initialState: InitState = {
    connection: null,
    hubId: null,
    percentage: null,
  };


  const reducers = {
    connect: (state: InitState, action: PayloadAction<{ callback: Function, callbackInit: Function }>) => {
      let hubId = uuid();
      state.hubId = hubId;

      const customRetryPolicy: IRetryPolicy = {
        nextRetryDelayInMilliseconds: (retryContext) => {
          return 1000;
        }
      };

      let url: any = REACT_APP_REAL_TIME_DOWNLOAD_URL;

      try {
        let conn = new HubConnectionBuilder()
          .withUrl(url)
          .withAutomaticReconnect(customRetryPolicy)
          .build();
        conn.serverTimeoutInMilliseconds = 60000;

        state.connection = conn;
        state.percentage = 0;

        conn.start()
        .then(async () => {
          conn.invoke("AddToGroup", hubId).catch((e: any) => {});

          if(action.payload && action.payload.callbackInit){
            action.payload.callbackInit(hubId);
          }

          conn.on(ReceiveMessage.Progress, (message: any) => {
            let percentage = (message && (typeof message.percentage === 'number')) ? message.percentage : 0;
            let errorMessage = (message && message.errorMessage && message.errorMessage != '') ? message.errorMessage : null;
            let successMessage = (message && message.successMessage && message.successMessage != '') ? message.successMessage : null;

            if(errorMessage != ''){
              Utils.toast(errorMessage, 'error');
            }
            if(successMessage != ''){
              Utils.toast(successMessage, 'success');
            }

            if(action.payload && action.payload.callback){
              action.payload.callback(percentage);
            }
          });
        })
        .catch((e) => {
          if(action.payload && action.payload.callbackInit){
            action.payload.callbackInit(null);
          }
        });
      } catch (err) {
        if(action.payload && action.payload.callbackInit){
          action.payload.callbackInit(null);
        }
      }
    },
    disconnect: (state: InitState) => {
      try {
        let conn = state.connection;
        if (conn) {
          conn.stop();
        }
        state.connection = null;
        state.hubId = null;
        state.percentage = null;
      } catch (err) {
        state.connection = null;
        state.hubId = null;
        state.percentage = null;
      }
    },

    setConnection: (state: InitState, action: PayloadAction<any>) => {
      try {
        state.connection = action.payload;
      }catch(e){}
    },
    subscribe: (state: InitState, action: PayloadAction<string>) => {
      state.percentage = 0;

      if(state.connection){
        state.connection.invoke("AddToGroup", action.payload);
      }
    },
    unsubscribe: (state: InitState, action: PayloadAction<string>) => {
      state.percentage = null;

      if(state.connection){
        state.connection.invoke("RemoveFromGroup", action.payload);
      }
    },

    onPercentage: (state: InitState, action: PayloadAction<number|null>) => {
      state.percentage = action.payload;
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
  };
}


export default NewReducer();