import React from 'react';

import { 
    Row,
    Col,
    Modal,
    Form,
    CloseButton,
    Button,
} from "react-bootstrap-v5";

import { ReactComponent as LightbulbIcon } from '../../../../_metronic/assets/img/icons/material-lightbulb-outline.svg';

import Utils from '../../../utils/utils';

import CustomGoogleMap from '../../../components/OLD/GoogleMap/CustomGoogleMap';
import AddressSearchInput from '../../../components/googleMap/AddressSearchInput';


const LocationDialog = (props) => {
    const [show, setShow] = React.useState(((props.show === false) || (props.show === true)) ? props.show : false);
    const [canClose, setCanClose] = React.useState(((props.canClose === false) || (props.canClose === true)) ? props.canClose : true);

    const [formOptions, setFormOptions] = React.useState(props.formOptions);
    const [customSiteName, setCustomSiteName] = React.useState((formOptions && formOptions.values && formOptions.values.customSiteName) ? formOptions.values.customSiteName : '');
    const [siteName, setSiteName] = React.useState((formOptions && formOptions.values && formOptions.values.siteName) ? formOptions.values.siteName : '');
    const [street, setStreet] = React.useState((formOptions && formOptions.values && formOptions.values.street) ? formOptions.values.street : '');
    const [blockNo, setBlockNo] = React.useState((formOptions && formOptions.values && formOptions.values.blockNo) ? formOptions.values.blockNo : '');
    const [postalCode, setPostalCode] = React.useState((formOptions && formOptions.values && formOptions.values.postalCode) ? formOptions.values.postalCode : '');
    const [latitude, setLatitude] = React.useState((formOptions && formOptions.values && formOptions.values.latitude) ? formOptions.values.latitude : null);
    const [longitude, setLongitude] = React.useState((formOptions && formOptions.values && formOptions.values.longitude) ? formOptions.values.longitude : null);

    const [isInvalid, setIsInvalid] = React.useState(false);

    // const [replaceSiteNameWithThisAddress, setReplaceSiteNameWithThisAddress] = React.useState(false);
    

    React.useEffect(() => {
        setShow(((props.show === false) || (props.show === true)) ? props.show : false);
    }, [props.show]);

    React.useEffect(() => {
        setCanClose(((props.canClose === false) || (props.canClose === true)) ? props.canClose : true);
    }, [props.canClose]);

    React.useEffect(() => {
        setFormOptions(props.formOptions);
        setCustomSiteName((props.formOptions && props.formOptions.values && props.formOptions.values.customSiteName) ? props.formOptions.values.customSiteName : '');
        setSiteName((props.formOptions && props.formOptions.values && props.formOptions.values.siteName) ? props.formOptions.values.siteName : '');
        setStreet((props.formOptions && props.formOptions.values && props.formOptions.values.street) ? props.formOptions.values.street : '');
        setBlockNo((props.formOptions && props.formOptions.values && props.formOptions.values.blockNo) ? props.formOptions.values.blockNo : '');
        setPostalCode((props.formOptions && props.formOptions.values && props.formOptions.values.postalCode) ? props.formOptions.values.postalCode : '');
        setLatitude((props.formOptions && props.formOptions.values && props.formOptions.values.latitude) ? props.formOptions.values.latitude : null);
        setLongitude((props.formOptions && props.formOptions.values && props.formOptions.values.longitude) ? props.formOptions.values.longitude : null);
    }, [props.formOptions]);


    const getData = () => {
        return {
            customSiteName: customSiteName,
            siteName: siteName,
            street: street,
            blockNo: blockNo,
            postalCode: postalCode,
            latitude: latitude,
            longitude: longitude,
            coordinates: Utils.joinCoordinates(latitude, longitude),
            // replaceSiteNameWithThisAddress: replaceSiteNameWithThisAddress,
        }
    }


    return <Modal
        className={props.className}
        show={show}
        onHide={() => {
            if(props.onCancel){
                props.onCancel();
            }
        }}
        size={'lg'}
        keyboard={false}
        animation={false}
        backdrop={canClose ? true : 'static'}
    >
        <Modal.Header>
            <Row className={'align-items-center gx-0 w-100'}>
                <Col xs={true}>
                    <Modal.Title className={'text-center'}>{props.title ? props.title : 'Change Location'}</Modal.Title>
                </Col>
                {canClose && <Col xs={'auto'}>
                    <CloseButton
                        className={'rounded-2'}
                        onClick={() => {
                            if(props.onCancel){
                                props.onCancel();
                            }
                        }}
                    />
                </Col>}
            </Row>
        </Modal.Header>
        <Modal.Body>

            <Row>
                <Col xs={12}>
                    <Form.Control 
                        as={AddressSearchInput}
                        placeholder={props.placeholder ? props.placeholder : 'Search Address...'}
                        autoFocus

                        error={isInvalid}
                        value={customSiteName}
                        onChange={(value) => {
                            setCustomSiteName(value);
                            setLatitude(null);
                            setLongitude(null);
                        }}
                        onBlur={(e) => {
                            let isCoord = false;

                            let value = e.target.value;
                            if(Utils.validateCoordinates(value)){
                                let args = value.split(',');
                
                                let center = {
                                    lat: (args && args.length > 0) ? Utils.isNumeric(args[0].trim()) ? parseFloat(args[0].trim()) : '' : '',
                                    lng: (args && args.length > 1) ? Utils.isNumeric(args[1].trim()) ? parseFloat(args[1].trim()) : '' : '',
                                };
                                
                                isCoord = true;

                                setSiteName(value);
                                setLatitude(center.lat);
                                setLongitude(center.lng);
                                setIsInvalid(false);
                            }

                            if(!isCoord){
                                if(latitude !== null && longitude !== null){
                                    
                                } else {
                                    setIsInvalid(true);
                                }
                            }
                        }}
                        onPlacesChanged={(place) => {
                            if (place) {
                                let address_components = place["address_components"] || [];
                    
                                let route = Utils.getFromAddress(address_components, "route");
                                let postal_code = Utils.getFromAddress(address_components, "postal_code");
                                let premise = Utils.getFromAddress(address_components, "premise");
                    
                                let street = route ? route : '';
                                let postalCode = postal_code ? postal_code : '';
                                let blockNo = premise ? premise : '';
                                let customSiteName = place.name ? place.name : '';
                                let siteName = (place.formatted_address) ? place.formatted_address : place.name;
                                
                                
                                setStreet(street);
                                setPostalCode(postalCode);
                                setBlockNo(blockNo);
                                setCustomSiteName(customSiteName);
                                setSiteName(siteName);
                    
                                if (place.geometry && place.geometry.location) {
                                    setLatitude(place.geometry.location.lat());
                                    setLongitude(place.geometry.location.lng());
                                    setIsInvalid(false);
                                }
                            }
                        }}
                        onEnterCoordinate={async (place, center) => {
                            if (place) {
                                let address_components = place["address_components"] || [];
                    
                                let route = Utils.getFromAddress(address_components, "route");
                                let postal_code = Utils.getFromAddress(address_components, "postal_code");
                                let premise = Utils.getFromAddress(address_components, "premise");
                    
                                let street = route ? route : '';
                                let postalCode = postal_code ? postal_code : '';
                                let blockNo = premise ? premise : '';
                                let customSiteName = place.name ? place.name : '';
                                let siteName = (place.formatted_address) ? place.formatted_address : place.name;
                                
                                
                                setStreet(street);
                                setPostalCode(postalCode);
                                setBlockNo(blockNo);
                                setCustomSiteName(customSiteName);
                                setSiteName(siteName);
                    
                                if (place.geometry && place.geometry.location) {
                                    setLatitude(place.geometry.location.lat());
                                    setLongitude(place.geometry.location.lng());
                                    setIsInvalid(false);
                                }
                            }
                        }}
                    />
                </Col>

                {(latitude != null) && <Col xs={12} style={{ minHeight: '280px' }}>
                    <CustomGoogleMap
                        center={(latitude !== "" && longitude !== "") ? { lat: latitude, lng: longitude } : null}
                        isSearchable={false}
                        streetViewControl={true}
                        defaultZoom={props.zoom}
                        markerCenter={(latitude !== "" && longitude !== "") ? { lat: latitude, lng: longitude } : null}
                        onDragEnd={async (position) => {
                            setLatitude(position.lat);
                            setLongitude(position.lng);
                        }}
                        onLoad={(refs) => {
                            if(latitude && longitude){
                                Utils.dinamicZoom(refs.map, latitude, longitude);
                            }
                        }}
                    />
                </Col>}

                {(latitude != null) && <Col xs={12} md={6}>
                    <Row className={'pt-10 pb-10'}>
                        <Col xs={12} className={'mb-3 fw-bold'}>Search Result</Col>
                        <Col xs={12}>
                            <Row className={'mb-3'}>
                                <Col xs={12} md={6}>Street Name</Col>
                                <Col xs={12} md={6}>{street}</Col>
                            </Row>
                            <Row className={'mb-3'}>
                                <Col xs={12} md={6}>Block Number</Col>
                                <Col xs={12} md={6}>{blockNo}</Col>
                            </Row>
                            <Row className={'mb-3'}>
                                <Col xs={12} md={6}>Post Code</Col>
                                <Col xs={12} md={6}>{postalCode}</Col>
                            </Row>
                            <Row className={'mb-5'}>
                                <Col xs={12} md={6}>Coordinates</Col>
                                <Col xs={12} md={6}>{Utils.joinCoordinates(latitude, longitude)}</Col>
                            </Row>
                            {/* <Row>
                                <Col xs={12}>
                                    <Form.Check
                                        id={'replaceSiteNameWithThisAddress_id'}
                                        label={'Replace Site Name With This Address'}
                                        checked={replaceSiteNameWithThisAddress}
                                        onChange={(e) => {
                                            setReplaceSiteNameWithThisAddress(e.target.checked);
                                        }}
                                    />
                                </Col>
                            </Row> */}
                        </Col>
                    </Row>
                </Col>}

                {(latitude != null) && <Col xs={12} md={6}>
                    <Row>
                        <Col xs={'auto'} className={'pt-10'}>
                            <LightbulbIcon />
                        </Col>
                        <Col xs={true}>
                            <Row className={'pt-10 pb-10'}>
                                <Col xs={12} className={'mb-2 fw-bold'}>Drag Location Marker To Adjust</Col>
                                <Col xs={12}>You can adjust the position of the marker by dragging it. The coordinates will change but the rest of the fields will stay the same.<br /><br />If you wish to search a new location, go to the address bar to search again.</Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>}

            </Row>
            
        </Modal.Body>
        <Modal.Footer>
            <Row className={'w-100'}>
                <Col xs={true} className={'text-end'}>
                    <Button 
                        type={'button'}
                        variant={'primary'}
                        disabled={(latitude == null)}
                        onClick={() => {
                            if(props.onSave){
                                props.onSave(getData());
                            }
                        }}
                    >
                      Next
                    </Button>
                </Col>
                <Col xs={true} className={'text-start'}>
                   <Button 
                        type={'button'}
                        variant={'light'}
                        onClick={() => {
                            if(props.onCancel){
                                props.onCancel();
                            }
                        }}
                    >
                      Cancel
                    </Button>
                </Col>
            </Row>
        </Modal.Footer>
    </Modal>
}


export default LocationDialog;
