/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import * as dispatchCrudApi from '../../../api/CRUD/DispatchCRUD'
import Utils from "../../../utils/utils";
// import {  } from "../../../utils/enums";

import { ParseResult } from "../../../utils/interfaces";


interface InitState {
  isLoading: boolean,
  items: Array<any>,
}

function NewReducer() {
  const name = 'driverTrackingSlice';


  const initialState: InitState = {
    isLoading: false,
    items: [],
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    startJobTracking: (state: InitState) => {
      state.isLoading = true;
    },
    finishJobTracking: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.length > 0) ? action.payload : [];
      state.items = data;
      state.isLoading = false;
    },
  };


  const apis = {
    callJobTrackingApi: (jobId: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      if(jobId){
        dispatch(actions.startJobTracking());

        await dispatchCrudApi.readApi(null, 'job/tracking/' + jobId).then(result => {
          let data = result.data;
          
          callback(true, data);
          dispatch(actions.finishJobTracking(data));
        }).catch(error => {
          let res: ParseResult = {
            isError: false,
            errorMessage: null,
            status: null,
          };

          Utils.parseErrorTS(error, (result: ParseResult): void => {
            res = result
          });
          
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
            Utils.toast(err, 'error');
          }
          
          callback(false, null);
          dispatch(actions.finishJobTracking(null));
        });
      }
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();