import React from 'react';
import _ from 'lodash';

import { TableCell } from '@material-ui/core';
  
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
// import SwapArray from 'swap-array';
  
import DataTableCustomHead from '../../../components/OLD/DataTable/DataTableCustomHead';


const DataTableMainHeader = (props) => {

    const DragHandle = SortableHandle(({ className, label }) => {
        return <div className={className} >{label}</div>
    });
    
    const SortableItem = SortableElement(({ k, col, columnVisibility, state }) => {
        return <TableCell
            data-index={k}
            width={col.width}
            style={{ display: columnVisibility[k] ? "" : "none" }}
            align={(col.align !== "") ? col.align : "left"}
        >
            <div
                data-index={k}
                className={'d-flex align-items-center'}
            >
                <DragHandle className={'cursor-grab ' + ((col && col.labelClass && col.labelClass != '') ? col.labelClass : '')} label={col.label} />
                {col.orderable && <span className={"custom-cell-sort"}>
                    <span
                        className={"sort-up " + (state.sortDir === "asc" && state.sortColumn === col.name ? "selected" : "")}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
        
                            if(props.onOrder){
                                props.onOrder(col.name, "asc");
                            }
                        }}
                    >
                        <i className={"material-icons custom-sort-icon-up"}>arrow_drop_up</i>
                    </span>
                    <span
                        className={"sort-down " + (state.sortDir === "desc" && state.sortColumn === col.name ? "selected" : "")}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
        
                            if(props.onOrder){
                                props.onOrder(col.name, "desc");
                            }
                        }}
                    >
                        <i className={"material-icons custom-sort-icon-down"}>arrow_drop_down</i>
                    </span>
                </span>}
            </div>
            <span className={"resize-handle"}></span>
        </TableCell>
    });

    const SortableList = SortableContainer(({ state, obj, columns, columnVisibility }) => {
        return <DataTableCustomHead
            stickyHeader={state.stickyHeader}
            isCheckbox={state.isCheckbox}
            isDragable={state.isDragable}
            isBorder={state.isBorder}
            numSelected={state.selected.length}
            rowCount={state.rows.length}
            onSelectAllClick={obj.handleSelectAllClick}
        >
            {(props.state.columns && props.state.columns.length > 0) && columns.map((col, k) => {
                return col.visible === false ? null : columnVisibility[k] === false ? null : (
                    <SortableItem
                        key={k}
                        index={k}
                        k={k}
                        col={col}
                        columnVisibility={columnVisibility}
                        state={state}
                    />
                );
            })}
        </DataTableCustomHead>
    });

    if(props.state.isDragableHeaderColumns === false){
        let selected = (props.state.selected && props.state.selected.length > 0) ? props.state.selected.length : 0;
        let numDisabledCheckBoxes = (props.state.disabledCheckboxes && props.state.disabledCheckboxes.length > 0) ? props.state.disabledCheckboxes.length : 0;

        return <DataTableCustomHead
            expandDetails={props.state.expandDetails}
            stickyHeader={props.state.stickyHeader}
            isCheckbox={props.state.isCheckbox}
            isDragable={props.state.isDragable}
            isBorder={props.state.isBorder}
            numSelected={(selected - numDisabledCheckBoxes)}
            numDisabledCheckBoxes={numDisabledCheckBoxes}
            rowCount={(props.state.rows.length - numDisabledCheckBoxes)}
            onSelectAllClick={props.obj.handleSelectAllClick}
        >
            {(props.state.columns && props.state.columns.length > 0) && props.state.columns.map((col, k) => {
                return col.visible === false ? null : props.state.columnVisibility[k] === false ? null : (
                    <TableCell
                        key={k}
                        data-index={k}
                        width={col.width}
                        style={{ display: props.state.columnVisibility[k] ? "" : "none" }}
                        align={
                            col.label === "ACTION" ? "center" : col.numeric ? "right" : "left"
                        }
                    >
                        <div
                            data-index={k}
                            className={col.label === "ACTION" ? "" : "v-left-center"}
                        >
                            <div>{col.label}</div>
                            {col.orderable && <span className={"custom-cell-sort"}>
                                <span
                                    className={"sort-up " + (props.state.sortDir === "asc" && props.state.sortColumn === col.name ? "selected" : "")}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                    
                                        if(props.onOrder){
                                            props.onOrder(col.name, "asc");
                                        }
                                    }}
                                >
                                    <i className={"material-icons custom-sort-icon-up"}>arrow_drop_up</i>
                                </span>
                                <span
                                    className={"sort-down " + (props.state.sortDir === "desc" && props.state.sortColumn === col.name ? "selected" : "")}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                    
                                        if(props.onOrder){
                                            props.onOrder(col.name, "desc");
                                        }
                                    }}
                                >
                                    <i className={"material-icons custom-sort-icon-down"}>arrow_drop_down</i>
                                </span>
                            </span>}
                        </div>
                        <span className={"resize-handle"}></span>
                    </TableCell>
                );
            })}
        </DataTableCustomHead>
    } else {
        return <SortableList
            state={props.state}
            obj={props.obj}
            columns={props.state.columns}
            columnVisibility={props.state.columnVisibility}
            expandDetails={props.state.expandDetails}
            axis={"x"}
            lockAxis={"x"}
            useDragHandle={true}
            helperClass={"custom-drag-cell"}
            onSortStart={() => {
                let els = document.getElementsByClassName('custom-grip');
                if(els && els.length > 0){
                    for(let i = 0; i < els.length; i++){
                        els[i].style.display = 'none';
                    }
                }
            }}
            onSortEnd={({ oldIndex, newIndex }) => {
                // Swap Columns
                // let columns = SwapArray(props.state.columns, oldIndex, newIndex);
                // let columnVisibility = SwapArray(props.state.columnVisibility, oldIndex, newIndex);

                // Shift Columns
                const columns = _.cloneDeep(props.state.columns);
                const columnVisibility = _.cloneDeep(props.state.columnVisibility);

                const newColumn = columns[oldIndex] || [];
                columns.splice(oldIndex, 1);
                columns.splice(newIndex, 0, newColumn);

                const newColumnVisibility = columnVisibility[oldIndex] || [];
                columnVisibility.splice(oldIndex, 1);
                columnVisibility.splice(newIndex, 0, newColumnVisibility);
                
                if(props.onSortEnd){
                    props.onSortEnd(columns, columnVisibility);
                }
                
                let els = document.getElementsByClassName('custom-grip');
                if(els && els.length > 0){
                    for(let i = 0; i < els.length; i++){
                        els[i].style.display = 'block';
                    }
                }
            }}
        />
    }
  }


  export default DataTableMainHeader;