import React, { Component } from 'react';

import { 
    Box,
    IconButton,
    Dialog
} from '@material-ui/core';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';


class CustomDialog extends Component {

    constructor(props){
        super(props);
    
        let open = ((props.open === true || props.open === false) ? props.open : false);
        let isCloseBtn = ((props.isCloseBtn === true || props.isCloseBtn === false) ? props.isCloseBtn : true);
        let fullWidth = ((props.fullWidth === true || props.fullWidth === false) ? props.fullWidth : false);
        let maxWidth = (props.maxWidth) ? (props.maxWidth !== '') ? props.maxWidth : null : 'sm';
        let closeBtnPosition = (props.closeBtnPosition) ? props.closeBtnPosition : 'out';
        let scroll = (props.scroll) ? props.scroll : 'body';
        let closeIcon = (props.closeIcon) ? props.closeIcon : <HighlightOffIcon />;
        let className = (props.className) ? props.className : '';
        let transitionProps = (props.transitionProps) ? props.transitionProps : null;

        this.state = {
            open: open,
            isCloseBtn: isCloseBtn,
            closeBtnPosition: closeBtnPosition,
            scroll: scroll,
            closeIcon: closeIcon,
            fullWidth: fullWidth,
            maxWidth: maxWidth,
            className: className,
            transitionProps: transitionProps,
        }
    }


    componentWillReceiveProps(nextProps) {
		if (nextProps.open !== this.state.open) {
			this.setState({
				open: nextProps.open
			});
		}
	}


    handleClose = (e, r) => {
        if(r !== 'backdropClick'){
            this.setState({
                open: false
            });
    
            if(this.props.onClose){
                this.props.onClose();
            }
        }
    }
    handleOpen = () => {
        this.setState({
            open: true
        });

        if(this.props.onOpen){
            this.props.onOpen();
        }
    }


    render() {
        return <Dialog
            className={"custom-dialog-component " + this.state.className}
            open={this.state.open}
            onClose={this.handleClose}
            fullWidth={this.state.fullWidth}
            maxWidth={(this.state.fullWidth) ? null : this.state.maxWidth}
            scroll={this.state.scroll}
            TransitionProps={this.state.transitionProps}
        >
            <Box className={'custom-paper'} position={"relative"} p={{ xs: 2, lg: 3}}>
                {this.state.isCloseBtn && 
                <IconButton 
                    className={this.state.closeBtnPosition === "out" ? "dialog-close-btn-out" : "dialog-close-btn-in"}
                    onClick={this.handleClose}
                >
                    {this.state.closeIcon}
                </IconButton>}
                {this.props.children}
            </Box>
        </Dialog>;
    }
}


export default CustomDialog;