import {FC, useRef, useEffect, useState} from 'react'
import { useHistory } from "react-router-dom";
import {shallowEqual, useSelector, connect, useDispatch, ConnectedProps} from 'react-redux'
import {LayoutSplashScreen} from '../../../_metronic/layout/core'
import * as auth from './saga'
import {dispatchMaintenance, authenticateToken, dispatchAuthenticateToken, getAccountPermissions, userCookieSettingsApi} from '../../../app/api/auth/api'
import {RootState} from '../..'

import Utils from '../../../app/utils/utils'


const mapState = (state: RootState) => ({auth: state.auth})
const connector = connect(mapState, auth.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const AuthInit: FC<PropsFromRedux> = (props) => {
  const didRequest = useRef(false)

  let history = useHistory();

  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const token = useSelector<RootState>(({auth}) => auth.token, shallowEqual)

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const { data: isMaintenance }  = await dispatchMaintenance()
          if(isMaintenance === "True"){
            history.push('/Maintenance');
          }

          const {data: user} = await authenticateToken(token as string)
          dispatch(props.fulfillUser(user))
          
          const {data: dispatchUser} = await dispatchAuthenticateToken(token as string)
          dispatch(props.fulfillDispatchUser(dispatchUser))
          
          const allPermissions = await getAccountPermissions()
          dispatch(props.setAccountPermissions(allPermissions))
          
          const data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize()
          };
          const {data: userCookieSettings} = await userCookieSettingsApi(data)
          dispatch(props.saveUserCookieSettings(((userCookieSettings && userCookieSettings.data && userCookieSettings.data.length > 0) ? userCookieSettings.data : [])))
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          dispatch(props.logout())
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (token) {
      requestUser()
    } else {
      dispatch(props.logout())
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default connector(AuthInit)
