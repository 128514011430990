import React from "react";

import { 
    Row,
    Col,
    Form,
    Spinner,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import { FieldArray } from 'formik';

import RTextEditor from '../../../components/OLD/RichTextEditor/RTextEditor.jsx';
import IconButtonNoTitle from "../../../components/Buttons/IconButtonNoTitle";
import DraggableListByChildren from '../../../components/draggableList/DraggableListByChildren';
import DraggableMultiListByChildren from '../../../components/draggableList/DraggableMultiListByChildren';

import WCDefaultIcon from '../../../../_metronic/assets/img/icons/wc_default.png';
import WCReceiptIcon from '../../../../_metronic/assets/img/icons/wc_receipt.png';

import Utils from "../../../utils/utils";
import { WeighChitLayout } from "../../../utils/enums";

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet } from '../../../../setup/redux/dispatch/actions'
import weighChitCustomFields from "./redux/weighChitCustomFields";


export const StyledFormCheck = styled(Form.Check)`
  margin: 12px;
  text-align: center;
  display: grid;

  label {
    justify-self: center;
    margin-bottom: 20px;
  }
  input {
    justify-self: center;
  }
`;


class WeightChitForm extends React.Component {
  
    constructor(props) {
        super(props);
    
        this.state = {
            weightChitShowNonBillable: false,
            weightChitShowBillable: false,
            weightChitShowRate: false,
            weightChitShowAmount: false,
        };
    }


    async componentDidMount(){
        await this.props.action.weighChitCustomFields.callReadApi();
        this.props.formOptions.setFieldValue('weightChitCustomFields', this.props.weighChitCustomFields.items);
    }


    defaultTemplate = () => {
        let {
            setFieldValue,
            values,
        } = this.props.formOptions;

        return <>
            <Row className={'mb-10'}>
                <Col xs={12}>
                    <h5 className={'mb-0'}>DEFAULT TEMPLATE</h5>
                </Col>
                <Col xs={12}>
                    <hr />
                </Col>
            </Row>

            <Form.Group as={Row} className={(values.weightChitAccountDocSettinglayout == WeighChitLayout.Receipt) ? '' : 'mb-10'}>
                <Col xs={12} md={true} className={'text-center'}>
                    <StyledFormCheck type={'radio'}>
                        <Form.Check.Label
                            onClick={async () => {
                                await setFieldValue('weightChitAccountDocSettinglayout', WeighChitLayout.Default);
                                await setFieldValue('weightChitShowNonBillable', this.state.weightChitShowNonBillable);
                                await setFieldValue('weightChitShowBillable', this.state.weightChitShowBillable);
                                await setFieldValue('weightChitShowRate', this.state.weightChitShowRate);
                                await setFieldValue('weightChitShowAmount', this.state.weightChitShowAmount);
                            }}
                        ><img src={WCDefaultIcon} alt={'Default'} width={'100%'} /></Form.Check.Label>
                        <Form.Check.Input 
                            type={'radio'}
                            checked={(values.weightChitAccountDocSettinglayout == WeighChitLayout.Default)}
                            onChange={async (e) => {
                                await setFieldValue('weightChitAccountDocSettinglayout', WeighChitLayout.Default);
                                await setFieldValue('weightChitShowNonBillable', this.state.weightChitShowNonBillable);
                                await setFieldValue('weightChitShowBillable', this.state.weightChitShowBillable);
                                await setFieldValue('weightChitShowRate', this.state.weightChitShowRate);
                                await setFieldValue('weightChitShowAmount', this.state.weightChitShowAmount);
                            }}
                        />
                    </StyledFormCheck>
                </Col>
                <Col xs={12} md={true} className={'text-center'}>
                    <StyledFormCheck type={'radio'}>
                        <Form.Check.Label
                            onClick={async () => {
                                await setFieldValue('weightChitAccountDocSettinglayout', WeighChitLayout.Receipt);

                                this.setState({
                                    weightChitShowNonBillable: values.weightChitShowNonBillable,
                                    weightChitShowBillable: values.weightChitShowBillable,
                                    weightChitShowRate: values.weightChitShowRate,
                                    weightChitShowAmount: values.weightChitShowAmount,
                                }, async () => {
                                    await setFieldValue('weightChitShowNonBillable', false);
                                    await setFieldValue('weightChitShowBillable', false);
                                    await setFieldValue('weightChitShowRate', false);
                                    await setFieldValue('weightChitShowAmount', false);
                                });
                            }}
                        ><img src={WCReceiptIcon} alt={'Receipt'} width={'100%'} /></Form.Check.Label>
                        <Form.Check.Input 
                            type={'radio'}
                            checked={(values.weightChitAccountDocSettinglayout == WeighChitLayout.Receipt)}
                            onChange={async (e) => {
                                await setFieldValue('weightChitAccountDocSettinglayout', WeighChitLayout.Receipt);

                                this.setState({
                                    weightChitShowNonBillable: values.weightChitShowNonBillable,
                                    weightChitShowBillable: values.weightChitShowBillable,
                                    weightChitShowRate: values.weightChitShowRate,
                                    weightChitShowAmount: values.weightChitShowAmount,
                                }, async () => {
                                    await setFieldValue('weightChitShowNonBillable', false);
                                    await setFieldValue('weightChitShowBillable', false);
                                    await setFieldValue('weightChitShowRate', false);
                                    await setFieldValue('weightChitShowAmount', false);
                                });
                            }}
                        />
                    </StyledFormCheck>
                </Col>
            </Form.Group>
            
            {this.headerReceipt()}

            <Row className={'mb-10'}>
                <Col xs={12} md={2} xxl={1}>Is visible</Col>
                <Col xs={12} md={4} lg={3} xxl={2}>Default label name</Col>
                <Col xs={12} md={true}>New label name</Col>
                <Col xs={12}>
                    <hr />
                </Col>
            </Row>
        </>
    }

    headerReceipt = () => {
        let {
            setFieldValue,
            setFieldTouched,
            values,
            errors,
        } = this.props.formOptions;

        if (values.weightChitAccountDocSettinglayout == WeighChitLayout.Receipt){
            return <Form.Group as={Row} className={'mb-10 pb-10'}>
                <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelStringHeaderTextPlainText.label}</Form.Label>
                <Col xs={12}>
                    <Form.Control
                        as = {RTextEditor}
                        isInvalid={!!errors.weightChitLabelStringHeaderTextPlainText}
                        id={this.props.fields.weightChitLabelStringHeaderTextPlainText.id}

                        toolbar={{
                            options: ['inline', 'textAlign'],
                            inline: {
                                inDropdown: false,
                                options: ['bold', 'italic', 'underline'],
                            },
                            textAlign: {
                                options: ['left', 'center', 'right', 'justify'],
                            },
                        }}

                        editorState={values.weightChitLabelStringHeaderText}
                        placeholder={this.props.fields.weightChitLabelStringHeaderTextPlainText.placeholder}
                        onChange={async (plainText, html, editorState) => {
                            await setFieldValue('weightChitLabelStringHeaderTextPlainText', plainText);
                            await setFieldValue('weightChitLabelStringHeaderTextHTML', html);
                            await setFieldValue('weightChitLabelStringHeaderText', editorState);
                            await setFieldTouched('weightChitLabelStringHeaderTextPlainText', true);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.weightChitLabelStringHeaderTextPlainText}</Form.Control.Feedback>
                </Col>
            </Form.Group>
        }
    }

    bodyDefault = () => {
        let {
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;
        
        return <>
            <DraggableListByChildren 
                droppableId={'weightChitweightDetailsDroppable1'}
                onDragEnd={(items, sourceIndex, destinationIndex) => {
                    if(items && items.length > 0){
                        items.forEach((item, i) => {
                            if(item && item.itemData && item.itemData.length > 0){
                                item.itemData.forEach((itemD, j) => {
                                    setFieldValue(itemD, item.sortOrder);
                                });
                            }
                        });
                    }
                }}
            >
                <Form.Group as={Row} sortOrder={'0'} itemData={['weightChitLabelWeightTicketSortOrder']} draggable={false}>
                    <Col xs={12} md={2} xxl={1}>
                        
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelWeightTicket.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.weightChitLabelWeightTicket.id}
                            placeholder={this.props.fields.weightChitLabelWeightTicket.placeholder}
                            value={values.weightChitLabelWeightTicket}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitLabelWeightTicket}
                        />
                        <Form.Control.Feedback type="invalid">{errors.weightChitLabelWeightTicket}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.weightChitShowCustomerNameSortOrder} itemData={['weightChitShowCustomerNameSortOrder', 'weightChitLabelCustomerNameSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.weightChitShowCustomerName.id}
                            label={null}
                            checked={values.weightChitShowCustomerName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitShowCustomerName}
                            feedback={errors.weightChitShowCustomerName}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelCustomerName.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.weightChitLabelCustomerName.id}
                            placeholder={this.props.fields.weightChitLabelCustomerName.placeholder}
                            value={values.weightChitLabelCustomerName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitLabelCustomerName}
                        />
                        <Form.Control.Feedback type="invalid">{errors.weightChitLabelCustomerName}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.weightChitShowCustomerAddressSortOrder} itemData={['weightChitShowCustomerAddressSortOrder', 'weightChitLabelCustomerAddressSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.weightChitShowCustomerAddress.id}
                            label={null}
                            checked={values.weightChitShowCustomerAddress}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitShowCustomerAddress}
                            feedback={errors.weightChitShowCustomerAddress}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelCustomerAddress.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.weightChitLabelCustomerAddress.id}
                            placeholder={this.props.fields.weightChitLabelCustomerAddress.placeholder}
                            value={values.weightChitLabelCustomerAddress}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitLabelCustomerAddress}
                        />
                        <Form.Control.Feedback type="invalid">{errors.weightChitLabelCustomerAddress}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.weightChitShowSiteAddressSortOrder} itemData={['weightChitShowSiteAddressSortOrder', 'weightChitLabelSiteAddressSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.weightChitShowSiteAddress.id}
                            label={null}
                            checked={values.weightChitShowSiteAddress}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitShowSiteAddress}
                            feedback={errors.weightChitShowSiteAddress}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelSiteAddress.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.weightChitLabelSiteAddress.id}
                            placeholder={this.props.fields.weightChitLabelSiteAddress.placeholder}
                            value={values.weightChitLabelSiteAddress}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitLabelSiteAddress}
                        />
                        <Form.Control.Feedback type="invalid">{errors.weightChitLabelSiteAddress}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.weightChitShowDONumberSortOrder} itemData={['weightChitShowDONumberSortOrder', 'weightChitLabelDONumberSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.weightChitShowDONumber.id}
                            label={null}
                            checked={values.weightChitShowDONumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitShowDONumber}
                            feedback={errors.weightChitShowDONumber}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelDONumber.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.weightChitLabelDONumber.id}
                            placeholder={this.props.fields.weightChitLabelDONumber.placeholder}
                            value={values.weightChitLabelDONumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitLabelDONumber}
                        />
                        <Form.Control.Feedback type="invalid">{errors.weightChitLabelDONumber}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.weightChitShowJobCompletionSortOrder} itemData={['weightChitShowJobCompletionSortOrder', 'weightChitLabelJobCompletionSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.weightChitShowJobCompletion.id}
                            label={null}
                            checked={values.weightChitShowJobCompletion}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitShowJobCompletion}
                            feedback={errors.weightChitShowJobCompletion}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelJobCompletion.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.weightChitLabelJobCompletion.id}
                            placeholder={this.props.fields.weightChitLabelJobCompletion.placeholder}
                            value={values.weightChitLabelJobCompletion}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitLabelJobCompletion}
                        />
                        <Form.Control.Feedback type="invalid">{errors.weightChitLabelJobCompletion}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.weightChitShowJobTypeSortOrder} itemData={['weightChitShowJobTypeSortOrder', 'weightChitLabelJobTypeSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.weightChitShowJobType.id}
                            label={null}
                            checked={values.weightChitShowJobType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitShowJobType}
                            feedback={errors.weightChitShowJobType}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelJobType.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.weightChitLabelJobType.id}
                            placeholder={this.props.fields.weightChitLabelJobType.placeholder}
                            value={values.weightChitLabelJobType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitLabelJobType}
                        />
                        <Form.Control.Feedback type="invalid">{errors.weightChitLabelJobType}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.weightChitShowBinINTypeSortOrder} itemData={['weightChitShowBinINTypeSortOrder', 'weightChitLabelBinINTypeSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.weightChitShowBinINType.id}
                            label={null}
                            checked={values.weightChitShowBinINType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitShowBinINType}
                            feedback={errors.weightChitShowBinINType}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelBinINType.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.weightChitLabelBinINType.id}
                            placeholder={this.props.fields.weightChitLabelBinINType.placeholder}
                            value={values.weightChitLabelBinINType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitLabelBinINType}
                        />
                        <Form.Control.Feedback type="invalid">{errors.weightChitLabelBinINType}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.weightChitShowWasteINTypeSortOrder} itemData={['weightChitShowWasteINTypeSortOrder', 'weightChitLabelWasteINTypeSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.weightChitShowWasteINType.id}
                            label={null}
                            checked={values.weightChitShowWasteINType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitShowWasteINType}
                            feedback={errors.weightChitShowWasteINType}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelWasteINType.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.weightChitLabelWasteINType.id}
                            placeholder={this.props.fields.weightChitLabelWasteINType.placeholder}
                            value={values.weightChitLabelWasteINType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitLabelWasteINType}
                        />
                        <Form.Control.Feedback type="invalid">{errors.weightChitLabelWasteINType}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.weightChitShowWeighTimestampSortOrder} itemData={['weightChitShowWeighTimestampSortOrder', 'weightChitLabelWeighTimestampSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.weightChitShowWeighTimestamp.id}
                            label={null}
                            checked={values.weightChitShowWeighTimestamp}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitShowWeighTimestamp}
                            feedback={errors.weightChitShowWeighTimestamp}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelWeighTimestamp.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.weightChitLabelWeighTimestamp.id}
                            placeholder={this.props.fields.weightChitLabelWeighTimestamp.placeholder}
                            value={values.weightChitLabelWeighTimestamp}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitLabelWeighTimestamp}
                        />
                        <Form.Control.Feedback type="invalid">{errors.weightChitLabelWeighTimestamp}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.weightChitShowWeighOutTimestampSortOrder} itemData={['weightChitShowWeighOutTimestampSortOrder', 'weightChitLabelWeighOutTimestampSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.weightChitShowWeighOutTimestamp.id}
                            label={null}
                            checked={values.weightChitShowWeighOutTimestamp}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitShowWeighOutTimestamp}
                            feedback={errors.weightChitShowWeighOutTimestamp}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelWeighOutTimestamp.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.weightChitLabelWeighOutTimestamp.id}
                            placeholder={this.props.fields.weightChitLabelWeighOutTimestamp.placeholder}
                            value={values.weightChitLabelWeighOutTimestamp}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitLabelWeighOutTimestamp}
                        />
                        <Form.Control.Feedback type="invalid">{errors.weightChitLabelWeighOutTimestamp}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.weightChitShowDriverSortOrder} itemData={['weightChitShowDriverSortOrder', 'weightChitLabelDriverSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.weightChitShowDriver.id}
                            label={null}
                            checked={values.weightChitShowDriver}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitShowDriver}
                            feedback={errors.weightChitShowDriver}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelDriver.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.weightChitLabelDriver.id}
                            placeholder={this.props.fields.weightChitLabelDriver.placeholder}
                            value={values.weightChitLabelDriver}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitLabelDriver}
                        />
                        <Form.Control.Feedback type="invalid">{errors.weightChitLabelDriver}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.weightChitShowVehicleSortOrder} itemData={['weightChitShowVehicleSortOrder', 'weightChitLabelVehicleSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.weightChitShowVehicle.id}
                            label={null}
                            checked={values.weightChitShowVehicle}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitShowVehicle}
                            feedback={errors.weightChitShowVehicle}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelVehicle.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.weightChitLabelVehicle.id}
                            placeholder={this.props.fields.weightChitLabelVehicle.placeholder}
                            value={values.weightChitLabelVehicle}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitLabelVehicle}
                        />
                        <Form.Control.Feedback type="invalid">{errors.weightChitLabelVehicle}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.weightChitShowWeighBySortOrder} itemData={['weightChitShowWeighBySortOrder', 'weightChitLabelWeighBySortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.weightChitShowWeighBy.id}
                            label={null}
                            checked={values.weightChitShowWeighBy}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitShowWeighBy}
                            feedback={errors.weightChitShowWeighBy}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelWeighBy.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.weightChitLabelWeighBy.id}
                            placeholder={this.props.fields.weightChitLabelWeighBy.placeholder}
                            value={values.weightChitLabelWeighBy}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitLabelWeighBy}
                        />
                        <Form.Control.Feedback type="invalid">{errors.weightChitLabelWeighBy}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.weightChitShowRemarksSortOrder} itemData={['weightChitShowRemarksSortOrder', 'weightChitLabelRemarksSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.weightChitShowRemarks.id}
                            label={null}
                            checked={values.weightChitShowRemarks}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitShowRemarks}
                            feedback={errors.weightChitShowRemarks}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelRemarks.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.weightChitLabelRemarks.id}
                            placeholder={this.props.fields.weightChitLabelRemarks.placeholder}
                            value={values.weightChitLabelRemarks}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitLabelRemarks}
                        />
                        <Form.Control.Feedback type="invalid">{errors.weightChitLabelRemarks}</Form.Control.Feedback>
                    </Col>
                </Form.Group>

                {values.weightChitCustomFields.map((item, i) => {
                    return <Form.Group key={'cf_' + i} as={Row} sortOrder={values['weightChitCustomField' + (i+1) + 'SortOrder']} itemData={['weightChitCustomField' + (i+1) + 'SortOrder']}>
                        <Col xs={12} md={2} xxl={1}>
                            <Form.Check
                                id={this.props.fields.weightChitCustomFieldsLabel.id + '_show_' + i}
                                label={null}
                                checked={values['weightChitCustomField' + (i+1)]}
                                value={(values && values['weightChitCustomField' + (i+1)]) ? values['weightChitCustomField' + (i+1)] : false}
                                onChange={async (e) => {
                                    await setFieldValue('weightChitCustomField' + (i+1), e.target.checked);
                                }}
                                onBlur={handleBlur}
                            />
                        </Col>
                        <Form.Label column xs={12} md={4} lg={3} xxl={2} className={'pe-0 pe-md-10'}>{this.props.fields.weightChitCustomFieldsLabel.label + ' ' + (i+1)}</Form.Label>
                        <Col xs={12} md={true}>
                            <Form.Control
                                type="text"
                                id={this.props.fields.weightChitCustomFieldsLabel.id + '_input_' + i}
                                placeholder={this.props.fields.weightChitCustomFieldsLabel.placeholder}
                                disabled={this.props.weighChitCustomFields.isLoading}
                                value={(item && item.label && item.label != '') ? item.label : ''}
                                onChange={async (e) => {
                                    await setFieldValue('weightChitCustomFields.' + i + '.label', e.target.value);
                                }}
                                onBlur={async (e) => {
                                    let data = {
                                        label: e.target.value,
                                    };

                                    if(item.accountCustomFieldId){
                                        data['accountCustomFieldId'] = item.accountCustomFieldId;
                                    }

                                    validateForm().then(async (err) => {
                                        if(!!err.weightChitCustomFields){
                                            Utils.toast('You must fill in the required fields', 'error');
                                        } else {
                                            await this.props.action.weighChitCustomFields.callUpdateApi(data);
                                            
                                            let wcItem = this.props.weighChitCustomFields.item;
                                            if(wcItem){
                                                await setFieldValue('weightChitCustomFields.' + i + '.accountCustomFieldId', wcItem.accountCustomFieldId);
                                                await setFieldValue('weightChitCustomFields.' + i + '.label', wcItem.label);
                                            }
                                        }
                                    })
                                }}
                                isInvalid={!!(errors && errors.weightChitCustomFields && errors.weightChitCustomFields[i] && errors.weightChitCustomFields[i].label)}
                            />
                            <Form.Control.Feedback type="invalid">{(errors && errors.weightChitCustomFields && errors.weightChitCustomFields[i] && errors.weightChitCustomFields[i].label) && errors.weightChitCustomFields[i].label}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                })}
            </DraggableListByChildren>
            

           <Row className={'mt-15 mb-10'}>
                <Col xs={12}>
                    <h5 className={'mb-0'}>WEIGH TABLE</h5>
                </Col>
                <Col xs={12}>
                    <hr />
                </Col>
            </Row>
            <DraggableListByChildren 
                droppableId={'weightChitweightTableDroppable1'}
                onDragEnd={(items, sourceIndex, destinationIndex) => {
                    if(items && items.length > 0){
                        items.forEach((item, i) => {
                            if(item && item.itemData && item.itemData.length > 0){
                                item.itemData.forEach((itemD, j) => {
                                    setFieldValue(itemD, item.sortOrder);
                                });
                            }
                        });
                    }
                }}
            >
                <Form.Group as={Row} sortOrder={values.weightChitShowGrossSortOrder} itemData={['weightChitShowGrossSortOrder', 'weightChitLabelGrossSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.weightChitShowGross.id}
                            label={null}
                            checked={values.weightChitShowGross}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitShowGross}
                            feedback={errors.weightChitShowGross}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelGross.label}</Form.Label>
                    <Col xs={12} md={true}>
                        {/* {(values.weightChitAccountDocSettinglayout == WeighChitLayout.Receipt) && <>
                            <Form.Control
                                type="text"
                                id={this.props.fields.weightChitLabelGross.id}
                                placeholder={this.props.fields.weightChitLabelGross.placeholder}
                                value={values.weightChitLabelGross}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={!!errors.weightChitLabelGross}
                            />
                            <Form.Control.Feedback type="invalid">{errors.weightChitLabelGross}</Form.Control.Feedback>
                        </>} */}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.weightChitShowTareSortOrder} itemData={['weightChitShowTareSortOrder', 'weightChitLabelTareSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.weightChitShowTare.id}
                            label={null}
                            checked={values.weightChitShowTare}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitShowTare}
                            feedback={errors.weightChitShowTare}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelTare.label}</Form.Label>
                    <Col xs={12} md={true}>
                        {/* {(values.weightChitAccountDocSettinglayout == WeighChitLayout.Receipt) && <>
                            <Form.Control
                                type="text"
                                id={this.props.fields.weightChitLabelTare.id}
                                placeholder={this.props.fields.weightChitLabelTare.placeholder}
                                value={values.weightChitLabelTare}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={!!errors.weightChitLabelTare}
                            />
                            <Form.Control.Feedback type="invalid">{errors.weightChitLabelTare}</Form.Control.Feedback>
                        </>} */}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.weightChitShowNettSortOrder} itemData={['weightChitShowNettSortOrder', 'weightChitLabelNettSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.weightChitShowNett.id}
                            label={null}
                            checked={values.weightChitShowNett}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitShowNett}
                            feedback={errors.weightChitShowNett}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelNett.label}</Form.Label>
                    <Col xs={12} md={true}>
                        {/* {(values.weightChitAccountDocSettinglayout == WeighChitLayout.Receipt) && <>
                            <Form.Control
                                type="text"
                                id={this.props.fields.weightChitLabelNett.id}
                                placeholder={this.props.fields.weightChitLabelNett.placeholder}
                                value={values.weightChitLabelNett}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={!!errors.weightChitLabelNett}
                            />
                            <Form.Control.Feedback type="invalid">{errors.weightChitLabelNett}</Form.Control.Feedback>
                        </>} */}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.weightChitShowNonBillableSortOrder} itemData={['weightChitShowNonBillableSortOrder', 'weightChitLabelNonBillableSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.weightChitShowNonBillable.id}
                            label={null}
                            checked={values.weightChitShowNonBillable}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitShowNonBillable}
                            feedback={errors.weightChitShowNonBillable}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelNonBillable.label}</Form.Label>
                    <Col xs={12} md={true}>
                        {/* {(values.weightChitAccountDocSettinglayout == WeighChitLayout.Receipt) && <>
                            <Form.Control
                                type="text"
                                id={this.props.fields.weightChitLabelNonBillable.id}
                                placeholder={this.props.fields.weightChitLabelNonBillable.placeholder}
                                value={values.weightChitLabelNonBillable}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={!!errors.weightChitLabelNonBillable}
                            />
                            <Form.Control.Feedback type="invalid">{errors.weightChitLabelNonBillable}</Form.Control.Feedback>
                        </>} */}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.weightChitShowBillableSortOrder} itemData={['weightChitShowBillableSortOrder', 'weightChitLabelBillableSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.weightChitShowBillable.id}
                            label={null}
                            checked={values.weightChitShowBillable}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitShowBillable}
                            feedback={errors.weightChitShowBillable}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelBillable.label}</Form.Label>
                    <Col xs={12} md={true}>
                        {/* {(values.weightChitAccountDocSettinglayout == WeighChitLayout.Receipt) && <>
                            <Form.Control
                                type="text"
                                id={this.props.fields.weightChitLabelBillable.id}
                                placeholder={this.props.fields.weightChitLabelBillable.placeholder}
                                value={values.weightChitLabelBillable}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={!!errors.weightChitLabelBillable}
                            />
                            <Form.Control.Feedback type="invalid">{errors.weightChitLabelBillable}</Form.Control.Feedback>
                        </>} */}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.weightChitShowRateSortOrder} itemData={['weightChitShowRateSortOrder', 'weightChitLabelRateSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.weightChitShowRate.id}
                            label={null}
                            checked={values.weightChitShowRate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitShowRate}
                            feedback={errors.weightChitShowRate}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelRate.label}</Form.Label>
                    <Col xs={12} md={true}>
                        {/* {(values.weightChitAccountDocSettinglayout == WeighChitLayout.Receipt) && <>
                            <Form.Control
                                type="text"
                                id={this.props.fields.weightChitLabelRate.id}
                                placeholder={this.props.fields.weightChitLabelRate.placeholder}
                                value={values.weightChitLabelRate}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={!!errors.weightChitLabelRate}
                            />
                            <Form.Control.Feedback type="invalid">{errors.weightChitLabelRate}</Form.Control.Feedback>
                        </>} */}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.weightChitShowAmountSortOrder} itemData={['weightChitShowAmountSortOrder', 'weightChitLabelAmountSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.weightChitShowAmount.id}
                            label={null}
                            checked={values.weightChitShowAmount}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.weightChitShowAmount}
                            feedback={errors.weightChitShowAmount}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelAmount.label}</Form.Label>
                    <Col xs={12} md={true}>
                        {/* {(values.weightChitAccountDocSettinglayout == WeighChitLayout.Receipt) && <>
                            <Form.Control
                                type="text"
                                id={this.props.fields.weightChitLabelAmount.id}
                                placeholder={this.props.fields.weightChitLabelAmount.placeholder}
                                value={values.weightChitLabelAmount}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={!!errors.weightChitLabelAmount}
                            />
                            <Form.Control.Feedback type="invalid">{errors.weightChitLabelAmount}</Form.Control.Feedback>
                        </>} */}
                    </Col>
                </Form.Group>
            </DraggableListByChildren>


            <Row className={'mt-15 mb-10'}>
                <Col xs={12}>
                    <hr />
                </Col>
            </Row>
            <DraggableListByChildren 
                droppableId={'weightChitweightFooterDroppable1'}
                layout={'row'}
                onDragEnd={(items, sourceIndex, destinationIndex) => {
                    if(items && items.length > 0){
                        items.forEach((item, i) => {
                            if(item && item.itemData && item.itemData.length > 0){
                                item.itemData.forEach((itemD, j) => {
                                    setFieldValue(itemD, item.sortOrder);
                                });
                            }
                        });
                    }
                }}
            >
                <Form.Group as={Row} sortOrder={values.weightChitShowMessageToCustomerSortOrder} itemData={['weightChitShowMessageToCustomerSortOrder', 'weightChitLabelMessageToCustomerSortOrder']} draggable={false}>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelMessageToCustomerPlainText.label}</Form.Label>
                    <Col xs={12}>
                        <Form.Control
                            as = {RTextEditor}
                            isInvalid={!!errors.weightChitLabelMessageToCustomerPlainText}
                            id={this.props.fields.weightChitLabelMessageToCustomerPlainText.id}

                            toolbar={{
                                options: ['inline', 'textAlign', 'list'],
                                inline: {
                                    inDropdown: false,
                                    options: ['bold', 'italic', 'underline'],
                                },
                                textAlign: {
                                    options: ['left', 'center', 'right', 'justify'],
                                },
                                list: {
                                inDropdown: false,
                                options: ['unordered', 'ordered'],
                                },
                            }}

                            editorState={values.weightChitLabelMessageToCustomer}
                            placeholder={this.props.fields.weightChitLabelMessageToCustomerPlainText.placeholder}
                            onChange={async (plainText, html, editorState) => {
                                await setFieldValue('weightChitLabelMessageToCustomerPlainText', plainText);
                                await setFieldValue('weightChitLabelMessageToCustomerHTML', html);
                                await setFieldValue('weightChitLabelMessageToCustomer', editorState);
                                await setFieldTouched('weightChitLabelMessageToCustomerPlainText', true);
                            }}
                        />
                        <Form.Control.Feedback type="invalid">{errors.weightChitLabelMessageToCustomerPlainText}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.weightChitShowFooterSortOrder} itemData={['weightChitShowFooterSortOrder', 'weightChitLabelFooterSortOrder']} draggable={false}>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelFooterPlainText.label}</Form.Label>
                    <Col xs={12}>
                        <Form.Control
                            as = {RTextEditor}
                            isInvalid={!!errors.weightChitLabelFooterPlainText}
                            id={this.props.fields.weightChitLabelFooterPlainText.id}

                            editorState={values.weightChitLabelFooter}
                            placeholder={this.props.fields.weightChitLabelFooterPlainText.placeholder}
                            onChange={async (plainText, html, editorState) => {
                                await setFieldValue('weightChitLabelFooterPlainText', plainText);
                                await setFieldValue('weightChitLabelFooterHTML', html);
                                await setFieldValue('weightChitLabelFooter', editorState);
                                await setFieldTouched('weightChitLabelFooterPlainText', true);
                            }}
                        />
                        <Form.Control.Feedback type="invalid">{errors.weightChitLabelFooterPlainText}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
            </DraggableListByChildren>
        </>
    }

    bodyReceipt = () => {
        let {
            handleChange,
            handleBlur,
            setFieldValue,
            setValues,
            setFieldTouched,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;
        
        return <>
            <DraggableMultiListByChildren 
                droppableId={'weightChitweightDetailsDroppable2'}
                init={<>
                    {this.getGroup(0)}
                    {this.getGroup(1)}
                    {this.getGroup(2)}
                    {this.getGroup(3)}
                    {this.getGroup(4)}
                </>}
                onDragEnd={({ items, sourceGroup, sourceIndex, destinationGroup, destinationIndex }) => {
                    if(items && items.length > 0){
                        let newValues = values;
    
                        items.forEach((item, i) => {
                            if(item && item.list && item.list.length > 0){
                                item.list.forEach((itemL, j) => {
                                    if(itemL && itemL.itemData && itemL.itemData.length > 0){
                                        itemL.itemData.forEach((itemD, j) => {
                                            newValues[itemD + 'SortOrder'] = itemL.sortOrder;
                                            newValues[itemD + 'Group'] = item.group;
                                        });
                                    };
                                });
                            }
                        });
                        
                        setValues(newValues);
                    }
                }}
            />
            
            <Row className={'mt-15 mb-10'}>
                <Col xs={12}>
                    <hr />
                </Col>
            </Row>
            <DraggableListByChildren 
                droppableId={'weightChitweightFooterDroppable2'}
                layout={'row'}
                onDragEnd={(items, sourceIndex, destinationIndex) => {
                    if(items && items.length > 0){
                        items.forEach((item, i) => {
                            if(item && item.itemData && item.itemData.length > 0){
                                item.itemData.forEach((itemD, j) => {
                                    setFieldValue(itemD, item.sortOrder);
                                });
                            }
                        });
                    }
                }}
            >
                <Form.Group as={Row} sortOrder={'0'} itemData={['weightChitShowMessageToCustomerSortOrder', 'weightChitLabelMessageToCustomerSortOrder']} draggable={false}>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelMessageToCustomerPlainText.label}</Form.Label>
                    <Col xs={12}>
                        <Form.Control
                            as = {RTextEditor}
                            isInvalid={!!errors.weightChitLabelMessageToCustomerPlainText}
                            id={this.props.fields.weightChitLabelMessageToCustomerPlainText.id}

                            toolbar={{
                                options: ['inline', 'textAlign', 'list'],
                                inline: {
                                    inDropdown: false,
                                    options: ['bold', 'italic', 'underline'],
                                },
                                textAlign: {
                                    options: ['left', 'center', 'right', 'justify'],
                                },
                                list: {
                                inDropdown: false,
                                options: ['unordered', 'ordered'],
                                },
                            }}

                            editorState={values.weightChitLabelMessageToCustomer}
                            placeholder={this.props.fields.weightChitLabelMessageToCustomerPlainText.placeholder}
                            onChange={async (plainText, html, editorState) => {
                                await setFieldValue('weightChitLabelMessageToCustomerPlainText', plainText);
                                await setFieldValue('weightChitLabelMessageToCustomerHTML', html);
                                await setFieldValue('weightChitLabelMessageToCustomer', editorState);
                                await setFieldTouched('weightChitLabelMessageToCustomerPlainText', true);
                            }}
                        />
                        <Form.Control.Feedback type="invalid">{errors.weightChitLabelMessageToCustomerPlainText}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                {/* <Form.Group as={Row} sortOrder={'1'} itemData={['weightChitShowFooterSortOrder', 'weightChitLabelFooterSortOrder']} draggable={false}>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelFooterPlainText.label}</Form.Label>
                    <Col xs={12}>
                        <Form.Control
                            as = {RTextEditor}
                            isInvalid={!!errors.weightChitLabelFooterPlainText}
                            id={this.props.fields.weightChitLabelFooterPlainText.id}

                            editorState={values.weightChitLabelFooter}
                            placeholder={this.props.fields.weightChitLabelFooterPlainText.placeholder}
                            onChange={async (plainText, html, editorState) => {
                                await setFieldValue('weightChitLabelFooterPlainText', plainText);
                                await setFieldValue('weightChitLabelFooterHTML', html);
                                await setFieldValue('weightChitLabelFooter', editorState);
                                await setFieldTouched('weightChitLabelFooterPlainText', true);
                            }}
                        />
                        <Form.Control.Feedback type="invalid">{errors.weightChitLabelFooterPlainText}</Form.Control.Feedback>
                    </Col>
                </Form.Group> */}
            </DraggableListByChildren>
        </>
    }


    getGroup = (group = 0) => {
        let {
            handleChange,
            handleBlur,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;

        let rows = [
            <Form.Group key={'rf_' + 1} as={Row} group={values.weightChitLabelWeightTicketGroup} sortOrder={values.weightChitLabelWeightTicketSortOrder} itemData={['weightChitLabelWeightTicket']} draggable={false}>
                        <Col xs={12} md={2} xxl={1}>
                            
                        </Col>
                        <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelWeightTicket.label}</Form.Label>
                            <Col xs={12} md={true}>
                                <Form.Control
                                    type="text"
                                    id={this.props.fields.weightChitLabelWeightTicket.id}
                                    placeholder={this.props.fields.weightChitLabelWeightTicket.placeholder}
                                    value={values.weightChitLabelWeightTicket}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={!!errors.weightChitLabelWeightTicket}
                                />
                                <Form.Control.Feedback type="invalid">{errors.weightChitLabelWeightTicket}</Form.Control.Feedback>
                            </Col>
            </Form.Group>,
            <Form.Group key={'rf_' + 2} as={Row} group={values.weightChitShowCustomerNameGroup} sortOrder={values.weightChitShowCustomerNameSortOrder} itemData={['weightChitShowCustomerName', 'weightChitLabelCustomerName']}>
                <Col xs={12} md={2} xxl={1}>
                    <Form.Check
                        id={this.props.fields.weightChitShowCustomerName.id}
                        label={null}
                        checked={values.weightChitShowCustomerName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitShowCustomerName}
                        feedback={errors.weightChitShowCustomerName}
                    />
                </Col>
                <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelCustomerName.label}</Form.Label>
                <Col xs={12} md={true}>
                    <Form.Control
                        type="text"
                        id={this.props.fields.weightChitLabelCustomerName.id}
                        placeholder={this.props.fields.weightChitLabelCustomerName.placeholder}
                        value={values.weightChitLabelCustomerName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitLabelCustomerName}
                    />
                    <Form.Control.Feedback type="invalid">{errors.weightChitLabelCustomerName}</Form.Control.Feedback>
                </Col>
            </Form.Group>,
            <Form.Group key={'rf_' + 3} as={Row} group={values.weightChitShowCustomerAddressGroup} sortOrder={values.weightChitShowCustomerAddressSortOrder} itemData={['weightChitShowCustomerAddress', 'weightChitLabelCustomerAddress']}>
                <Col xs={12} md={2} xxl={1}>
                    <Form.Check
                        id={this.props.fields.weightChitShowCustomerAddress.id}
                        label={null}
                        checked={values.weightChitShowCustomerAddress}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitShowCustomerAddress}
                        feedback={errors.weightChitShowCustomerAddress}
                    />
                </Col>
                <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelCustomerAddress.label}</Form.Label>
                <Col xs={12} md={true}>
                    <Form.Control
                        type="text"
                        id={this.props.fields.weightChitLabelCustomerAddress.id}
                        placeholder={this.props.fields.weightChitLabelCustomerAddress.placeholder}
                        value={values.weightChitLabelCustomerAddress}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitLabelCustomerAddress}
                    />
                    <Form.Control.Feedback type="invalid">{errors.weightChitLabelCustomerAddress}</Form.Control.Feedback>
                </Col>
            </Form.Group>,
            <Form.Group key={'rf_' + 4} as={Row} group={values.weightChitShowSiteAddressGroup} sortOrder={values.weightChitShowSiteAddressSortOrder} itemData={['weightChitShowSiteAddress', 'weightChitLabelSiteAddress']}>
                <Col xs={12} md={2} xxl={1}>
                    <Form.Check
                        id={this.props.fields.weightChitShowSiteAddress.id}
                        label={null}
                        checked={values.weightChitShowSiteAddress}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitShowSiteAddress}
                        feedback={errors.weightChitShowSiteAddress}
                    />
                </Col>
                <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelSiteAddress.label}</Form.Label>
                <Col xs={12} md={true}>
                    <Form.Control
                        type="text"
                        id={this.props.fields.weightChitLabelSiteAddress.id}
                        placeholder={this.props.fields.weightChitLabelSiteAddress.placeholder}
                        value={values.weightChitLabelSiteAddress}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitLabelSiteAddress}
                    />
                    <Form.Control.Feedback type="invalid">{errors.weightChitLabelSiteAddress}</Form.Control.Feedback>
                </Col>
            </Form.Group>,
            <Form.Group key={'rf_' + 5} as={Row} group={values.weightChitShowDONumberGroup} sortOrder={values.weightChitShowDONumberSortOrder} itemData={['weightChitShowDONumber', 'weightChitLabelDONumber']}>
                <Col xs={12} md={2} xxl={1}>
                    <Form.Check
                        id={this.props.fields.weightChitShowDONumber.id}
                        label={null}
                        checked={values.weightChitShowDONumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitShowDONumber}
                        feedback={errors.weightChitShowDONumber}
                    />
                </Col>
                <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelDONumber.label}</Form.Label>
                <Col xs={12} md={true}>
                    <Form.Control
                        type="text"
                        id={this.props.fields.weightChitLabelDONumber.id}
                        placeholder={this.props.fields.weightChitLabelDONumber.placeholder}
                        value={values.weightChitLabelDONumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitLabelDONumber}
                    />
                    <Form.Control.Feedback type="invalid">{errors.weightChitLabelDONumber}</Form.Control.Feedback>
                </Col>
            </Form.Group>,
            <Form.Group key={'rf_' + 6} as={Row} group={values.weightChitShowJobCompletionGroup} sortOrder={values.weightChitShowJobCompletionSortOrder} itemData={['weightChitShowJobCompletion', 'weightChitLabelJobCompletion']}>
                <Col xs={12} md={2} xxl={1}>
                    <Form.Check
                        id={this.props.fields.weightChitShowJobCompletion.id}
                        label={null}
                        checked={values.weightChitShowJobCompletion}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitShowJobCompletion}
                        feedback={errors.weightChitShowJobCompletion}
                    />
                </Col>
                <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelJobCompletion.label}</Form.Label>
                <Col xs={12} md={true}>
                    <Form.Control
                        type="text"
                        id={this.props.fields.weightChitLabelJobCompletion.id}
                        placeholder={this.props.fields.weightChitLabelJobCompletion.placeholder}
                        value={values.weightChitLabelJobCompletion}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitLabelJobCompletion}
                    />
                    <Form.Control.Feedback type="invalid">{errors.weightChitLabelJobCompletion}</Form.Control.Feedback>
                </Col>
            </Form.Group>,
            <Form.Group key={'rf_' + 7} as={Row} group={values.weightChitShowJobTypeGroup} sortOrder={values.weightChitShowJobTypeSortOrder} itemData={['weightChitShowJobType', 'weightChitLabelJobType']}>
                <Col xs={12} md={2} xxl={1}>
                    <Form.Check
                        id={this.props.fields.weightChitShowJobType.id}
                        label={null}
                        checked={values.weightChitShowJobType}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitShowJobType}
                        feedback={errors.weightChitShowJobType}
                    />
                </Col>
                <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelJobType.label}</Form.Label>
                <Col xs={12} md={true}>
                    <Form.Control
                        type="text"
                        id={this.props.fields.weightChitLabelJobType.id}
                        placeholder={this.props.fields.weightChitLabelJobType.placeholder}
                        value={values.weightChitLabelJobType}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitLabelJobType}
                    />
                    <Form.Control.Feedback type="invalid">{errors.weightChitLabelJobType}</Form.Control.Feedback>
                </Col>
            </Form.Group>,
            <Form.Group key={'rf_' + 8} as={Row} group={values.weightChitShowBinINTypeGroup} sortOrder={values.weightChitShowBinINTypeSortOrder} itemData={['weightChitShowBinINType', 'weightChitLabelBinINType']}>
                <Col xs={12} md={2} xxl={1}>
                    <Form.Check
                        id={this.props.fields.weightChitShowBinINType.id}
                        label={null}
                        checked={values.weightChitShowBinINType}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitShowBinINType}
                        feedback={errors.weightChitShowBinINType}
                    />
                </Col>
                <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelBinINType.label}</Form.Label>
                <Col xs={12} md={true}>
                    <Form.Control
                        type="text"
                        id={this.props.fields.weightChitLabelBinINType.id}
                        placeholder={this.props.fields.weightChitLabelBinINType.placeholder}
                        value={values.weightChitLabelBinINType}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitLabelBinINType}
                    />
                    <Form.Control.Feedback type="invalid">{errors.weightChitLabelBinINType}</Form.Control.Feedback>
                </Col>
            </Form.Group>,
            <Form.Group key={'rf_' + 9} as={Row} group={values.weightChitShowWasteINTypeGroup} sortOrder={values.weightChitShowWasteINTypeSortOrder} itemData={['weightChitShowWasteINType', 'weightChitLabelWasteINType']}>
                <Col xs={12} md={2} xxl={1}>
                    <Form.Check
                        id={this.props.fields.weightChitShowWasteINType.id}
                        label={null}
                        checked={values.weightChitShowWasteINType}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitShowWasteINType}
                        feedback={errors.weightChitShowWasteINType}
                    />
                </Col>
                <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelWasteINType.label}</Form.Label>
                <Col xs={12} md={true}>
                    <Form.Control
                        type="text"
                        id={this.props.fields.weightChitLabelWasteINType.id}
                        placeholder={this.props.fields.weightChitLabelWasteINType.placeholder}
                        value={values.weightChitLabelWasteINType}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitLabelWasteINType}
                    />
                    <Form.Control.Feedback type="invalid">{errors.weightChitLabelWasteINType}</Form.Control.Feedback>
                </Col>
            </Form.Group>,
            <Form.Group key={'rf_' + 10} as={Row} group={values.weightChitShowWeighTimestampGroup} sortOrder={values.weightChitShowWeighTimestampSortOrder} itemData={['weightChitShowWeighTimestamp', 'weightChitLabelWeighTimestamp']}>
                <Col xs={12} md={2} xxl={1}>
                    <Form.Check
                        id={this.props.fields.weightChitShowWeighTimestamp.id}
                        label={null}
                        checked={values.weightChitShowWeighTimestamp}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitShowWeighTimestamp}
                        feedback={errors.weightChitShowWeighTimestamp}
                    />
                </Col>
                <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelWeighTimestamp.label}</Form.Label>
                <Col xs={12} md={true}>
                    <Form.Control
                        type="text"
                        id={this.props.fields.weightChitLabelWeighTimestamp.id}
                        placeholder={this.props.fields.weightChitLabelWeighTimestamp.placeholder}
                        value={values.weightChitLabelWeighTimestamp}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitLabelWeighTimestamp}
                    />
                    <Form.Control.Feedback type="invalid">{errors.weightChitLabelWeighTimestamp}</Form.Control.Feedback>
                </Col>
            </Form.Group>,
            <Form.Group key={'rf_' + 11} as={Row} group={values.weightChitShowWeighOutTimestampGroup} sortOrder={values.weightChitShowWeighOutTimestampSortOrder} itemData={['weightChitShowWeighOutTimestamp', 'weightChitLabelWeighOutTimestamp']}>
                <Col xs={12} md={2} xxl={1}>
                    <Form.Check
                        id={this.props.fields.weightChitShowWeighOutTimestamp.id}
                        label={null}
                        checked={values.weightChitShowWeighOutTimestamp}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitShowWeighOutTimestamp}
                        feedback={errors.weightChitShowWeighOutTimestamp}
                    />
                </Col>
                <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelWeighOutTimestamp.label}</Form.Label>
                <Col xs={12} md={true}>
                    <Form.Control
                        type="text"
                        id={this.props.fields.weightChitLabelWeighOutTimestamp.id}
                        placeholder={this.props.fields.weightChitLabelWeighOutTimestamp.placeholder}
                        value={values.weightChitLabelWeighOutTimestamp}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitLabelWeighOutTimestamp}
                    />
                    <Form.Control.Feedback type="invalid">{errors.weightChitLabelWeighOutTimestamp}</Form.Control.Feedback>
                </Col>
            </Form.Group>,
            <Form.Group key={'rf_' + 12} as={Row} group={values.weightChitShowDriverGroup} sortOrder={values.weightChitShowDriverSortOrder} itemData={['weightChitShowDriver', 'weightChitLabelDriver']}>
                <Col xs={12} md={2} xxl={1}>
                    <Form.Check
                        id={this.props.fields.weightChitShowDriver.id}
                        label={null}
                        checked={values.weightChitShowDriver}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitShowDriver}
                        feedback={errors.weightChitShowDriver}
                    />
                </Col>
                <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelDriver.label}</Form.Label>
                <Col xs={12} md={true}>
                    <Form.Control
                        type="text"
                        id={this.props.fields.weightChitLabelDriver.id}
                        placeholder={this.props.fields.weightChitLabelDriver.placeholder}
                        value={values.weightChitLabelDriver}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitLabelDriver}
                    />
                    <Form.Control.Feedback type="invalid">{errors.weightChitLabelDriver}</Form.Control.Feedback>
                </Col>
            </Form.Group>,
            <Form.Group key={'rf_' + 13} as={Row} group={values.weightChitShowVehicleGroup} sortOrder={values.weightChitShowVehicleSortOrder} itemData={['weightChitShowVehicle', 'weightChitLabelVehicle']}>
                <Col xs={12} md={2} xxl={1}>
                    <Form.Check
                        id={this.props.fields.weightChitShowVehicle.id}
                        label={null}
                        checked={values.weightChitShowVehicle}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitShowVehicle}
                        feedback={errors.weightChitShowVehicle}
                    />
                </Col>
                <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelVehicle.label}</Form.Label>
                <Col xs={12} md={true}>
                    <Form.Control
                        type="text"
                        id={this.props.fields.weightChitLabelVehicle.id}
                        placeholder={this.props.fields.weightChitLabelVehicle.placeholder}
                        value={values.weightChitLabelVehicle}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitLabelVehicle}
                    />
                    <Form.Control.Feedback type="invalid">{errors.weightChitLabelVehicle}</Form.Control.Feedback>
                </Col>
            </Form.Group>,
            <Form.Group key={'rf_' + 14} as={Row} group={values.weightChitShowGrossGroup} sortOrder={values.weightChitShowGrossSortOrder} itemData={['weightChitShowGross', 'weightChitLabelGross']}>
                <Col xs={12} md={2} xxl={1}>
                    <Form.Check
                        id={this.props.fields.weightChitShowGross.id}
                        label={null}
                        checked={values.weightChitShowGross}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitShowGross}
                        feedback={errors.weightChitShowGross}
                    />
                </Col>
                <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelGross.label}</Form.Label>
                <Col xs={12} md={true}>
                    <Form.Control
                        type="text"
                        id={this.props.fields.weightChitLabelGross.id}
                        placeholder={this.props.fields.weightChitLabelGross.placeholder}
                        value={values.weightChitLabelGross}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitLabelGross}
                    />
                    <Form.Control.Feedback type="invalid">{errors.weightChitLabelGross}</Form.Control.Feedback>
                </Col>
            </Form.Group>,
            <Form.Group key={'rf_' + 15} as={Row} group={values.weightChitShowTareGroup} sortOrder={values.weightChitShowTareSortOrder} itemData={['weightChitShowTare', 'weightChitLabelTare']}>
                <Col xs={12} md={2} xxl={1}>
                    <Form.Check
                        id={this.props.fields.weightChitShowTare.id}
                        label={null}
                        checked={values.weightChitShowTare}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitShowTare}
                        feedback={errors.weightChitShowTare}
                    />
                </Col>
                <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelTare.label}</Form.Label>
                <Col xs={12} md={true}>
                    <Form.Control
                        type="text"
                        id={this.props.fields.weightChitLabelTare.id}
                        placeholder={this.props.fields.weightChitLabelTare.placeholder}
                        value={values.weightChitLabelTare}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitLabelTare}
                    />
                    <Form.Control.Feedback type="invalid">{errors.weightChitLabelTare}</Form.Control.Feedback>
                </Col>
            </Form.Group>,
            <Form.Group key={'rf_' + 16} as={Row} group={values.weightChitShowNettGroup} sortOrder={values.weightChitShowNettSortOrder} itemData={['weightChitShowNett', 'weightChitLabelNett']}>
                <Col xs={12} md={2} xxl={1}>
                    <Form.Check
                        id={this.props.fields.weightChitShowNett.id}
                        label={null}
                        checked={values.weightChitShowNett}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitShowNett}
                        feedback={errors.weightChitShowNett}
                    />
                </Col>
                <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelNett.label}</Form.Label>
                <Col xs={12} md={true}>
                    <Form.Control
                        type="text"
                        id={this.props.fields.weightChitLabelNett.id}
                        placeholder={this.props.fields.weightChitLabelNett.placeholder}
                        value={values.weightChitLabelNett}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitLabelNett}
                    />
                    <Form.Control.Feedback type="invalid">{errors.weightChitLabelNett}</Form.Control.Feedback>
                </Col>
            </Form.Group>,
            <Form.Group key={'rf_' + 17} as={Row} group={values.weightChitShowRemarksGroup} sortOrder={values.weightChitShowRemarksSortOrder} itemData={['weightChitShowRemarks', 'weightChitLabelRemarks']}>
                <Col xs={12} md={2} xxl={1}>
                    <Form.Check
                        id={this.props.fields.weightChitShowRemarks.id}
                        label={null}
                        checked={values.weightChitShowRemarks}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitShowRemarks}
                        feedback={errors.weightChitShowRemarks}
                    />
                </Col>
                <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelRemarks.label}</Form.Label>
                <Col xs={12} md={true}>
                    <Form.Control
                        type="text"
                        id={this.props.fields.weightChitLabelRemarks.id}
                        placeholder={this.props.fields.weightChitLabelRemarks.placeholder}
                        value={values.weightChitLabelRemarks}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitLabelRemarks}
                    />
                    <Form.Control.Feedback type="invalid">{errors.weightChitLabelRemarks}</Form.Control.Feedback>
                </Col>
            </Form.Group>,
            <Form.Group key={'rf_' + 18} as={Row} group={values.weightChitShowWeighByGroup} sortOrder={values.weightChitShowWeighBySortOrder} itemData={['weightChitShowWeighBy', 'weightChitLabelWeighBy']}>
                <Col xs={12} md={2} xxl={1}>
                    <Form.Check
                        id={this.props.fields.weightChitShowWeighBy.id}
                        label={null}
                        checked={values.weightChitShowWeighBy}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitShowWeighBy}
                        feedback={errors.weightChitShowWeighBy}
                    />
                </Col>
                <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.weightChitLabelWeighBy.label}</Form.Label>
                <Col xs={12} md={true}>
                    <Form.Control
                        type="text"
                        id={this.props.fields.weightChitLabelWeighBy.id}
                        placeholder={this.props.fields.weightChitLabelWeighBy.placeholder}
                        value={values.weightChitLabelWeighBy}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.weightChitLabelWeighBy}
                    />
                    <Form.Control.Feedback type="invalid">{errors.weightChitLabelWeighBy}</Form.Control.Feedback>
                </Col>
            </Form.Group>,
        ];


        for(let i = 0; i < 5; i++){
            let idx = (i+1);
            let customField = values.weightChitCustomFields.find(x => x.accountCustomFieldId == values['weightChitCustomFieldId' + idx]);
            rows.push(<Form.Group key={'cf_' + i} as={Row} group={values['weightChitCustomField' + idx + 'Group']}  sortOrder={values['weightChitCustomField' + idx + 'SortOrder']} itemData={['weightChitCustomField' + idx]}>
                <Col xs={12} md={2} xxl={1}>
                    <Form.Check
                        id={this.props.fields.weightChitCustomFieldsLabel.id + '_show_' + i}
                        label={null}
                        checked={values['weightChitCustomField' + idx]}
                        value={(values && values['weightChitCustomField' + idx]) ? values['weightChitCustomField' + idx] : false}
                        onChange={async (e) => {
                            await setFieldValue('weightChitCustomField' + idx, e.target.checked);
                        }}
                        onBlur={handleBlur}
                    />
                </Col>
                <Form.Label column xs={12} md={4} lg={3} xxl={2} className={'pe-0 pe-md-10'}>{(customField && customField.customFieldLabel && customField.customFieldLabel != '') ? customField.customFieldLabel : this.props.fields.weightChitCustomFieldsLabel.label}</Form.Label>
                <Col xs={12} md={true}>
                    <Form.Control
                        type="text"
                        id={this.props.fields.weightChitCustomFieldsLabel.id + '_input_' + i}
                        placeholder={this.props.fields['weightChitCustomField' + idx].placeholder}
                        disabled={this.props.weighChitCustomFields.isLoading}
                        value={(customField && customField.label && customField.label != '') ? customField.label : ''}
                        onChange={async (e) => {
                            await setFieldValue('weightChitCustomFields.' + i + '.label', e.target.value);
                        }}
                        onBlur={async (e) => {
                            let data = {
                                label: e.target.value,
                            };
    
                            if(customField.accountCustomFieldId){
                                data['accountCustomFieldId'] = customField.accountCustomFieldId;
                            }
    
                            validateForm().then(async (err) => {
                                if(!!err.weightChitCustomFields){
                                    Utils.toast('You must fill in the required fields', 'error');
                                } else {
                                    await this.props.action.weighChitCustomFields.callUpdateApi(data);
                                    
                                    let wcItem = this.props.weighChitCustomFields.item;
                                    if(wcItem){
                                        await setFieldValue('weightChitCustomFields.' + i + '.accountCustomFieldId', wcItem.accountCustomFieldId);
                                        await setFieldValue('weightChitCustomFields.' + i + '.label', wcItem.label);
                                    }
                                }
                            })
                        }}
                        isInvalid={!!(errors && errors.weightChitCustomFields && errors.weightChitCustomFields[i] && errors.weightChitCustomFields[i].label)}
                    />
                    <Form.Control.Feedback type="invalid">{(errors && errors.weightChitCustomFields && errors.weightChitCustomFields[i] && errors.weightChitCustomFields[i].label) && errors.weightChitCustomFields[i].label}</Form.Control.Feedback>
                </Col>
            </Form.Group>);
        }

        return <div group={group}>{rows.filter(x => x.props.group == group)}</div>;
    }


    render() {
        let {
            values,
        } = this.props.formOptions;

        return <Row>
            <Col xs={12} md={10} xl={8}>
                {
                    (values.weightChitAccountDocSettinglayout == WeighChitLayout.Receipt)
                    ?
                    <>
                        {this.defaultTemplate()}
                        {this.bodyReceipt()}
                    </>
                    :
                    <>
                        {this.defaultTemplate()}
                        {this.bodyDefault()}
                    </>
                }
            </Col>
        </Row>
    }
}


const mapStateToProps = state => {
    return {
      ...state,
      ...Utils.mapStateToProps(state),
    };
};
  
const mapDispatchToProps = (dispatch) => ({
    dispatchApiCallGet: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallGet(p1, p2, p3, p4, p5, p6)),
    clear: (payload) => dispatch(clear(payload)),
    action: {
        weighChitCustomFields: {
            callReadApi: (payload) => dispatch(weighChitCustomFields.callReadApi(payload)),
            callUpdateApi: (payload) => dispatch(weighChitCustomFields.callUpdateApi(payload)),
            callDeleteApi: (payload) => dispatch(weighChitCustomFields.callDeleteApi(payload)),
        },
    }
});
  
  
  export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(WeightChitForm);