import React from 'react';
import { compose, withProps } from 'recompose';
import { GoogleMap, Marker, InfoWindow, withScriptjs, withGoogleMap } from "react-google-maps"
import { MarkerClusterer } from "react-google-maps/lib/components/addons/MarkerClusterer";

import Spiderfy from "./Spiderfy";


const { REACT_APP_GOOGLE_MAP_API_KEY } = process.env;
const URL = "https://maps.googleapis.com/maps/api/js?key=" + REACT_APP_GOOGLE_MAP_API_KEY + "&v=3.exp&libraries=geometry,drawing,places";
const DEFAULT_CENTER = { lat: 1.353915, lng: 103.822901 };
const DEFAULT_ZOOM = 13;

const styles = [
  {
    featureType: "poi",
    stylers: [
     { visibility: "off" }
    ]   
   }
];

const GoogleMapInline = compose(
  withProps({
    googleMapURL: URL,
    loadingElement: <div style={{ width: "100%", height: "100%" }} />,
    containerElement: <div style={{ width: "100%", height: "100%" }} />,
    mapElement: <div style={{ width: "100%", height: "100%" }} />,
  }),
  withScriptjs,
  withGoogleMap
)(props => {
  const [center, setCenter] = React.useState(props.center ? props.center : DEFAULT_CENTER);
  const [zoom, setZoom] = React.useState(props.zoom ? props.zoom : DEFAULT_ZOOM);
  const [markers, setMarkers] = React.useState(props.markers ? props.markers : []);
  const [isLoading, setIsLoading] = React.useState(props.isLoading ? props.isLoading : false);


  React.useEffect(() => {
    setCenter(props.center ? props.center : DEFAULT_CENTER);
  }, [props.center]);

  React.useEffect(() => {
    setZoom(props.zoom ? props.zoom : DEFAULT_ZOOM);
  }, [props.zoom]);

  React.useEffect(() => {
    setMarkers(props.markers ? props.markers : []);
  }, [props.markers]);

  React.useEffect(() => {
    setIsLoading(props.isLoading ? props.isLoading : false);
  }, [props.isLoading]);


  const onToggleCloseAllAndOpen = (e, i) => {
    let newMarkers = Object.keys(markers).map((key) => markers[key]);

    if(newMarkers && newMarkers.length > 0){
      let isOpen = false;

      let index = newMarkers.findIndex(x => x.position.lat == i.position.lat && x.position.lng == i.position.lng && x.type == i.type && x.value == i.value);
      if(index > -1){
        isOpen = newMarkers[index].isOpen;
      }

      for(let m = 0; m < newMarkers.length; m++){
        newMarkers[m].isOpen = false;
      }

      if(index > -1){
        newMarkers[index].isOpen = !isOpen;
      }
    }

    setMarkers(newMarkers);
  }

  const onToggleOpen = (e, i) => {
    let newMarkers = Object.keys(markers).map((key) => markers[key]);

    if(newMarkers && newMarkers.length > 0){
      let index = newMarkers.findIndex(x => x.position.lat == i.position.lat && x.position.lng == i.position.lng && x.type == i.type && x.value == i.value);
      
      for(let m = 0; m < newMarkers.length; m++){
        newMarkers[m].isOpen = false;
      }

      if(index > -1){
        newMarkers[index].isOpen = true;
      }
    }

    setMarkers(newMarkers);
  }

  const onToggleClose = (e) => {
    let newMarkers = Object.keys(markers).map((key) => markers[key]);

    if(newMarkers && newMarkers.length > 0){
      for(let m = 0; m < newMarkers.length; m++){
        newMarkers[m].isOpen = false;
      }
    }

    setMarkers(newMarkers);
  }
  
  const refresh = (globalItem, i, item) => {
    let newMarkers = Object.keys(markers).map((key) => markers[key]);

    if(newMarkers && newMarkers.length > 0){
      let index = newMarkers.findIndex(x => x.position.lat == globalItem.position.lat && x.position.lng == globalItem.position.lng && x.type == globalItem.type && x.value == globalItem.value);
      if(index > -1){
        newMarkers[index].selectedJob = i;
        newMarkers[index].item = item;
        setMarkers(newMarkers);
      }
    }
  }

  const showMarkers = (type = null) => {
    if(markers && markers.length > 0){
      let newMarkers = [];

      markers.forEach((item, i) => {
        if((type == null) || (item.type == type)){
          let m = <Marker
            key={i}
            position={item.position}
            title={item.title}
            options={{
              icon: (item.type == 2)
              ?
              {
                path: window.google.maps.SymbolPath.CIRCLE,
                strokeColor: (item.color && item.color != '') ? item.color : "#088F8F",
                fillColor: "white",
                fillOpacity: 1,
                scale: (item.label && item.label != '') ? 9 : 8,
                strokeWeight: (item.label && item.label != '') ? 5 : 4,
              }
              :
                (item.type == 3)
                ?
                {
                  url: item.icon,
                  scaledSize: new window.google.maps.Size(26, 32),
                  labelOrigin: new window.google.maps.Point(13, 41),
                }
                :
                (item.icon && item.icon != '') ? item.icon : null,

              label: (item.label && item.label != '')
                ? 
                (item.type == 1)
                  ?
                  {
                    text: item.label,
                    color: "white",
                    className: "marker-pin-number"
                  }
                  :
                    (item.type == 3)
                    ?
                    item.label
                    :
                    {
                      text: item.label,
                      fontWeight: 'bold',
                      fontSize: '13px',
                      color: "black",
                    }
                :
                null,
              data: item,
              zIndex: 1000
            }}
            // onClick={(e) => onToggleCloseAllAndOpen(e, item)}
          >
            {item.isOpen && <InfoWindow onCloseClick={(e) => onToggleClose(e, item)}>{<div style={{ overflow: 'hidden' }}>{(item.infoWindow) ? item.infoWindow(item, refresh) : <>{item.title}</>}</div>}</InfoWindow>}
          </Marker>

          newMarkers.push(m);
        }
      });

      if(newMarkers && newMarkers.length > 0){
        return <Spiderfy
          onSpiderClick={(e, item) => {
            if(item.type != 3){
              if(props.detailsApi && !item.isOpen){
                props.detailsApi(item, () => {
                  onToggleCloseAllAndOpen(e, item)
                });
              } else {
                onToggleCloseAllAndOpen(e, item)
              }
            }
          }}
        >
          {newMarkers}
        </Spiderfy>
      }
    }
  }
  

  return <>
    <GoogleMap
      ref={props.onMapMounted}
      center={center}
      zoom={zoom}
      // options={{
      //   styles: styles,
      // }}
      defaultOptions={{
        styles: styles,
        disableDefaultUI: true,
        disableDoubleClickZoom: true,
        zoomControl: true,
        rotateControl: false,
        fullscreenControl: true,
        mapTypeControl: false,
        streetViewControl: false,
        streetViewControlOptions: {
          position: 7,
        },
      }}
      onClick={(e) => {
        onToggleClose(e)
      }}
    >
      {!isLoading && <>
        <MarkerClusterer
          averageCenter
          enableRetinaIcons
          gridSize={100}
          minimumClusterSize={10}
          maxZoom={15}
        >
          {showMarkers(2)}
        </MarkerClusterer>
        
        {showMarkers(1)}
        {showMarkers(3)}
      </>}
      
      {/* <Spiderfy
        onSpiderClick={(e, item) => {
          // onToggleCloseAllAndOpen(e, item)
          if(props.detailsApi && !item.isOpen){
            props.detailsApi(item, () => {
              onToggleCloseAllAndOpen(e, item)
            });
          } else {
            onToggleCloseAllAndOpen(e, item)
          }
        }}
        // onSpiderMouseOver={(e, item) => {
        //   if(props.detailsApi && !item.isOpen){
        //     props.detailsApi(item, () => {
        //       onToggleOpen(e, item)
        //     });
        //   } else {
        //     onToggleOpen(e, item)
        //   }
        // }}
      >
        {showMarkers(1)}
      </Spiderfy> */}

      {/* <Spiderfy
        onSpiderClick={(e, item) => {
          // onToggleCloseAllAndOpen(e, item)
          if(props.detailsApi && !item.isOpen){
            props.detailsApi(item, () => {
              onToggleCloseAllAndOpen(e, item)
            });
          } else {
            onToggleCloseAllAndOpen(e, item)
          }
        }}
        // onSpiderMouseOver={(e, item) => {
        //   if(props.detailsApi && !item.isOpen){
        //     props.detailsApi(item, () => {
        //       onToggleOpen(e, item)
        //     });
        //   } else {
        //     onToggleOpen(e, item)
        //   }
        // }}
      >
        <MarkerClusterer
          averageCenter
          enableRetinaIcons
          gridSize={100}
          minimumClusterSize={10}
        >
          {/* {showMarkers(2)} */}
          {/* {showMarkers()} */}
        {/* </MarkerClusterer> */}

        {/* {showMarkers(1)} */}
      {/* </Spiderfy>  */}
      

      {/* <MarkerClusterer
        averageCenter
        enableRetinaIcons
        gridSize={100}
        minimumClusterSize={10}
      >
        <Spiderfy
          onSpiderClick={(e, item) => {
            // onToggleCloseAllAndOpen(e, item)
            if(props.detailsApi && !item.isOpen){
              props.detailsApi(item, () => {
                onToggleCloseAllAndOpen(e, item)
              });
            } else {
              onToggleCloseAllAndOpen(e, item)
            }
          }}
          // onSpiderMouseOver={(e, item) => {
          //   if(props.detailsApi && !item.isOpen){
          //     props.detailsApi(item, () => {
          //       onToggleOpen(e, item)
          //     });
          //   } else {
          //     onToggleOpen(e, item)
          //   }
          // }}
        >
          {showMarkers()}
        </Spiderfy>
      </MarkerClusterer> */}
    </GoogleMap>
  </>
});


export default GoogleMapInline;