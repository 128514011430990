/* eslint-disable no-loop-func */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// import { ParseResult } from "../../../../utils/interfaces";


interface InitState {
  show: boolean,

  geofenceIndex: number|null,
  jobTemplateId: number|null,
  jobTypes: Array<any>,
  checkedJobTypes: Array<number>,
}


function NewReducer() {
  const name = 'payrollBetaCopyJobTypes';


  const initialState: InitState = {
    show: false,

    geofenceIndex: null,
    jobTemplateId: null,
    jobTypes: [],
    checkedJobTypes: [],
  };


  const reducers = {
    show: (state: InitState, action: PayloadAction<{ jobTemplateId: number|null, jobTypes: Array<any>, geofenceIndex: number|null }>) => {
      state.geofenceIndex = action.payload.geofenceIndex;
      state.jobTemplateId = action.payload.jobTemplateId;
      state.jobTypes = action.payload.jobTypes;
      state.checkedJobTypes = [];
      state.show = true;
    },
    hide: (state: InitState) => {
      state.geofenceIndex = null;
      state.jobTemplateId = null;
      state.jobTypes = [];
      state.checkedJobTypes = [];
      state.show = false;
    },

    setCheckedJobTypes: (state: InitState, action: PayloadAction<number>) => {
      let value = action.payload;
      let checkedJobTypes = state.checkedJobTypes;
      let index = checkedJobTypes.findIndex((x) => x == value);

      if (index === -1) {
        checkedJobTypes.push(value);
      } else {
        checkedJobTypes.splice(index, 1);
      }
      state.checkedJobTypes = checkedJobTypes;
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
  };
}


export default NewReducer();