import axios from 'axios';
import qs from 'query-string';


const { 
    REACT_APP_DISPATCH_API_URL,
    REACT_APP_XERO_AUTH_API_URL,
    REACT_APP_XERO_LOGIN_API_URL,
    REACT_APP_XERO_REDIRECT_URL,
    REACT_APP_XERO_CLIENT_ID,
    REACT_APP_XERO_SECRET,
    REACT_APP_XERO_SCOPE
} = process.env;


const API = "xeroapp";

const xeroApi = {

    getConnectUrl: (id = '') => {
        let url = REACT_APP_XERO_LOGIN_API_URL;

        var data = {
            response_type: "code",
            client_id: REACT_APP_XERO_CLIENT_ID,
            scope: REACT_APP_XERO_SCOPE,
            redirect_uri: REACT_APP_XERO_REDIRECT_URL,
            state: JSON.stringify({
                id: id,
                clientId: REACT_APP_XERO_CLIENT_ID
            })
        }
        
        return url + "?" + qs.stringify(data);
    },

    disconnect: async (data, token) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_DISPATCH_API_URL + API + '/deactivate';

        const getResult = await axios.put(
            URL,
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            })
            .catch( error => {
                try {
                    status = error.response.status;

                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch(err) {
                    errorMessage = 'Service Unavailable!'
                }
            });
        return { data: getResult, errorMessage: errorMessage, status: status};
    },

    read: async (data, token) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_DISPATCH_API_URL + API;
        
        const getResult = await axios.get(
            URL,
            { 
                params: data,
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            })
            .catch( error => {
                try {
                    status = error.response.status;

                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch(err) {
                    errorMessage = 'Service Unavailable!'
                }
            });
            
        return { data: getResult, errorMessage: errorMessage, status: status};
    },

    create: async (data, token) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_DISPATCH_API_URL + API;

        const getResult = await axios.post(
            URL,
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            })
            .catch( error => {
                try {
                    status = error.response.status;

                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch(err) {
                    errorMessage = 'Service Unavailable!'
                }
            });
        return { data: getResult, errorMessage: errorMessage, status: status};
    },
}

export default xeroApi;