/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Yup from 'yup';
import moment from 'moment';
import _ from 'lodash';

import * as dispatchCrudApi from '../../../../api/CRUD/DispatchCRUD'
import Utils from "../../../../utils/utils";
import { DayOfWeek, PayrollTemplateVariableType, PayrollTemplateIncentiveType } from "../../../../utils/enums";

import { ParseResult } from "../../../../utils/interfaces";


export const fields = {
  templateName: {
    id: 'templateName',
    label: 'Template name',
    placeholder: ' ',
  },
  templateDescription: {
    id: 'templateDescription',
    label: 'Description',
    placeholder: ' ',
  },

  hasSpecialDates: {
    id: 'hasSpecialDates',
    label: 'SPECIAL DATES',
    placeholder: ' ',
  },
  specialDates: {
    id: 'specialDates',
    label: 'Special dates',
    add: 'Add special dates',
    err: 'Fix the special date fields',
    errEmpty: 'Special date field cannot be empty',
  },
  specialDatesDate: {
    id: 'specialDatesDate',
    label: 'Special date',
    placeholder: 'DD/MM/YYYY',
  },
  specialDatesPay: {
    id: 'specialDatesPay',
    label: 'Pay',
    placeholder: ' ',
  },
  
  workTimes: {
    id: 'workTimes',
    label: 'Work times',
    placeholder: '',
  },
  times: {
    id: 'times',
    label: 'Work times',
    add: 'Add new time slot',
    err: 'Fix the work time fields',
  },
  workTimesLabel: {
    id: 'workTimesLabel',
    label: 'Label',
    placeholder: '',
  },
  workTimesDay: {
    id: 'workTimesDay',
    label: 'Day',
    placeholder: '',
  },
  workTimesIsEnabled: {
    id: 'workTimesIsEnabled',
    label: 'Enabled',
    placeholder: 'Rest',
  },
  workTimesTimeFrom: {
    id: 'workTimesTimeFrom',
    label: 'Time from',
    placeholder: 'hh:mm',
  },
  workTimesTimeTo: {
    id: 'workTimesTimeTo',
    label: 'Time to',
    placeholder: 'hh:mm',
  },
  workTimesPay: {
    id: 'workTimesPay',
    label: 'Pay',
    placeholder: ' ',
  },
  
  jobTemplates: {
    id: 'jobTemplates',
    label: 'Job types',
    placeholder: ' ',
  },
  jobTemplateId: {
    id: 'jobTemplateId',
    label: 'Job template',
    placeholder: ' ',
  },

  basicPay: {
    id: 'basicPay',
    label: 'Standard pay',
    placeholder: ' ',
  },
  incentivesPackagePay: {
    id: 'incentivesPackagePay',
    label: 'Package pay',
    placeholder: ' ',
  },
  incentivesPackagePer: {
    id: 'incentivesPackagePer',
    label: 'Per',
    placeholder: ' ',
    jobs: 'Jobs',
  },
  incentivesVolumePay: {
    id: 'incentivesVolumePay',
    label: 'Volume pay',
    placeholder: ' ',
  },
  graduatedWageId: {
    id: 'graduatedWageId',
    label: 'Graduated wage',
    placeholder: ' ',
    preview: 'Preview',
  },
  graduatedPay: {
    id: 'graduatedPay',
    label: 'Graduated pay',
    add: 'Add wage tier',
    err: 'Fix the graduated pay fields',
    preview: 'Preview',
  },
  graduatedPayText: {
    id: 'graduatedPayText',
    label: 'Graduated name',
    placeholder: 'Total units',
    firstText: 'For the first',
    nextText: 'For the next',
  },
  graduatedPayFirstJob: {
    id: 'graduatedPayFirstJob',
    label: 'First job',
    placeholder: ' ',
  },
  graduatedPayLastJob: {
    id: 'graduatedPayLastJob',
    label: 'Last job',
    placeholder: ' ',
  },
  graduatedPayJobPay: {
    id: 'graduatedPayJobPay',
    label: 'Per job',
    placeholder: ' ',
  },
  graduatedPayFlatPay: {
    id: 'graduatedPayFlatPay',
    label: 'Flat fee',
    placeholder: ' ',
  },
};

export const formSchema = (step: string) => {
  return Yup.object().shape({
    templateName: Yup.string().required().label(fields.templateName.label),
    templateDescription: Yup.string().label(fields.templateDescription.label),
    payrollTemplateVariableType: Yup.number(),
    payrollTemplateIncentiveType: Yup.number(),
    hasSpecialDates: Yup.boolean().nullable().label(fields.hasSpecialDates.label),

    workTimes: Yup.array().of(Yup.object().shape({
      label: Yup.string().required().label(fields.workTimesLabel.label),
      day: Yup.number().required().label(fields.workTimesDay.label),
      isEnabled: Yup.boolean().label(fields.workTimesIsEnabled.label),
  
      times: Yup.array().when(['isEnabled'], (isEnabled: boolean) => {
        if(isEnabled){
          return Yup.array().of(Yup.object().shape({
            timeFrom: Yup.string().nullable().required()
              .matches(/^([01][0-9]|2[0-3]):[0-5][0-9]$/, 'Enter the correct time format (hh:mm)')
              .test('is-timeFrom-less-than-timeTo', 'Time from must be less than time to', function (value) {
                const { timeTo } = this.parent;

                if (value == null || timeTo == null || timeTo == '__:__')
                  return true;
                
                const parsedTimeFrom = moment.utc(value, 'HH:mm');
                const parsedTimeTo = moment.utc(timeTo, 'HH:mm');
                return parsedTimeFrom.isBefore(parsedTimeTo);
              })
              .label(fields.workTimesTimeFrom.label),
            timeTo: Yup.string().nullable().required()
              .matches(/^([01][0-9]|2[0-3]):[0-5][0-9]$/, 'Enter the correct time format (hh:mm)')
              .test('is-timeTo-less-than-timeFrom', 'Time to must be greater than time from', function (value) {
                const { timeFrom } = this.parent;

                if (value == null || timeFrom == null || timeFrom == '__:__')
                  return true;

                const parsedTimeFrom = moment.utc(timeFrom, 'HH:mm');
                const parsedTimeTo = moment.utc(value, 'HH:mm');
                return parsedTimeTo.isAfter(parsedTimeFrom);
              })
              .label(fields.workTimesTimeTo.label),
            standardPay: Yup.number().nullable().label(fields.workTimesPay.label),
          }))
          .test(
            'differentTimeFromAndTo',
            'Time from and time to must be different',
            function (value) {
              if (!Array.isArray(value) || value.length <= 1)
                return true;

              let errors: any = [];
              value.forEach((item: any, i: number) => {
                if(item.timeFrom && item.timeTo) {
                  if(item.timeFrom == item.timeTo){
                    errors.push({[i]: {
                      timeFrom: 'Time from, time to must be different',
                      timeTo: 'Time from, time to must be different',
                    }})
                  }
                }
              });

              if(errors.length > 0){
                return this.createError({
                  message: () => errors
                })
              }

              return true;
            }
          )
          .test(
            'is-unique-timeFrom',
            '${path} cant have unique values for timeFrom and timeTo',
            function (value) {
              if (!Array.isArray(value) || value.length <= 1)
                return true;
          
              let errorsFrom: any = [];
              let errorsTo: any = [];
              let crossErrors: any = [];

              const uniqueTimeFrom = new Set();
              const uniqueTimeTo = new Set();

              value.forEach((item, index) => {
                if (uniqueTimeFrom.has(item.timeFrom)) {
                  errorsFrom.push({ [index]: { timeFrom: 'Time from can\'t be unique' } });
                } else {
                  uniqueTimeFrom.add(item.timeFrom);
                }

                if (uniqueTimeTo.has(item.timeTo)) {
                  errorsTo.push({ [index]: { timeTo: 'Time to can\'t be unique' } });
                } else {
                  uniqueTimeTo.add(item.timeTo);
                }

                for (let i = index + 1; i < value.length; i++) {
                  if (item.timeFrom === value[i].timeTo) {
                    crossErrors.push({ [index]: { timeFrom: 'Time from and time to can\'t have the same value' } });
                    crossErrors.push({ [i]: { timeTo: 'Time from and time to can\'t have the same value' } });
                  }
                  if (item.timeTo === value[i].timeFrom) {
                    crossErrors.push({ [index]: { timeTo: 'Time to and time from can\'t have the same value' } });
                    crossErrors.push({ [i]: { timeFrom: 'Time to and time from can\'t have the same value' } });
                  }
                }
              });

              let errors = [...errorsFrom, ...errorsTo, ...crossErrors];
              
              const mergedResult = errors.reduce((acc, item) => {
                const index = Object.keys(item)[0];
                acc[index] = { ...acc[index], ...item[index] };
                return acc;
              }, {});
              const finalResult = (Object.keys(mergedResult).length != 0) ? [mergedResult] : [];

              if (finalResult.length > 0) {
                return this.createError({
                  message: () => finalResult,
                });
              }
    
              return true;
            }
          )
        } else {
          return Yup.array().of(Yup.object().shape({
            timeFrom: Yup.string().nullable().matches(/^([01][0-9]|2[0-3]):[0-5][0-9]$/, 'Enter the correct time format (hh:mm)').label(fields.workTimesTimeFrom.label),
            timeTo: Yup.string().nullable().matches(/^([01][0-9]|2[0-3]):[0-5][0-9]$/, 'Enter the correct time format (hh:mm)').label(fields.workTimesTimeTo.label),
            standardPay: Yup.number().nullable().label(fields.workTimesPay.label),
          }));
        }
      })
      .min(1)
      .label(fields.times.label),
    }))
    .test('at-least-one-is-enabled', 'You have to enable work time', (value) =>
      Array.isArray(value) ? value.some((item) => item.isEnabled === true) : false
    )
    .label(fields.workTimes.label),

    specialDates: Yup.array().of(Yup.object().shape({
      date: Yup.date().when(['$hasSpecialDates'], (hasSpecialDates: boolean) => {
        if(hasSpecialDates){
          return Yup.date().required().label(fields.specialDatesDate.label)
        } else {
          return Yup.date().nullable().label(fields.specialDatesDate.label)
        }
      }),
      standardPay: Yup.number().nullable().label(fields.specialDatesPay.label),

      times: Yup.array().when(['$hasSpecialDates'], (hasSpecialDates: boolean) => {
        if(hasSpecialDates){
          return Yup.array().of(Yup.object().shape({
            timeFrom: Yup.string().nullable().required()
              .matches(/^([01][0-9]|2[0-3]):[0-5][0-9]$/, 'Enter the correct time format (hh:mm)')
              .test('is-timeFrom-less-than-timeTo', 'Time from must be less than time to', function (value) {
                const { timeTo } = this.parent;

                if (value == null || timeTo == null || timeTo == '__:__')
                  return true;
                
                const parsedTimeFrom = moment.utc(value, 'HH:mm');
                const parsedTimeTo = moment.utc(timeTo, 'HH:mm');
                return parsedTimeFrom.isBefore(parsedTimeTo);
              })
              .label(fields.workTimesTimeFrom.label),
            timeTo: Yup.string().nullable().required()
              .matches(/^([01][0-9]|2[0-3]):[0-5][0-9]$/, 'Enter the correct time format (hh:mm)')
              .test('is-timeTo-less-than-timeFrom', 'Time to must be greater than time from', function (value) {
                const { timeFrom } = this.parent;

                if (value == null || timeFrom == null || timeFrom == '__:__')
                  return true;

                const parsedTimeFrom = moment.utc(timeFrom, 'HH:mm');
                const parsedTimeTo = moment.utc(value, 'HH:mm');
                return parsedTimeTo.isAfter(parsedTimeFrom);
              })
              .label(fields.workTimesTimeTo.label),
            standardPay: Yup.number().nullable().label(fields.workTimesPay.label),
          }))
          .test(
            'differentTimeFromAndTo',
            'Time from and time to must be different',
            function (value) {
              if (!Array.isArray(value) || value.length <= 1)
                return true;

              let errors: any = [];
              value.forEach((item: any, i: number) => {
                if(item.timeFrom && item.timeTo) {
                  if(item.timeFrom == item.timeTo){
                    errors.push({[i]: {
                      timeFrom: 'Time from, time to must be different',
                      timeTo: 'Time from, time to must be different',
                    }})
                  }
                }
              });

              if(errors.length > 0){
                return this.createError({
                  message: () => errors
                })
              }

              return true;
            }
          )
          .test(
            'is-unique-timeFrom',
            '${path} cant have unique values for timeFrom and timeTo',
            function (value) {
              if (!Array.isArray(value) || value.length <= 1)
                return true;
          
              let errorsFrom: any = [];
              let errorsTo: any = [];
              let crossErrors: any = [];

              const uniqueTimeFrom = new Set();
              const uniqueTimeTo = new Set();

              value.forEach((item, index) => {
                if (uniqueTimeFrom.has(item.timeFrom)) {
                  errorsFrom.push({ [index]: { timeFrom: 'Time from can\'t be unique' } });
                } else {
                  uniqueTimeFrom.add(item.timeFrom);
                }

                if (uniqueTimeTo.has(item.timeTo)) {
                  errorsTo.push({ [index]: { timeTo: 'Time to can\'t be unique' } });
                } else {
                  uniqueTimeTo.add(item.timeTo);
                }

                for (let i = index + 1; i < value.length; i++) {
                  if (item.timeFrom === value[i].timeTo) {
                    crossErrors.push({ [index]: { timeFrom: 'Time from and time to can\'t have the same value' } });
                    crossErrors.push({ [i]: { timeTo: 'Time from and time to can\'t have the same value' } });
                  }
                  if (item.timeTo === value[i].timeFrom) {
                    crossErrors.push({ [index]: { timeTo: 'Time to and time from can\'t have the same value' } });
                    crossErrors.push({ [i]: { timeFrom: 'Time to and time from can\'t have the same value' } });
                  }
                }
              });

              let errors = [...errorsFrom, ...errorsTo, ...crossErrors];
              
              const mergedResult = errors.reduce((acc, item) => {
                const index = Object.keys(item)[0];
                acc[index] = { ...acc[index], ...item[index] };
                return acc;
              }, {});
              const finalResult = (Object.keys(mergedResult).length != 0) ? [mergedResult] : [];

              if (finalResult.length > 0) {
                return this.createError({
                  message: () => finalResult,
                });
              }
    
              return true;
            }
          )
        } else {
          return Yup.array().of(Yup.object().shape({
            timeFrom: Yup.string().nullable().matches(/^([01][0-9]|2[0-3]):[0-5][0-9]$/, 'Enter the correct time format (hh:mm)').label(fields.workTimesTimeFrom.label),
            timeTo: Yup.string().nullable().matches(/^([01][0-9]|2[0-3]):[0-5][0-9]$/, 'Enter the correct time format (hh:mm)').label(fields.workTimesTimeTo.label),
            standardPay: Yup.number().nullable().label(fields.workTimesPay.label),
          }));
        }
      })
      .min(1)
      .label(fields.times.label),
    }))
    .test(
      'is-unique',
      '${path} can\'t be unique',
      function (value) {
        if (!Array.isArray(value) || value.length <= 1)
          return true;
  
        const arr = value.map(obj => moment(obj.date).format(Utils.getDefaultDateFormat()));
        const unique = new Set(arr);
  
        return unique.size === arr.length;
      }
    )
    .test(
      'no-empty-fields-except-one',
      '${path} can\'t be empty',
      function (value) {
        if (!Array.isArray(value) || value.length <= 1) {
          return true;
        }

        const hasEemptyDates = value.some((obj) => !obj.date);
        if (hasEemptyDates) {
          return false;
        }

        return true;
      }
    )
    .label(fields.specialDates.label),
    

    selectedGeofenceJobType: Yup.array().of(Yup.object().shape({
      accountMapGeofenceGroupId: Yup.number().nullable(),
      
      jobTemplates: Yup.array().of(Yup.object().shape({
        jobTemplateId: Yup.number().nullable(),
        
        workingDays: Yup.array().of(Yup.object().shape({
          label: Yup.string().label(fields.workTimesLabel.label),
          day: Yup.number().label(fields.workTimesDay.label),
          isEnabled: Yup.boolean().label(fields.workTimesIsEnabled.label),
  
          times: Yup.array().when(['isEnabled', '$payrollTemplateVariableType'], (isEnabled, payrollTemplateVariableType) => {
            if(isEnabled && (step == '3b') && (payrollTemplateVariableType == PayrollTemplateVariableType.WorkTimeGeofenceJobTypeBeta)){
                return Yup.array().of(Yup.object().shape({
                  timeFrom: Yup.string().nullable().label(fields.workTimesTimeFrom.label),
                  timeTo: Yup.string().nullable().label(fields.workTimesTimeTo.label),
                  standardPay: Yup.number().nullable().min(0).label(fields.workTimesPay.label),
                }))
                .label(fields.times.label);
            } else {
                return Yup.array().of(Yup.object().shape({
                  timeFrom: Yup.string().nullable().label(fields.workTimesTimeFrom.label),
                  timeTo: Yup.string().nullable().label(fields.workTimesTimeTo.label),
                  standardPay: Yup.number().nullable().label(fields.workTimesPay.label),
                }))
                .label(fields.times.label);
            }
          }),
        }))
        .label(fields.workTimes.label),
  
        specialDays: Yup.array().of(Yup.object().shape({
          date: Yup.date().nullable().label(fields.specialDatesDate.label),
          standardPay: Yup.number().nullable().label(fields.specialDatesPay.label),
  
          times: Yup.array().when(['$payrollTemplateVariableType'], (payrollTemplateVariableType) => {
            if((step == '3b') && (payrollTemplateVariableType == PayrollTemplateVariableType.WorkTimeGeofenceJobTypeBeta)){
                return Yup.array().of(Yup.object().shape({
                  timeFrom: Yup.string().nullable().label(fields.workTimesTimeFrom.label),
                  timeTo: Yup.string().nullable().label(fields.workTimesTimeTo.label),
                  standardPay: Yup.number().nullable().min(0).label(fields.specialDatesPay.label),
                }))
                .label(fields.times.label);
            } else {
                return Yup.array().of(Yup.object().shape({
                  timeFrom: Yup.string().nullable().label(fields.workTimesTimeFrom.label),
                  timeTo: Yup.string().nullable().label(fields.workTimesTimeTo.label),
                  standardPay: Yup.number().nullable().label(fields.workTimesPay.label),
                }))
                .label(fields.times.label);
            }
          }),
        }))
        .label(fields.specialDates.label),
      }))
    }))
    .test('all-standardPay-filled-for-specific-object', 'All standardPay fields must be filled for the selected object', function (value) {
      if (!Array.isArray(value)) {
        return true;
      }

      if ((step == '3b') && (this.options?.context?.payrollTemplateVariableType == PayrollTemplateVariableType.WorkTimeGeofenceJobTypeBeta)) {
        let errors: any = [];

        value.forEach((mainItem, mainIndex) => {
          let emptyArr: any = [];

          if(mainItem && mainItem.jobTemplates && mainItem.jobTemplates.length > 0){
            mainItem.jobTemplates.forEach((item, index) => {
              const workingDays = item.workingDays || [];
              const specialDays = item.specialDays || [];
          
              const hasStandardPayInTimes = workingDays.some(day => {
                return Array.isArray(day.times) && day.times.some(time => {
                  return Utils.isNumber(time.standardPay)
                });
              });

              const hasStandardPayInDates = specialDays.some(day => {
                return Array.isArray(day.times) && day.times.some(time => {
                  return Utils.isNumber(time.standardPay)
                });
              });
  
              if (hasStandardPayInDates || hasStandardPayInTimes) {
                emptyArr.push(true);
  
                workingDays.forEach((day, dayIndex) => {
                  if (Array.isArray(day.times) && day.isEnabled) {
                    day.times.forEach((time, timeIndex) => {
                      if (!Utils.isNumber(time.standardPay)) {
                        if (!errors[mainIndex]) {
                          errors[mainIndex] = {};
                        }
                        errors[mainIndex]['jobTemplates'] = errors[mainIndex]['jobTemplates'] || [];
                        errors[mainIndex]['jobTemplates'][index] = errors[mainIndex]['jobTemplates'][index] || {};
                        errors[mainIndex]['jobTemplates'][index]['workingDays'] = errors[mainIndex]['jobTemplates'][index]['workingDays'] || [];
                        errors[mainIndex]['jobTemplates'][index]['workingDays'][dayIndex] = errors[mainIndex]['jobTemplates'][index]['workingDays'][dayIndex] || {};
                        errors[mainIndex]['jobTemplates'][index]['workingDays'][dayIndex]['times'] = errors[mainIndex]['jobTemplates'][index]['workingDays'][dayIndex]['times'] || [];
                        errors[mainIndex]['jobTemplates'][index]['workingDays'][dayIndex]['times'][timeIndex] = errors[mainIndex]['jobTemplates'][index]['workingDays'][dayIndex]['times'][timeIndex] || {};
                        errors[mainIndex]['jobTemplates'][index]['workingDays'][dayIndex]['times'][timeIndex]['standardPay'] = 'Standard pay is required';
                        errors[mainIndex]['jobTemplates'][index]['jobTemplateId'] = item.jobTemplateId;
                      }
                    });
                  }
                });

                specialDays.forEach((day, dayIndex) => {
                  if (Array.isArray(day.times)) {
                    day.times.forEach((time, timeIndex) => {
                      if (!Utils.isNumber(time.standardPay)) {
                        if (!errors[mainIndex]) {
                          errors[mainIndex] = {};
                        }
                        errors[mainIndex]['jobTemplates'] = errors[mainIndex]['jobTemplates'] || [];
                        errors[mainIndex]['jobTemplates'][index] = errors[mainIndex]['jobTemplates'][index] || {};
                        errors[mainIndex]['jobTemplates'][index]['specialDays'] = errors[mainIndex]['jobTemplates'][index]['specialDays'] || [];
                        errors[mainIndex]['jobTemplates'][index]['specialDays'][dayIndex] = errors[mainIndex]['jobTemplates'][index]['specialDays'][dayIndex] || {};
                        errors[mainIndex]['jobTemplates'][index]['specialDays'][dayIndex]['times'] = errors[mainIndex]['jobTemplates'][index]['specialDays'][dayIndex]['times'] || [];
                        errors[mainIndex]['jobTemplates'][index]['specialDays'][dayIndex]['times'][timeIndex] = errors[mainIndex]['jobTemplates'][index]['specialDays'][dayIndex]['times'][timeIndex] || {};
                        errors[mainIndex]['jobTemplates'][index]['specialDays'][dayIndex]['times'][timeIndex]['standardPay'] = 'Standard pay is required';
                        errors[mainIndex]['jobTemplates'][index]['jobTemplateId'] = item.jobTemplateId;
                      }
                    });
                  }
                });
              } else {
                emptyArr.push(false);
              }
            })
          }
          
          let hasEmpty = emptyArr.some((x: any) => x === true);
          if(!hasEmpty){
            if (!errors[mainIndex]) {
              errors[mainIndex] = {};
            }
            errors[mainIndex]['jobTemplates'] = 'You must fill in at least 1 job type';
          }
        });

        if(errors.length > 0){
          return this.createError({
            message: () => [errors]
          })
        }
      }
    
      return true;
    }),

    selectedGeofence: Yup.array().of(Yup.object().shape({
      accountMapGeofenceGroupId: Yup.number().nullable(),
      
      workingDays: Yup.array().of(Yup.object().shape({
        label: Yup.string().label(fields.workTimesLabel.label),
        day: Yup.number().label(fields.workTimesDay.label),
        isEnabled: Yup.boolean().label(fields.workTimesIsEnabled.label),

        times: Yup.array().when(['isEnabled', '$payrollTemplateVariableType'], (isEnabled, payrollTemplateVariableType) => {
          if(isEnabled && (step == '3b') && (payrollTemplateVariableType == PayrollTemplateVariableType.WorkTimeGeofenceBeta)){
              return Yup.array().of(Yup.object().shape({
                timeFrom: Yup.string().nullable().label(fields.workTimesTimeFrom.label),
                timeTo: Yup.string().nullable().label(fields.workTimesTimeTo.label),
                standardPay: Yup.number().nullable().min(0).required().label(fields.workTimesPay.label),
              }))
              .label(fields.times.label);
          } else {
              return Yup.array().of(Yup.object().shape({
                timeFrom: Yup.string().nullable().label(fields.workTimesTimeFrom.label),
                timeTo: Yup.string().nullable().label(fields.workTimesTimeTo.label),
                standardPay: Yup.number().nullable().label(fields.workTimesPay.label),
              }))
              .label(fields.times.label);
          }
        }),
      })),

      specialDays: Yup.array().of(Yup.object().shape({
        date: Yup.date().nullable().label(fields.specialDatesDate.label),
        standardPay: Yup.number().nullable().label(fields.specialDatesPay.label),

        times: Yup.array().when(['$payrollTemplateVariableType'], (payrollTemplateVariableType) => {
          if((step == '3b') && (payrollTemplateVariableType == PayrollTemplateVariableType.WorkTimeGeofenceBeta)){
              return Yup.array().of(Yup.object().shape({
                timeFrom: Yup.string().nullable().label(fields.workTimesTimeFrom.label),
                timeTo: Yup.string().nullable().label(fields.workTimesTimeTo.label),
                standardPay: Yup.number().nullable().min(0).required().label(fields.specialDatesPay.label),
              }))
              .label(fields.times.label);
          } else {
              return Yup.array().of(Yup.object().shape({
                timeFrom: Yup.string().nullable().label(fields.workTimesTimeFrom.label),
                timeTo: Yup.string().nullable().label(fields.workTimesTimeTo.label),
                standardPay: Yup.number().nullable().label(fields.workTimesPay.label),
              }))
              .label(fields.times.label);
          }
        }),
      }))
      .label(fields.specialDates.label),
    })),

    selectedJobType: Yup.array().of(Yup.object().shape({
      jobTemplateId: Yup.number().nullable(),
      
      workingDays: Yup.array().of(Yup.object().shape({
        label: Yup.string().label(fields.workTimesLabel.label),
        day: Yup.number().label(fields.workTimesDay.label),
        isEnabled: Yup.boolean().label(fields.workTimesIsEnabled.label),

        times: Yup.array().when(['isEnabled', '$payrollTemplateVariableType'], (isEnabled, payrollTemplateVariableType) => {
          if(isEnabled && (step == '3a') && (payrollTemplateVariableType == PayrollTemplateVariableType.WorkTimeJobTypeBeta)){
              return Yup.array().of(Yup.object().shape({
                timeFrom: Yup.string().nullable().label(fields.workTimesTimeFrom.label),
                timeTo: Yup.string().nullable().label(fields.workTimesTimeTo.label),
                standardPay: Yup.number().nullable().min(0).label(fields.workTimesPay.label),
              }))
              .label(fields.times.label);
          } else {
              return Yup.array().of(Yup.object().shape({
                timeFrom: Yup.string().nullable().label(fields.workTimesTimeFrom.label),
                timeTo: Yup.string().nullable().label(fields.workTimesTimeTo.label),
                standardPay: Yup.number().nullable().label(fields.workTimesPay.label),
              }))
              .label(fields.times.label);
          }
        }),
      }))
      .label(fields.workTimes.label),

      specialDays: Yup.array().of(Yup.object().shape({
        date: Yup.date().nullable().label(fields.specialDatesDate.label),
        standardPay: Yup.number().nullable().label(fields.specialDatesPay.label),

        times: Yup.array().when(['$payrollTemplateVariableType'], (payrollTemplateVariableType) => {
          if((step == '3a') && (payrollTemplateVariableType == PayrollTemplateVariableType.WorkTimeJobTypeBeta)){
              return Yup.array().of(Yup.object().shape({
                timeFrom: Yup.string().nullable().label(fields.workTimesTimeFrom.label),
                timeTo: Yup.string().nullable().label(fields.workTimesTimeTo.label),
                standardPay: Yup.number().nullable().min(0).label(fields.specialDatesPay.label),
              }))
              .label(fields.times.label);
          } else {
              return Yup.array().of(Yup.object().shape({
                timeFrom: Yup.string().nullable().label(fields.workTimesTimeFrom.label),
                timeTo: Yup.string().nullable().label(fields.workTimesTimeTo.label),
                standardPay: Yup.number().nullable().label(fields.workTimesPay.label),
              }))
              .label(fields.times.label);
          }
        }),
      }))
      .label(fields.specialDates.label),
    }))
    .test('all-standardPay-filled-for-specific-object', 'All standardPay fields must be filled for the selected object', function (value) {
      if (!Array.isArray(value)) {
        return true;
      }

      if ((step == '3a') && (this.options?.context?.payrollTemplateVariableType == PayrollTemplateVariableType.WorkTimeJobTypeBeta)) {
        let errors: any = [];
        let emptyArr: any = [];

        value.forEach((item, index) => {
          const workingDays = item.workingDays || [];
          const specialDays = item.specialDays || [];
      
          const hasStandardPayInTimes = workingDays.some(day => {
            return Array.isArray(day.times) && day.times.some(time => {
              return Utils.isNumber(time.standardPay)
            });
          });

          const hasStandardPayInDates = specialDays.some(day => {
            return Array.isArray(day.times) && day.times.some(time => {
              return Utils.isNumber(time.standardPay)
            });
          });

          if (hasStandardPayInDates || hasStandardPayInTimes) {
            emptyArr.push(true);

            workingDays.forEach((day, dayIndex) => {
              if (Array.isArray(day.times) && day.isEnabled) {
                day.times.forEach((time, timeIndex) => {
                  if (!Utils.isNumber(time.standardPay)) {
                    if (!errors[index]) {
                      errors[index] = {};
                    }
                    errors[index]['workingDays'] = errors[index]['workingDays'] || [];
                    errors[index]['workingDays'][dayIndex] = errors[index]['workingDays'][dayIndex] || {};
                    errors[index]['workingDays'][dayIndex]['times'] = errors[index]['workingDays'][dayIndex]['times'] || [];
                    errors[index]['workingDays'][dayIndex]['times'][timeIndex] = errors[index]['workingDays'][dayIndex]['times'][timeIndex] || {};
                    errors[index]['workingDays'][dayIndex]['times'][timeIndex]['standardPay'] = 'Standard pay is required';
                    errors[index]['jobTemplateId'] = item.jobTemplateId;
                  }
                });
              }
            });
      
            specialDays.forEach((day, dayIndex) => {
              if (Array.isArray(day.times)) {
                day.times.forEach((time, timeIndex) => {
                  if (!Utils.isNumber(time.standardPay)) {
                    if (!errors[index]) {
                      errors[index] = {};
                    }
                    errors[index]['specialDays'] = errors[index]['specialDays'] || [];
                    errors[index]['specialDays'][dayIndex] = errors[index]['specialDays'][dayIndex] || {};
                    errors[index]['specialDays'][dayIndex]['times'] = errors[index]['specialDays'][dayIndex]['times'] || [];
                    errors[index]['specialDays'][dayIndex]['times'][timeIndex] = errors[index]['specialDays'][dayIndex]['times'][timeIndex] || {};
                    errors[index]['specialDays'][dayIndex]['times'][timeIndex]['standardPay'] = 'Standard pay is required';
                    errors[index]['jobTemplateId'] = item.jobTemplateId;
                  }
                });
              }
            });
          } else {
            emptyArr.push(false);
          }
        });
      
        let hasEmpty = emptyArr.some((x: any) => x === true);
        if(!hasEmpty){
          errors = 'You must fill in at least 1 job type';
        }

        if(errors.length > 0){
          return this.createError({
            message: () => [errors]
          })
        }
      }
    
      return true;
    }),

    selectedWorkTime: Yup.object().nullable().shape({
      workingDays: Yup.array().of(Yup.object().shape({
        label: Yup.string().label(fields.workTimesLabel.label),
        day: Yup.number().label(fields.workTimesDay.label),
        isEnabled: Yup.boolean().label(fields.workTimesIsEnabled.label),

        times: Yup.array().when(['isEnabled', '$payrollTemplateVariableType'], (isEnabled, payrollTemplateVariableType) => {
          if(isEnabled && (step == '3a') && (payrollTemplateVariableType == PayrollTemplateVariableType.WorkTimeBeta)){
              return Yup.array().of(Yup.object().shape({
                timeFrom: Yup.string().nullable().label(fields.workTimesTimeFrom.label),
                timeTo: Yup.string().nullable().label(fields.workTimesTimeTo.label),
                standardPay: Yup.number().nullable().min(0).required().label(fields.workTimesPay.label),
              }))
              .label(fields.times.label);
          } else {
              return Yup.array().of(Yup.object().shape({
                timeFrom: Yup.string().nullable().label(fields.workTimesTimeFrom.label),
                timeTo: Yup.string().nullable().label(fields.workTimesTimeTo.label),
                standardPay: Yup.number().nullable().label(fields.workTimesPay.label),
              }))
              .label(fields.times.label);
          }
        }),
      })),

      specialDays: Yup.array().of(Yup.object().shape({
        date: Yup.date().nullable().label(fields.specialDatesDate.label),
        standardPay: Yup.number().nullable().label(fields.specialDatesPay.label),

        times: Yup.array().when(['$payrollTemplateVariableType'], (payrollTemplateVariableType) => {
          if((step == '3a') && (payrollTemplateVariableType == PayrollTemplateVariableType.WorkTimeBeta)){
              return Yup.array().of(Yup.object().shape({
                timeFrom: Yup.string().nullable().label(fields.workTimesTimeFrom.label),
                timeTo: Yup.string().nullable().label(fields.workTimesTimeTo.label),
                standardPay: Yup.number().nullable().min(0).required().label(fields.specialDatesPay.label),
              }))
              .label(fields.times.label);
          } else {
              return Yup.array().of(Yup.object().shape({
                timeFrom: Yup.string().nullable().label(fields.workTimesTimeFrom.label),
                timeTo: Yup.string().nullable().label(fields.workTimesTimeTo.label),
                standardPay: Yup.number().nullable().label(fields.workTimesPay.label),
              }))
              .label(fields.times.label);
          }
        }),
      }))
      .label(fields.specialDates.label),
    }),


    basicPay: Yup.number().when(['payrollTemplateIncentiveType'], (payrollTemplateIncentiveType, schema) => {
      if((step == '4') && (payrollTemplateIncentiveType == PayrollTemplateIncentiveType.StandardWage)){
        return Yup.number().nullable().min(0).required().label(fields.basicPay.label)
      } else {
        return Yup.number().nullable().label(fields.basicPay.label)
      }
    }),
    incentivesPackagePay: Yup.number().when(['payrollTemplateIncentiveType'], (payrollTemplateIncentiveType, schema) => {
      if((step == '4') && (payrollTemplateIncentiveType == PayrollTemplateIncentiveType.PackageWage)){
        return Yup.number().nullable().min(0).required().label(fields.incentivesPackagePay.label)
      } else {
        return Yup.number().nullable().label(fields.incentivesPackagePay.label)
      }
    }),
    incentivesPackagePer: Yup.number().when(['payrollTemplateIncentiveType'], (payrollTemplateIncentiveType, schema) => {
      if((step == '4') && (payrollTemplateIncentiveType == PayrollTemplateIncentiveType.PackageWage)){
        return Yup.number().nullable().min(0).required().label(fields.incentivesPackagePer.label)
      } else {
        return Yup.number().nullable().label(fields.incentivesPackagePer.label)
      }
    }),
    graduatedPay: Yup.array().when(['payrollTemplateIncentiveType'], (payrollTemplateIncentiveType) => {
      if((step == '4') && (payrollTemplateIncentiveType == PayrollTemplateIncentiveType.VolumeWage || payrollTemplateIncentiveType == PayrollTemplateIncentiveType.GraduatedWage)){
          return Yup.array().of(Yup.object().shape({
            text: Yup.string().nullable().label(fields.graduatedPayText.label),
            firstJob: Yup.number().nullable().min(1).required()
              .test('is-firstJob-less-than-lastJob', 'First job must be less than last job', function (value) {
                if (!value)
                  return true;

                const { lastJob } = this.parent;
                let lJob = Utils.isNumber(lastJob) ? parseFloat(lastJob) : 0;
                
                return Utils.isNumber(lastJob) ? value < lJob : true;
              })
              .label(fields.graduatedPayFirstJob.label),
            lastJob: Yup.number().nullable().min(1).required()
              .test('is-lastJob-less-than-firstJob', 'Last job must be greater than first job', function (value) {
                if (!value)
                  return true;

                const { firstJob } = this.parent;
                let fJob = Utils.isNumber(firstJob) ? parseFloat(firstJob) : 0;
                
                return Utils.isNumber(firstJob) ? value > fJob : true;
              })
              .label(fields.graduatedPayLastJob.label),
            jobPay: Yup.number().nullable().min(0).required().label(fields.graduatedPayJobPay.label),
            flatPay: Yup.number().nullable().min(0).required().label(fields.graduatedPayFlatPay.label),
          }))
          .test(
            'is-unique-fisrtlastjobs',
            '${path} cant have unique values for first and last jobs',
            function (value) {
              if (!Array.isArray(value) || value.length <= 1)
                return true;
          
              let errorsFirst: any = [];
              let errorsLast: any = [];
              let crossErrors: any = [];

              const uniqueFirst = new Set();
              const uniqueLast = new Set();

              value.forEach((item, index) => {
                if (uniqueFirst.has(item.firstJob)) {
                  errorsFirst.push({ [index]: { firstJob: 'First job can\'t be unique' } });
                } else {
                  uniqueFirst.add(item.firstJob);
                }

                if (uniqueLast.has(item.lastJob)) {
                  errorsLast.push({ [index]: { lastJob: 'Last job can\'t be unique' } });
                } else {
                  uniqueLast.add(item.lastJob);
                }

                for (let i = index + 1; i < value.length; i++) {
                  if (item.firstJob === value[i].lastJob) {
                    crossErrors.push({ [index]: { firstJob: 'First job and last job can\'t have the same value' } });
                    crossErrors.push({ [i]: { lastJob: 'First job and last job can\'t have the same value' } });
                  }
                  if (item.lastJob === value[i].firstJob) {
                    crossErrors.push({ [index]: { lastJob: 'First job and last job from can\'t have the same value' } });
                    crossErrors.push({ [i]: { firstJob: 'First job and last job from can\'t have the same value' } });
                  }
                }
              });

              let errors = [...errorsFirst, ...errorsLast, ...crossErrors];
              
              const mergedResult = errors.reduce((acc, item) => {
                const index = Object.keys(item)[0];
                acc[index] = { ...acc[index], ...item[index] };
                return acc;
              }, {});
              const finalResult = (Object.keys(mergedResult).length != 0) ? [mergedResult] : [];

              if (finalResult.length > 0) {
                return this.createError({
                  message: () => finalResult,
                });
              }
    
              return true;
            }
          )
          .label(fields.graduatedPay.label);
      } else {
          return Yup.array().of(Yup.object().shape({
            text: Yup.string().nullable().label(fields.graduatedPayText.label),
            firstJob: Yup.number().nullable().min(0).label(fields.graduatedPayFirstJob.label),
            lastJob: Yup.number().nullable().min(0).label(fields.graduatedPayLastJob.label),
            jobPay: Yup.number().nullable().min(0).label(fields.graduatedPayJobPay.label),
            flatPay: Yup.number().nullable().min(0).label(fields.graduatedPayFlatPay.label),
          }))
          .label(fields.graduatedPay.label);
      }
    }),
  })
}


export const hasSpecialDate = (specialDates: any) => {
  return (specialDates && specialDates.length > 0 && specialDates[0] && specialDates[0].date && specialDates[0].date != '') ? "true" : null;
};
export const hasSelectedDateTime = (arr: any, id: number): any => {
  let item: any = null;
  if(arr && arr && Array.isArray(arr) && arr.length > 0){
    item = arr.filter((x: any) => x.jobTemplateId).find((x: any) => x.jobTemplateId === id);
  }

  if(item){
    const hasStandardPayInTimes = item.workingDays.some((day: any) => {
      return day.isEnabled && Array.isArray(day.times) && day.times.some((time: any) => {
        return Utils.isNumberAvoidZero(time.standardPay)
      });
    });

    const hasSpecialPayInDates = item.specialDays.some((day: any) => {
      return day.date && Array.isArray(day.times) && day.times.some((time: any) => {
        return Utils.isNumberAvoidZero(time.standardPay)
      });
    });

    if (hasStandardPayInTimes || hasSpecialPayInDates) {
      return true;
    }
  }
  return false;
}
export const getWorkingDays = (workingDaysArr: any): any => {
  let workingDays: Array<any> = [];

  if(workingDaysArr && workingDaysArr.length > 0){
    workingDays = workingDaysArr.map((item: WorkingDayStruct, i: number) => {
      let times: Array<any> = [];
      if(item && item.times && item.times.length > 0){
        if(item.isEnabled){
          times = item.times.map((subItem: WorkTimeStruct, j: number) => {
            let timeFrom = moment.utc(subItem.timeFrom, 'HH:mm').toDate();
            let timeTo = moment.utc(subItem.timeTo, 'HH:mm').toDate();
            
            return {
              standardPay: subItem.standardPay,
              timeFrom: timeFrom,
              timeTo: timeTo,
            }
          });
        }
      }

      return {
        day: item.day,
        isEnabled: item.isEnabled,
        times: times,
      }
    });

    return workingDays;
  }
};
export const getSpecialDays = (specialDaysArr: any, hasSpecialDates: boolean): any => {
  let specialDays: Array<SpecialDateStruct>|null = null;

  if(specialDaysArr && specialDaysArr.length > 0){
    if(hasSpecialDates){
      specialDays = specialDaysArr.map((item: SpecialDateStruct, i: number) => {
        let times: Array<any> = [];
        if(item && item.times && item.times.length > 0){
          times = item.times.map((subItem: WorkTimeStruct, j: number) => {
            let timeFrom = moment.utc(subItem.timeFrom, 'HH:mm').toDate();
            let timeTo = moment.utc(subItem.timeTo, 'HH:mm').toDate();
            
            return {
              standardPay: subItem.standardPay,
              timeFrom: timeFrom,
              timeTo: timeTo,
            }
          });
        }
        
        return {
          date: item.date ? moment.utc(item.date).toDate() : null,
          standardPay: item.standardPay,
          times: times,
        }
      });
    }
  }

  return specialDays;
}
export const getSpecialDaysArr = (specialDaysArr: any): any => {
  let specialDays: Array<SpecialDateStruct> = [];

  if(specialDaysArr && specialDaysArr.length > 0){
    specialDays = specialDaysArr.map((item: SpecialDateStruct, i: number) => {
      let times: Array<any> = [];
      if(item && item.times && item.times.length > 0){
        times = item.times.map((subItem: WorkTimeStruct, j: number) => {
          let timeFrom = moment.utc(subItem.timeFrom, 'HH:mm').toDate();
          let timeTo = moment.utc(subItem.timeTo, 'HH:mm').toDate();
          
          return {
            standardPay: subItem.standardPay,
            timeFrom: timeFrom,
            timeTo: timeTo,
          }
        });
      }
      
      return {
        date: item.date ? moment.utc(item.date).toDate() : null,
        standardPay: item.standardPay,
        times: times,
      }
    });
  }

  return specialDays;
}
export const getDayOfWeekLabel = (day: number) => {
  if(day === DayOfWeek.Sunday){
    return 'SUN';
  } else if(day === DayOfWeek.Monday){
    return 'MON';
  } else if(day === DayOfWeek.Tuesday){
    return 'TUE';
  } else if(day === DayOfWeek.Wednesday){
    return 'WED';
  } else if(day === DayOfWeek.Thursday){
    return 'THU';
  } else if(day === DayOfWeek.Friday){
    return 'FRI';
  } else if(day === DayOfWeek.Saturday){
    return 'SAT';
  }
};
export const getIncentivesLabel = (payrollTemplateIncentiveType: any) => {
  if(payrollTemplateIncentiveType == PayrollTemplateIncentiveType.StandardWage){
    return 'Standard Wage';
  } else if(payrollTemplateIncentiveType == PayrollTemplateIncentiveType.PackageWage){
    return 'Package Wage';
  } else if(payrollTemplateIncentiveType == PayrollTemplateIncentiveType.GraduatedWage){
    return 'Graduated Wage';
  } else if(payrollTemplateIncentiveType == PayrollTemplateIncentiveType.VolumeWage){
    return 'Volume Wage';
  } else {
    return 'None';
  }
};
export const getWorkingDaysTemplate = (form: any, includePay: boolean = false, index: number = 0): any => {
  let workingDays: Array<WorkingDayStruct> = _.cloneDeep(initialValues.workTimes);

  if(form.variables && form.variables.length > 0){
    let item = form.variables[index];
    
    if(item && item.workingDays && item.workingDays.length > 0){
      workingDays = item.workingDays.map((item: any, i: number) => {
        let times: Array<WorkTimeStruct> = [];
        if(item.times && item.times.length > 0){
          times = item.times.map((subItem: any, j: number) => {
            return {
              timeFrom: moment(subItem.timeFrom).format('HH:mm'),
              timeTo: moment(subItem.timeTo).format('HH:mm'),
              standardPay: includePay ? subItem.standardPay : 0
            }
          });
        } else {
          times.push(workTime);
        }

        return {
          label: getDayOfWeekLabel(item.day),
          day: item.day,
          isEnabled: item.isEnabled,
          times: times,
        }
      });
    }
  }

  return workingDays;
}
export const getSpecialDaysTemplate = (form: any, includePay: boolean = false, index: number = 0): any => {
  let specialDays: Array<SpecialDateStruct> = _.cloneDeep(initialValues.specialDates);

  if(form.variables && form.variables.length > 0){
    let item = form.variables[index];
    
    if(item && item.specialDays && item.specialDays.length > 0){
      specialDays = item.specialDays.map((item: any, i: number) => {
        let times: Array<WorkTimeStruct> = [];
        if(item.times && item.times.length > 0){
          times = item.times.map((subItem: any, j: number) => {
            return {
              timeFrom: moment(subItem.timeFrom).format('HH:mm'),
              timeTo: moment(subItem.timeTo).format('HH:mm'),
              standardPay: includePay ? subItem.standardPay : 0
            }
          });
        } else {
          times.push(workTime);
        }

        return {
          date: moment(item.date).toDate(),
          standardPay: includePay ? item.standardPay : 0,
          times: times,
        }
      });
    }
  }

  return specialDays;
}
export const getSelectedJobType = (arr: any): any => {
  if(arr && arr.length > 0){
    for(let i = 0; i < arr.length; i++){
      const hasStandardPayInTimes = arr[i].workingDays.some((day: any) => {
        return Array.isArray(day.times) && day.times.some((time: any) => {
          return Utils.isNumberAvoidZero(time.standardPay)
        });
      });

      const hasStandardPayInDates = arr[i].specialDays.some((day: any) => {
        return Array.isArray(day.times) && day.times.some((time: any) => {
          return Utils.isNumberAvoidZero(time.standardPay)
        });
      });

      if (hasStandardPayInDates || hasStandardPayInTimes) {
        arr[i].isSelected = true;
        break;
      }
    }

    let isSomeSel = arr.some((x: any) => x.isSelected);
    if(!isSomeSel){
      arr[0].isSelected = true;
    }
  }

  return arr;
}
export const copyJobType = (item: any, selectedJobTypeArr: Array<SelectedJobTypeStruct>, checkedJobTypes: Array<number|null>): any => {
  let selectedJobType = _.cloneDeep(selectedJobTypeArr);
  if(selectedJobType && selectedJobType.length > 0){
    selectedJobType.filter(x => checkedJobTypes.includes(x.jobTemplateId)).map((row, i) => {
      row.workingDays = item.workingDays;
      row.specialDays = item.specialDays;
      return row;
    });
  }
  return selectedJobType;
}
export const copyGeofence = (item: any, selectedGeofenceArr: Array<any>, checkedGeofence: Array<number|null>): any => {
  let selectedGeofence = _.cloneDeep(selectedGeofenceArr);
  if(selectedGeofence && selectedGeofence.length > 0){
    selectedGeofence.filter((x: any) => checkedGeofence.includes(x.accountMapGeofenceGroupId)).map((row: any, i: number) => {
      row.workingDays = item.workingDays;
      row.specialDays = item.specialDays;
      return row;
    });
  }
  return selectedGeofence;
}
export const copyGeofenceJobType = (item: any, selectedGeofenceArr: Array<any>, checkedGeofence: Array<number|null>): any => {
  let selectedGeofence = _.cloneDeep(selectedGeofenceArr);
  if(selectedGeofence && selectedGeofence.length > 0){
    selectedGeofence.filter((x: any) => checkedGeofence.includes(x.accountMapGeofenceGroupId)).map((row: any, i: number) => {
      row.jobTemplates = item.jobTemplates;
      return row;
    });
  }
  return selectedGeofence;
}


export interface VariablesStruct {
  workingDays: Array<WorkingDayStruct>,
  specialDays: Array<SpecialDateStruct>|null,
  mapGeofences: Array<mapGeofenceStruct>|null,
  payrollTemplateVariableJobTemplates: Array<jobTemplateStruct>|null,
}
export const prepareForm = (values: any = null, defValues: any = null, callback: (step: string, radio2: boolean, radio3: boolean) => void) => {
  let form = _.cloneDeep(values);
  let data = _.cloneDeep(defValues);
  
  if(data && form){
    data['templateName'] = form.templateName;
    data['templateDescription'] = form.templateDescription;
    data['payrollTemplateVariableType'] = form.payrollTemplateVariableType;
    data['payrollTemplateIncentiveType'] = form.payrollTemplateIncentiveType;
    data['graduatedWageName'] = getIncentivesLabel(form.payrollTemplateIncentiveType);
    data['hasSpecialDates'] = false;
    
    let workTimes: Array<WorkingDayStruct> = getWorkingDaysTemplate(form, false, 0);
    data['workTimes'] = workTimes;

    let specialDates: Array<SpecialDateStruct> = getSpecialDaysTemplate(form, false, 0);
    data['specialDates'] = specialDates;


    if(form.payrollTemplateVariableType == PayrollTemplateVariableType.WorkTimeBeta){
      let selectedWorkTime: SelectedWorkTimeStruct = {
        workingDays: getWorkingDaysTemplate(form, true),
        specialDays: getSpecialDaysTemplate(form, true)
      };
      
      let hasSpecialDates = hasSpecialDate(selectedWorkTime.specialDays);

      data['selectedWorkTime'] = selectedWorkTime;
      data['hasSpecialDates'] = (hasSpecialDates);
      callback('3a', false, false);


    } else if(form.payrollTemplateVariableType == PayrollTemplateVariableType.WorkTimeJobTypeBeta){
      let hasSpecialDates: any = null;
      let selectedJobType: Array<SelectedJobTypeStruct> = [];
      if(form.variables && form.variables.length > 0){
        selectedJobType = form.variables.map((item: any, i: number) => {
          let jobTemplate = (item.payrollTemplateVariableJobTemplates && item.payrollTemplateVariableJobTemplates.length > 0) ? item.payrollTemplateVariableJobTemplates[0].jobTemplate : null;

          let selectedJobTypeItem: SelectedJobTypeStruct = {
            jobTemplateId: jobTemplate ? jobTemplate.jobTemplateId : null,
            jobTemplateName: jobTemplate ? jobTemplate.jobTemplateName : '',
            workingDays: getWorkingDaysTemplate(form, true, i),
            specialDays: getSpecialDaysTemplate(form, true, i),
            isSelected: false
          };
          if(hasSpecialDates == null){
            hasSpecialDates = hasSpecialDate(selectedJobTypeItem.specialDays);
          }
          return selectedJobTypeItem;
        });
      }
      selectedJobType = getSelectedJobType(selectedJobType);
      data['selectedJobType'] = selectedJobType;
      data['hasSpecialDates'] = (hasSpecialDates);
      callback('3a', false, true);


    } else if(form.payrollTemplateVariableType == PayrollTemplateVariableType.WorkTimeGeofenceBeta){
      let hasSpecialDates: any = null;
      let selectedGeofence: Array<SelectedGeofenceStruct> = [];
      if(form.variables && form.variables.length > 0){
        selectedGeofence = form.variables.map((item: any, i: number) => {
          let mapGeofences = (item.mapGeofences && item.mapGeofences.length > 0) ? item.mapGeofences[0].accountMapGeofenceGroup : null;

          let selectedJobTypeItem: SelectedGeofenceStruct = {
            accountMapGeofenceGroupId: mapGeofences ? mapGeofences.accountMapGeofenceGroupId : null,
            groupName: mapGeofences ? mapGeofences.groupName : '',
            workingDays: getWorkingDaysTemplate(form, true, i),
            specialDays: getSpecialDaysTemplate(form, true, i)
          };
          if(hasSpecialDates == null){
            hasSpecialDates = hasSpecialDate(selectedJobTypeItem.specialDays);
          }
          return selectedJobTypeItem;
        });
      }
      data['selectedGeofence'] = selectedGeofence;
      data['hasSpecialDates'] = (hasSpecialDates);
      callback('3b', true, false);


    } else if(form.payrollTemplateVariableType == PayrollTemplateVariableType.WorkTimeGeofenceJobTypeBeta){
      let hasSpecialDates: any = null;
      let selectedGeofenceJobType: Array<SelectedGeofenceJobTypeStruct> = [];
      let selectedGeofence: Array<SelectedGeofenceStruct> = [];

      if(form.variables && form.variables.length > 0){
        const groupArr = _.groupBy(form.variables, obj => obj.mapGeofences[0].accountMapGeofenceGroup.accountMapGeofenceGroupId);
        selectedGeofenceJobType = _.map(groupArr, (data, accountMapGeofenceGroupId) => {
          const groupedByJobTemplate = _.groupBy(data, obj => obj.payrollTemplateVariableJobTemplates[0].jobTemplateId);
          let jobTemplates = _.map(groupedByJobTemplate, (jobGroup, jobTemplateId) => {
            if(hasSpecialDates == null){
              hasSpecialDates = hasSpecialDate(jobGroup[0].specialDays);
            }

            let workingDaysArr = (jobGroup && jobGroup.length > 0 && jobGroup[0] && jobGroup[0].workingDays && jobGroup[0].workingDays.length > 0) ? jobGroup[0].workingDays : [];
            let workingDays: Array<any> = getWorkingDays(workingDaysArr);
            
            let specialDaysArr = (jobGroup && jobGroup.length > 0 && jobGroup[0] && jobGroup[0].specialDays && jobGroup[0].specialDays.length > 0) ? jobGroup[0].specialDays : null;
            let specialDays: Array<any> = getSpecialDaysArr(specialDaysArr);
            
            return {
              jobTemplateId: parseInt(jobTemplateId),
              jobTemplateName: jobGroup[0].payrollTemplateVariableJobTemplates[0].jobTemplate.jobTemplateName,
              workingDays: workingDays,
              specialDays: specialDays,
              isSelected: false,
            }
          });
          
          return {
            accountMapGeofenceGroupId: parseInt(accountMapGeofenceGroupId),
            groupName: data[0].mapGeofences[0].accountMapGeofenceGroup.groupName,
            jobTemplates: getSelectedJobType(jobTemplates) 
          };
        });

        if(selectedGeofenceJobType && selectedGeofenceJobType.length > 0){
          selectedGeofence = selectedGeofenceJobType.map((item: any, i: number) => {
            let selectedJobTypeItem: SelectedGeofenceStruct = {
              accountMapGeofenceGroupId: item ? item.accountMapGeofenceGroupId : null,
              groupName: item ? item.groupName : '',
              workingDays: [],
              specialDays: []
            };
            return selectedJobTypeItem;
          });
        }
      }
      data['selectedGeofence'] = selectedGeofence;
      data['selectedGeofenceJobType'] = selectedGeofenceJobType;
      data['hasSpecialDates'] = (hasSpecialDates);
      callback('3b', true, true);
    }


    if(form.payrollTemplateIncentiveType == PayrollTemplateIncentiveType.StandardWage){
      data['basicPay'] = form.basicPay;

    } else if(form.payrollTemplateIncentiveType == PayrollTemplateIncentiveType.PackageWage){
      if(form.incentives && form.incentives.length > 0){
        data['incentivesPackagePer'] = form.incentives[0].firstJob;
        data['incentivesPackagePay'] = form.incentives[0].jobPay;
      }

    } else if(form.payrollTemplateIncentiveType == PayrollTemplateIncentiveType.VolumeWage || form.payrollTemplateIncentiveType == PayrollTemplateIncentiveType.GraduatedWage){
      let graduatedPay: Array<any> = [];
      if(form.incentives && form.incentives.length > 0){
        graduatedPay = form.incentives.map((item: any, i: number) => {
          return {
            text: '',
            firstJob: item.firstJob,
            lastJob: item.lastJob,
            jobPay: item.jobPay,
            flatPay: item.flatPay,
          }
        });
      }
      data['graduatedPay'] = graduatedPay;
    }
  }

  return data;
};
export const prepareData = (values: any = null) => {
  let data: any = {
    isActive: true,
  };

  if(values){
    data['templateName'] = values.templateName;
    data['templateDescription'] = values.templateDescription;
    data['payrollTemplateVariableType'] = values.payrollTemplateVariableType;
    data['payrollTemplateIncentiveType'] = values.payrollTemplateIncentiveType;
    

    if(values.payrollTemplateVariableType == PayrollTemplateVariableType.WorkTimeBeta){
      let workingDaysArr = (values.selectedWorkTime && values.selectedWorkTime.workingDays && values.selectedWorkTime.workingDays.length > 0) ? values.selectedWorkTime.workingDays : [];
      let workingDays: Array<any> = getWorkingDays(workingDaysArr);
      
      let specialDaysArr = (values.selectedWorkTime && values.selectedWorkTime.specialDays && values.selectedWorkTime.specialDays.length > 0) ? values.selectedWorkTime.specialDays : null;
      let specialDays: Array<SpecialDateStruct>|null = getSpecialDays(specialDaysArr, values.hasSpecialDates);
      
      let variables: Array<VariablesStruct> = [{
        workingDays: workingDays,
        specialDays: specialDays,
        mapGeofences: null,
        payrollTemplateVariableJobTemplates: null,
      }];
      data['variables'] = variables;


    } else if(values.payrollTemplateVariableType == PayrollTemplateVariableType.WorkTimeJobTypeBeta){
      let variables: Array<VariablesStruct> = [];
      if(values.selectedJobType && values.selectedJobType && values.selectedJobType.length > 0){
        values.selectedJobType.forEach((item: SelectedJobTypeStruct, i: number) => {
          let workingDaysArr = (item.workingDays && item.workingDays.length > 0) ? item.workingDays : [];
          let workingDays: Array<any> = getWorkingDays(workingDaysArr);
          
          let specialDaysArr = (item.specialDays && item.specialDays.length > 0) ? item.specialDays : null;
          let specialDays: Array<SpecialDateStruct>|null = getSpecialDays(specialDaysArr, values.hasSpecialDates);

          let variable: VariablesStruct = {
            workingDays: workingDays,
            specialDays: specialDays,
            mapGeofences: null,
            payrollTemplateVariableJobTemplates: [{ jobTemplateId: item.jobTemplateId }],
          };
          variables.push(variable);
        });
      }
      data['variables'] = variables;


    } else if(values.payrollTemplateVariableType == PayrollTemplateVariableType.WorkTimeGeofenceBeta){
      let variables: Array<VariablesStruct> = [];
      if(values.selectedGeofence && values.selectedGeofence && values.selectedGeofence.length > 0){
        values.selectedGeofence.forEach((item: SelectedGeofenceStruct, i: number) => {
          let workingDaysArr = (item.workingDays && item.workingDays.length > 0) ? item.workingDays : [];
          let workingDays: Array<any> = getWorkingDays(workingDaysArr);
          
          let specialDaysArr = (item.specialDays && item.specialDays.length > 0) ? item.specialDays : null;
          let specialDays: Array<SpecialDateStruct>|null = getSpecialDays(specialDaysArr, values.hasSpecialDates);

          let variable: VariablesStruct = {
            workingDays: workingDays,
            specialDays: specialDays,
            mapGeofences: [{ accountMapGeofenceGroupId: item.accountMapGeofenceGroupId }],
            payrollTemplateVariableJobTemplates: null,
          };
          variables.push(variable);
        });
      }
      data['variables'] = variables;


    } else if(values.payrollTemplateVariableType == PayrollTemplateVariableType.WorkTimeGeofenceJobTypeBeta){
      let variables: Array<VariablesStruct> = [];
      if(values.selectedGeofenceJobType && values.selectedGeofenceJobType && values.selectedGeofenceJobType.length > 0){
        values.selectedGeofenceJobType.forEach((item: SelectedGeofenceJobTypeStruct, i: number) => {
          if(item && item.jobTemplates && item.jobTemplates.length > 0){
            item.jobTemplates.forEach((subItem: SelectedJobTypeStruct, j: number) => {
              let workingDaysArr = (subItem.workingDays && subItem.workingDays.length > 0) ? subItem.workingDays : [];
              let workingDays: Array<any> = getWorkingDays(workingDaysArr);
              
              let specialDaysArr = (subItem.specialDays && subItem.specialDays.length > 0) ? subItem.specialDays : null;
              let specialDays: Array<SpecialDateStruct>|null = getSpecialDays(specialDaysArr, values.hasSpecialDates);

              let variable: VariablesStruct = {
                workingDays: workingDays,
                specialDays: specialDays,
                mapGeofences: [{ accountMapGeofenceGroupId: item.accountMapGeofenceGroupId }],
                payrollTemplateVariableJobTemplates: [{ jobTemplateId: subItem.jobTemplateId }],
              };
              variables.push(variable);
            });
          }
        });
      }
      data['variables'] = variables;
    }


    if(values.payrollTemplateIncentiveType == PayrollTemplateIncentiveType.StandardWage){
      data['basicPay'] = values.basicPay;

    } else if(values.payrollTemplateIncentiveType == PayrollTemplateIncentiveType.PackageWage){
      data['incentives'] = [{
        firstJob: values.incentivesPackagePer,
        lastJob: values.incentivesPackagePer,
        jobPay: values.incentivesPackagePay,
      }];

    } else if(values.payrollTemplateIncentiveType == PayrollTemplateIncentiveType.VolumeWage || values.payrollTemplateIncentiveType == PayrollTemplateIncentiveType.GraduatedWage){
      let incentives: Array<any> = [];
      if(values.graduatedPay && values.graduatedPay.length > 0){
        incentives = values.graduatedPay.map((item: any, i: number) => {
          let isLastRow = (i === (values.graduatedPay.length-1)) ? true : false;
          let lastJob = item.lastJob;
          if(isLastRow && (lastJob === null || lastJob === undefined)){
            lastJob = Utils.getMaxPageSize()
          }

          return {
            firstJob: item.firstJob,
            lastJob: lastJob,
            jobPay: item.jobPay,
            flatPay: item.flatPay,
          }
        });
      }
      data['incentives'] = incentives;
    }
  }

  return data;
};


export const graduatedPay: {
  text: string;
  firstJob: number|null;
  lastJob: number|null;
  jobPay: number;
  flatPay: number;
} = {
  text: '',
  firstJob: 1,
  lastJob: Utils.getMaxPageSize(),
  jobPay: 0,
  flatPay: 0,
};

export const workTime: WorkTimeStruct = {
  timeFrom: moment().startOf('day').format('HH:mm'),
  timeTo: moment().endOf('day').format('HH:mm'),
  standardPay: 0
};
export const workingDay: WorkingDayStruct = {
  day: DayOfWeek.Sunday,
  isEnabled: false,
  times: [workTime],
};
export const specialDate: SpecialDateStruct = {
  date: null,
  standardPay: 0,
  times: [workTime],
};
export const mapGeofence: mapGeofenceStruct = {
  accountMapGeofenceGroupId: null,
};
export const jobTemplate: jobTemplateStruct = {
  jobTemplateId: null,
};

export interface WorkTimeStruct {
  timeFrom: string,
  timeTo: string,
  standardPay: number|null
};
export interface WorkingDayStruct {
  label?: string,
  day: number,
  isEnabled: boolean,
  times: Array<WorkTimeStruct>,
}
export interface SpecialDateStruct {
  date: Date|null,
  standardPay: number|null,
  times: Array<WorkTimeStruct>,
};
export interface mapGeofenceStruct {
  accountMapGeofenceGroupId: number|null,
};
export interface jobTemplateStruct {
  jobTemplateId: number|null,
};


export interface initialValuesStruct {
  templateName: string,
  templateDescription: string,
  payrollTemplateVariableType: number,
  payrollTemplateIncentiveType: number,
  hasSpecialDates: boolean,
  
  workTimes: Array<WorkingDayStruct>,
  specialDates: Array<SpecialDateStruct>,
  selectedGeofenceJobType: Array<SelectedGeofenceJobTypeStruct>,
  selectedGeofence: Array<SelectedGeofenceStruct>,
  selectedJobType: Array<SelectedJobTypeStruct>,
  selectedWorkTime: SelectedWorkTimeStruct|null,

  graduatedWageName: string,
  graduatedPay: Array<any>,
  basicPay: number|null,
  incentivesPackagePay: number|null,
  incentivesPackagePer: number|null,
};

export interface SelectedGeofenceJobTypeStruct {
  accountMapGeofenceGroupId: number|null,
  groupName: string,
  jobTemplates: Array<SelectedJobTypeStruct>,
};
export interface SelectedGeofenceStruct {
  accountMapGeofenceGroupId: number|null,
  groupName: string,
  workingDays: Array<WorkingDayStruct>,
  specialDays: Array<SpecialDateStruct>,
};
export interface SelectedJobTypeStruct {
  jobTemplateId: number|null,
  jobTemplateName: string,
  workingDays: Array<WorkingDayStruct>,
  specialDays: Array<SpecialDateStruct>,
  isSelected: boolean,
};
export interface SelectedWorkTimeStruct {
  workingDays: Array<WorkingDayStruct>,
  specialDays: Array<SpecialDateStruct>,
};


export const initialValues: initialValuesStruct = {
  templateName: '',
  templateDescription: '',
  payrollTemplateVariableType: PayrollTemplateVariableType.WorkTimeBeta,
  payrollTemplateIncentiveType: PayrollTemplateIncentiveType.None,
  hasSpecialDates: false,

  workTimes: [
    {
      label: getDayOfWeekLabel(DayOfWeek.Sunday),
      day: DayOfWeek.Sunday,
      isEnabled: false,
      times: [workTime],
    },
    {
      label: getDayOfWeekLabel(DayOfWeek.Monday),
      day: DayOfWeek.Monday,
      isEnabled: false,
      times: [workTime],
    },
    {
      label: getDayOfWeekLabel(DayOfWeek.Tuesday),
      day: DayOfWeek.Tuesday,
      isEnabled: false,
      times: [workTime],
    },
    {
      label: getDayOfWeekLabel(DayOfWeek.Wednesday),
      day: DayOfWeek.Wednesday,
      isEnabled: false,
      times: [workTime],
    },
    {
      label: getDayOfWeekLabel(DayOfWeek.Thursday),
      day: DayOfWeek.Thursday,
      isEnabled: false,
      times: [workTime],
    },
    {
      label: getDayOfWeekLabel(DayOfWeek.Friday),
      day: DayOfWeek.Friday,
      isEnabled: false,
      times: [workTime],
    },
    {
      label: getDayOfWeekLabel(DayOfWeek.Saturday),
      day: DayOfWeek.Saturday,
      isEnabled: false,
      times: [workTime],
    }
  ],
  specialDates: [specialDate],

  selectedGeofenceJobType: [],
  selectedGeofence: [],
  selectedJobType: [],
  selectedWorkTime: null,

  graduatedWageName: 'None',
  graduatedPay: [graduatedPay],
  basicPay: null,
  incentivesPackagePay: null,
  incentivesPackagePer: null,
};


interface InitState {
  isLoading: boolean,
  details: any,

  geofenceGroup: Array<any>,
  jobTypes: Array<any>,
  graduatedWageItems: Array<any>,
}


function NewReducer() {
  const name = 'payrollBetaForm';


  const initialState: InitState = {
    isLoading: false,
    details: initialValues,

    geofenceGroup: [],
    jobTypes: [],

    graduatedWageItems: [
      {
        value: PayrollTemplateIncentiveType.None,
        title: getIncentivesLabel(PayrollTemplateIncentiveType.None)
      },
      {
        value: PayrollTemplateIncentiveType.StandardWage,
        title: getIncentivesLabel(PayrollTemplateIncentiveType.StandardWage)
      },
      {
        value: PayrollTemplateIncentiveType.PackageWage,
        title: getIncentivesLabel(PayrollTemplateIncentiveType.PackageWage)
      },
      {
        value: PayrollTemplateIncentiveType.GraduatedWage,
        title: getIncentivesLabel(PayrollTemplateIncentiveType.GraduatedWage)
      },
      {
        value: PayrollTemplateIncentiveType.VolumeWage,
        title: getIncentivesLabel(PayrollTemplateIncentiveType.VolumeWage)
      }
    ],
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },

    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setValues: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
    },


    startDetails: (state: InitState) => {
      state.isLoading = true;
    },
    finishDetails: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload
      state.isLoading = false;
    },
    
    
    startGeofenceGroup: (state: InitState) => {
      state.isLoading = true;
    },
    finishGeofenceGroup: (state: InitState, action: PayloadAction<any>) => {
      state.geofenceGroup = action.payload
      state.isLoading = false;
    },
    
    
    startJobTypes: (state: InitState) => {
      state.isLoading = true;
    },
    finishJobTypes: (state: InitState, action: PayloadAction<any>) => {
      state.jobTypes = action.payload
      state.isLoading = false;
    },
    
    
    startCreate: (state: InitState) => {
      state.isLoading = true;
    },
    finishCreate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },
    
    
    startUpdate: (state: InitState) => {
      state.isLoading = true;
    },
    finishUpdate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },
  };


  const apis = {
    callDetailsApi: (id: number, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDetails());

      await dispatchCrudApi.readApi(null, 'payrolltemplate/beta/' + id).then(result => {
        let data = result.data;
        
        callback(true, data);
        dispatch(actions.finishDetails(data));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false, null);
        dispatch(actions.finishDetails(null));
      });
    },

    callGeofenceGroupApi: () => async (dispatch: any) => {
      dispatch(actions.startGeofenceGroup());

      let params: any = {
        currentPage: 1,
        pageSize: Utils.getMaxPageSize(),
        sortColumn: 'accountMapGeofenceGroupId',
        sortDir: 'asc',
        isIncludeInactive: false,
      };

      await dispatchCrudApi.readApi(params, 'map-geofence/group').then(result => {
        let data = result.data.data;
        
        dispatch(actions.finishGeofenceGroup(data));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        dispatch(actions.finishGeofenceGroup(null));
      });
    },
    
    callJobTypesApi: () => async (dispatch: any) => {
      dispatch(actions.startJobTypes());

      let params: any = {
        currentPage: 1,
        pageSize: Utils.getMaxPageSize(),
        sortColumn: 'common',
        sortDir: 'desc',
        isIncludeInactive: false,
      };

      await dispatchCrudApi.readApi(params, 'jobtemplate').then(result => {
        let data = result.data.data;
        
        dispatch(actions.finishJobTypes(data));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        dispatch(actions.finishJobTypes(null));
      });
    },
    
    callCreateApi: (params: any, callback: (state: boolean) => void) => async (dispatch: any) => {
      dispatch(actions.startCreate());

      await dispatchCrudApi.createApi(params, 'payrolltemplate/beta').then(result => {
        let data = result.data.data;
        
        callback(true);
        dispatch(actions.finishCreate(data));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false);
        dispatch(actions.finishCreate(null));
      });
    },
    
    callUpdateApi: (params: any, callback: (state: boolean) => void) => async (dispatch: any) => {
      dispatch(actions.startUpdate());

      await dispatchCrudApi.updateApi(params, 'payrolltemplate/beta').then(result => {
        let data = result.data.data;
        
        callback(true);
        dispatch(actions.finishUpdate(data));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }

        callback(false);
        dispatch(actions.finishUpdate(null));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();