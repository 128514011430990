import React from 'react';

import { isMobile, isTablet } from "react-device-detect";

import Utils from '../../../utils/utils';

import { 
    TextField, 
    IconButton,
    InputAdornment,
    Icon,
} from '@material-ui/core';

import { ReactComponent as SearchIcon } from '../../../../_metronic/assets/img/icons/manage-search.svg';


const IconButtonSearch = ({isOpen, onClick, additionalStyles,smallExpand, ...rest}) => {

    let txtRef = React.useRef();

    let [value, setValue] = React.useState((rest.value && rest.value !== '') ? rest.value : '');


    React.useEffect(() => {
        setValue((rest.value && rest.value !== '') ? rest.value : '');
    }, [rest.value]);


    const baseStyles = {
        open: {
            width: (isMobile || isTablet) ? 200 : 300,
        },
        openSmall: {
            width: 200,
        },
        closed: {
            width: (isMobile || isTablet) ? 200 : 0,
            overflow: 'none',
        },
        frame: {
            border: 'solid 1px black',
            borderRadius: 5
        }
    };
   
    let textStyle = smallExpand ? ( isOpen ? baseStyles.openSmall : baseStyles.closed) :  isOpen ? baseStyles.open : baseStyles.closed;
    textStyle = Object.assign(textStyle, additionalStyles ? additionalStyles.text : {});
    
    
    return <>
        {!(isMobile || isTablet) && <IconButton 
            className={rest.btnClassName} 
            style={rest.style ? rest.style : { top: '4px' }}
            onClick={(e) => {
                onClick();

                if(isOpen){
                    txtRef.current.blur();
                } else {
                    txtRef.current.focus();
                }
            }}
        >
            <SearchIcon />
        </IconButton>}
        <TextField 
            inputRef={txtRef}
            name='search' 
            placeholder='Search ...' 
            value={value}
            disabled={rest.disabled}
            style={textStyle}
            InputProps={{
                endAdornment: (!rest.disabled && isOpen && value !== '') ? <InputAdornment position={'end'}>
                    <IconButton
                        style={{
                            padding: '3px',
                        }}
                        onClick={() => {
                            if(rest.onClear){
                                setValue('');
                                rest.onClear();
                            }
                        }}
                    >
                        <Icon component={'i'}>close</Icon>
                    </IconButton>
                </InputAdornment> : null,
            }}
            onChange={(e) => {
                Utils.escapeChars(e);
                let val = Utils.escapeCharsInString(e.target.value);

                setValue(val);
            }}
            onKeyPress={(e) => {
                Utils.escapeChars(e);

                if (e.key === 'Enter') {
                    if(rest.onPressEnter){
                        rest.onPressEnter(e.target.value);
                    }
                }
            }}
        />
    </>
};
export default IconButtonSearch;