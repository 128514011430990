import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { 
  Grid,
  Button,
  Drawer,
  Box,
  List,
} from '@material-ui/core';

import MenuListItems from './MenuListItems';

import ManageLabel from '../../../../components/OLD/input/ManageLabel.jsx';


class RightMenu extends Component {

  constructor(props){
    super(props);

    let isDrawerOpen = ((this.props.isDrawerOpen === true || this.props.isDrawerOpen === false) ? this.props.isDrawerOpen : false);

    this.state = {
      isDrawerOpen: isDrawerOpen
    };
  }


    componentWillReceiveProps(nextProps) {
        if (nextProps.isDrawerOpen !== this.state.isDrawerOpen) {
            this.setState({
                isDrawerOpen: nextProps.isDrawerOpen
            });
        }
    }


  toggleDrawer = (state) => {
    this.setState({
      isDrawerOpen: state
    });

    if(this.props.onChange)
        this.props.onChange(state);
  }


  render() {
    return <Drawer anchor={'right'} className="side-right-menu"
    open={this.state.isDrawerOpen} onClose={() => {
      this.toggleDrawer(false);
    }}>
      <List
        className="side-right-menu-list"
        component="nav"
        subheader={
          <Grid container alignItems={'center'}>
            <Box clone pt={1} pb={2}>
              <Grid item xs={6} >
                <Box style={{ minWidth: '250px' }}>
                  <ManageLabel
                      className={'no-margin'}
                      label={"REPORTS"}
                      size={'md'}
                  />
                </Box>
              </Grid>
            </Box>
            <Box clone pt={1} pb={2}>
              <Grid item xs={6}>
                <Box textAlign="right">
                  <Button aria-label="menu" 
                    style={{ padding: '0px', minWidth: '0px' }}
                    onClick={() => {
                      this.toggleDrawer(false);
                    }}
                  >
                    <i className="fa fa-times"></i>
                  </Button>
                </Box>
              </Grid>
            </Box>
          </Grid>
        }
      >
          <MenuListItems auth={this.props.auth} />
      </List>
    </Drawer>
  }
}

export default withRouter(RightMenu);
