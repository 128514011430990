import React from 'react';
import { Formik } from 'formik';

import { 
    Modal,
    Button,
    Spinner,
    CloseButton,
    Row,
    Col,
    Form,
    InputGroup,
} from "react-bootstrap-v5";

import Utils from '../../../../utils/utils';
import { BillingTypes } from '../../../../utils/enums';

import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../../../../setup/redux/RootReducer';
import { InitState } from '../../redux/invoiceThisJob';
import invoiceNumberForm from '../../redux/invoiceNumberForm';


interface Props {
    slice: (state: RootState) => InitState,
    onHide: () => void,
    onPush: (jobIds: any, invoiceNumber: string, args: any) => void,
}


const InvoiceThisJobPopup = (props: Props) => {
    const dispatch = useDispatch();
    const state: InitState = useSelector(props.slice);
    const { resetInvoiceNumber } = useSelector((state: RootState) => state.invoiceNumberForm);


    const header = () => {
        return <Row className={'align-items-center'}>
            <Col xs={true}>
                <h5>Enter the new invoice number</h5>
            </Col>
            <Col xs={'auto'}>
                <CloseButton
                    className={'rounded-2'}
                    disabled={state.isLoading || resetInvoiceNumber.isLoading}
                    onClick={() => {
                        props.onHide();
                    }}
                />
            </Col>
        </Row>
    }
    const body = (formOptions: any) => {
        return <Row>
            <Col xs={12} className={'pt-5 pb-10'}>

                <Form.Group>
                    <Form.Control as={'div'} className={'mb-0'} plaintext readOnly>
                        {(formOptions.values.billingType == BillingTypes.Purchase) ? state.formLabels.invoiceNumber.labelPurchase : (formOptions.values.billingType == BillingTypes.Expense) ? state.formLabels.invoiceNumber.labelExpense : state.formLabels.invoiceNumber.label}
                        {state.formLabels.invoiceNumber.required && <span className={'text-danger ps-1'}>*</span>}

                        {
                            (resetInvoiceNumber.index == 1)
                            ?
                            <span className={'float-end'}><Spinner animation="border" size={'sm'} /></span>
                            :
                            (!state.isLoading && !resetInvoiceNumber.isLoading) && <a href={'/'}
                                className={'link-primary-underline float-end'}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    if(!state.isLoading && !resetInvoiceNumber.isLoading){
                                        let cb: any = (prefix: any, number: any) => {
                                            if(formOptions.values.billingType == BillingTypes.Purchase){
                                                formOptions.setFieldValue('invoicePrefix', prefix);
                                            } else if(formOptions.values.billingType == BillingTypes.Expense){
                                                formOptions.setFieldValue('expensePrefix', prefix);
                                            } else {
                                                formOptions.setFieldValue('invoicePrefix', prefix);
                                            }
                                            formOptions.setFieldValue('invoiceNumber', number);
                                        };

                                        let data: any = {
                                            index: 1,
                                            billingType: formOptions.values.billingType,
                                            accountBillingProfileId: formOptions.values.accountBillingProfileId,
                                            invoiceDate: formOptions.values.invoiceDate,
                                            callback: cb,
                                        };

                                        dispatch(invoiceNumberForm.callResetInvoiceNumberApi(data));
                                    }
                                }}
                            >Reset</a>
                        }
                    </Form.Control>
                    <InputGroup>
                        <InputGroup.Text>{formOptions.values.invoicePrefix}-</InputGroup.Text>
                        <Form.Control
                            {...state.formLabels.invoiceNumber.field}
                            isInvalid={!!(formOptions.errors && formOptions.errors.invoiceNumber)}

                            placeholder={(formOptions.values.billingType == BillingTypes.Purchase) ? state.formLabels.invoiceNumber.labelPurchase : (formOptions.values.billingType == BillingTypes.Expense) ? state.formLabels.invoiceNumber.labelExpense : state.formLabels.invoiceNumber.label}
                            disabled={resetInvoiceNumber.isLoading || state.isLoading}
                            value={formOptions.values.invoiceNumber}
                            onChange={(e) => {
                                const { name, value } = e.target;
                                formOptions.setFieldValue(name, value);
                            }}
                        />
                        <Form.Control.Feedback type="invalid">{(formOptions.errors && formOptions.errors.invoiceNumber) && formOptions.errors.invoiceNumber}</Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>

            </Col>
        </Row>
    }
    const footer = () => {
        return <Row className={'gx-2 align-items-center'}>
            <Col xs={'auto'}>
                <Button 
                    type={'submit'}
                    size={'sm'}
                    disabled={state.isLoading || resetInvoiceNumber.isLoading}
                >
                    Push
                    {state.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
            <Col xs={'auto'}>
                <Button 
                    type={'button'}
                    variant={'secondary'}
                    disabled={state.isLoading || resetInvoiceNumber.isLoading}
                    size={'sm'}
                    onClick={() => {
                        props.onHide();
                    }}
                >
                    Cancel
                    {state.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
        </Row>
    }


    return <Modal
        show={state.show}
        onHide={() => {
            props.onHide();
        }}
        backdrop={"static"}
        keyboard={false}
        enforceFocus={false}
        size={'lg'}
    >
        <Modal.Body>
            <Formik
                validationSchema={state.validationSchema}
                initialValues={state.formValues}
                enableReinitialize={true}
                validateOnMount={false}
                validateOnChange={true}
                onSubmit={(form) => {
                    let invoiceNumber: string = form.invoicePrefix + '-' + form.invoiceNumber;

                    props.onPush(state.jobIds, invoiceNumber, form.args);
                }}
            >
                {(formOptions) => {
                    return <Form className={'w-100'} onSubmit={formOptions.handleSubmit} onKeyDown={(e: any) => {
                        // Utils.disableEnterKey(e);
                    }}>
                        {header()}
                        {body(formOptions)}
                        {footer()}
                    </Form>
                }}
            </Formik>
        </Modal.Body>
    </Modal>
}


export default InvoiceThisJobPopup;
