import React from 'react';

const ProgressOverlay = props => {
    return <div className={'custom-loading-overlay ' + (props.active ? 'active' : '')}>
        <div className='content'>
            {props.children && <div className={'text'}>{props.children}</div>}
        </div>
    </div>
}


export default ProgressOverlay;