import React from "react";
import Switch from "react-switch";
import moment from "moment";

import { 
    Row,
    Col,
    Form,
    Button,
} from "react-bootstrap-v5";

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import Popup from '../../../../components/popup/Popup';
import CustomTooltip from '../../../../components/Tooltip/Tooltip';
import RSuiteDatePicker from '../../../../components/OLD/Datepicker/RSuiteDatePicker';
import RSuiteTimePicker from '../../../../components/OLD/TimePicker/RSuiteTimePicker';

import Utils from "../../../../utils/utils";

import { connect } from 'react-redux'
import { clear } from "../../../../../setup/redux/actions";
import { dispatchApiCallPost, dispatchApiCallGet } from '../../../../../setup/redux/dispatch/actions'


class CollectBinSection extends React.Component {
  
    constructor(props) {
        super(props);
        
        this.name = 'job_form_collect_bin_section';

        let dataParams = (props.dataParams) ? props.dataParams : null;
        let id = (dataParams && dataParams.id) ? dataParams.id : null;
        let row = (dataParams && dataParams.row) ? dataParams.row : null;
        

        this.state = {
            id: id,
            row: row,

            isLoadingCheckState: false,
        };
    }
    

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            this.name + '-job_check_allow_pull_job', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoadingCheckState: false,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();
                
                    if(callback){
                        callback(data);
                    }
                });
            }
        );
    }

    callCheckAllowPullJobApi = (callback = null) => {
        this.setState({
            isLoadingCheckState: true,
        });

        this.props.dispatchApiCallGet(null, this.name + '-job_check_allow_pull_job', 'job/check-allow-pull-job', null, callback, () => {});
    }
    /* END API */


    body = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;
        
        let isCompleted = Utils.isCompleted(values.currentStatusName);

        return <Row className={'pt-10'}>
            {/* <Col xs={12} className={'mt-10 mb-10'}><hr /></Col> */}

            <Col xs={12}>
                <Row className={'align-items-center mb-7'}>
                    <Col xs={'auto'}>
                        <h3 className={'mb-0'}>Collect Bin</h3>
                    </Col>
                    <Col xs={'auto'}>
                        <CustomTooltip placement={'right'} title={this.props.fields.followUpDays.info}>
                            <InfoOutlinedIcon style={{ color: '#DFDFDF', width: '30px', height: '25px' }} />
                        </CustomTooltip>
                    </Col>
                    <Col xs={12} sm={'auto'} className={'pt-1'}>
                        <Switch 
                            checked={values.haveFollowUp} 
                            disabled={this.state.isLoadingCheckState || isCompleted}
                            onChange={async (checked ) => {
                                await setFieldValue('haveFollowUp', checked);
                                await setFieldValue('followUpDate', null);
                                await setFieldValue('followUpTimeSpecific', null);
                                await setFieldValue('followUpAssignToSameDriverVehicle', false);


                                if(!checked){
                                    await setFieldValue('followUpDays', 0);
                                } else {
                                    this.callCheckAllowPullJobApi(async (data) => {
                                        if(data){
                                            await setFieldValue('haveFollowUp', checked);

                                            if(checked){
                                                let newDate = moment().add(7, 'd').toDate();
                                                let days = Utils.getDaysbetween(newDate, moment().toDate());

                                                await setFieldValue('followUpDays', days);
                                                await setFieldValue('followUpDate', newDate);
                                            } else {
                                                await setFieldValue('followUpDays', 0);
                                            }
                                        } else {
                                            await setFieldValue('haveFollowUp', false);
                                            await setFieldValue('followUpDays', 0);
                                        }
                                            
                                        if(this.props.onCollectBinCheck){
                                            this.props.onCollectBinCheck(data);
                                        }
                                    });
                                }
                            }}
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor="#185cff"
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                        />
                    </Col>
                </Row>
            </Col>

            {(values.haveFollowUp && !this.state.isLoadingCheckState) && <Col xs={12}>
                <Row className={'align-items-center'}>
                    <Col xs={12} md={'auto'}>
                        <span className={'pe-2'}>{this.props.fields.followUpDays.label}</span>
                    </Col>
                    <Col xs={12} md={1}>
                        <Form.Control
                            type="number"
                            id={this.props.fields.followUpDays.id}
                            placeholder={this.props.fields.followUpDays.placeholder}
                            value={values.followUpDays}
                            readOnly={values.jobDate == null || isCompleted}
                            onChange={async (e) => {
                                let value = e.target.value;
                                let pattern = Utils.onlyPositiveNumbers();

                                if(value.match(pattern) != null){
                                    await setFieldValue('followUpDays', e.target.value);

                                    try {
                                        let newDate = Utils.addDate(e.target.value, values.jobDate);
                                        await setFieldValue('followUpDate', newDate.toDate());
                                    }catch(e){}
                                }
                            }}
                            min={0}
                            isInvalid={!!errors.followUpDays}
                        />
                    </Col>
                    <Col xs={12} md={'auto'}>
                        <span className={'pe-2'}>{this.props.fields.followUpDays.label2}</span>
                    </Col>

                    <Col xs={12} md={'auto'}>
                        <span className={'pe-2'}>{this.props.fields.followUpDate.label}</span>
                    </Col>
                    <Col xs={12} md={2}>
                        <Form.Control
                            as = {RSuiteDatePicker}
                            isInvalid={!!errors.followUpDate}
                            
                            placeholder={this.props.fields.followUpDate.placeholder}
                            id={this.props.fields.followUpDate.id}
                            value={values.followUpDate}
                            icon={<></>}
                            placement={'bottom'}
                            cleanable={true}
                            isDefault={true}
                            readOnly={values.jobDate == null || isCompleted}
                            disabledDate={(date) => {
                                return (moment(date).isAfter(moment(values.jobDate).subtract(1, 'days'))) ? false : true;
                            }}
                            onChange={async (value) => {
                                if(value == null){
                                    await setFieldValue('followUpDate', value);
                                    await setFieldValue('followUpTimeSpecific', null);

                                    let days = Utils.getDaysbetween(value, values.jobDate);
                                    await setFieldValue('followUpDays', days);
                                } else {
                                    if(values.followUpTimeSpecific !== null){
                                        let dateTime = Utils.convertDateTimeByDate(value, values.followUpTimeSpecific);
                                        await setFieldValue('followUpDate', moment(dateTime).toDate());
                                        
                                        let days = Utils.getDaysbetween(dateTime, values.jobDate);
                                        await setFieldValue('followUpDays', days);
                                    } else {
                                        await setFieldValue('followUpDate', value);

                                        let days = Utils.getDaysbetween(value, values.jobDate);
                                        await setFieldValue('followUpDays', days);
                                    }
                                }
                            }}
                        />
                    </Col>

                    <Col xs={12} md={'auto'}>
                        <span className={'pe-2'}>{this.props.fields.followUpTimeSpecific.label}</span>
                    </Col>
                    <Col xs={12} md={2}>
                        <Form.Control
                            as = {RSuiteTimePicker}
                            isInvalid={!!errors.followUpTimeSpecific}

                            id={this.props.fields.followUpTimeSpecific.id}
                            value={values.followUpTimeSpecific}
                            hideMinutes={minute => minute % 5 !== 0}
                            readOnly={values.jobDate == null || isCompleted || values.followUpDate == null}
                            onChange={async (value) => {
                                if(value == null){
                                    await setFieldValue('followUpTimeSpecific', value);
                                } else {
                                    if(values.followUpDate !== null){
                                        let dateTime = Utils.convertDateTimeByDate(values.followUpDate, value);
                                        await setFieldValue('followUpTimeSpecific', moment(dateTime).toDate());
                                    } else {
                                        await setFieldValue('followUpTimeSpecific', value);
                                    }
                                }
                            }}
                        />
                    </Col>

                    <Col xs={12} md={'auto'}>
                        <Form.Check
                            id={this.props.fields.followUpAssignToSameDriverVehicle.id}
                            label={this.props.fields.followUpAssignToSameDriverVehicle.label}
                            checked={values.followUpAssignToSameDriverVehicle}
                            onChange={handleChange}
                            isInvalid={!!errors.followUpAssignToSameDriverVehicle}
                            feedback={errors.followUpAssignToSameDriverVehicle}
                            disabled={isCompleted}
                        />
                    </Col>

                    <Col xs={12}>
                        <Form.Group>
                            <Form.Control
                                type={'hidden'}
                                isInvalid={!!errors.jobDate}
                            />
                            <Form.Control.Feedback type="invalid">{errors.jobDate}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
            </Col>}
            
        </Row>
    }

    
    render() {
        return this.body()
    }
}

const mapDispatchToProps = {
    dispatchApiCallPost,
    dispatchApiCallGet,
    clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CollectBinSection);
