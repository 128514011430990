import React, { Component } from 'react';

import { JobStepType } from '../../../../utils/enums';

import { 
    Box,
    Grid,
    Divider,
} from '@material-ui/core';

import Card from '../../../../components/OLD/Card/Card.jsx';
import ManageLabel from '../../../../components/OLD/input/ManageLabel.jsx';
import CustomToggle from '../../../../components/OLD/Checkbox/CustomToggle.jsx';


class AppPermissionSection extends Component {
    
    constructor(props){
        super(props);

        let id = (props.id && props.id > 0) ? props.id : 0;
        let isCopy = ((props.isCopy === true || props.isCopy === false) ? props.isCopy : false);
        let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
        let disabled = ((props.disabled === true || props.disabled === false) ? props.disabled : false);
        let jobType = (props.jobType && props.jobType) ? props.jobType : 0;

        
        let isEditableBinTypeOut = false;
        let isEditableWasteTypeOut = false;
        let isEditableWasteTypeIn = false;
        let isEditableBinNumberIn= false;
        let isEditableBinNumberOut= false;

        
        this.state = {
            id: id,
            isCopy: isCopy,
            isLoading: isLoading,
            disabled: disabled,
            jobType: jobType,

            isEditableBinTypeOut: isEditableBinTypeOut,
            isEditableWasteTypeOut: isEditableWasteTypeOut,
            isEditableWasteTypeIn: isEditableWasteTypeIn,
            isEditableBinNumberIn: isEditableBinNumberIn,
            isEditableBinNumberOut: isEditableBinNumberOut,

        }
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.isCopy !== this.state.isCopy) {
            let isCopy = ((nextProps.isCopy === true || nextProps.isCopy === false) ? nextProps.isCopy : false);
            this.setState({
                isCopy: isCopy
            });
        }
        if (nextProps.isLoading !== this.state.isLoading) {
            let isLoading = ((nextProps.isLoading === true || nextProps.isLoading === false) ? nextProps.isLoading : false);
            this.setState({
                isLoading: isLoading
            });
        }
        if (nextProps.disabled !== this.state.disabled) {
            let disabled = ((nextProps.disabled === true || nextProps.disabled === false) ? nextProps.disabled : false);
            this.setState({
                disabled: disabled
            });
        }
        if (nextProps.jobType !== this.state.jobType) {
            let jobType = (nextProps.jobType && nextProps.jobType) ? nextProps.jobType : 0;
            this.setState({
                jobType: jobType
            });
        }
    }


    /* GETTER & SETTER */
    getIsEditableBinTypeOut = () => {
        return this.state.isEditableBinTypeOut
    }
    setIsEditableBinTypeOut = (isEditableBinTypeOut = false) => {
        this.setState({
            isEditableBinTypeOut: isEditableBinTypeOut,
        });
    }
    
    getIsEditableWasteTypeOut = () => {
        return this.state.isEditableWasteTypeOut
    }
    setIsEditableWasteTypeOut = (isEditableWasteTypeOut = false) => {
        this.setState({
            isEditableWasteTypeOut: isEditableWasteTypeOut,
        });
    }

    getIsEditableWasteTypeIn = () => {
        return this.state.isEditableWasteTypeIn
    }
    setIsEditableWasteTypeIn = (isEditableWasteTypeIn = false) => {
        this.setState({
            isEditableWasteTypeIn: isEditableWasteTypeIn,
        });
    }

    // new get set
    getIsEditableBinNumberOut = () => {
        return this.state.isEditableBinNumberOut
    }
    setIsEditableBinNumberOut = (isEditableBinNumberOut = false) => {
        this.setState({
            isEditableBinNumberOut: isEditableBinNumberOut,
        });
    }

    getIsEditableBinNumberIn = () => {
        if((this.state.jobType !== JobStepType.Shift) && (this.state.jobType !== JobStepType.OnTheSpot)){
            return this.state.isEditableBinNumberIn
        } else {
            return false
        }
    }
    setIsEditableBinNumberIn = (isEditableBinNumberIn = false) => {
        this.setState({
            isEditableBinNumberIn: isEditableBinNumberIn,
        });
    }
    /* END GETTER & SETTER */


    setAppPermissionForm = () => {
        return <Card>
            <Box pl={{ xs: 0, md: 5 }}>

                <Grid container>
                    <Box clone pt={3}>
                        <Grid item xs={12}>
                            
                            {(this.state.jobType !== JobStepType.Pull) && <Box clone>
                                <Grid container>
                                    <Box clone pb={2}>
                                        <Grid item xs={12}>
                                            <ManageLabel
                                                className={'custom-options-subtitle'}
                                                label={"BIN OUT"}
                                            />
                                            <Divider />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12}>
                                            
                                            <Box clone pb={2}>
                                                <Grid container alignItems={'center'}>
                                                    <Box clone pb={{ xs: 1, md: 0 }}>
                                                        <Grid item xs={12} md={true}>
                                                            The driver can change bin type on driver app
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={true} md={'auto'}>
                                                            <CustomToggle
                                                                checked={this.state.isEditableBinTypeOut}
                                                                disabled={false}
                                                                onChange={(checked) => {
                                                                    this.setState({
                                                                        isEditableBinTypeOut: checked
                                                                    });
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box clone textAlign={'center'}>
                                                        <Grid item xs={'auto'} md={1}>&nbsp;</Grid>
                                                    </Box>
                                                </Grid>
                                            </Box>

                                            <Box clone pb={2}>
                                                <Grid container alignItems={'center'}>
                                                    <Box clone pb={{ xs: 1, md: 0 }}>
                                                        <Grid item xs={12} md={true}>
                                                            The driver can change waste type on driver app
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={true} md={'auto'}>
                                                            <CustomToggle
                                                                checked={this.state.isEditableWasteTypeOut}
                                                                disabled={false}
                                                                onChange={(checked) => {
                                                                    this.setState({
                                                                        isEditableWasteTypeOut: checked
                                                                    });
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box clone textAlign={'center'}>
                                                        <Grid item xs={'auto'} md={1}>&nbsp;</Grid>
                                                    </Box>
                                                </Grid>
                                            </Box>

                                            <Box clone pb={2}>
                                                <Grid container alignItems={'center'}>
                                                    <Box clone pb={{ xs: 1, md: 0 }}>
                                                        <Grid item xs={12} md={true}>
                                                        The driver can add new Bin ID driver app
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={true} md={'auto'}>
                                                            <CustomToggle
                                                                checked={this.state.isEditableBinNumberOut}
                                                                disabled={false}
                                                                onChange={(checked) => {
                                                                    this.setState({
                                                                        isEditableBinNumberOut: checked
                                                                    });
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box clone textAlign={'center'}>
                                                        <Grid item xs={'auto'} md={1}>&nbsp;</Grid>
                                                    </Box>
                                                </Grid>
                                            </Box>

                                        </Grid>
                                    </Box>
                                </Grid>
                            </Box>}

                            {((this.state.jobType !== JobStepType.Put) && (this.state.jobType !== JobStepType.Out)) && <Box clone>
                                <Grid container>
                                    <Box clone pt={5} pb={2}>
                                        <Grid item xs={12}>
                                            <ManageLabel
                                                className={'custom-options-subtitle'}
                                                label={"BIN IN"}
                                            />
                                            <Divider />
                                        </Grid>
                                    </Box>
                                    <Box clone>
                                        <Grid item xs={12}>
                                            
                                            <Box clone pb={2}>
                                                <Grid container alignItems={'center'}>
                                                    <Box clone pb={{ xs: 1, md: 0 }}>
                                                        <Grid item xs={12} md={true}>
                                                            The driver can change waste type on driver app
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={true} md={'auto'}>
                                                            <CustomToggle
                                                                checked={this.state.isEditableWasteTypeIn}
                                                                disabled={false}
                                                                onChange={(checked) => {
                                                                    this.setState({
                                                                        isEditableWasteTypeIn: checked,
                                                                    }, () => {
                                                                        if(this.props.onChangIsEditableWasteTypeIn){
                                                                            this.props.onChangIsEditableWasteTypeIn(this.state.isEditableWasteTypeIn);
                                                                        }
                                                                    });
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box clone textAlign={'center'}>
                                                        <Grid item xs={'auto'} md={1}>&nbsp;</Grid>
                                                    </Box>
                                                </Grid>
                                            </Box>

                                            {((this.state.jobType !== JobStepType.Shift) && (this.state.jobType !== JobStepType.OnTheSpot)) && <Box clone pb={2}>
                                                <Grid container alignItems={'center'}>
                                                    <Box clone pb={{ xs: 1, md: 0 }}>
                                                        <Grid item xs={12} md={true}>
                                                        The driver can add new Bin ID on driver app
                                                              
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={true} md={'auto'}>
                                                            <CustomToggle
                                                                checked={this.state.isEditableBinNumberIn}
                                                                disabled={false}
                                                                onChange={(checked) => {
                                                                    this.setState({
                                                                        isEditableBinNumberIn: checked,
                                                                    }, () => {
                                                                        if(this.props.isEditableBinNumberIn){
                                                                            this.props.onChangIsEditableWasteTypeIn(this.state.isEditableBinNumberIn);
                                                                        }
                                                                    });
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box clone textAlign={'center'}>
                                                        <Grid item xs={'auto'} md={1}>&nbsp;</Grid>
                                                    </Box>
                                                </Grid>
                                            </Box>}

                                        </Grid>
                                    </Box>
                                </Grid>
                            </Box>}

                        </Grid>
                    </Box>
                </Grid>
        
            </Box>
        </Card>
    }


    render() {
        return <Box>
            {this.setAppPermissionForm()}
        </Box>
    }
}

export default AppPermissionSection;
