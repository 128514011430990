import { createSlice } from "@reduxjs/toolkit";

import * as dispatchCrudApi from '../../../api/CRUD/DispatchCRUD'
import Utils from "../../../utils/utils";
import { ServiceType, ReportUOM } from "../../../utils/enums";


function NewReducer() {
  const name = 'billingRefresh';


  const initialState = {
    isLoading: false,
    pricings: [],
    chargeCategoryItems: [],
  };


  const reducers = {
    start: (state) => {
      state.isLoading = true;
      state.pricings = [];
    },
    finish: (state, action) => {
      let data = action.payload;
      
      let chargeCategoryItems = (data && data.data && data.data.chargeCategoryItems && data.data.chargeCategoryItems.length > 0) ? data.data.chargeCategoryItems : [];
      let oldPricings = (data && data.data && data.data.oldPricings && data.data.oldPricings.length > 0) ? data.data.oldPricings : [];
      let job = (data && data.data && data.data.data) ? data.data.data : null;
      let pricings = (job && job.pricings && job.pricings.length > 0) ? job.pricings : [];
      let withoutManualPricings = pricings.filter(x => !(x.serviceType == ServiceType.AdditionalService && (x.bundleServiceTag == null || x.bundleServiceTag == '' || x.bundleServiceTag == undefined)));
      
      let obj = func.prepare(withoutManualPricings, oldPricings, chargeCategoryItems);

      state.pricings = obj.pricings;
      state.chargeCategoryItems = obj.chargeCategoryItems;
      state.isLoading = false;
    },
    finishError: (state, action) => {
      let data = action.payload;
      
      let chargeCategoryItems = (data && data.data && data.data.chargeCategoryItems && data.data.chargeCategoryItems.length > 0) ? data.data.chargeCategoryItems : [];
      let oldPricings = (data && data.data && data.data.oldPricings && data.data.oldPricings.length > 0) ? data.data.oldPricings : [];
      
      state.pricings = oldPricings;
      state.chargeCategoryItems = chargeCategoryItems;
      state.isLoading = false;
    },
  };

  
  const func = {
    prepare: (pricings = [], oldPricings = [], chargeCategoryItems = []) => {
      let newChargeCategoryItems = Array.from(chargeCategoryItems);

      let newPricings = [];
      if(pricings && pricings.length > 0){
        newPricings = pricings.map((item, i) => {
          let chargeCategoryObj = (item.chargeCategory) ? item.chargeCategory : null;
          let chargeCategoryId = (item.chargeCategoryId) ? item.chargeCategoryId : null;
          let chargeCategoryName = (chargeCategoryObj && chargeCategoryObj.chargeCategoryName) ? chargeCategoryObj.chargeCategoryName : '';
          if(chargeCategoryObj){
            let chargeCategoryItem = {
              value: chargeCategoryId,
              title: chargeCategoryName,
              item: chargeCategoryObj,
            }
  
            newChargeCategoryItems = Utils.addToArray(newChargeCategoryItems, chargeCategoryId, chargeCategoryItem)
          }

          // NEW from API
          let jobStep = (item.jobStep) ? item.jobStep : null;
          let weightChitTicketNumber = (jobStep && jobStep.weightChitTicketNumber) ? jobStep.weightChitTicketNumber : '';
          let binWeight = (jobStep && jobStep.binWeight) ? jobStep.binWeight : '';
          let tareBinWeight = (jobStep && jobStep.tareBinWeight) ? jobStep.tareBinWeight : '';
          let netBinWeight = (jobStep && jobStep.netBinWeight) ? jobStep.netBinWeight : '';
          let nonBillableBinWeight = (jobStep && jobStep.nonBillableBinWeight) ? jobStep.nonBillableBinWeight : '';
          let billableBinWeight = (jobStep && jobStep.billableBinWeight) ? jobStep.billableBinWeight : '';
          let weightChitRemarks = (jobStep && jobStep.weightChitRemarks) ? jobStep.weightChitRemarks : '';
          let binWeighBy = (jobStep && jobStep.binWeighBy) ? jobStep.binWeighBy : '';
          let weightChitDate = (jobStep && jobStep.weightChitDateTime) ? new Date(jobStep.weightChitDateTime) : null;
          let weightChitTime = (jobStep && jobStep.weightChitDateTime) ? new Date(jobStep.weightChitDateTime) : null;
          

          // OLD from prev pricings
          let isExpanded = false;
          if(item.useBinWeightQuantity){
            if(oldPricings && oldPricings.length > 0){
              let oldItems = oldPricings.filter(x => x.useBinWeightQuantity);
              if(oldItems && oldItems.length > 0){
                let oldJobStep = oldItems[0];
                isExpanded = (oldJobStep) ? oldJobStep.isExpanded : false;
                
                weightChitTicketNumber = (oldJobStep && oldJobStep.ticketNumber) ? oldJobStep.ticketNumber : jobStep.weightChitTicketNumber;
                binWeight = (oldJobStep && oldJobStep.grossWeight) ? oldJobStep.grossWeight : jobStep.binWeight;
                tareBinWeight = (oldJobStep && oldJobStep.tareWeight) ? oldJobStep.tareWeight : jobStep.tareBinWeight;
                netBinWeight = (oldJobStep && oldJobStep.nettWeight) ? oldJobStep.nettWeight : jobStep.netBinWeight;
                // nonBillableBinWeight = (oldJobStep && oldJobStep.nonBillableBinWeight) ? oldJobStep.nonBillableBinWeight : jobStep.nonBillableBinWeight;
                billableBinWeight = (oldJobStep && oldJobStep.billableWeight) ? oldJobStep.billableWeight : jobStep.billableBinWeight;
                weightChitRemarks = (oldJobStep && oldJobStep.remarks) ? oldJobStep.remarks : jobStep.weightChitRemarks;
                binWeighBy = (oldJobStep && oldJobStep.weighBy) ? oldJobStep.weighBy : jobStep.binWeighBy;
                weightChitDate = (oldJobStep && oldJobStep.date) ? oldJobStep.date : jobStep.date;
                weightChitTime = (oldJobStep && oldJobStep.time) ? oldJobStep.time : jobStep.time;


                if(binWeight && binWeight != ''){
                  let grossWeightVal = Utils.parseFloat(binWeight);
                  let tareWeightVal = Utils.parseFloat(tareBinWeight);
                  let nonBillableBinWeightVal = Utils.parseFloat(nonBillableBinWeight);
  
                  netBinWeight = grossWeightVal - tareWeightVal;
                  billableBinWeight = netBinWeight - nonBillableBinWeightVal;
                }
              }
            }
          }

          let uom = Utils.getUOM();
          try { netBinWeight = netBinWeight.toFixed((uom === ReportUOM.kg) ? 0 : 3); } catch(e){}
          try { billableBinWeight = billableBinWeight.toFixed((uom === ReportUOM.kg) ? 0 : 3); } catch(e){}
          
          let quantity = (item.quantity) ? item.quantity : '0';
          if(item.useBinWeightQuantity){
            // nonBillableBinWeight = Utils.convertUom(nonBillableBinWeight, Utils.getUOM(), Utils.getBillingUOM());
            try { nonBillableBinWeight = Utils.parseFloat(nonBillableBinWeight).toFixed((Utils.getUOM() === ReportUOM.kg) ? 0 : 3); } catch(e){}

            
            let grossWeight1 = Utils.parseFloat(binWeight);
            let tareWeight1 = Utils.parseFloat(tareBinWeight);
            let nonBillableBinWeight1 = Utils.parseFloat(nonBillableBinWeight);

            let nettWeight1 = grossWeight1 - tareWeight1;
            let billableWeight1 = nettWeight1 - nonBillableBinWeight1;
            billableBinWeight = (billableWeight1 < 0) ? 0 : Utils.parseFloat(billableWeight1);
            try { billableBinWeight = billableBinWeight.toFixed((Utils.getUOM() === ReportUOM.kg) ? 0 : 3); } catch(e){}

            quantity = Utils.convertUom(billableBinWeight, Utils.getUOM(), Utils.getBillingUOM());
            try { quantity = quantity.toFixed((Utils.getBillingUOM() === ReportUOM.kg) ? 0 : 3); } catch(e){}
          }
          

          let isTaxInclusive = ((item.isTaxInclusive === false) || (item.isTaxInclusive === true)) ? item.isTaxInclusive : false;
          let invoiceTaxRate = (item && Utils.isNumber(item.invoiceTaxRate)) ? item.invoiceTaxRate : null;
          let purchaseTaxRate = (item && Utils.isNumber(item.purchaseTaxRate)) ? item.purchaseTaxRate : null;

          return {
            serviceItemId: item.serviceItemId,
            serviceTag: item.serviceTag,
            billingType: item.billingType,
            chargeCategoryId: chargeCategoryId,
            chargeCategoryName: chargeCategoryName,
            quantity: quantity,
            price: item.price,
            isTaxInclusive: isTaxInclusive,
            invoiceTaxRate: invoiceTaxRate,
            purchaseTaxRate: purchaseTaxRate,
            bundleServiceTag: item.bundleServiceTag,
            binTypeId: item.binTypeId,
            wasteTypeId: item.wasteTypeId,
            serviceType: item.serviceType,
            useBinWeightQuantity: item.useBinWeightQuantity,
      
            isExpanded: isExpanded,
            jobStepId: item.jobStepId,
            jobStep: jobStep,
            ticketNumber: weightChitTicketNumber,
            grossWeight: binWeight,
            tareWeight: tareBinWeight,
            nettWeight: netBinWeight,
            nonBillableBinWeight: nonBillableBinWeight,
            billableWeight: billableBinWeight,
            remarks: weightChitRemarks,
            weighBy: binWeighBy,
            date: weightChitDate,
            time: weightChitTime,
            uom: Utils.getUOM(),
          }
        });
      }

      newPricings = Utils.addManualItems(newPricings, oldPricings);

      return {
        chargeCategoryItems: newChargeCategoryItems,
        pricings: newPricings,
      };
    }
  };


  const apis = {
    callRefreshPricingsApi: (payload = null) => async (dispatch) => {
      dispatch(actions.start());
      
      let jobId = (payload && payload.jobId) ? payload.jobId : null;
      let oldPricings = (payload && payload.oldPricings && payload.oldPricings.length > 0) ? payload.oldPricings : [];
      let chargeCategoryItems = (payload && payload.chargeCategoryItems && payload.chargeCategoryItems.length > 0) ? payload.chargeCategoryItems : [];

      await dispatchCrudApi.readApi(null, 'job/billing/refresh/' + jobId).then(result => {
        let data = {
          data: result.data,
          oldPricings: oldPricings,
          chargeCategoryItems: chargeCategoryItems,
        }
        
        dispatch(actions.finish({ data: data, errorMessage: '', isError: false }));
      }).catch(error => {
        let isError = true;
        let errorMessage = "";

        Utils.parseError(error, (result) => {
          isError = result.isError;
          errorMessage = result.errorMessage;
        });
        
        let err = (errorMessage && errorMessage.error && errorMessage.error != '') ? errorMessage.error : null;
        if(err){
          Utils.toast(<div style={{ fontWeight: 'bold' }}>{errorMessage.error}</div>, 'error');
        }

        let data = {
          data: null,
          oldPricings: oldPricings,
          chargeCategoryItems: chargeCategoryItems,
        }

        dispatch(actions.finishError({ data: data, errorMessage: errorMessage, isError: isError }));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();