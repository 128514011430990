import React, { Component } from 'react';

import { 
  Box,
  CircularProgress,
} from '@material-ui/core';

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';

import CheckIcon from '@material-ui/icons/Check';


class AuditTrailTimeline extends Component {
  
	constructor(props){
		super(props);

        this.defTextNoResult = 'No Result!';
        this.defTextOpened = 'Collapse';
        this.defTextClosed = 'Expand';

        let className = props.className ? props.className : '';
        let items = props.items ? props.items : [];
        let align = props.align ? props.align : 'left';
        let noResult = (props.noResult && props.noResult !== '') ? props.noResult : this.defTextNoResult;
        let isDetails = ((props.isDetails === false) || (props.isDetails === true)) ? props.isDetails : false;
        let detailsBtnTextOpened = (props.detailsBtnTextOpened && props.detailsBtnTextOpened !== '') ? props.detailsBtnTextOpened : this.defTextOpened;
        let detailsBtnTextClosed = (props.detailsBtnTextClosed && props.detailsBtnTextClosed !== '') ? props.detailsBtnTextClosed : this.defTextClosed;
        let isLoading = ((props.isLoading === false) || (props.isLoading === true)) ? props.isLoading : false;
        
		this.state = {
            className: className,
            items: items,
            align: align,
            noResult: noResult,
            detailsBtnTextOpened: detailsBtnTextOpened,
            detailsBtnTextClosed: detailsBtnTextClosed,
            isDetails: isDetails,
            isLoading: isLoading,
		}
	}
    
    
	componentWillReceiveProps(nextProps) {
		if (nextProps.items !== this.state.items) {
			this.setState({
				items: nextProps.items
			});
		}

        if (nextProps.isLoading !== this.state.isLoading) {
            let isLoading = ((nextProps.isLoading === false) || (nextProps.isLoading === true)) ? nextProps.isLoading : false;
			this.setState({
				isLoading: isLoading
			});
		}
        if (nextProps.isDetails !== this.state.isDetails) {
            let isDetails = ((nextProps.isDetails === false) || (nextProps.isDetails === true)) ? nextProps.isDetails : false;
			this.setState({
				isDetails: isDetails
			});
		}
        if (nextProps.detailsBtnTextOpened !== this.state.detailsBtnTextOpened) {
            let detailsBtnTextOpened = nextProps.detailsBtnTextOpened ? nextProps.detailsBtnTextOpened : this.defTextOpened;
			this.setState({
				detailsBtnTextOpened: detailsBtnTextOpened
			});
		}
        if (nextProps.detailsBtnTextClosed !== this.state.detailsBtnTextClosed) {
            let detailsBtnTextClosed = nextProps.detailsBtnTextClosed ? nextProps.detailsBtnTextClosed : this.defTextClosed;
			this.setState({
				detailsBtnTextClosed: detailsBtnTextClosed
			});
		}
    }


    setItems = () => {
        if(!this.state.isLoading){
            if(this.state.items && this.state.items.length > 0){
                return <Timeline align={this.state.align} className={'px-2'}>
                    {this.state.items.map((item, i) => {
                        return <TimelineItem key={i}>
                            <TimelineOppositeContent>{(item && item.time) ? item.time : ''}</TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot color={(item && item.color) ? item.color : 'grey'}>{(item && item.icon) ? item.icon : <CheckIcon />}</TimelineDot>
                                {(i < (this.state.items.length - 1)) && <TimelineConnector />}
                            </TimelineSeparator>
                            <TimelineContent>
                                {(item && item.contentTitle) && <Box className={'custom-timeline-item-desc pb-0'}>{item.contentTitle}</Box>}
                                {(this.state.isDetails) && <Box className={'custom-timeline-item-desc pb-5'}>
                                    <a href={'/'} 
                                        className={'link-primary'}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();

                                            if(this.props.onChangeShowDetails){
                                                this.props.onChangeShowDetails(i, !item.showDetails);
                                            }
                                        }}
                                    >
                                        {item.showDetails ? this.state.detailsBtnTextOpened : this.state.detailsBtnTextClosed}
                                    </a>
                                </Box>}
                                <Box className={'custom-timeline-item-desc'}>{(item && item.contentDesc) ? item.contentDesc : ''}</Box>
                                {(this.state.isDetails && item.showDetails) && <Box className={'custom-timeline-item-desc'}>{item.details}</Box>}
                            </TimelineContent>
                        </TimelineItem>
                    })}
                </Timeline>
            } else {
                return <Box pb={2} textAlign={'center'} fontWeight={'bold'}>{this.state.noResult}</Box>
            }
        } else {
            return <Box pb={2} textAlign={'center'}><CircularProgress /></Box>
        }
    }
	
	
	render() {
		return <Box className={"custom-timeline-component " + this.state.className}>
            {this.setItems()}
		</Box>;
	}
}

export default AuditTrailTimeline;
