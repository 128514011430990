import React from 'react';

import {
    Row,
    Col,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import { StyledStepText, StyledStepHr, StyledContainer } from '../style/styles';


const StyledStepContent = styled.div<ContentProps>`
    padding: ${props => props.padding};
`;


interface ContentProps {
    padding?: string;
}


const PayrollBetaStepContainer = ({ title, desc, children, padding = '20px 30px 20px 30px', ...rest }: any) => {
    return <StyledContainer className={'p-0'}>
        <Row>
            <Col xs={12}>
                <StyledStepText>
                    {title}<br />
                    <small>{desc}</small>
                </StyledStepText>
                <StyledStepHr />
            </Col>
            <Col xs={12}>
                <StyledStepContent padding={padding}>{children}</StyledStepContent>
            </Col>
        </Row>
    </StyledContainer>
}


export default PayrollBetaStepContainer;
