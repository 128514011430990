import React from "react";

import { 
    Row,
    Col,
    Form,
    InputGroup,
    Dropdown,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import { Formik } from 'formik';
import * as yup from 'yup';

import Utils from "../../../utils/utils";
import { ActiveInactive, XeroPaymentTermType, XeroPaymentTermTypeItems } from "../../../utils/enums";
import { StyledDropdownButton } from "../../../utils/styles";

import RSuiteSelectPicker from '../../../components/OLD/Select/RSuiteSelectPicker';
import makeCustomerCategory from "../../../components/makeCategoryCompenents/customer/makeCustomerCategory";
import InputLayout from "../../../components/input/InputLayout";
import SmartInputDropdown from '../../../components/dropdown/smartInputDropdown/SmartInputDropdown';

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut, dispatchApiCallDelete } from '../../../../setup/redux/dispatch/actions'


const CustomerCategory = makeCustomerCategory(RSuiteSelectPicker);


const formFields = {
    isActive: {
        id: 'isActive',
        label: 'Status',
        placeholder: ' ',
    },
    accountBillingProfileId: {
      id: 'accountBillingProfileId',
      label: 'Billing by',
      placeholder: ' ',
    },
    paymentTermsId: {
      id: 'paymentTermsId',
      label: 'Payment terms',
      placeholder: ' ',
    },
    xeroPaymentTerm: {
      id: 'xeroPaymentTerm',
      label: 'Payment terms',
      placeholder: ' ',
    },
    xeroPaymentTermType: {
        id: 'xeroPaymentTermType',
        label: ' ',
        placeholder: ' ',
        error: 'Due date for sales invoices must be between 1 and 31 when set to the following (or current) month.',
    },
    creditLimit: {
      id: 'creditLimit',
      label: 'Credit limit',
      placeholder: ' ',
    },
    isRequirePaymentCollection: {
      id: 'isRequirePaymentCollection',
      label: 'Collect payment',
      placeholder: ' ',
    },
    customerCategoryId: {
      id: 'customerCategoryId',
      label: 'Customer category',
      placeholder: ' ',
    },
    note: {
      id: 'note',
      label: 'Customer note',
      placeholder: Utils.placeholderRows(5),
    },
}
const formSchema = yup.object().shape({
    isEdit: yup.bool().oneOf([true, false]),
    isActive: yup.bool().oneOf([true, false]),
    accountBillingProfileId: yup.number().nullable().label(formFields.accountBillingProfileId.label),
    paymentTermsId: yup.number().nullable().label(formFields.paymentTermsId.label),

    // xeroPaymentTerm: yup.number().nullable().positive().min(0).label(formFields.xeroPaymentTerm.label),
    xeroPaymentTerm: yup.number().when(['customFormParams.isConnectXero', 'xeroPaymentTermType'], (isConnectXero, xeroPaymentTermType) => {
        if(isConnectXero){
            if((xeroPaymentTermType == XeroPaymentTermType.OF_CURRENT_MONTH) || (xeroPaymentTermType == XeroPaymentTermType.OF_FOLLOWING_MONTH)){
                return yup.number().nullable().required().positive().min(0).max(31, formFields.xeroPaymentTermType.error).label(formFields.xeroPaymentTerm.label);
            } else {
                return yup.number().nullable().required().positive().min(0).label(formFields.xeroPaymentTerm.label);
            }
        } else {
          return yup.number().nullable().positive().min(0).label(formFields.xeroPaymentTerm.label);
        }
    }),
    xeroPaymentTermType: yup.number().when(['customFormParams.isConnectXero'], (isConnectXero) => {
        if(isConnectXero){
          return yup.number().nullable().required().positive().min(0).label(formFields.xeroPaymentTermType.label);
        } else {
          return yup.number().nullable().label(formFields.xeroPaymentTermType.label);
        }
    }),

    creditLimit: yup.number().nullable().min(0).label(formFields.creditLimit.label),
    isRequirePaymentCollection: yup.bool().oneOf([true, false]),
    customerCategoryId: yup.number().nullable().label(formFields.customerCategoryId.label),
    note: yup.string().label(formFields.note.label),
});


const CreditLimitInputGroupText = styled(InputGroup.Text)`
    border-right: 0px;
    background-color: transparent;
    border-top-left-radius: 0.475rem;
    border-bottom-left-radius: 0.475rem;
    border-color: ${props => (props.isinvalid == 'true') ? '#FF0090' : '#E4E6EF'};
    padding-right: 0px;
`;
const CreditLimiInput = styled(Form.Control)`
    border-left: 0px;
    padding-left: 1px;
    
    &:focus {
        border-color: #E4E6EF;
    }
`;


class BulkUpdateCustomerForm extends React.Component {
  
    constructor(props) {
        super(props);
    
        this.pageName = 'customer_bulk_update';

        this.refForm = React.createRef();

        let id = (props.id) ? props.id : null;
        

        this.state = {
            id: id,

            initialValues: {
                isEdit: false,
                isActive: true,
                statusName: '',
                accountBillingProfileId: null,
                accountBillingProfileName: '',
                paymentTermsId: null,
                paymentTermsName: '',
                xeroPaymentTerm: '0',
                xeroPaymentTermType: '0',
                creditLimit: null,
                isRequirePaymentCollection: false,
                customerCategoryId: null,
                customerCategoryIdName: '',
                note: '',
                customFormParams: {
                    accountBillingProfileItems: [],
                    accountBillingProfileIsLoading: false,
                    isConnectQuickbook: false,
                    isConnectXero: false,
                    quickBookAppId: 0,
                    paymentTermsItems: [],
                    paymentTermsIsLoading: false,
                    customerCategoryItems: [],
                    customerCategoryIsLoading: false,
                }
            },
        };
    }


    componentDidMount(){
        // this.init();
    }

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
		Utils.reduxProps(nextProps,
            this.pageName + '-row_billing_rofile', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.accountBillingProfileId;
                        let title = item.billingProfileName;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-row_quickBook_credit_terms', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if(data && data.length > 0) {
                    for (var i = 0; i < data.length; i++) {
                        let item = data[i];
                        let value = item.id;
                        let title = item.name;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );

		Utils.reduxProps(nextProps,
            this.pageName + '-row_billing_customer_category', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.customerCategoryId;
                        let title = item.customerCategoryName;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );
    }
	
    callReadBillingProfileApi = (items, callback = null) => {
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            isActive: true,
        };
     
        this.props.dispatchApiCallGet(data, this.pageName + '-row_billing_rofile', 'AccountBillingProfile', { items: items }, callback, () => {});
    }
    
    callQuickbookReadCreditTermsApi = (quickBookAppId = 0, items, callback = null) => {
        let data = {
            quickBookAppId: quickBookAppId,
        };
     
        this.props.dispatchApiCallGet(data, this.pageName + '-row_quickBook_credit_terms', 'quickbookapp/term', { items: items }, callback, () => {});
    }

    callReadCustomerCategoryApi = (items, callback = null) => {
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
        };
     
        this.props.dispatchApiCallGet(data, this.pageName + '-row_billing_customer_category', 'customercategory', { items: items }, callback, () => {});
    }
    /* END API */


    /* FUNCTIONS */
    setLoading = (state = false) => {
        if(this.props.onLoading){
            this.props.onLoading(state);
        }
    }
    init = () => {
        if(this.refForm && this.refForm.current){
            this.setLoading(true);
            this.callReadBillingProfileApi(this.refForm.current.values.customFormParams.accountBillingProfileItems, async (items) => {
                if(items && items.length > 0){
                    let defaultIndex = items.findIndex(x => x.item.isDefault);
                    if(defaultIndex != -1){
                        let item = items[defaultIndex];

                        let quickBookApp = (item && item.item && item.item.quickBookApp) ? item.item.quickBookApp : null;
                        let isConnectQuickbook = (item && item.item && item.item.isConnectQuickbook) ? item.item.isConnectQuickbook : false;
                        let isConnectXero = (item && item.item && item.item.isConnectXero) ? item.item.isConnectXero : false;
                        let quickBookAppId = (quickBookApp && quickBookApp.quickBookAppId) ? quickBookApp.quickBookAppId : 0;

                        await this.refForm.current.setFieldValue('accountBillingProfileId', item.value);
                        await this.refForm.current.setFieldValue('accountBillingProfileName', item.title);
                        await this.refForm.current.setFieldValue('customFormParams.quickBookAppId', quickBookAppId);
                        await this.refForm.current.setFieldValue('customFormParams.isConnectQuickbook', isConnectQuickbook);
                        await this.refForm.current.setFieldValue('customFormParams.isConnectXero', isConnectXero);
                    }

                    await this.refForm.current.setFieldValue('customFormParams.accountBillingProfileItems', items);
                    await this.refForm.current.setFieldValue('customFormParams.accountBillingProfileIsLoading', false);

                    this.setLoading(false);
                }
            });
        }
    }
    prepareData = (form) => {
        // let billingProfile = null;
        let accountBillingProfileId = form.accountBillingProfileId;
        // if(accountBillingProfileId){
        //   billingProfile = {
        //     accountBillingProfileId: accountBillingProfileId,
        //     billingProfileName: form.accountBillingProfileName,
        //   }
        // }
        
        let category = null;
        let customerCategoryId = form.customerCategoryId;
        if(customerCategoryId){
            category = {
                // customerCategoryId: customerCategoryId,
                customerCategoryName: form.customerCategoryName,
            }
        }
        

        let data = {
            isActive: form.isActive,
            accountBillingProfileId: accountBillingProfileId,
            // billingProfile: billingProfile,
            quickBookDefaultTermId: form.paymentTermsId,
            creditLimit: form.creditLimit,
            isRequirePaymentCollection: form.isRequirePaymentCollection,
            customerCategoryId: customerCategoryId,
            category: category,
            note: form.note,
        };

        if(accountBillingProfileId){
            data['xeroPaymentTerm'] = form.xeroPaymentTerm;
            data['xeroPaymentTermType'] = form.xeroPaymentTermType;
        } else {
            data['xeroPaymentTerm'] = null;
        }

        if(form.customFormParams.isConnectQuickbook){
            delete data['xeroPaymentTerm'];
            delete data['xeroPaymentTermType'];
        }

        if(this.state.id) {
            data['ids'] = this.state.id;
        }

        return data;
    }
    setSubmit = (form) => {
        if(this.props.onSubmit){
            this.props.onSubmit(form);
        }
    }
    /* END FUNCTIONS */


    /* FORM */
    form = (formOptions) => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = formOptions;
        
        return <>

            <InputLayout
                label={formFields.isActive.label}
            >
                <Form.Control
                    as = {SmartInputDropdown}
                    isInvalid={false}
                    errorText={''}
        
                    value={values.isActive ? ActiveInactive.ActiveValue : ActiveInactive.InactiveValue}
                    label={values.isActive ? ActiveInactive.ActiveTitle : ActiveInactive.InactiveTitle}
                    options={[
                        {
                            value: ActiveInactive.InactiveValue,
                            title: ActiveInactive.InactiveTitle,
                            color: '#FF0090',
                        },
                        {
                            value: ActiveInactive.ActiveValue,
                            title: ActiveInactive.ActiveTitle,
                            color: '#185cff',
                        }
                    ]}
                    placeholder={formFields.isActive.placeholder}
                    disabled={false}
                    isLoading={false}
                    showSearch={false}
                    showClear={false}
                    showFooter={false}
                    isInfiniteScroll={false}

                    onChange={async (value, item, i) => {
                        await setFieldValue('isActive', value);
                    }}
                />
            </InputLayout>

            <InputLayout
                label={formFields.accountBillingProfileId.label}
            >
                <Form.Control
                    as = {RSuiteSelectPicker}
                    isInvalid={!!errors.accountBillingProfileId}

                    id={formFields.accountBillingProfileId.id}
                    placeholder={formFields.accountBillingProfileId.placeholder}
                    items={values.customFormParams.accountBillingProfileItems}
                    value={values.accountBillingProfileId}
                    selectedName={values.accountBillingProfileName}
                    isLoading={values.customFormParams.accountBillingProfileIsLoading}
                    searchable={true}
                    cleanable={true}
                    isDefault={true}
                    onClick={async () => {
                        await setFieldValue('customFormParams.accountBillingProfileIsLoading', true);
                        this.callReadBillingProfileApi(values.customFormParams.accountBillingProfileItems, async (items) => {
                            await setFieldValue('customFormParams.accountBillingProfileItems', items);
                            await setFieldValue('customFormParams.accountBillingProfileIsLoading', false);
                        });
                    }}
                    onSelect={async (value, item) => {
                        let quickBookApp = (item && item.item && item.item.quickBookApp) ? item.item.quickBookApp : null;
                        let isConnectQuickbook = (item && item.item && item.item.isConnectQuickbook) ? item.item.isConnectQuickbook : false;
                        let isConnectXero = (item && item.item && item.item.isConnectXero) ? item.item.isConnectXero : false;
                        let quickBookAppId = (quickBookApp && quickBookApp.quickBookAppId) ? quickBookApp.quickBookAppId : 0;

                        await setFieldValue('accountBillingProfileId', value);
                        await setFieldValue('accountBillingProfileName', item.title);
                        await setFieldValue('customFormParams.quickBookAppId', quickBookAppId);
                        await setFieldValue('customFormParams.isConnectQuickbook', isConnectQuickbook);
                        await setFieldValue('customFormParams.isConnectXero', isConnectXero);

                        if(isConnectXero){
                            let xeroApp = (item && item.item && item.item.xeroApp) ? item.item.xeroApp : null;
                            let xeroPaymentTermType = (xeroApp && xeroApp.xeroPaymentTermType) ? xeroApp.xeroPaymentTermType.toString() : '0';
                            let xeroPaymentTerm = (xeroApp && xeroApp.xeroPaymentTerm) ? xeroApp.xeroPaymentTerm.toString() : '0';
                            await setFieldValue('xeroPaymentTermType', xeroPaymentTermType);
                            await setFieldValue('xeroPaymentTerm', xeroPaymentTerm);
                        }
                    }}
                    onClear={async () => {
                        await setFieldValue('accountBillingProfileId', null);
                        await setFieldValue('accountBillingProfileName', '');
                        await setFieldValue('xeroPaymentTermType', '0');
                        await setFieldValue('xeroPaymentTerm', '0');
                        await setFieldValue('customFormParams.quickBookAppId', 0);
                        await setFieldValue('customFormParams.isConnectQuickbook', false);
                        await setFieldValue('customFormParams.isConnectXero', false);
                    }}
                />
                <Form.Control.Feedback type="invalid">{errors.accountBillingProfileId}</Form.Control.Feedback>
            </InputLayout>

            {(values.customFormParams.isConnectQuickbook) && <InputLayout
                label={formFields.paymentTermsId.label}
            >
                <Form.Control
                    as = {RSuiteSelectPicker}
                    isInvalid={!!errors.paymentTermsId}

                    id={formFields.paymentTermsId.id}
                    placeholder={formFields.paymentTermsId.placeholder}
                    items={values.customFormParams.paymentTermsItems}
                    value={values.paymentTermsId}
                    selectedName={values.paymentTermsName}
                    isLoading={values.customFormParams.paymentTermsIsLoading}
                    searchable={true}
                    cleanable={true}
                    isDefault={true}
                    onClick={async () => {
                        await setFieldValue('customFormParams.paymentTermsIsLoading', true);
                        this.callQuickbookReadCreditTermsApi(values.customFormParams.quickBookAppId, values.customFormParams.paymentTermsItems, async (items) => {
                            await setFieldValue('customFormParams.paymentTermsItems', items);
                            await setFieldValue('customFormParams.paymentTermsIsLoading', false);
                        });
                    }}
                    onSelect={async (value, item) => {
                        await setFieldValue('paymentTermsId', value);
                        await setFieldValue('paymentTermsName', item.title);
                        await setFieldValue('xeroPaymentTerm', '0');
                    }}
                    onClear={async () => {
                        await setFieldValue('paymentTermsId', null);
                        await setFieldValue('paymentTermsName', '');
                        await setFieldValue('xeroPaymentTerm', '0');
                    }}
                />
                <Form.Control.Feedback type="invalid">{errors.paymentTermsId}</Form.Control.Feedback>
            </InputLayout>}

            {(values.customFormParams.isConnectXero) && <InputLayout
                label={formFields.xeroPaymentTerm.label}
            >
                <InputGroup>
                    <Form.Control
                        type="number"
                        id={formFields.xeroPaymentTerm.id}
                        placeholder={formFields.xeroPaymentTerm.placeholder}
                        value={values.xeroPaymentTerm}
                        onChange={async (e) => {
                            await setFieldValue('xeroPaymentTerm', e.target.value);
                            await setFieldValue('paymentTermsId', null);
                            await setFieldValue('paymentTermsName', '');
                        }}
                        isInvalid={!!errors.xeroPaymentTerm}
                    />
                    <StyledDropdownButton
                        title={Utils.getXeroPaymentTermTypeName(values.xeroPaymentTermType)}
                        variant={'secondary'}
                        menuAlign={'right'}
                    >
                        {XeroPaymentTermTypeItems.map((item, i) => {
                            return <Dropdown.Item
                                className={'py-2'}
                                key={item.value + '_' + item.title + '_' + i}
                                active={(item.value === values.xeroPaymentTermType)}
                                onClick={async () => {
                                    await setFieldValue('xeroPaymentTermType', item.value);
                                }}
                            >{item.title}</Dropdown.Item>
                        })}
                    </StyledDropdownButton>
                    <Form.Control.Feedback type="invalid">{errors.xeroPaymentTerm}</Form.Control.Feedback>
                </InputGroup>
            </InputLayout>}

            <InputLayout
                label={formFields.creditLimit.label}
                error={<Form.Control.Feedback type="invalid" style={{ display: ((!!errors.creditLimit) ? 'block' : 'none')}}>{errors.creditLimit}</Form.Control.Feedback>}
                inputProps={{
                    className: 'd-flex align-items-center'
                }}
            >
                <InputGroup>
                    <CreditLimitInputGroupText isinvalid={(!!errors.creditLimit) ? 'true' : 'false'}>{Utils.getCurrency()}</CreditLimitInputGroupText>
                    <CreditLimiInput
                        type="number"
                        id={formFields.creditLimit.id}
                        placeholder={formFields.creditLimit.placeholder}
                        value={values.creditLimit}
                        onChange={async (e) => {
                            let value = e.target.value;
                            let pattern = Utils.onlyPositiveAndEmptyNumbers();

                            if(value.match(pattern) != null){
                                await setFieldValue('creditLimit', e.target.value);
                            }
                        }}
                        min={0}
                        isInvalid={!!errors.creditLimit}
                    />
                </InputGroup>
            </InputLayout>

            <InputLayout
                label={formFields.isRequirePaymentCollection.label}
                inputProps={{
                    className: 'd-flex align-items-center'
                }}
            >
                <Form.Check
                    id={formFields.isRequirePaymentCollection.id}
                    // label={formFields.isRequirePaymentCollection.label}
                    checked={values.isRequirePaymentCollection}
                    onChange={handleChange}
                    isInvalid={!!errors.isRequirePaymentCollection}
                    feedback={errors.isRequirePaymentCollection}
                />
            </InputLayout>

            <InputLayout
                label={formFields.customerCategoryId.label}
            >
                <Form.Control
                    as = {CustomerCategory}
                    isInvalid={!!errors.customerCategoryId}

                    id={formFields.customerCategoryId.id}
                    placeholder={formFields.customerCategoryId.placeholder}
                    items={values.customFormParams.customerCategoryItems}
                    value={values.customerCategoryId}
                    selectedName={values.customerCategoryName}
                    isLoading={values.customFormParams.customerCategoryIsLoading}
                    searchable={true}
                    cleanable={true}
                    isDefault={true}
                    onClick={async () => {
                        await setFieldValue('customFormParams.customerCategoryIsLoading', true);
                        this.callReadCustomerCategoryApi(values.customFormParams.customerCategoryItems, async (items) => {
                            await setFieldValue('customFormParams.customerCategoryItems', items);
                            await setFieldValue('customFormParams.customerCategoryIsLoading', false);
                        });
                    }}
                    onSelect={async (value, item) => {
                        await setFieldValue('customerCategoryId', value);
                        await setFieldValue('customerCategoryName', item.title);
                    }}
                    onClear={async () => {
                        await setFieldValue('customerCategoryId', null);
                        await setFieldValue('customerCategoryName', '');
                    }}
                    onCreate={async (item) => {
                        let items = values.customFormParams.customerCategoryItems;
                        items.push(item)
                        await setFieldValue('customFormParams.customerCategoryItems', items);
                        await setFieldValue('customerCategoryId', item.value);
                        await setFieldValue('customerCategoryName', item.title);
                    }}
                    onUpdate={async (item) => {
                        let items = values.customFormParams.customerCategoryItems
                        let index = items.findIndex(x => x.value === item.value);
                        items[index] = item;

                        await setFieldValue('customFormParams.customerCategoryItems', items);
                        if(values.customerCategoryId === item.value){
                            await setFieldValue('customerCategoryId', item.value);
                            await setFieldValue('customerCategoryName', item.title);
                        }
                    }}
                    onRemove={async (item) => {
                        let items = values.customFormParams.customerCategoryItems.filter(x => x.value !== item.value);
                        await setFieldValue('customFormParams.customerCategoryItems', items);
                        await setFieldValue('customerCategoryId', null);
                        await setFieldValue('customerCategoryName', '');
                    }}
                />
                <Form.Control.Feedback type="invalid">{errors.customerCategoryId}</Form.Control.Feedback>
            </InputLayout>

            <InputLayout
                label={formFields.note.label}
            >
                <Form.Control
                    as="textarea"
                    rows={5}
                    id={formFields.note.id}
                    placeholder={formFields.note.placeholder}
                    value={values.note}
                    onChange={async (e) => {
                        Utils.limitRows(e.target.value, 5, async (value) => {
                            await setFieldValue('note', value);
                        });
                    }}
                    isInvalid={!!errors.note}
                />
                <Form.Control.Feedback type="invalid">{errors.note}</Form.Control.Feedback>
            </InputLayout>

        </>
    }
    /* END FORM */


    render() {
        return <Formik
            innerRef={this.refForm}
            validationSchema={formSchema}
            initialValues={this.state.initialValues}
            enableReinitialize={true}
            validateOnMount={false}
            validateOnChange={false}
            onSubmit={(form, e) => {
                this.setLoading(true);
                let data = this.prepareData(form);
                this.setSubmit(data);
            }}
        >
            {(formOptions) => {
                return <Form className='w-100 p-5' noValidate autoComplete="off" onSubmit={formOptions.handleSubmit} onKeyDown={(keyEvent) => {
                    // if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                    //     keyEvent.preventDefault();
                    // }
                }}>
                    <Row>
                        <Col xs={12} className={'ps-2 pe-2'}>{this.form(formOptions)}</Col>
                    </Row>
                </Form>
            }}
        </Formik>
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  dispatchApiCallPost,
  dispatchApiCallPut,
  dispatchApiCallDelete,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(BulkUpdateCustomerForm);
