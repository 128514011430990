export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: string) {
  const current = getCurrentUrl(pathname)
  if (!current || !url) {
    return false
  }

  if (current === url) {
    return true
  }

  if (current.indexOf(url) > -1) {
    return true
  }

  return false
}


export const customCheckIsActive = (pathname: string, url: string) => {
  if (!pathname || !url) {
    return false
  }

  if (pathname === url) {
    return true
  }

  if (pathname.includes(url)) {
    return true
  }

  return false
}