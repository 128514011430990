import React from "react";
import _ from 'lodash';

import { 
    Row,
    Col,
    Form,
} from "react-bootstrap-v5";

import { Formik } from 'formik';
import * as yup from 'yup';

import Utils from "../../../utils/utils";
import { CustomerSiteAvailability } from "../../../utils/enums";

import InputLayout from "../../../components/input/InputLayout";
import SmartInputDropdown from '../../../components/dropdown/smartInputDropdown/SmartInputDropdown';
import RSuiteCheckPicker from '../../../components/OLD/Checkbox/RSuiteCheckPicker';
import makeCRUD from "../../../components/dropdown/smartInputDropdown/makeCRUD";

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut, dispatchApiCallDelete } from '../../../../setup/redux/dispatch/actions'


const SmartDropdown = makeCRUD(SmartInputDropdown);


const formFields = {
    zoneId: {
        id: 'zoneId',
        label: 'Group As',
        placeholder: ' ',
    },
    contactPersonName: {
        id: 'contactPersonName',
        label: 'Contact 1',
        placeholder: 'Person 1',
    },
    contactPersonPhoneNumber: {
        id: 'contactPersonPhoneNumber',
        label: 'Phone 1',
        placeholder: 'Phone 1',
    },
    contactPersonNameTwo: {
        id: 'contactPersonNameTwo',
        label: 'Contact 2',
        placeholder: 'Person 2',
    },
    contactPersonPhoneNumberTwo: {
        id: 'contactPersonPhoneNumberTwo',
        label: 'Phone 2',
        placeholder: 'Phone 2',
    },
    isContactPersonSendNotification: {
        id: 'isContactPersonSendNotification',
        label: 'Notification',
        placeholder: ' ',
    },
    isContactPersonTwoSendNotification: {
        id: 'isContactPersonTwoSendNotification',
        label: 'Notification',
        placeholder: ' ',
    },
    driverId: {
        id: 'driverId',
        label: 'Default Driver(s)',
        placeholder: ' ',
    },
    // vehicleId: {
    //     id: 'vehicleId',
    //     label: 'Default Vehicles(s)',
    //     placeholder: ' ',
    // },
    weekdayId: {
        id: 'weekdayId',
        label: 'Weekdays Availability',
        placeholder: ' ',
    },
    weekendId: {
        id: 'weekendId',
        label: 'Weekends Availability',
        placeholder: ' ',
    },
    remarks: {
        id: 'remarks',
        label: <>Site Remarks<br />(Internal)</>,
        placeholder: Utils.placeholderRows(5),
    },
    instructions: {
        id: 'instructions',
        label: <>Driver Remarks</>,
        placeholder: Utils.placeholderRows(5),
    },
}
const formSchema = yup.object().shape({
    isEdit: yup.bool().oneOf([true, false]),
    zoneId: yup.number().nullable().label(formFields.zoneId.label),
    remarks: yup.string().label(formFields.remarks.label),
    instructions: yup.string().label(formFields.instructions.label),
    contactPersonName: yup.string().label(formFields.contactPersonName.label),
    contactPersonPhoneNumber: yup.string().label(formFields.contactPersonPhoneNumber.label),
    contactPersonNameTwo: yup.string().label(formFields.contactPersonNameTwo.label),
    contactPersonPhoneNumberTwo: yup.string().label(formFields.contactPersonPhoneNumberTwo.label),
    isContactPersonSendNotification: yup.bool().oneOf([true, false]),
    isContactPersonTwoSendNotification: yup.bool().oneOf([true, false]),
    weekdayId: yup.array().nullable().label(formFields.weekdayId.label),
    weekendId: yup.array().nullable().label(formFields.weekendId.label),
    driverId: yup.array().nullable().label(formFields.driverId.label),
    // vehicleId: yup.array().nullable().label(formFields.vehicleId.label),
});

  
class BulkUpdateCustomerSiteForm extends React.Component {
  
    constructor(props) {
        super(props);
    
        this.pageName = 'customer_site_bulk_update';

        this.refForm = React.createRef();

        let id = (props.id) ? props.id : null;
        

        this.state = {
            id: id,

            initialValues: {
                isEdit: false,
                
                zoneId: null,
                zoneName: '',
                remarks: '',
                instructions: '',
                contactPersonName: '',
                contactPersonPhoneNumber: '',
                contactPersonNameTwo: '',
                contactPersonPhoneNumberTwo: '',
                isContactPersonSendNotification: false,
                isContactPersonTwoSendNotification: false,
                driverId: null,
                // vehicleId: null,
                weekdayId: null,
                weekendId: null,
                weekdaysAvailability: CustomerSiteAvailability.All,
                weekendAvailability: CustomerSiteAvailability.All,
                customFormParams: {
                    driverItems: [],
                    driverIsLoading: false,
                    // vehicleItems: [],
                    // vehicleIsLoading: false,

                    weekdayItems: [],
                    weekdayIsLoading: false,
                    isApplyAllWeekdayTimes: true,
                    weekdayIsSelectAll: false,

                    weekendItems: [],
                    weekendIsLoading: false,
                    isApplyAllWeekendTimes: true,
                    weekendIsSelectAll: false,
                }
            },
        };
    }


    componentDidMount(){
        
    }

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            this.pageName + '-vehicles_list', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.vehicleId;
                        let title = item.vehicleName;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            this.pageName + '-drivers_list', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.driverId;
                        let title = item.driverName;
                        
                        let defaultVehicle = (item && item.defaultVehicle) ? item.defaultVehicle : null;
                        let vehicleName = (defaultVehicle && defaultVehicle.vehicleName) ? defaultVehicle.vehicleName : null;

                        let arrItem = {
                            value: value,
                            title: Utils.customConcat([title, vehicleName], ' / '),
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            this.pageName + '-weekday_list', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.accountJobWorkingTimeId;
                        let title = item.workingTimeName;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-weekend_list', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.accountJobWorkingTimeId;
                        let title = item.workingTimeName;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );
    }
	
    callReadVehicleApi = (items, callback = null) => {
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            sortColumn: 'updated',
            sortDir: 'asc',
        };
     
        this.props.dispatchApiCallGet(data, this.pageName + '-vehicles_list', 'vehicle', { items: items }, callback, () => {});
    }
    callReadDriverApi = (items, callback = null) => {
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            sortColumn: 'updated',
            sortDir: 'asc',
        };
     
        this.props.dispatchApiCallGet(data, this.pageName + '-drivers_list', 'driver', { items: items }, callback, () => {});
    }

    callReadWeekdayTimesApi = (items, callback = null) => {
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            sortColumn: 'order',
            sortDir: 'asc',
        };
     
        this.props.dispatchApiCallGet(data, this.pageName + '-weekday_list', 'AccountJobWorkingTime', { items: items }, callback, () => {});
    }

    callReadWeekendTimesApi = (items, callback = null) => {
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            sortColumn: 'order',
            sortDir: 'asc',
        };
     
        this.props.dispatchApiCallGet(data, this.pageName + '-weekend_list', 'AccountJobWorkingTime', { items: items }, callback, () => {});
    }
    /* END API */


    /* FUNCTIONS */
    setLoading = (state = false) => {
        if(this.props.onLoading){
            this.props.onLoading(state);
        }
    }
    prepareData = (form) => {
        var newForm = _.cloneDeep(form);

        let driverIds = (newForm && newForm.driverId && newForm.driverId.length > 0) ? newForm.driverId.join(',') : '';
        // let vehicleIds = (newForm && newForm.vehicleId && newForm.vehicleId.length > 0) ? newForm.vehicleId.join(',') : '';
        let accountWeekdayJobWorkingTimeIds = (newForm && newForm.weekdayId && newForm.weekdayId.length > 0) ? newForm.weekdayId.join(',') : '';
        let accountWeekendJobWorkingTimeIds = (newForm && newForm.weekendId && newForm.weekendId.length > 0) ? newForm.weekendId.join(',') : '';

        // let isApplyAllWeekdayTimes = ((newForm.customFormParams.isApplyAllWeekdayTimes === false) || (newForm.customFormParams.isApplyAllWeekdayTimes === true)) ? newForm.customFormParams.isApplyAllWeekdayTimes : false;
        // let isApplyAllWeekendTimes = ((newForm.customFormParams.isApplyAllWeekendTimes === false) || (newForm.customFormParams.isApplyAllWeekendTimes === true)) ? newForm.customFormParams.isApplyAllWeekendTimes : false;

        let weekdaysAvailability = (newForm && newForm.weekdaysAvailability) ? newForm.weekdaysAvailability : CustomerSiteAvailability.All;
        let weekendAvailability = (newForm && newForm.weekendAvailability) ? newForm.weekendAvailability : CustomerSiteAvailability.All;

        // let isDeactivateOrDelete = ((newForm.isDeactivateOrDelete === false) || (newForm.isDeactivateOrDelete === true)) ? !newForm.isDeactivateOrDelete : false;
        // let copySiteRemarksToJob = ((newForm.copySiteRemarksToJob === false) || (newForm.copySiteRemarksToJob === true)) ? newForm.copySiteRemarksToJob : false;

        // let latitude = newForm.latitude;
        // let longitude = newForm.longitude;
        // let coordinates = (newForm && newForm.coordinates && newForm.coordinates != '') ? newForm.coordinates.split(',') : [];
        // latitude = (coordinates && coordinates.length > 0 && coordinates[0]) ? coordinates[0] : newForm.latitude;
        // longitude = (coordinates && coordinates.length > 0 && coordinates[1]) ? coordinates[1] : newForm.longitude;

        let isContactPersonSendNotification = ((newForm.isContactPersonSendNotification === false) || (newForm.isContactPersonSendNotification === true)) ? newForm.isContactPersonSendNotification : false;
        let isContactPersonTwoSendNotification = ((newForm.isContactPersonTwoSendNotification === false) || (newForm.isContactPersonTwoSendNotification === true)) ? newForm.isContactPersonTwoSendNotification : false;

        let data = {
            // customerId: this.state.customerId,
            // customSiteName: newForm.customSiteName,
            // siteName: newForm.siteName,
            // street: newForm.street,
            // blockNo: newForm.blockNo,
            // unitNo: newForm.unitNo,
            // postalCode: newForm.postalCode,
            // latitude: latitude,
            // longitude: longitude,
            // isDeactivateOrDelete: isDeactivateOrDelete,
            remarks: newForm.remarks,
            instructions: newForm.instructions,
            contactPersonName: newForm.contactPersonName,
            contactPersonPhoneNumber: newForm.contactPersonPhoneNumber,
            contactPersonNameTwo: newForm.contactPersonNameTwo,
            contactPersonPhoneNumberTwo: newForm.contactPersonPhoneNumberTwo,
            // isContactPersonSendNotification: isContactPersonSendNotification,
            // isContactPersonTwoSendNotification: isContactPersonTwoSendNotification,
            driverIds: driverIds,
            // vehicleIds: vehicleIds,
            accountWeekdayJobWorkingTimeIds: (weekdaysAvailability === CustomerSiteAvailability.SelectedHours) ? accountWeekdayJobWorkingTimeIds : (weekdaysAvailability === CustomerSiteAvailability.All) ? '' : null,
            accountWeekendJobWorkingTimeIds: (weekendAvailability === CustomerSiteAvailability.SelectedHours) ? accountWeekendJobWorkingTimeIds : (weekendAvailability === CustomerSiteAvailability.All) ? '' : null,
            weekdaysAvailability: weekdaysAvailability,
            weekendAvailability: weekendAvailability,
            // copySiteRemarksToJob: copySiteRemarksToJob,
        };
        
        if(form.zoneId !== 0){
            data['zoneId'] = form.zoneId;
        }

        if (this.state.id != '') {
            data['ids'] = this.state.id;
        }

        return data;
    }
    setSubmit = (form) => {
        if(this.props.onSubmit){
            this.props.onSubmit(form);
        }
    }
    /* END FUNCTIONS */


    /* FORM */
    form = (formOptions) => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = formOptions;
        
        return <>

            <InputLayout
                label={formFields.zoneId.label}
                inputProps={{
                    xs: 12,
                    lg: 7
                }}
            >
                <SmartDropdown
                    isInvalid={!!errors.zoneId}
                    errorText={errors.zoneId}

                    componentTitle={'Zone'}
                    componentApi={'zone'}
                    componentId={'zoneId'}
                    componentName={'zoneName'}
                    componentCreateData={{ zoneColor: '#000000' }}

                    placeholder={formFields.zoneId.placeholder}
                    value={values.zoneId}
                    label={values.zoneName}

                    disabled={false}
                    showDefault={false}
                    isInfiniteScroll={false}

                    onChange={async (value, item, i) => {
                        await setFieldValue('zoneId', value);
                        await setFieldValue('zoneName', item.title);
                    }}
                    onClear={async () => {
                        await setFieldValue('zoneId', null);
                        await setFieldValue('zoneName', '');
                    }}
                />
            </InputLayout>
            
            <InputLayout
                label={formFields.remarks.label}
                inputProps={{
                    xs: 12,
                    lg: 7
                }}
            >
                <Form.Control
                    as="textarea"
                    rows={5}
                    id={formFields.remarks.id}
                    placeholder={formFields.remarks.placeholder}
                    value={values.remarks}
                    onChange={async (e) => {
                        Utils.limitRows(e.target.value, 5, async (value) => {
                            await setFieldValue('remarks', value);
                        });
                    }}
                    isInvalid={!!errors.remarks}
                />
                <Form.Control.Feedback type="invalid">{errors.remarks}</Form.Control.Feedback>
            </InputLayout>

            <InputLayout
                label={formFields.instructions.label}
                inputProps={{
                    xs: 12,
                    lg: 7
                }}
            >
                <Form.Control
                    as="textarea"
                    rows={5}
                    id={formFields.instructions.id}
                    placeholder={formFields.instructions.placeholder}
                    value={values.instructions}
                    onChange={async (e) => {
                        Utils.limitRows(e.target.value, 5, async (value) => {
                            await setFieldValue('instructions', value);
                        });
                    }}
                    isInvalid={!!errors.instructions}
                />
                <Form.Control.Feedback type="invalid">{errors.instructions}</Form.Control.Feedback>
            </InputLayout>

            <InputLayout
                label={formFields.contactPersonName.label}
                inputProps={{
                    xs: 12,
                    lg: 7
                }}
            >
                <Row className={'align-items-center'}>
                    <Col xs={12} md={6}>
                        <Form.Control
                            type="text"
                            id={formFields.contactPersonName.id}
                            placeholder={formFields.contactPersonName.placeholder}
                            value={values.contactPersonName}
                            onChange={handleChange}
                            isInvalid={!!errors.contactPersonName}
                        />
                        <Form.Control.Feedback type="invalid">{errors.contactPersonName}</Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Control
                            type="text"
                            id={formFields.contactPersonPhoneNumber.id}
                            placeholder={formFields.contactPersonPhoneNumber.placeholder}
                            value={values.contactPersonPhoneNumber}
                            onChange={async (e) => {
                                await setFieldValue('contactPersonPhoneNumber', e.target.value);

                                // if(e.target.value == ''){
                                //     await setFieldValue('isContactPersonSendNotification', false);
                                // }
                            }}
                            isInvalid={!!errors.contactPersonPhoneNumber}
                        />
                        <Form.Control.Feedback type="invalid">{errors.contactPersonPhoneNumber}</Form.Control.Feedback>
                    </Col>
                    {/* <Col xs={12} md={'auto'}>
                        <Form.Check
                            id={formFields.isContactPersonSendNotification.id}
                            label={formFields.isContactPersonSendNotification.label}
                            checked={values.isContactPersonSendNotification}
                            disabled={(values.contactPersonPhoneNumber == '')}
                            onChange={async (e) => {
                                await setFieldValue('isContactPersonSendNotification', e.target.checked);
                            }}
                            isInvalid={!!errors.isContactPersonSendNotification}
                            feedback={errors.isContactPersonSendNotification}
                        />
                        <Form.Control.Feedback type="invalid">{errors.isContactPersonSendNotification}</Form.Control.Feedback>
                    </Col> */}
                </Row>
            </InputLayout>

            <InputLayout
                label={formFields.contactPersonNameTwo.label}
                inputProps={{
                    xs: 12,
                    lg: 7
                }}
            >
                <Row className={'align-items-center'}>
                    <Col xs={12} md={6}>
                        <Form.Control
                            type="text"
                            id={formFields.contactPersonNameTwo.id}
                            placeholder={formFields.contactPersonNameTwo.placeholder}
                            value={values.contactPersonNameTwo}
                            onChange={handleChange}
                            isInvalid={!!errors.contactPersonNameTwo}
                        />
                        <Form.Control.Feedback type="invalid">{errors.contactPersonNameTwo}</Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Control
                            type="text"
                            id={formFields.contactPersonPhoneNumberTwo.id}
                            placeholder={formFields.contactPersonPhoneNumberTwo.placeholder}
                            value={values.contactPersonPhoneNumberTwo}
                            onChange={async (e) => {
                                await setFieldValue('contactPersonPhoneNumberTwo', e.target.value);
                                
                                // if(e.target.value == ''){
                                //     await setFieldValue('isContactPersonTwoSendNotification', false);
                                // }
                            }}
                            isInvalid={!!errors.contactPersonPhoneNumberTwo}
                        />
                        <Form.Control.Feedback type="invalid">{errors.contactPersonPhoneNumberTwo}</Form.Control.Feedback>
                    </Col>
                    {/* <Col xs={12} md={'auto'}>
                        <Form.Check
                            id={formFields.isContactPersonTwoSendNotification.id}
                            label={formFields.isContactPersonTwoSendNotification.label}
                            checked={values.isContactPersonTwoSendNotification}
                            disabled={(values.contactPersonPhoneNumberTwo == '')}
                            onChange={async (e) => {
                                await setFieldValue('isContactPersonTwoSendNotification', e.target.checked);
                            }}
                            isInvalid={!!errors.isContactPersonTwoSendNotification}
                            feedback={errors.isContactPersonTwoSendNotification}
                        />
                        <Form.Control.Feedback type="invalid">{errors.isContactPersonTwoSendNotification}</Form.Control.Feedback>
                    </Col> */}
                </Row>
            </InputLayout>

            <InputLayout
                label={formFields.driverId.label}
                inputProps={{
                    xs: 12,
                    lg: 7
                }}
            >
                <Form.Control
                    as = {RSuiteCheckPicker}
                    isInvalid={!!errors.driverId}
                    
                    id={formFields.driverId.id}
                    placeholder={formFields.driverId.placeholder}
                    items={values.customFormParams.driverItems}
                    value={values.driverId}
                    isSelectedAll={false}
                    isLoading={values.customFormParams.driverIsLoading}
                    searchable={true}
                    countable={true}
                    cleanable={true}
                    showSelectAll={false}
                    isDefault={true}
                    onClick={async () => {
                        await setFieldValue('customFormParams.driverIsLoading', true);
                        this.callReadDriverApi(values.customFormParams.driverItems, async (items) => {
                            await setFieldValue('customFormParams.driverItems', items);
                            await setFieldValue('customFormParams.driverIsLoading', false);
                        });
                    }}
                    onChange={async (values, items) => {
                        await setFieldValue('driverId', values);
                    }}
                    onClear={async () => {
                        await setFieldValue('driverId', []);
                    }}
                />
                <Form.Control.Feedback type="invalid">{errors.driverId}</Form.Control.Feedback>
            </InputLayout>
            {/* <InputLayout
                label={formFields.vehicleId.label}
                inputProps={{
                    xs: 12,
                    lg: 7
                }}
            >
                <Form.Control
                    as = {RSuiteCheckPicker}
                    isInvalid={!!errors.vehicleId}
                    
                    id={formFields.vehicleId.id}
                    placeholder={formFields.vehicleId.placeholder}
                    items={values.customFormParams.vehicleItems}
                    value={values.vehicleId}
                    isSelectedAll={false}
                    isLoading={values.customFormParams.vehicleIsLoading}
                    searchable={true}
                    countable={true}
                    cleanable={true}
                    showSelectAll={false}
                    isDefault={true}
                    onClick={async () => {
                        await setFieldValue('customFormParams.vehicleIsLoading', true);
                        this.callReadVehicleApi(values.customFormParams.vehicleItems, async (items) => {
                            await setFieldValue('customFormParams.vehicleItems', items);
                            await setFieldValue('customFormParams.vehicleIsLoading', false);
                        });
                    }}
                    onChange={async (values, items) => {
                        await setFieldValue('vehicleId', values);
                    }}
                    onClear={async () => {
                        await setFieldValue('vehicleId', []);
                    }}
                />
                <Form.Control.Feedback type="invalid">{errors.vehicleId}</Form.Control.Feedback>
            </InputLayout> */}

            <InputLayout
                label={formFields.weekdayId.label}
                inputProps={{
                    xs: 12,
                    lg: 7
                }}
            >
                <div className={'pt-3'}>
                    <Form.Check
                        inline
                        type={'radio'}
                        id={formFields.weekdayId.id + '_1'}
                        label={'All'}
                        checked={values.weekdaysAvailability === CustomerSiteAvailability.All}
                        onChange={async (e) => {
                            await setFieldValue('weekdaysAvailability', CustomerSiteAvailability.All);
                        }}
                    />
                    <Form.Check
                        inline
                        type={'radio'}
                        id={formFields.weekdayId.id + '_2'}
                        label={'Selected hours'}
                        checked={values.weekdaysAvailability === CustomerSiteAvailability.SelectedHours}
                        onChange={async (e) => {
                            await setFieldValue('weekdaysAvailability', CustomerSiteAvailability.SelectedHours);
                        }}
                    />
                    <Form.Check
                        inline
                        type={'radio'}
                        id={formFields.weekdayId.id + '_3'}
                        label={'Not Available'}
                        checked={values.weekdaysAvailability === CustomerSiteAvailability.NotAvailable}
                        onChange={async (e) => {
                            await setFieldValue('weekdaysAvailability', CustomerSiteAvailability.NotAvailable);
                        }}
                    />
                </div>
            </InputLayout>
            {(values.weekdaysAvailability === CustomerSiteAvailability.SelectedHours) && <InputLayout
                label={<>&nbsp;</>}
                inputProps={{
                    xs: 12,
                    lg: 7
                }}
            >
                <Form.Control
                        as = {RSuiteCheckPicker}
                        isInvalid={!!errors.weekdayId}
                        
                        id={formFields.weekdayId.id}
                        placeholder={formFields.weekdayId.placeholder}
                        items={values.customFormParams.weekdayItems}
                        value={values.weekdayId}
                        isSelectedAll={values.customFormParams.weekdayIsSelectAll}
                        isLoading={values.customFormParams.weekdayIsLoading}
                        searchable={true}
                        countable={true}
                        cleanable={true}
                        showSelectAll={true}
                        isDefault={true}
                        placement={'topStart'}
                        onClick={async () => {
                            await setFieldValue('customFormParams.weekdayIsLoading', true);
                            this.callReadWeekdayTimesApi(values.customFormParams.weekdayItems, async (items) => {
                                await setFieldValue('customFormParams.weekdayItems', items);
                                await setFieldValue('customFormParams.weekdayIsLoading', false);
                            });
                        }}
                        onSelectAll={async (checked, isIndeterminate) => {
                            if(checked){
                                await setFieldValue('weekdayId', values.customFormParams.weekdayItems.map(x => x.value));
                                await setFieldValue('customFormParams.weekdayIsSelectAll', true);
                            } else {
                                await setFieldValue('weekdayId', []);
                                await setFieldValue('customFormParams.weekdayIsSelectAll', false);
                            }
                        }}
                        onChange={async (values, items) => {
                            await setFieldValue('weekdayId', values);
                            
                            if(items.length > 0){
                                await setFieldValue('customFormParams.weekdayIsSelectAll', (values.length === items.length));
                            } else {
                                await setFieldValue('customFormParams.weekdayIsSelectAll', false);
                            }
                        }}
                        onClear={async () => {
                            await setFieldValue('weekdayId', []);
                            await setFieldValue('customFormParams.weekdayIsSelectAll', false);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.weekdayId}</Form.Control.Feedback>
            </InputLayout>}
            
            <InputLayout
                label={formFields.weekendId.label}
                inputProps={{
                    xs: 12,
                    lg: 7
                }}
            >
                <div className={'pt-3'}>
                    <Form.Check
                        inline
                        type={'radio'}
                        id={formFields.weekendId.id + '_1'}
                        label={'All'}
                        checked={values.weekendAvailability === CustomerSiteAvailability.All}
                        onChange={async (e) => {
                            await setFieldValue('weekendAvailability', CustomerSiteAvailability.All);
                        }}
                    />
                    <Form.Check
                        inline
                        type={'radio'}
                        id={formFields.weekendId.id + '_2'}
                        label={'Selected hours'}
                        checked={values.weekendAvailability === CustomerSiteAvailability.SelectedHours}
                        onChange={async (e) => {
                            await setFieldValue('weekendAvailability', CustomerSiteAvailability.SelectedHours);
                        }}
                    />
                    <Form.Check
                        inline
                        type={'radio'}
                        id={formFields.weekendId.id + '_3'}
                        label={'Not Available'}
                        checked={values.weekendAvailability === CustomerSiteAvailability.NotAvailable}
                        onChange={async (e) => {
                            await setFieldValue('weekendAvailability', CustomerSiteAvailability.NotAvailable);
                        }}
                    />
                </div>
            </InputLayout>
            {(values.weekendAvailability === CustomerSiteAvailability.SelectedHours) && <InputLayout
                label={<>&nbsp;</>}
                inputProps={{
                    xs: 12,
                    lg: 7
                }}
            >
                <Form.Control
                        as = {RSuiteCheckPicker}
                        isInvalid={!!errors.weekendId}
                        
                        id={formFields.weekendId.id}
                        placeholder={formFields.weekendId.placeholder}
                        items={values.customFormParams.weekendItems}
                        value={values.weekendId}
                        isSelectedAll={values.customFormParams.weekendIsSelectAll}
                        isLoading={values.customFormParams.weekendIsLoading}
                        searchable={true}
                        countable={true}
                        cleanable={true}
                        showSelectAll={true}
                        isDefault={true}
                        placement={'topStart'}
                        onClick={async () => {
                            await setFieldValue('customFormParams.weekendIsLoading', true);
                            this.callReadWeekendTimesApi(values.customFormParams.weekendItems, async (items) => {
                                await setFieldValue('customFormParams.weekendItems', items);
                                await setFieldValue('customFormParams.weekendIsLoading', false);
                            });
                        }}
                        onSelectAll={async (checked, isIndeterminate) => {
                            if(checked){
                                await setFieldValue('weekendId', values.customFormParams.weekendItems.map(x => x.value));
                                await setFieldValue('customFormParams.weekendIsSelectAll', true);
                            } else {
                                await setFieldValue('weekendId', []);
                                await setFieldValue('customFormParams.weekendIsSelectAll', false);
                            }
                        }}
                        onChange={async (values, items) => {
                            await setFieldValue('weekendId', values);
                            
                            if(items.length > 0){
                                await setFieldValue('customFormParams.weekendIsSelectAll', (values.length === items.length));
                            } else {
                                await setFieldValue('customFormParams.weekendIsSelectAll', false);
                            }
                        }}
                        onClear={async () => {
                            await setFieldValue('weekendId', []);
                            await setFieldValue('customFormParams.weekendIsSelectAll', false);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.weekendId}</Form.Control.Feedback>
            </InputLayout>}

        </>
    }
    /* END FORM */


    render() {
        return <Formik
            innerRef={this.refForm}
            validationSchema={formSchema}
            initialValues={this.state.initialValues}
            enableReinitialize={true}
            validateOnMount={false}
            validateOnChange={false}
            onSubmit={(form, e) => {
                this.setLoading(true);
                let data = this.prepareData(form);
                this.setSubmit(data);
            }}
        >
            {(formOptions) => {
                return <Form className='w-100 p-5' noValidate autoComplete="off" onSubmit={formOptions.handleSubmit} onKeyDown={(keyEvent) => {
                    // if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                    //     keyEvent.preventDefault();
                    // }
                }}>
                    <Row>
                        <Col xs={12} className={'ps-2 pe-2'}>{this.form(formOptions)}</Col>
                    </Row>
                </Form>
            }}
        </Formik>
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  dispatchApiCallPost,
  dispatchApiCallPut,
  dispatchApiCallDelete,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(BulkUpdateCustomerSiteForm);
