/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Yup from 'yup';
import _ from 'lodash';
import moment from 'moment';

import * as dispatchCrudApi from '../../../api/CRUD/DispatchCRUD'
import Utils from "../../../utils/utils";
// import {  } from "../../../utils/enums";

import { ParseResult } from "../../../utils/interfaces";


export const fields = {
  driverId: {
    id: 'driverId',
    label: 'Driver',
    placeholder: 'Assign here or leave blank if no change',
  },
  vehicleId: {
    id: 'vehicleId',
    label: 'Vehicle',
    placeholder: 'Assign here or leave blank if no change',
  },
  jobDate: {
    id: 'jobDate',
    label: 'Job date',
    placeholder: 'Assign here or leave blank if no change',
  },
  jobTimeSpecific: {
    id: 'jobTimeSpecific',
    label: 'Job time',
    placeholder: 'Assign here or leave blank if no change',
  },
};

export const formSchema = () => {
  return Yup.object().shape({
    driverId: Yup.number().nullable().label(fields.driverId.label),
    vehicleId: Yup.number().nullable().label(fields.vehicleId.label),
    jobDate: Yup.date().nullable().label(fields.jobDate.label),
    jobTimeSpecific: Yup.date().nullable().label(fields.jobTimeSpecific.label),
  })
}

export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    const formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const prepareForm = (values: any = null, defValues: any = null) => {
  let form = _.cloneDeep(values);
  let data = _.cloneDeep(defValues);
  
  if(data && form ){
    let driverId = (form.driverId && form.driverId !== '') ? form.driverId : '';
    let driverName = (form.driverName && form.driverName !== '') ? form.driverName : '';

    let vehicleId = (form && form.vehicleId) ? form.vehicleId : null;
    let vehicleName = (form && form.vehicleName) ? form.vehicleName : '';

    let jobDate = (form.jobDate) ? moment(form.jobDate).toDate() : null;
    let jobTimeSpecific = (form.jobTimeSpecific) ? moment(form.jobTimeSpecific).toDate() : null;
    
    
    data['driverId'] = driverId;
    data['driverName'] = driverName;

    data['vehicleId'] = vehicleId;
    data['vehicleName'] = vehicleName;

    data['jobDate'] = jobDate;
    data['jobTimeSpecific'] = jobTimeSpecific;
  }
  
  return data;
};
export const prepareData = (values: any = null, ids: string, hubId: string) => {
  let data: any = {};

  if(values){
    data['hubId'] = hubId;
    data['ids'] = ids;

    data['driverId'] = values.driverId;
    data['driverName'] = values.driverName;
    
    data['vehicleId'] = values.vehicleId;
    data['vehicleName'] = values.vehicleName;

    data['jobDate'] = values.jobDate ? Utils.getLocalIsoDateTime(values.jobDate) : null;
    data['jobTimeSpecific'] = values.jobTimeSpecific ? Utils.getLocalIsoDateTime(values.jobTimeSpecific) : null;
  }

  return data;
};


export interface InitialValuesStruct {
  driverId: number|null,
  driverName: string,
  vehicleId: number|null,
  vehicleName: string,
  jobDate: Date|null,
  jobTimeSpecific: Date|null,
};
export const initialValues: InitialValuesStruct = {
  driverId: null,
  driverName: '',
  vehicleId: null,
  vehicleName: '',
  jobDate: null,
  jobTimeSpecific: null,
};


export interface ApiCallStruct {
  ids: string,
  jobDate: string|null,
  jobTimeSpecific: string|null,
  driverId: number|null,
  vehicleId: number|null,
  hubId: string|null,
};


interface InitState {
  isLoading: boolean,
  show: boolean,
  ids: any|null,
}

function NewReducer() {
  const name = 'bulkUpdateJobForm';


  const initialState: InitState = {
    isLoading: false,
    show: false,
    ids: null,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },

    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<any|null>) => {
      state.isLoading = false;
      state.ids = action.payload;
      state.show = true;
    },
    setHide: (state: InitState, action: PayloadAction<any|null>) => {
      state.show = false;
      state.ids = null;
      state.isLoading = false;
    },
    
    startBulkUpdate: (state: InitState) => {
      state.isLoading = true;
    },
    finishBulkUpdate: (state: InitState) => {
      state.isLoading = false;
      state.show = false;
    },
  };


  const apis = {
    callBulkUpdateApi: (params: ApiCallStruct, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startBulkUpdate());

      await dispatchCrudApi.updateApi(params, 'job/bulk').then(result => {
        let data = result.data.data;
        
        callback(true, data);
        dispatch(actions.finishBulkUpdate());
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }

        callback(false, null);
        dispatch(actions.finishBulkUpdate());
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();