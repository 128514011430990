/* eslint-disable no-loop-func */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import Utils from "../../../utils/utils";
import { PageCookieSettingType } from '../../../utils/enums'

// import * as dispatchCrudApi from '../../../api/CRUD/DispatchCRUD'
// import { ParseResult } from "../../../utils/interfaces";


interface InitState {
  isLive: boolean,
  items: Array<any>,

  value: number,

  nextRefresh: any,
  timeLeft: any,
}


function NewReducer() {
  const name = 'binCenterLive';


  const defaultIndex = 2;
  const items = [ 
    {
      value: 0,
      title: 'None',
    },
    {
      value: ((60 * 1000) * 1),
      title: 'Every 1 minute',
    },
    {
      value: ((60 * 1000) * 5),
      title: 'Every 5 minute',
    },
    {
      value: ((60 * 1000) * 15),
      title: 'Every 15 minute',
    },
    {
      value: ((60 * 1000) * 30),
      title: 'Every 30 minute',
    }
  ]


  const initialState: InitState = {
    items: items,
    isLive: false,

    value: items[defaultIndex].value,

    nextRefresh: null,
    timeLeft: null,
  };


  const reducers = {
    setLive: (state: InitState, action: PayloadAction<any>) => {
      state.isLive = action.payload;

      if(action.payload){
        state.nextRefresh = (state.value > 0) ? new Date(new Date().getTime() + state.value) : null;
        state.timeLeft = (state.nextRefresh > 0) ? Math.floor((state.nextRefresh - Date.now()) / 1000) : null;
      }
    },

    getCookies: (state: InitState, action: PayloadAction<{ auth: any, settingsType: any, pageName: any }>) => {
      const auth = (action.payload.auth) ? action.payload.auth : null;
      const settingsType = (action.payload.settingsType) ? action.payload.settingsType : null;
      const pageName = (action.payload.pageName) ? action.payload.pageName : null;

      let settingsItem = null;
      if(settingsType){
        const { userCookieSettings } = auth;
        settingsItem = Utils.getUserCookieSettings(userCookieSettings, settingsType, pageName);
      }

      let value = defaultIndex;
      try {
        value = settingsItem ? settingsItem[PageCookieSettingType.realTimeUpdate] : defaultIndex;
      } catch(e){}
      
      const item = state.items.find(x => x.value === value);
      state.value = item ? item.value : items[defaultIndex].value;

      state.nextRefresh = (state.value > 0) ? new Date(new Date().getTime() + state.value) : null;
      state.timeLeft = (state.nextRefresh > 0) ? Math.floor((state.nextRefresh - Date.now()) / 1000) : null;
    },

    selectedValue: (state: InitState, action: PayloadAction<{ auth: any, settingsType: any, pageName: any, item: any }>) => {
      const item = (action.payload.item) ? action.payload.item : null;

      state.timeLeft = null;
      state.value = item ? item.value : items[defaultIndex].value;

      state.nextRefresh = (state.value > 0) ? new Date(new Date().getTime() + state.value) : null;
      state.timeLeft = (state.nextRefresh > 0) ? Math.floor((state.nextRefresh - Date.now()) / 1000) : null;


      const auth = (action.payload.auth) ? action.payload.auth : null;
      const settingsType = (action.payload.settingsType) ? action.payload.settingsType : null;
      const pageName = (action.payload.pageName) ? action.payload.pageName : null;

      let data: any = {
        name: pageName,
        type: settingsType,
      };

      if(settingsType){
        let { token, userCookieSettings } = auth;
  
        let settings = null;
        let settingsArr = (userCookieSettings && userCookieSettings.length > 0) ? userCookieSettings : [];
        let settingsIndex = Utils.getUserCookieSettingsIndex(settingsArr, settingsType);
        if(settingsIndex !== -1 && settingsArr[settingsIndex]){
          settings = settingsArr[settingsIndex];
        }

        if(settings){
          let oldValue = JSON.parse(settings.value);
          let value = Object.assign({}, oldValue);
          value[PageCookieSettingType.realTimeUpdate] = state.value;
          data['value'] = JSON.stringify(value);
        } else {
          let value = {
            [PageCookieSettingType.realTimeUpdate]: state.value,
          };
          data['value'] = JSON.stringify(value);
        }

        Utils.saveUserCookieSettings(data, token);
      }
    },
    updateTimeLeft: (state: InitState) => {
      if(state.isLive){
        state.timeLeft = (state.nextRefresh > 0) ? Math.floor((state.nextRefresh - Date.now()) / 1000) : null;
      }
    },
    resetTimeLeft: (state: InitState) => {
      state.nextRefresh = (state.value > 0) ? new Date(new Date().getTime() + state.value) : null;
      state.timeLeft = (state.nextRefresh > 0) ? Math.floor((state.nextRefresh - Date.now()) / 1000) : null;
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
  };
}


export default NewReducer();