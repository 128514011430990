import React from "react";
import moment from "moment";

import { 
  Button,
  Row,
  Col,
  Form,
} from "react-bootstrap-v5";

import Utils from "../../../utils/utils";
import { AuditTrailObjectType, AuditTrailObjectTypeText } from "../../../utils/enums";

import RSuiteDateRangePickerCostume from "../../../components/OLD/Datepicker/RSuiteDateRangePickerCostume";

class ViewTopSection extends React.Component {  
  constructor(props) {
    super(props);
    
    this.state = {
      thisDate: moment().format(Utils.getAPIDateFormat()),
      searchQuery: '',
      objectType: '',
    }
  }


  datePicker() {
    return <div className={'date-range-picker'}>
      <RSuiteDateRangePickerCostume
        className={''}
        placeholder={"Select date"}
        ranges={Utils.getDateRanges(['Last 6 Months'])}
        value={this.props.dateRange}
        cleanable={false}
        renderValue={(value, format) => {
          if( moment(value[0]).format(Utils.getDefaultDateRangeFormat())  === moment(value[1]).format(Utils.getDefaultDateRangeFormat())  ){
            let date = moment(value[0]).format(Utils.getDefaultDateRangeFormat()) 
          
            return (
              <div pl={1}>
                <span className="dateTicker">{date}</span>
              </div>
            );
          } else {
            let date = moment(value[0]).format(Utils.getDefaultDateRangeFormat()) + " to " + moment(value[1]).format(Utils.getDefaultDateRangeFormat());

            return (
              <div pl={1}>
                <span className="dateTicker">{date}</span>
              </div>
            );
          }
        }}
        onChange={(value) => {
          let format = Utils.getAPIDateFormat();
          let fromDate = moment(value[0]).format(format);
          let toDate = moment(value[1]).format(format);

          if(this.props.onDateChange){
            this.props.onDateChange(fromDate, toDate, value);
          }
        }}
      />
    </div>
  }

  setButtonArrow() {
    return (
      <div className="button-arrow">
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            let dateFrom = moment(this.props.fromDate, Utils.getAPIDateFormat()).subtract(1, "days").format();
            let toDate = moment(this.props.toDate, Utils.getAPIDateFormat()).subtract(1, "days").format();

            if(this.props.onDateChange){
              this.props.onDateChange(dateFrom, toDate, [new Date(dateFrom), new Date(toDate)]);
            }
          }}
        >
          <i color="blue" className="fas fa-arrow-left"></i>
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            let dateFrom = moment(this.props.fromDate, Utils.getAPIDateFormat()).add(1, "days").format();
            let toDate = moment(this.props.toDate, Utils.getAPIDateFormat()).add(1, "days").format();

            if(this.props.onDateChange){
              this.props.onDateChange(dateFrom, toDate, [new Date(dateFrom), new Date(toDate)]);
            }
          }}
        >
          <i className="fas fa-arrow-right"></i>
        </Button>
      </div>
    );
  }

  render() {
    return <Row className="view-top-section-layout align-items-center">
      <Col xs={12} md={12} lg={12} xl={'auto'} className={'mb-2 mb-md-0'}>
        <h1 className={'title mx-0 mb-0'}>AUDIT TRAIL</h1>
      </Col>
      <Col xs={12} md={'auto'} className={'mb-2 mb-md-0'}>
        {this.setButtonArrow()}
      </Col>
      <Col xs={12} sm={true} lg={3} xl={2} className={'mb-2 mb-md-0'}>
        {this.datePicker()}
      </Col>
      <Col xs={12} sm={'auto'} md={'auto'} className={'mb-2 mb-md-0'}>
        <Button
          className={"btn-today"}
          variant={"outlined"}
          color={"primary"}
          onClick={() => {
            if(this.props.onDateChange){
              this.props.onDateChange(this.state.thisDate, this.state.thisDate, [new Date(this.state.thisDate), new Date(this.state.thisDate)]);
            }
          }}
        >
          TODAY
        </Button>
      </Col>

      <Col xs={12} lg={true} className={'pb-2 pb-lg-0 d-block d-lg-none d-xl-block'}></Col>
      
      <Col xs={12} md={'auto'} className={'pb-2 pb-lg-0'}>
        <Form.Control
            type="text"
            id={'audittrailusersearch1ID'}
            placeholder={'Search'}
            value={this.state.searchQuery}
            onChange={(e) => {
                this.setState({
                    searchQuery: e.target.value
                });
            }}
            onKeyDown={(e) => {
              if ((e.charCode || e.keyCode) === 13) {
                e.preventDefault();
                e.stopPropagation();

                if(this.props.onChangeFilters){
                  this.props.onChangeFilters(this.state.searchQuery, this.state.objectType);
                }
              }
            }}
        />
    </Col>
    <Col xs={12} sm={true} lg={2} xl={2} className={'pb-2 pb-lg-0'}>
        <Form.Select
            value={this.state.objectType}
            onChange={(e) => {
                this.setState({
                    objectType: e.target.value
                });
            }}
        >
            <option value={''}>All</option>
            <option value={AuditTrailObjectType.Customer}>{AuditTrailObjectTypeText[AuditTrailObjectType.Customer]}</option>
            <option value={AuditTrailObjectType.CustomerSite}>{AuditTrailObjectTypeText[AuditTrailObjectType.CustomerSite]}</option>
            {/* <option value={AuditTrailObjectType.Service}>{AuditTrailObjectTypeText[AuditTrailObjectType.Service]}</option> */}
            <option value={AuditTrailObjectType.ServiceCharge}>{AuditTrailObjectTypeText[AuditTrailObjectType.ServiceCharge]}</option>
            <option value={AuditTrailObjectType.AdditionalServiceCharge}>{AuditTrailObjectTypeText[AuditTrailObjectType.AdditionalServiceCharge]}</option>
            <option value={AuditTrailObjectType.JobTemplate}>{AuditTrailObjectTypeText[AuditTrailObjectType.JobTemplate]}</option>
            <option value={AuditTrailObjectType.RecurringJob}>{AuditTrailObjectTypeText[AuditTrailObjectType.RecurringJob]}</option>
            <option value={AuditTrailObjectType.AccountBillingProfile}>{AuditTrailObjectTypeText[AuditTrailObjectType.AccountBillingProfile]}</option>
            <option value={AuditTrailObjectType.BinType}>{AuditTrailObjectTypeText[AuditTrailObjectType.BinType]}</option>
            <option value={AuditTrailObjectType.BinNumber}>{AuditTrailObjectTypeText[AuditTrailObjectType.BinNumber]}</option>
            <option value={AuditTrailObjectType.WasteType}>{AuditTrailObjectTypeText[AuditTrailObjectType.WasteType]}</option>
            <option value={AuditTrailObjectType.Driver}>{AuditTrailObjectTypeText[AuditTrailObjectType.Driver]}</option>
            <option value={AuditTrailObjectType.Vehicle}>{AuditTrailObjectTypeText[AuditTrailObjectType.Vehicle]}</option>
            <option value={AuditTrailObjectType.Payroll}>{AuditTrailObjectTypeText[AuditTrailObjectType.Payroll]}</option>
            <option value={AuditTrailObjectType.AccountReminder}>{AuditTrailObjectTypeText[AuditTrailObjectType.AccountReminder]}</option>
            <option value={AuditTrailObjectType.AccountJobWorkingTime}>{AuditTrailObjectTypeText[AuditTrailObjectType.AccountJobWorkingTime]}</option>
            <option value={AuditTrailObjectType.Job}>{AuditTrailObjectTypeText[AuditTrailObjectType.Job]}</option>
            <option value={AuditTrailObjectType.DriverDetailsMonthly}>{AuditTrailObjectTypeText[AuditTrailObjectType.DriverDetailsMonthly]}</option>
        </Form.Select>
    </Col>
    <Col xs={12} sm={'auto'} md={'auto'} className={'pb-2 pb-lg-0'}>
        <Button 
            variant={'primary'}
            onClick={() => {
                if(this.props.onChangeFilters){
                    this.props.onChangeFilters(this.state.searchQuery, this.state.objectType);
                }
            }}
        >
            Search
        </Button>
      </Col>
    </Row>
  }
}

export default ViewTopSection;
