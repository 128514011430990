import React, { Component } from "react";

import Utils from "../../../utils/utils";

import { Box, Grid, IconButton } from "@material-ui/core";

import Icon from "@material-ui/core/Icon";

import CustomDialog from "../Dialog/CustomDialog.jsx";
import DataTableRemoveDialog from "../DataTable/DataTableRemoveDialog.jsx";
import Snackbar from "../../OLD/Snackbar/Snackbar.jsx";

class GridTable extends Component {
  constructor(props) {
    super(props);

    let className = props.className ? props.className : "";
    let items = props.items ? props.items : [];
    let required = props.required ? props.required : [];
    let duplicated = props.duplicated ? props.duplicated : [];
    let struct = props.struct ? props.struct : null;
    let noResult = props.noResult ? props.noResult : "No Result!";
    let removeMessage = props.removeMessage
      ? props.removeMessage
      : "Are you sure?";

    let headerRow = props.headerRow ? props.headerRow : null;
    let itemsRow = props.itemsRow ? props.itemsRow : null;
    let emptyRow = props.emptyRow ? props.emptyRow : null;
    let footerRow = props.footerRow ? props.footerRow : null;

    let hideIconsAtEmptyRow =
      props.hideIconsAtEmptyRow === false || props.hideIconsAtEmptyRow === true
        ? props.hideIconsAtEmptyRow
        : false;
    let disabled =
      props.disabled === true || props.disabled === false
        ? props.disabled
        : false;

    let emptyStruct = Utils.copyObj(struct);

    this.state = {
      className: className,
      items: items,
      required: required,
      duplicated: duplicated,
      struct: struct,
      emptyStruct: emptyStruct,
      noResult: noResult,

      removeTitle: "REMOVE",
      removeMessage: removeMessage,
      removeNoBtn: "No",

      isRemoveDialog: false,
      removeRow: null,
      removeRowIndex: null,

      headerRow: headerRow,
      itemsRow: itemsRow,
      emptyRow: emptyRow,
      footerRow: footerRow,

      hideIconsAtEmptyRow: hideIconsAtEmptyRow,
      disabled: disabled,

      showError: false,
      errorText: "",
      errorSeverity: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    // if (!apiUtil.isEqual(nextProps.items, this.state.items)) {
    this.setState({
      items: nextProps.items,
    });
    // }
    if (nextProps.headerRow !== this.state.headerRow) {
      this.setState({
        headerRow: nextProps.headerRow,
      });
    }
    if (nextProps.itemsRow !== this.state.itemsRow) {
      this.setState({
        itemsRow: nextProps.itemsRow,
      });
    }
    if (nextProps.emptyRow !== this.state.emptyRow) {
      this.setState({
        emptyRow: nextProps.emptyRow,
      });
    }
    if (nextProps.footerRow !== this.state.footerRow) {
      this.setState({
        footerRow: nextProps.footerRow,
      });
    }

    if (nextProps.disabled !== this.state.disabled) {
      let disabled =
        nextProps.disabled === true || nextProps.disabled === false
          ? nextProps.disabled
          : false;
      this.setState({
        disabled: disabled,
      });
    }

    if (nextProps.hideIconsAtEmptyRow !== this.state.hideIconsAtEmptyRow) {
      let hideIconsAtEmptyRow =
        nextProps.hideIconsAtEmptyRow === false ||
        nextProps.hideIconsAtEmptyRow === true
          ? nextProps.hideIconsAtEmptyRow
          : false;
      this.setState({
        hideIconsAtEmptyRow: hideIconsAtEmptyRow,
      });
    }
  }

  hideIconsAtEmptyRow = (hideIconsAtEmptyRow = true) => {
    this.setState({
      hideIconsAtEmptyRow: hideIconsAtEmptyRow,
    });
  };

  addRow = () => {
    if (this.props.onAddRow) {
      if (this.isValid()) {
        if (!this.hasDuplicated()) {
          let item = this.state.struct;
          let items = this.add();

          this.setState(
            {
              struct: Utils.copyObj(this.state.emptyStruct),
            },
            () => {
              this.props.onAddRow(items, item, this);
            }
          );
        } else {
          this.showError("Unable to enter duplicates!");
        }
      }
    }
  };
  removeRow = (item, i) => {
    this.hideRemoveDialog(() => {
      let items = this.remove(item, i);

      if (this.props.onRemoveRow) {
        this.props.onRemoveRow(items, item, i, this);
      }
    });
  };

  getItems = () => {
    return this.state.items;
  };

  refresh = () => {
    this.setState({
      items: this.state.items,
      footerRow: this.props.footerRow,
    });
  };

  clearEmptyRow = () => {
    this.setState({
      struct: Utils.copyObj(this.state.emptyStruct),
    });
  };

  isValid = () => {
    let state = true;

    let struct = this.state.struct;

    if (this.state.required && this.state.required.length > 0) {
      for (let i = 0; i < this.state.required.length; i++) {
        if (struct[this.state.required[i].field] === "") {
          this.showError(
            "You must choose " + this.state.required[i].text + "!"
          );
          struct[this.state.required[i].field + "Validate"] = true;
          state = false;
        }
      }
    }

    if (!state) {
      this.setState({
        struct: struct,
      });
    }

    return state;
  };
  hasDuplicated = () => {
    let fields = [];
    let values = [];

    if (
      this.state.struct &&
      this.state.duplicated &&
      this.state.duplicated.length > 0
    ) {
      for (let i = 0; i < this.state.duplicated.length; i++) {
        fields.push(this.state.duplicated[i]);
        values.push(this.state.struct[this.state.duplicated[i]]);
      }

      if (fields && fields.length > 0 && values && values.length > 0) {
        return Utils.hasDuplicated(this.state.items, fields, values);
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  hasEmpty = () => {
    let required = [];
    if (this.state.required && this.state.required.length > 0) {
      for (let i = 0; i < this.state.required.length; i++) {
        required.push(this.state.required[i].field);
      }
    }

    let items = this.state.items;
    if (items && items.length > 0) {
      return Utils.isEmptyRow(items[items.length - 1], required);
    } else {
      return false;
    }
  };

  showError = (error = "") => {
    this.setState({
      showError: true,
      errorText: error,
      errorSeverity: "error",
    });
  };

  add = () => {
    let items = this.state.items;

    items.push(this.state.struct);

    return items;
  };
  remove = (item, i) => {
    let items = this.state.items;

    if (items && items.length > 0) {
      if (i > -1) {
        items.splice(i, 1);
      }
    }

    return items;
  };

  addEmptyRow = (item = null) => {
    let items = this.state.items;

    if (!this.hasEmpty()) {
      items.push(Utils.copyObj(item ? item : this.state.emptyStruct));
    } else {
      this.showError("You must fill the empty fields!");
    }

    return items;
  };
  clearInside = (item = null) => {
    let items = this.state.items;
    this.addEmptyRow();
    items.pop();
  };

  showRemoveDialog = (item, i) => {
    this.setState({
      isRemoveDialog: true,
      removeRow: item,
      removeRowIndex: i,
    });
  };
  hideRemoveDialog = (callback = null) => {
    this.setState(
      {
        isRemoveDialog: false,
        removeRow: null,
        removeRowIndex: null,
      },
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  setHeader = () => {
    if (this.state.headerRow) {
      return (
        <Grid container alignItems={"center"} className={"header-row"}>
          <Box clone>
            <Grid item xs={12} md={true} className={"header-row-custom"}>
              {this.state.headerRow}
            </Grid>
          </Box>
          <Box clone textAlign={"center"}>
            <Grid
              item
              xs={12}
              md={"auto"}
              className={"header-row-custom-right"}
            >
              &nbsp;
            </Grid>
          </Box>
        </Grid>
      );
    }
  };

  setEmpty = () => {
    if (this.state.emptyRow && this.state.struct) {
      return (
        <Grid
          key={"empty_row"}
          container
          alignItems={"center"}
          className={"empty-row"}
        >
          <Box clone>
            <Grid item xs={12} md={true} className={"empty-row-custom"}>
              {this.state.emptyRow(this.state.struct, this)}
            </Grid>
          </Box>
          {!this.state.hideIconsAtEmptyRow && (
            <Box clone textAlign={"center"}>
              <Grid
                item
                xs={12}
                md={"auto"}
                className={"empty-row-custom-right"}
              >
                {!this.state.disabled && (
                  <IconButton
                    className="ok-icon"
                    onClick={() => {
                      this.addRow();
                    }}
                  >
                    <Icon component={"i"}>check_circle</Icon>
                  </IconButton>
                )}

                {!this.state.disabled && (
                  <IconButton
                    className="cancel-icon"
                    onClick={() => {
                      this.clearEmptyRow();
                      this.refresh();
                    }}
                  >
                    <Icon component={"i"}>cancel-icon</Icon>
                  </IconButton>
                )}
              </Grid>
            </Box>
          )}
        </Grid>
      );
    }
  };

  setFooter = () => {
    if (this.state.footerRow) {
      return (
        <Grid container alignItems={"center"} className={"footer-row"}>
          <Box clone>
            <Grid item xs={12} md={true} className={"footer-row-custom"}>
              {this.state.footerRow(this.state.items, this)}
            </Grid>
          </Box>
          <Box clone textAlign={"center"}>
            <Grid
              item
              xs={12}
              md={"auto"}
              className={"footer-row-custom-right"}
            >
              &nbsp;
            </Grid>
          </Box>
        </Grid>
      );
    }
  };

  setItems = () => {
    if (
      this.state.itemsRow &&
      this.state.items &&
      this.state.items.length > 0
    ) {
      let rows = this.state.items.map((item, i) => {
        return (
          <Grid container key={i} alignItems={"center"} className={"items-row"}>
            <Box clone>
              <Grid item xs={12} md={true} className={"items-row-custom"}>
                {this.state.itemsRow(item, i, this)}
              </Grid>
            </Box>
            <Box clone textAlign={"center"}>
              <Grid
                item
                xs={12}
                md={"auto"}
                className={"items-row-custom-right"}
              >
                {!this.state.disabled && (
                  <IconButton
                    className="remove-icon"
                    onClick={() => {
                      this.showRemoveDialog(item, i);
                    }}
                  >
                    <Icon component={"i"}>delete_forever</Icon>
                  </IconButton>
                )}
              </Grid>
            </Box>
          </Grid>
        );
      });

      rows.push(this.setEmpty());

      return rows;
    } else {
      return this.setNoResult();
    }
  };

  setNoResult = () => {
    // return <Box textAlign={'center'} fontWeight={'bold'}>{this.state.noResult}</Box>
    let rows = [];
    rows.push(this.setEmpty());
    return rows;
  };

  setRemoveDialog = () => {
    return (
      <CustomDialog
        open={this.state.isRemoveDialog}
        maxWidth={"lg"}
        onClose={() => {
          this.hideRemoveDialog();
        }}
      >
        <DataTableRemoveDialog
          title={this.state.removeTitle}
          removeMessage={this.state.removeMessage}
          noBtn={this.state.removeNoBtn}
          onClose={() => {
            this.hideRemoveDialog();
          }}
          onOk={() => {
            this.removeRow(this.state.removeRow, this.state.removeRowIndex);
          }}
        />
      </CustomDialog>
    );
  };

  setSnackbar = () => {
    return (
      <Snackbar
        open={this.state.showError}
        text={this.state.errorText}
        severity={this.state.errorSeverity}
        autoHideDuration={3000}
        onClose={() => {
          this.setState({
            showError: false,
            errorText: "",
            errorSeverity: null,
          });
        }}
      />
    );
  };

  render() {
    return (
      <Box className={"custom-grid-table-component " + this.state.className}>
        {this.setHeader()}
        {this.setItems()}
        {this.setFooter()}
        {this.setRemoveDialog()}
        {this.setSnackbar()}
      </Box>
    );
  }
}

export default GridTable;
