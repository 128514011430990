import React from "react";
import _ from "lodash";

import { 
    Row,
    Col,
    Dropdown,
    ButtonGroup,
    Spinner,
    Button,
    Form,
} from "react-bootstrap-v5";

import FooterActionButtons from './FooterActionButtons';

import Utils from "../../../../utils/utils";
import { PagesPermissions, JobStepType, CustomerSiteAvailability, JobAction } from '../../../../utils/enums';

// import { ReactComponent as PrintOfPreviewIcon } from '../../../../../_metronic/assets/img/icons/print_of_preview.svg';
// import { ReactComponent as MakingRecurringIcon } from '../../../../../_metronic/assets/img/icons/making_recurring.svg';
// import { ReactComponent as OptionsIcon } from '../../../../../_metronic/assets/img/icons/options.svg';

import { connect } from 'react-redux'
import { clear } from "../../../../../setup/redux/actions";
import { dispatchApiCallGet } from '../../../../../setup/redux/dispatch/actions'


class FooterSection extends React.Component {
  
    constructor(props) {
        super(props);
        
        this.name = 'job_form_footer_section';

        this.bodyRef = React.createRef();

        let dataParams = (props.dataParams) ? props.dataParams : null;
        let id = (dataParams && dataParams.id) ? dataParams.id : null;
        let row = (dataParams && dataParams.row) ? dataParams.row : null;
        
        
        this.state = {
            id: id,
            row: row,
        };
    }
    

    body = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;

        let actions = Utils.getJobFormActionButtons(values);

        let user = (this.props.auth && this.props.auth.user) ? this.props.auth.user : null;
        let permissions = (user && user.permissions && user.permissions.length > 0) ? user.permissions : [];
        
        let timeAvailability = CustomerSiteAvailability.All;
        let isWeekend = Utils.isWeekend(Utils.weekDayNumb(this.props.formOptions));
        if(isWeekend){
            timeAvailability = values.customFormParams.weekendAvailability;
        } else {
            timeAvailability = values.customFormParams.weekdaysAvailability;
        }

        let isStatusCompleted = false;
        if(values.statusName == "Completed") {
            isStatusCompleted = true;
        }

        return <Row className={'align-items-center'}>
            {
                (Utils.hasPermission(permissions, PagesPermissions.JobProofOfDelivery.read)) && 
                <Col xs={true} sm={'auto'}>
                    <Button 
                        className={'link-secondary d-flex align-items-center'}
                        variant={'link'}
                        disabled={this.props.isLoading || (!values.customerId) || (!values.jobTemplateId)}
                        onClick={(e) => {
                            if(this.props.onClickPOD){
                                this.props.onClickPOD();
                            }
                        }}
                    >
                        {/* <PrintOfPreviewIcon /> */}
                        <span className={'ps-2'}>Print Preview</span>
                        {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                    </Button>
                </Col>
            }

            <Col xs={'auto'} sm={true}>
                <Dropdown 
                    onSelect={(eventKey, e) => {
                        if(this.props.onClickMoreItem){
                            this.props.onClickMoreItem(eventKey);
                        }
                    }}
                >
                    <Dropdown.Toggle
                        className={'link-secondary d-flex align-items-center'}
                        style={{
                            margin: '0 auto',
                        }}
                        variant={'link'}
                        disabled={this.props.isLoading || (!values.customerId) || (!values.jobTemplateId)}
                    >
                        Options
                        {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant={'link'}>
                        {(values.isActive && (values.customerItem && values.customerItem.isActive)) && <Dropdown.Item eventKey={6} disabled={!values.customerId || !values.jobTemplateId || !values.customerSiteId || !Utils.isBinWasteSelected(this.props.formOptions)} active={false}>Make Recurring</Dropdown.Item>}
                        {(Utils.hasPermission(permissions, PagesPermissions.JobBilling.read)) && <Dropdown.Item eventKey={4} active={false}>Bill Job</Dropdown.Item>}
                        {(
                            (values.customFormParams.jobTemplateType === JobStepType.Exchange) ||
                            (values.customFormParams.jobTemplateType === JobStepType.OnTheSpot) ||
                            (values.customFormParams.jobTemplateType === JobStepType.Out && Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)) ||
                            (values.customFormParams.jobTemplateType === JobStepType.Pull)
                        ) && <Dropdown.Item eventKey={3} active={false}>Weigh chit</Dropdown.Item>}
                        {(values.isActive && (values.customerItem && values.customerItem.isActive)) && <Dropdown.Item eventKey={1} disabled={!values.isEdit || values.isCopy} active={false}>Copy Job</Dropdown.Item>}
                        {values.isActive && <Dropdown.Item eventKey={2} disabled={!values.isEdit || values.isCopy} active={false}>Delete Job</Dropdown.Item>}
                        {/* {(Utils.hasPermission(permissions, PagesPermissions.JobAuditTrail.read)) && <Dropdown.Item eventKey={5} disabled={!values.isEdit || values.isCopy} active={false}>Audit trail</Dropdown.Item>} */}
                    </Dropdown.Menu>
                </Dropdown>
            </Col>

            {/* <Col 
                className={'text-center pb-2 pb-md-0'}
                xs={{ span: 12, order: 2 }}
                sm={{ span: true, order: 2 }}
                md={{ span: 'auto', order: 1 }}
            >
                <Row className={'justify-content-center align-items-center'}>
                   
                    {values.isActive && <>
                        <Col xs={'auto'}>
                            <Button 
                                className={(values.isRecurring ? 'link-active' : 'link-secondary') + ' d-flex align-items-center'}
                                variant={'link'}
                                disabled={this.props.isLoading || (!values.customerId) || (!values.jobTemplateId)}
                                onClick={async (e) => {
                                    await setFieldValue('isRecurring', !values.isRecurring);
                                }}
                            >
                                <MakingRecurringIcon />
                                <span className={'ps-2'}>Making recurring</span>
                                {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                            </Button>
                        </Col>

                        <Col xs={12} md={'auto'}><div className={'custom-divider d-none d-lg-flex'}></div></Col>
                    </>}

                    <Col xs={'auto'}>
                        <Button 
                            className={(values.customFormParams.isOptions ? 'link-active' : 'link-secondary') + ' d-flex align-items-center'}
                            variant={'link'}
                            disabled={this.props.isLoading || (!values.customerId) || (!values.jobTemplateId)}
                            onClick={async (e) => {
                                await setFieldValue('customFormParams.isOptions', !values.customFormParams.isOptions);
                            }}
                        >
                            <OptionsIcon />
                            <span className={'ps-2'}>Options</span>
                            {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                        </Button>
                    </Col>

                    <Col xs={12} md={'auto'}><div className={'custom-divider d-none d-lg-flex'}></div></Col>

                    

                </Row>
            </Col> */}
            
            <Col xs={12} className={'d-block d-sm-none'}></Col>

            {isStatusCompleted && <Col xs={true} sm={'auto'} className={'mb-2 mb-sm-0'}>
                <Form.Check
                    id={'resend_DO'}
                    label={'Do you want to resend DO to customer?'}
                    checked={values.customFormParams.isSendEmail}
                    onChange={async (e) => {
                        await setFieldValue('customFormParams.isSendEmail', e.target.checked);
                    }}
                />
            </Col>}
            <Col xs={true} sm={'auto'} className={'mb-2 mb-sm-0'}>
                <Button 
                    type={'button'}
                    variant={'outline-secondary'}
                    disabled={this.props.isLoading}
                    onClick={() => {
                        this.props.history.push('/jobs');
                    }}
                >
                    Cancel
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
            {!this.props.isCreditLimit && <Col xs={'auto'} sm={'auto'}>
                <FooterActionButtons 
                    formOptions={this.props.formOptions}
                    isLoading={this.props.isLoading}
                    onSave={(eventKey, values) => {
                        if(this.props.onSave){
                            this.props.onSave(eventKey, values);
                        }
                    }}
                    onSaveCopyDraft={(eventKey, values) => {
                        if(this.props.onSaveCopyDraft){
                            this.props.onSaveCopyDraft(eventKey, values);
                        }
                    }}
                />
            </Col>}
        </Row>
    }
    

    render() {
        return this.body()
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(FooterSection);
