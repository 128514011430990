import React from 'react';
import moment from 'moment';
import _ from 'lodash';

import {
    Row,
    Col,
    Form,
} from "react-bootstrap-v5";

// import Utils from '../../../../utils/utils';
import { AuditTrailObjectType } from '../../../../utils/enums';

import AuditTrailDialog from '../../../../components/OLD/Pages/AuditTrail/AuditTrailDialog.jsx';
import AuditTrailFunctions from '../../../../components/OLD/Pages/AuditTrail/AuditTrailFunctions.jsx';

import Accordion from '../component/Accordion';
import SalaryForm from './SalaryForm';

import { useDispatch, useSelector } from "react-redux";
import {RootState} from '../../../../../setup'
import driversDetails, { useFormikContext, getYears, getMonthsByYear } from '../redux/driversDetails';

// import { StyledTitle } from '../style/driversDetailsStyles';


const SalaryTab = (props: any) => {
    const dispatch = useDispatch();
    const { dispatchUser } = useSelector((state: RootState) => state.auth);
    const { show, years, year, months } = useSelector((state: RootState) => state.driversDetails);

    const { values, errors, setFieldValue } = useFormikContext();
    
    const [isOpenAuditTrail, setIsOpenAuditTrail] = React.useState(false);
    const [objectId, setObjectId] = React.useState(null);


    React.useEffect(() => {
        let years = getYears(dispatchUser);
        dispatch(driversDetails.setYears(years));
    }, []);

    React.useEffect(() => {
        let months = getMonthsByYear(dispatchUser, year);
        dispatch(driversDetails.setMonths(months));
    }, [year]);
    

    const setAuditTrailDialog = () => {
        return <AuditTrailDialog 
            isOpen={isOpenAuditTrail}
            objectId={objectId}
            auditTrailObjectType={AuditTrailObjectType.DriverDetailsMonthly}
            title={"Monthly Audit Trail"}
            onClose={() => {
                setIsOpenAuditTrail(false);
                setObjectId(null);
            }}
            details={(obj: any, index: any, currentVersion: any, previousVersion: any) => {
                return AuditTrailFunctions.getDetails(AuditTrailObjectType.DriverDetailsMonthly, obj, index, currentVersion, previousVersion);
            }}
            manageData={(currentVersion: any, previousVersion: any, callback: any) => {
                return AuditTrailFunctions.getManageData(AuditTrailObjectType.DriverDetailsMonthly, currentVersion, previousVersion, callback);
            }}
        />
    }


    return <Row className={'px-5 px-md-0'}>
        {months.map((month, i) => {
            return <Col key={'month_' + i} xs={12} className={'mb-5 month_' + i}>
                <Accordion
                    index={i}
                    title={moment(month.date).format('MMM yyyy')}
                    isOpen={month.isOpen}
                    isLoading={month.isLoading}
                    onChange={(isOpen: any) => {
                        let clonedMnths = _.cloneDeep(months);
                        clonedMnths = clonedMnths.map((itm: any, j: number) => {
                            itm.isOpen = false;
                            itm.details = null;
                            itm.isLoading = false;
                            itm.isReRun = false;
                            itm.startDate = '';
                            itm.endDate = '';
                            return itm;
                        });
                        dispatch(driversDetails.setMonths(clonedMnths));
                        setFieldValue('data', null);

                        if(isOpen){
                            dispatch(driversDetails.callMonthDetailsApi(month.date, i, false, true, (state: any, data: any) => {
                                if(data){
                                    data.isAdHoc = false;
                                }

                                // Utils.scrollTo('month_' + i);

                                setTimeout(async () => {
                                    setFieldValue('data', data);
                                }, 100);
                            }));
                        }
                    }}
                    onClickHistory={() => {
                        if(month && month.details){
                            setIsOpenAuditTrail(true);
                            setObjectId(month.details.driverPayrollMonthReportId);
                        }
                    }}
                >
                    <Row>
                        <Col xs={12}>
                            <SalaryForm index={i} />
                        </Col>
                    </Row>
                </Accordion>
            </Col>
        })}
        
        <Col xs={'auto'} className={'mb-5 px-0'}>
            <Form.Select
                value={year}
                disabled={months.some(x => x.isLoading)}
                onChange={(e: any) => {
                    dispatch(driversDetails.setYear(e.target.value));
                }}
            >
                {years.map((item: any, i: number) => {
                    return <option key={'date_year_' + i} value={item.format('L')}>{item.format('yyyy')}</option>
                })}
            </Form.Select>
            {setAuditTrailDialog()}
        </Col>
    </Row>
}


export default SalaryTab;
