import React from 'react';

import { InView } from 'react-intersection-observer';

import Utils from '../../../utils/utils';

import { ReactComponent as CheckboxIcon } from "../../../../_metronic/assets/img/icons/ionic-ios-checkbox-outline.svg";
import { ReactComponent as UnCheckboxIcon } from "../../../../_metronic/assets/img/icons/ionic-ios-uncheckbox-outline.svg";
import { ReactComponent as CheckboxCheckedIcon } from "../../../../_metronic/assets/img/icons/ionic-ios-checkbox-outline-checked.svg";

import {
    Box,
    TableCell,
    TableRow,
    Checkbox,
    Collapse,
} from '@material-ui/core';
  
import { SortableElement, sortableHandle } from 'react-sortable-hoc';

import MenuIcon from '@material-ui/icons/Menu'


const DragHandle = sortableHandle((p) => <MenuIcon 
    className="my-drag-row"
    style={{ cursor: 's-resize' }} 
    onClick={(e) => {
        e.stopPropagation();
    }}
/>);

const SortableItem = SortableElement(props => {
    const { indexNo, row, id, isItemDisabledCheckbox, isItemSelected, labelId, isCheckbox, isDragable, isBorder, columns, groupIndex, handleClick, onColClick, handleCBClick, handleCBChange, customCheckBoxRow, setCol, setResponsiveColHead, columnVisibility, verticalAlign, details, customDetails, expandDetails, openDetailsRow, customRow, expandedRows, isVirtualize } = props;
    
    const cols = (columnVisibility && columnVisibility.length > 0) ? columnVisibility.filter(x => x === true).length : 0;
    const colSpan = (isBorder) ? cols + 1 : cols + 1;

    const [openDetails, setOpenDetails] = React.useState(((openDetailsRow === true) || (openDetailsRow === false)) ? openDetailsRow : false);
    
    const [isHover, setIsHover] = React.useState(false);
    
    const isRowExpanded = () => {
        return Utils.isInArray(expandedRows, id);
    }
    
    const toggleDetails = () => {
        setOpenDetails(!openDetails);
    }

    const setCustomDetails = () => {
        if(customDetails){
            return customDetails(row, props);
        }
    }

    const setDetails = () => {
        if(details){
            // row['isHover'] = isHover;
            return details(row, props, isItemSelected, this);
        }
    }
    const isDetails = () => {
        return (setDetails()) ? true : false;
    }

    const setCustomCheckBox = () => {
        if(customCheckBoxRow){
            return customCheckBoxRow(row, props);
        } else {
            if(!isItemDisabledCheckbox)
                return <Checkbox
                    disabled={isItemDisabledCheckbox}
                    checked={isItemSelected}
                    color={'default'}
                    onClick={event => handleCBClick(event, id, row, groupIndex)}
                    onChange={(event, isChecked) => handleCBChange(event, isChecked, id, row)}
                    inputProps={{ 'aria-labelledby': labelId }}
                    icon={<UnCheckboxIcon />}
                    checkedIcon={<CheckboxCheckedIcon />}
                    indeterminateIcon={<CheckboxCheckedIcon />}
                />
        }
    }
    
    const setExpandDetails = () => {
        if(expandDetails){
            return expandDetails(id, row, props, openDetails, toggleDetails);
        }
    }

    if(customRow){
        return <React.Fragment>
            <TableRow
                className={"custom-row row row_" + id + (isRowExpanded() ? ' exp-row pom-exp-row' : '')}
                hover
                onClick={event => handleClick(event, id, row)}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                selected={isItemSelected}
            >
                {isBorder && <TableCell
                    className="table-cell-border"
                ></TableCell>}

                {isCheckbox && 
                <TableCell 
                    className="datatable-checkbox-style"
                    align={'center'}
                    padding="normal"
                    style={{
                        width: '50px',
                        verticalAlign: (verticalAlign ? verticalAlign : 'middle')
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                >
                    {setCustomCheckBox()}
                </TableCell>}
                {expandDetails && 
                <TableCell 
                    className={'custom-expand-details-td'} 
                    style={{
                        width: '30px',
                        verticalAlign: (verticalAlign ? verticalAlign : 'middle'),
                    }}
                >
                    {setExpandDetails()}
                </TableCell>}
                {isDragable && 
                <TableCell className="responsive-hide"
                    align={'center'} 
                    style={{
                        width: '30px',
                        verticalAlign: (verticalAlign ? verticalAlign : 'middle')
                    }}
                >
                    <DragHandle />
                </TableCell>}

                {customRow(props, row)}

                {isBorder && <TableCell
                    className="table-cell-border"
                ></TableCell>}
            </TableRow>
            {setCustomDetails() && <TableRow 
                    className={'details-row' + (isRowExpanded() ? ' exp-details-row' : '')}
                    selected={isItemSelected}
                >
                <TableCell colSpan={colSpan}>
                    <Collapse in={openDetails} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            {setCustomDetails()}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>}
            {setDetails()}
        </React.Fragment>
    } else {
        return <React.Fragment>
            <InView 
                initialInView={true}
                rootMargin={'300px 0px 300px 0px'}
            >
                {({ inView, ref, entry }) => {
                    let inV = isVirtualize ? inView : true;

                    return <TableRow
                        ref={ref}
                        className={"row row_" + id + (isRowExpanded() ? ' exp-row pom-exp-row' : '') + (isDetails() ? ' details_main_row' : '') + ((isHover) ? ' hover' : '')}
                        hover
                        onClick={event => handleClick(event, id, row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                        onMouseOver={() => {
                            setIsHover(true);
                        }}
                        onMouseOut={() => {
                            setIsHover(false);
                        }}
                    >
                        {isBorder && <TableCell
                            className="table-cell-border"
                        ></TableCell>}
        
                        {isCheckbox && 
                        <TableCell 
                            className="datatable-checkbox-style"
                            align={'center'}
                            padding="normal"
                            style={{
                                width: '50px',
                                verticalAlign: (verticalAlign ? verticalAlign : 'middle'),
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                        >
                            {inV ? setCustomCheckBox() : null}
                        </TableCell>}
                        {expandDetails && 
                        <TableCell 
                            className={'custom-expand-details-td'} 
                            style={{
                                width: '30px',
                                verticalAlign: (verticalAlign ? verticalAlign : 'middle'),
                            }}
                        >
                            {inV ? setExpandDetails() : null}
                        </TableCell>}
                        {isDragable && 
                        <TableCell className="responsive-hide"
                            align={'center'}
                            style={{
                                width: '30px',
                                verticalAlign: (verticalAlign ? verticalAlign : 'middle')
                            }}
                        >
                            {inV ? <DragHandle /> : null}
                        </TableCell>}
                        {columns.map((col, i) => {
                            return (
                                (col.visible === false)
                                ?
                                null 
                                :
                                <TableCell 
                                    key={i}  
                                    id={labelId} 
                                    scope="row" 
                                    width={(col.width ? col.width : 'auto')}
                                    style={{ 
                                        width: (col.width ? col.width : 'auto'), 
                                        minWidth: '30px', 
                                        display: ((columnVisibility && columnVisibility.length > 0) ? (columnVisibility[i]) ? '' : 'none' : ''),
                                        verticalAlign: (col.verticalAlign ? col.verticalAlign : (verticalAlign ? verticalAlign : 'middle')),
                                    }}
                                    align={((col.align && col.align !== '') ? col.align : 'left')}
                                    padding={'normal'}
                                    onClick={(e) => {
                                        if(onColClick){
                                            onColClick(id, row, col, i, e);
                                        }
                                    }}
                                >
                                    {
                                        inV
                                        ?
                                            <>
                                                {setResponsiveColHead(col, row)}
                                                {setCol(col, row, indexNo)}
                                            </>
                                        :
                                            null
                                    }
                                </TableCell>
                            )
                        })}
        
                        {isBorder && <TableCell
                            className="table-cell-border"
                        ></TableCell>}
                    </TableRow>
                }}
            </InView>
            
            {setCustomDetails() && <TableRow 
                    className={'details-row' + (isRowExpanded() ? ' exp-details-row' : '')}
                    selected={isItemSelected}
                >
                <TableCell colSpan={colSpan}>
                    <Collapse in={openDetails} timeout="auto" unmountOnExit>
                        <Box margin={0}>
                            {setCustomDetails()}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>}
            {setDetails()}
        </React.Fragment>
    }
});


const DataTableRow = (props) => {
    const { index, indexNo, row, id, isItemDisabledCheckbox, isItemSelected, labelId, isCheckbox, isDragable, isBorder, columns, groupIndex, handleClick, onColClick, handleCBClick, handleCBChange, customCheckBoxRow, setCol, setResponsiveColHead, columnVisibility, verticalAlign, details, customDetails, expandDetails, openDetailsRow, customRow, expandedRows, isVirtualize } = props;
    
    return <SortableItem 
        index={index}
        indexNo={indexNo}
        row={row}
        id={id}
        isItemDisabledCheckbox={isItemDisabledCheckbox}
        isItemSelected={isItemSelected}
        labelId={labelId}
        isCheckbox={isCheckbox}
        isDragable={isDragable}
        isBorder={isBorder}
        columns={columns}
        handleClick={handleClick}
        onColClick={onColClick}
        handleCBClick={handleCBClick}
        handleCBChange={handleCBChange}
        customCheckBoxRow={customCheckBoxRow}
        setCol={setCol}
        setResponsiveColHead={setResponsiveColHead}
        columnVisibility={columnVisibility}
        verticalAlign={verticalAlign}
        groupIndex={groupIndex}
        details={details}
        customDetails={customDetails}
        expandDetails={expandDetails}
        openDetailsRow={openDetailsRow}
        customRow={customRow}
        expandedRows={expandedRows}
        isVirtualize={isVirtualize}
    />;
  }

  export default DataTableRow;