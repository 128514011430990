import React from 'react';
import _ from 'lodash';


const OrderableMultiListByChildren = ({ children, group = 'group', order = 'order', ...rest }) => {
    const [items, setItems] = React.useState([]);

    
    React.useEffect(() => {
        const itms = getItems(children);
        setItems(itms);
    }, [children]);


    const getItems = () => {
        if(children){
            let arr = [];
            let arrItems = [];

            arr = React.Children.toArray(children).map((child, index) => {
                if (Array.isArray(child.props.children)) {
                    arrItems.push(child.props.children);
                } else {
                    return {
                        id: index,
                        content: child.props.children,
                        [group]: child.props[group],
                        [order]: child.props[order],
                        render: (child) => {
                            return <>{child}</>
                        }
                    }
                }
            });

            if(arrItems && arrItems.length > 0){
                let flattenedArr = _.flattenDeep(arrItems);

                if(flattenedArr && flattenedArr.length > 0){
                    flattenedArr.forEach((childItem, j) => {
                        if(childItem){
                            arr.push({
                                id: '1000' + j,
                                content: childItem.props.children,
                                [group]: childItem.props[group],
                                [order]: childItem.props[order],
                                render: (childItem) => {
                                    return <>{childItem}</>
                                }
                            })
                        }
                    })
                }
            }

            const filteredGroups = _.filter(arr, (x) => !_.isEmpty(x));
            const groupedItems = _.groupBy(filteredGroups, 'group');
            const formattedGroups = _.map(groupedItems, (group, key) => {
                return {
                    group: key,
                    list: _.orderBy(group, 'order'),
                };
            });

            return formattedGroups;
        } else {
            return [];
        }
    };

    return <>
        {items.map((group, g) => {
            if(group && group.list && group.list.length > 0){
                return <div key={g} className={"mb-10"}>
                    {group.list.map((item, i) => {
                        return <React.Fragment key={i}>{item.render(item.content)}</React.Fragment>
                    })}
                </div>
            }
        })}
    </>
}


export default OrderableMultiListByChildren;
