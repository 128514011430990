import React, { Component } from 'react';

import Utils from '../../../../utils/utils';
import DocGenApi from '../../../../api/DocGen/DocGen.jsx';

import { 
  Box,
  Grid
} from '@material-ui/core';

import FolderIcon from '../../../../../_metronic/assets/img/manage/folder.png';
import AddPhotoIcon from '../../../../../_metronic/assets/img/manage/add_photo.png';

import PreviewImage from './PreviewImage.jsx';
import InnerDropzone from './InnerDropzone.jsx';
import OuterDropzone from './OuterDropzone.jsx';

import CustomDialog from '../../Dialog/CustomDialog.jsx';
import RemoveDialog from '../RemoveDialog.jsx';

import CustomCropperDialogWrapper from '../../Cropper/CustomCropperDialogWrapper.jsx';


class InnerFilesDropzoneWithCropper extends Component {
  
  constructor(props){
    super(props);

    this.inputRef = React.createRef();
    
    let value = (props.value) ? props.value : [];
    let accept = (props.accept) ? props.accept : null;
    let acceptText = (props.acceptText) ? props.acceptText : '.*';
    let path = (props.path) ? props.path : '';
    let maxEmptyItems = (props.maxEmptyItems) ? props.maxEmptyItems : 1;
    let smSize = (props.smSize) ? props.smSize : 6;
    let noImage = (props.noImage) ? props.noImage : FolderIcon;
    let addImage = (props.addImage) ? props.addImage : AddPhotoIcon;
    let disabled = ((props.disabled === true || props.disabled === false) ? props.disabled : false);
    let maxSize =  (props.maxSize) ? props.maxSize : 30000000;
    let multiple = ((props.multiple === true || props.multiple === false) ? props.multiple : true);
    let contain = ((props.contain === true || props.contain === false) ? props.contain : false);
    
    this.state = {
      accept: accept,
      acceptText: acceptText,
      files: value,
      path: path,
      noImage: noImage,
      addImage: addImage,
      disabled: disabled,
      maxSize: maxSize,
      multiple: multiple,
      maxEmptyItems: maxEmptyItems,
      smSize: smSize,
      isRemoveDialog: false,
      removeItem: null,
      removeItemUrl: null,
      contain: contain,

      indexProgress: null,
      progress: null,

      cropperDialogItems: null,
    };
  }
  

  componentWillReceiveProps(nextProps) {
		if (nextProps.value !== this.state.value) {
      let value = (nextProps.value) ? nextProps.value : [];
			this.setState({
				files: value
			});
    }
  }


  setFiles = (files) => {
    if (files && files.length > 0) {
      this.setState({
        files: files
      });
    }
  }


  /* API */
  callUploadFilesApi = async (files) => {
    const token = this.props.token;
    const dispatchAccountId = this.props.dispatchAccountId;

    let filesArr = files;
    
    let results = [];

    this.setState({
      indexProgress: 0,
      progress: 0
    });

    if(files && files.length > 0){
      for(let i = 0; i < files.length; i++){
        this.setState({
          indexProgress: i,
          progress: 0
        });

        let fName = (files[i].name && files[i].name !== '') ? files[i].name : '';
        let fileExt = fName.split('.').pop();
        let filename = fName.substr(0, fName.lastIndexOf("."))
        
        let getResult = await DocGenApi.uploadFile(
          files[i], 
          dispatchAccountId,
          this.state.path,
          filename, 
          fileExt,
          token, 
          (progress) => {
            this.setState({
              progress: progress
            });
          }
        );

        Utils.parseResult(getResult, (data) => {
          filesArr[i].url = data;
          results[i] = data;
        }, (error) => {
          Utils.toast(error, 'error');
        });

        this.setState({
          indexProgress: null,
          progress: null
        });
      }
    }

    return results;
  }
  /* END API */


  handleDropAccepted = (files, e) => {
    if(this.props.onDropAccepted)
      this.props.onDropAccepted(files);
  }
  
  handleDropRejected = (files, e) => {
    if(files && files.length > 0){
      for(var i = 0; i < files.length; i++) {
        let desc = null;

        if(files[i].size >= this.state.maxSize){
          desc = <div>
            <div>{"Error"}</div>
            <div>{files[i].name}</div>
            <div>File size is: <b>{Utils.bytesToSize(files[i].size)}</b></div>
            <div>Maximum allowed image size is: <b>{Utils.bytesToSize(this.state.maxSize)}</b></div>
          </div>
        } else {
          let ext = (this.state.accept && this.state.accept !== '') ? this.state.accept.split(',') : [];
          let type = files[i].type;

          if(ext.findIndex(x => x === type) === -1){
            desc = <div>
              <div className={"text-white"}>{files[i].name}</div>
              <div className={"text-white"}>File extension is: <b>{type}</b></div>
              <div className={"text-white"}>Allowed file extensions are: <b>{this.state.acceptText}</b></div>
            </div>
          } else {
            desc = <div>
              <div>{"Error"}</div>
              <div>{files[i].name}</div>
              <div>File size is: <b>{Utils.bytesToSize(files[i].size)}</b></div>
              <div>File extension is: <b>{type}</b></div>
              <div>An error occurred while uploading the file</div>
            </div>
          }
        }

        Utils.toast(desc, 'error');
      }
    }

    if(this.props.onDropRejected)
      this.props.onDropRejected(files);
  }

  handleDrop = (files) => {
    this.setState({
      cropperDialogItems: files,
    });
  }

  handleDropAfterCrop = (files) => {
    files.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file),
      url: '',
    }));

    this.setState({
      files: files,
      disabled: true,
      cropperDialogItems: null,
    }, () => {
      this.callUploadFilesApi(files).then(result => {
        this.setState({
          disabled: this.props.disabled
        });
  
        if(this.props.onUpload){
          this.props.onUpload(result);
        }
      });
    });

    if(this.props.onDrop){
      this.props.onDrop(files);
    }
  }

  handleRemove = (i, url) => {
    var file = this.state.files[i];

    var array = [...this.state.files];
    array.splice(i, 1);
    this.setState({files: array});
    
    if(this.props.onRemove){
      this.props.onRemove(file, i, array);
    }
  }


  getItems = () => {
    if(this.state.files && this.state.files.length > 0) {
        return (this.state.files.map((file, i) => {
          if(i < this.state.maxEmptyItems){
            return <Box clone key={i}>
              <Grid item xs={12} md={this.state.multiple ? this.state.smSize : 12}>
                <InnerDropzone
                  noImage={this.state.addImage}
                  preview={<PreviewImage 
                    index={i}
                    disabled={this.state.disabled}
                    file={(typeof file === 'object') ? file : null}
                    url={(typeof file === 'string') ? file : ''}
                    indexProgress={this.state.indexProgress}
                    progress={this.state.progress}
                    noImage={this.state.noImage}
                    contain={this.state.contain}
                    handleRemove={(i, url) => {
                      this.setState({
                        isRemoveDialog: true,
                        removeItem: i,
                        removeItemUrl: url
                      });
                    }}
                  />}
                />
                {/* {this.props.descField && <Box>asd{this.props.descField}</Box>} */}
              </Grid>
            </Box>
          }
        }
      ));
    }
  }

  setMultipleEmptyItems = () => {
    if (this.state.files && this.state.files.length > 0) {
      if (this.state.files.length >= this.state.maxEmptyItems) {
        // return this.getEmptyItem(this.state.files.length);
      } else {
        return ([...Array(this.state.maxEmptyItems - this.state.files.length)].map((e, i) => this.getEmptyItem(i) ));
      }
    } else {
      return ([...Array(this.state.maxEmptyItems)].map((e, i) => this.getEmptyItem(i) ));
    }
  }

  setSingleEmptyItem = () => {
    if (this.state.files && this.state.files.length <= 0) {
      return this.getEmptyItem(this.state.files.length);
    }
  }

  getEmptyItem = (i) => {
    return <Box clone key={i}>
      <Grid item xs={12} md={this.state.multiple ? this.state.smSize : 12}>
        <InnerDropzone 
          preview={null}
          noImage={this.state.addImage}
          onClick={() => {
            if(!this.state.disabled){
              this.inputRef.current.click();
            }
          }}
        />
      </Grid>
    </Box>
  }


  setCropperDialog = () => {
    return <CustomCropperDialogWrapper
      dialogItems={this.state.cropperDialogItems}
      onCropFinished={(files) => {
        if(files && files.length > 0){
          this.handleDropAfterCrop(files);
        } else {
          this.setState({
            cropperDialogItems: null,
            indexProgress: null,
            progress: null
          });
        }
      }}
    />
  }

  setRemoveDialog = () => {
    return  <CustomDialog
      open={this.state.isRemoveDialog}
      closeBtnPosition={'in'}
      maxWidth={'xs'}
      onClose={() => {
        this.setState({
          isRemoveDialog: false,
          removeItem: null,
          removeItemUrl: null
        });
      }}
    >
      <RemoveDialog 
        onClose={() => {
          this.setState({
            isRemoveDialog: false,
            removeItem: null,
            removeItemUrl: null
          });
        }}
        onOk={(i) => {
          this.handleRemove(this.state.removeItem, this.state.removeItemUrl);
  
          this.setState({
            isRemoveDialog: false,
            removeItem: null,
            removeItemUrl: null
          });
        }}
      />
    </CustomDialog>
  }


  render() {
    return <div className={"files-dropzone-component " + (this.props.className ? this.props.className : '') + ' ' + (this.state.disabled ? 'disabled' : '')}>
      <OuterDropzone
        accept={this.state.accept}
        disabled={this.state.disabled}
        maxSize={this.state.maxSize}
        multiple={this.state.multiple}
        onDropRejected={this.handleDropRejected}
        onDropAccepted={this.handleDropAccepted}
        onDrop={this.handleDrop}
        getInputRef={inputRef => this.inputRef = inputRef}
      >
        {this.getItems()}
        {
          this.state.multiple 
        ? 
          this.setMultipleEmptyItems()
        :
          this.setSingleEmptyItem()
        }
      </OuterDropzone>
      {this.setCropperDialog()}
      {this.setRemoveDialog()}
    </div>;
  }
}


export default InnerFilesDropzoneWithCropper;
