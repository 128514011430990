import React from 'react';
import {shallowEqual, useSelector} from 'react-redux'
import { Route, Redirect } from 'react-router-dom';

import Utils from '../utils/utils';

import {RootState} from '../../setup'


const RouteLayout = ({ ...rest }) => {
  const auth: any = useSelector<RootState>(({auth}) => auth, shallowEqual)

  const user = (auth && auth.user) ? auth.user : null;
  const isAuthorized = (user) ? true : false;
  const [permissions] = React.useState((user && user.permissions && user.permissions.length > 0) ? user.permissions : []);
  
  const Component = rest.Component;

  const layoutRef = React.createRef<any>();
  const resizeListener = () => {
    if(layoutRef && layoutRef.current){
      try {
        layoutRef.current.onWindowResize();
      } catch(e){}
    }
  }
  

  React.useEffect(() => {
    document.body.setAttribute('data-page', rest.className);
  });

  React.useEffect(() => {
    if(rest && rest.title && rest.title !== ''){
      document.title = rest.title
    }
  }, [rest.title]);

  React.useEffect(() => {
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  });

  
  if(isAuthorized){
    if(Utils.hasPermission(permissions, rest.read)){
      return (
        <Route {...rest} 
          render={matchProps => {
            if(rest.Layout){
              return <rest.Layout auth={auth} layoutRef={layoutRef}>
                <Component layoutRef={layoutRef} {...matchProps} auth={auth} settingsType={rest.settingsType} {...rest} />
              </rest.Layout>
            }else {
              return <>
                <Component layoutRef={layoutRef} {...matchProps} auth={auth} settingsType={rest.settingsType} {...rest} />
              </>
            }
          }}
        />
      )
    } else {
      if(rest.path === "/Sign-in-t"){
        return (
          <Redirect to="/" />
        )
      } else {
        return (
          <Redirect to={Utils.hasPagePermission(rest.path)} />
        )
      }
    }
  } else {
    return (
      <Redirect to="/sign-in" />
    )
  }
};

export default RouteLayout;