import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';


const DraggableListByChildren = ({ droppableId = 'droppable', children, onDragEnd, layout = 'inline', idField = 'id', sortOrder = 'sortOrder', draggable = 'draggable', itemData = 'itemData', nonDraggableOnBotton = false, ...rest }) => {
    const [items, setItems] = React.useState([]);
    const [nonDraggableItes, setNonDraggableItes] = React.useState([]);

    React.useEffect(() => {
        const itms = getItems(children);
        setItems(itms.filter(x => x[draggable] === true));
        setNonDraggableItes(itms.filter(x => x[draggable] === false));
    }, [children]);


    const getItems = () => {
        return React.Children.toArray(children).map((child, index) => {
            return {
                id: droppableId + '-' + index,
                content: child.props.children,
                layout: layout,
                [sortOrder]: child.props[sortOrder],
                [itemData]: child.props[itemData],
                [draggable]: (child.props[draggable] == false) ? false : true,
                render: (child) => {
                    return (layout == 'inline') ? <div className={'d-md-flex align-items-center'}>{child}</div> : <div>{child}</div>
                }
            }
        }).sort((a, b) => a[sortOrder] - b[sortOrder]);
    };

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? '#f2f2f2' : 'white',
        width: '100%'
    });

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: 'none',
        background: isDragging ? '#fffff0' : 'white',
        paddingTop: '5px',
        paddingBottom: '5px',
        ...draggableStyle
    });

    const dragHandle = (provided, item) => {
        return <i 
            style={{
                cursor: 'grab',
                paddingRight: '15px',
                paddingBottom: '2px',
            }}
            {...provided.dragHandleProps}
            className={'material-icons'}
        >menu</i>
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [draggedItem] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, draggedItem);
        result.forEach((item, index) => {
            item[sortOrder] = (index+1);
        });

        return result;
    };

    
    return <>
        {!nonDraggableOnBotton && nonDraggableItes.map((item, index) => {
            return <div key={item[idField]}
                style={{
                    paddingBottom: '5px',
                }}
            >
                {item.render(<>
                    {(item.layout == 'inline') && <i 
                        style={{
                            width: '39px',
                            paddingRight: '15px',
                            paddingBottom: '2px',
                        }}
                        className={'material-icons'}
                    ></i>}
                    {item.content}
                </>)}
            </div>
        })}

        <DragDropContext
            onDragEnd={(result) => {
                if (!result.destination)
                    return;
                    
                const itms = reorder(items, result.source.index, result.destination.index);
                setItems(itms);

                if(onDragEnd){
                    onDragEnd(itms, result.source.index, result.destination.index);
                }
            }}
        >
            <Droppable droppableId={droppableId}>
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                    >
                        {items.map((item, index) => {
                            return <Draggable key={item[idField]} draggableId={item[idField]} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}
                                    >
                                        {
                                            (item.layout !== 'inline')
                                            ?
                                            item.render(<>
                                                <div 
                                                    style={{
                                                        width: '35px',
                                                        display: 'inline-block',
                                                        height: '24px',
                                                        position: 'relative',
                                                        top: '7px'
                                                    }}
                                                >
                                                    {dragHandle(provided, item)}
                                                </div>
                                                {item.content}
                                            </>)
                                            :
                                            item.render(<>
                                                {dragHandle(provided, item)}
                                                {item.content}
                                            </>)
                                        }
                                    </div>
                                )}
                            </Draggable>
                        })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>

        {nonDraggableOnBotton && nonDraggableItes.map((item, index) => {
            return <div key={item[idField]}
                style={{
                    paddingBottom: '5px',
                }}
            >
                {item.render(<>
                    {(item.layout == 'inline') && <i 
                        style={{
                            width: '39px',
                            paddingRight: '15px',
                            paddingBottom: '2px',
                        }}
                        className={'material-icons'}
                    ></i>}
                    {item.content}
                </>)}
            </div>
        })}
    </>
}


export default DraggableListByChildren;
