import React, { Component } from "react";
// import moment from 'moment';

import apiUtil from "../../../api/apiUtil.jsx";
import CustomerApi from "../../../api/OLD/Dispatch/Customer.jsx";
import CustomerSiteApi from "../../../api/OLD/Dispatch/CustomerSite.jsx";
import ReportApi from "../../../api/OLD/Dispatch/Report.jsx";

import {
  Box,
  Grid,
  Button,
  IconButton,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";

import { ReactComponent as MailIcon } from "../../../../_metronic/assets/img/icons/report-mail.svg";
import { ReactComponent as PrintIcon } from "../../../../_metronic/assets/img/icons/report-print.svg";
import { ReactComponent as DownloadIcon } from "../../../../_metronic/assets/img/icons/report-download.svg";
import { ReactComponent as EditIcon } from "../../../../_metronic/assets/img/icons/report-edit.svg";
import CustomerInfo from "../../../components/OLD/Pages/Reports/CustomerInfo.jsx";
import {
  ReportTable,
  ReportCaption,
  ReportThead,
  ReportTbody,
  ReportTr,
  ReportTh,
  ReportTd,
} from "../../../components/OLD/Pages/Reports/CustomTable.jsx";

import ReportsLayout from "../Layout/ReportsLayout.jsx";
import SendEmailDialog from "../Dialogs/SendEmailDialog.jsx";

import Snackbar from "../../../components/OLD/Snackbar/Snackbar.jsx";
import IconsList from "../../../components/OLD/IconsList/IconsList.jsx";
import ManageLabel from "../../../components/OLD/input/ManageLabel.jsx";
// import RSuiteDateRangePicker from "../../../components/OLD/Datepicker/RSuiteDateRangePicker.jsx";
import RSuiteCheckPicker from "../../../components/OLD/Checkbox/RSuiteCheckPicker.jsx";
import CustomDialog from "../../../components/OLD/Dialog/CustomDialog.jsx";
import DropdownFilter from "../../../components/OLD/Dropdown/DropdownFilter.jsx";
import ManageInput from "../../../components/OLD/input/ManageInput.jsx";

import Utils from "../../../utils/utils.js";
import { connect } from 'react-redux'
import signalRslice from "../../../components/signalR/signalRslice";


class BinsOnSIteView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      report: null,

      pageName: "Bins on sites",
      isIconClicked: false,

      isSendEmailDialog: false,
      htmlSendEmailDialog: "",

      onEditToggler: false,

      
      showError: false,
      errorText: "",
      errorSeverity: null,



      download: "1",

      // dateRange: apiUtil.getDateRangeValue('This Month'),
      // dateFormat: apiUtil.getAPIDateFormat(),

      customerIsLoading: false,
      customerText: "",
      customerId: "",
      customerItem: null,
      customer: [],

      customerSiteIsLoading: false,
      customerSiteText: "",
      customerSiteId: "",
      customerSiteItem: null,
      customerSite: [],
    };
  }

  componentDidMount() {}

  /* CUSTOMER API */
  callCustomerReadApi = (searchQuery = "", callback = null) => {
    const { token } = this.context;

    this.setState({
      customerIsLoading: true,
      customer: [],
    });

    CustomerApi.read(
      {
        currentPage: 1,
        pageSize: apiUtil.getMaxPageSize(),
        searchQuery: searchQuery,
        isIncludeInactive: false,
        sortColumn: "customerName",
        sortDir: "asc",
      },
      token
    ).then((result) => {
      apiUtil.parseResult(
        result,
        (data) => {
          let arr = [];

          if (data && data.data && data.data.length > 0) {
            for (var i = 0; i < data.data.length; i++) {
              let item = data.data[i];
              let id = item.customerId;
              let name = item.customerNameDisplay;

              if (arr.findIndex((x) => x.value === id) === -1) {
                arr.push({
                  value: id,
                  title: name,
                  item: item,
                });
              }
            }
          }

          this.setState(
            {
              customer: arr,
              customerIsLoading: false,
            },
            () => {
              if (callback !== null) {
                callback(this.state.customer);
              }
            }
          );
        },
        (error, type) => {
          this.setState({
            customer: [],
            customerIsLoading: false,
          });
        }
      );
    });
  };
  /* END CUSTOMER API */

  /* CUSTOMER SITES API */
  callCustomerSiteReadApi = (searchQuery = "", callback = null) => {
    const { token } = this.context;

    this.setState({
      customerSiteIsLoading: true,
      customerSite: [],
    });

    CustomerSiteApi.list(
      {
        currentPage: 1,
        pageSize: apiUtil.getMaxPageSize(),
        searchQuery: searchQuery,
        customerId:
          this.state.customerSearchColumns &&
          this.state.customerSearchColumns.length > 0
            ? this.state.customerSearchColumns[0]
            : 0,
        showRecentJob: true,
        isIncludeInactive: true,
      },
      token
    ).then((result) => {
      apiUtil.parseResult(
        result,
        (data) => {
          let arr = [];

          if (data && data.data && data.data.length > 0) {
            for (var i = 0; i < data.data.length; i++) {
              let item = data.data[i];

              let id = item.customerSiteId;
              let name = item.siteNameDisplay;

              if (arr.findIndex((x) => x.value === id) === -1) {
                arr.push({
                  value: id,
                  title: name,
                  item: item,
                });
              }
            }
          }

          this.setState(
            {
              customerSite: arr,
              customerSiteIsLoading: false,
            },
            () => {
              if (callback !== null) {
                callback(this.state.customerSite);
              }
            }
          );
        },
        (error, type) => {
          this.setState({
            customerSite: [],
            customerSiteIsLoading: false,
          });
        }
      );
    });
  };
  /* END CUSTOMER SITES API */

  /* REPORT API */
  callReportReadApi = async (callback = null) => {
    const { token } = this.context;

    await this.props.action.signalRslice.connect({
      callback: (percent) => {
        this.props.action.signalRslice.onPercentage(percent);
      },
      callbackInit: (hubId) => {}
    });

    Utils.clickOutside();

    this.setState({
      isLoading: false,
      report: null,
    });

    // let fromD = (this.state.dateRange && this.state.dateRange.length > 0) ? this.state.dateRange[0] : '';
    // let toD = (this.state.dateRange && this.state.dateRange.length === 2) ? this.state.dateRange[1] : '';

    // let fromDate = (fromD !== '') ? moment(fromD).format(this.state.dateFormat) : '';
    // let toDate = (toD !== '') ? moment(toD).format(this.state.dateFormat) : '';

    let customerIds = this.state.customerSearchColumns.join(",");
    let customerSiteIds = this.state.customerSiteSearchColumns.join(",");

    let formData = {
      customerIds: customerIds,
      hubId: this.props.signalRslice.hubId,
    };

    if (!this.isCustomerSiteDisabled()) {
      formData["customerSiteIds"] = customerSiteIds;
    }

    ReportApi.read("/bin-on-site", formData, token).then((result) => {
      apiUtil.parseResult(
        result,
        (data) => {
          this.setState(
            {
              report: data,
              isLoading: false,
            },
            () => {
              if (callback !== null) {
                callback(this.state.report);
              }
            }
          );

          this.props.action.signalRslice.disconnect();
        },
        (error, type) => {
          this.setState({
            report: null,
            isLoading: false,
          });

          this.props.action.signalRslice.disconnect();
        }
      );
    });
  };

  callGeneratePDFApi = async (fileName = "", html = "", callback = null) => {
    const { token } = this.context;

    await this.props.action.signalRslice.connect({
      callback: (percent) => {
        this.props.action.signalRslice.onPercentage(percent);
      },
      callbackInit: (hubId) => {}
    });
    
    Utils.clickOutside();

    ReportApi.generatePDF(
      {
        fileName: fileName,
        htmlContent: html,
        hubId: this.props.signalRslice.hubId,
      },
      token
    ).then((result) => {
      apiUtil.parseResult(
        result,
        (data) => {
          if (callback !== null) {
            callback(fileName, data);
          }

          this.props.action.signalRslice.disconnect();
        },
        (error, type) => {
          if (callback !== null) {
            callback(null, null);
          }

          this.props.action.signalRslice.disconnect();
        }
      );
    });
  };

  callGenerateEXCELApi = async (callback = null) => {
    const { token } = this.context;
    
    await this.props.action.signalRslice.connect({
      callback: (percent) => {
        this.props.action.signalRslice.onPercentage(percent);
      },
      callbackInit: (hubId) => {}
    });
    
    Utils.clickOutside();

    // let fromD = (this.state.dateRange && this.state.dateRange.length > 0) ? this.state.dateRange[0] : '';
    // let toD = (this.state.dateRange && this.state.dateRange.length === 2) ? this.state.dateRange[1] : '';

    // let fromDate = (fromD !== '') ? moment(fromD).format(this.state.dateFormat) : '';
    // let toDate = (toD !== '') ? moment(toD).format(this.state.dateFormat) : '';

    let customerIds = this.state.customerSearchColumns.join(",");
    let customerSiteIds = this.state.customerSiteSearchColumns.join(",");

    let customers = [];
    if(this.state.report && this.state.report.length > 0){
      customers = this.state.report.map((x, i) => {
        let item = {
          customerId: x.customerId,
          customerSiteId: x.customerSiteId,
          remarks: x.remarks,
          attentionTo: x.customerAttentionTo,
        };

        return item;
      });
    }

    let formData = {
      // fromDate: fromDate,
      // toDate: toDate,
      customerIds: customerIds,
      customers: customers,
      hubId: this.props.signalRslice.hubId,
    };

    if (!this.isCustomerSiteDisabled()) {
      formData["customerSiteIds"] = customerSiteIds;
    }

    ReportApi.generatePostEXCEL("/bin-on-site-excel", formData, token).then(
      (result) => {
        apiUtil.parseResult(
          result,
          (data) => {
            if (callback !== null) {
              callback(data);
            }
            
            this.props.action.signalRslice.disconnect();
          },
          (error, type) => {
            if (callback !== null) {
              callback(null);
            }

            this.props.action.signalRslice.disconnect();
          }
        );
      }
    );
  };

  callSendPDFEmailApi = (
    emailAddresses = "",
    fileName = "",
    html = "",
    callback = null
  ) => {
    const { token } = this.context;

    ReportApi.sendPDFEmail(
      {
        emailAddresses: emailAddresses,
        emailSubject: fileName,
        fileName: fileName,
        htmlContent: html,
      },
      token
    ).then((result) => {
      apiUtil.parseResult(
        result,
        (data) => {
          this.setState(
            {
              showError: true,
              errorText: "Email sent successfully!",
              errorSeverity: "success",
            },
            () => {
              if (callback) {
                callback();
              }
            }
          );
        },
        (error, type) => {
          this.setState(
            {
              showError: true,
              errorText: error,
              errorSeverity: type,
            },
            () => {
              if (callback) {
                callback();
              }
            }
          );
        }
      );
    });
  };
  /* END REPORT API */

  /* FUNCTIONS */
  isRunReportBtnDisabled = () => {
    let customerLength =
      this.state.customerSearchColumns &&
      this.state.customerSearchColumns.length > 0
        ? this.state.customerSearchColumns.length
        : 0;
    let customerSiteLength =
      this.state.customerSiteSearchColumns &&
      this.state.customerSiteSearchColumns.length > 0
        ? this.state.customerSiteSearchColumns.length
        : 0;

    if (customerLength === 1) {
      return customerSiteLength > 0 ? false : true;
    } else if (customerLength > 1) {
      return false;
    } else {
      return true;
    }
  };
  isCustomerSiteDisabled = () => {
    let customerLength =
      this.state.customerSearchColumns &&
      this.state.customerSearchColumns.length > 0
        ? this.state.customerSearchColumns.length
        : 0;

    if (customerLength === 1) {
      return false;
    } else if (customerLength > 1) {
      return true;
    } else {
      return true;
    }
  };
  /* END FUNCTIONS */

  setSnackBar = () => {
    return (
      <Snackbar
        open={this.state.showError}
        text={this.state.errorText}
        severity={this.state.errorSeverity}
        onClose={() => {
          this.setState({
            showError: false,
            errorText: "",
            errorSeverity: null,
          });
        }}
      />
    );
  };

  /* SECTIONS */
  topSection = () => {
    return (
      <Box>
        <Grid container spacing={2} alignItems={"center"}>
          {/* <Box clone>
            <Grid item xs={12} md={3} lg={4}>
              <ManageLabel
                className={"no-margin"}
                label={"Reporting periods"}
                weight={"normal"}
                size={"xs"}
              />
              <Box bgcolor="white"  p={[1, 1, 1]}>
                <RSuiteDateRangePicker
                  className={'reports-date-picker'}
                  placeholder={"Select date"}
                  format={"DD.MM.YYYY"}
                  value={this.state.dateRange}
                  cleanable={false}
                  renderValue={(value, format) => {
                    let date = moment(value[0]).format(format) + ' - ' + moment(value[1]).format(format);
                    let label = apiUtil.getDateRangeLabel(value[0], value[1]);

                    return <span>{(label && label !== 'Custom') ? label + ' (' + date + ')' : date}</span>
                  }}
                  onChange={(value) => {
                    this.setState({
                      dateRange: value,
                    });
                  }}
                />
              </Box>

            </Grid>
          </Box> */}

          <Box clone>
            <Grid item xs={12} md={3} lg={4}>
              <ManageLabel
                className={"no-margin"}
                label={"Select Customer"}
                weight={"normal"}
                size={"xs"}
              />
              <RSuiteCheckPicker
                items={this.state.customer}
                value={this.state.customerSearchColumns}
                placeholder={"Select Customers ..."}
                isLoading={this.state.customerIsLoading}
                onInit={() => {
                  this.setState(
                    {
                      customerIsLoading: true,
                    },
                    () => {
                      this.callCustomerReadApi("", (arr) => {
                        this.setState({
                          customerIsLoading: false,
                          customer: arr,
                        });
                      });
                    }
                  );
                }}
                onChange={(values) => {
                  this.setState({
                    customerSearchColumns: values,
                    customerSiteSearchColumns: [],
                  });
                }}
              />
            </Grid>
          </Box>
          <Box clone>
            <Grid item xs={12} md={3} lg={4}>
              <ManageLabel
                className={"no-margin"}
                label={"Select Sites"}
                weight={"normal"}
                size={"xs"}
              />
              <RSuiteCheckPicker
                items={this.state.customerSite}
                value={this.state.customerSiteSearchColumns}
                placeholder={"Select Sites ..."}
                isLoading={this.state.customerSiteIsLoading}
                disabled={this.isCustomerSiteDisabled()}
                renderMenuItem={(label, item) => {
                  return (
                    <div>
                      {label}
                      {!item.item.isActive && (
                        <span
                          style={{ paddingLeft: "10px", fontWeight: "bold" }}
                          className={"text-red"}
                        >
                          (Deleted)
                        </span>
                      )}
                    </div>
                  );
                }}
                onOpen={() => {
                  this.setState(
                    {
                      customerSiteIsLoading: true,
                    },
                    () => {
                      this.callCustomerSiteReadApi("", (arr) => {
                        this.setState({
                          customerSiteIsLoading: false,
                          customerSite: arr,
                        });
                      });
                    }
                  );
                }}
                onChange={(values) => {
                  this.setState({
                    customerSiteSearchColumns: values,
                  });
                }}
              />
            </Grid>
          </Box>

          <Box clone textAlign={"right"}>
            <Grid item xs={12} md={6} lg={4}>
              <ManageLabel
                className={"no-margin"}
                label={<>&nbsp;</>}
                weight={"normal"}
                size={"xs"}
              />
              <Button
                className={"reports-primary-btn"}
                disabled={this.isRunReportBtnDisabled()}
                onClick={() => {
                  this.setState(
                    {
                      isLoading: false,
                      report: null,
                    },
                    () => {
                      this.callReportReadApi();
                    }
                  );
                }}
              >
                Run report
              </Button>
            </Grid>
          </Box>
        </Grid>
      </Box>
    );
  };

  infoSection = () => {
    return <CustomerInfo item={this.state.report} />;
  };

  hendleText = () => {
    this.setState({});
  };

  iconsSection = (ref) => {
    return (
      <Grid container>
        {}
        <IconsList
          items={[
            {
              id: 1,
              text: "Edit",
              disabled: this.state.isIconClicked,
              [this.state.onEditToggler ? 'button' : 'icon']: this.state.onEditToggler ? (
                <Button
                  onClick={() => {
                    this.setState({
                      onEditToggler: !this.state.onEditToggler,
                      isIconClicked: false,
                    });
                  }}
                  style={{
                    paddingRight: '5px'
                  }}
                  className={"reports-primary-btn"}
                  size="small"
                >
                  Save
                </Button>
              ) : (
                <EditIcon />
              ),
            },
            {
              id: 2,
              text: "Email",
              disabled: this.state.isIconClicked,
              icon: <MailIcon />,
            },
            {
              id: 3,
              text: "Print",
              disabled: this.state.isIconClicked,
              icon: <PrintIcon />,
            },
            {
              id: 4,
              text: "Download",
              button: (
                <DropdownFilter
                  isClickAway={true}
                  text={<DownloadIcon />}
                  button={IconButton}
                >
                  <Box p={3}>
                    <RadioGroup
                      aria-label="download"
                      name="download"
                      value={this.state.download}
                      onChange={(e) => {
                        this.setState({
                          download: e.target.value,
                        });
                      }}
                    >
                      <FormControlLabel
                        value={"1"}
                        control={<Radio disabled={this.state.isIconClicked} />}
                        label={"PDF"}
                      />
                      <FormControlLabel
                        value={"2"}
                        control={<Radio disabled={this.state.isIconClicked} />}
                        label={"EXCEL"}
                      />
                    </RadioGroup>
                    <Box pt={2}>
                      <Button
                        className={"reports-primary-btn"}
                        variant={"contained"}
                        color={"primary"}
                        disabled={this.state.isIconClicked}
                        onClick={() => {
                          if (this.state.download === "1") {
                            // PDF
                            this.setState(
                              {
                                isLoading: false,
                                isIconClicked: true,
                              },
                              () => {
                                apiUtil
                                  .getTemplateFromFile(
                                    "/templates/HtmlTemplate.html"
                                  )
                                  .then((result) => {
                                    let html1 = result.data.replace(
                                      "[[title]]",
                                      this.state.pageName
                                    );
                                    let html = html1.replace(
                                      "[[content]]",
                                      ref.current.outerHTML
                                    );
                                    this.callGeneratePDFApi(
                                      this.state.pageName,
                                      html,
                                      (fileName, data) => {
                                        apiUtil.saveByteArray(fileName, data);

                                        this.setState({
                                          isLoading: false,
                                          isIconClicked: false,
                                        });
                                      }
                                    );
                                  });
                              }
                            );
                          } else {
                            // EXCEL
                            this.setState(
                              {
                                isLoading: false,
                                isIconClicked: true,
                              },
                              () => {
                                this.callGenerateEXCELApi((data) => {
                                  let fileName = this.state.pageName + ".xls";
                                  let type = "application/vnd.ms-excel";

                                  apiUtil.saveByteArray(fileName, data, type);

                                  this.setState({
                                    isLoading: false,
                                    isIconClicked: false,
                                  });
                                });
                              }
                            );
                          }
                        }}
                      >
                        Download
                      </Button>
                    </Box>
                  </Box>
                </DropdownFilter>
              ),
            },
          ]}
          onClick={(item) => {
            if (item.id === 1) {
              // Edit
              this.setState(
                {
                  isIconClicked: true,
                },
                () => {
                  this.setState({
                    onEditToggler: !this.state.onEditToggler,
                  });
                }
              );
            } else if (item.id === 2) {
              // Email
              this.setState({
                isIconClicked: true,
                isSendEmailDialog: true,
                htmlSendEmailDialog: ref.current.outerHTML,
              });
            } else if (item.id === 3) {
              // Print
              this.setState(
                {
                  isLoading: true,
                  isIconClicked: true,
                },
                () => {
                  apiUtil
                    .getTemplateFromFile("/templates/HtmlTemplate.html")
                    .then((result) => {
                      let html1 = result.data.replace(
                        "[[title]]",
                        this.state.pageName
                      );
                      let html = html1.replace(
                        "[[content]]",
                        ref.current.outerHTML
                      );
                      apiUtil.printByteArray(this.state.pageName, html);

                      this.setState({
                        isLoading: false,
                        isIconClicked: false,
                      });
                    });
                }
              );
            }
          }}
        />
      </Grid>
    );
  };

  pageSection = () => {
    if (this.state.report && this.state.report.length > 0) {
      return this.state.report.map((item, i) => {
        return (
          <div className="page-break" key={i}>
            <div className={"row"}>
              {i > 0 && (
                <div
                  style={{ paddingTop: "50px", paddingBottom: "10px" }}
                  className={"col-xs-12"}
                >
                  <b>{this.state.pageName}</b>
                </div>
              )}
              <div className={"col-xs-12 pdf-col-sm-6 col-lg-6"}>
                <div className={"row"}>
                  <div className={"col-xs-12 pdf-col-sm-4 col-md-4 col-xl-4"}>
                    Customer
                  </div>
                  <div className={"col-xs-12 pdf-col-sm-8 col-md-8 col-xl-8"}>
                    {item && item.customerName ? item.customerName : <>-</>}
                  </div>
                </div>
                <div className={"row"}>
                  <div className={"col-xs-12 pdf-col-sm-4 col-md-4 col-xl-4"}>
                    Address
                  </div>
                  <div className={"col-xs-12 pdf-col-sm-8 col-md-8 col-xl-8"}>
                    {item && item.customerAddress ? (
                      item.customerAddress
                    ) : (
                      <>-</>
                    )}
                  </div>
                </div>
                <div className={"row"}>
                  <div className={"col-xs-12 pdf-col-sm-4 col-md-4 col-xl-4"}>
                    Remarks
                  </div>
                  <div className={"col-xs-12 pdf-col-sm-8 col-md-8 col-xl-8"}>
                    {this.state.onEditToggler ? (
                      <ManageInput
                        className={
                          "job-form-input bg-white plr-10 lh-25  no-padding"
                        }
                        placeholder={"Remarks"}
                        value={item.remarks}
                        multiline={true}
                        rows={1}
                        border={true}
                        onChange={(e) => {
                          let report = this.state.report;
                          report[i].remarks = e.target.value;
                          this.setState({
                            report: report,
                          });
                        }}
                      />
                    ) : (
                      <div>{item && item.remarks ? item.remarks : <>-</>}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className={"col-xs-12 pdf-col-sm-6 col-lg-6"}>
                <div className={"row"}>
                  <div className={"col-xs-12 pdf-col-sm-4 col-md-4 col-xl-4"}>
                    Attention to
                  </div>
                  {this.state.onEditToggler ? (
                    <div
                      className={
                        "col-xs-12 pdf-col-sm-8 col-md-8 col-xl-8  no-padding"
                      }
                    >
                      {" "}
                      <ManageInput
                        className={
                          "job-form-input bg-white no-padding plr-10 lh-25"
                        }
                        placeholder={" Attention To"}
                        value={item.customerAttentionTo}
                        multiline={true}
                        rows={1}
                        border={true}
                        onChange={(e) => {
                          let report = this.state.report;
                          report[i].customerAttentionTo = e.target.value;
                          this.setState({
                            report: report,
                          });
                        }}
                      />
                    </div>
                  ) : (
                    <div className={"col-xs-12 pdf-col-sm-8 col-md-8 col-xl-8"}>
                      {item && item.customerAttentionTo ? item.customerAttentionTo : <>-</>}
                    </div>
                  )}
                </div>
                <div className={"row"}>
                  <div className={"col-xs-12 pdf-col-sm-4 col-md-4 col-xl-4"}>
                    Report By
                  </div>
                  <div className={"col-xs-12 pdf-col-sm-8 col-md-8 col-xl-8"}>
                    {item && item.reportBy ? item.reportBy : <>-</>}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ paddingTop: "30px" }} className={"row"}>
              <div className={"col-xs-12"}>{this.printTable(item)}</div>
            </div>
          </div>
        );
      });
    } else {
      return this.printNoTable();
    }
  };

  printNoTable = () => {
    return (
      <Box className={"reports-table"}>
        <ReportTable className={"no-responsive-table"}>
          <>
            <ReportThead>
              <ReportTr>
                <ReportTh style={{ width: "20%" }}>Date</ReportTh>
                <ReportTh style={{ width: "20%" }}>Bin number</ReportTh>
                <ReportTh style={{ width: "20%" }}>Bin Type</ReportTh>
                <ReportTh style={{ width: "20%" }}>Waste Type</ReportTh>
                <ReportTh style={{ width: "20%" }}>DO Number</ReportTh>
              </ReportTr>
            </ReportThead>
            <ReportTbody>
              <ReportTr key={0}>
                <ReportTd colSpan={5}>
                  <Box textAlign={"center"}>No Result!</Box>
                </ReportTd>
              </ReportTr>
            </ReportTbody>
          </>
        </ReportTable>
      </Box>
    );
  };

  printTable = (items) => {
    if (items && items.data && items.data.length > 0) {
      return items.data.map((item, i) => {
        return (
          <Box className={"reports-table"} key={i}>
            <ReportTable className={"no-responsive-table"}>
              <>
                {this.printTableCaption(item)}
                {this.printTableHead(item)}
                {this.printTableBody(item)}
              </>
            </ReportTable>
          </Box>
        );
      });
    } else {
      return this.printNoTable();
    }
  };

  printTableCaption = (item) => {
    return <ReportCaption>{item.customerSiteName}</ReportCaption>;
  };

  printTableHead = (item) => {
    return (
      <ReportThead>
        <ReportTr>
          <ReportTh style={{ width: "20%" }}>Date</ReportTh>
          <ReportTh style={{ width: "20%" }}>Bin number</ReportTh>
          <ReportTh style={{ width: "20%" }}>Bin Type</ReportTh>
          <ReportTh style={{ width: "20%" }}>Waste Type</ReportTh>
          <ReportTh style={{ width: "20%" }}>DO Number</ReportTh>
        </ReportTr>
      </ReportThead>
    );
  };

  printTableBody = (item) => {
    let rows = [];

    if (item && item.data && item.data.length > 0) {
      rows.push(
        item.data.map((dataItem, i) => {
          return (
            <ReportTr key={i}>
              <ReportTd>{dataItem.jobDateDisplay}</ReportTd>
              <ReportTd>{dataItem.binNumber}</ReportTd>
              <ReportTd>{dataItem.binTypeName}</ReportTd>
              <ReportTd>{dataItem.wasteTypeName}</ReportTd>
              <ReportTd>{dataItem.jobNumber}</ReportTd>
            </ReportTr>
          );
        })
      );
    } else {
      rows.push(
        <ReportTr key={0}>
          <ReportTd colSpan={5}>
            <Box textAlign={"center"}>No Result!</Box>
          </ReportTd>
        </ReportTr>
      );
    }

    return <ReportTbody>{rows}</ReportTbody>;
  };
  /* END SECTIONS */

  /* DIALOGS */
  setSendEmailDialog = () => {
    return (
      <CustomDialog
        open={this.state.isSendEmailDialog}
        maxWidth={"lg"}
        onClose={() => {
          this.setState({
            isSendEmailDialog: false,
            isIconClicked: false,
            htmlSendEmailDialog: "",
          });
        }}
      >
        <SendEmailDialog
          item={null}
          isLoading={false}
          onClose={() => {
            this.setState({
              isSendEmailDialog: false,
              isIconClicked: false,
              htmlSendEmailDialog: "",
            });
          }}
          onOk={(isEdit, form) => {
            let htmlSendEmailDialog = this.state.htmlSendEmailDialog;

            if (form && form.emailAddresses && form.emailAddresses !== "") {
              apiUtil
                .getTemplateFromFile("/templates/HtmlTemplate.html")
                .then((result) => {
                  let html1 = result.data.replace(
                    "[[title]]",
                    this.state.pageName
                  );
                  let html = html1.replace("[[content]]", htmlSendEmailDialog);

                  this.callSendPDFEmailApi(
                    form.emailAddresses,
                    this.state.pageName,
                    html,
                    () => {
                      this.setState({
                        isSendEmailDialog: false,
                        isIconClicked: false,
                        htmlSendEmailDialog: "",
                      });
                    }
                  );
                });
            } else {
              this.setState({
                isSendEmailDialog: false,
                isIconClicked: false,
                htmlSendEmailDialog: "",
              });
            }
          }}
        />
      </CustomDialog>
    );
  };
  /* END DIALOGS */

  render() {
    return (
      <Box>
        <ReportsLayout
          auth={this.props.auth}
          isLoading={this.state.isLoading}
          report={this.state.report}
          titleSection={this.state.pageName}
          topSection={this.topSection()}
          infoSection={this.infoSection()}
          iconsSection={this.iconsSection}
          pageSection={this.pageSection()}
        />

        {this.setSendEmailDialog()}
        {this.setSnackBar()}
      </Box>
    );
  }
}


const mapStateToProps = state => {
  return {
    ...state,
    ...Utils.mapStateToProps(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  action: {
    signalRslice: {
      connect: (payload) => dispatch(signalRslice.connect(payload)),
      disconnect: (payload) => dispatch(signalRslice.disconnect(payload)),
      onPercentage: (payload) => dispatch(signalRslice.onPercentage(payload)),
    },
  }
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(BinsOnSIteView);
