import React from "react";
import Switch from "react-switch";

import { 
    Row,
    Col,
    Form,
} from "react-bootstrap-v5";

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import CustomTooltip from '../../../../components/Tooltip/Tooltip';

import Utils from "../../../../utils/utils";

import { connect } from 'react-redux'
import { clear } from "../../../../../setup/redux/actions";
import { dispatchApiCallPost, dispatchApiCallGet } from '../../../../../setup/redux/dispatch/actions'


class MultipleTripsSection extends React.Component {
  
    constructor(props) {
        super(props);
        
        this.name = 'job_form_multiple_trips_section';

        let dataParams = (props.dataParams) ? props.dataParams : null;
        let id = (dataParams && dataParams.id) ? dataParams.id : null;
        let row = (dataParams && dataParams.row) ? dataParams.row : null;
        

        this.state = {
            id: id,
            row: row,
        };
    }
    

    // componentWillReceiveProps(nextProps) {
    //     this.reduxProps(nextProps);
    // }


    /* API */
    reduxProps = nextProps => {
        
    }
    /* END API */


    body = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;
        
        return <Row className={'pt-10'}>
            {/* <Col xs={12} className={'mt-10 mb-10'}><hr /></Col> */}

            <Col xs={12}>
                <Row className={'align-items-center mb-7'}>
                    <Col xs={12} sm={'auto'}>
                        <h3>Multiple Trips</h3>
                    </Col>
                    <Col xs={12} sm={'auto'}>
                        <Switch 
                            checked={values.multipleTrip} 
                            onChange={async (checked ) => {
                                await setFieldValue('multipleTrip', checked);

                                if(!checked){
                                    await setFieldValue('numberOfTrip', 0);
                                    await setFieldValue('assignToSameDriverVehicle', false);
                                } else {
                                    await setFieldValue('numberOfTrip', 2);
                                }
                            }}
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor="#185cff"
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                        />
                    </Col>
                </Row>
            </Col>

            {values.multipleTrip && <Col xs={12}>
                <Row className={'align-items-center'}>
                    <Col xs={12} md={2}>
                        <span className={'pe-2'}>{this.props.fields.numberOfTrip.label}</span>
                        <CustomTooltip placement={'right'} title={this.props.fields.numberOfTrip.info}>
                            <InfoOutlinedIcon style={{ color: '#DFDFDF', width: '30px', height: '25px' }} />
                        </CustomTooltip>
                    </Col>
                    <Col xs={12} md={1}>
                        <Form.Control
                            type="number"
                            id={this.props.fields.numberOfTrip.id}
                            placeholder={this.props.fields.numberOfTrip.placeholder}
                            value={values.numberOfTrip}
                            onChange={async (e) => {
                                // let value = e.target.value;
                                // let pattern = Utils.minNumber2();

                                // if(value.match(pattern) != null){
                                    await setFieldValue('numberOfTrip', e.target.value);
                                // }
                            }}
                            onBlur={async () => {
                                if(values.numberOfTrip < 2){
                                    await setFieldValue('numberOfTrip', '');
                                }
                            }}
                            isInvalid={!!errors.numberOfTrip}
                        />
                    </Col>
                    <Col xs={12} md={'auto'}>
                        <Form.Check
                            id={this.props.fields.assignToSameDriverVehicle.id}
                            label={this.props.fields.assignToSameDriverVehicle.label}
                            checked={values.assignToSameDriverVehicle}
                            onChange={handleChange}
                            isInvalid={!!errors.assignToSameDriverVehicle}
                            feedback={errors.assignToSameDriverVehicle}
                        />
                    </Col>
                </Row>
            </Col>}
            
        </Row>
    }
    

    render() {
        return this.body()
    }
}

const mapDispatchToProps = {
    dispatchApiCallPost,
    dispatchApiCallGet,
    clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(MultipleTripsSection);
