import React from 'react';

import { 
    Modal,
    Button,
    Spinner,
    CloseButton,
    Row,
    Col,
    Nav,
    Tab,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import { BillingTypes } from '../../../../utils/enums';

import { useDispatch, useSelector } from "react-redux";
import invoiceNumber from '../../redux/invoiceNumber';
import signalRslice from "../../../../components/signalR/signalRslice";

import TabForm from './TabForm';
import TabInfo from './TabInfo';


const StyledBody = styled(Col)`
    max-height: 80vh;
    overflow: auto;
    width: 99%;
`;
const StyledNav = styled(Nav)`
    .nav-link {
        color: #484848;
        font-weight: bold;

        &[data-rb-event-key="1"] {
            &.active {
                color: #185cff;
            } 
        }

        &[data-rb-event-key="2"] {
            margin-left: 5px;
            margin-right: 5px;

            &.active {
                color: #CF3E3E;
            } 
        }

        &[data-rb-event-key="3"] {
            &.active {
                color: #28a745;
            } 
        }
    }
`;
const StyledCloseButton = styled.div`
    margin-left: auto;
    margin-right: 15px;
    align-items: center;
    display: flex;
`;
const StyledLoadingWrapper = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #00000030;
    display: flex;
    z-index: 9999;

    > div {
        display: flex;
        align-items: center;
        align-content: center;
        text-align: center;
        width: 100%;
    }
`;


const InvoiceNumberPopup = props => {
    const dispatch = useDispatch();
    const { isLoading, show, status, selectedTab, successItems, failedItems, billingType } = useSelector((state) => state.invoiceNumber);
    const { resetInvoiceNumber } = useSelector((state) => state.invoiceNumberForm);

    const [count, setCount] = React.useState(0);

    const refTabForm = React.useRef();
    const refTabFormFailed = React.useRef();

    const loading = () => {
        return <StyledLoadingWrapper>
            <Row>
                <Col xs={12} className={'px-7 py-5 text-center'}>
                    <Spinner animation="border" variant='primary' />
                </Col>
            </Row>
        </StyledLoadingWrapper>
    }
    const header = () => {
        return <Row>
            <Col xs={12}>
                <StyledNav variant="tabs">
                    {!status && <Nav.Item className={'order-2 order-sm-1 col-12 col-sm-auto text-center'}>
                        <Nav.Link eventKey={1} disabled={isLoading}>Create bulk {(billingType == BillingTypes.Purchase) ? 'purchase' : (billingType == BillingTypes.Expense) ? 'Expense' : 'invoices'}</Nav.Link>
                    </Nav.Item>}
                    {status && (failedItems.length > 0) && <Nav.Item className={'order-3 order-sm-2 col-6 col-sm-auto text-center'}>
                        <Nav.Link eventKey={2} disabled={isLoading}>Failed: {count}</Nav.Link>
                    </Nav.Item>}
                    {status && <Nav.Item className={'order-4 order-sm-3 col-5 col-sm-auto text-center'}>
                        <Nav.Link eventKey={3} disabled={isLoading}>Success: {successItems.length}</Nav.Link>
                    </Nav.Item>}
                    <StyledCloseButton className={'order-1 order-sm-4 mb-1 mb-sm-0'}>
                        <CloseButton
                            className={'rounded-2'}
                            disabled={isLoading}
                            onClick={async () => {
                                let allJobIds = await dispatch(invoiceNumber.getAllJobIds());
                                // await dispatch(invoiceNumber.hide());
            
                                if(props.onHide){
                                    setTimeout(() => {
                                        props.onHide(status, allJobIds);
                                    }, 0);
                                }
                            }}
                        />
                    </StyledCloseButton>
                </StyledNav>
            </Col>
        </Row>
    }
    const body = () => {
        return <Row>
            <StyledBody xs={12} className={'px-7 py-5'}>
                <Tab.Content>
                    {!status && <Tab.Pane eventKey={1}>
                        <TabForm 
                            ref={refTabForm}
                            onSubmit={async (form) => {
                                await dispatch(signalRslice.connect({
                                    callback: (percent) => {
                                        dispatch(signalRslice.onPercentage(percent));
                                    },
                                    callbackInit: async (hubId) => {
                                        await dispatch(invoiceNumber.callPushJobInvoicesApi(form, hubId, async (failedJobIds) => {
                                            if(failedJobIds && failedJobIds.length > 0){
                                                setTimeout(async () => {
                                                    await dispatch(invoiceNumber.callReadFailedJobsApi(failedJobIds));
                                                }, 100);
                                            }
                                        }));
        
                                        dispatch(signalRslice.disconnect());
                                    }
                                }));
                            }}
                            onHide={async () => {
                                await dispatch(invoiceNumber.hide());
                
                                if(props.onHide){
                                    setTimeout(() => {
                                        props.onHide(status, []);
                                    }, 0);
                                }
                            }}
                        />
                    </Tab.Pane>}
                    {status && (failedItems.length > 0) && <Tab.Pane eventKey={2}>
                        <TabForm 
                            ref={refTabFormFailed}
                            onCountChange={(count) => {
                                setCount(count);
                            }}
                        />
                    </Tab.Pane>}
                    {status && <Tab.Pane eventKey={3}>
                        <TabInfo />
                    </Tab.Pane>}
                </Tab.Content>
            </StyledBody>

            {isLoading && loading()}
        </Row>
    }
    const footer = () => {
        if(!status){
            return <Row className={'gx-2 ps-2 align-items-center'}>
                <Col xs={'auto'}>
                    <Button 
                        type={'button'}
                        size={'sm'}
                        disabled={isLoading || resetInvoiceNumber.isLoading}
                        onClick={() => {
                            if(refTabForm && refTabForm.current){
                                refTabForm.current.submitForm();
                            }
                        }}
                    >
                        Continue
                        {(isLoading || resetInvoiceNumber.isLoading) ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                    </Button>
                </Col>
                <Col xs={'auto'}>
                    <Button 
                        type={'button'}
                        variant={'secondary'}
                        disabled={isLoading || resetInvoiceNumber.isLoading}
                        size={'sm'}
                        onClick={async () => {
                            let allJobIds = await dispatch(invoiceNumber.getAllJobIds());
                            // await dispatch(invoiceNumber.hide());
            
                            if(props.onHide){
                                setTimeout(() => {
                                    props.onHide(status, allJobIds);
                                }, 0);
                            }
                        }}
                    >
                        Cancel
                        {(isLoading || resetInvoiceNumber.isLoading) ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                    </Button>
                </Col>
            </Row>
        }
    }
    const footerRetryAll = () => {
        return <Row className={'gx-2 ps-2 align-items-center'}>
            <Col xs={'auto'}>
                <Button 
                    type={'button'}
                    size={'sm'}
                    disabled={isLoading || resetInvoiceNumber.isLoading}
                    onClick={async () => {
                        await dispatch(signalRslice.connect({
                            callback: (percent) => {
                                dispatch(signalRslice.onPercentage(percent));
                            },
                            callbackInit: async (hubId) => {
                                let form = [];
                                if (refTabFormFailed && refTabFormFailed.current) {
                                    let failedItems = refTabFormFailed.current.getForm();
                                    form = failedItems.filter((customer) => customer.siteJobs.some((site) => site.jobs.some((job) => job.isOK == 'false')));
                                }

                                await dispatch(invoiceNumber.callRetryAllApi(form, hubId));

                                dispatch(signalRslice.disconnect());
                            }
                        }));
                    }}
                >
                    Retry all
                    {(isLoading || resetInvoiceNumber.isLoading) ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
        </Row>
    }


    return <Modal
        show={show}
        onHide={async () => {
            let allJobIds = await dispatch(invoiceNumber.getAllJobIds());
            // await dispatch(invoiceNumber.hide());

            if(props.onHide){
                setTimeout(() => {
                    props.onHide(status, allJobIds);
                }, 0);
            }
        }}
        backdrop={"static"}
        keyboard={false}
        enforceFocus={false}
        dialogClassName={'mw-90-vw'}
    >
        <Modal.Body className={'px-1 pt-1 pb-2'}>
            <Tab.Container 
                activeKey={selectedTab}
                onSelect={async (activeKey) => {
                    await dispatch(invoiceNumber.setSelectedTab(activeKey));
                }}
            >
                {header()}
                {body()}
                {(selectedTab == 1) && footer()}
                {(selectedTab == 2) && footerRetryAll()}
            </Tab.Container>
        </Modal.Body>
    </Modal>
}


export default InvoiceNumberPopup;
