import React from 'react';

import {
    Row,
    Col,
    Form,
    InputGroup,
} from "react-bootstrap-v5";

import Utils from '../../../../utils/utils';
import { ActiveInactive, AuditTrailObjectType } from '../../../../utils/enums';

import AuditTrailDialog from '../../../../components/OLD/Pages/AuditTrail/AuditTrailDialog.jsx';
import AuditTrailFunctions from '../../../../components/OLD/Pages/AuditTrail/AuditTrailFunctions.jsx';

import RSuiteDatePicker from '../../../../components/OLD/Datepicker/RSuiteDatePicker';
import RSuiteSelectPicker from '../../../../components/OLD/Select/RSuiteSelectPicker';
import SmartInputDropdown from '../../../../components/dropdown/smartInputDropdown/SmartInputDropdown';
import makeCRUD from "../../../../components/dropdown/smartInputDropdown/makeCRUD";

import { useDispatch, useSelector } from "react-redux";
import {RootState} from '../../../../../setup'
import driversDetails, { useFormikContext, fields } from '../redux/driversDetails';

import { StyledBasicCard, StyledBasicShadow, StyledTitle, StyledHistoryLink } from '../style/driversDetailsStyles';


const SmartDropdown = makeCRUD(SmartInputDropdown);


const BasicInfoTab = (props: any) => {
    const { values, errors, setFieldValue } = useFormikContext();

    const dispatch = useDispatch();
    const { isMobileDispatch, id } = useSelector((state: RootState) => state.driversDetails);

    const [passwordType, setPasswordType] = React.useState(true);
    
    const [isOpenAuditTrail, setIsOpenAuditTrail] = React.useState(false);
    const [objectId, setObjectId] = React.useState(null);


    const basicInfo = () => {
        return <Row>
            <Col xs={12} className={'mb-4'}>
                <StyledTitle>Basic info</StyledTitle>
                {id && <StyledHistoryLink
                    href={'/'}
                    style={{ position: 'relative', top: '5px' }}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        setIsOpenAuditTrail(true);
                        setObjectId(id);
                    }}
                >
                    <i className={'material-icons'}>history</i>
                </StyledHistoryLink>}
            </Col>
            <Col xs={12} className={'py-6'}>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group as={Row} className={'mb-3'}>
                            <Form.Label column sm={3} lg={2} xl={'auto'} style={{ minWidth: '150px' }}>{fields.driverName.label}</Form.Label>
                            <Col sm={9} lg={7}>
                                <Form.Control
                                    type="text"
                                    id={fields.driverName.id}
                                    placeholder={fields.driverName.placeholder}
                                    value={values.driverName}
                                    onChange={(e) => {
                                        setFieldValue('driverName', e.target.value);
                                    }}
                                    isInvalid={!!(errors && errors.driverName)}
                                />
                                {/* <Form.Control.Feedback type="invalid">{errors && errors.driverName}</Form.Control.Feedback> */}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className={'mb-3'}>
                            <Form.Label column sm={3} lg={2} xl={'auto'} style={{ minWidth: '150px' }}>{fields.isDeactivateOrDelete.label}</Form.Label>
                            <Col sm={9} lg={7}>
                                <Form.Control
                                    as = {RSuiteSelectPicker}

                                    id={fields.isDeactivateOrDelete.id}
                                    placeholder={fields.isDeactivateOrDelete.placeholder}
                                    items={[
                                        {
                                            value: ActiveInactive.ActiveValue,
                                            title: ActiveInactive.ActiveTitle,
                                        },
                                        {
                                            value: ActiveInactive.InactiveValue,
                                            title: ActiveInactive.InactiveTitle,
                                        }
                                    ]}
                                    value={values.isDeactivateOrDelete ? ActiveInactive.InactiveValue : ActiveInactive.ActiveValue}
                                    selectedName={values.isDeactivateOrDelete ? ActiveInactive.InactiveTitle : ActiveInactive.ActiveTitle}
                                    isLoading={false}
                                    searchable={false}
                                    cleanable={false}
                                    readOnly={false}
                                    isDefault={true}
                                    onSelect={(value: any, item: any) => {
                                        setFieldValue('isDeactivateOrDelete', (value === ActiveInactive.ActiveValue) ? false : true);
                                    }}
                                />
                                {/* <Form.Control.Feedback type="invalid">{errors && errors.isDeactivateOrDelete}</Form.Control.Feedback> */}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className={'mb-3'}>
                            <Form.Label column sm={3} lg={2} xl={'auto'} style={{ minWidth: '150px' }}>{fields.driverCategoryId.label}</Form.Label>
                            <Col sm={9} lg={7}>
                                <SmartDropdown
                                    isInvalid={!!errors.categoryId}
                                    errorText={errors.categoryId}

                                    componentTitle={'driverCategory'}
                                    componentApi={'driverCategory'}
                                    componentId={'driverCategoryId'}
                                    componentName={'driverCategoryName'}

                                    placeholder={fields.driverCategoryId.placeholder}
                                    value={values.driverCategoryId}
                                    label={values.driverCategoryName}

                                    disabled={false}
                                    showDefault={false}
                                    isInfiniteScroll={false}

                                    onChange={(value: any, item: any, i: any) => {
                                        setFieldValue('driverCategoryId', value);
                                        setFieldValue('driverCategoryName', item.title);
                                    }}
                                    onClear={() => {
                                        setFieldValue('driverCategoryId', null);
                                        setFieldValue('driverCategoryName', '');
                                    }}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className={'mb-3'}>
                            <Form.Label column sm={3} lg={2} xl={'auto'} style={{ minWidth: '150px' }}>{fields.vehicleId.label}</Form.Label>
                            <Col sm={9} lg={7}>
                                <SmartDropdown
                                    isInvalid={!!errors.vehicleId}
                                    errorText={errors.vehicleId}

                                    componentTitle={'vehicle'}
                                    componentApi={'vehicle'}
                                    componentId={'vehicleId'}
                                    componentName={'vehicleName'}

                                    placeholder={fields.vehicleId.placeholder}
                                    value={values.vehicleId}
                                    label={values.vehicleName}

                                    disabled={false}
                                    showDefault={false}
                                    isInfiniteScroll={false}

                                    onChange={(value: any, item: any, i: any) => {
                                        setFieldValue('vehicleId', value);
                                        setFieldValue('vehicleName', item.title);
                                    }}
                                    onClear={() => {
                                        setFieldValue('vehicleId', null);
                                        setFieldValue('vehicleName', '');
                                    }}
                                />
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group as={Row} className={'mb-3'}>
                            <Form.Label column sm={3} lg={2} xl={'auto'} style={{ minWidth: '150px' }}>{fields.idNumber.label}</Form.Label>
                            <Col sm={9} lg={7}>
                                <Form.Control
                                    type="text"
                                    id={fields.idNumber.id}
                                    placeholder={fields.idNumber.placeholder}
                                    value={values.idNumber}
                                    onChange={(e) => {
                                        setFieldValue('idNumber', e.target.value);
                                    }}
                                    isInvalid={!!(errors && errors.idNumber)}
                                />
                                {/* <Form.Control.Feedback type="invalid">{errors && errors.idNumber}</Form.Control.Feedback> */}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className={'mb-3'}>
                            <Form.Label column sm={3} lg={2} xl={'auto'} style={{ minWidth: '150px' }}>{fields.phoneNumber.label}</Form.Label>
                            <Col sm={9} lg={7}>
                                <Form.Control
                                    type="text"
                                    id={fields.phoneNumber.id}
                                    placeholder={fields.phoneNumber.placeholder}
                                    value={values.phoneNumber}
                                    onChange={(e) => {
                                        setFieldValue('phoneNumber', e.target.value);
                                    }}
                                    isInvalid={!!(errors && errors.phoneNumber)}
                                />
                                {/* <Form.Control.Feedback type="invalid">{errors && errors.phoneNumber}</Form.Control.Feedback> */}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className={'mb-3'}>
                            <Form.Label column sm={3} lg={2} xl={'auto'} style={{ minWidth: '150px' }}>{fields.homeAddress.label}</Form.Label>
                            <Col sm={9} lg={7}>
                                <Form.Control
                                    as="textarea"
                                    rows={5}
                                    id={fields.homeAddress.id}
                                    placeholder={fields.homeAddress.placeholder}
                                    value={values.homeAddress}
                                    onChange={(e) => {
                                        Utils.limitRows(e.target.value, 5, (value: any) => {
                                            setFieldValue('homeAddress', value);
                                        });
                                    }}
                                    isInvalid={!!(errors && errors.homeAddress)}
                                />
                                {/* <Form.Control.Feedback type="invalid">{errors && errors.homeAddress}</Form.Control.Feedback> */}
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>
            </Col>
        </Row>
    }

    const employment = () => {
        return <Row>
            <Col xs={12} className={'mb-4'}>
                <StyledTitle>Employment</StyledTitle>
            </Col>
            <Col xs={12} className={'py-6'}>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group as={Row} className={'mb-3'}>
                            <Form.Label column sm={3} lg={2} xl={'auto'} style={{ minWidth: '150px' }}>{fields.driverEmploymentTypeId.label}</Form.Label>
                            <Col sm={9} lg={7}>
                                <SmartDropdown
                                    isInvalid={!!errors.driverEmploymentTypeId}
                                    errorText={errors.driverEmploymentTypeId}

                                    componentTitle={'employmentType'}
                                    componentApi={'driver/employment-type'}
                                    componentId={'driverEmploymentTypeId'}
                                    componentName={'employmentTypeName'}

                                    placeholder={fields.driverEmploymentTypeId.placeholder}
                                    value={values.driverEmploymentTypeId}
                                    label={values.employmentTypeName}

                                    disabled={false}
                                    showDefault={false}
                                    isInfiniteScroll={false}

                                    onChange={(value: any, item: any, i: any) => {
                                        setFieldValue('driverEmploymentTypeId', value);
                                        setFieldValue('employmentTypeName', item.title);
                                    }}
                                    onClear={() => {
                                        setFieldValue('driverEmploymentTypeId', null);
                                        setFieldValue('employmentTypeName', '');
                                    }}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className={'mb-3'}>
                            <Form.Label column sm={3} lg={2} xl={'auto'} style={{ minWidth: '150px' }}>{fields.dateJoined.label}</Form.Label>
                            <Col sm={9} lg={7}>
                                <Form.Control
                                    as = {RSuiteDatePicker}
                                    isInvalid={!!errors.dateJoined}
                                    className={'form-control'}
                                    
                                    placeholder={fields.dateJoined.placeholder}
                                    id={fields.dateJoined.id}
                                    value={values.dateJoined}
                                    icon={<></>}
                                    placement={'bottomStart'}
                                    cleanable={true}
                                    isDefault={true}
                                    readOnly={false}
                                    oneTap={true}
                                    onChangeCalendarDate={(value: any) => {
                                        setFieldValue('dateJoined', value);
                                    }}
                                    onChange={(value: any) => {
                                        setFieldValue('dateJoined', value);
                                    }}
                                />
                                {/* <Form.Control.Feedback type="invalid">{errors.dateJoined}</Form.Control.Feedback> */}
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group as={Row} className={'mb-3'}>
                            <Form.Label column sm={3} lg={2} xl={'auto'} style={{ minWidth: '150px' }}>{fields.dateResigned.label}</Form.Label>
                            <Col sm={9} lg={7}>
                                <Form.Control
                                    as = {RSuiteDatePicker}
                                    isInvalid={!!errors.dateResigned}
                                    className={'form-control'}
                                    
                                    placeholder={fields.dateResigned.placeholder}
                                    id={fields.dateResigned.id}
                                    value={values.dateResigned}
                                    icon={<></>}
                                    placement={'bottomStart'}
                                    cleanable={true}
                                    isDefault={true}
                                    readOnly={false}
                                    oneTap={true}
                                    onChangeCalendarDate={(value: any) => {
                                        setFieldValue('dateResigned', value);
                                    }}
                                    onChange={(value: any) => {
                                        setFieldValue('dateResigned', value);
                                    }}
                                />
                                {/* <Form.Control.Feedback type="invalid">{errors.dateResigned}</Form.Control.Feedback> */}
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>
            </Col>
        </Row>
    }

    const driverMobile = () => {
        return <Row>
            <Col xs={12} className={'mb-2'}>
                <StyledTitle>Access to Driver mobile app</StyledTitle>
            </Col>
            <Col xs={12} className={'py-6'}>
                <Row>
                    <Col xs={12} className={'mb-4'}>
                        <Form.Check
                            type="switch"
                            id={fields.isAssignJobToPhone.id}
                            label={null}
                            checked={values.isAssignJobToPhone}
                            onChange={async (e) => {
                                await setFieldValue('isAssignJobToPhone', e.target.checked);
                            }}
                            isInvalid={!!errors.isAssignJobToPhone}
                            feedback={errors.isAssignJobToPhone}
                        />
                    </Col>

                    {values.isAssignJobToPhone && <>
                        <Col xs={12} md={6}>
                            <Form.Group as={Row} className={'mb-3'}>
                                <Form.Label column sm={3} lg={2} xl={'auto'} style={{ minWidth: '150px' }}>{fields.userName.label}</Form.Label>
                                <Col sm={9} lg={7}>
                                    <Form.Control
                                        type="text"
                                        id={fields.userName.id}
                                        placeholder={fields.userName.placeholder}
                                        value={values.userName}
                                        autoComplete='off'
                                        onChange={(e) => {
                                            setFieldValue('userName', e.target.value);
                                        }}
                                        isInvalid={!!(errors && errors.userName)}
                                    />
                                    {/* <Form.Control.Feedback type="invalid">{errors && errors.userName}</Form.Control.Feedback> */}
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group as={Row} className={'mb-3'}>
                                <Form.Label column sm={3} lg={2} xl={'auto'} style={{ minWidth: '150px' }}>{fields.password.label}</Form.Label>
                                <Col sm={9} lg={7}>
                                    <InputGroup className={'rounded-1 overflow-hidden'}>
                                        <Form.Control
                                            type={passwordType ? 'password' : 'text'}
                                            id={fields.password.id}
                                            placeholder={fields.password.placeholder}
                                            value={values.password}
                                            autoComplete="new-password"
                                            onChange={(e) => {
                                                setFieldValue('password', e.target.value);
                                            }}
                                            isInvalid={!!(errors && errors.password)}
                                        />
                                        <InputGroup.Text
                                            className={'cursor-pointer'}
                                            onClick={() => {
                                                setPasswordType(!passwordType)
                                            }}
                                        >
                                            <i className={'material-icons'} style={{ fontSize: '18px' }}>{passwordType ? 'visibility_off' : 'visibility'}</i>
                                        </InputGroup.Text>
                                        
                                        <Form.Control.Feedback type="invalid">{errors && errors.password}</Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                        </Col>
                    </>}
                </Row>
            </Col>
        </Row>
    }

    const setAuditTrailDialog = () => {
        return <AuditTrailDialog 
            isOpen={isOpenAuditTrail}
            objectId={objectId}
            auditTrailObjectType={AuditTrailObjectType.Driver}
            title={"Driver Audit Trail"}
            onClose={() => {
                setIsOpenAuditTrail(false);
                setObjectId(null);
            }}
            details={(obj: any, index: any, currentVersion: any, previousVersion: any) => {
                return AuditTrailFunctions.getDetails(AuditTrailObjectType.Driver, obj, index, currentVersion, previousVersion);
            }}
            manageData={(currentVersion: any, previousVersion: any, callback: any) => {
                return AuditTrailFunctions.getManageData(AuditTrailObjectType.Driver, currentVersion, previousVersion, callback);
            }}
        />
    }


    return <StyledBasicShadow>
        {!isMobileDispatch && <StyledBasicCard xs={12} hidebottomborder={'true'}>
            {basicInfo()}
            {setAuditTrailDialog()}
        </StyledBasicCard>}
        {!isMobileDispatch && <StyledBasicCard xs={12} hidebottomborder={'true'}>{employment()}</StyledBasicCard>}
        <StyledBasicCard xs={12}>{driverMobile()}</StyledBasicCard>
    </StyledBasicShadow>
}


export default BasicInfoTab;
