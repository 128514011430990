import React from "react";
import moment from 'moment';
import _ from 'lodash';
import CurrencyInput from 'react-currency-input-field';

import { 
    Row,
    Col,
    Form,
    Spinner,
    Button,
    InputGroup,
    Dropdown,
} from "react-bootstrap-v5";

import { FieldArray } from 'formik';

import { DrawerContentLayout, DrawerPageContentLayout } from '../../../components/drawer/DrawerLayout'
import Utils from "../../../utils/utils";
import { BillingTypes, ServiceType, BillingStatus, ReportUOM, XeroPaymentTermTypeItems, XeroPaymentTermType } from "../../../utils/enums";
import { StyledWeightChitTable, StyledDropdownButton } from "../../../utils/styles";

import InputLayout from "../../../components/input/InputLayout";
import RSuiteSelectPicker from '../../../components/OLD/Select/RSuiteSelectPicker';
import RSuiteDatePicker from '../../../components/OLD/Datepicker/RSuiteDatePicker';
import RSuiteTimePicker from '../../../components/OLD/TimePicker/RSuiteTimePicker';
import IconButton from "../../../components/Buttons/IconButton";
// import IconButtonTooltip from "../../../components/Buttons/IconButtonTooltip";
import SmartInputDropdown from '../../../components/dropdown/smartInputDropdown/SmartInputDropdown';
import makeCRUD from "../../../components/dropdown/smartInputDropdown/makeCRUD";

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet, dispatchApiCallPost } from '../../../../setup/redux/dispatch/actions'
import taxRate from "../../../../setup/redux/slices/taxRate";

import billingRefresh from "../redux/billingRefresh";


const SmartDropdown = makeCRUD(SmartInputDropdown);


class RowDrawerForm extends React.Component {
  
    constructor(props) {
        super(props);
    
        this.bodyRef = React.createRef();

        // let dataParams = (props.dataParams) ? props.dataParams : null;
        // let id = (dataParams && dataParams.id) ? dataParams.id : null;
    
        
        let billingType = (props && props.billingType) ? props.billingType : BillingTypes.Invoice;


        this.state = {
            billingType: billingType,

            isReadOnlyInvoiceNumber: true,
        };
    }
    
    
    // componentDidMount(){}

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
		Utils.reduxProps(nextProps,
            'billing-row-drawer_row-billing-rofile', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.accountBillingProfileId;
                        let title = item.billingProfileName;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            'billing-row-drawer_row-quickBook-credit-terms', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if(data && data.length > 0) {
                    for (var i = 0; i < data.length; i++) {
                        let item = data[i];
                        let value = item.id;
                        let title = item.name;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );

		Utils.reduxProps(nextProps,
            'billing-row-drawer_row-billing-charge-category', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = Array.from(variables.items);

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.chargeCategoryId;
                        let title = item.chargeCategoryName;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            'billing-row-drawer_row-additional_charge_template', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.serviceAdditionalChargeTemplateId;
                        let title = item.serviceAdditionalChargeName;
                        let arrItem = {
                            value: value,
                            title: title,
                            label: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(this.state.isError){
                        Utils.toast(this.state.err, 'error'); 
                    }
                
                    if(callback){
                        callback(arr);
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            'billing-row-drawer-service_customer_site_details', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = [];

            if(data && data.data && data.data.length > 0) {
              arr = data.data;
            }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }

                    if(this.props.setIsBlocking){
                        this.props.setIsBlocking(false);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            'billing-row-drawer-prompt-number', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = [];
                try {
                    arr = (data && data != '') ? data.split('-') : [data];
                } catch(e){}
        
                let prefix = (arr && arr.length > 0) ? arr[0] : '';
                let invoiceNumber = (arr && arr.length > 0) ? arr[arr.length-1] : '';
                
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();
        
                    if(callback){
                        callback(data, prefix, invoiceNumber);
                    }
                });
            }
        );
    }
	
    callReadBillingProfileApi = (items, callback = null) => {
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            isActive: true,
        };
     
        this.props.dispatchApiCallGet(data, 'billing-row-drawer_row-billing-rofile', 'AccountBillingProfile', { items: items }, callback, () => {});
    }
    
    callQuickbookReadCreditTermsApi = (quickBookAppId = 0, items, callback = null) => {
        let data = {
            quickBookAppId: quickBookAppId,
        };
     
        this.props.dispatchApiCallGet(data, 'billing-row-drawer_row-quickBook-credit-terms', 'quickbookapp/term', { items: items }, callback, () => {});
    }

    callReadChargeCategoryApi = (items, callback = null) => {
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            isActive: true,
        };
     
        this.props.dispatchApiCallGet(data, 'billing-row-drawer_row-billing-charge-category', 'chargecategory', { items: items }, callback, () => {});
    }

    callReadAdditionalServicesApi = (items, search = '', callback = null) => {
        let dataParams = (this.props.dataParams && this.props.dataParams.row) ? this.props.dataParams.row : null;

        let customer = (dataParams && dataParams.customer) ? dataParams.customer : null;
        let customerId = (customer && customer.customerId) ? customer.customerId : null;

        let steps = (dataParams && dataParams.steps) ? dataParams.steps : [];
        let customerSiteItems = steps.filter(x => x.customerSiteId > 0);
        let customerSiteId = (customerSiteItems && customerSiteItems.length > 0) ? customerSiteItems[0].customerSiteId: null;

        let data = {
            currentPage: 1,
            pageSize: 30,
            searchQuery: search,
            sortColumn: 'serviceTag',
            sortDir: 'desc',
            customerId: customerId,
            customerSiteId: customerSiteId,
        };
     
        this.props.dispatchApiCallGet(data, 'billing-row-drawer_row-additional_charge_template', 'service/additional-charge', { items: items }, callback, () => {});
    }

    callServiceCustomerSiteDetailsApi = (callback = null) => {
        let dataParams = (this.props.dataParams && this.props.dataParams.row) ? this.props.dataParams.row : null;
        let customer = (dataParams && dataParams.customer) ? dataParams.customer : null;
        let customerId = (customer && customer.customerId) ? customer.customerId: null;

        if(customerId){
            if(this.props.setIsBlocking){
                this.props.setIsBlocking(true);
            }
        
            let data = {
                currentPage: 1,
                pageSize: Utils.getMaxPageSize(),
                searchQuery: '',
                sortColumn: 'serviceTag',
                sortDir: 'desc',
                includeGlobalService: true,
                customerId: customerId,
            }

            this.props.dispatchApiCallGet(data, 'billing-row-drawer-service_customer_site_details', 'service-item', null, callback, () => {});
        } else {
            if(callback){
                callback(null)
            }
        }
    }

    callPromptInvoiceNumberApi = (callback = null) => {
        let obj = this.getInvoiceNumberParams();

        if(this.state.billingType === BillingTypes.Purchase){
            let data = {
                accountBillingProfileId: obj.accountBillingProfileId,
                purchaseOrderDate: obj.invoiceDate ? obj.invoiceDate : moment().format("YYYY-MM-DDTHH:mm:ss.000"),
            };
        
            this.props.dispatchApiCallPost(data, 'billing-row-drawer-prompt-number', 'job/billing/prompt-po-number', null, callback, () => {});
        } else if(this.state.billingType === BillingTypes.Expense){
            let data = {
                accountBillingProfileId: obj.accountBillingProfileId,
                expenseDate: obj.invoiceDate ? obj.invoiceDate : moment().format("YYYY-MM-DDTHH:mm:ss.000"),
            };
        
            this.props.dispatchApiCallPost(data, 'billing-row-drawer-prompt-number', 'job/billing/prompt-expense-number', null, callback, () => {});
        } else {
            let data = {
                accountBillingProfileId: obj.accountBillingProfileId,
                invoiceDate: obj.invoiceDate ? obj.invoiceDate : moment().format("YYYY-MM-DDTHH:mm:ss.000"),
            };
        
            this.props.dispatchApiCallPost(data, 'billing-row-drawer-prompt-number', 'job/billing/prompt-invoice-number', null, callback, () => {});
        }
    }
    /* END API */


    /* FUNCTIONS */
    updateBilling = async () => {
        let {
            values,
            setFieldValue,
        } = this.props.formOptions;
        
        if(this.props.isDedicatedForm && this.props.setIsBlocking){
            this.props.setIsBlocking(true);
        }

        let id = (this.props.dataParams && this.props.dataParams.id) ? this.props.dataParams.id : null;
        let chargeCategoryItems = (values && values.customFormParams && values.customFormParams && values.customFormParams.chargeCategoryItems && values.customFormParams.chargeCategoryItems.length > 0) ? values.customFormParams.chargeCategoryItems : [];
        await this.props.action.billingRefresh.callRefreshPricingsApi({
            jobId: id,
            oldPricings: values.pricings,
            chargeCategoryItems: chargeCategoryItems,
        });
        await setFieldValue('pricings', this.props.billingRefresh.pricings);
        await setFieldValue('customFormParams.chargeCategoryItems', this.props.billingRefresh.chargeCategoryItems);

        if(this.props.isDedicatedForm && this.props.setIsBlocking){
            this.props.setIsBlocking(false);
        }
    }
    
    getInvoiceNumberParams = () => {
        let {
            values,
        } = this.props.formOptions;
        
        let accountBillingProfileId = (values && values.accountBillingProfileId) ? values.accountBillingProfileId : null;
        let invoiceDate = (values && values.invoiceDate) ? values.invoiceDate : new Date();

        return {
            accountBillingProfileId: accountBillingProfileId,
            invoiceDate: invoiceDate,
        };
    }
    
    getAdditionalServiceParams = () => {
        let dataParams = (this.props.dataParams && this.props.dataParams.row) ? this.props.dataParams.row : null;

        let customer = (dataParams && dataParams.customer) ? dataParams.customer : null;
        let customerId = (customer && customer.customerId) ? customer.customerId : null;

        let steps = (dataParams && dataParams.steps) ? dataParams.steps : [];
        let customerSiteItems = steps.filter(x => x.customerSiteId > 0);
        let customerSiteId = (customerSiteItems && customerSiteItems.length > 0) ? customerSiteItems[0].customerSiteId: null;

        return {
            type: ServiceType.AdditionalService,
            customerId: customerId,
            customerSiteId: customerSiteId,
            includeGlobalService: true,
            IncludeTaxRate: true,
        };
    }
    /* END FUNCTIONS */


    body = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;
            
        let row = (this.props.dataParams && this.props.dataParams.row) ? this.props.dataParams.row : null;
        let jobNumber = (row && row.jobNumber) ? row.jobNumber : null;
        let customerName = (row && row.customerName) ? row.customerName : null;

        
        let billingStatusItems = [
            {
                value: BillingStatus.NonBillable,
                title: 'Non-Billable',
            },
            {
                value: BillingStatus.Unbilled,
                title: 'Unbilled',
            },
            {
                value: BillingStatus.MarkAsBilled,
                title: 'Mark as billed',
            },
            {
                value: BillingStatus.Billed,
                title: 'Billed',
            },
            {
                value: BillingStatus.MarkAsPaid,
                title: 'Mark as Paid',
            },
        ];
        let billingStatusDisabledItems = Utils.billingStatusDisabledItems(values.isBillable, values.isInvoiced, values.customFormParams.quickbookInvoiceId, values.customFormParams.xeroInvoiceId, values.customFormParams.quickbookPurchaseOrderId);
        let billingStatusValue = Utils.checkBillingStatus(values.isBillable, values.isInvoiced, values.isPaid, values.customFormParams.quickbookInvoiceId, values.customFormParams.xeroInvoiceId, values.customFormParams.quickbookPurchaseOrderId);
        
        return <Row className={'p-0'}>
            <Col xs={12}>
                <Row className={'px-1 py-1 px-md-10 py-md-5'}>
                    <Col xs={12} lg={6}>
                        <Row>
                            {customerName && <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.customerName.label}
                                >
                                    <Form.Control
                                        value={customerName}
                                        readOnly
                                    />
                                </InputLayout>
                            </Col>}

                            {jobNumber && <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.jobNumber.label}
                                >
                                    <Form.Control
                                        value={jobNumber}
                                        readOnly
                                    />
                                </InputLayout>
                            </Col>}

                            <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.jobStatusName.label}
                                >
                                    <Form.Control
                                        value={values.customFormParams.jobStatusName}
                                        readOnly
                                    />
                                </InputLayout>
                            </Col>
                            <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.customerSiteName.label}
                                >
                                    <Form.Control
                                        value={values.customFormParams.customerSiteName}
                                        readOnly
                                    />
                                </InputLayout>
                            </Col>
                            <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.jobTypeName.label}
                                >
                                    <Form.Control
                                        value={values.customFormParams.jobTypeName}
                                        readOnly
                                    />
                                </InputLayout>
                            </Col>
                            <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.jobDate.label}
                                >
                                    <Form.Control
                                        value={(values && values.customFormParams && values.customFormParams.jobDate) ? moment(values.customFormParams.jobDate).format(Utils.getDefaultDateTimeFormat()) : ''}
                                        readOnly
                                    />
                                </InputLayout>
                            </Col>
                            <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.remarks.label}
                                >
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        value={values.customFormParams.remarks}
                                        readOnly
                                    />
                                </InputLayout>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} lg={6}>
                        <Row>
                            <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.isInvoiced.label}
                                >
                                    <Form.Control
                                        as = {RSuiteSelectPicker}

                                        id={'status'}
                                        placeholder={''}
                                        items={billingStatusItems}
                                        value={billingStatusValue}
                                        disabledItemValues={billingStatusDisabledItems}
                                        isLoading={false}
                                        searchable={false}
                                        cleanable={false}
                                        readOnly={false}
                                        isDefault={true}
                                        renderValue={(value, item, selectedElement) => {
                                            let index = billingStatusItems.findIndex(x => x.value == value);
                                            let title = (index > -1) ? billingStatusItems[index].title : '';
                                            return <span className={(value === 0) ? 'text-danger' : ''}>{title}</span>
                                        }}
                                        onSelect={async (value, item) => {
                                            let obj = Utils.getBillingStatus(value);
                                            await setFieldValue('isBillable', obj.isBillable);
                                            await setFieldValue('isInvoiced', obj.isInvoiced);

                                            
                                            if(this.props.onChangeStatus){
                                                this.props.onChangeStatus(obj.isBillable);
                                            }
                                        }}
                                    />
                                </InputLayout>
                            </Col>

                            <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.accountBillingProfileId.label}
                                >
                                    <Form.Control
                                        as = {RSuiteSelectPicker}
                                        isInvalid={!!errors.accountBillingProfileId}

                                        id={this.props.fields.accountBillingProfileId.id}
                                        placeholder={this.props.fields.accountBillingProfileId.placeholder}
                                        items={values.customFormParams.accountBillingProfileItems}
                                        value={values.accountBillingProfileId}
                                        selectedName={values.accountBillingProfileName}
                                        isLoading={values.customFormParams.accountBillingProfileIsLoading}
                                        searchable={true}
                                        cleanable={true}
                                        isDefault={true}
                                        onClick={async () => {
                                            await setFieldValue('customFormParams.accountBillingProfileIsLoading', true);
                                            this.callReadBillingProfileApi(values.customFormParams.accountBillingProfileItems, async (items) => {
                                                await setFieldValue('customFormParams.accountBillingProfileItems', items);
                                                await setFieldValue('customFormParams.accountBillingProfileIsLoading', false);
                                            });
                                        }}
                                        onSelect={async (value, item) => {
                                            let quickBookApp = (item && item.item && item.item.quickBookApp) ? item.item.quickBookApp : null;
                                            let isConnectQuickbook = (item && item.item && item.item.isConnectQuickbook) ? item.item.isConnectQuickbook : false;
                                            let isConnectXero = (item && item.item && item.item.isConnectXero) ? item.item.isConnectXero : false;
                                            let quickBookAppId = (quickBookApp && quickBookApp.quickBookAppId) ? quickBookApp.quickBookAppId : 0;

                                            await setFieldValue('accountBillingProfileId', value);
                                            await setFieldValue('accountBillingProfileName', item.title);
                                            await setFieldValue('customFormParams.quickBookAppId', quickBookAppId);
                                            await setFieldValue('customFormParams.isConnectQuickbook', isConnectQuickbook);
                                            await setFieldValue('customFormParams.isConnectXero', isConnectXero);

                                            if(isConnectXero){
                                                let xeroApp = (item && item.item && item.item.xeroApp) ? item.item.xeroApp : null;
                                                let xeroPaymentTermType = (xeroApp && xeroApp.xeroPaymentTermType) ? xeroApp.xeroPaymentTermType.toString() : '0';
                                                let xeroPaymentTerm = (xeroApp && xeroApp.xeroPaymentTerm) ? xeroApp.xeroPaymentTerm.toString() : '0';
                                                await setFieldValue('xeroPaymentTermType', xeroPaymentTermType);
                                                await setFieldValue('xeroPaymentTerm', xeroPaymentTerm);
                                            }
                                        }}
                                        onClear={async () => {
                                            await setFieldValue('accountBillingProfileId', null);
                                            await setFieldValue('accountBillingProfileName', '');
                                            await setFieldValue('xeroPaymentTermType', '0');
                                            await setFieldValue('xeroPaymentTerm', '0');
                                            await setFieldValue('customFormParams.quickBookAppId', 0);
                                            await setFieldValue('customFormParams.isConnectQuickbook', false);
                                            await setFieldValue('customFormParams.isConnectXero', false);
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.accountBillingProfileId}</Form.Control.Feedback>
                                </InputLayout>
                            </Col>

                            <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.billingName.label}
                                >
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        value={values.customFormParams.billingName}
                                        readOnly
                                    />
                                </InputLayout>
                            </Col>

                            <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.billingAddress.label}
                                >
                                    <Form.Control
                                        as="textarea"
                                        rows={4}
                                        id={this.props.fields.billingAddress.id}
                                        placeholder={this.props.fields.billingAddress.placeholder}
                                        value={values.billingAddress}
                                        onChange={async (e) => {
                                            Utils.limitRowsBillingAddress(e.target.value, 4, async (value) => {
                                                await setFieldValue('billingAddress', value);
                                            });
                                        }}
                                        isInvalid={!!errors.billingAddress}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.billingAddress}</Form.Control.Feedback>
                                </InputLayout>
                            </Col>

                            <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.billedDate.label}
                                >
                                    <Form.Control
                                        as = {RSuiteDatePicker}
                                        isInvalid={!!errors.billedDate}

                                        placeholder={this.props.fields.billedDate.placeholder}
                                        id={this.props.fields.billedDate.id}
                                        value={values.billedDate}
                                        icon={<></>}
                                        placement={'bottom'}
                                        cleanable={true}
                                        readOnly={true}
                                        isDefault={true}
                                        onChange={(value) => {
                                            
                                        }}
                                        onClear={() => {
                                            
                                        }}
                                    />
                                </InputLayout>
                            </Col>

                            {values.customFormParams.isConnectQuickbook && <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.paymentTermsId.label}
                                >
                                    <Form.Control
                                        as = {RSuiteSelectPicker}
                                        isInvalid={!!errors.paymentTermsId}

                                        id={this.props.fields.paymentTermsId.id}
                                        placeholder={this.props.fields.paymentTermsId.placeholder}
                                        items={values.customFormParams.paymentTermsItems}
                                        value={values.paymentTermsId}
                                        selectedName={values.paymentTermsName}
                                        isLoading={values.customFormParams.paymentTermsIsLoading}
                                        searchable={true}
                                        cleanable={true}
                                        isDefault={true}
                                        onClick={async () => {
                                            await setFieldValue('customFormParams.paymentTermsIsLoading', true);
                                            this.callQuickbookReadCreditTermsApi(values.customFormParams.quickBookAppId, values.customFormParams.paymentTermsItems, async (items) => {
                                                await setFieldValue('customFormParams.paymentTermsItems', items);
                                                await setFieldValue('customFormParams.paymentTermsIsLoading', false);
                                            });
                                        }}
                                        onSelect={async (value, item) => {
                                            await setFieldValue('paymentTermsId', value);
                                            await setFieldValue('paymentTermsName', item.title);
                                            await setFieldValue('xeroPaymentTerm', '0');
                                        }}
                                        onClear={async () => {
                                            await setFieldValue('paymentTermsId', null);
                                            await setFieldValue('paymentTermsName', '');
                                            await setFieldValue('xeroPaymentTerm', '0');
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.paymentTermsId}</Form.Control.Feedback>
                                </InputLayout>
                            </Col>}
                            {values.customFormParams.isConnectXero && <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.xeroPaymentTerm.label}
                                >
                                    <InputGroup>
                                        <Form.Control
                                            type="number"
                                            id={this.props.fields.xeroPaymentTerm.id}
                                            placeholder={this.props.fields.xeroPaymentTerm.placeholder}
                                            value={values.xeroPaymentTerm}
                                            onChange={async (e) => {
                                                await setFieldValue('xeroPaymentTerm', e.target.value);
                                                await setFieldValue('paymentTermsId', null);
                                                await setFieldValue('paymentTermsName', '');
                                            }}
                                            isInvalid={!!errors.xeroPaymentTerm}
                                        />
                                        <StyledDropdownButton
                                            title={Utils.getXeroPaymentTermTypeName(values.xeroPaymentTermType)}
                                            variant={'secondary'}
                                            menuAlign={'right'}
                                        >
                                            {XeroPaymentTermTypeItems.map((item, i) => {
                                                return <Dropdown.Item
                                                    className={'py-2'}
                                                    key={item.value + '_' + item.title + '_' + i}
                                                    active={(item.value === values.xeroPaymentTermType)}
                                                    onClick={async () => {
                                                        await setFieldValue('xeroPaymentTermType', item.value);
                                                    }}
                                                >{item.title}</Dropdown.Item>
                                            })}
                                        </StyledDropdownButton>
                                        <Form.Control.Feedback type="invalid">{errors.xeroPaymentTerm}</Form.Control.Feedback>
                                    </InputGroup>
                                </InputLayout>
                            </Col>}

                            {values.isBillable && <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.invoiceDate.label}
                                >
                                    <Form.Control
                                        as = {RSuiteDatePicker}
                                        isInvalid={!!errors.invoiceDate}
                                        
                                        placeholder={this.props.fields.invoiceDate.placeholder}
                                        id={this.props.fields.invoiceDate.id}
                                        value={values.invoiceDate}
                                        icon={<></>}
                                        placement={'bottom'}
                                        cleanable={true}
                                        isDefault={true}
                                        onChange={async (value) => {
                                            await setFieldValue('invoiceDate', value);
                                        }}
                                        onClear={async () => {
                                            await setFieldValue('invoiceDate', null);
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.invoiceDate}</Form.Control.Feedback>
                                </InputLayout>
                            </Col>}
                            {values.isBillable && <Col xs={12}>
                                <InputLayout
                                    label={<>
                                        {(this.state.billingType === BillingTypes.Invoice) ? this.props.fields.invoiceNumber.label : 'PO number'}
                                        {/* {(billingStatusValue == BillingStatus.Unbilled) && <IconButtonTooltip
                                            title={'Update ' + ((this.state.billingType === BillingTypes.Invoice) ? this.props.fields.invoiceNumber.label : 'PO number')}
                                            placement={'top'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();

                                                this.callPromptInvoiceNumberApi(async (data, prefix, invoiceNumber) => {
                                                    await setFieldValue('invoiceNumber', data);

                                                    this.setState({
                                                        isReadOnlyInvoiceNumber: false,
                                                    });
                                                });
                                            }}
                                        >
                                            <i className={'material-icons'}>refresh</i>
                                        </IconButtonTooltip>} */}
                                    </>}
                                >
                                    <Form.Control
                                        type="text"
                                        id={this.props.fields.invoiceNumber.id}
                                        placeholder={this.props.fields.invoiceNumber.placeholder}
                                        readOnly={this.state.isReadOnlyInvoiceNumber}
                                        value={values.invoiceNumber}
                                        onChange={handleChange}
                                        isInvalid={!!errors.invoiceNumber}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.invoiceNumber}</Form.Control.Feedback>
                                </InputLayout>
                            </Col>}
                        </Row>
                    </Col>
                    <Col xs={12}>
                        <InputLayout
                            label={this.props.fields.billingNote.label}
                            labelProps={{
                                xs: 12,
                            }}
                            inputProps={{
                                xs: 12,
                            }}
                        >
                            <Form.Control
                                as="textarea"
                                rows={5}
                                id={this.props.fields.billingNote.id}
                                placeholder={this.props.fields.billingNote.placeholder}
                                value={values.billingNote}
                                onChange={async (e) => {
                                    Utils.limitRows(e.target.value, 5, async (value) => {
                                        await setFieldValue('billingNote', value);
                                    });
                                }}
                                isInvalid={!!errors.billingNote}
                            />
                            <Form.Control.Feedback type="invalid">{errors.billingNote}</Form.Control.Feedback>
                        </InputLayout>
                    </Col>
                </Row>
            </Col>

            {values.isBillable && <>
                <Col xs={12} className={'pt-10'}>
                    <Row className={'mb-5 d-none d-lg-block px-1 py-1 px-md-10 py-md-5'}>
                        <Col xs={12} lg={11}>
                            <Row>
                                <Col xs={12} lg={3}><h5>SERVICE NAME</h5></Col>
                                <Col xs={6} lg={2}><h5>BILLING ACTION</h5></Col>
                                <Col xs={6} lg={4}><h5>BILLING CATEGORY</h5></Col>
                                <Col xs={4} lg={1}><h5>QUANTITY</h5></Col>
                                <Col xs={4} lg={1}><h5>RATE</h5></Col>
                                <Col xs={4} lg={1}><h5>TOTAL</h5></Col>
                            </Row>
                        </Col>
                        <Col xs={12} lg={1}></Col>
                        <Col xs={12}><hr /></Col>
                    </Row>

                    <FieldArray 
                        name="pricings"
                        validateOnChange={false}
                    >
                        {({ remove, push }) => (
                            <>
                                {
                                    (values.pricings && values.pricings.length > 0)
                                    ?
                                    values.pricings.map((item, i) => {
                                        // return <Row key={i} className={'mb-5' + ((this.state.billingType !== item.billingType) ? 'd-none' : '')}>
                                        return <Row key={i} className={'mb-5'}>
                                            <Col xs={12}>
                                                <Row className={'px-1 px-md-10'}>
                                                    <Col xs={12} lg={11}>
                                                        <Row>
                                                            <Col xs={12} lg={3}>
                                                                <Form.Group className={'mb-5'}>
                                                                    <Form.Label className={'d-block d-lg-none'}>SERVICE NAME</Form.Label>
                                                                    {
                                                                        (item.serviceType === ServiceType.AdditionalService)
                                                                        ?
                                                                        <SmartDropdown
                                                                            isInvalid={!!(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].serviceTag)}
                                                                            errorText={(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].serviceTag) && errors.pricings[i].serviceTag}

                                                                            componentTitle={'serviceItem'}
                                                                            componentApi={'service-item'}
                                                                            componentId={'serviceItemId'}
                                                                            componentName={'serviceTag'}
                                                                            componentData={this.getAdditionalServiceParams()}
                                                                            customName={'' + (i+1)}
                                                                            sortColumn={'serviceTag'}
                                                                            sortDir={'desc'}

                                                                            placeholder={this.props.fields.serviceTag.placeholder}
                                                                            value={item.serviceTag}
                                                                            label={item.serviceTag}

                                                                            disabled={false}
                                                                            showDefault={false}
                                                                            showSearch={true}
                                                                            showFooter={false}
                                                                            showAdd={false}
                                                                            showEdit={false}
                                                                            isInfiniteScroll={false}
                                                                            isFreeText={false}

                                                                            onChange={async (value, item, j) => {
                                                                                await setFieldValue('pricings.' + i + '.serviceItemId', value);
                                                                                await setFieldValue('pricings.' + i + '.serviceTag', item.title);

                                                                                let chargeCategory = (item && item.item && item.item.chargeCategory) ? item.item.chargeCategory : null;
                                                                                let chargeCategoryId = (chargeCategory && chargeCategory.chargeCategoryId) ? chargeCategory.chargeCategoryId : null;
                                                                                let chargeCategoryName = (chargeCategory && chargeCategory.chargeCategoryName) ? chargeCategory.chargeCategoryName : '';

                                                                                let chargeCategoryItems = _.cloneDeep(values.customFormParams.chargeCategoryItems);
                                                                                if (chargeCategory) {
                                                                                    let chargeCategoryItem = {
                                                                                        value: chargeCategoryId,
                                                                                        title: chargeCategoryName,
                                                                                        item: chargeCategory,
                                                                                    };

                                                                                    chargeCategoryItems = Utils.addToArray(chargeCategoryItems, chargeCategoryId, chargeCategoryItem);

                                                                                    await setFieldValue('customFormParams.chargeCategoryItems', chargeCategoryItems);
                                                                                    await setFieldValue('pricings.' + i + '.chargeCategoryId', chargeCategoryId);
                                                                                    await setFieldValue('pricings.' + i + '.chargeCategoryName', chargeCategoryName);
                                                                                }


                                                                                let billingType = (item && item.item) ? item.item.billingType : null;
                                                                                if(billingType){
                                                                                    await setFieldValue('pricings.' + i + '.billingType', billingType);
                                                                                }
                                                                                
                                                                                let price = (item && item.item) ? item.item.price : '0';
                                                                                await setFieldValue('pricings.' + i + '.price', price);
                                                                                await setFieldValue('pricings.' + i + '.quantity', 1);
                                                                                await setFieldValue('pricings.' + i + '.bundleServiceTag', '');
                                                                            }}
                                                                            onClear={async () => {
                                                                                await setFieldValue('pricings.' + i + '.serviceItemId', '');
                                                                                await setFieldValue('pricings.' + i + '.serviceTag', '');
                                                                                await setFieldValue('pricings.' + i + '.bundleServiceTag', '');
                                                                                await setFieldValue('pricings.' + i + '.billingType', null);
                                                                                await setFieldValue('pricings.' + i + '.price', '');
                                                                                await setFieldValue('pricings.' + i + '.quantity', '');
                                                                                await setFieldValue('pricings.' + i + '.chargeCategoryId', null);
                                                                                await setFieldValue('pricings.' + i + '.chargeCategoryName', '');
                                                                            }}
                                                                        />
                                                                        :
                                                                        <Form.Control
                                                                            type="text"
                                                                            id={this.props.fields.serviceTag.id + '_' + i}
                                                                            placeholder={this.props.fields.serviceTag.placeholder}
                                                                            value={item.serviceTag}
                                                                            onChange={async (e) => {
                                                                                await setFieldValue('pricings.' + i + '.serviceTag', e.target.value);
                                                                                await setFieldValue('pricings.' + i + '.bundleServiceTag', e.target.value);
                                                                            }}
                                                                            isInvalid={!!(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].serviceTag)}
                                                                        />
                                                                    }
                                                                    {/* <Form.Control.Feedback type="invalid">{(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].serviceTag) && errors.pricings[i].serviceTag}</Form.Control.Feedback> */}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={12} sm={6} lg={2} xl={2} xxl={2}>
                                                                <Form.Group className={'mb-5'}>
                                                                    <Form.Label className={'d-block d-lg-none'}>BILLING ACTION</Form.Label>
                                                                    <Form.Control
                                                                        as = {RSuiteSelectPicker}
                                                                        isInvalid={!!(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].billingType)}
                        
                                                                        id={this.props.fields.billingType.id + '_' + i}
                                                                        placeholder={this.props.fields.billingType.placeholder}
                                                                        items={[
                                                                            {
                                                                                value: BillingTypes.Invoice,
                                                                                title: 'Invoice',
                                                                            },
                                                                            {
                                                                                value: BillingTypes.Purchase,
                                                                                title: 'Purchase',
                                                                            },
                                                                            {
                                                                                value: BillingTypes.Expense,
                                                                                title: 'Expense',
                                                                            },
                                                                        ]}
                                                                        value={item.billingType}
                                                                        isLoading={false}
                                                                        searchable={true}
                                                                        cleanable={true}
                                                                        readOnly={true}
                                                                        isDefault={true}
                                                                        onClick={() => {
                                                                            
                                                                        }}
                                                                        onSelect={async (value, item) => {
                                                                            await setFieldValue('pricings.' + i + '.billingType', value);
                                                                        }}
                                                                        onClear={async () => {
                                                                            await setFieldValue('pricings.' + i + '.billingType', null);
                                                                        }}
                                                                    />
                                                                    {/* <Form.Control.Feedback type="invalid">{(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].billingType) && errors.pricings[i].billingType}</Form.Control.Feedback> */}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={12} sm={6} lg={3} xl={3} xxl={4}>
                                                                <Form.Group className={'mb-5'}>
                                                                    <Form.Label className={'d-block d-lg-none'}>BILLING CATEGORY</Form.Label>
                                                                    <Form.Control
                                                                        as = {RSuiteSelectPicker}
                                                                        isInvalid={!!(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].chargeCategoryId)}
                        
                                                                        id={this.props.fields.chargeCategoryId.id + '_' + i}
                                                                        placeholder={this.props.fields.chargeCategoryId.placeholder}
                                                                        items={values.customFormParams.chargeCategoryItems}
                                                                        value={item.chargeCategoryId}
                                                                        selectedName={item.chargeCategoryName}
                                                                        isLoading={values.customFormParams.chargeCategoryIsLoading}
                                                                        searchable={true}
                                                                        cleanable={true}
                                                                        isDefault={true}
                                                                        onClick={async () => {
                                                                            await setFieldValue('customFormParams.chargeCategoryIsLoading', true);
                                                                            this.callReadChargeCategoryApi(values.customFormParams.chargeCategoryItems, async (items) => {
                                                                                await setFieldValue('customFormParams.chargeCategoryItems', items);
                                                                                await setFieldValue('customFormParams.chargeCategoryIsLoading', false);
                                                                            });
                                                                        }}
                                                                        onSelect={async (value, itemC) => {
                                                                            await setFieldValue('pricings.' + i + '.chargeCategoryId', value);
                                                                            await setFieldValue('pricings.' + i + '.chargeCategoryName', itemC.title);
                                                                            
                                                                            let invoiceTaxRate = (itemC && itemC.item) ? itemC.item.invoiceTaxRate : null;
                                                                            let purchaseTaxRate = (itemC && itemC.item) ? itemC.item.purchaseTaxRate : null;
                                                                            this.props.action.taxRate.setTaxRate({ index: value, invoiceTaxRate: invoiceTaxRate, purchaseTaxRate: purchaseTaxRate });
                                                                        }}
                                                                        onClear={async () => {
                                                                            await setFieldValue('pricings.' + i + '.chargeCategoryId', null);
                                                                            await setFieldValue('pricings.' + i + '.chargeCategoryName', '');

                                                                            this.props.action.taxRate.setTaxRate({ index: null, invoiceTaxRate: null, purchaseTaxRate: null });
                                                                        }}
                                                                    />
                                                                    {/* <Form.Control.Feedback type="invalid">{(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].chargeCategoryId) && errors.pricings[i].chargeCategoryId}</Form.Control.Feedback> */}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={12} sm={4} lg={1} xl={1} xxl={1}>
                                                                <Form.Group className={'mb-5'}>
                                                                    <Form.Label className={'d-block d-lg-none'}>QUANTITY</Form.Label>
                                                                    <Form.Control
                                                                        type="number"
                                                                        id={this.props.fields.quantity.id + '_' + i}
                                                                        placeholder={this.props.fields.quantity.placeholder}
                                                                        value={item.quantity}
                                                                        readOnly={item.useBinWeightQuantity}
                                                                        onChange={async (e) => {
                                                                            await setFieldValue('pricings.' + i + '.quantity', e.target.value);

                                                                            if(item.useBinWeightQuantity){
                                                                                await setFieldValue('pricings.' + i + '.quantity', e.target.value);
                                                                                await setFieldValue('pricings.' + i + '.billableWeight', e.target.value);

                                                                                // let grossWeight = Utils.parseFloat(e.target.value);
                                                                                // let tareWeight = Utils.parseFloat(item.tareWeight);
                                                                                // let nettWeight = grossWeight - tareWeight;

                                                                                // await setFieldValue('pricings.' + i + '.quantity', e.target.value);
                                                                                // await setFieldValue('pricings.' + i + '.grossWeight', e.target.value);
                                                                                // await setFieldValue('pricings.' + i + '.nettWeight', nettWeight.toFixed(3));
                                                                                // await setFieldValue('pricings.' + i + '.billableWeight', nettWeight.toFixed(3));

                                                                                // let billableWeight = Utils.parseFloat(values.billableWeight);
                                                                                // let maxNumb = Math.max(tareWeight, billableWeight)
                                                                                
                                                                                // if(grossWeight < maxNumb){
                                                                                //     await setFieldValue('pricings.' + i + '.grossWeight', e.target.value);
                                                                                //     await setFieldValue('pricings.' + i + '.tareWeight', e.target.value);
                                                                                //     await setFieldValue('pricings.' + i + '.nettWeight', e.target.value);
                                                                                //     await setFieldValue('pricings.' + i + '.billableWeight', e.target.value);
                                                                                // } else {
                                                                                //     await setFieldValue('pricings.' + i + '.grossWeight', e.target.value);
                                                                                //     await setFieldValue('pricings.' + i + '.nettWeight', nettWeight.toFixed(3));
                                                                                //     await setFieldValue('pricings.' + i + '.billableWeight', nettWeight.toFixed(3));
                                                                                // }
                                                                            }
                                                                        }}
                                                                        isInvalid={!!(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].quantity)}
                                                                    />
                                                                    {/* <Form.Control.Feedback type="invalid">{(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].quantity) && errors.pricings[i].quantity}</Form.Control.Feedback> */}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={12} sm={4} lg={2} xl={2} xxl={1}>
                                                                <Form.Group className={'mb-5'}>
                                                                    <Form.Label className={'d-block d-lg-none'}>RATE</Form.Label>
                                                                    <Form.Control
                                                                        as = {CurrencyInput}
                                                                        isInvalid={!!(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].price)}
                                                                        
                                                                        allowDecimals={true}
                                                                        allowNegativeValue={false}
                                                                        disableGroupSeparators={true}
                                                                        prefix={Utils.getCurrency()}

                                                                        id={this.props.fields.price.id + '_' + i}
                                                                        placeholder={this.props.fields.price.placeholder}
                                                                        value={item.price}
                                                                        onValueChange={async (value) => {
                                                                            await setFieldValue('pricings.' + i + '.price', value);
                                                                        }}
                                                                    />
                                                                    {/* <Form.Control.Feedback type="invalid">{(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].price) && errors.pricings[i].price}</Form.Control.Feedback> */}
                                                                    <Form.Control.Feedback type="invalid" className={(item.isTaxInclusive && Utils.isNumber(item.price)) ? 'd-block' : ''}>{taxRate.getWithoutTax(item.isTaxInclusive, item.price, taxRate.getTaxPercentage(item.billingType, item.invoiceTaxRate, item.purchaseTaxRate))}</Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={12} sm={4} lg={1} xl={1} xxl={1}>
                                                                <Form.Group className={'mb-2'}>
                                                                    <Form.Label className={'d-block d-lg-none'}>TOTAL</Form.Label>
                                                                    <Form.Label className={'mt-0 mt-sm-4 ' + ((this.state.billingType !== item.billingType) ? 'text-muted' : '')}><b>{Utils.getCurrency()}{Utils.formatNumber(item.quantity * item.price)}</b></Form.Label>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={12} lg={1} className={'text-center text-lg-start'}>
                                                        <IconButton
                                                            className={'ms-2'}
                                                            onClick={() => {
                                                                remove(i);
                                                            }}
                                                        >
                                                            <i className={'material-icons text-danger'}>delete_forever</i>
                                                        </IconButton>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            
                                            <Col xs={12}>
                                                {<>
                                                    {item.useBinWeightQuantity && <Row>
                                                        <Col xs={12} className={'text-center'}>
                                                            <a href={'/'}
                                                                className={'link-primary-icon-underline'}
                                                                onClick={async (e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();

                                                                    await setFieldValue('pricings.' + i + '.isExpanded', !item.isExpanded);
                                                                }}
                                                            >
                                                                Weigh Chit Option
                                                                <i className={'fa fa-angle-down mt-1 ps-2'}></i>
                                                            </a>
                                                        </Col>
                                                    </Row>}
                                                    
                                                    <Row className={'p-2 p-md-10 ' + (item.isExpanded ? 'd-flex' : 'd-none')} style={{ backgroundColor: '#F3F7FF' }}>
                                                            <Col xs={12} md={6}>
                                                                <Row>
                                                                    <Col xs={12}>
                                                                        <InputLayout
                                                                            label={this.props.fields.ticketNumber.label}
                                                                        >
                                                                            <Form.Control
                                                                                type="text"
                                                                                id={this.props.fields.ticketNumber.id + '_' + i}
                                                                                placeholder={this.props.fields.ticketNumber.placeholder}
                                                                                value={item.ticketNumber}
                                                                                onChange={async (e) => {
                                                                                    await setFieldValue('pricings.' + i + '.ticketNumber', e.target.value);
                                                                                }}
                                                                                isInvalid={!!(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].ticketNumber)}
                                                                                // readOnly={Utils.isRejectedOrCancelledOrFailedOrDeleted(values.customFormParams.jobStatusName)}
                                                                            />
                                                                            {/* <Form.Control.Feedback type="invalid">{(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].ticketNumber) && errors.pricings[i].ticketNumber}</Form.Control.Feedback> */}
                                                                        </InputLayout>
                                                                    </Col>
                                                                    <Col xs={12}>
                                                                        <InputLayout
                                                                            label={this.props.fields.weighBy.label}
                                                                        >
                                                                            <Form.Control
                                                                                type="text"
                                                                                id={this.props.fields.weighBy.id + '_' + i}
                                                                                placeholder={this.props.fields.weighBy.placeholder}
                                                                                value={item.weighBy}
                                                                                onChange={async (e) => {
                                                                                    await setFieldValue('pricings.' + i + '.weighBy', e.target.value);
                                                                                }}
                                                                                isInvalid={!!(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].weighBy)}
                                                                                // readOnly={Utils.isRejectedOrCancelledOrFailedOrDeleted(values.customFormParams.jobStatusName)}
                                                                            />
                                                                            {/* <Form.Control.Feedback type="invalid">{(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].weighBy) && errors.pricings[i].weighBy}</Form.Control.Feedback> */}
                                                                        </InputLayout>
                                                                    </Col>
                                                                    <Col xs={12}>
                                                                        <InputLayout
                                                                            label={this.props.fields.remarks.label}
                                                                        >
                                                                            <Form.Control
                                                                                // as="textarea"
                                                                                // rows={5}
                                                                                type="text"
                                                                                id={this.props.fields.remarks.id + '_' + i}
                                                                                placeholder={this.props.fields.remarks.placeholder}
                                                                                value={item.remarks}
                                                                                onChange={async (e) => {
                                                                                    await setFieldValue('pricings.' + i + '.remarks', e.target.value);
                                                                                }}
                                                                                isInvalid={!!(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].remarks)}
                                                                                // readOnly={Utils.isRejectedOrCancelledOrFailedOrDeleted(values.customFormParams.jobStatusName)}
                                                                            />
                                                                            {/* <Form.Control.Feedback type="invalid">{(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].remarks) && errors.pricings[i].remarks}</Form.Control.Feedback> */}
                                                                        </InputLayout>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col xs={12} md={6}>
                                                                <Row>
                                                                    <Col xs={12}>
                                                                        <InputLayout
                                                                            label={this.props.fields.date.label}
                                                                        >
                                                                            <Form.Control
                                                                                as = {RSuiteDatePicker}
                                                                                isInvalid={!!errors.date}

                                                                                placeholder={this.props.fields.date.placeholder}
                                                                                id={this.props.fields.date.id + '_' + i}
                                                                                value={item.date}
                                                                                icon={<></>}
                                                                                placement={'autoVerticalStart'}
                                                                                cleanable={true}
                                                                                isDefault={true}
                                                                                oneTap={false}
                                                                                showMeridian={true}
                                                                                format={Utils.getImportDateTimeFieldFormat()}
                                                                                onChange={async (value) => {
                                                                                    await setFieldValue('pricings.' + i + '.date', value);
                                                                                }}
                                                                                onClear={async () => {
                                                                                    await setFieldValue('pricings.' + i + '.date', null);
                                                                                }}
                                                                                // readOnly={Utils.isRejectedOrCancelledOrFailedOrDeleted(values.customFormParams.jobStatusName)}
                                                                            />
                                                                            {/* <Form.Control.Feedback type="invalid">{(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].date) && errors.pricings[i].date}</Form.Control.Feedback> */}
                                                                        </InputLayout>
                                                                    </Col>
                                                                    <Col xs={12}>
                                                                        <InputLayout
                                                                            label={this.props.fields.weightChitOutDateTime.label}
                                                                        >
                                                                            <Form.Control
                                                                                as = {RSuiteDatePicker}
                                                                                isInvalid={!!errors.weightChitOutDateTime}

                                                                                placeholder={this.props.fields.weightChitOutDateTime.placeholder}
                                                                                id={this.props.fields.weightChitOutDateTime.id + '_' + i}
                                                                                value={item.weightChitOutDateTime}
                                                                                icon={<></>}
                                                                                placement={'autoVerticalStart'}
                                                                                cleanable={true}
                                                                                isDefault={true}
                                                                                oneTap={false}
                                                                                showMeridian={true}
                                                                                format={Utils.getImportDateTimeFieldFormat()}
                                                                                onChange={async (value) => {
                                                                                    await setFieldValue('pricings.' + i + '.weightChitOutDateTime', value);
                                                                                }}
                                                                                onClear={async () => {
                                                                                    await setFieldValue('pricings.' + i + '.weightChitOutDateTime', null);
                                                                                }}
                                                                                // readOnly={Utils.isRejectedOrCancelledOrFailedOrDeleted(values.customFormParams.jobStatusName)}
                                                                            />
                                                                            {/* <Form.Control.Feedback type="invalid">{(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].weightChitOutDateTime) && errors.pricings[i].weightChitOutDateTime}</Form.Control.Feedback> */}
                                                                        </InputLayout>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            
                                                            <Col xs={12} className={'mt-2'}>
                                                                <Row>
                                                                    {(item.jobStepWeighChitCustomFields && item.jobStepWeighChitCustomFields.length > 0) && <>
                                                                        <Col xs={12} className={'mb-4'}>
                                                                            <h6>Custom fields</h6>
                                                                            <hr style={{ borderTop: '1px solid rgb(157 157 157)' }} />
                                                                        </Col>

                                                                        {item.jobStepWeighChitCustomFields.map((customItem, j) => {
                                                                            if(customItem.accountCustomField && customItem.accountCustomField.label && customItem.accountCustomField.label != ''){
                                                                                return <Col key={j} xs={12} md={6}>
                                                                                    <InputLayout
                                                                                        label={customItem.accountCustomField.label}
                                                                                    >
                                                                                        <Form.Control
                                                                                            type="text"
                                                                                            id={'custom_field_' + customItem.accountCustomFieldId}
                                                                                            placeholder={''}
                                                                                            value={(customItem.value) ? customItem.value : ''}
                                                                                            onChange={async (e) => {
                                                                                                await setFieldValue('pricings.' + i + '.jobStepWeighChitCustomFields.' + j + '.value', (e.target.value) ? e.target.value : null);
                                                                                            }}
                                                                                        />
                                                                                    </InputLayout>
                                                                                </Col>
                                                                            }
                                                                        })}
                                                                    </>}
                                                                </Row>
                                                            </Col>

                                                            <Col xs={12} className={'mt-10'}>
                                                                <StyledWeightChitTable className={'no-responsive-table'}>
                                                                    <thead>
                                                                        <tr className={'d-none d-md-table-row'}>
                                                                            <th>{this.props.fields.grossWeight.label + ((item.uom === ReportUOM.kg) ? ' (kg)' : ' (tons)')}</th>
                                                                            <th width={'5%'}>{'( - )'}</th>
                                                                            <th>{this.props.fields.tareWeight.label + ((item.uom === ReportUOM.kg) ? ' (kg)' : ' (tons)')}</th>
                                                                            <th width={'5%'}>{'( = )'}</th>
                                                                            <th>{this.props.fields.nettWeight.label + ((item.uom === ReportUOM.kg) ? ' (kg)' : ' (tons)')}</th>
                                                                            <th width={'5%'}>{'( - )'}</th>
                                                                            <th>{this.props.fields.nonBillableBinWeight.label + ((item.uom === ReportUOM.kg) ? ' (kg)' : ' (tons)')}</th>
                                                                            <th width={'5%'}>{'( = )'}</th>
                                                                            <th>{this.props.fields.billableWeight.label + ((item.uom === ReportUOM.kg) ? ' (kg)' : ' (tons)')}</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <InputLayout
                                                                                    label={this.props.fields.grossWeight.label + ((item.uom === ReportUOM.kg) ? ' (kg)' : ' (tons)')}
                                                                                    labelProps={{
                                                                                        className: 'd-block d-md-none',
                                                                                    }}
                                                                                >
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        id={this.props.fields.grossWeight.id + '_' + i}
                                                                                        placeholder={this.props.fields.grossWeight.placeholder}
                                                                                        value={item.grossWeight}
                                                                                        onChange={async (e) => {
                                                                                            if(e.target.value != ''){
                                                                                                let grossWeight = Utils.parseFloat(e.target.value);
                                                                                                let tareWeight = Utils.parseFloat(item.tareWeight);
                                                                                                let nonBillableBinWeight = Utils.parseFloat(item.nonBillableBinWeight);
                                                                                                let nettWeight = grossWeight - tareWeight;
                                                                                                let billableWeight = nettWeight - nonBillableBinWeight;
                                                                                                billableWeight = (billableWeight < 0) ? 0 : billableWeight;
                                                    
                                                                                                await setFieldValue('pricings.' + i + '.grossWeight', e.target.value);
                                                                                                await setFieldValue('pricings.' + i + '.nettWeight', nettWeight.toFixed((item.uom === ReportUOM.kg) ? 0 : 3));
                                                                                                await setFieldValue('pricings.' + i + '.billableWeight', billableWeight.toFixed((item.uom === ReportUOM.kg) ? 0 : 3));

                                                                                                let quantity = Utils.convertUom(billableWeight, Utils.getUOM(), Utils.getBillingUOM());
                                                                                                try { quantity = quantity.toFixed((Utils.getBillingUOM() === ReportUOM.kg) ? 0 : 3); } catch(e){}

                                                                                                await setFieldValue('pricings.' + i + '.quantity', quantity);
                                                                                            } else {
                                                                                                await setFieldValue('pricings.' + i + '.grossWeight', e.target.value);
                                                                                            }
                                                                                        }}
                                                                                        isInvalid={!!(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].grossWeight)}
                                                                                        // readOnly={Utils.isRejectedOrCancelledOrFailedOrDeleted(values.customFormParams.jobStatusName)}
                                                                                    />
                                                                                    <Form.Control.Feedback type="invalid">{(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].grossWeight) && errors.pricings[i].grossWeight}</Form.Control.Feedback>
                                                                                </InputLayout>
                                                                            </td>
                                                                            <td></td>
                                                                            <td>
                                                                                <InputLayout
                                                                                    label={this.props.fields.tareWeight.label + ((item.uom === ReportUOM.kg) ? ' (kg)' : ' (tons)')}
                                                                                    labelProps={{
                                                                                        className: 'd-block d-md-none',
                                                                                    }}
                                                                                >
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        id={this.props.fields.tareWeight.id + '_' + i}
                                                                                        placeholder={this.props.fields.tareWeight.placeholder}
                                                                                        value={item.tareWeight}
                                                                                        // readOnly={((item.grossWeight !== '') ? false : true) || Utils.isRejectedOrCancelledOrFailedOrDeleted(values.customFormParams.jobStatusName)}
                                                                                        readOnly={((item.grossWeight !== '') ? false : true)}
                                                                                        onChange={async (e) => {
                                                                                            let grossWeight = Utils.parseFloat(item.grossWeight);
                                                                                            let tareWeight = Utils.parseFloat(e.target.value);
                                                                                            let nonBillableBinWeight = Utils.parseFloat(item.nonBillableBinWeight);
                                                                                            let nettWeight = grossWeight - tareWeight;
                                                                                            let billableWeight = nettWeight - nonBillableBinWeight;
                                                                                            billableWeight = (billableWeight < 0) ? 0 : billableWeight;

                                                                                            await setFieldValue('pricings.' + i + '.tareWeight', e.target.value);
                                                                                            await setFieldValue('pricings.' + i + '.nettWeight', nettWeight.toFixed((item.uom === ReportUOM.kg) ? 0 : 3));
                                                                                            await setFieldValue('pricings.' + i + '.billableWeight', billableWeight.toFixed((item.uom === ReportUOM.kg) ? 0 : 3));
                                                                                            
                                                                                            let quantity = Utils.convertUom(billableWeight, Utils.getUOM(), Utils.getBillingUOM());
                                                                                            try { quantity = quantity.toFixed((Utils.getBillingUOM() === ReportUOM.kg) ? 0 : 3); } catch(e){}

                                                                                            await setFieldValue('pricings.' + i + '.quantity', quantity);
                                                                                        }}
                                                                                        isInvalid={!!(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].tareWeight)}
                                                                                    />
                                                                                    <Form.Control.Feedback type="invalid">{(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].tareWeight) && errors.pricings[i].tareWeight}</Form.Control.Feedback>
                                                                                </InputLayout>
                                                                            </td>
                                                                            <td></td>
                                                                            <td>
                                                                                <InputLayout
                                                                                    label={this.props.fields.nettWeight.label}
                                                                                    labelProps={{
                                                                                        className: 'd-block d-md-none',
                                                                                    }}
                                                                                >
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        id={this.props.fields.nettWeight.id + '_' + i}
                                                                                        placeholder={this.props.fields.nettWeight.placeholder}
                                                                                        value={item.nettWeight}
                                                                                        readOnly={true}
                                                                                        onValueChange={async (e) => {
                                                                                            await setFieldValue('pricings.' + i + '.nettWeight', e.target.value);
                                                                                        }}
                                                                                        isInvalid={!!(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].nettWeight)}
                                                                                    />
                                                                                    <Form.Control.Feedback type="invalid">{(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].nettWeight) && errors.pricings[i].nettWeight}</Form.Control.Feedback>
                                                                                </InputLayout>
                                                                            </td>
                                                                            <td></td>
                                                                            <td>
                                                                                <InputLayout
                                                                                    label={this.props.fields.nonBillableBinWeight.label + ((item.uom === ReportUOM.kg) ? ' (kg)' : ' (tons)')}
                                                                                    labelProps={{
                                                                                        className: 'd-block d-md-none',
                                                                                    }}
                                                                                >
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        id={this.props.fields.nonBillableBinWeight.id + '_' + i}
                                                                                        placeholder={this.props.fields.nonBillableBinWeight.placeholder}
                                                                                        value={item.nonBillableBinWeight}
                                                                                        // readOnly={((item.grossWeight !== '') ? false : true) || Utils.isRejectedOrCancelledOrFailedOrDeleted(values.customFormParams.jobStatusName)}
                                                                                        readOnly={((item.grossWeight !== '') ? false : true)}
                                                                                        onChange={async (e) => {
                                                                                            let grossWeight = Utils.parseFloat(item.grossWeight);
                                                                                            let tareWeight = Utils.parseFloat(item.tareWeight);
                                                                                            let nonBillableBinWeight = Utils.parseFloat(e.target.value);
                                                                                            let nettWeight = grossWeight - tareWeight;
                                                                                            let billableWeight = nettWeight - nonBillableBinWeight;
                                                                                            billableWeight = (billableWeight < 0) ? 0 : billableWeight;

                                                                                            await setFieldValue('pricings.' + i + '.nonBillableBinWeight', e.target.value);
                                                                                            await setFieldValue('pricings.' + i + '.tareWeight', tareWeight);
                                                                                            await setFieldValue('pricings.' + i + '.nettWeight', nettWeight.toFixed((item.uom === ReportUOM.kg) ? 0 : 3));
                                                                                            await setFieldValue('pricings.' + i + '.billableWeight', billableWeight.toFixed((item.uom === ReportUOM.kg) ? 0 : 3));

                                                                                            let quantity = Utils.convertUom(billableWeight, Utils.getUOM(), Utils.getBillingUOM());
                                                                                            try { quantity = quantity.toFixed((Utils.getBillingUOM() === ReportUOM.kg) ? 0 : 3); } catch(e){}

                                                                                            await setFieldValue('pricings.' + i + '.quantity', quantity);
                                                                                        }}
                                                                                        isInvalid={!!(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].nonBillableBinWeight)}
                                                                                    />
                                                                                    <Form.Control.Feedback type="invalid">{(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].nonBillableBinWeight) && errors.pricings[i].nonBillableBinWeight}</Form.Control.Feedback>
                                                                                </InputLayout>
                                                                            </td>
                                                                            <td></td>
                                                                            <td>
                                                                                <InputLayout
                                                                                    label={this.props.fields.billableWeight.label}
                                                                                    labelProps={{
                                                                                        className: 'd-block d-md-none',
                                                                                    }}
                                                                                >
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        id={this.props.fields.billableWeight.id + '_' + i}
                                                                                        placeholder={this.props.fields.billableWeight.placeholder}
                                                                                        value={item.billableWeight}
                                                                                        // readOnly={((item.grossWeight !== '') ? false : true) || Utils.isRejectedOrCancelledOrFailedOrDeleted(values.customFormParams.jobStatusName)}
                                                                                        readOnly={((item.grossWeight !== '') ? false : true)}
                                                                                        onChange={async (e) => {
                                                                                            await setFieldValue('pricings.' + i + '.billableWeight', e.target.value);
                                                                                            await setFieldValue('pricings.' + i + '.quantity', Utils.smartConversionKgTons(e.target.value));
                                                                                        }}
                                                                                        isInvalid={!!(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].billableWeight)}
                                                                                    />
                                                                                    <Form.Control.Feedback type="invalid">{(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].billableWeight) && errors.pricings[i].billableWeight}</Form.Control.Feedback>
                                                                                </InputLayout>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </StyledWeightChitTable>
                                                            </Col>
                                                    </Row>
                                                </>}
                                            </Col>
                                            
                                            {(i < (values.pricings.length-1)) && <Col xs={12} className={'d-block d-lg-none'}><hr /></Col>}
                                        </Row>
                                    })
                                    :
                                    <h3 className={'mb-5 px-1 px-md-10'}>No items!</h3>
                                }

                                <a href={'/'}
                                    className={'link-default fw-bold px-1 px-md-10'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        validateForm().then((err) => {
                                            if(!!err.pricings){
                                                Utils.toast('You must fill in the required fields', 'error');
                                            } else {
                                                push(this.props.initialValues.pricings[0]);
                                                // Utils.scrollToBottom(this.bodyRef);
                                            }
                                        })
                                    }}
                                >
                                    + Add Service Items
                                </a>
                            </>
                        )}
                    </FieldArray>
                </Col>

                <Col xs={12} className={'px-1 px-md-10'}>
                    <Col xs={12}><hr /></Col>
                    <Row className={'mb-5'}>
                        <Col xs={12} lg={11}>
                            <Row>
                                <Col xs={true} lg={11} className={'text-end'}><h3>TOTAL</h3></Col>
                                <Col xs={true} lg={1} className={'position-lg-relative'}>
                                    <h3 className={'position-lg-absolute'}>
                                    {Utils.getCurrency()}
                                    {
                                        (values && values.pricings && values.pricings.length > 0)
                                        ?
                                        Utils.formatNumber(values.pricings.map(x => {
                                            if(this.state.billingType === x.billingType){
                                                let q = parseFloat(x.quantity ? x.quantity : 0);
                                                let p = parseFloat(x.price ? x.price : 0);

                                                return parseFloat(q * p);
                                            } else {
                                                return 0;
                                            }
                                        }).reduce((sum, x) => sum + x))
                                        :
                                        parseFloat(0).toFixed(2)
                                    }
                                    </h3>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} lg={1}></Col>
                    </Row>
                </Col>
            </>}
        </Row>
    }
    
    footer = () => {
        // let {
        //     values,
        // } = this.props.formOptions;
        
        // let billingStatusValue = Utils.checkBillingStatus(values.isBillable, values.isInvoiced, values.isPaid, values.customFormParams.quickbookInvoiceId, values.customFormParams.xeroInvoiceId, values.customFormParams.quickbookPurchaseOrderId);

        return <Row>
            <Col xs={'auto'}>
                <Button 
                    type={'submit'}
                    disabled={this.props.isLoading}
                >
                    SAVE
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
            <Col xs={'auto'}>
                <Button 
                    type={'button'}
                    variant={'secondary'}
                    disabled={this.props.isLoading}
                    onClick={() => {
                        if(this.props.onCancel){
                            this.props.onCancel();
                        } else {
                            Utils.showDrawer(this.props.drawer, false);
                        }
                    }}
                >
                    CANCEL
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
            {/* {((this.state.billingType === BillingTypes.Invoice) && (billingStatusValue == BillingStatus.Unbilled)) && <Col className="d-flex justify-content-end">
                <Button 
                    type={'button'}
                    variant={'success'}
                    disabled={this.props.isLoading || this.props.invoiceThisJob.isLoading}
                    onClick={() => {
                        if(this.props.onInvoiceThis){
                            this.props.onInvoiceThis(values, this.props.dataParams);
                        }
                    }}
                >
                    {(this.state.billingType === BillingTypes.Purchase) ? 'PURCHASE' : 'INVOICE'} THIS JOB
                    {(this.props.isLoading || this.props.invoiceThisJob.isLoading) ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>} */}
        </Row>
    }


    render() {
        return <>
        {
            this.props.isDedicatedForm
            ?
            <DrawerPageContentLayout
                padding={'p-3'}
                ref={this.bodyRef}
                isBlocking={this.props.isBlocking}
                isLoading={this.props.billingRefresh.isLoading}
                body={this.body()}
                footer={this.footer()}
            />
            :
            <DrawerContentLayout
                padding={'p-3'}
                ref={this.bodyRef}
                isBlocking={this.props.isBlocking}
                isLoading={this.props.billingRefresh.isLoading}
                body={this.body()}
                footer={this.footer()}
            />
        }
        </>
    }
}

// const mapDispatchToProps = {
//   dispatchApiCallGet,
//   dispatchApiCallPost,
//   clear,
// }

const mapStateToProps = state => {
    return {
      ...state,
      ...Utils.mapStateToProps(state),
    };
};
const mapDispatchToProps = (dispatch) => ({
    dispatchApiCallGet: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallGet(p1, p2, p3, p4, p5, p6)),
    dispatchApiCallPost: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallPost(p1, p2, p3, p4, p5, p6)),
    clear: () => dispatch(clear()),
    action: {
        billingRefresh: {
            callRefreshPricingsApi: (payload) => dispatch(billingRefresh.callRefreshPricingsApi(payload)),
        },
        taxRate: {
            setTaxRate: (payload) => dispatch(taxRate.setTaxRate(payload)),
        },
    }
});
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(RowDrawerForm);
