import React from "react";
import { format } from "timeago.js";

import { 
    Row,
    Col,
    Button,
    Form,
} from "react-bootstrap-v5";

import { DrawerContentLayout } from '../../../../components/drawer/DrawerLayout'
import Utils from "../../../../utils/utils";

import { connect } from 'react-redux'
import { clear } from "../../../../../setup/redux/actions";
import { dispatchApiCallPost } from '../../../../../setup/redux/dispatch/actions'

import DefaultImage from "../../../../../_metronic/assets/img/default_image.jpg";


class JobFormChatDrawer extends React.Component {
  
    constructor(props) {
        super(props);
    
        this.name = 'job_form_chat_drawer';

        this.bodyRef = React.createRef();
        this.inputRef = React.createRef();

        let dataParams = (props.dataParams) ? props.dataParams : null;
        let id = (dataParams && dataParams.id) ? dataParams.id : null;
        let row = (dataParams && dataParams.row) ? dataParams.row : null;
    

        this.state = {
            id: id,
            row: row,
        };
    }


    componentDidMount() {
        this.focus();
        this.scrollToBottom();
    }
    
    componentDidUpdate() {
        this.scrollToBottom();
    }

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            this.name + '-job_add_message', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();
                    
                    if(callback){
                        callback(data);
                    }
                });
            }
        );
    }

    callAddMessageToDriverApi = (jobId = null, msg = '', callback = null) => {
        let data = {
            jobId: jobId,
            message: msg,
        };
    
        this.props.dispatchApiCallPost(data, this.name + '-job_add_message', 'job/jobs/add-message', null, callback, () => {});
    }
    /* END API */


    /* FUNCTIONS */
    focus = () => {
        try {
            if(this.inputRef && this.inputRef.current){
                this.inputRef.current.focus();
            }
        } catch(e) {}
    }
    
    scrollToBottom = () => {
        try {
            if(this.inputRef && this.inputRef.current){
                this.bodyRef.current.scrollTop = this.bodyRef.current.scrollHeight;
            }
        } catch(e) {}
    }

    sendMsg = (callback = null) => {
        let {
            values,
        } = this.props.formOptions;

        if(!values.msg || values.msg.trim() === ''){
            Utils.toast('Please enter a message', 'error');
    
            if(callback){
                callback(false, values.messages)
            }
        } else {
            this.callAddMessageToDriverApi(values.jobId, values.msg);

            values.messages.push({
                type: 'end',
                name: values.user,
                message: values.msg,
                date: new Date(),
            });

            if(callback){
                callback(true, values.messages)
            }
        }
    }
    /* FUNCTIONS */


    /* SECTIONS */
    setSection = () => {
        let {
            values,
        } = this.props.formOptions;

        return <Row>
            <Col xs={12}>

                {values.messages.map((item, i) => {
                    var newDate = new Date(new Date(item.created).getTime() - new Date(item.created).getTimezoneOffset()*60*1000);
                    
                    return <div key={i} className={'d-flex mb-10 justify-content-' + ((item.type === 1) ? 'start' : 'end')}>
                        <div className={'d-flex flex-column align-items align-items-' + ((item.type === 1) ? 'start' : 'end')}>
                            <div className='d-flex align-items-center mb-2'>
                                <div className='symbol  symbol-35px symbol-circle '>
                                    <img alt='Pic' src={DefaultImage} />
                                </div>
                                <div className='ms-3'>
                                    <span className='fs-5 fw-bolder text-gray-900 me-1'>{item.name}</span>
                                    <span className='text-muted fs-7 mb-1'>{format(newDate)}</span>
                                </div>
                            </div>
                            <div
                                className={'p-5 rounded bg-light-primary text-dark fw-bold text-' + ((item.type === 1) ? 'start' : 'end')}
                                style={{ width: 'fit-content' }}
                                data-kt-element='message-text'
                                dangerouslySetInnerHTML={{__html: item.message}}
                            ></div>
                        </div>
                    </div>
                })}

            </Col>
        </Row>
    }
    /* END SECTIONS */


    body = () => {
        return <>
            {this.setSection()}
        </>
    }
    
    footer = () => {
        let {
            setFieldValue,
            handleChange,
            errors,
            values,
        } = this.props.formOptions;
        
        return <Row>
            <Col xs={true}>
                <Form.Group>
                    <Form.Control
                        ref={this.inputRef}
                        type="text"
                        id={this.props.fields.msg.id}
                        placeholder={this.props.fields.msg.placeholder}
                        value={values.msg}
                        onChange={handleChange}
                        isInvalid={!!errors.msg}
                        onKeyDown={(keyEvent) => {
                            if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                                this.sendMsg(async (state, messages) => {
                                    if(state){
                                        await setFieldValue('messages', messages);
                                        if(this.props.onNewMsg){
                                            this.props.onNewMsg(messages);
                                        }
                                    }
                                    await setFieldValue('msg', '');
                                });
                            }
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.msg}</Form.Control.Feedback>
                </Form.Group>
            </Col>
            <Col xs={'auto'}>
                <Button 
                    type={'button'}
                    variant={'primary'}
                    className={'d-flex align-items-center'}
                    disabled={this.state.disabled}
                    onClick={() => {
                        this.sendMsg(async (state, messages) => {
                            if(state){
                                await setFieldValue('messages', messages);
                                if(this.props.onNewMsg){
                                    this.props.onNewMsg(messages);
                                }
                            }
                            await setFieldValue('msg', '');
                        });
                    }}
                >
                    <span className={'material-icons'}>send</span>
                </Button>
            </Col>
        </Row>
    }


    render() {
        return <DrawerContentLayout
            ref={this.bodyRef}
            isBlocking={this.props.isBlocking}
            body={this.body()}
            footer={this.footer()}
        />
    }
}

const mapDispatchToProps = {
    dispatchApiCallPost,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps)(JobFormChatDrawer);
