import React, { Component } from "react";
import moment from 'moment';
import * as yup from 'yup';
import parse from "html-react-parser";

import {
    Row,
    Col,
    Badge,
    Modal,
} from "react-bootstrap-v5";

import AuditTrailDialog from './dialogs/AuditTrailDialog';

import ViewLayout from "./layout/ViewLayout.jsx";
import ViewTopSection from "./partial/ViewTopSection.jsx";

import DataTable from "../../components/OLD/DataTable/DataTable";
import DataTableActionMenu from '../../components/OLD/DataTable/DataTableActionMenu';
import DataTableAjaxPagination from '../../components/OLD/DataTable/DataTableAjaxPagination.jsx';
import DataTableScrollSync from '../../components/OLD/DataTable/DataTableScrollSync.jsx';
import DropdownIconButtonMenu from '../../components/OLD/Dropdown/DropdownIconButtonMenu';
import IconButtonSearch from '../../components/OLD/input/IconButtonSearch';
// import IconButton from "../../components/Buttons/IconButton";
import makeExpanding from '../../components/OLD/Expanding/makeExpanding';

import { ReactComponent as SettingsIcon } from "../../../_metronic/assets/img/icons/manage-settings.svg";

import Utils from '../../utils/utils'
import { PageCookieSettingType } from '../../utils/enums'

import { connect } from 'react-redux'
import { actions } from "../../../setup/redux/auth/saga";
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut } from '../../../setup/redux/dispatch/actions'


const { saveUserCookieSettings } = actions;
const ExpandingSearchBox = makeExpanding(IconButtonSearch);

class AuditTrailList extends Component {
    constructor(props) {
        super(props);

        props.layoutRef.current = this;

        this.pageName = "audit-trail";

        this.tableRef = React.createRef();
        this.refDropdownActionMenu = React.createRef();
        this.refRowDrawerForm = React.createRef();
        this.refDataTableScrollSync = React.createRef();

        this.updatedTemplate = (id, row, col, obj) => {
            return <span>{moment(row.updated).format('MMM. DD, YYYY hh:mm A')}</span>
        };
        this.updatedExportTemplate = (row) => {
            return moment(row.updated).format('MMM. DD, YYYY hh:mm A');
        };

        this.descriptionTemplate = (id, row, col, obj, index) => {
            return <span>{parse(row.description)}</span>
        };
        this.descriptionExportTemplate = (row) => {
            return parse(row.description);
        };

        this.objectTypeTemplate = (id, row, col, obj, index) => {
            return <Badge bg={'primary'}>{Utils.getAuditTrailLabel(row.objectType)}</Badge>
        };
        this.objectTypeExportTemplate = (row) => {
            return Utils.getAuditTrailLabel(row.objectType);
        };

        let columns = [
            {
                id: "auditTrailId",
                name: "auditTrailId",
                label: "ID",
                responsiveLabel: "",
                visible: false,
            },
            {
                id: "updated",
                name: "updated",
                label: "Timestamp",
                responsiveLabel: "Timestamp",
                orderable: false,
                width: "280px",
                template: this.updatedTemplate,
                exportTemplate: this.updatedExportTemplate,
            },
            {
                id: "description",
                name: "description",
                label: "Description",
                responsiveLabel: "Description",
                orderable: false,
                template: this.descriptionTemplate,
                exportTemplate: this.descriptionExportTemplate,
            },
            {
                id: "objectType",
                name: "objectType",
                label: "Type",
                menuLabel: "Type",
                responsiveLabel: "Type",
                orderable: false,
                template: this.objectTypeTemplate,
                exportTemplate: this.objectTypeExportTemplate,
            },
        ];

        let initColumns = Array.from(columns);
        columns = Array.from(initColumns);

        let columnVisibility = [false, true, true, true];
        let initColumnVisibility = Array.from(columnVisibility);
        columnVisibility = Array.from(initColumnVisibility);

        this.state = {
            data: null,
            err: null,
            isError: false,
            isLoading: false,

            dateRange: Utils.getDateRangeValue("This Year"),
            fromDate: moment().startOf('year').format(Utils.getAPIDateFormat()),
            toDate: moment().endOf('year').format(Utils.getAPIDateFormat()),

            objectType: '',
            search: '',
            sortColumn: 'created',
            sortDir: 'desc',
            sortColumnInit: 'created',
            sortDirInit: 'desc',

            selected: [],
            columnVisibility: columnVisibility,
            initColumnVisibility: initColumnVisibility,
            columns: columns,
            initColumns: initColumns,
            rows: [],
            currentPage: 1,
            totalRows: 0,
            rowsPerPageSelected: 50,
            rowsPerPageOptions: [50, 100, 300],

            isAuditTrailDialog: false,
            auditTrailRow: null,


            isDisabledBatchAction: true,
            batchActionLoading: false,
        }
    }

    componentDidMount() {
        this.getFilterInLocalStorage(() => {
            this.callReadApi();
        });
    }

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }

    /* FUNCTIONS */
    onWindowResize = () => {
        this.getFilterInLocalStorage();
    }

    setFilterInLocalStorage = (isCookies = true) => {
        if (this.props.settingsType) {
            let { token, userCookieSettings } = this.props.auth;

            this.setState({
                isCookies: isCookies,
            });

            let data = {
                name: this.pageName,
                type: this.props.settingsType,
            };

            let valueLocalStorage = {
                [PageCookieSettingType.currentPage]: this.state.currentPage,
                [PageCookieSettingType.fromDate]: this.state.fromDate,
                [PageCookieSettingType.toDate]: this.state.toDate,
            };
            window.localStorage.setItem(this.pageName + '_' + this.props.settingsType, JSON.stringify(valueLocalStorage));

            let value = {
                [PageCookieSettingType.isCookies]: isCookies,
                [PageCookieSettingType.sortColumn]: this.state.sortColumn,
                [PageCookieSettingType.sortDir]: this.state.sortDir,
                // [PageCookieSettingType.currentPage]: this.state.currentPage,
                [PageCookieSettingType.rowsPerPageSelected]: this.state.rowsPerPageSelected,
                // [PageCookieSettingType.fromDate]: this.state.fromDate,
                // [PageCookieSettingType.toDate]: this.state.toDate,
                [PageCookieSettingType.columnVisibility]: this.state.columnVisibility,
                [PageCookieSettingType.columns]: this.state.columns,
                [PageCookieSettingType.isIncludeInactive]: this.state.isIncludeInactive,
            };
            data['value'] = JSON.stringify(value);

            Utils.saveUserCookieSettings(data, token, (settings) => {
                if (settings) {
                    let settingsArr = (userCookieSettings && userCookieSettings.length > 0) ? userCookieSettings : [];
                    let settingsIndex = Utils.getUserCookieSettingsIndex(settingsArr, this.props.settingsType);
                    if (settingsIndex !== -1 && settingsArr[settingsIndex]) {
                        settingsArr[settingsIndex] = settings;
                    } else {
                        settingsArr.push(settings);
                    }

                    this.props.saveUserCookieSettings(settingsArr)
                }
            });

            // apiUtil.deleteUserCookieSettings({ name: this.pageName, type: this.props.settingsType }, token);
            // apiUtil.deleteAllUserCookieSettings(token);
        }
    };

    getFilterInLocalStorage = (callback = null) => {
        let settingsItem = null;

        if (this.props.settingsType) {
            const { userCookieSettings } = this.props.auth;

            settingsItem = Utils.getUserCookieSettings(userCookieSettings, this.props.settingsType, this.pageName);
        }

        let valueLocalStorageStr = window.localStorage.getItem(this.pageName + '_' + this.props.settingsType);
        let valueLocalStorage = JSON.parse(valueLocalStorageStr);
        let currentPageValue = valueLocalStorage && valueLocalStorage[PageCookieSettingType.currentPage] && valueLocalStorage[PageCookieSettingType.currentPage] !== "" ? valueLocalStorage[PageCookieSettingType.currentPage] : this.state.currentPage;
        let fromDateValue = (valueLocalStorage && valueLocalStorage[PageCookieSettingType.fromDate] && valueLocalStorage[PageCookieSettingType.fromDate] !== '') ? valueLocalStorage[PageCookieSettingType.fromDate] : this.state.fromDate;
        let toDateValue = (valueLocalStorage && valueLocalStorage[PageCookieSettingType.toDate] && valueLocalStorage[PageCookieSettingType.toDate] !== '') ? valueLocalStorage[PageCookieSettingType.toDate] : this.state.toDate;
    
        let isCookiesValue = settingsItem && settingsItem[PageCookieSettingType.isCookies] && settingsItem[PageCookieSettingType.isCookies] !== "" ? settingsItem[PageCookieSettingType.isCookies] : this.state.isCookies;
        let sortColumnValue = settingsItem && settingsItem[PageCookieSettingType.sortColumn] && settingsItem[PageCookieSettingType.sortColumn] !== "" ? settingsItem[PageCookieSettingType.sortColumn] : this.state.sortColumn;
        let sortDirValue = settingsItem && settingsItem[PageCookieSettingType.sortDir] && settingsItem[PageCookieSettingType.sortDir] !== "" ? settingsItem[PageCookieSettingType.sortDir] : this.state.sortDir;
        // let currentPageValue = settingsItem && settingsItem[PageCookieSettingType.currentPage] && settingsItem[PageCookieSettingType.currentPage] !== "" ? settingsItem[PageCookieSettingType.currentPage] : this.state.currentPage;
        let rowsPerPageSelectedValue = settingsItem && settingsItem[PageCookieSettingType.rowsPerPageSelected] && settingsItem[PageCookieSettingType.rowsPerPageSelected] !== "" ? settingsItem[PageCookieSettingType.rowsPerPageSelected] : this.state.rowsPerPageSelected;
        // let fromDateValue = (settingsItem && settingsItem[PageCookieSettingType.fromDate] && settingsItem[PageCookieSettingType.fromDate] !== '') ? settingsItem[PageCookieSettingType.fromDate] : this.state.fromDate;
        // let toDateValue = (settingsItem && settingsItem[PageCookieSettingType.toDate] && settingsItem[PageCookieSettingType.toDate] !== '') ? settingsItem[PageCookieSettingType.toDate] : this.state.toDate;
        let columnVisibilityValue = (settingsItem && settingsItem[PageCookieSettingType.columnVisibility] && settingsItem[PageCookieSettingType.columnVisibility] !== '') ? settingsItem[PageCookieSettingType.columnVisibility] : this.state.columnVisibility;
        let columnsValue = (settingsItem && settingsItem[PageCookieSettingType.columns] && settingsItem[PageCookieSettingType.columns] !== '') ? settingsItem[PageCookieSettingType.columns] : this.state.columns;
        let isIncludeInactiveValue = (settingsItem && settingsItem[PageCookieSettingType.isIncludeInactive] && settingsItem[PageCookieSettingType.isIncludeInactive] !== '') ? settingsItem[PageCookieSettingType.isIncludeInactive] : this.state.isIncludeInactive;
        
        let columns = Utils.setTemplateFunctionsToColumns(this.state.columns, columnsValue);
        let dateRange = [
            moment(fromDateValue).toDate(),
            moment(toDateValue).toDate(),
        ];

        this.setState({
            isCookies: isCookiesValue,
            sortColumn: sortColumnValue,
            sortDir: sortDirValue,
            currentPage: currentPageValue,
            rowsPerPageSelected: rowsPerPageSelectedValue,
            fromDate: fromDateValue,
            toDate: toDateValue,
            columnVisibility: columnVisibilityValue,
            isIncludeInactive: isIncludeInactiveValue,
            columns: columns,
            dateRange: dateRange,
        }, () => {
            if (callback) {
                callback();
            }
        });
    };

    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            this.pageName + '-list',
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let rows = (data && data.data && data.data.length > 0) ? data.data : [];
                let totalRows = (data && data.total) ? data.total : 0;

                this.setState({
                    rows: rows,
                    totalRows: totalRows,
                    isDisabledBatchAction: totalRows <= 0,
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    if (callback) {
                        callback();
                    }
                });
            }
        );
    }

    setDataTable = () => {
        return <DataTable
            ref={this.tableRef}

            className={"table-style-v4"}
            classNameWrapper={"table-v4"}

            pageName={this.pageName}
            caption={'Audit Trail'}
            rowId={'auditTrailId'}

            noMarginBottom={true}
            verticalAlign={'middle'}

            stickyHeader={false}
            isHeader={true}
            resizable={true}
            isDragableHeaderColumns={true}
            isDragable={false}
            isBorder={false}
            isPagination={false}

            columnVisibility={this.state.columnVisibility}

            isLoading={this.state.isLoading}
            rowsPerPageSelected={this.state.rowsPerPageSelected}
            rowsPerPageOptions={this.state.rowsPerPageOptions}

            search={this.state.search}
            rows={this.state.rows}
            columns={this.state.columns}

            onRowClick={(id, row) => {
                this.setState({
                    isAuditTrailDialog: true,
                    auditTrailRow: row,
                });
            }}

            onRemove={(row) => {

            }}

            onSortEnd={(columns, columnVisibility) => {
                this.setState({
                    columns: columns,
                    columnVisibility: columnVisibility,
                }, () => {
                    this.setFilterInLocalStorage();
                });
            }}
            onOrder={(name, sort) => {
                this.setState({
                    sortColumn: name,
                    sortDir: sort,
                }, () => {
                    this.setFilterInLocalStorage();
                    this.callReadApi(false, () => {
                        this.setState({
                            totalRows: 0,
                            rows: [],
                            isLoading: false,
                        });
                    });
                });
            }}
            onResize={(columns) => {
                if(columns){
                  this.setState({
                    columns: columns
                  });
                }
                this.setFilterInLocalStorage();
            }}
        />
    }
    /* END FUNCTIONS */


    /* API */
    callReadApi = (withStatusCall = true, callback = null) => {
        let fromDate = moment(this.state.fromDate).format(Utils.getAPIDateFormat());
        let toDate = moment(this.state.toDate).format(Utils.getAPIDateFormat());

        this.setState({
            isLoading: true,
        });

        let data = {
            currentPage: this.state.currentPage,
            pageSize: this.state.rowsPerPageSelected,
            searchQuery: this.state.search,
            objectType: this.state.objectType,
            fromDate: fromDate,
            toDate: toDate,
            sortColumn: this.state.sortColumn,
            sortDir: this.state.sortDir,
        };

        this.props.dispatchApiCallGet(data, this.pageName + '-list', 'audittrail/get-by-user', null, null, callback);
    }
    /* END API */


    /* SECTIONS */
    topSection = () => {
        return <ViewTopSection
            fromDate={this.state.fromDate}
            toDate={this.state.toDate}
            dateRange={this.state.dateRange}
            isDisabledBatchAction={this.state.isDisabledBatchAction}
            batchActionLoading={this.state.batchActionLoading}

            onDateChange={(fromDate, toDate, dateRange) => {
                this.setState({
                    fromDate: fromDate,
                    toDate: toDate,
                    dateRange: dateRange,
                }, () => {
                    this.setFilterInLocalStorage();
                    this.callReadApi();
                });
            }}
            
            onChangeFilters={(searchQuery, objectType) => {
                this.setState({
                    currentPage: 1,
                    search: searchQuery,
                    objectType: objectType,
                }, () => {
                    this.setFilterInLocalStorage();
                    this.callReadApi();
                });
            }}
        />
    }

    toolbarSection = () => {
        return <Row>
            <Col className={'text-center text-md-end'}>
              {/* <div className='d-inline-flex align-items-center text-start'>
                <ExpandingSearchBox
                    btnClassName={'manage-icon-btn'}
                    value={this.state.search}
                    isOpen={(this.state.search !== '') ? true : false}
                    onPressEnter={(value) => {
                        this.setState({
                            search: value,
                            currentPage: 1,
                        }, () => {
                            this.setFilterInLocalStorage();
                            this.callReadApi(true, () => {
                                this.setState({
                                    totalRows: 0,
                                    rows: [],
                                    isLoading: false
                                });
                            });
                        });
                    }}
                    onClear={() => {
                        this.setState({
                            search: '',
                            currentPage: 1,
                        }, () => {
                            this.setFilterInLocalStorage();
                            this.callReadApi(true, () => {
                                this.setState({
                                    totalRows: 0,
                                    rows: [],
                                    isLoading: false
                                });
                            });
                        });
                    }}
                />
                </div> */}
                {Utils.isMobileTablet() && <div className='col-12 d-block d-md-none'></div>}
                <div className='d-inline-flex align-items-center text-start'>
                <DropdownIconButtonMenu
                    ref={this.refDropdownActionMenu}
                    className={'job-list-icon-button-dropdown'}
                    selected={null}
                    icon={<SettingsIcon />}
                >
                    <DataTableActionMenu
                        pageName={this.pageName}
                        columns={this.state.columns}
                        selected={this.state.rowsPerPageSelected}
                        selectItems={this.state.rowsPerPageOptions}
                        columnVisibility={this.state.columnVisibility}
                        onChangeColumnVisibility={(index, value) => {
                            let colVis = this.state.columnVisibility;
                            colVis[index] = value;

                            this.setState({
                                columnVisibility: colVis
                            }, () => {
                                this.setFilterInLocalStorage();
                            });
                        }}
                        canInactive={false}
                        onSelectChangeAjax={(val) => {
                            this.setState({
                                currentPage: 1,
                                rowsPerPageSelected: val
                            }, () => {
                                this.setFilterInLocalStorage();
                                this.callReadApi();
                            });
                        }}
                        isCookies={this.state.isCookies}
                        onResetColumns={() => {
                            this.setState({
                                columns: Array.from(this.state.initColumns),
                                columnVisibility: Array.from(this.state.initColumnVisibility),
                                sortColumn: this.state.sortColumnInit,
                                sortDir: this.state.sortDirInit,
                            }, () => {
                                this.setFilterInLocalStorage(false);
                                this.callReadApi();
                                if (this.refDropdownActionMenu && this.refDropdownActionMenu.current) {
                                    this.refDropdownActionMenu.current.hide();
                                }
                            });
                        }}
                        onShowMore={(isShowedMore) => {
                            this.setState({
                                pageName: this.pageName,
                            });
                        }}
                    />
                </DropdownIconButtonMenu>
                </div>
            </Col>
        </Row>
    }

    tableSection = () => {
        return <DataTableScrollSync ref={this.refDataTableScrollSync} className={"table-sync-v4"}>{this.setDataTable()}</DataTableScrollSync>
    }

    paginationSection = () => {
        return <DataTableAjaxPagination
            page={this.state.currentPage}
            selected={this.state.rowsPerPageSelected}
            count={this.state.totalRows}
            onPageChange={(page) => {
                this.setState({
                    currentPage: page,
                }, () => {
                    this.setFilterInLocalStorage();
                    this.callReadApi(false, () => {
                        this.setState({
                            totalRows: 0,
                            rows: [],
                            isLoading: false
                        });
                    });
                });
            }}
        />
    }

    /* END SECTIONS */


    /* DIALOGS */
    setAuditTrailDialog = () => {
        return <Modal
            show={this.state.isAuditTrailDialog}
            onHide={() => {
                this.setState({
                    isAuditTrailDialog: false,
                    auditTrailRow: null,
                });
            }}
            centered
            size="xl"
            backdrop="static"
            keyboard={false}
        >
            <AuditTrailDialog
                isLoading={false}
                item={this.state.auditTrailRow}
                onHide={() => {
                    this.setState({
                        isAuditTrailDialog: false,
                        auditTrailRow: null,
                    });
                }}
            />
        </Modal>
    }
    /* END DIALOGS */


    render() {
        return <div className={'audit-trail-page'}>
            <ViewLayout
                isBlocking={this.state.batchActionLoading}
                topSection={this.topSection()}
                toolbarSection={this.toolbarSection()}
                tableSection={this.tableSection()}
                paginationSection={this.paginationSection()}
            />

            {this.setAuditTrailDialog()}
        </div>
    }
}

const mapDispatchToProps = {
    dispatchApiCallGet,
    dispatchApiCallPost,
    dispatchApiCallPut,
    saveUserCookieSettings,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AuditTrailList);
