import React, { createContext, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { useFormik } from 'formik';
import _ from 'lodash';

import { 
    Row,
    Col,
    Button,
    Form,
    CloseButton,
} from "react-bootstrap-v5";

import Utils from '../../../utils/utils';
import { PayrollTemplateVariableType } from '../../../utils/enums';

import PayrollBetaFormLayout from "./layout/PayrollBetaForm";
import PayrollBetaStepContainer from "./partial/PayrollBetaStepContainer";
import PayrollBetaStep1 from "./partial/PayrollBetaStep1";
import PayrollBetaStep2 from "./partial/PayrollBetaStep2";
import PayrollBetaStep3a from "./partial/PayrollBetaStep3a";
import PayrollBetaStep3b from "./partial/PayrollBetaStep3b";
import PayrollBetaStep4 from "./partial/PayrollBetaStep4";
import CancelPopup from "./partial/CancelPopup";

import { useDispatch, useSelector } from "react-redux";
import {RootState} from '../../../../setup'
import payrollBetaLayout from './redux/payrollBetaLayout';
import payrollBetaForm, { fields, formSchema, initialValues, prepareData, prepareForm } from './redux/payrollBetaForm';


const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    const formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


const PayrollBetaForm = (props: any) => {
    const history = useHistory();

    const dispatch = useDispatch();
    const { step, step2Radio, step3aRadio, step3bRadio } = useSelector((state: RootState) => state.payrollBetaLayout);
    const { details } = useSelector((state: RootState) => state.payrollBetaForm);

    const [show, setShow] = React.useState(false);


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: formSchema(step),
        onSubmit: values => {
            let data = prepareData(values);
            let id = Utils.getIntProps(props);
            if(id && id > 0){
                data['payrollTemplateId'] = id;
                dispatch(payrollBetaForm.callUpdateApi(data, (state) => {
                    if(state){
                        cancel();
                    }
                }));
            } else {
                dispatch(payrollBetaForm.callCreateApi(data, (state) => {
                    if(state){
                        cancel();
                    }
                }));
            }
        },
    });
    const { values, errors, setValues, setFieldValue, validateForm, handleSubmit } = formik;

    
    React.useEffect(() => {
        let id = Utils.getIntProps(props);
        if(id && id > 0){
            dispatch(payrollBetaLayout.setMainLoading(true));
            dispatch(payrollBetaForm.callDetailsApi(id, (state: boolean, data: any) => {
                if(state){
                    let form = prepareForm(data, initialValues, (step, radio2, radio3) => {
                        let id = Utils.getIntProps(props);
                        if(id && id > 0){
                            // dispatch(payrollBetaLayout.setStep(step));
                            dispatch(payrollBetaLayout.setStep2Radio(radio2));
                            
                            if(step === '3a'){
                                dispatch(payrollBetaLayout.setStep3aRadio(radio3));
                            } else if(step === '3b'){
                                dispatch(payrollBetaLayout.setStep3bRadio(radio3));
                            }
                        }
                    });
                    setValues(form);
                }
                dispatch(payrollBetaLayout.setMainLoading(false));
            }));
        }

        dispatch(payrollBetaForm.callGeofenceGroupApi());
        dispatch(payrollBetaForm.callJobTypesApi());
    }, []);

    React.useEffect(() => {
        let payrollTemplateVariableType = values.payrollTemplateVariableType;

        if(step == '1'){
            payrollTemplateVariableType = PayrollTemplateVariableType.WorkTimeBeta;

        } else if(step == '2'){
            if(step2Radio){
                payrollTemplateVariableType = PayrollTemplateVariableType.WorkTimeGeofenceBeta;
            } else {
                payrollTemplateVariableType = PayrollTemplateVariableType.WorkTimeBeta;
            }

        } else if(step == '3a'){
            if(step3aRadio){
                payrollTemplateVariableType = PayrollTemplateVariableType.WorkTimeJobTypeBeta;
            } else {
                payrollTemplateVariableType = PayrollTemplateVariableType.WorkTimeBeta;
            }

        } else if(step == '3b'){
            if(step3bRadio){
                payrollTemplateVariableType = PayrollTemplateVariableType.WorkTimeGeofenceJobTypeBeta;
            } else {
                payrollTemplateVariableType = PayrollTemplateVariableType.WorkTimeGeofenceBeta;
            }
        }

        setFieldValue('payrollTemplateVariableType', payrollTemplateVariableType);
    }, [step, step2Radio, step3aRadio, step3bRadio]);


    const cancel = () => {
        dispatch(payrollBetaLayout.resetSlice());
        dispatch(payrollBetaForm.resetSlice());

        history.push('/manage/payroll/payroll');
    }
    const back = () => {
        dispatch(payrollBetaLayout.back());
    }
    const next = () => {
        validateForm().then((err: any) => {
            if(!_.isEmpty(err)){
                Utils.toast('You must fill in the required fields', 'error');
            } else {
                if(step == '1'){
                    if(values.workTimes.some((x: any) => x.isEnabled)){
                        dispatch(payrollBetaLayout.next());
                    } else {
                        Utils.toast('You must add work time', 'error');
                    }

                } else if(step == '2'){
                    if(step2Radio){
                        if(values.selectedGeofence.length > 0){
                            dispatch(payrollBetaLayout.next());
                        } else {
                            Utils.toast('You must select geofence', 'error');
                        }
                    } else {
                        dispatch(payrollBetaLayout.next());
                    }

                } else if(step == '3a'){
                    dispatch(payrollBetaLayout.next());

                } else if(step == '3b'){
                    dispatch(payrollBetaLayout.next());

                } else {
                    dispatch(payrollBetaLayout.next());
                }
            }
        })
    }

    const footerSection = () => {
        return <Row>
            <Col xs={12}>
                {(step === '1') && <Button variant={'light'} size={'sm'} className={'me-3'}
                    onClick={() => {
                        cancel();
                    }}
                >Cancel</Button>}
                {(step !== '1') && <Button variant={'light'} size={'sm'} className={'me-3'}
                    onClick={() => {
                        back();
                    }}
                >Back</Button>}
                {(step !== '4') && <Button size={'sm'}
                    onClick={() => {
                        next();
                    }}
                >Next</Button>}
                {(step === '4') && <Button type={'submit'} size={'sm'}>Save</Button>}
            </Col>
        </Row>
    }
    const topSection = () => {
        return <Row className={'align-items-end'}>
            <Col xs={12} className={'mb-5'}>
                <Row>
                    <Col xs={true}>
                        <h2>Driver Payroll template</h2>
                    </Col>
                    <Col xs={'auto'}>
                        <CloseButton
                            className={'rounded-2'}
                            onClick={() => {
                                setShow(true);
                            }}
                        />
                    </Col>
                </Row>
            </Col>
            <Col xs={12} lg={9}>
                <Form.Group as={Row} className={'mb-3'}>
                    <Form.Label column sm={3} lg={2} xl={'auto'} style={{ minWidth: '140px' }}>{fields.templateName.label}</Form.Label>
                    <Col sm={9} lg={7}>
                        <Form.Control
                            type="text"
                            size={'sm'}
                            id={fields.templateName.id}
                            placeholder={fields.templateName.placeholder}
                            value={values.templateName}
                            onChange={(e) => {
                                setFieldValue('templateName', e.target.value);
                            }}
                            isInvalid={!!(errors && errors.templateName)}
                        />
                        {/* <Form.Control.Feedback type="invalid">{errors && errors.templateName}</Form.Control.Feedback> */}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className={'mb-3'}>
                    <Form.Label column sm={3} lg={2} xl={'auto'} style={{ minWidth: '140px' }}>{fields.templateDescription.label}</Form.Label>
                    <Col sm={9} lg={7}>
                        <Form.Control
                            type="text"
                            size={'sm'}
                            id={fields.templateDescription.id}
                            placeholder={fields.templateDescription.placeholder}
                            value={values.templateDescription}
                            onChange={(e) => {
                                setFieldValue('templateDescription', e.target.value);
                            }}
                            isInvalid={!!(errors && errors.templateDescription)}
                        />
                        {/* <Form.Control.Feedback type="invalid">{errors && errors.templateDescription}</Form.Control.Feedback> */}
                    </Col>
                </Form.Group>
            </Col>
            {/* <Col xs={12} lg={3} className={'text-end mb-4'}>
                {(step === '1') && <Button variant={'light'} size={'sm'} className={'me-3'}
                    onClick={() => {
                        cancel();
                    }}
                >Cancel</Button>}
                {(step !== '1') && <Button variant={'light'} size={'sm'} className={'me-3'}
                    onClick={() => {
                        back();
                    }}
                >Back</Button>}
                {(step !== '4') && <Button size={'sm'}
                    onClick={() => {
                        next();
                    }}
                >Next</Button>}
                {(step === '4') && <Button type={'submit'} size={'sm'}>Save</Button>}
            </Col> */}
        </Row>
    }
    const step1Section = () => {
        return <PayrollBetaStepContainer
            title={'Step 1'}
            desc={'Set up your company work time policy'}
            padding={'0px 20px'}
        >
            <PayrollBetaStep1 />
        </PayrollBetaStepContainer>
    }
    const step2Section = () => {
        return <PayrollBetaStepContainer
            title={'Step 2'}
            desc={'Do you pay your driver based on locations?'}
        >
            <PayrollBetaStep2 />
        </PayrollBetaStepContainer>
    }
    const step3Section = () => {
        return <PayrollBetaStepContainer
            title={'Step 3'}
            desc={'Do you pay your driver based on job types?'}
        >
            {
                step2Radio
                ?
                <PayrollBetaStep3b />
                :
                <PayrollBetaStep3a />
            }
        </PayrollBetaStepContainer>
    }
    const step4Section = () => {
        return <PayrollBetaStepContainer
            title={'Step 4'}
            desc={'Do you pay your driver daily wage incentives?'}
        >
            <PayrollBetaStep4 />
        </PayrollBetaStepContainer>
    }


    return <FormikContext.Provider value={formik}>
        <form onSubmit={handleSubmit}>
            <PayrollBetaFormLayout
                footerSection={footerSection()}
                topSection={topSection()}
                step1Section={step1Section()}
                step2Section={step2Section()}
                step3Section={step3Section()}
                step4Section={step4Section()}
            />
            <CancelPopup
                show={show}
                onNo={() => {
                    setShow(false);
                }}
                onYes={() => {
                    setShow(false);
                    cancel();
                }}
            />
        </form>
    </FormikContext.Provider>
}


export default PayrollBetaForm;
