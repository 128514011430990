import React, { Component } from "react";

import JobsViewLayout from "./Layout/JobsViewLayout";
import JobViewTopSection from "./Partial/JobViewTopSection.jsx";
import JobViewStatusSection from "./Partial/JobViewStatusSection.jsx";
import JobViewTableSection from "./Partial/JobViewTableSection.jsx";

import Utils from "../../utils/utils";

import { connect } from 'react-redux'
import realTimeUpdate from "../../../setup/redux/slices/realTimeUpdate";
import dateSection from "../../../setup/redux/slices/dateSection";
import cancelToken from "../../../setup/redux/slices/cancelToken";

// import {
//   Button,
// } from "react-bootstrap-v5";


class JobsView extends Component {
  
  constructor(props) {
    super(props);

    props.layoutRef.current = this;

    this.pageName = "jobs-page";

    this.refJobViewTopSection = React.createRef();
    this.refJobViewTableSection = React.createRef();
    this.refJobViewStatusSection = React.createRef();

    this.state = {
      isLoadingTable: false,
      total: 0,
      isLoadingTotal: false,
      statues: [],
      isLoadingStatues: false,
      types: [],
      isLoadingTypes: false,
      jobTypeFilter: "",
      jobStatusFilter: "",

      isDisabledBatchAction: false,
    };
  }


  async componentDidMount() {
    await this.props.action.realTimeUpdate.getFilters({
      auth: this.props.auth,
      settingsType: this.props.settingsType,
      pageName: this.pageName,
    });
    
    await this.props.action.dateSection.dateGetFilters({
      auth: this.props.auth,
      settingsType: this.props.settingsType,
      pageName: this.pageName,
    });
  }
  async componentWillUnmount() {
    await this.props.action.cancelToken.cancel();
  }

  onWindowResize = () => {
    // this.getFilterInLocalStorage();
  }


  /* FUNCTIONS */
  loadTable = () => {
    if (this.refJobViewTableSection && this.refJobViewTableSection.current) {
      this.refJobViewTableSection.current.loadTable();
    }
  };

  loadTableShowAll = (isStatusOrTypes) => {
    if (this.refJobViewTableSection && this.refJobViewTableSection.current) {
      this.refJobViewTableSection.current.loadTableShowAll(isStatusOrTypes);
    }
  };

  loadTableShowAllWithStatues = () => {
    if (this.refJobViewTableSection && this.refJobViewTableSection.current) {
      this.refJobViewTableSection.current.loadTableShowAllWithStatues();
    }
  };

  loadTableByJobType = (jobTypeFilter = "") => {
    if (this.refJobViewTableSection && this.refJobViewTableSection.current) {
      this.refJobViewTableSection.current.loadTableByJobType(jobTypeFilter);
    }
  };

  loadTableByJobStatus = (jobStatusFilter = "") => {
    if (this.refJobViewTableSection && this.refJobViewTableSection.current) {
      this.refJobViewTableSection.current.loadTableByJobStatus(jobStatusFilter);
    }
  };
  resetStatuses = () => {
    if (this.refJobViewStatusSection && this.refJobViewStatusSection.current) {
      this.refJobViewStatusSection.current.resetStatuses();
    }
  };

  setActiveDropdown = () => {
    if (this.refJobViewTableSection && this.refJobViewTableSection.current) {
      return this.refJobViewTableSection.current.setActiveDropdown();
    }
  };
  setAdvancedSearch = () => {
    if (this.refJobViewTableSection && this.refJobViewTableSection.current) {
      return this.refJobViewTableSection.current.setAdvancedSearch();
    }
  };
  setSearch = () => {
    if (this.refJobViewTableSection && this.refJobViewTableSection.current) {
      return this.refJobViewTableSection.current.setSearch();
    }
  };
  setSettingsDropdown = (realTimeUpdateSelected) => {
    if (this.refJobViewTableSection && this.refJobViewTableSection.current) {
      return this.refJobViewTableSection.current.setSettingsDropdown(realTimeUpdateSelected);
    }
  };
  setColomnDropdown = () => {
    if (this.refJobViewTableSection && this.refJobViewTableSection.current) {
      return this.refJobViewTableSection.current.setColomnDropdown();
    }
  };

  getBatchAction = () => {
    if (this.refJobViewTableSection && this.refJobViewTableSection.current) {
      return this.refJobViewTableSection.current.getBatchAction();
    }
  };
  getBulkUpdate = () => {
    if (this.refJobViewTableSection && this.refJobViewTableSection.current) {
      return this.refJobViewTableSection.current.getBulkUpdate();
    }
  };
  setSelectedItemsCount = () => {
    if (this.refJobViewTableSection && this.refJobViewTableSection.current) {
      return this.refJobViewTableSection.current.setSelectedItemsCount();
    }
  };
  /* END FUNCTIONS */

  /* SECTIONS */
  topSection = () => {
    return (
      <JobViewTopSection
        ref={this.refJobViewTopSection}
        {...this.props}

        isDisabledBatchAction={this.state.isDisabledBatchAction}
        onChangeBatchAction={(isDisabledBatchAction) => {
          if (this.refJobViewTableSection && this.refJobViewTableSection.current) {
            this.refJobViewTableSection.current.setCheckedRows([], () => {
              this.setState({
                isDisabledBatchAction: isDisabledBatchAction,
              });
            })
          }
        }}

        onDateChange={() => {
          this.resetStatuses();
          this.loadTable();
        }}
        
        setActiveDropdown={this.setActiveDropdown}
        setAdvancedSearch={this.setAdvancedSearch}
        setSearch={this.setSearch}
        setSettingsDropdown={this.setSettingsDropdown}
        setColomnDropdown={this.setColomnDropdown}
        batchAction={this.getBatchAction}
        bulkUpdate={this.getBulkUpdate}
        setSelectedItemsCount={this.setSelectedItemsCount}
      />
    );
  };

  statusSection = () => {
    return (
      <JobViewStatusSection
        ref={this.refJobViewStatusSection}
        {...this.props}
        isLoadingTable={this.state.isLoadingTable}
        total={this.state.total}
        isLoadingTotal={this.state.isLoadingTotal}
        statues={this.state.statues}
        isLoadingStatues={this.state.isLoadingStatues}
        types={this.state.types}
        isLoadingTypes={this.state.isLoadingTypes}
        onClickShowAll={(isStatusOrTypes) => {
          this.loadTableShowAll(isStatusOrTypes);
        }}
        onClickStatusItem={(statusId) => {
          this.setState(
            {
              jobStatusFilter: statusId,
            },
            () => {
              this.loadTableByJobStatus(this.state.jobStatusFilter);
            }
          );
        }}
        onClickTypeItem={(item) => {
          this.setState(
            {
              jobTypeFilter: item.id,
            },
            () => {
              this.loadTableByJobType(this.state.jobTypeFilter);
            }
          );
        }}
      />
    );
  };

  tableSection = () => {
    return (
      <JobViewTableSection
        ref={this.refJobViewTableSection}
        isDisabledBatchAction={this.state.isDisabledBatchAction}
        {...this.props}
        onLoadingTable={(isLoading) => {
          if(isLoading != this.state.isLoadingTable){
            this.setState({
              isLoadingTable: isLoading,
            });
          }
        }}
        onLoadTable={(total) => {
          this.setState({
            total: total,
          });
        }}
        onTotalLoading={(isLoading) => {
          this.setState({
            isLoadingTotal: isLoading,
          });
        }}
        onStatusLoad={(statues, total) => {
          this.setState({
            statues: statues,
            total: total,
          });
        }}
        onStatusesLoading={(isLoading) => {
          this.setState({
            isLoadingStatues: isLoading,
          });
        }}
        onTypesLoad={(types) => {
          this.setState({
            types: types,
          });
        }}
        onTypesLoading={(isLoading) => {
          this.setState({
            isLoadingTypes: isLoading,
          });
        }}
        onTypesReset={() => {
          this.resetStatuses();
        }}
        onChangeBatchAction={(isDisabledBatchAction) => {
          this.setState({
            isDisabledBatchAction: isDisabledBatchAction,
          });
        }}
      />
    );
  };
  /* END SECTIONS */

  render() {
    return (
      <>
        {/* <Button
          className={'m-2'}
          onClick={async () => {
            await this.props.action.cancelToken.cancel();
          }}
        >Cancel API</Button> */}
        <JobsViewLayout
          topSection={this.topSection()}
          statusSection={this.statusSection()}
          tableSection={this.tableSection()}
        />
      </>
    );
  }
}


const mapStateToProps = state => {
  return {
    ...state,
    ...Utils.mapStateToProps(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  action: {
    realTimeUpdate: {
      getFilters: (payload) => dispatch(realTimeUpdate.getFilters(payload)),
    },
    dateSection: {
      dateGetFilters: (payload) => dispatch(dateSection.dateGetFilters(payload)),
    },
    cancelToken: {
      cancel: (payload) => dispatch(cancelToken.cancel(payload)),
    },
  }
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(JobsView);