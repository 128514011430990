import {put, call, all, fork, takeEvery, select} from 'redux-saga/effects'

import {
  clear,
  responseSuccess,
  responseError,
} from '../actions';

import {
  COMMON_PROFILE_POST,
  COMMON_PROFILE_GET,
  COMMON_PROFILE_PUT,
  COMMON_PROFILE_DELETE,
} from './actions';

import Utils from '../../../app/utils/utils';
import { CancelToken } from '../../../app/utils/enums';


let api = null;


export function* postW() {
  yield takeEvery(COMMON_PROFILE_POST, postF);
}
const createApiAsync = async (arg) => {
  let errorMessage = "";
  let status = 0;
  let isError = false;

  let data = await api.createApi(arg.payload.params, arg.payload.path, arg.payload.cancelRequestToken, arg.authorizationToken).then(result => {
    status = result.status;
    return result;
  }).catch(error => {
    console.info('⚡ commonProfile | error:', {
      path: arg.payload.path,
      params: arg.payload.params,
      error: error,
    });
    
    if (error && error.message && error.message == CancelToken.CANCELLED) {
      isError = false;
      errorMessage = null;
      status = 499;
    } else {
      Utils.parseError(error, (result) => {
        isError = result.isError;
        errorMessage = result.errorMessage;
        status = result.status;
      });
    }
  });
  return { data: data, errorMessage: errorMessage, isError: isError, status: status}
}
function* postF(data) {
  const token = yield select(state => state.auth.token);

  try {
    const result = yield call(createApiAsync, {...data, authorizationToken: token });
    if (result.status === 499) {
      yield put(responseError(result.errorMessage, data.payload.name, result.status, data.payload.variables, data.payload.callback, data.payload.callbackError500));
      yield put(clear());
    } else {
      if (result.errorMessage) {
        yield put(responseError(result.errorMessage, data.payload.name, result.status, data.payload.variables, data.payload.callback, data.payload.callbackError500));
        yield put(clear());
      } else {
        yield put(responseSuccess(result.data.data, data.payload.name, result.status, data.payload.variables, data.payload.callback, data.payload.callbackError500));
        yield put(clear());
      }
    }
  } catch (error) {
    yield put(responseError(null, data.payload.name, 599, data.payload.variables, data.payload.callback, data.payload.callbackError500));
    yield put(clear());
  }
}



export function* getW() {
  yield takeEvery(COMMON_PROFILE_GET, getF);
}
const readApiAsync = async (arg) => {
  let errorMessage = "";
  let status = 0;
  let isError = false;

  let data = await api.readApi(arg.payload.params, arg.payload.path, arg.payload.cancelRequestToken, arg.authorizationToken).then(result => {
    status = result.status;
    return result;
  }).catch(error => {
    console.info('⚡ commonProfile | error:', {
      path: arg.payload.path,
      params: arg.payload.params,
      error: error,
    });

    if (error && error.message && error.message == CancelToken.CANCELLED) {
      isError = false;
      errorMessage = null;
      status = 499;
    } else {
      Utils.parseError(error, (result) => {
        isError = result.isError;
        errorMessage = result.errorMessage;
        status = result.status;
      });
    }
  });
  return { data: data, errorMessage: errorMessage, isError: isError, status: status}
}
function* getF(data) {
  const token = yield select(state => state.auth.token);

  try {
    const result = yield call(readApiAsync, {...data, authorizationToken: token });
    if (result.status === 499) {
      yield put(responseError(result.errorMessage, data.payload.name, result.status, data.payload.variables, data.payload.callback, data.payload.callbackError500));
      yield put(clear());
    } else {
      if (result.errorMessage) {
        yield put(responseError(result.errorMessage, data.payload.name, result.status, data.payload.variables, data.payload.callback, data.payload.callbackError500));
        yield put(clear());
      } else {
        yield put(responseSuccess(result.data.data, data.payload.name, result.status, data.payload.variables, data.payload.callback, data.payload.callbackError500));
        yield put(clear());
      }
    }
  } catch (e) {
    yield put(responseError(null, data.payload.name, 599, data.payload.variables, data.payload.callback, data.payload.callbackError500));
    yield put(clear());
  }
}



export function* putW() {
  yield takeEvery(COMMON_PROFILE_PUT, putF);
}
const updateApiAsync = async (arg) => {
  let errorMessage = "";
  let status = 0;
  let isError = false;

  let data = await api.updateApi(arg.payload.params, arg.payload.path, arg.payload.cancelRequestToken, arg.authorizationToken).then(result => {
    status = result.status;
    return result;
  }).catch(error => {
    console.info('⚡ commonProfile | error:', {
      path: arg.payload.path,
      params: arg.payload.params,
      error: error,
    });

    if (error && error.message && error.message == CancelToken.CANCELLED) {
      isError = false;
      errorMessage = null;
      status = 499;
    } else {
      Utils.parseError(error, (result) => {
        isError = result.isError;
        errorMessage = result.errorMessage;
        status = result.status;
      });
    }
  });
  return { data: data, errorMessage: errorMessage, isError: isError, status: status}
}
function* putF(data) {
  const token = yield select(state => state.auth.token);

  try {
    const result = yield call(updateApiAsync, {...data, authorizationToken: token });
    if (result.status === 499) {
      yield put(responseError(result.errorMessage, data.payload.name, result.status, data.payload.variables, data.payload.callback, data.payload.callbackError500));
      yield put(clear());
    } else {
      if (result.errorMessage) {
        yield put(responseError(result.errorMessage, data.payload.name, result.status, data.payload.variables, data.payload.callback, data.payload.callbackError500));
        yield put(clear());
      } else {
        yield put(responseSuccess(result.data.data, data.payload.name, result.status, data.payload.variables, data.payload.callback, data.payload.callbackError500));
        yield put(clear());
      }
    }
  } catch (error) {
    yield put(responseError(null, data.payload.name, 599, data.payload.variables, data.payload.callback, data.payload.callbackError500));
    yield put(clear());
  }
}



export function* deleteW() {
  yield takeEvery(COMMON_PROFILE_DELETE, deleteF);
}
const deleteApiAsync = async (arg) => {
  let errorMessage = "";
  let status = 0;
  let isError = false;

  let data = await api.deleteApi(arg.payload.params, arg.payload.path, arg.payload.cancelRequestToken, arg.authorizationToken).then(result => {
    status = result.status;
    return result;
  }).catch(error => {
    console.info('⚡ commonProfile | error:', {
      path: arg.payload.path,
      params: arg.payload.params,
      error: error,
    });

    if (error && error.message && error.message == CancelToken.CANCELLED) {
      isError = false;
      errorMessage = null;
      status = 499;
    } else {
      Utils.parseError(error, (result) => {
        isError = result.isError;
        errorMessage = result.errorMessage;
        status = result.status;
      });
    }
  });
  return { data: data, errorMessage: errorMessage, isError: isError, status: status}
}
function* deleteF(data) {
  const token = yield select(state => state.auth.token);

  try {
    const result = yield call(deleteApiAsync, {...data, authorizationToken: token });
    if (result.status === 499) {
      yield put(responseError(result.errorMessage, data.payload.name, result.status, data.payload.variables, data.payload.callback, data.payload.callbackError500));
      yield put(clear());
    } else {
      if (result.errorMessage) {
        yield put(responseError(result.errorMessage, data.payload.name, result.status, data.payload.variables, data.payload.callback, data.payload.callbackError500));
        yield put(clear());
      } else {
        yield put(responseSuccess(result.data.data, data.payload.name, result.status, data.payload.variables, data.payload.callback, data.payload.callbackError500));
        yield put(clear());
      }
    }
  } catch (error) {
    yield put(responseError(null, data.payload.name, 599, data.payload.variables, data.payload.callback, data.payload.callbackError500));
    yield put(clear());
  }
}



export default function* crudSaga(apis) {
  api = apis;

  yield all([
    fork(postW),
    fork(getW),
    fork(putW),
    fork(deleteW),
  ]);
  
}
