import React from 'react';

import { 
  Row,
  Col,
} from "react-bootstrap-v5";

import { JobStepType } from '../../../../../utils/enums';


const JobsFormLayout = React.forwardRef((props, ref) => {
  let [headerSectionHeight, setHeaderSectionHeight] = React.useState(65);
  let [footerSectionHeight, setFooterSectionHeight] = React.useState(65);

  
  const resizeListener = () => {
    let headerSectionEl = document.getElementsByClassName('header-form-section')[0];
    let headerPosition = headerSectionEl.getBoundingClientRect();
    setHeaderSectionHeight(headerPosition.height);

    
    let footerSectionEl = document.getElementsByClassName('footer-form-section')[0];
    let footerPosition = footerSectionEl.getBoundingClientRect();
    setFooterSectionHeight(footerPosition.height);
  }


  let {
    values,
  } = props.formOptions;


  React.useEffect(() => {
    try{
      resizeListener();
    } catch(e){}
    
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  });
  

  return (
    <div className={'jobs-form-layout'}
      style={{
        overflow: 'hidden'
      }}
    >
        <div className={'header-form-section'}
          // style={{ 
          //   position: 'fixed',
          //   zIndex: '6',
          //   top: '65px',
          // }}
        >
          <div className={'py-7 px-5'}>{props.headerSection}</div>
        </div>

        <div
          className={'body-form-section'}
          style={{ 
            position: 'relative',
            // top: headerSectionHeight + 'px',
            paddingBottom: (footerSectionHeight * 1.5) + 'px',
          }}
        >
          <div className={'top-form-section px-10'}>
            <div className={'px-0 px-md-10'}>{props.topSection}</div>
          </div>

          {values.customerId && <div className={'job-type-form-section px-10'}>
              <div className={'px-0 px-md-10'}>{props.jobTypesSection}</div>
          </div>}

          {values.jobTemplateId && <div className={'locations-form-section px-10'}>
            <div className={'px-0 px-md-10'}>{props.locationsSection}</div>
          </div>}

          {values.customerSiteId && <div className={'steps-form-section py-10 px-10'}>
              <div className={'px-0 px-md-10'}>{props.stepsSection}</div>
          </div>}

          {values.customerSiteId && values.customFormParams.jobTemplateDetails && (!values.customFormParams.jobTemplateIsLoading) && <>
            <div className={'dispatch-form-sections px-10'}>
              <div className={'px-0 px-md-10'}>{props.dispatchSection}</div>
              
              {(!values.isEdit && props.multipleTripsSection) && <div className={'px-0 px-md-10'}>{props.multipleTripsSection}</div>}

              {((values.customFormParams.jobTemplateType === JobStepType.Put) &&(values.customFormParams.jobTemplateType === JobStepType.Out) && props.collectBinSection) && <div className={'px-0 px-md-10'}>{props.collectBinSection}</div>}

              {(values.customFormParams.jobTemplateType !== JobStepType.Out) && props.paymentSection && <Col
                className={'px-0 px-md-10'}
                xs={{ span: 12, order: 1 }}
                md={{ span: 12, order: 2 }}
              >{props.paymentSection}</Col>}

              {/* {values.isEdit && <div className={'px-0 px-md-10'}>{props.auditTrailSection}</div>} */}

              <div className={'px-0 px-md-10'}>
                <Row className={'gx-custom'}>
                  <Col xs={12}>
                    <Row>
                      {values.isEdit && <Col 
                        className={'photos-form-section pb-0 pb-md-10'}
                        xs={{ span: 12, order: 2 }}
                        md={{ span: 12, order: 1 }}
                      >{props.photosSection}</Col>}
                      {values.isEdit && <Col 
                        className={'photos-form-section pb-0 pb-md-10'}
                        xs={{ span: 12, order: 2 }}
                        md={{ span: 12, order: 1 }}
                      >{props.signatureSection}</Col>}
                      
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
            {props.recurringSection && <div className={'recurring-form-section py-10 px-10'}>
              <div className={'px-0 px-md-10'}>{props.recurringSection}</div>
            </div>}
          </>}

        </div>

        <div className={'footer-form-section'}>
          <div className={'py-7 px-5'}>{props.footerSection}</div>
        </div>
    </div>
  )
});

export default JobsFormLayout;