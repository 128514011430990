import React, { Component } from 'react';

import JobRouteMap from '../../../components/OLD/Pages/Jobs/JobForm/JobRouteMap.jsx';


class JobRouteDialog extends Component {
  
  constructor(props){
    super(props);

    let markers = (props.item && props.item.length > 0) ? props.item : [];
    let driverLocation = (props.driverLocation && props.driverLocation.length > 0) ? props.driverLocation : [];
    
    this.state = {
      markers: markers,
      driverLocation: driverLocation,
    }
  }


  setMap = () => {
    return <div style={{
        width: '100%',
        height: '400px',
      }}
    >
      <JobRouteMap markers={this.state.markers} driverLocation={this.state.driverLocation} />
    </div>
  }
  
  render() {
    return this.setMap()
  }
}

export default JobRouteDialog;
