import React from 'react';

import { 
  Grid
} from '@material-ui/core';

import { useDropzone } from 'react-dropzone';


const OuterDropzone = (props) => {
  const { accept, onDrop, disabled, maxSize, multiple, onDropRejected, onDropAccepted, getInputRef, children } = props;

  const { getRootProps, getInputProps, inputRef } = useDropzone({ 
    accept: accept,
    onDrop: onDrop,
    disabled: disabled,
    maxSize: maxSize,
    multiple: multiple,
    onDropRejected: onDropRejected,
    onDropAccepted: onDropAccepted,
    noClick: true
  });
  
  if(getInputRef)
    getInputRef(inputRef);

  return <Grid container spacing={2} {...getRootProps({className: 'dropzone-container'})}>
    <input {...getInputProps()} />
    {children}
  </Grid>
}

export default OuterDropzone;
