export const Period = {
    Hour: 'hour',
    Day: 'day',
    Week: 'week',
    Month: 'month',
    Year: 'year',
}

export const Zoom = {
    Min: 12,
    Max: 28,
    Init: 15,
}

export const ItemType = {
    Planed: 1,
    Actual: 2,
}