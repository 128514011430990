import React, { Component } from 'react';

import {
  Box
} from '@material-ui/core';

import Switch from "react-switch";


class CustomSwitch extends Component {
  
  constructor(props){
    super(props);

    let isExtraText = ((this.props.isExtraText === true || this.props.isExtraText === false) ? this.props.isExtraText : false);
    let checked = ((this.props.checked === true || this.props.checked === false) ? this.props.checked : false);
    let checkedIcon = (this.props.checkedIcon) ? this.props.checkedIcon : <span>Off</span>;
    let uncheckedIcon = (this.props.uncheckedIcon) ? this.props.uncheckedIcon : <span>On</span>;
    let checkedExtraText = (this.props.checkedExtraText) ? this.props.checkedExtraText : '';
    let uncheckedExtraText = (this.props.uncheckedExtraText) ? this.props.uncheckedExtraText : '';
    let onColor = (this.props.onColor) ? this.props.onColor : "#185cff";
    let offColor = (this.props.offColor) ? this.props.offColor : "#ECECEC";
    let width = (this.props.width) ? this.props.width : 100;
    let height = (this.props.height) ? this.props.height : 40;
    let disabled = (this.props.disabled) ? true : false;
    let className = (this.props.className) ? this.props.className : '';

    this.state = {
        isExtraText: isExtraText,
        checked: checked,
        checkedIcon: checkedIcon,
        uncheckedIcon: uncheckedIcon,
        onColor: onColor,
        offColor: offColor,
        checkedExtraText: checkedExtraText,
        uncheckedExtraText: uncheckedExtraText,
        width: width,
        height: height,
        disabled: disabled,
        className: className,
    }
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.checked !== this.state.checked) {
      this.setState({
        checked: nextProps.checked
      });
    }
    if (nextProps.disabled !== this.state.disabled) {
      this.setState({
        disabled: nextProps.disabled
      });
    }
  }


  handleChange = (checked)  => {
    this.setState({
        checked: checked
    });

    if(this.props.onChange)
      this.props.onChange(checked);
  };


  render() {
    return <Box className={"custom-swich-component " + this.state.className + (this.state.disabled ? ' disabled' : '') + ' ' + (this.state.checked ? 'active' : '')}>
      <Switch
        width={this.state.width}
        height={this.state.height}
        checked={this.state.checked}
        onChange={this.handleChange}
        checkedIcon={this.state.checkedIcon}
        uncheckedIcon={this.state.uncheckedIcon}
        onColor={this.state.onColor}
        offColor={this.state.offColor}
        disabled={this.state.disabled}
      />
      {
        (this.state.isExtraText)
        ?
          (this.state.checked)
          ?
          <span className={'custom-extra-text'}>{this.state.checkedExtraText}</span>
          :
          <span className={'custom-extra-text'}>{this.state.uncheckedExtraText}</span>
        :
        null
      }
    </Box>;
  }
}

export default CustomSwitch;
