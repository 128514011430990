import React, { Component } from 'react';

import parse from "html-react-parser";

import { 
    Row,
    Col,
    Modal,
    Button,
    CloseButton,
    Spinner,
} from "react-bootstrap-v5";

import AuditTrailFunctions from '../../../components/OLD/Pages/AuditTrail/AuditTrailFunctions.jsx';
import AuditTrailDetails from '../../../components/OLD/Pages/AuditTrail/AuditTrailDetails.jsx';

import Utils from '../../../utils/utils'

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet } from '../../../../setup/redux/dispatch/actions'


class AuditTrailDialog extends Component {
  
    constructor(props){
        super(props);

        this.pageName = 'audit_trail_dialog';

        let item = (props && props.item) ? props.item : null;
        
        let description = (item && item.description) ? item.description : '';
        let auditTrailId = (item && item.auditTrailId) ? item.auditTrailId : null;
        let objectId = (item && item.objectId) ? item.objectId : null;
        let objectType = (item) ? item.objectType : null;

        this.state = {
          description: description,
          auditTrailId: auditTrailId,
          objectId: objectId,
          objectType: objectType,
          content: null,
        }
    }


    componentDidMount() {
      this.callCompareAuditTrailApi(this.state.auditTrailId, this.state.objectId, this.state.objectType);
    }

    componentWillReceiveProps(nextProps) {
      this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
      Utils.reduxProps(nextProps,
          this.pageName + '-compare_audit_trail', 
          (data, isLoading, isError, err, statusCode, variables, callback) => {
            let currentVersion = (data && data.currentVersion) ? data.currentVersion : null;
            let previousVersion = (data && data.previousVersion) ? data.previousVersion : null;
            
            let content = null;
            let detailsMsg = <div style={{ fontWeight: 'bold' }}>Audit trails is not recorded for old data!</div>;
            if(currentVersion){
              AuditTrailFunctions.getManageData(this.state.objectType, currentVersion, previousVersion, (c, p) => {
                currentVersion = (c) ? c : null;
                previousVersion = (p) ? p : null;
              });
              
              let details = this.getDetails(0, currentVersion, previousVersion, this.state.objectType);
              if(details){
                content = details;
              } else {
                content = detailsMsg;
              }
            } else {
              content = detailsMsg;
            }

            this.setState({
              content: content,

              isLoading: isLoading,
              isError: isError,
              err: err,
            }, () => {
              this.props.clear();
              
              if(this.state.isError){
                Utils.toast(this.state.err, 'error');
              }
              
              if(callback){
                callback(currentVersion, previousVersion);
              }
            });
          }
      );
    }

    callCompareAuditTrailApi = (auditTrailId, objectId, objectType, callback = null) => {
      this.setState({
        isLoading: true,
      });
  
      this.props.dispatchApiCallGet(null, this.pageName + '-compare_audit_trail', "audittrail/compare-audit-trail/" + objectType + "/" + objectId + "/" + auditTrailId, null, callback, () => {});
    }
    /* END API */


    getDetails = (index, currentVersion, previousVersion, objectType) => {
      return <AuditTrailDetails
        index={index}
        currentVersion={currentVersion}
        previousVersion={previousVersion}
        objectType={objectType}
        details={(obj, index, currentVersion, previousVersion, objectType) => {
          return AuditTrailFunctions.getDetails(objectType, obj, index, currentVersion, previousVersion);
        }}
      />
    }


    header = () => {
        return <Modal.Header>
            <Row className={'align-items-center gx-0 w-100'}>
                <Col xs={true}>
                    <Modal.Title>{parse(this.state.description)}</Modal.Title>
                </Col>
                <Col xs={'auto'}>
                    <CloseButton
                        className={'rounded-2'}
                        onClick={() => {
                            if(this.props.onHide){
                                this.props.onHide();
                            }
                        }}
                    />
                </Col>
            </Row>
        </Modal.Header>
    }
    
    body = () => {
        return <Modal.Body>
            <Row>
                <Col xs={12}>
                  {
                    this.state.isLoading
                    ? 
                      <div className={'text-center'}>
                        <Spinner animation="border" />
                      </div>
                    : 
                      this.state.content
                  }
                </Col>
            </Row>
        </Modal.Body>
    }

    footer = () => {
        return <Modal.Footer>
            <Row className={'w-100'}>
                <Col xs={true}>
                    
                </Col>
                <Col xs={'auto'}>
                   <Button 
                        type={'button'}
                        variant={'light'}
                        disabled={this.state.isLoading}
                        onClick={() => {
                          if(this.props.onHide) {
                            this.props.onHide()
                          }
                        }}
                    >
                      Cancel
                    </Button>
                </Col>
            </Row>
        </Modal.Footer>
    }


    render() {
      return <>
        {this.header()}
        {this.body()}
        {this.footer()}
      </>
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AuditTrailDialog);
