import React, { Component } from "react";
import * as yup from 'yup';

import { 
  Row,
  Col,
  Button,
  Badge,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import { DrawerLayout } from '../../../components/drawer/DrawerLayout'
import CurrentDateTime from '../../../components/currentDateTime/CurrentDateTime'

import ViewLayout from "../layout/ViewLayout";
import PayrollForm from "./PayrollForm";

import DataTable from "../../../components/OLD/DataTable/DataTable";
import DataTableActionMenu from '../../../components/OLD/DataTable/DataTableActionMenu';
import DataTableAjaxPagination from '../../../components/OLD/DataTable/DataTableAjaxPagination.jsx';
import DataTableScrollSync from '../../../components/OLD/DataTable/DataTableScrollSync.jsx';
import DropdownIconButtonMenu from '../../../components/OLD/Dropdown/DropdownIconButtonMenu';
import IconButtonSearch from '../../../components/OLD/input/IconButtonSearch';
import makeExpanding from '../../../components/OLD/Expanding/makeExpanding';
import RemoveDialog from '../../../components/dialog/RemoveDialog';
import AdvancedFilter from "../../../components/OLD/Filter/AdvancedFilter.jsx";
import IconButton from "../../../components/Buttons/IconButton";
import IconButtonTable from "../../../components/Buttons/IconButtonTable";

import { ReactComponent as PrintIcon } from "../../../../_metronic/assets/img/icons/report-print.svg";
import { ReactComponent as DownloadIcon } from "../../../../_metronic/assets/img/icons/report-download.svg";
import { ReactComponent as SettingsIcon } from "../../../../_metronic/assets/img/icons/manage-settings.svg";  
import { ReactComponent as FilterIcon } from "../../../../_metronic/assets/img/icons/feather_filter.svg";  

import Utils from '../../../utils/utils'
import { PageCookieSettingType, PagesPermissions, PayrollTemplateVariableType, DayOfWeek, AuditTrailObjectType } from '../../../utils/enums'

import AuditTrailDialog from '../../../components/OLD/Pages/AuditTrail/AuditTrailDialog.jsx';
import AuditTrailFunctions from '../../../components/OLD/Pages/AuditTrail/AuditTrailFunctions.jsx';

import { connect } from 'react-redux'
import { actions } from "../../../../setup/redux/auth/saga";
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut, dispatchApiCallDelete } from '../../../../setup/redux/dispatch/actions'
import payrollBetaLayout from './redux/payrollBetaLayout';
import payrollBetaForm from './redux/payrollBetaForm';

const { saveUserCookieSettings } = actions;
const ExpandingSearchBox = makeExpanding(IconButtonSearch);

const StyledFormWizard = styled.a`
  position: relative;
  top: 4px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
  border-bottom: 1px dashed transparent;
  transition: all 0.2s;

  i {
    position: relative;
    top: 3px;
    font-size: 25px;
    margin-right: 7px;
  }

  .text {
    position: relative;
    top: 1px;
    color: #484848;
    font-weight: 500;
    margin-right: 8px;
  }

  &:hover {
    border-bottom: 1px dashed #484848;
    transition: all 0.2s;
  }
`;

const StyledBadge = styled(Badge)`
  background-color: #9E00FF !important;
`;

const formFields = {
  templateName: {
    id: 'templateName',
    label: 'Name',
    placeholder: ' ',
  },
  templateDescription: {
    id: 'templateDescription',
    label: 'Description',
    placeholder: ' ',
  },
  basicPay: {
    id: 'basicPay',
    label: 'Daily wage',
    placeholder: ' ',
  },
  payrollTemplateVariableTypeId: {
    id: 'payrollTemplateVariableTypeId',
    label: 'Variable wage',
    placeholder: ' ',
  },

  accountWorkingHourId: {
    id: 'accountWorkingHourId',
    label: 'Work time',
    placeholder: ' ',
  },
  workTimeName: {
    id: 'workTimeName',
    label: 'Work time name',
    placeholder: ' ',
  },
  workTimeDescription: {
    id: 'workTimeDescription',
    label: 'Work time description',
    placeholder: ' ',
  },

  accountWorkingHourDays: {
    id: 'accountWorkingHourDays',
    label: ' ',
    placeholder: ' ',
  },
  accountWorkingHourDaysDay: {
    id: 'accountWorkingHourDaysDay',
    label: ' ',
    placeholder: ' ',
  },
  accountWorkingHourDaysTimeFrom: {
    id: 'accountWorkingHourDaysTimeFrom',
    label: 'Time',
    placeholder: ' ',
  },
  accountWorkingHourDaysTimeTo: {
    id: 'accountWorkingHourDaysTimeTo',
    label: 'Time',
    placeholder: ' ',
  },
  standardPay: {
    id: 'standardPay',
    label: ' ',
    placeholder: ' ',
  },
  overtimePay: {
    id: 'overtimePay',
    label: ' ',
    placeholder: ' ',
  },

  payrollTemplateVariableRanges: {
    id: 'payrollTemplateVariableRanges',
    label: ' ',
    placeholder: ' ',
  },
  variableDistanceName: {
    id: 'variableDistanceName',
    label: ' ',
    placeholder: ' ',
  },
  payrollTemplateVariableRangeDistanceFrom: {
    id: 'payrollTemplateVariableRangeDistanceFrom',
    label: ' ',
    placeholder: ' ',
  },
  payrollTemplateVariableRangeDistanceTo: {
    id: 'payrollTemplateVariableRangeDistanceTo',
    label: ' ',
    placeholder: ' ',
  },
  payrollTemplateVariableRangeStandardPay: {
    id: 'payrollTemplateVariableRangeStandardPay',
    label: ' ',
    placeholder: ' ',
  },
  payrollTemplateVariableRangeOvertimePay: {
    id: 'payrollTemplateVariableRangeOvertimePay',
    label: ' ',
    placeholder: ' ',
  },
  
  payrollTemplateVariableJobTemplates: {
    id: 'payrollTemplateVariableJobTemplates',
    label: ' ',
    placeholder: ' ',
  },
  payrollTemplateVariableJobTemplatesStandardPay: {
    id: 'payrollTemplateVariableJobTemplatesStandardPay',
    label: ' ',
    placeholder: ' ',
  },
  payrollTemplateVariableJobTemplatesOvertimePay: {
    id: 'payrollTemplateVariableJobTemplatesOvertimePay',
    label: ' ',
    placeholder: ' ',
  },
  
  payrollTemplateVariableJobTemplateRanges: {
    id: 'payrollTemplateVariableJobTemplateRanges',
    label: ' ',
    placeholder: ' ',
  },
  payrollTemplateVariableJobTemplateRangesStandardPay: {
    id: 'payrollTemplateVariableJobTemplateRangesStandardPay',
    label: ' ',
    placeholder: ' ',
  },
  payrollTemplateVariableJobTemplateRangesOvertimePay: {
    id: 'payrollTemplateVariableJobTemplateRangesOvertimePay',
    label: ' ',
    placeholder: ' ',
  },
}
const formSchema = yup.object().shape({
  isEdit: yup.bool().oneOf([true, false]),

  templateName: yup.string().required().label(formFields.templateName.label),
  templateDescription: yup.string().label(formFields.templateDescription.label),
  basicPay: yup.number().nullable().min(0).label(formFields.basicPay.label),
  payrollTemplateVariableTypeId: yup.number().nullable().label(formFields.payrollTemplateVariableTypeId.label),

  accountWorkingHourIsNew: yup.bool().oneOf([true, false]),
  accountWorkingHourId: yup.number().when(['payrollTemplateVariableTypeId', 'accountWorkingHourIsNew'], (payrollTemplateVariableTypeId, accountWorkingHourIsNew) => {
    if(accountWorkingHourIsNew){
      return yup.number().nullable().label(formFields.accountWorkingHourId.label);
    } else {
      if(payrollTemplateVariableTypeId !== PayrollTemplateVariableType.None){
        return yup.number().nullable().required().label(formFields.accountWorkingHourId.label);
      } else {
        return yup.number().nullable().label(formFields.accountWorkingHourId.label);
      }
    }
  }),
  workTimeName: yup.string().when(['payrollTemplateVariableTypeId', 'accountWorkingHourId', 'accountWorkingHourIsNew'], (payrollTemplateVariableTypeId, accountWorkingHourId, accountWorkingHourIsNew) => {
    if(payrollTemplateVariableTypeId !== PayrollTemplateVariableType.None){
      if(accountWorkingHourId){
        return yup.string().label(formFields.workTimeName.label);
      } else {
        if(accountWorkingHourIsNew){
          return yup.string().required().label(formFields.workTimeName.label);
        } else {
          return yup.string().label(formFields.workTimeName.label);
        }
      }
    } else {
      return yup.string().label(formFields.workTimeName.label);
    }
  }),
  workTimeDescription: yup.string().label(formFields.workTimeDescription.label),
  standardPay: yup.number().nullable().min(0).label(formFields.standardPay.label),
  overtimePay: yup.number().nullable().min(0).label(formFields.overtimePay.label),

  accountWorkingHourDays: yup.array().of(
    yup.object().shape({
      day: yup.number().nullable().min(DayOfWeek.Sunday),
      isEnabled: yup.bool().oneOf([true, false]),
      timeFrom: yup.date().when(['isEnabled'], (isEnabled) => {
        if(isEnabled){
          return yup.date().nullable().required().label(formFields.accountWorkingHourDaysTimeFrom.label);
        } else {
          return yup.date().nullable().label(formFields.accountWorkingHourDaysTimeFrom.label);
        }
      }),
      timeTo: yup.date().when(['isEnabled'], (isEnabled) => {
        if(isEnabled){
          return yup.date().nullable().required().label(formFields.accountWorkingHourDaysTimeTo.label);
        } else {
          return yup.date().nullable().label(formFields.accountWorkingHourDaysTimeTo.label);
        }
      }),
    })
  ),
  
  payrollTemplateVariableRanges: yup.array().when(['payrollTemplateVariableTypeId'], (payrollTemplateVariableTypeId) => {
    if(payrollTemplateVariableTypeId === PayrollTemplateVariableType.WorkTimeDistance || payrollTemplateVariableTypeId === PayrollTemplateVariableType.WorkTimeDistanceJobType){
      return yup.array().of(
        yup.object().shape({
          variableDistanceName: yup.string().required().label(formFields.variableDistanceName.label),
          distanceFrom: yup.number().nullable().min(0).required().label(formFields.payrollTemplateVariableRangeDistanceFrom.label),
          distanceTo: yup.number().nullable().min(0).required().label(formFields.payrollTemplateVariableRangeDistanceTo.label),
          standardPay: yup.number().nullable().min(0).label(formFields.payrollTemplateVariableRangeStandardPay.label),
          overtimePay: yup.number().nullable().min(0).label(formFields.payrollTemplateVariableRangeOvertimePay.label),
        })
      )
    } else {
      yup.object().shape({
        variableDistanceName: yup.string().label(formFields.variableDistanceName.label),
        distanceFrom: yup.number().nullable().min(0).label(formFields.payrollTemplateVariableRangeDistanceFrom.label),
        distanceTo: yup.number().nullable().min(0).label(formFields.payrollTemplateVariableRangeDistanceTo.label),
        standardPay: yup.number().nullable().min(0).label(formFields.payrollTemplateVariableRangeStandardPay.label),
        overtimePay: yup.number().nullable().min(0).label(formFields.payrollTemplateVariableRangeOvertimePay.label),
      })
    }
  }),

  payrollTemplateVariableJobTemplates: yup.array().of(
    yup.object().shape({
      standardPay: yup.number().nullable().min(0).label(formFields.payrollTemplateVariableJobTemplatesStandardPay.label),
      overtimePay: yup.number().nullable().min(0).label(formFields.payrollTemplateVariableJobTemplatesOvertimePay.label),
      payrollTemplateVariableJobTemplateRanges: yup.array().of(
        yup.object().shape({
          standardPay: yup.number().nullable().min(0).label(formFields.payrollTemplateVariableJobTemplateRangesStandardPay.label),
          overtimePay: yup.number().nullable().min(0).label(formFields.payrollTemplateVariableJobTemplateRangesOvertimePay.label),
        })
      ),
    })
  ),
});


class PayrollView extends Component {

  constructor(props) {
    super(props);

    this.pageName = "manage-payroll-page";
    this.pageIdField = "payrollTemplateId";
    this.pageNameField = "templateName";
    this.pageAPI = "payrollTemplate";
    this.pageCaption = 'Payroll'

		let user = (props.auth && props.auth.user) ? props.auth.user : null;
		this.permissions = (user && user.permissions && user.permissions.length > 0) ? user.permissions : [];

    props.layoutRef.current = this;
    this.formDrawer = null;

    this.tableRef = React.createRef();
    this.refDropdownAdvancedFilter = React.createRef();
    this.refDropdownActionMenu = React.createRef();
    this.refFormDrawer = React.createRef();
    this.refDataTableScrollSync = React.createRef();


    this.templateNameTemplate = (id, row, col, obj, index) => {
      return <>
        <span>{row.templateName}</span>
        {
          (
            (row.payrollTemplateVariableType == PayrollTemplateVariableType.WorkTimeGeofenceBeta) || 
            (row.payrollTemplateVariableType == PayrollTemplateVariableType.WorkTimeGeofenceJobTypeBeta) || 
            (row.payrollTemplateVariableType == PayrollTemplateVariableType.WorkTimeBeta) || 
            (row.payrollTemplateVariableType == PayrollTemplateVariableType.WorkTimeJobTypeBeta)
          )
          ?
          <StyledBadge pill bg="primary" className={'ms-3'}>Beta</StyledBadge>
          :
          null
        }
      </>
    }
    this.templateNameExportTemplate = (row) => {
      return row.templateName;
    };
    this.templateNameExportCSVTemplate = (row) => {
      return row.templateName;
    };

    this.actionTemplate = (id, row, col, obj, index) => {
      return <IconButtonTable
        title={'Remove'}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          this.setState({
            isRemoveDialogTable: true,
            removeRowTable: id,
          });
        }}
      
        >
        <i className={'material-icons'}>delete</i>
      </IconButtonTable>
    }


    let columns = [
      { 
        id: this.pageIdField, 
        filterKey: this.pageIdField, 
        name: this.pageIdField, 
        label: 'ID',
        visible: false
      },
      {
        id: "templateName",
        name: "templateName",
        label: "Name",
        responsiveLabel: "Name",
        orderable: true,
        template: this.templateNameTemplate,
        exportTemplate: this.templateNameExportTemplate,
        exportCSVTemplate: this.templateNameExportCSVTemplate,
      },
      {
        id: "templateDescription",
        name: "templateDescription",
        label: "Description",
        responsiveLabel: "Description",
        orderable: true,
      },
      {
        id: 'action',
        filterKeyParam: 'action',
        name: 'action',
        label: '',
        width: '50px',
        responsiveLabel: '',
        orderable: false,
        exportVisible: false,
        visibleInDropdown: false,
        filterable: false,
        template: this.actionTemplate,
      },
    ];
    let initColumns = Array.from(columns);
    columns = Array.from(initColumns);
    
    
    let columnVisibility = [
      false,
      true,
      true,
      true,
    ];
    let initColumnVisibility = Array.from(columnVisibility);
    columnVisibility = Array.from(initColumnVisibility);


    this.state = {
      data: null,
      err: null,
      isError: false,
      isLoading: false,

      search: '',
      sortColumn: 'updated',
      sortDir: 'desc',
      searchTags: [],
      searchOperator: [],
      searchColumns: [],

      selected: [],
      columnVisibility: columnVisibility,
      initColumnVisibility: initColumnVisibility,
      columns: columns,
      initColumns: initColumns,
      rows: [],
      currentPage: 1,
      totalRows: 0,
      rowsPerPageSelected: 50,
      rowsPerPageOptions: [50, 100, 300],
      
      isCookies: false,
      
      isOpenAuditTrail: false,
      objectId: null,

      isRemoveDialog: false,
      removeRow: null,

      isRemoveDialogTable: false,
      removeRowTable: null,

      isIncludeInactive: false,

      isDisabledBatchAction: true,
      batchActionLoading: false,

      isLoadingFormDrawer: false,
      isVisibleFormDrawer: false,
      isBlockingFormDrawer: false,
      rowInitialValues: {
        isEdit: false,
        templateName: '',
        templateDescription: '',
        basicPay: '',
        payrollTemplateVariableTypeId: PayrollTemplateVariableType.None,
        payrollTemplateVariableTypeName: 'None',
        accountWorkingHourIsNew: false,
        accountWorkingHourId: null,
        accountWorkingHourName: '',
        workTimeName:  '',
        workTimeDescription: '',
        standardPay: '',
        overtimePay: '',
        accountWorkingHourDays: [
          {
            label: Utils.getDayOfWeekLabel(DayOfWeek.Sunday),
            day: DayOfWeek.Sunday,
            isEnabled: false,
            timeFrom: null,
            timeTo: null,
          },
          {
            label: Utils.getDayOfWeekLabel(DayOfWeek.Monday),
            day: DayOfWeek.Monday,
            isEnabled: false,
            timeFrom: null,
            timeTo: null,
          },
          {
            label: Utils.getDayOfWeekLabel(DayOfWeek.Tuesday),
            day: DayOfWeek.Tuesday,
            isEnabled: false,
            timeFrom: null,
            timeTo: null,
          },
          {
            label: Utils.getDayOfWeekLabel(DayOfWeek.Wednesday),
            day: DayOfWeek.Wednesday,
            isEnabled: false,
            timeFrom: null,
            timeTo: null,
          },
          {
            label: Utils.getDayOfWeekLabel(DayOfWeek.Thursday),
            day: DayOfWeek.Thursday,
            isEnabled: false,
            timeFrom: null,
            timeTo: null,
          },
          {
            label: Utils.getDayOfWeekLabel(DayOfWeek.Friday),
            day: DayOfWeek.Friday,
            isEnabled: false,
            timeFrom: null,
            timeTo: null,
          },
          {
            label: Utils.getDayOfWeekLabel(DayOfWeek.Saturday),
            day: DayOfWeek.Saturday,
            isEnabled: false,
            timeFrom: null,
            timeTo: null,
          },
        ],
        payrollTemplateVariableRanges: [
          {
            variableDistanceName: '',
            distanceFrom: '',
            distanceTo: '',
            standardPay: '',
            overtimePay: '',
          }
        ],
        payrollTemplateVariableJobTemplates: [
          {
            standardPay: '',
            overtimePay: '',
            payrollTemplateVariableJobTemplateRanges: [
              {
                variableDistanceName: '',
                standardPay: '',
                overtimePay: '',
              }
            ]
          }
        ],
        customFormParams: {
          accountWorkingHourItems: [],
          accountWorkingHourIsLoading: false,
          jobTemplateItems: [],
        }
      },
      rowValues: null,
    }
  }


  componentDidMount() {
    this.initDrawers();

    this.getFilterInLocalStorage(() => {
      this.callReadApi();
    });
  }
  
  componentWillReceiveProps(nextProps) {
    this.reduxProps(nextProps);
	}
  

	/* FUNCTIONS */
  onWindowResize = () => {
    this.getFilterInLocalStorage();
  }

  setFilterInLocalStorage = (isCookies = true) => {
    if(this.props.settingsType >= 0){
      let { token, userCookieSettings } = this.props.auth;

      this.setState({
        isCookies: isCookies,
      });
      
      let data = {
        name: this.pageName,
        type: this.props.settingsType,
      };

      let valueLocalStorage = {
        [PageCookieSettingType.currentPage]: this.state.currentPage,
      };
      window.localStorage.setItem(this.pageName + '_' + this.props.settingsType, JSON.stringify(valueLocalStorage));

      let value = {
        [PageCookieSettingType.isCookies]: isCookies,
        [PageCookieSettingType.search]: this.state.search,
        [PageCookieSettingType.sortColumn]: this.state.sortColumn,
        [PageCookieSettingType.sortDir]: this.state.sortDir,
        [PageCookieSettingType.rowsPerPageSelected]: this.state.rowsPerPageSelected,
        [PageCookieSettingType.columnVisibility]: this.state.columnVisibility,
        [PageCookieSettingType.columns]: this.state.columns,
        [PageCookieSettingType.isIncludeInactive]: this.state.isIncludeInactive,
      };
      data['value'] = JSON.stringify(value);

      Utils.saveUserCookieSettings(data, token, (settings) => {
        if(settings){
          let settingsArr = (userCookieSettings && userCookieSettings.length > 0) ? userCookieSettings : [];
          let settingsIndex = Utils.getUserCookieSettingsIndex(settingsArr, this.props.settingsType);
          if(settingsIndex !== -1 && settingsArr[settingsIndex]){
            settingsArr[settingsIndex] = settings;
          } else {
            settingsArr.push(settings);
          }

          this.props.saveUserCookieSettings(settingsArr)
        }
      });
    }
  };
  getFilterInLocalStorage = (callback = null) => {
    let settingsItem = null;

    if(this.props.settingsType >= 0){
      const { userCookieSettings } = this.props.auth;

      settingsItem = Utils.getUserCookieSettings(userCookieSettings, this.props.settingsType, this.pageName);
    }

    let valueLocalStorageStr = window.localStorage.getItem(this.pageName + '_' + this.props.settingsType);
    let valueLocalStorage = JSON.parse(valueLocalStorageStr);
    let currentPageValue = valueLocalStorage && valueLocalStorage[PageCookieSettingType.currentPage] && valueLocalStorage[PageCookieSettingType.currentPage] !== "" ? valueLocalStorage[PageCookieSettingType.currentPage] : this.state.currentPage;
    
    let isCookiesValue = settingsItem && settingsItem[PageCookieSettingType.isCookies] && settingsItem[PageCookieSettingType.isCookies] !== "" ? settingsItem[PageCookieSettingType.isCookies] : this.state.isCookies;
    let searchValue = (settingsItem && settingsItem[PageCookieSettingType.search] && settingsItem[PageCookieSettingType.search] !== '') ? settingsItem[PageCookieSettingType.search] : this.state.search;
    let sortColumnValue = settingsItem && settingsItem[PageCookieSettingType.sortColumn] && settingsItem[PageCookieSettingType.sortColumn] !== "" ? settingsItem[PageCookieSettingType.sortColumn] : this.state.sortColumn;
    let sortDirValue = settingsItem && settingsItem[PageCookieSettingType.sortDir] && settingsItem[PageCookieSettingType.sortDir] !== "" ? settingsItem[PageCookieSettingType.sortDir] : this.state.sortDir;
    let rowsPerPageSelectedValue = settingsItem && settingsItem[PageCookieSettingType.rowsPerPageSelected] && settingsItem[PageCookieSettingType.rowsPerPageSelected] !== "" ? settingsItem[PageCookieSettingType.rowsPerPageSelected] : this.state.rowsPerPageSelected;
    let columnVisibilityValue = (settingsItem && settingsItem[PageCookieSettingType.columnVisibility] && settingsItem[PageCookieSettingType.columnVisibility] !== '') ? settingsItem[PageCookieSettingType.columnVisibility] : this.state.columnVisibility;
    let columnsValue = (settingsItem && settingsItem[PageCookieSettingType.columns] && settingsItem[PageCookieSettingType.columns] !== '') ? settingsItem[PageCookieSettingType.columns] : this.state.columns;
    let isIncludeInactiveValue = (settingsItem && settingsItem[PageCookieSettingType.isIncludeInactive] && settingsItem[PageCookieSettingType.isIncludeInactive] !== '') ? settingsItem[PageCookieSettingType.isIncludeInactive] : this.state.isIncludeInactive;
    
    let columns = Utils.setTemplateFunctionsToColumns(this.state.columns, columnsValue);
    
    this.setState({
      isCookies: isCookiesValue,
      search: searchValue,
      sortColumn: sortColumnValue,
      sortDir: sortDirValue,
      currentPage: currentPageValue,
      rowsPerPageSelected: rowsPerPageSelectedValue,
      columnVisibility: columnVisibilityValue,
      isIncludeInactive: isIncludeInactiveValue,
      columns: columns,
    }, () => {
      if (callback) {
        callback();
      }
    });
  };

  reduxProps = nextProps => {
		Utils.reduxProps(nextProps,
      this.pageName + '-list', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        let rows = (data && data.data && data.data.length > 0) ? data.data : [];
        let totalRows = (data && data.total) ? data.total : 0;
        
        this.setState({
          rows: rows,
          totalRows: totalRows,

          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(callback){
            callback();
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      this.pageName + '-list_by_page', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        let rows = (data && data.data && data.data.length > 0) ? data.data : [];
        
        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(callback){
            callback(rows);
          }
        });
      }
    );
    
    Utils.reduxProps(nextProps,
      this.pageName + '-list_all', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        let rows = (data && data.data && data.data.length > 0) ? data.data : [];
        
        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(callback){
            callback(rows);
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      this.pageName + '-list_download_excel', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(callback){
            callback(data);
          }
        });
      }
    );
    
    Utils.reduxProps(nextProps,
      this.pageName + '-details', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
          this.setState({
            isBlockingFormDrawer: false,
            details: data,

            isLoading: isLoading,
            isError: isError,
            err: err,
          }, () => {
            if(this.state.isError){
              Utils.showDrawer(this.formDrawer, false);
            }
            
            if(callback){
              callback(data);
            }
          });
      }
    );
    
    Utils.reduxProps(nextProps,
      this.pageName + '-create', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoadingFormDrawer: false,

          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(this.state.isError){
          } else {
            Utils.showDrawer(this.formDrawer, false);
          }
          
          if(callback){
            callback(data);
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      this.pageName + '-copy', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoadingFormDrawer: false,

          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(this.state.isError){
          } else {
            Utils.showDrawer(this.formDrawer, false);
          }
          
          if(callback){
            callback(data);
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      this.pageName + '-update', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoadingFormDrawer: false,

          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(this.state.isError){
          } else {
            Utils.showDrawer(this.formDrawer, false);
          }
          
          if(callback){
            callback(data);
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      this.pageName + '-delete', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoadingFormDrawer: false,

          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(this.state.isError){
          } else {
            Utils.showDrawer(this.formDrawer, false);
          }
          
          if(callback){
            callback(data);
          }
        });
      }
    );
  }

  initDrawers = () => {
    Utils.initDrawers(() => {
      this.formDrawer = Utils.getInstanceDrawer('form');
      Utils.onShowDrawer(this.formDrawer, null, () => {
        this.setState({
          isVisibleFormDrawer: true,
        });
      });
      Utils.onHideDrawer(this.formDrawer, this.refFormDrawer, () => {
        this.setState({
          isVisibleFormDrawer: false,
        });
      });
    });
  }

  setDataTable = () => {
    return <DataTable 
      ref={this.tableRef}

      className={"table-style-v4"}
      classNameWrapper={"table-v4"}

      pageName={this.pageName}
      rowId={this.pageIdField}
      caption={this.pageCaption}
      
      noMarginBottom={true}
      verticalAlign={'middle'}
      
      stickyHeader={false}
      isHeader={true}
      resizable={true}
      isDragableHeaderColumns={true}
      isDragable={false}
      isCheckbox={false}
      isBorder={false}
      isPagination={false}
      
      columnVisibility={this.state.columnVisibility}

      isLoading={this.state.isLoading}
      rowsPerPageSelected={this.state.rowsPerPageSelected}
      rowsPerPageOptions={this.state.rowsPerPageOptions}

      search={this.state.search}
      rows={this.state.rows}
      columns={this.state.columns}

      onRowClick={(id, row) => {
        if(
          (row.payrollTemplateVariableType == PayrollTemplateVariableType.WorkTimeGeofenceBeta) || 
          (row.payrollTemplateVariableType == PayrollTemplateVariableType.WorkTimeGeofenceJobTypeBeta) || 
          (row.payrollTemplateVariableType == PayrollTemplateVariableType.WorkTimeBeta) || 
          (row.payrollTemplateVariableType == PayrollTemplateVariableType.WorkTimeJobTypeBeta)
        ){
          this.props.action.payrollBetaLayout.resetSlice();
          this.props.action.payrollBetaForm.resetSlice();

          this.props.history.push('/manage/payroll/form?id=' + id);
        } else {
          let dataParams = {
            id: id,
            row: row,
          };
          Utils.setDrawerData(this.formDrawer, dataParams);
          Utils.showDrawer(this.formDrawer);
        }
      }}

      onRemove={(row) => {

      }}

      onClickCheckAll={(obj, checked, isDisabledBatchAction) => {
        this.setState({
          isDisabledBatchAction: isDisabledBatchAction
        });
      }}
      onCheckRow={(obj, id, isDisabledBatchAction) => {
        this.setState({
          isDisabledBatchAction: isDisabledBatchAction
        });
      }}

      onSortEnd={(columns, columnVisibility) => {
        this.setState({
          columns: columns,
          columnVisibility: columnVisibility,
        }, () => {
          this.setFilterInLocalStorage();
        });
      }}
      onOrder={(name, sort) => {
        this.setState({
          sortColumn: name,
          sortDir: sort,
        }, () => {
          this.setFilterInLocalStorage();
          this.callReadApi(false);
        });
      }}
      onResize={(columns) => {
        if(columns){
          this.setState({
            columns: columns
          });
        }
        this.setFilterInLocalStorage();
      }}
    />
  }

  prepareForm = (dataParams, data) => {
    if(data){
      let templateVariables = null;
      let payrollTemplateVariables = (data && data.payrollTemplateVariables) ? data.payrollTemplateVariables : [];
      if(payrollTemplateVariables && payrollTemplateVariables.length > 0){
        templateVariables = payrollTemplateVariables[0];
      }

      let templateVariableWorkingTimes = null;
      if(templateVariables && templateVariables.payrollTemplateVariableWorkingTimes && templateVariables.payrollTemplateVariableWorkingTimes.length > 0){
        templateVariableWorkingTimes = templateVariables.payrollTemplateVariableWorkingTimes[0];
      }
      
      let accountWorkingHour = null;
      if(templateVariableWorkingTimes && templateVariableWorkingTimes.accountWorkingHour){
        accountWorkingHour = templateVariableWorkingTimes.accountWorkingHour;
      }

      let accountWorkingHourDays = [];
      if(accountWorkingHour && accountWorkingHour.accountWorkingHourDays && accountWorkingHour.accountWorkingHourDays.length > 0){
        accountWorkingHourDays = accountWorkingHour.accountWorkingHourDays.map((item, i) => {
          return {
            label: Utils.getDayOfWeekLabel(item.day),
            day: item.day,
            isEnabled: item.isEnabled,
            timeFrom: item.timeFrom,
            timeTo: item.timeTo,
          }
        });
      }
      
      let payrollTemplateVariableRanges = [];
      if(templateVariables && templateVariables.payrollTemplateVariableRanges && templateVariables.payrollTemplateVariableRanges.length > 0){
        payrollTemplateVariableRanges = templateVariables.payrollTemplateVariableRanges.map((item, i) => {
          return {
            variableDistanceName: (item.variableDistanceName) ? item.variableDistanceName : '',
            distanceFrom: item.distanceFrom,
            distanceTo: item.distanceTo,
            standardPay: item.standardPay,
            overtimePay: item.overtimePay,
          }
        });
      }

      let jobTemplateItems = [];
      let payrollTemplateVariableJobTemplates = [];
      if(templateVariables && templateVariables.payrollTemplateVariableJobTemplates && templateVariables.payrollTemplateVariableJobTemplates.length > 0){
        payrollTemplateVariableJobTemplates = templateVariables.payrollTemplateVariableJobTemplates.map((item, i) => {
          let payrollTemplateVariableJobTemplateRanges = [];
          if(item && item.payrollTemplateVariableJobTemplateRanges && item.payrollTemplateVariableJobTemplateRanges.length > 0){
            payrollTemplateVariableJobTemplateRanges = item.payrollTemplateVariableJobTemplateRanges.map((rangeItem, r) => {
              return {
                variableDistanceName: (rangeItem.variableDistanceName) ? rangeItem.variableDistanceName : '',
                standardPay: rangeItem.standardPay,
                overtimePay: rangeItem.overtimePay,
              }
            });
          }

          let jobTemplate = (item && item.jobTemplate) ?  item.jobTemplate : null;

          jobTemplateItems.push({
            jobTemplateId: (jobTemplate) ? jobTemplate.jobTemplateId : null,
            jobTemplateName: (jobTemplate) ? jobTemplate.jobTemplateName : '',
            value: (jobTemplate) ? jobTemplate.jobTemplateId : null,
            title: (jobTemplate) ? jobTemplate.jobTemplateName : '',
            item: jobTemplate,
            isExpanded: false,
            payrollTemplateVariableJobTemplateRanges: payrollTemplateVariableJobTemplateRanges,
            standardPay: item.standardPay,
            overtimePay: item.overtimePay,
          });

          return {
            jobTemplateId: (jobTemplate) ? jobTemplate.jobTemplateId : null,
            jobTemplateName: (jobTemplate) ? jobTemplate.jobTemplateName : '',
            value: (jobTemplate) ? jobTemplate.jobTemplateId : null,
            title: (jobTemplate) ? jobTemplate.jobTemplateName : '',
            item: jobTemplate,
            isExpanded: false,
            payrollTemplateVariableJobTemplateRanges: payrollTemplateVariableJobTemplateRanges,
            standardPay: item.standardPay,
            overtimePay: item.overtimePay,
          }
        });
      }


      
      let accountWorkingHourId = (accountWorkingHour && accountWorkingHour.accountWorkingHourId) ? accountWorkingHour.accountWorkingHourId : null;
      let workingHourName = (accountWorkingHour && accountWorkingHour.workingHourName) ? accountWorkingHour.workingHourName : '';
      let accountWorkingHourItems = (accountWorkingHour) ? [{
        value: accountWorkingHourId,
        title: workingHourName,
        item: accountWorkingHour,
      }] : [];

      
      let customFormParams = this.state.rowInitialValues.customFormParams;
      customFormParams.accountWorkingHourItems = accountWorkingHourItems;
      customFormParams.jobTemplateItems = jobTemplateItems;


      let rowValues = {
        isEdit: true,
        isActive: ((data.isActive === false) || (data.isActive === true)) ? data.isActive : false,

        templateName: (data && data.templateName && data.templateName !== '') ? data.templateName : '',
        templateDescription: (data && data.templateDescription && data.templateDescription !== '') ? data.templateDescription : '',
        basicPay: (data) ? data.basicPay : '',
        payrollTemplateVariableTypeId: (data && data.payrollTemplateVariableType === null) ? PayrollTemplateVariableType.None : data.payrollTemplateVariableType,
        payrollTemplateVariableTypeName: Utils.getPayrollTemplateVariableTypeNameByID(data.payrollTemplateVariableType),
        
        standardPay: (templateVariables && templateVariables.standardPay && templateVariables.standardPay !== '') ? templateVariables.standardPay : '',
        overtimePay: (templateVariables && templateVariables.overtimePay && templateVariables.overtimePay !== '') ? templateVariables.overtimePay : '',
        
        workTimeName: (accountWorkingHour && accountWorkingHour.workingHourName && accountWorkingHour.workingHourName !== '') ? accountWorkingHour.workingHourName : '',
        workTimeDescription: (accountWorkingHour && accountWorkingHour.workingHourDescription && accountWorkingHour.workingHourDescription !== '') ? accountWorkingHour.workingHourDescription : '',
        
        accountWorkingHourDays: accountWorkingHourDays,
        payrollTemplateVariableRanges: payrollTemplateVariableRanges,
        payrollTemplateVariableJobTemplates: payrollTemplateVariableJobTemplates,

        accountWorkingHourIsNew: false,
        accountWorkingHourId: accountWorkingHourId,
        accountWorkingHourName: workingHourName,

        customFormParams: customFormParams,
      };
  
      return rowValues;
    } else {
      return this.state.rowinitialValues;
    }
  }
  prepareData = (form, dataParams) => {
    let data = {
      [this.pageIdField]: dataParams.id,
      isActive: true,

      templateName: form.templateName,
      templateDescription: form.templateDescription,
      basicPay: (form.basicPay) ? form.basicPay : 0,
      payrollTemplateVariableType: (form.payrollTemplateVariableTypeId === PayrollTemplateVariableType.None) ? null : form.payrollTemplateVariableTypeId,
    };

    if(form.payrollTemplateVariableTypeId !== PayrollTemplateVariableType.None){
      let template = {};
      data['isVariablePay'] = true;

      // Work times
      let workingTime = {
        workingHourName: form.workTimeName,
        workingHourDescription: form.workTimeDescription,
        accountWorkingHourDays: form.accountWorkingHourDays
      };
      template['payrollTemplateVariableWorkingTimes'] = [{
        accountWorkingHourId: form.accountWorkingHourId,
        accountWorkingHour: workingTime,
      }];


      if(form.payrollTemplateVariableTypeId === PayrollTemplateVariableType.WorkTime){
        template['standardPay'] = form.standardPay;
        template['overtimePay'] = form.overtimePay;

      } else if(form.payrollTemplateVariableTypeId === PayrollTemplateVariableType.WorkTimeDistance){
        template['payrollTemplateVariableRanges'] = form.payrollTemplateVariableRanges;
        
      } else if(form.payrollTemplateVariableTypeId === PayrollTemplateVariableType.WorkTimeJobType){
        let payrollTemplateVariableJobTemplates = [];
        if(form && form.payrollTemplateVariableJobTemplates && form.payrollTemplateVariableJobTemplates.length > 0){
          payrollTemplateVariableJobTemplates = form.payrollTemplateVariableJobTemplates.map((item, i) => {
            return {
              jobTemplateId: item.jobTemplateId,
              standardPay: item.standardPay,
              overtimePay: item.overtimePay,
            }
          });
        }
        template['payrollTemplateVariableJobTemplates'] = payrollTemplateVariableJobTemplates;
    
      } else if(form.payrollTemplateVariableTypeId === PayrollTemplateVariableType.WorkTimeDistanceJobType){
        let payrollTemplateVariableRanges = [];
        if(form && form.payrollTemplateVariableRanges && form.payrollTemplateVariableRanges.length > 0){
          payrollTemplateVariableRanges = form.payrollTemplateVariableRanges.map((item, i) => {
            return {
              variableDistanceName: item.variableDistanceName,
              distanceFrom: item.distanceFrom,
              distanceTo: item.distanceTo,
            }
          });
        }
        template['payrollTemplateVariableRanges'] = payrollTemplateVariableRanges;
    
        let payrollTemplateVariableJobTemplates = [];
        if(form && form.payrollTemplateVariableJobTemplates && form.payrollTemplateVariableJobTemplates.length > 0){
          payrollTemplateVariableJobTemplates = form.payrollTemplateVariableJobTemplates.map((item, i) => {
            return {
              jobTemplateId: item.jobTemplateId,
              payrollTemplateVariableJobTemplateRanges: item.payrollTemplateVariableJobTemplateRanges
            }
          });
        }
        template['payrollTemplateVariableJobTemplates'] = payrollTemplateVariableJobTemplates;
      }

      data['payrollTemplateVariables'] = [template];
    } else {
      data['isVariablePay'] = false;
    }

    return data;
  }
	/* END FUNCTIONS */

	
	/* API */
  callReadApi = (withStatusCall = true, callback = null) => {
    this.setState({
      isLoading: true,
    });

    let data = {
      currentPage: this.state.currentPage,
      pageSize: this.state.rowsPerPageSelected,
      searchQuery: this.state.search,
      sortColumn: this.state.sortColumn,
      sortDir: this.state.sortDir,
      isIncludeInactive: ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : false,
    };
 
    if (this.state.searchTags && this.state.searchTags.length > 0) {
      data["searchTags"] = Utils.joinItems(this.state.searchTags);
    }
    if (this.state.searchOperator && this.state.searchOperator.length > 0) {
      data["searchOperator"] = Utils.getSelectedOperator(this.state.searchOperator);
    }
    if (this.state.searchColumns && this.state.searchColumns.length > 0) {
      data["searchColumns"] = Utils.getSelectedColumns(this.state.searchColumns);
    }

		this.props.dispatchApiCallGet(data, this.pageName + '-list', this.pageAPI, null, callback, () => {});

    if(withStatusCall){
      
    }
	}

  callReadByPageApi = (callback = null) => {
    let data = {
      currentPage: this.state.currentPage,
      pageSize: this.state.rowsPerPageSelected,
      searchQuery: this.state.search,
      sortColumn: this.state.sortColumn,
      sortDir: this.state.sortDir,
      isIncludeInactive: ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : false,
    };
 
    if (this.state.searchTags && this.state.searchTags.length > 0) {
      data["searchTags"] = Utils.joinItems(this.state.searchTags);
    }
    if (this.state.searchOperator && this.state.searchOperator.length > 0) {
      data["searchOperator"] = Utils.getSelectedOperator(this.state.searchOperator);
    }
    if (this.state.searchColumns && this.state.searchColumns.length > 0) {
      data["searchColumns"] = Utils.getSelectedColumns(this.state.searchColumns);
    }

		this.props.dispatchApiCallGet(data, this.pageName + '-list_by_page', this.pageAPI, null, callback, null);
  }

  callReadAllApi = (callback = null) => {
    let data = {
      currentPage: 1,
      pageSize: Utils.getMaxPageSize(),
      searchQuery: this.state.search,
      sortColumn: this.state.sortColumn,
      sortDir: this.state.sortDir,
      isIncludeInactive: ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : false,
    };

		this.props.dispatchApiCallGet(data, this.pageName + '-list_all', this.pageAPI, null, callback, null);
  }

  callDownloadExcelAllApi = (callback = null) => {
		Utils.toast(<div style={{ fontWeight: 'bold' }}>Please Wait ...</div>, 'info');
    
    let data = {
      currentPage: 1,
      pageSize: Utils.getMaxPageSize(),
      searchQuery: this.state.search,
      sortColumn: this.state.sortColumn,
      sortDir: this.state.sortDir,
      isIncludeInactive: ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : false,
    };

		this.props.dispatchApiCallGet(data, this.pageName + '-list_download_excel', this.pageAPI + '/download-excel', null, callback, null);
  }

  callDetailsApi = (id = null, callback = null) => {
    if(id){
      this.setState({
        isBlockingFormDrawer: true,
        details: null,
      });
  
      this.props.dispatchApiCallGet(null, this.pageName + '-details', this.pageAPI + '/' + id, null, callback, () => {});
    } else {
      if(callback){
        callback(null)
      }
    }
  }

  callCreateApi = (data, callback = null) => {
    this.setState({
      isLoadingFormDrawer: true,
    });

		this.props.dispatchApiCallPost(data, this.pageName + '-create', this.pageAPI, data, callback, null);
  }

  callCopyApi = (data, callback = null) => {
    this.setState({
      isLoadingFormDrawer: true,
    });

    data[this.pageNameField] = data[this.pageNameField] + ' - Copy';
    data['isCopy'] = true;

    delete data[this.pageIdField];

		this.props.dispatchApiCallPost(data, this.pageName + '-copy', this.pageAPI, data, callback, null);
  }

  callUpdateApi = (data, callback = null) => {
    this.setState({
      isLoadingFormDrawer: true,
    });

		this.props.dispatchApiCallPut(data, this.pageName + '-update', this.pageAPI, data, callback, null);
  }

  callDeleteApi = (data, callback = null) => {
    this.setState({
      isLoadingFormDrawer: true,
    });

		this.props.dispatchApiCallDelete(data, this.pageName + '-delete', this.pageAPI, data, callback, null);
  }
	/* END API */
	
	
	/* SECTIONS */
	actionSection = () => {
		return <Row className={'align-items-center'}>

      {(Utils.hasPermission(this.permissions, PagesPermissions.ManagePayroll.read)) && <Col xs={'auto'} className={'mb-2 mb-sm-0'}>
        <Button
          variant={'success'}
          className={'pt-1 pb-1 rounded-1 '}
          onClick={(e) => {
          this.props.history.push('/manage/payroll/payroll');
          }}
        >PAYROLL</Button>
      </Col>}
      
      {(Utils.hasPermission(this.permissions, PagesPermissions.ManageWorkHours.read)) && <Col xs={'auto'} className={'mb-2 mb-sm-0'}>
        <Button
          variant={'light'}
          className={'pt-1 pb-1 rounded-1 bg-white'}
          onClick={(e) => {
            this.props.history.push('/manage/payroll/hours');
          }}
        >WORK TIME</Button>
      </Col>}

      <Col xs={12} md={true} className={'order-1 order-md-3 pb-10 pb-md-0'}>
        <CurrentDateTime />
      </Col>
    </Row>
	}

	toolbarSection = () => {
		return <Row className={'d-block d-md-flex align-items-center'}>
      <Col xs={'auto'} md={true}>
        {/* <DropdownIconButtonMenu 
          ref={this.refDropdownAdvancedFilter}
          className={'job-list-icon-button-dropdown'}
          overflowVisible={true}
          selected={null}
          icon={<FilterIcon className={((this.state.searchTags && this.state.searchTags.length > 0) ? 'svg-fill-active' : '')}/>}
        >
          <div>
            <AdvancedFilter
              pageName={this.pageName}
              columns={this.state.columns}
              columnVisibility={this.state.columnVisibility}
              searchTags={this.state.searchTags}
              searchOperator={this.state.searchOperator}
              searchColumns={this.state.searchColumns}
              onSearch={(
                searchTags,
                searchOperator,
                searchColumns
              ) => {
                if(this.refDropdownAdvancedFilter && this.refDropdownAdvancedFilter.current){
                  this.refDropdownAdvancedFilter.current.hide();
                }

                this.setState(
                  {
                    searchTags: searchTags,
                    searchOperator: searchOperator,
                    searchColumns: searchColumns,
                    currentPage: 1,
                  },
                  () => {
                    this.setFilterInLocalStorage();
                    this.callReadApi();
                  }
                );
              }}
                    onCancel={() => {
                      if(this.refDropdownAdvancedFilter && this.refDropdownAdvancedFilter.current){
                        this.refDropdownAdvancedFilter.current.hide();
                      }
                    }}
            />
          </div>
        </DropdownIconButtonMenu>
        {this.state.searchTags && this.state.searchTags.length > 0 && (
          <a
            className={"link-danger"}
            href={"/"}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              this.setState(
                {
                  searchTags: [],
                  searchOperator: [],
                  searchColumns: [],
                },
                () => {
                  this.setFilterInLocalStorage();
                  this.callReadApi();
                }
              );
            }}
          >
            Clear Filters
          </a>
        )} */}
      </Col>
      <Col xs={'auto'} md={true} className={'text-center text-md-end'}>
        <div className='d-inline-flex align-items-center text-start'>
        <ExpandingSearchBox 
          btnClassName={'manage-icon-btn'}
          value={this.state.search}
          isOpen={(this.state.search !== '') ? true : false}
          onPressEnter={(value) => {
            this.setState({
              search: value,
              currentPage: 1,
            }, () => {
              this.setFilterInLocalStorage();
              this.callReadApi(true);
            });
          }}
          onClear={() => {
            this.setState({
              search: '',
              currentPage: 1,
            }, () => {
              this.setFilterInLocalStorage();
              this.callReadApi(true);
            });
          }}
        />
        </div>
                {Utils.isMobileTablet() && <div className='col-12 d-block d-md-none'></div>}
        <div className='d-inline-flex align-items-center text-start'>
        {/* <DropdownIconButtonMenu 
          className={'job-list-icon-button-dropdown'}
          selected={null}
          items={[
            {
              value: 1,
              text: 'Print this page',
            },
            {
              value: 2,
              text: 'Print all pages',
            }
          ]} 
          icon={<PrintIcon />} 
          onClick={(item) => {
            if(item.value === 1){
              this.callReadByPageApi((data) => {
                this.tableRef.current.print(data);
              });

            } else if(item.value === 2){
              this.callReadAllApi((data) => {
                this.tableRef.current.print(data);
              });
            }
          }}
        /> */}

        {/* <DropdownIconButtonMenu 
          className={'job-list-icon-button-dropdown'}
          selected={null}
          items={[
            {
              value: 1,
              text: 'Download all in PDF format',
            },
            {
              value: 2,
              text: 'Download all in Excel format',
            }
          ]} 
          icon={<DownloadIcon />} 
          onClick={(item) => {
              if(item.value === 1){
                this.callReadAllApi((data) => {
                  this.tableRef.current.exportPDF(data);
                });

              } else if(item.value === 2){
                this.callDownloadExcelAllApi((data) => {
                  let type = 'application/vnd.ms-excel';
                  Utils.saveByteArray(this.pageCaption, data, type);
                });
              }
          }}
        /> */}

        <DropdownIconButtonMenu 
          ref={this.refDropdownActionMenu}
          className={'job-list-icon-button-dropdown'}
          selected={null}
          icon={<SettingsIcon />}
        >
          <DataTableActionMenu 
            pageName={this.pageName}
            columns={this.state.columns}
            selected={this.state.rowsPerPageSelected}
            selectItems={this.state.rowsPerPageOptions}
            columnVisibility={this.state.columnVisibility}
            onChangeColumnVisibility={(index, value) => {
              let colVis = this.state.columnVisibility;
              colVis[index] = value;

              this.setState({
                columnVisibility: colVis
              }, () => {
                this.setFilterInLocalStorage();
              });
            }}
            canInactive={false}
            onSelectChangeAjax={(val) => {
              this.setState({
                currentPage: 1,
                rowsPerPageSelected: val
              }, () => {
                this.setFilterInLocalStorage();
                this.callReadApi();
              });
            }}
            onChangeIncludeInactive={(val) => {
              this.setState({
                isIncludeInactive: val,
                currentPage: 1,
              }, () => {
                this.setFilterInLocalStorage();
                this.callReadApi();
              });
            }}
            isCookies={this.state.isCookies}
            onResetColumns={() => {
              this.setState({
                columns: Array.from(this.state.initColumns),
                columnVisibility: Array.from(this.state.initColumnVisibility),
              }, () => {
                this.setFilterInLocalStorage(false);
                if(this.refDropdownActionMenu && this.refDropdownActionMenu.current){
                  this.refDropdownActionMenu.current.hide();
                }
              });
            }}
            onShowMore={(isShowedMore) => {
              this.setState({
                pageName: this.pageName,
              });
            }}
          />
        </DropdownIconButtonMenu>
        </div>
      </Col>
    </Row>
	}

	tableSection = () => {
		return <DataTableScrollSync ref={this.refDataTableScrollSync} className={"table-sync-v4"}>{this.setDataTable()}</DataTableScrollSync>
	}

	paginationSection = () => {
		return <DataTableAjaxPagination
      page={this.state.currentPage}
      selected={this.state.rowsPerPageSelected}
      count={this.state.totalRows}
      onPageChange={(page) => {
        this.setState({
          currentPage: page,
        }, () => {
          this.setFilterInLocalStorage();
          this.callReadApi(false);
        });
      }}
    />
	}

  formDrawerSection = () => {
		return <DrawerLayout
      name={'form'}
      size={'700px'}
      ref={this.refFormDrawer}
      drawer={this.formDrawer}
      validationSchema={formSchema}
      initialValues={this.state.rowInitialValues}
      values={this.state.rowValues}
      isVisible={this.state.isVisibleFormDrawer}
      isBlocking={Utils.isBlockingDrawer(this.state.isLoadingFormDrawer, this.state.isBlockingFormDrawer)}
      renderChildren={Utils.shouldDrawerRenderChildren(this.state.isLoadingFormDrawer, this.state.isBlockingFormDrawer)}

      title={(data) => {
        if(data){
          return <div className={'d-flex align-items-center'}>
            <div className={'d-block'}>
              <div className={'pe-2'}>
                <span className={'pe-2'}>Set Payroll Template</span>
                {(data.id > 0) && <IconButton
                  icon={'history'}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    this.setState({
                      isOpenAuditTrail: true,
                      objectId: data.id,
                    });
                  }}
                />}
              </div>
              <small>Configure the payroll type for drivers.</small>
            </div>
          </div>;
        }
      }}
      toolbar={null}
      
      onOpenClose={(isOpen, dataParams) => {
        if(isOpen){
          this.callDetailsApi(dataParams.id, (data) => {
            let rowValues = this.prepareForm(dataParams, data);
  
            this.setState({
              rowValues: rowValues,
            });
          });
        }
      }}
      onSubmit={(form, dataParams) => {
        let data = this.prepareData(form, dataParams);
        if(form.isEdit){
          this.callUpdateApi(data, (res) => {
            this.callReadApi();
          });
        } else {
          this.callCreateApi(data, (res) => {
            this.callReadApi();
          });
        }
      }}
    >
      {(formOptions, dataParams, isBlocking) => {
        return <PayrollForm
          drawer={this.formDrawer}
          fields={formFields}
          initialValues={this.state.rowInitialValues}
          values={this.state.rowValues}
          formOptions={formOptions}
          dataParams={dataParams}
          isBlocking={isBlocking}
          onDelete={(values) => {
            this.setState({
              isRemoveDialog: true,
              removeRow: values,
            });
          }}
          onCopy={(values) => {
            let data = this.prepareData(values, dataParams);
            this.callCopyApi(data, (res) => {
              this.callReadApi();
            });
          }}
        />
      }}
    </DrawerLayout>
	}
	/* END SECTIONS */

  
  /* DIALOGS */
  setRemoveDialog = () => {
    return <RemoveDialog 
      show={this.state.isRemoveDialog}
      isLoading={this.state.isLoadingFormDrawer}
      onCancel={() => {
        this.setState({
          isRemoveDialog: false,
          removeRow: null,
        });
      }}
      onRemove={() => {
        let values = this.state.removeRow;
        let data = this.prepareData(values, Utils.getDrawerData(this.formDrawer));

        this.callDeleteApi(data, (res) => {
          this.setState({
            isRemoveDialog: false,
            removeRow: null,
          });

          this.callReadApi();
        });
      }}
    />
  }
  
  setRemoveDialogTable = () => {
    return <RemoveDialog 
      show={this.state.isRemoveDialogTable}
      isLoading={this.state.isLoadingFormDrawer}
      onCancel={() => {
        this.setState({
          isRemoveDialogTable: false,
          removeRowTable: null,
        });
      }}
      onRemove={() => {
        let values = this.state.removeRowTable;
        
        this.callDeleteApi({ [this.pageIdField]: values }, (res) => {
          this.setState({
            isRemoveDialogTable: false,
            removeRowTable: null,
          });

          this.callReadApi();
        });
      }}
    />
  }

	setAuditTrailDialog = () => {
    return <AuditTrailDialog 
      isOpen={this.state.isOpenAuditTrail}
      objectId={this.state.objectId}
      auditTrailObjectType={AuditTrailObjectType.Payroll}
      title={"Payroll Audit Trail"}
      onClose={() => {
        this.setState({
          isOpenAuditTrail: false,
          objectId: null,
        });
      }}
      details={(obj, index, currentVersion, previousVersion) => {
        return AuditTrailFunctions.getDetails(AuditTrailObjectType.Payroll, obj, index, currentVersion, previousVersion);
      }}
      manageData={(currentVersion, previousVersion, callback) => {
        return AuditTrailFunctions.getManageData(AuditTrailObjectType.Payroll, currentVersion, previousVersion, callback);
      }}
    />
  }
  /* END DIALOGS */


  render() {
    return (
      <>
        <ViewLayout
          isBlocking={this.state.batchActionLoading}
          title={'PAYROLL'}
          col={<Col xs={true} className={'text-end mb-md-0'}>
            <Row>
              <Col xs={true}></Col>
              <Col xs={12} sm={'auto'} className={'mb-4 mb-sm-0'}>
                <StyledFormWizard href={'/manage/payroll/form'}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    this.props.action.payrollBetaLayout.resetSlice();
                    this.props.action.payrollBetaForm.resetSlice();

                    this.props.history.push('/manage/payroll/form');
                  }}
                >
                  <i className={'fa fa-magic'}></i>
                  <span className={'text'}>Form Wizard</span>
                  <StyledBadge pill bg="primary">Beta</StyledBadge>
                </StyledFormWizard>
              </Col>
              <Col xs={12} sm={'auto'}>
                <Button
                  variant="primary"
                  onClick={() => {
                    let dataParams = {
                      id: 0,
                      row: null,
                    };
                    Utils.setDrawerData(this.formDrawer, dataParams);
                    Utils.showDrawer(this.formDrawer);
                  }}
                >NEW</Button>
              </Col>
            </Row>
          </Col>}
          actionSection={this.actionSection()}
          toolbarSection={this.toolbarSection()}
          tableSection={this.tableSection()}
          paginationSection={this.paginationSection()}
        />

        {this.formDrawerSection()}
        {this.setRemoveDialog()}
        {this.setRemoveDialogTable()}
        {this.setAuditTrailDialog()}
      </>
    );
  }
}


const mapStateToProps = state => {
    return {
      ...state,
      ...Utils.mapStateToProps(state),
    };
};
const mapDispatchToProps = (dispatch) => ({
  dispatchApiCallGet: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallGet(p1, p2, p3, p4, p5, p6)),
  dispatchApiCallPost: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallPost(p1, p2, p3, p4, p5, p6)),
  dispatchApiCallPut: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallPut(p1, p2, p3, p4, p5, p6)),
  dispatchApiCallDelete: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallDelete(p1, p2, p3, p4, p5, p6)),
  saveUserCookieSettings: (payload) => dispatch(saveUserCookieSettings(payload)),
  action: {
    payrollBetaLayout: {
      resetSlice: (payload) => dispatch(payrollBetaLayout.resetSlice(payload)),
    },
    payrollBetaForm: {
      resetSlice: (payload) => dispatch(payrollBetaForm.resetSlice(payload)),
    },
  }
});
  
  
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PayrollView);
