import React from 'react';

import { 
    Modal,
    Button,
    Spinner,
    CloseButton,
    Row,
    Col,
} from "react-bootstrap-v5";

  
const RemoveDialog = props => {
    const [isLoading, setIsLoading] = React.useState(((props.isLoading === false) || (props.isLoading === true)) ? props.isLoading : false);
    const [show, setShow] = React.useState(((props.show === false) || (props.show === true)) ? props.show : false);
    

    React.useEffect(() => {
        setIsLoading(((props.isLoading === false) || (props.isLoading === true)) ? props.isLoading : false);
    }, [props.isLoading]);

    React.useEffect(() => {
        setShow(((props.show === false) || (props.show === true)) ? props.show : false);
    }, [props.show]);

    
    return <Modal
        className={props.className}
        show={show}
        onHide={() => {
            if(props.onCancel){
                props.onCancel();
            }
        }}
        centered
        backdrop="static"
        size="md"
        keyboard={false}
    >
        <Modal.Header>
            <Row className={'align-items-center gx-0 w-100'}>
                <Col xs={true}>
                    <Modal.Title>{props.title ? props.title : 'Remove'}</Modal.Title>
                </Col>
                <Col xs={'auto'}>
                    <CloseButton
                        className={'rounded-2'}
                        onClick={() => {
                            if(props.onCancel){
                                props.onCancel();
                            }
                        }}
                    />
                </Col>
            </Row>
        </Modal.Header>
        <Modal.Body>{props.body ? props.body : 'Are you sure you want to remove?'}</Modal.Body>
        <Modal.Footer>
            <Button 
                type={'button'}
                variant={props.cancelVariant ? props.cancelVariant : 'secondary'}
                disabled={isLoading}
                onClick={() => {
                    if(props.onCancel){
                        props.onCancel();
                    }
                }}
            >
                {props.cancelText ? props.cancelText : 'Cancel'}
                {isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
            </Button>
            <Button 
                type={'button'}
                variant={props.removeVariant ? props.removeVariant : 'danger'}
                disabled={isLoading}
                onClick={() => {
                    if(props.onRemove){
                        props.onRemove();
                    }
                }}
            >
                {props.removeText ? props.removeText : 'Yes, Please remove'}
                {isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
            </Button>
        </Modal.Footer>
    </Modal>
}


export default RemoveDialog;
