import React from "react";

import styled from 'styled-components';

import {
    ReportTable,
    ReportThead,
    ReportTbody,
    ReportTr,
    ReportTh,
    ReportTd,
} from "../../../components/OLD/Pages/Reports/CustomTable.jsx";

import Utils from "../../../utils/utils";
import { CustomerSiteAvailability } from "../../../utils/enums";

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut, dispatchApiCallDelete } from '../../../../setup/redux/dispatch/actions'



const ReportTableStyle = styled(ReportTable)`
    width: 100%;

    thead {
        tr {
            border-bottom: 1px solid #DFDFDF;

            th {
                color: #7A7A7A;
                padding-bottom: 5px;
            }
        }
    }

    tbody {
        tr {
            td {
                color: #7A7A7A;
                padding-top: 3px;
                padding-bottom: 3px;
            }

            &:hover {
                td {
                    background-color: #EFF6E4;
                }
            }
        }
    }
`;


class CustomerSiteHistoryForm extends React.Component {
  
    constructor(props) {
        super(props);

        let id = Utils.getIntProps(props);
        let isCopy = Utils.getBoolProps(props);
        let objectId = Utils.getIntProps(props, 'objectId');
        let objectType = Utils.getIntProps(props, 'objectType');

        this.pageName = 'customer_site_history_form';
        this.backLink = '/Customer-overview-local-services?id=' + objectId;
        
        this.state = {
            id: id,
            isCopy: isCopy,
            objectId: objectId,
            objectType: objectType,

            currentVersion: null,
            previousVersion: null,
        };
    }


    componentDidMount(){
        this.setLayoutForm();
        this.setLayoutTitle('View Changes');

        this.callDetailsApi((currentVersion, previousVersion) => {
            this.setState({
                currentVersion: currentVersion,
                previousVersion: previousVersion,
            }, () => {
                this.setLoading(false);
            });
        });
    }

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            this.pageName + '-details', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let currentVersion = (data && data.currentVersion) ? data.currentVersion : null;
                let previousVersion = (data && data.previousVersion) ? data.previousVersion : null;
                
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(currentVersion, previousVersion);
                    }
                });
            }
        );
    }
	
    callDetailsApi = (callback = null) => {
        if(this.state.id){
            this.setLoading(true);
            this.props.dispatchApiCallGet(null, this.pageName + '-details', 'audittrail/compare-audit-trail/' + this.state.objectType + '/' + this.state.objectId + '/' + this.state.id, null, callback, () => {});
        } else {
            if(callback){
                callback(null, null)
            }
        }
    }
    /* END API */


    /* LAYOUT FUNCTIONS */
    setLayoutForm = (options) => {
        if(this.props.layoutRef && this.props.layoutRef.current){
            this.props.layoutRef.current.setLayoutForm(this, options);
        }
    }
    setLayoutLoading = (state = false) => {
        if(this.props.layoutRef && this.props.layoutRef.current){
            this.props.layoutRef.current.setLayoutLoading(state);
        }
    }
    setLayoutTitle = (title = '') => {
        if(this.props.layoutRef && this.props.layoutRef.current){
            this.props.layoutRef.current.setlayoutTitle(title);
        }
    }
    setLayoutSubmit = (form) => {
        if(this.props.layoutRef && this.props.layoutRef.current){
            this.props.layoutRef.current.setLayoutSubmit(form);
        }
    }
    /* END LAYOUT FUNCTIONS */


    /* FUNCTIONS */
    setLoading = (state = false) => {
        this.setLayoutLoading(state);

        if(this.props.onLoading){
            this.props.onLoading(state);
        }
    }

    setRow = (name = null, prevVal = null, currVal = null, isDiff = false) => {
        return <ReportTr>
            <ReportTd>{name ? name : '-'}</ReportTd>
            <ReportTd>{prevVal ? prevVal : '-'}</ReportTd>
            <ReportTd style={{ color: (isDiff ? '#185CFF' : '') }}>{currVal ? currVal : '-'}</ReportTd>
        </ReportTr>
    }
    /* END FUNCTIONS */


    /* FORM */
    printTableHeader = () => {
        return <ReportThead>
            <ReportTr>
                <ReportTh style={{ width: '33%' }}>Fields</ReportTh>
                <ReportTh style={{ width: '33%' }}>Previous</ReportTh>
                <ReportTh style={{ width: '33%' }}>Current</ReportTh>
            </ReportTr>
        </ReportThead>
    }
    printTableBody = () => {
        return <ReportTbody>
            {Utils.getAuditTrailValue(this.state.previousVersion, this.state.currentVersion, this.setRow, 'Site Name', 'siteName')}
            {Utils.getAuditTrailValue(this.state.previousVersion, this.state.currentVersion, this.setRow, 'Street Name', 'street')}
            {Utils.getAuditTrailValue(this.state.previousVersion, this.state.currentVersion, this.setRow, 'Block Number', 'blockNo')}
            {Utils.getAuditTrailValue(this.state.previousVersion, this.state.currentVersion, this.setRow, 'Unit Number', 'unitNo')}
            {Utils.getAuditTrailValue(this.state.previousVersion, this.state.currentVersion, this.setRow, 'Post Code', 'postalCode')}
            {Utils.getAuditTrailValue(
                this.state.previousVersion, this.state.currentVersion, this.setRow, '', null,
                () => {
                    let prevLatitude = Utils.getPreviousValue(this.state.previousVersion, 'latitude');
                    let prevLongitude = Utils.getPreviousValue(this.state.previousVersion, 'longitude');

                    let currLatitude = Utils.getCurrentValue(this.state.currentVersion, 'latitude');
                    let currLongitude = Utils.getCurrentValue(this.state.currentVersion, 'longitude');

                    let prevJoinValue = Utils.joinCoordinates(prevLatitude, prevLongitude);
                    let currJoinValue = Utils.joinCoordinates(currLatitude, currLongitude);

                    let prevValue = Utils.validateCoordinates(prevJoinValue) ? prevJoinValue : '-';
                    let currValue = Utils.validateCoordinates(currJoinValue) ? currJoinValue : '-';
                    let isDifferentValue = Utils.isDifferentValue(prevValue, currValue);

                    return this.setRow('Coordinates', prevValue, currValue, isDifferentValue);
                }
            )}
            {Utils.getAuditTrailValue(this.state.previousVersion, this.state.currentVersion, this.setRow, 'Site Remarks', 'remarks')}
            {Utils.getAuditTrailValue(this.state.previousVersion, this.state.currentVersion, this.setRow, 'Driver Remarks', 'instructions')}
            {Utils.getAuditTrailValue(this.state.previousVersion, this.state.currentVersion, this.setRow, 'Contact 1', 'contactPersonName')}
            {Utils.getAuditTrailValue(this.state.previousVersion, this.state.currentVersion, this.setRow, 'Number 1', 'contactPersonPhoneNumber')}
            {/* {Utils.getAuditTrailValue(this.state.previousVersion, this.state.currentVersion, this.setRow, 'Notification 1', 'isContactPersonSendNotification', () => {
                let title = 'Notification 1';
                let group = 'isContactPersonSendNotification';

                let prevItem = Utils.getCurrentPreviousBoolValue(this.state.previousVersion, group);
                let currItem = Utils.getCurrentPreviousBoolValue(this.state.currentVersion, group);

                let prevValue = (prevItem) ? prevItem : '-';
                let currValue = (currItem) ? currItem : '-';
                let isDifferentValue = Utils.isDifferentValue(prevValue, currValue);
                
                return this.setRow(title, prevValue, currValue, isDifferentValue);
            })} */}

            {Utils.getAuditTrailValue(this.state.previousVersion, this.state.currentVersion, this.setRow, 'Contact 2', 'contactPersonNameTwo')}
            {Utils.getAuditTrailValue(this.state.previousVersion, this.state.currentVersion, this.setRow, 'Number 2', 'contactPersonPhoneNumberTwo')}
            {/* {Utils.getAuditTrailValue(this.state.previousVersion, this.state.currentVersion, this.setRow, 'Notification 2', 'isContactPersonTwoSendNotification', () => {
                let title = 'Notification 2';
                let group = 'isContactPersonTwoSendNotification';

                let prevItem = Utils.getCurrentPreviousBoolValue(this.state.previousVersion, group);
                let currItem = Utils.getCurrentPreviousBoolValue(this.state.currentVersion, group);

                let prevValue = (prevItem) ? prevItem : '-';
                let currValue = (currItem) ? currItem : '-';
                let isDifferentValue = Utils.isDifferentValue(prevValue, currValue);
                
                return this.setRow(title, prevValue, currValue, isDifferentValue);
            })} */}

            {Utils.getAuditTrailValue(
                this.state.previousVersion, this.state.currentVersion, this.setRow, '', null,
                () => {
                    let prevZone = Utils.getPreviousValue(this.state.previousVersion, 'zone');
                    let currZone = Utils.getCurrentValue(this.state.currentVersion, 'zone');

                    let prevValue = (prevZone && prevZone.zoneName) ? prevZone.zoneName : '-';
                    let currValue = (currZone && currZone.zoneName) ? currZone.zoneName : '-';
                    let isDifferentValue = Utils.isDifferentValue(prevValue, currValue);
                    
                    return this.setRow('Group As', prevValue, currValue, isDifferentValue);
                }
            )}
            {/* {Utils.getAuditTrailValue(
                this.state.previousVersion, this.state.currentVersion, this.setRow, '', null,
                () => {
                    return Utils.getAuditTrailDiffArray(this.state.previousVersion, this.state.currentVersion, this.setRow, 'Default Vehicle(s)', 'defaultVehicles', 'vehicleId', 'vehicleName');
                }
            )} */}
            {Utils.getAuditTrailValue(
                this.state.previousVersion, this.state.currentVersion, this.setRow, '', null,
                () => {
                    return Utils.getAuditTrailDiffArray(this.state.previousVersion, this.state.currentVersion, this.setRow, 'Default Driver(s)', 'defaultDrivers', 'driverId', 'driverName');
                }
            )}
            {Utils.getAuditTrailValue(this.state.previousVersion, this.state.currentVersion, this.setRow, '', null,
            () => {
                let prevData = Utils.getPreviousValue(this.state.previousVersion, 'weekdaysAvailability');
                let currData = Utils.getCurrentValue(this.state.currentVersion, 'weekdaysAvailability');

                let prevDataValue = '';
                if(prevData === CustomerSiteAvailability.NotAvailable){
                    prevDataValue = 'Not Available';
                } else if(prevData === CustomerSiteAvailability.SelectedHours){
                    prevDataValue = 'Selected Hours';
                } else {
                    prevDataValue = 'All';
                }

                let currDataValue = '';
                if(currData === CustomerSiteAvailability.NotAvailable){
                    currDataValue = 'Not Available';
                } else if(currData === CustomerSiteAvailability.SelectedHours){
                    currDataValue = 'Selected Hours';
                } else {
                    currDataValue = 'All';
                }

                let prevValue = prevDataValue ? prevDataValue : '-';
                let currValue = currDataValue ? currDataValue : '-';
                let isDifferentValue = Utils.isDifferentValue(prevValue, currValue);

                return this.setRow('Weekdays Availability', prevValue, currValue, isDifferentValue);
            })}
            {Utils.getAuditTrailValue(this.state.previousVersion, this.state.currentVersion, this.setRow, '', null,
            () => {
                let prevData = Utils.getPreviousValue(this.state.previousVersion, 'weekendAvailability');
                let currData = Utils.getCurrentValue(this.state.currentVersion, 'weekendAvailability');

                let prevDataValue = '';
                if(prevData === CustomerSiteAvailability.NotAvailable){
                    prevDataValue = 'Not Available';
                } else if(prevData === CustomerSiteAvailability.SelectedHours){
                    prevDataValue = 'Selected Hours';
                } else {
                    prevDataValue = 'All';
                }

                let currDataValue = '';
                if(currData === CustomerSiteAvailability.NotAvailable){
                    currDataValue = 'Not Available';
                } else if(currData === CustomerSiteAvailability.SelectedHours){
                    currDataValue = 'Selected Hours';
                } else {
                    currDataValue = 'All';
                }

                let prevValue = prevDataValue ? prevDataValue : '-';
                let currValue = currDataValue ? currDataValue : '-';
                let isDifferentValue = Utils.isDifferentValue(prevValue, currValue);

                return this.setRow('Weekends Availability', prevValue, currValue, isDifferentValue);
            })}
        </ReportTbody>;
    }
    printTable = () => {
        return <ReportTableStyle className={"no-responsive-table"}>
            <>
                {this.printTableHeader()}
                {this.state.currentVersion && this.printTableBody()}
            </>
        </ReportTableStyle>
    }
    /* END FORM */


    render() {
        return this.printTable()
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  dispatchApiCallPost,
  dispatchApiCallPut,
  dispatchApiCallDelete,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CustomerSiteHistoryForm);
