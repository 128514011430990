import React, { useEffect, useState } from 'react';

import Utils from '../../../utils/utils';

import {
    Box,
    Grid
} from '@material-ui/core';

import Icon from '@material-ui/core/Icon';


const DataTableAjaxPagination = (props) => {
    let pages = Utils.getPaginationCount(props.count, props.selected);

    const [currentPage, setCurrentPage] = useState(props.page);
    const [pageCount, setPageCount] = useState(pages);


    useEffect(() => {
        let pages = Utils.getPaginationCount(props.count, props.selected);
        
        setCurrentPage(props.page);
        setPageCount(pages);
    }, [props.count, props.page, props.selected, pageCount]);


    const setPageButtons = () => {
        let pagination = [];

        if(pages > 0){
            let start = ((currentPage-4) <= 1) ? 1 : (currentPage-4);
            let end = ((currentPage+4) >= pages) ? pages : (currentPage+4);

            for(let i = start; i <= end; i++) {
                pagination.push(<li key={i} className={"page-item " + ((i === currentPage) ? 'active' : '')}>
                    <a href="/" className="page-link" style={{ zIndex: '1' }}
                        onClick={(e) => {
                            e.preventDefault();
        
                            setCurrentPage(i);
                            
                            if(props.onPageChange) {
                                props.onPageChange(i);
                            }
                        }}
                    >{i}</a>
                </li>)
            }
        }

        return pagination;
    }


    return (
        <Box className={'datatable-custom-pagination-component ' + props.className} pt={1}>
            <ul className="pagination pagination-outline justify-content-center justify-content-sm-end">
                <li className="page-item ">
                    <a href="/" className="page-link"
                        onClick={(e) => {
                            e.preventDefault();
                            
                            let first = 1;
                            setCurrentPage(first);

                            if(props.onPageChange) {
                                props.onPageChange(first);
                            }
                        }}
                    >
                        <Icon component={'i'}>keyboard_arrow_left</Icon>
                        <span>First</span>
                    </a>
                </li>
                <li className="page-item ">
                    <a href="/" className="page-link"
                        onClick={(e) => {
                            e.preventDefault();

                            let prev = (currentPage <= 1 ? 1 : currentPage - 1);
                            setCurrentPage(prev);
                            
                            if(props.onPageChange) {
                                props.onPageChange(prev);
                            }
                        }}
                    >Previous</a>
                </li>
                <li className={'col-12 d-block d-sm-none pb-1'}></li>
                
                {setPageButtons()}
                {/* {[...new Array(pages)].map((item, i) => {
                    let p = (i+1);

                    return <li key={i} className={"page-item " + ((p === currentPage) ? 'active' : '')}>
                        <a href="/" className="page-link" style={{ zIndex: '1' }}
                            onClick={(e) => {
                                e.preventDefault();

                                setCurrentPage(p);
                                
                                if(props.onPageChange) {
                                    props.onPageChange(p);
                                }
                            }}
                        >{p}</a>
                    </li>
                })} */}

                <li className={'col-12 d-block d-sm-none pb-1'}></li>
                <li className="page-item ">
                    <a href="/" className="page-link"
                        onClick={(e) => {
                            e.preventDefault();

                            let next = (currentPage < pageCount ? currentPage + 1 : pageCount);
                            setCurrentPage(next);
                            
                            if(props.onPageChange) {
                                props.onPageChange(next);
                            }
                        }}
                    >Next</a>
                </li>
                <li className="page-item ">
                    <a href="/" className="page-link"
                        onClick={(e) => {
                            e.preventDefault();

                            let last = (pageCount <= 1 ? 1 : pageCount);
                            setCurrentPage(last);
                            
                            if(props.onPageChange) {
                                props.onPageChange(last);
                            }
                        }}
                    >
                        <span>Last</span>
                        <Icon component={'i'}>keyboard_arrow_right</Icon>
                    </a>
                </li>
            </ul>
        </Box>
    );
  }

  export default DataTableAjaxPagination;