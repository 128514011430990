import React from "react";

import { 
    Row,
    Col,
    Form,
} from "react-bootstrap-v5";

import Utils from "../../../../utils/utils";
import { CustomPhotoSignature, JobStepType } from "../../../../utils/enums";

import { connect } from 'react-redux'
import { clear } from "../../../../../setup/redux/actions";
import { dispatchApiCallGet } from '../../../../../setup/redux/dispatch/actions'


class OptionsSection extends React.Component {
  
    constructor(props) {
        super(props);
        
        this.name = 'job_form_options_section';

        let dataParams = (props.dataParams) ? props.dataParams : null;
        let id = (dataParams && dataParams.id) ? dataParams.id : null;
        let row = (dataParams && dataParams.row) ? dataParams.row : null;
        

        this.state = {
            id: id,
            row: row,
        };
    }


    body = () => {
        if(this.props.formOptions){
            let {
                handleChange,
                setFieldValue,
                validateForm,
                values,
                errors,
            } = this.props.formOptions;
        
        
            return <>
                <Row className="w-100">
                    <Col xs={12} lg={6}>
                        <Row>
                            {(values.customFormParams.jobTemplateType !== JobStepType.Task) && <Col xs={12} className={'pb-10 mb-10'}>

                                <Row>
                                    <Col xs={12}>
                                        <Row className={'align-items-center'}>
                                            <Col xs={12} md={6} className={'fw-bold'}>Start Job</Col>
                                            <Col xs={12} md={2} className={'text-center fw-bold'}>Yes</Col>
                                            <Col xs={12} md={2} className={'text-center fw-bold'}>No</Col>
                                            <Col xs={12} md={2} className={'text-center fw-bold'}>Optional</Col>
                                            <Col xs={12}><hr /></Col>
                                        </Row>
                                    </Col>

                                    {((values.customFormParams.jobTemplateType !== JobStepType.Pull) && (values.customFormParams.jobTemplateType !== JobStepType.Shift)) && <Col xs={12} className={'pb-2'}>
                                        <Row className={'align-items-center'}>
                                            <Form.Label column xs={12} md={6}>{this.props.fields.isRequireBinNumberToEnd1.label}</Form.Label>
                                            <Col xs={12} md={2} className={'text-center'}>
                                                <Form.Check
                                                    type={'radio'}
                                                    label={''}
                                                    id={'start-isRequireBinNumberToEnd1-yes'}
                                                    name={'isRequireBinNumberToEnd1'}
                                                    disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                    readOnly={values.haveFollowUp}
                                                    checked={values.isRequireBinNumberToEnd1}
                                                    onChange={async (e) => {
                                                        await setFieldValue('isRequireBinNumberToEnd1', e.target.checked);
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={12} md={2} className={'text-center'}>
                                                <Form.Check
                                                    type={'radio'}
                                                    label={''}
                                                    id={'start-isRequireBinNumberToEnd1-no'}
                                                    name={'isRequireBinNumberToEnd1'}
                                                    disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                    readOnly={values.haveFollowUp}
                                                    checked={!values.isRequireBinNumberToEnd1}
                                                    onChange={async (e) => {
                                                        if(values.haveFollowUp){
                                                            Utils.toast('You cannot change this field because Collect bin is activated', 'error');
                                                        } else {
                                                            await setFieldValue('isRequireBinNumberToEnd1', !e.target.checked);
                                                        }
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={12} md={2} className={'text-center'}></Col>
                                        </Row>
                                        
                                        {(values.customFormParams.jobTemplateType === JobStepType.Out) && <Row className={'align-items-center'}>
                                            <Form.Label column xs={12} md={6}>{this.props.fields.isRequireBinWeight.label}</Form.Label>
                                            <Col xs={12} md={2} className={'text-center'}>
                                                <Form.Check
                                                    type={'radio'}
                                                    label={''}
                                                    id={'complete-isRequireBinWeight-yes'}
                                                    name={'isRequireBinWeight'}
                                                    disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                    checked={values.isRequireBinWeight}
                                                    onChange={async (e) => {
                                                        await setFieldValue('isRequireBinWeight', e.target.checked);
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={12} md={2} className={'text-center'}>
                                                <Form.Check
                                                    type={'radio'}
                                                    label={''}
                                                    id={'complete-isRequireBinWeight-no'}
                                                    name={'isRequireBinWeight'}
                                                    disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                    checked={!values.isRequireBinWeight}
                                                    onChange={async (e) => {
                                                        await setFieldValue('isRequireBinWeight', !e.target.checked);
                                                        
                                                        await setFieldValue('isRequireReviewWasteType', false);
                                                        await setFieldValue('mustTakePhoto2', 0);
                                                        await setFieldValue('numberofPhotosRequired2', '0');
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={12} md={2} className={'text-center'}></Col>
                                        </Row>}

                                    </Col>}
                                </Row>

                            </Col>}
                            {((values.customFormParams.jobTemplateType !== JobStepType.Put) && (values.customFormParams.jobTemplateType !== JobStepType.Task) && (values.customFormParams.jobTemplateType !== JobStepType.Out)) && <Col xs={12} className={'pb-10  mb-10'}>
                                
                                <Row>
                                    <Col xs={12}>
                                        <Row className={'align-items-center'}>
                                            <Col xs={12} md={6} className={'fw-bold'}>In Progress Job</Col>
                                            <Col xs={12} md={2} className={'text-center fw-bold'}>Yes</Col>
                                            <Col xs={12} md={2} className={'text-center fw-bold'}>No</Col>
                                            <Col xs={12} md={2} className={'text-center fw-bold'}>Optional</Col>
                                            <Col xs={12}><hr /></Col>
                                        </Row>
                                    </Col>
                                    {((values.customFormParams.jobTemplateType === JobStepType.Pull) || (values.customFormParams.jobTemplateType === JobStepType.Exchange) || (values.customFormParams.jobTemplateType === JobStepType.Shift)) && <Col xs={12} className={'pb-2'}>
                                        <Row className={'align-items-center'}>
                                            <Form.Label column xs={12} md={6}>{this.props.fields.isRequireBinNumberToEnd2.label}</Form.Label>
                                            <Col xs={12} md={2} className={'text-center'}>
                                                <Form.Check
                                                    type={'radio'}
                                                    label={''}
                                                    id={'inprogress-isRequireBinNumberToEnd2-yes'}
                                                    name={'isRequireBinNumberToEnd2'}
                                                    disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                    checked={values.isRequireBinNumberToEnd2}
                                                    onChange={async (e) => {
                                                        await setFieldValue('isRequireBinNumberToEnd2', e.target.checked);
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={12} md={2} className={'text-center'}>
                                                <Form.Check
                                                    type={'radio'}
                                                    label={''}
                                                    id={'inprogress-isRequireBinNumberToEnd2-no'}
                                                    name={'isRequireBinNumberToEnd2'}
                                                    disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                    checked={!values.isRequireBinNumberToEnd2}
                                                    onChange={async (e) => {
                                                        await setFieldValue('isRequireBinNumberToEnd2', !e.target.checked);
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={12} md={2} className={'text-center'}></Col>
                                        </Row>
                                    </Col>}
                                    <Col xs={12} className={'pb-2'}>
                                        <Row className={'align-items-center'}>
                                            <Col xs={12} md={6}>
                                                <Form.Group as={Row} className={'align-items-center'}>
                                                    <Form.Label column xs={'auto'}>{this.props.fields.numberofPhotosRequired1.label}</Form.Label>
                                                    <Col sm={'auto'}>
                                                        <Form.Control
                                                            type="number"
                                                            id={this.props.fields.numberofPhotosRequired1.id}
                                                            placeholder={this.props.fields.numberofPhotosRequired1.placeholder}
                                                            value={values.numberofPhotosRequired1}
                                                            disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || (values.mustTakePhoto1 === 0) || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                            onChange={async (e) => {
                                                                let value = e.target.value;
                                                                let pattern = ((values.mustTakePhoto1 === 1) || (values.mustTakePhoto1 === 2)) ? Utils.maxInputNumber12() : Utils.maxInputNumber012();

                                                                if(value.match(pattern) != null){
                                                                    await setFieldValue('numberofPhotosRequired1', value);
                                                                }
                                                            }}
                                                            isInvalid={!!errors.numberofPhotosRequired1}
                                                            min={((values.mustTakePhoto1 === 1) || (values.mustTakePhoto1 === 2)) ? 1 : 0}
                                                            max={2}
                                                            style={{ maxWidth: '60px' }}
                                                        />
                                                    </Col>
                                                    <Form.Label column xs={'auto'}>{this.props.fields.mustTakePhoto1.label}</Form.Label>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={2} className={'text-center'}>
                                                <Form.Check
                                                    type={'radio'}
                                                    label={''}
                                                    id={'inprogress-mustTakePhoto1-yes'}
                                                    name={'mustTakePhoto1'}
                                                    disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                    checked={values.mustTakePhoto1 === CustomPhotoSignature.Yes}
                                                    onChange={async (e) => {
                                                        await setFieldValue('mustTakePhoto1', CustomPhotoSignature.Yes);
                                                        await setFieldValue('numberofPhotosRequired1', 1);
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={12} md={2} className={'text-center'}>
                                                <Form.Check
                                                    type={'radio'}
                                                    label={''}
                                                    id={'inprogress-mustTakePhoto1-no'}
                                                    name={'mustTakePhoto1'}
                                                    disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                    checked={values.mustTakePhoto1 === CustomPhotoSignature.No}
                                                    onChange={async (e) => {
                                                        await setFieldValue('mustTakePhoto1', CustomPhotoSignature.No);
                                                        await setFieldValue('numberofPhotosRequired1', 0);
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={12} md={2} className={'text-center'}>
                                                <Form.Check
                                                    type={'radio'}
                                                    label={''}
                                                    id={'inprogress-mustTakePhoto1-optional'}
                                                    name={'mustTakePhoto1'}
                                                    disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                    checked={values.mustTakePhoto1 === CustomPhotoSignature.Optional}
                                                    onChange={async (e) => {
                                                        await setFieldValue('mustTakePhoto1', CustomPhotoSignature.Optional);
                                                        await setFieldValue('numberofPhotosRequired1', 0);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} className={'pb-2'}>
                                        <Row className={'align-items-center'}>
                                            <Form.Label column xs={12} md={6}>{this.props.fields.mustTakeSignature.label}</Form.Label>
                                            <Col xs={12} md={2} className={'text-center'}>
                                                <Form.Check
                                                    type={'radio'}
                                                    label={''}
                                                    id={'inprogress-mustTakeSignature-yes'}
                                                    name={'mustTakeSignature'}
                                                    disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                    checked={values.mustTakeSignature === CustomPhotoSignature.Yes}
                                                    onChange={async (e) => {
                                                        await setFieldValue('mustTakeSignature', CustomPhotoSignature.Yes);
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={12} md={2} className={'text-center'}>
                                                <Form.Check
                                                    type={'radio'}
                                                    label={''}
                                                    id={'inprogress-mustTakeSignature-no'}
                                                    name={'mustTakeSignature'}
                                                    disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                    checked={values.mustTakeSignature === CustomPhotoSignature.No}
                                                    onChange={async (e) => {
                                                        await setFieldValue('mustTakeSignature', CustomPhotoSignature.No);
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={12} md={2} className={'text-center'}>
                                                <Form.Check
                                                    type={'radio'}
                                                    label={''}
                                                    id={'inprogress-mustTakeSignature-optional'}
                                                    name={'mustTakeSignature'}
                                                    disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                    checked={values.mustTakeSignature === CustomPhotoSignature.Optional}
                                                    onChange={async (e) => {
                                                        await setFieldValue('mustTakeSignature', CustomPhotoSignature.Optional);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    
                                    {/* <Col xs={12} className={'pb-2'}>
                                        <Row className={'align-items-center'}>
                                            <Form.Label column xs={12} md={6}>{this.props.fields.isRequirePaymentCollection.label}</Form.Label>
                                            <Col xs={12} md={2} className={'text-center'}>
                                                <Form.Check
                                                    type={'radio'}
                                                    label={''}
                                                    id={'inprogress-isRequirePaymentCollection-yes'}
                                                    name={'isRequirePaymentCollection'}
                                                    disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                    checked={values.isRequirePaymentCollection}
                                                    onChange={async (e) => {
                                                        await setFieldValue('isRequirePaymentCollection', e.target.checked);
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={12} md={2} className={'text-center'}>
                                                <Form.Check
                                                    type={'radio'}
                                                    label={''}
                                                    id={'inprogress-isRequirePaymentCollection-no'}
                                                    name={'isRequirePaymentCollection'}
                                                    disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                    checked={!values.isRequirePaymentCollection}
                                                    onChange={async (e) => {
                                                        await setFieldValue('isRequirePaymentCollection', !e.target.checked);
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={12} md={2} className={'text-center'}></Col>
                                        </Row>
                                    </Col> */}
                                </Row>

                            </Col>}
                            <Col xs={12} className={'pb-10'}>
                            
                                <Row className={'pb-10'}>
                                    <Col xs={12}>
                                        <Row className={'align-items-center'}>
                                            <Col xs={12} md={6} className={'fw-bold'}>Complete Job</Col>
                                            <Col xs={12} md={2} className={'text-center fw-bold'}>Yes</Col>
                                            <Col xs={12} md={2} className={'text-center fw-bold'}>No</Col>
                                            <Col xs={12} md={2} className={'text-center fw-bold'}>Optional</Col>
                                            <Col xs={12}><hr /></Col>
                                        </Row>
                                    </Col>
                                    {((values.customFormParams.jobTemplateType !== JobStepType.Put) && (values.customFormParams.jobTemplateType !== JobStepType.Task) && (values.customFormParams.jobTemplateType !== JobStepType.Out) && (values.customFormParams.jobTemplateType !== JobStepType.Shift)) && <Col xs={12} className={'pb-2'}>
                                        <Row className={'align-items-center'}>
                                            <Form.Label column xs={12} md={6}>{this.props.fields.isRequireBinWeight.label}</Form.Label>
                                            <Col xs={12} md={2} className={'text-center'}>
                                                <Form.Check
                                                    type={'radio'}
                                                    label={''}
                                                    id={'complete-isRequireBinWeight-yes'}
                                                    name={'isRequireBinWeight'}
                                                    disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                    checked={values.isRequireBinWeight}
                                                    onChange={async (e) => {
                                                        await setFieldValue('isRequireBinWeight', e.target.checked);
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={12} md={2} className={'text-center'}>
                                                <Form.Check
                                                    type={'radio'}
                                                    label={''}
                                                    id={'complete-isRequireBinWeight-no'}
                                                    name={'isRequireBinWeight'}
                                                    disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                    checked={!values.isRequireBinWeight}
                                                    onChange={async (e) => {
                                                        await setFieldValue('isRequireBinWeight', !e.target.checked);
                                                        
                                                        await setFieldValue('isRequireReviewWasteType', false);
                                                        await setFieldValue('mustTakePhoto2', 0);
                                                        await setFieldValue('numberofPhotosRequired2', '0');
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={12} md={2} className={'text-center'}></Col>
                                        </Row>
                                    </Col>}
                                    {((values.customFormParams.jobTemplateType !== JobStepType.Put) && (values.customFormParams.jobTemplateType !== JobStepType.Task) && (values.customFormParams.jobTemplateType !== JobStepType.Out) && (values.customFormParams.jobTemplateType !== JobStepType.Shift)) && <Col xs={12} className={'pb-2'}>
                                        <Row className={'align-items-center'}>
                                            <Form.Label column xs={12} md={6}>{this.props.fields.isRequireReviewWasteType.label}</Form.Label>
                                            <Col xs={12} md={2} className={'text-center'}>
                                                <Form.Check
                                                    type={'radio'}
                                                    label={''}
                                                    id={'complete-isRequireReviewWasteType-yes'}
                                                    name={'isRequireReviewWasteType'}
                                                    disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || !values.isEditableWasteTypeIn || !values.isRequireBinWeight || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                    checked={values.isRequireReviewWasteType}
                                                    onChange={async (e) => {
                                                        await setFieldValue('isRequireReviewWasteType', e.target.checked);
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={12} md={2} className={'text-center'}>
                                                <Form.Check
                                                    type={'radio'}
                                                    label={''}
                                                    id={'complete-isRequireReviewWasteType-no'}
                                                    name={'isRequireReviewWasteType'}
                                                    disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || !values.isEditableWasteTypeIn || !values.isRequireBinWeight || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                    checked={!values.isRequireReviewWasteType}
                                                    onChange={async (e) => {
                                                        await setFieldValue('isRequireReviewWasteType', !e.target.checked);
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={12} md={2} className={'text-center'}></Col>
                                        </Row>
                                    </Col>}
                                    <Col xs={12} className={'pb-2'}>
                                        <Row className={'align-items-center'}>
                                            <Col xs={12} md={6}>
                                                <Form.Group as={Row} className={'align-items-center'}>
                                                    <Form.Label column xs={'auto'}>{this.props.fields.numberofPhotosRequired2.label}</Form.Label>
                                                    <Col sm={'auto'}>
                                                        <Form.Control
                                                            type="number"
                                                            id={this.props.fields.numberofPhotosRequired2.id}
                                                            placeholder={this.props.fields.numberofPhotosRequired2.placeholder}
                                                            value={values.numberofPhotosRequired2}
                                                            disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || (values.mustTakePhoto2 === 0) || (((values.customFormParams.jobTemplateType === JobStepType.Put) || (values.customFormParams.jobTemplateType === JobStepType.Task) || (values.customFormParams.jobTemplateType === JobStepType.Out) || (values.customFormParams.jobTemplateType === JobStepType.Shift)) ? false : !values.isRequireBinWeight) || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                            onChange={async (e) => {
                                                                let value = e.target.value;
                                                                let pattern = ((values.mustTakePhoto2 === 1) || (values.mustTakePhoto2 === 2)) ? Utils.maxInputNumber12() : Utils.maxInputNumber012();

                                                                if(value.match(pattern) != null){
                                                                    await setFieldValue('numberofPhotosRequired2', value);
                                                                }
                                                            }}
                                                            isInvalid={!!errors.numberofPhotosRequired2}
                                                            min={((values.mustTakePhoto2 === 1) || (values.mustTakePhoto2 === 2)) ? 1 : 0}
                                                            max={2}
                                                            style={{ maxWidth: '60px' }}
                                                        />
                                                    </Col>
                                                    <Form.Label column xs={'auto'}>{this.props.fields.mustTakePhoto2.label}</Form.Label>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={2} className={'text-center'}>
                                                <Form.Check
                                                    type={'radio'}
                                                    label={''}
                                                    id={'complete-mustTakePhoto2-yes'}
                                                    name={'mustTakePhoto2'}
                                                    disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || (((values.customFormParams.jobTemplateType === JobStepType.Put) || (values.customFormParams.jobTemplateType === JobStepType.Task) || (values.customFormParams.jobTemplateType === JobStepType.Out) || (values.customFormParams.jobTemplateType === JobStepType.Shift)) ? false : !values.isRequireBinWeight) || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                    checked={values.mustTakePhoto2 === CustomPhotoSignature.Yes}
                                                    onChange={async (e) => {
                                                        await setFieldValue('mustTakePhoto2', CustomPhotoSignature.Yes);
                                                        await setFieldValue('numberofPhotosRequired2', 1);
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={12} md={2} className={'text-center'}>
                                                <Form.Check
                                                    type={'radio'}
                                                    label={''}
                                                    id={'complete-mustTakePhoto2-no'}
                                                    name={'mustTakePhoto2'}
                                                    disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || (((values.customFormParams.jobTemplateType === JobStepType.Put) || (values.customFormParams.jobTemplateType === JobStepType.Task) || (values.customFormParams.jobTemplateType === JobStepType.Out) || (values.customFormParams.jobTemplateType === JobStepType.Shift)) ? false : !values.isRequireBinWeight) || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                    checked={values.mustTakePhoto2 === CustomPhotoSignature.No}
                                                    onChange={async (e) => {
                                                        await setFieldValue('mustTakePhoto2', CustomPhotoSignature.No);
                                                        await setFieldValue('numberofPhotosRequired2', 0);
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={12} md={2} className={'text-center'}>
                                                <Form.Check
                                                    type={'radio'}
                                                    label={''}
                                                    id={'complete-mustTakePhoto2-optional'}
                                                    name={'mustTakePhoto2'}
                                                    disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || (((values.customFormParams.jobTemplateType === JobStepType.Put) || (values.customFormParams.jobTemplateType === JobStepType.Task) || (values.customFormParams.jobTemplateType === JobStepType.Out) || (values.customFormParams.jobTemplateType === JobStepType.Shift)) ? false : !values.isRequireBinWeight) || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                    checked={values.mustTakePhoto2 === CustomPhotoSignature.Optional}
                                                    onChange={async (e) => {
                                                        await setFieldValue('mustTakePhoto2', CustomPhotoSignature.Optional);
                                                        await setFieldValue('numberofPhotosRequired2', 0);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    {((values.customFormParams.jobTemplateType === JobStepType.Put) || (values.customFormParams.jobTemplateType === JobStepType.Task)) && <Col xs={12} className={'pb-2'}>
                                        <Row className={'align-items-center'}>
                                            <Form.Label column xs={12} md={6}>{this.props.fields.mustTakeSignature.label}</Form.Label>
                                            <Col xs={12} md={2} className={'text-center'}>
                                                <Form.Check
                                                    type={'radio'}
                                                    label={''}
                                                    id={'complete-mustTakeSignature-yes'}
                                                    name={'mustTakeSignature'}
                                                    disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                    checked={values.mustTakeSignature === CustomPhotoSignature.Yes}
                                                    onChange={async (e) => {
                                                        await setFieldValue('mustTakeSignature', CustomPhotoSignature.Yes);
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={12} md={2} className={'text-center'}>
                                                <Form.Check
                                                    type={'radio'}
                                                    label={''}
                                                    id={'complete-mustTakeSignature-no'}
                                                    name={'mustTakeSignature'}
                                                    disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                    checked={values.mustTakeSignature === CustomPhotoSignature.No}
                                                    onChange={async (e) => {
                                                        await setFieldValue('mustTakeSignature', CustomPhotoSignature.No);
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={12} md={2} className={'text-center'}>
                                                <Form.Check
                                                    type={'radio'}
                                                    label={''}
                                                    id={'complete-mustTakeSignature-optional'}
                                                    name={'mustTakeSignature'}
                                                    disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                    checked={values.mustTakeSignature === CustomPhotoSignature.Optional}
                                                    onChange={async (e) => {
                                                        await setFieldValue('mustTakeSignature', CustomPhotoSignature.Optional);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>}
                                    {/* {(values.customFormParams.jobTemplateType === JobStepType.Put) && <Col xs={12} className={'pb-2'}>
                                        <Row className={'align-items-center'}>
                                            <Form.Label column xs={12} md={6}>{this.props.fields.isRequirePaymentCollection.label}</Form.Label>
                                            <Col xs={12} md={2} className={'text-center'}>
                                                <Form.Check
                                                    type={'radio'}
                                                    label={''}
                                                    id={'complete-isRequirePaymentCollection-yes'}
                                                    name={'isRequirePaymentCollection'}
                                                    disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                    checked={values.isRequirePaymentCollection}
                                                    onChange={async (e) => {
                                                        await setFieldValue('isRequirePaymentCollection', e.target.checked);
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={12} md={2} className={'text-center'}>
                                                <Form.Check
                                                    type={'radio'}
                                                    label={''}
                                                    id={'complete-isRequirePaymentCollection-no'}
                                                    name={'isRequirePaymentCollection'}
                                                    disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                    checked={!values.isRequirePaymentCollection}
                                                    onChange={async (e) => {
                                                        await setFieldValue('isRequirePaymentCollection', e.target.checked);
                                                    }}
                                                />
                                            </Col>
                                            <Col xs={12} md={2} className={'text-center'}></Col>
                                        </Row>
                                    </Col>} */}
                                </Row>


                                <Row className={'mt-10'}>
                                {((values.customFormParams.jobTemplateType !== JobStepType.Pull) && (values.customFormParams.jobTemplateType !== JobStepType.Task)) && <Col xs={12} className={'pb-10'}>
                                
                                    <Row>
                                        <Col xs={12}>
                                            <Row className={'align-items-center'}>
                                                <Col xs={12} md={6} className={'fw-bold'}>Bin Out</Col>
                                                <Col xs={12} md={2} className={'text-center fw-bold'}>Yes</Col>
                                                <Col xs={12} md={2} className={'text-center fw-bold'}>No</Col>
                                                <Col xs={12} md={2} className={'text-center fw-bold'}>Optional</Col>
                                                <Col xs={12}><hr /></Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} className={'pb-2'}>
                                            <Row className={'align-items-center'}>
                                                <Form.Label column xs={12} md={6}>{this.props.fields.isEditableBinTypeOut.label}</Form.Label>
                                                <Col xs={12} md={2} className={'text-center'}>
                                                    <Form.Check
                                                        type={'radio'}
                                                        label={''}
                                                        id={'binout-isEditableBinTypeOut-yes'}
                                                        name={'isEditableBinTypeOut'}
                                                        disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                        checked={values.isEditableBinTypeOut}
                                                        onChange={async (e) => {
                                                            await setFieldValue('isEditableBinTypeOut', e.target.checked);
                                                        }}
                                                    />
                                                </Col>
                                                <Col xs={12} md={2} className={'text-center'}>
                                                    <Form.Check
                                                        type={'radio'}
                                                        label={''}
                                                        id={'binout-isEditableBinTypeOut-no'}
                                                        name={'isEditableBinTypeOut'}
                                                        disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                        checked={!values.isEditableBinTypeOut}
                                                        onChange={async (e) => {
                                                            await setFieldValue('isEditableBinTypeOut', !e.target.checked);
                                                        }}
                                                    />
                                                </Col>
                                                <Col xs={12} md={2} className={'text-center'}></Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} className={'pb-2'}>
                                            <Row className={'align-items-center'}>
                                                <Form.Label column xs={12} md={6}>{this.props.fields.isEditableWasteTypeOut.label}</Form.Label>
                                                <Col xs={12} md={2} className={'text-center'}>
                                                    <Form.Check
                                                        type={'radio'}
                                                        label={''}
                                                        id={'binout-isEditableWasteTypeOut-yes'}
                                                        name={'isEditableWasteTypeOut'}
                                                        disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                        checked={values.isEditableWasteTypeOut}
                                                        onChange={async (e) => {
                                                            await setFieldValue('isEditableWasteTypeOut', e.target.checked);
                                                        }}
                                                    />
                                                </Col>
                                                <Col xs={12} md={2} className={'text-center'}>
                                                    <Form.Check
                                                        type={'radio'}
                                                        label={''}
                                                        id={'binout-isEditableWasteTypeOut-no'}
                                                        name={'isEditableWasteTypeOut'}
                                                        disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                        checked={!values.isEditableWasteTypeOut}
                                                        onChange={async (e) => {
                                                            await setFieldValue('isEditableWasteTypeOut', !e.target.checked);
                                                        }}
                                                    />
                                                </Col>
                                                <Col xs={12} md={2} className={'text-center'}></Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} className={'pb-2'}>
                                            <Row className={'align-items-center'}>
                                                <Form.Label column xs={12} md={6}>{this.props.fields.isEditableBinNumberOut.label}</Form.Label>
                                                <Col xs={12} md={2} className={'text-center'}>
                                                    <Form.Check
                                                        type={'radio'}
                                                        label={''}
                                                        id={'binout-isEditableBinNumberOut-yes'}
                                                        name={'isEditableBinNumberOut'}
                                                        disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                        checked={values.isEditableBinNumberOut}
                                                        onChange={async (e) => {
                                                            await setFieldValue('isEditableBinNumberOut', e.target.checked);
                                                        }}
                                                    />
                                                </Col>
                                                <Col xs={12} md={2} className={'text-center'}>
                                                    <Form.Check
                                                        type={'radio'}
                                                        label={''}
                                                        id={'binout-isEditableBinNumberOut-no'}
                                                        name={'isEditableBinNumberOut'}
                                                        disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                        checked={!values.isEditableBinNumberOut}
                                                        onChange={async (e) => {
                                                            await setFieldValue('isEditableBinNumberOut', !e.target.checked);
                                                        }}
                                                    />
                                                </Col>
                                                <Col xs={12} md={2} className={'text-center'}></Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                </Col>}

                                {((values.customFormParams.jobTemplateType !== JobStepType.Put) && (values.customFormParams.jobTemplateType !== JobStepType.Task) && (values.customFormParams.jobTemplateType !== JobStepType.Out)) && <Col xs={12} className={'pb-10'}>
                                
                                    <Row>
                                        <Col xs={12}>
                                            <Row className={'align-items-center'}>
                                                <Col xs={12} md={6} className={'fw-bold'}>Bin In</Col>
                                                <Col xs={12} md={2} className={'text-center fw-bold'}>Yes</Col>
                                                <Col xs={12} md={2} className={'text-center fw-bold'}>No</Col>
                                                <Col xs={12} md={2} className={'text-center fw-bold'}>Optional</Col>
                                                <Col xs={12}><hr /></Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} className={'pb-2'}>
                                            <Row className={'align-items-center'}>
                                                <Form.Label column xs={12} md={6}>{this.props.fields.isEditableWasteTypeIn.label}</Form.Label>
                                                <Col xs={12} md={2} className={'text-center'}>
                                                    <Form.Check
                                                        type={'radio'}
                                                        label={''}
                                                        id={'binin-isEditableWasteTypeIn-yes'}
                                                        name={'isEditableWasteTypeIn'}
                                                        disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                        checked={values.isEditableWasteTypeIn}
                                                        onChange={async (e) => {
                                                            await setFieldValue('isEditableWasteTypeIn', e.target.checked);
                                                        }}
                                                    />
                                                </Col>
                                                <Col xs={12} md={2} className={'text-center'}>
                                                    <Form.Check
                                                        type={'radio'}
                                                        label={''}
                                                        id={'binin-isEditableWasteTypeIn-no'}
                                                        name={'isEditableWasteTypeIn'}
                                                        disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                        checked={!values.isEditableWasteTypeIn}
                                                        onChange={async (e) => {
                                                            await setFieldValue('isEditableWasteTypeIn', !e.target.checked);
                                                        }}
                                                    />
                                                </Col>
                                                <Col xs={12} md={2} className={'text-center'}></Col>
                                            </Row>
                                        </Col>
                                        {((values.customFormParams.jobTemplateType !== JobStepType.Shift) && (values.customFormParams.jobTemplateType !== JobStepType.OnTheSpot)) && <Col xs={12} className={'pb-2'}>
                                            <Row className={'align-items-center'}>
                                                <Form.Label column xs={12} md={6}>{this.props.fields.isEditableBinNumberIn.label}</Form.Label>
                                                <Col xs={12} md={2} className={'text-center'}>
                                                    <Form.Check
                                                        type={'radio'}
                                                        label={''}
                                                        id={'binin-isEditableBinNumberIn-yes'}
                                                        name={'isEditableBinNumberIn'}
                                                        disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                        checked={values.isEditableBinNumberIn}
                                                        onChange={async (e) => {
                                                            await setFieldValue('isEditableBinNumberIn', e.target.checked);
                                                        }}
                                                    />
                                                </Col>
                                                <Col xs={12} md={2} className={'text-center'}>
                                                    <Form.Check
                                                        type={'radio'}
                                                        label={''}
                                                        id={'binin-isEditableBinNumberIn-no'}
                                                        name={'isEditableBinNumberIn'}
                                                        disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                        checked={!values.isEditableBinNumberIn}
                                                        onChange={async (e) => {
                                                            await setFieldValue('isEditableBinNumberIn', !e.target.checked);
                                                        }}
                                                    />
                                                </Col>
                                                <Col xs={12} md={2} className={'text-center'}></Col>
                                            </Row>
                                        </Col>}
                                    </Row>

                                </Col>}
                                <Col xs={12} className={'pb-10 mt-10'}>
                                
                                    <Row>
                                        <Col xs={12}>
                                            <Row className={'align-items-center'}>
                                                <Col xs={12} md={6} className={'fw-bold'}>Others</Col>
                                                <Col xs={12}><hr /></Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} className={'pb-2'}>
                                            <Row className={'align-items-center'}>
                                                <Col xs={true}>{this.props.fields.duration.label}</Col>
                                                <Col xs={'auto'} className={'text-end'}>
                                                    <Form.Control
                                                        type="number"
                                                        id={this.props.fields.duration.id}
                                                        placeholder={this.props.fields.duration.placeholder}
                                                        value={values.duration}
                                                        disabled={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || values.isLoading || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                                        onChange={async (e) => {
                                                            let value = e.target.value;
                                                            let pattern = Utils.max24With1Decimal();

                                                            if(value.match(pattern) != null){
                                                                await setFieldValue('duration', e.target.value);
                                                            }
                                                        }}
                                                        isInvalid={!!errors.duration}
                                                        min={0}
                                                        step={0.5}
                                                        style={{ maxWidth: '120px' }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>


                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        }
    }


    render() {
        return <>
            {this.body()}
        </>
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(OptionsSection);
