import React from 'react';

import {
    TableBody,
    TableRow,
    TableCell,
} from '@material-ui/core';
  
import DataTableRow from './DataTableRow.jsx';
import DataTableEmptyRow from './DataTableEmptyRow.jsx';
import DataTableSkeletonRow from './DataTableSkeletonRow.jsx';

import { SortableContainer } from 'react-sortable-hoc';
import InfiniteScroll from 'react-infinite-scroller';


const SortableList = SortableContainer((props) => {
    const { getScrollParent, useWindowScroll, isLoading, rowId, isBorder, isDisabledCheckbox, isSelected, isCheckbox, isDragable, columns, handleClick, onColClick, handleCBClick, handleCBChange, customCheckBoxRow, setCol, setResponsiveColHead, columnVisibility, noResult, verticalAlign, details, customDetails, expandDetails, openDetailsRow, customRow, expandedRows, isVirtualize } = props;

    const showItems = 15;
    let [ fromData, setFromData] = React.useState(0);
    let [ hasMore, setHasMore] = React.useState((props.rows && props.rows.length > showItems) ? true : false);

    const [ rows, setRows] = React.useState((props.rows && props.rows.length > 0) ? props.rows: []);

    React.useEffect(() => {
        setRows((props.rows && props.rows.length > 0) ? props.rows: []);
        setFromData(0);
        setHasMore((props.rows && props.rows.length > showItems) ? true : false);
    }, [props.rows]);


    const cols = (columnVisibility && columnVisibility.length > 0) ? columnVisibility.filter(x => x === true).length : 0;
    const colSpan = (isBorder) ? cols + 1 : cols + 1;

    
    const isSomeRowsExpanded = () => {
        return (expandedRows && expandedRows.length > 0) ? true : false;
    }
    
    const drawRows = () => {
        let newItems = [];
        if(isVirtualize){
            newItems = rows.slice(0, (fromData + showItems));
        } else {
            newItems = rows;
        }

        if(newItems && newItems.length > 0){
            if(isVirtualize){
                return <InfiniteScroll
                    element={'tbody'}
                    className={'MuiTableBody-root' + (isSomeRowsExpanded() ? ' has-expanded-rows' : '')}
                    pageStart={0}
                    getScrollParent={getScrollParent}
                    useWindow={useWindowScroll}
                    loadMore={() => {
                        let newFromData = fromData + showItems;
                        setHasMore((newFromData >= rows.length) ? false : true)
                        setFromData((newFromData >= rows.length) ? rows.length : newFromData)
                    }}
                    hasMore={hasMore}
                    loader={<TableRow
                        key={'loadingKey'}
                        tabIndex={-1}
                        className={'infinite-scroll-loading'}
                    >
                        <TableCell colSpan={colSpan}>Loading...</TableCell>
                    </TableRow>}
                >
                    {(newItems && newItems.length > 0) && newItems.map((row, index) => {
                        return <React.Fragment key={index}>
                            {addRow(row, index, null)}
                        </React.Fragment>
                    })}
                </InfiniteScroll>
            } else {
                return <tbody>
                    {(newItems && newItems.length > 0) && newItems.map((row, index) => {
                    return <React.Fragment key={index}>
                            {addRow(row, index, null)}
                        </React.Fragment>
                    })}
                </tbody>
            }
        }
    };

    const addRow = (row, index, groupIndex) => {
        const id = row[rowId] ? row[rowId] : index;
        const isItemSelected = isSelected(id, row);
        const isItemDisabledCheckbox = isDisabledCheckbox(id, row);
        const labelId = `enhanced-table-checkbox-${index}`;
        
        return (
            <DataTableRow 
                key={index}
                index={index}
                indexNo={index}
                groupIndex={groupIndex}
                row={row}
                id={id}
                isItemDisabledCheckbox={isItemDisabledCheckbox}
                isItemSelected={isItemSelected}
                labelId={labelId}
                isCheckbox={isCheckbox}
                isDragable={isDragable}
                isBorder={isBorder}
                columns={columns}
                handleClick={handleClick}
                onColClick={onColClick}
                handleCBClick={handleCBClick}
                handleCBChange={handleCBChange}
                customCheckBoxRow={customCheckBoxRow}
                setCol={setCol}
                setResponsiveColHead={setResponsiveColHead}
                columnVisibility={columnVisibility}
                verticalAlign={verticalAlign}
                details={details}
                customDetails={customDetails}
                expandDetails={expandDetails}
                openDetailsRow={openDetailsRow}
                customRow={customRow}
                expandedRows={expandedRows}
                isVirtualize={isVirtualize}
            />
        );
    };

    return <React.Fragment>
        {
            (
            isLoading
            ?
                <TableBody>
                    <DataTableSkeletonRow 
                        isCheckbox={isCheckbox}
                        isDragable={isDragable}
                        isBorder={isBorder}
                        expandDetails={expandDetails}
                        columns={columns}
                        height={42}
                        columnVisibility={columnVisibility}
                    />
                </TableBody>
            :
                (rows && rows.length > 0)
                ?
                drawRows()
                :
                (
                    noResult
                    ?
                    <TableBody>{noResult}</TableBody>
                    :
                    <TableBody>
                        <DataTableEmptyRow 
                            isCheckbox={isCheckbox}
                            isDragable={isDragable}
                            isBorder={isBorder}
                            colSpan={colSpan}
                        />
                    </TableBody>
                )
            )
        }
    </React.Fragment>
});


const DataTableBody = React.forwardRef((props, ref) => {
    const { rows, order, orderBy, nestedOrderBy, getScrollParent, onRowSortEnd, useWindowScroll, page, rowsPerPage, rowsPerPageSelected, selected, isLoading, selectedGroups, selectedAllGroups, search, rowId, isBorder, isDisabledCheckbox, isSelected, isCheckbox, isDragable, isPagination, columns, handleClick, onColClick, handleCBClick, handleCBChange, customCheckBoxRow, customCheckBoxHead, setCol, setResponsiveColHead, columnVisibility, includeInactive, noResult, filters, verticalAlign, groupBy, groupByTotal, details, customDetails, expandDetails, customRow, expandedRows, isVirtualize } = props;
    
    const [openDetailsRow, setOpenDetailsRow] = React.useState(false);
    
    React.useImperativeHandle(
        ref,
        () => ({
            toggleAllDetails() {
                setOpenDetailsRow(!openDetailsRow);
            },
            hideAllDetails() {
                setOpenDetailsRow(false);
            },
            showAllDetails() {
                setOpenDetailsRow(true);
            },
         }),
    )

    return <SortableList
        ref={ref}
        rows={rows}
        order={order}
        orderBy={orderBy}
        nestedOrderBy={nestedOrderBy}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageSelected={rowsPerPageSelected}
        search={search}
        rowId={rowId}
        isCheckbox={isCheckbox}
        isDragable={isDragable}
        isPagination={isPagination}
        isBorder={isBorder}
        columns={columns}
        isSelected={isSelected}
        handleClick={handleClick}
        onColClick={onColClick}
        handleCBClick={handleCBClick}
        handleCBChange={handleCBChange}
        customCheckBoxRow={customCheckBoxRow}
        customCheckBoxHead={customCheckBoxHead}
        setCol={setCol}
        setResponsiveColHead={setResponsiveColHead}
        columnVisibility={columnVisibility}
        includeInactive={includeInactive}
        noResult={noResult}
        filters={filters}
        verticalAlign={verticalAlign}
        groupBy={groupBy}
        groupByTotal={groupByTotal}
        isDisabledCheckbox={isDisabledCheckbox}
        selected={selected}
        selectedGroups={selectedGroups}
        selectedAllGroups={selectedAllGroups}
        isLoading={isLoading}
        details={details}
        customDetails={customDetails}
        expandDetails={expandDetails}
        openDetailsRow={openDetailsRow}
        customRow={customRow}
        expandedRows={expandedRows}
        getScrollParent={getScrollParent}
        useWindowScroll={useWindowScroll}
        isVirtualize={isVirtualize}
        useDragHandle={true}
        useWindowAsScrollContainer={true}
        disableAutoscroll={true}
        lockAxis={'y'}
        helperClass="dragging-helper-class"
        onSortEnd={({oldIndex, newIndex}) => {
            if(onRowSortEnd){
                onRowSortEnd(oldIndex, newIndex);
            }
        }}
    />
  });

  export default DataTableBody;