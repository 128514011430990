/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import * as auth from '../../../setup/redux/auth/saga'
import {login} from '../../api/auth/api'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

import { 
  InputAdornment,
} from '@material-ui/core';
import ManageInput from '../../components/OLD/input/ManageInput.jsx';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 6 characters')
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 6 characters')
    .max(50, 'Maximum 50 characters')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)

  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        login(values.email, values.password)
          .then(({data: {token}}) => {
            setLoading(false)
            dispatch(auth.actions.login(token))
          })
          .catch( error => {
            try {
              setLoading(false)
              setSubmitting(false)

                if (error.response) {
                    // Request made and server responded             
                    if(error.response.data) {
                      if(error.response.data.error) {
                        setStatus(error.response.data.error);
                      } else {
                        setStatus(error.response.data);
                      }
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    setStatus('The login detail is incorrect')
                } else {
                    // Something happened in setting up the request that triggered an Error
                    setStatus('The login detail is incorrect')
                }
            } catch(err) {
              setStatus('The login detail is incorrect')
            }
        })
      }, 1000)
    },
  })

  const setPasswordIcon = () => {
    if(showCurrentPassword) {
        return <InputAdornment position="end">
        <i className={"fas fa-eye"}
            onClick={() => {
              setShowCurrentPassword(false);
            }}
        ></i>
        </InputAdornment>
    } else {
        return <InputAdornment position="end">
        <i className={"fas fa-eye-slash"}
            onClick={() => {
              setShowCurrentPassword(true);
            }}
        ></i>
        </InputAdornment>
    }
  }


  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='mb-lg-10 d-flex flex-column'>
        <h1 className='text-dark m-0 mb-3 mb-lg-3 fs-3qx fw-bolder'>Log in to your account</h1>
        <div className='text-muted fw-bold fs-4 mb-10'>
          Sign into your WastePorter account and simplify your Skip Bin Hire Business
        </div>
      </div>

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div></div>
      )}

      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Your E-mail</label>
        <ManageInput 
          placeholder='Email'
          {...formik.getFieldProps('email')}
          // error={formik.errors.email} 
          type='email'
          name='email'
          autoComplete='off'
        />
        {/* <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        /> */}
        {/* {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )} */}
      </div>
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
          </div>
        </div>
        <ManageInput 
          placeholder='Password'
          autoComplete='off'
          // error={formik.errors.password} 
          {...formik.getFieldProps('password')}
          type={showCurrentPassword ? 'text' : 'password'}
          endAdornment={setPasswordIcon()}
        />
        {/* <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        /> */}
        {/* {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )} */}
        <div className='text-end mt-5'>
        <Link
          to='/reset-password'
          className='link-primary fs-6 fw-bolder'
        >
          Forgot Password?
        </Link>
        </div>
      </div>
      
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-10'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label text-uppercase'>Sign In</span>}
          {loading && (
            <span className='indicator-progress text-uppercase' style={{display: 'block'}}>
              Signing In
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        <div className='text-center text-muted fw-bolder'> 
          Don't have an account? {' '}
          <a href='https://www.wasteporter.com/sign-up' className='link-primary fw-bold'>
            Sign Up
          </a>
        </div>
      </div>
      {/* end::Action */}
    </form>
  )
}
