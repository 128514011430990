import React from "react";

import { 
    Row,
    Col,
    Form,
    InputGroup,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import Utils from "../../../../utils/utils";
import { InOrOut, BinOrWaste, ReportUOM, PagesPermissions } from "../../../../utils/enums";

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import CustomTooltip from '../../../../components/Tooltip/Tooltip';
import RSuiteSelectPicker from '../../../../components/OLD/Select/RSuiteSelectPicker';
import RSuiteAutoComplete from '../../../../components/OLD/Autocomplete/RSuiteAutoComplete';
import InputLayout from "../../../../components/input/InputLayout";

import { connect } from 'react-redux'
import { clear } from "../../../../../setup/redux/actions";
import { dispatchApiCallGet } from '../../../../../setup/redux/dispatch/actions'

import { ReactComponent as ScanTextIcon } from "../../../../../_metronic/assets/img/bin_center/scan_text.svg";


const StyledInputGroup = styled(InputGroup)`
    input {
        &:not(.disabled) {
            &:not([value=""]) {
                border-right: 0px;
            }
        }
    }

    .input-group-text {
        background-color: white;
        cursor: pointer;

        i {
            font-size: 14px;
            color: #8e8e93;
        }

        &:hover {
            i {
                color: #f44336;
            }
        }
    }
`;
const StyledInputReadOnly = styled(Form.Control)`
    border: 0px;
    padding-left: 5px;
    cursor: default;

    &[readonly]{
        color: #181C32 !important;
    }
`;


class StepOutSection extends React.Component {
  
    constructor(props) {
        super(props);
        
        this.name = 'job_form_step_out_section';

        let dataParams = (props.dataParams) ? props.dataParams : null;
        let id = (dataParams && dataParams.id) ? dataParams.id : null;
        let row = (dataParams && dataParams.row) ? dataParams.row : null;
        
        this.pageRef = React.createRef();

        this.state = {
            id: id,
            row: row,
        };
    }
    

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            this.name + '-binnumber', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = [];

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.binNumberId;
                        let title = item.binNumberName;
                        let arrItem = {
                            index: i,
                            value: value,
                            title: title,
                            label: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );
    }
	
    callBinNumberReadApi = (binTypeId = null, items, searchQuery = '', callback = null) => {
        let {
            values,
        } = this.props.formOptions;

        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: searchQuery,
            binTypeId: binTypeId,
            customerSiteId: values.customerSiteId,
            customerId: values.customerId,
            showRecentJob: true,
        };

        this.props.dispatchApiCallGet(data, this.name + '-binnumber', 'binnumber', { items: items }, callback, () => {});
    }
    /* END API */


    body = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;
        
        let hasEditJobAfterCompleted = (Utils.hasPermission(Utils.getPermissions(), PagesPermissions.EditJobAfterCompleted.read))

        let isDisabled1 = values.isParams || Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || !(values.customerSiteId) || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName);
        let isDisabled2 = values.isParams || Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || !(values.customerSiteId) || Utils.isCancelledOrFailed(values.currentStatusName);
        let isDisabled = hasEditJobAfterCompleted ? isDisabled2 : isDisabled1;
        
        let isDisabledBinN1 = values.isParams || Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || !(values.customerSiteId) || !(values.stepOutBinTypeId) || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName);
        let isDisabledBinN2 = values.isParams || Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || !(values.customerSiteId) || !(values.stepOutBinTypeId) || Utils.isCancelledOrFailed(values.currentStatusName);
        let isDisabledBinN = hasEditJobAfterCompleted ? isDisabledBinN2 : isDisabledBinN1;
        
        let isDisabledCurrentStatusName1 = (!values.isRecurringForm && Utils.isCompletedOrCancelledOrFailed(values.currentStatusName))
        let isDisabledCurrentStatusName2 = (!values.isRecurringForm && Utils.isCancelledOrFailed(values.currentStatusName))
        let isDisabledCurrentStatusName = hasEditJobAfterCompleted ? isDisabledCurrentStatusName2 : isDisabledCurrentStatusName1;
        
        let isDisabledWeightChit = Utils.isCancelledOrFailed(values.currentStatusName);

        return <Row className={''}>
            <Col xs={12} md={6}>
                <Row className={''}>
                    <Col xs={12}>
                        <h3 className={'mb-7'}>
                            <span style={{ color: '#DFDFDF' }}>BIN OUT</span>
                            <CustomTooltip placement={'right'} title={'You deliver bin to customer site.'}>
                                <InfoOutlinedIcon style={{ color: '#DFDFDF', width: '30px', height: '25px' }} />
                            </CustomTooltip>
                        </h3>
                    </Col>
                    <Col xs={12}>
                        <Row>
                            <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.stepOutBinTypeId.label}
                                    required
                                >
                                    <StyledInputGroup>
                                        <Form.Control
                                            className={'custom-readonly-bin-waste-field ' + (isDisabled ? 'disabled': '')}
                                            id={this.props.fields.stepOutBinTypeId.id}
                                            placeholder={this.props.fields.stepOutBinTypeId.placeholder}
                                            readOnly={true}
                                            value={values.stepOutBinTypeName}
                                            isInvalid={!!errors.stepOutBinTypeId}
                                            onClick={(e) => {
                                                if(!isDisabled){
                                                    if(this.props.onBinWasteSidePanel){
                                                        this.props.onBinWasteSidePanel(InOrOut.IN, BinOrWaste.BIN);
                                                    }
                                                }
                                            }}
                                        />
                                        {(!isDisabled && values.stepOutBinTypeName != null && values.stepOutBinTypeName != '') && <InputGroup.Text
                                            onClick={(e) => {
                                                if(this.props.onBinWasteClear){
                                                    this.props.onBinWasteClear(InOrOut.IN, BinOrWaste.BIN);
                                                }
                                            }}
                                        >
                                            <i className={'material-icons'}>close</i>
                                        </InputGroup.Text>}
                                        <Form.Control.Feedback type="invalid">{errors.stepOutBinTypeId}</Form.Control.Feedback>
                                    </StyledInputGroup>
                                </InputLayout>
                            </Col>
                            <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.stepOutWasteTypeId.label}
                                    required
                                >
                                    <StyledInputGroup>
                                        <Form.Control
                                            className={'custom-readonly-bin-waste-field ' + (isDisabled ? 'disabled': '')}
                                            id={this.props.fields.stepOutWasteTypeId.id}
                                            placeholder={this.props.fields.stepOutWasteTypeId.placeholder}
                                            readOnly={true}
                                            value={values.stepOutWasteTypeName}
                                            isInvalid={!!errors.stepOutWasteTypeId}
                                            onClick={(e) => {
                                                if(!isDisabled){
                                                    if(this.props.onBinWasteSidePanel){
                                                        this.props.onBinWasteSidePanel(InOrOut.IN, BinOrWaste.BIN);
                                                    }
                                                }
                                            }}
                                        />
                                        {(!isDisabled && values.stepOutWasteTypeName != null && values.stepOutWasteTypeName != '') && <InputGroup.Text
                                            onClick={(e) => {
                                                if(this.props.onBinWasteClear){
                                                    this.props.onBinWasteClear(InOrOut.IN, BinOrWaste.BIN);
                                                }
                                            }}
                                        >
                                            <i className={'material-icons'}>close</i>
                                        </InputGroup.Text>}
                                        <Form.Control.Feedback type="invalid">{errors.stepOutWasteTypeId}</Form.Control.Feedback>
                                    </StyledInputGroup>
                                </InputLayout>
                            </Col>
                            <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.stepOutBinNumber.label}
                                >
                                    <InputGroup>
                                    <Form.Control
                                        as = {RSuiteAutoComplete}
                                        isInvalid={!!errors.stepOutBinNumber}

                                        id={this.props.fields.stepOutBinNumber.id}
                                        placeholder={this.props.fields.stepOutBinNumber.placeholder}
                                        items={values.customFormParams.stepOutBinNumberItems}
                                        value={values.stepOutBinNumber}
                                        isLoading={values.customFormParams.stepOutBinNumberIsLoading}
                                        disabled={isDisabledBinN}
                                        labelKey={'title'}
                                        isDefault={true}
                                        pattern={Utils.max7chars()}
                                        renderMenuItem={(label, item) => {
                                            let minDate = Utils.getMinDate();
                                            let lastJobDate = (item && item.item && item.item.lastJobDate) ? item.item.lastJobDate : minDate;

                                            return <div className={'d-flex align-items-center'}>
                                                {((lastJobDate !== minDate) && (item.index < 5)) ? <i className={'material-icons'}>access_time</i> : null}
                                                <span className={'ps-2'}>{item.title}</span>
                                            </div>
                                        }}
                                        onClick={async () => {
                                            await setFieldValue('customFormParams.stepOutBinNumberIsLoading', true);
                                            this.callBinNumberReadApi(values.stepOutBinTypeId, values.customFormParams.stepOutBinNumberItems, '', async (items) => {
                                                await setFieldValue('customFormParams.stepOutBinNumberItems', items);
                                                await setFieldValue('customFormParams.stepOutBinNumberIsLoading', false);
                                            });
                                        }}
                                        onSearch={async (value) => {
                                            await setFieldValue('stepOutisUsingQr', false);
                                            await setFieldValue('stepOutisUsingTextDetection', false);
                                            await setFieldValue('customFormParams.isSameAsBinOut', false);
                                            await setFieldValue('customFormParams.stepOutBinNumberIsLoading', true);
                                            this.callBinNumberReadApi(values.stepOutBinTypeId, values.customFormParams.stepOutBinNumberItems, value, async (items) => {
                                                await setFieldValue('customFormParams.stepOutBinNumberItems', items);
                                                await setFieldValue('customFormParams.stepOutBinNumberIsLoading', false);
                                            });
                                        }}
                                        onChange={async (value) => {
                                            await setFieldValue('stepOutisUsingQr', false);
                                            await setFieldValue('stepOutisUsingTextDetection', false);
                                            await setFieldValue('stepOutBinNumber', value);
                                            await setFieldValue('customFormParams.isSameAsBinOut', false);
                                        }}
                                        onSelect={async (value, item) => {
                                            await setFieldValue('stepOutisUsingQr', false);
                                            await setFieldValue('stepOutisUsingTextDetection', false);
                                            await setFieldValue('stepOutBinNumber', value);
                                            await setFieldValue('customFormParams.isSameAsBinOut', false);
                                        }}
                                    />
                                    {values.stepOutisUsingQr && <CustomTooltip title={'QR code is scanned'}>
                                        <i style={{ position: 'absolute', right: '15px', top: '15px' }} class="fa fa-qrcode"></i>
                                    </CustomTooltip>}
                                    {values.stepOutisUsingTextDetection && <CustomTooltip title={'QR code is scanned with text recognition'}>
                                        <ScanTextIcon style={{ position: 'absolute', right: '14px', top: '15px', width: '15px', height: '15px' }} />
                                    </CustomTooltip>}
                                    <Form.Control.Feedback type="invalid">{errors.stepOutBinNumber}</Form.Control.Feedback>
                                    </InputGroup>
                                </InputLayout>
                            </Col>

                            {!values.isRecurringForm && <Col  xs={12}>
                                <InputLayout
                                    label={<>
                                        <span>{'Bin Weight 1' + ((values.uom === ReportUOM.kg) ? ' (kg)' : ' (tons)')}</span>
                                        {/* <CustomTooltip placement={'right'} title={'Gross Weight (This) - Tare Weight - Non billable Weight = Billable Weight'}>
                                            <InfoOutlinedIcon style={{ color: '#DFDFDF', width: '30px', height: '25px' }} />
                                        </CustomTooltip> */}
                                    </>}
                                >
                                    <Form.Control
                                        className={'custom-readonly-bin-weight-field '}
                                        type={'number'}
                                        id={this.props.fields.binOutWeight.id}
                                        placeholder={this.props.fields.binOutWeight.placeholder}
                                        // readOnly={Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                        value={values.binOutWeight}
                                        onChange={async (e) => {
                                            await setFieldValue('binOutWeight', e.target.value);
                                        }}
                                        isInvalid={!!errors.binOutWeight}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.binOutWeight}</Form.Control.Feedback>
                                </InputLayout>
                            </Col>}

                            {isDisabledCurrentStatusName && <Col xs={12}>
                                <InputLayout
                                    label={<>
                                        <span>{'Bin Weight 2' + ((values.uom === ReportUOM.kg) ? ' (kg)' : ' (tons)')}</span>
                                        <CustomTooltip placement={'right'} title={'Gross Weight (This) - Tare Weight - Non billable Weight = Billable Weight'}>
                                            <InfoOutlinedIcon style={{ color: '#DFDFDF', width: '30px', height: '25px' }} />
                                        </CustomTooltip>
                                    </>}
                                >
                                    <Form.Control
                                        className={'custom-readonly-bin-weight-field ' + (isDisabledWeightChit ? 'disabled': '')}
                                        id={this.props.fields.stepBinWeight.id}
                                        placeholder={this.props.fields.stepBinWeight.placeholder}
                                        readOnly={true}
                                        value={values.stepBinWeight}
                                        isInvalid={!!errors.stepBinWeight}
                                        onClick={(e) => {
                                            if(!isDisabledWeightChit){
                                                if(this.props.onWeighChitSidePanel){
                                                    this.props.onWeighChitSidePanel();
                                                }
                                            }
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.stepBinWeight}</Form.Control.Feedback>
                                </InputLayout>
                            </Col>}

                            <Col xs={12}>
                                <InputLayout
                                    label={'Service name'}
                                    inputProps={{
                                        className: 'd-flex align-items-center'
                                    }}
                                >
                                    <div>
                                        <span className={'pe-2'}>{values.stepOutServiceItemName}</span>
                                        {(values.stepOutServiceItemIsGlobal && (values.stepOutServiceItemName != '')) && <CustomTooltip placement={'top'} title={'Global service'}>
                                            <i className={'material-icons'} style={{ position: 'relative', top: '6px', fontSize: '22px', }}>public</i>
                                        </CustomTooltip>}
                                    </div>
                                </InputLayout>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    }
    

    render() {
        return <div ref={this.pageRef}>
            {this.body()}
        </div>
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(StepOutSection);
