import React, { Component } from "react";
import * as yup from 'yup';

import { 
  Row,
  Col,
  Button,
} from "react-bootstrap-v5";

import { DrawerLayout } from '../../../components/drawer/DrawerLayout'
import CurrentDateTime from '../../../components/currentDateTime/CurrentDateTime'

import ViewLayout from "../layout/ViewLayout";

import DataTable from "../../../components/OLD/DataTable/DataTable";
import DataTableActionMenu from '../../../components/OLD/DataTable/DataTableActionMenu';
import DataTableAjaxPagination from '../../../components/OLD/DataTable/DataTableAjaxPagination.jsx';
import DataTableScrollSync from '../../../components/OLD/DataTable/DataTableScrollSync.jsx';
import DropdownIconButtonMenu from '../../../components/OLD/Dropdown/DropdownIconButtonMenu';
import IconButtonTable from "../../../components/Buttons/IconButtonTable";

import CustomDialog from "../../../components/OLD/Dialog/CustomDialog.jsx";
import StripePayDialog from "./Dialogs/StripePayDialog.jsx";

import { ReactComponent as SettingsIcon } from "../../../../_metronic/assets/img/icons/manage-settings.svg";

import Utils from '../../../utils/utils'
import { PageCookieSettingType, PagesPermissions, SubscriptionPaymentType } from '../../../utils/enums'

import { connect } from 'react-redux'
import { actions } from "../../../../setup/redux/auth/saga";
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut, dispatchApiCallDelete } from '../../../../setup/redux/dispatch/actions'


const { saveUserCookieSettings } = actions;


const formFields = {
  isEdit: {
    id: 'isEdit',
    label: ' ',
    placeholder: ' ',
  },
}
const formSchema = yup.object().shape({
  isEdit: yup.bool().oneOf([true, false]),
});


class SubscriptionView extends Component {

  constructor(props) {
    super(props);

    this.pageName = "manage-mysubscription-page";
    this.pageIdField = "accountSubscriptionBillingInvoiceId";
    this.pageNameField = "";
    this.pageAPI = "AccountSubscriptionBilling";
    this.pageCaption = 'Subscription'

		let user = (props.auth && props.auth.user) ? props.auth.user : null;
		this.permissions = (user && user.permissions && user.permissions.length > 0) ? user.permissions : [];

    props.layoutRef.current = this;
    this.formDrawer = null;

    this.tableRef = React.createRef();
    this.refDropdownAdvancedFilter = React.createRef();
    this.refDropdownActionMenu = React.createRef();
    this.refFormDrawer = React.createRef();
    this.refDataTableScrollSync = React.createRef();
    
    
    this.isPaidTemplate = (id, row, col, obj) => {
      return <div className={(row.isPaid) ? 'text-primary' : 'text-danger'}><b>{(row.isPaid) ? 'Paid' : 'Unpaid'}</b></div>
    };
    this.isPaidExportTemplate = (row) => {
      return (row.isPaid) ? 'Paid' : 'Unpaid';
    };
    this.isPaidExportCSVTemplate = (row) => {
      return (row.isPaid) ? 'Paid' : 'Unpaid';
    };
    
    this.periodStartDateDisplayTemplate = (id, row, col, obj) => {
      return <div>{row.periodStartDateDisplay + ' to ' + row.periodEndDateDisplay}</div>
    };
    this.periodStartDateDisplayExportTemplate = (row) => {
      return row.periodStartDateDisplay + ' to ' + row.periodEndDateDisplay;
    };
    this.periodStartDateDisplayExportCSVTemplate = (row) => {
      return row.periodStartDateDisplay + ' to ' + row.periodEndDateDisplay;
    };
    
    this.chargeTotalTemplate = (id, row, col, obj) => {
      return <div>{Utils.getCurrency() + Utils.formatNumber(row.chargeTotal)}</div>
    };
    this.chargeTotalExportTemplate = (row) => {
      return Utils.getCurrency() + Utils.formatNumber(row.chargeTotal);
    };
    this.chargeTotalExportCSVTemplate = (row) => {
      return Utils.getCurrency() + Utils.formatNumber(row.chargeTotal);
    };

    this.actionTemplate = (id, row, col, obj) => {
      return <div className={'text-center'}>
        {row.isPaid ? (
          <IconButtonTable
            title={'Download'}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              this.callDownloadAPI(row);
            }}
          >
            <i className={'material-icons'}>file_download</i>
          </IconButtonTable>
        ) : (
          // <Button
          //   variant={'primary'}
          //   className={'pt-1 pb-1 rounded-1 '}
          //   onClick={() => {
          //     this.setState({
          //       isStripePayDialog: true,
          //       isStripePayDialogItem: {
          //         row: row,
          //         amount: row.chargeTotal,
          //         description: row.chargeDescription,
          //       },
          //     });
          //   }}
          // >
          //   Pay now
          // </Button>
          <></>
        )}
      </div>
    };


    let columns = [
      { 
        id: this.pageIdField, 
        filterKey: this.pageIdField, 
        name: this.pageIdField, 
        label: 'ID',
        visible: false
      },
      {
        id: "isPaid",
        name: "isPaid",
        label: "Status",
        responsiveLabel: "Status",
        orderable: true,
        template: this.isPaidTemplate,
        exportTemplate: this.isPaidExportTemplate,
        exportCSVTemplate: this.isPaidExportCSVTemplate,
      },
      {
        id: "paymentTypeDisplay",
        name: "paymentType",
        label: "Method",
        responsiveLabel: "Method",
        orderable: true,
      },
      {
        id: "invoiceDateDisplay",
        name: "invoiceDate",
        label: "Invoice date",
        responsiveLabel: "Invoice date",
        orderable: true,
      },
      {
        id: "periodStartDateDisplay",
        name: "periodStartDate",
        label: "Usage period",
        responsiveLabel: "Usage period",
        orderable: false,
        template: this.periodStartDateDisplayTemplate,
        exportTemplate: this.periodStartDateDisplayExportTemplate,
        exportCSVTemplate: this.periodStartDateDisplayExportCSVTemplate,
      },
      {
        id: "invoiceDueDateDisplay",
        name: "invoiceDueDate",
        label: "Due date",
        responsiveLabel: "Due date",
        orderable: true,
      },
      {
        id: "chargeTotal",
        name: "chargeTotal",
        label: "Charge amount",
        responsiveLabel: "Charge amount",
        orderable: true,
        template: this.chargeTotalTemplate,
        exportTemplate: this.chargeTotalExportTemplate,
        exportCSVTemplate: this.chargeTotalExportCSVTemplate,
      },
      {
        id: "action",
        name: "action",
        label: "Actions",
        responsiveLabel: "Actions",
        width: "150px",
        align: "center",
        orderable: false,
        exportVisible: false,
        visibleInDropdown: false,
        filterable: false,
        template: this.actionTemplate,
      },
    ];
    let initColumns = Array.from(columns);
    columns = Array.from(initColumns);
    
    
    let columnVisibility = [
      false,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
    ];
    let initColumnVisibility = Array.from(columnVisibility);
    columnVisibility = Array.from(initColumnVisibility);


    this.state = {
      data: null,
      err: null,
      isError: false,
      isLoading: false,

      search: '',
      sortColumn: 'invoiceDate',
      sortDir: 'desc',
      searchTags: [],
      searchOperator: [],
      searchColumns: [],

      selected: [],
      columnVisibility: columnVisibility,
      initColumnVisibility: initColumnVisibility,
      columns: columns,
      initColumns: initColumns,
      rows: [],
      currentPage: 1,
      totalRows: 0,
      rowsPerPageSelected: 50,
      rowsPerPageOptions: [50, 100, 300],
      
      isCookies: false,
      
      isIncludeInactive: false,

      isDisabledBatchAction: true,
      batchActionLoading: false,

      isStripePayDialog: false,
      isStripePayDialogItem: null,
    }
  }


  componentDidMount() {
    this.initDrawers();

    this.getFilterInLocalStorage(() => {
      this.callReadApi();
    });
  }
  
  componentWillReceiveProps(nextProps) {
    this.reduxProps(nextProps);
	}
  

	/* FUNCTIONS */
  onWindowResize = () => {
    this.getFilterInLocalStorage();
  }

  setFilterInLocalStorage = (isCookies = true) => {
    if(this.props.settingsType >= 0){
      let { token, userCookieSettings } = this.props.auth;

      this.setState({
        isCookies: isCookies,
      });
      
      let data = {
        name: this.pageName,
        type: this.props.settingsType,
      };

      let valueLocalStorage = {
        [PageCookieSettingType.currentPage]: this.state.currentPage,
      };
      window.localStorage.setItem(this.pageName + '_' + this.props.settingsType, JSON.stringify(valueLocalStorage));

      let value = {
        [PageCookieSettingType.isCookies]: isCookies,
        [PageCookieSettingType.search]: this.state.search,
        [PageCookieSettingType.sortColumn]: this.state.sortColumn,
        [PageCookieSettingType.sortDir]: this.state.sortDir,
        [PageCookieSettingType.rowsPerPageSelected]: this.state.rowsPerPageSelected,
        [PageCookieSettingType.columnVisibility]: this.state.columnVisibility,
        [PageCookieSettingType.columns]: this.state.columns,
        [PageCookieSettingType.isIncludeInactive]: this.state.isIncludeInactive,
      };
      data['value'] = JSON.stringify(value);

      Utils.saveUserCookieSettings(data, token, (settings) => {
        if(settings){
          let settingsArr = (userCookieSettings && userCookieSettings.length > 0) ? userCookieSettings : [];
          let settingsIndex = Utils.getUserCookieSettingsIndex(settingsArr, this.props.settingsType);
          if(settingsIndex !== -1 && settingsArr[settingsIndex]){
            settingsArr[settingsIndex] = settings;
          } else {
            settingsArr.push(settings);
          }

          this.props.saveUserCookieSettings(settingsArr)
        }
      });
    }
  };
  getFilterInLocalStorage = (callback = null) => {
    let settingsItem = null;

    if(this.props.settingsType >= 0){
      const { userCookieSettings } = this.props.auth;

      settingsItem = Utils.getUserCookieSettings(userCookieSettings, this.props.settingsType, this.pageName);
    }

    let valueLocalStorageStr = window.localStorage.getItem(this.pageName + '_' + this.props.settingsType);
    let valueLocalStorage = JSON.parse(valueLocalStorageStr);
    let currentPageValue = valueLocalStorage && valueLocalStorage[PageCookieSettingType.currentPage] && valueLocalStorage[PageCookieSettingType.currentPage] !== "" ? valueLocalStorage[PageCookieSettingType.currentPage] : this.state.currentPage;
    
    let isCookiesValue = settingsItem && settingsItem[PageCookieSettingType.isCookies] && settingsItem[PageCookieSettingType.isCookies] !== "" ? settingsItem[PageCookieSettingType.isCookies] : this.state.isCookies;
    let searchValue = (settingsItem && settingsItem[PageCookieSettingType.search] && settingsItem[PageCookieSettingType.search] !== '') ? settingsItem[PageCookieSettingType.search] : this.state.search;
    let sortColumnValue = settingsItem && settingsItem[PageCookieSettingType.sortColumn] && settingsItem[PageCookieSettingType.sortColumn] !== "" ? settingsItem[PageCookieSettingType.sortColumn] : this.state.sortColumn;
    let sortDirValue = settingsItem && settingsItem[PageCookieSettingType.sortDir] && settingsItem[PageCookieSettingType.sortDir] !== "" ? settingsItem[PageCookieSettingType.sortDir] : this.state.sortDir;
    let rowsPerPageSelectedValue = settingsItem && settingsItem[PageCookieSettingType.rowsPerPageSelected] && settingsItem[PageCookieSettingType.rowsPerPageSelected] !== "" ? settingsItem[PageCookieSettingType.rowsPerPageSelected] : this.state.rowsPerPageSelected;
    let columnVisibilityValue = (settingsItem && settingsItem[PageCookieSettingType.columnVisibility] && settingsItem[PageCookieSettingType.columnVisibility] !== '') ? settingsItem[PageCookieSettingType.columnVisibility] : this.state.columnVisibility;
    let columnsValue = (settingsItem && settingsItem[PageCookieSettingType.columns] && settingsItem[PageCookieSettingType.columns] !== '') ? settingsItem[PageCookieSettingType.columns] : this.state.columns;
    let isIncludeInactiveValue = (settingsItem && settingsItem[PageCookieSettingType.isIncludeInactive] && settingsItem[PageCookieSettingType.isIncludeInactive] !== '') ? settingsItem[PageCookieSettingType.isIncludeInactive] : this.state.isIncludeInactive;
    
    let columns = Utils.setTemplateFunctionsToColumns(this.state.columns, columnsValue);
    
    this.setState({
      isCookies: isCookiesValue,
      search: searchValue,
      sortColumn: sortColumnValue,
      sortDir: sortDirValue,
      currentPage: currentPageValue,
      rowsPerPageSelected: rowsPerPageSelectedValue,
      columnVisibility: columnVisibilityValue,
      isIncludeInactive: isIncludeInactiveValue,
      columns: columns,
    }, () => {
      if (callback) {
        callback();
      }
    });
  };

  reduxProps = nextProps => {
		Utils.reduxProps(nextProps,
      this.pageName + '-list', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        let rows = (data && data.data && data.data.length > 0) ? data.data : [];
        let totalRows = (data && data.total) ? data.total : 0;
        
        this.setState({
          rows: rows,
          totalRows: totalRows,

          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(callback){
            callback();
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      this.pageName + '-download', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          batchActionLoading: false,

          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(this.state.isError){
          } else {
            Utils.saveByteArray(variables.invoiceNumber, data);
          }
          
          if(callback){
            callback();
          }
        });
      }
    );
    
    Utils.reduxProps(nextProps,
      this.pageName + '-mark_as_paid', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(this.state.isError){
          } else {
            Utils.toast(<div style={{ fontWeight: 'bold' }}>Successfully Paid!</div>, 'success');
          }
          
          if(callback){
            callback();
          }
        });
      }
    );
  }

  initDrawers = () => {
    Utils.initDrawers(() => {
      this.formDrawer = Utils.getInstanceDrawer('form');
      Utils.onShowDrawer(this.formDrawer, null, () => {
        this.setState({
          isVisibleFormDrawer: true,
        });
      });
      Utils.onHideDrawer(this.formDrawer, this.refFormDrawer, () => {
        this.setState({
          isVisibleFormDrawer: false,
        });
      });
    });
  }

  setDataTable = () => {
    return <DataTable 
      ref={this.tableRef}

      className={"table-style-v4"}
      classNameWrapper={"table-v4"}

      pageName={this.pageName}
      rowId={this.pageIdField}
      caption={this.pageCaption}
      
      noMarginBottom={true}
      verticalAlign={'middle'}
      
      stickyHeader={false}
      isHeader={true}
      resizable={true}
      isDragableHeaderColumns={true}
      isDragable={false}
      isCheckbox={false}
      isBorder={false}
      isPagination={false}
      
      columnVisibility={this.state.columnVisibility}

      isLoading={this.state.isLoading}
      rowsPerPageSelected={this.state.rowsPerPageSelected}
      rowsPerPageOptions={this.state.rowsPerPageOptions}

      search={this.state.search}
      rows={this.state.rows}
      columns={this.state.columns}

      onRemove={(row) => {

      }}

      onClickCheckAll={(obj, checked, isDisabledBatchAction) => {
        this.setState({
          isDisabledBatchAction: isDisabledBatchAction
        });
      }}
      onCheckRow={(obj, id, isDisabledBatchAction) => {
        this.setState({
          isDisabledBatchAction: isDisabledBatchAction
        });
      }}

      onSortEnd={(columns, columnVisibility) => {
        this.setState({
          columns: columns,
          columnVisibility: columnVisibility,
        }, () => {
          this.setFilterInLocalStorage();
        });
      }}
      onOrder={(name, sort) => {
        this.setState({
          sortColumn: name,
          sortDir: sort,
        }, () => {
          this.setFilterInLocalStorage();
          this.callReadApi(false);
        });
      }}
      onResize={(columns) => {
        if(columns){
          this.setState({
            columns: columns
          });
        }
        this.setFilterInLocalStorage();
      }}
    />
  }
	/* END FUNCTIONS */

	
	/* API */
  callReadApi = (withStatusCall = true, callback = null) => {
    this.setState({
      isLoading: true,
    });

    let data = {
      currentPage: this.state.currentPage,
      pageSize: this.state.rowsPerPageSelected,
      searchQuery: this.state.search,
      sortColumn: this.state.sortColumn,
      sortDir: this.state.sortDir,
      isIncludeInactive: ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : false,
    };
 
    if (this.state.searchTags && this.state.searchTags.length > 0) {
      data["searchTags"] = Utils.joinItems(this.state.searchTags);
    }
    if (this.state.searchOperator && this.state.searchOperator.length > 0) {
      data["searchOperator"] = Utils.getSelectedOperator(this.state.searchOperator);
    }
    if (this.state.searchColumns && this.state.searchColumns.length > 0) {
      data["searchColumns"] = Utils.getSelectedColumns(this.state.searchColumns);
    }

		this.props.dispatchApiCallGet(data, this.pageName + '-list', this.pageAPI + '/invoices', null, callback, () => {});

    if(withStatusCall){
      
    }
	}
  
  callDownloadAPI = (row = null, callback = null) => {
		Utils.toast(<div style={{ fontWeight: 'bold' }}>Please Wait ...</div>, 'info');
    
    this.setState({
      batchActionLoading: true,
    });

    let data = {
      invoiceid: row.accountSubscriptionBillingInvoiceId,
    };
 
		this.props.dispatchApiCallGet(data, this.pageName + '-download', this.pageAPI + '/download-invoice', row, callback, () => {});
	}
  
  callMarkAsPaidApi = (data = null, callback = null) => {
    this.setState({
      isLoading: true,
    });

		this.props.dispatchApiCallPut(data, this.pageName + '-mark_as_paid', this.pageAPI + '/mark-as-paid', data, callback, () => {});
	}
	/* END API */
	
	
	/* SECTIONS */
	actionSection = () => {
		return <Row className={'align-items-center'}>

      {(Utils.hasPermission(this.permissions, PagesPermissions.ManageGeneral.read)) && <Col xs={'auto'} className={'mb-2 mb-sm-0'}>
        <Button
          variant={'light'}
          className={'pt-1 pb-1 rounded-1 bg-white'}
          onClick={(e) => {
            this.props.history.push('/manage/setting/general');
          }}
        >
          GENERAL
        </Button>
      </Col>}
      
      {(Utils.hasPermission(this.permissions, PagesPermissions.ManageSubscription.read)) && <Col xs={'auto'} className={'mb-2 mb-sm-0'}>
        <Button
          variant={'success'}
          className={'pt-1 pb-1 rounded-1 '}
          onClick={(e) => {
            this.props.history.push('/manage/setting/subscription');
          }}
        >
        SUBSCRIPTION
        </Button>
      </Col>}

      <Col xs={12} md={true} className={'order-1 order-md-3 pb-10 pb-md-0'}>
        <CurrentDateTime />
      </Col>
    </Row>
	}

	toolbarSection = () => {
		return <Row className={'align-items-center'}>
      <Col xs={'auto'} md={true}></Col>
      <Col xs={12} md={true} className={'text-center text-md-end'}>
        <div className='d-inline-flex align-items-center text-start'>
        <DropdownIconButtonMenu 
          ref={this.refDropdownActionMenu}
          className={'job-list-icon-button-dropdown'}
          selected={null}
          icon={<SettingsIcon />}
        >
          <DataTableActionMenu 
            pageName={this.pageName}
            columns={this.state.columns}
            selected={this.state.rowsPerPageSelected}
            selectItems={this.state.rowsPerPageOptions}
            columnVisibility={this.state.columnVisibility}
            onChangeColumnVisibility={(index, value) => {
              let colVis = this.state.columnVisibility;
              colVis[index] = value;

              this.setState({
                columnVisibility: colVis
              }, () => {
                this.setFilterInLocalStorage();
              });
            }}
            canInactive={false}
            onSelectChangeAjax={(val) => {
              this.setState({
                currentPage: 1,
                rowsPerPageSelected: val
              }, () => {
                this.setFilterInLocalStorage();
                this.callReadApi();
              });
            }}
            onChangeIncludeInactive={(val) => {
              this.setState({
                isIncludeInactive: val,
                currentPage: 1,
              }, () => {
                this.setFilterInLocalStorage();
                this.callReadApi();
              });
            }}
            isCookies={this.state.isCookies}
            onResetColumns={() => {
              this.setState({
                columns: Array.from(this.state.initColumns),
                columnVisibility: Array.from(this.state.initColumnVisibility),
              }, () => {
                this.setFilterInLocalStorage(false);
                if(this.refDropdownActionMenu && this.refDropdownActionMenu.current){
                  this.refDropdownActionMenu.current.hide();
                }
              });
            }}
            onShowMore={(isShowedMore) => {
              this.setState({
                pageName: this.pageName,
              });
            }}
          />
        </DropdownIconButtonMenu>
        </div>
      </Col>
    </Row>
	}

	tableSection = () => {
		return <DataTableScrollSync ref={this.refDataTableScrollSync} className={"table-sync-v4"}>{this.setDataTable()}</DataTableScrollSync>
	}

	paginationSection = () => {
		return <DataTableAjaxPagination
      page={this.state.currentPage}
      selected={this.state.rowsPerPageSelected}
      count={this.state.totalRows}
      onPageChange={(page) => {
        this.setState({
          currentPage: page,
        }, () => {
          this.setFilterInLocalStorage();
          this.callReadApi(false);
        });
      }}
    />
	}
	/* END SECTIONS */


  /* DIALOGS */
  setStripePayDialog = () => {
    return (
      <CustomDialog
        className={"custom-subscription-dialog"}
        open={this.state.isStripePayDialog}
        maxWidth={"sm"}
        closeBtnPosition={"in"}
        onClose={() => {
          this.setState({
            isStripePayDialog: false,
            isStripePayDialogItem: null,
          });
        }}
      >
        <StripePayDialog
          item={this.state.isStripePayDialogItem}
          onClose={() => {
            this.setState({
              isStripePayDialog: false,
              isStripePayDialogItem: null,
            });
          }}
          onPay={(result) => {
            if (result && result.data) {
              let item = this.state.isStripePayDialogItem;
              let row = item.row;

              this.setState({
                isStripePayDialog: false,
                isStripePayDialogItem: null,
              });

              let payload = {
                accountSubscriptionBillingInvoiceId:
                  row.accountSubscriptionBillingInvoiceId,
                paymentType: SubscriptionPaymentType.Stripe,
                stripeResponse: result,
              };

              this.callMarkAsPaidApi(payload, () => {
                let rows = this.state.rows;
                let index = rows.findIndex((x) => {
                  return x.accountSubscriptionBillingInvoiceId === row.accountSubscriptionBillingInvoiceId;
                });
                if (index > -1) {
                  rows[index].isPaid = true;
                }

                this.setState({
                  rows: rows,
                  isStripePayDialog: false,
                  isStripePayDialogItem: null,
                });
              });
            }
          }}
        />
      </CustomDialog>
    );
  };
  /* END DIALOGS */


  render() {
    return (
      <>
        <ViewLayout
          isBlocking={this.state.batchActionLoading}
          title={'SETTINGS'}
          col={<></>}
          actionSection={this.actionSection()}
          toolbarSection={this.toolbarSection()}
          tableSection={this.tableSection()}
          paginationSection={this.paginationSection()}
        />

        {this.setStripePayDialog()}
      </>
    );
  }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  dispatchApiCallPost,
  dispatchApiCallPut,
  dispatchApiCallDelete,
  saveUserCookieSettings,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SubscriptionView);
