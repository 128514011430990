/* eslint-disable no-loop-func */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import * as dispatchCrudApi from '../../../api/CRUD/DispatchCRUD'
import Utils from "../../../utils/utils";

import { ParseResult } from "../../../utils/interfaces";


interface ApiCallArgs {
  data: any,
  path: string,
  method: string,
}

interface InitState {
  isLoading: boolean,
  data: any,
  url: any,
  error: any,

  log: any,
}


function NewReducer() {
  const name = 'publicIframe';


  const initialState: InitState = {
    isLoading: false,
    data: null,
    url: null,
    error: null,

    log: null,
  };


  const reducers = {
    start: (state: InitState) => {
      state.isLoading = true;
      state.data = null;
      state.url = null;
    },
    finish: (state: InitState, action: PayloadAction<{ data: any, url: any, error: string }>) => {
      let data = (action.payload && action.payload.data) ? action.payload.data : null;
      let url = (action.payload && action.payload.url) ? action.payload.url : null;
      let error = (action.payload && action.payload.error) ? action.payload.error : null;

      state.data = data;
      state.url = url;
      state.error = error;
      state.isLoading = false;
    },
    
    startLog: (state: InitState) => {
      state.isLoading = true;
      state.log = null;
    },
    finishLog: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload) ? action.payload : null;

      state.log = data;
      state.isLoading = false;
    },
  };


  const apis = {
    callPreviewApi: (params: ApiCallArgs) => async (dispatch: any) => {
      dispatch(actions.start());

      let apiCall = dispatchCrudApi.createApi;
      if(params.method === 'get'){
        apiCall = dispatchCrudApi.readApi;
      }

      await apiCall(params.data, params.path).then(result => {
        let data = result.data;
        let url = '';
        let error = null;

        try {
          if(data){
            url = window.URL.createObjectURL(new Blob([Utils.getUint8Array(data)], {type: 'application/pdf'}));
          }
          error = null;
        } catch(e){
          error = data;
        }
        
        dispatch(actions.finish({ data, url, error }));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        dispatch(actions.finish({ data: null, url: null, error: err }));
      });
    },

    callEmailLogApi: (params: any) => async (dispatch: any) => {
      dispatch(actions.startLog());

      await dispatchCrudApi.readApi(params, 'job/email-log').then(result => {
        let data = result.data;

        dispatch(actions.finishLog(data));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        dispatch(actions.finishLog(null));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();