import React, { Component } from "react";
import moment from 'moment';
import * as yup from 'yup';

import {
    Row,
    Col,
} from "react-bootstrap-v5";

import { DrawerLayout } from '../../components/drawer/DrawerLayout'
import CurrentDateTime from '../../components/currentDateTime/CurrentDateTime'

import ViewLayout from "./layout/ViewLayout.jsx";
import ViewTopSection from "./partial/ViewTopSection.jsx";
import ReminderForm from "./partial/ReminderForm.jsx";

import DataTable from "../../components/OLD/DataTable/DataTable";
import DataTableActionMenu from '../../components/OLD/DataTable/DataTableActionMenu';
import DataTableAjaxPagination from '../../components/OLD/DataTable/DataTableAjaxPagination.jsx';
import DataTableScrollSync from '../../components/OLD/DataTable/DataTableScrollSync.jsx';
import DropdownIconButtonMenu from '../../components/OLD/Dropdown/DropdownIconButtonMenu';
import IconButtonSearch from '../../components/OLD/input/IconButtonSearch';
// import IconButton from "../../components/Buttons/IconButton";
import makeExpanding from '../../components/OLD/Expanding/makeExpanding';

import { ReactComponent as SettingsIcon } from "../../../_metronic/assets/img/icons/manage-settings.svg";

import Utils from '../../utils/utils'
import { PageCookieSettingType } from '../../utils/enums'

import { connect } from 'react-redux'
import { actions } from "../../../setup/redux/auth/saga";
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut, dispatchApiCallDelete } from '../../../setup/redux/dispatch/actions'


const { saveUserCookieSettings } = actions;
const ExpandingSearchBox = makeExpanding(IconButtonSearch);

const formFields = {
    customerId: {
        id: 'customerId',
        label: 'Customer Name',
        placeholder: ' ',
    },
    customerSiteId: {
        id: 'customerSiteId',
        label: 'Site Name',
        placeholder: ' ',
    },
    reminderDescripton: {
        id: 'reminderDescripton',
        label: 'Remarks',
        placeholder: Utils.placeholderRows(5),
    },
    reminderDate: {
        id: 'reminderDate',
        label: 'Reminder Date',
        placeholder: '',
    },
    accountReminderUsers: {
        id: 'accountReminderUsers',
        label: 'Add team members to reminder list',
        placeholder: '',
    },
    userId: {
        id: 'userId',
        label: 'Add team members to reminder list',
        placeholder: '',
    },
    status: {
        id: 'status',
        label: 'Status',
        placeholder: '',
    },
}

const formSchema = yup.object().shape({
    isEdit: yup.bool().oneOf([true, false]),
    customerId: yup.number().nullable().required().label(formFields.customerId.label),
    customerSiteId: yup.number().nullable().required().label(formFields.customerSiteId.label),
    reminderDescripton: yup.string().label(formFields.reminderDescripton.label),
    reminderDate: yup.date().nullable().required().label(formFields.reminderDate.label),
    userId: yup.array().nullable().required().label('Users'),
    status: yup.bool().oneOf([true, false]),
});

class ReminderList extends Component {
    constructor(props) {
        super(props);

        props.layoutRef.current = this;

        this.pageName = "reminder-list";
        this.pageIdField = "accountReminderId";
        this.pageAPI = "AccountReminder";
        this.pageCaption = 'Reminders'

        this.tableRef = React.createRef();
        this.refDropdownActionMenu = React.createRef();
        this.refRowDrawerForm = React.createRef();
        this.refFormDrawer = React.createRef();
        this.refDataTableScrollSync = React.createRef();

        this.numberTemplate = (id, row, col, obj, index) => {
            return <span>{index + 1}</span>
        };

        this.createdTemplate = (id, row, col, obj) => {
            return <span>{moment(row.created).format('MMM. DD hh:mm A')}</span>;
        };

        this.customerNameDisplayTemplate = (id, row, col, obj, index) => {
            return (row.customer && row.customer.customerNameDisplay && row.customer.customerNameDisplay !== '' ? row.customer.customerNameDisplay : '');
        };

        this.siteNameDisplayTemplate = (id, row, col, obj, index) => {
            return (row.customerSite && row.customerSite.siteNameDisplay && row.customerSite.siteNameDisplay !== '') ? row.customerSite.siteNameDisplay : '';
        };

        this.reminderDescriptonTemplate = (id, row, col, obj, index) => {
            return row.reminderDescripton ? row.reminderDescripton : '';
        };

        this.updatedBy = (id, row, col, obj, index) => {
            return row.updatedByName ? row.updatedByName : '';
        };

        this.reminderDateTemplate = (id, row, col, obj, index) => {
            // return <span>{moment.utc(row.reminderDate).local().format('DD/MM/YYYY')}</span>;
            return <span>{moment(row.reminderDate).format('DD/MM/YYYY')}</span>;
        };

        let columns = [
            {
                id: "accountReminderId",
                name: "accountReminderId",
                label: "ID",
                responsiveLabel: "ID",
                visible: false,
            },
            {
                id: "number",
                name: "number",
                label: "No.",
                menuLabel: "No.",
                responsiveLabel: "No.",
                width: "80px",
                orderable: false,
                template: this.numberTemplate,
            },
            {
                id: "customerNameDisplay",
                name: "customerNameDisplay",
                label: "Customer",
                responsiveLabel: "Customer",
                orderable: false,
                template: this.customerNameDisplayTemplate,
            },
            {
                id: "siteNameDisplay",
                name: "siteNameDisplay",
                label: "Customer Site",
                responsiveLabel: "Customer Site",
                orderable: false,
                template: this.siteNameDisplayTemplate,
            },
            {
                id: "reminderDescripton",
                name: "reminderDescripton",
                label: "Description",
                responsiveLabel: "Description",
                width: "480px",
                orderable: true,
                template: this.reminderDescriptonTemplate,
            },
            {
                id: "reminderDate",
                name: "reminderDate",
                label: "Reminder Date",
                responsiveLabel: "Reminder Date",
                orderable: true,
                template: this.reminderDateTemplate,
            },
            {
                id: "updatedBy",
                name: "updatedBy",
                label: "Last update by",
                responsiveLabel: "Last update by",
                orderable: true,
                template: this.updatedBy,
            },
            {
                id: "statusDisplay",
                name: "status",
                label: "Current Status",
                responsiveLabel: "Current Status",
                orderable: true,
            }
        ];

        let initColumns = Array.from(columns);
        columns = Array.from(initColumns);

        let columnVisibility = [
            false,
            true,
            true,
            true,
            true,
            false,
            true,
            true
        ];
        let initColumnVisibility = Array.from(columnVisibility);
        columnVisibility = Array.from(initColumnVisibility);

        this.state = {
            data: null,
            err: null,
            isError: false,
            isLoading: false,

            dateRange: Utils.getDateRangeValue("Today"),
            fromDate: moment().startOf('day').format(Utils.getAPIDateFormat()),
            toDate: moment().endOf('day').format(Utils.getAPIDateFormat()),

            search: '',
            sortColumn: 'created',
            sortDir: 'desc',
            sortColumnInit: 'created',
            sortDirInit: 'desc',

            selected: [],
            columnVisibility: columnVisibility,
            initColumnVisibility: initColumnVisibility,
            columns: columns,
            initColumns: initColumns,
            rows: [],
            currentPage: 1,
            totalRows: 0,
            rowsPerPageSelected: 50,
            rowsPerPageOptions: [50, 100, 300],

            isDisabledBatchAction: true,
            batchActionLoading: false,
            
            isIncludeInactive: false,

            isLoadingFormDrawer: false,
            isVisibleFormDrawer: false,
            isBlockingFormDrawer: false,
            rowInitialValues: {
                isEdit: false,
                isActive: true,
                customerId: null,
                customerName: '',
                customerSiteId: null,
                customerSiteName: '',
                reminderDescripton: '',
                reminderDate: null,
                userId: null,
                status: false,
                customFormParams: {
                    customerItems: [],
                    customerIsLoading: false,
                    customerSiteItems: [],
                    customerSiteIsLoading: false,
                    userItems: [],
                    userIsLoading: false,
                    userIsSelectAll: false,
                }
            },
            rowValues: null,
        }
    }

    componentDidMount() {
        this.initDrawers();

        this.getFilterInLocalStorage(() => {
            this.callReadApi();
        });
    }

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }

    /* FUNCTIONS */
    onWindowResize = () => {
        this.getFilterInLocalStorage();
    }

    setFilterInLocalStorage = (isCookies = true) => {
        if (this.props.settingsType) {
            let { token, userCookieSettings } = this.props.auth;

            this.setState({
                isCookies: isCookies,
            });

            let data = {
                name: this.pageName,
                type: this.props.settingsType,
            };

            let valueLocalStorage = {
                [PageCookieSettingType.currentPage]: this.state.currentPage,
                [PageCookieSettingType.fromDate]: this.state.fromDate,
                [PageCookieSettingType.toDate]: this.state.toDate,
            };
            window.localStorage.setItem(this.pageName + '_' + this.props.settingsType, JSON.stringify(valueLocalStorage));

            let value = {
                [PageCookieSettingType.isCookies]: isCookies,
                [PageCookieSettingType.sortColumn]: this.state.sortColumn,
                [PageCookieSettingType.sortDir]: this.state.sortDir,
                // [PageCookieSettingType.currentPage]: this.state.currentPage,
                [PageCookieSettingType.rowsPerPageSelected]: this.state.rowsPerPageSelected,
                // [PageCookieSettingType.fromDate]: this.state.fromDate,
                // [PageCookieSettingType.toDate]: this.state.toDate,
                [PageCookieSettingType.columnVisibility]: this.state.columnVisibility,
                [PageCookieSettingType.columns]: this.state.columns,
                [PageCookieSettingType.isIncludeInactive]: this.state.isIncludeInactive,
            };
            data['value'] = JSON.stringify(value);

            Utils.saveUserCookieSettings(data, token, (settings) => {
                if (settings) {
                    let settingsArr = (userCookieSettings && userCookieSettings.length > 0) ? userCookieSettings : [];
                    let settingsIndex = Utils.getUserCookieSettingsIndex(settingsArr, this.props.settingsType);
                    if (settingsIndex !== -1 && settingsArr[settingsIndex]) {
                        settingsArr[settingsIndex] = settings;
                    } else {
                        settingsArr.push(settings);
                    }

                    this.props.saveUserCookieSettings(settingsArr)
                }
            });

            // apiUtil.deleteUserCookieSettings({ name: this.pageName, type: this.props.settingsType }, token);
            // apiUtil.deleteAllUserCookieSettings(token);
        }
    };

    getFilterInLocalStorage = (callback = null) => {
        let settingsItem = null;

        if (this.props.settingsType) {
            const { userCookieSettings } = this.props.auth;

            settingsItem = Utils.getUserCookieSettings(userCookieSettings, this.props.settingsType, this.pageName);
        }

        let valueLocalStorageStr = window.localStorage.getItem(this.pageName + '_' + this.props.settingsType);
        let valueLocalStorage = JSON.parse(valueLocalStorageStr);
        let currentPageValue = valueLocalStorage && valueLocalStorage[PageCookieSettingType.currentPage] && valueLocalStorage[PageCookieSettingType.currentPage] !== "" ? valueLocalStorage[PageCookieSettingType.currentPage] : this.state.currentPage;
        let fromDateValue = (valueLocalStorage && valueLocalStorage[PageCookieSettingType.fromDate] && valueLocalStorage[PageCookieSettingType.fromDate] !== '') ? valueLocalStorage[PageCookieSettingType.fromDate] : this.state.fromDate;
        let toDateValue = (valueLocalStorage && valueLocalStorage[PageCookieSettingType.toDate] && valueLocalStorage[PageCookieSettingType.toDate] !== '') ? valueLocalStorage[PageCookieSettingType.toDate] : this.state.toDate;

        let isCookiesValue = settingsItem && settingsItem[PageCookieSettingType.isCookies] && settingsItem[PageCookieSettingType.isCookies] !== "" ? settingsItem[PageCookieSettingType.isCookies] : this.state.isCookies;
        let sortColumnValue = settingsItem && settingsItem[PageCookieSettingType.sortColumn] && settingsItem[PageCookieSettingType.sortColumn] !== "" ? settingsItem[PageCookieSettingType.sortColumn] : this.state.sortColumn;
        let sortDirValue = settingsItem && settingsItem[PageCookieSettingType.sortDir] && settingsItem[PageCookieSettingType.sortDir] !== "" ? settingsItem[PageCookieSettingType.sortDir] : this.state.sortDir;
        let rowsPerPageSelectedValue = settingsItem && settingsItem[PageCookieSettingType.rowsPerPageSelected] && settingsItem[PageCookieSettingType.rowsPerPageSelected] !== "" ? settingsItem[PageCookieSettingType.rowsPerPageSelected] : this.state.rowsPerPageSelected;
        let columnVisibilityValue = (settingsItem && settingsItem[PageCookieSettingType.columnVisibility] && settingsItem[PageCookieSettingType.columnVisibility] !== '') ? settingsItem[PageCookieSettingType.columnVisibility] : this.state.columnVisibility;
        let columnsValue = (settingsItem && settingsItem[PageCookieSettingType.columns] && settingsItem[PageCookieSettingType.columns] !== '') ? settingsItem[PageCookieSettingType.columns] : this.state.columns;

        let columns = Utils.setTemplateFunctionsToColumns(this.state.columns, columnsValue);
        let dateRange = [
            moment(fromDateValue).toDate(),
            moment(toDateValue).toDate(),
        ];

        this.setState({
            isCookies: isCookiesValue,
            sortColumn: sortColumnValue,
            sortDir: sortDirValue,
            currentPage: currentPageValue,
            rowsPerPageSelected: rowsPerPageSelectedValue,
            fromDate: fromDateValue,
            toDate: toDateValue,
            columnVisibility: columnVisibilityValue,
            columns: columns,
            dateRange: dateRange,
        }, () => {
            if (callback) {
                callback();
            }
        });
    };

    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            'reminder-list',
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let rows = (data && data.data && data.data.length > 0) ? data.data : [];
                let totalRows = (data && data.total) ? data.total : 0;

                this.setState({
                    rows: rows,
                    totalRows: totalRows,
                    isDisabledBatchAction: totalRows <= 0,
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    if (callback) {
                        callback();
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-details',
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isBlockingFormDrawer: false,
                    details: data,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    if (this.state.isError) {
                        Utils.showDrawer(this.formDrawer, false);
                    }

                    if (callback) {
                        callback(data);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-create',
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoadingFormDrawer: false,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    if (this.state.isError) {
                    } else {
                        Utils.showDrawer(this.formDrawer, false);
                    }

                    if (callback) {
                        callback(data);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-update',
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoadingFormDrawer: false,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    if (this.state.isError) {
                    } else {
                        Utils.showDrawer(this.formDrawer, false);
                    }

                    if (callback) {
                        callback(data);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-delete',
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoadingFormDrawer: false,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    if (this.state.isError) {
                    } else {
                        Utils.showDrawer(this.formDrawer, false);
                    }

                    if (callback) {
                        callback(data);
                    }
                });
            }
        );
    }

    initDrawers = () => {
        Utils.initDrawers(() => {
            this.formDrawer = Utils.getInstanceDrawer('form');
            Utils.onShowDrawer(this.formDrawer, null, () => {
                this.setState({
                    isVisibleFormDrawer: true,
                });
            });
            Utils.onHideDrawer(this.formDrawer, this.refFormDrawer, () => {
                this.setState({
                    isVisibleFormDrawer: false,
                });
            });
        });
    }

    setDataTable = () => {
        return <DataTable
            ref={this.tableRef}

            className={"table-style-v4"}
            classNameWrapper={"table-v4"}

            pageName={this.pageName}
            rowId={this.pageIdField}
            caption={this.pageCaption}

            noMarginBottom={true}
            verticalAlign={'middle'}

            stickyHeader={false}
            isHeader={true}
            resizable={true}
            isDragableHeaderColumns={true}
            isDragable={false}
            isBorder={false}
            isPagination={false}

            disabledResizeColumns={[0]}
            columnVisibility={this.state.columnVisibility}

            isLoading={this.state.isLoading}
            rowsPerPageSelected={this.state.rowsPerPageSelected}
            rowsPerPageOptions={this.state.rowsPerPageOptions}

            search={this.state.search}
            rows={this.state.rows}
            columns={this.state.columns}

            onRowClick={(id, row) => {
                let dataParams = {
                    id: id,
                    row: row,
                };

                Utils.setDrawerData(this.formDrawer, dataParams);
                Utils.showDrawer(this.formDrawer);
            }}

            onRemove={(row) => {

            }}

            onSortEnd={(columns, columnVisibility) => {
                this.setState({
                    columns: columns,
                    columnVisibility: columnVisibility,
                }, () => {
                    this.setFilterInLocalStorage();
                });
            }}
            onOrder={(name, sort) => {
                this.setState({
                    sortColumn: name,
                    sortDir: sort,
                }, () => {
                    this.setFilterInLocalStorage();
                    this.callReadApi(false, () => {
                        this.setState({
                            totalRows: 0,
                            rows: [],
                            isLoading: false,
                        });
                    });
                });
            }}
            onResize={(columns) => {
                if(columns){
                  this.setState({
                    columns: columns
                  });
                }
                this.setFilterInLocalStorage();
            }}
        />
    }

    prepareForm = (dataParams, data) => {
        if (data) {
            let customerItem = (data && data.customer) ? data.customer : null;
            let customerId = (customerItem && customerItem.customerId) ? customerItem.customerId : null;
            let customerName = (customerItem && customerItem.customerName) ? customerItem.customerName : '';
            let customerItems = (customerItem) ? [{
                value: customerId,
                title: customerName,
                item: customerItem,
            }] : [];


            let customerSiteItem = (data && data.customerSite) ? data.customerSite : null;
            let customerSiteId = (customerSiteItem && customerSiteItem.customerSiteId) ? customerSiteItem.customerSiteId : null;
            let customerSiteName = (customerSiteItem && customerSiteItem.siteName) ? customerSiteItem.siteName : '';
            let customerSiteItems = (customerSiteItem) ? [{
                value: customerSiteId,
                title: customerSiteName,
                item: customerSiteItem,
            }] : [];


            let userId = [];
            let userItems = [];
            let accountReminderUsers = (data && data.accountReminderUsers) ? data.accountReminderUsers : [];
            if(accountReminderUsers && accountReminderUsers.length > 0){
                userItems = accountReminderUsers.map((item, i) => {
                    userId.push(item.userId);

                    return {
                        value: item.user.userId,
                        title: item.user.name,
                        item: item.user,
                    }
                });
            }


            let status = (data.status == 1) ? true : false;


            let customFormParams = this.state.rowInitialValues.customFormParams;
            customFormParams.customerItems = customerItems;
            customFormParams.customerSiteItems = customerSiteItems;
            customFormParams.userItems = userItems;
            customFormParams.isApplyAllUserTimes = (userId && userId.length > 0) ? false : true;

            let rowValues = {
                isEdit: true,
                customerId: (data && data.customerId) ? data.customerId : null,
                customerSiteId: (data && data.customerSiteId) ? data.customerSiteId : null,
                reminderDescripton: (data && data.reminderDescripton && data.reminderDescripton !== '') ? data.reminderDescripton : '',
                reminderDate: data.reminderDate ? new Date(data.reminderDate) : new Date(),
                userId: userId,
                status: status,
                customFormParams: customFormParams,
            };

            return rowValues;
        } else {
            return this.state.initialValues;
        }
    }

    prepareData = (form, dataParams) => {
        let accountReminderUsers = (form && form.userId && form.userId.length > 0) ? form.userId.map(x => ({ userid: x })) : '';
        
        let status = (form.status == true) ? 1 : 0;

        let data = {
            [this.pageIdField]: dataParams.id,
            customerId: form.customerId,
            customerSiteId: form.customerSiteId,
            reminderDescripton: form.reminderDescripton,
            reminderDate: form.reminderDate,
            accountReminderUsers: accountReminderUsers,
            status: status,
            isActive: true,
        };

        return data;
    }
    /* END FUNCTIONS */


    /* API */
    callReadApi = (withStatusCall = true, callback = null) => {
        let fromDate = moment(this.state.fromDate).format(Utils.getAPIDateFormat());
        let toDate = moment(this.state.toDate).format(Utils.getAPIDateFormat());

        this.setState({
            isLoading: true,
        });

        let data = {
            currentPage: this.state.currentPage,
            pageSize: this.state.rowsPerPageSelected,
            searchQuery: this.state.search,
            fromDate: fromDate,
            toDate: toDate,
            sortColumn: this.state.sortColumn,
            sortDir: this.state.sortDir,
            isIncludeInactive: ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : false,
        };

        this.props.dispatchApiCallGet(data, 'reminder-list', 'accountreminder', null, null, callback);
    }

    callDetailsApi = (id = null, callback = null) => {
        if (id) {
            this.setState({
                isBlockingFormDrawer: true,
                details: null,
            });

            this.props.dispatchApiCallGet(null, this.pageName + '-details', this.pageAPI + '/' + id, null, callback, () => { });
        } else {
            if (callback) {
                callback(null)
            }
        }
    }

    callCreateApi = (data, callback = null) => {
        this.setState({
            isLoadingFormDrawer: true,
        });

        this.props.dispatchApiCallPost(data, this.pageName + '-create', this.pageAPI, data, callback, null);
    }

    callUpdateApi = (data, callback = null) => {
        this.setState({
            isLoadingFormDrawer: true,
        });

        this.props.dispatchApiCallPut(data, this.pageName + '-update', this.pageAPI, data, callback, null);
    }

    callDeleteApi = (data, callback = null) => {
        this.setState({
            isLoadingFormDrawer: true,
        });

        this.props.dispatchApiCallDelete(data, this.pageName + '-delete', this.pageAPI, data, callback, null);
    }
    /* END API */


    /* SECTIONS */
    topSection = () => {
        return <ViewTopSection
            fromDate={this.state.fromDate}
            toDate={this.state.toDate}
            dateRange={this.state.dateRange}
            isDisabledBatchAction={this.state.isDisabledBatchAction}
            batchActionLoading={this.state.batchActionLoading}

            onDateChange={(fromDate, toDate, dateRange) => {
                this.setState({
                    fromDate: fromDate,
                    toDate: toDate,
                    dateRange: dateRange,
                }, () => {
                    this.setFilterInLocalStorage();
                    this.callReadApi();
                });
            }}

            onCreate={() => {
                let dataParams = {
                    id: 0,
                    row: null,
                };
                Utils.setDrawerData(this.formDrawer, dataParams);
                Utils.showDrawer(this.formDrawer);
            }}
        />
    }

    toolbarSection = () => {
        return <Row>
            <Col className={'text-center text-md-end'}>
              <div className='d-inline-flex align-items-center text-start'>
                <ExpandingSearchBox
                    btnClassName={'manage-icon-btn'}
                    value={this.state.search}
                    isOpen={(this.state.search !== '') ? true : false}
                    onPressEnter={(value) => {
                        this.setState({
                            search: value,
                            currentPage: 1,
                        }, () => {
                            this.setFilterInLocalStorage();
                            this.callReadApi(true, () => {
                                this.setState({
                                    totalRows: 0,
                                    rows: [],
                                    isLoading: false
                                });
                            });
                        });
                    }}
                    onClear={() => {
                        this.setState({
                            search: '',
                            currentPage: 1,
                        }, () => {
                            this.setFilterInLocalStorage();
                            this.callReadApi(true, () => {
                                this.setState({
                                    totalRows: 0,
                                    rows: [],
                                    isLoading: false
                                });
                            });
                        });
                    }}
                />
                </div>
                {Utils.isMobileTablet() && <div className='col-12 d-block d-md-none'></div>}
                <div className='d-inline-flex align-items-center text-start'>
                <DropdownIconButtonMenu
                    ref={this.refDropdownActionMenu}
                    className={'job-list-icon-button-dropdown'}
                    selected={null}
                    icon={<SettingsIcon />}
                >
                    <DataTableActionMenu
                        pageName={this.pageName}
                        columns={this.state.columns}
                        selected={this.state.rowsPerPageSelected}
                        selectItems={this.state.rowsPerPageOptions}
                        columnVisibility={this.state.columnVisibility}
                        // isCheckedIncludeInactive={this.state.isIncludeInactive}
                        onChangeColumnVisibility={(index, value) => {
                            let colVis = this.state.columnVisibility;
                            colVis[index] = value;

                            this.setState({
                                columnVisibility: colVis
                            }, () => {
                                this.setFilterInLocalStorage();
                            });
                        }}
                        canInactive={false}
                        // onChangeIncludeInactive={(val) => {
                        //   this.setState({
                        //     isIncludeInactive: val,
                        //     currentPage: 1,
                        //   }, () => {
                        //     this.setFilterInLocalStorage();
                        //     this.callReadApi();
                        //   });
                        // }}
                        onSelectChangeAjax={(val) => {
                            this.setState({
                                currentPage: 1,
                                rowsPerPageSelected: val
                            }, () => {
                                this.setFilterInLocalStorage();
                                this.callReadApi();
                            });
                        }}
                        isCookies={this.state.isCookies}
                        onResetColumns={() => {
                            this.setState({
                                columns: Array.from(this.state.initColumns),
                                columnVisibility: Array.from(this.state.initColumnVisibility),
                                sortColumn: this.state.sortColumnInit,
                                sortDir: this.state.sortDirInit,
                            }, () => {
                                this.setFilterInLocalStorage(false);
                                this.callReadApi();
                                if (this.refDropdownActionMenu && this.refDropdownActionMenu.current) {
                                    this.refDropdownActionMenu.current.hide();
                                }
                            });
                        }}
                        onShowMore={(isShowedMore) => {
                            this.setState({
                                pageName: this.pageName,
                            });
                        }}
                    />
                </DropdownIconButtonMenu>
                </div>
            </Col>
        </Row>
    }

    tableSection = () => {
        return <DataTableScrollSync ref={this.refDataTableScrollSync} className={"table-sync-v4"}>{this.setDataTable()}</DataTableScrollSync>
    }

    paginationSection = () => {
        return <DataTableAjaxPagination
            page={this.state.currentPage}
            selected={this.state.rowsPerPageSelected}
            count={this.state.totalRows}
            onPageChange={(page) => {
                this.setState({
                    currentPage: page,
                }, () => {
                    this.setFilterInLocalStorage();
                    this.callReadApi(false, () => {
                        this.setState({
                            totalRows: 0,
                            rows: [],
                            isLoading: false
                        });
                    });
                });
            }}
        />
    }

    formDrawerSection = () => {
        return <DrawerLayout
            name={'form'}
            ref={this.refFormDrawer}
            drawer={this.formDrawer}
            validationSchema={formSchema}
            initialValues={this.state.rowInitialValues}
            values={this.state.rowValues}
            isVisible={this.state.isVisibleFormDrawer}
            isBlocking={Utils.isBlockingDrawer(this.state.isLoadingFormDrawer, this.state.isBlockingFormDrawer)}
            renderChildren={Utils.shouldDrawerRenderChildren(this.state.isLoadingFormDrawer, this.state.isBlockingFormDrawer)}

            title={(data) => {
                if (data) {
                    return <div className={'d-flex align-items-center'}>
                        <span className={'pe-2'}>{(data.id > 0) ? 'Update Reminder' : 'Create Reminder'}</span>
                    </div>;
                }
            }}
            toolbar={null}

            onOpenClose={(isOpen, dataParams) => {
                if (isOpen) {
                    this.callDetailsApi(dataParams.id, (data) => {
                        let rowValues = this.prepareForm(dataParams, data);

                        this.setState({
                            rowValues: rowValues,
                        });
                    });
                }
            }}
            onSubmit={(form, dataParams) => {
                let data = this.prepareData(form, dataParams);
                if (form.isEdit) {
                    this.callUpdateApi(data, (res) => {
                        this.callReadApi();
                    });
                } else {
                    this.callCreateApi(data, (res) => {
                        this.callReadApi();
                    });
                }
            }}
        >
            {(formOptions, dataParams, isBlocking) => {
                return <ReminderForm
                    drawer={this.formDrawer}
                    fields={formFields}
                    initialValues={this.state.rowInitialValues}
                    values={this.state.rowValues}
                    formOptions={formOptions}
                    dataParams={dataParams}
                    isBlocking={isBlocking}
                    onDelete={(dataParams) => {
                        let data = {
                            [this.pageIdField]: dataParams.id
                        };
                        this.callDeleteApi(data, (res) => {
                            this.callReadApi();
                        });
                    }}
                />
            }}
        </DrawerLayout>
    }
    /* END SECTIONS */


    render() {
        return <div className={'alert-page'}>
            <ViewLayout
                isBlocking={this.state.batchActionLoading}
                topSection={this.topSection()}
                toolbarSection={this.toolbarSection()}
                tableSection={this.tableSection()}
                paginationSection={this.paginationSection()}
            />
            {this.formDrawerSection()}
        </div>
    }
}

const mapDispatchToProps = {
    dispatchApiCallGet,
    dispatchApiCallPost,
    dispatchApiCallPut,
    dispatchApiCallDelete,
    saveUserCookieSettings,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ReminderList);
