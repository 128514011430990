import React from 'react';

import { 
  Container,
  Spinner,
} from "react-bootstrap-v5";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';


const ViewLayout = React.forwardRef((props, ref) => {
  
  return <BlockUi 
    className={!props.renderChildren ? 'custom-block-ui' : ''} 
    tag={'div'} 
    keepInView 
    blocking={props.isBlocking}
    renderChildren={props.renderChildren} 
    loader={<Spinner animation="border" />}
  >
    <div className={'view-layout'}>
      <div className={'sections'}>
        {props.topSection && <div className={'custom-top-section shadow-sm'}
          style={{ 
            top: 65 + 'px',
            zIndex: '2'
          }}
        >
          <Container className={'py-8'}>{props.topSection}</Container>
        </div>}

        <div className={'bg-color ps-5 pe-5 container'}>
          <div className={'bg-color ps-5 pe-5'}>
              <Container className={'pt-5 pb-5 px-0'}>{props.contentSection}</Container>
          </div>
        </div>
      </div>
    </div>
  </BlockUi>
});

export default ViewLayout;