import React from "react";

import {
    Row,
    Col,
    Form,
    InputGroup,
} from "react-bootstrap-v5";

import { Formik } from 'formik';
import * as yup from 'yup';

import Utils from "../../../utils/utils";
import { ActiveInactive } from "../../../utils/enums";
import { BillingTypes } from "../../../utils/enums";

import RSuiteSelectPicker from '../../../components/OLD/Select/RSuiteSelectPicker';
import makeCRUD from "../../../components/dropdown/smartInputDropdown/makeCRUD";
import InputLayout from "../../../components/input/InputLayout";
import SmartInputDropdown from '../../../components/dropdown/smartInputDropdown/SmartInputDropdown';

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut, dispatchApiCallDelete } from '../../../../setup/redux/dispatch/actions'


const SmartDropdown = makeCRUD(SmartInputDropdown);


const formFields = {
    isActive: {
        id: 'isActive',
        label: 'Status',
        placeholder: ' ',
    },
    price: {
        id: 'price',
        label: 'Rate',
        placeholder: ' ',
    },
    billingType: {
        id: 'billingType',
        label: 'Billing Type',
        placeholder: ' ',
    },
    chargeCategoryId: {
        id: 'chargeCategoryId',
        label: 'Billing Category',
        placeholder: ' ',
    },
}
const formSchema = yup.object().shape({
    isEdit: yup.bool().oneOf([true, false]),
    isActive: yup.bool().nullable().oneOf([null, true, false]),
    billingType: yup.number().nullable().label(formFields.billingType.label),
    price: yup.number().nullable().min(0).label(formFields.price.label),
    chargeCategoryId: yup.number().nullable().label(formFields.chargeCategoryId.label),
});


class BulkUpdateGlobalServiceForm extends React.Component {

    constructor(props) {
        super(props);

        this.pageName = 'global_service_bulk_update';

        this.refForm = React.createRef();

        let id = (props.id) ? props.id : null;


        this.state = {
            id: id,

            initialValues: {
                isEdit: false,
                isActive: true,
                billingType: null,
                price: '',
                chargeCategoryId: null,
                chargeCategoryName: '',
                customFormParams: {
                    chargeCategoryItems: [],
                    serviceTagItems: [],
                    serviceTagIsLoading: false,
                }
            },
        };
    }


    componentDidMount() {
        this.init();
    }

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
    }
    /* END API */


    /* FUNCTIONS */
    setLoading = (state = false) => {
        if (this.props.onLoading) {
            this.props.onLoading(state);
        }
    }
    init = () => {
        if (this.refForm && this.refForm.current) {
            this.setLoading(true);

            this.setLoading(false);
        }
    }
    prepareData = (form) => {
        let data = {
            isActive: form.isActive,
            price: form.price,
            billingType: form.billingType,
            chargeCategoryId: form.chargeCategoryId
        };


        if (this.state.id) {
            data['ids'] = this.state.id;
        }

        return data;
    }
    setSubmit = (form) => {
        if (this.props.onSubmit) {
            this.props.onSubmit(form);
        }
    }
    /* END FUNCTIONS */


    /* FORM */
    form = (formOptions) => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = formOptions;

        return <>

            <InputLayout
                label={formFields.isActive.label}
            >
                <Form.Control
                    as={SmartInputDropdown}
                    isInvalid={false}
                    errorText={''}

                    value={values.isActive}
                    label={values.isActive}
                    options={[
                        {
                            value: ActiveInactive.InactiveValue,
                            title: ActiveInactive.InactiveTitle,
                            color: '#FF0090',
                        },
                        {
                            value: ActiveInactive.ActiveValue,
                            title: ActiveInactive.ActiveTitle,
                            color: '#185cff',
                        }
                    ]}
                    placeholder={formFields.isActive.placeholder}
                    disabled={false}
                    isLoading={false}
                    showSearch={false}
                    showClear={false}
                    showFooter={false}
                    isInfiniteScroll={false}

                    onChange={async (value, item, i) => {
                        await setFieldValue('isActive', value);
                    }}
                />
            </InputLayout>

            <InputLayout
                label={formFields.price.label}
            >
                <Form.Control
                    type="number"
                    id={formFields.price.id}
                    placeholder={formFields.price.placeholder}
                    value={values.price}
                    onChange={handleChange}
                    isInvalid={!!errors.price}
                />
                <Form.Control.Feedback type="invalid">{errors.price}</Form.Control.Feedback>
            </InputLayout>

            <InputLayout
                label={formFields.billingType.label}
            >
                <Form.Control
                    as={SmartInputDropdown}
                    isInvalid={false}
                    errorText={''}

                    value={values.billingType}
                    label={values.billingType}
                    options={[
                        {
                            value: BillingTypes.Invoice,
                            title: 'Invoice',
                        },
                        {
                            value: BillingTypes.Purchase,
                            title: 'Purchase',
                        },
                        {
                            value: BillingTypes.Expense,
                            title: 'Expense',
                        }
                    ]}
                    placeholder={formFields.billingType.placeholder}
                    disabled={false}
                    showDefault={false}
                    isLoading={false}
                    showSearch={false}
                    showClear={true}
                    showFooter={false}
                    isInfiniteScroll={false}

                    onChange={async (value, item, i) => {
                        await setFieldValue('billingType', value);
                    }}
                    onClear={async () => {
                        await setFieldValue('billingType', null);
                    }}
                />
            </InputLayout>

            <InputLayout
                label={formFields.chargeCategoryId.label}
            >
                <SmartDropdown
                    isInvalid={!!errors.chargeCategoryId}
                    errorText={errors.chargeCategoryId}

                    componentTitle={'chargeCategory'}
                    componentApi={'chargeCategory'}
                    componentId={'chargeCategoryId'}
                    componentName={'chargeCategoryName'}

                    placeholder={formFields.chargeCategoryId.placeholder}
                    value={values.chargeCategoryId}
                    label={values.chargeCategoryName}

                    disabled={false}
                    showDefault={false}
                    showFooter={false}
                    isInfiniteScroll={false}

                    onChange={async (value, item, i) => {
                        await setFieldValue('chargeCategoryId', value);
                        await setFieldValue('chargeCategoryName', item.title);
                    }}
                    onClear={async () => {
                        await setFieldValue('chargeCategoryId', null);
                        await setFieldValue('chargeCategoryName', '');
                    }}
                />
            </InputLayout>

        </>
    }
    /* END FORM */


    render() {
        return <Formik
            innerRef={this.refForm}
            validationSchema={formSchema}
            initialValues={this.state.initialValues}
            enableReinitialize={true}
            validateOnMount={false}
            validateOnChange={false}
            onSubmit={(form, e) => {
                this.setLoading(true);
                let data = this.prepareData(form);
                this.setSubmit(data);
            }}
        >
            {(formOptions) => {
                return <Form className='w-100 p-5' noValidate autoComplete="off" onSubmit={formOptions.handleSubmit} onKeyDown={(keyEvent) => {
                    // if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                    //     keyEvent.preventDefault();
                    // }
                }}>
                    <Row>
                        <Col xs={12} className={'ps-2 pe-2'}>{this.form(formOptions)}</Col>
                    </Row>
                </Form>
            }}
        </Formik>
    }
}

const mapDispatchToProps = {
    dispatchApiCallGet,
    dispatchApiCallPost,
    dispatchApiCallPut,
    dispatchApiCallDelete,
    clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(BulkUpdateGlobalServiceForm);
