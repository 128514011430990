import React from "react";

import { 
  StyledAUnbilled, StyledABilled, StyledANonBillable, StyledAPaid,
  StyledDivUnbilled, StyledDivBilled, StyledDivNonBillable, StyledDivPaid
} from '../../../utils/styles';

import Utils from '../../../utils/utils';


const paymentTermsTemplate = (id, row, col, obj, index) => {
return (row.customer && row.customer.paymentTerms) ? row.customer.paymentTerms : '';
};
const paymentTermsExportTemplate = (row) => {
return (row.customer && row.customer.paymentTerms) ? row.customer.paymentTerms : '';
};
const paymentTermsExportCSVTemplate = (row) => {
return (row.customer && row.customer.paymentTerms) ? row.customer.paymentTerms : '';
};

const creditLimitTemplate = (id, row, col, obj, index) => {
return (row.customer && row.customer.creditLimit) ? Utils.getCurrency() + Utils.formatNumber(row.customer.creditLimit) : 'Unlimited';
};
const creditLimitExportTemplate = (row) => {
return (row.customer && row.customer.creditLimit) ? Utils.getCurrency() + Utils.formatNumber(row.customer.creditLimit) : 'Unlimited';
};
const creditLimitExportCSVTemplate = (row) => {
return (row.customer && row.customer.creditLimit) ? Utils.getCurrency() + Utils.formatNumber(row.customer.creditLimit) : 'Unlimited';
};

const billingProfileNameTemplate = (id, row, col, obj, index) => {
return (row.customer && row.customer.billingProfileName) ? row.customer.billingProfileName : '';
};
const billingProfileNameExportTemplate = (row) => {
return (row.customer && row.customer.billingProfileName) ? row.customer.billingProfileName : '';
};
const billingProfileNameExportCSVTemplate = (row) => {
return (row.customer && row.customer.billingProfileName) ? row.customer.billingProfileName : '';
};

const unbilledJobsCountTextTemplate = (id, row, col, obj, index) => {
return <StyledDivUnbilled>
  {(row.unbilledJobsCount) ? row.unbilledJobsCount : '0'}
</StyledDivUnbilled>
};
const unbilledJobsCountTemplate = (id, row, col, obj, index) => {
  let field = 'unbilledActive';
  let value = ((row[field] == false) || (row[field] == true)) ? row[field] : false;
  
return <StyledAUnbilled href={'/'}
  active={value}
  onClick={(e) => {
    e.stopPropagation();
    e.preventDefault();
    
    obj.handleColumnClick(id, row, col, obj, index, field, value);
  }}
>
  {(row.unbilledJobsCount) ? row.unbilledJobsCount : '0'}
</StyledAUnbilled>
};
const unbilledJobsCountPurchaseTemplate = (id, row, col, obj, index) => {
  let field = 'unbilledPurchaseActive';
  let value = ((row[field] == false) || (row[field] == true)) ? row[field] : false;
  
return <StyledAUnbilled href={'/'}
  active={value}
  onClick={(e) => {
    e.stopPropagation();
    e.preventDefault();
    
    obj.handleColumnClick(id, row, col, obj, index, field, value);
  }}
>
  {(row.unbilledJobsCount) ? row.unbilledJobsCount : '0'}
</StyledAUnbilled>
};
const unbilledJobsCountExpenseTemplate = (id, row, col, obj, index) => {
  let field = 'unbilledExpenseActive';
  let value = ((row[field] == false) || (row[field] == true)) ? row[field] : false;
  
return <StyledAUnbilled href={'/'}
  active={value}
  onClick={(e) => {
    e.stopPropagation();
    e.preventDefault();
    
    obj.handleColumnClick(id, row, col, obj, index, field, value);
  }}
>
  {(row.unbilledJobsCount) ? row.unbilledJobsCount : '0'}
</StyledAUnbilled>
};
const unbilledJobsCountExportTemplate = (row) => {
return (row.unbilledJobsCount) ? row.unbilledJobsCount : '0';
};
const unbilledJobsCountExportCSVTemplate = (row) => {
return (row.unbilledJobsCount) ? row.unbilledJobsCount : '0';
};

const billedJobsCountTextTemplate = (id, row, col, obj, index) => {
return <StyledDivBilled>
  {(row.billedJobsCount) ? row.billedJobsCount : '0'}
</StyledDivBilled>
};
const billedJobsCountTemplate = (id, row, col, obj, index) => {
  let field = 'billedActive';
  let value = ((row[field] == false) || (row[field] == true)) ? row[field] : false;
  
return <StyledABilled href={'/'}
  active={value}
  onClick={(e) => {
    e.stopPropagation();
    e.preventDefault();
    
    obj.handleColumnClick(id, row, col, obj, index, field, value);
  }}
>
  {(row.billedJobsCount) ? row.billedJobsCount : '0'}
</StyledABilled>
};
const billedJobsCountPurchaseTemplate = (id, row, col, obj, index) => {
  let field = 'billedPurchaseActive';
  let value = ((row[field] == false) || (row[field] == true)) ? row[field] : false;
  
return <StyledABilled href={'/'}
  active={value}
  onClick={(e) => {
    e.stopPropagation();
    e.preventDefault();
    
    obj.handleColumnClick(id, row, col, obj, index, field, value);
  }}
>
  {(row.billedJobsCount) ? row.billedJobsCount : '0'}
</StyledABilled>
};
const billedJobsCountExpenseTemplate = (id, row, col, obj, index) => {
  let field = 'billedExpenseActive';
  let value = ((row[field] == false) || (row[field] == true)) ? row[field] : false;
  
return <StyledABilled href={'/'}
  active={value}
  onClick={(e) => {
    e.stopPropagation();
    e.preventDefault();
    
    obj.handleColumnClick(id, row, col, obj, index, field, value);
  }}
>
  {(row.billedJobsCount) ? row.billedJobsCount : '0'}
</StyledABilled>
};
const billedJobsCountExportTemplate = (row) => {
return (row.billedJobsCount) ? row.billedJobsCount : '0';
};
const billedJobsCountExportCSVTemplate = (row) => {
return (row.billedJobsCount) ? row.billedJobsCount : '0';
};

const nonBillableJobsCountTextTemplate = (id, row, col, obj, index) => {
return <StyledDivNonBillable>
  {(row.nonBillableJobsCount) ? row.nonBillableJobsCount : '0'}
</StyledDivNonBillable>
};
const nonBillableJobsCountTemplate = (id, row, col, obj, index) => {
  let field = 'nonBillableActive';
  let value = ((row[field] == false) || (row[field] == true)) ? row[field] : false;
  
return <StyledANonBillable href={'/'}
  active={value}
  onClick={(e) => {
    e.stopPropagation();
    e.preventDefault();
    
    obj.handleColumnClick(id, row, col, obj, index,field, field);
  }}
>
  {(row.nonBillableJobsCount) ? row.nonBillableJobsCount : '0'}
</StyledANonBillable>
};
const nonBillableJobsCountPurchaseTemplate = (id, row, col, obj, index) => {
  let field = 'nonBillablePurchaseActive';
  let value = ((row[field] == false) || (row[field] == true)) ? row[field] : false;
  
return <StyledANonBillable href={'/'}
  active={value}
  onClick={(e) => {
    e.stopPropagation();
    e.preventDefault();
    
    obj.handleColumnClick(id, row, col, obj, index,field, field);
  }}
>
  {(row.nonBillableJobsCount) ? row.nonBillableJobsCount : '0'}
</StyledANonBillable>
};
const nonBillableJobsCountExpenseTemplate = (id, row, col, obj, index) => {
  let field = 'nonBillableExpenseActive';
  let value = ((row[field] == false) || (row[field] == true)) ? row[field] : false;
  
return <StyledANonBillable href={'/'}
  active={value}
  onClick={(e) => {
    e.stopPropagation();
    e.preventDefault();
    
    obj.handleColumnClick(id, row, col, obj, index,field, field);
  }}
>
  {(row.nonBillableJobsCount) ? row.nonBillableJobsCount : '0'}
</StyledANonBillable>
};
const nonBillableJobsCountExportTemplate = (row) => {
return (row.nonBillableJobsCount) ? row.nonBillableJobsCount : '0';
};
const nonBillableJobsCountExportCSVTemplate = (row) => {
return (row.nonBillableJobsCount) ? row.nonBillableJobsCount : '0';
};

const paidJobsCountTextTemplate = (id, row, col, obj, index) => {
return <StyledDivPaid>
  {(row.paidJobsCount) ? row.paidJobsCount : '0'}
</StyledDivPaid>
};
const paidJobsCountTemplate = (id, row, col, obj, index) => {
  let field = 'paidActive';
  let value = ((row[field] == false) || (row[field] == true)) ? row[field] : false;

return <StyledAPaid href={'/'}
  active={value}
  onClick={(e) => {
    e.stopPropagation();
    e.preventDefault();

    obj.handleColumnClick(id, row, col, obj, index, field, value);
  }}
>
  {(row.paidJobsCount) ? row.paidJobsCount : '0'}
</StyledAPaid>
};
const paidJobsCountExportTemplate = (row) => {
return (row.paidJobsCount) ? row.paidJobsCount : '0';
};
const paidJobsCountExportCSVTemplate = (row) => {
return (row.paidJobsCount) ? row.paidJobsCount : '0';
};

const noteTemplate = (id, row, col, obj, index) => {
return (row.customer && row.customer.note) ? row.customer.note : '';
};
const noteExportTemplate = (row) => {
return (row.customer && row.customer.note) ? row.customer.note : '';
};
const noteExportCSVTemplate = (row) => {
return (row.customer && row.customer.note) ? row.customer.note : '';
};

const billingNameTemplate = (id, row, col, obj, index) => {
return (row.customer && row.customer.billingName) ? row.customer.billingName : '';
};
const billingNameExportTemplate = (row) => {
return (row.customer && row.customer.billingName) ? row.customer.billingName : '';
};
const billingNameExportCSVTemplate = (row) => {
return (row.customer && row.customer.billingName) ? row.customer.billingName : '';
};

const billingAddressTemplate = (id, row, col, obj, index) => {
return (row.customer && row.customer.billingAddress) ? row.customer.billingAddress : '';
};
const billingAddressExportTemplate = (row) => {
return (row.customer && row.customer.billingAddress) ? row.customer.billingAddress : '';
};
const billingAddressExportCSVTemplate = (row) => {
return (row.customer && row.customer.billingAddress) ? row.customer.billingAddress : '';
};


export const initColumnsInvoice = [
    { 
      id: 'customerId', 
      filterKey: 'customerId', 
      name: 'customerId', 
      label: 'ID',
      visible: false
    },
    { 
      id: 'customerName', 
      filterKey: 'customerName', 
      name: 'customerName', 
      label: 'Customer name',
      width: '250px',
      orderable: true,
    },
    { 
      id: 'unbilledJobsCount', 
      filterKey: 'unbilledJobsCount', 
      name: 'unbilledJobsCount', 
      label: 'Unbilled',
      width: '150px',
      orderable: true,
      template: unbilledJobsCountTemplate,
      exportTemplate: unbilledJobsCountExportTemplate,
      exportCSVTemplate: unbilledJobsCountExportCSVTemplate,
    },
    { 
      id: 'billedJobsCount', 
      filterKey: 'billedJobsCount', 
      name: 'billedJobsCount', 
      label: 'Billed',
      width: '150px',
      orderable: true,
      template: billedJobsCountTemplate,
      exportTemplate: billedJobsCountExportTemplate,
      exportCSVTemplate: billedJobsCountExportCSVTemplate,
    },
    { 
      id: 'paidJobsCount', 
      filterKey: 'paidJobsCount', 
      name: 'paidJobsCount', 
      label: 'Paid',
      width: '150px',
      orderable: true,
      template: paidJobsCountTemplate,
      exportTemplate: paidJobsCountExportTemplate,
      exportCSVTemplate: paidJobsCountExportCSVTemplate,
    },
    { 
      id: 'nonBillableJobsCount', 
      filterKey: 'nonBillableJobsCount', 
      name: 'nonBillableJobsCount', 
      label: 'Non-Billable',
      width: '150px',
      orderable: true,
      template: nonBillableJobsCountTemplate,
      exportTemplate: nonBillableJobsCountExportTemplate,
      exportCSVTemplate: nonBillableJobsCountExportCSVTemplate,
    },
    { 
      id: 'customer.billingName', 
      filterKey: 'billingName', 
      name: 'billingName', 
      label: 'Billing name',
      width: '200px',
      orderable: false,
      template: billingNameTemplate,
      exportTemplate: billingNameExportTemplate,
      exportCSVTemplate: billingNameExportCSVTemplate,
    },
    { 
      id: 'customer.paymentTerms', 
      filterKey: 'paymentTerms', 
      name: 'customer.paymentTerms', 
      label: 'Payment terms',
      width: '250px',
      orderable: true,
      template: paymentTermsTemplate,
      exportTemplate: paymentTermsExportTemplate,
      exportCSVTemplate: paymentTermsExportCSVTemplate,
    },
    { 
      id: 'customer.creditLimit', 
      filterKey: 'creditLimit', 
      name: 'customer.creditLimit', 
      label: 'Credit limit',
      width: '150px',
      orderable: true,
      template: creditLimitTemplate,
      exportTemplate: creditLimitExportTemplate,
      exportCSVTemplate: creditLimitExportCSVTemplate,
    },
    { 
      id: 'customer.note', 
      filterKey: 'note', 
      name: 'note', 
      label: 'Customer note',
      width: '200px',
      orderable: false,
      template: noteTemplate,
      exportTemplate: noteExportTemplate,
      exportCSVTemplate: noteExportCSVTemplate,
    },
    { 
      id: 'customer.billingProfileName', 
      filterKey: 'billingProfileName', 
      name: 'customer.billingProfileName', 
      label: 'Billing by',
      width: '200px',
      orderable: true,
      template: billingProfileNameTemplate,
      exportTemplate: billingProfileNameExportTemplate,
      exportCSVTemplate: billingProfileNameExportCSVTemplate,
    },
    { 
      id: 'customer.billingAddress', 
      filterKey: 'billingAddress', 
      name: 'billingAddress', 
      label: 'Billing address',
      width: '200px',
      orderable: false,
      template: billingAddressTemplate,
      exportTemplate: billingAddressExportTemplate,
      exportCSVTemplate: billingAddressExportCSVTemplate,
    },
];

export const initColumnVisibilityInvoice = [
    false,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    false,
];


export const initColumnsExpense = [
  { 
    id: 'customerId', 
    filterKey: 'customerId', 
    name: 'customerId', 
    label: 'ID',
    visible: false
  },
  { 
    id: 'customerName', 
    filterKey: 'customerName', 
    name: 'customerName', 
    label: 'Customer name',
    width: '150px',
    orderable: true,
  },
  { 
    id: 'unbilledJobsCount', 
    filterKey: 'unbilledJobsCount', 
    name: 'unbilledJobsCount', 
    label: 'Unbilled',
    width: '150px',
    orderable: true,
    template: unbilledJobsCountExpenseTemplate,
    exportTemplate: unbilledJobsCountExportTemplate,
    exportCSVTemplate: unbilledJobsCountExportCSVTemplate,
  },
  { 
    id: 'billedJobsCount', 
    filterKey: 'billedJobsCount', 
    name: 'billedJobsCount', 
    label: 'Billed',
    width: '150px',
    orderable: true,
    template: billedJobsCountExpenseTemplate,
    exportTemplate: billedJobsCountExportTemplate,
    exportCSVTemplate: billedJobsCountExportCSVTemplate,
  },
  // { 
  //   id: 'paidJobsCount', 
  //   filterKey: 'paidJobsCount', 
  //   name: 'paidJobsCount', 
  //   label: 'Paid',
  //   width: '150px',
  //   orderable: true,
  //   template: paidJobsCountTextTemplate,
  //   exportTemplate: paidJobsCountExportTemplate,
  //   exportCSVTemplate: paidJobsCountExportCSVTemplate,
  // },
  
  // { 
  //   id: 'nonBillableJobsCount', 
  //   filterKey: 'nonBillableJobsCount', 
  //   name: 'nonBillableJobsCount', 
  //   label: 'Non-Billable',
  //   width: '150px',
  //   orderable: true,
  //   template: nonBillableJobsCountExpenseTemplate,
  //   exportTemplate: nonBillableJobsCountExportTemplate,
  //   exportCSVTemplate: nonBillableJobsCountExportCSVTemplate,
  // },
  { 
    id: 'customer.billingName', 
    filterKey: 'billingName', 
    name: 'billingName', 
    label: 'Billing name',
    width: '150px',
    orderable: false,
    template: billingNameTemplate,
    exportTemplate: billingNameExportTemplate,
    exportCSVTemplate: billingNameExportCSVTemplate,
  },
  { 
    id: 'customer.paymentTerms', 
    filterKey: 'paymentTerms', 
    name: 'customer.paymentTerms', 
    label: 'Payment terms',
    width: '150px',
    orderable: true,
    template: paymentTermsTemplate,
    exportTemplate: paymentTermsExportTemplate,
    exportCSVTemplate: paymentTermsExportCSVTemplate,
  },
  { 
    id: 'customer.creditLimit', 
    filterKey: 'creditLimit', 
    name: 'customer.creditLimit', 
    label: 'Credit limit',
    width: '150px',
    orderable: true,
    template: creditLimitTemplate,
    exportTemplate: creditLimitExportTemplate,
    exportCSVTemplate: creditLimitExportCSVTemplate,
  },
  { 
    id: 'customer.note', 
    filterKey: 'note', 
    name: 'note', 
    label: 'Customer note',
    width: '150px',
    orderable: false,
    template: noteTemplate,
    exportTemplate: noteExportTemplate,
    exportCSVTemplate: noteExportCSVTemplate,
  },
  { 
    id: 'customer.billingProfileName', 
    filterKey: 'billingProfileName', 
    name: 'customer.billingProfileName', 
    label: 'Billing by',
    width: '150px',
    orderable: true,
    template: billingProfileNameTemplate,
    exportTemplate: billingProfileNameExportTemplate,
    exportCSVTemplate: billingProfileNameExportCSVTemplate,
  },
  { 
    id: 'customer.billingAddress', 
    filterKey: 'billingAddress', 
    name: 'billingAddress', 
    label: 'Billing address',
    width: '150px',
    orderable: false,
    template: billingAddressTemplate,
    exportTemplate: billingAddressExportTemplate,
    exportCSVTemplate: billingAddressExportCSVTemplate,
  },
];

export const initColumnVisibilityExpense = [
  false,
  true,
  true,
  true,
  // true,
  // true,
  true,
  true,
  true,
  true,
  true,
  true,
  false,
];


export const initColumnsPurchase = [
  { 
    id: 'customerId', 
    filterKey: 'customerId', 
    name: 'customerId', 
    label: 'ID',
    visible: false
  },
  { 
    id: 'customerName', 
    filterKey: 'customerName', 
    name: 'customerName', 
    label: 'Customer name',
    width: '150px',
    orderable: true,
  },
  { 
    id: 'unbilledJobsCount', 
    filterKey: 'unbilledJobsCount', 
    name: 'unbilledJobsCount', 
    label: 'Unbilled',
    width: '150px',
    orderable: true,
    template: unbilledJobsCountPurchaseTemplate,
    exportTemplate: unbilledJobsCountExportTemplate,
    exportCSVTemplate: unbilledJobsCountExportCSVTemplate,
  },
  { 
    id: 'billedJobsCount', 
    filterKey: 'billedJobsCount', 
    name: 'billedJobsCount', 
    label: 'Billed',
    width: '150px',
    orderable: true,
    template: billedJobsCountPurchaseTemplate,
    exportTemplate: billedJobsCountExportTemplate,
    exportCSVTemplate: billedJobsCountExportCSVTemplate,
  },
  // { 
  //   id: 'paidJobsCount', 
  //   filterKey: 'paidJobsCount', 
  //   name: 'paidJobsCount', 
  //   label: 'Paid',
  //   width: '150px',
  //   orderable: true,
  //   template: paidJobsCountTextTemplate,
  //   exportTemplate: paidJobsCountExportTemplate,
  //   exportCSVTemplate: paidJobsCountExportCSVTemplate,
  // },

  // { 
  //   id: 'nonBillableJobsCount', 
  //   filterKey: 'nonBillableJobsCount', 
  //   name: 'nonBillableJobsCount', 
  //   label: 'Non-Billable',
  //   width: '150px',
  //   orderable: true,
  //   template: nonBillableJobsCountPurchaseTemplate,
  //   exportTemplate: nonBillableJobsCountExportTemplate,
  //   exportCSVTemplate: nonBillableJobsCountExportCSVTemplate,
  // },
  { 
    id: 'customer.billingName', 
    filterKey: 'billingName', 
    name: 'billingName', 
    label: 'Billing name',
    width: '150px',
    orderable: false,
    template: billingNameTemplate,
    exportTemplate: billingNameExportTemplate,
    exportCSVTemplate: billingNameExportCSVTemplate,
  },
  { 
    id: 'customer.paymentTerms', 
    filterKey: 'paymentTerms', 
    name: 'customer.paymentTerms', 
    label: 'Payment terms',
    width: '150px',
    orderable: true,
    template: paymentTermsTemplate,
    exportTemplate: paymentTermsExportTemplate,
    exportCSVTemplate: paymentTermsExportCSVTemplate,
  },
  { 
    id: 'customer.creditLimit', 
    filterKey: 'creditLimit', 
    name: 'customer.creditLimit', 
    label: 'Credit limit',
    width: '150px',
    orderable: true,
    template: creditLimitTemplate,
    exportTemplate: creditLimitExportTemplate,
    exportCSVTemplate: creditLimitExportCSVTemplate,
  },
  { 
    id: 'customer.note', 
    filterKey: 'note', 
    name: 'note', 
    label: 'Customer note',
    width: '150px',
    orderable: false,
    template: noteTemplate,
    exportTemplate: noteExportTemplate,
    exportCSVTemplate: noteExportCSVTemplate,
  },
  { 
    id: 'customer.billingProfileName', 
    filterKey: 'billingProfileName', 
    name: 'customer.billingProfileName', 
    label: 'Billing by',
    width: '150px',
    orderable: true,
    template: billingProfileNameTemplate,
    exportTemplate: billingProfileNameExportTemplate,
    exportCSVTemplate: billingProfileNameExportCSVTemplate,
  },
  { 
    id: 'customer.billingAddress', 
    filterKey: 'billingAddress', 
    name: 'billingAddress', 
    label: 'Billing address',
    width: '150px',
    orderable: false,
    template: billingAddressTemplate,
    exportTemplate: billingAddressExportTemplate,
    exportCSVTemplate: billingAddressExportCSVTemplate,
  },
];

export const initColumnVisibilityPurchase = [
  false,
  true,
  true,
  true,
  // true,
  // true,
  true,
  true,
  true,
  true,
  true,
  true,
  false,
];