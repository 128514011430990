import React from "react";
import * as yup from 'yup';
import { Formik } from 'formik';

import { 
    Row,
    Col,
    Form,
} from "react-bootstrap-v5";

import Utils from "../../../utils/utils";

import CustomGoogleMap from '../../../components/OLD/GoogleMap/CustomGoogleMap';
import AddressSearchInput from '../../../components/googleMap/AddressSearchInput';

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet } from '../../../../setup/redux/dispatch/actions'

const formFieldsLocation = {
    address: {
        id: 'address',
        label: 'Address',
        placeholder: ' ',
    },
}
const formSchemaLocation = yup.object().shape({
    isEdit: yup.bool().oneOf([true, false]),

    isInvalid: yup.bool().oneOf([false], 'Invalid coordinates').required('Invalid coordinates'),
    address: yup.string().required().label(formFieldsLocation.address.label),
});

class JobFormLocationDialog extends React.Component {
  
    constructor(props) {
        super(props);
    
        this.refForm = React.createRef();

        let address = (this.props.item && this.props.item.address) ? this.props.item.address : '';
        let latitude = (this.props.item && this.props.item.latitude) ? this.props.item.latitude : null;
        let longitude = (this.props.item && this.props.item.longitude) ? this.props.item.longitude : null;

        this.state = {
            rowInitialValues: {
                isEdit: false,
                isInvalid: false,
                address: address,
                latitude: latitude,
                longitude: longitude,
            }
        };
    }


    /* SECTIONS */
    setSection = (formOptions) => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = formOptions;
            

        return <Row>
            <Col xs={12}>
                <AddressSearchInput
                    id={formFieldsLocation.address.id}
                    label={formFieldsLocation.address.label}
                    placeholder={formFieldsLocation.address.placeholder}
                    error={errors.address || errors.isInvalid}
                    defaultValue={values.address}
                    value={values.address}

                    onChange={async (value) => {
                        await setFieldValue('address', value);
                        await setFieldValue('latitude', null);
                        await setFieldValue('longitude', null);
                        await setFieldValue('coordinates', '');
                    }}
                    onBlur={async (e) => {
                        let isCoord = false;

                        let value = e.target.value;
                        if(Utils.validateCoordinates(value)){
                            let args = value.split(',');
            
                            let center = {
                                lat: (args && args.length > 0) ? Utils.isNumeric(args[0].trim()) ? parseFloat(args[0].trim()) : '' : '',
                                lng: (args && args.length > 1) ? Utils.isNumeric(args[1].trim()) ? parseFloat(args[1].trim()) : '' : '',
                            };
                            
                            isCoord = true;

                            await setFieldValue('address', value);
                            await setFieldValue('latitude', center.lat);
                            await setFieldValue('longitude', center.lng);
                            await setFieldValue('coordinates', '');
                            await setFieldValue('isInvalid', false);
                        }

                        if(!isCoord){
                            if(values.latitude !== null && values.longitude !== null){
                                
                            } else {
                                await setFieldValue('isInvalid', true);
                            }
                        }
                    }}
                    onPlacesChanged={async (place) => {
                        if (place) {
                            if (place.formatted_address) {
                                await setFieldValue('address', place.formatted_address);
                            } else if (place.name) {
                                await setFieldValue('address', place.name);
                            }
                
                            if (place.geometry && place.geometry.location) {
                                let latitude = place.geometry.location.lat();
                                let longitude = place.geometry.location.lng();
                                let coordinates = latitude !== "" ? latitude + "," + longitude : '';
                                
                                await setFieldValue('latitude', latitude);
                                await setFieldValue('longitude', longitude);
                                await setFieldValue('coordinates', coordinates);
                                await setFieldValue('isInvalid', false);
                            }
                        }
                    }}
                    onEnterCoordinate={async (place, center) => {
                        if (place) {
                            if (place.formatted_address) {
                                await setFieldValue('address', place.formatted_address);
                            } else if (place.name) {
                                await setFieldValue('address', place.name);
                            }
                        
                            if (center) {
                                let latitude = center.lat;
                                let longitude = center.lng;
                                let coordinates = latitude !== "" ? latitude + "," + longitude : '';
                                
                                await setFieldValue('latitude', latitude);
                                await setFieldValue('longitude', longitude);
                                await setFieldValue('coordinates', coordinates);
                                await setFieldValue('isInvalid', false);
                            }
                        }
                    }}
                />
            </Col>

            {((values.latitude != null) && (values.longitude != null))
            ?
            <Col xs={12} className={'mb-5'} style={{ minHeight: '280px' }}>
                <CustomGoogleMap
                    center={(values.latitude && values.longitude) ? { lat: values.latitude, lng: values.longitude } : this.props.centerMap}
                    isSearchable={false}
                    streetViewControl={true}
                    markerCenter={(values.latitude !== "" && values.longitude !== "") ? { lat: values.latitude, lng: values.longitude } : null}
                    defaultZoom={this.props.zoomMap}
                    onDragEnd={async (position) => {
                        let coordinates = (position.lat !== "" ? (position.lng !== "" ? position.lat + "," + position.lng : position.lat) : "");

                        await setFieldValue('latitude', position.lat);
                        await setFieldValue('longitude', position.lng);
                        await setFieldValue('coordinates', coordinates);
                    }}
                />
            </Col>
            :
            <Col xs={12} className={'mb-5'} style={{ minHeight: '60px' }}></Col>
            }
        </Row>
    }
    /* END SECTIONS */

    render() {
        return <>
            <Formik
                innerRef={this.refForm}
                validationSchema={formSchemaLocation}
                initialValues={this.state.rowInitialValues}
                validateOnMount={true}
                enableReinitialize={true}
                onSubmit={(form, e) => {
                    if(typeof this.props.onSubmit === 'function'){
                        this.props.onSubmit(form);
                    }
                }}
            >
                {(formOptions) => {
                    return <Form className='w-100' noValidate autoComplete="off" onSubmit={formOptions.handleSubmit} onKeyDown={(keyEvent) => {
                        // if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                        //     keyEvent.preventDefault();
                        // }
                    }}>
                        {this.setSection(formOptions)}
                    </Form>
                }}
            </Formik>
        </>
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(JobFormLocationDialog);
