import { 
    Form,
    Button,
    Dropdown,
    ProgressBar,
    DropdownButton,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import DropdownIconButtonMenu from '../components/OLD/Dropdown/DropdownIconButtonMenu';
import RSuiteDatePicker from '../components/OLD/Datepicker/RSuiteDatePicker';
import RSuiteTimePicker from '../components/OLD/TimePicker/RSuiteTimePicker';


/* TOP SECTION */
export const StyledArrowButton = styled(Button)`
  border: 1px solid #ECECEC !important;
  border-radius: 2px !important;
  
  i {
    padding-left: 12px !important;
    padding-right: 12px !important;
    color: #7B7B7B !important;
  }

  &:hover {
    border: 1px solid #484848 !important;
    color: #484848 !important;
    background-color: #f7f7fa !important;
  }
`;
export const StyledDatePicker = styled.div`
  border: 1px solid #ECECEC !important;
  border-radius: 2px !important;

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  .rs-picker-toggle-value {
    color: #7A7A7A !important;
  }

  .material-icons {
    color: #7A7A7A !important;
  }

  .rs-picker-toggle:hover {
    border: 0px;
    border-radius: 2px !important;
  }

  &:hover {
    border: 1px solid #484848 !important;
    color: #484848 !important;
    background-color: #f7f7fa !important;
  }
`;
export const StyledButtonToday = styled(Button)`
  border: 1px solid #185cff !important;
  border-radius: 2px !important;
  
  span {
    padding-left: 12px !important;
    padding-right: 12px !important;
    color: #185cff !important;
  }

  &:hover {
    border: 1px solid #185cff !important;
    color: #185cff !important;
    background-color: #f7f7fa !important;
  }
`;
export const StyledDropdownHeader = styled(Dropdown.Header)`
  font-size: 14px;
  color: #484848;
`;
export const StyledFormCheckItem = styled(Form.Check)`
  margin: 12px;
`;
export const StyledDropdownIconButtonMenu = styled(DropdownIconButtonMenu)`
  button {
    padding: 5px 10px !important;

    svg {
      position: relative;
      top: -1px;
    }
  }

  .btn.btn-light:focus:not(.btn-active) {
    background-color: transparent !important;
  }
`;
export const StyledDropDownTitle = styled.div`
  font-size: 20px;
  display: inline-block;
`;
export const StyledRoundedFormCheck = styled(Form.Check)`
    input {
        border-radius: 50% !important;
    }

    .form-check-input {
      position: relative;
      width: 15px;
      height: 15px;
      top: 3px;
      left: 5px;
      
        background-color: #F5F5F5;
        border-color: #F5F5F5;

        &[type=checkbox] {
            background-size: 100% 50% !important;
            background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 13 11%27 width=%2713%27 height=%2711%27 fill=%27none%27%3e%3cpath d=%27M11.0426 1.02893C11.3258 0.695792 11.8254 0.655283 12.1585 0.938451C12.4917 1.22162 12.5322 1.72124 12.249 2.05437L5.51985 9.97104C5.23224 10.3094 4.72261 10.3451 4.3907 10.05L0.828197 6.88335C0.50141 6.59288 0.471975 6.09249 0.762452 5.7657C1.05293 5.43891 1.55332 5.40948 1.88011 5.69995L4.83765 8.32889L11.0426 1.02893Z%27 fill=%27%23FFFFFF%27/%3e%3c/svg%3e") !important;
        }
    }

    .form-check-input:checked {
        background-color: #185cff;
        border-color: #185cff;
    }

    label {
      ${props => {
        return (props.checked) ? `
          color: #484848;
          font-weight: bold;
        ` : '';
      }}
    }
`;
export const StyledDropdownA = styled.a`
  font-size: 14px;
  color: #484848;

  ${props => {
    return (props.active) ? `
      color: #484848;
      font-weight: bold;

      &:hover, &:active, &:focus {
        color: #484848;
      }
    ` : '';
  }}
`;
/* END TOP SECTION */


/* PROGRESS BAR */
export const StyledStatusTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #BCBCBC;
  text-transform: uppercase;
  margin-left: 10px;
  margin-bottom: 3px;
  cursor: pointer;
`;
export const StyledProgressBar = styled(ProgressBar)`
  height: 35px;
  border-radius: 0px;
  overflow: unset;
  margin-bottom: 20px;

  .progress-bar {
    text-align: left;
    font-size: 13px;
    font-weight: 500;
  }
`;
export const StyledProgressBarChild = styled(ProgressBar)`
  overflow: unset;
  cursor: pointer;

  .progress-label-parent {
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: clip;
    padding-left: 10px;
    padding-right: 10px;
    min-height: 35px;
    align-items: center;
    display: flex;

    .progress-label-child-1 {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: clip;
    }

    .progress-label-child-2 {
      position: absolute;
      top: 40px;
      color: #444444;
      font-weight: bold;
    }
  }

  ${props => {
    if(props.variant == 'billable'){
      return `
        background-color: #185CFF;
      `;

    } else if(props.variant == 'nonBillable'){
      return `
        background-color: #7B7B7B;
      `;

    } else if(props.variant == 'unbilled'){
      return `
        background-color: #FF0090;
      `;

    } else if(props.variant == 'billed'){
      return `
        background-color: #6EB104;
      `;

    } else if(props.variant == 'paid'){
      return `
        background-color: #F5F5F5;
        color: #444444;
      `;

    } else {
      return '';
    }
  }}
`;
/* END PROGRESS BAR */


/* CHECKBOX */
export const RoundedFormCheck = styled(Form.Check)`
    input {
        border-radius: 50% !important;
    }

    .form-check-input {
        background-color: #F5F5F5;
        border-color: #F5F5F5;

        &[type=checkbox] {
            background-size: 100% 50% !important;
            background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 13 11%27 width=%2713%27 height=%2711%27 fill=%27none%27%3e%3cpath d=%27M11.0426 1.02893C11.3258 0.695792 11.8254 0.655283 12.1585 0.938451C12.4917 1.22162 12.5322 1.72124 12.249 2.05437L5.51985 9.97104C5.23224 10.3094 4.72261 10.3451 4.3907 10.05L0.828197 6.88335C0.50141 6.59288 0.471975 6.09249 0.762452 5.7657C1.05293 5.43891 1.55332 5.40948 1.88011 5.69995L4.83765 8.32889L11.0426 1.02893Z%27 fill=%27%23FFFFFF%27/%3e%3c/svg%3e") !important;
        }
    }

    .form-check-input:checked {
        background-color: #185cff;
        border-color: #185cff;
    }
`;

export const StyledSecondaryRadio = styled(Form.Check)`
    .form-check-input {
        border-weight: 2px;
        width: 20px;
        height: 20px;
    }

    .form-check-input:checked {
        background-color: transparent;
        border-color: #444444;
        filter: brightness(40%);
    }
`;
/* END CHECKBOX */


/* BLOCKER LOADING */
export const StyledBlockerLoading = styled.div`
    position: fixed;
    top: 0;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 99999;
    
    display: flex;
    align-items: center;
    justify-content: center;

    .spinner-border {

    }
`;
export const StyledOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.2;
`;

export const StyledElementBlockerLoading = styled.div`
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 99999;
    
    display: flex;
    align-items: center;
    justify-content: center;

    .spinner-border {

    }
`;
export const StyledElementOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.2;
`;
/* END BLOCKER LOADING */


/* END LABELS */
export const StyledBilllingStatus = styled.div`
  border-radius: 25px;
  min-height: 30px;
  text-align: center;
  
  font-size: 12px;
  font-weight: 500;
  
  display: flex;
  align-items: center;
  justify-content: center;

  ${props => {
    if(props.status == 'Mark as Paid'){
      return `
        background-color: #F5F5F5;
        color: #444444;
        border: 1px solid #E5E5EA;
      `;
    } else if(props.status == 'Paid'){
        return `
          background-color: #F5F5F5;
          color: #444444;
          border: 1px solid #E5E5EA;
        `;
    } else if(props.status == 'PO issued'){
      return `
        background-color: #F5F5F5;
        color: #444444;
        border: 1px solid #E5E5EA;
      `;
    } else if(props.status == 'EXP issued'){
      return `
        background-color: #F5F5F5;
        color: #444444;
        border: 1px solid #E5E5EA;
      `;
    } else if(props.status == 'Billed'){
      return `
        background-color: transparent;
        color: #6EB104;
        border: 1px solid #6EB104;
      `;
    } else if(props.status == 'Mark as Billed'){
      return `
        background-color: transparent;
        color: #444444;
        border: 1px solid #444444;
      `;
    } else if(props.status == 'Unbilled'){
      return `
        background-color: transparent;
        color: #FF0090;
        border: 1px solid #FF0090;
      `;
    } else if(props.status == 'PO not issued'){
      return `
        background-color: transparent;
        color: #FF0090;
        border: 1px solid #FF0090;
      `;
    } else if(props.status == 'EXP not issued'){
      return `
        background-color: transparent;
        color: #FF0090;
        border: 1px solid #FF0090;
      `;
    } else if(props.status == 'Non Billable'){
      return `
        background-color: #7B7B7B;
        color: white;
        border: 1px solid #7B7B7B;
      `;
    } else {
      return `
        background-color: transparent;
        color: #444444;
        border: 1px solid transparent;
      `;
    }
  }}
`;
/* END LABELS */


/* TABLE */
export const StyledWeightChitTable = styled.table`
    width: 100% !important;

    thead {
        tr {
            th {
                background-color: transparent !important;
                color: #484848 !important;
                border-bottom: 1px solid #DFDFDF !important;
            }
        }
    }

    tbody {
        tr {
            td {
                border-bottom: 0px !important;
                padding-top: 10px;
                padding-bottom: 10px;
                vertical-align: top;

                @media (max-width: 767px) {
                    display: block !important;
                    padding-top: 0px !important;
                    padding-bottom: 0px !important;
                }
            }
        }
    }
`;
/* END TABLE */


/* A */
export const StyledAUnbilled = styled.a`
  font-size: 14px;
  color: #FF0090 !important;
  padding: 3px 6px 3px 6px;
  border: 1px solid transparent;
  display: inline-block;

  &:hover {
    border: 1px solid #FF0090;
    font-weight: bold;
  }

  ${props => {
    return (props.active) ? `
      border: 1px solid #FF0090;
      font-weight: bold;
    ` : `
      border: 1px solid transparent;
      font-weight: normal;
    `;
  }}
`;
export const StyledDivUnbilled = styled.div`
  font-size: 14px;
  color: #FF0090;
`;
export const StyledABilled = styled.a`
  font-size: 14px;
  color: #6EB104 !important;
  padding: 3px 6px 3px 6px;
  border: 1px solid transparent;
  display: inline-block;

  &:hover {
    border: 1px solid #6EB104;
    font-weight: bold;
  }

  ${props => {
    return (props.active) ? `
      border: 1px solid #6EB104;
      font-weight: bold;
    ` : `
      border: 1px solid transparent;
      font-weight: normal;
    `;
  }}
`;
export const StyledDivBilled = styled.div`
  font-size: 14px;
  color: #6EB104;
`;
export const StyledANonBillable = styled.a`
  font-size: 14px;
  color: #7B7B7B !important;
  padding: 3px 6px 3px 6px;
  border: 1px solid transparent;
  display: inline-block;

  &:hover {
    border: 1px solid #7B7B7B;
    font-weight: bold;
  }

  ${props => {
    return (props.active) ? `
      border: 1px solid #7B7B7B;
      font-weight: bold;
    ` : `
      border: 1px solid transparent;
      font-weight: normal;
    `;
  }}
`;
export const StyledDivNonBillable = styled.div`
  font-size: 14px;
  color: #7B7B7B;
`;
export const StyledAPaid = styled.a`
  font-size: 14px;
  color: #444444 !important;
  padding: 3px 6px 3px 6px;
  border: 1px solid transparent;
  display: inline-block;

  &:hover {
    border: 1px solid #444444;
    font-weight: bold;
  }

  ${props => {
    return (props.active) ? `
      border: 1px solid #444444;
      font-weight: bold;
    ` : `
      border: 1px solid transparent;
      font-weight: normal;
    `;
  }}
`;
export const StyledDivPaid = styled.div`
  font-size: 14px;
  color: #444444;
`;
/* END A */


/* BUTTON */
export const StyledLinkButton = styled.button`
  background-color: transparent;
  color: #0066FF;
  padding: 0px;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`;
export const StyledRemoveButton = styled.button`
  background-color: transparent;
  color: #444444;
  padding: 0px;
  opacity: 0.7;

  i {
    color: #444444;
  }
  
  &:hover {
    opacity: 1;
  }
`;
/* END BUTTON */


/* REAL TIME ITEM */
export const StyledRealTimeItem = styled.div`
  .title {
    font-weight: 500;
  }

  .item {
    display: flex;
    align-items: center;
  }

  .item-title {
    flex-basis: 100%;

    span {
      font-size: 11px;
      float: right;
      top: 2px;
      position: relative;
    }
  }

  .item-icons {
    
  }

  .item-icon-up {
    height: 20px;
    overflow: hidden;
    position: relative;
    user-select: none;
    cursor: pointer;

    i {
      cursor: pointer;
      user-select: none;
    }

    &:hover {
      i {
        color: #cccccc;
      }
    }
  }

  .item-icon-down {
    height: 20px;
    overflow: hidden;
    position: relative;
    user-select: none;
    cursor: pointer;

    i {
      top: -5px;
      position: relative;
      cursor: pointer;
      user-select: none;
    }

    &:hover {
      i {
        color: #cccccc;
      }
    }
  }
`;
/* END REAL TIME ITEM */


/* XERO PAYMENT TERM TYPE */
export const StyledDropdownButton = styled(DropdownButton)`
  button {
    background-color: #f7f7f7 !important;
    color: #484848 !important;

    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;

    border-top: 1px solid #E4E6EF !important;
    border-right: 1px solid #E4E6EF !important;
    border-bottom: 1px solid #E4E6EF !important;
  }
`;
/* END XERO PAYMENT TERM TYPE */


/* DATE / TIME */
export const StyledDatePickerField = styled(RSuiteDatePicker)`
  .rs-stack-item {
    .rs-picker-toggle-textbox {
      padding-left: 0px !important;
    }
    .rs-picker-toggle-placeholder {
      padding-left: 0px !important;

      color: #A1A5B7 !important;
    }
  }
`;
export const StyledTimePickerField = styled(RSuiteTimePicker)`
  .rs-stack-item {
    &:first-child {
      left: -6px !important;
      position: relative;
    }

    .rs-picker-toggle-textbox {
      padding-left: 0px !important;
    }
    .rs-picker-toggle-placeholder {
      padding-left: 0px !important;

      color: #A1A5B7 !important;
    }
  }
`;
/* END DATE / TIME */