import React, { Component } from 'react';

import Autosuggest from 'react-autosuggest';
import Portal from 'react-travel'

import Utils from '../../../utils/utils';

import { 
  Box,
  Grid,
  Input,
  Popper,
  IconButton,
  CircularProgress,
  Icon,
} from '@material-ui/core';

import ManageInput from '../input/ManageInput.jsx';
import ManageLabel from '../input/ManageLabel.jsx';

import CustomDialog from '../Dialog/CustomDialog';
import DataTableRemoveDialog from '../DataTable/DataTableRemoveDialog.jsx';
import AddressSearchBox from '../GoogleMap/AddressSearchBox.jsx';


class CustomAutosuggest extends Component {
    
	constructor(props){
        super(props);
        
        this.inputRef = React.createRef();

        let className = props.className ? props.className : '';
        let value = props.value ? props.value : '';
        let items = props.items ? props.items : [];
        let name = props.name ? props.name : '';
        let placeholder = props.placeholder ? props.placeholder : '';
        let label = props.label ? props.label : '';
        let color = props.color ? props.color : '#484848';
        let removeMessage = props.removeMessage ? props.removeMessage : '';
        let icon = props.icon ? props.icon : null;
        let labelComponent = props.labelComponent ? props.labelComponent : null;
        let headComponent = props.headComponent ? props.headComponent : null;
        let footComponent = props.footComponent ? props.footComponent : null;
        let searchIcon = props.searchIcon ? props.searchIcon : null;
        let noIcon = ((props.noIcon === true || props.noIcon === false) ? props.noIcon : false);
        let readOnly = ((props.readOnly === true || props.readOnly === false) ? props.readOnly : false);
        let disabled = ((props.disabled === true || props.disabled === false) ? props.disabled : false);
        let required = ((props.required === true || props.required === false) ? props.required : false);
        let error = ((props.error === true || props.error === false) ? props.error : false);
        let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
        let isAjax = ((props.isAjax === true || props.isAjax === false) ? props.isAjax : true);
        let isSearch = ((props.isSearch === true || props.isSearch === false) ? props.isSearch : true);
        let isFreeText = ((props.isFreeText === true || props.isFreeText === false) ? props.isFreeText : false);
        let isGoogleSearch = ((props.isGoogleSearch === true || props.isGoogleSearch === false) ? props.isGoogleSearch : false);
        let showAllSuggestion = ((props.showAllSuggestion === true || props.showAllSuggestion === false) ? props.showAllSuggestion : false);
        let isCustomRenderSuggestionsContainer = ((props.isCustomRenderSuggestionsContainer === true || props.isCustomRenderSuggestionsContainer === false) ? props.isCustomRenderSuggestionsContainer : false);
        let isDefault = ((props.isDefault === true || props.isDefault === false) ? props.isDefault : false);
        
        let valueName = props.valueName ? props.valueName : 'value';
        let textName = props.textName ? props.textName : 'text';
        let labelName = props.labelName ? props.labelName : null;
        let recentName = props.recentName ? props.recentName : 'lastJobDate';
        let recentIcon = props.recentIcon ? props.recentIcon : <Icon component={'i'}>access_time</Icon>;
        let recentCount = props.recentCount ? props.recentCount : 5;

		this.state = {
			value: value,
            suggestions: [],
            className: className,
            items: items,
            name: name,
            placeholder: placeholder,
            label: label,
            color: color,
            icon: icon,
            searchIcon: searchIcon,
            labelComponent: labelComponent,
            headComponent: headComponent,
            footComponent: footComponent,
            noIcon: noIcon,
            readOnly: readOnly,
            disabled: disabled,
            required: required,
            error: error,
            isLoading: isLoading,
            valueName: valueName,
            textName: textName,
            labelName: labelName,
            recentName: recentName,
            recentIcon: recentIcon,
            recentCount: recentCount,
            rCount: 0,
            isAjax: isAjax,
            isSearch: isSearch,
            isFreeText: isFreeText,
            isGoogleSearch: isGoogleSearch,
            showAllSuggestion: showAllSuggestion,
            removeMessage: removeMessage,
            isCustomRenderSuggestionsContainer: isCustomRenderSuggestionsContainer,
            isDefault: isDefault,
            isRemoveDialog: false,
            removeRow: null,
		}
	}
    

    componentDidMount() {
        if(!this.state.isFreeText) {
            if(!this.state.disabled && this.state.isAjax && this.props.onInit) {
                this.props.onInit(this);
            }
        }
        if(!this.state.isGoogleSearch) {
            if(!this.state.disabled && this.state.isAjax && this.props.onInit) {
                this.props.onInit(this);
            }
        }
    }

    
    componentWillReceiveProps(nextProps) {
		if (nextProps.items !== this.state.items) {
			this.setState({
				items: nextProps.items
			});
        }
        if (nextProps.value !== this.state.value) {
			this.setState({
				value: nextProps.value
			});
		}
        if (nextProps.color !== this.state.color) {
			this.setState({
				color: nextProps.color
			});
		}
        if (nextProps.readOnly !== this.state.readOnly) {
			this.setState({
				readOnly: nextProps.readOnly
			});
		}
        if (nextProps.disabled !== this.state.disabled) {
			this.setState({
				disabled: nextProps.disabled
			});
		}
        if (nextProps.error !== this.state.error) {
			this.setState({
				error: nextProps.error
			});
		}
        if (nextProps.required !== this.state.required) {
			this.setState({
				required: nextProps.required
			});
		}
        if (nextProps.isLoading !== this.state.isLoading) {
			this.setState({
				isLoading: nextProps.isLoading
			});
		}
        if (nextProps.isAjax !== this.state.isAjax) {
            let isAjax = ((nextProps.isAjax === true || nextProps.isAjax === false) ? nextProps.isAjax : true);
        
            if (isAjax !== this.state.isAjax) {
                this.setState({
                    isAjax: nextProps.isAjax
                });
            }
		}
    }
    
  
    showAllSuggestions = () => {
        this.setState({
            suggestions: this.getSuggestions('')
        });
    }
    hideAllSuggestions = () => {
        this.setState({
            suggestions: ['']
        });
    }
    
    setItems = (items) => {
        this.setState({
            items: items
        }, () => {
            this.showAllSuggestions()
        });
    }
    
    escapeRegexCharacters = (str) => {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }
    
    getSuggestions = (value) => {
        try {
            const escapedValue = this.escapeRegexCharacters(value.trim());
            const regex = new RegExp('^' + escapedValue, 'i');
            
            if(this.state.items && this.state.items.length > 0){
                return this.state.items.filter(x => regex.test(x[this.state.textName]));
            } else {
                return [''];
            }
        } catch(e) {
            return [''];
        }
    }
    
    getSuggestionValue = (suggestion) => {
        return suggestion[this.state.textName];
    }
    
    shouldRenderSuggestions = () => {
        return !this.state.isLoading;
    }
    
    setRemoveDialog = () => {
        return  <CustomDialog
          open={this.state.isRemoveDialog}
          maxWidth={"lg"}
          onClose={() => {
            this.setState({
              isRemoveDialog: false,
              removeRow: null
            });
          }}
        >
          <DataTableRemoveDialog 
            removeMessage={this.state.removeMessage}
            onClose={() => {
              this.setState({
                isRemoveDialog: false,
                removeRow: null
              });
            }}
            onOk={() => {
                let item = this.state.removeRow;
                
                this.setState({
                    isRemoveDialog: false,
                    removeRow: null
                }, () => {
                    if(this.props.onRemove){
                        this.props.onRemove(this, item);
                    }
                });
            }}
          />
        </CustomDialog>
    }

    renderSuggestion = (suggestion, x) => {
        if(this.props.onRemove){
            if(suggestion[this.state.textName]){
                return <Grid container className={'no-propagation'}>
                    <Box clone className={'no-propagation'}>
                        <Grid item xs={true} className={"v-left-center no-propagation"}>
                            {this.renderSuggestionItem(suggestion, 'no-propagation')}
                        </Grid>
                    </Box>
                    <Box clone className={'no-propagation'}>
                        <Grid item xs={'auto'} className={'no-propagation'}>
                            <IconButton
                                className={"delete-btn no-propagation"}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();

                                    this.setState({
                                        isRemoveDialog: true,
                                        removeRow: suggestion
                                    });
                                }}
                            >
                                <Icon component={'i'} className={'no-propagation'}>delete_forever</Icon>
                            </IconButton>
                        </Grid>
                    </Box>
                </Grid>
            } else {
                if(suggestion[this.state.valueName] === -1){
                    return this.renderSuggestionItem(suggestion, 'no-propagation custom-placeholder');
                } else {
                    return this.renderSuggestionItem(suggestion, 'no-propagation');
                }
            }
        } else {
            if(suggestion[this.state.valueName] === -1){
                return this.renderSuggestionItem(suggestion, 'no-propagation custom-placeholder');
            } else {
                return this.renderSuggestionItem(suggestion, 'no-propagation');
            }
        }
    }

    renderSuggestionItem = (suggestion, className) => {
        let minDate = Utils.getMinDate();
        let recentVal = (suggestion && suggestion.item && suggestion.item[this.state.recentName]) ? suggestion.item[this.state.recentName] : minDate;

        let isRecent = false;
        let index = 1;
        if(recentVal !== minDate){
            isRecent = true;
            index = (suggestion.index && suggestion.index > 0) ? suggestion.index : 0;
        }
        
        return <Box>
            {(isRecent && (index > 0 && index <= this.state.recentCount)) ? <Box className={'custom-recent-icon'}>{this.state.recentIcon}</Box> : null}
            <span className={className}>{suggestion[(this.state.labelName) ? this.state.labelName : this.state.textName]}</span>
        </Box>
    }
    
    headComponent = () => {
        if(this.state.headComponent){
            return <Box 
                className={'no-propagation'}
                style={{ 
                    backgroundColor: 'white', 
                    position: 'sticky', 
                    top: '0px',
                    zIndex: '2',
                }}
            >{this.state.headComponent(this)}</Box>
        }
    }
    footComponent = () => {
        if(this.state.footComponent){
            return <Box className={'no-propagation'}>{this.state.footComponent(this)}</Box>
        }
    }

    renderSuggestionsContainer = ({ containerProps, children, query }) => {
        if(this.state.isCustomRenderSuggestionsContainer){
            if(this.inputRef && this.inputRef.current){
                const inputCoords = this.inputRef.current.getBoundingClientRect();
                return <Portal>
                    <Popper open={true} anchorEl={this.inputRef.current}>
                        <Box {...containerProps} className="custom_suggestions-container" style={{ width: inputCoords.width }}>
                            {this.headComponent()}
                            {children}
                            {this.footComponent()}
                        </Box>
                    </Popper>
                </Portal>
            }
        } else {
            return <Box {...containerProps}>
                {this.headComponent()}
                {children}
                {this.footComponent()}
            </Box>
        }
    }

    onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex }) => {
        event.stopPropagation();
        
        if(suggestion[this.state.valueName] === -1){
            event.preventDefault();
            event.stopPropagation();

            this.setState({
                value: ''
            }, () => {
                if(this.props.onChange) {
                    this.props.onChange(null, this);
                }
            });
        } else {
            this.setState({
                value: suggestion[this.state.textName]
            }, () => {
                if(this.props.onChange) {
                    this.props.onChange(suggestion, this);
                }
            });
        }
    }


    clear = (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            value: ''
        }, () => {
            if(this.props.onClear) {
                this.props.onClear();
            }
        });
    };

    onChange = (event, { newValue, method }) => {
        if(this.state.isAjax){
            this.setState({
                value: newValue
            }, () => {
                if(this.props.onSearch && method !== 'click') {
                    this.props.onSearch(newValue, this);
                }
            });
        } else {
            this.setState({
                value: newValue,
                suggestions: this.getSuggestions(newValue)
            });
        }
    };

    onChangeFreeText = (e) => {
        if(this.props.onChangeFreeText) {
            this.props.onChangeFreeText(e.target.value);
        }
    };
    
    onBlur = (e) => {
        if(this.props.onBlur) {
            this.props.onBlur(this);
        }

        if(!this.state.isSearch){
            if(this.props.onSearch) {
                this.props.onSearch(this);
            }
        }
    };

    onKeyPress = (event) => {
        if(this.props.onKeyPress){
            this.props.onKeyPress(event);
        }

        if (event.key === 'Enter') {
            if(this.props.onPressEnter){
                this.props.onPressEnter();
            }
        }
    };

    onMouseDown = () => {
        if(!this.state.disabled){
            if(this.state.isAjax){
                this.setState({
                    isLoading: true,
                });
            }
        }
    };
    
    onMouseUp = () => {
        if(!this.state.disabled){
            if(this.state.isAjax){
                this.setState({
                    isLoading: true,
                }, () => {
                    if(this.props.onClick) {
                        this.props.onClick(this);
                    }
                });
            }

            if(this.state.showAllSuggestion){
                if(this.props.onClick) {
                    this.props.onClick(this);
                }
            }
        }
    };
    
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions((this.state.isSearch) ? value : '')
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };
    

	render() {
        if(this.state.isFreeText || this.state.isGoogleSearch){
            const { value } = this.state;
            const inputProps = {
                autoComplete: 'off',
                className: this.state.className,
                name: this.state.name,
                id: (this.state.name !== '') ? this.state.name + '-id' : new Date().getTime() + '-id',
                readOnly: this.state.readOnly,
                disabled: this.state.disabled,
                error: this.state.error,
                required: this.state.required,
                fullWidth: true,
                placeholder: this.state.placeholder,
                value: (value) ? value : '',
                onBlur: this.onBlur,
                onKeyPress: this.onKeyPress,
            };

            if(this.state.isGoogleSearch){
                return <AddressSearchBox
                    {...inputProps}
                    onPlacesChanged={(place) => {
                        let e = {
                            target: {
                                value: ''
                            }
                        };

                        if(place){
                            if(place.formatted_address){
                                e.target.value = place.formatted_address;
                            } else if(place.name){
                                e.target.value = place.name;
                            }
                        } else {
                            e.target.value = '';
                        }
                        this.onChangeFreeText(e);
                    }}
                    onChange={(value) => {
                        let e = {
                            target: {
                                value: value
                            }
                        };

                        this.onChangeFreeText(e);
                    }}
                />
            } else {
                const inputProps = {
                    autoComplete: 'off',
                    className: this.state.className,
                    name: this.state.name,
                    id: (this.state.name !== '') ? this.state.name + '-id' : new Date().getTime() + '-id',
                    readOnly: this.state.readOnly,
                    disabled: this.state.disabled,
                    error: this.state.error,
                    required: this.state.required,
                    fullWidth: true,
                    placeholder: this.state.placeholder,
                    value: (value) ? value : '',
                    onChange: this.onChangeFreeText,
                    onBlur: this.onBlur,
                    onKeyPress: this.onKeyPress,
                };

                return <ManageInput {...inputProps} />
            }
        } else {
            const { value, suggestions } = this.state;
            const inputProps = {
                ref: this.inputRef,
                autoComplete: 'off',
                name: this.state.name,
                id: (this.state.name !== '') ? this.state.name + '-id' : new Date().getTime() + '-id',
                readOnly: this.state.readOnly,
                disabled: this.state.disabled,
                error: this.state.error,
                required: this.state.required,
                fullWidth: true,
                placeholder: this.state.placeholder,
                value: (value) ? value : '',
                onChange: this.onChange,
                onMouseDown: this.onMouseDown,
                onMouseUp: this.onMouseUp,
                onBlur: this.onBlur,
                onKeyPress: this.onKeyPress,
            };
            
            return <div className={"custom-autosuggest-component no-icon " + (this.state.isDefault ? ' default-look ' : '') + this.state.className + (this.state.id ? ' ' + this.state.id : '') + (this.props.isError == true ? ' is-invalid ' : '')}>
                {this.state.label && <ManageLabel 
                    className={"custom-label"}
                    label={this.state.label}
                    labelComponent={this.state.labelComponent}
                    disabled={this.state.disabled}
                    error={this.state.error}
                    required={this.state.required}
                />}
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={this.getSuggestionValue}
                    shouldRenderSuggestions={this.shouldRenderSuggestions}
                    renderSuggestion={this.renderSuggestion}
                    renderSuggestionsContainer={this.renderSuggestionsContainer}
                    onSuggestionSelected={this.onSuggestionSelected}
                    inputProps={inputProps}
                    renderInputComponent={params => (
                        <div className={"custom-autosuggest-input-component no-propagation"}>
                            {(this.state.noIcon ? null : this.state.icon)}
                            {this.state.isLoading && <CircularProgress className={"custom-autosuggest-progress"} size={25} />}
                            {
                            (this.state.value !== '')
                            ? 
                            (!this.state.disabled)
                            ?
                            <Icon className={"custom-autosuggest-close no-propagation"} component={'i'} onClick={this.clear}>close</Icon>
                            :
                            <></>
                            :
                            (
                                this.state.noIcon 
                                ? 
                                <></>
                                :
                                (
                                    this.state.searchIcon 
                                    ?
                                    (
                                        this.state.isLoading
                                        ?
                                        <></>
                                        :
                                        this.state.searchIcon
                                    )
                                    :
                                    <Icon className={"custom-autosuggest-search no-propagation"} component={'i'}>search</Icon>
                                )
                            )
                            }
                            <Input {...params} style={{ color: (!this.state.disabled ? this.state.color : '#999a9d') }} onClick={(e) => {e.stopPropagation();}} />
                        </div>
                    )}
                />
                {this.setRemoveDialog()}
            </div>;
        }
	}
}

export default CustomAutosuggest
