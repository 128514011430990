import React from "react";
import _ from 'lodash';
import debounce from 'lodash/debounce';

import { 
    Row,
    Col,
    Form,
    Button,
    Image,
    Spinner,
    Dropdown,
    InputGroup,
} from "react-bootstrap-v5";

import RTextEditor from '../../../components/OLD/RichTextEditor/RTextEditor.jsx';
import RSuiteSelectPicker from '../../../components/OLD/Select/RSuiteSelectPicker';
import RemoveDialog from '../../../components/dialog/RemoveDialog';
import IconButton from "../../../components/Buttons/IconButton";

import AdvancedXeroPopup from "./dialog/AdvancedXeroPopup";
import AdvancedQuickBookPopup from "./dialog/AdvancedQuickBookPopup";

import Utils from "../../../utils/utils";
import { YesNo, QuickbookOrXero, PagesPermissions, BillingTypes,  XeroPaymentTermType, XeroPaymentTermTypeItems } from "../../../utils/enums";
import { StyledDropdownButton } from "../../../utils/styles";

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet, dispatchApiCallPut } from '../../../../setup/redux/dispatch/actions'
import advancedMappingXero from "./redux/advancedMappingXero";
import advancedMappingQuickBook from "./redux/advancedMappingQuickBook";

import DocGenApi from '../../../api/DocGen/DocGen.jsx';
import QuickbookApi from "../../../api/OLD/Quickbook/Quickbook";
import XeroApi from "../../../api/OLD/Xero/Xero.jsx";


class GeneralForm extends React.Component {
  
    constructor(props) {
        super(props);
    
        let accountBillingProfileItem = (props && props.accountBillingProfileItem) ? props.accountBillingProfileItem : null;

        let user = (props.auth && props.auth.user) ? props.auth.user : null;
        this.permissions = (user && user.permissions && user.permissions.length > 0) ? user.permissions : [];

        this.state = {
            accountBillingProfileItem: accountBillingProfileItem,
            
            setDisconnectDialog: false,
            setDisconnectDialogItem: null,
            setDisconnectDialogIsLoading: false,
        };
    }

    
    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);

        if (nextProps.accountBillingProfileItem !== this.state.accountBillingProfileItem) {
            let accountBillingProfileItem = (nextProps.accountBillingProfileItem) ? nextProps.accountBillingProfileItem : null;
            this.setState({
                accountBillingProfileItem: accountBillingProfileItem
            });
        }
    }


    /* API */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            'company_general_form-quickBook_tax_code', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = []; //variables.items;

                if(data && data.length > 0) {
                    for (var i = 0; i < data.length; i++) {
                        let item = data[i];
                        let value = item.id;
                        let title = item.name;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            'company_general_form-xero_chart_of_account', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = []; //variables.items;

                if(data && data.length > 0) {
                    for (var i = 0; i < data.length; i++) {
                        let item = data[i];
                        let value = item.accountID;
                        let title = item.code + ' - ' + item.name;
                        let type = item.type;
                        let arrItem = {
                            value: value,
                            title: title,
                            type: type,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );
        Utils.reduxProps(nextProps,
            'company_general_form-quickBook_chart_of_account', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = []; //variables.items;

                if(data && data.length > 0) {
                    for (var i = 0; i < data.length; i++) {
                        let item = data[i];
                        let value = item.id;
                        let title = item.fullyQualifiedName;
                        let type = item.domain;
                        let arrItem = {
                            value: value,
                            title: title,
                            type: type,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            'company_general_form-xero_tax_rates', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = []; //variables.items;

                if(data && data.length > 0) {
                    for (var i = 0; i < data.length; i++) {
                        let item = data[i];
                        let value = item.taxType;
                        let title = item.name + ' (' + item.displayTaxRate + '%)';
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            'company_general_form-quickBook_update_tax_code', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(!this.state.isError){
                        Utils.toast('Successfully updated tax code!', 'success'); 
                    }
                
                    if(callback){
                        callback();
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            'company_general_form-xero_update_chart_of_account', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(!this.state.isError){
                        Utils.toast('Successfully updated product account!', 'success'); 
                    }
                
                    if(callback){
                        callback();
                    }
                });
            }
        );
        Utils.reduxProps(nextProps,
            'company_general_form-quickBook_update_chart_of_account', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(!this.state.isError){
                        Utils.toast('Successfully updated product account!', 'success'); 
                    }
                
                    if(callback){
                        callback();
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            'company_general_form-xero_update_invoice_tax_rate', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(!this.state.isError){
                        Utils.toast('Successfully updated invoice tax rate!', 'success'); 
                    }
                
                    if(callback){
                        callback();
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            'company_general_form-xero_update_purchase_tax_rate', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(!this.state.isError){
                        Utils.toast('Successfully updated purchase tax rate!', 'success'); 
                    }
                
                    if(callback){
                        callback();
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            'company_general_form-xero_update_payment_term_type', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(!this.state.isError){
                        Utils.toast('Successfully updated default xero payment term!', 'success'); 
                    }
                
                    if(callback){
                        callback();
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            'company_general_form-quickBook_credit_terms', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = []; //variables.items;

                if(data && data.length > 0) {
                    for (var i = 0; i < data.length; i++) {
                        let item = data[i];
                        let value = item.id;
                        let title = item.name;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            'company_general_form-quickBook_update_credit_terms', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(!this.state.isError){
                        Utils.toast('Successfully updated term!', 'success'); 
                    }
                
                    if(callback){
                        callback();
                    }
                });
            }
        );
    }
	
    callQuickbookReadTaxCodeApi = (items, callback = null) => {
        let quickBookAppId = (this.state.accountBillingProfileItem && this.state.accountBillingProfileItem.quickBookApp && this.state.accountBillingProfileItem.quickBookApp.quickBookAppId && this.state.accountBillingProfileItem.quickBookApp.quickBookAppId > 0) ? this.state.accountBillingProfileItem.quickBookApp.quickBookAppId : 0;
        
        let data = {
            isSalesTax: true,
            quickBookAppId: quickBookAppId,
        };
        
        this.props.dispatchApiCallGet(data, 'company_general_form-quickBook_tax_code', 'quickbookapp/tax-code', { items: items }, callback, () => {});
    }
    
    callXeroReadChartOfAccountApi = (items, callback = null) => {
        let xeroAppId = (this.state.accountBillingProfileItem && this.state.accountBillingProfileItem.xeroApp && this.state.accountBillingProfileItem.xeroApp.xeroAppId && this.state.accountBillingProfileItem.xeroApp.xeroAppId > 0) ? this.state.accountBillingProfileItem.xeroApp.xeroAppId : 0;
        
        let data = {
            xeroAppId: xeroAppId,
            isInvoice: true,
        };
        
        this.props.dispatchApiCallGet(data, 'company_general_form-xero_chart_of_account', 'XeroApp/chart-of-account', { items: items }, callback, () => {});
    }
    callXeroReadPurchaseChartOfAccountApi = (items, callback = null) => {
        let xeroAppId = (this.state.accountBillingProfileItem && this.state.accountBillingProfileItem.xeroApp && this.state.accountBillingProfileItem.xeroApp.xeroAppId && this.state.accountBillingProfileItem.xeroApp.xeroAppId > 0) ? this.state.accountBillingProfileItem.xeroApp.xeroAppId : 0;
        
        let data = {
            xeroAppId: xeroAppId,
            isInvoice: false,
            isPurchase: true,
        };
        
        this.props.dispatchApiCallGet(data, 'company_general_form-xero_chart_of_account', 'XeroApp/chart-of-account', { items: items }, callback, () => {});
    }
    
    callQuickBookReadChartOfAccountApi = (items, callback = null) => {
        let quickBookAppId = (this.state.accountBillingProfileItem && this.state.accountBillingProfileItem.quickBookApp && this.state.accountBillingProfileItem.quickBookApp.quickBookAppId && this.state.accountBillingProfileItem.quickBookApp.quickBookAppId > 0) ? this.state.accountBillingProfileItem.quickBookApp.quickBookAppId : 0;
        
        let data = {
            quickBookAppId: quickBookAppId,
            isInvoice: true,
        };
        
        this.props.dispatchApiCallGet(data, 'company_general_form-quickBook_chart_of_account', 'QuickBookApp/income-accounts', { items: items }, callback, () => {});
    }
    callQuickBookReadPurchaseChartOfAccountApi = (items, callback = null) => {
        let quickBookAppId = (this.state.accountBillingProfileItem && this.state.accountBillingProfileItem.quickBookApp && this.state.accountBillingProfileItem.quickBookApp.quickBookAppId && this.state.accountBillingProfileItem.quickBookApp.quickBookAppId > 0) ? this.state.accountBillingProfileItem.quickBookApp.quickBookAppId : 0;
        
        let data = {
            quickBookAppId: quickBookAppId,
            isInvoice: false,
            isPurchase: true,
        };
        
        this.props.dispatchApiCallGet(data, 'company_general_form-quickBook_chart_of_account', 'QuickBookApp/purchase-accounts', { items: items }, callback, () => {});
    }

    callXeroReadTaxRatesApi = (items, billingType, callback = null) => {
        let xeroAppId = (this.state.accountBillingProfileItem && this.state.accountBillingProfileItem.xeroApp && this.state.accountBillingProfileItem.xeroApp.xeroAppId && this.state.accountBillingProfileItem.xeroApp.xeroAppId > 0) ? this.state.accountBillingProfileItem.xeroApp.xeroAppId : 0;
        
        let data = {
            xeroAppId: xeroAppId,
        };
        
        if(billingType == BillingTypes.Purchase){
            data['isPurchaseTaxRate'] = true;
        } else {
            data['isInvoiceTaxRate'] = true;
        }
        
        this.props.dispatchApiCallGet(data, 'company_general_form-xero_tax_rates', 'XeroApp/tax-rates', { items: items }, callback, () => {});
    }

    callXeroUpdateChartOfAccountApi = (xeroDefaultChartOfAccountId, callback = null) => {
        let xeroAppId = (this.state.accountBillingProfileItem && this.state.accountBillingProfileItem.xeroApp && this.state.accountBillingProfileItem.xeroApp.xeroAppId && this.state.accountBillingProfileItem.xeroApp.xeroAppId > 0) ? this.state.accountBillingProfileItem.xeroApp.xeroAppId : 0;
        
        let {
            values,
        } = this.props.formOptions;

        let data = {
            xeroAppId: xeroAppId,
            xeroDefaultChartOfAccountId: xeroDefaultChartOfAccountId,
            xeroDefaultPurchaseChartOfAccountId: values.xeroDefaultPurchaseChartOfAccountId,
            xeroDefaultTaxType: values.xeroDefaultTaxType,
            xeroDefaultPurchaseTaxType: values.xeroDefaultPurchaseTaxType,
            xeroPaymentTerm: values.xeroPaymentTerm,
            xeroPaymentTermType: values.xeroPaymentTermType,
        };
        
        this.props.dispatchApiCallPut(data, 'company_general_form-xero_update_chart_of_account', 'xeroapp', null, callback, () => {});
    }
    callXeroUpdatePurchaseChartOfAccountApi = (xeroDefaultPurchaseChartOfAccountId, callback = null) => {
        let xeroAppId = (this.state.accountBillingProfileItem && this.state.accountBillingProfileItem.xeroApp && this.state.accountBillingProfileItem.xeroApp.xeroAppId && this.state.accountBillingProfileItem.xeroApp.xeroAppId > 0) ? this.state.accountBillingProfileItem.xeroApp.xeroAppId : 0;
        
        let {
            values,
        } = this.props.formOptions;

        let data = {
            xeroAppId: xeroAppId,
            xeroDefaultChartOfAccountId: values.xeroDefaultChartOfAccountId,
            xeroDefaultPurchaseChartOfAccountId: xeroDefaultPurchaseChartOfAccountId,
            xeroDefaultTaxType: values.xeroDefaultTaxType,
            xeroDefaultPurchaseTaxType: values.xeroDefaultPurchaseTaxType,
            xeroPaymentTerm: values.xeroPaymentTerm,
            xeroPaymentTermType: values.xeroPaymentTermType,
        };
        
        this.props.dispatchApiCallPut(data, 'company_general_form-xero_update_chart_of_account', 'xeroapp', null, callback, () => {});
    }

    callQuickBookUpdateChartOfAccountApi = (quickBookDefaultChartOfAccountId, callback = null) => {
        let quickBookAppId = (this.state.accountBillingProfileItem && this.state.accountBillingProfileItem.quickBookApp && this.state.accountBillingProfileItem.quickBookApp.quickBookAppId && this.state.accountBillingProfileItem.quickBookApp.quickBookAppId > 0) ? this.state.accountBillingProfileItem.quickBookApp.quickBookAppId : 0;
        
        let {
            values,
        } = this.props.formOptions;

        let data = {
            quickBookAppId: quickBookAppId,
            quickBookDefaultChartOfAccountId: quickBookDefaultChartOfAccountId,
            quickBookDefaultPurchaseChartOfAccountId: values.quickBookDefaultPurchaseChartOfAccountId,
            quickBookDefaultTaxCodeId: values.taxRateId,
            quickBookDefaultTermId: values.paymentTermsId,
            customerMemo: values.customerMemo,
        };
        
        this.props.dispatchApiCallPut(data, 'company_general_form-quickBook_update_chart_of_account', 'quickBookapp', null, callback, () => {});
    }
    callQuickBookUpdatePurchaseChartOfAccountApi = (quickBookDefaultPurchaseChartOfAccountId, callback = null) => {
        let quickBookAppId = (this.state.accountBillingProfileItem && this.state.accountBillingProfileItem.quickBookApp && this.state.accountBillingProfileItem.quickBookApp.quickBookAppId && this.state.accountBillingProfileItem.quickBookApp.quickBookAppId > 0) ? this.state.accountBillingProfileItem.quickBookApp.quickBookAppId : 0;
        
        let {
            values,
        } = this.props.formOptions;

        let data = {
            quickBookAppId: quickBookAppId,
            quickBookDefaultChartOfAccountId: values.quickBookDefaultChartOfAccountId,
            quickBookDefaultPurchaseChartOfAccountId: quickBookDefaultPurchaseChartOfAccountId,
            quickBookDefaultTaxCodeId: values.taxRateId,
            quickBookDefaultTermId: values.paymentTermsId,
            customerMemo: values.customerMemo,
        };
        
        this.props.dispatchApiCallPut(data, 'company_general_form-quickBook_update_chart_of_account', 'quickBookapp', null, callback, () => {});
    }

    callXeroUpdateInvoiceTaxRatesApi = (xeroDefaultTaxType, callback = null) => {
        let xeroAppId = (this.state.accountBillingProfileItem && this.state.accountBillingProfileItem.xeroApp && this.state.accountBillingProfileItem.xeroApp.xeroAppId && this.state.accountBillingProfileItem.xeroApp.xeroAppId > 0) ? this.state.accountBillingProfileItem.xeroApp.xeroAppId : 0;
        
        let {
            values,
        } = this.props.formOptions;

        let data = {
            xeroAppId: xeroAppId,
            xeroDefaultChartOfAccountId: values.xeroDefaultChartOfAccountId,
            xeroDefaultPurchaseChartOfAccountId: values.xeroDefaultPurchaseChartOfAccountId,
            xeroDefaultTaxType: xeroDefaultTaxType,
            xeroDefaultPurchaseTaxType: values.xeroDefaultPurchaseTaxType,
            xeroPaymentTerm: values.xeroPaymentTerm,
            xeroPaymentTermType: values.xeroPaymentTermType,
        };
        
        this.props.dispatchApiCallPut(data, 'company_general_form-xero_update_invoice_tax_rate', 'xeroapp', null, callback, () => {});
    }

    callXeroUpdatePurchaseTaxRatesApi = (xeroDefaultPurchaseTaxType, callback = null) => {
        let xeroAppId = (this.state.accountBillingProfileItem && this.state.accountBillingProfileItem.xeroApp && this.state.accountBillingProfileItem.xeroApp.xeroAppId && this.state.accountBillingProfileItem.xeroApp.xeroAppId > 0) ? this.state.accountBillingProfileItem.xeroApp.xeroAppId : 0;
        
        let {
            values,
        } = this.props.formOptions;

        let data = {
            xeroAppId: xeroAppId,
            xeroDefaultChartOfAccountId: values.xeroDefaultChartOfAccountId,
            xeroDefaultPurchaseChartOfAccountId: values.xeroDefaultPurchaseChartOfAccountId,
            xeroDefaultTaxType: values.xeroDefaultTaxType,
            xeroDefaultPurchaseTaxType: xeroDefaultPurchaseTaxType,
            xeroPaymentTerm: values.xeroPaymentTerm,
            xeroPaymentTermType: values.xeroPaymentTermType,
        };
        
        this.props.dispatchApiCallPut(data, 'company_general_form-xero_update_purchase_tax_rate', 'xeroapp', null, callback, () => {});
    }

    callXeroUpdatePaymentTermTypeApi = (xeroPaymentTerm, xeroPaymentTermType, callback = null) => {
        let xeroAppId = (this.state.accountBillingProfileItem && this.state.accountBillingProfileItem.xeroApp && this.state.accountBillingProfileItem.xeroApp.xeroAppId && this.state.accountBillingProfileItem.xeroApp.xeroAppId > 0) ? this.state.accountBillingProfileItem.xeroApp.xeroAppId : 0;
        
        let {
            values,
        } = this.props.formOptions;

        let data = {
            xeroAppId: xeroAppId,
            xeroDefaultChartOfAccountId: values.xeroDefaultChartOfAccountId,
            xeroDefaultPurchaseChartOfAccountId: values.xeroDefaultPurchaseChartOfAccountId,
            xeroDefaultTaxType: values.xeroDefaultTaxType,
            xeroDefaultPurchaseTaxType: values.xeroDefaultPurchaseTaxType,
            xeroPaymentTerm: xeroPaymentTerm,
            xeroPaymentTermType: xeroPaymentTermType,
        };
        
        this.props.dispatchApiCallPut(data, 'company_general_form-xero_update_payment_term_type', 'xeroapp', null, callback, () => {});
    }
    
    callQuickbookReadCreditTermsApi = (items, callback = null) => {
        let quickBookAppId = (this.state.accountBillingProfileItem && this.state.accountBillingProfileItem.quickBookApp && this.state.accountBillingProfileItem.quickBookApp.quickBookAppId && this.state.accountBillingProfileItem.quickBookApp.quickBookAppId > 0) ? this.state.accountBillingProfileItem.quickBookApp.quickBookAppId : 0;
                
        let data = {
            quickBookAppId: quickBookAppId,
        };
     
        this.props.dispatchApiCallGet(data, 'company_general_form-quickBook_credit_terms', 'quickbookapp/term', { items: items }, callback, () => {});
    }

    callQuickbookUpdateTaxCodeApi = (customerMemo, quickBookDefaultTaxCodeId, quickBookDefaultTermId, callback = null) => {
        let quickBookAppId = (this.state.accountBillingProfileItem && this.state.accountBillingProfileItem.quickBookApp && this.state.accountBillingProfileItem.quickBookApp.quickBookAppId && this.state.accountBillingProfileItem.quickBookApp.quickBookAppId > 0) ? this.state.accountBillingProfileItem.quickBookApp.quickBookAppId : 0;
        
        let {
            values,
        } = this.props.formOptions;
        
        let data = {
            quickBookAppId: quickBookAppId,
            quickBookDefaultTaxCodeId: quickBookDefaultTaxCodeId,
            quickBookDefaultTermId: quickBookDefaultTermId,
            customerMemo: customerMemo,
            quickBookDefaultChartOfAccountId: values.quickBookDefaultChartOfAccountId,
            quickBookDefaultPurchaseChartOfAccountId: values.quickBookDefaultPurchaseChartOfAccountId,
        };
        
        this.props.dispatchApiCallPut(data, 'company_general_form-quickBook_update_tax_code', 'quickbookapp', null, callback, () => {});
    }

    callQuickbookUpdateTermApi = (customerMemo, quickBookDefaultTaxCodeId, quickBookDefaultTermId, callback = null) => {
        let quickBookAppId = (this.state.accountBillingProfileItem && this.state.accountBillingProfileItem.quickBookApp && this.state.accountBillingProfileItem.quickBookApp.quickBookAppId && this.state.accountBillingProfileItem.quickBookApp.quickBookAppId > 0) ? this.state.accountBillingProfileItem.quickBookApp.quickBookAppId : 0;
        
        let {
            values,
        } = this.props.formOptions;

        let data = {
            quickBookAppId: quickBookAppId,
            quickBookDefaultTaxCodeId: quickBookDefaultTaxCodeId,
            quickBookDefaultTermId: quickBookDefaultTermId,
            customerMemo: customerMemo,
            quickBookDefaultChartOfAccountId: values.quickBookDefaultChartOfAccountId,
            quickBookDefaultPurchaseChartOfAccountId: values.quickBookDefaultPurchaseChartOfAccountId,
        };
        
        this.props.dispatchApiCallPut(data, 'company_general_form-quickBook_update_credit_terms', 'quickbookapp', null, callback, () => {});
    }

    callUploadFilesApi = async (file, callback = null) => {
        let auth = this.props.auth;
        let token = (auth && auth.token) ? auth.token : null;
        let dispatchUser = (auth && auth.dispatchUser) ? auth.dispatchUser : null;
        let dispatchAccountId = (dispatchUser && dispatchUser.accountId) ? dispatchUser.accountId : null;
        
        let fName = (file.name && file.name !== '') ? file.name : '';
        let fileExt = fName.split('.').pop();
        let filename = fName.substr(0, fName.lastIndexOf("."));
        let path = 'jobs/company-profile';
        
        DocGenApi.uploadFile(
            file, 
            dispatchAccountId,
            path, 
            filename,
            fileExt,
            token, 
            (progress) => {
                
            }
        ).then((result) => {
            if(result && result.data && result.data.data){
                if(callback){
                    callback(result.data.data);
                }
            } else {
                if(callback){
                    callback(null);
                }
            }
        }).catch(() => {
            if(callback){
                callback(null);
            }
        });
    }
    /* END API */


    body = () => {
        let {
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;
        
		let customerMemo = (values.customerMemo && values.customerMemo != '') ? values.customerMemo : '';
        let customerMemoLength = (customerMemo && customerMemo.length > 0) ? customerMemo.length : 0;
        let customerMemoIsInvalid = (errors && errors.customerMemo && errors.customerMemo);

        return <>
            <Form.Group as={Row} className={'mb-5'}>
                <Form.Label column xs={12} md={3} xl={2}>Audit trail</Form.Label>
                <Col xs={12} md={10} lg={6} xl={4}>
                    {values.isEdit && <IconButton
                        icon={'history'}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            if(this.props.onClickAutidTrail){
                                this.props.onClickAutidTrail();
                            }
                        }}
                    />}
                </Col>
            </Form.Group>

            <Form.Group as={Row} className={'mb-5'}>
                <Form.Label column xs={12} md={3} xl={2}>{this.props.fields.billingProfileName.label}</Form.Label>
                <Col xs={12} md={10} lg={6} xl={4}>
                    <Form.Control
                        type="text"
                        id={this.props.fields.billingProfileName.id}
                        placeholder={this.props.fields.billingProfileName.placeholder}
                        value={values.billingProfileName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.billingProfileName}
                    />
                    <Form.Control.Feedback type="invalid">{errors.billingProfileName}</Form.Control.Feedback>
                </Col>
                {values.isEdit && <Form.Label column xs={12} md={'auto'} className={'mt-5 mt-md-0'}>I.e. Billing by</Form.Label>}
            </Form.Group>
            
            <Form.Group as={Row} className={'mb-5'}>
                <Form.Label column xs={12} md={3} xl={2}>{this.props.fields.isActive.label}</Form.Label>
                <Col xs={12} md={10} lg={6} xl={4}>
                    <Form.Control
                        as = {RSuiteSelectPicker}
                        id={this.props.fields.isActive.id}

                        placeholder={this.props.fields.isActive.placeholder}
                        items={[
                            {
                                value: YesNo.NoValue,
                                title: YesNo.NoTitle,
                            },
                            {
                                value: YesNo.YesValue,
                                title: YesNo.YesTitle,
                            }
                        ]}
                        value={values.isActive ? YesNo.YesValue : YesNo.NoValue}
                        selectedName={values.isActive ? YesNo.YesTitle : YesNo.NoTitle}
                        isLoading={false}
                        searchable={false}
                        cleanable={false}
                        isDefault={true}
                        onSelect={async (value, itemObj) => {
                            let isActive = (value === YesNo.YesValue ? true : false);
                            await setFieldValue('isActive', isActive);
                            await setFieldTouched('isActive', true);
                        }}
                    />
                </Col>
                {values.isEdit && <Col xs={12} md={'auto'} className={'d-flex align-items-center mt-5 mt-lg-0'}>
                    <Form.Check
                        type="switch"
                        id={this.props.fields.isDefault.id}
                        label={this.props.fields.isDefault.label}
                        checked={values.isDefault}
                        onChange={async (e) => {
                            await setFieldValue('isDefault', e.target.checked);
                            await setFieldTouched('isDefault', true);
                        }}
                        isInvalid={!!errors.isDefault}
                        feedback={errors.isDefault}
                    />
                </Col>}
            </Form.Group>
            
            <Form.Group as={Row} className={'mb-5'}>
                <Form.Label column xs={12} md={3} xl={2}>{this.props.fields.companyAddressPlainText.label}</Form.Label>
                <Col xs={12} md={10} lg={6} xl={4}>
                    <Form.Control
                        as = {RTextEditor}
                        isInvalid={!!errors.companyAddressPlainText}
                        id={this.props.fields.companyAddressPlainText.id}

                        editorState={values.companyAddress}
                        placeholder={this.props.fields.companyAddressPlainText.placeholder}
                        onChange={async (plainText, html, editorState) => {
                            await setFieldValue('companyAddressPlainText', plainText);
                            await setFieldValue('companyAddressHTML', html);
                            await setFieldValue('companyAddress', editorState);
                            await setFieldTouched('companyAddressPlainText', true);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.companyAddressPlainText}</Form.Control.Feedback>
                </Col>
            </Form.Group>
            
            <Form.Group as={Row} className={'mb-5'}>
                <Form.Label column xs={12} md={3} xl={2}>{this.props.fields.companyLogo.label}</Form.Label>
                <Col xs={12} md={10} lg={6} xl={4}>
                    <Form.Control
                        type="file"
                        accept={this.props.fields.companyLogo.accept}
                        id={this.props.fields.companyLogo.id}
                        placeholder={this.props.fields.companyLogo.placeholder}
                        disabled={values.customFormParams.companyLogoIsUploading}
                        onChange={async e => {
                            let file = e.currentTarget.files[0];
                            await setFieldValue('companyLogo', file);
                            await setFieldTouched('companyLogoURL', true);
                            
                            validateForm().then(async (err) => {
                                if(err && err.companyLogo){
                                    await setFieldValue('companyLogoURL', null);
                                } else {
                                    try {
                                        await setFieldValue('customFormParams.companyLogoIsUploading', true);
                                        await this.callUploadFilesApi(file, async (url) => {
                                            await setFieldValue('companyLogoURL', url);
                                            await setFieldValue('customFormParams.companyLogoIsUploading', false);
                                        });
                                    } catch(e){
                                        await setFieldValue('companyLogoURL', null);
                                    };
                                }
                            })
                        }}
                        isInvalid={!!errors.companyLogo}
                    />
                    <Form.Control.Feedback type="invalid">{errors.companyLogo}</Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className={'mb-5'}>
                <Form.Label column xs={12} md={3} xl={2} className={'d-none d-lg-flex'}>&nbsp;</Form.Label>
                <Col xs={12} md={10} lg={6} xl={4}>
                    {
                        values.customFormParams.companyLogoIsUploading
                        ?
                        <div 
                            className={'img-thumbnail shadow-none d-flex align-items-center justify-content-center'}
                            style={{ width: '100%', height: '250px', minHeight: '250px' }}
                        >
                            <Spinner animation="border" />
                        </div>
                        :
                            values.companyLogoURL
                            ?
                            <Image 
                                className={'shadow-none'}
                                thumbnail
                                src={values.companyLogoURL}
                            />
                            :
                            <div 
                                className={'img-thumbnail shadow-none'}
                                style={{ width: '100%', height: '250px', minHeight: '250px' }}
                            >&nbsp;</div>
                    }
                </Col>
            </Form.Group>
            <Form.Group as={Row} className={'mb-5'}>
                <Form.Label column xs={12} md={3} xl={2} className={'d-none d-lg-flex'}>&nbsp;</Form.Label>
                <Col xs={12} md={10} lg={6} xl={4}>
                    <Button 
                        className={'w-100'}
                        type={'button'}
                        variant={'danger'}
                        disabled={!values.companyLogoURL}
                        onClick={async () => {
                            await setFieldValue('companyLogoURL', null);
                            await setFieldTouched('companyLogoURL', true);
                        }}
                    >
                        Remove logo
                    </Button>
                </Col>
            </Form.Group>
            
            {(Utils.hasPermission(this.permissions, PagesPermissions.AllowBillingProfileAccountingIntegration.read)) && <>
                {values.isEdit && <>
                    <Row className={'mt-10 mb-5'}>
                        <h5>BILLING</h5>
                    </Row>
                
                    <Form.Group as={Row} className={'mb-5'}>
                        <Form.Label column xs={12} md={3} xl={2}>{this.props.fields.quickbookOrXero.label}</Form.Label>
                        <Col xs={12} md={10} lg={6} xl={4}>
                            <Form.Control
                                as = {RSuiteSelectPicker}
                                id={this.props.fields.quickbookOrXero.id}

                                placeholder={this.props.fields.quickbookOrXero.placeholder}
                                items={[
                                    {
                                        value: QuickbookOrXero.None,
                                        title: 'None',
                                    },
                                    {
                                        value: QuickbookOrXero.Quickbook,
                                        title: 'Quickbook Online',
                                    },
                                    {
                                        value: QuickbookOrXero.Xero,
                                        title: 'Xero Online',
                                    }
                                ]}
                                value={values.customFormParams.quickbookOrXero}
                                selectedName={values.customFormParams.quickbookOrXeroName}
                                disabled={values.customFormParams.isConnectQuickbook || values.customFormParams.isConnectXero}
                                isLoading={false}
                                searchable={false}
                                cleanable={false}
                                isDefault={true}
                                onSelect={async (value, itemObj) => {
                                    await setFieldValue('customFormParams.quickbookOrXero', value);
                                    await setFieldValue('customFormParams.quickbookOrXeroName', itemObj.title);
                                }}
                            />
                        </Col>
                    </Form.Group>

                    {((values.customFormParams.isConnectQuickbook || values.customFormParams.isConnectXero) && (values.customFormParams.quickbookOrXero == QuickbookOrXero.Quickbook)) && <>
                        <Form.Group as={Row} className={'mb-5'}>
                            <Form.Label column xs={12} md={3} xl={2}>{this.props.fields.taxRateId.label}</Form.Label>
                            <Col xs={12} md={10} lg={6} xl={4}>
                                <Form.Control
                                    as = {RSuiteSelectPicker}
                                    isInvalid={!!errors.taxRateId}

                                    id={this.props.fields.taxRateId.id}
                                    placeholder={this.props.fields.taxRateId.placeholder}
                                    items={values.customFormParams.taxRateItems}
                                    value={values.taxRateId}
                                    selectedName={values.taxRateName}
                                    isLoading={values.customFormParams.taxRateIsLoading}
                                    searchable={true}
                                    cleanable={true}
                                    isDefault={true}
                                    onInit={async () => {
                                        await setFieldValue('customFormParams.taxRateIsLoading', true);
                                        this.callQuickbookReadTaxCodeApi(values.customFormParams.taxRateItems, async (items) => {
                                            await setFieldValue('customFormParams.taxRateItems', items);
                                            await setFieldValue('customFormParams.taxRateIsLoading', false);

                                            if(values.taxRateId && items && items.length > 0){
                                                let arr = items.filter(x => x.value == values.taxRateId);
                                                if(arr && arr.length > 0){
                                                    await setFieldValue('taxRateId', arr[0].value);
                                                    await setFieldValue('taxRateName', arr[0].title);
                                                }
                                            }
                                        });
                                    }}
                                    onClick={async () => {
                                        await setFieldValue('customFormParams.taxRateIsLoading', true);
                                        this.callQuickbookReadTaxCodeApi(values.customFormParams.taxRateItems, async (items) => {
                                            await setFieldValue('customFormParams.taxRateItems', items);
                                            await setFieldValue('customFormParams.taxRateIsLoading', false);
                                        });
                                    }}
                                    onSelect={async (value, item) => {
                                        await setFieldValue('taxRateId', value);
                                        await setFieldValue('taxRateName', item.title);
                                        
                                        let quickBookApp = values.customFormParams.quickBookApp;
                                        quickBookApp.quickBookDefaultTaxCodeId = value;
                                        
                                        await setFieldValue('customFormParams.quickBookApp', quickBookApp);
                                        
                                        // this.callQuickbookUpdateTaxCodeApi(values.customerMemo, value, values.paymentTermsId);
                                    }}
                                    onClear={async () => {
                                        await setFieldValue('taxRateId', null);
                                        await setFieldValue('taxRateName', '');
                                        
                                        let quickBookApp = values.customFormParams.quickBookApp;
                                        quickBookApp.quickBookDefaultTaxCodeId = null;
                                        
                                        await setFieldValue('customFormParams.quickBookApp', quickBookApp);

                                        // this.callQuickbookUpdateTaxCodeApi(values.customerMemo, null, values.paymentTermsId);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">{errors.taxRateId}</Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className={'mb-5'}>
                            <Form.Label column xs={12} md={3} xl={2}>{this.props.fields.paymentTermsId.label}</Form.Label>
                            <Col xs={12} md={10} lg={6} xl={4}>
                                <Form.Control
                                    as = {RSuiteSelectPicker}
                                    isInvalid={!!errors.paymentTermsId}

                                    id={this.props.fields.paymentTermsId.id}
                                    placeholder={this.props.fields.paymentTermsId.placeholder}
                                    items={values.customFormParams.paymentTermsItems}
                                    value={values.paymentTermsId}
                                    selectedName={values.paymentTermsName}
                                    isLoading={values.customFormParams.paymentTermsIsLoading}
                                    searchable={true}
                                    cleanable={true}
                                    isDefault={true}
                                    onInit={async () => {
                                        await setFieldValue('customFormParams.paymentTermsIsLoading', true);
                                        this.callQuickbookReadCreditTermsApi(values.customFormParams.paymentTermsItems, async (items) => {
                                            await setFieldValue('customFormParams.paymentTermsItems', items);
                                            await setFieldValue('customFormParams.paymentTermsIsLoading', false);

                                            if(values.paymentTermsId && items && items.length > 0){
                                                let arr = items.filter(x => x.value == values.paymentTermsId);
                                                if(arr && arr.length > 0){
                                                    await setFieldValue('paymentTermsId', arr[0].value);
                                                    await setFieldValue('paymentTermsName', arr[0].title);
                                                }
                                            }
                                        });
                                    }}
                                    onClick={async () => {
                                        await setFieldValue('customFormParams.paymentTermsIsLoading', true);
                                        this.callQuickbookReadCreditTermsApi(values.customFormParams.paymentTermsItems, async (items) => {
                                            await setFieldValue('customFormParams.paymentTermsItems', items);
                                            await setFieldValue('customFormParams.paymentTermsIsLoading', false);
                                        });
                                    }}
                                    onSelect={async (value, item) => {
                                        await setFieldValue('paymentTermsId', value);
                                        await setFieldValue('paymentTermsName', item.title);

                                        let quickBookApp = values.customFormParams.quickBookApp;
                                        quickBookApp.quickBookDefaultTermId = value;

                                        await setFieldValue('customFormParams.quickBookApp', quickBookApp);

                                        // this.callQuickbookUpdateTermApi(values.customerMemo, values.taxRateId, value);
                                    }}
                                    onClear={async () => {
                                        await setFieldValue('paymentTermsId', null);
                                        await setFieldValue('paymentTermsName', '');

                                        let quickBookApp = values.customFormParams.quickBookApp;
                                        quickBookApp.quickBookDefaultTermId = null;

                                        await setFieldValue('customFormParams.quickBookApp', quickBookApp);

                                        // this.callQuickbookUpdateTermApi(values.customerMemo, values.taxRateId, null);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">{errors.paymentTermsId}</Form.Control.Feedback>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className={'mb-5'}>
                            <Form.Label column xs={12} md={3} xl={2}>{this.props.fields.quickBookDefaultChartOfAccountId.label}</Form.Label>
                            <Col xs={12} md={10} lg={6} xl={4}>
                                <Form.Control
                                    as = {RSuiteSelectPicker}
                                    isInvalid={!!errors.quickBookDefaultChartOfAccountId}

                                    id={this.props.fields.quickBookDefaultChartOfAccountId.id}
                                    placeholder={this.props.fields.quickBookDefaultChartOfAccountId.placeholder}
                                    items={values.customFormParams.quickBookDefaultChartOfAccountItems}
                                    value={values.quickBookDefaultChartOfAccountId}
                                    selectedName={values.quickBookDefaultChartOfAccountName}
                                    isLoading={values.customFormParams.quickBookDefaultChartOfAccountIsLoading}
                                    // groupBy={'type'}
                                    renderMenuGroup={(label, item) => <b>{label}</b>}

                                    searchable={true}
                                    cleanable={true}
                                    isDefault={true}
                                    onInit={async () => {
                                        await setFieldValue('customFormParams.quickBookDefaultChartOfAccountIsLoading', true);
                                        this.callQuickBookReadChartOfAccountApi(values.customFormParams.quickBookDefaultChartOfAccountItems, async (items) => {
                                            await setFieldValue('customFormParams.quickBookDefaultChartOfAccountItems', items);
                                            await setFieldValue('customFormParams.quickBookDefaultChartOfAccountIsLoading', false);

                                            if(values.quickBookDefaultChartOfAccountId && items && items.length > 0){
                                                let arr = items.filter(x => x.value == values.quickBookDefaultChartOfAccountId);
                                                if(arr && arr.length > 0){
                                                    await setFieldValue('quickBookDefaultChartOfAccountId', arr[0].value);
                                                    await setFieldValue('quickBookDefaultChartOfAccountName', arr[0].title);
                                                }
                                            }
                                        });
                                    }}
                                    onClick={async () => {
                                        await setFieldValue('customFormParams.quickBookDefaultChartOfAccountIsLoading', true);
                                        this.callQuickBookReadChartOfAccountApi(values.customFormParams.quickBookDefaultChartOfAccountItems, async (items) => {
                                            await setFieldValue('customFormParams.quickBookDefaultChartOfAccountItems', items);
                                            await setFieldValue('customFormParams.quickBookDefaultChartOfAccountIsLoading', false);
                                        });
                                    }}
                                    onSelect={async (value, item) => {
                                        await setFieldValue('quickBookDefaultChartOfAccountId', value);
                                        await setFieldValue('quickBookDefaultChartOfAccountName', item.title);
                                        
                                        let quickBookApp = values.customFormParams.quickBookApp;
                                        quickBookApp.quickbookDefaultChartOfAccountId = value;
                                        
                                        await setFieldValue('customFormParams.quickBookApp', quickBookApp);
                                        
                                        // this.callQuickBookUpdateChartOfAccountApi(value);
                                    }}
                                    onClear={async () => {
                                        await setFieldValue('quickBookDefaultChartOfAccountId', null);
                                        await setFieldValue('quickBookDefaultChartOfAccountName', '');
                                        
                                        let quickBookApp = values.customFormParams.quickBookApp;
                                        quickBookApp.quickBookDefaultChartOfAccountId = null;
                                        
                                        await setFieldValue('customFormParams.quickBookApp', quickBookApp);

                                        // this.callQuickBookUpdateChartOfAccountApi(null);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">{errors.quickBookDefaultChartOfAccountId}</Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className={'mb-5'}>
                            <Form.Label column xs={12} md={3} xl={2}>{this.props.fields.quickBookDefaultPurchaseChartOfAccountId.label}</Form.Label>
                            <Col xs={12} md={10} lg={6} xl={4}>
                                <Form.Control
                                    as = {RSuiteSelectPicker}
                                    isInvalid={!!errors.quickBookDefaultPurchaseChartOfAccountId}

                                    id={this.props.fields.quickBookDefaultPurchaseChartOfAccountId.id}
                                    placeholder={this.props.fields.quickBookDefaultPurchaseChartOfAccountId.placeholder}
                                    items={values.customFormParams.quickBookDefaultPurchaseChartOfAccountItems}
                                    value={values.quickBookDefaultPurchaseChartOfAccountId}
                                    selectedName={values.quickBookDefaultPurchaseChartOfAccountName}
                                    isLoading={values.customFormParams.quickBookDefaultPurchaseChartOfAccountIsLoading}
                                    // groupBy={'type'}
                                    renderMenuGroup={(label, item) => <b>{label}</b>}

                                    searchable={true}
                                    cleanable={true}
                                    isDefault={true}
                                    onInit={async () => {
                                        await setFieldValue('customFormParams.quickBookDefaultPurchaseChartOfAccountIsLoading', true);
                                        this.callQuickBookReadPurchaseChartOfAccountApi(values.customFormParams.quickBookDefaultPurchaseChartOfAccountItems, async (items) => {
                                            await setFieldValue('customFormParams.quickBookDefaultPurchaseChartOfAccountItems', items);
                                            await setFieldValue('customFormParams.quickBookDefaultPurchaseChartOfAccountIsLoading', false);

                                            if(values.quickBookDefaultPurchaseChartOfAccountId && items && items.length > 0){
                                                let arr = items.filter(x => x.value == values.quickBookDefaultPurchaseChartOfAccountId);
                                                if(arr && arr.length > 0){
                                                    await setFieldValue('quickBookDefaultPurchaseChartOfAccountId', arr[0].value);
                                                    await setFieldValue('quickBookDefaultPurchaseChartOfAccountName', arr[0].title);
                                                }
                                            }
                                        });
                                    }}
                                    onClick={async () => {
                                        await setFieldValue('customFormParams.quickBookDefaultPurchaseChartOfAccountIsLoading', true);
                                        this.callQuickBookReadPurchaseChartOfAccountApi(values.customFormParams.quickBookDefaultPurchaseChartOfAccountItems, async (items) => {
                                            await setFieldValue('customFormParams.quickBookDefaultPurchaseChartOfAccountItems', items);
                                            await setFieldValue('customFormParams.quickBookDefaultPurchaseChartOfAccountIsLoading', false);
                                        });
                                    }}
                                    onSelect={async (value, item) => {
                                        await setFieldValue('quickBookDefaultPurchaseChartOfAccountId', value);
                                        await setFieldValue('quickBookDefaultPurchaseChartOfAccountName', item.title);
                                        
                                        let quickBookApp = values.customFormParams.quickBookApp;
                                        quickBookApp.quickBookDefaultPurchaseChartOfAccountId = value;
                                        
                                        await setFieldValue('customFormParams.quickBookApp', quickBookApp);
                                        
                                        // this.callQuickBookUpdatePurchaseChartOfAccountApi(value);
                                    }}
                                    onClear={async () => {
                                        await setFieldValue('quickBookDefaultPurchaseChartOfAccountId', null);
                                        await setFieldValue('quickBookDefaultPurchaseChartOfAccountName', '');
                                        
                                        let quickBookApp = values.customFormParams.quickBookApp;
                                        quickBookApp.quickBookDefaultPurchaseChartOfAccountId = null;
                                        
                                        await setFieldValue('customFormParams.quickBookApp', quickBookApp);

                                        // this.callQuickBookUpdatePurchaseChartOfAccountApi(null);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">{errors.quickBookDefaultPurchaseChartOfAccountId}</Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        
                        <Form.Group as={Row} className={'mb-5'}>
                            <Form.Label column xs={12} md={3} xl={2} className={'d-none d-lg-block'}>&nbsp;</Form.Label>
                            <Col xs={12} md={10} lg={6} xl={4}>
                                <a href={'/'}
                                    className={'link-primary'}
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        
                                        let quickBookAppId = (values && values.customFormParams && values.customFormParams.quickBookApp && values.customFormParams.quickBookApp.quickBookAppId) ? values.customFormParams.quickBookApp.quickBookAppId : null;
                                        
                                        await this.props.action.advancedMappingQuickBook.show(quickBookAppId);
                                        await this.props.action.advancedMappingQuickBook.callQuickBookReadChartOfAccountApi(quickBookAppId);
                                        await this.props.action.advancedMappingQuickBook.callQuickBookReadPurchaseChartOfAccountApi(quickBookAppId);
                                        await this.props.action.advancedMappingQuickBook.callReadApi();
                                        await this.props.action.advancedMappingQuickBook.setColumns([
                                            {
                                              id: 'chargeCategoryId',
                                              filterKey: 'chargeCategoryId',
                                              name: 'chargeCategoryId',
                                              label: 'ID',
                                              visible: false
                                            },
                                            {
                                              id: "chargeCategoryName",
                                              name: "chargeCategoryName",
                                              label: "NAME",
                                              responsiveLabel: "NAME",
                                              orderable: true,
                                            },
                                            {
                                              id: "billingTypeDisplay",
                                              name: "billingType",
                                              label: "BILLING ACTION",
                                              responsiveLabel: "BILLING ACTION",
                                              orderable: true,
                                            },
                                            {
                                              id: "useBinWeightQuantity",
                                              name: "useBinWeightQuantity",
                                              label: "LINK BILLABLE WEIGHT TO SERVICE ITEM QTY",
                                              responsiveLabel: "LINK BILLABLE WEIGHT TO SERVICE ITEM QTY",
                                              orderable: true,
                                              template: (id: any, row: any, col: any, obj: any) => {
                                                return row.useBinWeightQuantity === true ? "Yes" : "No";
                                              },
                                            },
                                            {
                                              id: "chargeCategoryQuickBookItems",
                                              name: "chargeCategoryQuickBookItems",
                                              label: "DEFAULT PRODUCT ACCOUNT",
                                              responsiveLabel: "DEFAULT PRODUCT ACCOUNT",
                                              width: '400px',
                                              orderable: false,
                                              template: (id: any, row: any, col: any, obj: any) => {
                                                let chartOfAccountItems = _.cloneDeep(this.props.advancedMappingQuickBook.chartOfAccountItems);

                                                let chargeCategoryQuickBookItems = row.chargeCategoryQuickBookItems;
                                                let quickBookDefaultPurchaseChartOfAccountId = (chargeCategoryQuickBookItems && chargeCategoryQuickBookItems.length > 0) ? chargeCategoryQuickBookItems[0].quickBookDefaultPurchaseChartOfAccountId : null;
                                                let value = (chargeCategoryQuickBookItems && chargeCategoryQuickBookItems.length > 0) ? chargeCategoryQuickBookItems[0].quickBookDefaultChartOfAccountId : null;
                                                let selectedItems = (value) ? chartOfAccountItems.filter(x => x.value == value) : null;
                                                let selectedItem = (selectedItems && selectedItems.length > 0) ? selectedItems[0] : null;
                                                let selectedName = (selectedItem) ? selectedItem.title : '';

                                                return <Form.Control
                                                    as = {RSuiteSelectPicker}
                                            
                                                    id={'chartOfAccountItemsId_' + id}
                                                    placeholder={'Default product account'}
                                                    items={chartOfAccountItems}
                                                    value={value}
                                                    selectedName={selectedName}
                                                    isLoading={this.props.advancedMappingQuickBook.chartOfAccountIsLoading}
                                                    groupBy={'type'}
                                                    renderMenuGroup={(label: any) => <b>{label}</b>}
                                            
                                                    searchable={true}
                                                    cleanable={true}
                                                    isDefault={true}
                                                    onInit={async () => {
                                                        
                                                    }}
                                                    onClick={async () => {
                                                        
                                                    }}
                                                    onSelect={async (value: any, item: any) => {
                                                        let items = _.cloneDeep(this.props.advancedMappingQuickBook.items);
                                                        let index = items.findIndex(x => x.chargeCategoryId == id);
                                                        if(index > -1){
                                                            items[index].chargeCategoryQuickBookItems = [{ 
                                                                quickBookDefaultChartOfAccountId: value,
                                                                quickBookDefaultPurchaseChartOfAccountId: quickBookDefaultPurchaseChartOfAccountId,
                                                            }]
                                                            await this.props.action.advancedMappingQuickBook.setItems(items);

                                                            let params = {
                                                                chargeCategoryId: id,
                                                                quickBookAppId: this.props.advancedMappingQuickBook.quickBookAppId,
                                                                quickBookDefaultPurchaseChartOfAccountId: quickBookDefaultPurchaseChartOfAccountId,
                                                                quickBookDefaultChartOfAccountId: value,
                                                            }
                                                            await this.props.action.advancedMappingQuickBook.callUpdateApi(params);
                                                        }
                                                    }}
                                                    onClear={async () => {
                                                        let items = _.cloneDeep(this.props.advancedMappingQuickBook.items);
                                                        let index = items.findIndex(x => x.chargeCategoryId == id);
                                                        if(index > -1){
                                                            items[index].chargeCategoryQuickBookItems = [{ 
                                                                quickBookDefaultChartOfAccountId: '',
                                                                quickBookDefaultPurchaseChartOfAccountId: quickBookDefaultPurchaseChartOfAccountId,
                                                            }]

                                                            await this.props.action.advancedMappingQuickBook.setItems(items);

                                                            let params = {
                                                                chargeCategoryId: id,
                                                                quickBookAppId: this.props.advancedMappingQuickBook.quickBookAppId,
                                                                quickBookDefaultPurchaseChartOfAccountId: quickBookDefaultPurchaseChartOfAccountId,
                                                                quickBookDefaultChartOfAccountId: '',
                                                            }
                                                            await this.props.action.advancedMappingQuickBook.callUpdateApi(params);
                                                        }
                                                    }}
                                                />
                                              },
                                            },
                                            {
                                              id: "purchaseChargeCategoryQuickBookItems",
                                              name: "purchaseChargeCategoryQuickBookItems",
                                              label: "DEFAULT PURCHASE PRODUCT ACCOUNT",
                                              responsiveLabel: "DEFAULT PURCHASE PRODUCT ACCOUNT",
                                              width: '400px',
                                              orderable: false,
                                              template: (id: any, row: any, col: any, obj: any) => {
                                                let purchaseChartOfAccountItems = _.cloneDeep(this.props.advancedMappingQuickBook.purchaseChartOfAccountItems);

                                                let chargeCategoryQuickBookItems = row.chargeCategoryQuickBookItems;
                                                let quickBookDefaultChartOfAccountId = (chargeCategoryQuickBookItems && chargeCategoryQuickBookItems.length > 0) ? chargeCategoryQuickBookItems[0].quickBookDefaultChartOfAccountId : null;
                                                let value = (chargeCategoryQuickBookItems && chargeCategoryQuickBookItems.length > 0) ? chargeCategoryQuickBookItems[0].quickBookDefaultPurchaseChartOfAccountId : null;
                                                let selectedItems = (value) ? purchaseChartOfAccountItems.filter(x => x.value == value) : null;
                                                let selectedItem = (selectedItems && selectedItems.length > 0) ? selectedItems[0] : null;
                                                let selectedName = (selectedItem) ? selectedItem.title : '';

                                                return <Form.Control
                                                    as = {RSuiteSelectPicker}
                                            
                                                    id={'purchaseChartOfAccountItemsId_' + id}
                                                    placeholder={'Default purchase product account'}
                                                    items={purchaseChartOfAccountItems}
                                                    value={value}
                                                    selectedName={selectedName}
                                                    isLoading={this.props.advancedMappingQuickBook.purchaseChartOfAccountIsLoading}
                                                    groupBy={'type'}
                                                    renderMenuGroup={(label: any) => <b>{label}</b>}
                                            
                                                    searchable={true}
                                                    cleanable={true}
                                                    isDefault={true}
                                                    onInit={async () => {
                                                        
                                                    }}
                                                    onClick={async () => {
                                                        
                                                    }}
                                                    onSelect={async (value: any, item: any) => {
                                                        let items = _.cloneDeep(this.props.advancedMappingQuickBook.items);
                                                        let index = items.findIndex(x => x.chargeCategoryId == id);
                                                        if(index > -1){
                                                            items[index].chargeCategoryQuickBookItems = [{ 
                                                                quickBookDefaultChartOfAccountId: quickBookDefaultChartOfAccountId,
                                                                quickBookDefaultPurchaseChartOfAccountId: value
                                                            }]
                                                            await this.props.action.advancedMappingQuickBook.setItems(items);

                                                            let params = {
                                                                chargeCategoryId: id,
                                                                quickBookAppId: this.props.advancedMappingQuickBook.quickBookAppId,
                                                                quickBookDefaultChartOfAccountId: quickBookDefaultChartOfAccountId,
                                                                quickBookDefaultPurchaseChartOfAccountId: value,
                                                            }
                                                            await this.props.action.advancedMappingQuickBook.callUpdateApi(params);
                                                        }
                                                    }}
                                                    onClear={async () => {
                                                        let items = _.cloneDeep(this.props.advancedMappingQuickBook.items);
                                                        let index = items.findIndex(x => x.chargeCategoryId == id);
                                                        if(index > -1){
                                                            items[index].chargeCategoryQuickBookItems = [{ 
                                                                quickBookDefaultChartOfAccountId: quickBookDefaultChartOfAccountId,
                                                                quickBookDefaultPurchaseChartOfAccountId: ''
                                                            }]
                                                            await this.props.action.advancedMappingQuickBook.setItems(items);

                                                            let params = {
                                                                chargeCategoryId: id,
                                                                quickBookAppId: this.props.advancedMappingQuickBook.quickBookAppId,
                                                                quickBookDefaultChartOfAccountId: quickBookDefaultChartOfAccountId,
                                                                quickBookDefaultPurchaseChartOfAccountId: '',
                                                            }
                                                            await this.props.action.advancedMappingQuickBook.callUpdateApi(params);
                                                        }
                                                    }}
                                                />
                                              },
                                            }
                                        ]);
                                    }}
                                >
                                    Advanced
                                </a>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className={'mb-5'}>
                            <Form.Label column xs={12} md={3} xl={2}>{this.props.fields.customerMemo.label}</Form.Label>
                            <Col xs={12} md={10} lg={6} xl={4}>
                                <Form.Control
                                    as="textarea"
                                    rows={5}
                                    id={this.props.fields.customerMemo.id}
                                    placeholder={this.props.fields.customerMemo.placeholder}
                                    value={customerMemo}
                                    onChange={async (e) => {
                                        Utils.limitRows(e.target.value, 5, async (value) => {
                                            await setFieldValue('customerMemo', value);
                                        });
                                    }}
                                    onBlur={handleBlur}
                                    isInvalid={!!customerMemoIsInvalid}
                                />
                                <div style={{ fontSize: '12px', textAlign: 'right' }} className={(!!customerMemoIsInvalid) ? 'text-danger' : 'text-muted'}>Message length: {customerMemoLength}/{this.props.fields.customerMemo.maxLength}</div>
                            </Col>
                        </Form.Group>
                    </>}
                    
                    {((values.customFormParams.isConnectQuickbook || values.customFormParams.isConnectXero) && (values.customFormParams.quickbookOrXero == QuickbookOrXero.Xero)) && <>
                        <Form.Group as={Row} className={'mb-5'}>
                            <Form.Label column xs={12} md={3} xl={2}>{this.props.fields.xeroPaymentTerm.label}</Form.Label>
                            <Col xs={12} md={10} lg={6} xl={4}>
                                <InputGroup>
                                    <Form.Control
                                        type="number"
                                        id={this.props.fields.xeroPaymentTerm.id}
                                        placeholder={this.props.fields.xeroPaymentTerm.placeholder}
                                        value={values.xeroPaymentTerm}
                                        onChange={async (e) => {
                                            await setFieldValue('xeroPaymentTerm', e.target.value);
                                            await setFieldValue('paymentTermsId', null);
                                            await setFieldValue('paymentTermsName', '');
                                        }}
                                        onBlur={async (e) => {
                                            validateForm().then(async (err) => {
                                                if(err && err.xeroPaymentTerm){
                                                } else {
                                                    // this.callXeroUpdatePaymentTermTypeApi(e.target.value, values.xeroPaymentTermType, values.paymentTermsId);
                                                }
                                            })
                                        }}
                                        isInvalid={!!errors.xeroPaymentTerm}
                                    />
                                    <StyledDropdownButton
                                        title={Utils.getXeroPaymentTermTypeName(values.xeroPaymentTermType)}
                                        variant={'secondary'}
                                        menuAlign={'right'}
                                    >
                                        {XeroPaymentTermTypeItems.map((item, i) => {
                                            return <Dropdown.Item
                                                className={'py-2'}
                                                key={item.value + '_' + item.title + '_' + i}
                                                active={(item.value === values.xeroPaymentTermType)}
                                                onClick={async () => {
                                                    await setFieldValue('xeroPaymentTermType', item.value);

                                                    validateForm().then(async (err) => {
                                                        if(err && err.xeroPaymentTerm){
                                                        } else {
                                                            // this.callXeroUpdatePaymentTermTypeApi(values.xeroPaymentTerm, item.value, values.paymentTermsId);
                                                        }
                                                    })
                                                }}
                                            >{item.title}</Dropdown.Item>
                                        })}
                                    </StyledDropdownButton>
                                    <Form.Control.Feedback type="invalid">{errors.xeroPaymentTerm}</Form.Control.Feedback>
                                </InputGroup>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className={'mb-5'}>
                            <Form.Label column xs={12} md={3} xl={2}>{this.props.fields.xeroDefaultChartOfAccountId.label}</Form.Label>
                            <Col xs={12} md={10} lg={6} xl={4}>
                                <Form.Control
                                    as = {RSuiteSelectPicker}
                                    isInvalid={!!errors.xeroDefaultChartOfAccountId}

                                    id={this.props.fields.xeroDefaultChartOfAccountId.id}
                                    placeholder={this.props.fields.xeroDefaultChartOfAccountId.placeholder}
                                    items={values.customFormParams.xeroDefaultChartOfAccountItems}
                                    value={values.xeroDefaultChartOfAccountId}
                                    selectedName={values.xeroDefaultChartOfAccountName}
                                    isLoading={values.customFormParams.xeroDefaultChartOfAccountIsLoading}
                                    groupBy={'type'}
                                    renderMenuGroup={(label, item) => <b>{label}</b>}

                                    searchable={true}
                                    cleanable={true}
                                    isDefault={true}
                                    onInit={async () => {
                                        await setFieldValue('customFormParams.xeroDefaultChartOfAccountIsLoading', true);
                                        this.callXeroReadChartOfAccountApi(values.customFormParams.xeroDefaultChartOfAccountItems, async (items) => {
                                            await setFieldValue('customFormParams.xeroDefaultChartOfAccountItems', items);
                                            await setFieldValue('customFormParams.xeroDefaultChartOfAccountIsLoading', false);

                                            if(values.xeroDefaultChartOfAccountId && items && items.length > 0){
                                                let arr = items.filter(x => x.value == values.xeroDefaultChartOfAccountId);
                                                if(arr && arr.length > 0){
                                                    await setFieldValue('xeroDefaultChartOfAccountId', arr[0].value);
                                                    await setFieldValue('xeroDefaultChartOfAccountName', arr[0].title);
                                                }
                                            }
                                        });
                                    }}
                                    onClick={async () => {
                                        await setFieldValue('customFormParams.xeroDefaultChartOfAccountIsLoading', true);
                                        this.callXeroReadChartOfAccountApi(values.customFormParams.xeroDefaultChartOfAccountItems, async (items) => {
                                            await setFieldValue('customFormParams.xeroDefaultChartOfAccountItems', items);
                                            await setFieldValue('customFormParams.xeroDefaultChartOfAccountIsLoading', false);
                                        });
                                    }}
                                    onSelect={async (value, item) => {
                                        await setFieldValue('xeroDefaultChartOfAccountId', value);
                                        await setFieldValue('xeroDefaultChartOfAccountName', item.title);
                                        
                                        let xeroApp = values.customFormParams.xeroApp;
                                        xeroApp.xeroDefaultChartOfAccountId = value;
                                        
                                        await setFieldValue('customFormParams.xeroApp', xeroApp);
                                        
                                        // this.callXeroUpdateChartOfAccountApi(value);
                                    }}
                                    onClear={async () => {
                                        await setFieldValue('xeroDefaultChartOfAccountId', null);
                                        await setFieldValue('xeroDefaultChartOfAccountName', '');
                                        
                                        let xeroApp = values.customFormParams.xeroApp;
                                        xeroApp.xeroDefaultChartOfAccountId = null;
                                        
                                        await setFieldValue('customFormParams.xeroApp', xeroApp);

                                        // this.callXeroUpdateChartOfAccountApi(null);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">{errors.xeroDefaultChartOfAccountId}</Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className={'mb-5'}>
                            <Form.Label column xs={12} md={3} xl={2}>{this.props.fields.xeroDefaultPurchaseChartOfAccountId.label}</Form.Label>
                            <Col xs={12} md={10} lg={6} xl={4}>
                                <Form.Control
                                    as = {RSuiteSelectPicker}
                                    isInvalid={!!errors.xeroDefaultPurchaseChartOfAccountId}

                                    id={this.props.fields.xeroDefaultPurchaseChartOfAccountId.id}
                                    placeholder={this.props.fields.xeroDefaultPurchaseChartOfAccountId.placeholder}
                                    items={values.customFormParams.xeroDefaultPurchaseChartOfAccountItems}
                                    value={values.xeroDefaultPurchaseChartOfAccountId}
                                    selectedName={values.xeroDefaultPurchaseChartOfAccountName}
                                    isLoading={values.customFormParams.xeroDefaultPurchaseChartOfAccountIsLoading}
                                    groupBy={'type'}
                                    renderMenuGroup={(label, item) => <b>{label}</b>}

                                    searchable={true}
                                    cleanable={true}
                                    isDefault={true}
                                    onInit={async () => {
                                        await setFieldValue('customFormParams.xeroDefaultPurchaseChartOfAccountIsLoading', true);
                                        this.callXeroReadPurchaseChartOfAccountApi(values.customFormParams.xeroDefaultPurchaseChartOfAccountItems, async (items) => {
                                            await setFieldValue('customFormParams.xeroDefaultPurchaseChartOfAccountItems', items);
                                            await setFieldValue('customFormParams.xeroDefaultPurchaseChartOfAccountIsLoading', false);

                                            if(values.xeroDefaultPurchaseChartOfAccountId && items && items.length > 0){
                                                let arr = items.filter(x => x.value == values.xeroDefaultPurchaseChartOfAccountId);
                                                if(arr && arr.length > 0){
                                                    await setFieldValue('xeroDefaultPurchaseChartOfAccountId', arr[0].value);
                                                    await setFieldValue('xeroDefaultPurchaseChartOfAccountName', arr[0].title);
                                                }
                                            }
                                        });
                                    }}
                                    onClick={async () => {
                                        await setFieldValue('customFormParams.xeroDefaultPurchaseChartOfAccountIsLoading', true);
                                        this.callXeroReadPurchaseChartOfAccountApi(values.customFormParams.xeroDefaultPurchaseChartOfAccountItems, async (items) => {
                                            await setFieldValue('customFormParams.xeroDefaultPurchaseChartOfAccountItems', items);
                                            await setFieldValue('customFormParams.xeroDefaultPurchaseChartOfAccountIsLoading', false);
                                        });
                                    }}
                                    onSelect={async (value, item) => {
                                        await setFieldValue('xeroDefaultPurchaseChartOfAccountId', value);
                                        await setFieldValue('xeroDefaultPurchaseChartOfAccountName', item.title);
                                        
                                        let xeroApp = values.customFormParams.xeroApp;
                                        xeroApp.xeroDefaultPurchaseChartOfAccountId = value;
                                        
                                        await setFieldValue('customFormParams.xeroApp', xeroApp);
                                        
                                        // this.callXeroUpdatePurchaseChartOfAccountApi(value);
                                    }}
                                    onClear={async () => {
                                        await setFieldValue('xeroDefaultPurchaseChartOfAccountId', null);
                                        await setFieldValue('xeroDefaultPurchaseChartOfAccountName', '');
                                        
                                        let xeroApp = values.customFormParams.xeroApp;
                                        xeroApp.xeroDefaultPurchaseChartOfAccountId = null;
                                        
                                        await setFieldValue('customFormParams.xeroApp', xeroApp);

                                        // this.callXeroUpdatePurchaseChartOfAccountApi(null);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">{errors.xeroDefaultPurchaseChartOfAccountId}</Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        
                        <Form.Group as={Row} className={'mb-5'}>
                            <Form.Label column xs={12} md={3} xl={2} className={'d-none d-lg-block'}>&nbsp;</Form.Label>
                            <Col xs={12} md={10} lg={6} xl={4}>
                                <a href={'/'}
                                    className={'link-primary'}
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        
                                        let xeroAppId = (values && values.customFormParams && values.customFormParams.xeroApp && values.customFormParams.xeroApp.xeroAppId) ? values.customFormParams.xeroApp.xeroAppId : null;
                                        
                                        await this.props.action.advancedMappingXero.show(xeroAppId);
                                        await this.props.action.advancedMappingXero.callXeroReadChartOfAccountApi(xeroAppId);
                                        await this.props.action.advancedMappingXero.callXeroReadPurchaseChartOfAccountApi(xeroAppId);
                                        await this.props.action.advancedMappingXero.callReadApi();
                                        await this.props.action.advancedMappingXero.setColumns([
                                            {
                                              id: 'chargeCategoryId',
                                              filterKey: 'chargeCategoryId',
                                              name: 'chargeCategoryId',
                                              label: 'ID',
                                              visible: false
                                            },
                                            {
                                              id: "chargeCategoryName",
                                              name: "chargeCategoryName",
                                              label: "NAME",
                                              responsiveLabel: "NAME",
                                              orderable: true,
                                            },
                                            {
                                              id: "billingTypeDisplay",
                                              name: "billingType",
                                              label: "BILLING ACTION",
                                              responsiveLabel: "BILLING ACTION",
                                              orderable: true,
                                            },
                                            {
                                              id: "useBinWeightQuantity",
                                              name: "useBinWeightQuantity",
                                              label: "LINK BILLABLE WEIGHT TO SERVICE ITEM QTY",
                                              responsiveLabel: "LINK BILLABLE WEIGHT TO SERVICE ITEM QTY",
                                              orderable: true,
                                              template: (id: any, row: any, col: any, obj: any) => {
                                                return row.useBinWeightQuantity === true ? "Yes" : "No";
                                              },
                                            },
                                            {
                                              id: "chargeCategoryXeroItems",
                                              name: "chargeCategoryXeroItems",
                                              label: "DEFAULT PRODUCT ACCOUNT",
                                              responsiveLabel: "DEFAULT PRODUCT ACCOUNT",
                                              width: '400px',
                                              orderable: false,
                                              template: (id: any, row: any, col: any, obj: any) => {
                                                let chartOfAccountItems = _.cloneDeep(this.props.advancedMappingXero.chartOfAccountItems);

                                                let chargeCategoryXeroItems = row.chargeCategoryXeroItems;
                                                let xeroDefaultPurchaseChartOfAccountId = (chargeCategoryXeroItems && chargeCategoryXeroItems.length > 0) ? chargeCategoryXeroItems[0].xeroDefaultPurchaseChartOfAccountId : null;
                                                let value = (chargeCategoryXeroItems && chargeCategoryXeroItems.length > 0) ? chargeCategoryXeroItems[0].xeroDefaultChartOfAccountId : null;
                                                let selectedItems = (value) ? chartOfAccountItems.filter(x => x.value == value) : null;
                                                let selectedItem = (selectedItems && selectedItems.length > 0) ? selectedItems[0] : null;
                                                let selectedName = (selectedItem) ? selectedItem.title : '';

                                                return <Form.Control
                                                    as = {RSuiteSelectPicker}
                                            
                                                    id={'chartOfAccountItemsId_' + id}
                                                    placeholder={'Default product account'}
                                                    items={chartOfAccountItems}
                                                    value={value}
                                                    selectedName={selectedName}
                                                    isLoading={this.props.advancedMappingXero.chartOfAccountIsLoading}
                                                    groupBy={'type'}
                                                    renderMenuGroup={(label: any) => <b>{label}</b>}
                                            
                                                    searchable={true}
                                                    cleanable={true}
                                                    isDefault={true}
                                                    onInit={async () => {
                                                        
                                                    }}
                                                    onClick={async () => {
                                                        
                                                    }}
                                                    onSelect={async (value: any, item: any) => {
                                                        let items = _.cloneDeep(this.props.advancedMappingXero.items);
                                                        let index = items.findIndex(x => x.chargeCategoryId == id);
                                                        if(index > -1){
                                                            items[index].chargeCategoryXeroItems = [{ 
                                                                xeroDefaultChartOfAccountId: value,
                                                                xeroDefaultPurchaseChartOfAccountId: xeroDefaultPurchaseChartOfAccountId,
                                                            }]
                                                            await this.props.action.advancedMappingXero.setItems(items);

                                                            let params = {
                                                                chargeCategoryId: id,
                                                                xeroAppId: this.props.advancedMappingXero.xeroAppId,
                                                                xeroDefaultPurchaseChartOfAccountId: xeroDefaultPurchaseChartOfAccountId,
                                                                xeroDefaultChartOfAccountId: value,
                                                            }
                                                            await this.props.action.advancedMappingXero.callUpdateApi(params);
                                                        }
                                                    }}
                                                    onClear={async () => {
                                                        let items = _.cloneDeep(this.props.advancedMappingXero.items);
                                                        let index = items.findIndex(x => x.chargeCategoryId == id);
                                                        if(index > -1){
                                                            items[index].chargeCategoryXeroItems = [{ 
                                                                xeroDefaultChartOfAccountId: '',
                                                                xeroDefaultPurchaseChartOfAccountId: xeroDefaultPurchaseChartOfAccountId,
                                                            }]

                                                            await this.props.action.advancedMappingXero.setItems(items);

                                                            let params = {
                                                                chargeCategoryId: id,
                                                                xeroAppId: this.props.advancedMappingXero.xeroAppId,
                                                                xeroDefaultPurchaseChartOfAccountId: xeroDefaultPurchaseChartOfAccountId,
                                                                xeroDefaultChartOfAccountId: '',
                                                            }
                                                            await this.props.action.advancedMappingXero.callUpdateApi(params);
                                                        }
                                                    }}
                                                />
                                              },
                                            },
                                            {
                                              id: "purchaseChargeCategoryXeroItems",
                                              name: "purchaseChargeCategoryXeroItems",
                                              label: "DEFAULT PURCHASE PRODUCT ACCOUNT",
                                              responsiveLabel: "DEFAULT PURCHASE PRODUCT ACCOUNT",
                                              width: '400px',
                                              orderable: false,
                                              template: (id: any, row: any, col: any, obj: any) => {
                                                let purchaseChartOfAccountItems = _.cloneDeep(this.props.advancedMappingXero.purchaseChartOfAccountItems);

                                                let chargeCategoryXeroItems = row.chargeCategoryXeroItems;
                                                let xeroDefaultChartOfAccountId = (chargeCategoryXeroItems && chargeCategoryXeroItems.length > 0) ? chargeCategoryXeroItems[0].xeroDefaultChartOfAccountId : null;
                                                let value = (chargeCategoryXeroItems && chargeCategoryXeroItems.length > 0) ? chargeCategoryXeroItems[0].xeroDefaultPurchaseChartOfAccountId : null;
                                                let selectedItems = (value) ? purchaseChartOfAccountItems.filter(x => x.value == value) : null;
                                                let selectedItem = (selectedItems && selectedItems.length > 0) ? selectedItems[0] : null;
                                                let selectedName = (selectedItem) ? selectedItem.title : '';

                                                return <Form.Control
                                                    as = {RSuiteSelectPicker}
                                            
                                                    id={'purchaseChartOfAccountItemsId_' + id}
                                                    placeholder={'Default purchase product account'}
                                                    items={purchaseChartOfAccountItems}
                                                    value={value}
                                                    selectedName={selectedName}
                                                    isLoading={this.props.advancedMappingXero.purchaseChartOfAccountIsLoading}
                                                    groupBy={'type'}
                                                    renderMenuGroup={(label: any) => <b>{label}</b>}
                                            
                                                    searchable={true}
                                                    cleanable={true}
                                                    isDefault={true}
                                                    onInit={async () => {
                                                        
                                                    }}
                                                    onClick={async () => {
                                                        
                                                    }}
                                                    onSelect={async (value: any, item: any) => {
                                                        let items = _.cloneDeep(this.props.advancedMappingXero.items);
                                                        let index = items.findIndex(x => x.chargeCategoryId == id);
                                                        if(index > -1){
                                                            items[index].chargeCategoryXeroItems = [{ 
                                                                xeroDefaultChartOfAccountId: xeroDefaultChartOfAccountId,
                                                                xeroDefaultPurchaseChartOfAccountId: value
                                                            }]
                                                            await this.props.action.advancedMappingXero.setItems(items);

                                                            let params = {
                                                                chargeCategoryId: id,
                                                                xeroAppId: this.props.advancedMappingXero.xeroAppId,
                                                                xeroDefaultChartOfAccountId: xeroDefaultChartOfAccountId,
                                                                xeroDefaultPurchaseChartOfAccountId: value,
                                                            }
                                                            await this.props.action.advancedMappingXero.callUpdateApi(params);
                                                        }
                                                    }}
                                                    onClear={async () => {
                                                        let items = _.cloneDeep(this.props.advancedMappingXero.items);
                                                        let index = items.findIndex(x => x.chargeCategoryId == id);
                                                        if(index > -1){
                                                            items[index].chargeCategoryXeroItems = [{ 
                                                                xeroDefaultChartOfAccountId: xeroDefaultChartOfAccountId,
                                                                xeroDefaultPurchaseChartOfAccountId: ''
                                                            }]
                                                            await this.props.action.advancedMappingXero.setItems(items);

                                                            let params = {
                                                                chargeCategoryId: id,
                                                                xeroAppId: this.props.advancedMappingXero.xeroAppId,
                                                                xeroDefaultChartOfAccountId: xeroDefaultChartOfAccountId,
                                                                xeroDefaultPurchaseChartOfAccountId: '',
                                                            }
                                                            await this.props.action.advancedMappingXero.callUpdateApi(params);
                                                        }
                                                    }}
                                                />
                                              },
                                            }
                                        ]);
                                    }}
                                >
                                    Advanced
                                </a>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className={'mb-5'}>
                            <Form.Label column xs={12} md={3} xl={2}>{this.props.fields.xeroDefaultTaxType.label}</Form.Label>
                            <Col xs={12} md={10} lg={6} xl={4}>
                                <Form.Control
                                    as = {RSuiteSelectPicker}
                                    isInvalid={!!errors.xeroDefaultTaxType}

                                    id={this.props.fields.xeroDefaultTaxType.id}
                                    placeholder={this.props.fields.xeroDefaultTaxType.placeholder}
                                    items={values.customFormParams.xeroDefaultTaxTypeInvoiceItems}
                                    value={values.xeroDefaultTaxType}
                                    selectedName={values.xeroDefaultTaxTypeName}
                                    isLoading={values.customFormParams.xeroDefaultTaxTypeIsLoading}

                                    searchable={true}
                                    cleanable={true}
                                    isDefault={true}
                                    onInit={async () => {
                                        await setFieldValue('customFormParams.xeroDefaultTaxTypeIsLoading', true);
                                        this.callXeroReadTaxRatesApi(values.customFormParams.xeroDefaultTaxTypeInvoiceItems, BillingTypes.Invoice, async (items) => {
                                            await setFieldValue('customFormParams.xeroDefaultTaxTypeInvoiceItems', items);
                                            await setFieldValue('customFormParams.xeroDefaultTaxTypeIsLoading', false);

                                            if(values.xeroDefaultTaxType && items && items.length > 0){
                                                let arr = items.filter(x => x.value == values.xeroDefaultTaxType);
                                                if(arr && arr.length > 0){
                                                    await setFieldValue('xeroDefaultTaxType', arr[0].value);
                                                    await setFieldValue('xeroDefaultTaxTypeName', arr[0].title);
                                                }
                                            }
                                        });
                                    }}
                                    onClick={async () => {
                                        await setFieldValue('customFormParams.xeroDefaultTaxTypeIsLoading', true);
                                        this.callXeroReadTaxRatesApi(values.customFormParams.xeroDefaultTaxTypeInvoiceItems, BillingTypes.Invoice, async (items) => {
                                            await setFieldValue('customFormParams.xeroDefaultTaxTypeInvoiceItems', items);
                                            await setFieldValue('customFormParams.xeroDefaultTaxTypeIsLoading', false);
                                        });
                                    }}
                                    onSelect={async (value, item) => {
                                        await setFieldValue('xeroDefaultTaxType', value);
                                        await setFieldValue('xeroDefaultTaxTypeName', item.title);
                                        
                                        let xeroApp = values.customFormParams.xeroApp;
                                        xeroApp.xeroDefaultTaxType = value;
                                        
                                        await setFieldValue('customFormParams.xeroApp', xeroApp);
                                        
                                        // this.callXeroUpdateInvoiceTaxRatesApi(value);
                                    }}
                                    onClear={async () => {
                                        await setFieldValue('xeroDefaultTaxType', null);
                                        await setFieldValue('xeroDefaultTaxTypeName', '');
                                        
                                        let xeroApp = values.customFormParams.xeroApp;
                                        xeroApp.xeroDefaultTaxType = null;
                                        
                                        await setFieldValue('customFormParams.xeroApp', xeroApp);

                                        // this.callXeroUpdateInvoiceTaxRatesApi(null);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">{errors.xeroDefaultTaxType}</Form.Control.Feedback>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className={'mb-5'}>
                            <Form.Label column xs={12} md={3} xl={2}>{this.props.fields.xeroDefaultPurchaseTaxType.label}</Form.Label>
                            <Col xs={12} md={10} lg={6} xl={4}>
                                <Form.Control
                                    as = {RSuiteSelectPicker}
                                    isInvalid={!!errors.xeroDefaultPurchaseTaxType}

                                    id={this.props.fields.xeroDefaultPurchaseTaxType.id}
                                    placeholder={this.props.fields.xeroDefaultPurchaseTaxType.placeholder}
                                    items={values.customFormParams.xeroDefaultTaxTypePurchaseItems}
                                    value={values.xeroDefaultPurchaseTaxType}
                                    selectedName={values.xeroDefaultPurchaseTaxTypeName}
                                    isLoading={values.customFormParams.xeroDefaultTaxTypeIsLoading}

                                    searchable={true}
                                    cleanable={true}
                                    isDefault={true}
                                    onInit={async () => {
                                        await setFieldValue('customFormParams.xeroDefaultTaxTypeIsLoading', true);
                                        this.callXeroReadTaxRatesApi(values.customFormParams.xeroDefaultTaxTypePurchaseItems, BillingTypes.Purchase, async (items) => {
                                            await setFieldValue('customFormParams.xeroDefaultTaxTypePurchaseItems', items);
                                            await setFieldValue('customFormParams.xeroDefaultTaxTypeIsLoading', false);

                                            if(values.xeroDefaultPurchaseTaxType && items && items.length > 0){
                                                let arr = items.filter(x => x.value == values.xeroDefaultPurchaseTaxType);
                                                if(arr && arr.length > 0){
                                                    await setFieldValue('xeroDefaultPurchaseTaxType', arr[0].value);
                                                    await setFieldValue('xeroDefaultPurchaseTaxTypeName', arr[0].title);
                                                }
                                            }
                                        });
                                    }}
                                    onClick={async () => {
                                        await setFieldValue('customFormParams.xeroDefaultTaxTypeIsLoading', true);
                                        this.callXeroReadTaxRatesApi(values.customFormParams.xeroDefaultTaxTypePurchaseItems, BillingTypes.Purchase, async (items) => {
                                            await setFieldValue('customFormParams.xeroDefaultTaxTypePurchaseItems', items);
                                            await setFieldValue('customFormParams.xeroDefaultTaxTypeIsLoading', false);
                                        });
                                    }}
                                    onSelect={async (value, item) => {
                                        await setFieldValue('xeroDefaultPurchaseTaxType', value);
                                        await setFieldValue('xeroDefaultPurchaseTaxTypeName', item.title);
                                        
                                        let xeroApp = values.customFormParams.xeroApp;
                                        xeroApp.xeroDefaultPurchaseTaxType = value;
                                        
                                        await setFieldValue('customFormParams.xeroApp', xeroApp);
                                        
                                        // this.callXeroUpdatePurchaseTaxRatesApi(value);
                                    }}
                                    onClear={async () => {
                                        await setFieldValue('xeroDefaultPurchaseTaxType', null);
                                        await setFieldValue('xeroDefaultPurchaseTaxTypeName', '');
                                        
                                        let xeroApp = values.customFormParams.xeroApp;
                                        xeroApp.xeroDefaultPurchaseTaxType = null;
                                        
                                        await setFieldValue('customFormParams.xeroApp', xeroApp);

                                        // this.callXeroUpdatePurchaseTaxRatesApi(null);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">{errors.xeroDefaultPurchaseTaxType}</Form.Control.Feedback>
                            </Col>
                        </Form.Group>

                    </>}

                    {
                        (values.customFormParams.quickbookOrXero !== QuickbookOrXero.None)
                        ?
                            (values.customFormParams.isConnectQuickbook || values.customFormParams.isConnectXero)
                            ?
                            <Form.Group as={Row} className={'mb-5'}>
                                <Form.Label column xs={12} md={3} xl={2} className={'d-none d-lg-flex'}>&nbsp;</Form.Label>
                                <Col xs={12} md={10} lg={6} xl={4}>
                                    <Button 
                                        className={'w-100'}
                                        type={'button'}
                                        variant={'danger'}
                                        onClick={async () => {
                                            this.setState({
                                                setDisconnectDialog: true,
                                                setDisconnectDialogItem: {
                                                    isConnectQuickbook: values.customFormParams.isConnectQuickbook,
                                                    isConnectXero: values.customFormParams.isConnectXero,
                                                    quickBookApp: values.customFormParams.quickBookApp,
                                                    xeroApp: values.customFormParams.xeroApp,
                                                },
                                                setDisconnectDialogIsLoading: false,
                                            });
                                        }}
                                    >
                                        Disconnect
                                    </Button>
                                </Col>
                            </Form.Group>
                            :
                            <Form.Group as={Row} className={'mb-5'}>
                                <Form.Label column xs={12} md={3} xl={2} className={'d-none d-lg-flex'}>&nbsp;</Form.Label>
                                <Col xs={12} md={10} lg={6} xl={4}>
                                    <Button 
                                        className={'w-100'}
                                        type={'button'}
                                        variant={'success'}
                                        onClick={async () => {
                                            if(values.isActive){
                                                if(values.customFormParams.quickbookOrXero === QuickbookOrXero.Quickbook){
                                                    window.open(
                                                        QuickbookApi.getConnectUrl(values.accountBillingProfileId),
                                                        "_blank"
                                                    );
                                                } else if(values.customFormParams.quickbookOrXero === QuickbookOrXero.Xero){
                                                    window.open(
                                                        XeroApi.getConnectUrl(values.accountBillingProfileId),
                                                        "_blank"
                                                    );
                                                }
                                            } else {
                                                Utils.toast('Sorry, this profile is not active!', 'error');
                                            }
                                        }}
                                    >
                                        Connect
                                    </Button>
                                </Col>
                            </Form.Group>
                        :
                        null
                    }
                    
                </>}
            </>}


            <Row className={'mt-10 mb-5'}>
                <h5>DEFAULT PREFIX FORMAT</h5>
            </Row>
            
            <Form.Group as={Row} className={'mb-5'}>
                <Form.Label column xs={12} md={3} xl={2}>{this.props.fields.prefixDoNumber.label}</Form.Label>
                <Col xs={12} md={10} lg={6} xl={4}>
                    <Form.Control
                        type="text"
                        id={this.props.fields.prefixDoNumber.id}
                        placeholder={this.props.fields.prefixDoNumber.placeholder}
                        value={values.prefixDoNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.prefixDoNumber}
                    />
                    <Form.Control.Feedback type="invalid">{errors.prefixDoNumber}</Form.Control.Feedback>
                </Col>
            </Form.Group>

            {(Utils.hasPermission(this.permissions, PagesPermissions.AllowBillingProfileAccountingIntegration.read)) && <>
                <Form.Group as={Row} className={'mb-5'}>
                    <Form.Label column xs={12} md={3} xl={2}>{this.props.fields.invoiceNumberPrefix.label}</Form.Label>
                    <Col xs={12} md={10} lg={6} xl={4}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.invoiceNumberPrefix.id}
                            placeholder={this.props.fields.invoiceNumberPrefix.placeholder}
                            value={values.invoiceNumberPrefix}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.invoiceNumberPrefix}
                        />
                        <Form.Control.Feedback type="invalid">{errors.invoiceNumberPrefix}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className={'mb-5'}>
                    <Form.Label column xs={12} md={3} xl={2}>{this.props.fields.purchaseOrderNumberPrefix.label}</Form.Label>
                    <Col xs={12} md={10} lg={6} xl={4}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.purchaseOrderNumberPrefix.id}
                            placeholder={this.props.fields.purchaseOrderNumberPrefix.placeholder}
                            value={values.purchaseOrderNumberPrefix}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.purchaseOrderNumberPrefix}
                        />
                        <Form.Control.Feedback type="invalid">{errors.purchaseOrderNumberPrefix}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className={'mb-5'}>
                    <Form.Label column xs={12} md={3} xl={2}>{this.props.fields.expenseNumberPrefix.label}</Form.Label>
                    <Col xs={12} md={10} lg={6} xl={4}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.expenseNumberPrefix.id}
                            placeholder={this.props.fields.expenseNumberPrefix.placeholder}
                            value={values.expenseNumberPrefix}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.expenseNumberPrefix}
                        />
                        <Form.Control.Feedback type="invalid">{errors.expenseNumberPrefix}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
            </>}

            <AdvancedXeroPopup />
            <AdvancedQuickBookPopup />
        </>
    }


    /* DIALOG */
    setDisconnectDialog = () => {
        return <RemoveDialog 
            show={this.state.setDisconnectDialog}
            isLoading={this.state.setDisconnectDialogIsLoading}
            title={'Disconnect!'}
            body={'Are you sure you want to disconnect?'}
            cancelText={'No'}
            removeText={'Yes'}
            onCancel={() => {
                this.setState({
                    setDisconnectDialog: false,
                    setDisconnectDialogItem: null,
                    setDisconnectDialogIsLoading: false,
                });
            }}
            onRemove={() => {
                let item = this.state.setDisconnectDialogItem;
                if(item.isConnectQuickbook){
                    if(this.props.onStartDisconnecting){
                        this.props.onStartDisconnecting()
                    }

                    QuickbookApi.disconnect(item.quickBookApp).then((result) => {
                        if(this.props.onDisconnect){
                            this.props.onDisconnect()
                        }
                    });
                } else if(item.isConnectXero){
                    if(this.props.onStartDisconnecting){
                        this.props.onStartDisconnecting()
                    }

                    XeroApi.disconnect(item.xeroApp).then((result) => {
                        if(this.props.onDisconnect){
                            this.props.onDisconnect()
                        }
                    });
                }
                this.setState({
                    setDisconnectDialog: false,
                    setDisconnectDialogItem: null,
                    setDisconnectDialogIsLoading: false,
                });
            }}
        />
    }
    /* END DIALOG */


    render() {
        return <>
            {this.body()}
            {this.setDisconnectDialog()}
        </>
    }
}

const mapStateToProps = state => {
    return {
      ...state,
      ...Utils.mapStateToProps(state),
    };
  };
  
  const mapDispatchToProps = (dispatch) => ({
    dispatchApiCallGet: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallGet(p1, p2, p3, p4, p5, p6)),
    dispatchApiCallPut: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallPut(p1, p2, p3, p4, p5, p6)),
    clear: (payload) => dispatch(clear(payload)),
    action: {
      advancedMappingXero: {
        show: (payload) => dispatch(advancedMappingXero.show(payload)),
        setColumns: (payload) => dispatch(advancedMappingXero.setColumns(payload)),
        setItems: (payload) => dispatch(advancedMappingXero.setItems(payload)),
        callXeroReadChartOfAccountApi: (payload) => dispatch(advancedMappingXero.callXeroReadChartOfAccountApi(payload)),
        callXeroReadPurchaseChartOfAccountApi: (payload) => dispatch(advancedMappingXero.callXeroReadPurchaseChartOfAccountApi(payload)),
        callReadApi: (payload) => dispatch(advancedMappingXero.callReadApi(payload)),
        callUpdateApi: (payload) => dispatch(advancedMappingXero.callUpdateApi(payload)),
      },
      advancedMappingQuickBook: {
        show: (payload) => dispatch(advancedMappingQuickBook.show(payload)),
        setColumns: (payload) => dispatch(advancedMappingQuickBook.setColumns(payload)),
        setItems: (payload) => dispatch(advancedMappingQuickBook.setItems(payload)),
        callQuickBookReadChartOfAccountApi: (payload) => dispatch(advancedMappingQuickBook.callQuickBookReadChartOfAccountApi(payload)),
        callQuickBookReadPurchaseChartOfAccountApi: (payload) => dispatch(advancedMappingQuickBook.callQuickBookReadPurchaseChartOfAccountApi(payload)),
        callReadApi: (payload) => dispatch(advancedMappingQuickBook.callReadApi(payload)),
        callUpdateApi: (payload) => dispatch(advancedMappingQuickBook.callUpdateApi(payload)),
      },
    }
  });
  
  
  export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(GeneralForm);