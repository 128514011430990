import React, { Component } from 'react';

import moment from 'moment';
import parse from 'html-react-parser';

import apiUtil from '../../../../api/apiUtil.jsx';
import AuditTrailApi from '../../../../api/OLD/Dispatch/AuditTrail.jsx';

import CustomDialog from '../../../../components/OLD/Dialog/CustomDialog.jsx';
import AuditTrailList from './AuditTrailList.jsx';


class AuditTrailDialog extends Component {
  
    constructor(props){
        super(props);

        let isOpen = ((props.isOpen === false) || (props.isOpen === true)) ? props.isOpen : false;
        let objectId = (props.objectId) ? props.objectId : null;
        let auditTrailObjectType = (props.auditTrailObjectType >= 0) ? props.auditTrailObjectType : null;
        let title = (props.title && props.title !== '') ? props.title : '';

        auditTrailObjectType = (auditTrailObjectType == 0) ? "0" : auditTrailObjectType;

        this.state = {
            isLoading: false,
            items: null,

            isOpen: isOpen,
            objectId: objectId,
            auditTrailObjectType: auditTrailObjectType,
            title: title,
            totalRows: 0,
            pageSize: 30,
            currentPage: 1,
        }
    }

    componentWillReceiveProps(nextProps) {
		if (nextProps.isOpen !== this.state.isOpen) {
            let isOpen = ((nextProps.isOpen === false) || (nextProps.isOpen === true)) ? nextProps.isOpen : false;
			this.setState({
				isOpen: isOpen,
                totalRows: 0,
                currentPage: 1,
			});
        }

        if (nextProps.objectId !== this.state.objectId) {
            let objectId = (nextProps.objectId) ? nextProps.objectId : null;
			this.setState({
				objectId: objectId,
                totalRows: 0,
                currentPage: 1,
			}, () => {
                if(this.state.isOpen){
                    this.callAuditTrailApi();
                }
            });
        }
	}


    /* API */
    callAuditTrailApi = () => {
        let token = '';

        this.setState({
            isLoading: true,
        });

        let data = {
          currentPage: this.state.currentPage,
          pageSize: this.state.pageSize,
          searchQuery: '',
          sortColumn: 'created',
          sortDir: 'desc',
          objectId: this.state.objectId,
          objectType: this.state.auditTrailObjectType,
        };
    
        AuditTrailApi.getAuditTrail(
          data, 
          token
        ).then(result => {
          apiUtil.parseResult(result, (data) => {
            let total = 0;
            let items = [];
            if(data && data.data && data.data.length > 0){
                total = data.total;

                let dataItems = data.data.sort((a, b) => new Date(b.updated) - new Date(a.updated));
                items = dataItems.map((item, i) => {
                    return {
                        id: item.auditTrailId,
                        item: item,
                        time: moment(item.updated).format('MMM. DD, YYYY hh:mm A'),
                        color: 'primary',
                        contentTitle: null,
                        contentDesc: <div>{parse(item.description)}</div>,
                        isLoadingDetails: false,
                        showDetails: false,
                        details: null,
                    }
                });
            }

            this.setState({
                isLoading: false,
				items: items,
                totalRows: total,
			});
          }, (error, type) => {
            this.setState({
                isLoading: false,
				items: null,
                totalRows: 0,
			});
          });
        });
    }
    /* END API */


    render() {
        return <CustomDialog
            open={this.state.isOpen}
            fullWidth={true}
            onClose={() => {
                this.setState({
                    isOpen: false,
                    items: null,
                }, () => {
                    if(this.props.onClose){
                        this.props.onClose();
                    }
                });
            }}
        >
            <AuditTrailList
                items={this.state.items}
                objectId={this.state.objectId}
                auditTrailObjectType={this.state.auditTrailObjectType}
                title={this.state.title}
                totalRows={this.state.totalRows}
                pageSize={this.state.pageSize}
                currentPage={this.state.currentPage}
                isLoading={this.state.isLoading}
                details={this.props.details}
                manageData={this.props.manageData}
                onChangePage={(page) => {
                    if(this.state.currentPage !== page){
                        this.setState({
                            currentPage: page,
                        }, () => {
                            this.callAuditTrailApi();
                        });
                    }
                }}
                onClose={() => {
                    this.setState({
                        isOpen: false,
                        items: null,
                    }, () => {
                        if(this.props.onClose){
                            this.props.onClose();
                        }
                    });
                }}
            />
        </CustomDialog>
    }
}

export default AuditTrailDialog;
