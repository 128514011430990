import React, { ChangeEvent } from 'react';

import {
    Form,
    Button,
} from "react-bootstrap-v5";

import styled from 'styled-components';

import Utils from "../../../utils/utils";

import { useDispatch, useSelector } from "react-redux";
import {RootState} from '../../../../setup'
import fileInputButtonSlice from './FileInputButtonSlice';


interface FileInputButtonProps {
    text?: string,
    placeholder?: string,
    accept?: Array<string>,
}


const CustomButton = styled(Button)`
    border: 1px solid #185cff !important;
    border-radius: 2px;
`;


const FileInputButton: React.FC<FileInputButtonProps> = ({ text = 'Select file', placeholder = 'No file chosen', accept = [ 'xls', 'xlsx', 'csv' ] }) => {
    const dispatch = useDispatch();
    const { isFile, name, disabled } = useSelector((state: RootState) => state.fileInputButtonSlice);

    const fileInputRef = React.useRef<HTMLInputElement>(null);
    

    const getFileExtension = (fileName: string) => {
        return fileName.substring(fileName.lastIndexOf('.') + 1);
    };
    
    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            
            const fileExtension = getFileExtension(file.name);
            if (!accept.includes(fileExtension)) {
                dispatch(fileInputButtonSlice.onChange(null));
                
                Utils.toast('Invalid file extension!', 'error');
            } else {
                dispatch(fileInputButtonSlice.onChange(file));
            }
        }
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };


    return <Form.Group>
        <Form.Control
            type={'file'}
            accept={accept.map(x => '.' + x).join(',')}
            onChange={handleFileChange}
            disabled={disabled}
            ref={fileInputRef}
            style={{ display: 'none' }}
        />
        <CustomButton variant="outline-primary" type="button" disabled={disabled} onClick={handleButtonClick}>{text}</CustomButton>
        {isFile ? <span className={'ps-5'}>{name}</span> : <span className={'ps-5'}>{placeholder}</span>}
    </Form.Group>
};


export default FileInputButton;
