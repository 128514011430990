import React, { Component } from 'react';
import debounce from "lodash/debounce";

import { AutoComplete } from 'rsuite';
import "rsuite/dist/rsuite.min.css";


class RSuiteAutoComplete extends Component {
  
	constructor(props){
		super(props);
        
        let menuClassName = props.menuClassName ? props.menuClassName : '';
        let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
        let disabled = ((props.disabled === true || props.disabled === false) ? props.disabled : false);
        let isDefault = ((props.isDefault === true || props.isDefault === false) ? props.isDefault : false);
        let items = props.items ? props.items : [];
        let value = props.value;
        let placeholder = props.placeholder ? props.placeholder : '';
        let placement = props.placement ? props.placement : 'bottomStart';
        let labelKey = props.labelKey ? props.labelKey : 'label';
        let valueKey = props.valueKey ? props.valueKey : 'value';
        let pattern = props.pattern ? props.pattern : null;

		this.state = {
            menuClassName: menuClassName,
            items: items,
            value: value,
            placeholder: placeholder,
            labelKey: labelKey,
            valueKey: valueKey,
            isLoading: isLoading,
            disabled: disabled,
            placement: placement,
            isDefault: isDefault,
            pattern: pattern,
            isOpen: false,
		}
	}
    

    componentWillReceiveProps(nextProps) {
        if (nextProps.items !== this.state.items) {
            let items = nextProps.items ? nextProps.items : [];
			this.setState({
				items: items
			});
        }
        
        if (nextProps.value !== this.state.value) {
			this.setState({
				value: nextProps.value
			});
        }

        if (nextProps.isLoading !== this.state.isLoading) {
            let isLoading = ((nextProps.isLoading === true || nextProps.isLoading === false) ? nextProps.isLoading : false);
			this.setState({
				isLoading: isLoading
			});
		}

        if (nextProps.disabled !== this.state.disabled) {
            let disabled = ((nextProps.disabled === true || nextProps.disabled === false) ? nextProps.disabled : false);
			this.setState({
				disabled: disabled
			});
		}
    }

    
    
    handleClick = () => {
        if(!this.state.disabled){
            this.setState({
                isOpen: true,
            }, () => {
                if(this.props.onClick){
                    this.props.onClick();
                }
            });
        }
    }

    handleClose = () => {
        this.setState({
            isOpen: false,
        });
    }

    handleChange = (value, e) => {
        if(e.type === 'change'){
            let state = true;
            if(this.state.pattern){
                if(value.match(this.state.pattern) == null){
                    state = false;
                }
            }

            if(state){
                this.setState({
                    isLoading: true,
                    value: value,
                }, () => {
                    if(this.props.onChange){
                        this.props.onChange(this.state.value);
                    }
    
                    this.handleIsTyping();
                });
            }
        } else {
            let item = null;

            let itemObj = this.state.items.filter(x => x[this.state.valueKey] === value);
            if(itemObj && itemObj.length > 0){
                item = itemObj[0];
            }

            this.setState({
                value: item[this.state.labelKey],
            }, () => {
                if(this.props.onSelect){
                    this.props.onSelect(this.state.value, item);
                }
            });
        }
    }

    handleFilterBy = (value, item) => {
        if(value === null || value === ''){
            return true;
        } else {
            return item[this.state.labelKey].toLowerCase().includes(value.toLowerCase())
        }
    }
    
    handleBlur = () => {
        if(this.props.onBlur){
            this.props.onBlur();
        }
    }
    
    handleIsTyping = debounce(() => {
        this.setState({
            isLoading: false,
        }, () => {
            if(this.props.onSearch){
                this.props.onSearch(this.state.value);
            }
        });
    }, 500);


	render() {
		return <div className={'custom-rsuite-select-picker ' + (this.state.isDefault ? 'default-look ' : '') + this.props.className}>
            <AutoComplete
                data={this.state.items}
                value={this.state.value}
                placeholder={this.state.placeholder}
                disabled={this.state.disabled}
                open={this.state.isOpen}
                placement={this.state.placement}
                menuClassName={'custom-menu-autocomplete ' + this.state.menuClassName}

                onClick={this.handleClick}
                onClose={this.handleClose}
                onChange={this.handleChange}
                filterBy={this.handleFilterBy}
                onBlur={this.handleBlur}
                
                renderMenuItem={(label, item) => {
                    if(this.props.renderMenuItem){
                        return this.props.renderMenuItem(label, item);
                    } else {
                        return <>{label}</>;
                    }
                }}

                renderMenu={menu => {
                    return <>
                        {
                            (this.state.isLoading)
                            ?
                            <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>Loading...</p>
                            :
                                (this.state.items.length === 0)
                                ?
                                <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>No Result!</p>
                                :
                                menu
                        }
                    </>
                }}
            />
		</div>;
	}
}

export default RSuiteAutoComplete;