import React from 'react';

import { Button } from 'react-bootstrap-v5';

const CircleButton = props => {
    return <div
        style={{
            position: 'relative',
            width: '35px',
            height: '35px',
        }}
    >
        <Button {...props} 
            className={'rounded-circle border border-primary ' + props.className}
            style={{
                position: 'absolute',
                width: '35px',
                height: '35px',
                padding: '0px',
            }}
        >
            {props.children}
        </Button>
    </div>
}


export default CircleButton;