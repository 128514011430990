import React from 'react';

import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


const options = {
    options: ['inline', 'textAlign'],
    inline: {
        inDropdown: false,
        options: ['bold', 'italic', 'underline'],
    },
    textAlign: {
        options: ['left', 'center', 'right', 'justify'],
    }
};


const RTextEditor = (props) => {
    const [className, setClassName] = React.useState(props.className ? props.className : '')
    const [placeholder] = React.useState(props.placeholder ? props.placeholder : '')
    const [toolbar] = React.useState(props.toolbar ? props.toolbar : options)
    const [icon] = React.useState(props.icon ? props.icon : null)


    const [editorState, setEditorState] = React.useState(props.editorState);
    

    React.useEffect(() => {
        setEditorState(props.editorState);
    }, [props.editorState]);

    React.useEffect(() => {
        setClassName(props.className);
    }, [props.className]);

      
    const onEditorStateChange = (editorState) => {
        let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        if (props.onChange) {
            let plainText = editorState.getCurrentContent().getPlainText();
            props.onChange(plainText, html, editorState);
        }
    }

    
    return <div className={"rich-text-editor-component " + className + (icon ? ' icon' : '')}>
        <Editor
            editorState={editorState}
            placeholder={placeholder}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            stripPastedStyles={true}
            readOnly={props.disabled}
            onEditorStateChange={onEditorStateChange}
            toolbar={toolbar}
            onFocus={props.onFocus}
        />
        {icon && <i className="material-icons custom-icon">{icon}</i>}
    </div>
}


export default RTextEditor;