import uuid from 'react-uuid';
import moment from 'moment';

import { 
  Form,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import Tooltip from '../../../../components/Tooltip/Tooltip';
import RSuiteDatePicker from '../../../../components/OLD/Datepicker/RSuiteDatePicker';
import RSuiteSelectPicker from '../../../../components/OLD/Select/RSuiteSelectPicker';
import Utils from '../../../../utils/utils';


const StyledTextField = styled(Form.Control)`
    &[readonly] {
      color: #484848 !important;
    }
`;

const StyledDatePickerField = styled(Form.Control)`
    .rs-picker-date {
      padding-left: 5px !important;
      padding-right: 5px !important;

      .rs-picker-toggle-textbox {
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
    }
`;


interface IPropsLabel {
  value: string;
  isInvalid: boolean;
  error: string;
}

interface IPropsString {
  autoFocus?: boolean|undefined;
  type?: string;
  isInvalid: boolean;
  value: string;
  pathName: string;
  error: string;
  disabled: boolean;
  onChange: (e: any) => void;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
}

interface IPropsBoolean {
  label?: string|null;
  checked: boolean;
  pathName: string;
  disabled: boolean;
  onChange: (e: any) => void;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
}

interface IPropsDate {
  autoFocus?: boolean|undefined;
  isInvalid: boolean;
  value: any;
  pathName: string;
  error: string;
  icon?: any;
  placement?: string;
  cleanable?: boolean|undefined;
  disabled: boolean;
  onChange: (e: any) => void;
  onClear: () => void;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
}

interface IPropsSelect {
  autoFocus?: boolean|undefined;
  isInvalid: boolean;
  value: string;
  pathName: string;
  text?: string;
  error: string;
  isLoading: boolean;
  items: Array<any>;
  disabledItemValues?: Array<any>;
  searchable?: boolean|undefined;
  cleanable?: boolean|undefined;
  disabled: boolean;
  onClick: () => void;
  onSelect: (value: any, item: any) => void;
  onClear: () => void;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
}


export const TextField = ({ value, isInvalid, error }: IPropsLabel) => {
  return <Form.Group>
    <StyledTextField
      id={'LabelField_' + uuid()}
      readOnly={true}
      isInvalid={isInvalid}
      value={value}
    />
    {/* <Tooltip title={error} placement={'bottom'}> */}
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    {/* </Tooltip> */}
  </Form.Group>
}

export const LabelField = ({ value, isInvalid, error }: IPropsLabel) => {
  return <Form.Group>
    <Form.Control
      id={'LabelField_' + uuid()}
      readOnly={true}
      isInvalid={isInvalid}
      value={value}
    />
    {/* <Tooltip title={error} placement={'bottom'}> */}
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    {/* </Tooltip> */}
  </Form.Group>
}

export const StringField = ({ autoFocus = false, type = 'text', disabled, isInvalid, value, pathName, error, onChange, onBlur, onFocus }: IPropsString) => {
  return <Form.Group>
    <Form.Control
      id={'StringField_' + uuid()}
      type={type}
      autoFocus={autoFocus}
      readOnly={disabled}

      isInvalid={isInvalid}
      defaultValue={value}
      name={pathName}
      // value={value}
      onChange={(e: any) => onChange(e)}
      onBlur={onBlur}
      onFocus={onFocus}
    />
    {/* <Tooltip title={error} placement={'bottom'}> */}
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    {/* </Tooltip> */}
  </Form.Group>
}

export const BooleanField = ({ label = null, disabled, checked, pathName, onChange, onBlur, onFocus }: IPropsBoolean) => {
  return <Form.Group>
      <Form.Check
        id={'BooleanField_' + uuid()}
        type={'switch'}
        label={label}
        readOnly={disabled}

        checked={checked}
        name={pathName}
        onChange={(e: any) => onChange(e)}
        onBlur={onBlur}
        onFocus={onFocus}
      />
  </Form.Group>
}

export const DateField = ({ autoFocus = false, disabled, icon = <></>, placement = 'bottom', cleanable = true, isInvalid, value, pathName, error, onChange, onClear, onBlur, onFocus }: IPropsDate) => {
  return <Form.Group>
    <StyledDatePickerField
      as = {RSuiteDatePicker}

      id={'DateField_' + uuid()}
      icon={icon}
      readOnly={disabled}
      placement={placement}
      cleanable={cleanable}
      autoFocus={autoFocus}
      isDefault={true}
      format={Utils.getImportDateTimePickerFormat()}
      showMeridian={false}
      oneTap={false}

      isInvalid={isInvalid}
      value={value}
      name={pathName}
      onChange={(e: any) => onChange(e)}
      onClear={() => onClear()}
      onBlur={onBlur}
      onFocus={onFocus}
    />
    {/* <Tooltip title={error} placement={'bottom'}> */}
      <Form.Control.Feedback className={(isInvalid ? 'd-block' : 'd-none')} type="invalid">{error}</Form.Control.Feedback>
    {/* </Tooltip> */}
  </Form.Group>
}

export const SelectField = ({ autoFocus = false, disabled, searchable = false, cleanable = true, isInvalid, items, isLoading, value, pathName, text, disabledItemValues = [], error, onClick, onSelect, onClear, onBlur, onFocus }: IPropsSelect) => {
  return <Form.Group>
    <Form.Control
      as = {RSuiteSelectPicker}

      id={'SelectField_' + uuid()}
      isLoading={isLoading}
      readOnly={disabled}
      disabledItemValues={disabledItemValues}
      searchable={searchable}
      cleanable={cleanable}
      autoFocus={autoFocus}
      isDefault={true}

      className={value ? 'selected-value' : ''}
      isInvalid={isInvalid}
      items={items}
      value={value}
      name={pathName}
      selectedName={text}
      onClick={() => onClick()}
      onSelect={(value: any, item: any) => onSelect(value, item)}
      onClear={() => onClear()}
      onBlur={onBlur}
      onFocus={onFocus}
    />
    {/* <Tooltip title={error} placement={'bottom'}> */}
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    {/* </Tooltip> */}
  </Form.Group>
}