import React from 'react';

import { Row, Col, Collapse } from 'react-bootstrap-v5';

const Accordion = props => {
    const [isOpen, setIsOpen] = React.useState(((props.isOpen === false) || (props.isOpen === true)) ? props.isOpen : false);

    React.useEffect(() => {
        setIsOpen(((props.isOpen === false) || (props.isOpen === true)) ? props.isOpen : false);
    }, [props.isOpen]);

    return <>
        <Row className={'d-flex align-items-center'}>
            <Col xs={'auto'}
                className={'cursor-pointer d-flex align-items-center'}
                onClick={() => {
                    if(props.onChange){
                        props.onChange(!isOpen);
                    }
                }}
            >
                {props.title}
            </Col>
            <Col xs={'auto'}
                className={'cursor-pointer d-flex align-items-center'}
                onClick={() => {
                    if(props.onChange){
                        props.onChange(!isOpen);
                    }
                }}
            >
                <i className={'material-icons'}>{isOpen ? 'expand_less' : 'expand_more'}</i>
            </Col>
        </Row>
        <Collapse in={isOpen}>
            {
                (props.body)
                ?
                props.body
                :
                <Row>
                    <Col xs={12} className={'mt-5'}>{props.children}</Col>
                </Row>
            }
        </Collapse>
    </>
}


export default Accordion;