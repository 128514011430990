import React from "react";

import moment from 'moment';
import parse from 'html-react-parser';

import apiUtil from '../../../../api/apiUtil.jsx';
import { 
    AuditTrailObjectType,
    AccountDocSettingType, 
    CustomPhotoSignature,
    StepType,
    JobStepType,
    JobStepBinActivity,
    DayOfWeek,
    Payroll,
    BillingDayInterval,
    PayrollTemplateWorkingDays,
    AccountDocSettingVariables,
} from '../../../../utils/enums';
import Utils from '../../../../utils/utils';

import {
    Box,
    Grid,
    Divider,
} from "@material-ui/core";


const AuditTrailFunctions = {
    getTypes: (type) => {
        return apiUtil.getKeyByValue(AuditTrailObjectType, type);
    },
    getDetails: (type, obj, index, currentVersion, previousVersion) => {
        let funcName = "getDetails";
        funcName += AuditTrailFunctions.getTypes(type);
        return AuditTrailFunctions[funcName](obj, index, currentVersion, previousVersion);
    },
    getManageData: (type, currentVersion, previousVersion, callback) => {
        let funcName = "getManageData";
        funcName += AuditTrailFunctions.getTypes(type);
        return AuditTrailFunctions[funcName](currentVersion, previousVersion, callback);
    },


    getDetailsCustomer: (obj, index, currentVersion, previousVersion) => {
        return (
            <Box
              style={{ wordBreak: 'breakAll' }}
            >
              {obj.rowTemplate("Customer Name", obj.highlight("customerName"))}
              {obj.rowTemplate("Billing Name", obj.highlight("billingName"))}
              {obj.rowTemplate(
                "Billing Address",
                obj.highlight("billingAddress")
              )}
              {obj.rowTemplate("Notes", obj.highlight("note"))}
              {obj.rowTemplate("Email", obj.highlight("emailAddresses"))}
              {/* {obj.rowTemplate(
                "Is Auto Send Do Doc",
                obj.highlight("isAutoSendDoDoc")
              )} */}
              {obj.rowTemplate("Is Active", obj.highlight("isActive"))}
              {obj.rowTemplate(
                "Billing By",
                obj.highlight("billingProfileName")
              )}
              {obj.rowTemplate(
                "Customer Category",
                obj.highlight("customerCategoryName")
              )}
              {/* {currentVersion.xeroPaymentTerm === null
                ? //  ketika xero null
                  obj.rowTemplate("Payment Terms", obj.highlight("paymentTerms"))
                : obj.rowTemplate(
                    "Xero Payment Term",
                    obj.highlight("xeroPaymentTerm")
                  )} */}
              {obj.rowTemplate("Payment Terms", obj.highlight("paymentTerms"))}
              {obj.rowTemplate("Credit Limit", obj.highlight('creditLimit', (val) => {
                return Utils.getCurrency() + Utils.formatNumber(val);
              }))}
            </Box>
        );
    },
    getManageDataCustomer: (currentVersion, previousVersion, callback) => {
        
    },


    getDetailsCustomerSite: (obj, index, currentVersion, previousVersion) => {
        return <Box>
            {obj.rowTemplate("Site Name", obj.highlight('customSiteName'))}
            {obj.rowTemplate("Block", obj.highlight('blockNo'))}
            {obj.rowTemplate("Unit No.", obj.highlight('unitNo'))}
            {obj.rowTemplate("Post Code", obj.highlight('postalCode'))}
            {obj.rowTemplate("Latitude", obj.highlight('latitude'))}
            {obj.rowTemplate("Longitude", obj.highlight('longitude'))}
            {obj.rowTemplate("Street", obj.highlight('street'))}
            {obj.rowTemplate("Group As", obj.highlight('zone.zoneName'))}
            {obj.rowTemplate("Site Instruction", obj.highlight('instructions'))}
            {obj.rowTemplate("Contact Person", obj.highlight('contactPersonName'))}
            {obj.rowTemplate("Telephone", obj.highlight('contactPersonPhoneNumber'))}
            {/* {obj.rowTemplate("Notification", obj.highlight('isContactPersonSendNotification'))} */}
            {obj.rowTemplate("Contact Person 2", obj.highlight('contactPersonNameTwo'))}
            {obj.rowTemplate("Telephone 2", obj.highlight('contactPersonPhoneNumberTwo'))}
            {/* {obj.rowTemplate("Notification 2", obj.highlight('isContactPersonTwoSendNotification'))} */}
            {obj.rowTemplate("Default Drivers", obj.highlight('concatDefaultDrivers'))}
            {/* {obj.rowTemplate("Default Vehicles", obj.highlight('concatDefaultVehicles'))} */}
        </Box>
    },
    getManageDataCustomerSite: (currentVersion, previousVersion, callback) => {
        if(callback){
            if(currentVersion){
                currentVersion['concatDefaultDrivers'] = (currentVersion && currentVersion.defaultDrivers && currentVersion.defaultDrivers.length > 0) ? currentVersion.defaultDrivers.map(x => x.driverName).join(', ') : '';
            }
            
            if(previousVersion){
                previousVersion['concatDefaultDrivers'] = (previousVersion && previousVersion.defaultDrivers && previousVersion.defaultDrivers.length > 0) ? previousVersion.defaultDrivers.map(x => x.driverName).join(', ') : '';
            }
            
            // if(currentVersion){
            //     currentVersion['concatDefaultVehicles'] = (currentVersion && currentVersion.defaultVehicles && currentVersion.defaultVehicles.length > 0) ? currentVersion.defaultVehicles.map(x => x.vehicleName).join(', ') : '';
            // }
            
            // if(previousVersion){
            //     previousVersion['concatDefaultVehicles'] = (previousVersion && previousVersion.defaultVehicles && previousVersion.defaultVehicles.length > 0) ? previousVersion.defaultVehicles.map(x => x.vehicleName).join(', ') : '';
            // }

            callback(currentVersion, previousVersion);
        }
    },
    

    getDetailsService: (obj, index, currentVersion, previousVersion) => {
        return null;
    },
    getManageDataService: (currentVersion, previousVersion, callback) => {
        
    },
    

    getDetailsServiceCharge: (obj, index, currentVersion, previousVersion) => {
        let currentSites = (currentVersion && currentVersion.arrSites && currentVersion.arrSites.length > 0) ? currentVersion.arrSites : [];
        let previousSites = (previousVersion && previousVersion.arrSites && previousVersion.arrSites.length > 0) ? previousVersion.arrSites : [];
        
        let diffSites = obj.getArrDiff(previousSites, currentSites, (a, b) => {
          return a.customerSiteId === b.customerSiteId;
        });
        let sites =  obj.concatUniqueArrays(previousSites, currentSites, 'customerSiteId');
        
        return <Box>
          {obj.rowTemplate("Waste Type Name", obj.highlight('wasteTypeName'))}
          {obj.rowTemplate("Bin Type Name", obj.highlight('binTypeName'))}
          {obj.rowTemplate("Service Tag", obj.highlight('serviceTag'))}
          {obj.rowTemplate("Category Name", obj.highlight('chargeCategoryName'))}
          {obj.rowTemplate("Rate", obj.highlight('price', (val) => {
            return Utils.getCurrency() + Utils.formatNumber(val);
          }))}
          {obj.rowTemplate("Is Bundled", obj.highlight('isBundled'))}

          <Box clone>
            <Grid item xs={12}>
              <Box pb={1}>
                <Grid container>
                    <Box clone>
                        <Grid item xs={12} md={3}>Sites</Grid>
                    </Box>
                    <Box clone className={'arr-diff-list'}>
                        <Grid item xs={12} md={'auto'}>

                          {(sites && sites.length > 0) && sites.map((item, i) => {
                            let addedCls = (obj.isExists(diffSites.added, item, 'customerSiteId')) ? 'highlight-row-green' : '';
                            let removedCls = (obj.isExists(diffSites.removed, item, 'customerSiteId')) ? 'highlight-row-red' : '';

                            return <Box key={i}>
                              <Grid container className={(addedCls !== '') ? addedCls : removedCls}>
                                <Box clone>
                                  <Grid item xs={12}>
                                    {item.customSiteName}
                                  </Grid>
                                </Box>
                              </Grid>
                              {obj.lineTemplate()}
                            </Box>
                          })}

                        </Grid>
                    </Box>
                </Grid>
              </Box>
            </Grid>
          </Box>

          <Box clone pt={3}>
                <Grid item xs={12}>
                    <Box pb={1}>
                        <b className={'text-color-secondary'}>BUNDLE SERVICE</b>
                    </Box>
        
                    <Box pb={1}>
                        <Box pb={1}>
                            <Grid container>
                                <Box clone>
                                    <Grid item xs={12} md={'auto'}>
                                        <Box minWidth={50}>No.</Box>
                                    </Grid>
                                </Box>
                                <Box clone>
                                    <Grid item xs={12} md={true}>
                                        <Grid container>
                                            <Box clone>
                                                <Grid item xs={12} md={6}>
                                                Additional Services
                                                </Grid>
                                            </Box>
                                            <Box clone>
                                                <Grid item xs={12} md={2}>
                                                Category
                                                </Grid>
                                            </Box>
                                            <Box clone>
                                                <Grid item xs={12} md={2}>
                                                Price
                                                </Grid>
                                            </Box>
                                            <Box clone>
                                                <Grid item xs={12} md={2}>
                                                Link to bin weight
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Box>
        
                        <Divider />

                        {
                            (currentVersion.additionalCharges && currentVersion.additionalCharges.length > 0)
                            ?
                            currentVersion.additionalCharges.map((item, i) => {
                                return <Box key={i} pb={1} className={obj.highlightRow('additionalCharges.' + i.toString())}>
                                    <Grid container>
                                        <Box clone>
                                            <Grid item xs={12} md={'auto'}>
                                                <Box minWidth={50}>{(i+1)}</Box>
                                            </Grid>
                                        </Box>
                                        <Box clone>
                                            <Grid item xs={12} md={true}>
                                                <Grid container>
                                                    <Box clone>
                                                        <Grid item xs={12} md={6}>
                                                        {obj.highlight('additionalCharges.' + i + '.serviceAdditionalChargeName')}
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={12} md={2}>
                                                        {obj.highlight('additionalCharges.' + i + '.chargeCategoryName')}
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={12} md={2}>
                                                        {obj.highlight('additionalCharges.' + i + '.price', (val) => {
                                                          return Utils.getCurrency() + Utils.formatNumber(val);
                                                        })}
                                                        </Grid>
                                                    </Box>
                                                    <Box clone>
                                                        <Grid item xs={12} md={2}>
                                                        {obj.highlight('additionalCharges.' + i + '.useBinWeightQuantity', (val) => {
                                                          return Utils.formatNumber(val, '0,0');
                                                        })}
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Box>
                            })
                            :
                            <Box pb={1}>
                                <Grid container>
                                    <Box clone textAlign={'center'}>
                                        <Grid item xs={12}>
                                        No Result!
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Box>
                        }
        
                    </Box>

                </Grid>
            </Box>
        </Box>
    },
    getManageDataServiceCharge: (currentVersion, previousVersion, callback) => {
        if(callback){
            if(currentVersion){
              currentVersion['arrSites'] = (currentVersion && currentVersion.serviceChargeCustomerSites && currentVersion.serviceChargeCustomerSites.length > 0) ? currentVersion.serviceChargeCustomerSites.map(x => {
                return {
                  customerSiteId: x.customerSite.customerSiteId,
                  customSiteName: x.customerSite.customSiteName,
                }
              }) : [];
            }
          
            if(previousVersion){
              previousVersion['arrSites'] = (previousVersion && previousVersion.serviceChargeCustomerSites && previousVersion.serviceChargeCustomerSites.length > 0) ? previousVersion.serviceChargeCustomerSites.map(x => {
                return {
                  customerSiteId: x.customerSite.customerSiteId,
                  customSiteName: x.customerSite.customSiteName,
                }
              }) : [];
            }
  
            callback(currentVersion, previousVersion);
        }
    },
    

    getDetailsAdditionalServiceCharge: (obj, index, currentVersion, previousVersion) => {
        let currentSites = (currentVersion && currentVersion.arrSites && currentVersion.arrSites.length > 0) ? currentVersion.arrSites : [];
        let previousSites = (previousVersion && previousVersion.arrSites && previousVersion.arrSites.length > 0) ? previousVersion.arrSites : [];
        
        let diffSites = obj.getArrDiff(previousSites, currentSites, (a, b) => {
          return a.customerSiteId === b.customerSiteId;
        });
        let sites =  obj.concatUniqueArrays(previousSites, currentSites, 'customerSiteId');
        
        return <Box>
          {obj.rowTemplate("Additional Charge Name", obj.highlight('serviceAdditionalChargeName'))}
          {obj.rowTemplate("Category Name", obj.highlight('chargeCategoryName'))}
          {obj.rowTemplate("Price", obj.highlight('price', (val) => {
            return Utils.getCurrency() + Utils.formatNumber(val);
          }))}
          {obj.rowTemplate("Service Tag", obj.highlight('serviceTag'))}
          
          <Box clone>
            <Grid item xs={12}>
              <Box pb={1}>
                <Grid container>
                    <Box clone>
                        <Grid item xs={12} md={3}>Sites</Grid>
                    </Box>
                    <Box clone className={'arr-diff-list'}>
                        <Grid item xs={12} md={'auto'}>

                          {(sites && sites.length > 0) && sites.map((item, i) => {
                            let addedCls = (obj.isExists(diffSites.added, item, 'customerSiteId')) ? 'highlight-row-green' : '';
                            let removedCls = (obj.isExists(diffSites.removed, item, 'customerSiteId')) ? 'highlight-row-red' : '';

                            return <Box key={i}>
                              <Grid container className={(addedCls !== '') ? addedCls : removedCls}>
                                <Box clone>
                                  <Grid item xs={12}>
                                    {item.customSiteName}
                                  </Grid>
                                </Box>
                              </Grid>
                              {obj.lineTemplate()}
                            </Box>
                          })}

                        </Grid>
                    </Box>
                </Grid>
              </Box>
            </Grid>
          </Box>

        </Box>
    },
    getManageDataAdditionalServiceCharge: (currentVersion, previousVersion, callback) => {
        if(callback){
            if(currentVersion){
              currentVersion['arrSites'] = (currentVersion && currentVersion.serviceAdditionalChargeCustomerSites && currentVersion.serviceAdditionalChargeCustomerSites.length > 0) ? currentVersion.serviceAdditionalChargeCustomerSites.map(x => {
                return {
                  customerSiteId: x.customerSite.customerSiteId,
                  customSiteName: x.customerSite.customSiteName,
                }
              }) : [];
            }
          
            if(previousVersion){
              previousVersion['arrSites'] = (previousVersion && previousVersion.serviceAdditionalChargeCustomerSites && previousVersion.serviceAdditionalChargeCustomerSites.length > 0) ? previousVersion.serviceAdditionalChargeCustomerSites.map(x => {
                return {
                  customerSiteId: x.customerSite.customerSiteId,
                  customSiteName: x.customerSite.customSiteName,
                }
              }) : [];
            }
  
            callback(currentVersion, previousVersion);
        }
    },
    

    getDetailsJobTemplate: (obj, index, currentVersion, previousVersion) => {
        return (
            <Box>
              {obj.rowTemplate(
                "Job Template Name",
                obj.highlight("jobTemplateName")
              )}
              {obj.rowTemplate(
                "Job Template Description",
                obj.highlight("jobTemplateDescription")
              )}

              {AuditTrailFunctions.stepsTemplateJobTemplate(currentVersion, obj)}
            </Box>
        );
    },
    getManageDataJobTemplate: (currentVersion, previousVersion, callback) => {
        if (callback) {
            if (currentVersion) {
              currentVersion.templateSteps.forEach((item, i) => {
                let mustTakePhotoText = "NO";
                if (item.mustTakePhoto === CustomPhotoSignature.Yes) {
                  mustTakePhotoText = "YES";
                } else if (
                  item.mustTakePhoto === CustomPhotoSignature.Optional
                ) {
                  mustTakePhotoText = "OPTIONAL";
                }
                item["mustTakePhotoText"] = mustTakePhotoText;

                let mustTakeSignatureText = "NO";
                if (item.mustTakeSignature === CustomPhotoSignature.Yes) {
                  mustTakeSignatureText = "YES";
                } else if (
                  item.mustTakeSignature === CustomPhotoSignature.Optional
                ) {
                  mustTakeSignatureText = "OPTIONAL";
                }
                item["mustTakeSignatureText"] = mustTakeSignatureText;
                item["numberofPhotosRequiredText"] = item.numberofPhotosRequired
                  ? item.numberofPhotosRequired.toString()
                  : "";
              });

              let jobDurationHours =
                currentVersion.jobDurationHours &&
                currentVersion.jobDurationHours.toString() !== ""
                  ? currentVersion.jobDurationHours.toString()
                  : "0";
              let jobDurationMinutes =
                currentVersion.jobDurationMinutes &&
                currentVersion.jobDurationMinutes.toString() !== ""
                  ? currentVersion.jobDurationMinutes.toString()
                  : "0";
              let duration = apiUtil.getDuration(
                jobDurationHours,
                jobDurationMinutes
              );

              currentVersion["duration"] = duration;
            }

            if (previousVersion) {
              previousVersion.templateSteps.forEach((item, i) => {
                let mustTakePhotoText = "NO";
                if (item.mustTakePhoto === CustomPhotoSignature.Yes) {
                  mustTakePhotoText = "YES";
                } else if (
                  item.mustTakePhoto === CustomPhotoSignature.Optional
                ) {
                  mustTakePhotoText = "OPTIONAL";
                }
                item["mustTakePhotoText"] = mustTakePhotoText;

                let mustTakeSignatureText = "NO";
                if (item.mustTakeSignature === CustomPhotoSignature.Yes) {
                  mustTakeSignatureText = "YES";
                } else if (
                  item.mustTakeSignature === CustomPhotoSignature.Optional
                ) {
                  mustTakeSignatureText = "OPTIONAL";
                }
                item["mustTakeSignatureText"] = mustTakeSignatureText;
                item["numberofPhotosRequiredText"] = item.numberofPhotosRequired
                  ? item.numberofPhotosRequired.toString()
                  : "";
              });

              let jobDurationHours =
                previousVersion.jobDurationHours &&
                previousVersion.jobDurationHours.toString() !== ""
                  ? previousVersion.jobDurationHours.toString()
                  : "0";
              let jobDurationMinutes =
                previousVersion.jobDurationMinutes &&
                previousVersion.jobDurationMinutes.toString() !== ""
                  ? previousVersion.jobDurationMinutes.toString()
                  : "0";
              let duration = apiUtil.getDuration(
                jobDurationHours,
                jobDurationMinutes
              );

              previousVersion["duration"] = duration;
            }

            callback(currentVersion, previousVersion);
        }
    },
    stepsTemplateJobTemplate: (data = null, obj = null) => {
        let html = [];
    
        let jobTypeInt = 0;
        try {
          if (data && data.templateSteps && data.templateSteps.length > 0) {
            jobTypeInt = parseInt(data.templateSteps[0].stepType);
          }
        } catch (e) {}
    
        let stepsStruct = apiUtil.getStepStruct(jobTypeInt);
        let templateSteps = data.templateSteps.sort(
          (a, b) => a.stepOrder - b.stepOrder
        );
    
        html.push(obj.emptyRowTemplate());
        html.push(obj.titleTemplate("ADDRESSES"));
        html.push(obj.hrTemplate());
    
        let columnsHeader = ["Default", "Location Name", "Address"];
    
        if (stepsStruct && stepsStruct.length > 0) {
          stepsStruct.forEach((struct, i) => {
            if (struct.jobType !== JobStepType.Shift) {
              if (
                struct.stepTypeDraw === StepType.BIN_IN ||
                struct.stepTypeDraw === StepType.BIN_OUT
              ) {
                if (struct.stepTypeDraw === StepType.BIN_IN) {
                  html.push(
                    <Box key={"addresses_" + (html.length + 1) + "_" + i} pl={1}>
                      {obj.emptyRowTemplate()}
                    </Box>
                  );
                  html.push(
                    <Box key={"addresses_" + (html.length + 1) + "_" + i} pl={1}>
                      {obj.titleTemplate(i + 1 + ". BIN IN")}
                    </Box>
                  );
                  html.push(
                    <Box key={"addresses_" + (html.length + 1) + "_" + i} pl={1}>
                      {obj.textTemplate("Return bin to this location")}
                    </Box>
                  );
                } else if (struct.stepTypeDraw === StepType.BIN_OUT) {
                  html.push(
                    <Box key={"addresses_" + (html.length + 1) + "_" + i} pl={1}>
                      {obj.emptyRowTemplate()}
                    </Box>
                  );

                  if (struct.jobType === JobStepType.Task) {
                    html.push(
                      <Box key={"addresses_" + (html.length + 1) + "_" + i} pl={1}>
                        {obj.titleTemplate(i + 1 + ". TASK")}
                      </Box>
                    );
                    html.push(
                      <Box key={"addresses_" + (html.length + 1) + "_" + i} pl={1}>
                        {obj.textTemplate("Do task from this address")}
                      </Box>
                    );

                  } else {
                    html.push(
                      <Box key={"addresses_" + (html.length + 1) + "_" + i} pl={1}>
                        {obj.titleTemplate(i + 1 + ". BIN OUT")}
                      </Box>
                    );
                    html.push(
                      <Box key={"addresses_" + (html.length + 1) + "_" + i} pl={1}>
                        {obj.textTemplate("Collect bin from this address")}
                      </Box>
                    );
                  }
                }
    
                let templateStepSites =
                  templateSteps &&
                  templateSteps[i] &&
                  templateSteps[i].templateStepSites &&
                  templateSteps[i].templateStepSites.length > 0
                    ? templateSteps[i].templateStepSites
                    : [];
    
                let rowData = [];
                if (templateStepSites && templateStepSites.length > 0) {
                  templateStepSites.forEach((item, j) => {
                    rowData.push([
                      obj.highlight(
                        "templateSteps." +
                          i +
                          ".templateStepSites." +
                          j +
                          ".isDefault"
                      ),
                      obj.highlight(
                        "templateSteps." +
                          i +
                          ".templateStepSites." +
                          j +
                          ".stepSiteName"
                      ),
                      obj.highlight(
                        "templateSteps." +
                          i +
                          ".templateStepSites." +
                          j +
                          ".stepSiteAddress"
                      ),
                    ]);
                  });
                }
    
                html.push(
                  <Box key={"addresses_" + (html.length + 1) + "_" + i} pl={1}>
                    {obj.emptyRowTemplate()}
                  </Box>
                );
                html.push(
                  <Box key={"addresses_" + (html.length + 1) + "_" + i} pl={1}>
                    {obj.tableTemplate(columnsHeader, rowData)}
                  </Box>
                );
              }
            }
          });
    
          html.push(obj.emptyRowTemplate());
          html.push(obj.titleTemplate("JOB STEPS"));
          html.push(obj.hrTemplate());
    
          stepsStruct.forEach((struct, i) => {
            let index = i + 1;
    
            if (struct.jobType !== JobStepType.Task) {
              if (
                index === 1 &&
                (struct.jobType === JobStepType.Pull ||
                  struct.jobType === JobStepType.Shift)
              ) {
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={1}>
                    {obj.emptyRowTemplate()}
                  </Box>
                );
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={1}>
                    {obj.titleTemplate(index + ". " + templateSteps[i].stepName)}
                  </Box>
                );
      
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={1}>
                    {obj.emptyRowTemplate()}
                  </Box>
                );
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={1}>
                    {obj.titleTemplate(index + 1 + ". In Progress")}
                  </Box>
                );
              } else if (
                index === 2 &&
                (struct.jobType === JobStepType.Pull ||
                  struct.jobType === JobStepType.Shift)
              ) {
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={1}>
                    {obj.emptyRowTemplate()}
                  </Box>
                );
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={1}>
                    {obj.titleTemplate(index + 1 + ". In Progress")}
                  </Box>
                );
              } else {
                if (index === 2 && (struct.jobType === JobStepType.Put) || (struct.jobType === JobStepType.Out)) {
                  html.push(
                    <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={1}>
                      {obj.emptyRowTemplate()}
                    </Box>
                  );
                  html.push(
                    <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={1}>
                      {obj.titleTemplate(
                        index + 1 + ". " + templateSteps[i].stepName
                      )}
                    </Box>
                  );
                } else {
                  html.push(
                    <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={1}>
                      {obj.emptyRowTemplate()}
                    </Box>
                  );
                  html.push(
                    <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={1}>
                      {obj.titleTemplate(index + ". " + templateSteps[i].stepName)}
                    </Box>
                  );
                }
              }
            } else {
              if(index === 2){
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={1}>
                    {obj.emptyRowTemplate()}
                  </Box>
                );
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={1}>
                    {obj.titleTemplate("1. Complete")}
                  </Box>
                );
              }
            }
    
            if (struct.jobType === JobStepType.Pull) {
              if (index === 1) {
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                    {obj.rowTemplateHalfAndHalf(
                      "The driver is required to input bin ID",
                      obj.highlight(
                        "templateSteps." + i + ".isRequireBinNumberToEnd"
                      )
                    )}
                  </Box>
                );
    
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                    {obj.rowTemplateHalfAndHalf(
                      "Required number of photos",
                      obj.highlight(
                        "templateSteps." + i + ".numberofPhotosRequiredText"
                      )
                    )}
                  </Box>
                );
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                    {obj.rowTemplateHalfAndHalf(
                      "Must take photo",
                      obj.highlight("templateSteps." + i + ".mustTakePhotoText")
                    )}
                  </Box>
                );
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                    {obj.rowTemplateHalfAndHalf(
                      "Must take signature",
                      obj.highlight("templateSteps." + i + ".mustTakeSignatureText")
                    )}
                  </Box>
                );
                // html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to collect payment", obj.highlight('templateSteps.' + i + '.isRequirePaymentCollection'))}</Box>);
              }
    
              if (index === 2) {
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                    {obj.rowTemplateHalfAndHalf(
                      "Required number of photos",
                      obj.highlight(
                        "templateSteps." + i + ".numberofPhotosRequiredText"
                      )
                    )}
                  </Box>
                );
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                    {obj.rowTemplateHalfAndHalf(
                      "Must take photo",
                      obj.highlight("templateSteps." + i + ".mustTakePhotoText")
                    )}
                  </Box>
                );
    
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                    {obj.rowTemplateHalfAndHalf(
                      "isRequireBinWeight",
                      obj.highlight("templateSteps." + i + ".isRequireBinWeight")
                    )}
                  </Box>
                );
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                    {obj.rowTemplateHalfAndHalf(
                      "isRequireReviewWasteType",
                      obj.highlight(
                        "templateSteps." + i + ".isRequireReviewWasteType"
                      )
                    )}
                  </Box>
                );
              }
            } else if (struct.jobType === JobStepType.Put) {
              if (index === 1) {
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                    {obj.rowTemplateHalfAndHalf(
                      "The driver is required to input bin ID",
                      obj.highlight(
                        "templateSteps." + i + ".isRequireBinNumberToEnd"
                      )
                    )}
                  </Box>
                );
              }
    
              if (index === 2) {
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                    {obj.rowTemplateHalfAndHalf(
                      "Required number of photos",
                      obj.highlight(
                        "templateSteps." + i + ".numberofPhotosRequiredText"
                      )
                    )}
                  </Box>
                );
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                    {obj.rowTemplateHalfAndHalf(
                      "Must take photo",
                      obj.highlight("templateSteps." + i + ".mustTakePhotoText")
                    )}
                  </Box>
                );
    
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                    {obj.rowTemplateHalfAndHalf(
                      "Must take signature",
                      obj.highlight("templateSteps." + i + ".mustTakeSignatureText")
                    )}
                  </Box>
                );
                // html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to collect payment", obj.highlight('templateSteps.' + i + '.isRequirePaymentCollection'))}</Box>);
              }

            } else if (struct.jobType === JobStepType.Task) {
              
              if (index === 2) {
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                    {obj.rowTemplateHalfAndHalf(
                      "Required number of photos",
                      obj.highlight(
                        "templateSteps." + i + ".numberofPhotosRequiredText"
                      )
                    )}
                  </Box>
                );
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                    {obj.rowTemplateHalfAndHalf(
                      "Must take photo",
                      obj.highlight("templateSteps." + i + ".mustTakePhotoText")
                    )}
                  </Box>
                );
    
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                    {obj.rowTemplateHalfAndHalf(
                      "Must take signature",
                      obj.highlight("templateSteps." + i + ".mustTakeSignatureText")
                    )}
                  </Box>
                );
                // html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to collect payment", obj.highlight('templateSteps.' + i + '.isRequirePaymentCollection'))}</Box>);
              }

            } else if (struct.jobType === JobStepType.Out) {
              if (index === 1) {
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                    {obj.rowTemplateHalfAndHalf(
                      "The driver is required to input bin ID",
                      obj.highlight(
                        "templateSteps." + i + ".isRequireBinNumberToEnd"
                      )
                    )}
                  </Box>
                );
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                    {obj.rowTemplateHalfAndHalf(
                      "The driver is required to input bin weight",
                      obj.highlight("templateSteps." + i + ".isRequireBinWeight")
                    )}
                  </Box>
                );

                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                    {obj.rowTemplateHalfAndHalf(
                      "Required number of photos",
                      obj.highlight(
                        "templateSteps." + i + ".numberofPhotosRequiredText"
                      )
                    )}
                  </Box>
                );
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                    {obj.rowTemplateHalfAndHalf(
                      "Must take photo",
                      obj.highlight("templateSteps." + i + ".mustTakePhotoText")
                    )}
                  </Box>
                );
              }
    
              if (index === 2) {
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                    {obj.rowTemplateHalfAndHalf(
                      "Required number of photos",
                      obj.highlight(
                        "templateSteps." + i + ".numberofPhotosRequiredText"
                      )
                    )}
                  </Box>
                );
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                    {obj.rowTemplateHalfAndHalf(
                      "Must take photo",
                      obj.highlight("templateSteps." + i + ".mustTakePhotoText")
                    )}
                  </Box>
                );
    
                // html.push(
                //   <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                //     {obj.rowTemplateHalfAndHalf(
                //       "Must take signature",
                //       obj.highlight("templateSteps." + i + ".mustTakeSignatureText")
                //     )}
                //   </Box>
                // );
                // html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to collect payment", obj.highlight('templateSteps.' + i + '.isRequirePaymentCollection'))}</Box>);
              }

            } else if (struct.jobType === JobStepType.Shift) {
              if (index === 1) {
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                    {obj.rowTemplateHalfAndHalf(
                      "The driver is required to input bin ID",
                      obj.highlight(
                        "templateSteps." + i + ".isRequireBinNumberToEnd"
                      )
                    )}
                  </Box>
                );
    
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                    {obj.rowTemplateHalfAndHalf(
                      "Required number of photos",
                      obj.highlight(
                        "templateSteps." + i + ".numberofPhotosRequiredText"
                      )
                    )}
                  </Box>
                );
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                    {obj.rowTemplateHalfAndHalf(
                      "Must take photo",
                      obj.highlight("templateSteps." + i + ".mustTakePhotoText")
                    )}
                  </Box>
                );
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                    {obj.rowTemplateHalfAndHalf(
                      "Must take signature",
                      obj.highlight("templateSteps." + i + ".mustTakeSignatureText")
                    )}
                  </Box>
                );
                // html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to collect payment", obj.highlight('templateSteps.' + i + '.isRequirePaymentCollection'))}</Box>);
              }
    
              if (index === 2) {
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                    {obj.rowTemplateHalfAndHalf(
                      "Required number of photos",
                      obj.highlight(
                        "templateSteps." + i + ".numberofPhotosRequiredText"
                      )
                    )}
                  </Box>
                );
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                    {obj.rowTemplateHalfAndHalf(
                      "Must take photo",
                      obj.highlight("templateSteps." + i + ".mustTakePhotoText")
                    )}
                  </Box>
                );
              }
            } else {
              if (jobTypeInt !== JobStepType.Pull) {
                if (index === 1) {
                  html.push(
                    <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                      {obj.rowTemplateHalfAndHalf(
                        "The driver is required to input bin ID",
                        obj.highlight(
                          "templateSteps." + i + ".isRequireBinNumberToEnd"
                        )
                      )}
                    </Box>
                  );
                }
              }
    
              if ((jobTypeInt !== JobStepType.Put) && (jobTypeInt !== JobStepType.Out)) {
                if (
                  jobTypeInt === JobStepType.Pull ||
                  jobTypeInt === JobStepType.Exchange
                ) {
                  if (index === 2) {
                    html.push(
                      <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                        {obj.rowTemplateHalfAndHalf(
                          "The driver is required to input bin ID",
                          obj.highlight(
                            "templateSteps." + i + ".isRequireBinNumberToEnd"
                          )
                        )}
                      </Box>
                    );
                  }
                }
    
                if (index === 2) {
                  html.push(
                    <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                      {obj.rowTemplateHalfAndHalf(
                        "Required number of photos",
                        obj.highlight(
                          "templateSteps." + i + ".numberofPhotosRequiredText"
                        )
                      )}
                    </Box>
                  );
                  html.push(
                    <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                      {obj.rowTemplateHalfAndHalf(
                        "Must take photo",
                        obj.highlight("templateSteps." + i + ".mustTakePhotoText")
                      )}
                    </Box>
                  );
                  html.push(
                    <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                      {obj.rowTemplateHalfAndHalf(
                        "Must take signature",
                        obj.highlight(
                          "templateSteps." + i + ".mustTakeSignatureText"
                        )
                      )}
                    </Box>
                  );
                  // html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to collect payment", obj.highlight('templateSteps.' + i + '.isRequirePaymentCollection'))}</Box>);
                }
              }
    
              if (index === 3) {
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                    {obj.rowTemplateHalfAndHalf(
                      "Required number of photos",
                      obj.highlight(
                        "templateSteps." + i + ".numberofPhotosRequiredText"
                      )
                    )}
                  </Box>
                );
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                    {obj.rowTemplateHalfAndHalf(
                      "Must take photo",
                      obj.highlight("templateSteps." + i + ".mustTakePhotoText")
                    )}
                  </Box>
                );
    
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                    {obj.rowTemplateHalfAndHalf(
                      "The driver is required to input bin weight",
                      obj.highlight("templateSteps." + i + ".isRequireBinWeight")
                    )}
                  </Box>
                );
                html.push(
                  <Box key={"job_steps_" + (html.length + 1) + "_" + i} pl={2}>
                    {obj.rowTemplateHalfAndHalf(
                      "The driver is required to review waste type",
                      obj.highlight(
                        "templateSteps." + i + ".isRequireReviewWasteType"
                      )
                    )}
                  </Box>
                );
              }
            }
          });
        }
    
        if (jobTypeInt !== JobStepType.Task) {
          html.push(obj.emptyRowTemplate());
          html.push(obj.titleTemplate("APP PERMISSION"));
          html.push(obj.hrTemplate());
      
          if (jobTypeInt !== JobStepType.Pull) {
            html.push(obj.emptyRowTemplate());
            html.push(
              <Box key={"app_permission_" + (html.length + 1)} pl={1}>
                {obj.titleTemplate("Bin out")}
              </Box>
            );
      
            html.push(
              <Box key={"app_permission_" + (html.length + 1)} pl={2}>
                {obj.rowTemplateHalfAndHalf(
                  "The driver can change bin type on driver app ",
                  obj.highlight("isEditableBinTypeOut")
                )}
              </Box>
            );
            html.push(
              <Box key={"app_permission_" + (html.length + 1)} pl={2}>
                {obj.rowTemplateHalfAndHalf(
                  "The driver can change waste type on driver app",
                  obj.highlight("isEditableWasteTypeOut")
                )}
              </Box>
            );
            html.push(
              <Box key={"app_permission_" + (html.length + 1)} pl={2}>
                {obj.rowTemplateHalfAndHalf(
                  "The driver can add new ID on driver app",
                  obj.highlight("isEditableBinNumberOut")
                )}
              </Box>
            );
          }
      
          if ((jobTypeInt !== JobStepType.Put) && (jobTypeInt !== JobStepType.Out)) {
            html.push(obj.emptyRowTemplate());
            html.push(
              <Box key={"app_permission_" + (html.length + 1)} pl={1}>
                {obj.titleTemplate("Bin in")}
              </Box>
            );
      
            html.push(
              <Box key={"app_permission_" + (html.length + 1)} pl={2}>
                {obj.rowTemplateHalfAndHalf(
                  "The driver can change waste type on driver app",
                  obj.highlight("isEditableWasteTypeIn")
                )}
              </Box>
            );
      
            if (jobTypeInt !== JobStepType.Shift ) {
              if (jobTypeInt !== JobStepType.OnTheSpot) {
                html.push(
                  <Box key={"app_permission_" + (html.length + 1)} pl={2}>
                    {obj.rowTemplateHalfAndHalf(
                      "The driver can add new ID on driver app",
                      obj.highlight("isEditableBinNumberIn")
                    )}
                  </Box>
                );
              }
            }
          
          }
        }
    
        html.push(obj.emptyRowTemplate());
        html.push(obj.titleTemplate("OTHERS"));
        html.push(obj.hrTemplate());
    
        html.push(
          <Box key={"others_" + (html.length + 1)} pl={1}>
            {obj.rowTemplateHalfAndHalf(
              "Bill my customer for this job type",
              obj.highlight("isBillable")
            )}
          </Box>
        );
        html.push(
          <Box key={"others_" + (html.length + 1)} pl={1}>
            {obj.rowTemplateHalfAndHalf(
              "Default job duration for this job type",
              obj.highlight("duration")
            )}
          </Box>
        );
      
        return html && html.length > 0 ? html : null;
    },
    

    getDetailsRecurringJob: (obj, index, currentVersion, previousVersion) => {
        let binInItem = (currentVersion && currentVersion.binInItem) ? currentVersion.binInItem : null;
        let binOutItem = (currentVersion && currentVersion.binOutItem) ? currentVersion.binOutItem : null;

        return <Box>
            {obj.rowTemplate("Template Name", obj.highlight('recurringJobName'))}
            {obj.rowTemplate("Description", obj.highlight('recurringJobDescription'))}
            {obj.rowTemplate("Customer Name", obj.highlight('customer.customerName'))}
            {obj.rowTemplate("Site Address", obj.highlight('customerSiteAddress'))}
            {obj.rowTemplate("Driver", obj.highlight('driverName'))}
            {obj.rowTemplate("Vehicle", obj.highlight('vehicleName'))}
            {obj.rowTemplate("Job Type Name", obj.highlight('jobTypeName'))}
            
            {binInItem && <>
                {obj.emptyRowTemplate()}
                {obj.titleTemplate("Bin In")}

                {obj.rowTemplate("Bin Type", obj.highlight('binInBinTypeItem'))}
                {obj.rowTemplate("Waste Types", obj.highlight('binInWasteTypesItem'))}
            </>}

            {binOutItem && <>
                {obj.emptyRowTemplate()}
                {obj.titleTemplate("Bin Out")}

                {obj.rowTemplate("Bin Type", obj.highlight('binOutBinTypeItem'))}
                {obj.rowTemplate("Waste Types", obj.highlight('binOutWasteTypesItem'))}
            </>}

            {AuditTrailFunctions.stepsTemplateRecurringJob(currentVersion, obj)}
        </Box>
    },
    getManageDataRecurringJob: (currentVersion, previousVersion, callback) => {
        if(callback){
            let days = [
                {
                    value: DayOfWeek.Monday,
                    label: 'Monday',
                    item: null,
                },
                {
                    value: DayOfWeek.Tuesday,
                    label: 'Tuesday',
                    item: null,
                },
                {
                    value: DayOfWeek.Wednesday,
                    label: 'Wednesday',
                    item: null,
                },
                {
                    value: DayOfWeek.Thursday,
                    label: 'Thursday',
                    item: null,
                },
                {
                    value: DayOfWeek.Friday,
                    label: 'Friday',
                    item: null,
                },
                {
                    value: DayOfWeek.Saturday,
                    label: 'Saturday',
                    item: null,
                },
                {
                    value: DayOfWeek.Sunday,
                    label: 'Sunday',
                    item: null,
                },
            ];


            if(currentVersion){
                let driverName = (currentVersion && currentVersion.assignedDriver && currentVersion.assignedDriver) ? currentVersion.assignedDriver.driverName : '';
                currentVersion['driverName'] = driverName;

                let vehicleName = (currentVersion && currentVersion.assignedVehicle && currentVersion.assignedVehicle) ? currentVersion.assignedVehicle.vehicleName : '';
                currentVersion['vehicleName'] = vehicleName;

                let customerSiteAddress = '';
                let steps = (currentVersion && currentVersion.steps && currentVersion.steps.length > 0) ? currentVersion.steps : [];
                if(steps && steps.length > 0){
                    steps.forEach((item) => {
                        if(item.customerSiteId && item.customerSiteId > 0){
                            customerSiteAddress = item.address;
                        }
                    });
                }
                currentVersion['customerSiteAddress'] = customerSiteAddress;


                currentVersion.steps.forEach((item, i) => {
                    let mustTakePhotoText = 'NO';
                    if(item.mustTakePhoto === CustomPhotoSignature.Yes){
                        mustTakePhotoText = 'YES';
                    } else if(item.mustTakePhoto === CustomPhotoSignature.Optional){
                        mustTakePhotoText = 'OPTIONAL';
                    }
                    item['mustTakePhotoText'] = mustTakePhotoText;

                    let mustTakeSignatureText = 'NO';
                    if(item.mustTakeSignature === CustomPhotoSignature.Yes){
                        mustTakeSignatureText = 'YES';
                    } else if(item.mustTakeSignature === CustomPhotoSignature.Optional){
                        mustTakeSignatureText = 'OPTIONAL';
                    }
                    item['mustTakeSignatureText'] = mustTakeSignatureText;
                    item['numberofPhotosRequiredText'] = (item.numberofPhotosRequired) ? item.numberofPhotosRequired.toString() : '';
                });
                    
                let jobDurationHours = (currentVersion.jobDurationHours && currentVersion.jobDurationHours.toString() !== '') ? currentVersion.jobDurationHours.toString() : '0';
                let jobDurationMinutes = (currentVersion.jobDurationMinutes && currentVersion.jobDurationMinutes.toString() !== '') ? currentVersion.jobDurationMinutes.toString() : '0';
                let duration = apiUtil.getDuration(jobDurationHours, jobDurationMinutes);

                currentVersion['duration'] = duration;

                
                let daysOfWeek = apiUtil.copyArr(days);
                daysOfWeek.forEach((day, i) => {
                    let schedules = (currentVersion && currentVersion.schedules && currentVersion.schedules.length > 0) ? currentVersion.schedules : [];
                    let dayIndex = schedules.findIndex((x) => x.day === day.value);
                    currentVersion[day.label] = (dayIndex !== -1) ? schedules[dayIndex].time : null;
                });

                
                let jobTypeInt = 0;
                try {
                    if(currentVersion && currentVersion.steps && currentVersion.steps.length > 0){
                        jobTypeInt = parseInt(currentVersion.steps[0].stepType);
                    }
                } catch(e){}

                let binInItem = null;
                let stepsStruct = apiUtil.getStepStruct(jobTypeInt);
                if(stepsStruct && stepsStruct.length > 0){
                    let index = stepsStruct.findIndex(x => x.stepTypeSave === StepType.BIN_IN);
                    if(index !== -1){
                        let steps = (currentVersion && currentVersion.steps && currentVersion.steps.length > 0) ? currentVersion.steps : [];
                        if(steps && steps.length > 0){
                            let binType = (steps[index] && steps[index].binType) ? steps[index].binType : null;
                            let binNumber = (steps[index] && steps[index].binNumber) ? steps[index].binNumber : null;
                            let wasteType = (steps[index] && steps[index].wasteType) ? steps[index].wasteType : null;
                            let wasteTypes = (steps[index] && steps[index].wasteTypes && steps[index].wasteTypes.length > 0) ? steps[index].wasteTypes : [];
                            
                            binInItem = {
                                binType: binType,
                                binNumber: binNumber,
                                wasteType: wasteType,
                                wasteTypes: ((wasteTypes && wasteTypes.length > 0) ? wasteTypes.map(x => x.wasteType.wasteTypeName).join(', ') : ''),
                            }
                        }
                    }
                }
                if(jobTypeInt === JobStepType.OnTheSpot) {
                    binInItem = null;
                }
                currentVersion['binInItem'] = binInItem;
                currentVersion['binInBinTypeItem'] = (binInItem && binInItem.binType) ? binInItem.binType.binTypeName : null;
                currentVersion['binInBinNumberItem'] = (binInItem && binInItem.binNumber) ? binInItem.binNumber : null;
                currentVersion['binInWasteTypeItem'] = (binInItem && binInItem.wasteType) ? binInItem.wasteType.wasteTypeName : null;
                currentVersion['binInWasteTypesItem'] = (binInItem && binInItem.wasteTypes) ? binInItem.wasteTypes : null;



                let binOutItem = null;
                if(jobTypeInt === JobStepType.Shift){
                    if(stepsStruct && stepsStruct.length > 0){
                        let index = stepsStruct.findIndex(x => x.binActivity === JobStepBinActivity.LoadBin);
                        if(index !== -1){
                            let steps = (currentVersion && currentVersion.steps && currentVersion.steps.length > 0) ? currentVersion.steps : [];
                            if(steps && steps.length > 0){
                                let binType = (steps[index] && steps[index].binType) ? steps[index].binType : null;
                                let binNumber = (steps[index] && steps[index].binNumber) ? steps[index].binNumber : null;
                                let wasteType = (steps[index] && steps[index].wasteType) ? steps[index].wasteType : null;
                                let wasteTypes = (steps[index] && steps[index].wasteTypes && steps[index].wasteTypes.length > 0) ? steps[index].wasteTypes : [];
                                
                                binOutItem = {
                                    binType: binType,
                                    binNumber: binNumber,
                                    wasteType: wasteType,
                                    wasteTypes: ((wasteTypes && wasteTypes.length > 0) ? wasteTypes.map(x => x.wasteType.wasteTypeName).join(', ') : ''),
                                }
                            }
                        }
                    }
                } else {
                    if(stepsStruct && stepsStruct.length > 0){
                        let index = stepsStruct.findIndex(x => x.stepTypeSave === StepType.BIN_OUT);
                        if(index !== -1){
                            let steps = (currentVersion && currentVersion.steps && currentVersion.steps.length > 0) ? currentVersion.steps : [];
                            if(steps && steps.length > 0){
                                let binType = (steps[index] && steps[index].binType) ? steps[index].binType : null;
                                let binNumber = (steps[index] && steps[index].binNumber) ? steps[index].binNumber : null;
                                let wasteType = (steps[index] && steps[index].wasteType) ? steps[index].wasteType : null;
                                let wasteTypes = (steps[index] && steps[index].wasteTypes && steps[index].wasteTypes.length > 0) ? steps[index].wasteTypes : [];
                                
                                binOutItem = {
                                    binType: binType,
                                    binNumber: binNumber,
                                    wasteType: wasteType,
                                    wasteTypes: ((wasteTypes && wasteTypes.length > 0) ? wasteTypes.map(x => x.wasteType.wasteTypeName).join(', ') : ''),
                                }
                            }
                        }
                    }
                }
                currentVersion['binOutItem'] = binOutItem;
                currentVersion['binOutBinTypeItem'] = (binOutItem && binOutItem.binType) ? binOutItem.binType.binTypeName : null;
                currentVersion['binOutBinNumberItem'] = (binOutItem && binOutItem.binNumber) ? binOutItem.binNumber : null;
                currentVersion['binOutWasteTypeItem'] = (binOutItem && binOutItem.wasteType) ? binOutItem.wasteType.wasteTypeName : null;
                currentVersion['binOutWasteTypesItem'] = (binOutItem && binOutItem.wasteTypes) ? binOutItem.wasteTypes : null;
            }
            
            if(previousVersion){
                let driverName = (previousVersion && previousVersion.assignedDriver && previousVersion.assignedDriver) ? previousVersion.assignedDriver.driverName : '';
                previousVersion['driverName'] = driverName;

                let vehicleName = (previousVersion && previousVersion.assignedVehicle && previousVersion.assignedVehicle) ? previousVersion.assignedVehicle.vehicleName : '';
                previousVersion['vehicleName'] = vehicleName;

                let customerSiteAddress = '';
                let steps = (previousVersion && previousVersion.steps && previousVersion.steps.length > 0) ? previousVersion.steps : [];
                if(steps && steps.length > 0){
                    steps.forEach((item) => {
                        if(item.customerSiteId && item.customerSiteId > 0){
                            customerSiteAddress = item.address;
                        }
                    });
                }
                previousVersion['customerSiteAddress'] = customerSiteAddress;


                previousVersion.steps.forEach((item, i) => {
                    let mustTakePhotoText = 'NO';
                    if(item.mustTakePhoto === CustomPhotoSignature.Yes){
                        mustTakePhotoText = 'YES';
                    } else if(item.mustTakePhoto === CustomPhotoSignature.Optional){
                        mustTakePhotoText = 'OPTIONAL';
                    }
                    item['mustTakePhotoText'] = mustTakePhotoText;

                    let mustTakeSignatureText = 'NO';
                    if(item.mustTakeSignature === CustomPhotoSignature.Yes){
                        mustTakeSignatureText = 'YES';
                    } else if(item.mustTakeSignature === CustomPhotoSignature.Optional){
                        mustTakeSignatureText = 'OPTIONAL';
                    }
                    item['mustTakeSignatureText'] = mustTakeSignatureText;
                    item['numberofPhotosRequiredText'] = (item.numberofPhotosRequired) ? item.numberofPhotosRequired.toString() : '';
                });
                    
                let jobDurationHours = (previousVersion.jobDurationHours && previousVersion.jobDurationHours.toString() !== '') ? previousVersion.jobDurationHours.toString() : '0';
                let jobDurationMinutes = (previousVersion.jobDurationMinutes && previousVersion.jobDurationMinutes.toString() !== '') ? previousVersion.jobDurationMinutes.toString() : '0';
                let duration = apiUtil.getDuration(jobDurationHours, jobDurationMinutes);

                previousVersion['duration'] = duration;


                let daysOfWeek = apiUtil.copyArr(days);
                daysOfWeek.forEach((day, i) => {
                    let schedules = (previousVersion && previousVersion.schedules && previousVersion.schedules.length > 0) ? previousVersion.schedules : [];
                    let dayIndex = schedules.findIndex((x) => x.day === day.value);
                    previousVersion[day.label] = (dayIndex !== -1) ? schedules[dayIndex].time : null;
                });


                let jobTypeInt = 0;
                try {
                    if(previousVersion && previousVersion.steps && previousVersion.steps.length > 0){
                        jobTypeInt = parseInt(previousVersion.steps[0].stepType);
                    }
                } catch(e){}

                let binInItem = null;
                let stepsStruct = apiUtil.getStepStruct(jobTypeInt);
                if(stepsStruct && stepsStruct.length > 0){
                    let index = stepsStruct.findIndex(x => x.stepTypeSave === StepType.BIN_IN);
                    if(index !== -1){
                        let steps = (previousVersion && previousVersion.steps && previousVersion.steps.length > 0) ? previousVersion.steps : [];
                        if(steps && steps.length > 0){
                            let binType = (steps[index] && steps[index].binType) ? steps[index].binType : null;
                            let binNumber = (steps[index] && steps[index].binNumber) ? steps[index].binNumber : null;
                            let wasteType = (steps[index] && steps[index].wasteType) ? steps[index].wasteType : null;
                            let wasteTypes = (steps[index] && steps[index].wasteTypes && steps[index].wasteTypes.length > 0) ? steps[index].wasteTypes : [];
                            
                            binInItem = {
                                binType: binType,
                                binNumber: binNumber,
                                wasteType: wasteType,
                                wasteTypes: ((wasteTypes && wasteTypes.length > 0) ? wasteTypes.map(x => x.wasteType.wasteTypeName).join(', ') : ''),
                            }
                        }
                    }
                }
                if(jobTypeInt === JobStepType.OnTheSpot) {
                    binInItem = null;
                }
                previousVersion['binInItem'] = binInItem;
                previousVersion['binInBinTypeItem'] = (binInItem && binInItem.binType) ? binInItem.binType.binTypeName : null;
                previousVersion['binInBinNumberItem'] = (binInItem && binInItem.binNumber) ? binInItem.binNumber : null;
                previousVersion['binInWasteTypeItem'] = (binInItem && binInItem.wasteType) ? binInItem.wasteType.wasteTypeName : null;
                previousVersion['binInWasteTypesItem'] = (binInItem && binInItem.wasteTypes) ? binInItem.wasteTypes : null;



                let binOutItem = null;
                if(jobTypeInt === JobStepType.Shift){
                    if(stepsStruct && stepsStruct.length > 0){
                        let index = stepsStruct.findIndex(x => x.binActivity === JobStepBinActivity.LoadBin);
                        if(index !== -1){
                            let steps = (previousVersion && previousVersion.steps && previousVersion.steps.length > 0) ? previousVersion.steps : [];
                            if(steps && steps.length > 0){
                                let binType = (steps[index] && steps[index].binType) ? steps[index].binType : null;
                                let binNumber = (steps[index] && steps[index].binNumber) ? steps[index].binNumber : null;
                                let wasteType = (steps[index] && steps[index].wasteType) ? steps[index].wasteType : null;
                                let wasteTypes = (steps[index] && steps[index].wasteTypes && steps[index].wasteTypes.length > 0) ? steps[index].wasteTypes : [];
                                
                                binOutItem = {
                                    binType: binType,
                                    binNumber: binNumber,
                                    wasteType: wasteType,
                                    wasteTypes: ((wasteTypes && wasteTypes.length > 0) ? wasteTypes.map(x => x.wasteType.wasteTypeName).join(', ') : ''),
                                }
                            }
                        }
                    }
                } else {
                    if(stepsStruct && stepsStruct.length > 0){
                        let index = stepsStruct.findIndex(x => x.stepTypeSave === StepType.BIN_OUT);
                        if(index !== -1){
                            let steps = (previousVersion && previousVersion.steps && previousVersion.steps.length > 0) ? previousVersion.steps : [];
                            if(steps && steps.length > 0){
                                let binType = (steps[index] && steps[index].binType) ? steps[index].binType : null;
                                let binNumber = (steps[index] && steps[index].binNumber) ? steps[index].binNumber : null;
                                let wasteType = (steps[index] && steps[index].wasteType) ? steps[index].wasteType : null;
                                let wasteTypes = (steps[index] && steps[index].wasteTypes && steps[index].wasteTypes.length > 0) ? steps[index].wasteTypes : [];
                                
                                binOutItem = {
                                    binType: binType,
                                    binNumber: binNumber,
                                    wasteType: wasteType,
                                    wasteTypes: ((wasteTypes && wasteTypes.length > 0) ? wasteTypes.map(x => x.wasteType.wasteTypeName).join(', ') : ''),
                                }
                            }
                        }
                    }
                }
                previousVersion['binOutItem'] = binOutItem;
                previousVersion['binOutBinTypeItem'] = (binOutItem && binOutItem.binType) ? binOutItem.binType.binTypeName : '';
                previousVersion['binOutBinNumberItem'] = (binOutItem && binOutItem.binNumber) ? binOutItem.binNumber : '';
                previousVersion['binOutWasteTypeItem'] = (binOutItem && binOutItem.wasteType) ? binOutItem.wasteType.wasteTypeName : '';
                previousVersion['binOutWasteTypesItem'] = (binOutItem && binOutItem.wasteTypes) ? binOutItem.wasteTypes : '';
            }

            callback(currentVersion, previousVersion);
        }
    },
    stepsTemplateRecurringJob: (data = null, obj = null) => {
		let html = [];

		let jobTypeInt = 0;
		try {
		  if(data && data.steps && data.steps.length > 0){
			jobTypeInt = parseInt(data.steps[0].stepType);
		  }
		} catch(e){}

		let stepsStruct = apiUtil.getStepStruct(jobTypeInt);

		html.push(obj.emptyRowTemplate());
		html.push(obj.titleTemplate("SCHEDULES"));
		html.push(obj.hrTemplate());
		
		html.push(<Box key={'schedules_' + (html.length+1)} pl={2}>{obj.rowTemplate('Monday', (data['Monday']) ? obj.highlight('Monday', (x) => (x ? moment(x).format(apiUtil.getDefaultTimeFormat()) : '-')) : '-')}</Box>);
		html.push(<Box key={'schedules_' + (html.length+1)} pl={2}>{obj.rowTemplate('Tuesday', (data['Tuesday']) ? obj.highlight('Tuesday', (x) => (x ? moment(x).format(apiUtil.getDefaultTimeFormat()) : '-')) : '-')}</Box>);
		html.push(<Box key={'schedules_' + (html.length+1)} pl={2}>{obj.rowTemplate('Wednesday', (data['Wednesday']) ? obj.highlight('Wednesday', (x) => (x ? moment(x).format(apiUtil.getDefaultTimeFormat()) : '-')) : '-')}</Box>);
		html.push(<Box key={'schedules_' + (html.length+1)} pl={2}>{obj.rowTemplate('Thursday', (data['Thursday']) ? obj.highlight('Thursday', (x) => (x ? moment(x).format(apiUtil.getDefaultTimeFormat()) : '-')) : '-')}</Box>);
		html.push(<Box key={'schedules_' + (html.length+1)} pl={2}>{obj.rowTemplate('Friday', (data['Friday']) ? obj.highlight('Friday', (x) => (x ? moment(x).format(apiUtil.getDefaultTimeFormat()) : '-')) : '-')}</Box>);
		html.push(<Box key={'schedules_' + (html.length+1)} pl={2}>{obj.rowTemplate('Saturday', (data['Saturday']) ? obj.highlight('Saturday', (x) => (x ? moment(x).format(apiUtil.getDefaultTimeFormat()) : '-')) : '-')}</Box>);
		html.push(<Box key={'schedules_' + (html.length+1)} pl={2}>{obj.rowTemplate('Sunday', (data['Sunday']) ? obj.highlight('Sunday', (x) => (x ? moment(x).format(apiUtil.getDefaultTimeFormat()) : '-')) : '-')}</Box>);
		html.push(obj.emptyRowTemplate());
		html.push(<Box key={'schedules_' + (html.length+1)} pl={2}>{obj.rowTemplate('Start Date', (data['startDate']) ? obj.highlight('startDate', (x) => moment(x).format('DD.MM.YYYY')) : '-')}</Box>);
		html.push(<Box key={'schedules_' + (html.length+1)} pl={2}>{obj.rowTemplate('End Date', (data['endDate']) ? obj.highlight('endDate', (x) => moment(x).format('DD.MM.YYYY')) : '-')}</Box>);

		if(stepsStruct && stepsStruct.length > 0){
			html.push(obj.emptyRowTemplate());
			html.push(obj.titleTemplate("OPTIONS"));
			html.push(obj.hrTemplate());

			stepsStruct.forEach((struct, i) => {
				let index = (i + 1);

				if((index === 1) && ((struct.jobType === JobStepType.Pull) || (struct.jobType === JobStepType.Shift))){
					html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={1}>{obj.emptyRowTemplate()}</Box>);
					html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={1}>{obj.titleTemplate(index + ". Start")}</Box>);

					html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={1}>{obj.emptyRowTemplate()}</Box>);
					html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={1}>{obj.titleTemplate((index+1) + ". In Progress")}</Box>);
				} else if((index === 2) && ((struct.jobType === JobStepType.Pull) || (struct.jobType === JobStepType.Shift))){
					html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={1}>{obj.emptyRowTemplate()}</Box>);
					html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={1}>{obj.titleTemplate((index+1) + ". In Progress")}</Box>);
				} else {
					let stepName = '';
					if(index === 1){
						stepName = 'Start';
					} else if(index === 2){
						stepName = 'In Progress';
					} else if(index === 3){
						stepName = 'Complete';
					}

					if((index === 2) && (struct.jobType === JobStepType.Put) && (struct.jobType === JobStepType.Out)){
						html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={1}>{obj.emptyRowTemplate()}</Box>);
						html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={1}>{obj.titleTemplate((index+1) + ". " + stepName)}</Box>);
					} else {
						html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={1}>{obj.emptyRowTemplate()}</Box>);
						html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={1}>{obj.titleTemplate(index + ". " + stepName)}</Box>);
					}
				}


				if(struct.jobType === JobStepType.Pull){
					if(index === 1){
						html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to input bin ID", obj.highlight('steps.' + i + '.isRequireBinNumberToEnd'))}</Box>);

						html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Required number of photos", obj.highlight('steps.' + i + '.numberofPhotosRequiredText'))}</Box>);
						html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Must take photo", obj.highlight('steps.' + i + '.mustTakePhotoText'))}</Box>);
						html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Must take signature", obj.highlight('steps.' + i + '.mustTakeSignatureText'))}</Box>);
						html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to collect payment", obj.highlight('steps.' + i + '.isRequirePaymentCollection'))}</Box>);
					}
		
					if(index === 2){
						html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Required number of photos", obj.highlight('steps.' + i + '.numberofPhotosRequiredText'))}</Box>);
						html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Must take photo", obj.highlight('steps.' + i + '.mustTakePhotoText'))}</Box>);
						
						html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("isRequireBinWeight", obj.highlight('steps.' + i + '.isRequireBinWeight'))}</Box>);
						html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("isRequireReviewWasteType", obj.highlight('steps.' + i + '.isRequireReviewWasteType'))}</Box>);
					}

				} else if(struct.jobType === JobStepType.Put){
					if(index === 1){
						html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to input bin ID", obj.highlight('steps.' + i + '.isRequireBinNumberToEnd'))}</Box>);
					}
		
					if(index === 2){
						html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Required number of photos", obj.highlight('steps.' + i + '.numberofPhotosRequiredText'))}</Box>);
						html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Must take photo", obj.highlight('steps.' + i + '.mustTakePhotoText'))}</Box>);

						html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Must take signature", obj.highlight('steps.' + i + '.mustTakeSignatureText'))}</Box>);
						html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to collect payment", obj.highlight('steps.' + i + '.isRequirePaymentCollection'))}</Box>);
					}

        } else if(struct.jobType === JobStepType.Out){
					if(index === 1){
						html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to input bin ID", obj.highlight('steps.' + i + '.isRequireBinNumberToEnd'))}</Box>);
						html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to input bin weight", obj.highlight('steps.' + i + '.isRequireBinWeight'))}</Box>);
					}
		
					if(index === 2){
						html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Required number of photos", obj.highlight('steps.' + i + '.numberofPhotosRequiredText'))}</Box>);
						html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Must take photo", obj.highlight('steps.' + i + '.mustTakePhotoText'))}</Box>);

						// html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Must take signature", obj.highlight('steps.' + i + '.mustTakeSignatureText'))}</Box>);
						// html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to collect payment", obj.highlight('steps.' + i + '.isRequirePaymentCollection'))}</Box>);
					}

				} else if(struct.jobType === JobStepType.Shift){
					if(index === 1){
						html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to input bin ID", obj.highlight('steps.' + i + '.isRequireBinNumberToEnd'))}</Box>);
						
						html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Required number of photos", obj.highlight('steps.' + i + '.numberofPhotosRequiredText'))}</Box>);
						html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Must take photo", obj.highlight('steps.' + i + '.mustTakePhotoText'))}</Box>);
						html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Must take signature", obj.highlight('steps.' + i + '.mustTakeSignatureText'))}</Box>);
						html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to collect payment", obj.highlight('steps.' + i + '.isRequirePaymentCollection'))}</Box>);
					}

					if(index === 2){
						html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Required number of photos", obj.highlight('steps.' + i + '.numberofPhotosRequiredText'))}</Box>);
						html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Must take photo", obj.highlight('steps.' + i + '.mustTakePhotoText'))}</Box>);
					}

				} else {
					if(jobTypeInt !== JobStepType.Pull){
						if(index === 1){
							html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to input bin ID", obj.highlight('steps.' + i + '.isRequireBinNumberToEnd'))}</Box>);
						}
					}
			
					if((jobTypeInt !== JobStepType.Put) && (jobTypeInt !== JobStepType.Out)){
						if((jobTypeInt === JobStepType.Pull) || (jobTypeInt === JobStepType.Exchange)){
							if(index === 2){
								html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to input bin ID", obj.highlight('steps.' + i + '.isRequireBinNumberToEnd'))}</Box>);
							}
						}
				
						if(index === 2){
							html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Required number of photos", obj.highlight('steps.' + i + '.numberofPhotosRequiredText'))}</Box>);
							html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Must take photo", obj.highlight('steps.' + i + '.mustTakePhotoText'))}</Box>);
							html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Must take signature", obj.highlight('steps.' + i + '.mustTakeSignatureText'))}</Box>);
							html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to collect payment", obj.highlight('steps.' + i + '.isRequirePaymentCollection'))}</Box>);
						}
					}
			
					if(index === 3){
						html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Required number of photos", obj.highlight('steps.' + i + '.numberofPhotosRequiredText'))}</Box>);
						html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Must take photo", obj.highlight('steps.' + i + '.mustTakePhotoText'))}</Box>);
						
						html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to input bin weight", obj.highlight('steps.' + i + '.isRequireBinWeight'))}</Box>);
						html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to review waste type", obj.highlight('steps.' + i + '.isRequireReviewWasteType'))}</Box>);
					}
				}

			});
		}


		html.push(obj.emptyRowTemplate());
		html.push(obj.titleTemplate("APP PERMISSION"));
		html.push(obj.hrTemplate());
		
		if(jobTypeInt !== JobStepType.Pull){
			html.push(obj.emptyRowTemplate());
			html.push(<Box key={'app_permission_' + (html.length+1)} pl={1}>{obj.titleTemplate("Bin out")}</Box>);

			html.push(<Box key={'app_permission_' + (html.length+1)} pl={2}>{obj.rowTemplateHalfAndHalf("The driver can change bin type on driver app", obj.highlight('isEditableBinTypeOut'))}</Box>);
			html.push(<Box key={'app_permission_' + (html.length+1)} pl={2}>{obj.rowTemplateHalfAndHalf("The driver can change waste type on driver app", obj.highlight('isEditableWasteTypeOut'))}</Box>);
		}

		if((jobTypeInt !== JobStepType.Put) && (jobTypeInt !== JobStepType.Out)){
			html.push(obj.emptyRowTemplate());
			html.push(<Box key={'app_permission_' + (html.length+1)} pl={1}>{obj.titleTemplate("Bin in")}</Box>);

			html.push(<Box key={'app_permission_' + (html.length+1)} pl={2}>{obj.rowTemplateHalfAndHalf("The driver can change waste type on driver app", obj.highlight('isEditableWasteTypeIn'))}</Box>);
		}

		
		html.push(obj.emptyRowTemplate());
		html.push(obj.titleTemplate("OTHERS"));
		html.push(obj.hrTemplate());

		html.push(<Box key={'others_' + (html.length+1)} pl={1}>{obj.rowTemplateHalfAndHalf("Default job duration for this job type", obj.highlight('duration'))}</Box>);

		return (html && html.length > 0) ? html : null;
	},
    

    getDetailsAccountBillingProfile: (obj, index, currentVersion, previousVersion) => {
        return (
            <Box>
              {obj.rowTemplate(
                "Billing Profile Name",
                obj.highlight("billingProfileName")
              )}
              {obj.rowTemplate(
                "Main Billing Profile",
                obj.highlight("isDefault")
              )}
              {obj.rowTemplate("Is Active", obj.highlight("isActive"))}
              {obj.rowTemplate(
                "Is Connect To Quickbook",
                obj.highlight("isConnectQuickbook")
              )}
              {obj.rowTemplate(
                "Is Connect To Xero",
                obj.highlight("isConnectXero")
              )}
              {obj.rowTemplate(
                "Invoice Number Prefix",
                obj.highlight("invoiceNumberPrefix")
              )}
              {obj.rowTemplate(
                "Purchase Order Prefix",
                obj.highlight("purchaseOrderNumberPrefix")
              )}

              {AuditTrailFunctions.customEmailMessageTemplateBillingProfile(currentVersion, obj)}
              {AuditTrailFunctions.deliveryOrderTemplateBillingProfile(currentVersion, obj)}
              {AuditTrailFunctions.printedReceiptTemplateBillingProfile(currentVersion, obj)}
            </Box>
        );
    },
    getManageDataAccountBillingProfile: (currentVersion, previousVersion, callback) => {
        if (callback) {
            if (currentVersion) {
              currentVersion["accountEmail"] =
                currentVersion.accountEmailTemplates &&
                currentVersion.accountEmailTemplates.length > 0
                  ? currentVersion.accountEmailTemplates[0]
                  : null;

              let accountDocSettings =
                currentVersion.accountDocSettings &&
                currentVersion.accountDocSettings.length > 0
                  ? currentVersion.accountDocSettings
                  : [];

              let detailsDeliveryOrder = [];
              if (accountDocSettings && accountDocSettings.length > 0) {
                let items = accountDocSettings.filter(
                  (x) => x.type === AccountDocSettingType.DeliveryOrder
                );
                if (items && items.length > 0) {
                  detailsDeliveryOrder = apiUtil.copyArr(
                    items &&
                      items[0] &&
                      items[0].accountDocSettingVariables &&
                      items[0].accountDocSettingVariables.length > 0
                      ? items[0].accountDocSettingVariables
                      : []
                  );
                }
              }
              currentVersion["detailsDeliveryOrder"] =
              AuditTrailFunctions.setDeliveryOrderFieldsBillingProfile(detailsDeliveryOrder);

              let detailsJobReceipt = [];
              if (accountDocSettings && accountDocSettings.length > 0) {
                let items = accountDocSettings.filter(
                  (x) => x.type === AccountDocSettingType.JobReceipt
                );
                if (items && items.length > 0) {
                  detailsJobReceipt = apiUtil.copyArr(
                    items &&
                      items[0] &&
                      items[0].accountDocSettingVariables &&
                      items[0].accountDocSettingVariables.length > 0
                      ? items[0].accountDocSettingVariables
                      : []
                  );
                }
              }
              currentVersion["detailsJobReceipt"] =
              AuditTrailFunctions.setPrintedReceiptFieldsBillingProfile(detailsJobReceipt);
            }

            if (previousVersion) {
              previousVersion["accountEmail"] =
                previousVersion.accountEmailTemplates &&
                previousVersion.accountEmailTemplates.length > 0
                  ? previousVersion.accountEmailTemplates[0]
                  : null;

              let accountDocSettings =
                previousVersion.accountDocSettings &&
                previousVersion.accountDocSettings.length > 0
                  ? previousVersion.accountDocSettings
                  : [];

              let detailsDeliveryOrder = [];
              if (accountDocSettings && accountDocSettings.length > 0) {
                let items = accountDocSettings.filter(
                  (x) => x.type === AccountDocSettingType.DeliveryOrder
                );
                if (items && items.length > 0) {
                  detailsDeliveryOrder = apiUtil.copyArr(
                    items &&
                      items[0] &&
                      items[0].accountDocSettingVariables &&
                      items[0].accountDocSettingVariables.length > 0
                      ? items[0].accountDocSettingVariables
                      : []
                  );
                }
              }
              previousVersion["detailsDeliveryOrder"] =
              AuditTrailFunctions.setDeliveryOrderFieldsBillingProfile(detailsDeliveryOrder);

              let detailsJobReceipt = [];
              if (accountDocSettings && accountDocSettings.length > 0) {
                let items = accountDocSettings.filter(
                  (x) => x.type === AccountDocSettingType.JobReceipt
                );
                if (items && items.length > 0) {
                  detailsJobReceipt = apiUtil.copyArr(
                    items &&
                      items[0] &&
                      items[0].accountDocSettingVariables &&
                      items[0].accountDocSettingVariables.length > 0
                      ? items[0].accountDocSettingVariables
                      : []
                  );
                }
              }
              previousVersion["detailsJobReceipt"] =
              AuditTrailFunctions.setPrintedReceiptFieldsBillingProfile(detailsJobReceipt);
            }

            callback(currentVersion, previousVersion);
        }
    },
    setDeliveryOrderFieldsBillingProfile: (accountDocSettingVariables = []) => {
        let data = {};
    
        if (accountDocSettingVariables && accountDocSettingVariables.length > 0) {
          let companyProfileAttachmentsItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "StringCompanyLogo"
          );
          let companyProfileAttachmentsUrl = [];
          if (companyProfileAttachmentsItem) {
            let stringCompanyLogo = apiUtil.findSettingsItem(
              companyProfileAttachmentsItem,
              AccountDocSettingVariables.String
            );
            if (stringCompanyLogo && stringCompanyLogo !== "") {
              companyProfileAttachmentsUrl.push(stringCompanyLogo);
            }
          }
          data["companyProfileAttachmentsUrl"] =
            companyProfileAttachmentsUrl.join(", ");
    
          let businessDetailsItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "StringHeaderText"
          );
          let businessDetails = apiUtil.findSettingsItem(
            businessDetailsItem,
            AccountDocSettingVariables.String
          );
          data["businessDetails"] = businessDetails;
    
          let prefixDoNumberItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "StringDOPrefix"
          );
          let prefixDoNumber = apiUtil.findSettingsItem(
            prefixDoNumberItem,
            AccountDocSettingVariables.String
          );
          data["prefixDoNumber"] = prefixDoNumber;
    
          let showCustomerNameItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowCustomerName"
          );
          let showCustomerName = apiUtil.findSettingsItem(
            showCustomerNameItem,
            AccountDocSettingVariables.Show
          );
          data["showCustomerName"] = showCustomerName;
    
          let labelCustomerNameItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "LabelCustomer_Name"
          );
          let labelCustomerName = apiUtil.findSettingsItem(
            labelCustomerNameItem,
            AccountDocSettingVariables.Label
          );
          data["labelCustomerName"] = labelCustomerName;
    
          let showCustomerAddressItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowCustomerAddress"
          );
          let showCustomerAddress = apiUtil.findSettingsItem(
            showCustomerAddressItem,
            AccountDocSettingVariables.Show
          );
          data["showCustomerAddress"] = showCustomerAddress;
    
          let labelCustomerAddressItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "LabelCustomer_Address"
          );
          let labelCustomerAddress = apiUtil.findSettingsItem(
            labelCustomerAddressItem,
            AccountDocSettingVariables.Label
          );
          data["labelCustomerAddress"] = labelCustomerAddress;
    
          let showProjectSiteItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowProjectSite"
          );
          let showProjectSite = apiUtil.findSettingsItem(
            showProjectSiteItem,
            AccountDocSettingVariables.Show
          );
          data["showProjectSite"] = showProjectSite;
    
          let labelProjectSiteItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "LabelProject_Site"
          );
          let labelProjectSite = apiUtil.findSettingsItem(
            labelProjectSiteItem,
            AccountDocSettingVariables.Label
          );
          data["labelProjectSite"] = labelProjectSite;
    
          let showJobTypeItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowJobType"
          );
          let showJobType = apiUtil.findSettingsItem(
            showJobTypeItem,
            AccountDocSettingVariables.Show
          );
          data["showJobType"] = showJobType;
    
          let labelJobTypeItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "LabelJob_Type"
          );
          let labelJobType = apiUtil.findSettingsItem(
            labelJobTypeItem,
            AccountDocSettingVariables.Label
          );
          data["labelJobType"] = labelJobType;
    
          let showJobCompletionItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowJobCompletion"
          );
          let showJobCompletion = apiUtil.findSettingsItem(
            showJobCompletionItem,
            AccountDocSettingVariables.Show
          );
          data["showJobCompletion"] = showJobCompletion;
    
          let labelJobCompletionItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "LabelJob_Completion"
          );
          let labelJobCompletion = apiUtil.findSettingsItem(
            labelJobCompletionItem,
            AccountDocSettingVariables.Label
          );
          data["labelJobCompletion"] = labelJobCompletion;
    
          let showDriverItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowDriver"
          );
          let showDriver = apiUtil.findSettingsItem(
            showDriverItem,
            AccountDocSettingVariables.Show
          );
          data["showDriver"] = showDriver;
    
          let labelDriverItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "LabelDriver"
          );
          let labelDriver = apiUtil.findSettingsItem(
            labelDriverItem,
            AccountDocSettingVariables.Label
          );
          data["labelDriver"] = labelDriver;
    
          let showVehicleItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowVehicle"
          );
          let showVehicle = apiUtil.findSettingsItem(
            showVehicleItem,
            AccountDocSettingVariables.Show
          );
          data["showVehicle"] = showVehicle;
    
          let labelVehicleItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "LabelVehicle"
          );
          let labelVehicle = apiUtil.findSettingsItem(
            labelVehicleItem,
            AccountDocSettingVariables.Label
          );
          data["labelVehicle"] = labelVehicle;
    
          let showBinINItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowBinNumberIN"
          );
          let showBinIN = apiUtil.findSettingsItem(
            showBinINItem,
            AccountDocSettingVariables.Show
          );
          data["showBinIN"] = showBinIN;
    
          let labelBinINItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "LabelBin_Number_IN"
          );
          let labelBinIN = apiUtil.findSettingsItem(
            labelBinINItem,
            AccountDocSettingVariables.Label
          );
          data["labelBinIN"] = labelBinIN;
    
          let showBinOUTItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowBinNumberOUT"
          );
          let showBinOUT = apiUtil.findSettingsItem(
            showBinOUTItem,
            AccountDocSettingVariables.Show
          );
          data["showBinOUT"] = showBinOUT;
    
          let labelBinOUTItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "LabelBin_Number_OUT"
          );
          let labelBinOUT = apiUtil.findSettingsItem(
            labelBinOUTItem,
            AccountDocSettingVariables.Label
          );
          data["labelBinOUT"] = labelBinOUT;
    
          let showSiteContactsItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowSiteContact"
          );
          let showSiteContacts = apiUtil.findSettingsItem(
            showSiteContactsItem,
            AccountDocSettingVariables.Show
          );
          data["showSiteContacts"] = showSiteContacts;
    
          let labelSiteContactsItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "LabelSite_Contact"
          );
          let labelSiteContacts = apiUtil.findSettingsItem(
            labelSiteContactsItem,
            AccountDocSettingVariables.Label
          );
          data["labelSiteContacts"] = labelSiteContacts;
    
          let showCollectedCashItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowAmountCollected"
          );
          let showCollectedCash = apiUtil.findSettingsItem(
            showCollectedCashItem,
            AccountDocSettingVariables.Show
          );
          data["showCollectedCash"] = showCollectedCash;
    
          let labelCollectedCashItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "LabelCollections"
          );
          let labelCollectedCash = apiUtil.findSettingsItem(
            labelCollectedCashItem,
            AccountDocSettingVariables.Label
          );
          data["labelCollectedCash"] = labelCollectedCash;
    
          let showAdditionalServicesItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowAdditionalServiceTable"
          );
          let showAdditionalServices = apiUtil.findSettingsItem(
            showAdditionalServicesItem,
            AccountDocSettingVariables.Show
          );
          data["showAdditionalServices"] = showAdditionalServices;
    
          let showStandardServicesRateItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowStandardServicesRate"
          );
          let showStandardServicesRate = apiUtil.findSettingsItem(
            showStandardServicesRateItem,
            AccountDocSettingVariables.Show
          );
          data["showStandardServicesRate"] = showStandardServicesRate;
    
          let showStandardServicesPriceItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowStandardServicesPrice"
          );
          let showStandardServicesPrice = apiUtil.findSettingsItem(
            showStandardServicesPriceItem,
            AccountDocSettingVariables.Show
          );
          data["showStandardServicesPrice"] = showStandardServicesPrice;
    
          let showAdditionalServicesRateItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowAdditionalServicesRate"
          );
          let showAdditionalServicesRate = apiUtil.findSettingsItem(
            showAdditionalServicesRateItem,
            AccountDocSettingVariables.Show
          );
          data["showAdditionalServicesRate"] = showAdditionalServicesRate;
    
          let showAdditionalServicesPriceItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowAdditionalServicesPrice"
          );
          let showAdditionalServicesPrice = apiUtil.findSettingsItem(
            showAdditionalServicesPriceItem,
            AccountDocSettingVariables.Show
          );
          data["showAdditionalServicesPrice"] = showAdditionalServicesPrice;
    
          let showSignatureAreaItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowSignature"
          );
          let showSignatureArea = apiUtil.findSettingsItem(
            showSignatureAreaItem,
            AccountDocSettingVariables.Show
          );
          data["showSignatureArea"] = showSignatureArea;
    
          let showFooterItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowDisclaimer"
          );
          let showFooter = apiUtil.findSettingsItem(
            showFooterItem,
            AccountDocSettingVariables.Show
          );
          data["showFooter"] = showFooter;
    
          let footerItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "StringDisclaimerText"
          );
          let footer = apiUtil.findSettingsItem(
            footerItem,
            AccountDocSettingVariables.String
          );
          data["footer"] = footer;
        }
    
        return data;
    },
    setPrintedReceiptFieldsBillingProfile: (accountDocSettingVariables = []) => {
        let data = {};
    
        if (accountDocSettingVariables && accountDocSettingVariables.length > 0) {
          let companyProfileAttachmentsItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "StringCompanyLogo"
          );
          let companyProfileAttachmentsUrl = [];
          if (companyProfileAttachmentsItem) {
            let stringCompanyLogo = apiUtil.findSettingsItem(
              companyProfileAttachmentsItem,
              AccountDocSettingVariables.String
            );
            if (stringCompanyLogo && stringCompanyLogo !== "") {
              companyProfileAttachmentsUrl.push(stringCompanyLogo);
            }
          }
          data["companyProfileAttachmentsUrl"] =
            companyProfileAttachmentsUrl.join(", ");
    
          let businessDetailsItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "StringHeaderText"
          );
          let businessDetails = apiUtil.findSettingsItem(
            businessDetailsItem,
            AccountDocSettingVariables.String
          );
          data["businessDetails"] = businessDetails;
    
          let labelCustomerAddressItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "LabelSite_Address"
          );
          let labelCustomerAddress = apiUtil.findSettingsItem(
            labelCustomerAddressItem,
            AccountDocSettingVariables.Label
          );
          data["labelCustomerAddress"] = labelCustomerAddress;
    
          let showCustomerNameItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowCustomerName"
          );
          let showCustomerName = apiUtil.findSettingsItem(
            showCustomerNameItem,
            AccountDocSettingVariables.Show
          );
          data["showCustomerName"] = showCustomerName;
    
          let labelCustomerNameItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "LabelCustomer_Name"
          );
          let labelCustomerName = apiUtil.findSettingsItem(
            labelCustomerNameItem,
            AccountDocSettingVariables.Label
          );
          data["labelCustomerName"] = labelCustomerName;
    
          let showDriverItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowDriver"
          );
          let showDriver = apiUtil.findSettingsItem(
            showDriverItem,
            AccountDocSettingVariables.Show
          );
          data["showDriver"] = showDriver;
    
          let labelDriverItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "LabelDriver"
          );
          let labelDriver = apiUtil.findSettingsItem(
            labelDriverItem,
            AccountDocSettingVariables.Label
          );
          data["labelDriver"] = labelDriver;
    
          let showVehicleItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowVehicle"
          );
          let showVehicle = apiUtil.findSettingsItem(
            showVehicleItem,
            AccountDocSettingVariables.Show
          );
          data["showVehicle"] = showVehicle;
    
          let labelVehicleItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "LabelVehicle"
          );
          let labelVehicle = apiUtil.findSettingsItem(
            labelVehicleItem,
            AccountDocSettingVariables.Label
          );
          data["labelVehicle"] = labelVehicle;
    
          let showJobTypeItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowJobType"
          );
          let showJobType = apiUtil.findSettingsItem(
            showJobTypeItem,
            AccountDocSettingVariables.Show
          );
          data["showJobType"] = showJobType;
    
          let labelJobTypeItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "LabelJob_Type"
          );
          let labelJobType = apiUtil.findSettingsItem(
            labelJobTypeItem,
            AccountDocSettingVariables.Label
          );
          data["labelJobType"] = labelJobType;
    
          let showBinDeliveredItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowBinDelivered"
          );
          let showBinDelivered = apiUtil.findSettingsItem(
            showBinDeliveredItem,
            AccountDocSettingVariables.Show
          );
          data["showBinDelivered"] = showBinDelivered;
    
          let labelBinDeliveredItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "LabelBin_Delivered"
          );
          let labelBinDelivered = apiUtil.findSettingsItem(
            labelBinDeliveredItem,
            AccountDocSettingVariables.Label
          );
          data["labelBinDelivered"] = labelBinDelivered;
    
          let showBinType1Item = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowBinDeliveredType"
          );
          let showBinType1 = apiUtil.findSettingsItem(
            showBinType1Item,
            AccountDocSettingVariables.Show
          );
          data["showBinType1"] = showBinType1;
    
          let labelBinType1Item = apiUtil.findSettings(
            accountDocSettingVariables,
            "LabelBin_Type_Delivered"
          );
          let labelBinType1 = apiUtil.findSettingsItem(
            labelBinType1Item,
            AccountDocSettingVariables.Label
          );
          data["labelBinType1"] = labelBinType1;
    
          let showPlannedWasteTypeItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowPlannedWasteType"
          );
          let showPlannedWasteType = apiUtil.findSettingsItem(
            showPlannedWasteTypeItem,
            AccountDocSettingVariables.Show
          );
          data["showPlannedWasteType"] = showPlannedWasteType;
    
          let labelPlannedWasteTypeItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "LabelPlanned_Waste_Type"
          );
          let labelPlannedWasteType = apiUtil.findSettingsItem(
            labelPlannedWasteTypeItem,
            AccountDocSettingVariables.Label
          );
          data["labelPlannedWasteType"] = labelPlannedWasteType;
    
          let showBinCollectedItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowBinCollected"
          );
          let showBinCollected = apiUtil.findSettingsItem(
            showBinCollectedItem,
            AccountDocSettingVariables.Show
          );
          data["showBinCollected"] = showBinCollected;
    
          let labelBinCollectedItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "LabelBin_Collected"
          );
          let labelBinCollected = apiUtil.findSettingsItem(
            labelBinCollectedItem,
            AccountDocSettingVariables.Label
          );
          data["labelBinCollected"] = labelBinCollected;
    
          let showBinType2Item = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowBinCollectedType"
          );
          let showBinType2 = apiUtil.findSettingsItem(
            showBinType2Item,
            AccountDocSettingVariables.Show
          );
          data["showBinType2"] = showBinType2;
    
          let labelBinType2Item = apiUtil.findSettings(
            accountDocSettingVariables,
            "LabelBin_Type_Collected"
          );
          let labelBinType2 = apiUtil.findSettingsItem(
            labelBinType2Item,
            AccountDocSettingVariables.Label
          );
          data["labelBinType2"] = labelBinType2;
    
          let showWasteTypeItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowWasteTypeCollected"
          );
          let showWasteType = apiUtil.findSettingsItem(
            showWasteTypeItem,
            AccountDocSettingVariables.Show
          );
          data["showWasteType"] = showWasteType;
    
          let labelWasteTypeItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "LabelWaste_Type_Collected"
          );
          let labelWasteType = apiUtil.findSettingsItem(
            labelWasteTypeItem,
            AccountDocSettingVariables.Label
          );
          data["labelWasteType"] = labelWasteType;
    
          let showPaymentTypeItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowPaymentType"
          );
          let showPaymentType = apiUtil.findSettingsItem(
            showPaymentTypeItem,
            AccountDocSettingVariables.Show
          );
          data["showPaymentType"] = showPaymentType;
    
          let labelPaymentTypeItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "LabelPayment_Type"
          );
          let labelPaymentType = apiUtil.findSettingsItem(
            labelPaymentTypeItem,
            AccountDocSettingVariables.Label
          );
          data["labelPaymentType"] = labelPaymentType;
    
          let showCollectedCashItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowAmountCollected"
          );
          let showCollectedCash = apiUtil.findSettingsItem(
            showCollectedCashItem,
            AccountDocSettingVariables.Show
          );
          data["showCollectedCash"] = showCollectedCash;
    
          let labelCollectedCashItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "LabelCollected_Cash"
          );
          let labelCollectedCash = apiUtil.findSettingsItem(
            labelCollectedCashItem,
            AccountDocSettingVariables.Label
          );
          data["labelCollectedCash"] = labelCollectedCash;
    
          let showSignatureAreaItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowSignature"
          );
          let showSignatureArea = apiUtil.findSettingsItem(
            showSignatureAreaItem,
            AccountDocSettingVariables.Show
          );
          data["showSignatureArea"] = showSignatureArea;
    
          let showSiteContactItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowSiteContact"
          );
          let showSiteContact = apiUtil.findSettingsItem(
            showSiteContactItem,
            AccountDocSettingVariables.Show
          );
          data["showSiteContact"] = showSiteContact;
    
          let showTelephoneItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowSiteTelephone"
          );
          let showTelephone = apiUtil.findSettingsItem(
            showTelephoneItem,
            AccountDocSettingVariables.Show
          );
          data["showTelephone"] = showTelephone;
    
          let footerItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "StringDisclaimerText"
          );
          let footer = apiUtil.findSettingsItem(
            footerItem,
            AccountDocSettingVariables.String
          );
          data["footer"] = footer;
    
          let showAdditionalServicesItem = apiUtil.findSettings(
            accountDocSettingVariables,
            "ShowAdditionalService"
          );
          let showAdditionalServices = apiUtil.findSettingsItem(
            showAdditionalServicesItem,
            AccountDocSettingVariables.Show
          );
          data["showAdditionalServices"] = showAdditionalServices;
        }
    
        return data;
    },
    customEmailMessageTemplateBillingProfile: (data = null, obj = null) => {
        let html = [];
    
        html.push(obj.emptyRowTemplate());
        html.push(obj.titleTemplate("CUSTOM EMAIL MESSAGE"));
        html.push(obj.hrTemplate());
    
        html.push(
          obj.rowTemplate("Subject", obj.highlight("accountEmail.subject"))
        );
        html.push(obj.rowTemplate("From", obj.highlight("accountEmail.fromName")));
        html.push(
          obj.rowTemplate("C.C", obj.highlight("accountEmail.recipientCc"))
        );
        html.push(
          // obj.rowTemplate("Message", obj.highlight("accountEmail.message"))
          obj.rowTemplate("Message", obj.highlight('accountEmail.message', (val) => {
            return (val && val != '') ? Utils.stringToHtmlTrim(val) : '';
          }))
        );
    
        return html && html.length > 0 ? html : null;
    },
    deliveryOrderTemplateBillingProfile: (data = null, obj = null) => {
        let html = [];
    
        html.push(obj.emptyRowTemplate());
        html.push(obj.titleTemplate("DELIVERY ORDER"));
        html.push(obj.hrTemplate());
    
        html.push(
          obj.rowTemplate(
            "Company Logo",
            obj.highlightImg("detailsDeliveryOrder.companyProfileAttachmentsUrl")
          )
        );
        html.push(
          obj.rowTemplate(
            "Business Details",
            obj.highlightHTML("detailsDeliveryOrder.businessDetails")
          )
        );
        html.push(
          obj.rowTemplate(
            "DO Number",
            obj.highlight("detailsDeliveryOrder.prefixDoNumber")
          )
        );
    
        html.push(obj.emptyRowTemplate());
        html.push(
          <Box key={"job_details_" + (html.length + 1)} pl={1}>
            {obj.titleTemplate("Job Details")}
          </Box>
        );
    
        let columnsHeaderJobDetails = ["Is visible", "Labels"];
        let rowDataJobDetails = [
          [
            obj.highlight("detailsDeliveryOrder.labelCustomerName"),
            obj.highlight("detailsDeliveryOrder.showCustomerName"),
          ],
          [
            obj.highlight("detailsDeliveryOrder.labelCustomerAddress"),
            obj.highlight("detailsDeliveryOrder.showCustomerAddress"),
          ],
          [
            obj.highlight("detailsDeliveryOrder.labelProjectSite"),
            obj.highlight("detailsDeliveryOrder.showProjectSite"),
          ],
          [
            obj.highlight("detailsDeliveryOrder.labelJobType"),
            obj.highlight("detailsDeliveryOrder.showJobType"),
          ],
          [
            obj.highlight("detailsDeliveryOrder.labelJobCompletion"),
            obj.highlight("detailsDeliveryOrder.showJobCompletion"),
          ],
          [
            obj.highlight("detailsDeliveryOrder.labelDriver"),
            obj.highlight("detailsDeliveryOrder.showDriver"),
          ],
          [
            obj.highlight("detailsDeliveryOrder.labelVehicle"),
            obj.highlight("detailsDeliveryOrder.showVehicle"),
          ],
          [
            obj.highlight("detailsDeliveryOrder.labelBinIN"),
            obj.highlight("detailsDeliveryOrder.showBinIN"),
          ],
          [
            obj.highlight("detailsDeliveryOrder.labelBinOUT"),
            obj.highlight("detailsDeliveryOrder.showBinOUT"),
          ],
          [
            obj.highlight("detailsDeliveryOrder.labelSiteContacts"),
            obj.highlight("detailsDeliveryOrder.showSiteContacts"),
          ],
          [
            obj.highlight("detailsDeliveryOrder.labelCollectedCash"),
            obj.highlight("detailsDeliveryOrder.showCollectedCash"),
          ],
        ];
        html.push(
          <Box key={"job_details_" + (html.length + 1)} pl={1}>
            {obj.tableTemplate(columnsHeaderJobDetails, rowDataJobDetails)}
          </Box>
        );
    
        html.push(obj.emptyRowTemplate());
        html.push(
          <Box key={"waste_bins_" + (html.length + 1)} pl={1}>
            {obj.titleTemplate("Waste & Bins")}
          </Box>
        );
    
        html.push(obj.emptyRowTemplate());
        html.push(
          <Box key={"waste_bins_standard_services" + (html.length + 1)} pl={2}>
            {obj.titleTemplate("Standard Services")}
          </Box>
        );
        html.push(
          <Box key={"waste_bins_standard_services" + (html.length + 1)} pl={3}>
            {obj.rowTemplate(
              "Rate",
              obj.highlight("detailsDeliveryOrder.showStandardServicesRate")
            )}
          </Box>
        );
        html.push(
          <Box key={"waste_bins_standard_services" + (html.length + 1)} pl={3}>
            {obj.rowTemplate(
              "Price",
              obj.highlight("detailsDeliveryOrder.showStandardServicesPrice")
            )}
          </Box>
        );
    
        html.push(obj.emptyRowTemplate());
        html.push(
          <Box key={"waste_bins_additional_services" + (html.length + 1)} pl={2}>
            {obj.titleTemplate("Additional Services")}
          </Box>
        );
        html.push(
          <Box key={"waste_bins_additional_services" + (html.length + 1)} pl={3}>
            {obj.rowTemplate(
              "Show Additional Services",
              obj.highlight("detailsDeliveryOrder.showAdditionalServices")
            )}
          </Box>
        );
        html.push(
          <Box key={"waste_bins_additional_services" + (html.length + 1)} pl={3}>
            {obj.rowTemplate(
              "Rate",
              obj.highlight("detailsDeliveryOrder.showAdditionalServicesRate")
            )}
          </Box>
        );
        html.push(
          <Box key={"waste_bins_additional_services" + (html.length + 1)} pl={3}>
            {obj.rowTemplate(
              "Price",
              obj.highlight("detailsDeliveryOrder.showAdditionalServicesPrice")
            )}
          </Box>
        );
    
        html.push(obj.emptyRowTemplate());
        html.push(
          <Box key={"footer_" + (html.length + 1)} pl={1}>
            {obj.titleTemplate("Footer")}
          </Box>
        );
        html.push(
          <Box key={"footer_" + (html.length + 1)} pl={3}>
            {obj.rowTemplate(
              "Signature Area",
              obj.highlight("detailsDeliveryOrder.showSignatureArea")
            )}
          </Box>
        );
        html.push(
          <Box key={"footer_" + (html.length + 1)} pl={3}>
            {obj.rowTemplate(
              // obj.highlight("detailsDeliveryOrder.footer"),
              obj.highlight('detailsDeliveryOrder.footer', (val) => {
                return (val && val != '') ? Utils.stringToHtmlTrim(val) : '';
              }),
              obj.highlight("detailsDeliveryOrder.showFooter")
            )}
          </Box>
        );
    
        return html && html.length > 0 ? html : null;
    },
    printedReceiptTemplateBillingProfile: (data = null, obj = null) => {
        let html = [];
    
        html.push(obj.emptyRowTemplate());
        html.push(obj.titleTemplate("PRINTED RECEIPT"));
        html.push(obj.hrTemplate());
    
        html.push(
          obj.rowTemplate(
            "Company Logo",
            obj.highlightImg("detailsJobReceipt.companyProfileAttachmentsUrl")
          )
        );
        html.push(
          obj.rowTemplate(
            "Business Details",
            obj.highlightHTML("detailsJobReceipt.businessDetails")
          )
        );
        html.push(
          obj.rowTemplate(
            "Show Customer Name",
            obj.highlight("detailsJobReceipt.showCustomerName")
          )
        );
        html.push(
          obj.rowTemplate(
            "Customer Address",
            obj.highlight("detailsJobReceipt.labelCustomerAddress")
          )
        );
    
        html.push(obj.emptyRowTemplate());
        html.push(
          <Box key={"job_details_" + (html.length + 1)} pl={1}>
            {obj.titleTemplate("Job Details")}
          </Box>
        );
    
        let columnsHeaderJobDetails = ["Is visible", "Labels"];
        let rowDataJobDetails = [
          [
            obj.highlight("detailsJobReceipt.labelDriver"),
            obj.highlight("detailsJobReceipt.showDriver"),
          ],
          [
            obj.highlight("detailsJobReceipt.labelVehicle"),
            obj.highlight("detailsJobReceipt.showVehicle"),
          ],
          [
            obj.highlight("detailsJobReceipt.labelJobType"),
            obj.highlight("detailsJobReceipt.showJobType"),
          ],
          [
            obj.highlight("detailsJobReceipt.labelBinDelivered"),
            obj.highlight("detailsJobReceipt.showBinDelivered"),
          ],
          [
            obj.highlight("detailsJobReceipt.labelBinType1"),
            obj.highlight("detailsJobReceipt.showBinType1"),
          ],
          [
            obj.highlight("detailsJobReceipt.labelPlannedWasteType"),
            obj.highlight("detailsJobReceipt.showPlannedWasteType"),
          ],
          [
            obj.highlight("detailsJobReceipt.labelBinCollected"),
            obj.highlight("detailsJobReceipt.showBinCollected"),
          ],
          [
            obj.highlight("detailsJobReceipt.labelBinType2"),
            obj.highlight("detailsJobReceipt.showBinType2"),
          ],
          [
            obj.highlight("detailsJobReceipt.labelWasteType"),
            obj.highlight("detailsJobReceipt.showWasteType"),
          ],
          [
            obj.highlight("detailsJobReceipt.labelPaymentType"),
            obj.highlight("detailsJobReceipt.showPaymentType"),
          ],
          [
            obj.highlight("detailsJobReceipt.labelCollectedCash"),
            obj.highlight("detailsJobReceipt.showCollectedCash"),
          ],
        ];
        html.push(
          <Box key={"job_details_" + (html.length + 1)} pl={1}>
            {obj.tableTemplate(columnsHeaderJobDetails, rowDataJobDetails)}
          </Box>
        );
    
        html.push(obj.emptyRowTemplate());
        html.push(
          <Box key={"waste_bins_" + (html.length + 1)} pl={1}>
            {obj.titleTemplate("Waste & Bins")}
          </Box>
        );
    
        html.push(obj.emptyRowTemplate());
        html.push(
          <Box key={"waste_bins_additional_services" + (html.length + 1)} pl={2}>
            {obj.titleTemplate("Additional Services")}
          </Box>
        );
        html.push(
          <Box key={"waste_bins_additional_services" + (html.length + 1)} pl={3}>
            {obj.rowTemplate(
              "Show Additional Services",
              obj.highlight("detailsJobReceipt.showAdditionalServices")
            )}
          </Box>
        );
    
        html.push(obj.emptyRowTemplate());
        html.push(
          <Box key={"footer_" + (html.length + 1)} pl={1}>
            {obj.titleTemplate("Footer")}
          </Box>
        );
        html.push(
          <Box key={"footer_" + (html.length + 1)} pl={3}>
            {obj.rowTemplate(
              "Signature Area",
              obj.highlight("detailsJobReceipt.showSignatureArea")
            )}
          </Box>
        );
        html.push(
          <Box key={"footer_" + (html.length + 1)} pl={3}>
            {obj.rowTemplate(
              "Show Site Contact",
              obj.highlight("detailsJobReceipt.showSiteContact")
            )}
          </Box>
        );
        html.push(
          <Box key={"footer_" + (html.length + 1)} pl={3}>
            {obj.rowTemplate(
              "Show Telephone",
              obj.highlight("detailsJobReceipt.showTelephone")
            )}
          </Box>
        );
        html.push(
          <Box key={"footer_" + (html.length + 1)} pl={3}>
            {obj.rowTemplate(
              "footer",
              obj.highlight("detailsJobReceipt.showFooter")
            )}
          </Box>
        );
    
        return html && html.length > 0 ? html : null;
    },
    

    getDetailsBinType: (obj, index, currentVersion, previousVersion) => {
        return (
            <Box>
              {obj.rowTemplate("Bin Type Name", obj.highlight("binTypeName"))}
              {/* {obj.rowTemplate("Bin Type Description", obj.highlight('binTypeDescription'))} */}
            </Box>
        );
    },
    getManageDataBinType: (currentVersion, previousVersion, callback) => {
        
    },
    

    getDetailsWasteType: (obj, index, currentVersion, previousVersion) => {
        return (
            <Box>
              {obj.rowTemplate(
                "Waste Type Name",
                obj.highlight("wasteTypeName"),
              )}
              {obj.rowTemplate(
                "Waste Category Name",
                obj.highlight("category.wasteTypeCategoryName")
              )}
            </Box>
        );
    },
    getManageDataWasteType: (currentVersion, previousVersion, callback) => {
        
    },
    

    getDetailsBinNumber: (obj, index, currentVersion, previousVersion) => {
        return <Box>
            {obj.rowTemplate("Bin Number Name", obj.highlight('binNumberName'))}
            {obj.rowTemplate("Bin Type", obj.highlight('binType.binTypeName'))}
        </Box>
    },
    getManageDataBinNumber: (currentVersion, previousVersion, callback) => {
        
    },
    

    getDetailsDriver: (obj, index, currentVersion, previousVersion) => {
        return <Box>
            {obj.rowTemplate("Driver Name", obj.highlight('driverName'))}
            {obj.rowTemplate("Username", obj.highlight('userName'))}
            {obj.rowTemplate("Is Assign Job To Phone", obj.highlight('isAssignJobToPhone'))}
            {obj.rowTemplate("Category", obj.highlight('category.driverCategoryName'))}
            {obj.rowTemplate("Default Vehicle Name", obj.highlight('defaultVehicle.vehicleName'))}
            {obj.rowTemplate("Payroll", obj.highlight('payrollTemplate.templateName'))}
        </Box>
    },
    getManageDataDriver: (currentVersion, previousVersion, callback) => {
        
    },
    

    getDetailsVehicle: (obj, index, currentVersion, previousVersion) => {
        let currentSites =
            currentVersion &&
            currentVersion.arrSites &&
            currentVersion.arrSites.length > 0
              ? currentVersion.arrSites
              : [];
        let previousSites =
        previousVersion &&
        previousVersion.arrSites &&
        previousVersion.arrSites.length > 0
            ? previousVersion.arrSites
            : [];

        let diffSites = obj.getArrDiff(
        previousSites,
        currentSites,
        (a, b) => {
            return a.customerSiteId === b.customerSiteId;
        }
        );
        let sites = obj.concatUniqueArrays(
        previousSites,
        currentSites,
        "customerSiteId"
        );

        return (
        <Box>
            {obj.rowTemplate("vehicle Name", obj.highlight("vehicleName"))}
            {obj.rowTemplate(
            "Default Driver Name",
            obj.highlight("defaultDriver.driverName")
            )}
            {obj.rowTemplate("Default Sites", obj.highlight("concatSites"))}

            <Box clone>
            <Grid item xs={12}>
                <Box pb={1}>
                <Grid container>
                    <Box clone>
                    <Grid item xs={12} md={3}>
                        Sites
                    </Grid>
                    </Box>
                    <Box clone className={"arr-diff-list"}>
                    <Grid item xs={12} md={"auto"}>
                        {sites &&
                        sites.length > 0 &&
                        sites.map((item, i) => {
                            let addedCls = obj.isExists(
                            diffSites.added,
                            item,
                            "customerSiteId"
                            )
                            ? "highlight-row-green"
                            : "";
                            let removedCls = obj.isExists(
                            diffSites.removed,
                            item,
                            "customerSiteId"
                            )
                            ? "highlight-row-red"
                            : "";

                            return (
                            <Box key={i}>
                                <Grid
                                container
                                className={
                                    addedCls !== "" ? addedCls : removedCls
                                }
                                >
                                <Box clone>
                                    <Grid item xs={12}>
                                    {item.customSiteName}
                                    </Grid>
                                </Box>
                                </Grid>
                                {obj.lineTemplate()}
                            </Box>
                            );
                        })}
                    </Grid>
                    </Box>
                </Grid>
                </Box>
            </Grid>
            </Box>
        </Box>
        );
    },
    getManageDataVehicle: (currentVersion, previousVersion, callback) => {
        if (callback) {
            if (currentVersion) {
              currentVersion["arrSites"] =
                currentVersion &&
                currentVersion.sites &&
                currentVersion.sites.length > 0
                  ? currentVersion.sites.map((x) => {
                      return {
                        customerSiteId: x.customerSiteId,
                        customSiteName: x.customSiteName,
                      };
                    })
                  : [];
            }

            if (previousVersion) {
              previousVersion["arrSites"] =
                previousVersion &&
                previousVersion.sites &&
                previousVersion.sites.length > 0
                  ? previousVersion.sites.map((x) => {
                      return {
                        customerSiteId: x.customerSiteId,
                        customSiteName: x.customSiteName,
                      };
                    })
                  : [];
            }

            callback(currentVersion, previousVersion);
        }
    },
    

    getDetailsPayroll: (obj, index, currentVersion, previousVersion) => {
        return <Box>
          {obj.rowTemplate("Template name", obj.highlight('templateName'))}
          {obj.rowTemplate("Description", obj.highlight('templateDescription'))}
          {obj.rowTemplate("Basic pay", obj.highlight('basicPayVal', (val) => {
            return Utils.getCurrency() + Utils.formatNumber(val);
          }))}
          {obj.rowTemplate("Billing Interval", obj.highlight('billingInterval'))}
          {obj.rowTemplate("Variable pay", obj.highlight('isVariablePay'))}
          {obj.rowTemplate("Template type", obj.highlight('templateType'))}

          {AuditTrailFunctions.mainChildItemsTemplatePayroll(currentVersion, previousVersion, obj)}
        </Box>
    },
    getManageDataPayroll: (currentVersion, previousVersion, callback) => {
        if (callback) {
            if (currentVersion) {
              currentVersion["basicPayVal"] = Utils.getCurrency() + Utils.formatNumber(currentVersion.basicPay);
  
  
              if(currentVersion.isVariablePay){
                currentVersion["templateType"] = (currentVersion.payrollTemplateVariableType === Payroll.TripCount) ? 'Trip Count' : 'Distance';
              } else {
                currentVersion["templateType"] = 'None';
              }
              
  
              if(currentVersion.billingDayInterval === BillingDayInterval.Year){
                currentVersion["billingInterval"] = 'Per Year';
              } else if(currentVersion.billingDayInterval === BillingDayInterval.Month){
                currentVersion["billingInterval"] = 'Per Month';
              } else if(currentVersion.billingDayInterval === BillingDayInterval.Week){
                currentVersion["billingInterval"] = 'Per Week';
              } else {
                currentVersion["billingInterval"] = 'Per Day';
              }
  
  
              let items = [];
              if(currentVersion.isVariablePay){
                if(currentVersion.payrollTemplateVariables && currentVersion.payrollTemplateVariables.length > 0){
                  currentVersion.payrollTemplateVariables.forEach(mainItem => {
                    let mItem = {
                      children: [],
                      workingDays: (mainItem.payrollTemplateWorkingDays && mainItem.payrollTemplateWorkingDays.length > 0) ? mainItem.payrollTemplateWorkingDays.map((x, i) => {
                        let workingDay = '';
                        if(x.variableDistanceWorkingDay === PayrollTemplateWorkingDays.PublicHolidays){
                          workingDay = 'PublicHolidays';
                        } else if(x.variableDistanceWorkingDay === PayrollTemplateWorkingDays.Weekends){
                          workingDay = 'Weekends';
                        } else if(x.variableDistanceWorkingDay === PayrollTemplateWorkingDays.Weekdays){
                          workingDay = 'Weekdays';
                        }
                        return {
                          workingDayId: x.payrollTemplateWorkingDayId,
                          workingDay: workingDay
                        }
                      }) : [],
                    };
  
                    mItem['workTimes'] = (mainItem.payrollTemplateVariableWorkingTimes && mainItem.payrollTemplateVariableWorkingTimes.length > 0) ? mainItem.payrollTemplateVariableWorkingTimes.map((x, i) => {
                      return {
                        accountWorkingHourId: (x.accountWorkingHour && x.accountWorkingHour.accountWorkingHourId && x.accountWorkingHour.accountWorkingHourId !== '') ? x.accountWorkingHour.accountWorkingHourId : '',
                        workingHourName: (x.accountWorkingHour && x.accountWorkingHour.workingHourName && x.accountWorkingHour.workingHourName !== '') ? x.accountWorkingHour.workingHourName : ''
                      }
                    }) : [];

                    mItem['jobTemplates'] = (mainItem.payrollTemplateVariableJobTemplates && mainItem.payrollTemplateVariableJobTemplates.length > 0) ? mainItem.payrollTemplateVariableJobTemplates.map((x, i) => {
                      return {
                        jobTemplateId: (x.jobTemplate && x.jobTemplate.jobTemplateId && x.jobTemplate.jobTemplateId !== '') ? x.jobTemplate.jobTemplateId : '',
                        jobTemplateName: (x.jobTemplate && x.jobTemplate.jobTemplateName && x.jobTemplate.jobTemplateName !== '') ? x.jobTemplate.jobTemplateName : ''
                      }
                    }) : [];
  
                    mItem['customers'] = (mainItem.payrollTemplateVariableCustomers && mainItem.payrollTemplateVariableCustomers.length > 0) ? mainItem.payrollTemplateVariableCustomers.map((x, i) => {
                      return {
                        customerId: (x.customer && x.customer.customerId && x.customer.customerId !== '') ? x.customer.customerId : '',
                        customerName: (x.customer && x.customer.customerName && x.customer.customerName !== '') ? x.customer.customerName : ''
                      }
                    }) : [];
                    
                    mItem['sites'] = (mainItem.payrollTemplateVariableSites && mainItem.payrollTemplateVariableSites.length > 0) ? mainItem.payrollTemplateVariableSites.map((x, i) => {
                      return {
                        customerSiteId: (x.site && x.site.customerSiteId && x.site.customerSiteId !== '') ? x.site.customerSiteId : '',
                        customerSiteName: (x.site && x.site.siteName && x.site.siteName !== '') ? x.site.siteName : ''
                      }
                    }) : [];

                    let children = [];
                    if(currentVersion.payrollTemplateVariableType === Payroll.TripCount) {
                      children = mainItem.payrollTemplateVariableTripCounts.map((x, i) => {
                        return {
                          variableTripCountName: x.variableTripCountName,
                          variableTripCount: x.variableTripCount,
                          payRate: x.payRate,
                        }
                      });
  
                    } else if (currentVersion.payrollTemplateVariableType === Payroll.Distance){
                      children = mainItem.payrollTemplateVariableRanges.map((x, i) => {
                        return {
                          variableDistanceName: x.variableDistanceName,
                          distanceFrom: x.distanceFrom,
                          distanceTo: x.distanceTo,
                          payRate: x.payRate,
                        }
                      });
                    }
                    mItem['children'] = children;
  
                    items.push(mItem);
                  });
                }
              }
              currentVersion["items"] = items;
            }
  
            
            if (previousVersion) {
              previousVersion["basicPayVal"] = Utils.getCurrency() + Utils.formatNumber(previousVersion.basicPay);
  
  
              if(previousVersion.isVariablePay){
                previousVersion["templateType"] = (previousVersion.payrollTemplateVariableType === Payroll.TripCount) ? 'Trip Count' : 'Distance';
              } else {
                previousVersion["templateType"] = 'None';
              }
              
  
              if(previousVersion.billingDayInterval === BillingDayInterval.Year){
                previousVersion["billingInterval"] = 'Per Year';
              } else if(previousVersion.billingDayInterval === BillingDayInterval.Month){
                previousVersion["billingInterval"] = 'Per Month';
              } else if(previousVersion.billingDayInterval === BillingDayInterval.Week){
                previousVersion["billingInterval"] = 'Per Week';
              } else {
                previousVersion["billingInterval"] = 'Per Day';
              }
  
  
              let items = [];
              if(previousVersion.isVariablePay){
                if(previousVersion.payrollTemplateVariables && previousVersion.payrollTemplateVariables.length > 0){
                  previousVersion.payrollTemplateVariables.forEach(mainItem => {
                    let mItem = {
                      children: [],
                      workingDays: (mainItem.payrollTemplateWorkingDays && mainItem.payrollTemplateWorkingDays.length > 0) ? mainItem.payrollTemplateWorkingDays.map((x, i) => {
                        let workingDay = '';
                        if(x.variableDistanceWorkingDay === PayrollTemplateWorkingDays.PublicHolidays){
                          workingDay = 'PublicHolidays';
                        } else if(x.variableDistanceWorkingDay === PayrollTemplateWorkingDays.Weekends){
                          workingDay = 'Weekends';
                        } else if(x.variableDistanceWorkingDay === PayrollTemplateWorkingDays.Weekdays){
                          workingDay = 'Weekdays';
                        }
                        return {
                          workingDayId: x.payrollTemplateWorkingDayId,
                          workingDay: workingDay
                        }
                      }) : [],
                    };
  
                    mItem['workTimes'] = (mainItem.payrollTemplateVariableWorkingTimes && mainItem.payrollTemplateVariableWorkingTimes.length > 0) ? mainItem.payrollTemplateVariableWorkingTimes.map((x, i) => {
                      return {
                        accountWorkingHourId: (x.accountWorkingHour && x.accountWorkingHour.accountWorkingHourId && x.accountWorkingHour.accountWorkingHourId !== '') ? x.accountWorkingHour.accountWorkingHourId : '',
                        workingHourName: (x.accountWorkingHour && x.accountWorkingHour.workingHourName && x.accountWorkingHour.workingHourName !== '') ? x.accountWorkingHour.workingHourName : ''
                      }
                    }) : [];
                    
                    mItem['jobTemplates'] = (mainItem.payrollTemplateVariableJobTemplates && mainItem.payrollTemplateVariableJobTemplates.length > 0) ? mainItem.payrollTemplateVariableJobTemplates.map((x, i) => {
                      return {
                        jobTemplateId: (x.jobTemplate && x.jobTemplate.jobTemplateId && x.jobTemplate.jobTemplateId !== '') ? x.jobTemplate.jobTemplateId : '',
                        jobTemplateName: (x.jobTemplate && x.jobTemplate.jobTemplateName && x.jobTemplate.jobTemplateName !== '') ? x.jobTemplate.jobTemplateName : ''
                      }
                    }) : [];
  
                    mItem['customers'] = (mainItem.payrollTemplateVariableCustomers && mainItem.payrollTemplateVariableCustomers.length > 0) ? mainItem.payrollTemplateVariableCustomers.map((x, i) => {
                      return {
                        customerId: (x.customer && x.customer.customerId && x.customer.customerId !== '') ? x.customer.customerId : '',
                        customerName: (x.customer && x.customer.customerName && x.customer.customerName !== '') ? x.customer.customerName : ''
                      }
                    }) : [];
                    
                    mItem['sites'] = (mainItem.payrollTemplateVariableSites && mainItem.payrollTemplateVariableSites.length > 0) ? mainItem.payrollTemplateVariableSites.map((x, i) => {
                      return {
                        customerSiteId: (x.site && x.site.customerSiteId && x.site.customerSiteId !== '') ? x.site.customerSiteId : '',
                        customerSiteName: (x.site && x.site.customerSiteName && x.site.customerSiteName !== '') ? x.site.customerSiteName : ''
                      }
                    }) : [];

                    let children = [];
                    if(previousVersion.payrollTemplateVariableType === Payroll.TripCount) {
                      children = mainItem.payrollTemplateVariableTripCounts.map((x, i) => {
                        return {
                          variableTripCountName: x.variableTripCountName,
                          variableTripCount: x.variableTripCount,
                          payRate: x.payRate,
                        }
                      });
  
                    } else if (previousVersion.payrollTemplateVariableType === Payroll.Distance){
                      children = mainItem.payrollTemplateVariableRanges.map((x, i) => {
                        return {
                          variableDistanceName: x.variableDistanceName,
                          distanceFrom: x.distanceFrom,
                          distanceTo: x.distanceTo,
                          payRate: x.payRate,
                        }
                      });
                    }
                    mItem['children'] = children;
  
                    items.push(mItem);
                  });
                }
              }
              previousVersion["items"] = items;
            }
  
  
            callback(currentVersion, previousVersion);
        }
    },
    mainChildItemsTemplatePayroll: (currentVersion = null, previousVersion = null, obj = null) => {
        let html = [];
        
        if(currentVersion.templateType !== 'None'){
          html.push(obj.emptyRowTemplate());
          html.push(obj.titleTemplate("Variable Pay by " + currentVersion.templateType));
          html.push(obj.lineTemplate());
    
          html.push(obj.customTemplate(<Grid container>
    
            {currentVersion.isVariablePay && currentVersion.items.map((mainItem, i) => {
              return <React.Fragment key={i}>
                
                {/* MAIN ITEM */}
                <Box clone pl={2}
                  style={{
                    borderRadius: '5px',
                    border: '1px solid #e5e5ea',
                    padding: '5px',
                  }}
                >
                  <Grid item xs={12}>
    
                    <Grid container>
                      <Box clone>
                        <Grid item xs={12}>
                          
                          <Grid container>
                            
                            <Box clone pb={1}>
                              <Grid item xs={12}>

                                {obj.rowTemplate("Is apply all working days", obj.highlight('payrollTemplateVariables.' + i + '.isApplyAllWorkDays'))}
    
                              </Grid>
                            </Box>

                            {!currentVersion.payrollTemplateVariables[i].isApplyAllWorkDays && <Box clone pb={1}>
                              <Grid item xs={12}>

                                <Grid container>
                                  <Box clone>
                                    <Grid item xs={12} md={3}>Working days</Grid>
                                  </Box>
                                  <Box clone>
                                    <Grid item xs={12} md={9}>
    
                                      {obj.tableTemplate(
                                        [""],
                                        mainItem.workingDays.map((child, c) => {
                                          return [
                                            obj.highlight('items.' + i + '.workingDays.' + c + '.workingDay'),
                                          ]
                                        }),
                                        '-',
                                        'no-padding-audit-trail-table'
                                      )}
    
                                    </Grid>
                                  </Box>
                                </Grid>
    
                              </Grid>
                            </Box>}
                            
                            <Box clone pb={1}>
                              <Grid item xs={12}>

                                {obj.rowTemplate("Is apply all work time", obj.highlight('payrollTemplateVariables.' + i + '.isApplyAllWorkTime'))}
    
                              </Grid>
                            </Box>

                            {!currentVersion.payrollTemplateVariables[i].isApplyAllWorkTime && <Box clone pb={1}>
                              <Grid item xs={12}>

                                <Grid container>
                                  <Box clone>
                                    <Grid item xs={12} md={3}>Work time</Grid>
                                  </Box>
                                  <Box clone>
                                    <Grid item xs={12} md={9}>
    
                                      {obj.tableTemplate(
                                        [""],
                                        mainItem.workTimes.map((child, c) => {
                                          return [
                                            obj.highlight('items.' + i + '.workTimes.' + c + '.workingHourName'),
                                          ]
                                        }),
                                        '-',
                                        'no-padding-audit-trail-table'
                                      )}
    
                                    </Grid>
                                  </Box>
                                </Grid>
    
                              </Grid>
                            </Box>}

                            <Box clone pb={1}>
                              <Grid item xs={12}>

                                {obj.rowTemplate("Is apply all job types", obj.highlight('payrollTemplateVariables.' + i + '.isApplyAllJobTypes'))}
    
                              </Grid>
                            </Box>
    
                            {!currentVersion.payrollTemplateVariables[i].isApplyAllJobTypes && <Box clone pb={1}>
                              <Grid item xs={12}>

                                <Grid container>
                                  <Box clone>
                                    <Grid item xs={12} md={3}>Job templates</Grid>
                                  </Box>
                                  <Box clone>
                                    <Grid item xs={12} md={9}>

                                      {obj.tableTemplate(
                                        [""],
                                        mainItem.jobTemplates.map((child, c) => {
                                          return [
                                            obj.highlight('items.' + i + '.jobTemplates.' + c + '.jobTemplateName'),
                                          ]
                                        }),
                                        '-',
                                        'no-padding-audit-trail-table'
                                      )}

                                    </Grid>
                                  </Box>
                                </Grid>

                              </Grid>
                            </Box>}
                            
                            
                            <Box clone pb={1}>
                              <Grid item xs={12}>

                                {obj.rowTemplate("Is apply all customer", obj.highlight('payrollTemplateVariables.' + i + '.isApplyAllCustomer'))}
    
                              </Grid>
                            </Box>

                            {!currentVersion.payrollTemplateVariables[i].isApplyAllCustomer && <Box clone pb={1}>
                              <Grid item xs={12}>

                                <Grid container>
                                  <Box clone>
                                    <Grid item xs={12} md={3}>Customers</Grid>
                                  </Box>
                                  <Box clone>
                                    <Grid item xs={12} md={9}>
                                      
                                      {obj.tableTemplate(
                                        [""],
                                        mainItem.customers.map((child, c) => {
                                          return [
                                            obj.highlight('items.' + i + '.customers.' + c + '.customerName'),
                                          ]
                                        }),
                                        '-',
                                        'no-padding-audit-trail-table'
                                      )}

                                    </Grid>
                                  </Box>
                                </Grid>

                              </Grid>
                            </Box>}
                            
                            
                            <Box clone pb={1}>
                              <Grid item xs={12}>

                                {obj.rowTemplate("Is apply all customer site", obj.highlight('payrollTemplateVariables.' + i + '.isApplyAllCustomerSite'))}
    
                              </Grid>
                            </Box>

                            {!currentVersion.payrollTemplateVariables[i].isApplyAllCustomerSite && <Box clone pb={1}>
                              <Grid item xs={12}>

                                <Grid container>
                                  <Box clone>
                                    <Grid item xs={12} md={3}>Customer sites</Grid>
                                  </Box>
                                  <Box clone>
                                    <Grid item xs={12} md={9}>
                                      
                                      {obj.tableTemplate(
                                        [""],
                                        mainItem.sites.map((child, c) => {
                                          return [
                                            obj.highlight('items.' + i + '.sites.' + c + '.customerSiteName'),
                                          ]
                                        }),
                                        '-',
                                        'no-padding-audit-trail-table'
                                      )}

                                    </Grid>
                                  </Box>
                                </Grid>

                              </Grid>
                            </Box>}

                          </Grid>
                        
                        </Grid>
                      </Box>
                      <Box clone>
                        <Grid item xs={12}>
    
                        {
                          (currentVersion.templateType === 'Trip Count')
                          ?
                          <Box pt={2}>
                            <Box style={{ height: '1px', backgroundColor: '#e5e5ea' }}></Box>
                            {obj.tableTemplate(
                              ["Name", "Pay rate"],
                              mainItem.children.map((child, c) => {
                                return [
                                  obj.highlight('items.' + i + '.children.' + c + '.variableTripCountName'),
                                  obj.highlight('items.' + i + '.children.' + c + '.payRate', (v) => Utils.getCurrency() + Utils.formatNumber(v)),
                                ]
                              })
                            )}
                          </Box>
                          :
                          <Box pt={2}>
                            <Box style={{ height: '1px', backgroundColor: '#e5e5ea' }}></Box>
                            {obj.tableTemplate(
                              ["Name", "From", "To", "Pay rate"],
                              mainItem.children.map((child, c) => {
                                return [
                                  obj.highlight('items.' + i + '.children.' + c + '.variableDistanceName'),
                                  obj.highlight('items.' + i + '.children.' + c + '.distanceFrom'),
                                  obj.highlight('items.' + i + '.children.' + c + '.distanceTo'),
                                  obj.highlight('items.' + i + '.children.' + c + '.payRate', (v) => Utils.getCurrency() + Utils.formatNumber(v)),
                                ]
                              })
                            )}
                          </Box>
                        }
    
                        </Grid>
                      </Box>
                    </Grid>
    
                  </Grid>
                </Box>
                {/* END MAIN ITEM */}
                
                {(i < (currentVersion.items.length - 1)) && <Box clone pl={2}>
                  <Grid item xs={12}>
                    <Box>&nbsp;</Box>
                  </Grid>
                </Box>}
    
              </React.Fragment>
            })}
            
          </Grid>));
        }
        
        return html && html.length > 0 ? html : null;
    },

  getDetailsAccountReminder: (obj, index, currentVersion, previousVersion) => {
    let currentTeamMembers = (currentVersion && currentVersion.teamMembers && currentVersion.teamMembers.length > 0) ? currentVersion.teamMembers : [];
    let previousTeamMembers = (previousVersion && previousVersion.teamMembers && previousVersion.teamMembers.length > 0) ? previousVersion.teamMembers : [];
    
    let diffTeamMembers = obj.getArrDiff(previousTeamMembers, currentTeamMembers, (a, b) => {
      return a.userId === b.userId;
    });
    let teamMembers =  obj.concatUniqueArrays(previousTeamMembers, currentTeamMembers, 'userId');
        
      return (
          <Box
            style={{ wordBreak: 'breakAll' }}
          >
            {obj.rowTemplate("Customer Name", obj.highlight("customerName"))}
            {obj.rowTemplate("Customer Site Name", obj.highlight("customerSiteName"))}
            {obj.rowTemplate("Remarks", obj.highlight("reminderDescripton"))}
            {obj.rowTemplate("Status", obj.highlight("statusDisplay"))}
            {obj.rowTemplate("Reminder Date", obj.highlight("reminderDate", (x) => x ? moment(x).format(apiUtil.getDefaultDateTimeFormat()) : '-'))}

            <Box clone>
              <Grid item xs={12}>
                <Box pb={1}>
                  <Grid container>
                      <Box clone>
                          <Grid item xs={12} md={3}>Team Members</Grid>
                      </Box>
                      <Box clone className={'arr-diff-list'}>
                          <Grid item xs={12} md={'auto'}>

                            {(teamMembers && teamMembers.length > 0) && teamMembers.map((item, i) => {
                              let addedCls = (obj.isExists(diffTeamMembers.added, item, 'userId')) ? 'highlight-row-green' : '';
                              let removedCls = (obj.isExists(diffTeamMembers.removed, item, 'userId')) ? 'highlight-row-red' : '';

                              return <Box key={i}>
                                <Grid container className={(addedCls !== '') ? addedCls : removedCls}>
                                  <Box clone>
                                    <Grid item xs={12}>
                                      {item.userName}
                                    </Grid>
                                  </Box>
                                </Grid>
                                {obj.lineTemplate()}
                              </Box>
                            })}

                          </Grid>
                      </Box>
                  </Grid>
                </Box>
              </Grid>
            </Box>

          </Box>
      );
  },
  getManageDataAccountReminder: (currentVersion, previousVersion, callback) => {
    if(callback){
      if(currentVersion){
        currentVersion['customerName'] = (currentVersion && currentVersion.customer && currentVersion.customer.customerName && currentVersion.customer.customerName != '') ? currentVersion.customer.customerName : '';
      }

      if(previousVersion){
        previousVersion['customerName'] = (previousVersion && previousVersion.customer && previousVersion.customer.customerName && previousVersion.customer.customerName != '') ? previousVersion.customer.customerName : '';
      }



      if(currentVersion){
        currentVersion['customerSiteName'] = (currentVersion && currentVersion.customerSite && currentVersion.customerSite.siteNameDisplay && currentVersion.customerSite.siteNameDisplay != '') ? currentVersion.customerSite.siteNameDisplay : '';
      }

      if(previousVersion){
        previousVersion['customerSiteName'] = (previousVersion && previousVersion.customerSite && previousVersion.customerSite.siteNameDisplay && previousVersion.customerSite.siteNameDisplay != '') ? previousVersion.customerSite.siteNameDisplay : '';
      }


      if(currentVersion){
        currentVersion['teamMembers'] = (currentVersion && currentVersion.accountReminderUsers && currentVersion.accountReminderUsers.length > 0) ? currentVersion.accountReminderUsers.map(x => {
          return {
            userId: x.AccountReminderUserId,
            userName: x.name,
          }
        }) : [];
      }
    
      if(previousVersion){
        previousVersion['teamMembers'] = (previousVersion && previousVersion.accountReminderUsers && previousVersion.accountReminderUsers.length > 0) ? previousVersion.accountReminderUsers.map(x => {
          return {
            userId: x.AccountReminderUserId,
            userName: x.name,
          }
        }) : [];
      }

      callback(currentVersion, previousVersion);
    }
  },

  getDetailsAccountJobWorkingTime: (obj, index, currentVersion, previousVersion) => {
      return (
          <Box
            style={{ wordBreak: 'breakAll' }}
          >
            {obj.rowTemplate("Order", obj.highlight("order"))}
            {obj.rowTemplate("Text", obj.highlight("workingTimeName"))}
            {obj.rowTemplate("Start time", obj.highlight("startTime", (x) => x ? moment(x).format(apiUtil.getDefaultTimeFormat()) : '-'))}
            {obj.rowTemplate("End time", obj.highlight("endTime", (x) => x ? moment(x).format(apiUtil.getDefaultTimeFormat()) : '-'))}
          </Box>
      );
  },
  getManageDataAccountJobWorkingTime: (currentVersion, previousVersion, callback) => {
    
  },

  getDetailsJob: (obj, index, currentVersion, previousVersion) => {
    return (
        <Box
          style={{ wordBreak: 'breakAll' }}
        >
          {obj.titleTemplate(obj.highlight("jobNumber"))}
          {/* {obj.highlight("jobId")} */}
          {obj.hrTemplate()}

          {AuditTrailFunctions.topTemplateJob(currentVersion, obj)}
          {AuditTrailFunctions.dispatchTemplateJob(currentVersion, obj)}
          {AuditTrailFunctions.locationsTemplateJob(currentVersion, obj)}
          {AuditTrailFunctions.binsTemplateJob(currentVersion, obj)}
          {AuditTrailFunctions.optionsTemplateJob(currentVersion, obj)}

        </Box>
    );
  },
  getManageDataJob: (currentVersion, previousVersion, callback) => {
    if(currentVersion){
      let customer = (currentVersion && currentVersion.customer) ? currentVersion.customer : null;
      let billingProfile = (currentVersion && currentVersion.billingProfile) ? currentVersion.billingProfile : null;
      
      currentVersion['customerNote'] = (customer && customer.note && customer.note != '') ? customer.note : '';
      currentVersion['billingProfileName'] = (billingProfile && billingProfile.billingProfileName && billingProfile.billingProfileName != '') ? billingProfile.billingProfileName : '';
      currentVersion['paymentTerms'] = (customer && customer.paymentTerms && customer.paymentTerms != '') ? customer.paymentTerms : '';
    }

    if(previousVersion){
      let customer = (previousVersion && previousVersion.customer) ? previousVersion.customer : null;
      let billingProfile = (previousVersion && previousVersion.billingProfile) ? previousVersion.billingProfile : null;
      
      previousVersion['customerNote'] = (customer && customer.note && customer.note != '') ? customer.note : '';
      previousVersion['billingProfileName'] = (billingProfile && billingProfile.billingProfileName && billingProfile.billingProfileName != '') ? billingProfile.billingProfileName : '';
      previousVersion['paymentTerms'] = (customer && customer.paymentTerms && customer.paymentTerms != '') ? customer.paymentTerms : '';
    }



    if(currentVersion){
      let step = null;

      let steps = (currentVersion && currentVersion.steps && currentVersion.steps.length > 0) ? currentVersion.steps : [];
      let stepsItems = steps.filter(x => x.customerSiteId > 0);
      if(stepsItems && stepsItems.length > 0){
        step = stepsItems[0];
      }

      currentVersion['contactPersonOne'] = (step && step.contactPersonOne && step.contactPersonOne != '') ? step.contactPersonOne : '';
      currentVersion['contactNumberOne'] = (step && step.contactNumberOne && step.contactNumberOne != '') ? step.contactNumberOne : '';
      currentVersion['contactPersonTwo'] = (step && step.contactPersonTwo && step.contactPersonTwo != '') ? step.contactPersonTwo : '';
      currentVersion['contactNumberTwo'] = (step && step.contactNumberTwo && step.contactNumberTwo != '') ? step.contactNumberTwo : '';
      currentVersion['siteRemarks'] = (step && step.siteRemarks && step.siteRemarks != '') ? step.siteRemarks : '';
    }

    if(previousVersion){
      let step = null;

      let steps = (previousVersion && previousVersion.steps && previousVersion.steps.length > 0) ? previousVersion.steps : [];
      let stepsItems = steps.filter(x => x.customerSiteId > 0);
      if(stepsItems && stepsItems.length > 0){
        step = stepsItems[0];
      }
      
      previousVersion['contactPersonOne'] = (step && step.contactPersonOne && step.contactPersonOne != '') ? step.contactPersonOne : '';
      previousVersion['contactNumberOne'] = (step && step.contactNumberOne && step.contactNumberOne != '') ? step.contactNumberOne : '';
      previousVersion['contactPersonTwo'] = (step && step.contactPersonTwo && step.contactPersonTwo != '') ? step.contactPersonTwo : '';
      previousVersion['contactNumberTwo'] = (step && step.contactNumberTwo && step.contactNumberTwo != '') ? step.contactNumberTwo : '';
      previousVersion['siteRemarks'] = (step && step.siteRemarks && step.siteRemarks != '') ? step.siteRemarks : '';
    }



    if(currentVersion){
      let location1 = null;
      let startLocation = '';
      let location2 = null;
      let endLocation = '';
      let address = null;
      let shiftAddress = null;

      let steps = (currentVersion && currentVersion.steps && currentVersion.steps.length > 0) ? currentVersion.steps : [];
      
      let stepsItems = steps.filter(x => x.customerSiteId > 0);
      if(stepsItems && stepsItems.length > 0){
        address = stepsItems[0];
      }
      if(stepsItems && stepsItems.length > 1){
        shiftAddress = stepsItems[1];
      }

      let locationItems = steps.filter(x => x.customerSiteId == null);
      if(locationItems && locationItems.length > 0){
        let startAddress = locationItems[0];
        
        if(startAddress.stepType == JobStepType.Exchange){
          location1 = 'Start Location';
          startLocation = (startAddress && startAddress.fullAddress && startAddress.fullAddress != '') ? startAddress.fullAddress : '';
        } else if(startAddress.stepType == JobStepType.OnTheSpot){
          location1 = 'Start Location';
          startLocation = (startAddress && startAddress.fullAddress && startAddress.fullAddress != '') ? startAddress.fullAddress : '';
        } else if(startAddress.stepType == JobStepType.Shift){
          location1 = '';
          startLocation = '';
        } else if((startAddress.stepType == JobStepType.Put) || (startAddress.stepType == JobStepType.Out)){
          location1 = 'Start Location';
          startLocation = (startAddress && startAddress.fullAddress && startAddress.fullAddress != '') ? startAddress.fullAddress : '';
        } else if(startAddress.stepType == JobStepType.Pull){
          location1 = 'End Location';
          startLocation = (startAddress && startAddress.fullAddress && startAddress.fullAddress != '') ? startAddress.fullAddress : '';
        }
      }

      if(locationItems && locationItems.length > 1){
        let endAddress = locationItems[1];
        
        if(endAddress.stepType == JobStepType.Exchange){
          location2 = 'End Location';
          endLocation = (endAddress && endAddress.fullAddress && endAddress.fullAddress != '') ? endAddress.fullAddress : '';
        } else if(endAddress.stepType == JobStepType.OnTheSpot){
          location2 = 'End Location';
          endLocation = (endAddress && endAddress.fullAddress && endAddress.fullAddress != '') ? endAddress.fullAddress : '';
        } else if(endAddress.stepType == JobStepType.Shift){
          location2 = '';
          endLocation = '';
        } else if((endAddress.stepType == JobStepType.Put) || (endAddress.stepType == JobStepType.Out)){
          location2 = '';
          endLocation = '';
        } else if(endAddress.stepType == JobStepType.Pull){
          location2 = '';
          endLocation = '';
        }
      }

      currentVersion['address'] = (address && address.fullAddress && address.fullAddress != '') ? address.fullAddress : '';
      currentVersion['shiftAddress'] = (shiftAddress && shiftAddress.fullAddress && shiftAddress.fullAddress != '') ? shiftAddress.fullAddress : '';
      currentVersion['location1'] = location1;
      currentVersion['startLocation'] = startLocation;
      currentVersion['location2'] = location2;
      currentVersion['endLocation'] = endLocation;
    }

    if(previousVersion){
      let location1 = null;
      let startLocation = '';
      let location2 = null;
      let endLocation = '';
      let address = null;
      let shiftAddress = null;

      let steps = (previousVersion && previousVersion.steps && previousVersion.steps.length > 0) ? previousVersion.steps : [];
      
      let stepsItems = steps.filter(x => x.customerSiteId > 0);
      if(stepsItems && stepsItems.length > 0){
        address = stepsItems[0];
      }
      if(stepsItems && stepsItems.length > 1){
        shiftAddress = stepsItems[1];
      }

      let locationItems = steps.filter(x => x.customerSiteId == null);
      if(locationItems && locationItems.length > 0){
        let startAddress = locationItems[0];
        
        if(startAddress.stepType == JobStepType.Exchange){
          location1 = 'Start Location';
          startLocation = (startAddress && startAddress.fullAddress && startAddress.fullAddress != '') ? startAddress.fullAddress : '';
        } else if(startAddress.stepType == JobStepType.OnTheSpot){
          location1 = 'Start Location';
          startLocation = (startAddress && startAddress.fullAddress && startAddress.fullAddress != '') ? startAddress.fullAddress : '';
        } else if(startAddress.stepType == JobStepType.Shift){
          location1 = '';
          startLocation = '';
        } else if((startAddress.stepType == JobStepType.Put) || (startAddress.stepType == JobStepType.Out)){
          location1 = 'Start Location';
          startLocation = (startAddress && startAddress.fullAddress && startAddress.fullAddress != '') ? startAddress.fullAddress : '';
        } else if(startAddress.stepType == JobStepType.Pull){
          location1 = 'End Location';
          startLocation = (startAddress && startAddress.fullAddress && startAddress.fullAddress != '') ? startAddress.fullAddress : '';
        }
      }
      if(locationItems && locationItems.length > 1){
        let endAddress = locationItems[1];
        
        if(endAddress.stepType == JobStepType.Exchange){
          location2 = 'End Location';
          endLocation = (endAddress && endAddress.fullAddress && endAddress.fullAddress != '') ? endAddress.fullAddress : '';
        } else if(endAddress.stepType == JobStepType.OnTheSpot){
          location2 = 'End Location';
          endLocation = (endAddress && endAddress.fullAddress && endAddress.fullAddress != '') ? endAddress.fullAddress : '';
        } else if(endAddress.stepType == JobStepType.Shift){
          location2 = '';
          endLocation = '';
        } else if((endAddress.stepType == JobStepType.Put) || (endAddress.stepType == JobStepType.Out)){
          location2 = '';
          endLocation = '';
        } else if(endAddress.stepType == JobStepType.Pull){
          location2 = '';
          endLocation = '';
        }
      }

      previousVersion['address'] = (address && address.fullAddress && address.fullAddress != '') ? address.fullAddress : '';
      previousVersion['shiftAddress'] = (shiftAddress && shiftAddress.fullAddress && shiftAddress.fullAddress != '') ? shiftAddress.fullAddress : '';
      previousVersion['location1'] = location1;
      previousVersion['startLocation'] = startLocation;
      previousVersion['location2'] = location2;
      previousVersion['endLocation'] = endLocation;
    }



    if(currentVersion){
      let bin1 = '';
      let binTypeName1 = '';
      let wasteTypeName1 = '';
      let binNumber1 = '';
      let binWeight1 = '';
      let bin2 = '';
      let binTypeName2 = '';
      let wasteTypeName2 = '';
      let binNumber2 = '';
      let binWeight2 = '';
      let binOutWeight = '';

      let steps = (currentVersion && currentVersion.steps && currentVersion.steps.length > 0) ? currentVersion.steps : [];
      if(steps && steps.length > 0){
        for(let i = 0; i < 2; i++){
          let step = steps[i];

          if(i == 0){
            if(step.stepType == JobStepType.Exchange){
              bin1 = 'Bin OUT';
            } else if(step.stepType == JobStepType.OnTheSpot){
              bin1 = 'Bin INFO';
            } else if(step.stepType == JobStepType.Shift){
              bin1 = 'Bin IN';
            } else if((step.stepType == JobStepType.Put) || (step.stepType == JobStepType.Out)){
              bin1 = 'Bin OUT';
            } else if(step.stepType == JobStepType.Pull){
              bin1 = 'Bin IN';
            }

            binTypeName1 = (step && step.binTypeName && step.binTypeName != '') ? step.binTypeName : '';
            wasteTypeName1 = (step && step.wasteTypes && step.wasteTypes.length > 0) ? step.wasteTypes.map(x => x.wasteTypeName).join(', ') : '';
            binNumber1 = (step && step.binNumber && step.binNumber != '') ? step.binNumber : '';
            binWeight1 = (step && step.binWeight && step.binWeight != '') ? step.binWeight : '';
            binOutWeight = (step && step.binOutWeight && step.binOutWeight != '') ? step.binOutWeight : '';

          } else if(i == 1){
            if(step.stepType == JobStepType.Exchange){
              bin2 = 'Bin IN';
              binTypeName2 = (step && step.binTypeName && step.binTypeName != '') ? step.binTypeName : '';
              wasteTypeName2 = (step && step.wasteTypes && step.wasteTypes.length > 0) ? step.wasteTypes.map(x => x.wasteTypeName).join(', ') : '';
              binNumber2 = (step && step.binNumber && step.binNumber != '') ? step.binNumber : '';
              binWeight2 = (step && step.binWeight && step.binWeight != '') ? step.binWeight : '';

            } else if(step.stepType == JobStepType.OnTheSpot){
              bin2 = '';
              binTypeName2 = '';
              wasteTypeName2 = '';
              binNumber2 = '';
              binWeight2 = '';

            } else if(step.stepType == JobStepType.Shift){
              bin2 = '';
              binTypeName2 = '';
              wasteTypeName2 = '';
              binNumber2 = '';
              binWeight2 = '';

            } else if((step.stepType == JobStepType.Put) || (step.stepType == JobStepType.Out)){
              bin2 = '';
              binTypeName2 = '';
              wasteTypeName2 = '';
              binNumber2 = '';
              binWeight2 = '';

            } else if(step.stepType == JobStepType.Pull){
              bin2 = '';
              binTypeName2 = '';
              wasteTypeName2 = '';
              binNumber2 = '';
              binWeight2 = '';
            }
          }
        }
      }

      currentVersion['bin1'] = bin1;
      currentVersion['binTypeName1'] = binTypeName1;
      currentVersion['wasteTypeName1'] = wasteTypeName1;
      currentVersion['binNumber1'] = binNumber1;
      currentVersion['binWeight1'] = binWeight1;
      currentVersion['bin2'] = bin2;
      currentVersion['binTypeName2'] = binTypeName2;
      currentVersion['wasteTypeName2'] = wasteTypeName2;
      currentVersion['binNumber2'] = binNumber2;
      currentVersion['binWeight2'] = binWeight2;
      currentVersion['binOutWeight'] = binOutWeight;
    }

    if(previousVersion){
      let bin1 = '';
      let binTypeName1 = '';
      let wasteTypeName1 = '';
      let binNumber1 = '';
      let binWeight1 = '';
      let bin2 = '';
      let binTypeName2 = '';
      let wasteTypeName2 = '';
      let binNumber2 = '';
      let binWeight2 = '';
      let binOutWeight = '';

      let steps = (previousVersion && previousVersion.steps && previousVersion.steps.length > 0) ? previousVersion.steps : [];
      if(steps && steps.length > 0){
        for(let i = 0; i < 2; i++){
          let step = steps[i];
          
          if(i == 0){
            if(step.stepType == JobStepType.Exchange){
              bin1 = 'Bin OUT';
            } else if(step.stepType == JobStepType.OnTheSpot){
              bin1 = 'Bin INFO';
            } else if(step.stepType == JobStepType.Shift){
              bin1 = 'Bin IN';
            } else if((step.stepType == JobStepType.Put) || (step.stepType == JobStepType.Out)){
              bin1 = 'Bin OUT';
            } else if(step.stepType == JobStepType.Pull){
              bin1 = 'Bin IN';
            }

            binTypeName1 = (step && step.binTypeName && step.binTypeName != '') ? step.binTypeName : '';
            wasteTypeName1 = (step && step.wasteTypes && step.wasteTypes.length > 0) ? step.wasteTypes.map(x => x.wasteTypeName).join(', ') : '';
            binNumber1 = (step && step.binNumber && step.binNumber != '') ? step.binNumber : '';
            binWeight1 = (step && step.binWeight && step.binWeight != '') ? step.binWeight : '';
            binOutWeight = (step && step.binOutWeight && step.binOutWeight != '') ? step.binOutWeight : '';

          } else if(i == 1){
            if(step.stepType == JobStepType.Exchange){
              bin2 = 'Bin IN';
              binTypeName2 = (step && step.binTypeName && step.binTypeName != '') ? step.binTypeName : '';
              wasteTypeName2 = (step && step.wasteTypes && step.wasteTypes.length > 0) ? step.wasteTypes.map(x => x.wasteTypeName).join(', ') : '';
              binNumber2 = (step && step.binNumber && step.binNumber != '') ? step.binNumber : '';
              binWeight2 = (step && step.binWeight && step.binWeight != '') ? step.binWeight : '';

            } else if(step.stepType == JobStepType.OnTheSpot){
              bin2 = '';
              binTypeName2 = '';
              wasteTypeName2 = '';
              binNumber2 = '';
              binWeight2 = '';

            } else if(step.stepType == JobStepType.Shift){
              bin2 = '';
              binTypeName2 = '';
              wasteTypeName2 = '';
              binNumber2 = '';
              binWeight2 = '';

            } else if((step.stepType == JobStepType.Put)){
              bin2 = '';
              binTypeName2 = '';
              wasteTypeName2 = '';
              binNumber2 = '';
              binWeight2 = '';

            } else if(step.stepType == JobStepType.Pull){
              bin2 = '';
              binTypeName2 = '';
              wasteTypeName2 = '';
              binNumber2 = '';
              binWeight2 = '';
            }

            binTypeName2 = (step && step.binTypeName && step.binTypeName != '') ? step.binTypeName : '';
            wasteTypeName2 = (step && step.wasteTypes && step.wasteTypes.length > 0) ? step.wasteTypes.map(x => x.wasteTypeName).join(', ') : '';
            binNumber2 = (step && step.binNumber && step.binNumber != '') ? step.binNumber : '';
            binWeight2 = (step && step.binWeight && step.binWeight != '') ? step.binWeight : '';
          }
        }
      }
      

      previousVersion['bin1'] = bin1;
      previousVersion['binTypeName1'] = binTypeName1;
      previousVersion['wasteTypeName1'] = wasteTypeName1;
      previousVersion['binNumber1'] = binNumber1;
      previousVersion['binWeight1'] = binWeight1;
      previousVersion['bin2'] = bin2;
      previousVersion['binTypeName2'] = binTypeName2;
      previousVersion['wasteTypeName2'] = wasteTypeName2;
      previousVersion['binNumber2'] = binNumber2;
      previousVersion['binWeight2'] = binWeight2;
      previousVersion['binOutWeight'] = binOutWeight;
    }



    if(currentVersion){
      let customerSiteAddress = '';
      let steps = (currentVersion && currentVersion.steps && currentVersion.steps.length > 0) ? currentVersion.steps : [];
      if(steps && steps.length > 0){
          steps.forEach((item) => {
              if(item.customerSiteId && item.customerSiteId > 0){
                  customerSiteAddress = item.address;
              }
          });
      }
      currentVersion['customerSiteAddress'] = customerSiteAddress;


      currentVersion.steps.forEach((item, i) => {
          let mustTakePhotoText = 'NO';
          if(item.mustTakePhoto === CustomPhotoSignature.Yes){
              mustTakePhotoText = 'YES';
          } else if(item.mustTakePhoto === CustomPhotoSignature.Optional){
              mustTakePhotoText = 'OPTIONAL';
          }
          item['mustTakePhotoText'] = mustTakePhotoText;

          let mustTakeSignatureText = 'NO';
          if(item.mustTakeSignature === CustomPhotoSignature.Yes){
              mustTakeSignatureText = 'YES';
          } else if(item.mustTakeSignature === CustomPhotoSignature.Optional){
              mustTakeSignatureText = 'OPTIONAL';
          }
          item['mustTakeSignatureText'] = mustTakeSignatureText;
          item['numberofPhotosRequiredText'] = (item.numberofPhotosRequired) ? item.numberofPhotosRequired.toString() : '';
      });
          
      let jobDurationHours = (currentVersion.jobDurationHours && currentVersion.jobDurationHours.toString() !== '') ? currentVersion.jobDurationHours.toString() : '0';
      let jobDurationMinutes = (currentVersion.jobDurationMinutes && currentVersion.jobDurationMinutes.toString() !== '') ? currentVersion.jobDurationMinutes.toString() : '0';
      let duration = apiUtil.getDuration(jobDurationHours, jobDurationMinutes);

      currentVersion['duration'] = duration;

      
      let jobTypeInt = 0;
      try {
          if(currentVersion && currentVersion.steps && currentVersion.steps.length > 0){
              jobTypeInt = parseInt(currentVersion.steps[0].stepType);
          }
      } catch(e){}

      let binInItem = null;
      let stepsStruct = apiUtil.getStepStruct(jobTypeInt);
      if(stepsStruct && stepsStruct.length > 0){
          let index = stepsStruct.findIndex(x => x.stepTypeSave === StepType.BIN_IN);
          if(index !== -1){
              let steps = (currentVersion && currentVersion.steps && currentVersion.steps.length > 0) ? currentVersion.steps : [];
              if(steps && steps.length > 0){
                  let binType = (steps[index] && steps[index].binType) ? steps[index].binType : null;
                  let binNumber = (steps[index] && steps[index].binNumber) ? steps[index].binNumber : null;
                  let wasteType = (steps[index] && steps[index].wasteType) ? steps[index].wasteType : null;
                  let wasteTypes = (steps[index] && steps[index].wasteTypes && steps[index].wasteTypes.length > 0) ? steps[index].wasteTypes : [];
                  
                  binInItem = {
                      binType: binType,
                      binNumber: binNumber,
                      wasteType: wasteType,
                      wasteTypes: ((wasteTypes && wasteTypes.length > 0) ? wasteTypes.map(x => x.wasteType.wasteTypeName).join(', ') : ''),
                  }
              }
          }
      }
      if(jobTypeInt === JobStepType.OnTheSpot) {
          binInItem = null;
      }
      currentVersion['binInItem'] = binInItem;
      currentVersion['binInBinTypeItem'] = (binInItem && binInItem.binType) ? binInItem.binType.binTypeName : null;
      currentVersion['binInBinNumberItem'] = (binInItem && binInItem.binNumber) ? binInItem.binNumber : null;
      currentVersion['binInWasteTypeItem'] = (binInItem && binInItem.wasteType) ? binInItem.wasteType.wasteTypeName : null;
      currentVersion['binInWasteTypesItem'] = (binInItem && binInItem.wasteTypes) ? binInItem.wasteTypes : null;



      let binOutItem = null;
      if(jobTypeInt === JobStepType.Shift){
          if(stepsStruct && stepsStruct.length > 0){
              let index = stepsStruct.findIndex(x => x.binActivity === JobStepBinActivity.LoadBin);
              if(index !== -1){
                  let steps = (currentVersion && currentVersion.steps && currentVersion.steps.length > 0) ? currentVersion.steps : [];
                  if(steps && steps.length > 0){
                      let binType = (steps[index] && steps[index].binType) ? steps[index].binType : null;
                      let binNumber = (steps[index] && steps[index].binNumber) ? steps[index].binNumber : null;
                      let wasteType = (steps[index] && steps[index].wasteType) ? steps[index].wasteType : null;
                      let wasteTypes = (steps[index] && steps[index].wasteTypes && steps[index].wasteTypes.length > 0) ? steps[index].wasteTypes : [];
                      
                      binOutItem = {
                          binType: binType,
                          binNumber: binNumber,
                          wasteType: wasteType,
                          wasteTypes: ((wasteTypes && wasteTypes.length > 0) ? wasteTypes.map(x => x.wasteType.wasteTypeName).join(', ') : ''),
                      }
                  }
              }
          }
      } else {
          if(stepsStruct && stepsStruct.length > 0){
              let index = stepsStruct.findIndex(x => x.stepTypeSave === StepType.BIN_OUT);
              if(index !== -1){
                  let steps = (currentVersion && currentVersion.steps && currentVersion.steps.length > 0) ? currentVersion.steps : [];
                  if(steps && steps.length > 0){
                      let binType = (steps[index] && steps[index].binType) ? steps[index].binType : null;
                      let binNumber = (steps[index] && steps[index].binNumber) ? steps[index].binNumber : null;
                      let wasteType = (steps[index] && steps[index].wasteType) ? steps[index].wasteType : null;
                      let wasteTypes = (steps[index] && steps[index].wasteTypes && steps[index].wasteTypes.length > 0) ? steps[index].wasteTypes : [];
                      
                      binOutItem = {
                          binType: binType,
                          binNumber: binNumber,
                          wasteType: wasteType,
                          wasteTypes: ((wasteTypes && wasteTypes.length > 0) ? wasteTypes.map(x => x.wasteType.wasteTypeName).join(', ') : ''),
                      }
                  }
              }
          }
      }
      currentVersion['binOutItem'] = binOutItem;
      currentVersion['binOutBinTypeItem'] = (binOutItem && binOutItem.binType) ? binOutItem.binType.binTypeName : null;
      currentVersion['binOutBinNumberItem'] = (binOutItem && binOutItem.binNumber) ? binOutItem.binNumber : null;
      currentVersion['binOutWasteTypeItem'] = (binOutItem && binOutItem.wasteType) ? binOutItem.wasteType.wasteTypeName : null;
      currentVersion['binOutWasteTypesItem'] = (binOutItem && binOutItem.wasteTypes) ? binOutItem.wasteTypes : null;
    }
    
    if(previousVersion){
      let customerSiteAddress = '';
      let steps = (previousVersion && previousVersion.steps && previousVersion.steps.length > 0) ? previousVersion.steps : [];
      if(steps && steps.length > 0){
          steps.forEach((item) => {
              if(item.customerSiteId && item.customerSiteId > 0){
                  customerSiteAddress = item.address;
              }
          });
      }
      previousVersion['customerSiteAddress'] = customerSiteAddress;


      previousVersion.steps.forEach((item, i) => {
          let mustTakePhotoText = 'NO';
          if(item.mustTakePhoto === CustomPhotoSignature.Yes){
              mustTakePhotoText = 'YES';
          } else if(item.mustTakePhoto === CustomPhotoSignature.Optional){
              mustTakePhotoText = 'OPTIONAL';
          }
          item['mustTakePhotoText'] = mustTakePhotoText;

          let mustTakeSignatureText = 'NO';
          if(item.mustTakeSignature === CustomPhotoSignature.Yes){
              mustTakeSignatureText = 'YES';
          } else if(item.mustTakeSignature === CustomPhotoSignature.Optional){
              mustTakeSignatureText = 'OPTIONAL';
          }
          item['mustTakeSignatureText'] = mustTakeSignatureText;
          item['numberofPhotosRequiredText'] = (item.numberofPhotosRequired) ? item.numberofPhotosRequired.toString() : '';
      });
          
      let jobDurationHours = (previousVersion.jobDurationHours && previousVersion.jobDurationHours.toString() !== '') ? previousVersion.jobDurationHours.toString() : '0';
      let jobDurationMinutes = (previousVersion.jobDurationMinutes && previousVersion.jobDurationMinutes.toString() !== '') ? previousVersion.jobDurationMinutes.toString() : '0';
      let duration = apiUtil.getDuration(jobDurationHours, jobDurationMinutes);

      previousVersion['duration'] = duration;


      let jobTypeInt = 0;
      try {
          if(previousVersion && previousVersion.steps && previousVersion.steps.length > 0){
              jobTypeInt = parseInt(previousVersion.steps[0].stepType);
          }
      } catch(e){}

      let binInItem = null;
      let stepsStruct = apiUtil.getStepStruct(jobTypeInt);
      if(stepsStruct && stepsStruct.length > 0){
          let index = stepsStruct.findIndex(x => x.stepTypeSave === StepType.BIN_IN);
          if(index !== -1){
              let steps = (previousVersion && previousVersion.steps && previousVersion.steps.length > 0) ? previousVersion.steps : [];
              if(steps && steps.length > 0){
                  let binType = (steps[index] && steps[index].binType) ? steps[index].binType : null;
                  let binNumber = (steps[index] && steps[index].binNumber) ? steps[index].binNumber : null;
                  let wasteType = (steps[index] && steps[index].wasteType) ? steps[index].wasteType : null;
                  let wasteTypes = (steps[index] && steps[index].wasteTypes && steps[index].wasteTypes.length > 0) ? steps[index].wasteTypes : [];
                  
                  binInItem = {
                      binType: binType,
                      binNumber: binNumber,
                      wasteType: wasteType,
                      wasteTypes: ((wasteTypes && wasteTypes.length > 0) ? wasteTypes.map(x => x.wasteType.wasteTypeName).join(', ') : ''),
                  }
              }
          }
      }
      if(jobTypeInt === JobStepType.OnTheSpot) {
          binInItem = null;
      }
      previousVersion['binInItem'] = binInItem;
      previousVersion['binInBinTypeItem'] = (binInItem && binInItem.binType) ? binInItem.binType.binTypeName : null;
      previousVersion['binInBinNumberItem'] = (binInItem && binInItem.binNumber) ? binInItem.binNumber : null;
      previousVersion['binInWasteTypeItem'] = (binInItem && binInItem.wasteType) ? binInItem.wasteType.wasteTypeName : null;
      previousVersion['binInWasteTypesItem'] = (binInItem && binInItem.wasteTypes) ? binInItem.wasteTypes : null;



      let binOutItem = null;
      if(jobTypeInt === JobStepType.Shift){
          if(stepsStruct && stepsStruct.length > 0){
              let index = stepsStruct.findIndex(x => x.binActivity === JobStepBinActivity.LoadBin);
              if(index !== -1){
                  let steps = (previousVersion && previousVersion.steps && previousVersion.steps.length > 0) ? previousVersion.steps : [];
                  if(steps && steps.length > 0){
                      let binType = (steps[index] && steps[index].binType) ? steps[index].binType : null;
                      let binNumber = (steps[index] && steps[index].binNumber) ? steps[index].binNumber : null;
                      let wasteType = (steps[index] && steps[index].wasteType) ? steps[index].wasteType : null;
                      let wasteTypes = (steps[index] && steps[index].wasteTypes && steps[index].wasteTypes.length > 0) ? steps[index].wasteTypes : [];
                      
                      binOutItem = {
                          binType: binType,
                          binNumber: binNumber,
                          wasteType: wasteType,
                          wasteTypes: ((wasteTypes && wasteTypes.length > 0) ? wasteTypes.map(x => x.wasteType.wasteTypeName).join(', ') : ''),
                      }
                  }
              }
          }
      } else {
          if(stepsStruct && stepsStruct.length > 0){
              let index = stepsStruct.findIndex(x => x.stepTypeSave === StepType.BIN_OUT);
              if(index !== -1){
                  let steps = (previousVersion && previousVersion.steps && previousVersion.steps.length > 0) ? previousVersion.steps : [];
                  if(steps && steps.length > 0){
                      let binType = (steps[index] && steps[index].binType) ? steps[index].binType : null;
                      let binNumber = (steps[index] && steps[index].binNumber) ? steps[index].binNumber : null;
                      let wasteType = (steps[index] && steps[index].wasteType) ? steps[index].wasteType : null;
                      let wasteTypes = (steps[index] && steps[index].wasteTypes && steps[index].wasteTypes.length > 0) ? steps[index].wasteTypes : [];
                      
                      binOutItem = {
                          binType: binType,
                          binNumber: binNumber,
                          wasteType: wasteType,
                          wasteTypes: ((wasteTypes && wasteTypes.length > 0) ? wasteTypes.map(x => x.wasteType.wasteTypeName).join(', ') : ''),
                      }
                  }
              }
          }
      }
      previousVersion['binOutItem'] = binOutItem;
      previousVersion['binOutBinTypeItem'] = (binOutItem && binOutItem.binType) ? binOutItem.binType.binTypeName : '';
      previousVersion['binOutBinNumberItem'] = (binOutItem && binOutItem.binNumber) ? binOutItem.binNumber : '';
      previousVersion['binOutWasteTypeItem'] = (binOutItem && binOutItem.wasteType) ? binOutItem.wasteType.wasteTypeName : '';
      previousVersion['binOutWasteTypesItem'] = (binOutItem && binOutItem.wasteTypes) ? binOutItem.wasteTypes : '';
    }

  },
  topTemplateJob: (data = null, obj = null) => {
    let html = [];

    html.push(obj.rowTemplate("Customer Name", obj.highlight("customerName")));
    html.push(obj.rowTemplate("Remarks", obj.highlight("remarks")));
    html.push(obj.rowTemplate("Customer note", obj.highlight("customerNote")));
    html.push(obj.rowTemplate("Billing by", obj.highlight("billingProfileName")));
    html.push(obj.rowTemplate("Payment terms", obj.highlight("paymentTerms")));
    html.push(obj.rowTemplate("Job Type", obj.highlight("jobTypeName")));

    return (html && html.length > 0) ? html : null;
  },
  dispatchTemplateJob: (data = null, obj = null) => {
    let html = [];

    html.push(obj.emptyRowTemplate());
    html.push(obj.titleTemplate("DISPATCH"));
    html.push(obj.hrTemplate());
    
    html.push(obj.rowTemplate("Job date", obj.highlight("jobDate", (x) => x ? moment(x).format(apiUtil.getDefaultDateFormat()) : '-')));
    html.push(obj.rowTemplate("Job time", obj.highlight("jobTimeSpecific", (x) => x ? moment(x).format(apiUtil.getDefaultTimeFormat()) : '-')));
    html.push(obj.rowTemplate("Driver", obj.highlight("driverName")));
    html.push(obj.rowTemplate("Vehicle", obj.highlight("vehicleName")));
    html.push(obj.rowTemplate("Contact person 1", obj.highlight("contactPersonOne")));
    html.push(obj.rowTemplate("Phone number 1", obj.highlight("contactNumberOne")));
    html.push(obj.rowTemplate("Contact person 2", obj.highlight("contactPersonTwo")));
    html.push(obj.rowTemplate("Phone number 2", obj.highlight("contactNumberTwo")));
    html.push(obj.rowTemplate("Site instruction", obj.highlight("siteRemarks")));
    html.push(obj.rowTemplate("Status", obj.highlight("statusName")));

    return (html && html.length > 0) ? html : null;
  },
  locationsTemplateJob: (data = null, obj = null) => {
    let html = [];

    html.push(obj.emptyRowTemplate());
    html.push(obj.titleTemplate("LOCATIONS"));
    html.push(obj.hrTemplate());

    html.push(obj.rowTemplate("Customer site address", obj.highlight("address")));
    if(data.shiftAddress != '') html.push(obj.rowTemplate("Customer site shift address", obj.highlight("shiftAddress")));
    if(data.startLocation != '') html.push(obj.rowTemplate(data.location1, obj.highlight("startLocation")));
    if(data.endLocation != '') html.push(obj.rowTemplate(data.location2, obj.highlight("endLocation")));

    return (html && html.length > 0) ? html : null;
  },
  binsTemplateJob: (data = null, obj = null) => {
    let html = [];
    
    let jobTypeInt = 0;
    try {
      if(data && data.steps && data.steps.length > 0){
      jobTypeInt = parseInt(data.steps[0].stepType);
      }
    } catch(e){}

    let stepsStruct = apiUtil.getStepStruct(jobTypeInt);

    html.push(obj.emptyRowTemplate());
    html.push(obj.titleTemplate(data.bin1));
    html.push(obj.hrTemplate());

    if(data.binTypeName1 != '') html.push(obj.rowTemplate("Bin type", obj.highlight("binTypeName1")));
    if(data.wasteTypeName1 != '') html.push(obj.rowTemplate("Waste type", obj.highlight("wasteTypeName1")));
    if(data.binNumber1 != '') html.push(obj.rowTemplate("Bin ID", obj.highlight("binNumber1")));

    if(jobTypeInt == JobStepType.Out){
      if(data.binOutWeight != '') html.push(obj.rowTemplate("Bin weight", obj.highlight("binOutWeight")));
      if(data.binWeight1 != '') html.push(obj.rowTemplate("Bin weight disposed", obj.highlight("binWeight1")));
    } else {
      if(data.binWeight1 != '') html.push(obj.rowTemplate("Bin weight", obj.highlight("binWeight1")));
    }
    
    html.push(obj.emptyRowTemplate());
    html.push(obj.titleTemplate(data.bin2));
    html.push(obj.hrTemplate());

    if(data.binTypeName2 != '') html.push(obj.rowTemplate("Bin type", obj.highlight("binTypeName2")));
    if(data.wasteTypeName2 != '') html.push(obj.rowTemplate("Waste type", obj.highlight("wasteTypeName2")));
    if(data.binNumber2 != '') html.push(obj.rowTemplate("Bin ID", obj.highlight("binNumber2")));

    if(jobTypeInt == JobStepType.Out){
      if(data.binOutWeight != '') html.push(obj.rowTemplate("Bin weight", obj.highlight("binOutWeight")));
      if(data.binWeight2 != '') html.push(obj.rowTemplate("Bin weight disposed", obj.highlight("binWeight2")));
    } else {
      if(data.binWeight2 != '') html.push(obj.rowTemplate("Bin weight", obj.highlight("binWeight2")));
    }
    

    return (html && html.length > 0) ? html : null;
  },
  optionsTemplateJob: (data = null, obj = null) => {
    let html = [];

    let jobTypeInt = 0;
    try {
      if(data && data.steps && data.steps.length > 0){
      jobTypeInt = parseInt(data.steps[0].stepType);
      }
    } catch(e){}

    let stepsStruct = apiUtil.getStepStruct(jobTypeInt);

    if(stepsStruct && stepsStruct.length > 0){
      html.push(obj.emptyRowTemplate());
      html.push(obj.titleTemplate("OPTIONS"));
      html.push(obj.hrTemplate());

      stepsStruct.forEach((struct, i) => {
        let index = (i + 1);

        if((index === 1) && ((struct.jobType === JobStepType.Pull) || (struct.jobType === JobStepType.Shift))){
          html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={1}>{obj.emptyRowTemplate()}</Box>);
          html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={1}>{obj.titleTemplate(index + ". Start")}</Box>);

          html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={1}>{obj.emptyRowTemplate()}</Box>);
          html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={1}>{obj.titleTemplate((index+1) + ". In Progress")}</Box>);
        } else if((index === 2) && ((struct.jobType === JobStepType.Pull) || (struct.jobType === JobStepType.Shift))){
          html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={1}>{obj.emptyRowTemplate()}</Box>);
          html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={1}>{obj.titleTemplate((index+1) + ". In Progress")}</Box>);
        } else {
          let stepName = '';
          if(index === 1){
            stepName = 'Start';
          } else if(index === 2){
            if(struct.jobType === JobStepType.Out){
              stepName = 'Complete';
            } else {
              stepName = 'In Progress';
            }
          } else if(index === 3){
            stepName = 'Complete';
          }

          if((index === 2) && (struct.jobType === JobStepType.Put) && (struct.jobType === JobStepType.Out)){
            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={1}>{obj.emptyRowTemplate()}</Box>);
            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={1}>{obj.titleTemplate((index+1) + ". " + stepName)}</Box>);
          } else {
            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={1}>{obj.emptyRowTemplate()}</Box>);
            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={1}>{obj.titleTemplate(index + ". " + stepName)}</Box>);
          }
        }


        if(struct.jobType === JobStepType.Pull){
          if(index === 1){
            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to input bin ID", obj.highlight('steps.' + i + '.isRequireBinNumberToEnd'))}</Box>);

            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Required number of photos", obj.highlight('steps.' + i + '.numberofPhotosRequiredText'))}</Box>);
            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Must take photo", obj.highlight('steps.' + i + '.mustTakePhotoText'))}</Box>);
            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Must take signature", obj.highlight('steps.' + i + '.mustTakeSignatureText'))}</Box>);
            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to collect payment", obj.highlight('steps.' + i + '.isRequirePaymentCollection'))}</Box>);
          }
    
          if(index === 2){
            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Required number of photos", obj.highlight('steps.' + i + '.numberofPhotosRequiredText'))}</Box>);
            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Must take photo", obj.highlight('steps.' + i + '.mustTakePhotoText'))}</Box>);
            
            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("isRequireBinWeight", obj.highlight('steps.' + i + '.isRequireBinWeight'))}</Box>);
            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("isRequireReviewWasteType", obj.highlight('steps.' + i + '.isRequireReviewWasteType'))}</Box>);
          }

        } else if(struct.jobType === JobStepType.Put){
          if(index === 1){
            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to input bin ID", obj.highlight('steps.' + i + '.isRequireBinNumberToEnd'))}</Box>);
          }
    
          if(index === 2){
            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Required number of photos", obj.highlight('steps.' + i + '.numberofPhotosRequiredText'))}</Box>);
            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Must take photo", obj.highlight('steps.' + i + '.mustTakePhotoText'))}</Box>);

            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Must take signature", obj.highlight('steps.' + i + '.mustTakeSignatureText'))}</Box>);
            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to collect payment", obj.highlight('steps.' + i + '.isRequirePaymentCollection'))}</Box>);
          }

        } else if(struct.jobType === JobStepType.Out){
          if(index === 1){
            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to input bin ID", obj.highlight('steps.' + i + '.isRequireBinNumberToEnd'))}</Box>);
            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to input bin weight", obj.highlight('steps.' + i + '.isRequireBinWeight'))}</Box>);
            
            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Required number of photos", obj.highlight('steps.' + i + '.numberofPhotosRequiredText'))}</Box>);
            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Must take photo", obj.highlight('steps.' + i + '.mustTakePhotoText'))}</Box>);
          }
    
          if(index === 2){
            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Required number of photos", obj.highlight('steps.' + i + '.numberofPhotosRequiredText'))}</Box>);
            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Must take photo", obj.highlight('steps.' + i + '.mustTakePhotoText'))}</Box>);

            // html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Must take signature", obj.highlight('steps.' + i + '.mustTakeSignatureText'))}</Box>);
            // html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to collect payment", obj.highlight('steps.' + i + '.isRequirePaymentCollection'))}</Box>);
          }

        } else if(struct.jobType === JobStepType.Shift){
          if(index === 1){
            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to input bin ID", obj.highlight('steps.' + i + '.isRequireBinNumberToEnd'))}</Box>);
            
            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Required number of photos", obj.highlight('steps.' + i + '.numberofPhotosRequiredText'))}</Box>);
            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Must take photo", obj.highlight('steps.' + i + '.mustTakePhotoText'))}</Box>);
            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Must take signature", obj.highlight('steps.' + i + '.mustTakeSignatureText'))}</Box>);
            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to collect payment", obj.highlight('steps.' + i + '.isRequirePaymentCollection'))}</Box>);
          }

          if(index === 2){
            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Required number of photos", obj.highlight('steps.' + i + '.numberofPhotosRequiredText'))}</Box>);
            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Must take photo", obj.highlight('steps.' + i + '.mustTakePhotoText'))}</Box>);
          }

        } else {
          if(jobTypeInt !== JobStepType.Pull){
            if(index === 1){
              html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to input bin ID", obj.highlight('steps.' + i + '.isRequireBinNumberToEnd'))}</Box>);
            }
          }
      
          if((jobTypeInt !== JobStepType.Put) && (jobTypeInt !== JobStepType.Out)){
            if((jobTypeInt === JobStepType.Pull) || (jobTypeInt === JobStepType.Exchange)){
              if(index === 2){
                html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to input bin ID", obj.highlight('steps.' + i + '.isRequireBinNumberToEnd'))}</Box>);
              }
            }
        
            if(index === 2){
              html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Required number of photos", obj.highlight('steps.' + i + '.numberofPhotosRequiredText'))}</Box>);
              html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Must take photo", obj.highlight('steps.' + i + '.mustTakePhotoText'))}</Box>);
              html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Must take signature", obj.highlight('steps.' + i + '.mustTakeSignatureText'))}</Box>);
              html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to collect payment", obj.highlight('steps.' + i + '.isRequirePaymentCollection'))}</Box>);
            }
          }
      
          if(index === 3){
            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Required number of photos", obj.highlight('steps.' + i + '.numberofPhotosRequiredText'))}</Box>);
            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("Must take photo", obj.highlight('steps.' + i + '.mustTakePhotoText'))}</Box>);
            
            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to input bin weight", obj.highlight('steps.' + i + '.isRequireBinWeight'))}</Box>);
            html.push(<Box key={'job_steps_' + (html.length+1) + '_' + i} pl={2}>{obj.rowTemplateHalfAndHalf("The driver is required to review waste type", obj.highlight('steps.' + i + '.isRequireReviewWasteType'))}</Box>);
          }
        }

      });
    }


    html.push(obj.emptyRowTemplate());
    html.push(obj.titleTemplate("APP PERMISSION"));
    html.push(obj.hrTemplate());
    
    if(jobTypeInt !== JobStepType.Pull){
      html.push(obj.emptyRowTemplate());
      html.push(<Box key={'app_permission_' + (html.length+1)} pl={1}>{obj.titleTemplate("Bin out")}</Box>);

      html.push(<Box key={'app_permission_' + (html.length+1)} pl={2}>{obj.rowTemplateHalfAndHalf("The driver can change bin type on driver app", obj.highlight('isEditableBinTypeOut'))}</Box>);
      html.push(<Box key={'app_permission_' + (html.length+1)} pl={2}>{obj.rowTemplateHalfAndHalf("The driver can change waste type on driver app", obj.highlight('isEditableWasteTypeOut'))}</Box>);
    }

    if((jobTypeInt !== JobStepType.Put) && (jobTypeInt !== JobStepType.Out)){
      html.push(obj.emptyRowTemplate());
      html.push(<Box key={'app_permission_' + (html.length+1)} pl={1}>{obj.titleTemplate("Bin in")}</Box>);

      html.push(<Box key={'app_permission_' + (html.length+1)} pl={2}>{obj.rowTemplateHalfAndHalf("The driver can change waste type on driver app", obj.highlight('isEditableWasteTypeIn'))}</Box>);
    }

    
    html.push(obj.emptyRowTemplate());
    html.push(obj.titleTemplate("OTHERS"));
    html.push(obj.hrTemplate());

    html.push(<Box key={'others_' + (html.length+1)} pl={1}>{obj.rowTemplateHalfAndHalf("Default job duration for this job type", obj.highlight('duration'))}</Box>);

    return (html && html.length > 0) ? html : null;
  },

  getDetailsDriverDetailsMonthly: (obj, index, currentVersion, previousVersion) => {
    return <Box
      style={{ wordBreak: 'breakAll' }}
    >
      {/* {obj.rowTemplate("Payroll Template", obj.highlight('payrollTemplateName'))} */}
      {obj.rowTemplate("Remarks", obj.highlight('remarks'))}
      {/* {obj.rowTemplate("Is Locked", obj.highlight("isLocked"))} */}
      {obj.rowTemplate("Job Count", obj.highlight("jobCount"))}
      {obj.rowTemplate("Total All Pay", obj.highlight('totalAllPay', (val) => {
        return Utils.getCurrency() + Utils.formatNumber(val);
      }))}
      {obj.rowTemplate("Total Monthly Pay", obj.highlight('totalPay', (val) => {
        return Utils.getCurrency() + Utils.formatNumber(val);
      }))}
      {obj.rowTemplate("Total Ad-Hoc Pay", obj.highlight('totalAdhocPay', (val) => {
        return Utils.getCurrency() + Utils.formatNumber(val);
      }))}
      
      <Box clone mt={4} mb={2}>{obj.hrTemplate()}</Box>
      
      {obj.tableTemplate(
        ["Description", "Other Pay"],
        (currentVersion.adhocDetails && currentVersion.adhocDetails.length > 0) && currentVersion.adhocDetails.map((item, i) => {
          return [
            obj.highlight('adhocDetails.' + i + '.description'),
            obj.highlight('adhocDetails.' + i + '.otherPay', (v) => Utils.getCurrency() + Utils.formatNumber(v)),
          ]
        })
      )}
      
      <Box clone mt={4} mb={2}>{obj.hrTemplate()}</Box>

      <Box pb={3}><h3 className={'text-color-secondary'}>Monthly List</h3></Box>

      {(currentVersion.days && currentVersion.days.length > 0) && currentVersion.days.map((data, i) => {
        return <Box key={'days_' + i} pb={1}>
          <Grid container>
            <Box clone>
              <Grid item xs={12}><Box pb={1}><b className={'text-color-secondary'}>{data.dateDisplay} ({data.dayDisplay}){(data.isSpecial ? ' - Special' : '')}</b></Box></Grid>
            </Box>
            <Box clone>
              <Grid item xs={12}>
                {obj.tableTemplate(
                  ["Work date", "Work time", "Job type", "Customer", "Site", "DO Number", "Geofence name", "Geofence Group", "Trip pay", "Incentive pay", "Remarks"],
                  (data && data.details && data.details.length > 0) && data.details.map((subItem, j) => {
                    return [
                      obj.highlight('days.' + i + '.details.' + j + '.workDate'),
                      obj.highlight('days.' + i + '.details.' + j + '.workTime'),
                      obj.highlight('days.' + i + '.details.' + j + '.jobType'),
                      obj.highlight('days.' + i + '.details.' + j + '.customerName'),
                      obj.highlight('days.' + i + '.details.' + j + '.site'),
                      obj.highlight('days.' + i + '.details.' + j + '.jobNumber'),
                      obj.highlight('days.' + i + '.details.' + j + '.geofenceName'),
                      obj.highlight('days.' + i + '.details.' + j + '.geofenceGroup'),
                      obj.highlight('days.' + i + '.details.' + j + '.tripPay', (v) => Utils.getCurrency() + Utils.formatNumber(v)),
                      obj.highlight('days.' + i + '.details.' + j + '.incentivePay', (v) => Utils.getCurrency() + Utils.formatNumber(v)),
                      obj.highlight('days.' + i + '.details.' + j + '.remarks'),
                    ]
                  })
                )}
              </Grid>
            </Box>
            <Box clone>
              <Grid item xs={12}>
                <Box clone pb={3} pt={3}>{obj.hrTemplate()}</Box>
              </Grid>
            </Box>
          </Grid>
        </Box>
      })}

    </Box>
  },
  getManageDataDriverDetailsMonthly: (currentVersion, previousVersion, callback) => {
    if(callback){
      if(currentVersion){
          currentVersion['payrollTemplateName'] = (currentVersion && currentVersion.payrollTemplate && currentVersion.payrollTemplate.templateName) ? currentVersion.payrollTemplate.templateName : '';
      }
      
      if(previousVersion){
          previousVersion['payrollTemplateName'] = (previousVersion && previousVersion.payrollTemplate && previousVersion.payrollTemplate.templateName) ? previousVersion.payrollTemplate.templateName : '';
      }
      
      callback(currentVersion, previousVersion);
    }
  },
}

export default AuditTrailFunctions;