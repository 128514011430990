import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

import * as dispatchCrudApi from '../../../api/CRUD/DispatchCRUD'
import Utils from "../../../utils/utils";


function NewReducer() {
  const name = 'billingQuickbookXero';


  const initialState = {
    quickbook: {
      isLoading: false,
      items: [],
    },
    xero: {
      isLoading: false,
      items: [],
    },
    QBPurchase: {
      isLoading: false,
      items: [],
    },
    xeroPurchase: {
      isLoading: false,
      items: [],
    },
    QBExpense: {
      isLoading: false,
      items: [],
    },
    xeroExpense: {
      isLoading: false,
      items: [],
    },
  };


  const reducers = {
    startQuickbook: (state) => {
      state.quickbook.isLoading = true;
      state.quickbook.items = [];
    },
    finishQuickbook: (state, action) => {
      let quickbook = state.quickbook;

      let arr = [];
      let data = action.payload;
      if(data.data && data.data.length > 0){
        for(var i = 0; i < data.data.length; i++){
          let item = data.data[i];
          let length = data.data.length;

          arr.push(<div key={i}>
            <div>Billed: {(item.invoiceDate) ? moment(item.invoiceDate).format(Utils.getDefaultDateFormat()) : ''}</div>
            <div>
              <a target={'_blank'} rel="noopener noreferrer"
                href={item.quickbookInvoiceUrl} 
                className={'link-primary'}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >{item.invoiceNumber}</a>
            </div>
            {(length > 0) ? (i < (length-1)) ? <hr /> : null : null}
          </div>);
        }
      }
      
      quickbook.items = arr;
      quickbook.isLoading = false;
      state.quickbook = quickbook;
    },


    startXero: (state) => {
      state.xero.isLoading = true;
      state.xero.items = [];
    },
    finishXero: (state, action) => {
      let xero = state.xero;

      let arr = [];
      let data = action.payload;
      if(data.data && data.data.length > 0){
        for(var i = 0; i < data.data.length; i++){
          let item = data.data[i];
          let length = data.data.length;

          arr.push(<div key={i}>
            <div>Billed: {(item.invoiceDate) ? moment(item.invoiceDate).format(Utils.getDefaultDateFormat()) : ''}</div>
            <div>
              <a target={'_blank'} rel="noopener noreferrer"
                href={item.xeroInvoiceUrl} 
                className={'link-primary'}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >{item.invoiceNumber}</a>
            </div>
            {(length > 0) ? (i < (length-1)) ? <hr /> : null : null}
          </div>);
        }
      }

      xero.items = arr;
      xero.isLoading = false;
      state.xero = xero;
    },


    startQBPurchase: (state) => {
      state.QBPurchase.isLoading = true;
      state.QBPurchase.items = [];
    },
    finishQBPurchase: (state, action) => {
      let QBPurchase = state.QBPurchase;

      let arr = [];
      let data = action.payload;
      if(data.data && data.data.length > 0){
        for(var i = 0; i < data.data.length; i++){
          let item = data.data[i];
          let length = data.data.length;

          arr.push(<div key={i}>
            <div>Billed: {(item.invoiceDate) ? moment(item.invoiceDate).format(Utils.getDefaultDateFormat()) : ''}</div>
            <div>
              <a target={'_blank'} rel="noopener noreferrer"
                href={item.quickbookInvoiceUrl} 
                className={'link-primary'}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >{item.invoiceNumber}</a>
            </div>
            {(length > 0) ? (i < (length-1)) ? <hr /> : null : null}
          </div>);
        }
      }
      
      QBPurchase.items = arr;
      QBPurchase.isLoading = false;
      state.QBPurchase = QBPurchase;
    },
    
    
    startXeroPurchase: (state) => {
      state.xeroPurchase.isLoading = true;
      state.xeroPurchase.items = [];
    },
    finishXeroPurchase: (state, action) => {
      let xeroPurchase = state.xeroPurchase;

      let arr = [];
      let data = action.payload;
      if(data.data && data.data.length > 0){
        for(var i = 0; i < data.data.length; i++){
          let item = data.data[i];
          let length = data.data.length;

          arr.push(<div key={i}>
            <div>Billed: {(item.purchaseOrderDate) ? moment(item.purchaseOrderDate).format(Utils.getDefaultDateFormat()) : ''}</div>
            <div>
              <a target={'_blank'} rel="noopener noreferrer"
                href={item.xeroPurchaseOrderUrl} 
                className={'link-primary'}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >{item.purchaseOrderNumber}</a>
            </div>
            {(length > 0) ? (i < (length-1)) ? <hr /> : null : null}
          </div>);
        }
      }
      
      xeroPurchase.items = arr;
      xeroPurchase.isLoading = false;
      state.xeroPurchase = xeroPurchase;
    },
    
    startQBExpense: (state) => {
      state.QBExpense.isLoading = true;
      state.QBExpense.items = [];
    },
    finishQBExpense: (state, action) => {
      let QBExpense = state.QBExpense;

      let arr = [];
      let data = action.payload;
      if(data.data && data.data.length > 0){
        for(var i = 0; i < data.data.length; i++){
          let item = data.data[i];
          let length = data.data.length;

          arr.push(<div key={i}>
            <div>Billed: {(item.invoiceDate) ? moment(item.invoiceDate).format(Utils.getDefaultDateFormat()) : ''}</div>
            <div>
              <a target={'_blank'} rel="noopener noreferrer"
                href={item.quickbookInvoiceUrl} 
                className={'link-primary'}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >{item.invoiceNumber}</a>
            </div>
            {(length > 0) ? (i < (length-1)) ? <hr /> : null : null}
          </div>);
        }
      }
      
      QBExpense.items = arr;
      QBExpense.isLoading = false;
      state.QBExpense = QBExpense;
    },
    
    
    startXeroExpense: (state) => {
      state.xeroExpense.isLoading = true;
      state.xeroExpense.items = [];
    },
    finishXeroExpense: (state, action) => {
      let xeroExpense = state.xeroExpense;

      let arr = [];
      let data = action.payload;
      if(data.data && data.data.length > 0){
        for(var i = 0; i < data.data.length; i++){
          let item = data.data[i];
          let length = data.data.length;

          arr.push(<div key={i}>
            <div>Billed: {(item.expenseDate) ? moment(item.expenseDate).format(Utils.getDefaultDateFormat()) : ''}</div>
            <div>
              <a target={'_blank'} rel="noopener noreferrer"
                href={item.xeroExpenseUrl} 
                className={'link-primary'}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >{item.expenseNumber}</a>
            </div>
            {(length > 0) ? (i < (length-1)) ? <hr /> : null : null}
          </div>);
        }
      }
      
      xeroExpense.items = arr;
      xeroExpense.isLoading = false;
      state.xeroExpense = xeroExpense;
    },
  };


  const apis = {
    callQuickbookApi: (params = null) => async (dispatch) => {
      dispatch(actions.startQuickbook());
  
      await dispatchCrudApi.readApi(params, 'job/billing/quickbook-invoice-history').then(result => {
        let data = result.data.data;
        
        dispatch(actions.finishQuickbook({ data: data, errorMessage: '', isError: false }));
      }).catch(error => {
        let isError = true;
        let errorMessage = "";

        Utils.parseError(error, (result) => {
          isError = result.isError;
          errorMessage = result.errorMessage;
        });
        
        dispatch(actions.finishQuickbook({ data: null, errorMessage: errorMessage, isError: isError }));
      });
    },
    
    callXeroApi: (params = null) => async (dispatch) => {
      dispatch(actions.startXero());
  
      await dispatchCrudApi.readApi(params, 'job/billing/xero-invoice-history').then(result => {
        let data = result.data.data;
        
        dispatch(actions.finishXero({ data: data, errorMessage: '', isError: false }));
      }).catch(error => {
        let isError = true;
        let errorMessage = "";
        
        Utils.parseError(error, (result) => {
          isError = result.isError;
          errorMessage = result.errorMessage;
        });
        
        dispatch(actions.finishXero({ data: null, errorMessage: errorMessage, isError: isError }));
      });
    },

    callQBPurchaseApi: (params = null) => async (dispatch) => {
      dispatch(actions.startQBPurchase());
  
      await dispatchCrudApi.readApi(params, 'job/billing/quickbook-purchase-order-history').then(result => {
        let data = result.data.data;
        
        dispatch(actions.finishQBPurchase({ data: data, errorMessage: '', isError: false }));
      }).catch(error => {
        let isError = true;
        let errorMessage = "";

        Utils.parseError(error, (result) => {
          isError = result.isError;
          errorMessage = result.errorMessage;
        });
        
        dispatch(actions.finishQBPurchase({ data: null, errorMessage: errorMessage, isError: isError }));
      });
    },
    
    callXeroPurchaseApi: (params = null) => async (dispatch) => {
      dispatch(actions.startXeroPurchase());
  
      await dispatchCrudApi.readApi(params, 'job/billing/xero-purchase-order-history').then(result => {
        let data = result.data.data;
        
        dispatch(actions.finishXeroPurchase({ data: data, errorMessage: '', isError: false }));
      }).catch(error => {
        let isError = true;
        let errorMessage = "";

        Utils.parseError(error, (result) => {
          isError = result.isError;
          errorMessage = result.errorMessage;
        });
        
        dispatch(actions.finishXeroPurchase({ data: null, errorMessage: errorMessage, isError: isError }));
      });
    },

    callQBExpenseApi: (params = null) => async (dispatch) => {
      dispatch(actions.startQBExpense());
  
      await dispatchCrudApi.readApi(params, 'job/billing/quickbook-expense-history').then(result => {
        let data = result.data.data;
        
        dispatch(actions.finishQBExpense({ data: data, errorMessage: '', isError: false }));
      }).catch(error => {
        let isError = true;
        let errorMessage = "";

        Utils.parseError(error, (result) => {
          isError = result.isError;
          errorMessage = result.errorMessage;
        });
        
        dispatch(actions.finishQBExpense({ data: null, errorMessage: errorMessage, isError: isError }));
      });
    },
    
    callXeroExpenseApi: (params = null) => async (dispatch) => {
      dispatch(actions.startXeroExpense());
  
      await dispatchCrudApi.readApi(params, 'job/billing/xero-expense-history').then(result => {
        let data = result.data.data;
        
        dispatch(actions.finishXeroExpense({ data: data, errorMessage: '', isError: false }));
      }).catch(error => {
        let isError = true;
        let errorMessage = "";

        Utils.parseError(error, (result) => {
          isError = result.isError;
          errorMessage = result.errorMessage;
        });
        
        dispatch(actions.finishXeroExpense({ data: null, errorMessage: errorMessage, isError: isError }));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();