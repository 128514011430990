import React, { Component } from 'react';

import { 
  Box,
  Grid,
  Button,
} from '@material-ui/core';

import GanttchartRouteMap from '../../../components/OLD/Pages/Jobs/GanttchartRouteMap.jsx';


class DriverRouteDialog extends Component {
  
  constructor(props){
    super(props);

    let item = (props.item) ? props.item : null;
    let markers = (item && item.data && item.data.jobs && item.data.jobs.length > 0) ? item.data.jobs : [];
    
    this.state = {
      markers: markers,
    }
  }


  setMap = () => {
    return <Box clone>
        <Grid item xs={12}>
            <Box
                width={'100%'}
                height={'400px'}
            >
                <GanttchartRouteMap
                    markers={this.state.markers}
                />
            </Box>
        </Grid>
    </Box>
  }

  setButtons = () => {
      return <Box clone pt={3} textAlign={"right"}>
        <Grid item xs={12}>
            <Button 
                color={'default'} 
                variant={'contained'}
                style={{ marginRight: '10px' }}
                onClick={() => {
                    if(this.props.onClose)
                        this.props.onClose()
                    }
                }
            >
                Cancel
            </Button>
        </Grid>
    </Box>
  }
  
  render() {
    return <Box className="driver-route-dialog">
        <Grid container>
            {this.setMap()}
            {this.setButtons()}
        </Grid>
    </Box>;
  }
}

export default DriverRouteDialog;
