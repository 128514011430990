import React, { useEffect } from "react";
import GSTC from "./Util/GSTCWrapper.jsx";


const GranttChart = (props) => {
    let [config, setConfig] = React.useState((props.config) ? props.config : {})
    let [className, setClassName] = React.useState((props.className) ? props.className : '')

    useEffect(() => {
        if(props.config !== config){
            setConfig((props.config) ? props.config : {});
        }
        if(props.className !== className){
            setClassName((props.className) ? props.className : '');
        }
    }, [ props.config, config, props.className, className ]);


    return <div className={"grantt-chart-component " + className}>
        <GSTC 
            config={config} 
            onInit={props.onInit}
            onState={props.onState}
        />
    </div>
  };

  export default GranttChart;