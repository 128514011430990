import React, { Component } from 'react';

import { 
  Paper,
} from '@material-ui/core';

class Card extends Component {

  constructor(props){
    super(props);

    let className = (props.className) ? props.className : '';
    let elevation = (props.elevation && props.elevation > 0) ? props.elevation : 0;

    this.state = {
        className: className,
        elevation: elevation,
    }
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.className !== this.state.className) {
      this.setState({
        className: nextProps.className
      });
    }

    if (nextProps.elevation !== this.state.elevation) {
      let elevation = (nextProps.elevation && nextProps.elevation > 0) ? nextProps.elevation : 0;
      this.setState({
        elevation: elevation
      });
    }
  }


  render() {
    return <Paper 
      elevation={this.state.elevation} 
      className={'custom-card-component ' + this.state.className} 
      style={this.props.style}
      onClick={() => {
        if(this.props.onClick){
          this.props.onClick();
        }
      }}
    >
        {this.props.children}
    </Paper>
  }
}

export default Card;
