import React from 'react';

import {
    Row,
    Col,
} from "react-bootstrap-v5";

import Skeleton from '@mui/material/Skeleton';

// import Utils from '../../../../utils/utils';
// import {  } from '../../../../utils/enums';

import { useDispatch, useSelector } from "react-redux";
import {RootState} from '../../../../../setup'
import driversDetails, { useFormikContext, getPrevDriver, getNextDriver } from '../redux/driversDetails';

import { StyledTitle, StyledPrevNextLink } from '../style/driversDetailsStyles';


const TopSection = (props: any) => {
    const { values } = useFormikContext();

    const dispatch = useDispatch();
    const { isLoading, isLoadingDriver, indexDriver, listDriver, id, months } = useSelector((state: RootState) => state.driversDetails);


    return <Row>
        <Col xs={{ span: 'auto', order: 1 }} md={{ span: 'auto', order: 1 }}>
            <StyledPrevNextLink
                href={'/'}
                title={getPrevDriver(indexDriver, listDriver)?.driverName}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    if(!months.some(x => x.isLoading)){
                        dispatch(driversDetails.prevDriver());
                    }
                }}
            >
                {'<< Previous'}
            </StyledPrevNextLink>
        </Col>
        <Col xs={{ span: 12, order: 3 }} md={{ span: 'auto', order: 2 }} className={'mt-1 mt-md-0'}>
            <StyledTitle className={'ps-6 ps-md-0'}>{id ? (isLoading || isLoadingDriver) ? <Skeleton variant="rounded" width={100} height={20} /> : values.driverName : <>New Driver</>}</StyledTitle>
        </Col>
        <Col xs={{ span: 'auto', order: 2 }} md={{ span: 'auto', order: 3 }}>
            <StyledPrevNextLink
                href={'/'}
                title={getNextDriver(indexDriver, listDriver)?.driverName}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    if(!months.some(x => x.isLoading)){
                        dispatch(driversDetails.nextDriver());
                    }
                }}
            >
                {'Next >>'}
            </StyledPrevNextLink>
        </Col>
    </Row>
}


export default TopSection;
