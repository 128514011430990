import React from 'react';

import {
    TableCell,
    TableRow
} from '@material-ui/core';


const DataTableSkeletonRow = (props) => {
    const { isBorder, isCheckbox, isDragable, columns, height, columnVisibility, expandDetails } = props;
    
    const cols = (columnVisibility && columnVisibility.length > 0) ? columnVisibility.filter(x => x === true).length : 0;
    const colSpan = (isBorder) ? cols + 1 : cols + 1;

    return <TableRow
        className={"skeleton_row"}
        tabIndex={-1}
    >
        {isBorder && <TableCell className="table-cell-border"></TableCell>}

        {isCheckbox && 
        <TableCell 
            className="datatable-checkbox-style"
            width={'50px'} 
            padding="normal"
        />}

        {expandDetails && <TableCell width={'30px'} />}

        {isDragable && <TableCell className="responsive-hide" width={'30px'} />}

        <TableCell style={{ textAlign: 'center', height: height }} colSpan={colSpan-1}>Loading ...</TableCell>

        {isBorder && <TableCell className="table-cell-border"></TableCell>}
    </TableRow>;
  }

  export default DataTableSkeletonRow;