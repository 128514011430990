export default function setupAxios(axios: any, store: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: {token},
        cancelToken: { cancelToken }
      } = store.getState()

      if(cancelToken){
        config.cancelToken = cancelToken.token;
      }

      if (token) {
        config.headers['content-type'] = 'application/json'
        config.headers.ContentType = 'application/json'
        config.headers.Authorization = (config && config.headers && config.headers.Authorization) ? config.headers.Authorization : token
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}
