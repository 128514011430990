import React from "react";
import queryString from "query-string";
import _ from 'lodash';

import { 
    Row,
    Col,
    Form,
    Button,
    InputGroup,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import Tooltip from '../../../components/Tooltip/Tooltip';
import LoadingButton from '../../../components/Buttons/LoadingButton';
import RSuiteSelectPicker from '../../../components/OLD/Select/RSuiteSelectPicker';

import Utils from "../../../utils/utils";

import { connect } from 'react-redux'
import notifications from './redux/notifications';


const { 
    REACT_APP_URL,
    REACT_APP_TWILIO_URL,
    REACT_APP_TWILIO_CLIENT_SID,
} = process.env;


const StyledMenuButton = styled(Button)`
    width: 100%;
    text-align: left;

    background-color: ${props => {
        if((props.active === true)){
            return '#185cff';
        } else {
            return 'transparent';
        }
    }} !important;

    color: ${props => {
        if((props.active === true)){
            return 'white';
        } else {
            return '#3F4254';
        }
    }} !important;

    &:hover {
        background-color: #185cff !important;
        color: white !important;
    }

    @media (max-width: 768px) {
        padding-left: 5px !important;
        padding-right: 5px !important;
        height: 30px !important;
        line-height: 15px;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
`;


class NotificationForm extends React.Component {
  
    constructor(props) {
        super(props);
    
        this.refLastSelectedInput = React.createRef();

        this.tags = [
            {
                value: '[[CustomerName]]',
                title: 'Customer Name',
            },
            {
                value: '[[JobNumber]]',
                title: 'Job Number',
            },
            {
                value: '[[JobCompletionDate]]',
                title: 'Job Completion Date',
            },
        ];

        
        let q = queryString.parse(window.location.search);
        this.id = q.id ? q.id : "";


        
        this.state = {
            passwordType: false,
            accountSidValidation: false,
            authTokenValidation: false,
        };
    }

    connectionForm = () => {
        let {
            handleChange,
            setFieldValue,
            handleBlur,
            values,
            errors,
        } = this.props.formOptions;

        return <>
            <Form.Group as={Row} className={'mb-5'}>
                <Form.Label column xs={12} md={4} xl={3}>{this.props.fields.notificationConnectTo.label}</Form.Label>
                <Col xs={12} md={true}>
                    <Form.Control
                        type="text"
                        disabled
                        id={this.props.fields.notificationConnectTo.id}
                        placeholder={this.props.fields.notificationConnectTo.placeholder}
                        value={values.notificationConnectTo}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.notificationConnectTo}
                    />
                    <Form.Control.Feedback type="invalid">{errors.notificationConnectTo}</Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className={'mb-5'}>
                <Form.Label column xs={12} md={4} xl={3}>{this.props.fields.notificationConnectServices.label}</Form.Label>
                <Col xs={12} md={true}>
                    <Form.Control
                        type="text"
                        disabled
                        id={this.props.fields.notificationConnectServices.id}
                        placeholder={this.props.fields.notificationConnectServices.placeholder}
                        value={values.notificationConnectServices}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.notificationConnectServices}
                    />
                    <Form.Control.Feedback type="invalid">{errors.notificationConnectServices}</Form.Control.Feedback>
                </Col>
            </Form.Group>

            <Form.Group as={Row} className={'mb-5'}>
                <Form.Label column xs={12} md={4} xl={3}>{this.props.fields.accountSid.label}</Form.Label>
                <Col xs={12} md={true}>
                    <Form.Control
                        type="text"
                        autoComplete="off"
                        disabled={values.customFormParams.isConnectTwilio}
                        id={this.props.fields.accountSid.id}
                        placeholder={this.props.fields.accountSid.placeholder}
                        value={values.accountSid}
                        onChange={async(e) => {
                            await setFieldValue('accountSid', e.target.value);

                            this.setState({
                                accountSidValidation: (e.target.value == ''),
                            });
                        }}
                        onBlur={handleBlur}
                        isInvalid={this.state.accountSidValidation}
                    />
                    <Form.Control.Feedback type="invalid">{errors.accountSid}</Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className={'mb-5'}>
                <Form.Label column xs={12} md={4} xl={3}>{this.props.fields.authToken.label}</Form.Label>
                <Col xs={12} md={true}>
                    <InputGroup className={'rounded-1 overflow-hidden'}>
                        <Form.Control
                            type={this.state.passwordType ? 'text' : 'password'}
                            autoComplete="new-password"
                            disabled={values.customFormParams.isConnectTwilio}
                            id={this.props.fields.authToken.id}
                            placeholder={this.props.fields.authToken.placeholder}
                            value={values.authToken}
                            onChange={async(e) => {
                                await setFieldValue('authToken', e.target.value);

                                this.setState({
                                    authTokenValidation: (e.target.value == ''),
                                });
                            }}
                            onBlur={handleBlur}
                            isInvalid={this.state.authTokenValidation}
                        />
                        <InputGroup.Text
                            className={'cursor-pointer'}
                            onClick={() => {
                                this.setState({
                                    passwordType: !this.state.passwordType,
                                });
                            }}
                        >
                            <i className={'material-icons'} style={{ fontSize: '18px' }}>{this.state.passwordType ? 'visibility_off' : 'visibility'}</i>
                        </InputGroup.Text>
                        <Form.Control.Feedback type="invalid">{errors.authToken}</Form.Control.Feedback>
                    </InputGroup>
                </Col>
            </Form.Group>


            {/* {values.customFormParams.isConnectTwilio && <Form.Group as={Row} className={'mb-5'}>
                <Form.Label column xs={12} md={4} xl={3}>{this.props.fields.notificationMessageFrom.label}</Form.Label>
                <Col xs={12} md={true}>
                    <Form.Control
                        type="text"
                        disabled
                        id={this.props.fields.notificationMessageFrom.id}
                        placeholder={this.props.fields.notificationMessageFrom.placeholder}
                        value={values.notificationMessageFrom}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.notificationMessageFrom}
                    />
                    <Form.Control.Feedback type="invalid">{errors.notificationMessageFrom}</Form.Control.Feedback>
                </Col>
            </Form.Group>} */}

            {values.customFormParams.isConnectTwilio && <Form.Group as={Row} className={'mb-5'}>
                <Form.Label column xs={12} md={4} xl={3}>{this.props.fields.fromPhoneNumber.label}</Form.Label>
                <Col xs={12} md={true}>
                    <Form.Control
                        as = {RSuiteSelectPicker}
                        isInvalid={!!errors.fromPhoneNumber}

                        id={this.props.fields.fromPhoneNumber.id}
                        placeholder={this.props.fields.fromPhoneNumber.placeholder}
                        items={this.props.notifications.dataSenderPhoneNumber}
                        value={values.fromPhoneNumber}
                        selectedName={values.fromPhoneNumber}
                        isLoading={this.props.notifications.isLoadingSenderPhoneNumber}
                        searchable={false}
                        cleanable={false}
                        isDefault={true}
                        onClick={async () => {
                            let twilioAppId = (values.twilioApp) ? values.twilioApp.twilioAppId : null;
                            await this.props.action.notifications.getSenderPhoneNumber(twilioAppId);
                        }}
                        onSelect={async (value, item) => {
                            await setFieldValue('fromPhoneNumber', value);
                            
                            let twilioAppId = (values.twilioApp) ? values.twilioApp.twilioAppId : null;
                            let data = {
                                twilioAppId: twilioAppId,
                                accountBillingProfileId: this.id,
                                fromPhoneNumber: value,
                            };
                            await this.props.action.notifications.setSenderPhoneNumber(data, () => {
                                Utils.toast('Phone number has been changed', 'success');
                            });
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.fromPhoneNumber}</Form.Control.Feedback>
                </Col>
            </Form.Group>}

            <Form.Group as={Row} className={'mb-5'}>
                <Form.Label column xs={12} md={4} xl={3} className={'d-none d-lg-flex'}>&nbsp;</Form.Label>
                <Col xs={12} md={true} className={'d-grid'}>
                    <LoadingButton 
                        variant={this.props.notifications.isLoading ? 'light' : values.customFormParams.isConnectTwilio ? 'danger' : 'success'}
                        isLoading={this.props.notifications.isLoading}
                        onClick={async () => {
                            if(values.isActive){
                                if(values.customFormParams.isConnectTwilio){
                                    this.props.action.notifications.disconnect(values.twilioApp, () => {
                                        window.location.reload();
                                    });
                                } else {
                                    if((values.accountSid == '') && (values.authToken == '')){
                                        this.setState({
                                            accountSidValidation: true,
                                            authTokenValidation: true,
                                        }, () => {
                                            Utils.toast('You must fill in the account SID field', 'error');
                                            Utils.toast('You must fill in the auth token field', 'error');
                                        });
                                    } else if(values.accountSid == ''){
                                        this.setState({
                                            accountSidValidation: true,
                                            authTokenValidation: false,
                                        }, () => {
                                            Utils.toast('You must fill in the account SID field', 'error');
                                        });
                                    } else if(values.authToken == ''){
                                        this.setState({
                                            accountSidValidation: false,
                                            authTokenValidation: true,
                                        }, () => {
                                            Utils.toast('You must fill in the auth token field', 'error');
                                        });
                                    } else {
                                        this.setState({
                                            accountSidValidation: false,
                                            authTokenValidation: false,
                                        }, () => {
                                            let data = {
                                                accountBillingProfileId: this.id,
                                                accountSid: values.accountSid,
                                                authToken: values.authToken,
                                            };
                                            this.props.action.notifications.connect(data, () => {
                                                window.location.reload();
                                            });
                                        });
                                    }
                                    // window.location.href = REACT_APP_TWILIO_URL + REACT_APP_TWILIO_CLIENT_SID + '?state=' + this.id;
                                }
                            } else {
                                Utils.toast('Sorry, this profile is not active!', 'error');
                            }
                        }}
                    >
                        {values.customFormParams.isConnectTwilio ? 'Disconnect' : 'Connect'}
                    </LoadingButton>
                </Col>
            </Form.Group>
        </>
    }

    notificationMenu = () => {
        let {
            validateForm,
            values,
        } = this.props.formOptions;

        return <Row className={'g-1'}>
            {(values.accountMessageTemplates && values.accountMessageTemplates.length > 0) && values.accountMessageTemplates.map((item, i) => {
                return <Col key={'menu_' + item.jobStatusId} xs={4} md={12}>
                    <StyledMenuButton 
                        className={'btn-active'}
                        type={'button'}
                        variant={'primary'}
                        size={'sm'}
                        title={(item && item.status && item.status.jobStatusName) ? item.status.jobStatusName : ''}
                        active={(this.props.notifications.selectedMenu === i)}
                        onClick={() => {
                            validateForm().then(async (err) => {
                                let accountMessageTemplates = (err && err.accountMessageTemplates && err.accountMessageTemplates.length > 0) ? err.accountMessageTemplates : [];
                                
                                if(!_.isEmpty(accountMessageTemplates)){
                                    Utils.toast('You must fill in the required fields', 'error');
                                } else {
                                    await this.props.action.notifications.setSelectedMenu(i);
                                }
                            })
                        }}
                    >
                        {(item && item.status && item.status.jobStatusName) ? item.status.jobStatusName : ''}
                    </StyledMenuButton>
                </Col>
            })}
        </Row>
    }
    
    notificationForm = () => {
        let {
            setFieldValue,
            handleBlur,
            values,
            errors,
        } = this.props.formOptions;
        
        let smsTemplate = (values.accountMessageTemplates && values.accountMessageTemplates[this.props.notifications.selectedMenu] && values.accountMessageTemplates[this.props.notifications.selectedMenu].smsTemplate) ? values.accountMessageTemplates[this.props.notifications.selectedMenu].smsTemplate : '';
        let whatsappTemplate = (values.accountMessageTemplates && values.accountMessageTemplates[this.props.notifications.selectedMenu] && values.accountMessageTemplates[this.props.notifications.selectedMenu].whatsappTemplate) ? values.accountMessageTemplates[this.props.notifications.selectedMenu].whatsappTemplate : '';

        let smsTemplateLength = (smsTemplate && smsTemplate.length > 0) ? smsTemplate.length : 0;
        let whatsappTemplateLength = (whatsappTemplate && whatsappTemplate.length > 0) ? whatsappTemplate.length : 0;

        let isInvalidSms = (errors && errors.accountMessageTemplates && errors.accountMessageTemplates[this.props.notifications.selectedMenu] && errors.accountMessageTemplates[this.props.notifications.selectedMenu].smsTemplate);
        let isInvalidWhatsApp = (errors && errors.accountMessageTemplates && errors.accountMessageTemplates[this.props.notifications.selectedMenu] && errors.accountMessageTemplates[this.props.notifications.selectedMenu].whatsappTemplate);

        return <>
            <Form.Group as={Row} className={'mb-5'}>
                <Form.Label column xs={12}>{this.props.fields.smsTemplate.label}</Form.Label>
                <Col xs={12}>
                    <Form.Control
                        as="textarea"
                        rows={5}
                        id={this.props.fields.smsTemplate.id}
                        placeholder={this.props.fields.smsTemplate.placeholder}
                        value={smsTemplate}
                        onChange={async (e) => {
                            Utils.limitRows(e.target.value, 5, async (value) => {
                                await setFieldValue('accountMessageTemplates.' + this.props.notifications.selectedMenu + '.smsTemplate', value);
                            });
                        }}
                        onFocus={(e) => {
                            this.refLastSelectedInput = 'sms';
                        }}
                        onBlur={handleBlur}
                        isInvalid={!!isInvalidSms}
                    />
                    <div style={{ fontSize: '12px', textAlign: 'right' }} className={(!!isInvalidSms) ? 'text-danger' : 'text-muted'}>Message length: {smsTemplateLength}/{this.props.fields.smsTemplate.maxLength}</div>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className={'mb-5'}>
                <Form.Label column xs={12}>{this.props.fields.whatsappTemplate.label}</Form.Label>
                <Col xs={12}>
                    <Form.Control
                        as="textarea"
                        rows={5}
                        id={this.props.fields.whatsappTemplate.id}
                        placeholder={this.props.fields.whatsappTemplate.placeholder}
                        value={whatsappTemplate}
                        onChange={async (e) => {
                            Utils.limitRows(e.target.value, 5, async (value) => {
                                await setFieldValue('accountMessageTemplates.' + this.props.notifications.selectedMenu + '.whatsappTemplate', value);
                            });
                        }}
                        onFocus={(e) => {
                            this.refLastSelectedInput = 'whatsapp';
                        }}
                        onBlur={handleBlur}
                        isInvalid={!!isInvalidWhatsApp}
                    />
                    <div style={{ fontSize: '12px', textAlign: 'right' }} className={(!!isInvalidWhatsApp) ? 'text-danger' : 'text-muted'}>Message length: {whatsappTemplateLength}/{this.props.fields.whatsappTemplate.maxLength}</div>
                </Col>
            </Form.Group>
        </>
    }
    
    notificationTags = () => {
        let {
            setFieldValue,
            values,
        } = this.props.formOptions;
        
        return <Row className={'align-items-center mb-5'}>
            {this.tags.map((tag, i) => {
                return <CopyToClipboard 
                    key={i} 
                    text={tag}
                    onCopy={async () => {
                        if(this.refLastSelectedInput == 'whatsapp'){
                            let prevValue = (values.accountMessageTemplates && values.accountMessageTemplates[this.props.notifications.selectedMenu] && values.accountMessageTemplates[this.props.notifications.selectedMenu].whatsappTemplate) ? values.accountMessageTemplates[this.props.notifications.selectedMenu].whatsappTemplate : '';
                            let plainText = prevValue + ' ' + tag.value;
    
                            await setFieldValue('accountMessageTemplates.' + this.props.notifications.selectedMenu + '.whatsappTemplate', plainText);

                        } else {
                            let prevValue = (values.accountMessageTemplates && values.accountMessageTemplates[this.props.notifications.selectedMenu] && values.accountMessageTemplates[this.props.notifications.selectedMenu].smsTemplate) ? values.accountMessageTemplates[this.props.notifications.selectedMenu].smsTemplate : '';
                            let plainText = prevValue + ' ' + tag.value;
    
                            await setFieldValue('accountMessageTemplates.' + this.props.notifications.selectedMenu + '.smsTemplate', plainText);
                        }
                    }}
                >
                    <Col sm={'auto'} lg={12} className={'cursor-pointer mb-5'}>
                        <Tooltip title="Copy Tag">
                            <div className={'d-flex align-items-center'} color={'#7F7F7F'} pr={3}>
                                {tag.title}
                                <i className={'material-icons'} style={{ color: '#7F7F7F', marginLeft: '10px', fontSize: '18px' }}>content_copy</i>
                            </div>
                        </Tooltip>
                    </Col>
                </CopyToClipboard>
            })}
        </Row>
    }


    render() {
        let {
            values,
        } = this.props.formOptions;

        return <>
            <Row className={'mb-10'}>
                <Col xs={12} md={10} lg={8} xl={6}>{this.connectionForm()}</Col>
            </Row>
            {values.customFormParams.isConnectTwilio && <Row className={'mt-20'}>
                <Col xs={12} md={10} lg={8} xl={6}>
                    <Row>
                        <Col xs={12} md={4} xl={3}>{this.notificationMenu()}</Col>
                        <Col xs={12} md={8} xl={9} className={'mt-10 mt-md-0'}>{this.notificationForm()}</Col>
                    </Row>
                </Col>
                <Col xs={12} md={2} lg={4} xl={3} className={'mt-10 mt-md-13'}>{this.notificationTags()}</Col>
            </Row>}
        </>
    }
}


const mapStateToProps = state => {
    return {
      ...state,
      ...Utils.mapStateToProps(state),
    };
  };
  
  const mapDispatchToProps = (dispatch) => ({
    action: {
      notifications: {
        setSelectedMenu: (payload) => dispatch(notifications.setSelectedMenu(payload)),
        disconnect: (payload, callback) => dispatch(notifications.disconnect(payload, callback)),
        connect: (payload, callback) => dispatch(notifications.connect(payload, callback)),
        getSenderPhoneNumber: (payload, callback) => dispatch(notifications.getSenderPhoneNumber(payload, callback)),
        setSenderPhoneNumber: (payload, callback) => dispatch(notifications.setSenderPhoneNumber(payload, callback)),
      },
    }
  });
  
  
  export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(NotificationForm);
