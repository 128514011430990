import * as yup from 'yup';
import _ from "lodash";
import moment from "moment";

import Utils from '../../../utils/utils';
import { BillingTypes, ServiceType, ReportUOM, XeroPaymentTermType } from '../../../utils/enums';


export const fieldsDetails = {
  billingAddress: {
    id: 'billingAddress',
    label: 'Billing address',
    placeholder: Utils.placeholderRows(4),
    error: 'Billing address is a required field',
  },
  accountBillingProfileId: {
    id: 'accountBillingProfileId',
    label: 'Billing by',
    placeholder: ' ',
    error: 'Billing by is a required field',
  },
  invoiceDate: {
    id: 'invoiceDate',
    label: 'Invoice date',
    labelPurchase: 'Purchase date',
    labelExpense: 'Expense date',
    placeholder: ' ',
    error: 'Invoice date is a required field',
  },
  invoiceNumber: {
    id: 'invoiceNumber',
    label: 'Invoice number',
    labelPurchase: 'Purchase number',
    labelExpense: 'Expense number',
    placeholder: ' ',
    error: 'Invoice number is a required field',
  },
  paymentTermsId: {
    id: 'paymentTermsId',
    label: 'Payment terms',
    placeholder: ' ',
    error: 'Payment terms is a required field',
  },
  xeroPaymentTerm: {
    id: 'xeroPaymentTerm',
    label: 'Payment terms',
    placeholder: ' ',
    error: 'Payment terms is a required field',
  },
  xeroPaymentTermType: {
    id: 'xeroPaymentTermType',
    label: ' ',
    placeholder: ' ',
    error: 'Due date for sales invoices must be between 1 and 31 when set to the following (or current) month.',
  },
}


export const rowFields = {
    customerName: {
      id: 'customerName',
      label: 'Customer name',
      placeholder: ' ',
    },
    jobNumber: {
      id: 'jobNumber',
      label: 'DO #',
      placeholder: ' ',
    },

    jobStatusName: {
      id: 'jobStatusName',
      label: 'Job Status',
      placeholder: ' ',
    },
    customerSiteName: {
      id: 'customerSiteName',
      label: 'Site Address',
      placeholder: ' ',
    },
    jobTypeName: {
      id: 'jobTypeName',
      label: 'Job Type',
      placeholder: ' ',
    },
    jobDate: {
      id: 'jobDate',
      label: 'Date / Time',
      placeholder: ' ',
    },
    billingName: {
      id: 'billingName',
      label: 'Billing Name',
      placeholder: ' ',
    },

    isInvoiced: {
      id: 'isInvoiced',
      label: 'Status',
      placeholder: ' ',
    },
    billedDate: {
      id: 'billedDate',
      label: 'Billed date',
      placeholder: ' ',
    },
    invoiceDate: {
      id: 'invoiceDate',
      label: 'Invoice date',
      placeholder: ' ',
    },
    invoiceNumber: {
      id: 'invoiceNumber',
      label: 'Invoice number',
      placeholder: ' ',
    },
    accountBillingProfileId: {
      id: 'accountBillingProfileId',
      label: 'Billing by',
      placeholder: ' ',
    },
    paymentTermsId: {
      id: 'paymentTermsId',
      label: 'Payment terms',
      placeholder: ' ',
    },
    xeroPaymentTerm: {
      id: 'xeroPaymentTerm',
      label: 'Payment terms',
      placeholder: ' ',
    },
    xeroPaymentTermType: {
      id: 'xeroPaymentTermType',
      label: ' ',
      placeholder: ' ',
      error: 'Due date for sales invoices must be between 1 and 31 when set to the following (or current) month.',
    },
    billingAddress: {
      id: 'billingAddress',
      label: 'Billing address',
      placeholder: Utils.placeholderRows(4),
    },
    billingNote: {
      id: 'billingNote',
      label: 'Billing note',
      placeholder: Utils.placeholderRows(5),
    },
    serviceTag: {
      id: 'serviceTag',
      label: 'Service tag',
      placeholder: ' ',
    },
    billingType: {
      id: 'billingType',
      label: 'Billing action',
      placeholder: ' ',
    },
    chargeCategoryId: {
      id: 'chargeCategoryId',
      label: 'Billing category',
      placeholder: ' ',
    },
    quantity: {
      id: 'quantity',
      label: 'Qty',
      placeholder: ' ',
    },
    price: {
      id: 'price',
      label: 'Rate',
      placeholder: ' ',
    },
    isTaxInclusive: {
      id: 'isTaxInclusive',
      label: 'Tax Inclusive',
      placeholder: ' ',
    },
    
    ticketNumber: {
      id: 'ticketNumber',
      label: 'Ticket number',
      placeholder: ' ',
    },
    grossWeight: {
      id: 'grossWeight',
      label: 'GROSS WEIGHT',
      placeholder: ' ',
      info: 'You can enter a maximum of 5 numbers with max 3 decimal places',
    },
    tareWeight: {
      id: 'tareWeight',
      label: 'TARE WEIGHT',
      placeholder: ' ',
    },
    nettWeight: {
      id: 'nettWeight',
      label: 'NETT WEIGHT',
      placeholder: ' ',
    },
    nonBillableBinWeight: {
      id: 'nonBillableBinWeight',
      label: 'NON-BILLABLE WEIGHT',
      placeholder: ' ',
    },
    billableWeight: {
      id: 'billableWeight',
      label: 'BILLABLE WEIGHT',
      placeholder: ' ',
    },
    remarks: {
      id: 'remarks',
      label: 'Remarks',
      placeholder: ' ',
    },
    weighBy: {
      id: 'weighBy',
      label: 'Weigh by',
      placeholder: ' ',
    },
    date: {
      id: 'date',
      label: 'Weigh bridge check in',
      placeholder: ' ',
    },
    weightChitOutDateTime: {
      id: 'weightChitOutDateTime',
      label: 'Weigh bridge check out',
      placeholder: ' ',
    },
}

export const rowSchema = yup.object().shape({
    billedDate: yup.date().nullable().label(rowFields.billedDate.label),
    invoiceDate: yup.date().nullable().label(rowFields.invoiceDate.label),
    invoiceNumber: yup.string().label(rowFields.invoiceNumber.label),
    accountBillingProfileId: yup.number().nullable().required().label(rowFields.accountBillingProfileId.label),
    paymentTermsId: yup.number().nullable().label(rowFields.paymentTermsId.label),
    
    // xeroPaymentTerm: yup.number().nullable().positive().min(0).label(rowFields.xeroPaymentTerm.label),
    xeroPaymentTerm: yup.number().when(['customFormParams.isConnectXero', 'xeroPaymentTermType'], (isConnectXero, xeroPaymentTermType) => {
      if(isConnectXero){
        if((xeroPaymentTermType == XeroPaymentTermType.OF_CURRENT_MONTH) || (xeroPaymentTermType == XeroPaymentTermType.OF_FOLLOWING_MONTH)){
          return yup.number().nullable().required().positive().min(0).max(31, rowFields.xeroPaymentTermType.error).label(rowFields.xeroPaymentTerm.label);
        } else {
          return yup.number().nullable().required().positive().min(0).label(rowFields.xeroPaymentTerm.label);
        }
      } else {
        return yup.number().nullable().positive().min(0).label(rowFields.xeroPaymentTerm.label);
      }
    }),
    xeroPaymentTermType: yup.number().when(['customFormParams.isConnectXero'], (isConnectXero) => {
      if(isConnectXero){
        return yup.number().nullable().required().positive().min(0).label(rowFields.xeroPaymentTermType.label);
      } else {
        return yup.number().nullable().label(rowFields.xeroPaymentTermType.label);
      }
    }),

    billingAddress: yup.string().label(rowFields.billingAddress.label),
    billingNote: yup.string().nullable().label(rowFields.billingNote.label),
    pricings: yup.array().of(yup.object().shape({
      serviceTag: yup.string().required().label(rowFields.serviceTag.label),
      billingType: yup.number().nullable().required().label(rowFields.billingType.label),
      chargeCategoryId: yup.number().nullable().required().label(rowFields.chargeCategoryId.label),
      quantity: yup.number().nullable().min(0).label(rowFields.quantity.label),
      price: yup.number().nullable().min(0).label(rowFields.price.label),

      isTaxInclusive: yup.bool().oneOf([true, false]),
      
      isExpanded: yup.bool().oneOf([true, false]),
      useBinWeightQuantity: yup.bool().oneOf([true, false]),
      ticketNumber: yup.string().nullable().label(rowFields.ticketNumber.label),
      // grossWeight: yup.number().nullable().min(0).label(rowFields.grossWeight.label),
      // grossWeight: yup.string().nullable().matches(Utils.decimalValidationInputText(), rowFields.grossWeight.info).label(rowFields.grossWeight.label),
      
      uom: yup.number().oneOf([ReportUOM.tons, ReportUOM.kg]),
      grossWeight: yup.number().when(['uom', 'useBinWeightQuantity'], (uom, useBinWeightQuantity, schema) => {
        if(useBinWeightQuantity){
          if (uom === ReportUOM.kg ) {
            return yup.number()
              .integer()
              .test('noDecimal', 'Decimal values are not allowed', val => {
                  if (val !== undefined && val !== null) {
                      const strVal = val.toString();
                      return strVal.indexOf('.') === -1;
                  }
                  return true;
              })
              .max(99999)
              .min(0)
              .nullable()
              .label(rowFields.grossWeight.label);

          } else {
            return yup.number()
              .typeError('Invalid ' + rowFields.grossWeight.label)
              .transform((value, originalValue) => {
                if (originalValue === undefined || originalValue === null || originalValue === '') {
                  return undefined;
                }
                return parseFloat(originalValue);
              })
              .test('maxDecimal', 'You can enter a maximum of 5 numbers with max 3 decimal places', val => {
                if (val === null || val === undefined) return true;
                  const strVal = val.toString();
                  const parts = strVal.split('.');
                if (parts.length === 1 || (parts.length === 2 && parts[1].length <= 3)) {
                    return true;
                }
                return false;
              })
              .max(99.999)
              .min(0)
              .nullable()
              .label(rowFields.grossWeight.label);
          }
        }
      }),

      tareWeight: yup.number().when(['uom', 'grossWeight', 'useBinWeightQuantity'], (uom, grossWeight, useBinWeightQuantity, schema) => {
        if(useBinWeightQuantity){
          if (uom === ReportUOM.kg ) {
            return yup.number()
              .integer()
              .test('noDecimal', 'Decimal values are not allowed', val => {
                  if (val !== undefined && val !== null) {
                      const strVal = val.toString();
                      return strVal.indexOf('.') === -1;
                  }
                  return true;
              })
              .max((Utils.parseFloat(grossWeight) > 0) ? grossWeight : 99999)
              .nullable()
              .min(0)
              .label(rowFields.tareWeight.label);
              
          } else {
            return yup.number()
              .typeError('Invalid ' + rowFields.tareWeight.label)
              .transform((value, originalValue) => {
                if (originalValue === undefined || originalValue === null || originalValue === '') {
                  return undefined;
                }
                return parseFloat(originalValue);
              })
              .test('maxDecimal', 'You can enter a maximum of 5 numbers with max 3 decimal places', val => {
                if (val === null || val === undefined) return true;
                  const strVal = val.toString();
                  const parts = strVal.split('.');
                if (parts.length === 1 || (parts.length === 2 && parts[1].length <= 3)) {
                    return true;
                }
                return false;
              })
              .max((Utils.parseFloat(grossWeight) > 0) ? grossWeight : 99.999)
              .nullable()
              .min(0)
              .label(rowFields.tareWeight.label);
          }
        }
      }),

      nettWeight: yup.number().when(['uom', 'useBinWeightQuantity'], (uom, useBinWeightQuantity, schema) => {
        if(useBinWeightQuantity){
          return yup.number().nullable().min(0).label(rowFields.nettWeight.label)
        } else {
          return yup.number().nullable().label(rowFields.nettWeight.label)
        }
      }),

      nonBillableBinWeight: yup.number().when(['uom', 'useBinWeightQuantity'], (uom, useBinWeightQuantity, schema) => {
        if(useBinWeightQuantity){
          if (uom === ReportUOM.kg ) {
            return yup.number()
              .integer()
              .test('noDecimal', 'Decimal values are not allowed', val => {
                  if (val !== undefined && val !== null) {
                      const strVal = val.toString();
                      return strVal.indexOf('.') === -1;
                  }
                  return true;
              })
              .min(0)
              .max(99999)
              .nullable()
              .label(rowFields.nonBillableBinWeight.label);
              
          } else {
            return yup.number()
              .typeError('Invalid ' + rowFields.nonBillableBinWeight.label)
              .transform((value, originalValue) => {
                if (originalValue === undefined || originalValue === null || originalValue === '') {
                  return undefined;
                }
                return parseFloat(originalValue);
              })
              .test('maxDecimal', 'You can enter a maximum of 5 numbers with max 3 decimal places', val => {
                if (val === null || val === undefined) return true;
                  const strVal = val.toString();
                  const parts = strVal.split('.');
                if (parts.length === 1 || (parts.length === 2 && parts[1].length <= 3)) {
                    return true;
                }
                return false;
              })
              .min(0)
              .max(99.999)
              .nullable()
              .label(rowFields.nonBillableBinWeight.label);
          }
        }
      }),

      billableWeight: yup.number().when(['uom', 'grossWeight', 'useBinWeightQuantity'], (uom, grossWeight, useBinWeightQuantity, schema) => {
        if(useBinWeightQuantity){
          if (uom === ReportUOM.kg ) {
            return yup.number()
              .integer()
              .test('noDecimal', 'Decimal values are not allowed', val => {
                  if (val !== undefined && val !== null) {
                      const strVal = val.toString();
                      return strVal.indexOf('.') === -1;
                  }
                  return true;
              })
              .max((Utils.parseFloat(grossWeight) > 0) ? grossWeight : 99999)
              .nullable()
              .min(0)
              .label(rowFields.billableWeight.label);
              
          } else {
            return yup.number()
              .typeError('Invalid ' + rowFields.billableWeight.label)
              .transform((value, originalValue) => {
                if (originalValue === undefined || originalValue === null || originalValue === '') {
                  return undefined;
                }
                return parseFloat(originalValue);
              })
              .test('maxDecimal', 'You can enter a maximum of 5 numbers with max 3 decimal places', val => {
                if (val === null || val === undefined) return true;
                  const strVal = val.toString();
                  const parts = strVal.split('.');
                if (parts.length === 1 || (parts.length === 2 && parts[1].length <= 3)) {
                    return true;
                }
                return false;
              })
              .max((Utils.parseFloat(grossWeight) > 0) ? grossWeight : 99.999)
              .nullable()
              .min(0)
              .label(rowFields.billableWeight.label);
          }
        }
      }),
      
      remarks: yup.string().nullable().label(rowFields.remarks.label),
      weighBy: yup.string().nullable().label(rowFields.weighBy.label),
      date: yup.date().nullable().label(rowFields.date.label),
      weightChitOutDateTime: yup.date().nullable().label(rowFields.weightChitOutDateTime.label),
      // time: yup.date().nullable().label(rowFields.time.label),
    })),
});

export const initialValues = {
  isInvoiced: false,
  isBillable: false,
  isPaid: false,
  billedDate: null,
  invoiceDate: null,
  invoiceNumber: '',
  accountBillingProfileId: null,
  accountBillingProfileName: '',
  paymentTermsId: null,
  paymentTermsName: '',
  xeroPaymentTerm: '0',
  xeroPaymentTermType: '0',
  billingAddress: '',
  billingNote: '',
  pricings: [
    {
      serviceTag: '',
      billingType: BillingTypes.Invoice,
      chargeCategoryId: null,
      chargeCategoryName: null,
      quantity: '',
      price: '',
      isTaxInclusive: false,
      invoiceTaxRate: null,
      purchaseTaxRate: null,
      expenseTaxRate: null,
      bundleServiceTag: '',
      binTypeId: null,
      wasteTypeId: null,
      serviceType: ServiceType.AdditionalService,
      useBinWeightQuantity: false,
      
      isExpanded: false,
      jobStepId: null,
      jobStep: null,
      ticketNumber: '',
      grossWeight: '',
      tareWeight: '',
      nettWeight: '',
      nonBillableBinWeight: '',
      billableWeight: '',
      remarks: '',
      weighBy: '',
      date: null,
      weightChitOutDateTime: null,
      // time: null,
      jobStepWeighChitCustomFields: [],
    }
  ],
  customFormParams: {
    accountBillingProfileItems: [],
    accountBillingProfileIsLoading: false,
    isConnectQuickbook: false,
    isConnectXero: false,
    quickBookAppId: 0,
    paymentTermsItems: [],
    paymentTermsIsLoading: false,
    chargeCategoryItems: [],
    chargeCategoryIsLoading: false,
    quickbookInvoiceId: null,
    xeroInvoiceId: null,
    quickbookPurchaseOrderId: null,
    quickbookExpenseId: null,

    billableBinWeight: '',
    jobStatusName: '',
    customerSiteName: '',
    jobTypeName: '',
    jobDate: '',
    remarks: '',
    billingName: '',
  }
};

export const prepareForm = (rowInitialValues, dataParams, data1, billingType, uom) => {
    let data = _.cloneDeep(data1);

    let customerItem = (data && data.customer) ? data.customer : null;

    let billingProfileItem = (customerItem && customerItem.billingProfile) ? customerItem.billingProfile : null;
    let accountBillingProfileId = (billingProfileItem && billingProfileItem.accountBillingProfileId) ? billingProfileItem.accountBillingProfileId : null;
    let accountBillingProfileName = (billingProfileItem && billingProfileItem.billingProfileName) ? billingProfileItem.billingProfileName : '';
    let accountBillingProfileItems = (billingProfileItem) ? [{
      value: accountBillingProfileId,
      title: accountBillingProfileName,
      item: billingProfileItem,
    }] : [];


    let isConnectQuickbook = (billingProfileItem && billingProfileItem.isConnectQuickbook) ? billingProfileItem.isConnectQuickbook : false;
    let isConnectXero = (billingProfileItem && billingProfileItem.isConnectXero) ? billingProfileItem.isConnectXero : false;
    
    let paymentTermsId = (customerItem && customerItem.quickBookDefaultTermId) ? customerItem.quickBookDefaultTermId : null;
    let paymentTermsName = (customerItem && customerItem.paymentTerms) ? customerItem.paymentTerms : '';
    let paymentTermsItems = (isConnectQuickbook) ? [{
      value: paymentTermsId,
      title: paymentTermsName,
      item: customerItem,
    }] : [];

    let quickBookApp = (billingProfileItem && billingProfileItem.quickBookApp) ? billingProfileItem.quickBookApp : null;
    let quickBookAppId = (quickBookApp && quickBookApp.quickBookAppId) ? quickBookApp.quickBookAppId : 0;
    let xeroPaymentTerm = (customerItem && customerItem.xeroPaymentTerm) ? customerItem.xeroPaymentTerm : '0';
    let xeroPaymentTermType = (customerItem && customerItem.xeroPaymentTermType) ? customerItem.xeroPaymentTermType.toString() : '0';
    let billingAddress = (customerItem && customerItem.billingAddress) ? customerItem.billingAddress : '';

    
    let chargeCategoryItems = [];
    let pricings = [];
    if(data && data.pricings && data.pricings.length > 0){
      let pricingList = data.pricings.sort((a, b) => a.sortOrder - b.sortOrder);

      // pricings = pricingList.filter(x => ((x.serviceType == ServiceType.StandardService) || (x.serviceType == ServiceType.BundleService)) && (x.billingType == billingType)).map((item, i) => {
      // pricings = pricingList.filter(x => (x.billingType.toString() == billingType.toString())).map((item, i) => {
      pricings = pricingList.map((item, i) => {
        let chargeCategoryObj = (item && item.chargeCategory) ? item.chargeCategory : null;
        let chargeCategoryId = (chargeCategoryObj && chargeCategoryObj.chargeCategoryId) ? chargeCategoryObj.chargeCategoryId : null;
        let chargeCategoryName = (chargeCategoryObj && chargeCategoryObj.chargeCategoryName) ? chargeCategoryObj.chargeCategoryName : '';

        if(chargeCategoryObj){
          let chargeCategoryItem = {
            value: chargeCategoryId,
            title: chargeCategoryName,
            item: chargeCategoryObj,
          }

          chargeCategoryItems = Utils.addToArray(chargeCategoryItems, chargeCategoryId, chargeCategoryItem)
        }
        
        
        let ticketNumber = '';
        let grossWeight = '';
        let tareWeight = '';
        let nettWeight = '';
        let billableWeight = '';
        let nonBillableBinWeight = '';
        let remarks = '';
        let weighBy = '';
        let weightChitDateTime = null;
        let weightChitOutDateTime = null;
        // let time = null;
        let jobStepWeighChitCustomFields = [];
        let jobStepId = (item.jobStepId) ? item.jobStepId : null;

        if(item && item.jobStep){
          ticketNumber = (item.jobStep && item.jobStep.weightChitTicketNumber) ? item.jobStep.weightChitTicketNumber : '';
          grossWeight = (item.jobStep && item.jobStep.binWeight) ? item.jobStep.binWeight : '';
          tareWeight = (item.jobStep && item.jobStep.tareBinWeight) ? item.jobStep.tareBinWeight : '';
          nettWeight = (item.jobStep && item.jobStep.netBinWeight) ? item.jobStep.netBinWeight : '';
          billableWeight = (item.jobStep && item.jobStep.billableBinWeight) ? item.jobStep.billableBinWeight : '';
          nonBillableBinWeight = (item.jobStep && item.jobStep.nonBillableBinWeight) ? item.jobStep.nonBillableBinWeight : '';
          remarks = (item.jobStep && item.jobStep.weightChitRemarks) ? item.jobStep.weightChitRemarks : '';
          weighBy = (item.jobStep && item.jobStep.binWeighBy) ? item.jobStep.binWeighBy : '';
          weightChitDateTime = (item.jobStep && item.jobStep.weightChitDateTime) ? new Date(item.jobStep.weightChitDateTime) : null;
          weightChitOutDateTime = (item.jobStep && item.jobStep.weightChitOutDateTime) ? new Date(item.jobStep.weightChitOutDateTime) : null;
          // date = weightChitDateTime ? new Date(weightChitDateTime) : null;
          // time = weightChitDateTime ? new Date(weightChitDateTime) : null;
          jobStepWeighChitCustomFields = (item.jobStep && item.jobStep.jobStepWeighChitCustomFields && item.jobStep.jobStepWeighChitCustomFields.length > 0) ? item.jobStep.jobStepWeighChitCustomFields : [];
        }

        let quantity = item.quantity;
        if(item.useBinWeightQuantity && billableWeight != ''){
          // let qty = Utils.smartConversionKgTons(billableWeight);
          let qty = Utils.convertUom(billableWeight, Utils.getUOM(), Utils.getBillingUOM())

          quantity = qty;
        }

        let isTaxInclusive = ((item.isTaxInclusive === false) || (item.isTaxInclusive === true)) ? item.isTaxInclusive : false;
        let invoiceTaxRate = (item && Utils.isNumber(item.invoiceTaxRate)) ? item.invoiceTaxRate : null;
        let purchaseTaxRate = (item && Utils.isNumber(item.purchaseTaxRate)) ? item.purchaseTaxRate : null;
        let expenseTaxRate = (item && Utils.isNumber(item.expenseTaxRate)) ? item.expenseTaxRate : null;

        return {
          jobPricingId: item.jobPricingId,
          serviceItemId: item.serviceItemId,
          serviceTag: item.serviceTag,
          description: item.serviceTag,
          billingType: item.billingType,
          chargeCategoryId: chargeCategoryId,
          chargeCategoryName: chargeCategoryName,
          quantity: quantity,
          price: item.price,

          isTaxInclusive: isTaxInclusive,
          invoiceTaxRate: invoiceTaxRate,
          purchaseTaxRate: purchaseTaxRate,
          expenseTaxRate: expenseTaxRate,

          bundleServiceTag: item.bundleServiceTag,
          binTypeId: item.binTypeId,
          wasteTypeId: item.wasteTypeId,
          serviceType: item.serviceType,
          useBinWeightQuantity: item.useBinWeightQuantity,
          isExpanded: false,
          jobStepId: jobStepId,
          jobStep: null,
          ticketNumber: ticketNumber,
          grossWeight: grossWeight,
          tareWeight: tareWeight,
          nettWeight: nettWeight,
          nonBillableBinWeight: nonBillableBinWeight,
          billableWeight: billableWeight,
          remarks: remarks,
          weighBy: weighBy,
          date: weightChitDateTime,
          weightChitOutDateTime: weightChitOutDateTime,
          // time: time,
          jobStepWeighChitCustomFields: jobStepWeighChitCustomFields,
          uom: uom,
        }
      });
    }

    
    let isBillable = (data) ? (data.isBillable === false || data.isBillable == true) ? data.isBillable : false : false;
    let isInvoiced = (data) ? (data.isInvoiced === false || data.isInvoiced == true) ? data.isInvoiced : false : false;
    let isPaid = (data) ? (data.isPaid === false || data.isPaid == true) ? data.isPaid : false : false;
    
    let quickbookInvoiceId = (data) ? (data && data.quickbookInvoiceId) ? data.quickbookInvoiceId : null : null;
    let xeroInvoiceId = (data) ? (data && data.xeroInvoiceId) ? data.xeroInvoiceId : null : null;
    let quickbookPurchaseOrderId = (data) ? (data && data.quickbookPurchaseOrderId) ? data.quickbookPurchaseOrderId : null : null;
    let quickbookExpenseId = (data) ? (data && data.quickbookExpenseId) ? data.quickbookExpenseId : null : null;

    let customFormParams = rowInitialValues.customFormParams;
    customFormParams.accountBillingProfileItems = accountBillingProfileItems;
    customFormParams.isConnectQuickbook = isConnectQuickbook;
    customFormParams.isConnectXero = isConnectXero;
    customFormParams.quickBookAppId = quickBookAppId;
    customFormParams.paymentTermsItems = paymentTermsItems;
    customFormParams.chargeCategoryItems = chargeCategoryItems;
    customFormParams.quickbookInvoiceId = quickbookInvoiceId;
    customFormParams.xeroInvoiceId = xeroInvoiceId;
    customFormParams.quickbookPurchaseOrderId = quickbookPurchaseOrderId;
    customFormParams.quickbookExpenseId = quickbookExpenseId;
    customFormParams.binTypeOutId = (data) ? (data && data.binTypeOutId) ? data.binTypeOutId : null : null;
    customFormParams.binTypeInId = (data) ? (data && data.binTypeInId) ? data.binTypeInId : null : null;
    customFormParams.wasteTypeOutId = (data) ? (data && data.wasteTypeOutId) ? data.wasteTypeOutId : null : null;
    customFormParams.wasteTypeInId = (data) ? (data && data.wasteTypeInId) ? data.wasteTypeInId : null : null;
    customFormParams.accountBillingProfileId = (data) ? (data && data.accountBillingProfileId) ? data.accountBillingProfileId : null : null;
    customFormParams.invoiceDate = (data) ? (data && data.invoiceDate) ? data.invoiceDate : new Date() : new Date();

    customFormParams.billableBinWeight = (data && data.billableBinWeight != '') ? data.billableBinWeight : '';
    customFormParams.jobStatusName = (data && data.jobStatusName != '') ? data.jobStatusName : '';
    customFormParams.customerSiteName = (data && data.customerSiteName != '') ? data.customerSiteName : '';
    customFormParams.jobTypeName = (data && data.jobTypeName != '') ? data.jobTypeName : '';
    customFormParams.jobDate = (data && data.jobDate != '') ? data.jobDate : '';
    customFormParams.remarks = (data && data.remarks != '') ? data.remarks : '';
    customFormParams.billingName = (data && data.customer && data.customer.billingName != '') ? data.customer.billingName : '';

    let rowValues = {
      isInvoiced: isInvoiced,
      isBillable: isBillable,
      isPaid: isPaid,
      billedDate: (dataParams && dataParams.row && dataParams.row.billedDate) ? new Date(dataParams.row.billedDate) : null,
      invoiceDate: (data && data.invoiceDate) ? new Date(data.invoiceDate) : new Date(),
      invoiceNumber: (data && data.invoiceNumber) ? data.invoiceNumber : '',
      accountBillingProfileId: accountBillingProfileId,
      accountBillingProfileName: accountBillingProfileName,
      paymentTermsId: paymentTermsId,
      paymentTermsName: paymentTermsName,
      xeroPaymentTerm: xeroPaymentTerm,
      xeroPaymentTermType: xeroPaymentTermType,
      billingAddress: billingAddress,
      billingNote: (data && data.billingNote) ? data.billingNote : '',
      pricings: pricings,
      customFormParams: customFormParams,
    };

    return rowValues;
}

export const prepareData = (form, dataParams) => {
    let pricings = _.cloneDeep(form.pricings);

    if(pricings && pricings.length > 0){
      for(let i = 0; i < pricings.length; i++){
        if(pricings[i] && pricings[i].useBinWeightQuantity){
          let weightChitDateTime = (pricings[i] && pricings[i].date) ? pricings[i].date : null;
          if(weightChitDateTime != null){
            let weightChitDate = moment(weightChitDateTime).utc().format(Utils.getAPIDateFormat());
            let weightChitTime = moment(weightChitDateTime).utc().format(Utils.getDefaultTimeFormat());
            weightChitDateTime = Utils.getLocalIsoDateTime(moment(weightChitDate + ' ' + weightChitTime, Utils.getAPIDateTimeFormat()))
          }
      
          let weightChitOutDateTime = (pricings[i] && pricings[i].weightChitOutDateTime) ? pricings[i].weightChitOutDateTime : null;
          if(weightChitOutDateTime != null){
            let weightChitOutDate = moment(weightChitOutDateTime).utc().format(Utils.getAPIDateFormat());
            let weightChitOutTime = moment(weightChitOutDateTime).utc().format(Utils.getDefaultTimeFormat());
            weightChitOutDateTime = Utils.getLocalIsoDateTime(moment(weightChitOutDate + ' ' + weightChitOutTime, Utils.getAPIDateTimeFormat()))
          }
      
          let jobStepWeighChitCustomFields = (pricings[i] && pricings[i].jobStepWeighChitCustomFields && pricings[i].jobStepWeighChitCustomFields.length > 0) ? pricings[i].jobStepWeighChitCustomFields : [];
          
          let jobStep = {
            jobStepId: pricings[i].jobStepId,
            jobPricingId: pricings[i].jobPricingId,
            weightChitTicketNumber: pricings[i].ticketNumber,
            binWeight: pricings[i].grossWeight,
            tareBinWeight: pricings[i].tareWeight,
            netBinWeight: pricings[i].nettWeight,
            billableBinWeight: pricings[i].billableWeight,
            nonBillableBinWeight: pricings[i].nonBillableBinWeight,
            binWeighBy: pricings[i].weighBy,
            weightChitRemarks: pricings[i].remarks,
            weightChitDateTime: weightChitDateTime,
            weightChitOutDateTime: weightChitOutDateTime,
            jobStepWeighChitCustomFields: jobStepWeighChitCustomFields,
          };

          pricings[i]['jobStep'] = jobStep;
          
          delete pricings[i].ticketNumber;
          delete pricings[i].grossWeight;
          delete pricings[i].tareWeight;
          delete pricings[i].nettWeight;
          delete pricings[i].billableWeight;
          delete pricings[i].remarks;
          delete pricings[i].isExpanded;
          delete pricings[i].jobStepWeighChitCustomFields;
        } else {
          delete pricings[i].jobStepWeighChitCustomFields;
        }
      }
    }

    let data = {
      jobId: dataParams.id,
      isBillable: form.isBillable,
      isInvoiced: form.isInvoiced,
      billedDate: form.billedDate,
      invoiceDate: form.invoiceDate,
      invoiceNumber: form.invoiceNumber,
      billingNote: form.billingNote,
      customer: {
        customerId: dataParams.row.customer.customerId,
        accountBillingProfileId: form.accountBillingProfileId,
        quickBookDefaultTermId: form.paymentTermsId,
        paymentTerms: form.xeroPaymentTerm,
        xeroPaymentTerm: form.xeroPaymentTerm,
        xeroPaymentTermType: form.xeroPaymentTermType,
        billingAddress: form.billingAddress,
      },
      pricings: pricings,
    };
    
    return data;
}

export const prepareDataForExpenseOUTJobType = (form, dataParams) => {
  let pricings = _.cloneDeep(form.pricings);

  if(pricings && pricings.length > 0){
    if(pricings.some(x => x.useBinWeightQuantity)){
      for(let i = 0; i < pricings.length; i++){
        if(pricings[i] && pricings[i].useBinWeightQuantity){
          let weightChitDateTime = (pricings[i] && pricings[i].date) ? pricings[i].date : null;
          if(weightChitDateTime != null){
            let weightChitDate = moment(weightChitDateTime).utc().format(Utils.getAPIDateFormat());
            let weightChitTime = moment(weightChitDateTime).utc().format(Utils.getDefaultTimeFormat());
            weightChitDateTime = Utils.getLocalIsoDateTime(moment(weightChitDate + ' ' + weightChitTime, Utils.getAPIDateTimeFormat()))
          }
      
          let weightChitOutDateTime = (pricings[i] && pricings[i].weightChitOutDateTime) ? pricings[i].weightChitOutDateTime : null;
          if(weightChitOutDateTime != null){
            let weightChitOutDate = moment(weightChitOutDateTime).utc().format(Utils.getAPIDateFormat());
            let weightChitOutTime = moment(weightChitOutDateTime).utc().format(Utils.getDefaultTimeFormat());
            weightChitOutDateTime = Utils.getLocalIsoDateTime(moment(weightChitOutDate + ' ' + weightChitOutTime, Utils.getAPIDateTimeFormat()))
          }
      
          let jobStepWeighChitCustomFields = (pricings[i] && pricings[i].jobStepWeighChitCustomFields && pricings[i].jobStepWeighChitCustomFields.length > 0) ? pricings[i].jobStepWeighChitCustomFields : [];
          
          let jobStep = {
            jobStepId: pricings[i].jobStepId,
            jobPricingId: pricings[i].jobPricingId,
            weightChitTicketNumber: pricings[i].ticketNumber,
            binWeight: pricings[i].grossWeight,
            tareBinWeight: pricings[i].tareWeight,
            netBinWeight: pricings[i].nettWeight,
            billableBinWeight: pricings[i].billableWeight,
            nonBillableBinWeight: pricings[i].nonBillableBinWeight,
            binWeighBy: pricings[i].weighBy,
            weightChitRemarks: pricings[i].remarks,
            weightChitDateTime: weightChitDateTime,
            weightChitOutDateTime: weightChitOutDateTime,
            jobStepWeighChitCustomFields: jobStepWeighChitCustomFields,
          };
  
          pricings[i]['jobStep'] = jobStep;
          
          delete pricings[i].ticketNumber;
          delete pricings[i].grossWeight;
          delete pricings[i].tareWeight;
          delete pricings[i].nettWeight;
          delete pricings[i].billableWeight;
          delete pricings[i].remarks;
          delete pricings[i].isExpanded;
          delete pricings[i].jobStepWeighChitCustomFields;
        } else {
          delete pricings[i].jobStepWeighChitCustomFields;
        }
      }

    } else {
      for(let i = 0; i < pricings.length; i++){
        if(i == pricings.reduce((acc, curr, index) => curr.billingType === BillingTypes.Expense ? index : acc, -1)){
          let weightChitDateTime = (pricings[i] && pricings[i].date) ? pricings[i].date : null;
          if(weightChitDateTime != null){
            let weightChitDate = moment(weightChitDateTime).utc().format(Utils.getAPIDateFormat());
            let weightChitTime = moment(weightChitDateTime).utc().format(Utils.getDefaultTimeFormat());
            weightChitDateTime = Utils.getLocalIsoDateTime(moment(weightChitDate + ' ' + weightChitTime, Utils.getAPIDateTimeFormat()))
          }
      
          let weightChitOutDateTime = (pricings[i] && pricings[i].weightChitOutDateTime) ? pricings[i].weightChitOutDateTime : null;
          if(weightChitOutDateTime != null){
            let weightChitOutDate = moment(weightChitOutDateTime).utc().format(Utils.getAPIDateFormat());
            let weightChitOutTime = moment(weightChitOutDateTime).utc().format(Utils.getDefaultTimeFormat());
            weightChitOutDateTime = Utils.getLocalIsoDateTime(moment(weightChitOutDate + ' ' + weightChitOutTime, Utils.getAPIDateTimeFormat()))
          }
      
          let jobStepWeighChitCustomFields = (pricings[i] && pricings[i].jobStepWeighChitCustomFields && pricings[i].jobStepWeighChitCustomFields.length > 0) ? pricings[i].jobStepWeighChitCustomFields : [];
          
          let jobStep = {
            jobStepId: pricings[i].jobStepId,
            jobPricingId: pricings[i].jobPricingId,
            weightChitTicketNumber: pricings[i].ticketNumber,
            binWeight: pricings[i].grossWeight,
            tareBinWeight: pricings[i].tareWeight,
            netBinWeight: pricings[i].nettWeight,
            billableBinWeight: pricings[i].billableWeight,
            nonBillableBinWeight: pricings[i].nonBillableBinWeight,
            binWeighBy: pricings[i].weighBy,
            weightChitRemarks: pricings[i].remarks,
            weightChitDateTime: weightChitDateTime,
            weightChitOutDateTime: weightChitOutDateTime,
            jobStepWeighChitCustomFields: jobStepWeighChitCustomFields,
          };
  
          pricings[i]['jobStep'] = jobStep;
          
          delete pricings[i].ticketNumber;
          delete pricings[i].grossWeight;
          delete pricings[i].tareWeight;
          delete pricings[i].nettWeight;
          delete pricings[i].billableWeight;
          delete pricings[i].remarks;
          delete pricings[i].isExpanded;
          delete pricings[i].jobStepWeighChitCustomFields;
        } else {
          delete pricings[i].jobStepWeighChitCustomFields;
        }
      }
    }
  }

  let data = {
    jobId: dataParams.id,
    isBillable: form.isBillable,
    isInvoiced: form.isInvoiced,
    billedDate: form.billedDate,
    invoiceDate: form.invoiceDate,
    invoiceNumber: form.invoiceNumber,
    billingNote: form.billingNote,
    customer: {
      customerId: dataParams.row.customer.customerId,
      accountBillingProfileId: form.accountBillingProfileId,
      quickBookDefaultTermId: form.paymentTermsId,
      paymentTerms: form.xeroPaymentTerm,
      xeroPaymentTerm: form.xeroPaymentTerm,
      xeroPaymentTermType: form.xeroPaymentTermType,
      billingAddress: form.billingAddress,
    },
    pricings: pricings,
  };
  
  return data;
}