import axios from 'axios';
import Utils from '../../utils/utils'


const { 
    REACT_APP_DOC_GEN_API_URL
} = process.env;


const API = "doc-generation-job";

const docGenApi = {

    uploadFile: async (file, accountId, path, fileName, ext, token, callback = null) => {
        let errorMessage = "";
        let status = 0;

        let fName = Utils.generateFileName(path, fileName);

        const formData = new FormData();
        formData.append('file', file, fName + '.' + ext);

        const URL = REACT_APP_DOC_GEN_API_URL + API + '/upload-file';

        const getResult = await axios.post(
            URL,
            formData,
            {
                headers: {
                    'UploadPath': 'dispatch/' + accountId + '/' + path + '/attachment/',
                    'Authorization': 'mWU89QAnk0ik'
                },
                onUploadProgress: function (progressEvent) {
                    const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                    
                    if (totalLength !== null) {
                        let progress = Math.round( (progressEvent.loaded * 100) / totalLength );

                        if(callback !== null){
                            callback(progress);
                        }
                    }
                }
            })
            .catch( error => {
                try {
                    status = error.response.status;

                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch(err) {
                    errorMessage = 'Service Unavailable!'
                }
            });
        return { data: getResult, errorMessage: errorMessage, status: status};
    },

    downloadFile: async (url) => {
        let errorMessage = "";
        let status = 0;

        const URL = url;

        const getResult = await axios({
            url: URL,
            method: 'GET',
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            }
        })
            .catch( error => {
                try {
                    status = error.response.status;

                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch(err) {
                    errorMessage = 'Service Unavailable!'
                }
            });
        return { data: getResult, errorMessage: errorMessage, status: status};
    },
    
}

export default docGenApi;