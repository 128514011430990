import React from "react";
import _ from "lodash";

import { 
    Dropdown,
    ButtonGroup,
    Spinner,
    Button,
} from "react-bootstrap-v5";

import Utils from "../../../../utils/utils";
import { CustomerSiteAvailability, JobAction } from '../../../../utils/enums';

const FooterActionButtons = (props) => {
    
    const actionButtons = () => {
        let {
            values,
        } = props.formOptions;

        
        let actions = Utils.getJobFormActionButtons(values);

        if(actions === null){
            return <Button 
                variant={'secondary'}
                disabled={true}
            >
                Dispatch Now
                {props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
            </Button>
        } else {
            return getDropdown(actions);
        }
    }

    const getTimeAvailability = (actions) => {
        let {
            values,
        } = props.formOptions;

        let timeAvailability = CustomerSiteAvailability.All;
        let isWeekend = Utils.isWeekend(Utils.weekDayNumb(props.formOptions));
        if(isWeekend){
            timeAvailability = values.customFormParams.weekendAvailability;
        } else {
            timeAvailability = values.customFormParams.weekdaysAvailability;
        }

        return timeAvailability;
    }

    const getDropdown = ({ main, dropdown }) => {
        let {
            validateForm,
            values,
        } = props.formOptions;

        let timeAvailability = getTimeAvailability();

        return <>
            {values.isActive && <Dropdown 
                as={ButtonGroup}
                onSelect={(eventKey, e) => {
                    if(values.isCopy && (eventKey == JobAction.Draft)){
                        if(props.onSaveCopyDraft){
                            props.onSaveCopyDraft(main, values);
                        }
                    } else {
                        validateForm().then((err) => {
                            if(_.isEmpty(err)){
                                if(props.onSave){
                                    props.onSave(eventKey, values);
                                }
                            } else {
                                let msg = 'You must fill in the required fields in form and in the billing side panel';
                                let keys = Object.keys(err);
                                if(keys && keys.length > 0){
                                    msg = err[keys[0]]
                                }
                                Utils.toast(msg, 'error');
                            }
                        })
                    }
                }}
            >
                <Button 
                    variant={'primary'}
                    disabled={props.isLoading || (timeAvailability == CustomerSiteAvailability.NotAvailable)}
                    onClick={(e) => {
                        validateForm().then((err) => {
                            if(_.isEmpty(err)){
                                if(props.onSave){
                                    props.onSave(main, values);
                                }
                            } else {
                                let msg = 'You must fill in the required fields in form and in the billing side panel';
                                let keys = Object.keys(err);
                                if(keys && keys.length > 0){
                                    msg = err[keys[0]]
                                }
                                Utils.toast(msg, 'error');
                            }
                        })
                    }}
                >
                    {Utils.getJobFormActionButtonName(main)}
                    {props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>

                {(dropdown && dropdown.length > 0) && <>
                    <Dropdown.Toggle
                        variant={'primary'}
                        disabled={props.isLoading || (timeAvailability == CustomerSiteAvailability.NotAvailable)}
                    />
                    <Dropdown.Menu variant={'primary'}>
                        {dropdown.map((act, i) => {
                            return <Dropdown.Item key={i} eventKey={act} active={false}>{Utils.getJobFormActionButtonName(act)}</Dropdown.Item>
                        })}
                    </Dropdown.Menu>
                </>}

            </Dropdown>}
        </>
    }


    return actionButtons()
};

export default FooterActionButtons;