import React from "react";
import PropTypes from "prop-types";
import { MAP, MARKER } from "react-google-maps/lib/constants";

class Spiderfy extends React.Component {
  static contextTypes = {
    [MAP]: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);
    const oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`)
    this.oms = new oms.OverlappingMarkerSpiderfier(this.context[MAP], {
      nearbyDistance: (props && props.nearbyDistance) ? props.nearbyDistance : 1,
      markersWontMove: true,
      markersWontHide: true,
      basicFormatEvents: true,
      keepSpiderfied: true,
      circleFootSeparation: (props && props.circleFootSeparation) ? props.circleFootSeparation : 50,
      spiralFootSeparation: (props && props.spiralFootSeparation) ? props.spiralFootSeparation : 50,
      legWeight: 0,
    });
    this.markerNodeMounted = this.markerNodeMounted.bind(this);

  }

  markerNodeMounted(ref) {
    try {
      const marker = ref.state[MARKER];

      this.oms.addMarker(marker); 
      window.google.maps.event.addListener(marker, "spider_click", (e) => {
        if (this.props.onSpiderClick) this.props.onSpiderClick(e, (marker && marker.data) ? marker.data : null);
      });
      window.google.maps.event.addListener(marker, "mouseover", (e) => {
        if (this.props.onSpiderMouseOver) this.props.onSpiderMouseOver(e, (marker && marker.data) ? marker.data : null);
      });
    } catch(e){}
  }
  

  render() {
    return (this.props.children && this.props.children.length > 0) 
      ? 
      this.props.children.map(child => React.cloneElement(child, { ref: this.markerNodeMounted }))
      :
      null
  }
}

export default Spiderfy;