import React from 'react';

import {
  IconButton,
  Menu
} from '@material-ui/core';


const IconsList = React.forwardRef((props, ref) => {
    const { color, onClick } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [items, setItemss] = React.useState((props.items && props.items.length > 0) ? props.items : []);


    React.useImperativeHandle(ref, () => ({
      hide() {
        setAnchorEl(null);
      }
    }));


    React.useEffect(() => {
      setItemss((props.items && props.items.length > 0) ? props.items : []); 
    });

    const handleClick = event => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    
    
    const setItems = () => {
      if(items && items.length > 0){
        return items.map((item, i) => {
          return <span key={i}>
            {
              item.button
              ?
              item.button
              :
              <>
                <IconButton aria-haspopup={item.children ? true : false} 
                  style={{ height: '50px'}}
                  color={color}
                  disabled={item.disabled}
                  onClick={(e) => {
                    if(item.children){
                      handleClick(e);
                    } else {
                      if(onClick){
                        onClick(item);
                      }
                    }
                  }}
                >
                  {item.icon}
                </IconButton>
                {item.children && <Menu
                  className="datatable-action-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <li onClick={(e) => { e.preventDefault(); }}>{item.children}</li>
                </Menu>}
              </>
            }
            
          </span>
        });
      }
    };


    return (
        <div className="icon-list-component">
            {setItems()}
        </div>
    );
  });

  export default IconsList;