import React from "react";
import _ from "lodash";

import { 
    Row,
    Col,
    Form,
} from "react-bootstrap-v5";

import RTextEditor from '../../../components/OLD/RichTextEditor/RTextEditor.jsx';
import DraggableListByChildren from '../../../components/draggableList/DraggableListByChildren';

import Utils from "../../../utils/utils";

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet } from '../../../../setup/redux/dispatch/actions'


class DeliveryOrderForm extends React.Component {
  
    constructor(props) {
        super(props);
    
        this.state = {
            
        };
    }


    body = () => {
        let {
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            values,
            errors,
        } = this.props.formOptions;
        
        return <>
            <Row className={'mb-10'}>
                <Col xs={12} md={2} xxl={1}>Is visible</Col>
                <Col xs={12} md={4} lg={3} xxl={2}>Default label name</Col>
                <Col xs={12} md={true}>New label name</Col>
                <Col xs={12}>
                    <hr />
                </Col>
            </Row>
            <Row className={'mb-10'}>
                <Col xs={12}>
                    <h5 className={'mb-0'}>JOB DETAILS</h5>
                </Col>
                <Col xs={12}>
                    <hr />
                </Col>
            </Row>

            <DraggableListByChildren 
                droppableId={'deliveryOrderJobDetailsDroppable'}
                onDragEnd={(items, sourceIndex, destinationIndex) => {
                    if(items && items.length > 0){
                        items.forEach((item, i) => {
                            if(item && item.itemData && item.itemData.length > 0){
                                item.itemData.forEach((itemD, j) => {
                                    setFieldValue(itemD, item.sortOrder);
                                });
                            }
                        });
                    }
                }}
            >
                {/* <Form.Group as={Row} sortOrder={values.deliveryOrderLabelDeliveryOrderSortOrder} itemData={['deliveryOrderLabelDeliveryOrderSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.deliveryOrderLabelDeliveryOrder.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.deliveryOrderLabelDeliveryOrder.id}
                            placeholder={this.props.fields.deliveryOrderLabelDeliveryOrder.placeholder}
                            value={values.deliveryOrderLabelDeliveryOrder}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderLabelDeliveryOrder}
                        />
                        <Form.Control.Feedback type="invalid">{errors.deliveryOrderLabelDeliveryOrder}</Form.Control.Feedback>
                    </Col>
                </Form.Group> */}
                <Form.Group as={Row} sortOrder={'0'} itemData={['deliveryOrderLabelDeliveryOrderSortOrder']} draggable={false}>
                    <Col xs={12} md={2} xxl={1}>
                        
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.deliveryOrderLabelDeliveryOrder.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.deliveryOrderLabelDeliveryOrder.id}
                            placeholder={this.props.fields.deliveryOrderLabelDeliveryOrder.placeholder}
                            value={values.deliveryOrderLabelDeliveryOrder}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderLabelDeliveryOrder}
                        />
                        <Form.Control.Feedback type="invalid">{errors.deliveryOrderLabelDeliveryOrder}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.deliveryOrderShowCustomerNameSortOrder} itemData={['deliveryOrderShowCustomerNameSortOrder', 'deliveryOrderLabelCustomerNameSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.deliveryOrderShowCustomerName.id}
                            label={null}
                            checked={values.deliveryOrderShowCustomerName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderShowCustomerName}
                            feedback={errors.deliveryOrderShowCustomerName}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.deliveryOrderLabelCustomerName.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.deliveryOrderLabelCustomerName.id}
                            placeholder={this.props.fields.deliveryOrderLabelCustomerName.placeholder}
                            value={values.deliveryOrderLabelCustomerName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderLabelCustomerName}
                        />
                        <Form.Control.Feedback type="invalid">{errors.deliveryOrderLabelCustomerName}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.deliveryOrderShowCustomerAddressSortOrder} itemData={['deliveryOrderShowCustomerAddressSortOrder', 'deliveryOrderLabelCustomerAddressSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.deliveryOrderShowCustomerAddress.id}
                            label={null}
                            checked={values.deliveryOrderShowCustomerAddress}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderShowCustomerAddress}
                            feedback={errors.deliveryOrderShowCustomerAddress}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.deliveryOrderLabelCustomerAddress.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.deliveryOrderLabelCustomerAddress.id}
                            placeholder={this.props.fields.deliveryOrderLabelCustomerAddress.placeholder}
                            value={values.deliveryOrderLabelCustomerAddress}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderLabelCustomerAddress}
                        />
                        <Form.Control.Feedback type="invalid">{errors.deliveryOrderLabelCustomerAddress}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.deliveryOrderShowProjectSiteSortOrder} itemData={['deliveryOrderShowProjectSiteSortOrder', 'deliveryOrderLabelProjectSiteSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.deliveryOrderShowProjectSite.id}
                            label={null}
                            checked={values.deliveryOrderShowProjectSite}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderShowProjectSite}
                            feedback={errors.deliveryOrderShowProjectSite}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.deliveryOrderLabelProjectSite.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.deliveryOrderLabelProjectSite.id}
                            placeholder={this.props.fields.deliveryOrderLabelProjectSite.placeholder}
                            value={values.deliveryOrderLabelProjectSite}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderLabelProjectSite}
                        />
                        <Form.Control.Feedback type="invalid">{errors.deliveryOrderLabelProjectSite}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.deliveryOrderShowJobTypeSortOrder} itemData={['deliveryOrderShowJobTypeSortOrder', 'deliveryOrderLabelJobTypeSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.deliveryOrderShowJobType.id}
                            label={null}
                            checked={values.deliveryOrderShowJobType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderShowJobType}
                            feedback={errors.deliveryOrderShowJobType}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.deliveryOrderLabelJobType.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.deliveryOrderLabelJobType.id}
                            placeholder={this.props.fields.deliveryOrderLabelJobType.placeholder}
                            value={values.deliveryOrderLabelJobType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderLabelJobType}
                        />
                        <Form.Control.Feedback type="invalid">{errors.deliveryOrderLabelJobType}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.deliveryOrderShowJobCompletionSortOrder} itemData={['deliveryOrderShowJobCompletionSortOrder', 'deliveryOrderLabelJobCompletionSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.deliveryOrderShowJobCompletion.id}
                            label={null}
                            checked={values.deliveryOrderShowJobCompletion}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderShowJobCompletion}
                            feedback={errors.deliveryOrderShowJobCompletion}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.deliveryOrderLabelJobCompletion.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.deliveryOrderLabelJobCompletion.id}
                            placeholder={this.props.fields.deliveryOrderLabelJobCompletion.placeholder}
                            value={values.deliveryOrderLabelJobCompletion}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderLabelJobCompletion}
                        />
                        <Form.Control.Feedback type="invalid">{errors.deliveryOrderLabelJobCompletion}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.deliveryOrderShowDriverSortOrder} itemData={['deliveryOrderShowDriverSortOrder', 'deliveryOrderLabelDriverSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.deliveryOrderShowDriver.id}
                            label={null}
                            checked={values.deliveryOrderShowDriver}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderShowDriver}
                            feedback={errors.deliveryOrderShowDriver}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.deliveryOrderLabelDriver.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.deliveryOrderLabelDriver.id}
                            placeholder={this.props.fields.deliveryOrderLabelDriver.placeholder}
                            value={values.deliveryOrderLabelDriver}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderLabelDriver}
                        />
                        <Form.Control.Feedback type="invalid">{errors.deliveryOrderLabelDriver}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.deliveryOrderShowVehicleSortOrder} itemData={['deliveryOrderShowVehicleSortOrder', 'deliveryOrderLabelVehicleSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.deliveryOrderShowVehicle.id}
                            label={null}
                            checked={values.deliveryOrderShowVehicle}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderShowVehicle}
                            feedback={errors.deliveryOrderShowVehicle}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.deliveryOrderLabelVehicle.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.deliveryOrderLabelVehicle.id}
                            placeholder={this.props.fields.deliveryOrderLabelVehicle.placeholder}
                            value={values.deliveryOrderLabelVehicle}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderLabelVehicle}
                        />
                        <Form.Control.Feedback type="invalid">{errors.deliveryOrderLabelVehicle}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.deliveryOrderShowBinINSortOrder} itemData={['deliveryOrderShowBinINSortOrder', 'deliveryOrderLabelBinINSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.deliveryOrderShowBinIN.id}
                            label={null}
                            checked={values.deliveryOrderShowBinIN}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderShowBinIN}
                            feedback={errors.deliveryOrderShowBinIN}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.deliveryOrderLabelBinIN.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.deliveryOrderLabelBinIN.id}
                            placeholder={this.props.fields.deliveryOrderLabelBinIN.placeholder}
                            value={values.deliveryOrderLabelBinIN}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderLabelBinIN}
                        />
                        <Form.Control.Feedback type="invalid">{errors.deliveryOrderLabelBinIN}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.deliveryOrderShowBinOUTSortOrder} itemData={['deliveryOrderShowBinOUTSortOrder', 'deliveryOrderLabelBinOUTSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.deliveryOrderShowBinOUT.id}
                            label={null}
                            checked={values.deliveryOrderShowBinOUT}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderShowBinOUT}
                            feedback={errors.deliveryOrderShowBinOUT}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.deliveryOrderLabelBinOUT.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.deliveryOrderLabelBinOUT.id}
                            placeholder={this.props.fields.deliveryOrderLabelBinOUT.placeholder}
                            value={values.deliveryOrderLabelBinOUT}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderLabelBinOUT}
                        />
                        <Form.Control.Feedback type="invalid">{errors.deliveryOrderLabelBinOUT}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.deliveryOrderShowSiteContactsSortOrder} itemData={['deliveryOrderShowSiteContactsSortOrder', 'deliveryOrderLabelSiteContactsSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.deliveryOrderShowSiteContacts.id}
                            label={null}
                            checked={values.deliveryOrderShowSiteContacts}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderShowSiteContacts}
                            feedback={errors.deliveryOrderShowSiteContacts}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.deliveryOrderLabelSiteContacts.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.deliveryOrderLabelSiteContacts.id}
                            placeholder={this.props.fields.deliveryOrderLabelSiteContacts.placeholder}
                            value={values.deliveryOrderLabelSiteContacts}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderLabelSiteContacts}
                        />
                        <Form.Control.Feedback type="invalid">{errors.deliveryOrderLabelSiteContacts}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.deliveryOrderShowPaymentTermsSortOrder} itemData={['deliveryOrderShowPaymentTermsSortOrder', 'deliveryOrderLabelPaymentTermsSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.deliveryOrderShowPaymentTerms.id}
                            label={null}
                            checked={values.deliveryOrderShowPaymentTerms}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderShowPaymentTerms}
                            feedback={errors.deliveryOrderShowPaymentTerms}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.deliveryOrderLabelPaymentTerms.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.deliveryOrderLabelPaymentTerms.id}
                            placeholder={this.props.fields.deliveryOrderLabelPaymentTerms.placeholder}
                            value={values.deliveryOrderLabelPaymentTerms}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderLabelPaymentTerms}
                        />
                        <Form.Control.Feedback type="invalid">{errors.deliveryOrderLabelPaymentTerms}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.deliveryOrderShowCollectedCashSortOrder} itemData={['deliveryOrderShowCollectedCashSortOrder', 'deliveryOrderLabelCollectedCashSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.deliveryOrderShowCollectedCash.id}
                            label={null}
                            checked={values.deliveryOrderShowCollectedCash}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderShowCollectedCash}
                            feedback={errors.deliveryOrderShowCollectedCash}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.deliveryOrderLabelCollectedCash.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.deliveryOrderLabelCollectedCash.id}
                            placeholder={this.props.fields.deliveryOrderLabelCollectedCash.placeholder}
                            value={values.deliveryOrderLabelCollectedCash}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderLabelCollectedCash}
                        />
                        <Form.Control.Feedback type="invalid">{errors.deliveryOrderLabelCollectedCash}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
            </DraggableListByChildren>
            

            <Row className={'mt-15 mb-10'}>
                <Col xs={12}>
                    <h5 className={'mb-0'}>SERVICE TABLE</h5>
                </Col>
                <Col xs={12}>
                    <hr />
                </Col>
            </Row>

            <DraggableListByChildren 
                droppableId={'deliveryOrderServiceTableDroppable'}
                onDragEnd={(items, sourceIndex, destinationIndex) => {
                    if(items && items.length > 0){
                        items.forEach((item, i) => {
                            if(item && item.itemData && item.itemData.length > 0){
                                item.itemData.forEach((itemD, j) => {
                                    setFieldValue(itemD, item.sortOrder);
                                });
                            }
                        });
                    }
                }}
            >
                <Form.Group as={Row} sortOrder={values.deliveryOrderShowStandardServicesBinTypeSortOrder} itemData={['deliveryOrderShowStandardServicesBinTypeSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.deliveryOrderShowStandardServicesBinType.id}
                            label={null}
                            checked={values.deliveryOrderShowStandardServicesBinType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderShowStandardServicesBinType}
                            feedback={errors.deliveryOrderShowStandardServicesBinType}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.deliveryOrderLabelStandardServicesBinType.label}</Form.Label>
                    <Col xs={12} md={true}>
                        {/* <Form.Control
                            type="text"
                            id={this.props.fields.deliveryOrderLabelStandardServicesBinType.id}
                            placeholder={this.props.fields.deliveryOrderLabelStandardServicesBinType.placeholder}
                            value={values.deliveryOrderLabelStandardServicesBinType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderLabelStandardServicesBinType}
                        />
                        <Form.Control.Feedback type="invalid">{errors.deliveryOrderLabelStandardServicesBinType}</Form.Control.Feedback> */}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.deliveryOrderShowStandardServicesWasteTypeSortOrder} itemData={['deliveryOrderShowStandardServicesWasteTypeSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.deliveryOrderShowStandardServicesWasteType.id}
                            label={null}
                            checked={values.deliveryOrderShowStandardServicesWasteType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderShowStandardServicesWasteType}
                            feedback={errors.deliveryOrderShowStandardServicesWasteType}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.deliveryOrderLabelStandardServicesWasteType.label}</Form.Label>
                    <Col xs={12} md={true}>
                        {/* <Form.Control
                            type="text"
                            id={this.props.fields.deliveryOrderLabelStandardServicesWasteType.id}
                            placeholder={this.props.fields.deliveryOrderLabelStandardServicesWasteType.placeholder}
                            value={values.deliveryOrderLabelStandardServicesWasteType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderLabelStandardServicesWasteType}
                        />
                        <Form.Control.Feedback type="invalid">{errors.deliveryOrderLabelStandardServicesWasteType}</Form.Control.Feedback> */}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.deliveryOrderShowStandardServicesQtySortOrder} itemData={['deliveryOrderShowStandardServicesQtySortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.deliveryOrderShowStandardServicesQty.id}
                            label={null}
                            checked={values.deliveryOrderShowStandardServicesQty}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderShowStandardServicesQty}
                            feedback={errors.deliveryOrderShowStandardServicesQty}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.deliveryOrderLabelStandardServicesQty.label}</Form.Label>
                    <Col xs={12} md={true}>
                        {/* <Form.Control
                            type="text"
                            id={this.props.fields.deliveryOrderLabelStandardServicesQty.id}
                            placeholder={this.props.fields.deliveryOrderLabelStandardServicesQty.placeholder}
                            value={values.deliveryOrderLabelStandardServicesQty}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderLabelStandardServicesQty}
                        />
                        <Form.Control.Feedback type="invalid">{errors.deliveryOrderLabelStandardServicesQty}</Form.Control.Feedback> */}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.deliveryOrderShowStandardServicesRateSortOrder} itemData={['deliveryOrderShowStandardServicesRateSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.deliveryOrderShowStandardServicesRate.id}
                            label={null}
                            checked={values.deliveryOrderShowStandardServicesRate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderShowStandardServicesRate}
                            feedback={errors.deliveryOrderShowStandardServicesRate}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.deliveryOrderLabelStandardServicesRate.label}</Form.Label>
                    <Col xs={12} md={true}>
                        {/* <Form.Control
                            type="text"
                            id={this.props.fields.deliveryOrderLabelStandardServicesRate.id}
                            placeholder={this.props.fields.deliveryOrderLabelStandardServicesRate.placeholder}
                            value={values.deliveryOrderLabelStandardServicesRate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderLabelStandardServicesRate}
                        />
                        <Form.Control.Feedback type="invalid">{errors.deliveryOrderLabelStandardServicesRate}</Form.Control.Feedback> */}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.deliveryOrderShowStandardServicesPriceSortOrder} itemData={['deliveryOrderShowStandardServicesPriceSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.deliveryOrderShowStandardServicesPrice.id}
                            label={null}
                            checked={values.deliveryOrderShowStandardServicesPrice}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderShowStandardServicesPrice}
                            feedback={errors.deliveryOrderShowStandardServicesPrice}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.deliveryOrderLabelStandardServicesPrice.label}</Form.Label>
                    <Col xs={12} md={true}>
                        {/* <Form.Control
                            type="text"
                            id={this.props.fields.deliveryOrderLabelStandardServicesPrice.id}
                            placeholder={this.props.fields.deliveryOrderLabelStandardServicesPrice.placeholder}
                            value={values.deliveryOrderLabelStandardServicesPrice}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderLabelStandardServicesPrice}
                        />
                        <Form.Control.Feedback type="invalid">{errors.deliveryOrderLabelStandardServicesPrice}</Form.Control.Feedback> */}
                    </Col>
                </Form.Group>
            </DraggableListByChildren>


            <Row className={'mt-10 mb-10'}>
                <Col xs={12} className={'d-flex align-items-center'}>
                    <Form.Check
                        type="switch"
                        id={this.props.fields.deliveryOrderShowAdditionalServices.id}
                        label={this.props.fields.deliveryOrderShowAdditionalServices.label}
                        checked={values.deliveryOrderShowAdditionalServices}
                        onBlur={handleBlur}
                        onChange={async (e) => {
                            await setFieldValue('deliveryOrderShowAdditionalServices', e.target.checked);

                            await setFieldValue('deliveryOrderShowAdditionalServicesDescription', e.target.checked);
                            await setFieldValue('deliveryOrderShowAdditionalServicesQty', e.target.checked);
                            await setFieldValue('deliveryOrderShowAdditionalServicesRate', e.target.checked);
                            await setFieldValue('deliveryOrderShowAdditionalServicesPrice', e.target.checked);
                        }}
                        isInvalid={!!errors.deliveryOrderShowAdditionalServices}
                        feedback={errors.deliveryOrderShowAdditionalServices}
                    />
                </Col>
            </Row>

            <DraggableListByChildren 
                droppableId={'deliveryOrderServiceTableAdditionalDroppable'}
                onDragEnd={(items, sourceIndex, destinationIndex) => {
                    if(items && items.length > 0){
                        items.forEach((item, i) => {
                            if(item && item.itemData && item.itemData.length > 0){
                                item.itemData.forEach((itemD, j) => {
                                    setFieldValue(itemD, item.sortOrder);
                                });
                            }
                        });
                    }
                }}
            >
                <Form.Group as={Row} sortOrder={values.deliveryOrderShowAdditionalServicesDescriptionSortOrder} itemData={['deliveryOrderShowAdditionalServicesDescriptionSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.deliveryOrderShowAdditionalServicesDescription.id}
                            label={null}
                            checked={values.deliveryOrderShowAdditionalServicesDescription}
                            onBlur={handleBlur}
                            onChange={async (e) => {
                                if(e.target.checked){
                                    await setFieldValue('deliveryOrderShowAdditionalServicesDescription', e.target.checked);
                                    await setFieldValue('deliveryOrderShowAdditionalServices', true);
                                } else {
                                    await setFieldValue('deliveryOrderShowAdditionalServicesDescription', e.target.checked);
                                    
                                    if(
                                        !values.deliveryOrderShowAdditionalServicesQty &&
                                        !values.deliveryOrderShowAdditionalServicesRate &&
                                        !values.deliveryOrderShowAdditionalServicesPrice
                                    ){
                                        await setFieldValue('deliveryOrderShowAdditionalServices', false);
                                    }
                                }
                            }}
                            isInvalid={!!errors.deliveryOrderShowAdditionalServicesDescription}
                            feedback={errors.deliveryOrderShowAdditionalServicesDescription}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.deliveryOrderLabelAdditionalServicesDescription.label}</Form.Label>
                    <Col xs={12} md={true}>
                        {/* <Form.Control
                            type="text"
                            id={this.props.fields.deliveryOrderLabelAdditionalServicesDescription.id}
                            placeholder={this.props.fields.deliveryOrderLabelAdditionalServicesDescription.placeholder}
                            value={values.deliveryOrderLabelAdditionalServicesDescription}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderLabelAdditionalServicesDescription}
                        />
                        <Form.Control.Feedback type="invalid">{errors.deliveryOrderLabelAdditionalServicesDescription}</Form.Control.Feedback> */}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.deliveryOrderShowAdditionalServicesQtySortOrder} itemData={['deliveryOrderShowAdditionalServicesQtySortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.deliveryOrderShowAdditionalServicesQty.id}
                            label={null}
                            checked={values.deliveryOrderShowAdditionalServicesQty}
                            onBlur={handleBlur}
                            onChange={async (e) => {
                                if(e.target.checked){
                                    await setFieldValue('deliveryOrderShowAdditionalServicesQty', e.target.checked);
                                    await setFieldValue('deliveryOrderShowAdditionalServices', true);
                                } else {
                                    await setFieldValue('deliveryOrderShowAdditionalServicesQty', e.target.checked);

                                    if(
                                        !values.deliveryOrderShowAdditionalServicesDescription &&
                                        !values.deliveryOrderShowAdditionalServicesRate &&
                                        !values.deliveryOrderShowAdditionalServicesPrice
                                    ){
                                        await setFieldValue('deliveryOrderShowAdditionalServices', false);
                                    }
                                }
                            }}
                            isInvalid={!!errors.deliveryOrderShowAdditionalServicesQty}
                            feedback={errors.deliveryOrderShowAdditionalServicesQty}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.deliveryOrderLabelAdditionalServicesQty.label}</Form.Label>
                    <Col xs={12} md={true}>
                        {/* <Form.Control
                            type="text"
                            id={this.props.fields.deliveryOrderLabelAdditionalServicesQty.id}
                            placeholder={this.props.fields.deliveryOrderLabelAdditionalServicesQty.placeholder}
                            value={values.deliveryOrderLabelAdditionalServicesQty}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderLabelAdditionalServicesQty}
                        />
                        <Form.Control.Feedback type="invalid">{errors.deliveryOrderLabelAdditionalServicesQty}</Form.Control.Feedback> */}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.deliveryOrderShowAdditionalServicesRateSortOrder} itemData={['deliveryOrderShowAdditionalServicesRateSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.deliveryOrderShowAdditionalServicesRate.id}
                            label={null}
                            checked={values.deliveryOrderShowAdditionalServicesRate}
                            onBlur={handleBlur}
                            onChange={async (e) => {
                                if(e.target.checked){
                                    await setFieldValue('deliveryOrderShowAdditionalServicesRate', e.target.checked);
                                    await setFieldValue('deliveryOrderShowAdditionalServices', true);
                                } else {
                                    await setFieldValue('deliveryOrderShowAdditionalServicesRate', e.target.checked);

                                    if(
                                        !values.deliveryOrderShowAdditionalServicesDescription &&
                                        !values.deliveryOrderShowAdditionalServicesQty &&
                                        !values.deliveryOrderShowAdditionalServicesPrice
                                    ){
                                        await setFieldValue('deliveryOrderShowAdditionalServices', false);
                                    }
                                }
                            }}
                            isInvalid={!!errors.deliveryOrderShowAdditionalServicesRate}
                            feedback={errors.deliveryOrderShowAdditionalServicesRate}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.deliveryOrderLabelAdditionalServicesRate.label}</Form.Label>
                    <Col xs={12} md={true}>
                        {/* <Form.Control
                            type="text"
                            id={this.props.fields.deliveryOrderLabelAdditionalServicesRate.id}
                            placeholder={this.props.fields.deliveryOrderLabelAdditionalServicesRate.placeholder}
                            value={values.deliveryOrderLabelAdditionalServicesRate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderLabelAdditionalServicesRate}
                        />
                        <Form.Control.Feedback type="invalid">{errors.deliveryOrderLabelAdditionalServicesRate}</Form.Control.Feedback> */}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.deliveryOrderShowAdditionalServicesPriceSortOrder} itemData={['deliveryOrderShowAdditionalServicesPriceSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.deliveryOrderShowAdditionalServicesPrice.id}
                            label={null}
                            checked={values.deliveryOrderShowAdditionalServicesPrice}
                            onBlur={handleBlur}
                            onChange={async (e) => {
                                if(e.target.checked){
                                    await setFieldValue('deliveryOrderShowAdditionalServicesPrice', e.target.checked);
                                    await setFieldValue('deliveryOrderShowAdditionalServices', true);
                                } else {
                                    await setFieldValue('deliveryOrderShowAdditionalServicesPrice', e.target.checked);

                                    if(
                                        !values.deliveryOrderShowAdditionalServicesDescription &&
                                        !values.deliveryOrderShowAdditionalServicesQty &&
                                        !values.deliveryOrderShowAdditionalServicesRate
                                    ){
                                        await setFieldValue('deliveryOrderShowAdditionalServices', false);
                                    }
                                }
                            }}
                            isInvalid={!!errors.deliveryOrderShowAdditionalServicesPrice}
                            feedback={errors.deliveryOrderShowAdditionalServicesPrice}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.deliveryOrderLabelAdditionalServicesPrice.label}</Form.Label>
                    <Col xs={12} md={true}>
                        {/* <Form.Control
                            type="text"
                            id={this.props.fields.deliveryOrderLabelAdditionalServicesPrice.id}
                            placeholder={this.props.fields.deliveryOrderLabelAdditionalServicesPrice.placeholder}
                            value={values.deliveryOrderLabelAdditionalServicesPrice}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderLabelAdditionalServicesPrice}
                        />
                        <Form.Control.Feedback type="invalid">{errors.deliveryOrderLabelAdditionalServicesPrice}</Form.Control.Feedback> */}
                    </Col>
                </Form.Group>
            </DraggableListByChildren>
            
            

            <Row className={'mt-15 mb-10'}>
                <Col xs={12}>
                    <h5 className={'mb-0'}>OTHERS</h5>
                </Col>
                <Col xs={12}>
                    <hr />
                </Col>
            </Row>
            
            <DraggableListByChildren 
                droppableId={'deliveryOrderOtherDroppable'}
                onDragEnd={(items, sourceIndex, destinationIndex) => {
                    if(items && items.length > 0){
                        items.forEach((item, i) => {
                            if(item && item.itemData && item.itemData.length > 0){
                                item.itemData.forEach((itemD, j) => {
                                    setFieldValue(itemD, item.sortOrder);
                                });
                            }
                        });
                    }
                }}
            >
                {/* <Form.Group as={Row} sortOrder={values.deliveryOrderShowSignatureSortOrder} itemData={['deliveryOrderShowSignatureSortOrder']}> */}
                <Form.Group as={Row} sortOrder={"1"} itemData={['deliveryOrderShowSignatureSortOrder']} draggable={false}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.deliveryOrderShowSignature.id}
                            label={null}
                            checked={values.deliveryOrderShowSignature}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderShowSignature}
                            feedback={errors.deliveryOrderShowSignature}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.deliveryOrderShowSignature.label}</Form.Label>
                    <Col xs={12} md={true}>
                        
                    </Col>
                </Form.Group>
                {/* <Form.Group as={Row} sortOrder={values.deliveryOrderShowProofOfDeliverySortOrder} itemData={['deliveryOrderShowProofOfDeliverySortOrder']}> */}
                <Form.Group as={Row} sortOrder={"2"} itemData={['deliveryOrderShowProofOfDeliverySortOrder']} draggable={false}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.deliveryOrderShowProofOfDelivery.id}
                            label={null}
                            checked={values.deliveryOrderShowProofOfDelivery}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderShowProofOfDelivery}
                            feedback={errors.deliveryOrderShowProofOfDelivery}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.deliveryOrderLabelProofOfDelivery.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.deliveryOrderLabelProofOfDelivery.id}
                            placeholder={this.props.fields.deliveryOrderLabelProofOfDelivery.placeholder}
                            value={values.deliveryOrderLabelProofOfDelivery}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderLabelProofOfDelivery}
                        />
                        <Form.Control.Feedback type="invalid">{errors.deliveryOrderLabelProofOfDelivery}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                {/* <Form.Group as={Row} sortOrder={values.deliveryOrderShowMessageToCustomerSortOrder} itemData={['deliveryOrderShowMessageToCustomerSortOrder']}> */}
                <Form.Group as={Row} sortOrder={"3"} itemData={['deliveryOrderShowMessageToCustomerSortOrder']} draggable={false}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.deliveryOrderShowMessageToCustomer.id}
                            label={null}
                            checked={values.deliveryOrderShowMessageToCustomer}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderShowMessageToCustomer}
                            feedback={errors.deliveryOrderShowMessageToCustomer}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.deliveryOrderLabelMessageToCustomerPlainText.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            as = {RTextEditor}
                            isInvalid={!!errors.deliveryOrderLabelMessageToCustomerPlainText}
                            id={this.props.fields.deliveryOrderLabelMessageToCustomerPlainText.id}

                            toolbar={{
                                options: ['inline', 'textAlign', 'list'],
                                inline: {
                                    inDropdown: false,
                                    options: ['bold', 'italic', 'underline'],
                                },
                                textAlign: {
                                    options: ['left', 'center', 'right', 'justify'],
                                },
                                list: {
                                inDropdown: false,
                                options: ['unordered', 'ordered'],
                                },
                            }}

                            editorState={values.deliveryOrderLabelMessageToCustomer}
                            placeholder={this.props.fields.deliveryOrderLabelMessageToCustomerPlainText.placeholder}
                            onChange={async (plainText, html, editorState) => {
                                await setFieldValue('deliveryOrderLabelMessageToCustomerPlainText', plainText);
                                await setFieldValue('deliveryOrderLabelMessageToCustomerHTML', html);
                                await setFieldValue('deliveryOrderLabelMessageToCustomer', editorState);
                                await setFieldTouched('deliveryOrderLabelMessageToCustomerPlainText', true);
                            }}
                        />
                        <Form.Control.Feedback type="invalid">{errors.deliveryOrderLabelMessageToCustomerPlainText}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                {/* <Form.Group as={Row} sortOrder={values.deliveryOrderShowDisclaimerSortOrder} itemData={['deliveryOrderShowDisclaimerSortOrder']}> */}
                <Form.Group as={Row} sortOrder={"4"} itemData={['deliveryOrderShowDisclaimerSortOrder']} draggable={false}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.deliveryOrderShowFooter.id}
                            label={null}
                            checked={values.deliveryOrderShowFooter}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.deliveryOrderShowFooter}
                            feedback={errors.deliveryOrderShowFooter}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.deliveryOrderLabelFooterPlainText.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            as = {RTextEditor}
                            isInvalid={!!errors.deliveryOrderLabelFooterPlainText}
                            id={this.props.fields.deliveryOrderLabelFooterPlainText.id}

                            editorState={values.deliveryOrderLabelFooter}
                            placeholder={this.props.fields.deliveryOrderLabelFooterPlainText.placeholder}
                            onChange={async (plainText, html, editorState) => {
                                await setFieldValue('deliveryOrderLabelFooterPlainText', plainText);
                                await setFieldValue('deliveryOrderLabelFooterHTML', html);
                                await setFieldValue('deliveryOrderLabelFooter', editorState);
                                await setFieldTouched('deliveryOrderLabelFooterPlainText', true);
                            }}
                        />
                        <Form.Control.Feedback type="invalid">{errors.deliveryOrderLabelFooterPlainText}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
            </DraggableListByChildren>
        </>
    }

    render() {
        return <Row>
            <Col xs={12} md={10} xl={8}>{this.body()}</Col>
        </Row>
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DeliveryOrderForm);
