import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {forgotPassword} from '../../api/auth/api'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
  .email('Wrong email format')
  .min(3, 'Minimum 6 characters')
  .max(50, 'Maximum 50 characters')
  .required('Email is required'),
})

export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [err, setErr] = useState('');
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        forgotPassword(values.email)
          .then((result) => {
            setHasErrors(false)
            setLoading(false)
          })
          .catch( error => {
            try {
                setHasErrors(true)
                setLoading(false)
                setSubmitting(false)

                if (error.response) {
                    // Request made and server responded                  
                    if(error.response.data) {
                      if(error.response.data.error) {
                        setErr(error.response.data.error);
                      } else {
                        setErr(error.response.data);
                      }
                    }    
                } else if (error.request) {
                    // The request was made but no response was received
                    setErr('The login detail is incorrect');
                } else {
                    // Something happened in setting up the request that triggered an Error
                    setErr('The login detail is incorrect');
                }
            } catch(err) {
              setErr('The login detail is incorrect');
            }
        })
      }, 1000)
    },
  })

  return (
      <form
        className='form w-100'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
      <div className='mb-lg-10 d-flex flex-column'>
        <h1 className='text-dark m-0 mb-3 mb-lg-3 fs-3qx fw-bolder'>Reset your password</h1>
        <div className='text-muted fw-bold fs-4 mb-10'>
          Having trouble signing in? Enter your email and we’ll send you a link to reset your password.
        </div>
      </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
                {err}
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 alert alert-success'>
            <div className='alert-text font-weight-bold'>Password reset link has been sent to your email!</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
          <input
            type='email'
            placeholder='example@gmail.com'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
      <div className='text-center'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary w-100 mb-10'
          >
          {!loading && <span className='indicator-label text-uppercase'>Reset Password</span>}
          {loading && (
            <span className='indicator-progress text-uppercase' style={{display: 'block'}}>
              Please wait 
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
          </button>

          <div className='text-center text-muted fw-bolder'> 
            Already have an account? {' '}
            <Link to='/login' className='link-primary fw-bold'>
              Sign In
            </Link>
          </div>
        </div>
        {/* end::Form group */}
      </form>
  )
}
