import React, { Component } from 'react';

import apiUtil from '../../../api/apiUtil.jsx'
import Utils from '../../../utils/utils'

import { 
  Box,
  Grid,
  Button,
  CircularProgress,
} from '@material-ui/core';

import ManageLabel from '../../../components/OLD/input/ManageLabel.jsx';
import FreeTextTagsInput from '../../../components/OLD/TagsInput/FreeTextTagsInput.jsx';


class SendEmailDialog extends Component {
  
  constructor(props){
    super(props);

    let isLoading = ((this.props.isLoading === true || this.props.isLoading === false) ? this.props.isLoading : false);

    let item = props.item ? props.item : null;
    
    let customer = (item && item.customer) ? item.customer : null;
    let emailAddresses = (customer && customer.emailAddresses) ? customer.emailAddresses : '';
    let emailAddressesTags = (emailAddresses && emailAddresses !== '') ? emailAddresses.split(',') : [];


    this.state = {
      isLoading: isLoading,
      item: item,
      emailAddresses: emailAddresses,
      emailAddressesTags: emailAddressesTags,
      emailAddressesValidated: false,
    }
  }


  componentWillReceiveProps(nextProps) {
		if (nextProps.isLoading !== this.state.isLoading) {
			this.setState({
				isLoading: nextProps.isLoading
			});
    }
  }


  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    const form = e.currentTarget;

    this.setState({
      isLoading: true,
      emailAddressesValidated: false,
    });
    
    if(this.state.emailAddresses === ''){
      this.setState({
        emailAddressesValidated: true
      });
    }
    
    if (form.checkValidity() === true) {
        if(this.props.onOk) {
            this.props.onOk((this.state.item ? true : false),
                {
                    emailAddresses: this.state.emailAddresses,
                }
            )
        }
    } else {
        this.setState({
            isLoading: false
        });
    }
  }


  render() {
    return <Box className="create-driver-category-form">
      <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
        <Grid container>
            <Box clone pb={2} textAlign={'center'}>
                <Grid item xs={12}>
                    <ManageLabel
                        label={'Send Email'}
                    />
                </Grid>
            </Box>
            <Box clone pb={2}>
                <Grid item xs={12}>
                    <FreeTextTagsInput
                        value={this.state.emailAddressesTags}
                        placeholder={"Email addresses (multiple emails with commas)"}
                        required={true}
                        error={this.state.emailAddressesValidated} 
                        errorText={'Email addresses is required'} 
                        name={'emailAddresses'}
                        validationRegex={apiUtil.emailValidation()}
                        onValidationReject={(arr) => {
                            if(arr && arr.length > 0){
                                if(arr[0] !== ''){
                                  Utils.toast('You must enter the email address correctly!', 'error');
                                }
                            }
                        }}
                        onChange={(tags) => {
                            this.setState({
                              emailAddressesTags: tags,
                              emailAddresses: tags.join(',')
                            });
                        }}
                    />
                </Grid>
            </Box>
            
          
          <Box clone pt={3} textAlign={"right"}>
            <Grid item xs={12}>
              <Button 
                  color={'default'} 
                  variant={'contained'}
                  style={{ marginRight: '10px' }}
                  onClick={() => {
                    if(this.props.onClose)
                      this.props.onClose()
                  }}
              >
                CANCEL
              </Button>
              <Button 
                  type={'submit'}
                  color={'primary'} 
                  variant={'contained'}
                  disabled={this.state.isLoading}
              >
                SEND
                {this.state.isLoading && <span style={{ paddingLeft: '10px' }}><CircularProgress size={15} style={{ color: 'white' }} /></span>}
              </Button>
            </Grid>
          </Box>
        </Grid>
      </form>
    </Box>;
  }
}

export default SendEmailDialog;
