import React from 'react';
import { useFormik } from 'formik';

import { 
    Row,
    Col,
    Form,
    Modal,
    Button,
    Spinner,
} from 'react-bootstrap-v5';

import SmartInputDropdown from '../../../components/dropdown/smartInputDropdown/SmartInputDropdown';
import makeCRUD from "../../../components/dropdown/smartInputDropdown/makeCRUD";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../../../setup'
import binCenterMergePopup, { FormikContext, formSchema, initialValues, fields, prepareData } from '../redux/binCenterMergePopup';


const SmartDropdown = makeCRUD(SmartInputDropdown);


const MergePopup = (props: any) => {
    const dispatch = useDispatch();
    const { show, item, isLoading } = useSelector((state: RootState) => state.binCenterMergePopup);


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: formSchema(),
        enableReinitialize: true,
        validateOnMount: true,
        validateOnChange: true,
        onSubmit: values => {
            let data = prepareData(values, item.binNumberId);
            if(props.onSave){
                props.onSave(data);
            }
        },
    });
    const { values, errors, setValues, setFieldValue, handleSubmit } = formik;
    
    React.useEffect(() => {
        if(show){
            setValues(initialValues);
        }
    }, [show]);

    return <Modal 
        show={show} 
        size={'sm'} 
        backdrop={'static'} 
        onHide={() => {
            dispatch(binCenterMergePopup.setHide());
            
            if(props.onCancel){
                props.onCancel();
            }
        }} 
        enforceFocus={false}
    >
        <Modal.Header className={'px-4 py-3'} closeButton={false}>
            <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body className={'px-5 py-7'}>
            <FormikContext.Provider value={formik}>
                <Row>
                    <Col xs={12}>
                        <Form.Group className={'mb-3'}>
                            <Form.Label>{fields.binNumberId.label}</Form.Label>
                            <SmartDropdown
                                isInvalid={!!errors.binNumberId}
                                errorText={errors.binNumberId}

                                componentTitle={'binNumber'}
                                componentApi={'binNumber'}
                                componentId={'binNumberId'}
                                componentName={'binNumberName'}
                                componentData={{
                                    binTypeId: item?.binTypeId,
                                    excludeBinNumberId: item?.binNumberId
                                }}

                                placeholder={fields.binNumberId.placeholder}
                                value={values.binNumberId}
                                label={values.binNumberName}

                                showFooter={false}
                                disabled={false}
                                showDefault={false}
                                isInfiniteScroll={false}

                                onChange={async (value: any, item: any, i: any) => {
                                    await setFieldValue('binNumberId', value);
                                    await setFieldValue('binNumberName', item.title);
                                }}
                                onClear={async () => {
                                    await setFieldValue('binNumberId', null);
                                    await setFieldValue('binNumberName', '');
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </FormikContext.Provider>
        </Modal.Body>

        <Modal.Footer className={'p-3'} style={{ justifyContent: 'start' }}>
            <Button
                type={'button'}
                variant={'primary'}
                size={'sm'}
                disabled={isLoading}
                onClick={() => {
                    handleSubmit()
                }}
            >
                {props.save}
                {isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
            </Button>

            <Button
                type={'button'}
                variant={'light'}
                size={'sm'}
                disabled={isLoading}
                onClick={() => {
                    dispatch(binCenterMergePopup.setHide());
            
                    if(props.onCancel){
                        props.onCancel();
                    }
                }}
            >
                {props.cancel}
                {isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
            </Button>
        </Modal.Footer>
    </Modal>
}


export default MergePopup;