import React from "react";

import { EditorState } from 'draft-js';
import h2p from 'html2plaintext';
import _ from 'lodash';
import Switch from "react-switch";

import { 
    Row,
    Col,
    Form,
    Button,
    InputGroup,
    Dropdown,
    Spinner,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import { Formik, FieldArray } from 'formik';
import * as yup from 'yup';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import Utils from "../../../utils/utils";
import { ActiveInactive, XeroPaymentTermTypeItems, XeroPaymentTermType } from "../../../utils/enums";
import { StyledDropdownButton } from "../../../utils/styles";

import CreditLimitForm from "./CreditLimitForm";

import InputLayout from "../../../components/input/InputLayout";
import RSuiteTagInput from '../../../components/OLD/TagsInput/RSuiteTagInput';
import CustomTooltip from '../../../components/Tooltip/Tooltip';
import Popup from '../../../components/popup/Popup';
import LoadingButton from '../../../components/Buttons/LoadingButton';
import SmartInputDropdown from '../../../components/dropdown/smartInputDropdown/SmartInputDropdown';
import makeCRUD from "../../../components/dropdown/smartInputDropdown/makeCRUD";

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut, dispatchApiCallDelete } from '../../../../setup/redux/dispatch/actions'


const SmartDropdown = makeCRUD(SmartInputDropdown);


const StyledTableWrapper = styled.div`
    margin-top: 15px;
    margin-bottom: 25px;
    overflow: auto;
`;
const StyledTable = styled.table`
    width: 100%;
`;
const StyledThead = styled.thead`

`;
const StyledTheadTr = styled.tr`
    
`;
const StyledTheadTrTh = styled.th`
    background-color: ${props => props.islast ? 'transparent' : '#F7F7F7'} !important;
    border: 1px solid ${props => props.islast ? 'transparent' : '#ECECEC'} !important;
    padding: 10px 20px;

`;
const StyledTbody = styled.tbody`
    
`;
const StyledTbodyTr = styled.tr`
    &:hover {
        > td {
            background-color: transparent !important;
        }
    }
`;
const StyledTbodyTrTd = styled.td`
    border: 1px solid ${props => props.islast ? 'transparent' : '#ECECEC'} !important;
    padding: 0px;
`;
const StyledTbodyTrTdEmpty = styled.td`
    border: 1px solid ${props => props.islast ? 'transparent' : '#ECECEC'} !important;
    padding: 10px 20px;
    line-height: 23px;
`;

const StyledCancel = styled.i`
    cursor: pointer;
    color: #DFDFDF;

    &:hover {
        color: #FF0090;
    }
`;

const TableInputText = styled(Form.Control)`
    border: 0px;
    border-radius: 0px;
    padding: 10px 20px;

    &:hover {
        outline: 1px solid #B5B5C3;
    }

    &:focus {
        outline: 1px solid #185CFF;
    }

    &.is-invalid {
        outline: 0px;
        border: 1px solid #FF0090;
    }
`;

const StyledCreditLimitPrice = styled.span`
    color: #181C32;
    font-size: 1.1rem;
    font-weight: 500;
`;
const StyledCreditLimitA = styled.a`
    text-decoration: underline;
    color: #185CFF !important;
    font-size: 1.1rem !important;
    font-weight: 500 !important;

    &:hover {
        opacity: 0.8;
    }
`;


const formFields = {
    isActive: {
      id: 'isActive',
      label: 'Status',
      placeholder: ' ',
    },
    customerName: {
      id: 'customerName',
      label: 'Customer',
      placeholder: ' ',
    },
    billingName: {
      id: 'billingName',
      label: 'Billing Name',
      placeholder: ' ',
    },
    billingAddress: {
      id: 'billingAddress',
      label: 'Billing Address',
      placeholder: Utils.placeholderRows(4),
    },
    contactNumber: {
      id: 'contactNumber',
      label: 'Phone Number',
      placeholder: ' ',
    },
    note: {
      id: 'note',
      label: 'Customer Note',
      labelDetails: '(For Internal Use)',
      placeholder: Utils.placeholderRows(5),
    },
    customerCategoryId: {
      id: 'customerCategoryId',
      label: 'Category',
      placeholder: ' ',
    },
    businessRegNo: {
      id: 'businessRegNo',
      label: 'Business Reg. No.',
      placeholder: ' ',
    },
    accountBillingProfileId: {
      id: 'accountBillingProfileId',
      label: 'Billing By',
      placeholder: ' ',
    },
    paymentTermsId: {
      id: 'paymentTermsId',
      label: 'Payment Terms',
      placeholder: ' ',
    },
    xeroPaymentTerm: {
      id: 'xeroPaymentTerm',
      label: 'Payment Terms',
      placeholder: ' ',
    },
    xeroPaymentTermType: {
      id: 'xeroPaymentTermType',
      label: '',
      placeholder: ' ',
      error: 'Due date for sales invoices must be between 1 and 31 when set to the following (or current) month.',
    },
    creditLimit: {
      id: 'creditLimit',
      label: 'Credit Limit',
      placeholder: ' ',
    },
    isRequirePaymentCollection: {
      id: 'isRequirePaymentCollection',
      label: 'Collect Payment Onsite',
      placeholder: ' ',
    },
    isAutoSendDoDoc: {
      id: 'isAutoSendDoDoc',
      label: 'Notify Recipients',
      placeholder: ' ',
    },
    emailAddresses: {
        id: 'emailAddresses',
        label: 'Company Email',
        placeholder: '',
        info: 'You can enter only one email address',
    },

    contactPersons: {
      id: 'contactPersons',
      label: 'Contact Persons',
      placeholder: ' ',
    },
    firstName: {
      id: 'firstName',
      label: 'First Name',
      placeholder: ' ',
    },
    emailAddress: {
      id: 'emailAddress',
      label: 'Email Address',
      placeholder: ' ',
    },
    workPhone: {
      id: 'workPhone',
      label: 'Work Phone',
      placeholder: ' ',
    },
    mobilePhone: {
      id: 'mobilePhone',
      label: 'Mobile',
      placeholder: ' ',
    },
    department: {
      id: 'department',
      label: 'Department',
      placeholder: ' ',
    },
}
const formSchema = yup.object().shape({
    isEdit: yup.bool().oneOf([true, false]),
    isActive: yup.bool().oneOf([true, false]),
    customerName: yup.string().required().label(formFields.customerName.label),
    billingName: yup.string().required().label(formFields.billingName.label),
    billingAddress: yup.string().label(formFields.billingAddress.label),
    contactNumber: yup.string().label(formFields.contactNumber.label),
    note: yup.string().label(formFields.note.label),
    customerCategoryId: yup.number().nullable().label(formFields.customerCategoryId.label),
    businessRegNo: yup.string().label(formFields.businessRegNo.label),
    accountBillingProfileId: yup.number().nullable().required().label(formFields.accountBillingProfileId.label),
    paymentTermsId: yup.number().nullable().label(formFields.paymentTermsId.label),
    
    // xeroPaymentTerm: yup.number().nullable().positive().min(0).label(formFields.xeroPaymentTerm.label),
    xeroPaymentTerm: yup.number().when(['customFormParams.isConnectXero', 'xeroPaymentTermType'], (isConnectXero, xeroPaymentTermType) => {
        if(isConnectXero){
            if((xeroPaymentTermType == XeroPaymentTermType.OF_CURRENT_MONTH) || (xeroPaymentTermType == XeroPaymentTermType.OF_FOLLOWING_MONTH)){
                return yup.number().nullable().required().positive().min(0).max(31, formFields.xeroPaymentTermType.error).label(formFields.xeroPaymentTerm.label);
            } else {
                return yup.number().nullable().required().positive().min(0).label(formFields.xeroPaymentTerm.label);
            }
        } else {
          return yup.number().nullable().positive().min(0).label(formFields.xeroPaymentTerm.label);
        }
    }),
    xeroPaymentTermType: yup.number().when(['customFormParams.isConnectXero'], (isConnectXero) => {
        if(isConnectXero){
          return yup.number().nullable().required().positive().min(0).label(formFields.xeroPaymentTermType.label);
        } else {
          return yup.number().nullable().label(formFields.xeroPaymentTermType.label);
        }
    }),
    
    creditLimit: yup.number().nullable().min(0).label(formFields.creditLimit.label),
    isRequirePaymentCollection: yup.bool().oneOf([true, false]),
    // emailAddresses: yup.string().email('Must be a valid email').label(formFields.emailAddresses.label),
    emailAddresses: yup.array().of(yup.string().email('Must be a valid email')).min(0).label(formFields.emailAddresses.label),

    contactPersons: yup.array().of(yup.object().shape({
        firstName: yup.string().nullable().label(formFields.firstName.label),
        emailAddress: yup.string().nullable().email('Must be a valid email').label(formFields.emailAddress.label),
        workPhone: yup.string().nullable().label(formFields.workPhone.label),
        mobilePhone: yup.string().nullable().label(formFields.mobilePhone.label),
        department: yup.string().nullable().label(formFields.department.label),
    })),
});

  
class CustomerForm extends React.Component {
  
    constructor(props) {
        super(props);

        this.pageName = 'customer_form';
        this.backLink = '/Customers';

        this.refForm = React.createRef();
        this.refCreditLimitForm = React.createRef();
        
        let id = Utils.getIntProps(props);
        let isCopy = Utils.getBoolProps(props);

        this.creditLimitEmailTemplateInit = {
            subject: '',
            fromName: '',
            // recipientCc: '',
            recipientCc: [],
            message: '',
        };

        this.contactPersonsInit = {
            firstName: '',
            emailAddress: '',
            workPhone: '',
            mobilePhone: '',
            department: '',
        };
        
        this.customerCreditLimitTriggersInit = {
            percentOfCreditLimit: null,
            action: [],
            emailAddress: []
        };

        
        this.state = {
            id: id,
            isCopy: isCopy,
            details: null,

            initialValues: {
                isEdit: false,
                isActive: true,
                customerName: '',
                billingName: '',
                billingAddress: '',
                contactNumber: '',
                note: '',
                customerCategoryId: null,
                customerCategoryName: '',
                businessRegNo: '',
                accountBillingProfileId: null,
                accountBillingProfileName: '',
                paymentTermsId: null,
                paymentTermsName: '',
                xeroPaymentTerm: '',
                xeroPaymentTermType: '0',
                isRequirePaymentCollection: false,
                contactPersons: [this.contactPersonsInit],
                emailAddresses: [],

                creditLimit: '',
                creditLimitAlertMessage: '',

                customerCreditLimitTriggers: [this.customerCreditLimitTriggersInit],
                creditLimitEmailTemplate: this.creditLimitEmailTemplateInit,
                customFormParams: {
                    accountBillingProfileItems: [],
                    isConnectQuickbook: false,
                    isConnectXero: false,
                    quickBookAppId: 0,
                    paymentTermsItems: [],
                    paymentTermsIsLoading: false,
                    customerCategoryItems: [],
                    
                    messagePlainText: '',
                    messageHTML: '',
                    message: EditorState.createEmpty(),
                }
            },
            
            popupShowCreditLimForm: false,
            popupLoadingCreditLimForm: false,
            popupDataCreditLimForm: null,
            popupEmailItemsCreditLimForm: null,
            popupMainEmailItemsCreditLimForm: null,

            companyEmailPrev: null,
        };
    }


    componentDidMount(){
        if(this.state.id){
            this.setLayoutForm()
        } else {
            this.setLayoutForm({
                footerContent: (isLoading) => {
                    return <Col xs={'auto'}>
                        <Button 
                            type={'button'}
                            variant={'primary'}
                            disabled={isLoading}
                            onClick={() => {
                                this.onSaveCreateNewSite((res) => {
                                    this.props.history.push({
                                        pathname: '/Customer-overview-sites',
                                        search: '?id=' + res.customerId,
                                        state: { showNewPopup: true },
                                    });
                                });
                            }}
                        >
                            Save & Create New Site
                            {isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                        </Button>
                    </Col>
                }
            });
        }

        this.callDetailsApi(this.state.id, (details) => {
            if(details){
                this.setLayoutTitle(this.state.isCopy ? 'Copy Customer' : 'Edit Customer');
                this.prepareForm(details);
                this.setLoading(false);
            } else {
                this.setLayoutTitle('New Customer');
                this.init();
            }
        });
    }

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    onSaveCreateNewSite = (callback = null) => {
        if(this.refForm && this.refForm.current){
            const { values, validateForm } = this.refForm.current;

            validateForm().then((err) => {
                if(!_.isEmpty(err)){
                    Utils.toast('You must fill in the required fields', 'error');
                } else {
                    this.setLoading(true);
                    let data = this.prepareData(values);

                    this.callCreateApi(data, (res, isError) => {
                        this.setLoading(false);

                        if(!isError && res){
                            if(callback){
                                callback(res);
                            }
                        }
                    });
                }
            })
        }
    }
    

    /* API */
    reduxProps = nextProps => {
		Utils.reduxProps(nextProps,
            this.pageName + '-row_billing_rofile', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.accountBillingProfileId;
                        let title = item.billingProfileName;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-row_customer_site_list', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.customerSiteId;
                        let title = item.siteName;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );
    
        Utils.reduxProps(nextProps,
            this.pageName + '-details', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(data);
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            this.pageName + '-create', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(data, isError);
                    }
                });
            }
        );
    
        Utils.reduxProps(nextProps,
            this.pageName + '-update', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(data, isError);
                    }
                });
            }
        );
    
        Utils.reduxProps(nextProps,
            this.pageName + '-delete', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(data, isError);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-quickBook_credit_terms', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = [];

                if(data && data.length > 0) {
                    for (var i = 0; i < data.length; i++) {
                        let item = data[i];
                        let value = item.id;
                        let title = item.name;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );
    }
	
    callReadBillingProfileApi = (items, callback = null) => {
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            isActive: true,
        };
     
        this.props.dispatchApiCallGet(data, this.pageName + '-row_billing_rofile', 'AccountBillingProfile', { items: items }, callback, () => {});
    }
    
    callReadCustomerSiteApi = (items, callback = null) => {
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            sortColumn: 'siteName',
            sortDir: 'asc',
            isIncludeInactive: false,
            includeServices: false,
            customerId: this.state.id,
        };
     
        this.props.dispatchApiCallGet(data, this.pageName + '-row_customer_site_list', 'customersite/list', { items: items }, callback, () => {});
    }
    
    callDetailsApi = (id = null, callback = null) => {
        if(id){
            this.setLoading(true);
            this.props.dispatchApiCallGet(null, this.pageName + '-details', 'customer/' + id, null, callback, () => {});
        } else {
            if(callback){
                callback(null)
            }
        }
    }

    callCreateApi = (data, callback = null) => {
        this.setLoading(true);
        this.props.dispatchApiCallPost(data, this.pageName + '-create', 'customer', data, callback, null);
    }

    callUpdateApi = (data, callback = null) => {
        this.setLoading(true);
        this.props.dispatchApiCallPut(data, this.pageName + '-update', 'customer', data, callback, null);
    }

    callDeleteApi = (data, callback = null) => {
        this.setLoading(true);
        this.props.dispatchApiCallDelete(data, this.pageName + '-delete', 'customer', data, callback, null);
    }

    callQuickbookReadCreditTermsApi = (quickBookAppId = 0, callback = null) => {
        let data = {
            quickBookAppId: quickBookAppId,
        };
     
        this.props.dispatchApiCallGet(data, this.pageName + '-quickBook_credit_terms', 'quickbookapp/term', null, callback, () => {});
    }
    /* END API */


    /* LAYOUT FUNCTIONS */
    setLayoutForm = (options) => {
        if(this.props.layoutRef && this.props.layoutRef.current){
            this.props.layoutRef.current.setLayoutForm(this, options);
        }
    }
    setLayoutLoading = (state = false) => {
        if(this.props.layoutRef && this.props.layoutRef.current){
            this.props.layoutRef.current.setLayoutLoading(state);
        }
    }
    setLayoutTitle = (title = '') => {
        if(this.props.layoutRef && this.props.layoutRef.current){
            this.props.layoutRef.current.setlayoutTitle(title);
        }
    }
    setLayoutSubmit = (form) => {
        if(this.props.layoutRef && this.props.layoutRef.current){
            this.props.layoutRef.current.setLayoutSubmit(form);
        }
    }
    /* END LAYOUT FUNCTIONS */


    /* FUNCTIONS */
    setLoading = (state = false) => {
        this.setLayoutLoading(state);

        if(this.props.onLoading){
            this.props.onLoading(state);
        }
    }
    init = () => {
        if(this.refForm && this.refForm.current){
            this.setLoading(true);
            this.callReadBillingProfileApi(this.refForm.current.values.customFormParams.accountBillingProfileItems, async (items) => {
                if(items && items.length > 0){
                    let defaultIndex = items.findIndex(x => x.item.isDefault);
                    if(defaultIndex != -1){
                        let item = items[defaultIndex];

                        let quickBookApp = (item && item.item && item.item.quickBookApp) ? item.item.quickBookApp : null;
                        let isConnectQuickbook = (item && item.item && item.item.isConnectQuickbook) ? item.item.isConnectQuickbook : false;
                        let isConnectXero = (item && item.item && item.item.isConnectXero) ? item.item.isConnectXero : false;
                        let quickBookAppId = (quickBookApp && quickBookApp.quickBookAppId) ? quickBookApp.quickBookAppId : 0;
                        
                        if(isConnectXero){
                            let xeroApp = (item && item.item && item.item.xeroApp) ? item.item.xeroApp : null;
                            let xeroPaymentTermType = (xeroApp && xeroApp.xeroPaymentTermType) ? xeroApp.xeroPaymentTermType.toString() : '0';
                            let xeroPaymentTerm = (xeroApp && xeroApp.xeroPaymentTerm) ? xeroApp.xeroPaymentTerm.toString() : '0';

                            await this.refForm.current.setFieldValue('xeroPaymentTermType', xeroPaymentTermType);
                            await this.refForm.current.setFieldValue('xeroPaymentTerm', xeroPaymentTerm);

                            this.setLoading(false);
                        } else if(isConnectQuickbook){
                            this.setLoading(true);
                            
                            this.callQuickbookReadCreditTermsApi(quickBookAppId, async (arr) => {
                                let paymentTermsName = '';
                                let quickBookDefaultTermId = (quickBookApp && quickBookApp.quickBookDefaultTermId) ? quickBookApp.quickBookDefaultTermId.toString() : '';

                                if(arr && arr.length > 0){
                                    let qbDefTermItem = arr.find(x => x.value == quickBookDefaultTermId);
                                    if(qbDefTermItem){
                                        paymentTermsName = qbDefTermItem.title;
                                    }
                                }
                                await this.refForm.current.setFieldValue('paymentTermsId', quickBookDefaultTermId);
                                await this.refForm.current.setFieldValue('paymentTermsName', paymentTermsName);

                                this.setLoading(false);
                            });
                        } else {
                            this.setLoading(false);
                        }
                        
                        await this.refForm.current.setFieldValue('accountBillingProfileId', item.value);
                        await this.refForm.current.setFieldValue('accountBillingProfileName', item.title);
                        await this.refForm.current.setFieldValue('customFormParams.quickBookAppId', quickBookAppId);
                        await this.refForm.current.setFieldValue('customFormParams.isConnectQuickbook', isConnectQuickbook);
                        await this.refForm.current.setFieldValue('customFormParams.isConnectXero', isConnectXero);
                    } else {
                        this.setLoading(false);
                    }

                    await this.refForm.current.setFieldValue('customFormParams.accountBillingProfileItems', items);
                } else {
                    this.setLoading(false);
                }
            });
        }
    }
    prepareForm = (data) => {
        if(data){
            var newData = _.cloneDeep(data);

            let customFormParams = this.state.initialValues.customFormParams;

            let customerCreditLimitTriggers = [];
            if(newData && newData.customerCreditLimitTriggers && newData.customerCreditLimitTriggers.length > 0){
                for(let i = 0; i < newData.customerCreditLimitTriggers.length; i++){
                    let actions = (newData.customerCreditLimitTriggers[i] && newData.customerCreditLimitTriggers[i].actions && newData.customerCreditLimitTriggers[i].actions.length > 0) ? newData.customerCreditLimitTriggers[i].actions : [];
                    let contacts = (newData.customerCreditLimitTriggers[i] && newData.customerCreditLimitTriggers[i].contacts && newData.customerCreditLimitTriggers[i].contacts.length > 0) ? newData.customerCreditLimitTriggers[i].contacts : [];
                    
                    newData.customerCreditLimitTriggers[i]['action'] = (actions && actions.length > 0) ? actions.map(x => x.action) : [];

                    if(contacts && contacts.length > 0) {
                        newData.customerCreditLimitTriggers[i]['emailAddress'] = contacts.map(x => {
                            if(x.emailAddress && x.emailAddress != ''){
                                return x.emailAddress
                            } else {
                                return x.customerContactPerson.emailAddress
                            }
                        })
                    }

                    // let emailAddress = (contacts && contacts.length > 0) ? (contacts.emailAddress && contacts.emailAddress != '') ? contacts.emailAddress.split(',') : [] : [];
                    // let contactsEmailAddress = (contacts && contacts.length > 0) ? contacts.map(x => x.customerContactPerson.emailAddress) : [];

                    // newData.customerCreditLimitTriggers[i]['emailAddress'] = [...emailAddress, ...contactsEmailAddress]
                    
                    customerCreditLimitTriggers.push(newData.customerCreditLimitTriggers[i]);
                }
            }
            if(customerCreditLimitTriggers.length == 0){
                customerCreditLimitTriggers = [this.customerCreditLimitTriggersInit];
            }


            let creditLimitEmailTemplate = null;
            if((newData && newData.creditLimitEmailTemplate)){
                let recipientCc = (newData.creditLimitEmailTemplate.recipientCc) ? newData.creditLimitEmailTemplate.recipientCc : '';
                let recipientCcItems = (recipientCc != '') ? recipientCc.split(',') : [];
                newData.creditLimitEmailTemplate.recipientCc = recipientCcItems;

                let message = (newData.creditLimitEmailTemplate.message) ? newData.creditLimitEmailTemplate.message : '';
                let messagePlainText = h2p(message);

                creditLimitEmailTemplate = newData.creditLimitEmailTemplate;
                creditLimitEmailTemplate.subject = (newData.creditLimitEmailTemplate.subject) ? newData.creditLimitEmailTemplate.subject : '';
                creditLimitEmailTemplate.fromName = (newData.creditLimitEmailTemplate.fromName) ? newData.creditLimitEmailTemplate.fromName : '';
                creditLimitEmailTemplate.message = (newData.creditLimitEmailTemplate.message) ? newData.creditLimitEmailTemplate.message : '';
                // creditLimitEmailTemplate.recipientCc = recipientCc;
                creditLimitEmailTemplate.recipientCc = recipientCcItems;
                
                customFormParams.messagePlainText = messagePlainText;
                customFormParams.messageHTML = message;
                customFormParams.message = Utils.createEditorState(message);
            } else {
                creditLimitEmailTemplate = this.creditLimitEmailTemplateInit;

                customFormParams.messagePlainText = '';
                customFormParams.messageHTML = '';
                customFormParams.message = Utils.createEditorState();
            }


            let billingProfileItem = (newData && newData.billingProfile) ? newData.billingProfile : null;
            let accountBillingProfileId = (billingProfileItem && billingProfileItem.accountBillingProfileId) ? billingProfileItem.accountBillingProfileId : null;
            let accountBillingProfileName = (billingProfileItem && billingProfileItem.billingProfileName) ? billingProfileItem.billingProfileName : '';
            let accountBillingProfileItems = (billingProfileItem) ? [{
                value: accountBillingProfileId,
                title: accountBillingProfileName,
                item: billingProfileItem,
            }] : [];
        
        
            let isConnectQuickbook = (billingProfileItem && billingProfileItem.isConnectQuickbook) ? billingProfileItem.isConnectQuickbook : false;
            let isConnectXero = (billingProfileItem && billingProfileItem.isConnectXero) ? billingProfileItem.isConnectXero : false;
            
            let paymentTermsId = (newData && newData.quickBookDefaultTermId) ? newData.quickBookDefaultTermId : null;
            let paymentTermsName = (newData && newData.paymentTerms) ? newData.paymentTerms : '';
            let paymentTermsItems = (isConnectQuickbook && paymentTermsId) ? [{
                value: paymentTermsId,
                title: paymentTermsName,
                item: newData,
            }] : [];
        
            let quickBookApp = (billingProfileItem && billingProfileItem.quickBookApp) ? billingProfileItem.quickBookApp : null;
            let quickBookAppId = (quickBookApp && quickBookApp.quickBookAppId) ? quickBookApp.quickBookAppId : 0;
            let xeroPaymentTerm = (newData && Utils.isNumber(newData.xeroPaymentTerm)) ? newData.xeroPaymentTerm.toString() : '';
            let xeroPaymentTermType = (newData && newData.xeroPaymentTermType) ? newData.xeroPaymentTermType.toString() : '0';
            
            let customerCategoryItem = (newData && newData.category) ? newData.category : null;
            let customerCategoryId = (customerCategoryItem && customerCategoryItem.customerCategoryId) ? customerCategoryItem.customerCategoryId : null;
            let customerCategoryName = (customerCategoryItem && customerCategoryItem.customerCategoryName) ? customerCategoryItem.customerCategoryName : '';
            let customerCategoryItems = (customerCategoryItem) ? [{
                value: customerCategoryId,
                title: customerCategoryName,
                item: customerCategoryItem,
            }] : [];

        
            let contactPersons = (newData && newData.contactPersons && newData.contactPersons.length > 0) ? newData.contactPersons : [this.contactPersonsInit];
        
        
            customFormParams.accountBillingProfileItems = accountBillingProfileItems;
            customFormParams.isConnectQuickbook = isConnectQuickbook;
            customFormParams.isConnectXero = isConnectXero;
            customFormParams.quickBookAppId = quickBookAppId;
            customFormParams.paymentTermsItems = paymentTermsItems;
            customFormParams.customerCategoryItems = customerCategoryItems;
        
            
            let rowValues = {
                isEdit: (this.state.isCopy) ? false : true,
                isActive: ((newData.isActive === false) || (newData.isActive === true)) ? newData.isActive : false,
                customerName: (newData && newData.customerName && newData.customerName !== '') ? newData.customerName : '',
                billingName: (newData && newData.billingName && newData.billingName !== '') ? newData.billingName : '',
                billingAddress: (newData && newData.billingAddress && newData.billingAddress !== '') ? newData.billingAddress : '',
                contactNumber: (newData && newData.contactNumber && newData.contactNumber !== '') ? newData.contactNumber : '',
                note: (newData && newData.note && newData.note !== '') ? newData.note : '',
                customerCategoryId: customerCategoryId,
                customerCategoryName: customerCategoryName,
                businessRegNo: (newData && newData.businessRegNo && newData.businessRegNo !== '') ? newData.businessRegNo : '',
                accountBillingProfileId: accountBillingProfileId,
                accountBillingProfileName: accountBillingProfileName,
                paymentTermsId: paymentTermsId,
                paymentTermsName: paymentTermsName,
                xeroPaymentTerm: xeroPaymentTerm,
                xeroPaymentTermType: xeroPaymentTermType,
                isRequirePaymentCollection: ((newData.isRequirePaymentCollection === false) || (newData.isRequirePaymentCollection === true)) ? newData.isRequirePaymentCollection : false,
                contactPersons: contactPersons,

                emailAddresses: (newData && newData.emailAddresses && newData.emailAddresses !== '') ? newData.emailAddresses.split(',') : [],
                
                creditLimit: (newData && newData.creditLimit) ? newData.creditLimit : '',
                creditLimitAlertMessage: (newData && newData.creditLimitAlertMessage) ? newData.creditLimitAlertMessage : '',

                customerCreditLimitTriggers: customerCreditLimitTriggers,
                creditLimitEmailTemplate: creditLimitEmailTemplate,

                customFormParams: customFormParams,
            };
        
            this.setState({
                initialValues: rowValues,
                details: newData,
            });
        }
    }
    prepareData = (form) => {
        let emailItems = [];
        if(this.refForm && this.refForm.current){
            let values = this.refForm.current.values;
            let contactPersons = (values && values.contactPersons && values.contactPersons.length > 0) ? values.contactPersons : [];
            if(contactPersons && contactPersons.length > 0){
                emailItems = contactPersons.map((item, i) => {
                    return {
                        value: item.emailAddress,
                        title: item.emailAddress,
                        item: item
                    }
                });
            }
        }

        var newForm = _.cloneDeep(form);

        // newForm.creditLimitEmailTemplate.recipientCc = form.creditLimitEmailTemplate.recipientCc;
        newForm.creditLimitEmailTemplate.recipientCc = form.creditLimitEmailTemplate.recipientCc.join(',');

        let customerCreditLimitTriggers2 = [];
        let customerCreditLimitTriggers1 = _.cloneDeep(newForm.customerCreditLimitTriggers);
        customerCreditLimitTriggers1.forEach((item, i) => {
            let actions = [];
            if(item.action && item.action.length > 0){
                actions = item.action.map(x => ({ action: x }));
            }

            let contacts = [];
            if(item.emailAddress && item.emailAddress.length > 0){
                contacts = item.emailAddress.map(x => {
                    let child = {};
                    if(form.emailAddresses.includes(x)){
                        child = { 
                            emailAddress: x
                        }
                    } else {
                        child = { 
                            customerContactPerson: {
                                emailAddress: x
                            }
                        }
                    }

                    let mAdrItems = emailItems.filter(y => y.item.emailAddress == x);
                    if(mAdrItems && mAdrItems.length > 0){
                        child['customerContactPersonId'] = mAdrItems[0].item.customerContactPersonId;
                    }

                    return child
                });
            }

            let params = {
                percentOfCreditLimit: item.percentOfCreditLimit,
                actions: actions,
                contacts: contacts,
            }

            if(item && item.customerCreditLimitTriggerId){
                params['customerCreditLimitTriggerId'] = item.customerCreditLimitTriggerId;
            }

            customerCreditLimitTriggers2.push(params);
        });
      

        let category = null;
        let customerCategoryId = newForm.customerCategoryId;
        if(customerCategoryId){
            category = {
                // customerCategoryId: customerCategoryId,
                customerCategoryName: newForm.customerCategoryName,
            }
        }
        
        // let billingProfile = null;
        let accountBillingProfileId = newForm.accountBillingProfileId;
        // if(accountBillingProfileId){
        //   billingProfile = {
        //     accountBillingProfileId: accountBillingProfileId,
        //     billingProfileName: form.accountBillingProfileName,
        //   }
        // }
        
        let emailAddresses = (newForm && newForm.emailAddresses && newForm.emailAddresses.length > 0) ?  newForm.emailAddresses.join(",") : '';
        
        let customerCreditLimitTriggers = [];
        if(customerCreditLimitTriggers2 && customerCreditLimitTriggers2.length > 0){
            var arrCopy = customerCreditLimitTriggers2.slice();
            customerCreditLimitTriggers = Utils.removeEmptyRowsWithArrays(arrCopy, (row) => {
                if(row['actions'] && row['contacts']){
                    return (row['percentOfCreditLimit'] !== null || row['actions'].length > 0 || row['contacts'].length > 0);
                }
            });
        }
        
        let contactPersons = [];
        if(newForm.contactPersons && newForm.contactPersons.length > 0){
            contactPersons = Utils.removeEmptyRows(newForm.contactPersons, [ 'firstName', 'emailAddress', 'workPhone', 'mobilePhone', 'department']);
        }

        let data = {
            isActive: newForm.isActive,
            customerName: newForm.customerName,
            billingName: newForm.billingName,
            billingAddress: newForm.billingAddress,
            contactNumber: newForm.contactNumber,
            note: newForm.note,
            customerCategoryId: customerCategoryId,
            category: category,
            businessRegNo: newForm.businessRegNo,
            accountBillingProfileId: accountBillingProfileId,
            // billingProfile: billingProfile,
            isRequirePaymentCollection: newForm.isRequirePaymentCollection,
            quickBookDefaultTermId: newForm.paymentTermsId,
            xeroPaymentTerm: newForm.xeroPaymentTerm,
            xeroPaymentTermType: newForm.xeroPaymentTermType,
            contactPersons: contactPersons,
            emailAddresses: emailAddresses,
            
            creditLimit: newForm.creditLimit,
            creditLimitAlertMessage: newForm.creditLimitAlertMessage,
            customerCreditLimitTriggers: customerCreditLimitTriggers,
            creditLimitEmailTemplate: newForm.creditLimitEmailTemplate,
        };

        if ((!this.state.isCopy) && this.state.id > 0) {
            data['customerId'] = this.state.id;
        }

        return data;
    }
    setSubmit = (form) => {
        this.setLayoutSubmit(form);

        if(this.props.onSubmit){
            this.props.onSubmit(form);
        }
    }
    removeContactPersonEmailFromCreditLimitTriggerList = async (formOptions, email) => {
        let {
            setFieldValue,
            values,
        } = formOptions;
        
        let customerCreditLimitTriggers = _.cloneDeep(values.customerCreditLimitTriggers);

        let state = null;
        if(customerCreditLimitTriggers && customerCreditLimitTriggers.length > 0){
            for(let i = 0; i < customerCreditLimitTriggers.length; i++){
                if(customerCreditLimitTriggers[i].contacts && customerCreditLimitTriggers[i].contacts.length > 0){
                    for(let j = 0; j < customerCreditLimitTriggers[i].contacts.length; j++){
                        if(customerCreditLimitTriggers[i].contacts[j] && customerCreditLimitTriggers[i].contacts[j].customerContactPerson && (customerCreditLimitTriggers[i].contacts[j].customerContactPerson.emailAddress == email)){
                            state = j;
                            break;
                        }
                    }

                    if(state != null){
                        delete customerCreditLimitTriggers[i].contacts[state];
                        customerCreditLimitTriggers[i].emailAddress = Utils.removeFromArray(customerCreditLimitTriggers[i].emailAddress, email);

                        state = null;
                    }
                }
            }
        }

        await setFieldValue('customerCreditLimitTriggers', customerCreditLimitTriggers);
    }
    removeEmailFromCreditLimitTriggerList = async (formOptions, email) => {
        let {
            setFieldValue,
            values,
        } = formOptions;
        
        let customerCreditLimitTriggers = _.cloneDeep(values.customerCreditLimitTriggers);

        let state = null;
        if(customerCreditLimitTriggers && customerCreditLimitTriggers.length > 0){
            for(let i = 0; i < customerCreditLimitTriggers.length; i++){
                if(customerCreditLimitTriggers[i].contacts && customerCreditLimitTriggers[i].contacts.length > 0){
                    for(let j = 0; j < customerCreditLimitTriggers[i].contacts.length; j++){
                        if(customerCreditLimitTriggers[i].contacts[j] && customerCreditLimitTriggers[i].contacts[j].emailAddress && (customerCreditLimitTriggers[i].contacts[j].emailAddress == email)){
                            state = j;
                            break;
                        }
                    }

                    if(state != null){
                        delete customerCreditLimitTriggers[i].contacts[state];
                        customerCreditLimitTriggers[i].emailAddress = Utils.removeFromArray(customerCreditLimitTriggers[i].emailAddress, email);

                        state = null;
                    }
                }
            }
        }

        await setFieldValue('customerCreditLimitTriggers', customerCreditLimitTriggers);
    }
    removeAllEmailFromCreditLimitTriggerList = async (formOptions) => {
        let {
            setFieldValue,
            values,
        } = formOptions;
        
        let customerCreditLimitTriggers = _.cloneDeep(values.customerCreditLimitTriggers);

        let state = [];
        if(customerCreditLimitTriggers && customerCreditLimitTriggers.length > 0){
            for(let i = 0; i < customerCreditLimitTriggers.length; i++){
                if(customerCreditLimitTriggers[i].contacts && customerCreditLimitTriggers[i].contacts.length > 0){
                    for(let j = 0; j < customerCreditLimitTriggers[i].contacts.length; j++){
                        if(customerCreditLimitTriggers[i].contacts[j] && customerCreditLimitTriggers[i].contacts[j].emailAddress && customerCreditLimitTriggers[i].contacts[j].emailAddress != ''){
                            state.push({
                                index: j,
                                email: customerCreditLimitTriggers[i].contacts[j].emailAddress,
                            });
                        }
                    }

                    if(state.length > 0){
                        for(let k = 0; k < state.length; k++){
                            delete customerCreditLimitTriggers[i].contacts[state[k].index];
                            customerCreditLimitTriggers[i].emailAddress = Utils.removeFromArray(customerCreditLimitTriggers[i].emailAddress, state[k].email);
                        }
                        state = [];
                    }
                }
            }
        }

        await setFieldValue('customerCreditLimitTriggers', customerCreditLimitTriggers);
    }

    changeEmailAddressInCreditLimitTriggers = (isValid, prevValue, value, values, callback = null) => {
        let customerCreditLimitTriggers = [];

        if(isValid){
            if(values.customerCreditLimitTriggers && values.customerCreditLimitTriggers.length > 0){
                customerCreditLimitTriggers = values.customerCreditLimitTriggers.map((item, i) => {
                    if(item && item.emailAddress && item.emailAddress.length > 0){
                        let emailAddress = item.emailAddress.map((email, j) => {
                            return (email === prevValue) ? value : email;
                        });
                        item.emailAddress = emailAddress;
                    }

                    if(item && item.contacts && item.contacts.length > 0){
                        let contacts = item.contacts.map((contact, j) => {
                            let emailAddress = (contact.emailAddress === prevValue) ? value : contact.emailAddress;
                            contact.emailAddress = emailAddress;

                            
                            let customerContactPerson = (contact.customerContactPerson) ? contact.customerContactPerson : null;
                            if(customerContactPerson){
                                let emailAddress1 = (customerContactPerson.emailAddress === prevValue) ? value : customerContactPerson.emailAddress;
                                customerContactPerson.emailAddress = emailAddress1;
                            }
                            contact.customerContactPerson = customerContactPerson;

                            
                            return contact;
                        });
                        item.contacts = contacts;
                    }

                    return item;
                });
            }
        }

        if(callback){
            callback(customerCreditLimitTriggers);
        }
    }
    /* END FUNCTIONS */


    /* FORM */
    form = (formOptions) => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = formOptions;
        
        return <>

            {(values.isEdit) && <InputLayout
                label={formFields.isActive.label}
            >
                <Form.Control
                    as = {SmartInputDropdown}
                    isInvalid={false}
                    errorText={''}
        
                    value={values.isActive ? ActiveInactive.ActiveValue : ActiveInactive.InactiveValue}
                    label={values.isActive ? ActiveInactive.ActiveTitle : ActiveInactive.InactiveTitle}
                    options={[
                        {
                            value: ActiveInactive.InactiveValue,
                            title: ActiveInactive.InactiveTitle,
                            color: '#FF0090',
                        },
                        {
                            value: ActiveInactive.ActiveValue,
                            title: ActiveInactive.ActiveTitle,
                            color: '#185cff',
                        }
                    ]}
                    placeholder={formFields.isActive.placeholder}
                    disabled={false}
                    isLoading={false}
                    showSearch={false}
                    showClear={false}
                    showFooter={false}
                    isInfiniteScroll={false}

                    onChange={async (value, item, i) => {
                        await setFieldValue('isActive', value);
                    }}
                />
            </InputLayout>}

            <InputLayout
                label={formFields.customerName.label}
                required
            >
                <Form.Control
                    type="text"
                    id={formFields.customerName.id}
                    placeholder={formFields.customerName.placeholder}
                    value={values.customerName}
                    onChange={async (e) => {
                        await setFieldValue('customerName', e.target.value);
                        await setFieldValue('billingName', e.target.value);
                    }}
                    isInvalid={!!errors.customerName}
                />
                <Form.Control.Feedback type="invalid">{errors.customerName}</Form.Control.Feedback>
            </InputLayout>

            <InputLayout
                label={formFields.billingName.label}
            >
                <Form.Control
                    type="text"
                    id={formFields.billingName.id}
                    placeholder={formFields.billingName.placeholder}
                    value={values.billingName}
                    onChange={handleChange}
                    isInvalid={!!errors.billingName}
                />
                <Form.Control.Feedback type="invalid">{errors.billingName}</Form.Control.Feedback>
            </InputLayout>

            <InputLayout
                label={formFields.billingAddress.label}
            >
                <Form.Control
                    as="textarea"
                    rows={4}
                    id={formFields.billingAddress.id}
                    placeholder={formFields.billingAddress.placeholder}
                    value={values.billingAddress}
                    onChange={async (e) => {
                        Utils.limitRowsBillingAddress(e.target.value, 4, async (value) => {
                            await setFieldValue('billingAddress', value);
                        });
                    }}
                    isInvalid={!!errors.billingAddress}
                />
                <Form.Control.Feedback type="invalid">{errors.billingAddress}</Form.Control.Feedback>
            </InputLayout>

            <InputLayout
                label={formFields.contactNumber.label}
            >
                <Form.Control
                    type="text"
                    id={formFields.contactNumber.id}
                    placeholder={formFields.contactNumber.placeholder}
                    value={values.contactNumber}
                    onChange={handleChange}
                    isInvalid={!!errors.contactNumber}
                />
                <Form.Control.Feedback type="invalid">{errors.contactNumber}</Form.Control.Feedback>
            </InputLayout>

            <InputLayout
                label={formFields.note.label}
                details={formFields.note.labelDetails}
            >
                <Form.Control
                    as="textarea"
                    rows={5}
                    id={formFields.note.id}
                    placeholder={formFields.note.placeholder}
                    value={values.note}
                    onChange={async (e) => {
                        Utils.limitRows(e.target.value, 5, async (value) => {
                            await setFieldValue('note', value);
                        });
                    }}
                    isInvalid={!!errors.note}
                />
                <Form.Control.Feedback type="invalid">{errors.note}</Form.Control.Feedback>
            </InputLayout>


            <InputLayout
                label={<>
                    <Row>
                        <Col xs={'auto'}>{formFields.emailAddresses.label}</Col>
                        <Col xs={'auto'}>
                            <CustomTooltip placement={'bottom'} title={formFields.emailAddresses.info}>
                                <InfoOutlinedIcon style={{ color: '#DFDFDF', width: '20px', height: '20px' }} />
                            </CustomTooltip>
                        </Col>
                    </Row>
                </>}
            >
                <Form.Control
                    type="text"
                    id={formFields.emailAddresses.id}
                    placeholder={formFields.emailAddresses.placeholder}
                    value={values.emailAddresses.join(',')}
                    onChange={async (e) => {
                        await setFieldValue('emailAddresses', [e.target.value]);
                    }}
                    isInvalid={!!errors.emailAddresses}
                    onFocus={async (e) => {
                        let isValid = Utils.isValidValue(e.target.value, Utils.emailValidation());
                        this.setState({
                            companyEmailPrev: isValid ? e.target.value : null,
                        });
                    }}
                    onBlur={async (e) => {
                        let isValid = Utils.isValidValue(e.target.value, Utils.emailValidation());
                        this.changeEmailAddressInCreditLimitTriggers(isValid, this.state.companyEmailPrev, e.target.value, values, async (customerCreditLimitTriggers) => {
                            await setFieldValue('customerCreditLimitTriggers', customerCreditLimitTriggers);
                            
                            this.setState({
                                companyEmailPrev: null,
                            });
                        });
                    }}
                />
                <Form.Control.Feedback type="invalid">{errors.emailAddresses}</Form.Control.Feedback>
            </InputLayout>
            {/* <InputLayout
                label={formFields.emailAddresses.label}
            >
                <Form.Control
                    as = {RSuiteTagInput}
                    isInvalid={!!errors.emailAddresses}

                    id={formFields.emailAddresses.id}
                    placeholder={formFields.emailAddresses.placeholder}
                    value={values.emailAddresses}
                    isDefault={true}
                    onChange={async (value) => {
                        let diffEmailAddresses = _.difference(values.emailAddresses, value);
                        if(diffEmailAddresses && diffEmailAddresses.length > 0){
                            this.removeEmailFromCreditLimitTriggerList(formOptions, diffEmailAddresses[0]);
                        }

                        await setFieldValue('emailAddresses', value);
                    }}
                    onClean={async () => {
                        this.removeAllEmailFromCreditLimitTriggerList(formOptions);
                        await setFieldValue('emailAddresses', []);
                    }}
                />
                <Form.Control.Feedback type="invalid">{errors.emailAddresses}</Form.Control.Feedback>
            </InputLayout> */}

            <InputLayout
                label={formFields.customerCategoryId.label}
            >
                <SmartDropdown
                    isInvalid={!!errors.customerCategoryId}
                    errorText={errors.customerCategoryId}

                    componentTitle={'customerCategory'}
                    componentApi={'customerCategory'}
                    componentId={'customerCategoryId'}
                    componentName={'customerCategoryName'}

                    placeholder={formFields.customerCategoryId.placeholder}
                    value={values.customerCategoryId}
                    label={values.customerCategoryName}

                    disabled={false}
                    showDefault={false}
                    isInfiniteScroll={false}

                    onChange={async (value, item, i) => {
                        await setFieldValue('customerCategoryId', value);
                        await setFieldValue('customerCategoryName', item.title);
                        // await setFieldValue('customerCategoryItem', item);
                    }}
                    onClear={async () => {
                        await setFieldValue('customerCategoryId', null);
                        await setFieldValue('customerCategoryName', '');
                        // await setFieldValue('customerCategoryItem', null);
                    }}
                />
            </InputLayout>

            <InputLayout
                label={formFields.businessRegNo.label}
            >
                <Form.Control
                    type="text"
                    id={formFields.businessRegNo.id}
                    placeholder={formFields.businessRegNo.placeholder}
                    value={values.businessRegNo}
                    onChange={handleChange}
                    isInvalid={!!errors.businessRegNo}
                />
                <Form.Control.Feedback type="invalid">{errors.businessRegNo}</Form.Control.Feedback>
            </InputLayout>

            <InputLayout
                label={formFields.accountBillingProfileId.label}
            >
                <SmartDropdown
                    isInvalid={!!errors.accountBillingProfileId}
                    errorText={errors.accountBillingProfileId}

                    componentTitle={'AccountBillingProfile'}
                    componentApi={'AccountBillingProfile'}
                    componentId={'accountBillingProfileId'}
                    componentName={'billingProfileName'}

                    renderReadAPI={(data, variables) => {
                        let options = (variables && variables.length > 0) ? variables : [];

                        if(data && data.data && data.data.length > 0) {
                            for (var i = 0; i < data.data.length; i++) {
                                let item = data.data[i];
                                let value = item['accountBillingProfileId'];
                                let title = item['billingProfileName'];
                                let arrItem = {
                                    value: value,
                                    title: title,
                                    item: item,
                                };
                    
                                options = Utils.addToArray(options, value, arrItem);
                            }
                        }

                        return {
                            options: options,
                            hasMore: false,
                        }
                    }}

                    placeholder={formFields.accountBillingProfileId.placeholder}
                    value={values.accountBillingProfileId}
                    label={values.accountBillingProfileName}

                    disabled={false}
                    showDefault={false}
                    showFooter={false}
                    isInfiniteScroll={false}

                    onChange={async (value, item, i) => {
                        await setFieldValue('accountBillingProfileId', value);
                        await setFieldValue('accountBillingProfileName', item.title);
                        await setFieldValue('paymentTermsId', null);
                        await setFieldValue('paymentTermsName', '');
                        await setFieldValue('xeroPaymentTermType', '0');
                        await setFieldValue('xeroPaymentTerm', '');
                        // await setFieldValue('accountBillingProfileItem', item);

                        let quickBookApp = (item && item.item && item.item.quickBookApp) ? item.item.quickBookApp : null;
                        let isConnectQuickbook = (item && item.item && item.item.isConnectQuickbook) ? item.item.isConnectQuickbook : false;
                        let isConnectXero = (item && item.item && item.item.isConnectXero) ? item.item.isConnectXero : false;
                        let quickBookAppId = (quickBookApp && quickBookApp.quickBookAppId) ? quickBookApp.quickBookAppId : 0;

                        await setFieldValue('customFormParams.quickBookAppId', quickBookAppId);
                        await setFieldValue('customFormParams.isConnectQuickbook', isConnectQuickbook);
                        await setFieldValue('customFormParams.isConnectXero', isConnectXero);

                        if(isConnectXero){
                            let xeroApp = (item && item.item && item.item.xeroApp) ? item.item.xeroApp : null;
                            let xeroPaymentTermType = (xeroApp && xeroApp.xeroPaymentTermType) ? xeroApp.xeroPaymentTermType.toString() : '0';
                            let xeroPaymentTerm = (xeroApp && xeroApp.xeroPaymentTerm) ? xeroApp.xeroPaymentTerm.toString() : '0';
                            await setFieldValue('xeroPaymentTermType', xeroPaymentTermType);
                            await setFieldValue('xeroPaymentTerm', xeroPaymentTerm);
                        }

                        if(isConnectQuickbook){
                            this.setLoading(true);
                            this.callQuickbookReadCreditTermsApi(quickBookAppId, async (arr) => {
                                let paymentTermsName = '';
                                let quickBookDefaultTermId = (quickBookApp && quickBookApp.quickBookDefaultTermId) ? quickBookApp.quickBookDefaultTermId.toString() : '';

                                if(arr && arr.length > 0){
                                    let qbDefTermItem = arr.find(x => x.value == quickBookDefaultTermId);
                                    if(qbDefTermItem){
                                        paymentTermsName = qbDefTermItem.title;
                                    }
                                }
                                await this.refForm.current.setFieldValue('paymentTermsId', quickBookDefaultTermId);
                                await this.refForm.current.setFieldValue('paymentTermsName', paymentTermsName);

                                this.setLoading(false);
                            });
                        }
                    }}
                    onClear={async () => {
                        await setFieldValue('accountBillingProfileId', null);
                        await setFieldValue('accountBillingProfileName', '');
                        // await setFieldValue('accountBillingProfileItem', null);
                        
                        await setFieldValue('paymentTermsId', null);
                        await setFieldValue('paymentTermsName', '');
                        await setFieldValue('xeroPaymentTermType', '0');
                        await setFieldValue('xeroPaymentTerm', '');
                        await setFieldValue('customFormParams.quickBookAppId', 0);
                        await setFieldValue('customFormParams.isConnectQuickbook', false);
                        await setFieldValue('customFormParams.isConnectXero', false);
                    }}
                />
            </InputLayout>

            {(values.customFormParams.isConnectQuickbook) && <InputLayout
                label={formFields.paymentTermsId.label}
            >
                <SmartDropdown
                    isInvalid={!!errors.paymentTermsId}
                    errorText={errors.paymentTermsId}

                    componentTitle={'paymentTerms'}
                    componentApi={'quickbookapp/term'}
                    componentId={'id'}
                    componentName={'name'}
                    componentData={{
                        quickBookAppId: values.customFormParams.quickBookAppId,
                    }}
                    
                    pageSize={Utils.getMaxPageSize()}

                    placeholder={formFields.paymentTermsId.placeholder}
                    value={values.paymentTermsId}
                    label={values.paymentTermsName}

                    disabled={false}
                    showDefault={false}
                    showSearch={false}
                    showFooter={false}
                    isInfiniteScroll={false}

                    renderReadAPI={(data, variables) => {
                        let options = (variables && variables.length > 0) ? variables : [];

                        if(data && data.length > 0) {
                            for (var i = 0; i < data.length; i++) {
                                let item = data[i];
                                let value = item['id'];
                                let title = item['name'];
                                let arrItem = {
                                    value: value,
                                    title: title,
                                    item: item,
                                };
                    
                                options = Utils.addToArrayIfNotExist(options, value, arrItem);
                            }
                        }

                        return {
                            options: options,
                            hasMore: false,
                        }
                    }}

                    onChange={async (value, item, i) => {
                        await setFieldValue('paymentTermsId', value);
                        await setFieldValue('paymentTermsName', item.title);
                        await setFieldValue('xeroPaymentTerm', '');
                        // await setFieldValue('paymentTermsItem', item);
                    }}
                    onClear={async () => {
                        await setFieldValue('paymentTermsId', null);
                        await setFieldValue('paymentTermsName', '');
                        await setFieldValue('xeroPaymentTerm', '');
                        // await setFieldValue('paymentTermsItem', null);
                    }}
                />
            </InputLayout>}

            {(values.customFormParams.isConnectXero) && <InputLayout
                label={formFields.xeroPaymentTerm.label}
            >
                <InputGroup>
                    <Form.Control
                        type="number"
                        id={formFields.xeroPaymentTerm.id}
                        placeholder={formFields.xeroPaymentTerm.placeholder}
                        value={values.xeroPaymentTerm}
                        onChange={async (e) => {
                            await setFieldValue('xeroPaymentTerm', e.target.value);
                            await setFieldValue('paymentTermsId', null);
                            await setFieldValue('paymentTermsName', '');
                        }}
                        isInvalid={!!errors.xeroPaymentTerm}
                    />
                    <StyledDropdownButton
                        title={Utils.getXeroPaymentTermTypeName(values.xeroPaymentTermType)}
                        variant={'secondary'}
                        menuAlign={'right'}
                    >
                        {XeroPaymentTermTypeItems.map((item, i) => {
                            return <Dropdown.Item
                                className={'py-2'}
                                key={item.value + '_' + item.title + '_' + i}
                                active={(item.value === values.xeroPaymentTermType)}
                                onClick={async () => {
                                    await setFieldValue('xeroPaymentTermType', item.value);
                                }}
                            >{item.title}</Dropdown.Item>
                        })}
                    </StyledDropdownButton>
                    <Form.Control.Feedback type="invalid">{errors.xeroPaymentTerm}</Form.Control.Feedback>
                </InputGroup>
            </InputLayout>}

            {values.isEdit && <InputLayout
                label={formFields.creditLimit.label}
                inputProps={{
                    className: 'd-flex align-items-center'
                }}
            >
                <StyledCreditLimitPrice className={'ps-4 ps-md-0 pe-4'}>{(values.creditLimit != '') ? Utils.getCurrency() + Utils.formatNumber(values.creditLimit) : 'Unlimited'}</StyledCreditLimitPrice>
                <StyledCreditLimitA href={'/'}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        validateForm().then((err) => {
                            if(!!err.contactPersons){
                                Utils.toast('You must fill in the required fields', 'error');
                            } else {
                                let popupEmailItemsCreditLimForm = [];
                                let contactPersons = (values && values.contactPersons && values.contactPersons.length > 0) ? values.contactPersons : [];
                                
                                let emailAddresses = (values && values.emailAddresses && values.emailAddresses.length > 0) ? values.emailAddresses : [];
                                if(contactPersons && contactPersons.length > 0){
                                    contactPersons.forEach((item, i) => {
                                        if(item.emailAddress != ''){
                                            popupEmailItemsCreditLimForm.push({
                                                value: item.emailAddress,
                                                title: item.emailAddress,
                                                item: item
                                            })
                                        }
                                    });
                                }

                                if(emailAddresses && emailAddresses.length > 0){
                                    emailAddresses.forEach((item, i) => {
                                        if(item.emailAddress != ''){
                                            let obj = {
                                                value: item,
                                                title: item,
                                                item: item
                                            }
                                            popupEmailItemsCreditLimForm = Utils.addToArrayIfNotExist(popupEmailItemsCreditLimForm, item, obj);
                                        }
                                    });
                                }

                                this.setState({
                                    popupShowCreditLimForm: true,
                                    popupLoadingCreditLimForm: false,
                                    popupDataCreditLimForm: values,
                                    popupEmailItemsCreditLimForm: popupEmailItemsCreditLimForm,
                                    popupMainEmailItemsCreditLimForm: emailAddresses,
                                });
                            }
                        })
                    }}
                >Manage</StyledCreditLimitA>
            </InputLayout>}

            <InputLayout
                label={formFields.isRequirePaymentCollection.label}
                inputProps={{
                    className: 'd-flex align-items-center'
                }}
            >
                <Switch
                    id={formFields.isRequirePaymentCollection.id}

                    checked={values.isRequirePaymentCollection}
                    onChange={async (checked) => {
                        await setFieldValue('isRequirePaymentCollection', checked);
                    }}

                    onColor="#F5F5F5"
                    onHandleColor="#185CFF"
                    
                    offColor="#F5F5F5"
                    offHandleColor="#7B7B7B"

                    handleDiameter={28}
                    width={50}
                    height={13}

                    uncheckedIcon={false}
                    checkedIcon={false}
                />
            </InputLayout>

        </>
    }

    contactPersons = (formOptions) => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = formOptions;
        
        return <>
        
            <FieldArray 
                name={formFields.contactPersons.id}
                validateOnChange={false}
            >
                {({ remove, push }) => (
                    <>
                        <StyledTableWrapper className={'responsive-table-md'}>
                            <StyledTable valign={'middle'} className={'no-responsive-table'}>
                                <StyledThead>
                                    <StyledTheadTr>
                                        <StyledTheadTrTh width={'17%'}>{formFields.firstName.label}</StyledTheadTrTh>
                                        <StyledTheadTrTh width={'17%'}>{formFields.emailAddress.label}</StyledTheadTrTh>
                                        <StyledTheadTrTh width={'17%'}>{formFields.workPhone.label}</StyledTheadTrTh>
                                        <StyledTheadTrTh width={'17%'}>{formFields.mobilePhone.label}</StyledTheadTrTh>
                                        <StyledTheadTrTh width={'17%'}>{formFields.department.label}</StyledTheadTrTh>
                                        <StyledTheadTrTh width={'11%'} islast={true}>&nbsp;</StyledTheadTrTh>
                                    </StyledTheadTr>
                                </StyledThead>
                                <StyledTbody>
                                {
                                    (values.contactPersons && values.contactPersons.length > 0)
                                    ?
                                    values.contactPersons.map((item, i) => {
                                        return <StyledTbodyTr key={i}>
                                            <StyledTbodyTrTd>
                                                <div className={'pt-1 ps-6 d-block d-md-none'}>{formFields.firstName.label}</div>
                                                <TableInputText
                                                    type="text"
                                                    id={formFields.firstName.id}
                                                    placeholder={formFields.firstName.placeholder}
                                                    value={item.firstName}
                                                    onChange={async (e) => {
                                                        await setFieldValue('contactPersons.' + i + '.firstName', e.target.value);
                                                    }}
                                                    isInvalid={!!(errors && errors.contactPersons && errors.contactPersons[i] && errors.contactPersons[i].firstName)}
                                                />
                                            </StyledTbodyTrTd>
                                            <StyledTbodyTrTd>
                                                <div className={'pt-1 ps-6 d-block d-md-none'}>{formFields.emailAddress.label}</div>
                                                <TableInputText
                                                    type="text"
                                                    id={formFields.emailAddress.id}
                                                    placeholder={formFields.emailAddress.placeholder}
                                                    value={item.emailAddress}
                                                    onChange={async (e) => {
                                                        await setFieldValue('contactPersons.' + i + '.emailAddress', e.target.value);
                                                    }}
                                                    onFocus={async (e) => {
                                                        let isValid = Utils.isValidValue(e.target.value, Utils.emailValidation());
                                                        this.setState({
                                                            companyEmailPrev: isValid ? e.target.value : null,
                                                        });
                                                    }}
                                                    onBlur={async (e) => {
                                                        let isValid = Utils.isValidValue(e.target.value, Utils.emailValidation());
                                                        this.changeEmailAddressInCreditLimitTriggers(isValid, this.state.companyEmailPrev, e.target.value, values, async (customerCreditLimitTriggers) => {
                                                            await setFieldValue('customerCreditLimitTriggers', customerCreditLimitTriggers);
                                                            
                                                            this.setState({
                                                                companyEmailPrev: null,
                                                            });
                                                        });
                                                    }}
                                                    isInvalid={!!(errors && errors.contactPersons && errors.contactPersons[i] && errors.contactPersons[i].emailAddress)}
                                                />
                                            </StyledTbodyTrTd>
                                            <StyledTbodyTrTd>
                                                <div className={'pt-1 ps-6 d-block d-md-none'}>{formFields.workPhone.label}</div>
                                                <TableInputText
                                                    type="text"
                                                    id={formFields.workPhone.id}
                                                    placeholder={formFields.workPhone.placeholder}
                                                    value={item.workPhone}
                                                    onChange={async (e) => {
                                                        await setFieldValue('contactPersons.' + i + '.workPhone', e.target.value);
                                                    }}
                                                    isInvalid={!!(errors && errors.contactPersons && errors.contactPersons[i] && errors.contactPersons[i].workPhone)}
                                                />
                                            </StyledTbodyTrTd>
                                            <StyledTbodyTrTd>
                                                <div className={'pt-1 ps-6 d-block d-md-none'}>{formFields.mobilePhone.label}</div>
                                                <TableInputText
                                                    type="text"
                                                    id={formFields.mobilePhone.id}
                                                    placeholder={formFields.mobilePhone.placeholder}
                                                    value={item.mobilePhone}
                                                    onChange={async (e) => {
                                                        await setFieldValue('contactPersons.' + i + '.mobilePhone', e.target.value);
                                                    }}
                                                    isInvalid={!!(errors && errors.contactPersons && errors.contactPersons[i] && errors.contactPersons[i].mobilePhone)}
                                                />
                                            </StyledTbodyTrTd>
                                            <StyledTbodyTrTd>
                                                <div className={'pt-1 ps-6 d-block d-md-none'}>{formFields.department.label}</div>
                                                <TableInputText
                                                    type="text"
                                                    id={formFields.department.id}
                                                    placeholder={formFields.department.placeholder}
                                                    value={item.department}
                                                    onChange={async (e) => {
                                                        await setFieldValue('contactPersons.' + i + '.department', e.target.value);
                                                    }}
                                                    isInvalid={!!(errors && errors.contactPersons && errors.contactPersons[i] && errors.contactPersons[i].department)}
                                                />
                                            </StyledTbodyTrTd>
                                            <StyledTbodyTrTd islast={true} className={'text-center'}>
                                                <div className={'pt-1 ps-1 d-block d-md-none'}>&nbsp;</div>
                                                <StyledCancel
                                                    className={'material-icons'}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        
                                                        if(values.contactPersons.length > 1){
                                                            this.removeContactPersonEmailFromCreditLimitTriggerList(formOptions, item.emailAddress);
                                                            remove(i);
                                                        } else {
                                                            this.removeContactPersonEmailFromCreditLimitTriggerList(formOptions, item.emailAddress);
                                                            remove(i);
                                                            setTimeout(() => {
                                                                push(this.contactPersonsInit);
                                                            }, 100);
                                                        }
                                                    }}
                                                >cancel</StyledCancel>
                                            </StyledTbodyTrTd>
                                        </StyledTbodyTr>
                                    })
                                    :
                                    <StyledTbodyTr>
                                        <StyledTbodyTrTdEmpty colSpan={5} className={'text-center'}>No Contacts!</StyledTbodyTrTdEmpty>
                                        <StyledTbodyTrTdEmpty islast={true}>&nbsp;</StyledTbodyTrTdEmpty>
                                    </StyledTbodyTr>
                                }
                                </StyledTbody>
                            </StyledTable>
                        </StyledTableWrapper>

                        <a href={'/'} 
                            className={'link-primary'}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                
                                if(values.contactPersons.length < 5){
                                    let hasEmpty = Utils.hasEmptyRows(values.contactPersons, (item, i) => {
                                        return Utils.isEmptyRow(item, [ 'firstName', 'emailAddress', 'workPhone', 'mobilePhone', 'department' ]);
                                    });
                                    
                                    if(!hasEmpty){
                                        push(this.contactPersonsInit);
                                    } else {
                                        Utils.toast('You must fill in the required fields', 'error');
                                    }
                                } else {
                                    Utils.toast('You can add a maximum of 5 contacts', 'warning');
                                }

                                // validateForm().then((err) => {
                                //     if(!!err.contactPersons){
                                //         Utils.toast('You must fill in the required fields', 'error');
                                //     } else {
                                //         push(this.contactPersonsInit);
                                //     }
                                // })
                            }}
                        >+ Add Contact Person</a>

                    </>
                )}
            </FieldArray>

        </>
    }
    /* END FORM */


	/* DIALOG */
    setPopupCreditLimitForm = () => {
        return <Popup 
            className={'no-bg'}
            auth={this.props.auth}
            settingsType={this.props.settingsType}

            show={this.state.popupShowCreditLimForm}
            isLoading={this.state.popupLoadingCreditLimForm}
            dataParam={this.state.popupDataCreditLimForm}
            emailItems={this.state.popupEmailItemsCreditLimForm}
            mainEmailItems={this.state.popupMainEmailItemsCreditLimForm}

            showHeader={true}
            header={({ isLoading, id, onHide, onSave }) => {
                return <>
                    <h3>
                        Manage Your Customer's Credit Limit<br />
                        <span style={{ fontSize: '13px', fontWeight: 'normal', color: '#7A7A7A' }}>Notify your customers via email and alert yourself or team members when credit limit is exceeded.</span>
                    </h3>
                    
                </>
            }}
            showFooter={true}
            centerFooter={'start'}
            footer={({ isLoading, id, onHide, onSave }) => {
                return <Row>
                    <Col xs={'auto'}>
                        <LoadingButton
                            isLoading={isLoading}
                            onClick={onSave}
                        >Ok</LoadingButton>
                    </Col>
                    <Col xs={'auto'}>
                        <Button
                            variant={'light'}
                            disabled={isLoading}
                            onClick={onHide}
                        >Cancel</Button>
                    </Col>
                </Row>
            }}

            scrollable={true}
            centered={true}
            closeButton={true}
            fullscreen={true}
            noBodyPadding={true}

            onHide={() => {
                this.setState({
                    popupShowCreditLimForm: false,
                    popupLoadingCreditLimForm: false,
                    popupDataCreditLimForm: null,
                    popupEmailItemsCreditLimForm: null,
                    popupMainEmailItemsCreditLimForm: null,
                });
            }}
            onSave={async () => {
                if(this.refCreditLimitForm && this.refCreditLimitForm.current){
                    if(this.refCreditLimitForm.current.refForm && this.refCreditLimitForm.current.refForm.current){
                        
                        let customerCreditLimitTriggers = [];
                        if(this.refCreditLimitForm.current.refForm.current.values.customerCreditLimitTriggers && this.refCreditLimitForm.current.refForm.current.values.customerCreditLimitTriggers.length > 0){
                            var arrCopy = this.refCreditLimitForm.current.refForm.current.values.customerCreditLimitTriggers.slice();
                            customerCreditLimitTriggers = Utils.removeEmptyRowsWithArrays(arrCopy, (row) => {
                                if(row['action'] && row['emailAddress']){
                                    return (row['percentOfCreditLimit'] !== null || row['action'].length > 0 || row['emailAddress'].length > 0);
                                }
                            });
                            await this.refCreditLimitForm.current.refForm.current.setFieldValue('customerCreditLimitTriggers', customerCreditLimitTriggers);
                        }

                        this.refCreditLimitForm.current.refForm.current.validateForm().then((err) => {
                            if(!_.isEmpty(err)){
                                Utils.toast('You must fill in the required fields', 'error');
                            } else {
                                this.refCreditLimitForm.current.refForm.current.handleSubmit();
                            }
                        })
                    }
                }
            }}
            
            onLoading={(state) => {
                this.setState({
                    popupLoadingCreditLimForm: state,
                });
            }}
            onSubmit={(form) => {
                if(this.refForm && this.refForm.current){
                    this.refForm.current.setValues(form);

                    this.setState({
                        popupShowCreditLimForm: false,
                        popupLoadingCreditLimForm: false,
                        popupDataCreditLimForm: null,
                        popupEmailItemsCreditLimForm: null,
                        popupMainEmailItemsCreditLimForm: null,
                    });
                }
            }}
        >
            {(props) => {
                return <CreditLimitForm
                    ref={this.refCreditLimitForm}
                    {...props}
                />
            }}
        </Popup>
    }

    footer = (formOptions) => {
        let {
            values,
            validateForm,
        } = formOptions;
        
        return <Row>
            <Col xs={'auto'}>
                <LoadingButton
                    isLoading={this.props.isLoading}
                    onClick={() => {
                        validateForm().then((err) => {
                            if(!_.isEmpty(err)){
                                Utils.toast('You must fill in the required fields', 'error');
                            } else {
                                if(this.props.onSave){
                                    this.props.onSave(() => {
                                        return this.prepareData(values);
                                    });
                                }
                            }
                        })
                    }}
                >Save</LoadingButton>
            </Col>
            <Col xs={'auto'}>
                <Button
                    variant={'light'}
                    disabled={this.props.isLoading}
                    onClick={() => {
                        Utils.showDrawer(this.props.drawer, false);
                    }}
                >Cancel</Button>
            </Col>
        </Row>
    }
	/* END DIALOG */


    render() {
        return <>
            <Formik
                innerRef={this.refForm}
                validationSchema={formSchema}
                initialValues={this.state.initialValues}
                enableReinitialize={true}
                validateOnMount={false}
                validateOnChange={false}
                onSubmit={(form, e) => {
                    this.setLoading(true);
                    let data = this.prepareData(form);
                    
                    if(form.isEdit){
                        this.callUpdateApi(data, (res, isError) => {
                            if(!isError){
                                this.setSubmit(form);
                            } else {
                                this.setLoading(false);
                            }
                        });
                    } else {
                        this.callCreateApi(data, (res, isError) => {
                            if(!isError){
                                this.setSubmit(form);
                            } else {
                                this.setLoading(false);
                            }
                        });
                    }
                }}
            >
                {(formOptions) => {
                    return <Form className='w-100 p-5' noValidate autoComplete="off" 
                        onSubmit={formOptions.handleSubmit}
                        onKeyDown={(keyEvent) => {
                        // if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                        //     keyEvent.preventDefault();
                        // }
                    }}>
                        <Row style={{ paddingBottom: (this.props.isFooter) ? '100px' : 'initial' }}>
                            <Col xs={12} md={10} lg={8} xl={6} className={'ps-2 pe-2 ps-md-7 pe-md-7'}>{this.form(formOptions)}</Col>
                            <Col xs={12} className={'ps-2 pe-2 ps-md-7 pe-md-7'}><hr /></Col>
                            <Col xs={12} className={'ps-2 pe-2 ps-md-7 pe-md-7 mb-10 pb-10'}>{this.contactPersons(formOptions)}</Col>
                            {(this.props.isFooter) && <Col
                                xs={12} 
                                className={'ps-2 pe-2 ps-md-7 pe-md-7'}
                                style={{
                                    position: 'fixed',
                                    left: '0px',
                                    bottom: '0px',
                                    backgroundColor: 'white',
                                    paddingBottom: '10px',
                                    paddingTop: '10px',
                                    borderTop: '1px solid #EFF2F5',
                                }}
                            >{this.footer(formOptions)}</Col>}
                        </Row>
                    </Form>
                }}
            </Formik>
            {this.setPopupCreditLimitForm()}
        </>
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  dispatchApiCallPost,
  dispatchApiCallPut,
  dispatchApiCallDelete,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CustomerForm);
