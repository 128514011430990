import React, { Component } from "react";
import * as yup from 'yup';
import currencies from 'iso-currencies';

import { 
  Row,
  Col,
  Button,
} from "react-bootstrap-v5";
import { getCountryCallingCode } from 'react-phone-number-input';

import { DrawerLayout } from '../../../components/drawer/DrawerLayout'
import CurrentDateTime from '../../../components/currentDateTime/CurrentDateTime'

import ViewLayout from "../layout/ViewLayout";
import GeneralForm from "./GeneralForm";

import DataTable from "../../../components/OLD/DataTable/DataTable";
import DataTableScrollSync from '../../../components/OLD/DataTable/DataTableScrollSync.jsx';

import Utils from '../../../utils/utils'
import { ReportUOM, PagesPermissions, AccountSettingType } from '../../../utils/enums'

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { actions } from "../../../../setup/redux/auth/saga";
import { commonProfileApiCallPut } from '../../../../setup/redux/commonProfile/actions'
import { dispatchApiCallGet, dispatchApiCallPut } from '../../../../setup/redux/dispatch/actions'


const { fulfillUser } = actions;


const defaultUoMLabel = 'My weigh metrics';
const defaultBillingUoMLabel = 'My billing metrics';
const timezoneUtcOffsetLabel = 'My time zone';
const mapSettingsIdLabel = 'My map';
const countryCodeLabel = 'My country code';
const currencyCodeLabel = 'My currency';
const allowDriverMultipleJobsLabel = 'Allow driver to do multiple jobs';
const showServiceItemNameInMobileAppLabel = 'Show service name in mobile app';


const formFields = {
  defaultUoM: {
    id: 'defaultUoM',
    label: defaultUoMLabel,
    placeholder: ' ',
  },
  defaultBillingUoM: {
    id: 'defaultBillingUoM',
    label: defaultBillingUoMLabel,
    placeholder: ' ',
  },
  timezoneUtcOffset: {
    id: 'timezoneUtcOffset',
    label: timezoneUtcOffsetLabel,
    placeholder: ' ',
  },
  mapSettingsId: {
    id: 'mapSettingsId',
    label: mapSettingsIdLabel,
    placeholder: ' ',
  },
  countryCode: {
    id: 'countryCode',
    label: countryCodeLabel,
    placeholder: ' ',
  },
  currencyCode: {
    id: 'currencyCode',
    label: currencyCodeLabel,
    placeholder: ' ',
  },
  allowDriverMultipleJobs: {
    id: 'allowDriverMultipleJobs',
    label: allowDriverMultipleJobsLabel,
    placeholder: ' ',
  },
  showServiceItemNameInMobileApp: {
    id: 'showServiceItemNameInMobileApp',
    label: showServiceItemNameInMobileAppLabel,
    placeholder: ' ',
  },
}
const formSchema = yup.object().shape({
  isEdit: yup.bool().oneOf([true, false]),

  defaultUoM: yup.number().nullable().required().label(formFields.defaultUoM.label),
  defaultBillingUoM: yup.number().nullable().required().label(formFields.defaultUoM.label),
  timezoneUtcOffset: yup.number().nullable().required().label(formFields.timezoneUtcOffset.label),
  mapSettingsId: yup.string().nullable().required().label(formFields.mapSettingsId.label),
  countryCode: yup.string().nullable().label(formFields.countryCode.label),
  currencyCode: yup.string().nullable().label(formFields.currencyCode.label),
  currencyCodeValue: yup.string().nullable().required().label(formFields.currencyCode.label),
  allowDriverMultipleJobs: yup.bool().oneOf([true, false]),
  showServiceItemNameInMobileApp: yup.bool().oneOf([true, false]),
});


class GeneralView extends Component {

  constructor(props) {
    super(props);

    this.pageName = "manage-general-page";
    this.pageIdField = "";
    this.pageNameField = "";
    this.pageAPI = "Account";
    this.pageCaption = 'General'

		let user = (props.auth && props.auth.user) ? props.auth.user : null;
		this.permissions = (user && user.permissions && user.permissions.length > 0) ? user.permissions : [];

    props.layoutRef.current = this;
    this.formDrawer = null;

    this.tableRef = React.createRef();
    this.refDropdownAdvancedFilter = React.createRef();
    this.refDropdownActionMenu = React.createRef();
    this.refFormDrawer = React.createRef();
    this.refDataTableScrollSync = React.createRef();


    this.timezoneItems = Utils.timeZoneList();

    this.valueTemplate = (id, row, col, obj) => {
      if(id === 1){
        return row.defaultUoM === ReportUOM.kg ? 'Kilograms (kg)' : 'Tons';
      } else if(id === 2){
        return Utils.getTimeZoneNameByOffset(this.timezoneItems, row.timezoneUtcOffset);
      } else if(id === 3){
        return row.mapSettingsName;
      } else if(id === 4){
        if(currencies.isValid(row.currencyCodeValue)){
          let obj = currencies.information(row.currencyCodeValue);
          let symbol = (obj && obj.symbol && obj.symbol != '') ? obj.symbol : row.currencyCodeValue;
          let title = symbol + ' (' + row.currencyCodeValue + ' - '+ obj.name + ')';
          return title;
        } else {
          return row.currencyCode;
        }
      } else if(id === 5){
        return row.defaultBillingUoM === ReportUOM.kg ? 'Kilograms (kg)' : 'Tons';
      } else if(id === 6){
        return (row.countryCode && row.countryCode != '') ? '+' + getCountryCallingCode(Utils.getCountryCode(row.countryCode)) : '';
      } else if(id === 7){
        return (row) ? (Utils.strToBoolLowerCase(row.allowDriverMultipleJobs) == true) ? 'Yes' : 'No' : 'No';
      } else if(id === 8){
        return (row) ? (Utils.strToBoolLowerCase(row.showServiceItemNameInMobileApp) == true) ? 'Yes' : 'No' : 'No';
      }
      return <></>
    };


    let columns = [
      { 
        id: 'id', 
        filterKey:  'id',
        name:  'id',
        label: 'ID',
        visible: false
      },
      {
        id: "label",
        name: "label",
        label: "Name",
        responsiveLabel: "Name",
        orderable: false,
      },
      {
        id: "value",
        name: "value",
        label: "Value",
        responsiveLabel: "Value",
        orderable: false,
        template: this.valueTemplate,
      },
    ];
    
    
    let columnVisibility = [
      false,
      true,
      true,
    ];

    this.state = {
      data: null,
      err: null,
      isError: false,
      isLoading: false,

      search: '',
      sortColumn: 'updated',
      sortDir: 'desc',
      searchTags: [],
      searchOperator: [],
      searchColumns: [],

      selected: [],
      columnVisibility: columnVisibility,
      columns: columns,
      rows: [],
      currentPage: 1,
      totalRows: 0,
      rowsPerPageSelected: -1,
      rowsPerPageOptions: [-1],
      
      isCookies: false,
      
      isIncludeInactive: false,

      isDisabledBatchAction: true,
      batchActionLoading: false,

      isLoadingFormDrawer: false,
      isVisibleFormDrawer: false,
      isBlockingFormDrawer: false,
      rowInitialValues: {
        isEdit: false,
        defaultUoM: ReportUOM.kg,
        defaultBillingUoM: ReportUOM.tons,
        timezoneUtcOffset: '',
        mapSettingsId: null,
        mapSettingsName: '',
        mapSettingsLat: '',
        mapSettingsLng: '',
        mapSettingsZoom: '',
        countryCode: '',
        currencyCode: '',
        currencyCodeValue: '',
        allowDriverMultipleJobs: false,
        showServiceItemNameInMobileApp: false,
      },
      rowValues: null,
    }
  }


  componentDidMount() {
    this.initDrawers();
    this.loadData();
  }
  
  componentWillReceiveProps(nextProps) {
    this.reduxProps(nextProps);
	}
  

	/* FUNCTIONS */
  reduxProps = nextProps => {
    Utils.reduxProps(nextProps,
      this.pageName + '-update_uom', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoadingFormDrawer: false,

          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          this.props.clear();

          if(this.state.isError){
          } else {
            this.props.fulfillUser(variables)
          }
          
          if(callback){
            callback(data);
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      this.pageName + '-update_timezone', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoadingFormDrawer: false,

          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          this.props.clear();
          
          if(this.state.isError){
          } else {
            this.props.fulfillUser(variables)
          }
          
          if(callback){
            callback(data);
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      this.pageName + '-get_account_setting_types', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        let isoCode = null, countryName = null, latitude = null, longitude = null, zoom = null, currencyCode = null, currencyCodeValue = null, allowDriverMultipleJobs = null, showServiceItemNameInMobileApp = null;

        if(data && data.length > 0){
          let isoCodeIndex = data.findIndex(x => x.type == AccountSettingType.DefaultMapPositionIsoCode);
          let countryNameIndex = data.findIndex(x => x.type == AccountSettingType.DefaultMapPositionCountryName);
          let latitudeIndex = data.findIndex(x => x.type == AccountSettingType.DefaultMapPositionLatitude);
          let longitudeIndex = data.findIndex(x => x.type == AccountSettingType.DefaultMapPositionLongitude);
          let zoomIndex = data.findIndex(x => x.type == AccountSettingType.DefaultMapPositionZoom);
          let currencyCodeIndex = data.findIndex(x => x.type == AccountSettingType.CurrencyCode);
          let currencyCodeValueIndex = data.findIndex(x => x.type == AccountSettingType.CurrencyCodeValue);
          let allowDriverMultipleJobsIndex = data.findIndex(x => x.type == AccountSettingType.AllowDriverMultipleJobs);
          let showServiceItemNameInMobileAppIndex = data.findIndex(x => x.type == AccountSettingType.ShowServiceItemNameInMobileApp);

          if(isoCodeIndex > -1){
            isoCode = data[isoCodeIndex];
          }

          if(countryNameIndex > -1){
            countryName = data[countryNameIndex];
          }

          if(latitudeIndex > -1){
            latitude = data[latitudeIndex];
          }

          if(longitudeIndex > -1){
            longitude = data[longitudeIndex];
          }

          if(zoomIndex > -1){
            zoom = data[zoomIndex];
          }

          if(currencyCodeIndex > -1){
            currencyCode = data[currencyCodeIndex];
          }

          if(currencyCodeValueIndex > -1){
            currencyCodeValue = data[currencyCodeValueIndex];
          }

          if(allowDriverMultipleJobsIndex > -1){
            allowDriverMultipleJobs = data[allowDriverMultipleJobsIndex];
          }

          if(showServiceItemNameInMobileAppIndex > -1){
            showServiceItemNameInMobileApp = data[showServiceItemNameInMobileAppIndex];
          }
        }

        this.setState({
          isLoadingFormDrawer: false,

          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          this.props.clear();
          
          if(callback){
            callback(isoCode, countryName, latitude, longitude, zoom, currencyCode, currencyCodeValue, allowDriverMultipleJobs, showServiceItemNameInMobileApp);
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      this.pageName + '-account_setting_update', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
          this.setState({
            isLoadingFormDrawer: false,
            
              isLoading: isLoading,
              isError: isError,
              err: err,
          }, () => {
            this.props.clear();
            
              if(callback){
                  callback();
              }
          });
      }
    );
  }

  initDrawers = () => {
    Utils.initDrawers(() => {
      this.formDrawer = Utils.getInstanceDrawer('form');
      Utils.onShowDrawer(this.formDrawer, null, () => {
        this.setState({
          isVisibleFormDrawer: true,
        });
      });
      Utils.onHideDrawer(this.formDrawer, this.refFormDrawer, () => {
        this.setState({
          isVisibleFormDrawer: false,
        });
      });
    });
  }

  setDataTable = () => {
    return <DataTable 
      ref={this.tableRef}

      className={"table-style-v4"}
      classNameWrapper={"table-v4"}

      pageName={this.pageName}
      rowId={'id'}
      caption={this.pageCaption}
      
      noMarginBottom={true}
      verticalAlign={'middle'}
      
      stickyHeader={false}
      isHeader={true}
      resizable={false}
      isDragableHeaderColumns={false}
      isDragable={false}
      isCheckbox={false}
      isBorder={false}
      isPagination={false}
      
      columnVisibility={this.state.columnVisibility}

      isLoading={this.state.isLoading}
      rowsPerPageSelected={this.state.rowsPerPageSelected}
      rowsPerPageOptions={this.state.rowsPerPageOptions}

      search={this.state.search}
      rows={this.state.rows}
      columns={this.state.columns}

      onRowClick={(id, row) => {
        let dataParams = {
          id: id,
          row: row,
        };
        Utils.setDrawerData(this.formDrawer, dataParams);
        Utils.showDrawer(this.formDrawer);
      }}

      onRemove={(row) => {

      }}

      onClickCheckAll={(obj, checked, isDisabledBatchAction) => {
        this.setState({
          isDisabledBatchAction: isDisabledBatchAction
        });
      }}
      onCheckRow={(obj, id, isDisabledBatchAction) => {
        this.setState({
          isDisabledBatchAction: isDisabledBatchAction
        });
      }}

      onSortEnd={(columns, columnVisibility) => {
        this.setState({
          columns: columns,
          columnVisibility: columnVisibility,
        });
      }}
      onOrder={(name, sort) => {
        this.setState({
          sortColumn: name,
          sortDir: sort,
        });
      }}
      onResize={(columns) => {
        if(columns){
          this.setState({
            columns: columns
          });
        }
      }}
    />
  }

  loadData = () => {
    Utils.showDrawer(this.formDrawer, false);

    this.callReadAccoutSettingsTypesApi((isoCode, countryName, latitude, longitude, zoom, currencyCode, currencyCodeValue, allowDriverMultipleJobs, showServiceItemNameInMobileApp) => {
      this.setRows(isoCode, countryName, latitude, longitude, zoom, currencyCode, currencyCodeValue, allowDriverMultipleJobs, showServiceItemNameInMobileApp);
    });
  }

  setRows = (isoCode, countryName, latitude, longitude, zoom, currencyCode, currencyCodeValue, allowDriverMultipleJobs, showServiceItemNameInMobileApp) => {
    let myMapRow = {
      mapSettingsId: (isoCode && isoCode.value && isoCode.value != '') ? isoCode.value : null,
      mapSettingsName: (countryName && countryName.value && countryName.value != '') ? countryName.value : '',
      countryCode: (countryName && countryName.value && countryName.value != '') ? countryName.value : '',
      mapSettingsLat: (latitude && latitude.value && latitude.value != '') ? latitude.value : '',
      mapSettingsLng: (longitude && longitude.value && longitude.value != '') ? longitude.value : '',
      mapSettingsZoom: (zoom && zoom.value && zoom.value != '') ? zoom.value : '',
      currencyCode: (currencyCode && currencyCode.value && currencyCode.value != '') ? currencyCode.value : '',
      currencyCodeValue: (currencyCodeValue && currencyCodeValue.value && currencyCodeValue.value != '') ? currencyCodeValue.value : '',
      allowDriverMultipleJobs: (allowDriverMultipleJobs && allowDriverMultipleJobs.value && allowDriverMultipleJobs.value != '') ? allowDriverMultipleJobs.value : 'false',
      showServiceItemNameInMobileApp: (showServiceItemNameInMobileApp && showServiceItemNameInMobileApp.value && showServiceItemNameInMobileApp.value != '') ? showServiceItemNameInMobileApp.value : 'false',
    }

		let user = (this.props.auth && this.props.auth.user) ? this.props.auth.user : null;
		let userAccount = (user && user.userAccount) ? user.userAccount : null;

    let rows = [
      {
        id: 1,
        label: defaultUoMLabel,
        ...userAccount,
        ...myMapRow,
      },
      {
        id: 5,
        label: defaultBillingUoMLabel,
        ...userAccount,
        ...myMapRow,
      },
      {
        id: 2,
        label: timezoneUtcOffsetLabel,
        ...userAccount,
        ...myMapRow,
      },
      {
        id: 3,
        label: mapSettingsIdLabel,
        ...userAccount,
        ...myMapRow,
      },
      {
        id: 6,
        label: countryCodeLabel,
        ...userAccount,
        ...myMapRow,
      },
      {
        id: 4,
        label: currencyCodeLabel,
        ...userAccount,
        ...myMapRow,
      },
      {
        id: 7,
        label: allowDriverMultipleJobsLabel,
        ...userAccount,
        ...myMapRow,
      },
      {
        id: 8,
        label: showServiceItemNameInMobileAppLabel,
        ...userAccount,
        ...myMapRow,
      }
    ];
    
    this.setState({
      rows: rows,
    });
  }

  prepareForm = (dataParams, data) => {
    if(data){
      let allowDriverMultipleJobs = Utils.strToBoolLowerCase(dataParams.row.allowDriverMultipleJobs);
      let showServiceItemNameInMobileApp = Utils.strToBoolLowerCase(dataParams.row.showServiceItemNameInMobileApp);
      let rowValues = {
        isEdit: true,

        ...dataParams.row,
        ...data,
        
        defaultUoM: dataParams.row.defaultUoM,
        timezoneUtcOffset: dataParams.row.timezoneUtcOffset,
        mapSettingsId: dataParams.row.mapSettingsId,
        mapSettingsName: dataParams.row.mapSettingsName,
        countryCode: dataParams.row.mapSettingsName,
        mapSettingsLat: dataParams.row.mapSettingsLat,
        mapSettingsLng: dataParams.row.mapSettingsLng,
        mapSettingsZoom: dataParams.row.mapSettingsZoom,
        currencyCode: dataParams.row.currencyCode,
        currencyCodeValue: dataParams.row.currencyCodeValue,
        allowDriverMultipleJobs: allowDriverMultipleJobs,
        showServiceItemNameInMobileApp: showServiceItemNameInMobileApp,
      };
  
      return rowValues;
    } else {
      return this.state.rowinitialValues;
    }
  }
  prepareData = (form, dataParams) => {
    let data = form;

    return data;
  }
  prepareMapData = (form, dataParams) => {
    let mapSettingsId = (form && form.mapSettingsId) ? form.mapSettingsId : null;
    let mapSettingsName = (form && form.mapSettingsName) ? form.mapSettingsName : '';
    let mapSettingsLat = (form && form.mapSettingsLat) ? form.mapSettingsLat : '';
    let mapSettingsLng = (form && form.mapSettingsLng) ? form.mapSettingsLng : '';
    let mapSettingsZoom = (form && form.mapSettingsZoom) ? form.mapSettingsZoom : '';
    let currencyCode = (form && form.currencyCode) ? form.currencyCode : '';
    let currencyCodeValue = (form && form.currencyCodeValue) ? form.currencyCodeValue : '';
    let allowDriverMultipleJobs = Utils.boolToStrLowerCase(form && form.allowDriverMultipleJobs);
    let showServiceItemNameInMobileApp = Utils.boolToStrLowerCase(form && form.showServiceItemNameInMobileApp);

    let data = {
      mapSettingsId: mapSettingsId,
      mapSettingsName: mapSettingsName,
      mapSettingsLat: mapSettingsLat,
      mapSettingsLng: mapSettingsLng,
      mapSettingsZoom: mapSettingsZoom,
      currencyCode: currencyCode,
      currencyCodeValue: currencyCodeValue,
      allowDriverMultipleJobs: allowDriverMultipleJobs,
      showServiceItemNameInMobileApp: showServiceItemNameInMobileApp,
    };

    return data;
  }
	/* END FUNCTIONS */

	
	/* API */
  callReadAccoutSettingsTypesApi = (callback = null) => {
		this.setState({
      isLoadingFormDrawer: true,
    });

    let types = [
      AccountSettingType.DefaultMapPositionIsoCode,
      AccountSettingType.DefaultMapPositionCountryName,
      AccountSettingType.DefaultMapPositionLatitude,
      AccountSettingType.DefaultMapPositionLongitude,
      AccountSettingType.DefaultMapPositionZoom,
      AccountSettingType.CurrencyCode,
      AccountSettingType.CurrencyCodeValue,
      AccountSettingType.AllowDefaultUomChange,
      AccountSettingType.AllowDriverMultipleJobs,
      AccountSettingType.ShowServiceItemNameInMobileApp
    ].join(',');

    let data = {
      types: types,
    }
    
    this.props.dispatchApiCallGet(data, this.pageName + '-get_account_setting_types', 'accountSetting', null, callback, () => {});
  }

  callUpdateApi = (mapData, callback = null) => {
    this.setState({
      isLoadingFormDrawer: true,
    });
    
    let data = {
      settings: [
        {      
          type: AccountSettingType.DefaultMapPositionIsoCode,
          value: mapData.mapSettingsId,
        },
        {
          type: AccountSettingType.DefaultMapPositionCountryName,
          value: mapData.mapSettingsName,
        },
        {
          type: AccountSettingType.DefaultMapPositionLatitude,
          value: mapData.mapSettingsLat,
        },
        {
          type: AccountSettingType.DefaultMapPositionLongitude,
          value: mapData.mapSettingsLng,
        },
        {
          type: AccountSettingType.DefaultMapPositionZoom,
          value: mapData.mapSettingsZoom,
        },
        {
          type: AccountSettingType.CurrencyCode,
          value: mapData.currencyCode,
        },
        {
          type: AccountSettingType.CurrencyCodeValue,
          value: mapData.currencyCodeValue,
        },
        {
          type: AccountSettingType.AllowDriverMultipleJobs,
          value: mapData.allowDriverMultipleJobs,
        },
        {
          type: AccountSettingType.ShowServiceItemNameInMobileApp,
          value: mapData.showServiceItemNameInMobileApp,
        }
      ]
    };
    this.props.dispatchApiCallPut(data, this.pageName + '-account_setting_update', 'AccountSetting/bulk', null, callback, () => {});
  }
  callUpdateUomApi = (data, callback = null) => {
    this.setState({
      isLoadingFormDrawer: true,
    });

    let defaultUoMData = {
      accountId: data.accountId,
      defaultUoM: data.defaultUoM,
    };

		let user = (this.props.auth && this.props.auth.user) ? this.props.auth.user : null;
    user.userAccount = data;

		this.props.commonProfileApiCallPut(defaultUoMData, this.pageName + '-update_uom', this.pageAPI + '/update/default-uom', user, callback, null);
  }
  callUpdateTimeZoneApi = (data, callback = null) => {
    this.setState({
      isLoadingFormDrawer: true,
    });

    let timezoneUtcOffsetData = {
      accountId: data.accountId,
      timezoneUtcOffset: data.timezoneUtcOffset,
    };

		let user = (this.props.auth && this.props.auth.user) ? this.props.auth.user : null;
    user.userAccount = data;

		this.props.commonProfileApiCallPut(timezoneUtcOffsetData, this.pageName + '-update_timezone', this.pageAPI + '/update/timezone', user, callback, null);
  }
	/* END API */
	
	
	/* SECTIONS */
	actionSection = () => {
		return <Row className={'align-items-center'}>

      {(Utils.hasPermission(this.permissions, PagesPermissions.ManageGeneral.read)) && <Col xs={'auto'} className={'mb-2 mb-sm-0'}>
        <Button
          variant={'success'}
          className={'pt-1 pb-1 rounded-1 '}
          onClick={(e) => {
            this.props.history.push('/manage/setting/general');
          }}
        >
          GENERAL
        </Button>
      </Col>}
      
      {(Utils.hasPermission(this.permissions, PagesPermissions.ManageSubscription.read)) && <Col xs={'auto'} className={'mb-2 mb-sm-0'}>
        <Button
          variant={'light'}
          className={'pt-1 pb-1 rounded-1 bg-white'}
          onClick={(e) => {
            this.props.history.push('/manage/setting/subscription');
          }}
        >
        SUBSCRIPTION
        </Button>
      </Col>}

      <Col xs={12} md={true} className={'order-1 order-md-3 pb-10 pb-md-0'}>
        <CurrentDateTime />
      </Col>
    </Row>
	}

	tableSection = () => {
		return <DataTableScrollSync ref={this.refDataTableScrollSync} className={"table-sync-v4"}>{this.setDataTable()}</DataTableScrollSync>
	}

  formDrawerSection = () => {
		return <DrawerLayout
      name={'form'}
      size={'700px'}
      ref={this.refFormDrawer}
      drawer={this.formDrawer}
      validationSchema={formSchema}
      initialValues={this.state.rowInitialValues}
      values={this.state.rowValues}
      isVisible={this.state.isVisibleFormDrawer}
      isBlocking={Utils.isBlockingDrawer(this.state.isLoadingFormDrawer, this.state.isBlockingFormDrawer)}
      renderChildren={Utils.shouldDrawerRenderChildren(this.state.isLoadingFormDrawer, this.state.isBlockingFormDrawer)}

      title={(data) => {
        if(data){
          return <div className={'d-flex align-items-center'}>
            <div className={'d-block'}>
              <div className={'pe-2'}>
                <span className={'pe-2'}>General</span>
                {/* <IconButton
                  icon={'history'}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                /> */}
              </div>
            </div>
          </div>;
        }
      }}
      toolbar={null}
      
      onOpenClose={(isOpen, dataParams) => {
        if(isOpen){
          let user = (this.props.auth && this.props.auth.user) ? this.props.auth.user : null;
          let userAccount = (user && user.userAccount) ? user.userAccount : null;

          let rowValues = this.prepareForm(dataParams, userAccount);

          this.setState({
            rowValues: rowValues,
          });
        }
      }}
      onSubmit={(form, dataParams) => {
        let data = this.prepareData(form, dataParams);
        let mapData = this.prepareMapData(form, dataParams);
        
        this.callUpdateUomApi(data, (res) => {
          this.callUpdateTimeZoneApi(data, (res) => {
            this.callUpdateApi(mapData, (res) => {
              window.location.reload();
            });
          });
        });
      }}
    >
      {(formOptions, dataParams) => {
        return <GeneralForm
          drawer={this.formDrawer}
          fields={formFields}
          initialValues={this.state.rowInitialValues}
          values={this.state.rowValues}
          formOptions={formOptions}
          dataParams={dataParams}
        />
      }}
    </DrawerLayout>
	}
	/* END SECTIONS */


  render() {
    return (
      <>
        <ViewLayout
          isBlocking={this.state.batchActionLoading}
          title={'SETTINGS'}
          col={<></>}
          actionSection={this.actionSection()}
          tableSection={this.tableSection()}
        />

        {this.formDrawerSection()}
      </>
    );
  }
}

const mapDispatchToProps = {
  commonProfileApiCallPut,
  dispatchApiCallGet,
  dispatchApiCallPut,
  fulfillUser,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(GeneralView);
