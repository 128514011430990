import React from "react";

import {
  Input,
  InputLabel,
  InputAdornment,
  FormControl,
  IconButton,
  Checkbox,
  Menu,
  Box,
  Grid,
  FormControlLabel,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Utils from "../../../utils/utils";

const DataTableSearch = (props) => {
  const {
    icon,
    label,
    placeholder,
    isfilterIncative,
    resultFilter,
    onPressEnter,
    onButtonClick,
    className,
  } = props;
  const inpurRef = React.useRef(null);

  const [value, setValue] = React.useState(props.value ? props.value : "");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isfilterIncativeVar, setisfilterIncativeVar] = React.useState(false);
  const open = Boolean(anchorEl);

  React.useEffect(() => {
    setValue(props.value ? props.value : "");
  }, [props.value]);

  const handleOnButtonClick = (e) => {
    e.stopPropagation();
    if (onButtonClick) {
      onButtonClick(inpurRef.current.lastChild.value);
    }
  };

  const handleOnPressEnter = (event) => {
    Utils.escapeChars(event);
    
    if (event.key === "Enter") {
      if (onPressEnter) {
        onPressEnter(event.target.value);
      }
    }
  };

  const handelPopoverChekbox = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handelPopoverChekboxClose = () => {
    setAnchorEl(null);
  };

  const handleFilterOutput = ()=> {
    if(isfilterIncativeVar === false ){
      setisfilterIncativeVar(true)
      resultFilter(true)

    }
    if(isfilterIncativeVar === true){
      setisfilterIncativeVar(false)
      resultFilter(false)
    }

  }

  return (
    <div className={"datatable-search-component " + className}>
      <FormControl style={{ width: "100%" }}>
        {label && (
          <InputLabel htmlFor="datatable-search-input">{label}</InputLabel>
        )}
        <Input
          id="datatable-search-input"
          style={{ height: "50px" }}
          ref={inpurRef}
          placeholder={placeholder}
          value={value}
          onKeyPress={handleOnPressEnter}
          onClick={(e) => {
            e.stopPropagation();
          }}
          startAdornment={
            <InputAdornment position="start">
              <IconButton onClick={handleOnButtonClick}>
                {icon ? icon : <SearchIcon />}
              </IconButton>
            </InputAdornment>
          }
          onChange={(e) => {
            setValue(e.target.value);
          }}
          endAdornment={(props.endAdornment)
            ?
            props.endAdornment
            :
            isfilterIncative === true ? (
              <>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handelPopoverChekbox}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={handelPopoverChekboxClose}
                  PaperProps={{
                    style: {
                      maxHeight: 28 * 4.5,
                      width: "inhert",
                    },
                  }}
                >
                  {/* if need some loop many choase */}
                  {/* {options.map((option) => (
          <MenuItem key={option} selected={option === 'Pyxis'} onClick={handleClose}>
            {option}
          </MenuItem>
        ))} */}

                  <Box pd={0} ml={2}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isfilterIncativeVar}
                            onChange={handleFilterOutput}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label="Show inactive sites"
                      />
                    </Grid>
                  </Box>
                </Menu>
              </>
            ) : null
          }
        />
      </FormControl>
    </div>
  );
};

export default DataTableSearch;
