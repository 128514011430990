import React from "react";
import InfiniteScroll from 'react-infinite-scroller';

import { 
    Row,
    Col,
    Form,
    Button,
    Dropdown,
    Badge,
    Spinner,
} from "react-bootstrap-v5";
import styled from 'styled-components';
import { Icon } from '@iconify/react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import Utils from "../../../utils/utils";
import { SortColumns } from "../../../utils/enums";

import WrappedButton from '../../../components/Buttons/WrappedButton';
import DropdownToggle from '../../../components/dropdown/DropdownToggle';
import SearchDialog from '../../../components/dialog/SearchDialog';
import Popup from '../../../components/popup/Popup';
import LoadingButton from '../../../components/Buttons/LoadingButton';
import RemoveDialog from '../../../components/dialog/RemoveDialog';

import CustomerSiteForm from "./CustomerSiteForm";

import { readApi } from '../../../api/CRUD/DispatchCRUD'

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut, dispatchApiCallDelete } from '../../../../setup/redux/dispatch/actions'


const StyledDropdownToggleText = styled.span`
    font-size: 14px;
`;
const StyledTabButton = styled.button`
    background-color: transparent;
    color: #185CFF;
    border-bottom: 2px solid transparent;
    padding-bottom: 7px;

    &:hover {
        border-bottom: 2px solid #185CFF;
    }

    ${props => {
        if(props.active){
            return 'color: #484848; border-bottom: 2px solid #185CFF;';
        }
    }}
`;

const StyledFormCheck = styled(Form.Check)`
    position: relative;
    border-bottom: 1px solid #F5F5F5;
    min-height: 60px;
    display: flex;
    align-items: center;

    .form-check-input[type=checkbox] {
        margin-left: 10px;
        border-radius: 0px;
        border: 0px;
        cursor: pointer;
        background-size: contain;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15'%3e%3cg id='Icon_ionic-ios-checkbox-outline' data-name='Icon ionic-ios-checkbox-outline' transform='translate(-2.5 -2.5)'%3e%3cpath id='Path_24397' data-name='Path 24397' d='M16.25,2.5H3.75A1.249,1.249,0,0,0,2.5,3.75v12.5A1.249,1.249,0,0,0,3.75,17.5h12.5a1.249,1.249,0,0,0,1.25-1.25V3.75A1.249,1.249,0,0,0,16.25,2.5Zm.156,13.594a.313.313,0,0,1-.312.313H3.906a.313.313,0,0,1-.312-.312V3.906a.313.313,0,0,1,.313-.312H16.094a.313.313,0,0,1,.313.313Z' fill='%23dfdfdf'/%3e%3cpath id='Path_24398' data-name='Path 24398' d='M14.2,7.535l-.687-.707a.148.148,0,0,0-.109-.047h0a.142.142,0,0,0-.109.047l-4.766,4.8L6.8,9.895a.151.151,0,0,0-.219,0l-.7.7a.156.156,0,0,0,0,.223L8.07,13a.692.692,0,0,0,.457.223.725.725,0,0,0,.453-.215h0l5.223-5.25A.167.167,0,0,0,14.2,7.535Z' fill='%23dfdfdf'/%3e%3c/g%3e%3c/svg%3e");
        
        &:checked{
            background-color: transparent;
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15'%3e%3cg id='Icon_ionic-ios-checkbox-outline' data-name='Icon ionic-ios-checkbox-outline' transform='translate(-2.5 -2.5)'%3e%3cpath id='Path_24397' data-name='Path 24397' d='M16.25,2.5H3.75A1.249,1.249,0,0,0,2.5,3.75v12.5A1.249,1.249,0,0,0,3.75,17.5h12.5a1.249,1.249,0,0,0,1.25-1.25V3.75A1.249,1.249,0,0,0,16.25,2.5Zm.156,13.594a.313.313,0,0,1-.312.313H3.906a.313.313,0,0,1-.312-.312V3.906a.313.313,0,0,1,.313-.312H16.094a.313.313,0,0,1,.313.313Z' fill='%23185cff'/%3e%3cpath id='Path_24398' data-name='Path 24398' d='M14.2,7.535l-.687-.707a.148.148,0,0,0-.109-.047h0a.142.142,0,0,0-.109.047l-4.766,4.8L6.8,9.895a.151.151,0,0,0-.219,0l-.7.7a.156.156,0,0,0,0,.223L8.07,13a.692.692,0,0,0,.457.223.725.725,0,0,0,.453-.215h0l5.223-5.25A.167.167,0,0,0,14.2,7.535Z' fill='%23185cff'/%3e%3c/g%3e%3c/svg%3e");
        }
    }

    label {
        position: relative;
        left: 10px;
        width: 100%;
        height: 100%;
        padding: 0px 10px 0px 0px;
        cursor: pointer;
        
        display: flex;
        align-items: center;
        
        width: 100%;
    
        ${(props) => {
            return props.isActive ? 'color: #185CFF;' : 'color: #FF0090;';
        }}

        &.text-secondary {
            color: #7B7B7B !important;
            font-size: 12px;
        }
    }

    .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
        opacity: 1;
    }

    ${(props) => {
        return props.active ? 'background-color: #ECF5DF;' : '';
    }}
`;
const StyledSortItem = styled(Dropdown.Item)`
    position: relative;
    display: flex;
    align-items: center;

    .custom-title {
        display: inline-block;
        flex-basis: 100%;
        width: 130px;
        white-space: pre-wrap;
        color: #484848;

        ${(props) => {
            return (props.active) ? 'font-weight: bold;' : '';
        }}
    }

    .custom-icons {
        display: inline-block;
        position: relative;
        width: 20px;
        height: 20px;
        overflow: hidden;
        background-color: transparent !important;

        .custom-icon-up {
            overflow: hidden;
            position: relative;
            height: 12px;

            > div {
                position: absolute;
                top: -6px;

                i {
                    ${(props) => {
                        return (props.active && props.direction == 'asc') ? 'color: #484848;' : 'color: #B8B8B8;';
                    }}

                    &:hover {
                        color: #484848;
                    }
                }
            }

            ${(props) => {
                return (props.active) ? 'display: block;' : 'display: none;';
            }}
        }

        .custom-icon-down {
            overflow: hidden;
            position: relative;
            height: 12px;

            > div {
                position: absolute;
                top: -9px;

                i {
                    ${(props) => {
                        return (props.active && props.direction == 'desc') ? 'color: #484848;' : 'color: #B8B8B8;';
                    }}

                    &:hover {
                        color: #484848;
                    }
                }
            }

            ${(props) => {
                return (props.active) ? 'display: block;' : 'display: none;';
            }}
        }
    }
    
    &.active, &:active, &:focus {
        ${(props) => {
            return props.active ? 'background-color: #ECECEC !important;' : 'background-color: transparent !important;';
        }}
    }

    &:hover {
        .custom-icons {
            .custom-icon-up {
                display: block;
            }
            .custom-icon-down {
                display: block;
            }
        }
    }
`;

const StyledBadge = styled(Badge)`
    background-color: transparent;
    border: 1px solid #DFDFDF;
    font-weight: normal;
`;


class CustomerSitesApplicableServices extends React.Component {
  
    constructor(props) {
        super(props);

        this.refForm = React.createRef();

        let customerId = Utils.getIntProps(props, 'customerId');
        let id = Utils.getIntProps(props);
        
        this.pageName = 'sites_applicable_services';
        this.backLink = '/Customer-overview-sites?id=' + customerId;
        
        let user = (props && props.auth && props.auth.user) ? props.auth.user : null;
        this.permissions = (user && user.permissions && user.permissions.length > 0) ? user.permissions : [];
        

        
        this.state = {
            customerId: customerId,
            id: id,
            details: null,
            isLoading: false,

            rightItems: [],
            rightSearch: '',
            rightSortColumn: 'serviceTag',
            rightSortDir: 'asc',
            
            popupShowForm: false,
            popupLoadingForm: false,
            popupIdForm: 0,
            popupIsCopyForm: false,

            leftItems: [],
            leftSearch: '',
            leftSortColumn: 'updated',
            leftSortDir: 'desc',
            leftCurrentPage: 1,
            leftPageSize: 30,
            leftTotal: 0,
            leftIsIncludeInactive: null,
            leftIsInactive: false,
            leftIsSearchDialog: false,
            sortColumnsLoading: false,
            sortColumns: SortColumns.customerSite,
            
            isRemoveDialog: false,
        };
    }


    componentDidMount(){
        this.setLayoutForm({
            leftHeaderContent: this.leftHeader,
            leftBodyContent: this.leftBody,
            rightHeaderContent: this.rightHeader,
            rightHeaderButtons: this.rightHeaderButtons,
            rightHeaderTabs: this.rightTabs,
        });

        this.callInitApis();
    }

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            this.pageName + '-read', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = [];
                if(data && data.data && data.data.length > 0){
                    arr = data.data.map((item, i) => {
                        return {
                            value: item.customerSiteId,
                            title: item.siteNameDisplay,
                            checked: false,
                            disabled: false,
                            active: (item.customerSiteId === this.state.id) ? true : false,
                            isActive: !item.isDeactivateOrDelete,
                            item: item,
                        }
                    });

                    arr = arr.sort((x, y) => {
                        return (x.active === y.active) ? 0 : x.active ? -1 : 1;
                    });
                }

                let total = (data && data.total) ? data.total : 0;

                this.setState({
                    leftTotal: total,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            this.pageName + '-read_more', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if (data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.customerSiteId;
                        let title = item.siteNameDisplay;
                        let arrItem = {
                            value: value,
                            title: title,
                            checked: false,
                            disabled: false,
                            active: (item.customerSiteId === this.state.id) ? true : false,
                            isActive: !item.isDeactivateOrDelete,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }

                    arr = arr.sort((x, y) => {
                        return (x.active === y.active) ? 0 : x.active ? -1 : 1;
                    });
                }

                let total = (data && data.total) ? data.total : 0;

                this.setState({
                    leftItems: arr,
                    leftTotal: total,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback();
                    }
                });
            }
        );
    
        Utils.reduxProps(nextProps,
            this.pageName + '-delete', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(data, isError);
                    }
                });
            }
        );
    
        Utils.reduxProps(nextProps,
            this.pageName + '-bulk-update', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(isError){
                        Utils.toast(<div style={{ fontWeight: 'bold' }}>{(err && err != '') ? err : 'An error has occurred'}</div>, 'error');
                    } else {
                        Utils.toast(<div style={{ fontWeight: 'bold' }}>{(data && data != '') ? data : 'Changes saved'}</div>, 'success');
                    }

                    if(callback){
                        callback(data, isError);
                    }
                });
            }
        );
    }
	
    callInitApis = () => {
        Utils.callAllApi([
            {
                api: readApi,
                path: 'customersite',
                params: () => {
                    let data = {
                        currentPage: this.state.leftCurrentPage,
                        pageSize: this.state.leftPageSize,
                        searchQuery: this.state.leftSearch,
                        sortColumn: this.state.leftSortColumn,
                        sortDir: this.state.leftSortDir,
                        customerId: this.state.customerId,
                    };
            
                    let leftIsIncludeInactive = ((this.state.leftIsIncludeInactive === true) || (this.state.leftIsIncludeInactive === false)) ? this.state.leftIsIncludeInactive : null;
                    if(leftIsIncludeInactive){
                        data['isIncludeInactive'] = leftIsIncludeInactive;
                    }

                    let leftIsInactive = ((this.state.leftIsInactive === true) || (this.state.leftIsInactive === false)) ? this.state.leftIsInactive : null;
                    if(leftIsInactive){
                        data['isInactive'] = leftIsInactive;
                    }

                    return data;
                },
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        let arr = [];
                        if(data && data.data && data.data.length > 0){
                            arr = data.data.map((item, i) => {
                                return {
                                    value: item.customerSiteId,
                                    title: item.siteNameDisplay,
                                    checked: false,
                                    disabled: false,
                                    active: (item.customerSiteId === this.state.id) ? true : false,
                                    isActive: !item.isDeactivateOrDelete,
                                    item: item,
                                }
                            });

                            arr = arr.sort((x, y) => {
                                return (x.active === y.active) ? 0 : x.active ? -1 : 1;
                            });
                        }

                        let total = (data && data.total) ? data.total : 0;

                        this.setState({
                            leftItems: arr,
                            leftTotal: total,
                        }, () => {
                            this.refreshLayout();
                        });
                    });
                }
            },
            {
                api: readApi,
                path: 'customersite/' + this.state.id,
                params: null,
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        this.setState({
                            details: data,
                        }, () => {
                            this.refreshLayout();
                        });
                    });
                }
            },
            {
                api: readApi,
                path: 'service-item',
                params: () => {
                    let data = {
                        currentPage: 1,
                        pageSize: Utils.getMaxPageSize(),
                        searchQuery: this.state.rightSearch,
                        sortColumn: this.state.rightSortColumn,
                        sortDir: this.state.rightSortDir,
                        customerId: this.state.customerId,
                        customerSiteId: this.state.id,
                    };
            
                    return data;
                },
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        this.setState({
                            rightItems: (data && data.data && data.data.length > 0) ? data.data : [],
                        }, () => {
                            this.refreshLayout();
                        });
                    });
                }
            },
        ], (result) => {
            this.setLoading(true);
        }, (result) => {
            this.setLoading(false);
            this.refreshLayout();
        });
    }

    callDetailsApis = (additionalParams = null) => {
        Utils.callAllApi([
            {
                api: readApi,
                path: 'customersite/' + this.state.id,
                params: null,
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        this.setState({
                            details: data,
                        }, () => {
                            this.refreshLayout();
                        });
                    });
                }
            },
            {
                api: readApi,
                path: 'service-item',
                params: () => {
                    let data = {
                        currentPage: 1,
                        pageSize: Utils.getMaxPageSize(),
                        searchQuery: this.state.rightSearch,
                        sortColumn: this.state.rightSortColumn,
                        sortDir: this.state.rightSortDir,
                        customerId: this.state.customerId,
                        customerSiteId: this.state.id,
                    };
            
                    return data;
                },
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        this.setState({
                            rightItems: (data && data.data && data.data.length > 0) ? data.data : [],
                        }, () => {
                            this.refreshLayout();
                        });
                    });
                }
            },
        ], (result) => {
            this.setLoading(true);
        }, (result) => {
            if(additionalParams){
                this.setState(additionalParams);
            }

            this.setLoading(false);
            this.refreshLayout();
        });
    }

    callReadApi = (callback = null) => {
        this.setLoading(true);
        
        let data = {
            currentPage: this.state.leftCurrentPage,
            pageSize: this.state.leftPageSize,
            searchQuery: this.state.leftSearch,
            sortColumn: this.state.leftSortColumn,
            sortDir: this.state.leftSortDir,
            customerId: this.state.customerId,
        };
   
        let leftIsIncludeInactive = ((this.state.leftIsIncludeInactive === true) || (this.state.leftIsIncludeInactive === false)) ? this.state.leftIsIncludeInactive : null;
        if(leftIsIncludeInactive){
            data['isIncludeInactive'] = leftIsIncludeInactive;
        }

        let leftIsInactive = ((this.state.leftIsInactive === true) || (this.state.leftIsInactive === false)) ? this.state.leftIsInactive : null;
        if(leftIsInactive){
            data['isInactive'] = leftIsInactive;
        }

        this.props.dispatchApiCallGet(data, this.pageName + '-read', 'customersite', null, callback, () => {});
    }
    
    callReadMoreApi = (callback = null) => {
        this.setLoading(true);
        
        let data = {
            currentPage: this.state.leftCurrentPage,
            pageSize: this.state.leftPageSize,
            searchQuery: this.state.leftSearch,
            sortColumn: this.state.leftSortColumn,
            sortDir: this.state.leftSortDir,
            customerId: this.state.customerId,
        };
   
        let leftIsIncludeInactive = ((this.state.leftIsIncludeInactive === true) || (this.state.leftIsIncludeInactive === false)) ? this.state.leftIsIncludeInactive : null;
        if(leftIsIncludeInactive){
            data['isIncludeInactive'] = leftIsIncludeInactive;
        }

        let leftIsInactive = ((this.state.leftIsInactive === true) || (this.state.leftIsInactive === false)) ? this.state.leftIsInactive : null;
        if(leftIsInactive){
            data['isInactive'] = leftIsInactive;
        }

        this.props.dispatchApiCallGet(data, this.pageName + '-read_more', 'customersite', { items: this.state.leftItems }, callback, () => {});
    }

    callDeleteApi = (callback = null) => {
        this.setLoading(true);

        let data = { 
            customerSiteId: this.state.id,
            IsDeactivateOrDelete: true,
        };

        this.props.dispatchApiCallDelete(data, this.pageName + '-delete', 'customersite', data, callback, null);
    }

    callBuldUpdateApi = (data, callback = null) => {
        this.setLoading(true);
        this.props.dispatchApiCallPut(data, this.pageName + '-bulk-update', 'customerSite/bulk', data, callback, () => {});
    }
    /* END API */


    /* LAYOUT FUNCTIONS */
    setLayoutForm = (options) => {
        if(this.props.layoutRef && this.props.layoutRef.current){
            this.props.layoutRef.current.setLayoutForm(this, options);
        }
    }
    setLayoutLoading = (state = false) => {
        if(this.props.layoutRef && this.props.layoutRef.current){
            this.props.layoutRef.current.setLayoutLoading(state);
        }
    }
    refreshLayout = () => {
        if(this.props.layoutRef && this.props.layoutRef.current){
            this.props.layoutRef.current.refreshLayout();
        }
    }
    /* END LAYOUT FUNCTIONS */


    /* FUNCTIONS */
    setLoading = (state = false) => {
        this.setLayoutLoading(state);

        this.setState({
            isLoading: state,
        });

        if(this.props.onLoading){
            this.props.onLoading(state);
        }
    }

    getFirstId = () => {
        if(this.state.leftItems && this.state.leftItems.length > 0){
            let item = this.state.leftItems[0]
            
            this.setState({
                id: item.value,
                details: null,
                leftItems: [],
            }, () => {
                this.props.history.push('/Customer-site-applicable-services?customerId=' + this.state.customerId + '&id=' + this.state.id);

                this.state.leftItems.forEach(x => x.active = false);
                item.active = true;
                this.refreshLayout();
                
                this.callInitApis();
            });
        } else {
            this.props.history.push('/Customer-overview');
        }
    }
    /* END FUNCTIONS */


    /* FORM */
    leftHeader = () => {
        return <Row className={'align-items-center gx-1'}>
            <Col xs={true}>
                <Dropdown alignRight>
                    <Dropdown.Toggle as={DropdownToggle} className={'px-0'}>
                        <StyledDropdownToggleText>{Utils.getAllActiveInactiveText(this.state.leftIsIncludeInactive, this.state.leftIsInactive)} Items</StyledDropdownToggleText>
                        <ArrowDropDownIcon />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            active={(this.state.leftIsIncludeInactive && this.state.leftIsInactive == null)}
                            onClick={() => {
                                this.setState({
                                    leftCurrentPage: 1,
                                    leftSearch: '',
                                    leftIsIncludeInactive: true,
                                    leftIsInactive: null,
                                }, () => {
                                    this.callReadApi((items) => {
                                        this.setState({
                                            leftItems: items,
                                        }, () => {
                                            this.setLoading(false);
                                            this.refreshLayout();
                                        });
                                    });
                                });
                            }}
                        >All</Dropdown.Item>
                        <Dropdown.Item
                            active={(this.state.leftIsIncludeInactive == null && !this.state.leftIsInactive)}
                            onClick={() => {
                                this.setState({
                                    leftCurrentPage: 1,
                                    leftSearch: '',
                                    leftIsIncludeInactive: null,
                                    leftIsInactive: false,
                                }, () => {
                                    this.callReadApi((items) => {
                                        this.setState({
                                            leftItems: items,
                                        }, () => {
                                            this.setLoading(false);
                                            this.refreshLayout();
                                        });
                                    });
                                });
                            }} 
                        >Active</Dropdown.Item>
                        <Dropdown.Item
                            active={(this.state.leftIsIncludeInactive == null && this.state.leftIsInactive)}
                            onClick={() => {
                                this.setState({
                                    leftCurrentPage: 1,
                                    leftSearch: '',
                                    leftIsIncludeInactive: null,
                                    leftIsInactive: true,
                                }, () => {
                                    this.callReadApi((items) => {
                                        this.setState({
                                            leftItems: items,
                                        }, () => {
                                            this.setLoading(false);
                                            this.refreshLayout();
                                        });
                                    });
                                });
                            }} 
                        >Inactive</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
            <Col xs={'auto'}>
                <WrappedButton
                    className={'px-3 pe-1'}
                    variant={"primary"}
                    size={'sm'}
                    onClick={() => {
                        this.setState({
                          popupShowForm: true,
                          popupLoadingForm: false,
                          popupIdForm: 0,
                          popupIsCopyForm: false,
                        });
                    }}
                >
                    <i className={'material-icons'}>add</i>
                </WrappedButton>
            </Col>
            <Col xs={'auto'}>
                <Dropdown alignRight>
                    <Dropdown.Toggle
                        as={DropdownToggle}
                        isButton
                        className={'px-3 pe-1'}
                        variant={"light"}
                        size={'sm'}
                        style={{ backgroundColor: 'transparent' }}
                    >
                        <i className={'material-icons'}>format_list_bulleted</i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Header>SORT BY</Dropdown.Header>

                        {this.state.sortColumns.map((item, i) => {
                            return <StyledSortItem
                                key={i}
                                active={(this.state.leftSortColumn == item.value) ? true : false}
                                direction={this.state.leftSortDir}
                                disabled={false}
                                onClick={() => {
                                    this.setState({
                                        sortColumnsLoading: true,
                                        leftCurrentPage: 1,
                                        leftSortColumn: item.value,
                                        leftSortDir: 'asc',
                                    }, () => {
                                        this.callReadApi((items) => {
                                            this.setState({
                                                sortColumnsLoading: false,
                                                leftItems: items,
                                            }, () => {
                                                this.setLoading(false);
                                                this.refreshLayout();
                                            });
                                        });
                                    });
                                }}
                            >
                                <div className={'custom-title'}>{item.title}</div>
                                <div className={'custom-icons'}>
                                    <div className={'custom-icon-up'}>
                                        <div>
                                            <i className={'material-icons ' + ((this.state.leftSortDir == 'asc') ? true : false)}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();

                                                    this.setState({
                                                        sortColumnsLoading: true,
                                                        leftCurrentPage: 1,
                                                        leftSortColumn: item.value,
                                                        leftSortDir: 'asc',
                                                    }, () => {
                                                        Utils.clickOutside();
                                                        this.callReadApi((items) => {
                                                            this.setState({
                                                                sortColumnsLoading: false,
                                                                leftItems: items,
                                                            }, () => {
                                                                this.setLoading(false);
                                                                this.refreshLayout();
                                                            });
                                                        });
                                                    });
                                                }}
                                            >arrow_drop_up</i>
                                        </div>
                                    </div>
                                    <div className={'custom-icon-down'}>
                                        <div>
                                            <i className={'material-icons ' + ((this.state.leftSortDir == 'desc') ? true : false)}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();

                                                    this.setState({
                                                        sortColumnsLoading: true,
                                                        leftCurrentPage: 1,
                                                        leftSortColumn: item.value,
                                                        leftSortDir: 'desc',
                                                    }, () => {
                                                        Utils.clickOutside();
                                                        this.callReadApi((items) => {
                                                            this.setState({
                                                                sortColumnsLoading: false,
                                                                leftItems: items,
                                                            }, () => {
                                                                this.setLoading(false);
                                                                this.refreshLayout();
                                                            });
                                                        });
                                                    });
                                                }}
                                            >arrow_drop_down</i>
                                        </div>
                                    </div>
                                </div>
                            </StyledSortItem>
                        })}
                        
                        <Dropdown.Divider />

                        <Dropdown.Item
                            active={false}
                            disabled={false}
                            onClick={() => {
                                this.setState({
                                    sortColumnsLoading: true,
                                    leftCurrentPage: 1,
                                    leftSearch: '',
                                    leftSortColumn: 'updated',
                                    leftSortDir: 'desc',
                                }, () => {
                                    this.callReadApi((items) => {
                                        this.setState({
                                            sortColumnsLoading: false,
                                            leftItems: items,
                                        }, () => {
                                            this.setLoading(false);
                                            this.refreshLayout();
                                        });
                                    });
                                });
                            }} 
                        >Refresh List</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
            <Col xs={'auto'}>
                <WrappedButton
                    className={'px-3 pe-1'}
                    variant={"light"}
                    size={'sm'}
                    style={{ backgroundColor: 'transparent' }}
                    onClick={() => {
                        this.setState({
                            leftIsSearchDialog: true,
                        });
                    }}
                >
                    <i className={'material-icons'}>search</i>
                </WrappedButton>
            </Col>
            {this.leftClearSearch()}
        </Row>
    }

    leftBody = () => {
        return <div style={{ width: '100%' }}>
            {
            (this.state.leftItems && this.state.leftItems.length > 0)
            ?
            <>
                <InfiniteScroll
                    loader={<div key={0} className={'text-center pt-2'}><Spinner animation="border" size={'sm'} /></div>}
                    useWindow={false}
                    initialLoad={false}
                    getScrollParent={() => document.getElementById((window.innerWidth < 992) ? 'left_panel_1_ID' : 'left_panel_2_ID')}
                    threshold={1}

                    pageStart={1}
                    hasMore={((this.state.leftTotal > this.state.leftPageSize) && (this.state.leftItems.length < this.state.leftTotal))}

                    loadMore={(page) => {
                        if((this.state.leftTotal > this.state.leftPageSize) && (this.state.leftItems.length < this.state.leftTotal)){
                            this.setState({
                                leftCurrentPage: (this.state.leftCurrentPage+1)
                            }, () => {
                                this.callReadMoreApi(() => {
                                    this.setLoading(false);
                                    this.refreshLayout();
                                });
                            });
                        }
                    }}
                >
                    {this.state.leftItems.map((item, i) => {
                        return <div key={i}
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                                if(!item.active){
                                    this.setState({
                                        id: item.value,
                                        details: null,
                                        rightItems: [],
                                    }, () => {
                                        this.props.history.push('/Customer-site-applicable-services?customerId=' + this.state.customerId + '&id=' + this.state.id);

                                        this.state.leftItems.forEach(x => x.active = false);
                                        item.active = true;
                                        this.refreshLayout();
                                        
                                        this.callDetailsApis();
                                    });
                                }
                            }}
                        >
                            <StyledFormCheck
                                type={'checkbox'}
                                active={item.active}
                                isActive={item.isActive}
                            >
                                <Row className={'align-items-center g-0'}>
                                    {/* <Col xs={'auto'}>
                                        <Form.Check.Input
                                            type={'checkbox'}
                                            checked={item.checked}
                                            disabled={item.disabled}
                                            onChange={(e) => {
                                                item.checked = e.target.checked;
                                                this.refreshLayout();
                                            }}
                                        />
                                    </Col> */}
                                    <Col xs={true}>
                                        <Row className={'align-items-center g-0'}>
                                            <Col xs={12}>
                                                <Form.Check.Label
                                                    // onClick={(e) => {
                                                    //     if(!item.active){
                                                    //         this.setState({
                                                    //             id: item.value,
                                                    //             details: null,
                                                    //             rightItems: [],
                                                    //         }, () => {
                                                    //             this.props.history.push('/Customer-site-applicable-services?customerId=' + this.state.customerId + '&id=' + this.state.id);
                            
                                                    //             this.state.leftItems.forEach(x => x.active = false);
                                                    //             item.active = true;
                                                    //             this.refreshLayout();
                                                                
                                                    //             this.callDetailsApis();
                                                    //         });
                                                    //     }
                                                    // }}
                                                >{item.title}</Form.Check.Label>
                                            </Col>
                                            {!this.state.sortColumnsLoading && <Col xs={12}>
                                                <Form.Check.Label
                                                    className={'text-secondary'}
                                                    onClick={(e) => {
                                                        if(!item.active){
                                                            this.setState({
                                                                id: item.value,
                                                                details: null,
                                                                rightItems: [],
                                                            }, () => {
                                                                this.props.history.push('/Customer-site-applicable-services?customerId=' + this.state.customerId + '&id=' + this.state.id);
                            
                                                                this.state.leftItems.forEach(x => x.active = false);
                                                                item.active = true;
                                                                this.refreshLayout();
                                                                
                                                                this.callDetailsApis();
                                                            });
                                                        }
                                                    }}
                                                >{Utils.findSortItemTemplate(this.state.sortColumns, this.state.leftSortColumn, ((item && item.item) ? item.item : null))}</Form.Check.Label>
                                            </Col>}
                                        </Row>
                                    </Col>
                                </Row>
                            </StyledFormCheck>
                        </div>
                    })}
                </InfiniteScroll>

                
                
                {/* {((this.state.leftTotal > this.state.leftPageSize) && (this.state.leftItems.length < this.state.leftTotal)) && <div className={'pt-3 pb-3'}>
                    <a href={'/'}
                        className={'link-primary'}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            
                            this.setState({
                                leftCurrentPage: (this.state.leftCurrentPage + 1)
                            }, () => {
                                this.callReadMoreApi(() => {
                                    this.setLoading(false);
                                    this.refreshLayout();
                                });
                            });
                        }}
                    >
                        Show More
                    </a>
                </div>} */}
            </>
            :
            <h6 className={'text-center pt-5 m-0'}>No Results!</h6>
            }
        </div>
    }

    leftClearSearch = () => {
        if(this.state.leftSearch !== ''){
            return <Col xs={12} className={'text-end'}>
                <a href={'/'}
                    className={'link-primary'}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        this.setState({
                            leftCurrentPage: 1,
                            leftSearch: '',
                            leftIsSearchDialog: false,
                        }, () => {
                            this.callReadApi((items, total) => {
                                this.setState({
                                    leftItems: items,
                                    leftTotal: total,
                                }, () => {
                                    this.setLoading(false);
                                    this.refreshLayout();
                                });
                            });
                        });
                    }}
                >
                    Clear Search
                </a>
            </Col>
        }
    }

    rightHeader = () => {
        if((this.state.customerId) && (this.state.id > 0) && this.state.details){
            return <Row className={'align-items-center justify-content-center justify-content-md-start py-5'}>
                <Col xs={'auto'}>
                    <h2 className={'m-0'}>{(this.state.details && this.state.details.siteNameDisplay && this.state.details.siteNameDisplay != '') ? this.state.details.siteNameDisplay : <>&nbsp;</>}</h2>
                </Col>
                <Col xs={'auto'}>
                    {this.state.details && <StyledBadge 
                        pill 
                        className={'px-5 py-2'} 
                        bg={!this.state.details.isDeactivateOrDelete ? 'default' : 'danger'}
                        text={!this.state.details.isDeactivateOrDelete ? 'dark' : 'light'}
                    >
                        {!this.state.details.isDeactivateOrDelete ? 'Active' : 'Inactive'}
                    </StyledBadge>}
                </Col>
            </Row>
        } else {
            return <Row className={'align-items-center justify-content-center justify-content-md-start py-5'}>
                <Col xs={'auto'}>
                    <h2 className={'m-0'}>{<>&nbsp;</>}</h2>
                </Col>
            </Row>
        }
    }

    rightHeaderButtons = () => {
        if((this.state.customerId) && (this.state.id > 0) && this.state.details){
            return <Row className={'align-items-center'}>
                <Col xs={true} sm={'auto'} className={'text-end text-sm-center'}>
                    <WrappedButton
                        className={'px-3'}
                        variant={"light"}
                        size={'sm'}
                        style={{ border: '1px solid #ECECEC' }}
                        onClick={() => {
                            this.setState({
                                popupShowForm: true,
                                popupLoadingForm: true,
                                popupIdForm: this.state.id,
                                popupIsCopyForm: false,
                            });
                        }}
                    >
                        <Icon icon="feather:edit-2" width="17" />
                    </WrappedButton>
                </Col>
                <Col xs={true} sm={'auto'} className={'text-start text-sm-center'}>
                    <Dropdown alignRight>
                        <Dropdown.Toggle
                            as={DropdownToggle}
                            isButton
                            className={'px-3 pe-1'}
                            variant={"light"}
                            size={'sm'}
                            style={{ border: '1px solid #ECECEC' }}
                        >
                            More
                            <i className={'material-icons'}>arrow_drop_down</i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {/* <Dropdown.Item
                                active={false}
                                disabled={false}
                                onClick={() => {
                                    this.setState({
                                        popupShowForm: true,
                                        popupLoadingForm: false,
                                        popupIdForm: this.state.id,
                                        popupIsCopyForm: true,
                                    });
                                }}
                            >Copy</Dropdown.Item> */}
                            {(this.state.details && !this.state.details.haveJobs) && <Dropdown.Item
                                active={false}
                                disabled={false}
                                onClick={() => {
                                    this.setState({
                                        isRemoveDialog: true,
                                    });
                                }} 
                            >Delete</Dropdown.Item>}
                            <Dropdown.Item
                                active={false}
                                onClick={() => {
                                    let data = {
                                        ids: this.state.id.toString(),
                                        isActive: false,
                                    }

                                    this.callBuldUpdateApi(data, () => {
                                        this.setLoading(false);
                                        this.callInitApis();
                                    });
                                }} 
                            >Make Inactive</Dropdown.Item>
                            <Dropdown.Item
                                active={false}
                                onClick={() => {
                                    let data = {
                                        ids: this.state.id.toString(),
                                        isActive: true,
                                    }

                                    this.callBuldUpdateApi(data, () => {
                                        this.setLoading(false);
                                        this.callInitApis();
                                    });
                                }} 
                            >Make active</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>
        }
    }

    rightTabs = () => {
        if((this.state.customerId) && (this.state.id > 0) && this.state.details){
            return <Row className={'gx-md-0 gx-lg-5 align-items-center justify-content-center justify-content-md-start'}>
                <Col xs={'auto'}>
                    <StyledTabButton
                        active={false}
                        onClick={() => {
                            this.props.history.push('/Customer-site-overview?customerId=' + this.state.customerId + '&id=' + this.state.id);
                        }}
                    >Overview</StyledTabButton>
                </Col>
                <Col xs={'auto'}>
                    <StyledTabButton
                        active={true}
                        onClick={() => {
                            this.props.history.push('/Customer-site-applicable-services?customerId=' + this.state.customerId + '&id=' + this.state.id);
                        }}
                    >Applicable services</StyledTabButton>
                </Col>
                <Col xs={'auto'}>
                    <StyledTabButton
                        active={false}
                        disabled={false}
                        onClick={() => {
                            this.props.history.push('/Customer-site-overview-history?customerId=' + this.state.customerId + '&id=' + this.state.id);
                        }}
                    >History</StyledTabButton>
                </Col>
            </Row>
        }
    }

    rightBody = () => {
        if(!this.state.isLoading){
            if(this.state.customerId > 0){
                if(this.state.id > 0){
                    if(this.state.details){
                        return <Row className={'gx-0'}>
                            <Col xs={12} lg={8} xl={8}>
                                <div className={'px-6 py-6'}>

                                <Row className={'mb-5'}>
                                        <Col xs={12} lg={6}>
                                            <Form.Control 
                                                ref={this.rightSearchInputRef}
                                                type={'search'}
                                                placeholder={'Search...'}
                                                autoFocus
                                                size={'sm'}
                                                onChange={(e) => {
                                                    this.setState({
                                                        rightSearch: e.target.value,
                                                    }, () => {
                                                        this.callDetailsApis();
                                                    });
                                                }}
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={12} lg={1}><b>#</b></Col>
                                        <Col xs={12} lg={11}><b>Service Name</b></Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col xs={12}><hr className={'m-2'} /></Col>
                                    </Row>

                                    <Row className={'mb-3'}>
                                        <Col xs={12}>
                                            {
                                            (this.state.rightItems && this.state.rightItems.length > 0)
                                            ?
                                            this.state.rightItems.map((item, i) => {
                                                return <div key={i}>

                                                    <Row className={'mb-3'}>
                                                        <Col xs={12} lg={1}>{(i+1)}</Col>
                                                        <Col xs={12} lg={11}>
                                                            <a className={'link-default-underline'}
                                                                href={'/Customer-local-service-overview?customerId=' + this.state.customerId + '&id=' + item.serviceItemId}
                                                                target={'_blank'}
                                                                rel="noreferrer"
                                                            >{Utils.getDataValue(item, 'serviceTag')}</a>
                                                        </Col>
                                                    </Row>
                                                    
                                                </div>
                                            })
                                            :
                                            <Row>
                                                <Col xs={12}><b>There are no services</b></Col>
                                            </Row>
                                            }
                                        </Col>
                                    </Row>

                                </div>
                            </Col>
                        </Row>
                    } else {
                        return <h5 className={'text-center mt-10 pt-10'}>The customer site was not found</h5>
                    }
                } else {
                    return <h5 className={'text-center mt-10 pt-10'}>The customer site doesn't exist</h5>
                }
            } else {
                return <h5 className={'text-center mt-10 pt-10'}>The customer doesn't exist</h5>
            }
        }
    }
    /* END FORM */


    /* DIALOG */
    setSearchDialog = () => {
        return <SearchDialog 
            show={this.state.leftIsSearchDialog}
            onCancel={() => {
                this.setState({
                    leftIsSearchDialog: false,
                });
            }}
            onSearch={(value) => {
                this.setState({
                    leftCurrentPage: 1,
                    leftSearch: value,
                    leftIsSearchDialog: false,
                }, () => {
                    this.callReadApi((items) => {
                        this.setState({
                            leftItems: items,
                        }, () => {
                            this.setLoading(false);
                            this.refreshLayout();
                        });
                    });
                });
            }}
        />
    }

    setPopupForm = () => {
        return <Popup 
            className={'no-bg'}
            auth={this.props.auth}
            settingsType={this.props.settingsType}

            customerId={this.state.customerId}

            show={this.state.popupShowForm}
            isLoading={this.state.popupLoadingForm}
            id={this.state.popupIdForm}
            isCopy={this.state.popupIsCopyForm}

            showHeader={true}
            header={this.state.popupIdForm ? this.state.popupIsCopyForm ? 'Copy Site' : 'Edit Site' : 'Add Site'}
            showFooter={true}
            centerFooter={'start'}
            footer={({ isLoading, id, isCopy, onHide, onSave, onRemove, onCopy }) => {
                return <Row style={{ width: '100%' }}>
                    <Col xs={'auto'}>
                        <LoadingButton
                            isLoading={isLoading}
                            onClick={onSave}
                        >Save</LoadingButton>
                    </Col>
                    <Col xs={'auto'}>
                        <Button
                            variant={'light'}
                            disabled={isLoading}
                            onClick={onHide}
                        >Cancel</Button>
                    </Col>
                    
                </Row>
            }}

            scrollable={true}
            centered={true}
            closeButton={true}
            fullscreen={true}
            noBodyPadding={true}

            onHide={() => {
                this.setState({
                    popupShowForm: false,
                    popupLoadingForm: false,
                    popupIdForm: 0,
                    popupIsCopyForm: false,
                });
            }}
            onSave={() => {
                if(this.refForm && this.refForm.current){
                    if(this.refForm.current.refForm && this.refForm.current.refForm.current){
                        this.refForm.current.refForm.current.handleSubmit();
                    }
                }
            }}
            onRemove={() => {
                if(this.refForm && this.refForm.current){
                    this.refForm.current.handleRemove();
                }
            }}
            onCopy={() => {
                let id = this.state.popupIdForm;

                this.setState({
                    popupShowForm: false,
                    popupLoadingForm: false,
                    popupIdForm: 0,
                    popupIsCopyForm: false,
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            popupShowForm: true,
                            popupLoadingForm: true,
                            popupIdForm: id,
                            popupIsCopyForm: true,
                        });
                    }, 500);
                });
            }}
            
            onLoading={(state) => {
                this.setState({
                    popupLoadingForm: state,
                });
            }}
            onSubmit={(form) => {
                this.setState({
                    popupShowForm: false,
                    popupLoadingForm: false,
                    popupIdForm: 0,
                    popupIsCopyForm: false,
                    details: null,
                    rightItems: [],
                }, () => {
                    this.callReadApi((items) => {
                        this.setState({
                            leftItems: items,
                        }, () => {
                            this.setLoading(false);
                            this.refreshLayout();
                        });
                    });

                    this.callDetailsApis();
                });
            }}
        >
            {(props) => {
                return <CustomerSiteForm
                    ref={this.refForm}
                    {...props}
                />
            }}
        </Popup>
    }

    setRemoveDialog = () => {
        return <RemoveDialog 
          title={'Remove customer site'}
          show={this.state.isRemoveDialog}
          isLoading={false}
          onCancel={() => {
            this.setState({
              isRemoveDialog: false,
            });
          }}
          onRemove={() => {
            this.setState({
                isRemoveDialog: false,
            }, () => {
                this.callDeleteApi((res, isError) => {
                    this.setLoading(false);
                    this.refreshLayout();
                    
                    if(!isError){
                        // this.props.history.push('/Customer-overview-sites?id=' + this.state.customerId);
                        this.getFirstId();
                    }
                });
            });
          }}
        />
    }
    /* END DIALOG */


    render() {
        return <>
            <Row style={{ display: 'contents', width: '100%' }}>
                <Col xs={12} className={'p-0'}>
                    {this.rightBody()}
                </Col>
            </Row>
            {this.setSearchDialog()}
            {this.setPopupForm()}
            {this.setRemoveDialog()}
        </>
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  dispatchApiCallPost,
  dispatchApiCallPut,
  dispatchApiCallDelete,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CustomerSitesApplicableServices);
