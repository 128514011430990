/* eslint-disable no-loop-func */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import * as dispatchCrudApi from '../../../../api/CRUD/DispatchCRUD'
import Utils from "../../../../utils/utils";
import { ParseResult } from "../../../../utils/interfaces";


interface ConnectApiCallArgs {
  accountBillingProfileId: number,
  accountSid: string,
}

interface DisconnectApiCallArgs {
  twilioAppId: number,
  accountBillingProfileId: number,
  accountSid: string,
}

interface InitState {
  isLoading: boolean,
  data: any,

  isLoadingSenderPhoneNumber: boolean,
  dataSenderPhoneNumber: any,

  selectedMenu: number,
}


function NewReducer() {
  const name = 'notifications';

  const initialState: InitState = {
    isLoading: false,
    data: null,

    isLoadingSenderPhoneNumber: false,
    dataSenderPhoneNumber: [],

    selectedMenu: 0,
  };


  const reducers = {
    setSelectedMenu: (state: InitState, action: PayloadAction<number>) => {
      state.selectedMenu = action.payload;
    },

    setSenderPhoneNumberValue: (state: InitState, action: PayloadAction<any>) => {
      if(action.payload){
        state.dataSenderPhoneNumber = [{
          value: action.payload,
          title: action.payload,
          item: action.payload,
        }];
      }
    },


    startConnect: (state: InitState) => {
      state.isLoading = true;
    },
    finishConnect: (state: InitState, action: PayloadAction<{ data: any, errorMessage: string|null, isError: boolean }>) => {
      state.isLoading = false;
    },

    startDisconnect: (state: InitState) => {
      state.isLoading = true;
    },
    finishDisconnect: (state: InitState, action: PayloadAction<{ data: any, errorMessage: string|null, isError: boolean }>) => {
      state.isLoading = false;
    },

    startRead: (state: InitState) => {
      state.isLoading = true;
      state.data = null;
    },
    finishRead: (state: InitState, action: PayloadAction<{ data: any, errorMessage: string|null, isError: boolean }>) => {
      state.isLoading = false;
      state.data = action.payload.data;
    },

    startReadSenderPhoneNumber: (state: InitState) => {
      state.isLoadingSenderPhoneNumber = true;
      state.dataSenderPhoneNumber = [];
    },
    finishReadSenderPhoneNumber: (state: InitState, action: PayloadAction<{ data: any, errorMessage: string|null, isError: boolean }>) => {
      state.isLoadingSenderPhoneNumber = false;

      let arr = [];
      if(action.payload.data && action.payload.data.length > 0) {
        for (var i = 0; i < action.payload.data.length; i++) {
            let item = action.payload.data[i];
            let value = item;
            let title = item;
            let arrItem: any = {
                value: value,
                title: title,
                item: item,
            };

            arr = Utils.addToArray(arr, value, arrItem);
        }
      }

      state.dataSenderPhoneNumber = arr;
    },
    
    startUpdateSenderPhoneNumber: (state: InitState) => {
      state.isLoadingSenderPhoneNumber = true;
    },
    finishUpdateSenderPhoneNumber: (state: InitState, action: PayloadAction<{ data: any, errorMessage: string|null, isError: boolean }>) => {
      state.isLoadingSenderPhoneNumber = false;
    },
  };


  const apis = {
    connect: (params: ConnectApiCallArgs, callback: any) => async (dispatch: any) => {
      dispatch(actions.startConnect());
      
      await dispatchCrudApi.createApi(params, 'twilio-app').then((result: any) => {
        dispatch(actions.finishConnect({ data: result.data, errorMessage: null, isError: false }));

        if(callback){
          callback(result.data);
        }
      }).catch((error: any) => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        dispatch(actions.finishConnect({ data: null, errorMessage: err, isError: true }));
      });
    },
    
    disconnect: (params: DisconnectApiCallArgs, callback: any) => async (dispatch: any) => {
      dispatch(actions.startDisconnect());
      
      await dispatchCrudApi.updateApi(params, 'twilio-app/deactivate').then((result: any) => {
        dispatch(actions.finishDisconnect({ data: result.data, errorMessage: null, isError: false }));

        if(callback){
          callback(result.data);
        }
      }).catch((error: any) => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        dispatch(actions.finishDisconnect({ data: null, errorMessage: err, isError: true }));
      });
    },

    getApp: (callback: any) => async (dispatch: any) => {
      dispatch(actions.startRead());
      
      await dispatchCrudApi.readApi(null, 'twilio-app').then((result: any) => {
        dispatch(actions.finishRead({ data: result.data, errorMessage: null, isError: false }));

        if(callback){
          callback(result.data);
        }
      }).catch((error: any) => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        dispatch(actions.finishRead({ data: null, errorMessage: err, isError: true }));
      });
    },

    getSenderPhoneNumber: (id: any, callback: any = null) => async (dispatch: any) => {
      dispatch(actions.startReadSenderPhoneNumber());
      
      await dispatchCrudApi.readApi(null, 'twilio-app/sender-phone-number/' + id).then((result: any) => {
        dispatch(actions.finishReadSenderPhoneNumber({ data: result.data, errorMessage: null, isError: false }));

        if(callback){
          callback(result.data);
        }
      }).catch((error: any) => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        dispatch(actions.finishReadSenderPhoneNumber({ data: null, errorMessage: err, isError: true }));
      });
    },
    
    setSenderPhoneNumber: (params: any, callback: any = null) => async (dispatch: any) => {
      dispatch(actions.startUpdateSenderPhoneNumber());
      
      await dispatchCrudApi.updateApi(params, 'twilio-app').then((result: any) => {
        dispatch(actions.finishUpdateSenderPhoneNumber({ data: result.data, errorMessage: null, isError: false }));

        if(callback){
          callback(result.data);
        }
      }).catch((error: any) => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        dispatch(actions.finishUpdateSenderPhoneNumber({ data: null, errorMessage: err, isError: true }));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();