import React from 'react';

import { 
    MenuList, 
    MenuItem, 
    MenuButton, 
    SubMenuItem,
} from 'react-menu-list';

import {
    Button,
    TextField,
    Paper,
} from '@material-ui/core';

import { Spinner } from "react-bootstrap-v5";

import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ArrowRight from '@material-ui/icons/ArrowRight';


const MenuSubItems = props => {
    const refMenuButton = React.useRef();
    const searchRef = React.useRef();

    const [text, setText] = React.useState(props.text ? props.text : '');
    const [items, setItems] = React.useState(props.items ? props.items : []);
    const [realItems, setRealItems] = React.useState(props.items ? props.items : []);

    const [isSearchFieldItem, setIsSearchFieldItem] = React.useState(props.isSearchFieldItem ? props.isSearchFieldItem : false);
    const [isSearchFieldSubItem, setIsSearchFieldSubItem] = React.useState(props.isSearchFieldSubItem ? props.isSearchFieldSubItem : false);
    const [disabled, setDisabled] = React.useState(props.disabled ? props.disabled : false);

    const [positionOptions] = React.useState(props.positionOptions ? props.positionOptions : { vAlign:'top', hAlign: 'center' });

    const [expandMore] = React.useState(props.expandMore ? props.expandMore : <ExpandMore />);
    const [expandLess] = React.useState(props.expandLess ? props.expandLess : <ExpandLess />);

    const [loading, setLoading] = React.useState(props.loading ? props.loading : false);


    React.useEffect(() => {
        setText(props.text ? props.text : '');
        setItems(props.items ? props.items : []);
        setRealItems(props.items ? props.items : []);
        setIsSearchFieldItem(props.isSearchFieldItem ? props.isSearchFieldItem : false);
        setIsSearchFieldSubItem(props.isSearchFieldSubItem ? props.isSearchFieldSubItem : false);
        setDisabled(props.disabled ? props.disabled : false);
        setLoading(props.loading ? props.loading : false);
    }, [props.text, props.items, props.realItems, props.isSearchFieldItem, props.isSearchFieldSubItem, props.disabled, props.loading]);

    
    const Li = (p) => {
        return (
            <MenuItem
                className={((p && p.item && p.item.text && p.item.text == 'Cancelled') ? 'text-red' : '') + ' ' + (((typeof p.disabled=== "function") ? p.disabled() : p.disabled) ? 'custom-item-disabled' : '')}
                style={{
                    cursor: 'pointer', 
                    userSelect: 'none',
                    padding: '5px 10px',
                    color: (((typeof p.disabled=== "function") ? p.disabled() : p.disabled) ? '#ccc' : ''),
                }}
                highlightedStyle={{ 
                    background: '#ccc',
                    color: 'black',
                }}
                onItemChosen={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    
                    if(!((typeof p.disabled=== "function") ? p.disabled() : p.disabled) && props.onClick){
                        props.onClick(p.item, p.subItem)
                    }

                    if(!((typeof p.disabled=== "function") ? p.disabled() : p.disabled)){
                        refMenuButton.current.close();
                    }
                }}
                {...p}
            />
        );
    }
    

    const setListSubItems = (item) => {
        if(item && item.items && item.items.length > 0){
            return item.items.map((subItem, i) => {
                return <Li key={i} item={item} subItem={subItem} disabled={((typeof subItem.disabled=== "function") ? subItem.disabled() : subItem.disabled)}>{subItem.text}</Li>
            });
        }
    };

    const setListItems = () => {
        if(items && items.length > 0){
            return items.map((item, i) => {
                return <React.Fragment key={i}>
                    {
                        (item.items)
                        ?
                        <SubMenuItem
                            className={(((typeof item.disabled=== "function") ? item.disabled() : item.disabled) ? 'custom-item-disabled' : '')}
                            menuZIndex={99999}
                            style={{
                                position: 'relative',
                                cursor: 'pointer', 
                                userSelect: 'none',
                                padding: '5px 10px',
                                color: (((typeof item.disabled=== "function") ? item.disabled() : item.disabled) ? '#ccc' : ''),
                            }}
                            highlightedStyle={{ 
                                background: '#ccc',
                                color: 'black',
                            }}
                            menu={
                                !((typeof item.disabled=== "function") ? item.disabled() : item.disabled)
                                ?
                                <React.Fragment>
                                    {isSearchFieldSubItem && setSearchField()}
                                    <Paper 
                                        className={'custom-paper ' + ((props.className && props.className !== '') ? props.className : '')}
                                        style={{
                                            // height: '250px',
                                            overflowY: 'auto',
                                        }}
                                        onMouseDown={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                    >
                                        <MenuList>
                                            {
                                                isSearchFieldSubItem && loading
                                                ?
                                                <div style={{ textAlign: 'center' }}>
                                                    <Spinner animation="border" />
                                                </div>
                                                :
                                                setListSubItems(item)
                                            }
                                        </MenuList>
                                    </Paper>
                                </React.Fragment>
                                :
                                null
                            }
                            onWillOpen={() => {
                                // if(props.callApi && !((typeof item.disabled=== "function") ? item.disabled() : item.disabled)){
                                //     setLoading(true);
                                //     props.callApi('', (data) => {
                                //         if(props.callApiResult){
                                //             props.callApiResult(data);
                                //             setLoading(false);
                                //         }
                                //     });
                                // }
                            }}
                        >
                            {item.text}
                            <ArrowRight 
                                style={{ 
                                    position: 'absolute', 
                                    top: '6px',
                                    right: '6px',
                                }}
                            />
                        </SubMenuItem>
                        :
                        <Li item={item} subItem={null} disabled={((typeof item.disabled=== "function") ? item.disabled() : item.disabled)}>{item.text}</Li>
                    }
                </React.Fragment>
            });
        }
    };


    const setSearchField = () => {
        return <div 
            style={{
                position: 'sticky',
                top: '0px',
                backgroundColor: 'white',
                zIndex: '1',
                padding: '8px'
            }}
            onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();

                // if(searchRef && searchRef.current){
                //     searchRef.current.focus();
                // }
            }}
        >
            <TextField
                inputRef={searchRef}
                placeholder={'Search ...'}
                type={"search"}
                fullWidth
                onMouseUp={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
    
                    e.target.focus();
                }}
                onChange={(e) => {
                    e.persist();
                    e.stopPropagation();
                    
                    if(props.callApi){
                        setLoading(true);
                        props.callApi(e.target.value, (data) => {
                            if(props.callApiResult){
                                props.callApiResult(data);
                                setLoading(false);
                            }
                        });
                    }

                    if(props.searchAction){
                        let items = realItems.filter((x) => x.text.toLowerCase().includes(e.target.value.toLowerCase()));
                        setItems(items);
                    }
                }}
            />
        </div>
    };


    return (
        <div className={'custom-menu-sub-items-component ' + ((props.className && props.className !== '') ? props.className : '')}>
            <MenuButton 
                ref={refMenuButton}
                menuZIndex={99998}
                positionOptions={positionOptions}
                // menuParentElement={typeof document !== `undefined` && document.body}
                // menuParentElement={document.getElementsByClassName('custom-menu-sub-items-component')[0]}
                renderButton={(domRef, opened, onKeyPress, onMouseDown) => {
                    return <Button
                        className={'custom-btn ' + ((text === '') ? 'disabled' : '')}
                        color={'default'}
                        variant={'text'}
                        disabled={disabled}
                        ref={domRef}
                        onKeyPress={onKeyPress}
                        onMouseDown={onMouseDown}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                        <span className={'custom-span-text'}>{(text !== '') ? text : 'Select'}</span>
                        <span className={'custom-span-icon'}>{!disabled ? opened ? expandLess : expandMore : ''}</span>
                    </Button>
                }}
                menu={
                    <React.Fragment>
                        {isSearchFieldItem && setSearchField()}
                        <Paper 
                            className={'custom-paper ' + ((props.className && props.className !== '') ? props.className : '')}
                            style={{
                                height: 'auto',
                                maxHeight: '200px',
                                overflowY: 'auto',
                            }}
                            onMouseDown={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            <MenuList>
                                {
                                    isSearchFieldItem && loading
                                    ?
                                    <div style={{ textAlign: 'center' }}>
                                        <Spinner animation="border" />
                                    </div>
                                    :
                                    setListItems()
                                }
                            </MenuList>
                        </Paper>
                    </React.Fragment>
                }
                onWillOpen={() => {
                    if(props.isOpen){
                        props.isOpen(true);
                    }

                    if(props.callApi){
                        setLoading(true);
                        props.callApi('', (data) => {
                            if(props.callApiResult){
                                props.callApiResult(data);
                                setLoading(false);
                            }
                        });
                    }

                    if(props.searchAction){
                        setItems(realItems);
                    }
                }}
                onWillClose={(x) => {
                    if(props.isOpen){
                        props.isOpen(false);
                    }
                }}
            />
        </div>
    );
}

export default MenuSubItems;