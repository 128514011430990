import React from "react";

import { 
    Row,
    Col,
    Form,
    InputGroup,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import Utils from "../../../../utils/utils";
import { InOrOut, BinOrWaste, PagesPermissions } from "../../../../utils/enums";

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import CustomTooltip from '../../../../components/Tooltip/Tooltip';
import RSuiteAutoComplete from '../../../../components/OLD/Autocomplete/RSuiteAutoComplete';
import InputLayout from "../../../../components/input/InputLayout";

import { connect } from 'react-redux'
import { clear } from "../../../../../setup/redux/actions";
import { dispatchApiCallGet } from '../../../../../setup/redux/dispatch/actions'

import { ReactComponent as ScanTextIcon } from "../../../../../_metronic/assets/img/bin_center/scan_text.svg";


const StyledInputGroup = styled(InputGroup)`
    input {
        &:not(.disabled) {
            &:not([value=""]) {
                border-right: 0px;
            }
        }
    }

    .input-group-text {
        background-color: white;
        cursor: pointer;

        i {
            font-size: 14px;
            color: #8e8e93;
        }

        &:hover {
            i {
                color: #f44336;
            }
        }
    }
`;
const StyledInputReadOnly = styled(Form.Control)`
    border: 0px;
    padding-left: 5px;
    cursor: default;

    &[readonly]{
        color: #181C32 !important;
    }
`;


class StepExchangeSection extends React.Component {
  
    constructor(props) {
        super(props);
        
        this.name = 'job_form_step_exchange_section';

        let dataParams = (props.dataParams) ? props.dataParams : null;
        let id = (dataParams && dataParams.id) ? dataParams.id : null;
        let row = (dataParams && dataParams.row) ? dataParams.row : null;
        
        this.pageRef = React.createRef();

        this.state = {
            id: id,
            row: row,
        };
    }
    

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            this.name + '-binnumber', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = [];

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.binNumberId;
                        let title = item.binNumberName;
                        let arrItem = {
                            index: i,
                            value: value,
                            title: title,
                            label: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );
    }
	
    callBinNumberReadApi = (binTypeId = null, items, searchQuery = '', callback = null) => {
        let {
            values,
        } = this.props.formOptions;

        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: searchQuery,
            binTypeId: binTypeId,
            customerSiteId: values.customerSiteId,
            customerId: values.customerId,
            showRecentJob: true,
        };

        this.props.dispatchApiCallGet(data, this.name + '-binnumber', 'binnumber', { items: items }, callback, () => {});
    }
    /* END API */


    body = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            setValues,
            values,
            errors,
        } = this.props.formOptions;
        
        let hasEditJobAfterCompleted = (Utils.hasPermission(Utils.getPermissions(), PagesPermissions.EditJobAfterCompleted.read))
        
        let isDisabledOut1 = Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || !(values.customerSiteId) || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName);
        let isDisabledOut2 = Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || !(values.customerSiteId) || Utils.isCancelledOrFailed(values.currentStatusName);
        let isDisabledOut = hasEditJobAfterCompleted ? isDisabledOut2 : isDisabledOut1;
        
        let isDisabledOutBinN1 = Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || !(values.customerSiteId) || !(values.stepExchangeOutBinTypeId) || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName);
        let isDisabledOutBinN2 = Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || !(values.customerSiteId) || !(values.stepExchangeOutBinTypeId) || Utils.isCancelledOrFailed(values.currentStatusName);
        let isDisabledOutBinN = hasEditJobAfterCompleted ? isDisabledOutBinN2 : isDisabledOutBinN1;
        
        let isDisabledIn = values.isParams || Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || !(values.customerSiteId) || Utils.isCancelledOrFailed(values.currentStatusName);
        let isDisabledWasteIn = values.isParams || Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || !(values.customerSiteId) || !(values.stepExchangeInBinTypeId) || Utils.isCancelledOrFailed(values.currentStatusName);
        let isDisabledWeightIn = Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || !(values.customerSiteId) || !(values.stepExchangeInBinTypeId) || Utils.isCancelledOrFailed(values.currentStatusName);
        let isDisabledWeightChit = Utils.isCancelledOrFailed(values.currentStatusName);


        return <Row className={'gx-custom'}>
            <Col xs={12} md={6}>
                <Row className={''}>
                    <Col xs={12}>
                        <h3 className={'mb-7'}>
                            <span style={{ color: '#DFDFDF' }}>BIN OUT</span>
                            <CustomTooltip placement={'right'} title={'You deliver bin to customer site.'}>
                                <InfoOutlinedIcon style={{ color: '#DFDFDF', width: '30px', height: '25px' }} />
                            </CustomTooltip>
                        </h3>
                    </Col>
                    <Col xs={12}>
                        <Row>
                            <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.stepExchangeOutBinTypeId.label}
                                    required
                                >
                                    <StyledInputGroup>
                                        <Form.Control
                                            className={'custom-readonly-bin-waste-field ' + (isDisabledOut ? 'disabled': '')}
                                            id={this.props.fields.stepExchangeOutBinTypeId.id}
                                            placeholder={this.props.fields.stepExchangeOutBinTypeId.placeholder}
                                            readOnly={true}
                                            value={values.stepExchangeOutBinTypeName}
                                            isInvalid={!!errors.stepExchangeOutBinTypeId}
                                            onClick={(e) => {
                                                if(!isDisabledOut){
                                                    if(this.props.onBinWasteSidePanel){
                                                        this.props.onBinWasteSidePanel(InOrOut.OUT, BinOrWaste.BIN);
                                                    }
                                                }
                                            }}
                                        />
                                        {(!isDisabledOut && values.stepExchangeOutBinTypeName != null && values.stepExchangeOutBinTypeName != '') && <InputGroup.Text
                                            onClick={(e) => {
                                                if(this.props.onBinWasteClear){
                                                    this.props.onBinWasteClear(InOrOut.OUT, BinOrWaste.BIN);
                                                }
                                            }}
                                        >
                                            <i className={'material-icons'}>close</i>
                                        </InputGroup.Text>}
                                        <Form.Control.Feedback type="invalid">{errors.stepExchangeOutBinTypeId}</Form.Control.Feedback>
                                    </StyledInputGroup>
                                </InputLayout>
                            </Col>
                            <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.stepExchangeOutWasteTypeId.label}
                                    required
                                >
                                    <StyledInputGroup>
                                        <Form.Control
                                            className={'custom-readonly-bin-waste-field ' + (isDisabledOut ? 'disabled': '')}
                                            id={this.props.fields.stepExchangeOutWasteTypeId.id}
                                            placeholder={this.props.fields.stepExchangeOutWasteTypeId.placeholder}
                                            readOnly={true}
                                            value={values.stepExchangeOutWasteTypeName}
                                            isInvalid={!!errors.stepExchangeOutWasteTypeId}
                                            onClick={(e) => {
                                                if(!isDisabledOut){
                                                    if(this.props.onBinWasteSidePanel){
                                                        this.props.onBinWasteSidePanel(InOrOut.OUT, BinOrWaste.WASTE);
                                                    }
                                                }
                                            }}
                                        />
                                        {(!isDisabledOut && values.stepExchangeOutWasteTypeName != null && values.stepExchangeOutWasteTypeName != '') && <InputGroup.Text
                                            onClick={(e) => {
                                                if(this.props.onBinWasteClear){
                                                    this.props.onBinWasteClear(InOrOut.OUT, BinOrWaste.WASTE);
                                                }
                                            }}
                                        >
                                            <i className={'material-icons'}>close</i>
                                        </InputGroup.Text>}
                                        <Form.Control.Feedback type="invalid">{errors.stepExchangeOutWasteTypeId}</Form.Control.Feedback>
                                    </StyledInputGroup>
                                </InputLayout>
                            </Col>
                            <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.stepExchangeOutBinNumber.label}
                                >
                                    <InputGroup>
                                    <Form.Control
                                        as = {RSuiteAutoComplete}
                                        isInvalid={!!errors.stepExchangeOutBinNumber}

                                        id={this.props.fields.stepExchangeOutBinNumber.id}
                                        placeholder={this.props.fields.stepExchangeOutBinNumber.placeholder}
                                        items={values.customFormParams.stepExchangeOutBinNumberItems}
                                        value={values.stepExchangeOutBinNumber}
                                        isLoading={values.customFormParams.stepExchangeOutBinNumberIsLoading}
                                        disabled={isDisabledOutBinN}
                                        labelKey={'title'}
                                        isDefault={true}
                                        pattern={Utils.max7chars()}
                                        renderMenuItem={(label, item) => {
                                            let minDate = Utils.getMinDate();
                                            let lastJobDate = (item && item.item && item.item.lastJobDate) ? item.item.lastJobDate : minDate;

                                            return <div className={'d-flex align-items-center'}>
                                                {((lastJobDate !== minDate) && (item.index < 5)) ? <i className={'material-icons'}>access_time</i> : null}
                                                <span className={'ps-2'}>{item.title}</span>
                                            </div>
                                        }}
                                        onClick={async () => {
                                            await setFieldValue('customFormParams.stepExchangeOutBinNumberIsLoading', true);
                                            this.callBinNumberReadApi(values.stepExchangeOutBinTypeId, values.customFormParams.stepExchangeOutBinNumberItems, '', async (items) => {
                                                await setFieldValue('customFormParams.stepExchangeOutBinNumberItems', items);
                                                await setFieldValue('customFormParams.stepExchangeOutBinNumberIsLoading', false);
                                            });
                                        }}
                                        onSearch={async (value) => {
                                            await setFieldValue('stepExchangeOutisUsingQr', false);
                                            await setFieldValue('stepExchangeOutisUsingTextDetection', false);
                                            await setFieldValue('customFormParams.isSameAsBinOut', Utils.isSameAsBinOut({
                                                stepExchangeOutBinTypeId: values.stepExchangeOutBinTypeId,
                                                stepExchangeOutBinNumber: value,
                                                stepExchangeOutWasteTypeId: values.stepExchangeOutWasteTypeId,
                                                stepExchangeInBinTypeId: values.stepExchangeInBinTypeId,
                                                stepExchangeInBinNumber: values.stepExchangeInBinNumber,
                                                stepExchangeInWasteTypeIds: values.stepExchangeInWasteTypeIds,
                                            }));

                                            await setFieldValue('customFormParams.stepExchangeOutBinNumberIsLoading', true);
                                            this.callBinNumberReadApi(values.stepExchangeOutBinTypeId, values.customFormParams.stepExchangeOutBinNumberItems, value, async (items) => {
                                                await setFieldValue('customFormParams.stepExchangeOutBinNumberItems', items);
                                                await setFieldValue('customFormParams.stepExchangeOutBinNumberIsLoading', false);
                                            });
                                        }}
                                        onChange={async (value) => {
                                            await setFieldValue('stepExchangeOutisUsingQr', false);
                                            await setFieldValue('stepExchangeOutisUsingTextDetection', false);
                                            await setFieldValue('stepExchangeOutBinNumber', value);
                                            await setFieldValue('customFormParams.isSameAsBinOut', Utils.isSameAsBinOut({
                                                stepExchangeOutBinTypeId: values.stepExchangeOutBinTypeId,
                                                stepExchangeOutBinNumber: value,
                                                stepExchangeOutWasteTypeId: values.stepExchangeOutWasteTypeId,
                                                stepExchangeInBinTypeId: values.stepExchangeInBinTypeId,
                                                stepExchangeInBinNumber: values.stepExchangeInBinNumber,
                                                stepExchangeInWasteTypeIds: values.stepExchangeInWasteTypeIds,
                                            }));
                                        }}
                                        onSelect={async (value, item) => {
                                            await setFieldValue('stepExchangeOutisUsingQr', false);
                                            await setFieldValue('stepExchangeOutisUsingTextDetection', false);
                                            await setFieldValue('stepExchangeOutBinNumber', value);
                                            await setFieldValue('customFormParams.isSameAsBinOut', Utils.isSameAsBinOut({
                                                stepExchangeOutBinTypeId: values.stepExchangeOutBinTypeId,
                                                stepExchangeOutBinNumber: value,
                                                stepExchangeOutWasteTypeId: values.stepExchangeOutWasteTypeId,
                                                stepExchangeInBinTypeId: values.stepExchangeInBinTypeId,
                                                stepExchangeInBinNumber: values.stepExchangeInBinNumber,
                                                stepExchangeInWasteTypeIds: values.stepExchangeInWasteTypeIds,
                                            }));
                                        }}
                                    />
                                    {values.stepExchangeOutisUsingQr && <CustomTooltip title={'QR code is scanned'}>
                                        <i style={{ position: 'absolute', right: '15px', top: '15px' }} class="fa fa-qrcode"></i>
                                    </CustomTooltip>}
                                    {values.stepExchangeOutisUsingTextDetection && <CustomTooltip title={'QR code is scanned with text recognition'}>
                                        <ScanTextIcon style={{ position: 'absolute', right: '14px', top: '15px', width: '15px', height: '15px' }} />
                                    </CustomTooltip>}
                                    <Form.Control.Feedback type="invalid">{errors.stepExchangeOutBinNumber}</Form.Control.Feedback>
                                    </InputGroup>
                                </InputLayout>
                            </Col>
                            
                            <Col xs={12}>
                                <InputLayout
                                    label={'Service name'}
                                    inputProps={{
                                        className: 'd-flex align-items-center'
                                    }}
                                >
                                    <div>
                                        <span className={'pe-2'}>{values.stepExchangeOutServiceItemName}</span>
                                        {(values.stepExchangeOutServiceItemIsGlobal && (values.stepExchangeOutServiceItemName != '')) && <CustomTooltip placement={'top'} title={'Global service'}>
                                            <i className={'material-icons'} style={{ position: 'relative', top: '6px', fontSize: '22px', }}>public</i>
                                        </CustomTooltip>}
                                    </div>
                                </InputLayout>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col xs={12} md={6}>
                <Row className={''}>
                    <Col xs={12}>
                        <Row>
                            <Col xs={12} sm={'auto'}>
                                <h3 className={'mb-7'}>
                                    <span style={{ color: '#DFDFDF' }}>BIN IN</span>
                                    <CustomTooltip placement={'right'} title={'You collect bin from customer site.'}>
                                        <InfoOutlinedIcon style={{ color: '#DFDFDF', width: '30px', height: '25px' }} />
                                    </CustomTooltip>
                                </h3>
                            </Col>
                            <Col xs={12} sm={'auto'}>
                                <Form.Group className={'mb-5'}>
                                    <Form.Check
                                        type="checkbox"
                                        id={this.props.fields.isSameAsBinOut.id}
                                        label={this.props.fields.isSameAsBinOut.label}
                                        checked={values.customFormParams.isSameAsBinOut}
                                        disabled={values.isParams || Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || !(values.customerSiteId) || !(values.stepExchangeOutBinTypeId) || Utils.isCancelledOrFailed(values.currentStatusName)}
                                        onChange={async (e) => {
                                            await setFieldValue('customFormParams.isSameAsBinOut', e.target.checked);
                                            await setFieldValue('customFormParams.isServiceOrHistory', false);

                                            if(e.target.checked){
                                                await setFieldValue('stepExchangeInServiceItemId', values.stepExchangeOutServiceItemId);
                                                await setFieldValue('stepExchangeInServiceItemName', values.stepExchangeOutServiceItemName);
                                                await setFieldValue('stepExchangeInServiceItemIsGlobal', values.stepExchangeOutServiceItemIsGlobal);
                                                
                                                let unladenBinWeightBinType = 0;
                                                let serviceTag = '';
                                                let index = null;
                                                let arr = values.customFormParams.stepExchangeOutBinWasteTypeItems;
                                                if(arr && arr.length > 0){
                                                    index = arr.findIndex(x => ((x.binTypeId === values.stepExchangeOutBinTypeId) && (x.wasteTypeId === values.stepExchangeOutWasteTypeId)));
                                                    serviceTag = (arr[index] && arr[index].item) ? arr[index].item.serviceTag : '';
                                                    index = (index === -1) ? null : Utils.makeIDfromString(((arr[index].serviceItemId) ? arr[index].serviceItemId : '') + arr[index].binTypeId + "_" + arr[index].binTypeName + "_" + arr[index].wasteTypeId + "_" + arr[index].wasteTypeName + "_" + serviceTag);
                                                }

                                                let binTypeItem = (values && values.stepExchangeOutBinTypeItem && values.stepExchangeOutBinTypeItem.item) ? values.stepExchangeOutBinTypeItem.item : null;
                                                unladenBinWeightBinType = (binTypeItem && binTypeItem.binType &&  Utils.isNumber(binTypeItem.binType.unladednBinWeight)) ? parseFloat(binTypeItem.binType.unladednBinWeight) : 0;
                                                
                                                let stepExchangeInBinWasteTypeArr = [{
                                                    value: values.stepExchangeOutWasteTypeId,
                                                    title: values.stepExchangeOutWasteTypeName,
                                                    item: {
                                                        binTypeId: values.stepExchangeOutBinTypeId,
                                                        binTypeName: values.stepExchangeOutBinTypeName,
                                                        wasteTypeId: values.stepExchangeOutWasteTypeId,
                                                        wasteTypeName: values.stepExchangeOutWasteTypeName,
                                                        serviceTag: serviceTag,
                                                        binOutDate: null,
                                                        binNumber: '',
                                                        driverName: '',
                                                        vehicleName: '',
                                                    },
                                                    binTypeId: values.stepExchangeOutBinTypeId,
                                                    binTypeName: values.stepExchangeOutBinTypeName,
                                                    wasteTypeId: values.stepExchangeOutWasteTypeId,
                                                    wasteTypeName: values.stepExchangeOutWasteTypeName,
                                                    serviceTag: serviceTag,
                                                    binOutDate: null,
                                                    binNumber: '',
                                                    driverName: '',
                                                    vehicleName: '',
                                                }];

                                                let value = Utils.makeIDfromString(((values.stepExchangeOutServiceItemId) ? values.stepExchangeOutServiceItemId : '') + values.stepExchangeOutBinTypeId + "_" + values.stepExchangeOutBinTypeName + "_" + values.stepExchangeOutWasteTypeId + "_" + values.stepExchangeOutWasteTypeName + "_" + serviceTag);
                                                let stepExchangeInBinWasteTypeItem = {
                                                    value: value,
                                                    item: {
                                                        binTypeId: values.stepExchangeOutBinTypeId,
                                                        binTypeName: values.stepExchangeOutBinTypeName,
                                                        wasteTypeId: values.stepExchangeOutWasteTypeId,
                                                        wasteTypeName: values.stepExchangeOutWasteTypeName,
                                                        serviceTag: serviceTag,
                                                        binOutDate: null,
                                                        binNumber: '',
                                                        driverName: '',
                                                        vehicleName: '',
                                                    },
                                                    binTypeId: values.stepExchangeOutBinTypeId,
                                                    binTypeName: values.stepExchangeOutBinTypeName,
                                                    wasteTypeId: values.stepExchangeOutWasteTypeId,
                                                    wasteTypeName: values.stepExchangeOutWasteTypeName,
                                                    serviceTag: serviceTag,
                                                    binOutDate: null,
                                                    binNumber: '',
                                                    driverName: '',
                                                    vehicleName: '',
                                                };
                                                let stepExchangeInBinWasteTypeItems = (stepExchangeInBinWasteTypeItem) ? [stepExchangeInBinWasteTypeItem] : [];

                                                await setFieldValue('customFormParams.stepExchangeInBinWasteTypeItems', stepExchangeInBinWasteTypeItems);
                                                await setFieldValue('customFormParams.stepExchangeInWasteTypeItems', stepExchangeInBinWasteTypeArr);
                                                await setFieldValue('customFormParams.stepExchangeInBinNumberItems', values.customFormParams.stepExchangeOutBinNumberItems);
                                                await setFieldValue('customFormParams.stepExchangeInBinWasteTypeId', index);
                                                await setFieldValue('stepExchangeInBinTypeId', values.stepExchangeOutBinTypeId);
                                                await setFieldValue('stepExchangeInBinTypeName', values.stepExchangeOutBinTypeName);
                                                await setFieldValue('stepExchangeInWasteTypeIds', [values.stepExchangeOutWasteTypeId]);
                                                await setFieldValue('stepExchangeInServiceItemId', values.stepExchangeOutServiceItemId);
                                                // await setFieldValue('stepExchangeInBinNumber', ((values.stepExchangeOutBinNumber) ? values.stepExchangeOutBinNumber : ''));
                                                await setFieldValue('stepExchangeInisUsingQr', false);
                                                await setFieldValue('stepExchangeInisUsingTextDetection', false);
                                                await setFieldValue('stepExchangeInIsCopyService', values.stepExchangeOutIsCopyService);
                                                
                                                let selectedCustomerId = (binTypeItem && binTypeItem.customerId) ? binTypeItem.customerId : null;
                                                if(this.props.onChangeBinType){
                                                    this.props.onChangeBinType(selectedCustomerId, values.customerSiteId, values.stepExchangeOutBinTypeId, [values.stepExchangeOutBinTypeItem], false);
                                                }
                                            } else {
                                                let stepExchangeInBinTypeId = values.stepExchangeInBinTypeId;
                                                let stepExchangeInWasteTypeIds = values.stepExchangeInWasteTypeIds;

                                                await setFieldValue('customFormParams.stepExchangeInBinWasteTypeItems', []);
                                                await setFieldValue('customFormParams.stepExchangeInWasteTypeItems', []);
                                                await setFieldValue('customFormParams.stepExchangeInBinWasteTypeId', null);
                                                await setFieldValue('stepExchangeInBinTypeId', null);
                                                await setFieldValue('stepExchangeInBinTypeName', '');
                                                await setFieldValue('stepExchangeInWasteTypeIds', null);
                                                await setFieldValue('stepExchangeInBinNumber', '');
                                                await setFieldValue('stepExchangeInisUsingQr', false);
                                                await setFieldValue('stepExchangeInisUsingTextDetection', false);
                                                await setFieldValue('stepExchangeInWasteTypeName', '');
                                                await setFieldValue('stepExchangeInSelectedIds', []);
                                                await setFieldValue('stepExchangeInServiceItemId', null);
                                                await setFieldValue('stepExchangeInServiceItemName', '');
                                                await setFieldValue('stepExchangeInIsCopyService', false);
                                                
                                                if(this.props.onChangeSameAsBinOutToFalse){
                                                    this.props.onChangeSameAsBinOutToFalse(stepExchangeInBinTypeId, stepExchangeInWasteTypeIds);
                                                }
                                            }
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12}>
                        <Row>
                            <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.stepExchangeInBinTypeId.label}
                                    required
                                >
                                    <StyledInputGroup>
                                        <Form.Control
                                            className={'custom-readonly-bin-waste-field ' + (isDisabledIn ? 'disabled': '')}
                                            id={this.props.fields.stepExchangeInBinTypeId.id}
                                            placeholder={this.props.fields.stepExchangeInBinTypeId.placeholder}
                                            readOnly={true}
                                            value={values.stepExchangeInBinTypeName}
                                            isInvalid={!!errors.stepExchangeInBinTypeId}
                                            onClick={(e) => {
                                                if(!isDisabledIn){
                                                    if(this.props.onBinWasteSidePanel){
                                                        this.props.onBinWasteSidePanel(InOrOut.IN, BinOrWaste.BIN);
                                                    }
                                                }
                                            }}
                                        />
                                        {(!isDisabledIn && values.stepExchangeInBinTypeName != null && values.stepExchangeInBinTypeName != '') && <InputGroup.Text
                                            onClick={(e) => {
                                                if(this.props.onBinWasteClear){
                                                    this.props.onBinWasteClear(InOrOut.IN, BinOrWaste.BIN);
                                                }
                                            }}
                                        >
                                            <i className={'material-icons'}>close</i>
                                        </InputGroup.Text>}
                                        <Form.Control.Feedback type="invalid">{errors.stepExchangeInBinTypeId}</Form.Control.Feedback>
                                    </StyledInputGroup>
                                </InputLayout>
                            </Col>
                            <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.stepExchangeInWasteTypeIds.label}
                                    required
                                >
                                    <StyledInputGroup>
                                        <Form.Control
                                            className={'custom-readonly-bin-waste-field ' + (isDisabledWasteIn ? 'disabled': '')}
                                            id={this.props.fields.stepExchangeInWasteTypeIds.id}
                                            placeholder={this.props.fields.stepExchangeInWasteTypeIds.placeholder}
                                            readOnly={true}
                                            value={values.stepExchangeInWasteTypeIds ? values.customFormParams.stepExchangeInWasteTypeItems.filter(item => values.stepExchangeInWasteTypeIds.includes(item.value)).map(x => x.title).join(', ') : ''}
                                            isInvalid={!!errors.stepExchangeInWasteTypeIds}
                                            onClick={(e) => {
                                                if(!isDisabledWasteIn){
                                                    if(this.props.onBinWasteSidePanel){
                                                        this.props.onBinWasteSidePanel(InOrOut.IN, BinOrWaste.WASTE);
                                                    }
                                                }
                                            }}
                                        />
                                        {(!isDisabledIn && (values.stepExchangeInWasteTypeIds ? values.customFormParams.stepExchangeInWasteTypeItems.filter(item => values.stepExchangeInWasteTypeIds.includes(item.value)).map(x => x.title).join(', ') : '') != '') && <InputGroup.Text
                                            onClick={(e) => {
                                                if(this.props.onBinWasteClear){
                                                    this.props.onBinWasteClear(InOrOut.IN, BinOrWaste.WASTE);
                                                }
                                            }}
                                        >
                                            <i className={'material-icons'}>close</i>
                                        </InputGroup.Text>}
                                        <Form.Control.Feedback type="invalid">{errors.stepExchangeInWasteTypeIds}</Form.Control.Feedback>
                                    </StyledInputGroup>
                                </InputLayout>
                            </Col>
                            <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.stepExchangeInBinNumber.label}
                                >
                                    <InputGroup>
                                    <Form.Control
                                        as = {RSuiteAutoComplete}
                                        isInvalid={!!errors.stepExchangeInBinNumber}

                                        id={this.props.fields.stepExchangeInBinNumber.id}
                                        placeholder={this.props.fields.stepExchangeInBinNumber.placeholder}
                                        items={values.customFormParams.stepExchangeInBinNumberItems}
                                        value={values.stepExchangeInBinNumber}
                                        isLoading={values.customFormParams.stepExchangeInBinNumberIsLoading}
                                        disabled={values.isParams || Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || !(values.customerSiteId) || !(values.stepExchangeInBinTypeId) || Utils.isCancelledOrFailed(values.currentStatusName)}
                                        labelKey={'title'}
                                        isDefault={true}
                                        pattern={Utils.max7chars()}
                                        renderMenuItem={(label, item) => {
                                            let minDate = Utils.getMinDate();
                                            let lastJobDate = (item && item.item && item.item.lastJobDate) ? item.item.lastJobDate : minDate;

                                            return <div className={'d-flex align-items-center'}>
                                                {((lastJobDate !== minDate) && (item.index < 5)) ? <i className={'material-icons'}>access_time</i> : null}
                                                <span className={'ps-2'}>{item.title}</span>
                                            </div>
                                        }}
                                        onClick={async () => {
                                            await setFieldValue('customFormParams.stepExchangeInBinNumberIsLoading', true);
                                            this.callBinNumberReadApi(values.stepExchangeInBinTypeId, values.customFormParams.stepExchangeInBinNumberItems, '', async (items) => {
                                                await setFieldValue('customFormParams.stepExchangeInBinNumberItems', items);
                                                await setFieldValue('customFormParams.stepExchangeInBinNumberIsLoading', false);
                                            });
                                        }}
                                        onSearch={async (value) => {
                                            await setFieldValue('stepExchangeInisUsingQr', false);
                                            await setFieldValue('stepExchangeInisUsingTextDetection', false);
                                            await setFieldValue('customFormParams.isSameAsBinOut', Utils.isSameAsBinOut({
                                                stepExchangeOutBinTypeId: values.stepExchangeOutBinTypeId,
                                                stepExchangeOutBinNumber: values.stepExchangeOutBinNumber,
                                                stepExchangeOutWasteTypeId: values.stepExchangeOutWasteTypeId,
                                                stepExchangeInBinTypeId: values.stepExchangeInBinTypeId,
                                                stepExchangeInBinNumber: value,
                                                stepExchangeInWasteTypeIds: values.stepExchangeInWasteTypeIds,
                                            }));
                                            await setFieldValue('customFormParams.stepExchangeInBinNumberIsLoading', true);
                                            this.callBinNumberReadApi(values.stepExchangeInBinTypeId, values.customFormParams.stepExchangeInBinNumberItems, value, async (items) => {
                                                await setFieldValue('customFormParams.stepExchangeInBinNumberItems', items);
                                                await setFieldValue('customFormParams.stepExchangeInBinNumberIsLoading', false);
                                            });
                                        }}
                                        onChange={async (value) => {
                                            await setFieldValue('stepExchangeInBinNumber', value);
                                            await setFieldValue('stepExchangeInisUsingQr', false);
                                            await setFieldValue('stepExchangeInisUsingTextDetection', false);
                                            await setFieldValue('customFormParams.isSameAsBinOut', Utils.isSameAsBinOut({
                                                stepExchangeOutBinTypeId: values.stepExchangeOutBinTypeId,
                                                stepExchangeOutBinNumber: values.stepExchangeOutBinNumber,
                                                stepExchangeOutWasteTypeId: values.stepExchangeOutWasteTypeId,
                                                stepExchangeInBinTypeId: values.stepExchangeInBinTypeId,
                                                stepExchangeInBinNumber: value,
                                                stepExchangeInWasteTypeIds: values.stepExchangeInWasteTypeIds,
                                            }));
                                        }}
                                        onSelect={async (value, item) => {
                                            await setFieldValue('stepExchangeInBinNumber', value);
                                            await setFieldValue('stepExchangeInisUsingQr', false);
                                            await setFieldValue('stepExchangeInisUsingTextDetection', false);
                                            await setFieldValue('customFormParams.isSameAsBinOut', Utils.isSameAsBinOut({
                                                stepExchangeOutBinTypeId: values.stepExchangeOutBinTypeId,
                                                stepExchangeOutBinNumber: values.stepExchangeOutBinNumber,
                                                stepExchangeOutWasteTypeId: values.stepExchangeOutWasteTypeId,
                                                stepExchangeInBinTypeId: values.stepExchangeInBinTypeId,
                                                stepExchangeInBinNumber: value,
                                                stepExchangeInWasteTypeIds: values.stepExchangeInWasteTypeIds,
                                            }));
                                        }}
                                    />
                                    {values.stepExchangeInisUsingQr && <CustomTooltip title={'QR code is scanned'}>
                                        <i style={{ position: 'absolute', right: '15px', top: '15px' }} class="fa fa-qrcode"></i>
                                    </CustomTooltip>}
                                    {values.stepExchangeInisUsingTextDetection && <CustomTooltip title={'QR code is scanned with text recognition'}>
                                        <ScanTextIcon style={{ position: 'absolute', right: '14px', top: '15px', width: '15px', height: '15px' }} />
                                    </CustomTooltip>}
                                    <Form.Control.Feedback type="invalid">{errors.stepExchangeInBinNumber}</Form.Control.Feedback>
                                    </InputGroup>
                                </InputLayout>
                            </Col>
                            
                            {!values.isRecurringForm && <Col xs={12}>
                                <InputLayout
                                    label={<>
                                        <span>{this.props.fields.stepBinWeight.label}</span>
                                        <CustomTooltip placement={'right'} title={'Gross Weight (This) - Tare Weight - Non billable Weight = Billable Weight'}>
                                            <InfoOutlinedIcon style={{ color: '#DFDFDF', width: '30px', height: '25px' }} />
                                        </CustomTooltip>
                                    </>}
                                >
                                    <Form.Control
                                        className={'custom-readonly-bin-weight-field ' + (isDisabledWeightChit ? 'disabled': '')}
                                        id={this.props.fields.stepBinWeight.id}
                                        placeholder={this.props.fields.stepBinWeight.placeholder}
                                        readOnly={true}
                                        value={values.stepBinWeight}
                                        isInvalid={!!errors.stepBinWeight}
                                        onClick={(e) => {
                                            if(!isDisabledWeightChit){
                                                if(this.props.onWeighChitSidePanel){
                                                    this.props.onWeighChitSidePanel();
                                                }
                                            }
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.stepBinWeight}</Form.Control.Feedback>
                                </InputLayout>
                            </Col>}

                            <Col xs={12}>
                                <InputLayout
                                    label={'Service name'}
                                    inputProps={{
                                        className: 'd-flex align-items-center'
                                    }}
                                >
                                    <div>
                                        <span className={'pe-2'}>{values.stepExchangeInServiceItemName}</span>
                                        {(values.stepExchangeInServiceItemIsGlobal && (values.stepExchangeInServiceItemName != '')) && <CustomTooltip placement={'top'} title={'Global service'}>
                                            <i className={'material-icons'} style={{ position: 'relative', top: '6px', fontSize: '22px', }}>public</i>
                                        </CustomTooltip>}
                                    </div>
                                </InputLayout>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    }
    

    render() {
        return <div ref={this.pageRef}>
            {this.body()}
        </div>
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(StepExchangeSection);
