import React from 'react';

import { 
    Modal,
    Spinner,
} from 'react-bootstrap-v5';
import styled from 'styled-components';


const StyledModal = styled(Modal)`
    .modal-header {
        background-color: var(--custom-color-F7F7F7);
        padding-top: 15px;
        padding-bottom: 15px;

        .modal-title {
            width: 100%;
            display: flex;
            padding: 0 0 0 8px;
        }
        
        .close {
            background-color: var(--custom-color-F7F7F7);
            border-radius: 5px;

            &:hover {
                background-color: #484848;
                color: white;
            }
        }
        
        &.no-bg {
            background-color: transparent;

            .close {
                background-color: transparent;
                
                &:hover {
                    background-color: #484848;
                    color: white;
                }
            }
        }
    }
    .modal-body {
        display: inherit;
        padding: 1.75rem 30px;

        &.no-body-padding {
            padding: 0px;
        }
    }
    .modal-footer {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .is-loading {
        .close {
            visibility: hidden;
        }
    }
`;

const StyledModalOwerlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;

    background-color: rgba(0, 0, 0, 0.2);
    cursor: no-drop;

    justify-content: center;
    align-items: center;
    display: flex;
`;


const Popup = (props) => {
    return <StyledModal 
        show={props.show} 
        scrollable={props.scrollable} 
        centered={props.centered} 
        fullscreen={props.fullscreen} 
        size={props.size} 
        backdrop={props.backdrop} 
        backdropClassName={props.backdropClassName} 
        onShow={props.onShow} 
        onHide={props.onHide} 
        enforceFocus={false}
    >
        {props.showHeader && <Modal.Header closeButton={props.closeButton} className={(props.className ? props.className : '') + ' ' + ((props.isLoading == true) ? 'is-loading' : '')}>
            <Modal.Title style={{ justifyContent: ((props && props.centerHeader && props.centerHeader != '') ? props.centerHeader : 'start') }}>{((typeof props.header === 'function') ? props.header(props) : props.header)}</Modal.Title>
        </Modal.Header>}

        <Modal.Body className={(props.noBodyPadding ? 'no-body-padding' : '')}>
            {((typeof props.children === 'function') ? props.children(props) : props.children)}
        </Modal.Body>

        {props.showFooter && <Modal.Footer style={{ justifyContent: ((props && props.centerFooter && props.centerFooter != '') ? props.centerFooter : 'center') }}>{((typeof props.footer === 'function') ? props.footer(props) : props.footer)}</Modal.Footer>}
        
        {props.isLoading && <StyledModalOwerlay><Spinner animation="border" /></StyledModalOwerlay>}
    </StyledModal>
}


export default Popup;