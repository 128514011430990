import React, { Component } from 'react';

import apiUtil from '../../../../api/apiUtil.jsx';
import AuditTrailApi from '../../../../api/OLD/Dispatch/AuditTrail.jsx';

import { 
    Box,
    Divider,
    LinearProgress,
} from '@material-ui/core';

import { 
    Row,
    Col,
    Form,
    Button,
} from "react-bootstrap-v5";

import Pagination from '@material-ui/lab/Pagination';

import ManageLabel from '../../../../components/OLD/input/ManageLabel.jsx';
import AuditTrailTimeline from "../../../../components/OLD/Timeline/AuditTrailTimeline.jsx";
import AuditTrailDetails from "./AuditTrailDetails.jsx";

import { AuditTrailObjectType, AuditTrailObjectTypeText } from '../../../../utils/enums';


class AuditTrailListUser extends Component {
  
    constructor(props){
        super(props);
        
        let isLoading = ((props.isLoading === false) || (props.isLoading === true)) ? props.isLoading : false;
        let items = (props.items && props.items.length > 0) ? props.items : [];
        let userEmail = (props.userEmail) ? props.userEmail : null;
        let title = (props.title && props.title !== '') ? props.title : '';
        
        this.state = {
            isLoading: isLoading,
            items: items,
            userEmail: userEmail,
            title: title,
            searchQuery: '',
            objectType: '',
        }
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.isLoading !== this.state.isLoading) {
            let isLoading = ((nextProps.isLoading === false) || (nextProps.isLoading === true)) ? nextProps.isLoading : false;
			this.setState({
				isLoading: isLoading
			});
		}

		if (nextProps.items !== this.state.items) {
            let items = (nextProps.items && nextProps.items.length > 0) ? nextProps.items : [];
			this.setState({
				items: items
			});
        }

        if (nextProps.userEmail !== this.state.userEmail) {
            let userEmail = (nextProps.userEmail) ? nextProps.userEmail : null;
			this.setState({
				userEmail: userEmail
			});
        }

        if (nextProps.title !== this.state.title) {
            let title = (nextProps.title && nextProps.title !== '') ? nextProps.title : '';
			this.setState({
				title: title
			});
        }
	}


    /* API */
    callCompareAuditTrailApi = (auditTrailId, objectId, objectType, callback = null) => {
        let token = '';
        
        AuditTrailApi.compareAuditTrail(
            objectType,
            objectId,
            auditTrailId,
            token
        ).then(result => {
            apiUtil.parseResult(result, (data) => {
                let currentVersion = (data && data.currentVersion) ? data.currentVersion : null;
                let previousVersion = (data && data.previousVersion) ? data.previousVersion : null;
                
                if(this.props.manageData){
                    this.props.manageData(currentVersion, previousVersion, objectType, (c, p) => {
                        currentVersion = (c) ? c : null;
                        previousVersion = (p) ? p : null;
                    });
                }

                if(callback){
                    callback(currentVersion, previousVersion);
                }
            }, (error, type) => {
                if(callback){
                    callback(null, null);
                }
            });
        });
    }
    /* END API */


    getDetails = (index, currentVersion, previousVersion, objectType) => {
        if(this.props.details){
            return <AuditTrailDetails
                index={index}
                currentVersion={currentVersion}
                previousVersion={previousVersion}
                objectType={objectType}
                details={this.props.details}
            />
        }
    }


    setTitle = () => {
        return <Row className={'align-items-center pb-2'}>
            <Col xs={12} md={true}>
                <ManageLabel 
                    className={'no-margin'}
                    label={(this.state.title !== '') ? this.state.title : 'Audit Trail'}
                    size={'md'}
                />
            </Col>

            {(this.props.showFilter) && <>
                <Col xs={12} md={'auto'} className={'pb-2'}>
                    <Form.Control
                        type="text"
                        id={'audittrailusersearchID'}
                        placeholder={'Search'}
                        value={this.state.searchQuery}
                        onChange={(e) => {
                            this.setState({
                                searchQuery: e.target.value
                            });
                        }}
                        onKeyDown={(e) => {
                          if ((e.charCode || e.keyCode) === 13) {
                            e.preventDefault();
                            e.stopPropagation();
            
                            if(this.props.onChangeFilters){
                              this.props.onChangeFilters(this.state.searchQuery, this.state.objectType);
                            }
                          }
                        }}
                    />
                </Col>
                <Col xs={12} md={'auto'} className={'pb-2'}>
                    <Form.Select
                        value={this.state.objectType}
                        onChange={(e) => {
                            this.setState({
                                objectType: e.target.value
                            });
                        }}
                    >
                        <option value={''}>All</option>
                        <option value={AuditTrailObjectType.Customer}>{AuditTrailObjectTypeText[AuditTrailObjectType.Customer]}</option>
                        <option value={AuditTrailObjectType.CustomerSite}>{AuditTrailObjectTypeText[AuditTrailObjectType.CustomerSite]}</option>
                        {/* <option value={AuditTrailObjectType.Service}>{AuditTrailObjectTypeText[AuditTrailObjectType.Service]}</option> */}
                        <option value={AuditTrailObjectType.ServiceCharge}>{AuditTrailObjectTypeText[AuditTrailObjectType.ServiceCharge]}</option>
                        <option value={AuditTrailObjectType.AdditionalServiceCharge}>{AuditTrailObjectTypeText[AuditTrailObjectType.AdditionalServiceCharge]}</option>
                        <option value={AuditTrailObjectType.JobTemplate}>{AuditTrailObjectTypeText[AuditTrailObjectType.JobTemplate]}</option>
                        <option value={AuditTrailObjectType.RecurringJob}>{AuditTrailObjectTypeText[AuditTrailObjectType.RecurringJob]}</option>
                        <option value={AuditTrailObjectType.AccountBillingProfile}>{AuditTrailObjectTypeText[AuditTrailObjectType.AccountBillingProfile]}</option>
                        <option value={AuditTrailObjectType.BinType}>{AuditTrailObjectTypeText[AuditTrailObjectType.BinType]}</option>
                        <option value={AuditTrailObjectType.BinNumber}>{AuditTrailObjectTypeText[AuditTrailObjectType.BinNumber]}</option>
                        <option value={AuditTrailObjectType.WasteType}>{AuditTrailObjectTypeText[AuditTrailObjectType.WasteType]}</option>
                        <option value={AuditTrailObjectType.Driver}>{AuditTrailObjectTypeText[AuditTrailObjectType.Driver]}</option>
                        <option value={AuditTrailObjectType.Vehicle}>{AuditTrailObjectTypeText[AuditTrailObjectType.Vehicle]}</option>
                        <option value={AuditTrailObjectType.Payroll}>{AuditTrailObjectTypeText[AuditTrailObjectType.Payroll]}</option>
                        <option value={AuditTrailObjectType.AccountReminder}>{AuditTrailObjectTypeText[AuditTrailObjectType.AccountReminder]}</option>
                        <option value={AuditTrailObjectType.AccountJobWorkingTime}>{AuditTrailObjectTypeText[AuditTrailObjectType.AccountJobWorkingTime]}</option>
                        <option value={AuditTrailObjectType.Job}>{AuditTrailObjectTypeText[AuditTrailObjectType.Job]}</option>
                    </Form.Select>
                </Col>
                <Col xs={12} md={'auto'} className={'pb-2'}>
                    <Button 
                        variant={'primary'}
                        onClick={() => {
                            if(this.props.onChangeFilters){
                                this.props.onChangeFilters(this.state.searchQuery, this.state.objectType);
                            }
                        }}
                    >
                        Search
                    </Button>
                </Col>
            </>}
        </Row>
    }

    setList = () => {
        return <Box pt={2}>
            <AuditTrailTimeline
                items={this.state.items}
                isLoading={this.state.isLoading}
                className={'min-width-120'}
                noResult={'No Audit Trail!'}
                isDetails={true}
                detailsBtnTextOpened={'Collapse Details'}
                detailsBtnTextClosed={'View Changes'}
                onChangeShowDetails={(index, showDetails) => {
                    let items = this.state.items;
                    if(items && items.length > 0 && index > -1){
                        items[index].showDetails = showDetails;
                        items[index].details = <LinearProgress />;
                        items[index].isLoadingDetails = showDetails;
            
                        this.setState({
                            items: items
                        }, () => {
                            if(showDetails){
                                this.callCompareAuditTrailApi(items[index].id, items[index].objectId, items[index].objectType, (currentVersion, previousVersion) => {
                                    let detailsMsg = <Box style={{ fontWeight: 'bold' }}>Audit trails is not recorded for old data!</Box>;

                                    let details = null;
                                    if(currentVersion){
                                        details = this.getDetails(index, currentVersion, previousVersion, items[index].objectType);
                                        if(details){
                                            items[index].details = details;
                                        } else {
                                            details = detailsMsg;
                                        }
                                    } else {
                                        details = detailsMsg;
                                    }
                    
                                    items[index].details = details;
                                    items[index].isLoadingDetails = false;
                    
                                    this.setState({
                                        items: items
                                    });
                                });
                            }
                        });
                    }
                }}
            />
        </Box>
    }

    setPagination = () => {
        if(this.state.items && this.state.items.length > 0){
            return <Box pb={2} textAlign={'center'}>
                <Pagination 
                    className={'v-center'}
                    count={apiUtil.getPaginationCount(this.props.totalRows, this.props.pageSize)} 
                    page={this.props.currentPage} 
                    size={'small'}
                    boundaryCount={2} 
                    showFirstButton
                    showLastButton
                    onChange={(event, value) => {
                        if(this.props.onChangePage){
                            this.props.onChangePage(value);
                        }
                    }}
                />
            </Box>
        }
    }

    setButton = () => {
        return <Box pt={2}>
            <Button 
                variant={'light'}
                onClick={() => {
                    if(this.props.onClose){
                        this.props.onClose()
                    }
                }}
            >
                Cancel
            </Button>
        </Box>
    }


    render() {
        return <Box>
            {this.setTitle()}
            <Divider />
            {this.setList()}
            {this.setPagination()}
            <Divider />
            {this.setButton()}
        </Box>
    }
}

export default AuditTrailListUser;
