import React from "react";

import uuid from 'react-uuid'

import Utils from "../../../utils/utils";
import { Operators } from "../../../utils/enums";

import { 
  Row,
  Col,
  Button,
  Form,
} from "react-bootstrap-v5";

import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

import IconButtonNoTitle from "../../Buttons/IconButtonNoTitle";
import FreeTextTagsInput from "../TagsInput/FreeTextTagsInput";
import RSuiteSelectPicker from '../Select/RSuiteSelectPicker';
import IconButtonTooltip from "../../Buttons/IconButtonTooltip";


const OPERATOR_KEY = [
  {
    value: Operators.OR,
    title: "Match Any",
    operator: "OR",
  },
];
const SEARCH_KEY = "id";
const FILTERABLE = "filterable";
const SEARCH_OPERATORS = [
  {
    value: Operators.OR,
    title: "Match Any",
    operator: "or",
  },
  {
    value: Operators.AND,
    title: "Match All",
    operator: "and",
  },
];


const AdvancedFilter = React.forwardRef((props, ref) => {

  const getVisibleColumns = () => {
    let items = [];

    if (
      columnVisibility &&
      columnVisibility.length > 0 &&
      columns &&
      columns.length > 0
    ) {
      for (let i = 0; i < columnVisibility.length; i++) {
        if (columnVisibility[i] === true) {
          if (columns[i]) {
            if (columns[i][FILTERABLE] !== undefined) {
              if (columns[i][FILTERABLE] === true) {
                items.push(columns[i]);
              }
            } else {
              items.push(columns[i]);
            }
          }
        }
      }
    }

    return items;
  };

  const getDisabledColumns = (items = [], column = '') => {
    let data = [];

    if (items && items.length > 0) {
      for (let i = 0; i < items.length; i++) {
        if(items[i].column != column) {
          data.push(items[i].column);
        }
      }
    }

    return data;
  };
  
  const checkIsValid = (items = []) => {
    let isValid = true;

    if(items && items.length > 0){
      for(let i = 0; i < items.length; i++){
        if(items[i].tags.length <= 0){
          isValid = false;
          break;
        } else if(items[i].column == ''){
          isValid = false;
          break;
        }
      }
    } else {
      isValid = false;
    }

    return isValid;
  };


  const itemsRef = React.useRef(null);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [items, setItems] = React.useState(props.filterParams && props.filterParams.length > 0 ? props.filterParams : [
    {
      uuid: uuid(),
      tags: [],
      column: '',
      disabled: []
    }
  ]);

  const [searchColumnKey, setSearchColumnKey] = React.useState(props.searchColumnKey && props.searchColumnKey !== "" ? props.searchColumnKey: SEARCH_KEY);
  const [columns, setColumns] = React.useState(props.columns && props.columns.length > 0 ? props.columns : []);
  const [columnVisibility, setColumnVisibility] = React.useState(props.columnVisibility && props.columnVisibility.length > 0 ? props.columnVisibility : []);

  const [searchOperator, setSearchOperator] = React.useState((props.searchOperator && props.searchOperator.length > 0) ? props.searchOperator : OPERATOR_KEY);


  React.useEffect(() => {
    setSearchColumnKey(props.searchColumnKey && props.searchColumnKey !== "" ? props.searchColumnKey : SEARCH_KEY);
    setColumns(props.columns && props.columns.length > 0 ? props.columns : []);
    setColumnVisibility(props.columnVisibility && props.columnVisibility.length > 0 ? props.columnVisibility : []);
  }, [
    props.filterParams,
    props.searchColumnKey,
    props.columns,
    props.columnVisibility,
  ]);

  const setRows = () => {
    return <Row>
      <Col xs={12} className={'pt-5'}
        ref={itemsRef}
        style={{
          maxHeight: '200px',
          overflow: 'scroll',
        }}
      >

        {items.map((item, i) => {
          return <Row key={i} className={'pb-1 pb-md-0'}>
            <Col xs={true}>
              <Row>
                <Col xs={12} md={8} className={'pb-1 pb-md-0'}>
                  <Form.Group>
                    <Form.Control
                      as = {FreeTextTagsInput}
                  
                      className={"advanced-filter"}
                      value={item.tags}
                      placeholder={"Insert single or multiple key words"}
                      smallPlaceholder={"Insert key words"}
                      onChange={(tags) => {
                        item.tags = tags;
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={4} className={'pb-4'}>
                  <Form.Group>
                    <Form.Control
                      as = {RSuiteSelectPicker}

                      placeholder={'Search in column'}
                      items={getVisibleColumns()}
                      value={item.column}
                      valueKey={searchColumnKey}
                      labelKey={'label'}
                      isLoading={false}
                      disabledItemValues={item.disabled}
                      virtualized={false}
                      searchable={true}
                      cleanable={false}
                      isDefault={true}
                      renderMenuItem={(label, obj) => {
                        let isChecked = false;
                        if(items && items.length > 0){
                          let index = items.findIndex(x => (x.uuid == item.uuid) && (x.column == obj[searchColumnKey]));
                          isChecked = (index > -1) ? true : false;
                        }
                      
                        return <div className="position-relative d-flex align-items-center">
                          <div style={{ flex: '0 1 15%' }} className="d-flex align-items-center">
                            <i className={'material-icons ' + ((isChecked) ? 'text-primary' : '')}>{(isChecked) ? 'radio_button_checked' : 'radio_button_unchecked'}</i>
                          </div>
                          <div style={{ flex: '0 1 88%' }} className="d-flex align-items-center">{label}</div>
                        </div>
                      }}
                      onClick={() => {
                        let disabled = getDisabledColumns(items, item.column);
                        item.disabled = disabled;
                        forceUpdate();
                      }}
                      onSelect={(value, obj) => {
                        item.column = value;
                      }}
                      onClear={() => {
                        item.column = '';
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col xs={'auto'}>
              <IconButtonNoTitle
                disabled={(items && items.length <= 1)}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  if(items && items.length > 1){
                    let newItems = items.filter(x => x.uuid != item.uuid);
                    setItems(newItems);
                  }
                }}
              >
                <i className={'material-icons text-secondary'} style={{ visibility: ((items && items.length > 1) ? 'visible' : 'hidden') }}>close</i>
              </IconButtonNoTitle>
            </Col>
          </Row>
        })}
        
      </Col>
      <Col xs={12}>
        <Button
          className={'border border-secondary ps-5 pe-5 pt-2 pb-2'}
          variant={"default"}
          size={'sm'}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            if(checkIsValid(items)){
              let newItem = {
                uuid: uuid(),
                tags: [],
                column: '',
                disabled: []
              };
              setItems([...items, newItem]);
              Utils.scrollToBottom(itemsRef);
            } else {
              Utils.toast('You must fill in the empty fields', 'error');
            }
          }}
        >
          ADD
        </Button>
      </Col>
    </Row>
  };

  const setOperators = () => {
    return <Row className={'pb-5 pt-5'}>
      <Col xs={true}>
        <Row>
          <Col xs={12}>
            <b>With conditions</b>
            <RadioGroup row className={'border ps-5 pt-2 pb-2 me-2'}
              aria-label="searchOperator"
              name="searchOperator"
              value={(searchOperator && searchOperator.length > 0) ? searchOperator[0].value : OPERATOR_KEY[0].value}
              onChange={(e) => {
                let selectedValue = SEARCH_OPERATORS.filter(x => x.value == e.target.value);
                setSearchOperator(selectedValue);
              }}
            >
              <FormControlLabel value={Operators.OR} control={<Radio color="primary" />} label={SEARCH_OPERATORS[0].title} />
              <FormControlLabel value={Operators.AND} control={<Radio color="primary" />} label={SEARCH_OPERATORS[1].title}  />
            </RadioGroup>
          </Col>
        </Row>
      </Col>
      <Col xs={'auto'}>
        <IconButtonNoTitle
          disabled={true}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <i className={'material-icons text-secondary'} style={{ visibility: 'hidden' }}>close</i>
        </IconButtonNoTitle>
      </Col>
    </Row>
  };

  const setBtn = () => {
    return <Row className={'pt-5'}>
      <Col xs={true}>
        <Button
          variant={"secondary"}
          onClick={() => {
            if(props.onCancel){
              props.onCancel();
            }
          }}
        >
          CANCEL
        </Button>
      </Col>
      <Col xs={true} className={'text-end'}>
        <Button
          variant={"primary"}
          onClick={() => {
            if(checkIsValid(items)){
              if (props.onSearch) {
                let filterParams = (items && items.length > 0) ? items : [];
                let searchOperatorValue = (items && items.length > 1) ? (searchOperator && searchOperator.length > 0) ? searchOperator : OPERATOR_KEY : OPERATOR_KEY;
  
                props.onSearch(filterParams, searchOperatorValue);
              }
            } else {
              Utils.toast('You must fill in the empty fields', 'error');
            }
          }}
        >
          SEARCH
        </Button>
      </Col>
    </Row>
  };


  return <Row className={'p-5'}
    style={{
      maxWidth: '800px'
    }}
  >
    <Col xs={true} className={'d-block d-md-flex align-items-center'}>
      <b>Filter by keywords</b>
      <div className={'w-100 d-block d-md-none'}></div>
      <div className={'d-flex align-items-center'}>
        <IconButtonTooltip
            title={'Separate multiple key words with commas'}
        >
          <i className={'material-icons p-1'} style={{ fontSize: '21px' }}>info_outline</i>
        </IconButtonTooltip>
      </div>
    </Col>
    {props.onClear && <Col xs={'auto'}>
      {(Utils.filterParams(items).length > 0) && <a 
        className={'link-danger'}
        href={'/'}
        onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            
            props.onClear();
        }}
      >Clear Filters</a>}
    </Col>}
    <Col xs={12}>
      {setRows()}
    </Col>
    <Col xs={12}>
      {(items && items.length > 1) && setOperators()}
    </Col>
    <Col xs={12}>
      {setBtn()}
    </Col>
  </Row>
});

export default AdvancedFilter;
