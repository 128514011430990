import React, { Component } from 'react';

import Utils from '../../../utils/utils';

import { 
    Box,
} from '@material-ui/core';

import FileSignatureIcon from '../../../../_metronic/assets/img/manage/file_signature.png';

import ManageInput from '../../../components/OLD/input/ManageInput.jsx';
import InnerFilesDropzone from '../../../components/OLD/Dropzone/InnerDropzone/InnerFilesDropzone.jsx';


class PODSignature extends Component {
    
    constructor(props){
        super(props);

        let isCopy = ((props.isCopy === true || props.isCopy === false) ? props.isCopy : false);
        let disabled = ((props.disabled === true || props.disabled === false) ? props.disabled : false);
        let statusText = (props.statusText && props.statusText !== '') ? props.statusText : '';
        let jobSignatures = (props.jobSignatures && props.jobSignatures.length > 0) ? props.jobSignatures : [{
            jobSignatureUrl: '',
            jobSignedUserName: '',
            jobSignedUserContact: '',
        }];
        
        this.state = {
            isCopy: isCopy,
            disabled: disabled,
            statusText: statusText,
            
            jobSignatures: jobSignatures,
            signatureAttachmentsIsLoading: false,
        }
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.isCopy !== this.state.isCopy) {
            let isCopy = ((nextProps.isCopy === true || nextProps.isCopy === false) ? nextProps.isCopy : false);
            this.setState({
                isCopy: isCopy
            });
        }
        if (nextProps.disabled !== this.state.disabled) {
            let disabled = ((nextProps.disabled === true || nextProps.disabled === false) ? nextProps.disabled : false);
            this.setState({
                disabled: disabled
            });
        }
        if (nextProps.statusText !== this.state.statusText) {
            let statusText = (nextProps.statusText && nextProps.statusText !== '') ? nextProps.statusText : '';
            this.setState({
                statusText: statusText
            });
        }
        if (nextProps.jobSignatures !== this.state.jobSignatures) {
            let jobSignatures = (nextProps.jobSignatures && nextProps.jobSignatures.length > 0) ? nextProps.jobSignatures : [{
                jobSignatureUrl: '',
                jobSignedUserName: '',
                jobSignedUserContact: '',
            }];
            this.setState({
                jobSignatures: jobSignatures
            });
        }
    }

    
    /* GETTER & SETTER */
    setJobSignatures = (jobSignatures = []) => {
        this.setState({
            jobSignatures: jobSignatures,
        });
    }
    getJobSignatures = () => {
        return this.state.jobSignatures;
    }
    /* END GETTER & SETTER */


    setSignatureForm = () => {
        if(this.state.jobSignatures && this.state.jobSignatures.length > 0){
            return this.state.jobSignatures.map((item, i) => {
                return <Box key={i} style={{ float: 'right' }} width={{ xs: 200, sm: 250, md: 300}} pb={2}>
                    <InnerFilesDropzone 
                        className={'job-form-files-dropzone-signature'}
                        token={this.props.token}
                        dispatchAccountId={this.props.dispatchAccountId}
                        addImage={FileSignatureIcon}
                        multiple={false}
                        contain={true}
                        disabled={this.state.disabled}
                        path={'jobs/signature'}
                        accept={"image/jpeg,image/jpg,image/png"}
                        acceptText={".jpeg, .jpg, .png"}
                        value={(item.jobSignatureUrl && item.jobSignatureUrl !== '') ? [ item.jobSignatureUrl ] : []}
                        onDrop={(files) => {
                            this.setState({
                                signatureAttachmentsIsLoading: true
                            });
                        }}
                        onUpload={(files) => {
                            this.setState({
                                signatureAttachmentsIsLoading: false
                            }, () => {
                                item.jobSignatureUrl = (files && files.length > 0) ? files[0] : '';

                                if(this.props.onChange){
                                    this.props.onChange(this.state.jobSignatures);
                                }
                            });
                        }}
                        onRemove={(file, i, files) => {
                            this.setState({
                                signatureAttachmentsIsLoading: false
                            }, () => {
                                item.jobSignatureUrl = '';

                                if(this.props.onChange){
                                    this.props.onChange(this.state.jobSignatures);
                                }
                            });
                        }}
                    />
                    <Box pt={1} style={{ borderTop: "1px solid #E5E5E5" }}>
                        {(item.jobSignedUserName ? item.jobSignedUserName + ' ' : '') + (item.jobSignedUserContact ? '(' + item.jobSignedUserContact + ')': '')}
                    </Box>
                    {/* <Box pt={1}>
                        <ManageInput
                            className={"bg-white"}
                            value={item.jobSignedUserName}
                            disabled={this.state.disabled}
                            placeholder={"Signed UserName"}
                            onBlur={(value) => {
                                item.jobSignedUserName = value;
                            }}
                        />
                    </Box>
                    <Box>
                        <ManageInput
                            className={"bg-white"}
                            value={item.jobSignedUserContact}
                            disabled={this.state.disabled}
                            placeholder={"Signed Contact"}
                            onBlur={(value) => {
                                item.jobSignedUserContact = value;
                            }}
                        />
                    </Box> */}
                </Box>
            });
        }
        return 
    }


    render() {
        return <Box>
            {this.setSignatureForm()}
        </Box>
    }
}

export default PODSignature;
