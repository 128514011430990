import React, { Component } from 'react';

import {
  Box
} from '@material-ui/core';

import Icon from '@material-ui/core/Icon';


class CustomToggle extends Component {
  
  constructor(props){
    super(props);

    let checked = ((props.checked === true || props.checked === false) ? props.checked : false);
    let disabled = ((props.disabled === true || props.disabled === false) ? props.disabled : false);
    let className = (props.className) ? props.className : '';
    let yes = (props.yes) ? props.yes : <span>YES</span>;
    let no = (props.no) ? props.no : <span>NO</span>;

    this.state = {
        checked: checked,
        disabled: disabled,
        className: className,
        yes: yes,
        no: no,
    }
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.checked !== this.state.checked) {
        let checked = ((nextProps.checked === true || nextProps.checked === false) ? nextProps.checked : false);
        this.setState({
            checked: checked
        });
    }
    if (nextProps.disabled !== this.state.disabled) {
        let disabled = ((nextProps.disabled === true || nextProps.disabled === false) ? nextProps.disabled : false);
        this.setState({
            disabled: disabled
        });
    }
  }


  render() {
    return <Box className={"custom-toggle-component " + this.state.className + ' ' + (this.state.disabled ? 'disabled' : (this.state.checked ? '' : ''))}>
      <Box 
        className={'custom-wrapper'}
        onClick={() => {
            if(!this.state.disabled && this.props.onChange){
                this.props.onChange(!this.state.checked);
            }
        }}
    >
          <Box className={'custom-text'}>{this.state.checked ? this.state.yes : this.state.no}</Box>
          <Box className={'custom-btns'}>
            <Box className={'custom-up'}>
                <Icon component={'i'} >expand_less</Icon>
            </Box>
            <Box className={'custom-down'}>
                <Icon component={'i'} >expand_more</Icon>
            </Box>
          </Box>
      </Box>
    </Box>;
  }
}

export default CustomToggle;
