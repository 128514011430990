import React from "react";

import { 
    Row,
    Col,
    Form,
} from "react-bootstrap-v5";

import DraggableListByChildren from '../../../components/draggableList/DraggableListByChildren';

import Utils from "../../../utils/utils";

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet } from '../../../../setup/redux/dispatch/actions'


class PrintedReceiptForm extends React.Component {
  
    constructor(props) {
        super(props);
    
        this.state = {
            items1: [
                { 
                    id: 'items1' + 1,
                    data: { sortOrder: 0 }
                },
                {
                    id: 'items1' + 2,
                    data: { sortOrder: 1 }
                },
                {
                    id: 'items1' + 3,
                    data: { sortOrder: 2 }
                },
                {
                    id: 'items1' + 4,
                    data: { sortOrder: 3 }
                },
                {
                    id: 'items1' + 5,
                    data: { sortOrder: 4 }
                },
            ],
            items2: [
                
                { 
                    id: 'items2' + 1,
                    data: { sortOrder: 0 }
                },
                {
                    id: 'items2' + 2,
                    data: { sortOrder: 1 }
                },
                {
                    id: 'items2' + 3,
                    data: { sortOrder: 2 }
                },
            ],
        };
    }


    body = () => {
        let {
            setFieldValue,
            handleChange,
            handleBlur,
            values,
            errors,
        } = this.props.formOptions;
        
        return <>
            <Row className={'mb-10'}>
                <Col xs={12} md={2} xxl={1}>Is visible</Col>
                <Col xs={12} md={4} lg={3} xxl={2}>Default label name</Col>
                <Col xs={12} md={true}>New label name</Col>
                <Col xs={12}>
                    <hr />
                </Col>
            </Row>
            <Row className={'mb-10'}>
                <Col xs={12}>
                    <h5 className={'mb-0'}>JOB DETAILS</h5>
                </Col>
                <Col xs={12}>
                    <hr />
                </Col>
            </Row>
            <DraggableListByChildren 
                droppableId={'printedReceiptJobDetailsDroppable'}
                onDragEnd={(items, sourceIndex, destinationIndex) => {
                    if(items && items.length > 0){
                        items.forEach((item, i) => {
                            if(item && item.itemData && item.itemData.length > 0){
                                item.itemData.forEach((itemD, j) => {
                                    setFieldValue(itemD, item.sortOrder);
                                });
                            }
                        });
                    }
                }}
            >
                <Form.Group as={Row} sortOrder={values.printedReceiptShowCustomerNameSortOrder} itemData={['printedReceiptShowCustomerNameSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.printedReceiptShowCustomerName.id}
                            label={null}
                            checked={values.printedReceiptShowCustomerName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.printedReceiptShowCustomerName}
                            feedback={errors.printedReceiptShowCustomerName}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.printedReceiptLabelCustomerName.label}</Form.Label>
                    <Col xs={12} md={true}>
                        {/* <Form.Control
                            type="text"
                            id={this.props.fields.printedReceiptLabelCustomerName.id}
                            placeholder={this.props.fields.printedReceiptLabelCustomerName.placeholder}
                            value={values.printedReceiptLabelCustomerName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.printedReceiptLabelCustomerName}
                        />
                        <Form.Control.Feedback type="invalid">{errors.printedReceiptLabelCustomerName}</Form.Control.Feedback> */}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.printedReceiptLabelCustomerAddressSortOrder} itemData={['printedReceiptLabelCustomerAddressSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        {/* <Form.Check
                            id={this.props.fields.printedReceiptShowCustomerAddress.id}
                            label={null}
                            checked={values.printedReceiptShowCustomerAddress}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.printedReceiptShowCustomerAddress}
                            feedback={errors.printedReceiptShowCustomerAddress}
                        /> */}
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.printedReceiptLabelCustomerAddress.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.printedReceiptLabelCustomerAddress.id}
                            placeholder={this.props.fields.printedReceiptLabelCustomerAddress.placeholder}
                            value={values.printedReceiptLabelCustomerAddress}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.printedReceiptLabelCustomerAddress}
                        />
                        <Form.Control.Feedback type="invalid">{errors.printedReceiptLabelCustomerAddress}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.printedReceiptShowDriverSortOrder} itemData={['printedReceiptShowDriverSortOrder', 'printedReceiptLabelDriverSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.printedReceiptShowDriver.id}
                            label={null}
                            checked={values.printedReceiptShowDriver}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.printedReceiptShowDriver}
                            feedback={errors.printedReceiptShowDriver}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.printedReceiptLabelDriver.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.printedReceiptLabelDriver.id}
                            placeholder={this.props.fields.printedReceiptLabelDriver.placeholder}
                            value={values.printedReceiptLabelDriver}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.printedReceiptLabelDriver}
                        />
                        <Form.Control.Feedback type="invalid">{errors.printedReceiptLabelDriver}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.printedReceiptShowVehicleSortOrder} itemData={['printedReceiptShowVehicleSortOrder', 'printedReceiptLabelVehicleSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.printedReceiptShowVehicle.id}
                            label={null}
                            checked={values.printedReceiptShowVehicle}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.printedReceiptShowVehicle}
                            feedback={errors.printedReceiptShowVehicle}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.printedReceiptLabelVehicle.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.printedReceiptLabelVehicle.id}
                            placeholder={this.props.fields.printedReceiptLabelVehicle.placeholder}
                            value={values.printedReceiptLabelVehicle}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.printedReceiptLabelVehicle}
                        />
                        <Form.Control.Feedback type="invalid">{errors.printedReceiptLabelVehicle}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.printedReceiptShowJobTypeSortOrder} itemData={['printedReceiptShowJobTypeSortOrder', 'printedReceiptLabelJobTypeSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.printedReceiptShowJobType.id}
                            label={null}
                            checked={values.printedReceiptShowJobType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.printedReceiptShowJobType}
                            feedback={errors.printedReceiptShowJobType}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.printedReceiptLabelJobType.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.printedReceiptLabelJobType.id}
                            placeholder={this.props.fields.printedReceiptLabelJobType.placeholder}
                            value={values.printedReceiptLabelJobType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.printedReceiptLabelJobType}
                        />
                        <Form.Control.Feedback type="invalid">{errors.printedReceiptLabelJobType}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.printedReceiptShowPaymentTypeSortOrder} itemData={['printedReceiptShowPaymentTypeSortOrder', 'printedReceiptLabelPaymentTypeSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.printedReceiptShowPaymentType.id}
                            label={null}
                            checked={values.printedReceiptShowPaymentType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.printedReceiptShowPaymentType}
                            feedback={errors.printedReceiptShowPaymentType}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.printedReceiptLabelPaymentType.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.printedReceiptLabelPaymentType.id}
                            placeholder={this.props.fields.printedReceiptLabelPaymentType.placeholder}
                            value={values.printedReceiptLabelPaymentType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.printedReceiptLabelPaymentType}
                        />
                        <Form.Control.Feedback type="invalid">{errors.printedReceiptLabelPaymentType}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.printedReceiptShowCollectedCashSortOrder} itemData={['printedReceiptShowCollectedCashSortOrder', 'printedReceiptLabelCollectedCashSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.printedReceiptShowCollectedCash.id}
                            label={null}
                            checked={values.printedReceiptShowCollectedCash}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.printedReceiptShowCollectedCash}
                            feedback={errors.printedReceiptShowCollectedCash}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.printedReceiptLabelCollectedCash.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.printedReceiptLabelCollectedCash.id}
                            placeholder={this.props.fields.printedReceiptLabelCollectedCash.placeholder}
                            value={values.printedReceiptLabelCollectedCash}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.printedReceiptLabelCollectedCash}
                        />
                        <Form.Control.Feedback type="invalid">{errors.printedReceiptLabelCollectedCash}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
            </DraggableListByChildren>
            

            <Row className={'mb-10 mt-10'}>
                <Col xs={12}>
                    <h5 className={'mb-0'}>DELIVERED BIN ACTIVITIES</h5>
                </Col>
                <Col xs={12}>
                    <hr />
                </Col>
            </Row>
            <DraggableListByChildren 
                droppableId={'printedReceiptBinActivities1Droppable'}
                onDragEnd={(items, sourceIndex, destinationIndex) => {
                    if(items && items.length > 0){
                        items.forEach((item, i) => {
                            if(item && item.itemData && item.itemData.length > 0){
                                item.itemData.forEach((itemD, j) => {
                                    setFieldValue(itemD, item.sortOrder);
                                });
                            }
                        });
                    }
                }}
            >
                <Form.Group as={Row} sortOrder={values.printedReceiptShowBinDeliveredSortOrder} itemData={['printedReceiptShowBinDeliveredSortOrder', 'printedReceiptLabelBinDeliveredSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.printedReceiptShowBinDelivered.id}
                            label={null}
                            checked={values.printedReceiptShowBinDelivered}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.printedReceiptShowBinDelivered}
                            feedback={errors.printedReceiptShowBinDelivered}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.printedReceiptLabelBinDelivered.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.printedReceiptLabelBinDelivered.id}
                            placeholder={this.props.fields.printedReceiptLabelBinDelivered.placeholder}
                            value={values.printedReceiptLabelBinDelivered}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.printedReceiptLabelBinDelivered}
                        />
                        <Form.Control.Feedback type="invalid">{errors.printedReceiptLabelBinDelivered}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.printedReceiptShowBinType1SortOrder} itemData={['printedReceiptShowBinType1SortOrder', 'printedReceiptLabelBinType1SortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.printedReceiptShowBinType1.id}
                            label={null}
                            checked={values.printedReceiptShowBinType1}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.printedReceiptShowBinType1}
                            feedback={errors.printedReceiptShowBinType1}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.printedReceiptLabelBinType1.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.printedReceiptLabelBinType1.id}
                            placeholder={this.props.fields.printedReceiptLabelBinType1.placeholder}
                            value={values.printedReceiptLabelBinType1}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.printedReceiptLabelBinType1}
                        />
                        <Form.Control.Feedback type="invalid">{errors.printedReceiptLabelBinType1}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.printedReceiptShowWasteType1SortOrder} itemData={['printedReceiptShowWasteType1SortOrder', 'printedReceiptLabelWasteType1SortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.printedReceiptShowWasteType1.id}
                            label={null}
                            checked={values.printedReceiptShowWasteType1}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.printedReceiptShowWasteType1}
                            feedback={errors.printedReceiptShowWasteType1}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.printedReceiptLabelWasteType1.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.printedReceiptLabelWasteType1.id}
                            placeholder={this.props.fields.printedReceiptLabelWasteType1.placeholder}
                            value={values.printedReceiptLabelWasteType1}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.printedReceiptLabelWasteType1}
                        />
                        <Form.Control.Feedback type="invalid">{errors.printedReceiptLabelWasteType1}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
            </DraggableListByChildren>


            <Row className={'mb-10 mt-10'}>
                <Col xs={12}>
                    <h5 className={'mb-0'}>COLLECTED BIN ACTIVITIES</h5>
                </Col>
                <Col xs={12}>
                    <hr />
                </Col>
            </Row>
            <DraggableListByChildren 
                droppableId={'printedReceiptBinActivities2Droppable'}
                onDragEnd={(items, sourceIndex, destinationIndex) => {
                    if(items && items.length > 0){
                        items.forEach((item, i) => {
                            if(item && item.itemData && item.itemData.length > 0){
                                item.itemData.forEach((itemD, j) => {
                                    setFieldValue(itemD, item.sortOrder);
                                });
                            }
                        });
                    }
                }}
            >
                <Form.Group as={Row} sortOrder={values.printedReceiptShowBinCollectedSortOrder} itemData={['printedReceiptShowBinCollectedSortOrder', 'printedReceiptLabelBinCollectedSortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.printedReceiptShowBinCollected.id}
                            label={null}
                            checked={values.printedReceiptShowBinCollected}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.printedReceiptShowBinCollected}
                            feedback={errors.printedReceiptShowBinCollected}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.printedReceiptLabelBinCollected.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.printedReceiptLabelBinCollected.id}
                            placeholder={this.props.fields.printedReceiptLabelBinCollected.placeholder}
                            value={values.printedReceiptLabelBinCollected}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.printedReceiptLabelBinCollected}
                        />
                        <Form.Control.Feedback type="invalid">{errors.printedReceiptLabelBinCollected}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.printedReceiptShowBinType2SortOrder} itemData={['printedReceiptShowBinType2SortOrder', 'printedReceiptLabelBinType2SortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.printedReceiptShowBinType2.id}
                            label={null}
                            checked={values.printedReceiptShowBinType2}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.printedReceiptShowBinType2}
                            feedback={errors.printedReceiptShowBinType2}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.printedReceiptLabelBinType2.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.printedReceiptLabelBinType2.id}
                            placeholder={this.props.fields.printedReceiptLabelBinType2.placeholder}
                            value={values.printedReceiptLabelBinType2}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.printedReceiptLabelBinType2}
                        />
                        <Form.Control.Feedback type="invalid">{errors.printedReceiptLabelBinType2}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={values.printedReceiptShowWasteType2SortOrder} itemData={['printedReceiptShowWasteType2SortOrder', 'printedReceiptLabelWasteType2SortOrder']}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.printedReceiptShowWasteType2.id}
                            label={null}
                            checked={values.printedReceiptShowWasteType2}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.printedReceiptShowWasteType2}
                            feedback={errors.printedReceiptShowWasteType2}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.printedReceiptLabelWasteType2.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.printedReceiptLabelWasteType2.id}
                            placeholder={this.props.fields.printedReceiptLabelWasteType2.placeholder}
                            value={values.printedReceiptLabelWasteType2}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.printedReceiptLabelWasteType2}
                        />
                        <Form.Control.Feedback type="invalid">{errors.printedReceiptLabelWasteType2}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
            </DraggableListByChildren>
            

            <Row className={'mt-15 mb-10'}>
                <Col xs={12}>
                    <h5 className={'mb-0'}>ADDITIONAL SERVICES</h5>
                </Col>
                <Col xs={12}>
                    <hr />
                </Col>
            </Row>
            <DraggableListByChildren 
                droppableId={'printedReceiptAdditionalServicesDroppable'}
                onDragEnd={(items, sourceIndex, destinationIndex) => {
                    if(items && items.length > 0){
                        items.forEach((item, i) => {
                            if(item && item.itemData && item.itemData.length > 0){
                                item.itemData.forEach((itemD, j) => {
                                    setFieldValue(itemD, item.sortOrder);
                                });
                            }
                        });
                    }
                }}
            >
                <Form.Group as={Row} sortOrder={0} itemData={['printedReceiptShowAdditionalServicesSortOrder']} draggable={false}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.printedReceiptShowAdditionalServices.id}
                            label={null}
                            checked={values.printedReceiptShowAdditionalServices}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.printedReceiptShowAdditionalServices}
                            feedback={errors.printedReceiptShowAdditionalServices}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.printedReceiptShowAdditionalServices.label}</Form.Label>
                    <Col xs={12} md={true}>
                        
                    </Col>
                </Form.Group>
            </DraggableListByChildren>
            
            
            <Row className={'mt-15 mb-10'}>
                <Col xs={12}>
                    <h5 className={'mb-0'}>OTHERS</h5>
                </Col>
                <Col xs={12}>
                    <hr />
                </Col>
            </Row>
            <DraggableListByChildren 
                droppableId={'printedReceiptOthersDroppable'}
                nonDraggableOnBotton={true}
                onDragEnd={(items, sourceIndex, destinationIndex) => {
                    if(items && items.length > 0){
                        items.forEach((item, i) => {
                            if(item && item.itemData && item.itemData.length > 0){
                                item.itemData.forEach((itemD, j) => {
                                    setFieldValue(itemD, item.sortOrder);
                                });
                            }
                        });
                    }
                }}
            >
                <Form.Group as={Row} sortOrder={2} itemData={['printedReceiptShowSignatureSortOrder']} draggable={false}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.printedReceiptShowSignature.id}
                            label={null}
                            checked={values.printedReceiptShowSignature}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.printedReceiptShowSignature}
                            feedback={errors.printedReceiptShowSignature}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.printedReceiptShowSignature.label}</Form.Label>
                    <Col xs={12} md={true}>
                        
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={3} itemData={['printedReceiptShowSiteContactSortOrder']} draggable={false}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.printedReceiptShowSiteContact.id}
                            label={null}
                            checked={values.printedReceiptShowSiteContact}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.printedReceiptShowSiteContact}
                            feedback={errors.printedReceiptShowSiteContact}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.printedReceiptShowSiteContact.label}</Form.Label>
                    <Col xs={12} md={true}>
                        
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={4} itemData={['printedReceiptShowSiteTelephoneSortOrder']} draggable={false}>
                    <Col xs={12} md={2} xxl={1}>
                        <Form.Check
                            id={this.props.fields.printedReceiptShowSiteTelephone.id}
                            label={null}
                            checked={values.printedReceiptShowSiteTelephone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.printedReceiptShowSiteTelephone}
                            feedback={errors.printedReceiptShowSiteTelephone}
                        />
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.printedReceiptShowSiteTelephone.label}</Form.Label>
                    <Col xs={12} md={true}>
                        
                    </Col>
                </Form.Group>
                <Form.Group as={Row} sortOrder={5} itemData={['printedReceiptLabelFooterSortOrder']} draggable={false}>
                    <Col xs={12} md={2} xxl={1}>
                        {/* <Form.Check
                            id={this.props.fields.printedReceiptShowFooter.id}
                            label={null}
                            checked={values.printedReceiptShowFooter}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.printedReceiptShowFooter}
                            feedback={errors.printedReceiptShowFooter}
                        /> */}
                    </Col>
                    <Form.Label column xs={12} md={4} lg={3} xxl={2}>{this.props.fields.printedReceiptLabelFooter.label}</Form.Label>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={this.props.fields.printedReceiptLabelFooter.id}
                            placeholder={this.props.fields.printedReceiptLabelFooter.placeholder}
                            value={values.printedReceiptLabelFooter}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.printedReceiptLabelFooter}
                        />
                        <Form.Control.Feedback type="invalid">{errors.printedReceiptLabelFooter}</Form.Control.Feedback>
                    </Col>
                </Form.Group>
            </DraggableListByChildren>
        </>
    }

    render() {
        return <Row>
            <Col xs={12} md={10} xl={8}>{this.body()}</Col>
        </Row>
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PrintedReceiptForm);
