/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Yup from 'yup';
import _ from 'lodash';

import * as dispatchCrudApi from '../../../api/CRUD/DispatchCRUD'
import Utils from "../../../utils/utils";
import { ParseResult } from "../../../utils/interfaces";


export const fields = {
  binNumberName: {
    id: 'binNumberName',
    label: 'Bin number',
    placeholder: ' ',
  },
  binTypeId: {
    id: 'binTypeId',
    label: 'Bin type',
    placeholder: ' ',
  },
};

export const formSchema = () => {
  return Yup.object().shape({
    binNumberName: Yup.string().required().label(fields.binNumberName.label),
    binTypeId: Yup.number().nullable().required().label(fields.binTypeId.label),
  })
}

export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    const formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};

export interface initialValuesStruct {
  binNumberName: string,
  binTypeId: number|null,
  binTypeName: string,
};
export const initialValues: initialValuesStruct = {
  binNumberName: '',
  binTypeId: null,
  binTypeName: '',
};

export const prepareForm = (data: any = null, initialValues: any) => {
  if(data){
    let binType = (data && data.binType) ? data.binType : null;
    let binTypeId = (binType && binType.binTypeId) ? binType.binTypeId : null;
    let binTypeName = (binType && binType.binTypeName) ? binType.binTypeName : '';

    let rowValues = {
      binNumberName: (data && data.binNumberName && data.binNumberName !== '') ? data.binNumberName : '',
      
      binTypeId: binTypeId,
      binTypeName: binTypeName,
    };

    return rowValues;
  } else {
    return initialValues;
  }
};
export const prepareData = (id: any = null, values: any = null) => {
  let data: any = {};

  if(values){
    data['binNumberName'] = values.binNumberName;
    
    if(values.binTypeId && values.binTypeId > 0){
      data['binTypeId'] = values.binTypeId
    }
  }

  if(id && id > 0){
    data['binNumberId'] = id;
    data['promptMerge'] = true;
  }

  return data;
};


interface InitState {
  isLoading: boolean,
  show: boolean,
  id: any,
  details: any,
}


function NewReducer() {
  const name = 'binCenterBinNumberPopup';


  const initialState: InitState = {
    isLoading: false,
    show: false,
    id: null,
    details: null,
  };


  const reducers = {
    setShow: (state: InitState, action: PayloadAction<{ id: any }>) => {
      state.id = action.payload.id;
      state.details = null;
      state.show = true;
      state.isLoading = false;
    },
    setHide: (state: InitState) => {
      state.id = null;
      state.details = null;
      state.show = false;
      state.isLoading = false;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    
    startCreate: (state: InitState) => {
      state.isLoading = true;
    },
    finishCreate: (state: InitState, action: PayloadAction<{ data: any }>) => {
      state.isLoading = false;
    },

    startUpdate: (state: InitState) => {
      state.isLoading = true;
    },
    finishUpdate: (state: InitState, action: PayloadAction<{ data: any }>) => {
      state.isLoading = false;
    },
    
    startDetails: (state: InitState) => {
      state.isLoading = true;
    },
    finishDetails: (state: InitState, action: PayloadAction<{ data: any }>) => {
      state.details = action.payload.data
      state.isLoading = false;
    },
  };


  const apis = {
    callCreateApi: (param: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startCreate());

      await dispatchCrudApi.createApi(param, 'binnumber').then(result => {
        let data = result.data;
        
        callback(true, data);
        dispatch(actions.finishCreate({ data }));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false, null);
        dispatch(actions.finishCreate({ data: null }));
      });
    },
    
    callUpdateApi: (param: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startUpdate());

      await dispatchCrudApi.updateApi(param, 'binnumber').then(result => {
        let data = result.data;
        
        callback(true, data);
        dispatch(actions.finishUpdate({ data }));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let mergeToBinNumberId = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.mergeToBinNumberId != '') ? true : false;
        if(mergeToBinNumberId){
          callback(false, res.errorMessage);
          dispatch(actions.finishUpdate({ data: res.errorMessage }));
        } else {
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
            Utils.toast(err, 'error');
          }
          callback(false, null);
          dispatch(actions.finishUpdate({ data: null }));
        }
      });
    },

    callDetailsApi: (id: number, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDetails());

      await dispatchCrudApi.readApi(null, 'binnumber/' + id).then(result => {
        let data = result.data;
        
        callback(true, data);
        dispatch(actions.finishDetails({ data }));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false, null);
        dispatch(actions.finishDetails({ data: null }));
      });
    },
  }


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();