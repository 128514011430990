import React, { Component } from 'react';

import Utils from '../../utils/utils';
import apiUtil from '../../api/apiUtil.jsx';
import UserApi from '../../api/OLD/CommonProfile/User.jsx';

import { 
  Box,
  Grid,
  Button,
  InputAdornment,
  CircularProgress
} from '@material-ui/core';

import ManageInput from '../../components/OLD/input/ManageInput.jsx';
import ManageLabel from '../../components/OLD/input/ManageLabel.jsx';
import CustomCropper from '../../components/OLD/Cropper/CustomCropper.jsx';


class CreateUserDialog extends Component {
  
  constructor(props){
    super(props);

    this.state = {
        showError: false,
        errorText: '',
        errorSeverity: null,
    
      isLoading: true,
      
      userId: 0,
      name: '',
      nameValidated: false,
      profileImageUrl: '',

      email: '',
      emailValidated: false,
      
      currentPassword: '',
      currentPasswordValidated: false,
      
      newPassword: '',
      newPasswordValidated: false,
      newPasswordError: '',
      
      confirmPassword: '',
      confirmPasswordValidated: false,
      confirmPasswordError: '',

      showConfirmPassword: false,
      showNewPassword: false,
      showCurrentPassword: false,
    }
  }


  componentDidMount() {
    let item = this.getUserInfo();
    if(item){
        let userId = (item && item.userId) ? item.userId : 0;
        let name = (item && item.name) ? item.name : '';
        let email = (item && item.email) ? item.email : '';
        let profileImageUrl = (item && item.profileImageUrl) ? item.profileImageUrl : '';

        this.setState({
            userId: userId,
            name: name,
            email: email,
            profileImageUrl: profileImageUrl,
            isLoading: false,
        });
    } else {
    //     if(this.props.onClose)
    //         this.props.onClose()
    }
  }


  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    // const { setUser } = this.context;

    const form = e.currentTarget;

    this.setState({
      isLoading: true,
      nameValidated: false,
      emailValidated: false,
      currentPasswordValidated: false,
      confirmPasswordValidated: false,
      confirmPasswordError: '',
      newPasswordError: '',
    });

    let state = true;
    
    if(this.state.name === ''){
      this.setState({
        nameValidated: true
      });
    }
    if(this.state.email === ''){
      this.setState({
        emailValidated: true
      });
    }

    if(this.isPassRequired()){
        if(this.state.currentPassword === ''){
            this.setState({
                currentPasswordValidated: true
            });
        }
        if(this.state.newPassword === ''){
            this.setState({
                newPasswordValidated: true
            });
        }
        if(this.state.confirmPassword === ''){
            this.setState({
                confirmPasswordValidated: true
            });
        }

        if(this.state.newPassword !== this.state.confirmPassword){
            state = false;
            this.setState({
                isLoading: false,
                confirmPasswordValidated: true,
                confirmPasswordError: 'The new password and the repeated password do not match',
            });
        } else if (!apiUtil.passwordValidation(this.state.newPassword)){
            state = false;
            this.setState({
                isLoading: false,
                newPasswordValidated: true,
                newPasswordError: 'The password must contain minimum eight characters, at least one letter, one number and one special character'
            });
        }
    }
    

    if ((state) && (form.checkValidity() === true)) {
        let data = {
            userId: this.state.userId,
            profileImageUrl: this.state.profileImageUrl,
            name: this.state.name,
            email: this.state.email,
        };

        if(this.state.newPassword !== ''){
            data['currentPassword'] = this.state.currentPassword;
            data['password'] = this.state.newPassword;
            data['confirmPassword'] = this.state.confirmPassword;
        }

        this.callUpdateUserApi(data, (userData) => {
            // setUser(JSON.stringify(userData));

            if(this.props.onSave)
                this.props.onSave(userData)
        });
    } else {
      this.setState({
        isLoading: false
      });
    }
  }


  getUserInfo = () => {
    return this.props.user;
  }

    /* USER API */
	callUpdateUserApi = (data, callback = null) => {
		const { token } = this.context;
		
		this.setState({
			isLoading: true
		});

		UserApi.updateSettings(
			data, 
			token
		).then(result => {
			apiUtil.parseResult(result, (data) => {
                Utils.toast('Successfully updated user!', 'success');
                
				this.setState({
					isLoading: false,
				}, () => {
					if(callback !== null){
						callback(data);
					}
				});
			}, (error, type) => {
                Utils.toast(error, 'error');
                
				this.setState({
					isLoading: false,
				});
			});
		});
    }
    /* END USER API */


    
  isPassRequired = () => {
    return ((this.state.currentPassword !== '') || (this.state.newPassword !== '') || (this.state.confirmPassword !== '')) ? true : false;
  }
  
  setPasswordIcon = (showPassword) => {
    if(this.state[showPassword]) {
        return <InputAdornment position="end">
        <i className={"fas fa-eye"}
            onClick={() => {
                this.setState({
                    [showPassword]: !this.state[showPassword]
                });
            }}
        ></i>
        </InputAdornment>
    } else {
        return <InputAdornment position="end">
        <i className={"fas fa-eye-slash"}
            onClick={() => {
                this.setState({
                    [showPassword]: !this.state[showPassword]
                });
            }}
        ></i>
        </InputAdornment>
    }
  }


  setPage = () => {
    return <Box>
        <Grid 
            container 
            justifyContent={'center'}
            alignItems={'center'}
            alignContent={'center'}
        >
            <Box clone pb={5}>
                <Grid item xs={12}>
                    <ManageLabel 
                        label={'User Setting'}
                        size={'xl'}
                    />
                </Grid>
            </Box>
            <Box clone pb={{ xs: 5, lg: 0 }}>
                <Grid item xs={12} lg={4}>
                    <CustomCropper
                        path={'users'}
                        initialImg={this.state.profileImageUrl}
                        onLoad={() => {
                            this.setState({
                                isLoading: true
                            });
                        }}
                        onUpload={(url) => {
                            this.setState({
                                profileImageUrl: url
                            });
                        }}
                        onEnd={() => {
                            this.setState({
                                isLoading: false
                            });
                        }}
                    />
                </Grid>
            </Box>
            <Box clone>
                <Grid item xs={12} lg={8}>

                    {/* ROW */}
                    <Box pb={2}>
                        <Grid container>
                            <Box clone className={'v-left-center'}>
                                <Grid item xs={12} lg={4}>Name</Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} lg={8}>
                                    <ManageInput 
                                        value={this.state.name}
                                        placeholder={"Enter name"}
                                        readOnly={false}
                                        autoFocus={true}
                                        required={true}
                                        error={this.state.nameValidated} 
                                        onChange={(e) => {
                                            this.setState({
                                                name: e.target.value
                                            });
                                        }}
                                    />
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>
                    {/* END ROW */}

                    {/* ROW */}
                    <Box pb={5}>
                        <Grid container>
                            <Box clone className={'v-left-center'}>
                                <Grid item xs={12} lg={4}>Email</Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} lg={8}>
                                    <ManageInput 
                                        value={this.state.email}
                                        placeholder={"Enter email"}
                                        type={'email'}
                                        autoComplete={"new-password"}
                                        readOnly={true}
                                        required={true}
                                        error={this.state.emailValidated} 
                                        onChange={(e) => {
                                            this.setState({
                                                email: e.target.value
                                            });
                                        }}
                                    />
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>
                    {/* END ROW */}

                    {/* ROW */}
                    <Box pb={2}>
                        <Grid container>
                            <Box clone className={'v-left-center'}>
                                <Grid item xs={12} lg={4}>Current Password</Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} lg={8}>
                                    <ManageInput 
                                        value={this.state.currentPassword}
                                        placeholder={"Enter current password"}
                                        required={this.isPassRequired()}
                                        error={this.state.currentPasswordValidated} 
                                        type={this.state.showCurrentPassword ? 'text' : 'password'}
                                        endAdornment={this.setPasswordIcon('showCurrentPassword')}
                                        onChange={(e) => {
                                            this.setState({
                                                currentPassword: e.target.value
                                            });
                                        }}
                                    />
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>
                    {/* END ROW */}

                    {/* ROW */}
                    <Box pb={2}>
                        <Grid container>
                            <Box clone className={'v-left-center'}>
                                <Grid item xs={12} lg={4}>New password</Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} lg={8}>
                                    <ManageInput 
                                        value={this.state.newPassword}
                                        placeholder={"Enter new password"}
                                        autoComplete={"new-password"}
                                        required={this.isPassRequired()}
                                        error={this.state.newPasswordValidated} 
                                        errorText={this.state.newPasswordError} 
                                        type={this.state.showNewPassword ? 'text' : 'password'}
                                        endAdornment={this.setPasswordIcon('showNewPassword')}
                                        onChange={(e) => {
                                            this.setState({
                                                newPassword: e.target.value
                                            });
                                        }}
                                    />
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>
                    {/* END ROW */}

                    {/* ROW */}
                    <Box pb={2}>
                        <Grid container>
                            <Box clone className={'v-left-center'}>
                                <Grid item xs={12} lg={4}>New password</Grid>
                            </Box>
                            <Box clone>
                                <Grid item xs={12} lg={8}>
                                    <ManageInput 
                                        value={this.state.confirmPassword}
                                        placeholder={"Enter confirm password"}
                                        required={this.isPassRequired()}
                                        error={this.state.confirmPasswordValidated} 
                                        errorText={this.state.confirmPasswordError} 
                                        type={this.state.showConfirmPassword ? 'text' : 'password'}
                                        endAdornment={this.setPasswordIcon('showConfirmPassword')}
                                        onChange={(e) => {
                                            this.setState({
                                                confirmPassword: e.target.value
                                            });
                                        }}
                                    />
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>
                    {/* END ROW */}

                </Grid>
            </Box>
        </Grid>
    </Box>
  }


  render() {
    return <Box className="create-user-form">
      <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
        <Grid container>
          <Box clone>
            <Grid item xs={12} md={true}>
                {this.setPage()}
            </Grid>
          </Box>
          
          <Box clone pt={3} textAlign={"right"}>
            <Grid item xs={12}>
              <Button 
                  className={'no-propagation'}
                  color={'default'} 
                  variant={'contained'}
                  style={{ marginRight: '10px' }}
                  onClick={() => {
                    if(this.props.onClose)
                      this.props.onClose()
                  }}
              >
                CANCEL
              </Button>
              <Button 
                  className={'no-propagation'}
                  type={'submit'}
                  color={'primary'} 
                  variant={'contained'}
                  disabled={this.state.isLoading}
              >
                SAVE
                {this.state.isLoading && <span style={{ paddingLeft: '10px' }}><CircularProgress size={15} style={{ color: 'white' }} /></span>}
              </Button>
            </Grid>
          </Box>
        </Grid>
      </form>
    </Box>;
  }
}

export default CreateUserDialog;
