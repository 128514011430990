import React from "react";
import moment from 'moment';

import { 
  Row,
  Col,
  Form,
} from "react-bootstrap-v5";

import Utils from '../../utils/utils';


const CurrentDateTime = (props) => {
    const [currentDateTime, setCurrentDateTime] = React.useState(null);


    React.useEffect(() => {
        const timer = setInterval(() => {
            setCurrentDateTime(Utils.getTZDateFromSettings());
        }, 1000);
        
        return () => {
            clearInterval(timer);
        }
    }, []);


    return <Row className={'align-items-center justify-content-md-end'}>
        <Col xs={'auto'} className={'order-2 order-md-1'}>
            {currentDateTime && <Form.Label style={{ margin: '0' }}>
                <i className={'material-icons'} style={{ position: 'relative', top: '6px' }}>access_time</i>&nbsp;{currentDateTime.format('hh:mm:ss a')}
            </Form.Label>}
        </Col>
        <Col xs={'auto'} className={'order-1 order-md-2'}>
            {currentDateTime && <Form.Label style={{ margin: '0' }}>
                <i className={'material-icons'} style={{ position: 'relative', top: '6px' }}>date_range</i>&nbsp;{currentDateTime.format('DD-MMM-YYYY (ddd)')}
            </Form.Label>}
        </Col>
    </Row>
}

export default CurrentDateTime;