import React from 'react';
import {isMobile} from 'react-device-detect';

import {
  Box,
  Button,
  Popper,
  MenuItem,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  Checkbox,
  Divider,
} from '@material-ui/core';


const DropdownCheckbox = props => {
    const { text, color, leftIcon, rightIcon, onChange, onApply, variant, className } = props;

    const [open, setOpen] = React.useState(false);
    const [items, setItems] = React.useState((props.menuItems && props.menuItems.length > 0) ? props.menuItems : []);
    const anchorRef = React.useRef(null);


    const handleToggle = (e) => {
      e.preventDefault();
      e.stopPropagation();
      
      setOpen(prevOpen => !prevOpen);
    };
  
    const handleClose = event => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
  
      setOpen(false);
    };

    function handleListKeyDown(event) {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      }
    }


    const prevOpen = React.useRef(open);
    React.useEffect(() => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
      }

      prevOpen.current = open;

      setItems((props.menuItems && props.menuItems.length > 0) ? props.menuItems : []);
    }, [open, props.menuItems]);
    

    const isCheckedAll = () => {
        return items.every(x => x.checked === true);
    };
    const isUncheckedAll = () => {
        return items.every(x => x.checked !== true);
    };
    const checkAllItems = () => {
        if(items && items.length > 0){
            let isChecked = isCheckedAll();

            setItems(items.map((item, i) => {
                item.checked = (isChecked) ? false : true;
                return item;
            }));
        }
    };
    const checkItem = (index, checked) => {
        setItems(items.map((item, i) => {
            if(index === i){
                item.checked = checked;
            }
            return item;
        }));
    };


    const setSelectAll = () => {
        return <MenuItem 
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                checkAllItems();
                
                if(onChange){
                    onChange(items);
                }
            }}
        >
            <Checkbox 
                indeterminate={(!isCheckedAll() && !isUncheckedAll())}
                checked={isCheckedAll()}
                color="default"
            />
            Select All
        </MenuItem>
    };
    const setMenuItems = () => {
      if(items && items.length > 0){
        return items.map((item, i) => {
          return <MenuItem 
            key={i}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              checkItem(i, !item.checked);

              if(onChange){
                onChange(items);
              }
            }}
          >
            <Checkbox 
                checked={item.checked}
                color="default"
            />
            {item.text}
          </MenuItem>
        });
      }
    };
    const setApply = () => {
        return <MenuItem 
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                setOpen(false);

                if(onApply){
                    onApply(items.filter(x => x.checked === true));
                }
            }}
        >
            <Box width={'100%'} textAlign={'center'}>Apply</Box>
        </MenuItem>
    };


    return (
        <>
            <Button
              ref={anchorRef}
              style={{ height: '50px', width: (isMobile ? '100%' : 'auto') }}
              aria-haspopup="true"
              color={(color ? color : 'primary')}
              variant={(variant ? variant : 'contained')}
              onClick={handleToggle}
              className={className}
            >
              {leftIcon}{text}{rightIcon}
            </Button>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition style={{ zIndex: '9999' }}>
            {({ TransitionProps, placement }) => (
                <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
                            {setSelectAll()}
                            <Divider />
                            {setMenuItems()}
                            <Divider />
                            <Box width={'100%'}>&nbsp;</Box>
                            {setApply()}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
                </Grow>
            )}
            </Popper>
        </>
    );
  }

  export default DropdownCheckbox;