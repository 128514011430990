import React, { Component } from 'react';

import { 
    Box,
    Grid,
    Button,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CircularProgress,
} from '@material-ui/core';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';


class CustomPaperDialog extends Component {

    constructor(props){
        super(props);
    
        let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
        let open = ((props.open === true || props.open === false) ? props.open : false);
        let isCloseBtn = ((props.isCloseBtn === true || props.isCloseBtn === false) ? props.isCloseBtn : true);
        let fullWidth = ((props.fullWidth === true || props.fullWidth === false) ? props.fullWidth : false);
        let title = (props.title) ? (props.title !== '') ? props.title : null : null;
        let maxWidth = (props.maxWidth) ? (props.maxWidth !== '') ? props.maxWidth : null : 'sm';
        let closeBtnPosition = (props.closeBtnPosition) ? props.closeBtnPosition : 'out';
        let scroll = (props.scroll) ? props.scroll : 'paper';
        let closeIcon = (props.closeIcon) ? props.closeIcon : <HighlightOffIcon />;
        let className = (props.className) ? props.className : '';
        
        let btnOkColor = (this.props.btnOkColor) ? this.props.btnOkColor : 'primary';
        let btnOkVariant = (this.props.btnOkVariant) ? this.props.btnOkVariant : 'contained';
        let btnOkText = (this.props.btnOkText) ? this.props.btnOkText : 'Select';
        
        let btnCloseColor = (this.props.btnCloseColor) ? this.props.btnCloseColor : 'default';
        let btnCloseVariant = (this.props.btnCloseVariant) ? this.props.btnCloseVariant : 'contained';
        let btnCloseText = (this.props.btnCloseText) ? this.props.btnCloseText : 'Cancel';
        

        this.state = {
            open: open,
            isLoading: isLoading,
            isCloseBtn: isCloseBtn,
            closeBtnPosition: closeBtnPosition,
            scroll: scroll,
            closeIcon: closeIcon,
            fullWidth: fullWidth,
            maxWidth: maxWidth,
            title: title,
            className: className,

            btnOkColor: btnOkColor,
            btnOkVariant: btnOkVariant,
            btnOkText: btnOkText,

            btnCloseColor: btnCloseColor,
            btnCloseVariant: btnCloseVariant,
            btnCloseText: btnCloseText,
        }
    }


    componentWillReceiveProps(nextProps) {
		if (nextProps.open !== this.state.open) {
			this.setState({
				open: nextProps.open
			});
		}
	}


    handleClose = (e, r) => {
        if(r !== 'backdropClick'){
            this.setState({
                open: false
            });
    
            if(this.props.onClose){
                this.props.onClose();
            }
        }
    }


    render() {
        return <Dialog
            className={"custom-dialog-component " + this.state.className}
            open={this.state.open}
            onClose={this.handleClose}
            fullWidth={this.state.fullWidth}
            maxWidth={(this.state.fullWidth) ? null : this.state.maxWidth}
            scroll={this.state.scroll}
            aria-labelledby="scroll-dialog-title"
        >
            <DialogTitle disableTypography id="scroll-dialog-title">
                <Grid container>
                    {this.state.title && <Box clone minHeight={20}>
                        <Grid item xs={true}>
                            <b>{this.state.title}</b>
                        </Grid>
                    </Box>}
                    <Box clone minHeight={20}>
                        <Grid item xs={'auto'}>
                            {this.state.isCloseBtn && <IconButton 
                                className={this.state.closeBtnPosition === "out" ? "dialog-close-btn-out" : "dialog-close-btn-in"}
                                onClick={this.handleClose}
                            >
                                {this.state.closeIcon}
                            </IconButton>}
                        </Grid>
                    </Box>
                </Grid>
            </DialogTitle>

            <DialogContent style={{ paddingTop: '0px' }}>{this.props.children}</DialogContent>

            <DialogActions>
                <Grid container>
                    <Box clone textAlign={"right"}>
                        <Grid item xs={12}>
                            <Button 
                                color={this.state.btnCloseColor} 
                                variant={this.state.btnCloseVariant}
                                style={{ marginRight: '10px', textTransform: 'none' }}
                                onClick={() => {
                                    if(this.props.onClose)
                                        this.props.onClose()
                                    }
                                }
                            >
                                {this.state.btnCloseText}
                            </Button>
                            <Button 
                                color={this.state.btnOkColor} 
                                variant={this.state.btnOkVariant}
                                style={{ textTransform: 'none' }}
                                disabled={this.state.isLoading}
                                onClick={() => {
                                    if(this.props.onOk)
                                        this.props.onOk()
                                    }
                                }
                            >
                                {this.state.btnOkText}
                                {this.state.isLoading && <span style={{ paddingLeft: '10px' }}><CircularProgress size={15} style={{ color: 'white' }} /></span>}
                            </Button>
                        </Grid>
                    </Box>
                </Grid>
            </DialogActions>
        </Dialog>;
    }
}


export default CustomPaperDialog;