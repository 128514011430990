import React from "react";
import _ from 'lodash';

import { 
    Row,
    Col,
    Form,
    Spinner,
    Button,
} from "react-bootstrap-v5";

import { DrawerContentLayout } from '../../../components/drawer/DrawerLayout'
import Utils from "../../../utils/utils";

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet } from '../../../../setup/redux/dispatch/actions'


class DriversNotesForm extends React.Component {
  
    constructor(props) {
        super(props);
    
        this.bodyRef = React.createRef();

        let dataParams = (props.dataParams) ? props.dataParams : null;
        let id = (dataParams && dataParams.id) ? dataParams.id : null;
        let row = (dataParams && dataParams.row) ? dataParams.row : null;
        
        this.state = {
            id: id,
            row: row,
        };
    }


    body = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;
        
        return <Row>
            
            <Col xs={12}>
                <Form.Group className={'mb-5'}>
                    <Form.Label>{this.props.fields.note.label}</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={5}
                        id={this.props.fields.note.id}
                        placeholder={this.props.fields.note.placeholder}
                        value={values.note}
                        onChange={async (e) => {
                            Utils.limitRows(e.target.value, 5, async (value) => {
                                await setFieldValue('note', value);
                            });
                        }}
                        isInvalid={!!errors.note}
                    />
                    <Form.Control.Feedback type="invalid">{errors.note}</Form.Control.Feedback>
                </Form.Group>
            </Col>

        </Row>
    }
    
    footer = () => {
        let {
            validateForm,
            values,
        } = this.props.formOptions;

        return <Row>
            <Col xs={'auto'}>
                <Button 
                    type={'submit'}
                    disabled={this.props.isLoading}
                >
                    SAVE
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
            <Col xs={'auto'}>
                <Button 
                    type={'button'}
                    variant={'secondary'}
                    disabled={this.props.isLoading}
                    onClick={() => {
                        Utils.showDrawer(this.props.drawer, false);
                    }}
                >
                    CANCEL
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
            
            
            {values.isEdit && <>
                <Col xs={12} className={'d-flex d-sm-none pb-3'}></Col>
                <Col xs={'auto'} sm={true} className={'text-end'}>
                    <Button 
                        type={'button'}
                        variant={'danger'}
                        disabled={this.props.isLoading}
                        onClick={() => {
                            if(this.props.onDelete){
                                this.props.onDelete(values);
                            }
                        }}
                    >
                        REMOVE
                        {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                    </Button>
                </Col>
                <Col xs={'auto'} md={'auto'}>
                    <Button 
                        type={'button'}
                        variant={'outline-secondary'}
                        disabled={this.props.isLoading}
                        onClick={() => {
                            validateForm().then((err) => {
                                if(_.isEmpty(err)){
                                    if(this.props.onCopy){
                                        this.props.onCopy(values);
                                    }
                                } else {
                                    Utils.toast('You must fill in the required fields', 'error');
                                }
                            })
                        }}
                    >
                        <i className={'material-icons'}>content_copy</i>
                        COPY
                        {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                    </Button>
                </Col>
            </>}

        </Row>
    }


    render() {
        return <DrawerContentLayout
            ref={this.bodyRef}
            isBlocking={this.props.isBlocking}
            body={this.body()}
            footer={this.footer()}
        />
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DriversNotesForm);
