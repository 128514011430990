import React, { Component } from 'react';

import { 
  Box,
  CircularProgress,
} from '@material-ui/core';

class CircularProgressBar extends Component {
    
	constructor(props){
        super(props);
        
        let isLoading = ((props.isLoading === false) || (props.isLoading === true)) ? props.isLoading : false;
        let color = (props.color) ? props.color : 'primary';
        let size = (props.size) ? props.size : 25;

		this.state = {
            isLoading: isLoading,
            color: color,
            size: size
		}
	}
    

	componentWillReceiveProps(nextProps) {
        if (nextProps.isLoading !== this.state.isLoading) {
            let isLoading = ((nextProps.isLoading === false) || (nextProps.isLoading === true)) ? nextProps.isLoading : false;
			this.setState({
				isLoading: isLoading
			});
        }
    }

    
	render() {
		return <Box className={"circular-progress-bar-component"} textAlign={'center'} >
            <CircularProgress size={this.state.size} color={this.state.color} />
		</Box>;
	}
}

export default CircularProgressBar;
