import React from 'react';

import { 
  Box
} from '@material-ui/core';

import { useDropzone } from 'react-dropzone';


const InnerDropzone = (props) => {
  const { getRootProps } = useDropzone();
  const { preview, noImage, onClick } = props;

  if(preview){
    return preview
  } else {
    return <Box style={{ backgroundImage: 'url(' + noImage + ')', backgroundSize: '45px' }} {...getRootProps({className: 'dropzone'})} 
      onClick={() => {
        if(onClick)
          onClick();
      }}
    ></Box>
  }
}


export default InnerDropzone;
