import React, { Component } from "react";
import * as yup from 'yup';
import _ from 'lodash';
import moment from 'moment';
import queryString from "query-string";
import { EditorState } from 'draft-js';

import { 
  Row,
  Col,
  Button,
  Form,
  Modal,
  Spinner,
} from "react-bootstrap-v5";

import { Formik } from 'formik';

import Popup from '../../../../components/popup/Popup';

import RemoveJobDialog from '../../../jobs/Dialogs/RemoveJobDialog';

import { DrawerLayout } from '../../../../components/drawer/DrawerLayout'
import CustomerForm from "../../../Customers/partial/CustomerForm.jsx";
import CustomerSiteForm from "../../../Customers/partial/CustomerSiteForm";
import JobFormBinHistoryDrawer from "../../../jobs/Partial/drawer/JobFormBinHistoryDrawer";

import JobsFormLayout from "./Layout/JobsFormLayout";
import HeaderSection from "./Partial/HeaderSection";
import FooterSection from "./Partial/FooterSection";
import TopSection from "../../../jobs/Partial/formSections/TopSection";
import LocationsSection from "../../../jobs/Partial/formSections/LocationsSection";
import JobTypesSection from "../../../jobs/Partial/formSections/JobTypesSection";
import DispatchSection from "../../../jobs/Partial/formSections/DispatchSection";
import AuditTrailSection from "../../../jobs/Partial/formSections/AuditTrailSection";
import PaymentSection from "../../../jobs/Partial/formSections/PaymentSection";
import RecurringSection from "../../../jobs/Partial/formSections/RecurringSection";
import OptionsSection from "../../../jobs/Partial/formSections/OptionsSection";
import StepExchangeSection from "../../../jobs/Partial/formSections/StepExchangeSection";
import StepPutSection from "../../../jobs/Partial/formSections/StepPutSection";
import StepOutSection from "../../../jobs/Partial/formSections/StepOutSection";
import StepPullSection from "../../../jobs/Partial/formSections/StepPullSection";
import StepShiftSection from "../../../jobs/Partial/formSections/StepShiftSection";
import StepOnTheSpotSection from "../../../jobs/Partial/formSections/StepOnTheSpotSection";

import Utils from '../../../../utils/utils'
import { InOrOut, CustomerSiteAvailability, JobAction, ServiceChargeType, JobStepType, ServiceType, AccountSettingType, RepeatOrder } from '../../../../utils/enums'

import { connect } from 'react-redux'
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut, dispatchApiCallDelete } from '../../../../../setup/redux/dispatch/actions'


yup.addMethod(yup.array, 'unique', function (message, mapper = a => a) {
  return this.test('unique', message, function (list) {
    return list.length === new Set(list.map(mapper)).size;
  });
});

const formFields = {
    customerId: {
      id: 'customerId',
      label: 'Company name',
      placeholder: ' ',
    },
    remarks: {
      id: 'remarks',
      label: 'Job Note',
      placeholder: ' ',
    },
    customerNote: {
      id: 'customerNote',
      label: 'Customer Note',
      placeholder: ' ',
    },
    accountBillingProfileId: {
      id: 'accountBillingProfileId',
      label: 'Billing by',
      placeholder: ' ',
    },
    paymentTermsId: {
      id: 'paymentTermsId',
      label: 'Payment terms',
      placeholder: ' ',
    },
    xeroPaymentTerm: {
      id: 'xeroPaymentTerm',
      label: 'Payment terms',
      placeholder: ' ',
    },

    jobTemplateId: {
      id: 'jobTemplateId',
      label: 'Job Type',
      placeholder: ' ',
    },
    customerSiteId: {
      id: 'customerSiteId',
      label: 'Customer Site',
      placeholder: ' ',
      info: '',
    },
    hasCoordCustomerSite: {
      id: 'hasCoordCustomerSite',
      label: 'Customer site doesn\'t have coordinates',
      placeholder: ' ',
    },
    customerSiteShiftId: {
      id: 'customerSiteShiftId',
      label: 'Location To',
      placeholder: ' ',
      info: '',
    },
    hasCoordCustomerSiteShift: {
      id: 'hasCoordCustomerSiteShift',
      label: 'Customer site doesn\'t have coordinates',
      placeholder: ' ',
    },
    templateStepSites1Id: {
      id: 'templateStepSites1Id',
      label: 'Start Location',
      placeholder: ' ',
    },
    templateStepSites1Name: {
      id: 'templateStepSites1Name',
      label: 'Start Location',
      placeholder: ' ',
    },
    templateStepSites2Id: {
      id: 'templateStepSites2Id',
      label: 'End Location',
      placeholder: ' ',
    },
    templateStepSites2Name: {
      id: 'templateStepSites2Name',
      label: 'End Location',
      placeholder: ' ',
    },
    jobDate: {
      id: 'jobDate',
      label: 'Job date',
      placeholder: ' ',
    },
    accountJobWorkingTimeId: {
      id: 'accountJobWorkingTimeId',
      label: 'Job time',
      placeholder: '__:__ __',
    },
    driverId: {
      id: 'driverId',
      label: 'Driver',
      placeholder: ' ',
    },
    vehicleId: {
      id: 'vehicleId',
      label: 'Vehicle',
      placeholder: ' ',
    },
    instructionToDrivers: {
      id: 'instructionToDrivers',
      label: 'Driver instruction',
      placeholder: ' ',
    },
    siteRemarks: {
      id: 'siteRemarks',
      label: 'Site instruction',
      placeholder: ' ',
    },
    contactPersonOne: {
      id: 'contactPersonOne',
      label: 'Contact person 1',
      placeholder: 'Person name',
    },
    contactNumberOne: {
      id: 'contactNumberOne',
      label: 'Phone number 1',
      placeholder: 'Phone number',
    },
    contactPersonTwo: {
      id: 'contactPersonTwo',
      label: 'Contact person 2',
      placeholder: 'Person name',
    },
    contactNumberTwo: {
      id: 'contactNumberTwo',
      label: 'Phone number 2',
      placeholder: 'Phone number',
    },
    isContactPersonSendNotification: {
        id: 'isContactPersonSendNotification',
        label: 'Notification',
        placeholder: ' ',
    },
    isContactPersonTwoSendNotification: {
        id: 'isContactPersonTwoSendNotification',
        label: 'Notification',
        placeholder: ' ',
    },
    statusId: {
      id: 'statusId',
      label: 'Status',
      placeholder: ' ',
    },
    amountToCollect: {
      id: 'amountToCollect',
      label: 'Amount to collect',
      placeholder: ' ',
    },
    collectedAmount: {
      id: 'collectedAmount',
      label: 'Collected by driver',
      placeholder: ' ',
    },
    paymentMethodId: {
      id: 'paymentMethodId',
      label: 'Payment mode',
      placeholder: ' ',
    },
    photoAttachmentsUrl: {
      id: 'photoAttachmentsUrl',
      label: ' ',
      placeholder: ' ',
    },
    jobSignedUserName: {
      id: 'jobSignedUserName',
      label: 'Signed username',
      placeholder: ' ',
    },
    jobSignedUserContact: {
      id: 'jobSignedUserContact',
      label: 'Signed contact',
      placeholder: ' ',
    },

    repeatOrder: {
      id: 'repeatOrder',
      label: 'Repeat Order',
      placeholder: '',
    },
    recurringJobName: {
      id: 'recurringJobName',
      label: 'Template name',
      placeholder: 'Insert a template name for this recurring job',
    },
    recurringJobDescription: {
      id: 'recurringJobDescription',
      label: 'Description',
      placeholder: 'Give this recurring job template a description',
    },
    recurringAssignToSameDriverVehicle: {
      id: 'recurringAssignToSameDriverVehicle',
      label: 'Assign to Same Driver/Vehicle',
      placeholder: '',
    },
    recurringNumberOfTrip: {
      id: 'recurringNumberOfTrip',
      label: 'Number of Trip(s) Daily',
      placeholder: '',
    },
    recurringStartDate: {
      id: 'recurringStartDate',
      label: 'Start recurring date',
      placeholder: ' ',
    },
    recurringEndDate: {
      id: 'recurringEndDate',
      label: 'End recurring date',
      placeholder: ' ',
    },
    recurringDefaultTime: {
      id: 'recurringDefaultTime',
      label: 'Job time',
      placeholder: ' ',
    },
    schedulesDays: [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ],
    schedulesDay: {
      id: 'schedulesDay',
      label: 'Day',
      placeholder: ' ',
    },
    schedulesTime: {
      id: 'schedulesTime',
      label: 'Time',
      placeholder: ' ',
    },

    isRequireBinNumberToEnd1: {
      id: 'isRequireBinNumberToEnd1',
      label: 'Driver is required to input bin ID',
      placeholder: ' ',
    },
    isRequireBinNumberToEnd2: {
      id: 'isRequireBinNumberToEnd2',
      label: 'Driver is required to input bin ID',
      placeholder: ' ',
    },
    mustTakePhoto1: {
      id: 'mustTakePhoto1',
      label: 'photo',
      placeholder: ' ',
    },
    numberofPhotosRequired1: {
      id: 'numberofPhotosRequired1',
      label: 'Driver is required to take',
      placeholder: ' ',
    },
    mustTakeSignature: {
      id: 'mustTakeSignature',
      label: 'Driver is required to take signature',
      placeholder: ' ',
    },
    isRequirePaymentCollection: {
      id: 'isRequirePaymentCollection',
      label: 'Driver is required to collect payment',
      placeholder: ' ',
    },
    isRequireBinWeight: {
      id: 'isRequireBinWeight',
      label: 'Driver is required to input bin weight',
      placeholder: ' ',
    },
    isRequireReviewWasteType: {
      id: 'isRequireReviewWasteType',
      label: 'Driver is required to review waste type',
      placeholder: ' ',
    },
    mustTakePhoto2: {
      id: 'mustTakePhoto2',
      label: 'photo',
      placeholder: ' ',
    },
    numberofPhotosRequired2: {
      id: 'numberofPhotosRequired2',
      label: 'Driver is required to take',
      placeholder: ' ',
    },
    isEditableBinTypeOut: {
      id: 'isEditableBinTypeOut',
      label: 'Driver can change bin type',
      placeholder: ' ',
    },
    isEditableWasteTypeOut: {
      id: 'isEditableWasteTypeOut',
      label: 'Driver can change waste type',
      placeholder: ' ',
    },
    isEditableBinNumberIn: {
      id: 'isEditableBinNumberIn',
      label: 'Driver can add new bin ID',
      placeholder: ' ',
    },
    isEditableBinNumberOut: {
      id: 'isEditableBinNumberOut',
      label: 'Driver can add new bin ID',
      placeholder: ' ',
    },
    isEditableWasteTypeIn: {
      id: 'isEditableWasteTypeIn',
      label: 'Driver can change waste type',
      placeholder: ' ',
    },
    duration: {
      id: 'duration',
      label: 'Default job hours for this job type',
      placeholder: ' ',
    },
    cancellationRemarks: {
      id: 'cancellationRemarks',
      idFailed: 'cancellationRemarksFailed',
      label: 'Cancellation remarks',
      labelFailed: 'Failed remarks',
      placeholder: ' ',
      placeholderFailed: ' ',
    },

    stepExchangeOutBinTypeId: {
      id: 'stepExchangeOutBinTypeId',
      label: 'Bin type',
      placeholder: ' ',
    },
    stepExchangeOutWasteTypeId: {
      id: 'stepExchangeOutWasteTypeId',
      label: 'For waste type',
      placeholder: ' ',
    },
    stepExchangeOutWasteTypeIds: {
      id: 'stepExchangeOutWasteTypeIds',
      label: 'For waste type',
      placeholder: ' ',
    },
    stepExchangeOutBinNumber: {
      id: 'stepExchangeOutBinNumber',
      label: 'Bin ID',
      placeholder: ' ',
    },

    stepExchangeInBinTypeId: {
      id: 'stepExchangeInBinTypeId',
      label: 'Bin type',
      placeholder: ' ',
    },
    stepExchangeInWasteTypeId: {
      id: 'stepExchangeInWasteTypeId',
      label: 'With waste type',
      placeholder: ' ',
    },
    stepExchangeInWasteTypeIds: {
      id: 'stepExchangeInWasteTypeIds',
      label: 'With waste type',
      placeholder: ' ',
      required: 'Bin type is a required field',
    },
    stepExchangeInBinNumber: {
      id: 'stepExchangeInBinNumber',
      label: 'Bin ID',
      placeholder: ' ',
    },

    stepPutBinTypeId: {
      id: 'stepPutBinTypeId',
      label: 'Bin type',
      placeholder: ' ',
    },
    stepPutWasteTypeId: {
      id: 'stepPutWasteTypeId',
      label: 'For waste type',
      placeholder: ' ',
    },
    stepPutWasteTypeIds: {
      id: 'stepPutWasteTypeIds',
      label: 'For waste type',
      placeholder: ' ',
    },
    stepPutBinNumber: {
      id: 'stepPutBinNumber',
      label: 'Bin ID',
      placeholder: ' ',
    },

    stepOutBinTypeId: {
      id: 'stepOutBinTypeId',
      label: 'Bin type',
      placeholder: ' ',
    },
    stepOutWasteTypeId: {
      id: 'stepOutWasteTypeId',
      label: 'For waste type',
      placeholder: ' ',
    },
    stepOutWasteTypeIds: {
      id: 'stepOutWasteTypeIds',
      label: 'For waste type',
      placeholder: ' ',
    },
    stepOutBinNumber: {
      id: 'stepOutBinNumber',
      label: 'Bin ID',
      placeholder: ' ',
    },

    stepPullBinTypeId: {
      id: 'stepPullBinTypeId',
      label: 'Bin type',
      placeholder: ' ',
    },
    stepPullWasteTypeId: {
      id: 'stepPullWasteTypeId',
      label: 'With waste type',
      placeholder: ' ',
    },
    stepPullWasteTypeIds: {
      id: 'stepPullWasteTypeIds',
      label: 'With waste type',
      placeholder: ' ',
    },
    stepPullBinNumber: {
      id: 'stepPullBinNumber',
      label: 'Bin ID',
      placeholder: ' ',
    },

    stepShiftBinTypeId: {
      id: 'stepShiftBinTypeId',
      label: 'Bin type',
      placeholder: ' ',
    },
    stepShiftWasteTypeId: {
      id: 'stepShiftWasteTypeId',
      label: 'For waste type',
      placeholder: ' ',
    },
    stepShiftWasteTypeIds: {
      id: 'stepShiftWasteTypeIds',
      label: 'For waste type',
      placeholder: ' ',
    },
    stepShiftBinNumber: {
      id: 'stepShiftBinNumber',
      label: 'Bin ID',
      placeholder: ' ',
    },

    stepOnTheSpotBinTypeId: {
      id: 'stepOnTheSpotBinTypeId',
      label: 'Bin type',
      placeholder: ' ',
    },
    stepOnTheSpotWasteTypeId: {
      id: 'stepOnTheSpotWasteTypeId',
      label: 'With waste type',
      placeholder: ' ',
    },
    stepOnTheSpotWasteTypeIds: {
      id: 'stepShiftWasteTypeIds',
      label: 'With waste type',
      placeholder: ' ',
    },
    stepOnTheSpotBinNumber: {
      id: 'stepShiftBinNumber',
      label: 'Bin ID',
      placeholder: ' ',
    },

    binOutWeight: {
      id: 'binOutWeight',
      label: 'Bin weight',
      placeholder: ' ',
      info: 'You can enter a maximum of 5 numbers with max 3 decimal places',
    },

    stepBinWeight: {
      id: 'stepBinWeight',
      label: 'Bin weight (Gross)',
      placeholder: ' ',
      info: 'You can enter a maximum of 5 numbers with max 3 decimal places',
    },
    isSameAsBinOut: {
      id: 'isSameAsBinOut',
      label: 'Same as Bin OUT',
      placeholder: ' ',
    },
}
const formSchema = yup.object().shape({
    isEdit: yup.bool().oneOf([true, false]),

    customerId: yup.number().nullable().required().label(formFields.customerId.label),
    remarks: yup.string().nullable().label(formFields.remarks.label),
    customerNote: yup.string().label(formFields.customerNote.label),
    accountBillingProfileId: yup.number().nullable().label(formFields.accountBillingProfileId.label),
    paymentTermsId: yup.number().nullable().label(formFields.paymentTermsId.label),
    xeroPaymentTerm: yup.number().nullable().positive().min(0).label(formFields.xeroPaymentTerm.label),

    jobTemplateId: yup.number().nullable().label(formFields.jobTemplateId.label),

    hasCoordCustomerSite: yup.bool().oneOf([true], formFields.hasCoordCustomerSite.label),
    customerSiteId: yup.number().nullable().label(formFields.customerSiteId.label),

    hasCoordCustomerSiteShift: yup.bool().when(['jobTemplateType'], (jobTemplateType) => {
      if(jobTemplateType === JobStepType.Shift){
        return yup.bool().oneOf([true], formFields.hasCoordCustomerSiteShift.label);
      } else {
        return yup.bool().oneOf([true, false]);
      }
    }),
    customerSiteShiftId: yup.number().when(['jobTemplateType'], (jobTemplateType) => {
      if(jobTemplateType === JobStepType.Shift){
        return yup.number().nullable().label(formFields.customerSiteShiftId.label);
      } else {
        return yup.number().nullable().label(formFields.customerSiteShiftId.label);
      }
    }),
    
    templateStepSites1Id: yup.string().when(['customFormParams', 'jobTemplateType'], (customFormParams, jobTemplateType) => {
      if(
        (jobTemplateType === JobStepType.Exchange) ||
        (jobTemplateType === JobStepType.Put) ||
        (jobTemplateType === JobStepType.Task) ||
        (jobTemplateType === JobStepType.Out) ||
        (jobTemplateType === JobStepType.OnTheSpot) ||
        (jobTemplateType === JobStepType.Pull)
      ){
        if(customFormParams.isDefaultLocation1){
          return yup.string().nullable().required().label(formFields.templateStepSites1Id.label);
        } else {
          return yup.string().nullable().label(formFields.templateStepSites1Id.label);
        }
      } else {
        return yup.string().nullable().label(formFields.templateStepSites1Id.label);
      }
    }),
    templateStepSites1Name: yup.string().when(['customFormParams', 'jobTemplateType'], (customFormParams, jobTemplateType) => {
      if(
        (jobTemplateType === JobStepType.Exchange) ||
        (jobTemplateType === JobStepType.Put) ||
        (jobTemplateType === JobStepType.Task) ||
        (jobTemplateType === JobStepType.Out) ||
        (jobTemplateType === JobStepType.OnTheSpot) ||
        (jobTemplateType === JobStepType.Pull)
      ){
        if(!customFormParams.isDefaultLocation1){
          return yup.string().nullable().required().label(formFields.templateStepSites1Name.label);
        } else {
          return yup.string().nullable().label(formFields.templateStepSites1Name.label);
        }
      } else {
        return yup.string().nullable().label(formFields.templateStepSites1Name.label);
      }
    }),
    templateStepSites2Id: yup.string().when(['customFormParams', 'jobTemplateType'], (customFormParams, jobTemplateType) => {
      if(
        (jobTemplateType === JobStepType.Exchange) ||
        (jobTemplateType === JobStepType.OnTheSpot)
      ){
        if(customFormParams.isDefaultLocation2){
          return yup.string().nullable().required().label(formFields.templateStepSites2Id.label);
        } else {
          return yup.string().nullable().label(formFields.templateStepSites2Id.label);
        }
      } else {
        return yup.string().nullable().label(formFields.templateStepSites2Id.label);
      }
    }),
    templateStepSites2Name: yup.string().when(['customFormParams', 'jobTemplateType'], (customFormParams, jobTemplateType) => {
      if(
        (jobTemplateType === JobStepType.Exchange) ||
        (jobTemplateType === JobStepType.OnTheSpot)
      ){
        if(!customFormParams.isDefaultLocation2){
          return yup.string().nullable().required().label(formFields.templateStepSites2Name.label);
        } else {
          return yup.string().nullable().label(formFields.templateStepSites2Name.label);
        }
      } else {
        return yup.string().nullable().label(formFields.templateStepSites2Name.label);
      }
    }),

    jobDate: yup.date().nullable().label(formFields.jobDate.label),
    accountJobWorkingTimeId: yup.number().nullable().label(formFields.accountJobWorkingTimeId.label),
    
    // driverId: yup.string().when(['statusName'], (statusName) => {
    //   if(Utils.isUnassignedOrCancelledOrFailed(statusName)){
    //     return yup.number().nullable().label(formFields.driverId.label);
    //   } else {
    //     return yup.number().nullable().required().label(formFields.driverId.label);
    //   }
    // }),

    driverId: yup.number().nullable().label(formFields.driverId.label),
    vehicleId: yup.number().nullable().label(formFields.vehicleId.label),
    instructionToDrivers: yup.string().nullable().label(formFields.instructionToDrivers.label),
    siteRemarks: yup.string().nullable().label(formFields.siteRemarks.label),
    contactPersonOne: yup.string().label(formFields.contactPersonOne.label),
    contactNumberOne: yup.string().nullable().label(formFields.contactNumberOne.label),
    contactPersonTwo: yup.string().label(formFields.contactPersonTwo.label),
    contactNumberTwo: yup.string().nullable().label(formFields.contactNumberTwo.label),
    statusId: yup.number().nullable().label(formFields.statusId.label),
    
    cancellationRemarks: yup.string().when(['statusName'], (statusName) => {
      if(Utils.isCancelledOrFailed(statusName)){
        return yup.string().label(Utils.isCancelled(statusName) ? formFields.cancellationRemarks.label : formFields.cancellationRemarks.labelFailed);
      } else {
        return yup.string().label(Utils.isCancelled(statusName) ? formFields.cancellationRemarks.label : formFields.cancellationRemarks.labelFailed);
      }
    }),

    amountToCollect: yup.number().nullable().min(0).label(formFields.amountToCollect.label),
    collectedAmount: yup.number().nullable().min(0).label(formFields.collectedAmount.label),
    paymentMethodId: yup.number().nullable().label(formFields.paymentMethodId.label),

    photoAttachmentsUrl: yup.array().of(yup.string()).min(0),
    jobSignedUserName: yup.string().label(formFields.jobSignedUserName.label),
    jobSignedUserContact: yup.string().label(formFields.jobSignedUserContact.label),
    
    isRecurring: yup.bool().oneOf([true, false]),
    repeatOrder: yup.number().nullable().required().label(formFields.repeatOrder.label),
    recurringJobName: yup.string().when(['isRecurring'], (isRecurring) => {
      if(isRecurring){
        return yup.string().required().label(formFields.recurringJobName.label);
      } else {
        return yup.string().label(formFields.recurringJobName.label);
      }
    }),
    recurringJobDescription: yup.string().label(formFields.recurringJobDescription.label),
    // recurringAssignToSameDriverVehicle: yup.bool().oneOf([true, false]),
    // recurringNumberOfTrip: yup.number().min(1).required().label(formFields.recurringNumberOfTrip.label),
    recurringStartDate: yup.string().when(['isRecurring'], (isRecurring) => {
      if(isRecurring){
        return yup.date().nullable().required().label(formFields.recurringStartDate.label);
      } else {
        return yup.date().nullable().label(formFields.recurringStartDate.label);
      }
    }),
    recurringEndDate: yup.string().when(['isRecurring'], (isRecurring) => {
      if(isRecurring){
        return yup.date().nullable().required().label(formFields.recurringEndDate.label);
      } else {
        return yup.date().nullable().label(formFields.recurringEndDate.label);
      }
    }),

    recurringDefaultTime: yup.string().when(['isRecurring', 'repeatOrder'], (isRecurring, repeatOrder) => {
      if(isRecurring && (repeatOrder === RepeatOrder.Daily)){
        return yup.date().nullable().required().label(formFields.recurringDefaultTime.label);
      } else {
        return yup.date().nullable().label(formFields.recurringDefaultTime.label);
      }
    }),
    
    schedules: yup.array().when(['repeatOrder'], (repeatOrder) => {
      if(repeatOrder === RepeatOrder.Monthly){
        return yup.array().of(
          yup.object().shape({
            day: yup.number().nullable().required().label(formFields.schedulesDay.label),
            isChecked: yup.bool().oneOf([true, false]),
            date: yup.number().when(['isChecked'], (isChecked) => {
              return yup.number().nullable().required().min(1).label(formFields.schedulesDay.label);
            }),
          })
        ).unique('You need to remove duplicates', a => a.date)
      } else if(repeatOrder === RepeatOrder.Weekly){
        return yup.array().of(
          yup.object().shape({
            day: yup.number().nullable().required().label(formFields.schedulesDay.label),
            isChecked: yup.bool().oneOf([true, false]),
            time: yup.date().when(['isChecked'], (isChecked) => {
              if(isChecked){
                return yup.date().nullable().required().label(formFields.schedulesTime.label);
              } else {
                return yup.date().nullable().label(formFields.schedulesTime.label);
              }
            }),
          })
        )
      } else {
        return yup.array().of(
          yup.object().shape({
            day: yup.number().nullable().required().label(formFields.schedulesDay.label),
            isChecked: yup.bool().oneOf([true, false]),
            time: yup.number().nullable().label(formFields.schedulesTime.label),
          })
        )
      }
    }),


    isRequireBinNumberToEnd1: yup.bool().oneOf([true, false]),
    isRequireBinNumberToEnd2: yup.bool().oneOf([true, false]),
    mustTakePhoto1: yup.number().nullable(),
    numberofPhotosRequired1: yup.number().nullable(),
    mustTakeSignature: yup.string().nullable(),
    isRequirePaymentCollection: yup.bool().oneOf([true, false]),
    isRequireBinWeight: yup.bool().oneOf([true, false]),
    isRequireReviewWasteType: yup.bool().oneOf([true, false]),
    mustTakePhoto2: yup.number().nullable(),
    numberofPhotosRequired2: yup.number().nullable(),
    isEditableBinTypeOut: yup.bool().oneOf([true, false]),
    isEditableWasteTypeOut: yup.bool().oneOf([true, false]),
    isEditableBinNumberIn: yup.bool().oneOf([true, false]),
    isEditableBinNumberOut: yup.bool().oneOf([true, false]),
    isEditableWasteTypeIn: yup.bool().oneOf([true, false]),
    duration: yup.number().nullable(),

    jobTemplateType: yup.number().nullable(),
    isSameAsBinOut: yup.bool().oneOf([true, false]),

    stepExchangeOutBinTypeId: yup.number().nullable().label(formFields.stepExchangeOutBinTypeId.label),
    stepExchangeOutWasteTypeId: yup.number().nullable().label(formFields.stepExchangeOutWasteTypeId.label),

    stepExchangeOutWasteTypeIds: yup.array().nullable().label(formFields.stepExchangeOutWasteTypeIds.label),
    stepExchangeOutBinNumber: yup.string().nullable().label(formFields.stepExchangeOutBinNumber.label),

    stepExchangeInBinTypeId: yup.number().nullable().label(formFields.stepExchangeInBinTypeId.label),

    stepExchangeInWasteTypeId: yup.number().nullable().label(formFields.stepExchangeInWasteTypeId.label),
    stepExchangeInWasteTypeIds: yup.array().nullable().label(formFields.stepExchangeInWasteTypeIds.label),
    stepExchangeInBinNumber: yup.string().nullable().label(formFields.stepExchangeInBinNumber.label),
    
    stepPutBinTypeId: yup.number().nullable().label(formFields.stepPutBinTypeId.label),
    stepPutWasteTypeId: yup.number().nullable().label(formFields.stepPutWasteTypeId.label),
    stepPutWasteTypeIds: yup.array().nullable().label(formFields.stepPutWasteTypeIds.label),
    stepPutBinNumber: yup.string().nullable().label(formFields.stepPutBinNumber.label),

    stepOutBinTypeId: yup.number().nullable().label(formFields.stepOutBinTypeId.label),
    stepOutWasteTypeId: yup.number().nullable().label(formFields.stepOutWasteTypeId.label),
    stepOutWasteTypeIds: yup.array().nullable().label(formFields.stepOutWasteTypeIds.label),
    stepOutBinNumber: yup.string().nullable().label(formFields.stepOutBinNumber.label),

    stepPullBinTypeId: yup.number().nullable().label(formFields.stepPullBinTypeId.label),
    stepPullWasteTypeId: yup.number().nullable().label(formFields.stepPullWasteTypeId.label),
    stepPullWasteTypeIds: yup.array().nullable().label(formFields.stepPullWasteTypeIds.label),
    stepPullBinNumber: yup.string().nullable().label(formFields.stepPullBinNumber.label),

    stepShiftBinTypeId: yup.number().nullable().label(formFields.stepShiftBinTypeId.label),
    stepShiftWasteTypeId: yup.number().nullable().label(formFields.stepShiftWasteTypeId.label),
    stepShiftWasteTypeIds: yup.array().nullable().label(formFields.stepShiftWasteTypeIds.label),
    stepShiftBinNumber: yup.string().nullable().label(formFields.stepShiftBinNumber.label),
    
    stepOnTheSpotBinTypeId: yup.number().nullable().label(formFields.stepOnTheSpotBinTypeId.label),
    stepOnTheSpotWasteTypeId: yup.number().nullable().label(formFields.stepOnTheSpotWasteTypeId.label),
    stepOnTheSpotWasteTypeIds: yup.array().nullable().label(formFields.stepOnTheSpotWasteTypeIds.label),
    stepOnTheSpotBinNumber: yup.string().nullable().label(formFields.stepOnTheSpotBinNumber.label),
    
    stepBinWeight: yup.string().nullable().matches(Utils.decimalValidationInputText(), formFields.stepBinWeight.info).label(formFields.stepBinWeight.label),
    binOutWeight: yup.string().nullable().matches(Utils.decimalValidationInputText(), formFields.binOutWeight.info).label(formFields.binOutWeight.label),
});


class JobsForm extends Component {

    constructor(props) {
        super(props);

        this.pageName = "recurring-job-form-page";
        this.pageIdField = "recurringJobId";
        this.pageAPI = "recurringjob";
        this.pageCaption = 'Recurring Job Form'
        
        let user = (props.auth && props.auth.user) ? props.auth.user : null;
        this.permissions = (user && user.permissions && user.permissions.length > 0) ? user.permissions : [];

        this.formDrawerCustomer = null;
        this.formDrawerCustomerSite = null;
        this.formDrawerLocation = null;
        this.formDrawerBinHistory = null;
        this.formDrawerAuditTrail = null;

        this.refBody = React.createRef();
        this.refFormik = React.createRef();
        this.refFormDrawerCustomer = React.createRef();
        this.refFormDrawerCustomerSite = React.createRef();
        this.refFormDrawerLocation = React.createRef();
        this.refJobTypesSection = React.createRef();
        this.refLocationsSection = React.createRef();
        this.refFormDrawerBinHistory = React.createRef();
        this.refFormDrawerBinHistoryDrawer = React.createRef();
        this.refFormDrawerAuditTrail = React.createRef();
        this.refOptions = React.createRef();
        this.refRecurring = React.createRef();
        
        this.userInfo = (props.auth && props.auth.user) ? props.auth.user : null;
        this.groupAccountEmail = this.userInfo && this.userInfo.userAccount && this.userInfo.userAccount.accountEmail ? this.userInfo.userAccount.accountEmail : "";
        this.userEmail = this.userInfo && this.userInfo.email && this.userInfo.email !== "" ? this.userInfo.email : "";

        let q = queryString.parse(window.location.search);
        this.id = q.id ? parseInt(q.id) : "";
        this.isCopy = q.isCopy == "true" ? true : false;

        this.currentDateTimeMoment = new Date();
        this.currentDateTime = moment(this.currentDateTimeMoment).format(Utils.getDefaultTimeFormat());

        this.center = { lat: 1.353915, lng: 103.822901 };
        this.zoom = 13;
    
        this.state = {
            data: null,
            err: null,
            isError: false,
            isLoading: false,

            isBlockingFormDrawer: false,

            isRemoveDialog: false,
            removeRow: null,
            isCompletedRemoveDialog: false,

            isOptionsDialog: false,
            itemOptionsDialog: null,
            oldItemOptionsDialog: null,

            rowInitialValues: {
                isEdit: false,
                isCopy: this.isCopy,
                isActive: true,
                promptUpdateBilling: false,

                action: JobAction.Save,

                customerId: null,
                customerName: '',
                customerItem: null,
                remarks: '',
                customerNote: '',
                accountBillingProfileId: null,
                accountBillingProfileName: '',
                paymentTermsId: null,
                paymentTermsName: '',
                xeroPaymentTerm: '',
                xeroPaymentTermType: '0',
                
                isBillable: true,
                billedDate: null,
                invoiceDate: null,
                invoiceNumber: '',
                billingAddress: '',
                billingNote: '',
                pricings: [],

                jobTemplateId: null,
                jobTemplateName: '',
                jobTemplateType: null,

                customerSiteId: null,
                customerSiteName: '',
                customerSiteAddress: '',
                customerSiteItem: null,
                hasCoordCustomerSite: false,
                customerSiteShiftId: null,
                customerSiteShiftName: '',
                customerSiteShiftAddress: '',
                customerSiteShiftItem: null,
                hasCoordCustomerSiteShift: false,
                templateStepSites1Id: null,
                templateStepSites1Name: '',
                templateStepSites1Address: '',
                templateStepSites1Latitude: null,
                templateStepSites1Longitude: null,
                templateStepSites2Id: null,
                templateStepSites2Name: '',
                templateStepSites2Address: '',
                templateStepSites2Latitude: null,
                templateStepSites2Longitude: null,

                jobDate: this.currentDateTimeMoment,
                accountJobWorkingTimeId: -1,
                accountJobWorkingTimeName: this.currentDateTime,
                driverId: null,
                driverName: '',
                vehicleId: null,
                vehicleName: '',
                instructionToDrivers: '',
                siteRemarks: '',
                cancellationRemarks: '',
                contactPersonOne: '',
                contactNumberOne: '',
                contactPersonTwo: '',
                contactNumberTwo: '',
                isContactPersonSendNotification: false,
                isContactPersonTwoSendNotification: false,
                statusId: null,
                statusName: '',
                currentStatusId: null,
                currentStatusName: '',

                amountToCollect: '',
                collectedAmount: '',
                paymentMethodId: null,
                paymentMethodName: '',

                photoAttachmentsUrl: [],
                jobSignatures: [],
                jobSignatureUrl: '',
                jobSignedUserName: '',
                jobSignedUserContact: '',
                proofOfDeliveryRemarks: '',

                isRecurringForm: true,
                isRecurring: true,
                repeatOrder: RepeatOrder.Daily,
                recurringJobName: '',
                recurringJobDescription: '',
                // recurringAssignToSameDriverVehicle: false,
                // recurringNumberOfTrip: 1,
                recurringStartDate: null,
                recurringEndDate: null,
                recurringDefaultTime: null,
                recurringType: ServiceChargeType.WithExpiry,
                schedules: Utils.getSchedules(RepeatOrder.Daily, null),

                isRequireBinNumberToEnd1: false,
                isRequireBinNumberToEnd2: false,
                mustTakePhoto1: '0',
                numberofPhotosRequired1: '0',
                mustTakeSignature: '0',
                isRequirePaymentCollection: false,
                isRequireBinWeight: false,
                isRequireReviewWasteType: false,
                mustTakePhoto2: '0',
                numberofPhotosRequired2: '0',
                isEditableBinTypeOut: false,
                isEditableWasteTypeOut: false,
                isEditableBinNumberIn: false,
                isEditableBinNumberOut: false,
                isEditableWasteTypeIn: false,
                duration: '2',

                stepExchangeOutServiceItemIsGlobal: true,
                stepExchangeInServiceItemIsGlobal: true,
                stepOnTheSpotServiceItemIsGlobal: true,
                stepShiftServiceItemIsGlobal: true,
                stepPutServiceItemIsGlobal: true,
                stepPullServiceItemIsGlobal: true,

                stepExchangeOutServiceItemId: null,
                stepExchangeOutServiceItemName: '',
                stepExchangeOutBinTypeId: null,
                stepExchangeOutBinTypeName: '',
                stepExchangeOutWasteTypeId: null,
                stepExchangeOutWasteTypeItem: null,
                stepExchangeOutWasteTypeName: '',
                stepExchangeOutWasteTypeIds: null,
                stepExchangeOutBinNumber: '',
                stepExchangeOutSelectedIds: [],
                stepExchangeOutIsCopyService: false,

                stepExchangeInServiceItemId: null,
                stepExchangeInServiceItemName: '',
                stepExchangeInBinTypeId: null,
                stepExchangeInBinTypeName: '',
                stepExchangeInWasteTypeId: null,
                stepExchangeInWasteTypeName: '',
                stepExchangeInWasteTypeIds: null,
                stepExchangeInBinNumber: '',
                stepExchangeInSelectedIds: [],
                stepExchangeInIsCopyService: false,
                
                stepPutServiceItemId: null,
                stepPutServiceItemName: '',
                stepPutBinTypeId: null,
                stepPutBinTypeName: '',
                stepPutWasteTypeId: null,
                stepPutWasteTypeName: '',
                stepPutWasteTypeIds: null,
                stepPutBinNumber: '',
                stepPutSelectedIds: [],
                stepPutIsCopyService: false,

                stepOutServiceItemId: null,
                stepOutServiceItemName: '',
                stepOutBinTypeId: null,
                stepOutBinTypeName: '',
                stepOutWasteTypeId: null,
                stepOutWasteTypeName: '',
                stepOutWasteTypeIds: null,
                stepOutBinNumber: '',
                stepOutSelectedIds: [],
                stepOutIsCopyService: false,

                stepPullServiceItemId: null,
                stepPullServiceItemName: '',
                stepPullBinTypeId: null,
                stepPullBinTypeName: '',
                stepPullWasteTypeId: null,
                stepPullWasteTypeName: '',
                stepPullWasteTypeIds: null,
                stepPullBinNumber: '',
                stepPullSelectedIds: [],
                stepPullIsCopyService: false,

                stepShiftServiceItemId: null,
                stepShiftServiceItemName: '',
                stepShiftBinTypeId: null,
                stepShiftBinTypeName: '',
                stepShiftWasteTypeId: null,
                stepShiftWasteTypeName: '',
                stepShiftWasteTypeIds: null,
                stepShiftBinNumber: '',
                stepShiftSelectedIds: [],
                stepShiftIsCopyService: false,

                stepOnTheSpotServiceItemId: null,
                stepOnTheSpotServiceItemName: '',
                stepOnTheSpotBinTypeId: null,
                stepOnTheSpotBinTypeName: '',
                stepOnTheSpotWasteTypeId: null,
                stepOnTheSpotWasteTypeName: '',
                stepOnTheSpotWasteTypeIds: null,
                stepOnTheSpotBinNumber: '',
                stepOnTheSpotSelectedIds: [],
                stepOnTheSpotIsCopyService: false,

                stepBinWeight: '',
                binOutWeight: '',

                serviceId: null,
                serviceName: '',

                detailsPOD: null,
                fromPOD: '',
                toPOD: [],
                subjectPOD: '',
                bodyPODPlainText: '',
                bodyPODHTML: '',
                bodyPOD: EditorState.createEmpty(),

                includeAttachmentPOD: true,
                includeDeliveryOrderPOD: true,
                includePhotosPOD: false,
                includeWeighChitPOD: false,

                weighChitTicketNumber: '',
                weighChitTareWeight: '0',
                weighChitNettWeight: '0',
                nonBillableBinWeight: '0',
                weighChitBillableWeight: '0',
                weighChitRemarks: '',
                weighChitWeighBy: '',
                weighChitDate: new Date(),
                weighChitTime: new Date(),

                customFormParams: {
                  jobDetails: null,
                  jobId: null,
                  jobNumber: null,
                  customerItems: [],
                  customerIsLoading: false,
                  accountBillingProfileItems: [],
                  accountBillingProfileIsLoading: false,
                  isConnectQuickbook: false,
                  isConnectXero: false,
                  quickBookAppId: 0,
                  paymentTermsItems: [],
                  paymentTermsIsLoading: false,
                  chargeCategoryItems: [],
                  chargeCategoryIsLoading: false,
                  jobTemplateIsLoading: false,
                  jobTemplateDetails: null,
                  jobTemplateType: null,
                  customerSiteItems: [],
                  customerSiteIsLoading: false,
                  customerSiteShiftItems: [],
                  customerSiteShiftIsLoading: false,
                  templateStepSites1Items: [],
                  templateStepSites1IsLoading: false,
                  templateStepSites2Items: [],
                  templateStepSites2IsLoading: false,
                  isDefaultLocation1: false,
                  isDefaultLocation2: false,
                  totalDistance: 0,
                  markers: [],
                  accountJobWorkingTimeItems: [{
                    value: -1,
                    title: this.currentDateTime,
                    item: null,
                  }],
                  accountJobWorkingTimeIsLoading: false,
                  jobTimeSpecific: null, //this.currentDateTimeMoment,
                  dispatchLaterTime: null,
                  driverItems: [],
                  driverIsLoading: false,
                  vehicleItems: [],
                  vehicleIsLoading: false,
                  disabledStatusItems: [],
                  statusItems: [],
                  statusIsLoading: false,
                  paymentMethodItems: [],
                  paymentMethodIsLoading: false,
                  photoAttachmentsIsLoading: false,
                  signatureAttachmentsIsLoading: false,
                  isOptions: false,
                  isSendEmail: false,

                  weekdaysAvailability: CustomerSiteAvailability.All,
                  weekendAvailability: CustomerSiteAvailability.All,

                  jobTemplateStep1Id: null,
                  jobTemplateStep2Id: null,

                  jobStepId1: 0,
                  jobStepId2: 0,
                  jobStepId3: 0,

                  binActivity1: null,
                  binActivity2: null,
                  binActivity3: null,

                  jobAttemptCompletedDate: null,

                  messages: [],
                  
                  stepExchangeOutBinWasteTypeItems: [],
                  stepExchangeOutBinWasteTypeIsLoading: false,
                  stepExchangeOutBinNumberItems: [],
                  stepExchangeOutBinNumberIsLoading: false,

                  stepExchangeInBinWasteTypeId: null,
                  stepExchangeInBinWasteTypeItems: [],
                  stepExchangeInBinWasteTypeIsLoading: false,
                  stepExchangeInWasteTypeItems: [],
                  stepExchangeInWasteTypeIsLoading: false,
                  stepExchangeInWasteTypeIsSelectAll: false,
                  stepExchangeInBinNumberItems: [],
                  stepExchangeInBinNumberIsLoading: false,

                  stepPutBinWasteTypeId: null,
                  stepPutBinWasteTypeItems: [],
                  stepPutBinWasteTypeIsLoading: false,
                  stepPutWasteTypeItems: [],
                  stepPutWasteTypeIsLoading: false,
                  stepPutWasteTypeIsSelectAll: false,
                  stepPutBinNumberItems: [],
                  stepPutBinNumberIsLoading: false,

                  stepOutBinWasteTypeId: null,
                  stepOutBinWasteTypeItems: [],
                  stepOutBinWasteTypeIsLoading: false,
                  stepOutWasteTypeItems: [],
                  stepOutWasteTypeIsLoading: false,
                  stepOutWasteTypeIsSelectAll: false,
                  stepOutBinNumberItems: [],
                  stepOutBinNumberIsLoading: false,

                  stepPullBinWasteTypeId: null,
                  stepPullBinWasteTypeItems: [],
                  stepPullBinWasteTypeIsLoading: false,
                  stepPullWasteTypeItems: [],
                  stepPullWasteTypeIsLoading: false,
                  stepPullWasteTypeIsSelectAll: false,
                  stepPullBinNumberItems: [],
                  stepPullBinNumberIsLoading: false,

                  stepShiftBinWasteTypeId: null,
                  stepShiftBinWasteTypeItems: [],
                  stepShiftBinWasteTypeIsLoading: false,
                  stepShiftWasteTypeItems: [],
                  stepShiftWasteTypeIsLoading: false,
                  stepShiftWasteTypeIsSelectAll: false,
                  stepShiftBinNumberItems: [],
                  stepShiftBinNumberIsLoading: false,

                  stepOnTheSpotBinWasteTypeId: null,
                  stepOnTheSpotBinWasteTypeItems: [],
                  stepOnTheSpotBinWasteTypeIsLoading: false,
                  stepOnTheSpotWasteTypeItems: [],
                  stepOnTheSpotWasteTypeIsLoading: false,
                  stepOnTheSpotWasteTypeIsSelectAll: false,
                  stepOnTheSpotBinNumberItems: [],
                  stepOnTheSpotBinNumberIsLoading: false,

                  isSameAsBinOut: false,
                  isServiceOrHistory: false,
                }
            },
            rowValues: null,

            isServiceOrHistory: false,
            

            isLoadingFormDrawerBinHistory: false,
            isVisibleFormDrawerBinHistory: false,
            isBlockingFormDrawerBinHistory: false,
            rowInitialValuesBinHistory: {
                isEdit: false,
            },
            rowValuesBinHistory: null,
            

            centerMap: this.center,
            zoomMap: this.zoom,

            allServicesItems: [],
        }
    }


    componentDidMount() {
      this.initDrawers();

      this.callDetailsApi(this.id, async (data) => {
        let rowValues = this.prepareForm(data);

        let serviceNames = await Utils.getJobServiceTagName(rowValues);
        rowValues = {...rowValues, ...serviceNames};

        this.setState({
          rowValues: rowValues,
        }, () => {
          if(this.refLocationsSection && this.refLocationsSection.current){
            this.refLocationsSection.current.calcDistance();
          }
        });
      });
    }
  
    componentWillReceiveProps(nextProps) {
      this.reduxProps(nextProps);
	  }
  

	/* FUNCTIONS */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            this.pageName + '-details', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isBlockingFormDrawer: false,
                    details: data,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    if(callback){
                        callback(data);
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            this.pageName + '-create', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isBlockingFormDrawer: false,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    if(!this.state.isError){
                      let error = (err && err.error) ? err.error : err;
                      Utils.toast(error, 'error');
                    }

                    if(callback){
                        callback(data, isError);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-copy', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isBlockingFormDrawer: false,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                  if(!this.state.isError){
                    let error = (err && err.error) ? err.error : err;
                    Utils.toast(error, 'error');
                  }

                    if(callback){
                        callback(data, isError);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-update', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isBlockingFormDrawer: false,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                  if(!this.state.isError){
                    let error = (err && err.error) ? err.error : err;
                    Utils.toast(error, 'error');
                  }

                    if(callback){
                        callback(data, isError);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-delete', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isBlockingFormDrawer: false,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    if(callback){
                        callback(data);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-create_customer', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isBlockingFormDrawer: false,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    if(!this.state.isError){
                        Utils.toast('Customer is successfully created!', 'success');
                    }
                    
                    if(callback){
                        callback(data);
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            this.pageName + '-create_customersite', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isBlockingFormDrawer: false,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    if(!this.state.isError){
                        Utils.toast('Customer site is successfully created!', 'success');
                    }
                    
                    if(callback){
                        callback(data);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
          this.pageName + '-service_charge', 
          (data, isLoading, isError, err, statusCode, variables, callback) => {
            // let wasteTypeIds = (variables && variables.wasteTypeIds && variables.wasteTypeIds !== '') ? variables.wasteTypeIds.split(',') : [];
            let isServiceOrHistory = (variables && variables.isServiceOrHistory) ? variables.isServiceOrHistory : false;
            let selectedItems = (variables && variables.selectedItems) ? variables.selectedItems : [];
            
            let serviceItemIdIndex = selectedItems.findIndex(y => y.item.serviceItemId);
            let serviceItemId = null;
            if(serviceItemIdIndex > -1){
              serviceItemId = selectedItems[serviceItemIdIndex].item.serviceItemId;
            }

            let standardItems = [];
            if(data && data.data && data.data.length > 0) {
              if(selectedItems && selectedItems.length > 0){
                if(serviceItemId){
                  standardItems = data.data.filter(x => x.serviceItemId == serviceItemId)
                } else {
                  standardItems = data.data.filter(x => selectedItems.map(y => y.serviceTag).includes(x.serviceTag))
                }

                // if(isServiceOrHistory){
                //   standardItems = data.data.filter(x => selectedItems.some(y => (y.binTypeId == x.binTypeId) && (y.wasteTypeId == x.wasteTypeId)))
                // } else {
                //   standardItems = data.data.filter(x => selectedItems.map(y => y.serviceTag).includes(x.serviceTag))
                // }
              }
            }
            
            let arr = [];
            if(standardItems && standardItems.length > 0) {
              for (var i = 0; i < standardItems.length; i++) {
                if(isServiceOrHistory){
                  if(i > 0){
                    break;
                  }
                }
                
                let item = standardItems[i];
                
                let serviceItemId = item.serviceItemId;
                let binTypeId = item.binTypeId;
                let binTypeName = (item.binTypeName) ? item.binTypeName : '';
                let wasteTypeId = item.wasteTypeId;
                let wasteTypeName = (item.wasteTypeName) ? item.wasteTypeName : '';
                let serviceTag = (item.serviceTag) ? item.serviceTag : '';
                let binOutDate = (item.binOutDate) ? item.binOutDate : '';
                let binNumber = (item.binNumber) ? item.binNumber : '';
                let driverName = (item.driverName) ? item.driverName : '';
                let vehicleName = (item.vehicleName) ? item.vehicleName : '';
                let jobNumber = (item.jobNumber) ? item.jobNumber : '';
                let isBundled = ((item.isBundled === true) || (item.isBundled === false)) ? item.isBundled : false;
                let additionalCharges = (item.bundleServices && item.bundleServices.length > 0) ? item.bundleServices : [];
                let value = Utils.makeIDfromString(((item.serviceItemId) ? item.serviceItemId : '') + item.binTypeId + "_" + binTypeName + "_" + item.wasteTypeId + "_" + wasteTypeName + "_" + serviceTag);
                
                let arrItem = {
                  value: value,
                  item: item,
                  serviceItemId: serviceItemId,
                  binTypeId: binTypeId,
                  binTypeName: binTypeName,
                  wasteTypeId: wasteTypeId,
                  wasteTypeName: wasteTypeName,
                  serviceTag: serviceTag,
                  binOutDate: binOutDate,
                  binNumber: binNumber,
                  driverName: driverName,
                  vehicleName: vehicleName,
                  jobNumber: jobNumber,
                  isBundled: isBundled,
                  additionalCharges: additionalCharges,
                };
                
                arr = Utils.addToArray(arr, value, arrItem);
              }
            }
            
            this.setState({
              isLoading: isLoading,
              isError: isError,
              err: err,
            }, () => {
              if(callback){
                callback(arr);
              }
            });
          }
        );

        Utils.reduxProps(nextProps,
          this.pageName + '-customer_details', 
          (data, isLoading, isError, err, statusCode, variables, callback) => {
              this.setState({
                isLoadingParams: false,

                  isLoading: isLoading,
                  isError: isError,
                  err: err,
              }, () => {
                  if(callback){
                      callback(data);
                  }
              });
          }
        );

        Utils.reduxProps(nextProps,
          this.pageName + '-job_template_details', 
          (data, isLoading, isError, err, statusCode, variables, callback) => {
              this.setState({
                isLoadingParams: false,

                  isLoading: isLoading,
                  isError: isError,
                  err: err,
              }, () => {
                  if(callback){
                      callback(data);
                  }
              });
          }
        );

        Utils.reduxProps(nextProps,
          this.pageName + '-customer_site_details', 
          (data, isLoading, isError, err, statusCode, variables, callback) => {
              this.setState({
                isLoadingParams: false,

                  isLoading: isLoading,
                  isError: isError,
                  err: err,
              }, () => {
                  if(callback){
                      callback(data);
                  }
              });
          }
        );

        Utils.reduxProps(nextProps,
          this.pageName + '-service_customer_site_details', 
          (data, isLoading, isError, err, statusCode, variables, callback) => {
            let arr = [];

            if(data && data.data && data.data.length > 0) {
              arr = data.data;
            }

              this.setState({
                isLoadingParams: false,

                  isLoading: isLoading,
                  isError: isError,
                  err: err,
              }, () => {
                  if(callback){
                      callback(arr);
                  }
              });
          }
        );
        
      Utils.reduxProps(nextProps,
        this.pageName + '-account_setting_type_lat', 
        (data, isLoading, isError, err, statusCode, variables, callback) => {
            this.setState({
              isLoadingFormDrawerCustomerSite: false,
                
                isLoading: isLoading,
                isError: isError,
                err: err,
            }, () => {
                if(callback){
                    callback(data);
                }
            });
        }
      );

      Utils.reduxProps(nextProps,
        this.pageName + '-account_setting_type_lng', 
        (data, isLoading, isError, err, statusCode, variables, callback) => {
            this.setState({
              isLoadingFormDrawerCustomerSite: false,

                isLoading: isLoading,
                isError: isError,
                err: err,
            }, () => {
                if(callback){
                    callback(data);
                }
            });
        }
      );

      Utils.reduxProps(nextProps,
        this.pageName + '-account_setting_type_zoom', 
        (data, isLoading, isError, err, statusCode, variables, callback) => {
            this.setState({
              isLoadingFormDrawerCustomerSite: false,

                isLoading: isLoading,
                isError: isError,
                err: err,
            }, () => {
                if(callback){
                    callback(data);
                }
            });
        }
      );
    }

  prepareForm = (data) => {
    if(data){
      let customerItem = (data.customer) ? data.customer : null;
      let customerId = (customerItem && customerItem.customerId) ? customerItem.customerId : null;
      let customerName = (customerItem && customerItem.customerNameDisplay) ? customerItem.customerNameDisplay : '';
      let customerItems = (customerItem) ? [{
        value: customerId,
        title: customerName,
        item: customerItem,
      }] : [];
      

      let billingProfileItem = (customerItem && customerItem.billingProfile) ? customerItem.billingProfile : null;
      let accountBillingProfileId = (billingProfileItem && billingProfileItem.accountBillingProfileId) ? billingProfileItem.accountBillingProfileId : null;
      let accountBillingProfileName = (billingProfileItem && billingProfileItem.billingProfileName) ? billingProfileItem.billingProfileName : '';
      let accountBillingProfileItems = (billingProfileItem) ? [{
        value: accountBillingProfileId,
        title: accountBillingProfileName,
        item: billingProfileItem,
      }] : [];


      let isConnectQuickbook = (billingProfileItem && billingProfileItem.isConnectQuickbook) ? billingProfileItem.isConnectQuickbook : false;
      let isConnectXero = (billingProfileItem && billingProfileItem.isConnectXero) ? billingProfileItem.isConnectXero : false;
      let quickBookApp = (billingProfileItem && billingProfileItem.quickBookApp) ? billingProfileItem.quickBookApp : null;
      let quickBookAppId = (quickBookApp && quickBookApp.quickBookAppId) ? quickBookApp.quickBookAppId : 0;
      let xeroPaymentTerm = (customerItem && customerItem.xeroPaymentTerm) ? customerItem.xeroPaymentTerm : '';
      let xeroPaymentTermType = (customerItem && customerItem.xeroPaymentTermType) ? customerItem.xeroPaymentTermType.toString() : '0';
      let billingAddress = (customerItem && customerItem.billingAddress) ? customerItem.billingAddress : '';
      
      let paymentTermsId = (customerItem && customerItem.quickBookDefaultTermId) ? customerItem.quickBookDefaultTermId : null;
      let paymentTermsName = (customerItem && customerItem.paymentTerms) ? customerItem.paymentTerms : '';
      let paymentTermsItems = (isConnectQuickbook) ? [{
        value: paymentTermsId,
        title: paymentTermsName,
        item: customerItem,
      }] : [];


      let paymentMethodItems = [];
      if (data && data.jobTemplate && data.jobTemplate && data.jobTemplate.jobPaymentTypeList.length) {
          paymentMethodItems = data.jobTemplate.jobPaymentTypeList.map((item, i) => {
              return {
                  value: i,
                  title: item,
              };
          });
      }


      let assignedDriver = (data.assignedDriver) ? data.assignedDriver : null;
      let driverId = (assignedDriver && assignedDriver.driverId) ? assignedDriver.driverId : null;
      let driverName = (assignedDriver && assignedDriver.driverName) ? assignedDriver.driverName : '';
      let driverItems = (assignedDriver) ? [{
        value: driverId,
        title: driverName,
        item: assignedDriver,
      }] : [];


      let assignedVehicle = (data.assignedVehicle) ? data.assignedVehicle : null;
      let vehicleId = (assignedVehicle && assignedVehicle.vehicleId) ? assignedVehicle.vehicleId : null;
      let vehicleName = (assignedVehicle && assignedVehicle.vehicleName) ? assignedVehicle.vehicleName : '';
      let vehicleItems = (assignedVehicle) ? [{
        value: vehicleId,
        title: vehicleName,
        item: assignedVehicle,
      }] : [];


      let jobTemplate = (data.jobTemplate) ? data.jobTemplate : null;
      let jobTemplateType = (data.steps && data.steps.length > 0) ? data.steps[0].stepType : null;


      let jobDurationHours = data.jobDurationHours && data.jobDurationHours.toString() !== "" ? data.jobDurationHours.toString() : "0";
      let jobDurationMinutes = data.jobDurationMinutes && data.jobDurationMinutes.toString() !== "" ? data.jobDurationMinutes.toString() : "0";
      let duration = Utils.getDuration(jobDurationHours, jobDurationMinutes);

      
      let jobStepId1 = 0;
      let jobStepId2 = 0;
      let jobStepId3 = 0;

      let binActivity1 = null;
      let binActivity2 = null;
      let binActivity3 = null;

      let customerSiteId = '';
      let customerSiteName = null;
      let customerSiteAddress = '';
      let customerSiteItem = null;
      let hasCoordCustomerSite = false;
      let customerSiteShiftId = null;
      let customerSiteShiftName = '';
      let customerSiteShiftAddress = '';
      let customerSiteShiftItem = null;
      let hasCoordCustomerSiteShift = false;
      let weekdaysAvailability = CustomerSiteAvailability.All;
      let weekendAvailability = CustomerSiteAvailability.All;
      
      let jobTemplateStep1Id = null;
      let templateStepSites1Id = null;
      let templateStepSites1Name = '';
      let templateStepSites1Address = '';
      let templateStepSites1Latitude = null;
      let templateStepSites1Longitude = null;

      let jobTemplateStep2Id = null;
      let templateStepSites2Id = null;
      let templateStepSites2Name = '';
      let templateStepSites2Address = '';
      let templateStepSites2Latitude = null;
      let templateStepSites2Longitude = null;

      let customerSiteItems = [];
      let customerSiteShiftItems = [];
      let templateStepSites1Items = [];
      let templateStepSites2Items = [];
      let isDefaultLocation1 = false;
      let isDefaultLocation2 = false;
      let markers = [];

      let siteRemarks = '';
      let contactPersonOne = '';
      let contactNumberOne = '';
      let contactPersonTwo = '';
      let contactNumberTwo = '';
      let isContactPersonSendNotification = false;
      let isContactPersonTwoSendNotification = false;
      let amountToCollect = '';
      let collectedAmount = '';
      let paymentMethodId = null;
      let paymentMethodName = '';

      let isRequireBinNumberToEnd1 = false;
      let isRequireBinNumberToEnd2 = false;
      let mustTakePhoto1 = '0';
      let numberofPhotosRequired1 = '0';
      let mustTakeSignature = '0';
      let isRequirePaymentCollection = false;
      let isRequireBinWeight = false;
      let isRequireReviewWasteType = false;
      let mustTakePhoto2 = '0';
      let numberofPhotosRequired2 = '0';


      if(jobTemplate && jobTemplate.templateSteps && jobTemplate.templateSteps.length > 0){
        let templateSteps = jobTemplate.templateSteps.filter(x => x.hasCustomerSite === false).sort((a, b) => a.stepOrder - b.stepOrder);
        
        if(templateSteps && templateSteps.length > 0){
          templateSteps.forEach((item, i) => {
            if(i === 0){
              isDefaultLocation1 = item.hasDefaultSite;
              jobTemplateStep1Id = item.jobTemplateStepId;
            } else {
              isDefaultLocation2 = item.hasDefaultSite;
              jobTemplateStep2Id = item.jobTemplateStepId;
            }
          });
        }
      }


      let stepExchangeOutServiceItemId = null;
      let stepExchangeOutBinTypeId = null;
      let stepExchangeOutBinTypeName = '';
      let stepExchangeOutBinTypeItem = null;
      let stepExchangeOutWasteTypeItem = null;
      let stepExchangeOutWasteTypeId = null;
      let stepExchangeOutWasteTypeName = '';
      let stepExchangeOutWasteTypeIds = [];
      let stepExchangeOutBinNumber = '';
      let stepExchangeOutSelectedIds = [];

      let stepExchangeInServiceItemId = null;
      let stepExchangeInBinTypeId = null;
      let stepExchangeInBinTypeName = '';
      let stepExchangeInWasteTypeId = null;
      let stepExchangeInWasteTypeName = '';
      let stepExchangeInWasteTypeIds = [];
      let stepExchangeInBinNumber = '';
      let stepExchangeInSelectedIds = [];

      let stepPutServiceItemId = null;
      let stepPutBinTypeId = null;
      let stepPutBinTypeName = '';
      let stepPutWasteTypeId = null;
      let stepPutWasteTypeName = '';
      let stepPutWasteTypeIds = [];
      let stepPutBinNumber = '';
      let stepPutSelectedIds = [];

      let stepOutServiceItemId = null;
      let stepOutBinTypeId = null;
      let stepOutBinTypeName = '';
      let stepOutWasteTypeId = null;
      let stepOutWasteTypeName = '';
      let stepOutWasteTypeIds = [];
      let stepOutBinNumber = '';
      let stepOutSelectedIds = [];

      let stepPullServiceItemId = null;
      let stepPullBinTypeId = null;
      let stepPullBinTypeName = '';
      let stepPullWasteTypeId = null;
      let stepPullWasteTypeName = '';
      let stepPullWasteTypeIds = [];
      let stepPullBinNumber = '';
      let stepPullSelectedIds = [];

      let stepShiftServiceItemId = null;
      let stepShiftBinTypeId = null;
      let stepShiftBinTypeName = '';
      let stepShiftWasteTypeId = null;
      let stepShiftWasteTypeName = '';
      let stepShiftWasteTypeIds = [];
      let stepShiftBinNumber = '';
      let stepShiftSelectedIds = [];

      let stepOnTheSpotServiceItemId = null;
      let stepOnTheSpotBinTypeId = null;
      let stepOnTheSpotBinTypeName = '';
      let stepOnTheSpotWasteTypeId = null;
      let stepOnTheSpotWasteTypeName = '';
      let stepOnTheSpotWasteTypeIds = [];
      let stepOnTheSpotBinNumber = '';
      let stepOnTheSpotSelectedIds = [];

      let stepBinWeight = '';
      let binOutWeight = '';
      
      let weighChitTicketNumber = '';
      let weighChitTareWeight = '0';
      let weighChitNettWeight = '0';
      let nonBillableBinWeight = '0';
      let weighChitBillableWeight = '0';
      let weighChitRemarks = '';
      let weighChitWeighBy = '';
      let weighChitDate = null;
      let weighChitTime = null;


      let stepExchangeOutBinWasteTypeItems = [];

      let stepExchangeInBinWasteTypeId = null;
      let stepExchangeInBinWasteTypeItems = [];
      let stepExchangeInWasteTypeItems = [];

      let stepPutBinWasteTypeId = null;
      let stepPutBinWasteTypeItems = [];
      let stepPutWasteTypeItems = [];

      let stepOutBinWasteTypeId = null;
      let stepOutBinWasteTypeItems = [];
      let stepOutWasteTypeItems = [];

      let stepPullBinWasteTypeId = null;
      let stepPullBinWasteTypeItems = [];
      let stepPullWasteTypeItems = [];

      let stepShiftBinWasteTypeId = null;
      let stepShiftBinWasteTypeItems = [];
      let stepShiftWasteTypeItems = [];

      let stepOnTheSpotBinWasteTypeId = null;
      let stepOnTheSpotBinWasteTypeItems = [];
      let stepOnTheSpotWasteTypeItems = [];


      if(data.steps && data.steps.length > 0){
        let steps = data.steps.sort((a, b) => a.stepOrder - b.stepOrder);


        let customerSteps = steps.filter(x => x.customerSiteId > 0);
        if(customerSteps && customerSteps.length > 0){
          customerSteps.forEach((item, i) => {
            if(i === 0){
              customerSiteItem = (item && item.site) ? item.site : null;
              customerSiteId = (customerSiteItem && customerSiteItem.customerSiteId) ? customerSiteItem.customerSiteId : null;
              // customerSiteName = (customerSiteItem && customerSiteItem.siteNameDisplay) ? customerSiteItem.siteNameDisplay : '';
              customerSiteName = Utils.formatCustomerSiteAddress(customerSiteItem);
              customerSiteAddress = Utils.formatCustomerSiteAddress(customerSiteItem);

              let latitude = (customerSiteItem && customerSiteItem.latitude && customerSiteItem.latitude != '') ? customerSiteItem.latitude : '';
              let longitude = (customerSiteItem && customerSiteItem.longitude && customerSiteItem.longitude != '') ? customerSiteItem.longitude : '';
              hasCoordCustomerSite = ((latitude && latitude != '') && (longitude && longitude != '')) ? true : false;

              customerSiteItems = (customerSiteItem) ? [{
                value: customerSiteId,
                title: customerSiteName,
                item: customerSiteItem,
              }] : [];

              siteRemarks = item.siteRemarks;
              contactPersonOne = item.contactPersonOne;
              contactNumberOne = item.contactNumberOne;
              contactPersonTwo = item.contactPersonTwo;
              contactNumberTwo = item.contactNumberTwo;
              isContactPersonSendNotification = item.isContactPersonSendNotification;
              isContactPersonTwoSendNotification = item.isContactPersonTwoSendNotification;
              amountToCollect = (item.defaultAmountToCollect) ? item.defaultAmountToCollect : 0;
              collectedAmount = !this.isCopy ? item.collectedAmount : '';
              paymentMethodId = item.jobPaymentType;
              paymentMethodName = (paymentMethodItems && paymentMethodItems.length > 0) ? (paymentMethodItems[item.jobPaymentType] && paymentMethodItems[item.jobPaymentType].title) ? paymentMethodItems[item.jobPaymentType].title : '' : '';

              weekdaysAvailability = (customerSiteItem && customerSiteItem.weekdaysAvailability) ? customerSiteItem.weekdaysAvailability : CustomerSiteAvailability.All;
              weekendAvailability = (customerSiteItem && customerSiteItem.weekendAvailability) ? customerSiteItem.weekendAvailability : CustomerSiteAvailability.All;
            } else {
              if(jobTemplateType === JobStepType.Shift){
                customerSiteShiftItem = (item && item.site) ? item.site : null;
                customerSiteShiftId = (customerSiteShiftItem && customerSiteShiftItem.customerSiteId) ? customerSiteShiftItem.customerSiteId : null;
                customerSiteShiftName = (customerSiteShiftItem && customerSiteShiftItem.siteNameDisplay) ? customerSiteShiftItem.siteNameDisplay : '';
                customerSiteShiftAddress = Utils.formatCustomerSiteAddress(customerSiteShiftItem);
                
                let latitude = (customerSiteShiftItem && customerSiteShiftItem.latitude && customerSiteShiftItem.latitude != '') ? customerSiteShiftItem.latitude : '';
                let longitude = (customerSiteShiftItem && customerSiteShiftItem.longitude && customerSiteShiftItem.longitude != '') ? customerSiteShiftItem.longitude : '';
                hasCoordCustomerSiteShift = ((latitude && latitude != '') && (longitude && longitude != '')) ? true : false;

                customerSiteShiftItems = (customerSiteShiftItem) ? [{
                  value: customerSiteShiftId,
                  title: customerSiteShiftName,
                  item: customerSiteShiftItem,
                }] : [];
              }
            }
          });
        }


        let addressSteps = steps.filter(x => x.customerSiteId === null || x.customerSiteId === 0);
        if(addressSteps && addressSteps.length > 0){
          addressSteps.forEach((item, i) => {
            if(i === 0){
              templateStepSites1Id = Utils.makeIDfromString(item.siteName);
              templateStepSites1Items = (jobTemplateStep1Id) ? [{
                value: templateStepSites1Id,
                title: item.siteName,
                item: {
                  ...item, 
                  stepSiteAddress: item.address,
                  stepSiteLatitude: item.latitude,
                  stepSiteLongitude: item.longitude,
                  stepSiteName: item.siteName,
                },
              }] : [];
            
              templateStepSites1Name = item.address;
              templateStepSites1Address = item.siteName;
              templateStepSites1Latitude = item.latitude;
              templateStepSites1Longitude = item.longitude;
            } else {
              templateStepSites2Id = Utils.makeIDfromString(item.siteName);
              templateStepSites2Items = (jobTemplateStep2Id) ? [{
                value: templateStepSites2Id,
                title: item.siteName,
                item: {
                  ...item, 
                  stepSiteAddress: item.address,
                  stepSiteLatitude: item.latitude,
                  stepSiteLongitude: item.longitude,
                  stepSiteName: item.siteName,
                },
              }] : [];
            
              templateStepSites2Name = item.address;
              templateStepSites2Address = item.siteName;
              templateStepSites2Latitude = item.latitude;
              templateStepSites2Longitude = item.longitude;
            }
          });
        }

        
        steps.forEach((item, i) => {
          if(i === 0){
            jobStepId1 = item.jobStepId;
            binActivity1 = item.binActivity;
            
            if(jobTemplateType === JobStepType.Shift) {
              isRequireBinNumberToEnd2 = item.isRequireBinNumberToEnd;
              mustTakePhoto1 = item.mustTakePhoto;
              numberofPhotosRequired1 = item.numberofPhotosRequired;
              mustTakeSignature = item.mustTakeSignature;
              isRequirePaymentCollection = item.isRequirePaymentCollection;
            } else if (jobTemplateType === JobStepType.Out) {
              isRequireBinNumberToEnd1 = item.isRequireBinNumberToEnd;
              isRequireBinWeight = item.isRequireBinWeight;
              isRequireReviewWasteType = item.isRequireReviewWasteType;

            } else if(jobTemplateType === JobStepType.Pull) {
              isRequireBinNumberToEnd2 = item.isRequireBinNumberToEnd;
              mustTakePhoto1 = item.mustTakePhoto;
              numberofPhotosRequired1 = item.numberofPhotosRequired;
              mustTakeSignature = item.mustTakeSignature;
              isRequirePaymentCollection = item.isRequirePaymentCollection;
            } else {
              if((jobTemplateType !== JobStepType.Pull) && (jobTemplateType !== JobStepType.Shift)) {
                isRequireBinNumberToEnd1 = item.isRequireBinNumberToEnd;
              }
            }
          } else if(i === 1){
            jobStepId2 = item.jobStepId;
            binActivity2 = item.binActivity;

            if(jobTemplateType === JobStepType.Shift) {
              mustTakePhoto2 = item.mustTakePhoto;
              numberofPhotosRequired2 = item.numberofPhotosRequired;
            } else if(jobTemplateType === JobStepType.Put) {
              mustTakePhoto2 = item.mustTakePhoto;
              numberofPhotosRequired2 = item.numberofPhotosRequired;
              mustTakeSignature = item.mustTakeSignature;
              isRequirePaymentCollection = item.isRequirePaymentCollection;
            } else if(jobTemplateType === JobStepType.Task) {
              mustTakePhoto2 = item.mustTakePhoto;
              numberofPhotosRequired2 = item.numberofPhotosRequired;
              mustTakeSignature = item.mustTakeSignature;
              isRequirePaymentCollection = item.isRequirePaymentCollection;
            } else if(jobTemplateType === JobStepType.Out) {
              mustTakePhoto2 = item.mustTakePhoto;
              numberofPhotosRequired2 = item.numberofPhotosRequired;
              // isRequireBinWeight = item.isRequireBinWeight;
              // mustTakeSignature = item.mustTakeSignature;
              // isRequirePaymentCollection = item.isRequirePaymentCollection;
            } else if(jobTemplateType === JobStepType.Pull) {
              isRequireBinWeight = item.isRequireBinWeight;
              isRequireReviewWasteType = item.isRequireReviewWasteType;
              mustTakePhoto2 = item.mustTakePhoto;
              numberofPhotosRequired2 = item.numberofPhotosRequired;
            } else {
              if((jobTemplateType !== JobStepType.Put) && (jobTemplateType !== JobStepType.Task) && (jobTemplateType !== JobStepType.Out)) {
                if((jobTemplateType === JobStepType.Pull) || (jobTemplateType === JobStepType.Exchange) || (jobTemplateType === JobStepType.Shift)) {
                  isRequireBinNumberToEnd2 = item.isRequireBinNumberToEnd;
                }

                mustTakePhoto1 = item.mustTakePhoto;
                numberofPhotosRequired1 = item.numberofPhotosRequired;
                mustTakeSignature = item.mustTakeSignature;
                isRequirePaymentCollection = item.isRequirePaymentCollection;
              }
            }

          } else if(i === 2){
            jobStepId3 = item.jobStepId;
            binActivity3 = item.binActivity;

            if((jobTemplateType !== JobStepType.Put) && (jobTemplateType !== JobStepType.Task) && (jobTemplateType !== JobStepType.Out) && (jobTemplateType !== JobStepType.Shift)) {
              isRequireBinWeight = item.isRequireBinWeight;
              isRequireReviewWasteType = item.isRequireReviewWasteType;
            }
            
            mustTakePhoto2 = item.mustTakePhoto;
            numberofPhotosRequired2 = item.numberofPhotosRequired;
          }
        });


        let step1 = steps.filter(x => x.stepOrder === 1);
        if(step1 && step1.length > 0){
          let step = step1[0];
          
          if(jobTemplateType === JobStepType.Exchange){
            let value = step.binTypeId;
            let binTypeId = step.binTypeId;
            let binTypeName = (step.binType && step.binType.binTypeName) ? step.binType.binTypeName : '';
            let wasteTypeId = step.wasteTypeId;
            let wasteTypeName = (step.wasteType && step.wasteType.wasteTypeName) ? step.wasteType.wasteTypeName : '';
            let serviceItemId = step.serviceItemId;
            let binNumber = !this.isCopy ? (step.binNumber) ? step.binNumber : '' : '';
            let arrItem = {
              value: value,
              item: step,
              binTypeId: binTypeId,
              binTypeName: binTypeName,
              wasteTypeId: wasteTypeId,
              wasteTypeName: wasteTypeName,
            };

            stepExchangeOutServiceItemId = serviceItemId;
            stepExchangeOutBinTypeId = binTypeId;
            stepExchangeOutBinTypeName = binTypeName;
            stepExchangeOutBinTypeItem = arrItem;
            stepExchangeOutWasteTypeItem = arrItem;
            stepExchangeOutWasteTypeId = wasteTypeId;
            stepExchangeOutWasteTypeName = wasteTypeName;
            stepExchangeOutBinNumber = binNumber;

            stepExchangeOutBinWasteTypeItems = (arrItem) ? [arrItem] : [];
            
            let serviceTag = Utils.getServiceTagName(wasteTypeName, binTypeName);
            let customId = Utils.makeIDfromString(((serviceItemId) ? serviceItemId : '') + binTypeId + "_" + binTypeName + "_" + wasteTypeId + "_" + wasteTypeName + "_" + serviceTag);
            stepExchangeOutSelectedIds = [customId];

          } else if(jobTemplateType === JobStepType.OnTheSpot){
            let binTypeId = step.binTypeId;
            let binTypeName = (step.binType && step.binType.binTypeName) ? step.binType.binTypeName : '';
            let wasteTypeId = step.wasteTypeId;
            let wasteTypeName = (step.wasteType && step.wasteType.wasteTypeName) ? step.wasteType.wasteTypeName : '';
            let serviceItemId = step.serviceItemId;
            let binNumber = !this.isCopy ? (step.binNumber) ? step.binNumber : '' : '';
            let binWeight = !this.isCopy ? (step.binWeight) ? step.binWeight : '' : '';
            let wasteTypes = (step.wasteTypes && step.wasteTypes.length > 0) ? step.wasteTypes : [];

            let customIds = [];
            let stepOnTheSpotBinWasteTypeArr = [];
            if(wasteTypes && wasteTypes.length > 0){
              stepOnTheSpotBinWasteTypeArr = wasteTypes.map((item, i) => {
                if(stepOnTheSpotWasteTypeIds.findIndex(x => x === item.wasteTypeId) === -1){
                  stepOnTheSpotWasteTypeIds.push(item.wasteTypeId);
                }

                let wtName = (item.wasteType) ? item.wasteType.wasteTypeName : '';

                let serviceTag = Utils.getServiceTagName(wtName, binTypeName);
                let customId = Utils.makeIDfromString(((item.serviceItemId) ? item.serviceItemId : '') + binTypeId + "_" + binTypeName + "_" + item.wasteTypeId + "_" + wtName + "_" + serviceTag);
                customIds = Utils.addToArr(customIds, customId);

                return {
                  value: item.wasteTypeId,
                  title: wtName,
                  item: {
                      binTypeId: binTypeId,
                      binTypeName: binTypeName,
                      wasteTypeId: item.wasteTypeId,
                      wasteTypeName: wtName,
                      serviceTag: '',
                      binOutDate: null,
                      binNumber: binNumber,
                      driverName: '',
                      vehicleName: '',
                  },
                  binTypeId: binTypeId,
                  binTypeName: binTypeName,
                  wasteTypeId: item.wasteTypeId,
                  wasteTypeName: wtName,
                  serviceTag: '',
                  binOutDate: null,
                  binNumber: binNumber,
                  driverName: '',
                  vehicleName: '',
                }
              });
            }

            stepOnTheSpotServiceItemId = serviceItemId;
            stepOnTheSpotBinTypeId = binTypeId;
            stepOnTheSpotBinTypeName = binTypeName;
            stepOnTheSpotWasteTypeId = wasteTypeId;
            stepOnTheSpotWasteTypeName = wasteTypeName;
            stepOnTheSpotBinNumber = binNumber;
            stepBinWeight = binWeight;
            
            weighChitTicketNumber = !this.isCopy ? step.weightChitTicketNumber ? step.weightChitTicketNumber : '' : '';
            weighChitTareWeight = !this.isCopy ? step.tareBinWeight ? step.tareBinWeight : '0' : '0';
            weighChitNettWeight = !this.isCopy ? step.netBinWeight ? step.netBinWeight : '0' : '0';
            nonBillableBinWeight = !this.isCopy ? step.nonBillableBinWeight ? step.nonBillableBinWeight : '0' : '0';
            weighChitBillableWeight = !this.isCopy ? step.billableBinWeight ? step.billableBinWeight : '0' : '0';
            weighChitRemarks = !this.isCopy ? step.weightChitRemarks ? step.weightChitRemarks : '' : '';
            weighChitWeighBy = !this.isCopy ? step.binWeighBy ? step.binWeighBy : '' : '';
            weighChitDate = !this.isCopy ? step.weightChitDateTime ? new Date(step.weightChitDateTime) : null : null;
            weighChitTime = !this.isCopy ? step.weightChitDateTime ? new Date(step.weightChitDateTime) : null : null;
            
            let value = Utils.makeIDfromString(((step.serviceItemId) ? step.serviceItemId : '') + step.binTypeId + "_" + step.binTypeName + "_" + step.wasteTypeId + "_" + step.wasteTypeName + "_" + step.serviceTag);
            let arrItem = {
                value: value,
                item: step,
                binTypeId: binTypeId,
                binTypeName: binTypeName,
                wasteTypeId: wasteTypeId,
                wasteTypeName: wasteTypeName,
            };

            stepOnTheSpotBinWasteTypeId = null;
            stepOnTheSpotBinWasteTypeItems = (arrItem) ? [arrItem] : [];
            stepOnTheSpotWasteTypeItems = stepOnTheSpotBinWasteTypeArr;

            stepOnTheSpotSelectedIds = customIds;

          } else if(jobTemplateType === JobStepType.Shift){
            let binTypeId = step.binTypeId;
            let binTypeName = (step.binType && step.binType.binTypeName) ? step.binType.binTypeName : '';
            let wasteTypeId = step.wasteTypeId;
            let wasteTypeName = (step.wasteType && step.wasteType.wasteTypeName) ? step.wasteType.wasteTypeName : '';
            let serviceItemId = step.serviceItemId;
            let binNumber = !this.isCopy ? (step.binNumber) ? step.binNumber : '' : '';
            let wasteTypes = (step.wasteTypes && step.wasteTypes.length > 0) ? step.wasteTypes : [];

            let customIds = [];
            let stepShiftBinWasteTypeArr = [];
            if(wasteTypes && wasteTypes.length > 0){
              stepShiftBinWasteTypeArr = wasteTypes.map((item, i) => {
                if(stepShiftWasteTypeIds.findIndex(x => x === item.wasteTypeId) === -1){
                  stepShiftWasteTypeIds.push(item.wasteTypeId);
                }

                let wtName = (item.wasteType) ? item.wasteType.wasteTypeName : '';

                let serviceTag = Utils.getServiceTagName(wtName, binTypeName);
                let customId = Utils.makeIDfromString(((item.serviceItemId) ? item.serviceItemId : '') + binTypeId + "_" + binTypeName + "_" + item.wasteTypeId + "_" + wtName + "_" + serviceTag);
                customIds = Utils.addToArr(customIds, customId);

                return {
                  value: item.wasteTypeId,
                  title: wtName,
                  item: {
                      binTypeId: binTypeId,
                      binTypeName: binTypeName,
                      wasteTypeId: item.wasteTypeId,
                      wasteTypeName: wtName,
                      serviceTag: '',
                      binOutDate: null,
                      binNumber: binNumber,
                      driverName: '',
                      vehicleName: '',
                  },
                  binTypeId: binTypeId,
                  binTypeName: binTypeName,
                  wasteTypeId: item.wasteTypeId,
                  wasteTypeName: wtName,
                  serviceTag: '',
                  binOutDate: null,
                  binNumber: binNumber,
                  driverName: '',
                  vehicleName: '',
                }
              });
            }
            
            stepShiftServiceItemId = serviceItemId;
            stepShiftBinTypeId = binTypeId;
            stepShiftBinTypeName = binTypeName;
            stepShiftWasteTypeId = wasteTypeId;
            stepShiftWasteTypeName = wasteTypeName;
            stepShiftBinNumber = binNumber;

            let value = Utils.makeIDfromString(((serviceItemId) ? serviceItemId : '') + binTypeId + "_" + binTypeName + "_" + wasteTypeId + "_" + wasteTypeName + "_" + step.serviceTag);
            let stepShiftBinWasteTypeItem = {
                value: value,
                item: {
                    binTypeId: binTypeId,
                    binTypeName: binTypeName,
                    wasteTypeId: wasteTypeId,
                    wasteTypeName: wasteTypeName,
                    serviceTag: '',
                    binOutDate: null,
                    binNumber: binNumber,
                    driverName: '',
                    vehicleName: '',
                },
                binTypeId: binTypeId,
                binTypeName: binTypeName,
                wasteTypeId: wasteTypeId,
                wasteTypeName: wasteTypeName,
                serviceTag: '',
                binOutDate: null,
                binNumber: binNumber,
                driverName: '',
                vehicleName: '',
            };

            stepShiftBinWasteTypeId = value;
            stepShiftBinWasteTypeItems = (stepShiftBinWasteTypeItem) ? [stepShiftBinWasteTypeItem] : [];
            stepShiftWasteTypeItems = stepShiftBinWasteTypeArr;
            
            stepShiftSelectedIds = customIds;

          } else if(jobTemplateType === JobStepType.Put){
            let value = step.binTypeId;
            let binTypeId = step.binTypeId;
            let binTypeName = (step.binType && step.binType.binTypeName) ? step.binType.binTypeName : '';
            let wasteTypeId = step.wasteTypeId;
            let wasteTypeName = (step.wasteType && step.wasteType.wasteTypeName) ? step.wasteType.wasteTypeName : '';
            let serviceItemId = step.serviceItemId;
            let binNumber = !this.isCopy ? (step.binNumber) ? step.binNumber : '' : '';
            let arrItem = {
              value: value,
              item: step,
              binTypeId: binTypeId,
              binTypeName: binTypeName,
              wasteTypeId: wasteTypeId,
              wasteTypeName: wasteTypeName,
            };

            stepPutServiceItemId = serviceItemId;
            stepPutBinTypeId = binTypeId;
            stepPutBinTypeName = binTypeName;
            stepPutWasteTypeId = wasteTypeId;
            stepPutWasteTypeName = wasteTypeName;
            stepPutBinNumber = binNumber;

            stepPutBinWasteTypeItems = (arrItem) ? [arrItem] : [];

            let serviceTag = Utils.getServiceTagName(wasteTypeName, binTypeName);
            let customId = Utils.makeIDfromString(((step.serviceItemId) ? step.serviceItemId : '') + binTypeId + "_" + binTypeName + "_" + wasteTypeId + "_" + wasteTypeName + "_" + serviceTag);
            stepPutSelectedIds = [customId];

          } else if(jobTemplateType === JobStepType.Out){
            let value = step.binTypeId;
            let binTypeId = step.binTypeId;
            let binTypeName = (step.binType && step.binType.binTypeName) ? step.binType.binTypeName : '';
            let wasteTypeId = step.wasteTypeId;
            let wasteTypeName = (step.wasteType && step.wasteType.wasteTypeName) ? step.wasteType.wasteTypeName : '';
            let serviceItemId = step.serviceItemId;
            let binNumber = !this.isCopy ? (step.binNumber) ? step.binNumber : '' : '';
            let binOutWeight = !this.isCopy ? (step.binOutWeight) ? step.binOutWeight : '' : '';
            let arrItem = {
              value: value,
              item: step,
              binTypeId: binTypeId,
              binTypeName: binTypeName,
              wasteTypeId: wasteTypeId,
              wasteTypeName: wasteTypeName,
            };

            stepOutServiceItemId = serviceItemId;
            stepOutBinTypeId = binTypeId;
            stepOutBinTypeName = binTypeName;
            stepOutWasteTypeId = wasteTypeId;
            stepOutWasteTypeName = wasteTypeName;
            stepOutBinNumber = binNumber;
            binOutWeight = binOutWeight;

            stepOutBinWasteTypeItems = (arrItem) ? [arrItem] : [];

            let serviceTag = Utils.getServiceTagName(wasteTypeName, binTypeName);
            let customId = Utils.makeIDfromString(((step.serviceItemId) ? step.serviceItemId : '') + binTypeId + "_" + binTypeName + "_" + wasteTypeId + "_" + wasteTypeName + "_" + serviceTag);
            stepOutSelectedIds = [customId];

          } else if(jobTemplateType === JobStepType.Pull){
            let binTypeId = step.binTypeId;
            let binTypeName = (step.binType && step.binType.binTypeName) ? step.binType.binTypeName : '';
            let wasteTypeId = step.wasteTypeId;
            let wasteTypeName = (step.wasteType && step.wasteType.wasteTypeName) ? step.wasteType.wasteTypeName : '';
            let serviceItemId = step.serviceItemId;
            let binNumber = !this.isCopy ? (step.binNumber) ? step.binNumber : '' : '';
            let binWeight = !this.isCopy ? (step.binWeight) ? step.binWeight : '' : '';
            let wasteTypes = (step.wasteTypes && step.wasteTypes.length > 0) ? step.wasteTypes : [];

            let customIds = [];
            let stepPullBinWasteTypeArr = [];
            if(wasteTypes && wasteTypes.length > 0){
              stepPullBinWasteTypeArr = wasteTypes.map((item, i) => {
                if(stepPullWasteTypeIds.findIndex(x => x === item.wasteTypeId) === -1){
                  stepPullWasteTypeIds.push(item.wasteTypeId);
                }

                let wtName = (item.wasteType) ? item.wasteType.wasteTypeName : '';

                let serviceTag = Utils.getServiceTagName(wtName, binTypeName);
                let customId = Utils.makeIDfromString(((item.serviceItemId) ? item.serviceItemId : '') + binTypeId + "_" + binTypeName + "_" + item.wasteTypeId + "_" + wtName + "_" + serviceTag);
                customIds = Utils.addToArr(customIds, customId);

                return {
                  value: item.wasteTypeId,
                  title: wtName,
                  item: {
                      binTypeId: binTypeId,
                      binTypeName: binTypeName,
                      wasteTypeId: item.wasteTypeId,
                      wasteTypeName: wtName,
                      serviceTag: '',
                      binOutDate: null,
                      binNumber: binNumber,
                      driverName: '',
                      vehicleName: '',
                  },
                  binTypeId: binTypeId,
                  binTypeName: binTypeName,
                  wasteTypeId: item.wasteTypeId,
                  wasteTypeName: wtName,
                  serviceTag: '',
                  binOutDate: null,
                  binNumber: binNumber,
                  driverName: '',
                  vehicleName: '',
                }
              });
            }
            
            stepPullServiceItemId = serviceItemId;
            stepPullBinTypeId = binTypeId;
            stepPullBinTypeName = binTypeName;
            stepPullWasteTypeId = wasteTypeId;
            stepPullWasteTypeName = wasteTypeName;
            stepPullBinNumber = binNumber;
            stepBinWeight = binWeight;
            
            weighChitTicketNumber = !this.isCopy ? step.weightChitTicketNumber ? step.weightChitTicketNumber : '' : '';
            weighChitTareWeight = !this.isCopy ? step.tareBinWeight ? step.tareBinWeight : '0' : '0';
            weighChitNettWeight = !this.isCopy ? step.netBinWeight ? step.netBinWeight : '0' : '0';
            nonBillableBinWeight = !this.isCopy ? step.nonBillableBinWeight ? step.nonBillableBinWeight : '0' : '0';
            weighChitBillableWeight = !this.isCopy ? step.billableBinWeight ? step.billableBinWeight : '0' : '0';
            weighChitRemarks = !this.isCopy ? step.weightChitRemarks ? step.weightChitRemarks : '' : '';
            weighChitWeighBy = !this.isCopy ? step.binWeighBy ? step.binWeighBy : '' : '';
            weighChitDate = !this.isCopy ? step.weightChitDateTime ? new Date(step.weightChitDateTime) : null : null;
            weighChitTime = !this.isCopy ? step.weightChitDateTime ? new Date(step.weightChitDateTime) : null : null;
            
            let value = Utils.makeIDfromString(((step.serviceItemId) ? step.serviceItemId : '') + step.binTypeId + "_" + step.binTypeName + "_" + step.wasteTypeId + "_" + step.wasteTypeName + "_" + step.serviceTag);
            let stepPullBinWasteTypeItem = {
                value: value,
                item: {
                    binTypeId: step.binTypeId,
                    binTypeName: step.binTypeName,
                    wasteTypeId: step.wasteTypeId,
                    wasteTypeName: step.wasteTypeName,
                    serviceTag: '',
                    binOutDate: null,
                    binNumber: binNumber,
                    driverName: '',
                    vehicleName: '',
                },
                binTypeId: step.binTypeId,
                binTypeName: step.binTypeName,
                wasteTypeId: step.wasteTypeId,
                wasteTypeName: step.wasteTypeName,
                serviceTag: '',
                binOutDate: null,
                binNumber: binNumber,
                driverName: '',
                vehicleName: '',
            };

            stepPullBinWasteTypeId = value;
            stepPullBinWasteTypeItems = (stepPullBinWasteTypeItem) ? [stepPullBinWasteTypeItem] : [];
            stepPullWasteTypeItems = stepPullBinWasteTypeArr;
            
            stepPullSelectedIds = customIds;
          }
        }

        let step2 = steps.filter(x => x.stepOrder === 2);
        if(step2 && step2.length > 0){
          let step = step2[0];

          if(jobTemplateType === JobStepType.Exchange){
            let binTypeId = step.binTypeId;
            let binTypeName = (step.binType && step.binType.binTypeName) ? step.binType.binTypeName : '';
            let wasteTypeId = step.wasteTypeId;
            let wasteTypeName = (step.wasteType && step.wasteType.wasteTypeName) ? step.wasteType.wasteTypeName : '';
            let serviceItemId = step.serviceItemId;
            let binNumber = !this.isCopy ? (step.binNumber) ? step.binNumber : '' : '';
            let binWeight = !this.isCopy ? (step.binWeight) ? step.binWeight : '' : '';
            let wasteTypes = (step.wasteTypes && step.wasteTypes.length > 0) ? step.wasteTypes : [];

            let customIds = [];
            let stepExchangeInBinWasteTypeArr = [];
            if(wasteTypes && wasteTypes.length > 0){
              stepExchangeInBinWasteTypeArr = wasteTypes.map((item, i) => {
                if(stepExchangeInWasteTypeIds.findIndex(x => x === item.wasteTypeId) === -1){
                  stepExchangeInWasteTypeIds.push(item.wasteTypeId);
                }

                let wtName = (item.wasteType) ? item.wasteType.wasteTypeName : '';

                let serviceTag = Utils.getServiceTagName(wtName, binTypeName);
                let customId = Utils.makeIDfromString(((item.serviceItemId) ? item.serviceItemId : '') + binTypeId + "_" + binTypeName + "_" + item.wasteTypeId + "_" + wtName + "_" + serviceTag);
                customIds = Utils.addToArr(customIds, customId);

                return {
                  value: item.wasteTypeId,
                  title: wtName,
                  item: {
                      binTypeId: binTypeId,
                      binTypeName: binTypeName,
                      wasteTypeId: item.wasteTypeId,
                      wasteTypeName: wtName,
                      serviceTag: '',
                      binOutDate: null,
                      binNumber: binNumber,
                      driverName: '',
                      vehicleName: '',
                  },
                  binTypeId: binTypeId,
                  binTypeName: binTypeName,
                  wasteTypeId: item.wasteTypeId,
                  wasteTypeName: wtName,
                  serviceTag: '',
                  binOutDate: null,
                  binNumber: binNumber,
                  driverName: '',
                  vehicleName: '',
                }
              });
            }

            stepExchangeInServiceItemId = serviceItemId;
            stepExchangeInBinTypeId = binTypeId;
            stepExchangeInBinTypeName = binTypeName;
            stepExchangeInWasteTypeId = wasteTypeId;
            stepExchangeInWasteTypeName = wasteTypeName;
            stepExchangeInBinNumber = binNumber;
            stepBinWeight = binWeight;
            
            weighChitTicketNumber = !this.isCopy ? step.weightChitTicketNumber ? step.weightChitTicketNumber : '' : '';
            weighChitTareWeight = !this.isCopy ? step.tareBinWeight ? step.tareBinWeight : '0' : '0';
            weighChitNettWeight = !this.isCopy ? step.netBinWeight ? step.netBinWeight : '0' : '0';
            nonBillableBinWeight = !this.isCopy ? step.nonBillableBinWeight ? step.nonBillableBinWeight : '0' : '0';
            weighChitBillableWeight = !this.isCopy ? step.billableBinWeight ? step.billableBinWeight : '0' : '0';
            weighChitRemarks = !this.isCopy ? step.weightChitRemarks ? step.weightChitRemarks : '' : '';
            weighChitWeighBy = !this.isCopy ? step.binWeighBy ? step.binWeighBy : '' : '';
            weighChitDate = !this.isCopy ? step.weightChitDateTime ? new Date(step.weightChitDateTime) : null : null;
            weighChitTime = !this.isCopy ? step.weightChitDateTime ? new Date(step.weightChitDateTime) : null : null;
            
            let value = Utils.makeIDfromString(((step.serviceItemId) ? step.serviceItemId : '') + step.binTypeId + "_" + step.binTypeName + "_" + step.wasteTypeId + "_" + step.wasteTypeName + "_" + step.serviceTag);
            let stepExchangeInBinWasteTypeItem = {
                value: value,
                item: {
                    binTypeId: step.binTypeId,
                    binTypeName: step.binTypeName,
                    wasteTypeId: step.wasteTypeId,
                    wasteTypeName: step.wasteTypeName,
                    serviceTag: '',
                    binOutDate: null,
                    binNumber: binNumber,
                    driverName: '',
                    vehicleName: '',
                },
                binTypeId: step.binTypeId,
                binTypeName: step.binTypeName,
                wasteTypeId: step.wasteTypeId,
                wasteTypeName: step.wasteTypeName,
                serviceTag: '',
                binOutDate: null,
                binNumber: binNumber,
                driverName: '',
                vehicleName: '',
            };

            stepExchangeInBinWasteTypeId = value;
            stepExchangeInBinWasteTypeItems = (stepExchangeInBinWasteTypeItem) ? [stepExchangeInBinWasteTypeItem] : [];
            stepExchangeInWasteTypeItems = stepExchangeInBinWasteTypeArr;
            
            stepExchangeInSelectedIds = customIds;
          }
        }
      }


      let customFormParams = this.state.rowInitialValues.customFormParams;
      customFormParams.jobDetails = data;
      customFormParams.jobId = data.jobId;
      customFormParams.jobNumber = !this.isCopy ? data.jobNumber : '';
      customFormParams.customerItems = customerItems;
      customFormParams.accountBillingProfileItems = accountBillingProfileItems;
      customFormParams.isConnectQuickbook = isConnectQuickbook;
      customFormParams.isConnectXero = isConnectXero;
      customFormParams.quickBookAppId = quickBookAppId;
      customFormParams.paymentTermsItems = paymentTermsItems;
      customFormParams.jobTemplateDetails = jobTemplate;
      customFormParams.jobTemplateType = jobTemplateType;
      customFormParams.customerSiteItems = customerSiteItems;
      customFormParams.customerSiteShiftItems = customerSiteShiftItems;
      customFormParams.jobTemplateStep1Id = jobTemplateStep1Id;
      customFormParams.templateStepSites1Items = templateStepSites1Items;
      customFormParams.jobTemplateStep2Id = jobTemplateStep2Id;
      customFormParams.templateStepSites2Items = templateStepSites2Items;
      customFormParams.isDefaultLocation1 = isDefaultLocation1;
      customFormParams.isDefaultLocation2 = isDefaultLocation2;
      customFormParams.totalDistance = data.totalDistance;
      customFormParams.markers = markers;
      customFormParams.jobTimeSpecific = !this.isCopy ? data.jobTimeSpecific ? new Date(data.jobTimeSpecific) : null : null;
      customFormParams.driverItems = driverItems;
      customFormParams.vehicleItems = vehicleItems;
      customFormParams.paymentMethodItems = paymentMethodItems;
      customFormParams.isSendEmail = data.isSendEmail;
      customFormParams.dispatchLaterTime = data.dispatchLaterTime;
      customFormParams.jobAttemptCompletedDate = data.jobAttemptCompletedDate;
      customFormParams.isSameAsBinOut = false;
      
      customFormParams.weekdaysAvailability = weekdaysAvailability;
      customFormParams.weekendAvailability = weekendAvailability;

      customFormParams.jobStepId1 = jobStepId1;
      customFormParams.jobStepId2 = jobStepId2;
      customFormParams.jobStepId3 = jobStepId3;
      customFormParams.binActivity1 = binActivity1;
      customFormParams.binActivity2 = binActivity2;
      customFormParams.binActivity3 = binActivity3;

      customFormParams.stepExchangeOutBinWasteTypeItems = stepExchangeOutBinWasteTypeItems;

      customFormParams.stepExchangeInBinWasteTypeId = stepExchangeInBinWasteTypeId;
      customFormParams.stepExchangeInBinWasteTypeItems = stepExchangeInBinWasteTypeItems;
      customFormParams.stepExchangeInWasteTypeItems = stepExchangeInWasteTypeItems;

      customFormParams.stepPutBinWasteTypeId = stepPutBinWasteTypeId;
      customFormParams.stepPutBinWasteTypeItems = stepPutBinWasteTypeItems;
      customFormParams.stepPutWasteTypeItems = stepPutWasteTypeItems;

      customFormParams.stepOutBinWasteTypeId = stepOutBinWasteTypeId;
      customFormParams.stepOutBinWasteTypeItems = stepOutBinWasteTypeItems;
      customFormParams.stepOutWasteTypeItems = stepOutWasteTypeItems;

      customFormParams.stepPullBinWasteTypeId = stepPullBinWasteTypeId;
      customFormParams.stepPullBinWasteTypeItems = stepPullBinWasteTypeItems;
      customFormParams.stepPullWasteTypeItems = stepPullWasteTypeItems;

      customFormParams.stepShiftBinWasteTypeId = stepShiftBinWasteTypeId;
      customFormParams.stepShiftBinWasteTypeItems = stepShiftBinWasteTypeItems;
      customFormParams.stepShiftWasteTypeItems = stepShiftWasteTypeItems;

      customFormParams.stepOnTheSpotBinWasteTypeId = stepOnTheSpotBinWasteTypeId;
      customFormParams.stepOnTheSpotBinWasteTypeItems = stepOnTheSpotBinWasteTypeItems;
      customFormParams.stepOnTheSpotWasteTypeItems = stepOnTheSpotWasteTypeItems;


      let isActive = ((data.isActive === false) || (data.isActive === true)) ? data.isActive : true;
      let promptUpdateBilling = ((data.promptUpdateBilling === false) || (data.promptUpdateBilling === true)) ? data.promptUpdateBilling : false;

      
      let rowValues = {
        isEdit: true,
        isCopy: this.isCopy,
        isActive: isActive,
        promptUpdateBilling: promptUpdateBilling,
        [this.pageIdField]: this.id,
        
        action: JobAction.Save,

        customerId: customerId,
        customerName: customerName,
        customerItem: customerItem,
        remarks: data.remarks,
        cancellationRemarks: (!this.isCopy) ? (data && data.cancellationRemarks) ? data.cancellationRemarks : '' : '',
        customerNote: (customerItem && customerItem.note) ? customerItem.note : '',

        accountBillingProfileId: accountBillingProfileId,
        accountBillingProfileName: accountBillingProfileName,
        paymentTermsId: paymentTermsId,
        paymentTermsName: paymentTermsName,
        xeroPaymentTerm: xeroPaymentTerm,
        xeroPaymentTermType: xeroPaymentTermType,

        isBillable: data.isBillable,
        billedDate: data.billedDate,
        invoiceDate: data.invoiceDate,
        invoiceNumber: data.invoiceNumber,
        billingAddress: billingAddress,
        billingNote: data.billingNote,

        driverId: driverId,
        driverName: driverName,
        vehicleId: vehicleId,
        vehicleName: vehicleName,

        isEditableBinTypeOut: data.isEditableBinTypeOut,
        isEditableWasteTypeOut: data.isEditableWasteTypeOut,
        isEditableBinNumberOut: data.isEditableBinNumberOut,
        isEditableBinNumberIn: data.isEditableBinNumberIn,
        isEditableWasteTypeIn: data.isEditableWasteTypeIn,
        duration: duration,
	
        jobTemplateId: data.jobTemplateId,
        jobTemplateName: data.jobTemplateName,
        jobTemplateType: jobTemplateType,

        instructionToDrivers: data.instructionToDrivers,
        siteRemarks: siteRemarks,
        contactPersonOne: contactPersonOne,
        contactNumberOne: contactNumberOne,
        contactPersonTwo: contactPersonTwo,
        contactNumberTwo: contactNumberTwo,
        isContactPersonSendNotification: isContactPersonSendNotification,
        isContactPersonTwoSendNotification: isContactPersonTwoSendNotification,
        amountToCollect: amountToCollect,
        collectedAmount: collectedAmount,
        paymentMethodId: paymentMethodId,
        paymentMethodName: paymentMethodName,

        isRequireBinNumberToEnd1: isRequireBinNumberToEnd1,
        isRequireBinNumberToEnd2: isRequireBinNumberToEnd2,
        mustTakePhoto1: mustTakePhoto1,
        numberofPhotosRequired1: numberofPhotosRequired1,
        mustTakeSignature: mustTakeSignature,
        isRequirePaymentCollection: isRequirePaymentCollection,
        isRequireBinWeight: isRequireBinWeight,
        isRequireReviewWasteType: isRequireReviewWasteType,
        mustTakePhoto2: mustTakePhoto2,
        numberofPhotosRequired2: numberofPhotosRequired2,

        customerSiteId: customerSiteId,
        customerSiteName: customerSiteName,
        customerSiteAddress: customerSiteAddress,
        customerSiteItem: customerSiteItem,
        hasCoordCustomerSite: hasCoordCustomerSite,
        customerSiteShiftId: customerSiteShiftId,
        customerSiteShiftName: customerSiteShiftName,
        customerSiteShiftAddress: customerSiteShiftAddress,
        customerSiteShiftItem: customerSiteShiftItem,
        hasCoordCustomerSiteShift: hasCoordCustomerSiteShift,

        templateStepSites1Id: templateStepSites1Id,
        templateStepSites1Name: templateStepSites1Name,
        templateStepSites1Address: templateStepSites1Address,
        templateStepSites1Latitude: templateStepSites1Latitude,
        templateStepSites1Longitude: templateStepSites1Longitude,
        templateStepSites2Id: templateStepSites2Id,
        templateStepSites2Name: templateStepSites2Name,
        templateStepSites2Address: templateStepSites2Address,
        templateStepSites2Latitude: templateStepSites2Latitude,
        templateStepSites2Longitude: templateStepSites2Longitude,

        stepExchangeOutServiceItemId: stepExchangeOutServiceItemId,
        stepExchangeOutBinTypeId: stepExchangeOutBinTypeId,
        stepExchangeOutBinTypeName: stepExchangeOutBinTypeName,
        stepExchangeOutBinTypeItem: stepExchangeOutBinTypeItem,
        stepExchangeOutWasteTypeItem: stepExchangeOutWasteTypeItem,
        stepExchangeOutWasteTypeId: stepExchangeOutWasteTypeId,
        stepExchangeOutWasteTypeName: stepExchangeOutWasteTypeName,
        stepExchangeOutWasteTypeIds: stepExchangeOutWasteTypeIds,
        stepExchangeOutBinNumber: stepExchangeOutBinNumber,
        stepExchangeOutSelectedIds: stepExchangeOutSelectedIds,

        stepExchangeInServiceItemId: stepExchangeInServiceItemId,
        stepExchangeInBinTypeId: stepExchangeInBinTypeId,
        stepExchangeInBinTypeName: stepExchangeInBinTypeName,
        stepExchangeInWasteTypeId: stepExchangeInWasteTypeId,
        stepExchangeInWasteTypeName: stepExchangeInWasteTypeName,
        stepExchangeInWasteTypeIds: stepExchangeInWasteTypeIds,
        stepExchangeInBinNumber: stepExchangeInBinNumber,
        stepExchangeInSelectedIds: stepExchangeInSelectedIds,
        
        stepPutServiceItemId: stepPutServiceItemId,
        stepPutBinTypeId: stepPutBinTypeId,
        stepPutBinTypeName: stepPutBinTypeName,
        stepPutWasteTypeId: stepPutWasteTypeId,
        stepPutWasteTypeName: stepPutWasteTypeName,
        stepPutWasteTypeIds: stepPutWasteTypeIds,
        stepPutBinNumber: stepPutBinNumber,
        stepPutSelectedIds: stepPutSelectedIds,

        stepOutServiceItemId: stepOutServiceItemId,
        stepOutBinTypeId: stepOutBinTypeId,
        stepOutBinTypeName: stepOutBinTypeName,
        stepOutWasteTypeId: stepOutWasteTypeId,
        stepOutWasteTypeName: stepOutWasteTypeName,
        stepOutWasteTypeIds: stepOutWasteTypeIds,
        stepOutBinNumber: stepOutBinNumber,
        stepOutSelectedIds: stepOutSelectedIds,

        stepPullServiceItemId: stepPullServiceItemId,
        stepPullBinTypeId: stepPullBinTypeId,
        stepPullBinTypeName: stepPullBinTypeName,
        stepPullWasteTypeId: stepPullWasteTypeId,
        stepPullWasteTypeName: stepPullWasteTypeName,
        stepPullWasteTypeIds: stepPullWasteTypeIds,
        stepPullBinNumber: stepPullBinNumber,
        stepPullSelectedIds: stepPullSelectedIds,

        stepShiftServiceItemId: stepShiftServiceItemId,
        stepShiftBinTypeId: stepShiftBinTypeId,
        stepShiftBinTypeName: stepShiftBinTypeName,
        stepShiftWasteTypeId: stepShiftWasteTypeId,
        stepShiftWasteTypeName: stepShiftWasteTypeName,
        stepShiftWasteTypeIds: stepShiftWasteTypeIds,
        stepShiftBinNumber: stepShiftBinNumber,
        stepShiftSelectedIds: stepShiftSelectedIds,

        stepOnTheSpotServiceItemId: stepOnTheSpotServiceItemId,
        stepOnTheSpotBinTypeId: stepOnTheSpotBinTypeId,
        stepOnTheSpotBinTypeName: stepOnTheSpotBinTypeName,
        stepOnTheSpotWasteTypeId: stepOnTheSpotWasteTypeId,
        stepOnTheSpotWasteTypeName: stepOnTheSpotWasteTypeName,
        stepOnTheSpotWasteTypeIds: stepOnTheSpotWasteTypeIds,
        stepOnTheSpotBinNumber: stepOnTheSpotBinNumber,
        stepOnTheSpotSelectedIds: stepOnTheSpotSelectedIds,

        stepBinWeight: stepBinWeight,
        binOutWeight: binOutWeight,
        
        weighChitTicketNumber: weighChitTicketNumber,
        weighChitTareWeight: weighChitTareWeight,
        weighChitNettWeight: weighChitNettWeight,
        nonBillableBinWeight: nonBillableBinWeight,
        weighChitBillableWeight: weighChitBillableWeight,
        weighChitRemarks: weighChitRemarks,
        weighChitWeighBy: weighChitWeighBy,
        weighChitDate: weighChitDate,
        weighChitTime: weighChitTime,
        
        isRecurringForm: true,
        isRecurring: true,
        repeatOrder: data.repeatOrder,
        recurringJobName: data.recurringJobName,
        recurringJobDescription: data.recurringJobDescription,
        // recurringAssignToSameDriverVehicle: data.assignToSameDriverVehicle,
        // recurringNumberOfTrip: data.numberOfTrip,
        recurringStartDate: data.startDate ? new Date(data.startDate) : null,
        recurringEndDate: data.endDate ? new Date(data.endDate) : null,
        recurringDefaultTime: data.defaultTime ? new Date(data.defaultTime) : null,
        recurringType: data.recurringType,
        schedules: Utils.getSchedules(data.repeatOrder, data),

        customFormParams: customFormParams,
      };
  
      markers = Utils.getJobFormLocationMarkers({
        customerSiteId: rowValues.customerSiteId,
        customerSiteItems: rowValues.customFormParams.customerSiteItems,
        customerSiteShiftId: rowValues.customerSiteShiftId,
        customerSiteShiftItems: rowValues.customFormParams.customerSiteShiftItems,
        templateStepSites1Latitude: rowValues.templateStepSites1Latitude,
        templateStepSites1Longitude: rowValues.templateStepSites1Longitude,
        templateStepSites2Latitude: rowValues.templateStepSites2Latitude,
        templateStepSites2Longitude: rowValues.templateStepSites2Longitude,
        jobTemplateType: rowValues.customFormParams.jobTemplateType
      }, rowValues);
      rowValues.customFormParams.markers = markers;
      
      let isSameAsBinOut = Utils.isSameAsBinOut({
        stepExchangeOutBinTypeId: rowValues.stepExchangeOutBinTypeId,
        stepExchangeOutBinNumber: rowValues.stepExchangeOutBinNumber,
        stepExchangeOutWasteTypeId: rowValues.stepExchangeOutWasteTypeId,
        stepExchangeInBinTypeId: rowValues.stepExchangeInBinTypeId,
        stepExchangeInBinNumber: rowValues.stepExchangeInBinNumber,
        stepExchangeInWasteTypeIds: rowValues.stepExchangeInWasteTypeIds,
      });
      rowValues.customFormParams.isSameAsBinOut = isSameAsBinOut;

      return rowValues;
    } else {
      return this.state.rowInitialValues;
    }
  }
  prepareData = (form) => {
    let duration = Utils.getHoursMinutes((form.duration) ? form.duration : 2);
    
    let jobDate = null;
    let jobDateTime = null;
    if(form.jobDate){
      jobDate = moment(form.jobDate).format(Utils.getAPIDateFormat());
      let jobTime = moment(form.customFormParams.jobTimeSpecific).format(Utils.getDefaultTimeFormat());
      jobDateTime = Utils.getLocalIsoDateTime(moment(jobDate + ' ' + jobTime, Utils.getAPIDateTimeFormat()))
    }

    let serviceId = null;
    let serviceName = '';
    if(form && form.customerSiteItem && form.customerSiteItem.services && form.customerSiteItem.services.length > 0){
      let service = form.customerSiteItem.services[0];
      serviceId = (service && service.serviceId) ? service.serviceId : null;
      serviceName = (service && service.serviceName) ? service.serviceName : '';
    }
    
    let data = {
      // action: parseInt(form.action),
      
      isActive: true,

      jobStatusId: form.statusId,
      customerId: form.customerId,
      driverId: form.driverId,
      vehicleId: form.vehicleId,
      jobTemplateId: form.jobTemplateId,
      jobTypeName: Utils.getJobTypeInfo(form.customFormParams.jobTemplateType.toString()),
      remarks: form.remarks,
      cancellationRemarks: form.cancellationRemarks,
      instructionToDrivers: form.instructionToDrivers,
      billingNote: form.billingNote,

      isBillable: form.isBillable,
      serviceId: serviceId,
      serviceName: serviceName,

      jobDate: jobDateTime,
      jobTimeSpecific: (form.customFormParams.jobTimeSpecific) ? (jobDateTime) ? jobDateTime : null : null,
      jobTimeFrom: jobDateTime,
      jobTimeTo: jobDateTime,
      workingTimeName: form.accountJobWorkingTimeName,
      jobDurationHours: (duration && duration.hours) ? duration.hours.toString() : '',
      jobDurationMinutes: (duration && duration.minutes) ? duration.minutes.toString() : '',
      dispatchLaterTime: form.customFormParams.dispatchLaterTime,
      
      isEditableWasteTypeIn: form.isEditableWasteTypeIn,
      isEditableBinTypeOut: form.isEditableBinTypeOut,
      isEditableWasteTypeOut: form.isEditableWasteTypeOut,
      isEditableBinNumberIn: form.isEditableBinNumberIn,
      isEditableBinNumberOut: form.isEditableBinNumberOut,
      totalDistance: form.customFormParams.totalDistance,

      steps: this.prepareStepsData(form),

      ...this.prepareRecurringData(form)
    };
    
    if(this.id > 0){
      data[this.pageIdField] = this.id;
    }
    
    return data;
  }
  prepareStepsData = (values) => {
    let data = [];


    let statusStarted = null;
    let statusStartedItem = Utils.findStatus(values.customFormParams.statusItems, 'title','started');
    if (statusStartedItem) {
      statusStarted = statusStartedItem.value;
    }

    let statusInProgress = null;
    let statusInProgressItem = Utils.findStatus(values.customFormParams.statusItems, 'title','in progress');
    if (statusInProgressItem) {
      statusInProgress = statusInProgressItem.value;
    }

    let statusCompleted = null;
    let statusCompletedItem = Utils.findStatus(values.customFormParams.statusItems, 'title','completed');
    if (statusCompletedItem) {
      statusCompleted = statusCompletedItem.value;
    }


    let serviceItemIds = this.getServiceItemId(values);
    

    if(values.customFormParams.jobTemplateType === JobStepType.Exchange){
      let step1 = {
        jobStepId: values.customFormParams.jobStepId1,
        stepOrder: 1,
        stepType: JobStepType.Exchange,
        binActivity: values.customFormParams.binActivity1,
        serviceItemId: serviceItemIds.serviceItemId1,
        isCopyServiceItem: values.stepExchangeOutIsCopyService,


        binTypeId: values.stepExchangeOutBinTypeId,
        binTypeName: values.stepExchangeOutBinTypeName,
        binNumber: (values.stepExchangeOutBinNumber) ? values.stepExchangeOutBinNumber : '',
        binWeight: null,
        
        wasteTypeId: values.stepExchangeOutWasteTypeId,
        wasteTypeName: values.stepExchangeOutWasteTypeName,
        wasteTypes: (values.stepExchangeOutWasteTypeId) ? [
          {
            wasteTypeId: values.stepExchangeOutWasteTypeId,
            order: 0
          }
        ] : [],


        customerSiteId: null,
        address: values.templateStepSites1Address,
        siteName: values.templateStepSites1Name,
        latitude: values.templateStepSites1Latitude,
        longitude: values.templateStepSites1Longitude,
        blockNo: '',
        street: '',
        unitNo: '',
        postalCode: '',

        contactPersonOne: '',
        contactNumberOne: '',
        contactPersonTwo: '',
        contactNumberTwo: '',
        isContactPersonSendNotification: false,
        isContactPersonTwoSendNotification: false,
        siteRemarks: '',

        amountToCollect: '',
        collectedAmount: '',
        jobPaymentType: null,

        requireStatusToEnd: statusStarted,

        isRequireBinNumberToEnd: values.isRequireBinNumberToEnd1,
        isRequireBinNumberToStart: values.isRequireBinNumberToEnd1,
        isRequireReviewWasteType: true,
      }
      data.push(step1);

      let step2 = {
        jobStepId: values.customFormParams.jobStepId2,
        stepOrder: 2,
        stepType: JobStepType.Exchange,
        binActivity: values.customFormParams.binActivity2,
        serviceItemId: serviceItemIds.serviceItemId2,
        isCopyServiceItem: values.stepExchangeInIsCopyService,
        

        binTypeId: values.stepExchangeInBinTypeId,
        binTypeName: values.stepExchangeInBinTypeName,
        binNumber: (values.stepExchangeInBinNumber) ? values.stepExchangeInBinNumber : '',
        binWeight: values.stepBinWeight,
        
        wasteTypeId: (values.stepExchangeInWasteTypeIds && values.stepExchangeInWasteTypeIds.length > 0) ? values.stepExchangeInWasteTypeIds[0] : null,
        wasteTypes: (values.stepExchangeInWasteTypeIds && values.stepExchangeInWasteTypeIds.length > 0) ? values.stepExchangeInWasteTypeIds.map((x, i) => {
          return {
            wasteTypeId: x,
            order: i
          }
        }) : [],


        customerSiteId: values.customerSiteId,
        address: values.customerSiteAddress,
        siteName: (values.customerSiteItem) ? values.customerSiteItem.siteName : '',
        latitude: (values.customerSiteItem) ? values.customerSiteItem.latitude : '',
        longitude: (values.customerSiteItem) ? values.customerSiteItem.longitude : '',
        blockNo: (values.customerSiteItem) ? values.customerSiteItem.blockNo : '',
        street: (values.customerSiteItem) ? values.customerSiteItem.street : '',
        unitNo: (values.customerSiteItem) ? values.customerSiteItem.unitNo : '',
        postalCode: (values.customerSiteItem) ? values.customerSiteItem.postalCode : '',
        
        contactPersonOne: values.contactPersonOne,
        contactNumberOne: values.contactNumberOne,
        contactPersonTwo: values.contactPersonTwo,
        contactNumberTwo: values.contactNumberTwo,
        isContactPersonSendNotification: values.isContactPersonSendNotification,
        isContactPersonTwoSendNotification: values.isContactPersonTwoSendNotification,
        siteRemarks: values.siteRemarks,

        defaultAmountToCollect: (values.isRequirePaymentCollection === false) ? 0 : ((values.amountToCollect !== '') ? values.amountToCollect : 0),
        collectedAmount: (values.isRequirePaymentCollection === false) ? '0' : values.collectedAmount,
        jobPaymentType: values.paymentMethodId,

        requireStatusToEnd: statusInProgress,

        isRequireBinNumberToEnd: values.isRequireBinNumberToEnd2,
        isRequireBinNumberToStart: values.isRequireBinNumberToEnd2,
        isRequireReviewWasteType: true,
        numberofPhotosRequired: values.numberofPhotosRequired1,
        mustTakePhoto: values.mustTakePhoto1,
        mustTakeSignature: values.mustTakeSignature,
        isRequirePaymentCollection: values.isRequirePaymentCollection,
      }
      data.push(step2);

      let step3 = {
        jobStepId: values.customFormParams.jobStepId3,
        stepOrder: 3,
        stepType: JobStepType.Exchange,
        binActivity: values.customFormParams.binActivity3,
        serviceItemId: serviceItemIds.serviceItemId3,
        isCopyServiceItem: false,
        
        customerSiteId: null,
        address: values.templateStepSites2Address,
        siteName: values.templateStepSites2Name,
        latitude: values.templateStepSites2Latitude,
        longitude: values.templateStepSites2Longitude,
        blockNo: '',
        street: '',
        unitNo: '',
        postalCode: '',

        contactPersonOne: '',
        contactNumberOne: '',
        contactPersonTwo: '',
        contactNumberTwo: '',
        isContactPersonSendNotification: false,
        isContactPersonTwoSendNotification: false,
        siteRemarks: '',

        amountToCollect: '',
        collectedAmount: '',
        jobPaymentType: null,
        
        requireStatusToEnd: statusCompleted,

        numberofPhotosRequired: values.numberofPhotosRequired2,
        mustTakePhoto: values.mustTakePhoto2,
        isRequireBinWeight: values.isRequireBinWeight,
        isRequireReviewWasteType: values.isRequireReviewWasteType,
      }
      data.push(step3);

    } else if(values.customFormParams.jobTemplateType === JobStepType.OnTheSpot){
      let step1 = {
        jobStepId: values.customFormParams.jobStepId1,
        stepOrder: 1,
        stepType: JobStepType.OnTheSpot,
        binActivity: values.customFormParams.binActivity1,
        serviceItemId: serviceItemIds.serviceItemId1,
        isCopyServiceItem: values.stepOnTheSpotIsCopyService,


        binTypeId: values.stepOnTheSpotBinTypeId,
        binTypeName: values.stepOnTheSpot,
        binNumber: (values.stepOnTheSpotBinNumber) ? values.stepOnTheSpotBinNumber : '',
        binWeight: null,
        
        
        wasteTypeId: (values.stepOnTheSpotWasteTypeIds && values.stepOnTheSpotWasteTypeIds.length > 0) ? values.stepOnTheSpotWasteTypeIds[0] : null,
        wasteTypes: (values.stepOnTheSpotWasteTypeIds && values.stepOnTheSpotWasteTypeIds.length > 0) ? values.stepOnTheSpotWasteTypeIds.map((x, i) => {
          return {
            wasteTypeId: x,
            order: i
          }
        }) : [],


        customerSiteId: null,
        address: values.templateStepSites1Address,
        siteName: values.templateStepSites1Name,
        latitude: values.templateStepSites1Latitude,
        longitude: values.templateStepSites1Longitude,
        blockNo: '',
        street: '',
        unitNo: '',
        postalCode: '',

        contactPersonOne: '',
        contactNumberOne: '',
        contactPersonTwo: '',
        contactNumberTwo: '',
        isContactPersonSendNotification: false,
        isContactPersonTwoSendNotification: false,
        siteRemarks: '',

        amountToCollect: '',
        collectedAmount: '',
        jobPaymentType: null,

        requireStatusToEnd: statusStarted,

        isRequireBinNumberToEnd: values.isRequireBinNumberToEnd1,
        isRequireBinNumberToStart: values.isRequireBinNumberToEnd1,
        isRequireReviewWasteType: true,
      }
      data.push(step1);

      let step2 = {
        jobStepId: values.customFormParams.jobStepId2,
        stepOrder: 2,
        stepType: JobStepType.OnTheSpot,
        binActivity: values.customFormParams.binActivity2,
        serviceItemId: serviceItemIds.serviceItemId2,
        isCopyServiceItem: values.stepOnTheSpotIsCopyService,
        

        binTypeId: values.stepOnTheSpotBinTypeId,
        binTypeName: values.stepOnTheSpotBinTypeName,
        binNumber: (values.stepOnTheSpotBinNumber) ? values.stepOnTheSpotBinNumber : '',
        binWeight: values.stepBinWeight,
        
        wasteTypeId: (values.stepOnTheSpotWasteTypeIds && values.stepOnTheSpotWasteTypeIds.length > 0) ? values.stepOnTheSpotWasteTypeIds[0] : null,
        wasteTypes: (values.stepOnTheSpotWasteTypeIds && values.stepOnTheSpotWasteTypeIds.length > 0) ? values.stepOnTheSpotWasteTypeIds.map((x, i) => {
          return {
            wasteTypeId: x,
            order: i
          }
        }) : [],


        customerSiteId: values.customerSiteId,
        address: values.customerSiteAddress,
        siteName: (values.customerSiteItem) ? values.customerSiteItem.siteName : '',
        latitude: (values.customerSiteItem) ? values.customerSiteItem.latitude : '',
        longitude: (values.customerSiteItem) ? values.customerSiteItem.longitude : '',
        blockNo: (values.customerSiteItem) ? values.customerSiteItem.blockNo : '',
        street: (values.customerSiteItem) ? values.customerSiteItem.street : '',
        unitNo: (values.customerSiteItem) ? values.customerSiteItem.unitNo : '',
        postalCode: (values.customerSiteItem) ? values.customerSiteItem.postalCode : '',
        
        contactPersonOne: values.contactPersonOne,
        contactNumberOne: values.contactNumberOne,
        contactPersonTwo: values.contactPersonTwo,
        contactNumberTwo: values.contactNumberTwo,
        isContactPersonSendNotification: values.isContactPersonSendNotification,
        isContactPersonTwoSendNotification: values.isContactPersonTwoSendNotification,
        siteRemarks: values.siteRemarks,

        defaultAmountToCollect: (values.isRequirePaymentCollection === false) ? 0 : ((values.amountToCollect !== '') ? values.amountToCollect : 0),
        collectedAmount: values.collectedAmount,
        jobPaymentType: values.paymentMethodId,

        requireStatusToEnd: statusInProgress,

        isRequireReviewWasteType: true,
        numberofPhotosRequired: values.numberofPhotosRequired1,
        mustTakePhoto: values.mustTakePhoto1,
        mustTakeSignature: values.mustTakeSignature,
        isRequirePaymentCollection: values.isRequirePaymentCollection,
      }
      data.push(step2);

      let step3 = {
        jobStepId: values.customFormParams.jobStepId3,
        stepOrder: 3,
        stepType: JobStepType.OnTheSpot,
        binActivity: values.customFormParams.binActivity3,
        serviceItemId: serviceItemIds.serviceItemId3,
        isCopyServiceItem: false,
        
        customerSiteId: null,
        address: values.templateStepSites2Address,
        siteName: values.templateStepSites2Name,
        latitude: values.templateStepSites2Latitude,
        longitude: values.templateStepSites2Longitude,
        blockNo: '',
        street: '',
        unitNo: '',
        postalCode: '',

        contactPersonOne: '',
        contactNumberOne: '',
        contactPersonTwo: '',
        contactNumberTwo: '',
        isContactPersonSendNotification: false,
        isContactPersonTwoSendNotification: false,
        siteRemarks: '',

        amountToCollect: '',
        collectedAmount: '',
        jobPaymentType: null,
        
        requireStatusToEnd: statusCompleted,

        numberofPhotosRequired: values.numberofPhotosRequired2,
        mustTakePhoto: values.mustTakePhoto2,
        isRequireBinWeight: values.isRequireBinWeight,
        isRequireReviewWasteType: values.isRequireReviewWasteType,
      }
      data.push(step3);

    } else if(values.customFormParams.jobTemplateType === JobStepType.Shift){
      let step1 = {
        jobStepId: values.customFormParams.jobStepId1,
        stepOrder: 1,
        stepType: JobStepType.Shift,
        binActivity: values.customFormParams.binActivity1,
        serviceItemId: serviceItemIds.serviceItemId1,
        isCopyServiceItem: values.stepShiftIsCopyService,


        binTypeId: values.stepShiftBinTypeId,
        binTypeName: values.stepShiftBinTypeName,
        binNumber: (values.stepShiftBinNumber) ? values.stepShiftBinNumber : '',
        binWeight: null,
        
        wasteTypeId: (values.stepShiftWasteTypeIds && values.stepShiftWasteTypeIds.length > 0) ? values.stepShiftWasteTypeIds[0] : null,
        wasteTypes: (values.stepShiftWasteTypeIds && values.stepShiftWasteTypeIds.length > 0) ? values.stepShiftWasteTypeIds.map((x, i) => {
          return {
            wasteTypeId: x,
            order: i
          }
        }) : [],


        customerSiteId: values.customerSiteId,
        address: values.customerSiteAddress,
        siteName: (values.customerSiteItem) ? values.customerSiteItem.siteName : '',
        latitude: (values.customerSiteItem) ? values.customerSiteItem.latitude : '',
        longitude: (values.customerSiteItem) ? values.customerSiteItem.longitude : '',
        blockNo: (values.customerSiteItem) ? values.customerSiteItem.blockNo : '',
        street: (values.customerSiteItem) ? values.customerSiteItem.street : '',
        unitNo: (values.customerSiteItem) ? values.customerSiteItem.unitNo : '',
        postalCode: (values.customerSiteItem) ? values.customerSiteItem.postalCode : '',
        
        contactPersonOne: values.contactPersonOne,
        contactNumberOne: values.contactNumberOne,
        contactPersonTwo: values.contactPersonTwo,
        contactNumberTwo: values.contactNumberTwo,
        isContactPersonSendNotification: values.isContactPersonSendNotification,
        isContactPersonTwoSendNotification: values.isContactPersonTwoSendNotification,
        siteRemarks: values.siteRemarks,

        defaultAmountToCollect: (values.isRequirePaymentCollection === false) ? 0 : ((values.amountToCollect !== '') ? values.amountToCollect : 0),
        collectedAmount: values.collectedAmount,
        jobPaymentType: values.paymentMethodId,

        requireStatusToEnd: statusInProgress,

        isRequireBinNumberToEnd: values.isRequireBinNumberToEnd2,
        isRequireBinNumberToStart: values.isRequireBinNumberToEnd2,
        isRequireReviewWasteType: true,
        numberofPhotosRequired: values.numberofPhotosRequired1,
        mustTakePhoto: values.mustTakePhoto1,
        mustTakeSignature: values.mustTakeSignature,
        isRequirePaymentCollection: values.isRequirePaymentCollection,
      }
      data.push(step1);

      let step2 = {
        jobStepId: values.customFormParams.jobStepId2,
        stepOrder: 2,
        stepType: JobStepType.Shift,
        binActivity: values.customFormParams.binActivity2,
        serviceItemId: serviceItemIds.serviceItemId2,
        isCopyServiceItem: false,
        

        binTypeId: values.stepShiftInBinTypeId,
        binTypeName: values.stepShiftInBinTypeName,
        binNumber: values.stepShiftInBinNumber,
        
        wasteTypeId: (values.stepShiftInWasteTypeIds && values.stepShiftInWasteTypeIds.length > 0) ? values.stepShiftInWasteTypeIds[0] : null,
        wasteTypes: (values.stepShiftInWasteTypeIds && values.stepShiftInWasteTypeIds.length > 0) ? values.stepShiftInWasteTypeIds.map((x, i) => {
          return {
            wasteTypeId: x,
            order: i
          }
        }) : [],


        customerSiteId: values.customerSiteShiftId,
        address: values.customerSiteShiftAddress,
        siteName: (values.customerSiteShiftItem) ? values.customerSiteShiftItem.siteName : '',
        latitude: (values.customerSiteShiftItem) ? values.customerSiteShiftItem.latitude : '',
        longitude: (values.customerSiteShiftItem) ? values.customerSiteShiftItem.longitude : '',
        blockNo: (values.customerSiteShiftItem) ? values.customerSiteShiftItem.blockNo : '',
        street: (values.customerSiteShiftItem) ? values.customerSiteShiftItem.street : '',
        unitNo: (values.customerSiteShiftItem) ? values.customerSiteShiftItem.unitNo : '',
        postalCode: (values.customerSiteShiftItem) ? values.customerSiteShiftItem.postalCode : '',
        
        contactPersonOne: '',
        contactNumberOne: '',
        contactPersonTwo: '',
        contactNumberTwo: '',
        isContactPersonSendNotification: false,
        isContactPersonTwoSendNotification: false,
        siteRemarks: '',

        amountToCollect: '',
        collectedAmount: '',
        jobPaymentType: null,

        requireStatusToEnd: statusCompleted,

        numberofPhotosRequired: values.numberofPhotosRequired2,
        mustTakePhoto: values.mustTakePhoto2,
      }
      data.push(step2);
    
    } else if(values.customFormParams.jobTemplateType === JobStepType.Put){
      let step1 = {
        jobStepId: values.customFormParams.jobStepId1,
        stepOrder: 1,
        stepType: JobStepType.Put,
        binActivity: values.customFormParams.binActivity1,
        serviceItemId: serviceItemIds.serviceItemId1,
        isCopyServiceItem: values.stepPutIsCopyService,


        binTypeId: values.stepPutBinTypeId,
        binTypeName: values.stepPutBinTypeName,
        binNumber: (values.stepPutBinNumber) ? values.stepPutBinNumber : '',
        binWeight: null,
        
        wasteTypeId: values.stepPutWasteTypeId,
        wasteTypeName: values.stepPutWasteTypeName,
        wasteTypes: (values.stepPutWasteTypeId) ? [
          {
            wasteTypeId: values.stepPutWasteTypeId,
            order: 0
          }
        ] : [],


        customerSiteId: null,
        address: values.templateStepSites1Address,
        siteName: values.templateStepSites1Name,
        latitude: values.templateStepSites1Latitude,
        longitude: values.templateStepSites1Longitude,
        blockNo: '',
        street: '',
        unitNo: '',
        postalCode: '',

        contactPersonOne: '',
        contactNumberOne: '',
        contactPersonTwo: '',
        contactNumberTwo: '',
        isContactPersonSendNotification: false,
        isContactPersonTwoSendNotification: false,
        siteRemarks: '',

        amountToCollect: '',
        collectedAmount: '',
        jobPaymentType: null,

        requireStatusToEnd: statusStarted,

        isRequireBinNumberToEnd: values.isRequireBinNumberToEnd1,
        isRequireBinNumberToStart: values.isRequireBinNumberToEnd1,
        isRequireReviewWasteType: true,
      }
      data.push(step1);

      let step2 = {
        jobStepId: values.customFormParams.jobStepId2,
        stepOrder: 2,
        stepType: JobStepType.Put,
        binActivity: values.customFormParams.binActivity2,
        serviceItemId: serviceItemIds.serviceItemId2,
        isCopyServiceItem: false,
        
        customerSiteId: values.customerSiteId,
        address: values.customerSiteAddress,
        siteName: (values.customerSiteItem) ? values.customerSiteItem.siteName : '',
        latitude: (values.customerSiteItem) ? values.customerSiteItem.latitude : '',
        longitude: (values.customerSiteItem) ? values.customerSiteItem.longitude : '',
        blockNo: (values.customerSiteItem) ? values.customerSiteItem.blockNo : '',
        street: (values.customerSiteItem) ? values.customerSiteItem.street : '',
        unitNo: (values.customerSiteItem) ? values.customerSiteItem.unitNo : '',
        postalCode: (values.customerSiteItem) ? values.customerSiteItem.postalCode : '',
        
        contactPersonOne: values.contactPersonOne,
        contactNumberOne: values.contactNumberOne,
        contactPersonTwo: values.contactPersonTwo,
        contactNumberTwo: values.contactNumberTwo,
        isContactPersonSendNotification: values.isContactPersonSendNotification,
        isContactPersonTwoSendNotification: values.isContactPersonTwoSendNotification,
        siteRemarks: values.siteRemarks,

        defaultAmountToCollect: (values.isRequirePaymentCollection === false) ? 0 : ((values.amountToCollect !== '') ? values.amountToCollect : 0),
        collectedAmount: values.collectedAmount,
        jobPaymentType: values.paymentMethodId,

        requireStatusToEnd: statusCompleted,

        isRequireReviewWasteType: true,
        numberofPhotosRequired: values.numberofPhotosRequired2,
        mustTakePhoto: values.mustTakePhoto2,
        mustTakeSignature: values.mustTakeSignature,
        isRequirePaymentCollection: values.isRequirePaymentCollection,
      }
      data.push(step2);

    } else if(values.customFormParams.jobTemplateType === JobStepType.Task){
      let step1 = {
        jobStepId: values.customFormParams.jobStepId1,
        stepOrder: 1,
        stepType: JobStepType.Task,
        binActivity: values.customFormParams.binActivity1,
        serviceItemId: serviceItemIds.serviceItemId1,
        isCopyServiceItem: false,

        binTypeId: null,
        binTypeName: '',
        binNumber: '',
        isUsingQr: false,
        isUsingTextDetection: false,
        binWeight: null,

        wasteTypeId: null,
        wasteTypeName: '',
        wasteTypes: [],

        customerSiteId: null,
        address: values.templateStepSites1Address,
        siteName: values.templateStepSites1Name,
        latitude: values.templateStepSites1Latitude,
        longitude: values.templateStepSites1Longitude,
        blockNo: '',
        street: '',
        unitNo: '',
        postalCode: '',

        contactPersonOne: '',
        contactNumberOne: '',
        contactPersonTwo: '',
        contactNumberTwo: '',
        isContactPersonSendNotification: false,
        isContactPersonTwoSendNotification: false,
        siteRemarks: '',

        amountToCollect: '',
        collectedAmount: '',
        jobPaymentType: null,

        requireStatusToEnd: statusStarted,

        isRequireBinNumberToEnd: values.isRequireBinNumberToEnd1,
        isRequireBinNumberToStart: values.isRequireBinNumberToEnd1,
        isRequireReviewWasteType: true,
      }
      data.push(step1);

      let step2 = {
        jobStepId: values.customFormParams.jobStepId2,
        stepOrder: 2,
        stepType: JobStepType.Task,
        binActivity: values.customFormParams.binActivity2,
        serviceItemId: serviceItemIds.serviceItemId2,
        isCopyServiceItem: false,
        
        customerSiteId: values.customerSiteId,
        address: values.customerSiteAddress,
        siteName: (values.customerSiteItem) ? values.customerSiteItem.siteName : '',
        latitude: (values.customerSiteItem) ? values.customerSiteItem.latitude : '',
        longitude: (values.customerSiteItem) ? values.customerSiteItem.longitude : '',
        blockNo: (values.customerSiteItem) ? values.customerSiteItem.blockNo : '',
        street: (values.customerSiteItem) ? values.customerSiteItem.street : '',
        unitNo: (values.customerSiteItem) ? values.customerSiteItem.unitNo : '',
        postalCode: (values.customerSiteItem) ? values.customerSiteItem.postalCode : '',
        
        contactPersonOne: values.contactPersonOne,
        contactNumberOne: values.contactNumberOne,
        contactPersonTwo: values.contactPersonTwo,
        contactNumberTwo: values.contactNumberTwo,
        isContactPersonSendNotification: values.isContactPersonSendNotification,
        isContactPersonTwoSendNotification: values.isContactPersonTwoSendNotification,
        siteRemarks: values.siteRemarks,

        defaultAmountToCollect: (values.isRequirePaymentCollection === false) ? 0 : ((values.amountToCollect !== '') ? values.amountToCollect : 0),
        collectedAmount: values.collectedAmount,
        jobPaymentType: values.paymentMethodId,

        requireStatusToEnd: statusCompleted,

        isRequireReviewWasteType: true,
        numberofPhotosRequired: values.numberofPhotosRequired2,
        mustTakePhoto: values.mustTakePhoto2,
        mustTakeSignature: values.mustTakeSignature,
        isRequirePaymentCollection: values.isRequirePaymentCollection,
      }
      data.push(step2);

    } else if(values.customFormParams.jobTemplateType === JobStepType.Out){
      let step1 = {
        jobStepId: values.customFormParams.jobStepId1,
        stepOrder: 1,
        stepType: JobStepType.Out,
        binActivity: values.customFormParams.binActivity1,
        serviceItemId: serviceItemIds.serviceItemId1,
        isCopyServiceItem: values.stepOutIsCopyService,


        binTypeId: values.stepOutBinTypeId,
        binTypeName: values.stepOutBinTypeName,
        binNumber: (values.stepOutBinNumber) ? values.stepOutBinNumber : '',
        binWeight: null,
        binOutWeight: values.binOutWeight,
        
        wasteTypeId: values.stepOutWasteTypeId,
        wasteTypeName: values.stepOutWasteTypeName,
        wasteTypes: (values.stepOutWasteTypeId) ? [
          {
            wasteTypeId: values.stepOutWasteTypeId,
            order: 0
          }
        ] : [],


        customerSiteId: null,
        address: values.templateStepSites1Address,
        siteName: values.templateStepSites1Name,
        latitude: values.templateStepSites1Latitude,
        longitude: values.templateStepSites1Longitude,
        blockNo: '',
        street: '',
        unitNo: '',
        postalCode: '',

        contactPersonOne: '',
        contactNumberOne: '',
        contactPersonTwo: '',
        contactNumberTwo: '',
        isContactPersonSendNotification: false,
        isContactPersonTwoSendNotification: false,
        siteRemarks: '',

        amountToCollect: '',
        collectedAmount: '',
        jobPaymentType: null,

        requireStatusToEnd: statusStarted,

        isRequireBinWeight: values.isRequireBinWeight,
        isRequireBinNumberToEnd: values.isRequireBinNumberToEnd1,
        isRequireBinNumberToStart: values.isRequireBinNumberToEnd1,
        isRequireReviewWasteType: true,
      }
      data.push(step1);

      let step2 = {
        jobStepId: values.customFormParams.jobStepId2,
        stepOrder: 2,
        stepType: JobStepType.Out,
        binActivity: values.customFormParams.binActivity2,
        serviceItemId: serviceItemIds.serviceItemId2,
        isCopyServiceItem: false,
        
        customerSiteId: values.customerSiteId,
        address: values.customerSiteAddress,
        siteName: (values.customerSiteItem) ? values.customerSiteItem.siteName : '',
        latitude: (values.customerSiteItem) ? values.customerSiteItem.latitude : '',
        longitude: (values.customerSiteItem) ? values.customerSiteItem.longitude : '',
        blockNo: (values.customerSiteItem) ? values.customerSiteItem.blockNo : '',
        street: (values.customerSiteItem) ? values.customerSiteItem.street : '',
        unitNo: (values.customerSiteItem) ? values.customerSiteItem.unitNo : '',
        postalCode: (values.customerSiteItem) ? values.customerSiteItem.postalCode : '',
        
        contactPersonOne: values.contactPersonOne,
        contactNumberOne: values.contactNumberOne,
        contactPersonTwo: values.contactPersonTwo,
        contactNumberTwo: values.contactNumberTwo,
        isContactPersonSendNotification: values.isContactPersonSendNotification,
        isContactPersonTwoSendNotification: values.isContactPersonTwoSendNotification,
        siteRemarks: values.siteRemarks,

        defaultAmountToCollect: (values.isRequirePaymentCollection === false) ? 0 : ((values.amountToCollect !== '') ? values.amountToCollect : 0),
        collectedAmount: values.collectedAmount,
        jobPaymentType: values.paymentMethodId,

        requireStatusToEnd: statusCompleted,

        isRequireReviewWasteType: false,
        numberofPhotosRequired: values.numberofPhotosRequired2,
        mustTakePhoto: values.mustTakePhoto2,
        mustTakeSignature: values.mustTakeSignature,
        isRequirePaymentCollection: values.isRequirePaymentCollection,
      }
      data.push(step2);

    } else if(values.customFormParams.jobTemplateType === JobStepType.Pull){
      let step1 = {
        jobStepId: values.customFormParams.jobStepId1,
        stepOrder: 1,
        stepType: JobStepType.Pull,
        binActivity: values.customFormParams.binActivity1,
        serviceItemId: serviceItemIds.serviceItemId1,
        isCopyServiceItem: values.stepPullIsCopyService,


        binTypeId: values.stepPullBinTypeId,
        binTypeName: values.stepPullBinTypeName,
        binNumber: (values.stepPullBinNumber) ? values.stepPullBinNumber : '',
        binWeight: values.stepBinWeight,
        
        wasteTypeId: (values.stepPullWasteTypeIds && values.stepPullWasteTypeIds.length > 0) ? values.stepPullWasteTypeIds[0] : null,
        wasteTypes: (values.stepPullWasteTypeIds && values.stepPullWasteTypeIds.length > 0) ? values.stepPullWasteTypeIds.map((x, i) => {
          return {
            wasteTypeId: x,
            order: i
          }
        }) : [],


        customerSiteId: values.customerSiteId,
        address: values.customerSiteAddress,
        siteName: (values.customerSiteItem) ? values.customerSiteItem.siteName : '',
        latitude: (values.customerSiteItem) ? values.customerSiteItem.latitude : '',
        longitude: (values.customerSiteItem) ? values.customerSiteItem.longitude : '',
        blockNo: (values.customerSiteItem) ? values.customerSiteItem.blockNo : '',
        street: (values.customerSiteItem) ? values.customerSiteItem.street : '',
        unitNo: (values.customerSiteItem) ? values.customerSiteItem.unitNo : '',
        postalCode: (values.customerSiteItem) ? values.customerSiteItem.postalCode : '',
        
        contactPersonOne: values.contactPersonOne,
        contactNumberOne: values.contactNumberOne,
        contactPersonTwo: values.contactPersonTwo,
        contactNumberTwo: values.contactNumberTwo,
        isContactPersonSendNotification: values.isContactPersonSendNotification,
        isContactPersonTwoSendNotification: values.isContactPersonTwoSendNotification,
        siteRemarks: values.siteRemarks,

        defaultAmountToCollect: (values.isRequirePaymentCollection === false) ? 0 : ((values.amountToCollect !== '') ? values.amountToCollect : 0),
        collectedAmount: values.collectedAmount,
        jobPaymentType: values.paymentMethodId,

        requireStatusToEnd: statusInProgress,

        isRequireBinNumberToEnd: values.isRequireBinNumberToEnd2,
        isRequireBinNumberToStart: values.isRequireBinNumberToEnd2,
        isRequireReviewWasteType: true,
        numberofPhotosRequired: values.numberofPhotosRequired1,
        mustTakePhoto: values.mustTakePhoto1,
        mustTakeSignature: values.mustTakeSignature,
        isRequirePaymentCollection: values.isRequirePaymentCollection,
      }
      data.push(step1);

      let step2 = {
        jobStepId: values.customFormParams.jobStepId2,
        stepOrder: 2,
        stepType: JobStepType.Pull,
        binActivity: values.customFormParams.binActivity2,
        serviceItemId: serviceItemIds.serviceItemId2,
        isCopyServiceItem: false,
        
        customerSiteId: null,
        address: values.templateStepSites1Address,
        siteName: values.templateStepSites1Name,
        latitude: values.templateStepSites1Latitude,
        longitude: values.templateStepSites1Longitude,
        blockNo: '',
        street: '',
        unitNo: '',
        postalCode: '',

        contactPersonOne: '',
        contactNumberOne: '',
        contactPersonTwo: '',
        contactNumberTwo: '',
        isContactPersonSendNotification: false,
        isContactPersonTwoSendNotification: false,
        siteRemarks: '',

        amountToCollect: '',
        collectedAmount: '',
        jobPaymentType: null,

        requireStatusToEnd: statusCompleted,

        isRequireReviewWasteType: values.isRequireReviewWasteType,
        isRequireBinWeight: values.isRequireBinWeight,
        numberofPhotosRequired: values.numberofPhotosRequired2,
        mustTakePhoto: values.mustTakePhoto2,
      }
      data.push(step2);
    }

    let steps = data.sort((a, b) => a.stepOrder - b.stepOrder);
    return steps;
  }
  prepareRecurringData = (values) => {
    let recurringStartDate = moment(values.recurringStartDate).format('YYYY-MM-DDTHH:mm:ss')
    let recurringEndDate = moment(values.recurringEndDate).format('YYYY-MM-DDTHH:mm:ss')

    let newDefaultTime = Utils.convertDateTimeByDate(Utils.getTZDateFromSettings(), values.recurringDefaultTime);
    let recurringDefaultTime = moment(newDefaultTime).format('YYYY-MM-DDTHH:mm:ss')

    let schedules = [];
    if(values.schedules && values.schedules.length > 0){
      values.schedules.forEach((item, i) => {
        if(values.repeatOrder === RepeatOrder.Monthly){
          schedules.push({
            day: item.day,
            date: item.date
          })
        } else if(values.repeatOrder === RepeatOrder.Weekly){
          let newItemTime = Utils.convertDateTimeByDate(Utils.getTZDateFromSettings(), item.time);
          let itemTime = moment(newItemTime).format('YYYY-MM-DDTHH:mm:ss')
          
          if(item.isChecked){
            schedules.push({
              day: item.day,
              time: itemTime
            })
          }
        }
      });
    }

    let data = {
      repeatOrder: values.repeatOrder,
      recurringJobName: values.recurringJobName,
      recurringJobDescription: values.recurringJobDescription,
      // assignToSameDriverVehicle: values.recurringAssignToSameDriverVehicle,
      // numberOfTrip: values.recurringNumberOfTrip,
      recurringType: values.recurringType,
      startDate: recurringStartDate,
      endDate: recurringEndDate,
      defaultTime: recurringDefaultTime,
      driverId: values.driverId,
      vehicleId: values.vehicleId,
      schedules: schedules,
    };

    return (values.isRecurring) ? data : null;
  }
  
  prepareCustomerFromParams = (data) => {
    if(data){
      let values = this.state.rowValues;

      let customerId = (data && data.customerId) ? data.customerId : null;
      let customerName = (data && data.customerNameDisplay) ? data.customerNameDisplay : '';
      let customerItems = (data) ? [{
        value: customerId,
        title: customerName,
        item: data,
      }] : [];

      let billingProfile = (data && data.billingProfile) ? data.billingProfile : null;
      let quickBookApp = (billingProfile && billingProfile.quickBookApp) ? billingProfile.quickBookApp : null;
      let isConnectQuickbook = (billingProfile && billingProfile.isConnectQuickbook) ? billingProfile.isConnectQuickbook : false;
      let isConnectXero = (billingProfile && billingProfile.isConnectXero) ? billingProfile.isConnectXero : false;
      let quickBookAppId = (quickBookApp && quickBookApp.quickBookAppId) ? quickBookApp.quickBookAppId : 0;
      let accountBillingProfileId = (billingProfile && billingProfile.accountBillingProfileId) ? billingProfile.accountBillingProfileId : null;
      let billingProfileName = (billingProfile && billingProfile.billingProfileName) ? billingProfile.billingProfileName : '';
      let quickBookDefaultTermId = (data && data.quickBookDefaultTermId) ? data.quickBookDefaultTermId : null;
      let paymentTerms = (data && data.paymentTerms) ? data.paymentTerms : '';
      let xeroPaymentTerm = (data && data.xeroPaymentTerm) ? data.xeroPaymentTerm : '';
      let xeroPaymentTermType = (data && data.xeroPaymentTermType) ? data.xeroPaymentTermType.toString() : '0';
      let note = (data && data.note) ? data.note : '';

      let billingProfileItem = null;
      if(billingProfile){
          billingProfileItem = {
              value: accountBillingProfileId,
              title: billingProfileName,
              item: billingProfile,
          };
      }
      let accountBillingProfileItems = (billingProfileItem) ? [billingProfileItem] : [];

      let paymentTermsItem = null;
      if(billingProfile){
          paymentTermsItem = {
              value: quickBookDefaultTermId,
              title: paymentTerms,
              item: data,
          };
      }
      let paymentTermsItems = (paymentTermsItem) ? [paymentTermsItem] : [];
      

      let newValues = {
          ...values,

          customerId: customerId,
          customerName: customerName,
          customerItem: data,

          accountBillingProfileId: accountBillingProfileId,
          accountBillingProfileName: billingProfileName,
          paymentTermsId: quickBookDefaultTermId,
          paymentTermsName: paymentTerms,
          xeroPaymentTerm: xeroPaymentTerm,
          xeroPaymentTermType: xeroPaymentTermType,
          customerNote: note,

          detailsPOD: null,
          fromPOD: '',
          subjectPOD: '',
          bodyPOD: '',
          includeDeliveryOrderPOD: false,
          includePhotosPOD: false,
          includeWeighChitPOD: false,
          includeAttachmentPOD: true,

          customFormParams: {
              ...values.customFormParams,

              customerItems: customerItems,
              accountBillingProfileItems: accountBillingProfileItems,
              paymentTermsItems: paymentTermsItems,
              isConnectQuickbook: isConnectQuickbook,
              isConnectXero: isConnectXero,
              quickBookAppId: quickBookAppId,
          },
      };

      return newValues;
    }
  }
  prepareJobTemplateFromParams = (details) => {
    if(details){
      let values = this.state.rowValues;

      let binActivity1 = null;
      let binActivity2 = null;
      let binActivity3 = null;

      let jobTemplateType = null;
      let templateStep = (details && details.templateSteps && details.templateSteps.length > 0) ? details.templateSteps[0] : null;
      if(templateStep){
          jobTemplateType = templateStep.stepType
      }

      let jobTemplateStep1Id = null;
      let isDefaultLocation1 = false;
      let templateStepSites1Items = [];
      let templateStepSites1Id = null;
      let templateStepSites1Name = '';
      let templateStepSites1Address = '';
      let templateStepSites1Latitude = null;
      let templateStepSites1Longitude = null;

      let jobTemplateStep2Id = null;
      let isDefaultLocation2 = false;
      let templateStepSites2Items = [];
      let templateStepSites2Id = null;
      let templateStepSites2Name = '';
      let templateStepSites2Address = '';
      let templateStepSites2Latitude = null;
      let templateStepSites2Longitude = null;

      let isRequireBinNumberToEnd1 = false;
      let isRequireBinNumberToEnd2 = false;
      let mustTakePhoto1 = '0';
      let numberofPhotosRequired1 = '0';
      let mustTakeSignature = false;
      let isRequirePaymentCollection = false;
      let isRequireBinWeight = false;
      let isRequireReviewWasteType = false;
      let mustTakePhoto2 = '0';
      let numberofPhotosRequired2 = '0';

      let jobDurationHours = details && details.jobDurationHours && details.jobDurationHours.toString() !== "" ? details.jobDurationHours.toString() : "0";
      let jobDurationMinutes = details && details.jobDurationMinutes && details.jobDurationMinutes.toString() !== "" ? details.jobDurationMinutes.toString() : "0";
      let duration = Utils.getDuration(jobDurationHours, jobDurationMinutes);


      if(details && details.templateSteps && details.templateSteps.length > 0){
          let steps = details.templateSteps.sort((a, b) => a.stepOrder - b.stepOrder);

          let step1 = (steps && steps.length > 0) ? steps[0] : null;
          let step2 = (steps && steps.length >= 2) ? steps[1] : null;
          let step3 = (steps && steps.length >=3) ? steps[2] : null;

          if (jobTemplateType === JobStepType.Pull) {
              if (step1) {
                  isRequireBinNumberToEnd2 = step1.isRequireBinNumberToEnd;

                  numberofPhotosRequired1 = step1.numberofPhotosRequired;
                  mustTakePhoto1 = step1.mustTakePhoto;
                  mustTakeSignature = step1.mustTakeSignature;
              }

              if (step2) {
                  numberofPhotosRequired2 = step2.numberofPhotosRequired;
                  mustTakePhoto2 = step2.mustTakePhoto;

                  isRequireBinWeight = step2.isRequireBinWeight;
                  isRequireReviewWasteType = step2.isRequireReviewWasteType;
              }
            } else if (jobTemplateType === JobStepType.Put) {
              if (step1) {
                  isRequireBinNumberToEnd1 = step1.isRequireBinNumberToEnd;
              }

              if (step2) {
                  numberofPhotosRequired2 = step2.numberofPhotosRequired;
                  mustTakePhoto2 = step2.mustTakePhoto;

                  mustTakeSignature = step2.mustTakeSignature;
              }
            } else if (jobTemplateType === JobStepType.Task) {
              if (step2) {
                  numberofPhotosRequired2 = step2.numberofPhotosRequired;
                  mustTakePhoto2 = step2.mustTakePhoto;

                  mustTakeSignature = step2.mustTakeSignature;
              }
            } else if (jobTemplateType === JobStepType.Out) {
              if (step1) {
                  isRequireBinNumberToEnd1 = step1.isRequireBinNumberToEnd;
                  isRequireBinWeight = step1.isRequireBinWeight;
              }

              if (step2) {
                  numberofPhotosRequired2 = step2.numberofPhotosRequired;
                  mustTakePhoto2 = step2.mustTakePhoto;

                  // mustTakeSignature = step2.mustTakeSignature;
              }
            } else if (jobTemplateType === JobStepType.Shift) {
              if (step1) {
                  isRequireBinNumberToEnd2 = step1.isRequireBinNumberToEnd;

                  numberofPhotosRequired1 = step1.numberofPhotosRequired;
                  mustTakePhoto1 = step1.mustTakePhoto;
                  mustTakeSignature = step1.mustTakeSignature;
              }

              if (step2) {
                  numberofPhotosRequired2 = step2.numberofPhotosRequired;
                  mustTakePhoto2 = step2.mustTakePhoto;
              }
            } else {
              if (jobTemplateType !== JobStepType.Pull) {
                if (step1) {
                  isRequireBinNumberToEnd1 = step1.isRequireBinNumberToEnd;
                }
              }

              if ((jobTemplateType !== JobStepType.Put) && (jobTemplateType !== JobStepType.Task) && (jobTemplateType !== JobStepType.Out)) {
                if (
                  jobTemplateType === JobStepType.Pull ||
                  jobTemplateType === JobStepType.Exchange
                ) {
                  if (step2) {
                      isRequireBinNumberToEnd2 = step2.isRequireBinNumberToEnd;
                  }
                }

                if (step2) {
                  numberofPhotosRequired1 = step2.numberofPhotosRequired;
                  mustTakePhoto1 = step2.mustTakePhoto;
                  mustTakeSignature = step2.mustTakeSignature;
                }
              }

              if (step3) {
                  numberofPhotosRequired2 = step3.numberofPhotosRequired;
                  mustTakePhoto2 = step3.mustTakePhoto;

                  isRequireBinWeight = step3.isRequireBinWeight;
                  isRequireReviewWasteType = step3.isRequireReviewWasteType;
              }
          }


          for(let i = 0; i < steps.length; i++){
              let item = steps[i];

              if(i === 0){
                  binActivity1 = item.binActivity;
              } else if(i === 1){
                  binActivity2 = item.binActivity;
              } else if(i === 2){
                  binActivity3 = item.binActivity;
              }
          }
      }

      if(details && details.templateSteps && details.templateSteps.length > 0){
          let templateSteps = details.templateSteps.filter(x => x.hasCustomerSite === false).sort((a, b) => a.stepOrder - b.stepOrder);
          
          if(templateSteps && templateSteps.length > 0){
              templateSteps.forEach((item, i) => {
                  if(i === 0){
                      isDefaultLocation1 = item.hasDefaultSite;
                      jobTemplateStep1Id = item.jobTemplateStepId;

                      if(item.hasDefaultSite){
                          let defaultItems = item.templateStepSites.filter(x => x.isDefault === true);
                          if(defaultItems && defaultItems.length > 0){
                              let defaultItem = defaultItems[0];

                              templateStepSites1Items = (defaultItem) ? [{
                                  value: defaultItem.jobTemplateStepSiteId,
                                  title: defaultItem.stepSiteName,
                                  item: defaultItem,
                              }] : [];
                              
                              templateStepSites1Id = defaultItem.jobTemplateStepSiteId;
                              templateStepSites1Name = defaultItem.stepSiteName;
                              templateStepSites1Address = defaultItem.stepSiteAddress;
                              templateStepSites1Latitude = defaultItem.stepSiteLatitude;
                              templateStepSites1Longitude = defaultItem.stepSiteLongitude;
                          }
                      }
                  } else {
                      isDefaultLocation2 = item.hasDefaultSite;
                      jobTemplateStep2Id = item.jobTemplateStepId;
                      
                      if(item.hasDefaultSite){
                          let defaultItems = item.templateStepSites.filter(x => x.isDefault === true);
                          if(defaultItems && defaultItems.length > 0){
                              let defaultItem = defaultItems[0];
                              
                              templateStepSites2Items = (defaultItem) ? [{
                                  value: defaultItem.jobTemplateStepSiteId,
                                  title: defaultItem.stepSiteName,
                                  item: defaultItem,
                              }] : [];
                              
                              templateStepSites2Id = defaultItem.jobTemplateStepSiteId;
                              templateStepSites2Name = defaultItem.stepSiteName;
                              templateStepSites2Address = defaultItem.stepSiteAddress;
                              templateStepSites2Latitude = defaultItem.stepSiteLatitude;
                              templateStepSites2Longitude = defaultItem.stepSiteLongitude;
                          }
                      }
                  }
              });
          }
      }


      let statusItems = [];
      if (details && details.templateStatuses && details.templateStatuses.length) {
          let stat = details.templateStatuses.map((item, i) => {
              return item.templateStatus;
          });

          let status = Utils.sortStatus(stat);

          statusItems = status.map((item, i) => {
              return {
                  value: item.jobStatusId,
                  title: item.jobStatusName,
                  disabled: false,
                  item: item,
              };
          });
      }
      
      let disabledStatusItems = Utils.setStatusDisable(statusItems, values.currentStatusId, values.driverId, values.isEdit, values.isCopy, jobTemplateType);
      
      let paymentMethodItems = [];
      if (details && details.jobPaymentTypeList && details.jobPaymentTypeList.length) {
          paymentMethodItems = details.jobPaymentTypeList.map((item, i) => {
              return {
                  value: i,
                  title: item,
              };
          });
      }


      let markers = Utils.getJobFormLocationMarkers({
          customerSiteId: values.customerSiteId,
          customerSiteItems: values.customFormParams.customerSiteItems,
          customerSiteShiftId: values.customerSiteShiftId,
          customerSiteShiftItems: values.customFormParams.customerSiteShiftItems,
          templateStepSites1Latitude: templateStepSites1Latitude,
          templateStepSites1Longitude: templateStepSites1Longitude,
          templateStepSites2Latitude: templateStepSites2Latitude,
          templateStepSites2Longitude: templateStepSites2Longitude,
          jobTemplateType: jobTemplateType
      }, values)

      let newValues = {
          ...values,

          jobTemplateId: details.jobTemplateId,
          jobTemplateName: details.jobTemplateName,

          jobTemplateType: jobTemplateType,
          isBillable: details.isBillable,

          templateStepSites1Id: templateStepSites1Id,
          templateStepSites1Name: templateStepSites1Name,
          templateStepSites1Address: templateStepSites1Address,
          templateStepSites1Latitude: templateStepSites1Latitude,
          templateStepSites1Longitude: templateStepSites1Longitude,

          templateStepSites2Id: templateStepSites2Id,
          templateStepSites2Name: templateStepSites2Name,
          templateStepSites2Address: templateStepSites2Address,
          templateStepSites2Latitude: templateStepSites2Latitude,
          templateStepSites2Longitude: templateStepSites2Longitude,

          isEditableBinTypeOut: details.isEditableBinTypeOut,
          isEditableWasteTypeOut: details.isEditableWasteTypeOut,
          isEditableBinNumberOut: details.isEditableBinNumberOut,
          isEditableBinNumberIn: details.isEditableBinNumberIn,
          isEditableWasteTypeIn: details.isEditableWasteTypeIn,
          duration: duration,

          isRequireBinNumberToEnd1: isRequireBinNumberToEnd1,
          isRequireBinNumberToEnd2: isRequireBinNumberToEnd2,
          mustTakePhoto1: mustTakePhoto1,
          numberofPhotosRequired1: numberofPhotosRequired1,
          mustTakeSignature: mustTakeSignature,
          isRequirePaymentCollection: isRequirePaymentCollection,
          isRequireBinWeight: isRequireBinWeight,
          isRequireReviewWasteType: isRequireReviewWasteType,
          mustTakePhoto2: mustTakePhoto2,
          numberofPhotosRequired2: numberofPhotosRequired2,

          customFormParams: {
              ...values.customFormParams,

              jobTemplateDetails: details,
              jobTemplateIsLoading: false,
              jobTemplateType: jobTemplateType,

              binActivity1: binActivity1,
              binActivity2: binActivity2,
              binActivity3: binActivity3,

              jobTemplateStep1Id: jobTemplateStep1Id,
              isDefaultLocation1: isDefaultLocation1,
              templateStepSites1Items: templateStepSites1Items,

              jobTemplateStep2Id: jobTemplateStep2Id,
              isDefaultLocation2: isDefaultLocation2,
              templateStepSites2Items: templateStepSites2Items,

              statusItems: statusItems,
              disabledStatusItems: disabledStatusItems,
              paymentMethodItems: paymentMethodItems,

              totalDistance: 0,
              markers: markers,
          },
      };

      if(!values.isEdit && values.statusId === null){
          let statusUnassigned = Utils.findStatus(statusItems, "title", "unassigned");
          if(statusUnassigned && statusUnassigned.value) {
              let statusId = (statusUnassigned.value) ? statusUnassigned.value : null;
              let statusName = (statusUnassigned.title) ? statusUnassigned.title : '';
              
              newValues['statusId'] = statusId;
              newValues['statusName'] = statusName;
          }
      }

      return newValues;
    }
  }
  prepareCustomerSiteFromParams = (details) => {
    if(details){
      let values = this.state.rowValues;

      let customerSiteId = (details && details.customerSiteId) ? details.customerSiteId : null;
      let customerSiteName = (details && details.siteNameDisplay) ? details.siteNameDisplay : '';
      let customerSiteItems = (details) ? [{
        value: customerSiteId,
        title: customerSiteName,
        item: details,
      }] : [];

      let latitude = (details && details.latitude && details.latitude != '') ? details.latitude : '';
      let longitude = (details && details.longitude && details.longitude != '') ? details.longitude : '';
      let hasCoordCustomerSite = ((latitude && latitude != '') && (longitude && longitude != '')) ? true : false;

      let markers = Utils.getJobFormLocationMarkers({
          customerSiteId: customerSiteId,
          customerSiteItems: values.customFormParams.customerSiteItems,
          customerSiteShiftId: values.customerSiteShiftId,
          customerSiteShiftItems: values.customFormParams.customerSiteShiftItems,
          templateStepSites1Latitude: values.templateStepSites1Latitude,
          templateStepSites1Longitude: values.templateStepSites1Longitude,
          templateStepSites2Latitude: values.templateStepSites2Latitude,
          templateStepSites2Longitude: values.templateStepSites2Longitude,
          jobTemplateType: values.customFormParams.jobTemplateType
      }, values)
      
      let obj = details;
      let remarks = (obj.remarks && obj.remarks !== '') ? obj.remarks : '';
      let instructions = (obj.instructions && obj.instructions !== '') ? obj.instructions : '';
      let contactPersonName = (obj.contactPersonName && obj.contactPersonName !== '') ? obj.contactPersonName : '';
      let contactPersonPhoneNumber = (obj.contactPersonPhoneNumber && obj.contactPersonPhoneNumber !== '') ? obj.contactPersonPhoneNumber : '';
      let contactPersonNameTwo = (obj.contactPersonNameTwo && obj.contactPersonNameTwo !== '') ? obj.contactPersonNameTwo : '';
      let contactPersonPhoneNumberTwo = (obj.contactPersonPhoneNumberTwo && obj.contactPersonPhoneNumberTwo !== '') ? obj.contactPersonPhoneNumberTwo : '';
      
      let isContactPersonSendNotification = ((obj.isContactPersonSendNotification === false) || (obj.isContactPersonSendNotification === true)) ? obj.isContactPersonSendNotification : false;
      let isContactPersonTwoSendNotification = ((obj.isContactPersonTwoSendNotification === false) || (obj.isContactPersonTwoSendNotification === true)) ? obj.isContactPersonTwoSendNotification : false;

      let weekdaysAvailability = (obj && obj.weekdaysAvailability) ? obj.weekdaysAvailability : CustomerSiteAvailability.All;
      let weekendAvailability = (obj && obj.weekendAvailability) ? obj.weekendAvailability : CustomerSiteAvailability.All;
      
      let vehicleItems = values.customFormParams.vehicleItems;
      let vehicleId = null;
      let vehicleName = '';

      let driverItems = values.customFormParams.driverItems;
      let driverId = null;
      let driverName = '';
      
      let newStatus = null;
      let statusId = null;
      let statusName = '';

      let defaultVehicles = (obj.defaultVehicles && obj.defaultVehicles.length > 0) ? obj.defaultVehicles : [];
      if(defaultVehicles && defaultVehicles.length > 0){
          let defArr = defaultVehicles.filter(x => x.defaultDriver !== null);
          if(defArr && defArr.length > 0){
              let vehicleItem = defArr[0];
              vehicleId = (vehicleItem.vehicleId && vehicleItem.vehicleId > 0) ? vehicleItem.vehicleId : null;
              vehicleName = (vehicleItem.vehicleName && vehicleItem.vehicleName !== '') ? vehicleItem.vehicleName : '';
              
              if(values.vehicleId === null || values.vehicleId <= 0){
                  let arrItem = {
                    value: vehicleId,
                    title: vehicleName,
                    item: vehicleItem,
                  };
                  vehicleItems = Utils.addToArray(vehicleItems, vehicleId, arrItem);
              }

              let driverItem = defArr[0].defaultDriver;
              driverId = (driverItem && driverItem.driverId && driverItem.driverId > 0) ? driverItem.driverId : null;
              driverName = (driverItem && driverItem.driverName && driverItem.driverName !== '') ? driverItem.driverName : '';
              
              if(values.driverId === null || values.driverId <= 0){
                  let arrItem = {
                    value: driverId,
                    title: driverName,
                    item: driverItem,
                  };
                  driverItems = Utils.addToArray(driverItems, driverId, arrItem);

                  try {
                    let selectedStatus = Utils.findStatus(values.customFormParams.statusItems, 'title','unassigned');
                    if (values.statusId === null || values.statusId === 0 || values.statusId === selectedStatus.value) {
                      newStatus = Utils.findStatus(values.customFormParams.statusItems, 'title','assigned');
                      statusId = newStatus.value;
                      statusName = newStatus.title;
                    }
                  }catch(e){}
              }
          }
      }

      let newValues = {
        ...values,

        customerSiteId: customerSiteId,
        customerSiteName: customerSiteName,
        hasCoordCustomerSite: hasCoordCustomerSite,
        customerSiteItem: details,
        customerSiteAddress: Utils.formatCustomerSiteAddress(details),

        vehicleId: vehicleId,
        vehicleName: vehicleName,
        
        driverId: driverId,
        driverName: driverName,
        
        statusId: statusId,
        statusName: statusName,

        remarks: remarks,
        siteRemarks: instructions,
        contactPersonOne: contactPersonName,
        contactNumberOne: contactPersonPhoneNumber,
        contactPersonTwo: contactPersonNameTwo,
        contactNumberTwo: contactPersonPhoneNumberTwo,
        isContactPersonSendNotification: isContactPersonSendNotification,
        isContactPersonTwoSendNotification: isContactPersonTwoSendNotification,

        customFormParams: {
          ...values.customFormParams,

          customerSiteItems: customerSiteItems,
          markers: markers,
          vehicleItems: vehicleItems,
          driverItems: driverItems,
          weekdaysAvailability: weekdaysAvailability,
          weekendAvailability: weekendAvailability,
        },
      };

      return newValues;
    }
  }
  prepareServiceCustomerSiteFromParams = (details) => {
    if(details){
      let values = this.state.rowValues;

      let serviceId = (details && details.serviceId) ? details.serviceId : null;
      let serviceName = (details && details.serviceName) ? details.serviceName : '';


      let newValues = {
        ...values,

        serviceId: serviceId,
        serviceName: serviceName,
        
        customFormParams: {
          ...values.customFormParams,
        },
      };

      return newValues;
    }
  }
  
  getServiceItemId = (values) => {
    let data = {
      serviceItemId1: null,
      serviceItemId2: null,
      serviceItemId3: null
    };
    
    if(values.customFormParams.jobTemplateType === JobStepType.Exchange){
      data.serviceItemId1 = values.stepExchangeOutServiceItemId;
      data.serviceItemId2 = values.stepExchangeInServiceItemId;
    } else if(values.customFormParams.jobTemplateType === JobStepType.OnTheSpot){
      data.serviceItemId1 = values.stepOnTheSpotServiceItemId;
    } else if(values.customFormParams.jobTemplateType === JobStepType.Shift){
      data.serviceItemId1 = values.stepShiftServiceItemId;
    } else if(values.customFormParams.jobTemplateType === JobStepType.Put){
      data.serviceItemId1 = values.stepPutServiceItemId;
    } else if(values.customFormParams.jobTemplateType === JobStepType.Pull){
      data.serviceItemId1 = values.stepPullServiceItemId;
    }

    return data;
  }
  getServiceItem = (bTId, wTId, data) => {
    let standardItems = [];
    if(data && data.charges && data.charges.length > 0) {
      standardItems = data.charges.filter(x => (x.binTypeId == bTId) && (x.wasteTypeId == wTId))
    }
    
    let arr = [];
    if(standardItems && standardItems.length > 0) {
        for (var i = 0; i < standardItems.length; i++) {
            let item = standardItems[i];
            
            let binTypeId = item.binTypeId;
            let binTypeName = (item.binTypeName) ? item.binTypeName : '';
            let wasteTypeId = item.wasteTypeId;
            let wasteTypeName = (item.wasteTypeName) ? item.wasteTypeName : '';
            let serviceTag = (item.serviceTag) ? item.serviceTag : '';
            let binOutDate = (item.binOutDate) ? item.binOutDate : '';
            let binNumber = (item.binNumber) ? item.binNumber : '';
            let driverName = (item.driverName) ? item.driverName : '';
            let vehicleName = (item.vehicleName) ? item.vehicleName : '';
            let jobNumber = (item.jobNumber) ? item.jobNumber : '';
            let isBundled = ((item.isBundled === true) || (item.isBundled === false)) ? item.isBundled : false;
            let additionalCharges = (item.additionalCharges && item.additionalCharges.length > 0) ? item.additionalCharges : [];
            let value = Utils.makeIDfromString(((item.serviceItemId) ? item.serviceItemId + "_" : "") + item.binTypeId + "_" + binTypeName + "_" + item.wasteTypeId + "_" + wasteTypeName + "_" + serviceTag);
            
            let arrItem = {
                value: value,
                item: item,
                binTypeId: binTypeId,
                binTypeName: binTypeName,
                wasteTypeId: wasteTypeId,
                wasteTypeName: wasteTypeName,
                serviceTag: serviceTag,
                binOutDate: binOutDate,
                binNumber: binNumber,
                driverName: driverName,
                vehicleName: vehicleName,
                jobNumber: jobNumber,
                isBundled: isBundled,
                additionalCharges: additionalCharges,
            };
            
            arr = Utils.addToArray(arr, value, arrItem);
        }
    }

    let charges = arr.sort((a, b) => b.additionalCharges.length - a.additionalCharges.length);
    
    let backDataItem = null;
    if(charges && charges.length > 0){
        let chargeWithBundles = charges.filter(x => x.additionalCharges && x.additionalCharges.length > 0);
        if(chargeWithBundles && chargeWithBundles.length > 0){
            backDataItem = chargeWithBundles[0];
        } else {
            backDataItem = charges[0];
        }
    }


    return backDataItem;
  }
  isCompletedJob = () => {
    if(this.refFormik && this.refFormik.current){
      let values = this.refFormik.current.values;
      // let currentStatusName = (values && values.currentStatusName && values.currentStatusName != '') ? values.currentStatusName : '';
      let statusName = (values && values.statusName && values.statusName != '') ? values.statusName : '';
      
      return (statusName.toLowerCase() == 'completed') ? true : false;
    } else {
      return false;
    }
  }

  updateBilling = async () => {
    if(this.refFormik && this.refFormik.current){
      let values = this.refFormik.current.values;
      let valuesBilling = this.refFormDrawerBilling.current.values;
      
      if(!valuesBilling.isBillable) {
        await this.refFormDrawerBilling.current.setFieldValue('isBillable', true);
      }

      this.callServiceCustomerSiteDetailsApi(values.customerId, async (serviceCustomerSiteDetails) => {
        await this.updateBillingPricings(serviceCustomerSiteDetails);
      });
    }
  }
  updateBillingPricings = async (serviceCustomerSiteDetails) => {
    let valuesBilling = this.refFormDrawerBilling.current.values;
    let prices1 = (valuesBilling.pricings && valuesBilling.pricings.length > 0) ? valuesBilling.pricings : [];
    let chargeCategoryItems = (this.state.rowInitialValuesBilling && this.state.rowInitialValuesBilling.customFormParams && this.state.rowInitialValuesBilling.customFormParams.chargeCategoryItems && this.state.rowInitialValuesBilling.customFormParams.chargeCategoryItems.length > 0) ? this.state.rowInitialValuesBilling.customFormParams.chargeCategoryItems : [];

    let qty = '';

    let prices2 = [];
    let binWaste = this.getBinWasteItem();
    if(binWaste && binWaste.wasteTypeId && binWaste.wasteTypeId.length > 0){
      binWaste.wasteTypeId.forEach((item, x) => {
        let index = prices1.findIndex(x => ((x.binTypeId == binWaste.binTypeId) && (x.wasteTypeId == item) && Utils.isStandardService(x)));
        let sTag = (index > -1) ? prices1[index].serviceTag : '';
        
        let serviceItem = (prices1 && prices1.length > 0) ? (binWaste.serviceItemId) ? Utils.getServiceTagItemByServiceItem(binWaste.serviceItemId, sTag, binWaste.binTypeId, item, serviceCustomerSiteDetails) : Utils.getServiceTagItem(sTag, binWaste.binTypeId, item, serviceCustomerSiteDetails) : Utils.getServiceItem(binWaste.binTypeId, item, serviceCustomerSiteDetails);
        let pricingObject = Utils.getPricings(chargeCategoryItems, serviceItem);
        let prices = (pricingObject && pricingObject.pricings && pricingObject.pricings.length > 0) ? pricingObject.pricings : [];
        chargeCategoryItems = (pricingObject && pricingObject.chargeCategoryItems && pricingObject.chargeCategoryItems.length > 0) ? pricingObject.chargeCategoryItems : [];
        
        let additionalCharges = (serviceItem && serviceItem.additionalCharges && serviceItem.additionalCharges.length > 0) ? serviceItem.additionalCharges : [];
        qty = Utils.getNonBillableWeight(additionalCharges);

        prices2.push(prices);
      });
    }

    let newPrices2 = _.flattenDeep(prices2);
    let newPricings = Utils.comparePricingsArrays(prices1, newPrices2, (item) => {
      return binWaste.wasteTypeId.includes(item.wasteTypeId) ? true : false;
    });
    newPricings = Utils.removeDuplicatedStandardPricings(newPricings);

    let newPricingsWithQty = Utils.setQtyAtMainPricings(newPricings, this.refFormik.current.values.weighChitBillableWeight);
    let newSortedPricingsWithQty = newPricingsWithQty.sort((a, b) => a.serviceType - b.serviceType);
    let newSortedPricingsWithQtyAndStepId = Utils.setStepIdPricings(newSortedPricingsWithQty, prices1);
      
    await this.refFormDrawerBilling.current.setFieldValue('pricings', newSortedPricingsWithQtyAndStepId);
    await this.refFormDrawerBilling.current.setFieldValue('customFormParams.chargeCategoryItems', chargeCategoryItems);

    // if(this.refFormik && this.refFormik.current){
    //   await this.refFormik.current.setFieldValue('nonBillableBinWeight', qty);

    //   try {
    //     let nettWeightVal = Utils.parseFloat(this.refFormik.current.values.weighChitNettWeight);

    //     if(nettWeightVal != '' && qty != ''){
    //       let nonBillableBinWeightVal = Utils.parseFloat(qty);
    //       let billableWeight = nettWeightVal - nonBillableBinWeightVal;
    //       billableWeight = billableWeight.toFixed(3)

    //       await this.refFormik.current.setFieldValue('weighChitBillableWeight', billableWeight);
    //     }
    //   } catch(e){}
    // }
  }
  getBinWasteItem = () => {
    let data = {
      binTypeId: null,
      wasteTypeId: null,
    };

    if(this.refFormik && this.refFormik.current){
      let values = this.refFormik.current.values;

      if(values.customFormParams.jobTemplateType === JobStepType.Exchange){
        let binTypeId = (values && values.stepExchangeInBinTypeId) ? values.stepExchangeInBinTypeId : null;
        let wasteTypeId = (values && values.stepExchangeInWasteTypeId) ? values.stepExchangeInWasteTypeId : null;
        let serviceItemId = (values && values.stepExchangeInServiceItemId) ? values.stepExchangeInServiceItemId : null;
        
        let wasteTypeIds = (values && values.stepExchangeInWasteTypeIds && values.stepExchangeInWasteTypeIds.length > 0) ? values.stepExchangeInWasteTypeIds : null;
        wasteTypeId = (wasteTypeIds && wasteTypeIds.length > 0) ? wasteTypeIds : [wasteTypeId];

        data = {
          binTypeId: binTypeId,
          wasteTypeId: wasteTypeId,
          serviceItemId: serviceItemId,
        };
      } else if(values.customFormParams.jobTemplateType === JobStepType.OnTheSpot){
        let binTypeId = (values && values.stepOnTheSpotBinTypeId) ? values.stepOnTheSpotBinTypeId : null;
        let wasteTypeId = (values && values.stepOnTheSpotWasteTypeId) ? values.stepOnTheSpotWasteTypeId : null;
        let serviceItemId = (values && values.stepOnTheSpotServiceItemId) ? values.stepOnTheSpotServiceItemId : null;
        
        let wasteTypeIds = (values && values.stepOnTheSpotWasteTypeIds && values.stepOnTheSpotWasteTypeIds.length > 0) ? values.stepOnTheSpotWasteTypeIds : null;
        wasteTypeId = (wasteTypeIds && wasteTypeIds.length > 0) ? wasteTypeIds : [wasteTypeId];

        data = {
          binTypeId: binTypeId,
          wasteTypeId: wasteTypeId,
          serviceItemId: serviceItemId,
        };
        
      } else if(values.customFormParams.jobTemplateType === JobStepType.Shift){
        let binTypeId = (values && values.stepShiftBinTypeId) ? values.stepShiftBinTypeId : null;
        let wasteTypeId = (values && values.stepShiftWasteTypeId) ? values.stepShiftWasteTypeId : null;
        let serviceItemId = (values && values.stepShiftServiceItemId) ? values.stepShiftServiceItemId : null;
        
        let wasteTypeIds = (values && values.stepShiftWasteTypeIds && values.stepShiftWasteTypeIds.length > 0) ? values.stepShiftWasteTypeIds : null;
        wasteTypeId = (wasteTypeIds && wasteTypeIds.length > 0) ? wasteTypeIds : [wasteTypeId];

        data = {
          binTypeId: binTypeId,
          wasteTypeId: wasteTypeId,
          serviceItemId: serviceItemId,
        };
        
      } else if(values.customFormParams.jobTemplateType === JobStepType.Put){
        let binTypeId = (values && values.stepPutBinTypeId) ? values.stepPutBinTypeId : null;
        let wasteTypeId = (values && values.stepPutWasteTypeId) ? values.stepPutWasteTypeId : null;
        let serviceItemId = (values && values.stepPutServiceItemId) ? values.stepPutServiceItemId : null;
        
        let wasteTypeIds = (values && values.stepPutWasteTypeIds && values.stepPutWasteTypeIds.length > 0) ? values.stepPutWasteTypeIds : null;
        wasteTypeId = (wasteTypeIds && wasteTypeIds.length > 0) ? wasteTypeIds : [wasteTypeId];

        data = {
          binTypeId: binTypeId,
          wasteTypeId: wasteTypeId,
          serviceItemId: serviceItemId,
        };
        
      } else if(values.customFormParams.jobTemplateType === JobStepType.Out){
        let binTypeId = (values && values.stepOutBinTypeId) ? values.stepOutBinTypeId : null;
        let wasteTypeId = (values && values.stepOutWasteTypeId) ? values.stepOutWasteTypeId : null;
        let serviceItemId = (values && values.stepOutServiceItemId) ? values.stepOutServiceItemId : null;
        
        let wasteTypeIds = (values && values.stepOutWasteTypeIds && values.stepOutWasteTypeIds.length > 0) ? values.stepOutWasteTypeIds : null;
        wasteTypeId = (wasteTypeIds && wasteTypeIds.length > 0) ? wasteTypeIds : [wasteTypeId];

        data = {
          binTypeId: binTypeId,
          wasteTypeId: wasteTypeId,
          serviceItemId: serviceItemId,
        };
        
      } else if(values.customFormParams.jobTemplateType === JobStepType.Pull){
        let binTypeId = (values && values.stepPullBinTypeId) ? values.stepPullBinTypeId : null;
        let wasteTypeId = (values && values.stepPullWasteTypeId) ? values.stepPullWasteTypeId : null;
        let serviceItemId = (values && values.stepPullServiceItemId) ? values.stepPullServiceItemId : null;
        
        let wasteTypeIds = (values && values.stepPullWasteTypeIds && values.stepPullWasteTypeIds.length > 0) ? values.stepPullWasteTypeIds : null;
        wasteTypeId = (wasteTypeIds && wasteTypeIds.length > 0) ? wasteTypeIds : [wasteTypeId];

        data = {
          binTypeId: binTypeId,
          wasteTypeId: wasteTypeId,
          serviceItemId: serviceItemId,
        };
        
      }
    }

    return data;
  }

  saveAction = (eventKey = null, res = null, isErr = null) => {
    // if(eventKey == JobAction.Dispatched){

    // } else if(eventKey == JobAction.Draft){

    // } else if(eventKey == JobAction.Completed){

    // } else if(eventKey == JobAction.Cancelled){

    // } else if(eventKey == JobAction.Assigned){

    // } else {

    // }
    
    this.props.history.push('/manage/job/job-recurring');
  }
	/* END FUNCTIONS */

	
	  /* API */
    callDetailsApi = (id = null, callback = null) => {
        if(id){
            this.setState({
                isBlockingFormDrawer: true,
                details: null,
            });
        
            let data = null;
            if(this.isCopy == true){
              data = {
                isCopy: true,
              };
            }

            this.props.dispatchApiCallGet(data, this.pageName + '-details', this.pageAPI + '/' + id, null, callback, () => {});
        } else {
            if(callback){
                callback(null)
            }
        }
    }

    callCreateApi = (data, callback = null) => {
        this.setState({
            isBlockingFormDrawer: true,
        });

        this.props.dispatchApiCallPost(data, this.pageName + '-create', this.pageAPI, data, callback, null);
    }

    callCopyApi = (data, callback = null) => {
        this.setState({
            isBlockingFormDrawer: true,
        });

        if (data && data[this.pageIdField]) {
          delete data[this.pageIdField];
        }

        if (data && data.attempts && data.attempts.length > 0) {
          for (let i = 0; i < data.attempts.length; i++) {
            delete data.attempts[i].jobId;
            delete data.attempts[i].jobAttemptId;
            delete data.attempts[i].submittedLat;
            delete data.attempts[i].submittedLng;
            delete data.attempts[i].submittedLocation;
            delete data.attempts[i].jobPhotos;
            delete data.attempts[i].jobSignatures;
            delete data.attempts[i].signatureUrl;
            delete data.attempts[i].signedUserContact;
            delete data.attempts[i].signedUserName;
          }
        }
        if (data && data.pricings && data.pricings.length > 0) {
          for (let i = 0; i < data.pricings.length; i++) {
            delete data.pricings[i].jobId;
            delete data.pricings[i].jobPricingId;
            delete data.pricings[i].jobStepId;
          }
        }
        if (data && data.steps && data.steps.length > 0) {
          for (let i = 0; i < data.steps.length; i++) {
            delete data.steps[i].jobId;
            delete data.steps[i].jobStepId;
            delete data.steps[i].collectedAmount;
          }
        }
        if (data && data.invoiceDate) {
          delete data.invoiceDate;
        }
        if (data && data.isInvoiced) {
          delete data.isInvoiced;
        }
        if (data && data.jobDocUrl) {
          delete data.jobDocUrl;
        }
        if (data && data.jobNumber) {
          delete data.jobNumber;
        }
    
        data["isCopy"] = true;

        this.props.dispatchApiCallPost(data, this.pageName + '-copy', this.pageAPI, data, callback, null);
    }

    callUpdateApi = (data, callback = null, eventKey = null, isFromEmailPopup = false) => {
      this.setState({
        isBlockingFormDrawer: true,
      });

      this.props.dispatchApiCallPut(data, this.pageName + '-update', this.pageAPI, data, callback, null);
    }

    callDeleteApi = (data, callback = null) => {
        this.setState({
            isBlockingFormDrawer: true,
        });

        this.props.dispatchApiCallDelete(data, this.pageName + '-delete', this.pageAPI, data, callback, null);
    }

    callCreateCustomerApi = (data, callback = null) => {
        this.setState({
            isBlockingFormDrawer: true,
        });

        this.props.dispatchApiCallPost(data, this.pageName + '-create_customer', 'customer', data, callback, null);
    }
    
    callCreateCustomerSiteApi = (data, callback = null) => {
        this.setState({
            isBlockingFormDrawer: true,
        });

        this.props.dispatchApiCallPost(data, this.pageName + '-create_customersite', 'customersite', data, callback, null);
    }
        
    callServiceChargesReadApi = (customerId = null, customerSiteId = null, binTypeId = null, wasteTypeIds = null, selectedItems = null, isServiceOrHistory, callback = null) => {
      let data = {
          currentPage: 1,
          pageSize: Utils.getMaxPageSize(),
          searchQuery: '',
          // customerSiteId: customerSiteId,
          customerId: customerId,
          wasteTypeIds: wasteTypeIds,
          binTypeId: binTypeId,
          sortColumn: 'serviceTag',
          sortDir: 'desc',
          includeGlobalService: true,
          includeTaxRate: true,
      };

      let variables = {
        wasteTypeIds: wasteTypeIds,
        selectedItems: selectedItems,
        isServiceOrHistory: isServiceOrHistory,
      };

      this.props.dispatchApiCallGet(data, this.pageName + '-service_charge', 'service-item', variables, callback, () => {});
    }

    callCustomerDetailsApi = (id = null, callback = null) => {
      if(id){
          this.setState({
            isLoadingParams: true,
          });
      
          this.props.dispatchApiCallGet(null, this.pageName + '-customer_details', 'customer/' + id, null, callback, () => {});
      } else {
          if(callback){
              callback(null)
          }
      }
    }

    callJobTemplateDetailsApi = (id = null, callback = null) => {
      if(id){
          this.setState({
            isLoadingParams: true,
          });
      
          this.props.dispatchApiCallGet(null, this.pageName + '-job_template_details', 'jobtemplate/' + id, null, callback, () => {});
      } else {
          if(callback){
              callback(null)
          }
      }
    }

    callCustomerSiteDetailsApi = (id = null, callback = null) => {
      if(id){
          this.setState({
            isLoadingParams: true,
          });
      
          this.props.dispatchApiCallGet(null, this.pageName + '-customer_site_details', 'customersite/' + id, null, callback, () => {});
      } else {
          if(callback){
              callback(null)
          }
      }
    }

    callServiceCustomerSiteDetailsApi = (customerId = null, callback = null) => {
      this.setState({
        isLoadingParams: true,
      });
      
      let data = {
        currentPage: 1,
        pageSize: Utils.getMaxPageSize(),
        searchQuery: '',
        sortColumn: 'serviceTag',
        sortDir: 'desc',
        includeGlobalService: true,
        customerId: customerId,
      }

      this.props.dispatchApiCallGet(data, this.pageName + '-service_customer_site_details', 'service-item', null, callback, () => {});
    }

    callReadAccountSettingsLatTypeApi = (callback = null) => {
      this.setState({
        isLoadingFormDrawerCustomerSite: true,
      });
  
      this.props.dispatchApiCallGet(null, this.pageName + '-account_setting_type_lat', 'AccountSetting/type/' + AccountSettingType.DefaultMapPositionLatitude, null, callback, () => {});
    }
    callReadAccountSettingsLngTypeApi = (callback = null) => {
      this.setState({
        isLoadingFormDrawerCustomerSite: true,
      });
  
      this.props.dispatchApiCallGet(null, this.pageName + '-account_setting_type_lng', 'AccountSetting/type/' + AccountSettingType.DefaultMapPositionLongitude, null, callback, () => {});
    }
    callReadAccountSettingsZoomTypeApi = (callback = null) => {
      this.setState({
        isLoadingFormDrawerCustomerSite: true,
      });
  
      this.props.dispatchApiCallGet(null, this.pageName + '-account_setting_type_zoom', 'AccountSetting/type/' + AccountSettingType.DefaultMapPositionZoom, null, callback, () => {});
    }
	  /* END API */


    /* SECTIONS */
    headerSection = (formOptions) => {
      return <HeaderSection
        {...this.props}
        isLoading={this.state.isBlockingFormDrawer}
        formOptions={formOptions}
        fields={formFields}

        onSave={(eventKey, form) => {
          if(form.isEdit){
            if(form.isCopy){
              let data = this.prepareData(form);
              this.callCopyApi(data, (res) => {
                if(eventKey == 1){ // SAVE & NEW
                  window.location.href = '/manage/job/recurring-job-form';
                } else if(eventKey == 2){ // SAVE ONLY
                  window.location.href = '/manage/job/recurring-job-form?id=' + res[this.pageIdField];
                } else { // SAVE & CLOSE
                  this.props.history.push('/manage/job/job-recurring');
                }
              });

            } else {
              let data = this.prepareData(form);
              this.callUpdateApi(data, (res) => {
                if(eventKey == 1){ // SAVE & NEW
                  window.location.href = '/manage/job/recurring-job-form';
                } else if(eventKey == 2){ // SAVE ONLY
                  window.location.href = '/manage/job/recurring-job-form?id=' + res[this.pageIdField];
                } else { // SAVE & CLOSE
                  this.props.history.push('/manage/job/job-recurring');
                }
              });
            }

          } else {
            let data = this.prepareData(form);
            this.callCreateApi(data, (res) => {
              if(eventKey == 1){ // SAVE & NEW
                window.location.href = '/manage/job/recurring-job-form';
              } else if(eventKey == 2){ // SAVE ONLY
                window.location.href = '/manage/job/recurring-job-form?id=' + res[this.pageIdField];
              } else { // SAVE & CLOSE
                this.props.history.push('/manage/job/job-recurring');
              }
            });
          }
        }}
      />
    }

    footerSection = (formOptions) => {
      let {
          values,
      } = formOptions;
      
      return <FooterSection
        {...this.props}
        isLoading={this.state.isBlockingFormDrawer}
        formOptions={formOptions}
        fields={formFields}
        onClickPOD={() => {
          this.setState({
            isPODDialog: true,
          });
        }}

        onClickMoreItem={(val) => {
          if(val == 1){ // Copy
            this.setState({
              recurringJobName: values.recurringJobName,
            }, () => {
              this.props.history.push('/manage/job/recurring-job-form?id=' + this.id + '&isCopy=true');
              this.isCopy = true;
              formOptions.setFieldValue('isCopy', true);
              formOptions.setFieldValue('recurringJobName', '');
            });

          } else if(val == 2){ // Delete
            this.setState({
              isRemoveDialog: true,
              removeRow: this.id,
            });

          }
        }}

        onSave={(eventKey, form) => {
          if(form.isEdit){
            if(form.isCopy){
              let data = this.prepareData(form);
              this.callCopyApi(data, (res) => {
                if(eventKey == 1){ // SAVE & NEW
                  window.location.href = '/manage/job/recurring-job-form';
                } else if(eventKey == 2){ // SAVE ONLY
                  window.location.href = '/manage/job/recurring-job-form?id=' + res[this.pageIdField];
                } else { // SAVE & CLOSE
                  this.props.history.push('/manage/job/job-recurring');
                }
              });

            } else {
              let data = this.prepareData(form);
              this.callUpdateApi(data, (res) => {
                if(eventKey == 1){ // SAVE & NEW
                  window.location.href = '/manage/job/recurring-job-form';
                } else if(eventKey == 2){ // SAVE ONLY
                  window.location.href = '/manage/job/recurring-job-form?id=' + res[this.pageIdField];
                } else { // SAVE & CLOSE
                  this.props.history.push('/manage/job/job-recurring');
                }
              });
            }

          } else {
            let data = this.prepareData(form);
            this.callCreateApi(data, (res) => {
              if(eventKey == 1){ // SAVE & NEW
                window.location.href = '/manage/job/recurring-job-form';
              } else if(eventKey == 2){ // SAVE ONLY
                window.location.href = '/manage/job/recurring-job-form?id=' + res[this.pageIdField];
              } else { // SAVE & CLOSE
                this.props.history.push('/manage/job/job-recurring');
              }
            });
          }
        }}
      />
    }
    
    topSection = (formOptions) => {
        let {
            values,
        } = formOptions;
      
        return <TopSection
            {...this.props}
            isLoading={this.state.isBlockingFormDrawer}
            formOptions={formOptions}
            fields={formFields}
            onClickCloseCopy={() => {
              window.location.href = '/manage/job/recurring-job-form?id=' + this.id;
              
              // this.props.history.push('/manage/job/recurring-job-form?id=' + this.id);
              // this.isCopy = false;
              // formOptions.setFieldValue('isCopy', false);
            }}
            onClickCloseChangedPrice={() => {
              formOptions.setFieldValue('promptUpdateBilling', false);
            }}
            onClickUpdateChangedPrice={() => {
              
            }}
            onCreateCustomer={() => {
                let dataParamsCustomer = {
                    id: 0,
                    row: null,
                };
                Utils.setDrawerData(this.formDrawerCustomer, dataParamsCustomer);
                Utils.showDrawer(this.formDrawerCustomer);
            }}
            onInitCalcDistance={() => {
              if(this.refLocationsSection && this.refLocationsSection.current){
                this.refLocationsSection.current.calcDistance();
              }
            }}
        />
    }

    locationsSection = (formOptions) => {
        return <LocationsSection
            {...this.props}
            ref={this.refLocationsSection}
            isLoading={this.state.isBlockingFormDrawer}
            formOptions={formOptions}
            fields={formFields}
            onCreateCustomerSite={(type) => {
                let {
                    values,
                } = formOptions;

                let dataParamsCustomerSite = {
                    id: 0,
                    customerId: values.customerId,
                    row: null,
                    type: type,
                };
                Utils.setDrawerData(this.formDrawerCustomerSite, dataParamsCustomerSite);
                Utils.showDrawer(this.formDrawerCustomerSite);
            }}
            onClickLocation1={() => {          
                let {
                    values,
                } = formOptions;

                let dataParamsLocation1 = {
                    id: 0,
                    type: 1,
                    row: values,
                };
                Utils.setDrawerData(this.formDrawerLocation, dataParamsLocation1);
                Utils.showDrawer(this.formDrawerLocation);
            }}
            onClickLocation2={() => {          
                let {
                    values,
                } = formOptions;
                
                let dataParamsLocation2 = {
                    id: 0,
                    type: 2,
                    row: values,
                };
                Utils.setDrawerData(this.formDrawerLocation, dataParamsLocation2);
                Utils.showDrawer(this.formDrawerLocation);
            }}
        />
    }

    jobTypesSection = (formOptions) => {
        return <JobTypesSection
            {...this.props}
            ref={this.refJobTypesSection}
            isLoading={this.state.isBlockingFormDrawer}
            formOptions={formOptions}
            fields={formFields}
        />
    }

    stepsSection = (formOptions) => {
      let {
        values,
      } = formOptions;
      
      if(values.customFormParams.jobTemplateType === JobStepType.Exchange){
        return <StepExchangeSection
          {...this.props}
          isLoading={this.state.isBlockingFormDrawer}
          formOptions={formOptions}
          fields={formFields}
          onChangeBinType={(customerId, customerSiteId, binTypeId, selectedItems, isServiceOrHistory) => {
            let wasteTypeIds = selectedItems.map(x => x.wasteTypeId);
            wasteTypeIds = _.uniqBy(wasteTypeIds);

            if(wasteTypeIds && wasteTypeIds.length > 0){
              this.callServiceChargesReadApi(customerId, customerSiteId, binTypeId, wasteTypeIds.join(','), selectedItems, isServiceOrHistory, async (charges) => {
                await Utils.onChangeBinTypeJobForm(formOptions, charges, selectedItems);
              });
            }
          }}
          onChangeSameAsBinOutToFalse={(stepExchangeInBinTypeId, stepExchangeInWasteTypeIds) => {
            Utils.onChangeSameAsBinOutToFalse(formOptions, stepExchangeInBinTypeId, stepExchangeInWasteTypeIds);
          }}
          onBinWasteSidePanel={(inOrOut, binOrWaste) => {
            let dataParamsBinHistory = {
              id: 0,
              type: JobStepType.Exchange,
              row: {
                isServiceOrHistory: 0,
                inOrOut: inOrOut,
                binOrWaste: binOrWaste,
                customerSiteId: values.customerSiteId,
                customerId: values.customerId,
                values: values,
              }
            };
            Utils.setDrawerData(this.formDrawerBinHistory, dataParamsBinHistory);
            Utils.showDrawer(this.formDrawerBinHistory);
          }}
          onWeighChitSidePanel={() => {
            let dataParamsWeighChit = {
              id: 0,
              row: formOptions.values,
            };
            Utils.setDrawerData(this.formDrawerWeighChit, dataParamsWeighChit);
            Utils.showDrawer(this.formDrawerWeighChit);
          }}
          onBinWasteClear={(inOrOut, binOrWaste) => {
            if(inOrOut == InOrOut.OUT){
              Utils.onClearExchangeOUT(formOptions);
            } else {
              Utils.onClearExchangeIN(formOptions);
            }
          }}
        />

      } else if(values.customFormParams.jobTemplateType === JobStepType.Put){
        return <StepPutSection
          {...this.props}
          isLoading={this.state.isBlockingFormDrawer}
          formOptions={formOptions}
          fields={formFields}
          onBinWasteSidePanel={(inOrOut, binOrWaste) => {
            let dataParamsBinHistory = {
              id: 0,
              type: JobStepType.Put,
              row: {
                isServiceOrHistory: 0,
                inOrOut: inOrOut,
                binOrWaste: binOrWaste,
                customerSiteId: values.customerSiteId,
                customerId: values.customerId,
                values: values,
              }
            };
            Utils.setDrawerData(this.formDrawerBinHistory, dataParamsBinHistory);
            Utils.showDrawer(this.formDrawerBinHistory);
          }}
          onBinWasteClear={(inOrOut, binOrWaste) => {
            Utils.onClearPut(formOptions);
          }}
        />
        
      } else if(values.customFormParams.jobTemplateType === JobStepType.Out){
        return <StepOutSection
          {...this.props}
          isLoading={this.state.isBlockingFormDrawer}
          formOptions={formOptions}
          fields={formFields}
          onBinWasteSidePanel={(inOrOut, binOrWaste) => {
            let dataParamsBinHistory = {
              id: 0,
              type: JobStepType.Out,
              row: {
                isServiceOrHistory: 0,
                inOrOut: inOrOut,
                binOrWaste: binOrWaste,
                customerSiteId: values.customerSiteId,
                customerId: values.customerId,
                values: values,
              }
            };
            Utils.setDrawerData(this.formDrawerBinHistory, dataParamsBinHistory);
            Utils.showDrawer(this.formDrawerBinHistory);
          }}
          onBinWasteClear={(inOrOut, binOrWaste) => {
            Utils.onClearOut(formOptions);
          }}
        />
        
      } else if(values.customFormParams.jobTemplateType === JobStepType.Pull){
        return <StepPullSection
          {...this.props}
          isLoading={this.state.isBlockingFormDrawer}
          formOptions={formOptions}
          fields={formFields}
          onBinWasteSidePanel={(inOrOut, binOrWaste) => {
            let dataParamsBinHistory = {
              id: 0,
              type: JobStepType.Pull,
              row: {
                isServiceOrHistory: 0,
                inOrOut: inOrOut,
                binOrWaste: binOrWaste,
                customerSiteId: values.customerSiteId,
                customerId: values.customerId,
                values: values,
              }
            };
            Utils.setDrawerData(this.formDrawerBinHistory, dataParamsBinHistory);
            Utils.showDrawer(this.formDrawerBinHistory);
          }}
          onWeighChitSidePanel={() => {
            let dataParamsWeighChit = {
              id: 0,
              row: formOptions.values,
            };
            Utils.setDrawerData(this.formDrawerWeighChit, dataParamsWeighChit);
            Utils.showDrawer(this.formDrawerWeighChit);
          }}
          onBinWasteClear={(inOrOut, binOrWaste) => {
            Utils.onClearPull(formOptions);
          }}
        />
        
      } else if(values.customFormParams.jobTemplateType === JobStepType.Shift){
        return <StepShiftSection
          {...this.props}
          isLoading={this.state.isBlockingFormDrawer}
          formOptions={formOptions}
          fields={formFields}
          onBinWasteSidePanel={(inOrOut, binOrWaste) => {
            let dataParamsBinHistory = {
              id: 0,
              type: JobStepType.Shift,
              row: {
                isServiceOrHistory: 0,
                inOrOut: inOrOut,
                binOrWaste: binOrWaste,
                customerSiteId: values.customerSiteId,
                customerId: values.customerId,
                values: values,
              }
            };
            Utils.setDrawerData(this.formDrawerBinHistory, dataParamsBinHistory);
            Utils.showDrawer(this.formDrawerBinHistory);
          }}
          onCreateCustomerSite={(type) => {
            let {
                values,
            } = formOptions;

            let dataParamsCustomerSite = {
                id: 0,
                customerId: values.customerId,
                row: null,
                type: type,
            };
            Utils.setDrawerData(this.formDrawerCustomerSite, dataParamsCustomerSite);
            Utils.showDrawer(this.formDrawerCustomerSite);
          }}
          onBinWasteClear={(inOrOut, binOrWaste) => {
            Utils.onClearShift(formOptions);
          }}
        />
        
      } else if(values.customFormParams.jobTemplateType === JobStepType.OnTheSpot){
        return <StepOnTheSpotSection
          {...this.props}
          isLoading={this.state.isBlockingFormDrawer}
          formOptions={formOptions}
          fields={formFields}
          onBinWasteSidePanel={(inOrOut, binOrWaste) => {
            let dataParamsBinHistory = {
              id: 0,
              type: JobStepType.OnTheSpot,
              row: {
                isServiceOrHistory: 0,
                inOrOut: inOrOut,
                binOrWaste: binOrWaste,
                customerSiteId: values.customerSiteId,
                customerId: values.customerId,
                values: values,
              }
            };
            Utils.setDrawerData(this.formDrawerBinHistory, dataParamsBinHistory);
            Utils.showDrawer(this.formDrawerBinHistory);
          }}
          onWeighChitSidePanel={() => {
            let dataParamsWeighChit = {
              id: 0,
              row: formOptions.values,
            };
            Utils.setDrawerData(this.formDrawerWeighChit, dataParamsWeighChit);
            Utils.showDrawer(this.formDrawerWeighChit);
          }}
          onBinWasteClear={(inOrOut, binOrWaste) => {
            Utils.onClearOnTheSpot(formOptions);
          }}
        />
        
      }
    }
    
    dispatchSection = (formOptions) => {
      let {
        values,
      } = formOptions;
      
      return <DispatchSection
        {...this.props}
        isLoading={this.state.isBlockingFormDrawer}
        formOptions={formOptions}
        fields={formFields}
        onChatMore={(messages) => {
            let dataParamsChat = {
              id: (this.id && this.id > 0) ? this.id : null,
              row: (messages && messages.length > 0) ? messages : [],
            };
            Utils.setDrawerData(this.formDrawerChat, dataParamsChat);
            Utils.showDrawer(this.formDrawerChat);
        }}
        onOptions={() => {
          var newValues = _.cloneDeep(values);
          
          this.setState({
            isOptionsDialog: true,
            itemOptionsDialog: formOptions,
            oldItemOptionsDialog: newValues,
          })
        }}
      />
    }

    auditTrailSection = (formOptions) => {
      let {
          values,
      } = formOptions;
      
      return <AuditTrailSection
        {...this.props}
        isLoading={this.state.isBlockingFormDrawer}
        formOptions={formOptions}
        fields={formFields}
      />
    }

    paymentSection = (formOptions) => {
        return <PaymentSection
            {...this.props}
            isLoading={this.state.isBlockingFormDrawer}
            formOptions={formOptions}
            fields={formFields}
        />
    }

    recurringSection = (formOptions) => {
        return <RecurringSection
            ref={this.refRecurring}
            {...this.props}
            isLoading={this.state.isBlockingFormDrawer}
            formOptions={formOptions}
            fields={formFields}
          />
    }
    /* END SECTIONS */


    /* FORMS */
    initDrawers = () => {
        Utils.initDrawers(() => {
          this.formDrawerCustomer = Utils.getInstanceDrawer('formCustomer');
          Utils.onShowDrawer(this.formDrawerCustomer, null, () => {
            this.setState({
              isVisibleFormDrawerCustomer: true,
            });
          });
          Utils.onHideDrawer(this.formDrawerCustomer, this.refFormDrawerCustomer, () => {
            this.setState({
              isVisibleFormDrawerCustomer: false,
            });
          });
          
          
          this.formDrawerCustomerSite = Utils.getInstanceDrawer('formCustomerSite');
          Utils.onShowDrawer(this.formDrawerCustomerSite, null, () => {
            this.setState({
              isVisibleFormDrawerCustomerSite: true,
            });
          });
          Utils.onHideDrawer(this.formDrawerCustomerSite, this.refFormDrawerCustomerSite, () => {
            this.setState({
              isVisibleFormDrawerCustomerSite: false,
            });
          });
          

          this.formDrawerBinHistory = Utils.getInstanceDrawer('formBinHistory');
          Utils.onShowDrawer(this.formDrawerBinHistory, null, () => {
            this.setState({
              isVisibleFormDrawerBinHistory: true,
            });
          });
          Utils.onHideDrawer(this.formDrawerBinHistory, this.refFormDrawerBinHistory, () => {
            this.setState({
              isVisibleFormDrawerBinHistory: false,
            });
          });
        });
    }

    formDrawerSectionCustomer = () => {
		  return <DrawerLayout
            name={'formCustomer'}
            ref={this.refFormDrawerCustomer}
            drawer={this.formDrawerCustomer}
            initialValues={this.state.rowInitialValuesCustomer}
            values={this.state.rowValuesCustomer}
            isVisible={this.state.isVisibleFormDrawerCustomer}
            isBlocking={Utils.isBlockingDrawer(this.state.isLoadingFormDrawerCustomer, this.state.isBlockingFormDrawerCustomer)}
            renderChildren={Utils.shouldDrawerRenderChildren(this.state.isLoadingFormDrawerCustomer, this.state.isBlockingFormDrawerCustomer)}
            size={'100%'}
            
            title={(data) => {
                if(data){
                    return <div className={'d-flex align-items-center'}>
                        <div className={'d-block'}>
                            <div className={'pe-2'}>
                                <span className={'pe-2'}>{(data.id > 0) ? 'Update customer' : 'Create customer'}</span>
                                {/* {(data.id > 0) && <IconButton
                                    icon={'history'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        this.setState({
                                            isOpenAuditTrail: true,
                                            objectId: data.id,
                                        });
                                    }}
                                />} */}
                            </div>
                        </div>
                    </div>;
                }
            }}
            toolbar={null}
            
            onOpenClose={(isOpen, dataParams) => {
                if(isOpen){
                    this.setState({
                        rowValuesCustomer: this.state.initialValuesCustomer,
                    }, () => {
                      this.refBody.current.click()
                    });
                }
            }}
            onSubmit={(form, dataParams) => {
                
            }}
            >
            {(formOptions, dataParams, isBlocking) => {
                return <CustomerForm
                    drawer={this.formDrawerCustomer}
                    initialValues={this.state.rowInitialValuesCustomer}
                    values={this.state.rowValuesCustomer}
                    formOptions={formOptions}
                    dataParams={dataParams}
                    isBlocking={isBlocking}

                    id={0}
                    isFooter={true}
                    onSave={async (callback) => {
                      let form = callback();
                      
                      if(!form.isEdit){
                          this.callCreateCustomerApi(form, async (res) => {
                            Utils.showDrawer(this.formDrawerCustomer, false);

                            if(res && this.refFormik && this.refFormik.current){
                              Utils.onClearSteps(this.refFormik.current);
                              Utils.onClearCustomerSite(this.refFormik.current);

                              let item = {
                                value: res.customerId,
                                title: res.customerName,
                                item: res,
                              };

                              await this.refFormik.current.setFieldValue('customerId', item.value);
                              await this.refFormik.current.setFieldValue('customerName', item.title);
                              await this.refFormik.current.setFieldValue('customerItem', item.item);

                              let billingProfile = (item && item.item && item.item.billingProfile) ? item.item.billingProfile : null;
                              let quickBookApp = (billingProfile && billingProfile.quickBookApp) ? billingProfile.quickBookApp : null;
                              let isConnectQuickbook = (billingProfile && billingProfile.isConnectQuickbook) ? billingProfile.isConnectQuickbook : false;
                              let isConnectXero = (billingProfile && billingProfile.isConnectXero) ? billingProfile.isConnectXero : false;
                              let quickBookAppId = (quickBookApp && quickBookApp.quickBookAppId) ? quickBookApp.quickBookAppId : 0;
                              let accountBillingProfileId = (item && item.item && item.item.accountBillingProfileId) ? item.item.accountBillingProfileId : null;
                              let billingProfileName = (item && item.item && item.item.billingProfileName) ? item.item.billingProfileName : '';
                              let quickBookDefaultTermId = (item && item.item && item.item.quickBookDefaultTermId) ? item.item.quickBookDefaultTermId : null;
                              let paymentTerms = (item && item.item && item.item.paymentTerms) ? item.item.paymentTerms : '';
                              let xeroPaymentTerm = (item && item.item && item.item.xeroPaymentTerm) ? item.item.xeroPaymentTerm : '';
                              let xeroPaymentTermType = (item && item.item && item.item.xeroPaymentTermType) ? item.item.xeroPaymentTermType.toString() : '0';
                              let note = (item && item.item && item.item.note) ? item.item.note : '';

                              let billingProfileItem = null;
                              if(billingProfile){
                                  billingProfileItem = {
                                      value: accountBillingProfileId,
                                      title: billingProfileName,
                                      item: billingProfile,
                                  };
                              }
                              let accountBillingProfileItems = (billingProfileItem) ? [billingProfileItem] : [];

                              let paymentTermsItem = null;
                              if(billingProfile){
                                  paymentTermsItem = {
                                      value: quickBookDefaultTermId,
                                      title: paymentTerms,
                                      item: item,
                                  };
                              }
                              let paymentTermsItems = (paymentTermsItem) ? [paymentTermsItem] : [];
                              
                              
                              let items = this.refFormik.current.values.customFormParams.customerItems;
                              items = Utils.addToArray(items, item.value, item);


                              await this.refFormik.current.setFieldValue('customFormParams.customerItems', items);

                              await this.refFormik.current.setFieldValue('customFormParams.accountBillingProfileItems', accountBillingProfileItems);
                              await this.refFormik.current.setFieldValue('customFormParams.paymentTermsItems', paymentTermsItems);
                              await this.refFormik.current.setFieldValue('customFormParams.isConnectQuickbook', isConnectQuickbook);
                              await this.refFormik.current.setFieldValue('customFormParams.isConnectXero', isConnectXero);
                              await this.refFormik.current.setFieldValue('customFormParams.quickBookAppId', quickBookAppId);
                              await this.refFormik.current.setFieldValue('accountBillingProfileId', accountBillingProfileId);
                              await this.refFormik.current.setFieldValue('accountBillingProfileName', billingProfileName);
                              await this.refFormik.current.setFieldValue('paymentTermsId', quickBookDefaultTermId);
                              await this.refFormik.current.setFieldValue('paymentTermsName', paymentTerms);
                              await this.refFormik.current.setFieldValue('xeroPaymentTerm', xeroPaymentTerm);
                              await this.refFormik.current.setFieldValue('xeroPaymentTermType', xeroPaymentTermType);
                              await this.refFormik.current.setFieldValue('customerNote', note);
                              
                              await this.refFormik.current.setFieldValue('detailsPOD', null);
                              await this.refFormik.current.setFieldValue('fromPOD', '');
                              await this.refFormik.current.setFieldValue('subjectPOD', '');
                              await this.refFormik.current.setFieldValue('bodyPOD', '');
                              await this.refFormik.current.setFieldValue('includeDeliveryOrderPOD', false);
                              await this.refFormik.current.setFieldValue('includePhotosPOD', false);
                              await this.refFormik.current.setFieldValue('includeWeighChitPOD', false);
                              await this.refFormik.current.setFieldValue('includeAttachmentPOD', true);
                            }
                          });
                      }
                    }}
                />
            }}
        </DrawerLayout>
	  }

    formDrawerSectionCustomerSite = () => {
		  return <DrawerLayout
            name={'formCustomerSite'}
            ref={this.refFormDrawerCustomerSite}
            drawer={this.formDrawerCustomerSite}
            initialValues={this.state.rowInitialValuesCustomerSite}
            values={this.state.rowValuesCustomerSite}
            isVisible={this.state.isVisibleFormDrawerCustomerSite}
            isBlocking={Utils.isBlockingDrawer(this.state.isLoadingFormDrawerCustomerSite, this.state.isBlockingFormDrawerCustomerSite)}
            renderChildren={Utils.shouldDrawerRenderChildren(this.state.isLoadingFormDrawerCustomerSite, this.state.isBlockingFormDrawerCustomerSite)}
            size={'100%'}

            title={(data) => {
                if(data){
                    return <div className={'d-flex align-items-center'}>
                        <div className={'d-block'}>
                            <div className={'pe-2'}>
                                <span className={'pe-2'}>{(data.id > 0) ? 'Update customer site' : 'Create customer site'}</span>
                            </div>
                        </div>
                    </div>;
                }
            }}
            toolbar={null}
            
            onOpenClose={(isOpen, dataParams) => {
                if(isOpen){
                  
                  this.callReadAccountSettingsLatTypeApi((latitude) => {
                    this.callReadAccountSettingsLngTypeApi((longitude) => {
                      this.callReadAccountSettingsZoomTypeApi((zoomItem) => {
                        let lat = (latitude && latitude.value && latitude.value != '') ? latitude.value : 1.353915;
                        let lng = (longitude && longitude.value && longitude.value != '') ? longitude.value : 103.822901;
                        let zoom = (zoomItem && zoomItem.value && zoomItem.value != '') ? zoomItem.value : '';
                        
                        let centerMap = (lat != '' && lng != '') ? { lat: parseFloat(lat), lng: parseFloat(lng) } : this.center;
                        let zoomMap = (zoom != '' && zoom != '') ? parseInt(zoom) : this.zoom;
                        
                        this.setState({
                          centerMap: centerMap,
                          zoomMap: zoomMap,
                        });
                      });
                    });
                  });

                    this.setState({
                        rowValuesCustomerSite: this.state.initialValuesCustomerSite,
                    }, () => {
                      this.refBody.current.click()
                    });
                }
            }}
            onSubmit={(form, dataParams) => {
                
            }}
        >
            {(formOptions, dataParams, isBlocking) => {
                return <CustomerSiteForm
                    drawer={this.formDrawerCustomerSite}
                    initialValues={this.state.rowInitialValuesCustomerSite}
                    initialValuesVehicle={this.state.rowInitialValuesVehicleCustomerSite}
                    values={this.state.rowValuesCustomerSite}
                    formOptions={formOptions}
                    dataParams={dataParams}

                    centerMap={this.state.centerMap}
                    zoomMap={this.state.zoomMap}
                    
                    id={0}
                    isDrawer={true}
                    isFooter={true}

                    onSave={(callback) => {
                      let form = callback();

                      if(!form.isEdit){
                        let customerId = null;
                        if(this.refFormik && this.refFormik.current){
                          try {
                            customerId = this.refFormik.current.values.customerId;
                          }catch(e){}
                        }

                          this.callCreateCustomerSiteApi({...form, customerId: customerId }, async (res) => {
                            Utils.showDrawer(this.formDrawerCustomerSite, false);
      
                            if(res && this.refFormik && this.refFormik.current){
                              let item = {
                                value: res.customerSiteId,
                                title: res.siteNameDisplay,
                                item: res,
                              };
      
                              if(dataParams && dataParams.type == 2){
                                let items = this.refFormik.current.values.customFormParams.customerSiteShiftItems;
                                items = Utils.addToArray(items, item.value, item);
                                await this.refFormik.current.setFieldValue('customFormParams.customerSiteShiftItems', items);
      
                                Utils.onClearSteps(this.refFormik.current);
      
                                await this.refFormik.current.setFieldValue('customerSiteShiftId', item.value);
                                await this.refFormik.current.setFieldValue('customerSiteShiftName', item.title);
                                
                                if(item && item.item){
                                  await this.refFormik.current.setFieldValue('customerSiteShiftAddress', Utils.formatCustomerSiteAddress(item.item));
                                  await this.refFormik.current.setFieldValue('customerSiteShiftItem', item.item);
                                  await this.refFormik.current.setFieldValue('hasCoordCustomerSiteShift', true);
                                }
      
                              } else {
                                let items = this.refFormik.current.values.customFormParams.customerSiteItems;
                                items = Utils.addToArray(items, item.value, item);
                                await this.refFormik.current.setFieldValue('customFormParams.customerSiteItems', items);
      
                                Utils.onClearSteps(this.refFormik.current);
      
                                await this.refFormik.current.setFieldValue('customerSiteId', item.value);
                                await this.refFormik.current.setFieldValue('customerSiteName', item.title);
                                    
                                if(item && item.item){
                                  let obj = item.item;
                                  let remarks = (obj.remarks && obj.remarks !== '') ? obj.remarks : '';
      
                                  await this.refFormik.current.setFieldValue('remarks', remarks);
                                  await this.refFormik.current.setFieldValue('customerSiteAddress', Utils.formatCustomerSiteAddress(item.item));
                                  await this.refFormik.current.setFieldValue('customerSiteItem', item.item);
                                  await this.refFormik.current.setFieldValue('hasCoordCustomerSite', true);
                                }
                              }
                              
                              if(item && item.item){
                                let obj = item.item;
                                // let remarks = (obj.remarks && obj.remarks !== '') ? obj.remarks : '';
                                let instructions = (obj.instructions && obj.instructions !== '') ? obj.instructions : '';
                                let contactPersonName = (obj.contactPersonName && obj.contactPersonName !== '') ? obj.contactPersonName : '';
                                let contactPersonPhoneNumber = (obj.contactPersonPhoneNumber && obj.contactPersonPhoneNumber !== '') ? obj.contactPersonPhoneNumber : '';
                                let contactPersonNameTwo = (obj.contactPersonNameTwo && obj.contactPersonNameTwo !== '') ? obj.contactPersonNameTwo : '';
                                let contactPersonPhoneNumberTwo = (obj.contactPersonPhoneNumberTwo && obj.contactPersonPhoneNumberTwo !== '') ? obj.contactPersonPhoneNumberTwo : '';
                                
                                let isContactPersonSendNotification = ((obj.isContactPersonSendNotification === false) || (obj.isContactPersonSendNotification === true)) ? obj.isContactPersonSendNotification : false;
                                let isContactPersonTwoSendNotification = ((obj.isContactPersonTwoSendNotification === false) || (obj.isContactPersonTwoSendNotification === true)) ? obj.isContactPersonTwoSendNotification : false;
                          
                                let service = (obj.service && obj.service.length > 0) ? obj.service[0] : null;
                                let serviceId = (service && service.serviceId && service.serviceId > 0) ? service.serviceId : null;
                                let serviceName = (service && service.serviceName && service.serviceName !== '') ? service.serviceName : '';
                                
                                let defaultVehicles = (obj.defaultVehicles && obj.defaultVehicles.length > 0) ? obj.defaultVehicles : [];
                                if(defaultVehicles && defaultVehicles.length > 0){
                                    let defArr = defaultVehicles.filter(x => x.defaultDriver !== null);
                                    if(defArr && defArr.length > 0){
                                        let vehicleItem = defArr[0];
                                        let vehicleId = (vehicleItem.vehicleId && vehicleItem.vehicleId > 0) ? vehicleItem.vehicleId : null;
                                        let vehicleName = (vehicleItem.vehicleName && vehicleItem.vehicleName !== '') ? vehicleItem.vehicleName : '';
                                        
                                        if(this.refFormik.current.values.vehicleId === null || this.refFormik.current.values.vehicleId <= 0){
                                            let items = this.refFormik.current.values.customFormParams.vehicleItems;
                                            let arrItem = {
                                                value: vehicleId,
                                                title: vehicleName,
                                                item: vehicleItem,
                                            };
                                            items = Utils.addToArray(items, vehicleId, arrItem);
                                            await this.refFormik.current.setFieldValue('customFormParams.vehicleItems', items);
                                            
                                            await this.refFormik.current.setFieldValue('vehicleId', vehicleId);
                                            await this.refFormik.current.setFieldValue('vehicleName', vehicleName);
                                        }
      
                                        let driverItem = defArr[0].defaultDriver;
                                        let driverId = (driverItem.driverId && driverItem.driverId > 0) ? driverItem.driverId : null;
                                        let driverName = (driverItem.driverName && driverItem.driverName !== '') ? driverItem.driverName : '';
                                        
                                        if(this.refFormik.current.values.driverId === null || this.refFormik.current.values.driverId <= 0){
                                            let items = this.refFormik.current.values.customFormParams.driverItems;
                                            let arrItem = {
                                                value: driverId,
                                                title: driverName,
                                                item: driverItem,
                                            };
                                            items = Utils.addToArray(items, driverId, arrItem);
                                            await this.refFormik.current.setFieldValue('customFormParams.driverItems', items);
      
                                            await this.refFormik.current.setFieldValue('driverId', driverId);
                                            await this.refFormik.current.setFieldValue('driverName', driverName);
      
      
                                            let selectedStatus = Utils.findStatus(this.refFormik.current.values.customFormParams.statusItems, 'title','unassigned');
                                            if (this.refFormik.current.values.statusId === null || this.refFormik.current.values.statusId === 0 || this.refFormik.current.values.statusId === selectedStatus.value) {
                                                let newStatus = Utils.findStatus(this.refFormik.current.values.customFormParams.statusItems, 'title','assigned');
                                                await this.refFormik.current.setFieldValue('statusId', newStatus.value);
                                                await this.refFormik.current.setFieldValue('statusName', newStatus.title);
                                            }
                                        }
                                    }
                                }
      
                                await this.refFormik.current.setFieldValue('siteRemarks', instructions);
                                await this.refFormik.current.setFieldValue('contactPersonOne', contactPersonName);
                                await this.refFormik.current.setFieldValue('contactNumberOne', contactPersonPhoneNumber);
                                await this.refFormik.current.setFieldValue('contactPersonTwo', contactPersonNameTwo);
                                await this.refFormik.current.setFieldValue('contactNumberTwo', contactPersonPhoneNumberTwo);
                                await this.refFormik.current.setFieldValue('isContactPersonSendNotification', isContactPersonSendNotification);
                                await this.refFormik.current.setFieldValue('isContactPersonTwoSendNotification', isContactPersonTwoSendNotification);
                                await this.refFormik.current.setFieldValue('serviceId', serviceId);
                                await this.refFormik.current.setFieldValue('serviceName', serviceName);
                              }
      
                              let markers = Utils.getJobFormLocationMarkers({
                                customerSiteId: this.refFormik.current.values.customerSiteId,
                                customerSiteItems: this.refFormik.current.values.customFormParams.customerSiteItems,
                                customerSiteShiftId: this.refFormik.current.values.customerSiteShiftId,
                                customerSiteShiftItems: this.refFormik.current.values.customFormParams.customerSiteShiftItems,
                                templateStepSites1Latitude: this.refFormik.current.values.templateStepSites1Latitude,
                                templateStepSites1Longitude: this.refFormik.current.values.templateStepSites1Longitude,
                                templateStepSites2Latitude: this.refFormik.current.values.templateStepSites2Latitude,
                                templateStepSites2Longitude: this.refFormik.current.values.templateStepSites2Longitude,
                                jobTemplateType: this.refFormik.current.values.customFormParams.jobTemplateType
                              }, this.refFormik.current.values)
                              await this.refFormik.current.setFieldValue('customFormParams.markers', markers);
      
                              if(this.refLocationsSection && this.refLocationsSection.current){
                                this.refLocationsSection.current.calcDistance();
                              }
                            }
                          });
                      }
                  }}
                />
            }}
        </DrawerLayout>
	  }
    
    formDrawerSectionBinHistory = () => {
		  return <DrawerLayout
        name={'formBinHistory'}
        ref={this.refFormDrawerBinHistory}
        drawer={this.formDrawerBinHistory}
        validationSchema={null}
        initialValues={this.state.rowInitialValuesBinHistory}
        values={this.state.rowValuesBinHistory}
        isVisible={this.state.isVisibleFormDrawerBinHistory}
        isBlocking={Utils.isBlockingDrawer(this.state.isLoadingFormDrawerBinHistory, this.state.isBlockingFormDrawerBinHistory)}
        renderChildren={Utils.shouldDrawerRenderChildren(this.state.isLoadingFormDrawerBinHistory, this.state.isBlockingFormDrawerBinHistory)}
        size={'100%'}

        title={(data) => {
          if(data){
            return <div className={'d-flex align-items-center'}>
              <div className={'d-block'}>
                <div className={'pe-2'}>
                  <span className={'pe-2'}>Select Site Service</span>
                </div>
              </div>
            </div>;
          }
        }}
        toolbar={null}
        
        onOpenClose={(isOpen, dataParams) => {
          if(isOpen){
            if(this.formDrawerBinHistory && this.formDrawerBinHistory.element){
              this.formDrawerBinHistory.element.click();
            }

            this.setState({
              rowValuesBinHistory: this.state.initialValuesBinHistory,
            });
          }
        }}
        onSubmit={(form, dataParams) => {
          
        }}
      >
        {(formOptions, dataParams, isBlocking) => {
          return <JobFormBinHistoryDrawer
            ref={this.refFormDrawerBinHistoryDrawer}
            drawer={this.formDrawerBinHistory}
            fields={null}
            initialValues={this.state.rowInitialValuesBinHistory}
            values={this.state.rowValuesBinHistory}
            mainFormOptions={(this.refFormik && this.refFormik.current) ? this.refFormik.current : null}
            formOptions={formOptions}
            dataParams={dataParams}
            isServiceOrHistory={this.state.isServiceOrHistory}
            onCancel={() => {
              this.setState({
                isServiceOrHistory: false,
              }, async () => {
                if(this.refFormik && this.refFormik.current){
                  await this.refFormik.current.setFieldValue('customFormParams.isServiceOrHistory', false);
                }
              });
            }}
            onSave={(form, dataParams, callback) => {
              this.setState({
                isServiceOrHistory: false,
              }, async () => {
                if(this.refFormik && this.refFormik.current){
                  await this.refFormik.current.setFieldValue('customFormParams.isServiceOrHistory', false);

                  let serviceNames = await Utils.getJobServiceTagName(this.refFormik.current.values);
                  let newValues = {...this.refFormik.current.values, ...serviceNames};
                  this.refFormik.current.setValues(newValues);
                }
              });
              
              
              if(callback){
                callback(this.refFormik);
              }

              Utils.showDrawer(this.formDrawerBinHistory, false);
            }}
            onChangeBinType={(customerId, customerSiteId, binTypeId, selectedItems, isServiceOrHistory) => {
              let wasteTypeIds = selectedItems.map(x => x.wasteTypeId);
              wasteTypeIds = _.uniqBy(wasteTypeIds);

              if(wasteTypeIds && wasteTypeIds.length > 0){
                this.callServiceChargesReadApi(customerId, customerSiteId, binTypeId, wasteTypeIds.join(','), selectedItems, isServiceOrHistory, async (charges) => {
                  await Utils.onChangeBinTypeJobForm(this.refFormik.current, charges, selectedItems);
                });
              }
            }}
            onCreateNewService={() => {
              let values = (this.refFormik && this.refFormik.current && this.refFormik.current.values) ? this.refFormik.current.values : null;
              
              let dataParamsService = {
                id: 0,
                row: values,
              };
              Utils.setDrawerData(this.formDrawerService, dataParamsService);
              Utils.showDrawer(this.formDrawerService);
            }}
            onAllServicesLoad={(arr) => {
              this.setState({
                allServicesItems: arr,
              });
            }}
          />
        }}
      </DrawerLayout>
	  }
    /* END FORMS */
	
	
    /* DIALOGS */
    setRemoveDialog = () => {
      return <Modal
        show={this.state.isRemoveDialog}
        onHide={() => {
          this.setState({
            isRemoveDialog: false,
            cancelDialogItemLoading: false,
            isCompletedRemoveDialog: false,
          });
        }}
        centered
        backdrop="static"
        keyboard={false}
      >
        <RemoveJobDialog
          isLoading={this.state.isBlockingFormDrawer}
          isCompletedJob={this.state.isCompletedRemoveDialog}
          onHide={() => {
            this.setState({
              isRemoveDialog: false,
              cancelDialogItemLoading: false,
              isCompletedRemoveDialog: false,
            });
          }}
          onOk={async (form) => {
            let proofOfDeliveryRemarks = form.driverNoteText && form.driverNoteText !== "" ? form.driverNoteText : "";
              
            if(this.refFormik && this.refFormik.current){
              await this.refFormik.current.setFieldValue('proofOfDeliveryRemarks', proofOfDeliveryRemarks);
            }

            this.setState({
              isRemoveDialog: false,
              cancelDialogItemLoading: false,
              isCompletedRemoveDialog: false,
            }, () => {
              let rowId = this.state.removeRow;

              let data = {
                [this.pageIdField]: rowId,
                'remarks': proofOfDeliveryRemarks,
              };

              this.callDeleteApi(data, (res) => {
                this.props.history.push('/manage/job/job-recurring');
              });
            });
          }}
        />
      </Modal>
    }

    setOptionsDialog = (formOptions) => {
      return <Popup 
        className={'no-bg'}
        auth={this.props.auth}
        settingsType={this.props.settingsType}

        show={this.state.isOptionsDialog}

        showHeader={true}
        header={({ isLoading, id, onHide, onSave }) => {
          return <>
            <h3>
              Driver Options<br />
              <span style={{ fontSize: '13px', fontWeight: 'normal', color: '#7A7A7A' }}>Job-based rules and permission on Driver's mobile app.</span>
            </h3>
          </>
        }}
        showFooter={true}
        centerFooter={'start'}
        footer={({ isLoading, item, onHide, onSave }) => {
          return <Row>
            <Col xs={'auto'}>
              <Button
                variant={'primary'}
                disabled={isLoading}
                onClick={onSave}
              >Save</Button>
            </Col>
            <Col xs={'auto'}>
              <Button
                variant={'light'}
                disabled={isLoading}
                onClick={onHide}
              >Cancel</Button>
            </Col>
          </Row>
        }}

        scrollable={true}
        centered={true}
        closeButton={true}
        fullscreen={true}
        noBodyPadding={false}
        size={'lg'}

        onHide={async () => {
          let {
            setValues,
          } = formOptions;
          
          await setValues(this.state.oldItemOptionsDialog);

          this.setState({
            isOptionsDialog: false,
            itemOptionsDialog: null,
            oldItemOptionsDialog: null,
          });
        }}
        onSave={() => {
          this.setState({
            isOptionsDialog: false,
            itemOptionsDialog: null,
            oldItemOptionsDialog: null,
          });
        }}
      >
        {(props) => {
          return <OptionsSection
            ref={this.refOptions}
            {...props}
            isLoading={this.state.isBlockingFormDrawer}
            formOptions={formOptions}
            fields={formFields}
          />
        }}
      </Popup>
    }
    /* END DIALOGS */


    render() {
        return (
            <div ref={this.refBody}>
                {
                    this.state.rowValues
                    ?
                    <Formik
                        innerRef={this.refFormik}
                        validationSchema={formSchema}
                        initialValues={this.state.rowValues}
                        validateOnMount={false}
                        enableReinitialize={true}
                    >
                        {(formOptions) => {
                            return <Form className='w-100' noValidate autoComplete="off" onSubmit={formOptions.handleSubmit}>
                              
                                {(this.state.isLoadingParams) && <div style={{
                                  position: 'fixed',
                                  top: '0',
                                  width: '100%',
                                  height: '100%',
                                  backgroundColor: 'black',
                                  opacity: '0.3',
                                  zIndex: '99999',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}><Spinner style={{ color: 'white' }} animation="border" /></div>}

                                <JobsFormLayout
                                    formOptions={formOptions}
                                    isBlocking={this.state.isBlockingFormDrawer}
                                    headerSection={this.headerSection(formOptions)}
                                    footerSection={this.footerSection(formOptions)}
                                    topSection={this.topSection(formOptions)}
                                    locationsSection={this.locationsSection(formOptions)}
                                    jobTypesSection={this.jobTypesSection(formOptions)}
                                    stepsSection={this.stepsSection(formOptions)}
                                    dispatchSection={this.dispatchSection(formOptions)}
                                    paymentSection={this.paymentSection(formOptions)}
                                    recurringSection={this.recurringSection(formOptions)}
                                    // auditTrailSection={this.auditTrailSection(formOptions)}
                                />
                                {this.setOptionsDialog(formOptions)}
                            </Form>
                        }}
                    </Formik>
                    :
                    null   
                }

                {this.formDrawerSectionCustomer()}
                {this.formDrawerSectionCustomerSite()}
                {this.formDrawerSectionBinHistory()}

                {this.setRemoveDialog()}
            </div>
        );
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  dispatchApiCallPost,
  dispatchApiCallPut,
  dispatchApiCallDelete,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(JobsForm);
