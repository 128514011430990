import { createSlice } from "@reduxjs/toolkit";
import axios from 'axios';

import { CancelToken } from '../../../app/utils/enums';

function NewReducer() {
  const name = 'cancelToken';

  
  const initialState = {
    cancelToken: axios.CancelToken.source(),
  };


  const reducers = {
    cancel: (state, action) => {
      if (state.cancelToken) {
        state.cancelToken.cancel(CancelToken.CANCELLED);
      }

      state.cancelToken = axios.CancelToken.source();
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
  };
}


export default NewReducer();