/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as yup from 'yup';
import _ from 'lodash';

import * as dispatchCrudApi from '../../../api/CRUD/DispatchCRUD'
import Utils from "../../../utils/utils";
import { CustomerSiteAvailability } from "../../../utils/enums";

import { ParseResult } from "../../../utils/interfaces";


export const fields = {
  siteName: {
    id: 'siteName',
    label: 'New location',
    placeholder: 'Search Address...',
  },
  street: {
      id: 'street',
      label: 'Street Name',
      placeholder: ' ',
  },
  blockNo: {
      id: 'blockNo',
      label: 'Block Number',
      placeholder: ' ',
  },
  unitNo: {
      id: 'unitNo',
      label: 'Unit Number',
      placeholder: ' ',
  },
  postalCode: {
      id: 'postalCode',
      label: 'Post Code',
      placeholder: ' ',
  },
  coordinates: {
      id: 'coordinates',
      label: 'Coordinates',
      placeholder: ' ',
  },

  zoneId: {
      id: 'zoneId',
      label: 'Group As',
      placeholder: ' ',
  },
  contactPersonName: {
      id: 'contactPersonName',
      label: 'Contact 1',
      placeholder: 'Person 1',
  },
  contactPersonPhoneNumber: {
      id: 'contactPersonPhoneNumber',
      label: 'Phone 1',
      placeholder: 'Phone 1',
  },
  contactPersonNameTwo: {
      id: 'contactPersonNameTwo',
      label: 'Contact 2',
      placeholder: 'Person 2',
  },
  contactPersonPhoneNumberTwo: {
      id: 'contactPersonPhoneNumberTwo',
      label: 'Phone 2',
      placeholder: 'Phone 2',
  },
  isContactPersonSendNotification: {
      id: 'isContactPersonSendNotification',
      label: 'Notification',
      placeholder: ' ',
  },
  isContactPersonTwoSendNotification: {
      id: 'isContactPersonTwoSendNotification',
      label: 'Notification',
      placeholder: ' ',
  },

  driverId: {
      id: 'driverId',
      label: 'Default Driver(s)',
      placeholder: ' ',
  },
  weekdayId: {
      id: 'weekdayId',
      label: 'Weekdays Availability',
      placeholder: ' ',
  },
  weekendId: {
      id: 'weekendId',
      label: 'Weekends Availability',
      placeholder: ' ',
  },
  remarks: {
      id: 'remarks',
      label: 'Site Remarks',
      labelDetails: '(Internal Note)',
      placeholder: Utils.placeholderRows(5),
  },
  instructions: {
      id: 'instructions',
      label: 'Driver Remarks',
      labelDetails: '(Appear On Driver App)',
      placeholder: Utils.placeholderRows(5),
  },
  copySiteRemarksToJob: {
      id: 'copySiteRemarksToJob',
      label: 'Copy Site Remarks To Job Form',
      placeholder: ' ',
  },
};

export const formSchema = (id: number|null= null) => {
  return yup.object().shape({
    isInvalid: yup.bool().oneOf([true, false]),

    siteName: yup.string().required().label(fields.siteName.label),
    street: yup.string().label(fields.street.label),
    blockNo: yup.string().label(fields.blockNo.label),
    unitNo: yup.string().label(fields.unitNo.label),
    postalCode: yup.string().label(fields.postalCode.label),
    coordinates: yup.string().required().label(fields.coordinates.label),

    zoneId: yup.number().nullable().label(fields.zoneId.label),
    remarks: yup.string().label(fields.remarks.label),
    instructions: yup.string().label(fields.instructions.label),
    contactPersonName: yup.string().label(fields.contactPersonName.label),
    contactPersonPhoneNumber: yup.string().nullable().label(fields.contactPersonPhoneNumber.label),
    contactPersonNameTwo: yup.string().label(fields.contactPersonNameTwo.label),
    contactPersonPhoneNumberTwo: yup.string().nullable().label(fields.contactPersonPhoneNumberTwo.label),
    isContactPersonSendNotification: yup.bool().oneOf([true, false]),
    isContactPersonTwoSendNotification: yup.bool().oneOf([true, false]),
    weekdayId: yup.array().nullable().label(fields.weekdayId.label),
    weekendId: yup.array().nullable().label(fields.weekendId.label),
    driverId: yup.array().nullable().label(fields.driverId.label),
    copySiteRemarksToJob: yup.bool().oneOf([true, false]).label(fields.copySiteRemarksToJob.label),
  })
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const prepareForm = (values: any = null, defValues: any = null) => {
  let form = _.cloneDeep(values);
  let data = _.cloneDeep(defValues);
  
  if(data && form){
    data['isInvalid'] = false;
    data['siteName'] = (form && form.siteName && form.siteName !== '') ? form.siteName : '';
  }
  
  return data;
};
export const prepareData = (values: any = null, customerId: any = null) => {
  let data: any = {};

  if(values){
    let driverIds = (values && values.driverId && values.driverId.length > 0) ? values.driverId.join(',') : '';
    let accountWeekdayJobWorkingTimeIds = (values && values.weekdayId && values.weekdayId.length > 0) ? values.weekdayId.join(',') : '';
    let accountWeekendJobWorkingTimeIds = (values && values.weekendId && values.weekendId.length > 0) ? values.weekendId.join(',') : '';

    let weekdaysAvailability = (values && values.weekdaysAvailability) ? values.weekdaysAvailability : CustomerSiteAvailability.All;
    let weekendAvailability = (values && values.weekendAvailability) ? values.weekendAvailability : CustomerSiteAvailability.All;

    let isDeactivateOrDelete = ((values.isDeactivateOrDelete === false) || (values.isDeactivateOrDelete === true)) ? !values.isDeactivateOrDelete : false;
    let copySiteRemarksToJob = ((values.copySiteRemarksToJob === false) || (values.copySiteRemarksToJob === true)) ? values.copySiteRemarksToJob : false;

    let latitude = values.latitude;
    let longitude = values.longitude;
    let coordinates = (values && values.coordinates && values.coordinates != '') ? values.coordinates.split(',') : [];
    latitude = (coordinates && coordinates.length > 0 && coordinates[0]) ? coordinates[0] : values.latitude;
    longitude = (coordinates && coordinates.length > 0 && coordinates[1]) ? coordinates[1] : values.longitude;

    let isContactPersonSendNotification = ((values.isContactPersonSendNotification === false) || (values.isContactPersonSendNotification === true)) ? values.isContactPersonSendNotification : false;
    let isContactPersonTwoSendNotification = ((values.isContactPersonTwoSendNotification === false) || (values.isContactPersonTwoSendNotification === true)) ? values.isContactPersonTwoSendNotification : false;
    
    
    data['customerId'] = customerId;
    data['customSiteName'] = values.customSiteName;
    data['siteName'] = values.siteName;
    data['street'] = values.street;
    data['blockNo'] = values.blockNo;
    data['unitNo'] = values.unitNo;
    data['postalCode'] = values.postalCode;
    data['latitude'] = latitude;
    data['longitude'] = longitude;

    data['isDeactivateOrDelete'] = isDeactivateOrDelete;
    data['remarks'] = values.remarks;
    data['instructions'] = values.instructions;
    data['contactPersonName'] = values.contactPersonName;
    data['contactPersonPhoneNumber'] = values.contactPersonPhoneNumber;
    data['contactPersonNameTwo'] = values.contactPersonNameTwo;
    data['contactPersonPhoneNumberTwo'] = values.contactPersonPhoneNumberTwo;
    data['isContactPersonSendNotification'] = isContactPersonSendNotification;
    data['isContactPersonTwoSendNotification'] = isContactPersonTwoSendNotification;
    data['driverIds'] = driverIds;
    data['accountWeekdayJobWorkingTimeIds'] = (weekdaysAvailability === CustomerSiteAvailability.SelectedHours) ? accountWeekdayJobWorkingTimeIds : (weekdaysAvailability === CustomerSiteAvailability.All) ? '' : null;
    data['accountWeekendJobWorkingTimeIds'] = (weekendAvailability === CustomerSiteAvailability.SelectedHours) ? accountWeekendJobWorkingTimeIds : (weekendAvailability === CustomerSiteAvailability.All) ? '' : null;
    data['weekdaysAvailability'] = weekdaysAvailability;
    data['weekendAvailability'] = weekendAvailability;
    data['copySiteRemarksToJob'] = copySiteRemarksToJob;

    if(values.zoneId !== 0){
      data['zoneId'] = values.zoneId;
    }
  }

  return data;
};


export interface initialValuesStruct {
  isInvalid: boolean,
  customSiteName: string,
  siteName: string,
  street: string,
  blockNo: string,
  unitNo: string,
  postalCode: string,
  latitude: string,
  longitude: string,
  coordinates: string,
  zoneId: any,
  zoneName: string,
  remarks: string,
  instructions: string,
  contactPersonName: string,
  contactPersonPhoneNumber: string,
  contactPersonNameTwo: string,
  contactPersonPhoneNumberTwo: string,
  isContactPersonSendNotification: boolean,
  isContactPersonTwoSendNotification: boolean,
  driverId: any,
  weekdayId: any,
  weekendId: any,
  weekdaysAvailability: any,
  weekendAvailability: any,
  copySiteRemarksToJob: boolean,
};
export const initialValues: initialValuesStruct = {
  isInvalid: false,
  customSiteName: '',
  siteName: '',
  street: '',
  blockNo: '',
  unitNo: '',
  postalCode: '',
  latitude: '',
  longitude: '',
  coordinates: '',
  zoneId: null,
  zoneName: '',
  remarks: '',
  instructions: '',
  contactPersonName: '',
  contactPersonPhoneNumber: '',
  contactPersonNameTwo: '',
  contactPersonPhoneNumberTwo: '',
  isContactPersonSendNotification: false,
  isContactPersonTwoSendNotification: false,
  driverId: null,
  weekdayId: null,
  weekendId: null,
  weekdaysAvailability: CustomerSiteAvailability.All,
  weekendAvailability: CustomerSiteAvailability.All,
  copySiteRemarksToJob: false,
};


interface InitState {
  isLoading: boolean,
  show: boolean,
  customerId: number|null,
  fieldType: number|null,
  id: any|null,
  items: Array<any>,
  details: any,

  isLoadingDriver: boolean,
  driverItems: Array<any>,

  weekdayIsLoading: boolean,
  weekdayIsSelectAll: boolean,
  weekdayItems: Array<any>,

  weekendIsLoading: boolean,
  weekendIsSelectAll: boolean,
  weekendItems: Array<any>,
}

function NewReducer() {
  const name = 'customerSiteMiniSlice';


  const initialState: InitState = {
    isLoading: false,
    show: false,
    customerId: null,
    fieldType: null,
    id: null,
    items: [],
    details: initialValues,

    isLoadingDriver: false,
    driverItems: [],

    weekdayIsLoading: false,
    weekdayIsSelectAll: false,
    weekdayItems: [],

    weekendIsLoading: false,
    weekendIsSelectAll: false,
    weekendItems: [],
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setId: (state: InitState, action: PayloadAction<any>) => {
      state.id = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<{ show: boolean, id: number|null, customerId: number|null, fieldType: number|null }>) => {
      state.id = action.payload.id;
      state.customerId = action.payload.customerId;
      state.fieldType = action.payload.fieldType;
      state.show = action.payload.show;
    },
    setValues: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
    },

    startRead: (state: InitState) => {
      state.isLoading = true;
      state.items = [];
    },
    finishRead: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      state.items = data;
    },

    startDetails: (state: InitState) => {
      state.isLoading = true;
    },
    finishDetails: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.details = action.payload;
    },

    startCreate: (state: InitState) => {
      state.isLoading = true;
    },
    finishCreate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },

    startUpdate: (state: InitState) => {
      state.isLoading = true;
    },
    finishUpdate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },

    startDelete: (state: InitState) => {
      state.isLoading = true;
    },
    finishDelete: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },

    startReadDriver: (state: InitState) => {
      state.isLoadingDriver = true;
      state.driverItems = [];
    },
    finishReadDriver: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingDriver = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      
      let arr = _.cloneDeep(state.driverItems);
      if(data && data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          let item = data[i];
          let value = item.driverId;
          let title = item.driverName;

          let defaultVehicle = (item && item.defaultVehicle) ? item.defaultVehicle : null;
          let vehicleName = (defaultVehicle && defaultVehicle.vehicleName) ? defaultVehicle.vehicleName : null;

          let arrItem: any = {
            value: value,
            title: Utils.customConcat([title, vehicleName], ' / '),
            item: item,
          };

          arr = Utils.addToArray(arr, value, arrItem);
        }
      }
      
      state.driverItems = arr;
    },
    
    setWeekdayIsSelectAll: (state: InitState, action: PayloadAction<boolean>) => {
      state.weekdayIsSelectAll = action.payload;
    },
    startReadWeekdayTimes: (state: InitState) => {
      state.weekdayIsLoading = true;
      state.weekdayItems = [];
    },
    finishReadWeekdayTimes: (state: InitState, action: PayloadAction<any>) => {
      state.weekdayIsLoading = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      
      let arr = _.cloneDeep(state.weekdayItems);
      if(data && data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          let item = data[i];
          let value = item.accountJobWorkingTimeId;
          let title = item.workingTimeName;
          let arrItem: any = {
              value: value,
              title: title,
              item: item,
          };

          arr = Utils.addToArray(arr, value, arrItem);
        }
      }
      
      state.weekdayItems = arr;
    },
    

    setWeekendIsSelectAll: (state: InitState, action: PayloadAction<boolean>) => {
      state.weekendIsSelectAll = action.payload;
    },
    startReadWeekendTimes: (state: InitState) => {
      state.weekendIsLoading = true;
      state.weekendItems = [];
    },
    finishReadWeekendTimes: (state: InitState, action: PayloadAction<any>) => {
      state.weekendIsLoading = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      
      let arr = _.cloneDeep(state.weekendItems);
      if(data && data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          let item = data[i];
          let value = item.accountJobWorkingTimeId;
          let title = item.workingTimeName;
          let arrItem: any = {
              value: value,
              title: title,
              item: item,
          };

          arr = Utils.addToArray(arr, value, arrItem);
        }
      }
      
      state.weekendItems = arr;
    },
  };


  const apis = {
    callReadApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startRead());
  
        let paramsData: any = params;

        await dispatchCrudApi.readApi(paramsData, 'customersite').then(result => {
            let data = result.data;
            
            callback(true, data);
            dispatch(actions.finishRead(data));
        }).catch(error => {
            let res: ParseResult = {
                isError: false,
                errorMessage: null,
                status: null,
            };
    
            Utils.parseErrorTS(error, (result: ParseResult): void => {
                res = result
            });
            
            let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
            if(res.isError && err){
                Utils.toast(err, 'error');
            }
            
            callback(false, null);
            dispatch(actions.finishRead(null));
        });
    },

    callDetailsApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDetails());
  
        await dispatchCrudApi.readApi(null, 'customersite/' + id).then(result => {
            let data = result.data;
            
            callback(true, data);
            dispatch(actions.finishDetails(data));
        }).catch(error => {
            let res: ParseResult = {
                isError: false,
                errorMessage: null,
                status: null,
            };
    
            Utils.parseErrorTS(error, (result: ParseResult): void => {
                res = result
            });
            
            let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
            if(res.isError && err){
                Utils.toast(err, 'error');
            }
            
            callback(false, null);
            dispatch(actions.finishDetails(null));
        });
    },

    callCreateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startCreate());
  
        await dispatchCrudApi.createApi(params, 'customersite').then(result => {
            let data = result.data;
            
            callback(true, data);
            dispatch(actions.finishCreate(data));
        }).catch(error => {
            let res: ParseResult = {
                isError: false,
                errorMessage: null,
                status: null,
            };
    
            Utils.parseErrorTS(error, (result: ParseResult): void => {
                res = result
            });
            
            let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
            if(res.isError && err){
                Utils.toast(err, 'error');
            }
            
            callback(false, null);
            dispatch(actions.finishCreate(null));
        });
    },

    callUpdateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startUpdate());
  
        await dispatchCrudApi.updateApi(params, 'customersite').then(result => {
            let data = result.data;
            
            callback(true, data);
            dispatch(actions.finishUpdate(data));
        }).catch(error => {
            let res: ParseResult = {
                isError: false,
                errorMessage: null,
                status: null,
            };
    
            Utils.parseErrorTS(error, (result: ParseResult): void => {
                res = result
            });
            
            let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
            if(res.isError && err){
                Utils.toast(err, 'error');
            }
            
            callback(false, null);
            dispatch(actions.finishUpdate(null));
        });
    },

    callDeleteApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDelete());
  
        let paramsData: any = { data: params };

        await dispatchCrudApi.deleteApi(paramsData, 'customersite').then(result => {
            let data = result.data;
            
            callback(true, data);
            dispatch(actions.finishDelete(data));
        }).catch(error => {
            let res: ParseResult = {
                isError: false,
                errorMessage: null,
                status: null,
            };
    
            Utils.parseErrorTS(error, (result: ParseResult): void => {
                res = result
            });
            
            let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
            if(res.isError && err){
                Utils.toast(err, 'error');
            }
            
            callback(false, null);
            dispatch(actions.finishDelete(null));
        });
    },

    callReadDriverApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startReadDriver());

      let paramsData: any = params;

      await dispatchCrudApi.readApi(paramsData, 'driver').then(result => {
          let data = result.data;
          
          callback(true, data);
          dispatch(actions.finishReadDriver(data));
      }).catch(error => {
          let res: ParseResult = {
              isError: false,
              errorMessage: null,
              status: null,
          };
  
          Utils.parseErrorTS(error, (result: ParseResult): void => {
              res = result
          });
          
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
              Utils.toast(err, 'error');
          }
          
          callback(false, null);
          dispatch(actions.finishReadDriver(null));
      });
    },

    callReadWeekdayTimesApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startReadWeekdayTimes());

      let paramsData: any = params;

      await dispatchCrudApi.readApi(paramsData, 'AccountJobWorkingTime').then(result => {
          let data = result.data;
          
          callback(true, data);
          dispatch(actions.finishReadWeekdayTimes(data));
      }).catch(error => {
          let res: ParseResult = {
              isError: false,
              errorMessage: null,
              status: null,
          };
  
          Utils.parseErrorTS(error, (result: ParseResult): void => {
              res = result
          });
          
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
              Utils.toast(err, 'error');
          }
          
          callback(false, null);
          dispatch(actions.finishReadWeekdayTimes(null));
      });
    },

    callReadWeekendTimesApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startReadWeekendTimes());

      let paramsData: any = params;

      await dispatchCrudApi.readApi(paramsData, 'AccountJobWorkingTime').then(result => {
          let data = result.data;
          
          callback(true, data);
          dispatch(actions.finishReadWeekendTimes(data));
      }).catch(error => {
          let res: ParseResult = {
              isError: false,
              errorMessage: null,
              status: null,
          };
  
          Utils.parseErrorTS(error, (result: ParseResult): void => {
              res = result
          });
          
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
              Utils.toast(err, 'error');
          }
          
          callback(false, null);
          dispatch(actions.finishReadWeekendTimes(null));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();