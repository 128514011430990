import React from 'react';

import { Button } from 'react-bootstrap-v5';

const IconButtonNoTitle = props => {
    return <Button {...props} size='sm' variant='link'>
        {props.children}
    </Button>
}


export default IconButtonNoTitle;