import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { 
    Input,
    Icon
} from '@material-ui/core';

import ManageLabel from '../input/ManageLabel.jsx';


class CustomCheckInput extends Component {
  
	constructor(props){
		super(props);

        this.refInput = React.createRef();

        let label = props.label ? props.label : "";
        let labelComponent = props.labelComponent ? props.labelComponent : null;
        let name = props.name ? props.name : "";
        let value = props.value ? props.value : "";
        let type = props.type ? props.type : "text";
        let width = props.width ? props.width : "100%";
        let autoComplete = props.autoComplete ? props.autoComplete : "";
        let placeholder = props.placeholder ? props.placeholder : "";
        let className = props.className ? props.className : "";
        let helperText = props.helperText ? props.helperText : "";
        let errorText = props.errorText ? props.errorText : "";
        let rows = props.rows ? props.rows : 1;
        let rowsMax = props.rowsMax ? props.rowsMax : null;
        let autoFocus = ((this.props.autoFocus === true || this.props.autoFocus === false) ? this.props.autoFocus : false);
        let disabled = ((this.props.disabled === true || this.props.disabled === false) ? this.props.disabled : false);
        let readOnly = ((this.props.readOnly === true || this.props.readOnly === false) ? this.props.readOnly : false);
        let disableUnderline = ((this.props.disableUnderline === true || this.props.disableUnderline === false) ? this.props.disableUnderline : true);
        let error = ((this.props.error === true || this.props.error === false) ? this.props.error : false);
        let fullWidth = ((this.props.fullWidth === true || this.props.fullWidth === false) ? this.props.fullWidth : true);
        let multiline = ((this.props.multiline === true || this.props.multiline === false) ? this.props.multiline : false);
        let required = ((this.props.required === true || this.props.required === false) ? this.props.required : false);
        let startAdornment = props.startAdornment ? props.startAdornment : null;
        let inputProps = props.inputProps ? props.inputProps : {};
        let oldValue = value;

		this.state = {
            label: label,
            labelComponent: labelComponent,
            className: className,

            name: name,
            value: props.value,
            oldValue: oldValue,
            placeholder: placeholder,
            type: type,
            inputProps: inputProps,

            disabled: disabled,
            readOnly: readOnly,
            required: required,

            autoComplete: autoComplete,
            autoFocus: autoFocus,
            disableUnderline: disableUnderline,
            multiline: multiline,
            rows: rows,
            rowsMax: rowsMax,
            fullWidth: fullWidth,
            width: width,

            startAdornment: startAdornment,

            error: error,
            errorText: errorText,
            helperText: helperText,

            id: (name !== '') ? name + '-id' : new Date().getTime() + '-id',
		}
    }
    

    componentWillReceiveProps(nextProps) {
		if (nextProps.value !== this.state.value) {
			// this.setState({
			// 	value: nextProps.value ? nextProps.value : "",
			// 	oldValue: nextProps.value ? nextProps.value : "",
			// });
        }
        if (nextProps.type !== this.state.type) {
			this.setState({
				type: nextProps.type
			});
        }
        if (nextProps.placeholder !== this.state.placeholder) {
			this.setState({
				placeholder: nextProps.placeholder
			});
        }
        if (nextProps.disabled !== this.state.disabled) {
			this.setState({
				disabled: nextProps.disabled
			});
        }
        if (nextProps.readOnly !== this.state.readOnly) {
			this.setState({
				readOnly: nextProps.readOnly
			});
        }
        if (nextProps.required !== this.state.required) {
			this.setState({
				required: nextProps.required
			});
        }
        if (nextProps.error !== this.state.error) {
			this.setState({
				error: nextProps.error
			});
		}
        if (nextProps.startAdornment !== this.state.startAdornment) {
			this.setState({
				startAdornment: nextProps.startAdornment
			});
		}
        if (nextProps.helperText !== this.state.helperText) {
			this.setState({
				helperText: nextProps.helperText
			});
		}
        if (nextProps.errorText !== this.state.errorText) {
			this.setState({
				errorText: nextProps.errorText
			});
		}
        if (nextProps.rowsMax !== this.state.rowsMax) {
			this.setState({
				rowsMax: nextProps.rowsMax
			});
		}
        if (nextProps.inputProps !== this.state.inputProps) {
            let inputProps = nextProps.inputProps ? nextProps.inputProps : {};
			this.setState({
				inputProps: inputProps
			});
		}
    }

    resize = (event) => {
        event.target.scrollTop = event.target.scrollHeight;
    };


    getNode(element) {
        var node = ReactDOM.findDOMNode(element);
        node.style.lineHeight = 'unset';
        return node;
    }
    getElement(node, className) {
        const el = node.querySelector(className);
        el.style.lineHeight = 'unset';
        return el;
    }

    triggerOnChange(value = '') {
        var lastValue = this.state.value;
        var node = this.getNode(this.refInput.current);
        var element = this.getElement(node, '.MuiInput-input');
        this.setState({
            value: value,
            oldValue: value,
        }, () => {
            var event = new Event('input', { bubbles: true });
            event.simulated = true;
            let tracker = element._valueTracker;
            if (tracker) {
                tracker.setValue(lastValue);
            }
            element.dispatchEvent(event);
        });
    }

    endAdornment = () => {
        return <div className={'custom-end-adornment ' + (this.state.type ? this.state.type : '')}>
            {this.endAdornmentCheckIcon()}
            {this.endAdornmentXIcon()}
        </div>
    };
    endAdornmentCheckIcon = () => {
        if(this.isSameAsOldValue()) {
            return <Icon 
                className={'custom-check-icon'} 
                component={'i'}
                onClick={(e) => {
                    e.stopPropagation();

                    this.setState({
                        oldValue: this.state.value
                    }, () => {
                        if(this.props.onCheck)
                            this.props.onCheck(this.state.value);
                    });
                }}
            >check_circle</Icon>
        }
    };
    endAdornmentXIcon = () => {
        if(this.isSameAsOldValue()) {
            return <Icon 
                className={'custom-x-icon'} 
                component={'i'}
                onClick={(e) => {
                    e.stopPropagation();
                    // this.triggerOnChange('');

                    this.setState({
                        value: this.state.oldValue
                    });
                }}
            >add_circle</Icon>
        }
    };

    isSameAsOldValue = () => {
        if (this.state.type !== 'number') {
            return (this.state.value != this.state.oldValue) ? true : false;
        } else {
            let value = isNaN(parseFloat(this.state.value)) ? parseFloat(0) : parseFloat(this.state.value);
            let oldValue = isNaN(parseFloat(this.state.oldValue)) ? parseFloat(0) : parseFloat(this.state.oldValue);
            return (value != oldValue) ? true : false;
        }
    };


	render() {
		return <div className={"custom-check-input-component " + this.state.className + ' ' + (this.state.type ? this.state.type : '')}>
            {this.state.label && <ManageLabel 
                className={"custom-label"}
                label={this.state.label}
                labelComponent={this.state.labelComponent}
                disabled={this.state.disabled}
                error={this.state.error}
                required={this.state.required}
            />}
            <div 
                style={{
                    position: 'relative'
                }} 
            >
                <Input 
                    className={"custom-input"}
                    value={this.state.value}
                    id={this.state.id}
                    name={this.state.name}
                    type={this.state.type}
                    style={{ width: this.state.width }}
                    autoComplete={this.state.autoComplete}
                    autoFocus={this.state.autoFocus}
                    disabled={this.state.disabled}
                    readOnly={this.state.readOnly}
                    disableUnderline={this.state.disableUnderline}
                    startAdornment={this.state.startAdornment}
                    error={this.state.error}
                    fullWidth={this.state.fullWidth}
                    multiline={this.state.multiline}
                    rows={this.state.rows}
                    maxRows={this.state.maxRows}
                    placeholder={this.state.placeholder}
                    required={this.state.required}
                    inputProps={this.state.inputProps}
                    ref={this.refInput}
                    onClick={(e) => {
                        e.stopPropagation();

                        if(this.props.onClick)
                            this.props.onClick(e);
                    }}
                    onMouseDown={(e) => {
                        e.stopPropagation();

                        if(this.props.onMouseDown)
                            this.props.onMouseDown(e);
                    }}
                    onChange={(event) => {
                        this.resize(event);

                        let pattern = null;
                        if(this.state.inputProps && this.state.inputProps.pattern){
                            pattern = this.state.inputProps.pattern;
                        }

                        if(pattern){
                            const regex = RegExp(pattern);
                            if(event.target.value.match(regex) !== null){
                                this.setState({
                                    value: event.target.value
                                });
                        
                                if(this.props.onChange)
                                    this.props.onChange(event);
                            }
                        } else {
                            this.setState({
                                value: event.target.value
                            });
                        
                            if(this.props.onChange)
                                this.props.onChange(event);
                        }
                    }}
                    onFocus={() => {
                        if(this.props.onFocus) {
                            this.props.onFocus();
                        }
                    }}
                    onBlur={() => {
                        if(this.props.onBlur) {
                            this.props.onBlur();
                        }
                    }}
                    onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                            this.setState({
                                oldValue: this.state.value
                            }, () => {
                                if(this.props.onPressEnter){
                                    this.props.onPressEnter(this.state.value);
                                }
                            });
                        }
                    }}
                />
                {this.endAdornment()}
            </div>
            {(this.state.error && this.state.errorText) && <ManageLabel 
                className={"custom-label text-danger"}
                size={'sm'}
                label={this.state.errorText}
                disabled={this.state.disabled}
                error={this.state.error}
                required={this.state.required}
            />}
            {this.state.helperText && <ManageLabel 
                className={"custom-label " + (this.state.error ? 'text-danger' : '')}
                size={'sm'}
                label={this.state.helperText}
                disabled={this.state.disabled}
                error={this.state.error}
                required={this.state.required}
            />}
		</div>;
	}
}

export default CustomCheckInput;
