import React, { useState } from "react";

import { compose, withProps, lifecycle } from "recompose";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
} from "react-google-maps";

const styles = [
  {
    featureType: "poi",
    stylers: [
     { visibility: "off" }
    ]   
   }
];

const { REACT_APP_GOOGLE_MAP_API_KEY } = process.env;
const URL =
  "https://maps.googleapis.com/maps/api/js?key=" +
  REACT_APP_GOOGLE_MAP_API_KEY +
  "&v=3.exp&libraries=geometry,drawing,places";

const ZOOM = 2;
const CENTER = { lat: 0.12463108622583872, lng: 10.621379999999983 };

const CenterAndZoomGoogleMap = compose(
  withProps({
    googleMapURL: URL,
    loadingElement: <div style={{ width: "100%", height: "100%" }} />,
    containerElement: <div style={{ width: "100%", height: "100%" }} />,
    mapElement: <div style={{ width: "100%", height: `100%` }} />,
  }),
  lifecycle({
    componentWillMount() {
      const refs = {};
      
      this.setState({
        count: 0,
        mapCenter: (this.props.defaultCenter ? this.props.defaultCenter : CENTER),

        onMapMounted: (ref) => {
          refs.map = ref;
        },
        onMarkerMounted: (ref) => {
          refs.marker = ref;
        },
        onCenterChanged: () => {
          let center = refs.map.getCenter();
          let coord = { lat: center.lat(), lng: center.lng() }
          this.setState({
            mapCenter: coord,
          });
        },
        onZoomChanged: () => {
          let position = refs.marker.getPosition();
          let zoom = refs.map.getZoom();
          if (this.props.onZoomChange) {
            this.props.onZoomChange(zoom, position);
          }
        },
        onDragEnd: (ref) => {
          let position = refs.marker.getPosition();
          let zoom = refs.map.getZoom();
          if (this.props.onCenterChange) {
            this.props.onCenterChange(position, zoom);
          }

          this.setState({
            mapCenter: { lat: position.lat(), lng: position.lng() },
          });
        },
        onRecenterClick: (center) => {
          this.setState({
            mapCenter: center,
          });
        },
        isEqual: (center) => {
          return center.lat === this.state.mapCenter.lat && center.lng === this.state.mapCenter.lng
        },
      })
    }
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  const [center, setCenter] = useState(props.defaultCenter ? props.defaultCenter : CENTER);
  const [zoom, setZoom] = useState(props.defaultZoom ? props.defaultZoom : ZOOM);


  React.useEffect(() => {
    setCenter(props.defaultCenter ? props.defaultCenter : CENTER);
  }, [ props.defaultCenter ]);

  React.useEffect(() => {
    setZoom(props.defaultZoom ? props.defaultZoom : ZOOM);
  }, [ props.defaultZoom ]);


  const googleMap = () => {
    if (typeof window.google !== 'undefined' && typeof window.google.maps !== 'undefined') {
      return (
        <div>
          <GoogleMap
            ref={props.onMapMounted}

            defaultZoom={zoom}
            zoom={zoom}
            onZoomChanged={props.onZoomChanged}
            onCenterChanged={props.onCenterChanged}

            defaultCenter={props.mapCenter}
            center={props.mapCenter}

            defaultOptions={{
              styles: styles,
              disableDefaultUI: true,
              disableDoubleClickZoom: true,
              rotateControl: false,
              zoomControl: true,
              fullscreenControl: false,
              streetViewControl: false,
              streetViewControlOptions: {
                position: 7,
              },
              mapTypeControl: false,
            }}
          >
            {center && <Marker
              ref={props.onMarkerMounted}
              title={'Center of the map'}
              position={center}
              options={{
                icon: (props.targetIcon && props.targetIcon != '') ? {
                  ...props.targetIcon,
                  anchor: new window.google.maps.Point(12, 12),
                  scaledSize: new window.google.maps.Size(24, 24),
                } : {
                  anchor: new window.google.maps.Point(12, 12),
                  scaledSize: new window.google.maps.Size(24, 24),
                },
              }}
              onDragEnd={props.onDragEnd}
              draggable={true}
            />}

            {!props.isEqual(center) && <button
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                zIndex: 1,
                cursor: 'pointer',

                background: 'none',
                display: 'block',
                border: '0px',
                margin: '0px',
                padding: '0px 10px',
                textTransform: 'none',
                appearance: 'none',

                boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
                borderRadius: '2px',
                backgroundColor: 'rgb(255, 255, 255)',
                height: '40px',
                fontWeight: '500',
              }}
              onClick={() => {
                props.onRecenterClick(center);
              }}
            >
              Recenter
            </button>}

          </GoogleMap>
        </div>
      );
    }
  };

  return googleMap();
});

export default CenterAndZoomGoogleMap;
