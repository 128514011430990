import React from 'react';

import { 
    Modal,
    Form,
    Button,
    Spinner,
    CloseButton,
    Row,
    Col,
} from "react-bootstrap-v5";


const fields = {
    field: {
        id: 'categoryName',
        label: 'Category name',
        placeholder: ' ',
        error: 'Category name is a required field',
    },
}

  
const CategoryDialog = props => {
    const item = props.item ? props.item : null;
    const title = (item && item.title && item.title !== '') ? item.title : '';

    const [isLoading, setIsLoading] = React.useState(((props.isLoading === false) || (props.isLoading === true)) ? props.isLoading : false);
    const [show, setShow] = React.useState(((props.show === false) || (props.show === true)) ? props.show : false);
    const [value, setValue] = React.useState(title);
    const [validation, setValidation] = React.useState((value && value !== '') ? false : true);
	
    const autoFocusRef = React.useRef();
    
    React.useEffect(() => {
        setIsLoading(((props.isLoading === false) || (props.isLoading === true)) ? props.isLoading : false);
    }, [props.isLoading]);

    React.useEffect(() => {
        const item = props.item ? props.item : null;
        const title = (item && item.title && item.title !== '') ? item.title : '';

        setValue(title);
        setValidation((title && title !== '') ? false : true);
        setShow(((props.show === false) || (props.show === true)) ? props.show : false);
    }, [props.show, props.item]);


    return <Modal
        show={show}
        onShow={() => { autoFocusRef.current.focus() }}
        onHide={() => {
            if(props.onHide){
                props.onHide();
            }
        }}
        centered
        backdrop="static"
        size="lg"
        // keyboard={false}
    >
        <Modal.Header>
            <Row className={'align-items-center gx-0 w-100'}>
                <Col xs={true}>
                    <Modal.Title>Waste type category</Modal.Title>
                </Col>
                <Col xs={'auto'}>
                    <CloseButton
                        className={'rounded-2'}
                        onClick={() => {
                            if(props.onHide){
                                props.onHide();
                            }
                        }}
                    />
                </Col>
            </Row>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Form.Text>{fields.field.placeholder}</Form.Text>
                <Form.Control
                    ref={autoFocusRef}
                    type="text"
                    autoComplete="off"
                    id={fields.field.id}
                    placeholder={fields.field.placeholder}
                    value={value}
                    onChange={(e) => {
                        setValue(e.target.value);
                        setValidation((e.target.value !== '') ? false : true);
                    }}
                    isInvalid={validation}
                />
                <Form.Control.Feedback type="invalid">{fields.field.error}</Form.Control.Feedback>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button 
                type={'button'}
                variant={'secondary'}
                disabled={isLoading}
                onClick={() => {
                    if(props.onHide){
                        props.onHide();
                    }
                }}
            >
                CANCEL
                {isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
            </Button>
            <Button 
                type={'button'}
                disabled={isLoading || validation}
                onClick={() => {
                    if(props.onSave){
                        props.onSave(value, item);
                    }
                }}
            >
                SAVE
                {isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
            </Button>
        </Modal.Footer>
    </Modal>
}


export default CategoryDialog;
