import React, { Component } from "react";
import SplitPane from 'react-split-pane';
import { Formik } from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';

import { 
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  ListGroup,
  Dropdown,
  Spinner,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import CurrentDateTime from '../../../components/currentDateTime/CurrentDateTime'

import ViewLayout from "../layout/ViewLayout";

import RemoveDialog from '../../../components/dialog/RemoveDialog';
import WrappedButton from '../../../components/Buttons/WrappedButton';
import DropdownToggle from '../../../components/dropdown/DropdownToggle';
import SearchDialog from '../../../components/dialog/SearchDialog';
import GeofenceMapView from '../../../components/geofence/GeofenceMapView'
import SmartInputDropdown from '../../../components/dropdown/smartInputDropdown/SmartInputDropdown';
import makeCRUD from "../../../components/dropdown/smartInputDropdown/makeCRUD";

import Utils from '../../../utils/utils'
import { SortColumns } from '../../../utils/enums'

import { readApi } from '../../../api/CRUD/DispatchCRUD'

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut, dispatchApiCallDelete } from '../../../../setup/redux/dispatch/actions'
import geofenceMapViewSlice from "../../../components/geofence/geofenceMapViewSlice";


const SmartDropdown = makeCRUD(SmartInputDropdown);


const StyledListGroupItem = styled(ListGroup.Item)`
  &.list-group-item {
    border-left-width: 3px;
    transition: border-left-width 0.1s;
  }

  &.active {
    background-color: transparent;
    border-color: transparent;
    color: #484848;
  }

  &.activate {
    background-color: #ecf5df;
    color: #484848;
    border-top-color: #F5F8FA;
    border-bottom-color: #F5F8FA;
    border-left-width: 6px;

    .title {
      font-weight: bold;
    }
    .desc {
      font-style: normal;
    }
  }

  &.hover {
    background-color: #F5F8FA;
  }
`;
const StyledTitle = styled.div`
  font-weight: 500;
`;
const StyledDesc = styled.small`
  font-size: 10px;
  font-style: italic;
`;
const StyledDelete = styled.a`
  position: relative;
  top: 3px;

  i {
    color: #484848;
    font-size: 20px;
    transition: all 0.1s;
  }

  &:hover {
    i {
      color: #FF0090;
    }
  }
`;
const StyledEdit = styled.a`
  position: relative;
  top: 3px;

  i {
    color: #484848;
    font-size: 20px;
    transition: all 0.1s;
  }

  &:hover {
    i {
      color: #FFA800;
    }
  }
`;
const StyledHide = styled.a`
  position: relative;
  top: 3px;
  right: 3px;

  i {
    color: #484848;
    font-size: 20px;
    transition: all 0.1s;
  }

  &:hover {
    i {
      color: #185cff;
    }
  }
`;
const StyledSortItem = styled(Dropdown.Item)`
    position: relative;
    display: flex;
    align-items: center;

    .custom-title {
        display: inline-block;
        flex-basis: 100%;
        width: 130px;
        white-space: pre-wrap;
        color: #484848;

        ${(props) => {
            return (props.active) ? 'font-weight: bold;' : '';
        }}
    }

    .custom-icons {
        display: inline-block;
        position: relative;
        width: 20px;
        height: 20px;
        overflow: hidden;
        background-color: transparent !important;

        .custom-icon-up {
            overflow: hidden;
            position: relative;
            height: 12px;

            > div {
                position: absolute;
                top: -6px;

                i {
                    ${(props) => {
                        return (props.active && props.direction == 'asc') ? 'color: #484848;' : 'color: #B8B8B8;';
                    }}

                    &:hover {
                        color: #484848;
                    }
                }
            }

            ${(props) => {
                return (props.active) ? 'display: block;' : 'display: none;';
            }}
        }

        .custom-icon-down {
            overflow: hidden;
            position: relative;
            height: 12px;

            > div {
                position: absolute;
                top: -9px;

                i {
                    ${(props) => {
                        return (props.active && props.direction == 'desc') ? 'color: #484848;' : 'color: #B8B8B8;';
                    }}

                    &:hover {
                        color: #484848;
                    }
                }
            }

            ${(props) => {
                return (props.active) ? 'display: block;' : 'display: none;';
            }}
        }
    }
    
    &.active, &:active, &:focus {
        ${(props) => {
            return props.active ? 'background-color: #ECECEC !important;' : 'background-color: transparent !important;';
        }}
    }

    &:hover {
        .custom-icons {
            .custom-icon-up {
                display: block;
            }
            .custom-icon-down {
                display: block;
            }
        }
    }
`;
const StyledSearch = styled(Form.Control)`
  padding-top: 0;
  padding-bottom: 0;
  min-height: 30px;
`;
const StyledSplitPane = styled.div`
  .custome-pane {
    height: calc(100vh - 65px) !important;

    .Pane1 {
      z-index: 8;
    }
    
    .Pane2 {
      display: grid !important;
    }
  }

  .Resizer {
    background: #000;
    opacity: .2;
    z-index: 7;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
  }

  .Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }

  .Resizer.horizontal {
    height: 11px;
    margin: 15px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;

    &:before {
      font-family: "Font Awesome 5 Free";
      font-size: 23px;
      font-weight: 900;
      content: '\f7a4';

      position: relative;
      top: -5px;
      left: 45%;
    }
  }

  .Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
  }

  .Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
  }

  .Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
  }
  .Resizer.disabled {
    cursor: default;
  }
  .Resizer.disabled:hover {
    border-color: transparent;
  }
`;
const StyledPane = styled.div`
  background-color: white;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;
const StyledForm = styled(Form)`
  ${(props) => {
    return (props.isModal) ? 'height: calc(100vh - 115px);' : 'height: unset;';
  }}
  ${(props) => {
    return (props.isModal) ? 'overflow: hidden;' : 'overflow: unset;';
  }}

  @media (max-width: 767px) {
    height: unset;
    overflow: unset;
  }
`;


const formFields = {
  name: {
    id: 'name',
    label: 'Name',
    placeholder: ' ',
  },
  description: {
    id: 'description',
    label: 'Description',
    placeholder: ' ',
  },
  color: {
    id: 'zoneColor',
    label: 'Zone color',
    placeholder: ' ',
  },
  accountMapGeofencePoints: {
    id: 'accountMapGeofencePoints',
    label: 'Geofance',
    placeholder: ' ',
  },
  latitude: {
    id: 'latitude',
    label: 'Latitude',
    placeholder: ' ',
  },
  longitude: {
    id: 'longitude',
    label: 'Longitude',
    placeholder: ' ',
  },
  accountMapGeofenceGroupId: {
    id: 'accountMapGeofenceGroupId',
    label: 'Group',
    placeholder: ' ',
  },
}
const formSchema = (selectedTab) => {
  if(selectedTab === 'tab2'){
    return yup.object().shape({
      isEdit: yup.bool().oneOf([true, false]),
      name: yup.string().required().label(formFields.name.label),
      description: yup.string().label(formFields.name.label),
      color: yup.string().label(formFields.color.label),
      accountMapGeofenceGroupId: yup.number().nullable().label(formFields.accountMapGeofenceGroupId.label),
    })

  } else {
    return yup.object().shape({
      isEdit: yup.bool().oneOf([true, false]),
      name: yup.string().required().label(formFields.name.label),
      description: yup.string().label(formFields.name.label),
      color: yup.string().label(formFields.color.label),
      accountMapGeofenceGroupId: yup.number().nullable().required().label(formFields.accountMapGeofenceGroupId.label),
    })
  }
};


class GeofenceView extends Component {

  constructor(props) {
    super(props);

    this.pageName = "manage-geofence-page";
    this.pageIdField = "accountMapGeofenceId";
    this.pageNameField = "name";
    this.pageAPI = "map-geofence";
    this.pageCaption = 'Geofence'

    this.INIT_VALUES = {
      isEdit: false,
      name: '',
      description: '',
      color: '#000000',
      latlngs: [],
      accountMapGeofenceGroupId: null,
      groupName: '',
      group: null,
    };

		let user = (props.auth && props.auth.user) ? props.auth.user : null;
		this.permissions = (user && user.permissions && user.permissions.length > 0) ? user.permissions : [];

    try {
      props.layoutRef.current = this;
    }catch(e){}

    this.geofenceRef = React.createRef();
    this.refForm = React.createRef();

    this.state = {
      data: null,
      err: null,
      isError: false,
      isLoading: false,

      search: '',
      sortColumn: 'groupName',
      sortDir: 'asc',
      isIncludeInactive: false,
      searchTags: [],
      searchOperator: [],
      searchColumns: [],
      sortColumns: SortColumns.geofence,
      isSearchDialog: false,

      rows1: [],
      rows2: [],
      totalRows: 0,
      currentPage: 1,
      rowsPerPageSelected: Utils.getMaxPageSize(),
      
      polygons: [],

      isRemoveDialogMap: false,
      removeRowMap: null,
      removeRowMapType: 1,

      selectedTab: (this.props.initTab && this.props.initTab != '') ? this.props.initTab : 'tab1',
      hoverId: [],
      selectedId: null,
      selectedGroupId: null,

      sizes: 200,

      isDisabled: true,
      isCreate: false,
      initialValues: this.INIT_VALUES,
    }
  }


  componentDidMount() {
    this.callReadApi();
  }
  
  componentWillReceiveProps(nextProps) {
    this.reduxProps(nextProps);
	}
  

	/* FUNCTIONS */
  reduxProps = nextProps => {
    Utils.reduxProps(nextProps,
      this.pageName + '-create', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, async () => {
          this.props.clear();

          if(callback){
            callback(data);
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      this.pageName + '-update', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, async () => {
          this.props.clear();
          
          if(callback){
            callback(data);
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      this.pageName + '-delete', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          this.props.clear();
          
          if(callback){
            callback(data);
          }
        });
      }
    );
  }

  prepareForm = (dataParams, slice, group = null, shouldDraw = true) => {
    let id = dataParams.id;
    let data = dataParams.row;
    if(data){
      let accountMapGeofencePoints = (data && data.accountMapGeofencePoints && data.accountMapGeofencePoints.length > 0) ? data.accountMapGeofencePoints.sort((a, b) => a.pointOrder - b.pointOrder) : [];
      let latlngs = (accountMapGeofencePoints && accountMapGeofencePoints.length > 0) ? accountMapGeofencePoints.map(point => [point.latitude, point.longitude]) : [];

      let latlng = (latlngs && latlngs.length > 0) ? { lat: latlngs[0][0], lng: latlngs[0][1] } : null;
      
      let groupItem = (data && data.group) ? data.group : null;
      let accountMapGeofenceGroupId = (groupItem && groupItem.accountMapGeofenceGroupId) ? groupItem.accountMapGeofenceGroupId : null;
      let groupName = (groupItem && groupItem.groupName) ? groupItem.groupName : '';
      
      let rowValues = {
        isEdit: true,
        isActive: ((data.isActive === false) || (data.isActive === true)) ? data.isActive : false,

        id: id,
        name: (data && data.name && data.name !== '') ? data.name : '',
        description: (data && data.description && data.description !== '') ? data.description : '',
        color: (data && data.zoneColor && data.zoneColor !== '') ? data.zoneColor : '',
        latlngs: latlngs,
        accountMapGeofenceGroupId: accountMapGeofenceGroupId,
        groupName: groupName,
        group: groupItem,
      };

      if(data.group && (this.state.selectedTab === 'tab2')){
        rowValues['name'] = data.group.groupName ? data.group.groupName : '';
        rowValues['description'] = data.group.description ? data.group.description : '';
        rowValues['color'] = data.group.groupColor ? data.group.groupColor : '';
      } else {
        rowValues['name'] = (data && data.name && data.name !== '') ? data.name : '';
        rowValues['description'] = (data && data.description && data.description !== '') ? data.description : '';
        rowValues['color'] = (data && data.zoneColor && data.zoneColor !== '') ? data.zoneColor : '';
      }

      if(shouldDraw){
        slice.setCoordinates([{...rowValues }]);
        if(latlng){
          slice.setCenter(latlng);
        }
      }

      return rowValues;
    } else {
      let initVal = this.state.initialValues;
      if(group){
        initVal['isEdit'] = true;
        initVal['id'] = group.accountMapGeofenceGroupId ? group.accountMapGeofenceGroupId : '';
        initVal['name'] = group.groupName ? group.groupName : '';
        initVal['description'] = group.description ? group.description : '';
        initVal['color'] = group.groupColor ? group.groupColor : '';
      } else {
        initVal['isEdit'] = false;
        initVal['id'] = '';
        initVal['name'] = '';
        initVal['description'] = '';
        initVal['color'] = '';
      }

      slice.setCoordinates([]);
      return initVal;
    }
  }
  prepareData = (form, dataParams) => {
    let accountMapGeofencePoints = [];
    let latlngs = (form.latlngs && form.latlngs.length > 0) ? form.latlngs : [];
    if(latlngs && latlngs.length > 0){
      accountMapGeofencePoints = latlngs.map((item, i) => {
        return {
          latitude: item[0],
          longitude: item[1],
          pointOrder: i
        }
      });
    }

    let accountMapGeofenceGroupId = (form && form.group) ? form.group.accountMapGeofenceGroupId : null;
    let description = (form && form.description) ? form.description : null;

    let data = {
      isActive: true,

      name: form.name,
      description: description,
      zoneColor: form.color,
      accountMapGeofencePoints: accountMapGeofencePoints,
      accountMapGeofenceGroupId: accountMapGeofenceGroupId,
    };

    if(dataParams.id){
      data[this.pageIdField] = dataParams.id;
    }

    return data;
  }
	/* END FUNCTIONS */

	
	/* API */
  callReadApi = (callback = null) => {
    let data = {
      currentPage: this.state.currentPage,
      pageSize: this.state.rowsPerPageSelected,
      searchQuery: this.state.search,
      sortDir: this.state.sortDir,
      isIncludeInactive: ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : false,
    };

    if (this.state.searchTags && this.state.searchTags.length > 0) {
      data["searchTags"] = Utils.joinItems(this.state.searchTags);
    }
    if (this.state.searchOperator && this.state.searchOperator.length > 0) {
      data["searchOperator"] = Utils.getSelectedOperator(this.state.searchOperator);
    }
    if (this.state.searchColumns && this.state.searchColumns.length > 0) {
      data["searchColumns"] = Utils.getSelectedColumns(this.state.searchColumns);
    }

    let apis = [
      {
        api: readApi,
        path: this.pageAPI,
        params: () => {
          data['sortColumn'] = 'group.' + this.state.sortColumn;
          return data;
        },
        callback: (result) => {
          Utils.parseResult(result, (data) => {
            let arr = [];
            let total = 0;
            if (data && data.data && data.data.length > 0) {
              arr = data.data;
              total = data.total;
            }

            this.setState({
              rows1: arr,
              polygons: arr,
            });
          });
        }
      }
    ];


    if(this.state.selectedTab === 'tab2'){
      apis.push({
        api: readApi,
        path: this.pageAPI + '/group',
        params: () => {
          data['includeGeofence'] = true;
          data['sortColumn'] = this.state.sortColumn;
          
          return data;
        },
        callback: (result) => {
          Utils.parseResult(result, (data) => {
            let arr = [];
            let total = 0;
            if (data && data.data && data.data.length > 0) {
              arr = data.data;
              total = data.total;
            }

            this.setState({
              rows2: arr,
              totalRows: total,
            });
          });
        }
      });
    }


    Utils.callAllApi(apis, (result) => {
      this.setState({
        isLoading: true,
      });
    }, async (result) => {
      let all = [];

      // if(this.state.selectedTab === 'tab2'){
        if(this.state.polygons && this.state.polygons.length > 0){
          this.state.polygons.forEach(polygon => {
            let group = (polygon && polygon.group) ? polygon.group : null;
            let accountMapGeofencePoints = (polygon && polygon.accountMapGeofencePoints && polygon.accountMapGeofencePoints.length > 0) ? polygon.accountMapGeofencePoints.sort((a, b) => a.pointOrder - b.pointOrder) : [];
            let latlngs = (accountMapGeofencePoints && accountMapGeofencePoints.length > 0) ? accountMapGeofencePoints.map(point => [point.latitude, point.longitude]) : [];
          
            all.push({
              name: polygon.name,
              color: polygon.zoneColor,
              latlngs: latlngs,
              group: group,
            });
          });
        }

        await this.props.action.geofenceMapViewSlice.setStaticCoordinates(all);
      // }

      this.setState({
        isLoading: false,
      }, () => {
        if(callback){
          callback();
        }
      });
    });
	}

  callCreateApi = (data, callback = null) => {
		this.props.dispatchApiCallPost(data, this.pageName + '-create', this.pageAPI, data, callback, null);
  }
  callCreateGroupApi = (data, callback = null) => {
		this.props.dispatchApiCallPost(data, this.pageName + '-create', this.pageAPI + '/group', data, callback, null);
  }

  callUpdateApi = (data, callback = null) => {
		this.props.dispatchApiCallPut(data, this.pageName + '-update', this.pageAPI, data, callback, null);
  }
  callUpdateGroupApi = (data, callback = null) => {
		this.props.dispatchApiCallPut(data, this.pageName + '-update', this.pageAPI + '/group', data, callback, null);
  }

  callDeleteApi = (type, data, callback = null) => {
    if(this.state.selectedTab === 'tab1'){
      this.props.dispatchApiCallDelete(data, this.pageName + '-delete', this.pageAPI, data, callback, null);
    } else {
      if(type === 2){
        this.props.dispatchApiCallDelete(data, this.pageName + '-delete', this.pageAPI + '/group', data, callback, null);
      } else {
        this.props.dispatchApiCallDelete(data, this.pageName + '-delete', this.pageAPI, data, callback, null);
      }
    }
  }
	/* END API */
	
	
	/* SECTIONS */
	toolbarSection = () => {
		return <Formik
      innerRef={this.refForm}
      validationSchema={formSchema(this.state.selectedTab)}
      initialValues={this.state.initialValues}
      enableReinitialize={true}
      validateOnMount={false}
      validateOnChange={false}
      onSubmit={(form, e) => {
        let dataParams = {
          id: (form && form.id) ? form.id : null,
          row: form,
        };
        let data = this.prepareData(form, dataParams);

        if(form.isEdit){
          if(this.state.selectedTab === 'tab2'){
            this.props.action.geofenceMapViewSlice.setLoading(true);
            let newData = {
              accountMapGeofenceGroupId: data[this.pageIdField],
              groupName: data.name,
              description: data.description,
              groupColor: data.zoneColor,
            }
            this.callUpdateGroupApi(newData, (res) => {
              let initialValue = this.prepareForm({ id: null, row: null }, this.props.action.geofenceMapViewSlice);
              if(this.refForm && this.refForm.current){
                this.refForm.current.setValues(initialValue);
              }
  
              this.setState({
                isDisabled: true,
                isCreate: false,
              });
              
              this.callReadApi(() => {
                this.props.action.geofenceMapViewSlice.setLoading(false);
              });
            });

          } else {
            this.props.action.geofenceMapViewSlice.setLoading(true);
            this.callUpdateApi(data, (res) => {
              let initialValue = this.prepareForm({ id: null, row: null }, this.props.action.geofenceMapViewSlice);
              if(this.refForm && this.refForm.current){
                this.refForm.current.setValues(initialValue);
              }
  
              this.setState({
                isDisabled: true,
                isCreate: false,
              });
              
              this.callReadApi(() => {
                this.props.action.geofenceMapViewSlice.setLoading(false);
              });
            });
          }

        } else {
          let coordinates = this.props.geofenceMapViewSlice.coordinates;
          data['latlngs'] = (coordinates && coordinates.length > 0) ? coordinates[0].latlngs : [];

          if(this.state.selectedTab === 'tab2'){
            this.props.action.geofenceMapViewSlice.setLoading(true);
            let newData = {
              groupName: data.name,
              description: data.description,
              groupColor: data.zoneColor,
            }
            this.callCreateGroupApi(newData, (res) => {
              let initialValue = this.prepareForm({ id: null, row: null }, this.props.action.geofenceMapViewSlice);
              if(this.refForm && this.refForm.current){
                this.refForm.current.setValues(initialValue);
              }
  
              this.setState({
                isDisabled: true,
                isCreate: false,
              });
  
              this.callReadApi(() => {
                this.props.action.geofenceMapViewSlice.setLoading(false);
              });
            });

          } else {
            this.callCreateApi(data, (res) => {
              this.props.action.geofenceMapViewSlice.setLoading(true);
              let initialValue = this.prepareForm({ id: null, row: null }, this.props.action.geofenceMapViewSlice);
              if(this.refForm && this.refForm.current){
                this.refForm.current.setValues(initialValue);
              }
  
              this.setState({
                isDisabled: true,
                isCreate: false,
              });
  
              this.callReadApi(() => {
                this.props.action.geofenceMapViewSlice.setLoading(false);
              });
            });
          }
        }

        this.props.action.geofenceMapViewSlice.setCoordinates([]);
      }}
    >
      {(formOptions) => {
        return <StyledForm className='w-100' isModal={this.props.isModal} noValidate autoComplete="off" onSubmit={formOptions.handleSubmit} onKeyDown={(keyEvent) => {
          // if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
          //   keyEvent.preventDefault();
          // }
        }}>
          <Row className={'gx-4'}>
            <Col xs={12} md={4} lg={3} xl={2} className={'order-2 order-md-1'}>
              <Row>
                <Col xs={12} sm={6} className={'mb-2 mb-md-0'}>
                  <Button
                    className={'w-100'}
                    variant={(this.state.selectedTab === 'tab1') ? 'primary' : 'light'}
                    size={'sm'}
                    onClick={async () => {
                      await this.props.action.geofenceMapViewSlice.setStaticCoordinates([]);
                      await this.props.action.geofenceMapViewSlice.setCoordinates([]);
                      await this.props.action.geofenceMapViewSlice.setAllVisible([]);

                      formOptions.resetForm();

                      let initialValues = this.prepareForm({ id: null, row: null }, this.props.action.geofenceMapViewSlice);
                      formOptions.setValues(initialValues);
  
                      this.setState({
                        selectedTab: 'tab1',
                        selectedId: null,
                        selectedGroupId: null,
                        isDisabled: true,
                        isCreate: false,
                        search: '',
                        polygons: [],
                        rows1: [],
                        rows2: [],
                        sortColumns: SortColumns.geofence,
                      }, async () => {
                        this.callReadApi();
                      });
                    }}
                  >Geofence</Button>
                </Col>
                <Col xs={12} sm={6} className={'mb-2 mb-md-0'}>
                  <Button
                    className={'w-100'}
                    variant={(this.state.selectedTab === 'tab2') ? 'primary' : 'light'}
                    size={'sm'}
                    onClick={async () => {
                      await this.props.action.geofenceMapViewSlice.setStaticCoordinates([]);
                      await this.props.action.geofenceMapViewSlice.setCoordinates([]);
                      await this.props.action.geofenceMapViewSlice.setAllVisible([]);

                      formOptions.resetForm();

                      let initialValues = this.prepareForm({ id: null, row: null }, this.props.action.geofenceMapViewSlice);
                      formOptions.setValues(initialValues);

                      this.setState({
                        selectedTab: 'tab2',
                        selectedId: null,
                        selectedGroupId: null,
                        isDisabled: true,
                        isCreate: false,
                        search: '',
                        polygons: [],
                        rows1: [],
                        rows2: [],
                        sortColumns: SortColumns.geofenceGroup,
                      }, async () => {
                        this.callReadApi();
                      });
                    }}
                  >Group</Button>
                </Col>
                <Col sm={12} className={'mt-2 mb-2'}>
                  <Row className={'align-items-center'}>
                    <Col xs={true}>
                      {/* <WrappedButton
                        className={'px-3 pe-1'}
                        variant={"light"}
                        size={'sm'}
                        style={{ backgroundColor: 'transparent' }}
                        onClick={() => {
                            this.setState({
                              isSearchDialog: true,
                            });
                        }}
                      >
                        <i className={'material-icons ' + ((this.state.search != '') ? 'text-primary' : '')}>search</i>
                      </WrappedButton> */}

                      <StyledSearch
                        type="text"
                        placeholder={'Search ...'}
                        size={'sm'}
                        disabled={!(!this.state.isCreate && this.state.isDisabled) || this.state.isLoading}
                        value={this.state.search}
                        onChange={(e) => {
                          Utils.escapeChars(e);
                          let val = Utils.escapeCharsInString(e.target.value);

                          this.setState({
                            search: val,
                          });
                        }}
                        onKeyDown={(e) => {
                            Utils.escapeChars(e);
                            if (e.key === 'Enter') {
                              formOptions.resetForm();

                              let initialValues = this.prepareForm({ id: null, row: null }, this.props.action.geofenceMapViewSlice);
                              formOptions.setValues(initialValues);

                              this.setState({
                                currentPage: 1,
                                isSearchDialog: false,
                              }, () => {
                                this.callReadApi();
                              });
                            }
                        }}
                        isInvalid={false}
                      />
                    </Col>
                    <Col xs={'auto'}>

                      {(this.state.selectedTab == 'tab1') && <Button 
                        type={'button'}
                        size={'sm'}
                        style={{
                          paddingTop: '4px',
                          paddingRight: '0px',
                          paddingBottom: '4px',
                          paddingLeft: '4px',
                        }}
                        disabled={!(!this.state.isCreate && this.state.isDisabled)}
                        onClick={async () => {
                          let initialValues = this.prepareForm({ id: null, row: null }, this.props.action.geofenceMapViewSlice);
                          formOptions.setValues(initialValues);

                          this.setState({
                            isDisabled: true,
                            isCreate: true,
                          });
                        }}
                      >
                        <i className={'material-icons'}>add</i>
                      </Button>}

                      {((this.state.selectedTab == 'tab2')) && <Button 
                        type={'button'}
                        size={'sm'}
                        style={{
                          paddingTop: '4px',
                          paddingRight: '0px',
                          paddingBottom: '4px',
                          paddingLeft: '4px',
                        }}
                        disabled={!(!this.state.isCreate && this.state.isDisabled)}
                        onClick={async () => {
                          let initialValues = this.prepareForm({ id: null, row: null }, this.props.action.geofenceMapViewSlice);
                          formOptions.setValues(initialValues);

                          this.setState({
                            isDisabled: false,
                            isCreate: false,
                            sizes: 180,
                          });
                        }}
                      >
                        <i className={'material-icons'}>add</i>
                      </Button>}

                    </Col>

                    {/* {(this.state.search !== '') && <Col xs={12} className={'text-end'}>
                      <a href={'/'}
                        className={'link-primary'}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();

                          this.setState({
                            currentPage: 1,
                            search: '',
                            isSearchDialog: false,
                          }, () => {
                            this.callReadApi();
                          });
                        }}
                      >
                        Clear Search
                      </a>
                    </Col>} */}

                  </Row>
                </Col>
                <Col xs={12} style={{ height: this.props.isModal ? 'calc(100vh - 65px)' : 'calc(100vh - 355px)', overflow: 'hidden' }} className={'d-none d-md-block'}>
                  <StyledSplitPane>
                    <SplitPane 
                      className={'custome-pane'}
                      style={{
                        position: 'relative'
                      }}
                      split={'horizontal'}
                      primary={'first'}
                      minSize={100}
                      maxSize={450}
                      size={this.state.sizes}
                      onChange={(size) => {
                        this.setState({
                          sizes: size,
                        });
                      }}
                    >
                      <StyledPane id={'up_panel_ID'}>
                        {this.state.selectedTab === 'tab1' && this.tab1(formOptions)}
                        {this.state.selectedTab === 'tab2' && this.tab2(formOptions)}
                      </StyledPane>
                      <StyledPane id={'down_panel_ID'}>
                        {this.form(formOptions, 1)}
                      </StyledPane>
                    </SplitPane>
                  </StyledSplitPane>
                </Col>
                <Col xs={12} className={'d-block d-md-none'}>
                  <Row>
                    <Col sm={12}>
                      {this.state.selectedTab === 'tab1' && this.tab1(formOptions)}
                      {this.state.selectedTab === 'tab2' && this.tab2(formOptions)}
                    </Col>
                    <Col sm={12}>
                      {this.form(formOptions, 2)}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={8} lg={9} xl={10} 
              className={'order-1 order-md-2 mb-5 mb-md-0'}
              style={{ height: this.props.isModal ? 'calc(100vh - 65px)' : 'calc(100vh - 280px)', overflow: 'hidden' }}
            >
              {this.map(formOptions)}
            </Col>
          </Row>
        </StyledForm>
      }}
    </Formik>
	}

  tab1 = (formOptions) => {
    let { setValues } = formOptions;

    return <>
      {
        this.state.isLoading
        ?
        <div key={0} className={'text-center pt-2'}><Spinner animation="border" size={'sm'} /></div>
        :
          (this.state.rows1 && this.state.rows1.length > 0)
          ?
          <ListGroup variant="flush">
            {this.state.rows1.map((item, i) => {
              return <StyledListGroupItem
                key={'geofence_' + i + '_' + item.accountMapGeofenceId}
                className={'position-relative ' + 
                  ((
                  this.props.geofenceMapViewSlice.coordinates && 
                  (this.props.geofenceMapViewSlice.coordinates.length > 0) && 
                  this.props.geofenceMapViewSlice.coordinates[0] && 
                  (this.props.geofenceMapViewSlice.coordinates[0].id == item.accountMapGeofenceId)) ? 'activate ' : '') + 
                  ((this.state.selectedId === item.accountMapGeofenceId) ? 'activate ' : '') + 
                  ((this.state.hoverId.includes(item.accountMapGeofenceId)) ? 'hover ' : '')
                }
                style={{ 
                  borderLeftColor: (item && item.group && item.group.groupColor && item.group.groupColor != '') ? item.group.groupColor : '#484848',
                }}
                eventKey={item.accountMapGeofenceId}
                action
                onClick={async (e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  
                  if(!this.state.isCreate && this.state.isDisabled){
                    await this.props.action.geofenceMapViewSlice.setCoordinates([]);
                    let initialValues = this.prepareForm({ id: item.accountMapGeofenceId, row: item }, this.props.action.geofenceMapViewSlice, null, false);
                    
                    let accountMapGeofencePoints = (item && item.accountMapGeofencePoints && item.accountMapGeofencePoints.length > 0) ? item.accountMapGeofencePoints.sort((a, b) => a.pointOrder - b.pointOrder) : [];
                    let latlngs = (accountMapGeofencePoints && accountMapGeofencePoints.length > 0) ? accountMapGeofencePoints.map(point => [point.latitude, point.longitude]) : [];
                    
                    let center = null;
                    if(this.geofenceRef && this.geofenceRef.current){
                      center = this.geofenceRef.current.getCenter(latlngs);
                      if(center && center.length > 0){
                        this.props.action.geofenceMapViewSlice.setCenter(center);
                      }
                    }

                    setValues(initialValues);
                    this.setState({
                      selectedId: item.accountMapGeofenceId,
                    });

                  }
                }}
                onMouseOver={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  
                  if((!this.state.isCreate && this.state.isDisabled) && this.geofenceRef && this.geofenceRef.current){
                    this.geofenceRef.current.addHover([item.accountMapGeofenceId]);
                  }
                }}
              >
                <Row className={'align-items-center'}>
                  <Col xs={true}>
                    <StyledTitle className={'title'}>{item.name}</StyledTitle>
                    <StyledDesc className={'desc'}>{(item.group && item.group.groupName) ? item.group.groupName : <>&nbsp;</>}</StyledDesc>
                  </Col>
                  <Col xs={'auto'}>
                    {/* <StyledHide
                      href={'/'}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        
                        this.props.action.geofenceMapViewSlice.setVisible(item.accountMapGeofenceId);
                      }}
                    >
                      <i className={'material-icons ' + (Utils.isInArray(this.props.geofenceMapViewSlice.visible, item.accountMapGeofenceId) ? 'text-primary' : '')}>{Utils.isInArray(this.props.geofenceMapViewSlice.visible, item.accountMapGeofenceId) ? 'visibility_off' : 'visibility'}</i>
                    </StyledHide> */}
                    {/* <StyledEdit
                      href={'/'}
                      onClick={async (e) => {
                        e.stopPropagation();
                        e.preventDefault();
          
                        if(!this.state.isCreate || !this.state.isDisabled){
                          await this.props.action.geofenceMapViewSlice.setCoordinates([]);
                          let initialValues = this.prepareForm({ id: item.accountMapGeofenceId, row: item }, this.props.action.geofenceMapViewSlice);
                          setValues(initialValues);
                        }

                        this.setState({
                          isDisabled: false,
                          isCreate: false,
                        });
                      }}
                    >
                      <i className={'material-icons'}>edit</i>
                    </StyledEdit>
                    <StyledDelete
                      href={'/'}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
            
                        this.setState({
                          isRemoveDialogMap: true,
                          removeRowMap: { id: item.accountMapGeofenceId },
                          removeRowMapType: 1,
                        });
                      }}
                    >
                      <i className={'material-icons'}>delete_forever</i>
                    </StyledDelete> */}

                    <Dropdown alignRight>
                      <Dropdown.Toggle
                        as={DropdownToggle}
                        disabled={!(!this.state.isCreate && this.state.isDisabled)}
                        isButton
                        className={'px-3 pe-1'}
                        variant={"light"}
                        size={'sm'}
                        style={{ backgroundColor: 'transparent' }}
                        onMouseDown={(e) => {
                          e.stopPropagation();
                          e.preventDefault();

                          Utils.clickOutside();
                        }}
                      >
                        <i class="fas fa-ellipsis-h" style={{ color: '#D9D9D9' }}></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          active={false}
                          onClick={async (e) => {
                            e.stopPropagation();
                            e.preventDefault();
          
                            if(!this.state.isCreate || !this.state.isDisabled){
                              await this.props.action.geofenceMapViewSlice.setCoordinates([]);
                              let initialValues = this.prepareForm({ id: item.accountMapGeofenceId, row: item }, this.props.action.geofenceMapViewSlice);
                              setValues(initialValues);
                            }
    
                            this.setState({
                              isDisabled: false,
                              isCreate: false,
                              sizes: 180,
                            });
                          }} 
                        >Edit</Dropdown.Item>

                        <Dropdown.Item
                          active={false}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
            
                            this.setState({
                              isRemoveDialogMap: true,
                              removeRowMap: { id: item.accountMapGeofenceId },
                              removeRowMapType: 1,
                            });
                          }} 
                        >Delete</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
              </StyledListGroupItem>
            })}
          </ListGroup>
          :
          <h6 className={'text-center pt-5 m-0'}>No Results!</h6>
      }
    </>
  }

  tab2 = (formOptions) => {
    let { setValues } = formOptions;

    return <>
      {
        this.state.isLoading
        ?
        <div key={0} className={'text-center pt-2'}><Spinner animation="border" size={'sm'} /></div>
        :
          (this.state.rows2 && this.state.rows2.length > 0)
          ?
          <ListGroup variant="flush">
            {this.state.rows2.map((item, i) => {
              return <StyledListGroupItem
                key={'geofence_group_' + i + '_' + item.accountMapGeofenceGroupId}
                className={'position-relative ' + 
                  ((this.state.selectedGroupId == item.accountMapGeofenceGroupId) ? 'activate ' : '') + 
                  (((item && item.accountMapGeofences && item.accountMapGeofences.length > 0) && item.accountMapGeofences.map(x => x.accountMapGeofenceId).some(y => this.state.hoverId.includes(y))) ? 'hover ' : '')
                }
                style={{ 
                  borderLeftColor: item.groupColor ? item.groupColor : '#484848',
                }}
                eventKey={item.accountMapGeofenceGroupId}
                action
                onClick={async (e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  let polys = [];
                  let accountMapGeofences = (item && item.accountMapGeofences && item.accountMapGeofences.length > 0) ? item.accountMapGeofences : [];
                  if(accountMapGeofences && accountMapGeofences.length > 0){
                    polys = accountMapGeofences.map(p => {
                      let accountMapGeofencePoints = (p && p.accountMapGeofencePoints && p.accountMapGeofencePoints.length > 0) ? p.accountMapGeofencePoints.sort((a, b) => a.pointOrder - b.pointOrder) : [];
                      let latlngs = (accountMapGeofencePoints && accountMapGeofencePoints.length > 0) ? accountMapGeofencePoints.map(point => [point.latitude, point.longitude]) : [];
                      
                      return latlngs
                    });
                  }

                  let center = null;
                  if(this.geofenceRef && this.geofenceRef.current){
                    center = this.geofenceRef.current.getPoygonsCenter(polys);
                    if(center && center.length > 0){
                      this.props.action.geofenceMapViewSlice.setCenter(center);
                    }
                  }


                  if(!this.state.isCreate && this.state.isDisabled){
                    this.setState({
                      selectedGroupId: item.accountMapGeofenceGroupId,
                    }, async () => {
                      let group = this.state.rows2.find(x => x.accountMapGeofenceGroupId == this.state.selectedGroupId);
                      await this.props.action.geofenceMapViewSlice.setCoordinates([]);
                      let initialValues = this.prepareForm({ id: null, row: null }, this.props.action.geofenceMapViewSlice, group);
                      setValues(initialValues);
                    });
                  }
                }}
                onMouseOver={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  
                  if((!this.state.isCreate && this.state.isDisabled) && this.geofenceRef && this.geofenceRef.current){
                    let ids = (item && item.accountMapGeofences && item.accountMapGeofences.length > 0) ? item.accountMapGeofences.map(x => x.accountMapGeofenceId) : [];  
                    this.geofenceRef.current.addHover(ids);
                  }
                }}
              >
                <Row className={'align-items-center'}>
                  <Col xs={true}>
                    <StyledTitle className={'title'}>{item.groupName}</StyledTitle>
                    <StyledDesc className={'desc'}>{(item.accountMapGeofences && item.accountMapGeofences.length > 0) ? item.accountMapGeofences.map(x => x.name).join(', ') : <>&nbsp;</>}</StyledDesc>
                  </Col>
                  <Col xs={'auto'}>
                    {/* <StyledHide
                      href={'/'}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        if(item.accountMapGeofences && item.accountMapGeofences.length > 0){
                          item.accountMapGeofences.forEach(x => {
                            this.props.action.geofenceMapViewSlice.setVisible(x.accountMapGeofenceId);
                          })
                        }
                      }}
                    >
                      <i className={'material-icons ' + ((item.accountMapGeofences.map(x => x.accountMapGeofenceId).some(y => this.props.geofenceMapViewSlice.visible.includes(y))) ? 'text-primary ' : '')}>{(item.accountMapGeofences.map(x => x.accountMapGeofenceId).some(y => this.props.geofenceMapViewSlice.visible.includes(y))) ? 'visibility_off' : 'visibility'}</i>
                    </StyledHide> */}
                    {/* <StyledDelete
                      href={'/'}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
            
                        this.setState({
                          isRemoveDialogMap: true,
                          removeRowMap: { id: item.accountMapGeofenceGroupId },
                          removeRowMapType: 2,
                        });
                      }}
                    >
                      <i className={'material-icons'}>delete_forever</i>
                    </StyledDelete> */}
                    
                    <Dropdown alignRight>
                      <Dropdown.Toggle
                        as={DropdownToggle}
                        disabled={!(!this.state.isCreate && this.state.isDisabled)}
                        isButton
                        className={'px-3 pe-1'}
                        variant={"light"}
                        size={'sm'}
                        style={{ backgroundColor: 'transparent' }}
                        onMouseDown={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          
                          Utils.clickOutside();
                        }}
                      >
                        <i class="fas fa-ellipsis-h" style={{ color: '#D9D9D9' }}></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          active={false}
                          disabled={false}
                          onClick={async (e) => {
                            e.stopPropagation();
                            e.preventDefault();
          
                            if(!this.state.isCreate || !this.state.isDisabled){
                              let group = this.state.rows2.find(x => x.accountMapGeofenceGroupId == item.accountMapGeofenceGroupId);
                              await this.props.action.geofenceMapViewSlice.setCoordinates([]);
                              let initialValues = this.prepareForm({ id: null, row: null }, this.props.action.geofenceMapViewSlice, group);
                              setValues(initialValues);
                            }
    
                            this.setState({
                              isDisabled: false,
                              isCreate: false,
                              sizes: 180,
                            });
                          }} 
                        >Edit</Dropdown.Item>

                        <Dropdown.Item
                          active={false}
                          disabled={false}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
            
                            this.setState({
                              isRemoveDialogMap: true,
                              removeRowMap: { id: item.accountMapGeofenceGroupId },
                              removeRowMapType: 2,
                            });
                          }} 
                        >Delete</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
              </StyledListGroupItem>
            })}
          </ListGroup>
          :
          <h6 className={'text-center pt-5 m-0'}>No Results!</h6>
      }
    </>
  }

  map = (formOptions) => {
    let { setValues, setFieldValue, resetForm } = formOptions;

		return <GeofenceMapView 
      ref={this.geofenceRef}
      height={this.props.isModal ? 'calc(100vh - 65px)' : '70vh'}
      selectedId={this.state.selectedId}
      selectedGroupId={this.state.selectedGroupId}
      selectedTab={this.state.selectedTab}
      enabledCreate={this.state.isCreate}
      rows={(this.state.selectedTab == 'tab2') ? this.state.rows2 : this.state.rows1}
      polygons={this.state.polygons}
      isFromPopup={(this.props.initTab && this.props.initTab != '')}
      onEditVertex={async (obj) => {
        // let data = this.prepareData(obj, obj);
        setFieldValue('latlngs', obj.latlngs);

        // this.props.action.geofenceMapViewSlice.setLoading(true);
        // this.callUpdateApi(data, (res) => {
        //   this.props.action.geofenceMapViewSlice.setLoading(false);
        //   this.callReadApi();
        // });
      }}
      onCreate={async (obj) => {
        if(obj){
          this.props.action.geofenceMapViewSlice.setCoordinates([obj]);
          await setFieldValue('latlngs', obj.latlngs);

          this.setState({
            isDisabled: false,
            sizes: 180,
          });
        }
      }}
      onEdit={async (obj) => {
        let item = null;
        if(this.state.selectedTab == 'tab2'){
          let items = this.state.rows2.filter(x => x.accountMapGeofences.find(x => x.accountMapGeofenceId == obj.id));
          if(items && items.length > 0){
            item = items[0].accountMapGeofences.find(x => x.accountMapGeofenceId == obj.id)
          }
        } else {
          item = this.state.rows1.find(x => x.accountMapGeofenceId == obj.id);
        }

        let group = null;
        if(this.state.selectedTab == 'tab2'){
          group = this.state.rows2.find(x => x.accountMapGeofenceGroupId == this.state.selectedGroupId);
        }

        let initialValues = this.prepareForm({ id: item.accountMapGeofenceId, row: item }, this.props.action.geofenceMapViewSlice, group);
        setValues(initialValues);

        this.setState({
          isDisabled: false,
          isCreate: false,
          sizes: 180,
        });
      }}
      onDeleted={async (obj) => {
        this.setState({
          isRemoveDialogMap: true,
          removeRowMap: obj,
          removeRowMapType: 1,
        });
      }}
      onHover={(id) => {
        this.setState({
          hoverId: id,
        });
      }}
      onClick={async (obj) => {
        if(obj){
          let item = null;
          if(this.state.selectedTab == 'tab2'){
            let items = this.state.rows2.filter(x => x.accountMapGeofences.find(x => x.accountMapGeofenceId == obj.id));
            if(items && items.length > 0){
              item = items[0].accountMapGeofences.find(x => x.accountMapGeofenceId == obj.id)
              this.setState({
                selectedGroupId: items[0].accountMapGeofenceGroupId,
              });
            }
          } else {
            item = this.state.rows1.find(x => x.accountMapGeofenceId == obj.id);
          }

          if(item){
            let initialValues = this.prepareForm({ id: item.accountMapGeofenceId, row: item }, this.props.action.geofenceMapViewSlice, null, false);
            setValues(initialValues);
  
            this.setState({
              isDisabled: true,
              isCreate: false,
            });
          } else {
            if(!this.state.isCreate){
              resetForm();

              let initialValues = this.prepareForm({ id: null, row: null }, this.props.action.geofenceMapViewSlice, null, false);
              setValues(initialValues);

              this.setState({
                isDisabled: true,
                selectedGroupId: null,
              });
            }
          }
        } else {
          if(!this.state.isCreate){
            resetForm();

            let initialValues = this.prepareForm({ id: null, row: null }, this.props.action.geofenceMapViewSlice, null, false);
            setValues(initialValues);

            this.setState({
              isDisabled: true,
              selectedGroupId: null,
            });
          }
        }
      }}

      // onClick={async (obj) => {
      //   if(obj){

      //   } else {
      //     if(!this.state.isCreate){
      //       resetForm();
  
      //       let initialValues = this.prepareForm({ id: null, row: null }, this.props.action.geofenceMapViewSlice);
      //       setValues(initialValues);
  
      //       this.setState({
      //         isDisabled: true,
      //         selectedId: null,
      //         selectedGroupId: null,
      //       });
      //     }
      //   }
      // }}
      onCancelCreate={() => {
        resetForm();

        let initialValues = this.prepareForm({ id: null, row: null }, this.props.action.geofenceMapViewSlice);
        setValues(initialValues);
        
        this.setState({
          isDisabled: true,
          isCreate: false,
          selectedId: null,
          selectedGroupId: null,
        });
      }}
    />
	}

  form = (formOptions, numb) => {
    let { values, errors, setFieldValue, resetForm } = formOptions;

    return <Row className={'mb-5'}>
      <Col xs={12} className={'mb-6'}>
        <Form.Label>{formFields.name.label} *</Form.Label>
        <InputGroup>
          <Form.Control
            type="text"
            id={formFields.name.id}
            placeholder={formFields.name.placeholder}
            value={values.name}
            disabled={this.state.isDisabled}
            onChange={async (e) => {
              await setFieldValue('name', e.target.value);
              
              let coordinates = this.props.geofenceMapViewSlice.coordinates;
              if(coordinates.length > 0){
                this.props.action.geofenceMapViewSlice.setCoordinates([{...values, name: e.target.value, latlngs: coordinates[0].latlngs }]);
              }
            }}
            isInvalid={!!errors.name}
          />
          {(this.state.selectedTab === 'tab2') && <Form.Control
              style={{ maxWidth: '44px' }}
              type="color"
              id={formFields.color.id}
              placeholder={formFields.color.placeholder}
              value={values.color}
              disabled={this.state.isDisabled}
              onChange={async (e) => {
                await setFieldValue('color', e.target.value);
                
                let coordinates = this.props.geofenceMapViewSlice.coordinates;
                if(coordinates.length > 0){
                  this.props.action.geofenceMapViewSlice.setCoordinates([{...values, color: e.target.value, latlngs: coordinates[0].latlngs }]);
                }
              }}
              isInvalid={!!errors.color}
            />}
        </InputGroup>
      </Col>
      
      <Col xs={12} className={'mb-3'}>
        <Form.Label>{formFields.description.label}</Form.Label>
        <Form.Control
          type="text"
          id={formFields.description.id}
          placeholder={formFields.description.placeholder}
          value={values.description}
          disabled={this.state.isDisabled}
          onChange={async (e) => {
            await setFieldValue('description', e.target.value);
          }}
          isInvalid={!!errors.description}
        />
      </Col>

      {(this.state.selectedTab === 'tab1') && <Col xs={12} className={'mb-3'}>
        <Form.Label>{formFields.accountMapGeofenceGroupId.label} *</Form.Label>
        <br />
        <small>Add your geofence to one or more group</small>
        <SmartDropdown
          isInvalid={!!errors.accountMapGeofenceGroupId}
          // errorText={errors.accountMapGeofenceGroupId}
          errorText={' '}

          componentTitle={'Geofence group ' + numb}
          componentApi={'map-geofence/group'}
          componentId={'accountMapGeofenceGroupId'}
          componentName={'groupName'}

          placeholder={formFields.accountMapGeofenceGroupId.placeholder}
          value={values.accountMapGeofenceGroupId}
          label={values.groupName}

          disabled={this.state.isDisabled}
          showDefault={false}
          isInfiniteScroll={false}

          itemRender={(label, item, keyLabel) => {
            let color = (item && item.item && item.item.groupColor && item.item.groupColor != '') ? item.item.groupColor : '#484848';
            return <>
                <i className={'fa fa-circle'} style={{ position: 'relative', top: '7px', fontSize: '6px', color: color }}></i>
                <span className={'ps-2 align-items-center d-flex'}>{label}</span>
            </>
          }}

          onChange={async (value, item, i) => {
            await setFieldValue('accountMapGeofenceGroupId', value);
            await setFieldValue('groupName', item.title);
            await setFieldValue('group', item.item);
            
            let coordinates = this.props.geofenceMapViewSlice.coordinates;
            if(coordinates.length > 0){
              this.props.action.geofenceMapViewSlice.setCoordinates([{...values, group: item.item, latlngs: coordinates[0].latlngs }]);
            }
          }}
          onClear={async () => {
            await setFieldValue('accountMapGeofenceGroupId', null);
            await setFieldValue('groupName', '');
            await setFieldValue('group', null);
            
            let coordinates = this.props.geofenceMapViewSlice.coordinates;
            if(coordinates.length > 0){
              this.props.action.geofenceMapViewSlice.setCoordinates([{...values, group: null, latlngs: coordinates[0].latlngs }]);
            }
          }}
        />
      </Col>}

      {!this.state.isDisabled && <Col xs={12}>
        <Row>
          <Col xs={'auto'}>
            <Button 
              type={'submit'}
              size={'sm'}
              disabled={this.props.isLoading || this.state.isDisabled}
            >
              Save
              {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
            </Button>
          </Col>
          <Col xs={'auto'}>
            <Button 
              type={'button'}
              variant={'secondary'}
              size={'sm'}
              disabled={this.props.isLoading || this.state.isDisabled}
              onClick={() => {
                if(this.geofenceRef && this.geofenceRef.current){
                  this.geofenceRef.current.clearLayer();
                  this.geofenceRef.current.cancelCreate();
                  this.props.action.geofenceMapViewSlice.setCoordinates([]);
                }
                
                resetForm();

                this.setState({
                  isDisabled: true,
                  selectedId: null,
                  selectedGroupId: null,
                });
              }}
            >
              Cancel
              {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
            </Button>
          </Col>
        </Row>
      </Col>}
    </Row>
  }
	/* END SECTIONS */

  
  /* DIALOGS */
  setRemoveDialogMap = () => {
    return <RemoveDialog 
      show={this.state.isRemoveDialogMap}
      isLoading={false}
      onCancel={() => {
        this.setState({
          isRemoveDialogMap: false,
          removeRowMap: null,
          removeRowMapType: 1,
        });
      }}
      onRemove={() => {
        let obj = this.state.removeRowMap;
        let type = this.state.removeRowMapType;

        this.props.action.geofenceMapViewSlice.setLoading(true);
        this.callDeleteApi(type, { [(type === 1) ? this.pageIdField : 'accountMapGeofenceGroupId']: obj.id }, (res) => {
          this.props.action.geofenceMapViewSlice.setLoading(false);
          this.callReadApi();

          let initialValue = this.prepareForm({ id: null, row: null }, this.props.action.geofenceMapViewSlice);
          if(this.refForm && this.refForm.current){
            this.refForm.current.setValues(initialValue);
          }

          this.setState({
            isRemoveDialogMap: false,
            removeRowMap: null,
            removeRowMapType: 1,
            selectedId: null,
            selectedGroupId: null,
          });
        });
      }}
    />
  }
  setSearchDialog = () => {
    return <SearchDialog 
      show={this.state.isSearchDialog}
      onCancel={() => {
        this.setState({
          isSearchDialog: false,
        });
      }}
      onSearch={(value) => {
        this.setState({
          currentPage: 1,
          search: value,
          isSearchDialog: false,
        }, () => {
          this.callReadApi();
        });
      }}
    />
  }
  /* END DIALOGS */


  render() {
    return (
      <div
        onMouseOver={(e) => {
          e.stopPropagation();
          e.preventDefault();

          if(this.geofenceRef && this.geofenceRef.current){
            this.geofenceRef.current.removeHover();
          }
        }}
      >
        {
        this.props.isModal
        ?
        <div className={'p-5'}>{this.toolbarSection()}</div>
        :
        <ViewLayout
          isBlocking={false}
          title={'GEOFENCE'}
          cardClassName={'px-3'}
          actionSection={<Row className={'align-items-center'}>
            <Col xs={12} md={true} className={'order-1 order-md-3 pb-10 pb-md-0'}>
              <CurrentDateTime />
            </Col>
          </Row>}
          toolbarSection={this.toolbarSection()}
        />
        }
        
        {this.setRemoveDialogMap()}
        {this.setSearchDialog()}
      </div>
    );
  }
}

const mapStateToProps = state => {
    return {
        ...state,
        ...Utils.mapStateToProps(state),
    };
};
  
const mapDispatchToProps = (dispatch) => ({
  dispatchApiCallGet: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallGet(p1, p2, p3, p4, p5, p6)),
  dispatchApiCallPost: (p1, p2, p3, p4, p5, p6, p7) => dispatch(dispatchApiCallPost(p1, p2, p3, p4, p5, p6, p7)),
  dispatchApiCallPut: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallPut(p1, p2, p3, p4, p5, p6)),
  dispatchApiCallDelete: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallDelete(p1, p2, p3, p4, p5, p6)),
  clear: (payload) => dispatch(clear(payload)),
    action: {
      geofenceMapViewSlice: {
        setLoading: (payload) => dispatch(geofenceMapViewSlice.setLoading(payload)),
        setCoordinates: (payload) => dispatch(geofenceMapViewSlice.setCoordinates(payload)),
        setStaticCoordinates: (payload) => dispatch(geofenceMapViewSlice.setStaticCoordinates(payload)),
        setCenter: (payload) => dispatch(geofenceMapViewSlice.setCenter(payload)),
        setVisible: (payload) => dispatch(geofenceMapViewSlice.setVisible(payload)),
        setAllVisible: (payload) => dispatch(geofenceMapViewSlice.setAllVisible(payload)),
      },
    }
});
  
  
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(GeofenceView);
GeofenceView.propTypes = {
  isModal: PropTypes.bool,
  initTab: PropTypes.string
};