import React from "react";
import _ from 'lodash';

import { 
    Row,
    Col,
    Form,
    Spinner,
    Button,
    ListGroup,
} from "react-bootstrap-v5";

import { DrawerListContentLayout } from '../../../../components/drawer/DrawerLayout'
import Utils from "../../../../utils/utils";

import { connect } from 'react-redux'
import { clear } from "../../../../../setup/redux/actions";
import { dispatchApiCallGet } from '../../../../../setup/redux/dispatch/actions'


class JobViewAssignToForm extends React.Component {
  
    constructor(props) {
        super(props);
    
        this.bodyRef = React.createRef();

        let dataParams = (props.dataParams) ? props.dataParams : null;
        let jobIds = (dataParams && dataParams.jobIds) ? dataParams.id : null;
        let jobStatusId = (dataParams && dataParams.jobStatusId) ? dataParams.jobStatusId : null;
        
        this.state = {
            jobIds: jobIds,
            jobStatusId: jobStatusId,
        };
    }


    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            'assignto_form_drawer-driver', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.driverId;
                        let title = item.driverName;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                            isChecked: false,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );
    }
	
    callReadBinTypeApi = (searchQuery = '', items, callback = null) => {
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: searchQuery,
        };
     
        this.props.dispatchApiCallGet(data, 'assignto_form_drawer-driver', 'driver', { items: items }, callback, () => {});
    }
    /* END API */


    body = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;
        
        return <Row>
            
            <Col xs={12}>
                <ListGroup variant="flush">
                    {
                        (values.driversList && values.driversList.length > 0)
                        ?
                        values.driversList.map((item, i) => {
                            return <ListGroup.Item key={i} className={'custom-list-check-box'}>
                                <Form.Group>
                                    <Form.Check
                                        type={'radio'}
                                        id={`drivers-radio-` + i}
                                        name={'drivers'}
                                        label={item.title}
                                        checked={item.isChecked}
                                        onChange={async (e) => {
                                            await setFieldValue('driversList.' + i + '.isChecked', e.target.checked);
                                            await setFieldValue('driverId', item.value);
                                        }}
                                    />
                                </Form.Group>
                            </ListGroup.Item>
                        })
                        :
                        values.isAutoSendDoDoc ? <h3 className={'mb-5'}>No items!</h3> : null
                    }
                </ListGroup>
            </Col>

        </Row>
    }
    
    footer = () => {
        let {
            validateForm,
            values,
        } = this.props.formOptions;

        return <Row>
            <Col xs={'auto'}>
                <Button 
                    type={'button'}
                    variant={'secondary'}
                    disabled={this.props.isLoading}
                    onClick={() => {
                        Utils.showDrawer(this.props.drawer, false);
                    }}
                >
                    CANCEL
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
            <Col xs={'auto'}>
                <Button 
                    type={'button'}
                    disabled={this.props.isLoading}
                    onClick={() => {
                        if(values.driverId){
                            if(this.props.onAssign){
                                this.props.onAssign(values);
                            }
                        } else {
                            Utils.toast(this.props.fields.driversList.required, 'error');
                        }
                    }}
                >
                    ASSIGN
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
            
            <Col xs={12} className={'d-flex d-sm-none pb-3'}></Col>
            <Col xs={'auto'} md={'auto'}>
                <Button 
                    type={'button'}
                    variant={'outline-secondary'}
                    disabled={this.props.isLoading}
                    onClick={() => {
                        if(values.driverId){
                            if(this.props.onDispatch){
                                this.props.onDispatch(values);
                            }
                        } else {
                            Utils.toast(this.props.fields.driversList.required, 'error');
                        }
                    }}
                >
                    DISPATCH
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>

        </Row>
    }


    render() {
        return <DrawerListContentLayout
            ref={this.bodyRef}
            isBlocking={this.props.isBlocking}
            body={this.body()}
            footer={this.footer()}
        />
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(JobViewAssignToForm);
