/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface InitState {
  isLoading: boolean,
  show: boolean,
  item: any,
  index: any,
  subIndex: any,
  type: any,
}


function NewReducer() {
  const name = 'reduxDeletePopupSlice';


  const initialState: InitState = {
    isLoading: false,
    show: false,
    item: null,
    index: null,
    subIndex: null,
    type: null,
  };


  const reducers = {
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<{ item: any, index: any, subIndex: any, type: any }>) => {
      state.item = action.payload.item;
      state.index = action.payload.index;
      state.subIndex = action.payload.subIndex;
      state.type = action.payload.type;
      state.show = true;
      state.isLoading = false;
    },
    setHide: (state: InitState) => {
      state.item = null;
      state.type = null;
      state.index = null;
      state.subIndex = null;
      state.show = false;
      state.isLoading = false;
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
  };
}


export default NewReducer();