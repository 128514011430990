import React from 'react'
import {toAbsoluteUrl} from '../../../helpers'

const FallbackView = () => {
  return (
    <div className='splash-screen'>
      <img src={toAbsoluteUrl('/media/logos/logo-compact.svg')} alt='Start logo' />
      <span>Loading ...</span>
    </div>
  )
}

export default FallbackView;