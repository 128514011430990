import axios from 'axios';


const { 
    REACT_APP_DISPATCH_API_URL
} = process.env;


const API = "audittrail";

const auditTrailApi = {

    getAuditTrail: async (data, token) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_DISPATCH_API_URL + API;
        
        const getResult = await axios.get(
            URL,
            { 
                params: data,
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            })
            .catch( error => {
                try {
                    status = error.response.status;

                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch(err) {
                    errorMessage = 'Service Unavailable!'
                }
            });
            
        return { data: getResult, errorMessage: errorMessage, status: status};
    },

    getAuditTrailUser: async (data, token) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_DISPATCH_API_URL + API;
        
        const getResult = await axios.get(
            URL + '/get-by-user',
            { 
                params: data,
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            })
            .catch( error => {
                try {
                    status = error.response.status;

                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch(err) {
                    errorMessage = 'Service Unavailable!'
                }
            });
            
        return { data: getResult, errorMessage: errorMessage, status: status};
    },

    compareAuditTrail: async (objectType, objectId, auditTrailId, token) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_DISPATCH_API_URL + API;
        
        const getResult = await axios.get(
            URL + "/compare-audit-trail/" + objectType + "/" + objectId + "/" + auditTrailId,
            { 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            })
            .catch( error => {
                try {
                    status = error.response.status;

                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch(err) {
                    errorMessage = 'Service Unavailable!'
                }
            });
            
        return { data: getResult, errorMessage: errorMessage, status: status};
    },

}

export default auditTrailApi;