import React, { Component } from 'react';
import moment from 'moment'

import InfiniteScroll from 'react-infinite-scroller';

import apiUtil from '../../../api/apiUtil.jsx';
import Api from '../../../api/OLD/Dispatch/RecurringJob.jsx';

import { AuditTrailObjectType, JobStepType, StepType, CustomPhotoSignature, DayOfWeek, JobStepBinActivity } from '../../../utils/enums';

import { 
  Box,
  Grid,
  IconButton,
  Tooltip,
  CircularProgress,
  Backdrop,
} from '@material-ui/core';

import { 
	Button,
	Row,
	Col,
} from "react-bootstrap-v5";

import ViewLayout from '../layout/ViewLayout';
import CurrentDateTime from '../../../components/currentDateTime/CurrentDateTime'

import { ReactComponent as EditIcon } from "../../../../_metronic/assets/img/icons/report-edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../_metronic/assets/img/icons/manage-delete.svg";
import { ReactComponent as CopyIcon } from "../../../../_metronic/assets/img/icons/manage-copy.svg";
import { ReactComponent as AuditTrailIcon } from "../../../../_metronic/assets/img/icons/youtube_searched_for.svg";

import { 
	ReportTable, 
	ReportThead, 
	ReportTbody, 
	ReportTr, 
	ReportTh, 
	ReportTd 
} from "../../../components/OLD/Pages/Reports/CustomTable.jsx";

import Snackbar from "../../../components/OLD/Snackbar/Snackbar.jsx";
import ManageLabel from '../../../components/OLD/input/ManageLabel.jsx';

import CustomDialog from '../../../components/OLD/Dialog/CustomDialog.jsx';
import DataTableRemoveDialog from '../../../components/OLD/DataTable/DataTableRemoveDialog.jsx';

import AuditTrailDialog from '../../../components/OLD/Pages/AuditTrail/AuditTrailDialog.jsx';
import AuditTrailFunctions from '../../../components/OLD/Pages/AuditTrail/AuditTrailFunctions.jsx';

import IconButtonSearch from '../../../components/OLD/input/IconButtonSearch.jsx'
import makeExpanding from '../../../components/OLD/Expanding/makeExpanding.jsx';

import Utils from '../../../utils/utils';
import {PagesPermissions} from '../../../utils/enums';



const ExpandingSearchBox = makeExpanding(IconButtonSearch);


class RecurringJobsView extends Component {
  
	constructor(props){
		super(props);

		let user = (props.auth && props.auth.user) ? props.auth.user : null;
		this.permissions = (user && user.permissions && user.permissions.length > 0) ? user.permissions : [];

		this.state = {
			rowId: 'recurringJobId',

			showError: false,
			errorText: '',
			errorSeverity: null,

			isRead: true,

			search: '',
			currentPage: 1,
			totalRows: 0,
			rowsPerPageSelected: Utils.getMaxPageSize(),
			isLoading: false,
			rowEdit: null,
            tableRows: [],
            tableColumns: [
				{
					label: 'Customer name',
					width: '200px',
				},
				{
					label: 'Template name',
					width: '150px',
				},
				{
					label: 'Description',
				
				},
				{
					label: 'Job type',
					width: '170px',
				},
				{
					label: 'Start date',
				},
				{
					label: 'End date',
				},
				{
					label: 'Bin out',
				},
				{
					label: 'Waste out',
				},
				{
					label: 'Bin in',
				},	
				{
					label: 'Waste in',
				},
			
				
			
				{
					label: 'Actions',
					width: '190px',
				},
			],
			
			isRemoveDialog: false,
			removeRow: null,

            isOpenAuditTrail: false,
            objectId: null,
		}
	}

	
	componentDidMount() {
		this.callReadApi(this.state.currentPage);
	}


	/* API */
	callReadApi = (currentPage = 1, callback = null, error500 = null) => {
		const { token } = this.context;
		
		Api.read(
			{
				currentPage: currentPage,
				pageSize: this.state.rowsPerPageSelected,
                searchQuery: this.state.search,
				sortColumn: 'updated',
				sortDir: 'desc'
			}, 
			token
		).then(result => {
			apiUtil.parseResult(result, (data) => {
				let newData = this.state.tableRows;
				if(data && data.data && data.data.length > 0){
					data.data.forEach(x => {
						newData.push({
							...x,  
						});
					})
				}
				
				this.setState({
					totalRows: data.total,
					tableRows: newData,
					currentPage: currentPage,
					isRead: false,
				}, () => {
					if(callback !== null){
						callback(newData);
					}
				});
			}, (error, type) => {
				this.setState({
					totalRows: 0,
					tableRows: [],
					currentPage: currentPage,
					isRead: false,
				}, () => {
					if(callback !== null){
						callback([]);
					}
				});
			}, error500);
		});
	}

	callDeleteApi = (row, callback = null) => {
		const { token, setModal } = this.context;
		
		this.setState({
			isLoading: true
		});

		Api.delete(
			row, 
			token
		).then(result => {
			apiUtil.parseResult(result, (data) => {
				this.setState({
					showError: true,
					errorText: "Successfully deleted!",
					errorSeverity: 'success',
					isLoading: false,
				}, () => {
					if(callback !== null){
						callback(data);
					}
				});
			}, (error, type, errorsArray, needForceDelete) => {
				if(needForceDelete){
					setModal({
					  open: needForceDelete,
					  title: 'Force Delete!',
					  desc: error,
					  onInit: () => {
						this.setState({
							isLoading: false,
						  isRemoveDialog: false,
						  removeRow: null,
						});
					  },
					  onClickClose: () => {
						setModal(null);
					  },
					  onClickOk: () => {
						row['forceDelete'] = true;
					
						this.callDeleteApi(row, (data) => {
						  if(callback !== null){
							callback(data);
						  }
					  
						  setModal(null);
						});
					  },
					});
				} else {
					this.setState({
					  showError: true,
					  errorText: error,
					  errorSeverity: type,
					  isLoading: false,
					});
				}
			});
		});
    }
	/* END API */


	/* BACKDROP && SNACKBAR */
	setBackDrop = () => {
		return <Backdrop 
			open={this.state.isLoading}
			style={{
				zIndex: 999999,
				color: '#ffffff'
			}}
		>
			<CircularProgress 
				color="inherit"
				style={{
					zIndex: 999999,
					color: '#ffffff'
				}}
			/>
		</Backdrop>
	}

	setSnackBar = () => {
		return (
		  <Snackbar
			open={this.state.showError}
			text={this.state.errorText}
			severity={this.state.errorSeverity}
			onClose={() => {
			  this.setState({
				showError: false,
				errorText: "",
				errorSeverity: null,
			  });
			}}
		  />
		);
	}
	/* END BACKDROP && SNACKBAR */


	/* SECTIONS */
	topSection = () => {
		return <Row className={'d-block d-md-flex align-items-center pb-5'}>
			<Col md={'auto'} className={'pb-2 pb-md-0 d-grid'}>
				<Button
					variant={'light'}
					className={'pt-1 pb-1 rounded-1 border bg-white'}
					onClick={(e) => {
						this.props.history.push('/manage/job/job-types');
					}}
				>
					Job types
				</Button>
			</Col>
			<Col md={'auto'} className={'pb-2 pb-md-0 d-grid'}>
				<Button
					variant={'success'}
					className={'pt-1 pb-1 rounded-1 border'}
					onClick={(e) => {
						this.props.history.push('/manage/job/job-recurring');
					}}
				>
					Recurring jobs
				</Button>
			</Col>
			<Col xs={12} className={'d-xs-flex d-lg-none w-100'}></Col>

			<Col xs={'auto'} md={true} className={'text-center text-md-end'}>
				<ExpandingSearchBox 
					btnClassName={'manage-icon-btn'}
					disabled={this.state.isEdit}
					onPressEnter={(value) => {
						this.setState({
							search: value,
							tableRows: [],
							isLoading: true,
						}, () => {
							this.callReadApi(1, () => {
								this.setState({
									isLoading: false,
								});
							});
						});
					}}
					onClear={() => {
						this.setState({
							search: '',
							tableRows: [],
							isLoading: true,
						}, () => {
							this.callReadApi(1, () => {
								this.setState({
									isLoading: false,
								});
							});
						});
					}}
				/>
			</Col>
		</Row>
	}

	listSection = () => {
		return <Box className={"manage-table"}>
			<ReportTable className={"no-responsive-table"}>
				<>
					{(this.state.tableColumns && this.state.tableColumns.length > 0) && <ReportThead>
						<ReportTr>
							{this.state.tableColumns.map((column, i) => {
								return <ReportTh key={i} style={{ width: ((column && column.width) ? column.width : 'auto') }}>{column.label}</ReportTh>
							})}
						</ReportTr>
					</ReportThead>}

					{
						(this.state.tableRows && this.state.tableRows.length > 0)
						?
						<InfiniteScroll
							element={'tbody'}
							pageStart={this.state.currentPage}
							getScrollParent={null}
							useWindow={true}
							loadMore={(page) => {
								this.callReadApi(page);
							}}
							hasMore={(this.state.tableRows.length < this.state.totalRows)}
							loader={<ReportTr key={0}>
								<ReportTd colSpan={this.state.tableColumns.length}>
									<Box textAlign={'center'} fontWeight={'bold'}>Loading ...</Box>
								</ReportTd>
							</ReportTr>}
						>
							{(this.state.tableRows && this.state.tableRows.length > 0) && this.state.tableRows.map((row, index) => {
								return <ReportTr key={index}>
									{this.printRowView(row, index)}
								</ReportTr>
							})}
						</InfiniteScroll>
						:
						<ReportTbody>
							<ReportTr key={0}>
								<ReportTd colSpan={this.state.tableColumns.length}>
									<Box textAlign={'center'} fontWeight={'bold'}>{this.state.isRead ? 'Loading ...' : 'No Result!'}</Box>
								</ReportTd>
							</ReportTr>
						</ReportTbody>
					}
				</>
			</ReportTable>
		</Box>
	}
	/* END SECTIONS */

	printRowView = (row, i) => {
		return <>
			<ReportTd>{row.customerNameDisplay}</ReportTd>
			<ReportTd>{row.recurringJobName}</ReportTd>
			<ReportTd>{row.recurringJobDescription}</ReportTd>
			<ReportTd >{row.jobTemplateName}</ReportTd>

			<ReportTd>{(row.startDate && row.startDate !== '') ? moment(row.startDate).format('DD.MM.YYYY') : ''}</ReportTd>
			<ReportTd>{(row.endDate && row.endDate !== '') ? moment(row.endDate).format('DD.MM.YYYY') : ''}</ReportTd>
					
			<ReportTd>{row.binTypeOut}</ReportTd>
			<ReportTd>{row.wasteTypeOut}</ReportTd>
			<ReportTd>{row.binTypeIn}</ReportTd>
	
			<ReportTd>{row.wasteTypeIn}</ReportTd>
			<ReportTd>
				<Tooltip title={'Edit'}>
					<span>
						<IconButton 
							className={'manage-icon-btn'}
							onClick={(e) => {
                                this.props.history.push('/manage/job/recurring-job-form?id=' + row.recurringJobId);
							}}
						>
							<EditIcon />
						</IconButton>
					</span>
				</Tooltip>
				<Tooltip title={'Delete'}>
					<span>
						<IconButton 
							className={'manage-icon-btn fill'}
							onClick={(e) => {
								this.setState({
									isRemoveDialog: true,
									removeRow: row,
								});
							}}
						>
							<DeleteIcon />
						</IconButton>
					</span>
				</Tooltip>
				<Tooltip title={'Copy'}>
					<span>
						<IconButton 
							className={'manage-icon-btn'}
							onClick={(e) => {
                                this.props.history.push('/manage/job/recurring-job-form?id=' + row.recurringJobId + '&isCopy=true');
							}}
						>
							<CopyIcon />
						</IconButton>
					</span>
				</Tooltip>
				<Tooltip title={'Audit Trail'}>
					<span>
						<IconButton 
							className={'manage-icon-btn audit-trail'}
							onClick={(e) => {
								this.setState({
									isOpenAuditTrail: true,
									objectId: row[this.state.rowId],
								});
							}}
						>
							<AuditTrailIcon />
						</IconButton>
					</span>
				</Tooltip>
			</ReportTd>
		</>
	}


	/* DIALOGS */
	setRemoveDialog = () => {
		return <CustomDialog
		  open={this.state.isRemoveDialog}
		  maxWidth={"lg"}
		  onClose={() => {
			this.setState({
			  isRemoveDialog: false,
			  removeRow: null
			});
		  }}
		>
		  <DataTableRemoveDialog 
			removeMessage={'Are you sure you want to remove?'}
			onClose={() => {
			  this.setState({
				isRemoveDialog: false,
				removeRow: null
			  });
			}}
			onOk={() => {
				let row = this.state.removeRow;

				let rowData = {
					isDeactivateOrDelete: true,
					[this.state.rowId]: row[this.state.rowId],
					recurringJobName: row.recurringJobName
				};

				this.callDeleteApi(rowData, () => {
					let rows = this.state.tableRows;
					apiUtil.removeFromArray(rows, row);
					this.setState({
						isRemoveDialog: false,
						removeRow: null,
						tableRows: rows,
					}, () => {
						if(this.state.tableRows.length <= 0){
							this.callReadApi(1);
						}
					});
				});
			}}
		  />
		</CustomDialog>
	}

	setAuditTrailDialog = () => {
        return <AuditTrailDialog 
          isOpen={this.state.isOpenAuditTrail}
          objectId={this.state.objectId}
          auditTrailObjectType={AuditTrailObjectType.RecurringJob}
          title={"Recurring Job Audit Trail"}
          onClose={() => {
            this.setState({
              isOpenAuditTrail: false,
              objectId: null,
            });
          }}
          details={(obj, index, currentVersion, previousVersion) => {
			return AuditTrailFunctions.getDetails(AuditTrailObjectType.RecurringJob, obj, index, currentVersion, previousVersion);
          }}
          manageData={(currentVersion, previousVersion, callback) => {
			return AuditTrailFunctions.getManageData(AuditTrailObjectType.RecurringJob, currentVersion, previousVersion, callback);
          }}
        />
    }
	/* END DIALOGS */


	render() {
		return <Box className={'manage-layout'}>
		<ViewLayout
		isBlocking={false}
		title={'JOBS'}
		col={<Col xs={true} className={'text-end mb-md-0'}>
			<Button
			variant="primary"
			disabled={this.state.isRead || this.state.isEdit}
			onClick={() => {
				this.props.history.push('/manage/job/recurring-job-form');
			}}
			>NEW</Button>
		</Col>}
		actionSection={<Row className={'align-items-center'}>
			{(Utils.hasPermission(this.permissions, PagesPermissions.ManageJobtemplate.read)) && <Col xs={'auto'} className={'mb-2 mb-sm-0'}>
			<Button
				variant={'success'}
				className={'pt-1 pb-1 rounded-1 '}
				onClick={(e) => {
				this.props.history.push('/manage/job/job-types');
				}}
			>
			JOB
			</Button>
			</Col>}
			{/* {(Utils.hasPermission(this.permissions, PagesPermissions.ManageService.read)) && <Col xs={'auto'} className={'mb-2 mb-sm-0'}>
			<Button
				variant={'light'}
				className={'pt-1 pb-1 rounded-1 bg-white'}
				onClick={(e) => {
				this.props.history.push('/manage/job/standard-services');
				}}
			>
				SERVICE
			</Button>
			</Col>} */}
			{(Utils.hasPermission(this.permissions, PagesPermissions.ManageTime.read)) && <Col xs={'auto'} className={'mb-2 mb-sm-0'}>
			<Button
				variant={'light'}
				className={'pt-1 pb-1 rounded-1 bg-white'}
				onClick={(e) => {
				this.props.history.push('/manage/job/time');
				}}
			>
				TIME
			</Button>
			</Col>}
			<Col xs={12} md={true} className={'order-1 order-md-3 pb-10 pb-md-0'}>
			<CurrentDateTime />
			</Col>
		</Row>}
		// toolbarSection={this.toolbarSection()}
		tableSection={<>
			{this.topSection()}
			{this.listSection()}
		</>}
		// paginationSection={this.paginationSection()}
		/>
			
			{this.setSnackBar()}
			{this.setBackDrop()}

			{this.setRemoveDialog()}
            {this.setAuditTrailDialog()}
		</Box>
	}
}

export default RecurringJobsView;
