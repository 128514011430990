import React from "react";

import queryString from "query-string";
import Carousel from 'react-elastic-carousel';
import styled from 'styled-components';

import Utils from "../../../utils/utils";
import { PageCookieSettingType } from "../../../utils/enums";

import { Box, Tabs } from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { 
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Dropdown,
} from "react-bootstrap-v5";

import makeDropdownList from "../../../components/dropdown/makeDropdownList";
import DropdownToggle from '../../../components/dropdown/DropdownToggle';
import OneStatusItem from "../../../components/OLD/OneStatusItem/OneStatusItem";
import "../../../../_metronic/assets/sass/pages/jobs/_job-status-section.scss";


const DropdownMenu = makeDropdownList(DropdownToggle);


const StyledCarousel = styled(Carousel)`
  .rec-arrow-left {
    width: unset !important;
    height: unset !important;
    min-width: unset !important;
    line-height: unset !important;
    box-shadow: unset !important;
    background-color: transparent !important;

    &[disabled] {
      display: none !important;
    }
  }

  .rec-arrow-right {
    width: unset !important;
    height: unset !important;
    min-width: unset !important;
    line-height: unset !important;
    box-shadow: unset !important;
    background-color: transparent !important;

    &[disabled] {
      display: none !important;
    }
  }
`;


class JobViewStatusSection extends React.Component {
  
  constructor(props) {
    super(props);

    let isLoadingTable = props.isLoadingTable === false || props.isLoadingTable === true ? props.isLoadingTable : false;

    let total = props.total && props.total > 0 ? props.total : 0;
    let isLoadingTotal = props.isLoadingTotal === false || props.isLoadingTotal === true ? props.isLoadingTotal : false;

    let statues = props.statues && props.statues.length > 0 ? props.statues : [];
    let isLoadingStatues = props.isLoadingStatues === false || props.isLoadingStatues === true ? props.isLoadingStatues : false;

    let types = props.types && props.types.length > 0 ? props.types : [];
    let isLoadingTypes = props.isLoadingTypes === false || props.isLoadingTypes === true ? props.isLoadingTypes : false;

    let q = queryString.parse(window.location.search);
    this.status_filter = q.status_filter ? q.status_filter : "";

    this.state = {
      isStatusOrTypes: 1,

      isLoadingTable: isLoadingTable,

      total: total,
      isLoadingTotal: isLoadingTotal,

      statues: statues,
      isLoadingStatues: isLoadingStatues,

      types: types,
      isLoadingTypes: isLoadingTypes,

      selectedStatus: this.getSelectStatus(statues),
      selectedType: null,
    };
  }

  componentDidMount() {
    this.getFilterInLocalStorage();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.total !== this.state.total) {
      let total = nextProps.total && nextProps.total > 0 ? nextProps.total : 0;
      this.setState({
        total: total,
      });
    }
    if (nextProps.isLoadingTotal !== this.state.isLoadingTotal) {
      let isLoadingTotal = nextProps.isLoadingTotal === false || nextProps.isLoadingTotal === true ? nextProps.isLoadingTotal : false;
      this.setState({
        isLoadingTotal: isLoadingTotal,
      });
    }
    
    if (nextProps.isLoadingTable !== this.state.isLoadingTable) {
      let isLoadingTable = nextProps.isLoadingTable === false || nextProps.isLoadingTable === true ? nextProps.isLoadingTable : false;
      this.setState({
        isLoadingTable: isLoadingTable,
      });
    }

    if (nextProps.statues !== this.state.statues) {
      let statues = nextProps.statues && nextProps.statues.length > 0 ? nextProps.statues : [];

      this.setState({
        statues: statues,
        selectedStatus: this.getSelectStatus(statues),
      }, () => {
        this.getFilterInLocalStorage();
      });
    }
    if (nextProps.isLoadingStatues !== this.state.isLoadingStatues) {
      let isLoadingStatues = nextProps.isLoadingStatues === false || nextProps.isLoadingStatues === true ? nextProps.isLoadingStatues : false;
      this.setState({
        isLoadingStatues: isLoadingStatues,
      });
    }

    if (nextProps.types !== this.state.types) {
      let types = nextProps.types && nextProps.types.length > 0 ? nextProps.types : [];
      this.setState({
        types: types,
      });
    }
    if (nextProps.isLoadingTypes !== this.state.isLoadingTypes) {
      let isLoadingTypes = nextProps.isLoadingTypes === false || nextProps.isLoadingTypes === true ? nextProps.isLoadingTypes : false;
      this.setState({
        isLoadingTypes: isLoadingTypes,
      });
    }
  }


  getFilterInLocalStorage = (callback = null) => {
    let settingsItem = null;

    if(this.props.settingsType){
      const { userCookieSettings } = this.props.auth;

      settingsItem = Utils.getUserCookieSettings(userCookieSettings, this.props.settingsType);
    }

    let jobStatusFilterValue = (settingsItem && settingsItem[PageCookieSettingType.jobStatusFilter] && settingsItem[PageCookieSettingType.jobStatusFilter] !== '') ? settingsItem[PageCookieSettingType.jobStatusFilter] : this.state.jobStatusFilter;
    let jobTemplateFilterValue = (settingsItem && settingsItem[PageCookieSettingType.jobTemplateFilter] && settingsItem[PageCookieSettingType.jobTemplateFilter] !== '') ? settingsItem[PageCookieSettingType.jobTemplateFilter] : this.state.jobTemplateFilter;
    let isStatusOrTypesValue = (settingsItem && settingsItem[PageCookieSettingType.isStatusOrTypes] && settingsItem[PageCookieSettingType.isStatusOrTypes] !== '') ? settingsItem[PageCookieSettingType.isStatusOrTypes] : this.state.isStatusOrTypes;
    
    let params = null;
    if(this.status_filter && this.status_filter != ''){
      params = {
        selectedStatus: this.getSelectStatusNumb(this.status_filter),
        selectedType: null,
        isStatusOrTypes: 1,
      };
    } else {
      params = {
        selectedStatus: this.getSelectStatusNumb(jobStatusFilterValue),
        selectedType: jobTemplateFilterValue,
        isStatusOrTypes: isStatusOrTypesValue,
      };
    }

    this.setState(params, () => {
      if (callback) {
        callback();
      }
    });
  }

  resetStatuses = () => {
    this.setState({
      selectedStatus: null,
      selectedType: null,
    });
  };


  getSelectStatusNumb = (id = null) => {
    if(id){
      try {
        let arr = id.split(',');
        if(arr && arr.length > 0){
          id = parseInt(arr[0]);
        }
      }catch(e){}

      let statusDraft = Utils.findStatus(this.state.statues, "jobStatusName", "Draft");
      let statusUnassigned = Utils.findStatus(this.state.statues, "jobStatusName", "Unassigned");
      let statusAssigned = Utils.findStatus(this.state.statues, "jobStatusName", "Assigned");
      let statusDispatched = Utils.findStatus(this.state.statues, "jobStatusName", "Dispatched");
      let statusAcknowledged = Utils.findStatus(this.state.statues, "jobStatusName", "Acknowledged");
      let statusStarted = Utils.findStatus(this.state.statues, "jobStatusName", "Started");
      let statusInProgress = Utils.findStatus(this.state.statues, "jobStatusName", "In Progress");
      let statusCompleted = Utils.findStatus(this.state.statues, "jobStatusName", "Completed");
      let statusCancelled = Utils.findStatus(this.state.statues, "jobStatusName", "Cancelled");
      let statusFailed = Utils.findStatus(this.state.statues, "jobStatusName", "Failed");
      let statusRejected = Utils.findStatus(this.state.statues, "jobStatusName", "Rejected");

      if(statusDraft && statusDraft.jobStatusId && statusDraft.jobStatusId === id){
        return 9;
      }
      if(statusUnassigned && statusUnassigned.jobStatusId && statusUnassigned.jobStatusId === id){
        return 9;
      }
      if(statusAssigned && statusAssigned.jobStatusId && statusAssigned.jobStatusId === id){
        return 2;
      }
      if(statusDispatched && statusDispatched.jobStatusId && statusDispatched.jobStatusId === id){
        return 3;
      }
      if(statusAcknowledged && statusAcknowledged.jobStatusId && statusAcknowledged.jobStatusId === id){
        return 4;
      }
      if(statusStarted && statusStarted.jobStatusId && statusStarted.jobStatusId === id){
        return 5;
      }
      if(statusInProgress && statusInProgress.jobStatusId && statusInProgress.jobStatusId === id){
        return 6;
      }
      if(statusCompleted && statusCompleted.jobStatusId && statusCompleted.jobStatusId === id){
        return 7;
      }
      if(statusCancelled && statusCancelled.jobStatusId && statusCancelled.jobStatusId === id){
        return 8;
      }
      if(statusFailed && statusFailed.jobStatusId && statusFailed.jobStatusId === id){
        return 8;
      }
      if(statusRejected && statusRejected.jobStatusId && statusRejected.jobStatusId === id){
        return 8;
      }
    }
    
    return null;
  }

  getSelectStatus = (statues = []) => {
    let statusDraft = Utils.findStatus(statues, "jobStatusName", "Draft");
    let statusUnassigned = Utils.findStatus(statues, "jobStatusName", "Unassigned");
    let statusAssigned = Utils.findStatus(statues, "jobStatusName", "Assigned");
    let statusDispatched = Utils.findStatus(statues, "jobStatusName", "Dispatched");
    let statusAcknowledged = Utils.findStatus(statues, "jobStatusName", "Acknowledged");
    let statusStarted = Utils.findStatus(statues, "jobStatusName", "Started");
    let statusInProgress = Utils.findStatus(statues, "jobStatusName", "In Progress");
    let statusCompleted = Utils.findStatus(statues, "jobStatusName", "Completed");
    let statusCancelled = Utils.findStatus(statues, "jobStatusName", "Cancelled");
    let statusFailed = Utils.findStatus(statues, "jobStatusName", "Failed");
    let statusRejected = Utils.findStatus(statues, "jobStatusName", "Rejected");

    if(statusDraft && statusDraft.jobStatusId && statusDraft.jobStatusId == this.status_filter){
      return 9;
    }
    if(statusUnassigned && statusUnassigned.jobStatusId && statusUnassigned.jobStatusId == this.status_filter){
      return 9;
    }
    if(statusAssigned && statusAssigned.jobStatusId && statusAssigned.jobStatusId == this.status_filter){
      return 2;
    }
    if(statusDispatched && statusDispatched.jobStatusId && statusDispatched.jobStatusId == this.status_filter){
      return 3;
    }
    if(statusAcknowledged && statusAcknowledged.jobStatusId && statusAcknowledged.jobStatusId == this.status_filter){
      return 4;
    }
    if(statusStarted && statusStarted.jobStatusId && statusStarted.jobStatusId == this.status_filter){
      return 5;
    }
    if(statusInProgress && statusInProgress.jobStatusId && statusInProgress.jobStatusId == this.status_filter){
      return 6;
    }
    if(statusCompleted && statusCompleted.jobStatusId && statusCompleted.jobStatusId == this.status_filter){
      return 7;
    }
    if(statusCancelled && statusCancelled.jobStatusId && statusCancelled.jobStatusId == this.status_filter){
      return 8;
    }
    if(statusFailed && statusFailed.jobStatusId && statusFailed.jobStatusId == this.status_filter){
      return 8;
    }
    if(statusRejected && statusRejected.jobStatusId && statusRejected.jobStatusId == this.status_filter){
      return 8;
    }

    return null;
  }

  getTotalStatusDropdown = () => {
    return <DropdownMenu
      key={'total_key_' + this.state.isStatusOrTypes}
      className={'px-3'}
      variant={"light"}
      size={"sm"}
      text={<Row className={'gx-0 align-items-center'}>
        <Col xs={true}>
          <OneStatusItem
            className={"job-statuses-types"}
            numb={<div className={"secondary-status-label"}>{this.state.total}</div>}
            text={'Total Jobs'}
            pb={{xs: 0, lg: 0, xl: 0}}
            active={false}
            isLoading={this.state.isLoadingStatues}
          />
        </Col>
        <Col xs={'auto'}><ArrowDropDownIcon /></Col>
      </Row>}
    >
      <div className={'p-2'}>
        <div className={'pb-1'}>
          <Button
            variant={"light"}
            className={'pt-1 pb-1 rounded-1 bg-white'}
            onClick={(e) => {
              if(!this.state.isLoadingTable)
              this.setState({
                isStatusOrTypes: 1,
                selectedType: null,
                selectedStatus: null,
              }, () => {
                if (this.props.onClickShowAll) {
                  this.props.onClickShowAll(this.state.isStatusOrTypes);
                }
              });
            }}
          >
            {(this.state.isStatusOrTypes === 1) ? <b>Job Status</b>: 'Job Status'}
          </Button>
        </div>
        <div>
          <Button
            variant={"light"}
            className={'pt-1 pb-1 rounded-1 bg-white'}
            onClick={(e) => {
              if(!this.state.isLoadingTable)
              this.setState({
                isStatusOrTypes: 2,
                selectedType: null,
                selectedStatus: null,
              }, () => {
                if (this.props.onClickShowAll) {
                  this.props.onClickShowAll(this.state.isStatusOrTypes);
                }
              });
            }}
          >
            {(this.state.isStatusOrTypes === 2) ? <b>Job Types</b>: 'Job Types'}
          </Button>
        </div>
      </div>
    </DropdownMenu>
  }

  getStatusItems = () => {
    let statusDraft = Utils.findStatus(this.state.statues, "jobStatusName", "Draft");
    let statusUnassigned = Utils.findStatus(this.state.statues, "jobStatusName", "Unassigned");
    let statusAssigned = Utils.findStatus(this.state.statues, "jobStatusName", "Assigned");
    let statusDispatched = Utils.findStatus(this.state.statues, "jobStatusName", "Dispatched");
    let statusAcknowledged = Utils.findStatus(this.state.statues, "jobStatusName", "Acknowledged");
    let statusStarted = Utils.findStatus(this.state.statues, "jobStatusName", "Started");
    let statusInProgress = Utils.findStatus(this.state.statues, "jobStatusName", "In Progress");
    let statusCompleted = Utils.findStatus(this.state.statues, "jobStatusName", "Completed");
    let statusCancelled = Utils.findStatus(this.state.statues, "jobStatusName", "Cancelled");
    let statusFailed = Utils.findStatus(this.state.statues, "jobStatusName", "Failed");
    let statusRejected = Utils.findStatus(this.state.statues, "jobStatusName", "Rejected");

    let menuItems = [];
    let textUnsuccessful = "Unsuccessful";
    let countUnsuccessful = 0;
    let statusUnsuccessful = false;
    let idsUnsuccessful = [];

    if (statusCancelled) {
      statusUnsuccessful = true;
      countUnsuccessful += statusCancelled.count;
      menuItems.push({
        id: 1,
        text: (
          <div>
            <span
              style={{ fontWeight: "bold", paddingRight: "10px" }}
              className={"status-unsuccessful-color"}
            >
              {statusCancelled.count}
            </span>
            <span>{statusCancelled.jobStatusName}</span>
          </div>
        ),
      });
      idsUnsuccessful.push(statusCancelled.jobStatusId);
    }
    if (statusFailed) {
      statusUnsuccessful = true;
      countUnsuccessful += statusFailed.count;
      menuItems.push({
        id: 2,
        text: (
          <div>
            <span
              style={{ fontWeight: "bold", paddingRight: "10px" }}
              className={"status-unsuccessful-color"}
            >
              {statusFailed.count}
            </span>
            <span>{statusFailed.jobStatusName}</span>
          </div>
        ),
      });
      idsUnsuccessful.push(statusFailed.jobStatusId);
    }
    if (statusRejected) {
      statusUnsuccessful = true;
      countUnsuccessful += statusRejected.count;
      menuItems.push({
        id: 3,
        text: (
          <div>
            <span
              style={{ fontWeight: "bold", paddingRight: "10px" }}
              className={"status-unsuccessful-color"}
            >
              {statusRejected.count}
            </span>
            <span>{statusRejected.jobStatusName}</span>
          </div>
        ),
      });
      idsUnsuccessful.push(statusFailed.statusRejected);
    }

    let textDraftAndUnassigned = "Draft";
    let countDraftAndUnassigned = 0;
    let statusDraftAndUnassigned = false;
    let idsDraftAndUnassigned = [];

    if (statusDraft) {
      statusDraftAndUnassigned = true;
      countDraftAndUnassigned += statusDraft.count;
      menuItems.push({
        id: 1,
        text: (
          <div>
            <span
              style={{ fontWeight: "bold", paddingRight: "10px" }}
              className={"status-draft-color"}
            >
              {statusDraft.count}
            </span>
            <span>{statusDraft.jobStatusName}</span>
          </div>
        ),
      });
      idsDraftAndUnassigned.push(statusDraft.jobStatusId);
    }
    if (statusUnassigned) {
      statusDraftAndUnassigned = true;
      countDraftAndUnassigned += statusUnassigned.count;
      menuItems.push({
        id: 2,
        text: (
          <div>
            <span
              style={{ fontWeight: "bold", paddingRight: "10px" }}
              className={"status-draft-color"}
            >
              {statusUnassigned.count}
            </span>
            <span>{statusUnassigned.jobStatusName}</span>
          </div>
        ),
      });
      idsDraftAndUnassigned.push(statusUnassigned.jobStatusId);
    }

    let items = [];
    items.push(this.getTotalStatusDropdown());

    
    if(statusDraftAndUnassigned){
      items.push(<OneStatusItem key={statusDraftAndUnassigned.jobStatusId}
        className={
          "job-statuses-types " +
          (this.state.selectedStatus === 9 ? "active" : "")
        }
        numb={
          <div
            style={{ backgroundColor: "transparent" }}
            className={"secondary-status-label"}
          >
            {countDraftAndUnassigned}
          </div>
        }
        text={<span style={{ color: '#DFDFDF' }}>{textDraftAndUnassigned}</span>}
        pb={{ xs: 0, lg: 0, xl: 0 }}
        active={false}
        isLoading={this.state.isLoadingStatues}
        onClick={(e) => {
          if(!this.state.isLoadingTable)
          if(this.state.selectedStatus === 9){
            this.setState({
              isStatusOrTypes: 1,
              selectedType: null,
              selectedStatus: null,
            }, () => {
              if (this.props.onClickShowAll) {
                this.props.onClickShowAll(this.state.isStatusOrTypes);
              }
            });
          } else {
            this.setState(
              {
                selectedStatus: 9,
                selectedType: null,
              },
              () => {
                if (this.props.onClickStatusItem) {
                  this.props.onClickStatusItem(idsDraftAndUnassigned.join(","));
                }
              }
            );
          }
        }}
      />);
    }

    if(statusAssigned){
      items.push(<OneStatusItem key={statusAssigned.jobStatusId}
        className={
          "job-statuses-types " +
          (this.state.selectedStatus === 2 ? "active" : "")
        }
        numb={<div className={'secondary-status-label'}>{statusAssigned.count}</div>}
        text={<span style={{ color: '#DFDFDF' }}>{statusAssigned.jobStatusName}</span>}
        pb={{ xs: 0, lg: 0, xl: 0 }}
        active={false}
        isLoading={this.state.isLoadingStatues}
        onClick={(e) => {
          if(!this.state.isLoadingTable)
          if(this.state.selectedStatus === 2){
            this.setState({
              isStatusOrTypes: 1,
              selectedType: null,
              selectedStatus: null,
            }, () => {
              if (this.props.onClickShowAll) {
                this.props.onClickShowAll(this.state.isStatusOrTypes);
              }
            });
          } else {
            this.setState(
              {
                selectedStatus: 2,
                selectedType: null,
              },
              () => {
                if (this.props.onClickStatusItem) {
                  this.props.onClickStatusItem(statusAssigned.jobStatusId);
                }
              }
            );
          }
        }}
      />);
    }

    if(statusDispatched){
      items.push(<OneStatusItem key={statusDispatched.jobStatusId}
        className={
          "job-statuses-types " +
          (this.state.selectedStatus === 3 ? "active" : "")
        }
        numb={<div className={'secondary-status-label'}>{statusDispatched.count}</div>}
        text={<span style={{ color: '#DFDFDF' }}>{statusDispatched.jobStatusName}</span>}
        pb={{ xs: 0, lg: 0, xl: 0 }}
        active={false}
        isLoading={this.state.isLoadingStatues}
        onClick={(e) => {
          if(!this.state.isLoadingTable)
          if(this.state.selectedStatus === 3){
            this.setState({
              isStatusOrTypes: 1,
              selectedType: null,
              selectedStatus: null,
            }, () => {
              if (this.props.onClickShowAll) {
                this.props.onClickShowAll(this.state.isStatusOrTypes);
              }
            });
          } else {
            this.setState(
              {
                selectedStatus: 3,
                selectedType: null,
              },
              () => {
                if (this.props.onClickStatusItem) {
                  this.props.onClickStatusItem(
                    statusDispatched.jobStatusId
                  );
                }
              }
            );
          }
        }}
      />);
    }

    if(statusAcknowledged){
      items.push(<OneStatusItem key={statusAcknowledged.jobStatusId}
        className={
          "job-statuses-types " +
          (this.state.selectedStatus === 4 ? "active" : "")
        }
        numb={<div className={'secondary-status-label'}>{statusAcknowledged.count}</div>}
        text={<span style={{ color: '#DFDFDF' }}>{statusAcknowledged.jobStatusName}</span>}
        pb={{ xs: 0, lg: 0, xl: 0 }}
        active={false}
        isLoading={this.state.isLoadingStatues}
        onClick={(e) => {
          if(!this.state.isLoadingTable)
          if(this.state.selectedStatus === 4){
            this.setState({
              isStatusOrTypes: 1,
              selectedType: null,
              selectedStatus: null,
            }, () => {
              if (this.props.onClickShowAll) {
                this.props.onClickShowAll(this.state.isStatusOrTypes);
              }
            });
          } else {
            this.setState(
              {
                selectedStatus: 4,
                selectedType: null,
              },
              () => {
                if (this.props.onClickStatusItem) {
                  this.props.onClickStatusItem(
                    statusAcknowledged.jobStatusId
                  );
                }
              }
            );
          }
        }}
      />);
    }

    if(statusStarted){
      items.push(<OneStatusItem key={statusStarted.jobStatusId}
        className={
          "job-statuses-types " +
          (this.state.selectedStatus === 5 ? "active" : "")
        }
        numb={<span className={'status-started-color'}>{statusStarted.count}</span>}
        text={<span className={'status-started-color'}>{statusStarted.jobStatusName}</span>}
        pb={{ xs: 0, lg: 0, xl: 0 }}
        active={false}
        isLoading={this.state.isLoadingStatues}
        onClick={(e) => {
          if(!this.state.isLoadingTable)
          if(this.state.selectedStatus === 5){
            this.setState({
              isStatusOrTypes: 1,
              selectedType: null,
              selectedStatus: null,
            }, () => {
              if (this.props.onClickShowAll) {
                this.props.onClickShowAll(this.state.isStatusOrTypes);
              }
            });
          } else {
            this.setState(
              {
                selectedStatus: 5,
                selectedType: null,
              },
              () => {
                if (this.props.onClickStatusItem) {
                  this.props.onClickStatusItem(statusStarted.jobStatusId);
                }
              }
            );
          }
        }}
      />);
    }

    if(statusInProgress){
      items.push(<OneStatusItem key={statusInProgress.jobStatusId}
        className={
          "job-statuses-types " +
          (this.state.selectedStatus === 6 ? "active" : "")
        }
        numb={<span className={'status-inprogress-color'}>{statusInProgress.count}</span>}
        text={<span className={'status-inprogress-color'}>{statusInProgress.jobStatusName}</span>}
        pb={{ xs: 0, lg: 0, xl: 0 }}
        active={false}
        isLoading={this.state.isLoadingStatues}
        onClick={(e) => {
          if(!this.state.isLoadingTable)
          if(this.state.selectedStatus === 6){
            this.setState({
              isStatusOrTypes: 1,
              selectedType: null,
              selectedStatus: null,
            }, () => {
              if (this.props.onClickShowAll) {
                this.props.onClickShowAll(this.state.isStatusOrTypes);
              }
            });
          } else {
            this.setState(
              {
                selectedStatus: 6,
                selectedType: null,
              },
              () => {
                if (this.props.onClickStatusItem) {
                  this.props.onClickStatusItem(
                    statusInProgress.jobStatusId
                  );
                }
              }
            );
          }
        }}
      />);
    }

    if(statusCompleted){
      items.push(<OneStatusItem key={statusCompleted.jobStatusId}
        className={
          "job-statuses-types " +
          (this.state.selectedStatus === 7 ? "active" : "")
        }
        numb={<span className={'status-completed-color'}>{statusCompleted.count}</span>}
        text={<span className={'status-completed-color'}>{statusCompleted.jobStatusName}</span>}
        pb={{ xs: 0, lg: 0, xl: 0 }}
        active={false}
        isLoading={this.state.isLoadingStatues}
        onClick={(e) => {
          if(!this.state.isLoadingTable)
          if(this.state.selectedStatus === 7){
            this.setState({
              isStatusOrTypes: 1,
              selectedType: null,
              selectedStatus: null,
            }, () => {
              if (this.props.onClickShowAll) {
                this.props.onClickShowAll(this.state.isStatusOrTypes);
              }
            });
          } else {
            this.setState(
              {
                selectedStatus: 7,
                selectedType: null,
              },
              () => {
                if (this.props.onClickStatusItem) {
                  this.props.onClickStatusItem(statusCompleted.jobStatusId);
                }
              }
            );
          }
        }}
      />);
    }

    if(statusUnsuccessful){
      items.push(<OneStatusItem key={statusUnsuccessful.jobStatusId}
        className={
          "job-statuses-types " +
          (this.state.selectedStatus === 8 ? "active" : "")
        }
        numb={
          <span
            style={{ backgroundColor: "transparent" }}
            className={"status-unsuccessful-color"}
          >
            {countUnsuccessful}
          </span>
        }
        text={<span className={'status-unsuccessful-color'}>{textUnsuccessful}</span>}
        pb={{ xs: 0, lg: 0, xl: 0 }}
        active={false}
        isLoading={this.state.isLoadingStatues}
        onClick={(e) => {
          if(!this.state.isLoadingTable)
          if(this.state.selectedStatus === 8){
            this.setState({
              isStatusOrTypes: 1,
              selectedType: null,
              selectedStatus: null,
            }, () => {
              if (this.props.onClickShowAll) {
                this.props.onClickShowAll(this.state.isStatusOrTypes);
              }
            });
          } else {
            this.setState(
              {
                selectedStatus: 8,
                selectedType: null,
              },
              () => {
                if (this.props.onClickStatusItem) {
                  this.props.onClickStatusItem(idsUnsuccessful.join(","));
                }
              }
            );
          }
        }}
      />);
    }

    return items;
  }

  printStatusList = () => {
    let items = this.getStatusItems();

    return <StyledCarousel
      itemsToShow={9}
      outerSpacing={0}
      itemPadding={[0, 0]}
      initialActiveIndex={0}
      breakPoints={[
        { width: 1, itemsToShow: 1 },
        { width: 200, itemsToShow: 2 },
        { width: 300, itemsToShow: 3 },
        { width: 400, itemsToShow: 4 },
        { width: 500, itemsToShow: 5 },
        { width: 600, itemsToShow: 6 },
        { width: 700, itemsToShow: 8 },
        { width: 800, itemsToShow: 8 },
        { width: 900, itemsToShow: 9 },
      ]}
      showEmptySlots={false}
      showArrows={true}
      pagination={false}
    >
      {items.map((item, i) => {
        return <React.Fragment key={i}>{item}</React.Fragment>
      })}
    </StyledCarousel>
  };

  printTypeList = () => {
    return <StyledCarousel
      itemsToShow={9}
      outerSpacing={0}
      itemPadding={[0, 0]}
      initialActiveIndex={0}
      breakPoints={[
        { width: 1, itemsToShow: 1 },
        { width: 200, itemsToShow: 2 },
        { width: 300, itemsToShow: 3 },
        { width: 400, itemsToShow: 4 },
        { width: 500, itemsToShow: 5 },
        { width: 600, itemsToShow: 6 },
        { width: 700, itemsToShow: 8 },
        { width: 800, itemsToShow: 8 },
        { width: 900, itemsToShow: 9 },
      ]}
      showEmptySlots={true}
      showArrows={true}
      pagination={false}
    >
      {this.getTotalStatusDropdown()}

      {this.state.types.map((item, i) => {
        return (
          <OneStatusItem
            key={i}
            className={
              "job-statuses-types " +
              (this.state.selectedType === item.id ? "active" : "")
            }
            numb={<span>{item.value}</span>}
            text={item.nameDisplay}
            pb={{ xs: 0, lg: 0, xl: 0 }}
            active={false}
            isLoading={this.state.isLoadingTypes}
            onClick={(e) => {
              if(!this.state.isLoadingTable)
              if(this.state.selectedType === item.id){
                this.setState({
                  isStatusOrTypes: 2,
                  selectedType: null,
                  selectedStatus: null,
                }, () => {
                  if (this.props.onClickShowAll) {
                    this.props.onClickShowAll(this.state.isStatusOrTypes);
                  }
                });
              } else {
                this.setState(
                  {
                    selectedType: item.id,
                    selectedStatus: null,
                  },
                  () => {
                    if (this.props.onClickTypeItem) {
                      this.props.onClickTypeItem(item);
                    }
                  }
                );
              }
            }}
          />
        );
      })}
    </StyledCarousel>
  };


  render() {
    return (
      <>
        {!this.state.isLoadingStatues && <Row className={'align-items-center'}>
          <Col xs={12} className={'pt-5 pb-3'}>
              {this.state.isStatusOrTypes === 1 ? this.printStatusList() : this.printTypeList()}
          </Col>
        </Row>}
      </>
    );
  }
}

export default JobViewStatusSection;
