import React from 'react';

// import Utils from '../../../utils/utils';

import { DatePicker } from 'rsuite';
import "rsuite/dist/rsuite.min.css";


const RSuiteTimePicker = (props) => {
    const [value, setValue] = React.useState((props && props.value) ? new Date(props.value) : null);


    React.useEffect(() => {
        setValue((props && props.value) ? new Date(props.value) : null);
    }, [props.value]);

    
    return <div className={'custom-rsuite-time-picker-component'}>
        <DatePicker 
            {...props}

            value={value}
            appearance={'subtle'}
            size={'xs'}
            showMeridian 
            preventOverflow
            format="hh:mm aa" 
            ranges={props.ranges ? props.ranges : [
                {
                    label: 'Now',
                    value: new Date()
                }
            ]}
        />
    </div>
}


export default RSuiteTimePicker;
