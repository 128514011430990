/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {Registration} from '../Registration'
import {ForgotPassword} from '../ForgotPassword'
import {ResetPassword} from '../ResetPassword'
import {Login} from '../Login'
import {LoginT} from '../LoginT'
import PublicIframe from "../../jobs/PublicIframe";
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

import bg from '../../../../_metronic/assets/img/login/hero_Image_6-p-1080.jpeg';
import logo from '../../../../_metronic/assets/img/Waste_Porter_logo_Long_Black.png';

const AuthPage = () => {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div
      className='d-flex justify-content-around'>
      <div className='d-flex flex-column ' style={{ maxWidth: '700px', height: '100vh', justifyContent: 'center' }}>
          <div className="d-flex flex-wrap fs-6 p-5 px-10 pb-0">
						<div className="d-flex px-lg-20 py-5">
              <a href='https://www.wasteporter.com' className='mb-3 mb-lg-12'>
                <img alt='Logo' src={toAbsoluteUrl(logo)} className='h-45px' />
              </a>
            </div>
					</div>
					<div className="d-flex flex-column px-10">
						<div className="py-5 px-lg-20">
              <Switch>
                <Route path='/sign-in' component={Login} />
                <Route path='/login' component={Login} />
                <Route path='/Sign-in-t' component={LoginT} />
                <Route path="/pub/job/preview" component={PublicIframe} />

                {/* <Route path='/Sign-up' component={Registration} />
                <Route path='/registration' component={Registration} /> */}

                <Route path='/forgot-password' component={ForgotPassword} />
                
                <Route path='/reset-password' component={ResetPassword} />

                <Redirect from='/' exact={true} to='/login' />
                <Redirect to='/login' />
              </Switch>
            </div>
          </div>
          <div className="d-flex flex-center flex-lg-stack flex-wrap fs-6 p-5 px-10 pb-0">
						<div className="d-flex px-lg-20 py-5">
							<p className='text-muted fs-6'>Copyright © powered by LogisFleet Pte Ltd {new Date().getFullYear()}.</p>
						</div>
					</div>
      </div>
				{/* <div className="d-none d-lg-flex flex-column flex-lg-row-fluid py-10 w-lg-50" 
         style={{
          backgroundImage: `url(${toAbsoluteUrl(bg)})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}>
				</div> */}
    </div>
  )
}

export default AuthPage