import React, { Component } from "react";

import { 
  Row,
  Col,
  Button,
} from "react-bootstrap-v5";

import {
  IconButton,
  Tooltip,
} from "@material-ui/core";

import CurrentDateTime from '../../../components/currentDateTime/CurrentDateTime'

import ViewLayout from "../layout/ViewLayout";

import DataTable from "../../../components/OLD/DataTable/DataTable";
import DataTableActionMenu from '../../../components/OLD/DataTable/DataTableActionMenu';
import DataTableAjaxPagination from '../../../components/OLD/DataTable/DataTableAjaxPagination.jsx';
import DataTableScrollSync from '../../../components/OLD/DataTable/DataTableScrollSync.jsx';
import DropdownIconButtonMenu from '../../../components/OLD/Dropdown/DropdownIconButtonMenu';
import IconButtonSearch from '../../../components/OLD/input/IconButtonSearch';
import makeExpanding from '../../../components/OLD/Expanding/makeExpanding';
import RemoveDialog from '../../../components/dialog/RemoveDialog';
import AdvancedFilter from "../../../components/OLD/Filter/AdvancedFilter.jsx";
import IconButtonTable from "../../../components/Buttons/IconButtonTable";
import CustomSwitch from "../../../components/OLD/Checkbox/CustomSwitch.jsx";
import AuditTrailDialog from "../../../components/OLD/Pages/AuditTrail/AuditTrailDialog.jsx";
import AuditTrailFunctions from "../../../components/OLD/Pages/AuditTrail/AuditTrailFunctions.jsx";

import { ReactComponent as PrintIcon } from "../../../../_metronic/assets/img/icons/report-print.svg";
import { ReactComponent as DownloadIcon } from "../../../../_metronic/assets/img/icons/report-download.svg"; 
import { ReactComponent as FilterIcon } from "../../../../_metronic/assets/img/icons/feather_filter.svg";  
import { ReactComponent as SettingsIcon } from "../../../../_metronic/assets/img/icons/manage-settings.svg"; 
import { ReactComponent as EditIcon } from "../../../../_metronic/assets/img/icons/report-edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../_metronic/assets/img/icons/manage-delete.svg";
import { ReactComponent as CopyIcon } from "../../../../_metronic/assets/img/icons/manage-copy.svg";
import { ReactComponent as AuditTrailIcon } from "../../../../_metronic/assets/img/icons/youtube_searched_for.svg";

import Utils from '../../../utils/utils'
import { PageCookieSettingType, PagesPermissions, AuditTrailObjectType, JobTemplateType } from '../../../utils/enums'

import { connect } from 'react-redux'
import { actions } from "../../../../setup/redux/auth/saga";
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut, dispatchApiCallDelete } from '../../../../setup/redux/dispatch/actions'


const { saveUserCookieSettings } = actions;
const ExpandingSearchBox = makeExpanding(IconButtonSearch);


class JobTypesView extends Component {

  constructor(props) {
    super(props);

    this.pageName = "manage-job-template-page";
    this.pageIdField = "jobTemplateId";
    this.pageNameField = "jobTemplateName";
    this.pageAPI = "JobTemplate";
    this.pageCaption = 'Job types'

		let user = (props.auth && props.auth.user) ? props.auth.user : null;
		this.permissions = (user && user.permissions && user.permissions.length > 0) ? user.permissions : [];

    props.layoutRef.current = this;
    this.formDrawer = null;

    this.tableRef = React.createRef();
    this.refDropdownAdvancedFilter = React.createRef();
    this.refDropdownActionMenu = React.createRef();
    this.refFormDrawer = React.createRef();
    this.refDataTableScrollSync = React.createRef();
    

    this.isActiveTemplate = (id, row, col, obj) => {
      return <div>
        <CustomSwitch
          checked={row.isActive}
          onColor={"#185CFF"}
          width={45}
          height={25}
          checkedIcon={<></>}
          uncheckedIcon={<></>}
          onChange={(checked) => {
            row.isActive = checked;

            let params = {
              jobTemplateId: row.jobTemplateId,
              jobTemplateName: row.jobTemplateName,
              isActive: checked,
            };

            this.callActivateApi(params, (data) => {
              if (data) {
                row.isActive = data.isActive;
              }
            });
          }}
        />
      </div>
    };
    this.isActiveExportTemplate = (row) => {
      return <div>{(row.isActive) ? 'Yes' : 'No'}</div>
    };
    this.isActiveExportCSVTemplate = (row) => {
      return <div>{(row.isActive) ? 'Yes' : 'No'}</div>
    };

    this.jobTemplateTypeTemplate = (id, row, col, obj) => {
      return <div>
        {row.jobTemplateType === JobTemplateType.Default && <div>Default</div>}
        {row.jobTemplateType === JobTemplateType.Custom && <div>Custom {Utils.getJobTypeInfo(row.stepType.toString())}</div>}
      </div>
    };
    this.jobTemplateTypeExportTemplate = (row) => {
      return row.jobTemplateType === JobTemplateType.Custom ? "Custom " + Utils.getJobTypeInfo(row.stepType.toString()) : "Default";
    };
    this.jobTemplateTypeExportCSVTemplate = (row) => {
      return row.jobTemplateType === JobTemplateType.Custom ? "Custom " + Utils.getJobTypeInfo(row.stepType.toString()) : "Default";
    };

    this.actionTemplate = (id, row, col, obj, index) => {
      return <>
        <Tooltip title={"Edit"}>
          <span>
            <IconButton
              className={"manage-icon-btn"}
              onClick={(e) => {
                this.props.history.push(
                  "/manage/job/job-type-form?id=" + row.jobTemplateId
                );
              }}
            >
              <EditIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={"Delete"}>
          <span>
            <IconButton
              className={"manage-icon-btn fill"}
              disabled={(row.jobTemplateType === JobTemplateType.Default)}
              onClick={(e) => {
                this.setState({
                  isRemoveDialog: true,
                  removeRow: row,
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={"Copy"}>
          <span>
            <IconButton
              className={"manage-icon-btn"}
              onClick={(e) => {
                this.props.history.push(
                  "/manage/job/job-type-form?id=" +
                    row.jobTemplateId +
                    "&isCopy=true"
                );
              }}
            >
              <CopyIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={"Audit Trail"}>
          <span>
            <IconButton
              className={"manage-icon-btn audit-trail"}
              onClick={(e) => {
                this.setState({
                  isOpenAuditTrail: true,
                  objectId: row[this.pageIdField],
                });
              }}
            >
              <AuditTrailIcon />
            </IconButton>
          </span>
        </Tooltip>
      </>
    }

    let columns = [
      { 
        id: this.pageIdField, 
        filterKey: this.pageIdField, 
        name: this.pageIdField, 
        label: 'ID',
        visible: false
      },
      {
        id: "jobTemplateName",
        name: "jobTemplateName",
        label: "Names",
        responsiveLabel: "Names",
        width: '250px',
        orderable: true,
      },
      {
        id: "jobTemplateDescription",
        name: "jobTemplateDescription",
        label: "Descriptions",
        responsiveLabel: "Descriptions",
        orderable: false,
      },
      {
        id: "isActive",
        name: "isActive",
        label: "Status (Active)",
        responsiveLabel: "Status (Active)",
        width: '150px',
        orderable: true,
        template: this.isActiveTemplate,
        exportTemplate: this.isActiveExportTemplate,
        exportCSVTemplate: this.isActiveExportCSVTemplate,
      },
      {
        id: "jobTemplateType",
        name: "jobTemplateType",
        label: "Types",
        responsiveLabel: "Types",
        width: '150px',
        orderable: true,
        template: this.jobTemplateTypeTemplate,
        exportTemplate: this.jobTemplateTypeExportTemplate,
        exportCSVTemplate: this.jobTemplateTypeExportCSVTemplate,
      },
      { 
        id: 'action',
        filterKeyParam: 'action',
        name: 'action',
        label: 'Actions',
        responsiveLabel: 'Actions',
        width: '200px',
        orderable: false,
        exportVisible: false,
        visibleInDropdown: false,
        filterable: false,
        template: this.actionTemplate,
      }
    ];
    let initColumns = Array.from(columns);
    columns = Array.from(initColumns);
    
    
    let columnVisibility = [
      false,
      true,
      true,
      true,
      true,
      true,
    ];
    let initColumnVisibility = Array.from(columnVisibility);
    columnVisibility = Array.from(initColumnVisibility);


    this.state = {
      data: null,
      err: null,
      isError: false,
      isLoading: false,

      search: '',
      sortColumn: 'jobTemplateType',
      sortDir: 'asc',
      searchTags: [],
      searchOperator: [],
      searchColumns: [],

      selected: [],
      columnVisibility: columnVisibility,
      initColumnVisibility: initColumnVisibility,
      columns: columns,
      initColumns: initColumns,
      rows: [],
      currentPage: 1,
      totalRows: 0,
      rowsPerPageSelected: 50,
      rowsPerPageOptions: [50, 100, 300],
      
      isCookies: false,
      
      isOpenAuditTrail: false,
      objectId: null,

      isRemoveDialog: false,
      removeRow: null,

      isIncludeInactive: false,
      isLoadingFormDrawer: false,
    }
  }


  componentDidMount() {
    this.getFilterInLocalStorage(() => {
      this.callReadApi();
    });
  }
  
  componentWillReceiveProps(nextProps) {
    this.reduxProps(nextProps);
	}
  

	/* FUNCTIONS */
  onWindowResize = () => {
    this.getFilterInLocalStorage();
  }

  setFilterInLocalStorage = (isCookies = true) => {
    if(this.props.settingsType >= 0){
      let { token, userCookieSettings } = this.props.auth;

      this.setState({
        isCookies: isCookies,
      });
      
      let data = {
        name: this.pageName,
        type: this.props.settingsType,
      };

      let valueLocalStorage = {
        [PageCookieSettingType.currentPage]: this.state.currentPage,
      };
      window.localStorage.setItem(this.pageName + '_' + this.props.settingsType, JSON.stringify(valueLocalStorage));

      let value = {
        [PageCookieSettingType.isCookies]: isCookies,
        [PageCookieSettingType.search]: this.state.search,
        [PageCookieSettingType.sortColumn]: this.state.sortColumn,
        [PageCookieSettingType.sortDir]: this.state.sortDir,
        [PageCookieSettingType.rowsPerPageSelected]: this.state.rowsPerPageSelected,
        [PageCookieSettingType.columnVisibility]: this.state.columnVisibility,
        [PageCookieSettingType.columns]: this.state.columns,
        [PageCookieSettingType.isIncludeInactive]: this.state.isIncludeInactive,
      };
      data['value'] = JSON.stringify(value);

      Utils.saveUserCookieSettings(data, token, (settings) => {
        if(settings){
          let settingsArr = (userCookieSettings && userCookieSettings.length > 0) ? userCookieSettings : [];
          let settingsIndex = Utils.getUserCookieSettingsIndex(settingsArr, this.props.settingsType);
          if(settingsIndex !== -1 && settingsArr[settingsIndex]){
            settingsArr[settingsIndex] = settings;
          } else {
            settingsArr.push(settings);
          }

          this.props.saveUserCookieSettings(settingsArr)
        }
      });
    }
  };
  getFilterInLocalStorage = (callback = null) => {
    let settingsItem = null;

    if(this.props.settingsType >= 0){
      const { userCookieSettings } = this.props.auth;

      settingsItem = Utils.getUserCookieSettings(userCookieSettings, this.props.settingsType, this.pageName);
    }

    let valueLocalStorageStr = window.localStorage.getItem(this.pageName + '_' + this.props.settingsType);
    let valueLocalStorage = JSON.parse(valueLocalStorageStr);
    let currentPageValue = valueLocalStorage && valueLocalStorage[PageCookieSettingType.currentPage] && valueLocalStorage[PageCookieSettingType.currentPage] !== "" ? valueLocalStorage[PageCookieSettingType.currentPage] : this.state.currentPage;
    
    let isCookiesValue = settingsItem && settingsItem[PageCookieSettingType.isCookies] && settingsItem[PageCookieSettingType.isCookies] !== "" ? settingsItem[PageCookieSettingType.isCookies] : this.state.isCookies;
    let searchValue = (settingsItem && settingsItem[PageCookieSettingType.search] && settingsItem[PageCookieSettingType.search] !== '') ? settingsItem[PageCookieSettingType.search] : this.state.search;
    let sortColumnValue = settingsItem && settingsItem[PageCookieSettingType.sortColumn] && settingsItem[PageCookieSettingType.sortColumn] !== "" ? settingsItem[PageCookieSettingType.sortColumn] : this.state.sortColumn;
    let sortDirValue = settingsItem && settingsItem[PageCookieSettingType.sortDir] && settingsItem[PageCookieSettingType.sortDir] !== "" ? settingsItem[PageCookieSettingType.sortDir] : this.state.sortDir;
    let rowsPerPageSelectedValue = settingsItem && settingsItem[PageCookieSettingType.rowsPerPageSelected] && settingsItem[PageCookieSettingType.rowsPerPageSelected] !== "" ? settingsItem[PageCookieSettingType.rowsPerPageSelected] : this.state.rowsPerPageSelected;
    let columnVisibilityValue = (settingsItem && settingsItem[PageCookieSettingType.columnVisibility] && settingsItem[PageCookieSettingType.columnVisibility] !== '') ? settingsItem[PageCookieSettingType.columnVisibility] : this.state.columnVisibility;
    let columnsValue = (settingsItem && settingsItem[PageCookieSettingType.columns] && settingsItem[PageCookieSettingType.columns] !== '') ? settingsItem[PageCookieSettingType.columns] : this.state.columns;
    let isIncludeInactiveValue = (settingsItem && settingsItem[PageCookieSettingType.isIncludeInactive] && settingsItem[PageCookieSettingType.isIncludeInactive] !== '') ? settingsItem[PageCookieSettingType.isIncludeInactive] : this.state.isIncludeInactive;
    
    let columns = Utils.setTemplateFunctionsToColumns(this.state.columns, columnsValue);
    
    this.setState({
      isCookies: isCookiesValue,
      search: searchValue,
      sortColumn: sortColumnValue,
      sortDir: sortDirValue,
      currentPage: currentPageValue,
      rowsPerPageSelected: rowsPerPageSelectedValue,
      columnVisibility: columnVisibilityValue,
      isIncludeInactive: isIncludeInactiveValue,
      columns: columns,
    }, () => {
      if (callback) {
        callback();
      }
    });
  };

  reduxProps = nextProps => {
		Utils.reduxProps(nextProps,
      this.pageName + '-list', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        let rows = (data && data.data && data.data.length > 0) ? data.data : [];
        let totalRows = (data && data.total) ? data.total : 0;
        
        this.setState({
          rows: rows,
          totalRows: totalRows,

          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(callback){
            callback();
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      this.pageName + '-list_by_page', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        let rows = (data && data.data && data.data.length > 0) ? data.data : [];
        
        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(callback){
            callback(rows);
          }
        });
      }
    );
    
    Utils.reduxProps(nextProps,
      this.pageName + '-list_all', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        let rows = (data && data.data && data.data.length > 0) ? data.data : [];
        
        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(callback){
            callback(rows);
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      this.pageName + '-list_download_excel', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(callback){
            callback(data);
          }
        });
      }
    );
    
    Utils.reduxProps(nextProps,
      this.pageName + '-details', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
          this.setState({
            isBlockingFormDrawer: false,
            details: data,

            isLoading: isLoading,
            isError: isError,
            err: err,
          }, () => {
            if(this.state.isError){
              Utils.showDrawer(this.formDrawer, false);
            }
            
            if(callback){
              callback(data);
            }
          });
      }
    );
    
    Utils.reduxProps(nextProps,
      this.pageName + '-create', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoadingFormDrawer: false,

          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(this.state.isError){
          } else {
            Utils.showDrawer(this.formDrawer, false);
          }
          
          if(callback){
            callback(data);
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      this.pageName + '-copy', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoadingFormDrawer: false,

          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(this.state.isError){
          } else {
            Utils.showDrawer(this.formDrawer, false);
          }
          
          if(callback){
            callback(data);
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      this.pageName + '-update', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoadingFormDrawer: false,

          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(this.state.isError){
          } else {
            Utils.showDrawer(this.formDrawer, false);
          }
          
          if(callback){
            callback(data);
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      this.pageName + '-delete', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoadingFormDrawer: false,

          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(this.state.isError){
          } else {
            Utils.showDrawer(this.formDrawer, false);
          }
          
          if(callback){
            callback(data);
          }
        });
      }
    );
    
    Utils.reduxProps(nextProps,
      this.pageName + '-activate', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoadingFormDrawer: false,

          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(this.state.isError){
          } else {
            Utils.showDrawer(this.formDrawer, false);
          }
          
          if(callback){
            callback(data);
          }
        });
      }
    );
  }

  setDataTable = () => {
    return <DataTable 
      ref={this.tableRef}

      className={"table-style-v4"}
      classNameWrapper={"table-v4"}

      pageName={this.pageName}
      rowId={this.pageIdField}
      caption={this.pageCaption}
      
      noMarginBottom={true}
      verticalAlign={'middle'}
      
      stickyHeader={false}
      isHeader={true}
      resizable={true}
      isDragableHeaderColumns={true}
      isDragable={false}
      isCheckbox={false}
      isBorder={false}
      isPagination={false}
      
      columnVisibility={this.state.columnVisibility}

      isLoading={this.state.isLoading}
      rowsPerPageSelected={this.state.rowsPerPageSelected}
      rowsPerPageOptions={this.state.rowsPerPageOptions}

      search={this.state.search}
      rows={this.state.rows}
      columns={this.state.columns}

      onRowClick={(id, row) => {
        
      }}

      onRemove={(row) => {

      }}

      onClickCheckAll={(obj, checked, isDisabledBatchAction) => {
        
      }}
      onCheckRow={(obj, id, isDisabledBatchAction) => {
        
      }}

      onSortEnd={(columns, columnVisibility) => {
        this.setState({
          columns: columns,
          columnVisibility: columnVisibility,
        }, () => {
          this.setFilterInLocalStorage();
        });
      }}
      onOrder={(name, sort) => {
        this.setState({
          sortColumn: name,
          sortDir: sort,
        }, () => {
          this.setFilterInLocalStorage();
          this.callReadApi(false);
        });
      }}
      onResize={(columns) => {
        if(columns){
          this.setState({
            columns: columns
          });
        }
        this.setFilterInLocalStorage();
      }}
    />
  }
	/* END FUNCTIONS */

	
	/* API */
  callReadApi = (withStatusCall = true, callback = null) => {
    this.setState({
      isLoading: true,
    });

    let data = {
      currentPage: this.state.currentPage,
      pageSize: this.state.rowsPerPageSelected,
      searchQuery: this.state.search,
      sortColumn: this.state.sortColumn,
      sortDir: this.state.sortDir,
      isIncludeInactive: ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : false,
    };
 
    if (this.state.searchTags && this.state.searchTags.length > 0) {
      data["searchTags"] = Utils.joinItems(this.state.searchTags);
    }
    if (this.state.searchOperator && this.state.searchOperator.length > 0) {
      data["searchOperator"] = Utils.getSelectedOperator(this.state.searchOperator);
    }
    if (this.state.searchColumns && this.state.searchColumns.length > 0) {
      data["searchColumns"] = Utils.getSelectedColumns(this.state.searchColumns);
    }

		this.props.dispatchApiCallGet(data, this.pageName + '-list', this.pageAPI, null, callback, () => {});

    if(withStatusCall){
      
    }
	}

  callReadByPageApi = (callback = null) => {
    let data = {
      currentPage: this.state.currentPage,
      pageSize: this.state.rowsPerPageSelected,
      searchQuery: this.state.search,
      sortColumn: this.state.sortColumn,
      sortDir: this.state.sortDir,
      isIncludeInactive: ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : false,
    };
 
    if (this.state.searchTags && this.state.searchTags.length > 0) {
      data["searchTags"] = Utils.joinItems(this.state.searchTags);
    }
    if (this.state.searchOperator && this.state.searchOperator.length > 0) {
      data["searchOperator"] = Utils.getSelectedOperator(this.state.searchOperator);
    }
    if (this.state.searchColumns && this.state.searchColumns.length > 0) {
      data["searchColumns"] = Utils.getSelectedColumns(this.state.searchColumns);
    }

		this.props.dispatchApiCallGet(data, this.pageName + '-list_by_page', this.pageAPI, null, callback, null);
  }

  callReadAllApi = (callback = null) => {
    let data = {
      currentPage: 1,
      pageSize: Utils.getMaxPageSize(),
      searchQuery: this.state.search,
      sortColumn: this.state.sortColumn,
      sortDir: this.state.sortDir,
      isIncludeInactive: ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : false,
    };

		this.props.dispatchApiCallGet(data, this.pageName + '-list_all', this.pageAPI, null, callback, null);
  }

  callDownloadExcelAllApi = (callback = null) => {
		Utils.toast(<div style={{ fontWeight: 'bold' }}>Please Wait ...</div>, 'info');
    
    let data = {
      currentPage: 1,
      pageSize: Utils.getMaxPageSize(),
      searchQuery: this.state.search,
      sortColumn: this.state.sortColumn,
      sortDir: this.state.sortDir,
      isIncludeInactive: ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : false,
    };

		this.props.dispatchApiCallGet(data, this.pageName + '-list_download_excel', this.pageAPI + '/download-excel', null, callback, null);
  }

  callDetailsApi = (id = null, callback = null) => {
    if(id){
      this.setState({
        isBlockingFormDrawer: true,
        details: null,
      });
  
      this.props.dispatchApiCallGet(null, this.pageName + '-details', this.pageAPI + '/' + id, null, callback, () => {});
    } else {
      if(callback){
        callback(null)
      }
    }
  }

  callCreateApi = (data, callback = null) => {
    this.setState({
      isLoadingFormDrawer: true,
    });

		this.props.dispatchApiCallPost(data, this.pageName + '-create', this.pageAPI, data, callback, null);
  }

  callCopyApi = (data, callback = null) => {
    this.setState({
      isLoadingFormDrawer: true,
    });

    data[this.pageNameField] = data[this.pageNameField] + ' - Copy';
    data['isCopy'] = true;

    delete data[this.pageIdField];

		this.props.dispatchApiCallPost(data, this.pageName + '-copy', this.pageAPI, data, callback, null);
  }

  callUpdateApi = (data, callback = null) => {
    this.setState({
      isLoadingFormDrawer: true,
    });

		this.props.dispatchApiCallPut(data, this.pageName + '-update', this.pageAPI, data, callback, null);
  }

  callDeleteApi = (data, callback = null) => {
    this.setState({
      isLoadingFormDrawer: true,
    });

		this.props.dispatchApiCallDelete(data, this.pageName + '-delete', this.pageAPI, data, callback, null);
  }

  callActivateApi = (data, callback = null) => {
    this.setState({
      isLoadingFormDrawer: true,
    });

		this.props.dispatchApiCallPut(data, this.pageName + '-activate', this.pageAPI + '/activate', data, callback, null);
  }
	/* END API */
	
	
	/* SECTIONS */
	actionSection = () => {
		return <Row className={'align-items-center'}>

      {(Utils.hasPermission(this.permissions, PagesPermissions.ManageJobtemplate.read)) && <Col xs={'auto'} className={'mb-2 mb-sm-0'}>
        <Button
          variant={'success'}
          className={'pt-1 pb-1 rounded-1 '}
          onClick={(e) => {
          this.props.history.push('/manage/job/job-types');
          }}
        >
          JOB
        </Button>
      </Col>}

      {/* {(Utils.hasPermission(this.permissions, PagesPermissions.ManageService.read)) && <Col xs={'auto'} className={'mb-2 mb-sm-0'}>
        <Button
          variant={'light'}
          className={'pt-1 pb-1 rounded-1 bg-white'}
          onClick={(e) => {
          this.props.history.push('/manage/job/standard-services');
          }}
        >
          SERVICE
        </Button>
      </Col>} */}

      {(Utils.hasPermission(this.permissions, PagesPermissions.ManageTime.read)) && <Col xs={'auto'} className={'mb-2 mb-sm-0'}>
        <Button
          variant={'light'}
          className={'pt-1 pb-1 rounded-1 bg-white'}
          onClick={(e) => {
          this.props.history.push('/manage/job/time');
          }}
        >
          TIME
        </Button>
      </Col>}

      <Col xs={12} md={true} className={'order-1 order-md-3 pb-10 pb-md-0'}>
        <CurrentDateTime />
      </Col>
    </Row>
	}

	toolbarSection = () => {
		return <Row className={'d-block d-md-flex align-items-center'}>
      {/* <Col xs={'auto'} md={true}>
        <DropdownIconButtonMenu 
          ref={this.refDropdownAdvancedFilter}
          className={'job-list-icon-button-dropdown'}
          overflowVisible={true}
          selected={null}
          icon={<FilterIcon className={((this.state.searchTags && this.state.searchTags.length > 0) ? 'svg-fill-active' : '')}/>}
        >
          <div>
            <AdvancedFilter
              pageName={this.pageName}
              columns={this.state.columns}
              columnVisibility={this.state.columnVisibility}
              searchTags={this.state.searchTags}
              searchOperator={this.state.searchOperator}
              searchColumns={this.state.searchColumns}
              onSearch={(
                searchTags,
                searchOperator,
                searchColumns
              ) => {
                if(this.refDropdownAdvancedFilter && this.refDropdownAdvancedFilter.current){
                  this.refDropdownAdvancedFilter.current.hide();
                }

                this.setState(
                  {
                    searchTags: searchTags,
                    searchOperator: searchOperator,
                    searchColumns: searchColumns,
                    currentPage: 1,
                  },
                  () => {
                    this.setFilterInLocalStorage();
                    this.callReadApi();
                  }
                );
              }}
                    onCancel={() => {
                      if(this.refDropdownAdvancedFilter && this.refDropdownAdvancedFilter.current){
                        this.refDropdownAdvancedFilter.current.hide();
                      }
                    }}
            />
          </div>
        </DropdownIconButtonMenu>
        {this.state.searchTags && this.state.searchTags.length > 0 && (
          <a
            className={"link-danger"}
            href={"/"}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              this.setState(
                {
                  searchTags: [],
                  searchOperator: [],
                  searchColumns: [],
                },
                () => {
                  this.setFilterInLocalStorage();
                  this.callReadApi();
                }
              );
            }}
          >
            Clear Filters
          </a>
        )}
      </Col> */}

      <Col md={'auto'} className={'pb-2 pb-md-0 d-grid'}>
        <Button
          variant={'success'}
          className={'pt-1 pb-1 rounded-1 border'}
          onClick={(e) => {
            this.props.history.push('/manage/job/job-types');
          }}
        >
          Job types
        </Button>
      </Col>
      <Col md={'auto'} className={'pb-2 pb-md-0 d-grid'}>
        <Button
          variant={'light'}
          className={'pt-1 pb-1 rounded-1 border bg-white'}
          onClick={(e) => {
            this.props.history.push('/manage/job/job-recurring');
          }}
        >
          Recurring jobs
        </Button>
      </Col>
      <Col xs={12} className={'d-xs-flex d-lg-none w-100'}></Col>

      <Col xs={'auto'} md={true} className={'text-center text-md-end'}>
        <div className='d-inline-flex align-items-center text-start'>
        <ExpandingSearchBox 
          btnClassName={'manage-icon-btn'}
          value={this.state.search}
          isOpen={(this.state.search !== '') ? true : false}
          onPressEnter={(value) => {
            this.setState({
              search: value,
              currentPage: 1,
            }, () => {
              this.setFilterInLocalStorage();
              this.callReadApi(true);
            });
          }}
          onClear={() => {
            this.setState({
              search: '',
              currentPage: 1,
            }, () => {
              this.setFilterInLocalStorage();
              this.callReadApi(true);
            });
          }}
        />
        </div>
                {Utils.isMobileTablet() && <div className='col-12 d-block d-md-none'></div>}
        <div className='d-inline-flex align-items-center text-start'>
        {/* <DropdownIconButtonMenu 
          className={'job-list-icon-button-dropdown'}
          selected={null}
          items={[
            {
              value: 1,
              text: 'Print this page',
            },
            {
              value: 2,
              text: 'Print all pages',
            }
          ]} 
          icon={<PrintIcon />} 
          onClick={(item) => {
            if(item.value === 1){
              this.callReadByPageApi((data) => {
                this.tableRef.current.print(data);
              });

            } else if(item.value === 2){
              this.callReadAllApi((data) => {
                this.tableRef.current.print(data);
              });
            }
          }}
        /> */}

        {/* <DropdownIconButtonMenu 
          className={'job-list-icon-button-dropdown'}
          selected={null}
          items={[
            {
              value: 1,
              text: 'Download all in PDF format',
            },
            {
              value: 2,
              text: 'Download all in Excel format',
            }
          ]} 
          icon={<DownloadIcon />} 
          onClick={(item) => {
              if(item.value === 1){
                this.callReadAllApi((data) => {
                  this.tableRef.current.exportPDF(data);
                });

              } else if(item.value === 2){
                this.callDownloadExcelAllApi((data) => {
                  let type = 'application/vnd.ms-excel';
                  Utils.saveByteArray(this.pageCaption, data, type);
                });
              }
          }}
        /> */}

        <DropdownIconButtonMenu 
          ref={this.refDropdownActionMenu}
          className={'job-list-icon-button-dropdown'}
          selected={null}
          icon={<SettingsIcon />}
        >
          <DataTableActionMenu 
            pageName={this.pageName}
            columns={this.state.columns}
            selected={this.state.rowsPerPageSelected}
            selectItems={this.state.rowsPerPageOptions}
            columnVisibility={this.state.columnVisibility}
            onChangeColumnVisibility={(index, value) => {
              let colVis = this.state.columnVisibility;
              colVis[index] = value;

              this.setState({
                columnVisibility: colVis
              }, () => {
                this.setFilterInLocalStorage();
              });
            }}
            isCheckedIncludeInactive={this.state.isIncludeInactive}
            canInactive={true}
            onSelectChangeAjax={(val) => {
              this.setState({
                currentPage: 1,
                rowsPerPageSelected: val
              }, () => {
                this.setFilterInLocalStorage();
                this.callReadApi();
              });
            }}
            onChangeIncludeInactive={(val) => {
              this.setState({
                isIncludeInactive: val,
                currentPage: 1,
              }, () => {
                this.setFilterInLocalStorage();
                this.callReadApi();
              });
            }}
            isCookies={this.state.isCookies}
            onResetColumns={() => {
              this.setState({
                columns: Array.from(this.state.initColumns),
                columnVisibility: Array.from(this.state.initColumnVisibility),
              }, () => {
                this.setFilterInLocalStorage(false);
                if(this.refDropdownActionMenu && this.refDropdownActionMenu.current){
                  this.refDropdownActionMenu.current.hide();
                }
              });
            }}
            onShowMore={(isShowedMore) => {
              this.setState({
                pageName: this.pageName,
              });
            }}
          />
        </DropdownIconButtonMenu>
        </div>
      </Col>
    </Row>
	}

	tableSection = () => {
		return <DataTableScrollSync ref={this.refDataTableScrollSync} className={"table-sync-v4"}>{this.setDataTable()}</DataTableScrollSync>
	}

	paginationSection = () => {
		return <DataTableAjaxPagination
      page={this.state.currentPage}
      selected={this.state.rowsPerPageSelected}
      count={this.state.totalRows}
      onPageChange={(page) => {
        this.setState({
          currentPage: page,
        }, () => {
          this.setFilterInLocalStorage();
          this.callReadApi(false);
        });
      }}
    />
	}
	/* END SECTIONS */

  
  /* DIALOGS */
  setRemoveDialog = () => {
    return <RemoveDialog 
      show={this.state.isRemoveDialog}
      isLoading={this.state.isLoadingFormDrawer}
      onCancel={() => {
        this.setState({
          isRemoveDialog: false,
          removeRow: null,
        });
      }}
      onRemove={() => {
        let row = this.state.removeRow;

        let data = {
          isDeactivateOrDelete: true,
          [this.pageIdField]: row[this.pageIdField],
          jobTemplateName: row.jobTemplateName,
        };

        this.callDeleteApi(data, (res) => {
          this.setState({
            isRemoveDialog: false,
            removeRow: null,
          });

          this.callReadApi();
        });
      }}
    />
  }
  
  setAuditTrailDialog = () => {
    return (
      <AuditTrailDialog
        isOpen={this.state.isOpenAuditTrail}
        objectId={this.state.objectId}
        auditTrailObjectType={AuditTrailObjectType.JobTemplate}
        title={"Job Template Audit Trail"}
        onClose={() => {
          this.setState({
            isOpenAuditTrail: false,
            objectId: null,
          });
        }}
        details={(obj, index, currentVersion, previousVersion) => {
          return AuditTrailFunctions.getDetails(AuditTrailObjectType.JobTemplate, obj, index, currentVersion, previousVersion);
        }}
        manageData={(currentVersion, previousVersion, callback) => {
          return AuditTrailFunctions.getManageData(AuditTrailObjectType.JobTemplate, currentVersion, previousVersion, callback);
        }}
      />
    );
  };
  /* END DIALOGS */


  render() {
    return (
      <>
        <ViewLayout
          isBlocking={this.state.isLoadingFormDrawer}
          title={'JOBS'}
          col={<Col xs={true} className={'text-end mb-md-0'}>
            
          </Col>}
          actionSection={this.actionSection()}
          toolbarSection={this.toolbarSection()}
          tableSection={this.tableSection()}
          paginationSection={this.paginationSection()}
        />

        {this.setRemoveDialog()}
        {this.setAuditTrailDialog()}
      </>
    );
  }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  dispatchApiCallPost,
  dispatchApiCallPut,
  dispatchApiCallDelete,
  saveUserCookieSettings,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(JobTypesView);
