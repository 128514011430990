import { createSlice } from "@reduxjs/toolkit";

import * as dispatchCrudApi from '../../../api/CRUD/DispatchCRUD'
import Utils from "../../../utils/utils";


function NewReducer() {
  const name = 'billing';


  const initialState = {
    customerSites: {
      isLoading: false,
      items: [],
    },
    billingDetails: {
      isLoading: false,
      data: null,
      isError: false,
    },
    billingSave: {
      isLoading: false,
      isError: false,
      job: null,
    },
    billingAddressUpdate: {
      isLoading: false,
      isError: false,
    },
  };


  const reducers = {
    startCustomerSites: (state) => {
      state.customerSites.isLoading = true;
      state.customerSites.items = [];
    },
    finishCustomerSites: (state, action) => {
      let customerSites = state.customerSites;

      let data = action.payload;
      
      customerSites.items = (data && data.data && data.data.length > 0) ? data.data : null;
      customerSites.isLoading = false;
      
      state.customerSites = customerSites;
    },
    
    
    startBillingDetails: (state) => {
      state.billingDetails.isLoading = true;
      state.billingDetails.data = [];
      state.billingDetails.isError = false;
    },
    finishBillingDetails: (state, action) => {
      let billingDetails = state.billingDetails;

      let data = action.payload;
      
      billingDetails.isError = data.isError;
      billingDetails.data = (data && data.data) ? data.data : null;
      billingDetails.isLoading = false;
      
      state.billingDetails = billingDetails;
    },
    
    
    startBillingSave: (state) => {
      state.billingSave.isLoading = true;
      state.billingSave.isError = false;
    },
    finishBillingSave: (state, action) => {
      let billingSave = state.billingSave;

      let data = action.payload;
      
      billingSave.isError = data.isError;
      billingSave.job = data.data;
      billingSave.isLoading = false;
      
      state.billingSave = billingSave;
    },


    startBillingAddressUpdate: (state) => {
      state.billingAddressUpdate.isLoading = true;
      state.billingAddressUpdate.isError = false;
    },
    finishBillingAddressUpdate: (state, action) => {
      let billingAddressUpdate = state.billingAddressUpdate;

      let data = action.payload;
      
      billingAddressUpdate.isError = data.isError;
      billingAddressUpdate.isLoading = false;
      
      state.billingAddressUpdate = billingAddressUpdate;
    },
  };


  const apis = {
    callCustomerSitesApi: (params = null) => async (dispatch) => {
      dispatch(actions.startCustomerSites());
  
      await dispatchCrudApi.readApi(params, 'job/billing/customer/sites').then(result => {
        let data = result.data.data;
        
        dispatch(actions.finishCustomerSites({ data: data, errorMessage: '', isError: false }));
      }).catch(error => {
        let isError = true;
        let errorMessage = "";

        Utils.parseError(error, (result) => {
          isError = result.isError;
          errorMessage = result.errorMessage;
        });
        
        dispatch(actions.finishCustomerSites({ data: null, errorMessage: errorMessage, isError: isError }));
      });
    },

    callJobBillingDetailsApi: (params = null) => async (dispatch) => {
      dispatch(actions.startBillingDetails());
  
      await dispatchCrudApi.readApi(null, 'job/billing/' + params).then(result => {
        let data = result.data;
        
        dispatch(actions.finishBillingDetails({ data: data, errorMessage: '', isError: false }));
      }).catch(error => {
        let isError = true;
        let errorMessage = "";

        Utils.parseError(error, (result) => {
          isError = result.isError;
          errorMessage = result.errorMessage;
        });
        
        dispatch(actions.finishBillingDetails({ data: null, errorMessage: errorMessage, isError: isError }));
      });
    },
    
    callSaveJobBillingApi: (params = null) => async (dispatch) => {
      dispatch(actions.startBillingSave());
  
      await dispatchCrudApi.updateApi(params, 'job/billing/' + params.jobId).then(result => {
        let data = result.data;
        
        dispatch(actions.finishBillingSave({ data: data, errorMessage: '', isError: false }));
      }).catch(error => {
        let isError = true;
        let errorMessage = "";

        Utils.parseError(error, (result) => {
          isError = result.isError;
          errorMessage = result.errorMessage;
        });
        
        let err = (errorMessage && errorMessage.error && errorMessage.error != '') ? errorMessage.error : null;
        if(err){
          Utils.toast(<div style={{ fontWeight: 'bold' }}>{errorMessage.error}</div>, 'error');
        }
        
        dispatch(actions.finishBillingSave({ data: null, errorMessage: errorMessage, isError: isError }));
      });
    },

    callBillingAddressUpdateApi: (params = null) => async (dispatch) => {
      dispatch(actions.startBillingAddressUpdate());
  
      await dispatchCrudApi.updateApi(params, 'customer/billing-address').then(result => {
        let data = result.data;
        
        dispatch(actions.finishBillingAddressUpdate({ data: data, errorMessage: '', isError: false }));
      }).catch(error => {
        let isError = true;
        let errorMessage = "";

        Utils.parseError(error, (result) => {
          isError = result.isError;
          errorMessage = result.errorMessage;
        });
        
        let err = (errorMessage && errorMessage.error && errorMessage.error != '') ? errorMessage.error : null;
        if(err){
          Utils.toast(<div style={{ fontWeight: 'bold' }}>{errorMessage.error}</div>, 'error');
        }
        
        dispatch(actions.finishBillingAddressUpdate({ data: null, errorMessage: errorMessage, isError: isError }));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();