import axios from 'axios';

import Utils from '../../utils/utils';


const { 
    REACT_APP_COMMON_PROFILE_API_URL
} = process.env;


const API = "usercookiesetting";

const userCookieSettingApi = {

    read: async (data, token) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_COMMON_PROFILE_API_URL + API;
        
        const getResult = await axios.get(
            URL,
            { 
                params: data,
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            })
            .catch( error => {
                let err = Utils.catchResponseError(error);

                status = err.status;
                errorMessage = err.errorMessage;
            });
            
        return { data: getResult, errorMessage: errorMessage, status: status};
    },

    create: async (data, token) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_COMMON_PROFILE_API_URL + API;

        const getResult = await axios.post(
            URL,
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            })
            .catch( error => {
                let err = Utils.catchResponseError(error);
                
                status = err.status;
                errorMessage = err.errorMessage;
            });
        return { data: getResult, errorMessage: errorMessage, status: status};
    },

    update: async (data, token) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_COMMON_PROFILE_API_URL + API;

        const getResult = await axios.put(
            URL,
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            })
            .catch( error => {
                let err = Utils.catchResponseError(error);
                
                status = err.status;
                errorMessage = err.errorMessage;
            });
        return { data: getResult, errorMessage: errorMessage, status: status};
    },

    details: async (data, token) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_COMMON_PROFILE_API_URL + API + '/detail';
        
        const getResult = await axios.get(
            URL,
            { 
                params: data,
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            })
            .catch( error => {
                let err = Utils.catchResponseError(error);
                
                status = err.status;
                errorMessage = err.errorMessage;
            });
            
        return { data: getResult, errorMessage: errorMessage, status: status};
    },

    delete: async (data, token) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_COMMON_PROFILE_API_URL + API;

        const getResult = await axios(
            {
                method: 'delete',
                url: URL,
                data: data, 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            })
            .catch( error => {
                let err = Utils.catchResponseError(error);
                
                status = err.status;
                errorMessage = err.errorMessage;
            });
        return { data: getResult, errorMessage: errorMessage, status: status};
    },
}

export default userCookieSettingApi;