import React from 'react';

import { 
  Row,
  Col,
  Container,
} from "react-bootstrap-v5";

// import CurrentDateTime from '../../../components/currentDateTime/CurrentDateTime'


const JobsViewLayout = React.forwardRef((props, ref) => {
  let [windowWidth, setWindowWidth] = React.useState(0);

  
  const resizeListener = () => {
    setWindowWidth(window.innerWidth);
  }
  
  
  React.useEffect(() => {
    try{
      resizeListener();
    } catch(e){}
    
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  });


  return (
    <div className={'jobs-view-layout bg-white'}>

        {/* TOP SECTION */}
        <div className={'top-section shadow-sm'}
          style={{ 
            top: 65 + 'px',
            zIndex: '2'
          }}
        >
          <Container className={'py-8'}>{props.topSection}</Container>
        </div>
        {/* END TOP SECTION */}

        <div className={'px-3'}>
          <div>{props.statusSection}</div>
          <div>{props.tableSection}</div>
        </div>

    </div>
  )
});

export default JobsViewLayout;