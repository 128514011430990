import React, { useState } from 'react';

import { 
  Container,
  Row,
  Col,
  Collapse,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import { useDispatch, useSelector } from "react-redux";
import binCenter from '../redux/binCenter';


export const StyledLayout = styled.div`
  font-family: 'Inter';
`;

export const StyledOpenCol = styled(Col)`
  ${props => {
    return (props.open) ? `
      border-top: 1px dashed #CFCFCF;
    ` : '';
  }}
  border-bottom: 1px dashed #CFCFCF;

  padding-top: 3px;
  padding-bottom: 3px;

  a {
    align-items: center;
    display: inline-flex;
    color: #444444;
    font-size: 16px;

    &:hover {
      color: #444444;
      opacity: 0.8;
    }
  }
`;

export const StyledGraphRow = styled(Row)`
  margin-top: 1px;
  margin-bottom: 1px;

  .dashed-top {
    border-top: 1px dashed #CFCFCF;
  }
  .dashed-bottom {
    border-bottom: 1px dashed #CFCFCF;
  }
  .dashed-bottom-mobile {
    border-bottom: 1px dashed transparent;
  }
  .dashed-left {
    border-left: 1px dashed #CFCFCF;
  }
  .dashed-right {
    border-right: 1px dashed #CFCFCF;
  }

  @media (max-width: 767px) {
    .dashed-top {
      border-top: 1px dashed transparent;
    }
    .dashed-bottom-mobile {
      border-bottom: 1px dashed #CFCFCF;
    }
    .dashed-left {
      border-left: 1px dashed transparent;
    }
    .dashed-right {
      border-right: 1px dashed transparent;
    }
  }

  @media (max-width: 992px) {
    canvas {
      margin: 0 auto;
    }
  }
`;

export const StyledTitle = styled(Col)`
  font-size: 20px;
  font-weight: 500;
  color: #111111;
  line-height: 24.2px;
`;


const ViewLayout = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { isOpen } = useSelector((state) => state.binCenter);
  const { isLive } = useSelector((state) => state.binCenterLive);

  return <StyledLayout className={'view-layout'}>
    <Container className={'px-5'}>
      <Row>
        <Col xs={12}>
          <Collapse in={isOpen}>
            <div>
              <Row>
                <StyledTitle xs={12} className={'mt-5 mb-10'}>Bin Center</StyledTitle>
              </Row>
              {
                isLive
                ?
                <StyledGraphRow className={'ps-md-10 pe-md-10'}>
                  <Col className={'px-5 pt-10 pb-10'} xs={12}>{props.section5}</Col>
                </StyledGraphRow>
                :
                <StyledGraphRow className={'ps-md-10 pe-md-10'}>
                  <Col className={'px-5 pb-10 dashed-bottom dashed-right'} xs={12} md={6}>{props.section1}</Col>
                  <Col className={'px-5 pt-10 pt-md-0 pb-10 dashed-bottom'} xs={12} md={6}>{props.section2}</Col>
                  <Col className={'px-5 pt-10 pb-10 dashed-bottom-mobile dashed-right'} xs={12} md={6}>{props.section3}</Col>
                  <Col className={'px-5 pt-10 pb-10'} xs={12} md={6}>{props.section4}</Col>
                </StyledGraphRow>
              }
            </div>
          </Collapse>
        </Col>
        <StyledOpenCol xs={12} className='text-center' open={isOpen}>
          <a href={'/'}
            className='cursor-pointer'
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();

              dispatch(binCenter.setOpen(!isOpen))
            }}
          >
            {isOpen ? 'Close' : 'Open'} <i className='material-icons'>{isOpen ? 'expand_less' : 'expand_more'}</i>
          </a>
        </StyledOpenCol>
      </Row>
    </Container>
    
    <Container className={'p-5'}>
      {props.tableHeaderSection && <Container className={'p-0 mb-5'}>{props.tableHeaderSection}</Container>}
      
      {
      isLive
      ?
      <>
        {props.tableLiveSection && <Container className={'p-0'}>{props.tableLiveSection()}</Container>}
          
        {props.paginationLiveSection && <div className={'custom-pagination-section bg-color'}>
          <Container className={'p-0'}>{props.paginationLiveSection}</Container>
        </div>}
      </>
      :
      <>
        {props.tableSection && <Container className={'p-0'}>{props.tableSection()}</Container>}
          
        {props.paginationSection && <div className={'custom-pagination-section bg-color'}>
          <Container className={'p-0'}>{props.paginationSection}</Container>
        </div>}
      </>
      }
    </Container>
  </StyledLayout>
});

export default ViewLayout;