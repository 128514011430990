import React from "react";

import { 
    Row,
    Col,
    Form,
    Spinner,
    Button,
    ListGroup,
} from "react-bootstrap-v5";

import { DrawerListContentLayout } from '../../../components/drawer/DrawerLayout'
import Utils from "../../../utils/utils";

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet } from '../../../../setup/redux/dispatch/actions'


class MapCollectBinForm extends React.Component {
  
    constructor(props) {
        super(props);
    
        this.page = 'map_collect_bin_drawer';

        this.bodyRef = React.createRef();

        let dataParams = (props.dataParams) ? props.dataParams : null;
        
        this.state = {
            dataParams: dataParams,
        };
    }


    body = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;
        
        return <Row>
            
            <Col xs={12}>
                <ListGroup variant="flush">
                    {
                        (values.jobTypeList && values.jobTypeList.length > 0)
                        ?
                        values.jobTypeList.map((item, i) => {
                            return <ListGroup.Item key={i} className={'custom-list-check-box'}>
                                <Form.Group>
                                    <Form.Check
                                        type={'checkbox'}
                                        id={`drivers-checkbox-` + i}
                                        label={item.title}
                                        checked={item.isChecked}
                                        onChange={async (e) => {
                                            await setFieldValue('jobTypeList.' + i + '.isChecked', e.target.checked);
                                        }}
                                    />
                                </Form.Group>
                            </ListGroup.Item>
                        })
                        :
                        values.isAutoSendDoDoc ? <h3 className={'mb-5'}>No items!</h3> : null
                    }
                </ListGroup>
            </Col>

        </Row>
    }
    
    footer = () => {
        let {
            validateForm,
            values,
        } = this.props.formOptions;

        return <Row>
            <Col xs={'auto'}>
                <Button 
                    type={'button'}
                    disabled={this.props.isLoading}
                    onClick={() => {
                        if(this.props.onSave){
                            this.props.onSave(values);
                        }
                    }}
                >
                    SAVE
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
            <Col xs={'auto'}>
                <Button 
                    type={'button'}
                    variant={'secondary'}
                    disabled={this.props.isLoading}
                    onClick={() => {
                        Utils.showDrawer(this.props.drawer, false);
                    }}
                >
                    CANCEL
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
        </Row>
    }


    render() {
        return <DrawerListContentLayout
            ref={this.bodyRef}
            isBlocking={this.props.isBlocking}
            body={this.body()}
            footer={this.footer()}
        />
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(MapCollectBinForm);
