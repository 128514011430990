import React from 'react';
import moment from 'moment';
import { debounce } from "lodash";
import * as Yup from 'yup';

import Utils from '../../../../utils/utils';
import {RootState} from '../../../../../setup'

import { ReactComponent as BinIcon } from "../../../../../_metronic/assets/img/icons/metro-bin.svg";

import importWeighChit, { HeaderColumnItem } from '../../redux/importWeighChit';
import { StringField, DateField, BooleanField, SelectField, TextField } from './Fields';


interface IForSelect {
  isLoading: boolean;
  items: Array<any>;
  onClick: () => void;
  onSelect: (value: any, obj: any) => void;
  onClear: () => void;
}


export const getColumns = (formOptions: any, isHeaderDisabled: boolean, state: RootState, dispatch: any, forSelect: IForSelect|null): any => {
  const { headerDropdownColumns, headerColumns } = state.importWeighChit;
  
  const getDisabledItemValues = (): any => {
    let disabledItemValues: any = [];

    if(headerColumns && headerColumns.length > 0){
      let selectedItems = headerColumns.filter(x => x.selected != null);
      disabledItemValues = selectedItems.map(x => x.selected.value);
    }

    return disabledItemValues;
  }

  if(headerColumns && headerColumns.length > 0){
    return headerColumns.map((item: any, i: number) => {
      if(!item.isAction && item.field != 'id' && item.field != 'isLoading' && item.field != 'isDelete' && item.field != 'response'){
        return {
          accessor: 'col_' + i,
          show: true,
          Header: (p: any) => <SelectField 
            isInvalid={false}
        
            isLoading={false}
            disabled={isHeaderDisabled}
            items={headerDropdownColumns}
        
            disabledItemValues={getDisabledItemValues()}
            value={item.selected ? item.selected.value : ''}
            pathName={'head_col_path_' + i}
            text={item.selected ? item.selected.title : ''}
            error={''}
            onClick={() => {
            }}
            onSelect={async (value: any, item: HeaderColumnItem) => {
              dispatch(importWeighChit.setHeaderColumnItem({ index: i, item: item }));
            }}
            onClear={async () => {
              dispatch(importWeighChit.setHeaderColumnItem({ index: i, item: null }));
            }}
          />,
  
          columns: [
            {
              Header: item.title,
              accessor: item.field,
              hideHeader: false,
              show: true,
              Cell: (cProps: any) => <RenderCol
                formOptions={formOptions}
                rowIndex={cProps.row.index}
                colIndex={i}
                headCol={item}
              />
            }
          ]
        }
      } else if (item.field == 'id'){
        return { Header: 'ID', accessor: 'id', show: false, columns: [
          {
            Header: 'ID',
            accessor: item.field,
            hideHeader: false,
            show: false,
            width: 5,
          }
        ]};
      } else if (item.field == 'isLoading'){
        return { Header: 'isLoading', accessor: 'isLoading', show: false, columns: [
          {
            Header: 'isLoading',
            accessor: item.field,
            hideHeader: false,
            show: false,
            width: 5,
          }
        ]};
      } else if (item.field == 'isDelete'){
        return { Header: 'isDelete', accessor: 'isDelete', show: false, columns: [
          {
            Header: 'isDelete',
            accessor: item.field,
            hideHeader: false,
            show: false,
            width: 5,
          }
        ]};
      } else if (item.field == 'response'){
        return { Header: 'response', accessor: 'response', show: false, columns: [
          {
            Header: 'response',
            accessor: item.field,
            hideHeader: false,
            show: false,
            width: 5,
          }
        ]};

      } else {
        return {
          accessor: 'col_action_' + i,
          Header: (p: any) => <div>&nbsp;</div>,
          show: true,
          width: 50,
  
          columns: [
            {
              Header: item.title,
              accessor: item.field,
              hideHeader: false,
              show: true,
              width: 50,
              Cell: (cProps: any) => <div style={{ textAlign: "center" }}>
                <a
                  className={"link-danger-svg-fill"}
                  style={{
                    display: 'inline-grid'
                  }}
                  href={"/"}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    if(!formOptions.values[cProps.row.id].isLoading){
                      let arr = formOptions.values.map((item: any, i: number) => ({...item, isDelete: false }));
                      formOptions.setValues(arr);
                      formOptions.setFieldValue(cProps.row.id + '.isDelete', true);
                    }
                  }}
                >
                  <BinIcon />
                </a>
              </div>
            }
          ]
        }
      }
    })
  }
}

export const getColumnsFailed = (formOptions: any, isHeaderDisabled: boolean, state: RootState, dispatch: any, forSelect: IForSelect|null): any => {
  const { headerDropdownColumns, headerColumnsFailed } = state.importWeighChit;
  
  if(headerColumnsFailed && headerColumnsFailed.length > 0){
    return headerColumnsFailed.map((item: any, i: number) => {
      if(!item.isAction && item.field != 'id' && item.field != 'isLoading' && item.field != 'isDelete' && item.field != 'response'){
        return {
          accessor: 'col_' + i,
          show: true,
          Header: (p: any) => <SelectField 
            isInvalid={false}
        
            isLoading={false}
            disabled={isHeaderDisabled}
            items={headerDropdownColumns}
        
            disabledItemValues={[]}
            value={item.selected ? item.selected.value : ''}
            pathName={'head_col_imported_path_' + i}
            text={item.selected ? item.selected.title : ''}
            error={''}
            onClick={() => {
            }}
            onSelect={async (value: any, item: HeaderColumnItem) => {
              dispatch(importWeighChit.setHeaderColumnItem({ index: i, item: item }));
            }}
            onClear={async () => {
              dispatch(importWeighChit.setHeaderColumnItem({ index: i, item: null }));
            }}
          />,

          columns: [
            {
              Header: item.title,
              accessor: item.field,
              hideHeader: false,
              show: true,
              Cell: (cProps: any) => <RenderCol
                formOptions={formOptions}
                rowIndex={cProps.row.index}
                colIndex={i}
                headCol={item}
              />
            }
          ]
        }
      } else if (item.field == 'id'){
        return { Header: 'ID', accessor: 'id', show: false, columns: [
          {
            Header: 'ID',
            accessor: item.field,
            hideHeader: false,
            show: false,
            width: 5,
          }
        ]};
      } else if (item.field == 'isLoading'){
        return { Header: 'isLoading', accessor: 'isLoading', show: false, columns: [
          {
            Header: 'isLoading',
            accessor: item.field,
            hideHeader: false,
            show: false,
            width: 5,
          }
        ]};
      } else if (item.field == 'isDelete'){
        return { Header: 'isDelete', accessor: 'isDelete', show: false, columns: [
          {
            Header: 'isDelete',
            accessor: item.field,
            hideHeader: false,
            show: false,
            width: 5,
          }
        ]};
      } else if (item.field == 'response'){
        return { Header: 'response', accessor: 'response', show: false, columns: [
          {
            Header: 'response',
            accessor: item.field,
            hideHeader: false,
            show: false,
            width: 5,
          }
        ]};

      } else {
        return {
          accessor: 'col_action_' + i,
          Header: (p: any) => <div>&nbsp;</div>,
          show: true,
          width: 50,
  
          columns: [
            {
              Header: item.title,
              accessor: item.field,
              hideHeader: false,
              show: true,
              width: 50,
              Cell: (cProps: any) => <div style={{ textAlign: "center" }}>
                <a
                  className={"link-danger-svg-fill"}
                  style={{
                    display: 'inline-grid'
                  }}
                  href={"/"}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    if(!formOptions.values[cProps.row.id].isLoading){
                      let arr = formOptions.values.map((item: any, i: number) => ({...item, isDelete: false }));
                      formOptions.setValues(arr);
                      formOptions.setFieldValue(cProps.row.id + '.isDelete', true);
                    }
                  }}
                >
                  <BinIcon />
                </a>
              </div>
            }
          ]
        }
      }
    })
  }
}

export const getColumnsSuccess = (formOptions: any, isHeaderDisabled: boolean, state: RootState, dispatch: any, forSelect: IForSelect|null): any => {
  const { headerDropdownColumns, headerColumnsSuccess } = state.importWeighChit;
  
  if(headerColumnsSuccess && headerColumnsSuccess.length > 0){
    return headerColumnsSuccess.map((item: any, i: number) => {
      if(!item.isAction && item.field != 'id' && item.field != 'isLoading' && item.field != 'isDelete' && item.field != 'response'){
        return {
          accessor: 'col_' + i,
          show: true,
          Header: (p: any) => <SelectField 
            isInvalid={false}
        
            isLoading={false}
            disabled={isHeaderDisabled}
            items={headerDropdownColumns}
        
            disabledItemValues={[]}
            value={item.selected ? item.selected.value : ''}
            pathName={'head_col_imported_path_' + i}
            text={item.selected ? item.selected.title : ''}
            error={''}
            onClick={() => {
            }}
            onSelect={async (value: any, item: HeaderColumnItem) => {
              dispatch(importWeighChit.setHeaderColumnItem({ index: i, item: item }));
            }}
            onClear={async () => {
              dispatch(importWeighChit.setHeaderColumnItem({ index: i, item: null }));
            }}
          />,

          columns: [
            {
              Header: item.title,
              accessor: item.field,
              hideHeader: false,
              show: true,
              Cell: (cProps: any) => <RenderCol
                formOptions={formOptions}
                rowIndex={cProps.row.index}
                colIndex={i}
                headCol={item}
                isSuccess={true}
              />
            }
          ]
        }
      } else if (item.field == 'id'){
        return { Header: 'ID', accessor: 'id', show: false, columns: [
          {
            Header: 'ID',
            accessor: item.field,
            hideHeader: false,
            show: false,
            width: 5,
          }
        ]};
      } else if (item.field == 'isLoading'){
        return { Header: 'isLoading', accessor: 'isLoading', show: false, columns: [
          {
            Header: 'isLoading',
            accessor: item.field,
            hideHeader: false,
            show: false,
            width: 5,
          }
        ]};
      } else if (item.field == 'isDelete'){
        return { Header: 'isDelete', accessor: 'isDelete', show: false, columns: [
          {
            Header: 'isDelete',
            accessor: item.field,
            hideHeader: false,
            show: false,
            width: 5,
          }
        ]};
      } else if (item.field == 'response'){
        return { Header: 'response', accessor: 'response', show: false, columns: [
          {
            Header: 'response',
            accessor: item.field,
            hideHeader: false,
            show: false,
            width: 5,
          }
        ]};

      } else {
        return {
          accessor: 'col_action_' + i,
          Header: (p: any) => <div>&nbsp;</div>,
          show: true,
          width: 50,
  
          columns: [
            {
              Header: item.title,
              accessor: item.field,
              hideHeader: false,
              show: true,
              width: 50,
              Cell: (cProps: any) => <div style={{ textAlign: "center" }}>
                <a
                  className={"link-danger-svg-fill"}
                  style={{
                    display: 'inline-grid'
                  }}
                  href={"/"}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    if(!formOptions.values[cProps.row.id].isLoading){
                      let arr = formOptions.values.map((item: any, i: number) => ({...item, isDelete: false }));
                      formOptions.setValues(arr);
                      formOptions.setFieldValue(cProps.row.id + '.isDelete', true);
                    }
                  }}
                >
                  <BinIcon />
                </a>
              </div>
            }
          ]
        }
      }
    })
  }
}

export const RenderCol = React.memo((props: any): any => {
  const inputRef = React.useRef('');

  let {
    setFieldValue,
    values,
    errors,
  } = props.formOptions;

  let rowIndex = props.rowIndex;
  let headCol = props.headCol;

  const handleFocus = (e: any, rowIndex: any, headCol: any) => {
    inputRef.current = '[' + rowIndex + '].' + headCol.field;
  };
  const handleBlur = (e: any, rowIndex: any, headCol: any) => {
    inputRef.current = '';
  };
  const handleChangeDebounced = debounce((e: any, value: any, rowIndex: any, headCol: any) => {
    setFieldValue(rowIndex + '.' + headCol.field, value);

    if(inputRef && inputRef.current){
      const inputField = document.querySelector('[name="' + inputRef.current + '"]') as HTMLInputElement | null;
      if (inputField) {
        inputField.focus();
      }
    }
  }, 200);

  if(headCol && headCol.selected){
    let isFirstCol = (props.colIndex == 5) ? true : false; // 5 is first column for errorMessage

    let render = null;
    let type = headCol.selected.type;
    if(props.isSuccess){
      render = headCol.selected.successRender;
      type = headCol.selected.successType;
    }
  
    if(type == 'string'){
      let isInvalid = (errors && errors['[' + rowIndex + '].' + headCol.field]) ? !!(errors['[' + rowIndex + '].' + headCol.field]) : false;
      let error = (errors && errors['[' + rowIndex + '].' + headCol.field]) ? errors['[' + rowIndex + '].' + headCol.field] : '';
      let value = (values && values[rowIndex]) ? values[rowIndex][headCol.field] : '';
      let pathName = '[' + rowIndex + '].' + headCol.field;
      
      let response = (values && values[rowIndex] && values[rowIndex].response) ? values[rowIndex].response : null;
      let errorMessage = isFirstCol ? (response && response.errorMessage && response.errorMessage != '') ? response.errorMessage : '' : '';
      isInvalid = (errorMessage != '') ? true : isInvalid;
      error = (error != '') ? error : errorMessage;
      
      return <StringField 
        isInvalid={isInvalid}
        value={value}
        pathName={pathName}
        error={error}
        disabled={false}
        onChange={(e: any) => handleChangeDebounced(e, e.target.value, rowIndex, headCol)}
        onBlur={(e: any) => handleBlur(e, rowIndex, headCol)}
        onFocus={(e: any) => handleFocus(e, rowIndex, headCol)}
      />

    } else if(type == 'number'){
      let isInvalid = (errors && errors['[' + rowIndex + '].' + headCol.field]) ? !!(errors['[' + rowIndex + '].' + headCol.field]) : false;
      let error = (errors && errors['[' + rowIndex + '].' + headCol.field]) ? errors['[' + rowIndex + '].' + headCol.field] : '';
      let value = (values && values[rowIndex]) ? values[rowIndex][headCol.field] : '';
      let pathName = '[' + rowIndex + '].' + headCol.field;
      
      let response = (values && values[rowIndex] && values[rowIndex].response) ? values[rowIndex].response : null;
      let errorMessage = isFirstCol ? (response && response.errorMessage && response.errorMessage != '') ? response.errorMessage : '' : '';
      isInvalid = (errorMessage != '') ? true : isInvalid;
      error = (error != '') ? error : errorMessage;
      
      return <StringField 
        isInvalid={isInvalid}
        // type={'number'}
        value={value}
        pathName={pathName}
        error={error}
        disabled={false}
        onChange={(e: any) => handleChangeDebounced(e, e.target.value, rowIndex, headCol)}
        onBlur={(e: any) => handleBlur(e, rowIndex, headCol)}
        onFocus={(e: any) => handleFocus(e, rowIndex, headCol)}
      />
    } else if(type == 'text'){
        let isInvalid = false;
        let error = '';
        let value = (values && values[rowIndex]) ? values[rowIndex][headCol.field] : '';

        if(render){
          return render((values && values[rowIndex] && values[rowIndex].response) ? values[rowIndex].response : null);
        } else {
          return <TextField 
            isInvalid={isInvalid}
            value={value}
            error={error}
          />
        }
        
    } else if(type == 'boolean'){
      let value = (values && values[rowIndex]) ? values[rowIndex][headCol.field] : '';
      let pathName = '[' + rowIndex + '].' + headCol.field;
      
      return <BooleanField 
        checked={value}
        pathName={pathName}
        disabled={false}
        onChange={(e: any) => setFieldValue(rowIndex + '.' + headCol.field, e.target.checked)}
      />

    } else if(type == 'date'){
      let isInvalid = (errors && errors['[' + rowIndex + '].' + headCol.field]) ? !!(errors['[' + rowIndex + '].' + headCol.field]) : false;
      let error = (errors && errors['[' + rowIndex + '].' + headCol.field]) ? errors['[' + rowIndex + '].' + headCol.field] : '';
      let value = (values && values[rowIndex] && values[rowIndex][headCol.field]) ? values[rowIndex][headCol.field] : null;
      value = (value instanceof Date) ? value : (value != null) ? value.toUpperCase() : value;
      let pathName = '[' + rowIndex + '].' + headCol.field;
      
      let response = (values && values[rowIndex] && values[rowIndex].response) ? values[rowIndex].response : null;
      let errorMessage = isFirstCol ? (response && response.errorMessage && response.errorMessage != '') ? response.errorMessage : '' : '';
      isInvalid = (errorMessage != '') ? true : isInvalid;
      error = (error != '') ? error : errorMessage;
      
      let isValidDate = false;
      let newValue = null;
      try {
        const parsedDate = moment(value, [moment.ISO_8601, Utils.getImportDateFormat(), Utils.getImportDateTimeFormat(), Utils.getImportDateTimeFormat('a'), 'DD/MM/YYYY HH:mm', 'DD/MM/YYYY HH:mm:ss', 'ddd MMM DD YYYY HH:mm:ss [GMT]Z']);
        isValidDate = parsedDate.isValid();
        newValue = isValidDate ? parsedDate.toDate() : null;
      } catch (error) {}

      if(newValue == null){
        newValue = isValidDate ? moment(value, Utils.getImportDateTimeFormat()).toDate() : null;
      }
            
      return <DateField 
        isInvalid={isInvalid}
        value={newValue}
        pathName={pathName}
        error={error}
        disabled={false}
        onChange={(value: any) => setFieldValue(rowIndex + '.' + headCol.field, value)}
        onClear={() => setFieldValue(rowIndex + '.' + headCol.field, '')}
        onBlur={(e: any) => handleBlur(e, rowIndex, headCol)}
        onFocus={(e: any) => handleFocus(e, rowIndex, headCol)}
      />

    } else if(type == 'select'){
      let forSelect = (headCol && headCol.forSelect) ? headCol.forSelect : null;
      let isLoading = (forSelect && ((forSelect.isLoading == false) || (forSelect.isLoading == true))) ? forSelect.isLoading : false;
      let items = (forSelect && forSelect.items && forSelect.items.length > 0) ? forSelect.items : [];
      let onClick = (forSelect && forSelect.onClick) ? forSelect.onClick : null;
      let onSelect = (forSelect && forSelect.onSelect) ? forSelect.onSelect : null;
      let onClear = (forSelect && forSelect.onClear) ? forSelect.onClear : null;

      let error = (errors && errors['[' + rowIndex + '].' + headCol.field]) ? errors['[' + rowIndex + '].' + headCol.field] : '';
      let value = (values && values[rowIndex]) ? values[rowIndex][headCol.field] : '';
      let newValue = ((typeof value === 'boolean') || (value instanceof Date)) ? '' : value;
      let pathName = '[' + rowIndex + '].' + headCol.field;
      
      let response = (values && values[rowIndex] && values[rowIndex].response) ? values[rowIndex].response : null;
      let errorMessage = isFirstCol ? (response && response.errorMessage && response.errorMessage != '') ? response.errorMessage : '' : '';
      let isInvalid = !(newValue);
      isInvalid = (errorMessage != '') ? true : isInvalid;
      error = (error != '') ? error : errorMessage;
      
      return <SelectField 
        isInvalid={isInvalid}
        
        isLoading={isLoading}
        disabled={false}
        items={items}
        
        disabledItemValues={[]}

        value={newValue}
        pathName={pathName}
        // text={null}
        error={error}

        onClick={() => {
          if(onClick){
            onClick();
          }
        }}
        onSelect={(value: any, obj: any) => {
          setFieldValue(rowIndex + '.' + headCol.field, value)

          if(onSelect){
            onSelect(value, obj);
          }
        }}
        onClear={() => {
          setFieldValue(rowIndex + '.' + headCol.field, '')

          if(onClear){
            onClear();
          }
        }}
      />

    } else {
      let isInvalid = (errors && errors['[' + rowIndex + '].' + headCol.field]) ? !!(errors['[' + rowIndex + '].' + headCol.field]) : false;
      let error = (errors && errors['[' + rowIndex + '].' + headCol.field]) ? errors['[' + rowIndex + '].' + headCol.field] : '';
      let value = (values && values[rowIndex]) ? values[rowIndex][headCol.field] : '';
      let newValue = (value instanceof Date) ? moment(value, [moment.ISO_8601, Utils.getImportDateFormat(), Utils.getImportDateTimeFormat(), Utils.getImportDateTimeFormat('a'), 'DD/MM/YYYY HH:mm', 'DD/MM/YYYY HH:mm:ss', 'ddd MMM DD YYYY HH:mm:ss [GMT]Z']).format(Utils.getImportDateTimeFormat()) : value;
      let pathName = '[' + rowIndex + '].' + headCol.field;
      
      let response = (values && values[rowIndex] && values[rowIndex].response) ? values[rowIndex].response : null;
      let errorMessage = isFirstCol ? (response && response.errorMessage && response.errorMessage != '') ? response.errorMessage : '' : '';
      isInvalid = (errorMessage != '') ? true : isInvalid;
      error = (error != '') ? error : errorMessage;
      
      return <StringField 
        isInvalid={isInvalid}
        value={newValue}
        pathName={pathName}
        error={error}
        disabled={false}
        onChange={(e: any) => handleChangeDebounced(e, e.target.value, rowIndex, headCol)}
        onBlur={(e: any) => handleBlur(e, rowIndex, headCol)}
        onFocus={(e: any) => handleFocus(e, rowIndex, headCol)}
      />
    }
  } else {
    let isInvalid = (errors && errors['[' + rowIndex + '].' + headCol.field]) ? !!(errors['[' + rowIndex + '].' + headCol.field]) : false;
    let error = (errors && errors['[' + rowIndex + '].' + headCol.field]) ? errors['[' + rowIndex + '].' + headCol.field] : '';
    let value = (values && values[rowIndex]) ? values[rowIndex][headCol.field] : '';
    let newValue = (value instanceof Date) ? moment(value, [moment.ISO_8601, Utils.getImportDateFormat(), Utils.getImportDateTimeFormat(), Utils.getImportDateTimeFormat('a'), 'DD/MM/YYYY HH:mm', 'DD/MM/YYYY HH:mm:ss', 'ddd MMM DD YYYY HH:mm:ss [GMT]Z']).format(Utils.getImportDateTimeFormat()) : value;
    let pathName = '[' + rowIndex + '].' + headCol.field;
    
    let isFirstCol = false;
    let response = (values && values[rowIndex] && values[rowIndex].response) ? values[rowIndex].response : null;
    let errorMessage = isFirstCol ? (response && response.errorMessage && response.errorMessage != '') ? response.errorMessage : '' : '';
    isInvalid = (errorMessage != '') ? true : isInvalid;
    error = (error != '') ? error : errorMessage;
    
    return <StringField 
      isInvalid={isInvalid}
      value={newValue}
      pathName={pathName}
      error={error}
      disabled={false}
      onChange={(e: any) => handleChangeDebounced(e, e.target.value, rowIndex, headCol)}
      onBlur={(e: any) => handleBlur(e, rowIndex, headCol)}
      onFocus={(e: any) => handleFocus(e, rowIndex, headCol)}
    />
  }
})

export const validationSchema = (state: any, values: any): any => {
  const { headerColumns, uom } = state.importWeighChit;

  let schema = [];
  let selectedFields = headerColumns.filter((column: any) => column.selected !== null);
  if(selectedFields && selectedFields.length > 0){
    schema = selectedFields.map((item: any, i: number) => {
      return { [item.field]: (typeof item.selected.validation === 'function') ? item.selected.validation(uom, null, null) : item.selected.validation }
    });
  }

  if(schema && schema.length > 0){
    let binWeight = selectedFields.find((column: any) => column.selected.name === 'binWeight');
    let tareBinWeight = selectedFields.find((column: any) => column.selected.name === 'tareBinWeight');
    let nonBillableBinWeight = selectedFields.find((column: any) => column.selected.name === 'nonBillableBinWeight');
    
    let schemaObj = Object.assign({}, ...schema);
    if(binWeight && tareBinWeight){
      if(schemaObj[binWeight?.field]) {
        schemaObj[tareBinWeight?.field] = (typeof tareBinWeight?.selected.validation === 'function') ? tareBinWeight?.selected.validation(uom, binWeight?.field, null) : tareBinWeight?.selected.validation;
      }
    }
    if(nonBillableBinWeight){
      if(schemaObj[nonBillableBinWeight?.field]) {
        schemaObj[nonBillableBinWeight?.field] = (typeof nonBillableBinWeight?.selected.validation === 'function') ? nonBillableBinWeight?.selected.validation(uom, binWeight?.field, tareBinWeight?.field) : nonBillableBinWeight?.selected.validation;
      }
    }

    let arr = values.filter((item: any) => (item !== undefined) && (item !== null) && (item.id !== undefined) && (item.id !== null));
    
    let validationSchema = Yup.array().of(Yup.object().shape(schemaObj));
    return validationSchema.validate(arr, { abortEarly: false });
  } else {
    let validationSchema = Yup.array().of(Yup.object().shape({}));
    return validationSchema.validate(values, { abortEarly: false });
  }
}

export const validationSchemaRow = (state: any, values: any): boolean => {
  const { headerColumns, uom } = state.importWeighChit;

  let schema = [];
  let selectedFields = headerColumns.filter((column: any) => column.selected !== null);
  if(selectedFields && selectedFields.length > 0){
    schema = selectedFields.map((item: any, i: number) => {
      return { [item.field]: (typeof item.selected.validation === 'function') ? item.selected.validation(uom, null, null) : item.selected.validation }
    });
  }

  let back = true;
  if(schema && schema.length > 0){
    let schemaObj = Object.assign({}, ...schema);
    let validationSchema = Yup.object().shape(schemaObj);
    try {
      validationSchema.validateSync(values, { abortEarly: false });
      back = true;
    } catch(e){
      back = false;
    }
  } else {
    back = true;
  }

  return back;
}

export const validation = (state: RootState): boolean => {
  const { headerColumns, headerDropdownColumns } = state.importWeighChit;
  
  const requiredFields = headerDropdownColumns.filter((column) => column.required);
  const isValid = requiredFields.every((field) => {
    const selectedFields = headerColumns.filter((column) => column.selected !== null);
    return selectedFields.find((column: any) => column.selected.value === field.value);
  });
  
  return isValid;
}

export const getData = (state: RootState, form: any): Array<any> => {
  const { headerColumns } = state.importWeighChit;

  let data: Array<any> = [];
  if(form && form.length > 0){
    let selectedFields = headerColumns.filter((column: any) => column.selected !== null);
    
    data = form.map((row: any, r: number) => {
      if(row && (typeof row.id !== 'undefined' && (typeof row.id === 'number' || row.id === 0))){
        let obj: any = {};
  
        selectedFields.forEach((col: any, c: number) => {
          if(col && col.selected && col.selected.type == 'date'){
            if(moment(row[col.field], [moment.ISO_8601, Utils.getImportDateFormat(), Utils.getImportDateTimeFormat(), Utils.getImportDateTimeFormat('a'), 'DD/MM/YYYY HH:mm', 'DD/MM/YYYY HH:mm:ss', 'ddd MMM DD YYYY HH:mm:ss [GMT]Z']).isValid()){
              let parsedValue: any = row[col.field];

              if (parsedValue instanceof Date) {
                parsedValue = moment(row[col.field]).format('DD/MM/YYYY HH:mm:ss');
              } else {
                parsedValue = row[col.field];
              }
    
              try {
                let testDate = moment(parsedValue, [moment.ISO_8601, Utils.getImportDateFormat(), Utils.getImportDateTimeFormat(), Utils.getImportDateTimeFormat('a'), 'DD/MM/YYYY HH:mm', 'DD/MM/YYYY HH:mm:ss', 'ddd MMM DD YYYY HH:mm:ss [GMT]Z']);
                parsedValue = testDate.toDate();
              } catch (error) {}

              obj[col.selected.name] = parsedValue;
            } else {
              obj[col.selected.name] = row[col.field];
            }
          } else {
            if(col && col.selected && col.selected.item){
              let customFields = obj[col.selected.itemField.mainKey];
              let customValue = col.selected.item[col.selected.itemField.dataValue];

              let newItem: any = {
                [col.selected.itemField.dataKey]: customValue,
                [col.selected.itemField.labelKey]: row[col.field],
              };

              customFields = Utils.addToArrayWithField(customFields, customValue, col.selected.itemField.dataKey, newItem);

              obj[col.selected.itemField.mainKey] = customFields;
            } else {
              obj[col.selected.name] = row[col.field];
            }
          }
        });
  
        return obj;
      }
    });
  }

  return data;
}

export const getReverseData = (headerColumns: any, form: any): any => {
  let reversedData: Array<any> = [];
  if(form && form.length > 0){
    let selectedFields = headerColumns.filter((column: any) => column.selected !== null);

    reversedData = form.map((row: any, r: number) => {
      let obj: any = { response: row };

      selectedFields.forEach((col: any, c: number) => {
        if(col && col.selected && col.selected.item){
          let selectedCustomField = '';
          
          let customFields = row[col.selected.itemField.mainKey];
          let itms = customFields.filter((x: any) => x[col.selected.itemField.dataKey] == col.selected.name);
          if(itms && itms.length > 0){
            selectedCustomField = itms[0][col.selected.itemField.labelKey];
          }

          obj[col.field] = selectedCustomField;
        } else {
          obj[col.field] = row[col.selected.name];
        }
      });

      return obj;
    });
  }


  let data = [];
  let headerArr: Array<any> = [];
  if(reversedData && reversedData.length > 0){
    headerArr = Object.keys({id: null, isDelete: false, isLoading: false, ...reversedData[0]});
    data = reversedData.map((item: any, i: number) => ({...item, id: i, isDelete: false, isLoading: false }));
  }


  let realHeaderArr: Array<any> = [];
  if(headerArr && headerArr.length > 0){
    realHeaderArr = headerArr.map((field: any, f: number) => {
      if((field != 'id') && (field != 'isLoading') && (field != 'isDelete') && (field != 'response')){
        let obj = headerColumns.filter((column: any) => column.field == field);
        if(obj && obj.length > 0){
          return {
            realField: obj[0].field,
            field: obj[0].selected.name,
            title: obj[0].selected.label,
            selected: obj[0].selected,
          };
        } else {
          return {
            realField: field,
            field: field,
            title: '',
            selected: null,
          };
        }
      } else {
        return {
          realField: field,
          field: field,
          title: '',
          selected: null,
        };
      }
    });
  }

  return {
    reversedData: reversedData,
    data: data,
    headerArr: headerArr,
    realHeaderArr: realHeaderArr,
  };
}