/* eslint-disable no-loop-func */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import * as dispatchCrudApi from '../../../api/CRUD/DispatchCRUD'
import Utils from "../../../utils/utils";
import { ParseResult } from "../../../utils/interfaces";
import { BinActivity, PageCookieSettingType } from '../../../utils/enums'


interface InitState {
  isLoading: boolean,
  selected: Array<any>,
  columnVisibility: Array<any>,
  columns: Array<any>,
  rows: Array<any>,
  currentPage: number,
  totalRows: number,
  rowsPerPageSelected: number,
  rowsPerPageOptions: Array<any>,
  search: any,
  sortColumn: string,
  sortDir: string,
  isIncludeInactive: any,
  isInactive: any,
  
  binStatus: any,
}


function NewReducer() {
  const name = 'binCenterTableLive';


  const initialState: InitState = {
    isLoading: false,
    selected: [],
    columnVisibility: [],
    columns: [],
    rows: [],
    currentPage: 1,
    totalRows: 0,
    rowsPerPageSelected: 50,
    rowsPerPageOptions: [50, 100, 300],
    search: '',
    sortColumn: 'jobcompleteddate',
    sortDir: 'desc',
    isIncludeInactive: null,
    isInactive: false,

    binStatus: null,
  };


  const reducers = {
    setColumns: (state: InitState, action: PayloadAction<any>) => {
      state.columns = action.payload;
    },
    setColumnsVisibility: (state: InitState, action: PayloadAction<any>) => {
      state.columnVisibility = action.payload;
    },
    setSearchParams: (state: InitState, action: PayloadAction<any>) => {
      state.search = action.payload;
      state.currentPage = 1;
    },
    setSortParams: (state: InitState, action: PayloadAction<{ sortColumn: any, sortDir: any }>) => {
      state.sortColumn = action.payload.sortColumn;
      state.sortDir = action.payload.sortDir;
    },
    setCurrentPage: (state: InitState, action: PayloadAction<any>) => {
      state.currentPage = action.payload;
    },
    setPerPageSelected: (state: InitState, action: PayloadAction<any>) => {
      state.rowsPerPageSelected = action.payload;
      state.currentPage = 1;
    },

    startClearFilter: (state: InitState) => {
      state.binStatus = null;
    },

    startRead: (state: InitState) => {
      state.isLoading = true;
    },
    finishRead: (state: InitState, action: PayloadAction<{ data: any, total: any }>) => {
      let data = (action.payload && action.payload.data) ? action.payload.data : [];
      let total = (action.payload && action.payload.total) ? action.payload.total : 0;

      state.rows = data;
      state.totalRows = total;
      state.isLoading = false;
    },

    startBinStatusFilter: (state: InitState, action: PayloadAction<{ id: any, checked: boolean }>) => {
      let id = action.payload.id;
      let checked = action.payload.checked;

      let binStatus = null;
      if(!checked){
        if(Utils.isNumber(id)){
          if(id.toString() == BinActivity.IN.toString()){
            binStatus = BinActivity.IN
          } else if(id.toString() == BinActivity.OUT.toString()){
            binStatus = BinActivity.OUT
          } else if(id.toString() == BinActivity.NEED_ATTENTION.toString()){
            binStatus = BinActivity.NEED_ATTENTION
          } else {
            binStatus = null;
          }
        } else {
          binStatus = null;
        }
      } else {
        binStatus = null
      }

      state.binStatus = binStatus;
    },
  };


  const apis = {
    callReadApi: (param: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startRead());

      await dispatchCrudApi.readApi(param, 'bin-center/live-bin-list').then(result => {
        let data = result.data.data;
        let total = result.data.total;
        
        callback(true, data);
        dispatch(actions.finishRead({ data, total }));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false, null);
        dispatch(actions.finishRead({ data: null, total: 0 }));
      });
    },
    
    setClearFilter: (callback: () => void) => async (dispatch: any) => {
      await dispatch(actions.startClearFilter());
      callback();
    },

    setBinStatusFilter: (param: any, callback: () => void) => async (dispatch: any) => {
      await dispatch(actions.startBinStatusFilter(param));
      callback();
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();