import { createSlice } from "@reduxjs/toolkit";

import * as dispatchCrudApi from '../../../app/api/CRUD/DispatchCRUD'
import Utils from "../../../app/utils/utils";
import { PageCookieSettingType } from "../../../app/utils/enums";


function NewReducer() {
  const name = 'tableSection';


  const initialState = {
    isLoading: false,
    isLoadingDownloadExcel: false,
    isDisabledBatchAction: true,
    
    search: '',
    isIncludeInactive: null,
    isInactive: false,

    rows: [],
    totalRows: 0,
    currentPage: 1,
    rowsPerPageSelected: 50,
    rowsPerPageOptions: [50, 100, 300],
    
    sortColumn: null,
    sortDir: null,
    sortColumnInit: null,
    sortDirInit: null,
    columnVisibility: [],
    initColumnVisibility: [],
    columns: [],
    initColumns: [],

    selectedCount: 0,
  };


  const reducers = {
    tableGetFilters: (state, action) => {
      const auth = (action.payload.auth) ? action.payload.auth : null;
      const settingsType = (action.payload.settingsType) ? action.payload.settingsType : null;
      const pageName = (action.payload.pageName) ? action.payload.pageName : null;

      let sortColumn = (action.payload.sortColumn) ? action.payload.sortColumn : null;
      let sortDir = (action.payload.sortDir) ? action.payload.sortDir : null;
      let sortColumnInit = (action.payload.sortColumnInit) ? action.payload.sortColumnInit : null;
      let sortDirInit = (action.payload.sortDirInit) ? action.payload.sortDirInit : null;

      let columnVisibility = (action.payload.columnVisibility) ? action.payload.columnVisibility : [];
      let initColumnVisibility = (action.payload.initColumnVisibility) ? action.payload.initColumnVisibility : [];
      let columns = (action.payload.columns) ? action.payload.columns : [];
      let initColumns = (action.payload.initColumns) ? action.payload.initColumns : [];

      let settingsItem = null;
      if(settingsType){
        const { userCookieSettings } = auth;
        settingsItem = Utils.getUserCookieSettings(userCookieSettings, settingsType, pageName);
      }

      let valueLocalStorageStr = window.localStorage.getItem(pageName + '_' + settingsType);
      let valueLocalStorage = JSON.parse(valueLocalStorageStr);
      let currentPageValue = valueLocalStorage && valueLocalStorage[PageCookieSettingType.currentPage] && valueLocalStorage[PageCookieSettingType.currentPage] !== "" ? valueLocalStorage[PageCookieSettingType.currentPage] : initialState.currentPage;
      let searchValue = valueLocalStorage && valueLocalStorage[PageCookieSettingType.search] && valueLocalStorage[PageCookieSettingType.search] !== "" ? valueLocalStorage[PageCookieSettingType.search] : initialState.search;
      
      let rowsPerPageSelectedValue = settingsItem && settingsItem[PageCookieSettingType.rowsPerPageSelected] && settingsItem[PageCookieSettingType.rowsPerPageSelected] !== "" ? settingsItem[PageCookieSettingType.rowsPerPageSelected] : initialState.rowsPerPageSelected;
      let isIncludeInactiveValue = (settingsItem && settingsItem[PageCookieSettingType.isIncludeInactive] && settingsItem[PageCookieSettingType.isIncludeInactive] !== '') ? settingsItem[PageCookieSettingType.isIncludeInactive] : initialState.isIncludeInactive;
      let isInactiveValue = (settingsItem && settingsItem[PageCookieSettingType.isInactive] && settingsItem[PageCookieSettingType.isInactive] !== '') ? settingsItem[PageCookieSettingType.isInactive] : initialState.isInactive;
      
      let sortColumnValue = settingsItem && settingsItem[PageCookieSettingType.sortColumn] && settingsItem[PageCookieSettingType.sortColumn] !== "" ? settingsItem[PageCookieSettingType.sortColumn] : sortColumn;
      let sortDirValue = settingsItem && settingsItem[PageCookieSettingType.sortDir] && settingsItem[PageCookieSettingType.sortDir] !== "" ? settingsItem[PageCookieSettingType.sortDir] : sortDir;
      let columnVisibilityValue = (settingsItem && settingsItem[PageCookieSettingType.columnVisibility] && settingsItem[PageCookieSettingType.columnVisibility].length > 0) ? settingsItem[PageCookieSettingType.columnVisibility] : columnVisibility;
      let columnsValue = (settingsItem && settingsItem[PageCookieSettingType.columns] && settingsItem[PageCookieSettingType.columns].length > 0) ? settingsItem[PageCookieSettingType.columns] : columns;
      

      let newColumns = Utils.setTemplateFunctionsToColumns(columns, columnsValue);


      state.sortColumnInit = sortColumnInit;
      state.sortDirInit = sortDirInit;
      state.initColumnVisibility = initColumnVisibility;
      state.initColumns = initColumns;

      state.sortColumn = sortColumnValue;
      state.sortDir = sortDirValue;
      state.columnVisibility = columnVisibilityValue;
      state.columns = newColumns;

      state.currentPage = currentPageValue;
      state.search = searchValue;
      state.rowsPerPageSelected = rowsPerPageSelectedValue;
      state.isIncludeInactive = isIncludeInactiveValue;
      state.isInactive = isInactiveValue;
    },
    tableSetFilters: (state, action) => {
      const auth = (action.payload.auth) ? action.payload.auth : null;
      const settingsType = (action.payload.settingsType) ? action.payload.settingsType : null;
      const pageName = (action.payload.pageName) ? action.payload.pageName : null;

      let sortColumn = (action.payload.sortColumn) ? action.payload.sortColumn : state.sortColumn;
      let sortDir = (action.payload.sortDir) ? action.payload.sortDir : state.sortDir;
      let columnVisibility = (action.payload.columnVisibility) ? action.payload.columnVisibility : state.columnVisibility;
      let columns = (action.payload.columns) ? action.payload.columns : state.columns;


      state.sortColumn = sortColumn;
      state.sortDir = sortDir;
      state.columnVisibility = columnVisibility;
      state.columns = columns;


      if(settingsType){
        let { token, userCookieSettings } = auth;
  
        let data = {
          name: pageName,
          type: settingsType,
        };
  

        let valueLocalStorageStr = window.localStorage.getItem(pageName + '_' + settingsType);
        let valueLocalStorage = (valueLocalStorageStr && valueLocalStorageStr != '') ? JSON.parse(valueLocalStorageStr) : {};

        valueLocalStorage[PageCookieSettingType.currentPage] = state.currentPage;
        valueLocalStorage[PageCookieSettingType.search] = state.search;

        window.localStorage.setItem(pageName + '_' + settingsType, JSON.stringify(valueLocalStorage));
  

        let value = {
          [PageCookieSettingType.sortColumn]: state.sortColumn,
          [PageCookieSettingType.sortDir]: state.sortDir,
          [PageCookieSettingType.rowsPerPageSelected]: state.rowsPerPageSelected,
          [PageCookieSettingType.columnVisibility]: state.columnVisibility,
          [PageCookieSettingType.columns]: state.columns,
          [PageCookieSettingType.isIncludeInactive]: state.isIncludeInactive,
          [PageCookieSettingType.isInactive]: state.isInactive,
        };
        data['value'] = JSON.stringify(value);
  
        Utils.saveUserCookieSettings(data, token, (settings) => {
          if(settings){
            let settingsArr = (userCookieSettings && userCookieSettings.length > 0) ? userCookieSettings : [];
            let settingsIndex = Utils.getUserCookieSettingsIndex(settingsArr, settingsType);
            if(settingsIndex !== -1 && settingsArr[settingsIndex]){
              let oldValue = JSON.parse(settingsArr[settingsIndex].value);
              let newValue = JSON.parse(settings.value);

              let value = Object.assign({}, oldValue);
              value[PageCookieSettingType.sortColumn] = newValue[PageCookieSettingType.sortColumn];
              value[PageCookieSettingType.sortDir] = newValue[PageCookieSettingType.sortDir];
              value[PageCookieSettingType.rowsPerPageSelected] = newValue[PageCookieSettingType.rowsPerPageSelected];
              value[PageCookieSettingType.columnVisibility] = newValue[PageCookieSettingType.columnVisibility];
              value[PageCookieSettingType.columns] = newValue[PageCookieSettingType.columns];
              value[PageCookieSettingType.isIncludeInactive] = newValue[PageCookieSettingType.isIncludeInactive];
              value[PageCookieSettingType.isInactive] = newValue[PageCookieSettingType.isInactive];
              
              settingsArr[settingsIndex].updated = settings.updated;
              settingsArr[settingsIndex].value = JSON.stringify(value);
            } else {
              settingsArr.push(settings);
            }
          }
        });
      }
    },

    tableSearch: (state, action) => {
      const payload = (action.payload && action.payload != '') ? action.payload : initialState.search;

      state.search = payload;
      state.currentPage = 1;
      state.selectedCount = 0;
    },
    tableChangeIncludeInactive: (state, action) => {
      const payload = ((action.payload == false) || (action.payload == true)) ? action.payload : initialState.isIncludeInactive;

      state.isIncludeInactive = payload;
      state.currentPage = 1;
      state.selectedCount = 0;
    },
    tableChangeInactive: (state, action) => {
      const payload = ((action.payload == false) || (action.payload == true)) ? action.payload : initialState.isInactive;

      state.isInactive = payload;
      state.currentPage = 1;
      state.selectedCount = 0;
    },
    tableChangeRowsPerPageSelected: (state, action) => {
      const payload = (action.payload) ? action.payload : initialState.rowsPerPageSelected;

      state.rowsPerPageSelected = payload;
      state.currentPage = 1;
      state.selectedCount = 0;
    },
    tableChangeRowsPerPageOptions: (state, action) => {
      const payload = (action.payload) ? action.payload : initialState.rowsPerPageOptions;

      state.rowsPerPageOptions = payload;
      state.selectedCount = 0;
    },
    tableChangeCurrentPage: (state, action) => {
      const payload = (action.payload) ? action.payload : 1;

      state.currentPage = payload;
      state.selectedCount = 0;
    },
    tableChangeDisabledBatchAction: (state, action) => {
      const payload = ((action.payload == false) || (action.payload == true)) ? action.payload : initialState.isDisabledBatchAction;

      state.isDisabledBatchAction = payload;
    },
    tableChangeSelected: (state, action) => {
      const payload = (action.payload) ? action.payload : initialState.selectedCount;

      state.selectedCount = payload;
    },
    tableChangeRows: (state, action) => {
      const payload = (action.payload && action.payload.length > 0) ? action.payload : [];
      
      state.rows = payload;
    },

    
    startRead: (state, action) => {
      state.isLoading = true;
      state.isDisabledBatchAction = true;
    },
    finishRead: (state, action) => {
      let data = action.payload;
      let arr = [];
      let total = 0;
      if (data && data.data && data.data.data && data.data.data.length > 0) {
        arr = data.data.data;
        total = data.data.total;
      }
      
      state.rows = arr;
      state.totalRows = total;
      state.isLoading = false;
    },
    

    startDownloadExcel: (state, action) => {
      state.isLoadingDownloadExcel = true;
    },
    finishDownloadExcel: (state, action) => {
      if(!action.payload.isError){
        let caption = action.payload.data.caption;
        let base64 = action.payload.data.base64;
        let type = action.payload.data.type;
        
        Utils.saveByteArray(caption, base64, type);
      }
      
      state.isLoadingDownloadExcel = false;
    },
  };


  const apis = {
    callTableReadApi: (payload = null) => async (dispatch) => {
      dispatch(actions.startRead());
      
      let params = (payload && payload.params) ? payload.params : null;
      let path = (payload && payload.path) ? payload.path : '';

      await dispatchCrudApi.readApi(params, path).then(result => {
        let data = result.data;
        
        dispatch(actions.finishRead({ data: data, errorMessage: '', isError: false }));
      }).catch(error => {
        let isError = true;
        let errorMessage = "";

        Utils.parseError(error, (result) => {
          isError = result.isError;
          errorMessage = result.errorMessage;
        });
        
        dispatch(actions.finishRead({ data: null, errorMessage: errorMessage, isError: isError }));
      });
    },

    callTableDownloadExcelApi: (payload = null) => async (dispatch) => {
      dispatch(actions.startDownloadExcel());
      
      let params = (payload && payload.params) ? payload.params : null;
      let path = (payload && payload.path) ? payload.path : '';
      let caption = (payload && payload.caption) ? payload.caption : 'List in Excel';

      await dispatchCrudApi.readApi(params, path).then(result => {
        let data = {
          caption: caption,
          base64: result.data,
          type: 'application/vnd.ms-excel',
        };
        
        dispatch(actions.finishDownloadExcel({ data: data, errorMessage: '', isError: false }));
      }).catch(error => {
        let isError = true;
        let errorMessage = "";

        Utils.parseError(error, (result) => {
          isError = result.isError;
          errorMessage = result.errorMessage;
        });
        
        if(errorMessage && errorMessage.error && errorMessage.error != '')
        Utils.toast(<div style={{ fontWeight: 'bold' }}>{(errorMessage && errorMessage.error && errorMessage.error != '') ? errorMessage.error : ''}</div>, 'error');

        dispatch(actions.finishDownloadExcel({ data: null, errorMessage: errorMessage, isError: isError }));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();