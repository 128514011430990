import React from 'react';
import CurrencyInput from 'react-currency-input-field';
import InputMask from 'react-input-mask';
import moment from 'moment';

import { 
    Row,
    Col,
    Form,
    Tab,
} from "react-bootstrap-v5";

import Utils from '../../../../utils/utils';

import RSuiteDatePicker from '../../../../components/OLD/Datepicker/RSuiteDatePicker';
import Tooltip from '../../../../components/Tooltip/Tooltip';

import { useDispatch, useSelector } from "react-redux";
import {RootState} from '../../../../../setup'
import payrollBetaLayout from '../redux/payrollBetaLayout';
import payrollBetaForm, { fields, hasSpecialDate, WorkingDayStruct, SpecialDateStruct, getDayOfWeekLabel, copyGeofence, SelectedJobTypeStruct } from '../redux/payrollBetaForm';
import payrollBetaCopyGeofence from '../redux/payrollBetaCopyGeofence';

import { useFormikContext } from '../PayrollBetaForm';

import { StyledColLeft, StyledColRight, StyledPay, StyledMenuRow, StyleMenuWrapperCol, StyledDatePicker, StyledStepHr, StyledFormLabel, StyledFormText, StyledSecondaryRadio, StyledTabs } from '../style/styles';

import CopyGeofence from './CopyGeofence';


const Geofence = (props: any) => {
    const { values, errors, setFieldValue, validateForm } = useFormikContext();

    const dispatch = useDispatch();
    const { step3bRadio } = useSelector((state: RootState) => state.payrollBetaLayout);
    const { jobTypes } = useSelector((state: RootState) => state.payrollBetaForm);

    
    const workTimeForm = (workingDays: Array<WorkingDayStruct>, i: number) => {
        return <Row>
            <Col xs={12} className={'mb-3'}>
                <Row>
                    <Col xs={2} md={2} lg={2} xl={1}>
                        <div className={'position-absolute'}><StyledFormLabel>WORK DAYS</StyledFormLabel></div>
                    </Col>
                    <Col xs={12} md={10} lg={10} xl={11}>
                        <Row className={'align-items-center mb-2'}>
                            <Col xs={12} md={6}>&nbsp;</Col>
                            <Col xs={12} md={6} className={'d-none d-md-block'}><StyledPay>Pay</StyledPay></Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col xs={12}>
                {(workingDays && workingDays.length > 0) && <>
                    {workingDays.map((item: any, j: number) => {
                        if(item.isEnabled){
                            return <Row key={'workTimes_' + i + '_' + j} className={'mb-3'}>
                                <Col xs={2} md={2} lg={2} xl={1} className={'mt-0 mt-md-3 mb-2 mb-md-0'}><StyledFormText>{getDayOfWeekLabel(item.day)}</StyledFormText></Col>
                                <Col xs={12} md={10} lg={10} xl={11}>
                                    {item.times.map((subItem: any, k: number) => {
                                        return <Row key={'work_times_' + i + '_'  + j + '_day_' + k} className={'align-items-center mb-2'}>
                                            <Col xs={12} md={6} className={'mb-3 mb-md-0'}>
                                                <Row className={'align-items-center'}>
                                                    <Col xs={12} sm={true} className={'custom-rsuite-time-picker-component'}>
                                                        <Form.Control
                                                            as = {InputMask}
                                                            mask={'99:99'}
                                                            size={'sm'}
                                                            readOnly={true}
                                                            tabIndex={-1}

                                                            id={fields.workTimes.id + '_' + i + '_' + j + '_' + fields.workTimesTimeFrom.id + '_' + k + '_timeFrom'}
                                                            isInvalid={!!(errors.selectedGeofence && errors.selectedGeofence[i] && errors.selectedGeofence[i].workingDays && errors.selectedGeofence[i].workingDays[j] && errors.selectedGeofence[i].workingDays[j].times && errors.selectedGeofence[i].workingDays[j].times[k] && errors.selectedGeofence[i].workingDays[j].times[k].timeFrom)}
                            
                                                            placeholder={fields.workTimesTimeFrom.placeholder}
                                                            value={subItem.timeFrom}
                                                            onChange={(e: any) => {
                                                                setFieldValue('selectedGeofence.' + i + '.workingDays.' + j + '.times.' + k + '.timeFrom', e.target.value);
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col xs={'auto'} className={'d-none d-lg-block px-1'}><StyledFormText>TO</StyledFormText></Col>
                                                    <Col xs={12} sm={true} className={'custom-rsuite-time-picker-component'}>
                                                        <Form.Control
                                                            as = {InputMask}
                                                            mask={'99:99'}
                                                            size={'sm'}
                                                            readOnly={true}
                                                            tabIndex={-1}

                                                            id={fields.workTimes.id + '_' + i + '_' + j + '_' + fields.workTimesTimeFrom.id + '_' + k + '_timeTo'}
                                                            isInvalid={!!(errors.selectedGeofence && errors.selectedGeofence[i] && errors.selectedGeofence[i].workingDays && errors.selectedGeofence[i].workingDays[j] && errors.selectedGeofence[i].workingDays[j].times && errors.selectedGeofence[i].workingDays[j].times[k] && errors.selectedGeofence[i].workingDays[j].times[k].timeTo)}
                            
                                                            placeholder={fields.workTimesTimeTo.placeholder}
                                                            value={subItem.timeTo}
                                                            onChange={(e: any) => {
                                                                setFieldValue('selectedGeofence.' + i + '.workingDays.' + j + '.times.' + k + '.timeTo', e.target.value);
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <StyledFormLabel className={'d-block d-md-none'}><StyledPay>{fields.workTimesPay.label}</StyledPay></StyledFormLabel>
                                                <Form.Control
                                                    as = {CurrencyInput}
                                                    allowDecimals={true}
                                                    allowNegativeValue={false}
                                                    disableGroupSeparators={true}
                                                    prefix={Utils.getCurrency()}
                                                    size={'sm'}
                                                    tabIndex={i*j*k}
                                                    style={{ borderColor: (errors.selectedGeofence && errors.selectedGeofence[i] && errors.selectedGeofence[i].workingDays && errors.selectedGeofence[i].workingDays[j] && errors.selectedGeofence[i].workingDays[j].times && errors.selectedGeofence[i].workingDays[j].times[k] && errors.selectedGeofence[i].workingDays[j].times[k].standardPay) ? '#FF0090' : '#0066FF' }}

                                                    id={fields.workTimes.id + '_' + i + '_' + j + '_' + fields.workTimesTimeFrom.id + '_' + k + '_pay'}
                                                    isInvalid={!!(errors.selectedGeofence && errors.selectedGeofence[i] && errors.selectedGeofence[i].workingDays && errors.selectedGeofence[i].workingDays[j] && errors.selectedGeofence[i].workingDays[j].times && errors.selectedGeofence[i].workingDays[j].times[k] && errors.selectedGeofence[i].workingDays[j].times[k].standardPay)}
                    
                                                    placeholder={fields.workTimesPay.placeholder}
                                                    value={subItem.standardPay}
                                                    onValueChange={(value: any) => {
                                                        setFieldValue('selectedGeofence.' + i + '.workingDays.' + j + '.times.' + k + '.standardPay', value);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    })}
                                </Col>
                            </Row>
                        }
                    })}
                </>}
            </Col>
        </Row>
    }

    const specialDatesForm = (specialDays: Array<SpecialDateStruct>, i: number) => {
        return <Row>
            <Col xs={12} className={'mb-3'}>
                <Row>
                    <Col xs={12} md={4} lg={4} xl={4}>
                        <div className={'position-absolute'}><StyledFormLabel>SPECIAL DATES</StyledFormLabel></div>
                    </Col>
                    <Col xs={12} md={8} lg={8} xl={8}>
                        <Row className={'align-items-center mb-2'}>
                            <Col xs={12} md={8}>&nbsp;</Col>
                            <Col xs={12} md={4} className={'d-none d-md-block'}><StyledPay>Pay</StyledPay></Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col xs={12}>
                {(specialDays && specialDays.length > 0) && <>
                    {specialDays.map((item: any, j: number) => {
                        return <Row key={'specialDays_' + i + '_' + j} className={'mb-3'}>
                            <Col xs={12} md={4} lg={4} xl={4}>
                                <StyledDatePicker
                                    as = {RSuiteDatePicker}
                                    isInvalid={!!(errors.specialDays && errors.specialDays.length > 0 && errors.specialDays[i] && errors.specialDays[i].date)}
                                    className={'form-control'}
                                    tabIndex={1000+i}
                                    
                                    placeholder={fields.specialDatesDate.placeholder}
                                    id={fields.specialDates.id + '_' + i + '_date'}
                                    value={item.date}
                                    icon={<></>}
                                    placement={'bottomStart'}
                                    cleanable={true}
                                    isDefault={true}
                                    readOnly={true}
                                    oneTap={true}
                                    // disabledDate={(date: any) => {
                                    //     return (moment(date).isAfter(moment().subtract(1, 'days'))) ? false : true;
                                    // }}
                                    onChange={(value: any) => {
                                        setFieldValue('selectedGeofence.' + i + '.specialDays.' + j + '.date', value);
                                    }}
                                />
                            </Col>
                            <Col xs={12} md={8} lg={8} xl={8}>
                                {item.times.map((subItem: any, k: number) => {
                                    return <Row key={'work_times_' + i + '_'  + j + '_day_' + k} className={'align-items-center mb-2'}>
                                        <Col xs={12} md={8} className={'mb-3 mb-md-0'}>
                                            <Row className={'align-items-center'}>
                                                <Col xs={12} sm={true} className={'custom-rsuite-time-picker-component'}>
                                                    <Form.Control
                                                        as = {InputMask}
                                                        mask={'99:99'}
                                                        size={'sm'}
                                                        readOnly={true}
                                                        tabIndex={-1}

                                                        id={fields.workTimes.id + '_' + i + '_' + j + '_' + fields.workTimesTimeFrom.id + '_' + k + '_timeFrom'}
                                                        isInvalid={!!(errors.selectedGeofence && errors.selectedGeofence[i] && errors.selectedGeofence[i].specialDays && errors.selectedGeofence[i].specialDays[j] && errors.selectedGeofence[i].specialDays[j].times && errors.selectedGeofence[i].specialDays[j].times[k] && errors.selectedGeofence[i].specialDays[j].times[k].timeFrom)}
                        
                                                        placeholder={fields.workTimesTimeFrom.placeholder}
                                                        value={subItem.timeFrom}
                                                        onChange={(e: any) => {
                                                            setFieldValue('selectedGeofence.' + i + '.specialDays.' + j + '.times.' + k + '.timeFrom', e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                                <Col xs={'auto'} className={'d-none d-lg-block px-1'}><StyledFormText>TO</StyledFormText></Col>
                                                <Col xs={12} sm={true} className={'custom-rsuite-time-picker-component'}>
                                                    <Form.Control
                                                        as = {InputMask}
                                                        mask={'99:99'}
                                                        size={'sm'}
                                                        readOnly={true}
                                                        tabIndex={-1}

                                                        id={fields.workTimes.id + '_' + i + '_' + j + '_' + fields.workTimesTimeFrom.id + '_' + k + '_timeTo'}
                                                        isInvalid={!!(errors.selectedGeofence && errors.selectedGeofence[i] && errors.selectedGeofence[i].specialDays && errors.selectedGeofence[i].specialDays[j] && errors.selectedGeofence[i].specialDays[j].times && errors.selectedGeofence[i].specialDays[j].times[k] && errors.selectedGeofence[i].specialDays[j].times[k].timeTo)}
                        
                                                        placeholder={fields.workTimesTimeTo.placeholder}
                                                        value={subItem.timeTo}
                                                        onChange={(e: any) => {
                                                            setFieldValue('selectedGeofence.' + i + '.specialDays.' + j + '.times.' + k + '.timeTo', e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <StyledFormLabel className={'d-block d-md-none'}><StyledPay>{fields.workTimesPay.label}</StyledPay></StyledFormLabel>
                                            <Form.Control
                                                as = {CurrencyInput}
                                                allowDecimals={true}
                                                allowNegativeValue={false}
                                                disableGroupSeparators={true}
                                                prefix={Utils.getCurrency()}
                                                size={'sm'}
                                                tabIndex={i*j*k}
                                                style={{ borderColor: (errors.selectedGeofence && errors.selectedGeofence[i] && errors.selectedGeofence[i].specialDays && errors.selectedGeofence[i].specialDays[j] && errors.selectedGeofence[i].specialDays[j].times && errors.selectedGeofence[i].specialDays[j].times[k] && errors.selectedGeofence[i].specialDays[j].times[k].standardPay) ? '#FF0090' : '#0066FF' }}

                                                id={fields.workTimes.id + '_' + i + '_' + j + '_' + fields.workTimesTimeFrom.id + '_' + k + '_pay'}
                                                isInvalid={!!(errors.selectedGeofence && errors.selectedGeofence[i] && errors.selectedGeofence[i].specialDays && errors.selectedGeofence[i].specialDays[j] && errors.selectedGeofence[i].specialDays[j].times && errors.selectedGeofence[i].specialDays[j].times[k] && errors.selectedGeofence[i].specialDays[j].times[k].standardPay)}
                
                                                placeholder={fields.workTimesPay.placeholder}
                                                value={subItem.standardPay}
                                                onValueChange={(value: any) => {
                                                    setFieldValue('selectedGeofence.' + i + '.specialDays.' + j + '.times.' + k + '.standardPay', value);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                })}
                            </Col>
                        </Row>
                    })}
                </>}
            </Col>
        </Row>
    }


    const geofenceSection = () => {
        const indexesArray = (errors && errors.selectedGeofence && errors.selectedGeofence.length > 0) ? errors.selectedGeofence.map((obj: any, index: number) => obj ? index : undefined).filter((index: number) => index !== undefined) : [];
        
        return <StyledTabs err={indexesArray}>
            {values.selectedGeofence.map((item: any, i: number) => {
                return <Tab
                    key={'geofenceGroup_' + item.accountMapGeofenceGroupId} 
                    eventKey={item.accountMapGeofenceGroupId}
                    title={<Row>
                        <Col xs={'auto'}>{item.groupName}</Col>
                        <Col xs={'auto'}>
                            <Tooltip id={'row2-' + item.jobTemplateId} title={'Copy'}>
                                <a href={'/'} tabIndex={-1}>
                                    <i className={'fa fa-clone'}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            
                                            dispatch(payrollBetaCopyGeofence.show({ accountMapGeofenceGroupId: item.accountMapGeofenceGroupId, geofence: values.selectedGeofence, geofenceIndex: i }));
                                        }}
                                    ></i>
                                </a>
                            </Tooltip>
                        </Col>
                    </Row>}
                >
                    <Row className={'mt-10'}>
                        <Col xs={12} xl={{ span: 10, offset: 1 }}>
                            <Row className={'justify-content-center'} style={{ border: '1px solid #ececec', borderBottom: '0px' }}>
                                <Col xs={12} lg={6} xl={6} className={'py-5 ps-5'}><h3 className={'m-0'}>Work Time</h3></Col>
                                <Col xs={12} lg={6} xl={6}></Col>
                            </Row>
                            <Row className={'justify-content-center'} style={{ border: '1px solid #ececec' }}>
                                <StyledColLeft xs={12} lg={6} xl={6} hasspecialdate={values.hasSpecialDates}>{workTimeForm(item.workingDays, i)}</StyledColLeft>
                                {values.hasSpecialDates && <StyledColRight xs={12} lg={6} xl={6}>{specialDatesForm(item.specialDays, i)}</StyledColRight>}
                            </Row>
                        </Col>
                    </Row>
                </Tab>
            })}
            <Col xs={12} className={'m-0 p-0'}><StyledStepHr /></Col>
        </StyledTabs>
    }


    return <Row>
        <Col xs={12}>
            {geofenceSection()}
            
            <CopyGeofence
                onSave={(accountMapGeofenceGroupId: number|null, checkedGeofence: Array<number>, geofenceIndex: number) => {
                    if(values.selectedGeofence && values.selectedGeofence.length > 0 && values.selectedGeofence[geofenceIndex]){
                        let item = values.selectedGeofence.find((x: any) => x.accountMapGeofenceGroupId == accountMapGeofenceGroupId);
                        if(item){
                            let arr = copyGeofence(item, values.selectedGeofence, checkedGeofence);
                            setFieldValue('selectedGeofence', arr);
                        }
                    }
                }}
            />
        </Col>
    </Row>
}


export default Geofence;
