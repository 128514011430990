import React from "react";

import _ from 'lodash';

import { Drawer, Divider } from '@material-ui/core';

import {
    Row,
    Col,
    Form,
    InputGroup,
    DropdownButton,
    Dropdown,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import { Formik } from 'formik';
import * as yup from 'yup';

import Utils from "../../../utils/utils";
import { BillingTypes } from "../../../utils/enums";

import InputLayout from "../../../components/input/InputLayout";

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut, dispatchApiCallDelete } from '../../../../setup/redux/dispatch/actions'


const StyledDropdownButton = styled(DropdownButton)`
    button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        color: #181C32 !important;
        background-color: #F5F8FA !important;
        border: 1px solid #E4E6EF !important;

        &:after {
            color: #181C32 !important;
        }

        &:hover {
            background-color: #f5f5f5 !important;
            color: #181C32 !important;
        }
    }
`;
const StyledAddButton = styled.a`
    border: 1px solid #DFDFDF;
    border-radius: 20px;
    padding: 7px 15px;
    color: #444444 !important;
    font-weight: 500;

    &:hover {
        color: #8F8F8F !important;
    }
`;
const StyledFormCheck = styled(Form.Check)`
    position: relative;
    min-height: 60px;
    display: flex;
    align-items: center;

    &:hover {
        background-color: #ECF5DF;
    }

    .form-check-input[type=checkbox] {
        margin-left: 10px;
        border-radius: 0px;
        border: 0px;
        cursor: pointer;
        background-size: contain;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15'%3e%3cg id='Icon_ionic-ios-checkbox-outline' data-name='Icon ionic-ios-checkbox-outline' transform='translate(-2.5 -2.5)'%3e%3cpath id='Path_24397' data-name='Path 24397' d='M16.25,2.5H3.75A1.249,1.249,0,0,0,2.5,3.75v12.5A1.249,1.249,0,0,0,3.75,17.5h12.5a1.249,1.249,0,0,0,1.25-1.25V3.75A1.249,1.249,0,0,0,16.25,2.5Zm.156,13.594a.313.313,0,0,1-.312.313H3.906a.313.313,0,0,1-.312-.312V3.906a.313.313,0,0,1,.313-.312H16.094a.313.313,0,0,1,.313.313Z' fill='%23dfdfdf'/%3e%3cpath id='Path_24398' data-name='Path 24398' d='M14.2,7.535l-.687-.707a.148.148,0,0,0-.109-.047h0a.142.142,0,0,0-.109.047l-4.766,4.8L6.8,9.895a.151.151,0,0,0-.219,0l-.7.7a.156.156,0,0,0,0,.223L8.07,13a.692.692,0,0,0,.457.223.725.725,0,0,0,.453-.215h0l5.223-5.25A.167.167,0,0,0,14.2,7.535Z' fill='%23dfdfdf'/%3e%3c/g%3e%3c/svg%3e");
        
        &:checked{
            background-color: transparent;
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15'%3e%3cg id='Icon_ionic-ios-checkbox-outline' data-name='Icon ionic-ios-checkbox-outline' transform='translate(-2.5 -2.5)'%3e%3cpath id='Path_24397' data-name='Path 24397' d='M16.25,2.5H3.75A1.249,1.249,0,0,0,2.5,3.75v12.5A1.249,1.249,0,0,0,3.75,17.5h12.5a1.249,1.249,0,0,0,1.25-1.25V3.75A1.249,1.249,0,0,0,16.25,2.5Zm.156,13.594a.313.313,0,0,1-.312.313H3.906a.313.313,0,0,1-.312-.312V3.906a.313.313,0,0,1,.313-.312H16.094a.313.313,0,0,1,.313.313Z' fill='%23185cff'/%3e%3cpath id='Path_24398' data-name='Path 24398' d='M14.2,7.535l-.687-.707a.148.148,0,0,0-.109-.047h0a.142.142,0,0,0-.109.047l-4.766,4.8L6.8,9.895a.151.151,0,0,0-.219,0l-.7.7a.156.156,0,0,0,0,.223L8.07,13a.692.692,0,0,0,.457.223.725.725,0,0,0,.453-.215h0l5.223-5.25A.167.167,0,0,0,14.2,7.535Z' fill='%23185cff'/%3e%3c/g%3e%3c/svg%3e");
        }
    }

    label {
        position: relative;
        left: 10px;
        width: 100%;
        height: 100%;
        padding: 5px 10px 5px 0px;
        cursor: pointer;
        
        display: flex;
        align-items: center;
        
        width: 100%;
    }

    .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
        opacity: 1;
    }

    ${(props) => {
        return props.active ? 'background-color: #ECF5DF;' : '';
    }}
`;


const formFields = {
    chargeCategoryName: {
        id: 'chargeCategoryName',
        label: 'Name',
        placeholder: ' ',
    },
    billingType: {
        id: 'billingType',
        label: 'Billing Type',
        placeholder: ' ',
    },
    useBinWeightQuantity: {
        id: 'useBinWeightQuantity',
        label: 'Link Billable Weight To Service Item Qty',
        placeholder: ' ',
    },
}
const formSchema = yup.object().shape({
    isEdit: yup.bool().oneOf([true, false]),
    //chargeCategoryId: yup.number().nullable().label(formFields.chargeCategoryId.label),
    chargeCategoryName: yup.string().required().label(formFields.chargeCategoryName.label),
    billingType: yup.number().nullable().min(0).label(formFields.billingType.label),
    useBinWeightQuantity: yup.bool().oneOf([true, false]).label(formFields.useBinWeightQuantity.label),
});


class BillingCategoryForm extends React.Component {

    constructor(props) {
        super(props);

        this.pageName = 'billing_category_form';
        this.backLink = '/billing/category';

        this.refForm = React.createRef();
        this.refInput = React.createRef();

        let id = Utils.getIntProps(props);
        let isCopy = Utils.getBoolProps(props);
        let newItem = Utils.getStringProps(props, 'newItem');

        this.panelWidth = '320px';

        this.state = {
            id: id,
            isCopy: isCopy,
            details: null,

            showSidePanel: false,

            initialValues: {
                isEdit: false,
                chargeCategoryName: (!id && newItem != '') ? newItem : '',
                billingType: BillingTypes.Invoice,
                useBinWeightQuantity: false,
            },
        };
    }


    componentDidMount() {
        this.setLayoutForm();

        this.callDetailsApi(this.state.id, (details) => {
            if (details) {
                this.setLayoutTitle(this.state.isCopy ? 'Copy Billing Category' : 'Edit Billing Category');
                this.prepareForm(details);
                this.setLoading(false);
            } else {
                this.setLayoutTitle('Add Billing Category');
            }
        });
    }

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            this.pageName + '-details',
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if (callback) {
                        callback(data);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-create',
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if (callback) {
                        callback(data, isError);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-update',
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if (callback) {
                        callback(data, isError);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-delete',
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if (callback) {
                        callback(data, isError);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-service_item_check_name',
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if (callback) {
                        callback();
                    }
                });
            }
        );
    }

    callDetailsApi = (id = null, callback = null) => {
        if (id) {
            this.setLoading(true);
            this.props.dispatchApiCallGet(null, this.pageName + '-details', 'ChargeCategory/' + id, null, callback, () => { });
        } else {
            if (callback) {
                callback(null)
            }
        }
    }

    callCreateApi = (data, callback = null) => {
        this.setLoading(true);
        this.props.dispatchApiCallPost(data, this.pageName + '-create', 'ChargeCategory', data, callback, () => { });
    }

    callUpdateApi = (data, callback = null) => {
        this.setLoading(true);
        this.props.dispatchApiCallPut(data, this.pageName + '-update', 'ChargeCategory', data, callback, () => { });
    }

    callDeleteApi = (data, callback = null) => {
        this.setLoading(true);
        this.props.dispatchApiCallDelete(data, this.pageName + '-delete', 'ChargeCategory', data, callback, () => { });
    }

    callChargeCheckNameApi = (chargeCategoryId = 0, chargeCategoryName = '', callback = null) => {
        if (chargeCategoryName !== '') {
            let data = {
                chargeCategoryId: chargeCategoryId,
                chargeCategoryName: chargeCategoryName,
            };

            this.props.dispatchApiCallPost(data, this.pageName + '-billing_category_check_name', 'ChargeCategory/check-name', null, callback, () => { });
        }
    }
    /* END API */


    /* LAYOUT FUNCTIONS */
    setLayoutForm = (options) => {
        if (this.props.layoutRef && this.props.layoutRef.current) {
            this.props.layoutRef.current.setLayoutForm(this, options);
        }
    }
    setLayoutLoading = (state = false) => {
        if (this.props.layoutRef && this.props.layoutRef.current) {
            this.props.layoutRef.current.setLayoutLoading(state);
        }
    }
    setLayoutTitle = (title = '') => {
        if (this.props.layoutRef && this.props.layoutRef.current) {
            this.props.layoutRef.current.setlayoutTitle(title);
        }
    }
    setLayoutSubmit = (form) => {
        if (this.props.layoutRef && this.props.layoutRef.current) {
            this.props.layoutRef.current.setLayoutSubmit(form);
        }
    }
    /* END LAYOUT FUNCTIONS */


    /* FUNCTIONS */
    setLoading = (state = false) => {
        this.setLayoutLoading(state);

        if (this.props.onLoading) {
            this.props.onLoading(state);
        }
    }
    prepareForm = (data) => {
        if (data) {
            var newData = _.cloneDeep(data);

            let customFormParams = this.state.initialValues.customFormParams;

            let rowValues = {
                isEdit: (this.state.isCopy) ? false : true,
                isActive: ((newData.isActive === false) || (newData.isActive === true)) ? newData.isActive : false,

                chargeCategoryName: (newData && newData.chargeCategoryName && newData.chargeCategoryName !== '') ? newData.chargeCategoryName : '',
                billingType: newData.billingType,
                useBinWeightQuantity: newData.useBinWeightQuantity,
                customFormParams: customFormParams,
            };

            this.setState({
                initialValues: rowValues,
                details: newData,
            });
        }
    }
    prepareData = (form) => {
        var newForm = _.cloneDeep(form);

        let data = {
            isActive: newForm.isActive,
            chargeCategoryName: newForm.chargeCategoryName,
            billingType: newForm.billingType,
            useBinWeightQuantity: newForm.useBinWeightQuantity,
        };

        if ((!this.state.isCopy) && this.state.id > 0) {
            data['chargeCategoryId'] = this.state.id;
        }

        return data;
    }
    setSubmit = (form) => {
        this.setLayoutSubmit(form);

        if (this.props.onSubmit) {
            this.props.onSubmit(form);
        }
    }
    /* END FUNCTIONS */


    /* FORM */
    form = (formOptions) => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = formOptions;

        return <>

            <InputLayout
                label={formFields.chargeCategoryName.label}
                required
                inputProps={{
                    xs: 12,
                    lg: 7
                }}
            >
                <Form.Control
                    type="text"
                    id={formFields.chargeCategoryName.id}
                    placeholder={formFields.chargeCategoryName.placeholder}
                    value={values.chargeCategoryName}
                    onChange={handleChange}
                    onBlur={() => {
                        this.callChargeCheckNameApi(values.serviceItemId, values.chargeCategoryName);
                    }}
                    isInvalid={!!errors.chargeCategoryName}
                />
                <Form.Control.Feedback type="invalid">{errors.chargeCategoryName}</Form.Control.Feedback>
            </InputLayout>
            <InputLayout
                label={formFields.billingType.label}
                inputProps={{
                    xs: 12,
                    lg: 7
                }}
            >
                <InputGroup>
                    <StyledDropdownButton
                        variant="outline-secondary"
                        title={(values.billingType === BillingTypes.Purchase) ? 'Purchase' : (values.billingType === BillingTypes.Expense) ? 'Expense' : 'Invoice'}
                        id={formFields.billingType.id}
                    >
                        <Dropdown.Item
                            active={(values.billingType === BillingTypes.Invoice)}
                            onClick={async () => {
                                await setFieldValue('billingType', BillingTypes.Invoice);
                            }}
                        >Invoice</Dropdown.Item>
                        <Dropdown.Item
                            active={(values.billingType === BillingTypes.Purchase)}
                            onClick={async () => {
                                await setFieldValue('billingType', BillingTypes.Purchase);
                            }}
                        >Purchase</Dropdown.Item>
                        <Dropdown.Item
                            active={(values.billingType === BillingTypes.Expense)}
                            onClick={async () => {
                                await setFieldValue('billingType', BillingTypes.Expense);
                                await setFieldValue('useBinWeightQuantity', true);
                            }}
                        >Expense</Dropdown.Item>
                    </StyledDropdownButton>
                </InputGroup>
            </InputLayout>
            <InputLayout
                label={formFields.useBinWeightQuantity.label}
                inputProps={{
                    xs: 12,
                    lg: 'auto'
                }}
            >
                {(values.billingType === BillingTypes.Expense) ? <div style={{
                        userSelect: 'none',
                        display: 'flex',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                    }}>{values.useBinWeightQuantity ? 'Yes' : 'No'}</div> : <a href={'/'}
                    className={'link-default-underline'}
                    style={{
                        userSelect: 'none',
                        display: 'flex',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                    }}
                    onClick={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        if(values.billingType === BillingTypes.Expense){
                            await setFieldValue('useBinWeightQuantity', false);
                        } else {
                            await setFieldValue('useBinWeightQuantity', !values.useBinWeightQuantity);
                        }
                    }}
                >{values.useBinWeightQuantity ? 'Yes' : 'No'}</a>}
            </InputLayout>
        </>
    }

    sidePanel = (formOptions) => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = formOptions;

        return <Drawer
            anchor={'right'}
            open={this.state.showSidePanel}
            ModalProps={{
                disablePortal: true,
            }}
            onClose={() => {
                this.setState({
                    showSidePanel: false,
                });
            }}
        >

            <Divider />
        </Drawer>
    }
    /* END FORM */


    render() {
        return <>
            <Formik
                innerRef={this.refForm}
                validationSchema={formSchema}
                initialValues={this.state.initialValues}
                enableReinitialize={true}
                validateOnMount={false}
                validateOnChange={false}
                onSubmit={(form, e) => {
                    this.setLoading(true);
                    let data = this.prepareData(form);

                    if (form.isEdit) {
                        this.callUpdateApi(data, (res, isError) => {
                            if (!isError) {
                                this.setSubmit(form);
                            } else {
                                this.setLoading(false);
                            }
                        });
                    } else {
                        this.callCreateApi(data, (res, isError) => {
                            if (!isError) {
                                this.setSubmit(form);
                            } else {
                                this.setLoading(false);
                            }
                        });
                    }
                }}
            >
                {(formOptions) => {
                    return <Form className='w-100' style={{ overflowX: 'hidden' }} noValidate autoComplete="off" onSubmit={formOptions.handleSubmit} onKeyDown={(keyEvent) => {
                        // if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                        //     keyEvent.preventDefault();
                        // }
                    }}>
                        <Row>
                            <Col xs={12} className={'ps-7 pe-7 pt-5 pb-5'} style={{ backgroundColor: '#F7F7F7' }}>
                                <Row>
                                    <Col xs={12} md={12} lg={10} xl={8} className={'ps-2 pe-2 ps-md-7 pe-md-7'}>{this.form(formOptions)}</Col>
                                </Row>
                            </Col>
                        </Row>
                        {this.sidePanel(formOptions)}
                    </Form>
                }}
            </Formik>
        </>
    }
}

const mapDispatchToProps = {
    dispatchApiCallGet,
    dispatchApiCallPost,
    dispatchApiCallPut,
    dispatchApiCallDelete,
    clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(BillingCategoryForm);
