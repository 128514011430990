import styled from 'styled-components';
import { 
    Container,
    Row,
    Col,
    Form,
    Dropdown,
    Tabs,
} from "react-bootstrap-v5";


// Layout
export const StyledFooter = styled.div`
    padding: 15px 15px 25px 15px;
    position: fixed;
    bottom: 0px;
    width: 100%;
    background-color: #EFEFEF;
    z-index: 1;

    @media (max-width: 992px) {
        position: relative;
        bottom: unset;
    }
`;
export const StyledTop = styled.div`
  background-color: white;
  padding: 5px 15px;
  border-bottom: 1px solid #E4E4E4;
`;
export const StyledPage = styled.div`
  margin: 15px;
  background-color: white;
  margin-bottom: 120px;

  @media (max-width: 992px) {
    margin-bottom: unset;
  }
`;


// Conteiner
export const StyledStepText = styled.div`
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;

    padding: 15px 30px 15px 30px;

    small {
        font-size: 13px;
        font-weight: 300;
    }
`;
export const StyledStepHr = styled.hr`
    margin: 0px;
    opacity: 1;
    border-color: #ececec;
`;
export const StyledContainer = styled(Container)`
    margin: 0 auto !important;
`;


// Step 1
export const StyledColLeft = styled(Col)`
    padding: 20px;
    border-right: 1px solid #ececec;

    ${props => {
        if(!props.hasspecialdate){
            return `
                border-right: 0px;
            `
        }
    }}
    
    @media (max-width: 992px) {
        border-right: 0px;
    }
`;
export const StyledColRight = styled(Col)`
    padding: 20px;
`;
export const StyledColInput = styled(Col)`
    max-width: 180px;

    @media (max-width: 767.98px) {
        max-width: unset;
    }
`;
export const StyledColRemove = styled(Col)`
    min-width: 30px;
`;
export const StyledDatePicker = styled(Form.Control)`
    &.form-control {
        > div {
            bacground-color: red !important;
            min-height: calc(1.5em + 1.3rem) !important;
            padding: 0.65rem 0.75rem !important;
            font-size: 0.925rem !important;
            border-radius: 0.475rem !important;
        }

        .rs-picker-date {
            font-size: 0.925rem !important;
        }
        .rs-picker-date .rs-picker-toggle-value, .rs-picker-date .rs-picker-toggle-placeholder, .rs-picker-date input {
            font-size: 0.925rem !important;
        }
        .rs-picker-date .rs-picker-toggle-clean {
            top: -1px !important;
        }

        ${props => {
            if(props.isInvalid){
                return `
                    border: 1px solid #FF0090;
                    color: #FF0090;
                `
            }
        }}
    }
`;
export const StyledLinkButton = styled.button`
  background-color: transparent;
  color: #0066FF;
  padding: 0px;
  opacity: 0.7;
  font-size: 0.925rem !important;
  font-weight: 500;
  
  &:hover {
    opacity: 1;
  }
`;
export const StyledFormLabel = styled(Form.Label)`
  font-size: 0.925rem !important;
`;
export const StyledFormText = styled.span`
  font-size: 0.925rem !important;
  user-select: none !important;
`;
export const StyledFormCheckWwitch = styled(Form.Check)`
    .form-check-input {
        height: 1.5rem !important;
        width: 3.0rem !important;
    }
`;

// Step 2
export const StyledCheck = styled(Form.Check)`
    .form-check-label {
        cursor: pointer;
    }

    &:hover {
        .form-check-label {
            i {
                opacity: 0.7;
            }
        }
    }
`;
export const StyledChecked = styled.i`
    display: grid;
    align-content: center;
    text-align: center;
    width: 22px;
    height: 22px;
    border-radius: 5px;
    background-color: #444444;
    color: white;
`;
export const StyledUnChecked = styled.i`
    display: grid;
    align-content: center;
    text-align: center;
    width: 22px;
    height: 22px;
    border-radius: 5px;
    background-color: #EAEAEA;
    color: white;
`;
export const StyledSecondaryRadio = styled(Form.Check)`
    label {
        font-size: 0.925rem !important;
    }

    .form-check-input {
        border-weight: 2px;
        width: 20px;
        height: 20px;
    }

    .form-check-input:checked {
        background-color: transparent;
        border-color: #444444;
        filter: brightness(40%);
    }
`;

// Step 3
export const StyledPay = styled.span`
    color: #0066FF;
    font-size: 0.925rem !important;
`;
export const StyledMenuRow = styled(Row)`
    min-height: 50px;
    align-items: center;
    border-bottom: 1px solid #EFEFEF;
    user-select: none;
    cursor: pointer;

    > div {
        color: #0066FF;
        font-weight: 400;
        font-size: 0.925rem;
    }

    a {
        i {
            color: #CCCCCC;
            font-size: 15px;
        }

        &:hover {
            i {
                color: #C1C1C1;
            }
        }
    }

    &:hover {
        background-color: #f9f9f9;
    }

    
    ${props => {
        if(props.active){
            return `
                background-color: #ECF5DF;
                
                > div {
                    color: #0066FF;
                }

                a {
                    i {
                        color: #CCCCCC;
                    }
                }

                &:hover {
                    background-color: #e6f5d0;
                }
            `
        }
    }}

    ${props => {
        if(props.error){
            return `
                border: 1px solid #FF0090;
                
                > div {
                    color: #FF0090;
                }
            `
        }
    }}
    
    ${props => {
        if(props.filled){
            return `
                > div {
                    color: #444444 !important;
                    font-weight: bold !important;
                }
            `
        }
    }}
`;
export const StyleMenuWrapperCol = styled(Col)`
    max-width: 300px;
    min-height: 100%;
    overflow: auto;

    border-right: 1px solid #ececec;

    @media (max-width: 992px) {
        max-width: 100%;
        border-right: 0px;
    }
`;
export const StyledTabs = styled(Tabs)`
    .nav-item {
        border: 1px solid #EFEFEF;
        border-radius: 0px;
        font-weight: 600;
        font-size: 0.925rem !important;
        color: #444444;

        a {
            i {
                color: #CCCCCC;
                font-size: 15px;
            }
    
            &:hover {
                i {
                    color: #C1C1C1;
                }
            }
        }
        
        &:hover {
            background-color: #f9f9f9 !important;
        }

        &.active {
            background-color: #ECF5DF;

            &:hover {
                background-color: #ECF5DF !important;
            }
        }
    }

    ${props => {
        if(props.err && props.err.length > 0){
            return props.err.map(x => {
                return `
                    .nav-item:nth-child(${(x+1)}) {
                        border: 1px solid #FF0090;
                        color: #FF0090;
                    }
                `
            });
        }
    }}
`;

// Step 4
export const StyledDropdown = styled(Dropdown)`
    .custom-graduatedWage-menu {
        width: 100%;
    }

    &.show > .btn.btn-primary {
        background-color: white !important;
        border: 1px solid #E4E6EF !important;
        color: #444444 !important;
        
        i {
            color: #444444 !important;
        }
    }
`;
export const StyledDropdownToggle = styled(Dropdown.Toggle)`
    width: 100%;
    text-align: left;

    background-color: white !important;
    border: 1px solid #E4E6EF !important;
    color: #444444 !important;

    &::after {
        display: none;
    }

    i {
        position: absolute;
        right: 15px;
        top: 15px;
        color: #444444 !important;
    }

    &.btn-check:checked + &.btn.btn-primary, &.btn-check:active + &.btn.btn-primary, &.btn.btn-primary:focus:not(.btn-active), &.btn.btn-primary:hover:not(.btn-active), &.btn.btn-primary:active:not(.btn-active), &.btn.btn-primary.active, &.btn.btn-primary.show, .show > &.btn.btn-primary {
        background-color: white !important;
        border: 1px solid #E4E6EF !important;
        color: #444444 !important;
        
        i {
            color: #444444 !important;
        }
    }
`;
export const StyledHeaderRow = styled(Row)`
    > div {
        display: flex;
        align-items: center;
        background-color: #EFEFEF;
        border-left: 1px solid #E0E0E0;
        min-height: 35px;
        padding-left: 10px;
        padding-right: 10px;

        :first-child {
            border-left: 0px;
        }

        :last-child {
            background-color: transparent;
            border-left: 1px solid #EFEFEF;
        }
    }
`;
export const StyledBodyRow = styled(Row)`
    > div {
        display: flex;
        align-items: center;
        min-height: 35px;

        :last-child {
            background-color: transparent;
            padding-left: 15px;
        }

        .form-control {
            border-radius: 0px;
            font-size: 0.925rem;
            padding: 0.75rem 1rem;
            line-height: 1.5;
            font-weight: 500;
        }

        @media (max-width: 767.98px) {
            border: 0px;
            margin-bottom: 10px;

            :last-child {
                text-align: center;
                display: block;
                padding-top: 10px;
                padding-left: 0px;
            }

            .form-control {
                border-radius: 0.475rem;
            }
        }
    }

    @media (max-width: 767.98px) {
        > div {
            display: block;
        }

        margin-bottom: 10px;
        border-bottom: 1px solid #EFEFEF;
    }
`;
export const StyledColPay = styled(Col)`
    max-width: 140px;

    @media (max-width: 767px) {
        max-width: unset !important;
    }
`;
export const StyledColPer = styled(Col)`
    max-width: 70px;

    @media (max-width: 767px) {
        max-width: unset !important;
    }
`;
export const StyledgraduatedPayText = styled(Form.Control)`
    &.form-control[readonly]::placeholder{
        color: #444444 !important;
    }
`;