import React from "react";
import queryString from "query-string";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

import Utils from "../../utils/utils";
import { BillingTypes } from "../../utils/enums";

import RowDrawerForm from "./partial/RowDrawerForm";
import IconButtonTooltip from "../../components/Buttons/IconButtonTooltip";

import { connect } from 'react-redux'
import billing from "./redux/billing";

import {KTSVG} from '../../../_metronic/helpers'

import { Form, Row, Col, Spinner } from "react-bootstrap-v5";
import { Formik } from 'formik';
import { rowFields, rowSchema, initialValues, prepareForm, prepareData, prepareDataForExpenseOUTJobType }from './functions/Fields';


class DedicatedBillingForm extends React.Component {

    constructor(props) {
        super(props);

        this.refRowForm = React.createRef();

        let q = queryString.parse(window.location.search);
        let id = q.id ? parseInt(q.id) : null;
        let billingType = q.billingType ? parseInt(q.billingType) : null;

        this.dispatchUser = (props.auth && props.auth.dispatchUser) ? props.auth.dispatchUser : null;
        
        this.state = {
          id: id,
          billingType: billingType,

          rowInitialValues: initialValues,
          rowValues: null,
          dataParams: null,

          isBillableStatus: false,
          isBlocking: true,
        };
    }


    componentDidMount() {
      this.callJobBillingDetailsApi();
    }


    redirect = (jobId = null, job = null) => {
      let data = { 
        dedicatedBillingJobForm: {
          jobId: jobId,
          job: job,
        }
      };
      this.props.history.push('/billing/' + (this.state.billingType == BillingTypes.Purchase ? 'purchase' : (this.state.billingType == BillingTypes.Expense) ? 'Expense' : 'invoice') + '-table/list-by-dates', data);
    }


    /* API */
    callJobBillingDetailsApi = async () => {
      if(this.state.id){
        await this.props.action.billing.callJobBillingDetailsApi(this.state.id);

        let dataParams = {
          id: this.state.id,
          row: this.props.billing.billingDetails.data,
        };
        let rowValues = prepareForm(this.state.rowInitialValues, dataParams, this.props.billing.billingDetails.data, this.state.billingType, this.dispatchUser.defaultUoM);
        
        this.setState({
          dataParams: dataParams,
          rowValues: rowValues,
          isBillableStatus: rowValues ? rowValues.isBillable : this.state.isBillable,
          isBlocking: false,
        });
      } else {
        this.redirect();
      }
    }
    callSaveJobBillingApi = async (data = null) => {
      await this.props.action.billing.callSaveJobBillingApi(data);
    }
    /* END API */


    title = (formOptions) => {
      return <Row className={'align-items-center'}>
        <Col xs={'auto'}>
          <div className={'d-flex align-items-center'}>
            {(this.state.dataParams && this.state.dataParams.row && this.state.dataParams.row.customerName) && <span className={'pe-2'}>{this.state.dataParams.row.customerName}</span>}
          </div>
          <div className={'d-flex align-items-center'}>
            {(this.state.dataParams && this.state.dataParams.row && this.state.dataParams.row.jobNumber) && <small>
              <a href={'/jobs-form?id=' + this.state.id}
                target={'_blank'}
                rel="noreferrer"
                className={'d-flex align-items-center'}
              >
                {(this.state.dataParams.row.jobNumber) ? this.state.dataParams.row.jobNumber : ''}
                <i className={'material-icons text-primary ps-1'} style={{ fontSize: '19px' }}>launch</i>
              </a>
            </small>}
          </div>
        </Col>
        <Col xs={'auto'}>
          {this.state.isBillableStatus && <IconButtonTooltip
            title={'Update billing'}
            placement={'bottom'}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              if(this.refRowForm && this.refRowForm.current){
                this.refRowForm.current.updateBilling();
              }
            }}
          >
            <i className={'material-icons'}>refresh</i>
          </IconButtonTooltip>}
        </Col>
      </Row>
    }

    form = (formOptions) => {
      return <RowDrawerForm
        ref={this.refRowForm}
        isDedicatedForm={true}
        drawer={this.rowDrawer}
        fields={rowFields}
        initialValues={this.state.rowInitialValues}
        values={this.state.rowValues}
        formOptions={formOptions}
        dataParams={this.state.dataParams}
        isBlocking={this.state.isBlocking}
        billingType={this.state.billingType}
        onChangeStatus={async (isBillable) => {
          this.setState({
            isBillableStatus: isBillable,
          });
        }}
        setIsBlocking={async (isBlocking) => {
          this.setState({
            isBlocking: isBlocking,
          });
        }}
        onCancel={() => {
          this.redirect();
        }}
      />
    }


    render() {
      return <Formik
        validationSchema={rowSchema}
        initialValues={this.state.rowValues ? this.state.rowValues : this.state.rowInitialValues}
        validateOnMount={true}
        enableReinitialize={true}
        onSubmit={(form, e) => {
          this.setState({
            isBlocking: true,
          }, async () => {
            let data = null;
            if(this.state.billingType == BillingTypes.Expense){
              data = prepareDataForExpenseOUTJobType(form, this.state.dataParams);
            } else {
              data = prepareData(form, this.state.dataParams);
            }
            await this.callSaveJobBillingApi(data);
  
            if(this.props.billing.billingSave.isError){
              this.setState({
                isBlocking: false,
              });
            } else {
              Utils.toast("You have successfully changed the data on the billing form", 'success'); 
  
              let job = (this.props.billing && this.props.billing.billingSave && this.props.billing.billingSave.job) ? this.props.billing.billingSave.job : null;
              this.redirect(this.state.id, job);
            }
          });
        }}
    >
        {(formOptions) => {
            return <Form className='w-100' noValidate autoComplete="off" onSubmit={formOptions.handleSubmit} onKeyDown={(keyEvent) => {
                // if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                //     keyEvent.preventDefault();
                // }
            }}>
                <div className='card w-100 rounded-0'>
                    <div className='card-header ps-4 pe-4'>
                        <div className='card-title'>
                            <div className='d-flex justify-content-center flex-column me-3'>{this.title(formOptions)}</div>
                        </div>

                        <div className='card-toolbar'>
                            <div className='btn btn-sm btn-icon btn-active-light-primary'
                              onClick={() => {
                                this.redirect();
                              }}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
                            </div>
                        </div>
                    </div>

                    <BlockUi 
                      className={'custom-block-ui'} 
                      tag={'div'} 
                      keepInView 
                      blocking={this.state.isBlocking}
                      renderChildren={true} 
                      loader={<Spinner animation="border" />}
                    >
                      {this.form(formOptions)}
                    </BlockUi>
                </div>
            </Form>
        }}
      </Formik>
    }
}

const mapStateToProps = state => {
  return {
    ...state,
    ...Utils.mapStateToProps(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  action: {
    billing: {
      callJobBillingDetailsApi: (payload) => dispatch(billing.callJobBillingDetailsApi(payload)),
      callSaveJobBillingApi: (payload) => dispatch(billing.callSaveJobBillingApi(payload)),
    },
  }
});


export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DedicatedBillingForm);
