import React, { Component } from 'react';
import ReactDOM from 'react-dom'

import {
    Box,
} from '@material-ui/core';

import Utils from '../../../utils/utils';

import { InputPicker } from 'rsuite';
import "rsuite/dist/rsuite.min.css";


class RSuiteInputPicker extends Component {
  
	constructor(props){
		super(props);
        
        this.inputRef = React.createRef();

        let menuClassName = props.menuClassName ? props.menuClassName : '';
        let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);
        let disabled = ((props.disabled === true || props.disabled === false) ? props.disabled : false);
        let shouldClose = ((props.shouldClose === true || props.shouldClose === false) ? props.shouldClose : true);
        let searchable = ((props.searchable === true || props.searchable === false) ? props.searchable : true);
        let cleanable = ((props.cleanable === true || props.cleanable === false) ? props.cleanable : true);
        let readOnly = ((props.readOnly === true || props.readOnly === false) ? props.readOnly : false);
        let virtualized = ((props.virtualized === true || props.virtualized === false) ? props.virtualized : false);
        let escapeCharsInSearch = ((props.escapeCharsInSearch === true || props.escapeCharsInSearch === false) ? props.escapeCharsInSearch : false);
        let isDefault = ((props.isDefault === true || props.isDefault === false) ? props.isDefault : false);
        let items = props.items ? props.items : [];
        let value = props.value;
        let selectedName = props.selectedName;
        let id = props.id;
        let icon = props.icon ? props.icon : null;
        let iconPosition = props.iconPosition ? props.iconPosition : 'left';
        let placeholder = props.placeholder ? props.placeholder : '';
        let placement = props.placement ? props.placement : 'bottomStart';
        let labelKey = props.labelKey ? props.labelKey : 'title';
        let valueKey = props.valueKey ? props.valueKey : 'value';

		this.state = {
            menuClassName: menuClassName,
            items: items,
            value: value,
            selectedName: selectedName,
            icon: icon,
            id: id,
            iconPosition: iconPosition,
            placeholder: placeholder,
            labelKey: labelKey,
            valueKey: valueKey,
            isLoading: isLoading,
            disabled: disabled,
            shouldClose: shouldClose,
            searchable: searchable,
            cleanable: cleanable,
            placement: placement,
            readOnly: readOnly,
            virtualized: virtualized,
            escapeCharsInSearch: escapeCharsInSearch,
            isDefault: isDefault,
            isOpen: false,
            isMenuOpen: false,
		}
	}
    

    componentDidMount(){
        document.body.addEventListener('click', this.onOutsiteClickListener, false);

        if(this.inputRef && this.inputRef.current && this.inputRef.current.root){
            let els = ReactDOM.findDOMNode(this.inputRef.current.root).getElementsByClassName('rs-picker-search-input')
            if(els && els.length > 0){
                if(els[0].localName == 'input'){
                    if(this.props.searchInput){
                        this.props.searchInput(els[0]);
                    }
                }
            }
        }

        if(this.props.onInit){
            this.props.onInit();
        }
    }
    
    componentWillUnmount(){
        document.body.removeEventListener('click', this.onOutsiteClickListener, false);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.items !== this.state.items) {
            let items = nextProps.items ? nextProps.items : [];
			this.setState({
				items: items
			});
        }
        
        if (nextProps.value !== this.state.value) {
			this.setState({
				value: nextProps.value
			});
        }
        
        if (nextProps.selectedName !== this.state.selectedName) {
			this.setState({
				selectedName: nextProps.selectedName
			});
        }
        
        if (nextProps.placeholder !== this.state.placeholder) {
			this.setState({
				placeholder: nextProps.placeholder
			});
        }
        
        if (nextProps.shouldClose !== this.state.shouldClose) {
            let shouldClose = ((nextProps.shouldClose === true || nextProps.shouldClose === false) ? nextProps.shouldClose : true);
			this.setState({
				shouldClose: shouldClose
			});
		}

        if (nextProps.isLoading !== this.state.isLoading) {
            let isLoading = ((nextProps.isLoading === true || nextProps.isLoading === false) ? nextProps.isLoading : false);
			this.setState({
				isLoading: isLoading
			});
		}

        if (nextProps.readOnly !== this.state.readOnly) {
            let readOnly = ((nextProps.readOnly === true || nextProps.readOnly === false) ? nextProps.readOnly : false);
			this.setState({
				readOnly: readOnly
			});
		}

        if (nextProps.disabled !== this.state.disabled) {
            let disabled = ((nextProps.disabled === true || nextProps.disabled === false) ? nextProps.disabled : false);
			this.setState({
				disabled: disabled
			});
		}
        
        if (nextProps.virtualized !== this.state.virtualized) {
            let virtualized = ((nextProps.virtualized === true || nextProps.virtualized === false) ? nextProps.virtualized : false);
			this.setState({
				virtualized: virtualized
			});
		}
    }


    onOutsiteClickListener = (e) => {
        try {
            if(this.state.isOpen && this.state.shouldClose && this.state.isMenuOpen){
                var path = e.path || (e.composedPath && e.composedPath());

                if(path && path.length > 0){
                    let state = false;

                    for(let i = 0; i < path.length; i++){
                        let cls = (path[i] && path[i].className && path[i].className !== '') ? path[i].className : '';
                        if(this.state.id){
                            if((cls.includes(this.state.id)) || cls.includes("rs-picker-select-menu")){
                                state = true;
                                break;
                            }
                        } else {
                            if(cls.includes("rs-picker-select-menu") || cls.includes("rs-btn-default")){
                                state = true;
                                break;
                            }
                        }
                    }

                    if(!state){
                        this.handleClose();
                    } else {
                        return;
                    }
                } else {
                    return;
                }
            } else {
                return;
            }
        } catch(err) {
            return;
        }
    }


    handleClick = () => {
        if(this.state.disabled || this.state.readOnly){

        } else {
            this.setState({
                isOpen: true,
            });

            if(this.props.onClick){
                this.props.onClick();
            }
        }
    }
    handleOpen = () => {
        this.setState({
            isMenuOpen: true,
        }, () => {
            if(this.state.searchable){
                try {
                    let inputElement = document.getElementsByClassName('rs-picker-search-bar-input')[0];
                    inputElement.focus();

                    if(this.state.escapeCharsInSearch){
                        inputElement.addEventListener("keypress", Utils.escapeChars);
                    }
                } catch(e){}
            }
    
            if(this.props.onOpen){
                this.props.onOpen();
            }
        });
    }

    handleClose = () => {
        if(this.state.shouldClose){
            this.setState({
                isMenuOpen: false,
                isOpen: false,
            }, () => {
                if(this.state.escapeCharsInSearch){
                    if(this.state.searchable){
                        try {
                            let inputElement = document.getElementsByClassName('rs-picker-search-bar-input')[0];
                            inputElement.removeEventListener("keypress", Utils.escapeChars);
                        } catch(e){}
                    }
                }
            });
        }
    }

    handleSearch = (value, e) => {
        if(this.props.onSearch){
            this.props.onSearch(value);
        }
    }
    
    handleSelect = (value, item, e) => {
        this.setState({
            value,
        }, () => {
            this.handleClose();

            if(this.props.onSelect){
                this.props.onSelect(value, item);
            }
        });
    }

    handleClean = (e) => {
        this.setState({
            value: '',
        }, () => {
            this.handleClose();

            if(this.props.onClear){
                this.props.onClear();
            }
        });
    }
    
    handleSearchBy = (keyword, label, item) => {
        if(keyword == ''){
            return true;
        } else {
            return label.toLowerCase().includes(keyword.toLowerCase())
        }
    }
    

	render() {
		return <Box className={'custom-rsuite-input-picker ' + (this.state.isDefault ? 'default-look ' : '') + this.props.className +  (this.state.icon ? " has-icon" : "") + (this.state.iconPosition ? " icon-" + this.state.iconPosition : "") + (this.state.readOnly ? ' read-only' : '') + (this.state.isMenuOpen ? ' menu-is-open' : '') + (this.state.id ? ' ' + this.state.id : '')}>
            {this.state.icon}
            <InputPicker
                ref={this.inputRef}
                menuClassName={this.state.menuClassName}
                data={this.state.isLoading ? [] : this.state.items}
                placeholder={this.state.isLoading ? this.state.selectedName ? this.state.selectedName : this.state.placeholder : this.state.placeholder}
                value={this.state.value}
                disabled={this.state.disabled}
                labelKey={this.state.labelKey}
                valueKey={this.state.valueKey}
                open={this.state.isOpen}
                searchable={this.state.searchable}
                cleanable={this.state.cleanable}
                placement={this.state.placement}
                virtualized={this.state.virtualized}
                readOnly={this.state.readOnly}
                block={true}
                preventOverflow={true}
                onClick={this.handleClick}
                onOpen={this.handleOpen}
                onClose={this.handleClose}
                onSearch={this.handleSearch}
                onSelect={this.handleSelect}
                onClean={this.handleClean}
                searchBy={this.handleSearchBy}
                renderValue={this.props.renderValue}
                locale={this.props.locale}
                disabledItemValues={this.props.disabledItemValues}
                renderMenu={menu => {
                    return <>
                        {
                            (this.state.isLoading)
                            ?
                            <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>loading...</p>
                            :
                                (this.state.items.length === 0)
                                ?
                                <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>No Result!</p>
                                :
                                    this.props.renderMenuHeader
                                    ?
                                    this.props.renderMenuHeader(menu)
                                    :
                                    menu
                        }
                    </>
                }}
                renderMenuItem={(label, item) => {
                    if(this.props.renderMenuItem){
                        return <div>{this.props.renderMenuItem(label, item)}</div>
                    } else {
                        return <>{label}</>;
                    }
                }}
                renderExtraFooter={() => {
                    if(this.props.renderExtraFooter){
                        return this.props.renderExtraFooter();
                    } else {
                        return null;
                    }
                }}
            />
		</Box>;
	}
}

export default RSuiteInputPicker;