import React from "react";

import { 
    Row,
    Col,
    Form,
    Button,
    InputGroup,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import Utils from "../../../../utils/utils";
import { InOrOut, BinOrWaste } from "../../../../utils/enums";

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import CustomTooltip from '../../../../components/Tooltip/Tooltip';
import RSuiteSelectPicker from '../../../../components/OLD/Select/RSuiteSelectPicker';
import RSuiteAutoComplete from '../../../../components/OLD/Autocomplete/RSuiteAutoComplete';
import RSuiteCheckPicker from '../../../../components/OLD/Checkbox/RSuiteCheckPicker';
import makeBinTypePullHistory from '../../../../components/makeCategoryCompenents/binType/makeBinTypePullHistory';
import InputLayout from "../../../../components/input/InputLayout";

import { connect } from 'react-redux'
import { clear } from "../../../../../setup/redux/actions";
import { dispatchApiCallGet } from '../../../../../setup/redux/dispatch/actions'

import { ReactComponent as ScanTextIcon } from "../../../../../_metronic/assets/img/bin_center/scan_text.svg";


const BinTypeHistory = makeBinTypePullHistory(RSuiteSelectPicker);


const StyledInputGroup = styled(InputGroup)`
    input {
        &:not(.disabled) {
            &:not([value=""]) {
                border-right: 0px;
            }
        }
    }

    .input-group-text {
        background-color: white;
        cursor: pointer;

        i {
            font-size: 14px;
            color: #8e8e93;
        }

        &:hover {
            i {
                color: #f44336;
            }
        }
    }
`;
const StyledInputReadOnly = styled(Form.Control)`
    border: 0px;
    padding-left: 5px;
    cursor: default;

    &[readonly]{
        color: #181C32 !important;
    }
`;


class StepPullSection extends React.Component {
  
    constructor(props) {
        super(props);
        
        this.name = 'job_form_step_pull_section';

        let dataParams = (props.dataParams) ? props.dataParams : null;
        let id = (dataParams && dataParams.id) ? dataParams.id : null;
        let row = (dataParams && dataParams.row) ? dataParams.row : null;
        
        this.pageRef = React.createRef();

        this.state = {
            id: id,
            row: row,
        };
    }
    

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            this.name + '-binnumber', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = [];

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.binNumberId;
                        let title = item.binNumberName;
                        let arrItem = {
                            index: i,
                            value: value,
                            title: title,
                            label: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );
    }

    
    callBinNumberReadApi = (binTypeId = null, items, searchQuery = '', callback = null) => {
        let {
            values,
        } = this.props.formOptions;

        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: searchQuery,
            binTypeId: binTypeId,
            customerSiteId: values.customerSiteId,
            customerId: values.customerId,
            showRecentJob: true,
        };

        this.props.dispatchApiCallGet(data, this.name + '-binnumber', 'binnumber', { items: items }, callback, () => {});
    }
    /* END API */


    body = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;
        
        let isDisabled = values.isParams || Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || !(values.customerSiteId) || Utils.isCancelledOrFailed(values.currentStatusName) || values.isCollectJob;
        let isDisabledWaste = values.isParams || Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || !(values.customerSiteId) || !(values.stepPullBinTypeId) || Utils.isCancelledOrFailed(values.currentStatusName);
        let isDisabledWeightChit = Utils.isCancelledOrFailed(values.currentStatusName);

        return <Row className={''}>
            <Col xs={12} md={6}>
                <Row className={''}>
                    <Col xs={12}>
                        <h3 className={'mb-7'}>
                            <span style={{ color: '#DFDFDF' }}>BIN IN</span>
                            <CustomTooltip placement={'right'} title={'You collect bin from customer site.'}>
                                <InfoOutlinedIcon style={{ color: '#DFDFDF', width: '30px', height: '25px' }} />
                            </CustomTooltip>
                        </h3>
                    </Col>
                    <Col xs={12}>
                        <Row>
                            <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.stepPullBinTypeId.label}
                                    required
                                >
                                    <StyledInputGroup>
                                        <Form.Control
                                            className={'custom-readonly-bin-waste-field ' + (isDisabled ? 'disabled': '')}
                                            id={this.props.fields.stepPullBinTypeId.id}
                                            placeholder={this.props.fields.stepPullBinTypeId.placeholder}
                                            readOnly={true}
                                            value={values.stepPullBinTypeName}
                                            isInvalid={!!errors.stepPullBinTypeId}
                                            onClick={(e) => {
                                                if(!isDisabled){
                                                    if(this.props.onBinWasteSidePanel){
                                                        this.props.onBinWasteSidePanel(InOrOut.IN, BinOrWaste.BIN);
                                                    }
                                                }
                                            }}
                                        />
                                        {(!isDisabled && values.stepPullBinTypeName != null && values.stepPullBinTypeName != '') && <InputGroup.Text
                                            onClick={(e) => {
                                                if(this.props.onBinWasteClear){
                                                    this.props.onBinWasteClear(InOrOut.IN, BinOrWaste.BIN);
                                                }
                                            }}
                                        >
                                            <i className={'material-icons'}>close</i>
                                        </InputGroup.Text>}
                                        <Form.Control.Feedback type="invalid">{errors.stepPullBinTypeId}</Form.Control.Feedback>
                                    </StyledInputGroup>
                                </InputLayout>
                            </Col>
                            <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.stepPullWasteTypeIds.label}
                                    required
                                >
                                    <StyledInputGroup>
                                        <Form.Control
                                            className={'custom-readonly-bin-waste-field ' + (isDisabledWaste ? 'disabled': '')}
                                            id={this.props.fields.stepPullWasteTypeIds.id}
                                            placeholder={this.props.fields.stepPullWasteTypeIds.placeholder}
                                            readOnly={true}
                                            value={values.stepPullWasteTypeIds ? values.customFormParams.stepPullWasteTypeItems.filter(item => values.stepPullWasteTypeIds.includes(item.value)).map(x => x.title).join(', ') : ''}
                                            isInvalid={!!errors.stepPullWasteTypeIds}
                                            onClick={(e) => {
                                                if(!isDisabledWaste){
                                                    if(this.props.onBinWasteSidePanel){
                                                        this.props.onBinWasteSidePanel(InOrOut.IN, BinOrWaste.BIN);
                                                    }
                                                }
                                            }}
                                        />
                                        {(!isDisabledWaste && (values.stepPullWasteTypeIds ? values.customFormParams.stepPullWasteTypeItems.filter(item => values.stepPullWasteTypeIds.includes(item.value)).map(x => x.title).join(', ') : '') != '') && <InputGroup.Text
                                            onClick={(e) => {
                                                if(this.props.onBinWasteClear){
                                                    this.props.onBinWasteClear(InOrOut.IN, BinOrWaste.BIN);
                                                }
                                            }}
                                        >
                                            <i className={'material-icons'}>close</i>
                                        </InputGroup.Text>}
                                        <Form.Control.Feedback type="invalid">{errors.stepPullWasteTypeIds}</Form.Control.Feedback>
                                    </StyledInputGroup>
                                </InputLayout>
                            </Col>
                            <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.stepPullBinNumber.label}
                                >
                                    <InputGroup>
                                    <Form.Control
                                        as = {RSuiteAutoComplete}
                                        isInvalid={!!errors.stepPullBinNumber}

                                        id={this.props.fields.stepPullBinNumber.id}
                                        placeholder={this.props.fields.stepPullBinNumber.placeholder}
                                        items={values.customFormParams.stepPullBinNumberItems}
                                        value={values.stepPullBinNumber}
                                        isLoading={values.customFormParams.stepPullBinNumberIsLoading}
                                        disabled={values.isParams || Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || !(values.customerSiteId) || !(values.stepPullBinTypeId) || Utils.isCancelledOrFailed(values.currentStatusName) || (values.isCollectJob && values.stepPullBinNumber != '')}
                                        labelKey={'title'}
                                        isDefault={true}
                                        pattern={Utils.max7chars()}
                                        renderMenuItem={(label, item) => {
                                            let minDate = Utils.getMinDate();
                                            let lastJobDate = (item && item.item && item.item.lastJobDate) ? item.item.lastJobDate : minDate;

                                            return <div className={'d-flex align-items-center'}>
                                                {((lastJobDate !== minDate) && (item.index < 5)) ? <i className={'material-icons'}>access_time</i> : null}
                                                <span className={'ps-2'}>{item.title}</span>
                                            </div>
                                        }}
                                        onClick={async () => {
                                            await setFieldValue('customFormParams.stepPullBinNumberIsLoading', true);
                                            this.callBinNumberReadApi(values.stepPullBinTypeId, values.customFormParams.stepPullBinNumberItems, '', async (items) => {
                                                await setFieldValue('customFormParams.stepPullBinNumberItems', items);
                                                await setFieldValue('customFormParams.stepPullBinNumberIsLoading', false);
                                            });
                                        }}
                                        onSearch={async (value) => {
                                            await setFieldValue('stepPullisUsingQr', false);
                                            await setFieldValue('stepPullisUsingTextDetection', false);
                                            await setFieldValue('customFormParams.isSameAsBinOut', false);
                                            await setFieldValue('customFormParams.stepPullBinNumberIsLoading', true);
                                            this.callBinNumberReadApi(values.stepPullBinTypeId, values.customFormParams.stepPullBinNumberItems, value, async (items) => {
                                                await setFieldValue('customFormParams.stepPullBinNumberItems', items);
                                                await setFieldValue('customFormParams.stepPullBinNumberIsLoading', false);
                                            });
                                        }}
                                        onChange={async (value) => {
                                            await setFieldValue('stepPullisUsingQr', false);
                                            await setFieldValue('stepPullisUsingTextDetection', false);
                                            await setFieldValue('stepPullBinNumber', value);
                                            await setFieldValue('customFormParams.isSameAsBinOut', false);
                                        }}
                                        onSelect={async (value, item) => {
                                            await setFieldValue('stepPullisUsingQr', false);
                                            await setFieldValue('stepPullisUsingTextDetection', false);
                                            await setFieldValue('stepPullBinNumber', value);
                                            await setFieldValue('customFormParams.isSameAsBinOut', false);
                                        }}
                                    />
                                    {values.stepPullisUsingQr && <CustomTooltip title={'QR code is scanned'}>
                                        <i style={{ position: 'absolute', right: '15px', top: '15px' }} class="fa fa-qrcode"></i>
                                    </CustomTooltip>}
                                    {values.stepPullisUsingTextDetection && <CustomTooltip title={'QR code is scanned with text recognition'}>
                                        <ScanTextIcon style={{ position: 'absolute', right: '14px', top: '15px', width: '15px', height: '15px' }} />
                                    </CustomTooltip>}
                                    <Form.Control.Feedback type="invalid">{errors.stepPullBinNumber}</Form.Control.Feedback>
                                    </InputGroup>
                                </InputLayout>
                            </Col>
                            {!values.isRecurringForm && <Col>
                                <InputLayout
                                    label={<>
                                        <span>{this.props.fields.stepBinWeight.label}</span>
                                        <CustomTooltip placement={'right'} title={'Gross Weight (This) - Tare Weight - Non billable Weight = Billable Weight'}>
                                            <InfoOutlinedIcon style={{ color: '#DFDFDF', width: '30px', height: '25px' }} />
                                        </CustomTooltip>
                                    </>}
                                >
                                    <Form.Control
                                        className={'custom-readonly-bin-weight-field ' + (isDisabledWeightChit ? 'disabled': '')}
                                        id={this.props.fields.stepBinWeight.id}
                                        placeholder={this.props.fields.stepBinWeight.placeholder}
                                        readOnly={true}
                                        value={values.stepBinWeight}
                                        isInvalid={!!errors.stepBinWeight}
                                        onClick={(e) => {
                                            if(!isDisabledWeightChit){
                                                if(this.props.onWeighChitSidePanel){
                                                    this.props.onWeighChitSidePanel();
                                                }
                                            }
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.stepBinWeight}</Form.Control.Feedback>
                                </InputLayout>
                            </Col>}
                            
                            <Col xs={12}>
                                <InputLayout
                                    label={'Service name'}
                                    inputProps={{
                                        className: 'd-flex align-items-center'
                                    }}
                                >
                                    <div>
                                        <span className={'pe-2'}>{values.stepPullServiceItemName}</span>
                                        {(values.stepPullServiceItemIsGlobal && (values.stepPullServiceItemName != '')) && <CustomTooltip placement={'top'} title={'Global service'}>
                                            <i className={'material-icons'} style={{ position: 'relative', top: '6px', fontSize: '22px', }}>public</i>
                                        </CustomTooltip>}
                                    </div>
                                </InputLayout>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    }
    

    render() {
        return <div ref={this.pageRef}>
            {this.body()}
        </div>
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(StepPullSection);
