import React from 'react';
import _ from 'lodash';
import SplitPane from 'react-split-pane';

import { 
  Drawer,
} from '@material-ui/core';

import { 
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import WrappedButton from '../components/Buttons/WrappedButton';

import { useDispatch } from "react-redux";
import cancelToken from "../../setup/redux/slices/cancelToken";


const StyledSplitPane = styled.div`
  .custome-pane {
    height: calc(100vh - 65px) !important;

    .Pane1 {
      z-index: 8;
    }
    
    .Pane2 {
      display: grid !important;
    }
  }

  .Resizer {
    background: #000;
    opacity: .2;
    z-index: 7;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
  }

  .Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }

  .Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
  }

  .Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
  }

  .Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
  }

  .Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
  }
  .Resizer.disabled {
    cursor: default;
  }
  .Resizer.disabled:hover {
    border-color: transparent;
  }
`;

const StyledPane = styled.div`
  background-color: white;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

const StyledOwerlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;

  background-color: rgba(0, 0, 0, 0.2);
  cursor: no-drop;

  justify-content: center;
  align-items: center;
  display: ${props => ((props.options.showLoadingOverlay === true) ? 'flex' : 'none')};
`;

const StyledLeftHeader = styled.div`
  position: ${props => ((props.options.isLeftHeaderFixed === true) ? 'sticky' : 'relative')};
  width: 100%;
  top: 0px;
  padding: 5px 0px;
  z-index: 6;
  background-color: white;
  border-bottom: 1px solid #f4f4f4;
  min-height: 48px;
`;

const StyledLeftBody = styled.div`
  display: flex;
  padding: 0px;
  min-height: ${props => ((props.options.isLeftFooterFixed === true) ? 'calc(100% - ' + props.height +')' : 'auto')};
`;

const StyledLeftFooter = styled.div`
  position: ${props => ((props.options.isLeftFooterFixed === true) ? 'sticky' : 'relative')};
  bottom: 0;
  width: 100%;
  padding: 20px 0px;
  z-index: 5;
  background-color: #F7F7F7;
  border-top: 1px solid #f4f4f4;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #E2E2E2;
  }
`;

const StyledRightHeader = styled.div`
  position: ${props => ((props.isMobile) ? 'relative' : (props.options.isRightHeaderFixed === true) ? 'sticky' : 'relative')};
  width: ${props => ((props.isMobile) ? '100vw' : '100%')};
  top: 0px;
  padding: 0px;
  z-index: 6;
  background-color: white;
  border-bottom: 1px solid #f4f4f4;
  min-height: 48px;
`;

const StyledRightBody = styled.div`
  display: flex;
`;


const MIN_WIDTH = 992;
const PANEL_WIDTH = 315;


const ResponsiveLayout = React.forwardRef((props, ref) => {
  const [size, setSize] = React.useState(PANEL_WIDTH);
  const [show, setShow] = React.useState(false);
  const [allowResize, setAllowResize] = React.useState(((props.options.allowResize === false) || (props.options.allowResize === true)) ? props.options.allowResize : false);
  const [canResize, setCanResize] = React.useState(allowResize ? true : false);


  React.useImperativeHandle(
    ref,
    () => ({
      getSize() {
        return size;
      },
    }),
  );


  React.useEffect(() => {
    setAllowResize(((props.options.allowResize === false) || (props.options.allowResize === true)) ? props.options.allowResize : false);
    setCanResize(allowResize ? true : false);
  }, [props.options]);

  React.useEffect(() => {
    if((props.windowWidth > 0) && (props.windowWidth < MIN_WIDTH)){
      setSize(0);
      setCanResize(false);

      if(props.onChangeSize){
        props.onChangeSize(0);
      }
    }
  }, [props.windowWidth]);


  const setMenu = () => {
    return <Drawer
      anchor={'left'} 
      open={show}
      onClose={() => {
        setShow(false)
      }}
    >
      <div id={'left_panel_1_ID'} style={{ minWidth: '320px', maxWidth: '320px', overflowX: 'hidden' }}>
        <LeftLayout 
          {...props}
          onHide={() => {
            setShow(false)
          }}
        />
      </div>
    </Drawer>
  }


  return <>
    <Container className={'px-2'}>
      <StyledSplitPane>
        <SplitPane 
          className={'custome-pane'}
          style={{
            position: 'relative'
          }}
          split={'vertical'}
          primary={'first'}
          allowResize={canResize}
          minSize={props.options.minSize}
          maxSize={props.options.maxSize}
          size={size}
          onChange={(size) => {
            setSize(size);

            if(props.onChangeSize){
              props.onChangeSize(size);
            }
          }}
        >
          <StyledPane id={'left_panel_2_ID'}>
            {(props.windowWidth >= MIN_WIDTH) && <LeftLayout 
              {...props}
              size={size}
              onHide={() => {
                setSize(0);
                setCanResize(false);

                if(props.onChangeSize){
                  props.onChangeSize(0);
                }
              }}
            />}
          </StyledPane>
          <StyledPane>
            <RightLayout
              {...props}
              size={size}
              onShow={() => {
                setSize(PANEL_WIDTH);
                setCanResize(allowResize ? true : false);

                if(props.onChangeSize){
                  props.onChangeSize(PANEL_WIDTH);
                }
              }}
              onToggleMenu={() => {
                setShow(!show);
                setCanResize(allowResize ? !canResize : false);
              }}
            />
          </StyledPane>
        </SplitPane>
      </StyledSplitPane>
    </Container>
    {props.isLoading && <StyledOwerlay options={props.options}><Spinner animation="border" /></StyledOwerlay>}
    {setMenu()}
  </>
});

const LeftLayout = ({options, ...props}) => {
  const [height, setHeight] = React.useState('0px');

  const refLeftHeader = React.useRef();
  const refLeftFooter = React.useRef();


  React.useEffect(() => {
    getHeaderFooterSize(50).then((res) => {
      setHeight(res);
    });
  }, []);

  React.useEffect(() => {
    getHeaderFooterSize().then((res) => {
      setHeight(res);
    });
  }, [props.size]);


  const getHeaderFooterSize = (time = 20) => {
    return new Promise(resolve => {
      setTimeout(() => {
        let headerH = 0;
        if(refLeftHeader && refLeftHeader.current){
          headerH = refLeftHeader.current.clientHeight;
        }
        
        let footerH = 0;
        if(refLeftFooter && refLeftFooter.current){
          footerH = refLeftFooter.current.clientHeight;
        }
        
        let res = (headerH + footerH + 2) + 'px';
        
        resolve(res);
      }, time);
    });
  }

  return <>
    {(options && options.showLeftHeader) && <StyledLeftHeader ref={refLeftHeader} options={options} isMobile={(props.windowWidth < MIN_WIDTH)}>
      <Row className={'align-items-center'} style={{ flexBasis: '100%', margin: '0px' }}>
        <Col xs={true}>{(typeof options.leftHeaderContent === 'function') ? options.leftHeaderContent() : options.leftHeaderContent}</Col>
      </Row>
    </StyledLeftHeader>}
    <StyledLeftBody options={options} isMobile={(props.windowWidth < MIN_WIDTH)} height={height}>{(typeof options.leftBodyContent === 'function') ? options.leftBodyContent() : options.leftBodyContent}</StyledLeftBody>
    {(options && options.showLeftFooter) && <StyledLeftFooter 
      ref={refLeftFooter} 
      options={options} 
      isMobile={(props.windowWidth < MIN_WIDTH)}
      onClick={() => {
        if(props.onHide){
          props.onHide();
        }
      }}
    >
      <i className={'fa fa-chevron-left'}></i>
    </StyledLeftFooter>}
  </>
};

const RightLayout = ({options, onToggleMenu, ...props}) => {
  return <>
    {(options && options.showRightHeader) && <StyledRightHeader options={options} isMobile={(props.windowWidth < MIN_WIDTH)}>
      
      <Row className={'align-items-center'} style={{ flexBasis: '100%', margin: '0px' }}>
        
        {((props.windowWidth >= MIN_WIDTH) && (props.size < 50)) && <Col xs={{ span: 'auto', order: 1 }} sm={{ span: 'auto', order: 1 }} md={{ span: 'auto', order: 1 }}>
          <WrappedButton
            className={'px-3 pe-1'}
            variant={"light"}
            size={'sm'}
            style={{ backgroundColor: 'transparent' }}
            onClick={async () => {
              if(props.onShow){
                props.onShow();
              }
            }}
          >
            <i className={'fa fa-chevron-right ps-1 pe-2'}></i>
          </WrappedButton>
        </Col>}

        {(props.windowWidth < MIN_WIDTH) && <Col xs={{ span: true, order: 2 }} sm={{ span: true, order: 2 }} md={{ span: 'auto', order: 2 }}>
          <WrappedButton
            className={'px-3 pe-1'}
            variant={"light"}
            size={'sm'}
            style={{ backgroundColor: 'transparent' }}
            onClick={() => {
              if(onToggleMenu){
                onToggleMenu();
              }
            }}
          >
            <i className={'fa fa-bars ps-1 pe-2'}></i>
          </WrappedButton>
        </Col>}

        <Col xs={{ span: 12, order: 5 }} sm={{ span: 12, order: 5 }} md={{ span: true, order: 3 }}>
          <Row className={'align-items-center'}>
            {options.rightHeaderContent && <Col xs={12}>
              {(typeof options.rightHeaderContent === 'function') ? options.rightHeaderContent() : options.rightHeaderContent}
            </Col>}
            
            {options.rightHeaderTabs && <Col xs={12}>
              {(typeof options.rightHeaderTabs === 'function') ? options.rightHeaderTabs() : options.rightHeaderTabs}
            </Col>}
          </Row>
        </Col>

        {options.rightHeaderButtons && <Col xs={{ span: 12, order: 4 }} sm={{ span: 'auto', order: 3 }} md={{ span: 'auto', order: 4 }}>
          {(typeof options.rightHeaderButtons === 'function') ? options.rightHeaderButtons() : options.rightHeaderButtons}
        </Col>}
            
        {options.headerCloseShow && <Col xs={{ span: 'auto', order: 3 }} sm={{ span: 'auto', order: 4 }} md={{ span: 'auto', order: 5 }} className={'text-end'}>
          <WrappedButton
            className={'px-3 pe-1'}
            variant={"light"}
            size={'sm'}
            style={{ backgroundColor: 'transparent' }}
            onClick={() => {
              if(props.page && props.page.props && props.page.props.history){
                props.page.props.history.push(props.page.backLink);
              }
            }}
          >
            <i className={'material-icons'}>close</i>
          </WrappedButton>
        </Col>}
        
      </Row>
    </StyledRightHeader>}
    <StyledRightBody options={options} isMobile={(props.windowWidth < MIN_WIDTH)} className={'p-0'}>{props.children}</StyledRightBody>
  </>
};


const PagePanelLayout = (props) => {
  const dispatch = useDispatch();
  
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [page, setPage] = React.useState(null);
  const [isLoading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState({});


  const [windowWidth, setWindowWidth] = React.useState(0);
  
  let obj = React.useRef();

  const resizeListener = () => {
    setWindowWidth(window.innerWidth);

    if(options && options.onWindowResize){
      try {
        options.onWindowResize();
      } catch(e){}
    }
  }
  
  React.useEffect(() => {
    
    return () => {
      dispatch(cancelToken.cancel());
    }
  }, []);

  React.useEffect(() => {
    try{
      resizeListener();
    } catch(e){}
    
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  });


  const onChangeSize = (size) => {
    if(options && options.onChangeSize){
      try {
        options.onChangeSize(size);
      } catch(e){}
    }
  }


  props.layoutRef.current = {
    obj: obj,
    setLayoutForm: (page = null, options = {}) => {
      let initOptions = {
        showLeftHeader: true,
        leftHeaderContent: null,
        isLeftHeaderFixed: true,
        leftBodyContent: null,
        showLeftFooter: true,
        isLeftFooterFixed: true,

        showRightHeader: true,
        rightHeaderContent: null,
        rightHeaderButtons: null,
        rightHeaderTabs: null,
        isRightHeaderFixed: true,

        headerCloseShow: true,
        showLoadingOverlay: true,

        allowResize: false,
        minSize: 200,
        maxSize: 900,

        onWindowResize: null,
        onChangeSize: null,
      };
      
      setPage(page);
      setOptions(_.assign(initOptions, options));

      let size = (obj && obj.current) ? obj.current.getSize() : PANEL_WIDTH;
      if(options && options.onChangeSize){
        try {
          options.onChangeSize(size);
        } catch(e){}
      }
    },
    setLayoutLoading: (state = false) => {
      setLoading(state);
    },
    refreshLayout: () => {
      forceUpdate();
    },
  };


  return <ResponsiveLayout {...props} ref={obj} isLoading={isLoading} windowWidth={windowWidth} options={options} page={page} onChangeSize={onChangeSize} />
}

export default PagePanelLayout;
