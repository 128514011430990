import React from "react";
import moment from 'moment';
import _ from 'lodash';
import styled from 'styled-components';

import {
    Row,
    Col,
    Button,
    Spinner,
    InputGroup,
    Form,
} from "react-bootstrap-v5";

import Pagination from '@material-ui/lab/Pagination';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import CustomTooltip from '../../../../components/Tooltip/Tooltip';
import { DrawerContentLayout } from '../../../../components/drawer/DrawerLayout'
import Utils from "../../../../utils/utils";
import { JobStepType, InOrOut, RadioOrCheckbox, ServiceType } from "../../../../utils/enums";

import { connect } from 'react-redux'
import { clear } from "../../../../../setup/redux/actions";
import { dispatchApiCallGet } from '../../../../../setup/redux/dispatch/actions'


const StyledInputGroup = styled(InputGroup)`
    border-bottom: 1px solid #adadad;

    .search-button {
        i {
            color: #adadad;
        }

        &:hover {
            i {
                color: #484848;
            }
        }
    }
    
    .search-input {
        border-color: transparent;
    }

    .search-clear {
        i {
            color: #adadad;
        }

        &:hover {
            i {
                color: #484848;
            }
        }
    }
`;

const StyledTable = styled.table`
    width: 100%;

    .caption {
        background-color: #F7F7F7;
        border: 1px solid #DFDFDF;
        padding: 7px;
        font-weight: normal;
        color: #444444;
    }

    .header-row {
        border: 1px solid #DFDFDF;

        th {
            padding: 7px;
            font-weight: normal;
            color: #7A7A7A;
        }
    }

    tbody {
        tr {
            border: 1px solid #DFDFDF;
            cursor: not-allowed;
            td {
                padding: 7px;
            }

            &:not(.disabled){
                cursor: pointer;

                &:hover {
                    background-color: #F7F7F7;
                }
            }
        }
    }
`;


class JobFormBinHistoryDrawer extends React.Component {

    constructor(props) {
        super(props);

        this.name = 'job_form_bin_history_drawer';

        this.bodyRef = React.createRef();
        this.inputRef = React.createRef();
        this.tableRef = React.createRef();

        let dataParams = (props.dataParams) ? props.dataParams : null;
        let isServiceOrHistory = ((props.isServiceOrHistory == false) || (props.isServiceOrHistory == true)) ? props.isServiceOrHistory : false;
        let id = (dataParams && dataParams.id) ? dataParams.id : null;
        let row = (dataParams && dataParams.row) ? dataParams.row : null;


        let mainFormOptionsValues = (props.mainFormOptions && props.mainFormOptions.values) ? props.mainFormOptions.values : null;
        

        this.state = {
            id: id,
            row: row,
            dataParams: dataParams,
            selectedValues: this.prepareForm(dataParams),

            search: '',
            sortColumn: 'created',
            sortDir: 'asc',
            includeGlobalService: true,
            includeLocalService: this.isCheckedAllCustomer(dataParams, mainFormOptionsValues),
            isIncludeInactive: null,
            isInactive: null,

            rowsPerPageSelected: Utils.getMaxPageSize(),
            isLoading: false,
            rows: [],
            currentPage: 1,

            clientSideCurrentPageLocal: 1,
            clientSideCurrentPageGlobal: 1,
            clientSideItemsPerPage: 10,

            selectedItems: [],
            arrService: [],

            isServiceOrHistory: isServiceOrHistory,
        };
    }


    componentDidMount() {
        if (!this.state.isServiceOrHistory) {
            this.callServiceItemsReadApi((arr) => {
                if(!this.state.includeLocalService){
                    let items = arr.filter(x => x.item.customerId > 0);
                    if(items && items.length === 0){
                        this.setState({
                            isLoading: true,
                            includeLocalService: true,
                        }, () => {
                            this.callServiceItemsReadApi((arr) => {
                                this.changePaginationToSelectedItem();
                            });
                        });
                    } else {
                        this.changePaginationToSelectedItem();
                    }
                } else {
                    this.changePaginationToSelectedItem();
                }
            });
        } else {
            this.callBinTypeHistoryReadApi();
        }
    }

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);

        if (nextProps.isServiceOrHistory !== this.state.isServiceOrHistory) {
            let isServiceOrHistory = ((nextProps.isServiceOrHistory == false) || (nextProps.isServiceOrHistory == true)) ? nextProps.isServiceOrHistory : false;
            this.setState({
                isServiceOrHistory: isServiceOrHistory,
                search: '',
                currentPage: 1,
                rows: [],
            }, () => {
                if (!this.state.isServiceOrHistory) {
                    this.callServiceItemsReadApi();
                } else {
                    this.callBinTypeHistoryReadApi();
                }
            });
        }
    }


    /* API */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            this.name + '-service_item_all',
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                // let arr = (data && data.data && data.data.length > 0) ? data.data : [];

                let arr = [];
                let selectedItems = [];

                let selectedValues = this.state.selectedValues;
                let binId = (selectedValues && selectedValues.binTypeId) ? selectedValues.binTypeId : null;
                let standardServices = (selectedValues && selectedValues.standardServices && selectedValues.standardServices.length > 0) ? selectedValues.standardServices : [];
                let wasteTypeIds = (selectedValues && selectedValues.wasteTypeIds && selectedValues.wasteTypeIds.length > 0) ? selectedValues.wasteTypeIds : [];

                if (data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        if (item.type === ServiceType.AdditionalService)
                            continue;


                        let index = item.serviceItemId;
                        let value = Utils.makeIDfromString(((item.serviceItemId) ? item.serviceItemId : '') + item.binTypeId + "_" + item.binTypeName + "_" + item.wasteTypeId + "_" + item.wasteTypeName + "_" + item.serviceTag);

                        let binTypeId = item.binTypeId;
                        let binTypeName = item.binTypeName;
                        let wasteTypeId = item.wasteTypeId;
                        let wasteTypeName = item.wasteTypeName;
                        let serviceTag = (item.serviceTag) ? item.serviceTag : '';

                        let isChecked = false;
                        if (standardServices && standardServices.length > 0) {
                            isChecked = ((binTypeId == binId) && (standardServices.findIndex(x => {
                                if (item.serviceItemId) {
                                    return x.serviceItemId == item.serviceItemId;
                                } else {
                                    let bundleServiceTag = (x.bundleServiceTag && x.bundleServiceTag !== '') ? x.bundleServiceTag : x.serviceTag
                                    return bundleServiceTag == serviceTag;
                                }
                            }) !== -1)) ? true : false;
                        } else {
                            if (item.serviceItemId && selectedValues.serviceItemId) {
                                isChecked = (item.serviceItemId == selectedValues.serviceItemId);
                            }
                        }

                        let isDisabled = (this.isRadioOrCheckbox() === RadioOrCheckbox.CHECKBOX) ? (binId != null && binTypeId != binId) ? true : false : false;

                        let arrItem = {
                            id: index,
                            serviceItemId: index,
                            value: value,
                            item: item,
                            binTypeId: binTypeId,
                            binTypeName: binTypeName,
                            wasteTypeId: wasteTypeId,
                            wasteTypeName: wasteTypeName,
                            wasteTypeIds: wasteTypeIds,
                            serviceTag: serviceTag,
                            checked: isChecked,
                            disabled: isDisabled,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                        if (isChecked) {
                            selectedItems = Utils.addToArray(selectedItems, value, arrItem);
                        }
                    }
                }

                if (standardServices && standardServices.length > 0) {
                    let ssItem = standardServices[0];
                    let isChecked = arr.some(x => x.checked);
                    if (!isChecked) {
                        if (ssItem.binTypeId != null && ssItem.wasteTypeId != null) {
                            let arrIndex = arr.findIndex(x => x.binTypeId == ssItem.binTypeId && x.wasteTypeId == ssItem.wasteTypeId);
                            if (arrIndex != -1) {
                                arr[arrIndex].checked = true;
                            }
                        }
                    }
                }

                arr = arr.filter(row => {
                    if (this.state.search === '') {
                        return true;
                    } else {
                        let keyArr = Object.keys(row).map(key => {
                            let searchVal = '';
                            try {
                                searchVal = this.state.search.toLowerCase();
                            } catch (err) {
                                searchVal = '';
                            }

                            let col = row[key] ? row[key] : '';
                            if (col.toString().toLowerCase().includes(searchVal)) {
                                return true
                            } else {
                                return false;
                            }
                        });
                        return keyArr.includes(true);
                    }
                });

                arr = this.disableRows(arr, selectedItems);


                this.setState({
                    isLoading: isLoading,
                    selectedItems: selectedItems,
                    rows: arr,
                    arrService: arr,
                }, () => {
                    this.props.clear();

                    if(this.props.onAllServicesLoad){
                        this.props.onAllServicesLoad(arr);
                    }

                    if (callback) {
                        callback(arr);
                    }
                });

            }
        );

        Utils.reduxProps(nextProps,
            this.name + '-service_item',
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = [];
                let selectedItems = [];

                let selectedValues = this.state.selectedValues;
                let binId = (selectedValues && selectedValues.binTypeId) ? selectedValues.binTypeId : null;
                let standardServices = (selectedValues && selectedValues.standardServices && selectedValues.standardServices.length > 0) ? selectedValues.standardServices : [];
                let wasteTypeIds = (selectedValues && selectedValues.wasteTypeIds && selectedValues.wasteTypeIds.length > 0) ? selectedValues.wasteTypeIds : [];

                if (data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        if (item.type === ServiceType.AdditionalService)
                            continue;


                        let index = item.serviceItemId;
                        let value = Utils.makeIDfromString(((item.serviceItemId) ? item.serviceItemId : '') + item.binTypeId + "_" + item.binTypeName + "_" + item.wasteTypeId + "_" + item.wasteTypeName + "_" + item.serviceTag);

                        let binTypeId = item.binTypeId;
                        let binTypeName = item.binTypeName;
                        let wasteTypeId = item.wasteTypeId;
                        let wasteTypeName = item.wasteTypeName;
                        let serviceTag = (item.serviceTag) ? item.serviceTag : '';

                        let isChecked = false;
                        if (standardServices && standardServices.length > 0) {
                            isChecked = ((binTypeId == binId) && (standardServices.findIndex(x => {
                                if (item.serviceItemId) {
                                    return x.serviceItemId == item.serviceItemId;
                                } else {
                                    let bundleServiceTag = (x.bundleServiceTag && x.bundleServiceTag !== '') ? x.bundleServiceTag : x.serviceTag
                                    return bundleServiceTag == serviceTag;
                                }
                            }) !== -1)) ? true : false;
                        } else {
                            if (item.serviceItemId && selectedValues.serviceItemId) {
                                isChecked = (item.serviceItemId == selectedValues.serviceItemId);
                            }
                        }

                        let isDisabled = (this.isRadioOrCheckbox() === RadioOrCheckbox.CHECKBOX) ? (binId != null && binTypeId != binId) ? true : false : false;

                        let arrItem = {
                            id: index,
                            serviceItemId: index,
                            value: value,
                            item: item,
                            binTypeId: binTypeId,
                            binTypeName: binTypeName,
                            wasteTypeId: wasteTypeId,
                            wasteTypeName: wasteTypeName,
                            wasteTypeIds: wasteTypeIds,
                            serviceTag: serviceTag,
                            checked: isChecked,
                            disabled: isDisabled,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                        if (isChecked) {
                            selectedItems = Utils.addToArray(selectedItems, value, arrItem);
                        }
                    }
                }

                if (standardServices && standardServices.length > 0) {
                    let ssItem = standardServices[0];
                    let isChecked = arr.some(x => x.checked);
                    if (!isChecked) {
                        if (ssItem.binTypeId != null && ssItem.wasteTypeId != null) {
                            let arrIndex = arr.findIndex(x => x.binTypeId == ssItem.binTypeId && x.wasteTypeId == ssItem.wasteTypeId);
                            if (arrIndex != -1) {
                                arr[arrIndex].checked = true;
                            }
                        }
                    }
                }

                arr = arr.filter(row => {
                    if (this.state.search === '') {
                        return true;
                    } else {
                        let keyArr = Object.keys(row).map(key => {
                            let searchVal = '';
                            try {
                                searchVal = this.state.search.toLowerCase();
                            } catch (err) {
                                searchVal = '';
                            }

                            let col = row[key] ? row[key] : '';
                            if (col.toString().toLowerCase().includes(searchVal)) {
                                return true
                            } else {
                                return false;
                            }
                        });
                        return keyArr.includes(true);
                    }
                });

                arr = this.disableRows(arr, selectedItems);


                this.setState({
                    selectedItems: selectedItems,
                    rows: arr,
                    arrService: arr,

                    currentPage: 1,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if (callback) {
                        callback(arr);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.name + '-report_bin_type_detail',
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = [];
                let selectedItems = [];

                let selectedValues = this.state.selectedValues;
                let binId = (selectedValues && selectedValues.binTypeId) ? selectedValues.binTypeId : null;
                // let wasteTypeIds = (selectedValues && selectedValues.wasteTypeIds) ? selectedValues.wasteTypeIds : []; 
                // let wasteId = (wasteTypeIds && wasteTypeIds.length > 0) ? wasteTypeIds[0] : null; 



                if (data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];

                        let serviceItem = null;
                        let serviceItems = this.state.arrService.filter(x => (x.binTypeId == item.binTypeId) && (x.wasteTypeId == item.wasteTypeId));
                        if (serviceItems && serviceItems.length > 0) {
                            serviceItem = serviceItems[0]
                        }

                        let index = item.customerSiteBinHistoryId;
                        let serviceItemId = (serviceItem && serviceItem.serviceItemId) ? serviceItem.serviceItemId : item.serviceItemId;
                        let serviceTag = Utils.getServiceTagName(item.wasteTypeName, item.binTypeName);
                        let value = Utils.makeIDfromString(index + "_" + item.binTypeId + "_" + item.binTypeName + "_" + item.wasteTypeId + "_" + item.wasteTypeName + "_" + serviceTag);
                        let binTypeId = item.binTypeId;
                        let binTypeName = item.binTypeName;
                        let wasteTypeId = item.wasteTypeId;
                        let wasteTypeName = item.wasteTypeName;
                        let binOutDate = (item.binOutDate) ? item.binOutDate : '';
                        let binNumber = (item.binNumber) ? item.binNumber : '';
                        let driverName = (item.driverName) ? item.driverName : '';
                        let vehicleName = (item.vehicleName) ? item.vehicleName : '';
                        let jobNumber = (item.jobNumber) ? item.jobNumber : '';

                        let isChecked = false;
                        let isDisabled = (this.isRadioOrCheckbox() === RadioOrCheckbox.CHECKBOX) ? (binId != null && binTypeId != binId) ? true : false : false;

                        let arrItem = {
                            id: index,
                            serviceItemId: serviceItemId,
                            value: value,
                            item: item,
                            binTypeId: binTypeId,
                            binTypeName: binTypeName,
                            wasteTypeId: wasteTypeId,
                            wasteTypeName: wasteTypeName,
                            serviceTag: serviceTag,
                            binOutDate: binOutDate,
                            binNumber: binNumber,
                            driverName: driverName,
                            vehicleName: vehicleName,
                            jobNumber: jobNumber,
                            checked: isChecked,
                            disabled: isDisabled,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                        if (isChecked) {
                            selectedItems = Utils.addToArray(selectedItems, value, arrItem);
                        }
                    }
                }

                arr = arr.filter(row => {
                    if (this.state.search === '') {
                        return true;
                    } else {
                        let keyArr = Object.keys(row).map(key => {
                            let searchVal = '';
                            try {
                                searchVal = this.state.search.toLowerCase();
                            } catch (err) {
                                searchVal = '';
                            }

                            let col = row[key] ? row[key] : '';
                            if (col.toString().toLowerCase().includes(searchVal)) {
                                return true
                            } else {
                                return false;
                            }
                        });
                        return keyArr.includes(true);
                    }
                });

                arr = this.disableRows(arr, selectedItems);

                this.setState({
                    selectedItems: selectedItems,
                    rows: arr,

                    currentPage: 1,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if (callback) {
                        callback(arr);
                    }
                });
            }
        );
    }

    
    callAllServiceItemsReadApi = (callback = null) => {
        this.setState({
            isLoading: true,
        });

        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            includeGlobalService: true,
            includeLocalService: true,
            types: '0,1',
            includeSites: false,
            includeTaxRate: false,
            distinct: true,
        };

        this.props.dispatchApiCallGet(data, this.name + '-service_item_all', 'service-item/compact', null, callback, () => { });
    }

    callServiceItemsReadApi = (callback = null) => {
        this.setState({
            isLoading: true,
        });

        let data = {
            currentPage: this.state.currentPage,
            pageSize: this.state.rowsPerPageSelected,
            // searchQuery: this.state.search,
            // sortColumn: this.state.sortColumn,
            // sortDir: this.state.sortDir,
            types: '0,1',
            includeSites: false,
            includeTaxRate: false,
        };

        let includeGlobalService = ((this.state.includeGlobalService === true) || (this.state.includeGlobalService === false)) ? this.state.includeGlobalService : null;
        if (includeGlobalService) {
            data['includeGlobalService'] = includeGlobalService;
        }

        let includeLocalService = ((this.state.includeLocalService === true) || (this.state.includeLocalService === false)) ? this.state.includeLocalService : null;
        if (includeLocalService) {
            data['includeLocalService'] = includeLocalService;
            data['distinct'] = true;
        } else {
            data['distinct'] = null;
        }

        let isIncludeInactive = ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : null;
        if (isIncludeInactive) {
            data['isIncludeInactive'] = isIncludeInactive;
        }

        let isInactive = ((this.state.isInactive === true) || (this.state.isInactive === false)) ? this.state.isInactive : null;
        if (isInactive) {
            data['isInactive'] = isInactive;
        }

        if (!includeLocalService) {
            if (this.state.row.customerId) {
                data['customerId'] = this.state.row.customerId;
            }

            if (this.state.row.customerSiteId) {
                data['customerSiteId'] = this.state.row.customerSiteId;
            }
        }

        this.props.dispatchApiCallGet(data, this.name + '-service_item', 'service-item/compact', null, callback, () => { });
    }

    callBinTypeHistoryReadApi = (callback = null) => {
        let data = {
            currentPage: this.state.currentPage,
            pageSize: this.state.rowsPerPageSelected,
            // searchQuery: this.state.search,
            sortColumn: this.state.sortColumn,
            sortDir: this.state.sortDir,
            customerSiteId: this.state.row.customerSiteId,
        };

        this.props.dispatchApiCallGet(data, this.name + '-report_bin_type_detail', 'report/bin-out-detail', null, callback, () => { });
    }
    /* END API */


    /* FUNCTIONS */
    colRadioTemplate = (row, i) => {
        return <div className={'d-flex align-items-center'}>
            {!row.disabled && <Form.Check
                type={(this.isRadioOrCheckbox() === RadioOrCheckbox.RADIO) ? 'radio' : 'checkbox'}
                id={'row_' + row.serviceItemId + '_' + i}
                name={'jobFormDrawerTableRadio_row'}
                label={''}
                checked={row.checked}
                disabled={row.disabled}
                readOnly={true}
            />}
        </div>
    }

    isRadioOrCheckbox = () => {
        let values = (this.state.dataParams && this.state.dataParams.row && this.state.dataParams.row.values) ? this.state.dataParams.row.values : null;
        let currentStatusName = (values && values.currentStatusName) ? values.currentStatusName : '';

        if (currentStatusName.toString().toLowerCase() === 'completed') {
            if (this.state.dataParams.type === JobStepType.Exchange) {
                if (this.state.row.inOrOut === InOrOut.OUT) {
                    return RadioOrCheckbox.RADIO;
                } else if (this.state.row.inOrOut === InOrOut.IN) {
                    return RadioOrCheckbox.CHECKBOX;
                }
            } else if (this.state.dataParams.type === JobStepType.OnTheSpot) {
                return RadioOrCheckbox.CHECKBOX;
            } else if (this.state.dataParams.type === JobStepType.Shift) {
                return RadioOrCheckbox.CHECKBOX;
            } else if (this.state.dataParams.type === JobStepType.Put) {
                return RadioOrCheckbox.RADIO;
            } else if (this.state.dataParams.type === JobStepType.Out) {
                return RadioOrCheckbox.RADIO;
            } else if (this.state.dataParams.type === JobStepType.Pull) {
                return RadioOrCheckbox.CHECKBOX;
            }
        } else {
            return RadioOrCheckbox.RADIO;
        }
    }

    disableRows = (rows, selectedItems) => {
        if (this.isRadioOrCheckbox() === RadioOrCheckbox.CHECKBOX) {
            let binTypeId = 0;

            if (selectedItems && selectedItems.length > 0) {
                binTypeId = (selectedItems[0] && selectedItems[0].binTypeId) ? selectedItems[0].binTypeId : 0;
            }

            if (rows && rows.length > 0) {
                for (let i = 0; i < rows.length; i++) {
                    rows[i]['disabled'] = (binTypeId == 0) ? false : (rows[i].binTypeId !== binTypeId) ? true : false;
                }

                let checkedIds = rows.filter(x => x.checked);

                for (let i = 0; i < rows.length; i++) {
                    if (!rows[i]['disabled']) {
                        if (checkedIds.findIndex(x => (x.binTypeId == rows[i].binTypeId) && (x.wasteTypeId == rows[i].wasteTypeId)) !== -1) {
                            rows[i]['disabled'] = rows[i]['checked'] ? false : true;
                        }
                    }
                }

                if (selectedItems && selectedItems.length == 0) {
                    let values = (this.state.dataParams && this.state.dataParams.row && this.state.dataParams.row.values) ? this.state.dataParams.row.values : null;
                    let currentStatusName = (values && values.currentStatusName) ? values.currentStatusName : '';

                    let selectedBinTypeId = 0;
                    if (currentStatusName.toString().toLowerCase() === 'completed') {
                        if (this.state.dataParams.type === JobStepType.Exchange) {
                            if (this.state.row.inOrOut === InOrOut.IN) {
                                selectedBinTypeId = values.stepExchangeInBinTypeId;
                            }

                        } else if (this.state.dataParams.type === JobStepType.OnTheSpot) {
                            selectedBinTypeId = values.stepOnTheSpotBinTypeId;

                        } else if (this.state.dataParams.type === JobStepType.Shift) {
                            selectedBinTypeId = values.stepShiftBinTypeId;

                        } else if (this.state.dataParams.type === JobStepType.Put) {
                            selectedBinTypeId = values.stepPutBinTypeId;

                        } else if (this.state.dataParams.type === JobStepType.Out) {
                            selectedBinTypeId = values.stepOutBinTypeId;

                        } else if (this.state.dataParams.type === JobStepType.Pull) {
                            selectedBinTypeId = values.stepPullBinTypeId;
                        }

                        for (let i = 0; i < rows.length; i++) {
                            if (rows[i].binTypeId !== selectedBinTypeId) {
                                rows[i]['disabled'] = false;
                            }
                        }
                    }
                }
            }
        }

        return rows;
    }

    rowClick = (id, row, obj = null) => {
        if (!row.disabled) {
            let selectedItems = this.state.selectedItems;
            let selItems = [];

            let rowId = 'id';

            let rows = this.state.rows;
            if (rows && rows.length > 0) {
                for (let i = 0; i < rows.length; i++) {
                    if (rows[i][rowId] === id) {
                        let isChecked = (this.isRadioOrCheckbox() === RadioOrCheckbox.CHECKBOX) ? !rows[i]['checked'] : true;
                        rows[i]['checked'] = isChecked;

                        if ((this.isRadioOrCheckbox() === RadioOrCheckbox.RADIO)) {
                            selectedItems = [];
                        }

                        if (isChecked) {
                            selItems = Utils.addToArr(selectedItems, rows[i]);
                        } else {
                            selItems = Utils.removeFromArr(selectedItems, rows[i]);
                        }
                    } else {
                        if ((this.isRadioOrCheckbox() === RadioOrCheckbox.RADIO)) {
                            rows[i]['checked'] = false;
                            selItems = Utils.removeFromArr(selectedItems, rows[i]);
                        }
                    }
                }
            }

            rows = this.disableRows(rows, this.state.selectedItems);

            this.setState({
                rows: rows,
                selectedItems: selItems,
            });
        }
    }

    addNewItem = (item) => {
        if (item && (item.type != ServiceType.AdditionalService)) {
            let rows = this.state.rows;
            let selectedItems = this.state.rows;

            let binId = (selectedItems && selectedItems.length > 0 && selectedItems[0].binTypeId) ? selectedItems[0].binTypeId : null;

            let bTName = (item && item.binType && item.binType.binTypeName && item.binType.binTypeName != '') ? item.binType.binTypeName : '';
            let wTName = (item && item.wasteType && item.wasteType.wasteTypeName && item.wasteType.wasteTypeName != '') ? item.wasteType.wasteTypeName : '';

            let binTypeId = item.binTypeId;
            let binTypeName = (item && item.binTypeName && item.binTypeName != '') ? item.binTypeName : bTName;
            let wasteTypeId = item.wasteTypeId;
            let wasteTypeName = (item && item.wasteTypeName && item.wasteTypeName != '') ? item.wasteTypeName : wTName;
            let serviceTag = (item.serviceTag) ? item.serviceTag : '';

            let index = item.serviceItemId;
            let value = Utils.makeIDfromString(((item.serviceItemId) ? item.serviceItemId : '') + binTypeId + "_" + bTName + "_" + wasteTypeId + "_" + wasteTypeName + "_" + serviceTag);

            let isChecked = true;
            let isDisabled = (binId !== null) ? (isChecked) ? false : true : false;

            let arrItem = {
                id: index,
                serviceItemId: index,
                value: value,
                item: item,
                binTypeId: binTypeId,
                binTypeName: binTypeName,
                wasteTypeId: wasteTypeId,
                wasteTypeName: wasteTypeName,
                serviceTag: serviceTag,
                checked: isChecked,
                disabled: isDisabled,
            };

            if (isChecked)
                rows = Utils.addToArray(rows, value, arrItem);

            this.setState({
                rows: rows,
            }, () => {
                this.rowClick(index, arrItem);
            });
        }
    }

    isCheckedAllCustomer = (dataParams, values) => {
        let isChecked = false;

        if (dataParams && values) {
            let row = (dataParams && dataParams.row) ? dataParams.row : null;

            if (dataParams.type === JobStepType.Exchange) {
                if (row.inOrOut === InOrOut.OUT) {
                    isChecked = ((values.stepExchangeOutIsCopyService === false) || (values.stepExchangeOutIsCopyService === true)) ? values.stepExchangeOutIsCopyService : false;
                } else if (row.inOrOut === InOrOut.IN) {
                    isChecked = ((values.stepExchangeInIsCopyService === false) || (values.stepExchangeInIsCopyService === true)) ? values.stepExchangeInIsCopyService : false;
                }

            } else if (dataParams.type === JobStepType.OnTheSpot) {
                isChecked = ((values.stepOnTheSpotIsCopyService === false) || (values.stepOnTheSpotIsCopyService === true)) ? values.stepOnTheSpotIsCopyService : false;

            } else if (dataParams.type === JobStepType.Shift) {
                isChecked = ((values.stepShiftIsCopyService === false) || (values.stepShiftIsCopyService === true)) ? values.stepShiftIsCopyService : false;

            } else if (dataParams.type === JobStepType.Put) {
                isChecked = ((values.stepPutIsCopyService === false) || (values.stepPutIsCopyService === true)) ? values.stepPutIsCopyService : false;

            } else if (dataParams.type === JobStepType.Out) {
                isChecked = ((values.stepOutIsCopyService === false) || (values.stepOutIsCopyService === true)) ? values.stepOutIsCopyService : false;

            } else if (dataParams.type === JobStepType.Pull) {
                isChecked = ((values.stepPullIsCopyService === false) || (values.stepPullIsCopyService === true)) ? values.stepPullIsCopyService : false;
            }
        }

        return isChecked;
    }

    getPageNumber = (data, id) => {
        if(data && data.length > 0){
            const index = data.findIndex(item => item.id === id);
            if (index === -1) {
              return -1;
            }
            return Math.floor(index / this.state.clientSideItemsPerPage) + 1;
        } else {
            return -1;
        }
    }
    changePaginationToSelectedItem = () => {
        if(this.state.rows && this.state.rows.length > 0){
            let localServices = this.state.rows.filter(x => (x.item && x.item.customerId > 0) || (x.customerId > 0));
            let globalServices = this.state.rows.filter(x => (x.item && x.item.customerId === null) || (x.customerId === null));
            
            if(this.state.selectedItems && this.state.selectedItems.length > 0){
                let selectedItems = this.state.selectedItems[0];
                
                if(localServices && localServices.length > 0){
                    let pageNumber = this.getPageNumber(localServices, selectedItems.serviceItemId);

                    if(pageNumber != -1){
                        this.setState({
                            clientSideCurrentPageLocal: pageNumber,
                        });
                    }
                }
                
                if(globalServices && globalServices.length > 0){
                    let pageNumber = this.getPageNumber(globalServices, selectedItems.serviceItemId);

                    if(pageNumber != -1){
                        this.setState({
                            clientSideCurrentPageGlobal: pageNumber,
                        });
                    }
                }
            }
        }
    }

    prepareForm = (dataParams) => {
        let row = (dataParams && dataParams.row) ? dataParams.row : null;
        let values = (row && row.values) ? row.values : null;

        let pricings = (values && values.pricings && values.pricings.length > 0) ? values.pricings : [];
        let standardServices = pricings.filter(x => Utils.isStandardService(x));

        let data = {
            ids: null,
            binTypeId: null,
            wasteTypeIds: [],
            standardServices: [],
            serviceItemId: null,
        };

        if (values) {
            if (dataParams.type === JobStepType.Exchange) {
                if (row.inOrOut === InOrOut.OUT) {
                    data.ids = values.stepExchangeOutSelectedIds;
                    data.binTypeId = values.stepExchangeOutBinTypeId;
                    data.wasteTypeIds = [values.stepExchangeOutWasteTypeId];
                    data.serviceItemId = values.stepExchangeOutServiceItemId;

                    data.standardServices = [{
                        binTypeId: values.stepExchangeOutBinTypeId,
                        wasteTypeId: values.stepExchangeOutWasteTypeId,
                        serviceTag: Utils.getServiceTagName(values.stepExchangeOutWasteTypeName, values.stepExchangeOutBinTypeName),
                        serviceItemId: values.stepExchangeOutServiceItemId,
                    }];
                } else if (row.inOrOut === InOrOut.IN) {
                    data.ids = values.stepExchangeInSelectedIds;
                    data.binTypeId = values.stepExchangeInBinTypeId;
                    data.wasteTypeIds = values.stepExchangeInWasteTypeIds;
                    data.serviceItemId = values.stepExchangeInServiceItemId;

                    let wasteTypeName = values.stepExchangeInWasteTypeIds ? values.customFormParams.stepExchangeInWasteTypeItems.filter(item => item.value == values.stepExchangeInWasteTypeIds[0]).map(x => x.title).join(', ') : '';
                    data.standardServices = (values.isBillable) ? standardServices : [{
                        binTypeId: values.stepExchangeInBinTypeId,
                        wasteTypeId: values.stepExchangeInWasteTypeId,
                        serviceTag: Utils.getServiceTagName(wasteTypeName, values.stepExchangeInBinTypeName),
                        serviceItemId: values.stepExchangeInServiceItemId,
                    }];
                }

            } else if (dataParams.type === JobStepType.OnTheSpot) {
                data.ids = values.stepOnTheSpotSelectedIds;
                data.binTypeId = values.stepOnTheSpotBinTypeId;
                data.wasteTypeIds = values.stepOnTheSpotWasteTypeIds;
                data.serviceItemId = values.stepOnTheSpotServiceItemId;

                let wasteTypeName = values.stepOnTheSpotWasteTypeIds ? values.customFormParams.stepOnTheSpotWasteTypeItems.filter(item => item.value == values.stepOnTheSpotWasteTypeIds[0]).map(x => x.title).join(', ') : '';
                data.standardServices = (values.isBillable) ? standardServices : [{
                    binTypeId: values.stepOnTheSpotBinTypeId,
                    wasteTypeId: values.stepOnTheSpotWasteTypeId,
                    serviceTag: Utils.getServiceTagName(wasteTypeName, values.stepOnTheSpotBinTypeName),
                    serviceItemId: values.stepOnTheSpotServiceItemId,
                }];

            } else if (dataParams.type === JobStepType.Shift) {
                data.ids = values.stepShiftSelectedIds;
                data.binTypeId = values.stepShiftBinTypeId;
                data.wasteTypeIds = values.stepShiftWasteTypeIds;
                data.serviceItemId = values.stepShiftServiceItemId;

                let wasteTypeName = values.stepShiftWasteTypeIds ? values.customFormParams.stepShiftWasteTypeItems.filter(item => item.value == values.stepShiftWasteTypeIds[0]).map(x => x.title).join(', ') : '';
                data.standardServices = (values.isBillable) ? standardServices : [{
                    binTypeId: values.stepShiftBinTypeId,
                    wasteTypeId: values.stepShiftWasteTypeId,
                    serviceTag: Utils.getServiceTagName(wasteTypeName, values.stepShiftBinTypeName),
                    serviceItemId: values.stepShiftServiceItemId,
                }];

            } else if (dataParams.type === JobStepType.Put) {
                data.ids = values.stepPutSelectedIds;
                data.binTypeId = values.stepPutBinTypeId;
                data.wasteTypeIds = [values.stepPutWasteTypeId];
                data.serviceItemId = values.stepPutServiceItemId;

                data.standardServices = (values.isBillable) ? standardServices : [{
                    binTypeId: values.stepPutBinTypeId,
                    wasteTypeId: values.stepPutWasteTypeId,
                    serviceTag: Utils.getServiceTagName(values.stepPutWasteTypeName, values.stepPutBinTypeName),
                    serviceItemId: values.stepPutServiceItemId,
                }];

            } else if (dataParams.type === JobStepType.Out) {
                data.ids = values.stepOutSelectedIds;
                data.binTypeId = values.stepOutBinTypeId;
                data.wasteTypeIds = [values.stepOutWasteTypeId];
                data.serviceItemId = values.stepOutServiceItemId;

                data.standardServices = (values.isBillable) ? standardServices : [{
                    binTypeId: values.stepOutBinTypeId,
                    wasteTypeId: values.stepOutWasteTypeId,
                    serviceTag: Utils.getServiceTagName(values.stepOutWasteTypeName, values.stepOutBinTypeName),
                    serviceItemId: values.stepOutServiceItemId,
                }];

            } else if (dataParams.type === JobStepType.Pull) {
                data.ids = values.stepPullSelectedIds;
                data.binTypeId = values.stepPullBinTypeId;
                data.wasteTypeIds = values.stepPullWasteTypeIds;
                data.serviceItemId = values.stepPullServiceItemId;

                let wasteTypeName = values.stepPullWasteTypeIds ? values.customFormParams.stepPullWasteTypeItems.filter(item => item.value == values.stepPullWasteTypeIds[0]).map(x => x.title).join(', ') : '';
                data.standardServices = (values.isBillable) ? standardServices : [{
                    binTypeId: values.stepPullBinTypeId,
                    wasteTypeId: values.stepPullWasteTypeId,
                    serviceTag: Utils.getServiceTagName(wasteTypeName, values.stepPullBinTypeName),
                    serviceItemId: values.stepPullServiceItemId,
                }];
            }
        }

        return data;
    }
    prepareData = async (refFormik) => {
        if (refFormik && refFormik.current) {
            let {
                values,
                setFieldValue,
            } = refFormik.current;

            let selectedItems = this.state.selectedItems;
            let serviceItemIds = (selectedItems && selectedItems.length > 0) ? selectedItems.map(x => (x && x.serviceItemId) ? x.serviceItemId : null) : [];

            await setFieldValue('serviceItemIds', serviceItemIds);

            if (selectedItems && selectedItems.length > 0) {
                let item = selectedItems[0];
                let selectedCustomerId = (item && item.item && item.item.customerId) ? item.item.customerId : null;

                let useBinWeightQuantity = (item && item.item) ? item.item.useBinWeightQuantity : false;
                let bundleServices = (item && item.item && item.item.bundleServices && item.item.bundleServices.length > 0) ? item.item.bundleServices : [];
                let useBinWeightQuantityItem = (bundleServices && bundleServices.length > 0) ? bundleServices.find(x => x.useBinWeightQuantity == true) : null;
                let nonBillableBinWeight = '0';
                if(useBinWeightQuantity){
                    nonBillableBinWeight = (item && item.item && item.item.nonBillableWeight) ? item.item.nonBillableWeight : '0';
                } else {
                    nonBillableBinWeight = (useBinWeightQuantityItem && useBinWeightQuantityItem.nonBillableWeight) ? useBinWeightQuantityItem.nonBillableWeight : '0';
                }

                // Convert from billing uom to default uom
                nonBillableBinWeight = nonBillableBinWeight != '0'
                    ? Utils.parseFloat(Utils.getBillingUOM() == Utils.getUOM() ? nonBillableBinWeight : Utils.convertUom(nonBillableBinWeight, Utils.getBillingUOM(), Utils.getUOM()))
                    : nonBillableBinWeight;

                await setFieldValue('nonBillableBinWeight', nonBillableBinWeight);


                if (this.state.dataParams.type === JobStepType.Exchange) {
                    if (this.state.row.inOrOut === InOrOut.OUT) {
                        await setFieldValue('stepExchangeOutServiceItemId', item.serviceItemId);
                        await setFieldValue('stepExchangeOutBinTypeId', item.binTypeId);
                        await setFieldValue('stepExchangeOutBinTypeName', item.binTypeName);
                        await setFieldValue('stepExchangeOutBinTypeItem', item);
                        await setFieldValue('stepExchangeOutIsCopyService', Utils.checkIsCopyServiceItem(values, item));

                        let wasteTypeId = (item && item.wasteTypeId) ? item.wasteTypeId : null;
                        let wasteTypeName = (item && item.wasteTypeName) ? item.wasteTypeName : '';

                        await setFieldValue('stepExchangeOutWasteTypeId', wasteTypeId);
                        await setFieldValue('stepExchangeOutWasteTypeName', wasteTypeName);

                        let customId = Utils.makeIDfromString(((item.serviceItemId) ? item.serviceItemId : '') + item.binTypeId + "_" + item.binTypeName + "_" + item.wasteTypeId + "_" + item.wasteTypeName + "_" + item.serviceTag);
                        await setFieldValue('stepExchangeOutSelectedIds', [customId]);
                        await setFieldValue('stepExchangeOutIsServiceOrHistory', this.state.isServiceOrHistory);

                        let binNumber = (item && item.binNumber) ? item.binNumber : '';
                        if (binNumber && binNumber != '') {
                            await setFieldValue('stepExchangeOutBinNumber', binNumber);
                        }

                        await setFieldValue('customFormParams.isSameAsBinOut', Utils.isSameAsBinOut({
                            stepExchangeOutBinTypeId: item.binTypeId,
                            stepExchangeOutBinNumber: binNumber,
                            stepExchangeOutWasteTypeId: wasteTypeId,
                            stepExchangeInBinTypeId: values.stepExchangeInBinTypeId,
                            stepExchangeInBinNumber: values.stepExchangeInBinNumber,
                            stepExchangeInWasteTypeIds: values.stepExchangeInWasteTypeIds,
                        }));

                    } else if (this.state.row.inOrOut === InOrOut.IN) {
                        let wasteTypeIds = selectedItems.map(x => x.wasteTypeId);

                        let customId = Utils.makeIDfromString(item.serviceItemId + item.binTypeId + "_" + item.binTypeName + "_" + item.wasteTypeId + "_" + item.wasteTypeName + "_" + item.serviceTag);

                        let wasteTypeId = (item && item.wasteTypeId) ? item.wasteTypeId : null;
                        let binNumber = (item && item.binNumber) ? item.binNumber : '';

                        let customIds = [];
                        let stepExchangeInBinWasteTypeArr = [];
                        for (let i = 0; i < selectedItems.length; i++) {
                            let x = selectedItems[i];

                            let customId = Utils.makeIDfromString(((x.serviceItemId) ? x.serviceItemId : '') + x.binTypeId + "_" + x.binTypeName + "_" + x.wasteTypeId + "_" + x.wasteTypeName + "_" + x.serviceTag);
                            customIds = Utils.addToArr(customIds, customId);

                            let newItem = {
                                value: x.wasteTypeId,
                                title: x.wasteTypeName,
                                item: {
                                    binTypeId: x.binTypeId,
                                    binTypeName: x.binTypeName,
                                    wasteTypeId: x.wasteTypeId,
                                    wasteTypeName: x.wasteTypeName,
                                    serviceTag: '',
                                    binOutDate: null,
                                    binNumber: x.binNumber,
                                    driverName: '',
                                    vehicleName: '',
                                },
                                binTypeId: x.binTypeId,
                                binTypeName: x.binTypeName,
                                wasteTypeId: x.wasteTypeId,
                                wasteTypeName: x.wasteTypeName,
                                serviceTag: '',
                                binOutDate: null,
                                binNumber: x.binNumber,
                                driverName: '',
                                vehicleName: '',
                            };

                            stepExchangeInBinWasteTypeArr = Utils.addToArray(stepExchangeInBinWasteTypeArr, x.wasteTypeId, newItem);
                        }

                        await setFieldValue('stepExchangeInServiceItemId', item.serviceItemId);
                        await setFieldValue('stepExchangeInSelectedIds', customIds);
                        await setFieldValue('stepExchangeInIsServiceOrHistory', this.state.isServiceOrHistory);
                        await setFieldValue('stepExchangeInWasteTypeIds', wasteTypeIds);
                        await setFieldValue('customFormParams.stepExchangeInWasteTypeItems', stepExchangeInBinWasteTypeArr);
                        await setFieldValue('stepExchangeInIsCopyService', Utils.checkIsCopyServiceItem(values, item));

                        await setFieldValue('customFormParams.stepExchangeInBinWasteTypeId', customId);
                        await setFieldValue('stepExchangeInBinTypeId', item.binTypeId);
                        await setFieldValue('stepExchangeInBinTypeName', item.binTypeName);

                        if (binNumber && binNumber != '') {
                            await setFieldValue('stepExchangeInBinNumber', binNumber);
                        }

                        await setFieldValue('customFormParams.isSameAsBinOut', Utils.isSameAsBinOut({
                            stepExchangeOutBinTypeId: values.stepExchangeOutBinTypeId,
                            stepExchangeOutBinNumber: values.stepExchangeOutBinNumber,
                            stepExchangeOutWasteTypeId: values.stepExchangeOutWasteTypeId,
                            stepExchangeInBinTypeId: item.binTypeId,
                            stepExchangeInBinNumber: binNumber,
                            stepExchangeInWasteTypeIds: wasteTypeIds,
                        }));

                        if (this.props.onChangeBinType) {
                            this.props.onChangeBinType(selectedCustomerId, this.state.row.customerSiteId, item.binTypeId, selectedItems, this.state.isServiceOrHistory);
                        }
                    }

                } else if (this.state.dataParams.type === JobStepType.OnTheSpot) {
                    let wasteTypeIds = selectedItems.map(x => x.wasteTypeId);

                    let customId = Utils.makeIDfromString(item.serviceItemId + item.binTypeId + "_" + item.binTypeName + "_" + item.wasteTypeId + "_" + item.wasteTypeName + "_" + item.serviceTag);

                    let wasteTypeId = (item && item.wasteTypeId) ? item.wasteTypeId : null;
                    let binNumber = (item && item.binNumber) ? item.binNumber : '';

                    let customIds = [];
                    let stepOnTheSpotBinWasteTypeArr = [];
                    for (let i = 0; i < selectedItems.length; i++) {
                        let x = selectedItems[i];

                        let customId = Utils.makeIDfromString(((x.serviceItemId) ? x.serviceItemId : '') + x.binTypeId + "_" + x.binTypeName + "_" + x.wasteTypeId + "_" + x.wasteTypeName + "_" + x.serviceTag);
                        customIds = Utils.addToArr(customIds, customId);

                        let newItem = {
                            value: x.wasteTypeId,
                            title: x.wasteTypeName,
                            item: {
                                binTypeId: x.binTypeId,
                                binTypeName: x.binTypeName,
                                wasteTypeId: x.wasteTypeId,
                                wasteTypeName: x.wasteTypeName,
                                serviceTag: '',
                                binOutDate: null,
                                binNumber: x.binNumber,
                                driverName: '',
                                vehicleName: '',
                            },
                            binTypeId: x.binTypeId,
                            binTypeName: x.binTypeName,
                            wasteTypeId: x.wasteTypeId,
                            wasteTypeName: x.wasteTypeName,
                            serviceTag: '',
                            binOutDate: null,
                            binNumber: x.binNumber,
                            driverName: '',
                            vehicleName: '',
                        };

                        stepOnTheSpotBinWasteTypeArr = Utils.addToArray(stepOnTheSpotBinWasteTypeArr, x.wasteTypeId, newItem);
                    }

                    await setFieldValue('stepOnTheSpotServiceItemId', item.serviceItemId);
                    await setFieldValue('stepOnTheSpotSelectedIds', customIds);
                    await setFieldValue('stepOnTheSpotIsServiceOrHistory', this.state.isServiceOrHistory);
                    await setFieldValue('stepOnTheSpotWasteTypeIds', wasteTypeIds);
                    await setFieldValue('customFormParams.stepOnTheSpotWasteTypeItems', stepOnTheSpotBinWasteTypeArr);
                    await setFieldValue('stepOnTheSpotIsCopyService', Utils.checkIsCopyServiceItem(values, item));

                    await setFieldValue('customFormParams.stepOnTheSpotBinWasteTypeId', customId);
                    await setFieldValue('stepOnTheSpotBinTypeId', item.binTypeId);
                    await setFieldValue('stepOnTheSpotBinTypeName', item.binTypeName);

                    if (binNumber && binNumber != '') {
                        await setFieldValue('stepOnTheSpotBinNumber', binNumber);
                    }

                    if (this.props.onChangeBinType) {
                        this.props.onChangeBinType(selectedCustomerId, this.state.row.customerSiteId, item.binTypeId, selectedItems, this.state.isServiceOrHistory);
                    }

                } else if (this.state.dataParams.type === JobStepType.Shift) {
                    let wasteTypeIds = selectedItems.map(x => x.wasteTypeId);

                    let customId = Utils.makeIDfromString(item.serviceItemId + item.binTypeId + "_" + item.binTypeName + "_" + item.wasteTypeId + "_" + item.wasteTypeName + "_" + item.serviceTag);

                    let wasteTypeId = (item && item.wasteTypeId) ? item.wasteTypeId : null;
                    let binNumber = (item && item.binNumber) ? item.binNumber : '';

                    let customIds = [];
                    let stepShiftBinWasteTypeArr = [];
                    for (let i = 0; i < selectedItems.length; i++) {
                        let x = selectedItems[i];

                        let customId = Utils.makeIDfromString(((x.serviceItemId) ? x.serviceItemId : '') + x.binTypeId + "_" + x.binTypeName + "_" + x.wasteTypeId + "_" + x.wasteTypeName + "_" + x.serviceTag);
                        customIds = Utils.addToArr(customIds, customId);

                        let newItem = {
                            value: x.wasteTypeId,
                            title: x.wasteTypeName,
                            item: {
                                binTypeId: x.binTypeId,
                                binTypeName: x.binTypeName,
                                wasteTypeId: x.wasteTypeId,
                                wasteTypeName: x.wasteTypeName,
                                serviceTag: '',
                                binOutDate: null,
                                binNumber: x.binNumber,
                                driverName: '',
                                vehicleName: '',
                            },
                            binTypeId: x.binTypeId,
                            binTypeName: x.binTypeName,
                            wasteTypeId: x.wasteTypeId,
                            wasteTypeName: x.wasteTypeName,
                            serviceTag: '',
                            binOutDate: null,
                            binNumber: x.binNumber,
                            driverName: '',
                            vehicleName: '',
                        };

                        stepShiftBinWasteTypeArr = Utils.addToArray(stepShiftBinWasteTypeArr, x.wasteTypeId, newItem);
                    }

                    await setFieldValue('stepShiftServiceItemId', item.serviceItemId);
                    await setFieldValue('stepShiftSelectedIds', customIds);
                    await setFieldValue('stepShiftIsServiceOrHistory', this.state.isServiceOrHistory);
                    await setFieldValue('stepShiftWasteTypeIds', wasteTypeIds);
                    await setFieldValue('customFormParams.stepShiftWasteTypeItems', stepShiftBinWasteTypeArr);
                    await setFieldValue('stepShiftIsCopyService', Utils.checkIsCopyServiceItem(values, item));

                    await setFieldValue('customFormParams.stepShiftBinWasteTypeId', customId);
                    await setFieldValue('stepShiftBinTypeId', item.binTypeId);
                    await setFieldValue('stepShiftBinTypeName', item.binTypeName);

                    if (binNumber && binNumber != '') {
                        await setFieldValue('stepShiftBinNumber', binNumber);
                    }

                    if (this.props.onChangeBinType) {
                        this.props.onChangeBinType(selectedCustomerId, this.state.row.customerSiteId, item.binTypeId, selectedItems, this.state.isServiceOrHistory);
                    }

                } else if (this.state.dataParams.type === JobStepType.Put) {
                    await setFieldValue('stepPutServiceItemId', item.serviceItemId);
                    await setFieldValue('stepPutBinTypeId', item.binTypeId);
                    await setFieldValue('stepPutBinTypeName', item.binTypeName);
                    await setFieldValue('stepPutIsCopyService', Utils.checkIsCopyServiceItem(values, item));

                    let wasteTypeId = (item && item.wasteTypeId) ? item.wasteTypeId : null;
                    let wasteTypeName = (item && item.wasteTypeName) ? item.wasteTypeName : '';

                    await setFieldValue('stepPutWasteTypeId', wasteTypeId);
                    await setFieldValue('stepPutWasteTypeName', wasteTypeName);

                    let customId = Utils.makeIDfromString(((item.serviceItemId) ? item.serviceItemId : '') + item.binTypeId + "_" + item.binTypeName + "_" + item.wasteTypeId + "_" + item.wasteTypeName + "_" + item.serviceTag);
                    await setFieldValue('stepPutSelectedIds', [customId]);
                    await setFieldValue('stepPutIsServiceOrHistory', this.state.isServiceOrHistory);

                    let binNumber = (item && item.binNumber) ? item.binNumber : '';

                    if (binNumber && binNumber != '') {
                        await setFieldValue('stepPutBinNumber', binNumber);
                    }

                    if (this.props.onChangeBinType) {
                        this.props.onChangeBinType(selectedCustomerId, this.state.row.customerSiteId, item.binTypeId, selectedItems, this.state.isServiceOrHistory);
                    }

                } else if (this.state.dataParams.type === JobStepType.Out) {
                    await setFieldValue('stepOutServiceItemId', item.serviceItemId);
                    await setFieldValue('stepOutBinTypeId', item.binTypeId);
                    await setFieldValue('stepOutBinTypeName', item.binTypeName);
                    await setFieldValue('stepOutIsCopyService', Utils.checkIsCopyServiceItem(values, item));

                    let wasteTypeId = (item && item.wasteTypeId) ? item.wasteTypeId : null;
                    let wasteTypeName = (item && item.wasteTypeName) ? item.wasteTypeName : '';

                    await setFieldValue('stepOutWasteTypeId', wasteTypeId);
                    await setFieldValue('stepOutWasteTypeName', wasteTypeName);

                    let customId = Utils.makeIDfromString(((item.serviceItemId) ? item.serviceItemId : '') + item.binTypeId + "_" + item.binTypeName + "_" + item.wasteTypeId + "_" + item.wasteTypeName + "_" + item.serviceTag);
                    await setFieldValue('stepOutSelectedIds', [customId]);
                    await setFieldValue('stepOutIsServiceOrHistory', this.state.isServiceOrHistory);

                    let binNumber = (item && item.binNumber) ? item.binNumber : '';

                    if (binNumber && binNumber != '') {
                        await setFieldValue('stepOutBinNumber', binNumber);
                    }

                    if (this.props.onChangeBinType) {
                        this.props.onChangeBinType(selectedCustomerId, this.state.row.customerSiteId, item.binTypeId, selectedItems, this.state.isServiceOrHistory);
                    }

                } else if (this.state.dataParams.type === JobStepType.Pull) {
                    let wasteTypeIds = selectedItems.map(x => x.wasteTypeId);

                    let customId = Utils.makeIDfromString(item.serviceItemId + item.binTypeId + "_" + item.binTypeName + "_" + item.wasteTypeId + "_" + item.wasteTypeName + "_" + item.serviceTag);

                    let wasteTypeId = (item && item.wasteTypeId) ? item.wasteTypeId : null;
                    let binNumber = (item && item.binNumber) ? item.binNumber : '';

                    let customIds = [];
                    let stepPullBinWasteTypeArr = [];
                    for (let i = 0; i < selectedItems.length; i++) {
                        let x = selectedItems[i];

                        let customId = Utils.makeIDfromString(((x.serviceItemId) ? x.serviceItemId : '') + x.binTypeId + "_" + x.binTypeName + "_" + x.wasteTypeId + "_" + x.wasteTypeName + "_" + x.serviceTag);
                        customIds = Utils.addToArr(customIds, customId);

                        let newItem = {
                            value: x.wasteTypeId,
                            title: x.wasteTypeName,
                            item: {
                                binTypeId: x.binTypeId,
                                binTypeName: x.binTypeName,
                                wasteTypeId: x.wasteTypeId,
                                wasteTypeName: x.wasteTypeName,
                                serviceTag: '',
                                binOutDate: null,
                                binNumber: x.binNumber,
                                driverName: '',
                                vehicleName: '',
                            },
                            binTypeId: x.binTypeId,
                            binTypeName: x.binTypeName,
                            wasteTypeId: x.wasteTypeId,
                            wasteTypeName: x.wasteTypeName,
                            serviceTag: '',
                            binOutDate: null,
                            binNumber: x.binNumber,
                            driverName: '',
                            vehicleName: '',
                        };

                        stepPullBinWasteTypeArr = Utils.addToArray(stepPullBinWasteTypeArr, x.wasteTypeId, newItem);
                    }

                    await setFieldValue('stepPullServiceItemId', item.serviceItemId);
                    await setFieldValue('stepPullSelectedIds', customIds);
                    await setFieldValue('stepPullIsServiceOrHistory', this.state.isServiceOrHistory);
                    await setFieldValue('stepPullWasteTypeIds', wasteTypeIds);
                    await setFieldValue('customFormParams.stepPullWasteTypeItems', stepPullBinWasteTypeArr);
                    await setFieldValue('stepPullIsCopyService', Utils.checkIsCopyServiceItem(values, item));

                    await setFieldValue('customFormParams.stepPullBinWasteTypeId', customId);
                    await setFieldValue('stepPullBinTypeId', item.binTypeId);
                    await setFieldValue('stepPullBinTypeName', item.binTypeName);

                    if (binNumber && binNumber != '') {
                        await setFieldValue('stepPullBinNumber', binNumber);
                    }

                    if (this.props.onChangeBinType) {
                        this.props.onChangeBinType(selectedCustomerId, this.state.row.customerSiteId, item.binTypeId, selectedItems, this.state.isServiceOrHistory);
                    }
                }
            }
        }
    }
    
    clientSideRows = (rows = [], currentPage = 1) => {
        let data = (rows && rows.length > 0) ? rows : [];

        const indexOfLastItem = currentPage * this.state.clientSideItemsPerPage;
        const indexOfFirstItem = indexOfLastItem - this.state.clientSideItemsPerPage;
        const currentData = data.slice(indexOfFirstItem, indexOfLastItem);

        return (currentData && currentData.length > 0) ? currentData : [];
    }
    clientSideSearch = (state = true) => {
        let allRows = (this.state.arrService && this.state.arrService.length > 0) ? _.cloneDeep(this.state.arrService) : [];
        
        if(this.state.search != ''){
            let filteredRows = (this.state.arrService && this.state.arrService.length > 0) ? allRows.filter(x => x.serviceTag.toLowerCase().trim().includes(this.state.search.toLowerCase().trim())) : [];
            
            this.setState({
                rows: filteredRows,
                clientSideCurrentPageLocal: 1,
                clientSideCurrentPageGlobal: 1,
                isLoading: false,
            });
        } else {
            this.setState({
                rows: allRows,
                clientSideCurrentPageLocal: 1,
                clientSideCurrentPageGlobal: 1,
            }, () => {
                this.setState({
                    isLoading: false,
                }, () => {
                    if(state){
                        this.callServiceItemsReadApi((arr) => {
                            this.changePaginationToSelectedItem();
                        });
                    }
                });
            });
        }
    }
    /* END FUNCTIONS */


    /* SECTIONS */
    setDataTableServiceItems = () => {
        let localServices = this.state.rows.filter(x => (x.item && x.item.customerId > 0) || (x.customerId > 0));
        let localServiceRows = this.clientSideRows(localServices, this.state.clientSideCurrentPageLocal);
        
        let globalServices = this.state.rows.filter(x => (x.item && x.item.customerId === null) || (x.customerId === null));
        let globalServiceRows = this.clientSideRows(globalServices, this.state.clientSideCurrentPageGlobal);

        return <>
            {(localServiceRows && localServiceRows.length > 0) && <StyledTable className={'no-responsive-table mb-5'}>
                <thead>
                    <tr>
                        <th colSpan={this.state.includeLocalService ? 5 : 4} className={'caption'}>
                            <span>LOCAL SERVICE</span>
                            <CustomTooltip placement={'right'} title={'A Local Service is unique and applies to single customer.'}>
                                <InfoOutlinedIcon style={{ color: '#DFDFDF', width: '30px', height: '25px' }} />
                            </CustomTooltip>
                        </th>
                    </tr>
                    <tr className={'d-none d-md-table-row header-row'}>
                        <th width={'10%'}>&nbsp;</th>
                        {this.state.includeLocalService && <th width={'20%'}>Customer Name</th>}
                        <th width={this.state.includeLocalService ? '20%' : '30%'}>Name</th>
                        <th width={this.state.includeLocalService ? '25%' : '30%'}>Bin Type</th>
                        <th width={this.state.includeLocalService ? '25%' : '30%'}>Waste Type</th>
                    </tr>
                </thead>
                <tbody>
                    {localServiceRows.map((row, i) => {
                        return <tr key={row.value}
                            className={(row.disabled) ? 'disabled' : ''}
                            onClick={(e) => {
                                this.rowClick(row.id, row);
                            }}
                        >
                            <td>{this.colRadioTemplate(row, i)}</td>
                            {this.state.includeLocalService && <td>{(row && row.item && row.item.customerName) ? row.item.customerName : ''}</td>}
                            <td>{row.serviceTag}</td>
                            <td>{row.binTypeName}</td>
                            <td>{row.wasteTypeName}</td>
                        </tr>
                    })}
                </tbody>
            </StyledTable>}

            {(localServiceRows && localServiceRows.length > 0) && <div className="mb-10">
                <Pagination 
                    className={'v-center'}
                    count={Utils.getPaginationCount(localServices.length, this.state.clientSideItemsPerPage)} 
                    page={this.state.clientSideCurrentPageLocal} 
                    size={'small'}
                    boundaryCount={2} 
                    showFirstButton
                    showLastButton
                    onChange={(event, value) => {
                        this.setState({
                            clientSideCurrentPageLocal: value,
                        });
                    }}
                />    
            </div>}


            <StyledTable className={'no-responsive-table mb-5'}>
                <thead>
                    <tr>
                        {/* <th colSpan={this.state.includeLocalService ? 5 : 4} className={'caption'}> */}
                        <th colSpan={4} className={'caption'}>
                            <span>GLOBAL SERVICE</span>
                            <CustomTooltip placement={'right'} title={'Global service is a service shared among all customers. '}>
                                <InfoOutlinedIcon style={{ color: '#DFDFDF', width: '30px', height: '25px' }} />
                            </CustomTooltip>
                        </th>
                    </tr>
                    <tr className={'d-none d-md-table-row header-row'}>
                        <th width={'10%'}>&nbsp;</th>
                        {/* {this.state.includeLocalService && <th width={'20%'}>Customer Name</th>} */}
                        <th width={this.state.includeLocalService ? '20%' : '30%'}>Name</th>
                        <th width={this.state.includeLocalService ? '25%' : '30%'}>Bin Type</th>
                        <th width={this.state.includeLocalService ? '25%' : '30%'}>Waste Type</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (globalServiceRows && globalServiceRows.length > 0)
                            ?
                            globalServiceRows.map((row, i) => {
                                return <tr key={row.value}
                                    className={(row.disabled) ? 'disabled' : ''}
                                    onClick={(e) => {
                                        this.rowClick(row.id, row);
                                    }}
                                >
                                    <td>{this.colRadioTemplate(row, i)}</td>
                                    {/* {this.state.includeLocalService && <td>{(row && row.item && row.item.customerName) ? row.item.customerName : ''}</td>} */}
                                    <td>{row.serviceTag}</td>
                                    <td>{row.binTypeName}</td>
                                    <td>{row.wasteTypeName}</td>
                                </tr>
                            })
                            :
                            <tr colSpan={4}>
                                <td>No Items!</td>
                            </tr>
                    }
                </tbody>
            </StyledTable>

            <div className="mb-10">
                <Pagination 
                    className={'v-center'}
                    count={Utils.getPaginationCount(globalServices.length, this.state.clientSideItemsPerPage)} 
                    page={this.state.clientSideCurrentPageGlobal} 
                    size={'small'}
                    boundaryCount={2} 
                    showFirstButton
                    showLastButton
                    onChange={(event, value) => {
                        this.setState({
                            clientSideCurrentPageGlobal: value,
                        });
                    }}
                />    
            </div>
        </>
    }

    setDataTableHistoryItems = () => {
        return <>
            {(this.state.rows && this.state.rows.filter(x => x.item.customerId > 0).length > 0) && <StyledTable className={'no-responsive-table mb-10'}>
                <thead>
                    {/* <tr>
                        <th colSpan={4} className={'caption'}>
                            <span>HISTORY</span>
                            <CustomTooltip placement={'right'} title={'A Local Service is unique and applies to single customer.'}>
                                <InfoOutlinedIcon style={{ color: '#DFDFDF', width: '30px', height: '25px' }} />
                            </CustomTooltip>
                        </th>
                    </tr> */}
                    <tr className={'d-none d-md-table-row header-row'}>
                        <th width={'5%'}>&nbsp;</th>
                        <th width={'15%'}>Complete date</th>
                        <th width={'15%'}>Bin type</th>
                        <th width={'15%'}>For Waste Type</th>
                        <th width={'15%'}>Bin ID</th>
                        <th width={'15%'}>DO number</th>
                        <th width={'15%'}>Driver</th>
                        <th width={'15%'}>Vehicle</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.rows.filter(x => x.item.customerId > 0).map((row, i) => {
                        return <tr key={row.value}
                            className={(row.disabled) ? 'disabled' : ''}
                            onClick={(e) => {
                                this.rowClick(row.id, row);
                            }}
                        >
                            <td>{this.colRadioTemplate(row, i)}</td>
                            <td>{(row.binOutDate) ? moment(row.binOutDate).format(Utils.getDefaultDateFormat()) : ''}</td>
                            <td>{row.binTypeName}</td>
                            <td>{row.wasteTypeName}</td>
                            <td>{row.binNumber}</td>
                            <td>{row.jobNumber}</td>
                            <td>{row.driverName}</td>
                            <td>{row.vehicleName}</td>
                        </tr>
                    })}
                </tbody>
            </StyledTable>}
        </>
    }
    /* END SECTIONS */


    body = () => {
        let {
            values,
        } = this.props.mainFormOptions;

        return <Row>
            <Col xs={12} className={'pb-5'}>
                <Row className={'align-items-center'}>
                    <Col xs={true} md={3}>
                        <StyledInputGroup>
                            <Button
                                className={'search-button'}
                                variant={'link'}
                                disabled={(this.props.isLoading || this.state.isLoading)}
                                onClick={() => {
                                    if (!this.state.isServiceOrHistory) {
                                        // this.callServiceItemsReadApi();
                                        this.clientSideSearch();
                                    } else {
                                        this.callBinTypeHistoryReadApi();
                                    }
                                }}
                            >
                                <i className={'material-icons'}>search</i>
                            </Button>

                            <Form.Control
                                className={'search-input'}
                                type="text"
                                id={this.name + '-search'}
                                placeholder={'Search'}
                                value={this.state.search}
                                onChange={(e) => {
                                    this.setState({
                                        search: e.target.value,
                                    });
                                }}
                                onKeyDown={(keyEvent) => {
                                    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                                        if (!this.state.isServiceOrHistory) {
                                            // this.callServiceItemsReadApi();
                                            this.clientSideSearch();
                                        } else {
                                            this.callBinTypeHistoryReadApi();
                                        }
                                    }
                                }}
                            />
                            {(this.state.search !== '') && <Button
                                className={'search-clear'}
                                variant={'link'}
                                style={{
                                    position: 'absolute',
                                    right: '10px',
                                    zIndex: 10,
                                }}
                                onClick={() => {
                                    this.setState({
                                        search: '',
                                    }, () => {
                                        if (!this.state.isServiceOrHistory) {
                                            // this.callServiceItemsReadApi();
                                            this.clientSideSearch();
                                        } else {
                                            this.callBinTypeHistoryReadApi();
                                        }
                                    });
                                }}
                            >
                                <i class="fa fa-times"></i>
                            </Button>}
                        </StyledInputGroup>
                        {!this.state.isServiceOrHistory && <Form.Check
                            id={'includeLocalService_id'}
                            className={'align-content-center mt-3'}
                            label={'Search from all customers'}
                            disabled={(this.props.isLoading || this.state.isLoading)}
                            checked={this.state.includeLocalService}
                            onChange={(e) => {
                                this.setState({
                                    includeLocalService: e.target.checked,
                                }, () => {
                                    if (!this.state.isServiceOrHistory) {
                                        if (e.target.checked) {
                                            this.callAllServiceItemsReadApi((arr) => {
                                                this.clientSideSearch(false);
                                                this.changePaginationToSelectedItem();
                                            });
                                        } else {
                                            this.callServiceItemsReadApi();
                                        }
                                    } else {
                                        this.callBinTypeHistoryReadApi();
                                    }
                                });
                            }}
                        />}
                    </Col>
                    <Col xs={true}></Col>
                    {!this.state.isServiceOrHistory && <Col xs={true} md={'auto'}>
                        <Button
                            size={'sm'}
                            onClick={(e) => {
                                if (this.pageRef && this.pageRef.current) {
                                    this.pageRef.current.click();
                                }

                                if (this.props.onCreateNewService) {
                                    this.props.onCreateNewService();
                                }
                            }}
                        >
                            <span className={'ps-3 pe-3'}>+ New Local Service</span>
                        </Button>
                    </Col>}
                </Row>
            </Col>

            {(!this.props.isLoading && !this.state.isLoading) ? <Col xs={12} className={'mt-5'}>{!this.state.isServiceOrHistory ? this.setDataTableServiceItems() : this.setDataTableHistoryItems()}</Col> : <Col xs={12} className={'mt-5 text-center'}><h5>Loading ...</h5></Col>}
        </Row>
    }

    footer = () => {
        let {
            values,
        } = this.props.formOptions;

        return <Row>
            <Col xs={'auto'}>
                <Button
                    type={'button'}
                    variant={'secondary'}
                    disabled={this.props.isLoading}
                    onClick={() => {
                        if (this.props.onCancel) {
                            this.props.onCancel();
                        }

                        Utils.showDrawer(this.props.drawer, false);
                    }}
                >
                    Cancel
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
            <Col xs={'auto'}>
                <Button
                    type={'button'}
                    variant={'primary'}
                    disabled={this.props.isLoading || !(this.state.selectedItems && this.state.selectedItems.length > 0)}
                    onClick={() => {
                        if (this.props.onSave) {
                            this.props.onSave(values, this.state.dataParams, async (refFormik) => {
                                await this.prepareData(refFormik);

                                let serviceNames = await Utils.getJobServiceTagName(refFormik.current.values);
                                let newValues = { ...refFormik.current.values, ...serviceNames };
                                refFormik.current.setValues(newValues);
                            });
                        }
                    }}
                >
                    Save
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
        </Row>
    }


    render() {
        return <DrawerContentLayout
            ref={this.bodyRef}
            isBlocking={this.props.isBlocking}
            body={this.body()}
            footer={this.footer()}
        />
    }
}

const mapDispatchToProps = {
    dispatchApiCallGet,
    clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(JobFormBinHistoryDrawer);
