import React from "react";
import styled from 'styled-components';

import {
    Row,
    Col,
    Form,
    Spinner,
    Button,
} from "react-bootstrap-v5";

import { DrawerContentLayout } from '../../../../components/drawer/DrawerLayout'
import Utils from "../../../../utils/utils";
import { BillingTypes, ServiceType, ReportUOM, JobStepType } from "../../../../utils/enums";

import InputLayout from "../../../../components/input/InputLayout";
import RSuiteDatePicker from '../../../../components/OLD/Datepicker/RSuiteDatePicker';
import RSuiteTimePicker from '../../../../components/OLD/TimePicker/RSuiteTimePicker';
import CustomTooltip from '../../../../components/Tooltip/Tooltip';

import { connect } from 'react-redux'
import { clear } from "../../../../../setup/redux/actions";
import { dispatchApiCallGet, dispatchApiCallPost } from '../../../../../setup/redux/dispatch/actions'
import refreshWeighChit from "../../redux/refreshWeighChit";



const StyledTable = styled.table`
    width: 100% !important;

    thead {
        tr {
            th {
                background-color: transparent !important;
                color: #484848 !important;
                border-bottom: 1px solid #DFDFDF !important;
            }
        }
    }

    tbody {
        tr {
            td {
                border-bottom: 0px !important;
                padding-top: 10px;
                padding-bottom: 10px;
                vertical-align: top;

                @media (max-width: 767px) {
                    display: block !important;
                    padding-top: 0px !important;
                    padding-bottom: 0px !important;
                }
            }
        }
    }
`;


class JobFormWeighChitDrawer extends React.Component {

    constructor(props) {
        super(props);

        this.name = 'job_form_weight_chit_drawer';

        this.bodyRef = React.createRef();

        // let dataParams = (props.dataParams) ? props.dataParams : null;
        // let id = (dataParams && dataParams.id) ? dataParams.id : null;


        let billingType = (props && props.billingType) ? props.billingType : BillingTypes.Invoice;


        this.state = {
            billingType: billingType,
        };
    }


    // componentDidMount(){}

    async componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            this.name + '-billing_rofile',
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if (data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.accountBillingProfileId;
                        let title = item.billingProfileName;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if (this.state.isError) {
                        Utils.toast(this.state.err, 'error');
                    }

                    if (callback) {
                        callback(arr);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.name + '-quickBook_credit_terms',
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if (data && data.length > 0) {
                    for (var i = 0; i < data.length; i++) {
                        let item = data[i];
                        let value = item.id;
                        let title = item.name;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if (this.state.isError) {
                        Utils.toast(this.state.err, 'error');
                    }

                    if (callback) {
                        callback(arr);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.name + '-billing_charge_category',
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if (data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.chargeCategoryId;
                        let title = item.chargeCategoryName;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if (this.state.isError) {
                        Utils.toast(this.state.err, 'error');
                    }

                    if (callback) {
                        callback(arr);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.name + '-additional_charge_template',
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if (data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.serviceItemId;
                        let title = item.serviceTag;
                        let arrItem = {
                            value: value,
                            title: title,
                            label: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if (this.state.isError) {
                        Utils.toast(this.state.err, 'error');
                    }

                    if (callback) {
                        callback(arr);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.name + '-get_job_doc',
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if (this.state.isError) {
                        Utils.toast(this.state.err, 'error');
                    }

                    if (callback) {
                        callback(data);
                    }
                });
            }
        );
    }

    callReadBillingProfileApi = (items, callback = null) => {
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            isActive: true,
        };

        this.props.dispatchApiCallGet(data, this.name + '-billing_rofile', 'AccountBillingProfile', { items: items }, callback, () => { });
    }

    callQuickbookReadCreditTermsApi = (quickBookAppId = 0, items, callback = null) => {
        let data = {
            quickBookAppId: quickBookAppId,
        };

        this.props.dispatchApiCallGet(data, this.name + '-quickBook_credit_terms', 'quickbookapp/term', { items: items }, callback, () => { });
    }

    callReadChargeCategoryApi = (items, callback = null) => {
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            isActive: true,
        };

        this.props.dispatchApiCallGet(data, this.name + '-billing_charge_category', 'chargecategory', { items: items }, callback, () => { });
    }

    callReadAdditionalServicesApi = (items, search = '', callback = null) => {
        let values = (this.props.dataParams && this.props.dataParams.row) ? this.props.dataParams.row : null;

        let data = {
            currentPage: 1,
            pageSize: 30,
            searchQuery: search,
            sortColumn: 'serviceTag',
            sortDir: 'desc',
            customerId: values.customerId,
            customerSiteId: values.customerSiteId,
            type: ServiceType.AdditionalService,
        };

        this.props.dispatchApiCallGet(data, this.name + '-additional_charge_template', 'service-item', { items: items }, callback, () => { });
    }

    callDownloadPDFFileApi = (callback = null) => {
        Utils.toast(<div style={{ fontWeight: 'bold' }}>Please Wait ...</div>, 'info');

        this.setState({
            isLoading: true,
        });

        let values = (this.props.dataParams && this.props.dataParams.row) ? this.props.dataParams.row : null;

        let data = {
            jobId: values.jobId,
            includeAttachment: false,
            includePdfAttachmentDeliveryOrder: false,
            includePdfAttachmentPhoto: false,
            includePdfAttachmentWeightChit: true,
        };


        this.props.dispatchApiCallPost(data, this.name + '-get_job_doc', 'job/get-job-doc', null, callback, () => { });
    }
    /* END API */


    body = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;

        let statusName = (this.props.dataParams && this.props.dataParams.row && this.props.dataParams.row.statusName) ? this.props.dataParams.row.statusName : null;

        return <Row className={'p-2 p-md-10 g-10'}>
            <Col xs={12} md={6}>
                <Row>
                    <Col xs={12}>
                        <InputLayout
                            label={this.props.fields.ticketNumber.label}
                        >
                            <Form.Control
                                type="text"
                                id={this.props.fields.ticketNumber.id}
                                placeholder={this.props.fields.ticketNumber.placeholder}
                                value={values.ticketNumber}
                                onChange={handleChange}
                                isInvalid={!!errors.ticketNumber}
                                readOnly={Utils.isRejectedOrCancelledOrFailedOrDeleted(statusName)}
                            />
                            <Form.Control.Feedback type="invalid">{errors.ticketNumber}</Form.Control.Feedback>
                        </InputLayout>
                    </Col>
                    <Col xs={12}>
                        <InputLayout
                            label={this.props.fields.weighBy.label}
                        >
                            <Form.Control
                                type="text"
                                id={this.props.fields.weighBy.id}
                                placeholder={this.props.fields.weighBy.placeholder}
                                value={values.weighBy}
                                onChange={handleChange}
                                isInvalid={!!errors.weighBy}
                                readOnly={Utils.isRejectedOrCancelledOrFailedOrDeleted(statusName)}
                            />
                            <Form.Control.Feedback type="invalid">{errors.weighBy}</Form.Control.Feedback>
                        </InputLayout>
                    </Col>
                    <Col xs={12}>
                        <InputLayout
                            label={this.props.fields.remarks.label}
                        >
                            <Form.Control
                                // as="textarea"
                                // rows={5}
                                type="text"
                                id={this.props.fields.remarks.id}
                                placeholder={this.props.fields.remarks.placeholder}
                                value={values.remarks}
                                onChange={handleChange}
                                isInvalid={!!errors.remarks}
                                readOnly={Utils.isRejectedOrCancelledOrFailedOrDeleted(statusName)}
                            />
                            <Form.Control.Feedback type="invalid">{errors.remarks}</Form.Control.Feedback>
                        </InputLayout>
                    </Col>
                </Row>
            </Col>
            <Col xs={12} md={6}>
                <Row>
                    <Col xs={12}>
                        <InputLayout
                            label={this.props.fields.date.label}
                        >
                            <Form.Control
                                as={RSuiteDatePicker}
                                isInvalid={!!errors.date}

                                placeholder={this.props.fields.date.placeholder}
                                id={this.props.fields.date.id}
                                value={values.date}
                                icon={<></>}
                                placement={'bottomLeft'}
                                cleanable={true}
                                isDefault={true}
                                oneTap={false}
                                showMeridian={true}
                                format={Utils.getImportDateTimeFieldFormat()}
                                onChange={async (value) => {
                                    await setFieldValue('date', value);
                                }}
                                onClear={async () => {
                                    await setFieldValue('date', null);
                                }}
                                readOnly={Utils.isRejectedOrCancelledOrFailedOrDeleted(statusName)}
                            />
                            <Form.Control.Feedback type="invalid">{errors.date}</Form.Control.Feedback>
                        </InputLayout>
                    </Col>
                    <Col xs={12}>
                        <InputLayout
                            label={this.props.fields.weightChitOutDateTime.label}
                        >
                            <Form.Control
                                as={RSuiteDatePicker}
                                isInvalid={!!errors.weightChitOutDateTime}

                                placeholder={this.props.fields.weightChitOutDateTime.placeholder}
                                id={this.props.fields.weightChitOutDateTime.id}
                                value={values.weightChitOutDateTime}
                                icon={<></>}
                                placement={'bottomLeft'}
                                cleanable={true}
                                isDefault={true}
                                oneTap={false}
                                showMeridian={true}
                                format={Utils.getImportDateTimeFieldFormat()}
                                onChange={async (value) => {
                                    await setFieldValue('weightChitOutDateTime', value);
                                }}
                                onClear={async () => {
                                    await setFieldValue('weightChitOutDateTime', null);
                                }}
                                readOnly={Utils.isRejectedOrCancelledOrFailedOrDeleted(statusName)}
                            />
                            <Form.Control.Feedback type="invalid">{errors.weightChitOutDateTime}</Form.Control.Feedback>
                        </InputLayout>
                    </Col>
                    {/* <Col xs={12}>
                        <InputLayout
                            label={this.props.fields.time.label}
                        >
                            <Form.Control
                                as = {RSuiteTimePicker}
                                isInvalid={!!errors.time}
                                
                                id={this.props.fields.time.id}
                                value={values.time}
                                placeholder={this.props.fields.time.placeholder}
                                // hideMinutes={minute => minute % 30 !== 0}
                                placement={'bottomLeft'}
                                onChange={async (value) => {
                                    let dateTime = Utils.convertDateTime(value);
                                    await setFieldValue('time', dateTime);
                                }}
                                disabled={Utils.isRejectedOrCancelledOrFailedOrDeleted(statusName)}
                            />
                            <Form.Control.Feedback type="invalid">{errors.time}</Form.Control.Feedback>
                        </InputLayout>
                    </Col> */}
                </Row>
            </Col>

            <Col xs={12} className={'mt-2'}>
                <Row>
                    {(values.jobStepWeighChitCustomFields && values.jobStepWeighChitCustomFields.length > 0) && <>
                        <Col xs={12} className={'mb-4'}>
                            <h6>Custom fields</h6>
                            <hr style={{ borderTop: '1px solid rgb(157 157 157)' }} />
                        </Col>

                        {values.jobStepWeighChitCustomFields.map((customItem, i) => {
                            if (customItem.accountCustomField && customItem.accountCustomField.label && customItem.accountCustomField.label != '') {
                                return <Col key={i} xs={12} md={6}>
                                    <InputLayout
                                        label={customItem.accountCustomField.label}
                                    >
                                        <Form.Control
                                            type="text"
                                            id={'custom_field_' + customItem.accountCustomFieldId}
                                            placeholder={''}
                                            value={(customItem.value) ? customItem.value : ''}
                                            onChange={async (e) => {
                                                await setFieldValue('jobStepWeighChitCustomFields.' + i + '.value', (e.target.value) ? e.target.value : null);
                                            }}
                                            isInvalid={!!(errors && errors.jobStepWeighChitCustomFields && errors.jobStepWeighChitCustomFields[i] && errors.jobStepWeighChitCustomFields[i].value)}
                                            readOnly={Utils.isRejectedOrCancelledOrFailedOrDeleted(statusName)}
                                        />
                                        <Form.Control.Feedback type="invalid">{(errors && errors.jobStepWeighChitCustomFields && errors.jobStepWeighChitCustomFields[i] && errors.jobStepWeighChitCustomFields[i].value) && errors.jobStepWeighChitCustomFields[i].value}</Form.Control.Feedback>
                                    </InputLayout>
                                </Col>
                            }
                        })}
                    </>}
                </Row>
            </Col>

            <Col xs={12} className={'mt-10'}>
                <StyledTable className={'no-responsive-table'}>
                    <thead>
                        <tr className={'d-none d-md-table-row'}>
                            <th>{this.props.fields.grossWeight.label + ((values.uom === ReportUOM.kg) ? ' (kg)' : ' (tons)')}</th>
                            <th width={'5%'}>{'( - )'}</th>
                            <th>{this.props.fields.tareWeight.label + ((values.uom === ReportUOM.kg) ? ' (kg)' : ' (tons)')}</th>
                            <th width={'5%'}>{'( = )'}</th>
                            <th>{this.props.fields.nettWeight.label + ((values.uom === ReportUOM.kg) ? ' (kg)' : ' (tons)')}</th>
                            <th width={'5%'}>{'( - )'}</th>
                            <th>{this.props.fields.nonBillableBinWeight.label + ((values.uom === ReportUOM.kg) ? ' (kg)' : ' (tons)')}</th>
                            <th width={'5%'}>{'( = )'}</th>
                            <th>{this.props.fields.billableWeight.label + ((values.uom === ReportUOM.kg) ? ' (kg)' : ' (tons)')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <InputLayout
                                    label={this.props.fields.grossWeight.label + ((values.uom === ReportUOM.kg) ? ' (kg)' : ' (tons)')}
                                    labelProps={{
                                        className: 'd-block d-md-none',
                                    }}
                                >
                                    <Form.Control
                                        type="number"
                                        id={this.props.fields.grossWeight.id}
                                        placeholder={this.props.fields.grossWeight.placeholder}
                                        value={values.grossWeight}
                                        onChange={async (e) => {
                                            if (e.target.value != '') {
                                                let grossWeight = Utils.parseFloat(e.target.value);
                                                let tareWeight = Utils.parseFloat(values.tareWeight);
                                                let nonBillableBinWeight = Utils.parseFloat(values.nonBillableBinWeight);
                                                let nettWeight = grossWeight - tareWeight;
                                                let billableWeight = nettWeight - nonBillableBinWeight;
                                                billableWeight = (billableWeight < 0) ? 0 : billableWeight;

                                                await setFieldValue('grossWeight', e.target.value);
                                                await setFieldValue('nettWeight', nettWeight.toFixed((values.uom === ReportUOM.kg) ? 0 : 3));
                                                await setFieldValue('billableWeight', billableWeight.toFixed((values.uom === ReportUOM.kg) ? 0 : 3));
                                            } else {
                                                await setFieldValue('grossWeight', e.target.value);
                                            }

                                            // let billableWeight = Utils.parseFloat(values.billableWeight);
                                            // let maxNumb = Math.max(tareWeight, billableWeight)

                                            // if(grossWeight < maxNumb){
                                            //     await setFieldValue('grossWeight', e.target.value);
                                            //     await setFieldValue('tareWeight', e.target.value);
                                            //     await setFieldValue('nettWeight', e.target.value);
                                            //     await setFieldValue('billableWeight', e.target.value);
                                            // } else {
                                            //     await setFieldValue('grossWeight', e.target.value);
                                            //     await setFieldValue('nettWeight', nettWeight.toFixed(3));
                                            //     await setFieldValue('billableWeight', nettWeight.toFixed(3));
                                            // }
                                        }}
                                        isInvalid={!!errors.grossWeight}
                                        readOnly={Utils.isRejectedOrCancelledOrFailedOrDeleted(statusName)}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.grossWeight}</Form.Control.Feedback>
                                </InputLayout>
                            </td>
                            <td></td>
                            <td>
                                <InputLayout
                                    label={this.props.fields.tareWeight.label + ((values.uom === ReportUOM.kg) ? ' (kg)' : ' (tons)')}
                                    labelProps={{
                                        className: 'd-block d-md-none',
                                    }}
                                >
                                    <Form.Control
                                        type="number"
                                        id={this.props.fields.tareWeight.id}
                                        placeholder={this.props.fields.tareWeight.placeholder}
                                        value={values.tareWeight}
                                        readOnly={((values.grossWeight !== '') ? false : true) || Utils.isRejectedOrCancelledOrFailedOrDeleted(statusName)}
                                        onChange={async (e) => {
                                            let grossWeight = Utils.parseFloat(values.grossWeight);
                                            let tareWeight = Utils.parseFloat(e.target.value);
                                            let nonBillableBinWeight = Utils.parseFloat(values.nonBillableBinWeight);
                                            let nettWeight = grossWeight - tareWeight;
                                            let billableWeight = nettWeight - nonBillableBinWeight;
                                            billableWeight = (billableWeight < 0) ? 0 : billableWeight;

                                            await setFieldValue('tareWeight', e.target.value);
                                            await setFieldValue('nettWeight', nettWeight.toFixed((values.uom === ReportUOM.kg) ? 0 : 3));
                                            await setFieldValue('billableWeight', billableWeight.toFixed((values.uom === ReportUOM.kg) ? 0 : 3));
                                        }}
                                        isInvalid={!!errors.tareWeight}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.tareWeight}</Form.Control.Feedback>
                                </InputLayout>
                                {(this.props.jobTemplateType !== JobStepType.Out) && <div className={'mb-2'}>
                                    {!this.props.refreshWeighChit.isLoading && <CustomTooltip title={'Refresh tare weight'} className={'dark-tooltip mb-2'}>
                                        <a href={'/'}
                                            className={'d-inline-flex align-items-center'}
                                            onClick={async (e) => {
                                                e.preventDefault();
                                                e.stopPropagation();

                                                let weighChitTareWeight = Utils.calcTareWeight(this.props.refreshWeighChit.binUnladenWeight, this.props.refreshWeighChit.vehicleUnladenWeight);

                                                await setFieldValue('tareWeight', weighChitTareWeight);

                                                let grossWeight = Utils.parseFloat(values.grossWeight);
                                                let nonBillableBinWeight = Utils.parseFloat(values.nonBillableBinWeight);
                                                let nettWeight = grossWeight - weighChitTareWeight;
                                                let billableWeight = nettWeight - nonBillableBinWeight;
                                                billableWeight = (billableWeight < 0) ? 0 : billableWeight;

                                                await setFieldValue('nettWeight', nettWeight.toFixed((values.uom === ReportUOM.kg) ? 0 : 3));
                                                await setFieldValue('billableWeight', billableWeight.toFixed((values.uom === ReportUOM.kg) ? 0 : 3));
                                            }}
                                        >
                                            <i className={'fa fa-sync-alt text-primary pe-1'}></i>
                                        </a>
                                    </CustomTooltip>}
                                    <u className={(this.props.refreshWeighChit.isLoading ? 'ps-6' : 'ms-1') + ' text-primary'}>Vehicle: {!this.props.refreshWeighChit.isLoading ? this.props.refreshWeighChit.vehicleUnladenWeight ? this.props.refreshWeighChit.vehicleUnladenWeight : '-' : <Spinner animation="border" size={'sm'} />}</u>
                                </div>}
                                {(this.props.jobTemplateType !== JobStepType.Out) && <div className={'mb-2'}>
                                    <u className={'ps-6 mb-2 text-primary'}>Bin: {!this.props.refreshWeighChit.isLoading ? this.props.refreshWeighChit.binUnladenWeight ? this.props.refreshWeighChit.binUnladenWeight : '-' : <Spinner animation="border" size={'sm'} />}</u>
                                </div>}
                            </td>
                            <td></td>
                            <td>
                                <InputLayout
                                    label={this.props.fields.nettWeight.label}
                                    labelProps={{
                                        className: 'd-block d-md-none',
                                    }}
                                >
                                    <Form.Control
                                        type="number"
                                        id={this.props.fields.nettWeight.id}
                                        placeholder={this.props.fields.nettWeight.placeholder}
                                        value={values.nettWeight}
                                        readOnly={true}
                                        onChange={handleChange}
                                        isInvalid={!!errors.nettWeight}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.nettWeight}</Form.Control.Feedback>
                                </InputLayout>
                            </td>
                            <td></td>
                            <td>
                                <InputLayout
                                    label={this.props.fields.nonBillableBinWeight.label + ((values.uom === ReportUOM.kg) ? ' (kg)' : ' (tons)')}
                                    labelProps={{
                                        className: 'd-block d-md-none',
                                    }}
                                >
                                    <Form.Control
                                        type="number"
                                        id={this.props.fields.nonBillableBinWeight.id}
                                        placeholder={this.props.fields.nonBillableBinWeight.placeholder}
                                        value={values.nonBillableBinWeight}
                                        readOnly={((values.grossWeight !== '') ? false : true) || Utils.isRejectedOrCancelledOrFailedOrDeleted(statusName)}
                                        onChange={async (e) => {
                                            let grossWeight = Utils.parseFloat(values.grossWeight);
                                            let tareWeight = Utils.parseFloat(values.tareWeight);
                                            let nonBillableBinWeight = Utils.parseFloat(e.target.value);
                                            let nettWeight = grossWeight - tareWeight;
                                            let billableWeight = nettWeight - nonBillableBinWeight;
                                            billableWeight = (billableWeight < 0) ? 0 : billableWeight;

                                            await setFieldValue('nonBillableBinWeight', e.target.value);
                                            await setFieldValue('tareWeight', tareWeight);
                                            await setFieldValue('nettWeight', nettWeight.toFixed((values.uom === ReportUOM.kg) ? 0 : 3));
                                            await setFieldValue('billableWeight', billableWeight.toFixed((values.uom === ReportUOM.kg) ? 0 : 3));
                                        }}
                                        isInvalid={!!errors.nonBillableBinWeight}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.nonBillableBinWeight}</Form.Control.Feedback>
                                </InputLayout>
                                {(this.props.jobTemplateType !== JobStepType.Out) && <CustomTooltip title={'Refresh non-billable weight'} className={'dark-tooltip mb-2'}>
                                    {!this.props.refreshWeighChit.isLoading
                                        ?
                                        <a href={'/'}
                                            className={'d-inline-flex align-items-center'}
                                            onClick={async (e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                
                                                // Convert from billing uom to default uom
                                                let nonBillableBinWeight = Utils.parseFloat(Utils.getBillingUOM() == values.uom ? this.props.refreshWeighChit.nonBillableWeight : Utils.convertUom(this.props.refreshWeighChit.nonBillableWeight, Utils.getBillingUOM(), values.uom));
                                                
                                                await setFieldValue('nonBillableBinWeight', nonBillableBinWeight);

                                                let grossWeight = Utils.parseFloat(values.grossWeight);
                                                let nettWeight = grossWeight - Utils.parseFloat(values.tareWeight);
                                                let billableWeight = nettWeight - nonBillableBinWeight;
                                                billableWeight = (billableWeight < 0) ? 0 : billableWeight;

                                                await setFieldValue('nettWeight', nettWeight.toFixed((values.uom === ReportUOM.kg) ? 0 : 3));
                                                await setFieldValue('billableWeight', billableWeight.toFixed((values.uom === ReportUOM.kg) ? 0 : 3));
                                            }}
                                        >
                                            <i class={'fa fa-sync-alt text-primary pe-2'}></i>
                                            <u>Refresh</u>
                                        </a>
                                        :
                                        <u className={'text-primary'}>Refresh <Spinner animation="border" size={'sm'} /></u>
                                    }
                                </CustomTooltip>}
                            </td>
                            <td></td>
                            <td>
                                <InputLayout
                                    label={this.props.fields.billableWeight.label}
                                    labelProps={{
                                        className: 'd-block d-md-none',
                                    }}
                                >
                                    <Form.Control
                                        type="number"
                                        id={this.props.fields.billableWeight.id}
                                        placeholder={this.props.fields.billableWeight.placeholder}
                                        value={values.billableWeight}
                                        readOnly={((values.grossWeight !== '') ? false : true) || Utils.isRejectedOrCancelledOrFailedOrDeleted(statusName)}
                                        onChange={handleChange}
                                        isInvalid={!!errors.billableWeight}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.billableWeight}</Form.Control.Feedback>
                                </InputLayout>
                            </td>
                        </tr>
                    </tbody>
                </StyledTable>
            </Col>
        </Row>
    }

    footer = () => {
        let {
            values,
            errors,
        } = this.props.formOptions;

        let statusName = (this.props.dataParams && this.props.dataParams.row && this.props.dataParams.row.statusName) ? this.props.dataParams.row.statusName : null;
        let jobNumber = (this.props.dataParams && this.props.dataParams.row && this.props.dataParams.row.jobNumber) ? this.props.dataParams.row.jobNumber : null;
        if (!jobNumber) {
            let customFormParams = (this.props.dataParams && this.props.dataParams.row && this.props.dataParams.row.customFormParams) ? this.props.dataParams.row.customFormParams : null;
            jobNumber = (customFormParams && customFormParams.jobNumber) ? customFormParams.jobNumber : null;
        }

        return <Row>
            <Col xs={'auto'}>
                <Button
                    type={'submit'}
                    disabled={this.props.isLoading}
                >
                    {(this.props.isSave == true) ? 'SAVE' : 'OK'}
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
            <Col xs={'auto'}>
                <Button
                    type={'button'}
                    variant={'secondary'}
                    disabled={this.props.isLoading}
                    onClick={() => {
                        Utils.showDrawer(this.props.drawer, false);
                    }}
                >
                    CANCEL
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>

            {(this.props.isDownload && (statusName == 'Completed')) && <Col xs={true} className={'text-end'}>
                <Button
                    type={'button'}
                    variant={'outline-secondary'}
                    disabled={this.props.isLoading}
                    onClick={() => {
                        this.callDownloadPDFFileApi((response) => {
                            if(response){
                                let byteArray = Utils.getUint8Array(response);
                                const url = window.URL.createObjectURL(new Blob([byteArray], { type: "application/pdf" }));
                                const link = document.createElement("a");
                                link.href = url;
                                link.setAttribute(
                                    "download",
                                    jobNumber +
                                    ".pdf"
                                );
                                document.body.appendChild(link);
                                link.click();
                                link.remove();
                            }
                        });
                    }}
                >
                    DOWNLOAD
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>}

        </Row>
    }


    render() {
        return <DrawerContentLayout
            ref={this.bodyRef}
            isBlocking={this.props.isBlocking}
            body={this.body()}
            footer={this.footer()}
        />
    }
}


const mapStateToProps = state => {
    return {
        ...state,
        ...Utils.mapStateToProps(state),
    };
};
const mapDispatchToProps = (dispatch) => ({
    dispatchApiCallGet: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallGet(p1, p2, p3, p4, p5, p6)),
    dispatchApiCallPost: (p1, p2, p3, p4, p5, p6, p7) => dispatch(dispatchApiCallPost(p1, p2, p3, p4, p5, p6, p7)),
    clear: (payload) => dispatch(clear(payload)),
    action: {
        refreshWeighChit: {
            callReadWeightApi: (payload) => dispatch(refreshWeighChit.callReadWeightApi(payload)),
        },
    }
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(JobFormWeighChitDrawer);
