/* eslint-disable no-loop-func */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// import { ParseResult } from "../../../../utils/interfaces";


interface InitState {
  show: boolean,

  geofenceIndex: number|null,
  accountMapGeofenceGroupId: number|null,
  geofence: Array<any>,
  checkedGeofence: Array<number>,
}


function NewReducer() {
  const name = 'payrollBetaCopyGeofence';


  const initialState: InitState = {
    show: false,

    geofenceIndex: null,
    accountMapGeofenceGroupId: null,
    geofence: [],
    checkedGeofence: [],
  };


  const reducers = {
    show: (state: InitState, action: PayloadAction<{ accountMapGeofenceGroupId: number|null, geofence: Array<any>, geofenceIndex: number|null }>) => {
      state.geofenceIndex = action.payload.geofenceIndex;
      state.accountMapGeofenceGroupId = action.payload.accountMapGeofenceGroupId;
      state.geofence = action.payload.geofence;
      state.checkedGeofence = [];
      state.show = true;
    },
    hide: (state: InitState) => {
      state.geofenceIndex = null;
      state.accountMapGeofenceGroupId = null;
      state.geofence = [];
      state.checkedGeofence = [];
      state.show = false;
    },

    setCheckedGeofence: (state: InitState, action: PayloadAction<any>) => {
      let value = action.payload;
      let checkedGeofence = state.checkedGeofence;
      let index = checkedGeofence.findIndex((x: any) => x == value);

      if (index === -1) {
        checkedGeofence.push(value);
      } else {
        checkedGeofence.splice(index, 1);
      }
      state.checkedGeofence = checkedGeofence;
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
  };
}


export default NewReducer();