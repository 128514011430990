import React, { Component } from "react";
import * as yup from 'yup';

import { 
  Row,
  Col,
  Button,
} from "react-bootstrap-v5";

import { DrawerLayout } from '../../../components/drawer/DrawerLayout'
import CurrentDateTime from '../../../components/currentDateTime/CurrentDateTime'

import ViewLayout from "../layout/ViewLayout";
import DriversNotesForm from "./DriversNotesForm";

import DataTable from "../../../components/OLD/DataTable/DataTable";
import DataTableActionMenu from '../../../components/OLD/DataTable/DataTableActionMenu';
import DataTableAjaxPagination from '../../../components/OLD/DataTable/DataTableAjaxPagination.jsx';
import DataTableScrollSync from '../../../components/OLD/DataTable/DataTableScrollSync.jsx';
import DropdownIconButtonMenu from '../../../components/OLD/Dropdown/DropdownIconButtonMenu';
import IconButtonSearch from '../../../components/OLD/input/IconButtonSearch';
import makeExpanding from '../../../components/OLD/Expanding/makeExpanding';
import RemoveDialog from '../../../components/dialog/RemoveDialog';
import AdvancedFilter from "../../../components/OLD/Filter/AdvancedFilter.jsx";
import IconButton from "../../../components/Buttons/IconButton";
import IconButtonTable from "../../../components/Buttons/IconButtonTable";

import { ReactComponent as PrintIcon } from "../../../../_metronic/assets/img/icons/report-print.svg";
import { ReactComponent as DownloadIcon } from "../../../../_metronic/assets/img/icons/report-download.svg";
import { ReactComponent as SettingsIcon } from "../../../../_metronic/assets/img/icons/manage-settings.svg";  
import { ReactComponent as FilterIcon } from "../../../../_metronic/assets/img/icons/feather_filter.svg";  

import Utils from '../../../utils/utils'
import { PageCookieSettingType, PagesPermissions } from '../../../utils/enums'

import { connect } from 'react-redux'
import { actions } from "../../../../setup/redux/auth/saga";
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut, dispatchApiCallDelete } from '../../../../setup/redux/dispatch/actions'


const { saveUserCookieSettings } = actions;
const ExpandingSearchBox = makeExpanding(IconButtonSearch);


const formFields = {
  note: {
    id: 'note',
    label: 'Note',
    placeholder: Utils.placeholderRows(5),
  },
}
const formSchema = yup.object().shape({
  isEdit: yup.bool().oneOf([true, false]),

  note: yup.string().required().label(formFields.note.label),
});


class DriversNotesView extends Component {

  constructor(props) {
    super(props);

    this.pageName = "manage-drivernote-page";
    this.pageIdField = "driverNoteId";
    this.pageNameField = "note";
    this.pageAPI = "drivernote";
    this.pageCaption = 'Driver\'s notes'

		let user = (props.auth && props.auth.user) ? props.auth.user : null;
		this.permissions = (user && user.permissions && user.permissions.length > 0) ? user.permissions : [];

    props.layoutRef.current = this;
    this.formDrawer = null;

    this.tableRef = React.createRef();
    this.refDropdownAdvancedFilter = React.createRef();
    this.refDropdownActionMenu = React.createRef();
    this.refFormDrawer = React.createRef();
    this.refDataTableScrollSync = React.createRef();
    

    let columns = [
      { 
        id: this.pageIdField, 
        filterKey: this.pageIdField, 
        name: this.pageIdField, 
        label: 'ID',
        visible: false
      },
      {
        id: "note",
        name: "note",
        label: "Note",
        responsiveLabel: "Note",
        orderable: true,
      },
    ];
    let initColumns = Array.from(columns);
    columns = Array.from(initColumns);
    
    
    let columnVisibility = [
      false,
      true,
      // true,
    ];
    let initColumnVisibility = Array.from(columnVisibility);
    columnVisibility = Array.from(initColumnVisibility);


    this.state = {
      data: null,
      err: null,
      isError: false,
      isLoading: false,

      search: '',
      sortColumn: 'updated',
      sortDir: 'desc',
      searchTags: [],
      searchOperator: [],
      searchColumns: [],

      selected: [],
      columnVisibility: columnVisibility,
      initColumnVisibility: initColumnVisibility,
      columns: columns,
      initColumns: initColumns,
      rows: [],
      currentPage: 1,
      totalRows: 0,
      rowsPerPageSelected: 50,
      rowsPerPageOptions: [50, 100, 300],
      
      isCookies: false,
      
      isOpenAuditTrail: false,
      objectId: null,

      isRemoveDialog: false,
      removeRow: null,

      isIncludeInactive: false,

      isDisabledBatchAction: true,
      batchActionLoading: false,

      isLoadingFormDrawer: false,
      isVisibleFormDrawer: false,
      isBlockingFormDrawer: false,
      rowInitialValues: {
        isEdit: false,
        note: '',
      },
      rowValues: null,
    }
  }


  componentDidMount() {
    this.initDrawers();

    this.getFilterInLocalStorage(() => {
      this.callReadApi();
    });
  }
  
  componentWillReceiveProps(nextProps) {
    this.reduxProps(nextProps);
	}
  

	/* FUNCTIONS */
  onWindowResize = () => {
    this.getFilterInLocalStorage();
  }

  setFilterInLocalStorage = (isCookies = true) => {
    if(this.props.settingsType >= 0){
      let { token, userCookieSettings } = this.props.auth;

      this.setState({
        isCookies: isCookies,
      });
      
      let data = {
        name: this.pageName,
        type: this.props.settingsType,
      };

      let valueLocalStorage = {
        [PageCookieSettingType.currentPage]: this.state.currentPage,
      };
      window.localStorage.setItem(this.pageName + '_' + this.props.settingsType, JSON.stringify(valueLocalStorage));

      let value = {
        [PageCookieSettingType.isCookies]: isCookies,
        [PageCookieSettingType.search]: this.state.search,
        [PageCookieSettingType.sortColumn]: this.state.sortColumn,
        [PageCookieSettingType.sortDir]: this.state.sortDir,
        [PageCookieSettingType.rowsPerPageSelected]: this.state.rowsPerPageSelected,
        [PageCookieSettingType.columnVisibility]: this.state.columnVisibility,
        [PageCookieSettingType.columns]: this.state.columns,
        [PageCookieSettingType.isIncludeInactive]: this.state.isIncludeInactive,
      };
      data['value'] = JSON.stringify(value);

      Utils.saveUserCookieSettings(data, token, (settings) => {
        if(settings){
          let settingsArr = (userCookieSettings && userCookieSettings.length > 0) ? userCookieSettings : [];
          let settingsIndex = Utils.getUserCookieSettingsIndex(settingsArr, this.props.settingsType);
          if(settingsIndex !== -1 && settingsArr[settingsIndex]){
            settingsArr[settingsIndex] = settings;
          } else {
            settingsArr.push(settings);
          }

          this.props.saveUserCookieSettings(settingsArr)
        }
      });
    }
  };
  getFilterInLocalStorage = (callback = null) => {
    let settingsItem = null;

    if(this.props.settingsType >= 0){
      const { userCookieSettings } = this.props.auth;

      settingsItem = Utils.getUserCookieSettings(userCookieSettings, this.props.settingsType, this.pageName);
    }

    let valueLocalStorageStr = window.localStorage.getItem(this.pageName + '_' + this.props.settingsType);
    let valueLocalStorage = JSON.parse(valueLocalStorageStr);
    let currentPageValue = valueLocalStorage && valueLocalStorage[PageCookieSettingType.currentPage] && valueLocalStorage[PageCookieSettingType.currentPage] !== "" ? valueLocalStorage[PageCookieSettingType.currentPage] : this.state.currentPage;
    
    let isCookiesValue = settingsItem && settingsItem[PageCookieSettingType.isCookies] && settingsItem[PageCookieSettingType.isCookies] !== "" ? settingsItem[PageCookieSettingType.isCookies] : this.state.isCookies;
    let searchValue = (settingsItem && settingsItem[PageCookieSettingType.search] && settingsItem[PageCookieSettingType.search] !== '') ? settingsItem[PageCookieSettingType.search] : this.state.search;
    let sortColumnValue = settingsItem && settingsItem[PageCookieSettingType.sortColumn] && settingsItem[PageCookieSettingType.sortColumn] !== "" ? settingsItem[PageCookieSettingType.sortColumn] : this.state.sortColumn;
    let sortDirValue = settingsItem && settingsItem[PageCookieSettingType.sortDir] && settingsItem[PageCookieSettingType.sortDir] !== "" ? settingsItem[PageCookieSettingType.sortDir] : this.state.sortDir;
    let rowsPerPageSelectedValue = settingsItem && settingsItem[PageCookieSettingType.rowsPerPageSelected] && settingsItem[PageCookieSettingType.rowsPerPageSelected] !== "" ? settingsItem[PageCookieSettingType.rowsPerPageSelected] : this.state.rowsPerPageSelected;
    let columnVisibilityValue = (settingsItem && settingsItem[PageCookieSettingType.columnVisibility] && settingsItem[PageCookieSettingType.columnVisibility] !== '') ? settingsItem[PageCookieSettingType.columnVisibility] : this.state.columnVisibility;
    let columnsValue = (settingsItem && settingsItem[PageCookieSettingType.columns] && settingsItem[PageCookieSettingType.columns] !== '') ? settingsItem[PageCookieSettingType.columns] : this.state.columns;
    let isIncludeInactiveValue = (settingsItem && settingsItem[PageCookieSettingType.isIncludeInactive] && settingsItem[PageCookieSettingType.isIncludeInactive] !== '') ? settingsItem[PageCookieSettingType.isIncludeInactive] : this.state.isIncludeInactive;
    
    let columns = Utils.setTemplateFunctionsToColumns(this.state.columns, columnsValue);
    
    this.setState({
      isCookies: isCookiesValue,
      search: searchValue,
      sortColumn: sortColumnValue,
      sortDir: sortDirValue,
      currentPage: currentPageValue,
      rowsPerPageSelected: rowsPerPageSelectedValue,
      columnVisibility: columnVisibilityValue,
      isIncludeInactive: isIncludeInactiveValue,
      columns: columns,
    }, () => {
      if (callback) {
        callback();
      }
    });
  };

  reduxProps = nextProps => {
		Utils.reduxProps(nextProps,
      this.pageName + '-list', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        let rows = (data && data.data && data.data.length > 0) ? data.data : [];
        let totalRows = (data && data.total) ? data.total : 0;
        
        this.setState({
          rows: rows,
          totalRows: totalRows,

          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(callback){
            callback();
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      this.pageName + '-list_by_page', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        let rows = (data && data.data && data.data.length > 0) ? data.data : [];
        
        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(callback){
            callback(rows);
          }
        });
      }
    );
    
    Utils.reduxProps(nextProps,
      this.pageName + '-list_all', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        let rows = (data && data.data && data.data.length > 0) ? data.data : [];
        
        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(callback){
            callback(rows);
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      this.pageName + '-list_download_excel', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(callback){
            callback(data);
          }
        });
      }
    );
    
    Utils.reduxProps(nextProps,
      this.pageName + '-details', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
          this.setState({
            isBlockingFormDrawer: false,
            details: data,

            isLoading: isLoading,
            isError: isError,
            err: err,
          }, () => {
            if(this.state.isError){
              Utils.showDrawer(this.formDrawer, false);
            }
            
            if(callback){
              callback(data);
            }
          });
      }
    );
    
    Utils.reduxProps(nextProps,
      this.pageName + '-create', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoadingFormDrawer: false,

          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(this.state.isError){
          } else {
            Utils.showDrawer(this.formDrawer, false);
          }
          
          if(callback){
            callback(data);
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      this.pageName + '-copy', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoadingFormDrawer: false,

          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(this.state.isError){
          } else {
            Utils.showDrawer(this.formDrawer, false);
          }
          
          if(callback){
            callback(data);
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      this.pageName + '-update', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoadingFormDrawer: false,

          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(this.state.isError){
          } else {
            Utils.showDrawer(this.formDrawer, false);
          }
          
          if(callback){
            callback(data);
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      this.pageName + '-delete', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoadingFormDrawer: false,

          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(this.state.isError){
          } else {
            Utils.showDrawer(this.formDrawer, false);
          }
          
          if(callback){
            callback(data);
          }
        });
      }
    );
  }

  initDrawers = () => {
    Utils.initDrawers(() => {
      this.formDrawer = Utils.getInstanceDrawer('form');
      Utils.onShowDrawer(this.formDrawer, null, () => {
        this.setState({
          isVisibleFormDrawer: true,
        });
      });
      Utils.onHideDrawer(this.formDrawer, this.refFormDrawer, () => {
        this.setState({
          isVisibleFormDrawer: false,
        });
      });
    });
  }

  setDataTable = () => {
    return <DataTable 
      ref={this.tableRef}

      className={"table-style-v4"}
      classNameWrapper={"table-v4"}

      pageName={this.pageName}
      rowId={this.pageIdField}
      caption={this.pageCaption}
      
      noMarginBottom={true}
      verticalAlign={'middle'}
      
      stickyHeader={false}
      isHeader={true}
      resizable={true}
      isDragableHeaderColumns={true}
      isDragable={false}
      isCheckbox={false}
      isBorder={false}
      isPagination={false}
      
      columnVisibility={this.state.columnVisibility}

      isLoading={this.state.isLoading}
      rowsPerPageSelected={this.state.rowsPerPageSelected}
      rowsPerPageOptions={this.state.rowsPerPageOptions}

      search={this.state.search}
      rows={this.state.rows}
      columns={this.state.columns}

      onRowClick={(id, row) => {
        let dataParams = {
          id: id,
          row: row,
        };
        Utils.setDrawerData(this.formDrawer, dataParams);
        Utils.showDrawer(this.formDrawer);
      }}

      onRemove={(row) => {

      }}

      onClickCheckAll={(obj, checked, isDisabledBatchAction) => {
        this.setState({
          isDisabledBatchAction: isDisabledBatchAction
        });
      }}
      onCheckRow={(obj, id, isDisabledBatchAction) => {
        this.setState({
          isDisabledBatchAction: isDisabledBatchAction
        });
      }}

      onSortEnd={(columns, columnVisibility) => {
        this.setState({
          columns: columns,
          columnVisibility: columnVisibility,
        }, () => {
          this.setFilterInLocalStorage();
        });
      }}
      onOrder={(name, sort) => {
        this.setState({
          sortColumn: name,
          sortDir: sort,
        }, () => {
          this.setFilterInLocalStorage();
          this.callReadApi(false);
        });
      }}
      onResize={(columns) => {
        if(columns){
          this.setState({
            columns: columns
          });
        }
        this.setFilterInLocalStorage();
      }}
    />
  }

  prepareForm = (dataParams, data) => {
    if(data){
      let rowValues = {
        isEdit: true,

        note: (data && data.note && data.note !== '') ? data.note : '',
      };
  
      return rowValues;
    } else {
      return this.state.rowinitialValues;
    }
  }
  prepareData = (form, dataParams) => {
    let data = {
      [this.pageIdField]: dataParams.id,
      
      note: form.note,
    };

    return data;
  }
	/* END FUNCTIONS */

	
	/* API */
  callReadApi = (withStatusCall = true, callback = null) => {
    this.setState({
      isLoading: true,
    });

    let data = {
      currentPage: this.state.currentPage,
      pageSize: this.state.rowsPerPageSelected,
      searchQuery: this.state.search,
      sortColumn: this.state.sortColumn,
      sortDir: this.state.sortDir,
      isIncludeInactive: ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : false,
    };
 
    if (this.state.searchTags && this.state.searchTags.length > 0) {
      data["searchTags"] = Utils.joinItems(this.state.searchTags);
    }
    if (this.state.searchOperator && this.state.searchOperator.length > 0) {
      data["searchOperator"] = Utils.getSelectedOperator(this.state.searchOperator);
    }
    if (this.state.searchColumns && this.state.searchColumns.length > 0) {
      data["searchColumns"] = Utils.getSelectedColumns(this.state.searchColumns);
    }

		this.props.dispatchApiCallGet(data, this.pageName + '-list', this.pageAPI, null, callback, () => {});

    if(withStatusCall){
      
    }
	}

  callReadByPageApi = (callback = null) => {
    let data = {
      currentPage: this.state.currentPage,
      pageSize: this.state.rowsPerPageSelected,
      searchQuery: this.state.search,
      sortColumn: this.state.sortColumn,
      sortDir: this.state.sortDir,
      isIncludeInactive: ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : false,
    };
 
    if (this.state.searchTags && this.state.searchTags.length > 0) {
      data["searchTags"] = Utils.joinItems(this.state.searchTags);
    }
    if (this.state.searchOperator && this.state.searchOperator.length > 0) {
      data["searchOperator"] = Utils.getSelectedOperator(this.state.searchOperator);
    }
    if (this.state.searchColumns && this.state.searchColumns.length > 0) {
      data["searchColumns"] = Utils.getSelectedColumns(this.state.searchColumns);
    }

		this.props.dispatchApiCallGet(data, this.pageName + '-list_by_page', this.pageAPI, null, callback, null);
  }

  callReadAllApi = (callback = null) => {
    let data = {
      currentPage: 1,
      pageSize: Utils.getMaxPageSize(),
      searchQuery: this.state.search,
      sortColumn: this.state.sortColumn,
      sortDir: this.state.sortDir,
      isIncludeInactive: ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : false,
    };

		this.props.dispatchApiCallGet(data, this.pageName + '-list_all', this.pageAPI, null, callback, null);
  }

  callDownloadExcelAllApi = (callback = null) => {
		Utils.toast(<div style={{ fontWeight: 'bold' }}>Please Wait ...</div>, 'info');
    
    let data = {
      currentPage: 1,
      pageSize: Utils.getMaxPageSize(),
      searchQuery: this.state.search,
      sortColumn: this.state.sortColumn,
      sortDir: this.state.sortDir,
      isIncludeInactive: ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : false,
    };

		this.props.dispatchApiCallGet(data, this.pageName + '-list_download_excel', this.pageAPI + '/download-excel', null, callback, null);
  }

  callDetailsApi = (id = null, callback = null) => {
    if(id){
      this.setState({
        isBlockingFormDrawer: true,
        details: null,
      });
  
      this.props.dispatchApiCallGet(null, this.pageName + '-details', this.pageAPI + '/' + id, null, callback, () => {});
    } else {
      if(callback){
        callback(null)
      }
    }
  }

  callCreateApi = (data, callback = null) => {
    this.setState({
      isLoadingFormDrawer: true,
    });

		this.props.dispatchApiCallPost(data, this.pageName + '-create', this.pageAPI, data, callback, null);
  }

  callCopyApi = (data, callback = null) => {
    this.setState({
      isLoadingFormDrawer: true,
    });

    data[this.pageNameField] = data[this.pageNameField] + ' - Copy';
    data['isCopy'] = true;

    delete data[this.pageIdField];

		this.props.dispatchApiCallPost(data, this.pageName + '-copy', this.pageAPI, data, callback, null);
  }

  callUpdateApi = (data, callback = null) => {
    this.setState({
      isLoadingFormDrawer: true,
    });

		this.props.dispatchApiCallPut(data, this.pageName + '-update', this.pageAPI, data, callback, null);
  }

  callDeleteApi = (data, callback = null) => {
    this.setState({
      isLoadingFormDrawer: true,
    });

		this.props.dispatchApiCallDelete(data, this.pageName + '-delete', this.pageAPI, data, callback, null);
  }
	/* END API */
	
	
	/* SECTIONS */
	actionSection = () => {
		return <Row className={'align-items-center'}>

      {(Utils.hasPermission(this.permissions, PagesPermissions.ManageDrivers.read)) && <Col xs={'auto'} className={'mb-2 mb-sm-0'}>
        <Button
          variant={'success'}
          className={'pt-1 pb-1 rounded-1 '}
          onClick={(e) => {
            this.props.history.push('/manage/resource/drivers');
          }}
        >
        DRIVER
        </Button>
      </Col>}

      {(Utils.hasPermission(this.permissions, PagesPermissions.ManageVehicles.read)) && <Col xs={'auto'} className={'mb-2 mb-sm-0'}>
        <Button
          variant={'light'}
          className={'pt-1 pb-1 rounded-1 bg-white'}
          onClick={(e) => {
            this.props.history.push('/manage/resource/vehicles');
          }}
        >
          VEHICLE
        </Button>
      </Col>}

      {(Utils.hasPermission(this.permissions, PagesPermissions.ManageWaste.read)) && <Col xs={'auto'} className={'mb-2 mb-sm-0'}>
        <Button
          variant={'light'}
          className={'pt-1 pb-1 rounded-1 bg-white'}
          onClick={(e) => {
            this.props.history.push('/manage/resource/waste-types');
          }}
        >
          WASTE
        </Button>
      </Col>}

      {(Utils.hasPermission(this.permissions, PagesPermissions.ManageBins.read)) && <Col xs={'auto'} className={'mb-2 mb-sm-0'}>
        <Button
          variant={'light'}
          className={'pt-1 pb-1 rounded-1 bg-white'}
          onClick={(e) => {
            this.props.history.push('/manage/resource/bin-types');
          }}
        >
          BIN
        </Button>
      </Col>}

      <Col xs={12} md={true} className={'order-1 order-md-3 pb-10 pb-md-0'}>
        <CurrentDateTime />
      </Col>
    </Row>
	}

	toolbarSection = () => {
		return <Row className={'d-block d-md-flex align-items-center'}>
      {/* <Col xs={'auto'} md={true}>
        <DropdownIconButtonMenu 
          ref={this.refDropdownAdvancedFilter}
          className={'job-list-icon-button-dropdown'}
          overflowVisible={true}
          selected={null}
          icon={<FilterIcon className={((this.state.searchTags && this.state.searchTags.length > 0) ? 'svg-fill-active' : '')}/>}
        >
          <div>
            <AdvancedFilter
              pageName={this.pageName}
              columns={this.state.columns}
              columnVisibility={this.state.columnVisibility}
              searchTags={this.state.searchTags}
              searchOperator={this.state.searchOperator}
              searchColumns={this.state.searchColumns}
              onSearch={(
                searchTags,
                searchOperator,
                searchColumns
              ) => {
                if(this.refDropdownAdvancedFilter && this.refDropdownAdvancedFilter.current){
                  this.refDropdownAdvancedFilter.current.hide();
                }

                this.setState(
                  {
                    searchTags: searchTags,
                    searchOperator: searchOperator,
                    searchColumns: searchColumns,
                    currentPage: 1,
                  },
                  () => {
                    this.setFilterInLocalStorage();
                    this.callReadApi();
                  }
                );
              }}
                    onCancel={() => {
                      if(this.refDropdownAdvancedFilter && this.refDropdownAdvancedFilter.current){
                        this.refDropdownAdvancedFilter.current.hide();
                      }
                    }}
            />
          </div>
        </DropdownIconButtonMenu>
        {this.state.searchTags && this.state.searchTags.length > 0 && (
          <a
            className={"link-danger"}
            href={"/"}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              this.setState(
                {
                  searchTags: [],
                  searchOperator: [],
                  searchColumns: [],
                },
                () => {
                  this.setFilterInLocalStorage();
                  this.callReadApi();
                }
              );
            }}
          >
            Clear Filters
          </a>
        )}
      </Col> */}

      <Col md={'auto'} className={'pb-2 pb-md-0 d-grid'}>
        <Button
          variant={'light'}
          className={'pt-1 pb-1 rounded-1 border bg-white'}
          onClick={(e) => {
            this.props.history.push('/manage/resource/drivers');
          }}
        >
          Driver list
        </Button>
      </Col>
      <Col md={'auto'} className={'pb-2 pb-md-0 d-grid'}>
        <Button
          variant={'success'}
          className={'pt-1 pb-1 rounded-1 border'}
          onClick={(e) => {
            this.props.history.push('/manage/resource/driver-notes');
          }}
        >
          Driver's notes
        </Button>
      </Col>
      <Col xs={12} className={'d-xs-flex d-lg-none w-100'}></Col>

      <Col xs={'auto'} md={true} className={'text-center text-md-end'}>
        <div className='d-inline-flex align-items-center text-start'>
        <ExpandingSearchBox 
          btnClassName={'manage-icon-btn'}
          value={this.state.search}
          isOpen={(this.state.search !== '') ? true : false}
          onPressEnter={(value) => {
            this.setState({
              search: value,
              currentPage: 1,
            }, () => {
              this.setFilterInLocalStorage();
              this.callReadApi(true);
            });
          }}
          onClear={() => {
            this.setState({
              search: '',
              currentPage: 1,
            }, () => {
              this.setFilterInLocalStorage();
              this.callReadApi(true);
            });
          }}
        />
        </div>
                {Utils.isMobileTablet() && <div className='col-12 d-block d-md-none'></div>}
        <div className='d-inline-flex align-items-center text-start'>
        {/* <DropdownIconButtonMenu 
          className={'job-list-icon-button-dropdown'}
          selected={null}
          items={[
            {
              value: 1,
              text: 'Print this page',
            },
            {
              value: 2,
              text: 'Print all pages',
            }
          ]} 
          icon={<PrintIcon />} 
          onClick={(item) => {
            if(item.value === 1){
              this.callReadByPageApi((data) => {
                this.tableRef.current.print(data);
              });

            } else if(item.value === 2){
              this.callReadAllApi((data) => {
                this.tableRef.current.print(data);
              });
            }
          }}
        /> */}

        {/* <DropdownIconButtonMenu 
          className={'job-list-icon-button-dropdown'}
          selected={null}
          items={[
            {
              value: 1,
              text: 'Download all in PDF format',
            },
            {
              value: 2,
              text: 'Download all in Excel format',
            }
          ]} 
          icon={<DownloadIcon />} 
          onClick={(item) => {
              if(item.value === 1){
                this.callReadAllApi((data) => {
                  this.tableRef.current.exportPDF(data);
                });

              } else if(item.value === 2){
                this.callDownloadExcelAllApi((data) => {
                  let type = 'application/vnd.ms-excel';
                  Utils.saveByteArray(this.pageCaption, data, type);
                });
              }
          }}
        /> */}

        <DropdownIconButtonMenu 
          ref={this.refDropdownActionMenu}
          className={'job-list-icon-button-dropdown'}
          selected={null}
          icon={<SettingsIcon />}
        >
          <DataTableActionMenu 
            pageName={this.pageName}
            columns={this.state.columns}
            selected={this.state.rowsPerPageSelected}
            selectItems={this.state.rowsPerPageOptions}
            columnVisibility={this.state.columnVisibility}
            onChangeColumnVisibility={(index, value) => {
              let colVis = this.state.columnVisibility;
              colVis[index] = value;

              this.setState({
                columnVisibility: colVis
              }, () => {
                this.setFilterInLocalStorage();
              });
            }}
            canInactive={false}
            onSelectChangeAjax={(val) => {
              this.setState({
                currentPage: 1,
                rowsPerPageSelected: val
              }, () => {
                this.setFilterInLocalStorage();
                this.callReadApi();
              });
            }}
            onChangeIncludeInactive={(val) => {
              this.setState({
                isIncludeInactive: val,
                currentPage: 1,
              }, () => {
                this.setFilterInLocalStorage();
                this.callReadApi();
              });
            }}
            isCookies={this.state.isCookies}
            onResetColumns={() => {
              this.setState({
                columns: Array.from(this.state.initColumns),
                columnVisibility: Array.from(this.state.initColumnVisibility),
              }, () => {
                this.setFilterInLocalStorage(false);
                if(this.refDropdownActionMenu && this.refDropdownActionMenu.current){
                  this.refDropdownActionMenu.current.hide();
                }
              });
            }}
            onShowMore={(isShowedMore) => {
              this.setState({
                pageName: this.pageName,
              });
            }}
          />
        </DropdownIconButtonMenu>
        </div>
      </Col>
    </Row>
	}

	tableSection = () => {
		return <DataTableScrollSync ref={this.refDataTableScrollSync} className={"table-sync-v4"}>{this.setDataTable()}</DataTableScrollSync>
	}

	paginationSection = () => {
		return <DataTableAjaxPagination
      page={this.state.currentPage}
      selected={this.state.rowsPerPageSelected}
      count={this.state.totalRows}
      onPageChange={(page) => {
        this.setState({
          currentPage: page,
        }, () => {
          this.setFilterInLocalStorage();
          this.callReadApi(false);
        });
      }}
    />
	}

  formDrawerSection = () => {
		return <DrawerLayout
      name={'form'}
      size={'700px'}
      ref={this.refFormDrawer}
      drawer={this.formDrawer}
      validationSchema={formSchema}
      initialValues={this.state.rowInitialValues}
      values={this.state.rowValues}
      isVisible={this.state.isVisibleFormDrawer}
      isBlocking={Utils.isBlockingDrawer(this.state.isLoadingFormDrawer, this.state.isBlockingFormDrawer)}
      renderChildren={Utils.shouldDrawerRenderChildren(this.state.isLoadingFormDrawer, this.state.isBlockingFormDrawer)}

      title={(data) => {
        if(data){
          return <div className={'d-flex align-items-center'}>
            <div className={'d-block'}>
              <div className={'pe-2'}>
                <span className={'pe-2'}>Driver notes</span>
                {/* <IconButton
                  icon={'history'}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                /> */}
              </div>
            </div>
          </div>;
        }
      }}
      toolbar={null}
      
      onOpenClose={(isOpen, dataParams) => {
        if(isOpen){
          this.callDetailsApi(dataParams.id, (data) => {
            let rowValues = this.prepareForm(dataParams, data);
  
            this.setState({
              rowValues: rowValues,
            });
          });
        }
      }}
      onSubmit={(form, dataParams) => {
        let data = this.prepareData(form, dataParams);
        if(form.isEdit){
          this.callUpdateApi(data, (res) => {
            this.callReadApi();
          });
        } else {
          this.callCreateApi(data, (res) => {
            this.callReadApi();
          });
        }
      }}
    >
      {(formOptions, dataParams) => {
        return <DriversNotesForm
          drawer={this.formDrawer}
          fields={formFields}
          initialValues={this.state.rowInitialValues}
          values={this.state.rowValues}
          formOptions={formOptions}
          dataParams={dataParams}
          onDelete={(values) => {
            this.setState({
              isRemoveDialog: true,
              removeRow: values,
            });
          }}
          onCopy={(values) => {
            let data = this.prepareData(values, dataParams);
            this.callCopyApi(data, (res) => {
              this.callReadApi();
            });
          }}
        />
      }}
    </DrawerLayout>
	}
	/* END SECTIONS */

  
  /* DIALOGS */
  setRemoveDialog = () => {
    return <RemoveDialog 
      show={this.state.isRemoveDialog}
      isLoading={this.state.isLoadingFormDrawer}
      onCancel={() => {
        this.setState({
          isRemoveDialog: false,
          removeRow: null,
        });
      }}
      onRemove={() => {
        let values = this.state.removeRow;
        let data = this.prepareData(values, Utils.getDrawerData(this.formDrawer));

        this.callDeleteApi(data, (res) => {
          this.setState({
            isRemoveDialog: false,
            removeRow: null,
          });

          this.callReadApi();
        });
      }}
    />
  }
  /* END DIALOGS */


  render() {
    return (
      <>
        <ViewLayout
          isBlocking={this.state.batchActionLoading}
          title={'RESOURCES'}
          col={<Col xs={true} className={'text-end mb-md-0'}>
            <Button
              variant="primary"
              onClick={() => {
                let dataParams = {
                  id: 0,
                  row: null,
                };
                Utils.setDrawerData(this.formDrawer, dataParams);
                Utils.showDrawer(this.formDrawer);
              }}
              >NEW</Button>
          </Col>}
          actionSection={this.actionSection()}
          toolbarSection={this.toolbarSection()}
          tableSection={this.tableSection()}
          paginationSection={this.paginationSection()}
        />

        {this.formDrawerSection()}
        {this.setRemoveDialog()}
        {/* {this.setAuditTrailDialog()} */}
      </>
    );
  }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  dispatchApiCallPost,
  dispatchApiCallPut,
  dispatchApiCallDelete,
  saveUserCookieSettings,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DriversNotesView);
