import React, { Component } from 'react';

import {
    Box,
    Grid,
    Paper,
    Button,
} from '@material-ui/core';

import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';


class NewSingleDatePicker extends Component {
  
	constructor(props){
		super(props);

        let date = props.date ? new Date(props.date) : new Date();
        let maxDate = props.maxDate ? new Date(props.maxDate) : null;

		this.state = {
            date: date,
            maxDate: maxDate,
		}
	}
    

    componentWillReceiveProps(nextProps) {
        if (nextProps.startDate !== this.state.startDate) {
            let date = nextProps.date ? new Date(nextProps.date) : new Date();
			this.setState({
				date: date
			});
        }
    }


	render() {
		return <Box className={"new-range-date-picker-component"}>
            <Paper elevation={0}>
                <Box p={1}>
                    <Grid container spacing={1}>
                        <Box clone>
                            <Grid item xs={12} md={6}>
                                {
                                    this.state.maxDate
                                    ?
                                    <Calendar
                                        onChange={(date) => {
                                            this.setState({
                                                date: date
                                            });
                                        }}
                                        fixedHeight={true}
                                        editableDateInputs={true}
                                        date={this.state.date}
                                        maxDate={this.state.maxDate}
                                        direction="horizontal"
                                    />
                                    :
                                    <Calendar
                                        onChange={(date) => {
                                            this.setState({
                                                date: date
                                            });
                                        }}
                                        fixedHeight={true}
                                        editableDateInputs={true}
                                        date={this.state.date}
                                        direction="horizontal"
                                    />
                                }
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid container>
                        <Box clone textAlign={'right'}>
                            <Grid item xs={12}>
                                <Button 
                                    color={'primary'} 
                                    variant={'contained'}
                                    onClick={() => {
                                        if(this.props.onChange) {
                                            this.props.onChange(this.state.date);
                                        }
                                    }}
                                >Apply</Button>
                            </Grid>
                        </Box>
                    </Grid>
                </Box>
            </Paper>
		</Box>;
	}
}

export default NewSingleDatePicker;