import React from "react";

import {
  Box,
  Grid,
  Paper,
  IconButton,
  Hidden,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";

import RightMenu from "../../../components/OLD/Pages/Reports/RightMenu.jsx";
import MenuListItems from "../../../components/OLD/Pages/Reports/MenuListItems.jsx";
import ManageLabel from "../../../components/OLD/input/ManageLabel.jsx";

const ReportsLayout = (props) => {
  const ref = React.useRef(null);

  const [isDrawerOpen, setOpenDrawer] = React.useState(false);

  let [isLoading, setIsLoading] = React.useState(
    props.isLoading === false || props.isLoading === true
      ? props.isLoading
      : false
  );
  let [report, setReport] = React.useState(props.report ? props.report : null);

  let [windowWidth, setWindowWidth] = React.useState(0);
  let [windowHeight, setWindowHeight] = React.useState(0);
  let [headerHeight, setHeaderHeight] = React.useState(0);
  let [leftHeight, setLeftHeight] = React.useState(0);

  const resizeListener = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);

    let lHeight = 0;
    let hHeight = 0;
    try {
      let header = document.body.getElementsByTagName("header")[0];
      let headerPosition = header.getBoundingClientRect();
      hHeight = headerPosition.height + 1;
      lHeight = windowHeight - hHeight - 18;
    } catch (err) {
    } finally {
      setHeaderHeight(hHeight);
      setLeftHeight(lHeight);
    }
  };

  React.useEffect(() => {
    setIsLoading(
      props.isLoading === false || props.isLoading === true
        ? props.isLoading
        : false
    );
    setReport(props.report ? props.report : null);

    try {
      setTimeout(() => {
        resizeListener();
      }, 200);
    } catch (e) {
    } finally {
      window.addEventListener("resize", resizeListener);
    }

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  });

  const setBackDrop = () => {
    return (
      <Backdrop
        open={isLoading}
        style={{
          zIndex: 999999,
          color: "#ffffff",
        }}
      >
        <CircularProgress
          color="inherit"
          style={{
            zIndex: 999999,
            color: "#ffffff",
          }}
        />
      </Backdrop>
    );
  };

  return (
    <Box p={1} className={"reports-layout"}>
      <Grid container spacing={2}>
        {/* BREADCRUMBS & HAMBURGER MENU */}
        <Hidden implementation={"css"} xlUp>
          <Box clone pl={2} pt={{ xs: 0, lg: 2 }} pb={{ xs: 0, lg: 2 }}>
            <Grid item xs={12}>
              <Box position={"relative"} textAlign={"right"}>
                <IconButton
                  className="hamburger-btn"
                  aria-label="menu"
                  style={{ padding: "0px" }}
                  onClick={() => {
                    setOpenDrawer(!isDrawerOpen);
                  }}
                >
                  <i className="material-icons">menu</i>
                </IconButton>
              </Box>
            </Grid>
          </Box>
        </Hidden>
        {/* END BREADCRUMBS & HAMBURGER MENU */}

        {/* MENU LIST */}
        <Hidden implementation={"css"} lgDown>
          <Box
            clone
            className={
              "left-section " + (windowWidth < 992 ? "small-screen" : "")
            }
            style={{
              position: "sticky",
              top: headerHeight + "px",
              minHeight: "calc(100vh - 65px)",
              height: leftHeight + "px",
              overflowY: "scroll",
            }}
          >
            <Grid item xs={12} lg={"auto"}>
              <Grid container>
                <Box clone pl={2} pb={2}>
                  <Grid item xs={12}>
                    <Box mt={{ xs: 0, lg: 3 }}>
                      <Grid container alignItems={"center"}>
                        <Box clone pr={2}>
                          <Grid item xs={12} md={"auto"}>
                            <h2>REPORTS</h2>
                          </Grid>
                        </Box>
                      </Grid>
                    </Box>
                  </Grid>
                </Box>
                <Box clone>
                  <Grid item xs={12}>
                    <MenuListItems auth={props.auth} />
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Hidden>
        {/* END MENU LIST */}

        {/* CONTENT */}
        <Box clone pb={5}>
          <Grid item xs={12} lg={true}>
            <Grid container>
              {/* TITLE SECTION */}
              <Box clone pb={1}>
                <Grid item xs={12}>
                  <ManageLabel label={props.titleSection} size={"md"} />
                </Grid>
              </Box>
              {/* END TITLE SECTION */}

              {/* TOP SECTION */}
              <Box clone pb={3}>
                <Grid item xs={12}>
                  {props.topSection}
                </Grid>
              </Box>
              {/* END TOP SECTION */}

              {/* PAGE SECTION */}
              <Box clone pb={3}>
                <Grid item xs={12}>
                  {report ? (
                    <Paper elevation={0} square>
                      <Box ref={ref} pt={3} pb={3} pl={5} pr={5}>
                        <Grid container>
                          <Box
                            clone
                            style={{ paddingBottom: "40px" }}
                            order={{ xs: 2, md: 1 }}
                          >
                            <Grid item xs={12} md={true}>
                              {props.infoSection}
                            </Grid>
                          </Box>

                          <Box
                            pb={2}
                            order={{ xs: 1, md: 2 }}
                            className={"print-hide"}
                          >
                            <Grid container direction={"column"}>
                              <Box
                                item
                                pb={2}
                                order={{ xs: 1, md: 2 }}
                                className={"print-hide"}
                              >
                                <Grid item xs={12} md={"auto"}>
                                  {props.iconsSection(ref)}
                                </Grid>
                              </Box>
            
 {props.iconsSectionButton ?  <Box
                                  item
                                  pb={2}
                                  order={{ xs: 1, md: 2 }}
                                  className={"print-hide"}
                                >
                                  <Grid item xs={12} md={"auto"}>
                                    {props.iconsSectionButton(ref)}
                                  </Grid>
                                </Box>: ""} 
                     
                            </Grid>
                          </Box>

                          <Box clone order={{ xs: 3, md: 3 }}>
                            <Grid item xs={12}>
                              <Grid container alignItems={"center"}>
                                <Box clone style={{ paddingBottom: "20px" }}>
                                  <Grid item xs={12}>
                                    <ManageLabel
                                      className={"no-margin"}
                                      label={props.titleSection}
                                      size={"sm"}
                                    />
                                  </Grid>
                                </Box>
                                <Box clone pb={2}>
                                  <Grid item xs={12}>
                                    {props.pageSection}
                                  </Grid>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Box>
                    </Paper>
                  ) : (
                    <Box textAlign={"center"}>No Report!</Box>
                  )}
                </Grid>
              </Box>
              {/* END PAGE SECTION */}
            </Grid>
          </Grid>
        </Box>
        {/* END CONTENT */}
      </Grid>

      <RightMenu
        auth={props.auth}
        isDrawerOpen={isDrawerOpen}
        onChange={(state) => {
          setOpenDrawer(state);
        }}
      />

      {setBackDrop()}
    </Box>
  );
};

export default ReportsLayout;
