import React from 'react';

import { 
    Box,
    Grid,
    CircularProgress,
    Backdrop,
} from '@material-ui/core';


const JobTypeLayout = React.forwardRef((props, ref) => {
  
  let [windowWidth, setWindowWidth] = React.useState(0);
  let [topHeight, setTopHeight] = React.useState(0);
  let [leftHeight, setLeftHeight] = React.useState(0);

  let [isDialog, setIsDialog] = React.useState(((props.isDialog === false) || (props.isDialog === true)) ? props.isDialog : false);
  let [isCopy, setIsCopy] = React.useState(((props.isCopy === false) || (props.isCopy === true)) ? props.isCopy : false);
  let [isLoading, setIsLoading] = React.useState(((props.isLoading === false) || (props.isLoading === true)) ? props.isLoading : false);
  let [disabled, setDisabled] = React.useState(((props.disabled === false) || (props.disabled === true)) ? props.disabled : false);
  let [jobType, setJobType] = React.useState(props.jobType);

  const formRef = React.useRef();


  const resizeListener = () => {
    setWindowWidth(window.innerWidth);

    let tHeight = topHeight;
    try {
      let header = document.body.getElementsByTagName('header')[0];
      let headerPosition = header.getBoundingClientRect();
      tHeight = headerPosition.height + 1;
    } catch(err){
    } finally {
      setTopHeight(tHeight);
    }

    let lHeight = leftHeight;
    try {
      let topSection = document.body.getElementsByClassName('top-section')[0];
      let topSectionPosition = topSection.getBoundingClientRect();
      lHeight = tHeight + topSectionPosition.height;
    } catch(err){
    } finally {
        setLeftHeight(lHeight);
    }
  }
  

  
  React.useImperativeHandle(
    ref,
    () => ({
        submitForm() {
            formRef.current.dispatchEvent(new Event("submit", { cancelable: true }));
        }
     }),
  );

  React.useEffect(() => {
    setIsDialog(((props.isDialog === false) || (props.isDialog === true)) ? props.isDialog : false);
    setIsCopy(((props.isCopy === false) || (props.isCopy === true)) ? props.isCopy : false);
    setIsLoading(((props.isLoading === false) || (props.isLoading === true)) ? props.isLoading : false);
    setDisabled(((props.disabled === false) || (props.disabled === true)) ? props.disabled : false);
    setJobType(props.jobType);
    
    try{
      resizeListener();
    } catch(e){}
    
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  });


  const setBackDrop = () => {
    return <Backdrop 
      open={isLoading}
      style={{
        zIndex: 999999,
        color: '#ffffff'
      }}
    >
      <CircularProgress 
        color="inherit"
        style={{
          zIndex: 999999,
          color: '#ffffff'
        }}
      />
    </Backdrop>
  }


  return (
    <Box className={'job-type-layout'}>
      
        <form ref={formRef} noValidate autoComplete="off"
            onKeyPress={e => {
              if (e.which === 13) {
                e.preventDefault();
              }
            }}
            onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();

                if(props.onSubmit){
                    props.onSubmit(e);
                }
            }}
        >
            {/* TOP SECTION */}
            <Grid container className={'top-section ' + ((windowWidth < 992) ? 'small-screen' : '')} style={{ top: 65 + 'px', zIndex: '2' }}>
                <Box clone pt={0} pb={2} pl={{ xs: 1, md: 2 }} pr={{ xs: 1, md: 2 }}>
                    <Grid item xs={12}>
                        {props.top}
                    </Grid>
                </Box>
            </Grid>
            {/* END TOP SECTION */}

            {/* CONTENT SECTION */}
            <Grid container className={'content-section'}>
                
                {/* RIGHT SECTION */}
                <Box clone className={'left-section'} style={{ top: (leftHeight + 65) + 'px', height: 'calc(100vh - ' + (leftHeight + 65) + 'px)', overflow: 'scroll' }} pr={2}>
                    <Grid item xs={12} md={3} xl={3}>
                        {props.menu}
                    </Grid>
                </Box>
                {/* END RIGHT SECTION */}
            
                {/* LEFT SECTION */}
                <Box clone>
                    <Grid item xs={12} md={9} xl={9}>

                        {/* PAGE */}
                        <Box pt={2} pb={2} pl={{ xs: 1, md: 2 }} pr={{ xs: 1, md: 2 }}>
                              <Grid container>

                                  <Box id={'section_1_ID'} clone pb={2}>
                                      <Grid item xs={12}>
                                          {props.addresses}
                                      </Grid>
                                  </Box>

                                  <Box id={'section_2_ID'} clone pb={2}>
                                      <Grid item xs={12}>
                                          {props.jobSteps}
                                      </Grid>
                                  </Box>

                                  <Box id={'section_3_ID'} clone pb={2}>
                                      <Grid item xs={12}>
                                          {props.appPermission}
                                      </Grid>
                                  </Box>

                                  <Box id={'section_4_ID'} clone pb={2}>
                                      <Grid item xs={12}>
                                          {props.others}
                                      </Grid>
                                  </Box>
                                  
                              </Grid>
                        </Box>
                        {/* END PAGE */}

                        
                    </Grid>
                </Box>
                {/* END LEFT SECTION */}

            </Grid>
            {/* END CONTENT SECTION */}

        </form>
        
        {setBackDrop()}
    </Box>
  )
});

export default JobTypeLayout;