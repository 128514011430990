import React from 'react';
import _ from 'lodash';


const OrderableListByChildren = ({ children, order = 'order', ...rest }) => {
    const [items, setItems] = React.useState([]);

    
    React.useEffect(() => {
        const itms = getItems(children);
        setItems(itms);
    }, [children]);


    const getItems = () => {
        if(children){
            let arr = [];
            let arrItems = [];

            arr = React.Children.toArray(children).map((child, index) => {
                if (Array.isArray(child.props.children)) {
                    arrItems.push(child.props.children);
                } else {
                    return {
                        id: index,
                        content: child.props.children,
                        [order]: child.props[order],
                        render: (child) => {
                            return <>{child}</>
                        }
                    }
                }
            });

            if(arrItems && arrItems.length > 0){
                let flattenedArr = _.flattenDeep(arrItems);

                if(flattenedArr && flattenedArr.length > 0){
                    flattenedArr.forEach((childItem, j) => {
                        if(childItem){
                            arr.push({
                                id: '1000' + j,
                                content: childItem.props.children,
                                [order]: childItem.props[order],
                                render: (childItem) => {
                                    return <>{childItem}</>
                                }
                            })
                        }
                    })
                }
            }

            arr.sort((a, b) => a[order] - b[order]);

            return arr;
        } else {
            return [];
        }
    };

    return <>
        {items.map((item, index) => {
            if(item){
                return <React.Fragment key={index}>{item.render(item.content)}</React.Fragment>
            }
        })}
    </>
}


export default OrderableListByChildren;
