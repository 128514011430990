import React from "react";
import _ from "lodash";

import { 
    Row,
    Col,
    Dropdown,
    ButtonGroup,
    Spinner,
    Button,
} from "react-bootstrap-v5";

import Utils from "../../../../../utils/utils";

import { connect } from 'react-redux'
import { clear } from "../../../../../../setup/redux/actions";
import { dispatchApiCallGet } from '../../../../../../setup/redux/dispatch/actions'


class FooterSection extends React.Component {
  
    constructor(props) {
        super(props);
        
        this.name = 'job_form_footer_section';

        this.bodyRef = React.createRef();

        let dataParams = (props.dataParams) ? props.dataParams : null;
        let id = (dataParams && dataParams.id) ? dataParams.id : null;
        let row = (dataParams && dataParams.row) ? dataParams.row : null;
        
        
        this.state = {
            id: id,
            row: row,
        };
    }
    

    body = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;
        
        return <Row className={'align-items-center'}>
            <Col 
                className={'text-center text-md-start pb-2 pb-md-0'}
                xs={{ span: 12, order: 2 }}
                sm={{ span: true, order: 2 }}
                md={{ span: 'auto', order: 1 }}
            >
                <Button 
                    type={'button'}
                    variant={'outline-secondary'}
                    disabled={this.props.isLoading}
                    onClick={() => {
                        this.props.history.push('/manage/job/job-recurring');
                    }}
                >
                    CANCEL
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
            <Col 
                className={'text-center pb-2 pb-md-0'}
                xs={{ span: 12, order: 1 }}
                md={{ span: true, order: 2 }}
            >
                <Row className={'justify-content-center align-items-center'}>
                    
                    <Col xs={'auto'}>
                        <Dropdown 
                            onSelect={(eventKey, e) => {
                                if(this.props.onClickMoreItem){
                                    this.props.onClickMoreItem(eventKey);
                                }
                            }}
                        >
                            <Dropdown.Toggle
                                className={'link-secondary d-flex align-items-center'}
                                variant={'link'}
                                disabled={this.props.isLoading || (!values.customerId) || (!values.jobTemplateId)}
                            >
                                More
                                {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                            </Dropdown.Toggle>
                            <Dropdown.Menu variant={'link'}>
                                <Dropdown.Item eventKey={1} disabled={!values.isEdit || values.isCopy} active={false}>Copy</Dropdown.Item>
                                <Dropdown.Item eventKey={2} disabled={!values.isEdit || values.isCopy} active={false}>Delete</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>

                </Row>
            </Col>
            <Col 
                className={'text-center text-md-end'}
                xs={{ span: 12, order: 3 }} 
                sm={{ span: true, order: 3 }}
                md={{ span: 'auto', order: 3 }}
            >
                <Dropdown 
                    as={ButtonGroup}
                    onSelect={(eventKey, e) => {
                        validateForm().then((err) => {
                            if(_.isEmpty(err)){
                                if(this.props.onSave){
                                    this.props.onSave(eventKey, values);
                                }
                            } else {
                                Utils.toast('You must fill in the required fields in form', 'error');
                            }
                        })
                    }}
                >
                    <Button 
                        variant={'primary'}
                        disabled={this.props.isLoading}
                        onClick={(e) => {
                            validateForm().then((err) => {
                                if(_.isEmpty(err)){
                                    if(this.props.onSave){
                                        this.props.onSave(0, values);
                                    }
                                } else {
                                    Utils.toast('You must fill in the required fields in form', 'error');
                                }
                            })
                        }}
                    >
                        SAVE & CLOSE
                        {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                    </Button>
                    <Dropdown.Toggle
                        variant={'primary'}
                        disabled={this.props.isLoading}
                    />
                    <Dropdown.Menu variant={'primary'}>
                        <Dropdown.Item eventKey={1} active={false}>SAVE & NEW</Dropdown.Item>
                        <Dropdown.Item eventKey={2} active={false}>SAVE ONLY</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
        </Row>
    }
    

    render() {
        return this.body()
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(FooterSection);
