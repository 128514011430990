import React, { Component } from "react";
import moment from 'moment';

import { Dropdown } from 'rsuite';

import { 
  Row,
  Col,
  Button,
} from "react-bootstrap-v5";

import CurrentDateTime from '../../../components/currentDateTime/CurrentDateTime'

import ViewLayout from "../layout/ViewLayout";
// import DriversDetailsPopup from './DriversDetailsPopup';

import DataTable from "../../../components/OLD/DataTable/DataTable";
import DataTableActionMenu from '../../../components/OLD/DataTable/DataTableActionMenu';
import DataTableAjaxPagination from '../../../components/OLD/DataTable/DataTableAjaxPagination.jsx';
import DataTableScrollSync from '../../../components/OLD/DataTable/DataTableScrollSync.jsx';
import DropdownIconButtonMenu from '../../../components/OLD/Dropdown/DropdownIconButtonMenu';
import IconButtonSearch from '../../../components/OLD/input/IconButtonSearch';
import makeExpanding from '../../../components/OLD/Expanding/makeExpanding';
import WrappedButton from '../../../components/Buttons/WrappedButton';

import { ReactComponent as SettingsIcon } from "../../../../_metronic/assets/img/icons/manage-settings.svg";  

import Utils from '../../../utils/utils'
import { PageCookieSettingType, PagesPermissions } from '../../../utils/enums'

import ViewTopSection from "../../Customers/partial/ViewTopSection.jsx";

import { connect } from 'react-redux'
import { actions } from "../../../../setup/redux/auth/saga";
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut, dispatchApiCallDelete } from '../../../../setup/redux/dispatch/actions'
import driversDetails from './redux/driversDetails';
import signalRslice from "../../../components/signalR/signalRslice";


const { saveUserCookieSettings } = actions;
const ExpandingSearchBox = makeExpanding(IconButtonSearch);


class DriversView extends Component {

  constructor(props) {
    super(props);

    this.pageName = "manage-drivers-page";
    this.pageIdField = "driverId";
    this.pageNameField = "driverName";
    this.pageAPI = "driver";
    this.pageCaption = 'Drivers'

		let user = (props.auth && props.auth.user) ? props.auth.user : null;
		this.permissions = (user && user.permissions && user.permissions.length > 0) ? user.permissions : [];

    props.layoutRef.current = this;

    this.tableRef = React.createRef();
    this.refDropdownAdvancedFilter = React.createRef();
    this.refDropdownActionMenu = React.createRef();
    this.refDataTableScrollSync = React.createRef();
    

    this.isDeactivateOrDeleteTemplate = (id, row, col, obj) => {
      return <div>{(row.isDeactivateOrDelete) ? 'Inactive' : 'Active'}</div>
    };
    this.isDeactivateOrDeleteExportTemplate = (row) => {
      return row.isDeactivateOrDelete === true ? "Inactive" : "Active";
    };
    this.isDeactivateOrDeleteExportCSVTemplate = (row) => {
      return row.isDeactivateOrDelete === true ? "Inactive" : "Active";
    };


    this.driverCategoryNameTemplate = (id, row, col, obj) => {
      return <div>{(row.category && row.category.driverCategoryName) ? row.category.driverCategoryName : ''}</div>
    };
    this.driverCategoryNameExportTemplate = (row) => {
      return (row.category && row.category.driverCategoryName) ? row.category.driverCategoryName : '';
    };
    this.driverCategoryNameExportCSVTemplate = (row) => {
      return (row.category && row.category.driverCategoryName) ? row.category.driverCategoryName : '';
    };

    this.vehicleNameTemplate = (id, row, col, obj) => {
      return <div>{(row.defaultVehicle && row.defaultVehicle.vehicleName) ? row.defaultVehicle.vehicleName : ''}</div>
    };
    this.vehicleNameExportTemplate = (row) => {
      return (row.defaultVehicle && row.defaultVehicle.vehicleName) ? row.defaultVehicle.vehicleName : '';
    };
    this.vehicleNameExportCSVTemplate = (row) => {
      return (row.defaultVehicle && row.defaultVehicle.vehicleName) ? row.defaultVehicle.vehicleName : '';
    };

    this.employmentTypeTemplate = (id, row, col, obj) => {
      return <div>{(row.employmentType && row.employmentType.employmentTypeName) ? row.employmentType.employmentTypeName : ''}</div>
    };
    this.employmentTypeExportTemplate = (row) => {
      return (row.employmentType && row.employmentType.employmentTypeName) ? row.employmentType.employmentTypeName : '';
    };
    this.employmentTypeExportCSVTemplate = (row) => {
      return (row.employmentType && row.employmentType.employmentTypeName) ? row.employmentType.employmentTypeName : '';
    };

    this.dateJoinedTemplate = (id, row, col, obj) => {
      return <div>{(row && row.dateJoined) ? moment(row.dateJoined).format(Utils.getDefaultDateFormat()) : ''}</div>
    };
    this.dateJoinedExportTemplate = (row) => {
      return (row && row.dateJoined) ? moment(row.dateJoined).format(Utils.getDefaultDateFormat()) : ''
    };
    this.dateJoinedExportCSVTemplate = (row) => {
      return (row && row.dateJoined) ? moment(row.dateJoined).format(Utils.getDefaultDateFormat()) : ''
    };
    
    this.isAssignJobToPhoneTemplate = (id, row, col, obj) => {
      return row.isAssignJobToPhone === true ? "Yes" : "No";
    };
    this.isAssignJobToPhoneExportTemplate = (row) => {
      return row.isAssignJobToPhone === true ? "Yes" : "No";
    };
    this.isAssignJobToPhoneExportCSVTemplate = (row) => {
      return row.isAssignJobToPhone === true ? "Yes" : "No";
    };

    this.templateNameTemplate = (id, row, col, obj) => {
      return <div>{(row.payrollTemplate && row.payrollTemplate.templateName) ? row.payrollTemplate.templateName : ''}</div>
    };
    this.templateNameExportTemplate = (row) => {
      return (row.payrollTemplate && row.payrollTemplate.templateName) ? row.payrollTemplate.templateName : '';
    };
    this.templateNameExportCSVTemplate = (row) => {
      return (row.payrollTemplate && row.payrollTemplate.templateName) ? row.payrollTemplate.templateName : '';
    };


    let columns = [
      { 
        id: this.pageIdField, 
        filterKey: this.pageIdField, 
        name: this.pageIdField, 
        label: 'ID',
        visible: false
      },
      {
        id: "driverName",
        name: "driverName",
        label: "Name",
        responsiveLabel: "Name",
        orderable: true,
      },
      {
        id: "isDeactivateOrDelete",
        name: "isDeactivateOrDelete",
        label: "Status",
        responsiveLabel: "Status",
        orderable: false,
        template: this.isDeactivateOrDeleteTemplate,
        exportTemplate: this.isDeactivateOrDeleteExportTemplate,
        exportCSVTemplate: this.isDeactivateOrDeleteExportCSVTemplate,
      },
      {
        id: "driverCategoryName",
        name: "category.driverCategoryName",
        label: "Categories",
        responsiveLabel: "Categories",
        orderable: true,
        template: this.driverCategoryNameTemplate,
        exportTemplate: this.driverCategoryNameExportTemplate,
        exportCSVTemplate: this.driverCategoryNameExportCSVTemplate,
      },
      {
        id: "vehicleName",
        name: "defaultVehicle.vehicleName",
        label: "Default vehicle",
        responsiveLabel: "Default vehicle",
        orderable: true,
        template: this.vehicleNameTemplate,
        exportTemplate: this.vehicleNameExportTemplate,
        exportCSVTemplate: this.vehicleNameExportCSVTemplate,
      },
      {
        id: "employmentType",
        name: "employmentType.employmentTypeName",
        label: "Employement type",
        responsiveLabel: "Employement type",
        orderable: true,
        template: this.employmentTypeTemplate,
        exportTemplate: this.employmentTypeExportTemplate,
        exportCSVTemplate: this.employmentTypeExportCSVTemplate,
      },
      {
        id: "dateJoined",
        name: "dateJoined",
        label: "Date Joined",
        responsiveLabel: "Date Joined",
        orderable: true,
        template: this.dateJoinedTemplate,
        exportTemplate: this.dateJoinedExportTemplate,
        exportCSVTemplate: this.dateJoinedExportCSVTemplate,
      },
      {
        id: "isAssignJobToPhone",
        name: "isAssignJobToPhone",
        label: "Access to driver Mobile app",
        responsiveLabel: "Access to driver Mobile app",
        orderable: true,
        template: this.isAssignJobToPhoneTemplate,
        exportTemplate: this.isAssignJobToPhoneExportTemplate,
        exportCSVTemplate: this.isAssignJobToPhoneExportCSVTemplate,
      },
      {
        id: "templateName",
        name: "payrollTemplate.templateName",
        label: "Payroll",
        responsiveLabel: "Payroll",
        orderable: true,
        template: this.templateNameTemplate,
        exportTemplate: this.templateNameExportTemplate,
        exportCSVTemplate: this.templateNameExportCSVTemplate,
      },
      {
        id: "lastPayrollMonthDisplay",
        name: "lastPayrollMonthDisplay",
        label: "Last Run payroll",
        responsiveLabel: "Last Run payroll",
        orderable: false,
      },
    ];
    let initColumns = Array.from(columns);
    columns = Array.from(initColumns);
    
    
    let columnVisibility = [
      false,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
    ];
    let initColumnVisibility = Array.from(columnVisibility);
    columnVisibility = Array.from(initColumnVisibility);


    this.state = {
      data: null,
      err: null,
      isError: false,
      isLoading: false,

      search: '',
      sortColumn: 'updated',
      sortDir: 'desc',
      searchTags: [],
      searchOperator: [],
      searchColumns: [],

      selected: [],
      columnVisibility: columnVisibility,
      initColumnVisibility: initColumnVisibility,
      columns: columns,
      initColumns: initColumns,
      rows: [],
      currentPage: 1,
      totalRows: 0,
      rowsPerPageSelected: 50,
      rowsPerPageOptions: [50, 100, 300],
      
      isCookies: false,
      
      isIncludeInactive: false,

      isDisabledBatchAction: true,
      batchActionLoading: false,
    }
  }


  componentDidMount() {
    this.props.action.driversDetails.resetSlice()
    
    this.getFilterInLocalStorage(() => {
      this.callReadApi();
    });
  }
  
  componentWillReceiveProps(nextProps) {
    this.reduxProps(nextProps);
	}
  

	/* FUNCTIONS */
  onWindowResize = () => {
    this.getFilterInLocalStorage();
  }

  setFilterInLocalStorage = (isCookies = true) => {
    if(this.props.settingsType >= 0){
      let { token, userCookieSettings } = this.props.auth;

      this.setState({
        isCookies: isCookies,
      });
      
      let data = {
        name: this.pageName,
        type: this.props.settingsType,
      };

      let valueLocalStorage = {
        [PageCookieSettingType.currentPage]: this.state.currentPage,
      };
      window.localStorage.setItem(this.pageName + '_' + this.props.settingsType, JSON.stringify(valueLocalStorage));

      let value = {
        [PageCookieSettingType.isCookies]: isCookies,
        [PageCookieSettingType.search]: this.state.search,
        [PageCookieSettingType.sortColumn]: this.state.sortColumn,
        [PageCookieSettingType.sortDir]: this.state.sortDir,
        [PageCookieSettingType.rowsPerPageSelected]: this.state.rowsPerPageSelected,
        [PageCookieSettingType.columnVisibility]: this.state.columnVisibility,
        [PageCookieSettingType.columns]: this.state.columns,
        [PageCookieSettingType.isIncludeInactive]: this.state.isIncludeInactive,
      };
      data['value'] = JSON.stringify(value);

      Utils.saveUserCookieSettings(data, token, (settings) => {
        if(settings){
          let settingsArr = (userCookieSettings && userCookieSettings.length > 0) ? userCookieSettings : [];
          let settingsIndex = Utils.getUserCookieSettingsIndex(settingsArr, this.props.settingsType);
          if(settingsIndex !== -1 && settingsArr[settingsIndex]){
            settingsArr[settingsIndex] = settings;
          } else {
            settingsArr.push(settings);
          }

          this.props.saveUserCookieSettings(settingsArr)
        }
      });
    }
  };
  getFilterInLocalStorage = (callback = null) => {
    let settingsItem = null;

    if(this.props.settingsType >= 0){
      const { userCookieSettings } = this.props.auth;

      settingsItem = Utils.getUserCookieSettings(userCookieSettings, this.props.settingsType, this.pageName);
    }

    let valueLocalStorageStr = window.localStorage.getItem(this.pageName + '_' + this.props.settingsType);
    let valueLocalStorage = JSON.parse(valueLocalStorageStr);
    let currentPageValue = valueLocalStorage && valueLocalStorage[PageCookieSettingType.currentPage] && valueLocalStorage[PageCookieSettingType.currentPage] !== "" ? valueLocalStorage[PageCookieSettingType.currentPage] : this.state.currentPage;
    
    let isCookiesValue = settingsItem && settingsItem[PageCookieSettingType.isCookies] && settingsItem[PageCookieSettingType.isCookies] !== "" ? settingsItem[PageCookieSettingType.isCookies] : this.state.isCookies;
    let searchValue = (settingsItem && settingsItem[PageCookieSettingType.search] && settingsItem[PageCookieSettingType.search] !== '') ? settingsItem[PageCookieSettingType.search] : this.state.search;
    let sortColumnValue = settingsItem && settingsItem[PageCookieSettingType.sortColumn] && settingsItem[PageCookieSettingType.sortColumn] !== "" ? settingsItem[PageCookieSettingType.sortColumn] : this.state.sortColumn;
    let sortDirValue = settingsItem && settingsItem[PageCookieSettingType.sortDir] && settingsItem[PageCookieSettingType.sortDir] !== "" ? settingsItem[PageCookieSettingType.sortDir] : this.state.sortDir;
    let rowsPerPageSelectedValue = settingsItem && settingsItem[PageCookieSettingType.rowsPerPageSelected] && settingsItem[PageCookieSettingType.rowsPerPageSelected] !== "" ? settingsItem[PageCookieSettingType.rowsPerPageSelected] : this.state.rowsPerPageSelected;
    let columnVisibilityValue = (settingsItem && settingsItem[PageCookieSettingType.columnVisibility] && settingsItem[PageCookieSettingType.columnVisibility] !== '') ? settingsItem[PageCookieSettingType.columnVisibility] : this.state.columnVisibility;
    let columnsValue = (settingsItem && settingsItem[PageCookieSettingType.columns] && settingsItem[PageCookieSettingType.columns] !== '') ? settingsItem[PageCookieSettingType.columns] : this.state.columns;
    let isIncludeInactiveValue = (settingsItem && settingsItem[PageCookieSettingType.isIncludeInactive] && settingsItem[PageCookieSettingType.isIncludeInactive] !== '') ? settingsItem[PageCookieSettingType.isIncludeInactive] : this.state.isIncludeInactive;
    
    let columns = Utils.setTemplateFunctionsToColumns(this.state.columns, columnsValue);
    
    this.setState({
      isCookies: isCookiesValue,
      search: searchValue,
      sortColumn: sortColumnValue,
      sortDir: sortDirValue,
      currentPage: currentPageValue,
      rowsPerPageSelected: rowsPerPageSelectedValue,
      columnVisibility: columnVisibilityValue,
      isIncludeInactive: isIncludeInactiveValue,
      columns: columns,
    }, () => {
      if (callback) {
        callback();
      }
    });
  };

  reduxProps = nextProps => {
		Utils.reduxProps(nextProps,
      this.pageName + '-list', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        let rows = (data && data.data && data.data.length > 0) ? data.data : [];
        let totalRows = (data && data.total) ? data.total : 0;
        
        this.setState({
          rows: rows,
          totalRows: totalRows,

          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(callback){
            callback();
          }
        });
      }
    );
  }

  setDataTable = () => {
    return <DataTable 
      ref={this.tableRef}

      className={"table-style-v4"}
      classNameWrapper={"table-v4"}

      pageName={this.pageName}
      rowId={this.pageIdField}
      caption={this.pageCaption}
      
      noMarginBottom={true}
      verticalAlign={'middle'}
      
      stickyHeader={false}
      isHeader={true}
      resizable={true}
      isDragableHeaderColumns={true}
      isDragable={false}
      isCheckbox={true}
      isBorder={false}
      isPagination={false}
      
      columnVisibility={this.state.columnVisibility}
      disabledResizeColumns={[0]}

      isLoading={this.state.isLoading}
      rowsPerPageSelected={this.state.rowsPerPageSelected}
      rowsPerPageOptions={this.state.rowsPerPageOptions}

      search={this.state.search}
      rows={this.state.rows}
      columns={this.state.columns}

      onRowClick={(id, row) => {
        // this.props.action.driversDetails.setShow({ show: true, id: id, isMobileDispatch: false})
        this.props.history.push('/manage/resource/drivers-details?id=' + id);
      }}

      onRemove={(row) => {

      }}

      onClickCheckAll={(obj, checked, isDisabledBatchAction) => {
        this.setState({
          isDisabledBatchAction: isDisabledBatchAction
        });
      }}
      onCheckRow={(obj, id, isDisabledBatchAction) => {
        this.setState({
          isDisabledBatchAction: isDisabledBatchAction
        });
      }}

      onSortEnd={(columns, columnVisibility) => {
        this.setState({
          columns: columns,
          columnVisibility: columnVisibility,
        }, () => {
          this.setFilterInLocalStorage();
        });
      }}
      onOrder={(name, sort) => {
        if(this.tableRef && this.tableRef.current){
            this.tableRef.current.setSelected([], () => {
                this.setState({
                    isDisabledBatchAction: true,
                });
            });
        }
        
        this.setState({
          sortColumn: name,
          sortDir: sort,
        }, () => {
          this.setFilterInLocalStorage();
          this.callReadApi(false);
        });
      }}
      onResize={(columns) => {
        if(columns){
          this.setState({
            columns: columns
          });
        }
        this.setFilterInLocalStorage();
      }}
    />
  }
	/* END FUNCTIONS */

	
	/* API */
  callReadApi = (withStatusCall = true, callback = null) => {
    this.setState({
      isLoading: true,
    });

    let data = {
      currentPage: this.state.currentPage,
      pageSize: this.state.rowsPerPageSelected,
      searchQuery: this.state.search,
      sortColumn: this.state.sortColumn,
      sortDir: this.state.sortDir,
      isIncludeInactive: ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : false,
      includePayrollReport: true,
    };
 
    if (this.state.searchTags && this.state.searchTags.length > 0) {
      data["searchTags"] = Utils.joinItems(this.state.searchTags);
    }
    if (this.state.searchOperator && this.state.searchOperator.length > 0) {
      data["searchOperator"] = Utils.getSelectedOperator(this.state.searchOperator);
    }
    if (this.state.searchColumns && this.state.searchColumns.length > 0) {
      data["searchColumns"] = Utils.getSelectedColumns(this.state.searchColumns);
    }

		this.props.dispatchApiCallGet(data, this.pageName + '-list', this.pageAPI, null, callback, () => {});

    if(withStatusCall){
      
    }
	}
	/* END API */
	
	
	/* SECTIONS */
	actionSection = () => {
		return <Row className={'align-items-center'}>

      {(Utils.hasPermission(this.permissions, PagesPermissions.ManageDrivers.read)) && <Col xs={'auto'} className={'mb-2 mb-sm-0'}>
        <Button
          variant={'success'}
          className={'pt-1 pb-1 rounded-1 '}
          onClick={(e) => {
            this.props.history.push('/manage/resource/drivers');
          }}
        >
        DRIVER
        </Button>
      </Col>}

      {(Utils.hasPermission(this.permissions, PagesPermissions.ManageVehicles.read)) && <Col xs={'auto'} className={'mb-2 mb-sm-0'}>
        <Button
          variant={'light'}
          className={'pt-1 pb-1 rounded-1 bg-white'}
          onClick={(e) => {
            this.props.history.push('/manage/resource/vehicles');
          }}
        >
          VEHICLE
        </Button>
      </Col>}

      {(Utils.hasPermission(this.permissions, PagesPermissions.ManageWaste.read)) && <Col xs={'auto'} className={'mb-2 mb-sm-0'}>
        <Button
          variant={'light'}
          className={'pt-1 pb-1 rounded-1 bg-white'}
          onClick={(e) => {
            this.props.history.push('/manage/resource/waste-types');
          }}
        >
          WASTE
        </Button>
      </Col>}

      {(Utils.hasPermission(this.permissions, PagesPermissions.ManageBins.read)) && <Col xs={'auto'} className={'mb-2 mb-sm-0'}>
        <Button
          variant={'light'}
          className={'pt-1 pb-1 rounded-1 bg-white'}
          onClick={(e) => {
            this.props.history.push('/manage/resource/bin-types');
          }}
        >
          BIN
        </Button>
      </Col>}

      <Col xs={12} md={true} className={'order-1 order-md-3 pb-10 pb-md-0'}>
        <CurrentDateTime />
      </Col>
    </Row>
	}

	toolbarSection = () => {
		return <Row className={'d-block d-md-flex align-items-center'}>

      <Col md={'auto'} className={'pb-2 pb-md-0 d-grid'}>
        <Button
          variant={'success'}
          className={'pt-1 pb-1 rounded-1 border'}
          onClick={(e) => {
            this.props.history.push('/manage/resource/drivers');
          }}
        >
          Driver list
        </Button>
      </Col>
      <Col md={'auto'} className={'pb-2 pb-md-0 d-grid'}>
        <Button
          variant={'light'}
          className={'pt-1 pb-1 rounded-1 border bg-white'}
          onClick={(e) => {
            this.props.history.push('/manage/resource/driver-notes');
          }}
        >
          Driver's notes
        </Button>
      </Col>
      <Col xs={12} className={'d-xs-flex d-lg-none w-100'}></Col>

      <Col xs={'auto'} md={true} className={'text-center text-md-end'}>
        <div className='d-inline-flex align-items-center text-start'>
        <ExpandingSearchBox 
          btnClassName={'manage-icon-btn'}
          value={this.state.search}
          isOpen={(this.state.search !== '') ? true : false}
          onPressEnter={(value) => {
            this.setState({
              search: value,
              currentPage: 1,
            }, () => {
              this.setFilterInLocalStorage();
              this.callReadApi(true);
            });
          }}
          onClear={() => {
            this.setState({
              search: '',
              currentPage: 1,
            }, () => {
              this.setFilterInLocalStorage();
              this.callReadApi(true);
            });
          }}
        />
        </div>
                {Utils.isMobileTablet() && <div className='col-12 d-block d-md-none'></div>}
        <div className='d-inline-flex align-items-center text-start'>

        <DropdownIconButtonMenu 
          ref={this.refDropdownActionMenu}
          className={'job-list-icon-button-dropdown'}
          selected={null}
          icon={<SettingsIcon />}
        >
          <DataTableActionMenu 
            pageName={this.pageName}
            columns={this.state.columns}
            selected={this.state.rowsPerPageSelected}
            selectItems={this.state.rowsPerPageOptions}
            columnVisibility={this.state.columnVisibility}
            onChangeColumnVisibility={(index, value) => {
              let colVis = this.state.columnVisibility;
              colVis[index] = value;

              this.setState({
                columnVisibility: colVis
              }, () => {
                this.setFilterInLocalStorage();
              });
            }}
            canInactive={true}
            isCheckedIncludeInactive={this.state.isIncludeInactive === true || this.state.isIncludeInactive === false ? this.state.isIncludeInactive : false}
            onSelectChangeAjax={(val) => {
              this.setState({
                currentPage: 1,
                rowsPerPageSelected: val
              }, () => {
                this.setFilterInLocalStorage();
                this.callReadApi();
              });
            }}
            onChangeIncludeInactive={(val) => {
              this.setState({
                isIncludeInactive: val,
                currentPage: 1,
              }, () => {
                this.setFilterInLocalStorage();
                this.callReadApi();
              });
            }}
            isCookies={this.state.isCookies}
            onResetColumns={() => {
              this.setState({
                columns: Array.from(this.state.initColumns),
                columnVisibility: Array.from(this.state.initColumnVisibility),
              }, () => {
                this.setFilterInLocalStorage(false);
                if(this.refDropdownActionMenu && this.refDropdownActionMenu.current){
                  this.refDropdownActionMenu.current.hide();
                }
              });
            }}
            onShowMore={(isShowedMore) => {
              this.setState({
                pageName: this.pageName,
              });
            }}
          />
        </DropdownIconButtonMenu>
        </div>
      </Col>
    </Row>
	}
  
	topSectionChecked = () => {
    return <Row className={"align-items-center"}>
      <Col xs={12} md={'auto'}>
        <Dropdown
          title="Batch Action"
          onSelect={async (eventKey: string) => {
            let selected = '';
            if(this.tableRef && this.tableRef.current){
              let selectedArr = this.tableRef.current.getSelected();
              if(selectedArr && selectedArr.length > 0){
                selected = selectedArr.join(',');
              }
            }
            
            if(eventKey === 'active'){
              await this.props.action.signalRslice.connect({
                callback: (percent) => {
                  this.props.action.signalRslice.onPercentage(percent);
                },
                callbackInit: async (hubId) => {
                  await this.props.action.driversDetails.callBatchActiveApi(hubId, selected, true, async (state) => {
                    if(state){
                      if(this.tableRef && this.tableRef.current){
                        this.tableRef.current.setSelected([], () => {
                          this.setState({
                            isDisabledBatchAction: true,
                          });
                        });
                      }
                      this.callReadApi();
                    }
                  });
                  this.props.action.signalRslice.disconnect();
                }
              });



            } else if(eventKey === 'inactive'){
              await this.props.action.signalRslice.connect({
                callback: (percent) => {
                  this.props.action.signalRslice.onPercentage(percent);
                },
                callbackInit: async (hubId) => {
                  await this.props.action.driversDetails.callBatchActiveApi(hubId, selected, false, async (state) => {
                    if(state){
                      if(this.tableRef && this.tableRef.current){
                        this.tableRef.current.setSelected([], () => {
                          this.setState({
                            isDisabledBatchAction: true,
                          });
                        });
                      }
                      this.callReadApi();
                    }
                  });
                  this.props.action.signalRslice.disconnect();
                }
              });



            } else if(eventKey === 'run-this-month'){
              await this.props.action.signalRslice.connect({
                callback: (percent) => {
                  this.props.action.signalRslice.onPercentage(percent);
                },
                callbackInit: async (hubId) => {
                  let date = moment();

                  await this.props.action.driversDetails.callBatchGenerateApi(hubId, selected, date, async (state) => {
                    if(this.tableRef && this.tableRef.current){
                      this.tableRef.current.setSelected([], () => {
                        this.setState({
                          isDisabledBatchAction: true,
                        });
                      });
                    }
                  });
                  this.props.action.signalRslice.disconnect();
                }
              });

            } else if(eventKey === 'run-last-month'){
              await this.props.action.signalRslice.connect({
                callback: (percent) => {
                  this.props.action.signalRslice.onPercentage(percent);
                },
                callbackInit: async (hubId) => {
                  let date = moment().subtract(1, 'month');

                  await this.props.action.driversDetails.callBatchGenerateApi(hubId, selected, date, async (state) => {
                    if(this.tableRef && this.tableRef.current){
                      this.tableRef.current.setSelected([], () => {
                        this.setState({
                          isDisabledBatchAction: true,
                        });
                      });
                    }
                  });
                  this.props.action.signalRslice.disconnect();
                }
              });




            } else if(eventKey === 'export-this-month'){
              await this.props.action.signalRslice.connect({
                callback: (percent) => {
                  this.props.action.signalRslice.onPercentage(percent);
                },
                callbackInit: async (hubId) => {
                  let date = moment();

                  await this.props.action.driversDetails.callExportApi(hubId, selected, date, async (date, type, data) => {
                    if(data){
                      if(this.tableRef && this.tableRef.current){
                        this.tableRef.current.setSelected([], () => {
                          this.setState({
                            isDisabledBatchAction: true,
                          });
                        });
                      }

                      let name = moment(date).format('MMM yyyy');
                      Utils.saveByteArray(name, data, type);
                    }
                  });
                  this.props.action.signalRslice.disconnect();
                }
              });

            } else if(eventKey === 'export-last-month'){
              await this.props.action.signalRslice.connect({
                callback: (percent) => {
                  this.props.action.signalRslice.onPercentage(percent);
                },
                callbackInit: async (hubId) => {
                  let date = moment().subtract(1, 'month');

                  await this.props.action.driversDetails.callExportApi(hubId, selected, date, async (date, type, data) => {
                    if(data){
                      if(this.tableRef && this.tableRef.current){
                        this.tableRef.current.setSelected([], () => {
                          this.setState({
                            isDisabledBatchAction: true,
                          });
                        });
                      }
                      
                      let name = moment(date).format('MMM yyyy');
                      Utils.saveByteArray(name, data, type);
                    }
                  });
                  this.props.action.signalRslice.disconnect();
                }
              });
            }
          }}
        >
          <Dropdown.Item eventKey="active">Make active</Dropdown.Item>
          <Dropdown.Item eventKey="inactive">Make inactive</Dropdown.Item>
          <Dropdown.Menu title="Run payroll for">
            <Dropdown.Item eventKey="run-this-month">This month</Dropdown.Item>
            <Dropdown.Item eventKey="run-last-month">Last month</Dropdown.Item>
          </Dropdown.Menu>
          <Dropdown.Menu title="Export payroll for">
            <Dropdown.Item eventKey="export-this-month">This month</Dropdown.Item>
            <Dropdown.Item eventKey="export-last-month">Last month</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
      <Col xs={12} md={true}>
        <span>{((this.tableRef && this.tableRef.current) ? this.tableRef.current.getSelected().length : '0')} item(s) selected</span>
      </Col>
      <Col xs={true}></Col>
      <Col xs={'auto'} className={'text-center'}>
        <WrappedButton
          className={'px-3 pe-1'}
          variant={"light"}
          size={'sm'}
          style={{ backgroundColor: 'transparent' }}
          onClick={() => {
            if(this.tableRef && this.tableRef.current){
              this.tableRef.current.setSelected([], () => {
                this.setState({
                  isDisabledBatchAction: true,
                });
              });
            }
          }}
        >
          <i className={'material-icons'}>close</i>
        </WrappedButton>
      </Col>
    </Row>
  }

	tableSection = () => {
		return <DataTableScrollSync ref={this.refDataTableScrollSync} className={"table-sync-v4"}>{this.setDataTable()}</DataTableScrollSync>
	}

	paginationSection = () => {
		return <DataTableAjaxPagination
      page={this.state.currentPage}
      selected={this.state.rowsPerPageSelected}
      count={this.state.totalRows}
      onPageChange={(page) => {
        this.setState({
          currentPage: page,
        }, () => {
          this.setFilterInLocalStorage();
          this.callReadApi(false);
        });
      }}
    />
	}
	/* END SECTIONS */


  render() {
    return (
      <>
        <ViewLayout
          isBlocking={this.state.batchActionLoading}
          title={'RESOURCES'}
          col={<Col xs={true} className={'text-end mb-md-0'}>
            <Button
              variant="primary"
              onClick={() => {
                // this.props.action.driversDetails.setShow({ show: true, id: null, isMobileDispatch: false})
                this.props.history.push('/manage/resource/drivers-details');
              }}
              >NEW</Button>
          </Col>}
          actionSection={this.actionSection()}
          toolbarSection={<>
            <ViewTopSection
              hasChecked={(!this.state.isDisabledBatchAction)}
              unchecked={this.toolbarSection()}
              checked={this.topSectionChecked()}
            />
          </>}
          tableSection={this.tableSection()}
          paginationSection={this.paginationSection()}
        />

        {/* <DriversDetails
          sortColumn={this.state.sortColumn}
          sortDir={this.state.sortDir}
          onCreate={(data) => {
            this.callReadApi();
          }}
          onUpdate={(data) => {
            this.callReadApi();
          }}
        /> */}
      </>
    );
  }
}


const mapStateToProps = state => {
  return {
    ...state,
    ...Utils.mapStateToProps(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  dispatchApiCallGet: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallGet(p1, p2, p3, p4, p5, p6)),
  dispatchApiCallPost: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallPost(p1, p2, p3, p4, p5, p6)),
  dispatchApiCallPut: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallPut(p1, p2, p3, p4, p5, p6)),
  dispatchApiCallDelete: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallDelete(p1, p2, p3, p4, p5, p6)),
  saveUserCookieSettings: (payload) => dispatch(saveUserCookieSettings(payload)),
  action: {
    driversDetails: {
      // setShow: (payload) => dispatch(driversDetails.setShow(payload)),
      resetSlice: (payload) => dispatch(driversDetails.resetSlice(payload)),
      callBatchGenerateApi: (hubId: any, ids: any, date: any, callback: (state: boolean) => void) => dispatch(driversDetails.callBatchGenerateApi(hubId, ids, date, callback)),
      callExportApi: (hubId: any, ids: any, date: any, callback: (state: boolean) => void) => dispatch(driversDetails.callExportApi(hubId, ids, date, callback)),
      callBatchActiveApi: (hubId: any, ids: any, isActive: boolean, callback: (state: boolean) => void) => dispatch(driversDetails.callBatchActiveApi(hubId, ids, isActive, callback)),
    },
    signalRslice: {
      connect: (payload) => dispatch(signalRslice.connect(payload)),
      disconnect: (payload) => dispatch(signalRslice.disconnect(payload)),
      onPercentage: (payload) => dispatch(signalRslice.onPercentage(payload)),
    },
  }
});


export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DriversView);
