import React from "react";

import { 
  Button,
  Row,
  Col
} from "react-bootstrap-v5";

const ViewTopSection = (props) =>  {
  return (
    <Row className="view-top-section-layout align-items-center">
      <Col xs={12} sm={true} className={'mb-2 mb-md-0'}>
        <h1 className={'title mx-0'}>DASHBOARD</h1>
      </Col>
      <Col xs={true} sm={'auto'} className={'text-end mb-2 mb-md-0'}>      
      <a className="btn btn-primary w-100" 
          href={'/jobs-form'}
        >CREATE JOB</a>  
      </Col>
    </Row>
  )
}

export default ViewTopSection;
