/* eslint-disable no-loop-func */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import * as dispatchCrudApi from '../../../../api/CRUD/DispatchCRUD'
import Utils from "../../../../utils/utils";
import { ParseResult } from "../../../../utils/interfaces";


interface InitState {
  show: boolean,
  isLoading: boolean,
  items: Array<any>,
  
  currentPage: number|null|undefined,
  pageSize: number|null|undefined,
  searchQuery: string|null|undefined,
  sortColumn: string|null|undefined,
  sortDir: string|null|undefined,
  quickBookAppId: number|null,
  
  columns: Array<any>,
  columnVisibility: Array<any>,
  total: number,
  
  chartOfAccountItems: Array<any>,
  chartOfAccountIsLoading: boolean,

  purchaseChartOfAccountItems: Array<any>,
  purchaseChartOfAccountIsLoading: boolean,
}

interface ApiCallArgs {
  currentPage?: number,
  pageSize?: number,
  searchQuery?: string,
  sortColumn?: string,
  sortDir?: string,
}


function NewReducer() {
  const name = 'advancedMappingQuickBook';

  const initArgs: ApiCallArgs = {
    currentPage: 1,
    pageSize: 50,
    searchQuery: '',
    sortColumn: 'chargeCategoryName',
    sortDir: 'asc',
  }

  const columnVisibility: Array<any> = [
    false,
    true,
    true,
    true,
    true,
    true,
  ];


  const initialState: InitState = {
    show: false,
    isLoading: false,
    items: [],
    
    currentPage: initArgs.currentPage,
    pageSize: initArgs.pageSize,
    searchQuery: initArgs.searchQuery,
    sortColumn: initArgs.sortColumn,
    sortDir: initArgs.sortDir,
    quickBookAppId: null,

    columns: [],
    columnVisibility: columnVisibility,
    total: 0,
    
    chartOfAccountItems: [],
    chartOfAccountIsLoading: true,
    
    purchaseChartOfAccountItems: [],
    purchaseChartOfAccountIsLoading: true,
  };


  const reducers = {
    show: (state: InitState, action: PayloadAction<number|null>) => {
      state.quickBookAppId = action.payload;
      state.items = [];
      state.isLoading = false;
      state.show = true;
    },
    hide: (state: InitState) => {
      state.quickBookAppId = null;
      state.items = [];
      state.isLoading = false;
      state.show = false;
    },

    setColumns: (state: InitState, action: PayloadAction<Array<any>>) => {
      state.columns = action.payload;
    },
    setItems: (state: InitState, action: PayloadAction<Array<any>>) => {
      state.items = action.payload;
    },


    startRead: (state: InitState, action: PayloadAction<ApiCallArgs>) => {
      state.currentPage = action.payload.currentPage;
      state.pageSize = action.payload.pageSize;
      state.searchQuery = action.payload.searchQuery;
      state.sortColumn = action.payload.sortColumn;
      state.sortDir = action.payload.sortDir;

      state.items = [];
      state.isLoading = true;
    },
    finishRead: (state: InitState, action: PayloadAction<{ args: ApiCallArgs, data: any, errorMessage: string|null, isError: boolean }>) => {
      let data = (action.payload && action.payload.data && action.payload.data.data  && action.payload.data.data.length > 0) ? action.payload.data.data : [];
      let total = (action.payload && action.payload.data && action.payload.data.total) ? action.payload.data.total : 0;
      
      state.items = data;
      state.total = total;
      state.isLoading = false;
    },

    startChartOfAccount: (state: InitState) => {
      state.isLoading = true;
      state.chartOfAccountItems = [];
      state.chartOfAccountIsLoading = true;
    },
    finishChartOfAccount: (state: InitState, action: PayloadAction<{ data: any, errorMessage: string|null, isError: boolean }>) => {
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      
      let arr = [];
      if(data && data.length > 0) {
        for (var i = 0; i < data.length; i++) {
            let item = data[i];
            let value = item.id;
            let title = item.fullyQualifiedName;
            let type = item.domain;
            let arrItem: any = {
                value: value,
                title: title,
                type: type,
                item: item,
            };

            arr = Utils.addToArray(arr, value, arrItem);
        }
      }

      state.chartOfAccountItems = arr;
      state.chartOfAccountIsLoading = false;
      state.isLoading = false;
    },

    startPurchaseChartOfAccount: (state: InitState) => {
      state.isLoading = true;
      state.purchaseChartOfAccountItems = [];
      state.purchaseChartOfAccountIsLoading = true;
    },
    finishPurchaseChartOfAccount: (state: InitState, action: PayloadAction<{ data: any, errorMessage: string|null, isError: boolean }>) => {
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      
      let arr = [];
      if(data && data.length > 0) {
        for (var i = 0; i < data.length; i++) {
            let item = data[i];
            let value = item.id;
            let title = item.fullyQualifiedName;
            let type = item.domain;
            let arrItem: any = {
                value: value,
                title: title,
                type: type,
                item: item,
            };

            arr = Utils.addToArray(arr, value, arrItem);
        }
      }

      state.purchaseChartOfAccountItems = arr;
      state.purchaseChartOfAccountIsLoading = false;
      state.isLoading = false;
    },
    
    startUpdate: (state: InitState) => {
      state.chartOfAccountIsLoading = true;
    },
    finishUpdate: (state: InitState, action: PayloadAction<{ data: any, errorMessage: string|null, isError: boolean }>) => {
      state.chartOfAccountIsLoading = false;
    },
  };


  const apis = {
    callReadApi: (params: ApiCallArgs) => async (dispatch: any, getState: any) => {
      const { quickBookAppId } = getState().advancedMappingQuickBook;

      const args: any = { ...initArgs, ...params, quickBookAppId: quickBookAppId };

      dispatch(actions.startRead(args));

      await dispatchCrudApi.readApi(args, 'ChargeCategory').then((result: any) => {
        dispatch(actions.finishRead({ args: args, data: result.data, errorMessage: null, isError: false }));
      }).catch((error: any) => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        dispatch(actions.finishRead({ args: args, data: null, errorMessage: err, isError: true }));
      });

    },
    
    callQuickBookReadChartOfAccountApi: (quickBookAppId: number|null) => async (dispatch: any) => {
      dispatch(actions.startChartOfAccount());

      let params: any = { quickBookAppId: quickBookAppId, isInvoice: true };

      await dispatchCrudApi.readApi(params, 'QuickBookApp/income-accounts').then((result: any) => {
        dispatch(actions.finishChartOfAccount({ data: result.data, errorMessage: null, isError: false }));
      }).catch((error: any) => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        dispatch(actions.finishChartOfAccount({ data: null, errorMessage: err, isError: true }));
      });

    },

    callQuickBookReadPurchaseChartOfAccountApi: (quickBookAppId: number|null) => async (dispatch: any) => {
      dispatch(actions.startPurchaseChartOfAccount());

      let params: any = { quickBookAppId: quickBookAppId, isInvoice: false, isPurchase: true };

      await dispatchCrudApi.readApi(params, 'QuickBookApp/purchase-accounts').then((result: any) => {
        dispatch(actions.finishPurchaseChartOfAccount({ data: result.data, errorMessage: null, isError: false }));
      }).catch((error: any) => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        dispatch(actions.finishPurchaseChartOfAccount({ data: null, errorMessage: err, isError: true }));
      });

    },
    
    callUpdateApi: (params: any) => async (dispatch: any) => {
      dispatch(actions.startUpdate());

      await dispatchCrudApi.updateApi(params, 'QuickBookApp/charge-category-item').then((result: any) => {
        dispatch(actions.finishUpdate({ data: result.data, errorMessage: null, isError: false }));
        Utils.toast('You have successfully changed the default product account field', 'success');
      }).catch((error: any) => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        dispatch(actions.finishUpdate({ data: null, errorMessage: err, isError: true }));
      });

    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();