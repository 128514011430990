import React from "react";

import _ from 'lodash';

import { 
    Row,
    Col,
    Form,
    Button,
    Spinner,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import { Formik } from 'formik';
import * as yup from 'yup';

import Utils from "../../../utils/utils";
import { CustomerSiteAvailability, AccountSettingType } from "../../../utils/enums";
import { readApi } from '../../../api/CRUD/DispatchCRUD'

import LocationDialog from '../dialog/LocationDialog';

import InputLayout from "../../../components/input/InputLayout";
import SmartInputDropdown from '../../../components/dropdown/smartInputDropdown/SmartInputDropdown';
import makeCRUD from "../../../components/dropdown/smartInputDropdown/makeCRUD";
import StaticGoogleMap from '../../../components/googleMap/StaticGoogleMap';
import RSuiteCheckPicker from '../../../components/OLD/Checkbox/RSuiteCheckPicker';
import RemoveDialog from '../../../components/dialog/RemoveDialog';
import PhoneField from '../../../components/phone/PhoneField';

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut, dispatchApiCallDelete } from '../../../../setup/redux/dispatch/actions'


const SmartDropdown = makeCRUD(SmartInputDropdown);


const StyledButton = styled(Button)`
    background-color: white !important;
    border: 1px solid #DFDFDF !important;
`;
const StyledFormControl = styled(Form.Control)`
    background-color: transparent !important;
    border: 0px !important;

    &[readonly] {
        color: #484848 !important;
        cursor: text !important;
    }
`;


const formFields = {
    siteName: {
        id: 'siteName',
        label: 'Site Name',
        placeholder: ' ',
    },
    street: {
        id: 'street',
        label: 'Street Name',
        placeholder: ' ',
    },
    blockNo: {
        id: 'blockNo',
        label: 'Block Number',
        placeholder: ' ',
    },
    unitNo: {
        id: 'unitNo',
        label: 'Unit Number',
        placeholder: ' ',
    },
    postalCode: {
        id: 'postalCode',
        label: 'Post Code',
        placeholder: ' ',
    },
    coordinates: {
        id: 'coordinates',
        label: 'Coordinates',
        placeholder: ' ',
    },
    isActive: {
        id: 'isActive',
        label: 'Status',
        placeholder: ' ',
    },
    zoneId: {
        id: 'zoneId',
        label: 'Group As',
        placeholder: ' ',
    },
    contactPersonName: {
        id: 'contactPersonName',
        label: 'Contact 1',
        placeholder: 'Person 1',
    },
    contactPersonPhoneNumber: {
        id: 'contactPersonPhoneNumber',
        label: 'Phone 1',
        placeholder: 'Phone 1',
    },
    contactPersonNameTwo: {
        id: 'contactPersonNameTwo',
        label: 'Contact 2',
        placeholder: 'Person 2',
    },
    contactPersonPhoneNumberTwo: {
        id: 'contactPersonPhoneNumberTwo',
        label: 'Phone 2',
        placeholder: 'Phone 2',
    },
    isContactPersonSendNotification: {
        id: 'isContactPersonSendNotification',
        label: 'Notification',
        placeholder: ' ',
    },
    isContactPersonTwoSendNotification: {
        id: 'isContactPersonTwoSendNotification',
        label: 'Notification',
        placeholder: ' ',
    },
    // vehicleId: {
    //     id: 'vehicleId',
    //     label: 'Default Vehicles(s)',
    //     placeholder: ' ',
    // },
    driverId: {
        id: 'driverId',
        label: 'Default Driver(s)',
        placeholder: ' ',
    },
    weekdayId: {
        id: 'weekdayId',
        label: 'Weekdays Availability',
        placeholder: ' ',
    },
    weekendId: {
        id: 'weekendId',
        label: 'Weekends Availability',
        placeholder: ' ',
    },
    remarks: {
        id: 'remarks',
        label: <>Site Remarks<br />(Internal Note)</>,
        placeholder: Utils.placeholderRows(5),
    },
    instructions: {
        id: 'instructions',
        label: <>Driver Remarks<br />(Appear On Driver App)</>,
        placeholder: Utils.placeholderRows(5),
    },
    copySiteRemarksToJob: {
        id: 'copySiteRemarksToJob',
        label: 'Copy Site Remarks To Job Form',
        placeholder: ' ',
    },
}
const formSchema = yup.object().shape({
    isEdit: yup.bool().oneOf([true, false]),
    siteName: yup.string().required().label(formFields.siteName.label),
    street: yup.string().label(formFields.street.label),
    blockNo: yup.string().label(formFields.blockNo.label),
    unitNo: yup.string().label(formFields.unitNo.label),
    postalCode: yup.string().label(formFields.postalCode.label),
    coordinates: yup.string().required().label(formFields.coordinates.label),
    isActive: yup.bool().oneOf([true, false]),
    zoneId: yup.number().nullable().label(formFields.zoneId.label),
    remarks: yup.string().label(formFields.remarks.label),
    instructions: yup.string().label(formFields.instructions.label),
    contactPersonName: yup.string().label(formFields.contactPersonName.label),
    contactPersonPhoneNumber: yup.string().nullable().label(formFields.contactPersonPhoneNumber.label),
    contactPersonNameTwo: yup.string().label(formFields.contactPersonNameTwo.label),
    contactPersonPhoneNumberTwo: yup.string().nullable().label(formFields.contactPersonPhoneNumberTwo.label),
    isContactPersonSendNotification: yup.bool().oneOf([true, false]),
    isContactPersonTwoSendNotification: yup.bool().oneOf([true, false]),
    weekdayId: yup.array().nullable().label(formFields.weekdayId.label),
    weekendId: yup.array().nullable().label(formFields.weekendId.label),
    // vehicleId: yup.array().nullable().label(formFields.vehicleId.label),
    driverId: yup.array().nullable().label(formFields.driverId.label),
    copySiteRemarksToJob: yup.bool().oneOf([true, false]).label(formFields.copySiteRemarksToJob.label),
});


class CustomerSiteForm extends React.Component {
  
    constructor(props) {
        super(props);

        let customerId = Utils.getIntProps(props, 'customerId');

        this.pageName = 'customer_site_form';
        this.backLink = '/Customer-overview-sites?id=' + customerId;

        this.refForm = React.createRef();
        this.refInput = React.createRef();
        
        let id = Utils.getIntProps(props);
        let isCopy = Utils.getBoolProps(props);


        this.state = {
            customerId: customerId,

            id: id,
            isCopy: isCopy,
            details: null,
            isLoadingMap: true,
            
            isLocationDialog: false,
            canLocationDialogClose: true,
            locationDialogTitle: null,

            isRemoveDialog: false,

            lat: 1.353915,
            lng: 103.822901,
            zoom: 16,

            initialValues: {
                isEdit: false,

                customSiteName: '',
                siteName: '',
                street: '',
                blockNo: '',
                unitNo: '',
                postalCode: '',
                latitude: '',
                longitude: '',
                coordinates: '',
                isActive: true,
                zoneId: null,
                zoneName: '',
                remarks: '',
                instructions: '',
                contactPersonName: '',
                contactPersonPhoneNumber: '',
                contactPersonNameTwo: '',
                contactPersonPhoneNumberTwo: '',
                isContactPersonSendNotification: false,
                isContactPersonTwoSendNotification: false,
                // vehicleId: null,
                driverId: null,
                weekdayId: null,
                weekendId: null,
                weekdaysAvailability: CustomerSiteAvailability.All,
                weekendAvailability: CustomerSiteAvailability.All,
                copySiteRemarksToJob: false,
                customFormParams: {
                    // vehicleItems: [],
                    // vehicleIsLoading: false,

                    driverItems: [],
                    driverIsLoading: false,

                    weekdayItems: [],
                    weekdayIsLoading: false,
                    isApplyAllWeekdayTimes: true,
                    weekdayIsSelectAll: false,

                    weekendItems: [],
                    weekendIsLoading: false,
                    isApplyAllWeekendTimes: true,
                    weekendIsSelectAll: false,
                }
            },
        };
    }


    componentDidMount(){
        this.setLayoutForm();
        this.callInitApis();

        if(this.state.id == null){
            this.setState({
                isLocationDialog: true,
                canLocationDialogClose: false,
                locationDialogTitle: 'New Location',
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            this.pageName + '-create', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(data, isError);
                    }
                });
            }
        );
    
        Utils.reduxProps(nextProps,
            this.pageName + '-update', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(data, isError);
                    }
                });
            }
        );
    
        Utils.reduxProps(nextProps,
            this.pageName + '-delete', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(data, isError);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-copy', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(data, isError);
                    }
                });
            }
        );
    
        Utils.reduxProps(nextProps,
            this.pageName + '-vehicles_list', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.vehicleId;
                        let title = item.vehicleName;

                        let defaultDriver = (item && item.defaultDriver) ? item.defaultDriver : null;
                        let driverName = (defaultDriver && defaultDriver.driverName) ? defaultDriver.driverName : null;

                        let arrItem = {
                            value: value,
                            title: Utils.customConcat([driverName, title], ' / '),
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            this.pageName + '-drivers_list', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.driverId;
                        let title = item.driverName;

                        let defaultVehicle = (item && item.defaultVehicle) ? item.defaultVehicle : null;
                        let vehicleName = (defaultVehicle && defaultVehicle.vehicleName) ? defaultVehicle.vehicleName : null;

                        let arrItem = {
                            value: value,
                            title: Utils.customConcat([title, vehicleName], ' / '),
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            this.pageName + '-weekday_list', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.accountJobWorkingTimeId;
                        let title = item.workingTimeName;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-weekend_list', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.accountJobWorkingTimeId;
                        let title = item.workingTimeName;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );
    }
	
    callInitApis = () => {
        Utils.callAllApi([
            {
                api: readApi,
                path: 'customerSite/' + this.state.id,
                params: null,
                shouldCall: (this.state.id > 0) ? true : false,
                callback: (result) => {
                    Utils.parseResult(result, (details) => {
                        if(details){
                            this.setLayoutTitle(this.state.isCopy ? 'Copy Site' : 'Edit Site');
                            this.prepareForm(details);
                            this.setLoading(false);
                        } else {
                            this.setLayoutTitle('Add Site');
                        }
                    });
                }
            },
            {
                api: readApi,
                path: 'AccountSetting/type/' + AccountSettingType.DefaultMapPositionLatitude,
                params: null,
                shouldCall: (this.state.id > 0) ? false : true,
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        let lat = (data && data.value && data.value != '') ? parseFloat(data.value) : 1.353915;
                        this.setState({
                            lat: lat,
                        });
                    });
                }
            },
            {
                api: readApi,
                path: 'AccountSetting/type/' + AccountSettingType.DefaultMapPositionLongitude,
                params: null,
                shouldCall: (this.state.id > 0) ? false : true,
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        let lng = (data && data.value && data.value != '') ? parseFloat(data.value) : 103.822901;
                        this.setState({
                            lng: lng,
                        });
                    });
                }
            },
            {
                api: readApi,
                path: 'AccountSetting/type/' + AccountSettingType.DefaultMapPositionZoom,
                params: null,
                shouldCall: (this.state.id > 0) ? false : true,
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        let zoom = (data && data.value && data.value != '') ? parseInt(data.value) : 16;
                        this.setState({
                            zoom: zoom,
                        });
                    });
                }
            }
        ], (result) => {
            this.setState({
                isLoadingMap: true,
            })
            this.setLoading(true);
        }, (result) => {
            this.setState({
                isLoadingMap: false,
            })
            this.setLoading(false);
        });
    }

    callCreateApi = (data, callback = null) => {
        this.setLoading(true);
        this.props.dispatchApiCallPost(data, this.pageName + '-create', 'customerSite', data, callback, () => {});
    }

    callUpdateApi = (data, callback = null) => {
        this.setLoading(true);
        this.props.dispatchApiCallPut(data, this.pageName + '-update', 'customerSite', data, callback, () => {});
    }

    callDeleteApi = (data, callback = null) => {
        this.setLoading(true);
        this.props.dispatchApiCallDelete(data, this.pageName + '-delete', 'customerSite', data, callback, () => {});
    }

    callCopyApi = (data, callback = null) => {
        this.setLoading(true);

        data['isCopy'] = true;
        delete data['customerSiteId'];
    
        this.props.dispatchApiCallPost(data, this.pageName + '-copy', 'customerSite', data, callback, null);
    }

    callReadVehicleApi = (items, callback = null) => {
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            sortColumn: 'updated',
            sortDir: 'asc',
        };
     
        this.props.dispatchApiCallGet(data, this.pageName + '-vehicles_list', 'vehicle', { items: items }, callback, () => {});
    }
    
    callReadDriverApi = (items, callback = null) => {
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            sortColumn: 'updated',
            sortDir: 'asc',
        };
     
        this.props.dispatchApiCallGet(data, this.pageName + '-drivers_list', 'driver', { items: items }, callback, () => {});
    }

    callReadWeekdayTimesApi = (items, callback = null) => {
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            sortColumn: 'order',
            sortDir: 'asc',
        };
     
        this.props.dispatchApiCallGet(data, this.pageName + '-weekday_list', 'AccountJobWorkingTime', { items: items }, callback, () => {});
    }

    callReadWeekendTimesApi = (items, callback = null) => {
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            sortColumn: 'order',
            sortDir: 'asc',
        };
     
        this.props.dispatchApiCallGet(data, this.pageName + '-weekend_list', 'AccountJobWorkingTime', { items: items }, callback, () => {});
    }
    /* END API */


    /* LAYOUT FUNCTIONS */
    setLayoutForm = (options) => {
        if(this.props.layoutRef && this.props.layoutRef.current){
            this.props.layoutRef.current.setLayoutForm(this, options);
        }
    }
    setLayoutLoading = (state = false) => {
        if(this.props.layoutRef && this.props.layoutRef.current){
            this.props.layoutRef.current.setLayoutLoading(state);
        }
    }
    setLayoutTitle = (title = '') => {
        if(this.props.layoutRef && this.props.layoutRef.current){
            this.props.layoutRef.current.setlayoutTitle(title);
        }
    }
    setLayoutSubmit = (form) => {
        if(this.props.layoutRef && this.props.layoutRef.current){
            this.props.layoutRef.current.setLayoutSubmit(form);
        }
    }
    /* END LAYOUT FUNCTIONS */


    /* FUNCTIONS */
    setLoading = (state = false) => {
        this.setLayoutLoading(state);

        if(this.props.onLoading){
            this.props.onLoading(state);
        }
    }
    prepareForm = (data) => {
        if(data){
            var newData = _.cloneDeep(data);

            let latitude = (newData && newData.latitude && newData.latitude !== '') ? newData.latitude : '';
            let longitude = (newData && newData.longitude && newData.longitude !== '') ? newData.longitude : '';
            let coordinates = Utils.joinCoordinates(latitude, longitude);

            let zoneItem = (newData && newData.zone) ? newData.zone : null;
            let zoneId = (zoneItem && zoneItem.zoneId) ? zoneItem.zoneId : null;
            let zoneName = (zoneItem && zoneItem.zoneName) ? zoneItem.zoneName : '';

            // let vehicleId = [];
            // let vehicleItems = [];
            // let defaultVehicles = (newData && newData.defaultVehicles) ? newData.defaultVehicles : [];
            // if(defaultVehicles && defaultVehicles.length > 0){
            //     vehicleItems = defaultVehicles.map((item, i) => {
            //     vehicleId.push(item.vehicleId);

            //     let defaultDriver = (item && item.defaultDriver) ? item.defaultDriver : null;
            //     let driverName = (defaultDriver && defaultDriver.driverName) ? defaultDriver.driverName : null;

            //     return {
            //         value: item.vehicleId,
            //         title: (driverName) ? driverName + '/' + item.vehicleName : item.vehicleName,
            //         item: item,
            //     }
            //     });
            // }
            
            let driverId = [];
            let driverItems = [];
            let defaultDrivers = (newData && newData.defaultDrivers) ? newData.defaultDrivers : [];
            if(defaultDrivers && defaultDrivers.length > 0){
                driverItems = defaultDrivers.map((item, i) => {
                    driverId.push(item.driverId);

                let defaultVehicle = (item && item.defaultVehicle) ? item.defaultVehicle : null;
                let vehicleName = (defaultVehicle && defaultVehicle.vehicleName) ? defaultVehicle.vehicleName : null;

                return {
                    value: item.driverId,
                    title: Utils.customConcat([item.driverName, vehicleName], ' / '),
                    item: item,
                }
                });
            }

            
            let weekdayId = [];
            let weekdayItems = [];
            let customerSiteWeekdayJobWorkingTimes = (newData && newData.customerSiteWeekdayJobWorkingTimes) ? newData.customerSiteWeekdayJobWorkingTimes : [];
            if(customerSiteWeekdayJobWorkingTimes && customerSiteWeekdayJobWorkingTimes.length > 0){
                weekdayItems = customerSiteWeekdayJobWorkingTimes.map((item, i) => {
                weekdayId.push(item.accountJobWorkingTimeId);

                return {
                    value: item.accountJobWorkingTime.accountJobWorkingTimeId,
                    title: item.accountJobWorkingTime.workingTimeName,
                    item: item.accountJobWorkingTime,
                }
                });
            }


            let weekendId = [];
            let weekendItems = [];
            let customerSiteWeekendJobWorkingTimes = (newData && newData.customerSiteWeekendJobWorkingTimes) ? newData.customerSiteWeekendJobWorkingTimes : [];
            if(customerSiteWeekendJobWorkingTimes && customerSiteWeekendJobWorkingTimes.length > 0){
                weekendItems = customerSiteWeekendJobWorkingTimes.map((item, i) => {
                weekendId.push(item.accountJobWorkingTimeId);
                
                return {
                    value: item.accountJobWorkingTime.accountJobWorkingTimeId,
                    title: item.accountJobWorkingTime.workingTimeName,
                    item: item.accountJobWorkingTime,
                }
                });
            }
            
            
            let weekdaysAvailability = (newData && newData.weekdaysAvailability) ? newData.weekdaysAvailability : CustomerSiteAvailability.All;
            let weekendAvailability = (newData && newData.weekendAvailability) ? newData.weekendAvailability : CustomerSiteAvailability.All;
            let isActive = ((newData.isDeactivateOrDelete === false) || (newData.isDeactivateOrDelete === true)) ? !newData.isDeactivateOrDelete : false;
            let copySiteRemarksToJob = ((newData.copySiteRemarksToJob === false) || (newData.copySiteRemarksToJob === true)) ? newData.copySiteRemarksToJob : false;

            let isContactPersonSendNotification = ((newData.isContactPersonSendNotification === false) || (newData.isContactPersonSendNotification === true)) ? newData.isContactPersonSendNotification : false;
            let isContactPersonTwoSendNotification = ((newData.isContactPersonTwoSendNotification === false) || (newData.isContactPersonTwoSendNotification === true)) ? newData.isContactPersonTwoSendNotification : false;

            let customFormParams = this.state.initialValues.customFormParams;
            // customFormParams.vehicleItems = vehicleItems;
            customFormParams.driverItems = driverItems;
            customFormParams.weekdayItems = weekdayItems;
            customFormParams.isApplyAllWeekdayTimes = (weekdayId && weekdayId.length > 0) ? false : true;
            customFormParams.weekendItems = weekendItems;
            customFormParams.isApplyAllWeekendTimes = (weekendId && weekendId.length > 0) ? false : true;


            let rowValues = {
                isEdit: true,
                customSiteName: (newData && newData.customSiteName && newData.customSiteName !== '') ? newData.customSiteName + (this.state.isCopy ? ' - copy' : '') : '',
                siteName: (newData && newData.siteName && newData.siteName !== '') ? newData.siteName + (this.state.isCopy ? ' - copy' : '') : '',
                street: (newData && newData.street && newData.street !== '') ? newData.street : '',
                blockNo: (newData && newData.blockNo && newData.blockNo !== '') ? newData.blockNo : '',
                unitNo: (newData && newData.unitNo && newData.unitNo !== '') ? newData.unitNo : '',
                postalCode: (newData && newData.postalCode && newData.postalCode !== '') ? newData.postalCode : '',
                latitude: latitude,
                longitude: longitude,
                coordinates: coordinates,
                zoneId: zoneId,
                zoneName: zoneName,
                remarks: (newData && newData.remarks && newData.remarks !== '') ? newData.remarks : '',
                instructions: (newData && newData.instructions && newData.instructions !== '') ? newData.instructions : '',
                contactPersonName: (newData && newData.contactPersonName && newData.contactPersonName !== '') ? newData.contactPersonName : '',
                contactPersonPhoneNumber: (newData && newData.contactPersonPhoneNumber && newData.contactPersonPhoneNumber !== '') ? newData.contactPersonPhoneNumber : '',
                contactPersonNameTwo: (newData && newData.contactPersonNameTwo && newData.contactPersonNameTwo !== '') ? newData.contactPersonNameTwo : '',
                contactPersonPhoneNumberTwo: (newData && newData.contactPersonPhoneNumberTwo && newData.contactPersonPhoneNumberTwo !== '') ? newData.contactPersonPhoneNumberTwo : '',
                isContactPersonSendNotification: isContactPersonSendNotification,
                isContactPersonTwoSendNotification: isContactPersonTwoSendNotification,
                // vehicleId: vehicleId,
                driverId: driverId,
                weekdayId: weekdayId,
                weekendId: weekendId,
                weekdaysAvailability: weekdaysAvailability,
                weekendAvailability: weekendAvailability,
                isActive: isActive,
                copySiteRemarksToJob: copySiteRemarksToJob,
                customFormParams: customFormParams,
            };
        
            this.setState({
                initialValues: rowValues,
                details: newData,
            });
        }
    }
    prepareData = (form) => {
        var newForm = _.cloneDeep(form);

        // let vehicleIds = (newForm && newForm.vehicleId && newForm.vehicleId.length > 0) ? newForm.vehicleId.join(',') : '';
        let driverIds = (newForm && newForm.driverId && newForm.driverId.length > 0) ? newForm.driverId.join(',') : '';
        let accountWeekdayJobWorkingTimeIds = (newForm && newForm.weekdayId && newForm.weekdayId.length > 0) ? newForm.weekdayId.join(',') : '';
        let accountWeekendJobWorkingTimeIds = (newForm && newForm.weekendId && newForm.weekendId.length > 0) ? newForm.weekendId.join(',') : '';

        let isApplyAllWeekdayTimes = ((newForm.customFormParams.isApplyAllWeekdayTimes === false) || (newForm.customFormParams.isApplyAllWeekdayTimes === true)) ? newForm.customFormParams.isApplyAllWeekdayTimes : false;
        let isApplyAllWeekendTimes = ((newForm.customFormParams.isApplyAllWeekendTimes === false) || (newForm.customFormParams.isApplyAllWeekendTimes === true)) ? newForm.customFormParams.isApplyAllWeekendTimes : false;

        let weekdaysAvailability = (newForm && newForm.weekdaysAvailability) ? newForm.weekdaysAvailability : CustomerSiteAvailability.All;
        let weekendAvailability = (newForm && newForm.weekendAvailability) ? newForm.weekendAvailability : CustomerSiteAvailability.All;

        let isDeactivateOrDelete = ((newForm.isDeactivateOrDelete === false) || (newForm.isDeactivateOrDelete === true)) ? !newForm.isDeactivateOrDelete : false;
        let copySiteRemarksToJob = ((newForm.copySiteRemarksToJob === false) || (newForm.copySiteRemarksToJob === true)) ? newForm.copySiteRemarksToJob : false;

        let latitude = newForm.latitude;
        let longitude = newForm.longitude;
        let coordinates = (newForm && newForm.coordinates && newForm.coordinates != '') ? newForm.coordinates.split(',') : [];
        latitude = (coordinates && coordinates.length > 0 && coordinates[0]) ? coordinates[0] : newForm.latitude;
        longitude = (coordinates && coordinates.length > 0 && coordinates[1]) ? coordinates[1] : newForm.longitude;

        let isContactPersonSendNotification = ((newForm.isContactPersonSendNotification === false) || (newForm.isContactPersonSendNotification === true)) ? newForm.isContactPersonSendNotification : false;
        let isContactPersonTwoSendNotification = ((newForm.isContactPersonTwoSendNotification === false) || (newForm.isContactPersonTwoSendNotification === true)) ? newForm.isContactPersonTwoSendNotification : false;
        
        let data = {
            customerId: this.state.customerId,
            customSiteName: newForm.customSiteName,
            siteName: newForm.siteName,
            street: newForm.street,
            blockNo: newForm.blockNo,
            unitNo: newForm.unitNo,
            postalCode: newForm.postalCode,
            latitude: latitude,
            longitude: longitude,
            isDeactivateOrDelete: isDeactivateOrDelete,
            remarks: newForm.remarks,
            instructions: newForm.instructions,
            contactPersonName: newForm.contactPersonName,
            contactPersonPhoneNumber: newForm.contactPersonPhoneNumber,
            contactPersonNameTwo: newForm.contactPersonNameTwo,
            contactPersonPhoneNumberTwo: newForm.contactPersonPhoneNumberTwo,
            isContactPersonSendNotification: isContactPersonSendNotification,
            isContactPersonTwoSendNotification: isContactPersonTwoSendNotification,
            // vehicleIds: vehicleIds,
            driverIds: driverIds,
            accountWeekdayJobWorkingTimeIds: (weekdaysAvailability === CustomerSiteAvailability.SelectedHours) ? accountWeekdayJobWorkingTimeIds : (weekdaysAvailability === CustomerSiteAvailability.All) ? '' : null,
            accountWeekendJobWorkingTimeIds: (weekendAvailability === CustomerSiteAvailability.SelectedHours) ? accountWeekendJobWorkingTimeIds : (weekendAvailability === CustomerSiteAvailability.All) ? '' : null,
            weekdaysAvailability: weekdaysAvailability,
            weekendAvailability: weekendAvailability,
            copySiteRemarksToJob: copySiteRemarksToJob,
        };
        
        if(form.zoneId !== 0){
            data['zoneId'] = form.zoneId;
        }

        if ((!this.state.isCopy) && this.state.id > 0) {
            data['customerSiteId'] = this.state.id;
        }

        return data;
    }
    setSubmit = (form) => {
        this.setLayoutSubmit(form);

        if(this.props.onSubmit){
            this.props.onSubmit(form);
        }
    }

    handleRemove = () => {
        this.setState({
            isRemoveDialog: true,
        });
    }
    handleCopy = () => {
        if(this.props.onCopy){
            this.props.onCopy();
        }
    }
    /* END FUNCTIONS */


    /* FORM */
    formHeaderLeft = (formOptions) => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = formOptions;
        
        return <>

            <InputLayout
                label={formFields.siteName.label}
                required
                inputProps={{
                    xs: 12,
                    lg: 7
                }}
            >
                <Form.Control
                    type="text"
                    id={formFields.siteName.id}
                    placeholder={formFields.siteName.placeholder}
                    value={values.siteName}
                    onChange={handleChange}
                    isInvalid={!!errors.siteName}
                />
                <Form.Control.Feedback type="invalid">{errors.siteName}</Form.Control.Feedback>
            </InputLayout>

            <InputLayout
                label={formFields.street.label}
                inputProps={{
                    xs: 12,
                    lg: 7
                }}
            >
                <Form.Control
                    type="text"
                    id={formFields.street.id}
                    placeholder={formFields.street.placeholder}
                    value={values.street}
                    onChange={handleChange}
                    isInvalid={!!errors.street}
                />
                <Form.Control.Feedback type="invalid">{errors.street}</Form.Control.Feedback>
            </InputLayout>
            
            <InputLayout
                label={formFields.blockNo.label}
                inputProps={{
                    xs: 12,
                    lg: 7
                }}
            >
                <Form.Control
                    type="text"
                    id={formFields.blockNo.id}
                    placeholder={formFields.blockNo.placeholder}
                    value={values.blockNo}
                    onChange={handleChange}
                    isInvalid={!!errors.blockNo}
                />
                <Form.Control.Feedback type="invalid">{errors.blockNo}</Form.Control.Feedback>
            </InputLayout>
            
            <InputLayout
                label={formFields.unitNo.label}
                inputProps={{
                    xs: 12,
                    lg: 7
                }}
            >
                <Form.Control
                    type="text"
                    id={formFields.unitNo.id}
                    placeholder={formFields.unitNo.placeholder}
                    value={values.unitNo}
                    onChange={handleChange}
                    isInvalid={!!errors.unitNo}
                />
                <Form.Control.Feedback type="invalid">{errors.unitNo}</Form.Control.Feedback>
            </InputLayout>
            
            <InputLayout
                label={formFields.postalCode.label}
                inputProps={{
                    xs: 12,
                    lg: 7
                }}
            >
                <Form.Control
                    type="text"
                    id={formFields.postalCode.id}
                    placeholder={formFields.postalCode.placeholder}
                    value={values.postalCode}
                    onChange={handleChange}
                    isInvalid={!!errors.postalCode}
                />
                <Form.Control.Feedback type="invalid">{errors.postalCode}</Form.Control.Feedback>
            </InputLayout>

            <InputLayout
                label={formFields.coordinates.label}
                inputProps={{
                    xs: 12,
                    lg: 7
                }}
            >
                <StyledFormControl
                    type="text"
                    readOnly
                    id={formFields.coordinates.id}
                    placeholder={formFields.coordinates.placeholder}
                    value={Utils.getDataValue(values, 'coordinates')}
                    isInvalid={!!errors.coordinates}
                />
                <Form.Control.Feedback type="invalid">{errors.coordinates}</Form.Control.Feedback>
            </InputLayout>
            
        </>
    }
    formHeaderRight = (formOptions) => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = formOptions;
        
        if(!this.state.isLoadingMap){
            return <div className={'position-relative'} style={{ height: '250px' }}>
                <StaticGoogleMap
                    zoom={this.state.zoom}
                    center={Utils.parseCoordinates(values.coordinates, { lat: this.state.lat, lng: this.state.lng })}
                    onLoad={(ref) => {
                        let position = Utils.parseCoordinates(values.coordinates, { lat: this.state.lat, lng: this.state.lng })
                        if(position.lat && position.lng){
                            Utils.dinamicZoom(ref, position.lat, position.lng);
                        }
                    }}
                />
                <div className={'text-center'}>
                    <StyledButton
                        className={'mt-10 mt-md-5'}
                        variant={'light'}
                        size={'sm'}
                        onClick={(e) => {
                            this.setState({
                                isLocationDialog: true,
                                canLocationDialogClose: true,
                                locationDialogTitle: 'Change Location',
                            });
                        }}
                    >Change Location</StyledButton>
                </div>
            </div>
        }
    }

    formBodyLeft = (formOptions) => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = formOptions;
        
        return <>
            
            <InputLayout
                label={formFields.zoneId.label}
                inputProps={{
                    xs: 12,
                    lg: 7
                }}
            >
                <SmartDropdown
                    isInvalid={!!errors.zoneId}
                    errorText={errors.zoneId}

                    componentTitle={'Zone'}
                    componentApi={'zone'}
                    componentId={'zoneId'}
                    componentName={'zoneName'}
                    componentCreateData={{ zoneColor: '#000000' }}

                    placeholder={formFields.zoneId.placeholder}
                    value={values.zoneId}
                    label={values.zoneName}

                    disabled={false}
                    showDefault={false}
                    isInfiniteScroll={false}

                    onChange={async (value, item, i) => {
                        await setFieldValue('zoneId', value);
                        await setFieldValue('zoneName', item.title);
                    }}
                    onClear={async () => {
                        await setFieldValue('zoneId', null);
                        await setFieldValue('zoneName', '');
                    }}
                />
            </InputLayout>

            <InputLayout
                label={formFields.contactPersonName.label}
                inputProps={{
                    xs: 12,
                    lg: 7
                }}
            >
                <Row className={'align-items-center'}>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={formFields.contactPersonName.id}
                            placeholder={formFields.contactPersonName.placeholder}
                            value={values.contactPersonName}
                            onChange={handleChange}
                            isInvalid={!!errors.contactPersonName}
                        />
                        <Form.Control.Feedback type="invalid">{errors.contactPersonName}</Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={true}>
                        <Form.Control
                            // as={PhoneField}
                            id={formFields.contactPersonPhoneNumber.id}
                            placeholder={formFields.contactPersonPhoneNumber.placeholder}
                            value={values.contactPersonPhoneNumber}
                            isInvalid={!!errors.contactPersonPhoneNumber}

                            onChange={async (e) => {
                                await setFieldValue('contactPersonPhoneNumber', e.target.value);
                                // await setFieldValue('contactPersonPhoneNumber', Utils.parsePhoneNumber(value));
                                
                                // if(value == '' || value == undefined){
                                //     await setFieldValue('isContactPersonSendNotification', false);
                                // }
                            }}
                        />
                        <Form.Control.Feedback type="invalid">{errors.contactPersonPhoneNumber}</Form.Control.Feedback>
                    </Col>
                    {/* <Col xs={12} md={'auto'}>
                        <Form.Check
                            id={formFields.isContactPersonSendNotification.id}
                            label={formFields.isContactPersonSendNotification.label}
                            checked={values.isContactPersonSendNotification}
                            disabled={(values.contactPersonPhoneNumber == '' || values.contactPersonPhoneNumber == undefined)}
                            onChange={async (e) => {
                                await setFieldValue('isContactPersonSendNotification', e.target.checked);
                            }}
                            isInvalid={!!errors.isContactPersonSendNotification}
                            feedback={errors.isContactPersonSendNotification}
                        />
                        <Form.Control.Feedback type="invalid">{errors.isContactPersonSendNotification}</Form.Control.Feedback>
                    </Col> */}
                </Row>
            </InputLayout>

            <InputLayout
                label={formFields.contactPersonNameTwo.label}
                inputProps={{
                    xs: 12,
                    lg: 7
                }}
            >
                <Row className={'align-items-center'}>
                    <Col xs={12} md={true}>
                        <Form.Control
                            type="text"
                            id={formFields.contactPersonNameTwo.id}
                            placeholder={formFields.contactPersonNameTwo.placeholder}
                            value={values.contactPersonNameTwo}
                            onChange={handleChange}
                            isInvalid={!!errors.contactPersonNameTwo}
                        />
                        <Form.Control.Feedback type="invalid">{errors.contactPersonNameTwo}</Form.Control.Feedback>
                    </Col>
                    <Col xs={12} md={true}>
                        <Form.Control
                            // as={PhoneField}
                            id={formFields.contactPersonPhoneNumberTwo.id}
                            placeholder={formFields.contactPersonPhoneNumberTwo.placeholder}
                            value={values.contactPersonPhoneNumberTwo}
                            isInvalid={!!errors.contactPersonPhoneNumberTwo}

                            onChange={async (e) => {
                                await setFieldValue('contactPersonPhoneNumberTwo', e.target.value);
                                // await setFieldValue('contactPersonPhoneNumberTwo', Utils.parsePhoneNumber(value));
                                
                                // if(value == '' || value == undefined){
                                //     await setFieldValue('isContactPersonTwoSendNotification', false);
                                // }
                            }}
                        />
                        <Form.Control.Feedback type="invalid">{errors.contactPersonPhoneNumberTwo}</Form.Control.Feedback>
                    </Col>
                    {/* <Col xs={12} md={'auto'}>
                        <Form.Check
                            id={formFields.isContactPersonTwoSendNotification.id}
                            label={formFields.isContactPersonTwoSendNotification.label}
                            checked={values.isContactPersonTwoSendNotification}
                            disabled={(values.contactPersonPhoneNumberTwo == '' || values.contactPersonPhoneNumberTwo == undefined)}
                            onChange={async (e) => {
                                await setFieldValue('isContactPersonTwoSendNotification', e.target.checked);
                            }}
                            isInvalid={!!errors.isContactPersonTwoSendNotification}
                            feedback={errors.isContactPersonTwoSendNotification}
                        />
                        <Form.Control.Feedback type="invalid">{errors.isContactPersonTwoSendNotification}</Form.Control.Feedback>
                    </Col> */}
                </Row>
            </InputLayout>

        </>
    }
    formBodyRight = (formOptions) => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = formOptions;
        
        return <>
            {/* <InputLayout
                label={formFields.vehicleId.label}
                inputProps={{
                    xs: 12,
                    lg: 7
                }}
            >
                <Form.Control
                    as = {RSuiteCheckPicker}
                    isInvalid={!!errors.vehicleId}
                    
                    id={formFields.vehicleId.id}
                    placeholder={formFields.vehicleId.placeholder}
                    items={values.customFormParams.vehicleItems}
                    value={values.vehicleId}
                    isSelectedAll={false}
                    isLoading={values.customFormParams.vehicleIsLoading}
                    searchable={true}
                    countable={true}
                    cleanable={true}
                    showSelectAll={false}
                    isDefault={true}
                    onClick={async () => {
                        await setFieldValue('customFormParams.vehicleIsLoading', true);
                        this.callReadVehicleApi(values.customFormParams.vehicleItems, async (items) => {
                            await setFieldValue('customFormParams.vehicleItems', items);
                            await setFieldValue('customFormParams.vehicleIsLoading', false);
                        });
                    }}
                    onChange={async (values, items) => {
                        await setFieldValue('vehicleId', values);
                    }}
                    onClear={async () => {
                        await setFieldValue('vehicleId', []);
                    }}
                />
                <Form.Control.Feedback type="invalid">{errors.vehicleId}</Form.Control.Feedback>
            </InputLayout> */}
            
            <InputLayout
                label={formFields.driverId.label}
                inputProps={{
                    xs: 12,
                    lg: 7
                }}
            >
                <Form.Control
                    as = {RSuiteCheckPicker}
                    isInvalid={!!errors.driverId}
                    
                    id={formFields.driverId.id}
                    placeholder={formFields.driverId.placeholder}
                    items={values.customFormParams.driverItems}
                    value={values.driverId}
                    isSelectedAll={false}
                    isLoading={values.customFormParams.driverIsLoading}
                    searchable={true}
                    countable={true}
                    cleanable={true}
                    showSelectAll={false}
                    isDefault={true}
                    onClick={async () => {
                        await setFieldValue('customFormParams.driverIsLoading', true);
                        this.callReadDriverApi(values.customFormParams.driverItems, async (items) => {
                            await setFieldValue('customFormParams.driverItems', items);
                            await setFieldValue('customFormParams.driverIsLoading', false);
                        });
                    }}
                    onChange={async (values, items) => {
                        await setFieldValue('driverId', values);
                    }}
                    onClear={async () => {
                        await setFieldValue('driverId', []);
                    }}
                />
                <Form.Control.Feedback type="invalid">{errors.driverId}</Form.Control.Feedback>
            </InputLayout>

            <InputLayout
                label={formFields.weekdayId.label}
                inputProps={{
                    xs: 12,
                    lg: 7
                }}
            >
                <div className={'pt-3'}>
                    <Form.Check
                        inline
                        type={'radio'}
                        id={formFields.weekdayId.id + '_1'}
                        label={'All'}
                        checked={values.weekdaysAvailability === CustomerSiteAvailability.All}
                        onChange={async (e) => {
                            await setFieldValue('weekdaysAvailability', CustomerSiteAvailability.All);
                        }}
                    />
                    <Form.Check
                        inline
                        type={'radio'}
                        id={formFields.weekdayId.id + '_2'}
                        label={'Selected hours'}
                        checked={values.weekdaysAvailability === CustomerSiteAvailability.SelectedHours}
                        onChange={async (e) => {
                            await setFieldValue('weekdaysAvailability', CustomerSiteAvailability.SelectedHours);
                        }}
                    />
                    <Form.Check
                        inline
                        type={'radio'}
                        id={formFields.weekdayId.id + '_3'}
                        label={'Not Available'}
                        checked={values.weekdaysAvailability === CustomerSiteAvailability.NotAvailable}
                        onChange={async (e) => {
                            await setFieldValue('weekdaysAvailability', CustomerSiteAvailability.NotAvailable);
                        }}
                    />
                </div>
            </InputLayout>
            {(values.weekdaysAvailability === CustomerSiteAvailability.SelectedHours) && <InputLayout
                label={<>&nbsp;</>}
                inputProps={{
                    xs: 12,
                    lg: 7
                }}
            >
                <Form.Control
                        as = {RSuiteCheckPicker}
                        isInvalid={!!errors.weekdayId}
                        
                        id={formFields.weekdayId.id}
                        placeholder={formFields.weekdayId.placeholder}
                        items={values.customFormParams.weekdayItems}
                        value={values.weekdayId}
                        isSelectedAll={values.customFormParams.weekdayIsSelectAll}
                        isLoading={values.customFormParams.weekdayIsLoading}
                        searchable={true}
                        countable={true}
                        cleanable={true}
                        showSelectAll={true}
                        isDefault={true}
                        placement={'topStart'}
                        onClick={async () => {
                            await setFieldValue('customFormParams.weekdayIsLoading', true);
                            this.callReadWeekdayTimesApi(values.customFormParams.weekdayItems, async (items) => {
                                await setFieldValue('customFormParams.weekdayItems', items);
                                await setFieldValue('customFormParams.weekdayIsLoading', false);
                            });
                        }}
                        onSelectAll={async (checked, isIndeterminate) => {
                            if(checked){
                                await setFieldValue('weekdayId', values.customFormParams.weekdayItems.map(x => x.value));
                                await setFieldValue('customFormParams.weekdayIsSelectAll', true);
                            } else {
                                await setFieldValue('weekdayId', []);
                                await setFieldValue('customFormParams.weekdayIsSelectAll', false);
                            }
                        }}
                        onChange={async (values, items) => {
                            await setFieldValue('weekdayId', values);
                            
                            if(items.length > 0){
                                await setFieldValue('customFormParams.weekdayIsSelectAll', (values.length === items.length));
                            } else {
                                await setFieldValue('customFormParams.weekdayIsSelectAll', false);
                            }
                        }}
                        onClear={async () => {
                            await setFieldValue('weekdayId', []);
                            await setFieldValue('customFormParams.weekdayIsSelectAll', false);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.weekdayId}</Form.Control.Feedback>
            </InputLayout>}
            
            <InputLayout
                label={formFields.weekendId.label}
                inputProps={{
                    xs: 12,
                    lg: 7
                }}
            >
                <div className={'pt-3'}>
                    <Form.Check
                        inline
                        type={'radio'}
                        id={formFields.weekendId.id + '_1'}
                        label={'All'}
                        checked={values.weekendAvailability === CustomerSiteAvailability.All}
                        onChange={async (e) => {
                            await setFieldValue('weekendAvailability', CustomerSiteAvailability.All);
                        }}
                    />
                    <Form.Check
                        inline
                        type={'radio'}
                        id={formFields.weekendId.id + '_2'}
                        label={'Selected hours'}
                        checked={values.weekendAvailability === CustomerSiteAvailability.SelectedHours}
                        onChange={async (e) => {
                            await setFieldValue('weekendAvailability', CustomerSiteAvailability.SelectedHours);
                        }}
                    />
                    <Form.Check
                        inline
                        type={'radio'}
                        id={formFields.weekendId.id + '_3'}
                        label={'Not Available'}
                        checked={values.weekendAvailability === CustomerSiteAvailability.NotAvailable}
                        onChange={async (e) => {
                            await setFieldValue('weekendAvailability', CustomerSiteAvailability.NotAvailable);
                        }}
                    />
                </div>
            </InputLayout>
            {(values.weekendAvailability === CustomerSiteAvailability.SelectedHours) && <InputLayout
                label={<>&nbsp;</>}
                inputProps={{
                    xs: 12,
                    lg: 7
                }}
            >
                <Form.Control
                        as = {RSuiteCheckPicker}
                        isInvalid={!!errors.weekendId}
                        
                        id={formFields.weekendId.id}
                        placeholder={formFields.weekendId.placeholder}
                        items={values.customFormParams.weekendItems}
                        value={values.weekendId}
                        isSelectedAll={values.customFormParams.weekendIsSelectAll}
                        isLoading={values.customFormParams.weekendIsLoading}
                        searchable={true}
                        countable={true}
                        cleanable={true}
                        showSelectAll={true}
                        isDefault={true}
                        placement={'topStart'}
                        onClick={async () => {
                            await setFieldValue('customFormParams.weekendIsLoading', true);
                            this.callReadWeekendTimesApi(values.customFormParams.weekendItems, async (items) => {
                                await setFieldValue('customFormParams.weekendItems', items);
                                await setFieldValue('customFormParams.weekendIsLoading', false);
                            });
                        }}
                        onSelectAll={async (checked, isIndeterminate) => {
                            if(checked){
                                await setFieldValue('weekendId', values.customFormParams.weekendItems.map(x => x.value));
                                await setFieldValue('customFormParams.weekendIsSelectAll', true);
                            } else {
                                await setFieldValue('weekendId', []);
                                await setFieldValue('customFormParams.weekendIsSelectAll', false);
                            }
                        }}
                        onChange={async (values, items) => {
                            await setFieldValue('weekendId', values);
                            
                            if(items.length > 0){
                                await setFieldValue('customFormParams.weekendIsSelectAll', (values.length === items.length));
                            } else {
                                await setFieldValue('customFormParams.weekendIsSelectAll', false);
                            }
                        }}
                        onClear={async () => {
                            await setFieldValue('weekendId', []);
                            await setFieldValue('customFormParams.weekendIsSelectAll', false);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.weekendId}</Form.Control.Feedback>
            </InputLayout>}

        </>
    }
    
    formFooterLeft = (formOptions) => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = formOptions;
        
        return <>
            
            <InputLayout
                label={formFields.remarks.label}
                inputProps={{
                    xs: 12,
                    lg: 7
                }}
            >
                <Form.Control
                    as="textarea"
                    rows={5}
                    id={formFields.remarks.id}
                    placeholder={formFields.remarks.placeholder}
                    value={values.remarks}
                    onChange={async (e) => {
                        Utils.limitRows(e.target.value, 5, async (value) => {
                            await setFieldValue('remarks', value);
                        });
                    }}
                    isInvalid={!!errors.remarks}
                />
                <Form.Control.Feedback type="invalid">{errors.remarks}</Form.Control.Feedback>
            </InputLayout>

            <InputLayout
                label={<>&nbsp;</>}
                inputProps={{
                    xs: 12,
                    lg: 7
                }}
            >
                <Form.Check
                    id={formFields.copySiteRemarksToJob.id}
                    label={formFields.copySiteRemarksToJob.label}
                    checked={values.copySiteRemarksToJob}
                    onChange={async (e) => {
                        await setFieldValue('copySiteRemarksToJob', e.target.checked);
                    }}
                    isInvalid={!!errors.copySiteRemarksToJob}
                    feedback={errors.copySiteRemarksToJob}
                />
            </InputLayout>

        </>
    }
    formFooterRight = (formOptions) => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = formOptions;
        
        return <>
            
            <InputLayout
                label={formFields.instructions.label}
                inputProps={{
                    xs: 12,
                    lg: 7
                }}
            >
                <Form.Control
                    as="textarea"
                    rows={5}
                    id={formFields.instructions.id}
                    placeholder={formFields.instructions.placeholder}
                    value={values.instructions}
                    onChange={async (e) => {
                        Utils.limitRows(e.target.value, 5, async (value) => {
                            await setFieldValue('instructions', value);
                        });
                    }}
                    isInvalid={!!errors.instructions}
                />
                <Form.Control.Feedback type="invalid">{errors.instructions}</Form.Control.Feedback>
            </InputLayout>

        </>
    }
    /* END FORM */


    /* DIALOG */
    setLocationDialog = (formOptions) => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = formOptions;

        return <LocationDialog 
            show={this.state.isLocationDialog}
            canClose={this.state.canLocationDialogClose}
            formOptions={formOptions}
            zoom={this.state.zoom}
            title={this.state.locationDialogTitle}
            onCancel={() => {
                this.setState({
                    isLocationDialog: false,
                    canLocationDialogClose: true,
                    locationDialogTitle: null,
                });
            }}
            onSave={(form) => {
                this.setState({
                    isLocationDialog: false,
                    canLocationDialogClose: true,
                    locationDialogTitle: null,
                }, async () => {
                    await setFieldValue('customSiteName', form.customSiteName);
                    // if(form.replaceSiteNameWithThisAddress){
                    //     await setFieldValue('siteName', Utils.formatCustomerSiteAddress(form));
                    // } else {
                    //     await setFieldValue('siteName', form.customSiteName);
                    // }

                    await setFieldValue('siteName', form.customSiteName);
                    await setFieldValue('street', form.street);
                    await setFieldValue('blockNo', form.blockNo);
                    await setFieldValue('postalCode', form.postalCode);
                    await setFieldValue('latitude', form.latitude);
                    await setFieldValue('longitude', form.longitude);
                    await setFieldValue('coordinates', form.coordinates);
                });
            }}
        />
    }

    setRemoveDialog = () => {
        return <RemoveDialog 
          title={'Remove customer site'}
          show={this.state.isRemoveDialog}
          isLoading={false}
          onCancel={() => {
            this.setState({
              isRemoveDialog: false,
            });
          }}
          onRemove={() => {
            this.setState({
                isRemoveDialog: false,
            }, () => {
                if(this.refForm && this.refForm.current){
                    let { values } = this.refForm.current;
                    
                    let data = this.prepareData(values);
    
                    this.callDeleteApi(data, (res, isError) => {
                        if(!isError){
                            this.setSubmit(values);
                        } else {
                            this.setLoading(false);
                        }
                    });
                } else {
                    this.setLoading(false);
                }
            });
          }}
        />
    }
    /* END DIALOG */

    footer = (formOptions) => {
        let {
            values,
            validateForm,
        } = formOptions;
        
        return <Row>
            <Col xs={'auto'}>
                <Button 
                    type={'button'}
                    variant={'secondary'}
                    disabled={this.props.isLoading}
                    onClick={() => {
                        Utils.showDrawer(this.props.drawer, false);
                    }}
                >
                    Cancel
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
            <Col xs={'auto'}>
                <Button 
                    type={'button'}
                    variant={'primary'}
                    disabled={this.props.isLoading}
                    onClick={() => {
                        validateForm().then((err) => {
                            if(!_.isEmpty(err)){
                                Utils.toast('You must fill in the required fields', 'error');
                            } else {
                                if(this.props.onSave){
                                    this.props.onSave(() => {
                                        return this.prepareData(values);
                                    });
                                }
                            }
                        })
                    }}
                >
                    Save
                    {this.props.isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
        </Row>
    }


    render() {
        return <>
            <Formik
                innerRef={this.refForm}
                validationSchema={formSchema}
                initialValues={this.state.initialValues}
                enableReinitialize={true}
                validateOnMount={false}
                validateOnChange={false}
                onSubmit={(form, e) => {
                    this.setLoading(true);
                    let data = this.prepareData(form);

                    if(this.state.isCopy){
                        this.callCopyApi(data, (res, isError) => {
                            if(!isError){
                                this.setSubmit(form);
                            } else {
                                this.setLoading(false);
                            }
                        });
                    } else {
                        if(form.isEdit){
                            this.callUpdateApi(data, (res, isError) => {
                                if(!isError){
                                    this.setSubmit(form);
                                } else {
                                    this.setLoading(false);
                                }
                            });
                        } else {
                            this.callCreateApi(data, (res, isError) => {
                                if(!isError){
                                    this.setSubmit(form);
                                } else {
                                    this.setLoading(false);
                                }
                            });
                        }
                    }
                }}
            >
                {(formOptions) => {
                    return <Form className='w-100' style={{ overflowX: 'hidden' }} noValidate autoComplete="off" onSubmit={formOptions.handleSubmit} onKeyDown={(keyEvent) => {
                        // if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                        //     keyEvent.preventDefault();
                        // }
                    }}>
                        <Row style={{ paddingBottom: (this.props.isFooter) ? '100px' : 'initial' }}>
                            <Col xs={12} className={'ps-7 pe-7 pt-5 pb-5'}  style={{ backgroundColor: '#F7F7F7' }}>
                                <Row>
                                    <Col xs={12} md={12} lg={6} xl={6} className={'ps-2 pe-2 ps-md-7 pe-md-7'}>{this.formHeaderLeft(formOptions)}</Col>
                                    <Col xs={12} md={12} lg={6} xl={4} className={'ps-2 pe-2 ps-md-7 pe-md-7'}>{this.formHeaderRight(formOptions)}</Col>
                                </Row>
                            </Col>
                            <Col xs={12} className={'ps-7 pe-7 mt-10 mt-md-0 pt-10 pt-md-5 pb-5'}>
                                <Row>
                                    <Col xs={12} md={12} lg={6} xl={6} className={'ps-2 pe-2 ps-md-7 pe-md-7'}>{this.formBodyLeft(formOptions)}</Col>
                                    <Col xs={12} md={12} lg={6} xl={6} className={'ps-2 pe-2 ps-md-7 pe-md-7'}>{this.formBodyRight(formOptions)}</Col>
                                </Row>
                            </Col>
                            <Col xs={12} className={'ps-7 pe-7 pt-5 pb-5'}>
                                <Row>
                                    <Col xs={12} md={12} lg={6} xl={6} className={'ps-2 pe-2 ps-md-7 pe-md-7'}>{this.formFooterLeft(formOptions)}</Col>
                                    <Col xs={12} md={12} lg={6} xl={6} className={'ps-2 pe-2 ps-md-7 pe-md-7'}>{this.formFooterRight(formOptions)}</Col>
                                </Row>
                            </Col>
                            {(this.props.isFooter) && <Col 
                                xs={12}
                                className={'ps-2 pe-2 ps-md-7 pe-md-7'}
                                style={{
                                    position: 'fixed',
                                    left: '0px',
                                    bottom: '0px',
                                    backgroundColor: 'white',
                                    paddingBottom: '10px',
                                    paddingTop: '10px',
                                    borderTop: '1px solid #EFF2F5',
                                }}
                            >{this.footer(formOptions)}</Col>}
                        </Row>
                        {this.setLocationDialog(formOptions)}
                        {this.setRemoveDialog()}
                    </Form>
                }}
            </Formik>
        </>
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  dispatchApiCallPost,
  dispatchApiCallPut,
  dispatchApiCallDelete,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CustomerSiteForm);
