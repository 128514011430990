import React from 'react';

import { 
    Modal,
    Row,
    Col,
    Button,
} from "react-bootstrap-v5";



const CancelPopup = (props: any) => {
    const onNo = () => {
        if(props.onNo){
            props.onNo();
        }
    }
    const onYes = () => {
        if(props.onYes){
            props.onYes();
        }
    }


    return <Modal
        show={props.show}
        onHide={onNo}
        backdrop={"static"}
        // fullscreen={true}
        scrollable={true}
        keyboard={false}
        enforceFocus={false}
    >
        <Modal.Header closeButton={true}>Cancel form</Modal.Header>

        <Modal.Body style={{ maxHeight: '217px' }}>Are you sure you want to exit the form?</Modal.Body>

        <Modal.Footer style={{ justifyContent: 'flex-start' }}>
            <Row className={'m-0 align-items-center'}>
                <Col xs={'auto'}>
                    <Button 
                        type={'button'}
                        variant={'primary'}
                        size={'sm'}
                        onClick={onYes}
                    >
                        Yes
                    </Button>
                </Col>
                <Col xs={'auto'}>
                    <Button 
                        type={'button'}
                        variant={'secondary'}
                        size={'sm'}
                        onClick={onNo}
                    >
                        No
                    </Button>
                </Col>
            </Row>
        </Modal.Footer>
    </Modal>
}


export default CancelPopup;
