import axios from 'axios';


const { 
    REACT_APP_STRIPE_URL,
    REACT_APP_STRIPE_SECRET_API_KEY
} = process.env;


const stripeApi = {

    pay: async (paymentMethodId, amount, currency) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_STRIPE_URL;

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + REACT_APP_STRIPE_SECRET_API_KEY
            }
        };

        const params = new URLSearchParams();
        params.append('payment_method', paymentMethodId);
        params.append('amount', amount);
        params.append('currency', currency);
        params.append('confirmation_method', 'manual');
        params.append('confirm', true);

        const getResult = await axios.post(URL + 'payment_intents', params, config).catch(error => {
            try {
                status = error.response.status;

                if (error.response) {
                    // Request made and server responded
                    errorMessage = error.response.data;
                } else if (error.request) {
                    // The request was made but no response was received
                    errorMessage = error.request;
                } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                }
            } catch(err) {
                errorMessage = 'Service Unavailable!'
            }
        });
        return { data: getResult, errorMessage: errorMessage, status: status};
    },
}

export default stripeApi;