import React from 'react';
import CurrencyInput from 'react-currency-input-field';
import InputMask from 'react-input-mask';
import moment from 'moment';
import _ from 'lodash';

import { 
    Row,
    Col,
    Form,
} from "react-bootstrap-v5";

import Utils from '../../../../utils/utils';

import RSuiteDatePicker from '../../../../components/OLD/Datepicker/RSuiteDatePicker';
import Tooltip from '../../../../components/Tooltip/Tooltip';

import { useDispatch, useSelector } from "react-redux";
import {RootState} from '../../../../../setup'
import { fields, hasSpecialDate, SelectedJobTypeStruct, hasSelectedDateTime, copyJobType, getDayOfWeekLabel } from '../redux/payrollBetaForm';
import payrollBetaCopyJobTypes from '../redux/payrollBetaCopyJobTypes';

import { useFormikContext } from '../PayrollBetaForm';
import CopyJobType from './CopyJobType';

import { StyledColLeft, StyledColRight, StyledPay, StyledMenuRow, StyleMenuWrapperCol, StyledDatePicker, StyledFormLabel, StyledFormText } from '../style/styles';


const WorkTimeJobType = (props: any) => {
    const { values, errors, setFieldValue, validateForm } = useFormikContext();

    const dispatch = useDispatch();
    // const { step3aRadio } = useSelector((state: RootState) => state.payrollBetaLayout);
    const { jobTypes } = useSelector((state: RootState) => state.payrollBetaForm);


    const getSelected = () => {
        if(values.selectedJobType && values.selectedJobType.length > 0){
            let index = values.selectedJobType.findIndex((x: SelectedJobTypeStruct) => x.isSelected === true);
            if(index > -1){
                return {
                    index: index,
                    item: values.selectedJobType[index],
                };
            }
        }

        return {
            index: -1,
            item: null,
        };
    }


    const workTimeForm = () => {
        let selected = getSelected();
        if(selected && selected.item){
            return <Row>
                <Col xs={12} className={'mb-3'}>
                    <Row>
                        <Col xs={2} md={2} lg={2} xl={1}>
                            <div className={'position-absolute'}><StyledFormLabel>WORK DAYS</StyledFormLabel></div>
                        </Col>
                        <Col xs={12} md={10} lg={10} xl={11}>
                            <Row className={'align-items-center mb-2'}>
                                <Col xs={12} md={6}>&nbsp;</Col>
                                <Col xs={12} md={6} className={'d-none d-md-block'}><StyledPay>Pay</StyledPay></Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12}>
                    {(selected.item.workingDays && selected.item.workingDays.length > 0) && <>
                        {selected.item.workingDays.map((item: any, i: number) => {
                            if(item.isEnabled){
                                return <Row key={'workTimes_' + i} className={'mb-3'}>
                                    <Col xs={2} md={2} lg={2} xl={1} className={'mt-0 mt-md-3 mb-2 mb-md-0'}><StyledFormText>{getDayOfWeekLabel(item.day)}</StyledFormText></Col>
                                    <Col xs={12} md={10} lg={10} xl={11}>
                                        {item.times.map((subItem: any, j: number) => {
                                            return <Row key={'work_times_' + i + '_day_' + j} className={'align-items-center mb-2'}>
                                                <Col xs={12} md={6} className={'mb-3 mb-md-0'}>
                                                    <Row className={'align-items-center'}>
                                                        <Col xs={12} sm={true} className={'custom-rsuite-time-picker-component'}>
                                                            <Form.Control
                                                                as = {InputMask}
                                                                mask={'99:99'}
                                                                size={'sm'}
                                                                readOnly={true}
                                                                tabIndex={-1}

                                                                id={fields.workTimes.id + '_' + selected.index + '_' + i + '_' + fields.workTimesTimeFrom.id + '_' + j + '_timeFrom'}
                                                                isInvalid={!!(errors.selectedJobType && errors.selectedJobType.length > 0 && errors.selectedJobType[selected.index] && errors.selectedJobType[selected.index].workingDays && errors.selectedJobType[selected.index].workingDays[i] && errors.selectedJobType[selected.index].workingDays[i].times && errors.selectedJobType[selected.index].workingDays[i].times[j] && errors.selectedJobType[selected.index].workingDays[i].times[j].timeFrom)}
                                                                placeholder={fields.workTimesTimeFrom.placeholder}
                                                                value={subItem.timeFrom}
                                                                onChange={(e: any) => {
                                                                    setFieldValue('selectedJobType.' + selected.index + '.workingDays.' + i + '.times.' + j + '.timeFrom', e.target.value);
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col xs={'auto'} className={'d-none d-lg-block px-1'}><StyledFormText>TO</StyledFormText></Col>
                                                        <Col xs={12} sm={true} className={'custom-rsuite-time-picker-component'}>
                                                            <Form.Control
                                                                as = {InputMask}
                                                                mask={'99:99'}
                                                                size={'sm'}
                                                                readOnly={true}
                                                                tabIndex={-1}

                                                                id={fields.workTimes.id + '_' + selected.index  +  '_' + i + '_' + fields.workTimesTimeTo.id + '_' + j + '_timeTo'}
                                                                isInvalid={!!(errors.selectedJobType && errors.selectedJobType.length > 0 && errors.selectedJobType[selected.index] && errors.selectedJobType[selected.index].workingDays && errors.selectedJobType[selected.index].workingDays[i] && errors.selectedJobType[selected.index].workingDays[i].times && errors.selectedJobType[selected.index].workingDays[i].times[j] && errors.selectedJobType[selected.index].workingDays[i].times[j].timeTo)}
                                                                placeholder={fields.workTimesTimeTo.placeholder}
                                                                value={subItem.timeTo}
                                                                onChange={(e: any) => {
                                                                    setFieldValue('selectedJobType.' + selected.index + '.workingDays.' + i + '.times.' + j + '.timeTo', e.target.value);
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <StyledFormLabel className={'d-block d-md-none'}><StyledPay>{fields.workTimesPay.label}</StyledPay></StyledFormLabel>
                                                    <Form.Control
                                                        as = {CurrencyInput}
                                                        allowDecimals={true}
                                                        allowNegativeValue={false}
                                                        disableGroupSeparators={true}
                                                        prefix={Utils.getCurrency()}
                                                        size={'sm'}
                                                        tabIndex={i*j}
                                                        style={{ borderColor: (errors.selectedJobType && errors.selectedJobType.length > 0 && errors.selectedJobType[selected.index] && errors.selectedJobType[selected.index].workingDays && errors.selectedJobType[selected.index].workingDays[i] && errors.selectedJobType[selected.index].workingDays[i].times && errors.selectedJobType[selected.index].workingDays[i].times[j] && errors.selectedJobType[selected.index].workingDays[i].times[j].standardPay) ? '#FF0090' : '#0066FF' }}

                                                        id={fields.workTimes.id + '_' + selected.index + '_' + i + '_' + fields.workTimesPay.id + '_' + j + '_pay'}
                                                        isInvalid={!!(errors.selectedJobType && errors.selectedJobType.length > 0 && errors.selectedJobType[selected.index] && errors.selectedJobType[selected.index].workingDays && errors.selectedJobType[selected.index].workingDays[i] && errors.selectedJobType[selected.index].workingDays[i].times && errors.selectedJobType[selected.index].workingDays[i].times[j] && errors.selectedJobType[selected.index].workingDays[i].times[j].standardPay)}
                                                        placeholder={fields.workTimesPay.placeholder}
                                                        value={Utils.isNumber(subItem.standardPay) ? subItem.standardPay : ''}
                                                        onValueChange={(value: any) => {
                                                            setFieldValue('selectedJobType.' + selected.index + '.workingDays.' + i + '.times.' + j + '.standardPay', value);
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        })}
                                    </Col>
                                </Row>
                            }
                        })}
                    </>}
                </Col>
            </Row>
        }
    }
    const specialDatesForm = () => {
        let selected = getSelected();
        if(selected && selected.item){
            return <Row>
                <Col xs={12} className={'mb-3'}>
                    <Row>
                        <Col xs={12} md={4} lg={3} xl={3}>
                            <div className={'position-absolute'}><StyledFormLabel>SPECIAL DATES</StyledFormLabel></div>
                        </Col>
                        <Col xs={12} md={8} lg={9} xl={9}>
                            <Row className={'align-items-center mb-2'}>
                                <Col xs={12} md={8}>&nbsp;</Col>
                                <Col xs={12} md={4} className={'d-none d-md-block'}><StyledPay>Pay</StyledPay></Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12}>
                    {(selected.item.specialDays && selected.item.specialDays.length > 0) && <>
                        {selected.item.specialDays.map((item: any, i: number) => {
                            return <Row key={'specialDays_' + i} className={'mb-3'}>
                                <Col xs={12} md={4} lg={3} xl={3}>
                                    <StyledDatePicker
                                        as = {RSuiteDatePicker}
                                        isInvalid={!!(errors.specialDays && errors.specialDays.length > 0 && errors.specialDays[i] && errors.specialDays[i].date)}
                                        className={'form-control'}
                                        tabIndex={1000+i}
                                        
                                        placeholder={fields.specialDatesDate.placeholder}
                                        id={fields.specialDates.id + '_' + i + '_date'}
                                        value={item.date}
                                        icon={<></>}
                                        placement={'bottomStart'}
                                        cleanable={true}
                                        isDefault={true}
                                        readOnly={true}
                                        oneTap={true}
                                        // disabledDate={(date: any) => {
                                        //     return (moment(date).isAfter(moment().subtract(1, 'days'))) ? false : true;
                                        // }}
                                        onChange={(value: any) => {
                                            setFieldValue('selectedWorkTime.specialDays.' + i + '.date', value);
                                        }}
                                    />
                                </Col>
                                <Col xs={12} md={8} lg={9} xl={9}>
                                    {item.times.map((subItem: any, j: number) => {
                                        return <Row key={'work_times_' + i + '_day_' + j} className={'align-items-center mb-2'}>
                                            <Col xs={12} md={8} className={'mb-3 mb-md-0'}>
                                                <Row className={'align-items-center'}>
                                                    <Col xs={12} sm={true} className={'custom-rsuite-time-picker-component'}>
                                                        <Form.Control
                                                            as = {InputMask}
                                                            mask={'99:99'}
                                                            size={'sm'}
                                                            readOnly={true}
                                                            tabIndex={-1}

                                                            id={fields.workTimes.id + '_' + selected.index + '_' + i + '_' + fields.workTimesTimeFrom.id + '_' + j + '_timeFrom'}
                                                            isInvalid={!!(errors.selectedJobType && errors.selectedJobType.length > 0 && errors.selectedJobType[selected.index] && errors.selectedJobType[selected.index].specialDays && errors.selectedJobType[selected.index].specialDays[i] && errors.selectedJobType[selected.index].specialDays[i].times && errors.selectedJobType[selected.index].specialDays[i].times[j] && errors.selectedJobType[selected.index].specialDays[i].times[j].timeFrom)}
                                                            placeholder={fields.workTimesTimeFrom.placeholder}
                                                            value={subItem.timeFrom}
                                                            onChange={(e: any) => {
                                                                setFieldValue('selectedJobType.' + selected.index + '.specialDays.' + i + '.times.' + j + '.timeFrom', e.target.value);
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col xs={'auto'} className={'d-none d-lg-block px-1'}><StyledFormText>TO</StyledFormText></Col>
                                                    <Col xs={12} sm={true} className={'custom-rsuite-time-picker-component'}>
                                                        <Form.Control
                                                            as = {InputMask}
                                                            mask={'99:99'}
                                                            size={'sm'}
                                                            readOnly={true}
                                                            tabIndex={-1}

                                                            id={fields.workTimes.id + '_' + selected.index  +  '_' + i + '_' + fields.workTimesTimeTo.id + '_' + j + '_timeTo'}
                                                            isInvalid={!!(errors.selectedJobType && errors.selectedJobType.length > 0 && errors.selectedJobType[selected.index] && errors.selectedJobType[selected.index].specialDays && errors.selectedJobType[selected.index].specialDays[i] && errors.selectedJobType[selected.index].specialDays[i].times && errors.selectedJobType[selected.index].specialDays[i].times[j] && errors.selectedJobType[selected.index].specialDays[i].times[j].timeTo)}
                                                            placeholder={fields.workTimesTimeTo.placeholder}
                                                            value={subItem.timeTo}
                                                            onChange={(e: any) => {
                                                                setFieldValue('selectedJobType.' + selected.index + '.specialDays.' + i + '.times.' + j + '.timeTo', e.target.value);
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <StyledFormLabel className={'d-block d-md-none'}><StyledPay>{fields.workTimesPay.label}</StyledPay></StyledFormLabel>
                                                <Form.Control
                                                    as = {CurrencyInput}
                                                    allowDecimals={true}
                                                    allowNegativeValue={false}
                                                    disableGroupSeparators={true}
                                                    prefix={Utils.getCurrency()}
                                                    size={'sm'}
                                                    tabIndex={i*j}
                                                    style={{ borderColor: (errors.selectedJobType && errors.selectedJobType.length > 0 && errors.selectedJobType[selected.index] && errors.selectedJobType[selected.index].specialDays && errors.selectedJobType[selected.index].specialDays[i] && errors.selectedJobType[selected.index].specialDays[i].times && errors.selectedJobType[selected.index].specialDays[i].times[j] && errors.selectedJobType[selected.index].specialDays[i].times[j].standardPay) ? '#FF0090' : '#0066FF' }}

                                                    id={fields.workTimes.id + '_' + selected.index + '_' + i + '_' + fields.workTimesPay.id + '_' + j + '_pay'}
                                                    isInvalid={!!(errors.selectedJobType && errors.selectedJobType.length > 0 && errors.selectedJobType[selected.index] && errors.selectedJobType[selected.index].specialDays && errors.selectedJobType[selected.index].specialDays[i] && errors.selectedJobType[selected.index].specialDays[i].times && errors.selectedJobType[selected.index].specialDays[i].times[j] && errors.selectedJobType[selected.index].specialDays[i].times[j].standardPay)}
                                                    placeholder={fields.workTimesPay.placeholder}
                                                    value={Utils.isNumber(subItem.standardPay) ? subItem.standardPay : ''}
                                                    onValueChange={(value: any) => {
                                                        setFieldValue('selectedJobType.' + selected.index + '.specialDays.' + i + '.times.' + j + '.standardPay', value);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    })}
                                </Col>
                            </Row>
                        })}
                    </>}
                </Col>
            </Row>
        }
    }

    const menuSection = () => {
        let selected = getSelected();

        return <Row style={{ padding: '0px 15px' }}>
            <Col xs={12} className={'mb-2 mb-lg-7 pt-5'}>
                <h3>Job types</h3>
            </Col>
            <StyleMenuWrapperCol xs={12}>
                {jobTypes.map((item: any, i: number) => {
                    let errItm: any = null;
                    if(errors && errors.selectedJobType && Array.isArray(errors.selectedJobType) && errors.selectedJobType.length > 0){
                        errItm = errors.selectedJobType.filter((x: any) => x.jobTemplateId).find((x: any) => x.jobTemplateId === item.jobTemplateId);
                    }
                    
                    return <StyledMenuRow
                        key={'menu-' + item.jobTemplateId}
                        active={(selected && selected.item && selected.item.jobTemplateId && selected.item.jobTemplateId > 0) ? (selected.item.jobTemplateId === item.jobTemplateId) ? "true" : "" : ""}
                        error={errItm ? 'true' : ''}
                        filled={hasSelectedDateTime(((values && values.selectedJobType && values.selectedJobType.length > 0) ? values.selectedJobType : []), item.jobTemplateId) ? 'true' : ''}
                        onClick={(e: any) => {
                            e.stopPropagation();
                            e.preventDefault();
                            
                            let arr: Array<SelectedJobTypeStruct> = _.cloneDeep(values.selectedJobType);
                            arr = arr.map((item: SelectedJobTypeStruct, i: number) => {
                                item.isSelected = false;
                                return item;
                            });
                            let selectedIndex = arr.findIndex(x => x.jobTemplateId === item.jobTemplateId);
                            arr[selectedIndex].isSelected = true
                            setFieldValue('selectedJobType', arr);
                        }}
                    >
                        <Col xs={true}>{item.jobTemplateName}</Col>
                        <Col xs={'auto'}>
                            <Tooltip id={'row-' + item.jobTemplateId} title={'Copy'}>
                                <a href={'/'} tabIndex={-1}>
                                    <i className={'fa fa-clone'}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            
                                            dispatch(payrollBetaCopyJobTypes.show({ jobTemplateId: item.jobTemplateId, jobTypes: jobTypes, geofenceIndex: null }));
                                        }}
                                    ></i>
                                </a>
                            </Tooltip>
                        </Col>
                    </StyledMenuRow>
                })}
            </StyleMenuWrapperCol>
        </Row>
    }


    return <Row>
        <Col xs={12} lg={2} xl={2}>
            {menuSection()}
            <CopyJobType
                onSave={(jobTemplateId: number|null, checkedJobTypes: Array<number>, geofenceIndex: number|null) => {
                    if(values.selectedJobType && values.selectedJobType.length > 0){
                        let item = values.selectedJobType.find((x: SelectedJobTypeStruct) => x.jobTemplateId === jobTemplateId);
                        if(item){
                            let arr = copyJobType(item, values.selectedJobType, checkedJobTypes);
                            setFieldValue('selectedJobType', arr);
                        }
                    }
                }}
            />
            <Form.Control.Feedback type="invalid" className={'d-block'}>{(errors.selectedJobType && errors.selectedJobType && typeof errors.selectedJobType === "string") && errors.selectedJobType}</Form.Control.Feedback>
        </Col>
        <Col xs={12} lg={{ span: 10 }}>
            <Row className={'justify-content-center'} style={{ border: '1px solid #ececec', borderBottom: '0px' }}>
                <Col xs={12} lg={6} xl={6} className={'py-5 ps-5'}><h3 className={'m-0'}>Work Time</h3></Col>
                <Col xs={12} lg={6} xl={6}></Col>
            </Row>
            <Row className={'justify-content-center'} style={{ border: '1px solid #ececec' }}>
                <StyledColLeft xs={12} lg={6} xl={6} hasspecialdate={values.hasSpecialDates}>{workTimeForm()}</StyledColLeft>
                {values.hasSpecialDates && <StyledColRight xs={12} lg={6} xl={6}>{specialDatesForm()}</StyledColRight>}
            </Row>
        </Col>
    </Row>
}


export default WorkTimeJobType;
