import React from "react";
import moment from "moment";
import styled from 'styled-components';
import CurrencyInput from 'react-currency-input-field';

import { 
    Row,
    Col,
    Form,
    Button,
} from "react-bootstrap-v5";

import { FieldArray } from 'formik';

import InputLayout from "../../../../components/input/InputLayout";
import RSuiteSelectPicker from '../../../../components/OLD/Select/RSuiteSelectPicker';
import RSuiteDatePicker from '../../../../components/OLD/Datepicker/RSuiteDatePicker';
import RSuiteTimePicker from '../../../../components/OLD/TimePicker/RSuiteTimePicker';

import Utils from "../../../../utils/utils";
import { RepeatOrder, CustomerSiteAvailability } from "../../../../utils/enums";

import { connect } from 'react-redux'
import { clear } from "../../../../../setup/redux/actions";
import { dispatchApiCallGet } from '../../../../../setup/redux/dispatch/actions'


const StyledCancel = styled.i`
    cursor: pointer;
    color: #DFDFDF;

    &:hover {
        color: #FF0090;
    }
`;


class RecurringSection extends React.Component {
  
    constructor(props) {
        super(props);
        
        this.name = 'job_form_recurring_section';

        let dataParams = (props.dataParams) ? props.dataParams : null;
        let id = (dataParams && dataParams.id) ? dataParams.id : null;
        let row = (dataParams && dataParams.row) ? dataParams.row : null;
        

        this.state = {
            id: id,
            row: row,

            weekdayItems: [],
            weekendItems: [],
            weekdaysAvailability: CustomerSiteAvailability.All,
            weekendAvailability: CustomerSiteAvailability.All,
            isCheckAvailability: false,
        };
    }


    componentDidMount(){
        this.callReadCustomerSiteDetailsApi((data) => {
            let weekdayArray = (data && data.customerSiteWeekdayJobWorkingTimes && data.customerSiteWeekdayJobWorkingTimes.length > 0) ? data.customerSiteWeekdayJobWorkingTimes.map(x => ({ workingTimeName: x.accountJobWorkingTime.workingTimeName, startTime: x.accountJobWorkingTime.startTime, endTime: x.accountJobWorkingTime.endTime })) : [];
            let weekendArray = (data && data.customerSiteWeekendJobWorkingTimes && data.customerSiteWeekendJobWorkingTimes.length > 0) ? data.customerSiteWeekendJobWorkingTimes.map(x => ({ workingTimeName: x.accountJobWorkingTime.workingTimeName, startTime: x.accountJobWorkingTime.startTime, endTime: x.accountJobWorkingTime.endTime })) : [];
            
            let weekdaysAvailability = (data && data.weekdaysAvailability) ? data.weekdaysAvailability : CustomerSiteAvailability.All;
            let weekendAvailability = (data && data.weekendAvailability) ? data.weekendAvailability : CustomerSiteAvailability.All;

            let weekdayItems = [];
            if(weekdayArray && weekdayArray.length > 0){
                weekdayArray.forEach((time, i) => {
                    let h = moment(time.startTime).format('h');
                    let m = moment(time.startTime).format('mm');

                    if(h == 12){
                        let a = moment(time.startTime).format('a');
                        h = (a == 'am') ? '0' : h;
                    }

                    weekdayItems.push({
                        h: h,
                        m: m,
                    });
                });
            }

            let weekendItems = [];
            if(weekendArray && weekendArray.length > 0){
                weekendArray.forEach((time, i) => {
                    let h = moment(time.startTime).format('h');
                    let m = moment(time.startTime).format('mm');

                    if(h == 12){
                        let a = moment(time.startTime).format('a');
                        h = (a == 'am') ? '0' : h;
                    }

                    weekendItems.push({
                        h: h,
                        m: m,
                    });
                });
            }

            this.setState({
                weekdayItems: weekdayItems,
                weekendItems: weekendItems,
                weekdaysAvailability: weekdaysAvailability,
                weekendAvailability: weekendAvailability,
                isCheckAvailability: false,
            });
        });
    }

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            this.name + '-customersite', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = []; //variables.items;

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.customerSiteId;
                        let title = item.siteNameDisplay;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.name + '-customersite-details', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(data);
                    }
                });
            }
        );
    }
	
    callReadCustomerSiteApi = (customerId = null, items, callback = null) => {
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            customerId: customerId,
            showRecentJob: true,
        };
     
        this.props.dispatchApiCallGet(data, this.name + '-customersite', 'customersite', { items: items }, callback, () => {});
    }

    callReadCustomerSiteDetailsApi = (callback = null) => {
        this.setState({
            isCheckAvailability: true,
        });

        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;

        let customerSiteId = (values && values.customerSiteId) ? values.customerSiteId : null;

        this.props.dispatchApiCallGet(null, this.name + '-customersite-details', 'customersite/' + customerSiteId, null, callback, () => {});
    }
    /* END API */


    body = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;
        
            return <>
                <Row>
                    {values.isRecurringForm && <>
                        <Col xs={12} className={'mt-10 mb-10'}><hr /></Col>
                        <Col xs={12} sm={'auto'} className={'mb-10'}>
                            <h3>SET UP RECURRING JOBS</h3>
                        </Col>
                    </>}

                    <Col xs={12}>
                        <InputLayout
                            label={this.props.fields.repeatOrder.label}
                        >
                            <Form.Control
                                as = {RSuiteSelectPicker}

                                id={'status'}
                                placeholder={''}
                                items={[
                                    {
                                        value: RepeatOrder.Daily,
                                        title: 'Daily',
                                    },
                                    {
                                        value: RepeatOrder.Weekly,
                                        title: 'Weekly',
                                    },
                                    {
                                        value: RepeatOrder.Monthly,
                                        title: 'Monthly',
                                    },
                                ]}
                                value={values.repeatOrder}
                                isLoading={false}
                                searchable={false}
                                cleanable={false}
                                isDefault={true}
                                onSelect={async (value, item) => {
                                    let schedules = Utils.getSchedules(value);

                                    await setFieldValue('repeatOrder', value);
                                    await setFieldValue('schedules', schedules);
                                }}
                            />
                            <Form.Control.Feedback type="invalid">{errors.repeatOrder}</Form.Control.Feedback>
                        </InputLayout>
                    </Col>
                    <Col xs={12}>
                        <InputLayout
                            label={this.props.fields.recurringJobName.label}
                        >
                            <Form.Control
                                type="text"
                                id={this.props.fields.recurringJobName.id}
                                placeholder={this.props.fields.recurringJobName.placeholder}
                                value={values.recurringJobName}
                                onChange={handleChange}
                                isInvalid={!!errors.recurringJobName}
                            />
                            <Form.Control.Feedback type="invalid">{errors.recurringJobName}</Form.Control.Feedback>
                        </InputLayout>
                    </Col>
                    <Col xs={12}>
                        <InputLayout
                            label={this.props.fields.recurringJobDescription.label}
                        >
                            <Form.Control
                                type="text"
                                id={this.props.fields.recurringJobDescription.id}
                                placeholder={this.props.fields.recurringJobDescription.placeholder}
                                value={values.recurringJobDescription}
                                onChange={handleChange}
                                isInvalid={!!errors.recurringJobDescription}
                            />
                            <Form.Control.Feedback type="invalid">{errors.recurringJobDescription}</Form.Control.Feedback>
                        </InputLayout>
                    </Col>
                    {/* <Col xs={12}>
                        <InputLayout
                            label={this.props.fields.recurringNumberOfTrip.label}
                        >
                            <Form.Control
                                type="number"
                                id={this.props.fields.recurringNumberOfTrip.id}
                                placeholder={this.props.fields.recurringNumberOfTrip.placeholder}
                                value={values.recurringNumberOfTrip}
                                onChange={handleChange}
                                isInvalid={!!errors.recurringNumberOfTrip}
                            />
                            <Form.Control.Feedback type="invalid">{errors.recurringNumberOfTrip}</Form.Control.Feedback>
                        </InputLayout>
                    </Col> */}

                    {((values.repeatOrder === RepeatOrder.Weekly) || (values.repeatOrder === RepeatOrder.Monthly)) && <>
                        <Col xs={12} className={'mt-10 pt-10'}>Occurs on every</Col>
                        <Col xs={12}><hr /></Col>
                    </>}

                    {((values.repeatOrder === RepeatOrder.Weekly) || (values.repeatOrder === RepeatOrder.Monthly)) && <Col xs={12} className={'mb-10 pb-10'}>
                        <FieldArray 
                            name={'schedules'}
                            validateOnChange={false}
                        >
                            {({ remove, push }) => (
                                <>
                                    {(values.schedules && values.schedules.length > 0) && 
                                        values.schedules.map((item, i) => {
                                            return <Row key={i} className={'align-items-center mb-7'}>
                                                {(values.repeatOrder === RepeatOrder.Weekly) && <Col xs={12} md={4} xl={2} className={'pb-5 pb-md-0'}>
                                                    <Form.Group>
                                                        <Form.Check
                                                            id={this.props.fields.schedulesDay.id + '_' + i + '_day'}
                                                            label={this.props.fields.schedulesDays[i]}
                                                            checked={item.isChecked}       
                                                            onChange={async (e) => {
                                                                await setFieldValue('schedules.' + i + '.isChecked', e.target.checked);

                                                                if(e.target.checked){
                                                                    // let timeFrom = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 9);
                                                                    // let dateTimeFrom = Utils.convertDateTime(timeFrom);
                                                                    
                                                                    // await setFieldValue('schedules.' + i + '.time', dateTimeFrom);
                                                                } else {
                                                                    await setFieldValue('schedules.' + i + '.time', null);
                                                                }
                                                            }}
                                                            isInvalid={!!(errors && errors.schedules && errors.schedules[i] && errors.schedules[i].isChecked)}
                                                            feedback={errors && errors.schedules && errors.schedules[i] && errors.schedules[i].isChecked}
                                                        />
                                                    </Form.Group>
                                                </Col>}
                                                <Col xs={true} md={5} xl={4}>
                                                    {
                                                        (values.repeatOrder === RepeatOrder.Monthly)
                                                        ?
                                                        <Form.Group>
                                                            <Form.Control
                                                                as = {CurrencyInput}
                                                                isInvalid={!!(errors && errors.schedules && errors.schedules[i] && errors.schedules[i].date)}
                                                                
                                                                allowDecimals={false}
                                                                allowNegativeValue={false}
                                                                disableGroupSeparators={true}
                                                                suffix={Utils.addSuffix(item.date)}

                                                                id={this.props.fields.schedulesTime.id + '_' + i + '_time_day'}
                                                                placeholder={''}
                                                                value={item.date}
                                                                onValueChange={async (value) => {
                                                                    await setFieldValue('schedules.' + i + '.date', value);
                                                                }}
                                                                onClick={(e) => {
                                                                    e.target.select();
                                                                }}
                                                            />
                                                            {/* <Form.Control
                                                                type="number"
                                                                id={this.props.fields.schedulesTime.id + '_' + i + '_time_day'}
                                                                placeholder={''}
                                                                value={item.date}
                                                                onChange={async (e) => {
                                                                    await setFieldValue('schedules.' + i + '.date', e.target.value);
                                                                }}
                                                                isInvalid={!!(errors && errors.schedules && errors.schedules[i] && errors.schedules[i].date)}
                                                            /> */}
                                                            <Form.Control.Feedback type="invalid">{errors && errors.schedules && errors.schedules[i] && errors.schedules[i].date}</Form.Control.Feedback>
                                                        </Form.Group>
                                                        :
                                                        <Form.Group>
                                                            <Form.Control
                                                                as = {RSuiteTimePicker}
                                                                isInvalid={!!(errors && errors.schedules && errors.schedules[i] && errors.schedules[i].time)}

                                                                id={this.props.fields.schedulesTime.id + '_' + i + '_time'}
                                                                value={item.time}
                                                                disabled={!item.isChecked}
                                                                // hideMinutes={minute => minute % 30 !== 0}
                                                                ranges={[]}
                                                                hideHours={(hour) => {
                                                                    let items = [];
                                                                    if(i == 5 || i == 6){
                                                                        if(this.state.weekendAvailability == CustomerSiteAvailability.SelectedHours){
                                                                            items = this.state.weekendItems.filter(x => x.h == hour);
                                                                            return !(items && items.length > 0);
                                                                        } else if(this.state.weekendAvailability == CustomerSiteAvailability.NotAvailable){
                                                                            return true;
                                                                        } else {
                                                                            return false;
                                                                        }
                                                                    } else {
                                                                        if(this.state.weekdaysAvailability == CustomerSiteAvailability.SelectedHours){
                                                                            items = this.state.weekdayItems.filter(x => x.h == hour);
                                                                            return !(items && items.length > 0);
                                                                        } else if(this.state.weekdaysAvailability == CustomerSiteAvailability.NotAvailable){
                                                                            return true;
                                                                        } else {
                                                                            return false;
                                                                        }
                                                                    }
                                                                }}
                                                                disabledHours={(hour) => {
                                                                    let items = [];
                                                                    if(i == 5 || i == 6){
                                                                        if(this.state.weekendAvailability == CustomerSiteAvailability.SelectedHours){
                                                                            items = this.state.weekendItems.filter(x => x.h == hour);
                                                                            return !(items && items.length > 0);
                                                                        } else if(this.state.weekendAvailability == CustomerSiteAvailability.NotAvailable){
                                                                            return true;
                                                                        } else {
                                                                            return false;
                                                                        }
                                                                    } else {
                                                                        if(this.state.weekdaysAvailability == CustomerSiteAvailability.SelectedHours){
                                                                            items = this.state.weekdayItems.filter(x => x.h == hour);
                                                                            return !(items && items.length > 0);
                                                                        } else if(this.state.weekdaysAvailability == CustomerSiteAvailability.NotAvailable){
                                                                            return true;
                                                                        } else {
                                                                            return false;
                                                                        }
                                                                    }
                                                                }}
                                                                hideMinutes={(minute) => {
                                                                    let items = [];
                                                                    if(i == 5 || i == 6){
                                                                        if(this.state.weekendAvailability == CustomerSiteAvailability.SelectedHours){
                                                                            items = this.state.weekendItems.filter(x => x.m == minute);
                                                                            return !(items && items.length > 0);
                                                                        } else if(this.state.weekendAvailability == CustomerSiteAvailability.NotAvailable){
                                                                            return true;
                                                                        } else {
                                                                            return false;
                                                                        }
                                                                    } else {
                                                                        if(this.state.weekdaysAvailability == CustomerSiteAvailability.SelectedHours){
                                                                            items = this.state.weekdayItems.filter(x => x.m == minute);
                                                                            return !(items && items.length > 0);
                                                                        } else if(this.state.weekdaysAvailability == CustomerSiteAvailability.NotAvailable){
                                                                            return true;
                                                                        } else {
                                                                            return false;
                                                                        }
                                                                    }
                                                                }}
                                                                disabledMinutes={(minute) => {
                                                                    let items = [];
                                                                    if(i == 5 || i == 6){
                                                                        if(this.state.weekendAvailability == CustomerSiteAvailability.SelectedHours){
                                                                            items = this.state.weekendItems.filter(x => x.m == minute);
                                                                            return !(items && items.length > 0);
                                                                        } else if(this.state.weekendAvailability == CustomerSiteAvailability.NotAvailable){
                                                                            return true;
                                                                        } else {
                                                                            return false;
                                                                        }
                                                                    } else {
                                                                        if(this.state.weekdaysAvailability == CustomerSiteAvailability.SelectedHours){
                                                                            items = this.state.weekdayItems.filter(x => x.m == minute);
                                                                            return !(items && items.length > 0);
                                                                        } else if(this.state.weekdaysAvailability == CustomerSiteAvailability.NotAvailable){
                                                                            return true;
                                                                        } else {
                                                                            return false;
                                                                        }
                                                                    }
                                                                }}
                                                                onChange={async (value) => {
                                                                    let dateTime = Utils.convertDateTime(value);
                                                                    await setFieldValue('schedules.' + i + '.time', dateTime);
                                                                }}
                                                            />
                                                            <Form.Control.Feedback type="invalid">{errors && errors.schedules && errors.schedules[i] && errors.schedules[i].time}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    }
                                                </Col>

                                                {(values.repeatOrder === RepeatOrder.Monthly) && <Col xs={'auto'}>
                                                    {(values.schedules.length > 1) && <StyledCancel
                                                        className={'material-icons'}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                            
                                                            remove(i);
                                                        }}
                                                    >cancel</StyledCancel>}
                                                </Col>}

                                            </Row>
                                        })
                                    }

                                    {(values.repeatOrder === RepeatOrder.Monthly) && <a href={'/'}
                                        className={'link-default fw-bold'}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();

                                            validateForm().then((err) => {
                                                if(!!err.schedules){
                                                    if(typeof err.schedules === 'string'){
                                                        Utils.toast(err.schedules, 'error');
                                                    } else {
                                                        Utils.toast('You must fill in the required fields', 'error');
                                                    }
                                                } else {
                                                    push(Utils.getSchedules(RepeatOrder.Monthly)[0]);
                                                }
                                            })
                                        }}
                                    >
                                        + Add New Dates
                                    </a>}
                                </>
                            )}
                        </FieldArray>
                    </Col>}

                    
                    <Col xs={12}>
                        <InputLayout
                            label={this.props.fields.recurringStartDate.label}
                        >
                            <Form.Control
                                as = {RSuiteDatePicker}
                                isInvalid={!!errors.recurringStartDate}
                                
                                placeholder={this.props.fields.recurringStartDate.placeholder}
                                id={this.props.fields.recurringStartDate.id}
                                value={values.recurringStartDate}
                                icon={<></>}
                                placement={'autoVerticalStart'}
                                cleanable={true}
                                isDefault={true}
                                disabledDate={(date) => {
                                    let currentDateWithTZ = Utils.getTZDateFromSettings().toDate();
                                    return (moment(date).isAfter(moment(currentDateWithTZ).subtract(1, 'days'))) ? false : true;
                                }}
                                onChange={async (value) => {
                                    await setFieldValue('recurringStartDate', value);
                                }}
                                onClear={async () => {
                                    await setFieldValue('recurringStartDate', null);
                                }}
                            />
                            <Form.Control.Feedback type="invalid">{errors.recurringStartDate}</Form.Control.Feedback>
                        </InputLayout>
                    </Col>
                    <Col xs={12}>
                        <InputLayout
                            label={this.props.fields.recurringEndDate.label}
                        >
                            <Form.Control
                                as = {RSuiteDatePicker}
                                isInvalid={!!errors.recurringEndDate}
                                
                                placeholder={this.props.fields.recurringEndDate.placeholder}
                                id={this.props.fields.recurringEndDate.id}
                                value={values.recurringEndDate}
                                icon={<></>}
                                placement={'autoVerticalStart'}
                                cleanable={true}
                                isDefault={true}
                                disabledDate={(date) => {
                                    return (moment(date).isBetween(moment().subtract(1, 'days'), moment(values.recurringStartDate).add(1, 'days').add(1, 'years'))) ? false : true;
                                }}
                                onChange={async (value) => {
                                    await setFieldValue('recurringEndDate', value);
                                }}
                                onClear={async () => {
                                    await setFieldValue('recurringEndDate', null);
                                }}
                            />
                            <Form.Control.Feedback type="invalid">{errors.recurringEndDate}</Form.Control.Feedback>
                        </InputLayout>
                    </Col>

                    {(values.repeatOrder === RepeatOrder.Daily) && <Col xs={12}>
                        <InputLayout
                            label={this.props.fields.recurringDefaultTime.label}
                        >
                            <Form.Group>
                                <Form.Control
                                    as = {RSuiteTimePicker}
                                    isInvalid={!!errors.recurringDefaultTime}

                                    id={this.props.fields.recurringDefaultTime.id}
                                    placeholder={this.props.fields.recurringDefaultTime.placeholder}
                                    value={values.recurringDefaultTime}

                                    ranges={[]}
                                    hideHours={(hour) => {
                                        if(this.state.weekdaysAvailability == CustomerSiteAvailability.SelectedHours){
                                            let items = this.state.weekdayItems.filter(x => x.h == hour);
                                            return !(items && items.length > 0);
                                        } else if(this.state.weekdaysAvailability == CustomerSiteAvailability.NotAvailable){
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    }}
                                    disabledHours={(hour) => {
                                        if(this.state.weekdaysAvailability == CustomerSiteAvailability.SelectedHours){
                                            let items = this.state.weekdayItems.filter(x => x.h == hour);
                                            return !(items && items.length > 0);
                                        } else if(this.state.weekdaysAvailability == CustomerSiteAvailability.NotAvailable){
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    }}
                                    hideMinutes={(minute) => {
                                        if(this.state.weekdaysAvailability == CustomerSiteAvailability.SelectedHours){
                                            let items = this.state.weekdayItems.filter(x => x.m == minute);
                                            return !(items && items.length > 0);
                                        } else if(this.state.weekdaysAvailability == CustomerSiteAvailability.NotAvailable){
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    }}
                                    disabledMinutes={(minute) => {
                                        if(this.state.weekdaysAvailability == CustomerSiteAvailability.SelectedHours){
                                            let items = this.state.weekdayItems.filter(x => x.m == minute);
                                            return !(items && items.length > 0);
                                        } else if(this.state.weekdaysAvailability == CustomerSiteAvailability.NotAvailable){
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    }}
                                    onChange={async (value) => {
                                        let dateTime = Utils.convertDateTime(value);
                                        await setFieldValue('recurringDefaultTime', dateTime);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">{errors.recurringDefaultTime}</Form.Control.Feedback>
                            </Form.Group>
                        </InputLayout>
                    </Col>}

                    {!values.isRecurringForm && <Col xs={12}>
                        <InputLayout
                            label={this.props.fields.recurringAssignToSameDriverVehicle.label}
                            inputProps={{
                                className: 'd-flex align-items-center',
                            }}
                        >
                            {values.driverId && <>
                                {
                                    values.recurringAssignToSameDriverVehicle
                                    ?
                                    <>
                                        {Utils.customConcat([values.driverName, values.vehicleName], ' / ')}
                                        <div 
                                            className='btn btn-sm btn-icon btn-active-light-danger ms-4'
                                            onClick={async (e) => {
                                                e.preventDefault();
                                                e.stopPropagation();

                                                await setFieldValue('recurringAssignToSameDriverVehicle', false);
                                            }}
                                        >
                                            <i className={'material-icons text-danger'}>close</i>
                                        </div>
                                    </>
                                    :
                                    <>
                                    <a href={'/'}
                                        className={'link-primary'}
                                        onClick={async (e) => {
                                            e.preventDefault();
                                            e.stopPropagation();

                                            await setFieldValue('recurringAssignToSameDriverVehicle', true);
                                        }}
                                    >Assign</a>
                                    </>
                                }
                            </>}
                        </InputLayout>
                    </Col>}

                    {/* <Col xs={12}>
                        <InputLayout
                            label={this.props.fields.recurringAssignToSameDriverVehicle.label}
                            labelProps={{
                                xs: 'auto'
                            }}
                            inputProps={{
                                className: 'd-flex align-items-center',
                                xs: 'auto'
                            }}
                        >
                            <Form.Check
                                id={this.props.fields.recurringAssignToSameDriverVehicle.id}
                                label={null}
                                checked={values.recurringAssignToSameDriverVehicle}
                                onChange={handleChange}
                                isInvalid={!!errors.recurringAssignToSameDriverVehicle}
                                feedback={errors.recurringAssignToSameDriverVehicle}
                            />
                            <Form.Control.Feedback type="invalid">{errors.recurringAssignToSameDriverVehicle}</Form.Control.Feedback>
                        </InputLayout>
                    </Col> */}

                </Row>
            </>
    }


    render() {
        return <>
            {!this.state.isCheckAvailability && this.body()}
        </>
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(RecurringSection);
