import React, { Component } from 'react';

import { 
    Row,
    Col,
    Form,
    Modal,
    Button,
    CloseButton,
} from "react-bootstrap-v5";

import Utils from '../../../utils/utils'

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut } from '../../../../setup/redux/dispatch/actions'


class RemoveJobDialog extends Component {
  
    constructor(props){
        super(props);

        this.pageName = 'job_remove_dialog';

        this.state = {
            driverNoteText: '',
            driverNoteTextValidated: false,
        }
    }


    header = () => {
        return <Modal.Header>
            <Row className={'align-items-center gx-0 w-100'}>
                <Col xs={true}>
                    <Modal.Title>Are you sure you want to remove this job?</Modal.Title>
                </Col>
                <Col xs={'auto'}>
                    <CloseButton
                        className={'rounded-2'}
                        onClick={() => {
                            if(this.props.onHide){
                                this.props.onHide();
                            }
                        }}
                    />
                </Col>
            </Row>
        </Modal.Header>
    }
    
    body = () => {
        return <Modal.Body>
            <Row>
                <Col xs={12}>
                    {this.props.isCompletedJob && <>
                      <div className="alert alert-warning d-flex align-items-center fade show" role="alert">
                        <i className={'material-icons text-warning'}>warning</i>
                        <span className={'ps-3'}>Once you choose to delete a completed job, the details of the job will be removed from the maps and reports.</span>
                      </div>
                    </>}
                </Col>
                <Col xs={12}>
                    {this.fields()}
                </Col>
            </Row>
        </Modal.Body>
    }

    footer = () => {
        return <Modal.Footer>
            <Row className={'w-100'}>
                <Col xs={true}>
                    
                </Col>
                <Col xs={'auto'}>
                   <Button 
                        type={'button'}
                        variant={'light'}
                        disabled={this.state.isLoading}
                        onClick={() => {
                          if(this.props.onHide) {
                            this.props.onHide()
                          }
                        }}
                    >
                      Cancel
                    </Button>
                </Col>
                <Col xs={'auto'}>
                   <Button 
                        type={'button'}
                        variant={'danger'}
                        disabled={this.state.isLoading}
                        onClick={() => {
                          if(this.props.onOk) {
                            this.props.onOk({
                              driverNoteText: this.state.driverNoteText,
                            })
                          }
                        }}
                    >
                      Yes, Please remove
                    </Button>
                </Col>
            </Row>
        </Modal.Footer>
    }


    fields = () => {
      return <Row>
        <Col xs={12}>
          <Form.Group className={'mb-5'}>
              <Form.Label>Remarks</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                id={this.pageName + 'remove_Remarks_driverNoteText'}
                placeholder={Utils.placeholderRows(5)}
                disabled={this.state.isLoading}
                value={this.state.driverNoteText}
                onChange={(e) => {
                  Utils.limitRows(e.target.value, 5, async (value) => {
                    this.setState({
                      driverNoteText: e.target.value,
                      driverNoteTextValidated: ((e.target.value !== '') ? false : true),
                    });
                  });
                }}
                isInvalid={this.state.driverNoteTextValidated}
              />
              <Form.Control.Feedback type="invalid">You must fill in the field</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    }


    render() {
        return <>
            {this.header()}
            {this.body()}
            {this.footer()}
        </>
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  dispatchApiCallPost,
  dispatchApiCallPut,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(RemoveJobDialog);
