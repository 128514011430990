import {
    CLEAR,
    SUCCESS,
    ERROR,
    INITIAL_STATE,
} from '../actions';

import {
    COMMON_PROFILE_POST,
    COMMON_PROFILE_GET,
    COMMON_PROFILE_PUT,
    COMMON_PROFILE_DELETE,
} from './actions';

export const reducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case COMMON_PROFILE_POST:
        case COMMON_PROFILE_GET:
        case COMMON_PROFILE_PUT:
        case COMMON_PROFILE_DELETE:
        case SUCCESS:
        case ERROR:
        case CLEAR:
            return payload;

        default:
            return state;
    }
}