import React from 'react';

import { 
  Spinner,
} from "react-bootstrap-v5";

// import Utils from '../../../../utils/utils';
import { StyledBlockerLoading, StyledOverlay } from "../../../../utils/styles";

// import { useDispatch, useSelector } from "react-redux";
import { useSelector } from "react-redux";
import {RootState} from '../../../../../setup'
// import payrollBetaForm from '../redux/payrollBetaForm';

import { StyledFooter, StyledTop, StyledPage } from '../style/styles';



const PayrollBetaForm = (props: any) => {
  // const dispatch = useDispatch();
  const { isLoading } = useSelector((state: RootState) => state.payrollBetaForm);
  const { isMainLoading, step } = useSelector((state: RootState) => state.payrollBetaLayout);
  

  return <div>
    <StyledTop>{props.topSection}</StyledTop>
    <StyledPage>
      {(step === '1') && <div>{props.step1Section}</div>}
      {(step === '2') && <div>{props.step2Section}</div>}
      {(step === '3a' || step === '3b') && <div>{props.step3Section}</div>}
      {(step === '4') && <div>{props.step4Section}</div>}
    </StyledPage>
    <StyledFooter>{props.footerSection}</StyledFooter>

    {(isLoading || isMainLoading) && <StyledBlockerLoading>
      <StyledOverlay />
      <Spinner animation="border" variant="primary" />
    </StyledBlockerLoading>}
  </div>
}


export default PayrollBetaForm;
