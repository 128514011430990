import React from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CurrencyInput from 'react-currency-input-field';
import _ from 'lodash';

import { 
    Row,
    Col,
    Form,
    InputGroup,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import { Formik, FieldArray } from 'formik';
import * as yup from 'yup';

import Utils from "../../../utils/utils";
import { CustomerCreditLimitAction } from "../../../utils/enums";

import InputLayout from "../../../components/input/InputLayout";
import RTextEditor from '../../../components/OLD/RichTextEditor/RTextEditor.jsx';
import Tooltip from '../../../components/Tooltip/Tooltip';
import RSuiteTagInput from '../../../components/OLD/TagsInput/RSuiteTagInput';
import RSuiteCheckPicker from "../../../components/OLD/Checkbox/RSuiteCheckPicker.jsx";
import Accordion from '../../../components/accordion/Accordion';

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut, dispatchApiCallDelete } from '../../../../setup/redux/dispatch/actions'


const StyledTableWrapper = styled.div`
    margin-top: 15px;
    margin-bottom: 25px;
    overflow: auto;
`;
const StyledTable = styled.table`
    width: 100%;
`;
const StyledThead = styled.thead`

`;
const StyledTheadTr = styled.tr`
    
`;
const StyledTheadTrTh = styled.th`
    background-color: ${props => props.islast ? 'transparent' : '#F7F7F7'} !important;
    border: 1px solid ${props => props.islast ? 'transparent' : '#ECECEC'} !important;
    padding: 10px 20px;

`;
const StyledTbody = styled.tbody`
    
`;
const StyledTbodyTr = styled.tr`
    &:hover {
        > td {
            background-color: transparent !important;
        }
    }
`;
const StyledTbodyTrTd = styled.td`
    position: relative;
    border: 1px solid ${props => props.islast ? 'transparent' : '#ECECEC'} !important;
    padding: 0px;
`;
const StyledTfoot = styled.tbody`
    
`;
const StyledTfootTr = styled.tr`
    &:hover {
        > td {
            background-color: transparent !important;
        }
    }
`;
const StyledTfootTrTd = styled.td`
    padding: 15px 0 0 0;
`;
const StyledTbodyTrTdEmpty = styled.td`
    border: 1px solid ${props => props.islast ? 'transparent' : '#ECECEC'} !important;
    padding: 10px 20px;
    line-height: 23px;
    margin-bottom: 0px !important;
`;

const StyledRSuiteCheckPickerItem = styled.span`
    display: inline-block;
    background-color: #E7EEFF;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 10px;
`;

const StyledCancel = styled.i`
    cursor: pointer;
    color: #DFDFDF;

    &:hover {
        color: #FF0090;
    }
`;

const StyledTbodyTrTdSpan = styled.span`
    position: absolute;
    top: 12px;
    left: 47px;
`;

const TableInputText = styled(Form.Control)`
    border: 0px;
    border-radius: 0px;
    padding: 10px 20px;
    width: 100%;
    max-height: 43px;

    &:hover {
        outline: 1px solid #B5B5C3;
    }

    &:focus {
        outline: 1px solid #185CFF;
    }

    &.is-invalid {
        outline: 0px;
        border: 1px solid #FF0090;
    }

    &[disabled] {
        background-color: transparent !important;
    }

    ${props => {
        return (props.isInvalid) ? 'border: 1px solid #FF0090 !important;' : '';
    }}
`;
const TableCurrencyInput = styled(CurrencyInput)`
    border: 0px;
    border-radius: 0px;
    padding: 10px 20px;
    width: 100%;
    outline: 0px;
    border: 1px solid transparent;
    max-height: 43px;

    &:hover {
        border: 1px solid #B5B5C3;
    }

    &:focus {
        border: 1px solid #B5B5C3;
    }

    &.is-invalid {
        outline: 0px;
        border: 1px solid #FF0090;
    }

    &[disabled] {
        background-color: transparent !important;
    }

    ${props => {
        return (props.isInvalid) ? 'border: 1px solid #FF0090 !important;' : '';
    }}
`;

const CreditLimitInputGroupText = styled(InputGroup.Text)`
    border-right: 0px;
    background-color: transparent;
    border-top-left-radius: 0.475rem;
    border-bottom-left-radius: 0.475rem;
    border-color: ${props => (props.isinvalid == 'true') ? '#FF0090' : '#E4E6EF'};
    padding-right: 0px;
`;
const CreditLimiInput = styled(Form.Control)`
    border-left: 0px;
    padding-left: 1px;
    
    &:focus {
        border-color: #E4E6EF;
    }

    @media (min-width: 768px) {
        max-width: 130px;
    }
`;
const StyledTagInput = styled(RSuiteTagInput)`
    input {
        max-width: 100% !important;
    }
`;


const formFields = {
    creditLimit: {
      id: 'creditLimit',
      label: 'Credit Limit',
      placeholder: ' ',
    },
    creditLimitAlertMessage: {
        id: 'creditLimitAlertMessage',
        label: 'Alert Message',
        placeholder: ' ',
    },

    customerCreditLimitTriggers: {
      id: 'customerCreditLimitTriggers',
      label: ' ',
      placeholder: ' ',
    },
    percentOfCreditLimit: {
      id: 'percentOfCreditLimit',
      label: 'Credit Limit',
      placeholder: ' ',
    },
    action: {
      id: 'action',
      label: 'Trigger Action',
      placeholder: ' ',
    },
    alertAt: {
      id: 'alertAt',
      label: 'Alert At',
      placeholder: ' ',
    },
    isStopDispatching: {
      id: 'isStopDispatching',
      label: 'Stop Dispatching',
      placeholder: ' ',
    },
    emailAddress: {
      id: 'emailAddress',
      label: 'Alert Users',
      placeholder: ' ',
    },
    
    subject: {
      id: 'subject',
      label: 'Subject',
      placeholder: ' ',
    },
    fromName: {
        id: 'fromName',
        label: 'From',
        placeholder: ' ',
    },
    recipientCc: {
        id: 'recipientCc',
        label: 'CC Copy',
        placeholder: 'Email addresses (multiple emails with commas)',
        smallPlaceholder: ' ',
    },
    message: {
        id: 'message',
        label: 'Message',
        placeholder: ' ',
    },
}
const formSchema = yup.object().shape({
    isEdit: yup.bool().oneOf([true, false]),

    creditLimit: yup.number().nullable().min(1).label(formFields.percentOfCreditLimit.label),

    isStopDispatching: yup.boolean(),
    alertAt: yup.string().when(['isStopDispatching'], (isStopDispatching) => {
        if(isStopDispatching){
            return yup.number().nullable().required().min(1).max(100).label(formFields.creditLimit.label)
        } else {
            return yup.number().nullable().min(1).max(100).label(formFields.creditLimit.label)
        }
    }),
    creditLimitAlertMessage: yup.string().when(['isStopDispatching'], (isStopDispatching) => {
        if(isStopDispatching){
            return yup.string().required().label(formFields.creditLimitAlertMessage.label)
        } else {
            return yup.string().label(formFields.creditLimitAlertMessage.label)
        }
    }),
    
    customerCreditLimitTriggers: yup.array().of(yup.object().shape({
        percentOfCreditLimit: yup.number().when(['emailAddress'], (emailAddress) => {
            if(emailAddress.length > 0){
                return yup.number().nullable().required().min(1).max(100).label(formFields.percentOfCreditLimit.label);
            } else {
                return yup.number().nullable().min(1).max(100).label(formFields.percentOfCreditLimit.label);
            }
        }),

        action: yup.array().of(yup.number()).label(formFields.action.label),

        emailAddress: yup
        .array()
        .of(yup.string().email('Must be a valid email'))
        .label(formFields.emailAddress.label)
        .test('emailRequired', 'Email Address is required when Percent of Credit Limit is provided', function (value) {
            const percentOfCreditLimit = this.parent.percentOfCreditLimit;
            if (percentOfCreditLimit !== undefined && percentOfCreditLimit !== null) {
            if (!value || value.length === 0) {
                return false;
            }
            }
            return true;
        }),
    }))
    .test('is-unique', 'Credit limit must be unique', function (value) {
        if (!Array.isArray(value) || value.length <= 1)
        return true;

        const arr = value.map(obj => obj.percentOfCreditLimit);
        const unique = new Set(arr);

        return unique.size === arr.length;
    }),

    creditLimitEmailTemplate: yup.object().when(['customerCreditLimitTriggers'], (customerCreditLimitTriggers) => {
        let state = false;
        if(customerCreditLimitTriggers && customerCreditLimitTriggers.length > 0){
            for(let i = 0; i < customerCreditLimitTriggers.length; i++){
                let action = (customerCreditLimitTriggers[i] && customerCreditLimitTriggers[i].action && customerCreditLimitTriggers[i].action.length) ? customerCreditLimitTriggers[i].action : [];
                let emailAddress = (customerCreditLimitTriggers[i] && customerCreditLimitTriggers[i].emailAddress && customerCreditLimitTriggers[i].emailAddress.length) ? customerCreditLimitTriggers[i].emailAddress : [];
                let percentOfCreditLimit = (customerCreditLimitTriggers[i] && customerCreditLimitTriggers[i].percentOfCreditLimit && customerCreditLimitTriggers[i].percentOfCreditLimit != '') ? customerCreditLimitTriggers[i].percentOfCreditLimit : '';
                if(emailAddress.length > 0 && percentOfCreditLimit != ''){
                    if(action.includes(CustomerCreditLimitAction.Email)){
                        state = true;
                        break;
                    }
                }
            }
        }
        
        if(state){
            return yup.object().shape({
                subject: yup.string().required().label(formFields.subject.label),
                fromName: yup.string().required().label(formFields.fromName.label),
                // recipientCc: yup.string().label(formFields.recipientCc.label),
                recipientCc: yup.array().of(yup.string().email('Must be a valid email')).label(formFields.recipientCc.label),
                message: yup.string().required().label(formFields.message.label),
            })
        } else {
            return yup.object().shape({
                subject: yup.string().label(formFields.subject.label),
                fromName: yup.string().label(formFields.fromName.label),
                // recipientCc: yup.string().label(formFields.recipientCc.label),
                recipientCc: yup.array().of(yup.string().email('Must be a valid email')),
                message: yup.string().label(formFields.message.label),
            })
        }
    }),

});

  
class CreditLimitForm extends React.Component {
  
    constructor(props) {
        super(props);
    
        this.pageName = 'customer_credit_limit_form';
        this.backLink = '/Customers';

        this.refForm = React.createRef();
        this.refLastSelectedInput = React.createRef();

        let id = Utils.getIntProps(props);
        
        
        this.tagItems = [
            {
                value: '[[CustomerName]]',
                title: 'Customer Name',
            },
            {
                value: '[[CreditLimitAmount]]',
                title: 'Credit Limit',
            },
            {
                value: '[[CreditAmount]]',
                title: 'Credit Amount',
            },
            {
                value: '[[CreditPercent]]',
                title: 'Credit Percent',
            },
        ];


        let customerCreditLimitTriggersEmail = [];
        let customerCreditLimitTriggersStopService = [];
        let dataParam = this.props.dataParam;
        if(dataParam && dataParam.customerCreditLimitTriggers && dataParam.customerCreditLimitTriggers.length > 0){
            customerCreditLimitTriggersEmail = dataParam.customerCreditLimitTriggers.filter(x => x && x.action && x.action.length > 0 && x.action.includes(CustomerCreditLimitAction.Email));
            customerCreditLimitTriggersStopService = dataParam.customerCreditLimitTriggers.filter(x => x && x.action && x.action.length > 0 && x.action.includes(CustomerCreditLimitAction.StopService));
        }

        let isStopDispatching = false;
        let alertAt = '';
        if(customerCreditLimitTriggersStopService && customerCreditLimitTriggersStopService.length > 0){
            alertAt = customerCreditLimitTriggersStopService[0].percentOfCreditLimit;
            isStopDispatching = true;
        }

        dataParam['customerCreditLimitTriggers'] = customerCreditLimitTriggersEmail;
        dataParam['isStopDispatching'] = isStopDispatching;
        dataParam['alertAt'] = alertAt;


        this.state = {
            id: id,

            initialValues: dataParam,

            isOpen1: false,
            color1: '#BBBBBB',
            isOpen2: false,
            color2: '#BBBBBB',
        };
    }

    
    componentDidMount() {
        if(this.refForm && this.refForm.current){
            this.setState({
                isOpen1: this.hasValue1(),
                isOpen2: this.hasValue2(),
            });

            this.addRowIfEmpty();
            this.setColors();
        }
    }


    /* FUNCTIONS */
    setLoading = (state = false) => {
        if(this.props.onLoading){
            this.props.onLoading(state);
        }
    }

    isAtLeastOneFieldPopulated = (customerCreditLimitTriggers) => {
        if(customerCreditLimitTriggers && customerCreditLimitTriggers.length > 0){
            for (const trigger of customerCreditLimitTriggers) {
              if (
                trigger &&
                (trigger.percentOfCreditLimit ||
                  (trigger.emailAddress && trigger.emailAddress.length > 0))
              ) {
                return true;
              }
            }
            return false;
        }
        return false;
    }
    hasValue1 = () => {
        if(this.refForm && this.refForm.current){
            let { values } = this.refForm.current;

            // let customerCreditLimitTriggers = this.isAtLeastOneFieldPopulated(values.customerCreditLimitTriggers);
            // let subject = (values.creditLimitEmailTemplate && values.creditLimitEmailTemplate.subject && values.creditLimitEmailTemplate.subject != '');
            // let fromName = (values.creditLimitEmailTemplate && values.creditLimitEmailTemplate.fromName && values.creditLimitEmailTemplate.fromName != '');
            // let recipientCc = (values.creditLimitEmailTemplate && values.creditLimitEmailTemplate.recipientCc && values.creditLimitEmailTemplate.recipientCc.length > 0);
            // let message = (values.creditLimitEmailTemplate && values.creditLimitEmailTemplate.message && values.creditLimitEmailTemplate.message != '');
            // return (customerCreditLimitTriggers || subject || fromName || recipientCc || message);
        
            let customerCreditLimitTriggers = this.isAtLeastOneFieldPopulated(values.customerCreditLimitTriggers);
            return (customerCreditLimitTriggers);
        }
    }
    hasValue2 = () => {
        if(this.refForm && this.refForm.current){
            let { values } = this.refForm.current;

            return !Utils.isEmptyRow(values, ['isStopDispatching', 'alertAt', 'creditLimitAlertMessage']);
        }
    }
    setColors = () => {
        if(this.refForm && this.refForm.current){
            this.setState({
                color1: this.hasValue1() ? '#185CFF' : '#BBBBBB',
                color2: this.hasValue2() ? '#185CFF' : '#BBBBBB',
            });
        }
    }
    addRowIfEmpty = () => {
        if(this.refForm && this.refForm.current){
            let { values, setFieldValue } = this.refForm.current;

            let customerCreditLimitTriggers = values.customerCreditLimitTriggers;
            let hasEmpty = Utils.hasEmptyRows(customerCreditLimitTriggers, (item, i) => {
                return Utils.isEmptyRow(item, [ 'percentOfCreditLimit' ]);
            });
            
            if(!hasEmpty){
                customerCreditLimitTriggers.push({
                    percentOfCreditLimit: '',
                    action: [CustomerCreditLimitAction.Email],
                    emailAddress: [],
                });

                setFieldValue('customerCreditLimitTriggers', customerCreditLimitTriggers);
            }
        }
    }
    
    setSubmit = (data, form) => {
        let customerCreditLimitTriggers = [];
        if(data.customerCreditLimitTriggers && data.customerCreditLimitTriggers.length > 0){
            var arrCopy = data.customerCreditLimitTriggers.slice();
            customerCreditLimitTriggers = Utils.removeEmptyRowsWithArrays(arrCopy, (row) => {
                if(row['emailAddress']){
                    return ((row['percentOfCreditLimit'] && row['percentOfCreditLimit'] !== null) || row['emailAddress'].length > 0);
                }
            });
        }
        
        if(data.isStopDispatching){
            customerCreditLimitTriggers.push({
                percentOfCreditLimit: data.alertAt,
                action: [CustomerCreditLimitAction.StopService],
                emailAddress: []
            });
        }

        
        var newData = _.cloneDeep(data);
        newData['customerCreditLimitTriggers'] = customerCreditLimitTriggers;

        if(this.props.onSubmit){
            this.props.onSubmit(newData, form);
        }
    }
    /* END FUNCTIONS */


    /* FORM */
    creditLimitField = (formOptions) => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = formOptions;
        
        return <>
            <InputLayout
                label={formFields.creditLimit.label}
                required
                error={<Form.Control.Feedback type="invalid" style={{ display: ((!!errors.creditLimit) ? 'block' : 'none')}}>{errors.creditLimit}</Form.Control.Feedback>}
                labelProps={{
                    sm: 'auto'
                }}
                inputProps={{
                    md: 4,
                    lg: 3,
                    xl: 3,
                    xxl: 2
                }}
            >
                <InputGroup>
                    <CreditLimitInputGroupText isinvalid={(!!errors.creditLimit) ? 'true' : 'false'}>{Utils.getCurrency()}</CreditLimitInputGroupText>
                    <CreditLimiInput
                        type="number"
                        id={formFields.creditLimit.id}
                        placeholder={formFields.creditLimit.placeholder}
                        value={values.creditLimit}
                        onChange={async (e) => {
                            let value = e.target.value;
                            let pattern = Utils.onlyPositiveAndEmptyNumbers();

                            if(value.match(pattern) != null){
                                await setFieldValue('creditLimit', e.target.value);
                            }

                            this.setColors();
                        }}
                        onBlur={async (e) => {
                            if(!(e.target.value)){
                                // await setFieldValue('customerCreditLimitTriggers', []);
                                await setFieldValue('customerCreditLimitTriggers', [{
                                    percentOfCreditLimit: '',
                                    action: [CustomerCreditLimitAction.Email],
                                    emailAddress: [],
                                }]);
                            }
                        }}
                        min={0}
                        isInvalid={!!errors.creditLimit}
                    />
                </InputGroup>
            </InputLayout>
        </>
    }

    creditLimitTable = (formOptions) => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = formOptions;
        
        return <>
        
            <FieldArray 
                name={formFields.customerCreditLimitTriggers.id}
                validateOnChange={false}
            >
                {({ remove, push }) => (
                    <>
                        <StyledTableWrapper className={'responsive-table-md'}>
                            <StyledTable valign={'middle'} className={'no-responsive-table'}>
                                <StyledThead>
                                    <StyledTheadTr>
                                        <StyledTheadTrTh width={'15%'}>{formFields.percentOfCreditLimit.label}</StyledTheadTrTh>
                                        <StyledTheadTrTh width={'70%'}>{formFields.emailAddress.label}</StyledTheadTrTh>
                                        <StyledTheadTrTh width={'11%'} islast={true}>&nbsp;</StyledTheadTrTh>
                                    </StyledTheadTr>
                                </StyledThead>

                                <StyledTbody>
                                {
                                    (values.customerCreditLimitTriggers && values.customerCreditLimitTriggers.length > 0)
                                    ?
                                    values.customerCreditLimitTriggers.map((item, i) => {
                                        return <StyledTbodyTr key={i}>

                                            <StyledTbodyTrTd>
                                                <div className={'pt-1 ps-6 d-block d-md-none'}>{formFields.percentOfCreditLimit.label}</div>
                                                {/* <TableInputText
                                                    isInvalid={!!(errors && errors.customerCreditLimitTriggers && errors.customerCreditLimitTriggers[i] && errors.customerCreditLimitTriggers[i].percentOfCreditLimit)}
                                                    
                                                    type="number"
                                                    min={1}
                                                    max={100}

                                                    id={formFields.percentOfCreditLimit.id}
                                                    placeholder={formFields.percentOfCreditLimit.placeholder}
                                                    value={item.percentOfCreditLimit}
                                                    disabled={!(values.creditLimit)}
                                                    onChange={async (e) => {
                                                        let pattern = Utils.onlyPositiveAndEmptyNumbers100();

                                                        if(e.target.value.match(pattern) != null){
                                                            await setFieldValue('customerCreditLimitTriggers.' + i + '.percentOfCreditLimit', e.target.value);
                                                        }

                                                        this.setColors();
                                                    }}
                                                    onClick={(e) => {
                                                        e.target.select();
                                                    }}
                                                    onBlur={async (e) => {
                                                        if((item.percentOfCreditLimit < 1) || (item.percentOfCreditLimit > 100)){
                                                            if(item.percentOfCreditLimit < 1){
                                                                Utils.toast('Minimum credit limit is 1%', 'warning');
                                                            } else {
                                                                Utils.toast('Maximum credit limit is 100%', 'warning');
                                                            }
                                                            // await setFieldValue('customerCreditLimitTriggers.' + i + '.percentOfCreditLimit', '');
                                                        }
                                                    }}
                                                /> */}
                                                {/* {(item.percentOfCreditLimit != null && item.percentOfCreditLimit != '') && <StyledTbodyTrTdSpan>%</StyledTbodyTrTdSpan>} */}

                                                <Form.Control
                                                    as = {TableCurrencyInput}
                                                    isInvalid={!!(errors && errors.customerCreditLimitTriggers && errors.customerCreditLimitTriggers[i] && errors.customerCreditLimitTriggers[i].percentOfCreditLimit)}
                                                    
                                                    allowDecimals={false}
                                                    allowNegativeValue={false}
                                                    disableGroupSeparators={true}
                                                    suffix={'%'}

                                                    id={formFields.percentOfCreditLimit.id}
                                                    placeholder={formFields.percentOfCreditLimit.placeholder}
                                                    value={item.percentOfCreditLimit}
                                                    disabled={!(values.creditLimit)}
                                                    onValueChange={async (value) => {
                                                        let val = (value && value != '') ? value : '';
                                                        let pattern = Utils.onlyPositiveAndEmptyNumbers100();

                                                        if(val.match(pattern) != null){
                                                            await setFieldValue('customerCreditLimitTriggers.' + i + '.percentOfCreditLimit', val);
                                                        }

                                                        this.setColors();
                                                    }}
                                                    onClick={(e) => {
                                                        e.target.select();
                                                    }}
                                                    onBlur={async (e) => {
                                                        let percentOfCreditLimit = Utils.isNumber(item.percentOfCreditLimit) ? item.percentOfCreditLimit : '';
                                                        
                                                        if(Utils.isNumber(percentOfCreditLimit) && ((percentOfCreditLimit < 1) || (percentOfCreditLimit > 100))){
                                                            if(percentOfCreditLimit < 1){
                                                                Utils.toast('Minimum credit limit is 1%', 'warning');
                                                            } else {
                                                                Utils.toast('Maximum credit limit is 100%', 'warning');
                                                            }
                                                        }

                                                        this.setColors();
                                                    }}
                                                />
                                            </StyledTbodyTrTd>
                                            <StyledTbodyTrTd>
                                                <div className={'pt-1 ps-6 d-block d-md-none'}>{formFields.emailAddress.label}</div>
                                                <TableInputText
                                                    as = {RSuiteCheckPicker}
                                                    isInvalid={!!(errors && errors.customerCreditLimitTriggers && errors.customerCreditLimitTriggers[i] && errors.customerCreditLimitTriggers[i].emailAddress)}

                                                    id={formFields.emailAddress.id}
                                                    placeholder={formFields.emailAddress.placeholder}
                                                    items={this.props.emailItems}
                                                    value={item.emailAddress}
                                                    disabled={!item.action.includes(CustomerCreditLimitAction.Email) || !(values.creditLimit)}
                                                    isLoading={false}
                                                    searchable={false}
                                                    countable={false}
                                                    cleanable={true}
                                                    isDefault={true}
                                                    showSelectAll={false}
                                                    renderValue={(value, checkedItems, selectedElement) => {
                                                        if(value && value.length > 0){
                                                            return value.map((item, j) => {
                                                                return <StyledRSuiteCheckPickerItem key={j} className="rs-picker-value-item">{item}</StyledRSuiteCheckPickerItem>
                                                            });
                                                        } else {
                                                            return selectedElement
                                                        }
                                                    }}
                                                    onChange={async (values, items) => {
                                                        await setFieldValue('customerCreditLimitTriggers.' + i + '.emailAddress', values);

                                                        this.setColors();
                                                    }}
                                                    onClear={async () => {
                                                        await setFieldValue('customerCreditLimitTriggers.' + i + '.emailAddress', []);

                                                        this.setColors();
                                                    }}
                                                />
                                            </StyledTbodyTrTd>
                                            <StyledTbodyTrTd islast={true} className={'text-center'}>
                                                <div className={'pt-1 ps-1 d-block d-md-none'}>&nbsp;</div>
                                                <StyledCancel
                                                    className={'material-icons'}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        
                                                        if(values.creditLimit){
                                                            if(values.customerCreditLimitTriggers.length > 1){
                                                                remove(i);
                                                            } else {
                                                                remove(i);
                                                                setTimeout(() => {
                                                                    push({
                                                                        percentOfCreditLimit: '',
                                                                        action: [CustomerCreditLimitAction.Email],
                                                                        emailAddress: [],
                                                                    });
                                                                }, 100);
                                                            }
                                                        }

                                                        this.setColors();
                                                    }}
                                                >cancel</StyledCancel>
                                            </StyledTbodyTrTd>
                                        </StyledTbodyTr>
                                    })
                                    :
                                    <StyledTbodyTr>
                                        <StyledTbodyTrTdEmpty colSpan={2} className={'text-center'}>No Credit Limit!</StyledTbodyTrTdEmpty>
                                        <StyledTbodyTrTdEmpty islast={true}>&nbsp;</StyledTbodyTrTdEmpty>
                                    </StyledTbodyTr>
                                }
                                </StyledTbody>
                                
                                {(errors.customerCreditLimitTriggers && typeof errors.customerCreditLimitTriggers === "string") && <StyledTfoot>
                                    <StyledTfootTr>
                                        <StyledTfootTrTd colSpan={3} className={'pt-0 mt-0'}>
                                            <Form.Control.Feedback type="invalid" className={'d-block'}>{errors.customerCreditLimitTriggers}</Form.Control.Feedback>
                                        </StyledTfootTrTd>
                                    </StyledTfootTr>
                                </StyledTfoot>}

                                {values.creditLimit && <StyledTfoot>
                                    <StyledTfootTr>
                                        <StyledTfootTrTd colSpan={3}>
                                            <a href={'/'} 
                                                className={'link-primary'}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    
                                                    let hasEmpty = Utils.hasEmptyRows(values.customerCreditLimitTriggers, (item, i) => {
                                                        return Utils.isEmptyRow(item, [ 'percentOfCreditLimit', 'emailAddress' ]);
                                                    });
                                                    
                                                    if(!hasEmpty){
                                                        validateForm().then((err) => {
                                                            if(!!err.customerCreditLimitTriggers){
                                                                Utils.toast('You must fill in the required fields', 'error');
                                                            } else {
                                                                push({
                                                                    percentOfCreditLimit: '',
                                                                    action: [CustomerCreditLimitAction.Email],
                                                                    emailAddress: [],
                                                                });
                                                            }
                                                        })
                                                    } else {
                                                        Utils.toast('You must fill in the required fields', 'error');
                                                    }

                                                    this.setColors();
                                                }}
                                            >+ Add Credit Limit</a>
                                        </StyledTfootTrTd>
                                    </StyledTfootTr>
                                </StyledTfoot>}
                            </StyledTable>
                        </StyledTableWrapper>
                    </>
                )}
            </FieldArray>

        </>
    }

    creditLimitEmail = (formOptions) => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = formOptions;
        
        return <>

            <InputLayout
                label={formFields.subject.label}
            >
                <Form.Control
                    type="text"
                    id={formFields.subject.id}
                    placeholder={formFields.subject.placeholder}
                    value={values.creditLimitEmailTemplate.subject}
                    onChange={async (e) => {
                      await setFieldValue('creditLimitEmailTemplate.subject', e.target.value);

                      this.setColors();
                    }}
                    onFocus={(e) => {
                        this.refLastSelectedInput = 'subject';
                    }}
                    isInvalid={!!(errors && errors.creditLimitEmailTemplate && errors.creditLimitEmailTemplate.subject)}
                />
                <Form.Control.Feedback type="invalid">{(errors && errors.creditLimitEmailTemplate && errors.creditLimitEmailTemplate.subject) && errors.creditLimitEmailTemplate.subject}</Form.Control.Feedback>
            </InputLayout>

            <InputLayout
                label={formFields.fromName.label}
            >
                <Form.Control
                    type="text"
                    id={formFields.fromName.id}
                    placeholder={formFields.fromName.placeholder}
                    value={values.creditLimitEmailTemplate.fromName}
                    onChange={async (e) => {
                      await setFieldValue('creditLimitEmailTemplate.fromName', e.target.value);

                      this.setColors();
                    }}
                    onFocus={(e) => {
                        this.refLastSelectedInput = 'fromName';
                    }}
                    isInvalid={!!(errors && errors.creditLimitEmailTemplate && errors.creditLimitEmailTemplate.fromName)}
                />
                <Form.Control.Feedback type="invalid">{(errors && errors.creditLimitEmailTemplate && errors.creditLimitEmailTemplate.fromName) && errors.creditLimitEmailTemplate.fromName}</Form.Control.Feedback>
            </InputLayout>

            {/* <InputLayout
                label={formFields.recipientCc.label}
            >
                <Form.Control
                    type="text"
                    id={formFields.recipientCc.id}
                    placeholder={formFields.recipientCc.placeholder}
                    value={values.creditLimitEmailTemplate.recipientCc}
                    onChange={async (e) => {
                      await setFieldValue('creditLimitEmailTemplate.recipientCc', e.target.value);

                                this.setColors();
                    }}
                    isInvalid={!!(errors && errors.creditLimitEmailTemplate && errors.creditLimitEmailTemplate.recipientCc)}
                />
                <Form.Control.Feedback type="invalid">{(errors && errors.creditLimitEmailTemplate && errors.creditLimitEmailTemplate.recipientCc) && errors.creditLimitEmailTemplate.recipientCc}</Form.Control.Feedback>
            </InputLayout> */}


            <InputLayout
                label={formFields.recipientCc.label}
            >
                <Form.Control
                    as = {StyledTagInput}
                    isInvalid={!!(errors && errors.creditLimitEmailTemplate && errors.creditLimitEmailTemplate.recipientCc)}

                    id={formFields.recipientCc.id}
                    placeholder={formFields.recipientCc.placeholder}
                    value={values.creditLimitEmailTemplate.recipientCc}
                    isDefault={true}
                    onChange={async (value) => {
                        let arr = [];
                        if(value && value.length > 0){
                            value.forEach(email => {
                                let pattern = Utils.emailValidation();
    
                                if(email.match(pattern) != null){
                                    arr.push(email);
                                } else {
                                    Utils.toast('You must enter a valid email address', 'error');
                                }
                            })
                        }

                        await setFieldValue('creditLimitEmailTemplate.recipientCc', arr);

                        this.setColors();
                    }}
                    onClean={async () => {
                        await setFieldValue('creditLimitEmailTemplate.recipientCc', []);

                        this.setColors();
                    }}
                />
                <Form.Control.Feedback type="invalid" style={{ display: ((errors && errors.creditLimitEmailTemplate && errors.creditLimitEmailTemplate.recipientCc && errors.creditLimitEmailTemplate.recipientCc.length > 0) ? 'block' : 'none') }}>{(errors && errors.creditLimitEmailTemplate && errors.creditLimitEmailTemplate.recipientCc) && errors.creditLimitEmailTemplate.recipientCc}</Form.Control.Feedback>
            </InputLayout>
            
            <InputLayout
                label={formFields.message.label}
            >
                <Form.Control
                    as = {RTextEditor}
                    isInvalid={!!(errors && errors.creditLimitEmailTemplate && errors.creditLimitEmailTemplate.message)}
                    id={formFields.message.id}

                    editorState={values.customFormParams.message}
                    placeholder={formFields.message.placeholder}
                    onChange={async (plainText, html, editorState) => {
                        await setFieldValue('creditLimitEmailTemplate.message', plainText);

                        await setFieldValue('customFormParams.messagePlainText', plainText);
                        await setFieldValue('customFormParams.messageHTML', html);
                        await setFieldValue('customFormParams.message', editorState);

                        this.setColors();
                    }}
                    onFocus={(e) => {
                        this.refLastSelectedInput = 'message';
                    }}
                />
                <Form.Control.Feedback type="invalid">{(errors && errors.creditLimitEmailTemplate && errors.creditLimitEmailTemplate.message) && errors.creditLimitEmailTemplate.message}</Form.Control.Feedback>
            </InputLayout>

        </>
    }

    creditLimitCustomField = (formOptions) => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = formOptions;

        return <Row className={'align-items-center mb-5 mt-0 mt-md-2 pt-10'}>
            <Col sm={12} className={'mb-5'}>Custom Fields</Col>
            {this.tagItems.map((tag, i) => {
                return <CopyToClipboard 
                    key={i} 
                    text={tag.value}
                    onCopy={async () => {
                        if(this.refLastSelectedInput == 'subject'){
                            let prevValue = values.creditLimitEmailTemplate.subject;
                            let plainText = prevValue + ' ' + tag.value;
    
                            await setFieldValue('creditLimitEmailTemplate.subject', plainText);

                        } else if(this.refLastSelectedInput == 'fromName'){
                            let prevValue = values.creditLimitEmailTemplate.fromName;
                            let plainText = prevValue + ' ' + tag.value;
    
                            await setFieldValue('creditLimitEmailTemplate.fromName', plainText);

                        } else if(this.refLastSelectedInput == 'creditLimitAlertMessage'){
                            let prevValue = values.creditLimitAlertMessage;
                            let plainText = prevValue + ' ' + tag.value;
    
                            await setFieldValue('creditLimitAlertMessage', plainText);

                        } else {
                            let messagePlainText = values.customFormParams.messagePlainText;
                            let plainText = messagePlainText + ' ' + tag.value;
                            let editorState = Utils.createEditorState(plainText);
    
                            await setFieldValue('creditLimitEmailTemplate.message', plainText);
                            await setFieldValue('customFormParams.messagePlainText', plainText);
                            await setFieldValue('customFormParams.messageHTML', plainText);
                            await setFieldValue('customFormParams.message', editorState);
                        }

                        this.setColors();
                    }}
                >
                    <Col sm={12} className={'cursor-pointer mb-5'}>
                        <Tooltip title="Copy Tag">
                            <div className={'d-flex align-items-center'} color={'#7F7F7F'}>
                                <i className={'material-icons'} style={{ color: '#7F7F7F', marginRight: '10px', fontSize: '18px' }}>content_copy</i>
                                {tag.title}
                            </div>
                        </Tooltip>
                    </Col>
                </CopyToClipboard>
            })}
        </Row>
    }

    creditLimitStopService = (formOptions) => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = formOptions;
        
        return <>

            <InputLayout
                label={formFields.alertAt.label}
            >
                <Form.Control
                    as = {CurrencyInput}
                    isInvalid={!!errors.alertAt}
                    
                    allowDecimals={false}
                    allowNegativeValue={false}
                    disableGroupSeparators={true}
                    suffix={'%'}

                    id={formFields.percentOfCreditLimit.id}
                    placeholder={formFields.percentOfCreditLimit.placeholder}
                    value={values.alertAt}
                    disabled={!(values.isStopDispatching)}
                    onValueChange={async (value) => {
                        let val = (value && value != '') ? value : '';
                        let pattern = Utils.onlyPositiveAndEmptyNumbers100();

                        if(val.match(pattern) != null){
                            await setFieldValue('alertAt', val);
                        }

                        this.setColors();
                    }}
                    onClick={(e) => {
                        e.target.select();
                    }}
                    onBlur={async (e) => {
                        let alertAt = Utils.isNumber(values.alertAt) ? values.alertAt : '';
                        
                        if(Utils.isNumber(alertAt) && ((alertAt < 1) || (alertAt > 100))){
                            if(alertAt < 1){
                                Utils.toast('Minimum credit limit is 1%', 'warning');
                            } else {
                                Utils.toast('Maximum credit limit is 100%', 'warning');
                            }
                            // await setFieldValue('alertAt', '');
                        }

                        this.setColors();
                    }}
                />
                {/* <Form.Control.Feedback type="invalid">{errors.alertAt}</Form.Control.Feedback> */}
            </InputLayout>

            <InputLayout
                label={formFields.creditLimitAlertMessage.label}
            >
                <Form.Control
                    type="text"
                    id={formFields.creditLimitAlertMessage.id}
                    placeholder={formFields.creditLimitAlertMessage.placeholder}
                    value={values.creditLimitAlertMessage}
                    onChange={async (e) => {
                        await setFieldValue('creditLimitAlertMessage', e.target.value);

                        this.setColors();
                    }}
                    isInvalid={!!errors.creditLimitAlertMessage}
                    onFocus={(e) => {
                        this.refLastSelectedInput = 'creditLimitAlertMessage';
                    }}
                />
                {/* <Form.Control.Feedback type="invalid">{errors.creditLimitAlertMessage}</Form.Control.Feedback> */}
            </InputLayout>
            
            <InputLayout
                label={formFields.isStopDispatching.label}
            >
                <Form.Check
                    type={'checkbox'}
                    id={'stop_dispatching'}
                    checked={values.isStopDispatching}
                    onChange={async (e) => {
                        await setFieldValue('isStopDispatching', e.target.checked);

                        if(!e.target.checked){
                            await setFieldValue('alertAt', '');
                        }

                        this.setColors();
                    }}
                />
            </InputLayout>

        </>
    }
    /* END FORM */


    render() {
        return <>
            <Formik
                innerRef={this.refForm}
                validationSchema={formSchema}
                initialValues={this.state.initialValues}
                enableReinitialize={true}
                validateOnMount={false}
                validateOnChange={true}
                validate={(values) => {
                    setTimeout(() => {
                        if(this.refForm && this.refForm.current && this.refForm.current.errors){
                            if(!_.isEmpty(this.refForm.current.errors)){
                                // Utils.toast('You must fill in the required fields', 'error');
                        
                                this.setState({
                                    isOpen1: true,
                                    isOpen2: true,
                                }, () => {
                                    // Utils.scrollToInvalidField();
                                });
                            }
                        }
                    }, 200);
                }}
                onSubmit={(form, e) => {
                    this.setSubmit(form);
                }}
            >
                {(formOptions) => {
                    return <Form className='w-100 p-5' noValidate autoComplete="off" onSubmit={formOptions.handleSubmit} onKeyDown={(keyEvent) => {
                        // if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                        //     keyEvent.preventDefault();
                        // }
                    }}>

                        <Row>
                            <Col xs={12} className={'ps-2 pe-2 ps-md-7 pe-md-7 mt-1 mb-1'}>{this.creditLimitField(formOptions)}</Col>
                        </Row>
                        
                        <Row>
                            <Col xs={12} className={'ps-2 pe-2 ps-md-7 pe-md-7'}><hr style={{ opacity: '1', margin: '0', borderColor: '#EEEEEE' }} /></Col>
                        </Row>

                        <Accordion
                            title={<h3 className={'ps-2 pe-2 ps-md-7 pe-md-7 pt-8 pb-10 m-0'}>
                                <i style={{ color: this.state.color1, position: 'relative', top: '6px', paddingRight: '10px' }} className={'material-icons'}>check_circle</i>
                                Email Your Customers, Yourself Or Team Members
                            </h3>}
                            isOpen={this.state.isOpen1}
                            onChange={(isOpen) => {
                                this.setState({
                                    isOpen1: isOpen
                                });
                            }}
                            body={<Row className={'ps-2 pe-2 ps-md-7 pe-md-7'}>
                                <Col xs={12} md={11} lg={9} className={'ps-2 pe-2 ps-md-7 pe-md-7'}>{this.creditLimitTable(formOptions)}</Col>
                                <Col xs={12} md={10} lg={8} className={'ps-2 pe-2 ps-md-7 pe-md-7'}>{this.creditLimitEmail(formOptions)}</Col>
                                <Col xs={12} md={2} lg={2}>{this.creditLimitCustomField(formOptions)}</Col>
                                <Col xs={12} className={'ps-2 pe-2 ps-md-7 pe-md-7'}><hr style={{ opacity: '1', margin: '0', borderColor: '#EEEEEE' }} /></Col>
                            </Row>}
                        />
                        
                        <Row>
                            <Col xs={12} className={'ps-2 pe-2 ps-md-7 pe-md-7'}><hr style={{ opacity: '1', margin: '0', borderColor: '#EEEEEE' }} /></Col>
                        </Row>
                        
                        <Accordion
                            title={<h3 className={'ps-2 pe-2 ps-md-7 pe-md-7 pt-8 pb-10 m-0'}>
                                <i style={{ color: this.state.color2, position: 'relative', top: '6px', paddingRight: '10px' }} className={'material-icons'}>check_circle</i>
                                Alert Your Team Members On Job Form
                            </h3>}
                            isOpen={this.state.isOpen2}
                            onChange={(isOpen) => {
                                this.setState({
                                    isOpen2: isOpen
                                });
                            }}
                            body={<Row className={'ps-2 pe-2 ps-md-7 pe-md-7'}>
                                <Col xs={12} md={10} lg={8} className={'ps-2 pe-2 ps-md-7 pe-md-7'}>{this.creditLimitStopService(formOptions)}</Col>
                            </Row>}
                        />

                    </Form>
                }}
            </Formik>
        </>
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  dispatchApiCallPost,
  dispatchApiCallPut,
  dispatchApiCallDelete,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CreditLimitForm);
