import axios from 'axios';
import qs from 'query-string';


const { 
    REACT_APP_DISPATCH_API_URL,
    REACT_APP_QUICKBOOK_CONNECT_API_URL,
    REACT_APP_QUICKBOOK_SANDBOX_CLIENT_ID,
    REACT_APP_QUICKBOOK_REDIRECT_URL
} = process.env;


const API = "quickbookapp";

const quickbookApi = {

    getConnectUrl: (id = '', scope = '', responseType = '', environment = '', redirectUrl = '') => {
        let url = REACT_APP_QUICKBOOK_CONNECT_API_URL;

        let quickbookScope = (scope !== '') ? scope : "com.intuit.quickbooks.accounting";
        let quickbookResponseType = (responseType !== '') ? responseType : "code";
        let quickbookEnvironment = (environment !== '') ? environment : "sandbox";
        let quickbookRedirectUrl = (redirectUrl !== '') ? redirectUrl : REACT_APP_QUICKBOOK_REDIRECT_URL;

        var data = {
            client_id: REACT_APP_QUICKBOOK_SANDBOX_CLIENT_ID,
            scope: quickbookScope,
            redirect_uri: quickbookRedirectUrl,
            response_type: quickbookResponseType,
            environment: quickbookEnvironment,
            state: JSON.stringify({
                id: id,
                clientId: REACT_APP_QUICKBOOK_SANDBOX_CLIENT_ID
            })
        }
        
        return url + "?" + qs.stringify(data);
    },

    disconnect: async (data, token) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_DISPATCH_API_URL + API + '/deactivate';

        const getResult = await axios.put(
            URL,
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            })
            .catch( error => {
                try {
                    status = error.response.status;

                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch(err) {
                    errorMessage = 'Service Unavailable!'
                }
            });
        return { data: getResult, errorMessage: errorMessage, status: status};
    },

    readTaxCode: async (data, token) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_DISPATCH_API_URL + API + '/tax-code';
        
        const getResult = await axios.get(
            URL,
            { 
                params: data,
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            })
            .catch( error => {
                try {
                    status = error.response.status;

                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch(err) {
                    errorMessage = 'Service Unavailable!'
                }
            });
            
        return { data: getResult, errorMessage: errorMessage, status: status};
    },
    
    readCreditTerms: async (data, token) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_DISPATCH_API_URL + API + '/term';
        
        const getResult = await axios.get(
            URL,
            { 
                params: data,
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            })
            .catch( error => {
                try {
                    status = error.response.status;

                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch(err) {
                    errorMessage = 'Service Unavailable!'
                }
            });
            
        return { data: getResult, errorMessage: errorMessage, status: status};
    },

    update: async (data, token) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_DISPATCH_API_URL + API;

        const getResult = await axios.put(
            URL,
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            })
            .catch( error => {
                try {
                    status = error.response.status;

                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch(err) {
                    errorMessage = 'Service Unavailable!'
                }
            });
        return { data: getResult, errorMessage: errorMessage, status: status};
    },

    updateTaxCode: async (data, token) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_DISPATCH_API_URL + API;

        const getResult = await axios.put(
            URL,
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            })
            .catch( error => {
                try {
                    status = error.response.status;

                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch(err) {
                    errorMessage = 'Service Unavailable!'
                }
            });
        return { data: getResult, errorMessage: errorMessage, status: status};
    },

    readItems: async (data, token) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_DISPATCH_API_URL + API + '/items';
        
        const getResult = await axios.get(
            URL,
            { 
                params: data,
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            })
            .catch( error => {
                try {
                    status = error.response.status;

                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch(err) {
                    errorMessage = 'Service Unavailable!'
                }
            });
            
        return { data: getResult, errorMessage: errorMessage, status: status};
    },

    read: async (data, token) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_DISPATCH_API_URL + API;
        
        const getResult = await axios.get(
            URL,
            { 
                params: data,
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            })
            .catch( error => {
                try {
                    status = error.response.status;

                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch(err) {
                    errorMessage = 'Service Unavailable!'
                }
            });
            
        return { data: getResult, errorMessage: errorMessage, status: status};
    },

    create: async (data, token) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_DISPATCH_API_URL + API;

        const getResult = await axios.post(
            URL,
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            })
            .catch( error => {
                try {
                    status = error.response.status;

                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch(err) {
                    errorMessage = 'Service Unavailable!'
                }
            });
        return { data: getResult, errorMessage: errorMessage, status: status};
    },
}

export default quickbookApi;