import React, { useEffect, useState } from 'react';

import Utils from '../../../../utils/utils';

import { 
    Container,
    Row,
    Col,
    Form,
} from "react-bootstrap-v5";
import styled from 'styled-components';


const StyledIconLeft = styled.i`
    width: 12px;
    height: 22px;
    justify-content: center;
    align-items: center;
    display: flex;
    user-select: none;

    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg);

    &:hover {
        color: var(--custom-color-primary);
        cursor: pointer;
    }
`;

const StyledIconRight = styled.i`
    width: 12px;
    height: 22px;
    justify-content: center;
    align-items: center;
    display: flex;
    user-select: none;

    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);

    &:hover {
        color: var(--custom-color-primary);
        cursor: pointer;
    }
`;


const DataTableAjaxPaginationV5 = (props) => {
    const [pageCount, setPageCount] = useState((props && props.count) ? props.count : 0);
    const [selected, setSelected] = useState((props && props.selected) ? props.selected : 50);
    const [currentPage, setCurrentPage] = useState((props && props.page) ? props.page : 1);
    const [options, setOptions] = useState((props && props.options) ? props.options : [50, 100, 300]);
    

    useEffect(() => {
        setPageCount((props && props.count) ? props.count : 0);
        setSelected((props && props.selected) ? props.selected : 50);
        setCurrentPage((props && props.page) ? props.page : 1);
        setOptions((props && props.options) ? props.options : [50, 100, 300]);
    }, [props.count, props.page, props.selected, props.options]);


    const getSelect = () => {
        return <Form.Select
            value={selected}
            onChange={(e) => {
                props.onOptionsChange(e.target.value);
            }}
        >
            {options && options.map((x, i) => <option key={i} value={x}>{x + ' Per Page'}</option>)}
        </Form.Select>
    }

    const getPrev = () => {
        return <StyledIconLeft 
            className={'material-icons'}
            onClick={() => {
                let prevPage = (currentPage > 1) ? (currentPage-1) : currentPage;
                props.onPageChange(prevPage);
            }}
        >arrow_drop_up</StyledIconLeft>
    }

    const getPages = () => {
        let obj = Utils.getPaginationFromTo(pageCount, selected, currentPage);
        return (pageCount > 0) ? obj.from + ' - ' + obj.to : '0 - 0';
    }

    const getNext = () => {
        return <StyledIconRight 
            className={'material-icons'}
            onClick={() => {
                let nextPage = ((currentPage * selected) < pageCount) ? (currentPage+1) : currentPage;
                props.onPageChange(nextPage);
            }}
        >arrow_drop_up</StyledIconRight>
    }


    return <div 
        className={'bg-white ' + (props.fixed ? 'pagination-fixed' : '')}
        style={(props.style ? props.style : null)}
    >
        <Container>
            <Row className={'g-2 align-items-center'}>
                <Col xs={true}>Total: {pageCount}</Col>
                <Col xs={'auto'}>{getSelect()}</Col>
                <Col xs={'auto'}>{getPrev()}</Col>
                <Col xs={'auto'}>{getPages()}</Col>
                <Col xs={'auto'}>{getNext()}</Col>
            </Row>
        </Container>
    </div>
  }

  export default DataTableAjaxPaginationV5;