import React from "react";

import { 
    Row,
    Col,
    Form,
    Collapse,
} from "react-bootstrap-v5";

import Tooltip from '../../../components/Tooltip/Tooltip';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import RTextEditor from '../../../components/OLD/RichTextEditor/RTextEditor.jsx';
import RSuiteTagInput from '../../../components/OLD/TagsInput/RSuiteTagInput';

import Utils from "../../../utils/utils";

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet } from '../../../../setup/redux/dispatch/actions'


// const Accordion = (props) => {
//     const [isOpen, setIsOpen] = React.useState(((props.isOpen === false) || (props.isOpen === true)) ? props.isOpen : false);

//     React.useEffect(() => {
//         setIsOpen(((props.isOpen === false) || (props.isOpen === true)) ? props.isOpen : false);
//     }, [props.isOpen]);

//     return <>
//         <Row>
//             <Col xs={12} md={3} xl={2}>&nbsp;</Col>
//             <Col xs={'auto'}
//                 className={'cursor-pointer'}
//                 onClick={() => {
//                     if(props.onChange){
//                         props.onChange(!isOpen);
//                     }
//                 }}
//             >
//                 <span className={'mb-0'}>{props.title}</span>
//             </Col>
//             <Col xs={'auto'}
//                 className={'cursor-pointer'}
//                 onClick={() => {
//                     if(props.onChange){
//                         props.onChange(!isOpen);
//                     }
//                 }}
//             >
//                 <i className={'material-icons'}>{isOpen ? 'expand_more' : 'expand_less'}</i>
//             </Col>
//         </Row>
//         <Row>
//             <Col xs={12} md={3} xl={2}>&nbsp;</Col>
//             <Col xs={12} md={true}>
//                 <Collapse in={isOpen}>
//                     <Row>
//                         <Col xs={12}>{props.children}</Col>
//                     </Row>
//                 </Collapse>
//             </Col>
//         </Row>
//     </>
// }


class EmailSettingsForm extends React.Component {
  
    constructor(props) {
        super(props);
    
        this.refLastSelectedInput = React.createRef();

        this.tags = [
            {
                value: '[[CustomerName]]',
                title: 'Customer Name',
            },
            {
                value: '[[JobNumber]]',
                title: 'Job Number',
            },
            {
                value: '[[JobCompletionDate]]',
                title: 'Job Completion Date',
            },
        ];

        this.state = {
            isClickedCustomFields: false,
        };
    }


    body = () => {
        let {
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            values,
            errors,
        } = this.props.formOptions;
        
        return <>
            <Form.Group as={Row} className={'mb-5'}>
                <Form.Label column xs={12} md={3} xl={2}>{this.props.fields.emailSettingsSubject.label}</Form.Label>
                <Col xs={12} md={true}>
                    <Form.Control
                        type="text"
                        id={this.props.fields.emailSettingsSubject.id}
                        placeholder={this.props.fields.emailSettingsSubject.placeholder}
                        value={values.emailSettingsSubject}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.emailSettingsSubject}
                        onFocus={(e) => {
                            this.refLastSelectedInput = 'subject';
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.emailSettingsSubject}</Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className={'mb-5'}>
                <Form.Label column xs={12} md={3} xl={2}>{this.props.fields.emailSettingsFrom.label}</Form.Label>
                <Col xs={12} md={true}>
                    <Form.Control
                        type="text"
                        id={this.props.fields.emailSettingsFrom.id}
                        placeholder={this.props.fields.emailSettingsFrom.placeholder}
                        value={values.emailSettingsFrom}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.emailSettingsFrom}
                        onFocus={(e) => {
                            this.refLastSelectedInput = 'fromName';
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.emailSettingsFrom}</Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className={'mb-5'}>
                <Form.Label column xs={12} md={3} xl={2}>{this.props.fields.emailSettingsCc.label}</Form.Label>
                <Col xs={12} md={true}>
                    {/* <Form.Control
                        type="text"
                        id={this.props.fields.emailSettingsCc.id}
                        placeholder={this.props.fields.emailSettingsCc.placeholder}
                        value={values.emailSettingsCc}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.emailSettingsCc}
                    />
                    <Form.Control.Feedback type="invalid">{errors.emailSettingsCc}</Form.Control.Feedback> */}

                    <Form.Group className={'mb-5'}>
                        <Form.Control
                            as = {RSuiteTagInput}
                            isInvalid={!!errors.emailSettingsCc}

                            id={this.props.fields.emailSettingsCc.id}
                            placeholder={this.props.fields.emailSettingsCc.placeholder}
                            value={values.emailSettingsCc}
                            isDefault={true}
                            onChange={async (value) => {
                                let arr = [];
                                if(value && value.length > 0){
                                    value.forEach(email => {
                                        let pattern = Utils.emailValidation();
            
                                        if(email.match(pattern) != null){
                                            arr.push(email);
                                        } else {
                                            Utils.toast('You must enter a valid email address', 'error');
                                        }
                                    })
                                }

                                await setFieldValue('emailSettingsCc', arr);
                            }}
                            onClean={async () => {
                                await setFieldValue('emailSettingsCc', []);
                            }}
                        />
                        <Form.Control.Feedback type="invalid">{errors.emailSettingsCc}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className={'mb-5'}>
                <Form.Label column xs={12} md={3} xl={2}>{this.props.fields.emailSettingsMessagePlainText.label}</Form.Label>
                <Col xs={12} md={true}>
                    <Form.Control
                        as = {RTextEditor}
                        isInvalid={!!errors.emailSettingsMessagePlainText}
                        id={this.props.fields.emailSettingsMessagePlainText.id}

                        editorState={values.emailSettingsMessage}
                        placeholder={this.props.fields.emailSettingsMessagePlainText.placeholder}
                        onChange={async (plainText, html, editorState) => {
                            await setFieldValue('emailSettingsMessagePlainText', plainText);
                            await setFieldValue('emailSettingsMessageHTML', html);
                            await setFieldValue('emailSettingsMessage', editorState);
                            await setFieldTouched('emailSettingsMessage', true);
                        }}
                        onFocus={(e) => {
                            this.refLastSelectedInput = 'message';
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.emailSettingsMessagePlainText}</Form.Control.Feedback>
                </Col>
            </Form.Group>

            {/* <Accordion
                title={'Add Custom fields'}
                isOpen={this.state.isClickedCustomFields}
                onChange={(isOpen) => {
                    this.setState({
                        isClickedCustomFields: !this.state.isClickedCustomFields,
                    });
                }}
            >
                <Row className={'align-items-center mb-5 mt-5'}>
                    {this.tags.map((tag, i) => {
                        return <CopyToClipboard 
                            key={i} 
                            text={tag}
                            onCopy={() => {
                                this.showToast(tag);
                            }}
                        >
                            <Col sm={'auto'} className={'cursor-pointer mb-5'}>
                                <Tooltip title="Copy Tag">
                                    <div className={'d-flex align-items-center'} color={'#7F7F7F'} pr={3}>
                                        {tag}
                                        <i className={'material-icons'} style={{ color: '#7F7F7F', marginLeft: '10px', fontSize: '18px' }}>content_copy</i>
                                    </div>
                                </Tooltip>
                            </Col>
                        </CopyToClipboard>
                    })}
                </Row>
            </Accordion> */}
            
            <Form.Group as={Row} className={'mb-5'}>
                <Form.Label column xs={12} md={3} xl={2}>Include</Form.Label>
                <Col xs={12} md={true} className={'d-flex align-items-center'}>
                    <Form.Check
                        id={this.props.fields.emailSettingsDeliveryOrder.id}
                        label={this.props.fields.emailSettingsDeliveryOrder.label}
                        checked={values.emailSettingsDeliveryOrder}
                        onChange={async (e) => {
                            await setFieldValue('emailSettingsDeliveryOrder', e.target.checked);
                            if(!e.target.checked){
                                await setFieldValue('emailSettingsIncludePhotos', false);
                            }
                        }}
                        isInvalid={!!errors.emailSettingsDeliveryOrder}
                        feedback={errors.emailSettingsDeliveryOrder}
                    />
                </Col>
                <Col xs={12} md={true} className={'d-flex align-items-center'}>
                    <Form.Check
                        id={this.props.fields.emailSettingsIncludePhotos.id}
                        label={this.props.fields.emailSettingsIncludePhotos.label}
                        disabled={!values.emailSettingsDeliveryOrder}
                        checked={values.emailSettingsIncludePhotos}
                        onChange={async (e) => {
                            await setFieldValue('emailSettingsIncludePhotos', e.target.checked);
                        }}
                        isInvalid={!!errors.emailSettingsIncludePhotos}
                        feedback={errors.emailSettingsIncludePhotos}
                    />
                </Col>
                <Col xs={12} md={true} className={'d-flex align-items-center'}>
                    <Form.Check
                        id={this.props.fields.emailSettingsWeighChit.id}
                        label={this.props.fields.emailSettingsWeighChit.label}
                        disabled={this.state.isLoading}
                        checked={values.emailSettingsWeighChit}
                        onChange={async (e) => {
                            await setFieldValue('emailSettingsWeighChit', e.target.checked);
                        }}
                        isInvalid={!!errors.emailSettingsWeighChit}
                        feedback={errors.emailSettingsWeighChit}
                    />
                </Col>
            </Form.Group>
        </>
    }

    creditLimitCustomField = () => {
        let {
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            values,
            errors,
        } = this.props.formOptions;

        return <Row className={'align-items-center mb-5'}>
            {this.tags.map((tag, i) => {
                return <CopyToClipboard 
                    key={i} 
                    text={tag}
                    onCopy={async () => {
                        if(this.refLastSelectedInput == 'subject'){
                            let prevValue = values.emailSettingsSubject;
                            let plainText = prevValue + ' ' + tag.value;
    
                            await setFieldValue('emailSettingsSubject', plainText);

                        } else if(this.refLastSelectedInput == 'fromName'){
                            let prevValue = values.emailSettingsFrom;
                            let plainText = prevValue + ' ' + tag.value;
    
                            await setFieldValue('emailSettingsFrom', plainText);

                        } else {
                            let messagePlainText = values.emailSettingsMessagePlainText;
                            let plainText = messagePlainText + ' ' + tag.value;
                            let editorState = Utils.createEditorState(plainText);
    
                            await setFieldValue('emailSettingsMessagePlainText', plainText);
                            await setFieldValue('emailSettingsMessageHTML', plainText);
                            await setFieldValue('emailSettingsMessage', editorState);
                        }
                    }}
                >
                    <Col sm={'auto'} lg={12} className={'cursor-pointer mb-5'}>
                        <Tooltip title="Copy Tag">
                            <div className={'d-flex align-items-center'} color={'#7F7F7F'} pr={3}>
                                {tag.title}
                                <i className={'material-icons'} style={{ color: '#7F7F7F', marginLeft: '10px', fontSize: '18px' }}>content_copy</i>
                            </div>
                        </Tooltip>
                    </Col>
                </CopyToClipboard>
            })}
        </Row>
    }


    showToast = (tag) => {
        Utils.toast(<div>
            <div className={'fw-bold pb-2'}>Tag {tag}</div>
            <div>Tag was copied to the clipboard</div>
        </div>, 'info');
    }

    render() {
        return <Row>
            <Col xs={12} lg={9} xl={8}>{this.body()}</Col>
            <Col xs={12} lg={3} xl={4}>{this.creditLimitCustomField()}</Col>
        </Row>
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(EmailSettingsForm);
