import { createSlice } from "@reduxjs/toolkit";

import Utils from "../../../app/utils/utils";
import { PageCookieSettingType } from "../../../app/utils/enums";


function NewReducer() {
  const name = 'realTimeUpdate';


  const initialState = {
    title: 'Set page refresh rate',

    items: [ 
      {
        value: 0,
        title: 'Real-time',
        ms: 0,
      },
      {
        value: 1,
        title: 'Every 1 minute',
        ms: 60000,
      },
      {
        value: 5,
        title: 'Every 5 minute',
        ms: 300000,
      }
    ],

    index: 0,
    value: 0,
    ms: 0,
    selected: 'Real-time',

    nextRefresh: null,
    timeLeft: null,
    isPaused: false,
  };


  const reducers = {
    getFilters: (state, action) => {
      const auth = (action.payload.auth) ? action.payload.auth : null;
      const settingsType = (action.payload.settingsType) ? action.payload.settingsType : null;
      const pageName = (action.payload.pageName) ? action.payload.pageName : null;

      let settingsItem = null;
      if(settingsType){
        const { userCookieSettings } = auth;
        settingsItem = Utils.getUserCookieSettings(userCookieSettings, settingsType, pageName);
      }

      let index = settingsItem && settingsItem[PageCookieSettingType.realTimeUpdate] && settingsItem[PageCookieSettingType.realTimeUpdate] !== "" ? settingsItem[PageCookieSettingType.realTimeUpdate] : 0;
      
      state.index = index;
      state.value = state.items[index].value;
      state.ms = state.items[index].ms;
      state.selected = state.items[index].title;

      state.nextRefresh = (state.ms > 0) ? new Date(new Date().getTime() + state.ms) : null;
      state.timeLeft = (state.nextRefresh > 0) ? Math.floor((state.nextRefresh - Date.now()) / 1000) : null;
    },

    up: (state, action) => {
      let max = (state.items && state.items.length > 0) ? (state.items.length - 1) : 0;
      
      if(state.index == max){
        state.index = 0;
      } else {
        state.index += 1;
      }

      state.timeLeft = null;
      state.value = state.items[state.index].value;
      state.ms = state.items[state.index].ms;
      state.selected = state.items[state.index].title;

      state.nextRefresh = (state.ms > 0) ? new Date(new Date().getTime() + state.ms) : null;
      state.timeLeft = (state.nextRefresh > 0) ? Math.floor((state.nextRefresh - Date.now()) / 1000) : null;


      const auth = (action.payload.auth) ? action.payload.auth : null;
      const settingsType = (action.payload.settingsType) ? action.payload.settingsType : null;
      const pageName = (action.payload.pageName) ? action.payload.pageName : null;

      let data = {
        name: pageName,
        type: settingsType,
      };

      if(settingsType){
        let { token, userCookieSettings } = auth;
  
        let settings = null;
        let settingsArr = (userCookieSettings && userCookieSettings.length > 0) ? userCookieSettings : [];
        let settingsIndex = Utils.getUserCookieSettingsIndex(settingsArr, settingsType);
        if(settingsIndex !== -1 && settingsArr[settingsIndex]){
          settings = settingsArr[settingsIndex];
        }

        if(settings){
          let oldValue = JSON.parse(settings.value);
          let value = Object.assign({}, oldValue);
          value[PageCookieSettingType.realTimeUpdate] = state.index;
          data['value'] = JSON.stringify(value);
        }

        Utils.saveUserCookieSettings(data, token);
      }
    },
    down: (state, action) => {
      let max = (state.items && state.items.length > 0) ? (state.items.length - 1) : 0;
      
      if(state.index == 0){
        state.index = max;
      } else {
        state.index -= 1;
      }

      state.timeLeft = null;
      state.value = state.items[state.index].value;
      state.ms = state.items[state.index].ms;
      state.selected = state.items[state.index].title;

      state.nextRefresh = (state.ms > 0) ? new Date(new Date().getTime() + state.ms) : null;
      state.timeLeft = (state.nextRefresh > 0) ? Math.floor((state.nextRefresh - Date.now()) / 1000) : null;


      const auth = (action.payload.auth) ? action.payload.auth : null;
      const settingsType = (action.payload.settingsType) ? action.payload.settingsType : null;
      const pageName = (action.payload.pageName) ? action.payload.pageName : null;

      let data = {
        name: pageName,
        type: settingsType,
      };

      if(settingsType){
        let { token, userCookieSettings } = auth;
  
        let settings = null;
        let settingsArr = (userCookieSettings && userCookieSettings.length > 0) ? userCookieSettings : [];
        let settingsIndex = Utils.getUserCookieSettingsIndex(settingsArr, settingsType);
        if(settingsIndex !== -1 && settingsArr[settingsIndex]){
          settings = settingsArr[settingsIndex];
        }

        if(settings){
          let oldValue = JSON.parse(settings.value);
          let value = Object.assign({}, oldValue);
          value[PageCookieSettingType.realTimeUpdate] = state.index;
          data['value'] = JSON.stringify(value);
        }

        Utils.saveUserCookieSettings(data, token);
      }
    },

    updateTimeLeft: (state, action) => {
      if(!state.isPaused){
        state.timeLeft = (state.nextRefresh > 0) ? Math.floor((state.nextRefresh - Date.now()) / 1000) : null;
      }
    },
    resetTimeLeft: (state, action) => {
      state.nextRefresh = (state.ms > 0) ? new Date(new Date().getTime() + state.ms) : null;
      state.timeLeft = (state.nextRefresh > 0) ? Math.floor((state.nextRefresh - Date.now()) / 1000) : null;
    },
    pause: (state) => {
      state.isPaused = true;
    },
    play: (state) => {
      state.isPaused = false;
      state.nextRefresh = (state.ms > 0) ? new Date(new Date().getTime() + state.ms) : null;
      state.timeLeft = (state.nextRefresh > 0) ? Math.floor((state.nextRefresh - Date.now()) / 1000) : null;
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
  };
}


export default NewReducer();