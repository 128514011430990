const name = 'common_profile';

export const COMMON_PROFILE_POST = 'api_call_' + name + '_post';
export const COMMON_PROFILE_GET = 'api_call_' + name + '_get';
export const COMMON_PROFILE_PUT = 'api_call_' + name + '_put';
export const COMMON_PROFILE_DELETE = 'api_call_' + name + '_delete';

export const commonProfileApiCallPost = (data = null, name = '', path = '', variables = null, callback = null, callbackError500 = null, cancelRequestToken = null) => ({ type: COMMON_PROFILE_POST, payload: { isResponse: false,  name: name, path: path, isLoading: true, params: data, data: null, err: null, isError: false, statusCode: null, variables: variables, callback: callback, callbackError500: callbackError500, cancelRequestToken: cancelRequestToken } })
export const commonProfileApiCallGet = (data = null, name = '', path = '', variables = null, callback = null, callbackError500 = null, cancelRequestToken = null) => ({ type: COMMON_PROFILE_GET, payload: { isResponse: false,  name: name, path: path, isLoading: true, params: data, data: null, err: null, isError: false, statusCode: null, variables: variables, callback: callback, callbackError500: callbackError500, cancelRequestToken: cancelRequestToken } })
export const commonProfileApiCallPut = (data = null, name = '', path = '', variables = null, callback = null, callbackError500 = null, cancelRequestToken = null) => ({ type: COMMON_PROFILE_PUT, payload: { isResponse: false,  name: name, path: path, isLoading: true, params: data, data: null, err: null, isError: false, statusCode: null, variables: variables, callback: callback, callbackError500: callbackError500, cancelRequestToken: cancelRequestToken } })
export const commonProfileApiCallDelete = (data = null, name = '', path = '', variables = null, callback = null, callbackError500 = null, cancelRequestToken = null) => ({ type: COMMON_PROFILE_DELETE, payload: { isResponse: false,  name: name, path: path, isLoading: true, params: data, data: null, err: null, isError: false, statusCode: null, variables: variables, callback: callback, callbackError500: callbackError500, cancelRequestToken: cancelRequestToken } })
