import axios from 'axios'

const { 
  REACT_APP_COMMON_PROFILE_API_URL,
  REACT_APP_DISPATCH_API_URL
} = process.env;

const PATH = 'Account/';

// Server should return AuthModel
export function login(email, password) {
  return axios.post(REACT_APP_COMMON_PROFILE_API_URL + PATH + 'login', {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(email) {
  return axios.post(REACT_APP_COMMON_PROFILE_API_URL + PATH + 'register', {
    email
  })
}

export function forgotPassword(email) {
  return axios.post(REACT_APP_COMMON_PROFILE_API_URL + PATH + 'forgot-password', {
    email
  })
}

export function changePassword(email, password, confirmpassword, code) {
  return axios.post(REACT_APP_COMMON_PROFILE_API_URL + PATH + 'change-password', {
    // email,
    password,
    confirmpassword,
    code: code,
  })
}

export function authenticateToken(token) {
  return axios.get(REACT_APP_COMMON_PROFILE_API_URL + PATH + 'authenticate-token')
}

export function userCookieSettingsApi(data) {
  return axios.get(REACT_APP_COMMON_PROFILE_API_URL + 'usercookiesetting', { params: data })
}

export function getAccountPermissions() {
  return axios.get(REACT_APP_COMMON_PROFILE_API_URL + PATH + 'get-permissions')
}


export function dispatchAuthenticateToken(token) {
  return axios.get(REACT_APP_DISPATCH_API_URL + PATH + 'authenticate-token')
}

export function dispatchMaintenance() {
  return axios.get(REACT_APP_DISPATCH_API_URL + 'setting/type/0')
}