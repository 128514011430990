/* eslint-disable no-loop-func */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import * as dispatchCrudApi from '../../../app/api/CRUD/DispatchCRUD'
import Utils from "../../../app/utils/utils";
import { BillingTypes } from "../../../app/utils/enums";
import { ParseResult } from "../../../app/utils/interfaces";


interface InitState {
  isLoading: boolean,

  accountBillingProfileId: number|null,
  invoiceTaxRate: number|null,
  purchaseTaxRate: number|null,

  category: Array<any>,
}


function NewReducer() {
  const name = 'taxRate';


  const initialState: InitState = {
    isLoading: false,

    accountBillingProfileId: null,
    invoiceTaxRate: null,
    purchaseTaxRate: null,
    
    category: [],
  };


  const reducers = {
    setMainTaxRate: (state: InitState, action: PayloadAction<{ invoiceTaxRate: number|null, purchaseTaxRate: number|null }>) => {
      let invoiceTaxRate = (Utils.isNumber(action.payload.invoiceTaxRate)) ? action.payload.invoiceTaxRate : null;
      let purchaseTaxRate = (Utils.isNumber(action.payload.purchaseTaxRate)) ? action.payload.purchaseTaxRate : null;

      state.invoiceTaxRate = invoiceTaxRate;
      state.purchaseTaxRate = purchaseTaxRate;
    },
    setTaxRate: (state: InitState, action: PayloadAction<{ index: number|null, invoiceTaxRate: number|null, purchaseTaxRate: number|null }>) => {
      if(action.payload.index){
        let invoiceTaxRate = (Utils.isNumber(action.payload.invoiceTaxRate)) ? action.payload.invoiceTaxRate : null;
        let purchaseTaxRate = (Utils.isNumber(action.payload.purchaseTaxRate)) ? action.payload.purchaseTaxRate : null;

        let item: any = {
          index: action.payload.index,
          invoiceTaxRate: invoiceTaxRate,
          purchaseTaxRate: purchaseTaxRate,
        };
  
        let category = state.category;
        category = Utils.addToArrayWithField(category, action.payload.index, 'index', item);
        state.category = category;
      }
    },

    startReadDefaultProfile: (state: InitState) => {
      state.category = [];

      state.accountBillingProfileId = null;
      state.invoiceTaxRate = null;
      state.purchaseTaxRate = null;

      state.isLoading = true;
    },
    finishReadDefaultProfile: (state: InitState, action: PayloadAction<{ data: any, errorMessage: string|null, isError: boolean }>) => {
      let item = (action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.length > 0) ? action.payload.data.data[0] : null;
      
      let accountBillingProfileId = (item && Utils.isNumber(item.accountBillingProfileId)) ? item.accountBillingProfileId : null;
      let invoiceTaxRate = (item && Utils.isNumber(item.invoiceTaxRate)) ? item.invoiceTaxRate : null;
      let purchaseTaxRate = (item && Utils.isNumber(item.purchaseTaxRate)) ? item.purchaseTaxRate : null;
      
      state.accountBillingProfileId = accountBillingProfileId;
      state.invoiceTaxRate = invoiceTaxRate;
      state.purchaseTaxRate = purchaseTaxRate;

      state.isLoading = false;
    },
    
    startReadCustomerProfile: (state: InitState) => {
      state.category = [];

      state.accountBillingProfileId = null;
      state.invoiceTaxRate = null;
      state.purchaseTaxRate = null;
      
      state.isLoading = true;
    },
    finishReadCustomerProfile: (state: InitState, action: PayloadAction<{ data: any, errorMessage: string|null, isError: boolean }>) => {
      let item = (action.payload && action.payload.data && action.payload.data.billingProfile) ? action.payload.data.billingProfile : null;
      
      let accountBillingProfileId = (item && Utils.isNumber(item.accountBillingProfileId)) ? item.accountBillingProfileId : null;
      let invoiceTaxRate = (item && Utils.isNumber(item.invoiceTaxRate)) ? item.invoiceTaxRate : null;
      let purchaseTaxRate = (item && Utils.isNumber(item.purchaseTaxRate)) ? item.purchaseTaxRate : null;
      
      state.accountBillingProfileId = accountBillingProfileId;
      state.invoiceTaxRate = invoiceTaxRate;
      state.purchaseTaxRate = purchaseTaxRate;

      state.isLoading = false;
    },
  };


  const func = {
    getTax: (isTaxInclusive: boolean, price: number, percent: number|null) => {
      let tax: number = 0;

      if(isTaxInclusive === true){
        if(percent != null && Utils.isNumber(percent)){
          try {
            let rate = Utils.parseFloat(price);
            tax = (100 / (percent + 100)) * rate
          } catch (e) {
            tax = price;
          }
        } else {
          tax = price;
        }
      } else {
        tax = price;
      }

      return tax;
    },
    getWithoutTax: (isTaxInclusive: boolean, price: number, percent: number|null) => {
      let plusTax = func.getTax(isTaxInclusive, price, percent);
      return Utils.getCurrency() + Utils.formatNumber(plusTax) + ' without tax';
    },
    getTaxPercentage: (billingType: number|null, invoiceTaxRate: number|null, purchaseTaxRate: number|null): number|null => {
      return (billingType === BillingTypes.Purchase) ? purchaseTaxRate : invoiceTaxRate;
    },
    getTaxRate: (isTaxInclusive: boolean, item: any|null, taxRate: { invoiceTaxRate: number|null, purchaseTaxRate: number|null, category: Array<any> }) => {
      let invoiceTaxRate = (taxRate && Utils.isNumber(taxRate.invoiceTaxRate)) ? taxRate.invoiceTaxRate : null;
      let purchaseTaxRate = (taxRate && Utils.isNumber(taxRate.purchaseTaxRate)) ? taxRate.purchaseTaxRate : null;

      let chargeCategoryId = (item && item.chargeCategoryId) ? item.chargeCategoryId : null;
      if(chargeCategoryId && taxRate.category && taxRate.category.length > 0){
        let catItems = taxRate.category.filter((x: any) => x.index == chargeCategoryId);
        if(catItems && catItems.length > 0){
          let invoiceTaxRateC = (catItems && catItems[0] && Utils.isNumber(catItems[0].invoiceTaxRate)) ? catItems[0].invoiceTaxRate : null;
          let purchaseTaxRatec = (catItems && catItems[0] && Utils.isNumber(catItems[0].purchaseTaxRate)) ? catItems[0].purchaseTaxRate : null;

          invoiceTaxRate = invoiceTaxRateC;
          purchaseTaxRate = purchaseTaxRatec;
        }
      }

      let billingType = (item) ? item.billingType : null;
      let price = (item) ? item.price : null;

      let getTaxPercentage: number|null = func.getTaxPercentage(billingType, invoiceTaxRate, purchaseTaxRate);
      return func.getWithoutTax(isTaxInclusive, price, getTaxPercentage);
    },
    getTaxRateColumn: (item: any|null) => {
      if(item){
        let iTaxRate = (item && item.invoiceTaxRate) ? item.invoiceTaxRate : null;
        let pTaxRate = (item && item.purchaseTaxRate) ? item.purchaseTaxRate : null;
        let invoiceTaxRate = (item && item.chargeCategory && item.chargeCategory.invoiceTaxRate) ? item.chargeCategory.invoiceTaxRate : iTaxRate;
        let purchaseTaxRate = (item && item.chargeCategory && item.chargeCategory.purchaseTaxRate) ? item.chargeCategory.purchaseTaxRate : pTaxRate;

        return <>
          <div>{Utils.getCurrency() + Utils.formatNumber(item.price)}</div>
          {(item.isTaxInclusive && Utils.isNumber(item.price)) && <i className={'fw-light text-muted fs-7'}>{func.getWithoutTax(item.isTaxInclusive, item.price, func.getTaxPercentage(item.billingType, invoiceTaxRate, purchaseTaxRate))}</i>}
        </>
      }
    },
    getTaxRateField: (item: any|null, iTaxRate: number|null, pTaxRate: number|null) => {
      if(item){
        let invoiceTaxRate = (item && item.chargeCategory && item.chargeCategory.invoiceTaxRate) ? item.chargeCategory.invoiceTaxRate : iTaxRate;
        let purchaseTaxRate = (item && item.chargeCategory && item.chargeCategory.purchaseTaxRate) ? item.chargeCategory.purchaseTaxRate : pTaxRate;

        return <>
          <div>{Utils.getCurrency() + Utils.formatNumber(item.price)}</div>
          {(item.isTaxInclusive && Utils.isNumber(item.price)) && <i className={'fw-light text-muted fs-7'}>{func.getWithoutTax(item.isTaxInclusive, item.price, func.getTaxPercentage(item.billingType, invoiceTaxRate, purchaseTaxRate))}</i>}
        </>
      }
    },
  }


  const apis = {
    getDefaultProfileApi: () => async (dispatch: any) => {
      dispatch(actions.startReadDefaultProfile());

      let params: any = {
        isActive: true,
        isDefault: true,
      };

      await dispatchCrudApi.readApi(params, 'AccountBillingProfile').then((result: any) => {
        dispatch(actions.finishReadDefaultProfile({ data: result.data, errorMessage: null, isError: false }));
      }).catch((error: any) => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        dispatch(actions.finishReadDefaultProfile({ data: null, errorMessage: err, isError: true }));
      });
    },

    getCustomerProfileApi: (customerId: number) => async (dispatch: any) => {
      dispatch(actions.startReadCustomerProfile());

      if(customerId){
        await dispatchCrudApi.readApi(null, 'customer/' + customerId).then((result: any) => {
          dispatch(actions.finishReadCustomerProfile({ data: result.data, errorMessage: null, isError: false }));
        }).catch((error: any) => {
          let res: ParseResult = {
            isError: false,
            errorMessage: null,
            status: null,
          };
  
          Utils.parseErrorTS(error, (result: ParseResult): void => {
            res = result
          });
          
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
            Utils.toast(err, 'error');
          }
          
          dispatch(actions.finishReadCustomerProfile({ data: null, errorMessage: err, isError: true }));
        });
      } else {
        dispatch(actions.finishReadCustomerProfile({ data: null, errorMessage: '', isError: true }));
      }
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
    ...func,
  };
}


export default NewReducer();