import React, { Component } from 'react';

import { 
    Box,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
} from '@material-ui/core';


class LegendMenu extends Component {

  constructor(props){
    super(props);

    let items = (props.items && props.items.length > 0) ? props.items : [];

    this.state = {
      items: items,
    };
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.items !== this.state.items) {
        let items = (nextProps.items && nextProps.items.length > 0) ? nextProps.items : [];
        this.setState({
            items: items
        });
    }
  }


  render() {
    return <Box className="legend-menu-component">
      <List className="legend-menu-list">
        {this.state.items.map((item, i) => {
            return <ListItem key={i} 
                button={(item.button === null) ? null : true}
                disabled={(item.disabled) ? item.disabled : false}
                style={{
                    borderLeftColor: (item.color && item.color !== '') ? item.color : ''
                }}
                onClick={() => {
                    if((item.button !== null) && this.props.onClick){
                        this.props.onClick(item);
                    }
                }}
            >
              <div className={'border-div ' + ((item.className && item.className !== '') ? item.className : '')}></div>
              <ListItemText primary={item.text} />
              {item.action && <ListItemSecondaryAction>{item.action}</ListItemSecondaryAction>}
            </ListItem>
        })}
        
      </List>
    </Box>
  }
}

export default LegendMenu;
