import {FC} from 'react'

const NoPermission: FC = () => {
  return (
    <>
      <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>No Permission</h1>

      <div className='fw-bold fs-3 text-gray-400 mb-15'>
      We are sorry <br /> You do not have permission for the selected action!
      </div>
    </>
  )
}

export {NoPermission}
