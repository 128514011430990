import React, { Component } from 'react';

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import { 
  Box,
} from '@material-ui/core';


class CustomCropperForDialog extends Component {
	
    constructor(props){
        super(props);

        this.imageRef = React.createRef();

        let initialImg = (props.initialImg) ? props.initialImg : '';
        let name = (props.name) ? props.name : '';
        let aspectRatio = (props.aspectRatio) ? props.aspectRatio : 1;
        let width = (props.width) ? props.width : 400;
        let height = (props.height) ? props.height : 400;
        let resolutionWidth = (props.resolutionWidth) ? props.resolutionWidth : 800;
        let resolutionHeight = (props.resolutionHeight) ? props.resolutionHeight : 800;
        let dragMode = (props.dragMode) ? props.dragMode : 'move';
        let viewMode = (props.viewMode || props.viewMode === 0) ? props.viewMode : 0;
        let autoCropArea = (props.autoCropArea || props.autoCropArea === 0) ? props.autoCropArea : 1;
        let className = (props.className) ? props.className : '';

        let guides = ((props.guides === true || props.guides === false) ? props.guides : true);
        let responsive = ((props.responsive === true || props.responsive === false) ? props.responsive : true);
        let restore = ((props.restore === true || props.restore === false) ? props.restore : true);
        let modal = ((props.modal === true || props.modal === false) ? props.modal : true);
        let center = ((props.center === true || props.center === false) ? props.center : true);
        let movable = ((props.movable === true || props.movable === false) ? props.movable : true);
        let disable = ((props.disable === true || props.disable === false) ? props.disable : false);
        let isCircle = ((props.isCircle === true || props.isCircle === false) ? props.isCircle : true);
        

        this.state = {
            className: className,

            initialImg: initialImg,
            name: name,
            aspectRatio: aspectRatio,
            width: width,
            height: height,
            resolutionWidth: resolutionWidth,
            resolutionHeight: resolutionHeight,
            dragMode: dragMode,
            viewMode: viewMode,
            autoCropArea: autoCropArea,

            guides: guides,
            responsive: responsive,
            restore: restore,
            modal: modal,
            center: center,
            movable: movable,
            disable: disable,
            isCircle: isCircle,
        }
    }

    componentDidMount() {
        if(this.state.initialImg === ''){
            if(this.props.onClose){
                this.props.onClose();
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isLoading !== this.state.isLoading) {
            this.setState({
                isLoading: nextProps.isLoading
            });
        }
    }


    /* EVENTS */
    reset = () => {
        this.imageRef.current.reset();
    }
    clear = () => {
        this.imageRef.current.clear();
    }
    destroy = () => {
        this.imageRef.current.destroy();
    }
    crop = (width = null, height = null) => {
        let img = this.imageRef.current.getCroppedCanvas({
            width: width ? width : this.state.resolutionWidth,
            height: height? height : this.state.resolutionHeight,
        });

        img.toBlob((blob) => {
            this.setState({
                initialImg: img.toDataURL(),
            }, () => {
                if(this.props.onUpload){
                    this.props.onUpload(blob, this.state.name);
                }
                this.close();
            });
        });
    }
    close = () => {
        this.setState({
            initialImg: null,
        }, () => {
            if(this.props.onEnd){
                this.props.onEnd();
            }
        });
    }
    /* END EVENTS */


    /* ELEMENTS */
    setCropper = () => {
        return <Box className={'cropper-wrapper'}>
            <Cropper
                ref={this.imageRef}

                src={this.state.initialImg}
                style={{
                    width: this.state.width,
                    height: this.state.height,
                    margin: '0 auto',
                    position: 'relative',
                }}
                aspectRatio={this.state.aspectRatio}
                dragMode={this.state.dragMode}
                viewMode={this.state.viewMode}
                autoCropArea={this.state.autoCropArea}

                responsive={this.state.responsive}
                guides={this.state.guides}
                restore={this.state.restore}
                modal={this.state.modal}
                center={this.state.center}
                movable={this.state.movable}
                disable={this.state.disable}

                crop={(e) => {
                    var width = e.detail.width;
                    var height = e.detail.height;

                    if (
                        width < 1
                        || height < 1
                        || width > this.state.resolutionWidth
                        || height > this.state.resolutionHeight
                    ) {
                        width = Math.max(1, Math.min(this.state.resolutionWidth, width));
                        height = Math.max(1, Math.min(this.state.resolutionHeight, height));
                    }

                    if(this.props.onCrop){
                        this.props.onCrop(width, height);
                    }
                }}
            />
        </Box>
    }
    /* END ELEMENTS */
    

    render() {
        return <Box className={"custom-cropper-component " + this.state.className + " " + ((this.state.isCircle === false) ? 'no-circle' : '')}
            style={{
                width: this.state.width,
                height: this.state.height,
                margin: '0 auto',
            }}
        >
            {this.setCropper()}
        </Box>;
    }
}

export default CustomCropperForDialog;
