import { createSlice } from "@reduxjs/toolkit";
import * as Yup from 'yup';
import moment from 'moment';

import * as dispatchCrudApi from '../../../api/CRUD/DispatchCRUD'
import Utils from '../../../utils/utils';
import { BillingTypes, XeroPaymentTermType } from '../../../utils/enums';

import invoiceNumber from './invoiceNumber';


function NewReducer() {
  const name = 'invoiceNumberForm';


  const formValues = {
    form: []
  }

  const formLabels = {
    customerName: {
      field: {
        type: 'text',
        id: 'customerName',
        name: 'customerName',
        placeholder: 'Customer Name',
      },
      label: 'Customer Name',
      required: true,
    },
    billingName: {
      field: {
        type: 'text',
        id: 'billingName',
        name: 'billingName',
        placeholder: 'Billing Name',
      },
      label: 'Billing Name',
      required: true,
    },
    billingAddress: {
      field: {
        as: 'textarea',
        rows: 4,
        id: 'billingAddress',
        name: 'billingAddress',
        placeholder: Utils.placeholderRows(4),
      },
      label: 'Billing Address',
      required: true,
    },
    accountBillingProfileId: {
      field: {
        id: 'accountBillingProfileId',
        name: 'billingProfileName',
        placeholder: 'Billing by',
      },
      label: 'Billing by',
      required: true,
    },
    invoiceDate: {
      field: {
        id: 'invoiceDate',
        name: 'invoiceDate',
        placeholder: 'Invoice Date',
      },
      label: 'Invoice Date',
      labelPurchase: 'Purchase date',
      labelExpense: 'Expense date',
      required: true,
    },
    invoiceNumber: {
      field: {
        type: 'text',
        id: 'invoiceNumber',
        name: 'invoiceNumber',
        placeholder: 'Invoice number',
      },
      label: 'Invoice number',
      labelPurchase: 'Purchase number',
      idPurchase: 'purchaseOrderNumber',
      namePurchase: 'purchaseOrderNumber',
      labelExpense: 'Expense number',
      idExpense: 'expenseNumber',
      nameExpense: 'expenseNumber',
      required: true,
    },
    quickBookDefaultTermId: {
      field: {
        id: 'quickBookDefaultTermId',
        name: 'paymentTerms',
        placeholder: 'Payment terms',
      },
      label: 'Payment terms',
      required: true,
    },
    xeroPaymentTerm: {
      field: {
        type: 'number',
        id: 'xeroPaymentTerm',
        name: 'xeroPaymentTerm',
        placeholder: 'Payment terms',
      },
      label: 'Payment terms',
      required: false,
    },
    xeroPaymentTermType: {
      field: {
        type: 'number',
        id: 'xeroPaymentTermType',
        name: 'xeroPaymentTermType',
        placeholder: '',
      },
      label: '',
      error: 'Due date for sales invoices must be between 1 and 31 when set to the following (or current) month.',
      required: false,
    },
  }

  const validationSchema = Yup.object().shape({
    form: Yup.array().of(Yup.object().shape({
      hasBillable: Yup.bool().oneOf([true, false]),

      customer: Yup.object().when(['hasBillable'], (hasBillable) => {
        if(hasBillable){
          return Yup.object().shape({
            
            customerName: Yup.string().required().label(formLabels.customerName.label),
            billingName: Yup.string().required().label(formLabels.billingName.label),

            billingAddress: Yup.string().required().label(formLabels.billingAddress.label),
            accountBillingProfileId: Yup.number().nullable().required().label(formLabels.accountBillingProfileId.label),
    
            paymentTerms: Yup.string().nullable(),
            quickBookDefaultTermId: Yup.string().when(['billingProfile.isConnectQuickbook'], (isConnectQuickbook) => {
              if(isConnectQuickbook){
                return Yup.string().nullable().required().label(formLabels.quickBookDefaultTermId.label);
              } else {
                return Yup.string().nullable().label(formLabels.quickBookDefaultTermId.label);
              }
            }),
    
            // xeroPaymentTerm: Yup.number().nullable().positive().min(0).label(formLabels.xeroPaymentTerm.label),
            xeroPaymentTerm: Yup.number().when(['billingProfile.isConnectXero', 'xeroPaymentTermType'], (isConnectXero, xeroPaymentTermType) => {
              if(isConnectXero){
                if((xeroPaymentTermType == XeroPaymentTermType.OF_CURRENT_MONTH) || (xeroPaymentTermType == XeroPaymentTermType.OF_FOLLOWING_MONTH)){
                  return Yup.number().nullable().required().positive().min(0).max(31, formLabels.xeroPaymentTermType.error).label(formLabels.xeroPaymentTerm.label);
                } else {
                  return Yup.number().nullable().required().positive().min(0).label(formLabels.xeroPaymentTerm.label);
                }
              } else {
                return Yup.number().nullable().positive().min(0).label(formLabels.xeroPaymentTerm.label);
              }
            }),
            xeroPaymentTermType: Yup.number().when(['billingProfile.isConnectXero'], (isConnectXero) => {
              if(isConnectXero){
                return Yup.number().nullable().required().positive().min(0).label(formLabels.xeroPaymentTermType.label);
              } else {
                return Yup.number().nullable().label(formLabels.xeroPaymentTermType.label);
              }
            }),
    
    
            billingProfile: Yup.object().shape({
              billingType: Yup.number().nullable(),
    
              invoiceDate: Yup.date().when(['billingType'], (billingType) => {
                if(Utils.parseFloat(billingType) == BillingTypes.Purchase){
                  return Yup.date().nullable().required().label(formLabels.invoiceDate.labelPurchase);
                } else if(Utils.parseFloat(billingType) == BillingTypes.Expense){
                  return Yup.date().nullable().required().label(formLabels.invoiceDate.labelExpense);
                } else {
                  return Yup.date().nullable().required().label(formLabels.invoiceDate.label);
                }
              }),
    
              invoiceNumberPrefix: Yup.string().nullable(),
              purchaseOrderNumberPrefix: Yup.string().nullable(),
              invoiceNumber: Yup.string().when(['billingType'], (billingType) => {
                if(Utils.parseFloat(billingType) == BillingTypes.Purchase){
                  return Yup.string().required().label(formLabels.invoiceNumber.labelPurchase);
                } else if(Utils.parseFloat(billingType) == BillingTypes.Expense){
                  return Yup.string().required().label(formLabels.invoiceNumber.labelExpense);
                } else {
                  return Yup.string().required().label(formLabels.invoiceNumber.label);
                }
              }),
    
              quickBookAppId: Yup.number().nullable(),
              isConnectQuickbook: Yup.bool().oneOf([true, false]),
              isConnectXero: Yup.bool().oneOf([true, false]),
            }),
    
          });

        } else {

          return Yup.object().shape({
            
            customerName: Yup.string().label(formLabels.customerName.label),
            billingName: Yup.string().label(formLabels.billingName.label),

            billingAddress: Yup.string().label(formLabels.billingAddress.label),
            accountBillingProfileId: Yup.number().nullable().label(formLabels.accountBillingProfileId.label),
    
            paymentTerms: Yup.string().nullable(),
            quickBookDefaultTermId: Yup.string().when(['billingProfile.isConnectQuickbook'], (isConnectQuickbook) => {
              if(isConnectQuickbook){
                return Yup.string().nullable().label(formLabels.quickBookDefaultTermId.label);
              } else {
                return Yup.string().nullable().label(formLabels.quickBookDefaultTermId.label);
              }
            }),
    
            // xeroPaymentTerm: Yup.number().nullable().positive().min(0).label(formLabels.xeroPaymentTerm.label),
            xeroPaymentTerm: Yup.number().when(['billingProfile.isConnectXero', 'xeroPaymentTermType'], (isConnectXero, xeroPaymentTermType) => {
              if(isConnectXero){
                if((xeroPaymentTermType == XeroPaymentTermType.OF_CURRENT_MONTH) || (xeroPaymentTermType == XeroPaymentTermType.OF_FOLLOWING_MONTH)){
                  return Yup.number().nullable().required().positive().min(0).max(31, formLabels.xeroPaymentTermType.error).label(formLabels.xeroPaymentTerm.label);
                } else {
                  return Yup.number().nullable().required().positive().min(0).label(formLabels.xeroPaymentTerm.label);
                }
              } else {
                return Yup.number().nullable().positive().min(0).label(formLabels.xeroPaymentTerm.label);
              }
            }),
            xeroPaymentTermType: Yup.number().when(['billingProfile.isConnectXero'], (isConnectXero) => {
              if(isConnectXero){
                return Yup.number().nullable().required().positive().min(0).label(formLabels.xeroPaymentTermType.label);
              } else {
                return Yup.number().nullable().label(formLabels.xeroPaymentTermType.label);
              }
            }),

            billingProfile: Yup.object().shape({
              billingType: Yup.number().nullable(),
    
              invoiceDate: Yup.date().when(['billingType'], (billingType) => {
                if(Utils.parseFloat(billingType) == BillingTypes.Purchase){
                  return Yup.date().nullable().label(formLabels.invoiceDate.labelPurchase);
                } else if(Utils.parseFloat(billingType) == BillingTypes.Expense){
                  return Yup.date().nullable().label(formLabels.invoiceDate.labelExpense);
                } else {
                  return Yup.date().nullable().label(formLabels.invoiceDate.label);
                }
              }),
    
              invoiceNumberPrefix: Yup.string().nullable(),
              purchaseOrderNumberPrefix: Yup.string().nullable(),
              invoiceNumber: Yup.string().when(['billingType'], (billingType) => {
                if(Utils.parseFloat(billingType) == BillingTypes.Purchase){
                  return Yup.string().label(formLabels.invoiceNumber.labelPurchase);
                } else if(Utils.parseFloat(billingType) == BillingTypes.Expense){
                  return Yup.string().label(formLabels.invoiceNumber.labelExpense);
                } else {
                  return Yup.string().label(formLabels.invoiceNumber.label);
                }
              }),
    
              quickBookAppId: Yup.number().nullable(),
              isConnectQuickbook: Yup.bool().oneOf([true, false]),
              isConnectXero: Yup.bool().oneOf([true, false]),
            }),
    
          });
        }
      }),

      // customer: Yup.object().shape({
      //   hasBillable: Yup.bool().oneOf([true, false]),

      //   billingAddress: Yup.string().required().label(formLabels.billingAddress.label),
      //   accountBillingProfileId: Yup.number().nullable().required().label(formLabels.accountBillingProfileId.label),

      //   paymentTerms: Yup.string().nullable(),
      //   quickBookDefaultTermId: Yup.string().when(['billingProfile.isConnectQuickbook'], (isConnectQuickbook) => {
      //     if(isConnectQuickbook){
      //       return Yup.string().nullable().required().label(formLabels.quickBookDefaultTermId.label);
      //     } else {
      //       return Yup.string().nullable().label(formLabels.quickBookDefaultTermId.label);
      //     }
      //   }),

      //   xeroPaymentTerm: Yup.number().nullable().positive().min(0).label(formLabels.xeroPaymentTerm.label),
      //   // xeroPaymentTerm: Yup.number().when(['billingProfile.isConnectXero'], (isConnectXero) => {
      //   //   if(isConnectXero){
      //   //     return Yup.number().nullable().required().positive().min(0).label(formLabels.xeroPaymentTerm.label);
      //   //   } else {
      //   //     return Yup.number().nullable().positive().min(0).label(formLabels.xeroPaymentTerm.label);
      //   //   }
      //   // }),


      //   billingProfile: Yup.object().shape({
      //     billingType: Yup.number().nullable(),

      //     invoiceDate: Yup.date().when(['billingType'], (billingType) => {
      //       if(Utils.parseFloat(billingType) == BillingTypes.Purchase){
      //         return Yup.date().nullable().required().label(formLabels.invoiceDate.labelPurchase);
      //       } else {
      //         return Yup.date().nullable().required().label(formLabels.invoiceDate.label);
      //       }
      //     }),

      //     invoiceNumberPrefix: Yup.string().nullable(),
      //     purchaseOrderNumberPrefix: Yup.string().nullable(),
      //     invoiceNumber: Yup.string().when(['billingType'], (billingType) => {
      //       if(Utils.parseFloat(billingType) == BillingTypes.Purchase){
      //         return Yup.string().required().label(formLabels.invoiceNumber.labelPurchase);
      //       } else {
      //         return Yup.string().required().label(formLabels.invoiceNumber.label);
      //       }
      //     }),

      //     quickBookAppId: Yup.number().nullable(),
      //     isConnectQuickbook: Yup.bool().oneOf([true, false]),
      //     isConnectXero: Yup.bool().oneOf([true, false]),
      //   }),

      // }),
      
    }))
  });

  const initialState = {
    formValues: formValues,
    formLabels: formLabels,
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnMount: false,
    validateOnChange: true,

    billingProfile: {
      isLoading: false,
      data: [],
      isError: false,
    },
    creditTerms: {
      isLoading: false,
      data: [],
      isError: false,
    },
    resetInvoiceNumber: {
      index: null,
      isLoading: false,
      invoicePrefix: '',
      invoiceNumber: '',
      isError: false,
    },
  };


  const reducers = {
    /**
     * Inicialization form only on fist load
     */
    initForm: (state, action) => {
      state.formValues['form'] = action.payload;
    },

    /**
     * Set data at form on submit (Triggers extraReducers in invoiceNumber slice to retain the newly entered data)
     */
    setForm: (state, action) => {
      state.formValues['form'] = action.payload;
    },


    billingProfileStart: (state, action) => {
      state.billingProfile.isLoading = true;
      state.billingProfile.data = [];
      state.billingProfile.isError = false;
    },
    billingProfileFinish: (state, action) => {
      let billingProfile = state.billingProfile;

      let data = action.payload;
      
      billingProfile.isError = data.isError;
      billingProfile.data = (data && data.data && data.data.data) ? data.data.data : [];
      billingProfile.isLoading = false;
      
      state.billingProfile = billingProfile;
    },
    
    creditTermsStart: (state, action) => {
      state.creditTerms.isLoading = true;
      state.creditTerms.data = [];
      state.creditTerms.isError = false;
    },
    creditTermsFinish: (state, action) => {
      let creditTerms = state.creditTerms;

      let data = action.payload;
      
      creditTerms.isError = data.isError;
      creditTerms.data = (data && data.data) ? data.data : [];
      creditTerms.isLoading = false;
      
      state.creditTerms = creditTerms;
    },
    
    resetInvoiceNumberStart: (state, action) => {
      state.resetInvoiceNumber.index = action.payload;
      state.resetInvoiceNumber.isLoading = true;
      state.resetInvoiceNumber.invoicePrefix = '';
      state.resetInvoiceNumber.invoiceNumber = '';
      state.resetInvoiceNumber.isError = false;
    },
    resetInvoiceNumberFinish: (state, action) => {
      let resetInvoiceNumber = state.resetInvoiceNumber;

      let data = action.payload;
      
      let arr = [];
      try {
        arr = (data && data.data && data.data != '') ? data.data.split('-') : [];
      } catch(e){}
      let invoicePrefix = (arr && arr.length > 0) ? arr[0] : '';
      let invoiceNumber = (arr && arr.length > 1) ? arr[1] : '';

      if(action.payload && action.payload.callback){
        action.payload.callback(invoicePrefix, invoiceNumber);
      }

      resetInvoiceNumber.isError = data.isError;
      resetInvoiceNumber.invoicePrefix = invoicePrefix;
      resetInvoiceNumber.invoiceNumber = invoiceNumber;
      resetInvoiceNumber.index = null;
      resetInvoiceNumber.isLoading = false;
      
      state.resetInvoiceNumber = resetInvoiceNumber;
    },
  };
  

  const extraReducers = (builder) => {
    /**
     * When jobs are finished loading, creditLimits items are added only first time
     */
    builder.addCase(invoiceNumber.finishJobs, (state, action) => {
      let data = (action && action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.length > 0) ? action.payload.data.data : [];

      if(data && data.length > 0){
        let arr = [];

        data.forEach((item) => {
          if(item.customer && item.customer.billingProfile && item.customer.billingProfile.isConnectQuickbook){
            let quickBookDefaultTermId = item.customer.quickBookDefaultTermId;
            let paymentTerms = item.customer.paymentTerms;

            let itm = {
              [formLabels.quickBookDefaultTermId.field.id]: quickBookDefaultTermId,
              [formLabels.quickBookDefaultTermId.field.name]: paymentTerms,
            };

            let index = arr.findIndex((x) => x[formLabels.quickBookDefaultTermId.field.id] == quickBookDefaultTermId);
            if(index === -1) {
              arr.push(itm);
            }
          }
        });

        let creditTerms = state.creditTerms;
        creditTerms.data = arr;
        state.creditTerms = creditTerms;
      }
    });
  };


  const apis = {
    callReadBillingProfileApi: (params = null) => async (dispatch) => {
      dispatch(actions.billingProfileStart());

      let data = {
        currentPage: 1,
        pageSize: Utils.getMaxPageSize(),
        searchQuery: '',
        isActive: true,
      };

      await dispatchCrudApi.readApi(data, 'AccountBillingProfile').then(result => {
        let data = result.data;
        
        dispatch(actions.billingProfileFinish({ data: data, errorMessage: '', isError: false }));
      }).catch(error => {
        let isError = true;
        let errorMessage = "";

        Utils.parseError(error, (result) => {
          isError = result.isError;
          errorMessage = result.errorMessage;
        });
        
        dispatch(actions.billingProfileFinish({ data: null, errorMessage: errorMessage, isError: isError }));
      });
    },
    
    callReadCreditTermsApi: (params = null) => async (dispatch) => {
      dispatch(actions.creditTermsStart());

      let data = {
        quickBookAppId: params,
      };

      await dispatchCrudApi.readApi(data, 'quickbookapp/term').then(result => {
        let data = result.data.map(item => {
          item[formLabels.quickBookDefaultTermId.field.id] = item.id;
          item[formLabels.quickBookDefaultTermId.field.name] = item.name;
          return item;
        });
        
        dispatch(actions.creditTermsFinish({ data: data, errorMessage: '', isError: false }));
      }).catch(error => {
        let isError = true;
        let errorMessage = "";

        Utils.parseError(error, (result) => {
          isError = result.isError;
          errorMessage = result.errorMessage;
        });
        
        dispatch(actions.creditTermsFinish({ data: null, errorMessage: errorMessage, isError: isError }));
      });
    },
    
    callResetInvoiceNumberApi: (params = null) => async (dispatch) => {
      let index = (params && params.index != null) ? params.index : null;
      dispatch(actions.resetInvoiceNumberStart(index));

      let callback = (params && params.callback) ? params.callback : null; // callback function called in TabForm on click on reset invoice nuber link
      let billingType = (params && params.billingType != null) ? params.billingType : null;
      let accountBillingProfileId = (params && params.accountBillingProfileId) ? params.accountBillingProfileId : null;
      let invoiceDate = (params && params.invoiceDate) ? params.invoiceDate : moment().format("YYYY-MM-DDTHH:mm:ss.000");

      let data = {
        accountBillingProfileId: accountBillingProfileId,
      };

      let path = '';
      if(billingType == BillingTypes.Purchase){
        path = 'job/billing/prompt-po-number';
        data['purchaseOrderDate'] = invoiceDate;
      } else if(billingType == BillingTypes.Expense){
          path = 'job/billing/prompt-expense-number';
          data['ExpenseDate'] = invoiceDate;
      } else {
        path = 'job/billing/prompt-invoice-number';
        data['invoiceDate'] = invoiceDate;
      }

      await dispatchCrudApi.createApi(data, path).then(result => {
        let data = result.data;
        
        dispatch(actions.resetInvoiceNumberFinish({ data: data, errorMessage: '', isError: false, billingType: billingType, index: index, callback: callback }));
      }).catch(error => {
        let isError = true;
        let errorMessage = "";

        Utils.parseError(error, (result) => {
          isError = result.isError;
          errorMessage = result.errorMessage;
        });
        
        dispatch(actions.resetInvoiceNumberFinish({ data: null, errorMessage: errorMessage, isError: isError, billingType: billingType, index: index, callback: null }));
      });
    },

  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
    extraReducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();