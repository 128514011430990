import React from "react";

import { 
    Row,
    Col,
    Form,
    InputGroup,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import Utils from "../../../../utils/utils";
import { InOrOut, BinOrWaste, PagesPermissions } from "../../../../utils/enums";

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import CustomTooltip from '../../../../components/Tooltip/Tooltip';
import RSuiteSelectPicker from '../../../../components/OLD/Select/RSuiteSelectPicker';
import RSuiteAutoComplete from '../../../../components/OLD/Autocomplete/RSuiteAutoComplete';
import InputLayout from "../../../../components/input/InputLayout";

import { connect } from 'react-redux'
import { clear } from "../../../../../setup/redux/actions";
import { dispatchApiCallGet } from '../../../../../setup/redux/dispatch/actions'

import { ReactComponent as ScanTextIcon } from "../../../../../_metronic/assets/img/bin_center/scan_text.svg";


const StyledInputGroup = styled(InputGroup)`
    input {
        &:not(.disabled) {
            &:not([value=""]) {
                border-right: 0px;
            }
        }
    }

    .input-group-text {
        background-color: white;
        cursor: pointer;

        i {
            font-size: 14px;
            color: #8e8e93;
        }

        &:hover {
            i {
                color: #f44336;
            }
        }
    }
`;
const StyledInputReadOnly = styled(Form.Control)`
    border: 0px;
    padding-left: 5px;
    cursor: default;

    &[readonly]{
        color: #181C32 !important;
    }
`;


class StepPutSection extends React.Component {
  
    constructor(props) {
        super(props);
        
        this.name = 'job_form_step_put_section';

        let dataParams = (props.dataParams) ? props.dataParams : null;
        let id = (dataParams && dataParams.id) ? dataParams.id : null;
        let row = (dataParams && dataParams.row) ? dataParams.row : null;
        
        this.pageRef = React.createRef();

        this.state = {
            id: id,
            row: row,
        };
    }
    

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            this.name + '-binnumber', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = [];

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.binNumberId;
                        let title = item.binNumberName;
                        let arrItem = {
                            index: i,
                            value: value,
                            title: title,
                            label: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );
    }
	
    callBinNumberReadApi = (binTypeId = null, items, searchQuery = '', callback = null) => {
        let {
            values,
        } = this.props.formOptions;

        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: searchQuery,
            binTypeId: binTypeId,
            customerSiteId: values.customerSiteId,
            customerId: values.customerId,
            showRecentJob: true,
        };

        this.props.dispatchApiCallGet(data, this.name + '-binnumber', 'binnumber', { items: items }, callback, () => {});
    }
    /* END API */


    body = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;
        
        let hasEditJobAfterCompleted = (Utils.hasPermission(Utils.getPermissions(), PagesPermissions.EditJobAfterCompleted.read))

        let isDisabled1 = values.isParams || Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || !(values.customerSiteId) || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName);
        let isDisabled2 = values.isParams || Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || !(values.customerSiteId) || Utils.isCancelledOrFailed(values.currentStatusName);
        let isDisabled = hasEditJobAfterCompleted ? isDisabled2 : isDisabled1;
        
        let isDisabledBinN1 = values.isParams || Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || !(values.customerSiteId) || !(values.stepPutBinTypeId) || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName);
        let isDisabledBinN2 = values.isParams || Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || !(values.customerSiteId) || !(values.stepPutBinTypeId) || Utils.isCancelledOrFailed(values.currentStatusName);
        let isDisabledBinN = hasEditJobAfterCompleted ? isDisabledBinN2 : isDisabledBinN1;
        
        return <Row className={''}>
            <Col xs={12} md={6}>
                <Row className={''}>
                    <Col xs={12}>
                        <h3 className={'mb-7'}>
                            <span style={{ color: '#DFDFDF' }}>BIN OUT</span>
                            <CustomTooltip placement={'right'} title={'You deliver bin to customer site.'}>
                                <InfoOutlinedIcon style={{ color: '#DFDFDF', width: '30px', height: '25px' }} />
                            </CustomTooltip>
                        </h3>
                    </Col>
                    <Col xs={12}>
                        <Row>
                            <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.stepPutBinTypeId.label}
                                    required
                                >
                                    <StyledInputGroup>
                                        <Form.Control
                                            className={'custom-readonly-bin-waste-field ' + (isDisabled ? 'disabled': '')}
                                            id={this.props.fields.stepPutBinTypeId.id}
                                            placeholder={this.props.fields.stepPutBinTypeId.placeholder}
                                            readOnly={true}
                                            value={values.stepPutBinTypeName}
                                            isInvalid={!!errors.stepPutBinTypeId}
                                            onClick={(e) => {
                                                if(!isDisabled){
                                                    if(this.props.onBinWasteSidePanel){
                                                        this.props.onBinWasteSidePanel(InOrOut.IN, BinOrWaste.BIN);
                                                    }
                                                }
                                            }}
                                        />
                                        {(!isDisabled && values.stepPutBinTypeName != null && values.stepPutBinTypeName != '') && <InputGroup.Text
                                            onClick={(e) => {
                                                if(this.props.onBinWasteClear){
                                                    this.props.onBinWasteClear(InOrOut.IN, BinOrWaste.BIN);
                                                }
                                            }}
                                        >
                                            <i className={'material-icons'}>close</i>
                                        </InputGroup.Text>}
                                        <Form.Control.Feedback type="invalid">{errors.stepPutBinTypeId}</Form.Control.Feedback>
                                    </StyledInputGroup>
                                </InputLayout>
                            </Col>
                            <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.stepPutWasteTypeId.label}
                                    required
                                >
                                    <StyledInputGroup>
                                        <Form.Control
                                            className={'custom-readonly-bin-waste-field ' + (isDisabled ? 'disabled': '')}
                                            id={this.props.fields.stepPutWasteTypeId.id}
                                            placeholder={this.props.fields.stepPutWasteTypeId.placeholder}
                                            readOnly={true}
                                            value={values.stepPutWasteTypeName}
                                            isInvalid={!!errors.stepPutWasteTypeId}
                                            onClick={(e) => {
                                                if(!isDisabled){
                                                    if(this.props.onBinWasteSidePanel){
                                                        this.props.onBinWasteSidePanel(InOrOut.IN, BinOrWaste.BIN);
                                                    }
                                                }
                                            }}
                                        />
                                        {(!isDisabled && values.stepPutWasteTypeName != null && values.stepPutWasteTypeName != '') && <InputGroup.Text
                                            onClick={(e) => {
                                                if(this.props.onBinWasteClear){
                                                    this.props.onBinWasteClear(InOrOut.IN, BinOrWaste.BIN);
                                                }
                                            }}
                                        >
                                            <i className={'material-icons'}>close</i>
                                        </InputGroup.Text>}
                                        <Form.Control.Feedback type="invalid">{errors.stepPutWasteTypeId}</Form.Control.Feedback>
                                    </StyledInputGroup>
                                </InputLayout>
                            </Col>
                            <Col xs={12}>
                                <InputLayout
                                    label={this.props.fields.stepPutBinNumber.label}
                                >
                                    <InputGroup>
                                    <Form.Control
                                        as = {RSuiteAutoComplete}
                                        isInvalid={!!errors.stepPutBinNumber}

                                        id={this.props.fields.stepPutBinNumber.id}
                                        placeholder={this.props.fields.stepPutBinNumber.placeholder}
                                        items={values.customFormParams.stepPutBinNumberItems}
                                        value={values.stepPutBinNumber}
                                        isLoading={values.customFormParams.stepPutBinNumberIsLoading}
                                        disabled={isDisabledBinN}
                                        labelKey={'title'}
                                        isDefault={true}
                                        pattern={Utils.max7chars()}
                                        renderMenuItem={(label, item) => {
                                            let minDate = Utils.getMinDate();
                                            let lastJobDate = (item && item.item && item.item.lastJobDate) ? item.item.lastJobDate : minDate;

                                            return <div className={'d-flex align-items-center'}>
                                                {((lastJobDate !== minDate) && (item.index < 5)) ? <i className={'material-icons'}>access_time</i> : null}
                                                <span className={'ps-2'}>{item.title}</span>
                                            </div>
                                        }}
                                        onClick={async () => {
                                            await setFieldValue('customFormParams.stepPutBinNumberIsLoading', true);
                                            this.callBinNumberReadApi(values.stepPutBinTypeId, values.customFormParams.stepPutBinNumberItems, '', async (items) => {
                                                await setFieldValue('customFormParams.stepPutBinNumberItems', items);
                                                await setFieldValue('customFormParams.stepPutBinNumberIsLoading', false);
                                            });
                                        }}
                                        onSearch={async (value) => {
                                            await setFieldValue('stepPutisUsingQr', false);
                                            await setFieldValue('stepPutisUsingTextDetection', false);
                                            await setFieldValue('customFormParams.isSameAsBinOut', false);
                                            await setFieldValue('customFormParams.stepPutBinNumberIsLoading', true);
                                            this.callBinNumberReadApi(values.stepPutBinTypeId, values.customFormParams.stepPutBinNumberItems, value, async (items) => {
                                                await setFieldValue('customFormParams.stepPutBinNumberItems', items);
                                                await setFieldValue('customFormParams.stepPutBinNumberIsLoading', false);
                                            });
                                        }}
                                        onChange={async (value) => {
                                            await setFieldValue('stepPutisUsingQr', false);
                                            await setFieldValue('stepPutisUsingTextDetection', false);
                                            await setFieldValue('stepPutBinNumber', value);
                                            await setFieldValue('customFormParams.isSameAsBinOut', false);
                                        }}
                                        onSelect={async (value, item) => {
                                            await setFieldValue('stepPutisUsingQr', false);
                                            await setFieldValue('stepPutisUsingTextDetection', false);
                                            await setFieldValue('stepPutBinNumber', value);
                                            await setFieldValue('customFormParams.isSameAsBinOut', false);
                                        }}
                                    />
                                    {values.stepPutisUsingQr && <CustomTooltip title={'QR code is scanned'}>
                                        <i style={{ position: 'absolute', right: '15px', top: '15px' }} class="fa fa-qrcode"></i>
                                    </CustomTooltip>}
                                    {values.stepPutisUsingTextDetection && <CustomTooltip title={'QR code is scanned with text recognition'}>
                                        <ScanTextIcon style={{ position: 'absolute', right: '14px', top: '15px', width: '15px', height: '15px' }} />
                                    </CustomTooltip>}
                                    <Form.Control.Feedback type="invalid">{errors.stepPutBinNumber}</Form.Control.Feedback>
                                    </InputGroup>
                                </InputLayout>
                            </Col>
                            
                            <Col xs={12}>
                                <InputLayout
                                    label={'Service name'}
                                    inputProps={{
                                        className: 'd-flex align-items-center'
                                    }}
                                >
                                    <div>
                                        <span className={'pe-2'}>{values.stepPutServiceItemName}</span>
                                        {(values.stepPutServiceItemIsGlobal && (values.stepPutServiceItemName != '')) && <CustomTooltip placement={'top'} title={'Global service'}>
                                            <i className={'material-icons'} style={{ position: 'relative', top: '6px', fontSize: '22px', }}>public</i>
                                        </CustomTooltip>}
                                    </div>
                                </InputLayout>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    }
    

    render() {
        return <div ref={this.pageRef}>
            {this.body()}
        </div>
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(StepPutSection);
