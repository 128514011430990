import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {changePassword, forgotPassword} from '../../api/auth/api'

const initialValues = {
  password: '',
  confirmPassword: '',
}

const forgotPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, 'Minimum 6 characters')
    .max(50, 'Maximum 50 characters')
    .required('Password is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [err, setErr] = useState('');
  
  const email = window.localStorage.getItem('resetPassEmail') || '';

  let path = document.location.href.split('/');
  const code = (path && path.length > 0) ? path[path.length - 1] : '';

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        changePassword(email, values.password, values.confirmPassword, code)
          .then((result) => {
            setHasErrors(false)
            setLoading(false)
          })
          .catch( error => {
            try {
                setHasErrors(true)
                setLoading(false)
                setSubmitting(false)

                if (error.response) {
                    // Request made and server responded                  
                    if(error.response.data) {
                      if(error.response.data.error) {
                        setErr(error.response.data.error);
                      } else {
                        setErr(error.response.data);
                      }
                    }    
                } else if (error.request) {
                    // The request was made but no response was received
                    setErr('Internal server error');
                } else {
                    // Something happened in setting up the request that triggered an Error
                    setErr('Internal server error');
                }
            } catch(err) {
              setErr('Internal server error');
            }
        })
      }, 1000)
    },
  })

  return (
      <form
        className='form w-100'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
      <div className='mb-lg-10 d-flex flex-column'>
        <h1 className='text-dark m-0 mb-3 mb-lg-3 fs-3qx fw-bolder'>Reset your password</h1>
        <div className='text-muted fw-bold fs-4 mb-10'>
          Enter new password.
        </div>
      </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
                {err}
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 alert alert-success'>
            <div className='alert-text font-weight-bold'>Password changed successfully!</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>New Password</label>
          <input
            type='password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.password && formik.errors.password
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Repeat Password</label>
          <input
            type='password'
            autoComplete='off'
            {...formik.getFieldProps('confirmPassword')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword
              },
              {
                'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
              }
            )}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.confirmPassword}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
      <div className='text-center'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary w-100 mb-10'
          >
          {!loading && <span className='indicator-label text-uppercase'>Reset Password</span>}
          {loading && (
            <span className='indicator-progress text-uppercase' style={{display: 'block'}}>
              Please wait 
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
          </button>

          <div className='text-center text-muted fw-bolder'> 
            Already have an account? {' '}
            <Link to='/login' className='link-primary fw-bold'>
              Sign In
            </Link>
          </div>
        </div>
        {/* end::Form group */}
      </form>
  )
}
