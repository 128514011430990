import React, { Component } from 'react';

import apiUtil from '../../../../api/apiUtil.jsx';
import Utils from '../../../../utils/utils.js';
import JobTemplateApi from '../../../../api/OLD/Dispatch/JobTemplate.jsx';

import { 
    Box,
    Grid,
    Tooltip,
    Radio,
} from '@material-ui/core';

import CustomAutosuggest from '../../../../components/OLD/Autocomplete/CustomAutosuggest.jsx';
import StandaloneSearchBox from '../../../../components/OLD/GoogleMap/AddressSearchBox.jsx';


class LocationItemRow extends Component {
  
    constructor(props){
        super(props);

        let item = (props.item) ? props.item : null;
        let addresses = (props.addresses && props.addresses.length > 0) ? props.addresses : [];
        
        let isDefault = (item && ((item.isDefault === false) || (item.isDefault === true))) ? item.isDefault : false;
        let stepSiteName = (item && item.stepSiteName !== '') ? item.stepSiteName : '';
        let stepSiteAddress = (item && item.stepSiteAddress !== '') ? item.stepSiteAddress : '';
        let stepSiteLatitude = (item && item.stepSiteLatitude !== '') ? item.stepSiteLatitude : null;
        let stepSiteLongitude = (item && item.stepSiteLongitude !== '') ? item.stepSiteLongitude : null;


        this.state = {
            item: item,
            addresses: addresses,

            isDefault: isDefault,
            stepSiteName: stepSiteName,
            stepSiteAddress: stepSiteAddress,
            stepSiteLatitude: stepSiteLatitude,
            stepSiteLongitude: stepSiteLongitude,

            siteAddress: [],
            siteAddressIsLoading: false
        }
    }


    componentWillReceiveProps(nextProps) {
        let item = (nextProps.item) ? nextProps.item : null;
        let addresses = (nextProps.addresses && nextProps.addresses.length > 0) ? nextProps.addresses : [];

        let isDefault = (item && ((item.isDefault === false) || (item.isDefault === true))) ? item.isDefault : false;
        let stepSiteName = (item && item.stepSiteName !== '') ? item.stepSiteName : '';
        let stepSiteAddress = (item && item.stepSiteAddress !== '') ? item.stepSiteAddress : '';
        let stepSiteLatitude = (item && item.stepSiteLatitude !== '') ? item.stepSiteLatitude : null;
        let stepSiteLongitude = (item && item.stepSiteLongitude !== '') ? item.stepSiteLongitude : null;

        
        this.setState({
            item: item,
            addresses: addresses,

            isDefault: isDefault,
            stepSiteName: stepSiteName,
            stepSiteAddress: stepSiteAddress,
            stepSiteLatitude: stepSiteLatitude,
            stepSiteLongitude: stepSiteLongitude,

            isInvalid: false,
        });
    }

    
    /* API */
    callJobTemplateSiteAddressReadApi = (searchQuery = "", callback = null) => {
        const { token } = this.context;
        
        this.setState({
            siteAddressIsLoading: true,
            siteAddress: []
        });
        
        JobTemplateApi.getJobTemplateSiteAddress(
            {
                currentPage: 1,
                pageSize: 10,
                searchQuery: searchQuery
            }, 
            token
        ).then(result => {
            apiUtil.parseResult(result, (data) => {
                let arr = [];
                let count = 0;
                if(this.state.addresses && this.state.addresses.length > 0){
                    for(let i = 0; i < this.state.addresses.length; i++){
                        count += 1;
                        let item = this.state.addresses[i];
                        item['value'] = count;
                        item['title'] = item.stepSiteName;

                        if(item.stepSiteName !== ''){
                            if(arr.findIndex(x => x.title.toString().toLowerCase() === item.stepSiteName.toString().toLowerCase()) === -1){
                                arr.push(item);
                            }
                        }
                    }
                }

                if(data && data.data && data.data.length > 0){
                    for(let i = 0; i < data.data.length; i++){
                        count += 1;
                        let item = data.data[i];
                        item['value'] = count;
                        item['title'] = item.stepSiteName;

                        if(item.stepSiteName !== ''){
                            if(arr.findIndex(x => x.title.toString().toLowerCase() === item.stepSiteName.toString().toLowerCase()) === -1){
                                arr.push(item);
                            }
                        }
                    }
                }

                this.setState({
                    siteAddress: arr,
                    siteAddressIsLoading: false
                }, () => {
                    if(callback !== null){
                        callback(this.state.siteAddress);
                    }
                });
            }, (error, type) => {
                this.setState({
                    siteAddress: [],
                    siteAddressIsLoading: false
                });
            });
        });
    }
    /* END API */


    render() {
        return <Box className={'items-row-custom-item'}>
            <Grid container spacing={2} alignItems={'center'} className={'custom-row'}>
                <Box clone>
                    <Grid item xs={12} md={1} className={'custom-cell'}>
                        <Box textAlign={'center'} pl={1} pr={1}>
                            <Tooltip title={'Set As Default'}>
                                <Box>
                                    <Radio 
                                        className={'primary-radio-box'}
                                        checked={this.state.isDefault}
                                        value="true"
                                        color={'primary'}
                                        onClick={(e) => {
                                            if(!this.state.isDefault && this.props.onRemoveDefault){
                                                this.props.onRemoveDefault(() => {
                                                    this.setState({
                                                        isDefault: !this.state.isDefault
                                                    }, () => {
                                                        if(this.props.onChange){
                                                            this.props.onChange('isDefault', this.state.isDefault, null, false);
                                                        }
                                                    });
                                                });
                                            }
                                        }}
                                    />
                                </Box>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12} md={3} className={'custom-cell'}>
                        <CustomAutosuggest 
                            className={'job-form-autosuggest'}
                            valueName={'value'}
                            textName={'title'}
                            isCustomRenderSuggestionsContainer={true}
                            value={this.state.stepSiteName}
                            items={this.state.siteAddress}
                            noIcon={true}
                            isAjax={true}
                            isLoading={this.state.siteAddressIsLoading} 
                            onChange={(value) => {
                                if(value){
                                    this.setState({
                                        stepSiteName: value.stepSiteName,
                                        stepSiteAddress: value.stepSiteAddress,
                                        stepSiteLatitude: value.stepSiteLatitude,
                                        stepSiteLongitude: value.stepSiteLongitude,
                                    }, () => {
                                        if(this.props.onChange){
                                            this.props.onChange('stepSiteName', this.state.stepSiteName, null, false);
                                            this.props.onChange('stepSiteAddress', this.state.stepSiteAddress, null, false);
                                            this.props.onChange('stepSiteLatitude', this.state.stepSiteLatitude, null, false);
                                            this.props.onChange('stepSiteLongitude', this.state.stepSiteLongitude, null, false);
                                        }
                                    });
                                } else {
                                    this.setState({
                                        stepSiteName: '',
                                    }, () => {
                                        if(this.props.onChange){
                                            this.props.onChange('stepSiteName', this.state.stepSiteName, null, false);
                                            this.props.onChange('stepSiteAddress', this.state.stepSiteAddress, null, false);
                                            this.props.onChange('stepSiteLatitude', this.state.stepSiteLatitude, null, false);
                                            this.props.onChange('stepSiteLongitude', this.state.stepSiteLongitude, null, false);
                                        }
                                    });
                                }
                            }}
                            onSearch={(value, e) => {
                                this.setState({
                                    stepSiteName: value
                                }, () => {
                                    this.callJobTemplateSiteAddressReadApi(value, () => {
                                        e.showAllSuggestions();
                                    });
                                });
                            }}
                            onClick={(e) => {
                                this.callJobTemplateSiteAddressReadApi('', (arr) => {
                                    this.setState({
                                        siteAddress: arr
                                    }, () => {
                                        e.showAllSuggestions();
                                    });
                                });
                            }}
                            onBlur={(e) => {
                                e.hideAllSuggestions();
                                
                                if(this.props.onChange){
                                    this.props.onChange('stepSiteName', this.state.stepSiteName, null, false);
                                    this.props.onChange('stepSiteAddress', this.state.stepSiteAddress, null, false);
                                    this.props.onChange('stepSiteLatitude', this.state.stepSiteLatitude, null, false);
                                    this.props.onChange('stepSiteLongitude', this.state.stepSiteLongitude, null, false);
                                }
                            }}
                            onClear={() => {
                                this.setState({
                                    stepSiteName: '',
                                }, () => {
                                    if(this.props.onChange){
                                        this.props.onChange('stepSiteName', this.state.stepSiteName, null, false);
                                    }
                                });
                            }}
                        />
                    </Grid>
                </Box>
                <Box clone>
                    <Grid item xs={12} md={true} className={'custom-cell'}>
                        <StandaloneSearchBox 
                            value={this.state.stepSiteAddress}
                            placeholder={'Insert address or coordinate'}
                            error={this.state.isInvalid}
                            onChange={(value) => {
                                this.setState({
                                    stepSiteAddress: value,
                                    stepSiteLatitude: null,
                                    stepSiteLongitude: null,
                                });
                            }}
                            onBlur={(value) => {
                                let isCoord = false;
                                if(Utils.validateCoordinates(value)){
                                    let args = value.split(',');
                    
                                    let center = {
                                      lat: (args && args.length > 0) ? Utils.isNumeric(args[0].trim()) ? parseFloat(args[0].trim()) : '' : '',
                                      lng: (args && args.length > 1) ? Utils.isNumeric(args[1].trim()) ? parseFloat(args[1].trim()) : '' : '',
                                    };
                                    
                                    isCoord = true;

                                    this.setState({
                                        stepSiteAddress: value,
                                        stepSiteLatitude: center.lat,
                                        stepSiteLongitude: center.lng,
                                        isInvalid: false,
                                    }, () => {
                                        if(this.props.onChange){
                                            this.props.onChange('stepSiteAddress', value, null, false);
                                            this.props.onChange('stepSiteLatitude', center.lat, null, false);
                                            this.props.onChange('stepSiteLongitude', center.lng, null, false);
                                        }
                                    });
                                }

                                if(!isCoord){
                                    if(this.state.stepSiteLatitude !== null && this.state.stepSiteLongitude !== null){
                                        if(this.props.onChange){
                                            this.props.onChange('stepSiteAddress', this.state.stepSiteAddress, null, false);
                                            this.props.onChange('stepSiteLatitude', this.state.stepSiteLatitude, null, false);
                                            this.props.onChange('stepSiteLongitude', this.state.stepSiteLongitude, null, false);
                                        }
                                    } else {
                                        // Utils.toast('Invalid coordinate', 'error');

                                        this.setState({
                                            isInvalid: true,
                                            stepSiteAddress: '',
                                            stepSiteLatitude: null,
                                            stepSiteLongitude: null,
                                        }, () => {
                                            if(this.props.onChange){
                                                this.props.onChange('stepSiteAddress', this.state.stepSiteAddress, null, false);
                                                this.props.onChange('stepSiteLatitude', this.state.stepSiteLatitude, null, false);
                                                this.props.onChange('stepSiteLongitude', this.state.stepSiteLongitude, null, false);
                                            }
                                        });
                                    }
                                }
                            }}
                            onPlacesChanged={(place) => {
                                if(place){
                                    if(place.formatted_address){
                                        this.setState({
                                            stepSiteAddress: place.formatted_address,
                                            isInvalid: false,
                                        }, () => {
                                            if(this.props.onChange){
                                                this.props.onChange('stepSiteAddress', this.state.stepSiteAddress, null, false);
                                                this.props.onChange('stepSiteLatitude', this.state.stepSiteLatitude, null, false);
                                                this.props.onChange('stepSiteLongitude', this.state.stepSiteLongitude, null, false);
                                            }
                                        });
                                    } else if(place.name){
                                        this.setState({
                                            stepSiteAddress: place.name,
                                            isInvalid: false,
                                        }, () => {
                                            if(this.props.onChange){
                                                this.props.onChange('stepSiteAddress', this.state.stepSiteAddress, null, false);
                                                this.props.onChange('stepSiteLatitude', this.state.stepSiteLatitude, null, false);
                                                this.props.onChange('stepSiteLongitude', this.state.stepSiteLongitude, null, false);
                                            }
                                        });
                                    }
                                    
                                    if(place.geometry && place.geometry.location){
                                        this.setState({
                                            stepSiteLatitude: place.geometry.location.lat(),
                                            stepSiteLongitude: place.geometry.location.lng(),
                                            isInvalid: false,
                                        }, () => {
                                            if(this.props.onChange){
                                                this.props.onChange('stepSiteAddress', this.state.stepSiteAddress, null, false);
                                                this.props.onChange('stepSiteLatitude', this.state.stepSiteLatitude, null, false);
                                                this.props.onChange('stepSiteLongitude', this.state.stepSiteLongitude, null, false);
                                            }
                                        });
                                    } else {
                                        if(this.props.onChange){
                                            this.props.onChange('stepSiteAddress', this.state.stepSiteAddress, null, false);
                                            this.props.onChange('stepSiteLatitude', this.state.stepSiteLatitude, null, false);
                                            this.props.onChange('stepSiteLongitude', this.state.stepSiteLongitude, null, false);
                                        }
                                    }
                                } else {
                                    this.setState({
                                        stepSiteAddress: '',
                                        stepSiteLatitude: null,
                                        stepSiteLongitude: null,
                                        isInvalid: false,
                                    }, () => {
                                        if(this.props.onChange){
                                            this.props.onChange('stepSiteAddress', this.state.stepSiteAddress, null, false);
                                            this.props.onChange('stepSiteLatitude', this.state.stepSiteLatitude, null, false);
                                            this.props.onChange('stepSiteLongitude', this.state.stepSiteLongitude, null, false);
                                        }
                                    });
                                }
                            }}
                            onEnterCoordinate={async (place, center) => {
                                if(place){
                                    if(place.formatted_address){
                                        this.setState({
                                            stepSiteAddress: place.formatted_address,
                                        }, () => {
                                            if(this.props.onChange){
                                                this.props.onChange('stepSiteAddress', this.state.stepSiteAddress, null, false);
                                                this.props.onChange('stepSiteLatitude', this.state.stepSiteLatitude, null, false);
                                                this.props.onChange('stepSiteLongitude', this.state.stepSiteLongitude, null, false);
                                            }
                                        });
                                    } else if(place.name){
                                        this.setState({
                                            stepSiteAddress: place.name,
                                        }, () => {
                                            if(this.props.onChange){
                                                this.props.onChange('stepSiteAddress', this.state.stepSiteAddress, null, false);
                                                this.props.onChange('stepSiteLatitude', this.state.stepSiteLatitude, null, false);
                                                this.props.onChange('stepSiteLongitude', this.state.stepSiteLongitude, null, false);
                                            }
                                        });
                                    }
                                    
                                    if(place.geometry && place.geometry.location){
                                        this.setState({
                                            stepSiteLatitude: place.geometry.location.lat(),
                                            stepSiteLongitude: place.geometry.location.lng(),
                                        }, () => {
                                            if(this.props.onChange){
                                                this.props.onChange('stepSiteAddress', this.state.stepSiteAddress, null, false);
                                                this.props.onChange('stepSiteLatitude', this.state.stepSiteLatitude, null, false);
                                                this.props.onChange('stepSiteLongitude', this.state.stepSiteLongitude, null, false);
                                            }
                                        });
                                    } else {
                                        if(this.props.onChange){
                                            this.props.onChange('stepSiteAddress', this.state.stepSiteAddress, null, false);
                                            this.props.onChange('stepSiteLatitude', this.state.stepSiteLatitude, null, false);
                                            this.props.onChange('stepSiteLongitude', this.state.stepSiteLongitude, null, false);
                                        }
                                    }
                                } else {
                                    this.setState({
                                        stepSiteAddress: '',
                                        stepSiteLatitude: null,
                                        stepSiteLongitude: null,
                                    }, () => {
                                        if(this.props.onChange){
                                            this.props.onChange('stepSiteAddress', this.state.stepSiteAddress, null, false);
                                            this.props.onChange('stepSiteLatitude', this.state.stepSiteLatitude, null, false);
                                            this.props.onChange('stepSiteLongitude', this.state.stepSiteLongitude, null, false);
                                        }
                                    });
                                }
                            }}
                        />
                    </Grid>
                </Box>
            </Grid>
        </Box>
    }
}

export default LocationItemRow;
