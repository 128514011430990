import axios from 'axios';


const { 
    REACT_APP_DISPATCH_API_URL
} = process.env;


const API = "customersite";

const customerSiteApi = {

    read: async (data, token) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_DISPATCH_API_URL + API;
        
        const getResult = await axios.get(
            URL,
            { 
                params: data,
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            })
            .catch( error => {
                try {
                    status = error.response.status;

                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch(err) {
                    errorMessage = 'Service Unavailable!'
                }
            });
            
        return { data: getResult, errorMessage: errorMessage, status: status};
    },

    list: async (data, token) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_DISPATCH_API_URL + API;
        
        const getResult = await axios.get(
            URL + '/list',
            { 
                params: data,
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            })
            .catch( error => {
                try {
                    status = error.response.status;

                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch(err) {
                    errorMessage = 'Service Unavailable!'
                }
            });
            
        return { data: getResult, errorMessage: errorMessage, status: status};
    },

    create: async (data, token) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_DISPATCH_API_URL + API;

        const getResult = await axios.post(
            URL,
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            })
            .catch( error => {
                try {
                    status = error.response.status;

                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch(err) {
                    errorMessage = 'Service Unavailable!'
                }
            });
        return { data: getResult, errorMessage: errorMessage, status: status};
    },

    update: async (data, token) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_DISPATCH_API_URL + API;

        const getResult = await axios.put(
            URL,
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            })
            .catch( error => {
                try {
                    status = error.response.status;

                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch(err) {
                    errorMessage = 'Service Unavailable!'
                }
            });
        return { data: getResult, errorMessage: errorMessage, status: status};
    },

    delete: async (data, token) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_DISPATCH_API_URL + API;

        const getResult = await axios(
            {
                method: 'delete',
                url: URL,
                data: data, 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            })
            .catch( error => {
                try {
                    status = error.response.status;

                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch(err) {
                    errorMessage = 'Service Unavailable!'
                }
            });
        return { data: getResult, errorMessage: errorMessage, status: status};
    },

    deactive: async (data, token) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_DISPATCH_API_URL + API;

        const getResult = await axios.post(
            URL + "/deactivate",
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            })
            .catch( error => {
                try {
                    status = error.response.status;

                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch(err) {
                    errorMessage = 'Service Unavailable!'
                }
            });
        return { data: getResult, errorMessage: errorMessage, status: status};
    },

    stat: async (token) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_DISPATCH_API_URL + API + '/stat/all';
        
        const getResult = await axios.get(
            URL,
            { 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            })
            .catch( error => {
                try {
                    status = error.response.status;

                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch(err) {
                    errorMessage = 'Service Unavailable!'
                }
            });
            
        return { data: getResult, errorMessage: errorMessage, status: status};
    },

    details: async (id, token) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_DISPATCH_API_URL + API + '/' + id;
        
        const getResult = await axios.get(
            URL,
            { 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            })
            .catch( error => {
                try {
                    status = error.response.status;

                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch(err) {
                    errorMessage = 'Service Unavailable!'
                }
            });
            
        return { data: getResult, errorMessage: errorMessage, status: status};
    },
    
    readBins: async (id, data, token) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_DISPATCH_API_URL + API + "/" + id + "/bins";
        
        const getResult = await axios.get(
            URL,
            { 
                params: data,
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            })
            .catch( error => {
                try {
                    status = error.response.status;

                    if (error.response) {
                        // Request made and server responded
                        errorMessage = error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = error.request;
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage = error.message;
                    }
                } catch(err) {
                    errorMessage = 'Service Unavailable!'
                }
            });
            
        return { data: getResult, errorMessage: errorMessage, status: status};
    },
}

export default customerSiteApi;